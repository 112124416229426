import * as S from './VegaDataTable.styles';
import React from 'react';
import {View as VegaView, DataListenerHandler} from 'vega';
import {VariableSizeGrid as Grid} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {isPlainObject} from 'lodash';

const HORIZONTAL_PADDING = 8;

function getColumnWidths(table: any[], keys: string[]) {
  const result: {[key: string]: number} = {};
  for (const key of keys) {
    let max = key.length;
    for (const row of table) {
      max = Math.max(max, row[key] == null ? 1 : row[key].toString().length);
    }
    result[key] = Math.min(16 + max * 7.5, 160);
  }
  return result;
}

export interface VegaDataTableProps {
  className?: string;
  view: VegaView;
  name: string;
  initialValue: any;
  onValueChange?(value: any): void;
}

const VegaDataTable: React.FC<VegaDataTableProps> = ({
  className,
  view,
  name,
  initialValue,
  onValueChange,
}) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    const onChange: DataListenerHandler = (n, newValue) => {
      setValue(newValue);
      onValueChange?.(newValue);
    };
    // When the view changes, the selected table isn't immediately changed, so an error is thrown once.
    try {
      view.addDataListener(name, onChange);
    } catch {
      return;
    }
    return () => {
      view.removeDataListener(name, onChange);
    };
  }, [view, name, onValueChange]);

  React.useEffect(() => {
    if (isPlainObject(initialValue)) {
      setValue(Object.values(initialValue));
    } else {
      setValue(initialValue);
    }
  }, [initialValue]);

  const dataKeys = React.useMemo(
    () =>
      Array.isArray(value) && value[0] != null ? Object.keys(value[0]) : [],
    [value]
  );
  const columnWidths = React.useMemo(
    () => getColumnWidths(value, dataKeys),
    [value, dataKeys]
  );

  if (!Array.isArray(value)) {
    return <S.NoDataMessage>Not an array</S.NoDataMessage>;
  }

  if (value.length === 0) {
    return <S.NoDataMessage>No data</S.NoDataMessage>;
  }

  const innerElementType = React.forwardRef<HTMLDivElement, any>(
    ({children, style, ...rest}, ref) => (
      <div
        ref={ref}
        style={{
          ...style,
          width: `${parseFloat(style.width) + HORIZONTAL_PADDING * 2}px`,
        }}
        {...rest}>
        <S.DataHeaderRow>
          {dataKeys.map(k => (
            <S.DataCell title={k} key={k} width={columnWidths[k]}>
              {k}
            </S.DataCell>
          ))}
        </S.DataHeaderRow>
        {children}
      </div>
    )
  );

  return (
    <S.Wrapper>
      <AutoSizer>
        {({height, width}) => (
          <Grid
            innerElementType={innerElementType}
            columnCount={dataKeys.length}
            columnWidth={i => columnWidths[dataKeys[i]]}
            height={height}
            rowCount={value.length + 1}
            rowHeight={() => 33}
            width={width}>
            {({columnIndex, rowIndex, style}) => {
              if (rowIndex === 0) {
                return null;
              }
              const val = value[rowIndex - 1][dataKeys[columnIndex]];
              const content = val == null ? '-' : val.toString();
              return (
                <S.DataCell
                  width={100}
                  style={{
                    ...(style as any),
                    left: `${
                      parseFloat(style.left as string) + HORIZONTAL_PADDING
                    }px`,
                  }}
                  title={content}>
                  {content}
                </S.DataCell>
              );
            }}
          </Grid>
        )}
      </AutoSizer>
    </S.Wrapper>
  );
};

export default VegaDataTable;
