import * as S from './PanelBankControlsModal.styles';

import React from 'react';
import makeComp from '../util/profiler';
import {Checkbox, Modal, Message} from 'semantic-ui-react';
import {useWorkspaceAction} from '../state/workspaces/hooks';
import * as WorkspaceActions from '../state/workspaces/actions';
import {
  OrganizationPrefix,
  PanelBankSettings,
  SectionPanelSorting,
} from '../util/panelbank';
import {Ref} from '../state/views/panelBankConfig/types';
import {PanelBankConfigWithRefs} from './PanelBank';
import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as ViewHooks from '../state/views/hooks';

interface PanelBankControlsModalProps {
  panelBankConfigRef: Ref;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  workspaceID: string;
  isModalOpen: boolean;
  onClose(): void;
  updatePanelBankSettings(panelSettings: PanelBankSettings): void;
}

const PanelBankControlsModal = makeComp(
  (props: PanelBankControlsModalProps) => {
    const {
      panelBankConfigRef,
      panelBankConfigWithRefs,
      workspaceID,
      isModalOpen,
      updatePanelBankSettings,
      onClose,
    } = props;
    const {
      sections,
      settings: {showEmptySections, autoOrganizePrefix, sortAlphabetically},
    } = panelBankConfigWithRefs;
    const [modalSettings, setModalSettings] = React.useState<
      PanelBankSettings & {sortAllSections: boolean}
    >({
      showEmptySections,
      autoOrganizePrefix,
      sortAlphabetically,
      sortAllSections: false,
    });

    const reorganizePanelSections = useWorkspaceAction(
      workspaceID,
      WorkspaceActions.resetWithPanelBankSettings
    );

    const showSortAllOption =
      sections.some(section => section.sorted === SectionPanelSorting.Manual) &&
      modalSettings.sortAlphabetically;

    const updateSettingsAndSortPanels = ViewHooks.useViewAction(
      panelBankConfigRef,
      PanelBankConfigActions.updateSettingsAndSortPanels
    );

    return (
      <Modal open={isModalOpen} onClose={onClose}>
        <Modal.Header>Workspace Settings</Modal.Header>
        <Modal.Content className="panel-bank__modal">
          <S.MainOption>
            Organize panels into sections based on metric name.
            <div>
              <S.SubOption
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
                  });
                }}>
                <S.SubOptionRadio
                  active={
                    modalSettings.autoOrganizePrefix ===
                      OrganizationPrefix.FirstPrefix ||
                    modalSettings.autoOrganizePrefix == null
                  }
                />
                Group first prefix
              </S.SubOption>
              <S.SubOptionDescription>
                Ex. Log metric "a/b/c/d" → section a
              </S.SubOptionDescription>
              <S.SubOption
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    autoOrganizePrefix: OrganizationPrefix.LastPrefix,
                  });
                }}>
                <S.SubOptionRadio
                  active={
                    modalSettings.autoOrganizePrefix ===
                    OrganizationPrefix.LastPrefix
                  }
                />
                Group last prefix
              </S.SubOption>
              <S.SubOptionDescription>
                Ex. Log metric "a/b/c/d" → section a/b/c
              </S.SubOptionDescription>
            </div>
          </S.MainOption>
          <S.MainOption>
            <Checkbox
              toggle
              label="Show empty sections"
              checked={modalSettings.showEmptySections}
              onChange={(_, value) =>
                setModalSettings({
                  ...modalSettings,
                  showEmptySections: value.checked ?? false,
                })
              }
            />
            <S.MainOptionDescription>
              By default, we hide sections that have no available panels.
            </S.MainOptionDescription>
          </S.MainOption>

          <S.MainOption>
            <Checkbox
              toggle
              label="Sort panels alphabetically"
              checked={modalSettings.sortAlphabetically}
              onChange={(_, value) => {
                setModalSettings({
                  ...modalSettings,
                  sortAlphabetically: value.checked ?? false,
                });
              }}
            />
          </S.MainOption>
          {showSortAllOption && (
            <S.SubOptionAlert>
              Some sections have been sorted manually. Do you want to override
              all sections with this global sort setting?
              <S.OptionCheckbox
                toggle
                label="Override all sections on the page"
                checked={modalSettings.sortAllSections}
                onChange={() => {
                  setModalSettings({
                    ...modalSettings,
                    sortAllSections: !modalSettings.sortAllSections,
                  });
                }}
              />
            </S.SubOptionAlert>
          )}

          <div>
            {modalSettings.autoOrganizePrefix !== autoOrganizePrefix && (
              <Message negative style={{borderRadius: 0, padding: '8px 12px'}}>
                Reconfiguring panel organization clears your workspace and is
                irreversible. All your changes will be lost.
              </Message>
            )}
            <S.Actions>
              <S.WBModalButton onClick={onClose}>Cancel</S.WBModalButton>
              <S.WBModalButton
                primary
                onClick={() => {
                  if (modalSettings.autoOrganizePrefix !== autoOrganizePrefix) {
                    reorganizePanelSections(modalSettings);
                  }
                  if (modalSettings.sortAlphabetically) {
                    updateSettingsAndSortPanels({
                      panelBankSettings: modalSettings,
                      sortAllSections: modalSettings.sortAllSections,
                    });
                  } else {
                    updatePanelBankSettings(modalSettings);
                  }
                  setModalSettings({
                    ...modalSettings,
                    sortAllSections: false,
                  });
                  onClose();
                }}>
                Apply
              </S.WBModalButton>
            </S.Actions>
          </div>
        </Modal.Content>
      </Modal>
    );
  },
  {id: 'PanelBankControlsModal'}
);

export default PanelBankControlsModal;
