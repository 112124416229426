import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  MutableRefObject,
} from 'react';
import _ from 'lodash';

import {shallowEqual} from '@wandb/cg/browser/utils/obj';
import {difference} from './data';

// This hook is used in development for debugging state changes
// It shouldn't be used in production
export function useTraceUpdate(name: string, props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        (ps as any)[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', name, changedProps);
    }
    prev.current = props;
  });
}

// Returns [liveState, debounceState, setState, setLiveAndDebounceState]
export function useDebounceState<S>(
  initial: S,
  delay: number
): [S, S, (newVal: S) => void, (newVal: S) => void] {
  const [liveState, setLiveState] = useState(initial);
  const [debounceState, setDebounceState] = useState(initial);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceState(liveState);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [liveState, delay]);

  const setLiveAndDebounceState = useCallback((newVal: S) => {
    setLiveState(newVal);
    setDebounceState(newVal);
  }, []);

  return [liveState, debounceState, setLiveState, setLiveAndDebounceState];
}

export function useShallowEqualValue<TValue>(v: TValue): TValue {
  const lastValue = useRef(v);

  if (v !== lastValue.current && !shallowEqual(v, lastValue.current)) {
    lastValue.current = v;
  }
  return lastValue.current;
}

export function useDeepEqualValue<TValue>(v: TValue): TValue {
  const lastValue = useRef(v);

  if (v !== lastValue.current && !_.isEqual(v, lastValue.current)) {
    lastValue.current = v;
  }
  return lastValue.current;
}

export function useTimer(fn: () => void, delay: number) {
  useEffect(() => {
    setTimeout(fn, delay);
    // eslint-disable-next-line
  }, []);
}

export function useBoundingClientRect(
  elRef: MutableRefObject<Element | null>,
  skip: boolean = false
) {
  const [, forceRender] = useState({});
  const rectRef = useRef<DOMRect | null>(
    elRef.current?.getBoundingClientRect() ?? null
  );
  const skipRef = useRef(skip);
  skipRef.current = skip;

  useLayoutEffect(() => {
    if (skip) {
      return;
    }
    const updateRect = () => {
      if (skipRef.current) {
        return;
      }

      if (elRef.current == null) {
        if (rectRef.current != null) {
          rectRef.current = null;
          forceRender({});
        }
        return;
      }

      const oldRect = rectRef.current;
      const newRect = elRef.current.getBoundingClientRect();

      if (
        oldRect == null ||
        oldRect.x !== newRect.x ||
        oldRect.y !== newRect.y ||
        oldRect.width !== newRect.width ||
        oldRect.height !== newRect.height
      ) {
        rectRef.current = newRect;
        forceRender({});
      }
    };
    updateRect();
    // there is no good way to detect element position shifts for arbitrary reasons.
    // a 50ms interval for this cheap function should be fine.
    // still, for performance concerns, we should ensure that skip === true whenever we don't need the update.
    const intervalID = setInterval(updateRect, 50);
    return () => clearInterval(intervalID);
    // eslint-disable-next-line
  }, [skip]);

  return rectRef.current;
}

export function usePoll(f: () => Promise<any>, interval: number) {
  if (interval === 0) {
    interval = 365 * 24 * 60 * 60000;
  }

  const mountedRef = useRef(true);
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  useEffect(() => {
    return () => {
      mountedRef.current = false;
      // if there is a running timer on unmount, remove it
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [mountedRef, timerRef]);

  const [pollCount, setPollCount] = useState(0);
  const pollCountRef = useRef(pollCount);
  pollCountRef.current = pollCount;

  useEffect(() => {
    f().then(() => {
      // don't schedule after unmount
      if (!mountedRef.current) {
        return;
      }
      // don't schedule if something else triggered a re-poll
      if (pollCount !== pollCountRef.current) {
        return;
      }

      // clear any other lingering timeouts
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => setPollCount(pc => pc + 1), interval);
    });
  }, [
    f,
    interval,
    pollCount,
    setPollCount,
    pollCountRef,
    mountedRef,
    timerRef,
  ]);
}

function trackMaxScrollDepth(
  type: string,
  id: string,
  maxScrollDepth: number
): void {
  window.analytics.track('Max scroll depth', {
    type,
    id,
    maxScrollDepth,
    documentScrollHeight: document.body.scrollHeight,
  });
}

export function useTrackMaxScrollDepth(
  type: string,
  id: string,
  heightResolutionPX = 500,
  throttleMS = 500
): () => void {
  const pageReadyRef = useRef(false);
  const maxDepthRef = useRef(0);

  useEffect(() => {
    const handleScroll = _.throttle(
      () => {
        const y = window.scrollY;
        const toNearestCheckpointBelow =
          Math.floor(y / heightResolutionPX) * heightResolutionPX;
        if (toNearestCheckpointBelow > maxDepthRef.current) {
          maxDepthRef.current = toNearestCheckpointBelow;
          if (pageReadyRef.current) {
            trackMaxScrollDepth(type, id, maxDepthRef.current);
          }
        }
      },
      throttleMS,
      {leading: false}
    );
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [type, id, heightResolutionPX, throttleMS]);

  const signalPageReadyRef = useRef(
    _.once(() => {
      pageReadyRef.current = true;
      trackMaxScrollDepth(type, id, maxDepthRef.current);
    })
  );
  return signalPageReadyRef.current;
}

function trackTimeSpentOnPage(
  type: string,
  id: string,
  timeSpent: number
): void {
  window.analytics.track('Time spent on page', {
    type,
    id,
    timeSpent,
  });
}

export function useTrackTimeSpentOnPage(type: string, id: string): void {
  useEffect(() => {
    trackTimeSpentOnPage(type, id, 0);
    const firstRenderTS = Date.now();
    let stop = false;
    let waitMS = 1000;
    const track = () => {
      if (stop) {
        return;
      }
      trackTimeSpentOnPage(type, id, Date.now() - firstRenderTS);
      waitMS *= 2;
      setTimeout(track, waitMS);
    };
    setTimeout(track, waitMS);
    return () => {
      stop = true;
    };
    // eslint-disable-next-line
  }, []);
}

export function useLogIfChanged(
  identifier: string,
  o: any,
  equalityFn = (a: any, b: any) => a === b
): boolean {
  const ref = useRef(o);
  const changed = !equalityFn(ref.current, o);
  if (changed) {
    console.group(`[useLogIfChanged]: ${identifier} changed`);
    console.log('prev', ref.current);
    console.log('next', o);
    console.log('diff', difference(ref.current, o));
    console.groupEnd();
  }
  ref.current = o;
  return changed;
}

export function useScrollPosition() {
  const [pos, setPos] = useState(0);
  useEffect(() => {
    // TODO: This probably needs to be debounced for performance.
    document.addEventListener('scroll', e => {
      setPos(document.scrollingElement?.scrollTop ?? 0);
    });
    // TODO: This needs to clean up the event listener on unmount!
  }, []);
  return pos;
}
