import React, {PropsWithChildren, useCallback, useState} from 'react';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import classNames from 'classnames';
import {MultiStateCheckboxCheckedState} from './MultiStateCheckbox';
import makeComp from '../util/profiler';

type RenderHeader = (
  checked: MultiStateCheckboxCheckedState,
  onClick: () => void
) => JSX.Element;

type RenderRow<T> = (
  r: T,
  selected: boolean,
  setSelected: (id: string, s: boolean) => void
) => JSX.Element;

interface MultiSelectTableProps<T extends {id: string}> {
  className?: string;
  readOnly: boolean;
  rows: T[];
  draftRows?: T[];
  sortBy?: string | ((row: T) => any);
  sortReverse?: boolean;
  sortDraftBy?: string | ((row: T) => any);
  sortDraftReverse?: boolean;
  renderHeader: RenderHeader;
  renderRow: RenderRow<T>;
  renderDraftHeader?: RenderHeader;
  renderDraftRow?: RenderRow<T>;
  renderAddButton(): JSX.Element;
  renderDeleteButton(): JSX.Element;
  renderDialogs(selectedRows: T[]): JSX.Element;
  renderEmpty?(): JSX.Element;
  renderFilter?(): JSX.Element;
  renderSearch?(): JSX.Element;
}

const MultiSelectTable = makeComp(
  <T extends {id: string}>({
    className,
    readOnly,
    rows,
    draftRows = [],
    sortBy,
    sortReverse,
    sortDraftBy,
    sortDraftReverse,
    renderRow,
    renderDraftRow,
    renderHeader,
    renderDraftHeader,
    renderAddButton,
    renderDeleteButton,
    renderDialogs,
    renderEmpty,
    renderFilter,
    renderSearch,
  }: PropsWithChildren<MultiSelectTableProps<T>>) => {
    const [selectedRowIDs, setSelectedRowIDs] = useState<string[]>([]);

    const setRowSelected = useCallback(
      (id: string, selected: boolean) =>
        setSelectedRowIDs(sv =>
          selected ? [...sv, id] : sv.filter(v => v !== id)
        ),
      [setSelectedRowIDs]
    );

    const selectedRowIDSet = new Set(selectedRowIDs);

    const rowIDSet = new Set(rows.map(r => r.id));
    const draftRowIDSet = new Set(draftRows.map(r => r.id));

    const filteredSelectedRows: T[] = [...rows, ...draftRows].filter(r =>
      selectedRowIDSet.has(r.id)
    );

    const selectedDraftRowCount = selectedRowIDs.filter(id =>
      draftRowIDSet.has(id)
    ).length;
    const selectedRowCount = selectedRowIDs.filter(id =>
      rowIDSet.has(id)
    ).length;

    const draftTableSelectedState: MultiStateCheckboxCheckedState =
      selectedDraftRowCount === draftRows.length
        ? 'checked'
        : selectedDraftRowCount === 0
        ? 'unchecked'
        : 'partial';

    const onDraftTableSelectChanged = () =>
      setSelectedRowIDs(sv => {
        if (draftTableSelectedState === 'checked') {
          return [...sv.filter(v => !draftRowIDSet.has(v))];
        } else {
          return [...sv.filter(v => !draftRowIDSet.has(v)), ...draftRowIDSet];
        }
      });

    const tableSelectedState: MultiStateCheckboxCheckedState =
      selectedRowCount === rows.length
        ? 'checked'
        : selectedRowCount === 0
        ? 'unchecked'
        : 'partial';

    const onTableSelectChanged = () =>
      setSelectedRowIDs(sv => {
        if (tableSelectedState === 'checked') {
          return [...sv.filter(v => !rowIDSet.has(v))];
        } else {
          return [...sv.filter(v => !rowIDSet.has(v)), ...rowIDSet];
        }
      });

    const sortedRows = sortBy ? _.sortBy(rows, sortBy) : rows;
    if (sortReverse) {
      sortedRows.reverse();
    }
    const sortedDraftRows = sortDraftBy
      ? _.sortBy(draftRows, sortDraftBy)
      : draftRows;
    if (sortDraftReverse) {
      sortedDraftRows.reverse();
    }

    if (renderEmpty != null) {
      return (
        <>
          <div className={classNames('report-table', className)}>
            <div className="report-table__actions">
              <Form size="small">
                <Form.Group inline />
              </Form>
              {!readOnly && (
                <div className="report-table__action">{renderAddButton()}</div>
              )}
            </div>
          </div>
          {renderEmpty()}
        </>
      );
    }
    return (
      <>
        {renderDialogs(filteredSelectedRows)}
        <div className={classNames('report-table', className)}>
          <div className="report-table__actions">
            <Form size="small">
              <Form.Group inline>
                {renderSearch?.()}
                {renderFilter?.()}
              </Form.Group>
            </Form>
            {filteredSelectedRows.length > 0 && (
              <div className="report-table__action">{renderDeleteButton()}</div>
            )}
            {!readOnly && (
              <div className="report-table__action">{renderAddButton()}</div>
            )}
          </div>
          {renderDraftHeader != null && renderDraftRow != null && (
            <div className="report-table__draft-table">
              <div className="report-table__header">
                {renderDraftHeader(
                  draftTableSelectedState,
                  onDraftTableSelectChanged
                )}
              </div>
              {sortedDraftRows.length === 0 && (
                <div className="report-table__no-results">No results</div>
              )}
              {sortedDraftRows.map(r =>
                renderDraftRow(r, selectedRowIDSet.has(r.id), setRowSelected)
              )}
            </div>
          )}
          {
            <div className="report-table__master-table">
              <div className="report-table__header">
                {renderHeader(tableSelectedState, onTableSelectChanged)}
              </div>
              {sortedRows.length === 0 && (
                <div className="report-table__no-results">No results</div>
              )}
              {sortedRows.map(r =>
                renderRow(r, selectedRowIDSet.has(r.id), setRowSelected)
              )}
            </div>
          }
        </div>
      </>
    );
  },
  {id: 'MultiSelectTable'}
);

export default MultiSelectTable;
