import React, {useState} from 'react';
import {Form, Icon, Modal} from 'semantic-ui-react';
import CopyableText from '../CopyableText';
import gql from 'graphql-tag';
import {useQuery} from '../../state/graphql/query';
import WandbLoader from '../WandbLoader';
import {EditCustomChartAccessFormHydrated} from './CustomChartAccess';
import makeComp from '../../util/profiler';
import * as Generated from '../../generated/graphql';
import {CustomChartsQueryData, CustomChart} from '../../graphql/customCharts';
import {useQuery as useApolloQuery, useMutation} from 'react-apollo';

// very sad that I need to make this query just to see if I'm in a team.
// hopefully we get a better way to get this info later.
const ENTITY_IS_TEAM_QUERY = gql`
  query EntityIsTeam($name: String!) {
    entity(name: $name) {
      id
      isTeam
    }
  }
`;

interface EntityIsTeamQueryData {
  entity: {
    id: string;
    isTeam: string;
  };
}

interface EntityIsTeamQueryVariables {
  name: string;
}

function sanitizeName(displayName: string) {
  return displayName
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^a-z0-9-_/]/g, '');
}

interface VegaPanelSaveAsProps {
  entityName: string;
  projectName: string;
  customCharts: CustomChart[];
  onSave(name: string, displayName: string, access: string): void;
}

const VegaPanelSaveAs = makeComp(
  (props: VegaPanelSaveAsProps) => {
    const [saveName, setSaveName] = useState('');
    const [saveDisplayName, setSaveDisplayName] = useState('');
    const [saveAccess, setSaveAccess] = useState('UNLISTED');

    const isTeamQuery = useQuery<
      EntityIsTeamQueryData,
      EntityIsTeamQueryVariables
    >(ENTITY_IS_TEAM_QUERY, {
      variables: {name: props.entityName},
      onCompleted: r => {
        if (r.entity.isTeam) {
          setSaveAccess('PRIVATE');
        }
      },
    });

    if (isTeamQuery.loading) {
      return <WandbLoader />;
    }

    const alreadyTaken =
      props.customCharts.filter(v => v.name === saveName).length > 0;
    return (
      <Form style={{marginBottom: 0}}>
        <h2>Save as</h2>
        <Form.Input
          placeholder="Vega visualization display name"
          autoFocus
          value={saveDisplayName}
          onChange={(e, {value}) => {
            setSaveDisplayName(value);
            setSaveName(sanitizeName(value));
          }}
        />
        <Form.Input
          placeholder="Slug"
          value={saveName}
          onChange={(e, {value}) => setSaveName(sanitizeName(value))}
        />
        <EditCustomChartAccessFormHydrated
          access={saveAccess}
          onAccessChange={setSaveAccess}></EditCustomChartAccessFormHydrated>
        <p>
          This chart will be saved under the <strong>{props.entityName}</strong>{' '}
          entity.{' '}
          {saveName && (
            <>
              You can log it from the CLI using this ID:{' '}
              <CopyableText
                code
                text={`${props.entityName}/${saveName}`}
                toastText="Copied chart ID"
              />
            </>
          )}
        </p>
        <Form.Button
          disabled={alreadyTaken}
          primary
          onClick={() => props.onSave(saveName, saveDisplayName, saveAccess)}>
          Create
        </Form.Button>
        {alreadyTaken && (
          <p style={{marginBottom: 0}} className="hint-text">
            <Icon color="orange" name="warning circle" />A panel exists with
            this name.
          </p>
        )}
      </Form>
    );
  },
  {id: 'Vega2.VegaPanelSaveAs'}
);

interface VegaPanelSaveAsTriggerProps {
  entityName: string;
  projectName: string;
  spec: string;
  onSave(id: string): void;
  children(props: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }): React.ReactNode;
}

export const VegaPanelSaveAsTrigger: React.FC<VegaPanelSaveAsTriggerProps> = ({
  entityName,
  projectName,
  onSave,
  children,
  spec,
}) => {
  const [open, setOpen] = React.useState(false);
  const teamCustomChartsQuery = useApolloQuery<
    CustomChartsQueryData,
    Generated.CustomChartsQueryVariables
  >(Generated.CustomChartsDocument, {
    fetchPolicy: 'network-only',
    variables: {entity: entityName},
    skip: !open,
  });
  const customCharts =
    teamCustomChartsQuery.data == null
      ? []
      : teamCustomChartsQuery.data.customCharts.edges.map(e => e.node);

  const [createCustomChart] = useMutation<
    Generated.CreateCustomChartMutationResult,
    Generated.CreateCustomChartMutationVariables
  >(Generated.CreateCustomChartDocument);
  return (
    <>
      {children({setOpen})}
      <Modal
        size="mini"
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <Modal.Content>
          <VegaPanelSaveAs
            entityName={entityName}
            projectName={projectName}
            customCharts={customCharts}
            onSave={(name, displayName, access) => {
              createCustomChart({
                variables: {
                  entity: entityName,
                  name,
                  displayName,
                  access,
                  type: 'vega2',
                  spec,
                },
              }).then(r => {
                teamCustomChartsQuery.refetch();
                setOpen(false);
                // why is r typed wrong (it has an extra 'data')??
                const id = (r.data as any)?.createCustomChart?.chart.id;
                if (id != null) {
                  onSave(id);
                }
              });
            }}></VegaPanelSaveAs>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default VegaPanelSaveAs;
