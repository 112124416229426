import styled from 'styled-components';
import {LinkNoCrawl} from '../util/links';
import {WBIcon} from '@wandb/ui';
import * as globals from '../css/globals.styles';

export const LinkToPage = styled(LinkNoCrawl)`
  color: ${globals.gray500};
  position: fixed;
  bottom: 40px;
  left: 32px;
  display: flex;
  align-items: center;
  &:hover {
    color: ${globals.primaryText};
  }
`;

export const LinkToPageIcon = styled(WBIcon)`
  font-size: 1.3em;
  vertical-align: top;
  line-height: 1.2;
  align-self: center;
  padding-right: 4px;
`;
