// Just handles the old report url (where we used a ?view query string
// with the username and name), to redirect to the new url, where we just
// use the report ID.
import '../css/ReportPage.less';

import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import * as QueryString from 'query-string';

import Loader from '../components/WandbLoader';
import * as Urls from '../util/urls';
import * as String from '@wandb/cg/browser/utils/string';

import * as ReportHooks from '../state/reports/hooks';
import NoMatch from '../components/NoMatch';
import makeComp from '../util/profiler';

interface ReportPageOldProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
    };
  };
  history: any;
}

type ReportPageEdidOldInnerProps = ReportPageOldProps & {
  userName: string;
  viewName: string;
};

const ReportPageEditOldInner = makeComp(
  (props: ReportPageEdidOldInnerProps) => {
    const {entityName, projectName} = props.match.params;
    const {userName, viewName} = props;
    const [invalidState, setInvalidState] = useState(false);

    const {loading, loadedReportID} = ReportHooks.useLoadReportByName(
      entityName,
      projectName,
      userName,
      viewName
    );

    useEffect(() => {
      if (loading) {
        return;
      }

      if (loadedReportID != null) {
        props.history.replace(
          Urls.reportEdit({
            entityName,
            projectName,
            reportID: loadedReportID,
          })
        );
      } else {
        // Show a 404 if report isn't found.
        setInvalidState(true);
      }
    }, [
      entityName,
      loadedReportID,
      loading,
      projectName,
      props.history,
      userName,
      viewName,
    ]);

    if (invalidState) {
      return <NoMatch />;
    }

    return <Loader />;
  },
  {id: 'ReportPageEditOldInner'}
);

const ReportPageEditOld = makeComp(
  (props: ReportPageOldProps) => {
    const viewParam =
      QueryString.parse(window.location.search).view || 'New Report';
    const firstView = _.isArray(viewParam) ? viewParam[0] : viewParam;
    const [userName, viewName]: [string | null, string | null] =
      String.splitOnce(firstView, '/');

    useEffect(() => {
      // When users navigate to /:entity/:project/reports, redirect to .../reportlist.
      const {entityName, projectName} = props.match.params;
      if (userName == null || viewName == null) {
        props.history.replace(Urls.reportList({entityName, projectName}));
      }
    });

    if (userName == null || viewName == null) {
      // Can't call replace in render fn, so just show a loader until effect runs.
      return <Loader />;
    }

    return (
      <ReportPageEditOldInner
        {...props}
        userName={userName}
        viewName={viewName}
      />
    );
  },
  {id: 'ReportPageEditOld'}
);

export default ReportPageEditOld;
