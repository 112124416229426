import styled from 'styled-components';
import {SectionColumn} from './Section.styles';
import {TABLET_WIDTH} from '../css/globals.styles';

export const OnboardingContainer = styled.div`
  margin-bottom: 32px;

  @media (max-width: 520px) {
    margin-bottom: 24px;
  }
`;

export const HomeHeader = styled.div`
  text-align: center;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  @media (max-width: 520px) {
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

const HomeHeaderText = styled.h1`
  max-width: 480px;
  margin: 0 auto;
`;

export const HomeHeaderMainText = styled(HomeHeaderText as any)`
  font-size: 32px;

  @media (max-width: 520px) {
    font-size: 24px;
  }
`;

export const HomeHeaderSubText = styled(HomeHeaderText as any)`
  font-size: 20px;
  margin-top: 16px;
  line-height: 32px;

  @media (max-width: 520px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const HomePageSectionColumn = styled(SectionColumn)`
  @media (min-width: ${TABLET_WIDTH + 1}px) {
    &:nth-child(1) {
      width: 312px;
    }

    &:nth-child(2) {
      width: 576px;
    }
  }
`;
