import '../css/PanelBarChart.less';

import React from 'react';
import * as _ from 'lodash';
import memoize from 'memoize-one';
import useResizeObserver from 'use-resize-observer';

import * as Panels from '../util/panels';
import * as Query from '../util/queryts';

import {useCallback} from 'react';

import {
  QueryToRunsDataQueryParams,
  toRunsDataQuery,
  RunsDataQuery,
} from '../containers/RunsDataLoader';
import {Tab, Checkbox} from 'semantic-ui-react';
import ProjectFieldSelector from './ProjectFieldSelector';
import WandbLoader from './WandbLoader';
import {
  getPointsFromData,
  overrideBarColors,
  overrideBarTitles,
  PlotFontSizeOrAuto,
  ChartAreaOption,
  ChartAggOption,
} from '../util/plotHelpers';
import PanelError from './elements/PanelError';

import PlotWarning from './PlotWarning';
import RangeInput from './elements/RangeInput';
import LabeledOption from './elements/LabeledOption';
import PanelTitle from './elements/PanelTitle';
import PanelGroupingOptions from './PanelGroupingOptions';
import PanelChartOptions from './PanelChartOptions';
import PanelExpressionOptions, {
  parseExpressions,
  getExpressionFields,
} from './PanelExpressionOptions';
import {Expression} from '../util/expr';
import {useRunsData} from '../state/runs/hooks';

import * as Run from '../util/runs';
import * as Filters from '../util/filters';
import {
  getDefaultLegendTemplate,
  legendTemplateFieldNames,
} from '../util/legend';

import * as InteractStateActions from '../state/views/interactState/actions';
import * as InteractStateContext from '../state/views/interactState/context';

import PanelLegend from './PanelLegend';

import BarChart from './vis/BarChart';
import ModifiedDropdown from './elements/ModifiedDropdown';
import makeComp from '../util/profiler';

import {useSampleAndQueryToTable} from '../components/Export';

const PANEL_TYPE = 'Bar Chart';
const DEFAULT_MAX_GROUP_RUNS = 1000;
const ABSOLUTE_MAX_GROUP_RUNS = 1000;
const DEFAULT_BAR_LIMIT = 10;

export type PlotStyle = 'bar' | 'boxplot' | 'violin';

export function isPlotStyle(s: string): s is PlotStyle {
  return s === 'bar' || s === 'boxplot' || s === 'violin';
}

export interface BarChartConfig {
  metrics?: string[];
  chartTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  xAxisMin?: number;
  xAxisMax?: number;
  limit?: number; // max number of runs or groups to show
  barLimit?: number; // max number of bars to show - relevant if multiple metrics used
  aggregate?: boolean; // panel level grouping
  aggregateMetrics?: boolean;
  groupBy?: string; // panel level groupBy
  groupRunsLimit?: number;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  plotStyle?: PlotStyle;
  vertical?: boolean;
  legendFields?: string[];
  legendTemplate?: string; // used to generate the default legend
  colorEachMetricDifferently?: boolean; // if we have multiple metrics, override the run colors - NOT USED
  overrideSeriesTitles?: {[key: string]: string};
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  expressions?: string[];
  fontSize?: PlotFontSizeOrAuto;
}

type PanelBarChartProps = Panels.PanelProps<BarChartConfig>;

const getRunSets = (query: Query.Query) => {
  return query.runSets != null
    ? query.runSets.map(rs => ({
        id: rs.id,
        grouping: rs.grouping,
        entityName: rs.entityName,
        projectName: rs.projectName,
      }))
    : [];
};

const defaultTitle = (config: BarChartConfig) => {
  if (config.metrics == null) {
    return '';
  }

  return config.metrics
    .map(m => {
      const key = Run.keyFromString(m);
      return key != null ? Run.keyDisplayName(key, true) : m;
    })
    .join(', ');
};

const metricStringsToKeys = (metrics: string[]) => {
  // metrics should be in the form config:metricName, if not we assume they
  // are summary metrics.
  const metricKeys = (metrics || []).map(metricStr => {
    if (metricStr.includes(':')) {
      return (
        Run.keyFromString(metricStr) ||
        ({section: 'summary', name: metricStr} as Run.Key) // shouldn't happen
      );
    } else {
      return {section: 'summary', name: metricStr} as Run.Key;
    }
  });
  return metricKeys;
};

const warnMaxPlotBarsShowingLimit = (maxRuns: number, plotUnit: string) => {
  return (
    <PlotWarning
      helpText={
        'Click edit (top right corner) to change the max number of ' +
        plotUnit +
        ' displayed'
      }
      message={'Showing first ' + maxRuns + ' ' + plotUnit}
    />
  );
};

const warnRunSamplingWhenGroupedLimit = (maxRunsSampled: number) => {
  return (
    <PlotWarning
      helpText={
        'Click edit (top right corner) to change the number of runs sampled in group metrics'
      }
      message={'Computing group metrics from first ' + maxRunsSampled + ' runs'}
    />
  );
};

const PanelBarChart: React.FC<PanelBarChartProps> = makeComp(
  props => {
    const [runHighlight, setInternalRunHighlight] = React.useState<
      string | null
    >(null);

    const setHighlight = InteractStateContext.useInteractStateAction(
      InteractStateActions.setHighlight
    );

    const memParseExpressions = useCallback(memoize(parseExpressions), [
      props.config.expressions,
    ]);

    const parsedExpressions = memParseExpressions(props.config.expressions);

    const panelQuery = barChartTransformQuery(
      props.pageQuery,
      props.config,
      parsedExpressions
    );
    const runsDataQuery = useRunsData(panelQuery);
    const {data} = runsDataQuery;

    const elementRef = React.useRef<HTMLDivElement>(null);
    const {height = 1} = useResizeObserver<HTMLDivElement>({
      ref: elementRef,
    });

    const setRunHighlight = (newRunHighlight: string | null) => {
      if (newRunHighlight == null) {
        setInternalRunHighlight(null);
        setHighlight('run:name', undefined);
      } else {
        setInternalRunHighlight(newRunHighlight);
        setHighlight('run:name', newRunHighlight);
      }
    };

    const {config} = props;

    const runSets = getRunSets(props.pageQuery);

    // We need to memoize this per component instance, rather than globally.
    // Matches logic in PanelRunsLinePlot
    const memGetPointsFromData = useCallback(
      memoize(getPointsFromData, (a: any, b: any) => {
        return a[0] === b[0] && _.isEqual(a[1], b[1]);
      }),
      [getPointsFromData]
    );

    const singleRun = Panels.isSingleRun(props);

    const defaultLegendTemplate = getDefaultLegendTemplate(
      singleRun,
      Panels.isGrouped(props.pageQuery, config) ?? false,
      false,
      config.legendFields ?? [],
      config.metrics ?? [],
      runSets != null && runSets.length > 1,
      config.aggregateMetrics ?? false,
      'bar'
    );

    let barData = memGetPointsFromData(data.filtered, {
      metricKeys: metricStringsToKeys(config.metrics ?? []),
      customRunColors: props.customRunColors,
      groupBy: config.groupBy ?? 'None',
      panelAggregate: config.aggregate,
      aggregateMetrics: config.aggregateMetrics ?? false,
      groupAgg: config.groupAgg,
      groupArea: config.groupArea,
      runSets,
      aggregateCalculations: [],
      colorEachMetricDifferently:
        config.colorEachMetricDifferently ?? singleRun,
      boxPlot: config.plotStyle === 'boxplot',
      violinPlot: config.plotStyle === 'violin',
      legendTemplate: config.legendTemplate ?? defaultLegendTemplate,
      expressions: parsedExpressions.expressions ?? [],
    });

    const memOverrideColors = useCallback(memoize(overrideBarColors), [
      overrideBarColors,
    ]);

    const memOverrideTitles = useCallback(memoize(overrideBarTitles), [
      overrideBarTitles,
    ]);

    if (config.overrideSeriesTitles != null) {
      barData = memOverrideTitles(
        barData,
        config.overrideSeriesTitles,
        !singleRun
      );
    }

    if (config.overrideColors != null) {
      barData = memOverrideColors(barData, config.overrideColors, !singleRun);
    }

    const renderConfig = () => {
      if (!data) {
        return <></>;
      }

      const dataTab = (
        <Tab.Pane as="div" className="form-grid">
          <LabeledOption
            label="Metric"
            option={
              <ProjectFieldSelector
                className="with-button"
                query={props.pageQuery}
                columns={['summary_metrics', 'config']}
                types={['number']}
                defaultKeys={[]}
                multi
                autoPick
                selection
                value={props.config.metrics}
                setValue={value => props.updateConfig({metrics: value})}
                searchByKeyAndText
              />
            }
          />
          <LabeledOption
            label="Range"
            option={
              <RangeInput
                disabled={
                  props.config.metrics == null ||
                  props.config.metrics.length === 0
                }
                onMinChange={newVal => {
                  props.updateConfig({xAxisMin: newVal});
                }}
                onMaxChange={newVal => {
                  props.updateConfig({xAxisMax: newVal});
                }}
                minValue={props.config.xAxisMin}
                maxValue={props.config.xAxisMax}
              />
            }
          />
          <LabeledOption
            label="Vertical"
            helpText="Plot vertical lines"
            option={
              <Checkbox
                toggle
                checked={config.vertical}
                onClick={(e, value) =>
                  props.updateConfig({
                    vertical: value.checked,
                  })
                }
              />
            }
          />
          <LabeledOption
            label={`Max runs to show`}
            option={
              <ModifiedDropdown
                lazyLoad
                className="no-wrap-options"
                value={config.limit || 10}
                style={{minWidth: 60}}
                selection
                options={[2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 50, 100].map(
                  n => ({
                    value: n,
                    text: `${n} runs`,
                  })
                )}
                onChange={(e, {value}) => {
                  if (typeof value === 'number') {
                    props.updateConfig({
                      limit: value,
                    });
                  }
                }}
              />
            }
          />
          {((config.metrics?.length && config.metrics?.length >= 2) ||
            barData.length > (config.barLimit ?? DEFAULT_BAR_LIMIT)) && (
            <LabeledOption
              label={`Max bars to show`}
              option={
                <ModifiedDropdown
                  lazyLoad
                  className="no-wrap-options"
                  value={config.barLimit ?? DEFAULT_BAR_LIMIT}
                  style={{minWidth: 60}}
                  selection
                  options={[
                    2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 50, 100, 1000,
                  ].map(n => ({
                    value: n,
                    text: `${n} bars`,
                  }))}
                  onChange={(e, {value}) => {
                    if (typeof value === 'number') {
                      props.updateConfig({
                        barLimit: value,
                      });
                    }
                  }}
                />
              }
            />
          )}
        </Tab.Pane>
      );

      const legendTab = (
        <Tab.Pane as="div" className="form-grid">
          <PanelLegend
            type={'bars'}
            config={config}
            updateConfig={props.updateConfig}
            pageQuery={props.pageQuery}
            defaultTitle={defaultTitle(props.config)}
            editableLegendSeries={barData}
            defaultLegendTemplate={defaultLegendTemplate}
            singleRun={singleRun}
          />
        </Tab.Pane>
      );

      const expressionsTab = (
        <Tab.Pane as="div" className="form-grid">
          <PanelExpressionOptions
            type={'bars'}
            config={config}
            updateConfig={props.updateConfig}
            availableExpressionVarNames={[]}
            exampleIdentifier={Run.rawConfigKeyDisplayName(
              (config.metrics ? config.metrics[0] : undefined) ?? 'summary:acc'
            )}
          />
        </Tab.Pane>
      );

      const chartTab = (
        <Tab.Pane as="div" className="form-grid">
          <PanelChartOptions
            type={'bars'}
            config={config}
            updateConfig={props.updateConfig}
            pageQuery={props.pageQuery}
          />
        </Tab.Pane>
      );

      const settingsPanes = [
        {
          menuItem: 'Data',
          render: () => dataTab,
        },
        {
          menuItem: 'Grouping',
          render: () => groupingTab,
        },
        {
          menuItem: 'Chart',
          render: () => chartTab,
        },
        {
          menuItem: 'Legend',
          render: () => legendTab,
        },
        {
          menuItem: 'Expressions',
          render: () => expressionsTab,
        },
      ];

      const groupingTab = (
        <Tab.Pane as="div" className="form-grid">
          <PanelGroupingOptions
            type={'bars'}
            config={config}
            disabled={!config.metrics || config.metrics.length === 0}
            isGrouped={Panels.isGrouped(props.pageQuery, config) || false}
            updateConfig={props.updateConfig}
            defaultMaxGroupRuns={DEFAULT_MAX_GROUP_RUNS}
            absoluteMaxGroupRuns={ABSOLUTE_MAX_GROUP_RUNS}
            totalRuns={data.totalRuns}
            usingExpressions={false}
            pageQuery={props.pageQuery}
            singleRun={singleRun}
          />
        </Tab.Pane>
      );

      return (
        <div className="chart-modal">
          <div className="chart-preview">{renderGraph()}</div>
          <div className="chart-settings">
            <Tab
              panes={settingsPanes}
              menu={{
                secondary: true,
                pointing: true,
                className: 'chart-settings-tab-menu',
              }}
            />
          </div>
        </div>
      );
    };

    const memWarnRunSamplingWhenGroupedLimit = useCallback(
      memoize(warnRunSamplingWhenGroupedLimit),
      [warnRunSamplingWhenGroupedLimit]
    );
    const memWarnMaxPlotBarsShowingLimit = useCallback(
      memoize(warnMaxPlotBarsShowingLimit),
      [warnMaxPlotBarsShowingLimit]
    );

    const renderGraph = () => {
      let errorMessage;

      const maxBars = config.barLimit ?? DEFAULT_BAR_LIMIT;

      let legendPrefix;

      if (Panels.isGrouped(props.pageQuery, config)) {
        // show user warning about group subsampling when the total runs visible in the table
        // exceed the number of runs sampled to compute group metrics in the chart
        if (
          props.data.totalRuns >
          (config.groupRunsLimit ?? DEFAULT_MAX_GROUP_RUNS)
        ) {
          legendPrefix = memWarnRunSamplingWhenGroupedLimit(
            config.groupRunsLimit ?? DEFAULT_MAX_GROUP_RUNS
          );
          // show user warning about max groups if the total groups visible in the table exceed
          // the maximum number of runs displayed in the chart--note this happens less often and is
          // easier to notice than the previous case
        } else if (barData.length > maxBars) {
          legendPrefix = memWarnMaxPlotBarsShowingLimit(maxBars, 'bars');
        }
        // show user warning about plot line limit: there are more runs visible in the table than can be displayed
        // as plot lines in the chart
      } else if (barData.length > maxBars) {
        legendPrefix = memWarnMaxPlotBarsShowingLimit(maxBars, 'bars');
      }

      if (config.metrics == null || config.metrics.length === 0) {
        errorMessage = 'Select a metric to visualize in this line chart.';
      } else if (data.filtered.length === 0) {
        errorMessage = (
          <>
            Select runs that logged{' '}
            {config.metrics?.map(Run.rawConfigKeyDisplayName).join(',')} <br />
            to visualize data in this bar chart.
          </>
        );
      }

      const highlight = _.find(
        barData,
        point => point.uniqueId === runHighlight
      );

      const fontSize = Panels.getFontSize(config.fontSize ?? 'auto', height);

      return (
        <div className="chart" ref={elementRef}>
          <PanelTitle
            title={getTitleFromConfig(props.config)}
            searchQuery={props.searchQuery}
            fontSize={fontSize}
          />
          {props.loading && <WandbLoader />}
          {!props.loading && (
            <div className="chart-with-warning">
              {legendPrefix}

              <div className="chart-content">
                {!data.loading && errorMessage != null ? (
                  <PanelError message={errorMessage} />
                ) : (
                  <>
                    <BarChart
                      bars={barData}
                      vertical={config.vertical}
                      boxPlot={
                        Panels.isGrouped(props.pageQuery, config) &&
                        config.plotStyle === 'boxplot'
                      }
                      violinPlot={
                        Panels.isGrouped(props.pageQuery, config) &&
                        config.plotStyle === 'violin'
                      }
                      maxBars={maxBars}
                      showAllLabels={true}
                      min={config.xAxisMin}
                      max={config.xAxisMax}
                      highlight={highlight}
                      mouseOver={(event, bar) =>
                        setRunHighlight(bar.uniqueId || null)
                      }
                      fontSize={fontSize}
                      mouseOut={() => setRunHighlight(null)}></BarChart>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      );
    };

    if (props.configMode) {
      return renderConfig();
    } else {
      return renderGraph();
    }
  },
  {id: 'PanelBarChart'}
);

const barChartTransformQuery = (
  query: Query.Query,
  config: BarChartConfig,
  parsedExpressions: {
    expressions?: Expression[];
    xExpressions?: Expression;
  }
) => {
  const queryToDataQueryParams: QueryToRunsDataQueryParams = {
    selectionsAsFilters: true,
  };

  const expressionFields = getExpressionFields(parsedExpressions);

  const metricKeys = metricStringsToKeys(config.metrics || []);

  if (config.metrics && config.metrics.length > 0) {
    const filters: Filters.Filter[] = metricKeys.map(metricKey => ({
      key: metricKey,
      op: '!=',
      value: null,
    }));
    const mergeFilters: Filters.Filter = Filters.Or(filters);
    queryToDataQueryParams.mergeFilters = mergeFilters;
  }

  const transformed = toRunsDataQuery(query, queryToDataQueryParams);

  let legendFields = config.legendFields || [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(
      templateFields,
      config.legendFields || []
    );
    legendFields = legendFields.concat(extraLegendFields);
  }

  let displayFields = [
    ...legendFields.map(Run.keyFromString),
    ...(query.grouping || []),
    config.aggregate && config.groupBy
      ? Run.key('config', config.groupBy)
      : null,
  ];

  if (query.runSets != null) {
    query.runSets.forEach(rs => {
      if (rs.grouping) {
        displayFields = displayFields.concat(rs.grouping);
      }
    });
  }

  const extraFields = _.uniq(_.concat(displayFields, expressionFields));

  // And then add them into the correct query fields.
  transformed.configKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'config')
    .map(key => key!.name);
  // Have to concat here to preserve the config key we added above.
  transformed.summaryKeys = extraFields
    .concat(metricKeys ?? [])
    .filter(key => key != null && key.section === 'summary')
    .map(key => key!.name);

  transformed.page = {
    size: config.limit ?? 10,
  };

  if (Panels.isGrouped(query, config)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // optionally compute group statistics over all runs instead of sub-sampling
    transformed.page.size = config.groupRunsLimit ?? DEFAULT_MAX_GROUP_RUNS;
    // Disable grouping for this query, we'll do it ourselves.
    transformed.queries = transformed.queries.map(q => ({...q, grouping: []}));
  }

  return transformed;
};

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state. So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query.Query,
  config: BarChartConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query.Query, config: BarChartConfig): any => {
  const query = barChartTransformQuery(
    pageQuery,
    config,
    parseExpressions(config.expressions, undefined)
  );

  return useSampleAndQueryToTable(query, pageQuery, config);
};

function getTitleFromConfig(config: BarChartConfig): string {
  return config.chartTitle || defaultTitle(config);
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, BarChartConfig> = {
  type: PANEL_TYPE,
  Component: PanelBarChart,
  getTitleFromConfig,
  transformQuery: transformQuerySkip,
  useTableData,
  exportable: {
    image: true,
    csv: true,
    api: true,
  },
  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    xAxisMin: {editor: 'number', displayName: 'X min'},
    xAxisMax: {editor: 'number', displayName: 'X max'},
  },
  icon: 'panel-bar-chart',
};
