/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'rundata-histogram',
  name: 'Histogram (run data)',
  description:
    'A Vega histogram over run data. You can change the binning behavior in real-time using the sliders.',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    padding: 5,

    signals: [
      {
        name: 'binOffset',
        value: 0,
        bind: {input: 'range', min: -0.1, max: 0.1},
      },
      {
        name: 'binStep',
        value: 0.1,
        bind: {input: 'range', min: 0.001, max: 0.4, step: 0.001},
      },
    ],

    data: [
      {
        name: 'binned',
        source: 'runs',
        transform: [
          {
            type: 'extent',
            field: '${run-field:metric}',
            signal: 'extent',
          },
          {
            type: 'bin',
            field: '${run-field:metric}',
            extent: {signal: 'extent'},
            anchor: {signal: 'binOffset'},
            step: {signal: 'binStep'},
            nice: false,
          },
          {
            type: 'aggregate',
            key: 'bin0',
            groupby: ['bin0', 'bin1'],
            fields: ['bin0'],
            ops: ['count'],
            as: ['count'],
          },
        ],
      },
    ],

    title: {
      text: 'Histogram (run data)',
    },

    scales: [
      {
        name: 'xscale',
        type: 'linear',
        range: 'width',
        domain: {signal: 'extent'},
      },
      {
        name: 'yscale',
        type: 'linear',
        range: 'height',
        round: true,
        domain: {data: 'binned', field: 'count'},
        zero: true,
        nice: true,
      },
    ],

    axes: [
      {
        orient: 'bottom',
        scale: 'xscale',
        zindex: 1,
        title: '${run-field:metric}',
      },
      {orient: 'left', scale: 'yscale', tickCount: 5, zindex: 1},
    ],

    marks: [
      {
        type: 'rect',
        from: {data: 'binned'},
        encode: {
          update: {
            x: {scale: 'xscale', field: 'bin0'},
            x2: {
              scale: 'xscale',
              field: 'bin1',
              offset: {signal: 'binStep > 0.02 ? -0.5 : 0'},
            },
            y: {scale: 'yscale', field: 'count'},
            y2: {scale: 'yscale', value: 0},
            fill: {value: 'steelblue'},
          },
          hover: {fill: {value: 'firebrick'}},
        },
      },
      {
        type: 'rect',
        from: {data: 'runs'},
        encode: {
          enter: {
            x: {scale: 'xscale', field: '${run-field:metric}'},
            width: {value: 1},
            y: {value: 25, offset: {signal: 'height'}},
            height: {value: 5},
            fill: {value: 'steelblue'},
            fillOpacity: {value: 0.4},
          },
        },
      },
    ],
  },
};

export default def;
