import {ThunkResult} from '../../../types/redux';
import {Ref as DiscussionCommentRef} from './types';
import * as Generated from '../../../generated/graphql';
import * as DiscussionCommentActions from './actions';
import {parseUser} from '../api';

export function updateReportDiscussionComment(
  discussionCommentRef: DiscussionCommentRef,
  body: string,
  callback?: () => void
): ThunkResult<void> {
  return (dispatch, getState, client) => {
    const state = getState();
    const commentServerID =
      state.views.parts[discussionCommentRef.type][discussionCommentRef.id].id;

    client
      .mutate<Generated.UpdateDiscussionCommentMutation>({
        mutation: Generated.UpdateDiscussionCommentDocument,
        variables: {discussionCommentID: commentServerID, body},
        refetchQueries: [],
      })
      .then(response => {
        const updatedComment =
          response.data?.updateDiscussionComment?.discussionComment;
        if (updatedComment != null) {
          dispatch(
            DiscussionCommentActions.updateDiscussionComment(
              discussionCommentRef,
              {
                ...updatedComment,
                poster: parseUser(updatedComment.poster!),
                updatedAt: updatedComment.updatedAt ?? undefined,
              }
            )
          );
          if (callback != null) {
            callback();
          }
        }
      });
  };
}
