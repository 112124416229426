import * as S from './MoveCloneProjectModal.styles';

import React, {useState} from 'react';
import {Button, Form, Icon, Message, Popup} from 'semantic-ui-react';
import makeComp from '../util/profiler';
import {
  useAllProjectNamesQuery,
  useCloneProjectsMutation,
  useMoveProjectsMutation,
  useTeamMembersQuery,
} from '../generated/graphql';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {toast} from './elements/Toast';
interface MoveCloneProjectModalProps {
  clone?: boolean;
  onClose(): void;
}

const MoveCloneProjectModal = makeComp(
  (props: MoveCloneProjectModalProps) => {
    const {clone} = props;
    const [projectName, setProjectName] = useState<string>('');
    const [allProjects, setAllProjects] = useState<boolean>(false);
    const [sourceEntityName, setSourceEntityName] = useState<string>('');
    const [destinationEntityName, setDestinationEntityName] =
      useState<string>('');
    const [ownerUsername, setOwnerUsername] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [err, setErr] = useState<string>('');

    const [moveProjects] = useMoveProjectsMutation({
      context: propagateErrorsContext,
    });
    const [cloneProjects] = useCloneProjectsMutation({
      context: propagateErrorsContext,
    });

    const {data} = useAllProjectNamesQuery({
      variables: {entityName: sourceEntityName, first: 10000},
      fetchPolicy: 'cache-and-network',
    });
    const projects = data?.projects?.edges.map(p => p.node) ?? [];
    const projectOptions = projects.map(p => {
      return {name: p!.name, value: p!.name, text: p!.name};
    });

    const memberData = useTeamMembersQuery({
      variables: {entityName: destinationEntityName},
    });
    const teamMembers = memberData?.data?.entity?.members;
    const memberOptions = teamMembers?.map(m => {
      return m && m.username
        ? {name: m.username, value: m.username, text: m.username}
        : {};
    });

    const onClose = () => {
      setProjectName('');
      setDestinationEntityName('');
      setSourceEntityName('');
      setOwnerUsername('');
      setNotes('');
      setErr('');
      setAllProjects(false);
      props.onClose();
    };

    const onSubmitHandler = async () => {
      try {
        if (
          (projectName === '' && !allProjects) ||
          sourceEntityName === '' ||
          destinationEntityName === '' ||
          ownerUsername === ''
        ) {
          setErr('Error: Fill out all the required fields.');
          return;
        }

        let projectNames;
        if (!allProjects) {
          projectNames = [projectName];
        }

        const vars = {
          variables: {
            projectNames,
            sourceEntityName,
            destinationEntityName,
            ownerUsername,
            notes,
          },
        };

        window.analytics.track(
          (clone ? 'Clone' : 'Move') + ' project button clicked',
          vars
        );

        if (clone) {
          const result = await cloneProjects(vars);
          if (result.errors != null) {
            setErr(`Error cloning project`);
            return;
          }

          toast('Cloning projects task received');
        } else {
          const result = await moveProjects(vars);
          if (result.errors != null) {
            setErr(`Error moving project`);
            return;
          }

          toast('Moving projects task received');
        }
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
        setErr('Error sending task: ' + errMsg);
      }

      onClose();
    };

    const content = (
      <S.FormWrapper>
        <Form>
          <Form.Input
            data-test="source-entity-name"
            label={
              <TitleWithPopup
                title={'Source entity name'}
                content={
                  <span>
                    If you’re {clone ? 'cloning' : 'moving'} a project{' '}
                    <S.BoldSpan>from</S.BoldSpan> an individual user, enter
                    their username.{' '}
                    {clone ? (
                      <span>
                        If you’re cloning a project{' '}
                        <S.BoldSpan>from</S.BoldSpan> a team, enter the team's
                        name.
                      </span>
                    ) : (
                      'Note: moving a project from a team is not supported yet.'
                    )}
                  </span>
                }
              />
            }
            onChange={(e, {value}) => {
              setSourceEntityName(value);
            }}
            value={sourceEntityName}
          />
          <Form.Dropdown
            data-test="project-name"
            selection
            required
            search
            disabled={allProjects}
            label={
              <S.TitleSpan>
                Project<S.Required>*</S.Required>
              </S.TitleSpan>
            }
            options={projectOptions}
            value={projectName}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setProjectName(value);
              }
            }}
          />
          <Form.Checkbox
            label="All Projects"
            data-test="all-projects"
            checked={allProjects}
            onChange={() => {
              setAllProjects(prev => !prev);
            }}
          />
          <Form.Input
            data-test="destination-entity-name"
            required
            label={
              <TitleWithPopup
                title={'Destination entity name'}
                content={
                  <span>
                    If you’re {clone ? 'cloning' : 'moving'} a project{' '}
                    <S.BoldSpan>to</S.BoldSpan> an individual user, enter their
                    username.{' '}
                    {clone ? (
                      <span>
                        If you’re cloning a project <S.BoldSpan>to</S.BoldSpan>{' '}
                        a team, enter the team's name.
                      </span>
                    ) : (
                      'Note: moving a project to a team is not supported yet.'
                    )}
                  </span>
                }
              />
            }
            onChange={(e, {value}) => {
              setDestinationEntityName(value);
            }}
            value={destinationEntityName}
          />
          <Form.Dropdown
            data-test="new-owner-username"
            selection
            search
            label={
              <TitleWithPopup
                title={"New owner's username"}
                content={
                  <span>
                    If you’re {clone ? 'cloning' : 'moving'} a project{' '}
                    <S.BoldSpan>to</S.BoldSpan> an individual user, enter their
                    username again.{' '}
                    <span>
                      If you’re cloning a project <S.BoldSpan>to</S.BoldSpan> a
                      team, enter the username of a member of that team.
                    </span>
                  </span>
                }
              />
            }
            options={memberOptions}
            value={ownerUsername}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                if (memberOptions) {
                  setOwnerUsername(value);
                }
              }
            }}
          />
          <Form.Input
            data-test="notes"
            label={<S.TitleSpan>Notes</S.TitleSpan>}
            value={notes}
            onChange={(e, {value}) => {
              setNotes(value);
            }}
          />
        </Form>
      </S.FormWrapper>
    );

    const warningMessage = (
      <div className="ui warning message slow-warning">
        <S.WarningWrapper>
          <S.WarningIconWrapper>
            <LegacyWBIcon name="warning" />
          </S.WarningIconWrapper>
          Warning: {clone && 'Cloning project does not clone artifacts. '}
          You cannot undo this action.
        </S.WarningWrapper>
      </div>
    );

    return (
      <S.ContentWrapper>
        {warningMessage}
        {content}
        <Message hidden={err === ''} error>
          {err}
        </Message>
        <S.ButtonWrapper>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSubmitHandler} primary>
            {clone ? 'Clone' : 'Move'}
          </Button>
        </S.ButtonWrapper>
      </S.ContentWrapper>
    );
  },
  {id: 'MoveCopyProject'}
);

interface TitleWithPopupProps {
  title: string;
  content: JSX.Element;
}

const TitleWithPopup: React.FC<TitleWithPopupProps> = (
  props: TitleWithPopupProps
) => {
  return (
    <S.TitleSpan>
      {props.title}
      <S.Required>*</S.Required>
      <Popup
        trigger={<Icon name="question circle" color="grey" />}
        content={props.content}
      />
    </S.TitleSpan>
  );
};

export default MoveCloneProjectModal;
