import styled from 'styled-components';
import React from 'react';
import {Redirect} from 'react-router';
import {Loader, Segment} from 'semantic-ui-react';
import {useArtifactCollectionQuery} from '../generated/graphql';
import makeComp from '../util/profiler';
import * as Urls from '../util/urls';

import Artifact from './Artifact';

const MarginSegment = styled(Segment)`
  margin: 20px !important;
`;

export const ArtifactView = makeComp<{
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  history: any;
}>(
  props => {
    const {
      entityName,
      projectName,
      artifactTypeName,
      artifactCollectionName,
      artifactTab,
      artifactCommitHash,
      filePath,
      history,
    } = props;

    const q2 = useArtifactCollectionQuery({
      skip: artifactCollectionName == null,
      variables: {
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: artifactCollectionName ?? '',
      },
    });

    if (q2.loading) {
      return <Loader />;
    }

    const artifactCollection = q2.data?.project?.artifactType?.artifactSequence;
    const artifacts = artifactCollection?.artifacts.edges.map(n => n.node);
    if (artifacts == null || artifacts?.length === 0) {
      return <MarginSegment>No artifacts have been created yet.</MarginSegment>;
    }

    const firstArtifact = artifacts[0];
    if (firstArtifact.commitHash == null) {
      return (
        <MarginSegment>No commit hashes have been created yet.</MarginSegment>
      );
    }

    if (artifactCommitHash == null) {
      const url = Urls.artifactSubpage({
        entityName,
        projectName,
        artifactTypeName,
        artifactSequenceName: artifactCollectionName ?? '',
        artifactCommitHash: firstArtifact.commitHash,
      });
      return <Redirect to={url} />;
    }

    return (
      <Artifact
        entityName={entityName}
        projectName={projectName}
        artifactTypeName={artifactTypeName}
        artifactSequenceName={artifactCollectionName!}
        artifactCommitHash={artifactCommitHash}
        artifactTab={artifactTab}
        filePath={filePath}
        history={history}
        refetch={() => q2.refetch()}
      />
    );
  },
  {id: 'ArtifactsView'}
);
