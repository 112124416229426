import React from 'react';
import {Link} from '../util/links';
import makeComp from '../util/profiler';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as S from './NudgeBar.styles';

type NudgeBarProps = {
  content: React.ReactNode;
  linkText?: string | null;
  link?: string;
  showDismissOption?: boolean;
  inline?: true;
  onClickLink?: () => void;
  onClose?: () => void;
};

const NudgeBar: React.FC<NudgeBarProps> = makeComp(
  (props: NudgeBarProps) => {
    return (
      <>
        <S.Nudge inline={props.inline}>
          <S.NudgeInner>
            <S.NudgeMessage>{props.content}</S.NudgeMessage>
            {props.linkText != null && props.link != null && (
              <S.NudgeLink>
                <Link
                  className="nudge-info-link"
                  to={props.link}
                  onClick={props.onClickLink}>
                  {props.linkText}
                </Link>
              </S.NudgeLink>
            )}
          </S.NudgeInner>
          <S.NudgeDismiss>
            {props.showDismissOption != null && (
              <LegacyWBIcon
                name="close"
                className="close-button"
                onClick={props.onClose != null ? props.onClose : () => ({})}
                size="large"
              />
            )}
          </S.NudgeDismiss>
        </S.Nudge>
      </>
    );
  },
  {id: 'NudgeBar', memo: true}
);

export default NudgeBar;
