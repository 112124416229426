import * as globals from '../css/globals.styles';

import * as React from 'react';
import {useCallback} from 'react';
import {Button, Item, Segment} from 'semantic-ui-react';

import {captureError} from '../util/integrations';

import {useDispatch} from '../state/hooks';
import * as WorkspaceActions from '../state/workspaces/actions';
import makeComp from '../util/profiler';

interface WorkspaceErrorHandlerProps {
  workspaceID: string;
  viewType: 'project-view' | 'sweep-view' | 'group-view' | 'run-view';
  children: JSX.Element;
}

interface WorkspaceErrorHandlerState {
  hasError: boolean;
}

class WorkspaceErrorHandlerComp extends React.Component<
  WorkspaceErrorHandlerProps & ReturnType<typeof useWorkspaceErrorHandlerProps>,
  WorkspaceErrorHandlerState
> {
  state = {hasError: false};

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({hasError: true});

    captureError(error, 'WorkspaceErrorHandlerComp_componentdidcatch', {
      extra: Object.assign(info, {
        viewtype: this.props.viewType,
      }),
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Segment placeholder textAlign="center" padded="very">
          <Item.Group style={{display: 'inline-block'}}>
            <Item>
              <Item.Content
                verticalAlign="middle"
                style={{
                  textAlign: 'left',
                  maxWidth: 430,
                }}>
                <Item.Header
                  style={{color: globals.gray500, fontWeight: 'normal'}}>
                  There was a problem rendering these panels.
                </Item.Header>
                <Item.Description style={{color: globals.gray500}}>
                  Click this button to reset your workspace to the default
                  layout.
                  <br />
                  This preserves runs, but custom charts will be lost.
                  <Button
                    primary
                    style={{marginTop: 16, marginLeft: 0}}
                    onClick={() => {
                      this.props.fixView();
                      this.setState({hasError: false});
                    }}>
                    Reset my workspace
                  </Button>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      );
    } else {
      return this.props.children;
    }
  }
}

function useWorkspaceErrorHandlerProps(props: WorkspaceErrorHandlerProps) {
  const {workspaceID} = props;
  const dispatch = useDispatch();
  const fixView = useCallback(
    () => dispatch(WorkspaceActions.fixView(workspaceID)),
    [dispatch, workspaceID]
  );

  return {fixView};
}

const WorkspaceErrorHandler = makeComp(
  (props: WorkspaceErrorHandlerProps) => {
    const errorProps = useWorkspaceErrorHandlerProps(props);
    return <WorkspaceErrorHandlerComp {...props} {...errorProps} />;
  },
  {id: 'WorkspaceErrorHandler'}
);

export default WorkspaceErrorHandler;
