import React from 'react';

import {ImageIconName} from '../ImageIcon';
import FancyPageSidebarSection from './FancyPageSidebarSection';
import makeComp from '../../util/profiler';

export interface FancyPageSidebarItem {
  iconName: ImageIconName;
  selectedIconName?: ImageIconName;
  name: string;
  slug?: string;
  internalLink?: string;
  externalLink?: string;
  render?: () => React.ReactNode;

  // Enables multiple sections with dividers in between. Undefined = 0.
  sectionIndex?: number;
}

interface FancyPageSidebarProps {
  selectedItem: FancyPageSidebarItem;
  items: FancyPageSidebarItem[];
  baseUrl: string;
  defaultIndex?: number;
}

const FancyPageSidebar: React.FC<FancyPageSidebarProps> = makeComp(
  props => {
    if (props.items.length < 2) {
      return <></>;
    }

    const sections = new Set<number>();
    props.items.forEach(item => {
      sections.add(item.sectionIndex || 0);
    });

    return (
      <div className={`fancy-page__sidebar`}>
        <div className="fancy-page__sidebar__sections">
          {Array.from(sections)
            .sort()
            .map((section, i) => (
              <React.Fragment key={'section__' + i}>
                {i !== 0 && (
                  <div className="fancy-page__sidebar__sections__divider" />
                )}
                <FancyPageSidebarSection
                  selectedItem={props.selectedItem}
                  items={props.items.filter(
                    item => section === (item.sectionIndex || 0)
                  )}
                  baseUrl={props.baseUrl}
                  defaultIndex={props.defaultIndex}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  },
  {id: 'FancyPageSidebar'}
);

export default FancyPageSidebar;
