import _ from 'lodash';
import React, {useState, useRef, useLayoutEffect} from 'react';
import {Button} from 'semantic-ui-react';

import {ValueOp} from '../../../util/filters';
import {Key as RunKey, keyToCss, Value as RunValue} from '../../../util/runs';
import {makePropsAreEqual} from '../../../util/shouldUpdate';
import {
  DFTableColumn,
  DFTableHoverCellCoords,
  DFTableRowInterface,
} from './DFTable';
import {DFTableCell} from './DFTableCell';
import makeComp from '../../../util/profiler';

interface DFTableRowProps {
  columns: DFTableColumn[];
  row: DFTableRowInterface;
  pinned?: boolean;
  recursionDepth: number;
  expandedRowAddresses: string[]; // list of expanded rows, an array of rowAddresses
  loadingRowAddresses: string[];
  childrenPaginationMap: {
    [key: string]: number | undefined;
  };
  hoverCellCoords: DFTableHoverCellCoords;
  expanded?: boolean;
  setHoverCellCoords(newCoords: DFTableHoverCellCoords): void;
  setChildrenPagination(rowAddress: string, activePage?: number): void;
  toggleExpandedRow(rowAddress: string): void; // expand or collapse this row
  addFilter(key: RunKey, op: ValueOp, value: RunValue): void;
}

export const DFTableRow: React.FC<DFTableRowProps> = makeComp(
  ({
    columns,
    row,
    recursionDepth,
    expandedRowAddresses,
    loadingRowAddresses,
    childrenPaginationMap,
    setChildrenPagination,
    hoverCellCoords,
    setHoverCellCoords,
    pinned,
    expanded,

    addFilter,
    toggleExpandedRow,
  }) => {
    const [changeAnimating, setChangeAnimating] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const nextRecursionDepthRef = useRef(recursionDepth + 1);
    const firstRenderRef = useRef(true);

    useLayoutEffect(() => {
      if (ref.current != null) {
        ref.current.addEventListener('animationend', () => {
          setChangeAnimating(false);
        });
      }
      // If this run was started recently, highlight it
      // TODO: Get rid of anys
      if (Date.now() - new Date((row as any).createdAt).getTime() < 30000) {
        setChangeAnimating(true);
      }
      // eslint-disable-next-line
    }, []);

    // comment out to disable row highlighting
    // TODO: Get rid of anys
    const rowHeartbeatAt = (row as any).heartbeatAt;
    useLayoutEffect(() => {
      if (firstRenderRef.current) {
        return;
      }
      setChangeAnimating(true);
    }, [rowHeartbeatAt]);
    useLayoutEffect(() => {
      firstRenderRef.current = false;
    }, []);

    const rowAddress = row.__address__;
    const childRows = row.__children__ || []; // // data && data.filtered;
    const loadingChildren = _.includes(loadingRowAddresses, rowAddress);

    /* Children Pagination */
    const childrenPageSize = 10;
    const childrenActivePage = childrenPaginationMap[rowAddress] || 1;
    const childrenTotalPages = Math.ceil(childRows.length / childrenPageSize);
    const childrenSliceStart = (childrenActivePage - 1) * childrenPageSize;
    const childrenSliceEnd = childrenSliceStart + childrenPageSize;

    const isExpanded = _.includes(expandedRowAddresses, rowAddress);
    // TODO: Get rid of any
    const isGroup = (row as any).groupCounts != null || childRows.length > 0;

    const hoveringRow = hoverCellCoords && hoverCellCoords[0] === rowAddress;

    return (
      <React.Fragment>
        <div
          ref={ref}
          className={`df-tree-cell-row-background
            ${hoveringRow ? 'df-tree-cell--hovering' : ''}
            ${changeAnimating ? 'highlight-change-animation' : ''}
          `}
        />
        <div className="df-tree-padding" />
        {columns.map((column, columnIndex) => {
          const columnKey = column.key;
          const className = `df-tree-cell
            df-tree-cell--column-${keyToCss(columnKey)}
            df-tree-cell--column-${columnIndex}
            df-tree-cell--row-${rowAddress}
          `;
          return (
            <DFTableCell
              key={`${rowAddress}-${column.accessor}`}
              className={className}
              column={column}
              row={row}
              loadingChildren={loadingChildren}
              recursionDepth={recursionDepth}
              isGroup={isGroup}
              isExpanded={isExpanded}
              addFilter={addFilter}
              toggleExpandedRow={toggleExpandedRow}
              hoveringRow={hoveringRow}
              expanded={expanded}
              cellHoverProps={{
                onMouseEnter: () => {
                  setHoverCellCoords([rowAddress, keyToCss(columnKey)]);
                },
                onMouseLeave: () => {
                  setHoverCellCoords([]);
                },
              }}
            />
          );
        })}
        <div className="df-tree-padding" />

        {isExpanded &&
          childRows &&
          /* GROUPS */
          isGroup && (
            <React.Fragment>
              {childRows
                .slice(childrenSliceStart, childrenSliceEnd)
                .map(childRow => {
                  return (
                    <DFTableRow
                      key={childRow.__address__}
                      columns={columns}
                      row={childRow}
                      pinned={pinned}
                      expandedRowAddresses={expandedRowAddresses}
                      loadingRowAddresses={loadingRowAddresses}
                      toggleExpandedRow={toggleExpandedRow}
                      recursionDepth={nextRecursionDepthRef.current}
                      addFilter={addFilter}
                      setHoverCellCoords={setHoverCellCoords}
                      expanded={expanded}
                      childrenPaginationMap={childrenPaginationMap}
                      setChildrenPagination={setChildrenPagination}
                      hoverCellCoords={hoverCellCoords}
                    />
                  );
                })}

              {childrenTotalPages > 1 && (
                <div
                  className="df-tree-cell df-tree-pagination"
                  style={{
                    gridColumn: `2 / -1`,
                    paddingLeft:
                      57 +
                      (expanded ? 18 : 0) +
                      nextRecursionDepthRef.current * 18,
                  }}>
                  {pinned && (
                    <Button.Group>
                      <Button
                        size="tiny"
                        icon="chevron left"
                        disabled={childrenActivePage < 2}
                        onClick={() => {
                          setChildrenPagination(
                            rowAddress,
                            childrenActivePage - 1
                          );
                        }}
                      />
                      <Button>
                        {`${childrenSliceStart + 1}-${Math.min(
                          childRows.length,
                          childrenSliceEnd
                        )} of ${childRows.length}`}
                      </Button>
                      <Button
                        size="tiny"
                        icon="chevron right"
                        disabled={childrenActivePage >= childrenTotalPages}
                        onClick={() => {
                          setChildrenPagination(
                            rowAddress,
                            childrenActivePage + 1
                          );
                        }}
                      />
                    </Button.Group>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
      </React.Fragment>
    );
  },
  {
    id: 'DFTableRow',
    memo: (prevProps, nextProps) => {
      const {row, expandedRowAddresses, loadingRowAddresses, hoverCellCoords} =
        prevProps;

      const isHoveringRowOrChild = (
        rowAddressInner: string,
        hoverCellCoordsInner: DFTableHoverCellCoords
      ) => {
        return (
          hoverCellCoordsInner &&
          hoverCellCoordsInner[0] &&
          (hoverCellCoordsInner[0] === rowAddressInner ||
            (hoverCellCoordsInner[0] as string).startsWith(
              rowAddressInner + '-'
            ))
        );
      };

      const rowAddress = row.__address__;
      if (loadingRowAddresses !== nextProps.loadingRowAddresses) {
        if (
          !_.isEqual(
            loadingRowAddresses.filter(ra => _.startsWith(ra, rowAddress)), // child row addresses are prefixed with rowAddress
            nextProps.loadingRowAddresses.filter(ra =>
              _.startsWith(ra, rowAddress)
            )
          )
        ) {
          return false;
        }
      }
      // re-render if user has expanded/closed this row or any child row
      if (expandedRowAddresses !== nextProps.expandedRowAddresses) {
        if (
          !_.isEqual(
            expandedRowAddresses.filter(ra => _.startsWith(ra, rowAddress)), // child row addresses are prefixed with rowAddress
            nextProps.expandedRowAddresses.filter(ra =>
              _.startsWith(ra, rowAddress)
            )
          )
        ) {
          return false;
        }
      }
      // entering or leaving this row/group
      if (
        isHoveringRowOrChild(rowAddress, hoverCellCoords) !==
        isHoveringRowOrChild(
          nextProps.row.__address__,
          nextProps.hoverCellCoords
        )
      ) {
        return false;
      }
      // Moving rows within this group
      if (
        isHoveringRowOrChild(
          nextProps.row.__address__,
          nextProps.hoverCellCoords
        ) &&
        (hoverCellCoords && hoverCellCoords[0]) !== nextProps.hoverCellCoords[0]
      ) {
        return false;
      }
      const propsAreEqual = makePropsAreEqual({
        name: 'DFTableRow',
        deep: ['columns', 'row'],
        // We remount when run.id changes
        ignore: [
          'loadingRowAddresses',
          'expandedRowAddresses',
          'hoverCellCoords',
        ],
        ignoreFunctions: true,
        debug: false,
        verbose: true,
      });
      return propsAreEqual(prevProps, nextProps);
    },
  }
);
