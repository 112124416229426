import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const EditorBar = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
`;

export const PanelHandler = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
`;

export const PanelHandlerContent = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const PanelHandlerConfig = styled.div`
  flex: 0 0 350px;
  padding-left: 10px;
  margin-left: 10px;
  margin-top: 10px;
  border-left: 1px solid #eee;
`;

export const ConfigurationContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 350px;
`;

export const ConfigurationContentItems = styled.div`
  flex: 1 1 auto;
  overflow-x: hide;
  overflow-y: auto;
`;

export const ConfigurationContentItem = styled.div`
  width: 100%;
`;

export const ConfigurationContentControls = styled.div`
  flex: 0 0 auto;
  height: 35px;
  margin-top: 24;
  display: flex;
  justify-content: space-between;
`;
