import * as React from 'react';

import * as QueryTS from '../util/queryts';

import Export, {queryToTable, captureUniqueError} from './Export';
import * as RunFeed from '../util/runfeed';
import makeComp from '../util/profiler';

import {toRunsDataQuery, RunsDataQuery} from '../containers/RunsDataLoader';
import * as RunHooks from '../state/runs/hooks';

import {Table} from '../util/csv';

import * as Query from '../util/queryts';
import * as Panels from '../util/panels';

interface TableExportProps {
  query: RunsDataQuery;
  pageQuery: QueryTS.Query;
  tableSettings: RunFeed.Config;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

const TableExport = makeComp(
  (props: TableExportProps) => {
    return (
      <Export
        pageQuery={props.pageQuery}
        trigger={props.trigger}
        open={props.open}
        onClose={props.onClose}
        panel={
          {
            __id__: 'dummyPanel',
            viewType: 'Run Selector',
            config: {columnAccessors: props.tableSettings.columnOrder},
          } as Panels.LayedOutPanel
        }
      />
    );
  },
  {id: 'TableExport'}
);

export default TableExport;

const useTableData = (pageQuery: Query.Query, config: any) => {
  let query = toRunsDataQuery(
    pageQuery,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );

  query = {
    ...query,
    page: {size: 10000},
  };

  const runsData = RunHooks.useRunsData(query);

  let table: Table = {cols: [], data: []};
  try {
    table = queryToTable(runsData.data, query, pageQuery, config);
  } catch (e) {
    captureUniqueError(e);
  }

  return {table, loading: runsData.loading};
};

interface PanelRunSelectorConfig {
  columnAccessors: string[];
}

const RunSelectorPanel: React.FC<Panels.PanelProps<PanelRunSelectorConfig>> =
  makeComp(
    () => {
      return null;
    },
    {id: 'RunSelectorPanel'}
  );

export const Spec: Panels.PanelSpec<'Run Selector', PanelRunSelectorConfig> = {
  type: 'Run Selector',
  Component: RunSelectorPanel as any,
  useTableData,
  transformQuery: () => ({} as RunsDataQuery),
};
