// TODO: the vega panels don't change size when the browser resizes

import * as _ from 'lodash';
import * as React from 'react';
import {Button, Dropdown, Loader, Segment} from 'semantic-ui-react';
import {Spec as VegaSpec} from 'vega';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import * as VegaLib from '../util/vega';
import {useRunsData, useLoadHistoryFileTables} from '../state/runs/hooks';
import {useVegaPanelQuery, View} from '../state/graphql/vegaPanelQuery';
import Markdown from './Markdown';
import * as BuiltinPanelDefs from './Vega/builtinPanelDefs';
import VegaPanelEditor from './Vega/VegaPanelEditor';
import VegaPanelUserConfig from './Vega/VegaPanelUserConfig';
import VegaViz from './Vega/VegaViz';
import makeComp from '../util/profiler';

const PANEL_TYPE = 'Vega';

export type VegaPanelConfig = Partial<VegaLib.UserSettings> & {
  panelDefId?: string;

  customPanelDef?: VegaLib.VegaPanelDef;
};

type VegaPanelProps = Panels.PanelProps<VegaPanelConfig>;
type VegaPanelWithQueryProps = Omit<VegaPanelWithDataProps, 'data'> &
  ReturnType<typeof useRunsData>;
interface VegaPanelState {
  config: string;
  editMode: boolean;
}

function getVegaPanelDef(
  config: VegaPanelConfig,
  views: View[]
): VegaLib.VegaPanelDef | null {
  const panelDefID = getPanelDefID(config);
  if (_.startsWith(panelDefID, 'lib:')) {
    const id = panelDefID.split(':')[1];
    const view = _.find(views, v => v.id === id);
    if (view == null) {
      return null;
    }
    return {
      id,
      name: view.name,
      description: view.description,
      spec: view.spec,
    };
  } else if (panelDefID === 'custom') {
    return config.customPanelDef!;
  } else {
    return BuiltinPanelDefs.getPanelDef(panelDefID);
  }
}

function getSpec(config: VegaPanelConfig, views: View[]): VegaSpec | null {
  const panelDef = getVegaPanelDef(config, views);
  if (panelDef == null) {
    return null;
  }
  return VegaLib.parseSpecJSON(panelDef.spec);
}

function getPanelDefID(config: VegaPanelConfig) {
  return config.panelDefId || BuiltinPanelDefs.IDS[0];
}

export class VegaPanel extends React.Component<
  VegaPanelWithQueryProps,
  VegaPanelState
> {
  static type = PANEL_TYPE;

  static transformQuery(
    query: Query.Query,
    config: VegaPanelConfig
  ): RunsDataQuery {
    // We always disable this query, and instead use our own internal RunsDataLoader.
    // We need to do this because the query we want to run actually depends on another
    // query (the vegaPanelQuery which asks for user configured views from the views
    // table), which depends on the current panel configuration.
    const result = toRunsDataQuery(query);
    result.disabled = true;
    return result;
  }

  state: VegaPanelState = {
    config: '',
    editMode: false,
  };

  ref: any;

  setRef = (ref: any) => {
    this.ref = ref;
  };

  renderUserConfig() {
    const keyInfo = this.props.keyInfo!;
    const {views, config} = this.props;
    const fieldSettings = config.fieldSettings || {};
    const runFieldSettings = config.runFieldSettings || {};
    const runFieldListSettings = config.runFieldListSettings || {};
    const historyFieldSettings = config.historyFieldSettings || {};
    const spec = getSpec(this.props.config, views);
    if (spec == null) {
      return <Segment>Vega spec invalid.</Segment>;
    }
    return (
      <VegaPanelUserConfig
        refs={VegaLib.parseSpec(spec) || []}
        runFieldListSettings={runFieldListSettings}
        runFieldOptions={VegaLib.runFieldOptions(this.props.data.keyInfo)}
        historyFieldOptions={VegaLib.historyFieldOptions(keyInfo)}
        fieldSettings={fieldSettings}
        runFieldSettings={runFieldSettings}
        historyFieldSettings={historyFieldSettings}
        updateUserSettings={newSettings =>
          this.props.updateConfig(_.merge({}, config, newSettings))
        }
      />
    );
  }

  renderEditor(views: View[]) {
    const {refetch} = this.props;
    const keyInfo = this.props.keyInfo!;
    const panelDef = getVegaPanelDef(this.props.config, views);
    if (panelDef == null) {
      return <Segment>Invalid panel.</Segment>;
    }
    return (
      <VegaPanelEditor
        pageQuery={this.props.pageQuery}
        keyInfo={keyInfo}
        state={{
          viewId: panelDef.id,
          name: panelDef.name,
          description: panelDef.description,
          spec: panelDef.spec,
          fieldSettings: this.props.config.fieldSettings || {},
          runFieldSettings: this.props.config.runFieldSettings || {},
          runFieldListSettings: this.props.config.runFieldListSettings || {},
          historyFieldSettings: this.props.config.historyFieldSettings || {},
        }}
        onCancel={() => this.setState({editMode: false})}
        onApplyCustom={newSpec => {
          this.props.updateConfig({
            panelDefId: 'custom',
            customPanelDef: {
              name: 'Custom',
              description: '',
              spec: newSpec,
            },
          });
          this.setState({editMode: false});
        }}
        onApplyFromLibrary={id => {
          this.props.updateConfig({
            panelDefId: 'lib:' + id,
            customPanelDef: undefined,
          });
          this.setState({editMode: false});
        }}
        vegaViews={views}
        refetchVegaViews={refetch}
      />
    );
  }

  renderConfig() {
    const {config, views} = this.props;
    let options = views.map(vegaView => {
      const optID = 'lib:' + vegaView.id;
      return {
        key: optID,
        value: optID,
        text: vegaView.name,
      };
    });
    options = options.concat(
      BuiltinPanelDefs.IDS.map(id => ({
        key: id,
        value: id,
        text: '[W&B builtin] ' + BuiltinPanelDefs.getPanelDef(id)!.name,
      }))
    );
    if (config.panelDefId === 'custom') {
      options.push({key: 'custom', value: 'custom', text: 'custom'});
    }
    const curPanelDef = getVegaPanelDef(config, views);
    return (
      <div className="chart-modal">
        <div className="chart-preview">{this.renderViz()}</div>
        <div className="chart-settings">
          <h5>Type</h5>
          <Dropdown
            search
            selection
            options={options}
            value={getPanelDefID(config)}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                this.props.updateConfig({...config, panelDefId: value});
              }
            }}
          />
          <Button
            style={{marginLeft: 12}}
            onClick={() => this.setState({editMode: true})}>
            Edit
          </Button>
          {curPanelDef != null && (
            <div style={{marginTop: 16}}>
              <Markdown content={curPanelDef.description} condensed={false} />
            </div>
          )}
          {this.renderUserConfig()}
        </div>
      </div>
    );
  }

  renderViz = () => {
    const {config, views} = this.props;
    const {
      fieldSettings,
      runFieldSettings,
      runFieldListSettings,
      historyFieldSettings,
    } = config;

    const key = historyFieldSettings?.key;

    const spec = getSpec(config, views);
    if (spec == null) {
      return <Segment>Invalid panel.</Segment>;
    }
    return (
      <>
        {key && (
          <h6 className="panel-title" title={key}>
            {key}
          </h6>
        )}
        <VegaViz
          spec={spec}
          data={this.props.data}
          userSettings={{
            fieldSettings: fieldSettings || {},
            runFieldSettings: runFieldSettings || {},
            runFieldListSettings: runFieldListSettings || {},
            historyFieldSettings: historyFieldSettings || {},
          }}
        />
      </>
    );
  };

  render() {
    const {views, data} = this.props;
    if (data.loading) {
      return <Loader active />;
    }
    if (this.props.configMode) {
      if (this.state.editMode) {
        return this.renderEditor(views);
      } else {
        return this.renderConfig();
      }
    } else {
      return this.renderViz();
    }
  }
}

type VegaPanelWithDataProps = VegaPanelProps & {views: View[]; refetch(): void};

const VegaPanelWithDataViews = makeComp(
  (props: VegaPanelWithDataProps) => {
    const {pageQuery, config, views} = props;
    const panelDef = getVegaPanelDef(config, views);
    const query = VegaLib.runsDataQuery(
      pageQuery,
      {
        fieldSettings: config.fieldSettings || {},
        runFieldSettings: config.runFieldSettings || {},
        runFieldListSettings: config.runFieldListSettings || {},
        historyFieldSettings: config.historyFieldSettings || {},
      },
      panelDef != null ? panelDef.spec : undefined
    );
    let runsQuery = useRunsData(query);
    runsQuery = useLoadHistoryFileTables(query, runsQuery);
    return <VegaPanel {...props} {...runsQuery} />;
  },
  {id: 'PanelVega.VegaPanelWithDataViews'}
);

const VegaPanelContainer = makeComp(
  (props: VegaPanelProps) => {
    const {entityName, projectName} = props.query;
    const {panelDefId} = props.config;
    const vegaPanelQuery = useVegaPanelQuery({
      entityName,
      projectName,
      viewId: panelDefId,
    });
    if (vegaPanelQuery.loading) {
      return <Loader />;
    }
    return (
      <VegaPanelWithDataViews
        {...props}
        views={vegaPanelQuery.views}
        refetch={vegaPanelQuery.refetch}
      />
    );
  },
  {id: 'PanelVega.VegaPanelContainer'}
);

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, VegaPanelConfig> = {
  type: PANEL_TYPE,
  Component: VegaPanelContainer,
  transformQuery: VegaPanel.transformQuery,
};
