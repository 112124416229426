import '../css/RunPage.less';

import {ApolloClient, ApolloQueryResult} from 'apollo-client';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import React, {FC, useState, useEffect, useRef, useMemo} from 'react';
import {graphql, withApollo} from 'react-apollo';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Dispatch} from 'redux';

import AnonymousClaimBanner from '../components/AnonymousClaimBanner';
import ArtifactFiles from '../components/ArtifactFiles';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import NoMatch from '../components/NoMatch';
import FancyPage from '../components/FancyPage';
import RunFiles from '../components/RunFiles';
import Log from '../components/Log';
import RunModelInfo from '../components/RunModelInfo';
import RunSystemMetrics from '../components/RunSystemMetrics';
import Loader from '../components/WandbLoader';
import AutoRefreshSettings from '../containers/AutoRefreshSettings';
import * as ViewMutations from '../containers/ViewMutations';
import {PROJECT_UPSERT} from '../graphql/models';
import {RunQueryResultProps, withRunQuery} from '../graphql/runQuery';
import {DELETE_RUN, RUN_STOP, RUN_UPSERT} from '../graphql/runs';
import {withViewer} from '../graphql/users_get_viewer';
import {useRunArtifactVersionsQuery} from '../generated/graphql';
import {isInJupyterNotebook} from '../setup';
import * as PollingReducer from '../state/polling/reducer';
import * as PollingActions from '../state/polling/actions';
import {User} from '../types/graphql';
import {AnalyticsEvents} from '../util/integrations';
import {JSONparseNaN} from '@wandb/cg/browser/utils/jsonnan';
import * as RunHelpers from '../util/runhelpers';
import * as Run from '../util/runs';
import * as urls from '../util/urls';
import RunOverview from './Run/RunOverview';
import RunWorkspace from './Run/RunWorkspace';
import RunArtifacts from './Run/RunArtifacts';
import {sweep as sweepUrl} from '../util/urls';
import {setDocumentTitle, setDocumentDescription} from '../util/document';
import makeComp from '../util/profiler';
import {envIsLocal, urlPrefixed} from '../config';
import {Match} from '../types/base';

const POLL_INTERVAL = 10000;

const runFromJson = _.memoize(Run.fromJson);

interface RunMatch extends Match {
  params: {
    entityName: string;
    projectName: string;
    runName: string;
    tab?: string;
    filePath?: string;
  };
}

interface RunPageProps {
  client: ApolloClient<any>;
  match: RunMatch;
  history: any;
  pollInterval: number;
  viewer: User;
  autoRefreshEnabled: boolean;
  dispatch: Dispatch<PollingReducer.ActionType>;
  upsertView(
    variables: ViewMutations.ViewUpsertVariables
  ): Promise<ApolloQueryResult<ViewMutations.ViewUpsertResult>>;
  setPollInterval(pi: number): void;
  updateProject(vars: {
    id: string;
    entityName: string;
    name: string;
    views?: string;
    access?: string;
  }): Promise<ApolloQueryResult<{}>>;
  updateRun(vars: {
    id: string;
    tags: string[];
    displayName: string;
    notes: string;
  }): Promise<ApolloQueryResult<{}>>;
  deleteRun(id: string, deleteArtifacts?: boolean): void;
}

// TODO: There are a lot of unnecessary non-null assertions (Typescript's
//   ! operator). We can get rid of them by making fewer things optional in
//   ReportSection
const RunPage: FC<RunPageProps & RunQueryResultProps> = makeComp(
  ({
    match,
    history,
    pollInterval,
    viewer,
    autoRefreshEnabled,
    dispatch,
    setPollInterval,
    updateProject,
    updateRun,
    deleteRun,
    runQuery,
  }) => {
    const pollStartTime = useRef(0);

    const project = runQuery.project;
    const run = project?.run;

    useEffect(() => {
      window.analytics.track(AnalyticsEvents.SINGLE_RUN_WORKSPACE, {
        page: 'Run',
        panelbank: true,
      });

      // Used to track when we start polling this query, so we can
      // change the pollInterval based on the amount of time the user
      // has been on the page.
      pollStartTime.current = new Date().getTime();
      dispatch(PollingActions.setBasePollInterval(POLL_INTERVAL));
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (run == null) {
        return;
      }
      let newPollInterval = 0;

      // Runs in Jupyter notebooks can be resumed, so we want to poll even if
      // the run is not in the running state.
      if (run.state === 'running' || isInJupyterNotebook()) {
        const queryWeight = RunHelpers.queryWeight(run.historyKeys);
        newPollInterval = queryWeight / 5;

        let minPI = 30000;
        if (new Date().getTime() - pollStartTime.current < 20 * 60 * 1000) {
          // If less than 20 minutes on page, use a smaller minimum
          minPI = 2000;
        }

        if (newPollInterval < minPI) {
          newPollInterval = minPI;
        }

        const maxPI = 90 * 1000; // 90 seconds
        if (newPollInterval > maxPI) {
          newPollInterval = maxPI;
        }
      }
      if (autoRefreshEnabled && newPollInterval !== pollInterval) {
        setPollInterval(newPollInterval);
        // It's ugly that we compute all this poll interval stuff in the run
        // page itself. This computation is now legacy, but it still works.
        // TODO: move smart poll interval logic into runs-low/sagas.ts
        dispatch(PollingActions.setBasePollInterval(newPollInterval));
      }
    }, [run, autoRefreshEnabled, pollInterval, setPollInterval, dispatch]);

    const {entityName, projectName, runName, tab, filePath} = match.params;
    const runArtifacts = useRunArtifactVersionsQuery({
      variables: {
        entityName,
        projectName,
        runName,
        includeOutput: true,
        includeInput: false,
        pageSize: 1, // we only care about the total count, so keep the size low
      },
    });

    const tabDescription: string = useMemo(() => {
      switch (tab) {
        case `overview`:
          return `Overview`;
        case `system`:
          return `System`;
        case `tensorboard`:
          return `Tensorboard`;
        case `model`:
          return `Model`;
        case `logs`:
          return `Logs`;
        case `files`:
          return 'Files';
        case `artifacts`:
          return 'Artifacts';
        case `code`:
          return 'Code';
        case `workspace`:
        default:
          return 'Workspace';
      }
    }, [tab]);

    useEffect(() => {
      if (run == null) {
        return;
      }
      const runDisplayName = run.displayName ?? runName;
      setDocumentTitle(`${runDisplayName} | ${projectName}`);
      setDocumentDescription(
        `${tabDescription} of run ${runDisplayName} in ${projectName}, a machine learning project by ${entityName} using Weights & Biases.`
      );
    }, [tabDescription, run, runName, projectName, entityName]);

    if (runQuery.loading) {
      return <Loader />;
    } else if (project && project.entity.claimingEntity != null) {
      // If the run's entity has been claimed, redirect to the claiming entity
      // instead so the original link still works.
      return (
        <Redirect
          to={urls.run({
            entityName: project.entity.claimingEntity.name,
            projectName,
            name: runName,
          })}
        />
      );
    } else if (project == null || run == null) {
      return <NoMatch />;
    }
    const {entity} = project;
    const {fileCount} = run;
    const summaryMetrics = Run.parseSummary(run.summaryMetrics, run.name) || {};
    const config = JSONparseNaN(run.config);
    // 'wandb_version' is an extra thing put in by the CLI that is a misnomer
    // and useless.
    delete config.wandb_version;

    let readOnly = false;
    if (!viewer) {
      readOnly = true;
    } else if (runQuery.loading || project == null) {
      readOnly = true;
    } else if (project.readOnly) {
      readOnly = true;
    }

    const pollIntervalMaybeDisabled = autoRefreshEnabled ? pollInterval : 0;

    const runObj = runFromJson(run)!;

    return (
      <div
        style={{height: '100%'}}
        className={`run-page ${readOnly ? 'read-only' : ''}`}>
        <FancyPage
          history={history}
          baseUrl={urls.run({entityName, projectName, name: runName})}
          defaultIndex={1}
          activeSlug={tab}
          items={[
            {
              iconName: 'cecile-overview-gray',
              selectedIconName: 'cecile-overview',
              name: 'Overview',
              slug: 'overview',
              render: () => (
                <>
                  <AnonymousClaimBanner
                    entityName={entityName}
                    projectName={projectName}
                    run={runName}
                    user={entity.user}
                    viewer={viewer}
                  />
                  <RunOverview
                    project={project}
                    run={run}
                    summaryMetrics={summaryMetrics}
                    config={config}
                    viewer={viewer}
                    history={history}
                    updateRun={fields => {
                      updateRun({
                        id: run.id,
                        tags: run.tags.map(t => t.name),
                        displayName: run.displayName,
                        notes: run.notes,
                        ...fields,
                      });
                    }}
                    updateProject={fields => {
                      updateProject({
                        id: project.id,
                        entityName: project.entityName,
                        name: project.name,
                        ...fields,
                      });
                    }}
                    deleteRun={deleteRun}
                  />
                </>
              ),
            },
            {
              iconName: 'cecile-workspace-gray',
              selectedIconName: 'cecile-workspace',
              name: 'Charts',
              slug: 'workspace',
              render: () => (
                <>
                  <AnonymousClaimBanner
                    entityName={entityName}
                    projectName={projectName}
                    run={runName}
                    user={entity.user}
                    viewer={viewer}
                  />
                  <RunWorkspace
                    entityName={entityName}
                    projectName={projectName}
                    run={runObj}
                    readOnly={readOnly}
                  />
                </>
              ),
            },
            {
              iconName: 'cecile-system-gray',
              selectedIconName: 'cecile-system',
              name: 'System',
              slug: 'system',
              render: () => (
                <>
                  <AnonymousClaimBanner
                    entityName={entityName}
                    projectName={projectName}
                    run={runName}
                    user={entity.user}
                    viewer={viewer}
                  />
                  <RunSystemMetrics
                    entityName={entityName}
                    projectName={projectName}
                    runName={runName}
                    pollInterval={pollIntervalMaybeDisabled}
                  />
                </>
              ),
            },
            ...(run.servicesAvailable.tensorboard && !envIsLocal
              ? [
                  {
                    iconName: 'tensorflow-gray',
                    selectedIconName: 'tensorflow',
                    name: 'Tensorboard',
                    slug: 'tensorboard',
                    render: () => (
                      <iframe
                        title="TensorBoard"
                        className="tensorboard-iframe"
                        src={urls.tensorboardUrl(
                          entityName,
                          projectName,
                          runName
                        )}
                      />
                    ),
                  },
                ]
              : []),
            ...(Object.values(summaryMetrics).find(
              (val: any) =>
                val && (val._type === 'graph' || val._type === 'graph-file')
            )
              ? [
                  {
                    iconName: 'cecile-model-gray',
                    selectedIconName: 'cecile-model',
                    name: 'Model',
                    slug: 'model',
                    render: () => (
                      <>
                        <AnonymousClaimBanner
                          entityName={entityName}
                          projectName={projectName}
                          run={runName}
                          user={entity.user}
                          viewer={viewer}
                        />
                        <RunModelInfo
                          entityName={entityName}
                          projectName={projectName}
                          runName={runName}
                          summaryMetrics={summaryMetrics}
                        />
                      </>
                    ),
                  },
                ]
              : []),
            {
              iconName: 'cecile-logs-gray',
              selectedIconName: 'cecile-logs',
              name: 'Logs',
              slug: 'logs',
              render: () => {
                const outputLogFile = RunHelpers.outputLogFile(run);
                return (
                  <>
                    <AnonymousClaimBanner
                      entityName={entityName}
                      projectName={projectName}
                      run={runName}
                      user={entity.user}
                      viewer={viewer}
                    />
                    {outputLogFile && (
                      <a
                        href={outputLogFile}
                        download="output.log"
                        className="download-logs-button">
                        <LegacyWBIcon name="download" />
                      </a>
                    )}
                    <Log
                      entityName={entityName}
                      projectName={projectName}
                      runName={runName}
                      pollInterval={pollIntervalMaybeDisabled}
                      hideTimestamp
                    />
                  </>
                );
              },
            },
            {
              iconName: 'cecile-files-gray',
              selectedIconName: 'cecile-files',
              name: `Files (${fileCount})`,
              slug: 'files',
              render: () => (
                <>
                  <AnonymousClaimBanner
                    entityName={entityName}
                    projectName={projectName}
                    run={runName}
                    user={entity.user}
                    viewer={viewer}
                  />
                  <RunFiles
                    entityName={entityName}
                    projectName={projectName}
                    runName={runName}
                    path={filePath}
                    totalFiles={fileCount}
                    history={history}
                  />
                </>
              ),
            },
            ...(run.inputArtifacts.totalCount > 0 ||
            run.outputArtifacts.totalCount > 0
              ? [
                  {
                    iconName: 'artifact-gray',
                    selectedIconName: 'artifact-white',
                    name: `Artifacts`,
                    slug: 'artifacts',
                    render: () => (
                      <>
                        <AnonymousClaimBanner
                          entityName={entityName}
                          projectName={projectName}
                          run={runName}
                          user={entity.user}
                          viewer={viewer}
                        />
                        <RunArtifacts
                          entityName={entityName}
                          projectName={projectName}
                          runName={runName}
                        />
                      </>
                    ),
                  },
                ]
              : []),
            ...(config._wandb?.value?.code_path ||
            config._wandb?.value?.session_history
              ? [
                  {
                    iconName: 'cecile-code-gray',
                    selectedIconName: 'cecile-code',
                    name: 'Code',
                    slug: 'code',
                    render: () => {
                      const outputs =
                        runArtifacts.data?.project?.run?.outputArtifacts?.edges.filter(
                          edge => edge?.node?.artifactType.name === 'code'
                        );
                      if (outputs && outputs.length > 0) {
                        const output = outputs[outputs.length - 1];
                        return (
                          <div style={{padding: '0 16px'}}>
                            <ArtifactFiles
                              entityName={entityName}
                              projectName={projectName}
                              artifactTypeName={'code'}
                              artifactSequenceName={
                                output.node!.artifactSequence.name
                              }
                              artifactCommitHash={output.node!.commitHash!}
                              filePath={filePath}
                              useSetFilePath={base => path =>
                                history.push(
                                  urls.runCodeFile(
                                    Object.assign({runName, path}, base)
                                  )
                                )}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <RunFiles
                            entityName={entityName}
                            projectName={projectName}
                            runName={runName}
                            path={
                              config._wandb.value.code_path ||
                              config._wandb.value.session_history
                            }
                            totalFiles={fileCount}
                            history={history}
                          />
                        );
                      }
                    },
                  },
                ]
              : []),
            ...(project.linkedBenchmark != null
              ? [
                  {
                    iconName: 'cecile-benchmark-gray',
                    selectedIconName: 'cecile-benchmark',
                    name: 'Benchmark: ' + project.linkedBenchmark.name,
                    externalLink: urls.project(
                      /* TS3.9 upgraded caused type mismatch here, please fix if working on benchmarks */
                      project.linkedBenchmark as any
                    ),
                    sectionIndex: 1,
                  },
                ]
              : []),
            ...(runObj.sweep?.name != null
              ? [
                  {
                    iconName: 'cecile-sweeps-gray-clear',
                    selectedIconName: 'cecile-sweeps',
                    name: `Sweep`,
                    externalLink: sweepUrl({
                      entityName,
                      projectName,
                      sweepName: runObj.sweep.name,
                    }),
                    sectionIndex: 1,
                  },
                ]
              : []),
          ]}
        />
      </div>
    );
  },
  {id: 'RunPage', memo: true}
);

const withMutations = compose(
  ViewMutations.upsertViewMutation,
  graphql(DELETE_RUN, {
    props: ({mutate, ownProps}: any) => ({
      deleteRun: (id: string, deleteArtifacts?: boolean) => {
        const matchParams = ownProps.match.params;
        if (mutate) {
          mutate({
            variables: {id, deleteArtifacts},
          }).then(
            () =>
              (window.location.href = urlPrefixed(
                `/${matchParams.entityName}/${matchParams.projectName}`
              ))
          );
        }
      },
    }),
  }),
  graphql(RUN_STOP, {
    props: ({mutate}) => ({
      stop: (id: string) =>
        mutate &&
        mutate({
          variables: {id},
        }).then(() => console.log('Stopping run')),
    }),
  }),
  graphql(RUN_UPSERT, {
    props: ({mutate}) => ({
      updateRun: (variables: any) =>
        mutate &&
        mutate({
          variables,
        }),
    }),
  }),
  graphql(PROJECT_UPSERT, {
    props: ({mutate}) => ({
      updateProject: (variables: any) =>
        mutate &&
        mutate({
          variables: {...variables},
        }),
    }),
  })
) as any;

const RunPageWrapped = withViewer(
  withMutations(withApollo(withRunQuery(connect()(RunPage)) as any))
);

interface PollingRunPageProps {
  match: RunMatch;
}

const PollingRunPage: FC<PollingRunPageProps> = makeComp(
  props => {
    const [pollInterval, setPollInterval] = useState(0);

    return (
      <AutoRefreshSettings>
        {autoRefreshEnabled => (
          <RunPageWrapped
            {...props}
            autoRefreshEnabled={autoRefreshEnabled}
            pollInterval={autoRefreshEnabled ? pollInterval : 0}
            setPollInterval={(t: number) => setPollInterval(t)}
          />
        )}
      </AutoRefreshSettings>
    );
  },
  {id: 'PollingRunPage', memo: true}
);

export default PollingRunPage;
