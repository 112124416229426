import _ from 'lodash';

import * as ProjectPageViewTypes from '../state/views/projectPage/types';
import * as PanelSettings from '../util/panelsettings';
import * as Section from '../util/section';
import * as WorkspaceSelectors from '../state/workspaces/selectors';
import {
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
  EMPTY_PANEL_BANK_CONFIG,
} from '../util/panelbank';
import {useViewUrlEffect, useWorkspaceInit} from '../state/workspaces/hooks';
import {usePropsSelector} from '../state/hooks';
import {ProjectPageViewSpec} from '../state/views/projectPage/types';

export type ViewType = 'project-view' | 'sweep-view' | 'group-view';
export type RecommendGrouping = 'group-and-jobtype' | 'jobtype';

const DEFAULT_CONFIG: Section.Config = {
  openRunSet: 0,
  runSets: [Section.emptyRunSetSelectAll()],
  panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
  panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
  settings: PanelSettings.EMPTY_SETTINGS,
  customRunColors: {},
};

// Sweep, Group and Project workspaces are exactly the same
// currently.
const DEFAULT_VIEW_SPEC: ProjectPageViewTypes.ProjectPageViewSpec = {
  vizExpanded: false,
  libraryExpanded: true,
  section: DEFAULT_CONFIG,
};
export const createDefaultSpec = () => _.cloneDeep(DEFAULT_VIEW_SPEC);

export const useRunWorkspaceProps = (args: {
  entityName: string;
  projectName: string;
  viewType: ViewType;
  defaultSpec: ProjectPageViewSpec;
  workspaceObjectID?: string;
}) => {
  const {viewType} = args;
  const workspaceID = useWorkspaceInit(
    args.entityName,
    args.projectName,
    viewType,
    args.defaultSpec,
    args.workspaceObjectID
  );
  useViewUrlEffect(workspaceID);
  const workspace = usePropsSelector(
    WorkspaceSelectors.makeMultiRunWorkspaceSelector,
    workspaceID,
    viewType
  );

  return workspace.loading
    ? {loading: true as true}
    : {loading: false as false, readyProps: {workspace, workspaceID}};
};
