import React, {FC} from 'react';
import {QueryResult} from '@apollo/react-common';

import WandbLoader from '../components/WandbLoader';
import makeComp from '../util/profiler';

interface DeferredQueryDataProps {
  query: QueryResult;
  children: (data: QueryResult['data']) => JSX.Element | null;
}

const DeferredQueryData: FC<DeferredQueryDataProps> = makeComp(
  ({query, children}) => {
    const {data, loading} = query;
    /*
  if (query.error != null) {
    // TODO(axel): show proper error state
  }
  */
    if (loading) {
      return (
        <div style={{position: 'relative', height: 200, marginBottom: 40}}>
          <WandbLoader />
        </div>
      );
    }
    return children(data);
  },
  {id: 'DeferredQueryData', memo: true}
);

export default DeferredQueryData;
