import _ from 'lodash';
import React, {useState, useCallback, useMemo} from 'react';
import {Button, Dropdown, Icon} from 'semantic-ui-react';
import '../css/ImagePreview.less';
import SliderInput from './elements/SliderInput';
import makeComp from '../util/profiler';

export interface ImagePreviewConfig {
  imageColumn: string;
  overlayColumn?: string;
  overlayOpacity?: number;
}

export interface ImagePreviewProps {
  columnOptions: string[];
  previewConfigs: ImagePreviewConfig[];
  configMode: boolean;

  // column name to image source
  columnToImage(column: string): string | undefined;

  updatePreviewConfigs(previewConfigs: ImagePreviewConfig[]): void;
}

const ImagePreview: React.FC<ImagePreviewProps> = makeComp(
  ({
    columnOptions,
    previewConfigs,
    configMode,
    columnToImage,
    updatePreviewConfigs,
  }) => {
    const addPreview = useCallback(
      (end: boolean) => {
        // Try to default to a preview image not already shown
        let imageColumn = columnOptions.find(
          c => !previewConfigs.find(cfg => cfg.imageColumn === c)
        );
        // Otherwise just default to the first option
        if (!imageColumn) {
          imageColumn = columnOptions[0];
        }

        updatePreviewConfigs(
          end
            ? [...previewConfigs, {imageColumn}]
            : [{imageColumn}, ...previewConfigs]
        );
      },
      [columnOptions, previewConfigs, updatePreviewConfigs]
    );

    const updatePreview = useCallback(
      (index: number, config: ImagePreviewConfig) => {
        updatePreviewConfigs([
          ...previewConfigs.slice(0, index),
          config,
          ...previewConfigs.slice(index + 1),
        ]);
      },
      [previewConfigs, updatePreviewConfigs]
    );

    const deletePreview = useCallback(
      (index: number) => {
        updatePreviewConfigs([
          ...previewConfigs.slice(0, index),
          ...previewConfigs.slice(index + 1),
        ]);
      },
      [previewConfigs, updatePreviewConfigs]
    );

    return (
      <div className="image-preview">
        {previewConfigs.length === 0 ? (
          <AddButton onClick={() => addPreview(true)} />
        ) : (
          <>
            {configMode && (
              <AddButton hidden side onClick={() => addPreview(false)} />
            )}
            {previewConfigs.map((preview, i) => (
              <ImagePreviewItem
                key={i}
                config={preview}
                columnOptions={columnOptions}
                configMode={configMode}
                columnToImage={columnToImage}
                updatePreview={config => updatePreview(i, config)}
                deletePreview={() => deletePreview(i)}
              />
            ))}
            {configMode && (
              <AddButton hidden side onClick={() => addPreview(true)} />
            )}
          </>
        )}
      </div>
    );
  },
  {id: 'ImagePreview', memo: true}
);

export default ImagePreview;

interface ImagePreviewItemProps {
  config: ImagePreviewConfig;

  columnOptions: string[];
  configMode: boolean;

  columnToImage(column: string): string | undefined;
  updatePreview(config: ImagePreviewConfig): void;
  deletePreview(): void;
}

const ImagePreviewItem: React.FC<ImagePreviewItemProps> = makeComp(
  ({
    config,
    columnOptions,
    configMode,
    columnToImage,
    updatePreview,
    deletePreview,
  }) => {
    const [overlayOpacity, setOverlayOpacity] = useState<number | undefined>();

    const saveOverlayOpacity = useMemo(
      () =>
        _.debounce(() => {
          updatePreview({
            ...config,
            overlayOpacity,
          });
          setOverlayOpacity(undefined);
        }, 100),
      [config, overlayOpacity, updatePreview]
    );

    const updateOverlayOpacity = useCallback(
      (value: number) => {
        setOverlayOpacity(value);
        saveOverlayOpacity();
      },
      [saveOverlayOpacity]
    );

    const overlayOpacityWithDefault =
      overlayOpacity != null
        ? overlayOpacity
        : config.overlayOpacity != null
        ? config.overlayOpacity
        : 0.5;
    const backgroundUrl = columnToImage(config.imageColumn);
    const overlayUrl =
      config.overlayColumn && columnToImage(config.overlayColumn);
    return (
      <div className="image-preview--item">
        <div
          className="image-preview--image"
          style={{
            backgroundImage: backgroundUrl && `url(${backgroundUrl})`,
          }}>
          {config.overlayColumn && (
            <div
              className="image-preview--overlay"
              style={{
                backgroundImage: overlayUrl && `url(${overlayUrl})`,
                opacity: overlayOpacityWithDefault,
              }}
            />
          )}
        </div>
        {configMode ? (
          <div className="image-preview--config-overlay">
            <div className="image-preview--delete" onClick={deletePreview}>
              <Icon active name="close" />
            </div>
            <div className="image-preview--config">
              <div>
                <label>Preview Image</label>
                <Dropdown
                  fluid
                  selection
                  options={columnOptions.map(c => ({
                    text: c,
                    key: c,
                    value: c,
                  }))}
                  value={config.imageColumn}
                  onChange={(_1, {value}) =>
                    updatePreview({...config, imageColumn: value as string})
                  }
                />
              </div>
              {config.overlayColumn ? (
                <>
                  <div>
                    <label>Overlay Image</label>
                    <Dropdown
                      fluid
                      selection
                      options={columnOptions.map(c => ({
                        text: c,
                        key: c,
                        value: c,
                      }))}
                      value={config.overlayColumn}
                      onChange={(_1, {value}) =>
                        updatePreview({
                          ...config,
                          overlayColumn: value as string,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Overlay Opacity</label>
                    <br />
                    <SliderInput
                      min={0}
                      max={1}
                      step={0.01}
                      value={overlayOpacityWithDefault}
                      onChange={value => updateOverlayOpacity(value)}
                    />
                  </div>
                  <div>
                    <Button
                      content="Remove Overlay"
                      fluid
                      onClick={() =>
                        updatePreview({
                          ...config,
                          overlayColumn: undefined,
                        })
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Button
                      content="Add Overlay"
                      fluid
                      onClick={() =>
                        updatePreview({
                          ...config,
                          overlayColumn:
                            columnOptions.find(c => c !== config.imageColumn) ||
                            columnOptions[0],
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          config.overlayColumn && (
            <div className="image-preview--opacity-overlay">
              <div>
                <SliderInput
                  min={0}
                  max={1}
                  step={0.01}
                  value={overlayOpacityWithDefault}
                  onChange={value => updateOverlayOpacity(value)}
                />
              </div>
            </div>
          )
        )}
      </div>
    );
  },
  {id: 'ImagePreviewItem', memo: true}
);

interface AddButtonProps {
  hidden?: boolean;
  side?: boolean;

  onClick(): void;
}

const AddButton: React.FC<AddButtonProps> = makeComp(
  ({hidden, side, onClick}) => {
    return (
      <div
        className={`
          add-button
          ${hidden ? 'add-button--hidden' : ''}
          ${side ? 'add-button--side' : ''}
      `}
        onClick={onClick}>
        <div className="add-button--plus">+</div>
      </div>
    );
  },
  {id: 'AddButton', memo: true}
);
