import {createBrowserHistory, History, LocationDescriptorObject} from 'history';
import {urlPrefixed} from '../config';

const globalHistory = createBrowserHistory({
  basename: urlPrefixed(undefined, false),
});

export default globalHistory;

interface BaseNavigateOpts {
  replace?: boolean;
  history?: History;
}

// Use this to navigate to a new path while optionally preserving search/hash
interface PathnameNavigateOpts extends BaseNavigateOpts {
  pathname: string;
  preserveSearch?: boolean;
  preserveHash?: boolean;
}

// Use this to navigate to a new location, including explicit new search/hash values
interface LocationNavigateOpts extends BaseNavigateOpts {
  location: LocationDescriptorObject;
}

type NavigateOpts = PathnameNavigateOpts | LocationNavigateOpts;

export function navigateTo(opts: NavigateOpts): void {
  const {replace = false, history = globalHistory} = opts;

  if ('location' in opts) {
    if (replace) {
      history.replace(opts.location);
    } else {
      history.push(opts.location);
    }
    return;
  }

  if ('pathname' in opts) {
    const {pathname, preserveSearch = false, preserveHash = false} = opts;
    const {location} = history;

    const to: LocationDescriptorObject = {pathname};
    if (preserveSearch) {
      to.search = location.search;
    }
    if (preserveHash) {
      to.hash = location.hash;
    }

    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
    return;
  }
}
