/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'confusion-matrix-history',
  name: 'Confusion Matrix history plot',
  description: 'A confusion matrix showing history across runs',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    padding: 5,
    signals: [],
    data: [
      {
        name: '${history-table:rows:x-axis,key}',
      },
    ],
    title: {
      text: 'Confusion Matrix',
    },
    scales: [
      {
        name: 'lum',
        type: 'linear',
        range: [1, 0.5],
        domain: {
          data: 'rows',
          field: 'Count',
        },
      },
      {
        name: 'true',
        type: 'band',
        range: 'width',
        domain: {
          data: 'rows',
          field: 'True Class',
        },
      },
      {
        name: 'pred',
        type: 'band',
        range: 'height',
        domain: {
          data: 'rows',
          field: 'Pred Class',
        },
      },
      {
        name: 'run',
        type: 'band',
        range: [
          0,
          {
            signal: "bandwidth('pred')",
          },
        ],
        domain: {
          data: 'rows',
          field: 'runName',
        },
      },
      {
        name: 'step',
        type: 'band',
        range: [
          0,
          {
            signal: "bandwidth('true')",
          },
        ],
        domain: {
          data: 'rows',
          field: '${history-field:x-axis}',
        },
      },
      {
        name: 'runColor',
        type: 'point',
        range: [0, 360],
        padding: 0.5,
        domain: {
          data: 'rows',
          field: 'runName',
        },
      },
    ],
    axes: [
      {
        title: 'True Class',
        scale: 'true',
        orient: 'top',
      },
      {
        title: 'Predicted Class',
        scale: 'pred',
        orient: 'left',
      },
    ],
    legends: [
      {
        type: 'symbol',
        fill: 'runColor',
        orient: 'left',
        encode: {
          symbols: {
            update: {
              fill: {
                signal: "hsl(scale('runColor', datum.label), 1, 0.5)",
              },
            },
          },
        },
      },
    ],
    marks: [
      {
        type: 'group',
        from: {
          facet: {
            data: 'rows',
            name: 'facet',
            groupby: ['True Class', 'Pred Class'],
          },
        },
        encode: {
          enter: {
            x: {
              scale: 'true',
              field: 'True Class',
            },
            y: {
              scale: 'pred',
              field: 'Pred Class',
            },
            width: {
              scale: 'true',
              band: 1,
            },
            height: {
              scale: 'pred',
              band: 1,
            },
          },
        },
        marks: [
          {
            type: 'rect',
            from: {
              data: 'facet',
            },
            encode: {
              update: {
                x: {
                  scale: 'step',
                  field: '${history-field:x-axis}',
                },
                y: {
                  scale: 'run',
                  field: 'runName',
                },
                width: {
                  scale: 'step',
                  band: 1,
                },
                height: {
                  scale: 'run',
                  band: 1,
                },
                fill: {
                  signal:
                    "hsl(scale('runColor', datum.runName), 1, scale('lum', datum.Count))",
                },
              },
            },
          },
          {
            type: 'rect',
            x: 0,
            y: 0,
            encode: {
              enter: {
                fillOpacity: {
                  value: 0,
                },
                stroke: {
                  value: 'black',
                },
                strokeWidth: {
                  value: 0.3,
                },
                width: {
                  scale: 'true',
                  band: 1,
                },
                height: {
                  scale: 'pred',
                  band: 1,
                },
              },
            },
          },
        ],
      },
    ],
  },
};

export default def;
