import '../../css/SliderInput.less';

import _ from 'lodash';
import React from 'react';

import classNames from 'classnames';

// Doesn't yet work in nested panels yet.
// import {BetterPopup} from '../BetterPopup';
import NumberInput from './NumberInput';
import {ID} from '@wandb/cg/browser/utils/string';
import {Popup} from 'semantic-ui-react';
import makeComp from '../../util/profiler';

export interface SliderInputProps {
  min: number;
  max: number;
  step: number;
  value?: number;
  // if true, only input will be displayed, with slider appearing in a hover popup
  sliderInPopup?: boolean;
  className?: string;
  debounceTime?: number;
  trigger?: JSX.Element;
  hasInput?: boolean;
  minLabel?: string;
  maxLabel?: string;
  ticks?: number[];
  disabled?: boolean;
  strideLength?: number;
  // if true, the slider will be restricted to props.max, but the input will be unbounded (https://wandb.atlassian.net/browse/WB-5666)
  allowGreaterThanMax?: boolean;
  onChange(value: number): void;
}

export const INPUT_SLIDER_CLASS = 'input__slider';

const SliderInput = makeComp(
  (props: SliderInputProps) => {
    const {onChange, debounceTime} = props;
    const [sliderValue, setSliderValue] = React.useState(props.value || 0);

    const tickListID = React.useMemo(() => ID(10), []);

    const updateDebounced = React.useMemo(
      () =>
        _.debounce((value: number) => {
          onChange(value);
        }, debounceTime || 1),
      [onChange, debounceTime]
    );
    // Cancel any potentially-still-running debounce method on cleanup
    React.useLayoutEffect(() => {
      return () => updateDebounced.flush();
    }, [updateDebounced]);

    React.useEffect(() => {
      if (props.value != null) {
        setSliderValue(props.value);
      }
    }, [props.value]);

    const tickDatalist = React.useMemo(
      () =>
        props.ticks && (
          <datalist id={tickListID}>
            {props.ticks.map((t, i) => (
              <option key={i} value={t}></option>
            ))}
          </datalist>
        ),
      [tickListID, props.ticks]
    );

    const renderSlider = () => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {props.minLabel && <label className="min">{props.minLabel}</label>}
          <input
            // Other code relies on this class to detect if the event
            // comes from a slider, do not remove.
            type="range"
            disabled={props.disabled ?? false}
            min={props.min}
            max={props.max}
            step={props.step}
            value={sliderValue}
            list={tickListID}
            onInput={(e: React.SyntheticEvent<HTMLInputElement>) => {
              const value = parseFloat(e.currentTarget.value);
              setSliderValue(value);
              updateDebounced(value);
            }}
            // suppress warning about onChange missing
            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {}}
          />
          {props.maxLabel && (
            <label className="max">&nbsp;{props.maxLabel}</label>
          )}
          {tickDatalist}
        </div>
      );
    };

    const renderInput = () => {
      return (
        <NumberInput
          stepper
          strideLength={props.strideLength}
          disabled={props.disabled ?? false}
          min={props.min}
          max={props.allowGreaterThanMax ? undefined : props.max}
          value={props.value}
          ticks={props.ticks}
          onChange={(newVal: any) => {
            if (newVal === undefined) {
              return;
            }
            if (newVal > props.max && !props.allowGreaterThanMax) {
              newVal = props.max;
            }
            if (newVal < props.min) {
              newVal = props.min;
            }
            updateDebounced(newVal);
            setSliderValue(newVal);
          }}
        />
      );
    };

    return (
      <div className={classNames('slider-input', props.className)}>
        {props.sliderInPopup ? (
          <Popup
            inverted
            size="mini"
            className={INPUT_SLIDER_CLASS}
            hoverable
            position="top center"
            // on="click"
            trigger={<span>{props.trigger ?? renderInput()}</span>}
            content={<div className="slider-input">{renderSlider()}</div>}
          />
        ) : (
          <>
            {renderSlider()}
            {props.trigger ?? (props.hasInput && renderInput())}
          </>
        )}
      </div>
    );
  },
  {id: 'SliderInput'}
);

export default SliderInput;
