import * as S from './table-of-contents.styles';

import React, {useMemo} from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useEditor,
  useReadOnly,
} from 'slate-react';
import {BlockWrapper} from './drag-drop';
import {isHeading} from './headings';
import {getDeepLinkIdFromText} from '../util';
import docUrl from '../../../util/doc_urls';
import {WBMenuOption} from '@wandb/ui';

export interface TableOfContents extends Element {
  type: 'table-of-contents';
  displayLevel?: number;
}

export const isTableOfContents = (node: Node): node is TableOfContents =>
  node.type === 'table-of-contents';

export const TableOfContentsElement: React.FC<
  RenderElementProps & {
    element: TableOfContents;
  }
> = ({attributes, element, children}) => {
  const editor = useEditor();
  const readOnly = useReadOnly();
  const selected = useSelected();

  const content = useMemo(() => {
    if (
      editor.children.length === 0 ||
      !editor.children.some((node: Node) => isHeading(node))
    ) {
      // Show message when there is no heading in reports for table of contents
      return (
        <S.NoHeadingMessage>
          Add a heading to create links in this table of contents.{' '}
          <S.LearnMoreLink href={docUrl.reports + '#how-to-edit-a-report'}>
            Learn more.
          </S.LearnMoreLink>
        </S.NoHeadingMessage>
      );
    }

    const maxLevel = Math.max(
      ...editor.children.map(node => (isHeading(node) ? node.level : 0))
    );

    const options: WBMenuOption[] = [];

    if (maxLevel === 0) {
      return options;
    }
    if (maxLevel >= 1) {
      options.unshift({value: 1, name: 'Header 1 Only'});
    }
    if (maxLevel >= 2) {
      options.unshift({value: 2, name: 'Header 1 & 2'});
    }
    if (maxLevel >= 3) {
      options.unshift({value: 3, name: 'All Headers'});
    }

    // By default, it tries to show up to the highest header level in the reports
    const displayLevel = element.displayLevel ?? maxLevel;

    return (
      <>
        {editor.children
          .filter(
            (node: Node) =>
              isHeading(node) &&
              node.children.length > 0 &&
              node.level <= displayLevel
          )
          .map((node: Node, i: number) => {
            const title = (node.children as Node[])[0].text as string;
            const href = '#' + getDeepLinkIdFromText(title);

            return (
              <S.Anchor key={i} href={href} level={node.level as 1 | 2 | 3}>
                {title}
              </S.Anchor>
            );
          })}
        {!readOnly && displayLevel > 0 && (
          <S.HeaderSelect
            options={options}
            value={displayLevel}
            onSelect={v => {
              const path = ReactEditor.findPath(editor, element);
              const range = Editor.range(editor, path);

              Editor.withoutNormalizing(editor, () => {
                Transforms.setNodes(
                  editor,
                  {displayLevel: v},
                  {
                    at: range,
                  }
                );
              });
            }}></S.HeaderSelect>
        )}
      </>
    );
  }, [editor, element, readOnly]);

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.TableOfContents contentEditable={false} selected={selected}>
        {content}
      </S.TableOfContents>
      {children}
    </BlockWrapper>
  );
};

export const withTableOfContents = <T extends ReactEditor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isTableOfContents(element) ? true : isVoid(element);
  };

  return editor;
};
