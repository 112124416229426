import * as H from 'history';
import {flowRight as compose} from 'lodash';
import * as querystring from 'querystring';
import React, {useState, useCallback, useRef} from 'react';
import {Button, Header, Loader, Modal, Table} from 'semantic-ui-react';
import {
  DeleteIntegrationComponent,
  Integration,
  SlackIntegration as Slack,
  useServerInfoQuery,
} from '../generated/graphql';
import {
  CreateSlackChannelSubscriptionMutationFn,
  createSlackChannelSubscrptionMutation,
} from '../graphql/alerts';
import {
  createSlackIntegrationMutation,
  CreateSlackIntegrationMutationFn,
} from '../graphql/integrations';
import {Entity} from '../types/graphql';
import '../css/SlackIntegration.less';

import {randID} from '../util/data';
import {captureError} from '../util/integrations';
import makeComp from '../util/profiler';

const SLACK_CODE = 'slack';
const STATE_STORE_KEY = '__slack_oauth_state';

// We store the request type in the oauth state variable
// This way, when the page is loaded with the query params we
// can make the correct integration consume the tokens
const isSlackOAuth = (state: string | string[] | undefined) => {
  if (typeof state === 'string') {
    const codes = state.split(':');
    return (codes && codes[0]) === SLACK_CODE;
  } else {
    return false;
  }
};

export interface SlackIntegrationProps {
  history: H.History;
  entity: Entity;
  entityRefetch: any;
  activeIntegration: string;
  integrationReason: string;
  createSlackChannelSubscription: CreateSlackChannelSubscriptionMutationFn; // from withMutations()
  createSlackIntegration: CreateSlackIntegrationMutationFn; // from withMutations()
}

export function isSlackIntegration(
  integration: Integration
): integration is Slack {
  return (integration as any).__typename === 'SlackIntegration';
}

export function toSlackIntegration(
  integration: Integration
): Slack | undefined {
  if (isSlackIntegration(integration)) {
    return integration;
  }

  return undefined;
}

const SlackIntegration: React.FC<SlackIntegrationProps> = makeComp(
  ({
    history,
    entity,
    entityRefetch,
    integrationReason,
    createSlackIntegration: createSlackIntegrationMtn,
  }) => {
    const [disconnectSlackModalOpen, setDisconnectSlackModalOpen] =
      useState(false);
    const [slackIntegrationInProgress, setSlackIntegrationInProgress] =
      useState(false);

    const redirectUriRef = useRef(
      window.location.origin + window.location.pathname
    );

    const serverInfo = useServerInfoQuery();

    const getSlackIntegration = useCallback((): Slack | undefined => {
      for (const integrationEdge of entity.integrations.edges) {
        if (isSlackIntegration(integrationEdge.node)) {
          return toSlackIntegration(integrationEdge.node);
        }
      }

      return undefined;
    }, [entity.integrations.edges]);

    const startSlackOAuth = useCallback(() => {
      const state = `${SLACK_CODE}:wandb:` + randID(20);
      localStorage.setItem(STATE_STORE_KEY, state);
      if (!serverInfo.data) {
        alert('unable to access slack configuration');
        return;
      }

      // eslint-disable-next-line wandb/no-unprefixed-urls
      window.open(
        `https://slack.com/oauth/authorize?${querystring.stringify({
          scope: 'incoming-webhook',
          client_id: serverInfo.data?.serverInfo?.slackClientID,
          state,
          redirect_uri: redirectUriRef.current,
        })}`,
        '_self'
      );
    }, [serverInfo.data]);

    const openDisconnectSlackModal = useCallback(
      () => setDisconnectSlackModalOpen(true),
      []
    );

    const closeDisconnectSlackModal = useCallback(
      () => setDisconnectSlackModalOpen(false),
      []
    );

    // Is called on page load when the oauth values are present in the url
    const createSlackIntegration = useCallback((): void => {
      const location = history.location;

      let params = history.location.search;

      // Extract query params
      if (location.search.startsWith('?')) {
        params = location.search.substring(1);
      }

      const queryParams = querystring.parse(params);
      const code = queryParams.code;
      const serverOAuthState = queryParams.state;

      // Only act for relevant responses, determined by a code we put in the state.
      if (!isSlackOAuth(serverOAuthState)) {
        return;
      }

      // Remove query params from url
      if (history.location.search !== '') {
        history.replace({search: ''});
      }

      // Missing code means the user is not going through the integration flow.
      if (code === undefined || typeof code !== 'string') {
        return;
      }

      // Quit if in progress
      if (slackIntegrationInProgress) {
        return;
      }

      // Check that state matches across request to prevent xsrf
      const storedOAuthState = localStorage.getItem(STATE_STORE_KEY);
      // Reset local state
      localStorage.removeItem(STATE_STORE_KEY);

      if (storedOAuthState === undefined) {
        captureError('No stored state ', 'slackintegration1', {
          extra: {oAuthState: serverOAuthState, storedOAuthState},
        });
        return;
      }

      // Check auth state to prevent csrf
      if (serverOAuthState !== storedOAuthState) {
        // Scope the response to the specific integration so we don't hoist responses
        captureError(
          "Oauth state doesn't match local value",
          'slackintegratin2',
          {
            extra: {oAuthState: serverOAuthState, storedOAuthState},
          }
        );
      }

      setSlackIntegrationInProgress(true);

      createSlackIntegrationMtn({
        code,
        redirectURI: redirectUriRef.current,
        entityName: entity.name,
      })
        .then(entityRefetch)
        .then(() => {
          setSlackIntegrationInProgress(false);
        });
    }, [
      createSlackIntegrationMtn,
      entity.name,
      entityRefetch,
      history,
      slackIntegrationInProgress,
    ]);

    const slackIntegration = getSlackIntegration();
    if (slackIntegration === undefined) {
      createSlackIntegration();
    }

    const header =
      slackIntegration !== undefined ? (
        <Table className="slack--table" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{fontWeight: 600}} width={10}>
                Slack integration
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Workspace</Table.Cell>
              <Table.Cell>{slackIntegration.teamName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Channel</Table.Cell>
              <Table.Cell>{slackIntegration.channelName}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <div className="slack--header-text">{integrationReason}</div>
      );

    if (serverInfo.loading) {
      return <Loader />;
    }

    return (
      <div className="slack">
        <div className="slack--header">{header}</div>
        <DeleteIntegrationComponent onCompleted={entityRefetch}>
          {(deleteIntegrationMutation: any) => {
            if (slackIntegration !== undefined) {
              return (
                <Modal
                  className="slack--disconnect-modal"
                  open={disconnectSlackModalOpen}
                  onClose={closeDisconnectSlackModal}
                  trigger={
                    <Button
                      className="slack--disconnect-modal-button"
                      size="tiny"
                      onClick={openDisconnectSlackModal}>
                      Disconnect Slack
                    </Button>
                  }>
                  <Header content="Are you sure you want to disconnect Slack?" />
                  <Modal.Content>
                    <p>We will no longer be able to send you alerts!</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic onClick={closeDisconnectSlackModal}>
                      Nevermind
                    </Button>
                    <Button
                      negative
                      size="tiny"
                      onClick={() => {
                        deleteIntegrationMutation({
                          variables: {
                            id: getSlackIntegration()!.id,
                          },
                        });

                        closeDisconnectSlackModal();
                      }}>
                      Disconnect
                    </Button>
                  </Modal.Actions>
                </Modal>
              );
            } else {
              return (
                <Button
                  positive
                  size="tiny"
                  className="slack--connect-button"
                  onClick={() => startSlackOAuth()}>
                  Connect Slack
                </Button>
              );
            }
          }}
        </DeleteIntegrationComponent>
      </div>
    );
  },
  {id: 'SlackIntegration', memo: true}
);

const withMutations = compose(
  createSlackChannelSubscrptionMutation,
  createSlackIntegrationMutation
) as any;

export default withMutations(SlackIntegration);
