import 'react-table/react-table.css';

import * as S from './ProjectsList.styles';

import _ from 'lodash';
import React, {FC, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Container, Input, List} from 'semantic-ui-react';

import {fuzzyMatchWithMapping} from '../../util/fuzzyMatch';
import {accessToEnglish} from '../../util/permissions';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {Graph, Project} from './graphql';
import makeComp from '../../util/profiler';
import {useViewer} from '../../state/viewer/hooks';
import {allProjects} from '../../util/urls';

interface ProjectsListProps {
  data: Graph<Project>;
  name: string;
  entityName: string;
}

const ProjectsList: FC<ProjectsListProps> = makeComp(
  ({data, entityName}) => {
    const viewer = useViewer();
    const searchInputRef = useRef<Input | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    let projects = _.map(data.edges, n => {
      const accessString = accessToEnglish(n.node.access);
      return {
        searchString: n.node.name,
        row: {
          entityName: n.node.entityName,
          name: n.node.name,
          accessString: accessString ? accessString : '',
          access: n.node.access,
        },
      };
    });
    const haveProjects = projects.length > 0;
    if (!_.isEmpty(searchQuery)) {
      projects = fuzzyMatchWithMapping(
        projects,
        searchQuery,
        d => d.searchString
      );
    }
    return (
      <div className="homepage" style={{marginTop: 24}}>
        <Container>
          <p className="subtitle">Projects</p>
          {haveProjects && (
            <Input
              className="searchbox project-search"
              icon={<LegacyWBIcon name="search" />}
              iconPosition="left"
              ref={searchInputRef}
              placeholder={'Search'}
              value={searchQuery}
              onChange={(e, {value}) => {
                setSearchQuery(value);
              }}
            />
          )}
          <div>
            <List>
              {projects.splice(0, 10).map(project => {
                return (
                  <List.Item
                    className="project-list-item"
                    key={project.row.name}
                    style={{wordBreak: 'break-all'}}>
                    <LegacyWBIcon
                      name={
                        _.includes(
                          ['USER_WRITE', 'USER_READ'],
                          project.row.access
                        )
                          ? 'lock-open'
                          : 'lock'
                      }
                    />
                    <List.Content>
                      <Link
                        to={`/${project.row.entityName}/${project.row.name}`}>
                        {project.row.entityName}/{project.row.name}
                      </Link>
                    </List.Content>
                  </List.Item>
                );
              })}
              {projects.length > 10 && viewer && viewer.username && (
                <List.Item>
                  <S.ViewAllIcon name="move" />
                  <List.Content style={{paddingLeft: 7}}>
                    <Link to={allProjects(viewer.username)}>View all</Link>
                  </List.Content>
                </List.Item>
              )}
              <List.Item style={{marginTop: projects.length === 0 ? 8 : 0}}>
                <LegacyWBIcon name="plus" />
                <List.Content style={{paddingLeft: 6}}>
                  <Link to={`/new-project?entityName=${entityName}`}>
                    Create new project
                  </Link>
                </List.Content>
              </List.Item>
            </List>
          </div>
        </Container>
      </div>
    );
  },
  {id: 'ProjectsList', memo: true}
);

export default ProjectsList;
