import React from 'react';
import {Checkbox} from 'semantic-ui-react';

import {Counts as RunCounts} from '../util/runs';
import MultiStateCheckbox from './MultiStateCheckbox';
import PopupDropdown from './PopupDropdown';
import makeComp from '../util/profiler';

interface RunSelectAllProps {
  runCounts?: RunCounts;
  allCheckedState: 'unchecked' | 'checked' | 'partial' | 'unknown';
  onlyShowSelected?: boolean;
  setOnlyShowSelected?(newSetting: boolean): void;
  selectAllVisible(): void; // copies visualized runs to temp selections
  onSelectAll(): void;
  onSelectNone(): void;
}

const RunSelectAll: React.FC<RunSelectAllProps> = makeComp(
  ({
    allCheckedState,
    onSelectAll,
    onSelectNone,
    runCounts,
    onlyShowSelected,
    setOnlyShowSelected,
    selectAllVisible,
  }) => {
    const selectedCount =
      runCounts && runCounts.runs && runCounts.selected && runCounts.selected;
    const dropdownOptions = [
      {
        key: 'selectall',
        content: 'Visualize all',
        onClick: onSelectAll,
      },
      {
        key: 'selectnone',
        content: 'Visualize none',
        onClick: onSelectNone,
      },
      {
        key: 'selectallvisible',
        content: 'Select all visible runs',
        onClick: selectAllVisible,
      },
      {
        key: 'onlyshowselected',
        onClick: () => {
          if (setOnlyShowSelected) {
            setOnlyShowSelected(!onlyShowSelected);
          }
        },
        content: (
          <Checkbox
            toggle
            checked={onlyShowSelected}
            label={`Only show visualized${
              selectedCount ? ` (${selectedCount})` : ''
            }`}
          />
        ),
      },
    ];
    return (
      <PopupDropdown
        options={dropdownOptions}
        className="popup-dropdown--selectall"
        trigger={
          <MultiStateCheckbox checked={allCheckedState} theme="visibility" />
        }
      />
    );
  },
  {id: 'RunSelectAll', memo: true}
);

export default RunSelectAll;
