import {TriggerGenerator, WBPopupTrigger} from '@wandb/ui';
import React from 'react';
import {useMutation} from 'react-apollo';
import {Checkbox, Loader} from 'semantic-ui-react';
import * as Generated from '../../generated/graphql';
import {CustomChartQueryData} from '../../graphql/customCharts';
import {useQuery} from '../../state/graphql/query';
import {useViewer} from '../../state/viewer/hooks';
import {viewerUsingAdminPrivileges} from '../../util/admin';
import makeComp from '../../util/profiler';
import {WBPopupDirection} from '@wandb/ui';
import * as S from './CustomChartAccess.styles';

interface EditCustomChartAccessFormHydratedProps {
  access: string;
  onAccessChange(access: string): void;
}

export const EditCustomChartAccessFormHydrated: React.FC<EditCustomChartAccessFormHydratedProps> =
  makeComp(
    ({access, onAccessChange}) => {
      const viewer = useViewer();
      let helperText: string;
      switch (access) {
        case 'PUBLIC':
          helperText =
            'Everyone will see this chart listed as an available option in the UI. Currently only works for charts made in the wandb team.';
          break;
        case 'UNLISTED':
          helperText =
            'Anyone can view instantiations of this chart in your reports and workspaces. Anyone with its ID can instantiate it from the CLI.';
          break;
        default:
          helperText =
            'Only members of this team can see and create instantiations of this chart. Even when used in public projects and reports, visitors from outside this team will be unable to view the chart.';
          break;
      }
      return (
        <>
          <Checkbox
            toggle
            checked={access === 'UNLISTED' || access === 'PUBLIC'}
            label="Publicly accessible"
            onChange={() => {
              onAccessChange(access === 'PRIVATE' ? 'UNLISTED' : 'PRIVATE');
            }}></Checkbox>

          {viewer?.admin &&
            viewerUsingAdminPrivileges() &&
            (access === 'UNLISTED' || access === 'PUBLIC') && (
              <S.AdminStripe>
                <Checkbox
                  toggle
                  checked={access === 'PUBLIC'}
                  label="Publicly listed (admin only)"
                  onChange={() => {
                    onAccessChange(
                      access === 'UNLISTED' ? 'PUBLIC' : 'UNLISTED'
                    );
                  }}></Checkbox>
              </S.AdminStripe>
            )}
          <S.HelperText>{helperText}</S.HelperText>
        </>
      );
    },
    {id: 'EditCustomChartAccessFormHydrated'}
  );

interface EditCustomChartAccessFormProps {
  customChartId: string;
  onAccessChange?(access: string): void;
}

export const EditCustomChartAccessForm: React.FC<EditCustomChartAccessFormProps> =
  makeComp(
    ({customChartId, onAccessChange}) => {
      const customChartQuery = useQuery<
        CustomChartQueryData,
        Generated.CustomChartQueryVariables
      >(Generated.CustomChartDocument, {
        variables: {id: customChartId},
      });
      const [updateCustomChart] = useMutation<
        Generated.UpdateCustomChartMutationResult,
        Generated.UpdateCustomChartMutationVariables
      >(Generated.UpdateCustomChartDocument);

      if (customChartQuery.loading) {
        return <Loader active size="tiny"></Loader>;
      }

      const {
        entity: {name: entityName},
        name,
        access,
      } = customChartQuery.customChart;
      return (
        <EditCustomChartAccessFormHydrated
          access={access}
          onAccessChange={newAccess => {
            updateCustomChart({
              variables: {
                entity: entityName,
                name,
                access: newAccess,
              },
            }).then(() => {
              onAccessChange?.(newAccess);
            });
          }}></EditCustomChartAccessFormHydrated>
      );
    },
    {id: 'EditCustomChartAccessForm'}
  );

interface EditCustomChartAccessTriggerProps {
  customChartId: string;
  direction?: WBPopupDirection;
  children: TriggerGenerator;
  onAccessChange?(access: string): void;
}

export const EditCustomChartAccessTrigger: React.FC<EditCustomChartAccessTriggerProps> =
  makeComp(
    ({customChartId, direction, children, onAccessChange}) => {
      return (
        <WBPopupTrigger
          direction={direction}
          popupContent={() => (
            <S.PopupWrapper>
              <EditCustomChartAccessForm
                customChartId={customChartId}
                onAccessChange={onAccessChange}
              />
            </S.PopupWrapper>
          )}
          triangleColor={'white'}>
          {children}
        </WBPopupTrigger>
      );
    },
    {id: 'EditCustomChartAccessTrigger'}
  );

interface CustomChartAccessIndicatorProps {
  access: string;
  readOnly?: boolean;
  open?: boolean;
  onClick?(): void;
}

export const CustomChartAccessIndicator = React.forwardRef<
  HTMLDivElement,
  CustomChartAccessIndicatorProps
>(({access, readOnly, onClick, open}, ref) => {
  return (
    <S.IndicatorIcon
      readOnly={readOnly}
      ref={ref}
      open={open}
      onClick={onClick}
      name={
        access === 'PUBLIC'
          ? 'globe'
          : access === 'UNLISTED'
          ? 'lock-open'
          : 'lock'
      }
    />
  );
});
