import React, {FunctionComponent, useState} from 'react';
import {Button, Dropdown} from 'semantic-ui-react';

import {DeleteProjectModal} from './DeleteProjectModal';
import {UndeleteRunsModal} from './UndeleteRunsModal';
import makeComp from '../util/profiler';
import {ProjectData} from '../state/graphql/projectPageQuery';

export interface ExtraProjectActionsProps {
  project: ProjectData;
  deleteProject(): void;
  undeleteRuns(): void;
}

export const ExtraProjectActions: FunctionComponent<ExtraProjectActionsProps> =
  makeComp(
    ({project, deleteProject, undeleteRuns}) => {
      const [deleteProjectModalOpen, setDeleteProjectModalOpen] =
        useState(false);
      const [undeleteRunsModalOpen, setUndeleteRunsModalOpen] = useState(false);

      return (
        <div>
          <Dropdown
            className="dropdown-menu-icon-button"
            floating
            trigger={<Button size="tiny" icon="ellipsis vertical" />}
            direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                icon="trash"
                content="Delete project"
                onClick={() => setDeleteProjectModalOpen(true)}
              />
              <Dropdown.Item
                icon="refresh"
                content="Undelete recently deleted runs"
                onClick={() => setUndeleteRunsModalOpen(true)}
              />
            </Dropdown.Menu>
          </Dropdown>
          <DeleteProjectModal
            open={deleteProjectModalOpen}
            projectName={project.name}
            onClose={() => setDeleteProjectModalOpen(false)}
            deleteProject={deleteProject}
          />
          <UndeleteRunsModal
            open={undeleteRunsModalOpen}
            onClose={() => setUndeleteRunsModalOpen(false)}
            undeleteRuns={undeleteRuns}
          />
        </div>
      );
    },
    {id: 'ExtraProjectActions'}
  );
