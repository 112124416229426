import gql from 'graphql-tag';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {CellInfo, Column} from 'react-table';
import 'react-table/react-table.css';
import {Graph} from '../types/graphql';
import * as FuzzyMatch from '../util/fuzzyMatch';
import {NavLinkNoCrawl} from '../util/links';
import makeComp from '../util/profiler';
import {runGroup} from '../util/urls';
import ImageIconHeader from './ImageIconHeader';
import WBReactTable from './WBReactTable';

import TimeAgo from 'react-timeago';

interface RunsTableProps {
  data: Graph<RunsTableRun>;
  name: string;
  showUserColumn: boolean;
  pageSize?: number | null;
  onChangePageSize?: (pageSize: number) => void;
}

export interface RunsTableRun {
  id: string;
  name: string;
  displayName: string;
  state: string;
  createdAt: string;
  heartbeatAt: string;
  updatedAt: string;
  user: {
    id: string;
    username: string;
  };
  project: {
    id: string;
    name: string;
    entityName: string;
  };
  groupCounts?: number[];
}

export const runsTableFragment = gql`
  fragment RunsTableFragment on Run {
    id
    name
    displayName
    state
    createdAt
    heartbeatAt
    updatedAt
    user {
      id
      username
    }
    project {
      id
      name
      entityName
    }
    groupCounts
  }
`;

const RunsTable: React.FC<RunsTableProps> = makeComp(
  ({data, name, showUserColumn, pageSize, onChangePageSize}) => {
    const columns = useCallback(
      (searchQuery?: string) => {
        const nameColumn: Column = {
          Header: 'Name',
          accessor: '',
          Cell: (cellInfo: CellInfo) => {
            const row = cellInfo.value;
            const run: RunsTableRun = row.run;
            const project = run.project;
            const groupCounts = run.groupCounts || [];

            let namePieces: string[];
            if (!searchQuery) {
              namePieces = [run.displayName];
            } else {
              namePieces = FuzzyMatch.fuzzyComponentSplit(
                [run.displayName, project.name],
                searchQuery
              )[0];
            }

            if (groupCounts.length > 0) {
              return (
                <NavLinkNoCrawl
                  to={runGroup({
                    entityName: project.entityName,
                    projectName: project.name,
                    name: run.name,
                  })}>
                  <div>
                    {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}{' '}
                    <span style={{color: 'gray'}}>
                      {' ('}
                      {groupCounts[0]}
                      {groupCounts[0] === 1 ? ' run)' : ' runs)'}
                    </span>
                  </div>
                </NavLinkNoCrawl>
              );
            } else {
              return (
                <NavLinkNoCrawl
                  to={`/${run.project.entityName}/${run.project.name}/runs/${run.name}`}>
                  {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}
                </NavLinkNoCrawl>
              );
            }
          },
        };
        const projectColumn: Column = {
          Header: 'Project',
          accessor: '',
          Cell: (cellInfo: CellInfo) => {
            const row = cellInfo.value;
            const run = row.run;
            const project = run.project;

            let namePieces: string[];
            if (!searchQuery) {
              namePieces = [project.name];
            } else {
              namePieces = FuzzyMatch.fuzzyComponentSplit(
                [run.displayName, project.name],
                searchQuery
              )[1];
            }

            return (
              <NavLinkNoCrawl to={`/${project.entityName}/${project.name}`}>
                {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}
              </NavLinkNoCrawl>
            );
          },
        };
        const userColumn: Column = {
          Header: 'User',
          accessor: 'run.user.username',
          Cell: (cellInfo: CellInfo) => {
            const username = cellInfo.value;
            return (
              <NavLinkNoCrawl to={`/${username}`}>{username}</NavLinkNoCrawl>
            );
          },
        };
        const stateColumn: Column = {Header: 'State', accessor: 'state'};
        const createdColumn: Column = {
          Header: 'Created',
          accessor: 'createdAt',
        };
        const allColumns = [
          nameColumn,
          projectColumn,
          stateColumn,
          createdColumn,
        ];
        if (showUserColumn) {
          allColumns.push(userColumn);
        }
        return allColumns;
      },
      [showUserColumn]
    );

    const runs = _.map(
      // runs *should* always have a project, but sometimes runs aren't deleted
      // when a project is -- this guards against that case
      data.edges.filter(n => !!n.node.project),
      n => {
        return {
          searchString: `${n.node.displayName}${n.node.project.name}`,
          row: {
            searchString: `${n.node.displayName}${n.node.project.name}`,
            run: n.node,
            state: n.node.state,
            createdAt: <TimeAgo date={n.node.createdAt + 'Z'} />,
          },
        };
      }
    );

    return runs.length === 0 ? (
      <div />
    ) : (
      <>
        <ImageIconHeader icon="run" text="Runs" />
        <WBReactTable
          data={runs}
          columns={columns()}
          searchResultColumns={columns}
          pageSize={pageSize ?? 10}
          onChangePageSize={onChangePageSize}
        />
      </>
    );
  },
  {id: 'RunsTable', memo: true}
);

export default RunsTable;
