import React, {useCallback} from 'react';
import {Button} from 'semantic-ui-react';
import '../css/SubscribeForm.less';
import {useCreateStorageSubscriptionMutation} from '../generated/graphql';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {captureError} from '../util/integrations';
import makeComp from '../util/profiler';
import StripeForm, {SubmitParams} from './StripeForm';

type StorageSubscribeFormProps = {
  storagePlanID: string;
  trackingPlanID: string;
  orgID?: string;
  onSubscriptionCompleted: () => void;
};

export const StorageSubscribeForm: React.FC<StorageSubscribeFormProps> =
  makeComp(
    props => {
      const {storagePlanID, trackingPlanID, orgID, onSubscriptionCompleted} =
        props;

      const [createStorageSubscriptionMutation] =
        useCreateStorageSubscriptionMutation({
          context: {...propagateErrorsContext(), ...doNotRetryContext()},
        });

      const submit = useCallback(
        async ({
          stripe,
          custoEmail,
          paymentMethodID,
          setErrMsg,
        }: SubmitParams) => {
          const subscription = await subscribe();
          if (subscription == null) {
            return false;
          }

          const paymentIntent = subscription.latest_invoice.payment_intent;
          const clientSecret = paymentIntent?.client_secret;
          const paymentIntentStatus = paymentIntent?.status;
          const needsPayment =
            ['requires_action', 'requires_payment_method'].indexOf(
              paymentIntentStatus
            ) !== -1;

          if (needsPayment) {
            const paymentConfirmed = await confirmPayment(clientSecret);
            if (!paymentConfirmed) {
              return false;
            }
          }

          onSubscriptionCompleted();
          return true;

          // HELPER FUNCTIONS

          async function subscribe(): Promise<{latest_invoice: any} | null> {
            try {
              const createSubResult = await createStorageSubscriptionMutation({
                variables: {
                  storagePlanId: storagePlanID,
                  trackingPlanId: trackingPlanID,
                  trial: false,
                  ...(paymentMethodID != null
                    ? {paymentMethod: paymentMethodID}
                    : {organizationId: orgID}),
                },
              });

              const sub =
                createSubResult.data?.createStorageSubscription?.subscription;

              if (createSubResult.errors != null || sub == null) {
                onError({
                  err: "Unknown error confirming subscription. Exception didn't throw but success was not reported.",
                  op: 'handleSubscribe-unknown',
                  userFacingErrorMsg:
                    'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
                });
                return null;
              }

              return sub;
            } catch (err) {
              const errMsg =
                extractErrorMessageFromApolloError(err) ?? err.message;
              onError({
                err,
                op: 'createSubResult',
                userFacingErrorMsg: `Error processing storage subscription payment: ${errMsg}`,
                analyticsEvent: 'Error processing subscription payment',
                extra: {
                  reason: errMsg,
                },
              });
              return null;
            }
          }

          async function confirmPayment(secret: string): Promise<boolean> {
            try {
              const result = await stripe.confirmCardPayment(secret);

              if (result.error) {
                onError({
                  err: result.error.message!,
                  op: 'handleCardPayment',
                  userFacingErrorMsg: `We had trouble confirming your payment: ${result.error.message}`,
                });
                return false;
              }

              return true;
            } catch (err) {
              onError({
                err,
                op: 'handleCardPayment',
                userFacingErrorMsg: `We had trouble confirming your payment: ${err}`,
              });
              return false;
            }
          }

          type ErrOpts = {
            err: Error | string;
            op: string;
            userFacingErrorMsg: string;
            analyticsEvent?: string;
            extra?: {[key: string]: any};
          };
          function onError(opts: ErrOpts) {
            const extra = {
              email: custoEmail,
              storageID: storagePlanID,
              trackingID: trackingPlanID,
              units: 1,
              trial: false,
              ...opts.extra,
            };
            captureError(opts.err, `StorageSubscribeForm-${opts.op}`, {
              extra,
            });
            if (opts.analyticsEvent != null) {
              window.analytics.track(opts.analyticsEvent, extra);
            }
            setErrMsg(opts.userFacingErrorMsg);
          }
        },
        [
          storagePlanID,
          trackingPlanID,
          createStorageSubscriptionMutation,
          onSubscriptionCompleted,
          orgID,
        ]
      );

      return (
        <StripeForm
          hideOrgName
          hideCardFields={orgID != null}
          renderButtons={({submitWrapper, formDisabled, submitting}) => (
            <Button
              className="subscribe-button"
              color="blue"
              onClick={() => {
                window.analytics.track(
                  'Storage subscribe purchase button clicked'
                );
                submitWrapper(submit);
              }}
              disabled={formDisabled}>
              {submitting ? 'Processing...' : 'Subscribe now'}
            </Button>
          )}
        />
      );
    },
    {id: 'StorageSubscribeForm', memo: true}
  );
