import '../css/SearchNav.less';

import {History} from 'history';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Button, Dropdown, Image, Input, Label, Popup} from 'semantic-ui-react';

import config, {envIsLocal, urlPrefixed} from '../config';
import AutoRefreshSettings from '../containers/AutoRefreshSettings';
import {auth, isInIframe} from '../setup';
import {Match} from '../types/base';
import {Graph} from '../types/graphql';
import {
  setViewerUsingAdminPrivileges,
  viewerUsingAdminPrivileges,
} from '../util/admin';
import docUrl from '../util/doc_urls';
import {fuzzyMatchHighlight, fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import {AccessOptions} from '../util/permissions';
import {getSweepDisplayName} from '../util/sweeps';
import * as Urls from '../util/urls';
import {Breadcrumbs} from './Breadcrumbs.1';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {WBIcon} from '@wandb/ui';
import {LoginButton} from './LoginButton';
import {ProjectAccessProject} from './ProjectAccess';
import WBLogo from './WBLogo';
import {hotkeyPressed} from '../util/hotkeys';
import {openBeamer} from '../util/beamer';
import BeamerIcon from './BeamerIcon';
import makeComp from '../util/profiler';

import {CreateTeamModal} from './CreateTeamModal';
import {InviteTeamButton} from './InviteTeamButton';
import {AddUserModal} from './AddUserModal';
import {
  OrgType,
  useServerInfoQuery,
  useSearchNavQueryQuery,
  useUpsertModelMutation,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {useHasNavigation} from '../state/global/hooks';
import {GALLERY_PATH_SEGMENT} from '../routeData';
import classNames from 'classnames';
import {TargetBlank} from '../util/links';
import {decodeURIComponentSafe} from '../util/url';

interface LocalSearchResult {
  type: 'project' | 'team';
  name: string;
  photoUrl?: string;
  meta?: string;
  url: string;
}

interface LocalSearchResultItemProps {
  result: LocalSearchResult;
  selected?: boolean;
  query: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const LocalSearchResultItem: React.FC<LocalSearchResultItemProps> = makeComp(
  ({result, query, onClick, selected}) => {
    return (
      <Link
        onClick={onClick}
        className={`search-result ${selected ? 'selected' : ''}`}
        to={result.url}>
        <div className="search-result-left-wrapper">
          {result.type === 'project' && (
            <LegacyWBIcon name="folder" className="search-result-icon" />
          )}
          {result.type === 'team' && result.photoUrl && (
            <Image src={result.photoUrl} className="search-result-photo" />
          )}
          <div className="search-result-name">
            {fuzzyMatchHighlight(result.name, query)}
          </div>
        </div>
        {result.meta && <div className="search-result-meta">{result.meta}</div>}
      </Link>
    );
  },
  {id: 'LocalSearchResultItem', memo: true}
);

interface LocalSearchProps {
  data: SearchNavQueryData;
  match: Match;
  history: any;
  onClose: () => void;
}

const RESULTS_LIMIT = 5;

const LocalSearch: React.FC<LocalSearchProps> = makeComp(
  ({data, match, history, onClose}) => {
    const [currentQuery, setCurrentQuery] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showAllEntityResults, setShowAllEntityResults] = useState(false);
    const [showAllContributedResults, setShowAllContributedResults] =
      useState(false);
    const [showAllRemainingResults, setShowAllRemainingResults] =
      useState(false);

    const inputRef = useRef<Input | null>(null);
    const keyboardOptionsRef = useRef<LocalSearchResult[]>([]);

    const blur = useCallback(() => {
      onClose();
      if (document.activeElement) {
        (document.activeElement as any).blur();
      }
    }, [onClose]);

    const clearQuery = useCallback(() => {
      setCurrentQuery('');
    }, []);

    const blurAndClear = useCallback(() => {
      onClose();
      setCurrentQuery('');
      if (document.activeElement) {
        (document.activeElement as any).blur();
      }
    }, [onClose]);

    const onKeyDown = useCallback(
      (e: any) => {
        if (e.keyCode === 27) {
          blur();
        }
        if (e.keyCode === 13) {
          const option = keyboardOptionsRef.current[selectedIndex];
          if (option != null) {
            history.push(option.url);
            blurAndClear();
          }
        }
        if (e.keyCode === 38) {
          e.preventDefault();
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          }
        }
        if (e.keyCode === 40) {
          e.preventDefault();
          if (selectedIndex < keyboardOptionsRef.current.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          }
        }
      },
      [blur, blurAndClear, history, selectedIndex]
    );

    const projectToLocalSearchResult: (
      project: SearchNavProject
    ) => LocalSearchResult = useCallback((project: SearchNavProject) => {
      return {
        type: 'project',
        name: project.name,
        meta: project.entityName,
        url: Urls.project({
          name: project.name,
          entityName: project.entityName,
        }),
      };
    }, []);

    const teamToLocalSearchResult: (team: SearchNavTeam) => LocalSearchResult =
      useCallback((team: SearchNavTeam) => {
        return {
          type: 'team',
          name: team.name,
          url: Urls.teamPage(team.name),
          photoUrl: team.photoUrl,
        };
      }, []);

    const {entityName} = match.params;

    if (!data.viewer) {
      return <></>;
    }

    const alreadyFoundId: {
      [key: string]: boolean;
    } = {};

    const entityOptions = data.projects.edges.map(project => {
      alreadyFoundId[project.node.id] = true;
      return projectToLocalSearchResult(project.node);
    });

    const contributedOptions: LocalSearchResult[] = [];
    if (currentQuery) {
      data.viewer.projects.edges.forEach(project => {
        if (!alreadyFoundId[project.node.id]) {
          alreadyFoundId[project.node.id] = true;
          contributedOptions.push(projectToLocalSearchResult(project.node));
        }
      });
    }

    const remainingOptions: LocalSearchResult[] = [];
    if (currentQuery) {
      data.viewer.teams.edges.forEach(team => {
        remainingOptions.push(teamToLocalSearchResult(team.node));
        team.node.projects.edges.forEach(project => {
          if (!alreadyFoundId[project.node.id]) {
            remainingOptions.push(projectToLocalSearchResult(project.node));
          }
        });
      });
    }

    const searchInput = (
      <>
        <Input
          autoFocus
          icon={<LegacyWBIcon name="search" />}
          ref={inputRef}
          iconPosition="left"
          className="local-search-input"
          placeholder={'Find project or team'}
          value={currentQuery}
          onChange={e => {
            setCurrentQuery(e.currentTarget.value);
            setShowAllEntityResults(false);
            setShowAllContributedResults(false);
            setShowAllRemainingResults(false);
          }}
          onKeyDown={onKeyDown}
        />
        {currentQuery && (
          <LegacyWBIcon
            name="close"
            className="close-button"
            onMouseDown={(e: any) => e.preventDefault()}
            onClick={clearQuery}
          />
        )}
        <div
          className="cancel-button"
          onMouseDown={(e: any) => e.preventDefault()}
          onClick={blur}>
          Cancel
        </div>
      </>
    );

    const entityResults = fuzzyMatchWithMapping<LocalSearchResult>(
      entityOptions,
      currentQuery,
      result => result.name
    );

    let displayedEntityResults = entityResults;
    if (!showAllEntityResults) {
      displayedEntityResults = entityResults.slice(0, RESULTS_LIMIT);
    }

    const contributedResults = fuzzyMatchWithMapping<LocalSearchResult>(
      contributedOptions,
      currentQuery,
      result => result.name
    );

    let displayedContributedResults = contributedResults;
    if (!showAllContributedResults) {
      displayedContributedResults = contributedResults.slice(0, RESULTS_LIMIT);
    }

    const remainingResults = fuzzyMatchWithMapping<LocalSearchResult>(
      remainingOptions,
      currentQuery,
      result => result.name
    );

    let displayedRemainingResults = remainingResults;
    if (!showAllRemainingResults) {
      displayedRemainingResults = remainingResults.slice(0, RESULTS_LIMIT);
    }

    keyboardOptionsRef.current = displayedEntityResults.concat(
      displayedContributedResults.concat(displayedRemainingResults)
    );

    const hiddenEntityResultsCount = entityResults.length - RESULTS_LIMIT;
    const entityResultsList = (
      <>
        {displayedEntityResults.map((result, index) => (
          <LocalSearchResultItem
            key={result.name + '|' + result.meta}
            onClick={blurAndClear}
            result={result}
            query={currentQuery}
            selected={selectedIndex === index}
          />
        ))}
        {!showAllEntityResults && entityResults.length > RESULTS_LIMIT && (
          <div
            className="show-more"
            onClick={() => {
              setShowAllEntityResults(true);
            }}>
            {hiddenEntityResultsCount} more result
            {hiddenEntityResultsCount === 1 ? '' : 's'} in this entity ➞
          </div>
        )}
      </>
    );

    const hiddenContributedResultsCount =
      contributedResults.length - RESULTS_LIMIT;
    const contributedResultsList = (
      <>
        {displayedContributedResults.map((result, index) => (
          <LocalSearchResultItem
            key={result.name + '|' + result.meta}
            onClick={blurAndClear}
            result={result}
            query={currentQuery}
            selected={selectedIndex === displayedEntityResults.length + index}
          />
        ))}
        {!showAllContributedResults &&
          contributedResults.length > RESULTS_LIMIT && (
            <div
              className="show-more"
              onClick={() => {
                setShowAllContributedResults(true);
              }}>
              {hiddenContributedResultsCount} more contributed result
              {hiddenContributedResultsCount === 1 ? '' : 's'} ➞
            </div>
          )}
      </>
    );

    const hiddenRemainingResultsCount = remainingResults.length - RESULTS_LIMIT;
    const remainingResultsList = (
      <>
        {displayedRemainingResults.map((result, index) => (
          <LocalSearchResultItem
            key={result.name + '|' + result.meta}
            onClick={blurAndClear}
            result={result}
            query={currentQuery}
            selected={
              selectedIndex ===
              displayedEntityResults.length +
                displayedContributedResults.length +
                index
            }
          />
        ))}
        {!showAllRemainingResults && remainingResults.length > RESULTS_LIMIT && (
          <div
            className="show-more"
            onClick={() => {
              setShowAllRemainingResults(true);
            }}>
            {hiddenRemainingResultsCount} more result
            {hiddenRemainingResultsCount === 1 ? '' : 's'} ➞
          </div>
        )}
      </>
    );

    const entityPageResultsLists =
      displayedEntityResults.length +
        displayedContributedResults.length +
        displayedRemainingResults.length >
      0 ? (
        <>
          {displayedEntityResults.length > 0 && (
            <>
              <div className="search-result-header">In this entity</div>
              {entityResultsList}
            </>
          )}
          {displayedContributedResults.length > 0 && (
            <>
              <div className="search-result-header">Contributed to</div>
              {contributedResultsList}
            </>
          )}
          {displayedRemainingResults.length > 0 && (
            <>
              <div className="search-result-header">From your teams</div>
              {remainingResultsList}
            </>
          )}
        </>
      ) : (
        <div className="no-search-results">No results</div>
      );

    const homePageResultsLists =
      displayedContributedResults.length + displayedRemainingResults.length >
      0 ? (
        <>
          {displayedContributedResults.length > 0 && (
            <>
              <div className="search-result-header">Contributed to</div>
              {contributedResultsList}
            </>
          )}
          {displayedRemainingResults.length > 0 && (
            <>
              <div className="search-result-header">From your teams</div>
              {remainingResultsList}
            </>
          )}
        </>
      ) : (
        <div className="no-search-results">No results</div>
      );

    const entityPageStarterContent =
      displayedEntityResults.length > 0 ? (
        <>
          <div className="search-result-header">In this entity</div>
          {entityResultsList}
        </>
      ) : (
        <div className="no-search-results">No projects in this entity</div>
      );

    const homePageStarterContent = (
      <div className="team-items">
        {data.viewer.teams.edges.map(team => (
          <Link
            key={team.node.id}
            onClick={blurAndClear}
            className="team-item"
            to={Urls.teamPage(team.node.name)}>
            <Image className="team-image" src={team.node.photoUrl} />
            <div className="team-name">{team.node.name}</div>
          </Link>
        ))}
      </div>
    );

    const searchResultsDropdown = (
      <div
        className="local-search-dropdown"
        onMouseDown={(e: any) => e.preventDefault()}>
        <div className="dropdown-content">
          {currentQuery ? (
            <div className="search-results">
              {entityName ? entityPageResultsLists : homePageResultsLists}
            </div>
          ) : entityName ? (
            entityPageStarterContent
          ) : (
            homePageStarterContent
          )}
        </div>
      </div>
    );

    return (
      <div
        className={`local-search ${currentQuery ? '' : 'empty'}`}
        onBlur={blur}>
        {searchInput}
        {searchResultsDropdown}
      </div>
    );
  },
  {id: 'LocalSearch', memo: true}
);

interface SearchNavProps {
  match: Match;
  history: History;
}

const SearchNav: React.FC<SearchNavProps> = makeComp(
  ({match, history}) => {
    const viewer = useViewer();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searching, setSearching] = useState(false);

    const handleGlobalKeyDown = useCallback((e: KeyboardEvent) => {
      if (hotkeyPressed(e, 'globalSearch')) {
        setSearching(true);
      }
    }, []);

    useEffect(() => {
      document.addEventListener('keydown', handleGlobalKeyDown);
      return () => {
        document.removeEventListener('keydown', handleGlobalKeyDown);
      };
      // eslint-disable-next-line
    }, []);

    const serverInfoQuery = useServerInfoQuery();
    const isGoogleIap = serverInfoQuery.data?.serverInfo?.isGoogleIap;

    const {entityName, runName, groupName, sweepName, reportNameAndID} =
      match.params;

    // HAX: the project name URI we get from react-router might be encoded.
    // Decode this URI component before using it.
    // If this happens again on another URI component (e.g. entity name), we
    // should do more general fix at the react-router layer.
    const projectName =
      match.params.projectName != null
        ? decodeURIComponentSafe(match.params.projectName)
        : undefined;

    let pageType = 'project';
    if (runName != null) {
      pageType = 'run';
    }

    const {data: searchNavData} = useSearchNavQueryQuery({
      variables: {
        entityName: entityName ?? '',
        projectName: projectName ?? '',
        runName: runName ?? '',
        sweepName: sweepName ?? '',
      },
    });
    const data = searchNavData as SearchNavQueryData;

    const [upsertModelMutation] = useUpsertModelMutation();
    const [availableNavigation, updateNav] = useHasNavigation();

    const orgOptions = viewer?.organizations?.filter(
      org => org.orgType === OrgType.Organization
    );

    const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;
    const isVm = isOnPrem && config.ENVIRONMENT_NAME === 'onprem-vm';
    const isLocal = isOnPrem && config.ENVIRONMENT_NAME === 'local';
    const canLogout = !config.AUTH_STUB_JWT || config.AUTH_STUB_JWT === 'false';

    // hide the search nav in gallery for mobile
    const currentPath: string = window.location.pathname.split('/')[1] ?? '';
    const isMobileGallery: boolean = currentPath === GALLERY_PATH_SEGMENT;
    const isSignup = currentPath === 'signup';

    // If artifact type name is available, decode any '@' chars
    // for the correct artifact type name to display in the nav.
    const artifactTypeName =
      match.params.artifactTypeName != null
        ? decodeURIComponent(match.params.artifactTypeName)
        : undefined;

    // TODO: this component is getting re-rendered on each poll...
    return (
      <div
        className={`search-nav ${
          isMobileGallery ? 'hide-in-mobile-gallery' : ''
        }`}
        id="searchNav">
        <div className="nav-left-container">
          <WBLogo
            onClick={(e: any) => {
              if (isInIframe()) {
                e.preventDefault();
                window.open(urlPrefixed());
              }
            }}
          />
          {isInIframe() && (
            <>
              <h1>Weights &amp; Biases</h1>
              <WBIcon
                className={classNames('nav', {
                  backward: availableNavigation.backward,
                })}
                title="Go backward"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  availableNavigation.backwardCount += 1;
                  updateNav(availableNavigation);
                  history.goBack();
                }}
                useNewIconComponent
                name="chevron-left"
              />
              <WBIcon
                className={classNames('nav', {
                  forward: availableNavigation.forward,
                })}
                title="Go forward"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  availableNavigation.backwardCount -= 1;
                  updateNav(availableNavigation);
                  history.goForward();
                }}
                useNewIconComponent
                name="chevron-right"
              />
            </>
          )}
          {!isInIframe() && (
            <>
              {!isSignup && data && viewer && (
                <>
                  <LegacyWBIcon
                    name="search"
                    className="local-search-toggle"
                    onClick={() => setSearching(!searching)}
                  />
                  {searching && (
                    <LocalSearch
                      data={data}
                      match={match}
                      history={history}
                      onClose={() => {
                        setSearching(false);
                      }}
                    />
                  )}
                </>
              )}
              {entityName && (
                <Breadcrumbs
                  entityName={entityName}
                  projectName={projectName}
                  project={data && data.project}
                  tab={match.params.tab}
                  artifactTypeName={artifactTypeName}
                  artifactCollectionName={match.params.artifactCollectionName}
                  artifactCommitHash={match.params.artifactCommitHash}
                  artifactTab={match.params.artifactTab}
                  filePath={match.params.filePath}
                  updateProjectAccess={(access: AccessOptions) =>
                    projectName != null &&
                    data &&
                    data.project &&
                    upsertModelMutation({
                      variables: {
                        entityName,
                        name: projectName,
                        access,
                      },
                    })
                  }
                  groupName={groupName}
                  sweepName={
                    data &&
                    data.project &&
                    data.project.sweep &&
                    data.project.sweep.name
                  }
                  sweepDisplayName={
                    data &&
                    data.project &&
                    data.project.sweep &&
                    getSweepDisplayName(data.project.sweep)
                  }
                  reportNameAndId={reportNameAndID}
                  isBenchmark={data && data.project && data.project.isBenchmark}
                  runName={runName}
                  runDisplayName={
                    data &&
                    data.project &&
                    data.project.run &&
                    data.project.run.displayName
                  }
                  runState={
                    data &&
                    data.project &&
                    data.project.run &&
                    data.project.run.state
                  }
                  // authorName={authorName}
                  pageType={pageType}
                />
              )}
            </>
          )}
        </div>
        {isInIframe() ? (
          <TargetBlank
            className="icon"
            href={document.location.href.replace('jupyter=true', '')} // TODO: actually parse the query and handle other flags?
          >
            Open page <WBIcon style={{marginLeft: 5}} name="right-arrow" />
          </TargetBlank>
        ) : (
          <>
            {viewer != null && !envIsLocal && <InviteTeamButton />}
            {viewer != null && envIsLocal && (
              <>
                <Button
                  color="yellow"
                  onClick={() => setShowModal(true)}
                  style={{
                    padding: '6px 9px',
                    alignSelf: 'center',
                    margin: '0 16px 0 0 ',
                  }}>
                  <span style={{fontSize: '14px'}}>Invite team</span>
                </Button>
                {showModal && (
                  <AddUserModal
                    data-test="invite-team"
                    open={showModal}
                    header={<div>Invite team</div>}
                    onOpen={() => setShowModal(true)}
                    onClose={() => setShowModal(false)}
                  />
                )}
              </>
            )}
          </>
        )}
        {!isInIframe() && (
          <div className="viewer-dropdown">
            {viewer ? (
              <Dropdown
                className="nav-propic-dropdown"
                direction="left"
                trigger={
                  <>
                    <Image
                      className="nav-propic"
                      src={viewer.photoUrl}
                      avatar
                    />
                    <BeamerIcon absolute />
                  </>
                }>
                <Dropdown.Menu>
                  {!isSignup && (
                    <>
                      <Link to={Urls.profilePage(viewer.username ?? '')}>
                        <Dropdown.Item>Profile</Dropdown.Item>
                      </Link>
                      <Link to={Urls.settings()}>
                        <Dropdown.Item>Settings</Dropdown.Item>
                      </Link>
                      {!isOnPrem && (
                        <Link to={Urls.subscriptions()}>
                          <Dropdown.Item>Subscriptions</Dropdown.Item>
                        </Link>
                      )}
                      {!isOnPrem && (orgOptions?.length ?? 0) > 0 && (
                        <CreateTeamModal
                          orgOptions={
                            orgOptions?.map(o => ({
                              text: o.name,
                              value: o.id,
                            })) ?? []
                          }
                          trigger={<Dropdown.Item>Create team</Dropdown.Item>}
                          onCreate={teamName => history.push(`/${teamName}`)}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        />
                      )}
                      <AutoRefreshSettings>
                        {(autoRefreshEnabled, setAutoRefreshEnabled) => (
                          <Dropdown.Item
                            onClick={() =>
                              setAutoRefreshEnabled(!autoRefreshEnabled)
                            }>
                            Auto-refresh
                            <Label
                              className={classNames('auto-refresh-label', {
                                active: autoRefreshEnabled,
                              })}
                              horizontal>
                              {autoRefreshEnabled ? 'On' : 'Off'}
                            </Label>
                          </Dropdown.Item>
                        )}
                      </AutoRefreshSettings>
                      {viewer.admin && !isLocal && (
                        <Dropdown.Item
                          onClick={() => {
                            setViewerUsingAdminPrivileges(
                              !viewerUsingAdminPrivileges()
                            );
                            window.location.reload();
                          }}>
                          Admin powers{' '}
                          <Label
                            className={classNames('auto-refresh-label', {
                              active: viewerUsingAdminPrivileges(),
                            })}
                            horizontal>
                            {viewerUsingAdminPrivileges() ? 'On' : 'Off'}
                          </Label>
                        </Dropdown.Item>
                      )}
                      {isVm && (
                        <Dropdown.Item href="/vm-settings" text="VM Settings" />
                      )}
                      {isLocal && viewer.admin && (
                        <>
                          <Dropdown.Item
                            href={urlPrefixed('/system-admin', false)}
                            text="System Settings"
                          />
                          <Link to={Urls.userManagement()}>
                            <Dropdown.Item text="Users" />
                          </Link>
                          <Dropdown.Item
                            href={urlPrefixed('/admin/usage', false)}
                            text="Activity Dashboard"
                          />
                        </>
                      )}
                      <Dropdown.Divider />
                    </>
                  )}
                  <Dropdown.Item href={docUrl.root} text="Documentation" />
                  {!isVm && !isLocal && (
                    <>
                      <Dropdown.Item onClick={openBeamer}>
                        What's new
                        <BeamerIcon />
                      </Dropdown.Item>
                      <TargetBlank href={Urls.communityForum()}>
                        <Dropdown.Item>Community</Dropdown.Item>
                      </TargetBlank>
                      <Link to={Urls.reportGallery()}>
                        <Dropdown.Item>Fully Connected</Dropdown.Item>
                      </Link>
                    </>
                  )}
                  {canLogout && (
                    <>
                      <Dropdown.Divider />
                      {isGoogleIap ? (
                        <Popup
                          content="This instance is using Google IAP for authentication, so you'll need to log out of your Google account to log out of wandb."
                          position="left center"
                          trigger={
                            <Dropdown.Item
                              className="disabled-log-out-dropdown-item"
                              disabled
                              text="Log out"
                            />
                          }
                        />
                      ) : (
                        <Dropdown.Item
                          onClick={() => auth.logout()}
                          text="Log out"
                        />
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="logged-out-menu">
                <Dropdown
                  direction="left"
                  text={'Help'}
                  className="help-dropdown">
                  <Dropdown.Menu>
                    <Dropdown.Item href={'/site'} text="Company website" />
                    <Dropdown.Divider />
                    <Dropdown.Item href={docUrl.root} text="Documentation" />
                    {!isVm && !isLocal && (
                      <>
                        <Dropdown.Item
                          as={TargetBlank}
                          href={Urls.communityForum()}
                          text="Community"
                        />
                        <Dropdown.Item
                          as={Link}
                          to={Urls.reportGallery()}
                          text="Fully Connected"
                        />
                        <Dropdown.Divider />
                        <Dropdown.Item
                          href="/site/contact"
                          text="Request a demo"
                        />
                        <Dropdown.Item
                          href="/site/pricing"
                          text="Private teams"
                        />
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <LoginButton />
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
  {id: 'SearchNav', memo: true}
);

export default SearchNav;

interface SearchNavProject {
  id: string;
  name: string;
  entityName: string;
}

interface SearchNavTeam {
  id: string;
  name: string;
  photoUrl: string;
  projects: Graph<SearchNavProject>;
}

interface SearchNavOrganizations {
  id: string;
  name: string;
  orgType: string;
}

interface SearchNavQueryData {
  viewer?: {
    id: string;
    admin: string;
    photoUrl: string;
    username: string;
    teams: Graph<SearchNavTeam>;
    projects: Graph<SearchNavProject>;
    organizations: SearchNavOrganizations[];
  };
  projects: Graph<SearchNavProject>;
  project?: ProjectAccessProject & {
    isBenchmark: boolean;
    id: string;
    run?: {
      id: string;
      displayName: string;
      state: string;
    };
    sweep?: {
      id: string;
      name: string;
      displayName?: string;
      config?: string;
    };
  };
}
