import '../css/PanelParallelCoord.less';
import React, {useCallback, useState} from 'react';
import {derived} from './property-editors/property-editors';
import * as Run from '../util/runs';
import PanelTitle from './elements/PanelTitle';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import {toRunsDataQuery} from '../containers/RunsDataLoader';
import WandbLoader from './WandbLoader';
import PanelError from './elements/PanelError';
import makeComp from '../util/profiler';
import {useSampleAndQueryToTable} from './Export';
import {GradientStop} from './GradientPicker';
import {PCSettings} from './ParallelCoordinates/PCSettings';
import {PCPlotSVG} from './ParallelCoordinates/PCPlotSVG';
import Measure from 'react-measure';
import {PlotFontSizeOrAuto} from '../util/plotHelpers';

// A single column (aka axis, aka dimension) in the parallel coordinates plot
export interface PCColumn {
  accessor?: string; // should be something like export loss.value
  value?: string;
  log?: boolean;
  inverted?: boolean;
  displayName?: string;
  params?: {[key: string]: string};
}

// The json config for parallel coordinates plots
export interface PCConfig {
  columns?: PCColumn[];
  dimensions?: string[];
  customGradient?: GradientStop[];
  gradientColor?: boolean;
  legendFields?: string[];
  onlyShowSelectedLines?: boolean;
  chartTitle?: string;
  fontSize?: PlotFontSizeOrAuto;
}

export type ParallelCoordPanelProps = Panels.PanelProps<PCConfig>;

// This component is the main entry point for the Parallel Coordinates panel
const ParallelCoordPanel: React.FC<ParallelCoordPanelProps> = makeComp(
  props => {
    const {config, updateConfig, pageQuery} = props;

    if (props.configMode) {
      return (
        <div className="chart-modal">
          <div className="chart-preview" style={{position: 'relative'}}>
            <PCPlotContainer {...props} />
          </div>
          <PCSettings
            config={config}
            updateConfig={updateConfig}
            pageQuery={pageQuery}
          />
        </div>
      );
    } else {
      return <PCPlotContainer {...props} />;
    }
  },
  {id: 'ParallelCoordPanel'}
);

// PCPlotContainer includes the plot metadata (title, error message, run count)
// The actual plot is rendered in the PCPlotSVG subcomponent, defined in PCPlotSVG.tsx
const PCPlotContainer: React.FC<ParallelCoordPanelProps> = makeComp(
  props => {
    const {
      config,
      data,
      runSetRefs,
      customRunColors,
      searchQuery,
      updateConfig,
    } = props;
    const columns = config.columns ?? [];

    const {loading, totalRuns} = data;
    const maxRuns = data.limit;
    const runs = data.filtered;

    const [plotWidth, setPlotWidth] = useState<number>(0);
    const [plotHeight, setPlotHeight] = useState<number>(0);

    let errorMessage: JSX.Element;
    if (runs.length === 0) {
      errorMessage = (
        <>
          Select runs to visualize data
          <br />
          in this parallel coordinates chart.
        </>
      );
    } else if (!columns || columns.length === 0) {
      errorMessage = (
        <>
          Select columns to visualize data
          <br />
          in this parallel coordinates chart.
        </>
      );
    }

    const onResize = useCallback(({bounds}) => {
      setPlotHeight(bounds?.height || 0);
      setPlotWidth(bounds?.width || 0);
    }, []);

    return (
      <>
        <PanelTitle
          title={getTitleFromConfig(config)}
          searchQuery={searchQuery}
          fontSize={Panels.getFontSize(config.fontSize ?? 'auto', plotHeight)}
        />
        <Measure bounds onResize={onResize}>
          {({measureRef}) => {
            return (
              <div className="chart-content" ref={measureRef}>
                {loading && <WandbLoader />}
                {!loading && errorMessage ? (
                  <PanelError message={errorMessage} />
                ) : (
                  <>
                    <div
                      key="numRuns"
                      style={{float: 'right', marginRight: 15}}>
                      {maxRuns && totalRuns > maxRuns && (
                        <span style={{fontSize: 13, fontStyle: 'italic'}}>
                          Showing first {maxRuns} runs{' '}
                        </span>
                      )}
                    </div>
                    <PCPlotSVG
                      runs={runs}
                      runSetRefs={runSetRefs}
                      customRunColors={customRunColors}
                      plotWidth={plotWidth}
                      plotHeight={plotHeight}
                      config={config}
                      updateConfig={updateConfig}
                    />
                  </>
                )}
              </div>
            );
          }}
        </Measure>
      </>
    );
  },
  {id: 'PCPlotContainer'}
);

// Should combine with PanelScatterPlot

function transformQuery(query: Query.Query, config: PCConfig) {
  const result = toRunsDataQuery(query, undefined, {
    // fullConfig: true,
    // fullSummary: true,
  });
  result.page = {
    size: 500,
  };
  const columnAccessors = config.columns
    ? config.columns.map(c => c.accessor)
    : config.dimensions || [];

  const fields = columnAccessors.map(accessor => {
    if (accessor != null) {
      return Run.keyFromString(accessor);
    } else {
      return null;
    }
  });

  if (config.legendFields != null) {
    config.legendFields.forEach(field => fields.push(Run.keyFromString(field)));
  }
  result.configKeys = fields
    .filter(key => key && key.section === 'config')
    .map(key => key!.name);
  result.summaryKeys = fields
    .filter(key => key && key.section === 'summary')
    .map(key => key!.name);

  return result;
}

function useTableData(pageQuery: Query.Query, config: PCConfig) {
  const query = toRunsDataQuery(pageQuery, undefined, {});
  query.page = {
    size: 500,
  };

  const columnAccessors = config.columns
    ? config.columns.map(c => c.accessor)
    : config.dimensions || [];

  const fields = columnAccessors.map(accessor => {
    if (accessor != null) {
      return Run.keyFromString(accessor);
    } else {
      return null;
    }
  });
  if (config.legendFields != null) {
    config.legendFields.forEach(field => fields.push(Run.keyFromString(field)));
  }
  query.configKeys = fields
    .filter(key => key && key.section === 'config')
    .map(key => key!.name);
  query.summaryKeys = fields
    .filter(key => key && key.section === 'summary')
    .map(key => key!.name);

  return useSampleAndQueryToTable(query, pageQuery, config);
}

export const PANEL_TYPE = 'Parallel Coordinates Plot';

const configSpec = {
  gradientColor: {
    editor: 'checkbox' as const,
    displayName: 'Use gradient',
    default: true,
  },
  customGradient: {
    editor: 'gradient' as const,
    displayName: 'Gradient',
    default: [
      {offset: 0, color: '#900000'},
      {offset: 50, color: '#D64F04'},
      {offset: 100, color: '#FFE600'},
    ],
    visible: derived((config: any) => config.gradientColor),
  },
};

function getTitleFromConfig(config: PCConfig): string {
  return config.chartTitle ?? '';
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, PCConfig> = {
  type: PANEL_TYPE,
  Component: ParallelCoordPanel,
  getTitleFromConfig,
  exportable: {
    image: true,
    csv: true,
    api: true,
  },
  transformQuery,
  configSpec,
  useTableData,
  icon: 'panel-parallel',
};

// type Props = PanelConfigSpecToProps<typeof configSpec>;
