import * as _ from 'lodash';
import * as React from 'react';
import {Header, Icon} from 'semantic-ui-react';

import LongformMarkdown from '../../components/LongformMarkdown';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Benchmark from '../../util/benchmark';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

export default function (fields: Benchmark.BenchmarkFields): Theme {
  return {
    benchmarkFields: fields,
    keys: fields.keys,
    benchmarkLink: 'https://app.wandb.ai', // TODO
    overview: () => {
      return <LongformMarkdown content={fields.overview} />;
    },
    leaderboard: approvedRuns => {
      const scoreCols = _.map(fields.keys, k => ({
        Header:
          k === fields.sortMetric ? (
            <span style={{fontWeight: 'bold'}}>
              {k}
              {fields.sortAsc ? (
                <Icon name="caret up" />
              ) : (
                <Icon name="caret down" />
              )}
            </span>
          ) : (
            k
          ),
        id: k,
        accessor: (run: WithSummary<BenchmarkRun>) => {
          return Run.displayValue(run.summary[k]);
        },
      }));
      const leaderboardCols = [...Leaderboard.cols, ...scoreCols];
      console.log('FIELDS', fields);

      return (
        <>
          <Header as="h3">Top submissions</Header>
          <WBReactTable
            columns={leaderboardCols}
            data={Leaderboard.rowsForMetric(
              approvedRuns,
              fields.sortMetric,
              !fields.sortAsc
            )}
          />
        </>
      );
    },
    submissionGuidelines: fields.submissionInstructions,
  };
}
