import * as S from './DeleteMemberModal.styles';

import React, {useCallback, useMemo, useState} from 'react';
import makeComp from '../util/profiler';
import {
  useDeleteInviteMutation,
  UserAccountType,
  useRemoveUserFromOrganizationMutation,
} from '../generated/graphql';
import {Checkbox, Icon} from 'semantic-ui-react';
import {MemberData} from '../graphql/teamQuery';
import {propagateErrorsContext} from '../util/errors';
import {toast} from './elements/Toast';
import {useViewer} from '../state/viewer/hooks';
import {Modal, Button} from 'semantic-ui-react';

enum DeleteMemberStep {
  ConfirmingDeletion,
  RemovedFromBoth,
  RemovedFromTeamContactOrgAdmin,
  RemovedFromTeamGoToEdit,
}

interface DeleteMemberModalProps {
  member?: MemberData;
  org?: any;
  entityName?: string;
  refetch?: () => void;
}

const DeleteMemberModal: React.FC<DeleteMemberModalProps> = makeComp(
  props => {
    const [open, setOpen] = useState<boolean>(false);

    const onCloseHandler = useCallback(() => {
      setOpen(false);
    }, []);

    return (
      <>
        <S.DeleteButton name={'delete'} onClick={() => setOpen(true)} />
        {open && <DeleteMemberModalInner onClose={onCloseHandler} {...props} />}
      </>
    );
  },
  {id: 'DeleteMemberModal'}
);

interface DeleteMemberModalInnerProps extends DeleteMemberModalProps {
  onClose: () => void;
}

const DeleteMemberModalInner: React.FC<DeleteMemberModalInnerProps> = makeComp(
  ({member, org, entityName, refetch, onClose}) => {
    const [deleteFromOrg, setDeleteFromOrg] = useState<boolean>(false);
    const [step, setStep] = useState<DeleteMemberStep>(
      DeleteMemberStep.ConfirmingDeletion
    );
    const viewer = useViewer();

    const [deleteInvite] = useDeleteInviteMutation({
      context: propagateErrorsContext(),
    });
    const [removeUserFromOrg] = useRemoveUserFromOrganizationMutation({
      context: propagateErrorsContext(),
    });

    const isOrgAdmin: boolean = useMemo(
      () =>
        org?.members?.find(
          (m: any) => m.username === viewer?.username && m.role === 'admin'
        ) ?? false,
      [org, viewer]
    );

    // some team doesn't belong to any organization and service account
    // doesn't take up the seat so ignore those cases for removing from org
    // this intentionally doesn't include irOrgAdmin checking because we want
    // to show the checkbox to non org admin so they are aware of removing from org for seat
    const showRemoveFromOrg = useMemo(
      () => org != null && member?.accountType !== UserAccountType.Service,
      [org, member]
    );

    const removeUser = useCallback(async () => {
      if (!member) {
        return;
      }

      try {
        const deleteInviteResult = await deleteInvite({
          variables: {
            id: member.id,
            entityName,
          },
        });

        if (deleteInviteResult.errors != null) {
          toast(
            `Oops, something went wrong with removing ${member.username} from this team`
          );
          onClose();
          return;
        }

        if (!showRemoveFromOrg) {
          onClose();
          return;
        }

        // if deleting from org is possible, there will be 3 situations
        // 1. [RemovedFromBoth] successfully removed from team and org
        // 2. [RemovedFromTeamContactOrgAdmin] removed from team but not org admin so cannot remove from org
        // 3. [RemovedFromTeamGoToEdit] remove from team but didn't try to or failed to remove from org
        if (!deleteFromOrg) {
          // situation 3
          setStep(DeleteMemberStep.RemovedFromTeamGoToEdit);
          return;
        }

        if (!isOrgAdmin) {
          // situation 2
          setStep(DeleteMemberStep.RemovedFromTeamContactOrgAdmin);
          return;
        }

        try {
          const removeUserFromOrgResult = await removeUserFromOrg({
            variables: {
              userName: member.username ?? '',
              organizationId: org.id,
            },
          });
          if (removeUserFromOrgResult.errors != null) {
            // situation 3
            toast(
              `Oops, something went wrong with removing ${member.username} from organization ${org.name}`
            );
            setStep(DeleteMemberStep.RemovedFromTeamGoToEdit);
            return;
          }
        } catch (err) {
          // situation 3
          toast(
            `Oops, something went wrong with removing ${member.username} from organization ${org.name}`
          );
          setStep(DeleteMemberStep.RemovedFromTeamGoToEdit);
          return;
        }
        // situation 1
        setStep(DeleteMemberStep.RemovedFromBoth);
      } catch (err) {
        toast(
          `Oops, something went wrong with removing ${member.username} from team`
        );
        onClose();
      }
    }, [
      member,
      entityName,
      org,
      deleteInvite,
      removeUserFromOrg,
      onClose,
      deleteFromOrg,
      isOrgAdmin,
      showRemoveFromOrg,
    ]);

    const modalHeader = useMemo(() => {
      switch (step) {
        case DeleteMemberStep.ConfirmingDeletion:
          return (
            <>
              <Icon name={'exclamation triangle'} /> Danger
            </>
          );
        case DeleteMemberStep.RemovedFromBoth:
          return `${member?.username} Removed from Team and Organization`;
        case DeleteMemberStep.RemovedFromTeamContactOrgAdmin:
        case DeleteMemberStep.RemovedFromTeamGoToEdit:
          return `${member?.username} Still in Organization`;
        default:
          return;
      }
    }, [step, member]);

    const modalContent = useMemo(() => {
      switch (step) {
        case DeleteMemberStep.ConfirmingDeletion:
          return (
            <>
              <p>
                Are you sure you want to remove{' '}
                <S.Bold>{member?.username ?? member?.email}</S.Bold> from{' '}
                <S.Bold>{entityName ?? 'this team'}</S.Bold>?
              </p>
              {showRemoveFromOrg && (
                <S.RemoveFromOrgSection>
                  <Checkbox
                    checked={deleteFromOrg}
                    data-test="delete-from-org-checkbox"
                    onClick={() => {
                      setDeleteFromOrg(prev => !prev);
                    }}></Checkbox>
                  <S.RemoveFromOrgContent>
                    Remove <S.Bold>{member?.username}</S.Bold> from your
                    organization <S.Bold>{org?.name}</S.Bold>
                  </S.RemoveFromOrgContent>
                </S.RemoveFromOrgSection>
              )}
            </>
          );
        case DeleteMemberStep.RemovedFromBoth:
          return (
            <>
              You have successfully removed <S.Bold>{member?.username}</S.Bold>{' '}
              from your team <S.Bold>{entityName}</S.Bold> and organization{' '}
              <S.Bold>{org.name}</S.Bold>. Go to the Subscription Page to edit
              org members.
            </>
          );
        case DeleteMemberStep.RemovedFromTeamContactOrgAdmin:
          return (
            <>
              You have successfully removed <S.Bold>{member?.username}</S.Bold>{' '}
              from your team <S.Bold>{entityName}</S.Bold>, but they are still a
              member of your paid organization. Please contact your org admin to
              have them removed.
            </>
          );
        case DeleteMemberStep.RemovedFromTeamGoToEdit:
          return (
            <>
              You have successfully removed <S.Bold>{member?.username}</S.Bold>{' '}
              from your team <S.Bold>{entityName}</S.Bold>, but they are still a
              member of your paid organization. Go to the Subscription Page to
              edit org members.
            </>
          );
        default:
          return;
      }
    }, [step, member, org, entityName, deleteFromOrg, showRemoveFromOrg]);

    return (
      <Modal open onClose={onClose} size="small">
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Content>{modalContent}</Modal.Content>
        {step === DeleteMemberStep.ConfirmingDeletion ? (
          <Modal.Actions>
            <S.ButtonWrapper>
              <Button onClick={onClose} content="cancel" />
              <Button onClick={removeUser} content="Yes" color="red" />
            </S.ButtonWrapper>
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              onClick={() => {
                // removed member from the team so the list gets refetched
                onClose();
                refetch?.();
              }}
              content="Close"
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  },
  {id: 'DeleteMemeberModalInner'}
);

export default DeleteMemberModal;
