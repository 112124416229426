import gql from 'graphql-tag';

export const AVAILABLE_FILES_QUERY = gql`
  query AvailableFilesQuery(
    $projectName: String!
    $entityName: String!
    $runName: String!
    $filenames: [String!]!
  ) {
    project(entityName: $entityName, name: $projectName) {
      id
      run(name: $runName) {
        id
        files(names: $filenames) {
          edges {
            node {
              id
              name
              directUrl
              md5
              sizeBytes
            }
          }
        }
      }
    }
  }
`;
