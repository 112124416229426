import React from 'react';
import makeComp from '../util/profiler';
import {ImageExportModal} from './ImageExportModal';

import {getPanelSpec, PanelCompRedux} from '../util/panels';

import * as RunSetTypes from '../state/views/runSet/types';
import * as CustomRunColorsTypes from '../state/views/customRunColors/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as PanelViewTypes from '../state/views/panel/types';

interface PanelImageExportModalProps {
  open?: boolean;
  type: 'svg' | 'png';
  onClose: () => void;
  panelSpec: ReturnType<typeof getPanelSpec>;
  runSetRefs: RunSetTypes.Ref[];
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  customRunColorsRef: CustomRunColorsTypes.Ref;
  readOnly?: boolean;
  searchQuery?: string;
  panelRef: PanelViewTypes.Ref;
}

export interface PanelExportRef {
  onDownloadSVG(panelDOMRef: HTMLDivElement | undefined, name: string): void;
  onDownloadPNG(panelDOMRef: HTMLDivElement | undefined, name: string): void;
  onDownloadPDF(panelDOMRef: HTMLDivElement | undefined, name: string): void;
}
export const PanelImageExportModal = makeComp(
  (props: PanelImageExportModalProps) => {
    const panelDOMRef = React.useRef<HTMLDivElement>(null);
    const panelExportRef = React.useRef<PanelExportRef>();

    return (
      <ImageExportModal
        open={props.open}
        type={props.type}
        panelDOMRef={panelDOMRef}
        panelExportRef={panelExportRef}
        viewType={props.panelSpec.type}
        onClose={props.onClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
          className={
            props.panelSpec.type.toLowerCase().replace(' ', '-') +
            '-container' +
            ' fullscreen-mode'
          }
          ref={panelDOMRef}>
          <PanelCompRedux
            svg={true}
            configMode={false}
            currentHeight={320}
            runSetRefs={props.runSetRefs}
            panelRef={props.panelRef}
            panelSettings={props.panelSettings}
            customRunColorsRef={props.customRunColorsRef}
            dimensions={undefined as any}
            readOnly={props.readOnly}
            searchQuery={props.searchQuery}
            panelExportRef={panelExportRef}
          />
        </div>
        );
      </ImageExportModal>
    );
  },
  {id: 'PanelImageExportModal'}
);
