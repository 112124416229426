import {createAction} from 'typesafe-actions';

import * as Types from './types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import * as PanelTypes from '../panel/types';

import {PanelBankSettings, PanelBankDiff} from '../../../util/panelbank';

export const noOp = createAction(
  '@view/panelBankConfig/noOp',
  action => (ref: Types.Ref) => action({ref})
);

export const initializeNewPanels = createAction(
  '@view/panelBankConfig/initializeNewPanels',
  action => (ref: Types.Ref, panelBankDiff: PanelBankDiff) =>
    action({ref, panelBankDiff})
);

// Update the 'autoOrganizePrefix' and 'showEmptySections' settings
export const updateSettings = createAction(
  '@view/panelBankConfig/updateSettings',
  action => (ref: Types.Ref, panelBankSettings: Partial<PanelBankSettings>) =>
    action({ref, panelBankSettings})
);

// Add a new section
// By default, inserts new section before the given sectionRef.
// Pass addAfter=true if you want to insert after the given section.
export const addSection = createAction(
  '@view/panelBankConfig/addSection',
  action =>
    (
      ref: Types.Ref,
      sectionRef: PanelBankSectionConfigTypes.Ref,
      options?: {
        addAfter?: boolean;
        newSectionName?: string;
      }
    ) =>
      action({ref, sectionRef, options})
);

export const deleteSection = createAction(
  '@view/panelBankConfig/deleteSection',
  action => (ref: Types.Ref, sectionRef: PanelBankSectionConfigTypes.Ref) =>
    action({ref, sectionRef})
);

export const moveSectionBefore = createAction(
  '@view/panelBankConfig/moveSectionBefore',
  action =>
    (
      ref: Types.Ref,
      moveSectionRef: PanelBankSectionConfigTypes.Ref,
      beforeSectionRef?: PanelBankSectionConfigTypes.Ref // if null, we're moving to the last position in the array
    ) =>
      action({ref, moveSectionRef, beforeSectionRef})
);

export const movePanel = createAction(
  '@view/panelBankConfig/movePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're deleting
      fromSectionRef: PanelBankSectionConfigTypes.Ref, // the panelbank section we're moving the panel from
      toSectionRef: PanelBankSectionConfigTypes.Ref, // the panelbank section we're moving the panel to
      toIndex?: number, // the index we're moving the panel to in toSectionRefs
      inactivePanelRefIDs?: Set<string>
    ) =>
      action({
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
        inactivePanelRefIDs,
      })
);

export const movePanelToNewSection = createAction(
  '@view/panelBankConfig/movePanelToNewSection',
  action =>
    (
      ref: Types.Ref,
      args: {
        panelRef: PanelTypes.Ref; // the panel we're deleting
        fromSectionRef: PanelBankSectionConfigTypes.Ref; // the panelbank section we're moving the panel from
        newSectionName: string;
      }
    ) =>
      action({ref, args}) // panelRef, fromSectionRef, toSectionRef, toIndex})
);

export const updateSettingsAndSortPanels = createAction(
  '@view/panelBankConfig/updateSettingsAndSortPanels',
  action =>
    (
      ref: Types.Ref,
      args: {
        panelBankSettings: Partial<PanelBankSettings>;
        sortAllSections: boolean;
      }
    ) =>
      action({ref, args})
);
