import styled, {css} from 'styled-components';
import {GLOBAL_COLORS} from '../../../util/colors';

export const HorizontalRule = styled.hr<{selected?: boolean}>`
  margin: 16px 0;
  border-top: none;
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  ${props =>
    props.selected &&
    css`
      border-bottom: 1px solid rgb(208, 225, 252);
      box-shadow: 0 0 0 2px rgb(208, 225, 252);
    `}
`;
