import styled, {css} from 'styled-components';
import {REPORT_FONT_SIZE} from '../constants';
import TextareaAutosize from 'react-textarea-autosize';
import {GLOBAL_COLORS} from '../../../util/colors';

export const InlineLatexError = styled.span<{selected?: boolean}>`
  border-radius: 4px;
  color: red;
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 2px rgb(208, 225, 252);
    `}
`;

export const InlineLatexPlaceholder = styled.span<{selected?: boolean}>`
  border-radius: 4px;
  color: #999;
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 2px rgb(208, 225, 252);
    `}
`;

export const InlineLatex = styled.span<{selected?: boolean}>`
  border-radius: 4px;
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 2px rgb(208, 225, 252);
    `}
`;

export const Latex = styled.div<{selected?: boolean}>`
  ${props =>
    props.selected &&
    css`
      border-bottom: 1px solid rgb(208, 225, 252);
      box-shadow: 0 0 0 2px rgb(208, 225, 252);
    `}
`;

export const BlockLatexWrapper = styled.div`
  text-align: center;
  margin-bottom: ${Math.floor(REPORT_FONT_SIZE * 0.8)}px;
`;

export const EditLatexWrapper = styled.div`
  border-radius: 4px;
`;

export const LatexInput = styled(TextareaAutosize)`
  font-family: Inconsolata;
  display: block;
  width: 240px;
  padding: 8px;
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  border-radius: 4px;
  background: rgb(250, 251, 253);
  outline: none;
`;
