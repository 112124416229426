import styled from 'styled-components';

export const Page = styled.div`
  padding: 16px;
`;

export const ControlArea = styled.div`
  margin-bottom: 24px;
`;

export const LabeledControl = styled.div`
  display: flex;
  align-items: center;
`;
export const ControlLabel = styled.div`
  margin-right: 16px;
`;

export const Op = styled.div`
  display: flex;
`;

export const OpKind = styled.div`
  text-transform: uppercase;
  margin-right: 8px;
`;

export const OpName = styled.div`
  margin-right: 8px;
`;
export const OpBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OpArg = styled.div`
  white-space: nowrap;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

export const OpArgName = styled.span`
  color: #008a4b;
`;

export const Sep = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

export const Type = styled.span`
  color: #858100;
`;
