import * as S from './GenerateLicenseForm.styles';

import React, {useState} from 'react';
import {Button, Form, List, Message} from 'semantic-ui-react';

import {useGenerateLocalLicenseMutation} from '../generated/graphql';
import {
  propagateErrorsContext,
  extractErrorMessageFromApolloError,
} from '../util/errors';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';
import {toast} from './elements/Toast';
import copyToClipboard from 'copy-to-clipboard';
import moment from 'moment';

interface GenerateLicenseFormProps {
  onSubmit(): void;
  onClose?(): void;
}

const GenerateLicenseForm = makeComp(
  (props: GenerateLicenseFormProps) => {
    const {onSubmit, onClose} = props;

    const [customerName, setCustomerName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [teams, setTeams] = useState<number>(0);
    const [seats, setSeats] = useState<number>(4);
    const [storageGigs, setStorageGigs] = useState<number>(2);
    const [expDuration, setExpDuration] = useState<number>(365);
    // TODO: Add all the flags here whenever a new one comes in
    const [flags, setFlags] = useState([
      'auth0',
      'mysql',
      's3',
      'notifications',
      'slack',
      'redis',
      'org_dash',
    ]);
    const [newFlag, setNewFlag] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const [generateLicense] = useGenerateLocalLicenseMutation({
      context: propagateErrorsContext(),
    });

    const onSubmitHandler = async () => {
      try {
        const validExpDuration = moment('2038-01-19')
          .utc()
          .diff(moment().utc(), 'days');
        if (expDuration > validExpDuration) {
          setError(`Pick a expiration duration under ${validExpDuration} days`);
          return;
        }

        const variables = {
          customerName,
          contactEmail: email,
          notes,
          teams,
          seats,
          expDuration,
          storageGigs,
          flags,
        };
        window.analytics.track('Generate license', variables);
        const generateLicenseResult = await generateLicense({variables});
        if (generateLicenseResult.errors != null) {
          setError(
            `Error generating license: ${generateLicenseResult.errors.join(
              ', '
            )}`
          );
          return;
        }

        const generatedLicense =
          generateLicenseResult.data?.generateLocalLicense?.localLicense
            ?.license;
        if (generatedLicense == null) {
          throw new Error('generated license cannot be null');
        }
        copyToClipboard(generatedLicense);
        toast('Succesfully generated local license and copied to clipboard.');
        onSubmit();
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
        setError(`Error generating license: ${errMsg}`);
      }
    };

    const addNewFlagHandler = () => {
      if (flags.includes(newFlag)) {
        setError(`New flag ${newFlag} already exists`);
      } else {
        setFlags([newFlag, ...flags]);
        setNewFlag('');
      }
    };

    const setValueWithDefault = (
      setValueFunc: React.Dispatch<React.SetStateAction<number>>,
      value: string | number,
      defaultValue: number
    ): void => {
      if (typeof value === 'string') {
        value = Number(value);
      }
      isNaN(value) ? setValueFunc(defaultValue) : setValueFunc(value);
    };

    return (
      <>
        {error && <Message negative content={error} />}
        <S.InputHeader>Company Name</S.InputHeader>
        <Form.Input
          value={customerName}
          style={{width: '100%'}}
          onChange={(e, {value}) => {
            setCustomerName(value);
          }}
        />
        <S.InputHeader>Email</S.InputHeader>
        <Form.Input
          value={email}
          style={{width: '100%'}}
          onChange={(e, {value}) => {
            setEmail(value);
          }}
        />
        <S.InputHeader>Notes</S.InputHeader>
        <Form.Input
          value={notes}
          style={{width: '100%'}}
          onChange={(e, {value}) => {
            setNotes(value);
          }}
        />
        <S.ClaimsWrapper>
          <S.FormColumn>
            <S.InputRow>
              <S.InputLabel>Teams</S.InputLabel>
              <Form.Input
                value={teams}
                width={7}
                onChange={(e, {value}) => {
                  setValueWithDefault(setTeams, value, 0);
                }}
              />
            </S.InputRow>
            <S.InputRow>
              <S.InputLabel>Seats</S.InputLabel>
              <Form.Input
                value={seats}
                width={7}
                onChange={(e, {value}) => {
                  setValueWithDefault(setSeats, value, 0);
                }}
              />
            </S.InputRow>
            <S.InputRow>
              <S.InputLabel>Storage GB</S.InputLabel>
              <Form.Input
                value={storageGigs}
                width={7}
                onChange={(e, {value}) => {
                  setValueWithDefault(setStorageGigs, value, 0);
                }}
              />
            </S.InputRow>
            <S.InputRow>
              <S.InputLabel>Days until expiration</S.InputLabel>
              <Form.Input
                value={expDuration}
                width={7}
                onChange={(e, {value}) => {
                  setValueWithDefault(setExpDuration, value, 365);
                }}
              />
            </S.InputRow>
          </S.FormColumn>
          <S.FormColumn>
            <S.InputHeader>Flags</S.InputHeader>
            <S.NewFlagWrapper>
              <S.FlagInputWrapper>
                <Form.Input
                  style={{height: '39px'}}
                  value={newFlag}
                  placeholder="New Flag"
                  width="16"
                  onChange={(e, {value}) => {
                    setNewFlag(value);
                  }}
                />
              </S.FlagInputWrapper>
              <Button disabled={newFlag === ''} onClick={addNewFlagHandler}>
                Add
              </Button>
            </S.NewFlagWrapper>
            <S.FlagListWrapper>
              <List divided verticalAlign="middle">
                {flags.map(flag => {
                  return (
                    <List.Item style={{padding: '8px'}}>
                      <List.Content floated="right" style={{padding: 0}}>
                        <S.DeleteButton>
                          <LegacyWBIcon
                            name="delete"
                            size="large"
                            onClick={() => {
                              const filteredFlags = flags.filter(
                                f => flag !== f
                              );
                              setFlags(filteredFlags);
                            }}
                          />
                        </S.DeleteButton>
                      </List.Content>
                      <S.ContentWrapper>
                        <List.Content style={{padding: 0}}>
                          {flag}{' '}
                        </List.Content>
                      </S.ContentWrapper>
                    </List.Item>
                  );
                })}
              </List>
            </S.FlagListWrapper>
          </S.FormColumn>
        </S.ClaimsWrapper>
        <S.ButtonWrapper>
          {onClose != null && <Button onClick={onClose}>Cancel</Button>}
          <Button
            primary
            onClick={onSubmitHandler}
            disabled={email === '' || customerName === ''}>
            Generate
          </Button>
        </S.ButtonWrapper>
      </>
    );
  },
  {id: 'GenerateLicenseForm'}
);

export default GenerateLicenseForm;
