import React, {useState} from 'react';
import {Button, Checkbox, Modal} from 'semantic-ui-react';
import makeComp from '../util/profiler';
import {useRunArtifactVersionsQuery} from '../generated/graphql';
import WandbLoader from './WandbLoader';

interface DeleteRunModalProps {
  entityName: string;
  projectName: string;
  runName: string;
  runDisplayName: string;
  trigger?: React.ReactNode;
  onModalCanceled?(): void;
  onDeleteRun(deleteArtifacts?: boolean): void;
}

const DeleteRunModal: React.FC<DeleteRunModalProps> = makeComp(
  ({
    entityName,
    projectName,
    runName,
    runDisplayName,
    trigger,
    onModalCanceled,
    onDeleteRun,
  }) => {
    const runArtifacts = useRunArtifactVersionsQuery({
      variables: {
        entityName,
        projectName,
        runName,
        includeOutput: true,
        includeInput: false,
        pageSize: 1, // we only care about the total count, so keep the size low
      },
      fetchPolicy: 'no-cache',
    });

    const outputArtifactsCount =
      runArtifacts.data?.project?.run?.outputArtifacts?.totalCount ?? 0;

    const showCheckbox = (outputArtifactsCount ?? 0) > 0;
    const [deleteArtifacts, setDeleteArtifacts] = useState(false);

    return (
      <Modal trigger={trigger} open onClose={onModalCanceled}>
        {runArtifacts.loading ? (
          <Modal.Content style={{height: 200}}>
            <WandbLoader />
          </Modal.Content>
        ) : (
          <>
            <Modal.Content>
              <p>
                Are you sure you want to DELETE run "{runDisplayName}"? Deleted
                runs will be removed everywhere, including reports. This action
                cannot be undone!
              </p>
              {showCheckbox && (
                <Checkbox
                  label={`Delete ALL ${outputArtifactsCount} output artifact(s)`}
                  checked={deleteArtifacts}
                  onChange={() => setDeleteArtifacts(!deleteArtifacts)}
                />
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onModalCanceled}>Cancel</Button>
              <Button
                color="red"
                disabled={showCheckbox && !deleteArtifacts}
                onClick={() => onDeleteRun(deleteArtifacts)}>
                Delete this run
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>
    );
  },
  {id: 'DeleteRunModal', memo: true}
);

export default DeleteRunModal;
