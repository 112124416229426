import * as S from './drag-drop.styles';

import React, {useMemo} from 'react';
import {useTyping} from '../TypingContext';
import {Transforms, Path, Editor} from 'slate';
import {
  RenderElementProps,
  useReadOnly,
  useEditor,
  ReactEditor,
} from 'slate-react';

import makeComp from '../../../util/profiler';
import {WBSlateElement} from '../WBSlate';
import {EditorWithImages} from './images';

import {Node} from 'slate';
import {Popup} from 'semantic-ui-react';
import {WBPopupMenuTrigger} from '@wandb/ui';
import {WBMenuOption} from '@wandb/ui';
import {getParentReportURL} from '../../../util/url';
import copyToClipboard from 'copy-to-clipboard';
import {toast} from '../../elements/Toast';
import {cloneDeep, isEqual} from 'lodash';
import {white} from '../../../css/globals.styles';
import {isPanelGrid} from './panel-grids';
import {getDeepLinkId} from '../util';

export type DragType = 'block' | 'text';

export const getDragType = (items: DataTransferItemList) => {
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < items.length; i++) {
    const {kind, type} = items[i];
    if (type === 'application/x-slate-fragment' || kind === 'file') {
      return 'block';
    }
  }

  return 'text';
};

interface WBSlateDragDropContextValue {
  parentViewId?: string;
  dragItem?: Node;
  dragHandleMenuOpen: boolean; // True if any element's drag handle menu is open, disables popups on the other drag handles
  setDragItem(node: Node | undefined): void;
  setDragHandleMenuOpen(open: boolean): void;
}

export const WBSlateDragDropContext =
  React.createContext<WBSlateDragDropContextValue>({} as any);

export const useDrag = (item: Node, options?: {onHandleMouseDown?(): void}) => {
  const {setDragItem} = React.useContext(WBSlateDragDropContext);
  const [dragPhase, setDragPhase] = React.useState<
    'none' | 'renderingGhost' | 'dragging'
  >('none');
  const [grabbedHandle, setGrabbedHandle] = React.useState(false);

  const editor = useEditor();

  const sourceAttributes = React.useMemo(
    () => ({
      draggable: grabbedHandle,
      onDragStart() {
        if (grabbedHandle) {
          Transforms.select(editor, ReactEditor.findPath(editor, item));
          setDragItem(item);
          setDragPhase('renderingGhost');
          setGrabbedHandle(false);
        }
      },
      onDragEnd() {
        Transforms.deselect(editor);
        setDragItem(undefined);
        setDragPhase('none');
      },
      onDrag() {
        if (dragPhase === 'renderingGhost') {
          setDragPhase('dragging');
        }
      },
    }),
    [grabbedHandle, editor, item, setDragItem, dragPhase]
  );

  const handleAttributes = React.useMemo(
    () => ({
      onMouseDown() {
        options?.onHandleMouseDown?.();
        setGrabbedHandle(true);
      },
      onMouseUp() {
        setGrabbedHandle(false);
      },
    }),
    [options]
  );

  return {sourceAttributes, handleAttributes, dragPhase};
};

export const useDrop = (options?: {
  onDragEnter?(e: React.DragEvent): void;
  onDragLeave?(e: React.DragEvent): void;
  onDrop?(e: React.DragEvent): void;
}) => {
  const {setDragItem} = React.useContext(WBSlateDragDropContext);
  const [dragType, setDragType] = React.useState<DragType | undefined>(
    undefined
  );
  const counter = React.useRef<number>(0);

  const onDragEnter = React.useCallback(
    (e: React.DragEvent) => {
      if (counter.current === 0) {
        options?.onDragEnter?.(e);

        setDragType(getDragType(e.dataTransfer.items));
      }

      counter.current++;
    },
    [options]
  );

  const onDragLeave = React.useCallback(
    (e: React.DragEvent) => {
      counter.current--;

      if (counter.current === 0) {
        options?.onDragLeave?.(e);

        setDragType(undefined);
      }
    },
    [options]
  );

  const onDrop = React.useCallback(
    (e: React.DragEvent) => {
      options?.onDrop?.(e);

      counter.current = 0;
      setDragType(undefined);
      setDragItem(undefined);
    },
    [options, setDragItem]
  );

  const targetAttributes = React.useMemo(
    () => ({
      onDragEnter,
      onDragLeave,
      onDrop,
    }),
    [onDragEnter, onDragLeave, onDrop]
  );

  return {targetAttributes, dragType};
};

export const BlockWrapper: React.FC<
  RenderElementProps & {
    disableClickSelect?: boolean;
    noDragging?: boolean;
  }
> = ({attributes, element, children, disableClickSelect, noDragging}) => {
  const editor = useEditor();
  const readOnly = useReadOnly();
  const {dragItem} = React.useContext(WBSlateDragDropContext);

  const {sourceAttributes, handleAttributes, dragPhase} = useDrag(element, {
    onHandleMouseDown() {
      if (isPanelGrid(element)) {
        Transforms.select(editor, ReactEditor.findPath(editor, element));
      } else {
        Transforms.deselect(editor);
      }
    },
  });

  const {targetAttributes, dragType} = useDrop({
    onDragEnter(e) {
      e.preventDefault();
    },
    onDrop(e) {
      const data = e.dataTransfer;
      const fragment = data.getData('application/x-slate-fragment');

      if (fragment) {
        const decoded = decodeURIComponent(window.atob(fragment));
        const parsed = JSON.parse(decoded) as WBSlateElement[];

        const destinationPath = ReactEditor.findPath(editor, element);

        let sourcePath: Path | null = null;

        if (dragItem != null) {
          sourcePath = ReactEditor.findPath(editor, dragItem);
        }

        if (sourcePath == null) {
          // Assume external source; simply insert.
          destinationPath[destinationPath.length - 1]++;
          Transforms.insertNodes(editor, parsed, {
            at: destinationPath,
          });
          Transforms.select(editor, destinationPath);
        } else {
          if (
            destinationPath.length !== sourcePath.length ||
            Path.isBefore(destinationPath, sourcePath)
          ) {
            destinationPath[destinationPath.length - 1]++;
          }

          Transforms.moveNodes(editor, {
            at: sourcePath,
            to: destinationPath,
          });
        }

        e.stopPropagation();
        e.preventDefault();
        return;
      }

      // For dropping images directly into report; doesn't really belong in this file
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.files.length; i++) {
        const file = data.files[i];
        if (file.type.includes('image')) {
          const destinationPath = ReactEditor.findPath(editor, element);
          destinationPath[destinationPath.length - 1]++;
          EditorWithImages.insertImageFromData(editor, file, {
            at: destinationPath,
          });
          e.stopPropagation();
          e.preventDefault();
          return;
        }
      }
    },
  });

  const deepLinkId = React.useMemo(() => getDeepLinkId(element), [element]);

  if (readOnly) {
    return children;
  }

  return (
    <S.FullBlockWrapper
      {...attributes}
      {...targetAttributes}
      {...sourceAttributes}
      onDragOver={e => {
        if (dragType === 'block') {
          e.preventDefault();
        }
      }}
      onMouseDown={e => {
        if (e.target === attributes.ref.current) {
          e.preventDefault();
          ReactEditor.focus(editor);
          Transforms.deselect(editor);
        }

        if (disableClickSelect) {
          if (
            (e.target as HTMLElement).parentElement === attributes.ref.current
          ) {
            e.preventDefault();
            ReactEditor.focus(editor);
            Transforms.deselect(editor);
          }
        }
      }}
      onClick={e => {
        if (disableClickSelect) {
          e.stopPropagation();
        }
      }}>
      <S.BlockWrapper
        id={deepLinkId}
        dragging={dragPhase === 'dragging'}
        isDropTarget={dragType === 'block'}
        disableClickSelect={disableClickSelect}>
        {!readOnly && !noDragging && (
          <BlockDragHandle
            handleAttributes={handleAttributes}
            element={element}
            deepLinkId={deepLinkId}
          />
        )}
        <div>{children}</div>
      </S.BlockWrapper>
    </S.FullBlockWrapper>
  );
};

interface BlockDragHandleProps {
  handleAttributes: any;
  element: Node;
  deepLinkId?: string;
}

const BlockDragHandle: React.FC<BlockDragHandleProps> = makeComp(
  ({handleAttributes, element, deepLinkId}) => {
    const {typing} = useTyping();
    const editor = useEditor();
    const {parentViewId, dragItem, dragHandleMenuOpen, setDragHandleMenuOpen} =
      React.useContext(WBSlateDragDropContext);

    const dragging = dragItem != null;

    const menuItems: WBMenuOption[] = useMemo(() => {
      const items: WBMenuOption[] = [];
      items.push({
        value: 'duplicate',
        name: 'Duplicate',
        icon: 'copy',
        onSelect: () => {
          const path = ReactEditor.findPath(editor, element);
          const copy = cloneDeep(Editor.node(editor, path)[0]);
          Transforms.insertNodes(editor, copy, {at: path});
          Transforms.deselect(editor);
          setDragHandleMenuOpen(false);
        },
      });
      items.push({
        value: 'delete',
        name: 'Delete',
        icon: 'delete',
        onSelect: () => {
          Transforms.removeNodes(editor, {
            at: ReactEditor.findPath(editor, element),
          });
          Transforms.deselect(editor);
          setDragHandleMenuOpen(false);
        },
      });
      if (deepLinkId != null) {
        items.push({
          value: 'link',
          name: 'Copy link',
          icon: 'link',
          onSelect: () => {
            const deepUrl =
              getParentReportURL(window.location.href, parentViewId) +
              '#' +
              deepLinkId;
            copyToClipboard(deepUrl);
            toast('Copied link');
            Transforms.deselect(editor);
            setDragHandleMenuOpen(false);
          },
        });
      }
      return items;
    }, [deepLinkId, editor, element, parentViewId, setDragHandleMenuOpen]);

    const offsetTop =
      element.type === 'heading' && element.level === 1 ? 4 : undefined;

    if (dragging) {
      return isEqual(dragItem, element) ? (
        // Drag handle without popups (displayed on element while actively dragging)
        <S.DragHandle
          offsetTop={offsetTop}
          contentEditable={false}
          hidden={typing}
          {...handleAttributes}
        />
      ) : (
        // Disable drag handle if you're dragging a different element
        <></>
      );
    }

    return (
      // Drag handle with popup menus
      <WBPopupMenuTrigger
        menuBackgroundColor={white}
        theme="light"
        direction="center left"
        options={menuItems}>
        {({anchorRef, setOpen, open: menuOpen}) => (
          <Popup
            size="tiny"
            position="top center"
            basic
            disabled={dragHandleMenuOpen}
            inverted
            trigger={
              <S.DragHandle
                forceVisible={menuOpen}
                ref={anchorRef}
                offsetTop={offsetTop}
                onClick={() => {
                  setOpen(o => {
                    setDragHandleMenuOpen(!o);
                    return !o;
                  });
                }}
                contentEditable={false}
                hidden={typing}
                {...handleAttributes}
              />
            }
            content={
              <div>
                Drag to move
                <br />
                Click to open menu
              </div>
            }
          />
        )}
      </WBPopupMenuTrigger>
    );
  },
  {id: 'BlockDragHandle', memo: true}
);
