import * as React from 'react';

import {useRunSetsQuery} from '../state/runs/hooks';
import * as ViewHooks from '../state/views/hooks';
import * as PanelTypes from '../state/views/panel/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as RunSetTypes from '../state/views/runSet/types';

import Export from './Export';
import makeComp from '../util/profiler';

interface PanelExportCSVProps {
  runSetRefs: RunSetTypes.Ref[];
  panelRef: PanelTypes.Ref;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

const PanelExportCSV = makeComp(
  ({
    panelRef,
    panelSettings,
    runSetRefs,
    trigger,
    open,
    onClose,
  }: PanelExportCSVProps) => {
    let panel = ViewHooks.usePart(panelRef);
    const pageQuery = useRunSetsQuery(runSetRefs);
    if (panelSettings != null && panel.viewType === 'Run History Line Plot') {
      panel = {
        ...panel,
        config: {
          ...panelSettings,
          ...panel.config,
        },
      };
    }
    if (panelSettings != null && panel.viewType === 'Run History Line Plot') {
      panel = {
        ...panel,
        config: {
          ...panelSettings,
          ...panel.config,
        },
      };
    }

    return (
      <Export
        panel={panel}
        pageQuery={pageQuery}
        trigger={trigger}
        open={open}
        onClose={onClose}
      />
    );
  },
  {id: 'PanelExportCSV'}
);

export default PanelExportCSV;
