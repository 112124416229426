import _ from 'lodash';
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import PanelError from '../elements/PanelError';
import {Loader} from 'semantic-ui-react';
import {Spec as VegaSpec, View as VegaView} from 'vega';
import {RunsData} from '../../containers/RunsDataLoader';
import * as VegaLib from '../../util/vega';
import * as VegaData from '../../util/vegaData';
import makeComp from '../../util/profiler';

interface VegaVizProps {
  spec: any;

  data: RunsData;

  userSettings: VegaLib.UserSettings;

  setView?(view: VegaView | null): void;
}

enum VegaVizRenderState {
  Init,
  Ready,
  Updating,
}

// Higher number takes priority.
enum VegaVizAction {
  None = 0,
  Update = 1,
  Create = 2,
}

function isVegaLite(spec: VegaSpec) {
  return spec.$schema && spec.$schema.includes('vega-lite');
}

const VegaViz: FC<VegaVizProps> = makeComp(
  ({spec, data, userSettings, setView}) => {
    const [renderState, setRenderState] = useState(VegaVizRenderState.Init);
    const nextAction = useRef<VegaVizAction>(VegaVizAction.None);

    const vega = useRef<typeof import('vega') | undefined>();
    const vl = useRef<typeof import('vega-lite') | undefined>();
    const vegaToolTip = useRef<typeof import('vega-tooltip') | undefined>();

    const ref = useRef<any | undefined>();
    const view = useRef<VegaView | null>(null);
    const renderedSpec = useRef<any | undefined>();
    const renderedUserSettings = useRef<
      VegaVizProps['userSettings'] | undefined
    >();
    const renderedData = useRef<RunsData | undefined>();

    // We cache the current vega table names and use them
    // to call view.current.data() to determine if we don't have any
    // data at all.
    const empty = useRef(true);
    const tableNames = useRef<string[]>([]);

    const loadLibraries = useCallback(async () => {
      // TODO: parallel, but need to make sure these get built as
      // separate bundles.
      vega.current = await import('vega');
      vl.current = await import('vega-lite');
      vegaToolTip.current = await import('vega-tooltip');
      setRenderState(VegaVizRenderState.Ready);
    }, []);

    const determineAction = useCallback(() => {
      // console.log(
      //   'DETERMINE action',
      //   _.isEqual(this.props.spec, this.renderedSpec),
      //   _.isEqual(this.props.userSettings, this.renderedUserSettings),
      //   this.props.data === this.renderedData
      // );
      if (
        !_.isEqual(spec, renderedSpec.current) ||
        !_.isEqual(userSettings, renderedUserSettings.current)
      ) {
        return VegaVizAction.Create;
      } else if (data !== renderedData.current) {
        if (view.current == null) {
          // This happens when we tried to render but generated empty tables.
          return VegaVizAction.Create;
        } else {
          return VegaVizAction.Update;
        }
      }
      return VegaVizAction.None;
    }, [data, spec, userSettings]);

    const setRef = useCallback((newRef: any) => {
      ref.current = newRef;
    }, []);

    const setDimensions = useCallback((v: VegaView) => {
      if (v != null && ref.current != null) {
        v.width(ref.current.clientWidth - 20).height(
          ref.current.clientHeight - 50
        );
      }
    }, []);

    const onWindowResize = useMemo(
      () =>
        _.debounce(() => {
          if (view.current != null && ref.current != null) {
            setDimensions(view.current);
            view.current.runAsync();
          }
        }, 100),
      [setDimensions]
    );

    const checkEmpty = useCallback((v: VegaView) => {
      console.log(v.getState());
      let isEmpty = true;
      for (const tableName of tableNames.current) {
        if (tableName != null) {
          const tableData = v.data(tableName);
          if (tableData != null && tableData.length > 0) {
            isEmpty = false;
          }
        }
      }
      empty.current = isEmpty;
    }, []);

    const createVegaView = useCallback((): VegaView | null => {
      if (ref.current == null) {
        return null;
      }

      const refs = VegaLib.parseSpec(spec);
      if (refs == null) {
        return null;
      }

      const tables = VegaData.computeInitialTables(
        data,
        VegaData.SpecToTables(refs, userSettings)
      );

      // It seems like there is an issue where if a table has 0 rows, Vega doesn't
      // track it. Then later when we try to add data to it (in update) we crash because
      // the table doesn't exist. So instead of fail to create the view here, and try
      // again later when there is more data.
      if (tables.map(t => t.values.length === 0).some(o => o)) {
        return null;
      }
      tableNames.current = tables.map(t => t.name);

      let specWithFields: any = VegaLib.injectFields(
        spec,
        refs,
        userSettings,
        data.keyInfo
      );

      specWithFields.autosize = 'fit';

      let runtime: any;

      if (isVegaLite(specWithFields)) {
        specWithFields.datasets = _.fromPairs(
          tables.map(t => [t.name, t.values])
        );

        // Compile to Vega
        try {
          specWithFields = vl.current!.compile(specWithFields).spec;
        } catch (e) {
          console.log('Invalid Vega-lite: ', e);
        }
      } else {
        // Inject data for Vega
        if (specWithFields.data == null) {
          // just make it our data
          specWithFields.data = tables;
        } else {
          if (!_.isArray(specWithFields.data)) {
            specWithFields.data = [specWithFields.data];
          }
          for (const table of tables) {
            const found = _.find(
              specWithFields.data,
              t => t.name === table.name
            );
            if (found != null) {
              found.values = table.values;
            } else {
              specWithFields.data = [table, ...specWithFields.data];
            }
          }
        }
      }
      try {
        runtime = vega.current!.parse(specWithFields);
      } catch (e) {
        console.log('Invalid Vega', e);
        return null;
      }

      (window as any).VEGA = runtime;
      (window as any).VIEW = view.current;

      const vegaView = new vega.current!.View(runtime, {
        container: ref.current,
      }).hover();
      vegaToolTip.current!.default(vegaView);

      // vegaView.logLevel(this.vega!.Debug);

      setDimensions(vegaView);

      checkEmpty(vegaView);

      return vegaView;
    }, [checkEmpty, data, userSettings, setDimensions, spec]);

    const updateViewData = useCallback(() => {
      if (view.current == null) {
        return;
      }
      // TODO: cache refs so we don't keep doing this
      const refs = VegaLib.parseSpec(spec);
      if (refs == null) {
        return;
      }

      const tables = VegaData.SpecToTables(refs, userSettings);
      const delta = VegaData.computeTableDelta(
        renderedData.current!,
        data,
        tables
      );

      for (const changeSet of delta) {
        view.current.change(
          changeSet.tableName,
          vega
            .current!.changeset()
            .remove(changeSet.remove)
            .insert(changeSet.insertRows)
        );
      }
      checkEmpty(view.current);
    }, [checkEmpty, data, spec, userSettings]);

    const doAction = useCallback(
      async (action: VegaVizAction.Create | VegaVizAction.Update) => {
        nextAction.current = VegaVizAction.None;

        if (action === VegaVizAction.Create) {
          if (view.current != null) {
            view.current.finalize();
          }
          view.current = createVegaView();
          if (setView != null) {
            setView(view.current);
          }
        } else {
          updateViewData();
        }

        renderedSpec.current = spec;
        renderedData.current = data;
        renderedUserSettings.current = userSettings;

        if (view.current != null) {
          setRenderState(VegaVizRenderState.Updating);
          await view.current.runAsync();
          setRenderState(VegaVizRenderState.Ready);
        }
      },
      [createVegaView, data, setView, spec, updateViewData, userSettings]
    );

    useEffect(() => {
      loadLibraries();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      window.addEventListener('resize', onWindowResize);
      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    }, [onWindowResize]);

    useEffect(() => {
      const action = determineAction();
      // console.log('DID UPDATE', this.state.renderState, action, this.nextAction);
      switch (renderState) {
        case VegaVizRenderState.Init:
          break;
        case VegaVizRenderState.Ready: {
          const takeAction =
            nextAction.current > action ? nextAction.current : action;
          if (takeAction !== VegaVizAction.None) {
            doAction(takeAction);
          }
          break;
        }
        case VegaVizRenderState.Updating: {
          if (action > nextAction.current) {
            nextAction.current = action;
          }
          break;
        }
        default:
          throw new Error('programming error');
      }
    });

    return (
      <div style={{position: 'relative', width: '100%', height: '100%'}}>
        {renderState !== VegaVizRenderState.Ready ? (
          <Loader active />
        ) : (
          empty.current && (
            <div style={{position: 'absolute', width: '100%', height: '100%'}}>
              <PanelError message="No data available. Please select runs that have valid data for this plot." />
            </div>
          )
        )}
        <div
          style={{overflow: 'auto', width: '100%', height: '100%'}}
          ref={setRef}
        />
      </div>
    );
  },
  {id: 'VegaViz', memo: true}
);

export default VegaViz;
