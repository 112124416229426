import React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {Breadcrumb} from 'semantic-ui-react';
import makeComp from '../util/profiler';

interface BreadcrumbsProps {
  entityName: string;
  experimentName?: string;
  projectName?: string;
  runs?: any;
  runName?: string;
  reportName?: string;
  // sweepName: any;
  extra?: any;
  style?: {};
  disableLinks?: true;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = makeComp(
  ({
    entityName,
    experimentName,
    projectName,
    runName,
    reportName,
    extra,
    style,
    disableLinks,
  }) => {
    const slug = '/' + entityName + '/' + projectName;
    return (
      <Breadcrumb style={style} size="big">
        <Breadcrumb.Section>
          <BreadcrumbNavLink to={'/' + entityName} disableLink={disableLinks}>
            {entityName}
          </BreadcrumbNavLink>
        </Breadcrumb.Section>
        {projectName && (
          <React.Fragment>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink to={slug} disableLink={disableLinks}>
                {projectName}
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
          </React.Fragment>
        )}
        {experimentName && (
          <React.Fragment>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink
                to={`${slug}/experiments/${experimentName}`}
                disableLink={disableLinks}>
                {experimentName}
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
          </React.Fragment>
        )}
        {runName && (
          <React.Fragment>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink to={slug} disableLink={disableLinks}>
                runs
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink
                to={slug + '/runs/' + runName}
                disableLink={disableLinks}>
                {runName}
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
          </React.Fragment>
        )}
        {reportName && (
          <React.Fragment>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink
                to={slug + '/reports'}
                disableLink={disableLinks}>
                reports
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              <BreadcrumbNavLink
                to={slug + '/reports/' + reportName}
                disableLink={disableLinks}>
                {reportName}
              </BreadcrumbNavLink>
            </Breadcrumb.Section>
          </React.Fragment>
        )}

        {extra && (
          <React.Fragment>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>{extra}</Breadcrumb.Section>
          </React.Fragment>
        )}
      </Breadcrumb>
    );
  },
  {id: 'Breadcrumbs', memo: true}
);

export default Breadcrumbs;

interface BreadcrumbNavLinkProps extends NavLinkProps {
  disableLink?: true;
}

const BreadcrumbNavLink: React.FC<BreadcrumbNavLinkProps> = makeComp(
  ({disableLink, ...passProps}) => {
    if (disableLink) {
      return <span className="fake-link">{passProps.children}</span>;
    }

    return <NavLink {...passProps} />;
  },
  {id: 'BreadcrumbNavLink', memo: true}
);
