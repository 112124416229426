import * as S from './QueryEditorDeleteButton.styles';

import React from 'react';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import makeComp from '../../util/profiler';

const QueryEditorDeleteButton: React.FC<React.HTMLAttributes<HTMLDivElement>> =
  makeComp(
    props => {
      return (
        <S.Wrapper {...props}>
          <LegacyWBIcon name="close"></LegacyWBIcon>
        </S.Wrapper>
      );
    },
    {id: 'QueryEditorDeleteButton'}
  );

export default QueryEditorDeleteButton;
