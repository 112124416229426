import React from 'react';
import TimeAgo from 'react-timeago';
import {Table} from 'semantic-ui-react';
import {AgentData} from '../state/graphql/sweepPageQuery';
import ImageIcon from './ImageIcon';

import '../css/ActionTable.less';
import '../css/ReportTable.less';
import makeComp from '../util/profiler';

interface AgentTableProps {
  agents: AgentData[];
}

const AgentTable: React.FC<AgentTableProps> = makeComp(
  ({agents}) => {
    return (
      <div className="action-table report-table">
        <Table selectable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Agent</Table.HeaderCell>
              <Table.HeaderCell>Host</Table.HeaderCell>
              <Table.HeaderCell singleLine>Last heartbeat</Table.HeaderCell>
              <Table.HeaderCell singleLine>Runs launched</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {agents.map(agent => {
              return (
                <Table.Row className="action-table__row" key={agent.id}>
                  <Table.Cell className="action-table__row__desription-cell">
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                      <ImageIcon style={{marginRight: 12}} name="sweep" />
                      {agent.name}
                    </div>
                  </Table.Cell>
                  <Table.Cell singleLine>{agent.host}</Table.Cell>
                  <Table.Cell singleLine>
                    <TimeAgo date={agent.heartbeatAt + 'Z'} live={false} />
                  </Table.Cell>
                  <Table.Cell singleLine>{agent.totalRuns}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  },
  {id: 'AgentTable', memo: true}
);

export default AgentTable;
