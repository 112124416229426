import * as _ from 'lodash';
import * as Normalize from '../normalize';
import * as SectionNormalize from '../section/normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.ProjectPageObjSchema>(
  'project-view',
  (whole, ctx) => {
    return {
      ..._.omit(whole, 'section'),
      sectionRef: SectionNormalize.normalize(whole.section, ctx),
    };
  }
);

export const denormalize = Normalize.denormFn<Types.ProjectPageObjSchema>(
  (part, ctx) => ({
    ..._.omit(part, 'sectionRef'),
    section: SectionNormalize.denormalize(part.sectionRef, ctx),
  })
);
