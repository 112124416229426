import {useEffect, useState} from 'react';
import makeComp from '../util/profiler';
import React from 'react';
import {envIsLocal} from '../config';
import {setCookie, getCookie} from '../util/cookie';
import NudgeBar from './NudgeBar';
import {useLatestLocalVersionInfoQuery} from '../generated/graphql';

const LOCAL_NUDGE_COOKIE_NAME = 'hide local nudge';

const LocalNudgeBar: React.FC = makeComp(
  () => {
    if (!envIsLocal) {
      return <></>;
    }
    const [showNudge, setShowNudge] = useState<boolean>(false);
    const localOutOfDateQuery = useLatestLocalVersionInfoQuery();
    const localOutOfDate =
      localOutOfDateQuery.data?.serverInfo?.latestLocalVersionInfo?.outOfDate ??
      false;
    const latestVersion =
      localOutOfDateQuery?.data?.serverInfo?.latestLocalVersionInfo
        ?.latestVersionString;

    const hasDismissed = getCookie(LOCAL_NUDGE_COOKIE_NAME);
    const contactString =
      'Contact your instance administrator to upgrade or find upgrade instructions here.';
    const mainText =
      latestVersion == null
        ? 'A new version of W&B Local is now available. ' + contactString
        : `Local version ${latestVersion} is now available. ` + contactString;
    const linkText = 'Upgrade local docs →';
    const link = 'https://docs.wandb.ai/self-hosted/local#upgrades';
    const onNudgeDismiss = () => {
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      const expiryDate = new Date(Date.now() + oneWeek);
      setCookie(LOCAL_NUDGE_COOKIE_NAME, 'true', expiryDate);
      setShowNudge(false);
    };

    useEffect(() => {
      setShowNudge(hasDismissed === '' && localOutOfDate);
    }, [localOutOfDate, hasDismissed]);
    return (
      <>
        {showNudge && (
          <NudgeBar
            content={mainText}
            linkText={linkText}
            link={link}
            showDismissOption={true}
            onClose={onNudgeDismiss}
          />
        )}
      </>
    );
  },
  {id: 'LocalNudgeBar', memo: true}
);

export default LocalNudgeBar;
