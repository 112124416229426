/*
 * This component waits to update its children until the next render cycle
 *
 * Current use cases:
 * - Waiting until siblings are properly (un)mounted so that DOM element measurements are accurate.
 *   See https://github.com/laxels/flex-grow-react-sibling
 */

import React, {ReactNode, useLayoutEffect, useMemo, useState} from 'react';
import makeComp from '../util/profiler';

const DelayedRender: React.FC = makeComp(
  ({children}) => {
    const [prevChildren, setPrevChildren] = useState<ReactNode>(children);

    useLayoutEffect(() => {
      setPrevChildren(children);
    }, [children]);

    const rendered = useMemo(() => <>{prevChildren}</>, [prevChildren]);
    return rendered;
  },
  {id: 'DelayedRender', memo: true}
);

export default DelayedRender;
