import React, {useMemo, useState} from 'react';
import makeComp from '../util/profiler';

import {ArtifactSequence} from '../state/graphql/projectArtifactsQuery';

import {
  ArtifactSidebarPaginate,
  ArtifactSidebarSequenceWithUrlToggle,
} from './ArtifactSidebarSequence';

import './ArtifactsSidebar.less';

import {
  ArtifactsBox,
  ArtifactSearchBar,
  ArtifactSidebarTypePaginate,
  ArtifactTypeBox,
  ArtifactTypeContainer,
} from './ArtifactSidebar.styles';
import {fuzzyMatch} from '../util/fuzzyMatch';
import classNames from 'classnames';
import {useDebounceState} from '../util/hooks';
import {Icon} from 'semantic-ui-react';
import {Maybe, ProjectArtifactsQuery} from '../generated/graphql';

interface ArtifactSidebarTypeProps {
  id?: string;
  name: string;
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactSequences?: Array<
    Maybe<Pick<ArtifactSequence, 'id' | 'name'>> | undefined
  >;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  refetchProjectArtifacts: () => void;
}

const ARTIFACTS_PER_SECTION = 10;

function usePaginate<T>(items: T[], perPage: number) {
  const total = items.length;
  const totalPages = Math.ceil(total / perPage);

  const [page, setPage] = useState(0);
  const pageWithMax = Math.min(page, totalPages - 1);

  const hasPreviousPage = pageWithMax > 0;
  const hasNextPage = pageWithMax < totalPages - 1;

  const skip = perPage * pageWithMax;
  const itemsPage = items.slice(skip, skip + perPage);

  return {
    total,
    totalPages,
    page: pageWithMax,
    setPage,
    itemsPage,
    hasNextPage,
    hasPreviousPage,
  };
}

const ArtifactSidebarType = makeComp<ArtifactSidebarTypeProps>(
  props => {
    const {
      id,
      name,
      artifactSequences,
      artifactTypeName,
      artifactCollectionName,
    } = props;

    const as = (artifactSequences?.filter(s => s != null) ?? []) as Array<
      Pick<ArtifactSequence, 'id' | 'name'>
    >;

    const {
      totalPages,
      hasNextPage,
      hasPreviousPage,
      setPage,
      total,
      page,
      itemsPage,
    } = usePaginate(as, ARTIFACTS_PER_SECTION);

    const [active, setActive] = useState(true);
    const icon = active ? 'angle down' : 'angle right';

    return (
      <ArtifactTypeContainer key={id} className="artifact-type">
        <ArtifactTypeBox onClick={() => setActive(s => !s)}>
          <Icon name={icon} />
          <span className="artifact-type-title">{name}</span>
        </ArtifactTypeBox>
        {active && (
          <ArtifactsBox>
            {itemsPage.map(a => (
              <ArtifactSidebarSequenceWithUrlToggle
                key={a.id}
                {...props}
                {...a}
                artifactCollectionName={artifactCollectionName}
                activeArtifactTypeName={artifactTypeName}
                artifactTypeName={name}
              />
            ))}
            {totalPages > 0 && (
              <ArtifactSidebarTypePaginate>
                <ArtifactSidebarPaginate
                  size="tiny"
                  perPage={ARTIFACTS_PER_SECTION}
                  page={page}
                  total={total}
                  pageInfo={{hasNextPage, hasPreviousPage}}
                  onNextClick={() => setPage(s => s + 1)}
                  onPreviousClick={() => setPage(s => s - 1)}
                />
              </ArtifactSidebarTypePaginate>
            )}
          </ArtifactsBox>
        )}
      </ArtifactTypeContainer>
    );
  },
  {id: 'ArtifactSidebarType'}
);

interface ArtifactSidebarProps {
  entityName: string;
  projectName: string;
  artifactTypes: NonNullable<
    NonNullable<ProjectArtifactsQuery['project']>['artifactTypes']
  >;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  refetchProjectArtifacts: () => void;
}

const ArtifactSidebar = makeComp<ArtifactSidebarProps>(
  props => {
    const [focused, setFocused] = useState(false);
    const [searchQuery, searchQueryDebounced, setSearchQuery] =
      useDebounceState('', 250);

    const {artifactCollectionName, artifactTypes, artifactTypeName} = props;

    const allArtifactNames = useMemo(() => {
      const names: string[] = [];
      const artifactTypeNodes = artifactTypes.edges.map(at => at.node);
      for (const at of artifactTypeNodes) {
        for (const e of at?.artifactSequences?.edges ?? []) {
          const name = e.node?.name;
          if (name != null) {
            names.push(name);
          }
        }
      }
      return names;
    }, [artifactTypes.edges]);

    const filteredArtifactTypes = useMemo(() => {
      const artifactNamesFiltered = fuzzyMatch(
        allArtifactNames,
        searchQueryDebounced
      );
      return artifactTypes.edges
        .filter(at => at.node != null)
        .map(at => ({
          ...at.node!,
          artifactSequences: (at?.node?.artifactSequences?.edges ?? [])
            .map(e => e.node)
            .filter(
              seq =>
                seq != null &&
                (searchQueryDebounced === '' ||
                  artifactNamesFiltered.includes(seq.name))
            ),
        }));
    }, [searchQueryDebounced, artifactTypes.edges, allArtifactNames]);

    return (
      <>
        <ArtifactSearchBar
          className={classNames({'artifact-sidebar-search-bar-focus': focused})}
          icon={{className: 'wbic-ic-search', size: 'large'}}
          iconPosition="left"
          onChange={(_, {value}) => setSearchQuery(value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(searchQuery.length > 0)}
          placeholder={'Find matching artifacts'}
        />
        {filteredArtifactTypes
          .filter(({artifactSequences}) => artifactSequences.length > 0)
          .map(artifact => (
            <ArtifactSidebarType
              key={artifact.id}
              {...props}
              {...artifact}
              artifactTypeName={artifactTypeName}
              artifactCollectionName={artifactCollectionName}
            />
          ))}
      </>
    );
  },
  {id: 'ArtifactSidebar'}
);

export default ArtifactSidebar;
