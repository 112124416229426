import * as React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {auth} from '../setup';
import makeComp from '../util/profiler';
import {login} from '../util/urls';

export const LoginButton = makeComp(
  (props: any) => {
    return (
      <Button
        data-test="login-button"
        color="yellow"
        {...props}
        onClick={() => auth.login()}>
        Login
      </Button>
    );
  },
  {id: 'LoginButton'}
);
export const SignupButton = makeComp(
  ({hideIcon, ...props}: any) => {
    const text = props.text;
    return (
      <Button
        data-test="signup"
        className="signup-button"
        primary
        {...props}
        as="a"
        href={login({signup: true})}>
        {text != null ? (
          text
        ) : (
          <>
            Sign up
            {!hideIcon && <Icon name="arrow right" />}
          </>
        )}
      </Button>
    );
  },
  {id: 'SignupButton'}
);
