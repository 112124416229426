import {HistorySpec} from './../../types/run';
import {Draft} from 'immer';
import * as _ from 'lodash';

import * as Filters from '../../util/filters';
import * as Run from '../../util/runs';
import * as Obj from '@wandb/cg/browser/utils/obj';
import {ApolloClient} from '../types';
import * as Actions from './actions';
import * as History from './history';
import * as Meta from './meta';
import * as QueryMerge from './queryMerge';
import {RunsReducerState, HistoryKeyInfoState} from './reducer';
import * as ServerQuery from './serverQuery';
import * as Types from './types';

type runKeyFunc = (run: Run.Run) => string;

export const DEFAULT_BASIC_FRAGMENT: Types.RunBasicFragment['data'] = {
  notes: '',
  group: '',
  jobType: '',
  createdAt: '',
  heartbeatAt: '',
  host: '',
  state: '',
  stopped: false,
  user: {
    photoUrl: '',
    username: '',
  },
  tags: [],
  servicesAvailable: {
    tensorboard: false,
  },
};
export const DEFAULT_HISTORY_KEYS_FRAGMENT: Types.RunHistoryKeysFragment['data'] =
  {};

// Get the state key for a run that is a result of the specified query.
//
// When a query fetches runs grouped, the name of a run is no longer a unique
// identifier. We also can't just use the group keys as part of the key because
// the filters provided affect the properties of the run. To solve this we have
// to store grouped runs scoped exclusively to the server query that fetched the
// run.
export function runKeyFuncForQuery(query: Types.MergedQuery): runKeyFunc {
  if (query.query.query.grouping.length > 0) {
    const queryIds = [...query.sourceIDs];
    queryIds.sort();
    const prefix = queryIds.join(',') + '/';
    return r => prefix + r.name;
  }
  return r => r.name;
}

// This function merges queries that can be merged so that we generate less
// traffic and load.
//
// It currently does a "historyKeyInfo aware" merge. Line plot panels add
// extra keys_info filters to filter down to runs that contain the history metrics
// the user is plotting. We keep track of known historyKeyInfo for each
// historyKeyInfo query in the app. If we can find it, we remove the keys_info
// filters from the query, and generate a historyKeyInfo signature for
// the query instead. The historyKeyInfo signature is all of the keysets
// that the keys_info filter would match.
//
// We also merge historySpecs when we can.
export function mergeQueries(
  queries: Types.CachedQuery[],
  historyKeyInfos: HistoryKeyInfoState[]
): Types.MergedQuery[] {
  // Compute each query's merge signature, and store it alongside the query.
  const queriesWithMergeSignatures = queries.map(q => ({
    query: q,
    mergeSignature: QueryMerge.queryMergeSignature(q, historyKeyInfos),
  }));
  // Group by the stringified merge signature. When merge signatures match, we
  // can merge these queries
  const merged = _.groupBy(queriesWithMergeSignatures, ({mergeSignature}) =>
    JSON.stringify(mergeSignature)
  );

  // For each merged group of queries, compute a single query
  const mergeResult = _.values(merged).map(qms => {
    // We can use most fields from the first query in the group.
    const query0 = qms[0].query;
    // Within each original query, try to merge specs together. Need to do this for
    // panels with multiple same-keyset keys to ensure consistent sampling.
    const historySpecs = _.flatMap(qms, qm => {
      // The query signature computation already figured out which historyKeySets
      // result can be used, given this query's filters. We use it again here to
      // merge historySpecs.
      const historyKeySetsIndex = qm.mergeSignature.historyKeySetsIndex;
      let histSpecs = qm.query.query.historySpecs || [];
      if (historyKeySetsIndex !== -1) {
        // Group the history specs together based on their merge signatures
        const groupByHistorySpecs = _.groupBy(histSpecs, hs =>
          QueryMerge.historySpecMergeSignature(
            hs,
            historyKeyInfos[historyKeySetsIndex].historyKeySets
          )
        );
        histSpecs = _.values(groupByHistorySpecs).map(historySpecsGroup => ({
          ...historySpecsGroup[0],
          keys: _.uniq(_.flatMap(historySpecsGroup, hs => hs.keys)).sort(),
        }));
      }
      return histSpecs;
    });
    return {
      sourceIDs: qms.map(q => q.query.id),
      sourceGenerations: qms.map(q => q.query.generation),
      query: {
        ...query0,
        query: {
          ...query0.query,
          historySpecs: historySpecs.length > 0 ? historySpecs : undefined,
        },
      },
    };
  });
  return mergeResult;
}

// Given the current state, generate queries to send to the server. Individual
//   queries might be merged into a single query.
// isPoll: If false, we generate initial queries. If true, we generate update
//   queries, that only request data newer than the data we already have.
export function prepareQueries<SQ, SR>(
  serverQueryStrategy: ServerQuery.Transform<SQ, SR>,
  state: RunsReducerState,
  isPoll: boolean,
  exclude?: Types.QueryGeneration[]
) {
  const queries = _.values(state.queries)
    .filter(rq => (isPoll ? !rq.loading : rq.loading))
    .filter(
      rq =>
        exclude == null ||
        exclude.find(
          eq => eq.id === rq.id && eq.generation === rq.generation
        ) == null
    );
  if (queries.length === 0) {
    return [];
  }

  const mergedQueries = mergeQueries(queries, _.values(state.historyKeyInfos));

  return mergedQueries.map(mq => {
    return {
      ...mq,
      serverQuery: serverQueryStrategy.toServerQuery(mq.query.query, {
        totalRuns: mq.query.totalRuns,
        runs: mq.query.result.map(id => {
          const run = state.runs[id];
          return {
            ...run.identity.data,
            ...fragmentData(run.basic, DEFAULT_BASIC_FRAGMENT),
            ...fragmentData(run.historyKeys, DEFAULT_HISTORY_KEYS_FRAGMENT),
            // we don't actually need these for query updating at the moment.
            // if we end up changing this, we'll have to pull this data.
            sampledHistory: [],
            config: {},
            summary: {},
            _wandb: {},
          };
        }),
        lastUpdatedAt: mq.query.lastUpdatedAt,
      }),
    };
  });
}

// Given the results of a set of queries, update the store.
export function applyQueryResults<SQ, SR>(
  serverQueryStrategy: ServerQuery.Transform<SQ, SR>,
  state: RunsReducerState,
  mergedQueries: Types.MergedQuery[],
  results: SR[]
): RunsReducerState {
  let result = state;
  for (const [mergedQuery, serverResult] of _.zip(mergedQueries, results)) {
    if (mergedQuery == null || serverResult == null) {
      throw new Error('Different length lists for mergeQueryResults');
    }

    // if none of the source queries exist or match the generation at query
    // time, the runs referenced in them are likely already GCd, and we don't
    // need to do anything.
    if (
      mergedQuery.sourceIDs.filter(
        (id, i) =>
          state.queries[id] != null &&
          state.queries[id].generation === mergedQuery.sourceGenerations[i]
      ).length === 0
    ) {
      continue;
    }

    const runKey = runKeyFuncForQuery(mergedQuery);

    // Dernormalize the history
    const prevResult: Types.QueryResult = {
      totalRuns: mergedQuery.query.totalRuns,
      runs: mergedQuery.query.result.map(runID => {
        const run = state.runs[runID];
        const query = mergedQuery.query.query;
        const config = query.fullConfig
          ? fragmentData(run.fullConfig, {config: {}}).config
          : Meta.getMetaResult(run.normalizedConfig, query.configKeys);
        const summary = query.fullSummary
          ? fragmentData(run.fullSummary, {summary: {}}).summary
          : Meta.getMetaResult(run.normalizedSummary, query.summaryKeys);
        const denormRun: Run.Run = {
          ...run.identity.data,
          ...fragmentData(run.basic, DEFAULT_BASIC_FRAGMENT),
          ...fragmentData(run.historyKeys, DEFAULT_HISTORY_KEYS_FRAGMENT),
          config,
          summary,
          _wandb: Meta.getMetaResult(run.normalizedWandb, query.wandbKeys),
        };
        return denormRun;
      }),
      lastUpdatedAt: mergedQuery.query.lastUpdatedAt,
    };

    // Convert from a server result to a query result
    const finalServerResult = serverQueryStrategy.fromServerResult(
      mergedQuery.query.query,
      serverResult,
      prevResult
    );

    // We always store normalized history in terms of source queries, not
    // merged queries.
    const sourceQueryHistorySpecs = _.flatten(
      mergedQuery.sourceIDs
        .map(sid =>
          state.queries[sid] != null
            ? state.queries[sid].query.historySpecs
            : []
        )
        .filter(Obj.notEmpty)
    );

    // Update run cache.
    result = {
      ...result,
      runs: updateRunCache(
        mergedQuery.query.query,
        sourceQueryHistorySpecs,
        finalServerResult.runs,
        result.runs,
        runKey
      ),
    };

    // Unmerge result
    for (const [sourceQueryID, sourceQueryGen] of _.zip(
      mergedQuery.sourceIDs,
      mergedQuery.sourceGenerations
    )) {
      if (sourceQueryID == null || sourceQueryGen == null) {
        throw new Error('Different length lists for merged query sources');
      }

      const query = state.queries[sourceQueryID];
      if (query == null) {
        // This happens if the query is removed
        continue;
      }
      if (query.generation !== sourceQueryGen) {
        // The query was updated since this request was fired, so don't apply this result
        continue;
      }
      result = {
        ...result,
        queries: {
          ...result.queries,
          [sourceQueryID]: {
            ...result.queries[sourceQueryID],
            loading: false,
            totalRuns: finalServerResult.totalRuns,
            result: finalServerResult.runs.map(runKey),
            lastUpdatedAt: finalServerResult.lastUpdatedAt,
          },
        },
      };
    }
  }
  return result;
  // At this point there may be runs that have "fallen out of favor". We rely
  // on the reducer to call collectGarbage below.
}

// Update the cached runs that we stored in apollo, given updated runs.
export function updateRunCache(
  // The merged query we made to the server
  query: Pick<
    Types.Query,
    | 'historySpecs'
    | 'enableBasic'
    | 'configKeys'
    | 'summaryKeys'
    | 'wandbKeys'
    | 'enableHistoryKeyInfo'
  >,
  // History specs for source queries that resulted in the merged query
  sourceQueryHistorySpecs: HistorySpec[],
  // The merged query result we received from the server
  updatedRuns: Run.Run[],
  runs: RunsReducerState['runs'],
  runKey: runKeyFunc
): RunsReducerState['runs'] {
  let result = runs;
  for (const run of updatedRuns) {
    const key = runKey(run);
    const prevRun = result[key];
    let runResult = applyRunFieldUpdate(query, prevRun, run);
    if (
      query.historySpecs != null &&
      sourceQueryHistorySpecs != null &&
      run.sampledHistory != null
    ) {
      if (query.historySpecs.length !== run.sampledHistory.length) {
        throw new Error('Different length lists for updateRunHistory');
      }
      const historyQueryResult = _.zip(
        query.historySpecs,
        run.sampledHistory
      ).map(([spec, rows]) => ({spec: spec!, rows: rows!}));
      runResult = {
        ...runResult,
        normalizedSampledHistory: History.updateRunHistory(
          runResult.normalizedSampledHistory,
          historyQueryResult,
          sourceQueryHistorySpecs
        ),
      };
    }
    runResult = {
      ...runResult,
      normalizedConfig: Meta.applyMetaUpdate(
        runResult.normalizedConfig,
        query.configKeys,
        run.config,
        run.updatedAt
      ),
      normalizedSummary: Meta.applyMetaUpdate(
        runResult.normalizedSummary,
        query.summaryKeys,
        run.summary,
        run.updatedAt
      ),
      normalizedWandb: Meta.applyMetaUpdate(
        runResult.normalizedWandb,
        query.wandbKeys,
        run._wandb,
        run.updatedAt
      ),
    };
    result = {...result, [key]: runResult};
  }
  return result;
}

type QueryFragmentEnables = Pick<
  Types.Query,
  | 'enableBasic'
  | 'enableHistoryKeyInfo'
  | 'fullConfig'
  | 'configKeys'
  | 'fullSummary'
  | 'summaryKeys'
  | 'wandbKeys'
>;

function runToFragments(
  query: QueryFragmentEnables,
  run: Run.Run
): [
  Types.RunIdentityFragment,
  Types.RunBasicFragment | undefined,
  Types.RunHistoryKeysFragment | undefined,
  Types.RunFullConfigFragment | undefined,
  Types.RunFullSummaryFragment | undefined
] {
  const {
    // identity
    id,
    name,
    displayName,
    updatedAt,

    // historyKeys
    historyKeys,

    // handled separately
    sampledHistory,
    config,
    summary,
    _wandb,

    // basic
    ...basicFields
  } = run;

  const identityFragment: Types.RunIdentityFragment = {
    updated: updatedAt,
    data: {id, name, displayName, updatedAt},
  };

  const basicFragment: Types.RunBasicFragment = {
    updated: updatedAt,
    data: basicFields,
  };

  const historyKeysFragment: Types.RunHistoryKeysFragment = {
    updated: updatedAt,
    data: {historyKeys},
  };

  const fullConfigFragment: Types.RunFullConfigFragment = {
    updated: updatedAt,
    data: {config},
  };

  const fullSummaryFragment: Types.RunFullSummaryFragment = {
    updated: updatedAt,
    data: {summary},
  };

  return [
    identityFragment,
    query.enableBasic ? basicFragment : undefined,
    query.enableHistoryKeyInfo ? historyKeysFragment : undefined,
    query.fullConfig ? fullConfigFragment : undefined,
    query.fullSummary ? fullSummaryFragment : undefined,
  ];
}

export function applyFragmentUpdate<D>(
  prevFragment: Draft<Types.Fragment<D>> | undefined,
  fragment: Types.Fragment<D> | undefined
): Types.Fragment<D> | undefined {
  if (
    fragment != null &&
    (prevFragment == null || prevFragment.updated < fragment.updated)
  ) {
    return fragment;
  }
  return prevFragment as Types.Fragment<D>;
}

export function fragmentData<D>(
  fragment: Types.Fragment<D> | undefined,
  defaultData: D
): D {
  return fragment != null ? fragment.data : defaultData;
}

// Update a cached run, merging in all fields that are newer than the latest
// updatedAt timestamp we have for that fragment.
export function applyRunFieldUpdate(
  query: QueryFragmentEnables,
  prevRun: Draft<Types.CachedRun> | undefined,
  run: Run.Run
): Draft<Types.CachedRun> {
  const [identity, basic, historyKeys, fullConfig, fullSummary] =
    runToFragments(query, run);
  if (prevRun == null) {
    return {
      identity,
      basic,
      historyKeys,
      fullConfig,
      fullSummary,
      normalizedSampledHistory: [],
      normalizedConfig: [],
      normalizedSummary: [],
      normalizedWandb: [],
    };
  }
  return {
    ...prevRun,
    identity: applyFragmentUpdate(prevRun.identity, identity)!,
    basic: applyFragmentUpdate(prevRun.basic, basic),
    historyKeys: applyFragmentUpdate(prevRun.historyKeys, historyKeys),
    fullConfig: applyFragmentUpdate(prevRun.fullConfig, fullConfig),
    fullSummary: applyFragmentUpdate(prevRun.fullSummary, fullSummary),
  };
}

// Clears any runs that are no longer needed.
export function collectGarbage(state: RunsReducerState) {
  const keepRunIDs = _.uniq(_.flatMap(state.queries, q => q.result));
  // This makes an object with keepRunIDs as keys (and values, which are unused)
  const keepRunIDsLookup = _.zipObject(keepRunIDs, keepRunIDs);
  const deleteRunIDs: string[] = [];
  _.forEach(state.runs, (run, id) => {
    if (keepRunIDsLookup[id] == null) {
      deleteRunIDs.push(id);
    }
  });
  for (const id of deleteRunIDs) {
    delete state.runs[id];
  }
}

export function executeQueries<SQ, SR>(
  serverQueryStrategy: ServerQuery.StrategyGraphql<SQ, SR>,
  mergedQueries: Array<Types.MergedServerQuery<SQ>>,
  client: ApolloClient,
  dispatch: any
) {
  const promises = mergedQueries.map(mq =>
    serverQueryStrategy.doQuery(client, mq.serverQuery)
  );
  Promise.all(promises).then(results =>
    dispatch(Actions.queryResults(mergedQueries, results))
  );
}

function queryForCompare(
  query: Types.Query,
  extras: {[key: string]: any} = {}
) {
  return {
    ...query,
    filters: Filters.toMongo(query.filters),
    ...extras,
  };
}

// Returns true if newQuery would update stateQuery.
export function queryNeedsUpdate(
  newQuery: Types.Query,
  stateQuery: Types.CachedQuery | null
) {
  return (
    stateQuery != null &&
    !_.isEqual(queryForCompare(stateQuery.query), queryForCompare(newQuery))
  );
}

// Returns true if the results of the stateQuery need to be cleared on update.
export function queryNeedsFetch(
  newQuery: Types.Query,
  stateQuery: Types.CachedQuery
) {
  return (
    !_.isEqual(
      queryForCompare(stateQuery.query, {
        limit: 0,
      }),
      queryForCompare(newQuery, {
        limit: 0,
      })
    ) || newQuery.limit > stateQuery.query.limit
  );
}

// Returns true if the results of the stateQuery need to be cleared on update.
export function queryNeedsClear(
  newQuery: Types.Query,
  stateQuery: Types.CachedQuery
) {
  return !_.isEqual(
    queryForCompare(stateQuery.query, {
      limit: 0,
      filters: null,
      sort: null,
    }),
    queryForCompare(newQuery, {
      limit: 0,
      filters: null,
      sort: null,
    })
  );
}

// Updates stateQuery based on the changes in newQuery
export function updateQuery(
  newQuery: Types.Query,
  stateQuery: Types.CachedQuery
) {
  // Mark the query as loading to force a new query
  if (queryNeedsFetch(newQuery, stateQuery)) {
    stateQuery.loading = true;
    stateQuery.lastUpdatedAt = new Date(0).toISOString();
  }

  // if the structural parameters (fragments or history specs) changed, we can't
  // use the existing results, even temporarily
  if (queryNeedsClear(newQuery, stateQuery)) {
    stateQuery.result = [];
    stateQuery.lastUpdatedAt = new Date(0).toISOString();
  } else if (newQuery.limit < stateQuery.query.limit) {
    // if we are keeping the old results, truncate the existing results to match
    // any change in page size
    stateQuery.result.splice(newQuery.limit);
  }

  stateQuery.generation++;
  stateQuery.query = newQuery;
}
