// This shares a lot of code with RunPagePanels, try to keep them
// in sync, or refactor
import * as React from 'react';

import Inspector from '../components/Inspector';
import RunSelector from './RunSelector';
import ViewBar from './Views/ViewBar';
import WandbLoader from './WandbLoader';
import WorkspaceErrorHandler from './WorkspaceErrorHandler';

import {getTheme} from '../pages/Benchmark/Theme';
import * as Filter from '../util/filters';
import * as Run from '../util/runs';
import * as Query from '../util/queryts';

import {
  MultiRunWorkspaceQueryData,
  useMultiRunWorkspaceQuery,
} from '../state/graphql/multiRunWorkspaceQuery';
import * as MultiRunWorkspaceActions from '../state/views/multiRunWorkspace/actions';
import {RunQueryContext, RunsQueryContext} from '../state/runs/context';
import * as ViewHooks from '../state/views/hooks';
import * as ViewerHooks from '../state/viewer/hooks';
import PanelBank from './PanelBank';
import {ProjectPageViewSpec} from '../state/views/projectPage/types';
import makeComp from '../util/profiler';
import NoMatch from './NoMatch';
import {
  createDefaultSpec,
  RecommendGrouping,
  useRunWorkspaceProps,
  ViewType,
} from './RunWorkspace';

export interface MultiRunWorkspaceProps {
  entityName: string;
  projectName: string;
  tableExpanded: boolean;
  viewType: ViewType;
  title: string;
  mergeFilters?: Filter.Filter;
  runSetName?: string;
  recommendGrouping: RecommendGrouping;
  workspaceObjectID?: string;
  runsQueryContextValue?: Partial<RunsQueryContext>;
  isSingleMode?: boolean;
  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  selectedRunName?: string;
  onSelectRunName?: (runId: string) => void;
  defaultSpecPostFn?: (spec: ProjectPageViewSpec) => ProjectPageViewSpec;
  onSetTableExpanded?(expanded: boolean): void;
}

type UseMultiRunWorkspaceQueryPropsResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | {
      loading: false;
      error: null;
      readyProps: MultiRunWorkspaceReadyProps;
    };

interface MultiRunWorkspaceReadyProps {
  project: MultiRunWorkspaceQueryData['project'];
  defaultSpec: ProjectPageViewSpec;
  recommendedGrouping: Query.Grouping;
}

export function useMultiRunWorkspaceQueryProps(
  props: MultiRunWorkspaceProps
): UseMultiRunWorkspaceQueryPropsResult {
  const {entityName, projectName, recommendGrouping, defaultSpecPostFn} = props;
  // We need to compute the default workspace step, which relies on first
  // loading the project.
  const multiRunWorkspaceQuery = useMultiRunWorkspaceQuery({
    entityName,
    projectName,
  });

  if (multiRunWorkspaceQuery.loading) {
    return {loading: true, error: null};
  }

  const project = multiRunWorkspaceQuery.project;

  if (project == null) {
    return {loading: false, error: true};
  }

  const recommendedGrouping: Query.Grouping = [];

  let defaultSpec = createDefaultSpec();
  if (project.totalRunsWithGroup > 0) {
    if (recommendGrouping === 'group-and-jobtype') {
      recommendedGrouping.push({
        section: 'run',
        name: 'group',
      } as Run.Key);
    }
    if (project.totalRunsWithJobType > 0) {
      recommendedGrouping.push({
        section: 'run',
        name: 'jobType',
      } as Run.Key);
    }
  }
  defaultSpec.section.runSets![0].grouping = recommendedGrouping;

  if (defaultSpecPostFn != null) {
    defaultSpec = defaultSpecPostFn(defaultSpec);
  }

  return {
    loading: false,
    error: null,
    readyProps: {
      project: multiRunWorkspaceQuery.project,
      defaultSpec,
      recommendedGrouping,
    },
  };
}

export default makeComp(
  (props: MultiRunWorkspaceProps) => {
    const {entityName, projectName, runsQueryContextValue} = props;
    const multiRunWorkspaceProps = useMultiRunWorkspaceQueryProps(props);
    if (multiRunWorkspaceProps.loading) {
      return <WandbLoader />;
    }
    if (multiRunWorkspaceProps.error) {
      return <NoMatch />;
    }
    return (
      <RunQueryContext.Provider
        value={{
          entityName,
          projectName,
          mergeFilters: props.mergeFilters,
          runSetName: props.runSetName,
          ...runsQueryContextValue,
        }}>
        <MultiRunWorkspaceWorkspace
          {...props}
          {...multiRunWorkspaceProps.readyProps}
        />
      </RunQueryContext.Provider>
    );
  },
  {id: 'MultiRunWorkspace.default'}
);

type MultiRunWorkspaceWorkspaceProps = MultiRunWorkspaceProps &
  MultiRunWorkspaceReadyProps;

const MultiRunWorkspaceWorkspace = makeComp(
  (props: MultiRunWorkspaceWorkspaceProps) => {
    const multiRunWorkspaceProps = useRunWorkspaceProps(props);
    if (multiRunWorkspaceProps.loading) {
      return <WandbLoader />;
    }
    return (
      <WorkspaceErrorHandler
        workspaceID={multiRunWorkspaceProps.readyProps.workspaceID}
        viewType={props.viewType}>
        <MultiRunWorkspace {...props} {...multiRunWorkspaceProps.readyProps} />
      </WorkspaceErrorHandler>
    );
  },
  {id: 'MultiRunWorkspaceWorkspace'}
);

type MultiRunWorkspaceAllProps = MultiRunWorkspaceWorkspaceProps &
  ReturnType<typeof useRunWorkspaceProps>['readyProps'];

const MultiRunWorkspace = makeComp<MultiRunWorkspaceAllProps>(
  props => {
    const {
      entityName,
      projectName,
      project,
      workspace,
      workspaceID,
      children,
      isSingleMode,
      selectedRunName,
      onSelectRunName,
    } = props;

    const viewer = ViewerHooks.useViewer();

    const readOnly = !viewer || project.readOnly; // enforce readOnly=true for anonymous users

    const linkedBenchmarkMeta =
      !project.isBenchmark && project.linkedBenchmark != null
        ? project.linkedBenchmark
        : null;

    const linkedBenchmarkInfo =
      linkedBenchmarkMeta != null ? getTheme(linkedBenchmarkMeta) : null;

    const hideSlowWarning = ViewHooks.useViewAction(
      workspace.partRef,
      MultiRunWorkspaceActions.hideSlowWarning
    );

    return (
      <>
        <div className="project-page-workspace">
          <RunSelector
            // Make sure to remount if the runSetRef id changes (happens
            // when we load a new workspace, or clear our workspace)
            key={workspace.runSetRef.id}
            className="single-wb-table"
            expandable
            expanded={props.tableExpanded}
            onSetExpanded={props.onSetTableExpanded}
            pageEntityName={entityName}
            pageProjectName={projectName}
            runQueues={project.runQueues}
            // TODO: We don't need this anymore right?
            pollInterval={0}
            benchmarkMeta={project.linkedBenchmark}
            displayBenchmark={linkedBenchmarkInfo != null}
            showArtifactCounts={props.showArtifactCounts}
            showLogCounts={props.showLogCounts}
            customRunColorsRef={workspace.sectionPart.customRunColorsRef}
            selectedRunName={selectedRunName}
            onSelectRunName={onSelectRunName}
            runSetRef={workspace.runSetRef}
            isSingleMode={isSingleMode}
            title={props.title}
            recommendedGrouping={props.recommendedGrouping}
          />
          {children ? (
            <div style={{flexGrow: 1}}>{children}</div>
          ) : (
            <PanelBank
              entityName={entityName}
              projectName={projectName}
              readOnly={readOnly}
              panelSettingsRef={workspace.panelSettingsRef}
              panelBankConfigRef={workspace.panelBankConfigRef}
              customRunColorsRef={workspace.sectionPart.customRunColorsRef}
              runSetRefs={workspace.sectionPart.runSetRefs}
              showSlowWarning={workspace.part.slowWarningHiddenAt == null}
              hideSlowWarning={hideSlowWarning}
              workspaceID={workspaceID}
            />
          )}
          {viewer?.userInfo?.bio?.includes('INSPECTOR') && (
            <Inspector runSetRefs={workspace.sectionPart.runSetRefs} />
          )}
        </div>
        <ViewBar
          workspaceID={workspaceID}
          readOnly={readOnly}
          entityName={entityName}
          projectName={projectName}
        />
      </>
    );
  },
  {id: 'MultiRunWorkspace'}
);
