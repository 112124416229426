import * as _ from 'lodash';
import {base32Encode, base32Decode} from '@ctrl/ts-base32';
import * as Run from './runs';

import jsep from 'jsep';

// Clone of jsep typings with union types
export type Expression =
  | ArrayExpression
  | BinaryExpression
  | CallExpression
  | Compound
  | ConditionalExpression
  | Identifier
  | Literal
  | LogicalExpression
  | MemberExpression
  | ThisExpression
  | UnaryExpression;

interface ArrayExpression {
  type: 'ArrayExpression';
  elements: Expression[];
}

interface BinaryExpression {
  type: 'BinaryExpression';
  operator:
    | '|'
    | '^'
    | '&'
    | '=='
    | '!='
    | '==='
    | '!=='
    | '<'
    | '>'
    | '<='
    | '>='
    | '<<'
    | '>>'
    | '>>>'
    | '+'
    | '-'
    | '*'
    | '/'
    | '%'
    | '**';
  left: Expression;
  right: Expression;
}

export interface CallExpression {
  type: 'CallExpression';
  arguments: Expression[];
  callee: Expression;
}

interface Compound {
  type: 'Compound';
  body: Expression[];
}

interface ConditionalExpression {
  type: 'ConditionalExpression';
  test: Expression;
  consequent: Expression;
  alternate: Expression;
}

interface Identifier {
  type: 'Identifier';
  name: string;
}

interface Literal {
  type: 'Literal';
  value: boolean | number | string;
  raw: string;
}

interface LogicalExpression {
  type: 'LogicalExpression';
  operator: '||' | '&&';
  left: Expression;
  right: Expression;
}

interface MemberExpression {
  type: 'MemberExpression';
  computed: boolean;
  object: Expression;
  property: Expression;
}

interface ThisExpression {
  type: 'ThisExpression';
}

interface UnaryExpression {
  type: 'UnaryExpression';
  operator: '-' | '!' | '~' | '+';
  argument: Expression;
  prefix: boolean;
}

const mathFuncs = Object.getOwnPropertyNames(Math).filter(
  k => typeof (Math as any)[k] === 'function' && (Math as any)[k].length > 0
);
const varargsMathFuncs = ['min', 'max', 'hypot'];

const mathLiterals = Object.getOwnPropertyNames(Math)
  .filter(k => typeof (Math as any)[k] === 'number')
  .map(k => k.toLowerCase());

const literals = ['true', 'false', 'null', ...mathLiterals];

// add exponent
jsep.addBinaryOp('**', 11);
// add math constants
mathLiterals.forEach(k => {
  (jsep as any).addLiteral(k, (Math as any)[k.toUpperCase()]);
});

function validateExpression(
  expr: Expression,
  identifiers?: {[key: string]: boolean}
): boolean {
  switch (expr.type) {
    case 'ArrayExpression':
      return false;
    case 'BinaryExpression':
      return (
        validateExpression(expr.left, identifiers) &&
        validateExpression(expr.right, identifiers)
      );
    case 'CallExpression':
      return validateCall(expr);
    case 'Compound':
      return false;
    case 'ConditionalExpression':
      return (
        validateExpression(expr.test, identifiers) &&
        validateExpression(expr.consequent, identifiers) &&
        validateExpression(expr.alternate, identifiers)
      );
    case 'Identifier':
      if (identifiers == null) {
        return true;
      } else {
        return expr.name in identifiers;
      }
    case 'Literal':
      return typeof expr.value !== 'string';
    case 'LogicalExpression':
      return (
        validateExpression(expr.left, identifiers) &&
        validateExpression(expr.right, identifiers)
      );
    case 'MemberExpression':
      return false;
    case 'ThisExpression':
      return false;
    case 'UnaryExpression':
      return validateExpression(expr.argument, identifiers);
    default:
      return false;
  }
}

function validateCall(expr: CallExpression): boolean {
  if (expr.callee.type !== 'Identifier') {
    return false;
  }
  const name = expr.callee.name;
  if (mathFuncs.indexOf(name) === -1) {
    return false;
  }
  if (expr.arguments.length === 0) {
    return false;
  }
  if (
    varargsMathFuncs.indexOf(name) === -1 &&
    expr.arguments.length !== (Math as any)[name].length
  ) {
    return false;
  }
  return true;
}

export function parseExpression(
  rawExpr: string,
  identifiers?: {[key: string]: boolean}
): Expression | undefined {
  let expr: Expression;
  try {
    expr = (jsep as any)(rawExpr);
  } catch (err) {
    return undefined;
  }
  if (!validateExpression(expr, identifiers)) {
    return undefined;
  }
  return expr;
}

type IdentifiersInExpressionOpts = {
  ignoreCallee?: boolean;
};

export function identifiersInExpression(
  expr: Expression,
  opts: IdentifiersInExpressionOpts = {}
): string[] {
  const {ignoreCallee} = opts;
  switch (expr.type) {
    case 'BinaryExpression' || 'LogicalExpression':
      return [
        ...identifiersInExpression(expr.left, opts),
        ...identifiersInExpression(expr.right, opts),
      ];
    case 'ConditionalExpression':
      return [
        ...identifiersInExpression(expr.alternate, opts),
        ...identifiersInExpression(expr.consequent, opts),
        ...identifiersInExpression(expr.test, opts),
      ];
    case 'CallExpression':
      return [
        ..._.flatten(expr.arguments.map(e => identifiersInExpression(e, opts))),
        ...(ignoreCallee ? [] : identifiersInExpression(expr.callee, opts)),
      ];
    case 'Identifier':
      return [expr.name];
    case 'Literal':
      return [];
    case 'UnaryExpression':
      return identifiersInExpression(expr.argument, opts);
    default:
      return [];
  }
}

export function expressionToString(expr: Expression): string {
  switch (expr.type) {
    case 'BinaryExpression':
      return `(${expressionToString(expr.left)} ${
        expr.operator
      } ${expressionToString(expr.right)})`;
    case 'ConditionalExpression':
      return `(${expressionToString(expr.test)} ? ${expressionToString(
        expr.consequent
      )} : ${expressionToString(expr.alternate)})`;
    case 'CallExpression':
      return `${expressionToString(expr.callee)}(${expr.arguments
        .map(expressionToString)
        .join(', ')})`;
    case 'Identifier':
      return identifierContainsIllegalChars(expr.name)
        ? '${' + expr.name + '}'
        : expr.name;
    case 'Literal':
      return expr.raw;
    case 'LogicalExpression':
      return `(${expressionToString(expr.left)} ${
        expr.operator
      } ${expressionToString(expr.right)})`;
    case 'UnaryExpression':
      return `${expr.operator}${expressionToString(expr.argument)}`;
    default:
      return '';
  }
}

/* tslint:disable no-bitwise */
export function evaluateExpression(
  expr: Expression,
  identifiers: {[key: string]: number}
): number {
  let left: number;
  let right: number;
  let val: number = 0;

  switch (expr.type) {
    case 'BinaryExpression':
      left = evaluateExpression(expr.left, identifiers);
      right = evaluateExpression(expr.right, identifiers);
      switch (expr.operator) {
        case '|':
          val = left | right;
          break;
        case '^':
          val = left ^ right;
          break;
        case '&':
          val = left & right;
          break;
        case '==':
          val = Number(left === right);
          break;
        case '!=':
          val = Number(left !== right);
          break;
        case '===':
          val = Number(left === right);
          break;
        case '!==':
          val = Number(left !== right);
          break;
        case '<':
          val = Number(left < right);
          break;
        case '>':
          val = Number(left > right);
          break;
        case '<=':
          val = Number(left <= right);
          break;
        case '>=':
          val = Number(left >= right);
          break;
        case '<<':
          val = left << right;
          break;
        case '>>':
          val = left >> right;
          break;
        case '>>>':
          val = left >>> right;
          break;
        case '+':
          val = left + right;
          break;
        case '-':
          val = left - right;
          break;
        case '*':
          val = left * right;
          break;
        case '/':
          val = left / right;
          break;
        case '%':
          val = left % right;
          break;
        case '**':
          val = left ** right;
          break;
        default:
          break;
      }
      return val;
    case 'ConditionalExpression':
      const test = evaluateExpression(expr.test, identifiers);
      const consequent = evaluateExpression(expr.consequent, identifiers);
      const alternate = evaluateExpression(expr.alternate, identifiers);
      return test ? consequent : alternate;
    case 'CallExpression':
      const name = (expr.callee as Identifier).name;
      const args = expr.arguments.map(arg =>
        evaluateExpression(arg, identifiers)
      );
      return (Math as any)[name].call(null, ...args);
    case 'Identifier':
      return identifiers[expr.name] != null ? identifiers[expr.name] : NaN;
    case 'Literal':
      return Number(expr.value);
    case 'LogicalExpression':
      left = evaluateExpression(expr.left, identifiers);
      right = evaluateExpression(expr.right, identifiers);
      switch (expr.operator) {
        case '||':
          val = Number(left || right);
          break;
        case '&&':
          val = Number(left && right);
          break;
        default:
          break;
      }
      return val;
    case 'UnaryExpression':
      val = evaluateExpression(expr.argument, identifiers);
      switch (expr.operator) {
        case '-':
          val = -val;
          break;
        case '!':
          val = Number(!val);
          break;
        case '~':
          val = ~val;
          break;
        case '+':
          val = +val;
          break;
        default:
          break;
      }
      return val;
    default:
      return 0;
  }
}
/* tslint:enable no-bitwise */

export function aliasForKey(key: string): string {
  key = key
    .split('')
    .map(c => {
      if (
        (c >= 'a' && c <= 'z') ||
        (c >= 'A' && c <= 'Z') ||
        (c >= '0' && c <= '9') ||
        c === '_' ||
        c === '$'
      ) {
        return c;
      }
      return '_';
    })
    .join('');
  if (key[0] >= '0' && key[0] <= '9') {
    key = '_' + key;
  }
  if (literals.indexOf(key) !== -1) {
    key = '_' + key;
  }
  return key;
}

export type FancyExpression = Expression & {type: 'fancy'};

// Base 32 encode anything inside ${ } so we can use variable names that
// expr can't parse and also have a bijective mapping between the original
// names and the produced names for sanity.

const ENCODED_IDENTIFIER_PREFIX = '_BASE32_';

export function identifierContainsIllegalChars(expr: string) {
  return expr
    .split('')
    .find(
      c =>
        !(
          (c >= 'a' && c <= 'z') ||
          (c >= 'A' && c <= 'Z') ||
          (c >= '0' && c <= '9') ||
          c === '_' ||
          c === '$'
        )
    );
}

export function encodeIdentifiersInExpr(expr: string): string {
  /**
   * Takes any variable inside ${} and 32 bit encodes the name
   */
  let varEncodedExprString = expr;

  const matches = expr.match(/\${([^}])+}/g);
  if (matches) {
    matches.forEach(match => {
      const identifier = match.replace(/^\${\s*/, '').replace(/\s*}$/, '');
      const encodedIdentifier =
        ENCODED_IDENTIFIER_PREFIX +
        base32Encode(Buffer.from(identifier), 'RFC4648', {
          padding: false,
        });
      varEncodedExprString = varEncodedExprString.replace(
        match,
        encodedIdentifier + ' ' // need to add a space for parsing
      );
    });
  }

  return varEncodedExprString;
}

function isIdentifierBase32Encoded(identifier: string) {
  return identifier.startsWith(ENCODED_IDENTIFIER_PREFIX);
}

export function decodeIdentifiersInExpression(expr: Expression) {
  switch (expr.type) {
    case 'BinaryExpression' || 'LogicalExpression':
      decodeIdentifiersInExpression(expr.left);
      decodeIdentifiersInExpression(expr.right);
      return;
    case 'ConditionalExpression':
      decodeIdentifiersInExpression(expr.alternate);
      decodeIdentifiersInExpression(expr.consequent);
      decodeIdentifiersInExpression(expr.test);
      return;
    case 'CallExpression':
      expr.arguments.map(e => decodeIdentifiersInExpression(e));
      decodeIdentifiersInExpression(expr.callee);
      return;
    case 'Identifier':
      expr.name = decodeIdentifier(expr.name).toString();
      return;
    case 'Literal':
      return;
    case 'UnaryExpression':
      decodeIdentifiersInExpression(expr.argument);
      return;
    default:
      return;
  }
}

function decodeIdentifier(identifier: string) {
  if (isIdentifierBase32Encoded(identifier)) {
    const encodedName = identifier.replace(ENCODED_IDENTIFIER_PREFIX, '');
    return Buffer.from(base32Decode(encodedName)).toString();
  } else {
    return identifier;
  }
}
export function escapeIdentifier(identifier: string) {
  return identifierContainsIllegalChars(identifier)
    ? '${' + identifier + '}'
    : identifier;
}

export function parseFancyExpression(rawExpr: string) {
  const encExpr = encodeIdentifiersInExpr(rawExpr);
  const expr = parseExpression(encExpr);
  if (expr != null) {
    decodeIdentifiersInExpression(expr);
  }
  return expr;
}

export function metricsInExpression(expression: Expression) {
  const identifiers = identifiersInExpression(expression, {ignoreCallee: true});

  return identifiers.filter(
    e => !e.startsWith('config:') && !e.startsWith('summary:')
  );
}

export function summaryKeysInExpression(expression: Expression) {
  const identifiers = identifiersInExpression(expression, {ignoreCallee: true});

  const keys = identifiers
    .filter(e => e.startsWith('summary:'))
    .map(e => Run.keyFromString(e))
    .filter(k => k != null) as Run.Key[];

  return keys;
}

export function configKeysInExpression(expression: Expression) {
  const identifiers = identifiersInExpression(expression, {ignoreCallee: true});

  const keys = identifiers
    .filter(e => e.startsWith('config:'))
    .map(e => Run.keyFromString(e))
    .filter(k => k != null) as Run.Key[];

  return keys;
}
