import styled from 'styled-components';

export const ModalHeaderWrapper = styled.div`
  font-weight: 600;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5em 2em;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  justify-content: flex-end;
`;

export const InUseMessageWrapper = styled.span`
  padding-top: 8px;
`;
