import React, {useState, useCallback} from 'react';
import {Button, Header, Message, Modal} from 'semantic-ui-react';
import {Link, useHistory} from 'react-router-dom';
import {
  ClaimAnonymousEntityComponent,
  UserAccountType,
} from '../generated/graphql';
import {Subset} from '../types/base';
import {User} from '../types/graphql';
import {entity, login, run as runURL} from '../util/urls';
import {AnonymousEntityClaim, ClaimTask} from './AnonymousEntityClaimer';
import makeComp from '../util/profiler';

interface AnonymousClaimBannerProps {
  entityName: string;
  projectName: string;
  run: string;
  user: Subset<User, 'id' | 'username' | 'apiKey' | 'accountType'> | null;
  viewer: User | null;
}

const AnonymousClaimBanner: React.FC<AnonymousClaimBannerProps> = makeComp(
  ({entityName, projectName, run, user, viewer}) => {
    const [claimModalOpen, setClaimModalOpen] = useState(false);
    const [claim, setClaim] = useState<ClaimTask | undefined>(undefined);
    const history = useHistory();

    const openClaimModal = useCallback(() => {
      setClaimModalOpen(true);
      window.location.hash = '#claim';
    }, []);

    const closeClaimModal = useCallback(() => {
      setClaimModalOpen(false);
      window.location.hash = '';
    }, []);

    if (user === null) {
      return <div />;
    }

    const {username, apiKey, accountType} = user;

    // Users are only claimable if they are anonymous and the current viewer
    // has access to their API key, which means the user is supplying the
    // anonymous user's API key in the request header.
    if (accountType !== UserAccountType.Anonymous || apiKey === undefined) {
      return <div />;
    }

    // The user is claimable, but we're not logged in. Prompt the user to log in.
    if (!viewer) {
      return (
        <Message id={apiKey} color="orange" attached>
          <Message.Header>Unclaimed anonymous runs</Message.Header>
          <p>
            The anonymous user <Link to={entity(username)}>{username}</Link> has
            not been claimed. <Link to={login()}>Log in or sign up</Link> to
            claim this user's runs.
          </p>
        </Message>
      );
    }

    const claimInProgress = claim !== undefined;

    // User is claimable and we're logged in. In addition to the banner,
    // also pop up a modal that guides the user through the claim workflow.
    return (
      <ClaimAnonymousEntityComponent>
        {claimMutation => (
          <React.Fragment>
            <Message id={apiKey} color="orange" attached>
              <Message.Header>Unclaimed anonymous runs</Message.Header>
              <p>
                The anonymous user <Link to={entity(username)}>{username}</Link>{' '}
                has not been claimed.{' '}
                <a href="#claim" onClick={openClaimModal}>
                  Click here
                </a>{' '}
                to claim this user's runs.
              </p>
            </Message>
            <Modal open={claimModalOpen}>
              <Header>
                Would you like to claim anonymous user{' '}
                <Link to={entity(entityName)}>{entityName}</Link>?
              </Header>
              <Modal.Content>
                <p>
                  All of its projects and runs will be transferred to your
                  account.
                </p>
                {claimInProgress && (
                  <AnonymousEntityClaim
                    task={claim!}
                    onFinished={() => {
                      closeClaimModal();

                      // Once the claim is complete, the run has been moved
                      // into the viewer's entity. Redirect to that run.
                      history.push(
                        runURL({
                          entityName: viewer.userEntity.name,
                          projectName,
                          name: run,
                        })
                      );
                    }}
                    onError={() => {
                      setClaimModalOpen(false);
                    }}
                  />
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  visible={!claimInProgress}
                  negative
                  onClick={closeClaimModal}>
                  Leave unclaimed
                </Button>
                <Button
                  positive
                  onClick={() => {
                    claimMutation({
                      variables: {
                        anonymousApiKey: apiKey,
                      },
                    }).then(res => {
                      if (!res || !res.data || !res.data.claimAnonymousEntity) {
                        return;
                      }

                      setClaim(res.data.claimAnonymousEntity.task);
                    });
                  }}
                  disabled={claimInProgress}
                  loading={claimInProgress}>
                  Claim
                </Button>
              </Modal.Actions>
            </Modal>
          </React.Fragment>
        )}
      </ClaimAnonymousEntityComponent>
    );
  },
  {id: 'AnonymousClaimBanner', memo: true}
);

export default AnonymousClaimBanner;
