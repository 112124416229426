import * as S from './ActiveRunIndicator.styles';
import React from 'react';

export interface ActiveRunIndicatorProps {
  className?: string;
}

const ActiveRunIndicator: React.FC<ActiveRunIndicatorProps> = ({className}) => {
  return (
    <S.Wrapper className={className}>
      <S.Dot></S.Dot>
    </S.Wrapper>
  );
};

export default ActiveRunIndicator;
