import * as React from 'react';
import {labelComponent} from '../util/media';
import makeComp from '../util/profiler';
import {runLink} from '../util/runhelpers';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import HtmlViewer from './Panel2/HtmlViewer';

const HtmlCard = makeComp(
  (props: MediaCardProps) => {
    const {
      run,
      globalStep,
      mediaKey,
      mediaIndex,
      runSignature,
      height,
      tileMedia,
    } = props;

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    return (
      <HtmlViewer
        headerElements={labelComponent(props, tileMedia?.step, titleLink)}
        notFoundElements={[
          <MessageMediaNotFound
            mediaIndex={mediaIndex}
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaType="html"
          />,
        ]}
        htmlNotFound={tileMedia == null}
        height={height}
        src={tileMedia?.objectURL}
      />
    );
  },
  {id: 'HtmlCard', memo: true}
);

export default HtmlCard;
