import _ from 'lodash';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {labelComponent, makeCaptions} from '../util/media';
import makeComp from '../util/profiler';
import {useDownloadFile} from '../util/requests';
import {runLink} from '../util/runhelpers';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import VideoViewer from './Panel2/VideoViewer';

type VideoCardProps = MediaCardProps;

// a single image card
const VideoCard = makeComp(
  (props: VideoCardProps) => {
    const {run, mediaKey, mediaIndex, runSignature, globalStep, tileMedia} =
      props;
    const objectURL = tileMedia?.objectURL ?? '';
    const filename = tileMedia?.filePath
      ? _.last(tileMedia.filePath.split('/'))
      : undefined;

    const [refreshTimestamp, setRefreshTimestamp] = useState<number>(
      Date.now()
    );

    const downloadFile = useDownloadFile();

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    // get captions for all images in the group
    const captions = makeCaptions(tileMedia?.historyRow[mediaKey], mediaIndex);

    useEffect(() => {
      setRefreshTimestamp(Date.now());
    }, [objectURL]);

    return (
      <VideoViewer
        videoFilename={filename}
        videoSrc={objectURL}
        width={props.width}
        height={props.height}
        headerElement={labelComponent(props, tileMedia?.step, titleLink)}
        footerElement={
          <>
            {captions.length > 0 && (
              <div className="image-card-caption">{captions}</div>
            )}
            {tileMedia?.filePath && (
              <LegacyWBIcon
                className="content-card__download"
                onClick={() => {
                  downloadFile(runSignature, tileMedia.filePath);
                }}
                name="download"
              />
            )}
          </>
        }
        failedLoadElement={
          <MessageMediaNotFound
            mediaIndex={mediaIndex}
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaType="videos"
          />
        }
        refreshTimestamp={refreshTimestamp}
        mediaFailedToLoad={tileMedia == null}
        single={props.single}
      />
    );
  },
  {id: 'VideoCard', memo: true}
);

export default VideoCard;
