import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';

import {useQuery} from './query';

///// Query definition

export const SINGLE_FILE_QUERY = gql`
  query SingleFile(
    $projectName: String!
    $entityName: String
    $runName: String!
    $fileName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      run(name: $runName) {
        id
        files(first: 1, names: [$fileName]) {
          edges {
            node {
              id
              name
              directUrl
              sizeBytes
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export interface Files {
  edges: Array<{
    node: {
      id: string;
      name: string;
      directUrl: string;
      sizeBytes: number;
      updatedAt: string;
    };
  }>;
}

// The query's output shape.
interface Data {
  project: {
    run: {
      files: Files;
    };
  };
}

export function useSingleFileQuery(
  variables: Generated.SingleFileQueryVariables
) {
  const query = useQuery<Data, typeof variables>(SINGLE_FILE_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });
  if (query.initialLoading) {
    return {loading: true as true};
  }
  const fileEdges = query.project.run.files.edges;
  return {
    loading: false as false,
    file: fileEdges.length === 0 ? undefined : fileEdges[0].node,
  };
}
