import React from 'react';
import SliderInput from '../elements/SliderInput';
import {PropertyEditorComponent} from './property-editors';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

// TODO: add mixed state
const SliderPropertyEditor: PropertyEditorComponent<'slider'> = makeComp(
  props => {
    const displayedVal =
      props.values.length === 1 ? props.values[0] : undefined;
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        <SliderInput
          min={props.min}
          max={props.max}
          step={props.step}
          value={displayedVal || 0}
          onChange={val => {
            props.save(val);
          }}></SliderInput>
      </InspectorPropertyWrapper>
    );
  },
  {id: 'SliderPropertyEditor'}
);

export default SliderPropertyEditor;
