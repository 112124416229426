import * as S from './FloatingReportDiscussion.styles';

import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';
import {Popup} from 'semantic-ui-react';
import {useSelector, usePropsSelector} from '../state/hooks';
import {usePart} from '../state/views/hooks';
import {
  ReportPageNormalized,
  ReportWidthOption,
} from '../state/views/report/types';
import {ReportViewRef} from '../state/reports/types';
import {navigateTo} from '../util/history';
import {useFeaturedReportsAuthorsQuery} from '../generated/graphql';
import {ReportAuthor} from '../util/section';
import ShareReportTrigger from './ShareReportTrigger';
import * as ViewSelectors from '../state/views/selectors';
import * as ViewHooks from '../state/views/hooks';
import * as ViewThunks from '../state/views/thunks';
import {useViewer} from '../state/viewer/hooks';
import * as ReportViewsSelectors from '../state/reports/selectors';
import {auth} from '../setup';
import {scrollToElement} from '../util/document';
import * as ReportSelectors from '../state/reports/selectors';
import * as Urls from '../util/urls';

const MIN_REPORT_DEPTH = 150;
interface FloatingReportDiscussionProps {
  viewRef: ReportViewRef;
  isFeatured?: boolean;
}

const FloatingReportDiscussion: React.FC<FloatingReportDiscussionProps> = ({
  viewRef,
  isFeatured,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [pastScrollThreshold, setPastScrollThreshold] = useState(false);
  const [outOfBounds, setOutOfBounds] = useState(false);
  const [narrow, setNarrow] = useState(false);

  const viewer = useViewer();

  useLayoutEffect(() => {
    const scrollHandler = _.throttle(() => {
      setPastScrollThreshold(window.scrollY >= MIN_REPORT_DEPTH);
    }, 50);
    const resizeHandler = _.throttle(() => {
      if (containerRef.current == null) {
        return;
      }
      const {right} = containerRef.current.getBoundingClientRect();
      if (right > S.WIDTH) {
        setNarrow(false);
        setOutOfBounds(false);
      } else if (right > S.NARROW_WIDTH) {
        setNarrow(true);
        setOutOfBounds(false);
      } else {
        setNarrow(true);
        setOutOfBounds(true);
      }
    }, 50);
    scrollHandler();
    resizeHandler();
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const goToAuthorProfile = useCallback(
    (a: ReportAuthor) => navigateTo({pathname: `/${a.username}`}),
    []
  );

  const returnToGallery = useCallback(
    () => navigateTo({pathname: Urls.reportGallery()}),
    []
  );

  const view = useSelector(state => state.views.views[viewRef.id]);
  const reportID = view.id;
  if (reportID == null || view.partRef == null) {
    throw new Error('invalid state');
  }
  const viewPart = usePart(view.partRef) as ReportPageNormalized;
  const viewAuthors = viewPart.authors;
  let authors;
  if (viewAuthors != null && viewAuthors.length > 0) {
    authors = viewAuthors;
  } else {
    authors = [_.pick(view.user, 'username', 'name')];
  }

  const {data: authorsData} = useFeaturedReportsAuthorsQuery({
    variables: {usernames: authors.map(a => a.username)},
    skip: authors.length === 0,
  });
  const photoByUsername: Map<string, string> = useMemo(() => {
    const map = new Map();
    for (const e of authorsData?.users?.edges ?? []) {
      if (!e.node?.username || !e.node?.photoUrl) {
        continue;
      }
      map.set(e.node.username, e.node.photoUrl);
    }
    return map;
  }, [authorsData]);

  const starCount = usePropsSelector(
    ViewSelectors.makeStarCountSelector,
    viewRef
  );
  const starred = Boolean(
    usePropsSelector(ViewSelectors.makeStarredSelector, viewRef)
  );
  const star = ViewHooks.useViewRefThunk(viewRef, ViewThunks.starView);
  const unstar = ViewHooks.useViewRefThunk(viewRef, ViewThunks.unstarView);
  const toggleStar = useCallback(() => {
    if (viewer == null) {
      auth.login();
      return;
    }
    starred ? unstar() : star();
  }, [viewer, starred, star, unstar]);

  const commentsCount = useSelector(
    ReportViewsSelectors.getReportDiscussionCommentsCount(viewRef)
  );

  const reportWidth: ReportWidthOption = useSelector(
    ReportSelectors.getReportWidth(viewRef)
  );

  if (reportWidth !== 'readable') {
    return null;
  }

  const commentText =
    commentsCount > 0 ? `${commentsCount} comments` : `Add a comment`;
  const commentRow = (
    <S.Row light onClick={() => scrollToElement('.report-discussion')}>
      <S.Icon name="chat" />
      {!narrow && commentText}
    </S.Row>
  );
  const shareText = 'Share';
  const shareRow = (
    <S.Row light>
      <S.Icon name="share" />
      {!narrow && shareText}
    </S.Row>
  );
  const heartText = `${starCount} hearts`;
  const heartRow = (
    <S.Row light active={starred} onClick={toggleStar}>
      <S.Icon name={starred ? 'heart' : 'heart-outline'} />
      {!narrow && heartText}
    </S.Row>
  );
  const backText = 'Back to Fully Connected';
  const backRow = (
    <S.Row backLink onClick={returnToGallery}>
      <S.Icon name="previous" />
      {!narrow && backText}
    </S.Row>
  );

  return (
    <S.FloatingContainer
      active={!outOfBounds && pastScrollThreshold}
      narrow={narrow}
      ref={containerRef}>
      <S.Header>
        {authors.map(a =>
          !narrow ? (
            <S.Row key={a.username} onClick={() => goToAuthorProfile(a)}>
              <S.Image src={photoByUsername.get(a.username) ?? '/logo.png'} />
              {a.name}
            </S.Row>
          ) : (
            <Popup
              key={a.username}
              inverted
              position="right center"
              content={a.name}
              size="mini"
              on="hover"
              trigger={
                <S.Row key={a.username} onClick={() => goToAuthorProfile(a)}>
                  <S.Image
                    src={photoByUsername.get(a.username) ?? '/logo.png'}
                  />
                </S.Row>
              }
            />
          )
        )}
      </S.Header>
      {!narrow ? commentRow : withPopup(commentRow, commentText)}
      {view.project != null && (
        <ShareReportTrigger viewRef={viewRef}>
          {!narrow ? shareRow : withPopup(shareRow, shareText)}
        </ShareReportTrigger>
      )}
      {!narrow ? heartRow : withPopup(heartRow, heartText)}
      {isFeatured && (!narrow ? backRow : withPopup(backRow, backText))}
    </S.FloatingContainer>
  );
};

export default FloatingReportDiscussion;

function withPopup(trigger: JSX.Element, content: string) {
  return (
    <Popup
      inverted
      position="right center"
      content={content}
      size="mini"
      on="hover"
      trigger={trigger}
    />
  );
}
