import * as globals from '../css/globals.styles';

import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {
  Icon,
  Modal,
  Input,
  Button,
  Progress,
  Message,
  Accordion,
  AccordionPanelProps,
  InputOnChangeData,
} from 'semantic-ui-react';
import {ApolloQueryResult} from 'apollo-client';
import {ExecutionResult} from '@apollo/react-common';
import {StorageAction, LeafMap, Node} from '../pages/StorageExplorer';
import numeral from 'numeral';
import {
  useDeleteModelMutation,
  useDeleteRunMutation,
  useDeleteFilesMutation,
  useDeleteArtifactMutation,
  useDeleteArtifactSequenceMutation,
  DeleteFilesMutation,
  DeleteRunMutation,
  DeleteModelMutation,
  DeleteArtifactMutation,
  DeleteArtifactSequenceMutation,
} from '../generated/graphql';
import makeComp from '../util/profiler';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
  doNotRetryContext,
} from '../util/errors';
import {WBIcon} from '@wandb/ui';
import produce from 'immer';

interface StorageConfirmDeleteProps {
  refetch: () => Promise<ApolloQueryResult<any>>;
  entityName: string;
  selectedIds: Set<string>;
  dispatch: React.Dispatch<StorageAction>;
  rootTree: Node;
  leafMap: LeafMap;
  summary: string;
}

interface TypeMap {
  [key: string]: string[];
}

interface Error {
  message: string;
}

interface DeleteProgress {
  total: number;
  finished: number;
  failed: number;
  errors: Error[]; // TODO: types
  phase:
    | 'confirm'
    | 'complete'
    | 'failed'
    | 'files'
    | 'projects'
    | 'runs'
    | 'artifacts'
    | 'artifact_versions';
}

type Mutations =
  | DeleteFilesMutation
  | DeleteRunMutation
  | DeleteModelMutation
  | DeleteArtifactMutation
  | DeleteArtifactSequenceMutation;
type MutationHook = (options?: {
  variables: any;
}) => Promise<ExecutionResult<Mutations>>;

async function mutationWithError(
  mutation: MutationHook,
  variables: any,
  origProgress: DeleteProgress,
  phase: DeleteProgress['phase']
): Promise<DeleteProgress> {
  // DeleteFilesMutation accepts multiple ids, the rest don't
  return produce(origProgress, async progress => {
    progress.phase = phase;
    const total = variables.ids == null ? 1 : variables.ids.length;
    try {
      const res = await mutation({variables});
      if (res != null && res.errors) {
        progress.errors = progress.errors.concat(res.errors);
        progress.failed += total;
      } else if (res != null) {
        progress.finished += total;
      }
    } catch (e) {
      console.error('Mutation error', e);
      progress.errors.push({
        message: extractErrorMessageFromApolloError(e) || e.toString(),
      });
      progress.failed += total;
    }
  });
}

function initialProgress(): DeleteProgress {
  return {
    total: 0,
    finished: 0,
    failed: 0,
    errors: [],
    phase: 'confirm',
  };
}

const StorageExplorerConfirm: React.FC<StorageConfirmDeleteProps> = makeComp(
  ({refetch, summary, selectedIds, rootTree, leafMap}) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [deleteProgress, setDeleteProgress] = useState<DeleteProgress>(
      initialProgress()
    );
    // We don't want to retry deletes and ensure we handle the errors in this component
    const deleteContext = {
      context: {...doNotRetryContext(), ...propagateErrorsContext()},
    };
    const [deleteFiles, deleteFilesStatus] =
      useDeleteFilesMutation(deleteContext);
    const [deleteProject, deleteProjectStatus] =
      useDeleteModelMutation(deleteContext);
    const [deleteRun, deleteRunStatus] = useDeleteRunMutation(deleteContext);
    const [deleteArtifactVersion, deleteArtifactVersionStatus] =
      useDeleteArtifactMutation(deleteContext);
    const [deleteArtifact, deleteArtifactStatus] =
      useDeleteArtifactSequenceMutation(deleteContext);
    const onConfirmChange = useCallback(
      (_, data: InputOnChangeData) => {
        setConfirmationText(data.value);
      },
      [setConfirmationText]
    );
    const closeTimeout = useRef<ReturnType<typeof setTimeout>>();
    const deleteButtonEnabled = useMemo(
      () => confirmationText === 'DELETE ALL' && deleteProgress.total === 0,
      [confirmationText, deleteProgress]
    );

    // Reload storage stats on success or failure
    useEffect(() => {
      if (confirmOpen === false && deleteProgress.total > 0) {
        if (closeTimeout.current != null) {
          clearTimeout(closeTimeout.current);
        }
        setDeleteProgress(initialProgress());
        refetch();
      }
    }, [confirmOpen, deleteProgress, refetch]);

    const onConfirm = useCallback(async () => {
      let progress = initialProgress();
      const idMap = Array.from(selectedIds.values()).reduce((typeMap, id) => {
        const type = leafMap[id].type;
        if (typeMap[type] == null) {
          typeMap[type] = [];
        }
        progress.total += 1;
        typeMap[type].push(id);
        return typeMap;
      }, {} as TypeMap);
      setDeleteProgress(progress);

      if (idMap.file) {
        const chunkSize = 100;
        // Delete 100 files at a time
        for (let i = 0, j = idMap.file.length; i < j; i += chunkSize) {
          progress = await mutationWithError(
            deleteFiles,
            {ids: idMap.file.slice(i, i + chunkSize)},
            progress,
            'files'
          );
          setDeleteProgress(progress);
        }
      }
      if (idMap.project) {
        for (const id of idMap.project) {
          progress = await mutationWithError(
            deleteProject,
            {id},
            progress,
            'projects'
          );
          setDeleteProgress(progress);
        }
      }
      if (idMap.artifact) {
        for (const id of idMap.artifact) {
          progress = await mutationWithError(
            deleteArtifact,
            {artifactSequenceID: id},
            progress,
            'artifacts'
          );
          setDeleteProgress(progress);
        }
      }
      if (idMap.artifact_version) {
        for (const id of idMap.artifact_version) {
          progress = await mutationWithError(
            deleteArtifactVersion,
            {artifactID: id},
            progress,
            'artifact_versions'
          );
          setDeleteProgress(progress);
        }
      }
      if (idMap.run) {
        // TODO: useDeleteRuns with filters someday
        for (const id of idMap.run) {
          progress = await mutationWithError(deleteRun, {id}, progress, 'runs');
          setDeleteProgress(progress);
        }
      }
      setDeleteProgress(
        produce(progress, newProgress => {
          newProgress.phase =
            newProgress.errors.length === 0 ? 'complete' : 'failed';
        })
      );
      if (progress.total === progress.finished) {
        closeTimeout.current = setTimeout(() => {
          setConfirmOpen(false);
        }, 5000);
      }
    }, [
      selectedIds,
      leafMap,
      setConfirmOpen,
      deleteProject,
      deleteRun,
      deleteFiles,
      deleteArtifact,
      deleteArtifactVersion,
    ]);

    const selectedTree = useMemo(() => {
      function defaultNode(name: string): Node {
        return {
          name,
          files: [],
          subdirectories: {},
          size: 0,
        };
      }
      const results: Node = defaultNode('root');
      if (!confirmOpen) {
        return results;
      }
      function traverse(dirs: {[key: string]: Node}, parent: Node, root: Node) {
        Object.keys(dirs).forEach(key => {
          if (selectedIds.has(dirs[key].id!)) {
            root.subdirectories[key] = dirs[key];
            root.size! += dirs[key].size!;
            parent.size! += dirs[key].size!;
          }
          if (Object.keys(dirs[key].subdirectories).length > 0) {
            if (root.subdirectories[key] == null) {
              root.subdirectories[key] = defaultNode(key);
              root.subdirectories[key].id = dirs[key].id;
            }
            traverse(dirs[key].subdirectories, root, root.subdirectories[key]);
          }
        });
        return root;
      }
      function prune(root: Node) {
        // Remove all empty dirs
        Object.keys(root.subdirectories).forEach(dir => {
          if (root.subdirectories[dir].size === 0) {
            delete root.subdirectories[dir];
          } else {
            prune(root.subdirectories[dir]);
          }
        });
        return root;
      }
      function construct(
        projo: Node,
        fileName: string,
        bytes: number,
        type: string
      ) {
        // Build up files / artifacts
        if (projo.subdirectories[type] == null) {
          projo.subdirectories[type] = defaultNode(type);
        }
        projo.size! += bytes;
        projo.subdirectories[type].size! += bytes;
        projo.subdirectories[type].subdirectories[fileName] =
          defaultNode(fileName);
        projo.subdirectories[type].subdirectories[fileName].size! += bytes;
      }
      const traversed = traverse(rootTree.subdirectories, results, results);
      // Remove existing artifacts nodes
      Object.keys(traversed.subdirectories).forEach(
        p => delete traversed.subdirectories[p].subdirectories.artifacts
      );
      // Add individual files / artifacts / versions to summary
      Object.keys(leafMap).forEach(k => {
        const leaf = leafMap[k];
        const projo = traversed.subdirectories[leaf.path[0]];
        if (leaf.path.length > 3) {
          const fileName = leaf.path.slice(3).join('/');
          if (leaf.type === 'file') {
            construct(projo, fileName, leaf.bytes, 'files');
          } else if (leaf.type === 'artifact_version') {
            construct(projo, fileName, leaf.bytes, 'artifact versions');
          } else if (leaf.type === 'artifact') {
            construct(projo, fileName, leaf.bytes, 'artifacts');
          }
        }
      });
      return prune(traversed);
    }, [rootTree, selectedIds, leafMap, confirmOpen]);

    const traverseTree = useCallback(
      (
        dirs: {[key: string]: Node},
        panels: AccordionPanelProps[],
        level: number
      ) => {
        // TODO: Handle files?
        if (Object.keys(dirs).length === 0) {
          return panels;
        }
        Object.keys(dirs).forEach(key => {
          const subPanels = traverseTree(
            dirs[key].subdirectories,
            [],
            level + 1
          );
          let title = `${key} (${numeral(dirs[key].size).format('0.0b')})`;
          if (level === 1) {
            title = `${subPanels.length} ` + title;
          }
          if (subPanels.length > 0) {
            panels.push({
              key: `panel-${level}-${key}`,
              title,
              content: {
                content:
                  level >= 1 ? (
                    <ul
                      style={{
                        margin: '-15px 0 -15px 80px',
                        color: globals.gray500,
                        listStyle: 'none',
                      }}>
                      {subPanels.map(s => (
                        <li key={s.key}>{s.title}</li>
                      ))}
                    </ul>
                  ) : (
                    <Accordion
                      style={{padding: '8px 0', margin: 0}}
                      panels={subPanels}
                    />
                  ),
              },
            });
          } else {
            panels.push({
              key: `panel-${level}-${key}`,
              title,
            });
          }
        });
        return panels;
      },
      []
    );

    const rootPanels = useMemo(() => {
      return traverseTree(selectedTree.subdirectories, [], 0);
    }, [selectedTree, traverseTree]);

    const mutationLoading =
      deleteFilesStatus.loading ||
      deleteRunStatus.loading ||
      deleteProjectStatus.loading ||
      deleteArtifactStatus.loading ||
      deleteArtifactVersionStatus.loading;

    let content = (
      <>
        <p>
          You are about to delete {summary}. You can browse the projects below
          to review your selections. Type <b>DELETE ALL</b> in the dialogue to
          confirm.
        </p>
        <Accordion style={{padding: '8px 0'}} panels={rootPanels} />
      </>
    );
    if (deleteProgress.total > 0) {
      const percentComplete =
        (deleteProgress.finished / deleteProgress.total) * 100;
      content = (
        <div>
          {percentComplete === 100 ? (
            <Message positive>
              <Message.Header>Deletion complete</Message.Header>
              <p>
                Successfully deleted {summary}. Usage metrics may take up to 5
                minutes to update in the UI.
              </p>
            </Message>
          ) : (
            <Progress
              percent={percentComplete}
              success={deleteProgress.failed === 0}
              error={deleteProgress.failed !== 0}>
              Deleting {summary}...
            </Progress>
          )}
          {deleteProgress.errors.length > 0 && (
            <Message negative>
              <Message.Header>
                Errors were encountered while deleting objects:
              </Message.Header>
              <ul>
                {deleteProgress.errors.map((e, i) => (
                  <li key={`error_${i}`}>{e.message}</li>
                ))}
              </ul>
            </Message>
          )}
        </div>
      );
    }

    // TODO: get rid of the bottom borders
    return (
      <>
        <Button
          color="red"
          disabled={selectedIds.size === 0}
          onClick={() => setConfirmOpen(true)}
          style={{marginTop: -8}}
          floated="right">
          Delete
        </Button>
        <Modal
          size="small"
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}>
          <Modal.Header>
            <Icon name="exclamation triangle" />
            Danger this action cannot be undone.
          </Modal.Header>
          <Modal.Content scrolling>{content}</Modal.Content>
          <Modal.Actions>
            <Button
              className="wb-icon-button"
              size="tiny"
              onClick={() => setConfirmOpen(false)}>
              <WBIcon name="close" />
              Cancel
            </Button>
            <Button
              color="red"
              size="tiny"
              className="wb-icon-button"
              loading={mutationLoading}
              disabled={
                !deleteButtonEnabled || deleteProgress.errors.length > 0
              }
              onClick={onConfirm}>
              Delete All
            </Button>
            <Input
              style={{width: '140px'}}
              placeholder="DELETE ALL"
              onChange={onConfirmChange}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  },
  {id: 'StoragePercentage', memo: true}
);

export default StorageExplorerConfirm;
