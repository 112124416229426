import * as Sentry from '@sentry/browser';
import {getAllCookies} from './cookie';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export const Analytics = window.analytics;

export const AnalyticsEvents = {
  MULTI_RUN_WORKSPACE: 'Workspace viewed (multi-run)',
  SINGLE_RUN_WORKSPACE: 'Workspace viewed (single-run)',
};

interface FullStoryInterface {
  getCurrentSessionURL: (addTime?: boolean) => string;
  event: (name: string, object: any) => void;
}

// Expect these items to be in the window object.
declare global {
  interface Window {
    FS: FullStoryInterface;
  }
}

declare global {
  interface Window {
    // Todo: Figure out how to hook up types from @types/prismjs

    Prism: any;
  }
}

export const Prism = window.Prism;

export function getFullStoryUrl(): string | undefined {
  return (
    window.FS &&
    window.FS.getCurrentSessionURL &&
    window.FS.getCurrentSessionURL(true)
  );
}

interface ErrorParams {
  tags?: {[key: string]: string};
  extra?: {[key: string]: any};
  level?: Sentry.Severity;
  fingerprint?: string[];
}

// You must pass callsite, a string indicating what part of our code
// base captureError was called from.
export function captureError(
  err: Error | string,
  callsite: string,
  errorParams: ErrorParams = {}
) {
  const extra = {
    ...(errorParams.extra || {}),
    callsite,
    state: getStateDump(),
  };
  Sentry.withScope(scope => {
    scope.setTag('callsite', callsite);
    scope.setExtras(extra);
    if (errorParams.level) {
      scope.setLevel(errorParams.level);
    }
    Object.entries(errorParams.tags || {}).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
    if (errorParams.fingerprint != null) {
      scope.setFingerprint(errorParams.fingerprint);
    }
    if (err instanceof Error) {
      Sentry.captureException(err);
    } else {
      Sentry.captureMessage(err);
    }
  });
}

export const getStateDump = () => ({
  localStorage: dumpStorageData(localStorage),
  sessionStorage: dumpStorageData(sessionStorage),
  cookies: dumpCookieData(),
});

const dumpStorageData = (s: Storage) => {
  const data: {[key: string]: any} = {};
  for (let i = 0; i < s.length; i++) {
    const key = s.key(i)!;
    const val = s.getItem(key);
    try {
      data[key] = JSON.parse(val!);
    } catch {
      data[key] = val;
    }
  }
  return data;
};

const dumpCookieData = () => {
  const data: {[key: string]: any} = {};
  const cookies = getAllCookies();
  for (const [key, val] of Object.entries(cookies)) {
    try {
      data[key] = JSON.parse(val as string);
    } catch {
      data[key] = val;
    }
  }
  return data;
};

// Reload on error on dashboard pages
export function shouldReloadOnError(): boolean {
  return window.location.pathname.indexOf('/dashboards') > -1;
}
