import styled from 'styled-components';
import {GLOBAL_COLORS} from '../../util/colors';

export const Wrapper = styled.div`
  .ui.input {
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const HelperText = styled.p`
  margin-bottom: 16px;
  code {
    padding: 0 2px;
    background: #f6f6f6;
    border-radius: 4px;
    border: 1px solid ${GLOBAL_COLORS.outline.toString()};
    font-family: Inconsolata;
  }
`;
