import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {BenchmarkProject} from '../../types/graphql';

import {useQuery} from './query';
import {RunQueue} from '../../generated/graphql';

export const MULTI_RUN_WORKSPACE_QUERY = gql`
  query MultiRunWorkspace($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      totalRunsWithGroup
      totalRunsWithJobType
      readOnly
      isBenchmark
      linkedBenchmark {
        id
        entityName
        name
        gitHubSubmissionRepo
        views
      }
      runQueues {
        id
        name
        createdBy
        access
      }
    }
  }
`;

export interface MultiRunWorkspaceQueryData {
  project: {
    id: string;
    totalRunsWithGroup: number;
    totalRunsWithJobType: number;
    readOnly: boolean;
    isBenchmark: boolean;
    linkedBenchmark: BenchmarkProject;
    runQueues: RunQueue[];
  };
}
export function useMultiRunWorkspaceQuery(
  variables: Generated.MultiRunWorkspaceQueryVariables
) {
  return useQuery<MultiRunWorkspaceQueryData, typeof variables>(
    MULTI_RUN_WORKSPACE_QUERY,
    {variables}
  );
}
