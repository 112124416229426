import React, {FunctionComponent, useState, useCallback, useMemo} from 'react';
import {Button, Modal, Input, Icon, InputOnChangeData} from 'semantic-ui-react';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

export interface DeleteProjectModalProps {
  open: boolean;
  projectName: string;
  onClose(): void;
  deleteProject(): void;
}

export const DeleteProjectModal: FunctionComponent<DeleteProjectModalProps> =
  makeComp(
    props => {
      const [deleteProjectConfirmation, setDeleteProjectConfirmation] =
        useState('');
      const onDeleteProjectConfirmationChange = useCallback(
        (_, data: InputOnChangeData) => {
          setDeleteProjectConfirmation(data.value);
        },
        [setDeleteProjectConfirmation]
      );

      const deleteButtonEnabled = useMemo(
        () => deleteProjectConfirmation === props.projectName,
        [deleteProjectConfirmation, props.projectName]
      );
      return (
        <Modal
          size="small"
          open={props.open}
          onClose={props.onClose}
          onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
          <Modal.Header>
            <Icon name="exclamation triangle" />
            Danger
          </Modal.Header>
          <Modal.Content>
            <p>
              You are about to <b>DELETE</b> this project. This will include all
              runs, files, and metadata within this project.
            </p>
            <p>
              <b>This action cannot be undone.</b>
            </p>
            <p>To proceed, please type the name of the project below.</p>
            <Input
              style={{width: '240px'}}
              placeholder={props.projectName}
              onChange={onDeleteProjectConfirmationChange}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="wb-icon-button"
              size="tiny"
              onClick={props.onClose}>
              <LegacyWBIcon name="close" />
              Cancel
            </Button>
            <Button
              color="red"
              size="tiny"
              className="wb-icon-button"
              onClick={props.deleteProject}
              disabled={!deleteButtonEnabled}>
              <LegacyWBIcon name="check" style={{color: 'white'}} /> Delete this
              project
            </Button>
          </Modal.Actions>
        </Modal>
      );
    },
    {id: 'DeleteProjectModal'}
  );
