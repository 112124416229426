import '../css/PanelBank.less';

import React, {useEffect, useMemo, useRef} from 'react';
import _ from 'lodash';

import DragDropContext, {
  DragDropProvider,
  DragDropState,
  DropTarget,
} from '../containers/DragDrop';
import {
  EMPTY_PANEL_BANK_SETTINGS,
  isPanelBankSection,
  isPanel,
  keyInfoToDefaultPanelSpecs,
  keyInfoToAddedPanels,
  panelBankConfigToFoundPanelSpecs,
  expectedAPIAddedPanelSpecsFromPanelBankConfigs,
  getPanelBankDiff,
  panelIsActive,
  searchRegexFromQuery,
  PanelBankConfigState,
} from '../util/panelbank';
import * as Panels from '../util/panels';
import * as RunHelpers from '../util/runhelpers';
import EmptyVisualizations from './EmptyVisualizations';
import * as ViewHooks from '../state/views/hooks';
import {useRunsWandbConfigQuery} from '../state/runs/hooks';
import * as PanelTypes from '../state/views/panel/types';
import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import {PanelBankSection} from './PanelBankSection';
import {PanelBankProps} from './PanelBank';
import {usePrevious} from '../state/hooks';
import PanelBankAddVisButton from './PanelBankAddVisButton';
import {RunHistoryKeyInfo} from '../types/run';
import {Button} from 'semantic-ui-react';
import {PartRefFromObjSchema} from '../state/views/types';
import {getMousePositionMemoized} from '../util/mouse';
import {PanelBankSectionConfigObjSchema} from '../state/views/panelBankSectionConfig/types';
import {flatten, compact} from 'lodash';
import makeComp from '../util/profiler';
import {PanelBankConfigWithRefs} from './PanelBank';

type PanelBankSectionsProps = PanelBankProps & {
  warningVisible?: boolean;
  historyKeyInfo: RunHistoryKeyInfo;
  historyKeyViz: {[key: string]: any};
  searchQuery: string;
  panelBankWidth: number;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
};

// This component renders the Panelbank sections (everything besides search + options bar)
// Most of the PanelBank logic lives here
const PanelBankSections: React.FC<PanelBankSectionsProps> = makeComp(
  props => {
    const {
      initializeNewPanels,
      panelBankDiff,
      addSection,
      deleteSection,
      moveSectionBefore,
      movePanel,
    } = usePanelBankSectionsProps(props);

    const {
      panelBankConfigRef,
      warningVisible,
      historyKeyInfo,
      historyKeyViz,
      searchQuery,
      readOnly,
      panelSettingsRef,
      panelBankWidth,
      runSetRefs,
      customRunColorsRef,
      singleRun,
      entityName,
      panelBankConfigWithRefs,
    } = props;

    useEffect(
      () =>
        panelBankSectionsEffect({
          initializeNewPanels,
          panelBankDiff,
        }),
      [
        historyKeyInfo,
        historyKeyViz,
        initializeNewPanels,
        panelBankDiff,
        runSetRefs,
        searchQuery,
        panelSettingsRef,
        panelBankConfigRef,
      ]
    );
    const lastShiftKeyRef = useRef(false);

    const keyTypes = RunHelpers.useKeyTypes(historyKeyInfo);
    const {showEmptySections} =
      panelBankConfigWithRefs.settings || EMPTY_PANEL_BANK_SETTINGS;
    // Filter out panels that don't have data, and panels that don't match the search query
    const renderSectionsWithRefs = useMemo(
      () =>
        panelBankConfigWithRefs.sections.map(section => {
          // activePanelRefs are the panels that get rendered.
          // for flow sections, activePanelRefs = panels with data filtered by searchQuery
          // for grid sections, activePanelRefs = all panels filtered by searchQuery (we show placeholders for panels with no data)
          const activePanelRefs: Array<
            PartRefFromObjSchema<PanelTypes.PanelObjSchema>
          > = [];

          const inactivePanelRefs: Array<
            PartRefFromObjSchema<PanelTypes.PanelObjSchema>
          > = [];

          const searchRegex = searchRegexFromQuery(searchQuery);

          section.panels.forEach(panel => {
            (panelIsActive({
              section,
              panel,
              singleRun,
              searchRegex,
              historyKeyInfo,
              keyTypes,
            })
              ? activePanelRefs
              : inactivePanelRefs
            ).push(panel.ref);
          });

          return {
            ...section,
            activePanelRefs,
            inactivePanelRefs,
          };
        }),
      [
        panelBankConfigWithRefs,
        historyKeyInfo,
        keyTypes,
        searchQuery,
        singleRun,
      ]
    );
    const {defaultMoveToSectionName} = panelBankConfigWithRefs.settings;
    const defaultMoveToSection = panelBankConfigWithRefs.sections.find(s => {
      return s.name === defaultMoveToSectionName;
    });

    // NOTE: this assumes Hidden Panels is the last section
    const hiddenSection = panelBankConfigWithRefs.sections.slice(-1)[0];

    if (
      panelBankConfigWithRefs.state !== PanelBankConfigState.Ready &&
      renderSectionsWithRefs.every(s => s.activePanelRefs.length === 0) &&
      historyKeyInfo.lastStep != null &&
      historyKeyInfo.lastStep < 0
    ) {
      return (
        <>
          <div className="no-metrics-logged">
            <EmptyVisualizations
              headerText="No metrics logged yet."
              helpText={<div>Charts will appear here as data is logged.</div>}
            />
          </div>
          <div className="panel-bank__divider" />
          <div style={{padding: 16}}>
            <Button
              data-test="panelbank-add-section-button"
              size="tiny"
              content={'Add a section'}
              onClick={() => addSection(hiddenSection.ref)}
            />
          </div>
        </>
      );
    }

    const visibleRenderSectionsWithActivePanels = renderSectionsWithRefs.filter(
      s =>
        s.activePanelRefs.length > 0 ||
        // If a user-added section is empty (has 0 panels), we want it to always be visible
        (s.activePanelRefs.length === 0 && s.inactivePanelRefs.length === 0)
    );

    // While dragging sections, all sections are collapsed.
    // This function adds a spacer to account for the height difference.
    const getTopSpacerHeightWhenDraggingSection = (
      dragDropContext: DragDropState
    ) => {
      const SECTION_TITLE_HEIGHT = 49;
      const draggingSectionIndex = _.findIndex(
        visibleRenderSectionsWithActivePanels,
        s => {
          return s.ref.id === dragDropContext.dragRef?.id;
        }
      );
      const result =
        draggingSectionIndex > -1 && dragDropContext.mouseDownEvent != null
          ? getMousePositionMemoized(dragDropContext.mouseDownEvent).page.y -
            72 - // panelbank top padding
            52 - // nav bar height
            (warningVisible ? 52 : 0) - // warning message height (e.g. slow-warning)
            draggingSectionIndex * SECTION_TITLE_HEIGHT -
            getMousePositionMemoized(dragDropContext.mouseDownEvent).offset.y -
            12
          : 0;
      return result;
    };

    const getTopSpacerHeightWhenDraggingPanel = (
      dragDropContext: DragDropState
    ) => {
      const SECTION_TITLE_HEIGHT = 49;
      const sectionIndexOnShift = _.findIndex(
        visibleRenderSectionsWithActivePanels,
        s => {
          return s.ref.id === dragDropContext.dropRefOnShift?.id;
        }
      );
      const mouseYOnShift = dragDropContext.mouseEventOnShift?.pageY!;
      const result =
        sectionIndexOnShift > -1
          ? mouseYOnShift -
            72 - // panelbank top padding
            52 - // nav bar height
            (warningVisible ? 52 : 0) - // warning message height (e.g. slow-warning)
            (sectionIndexOnShift + 0.5) * SECTION_TITLE_HEIGHT
          : 0;
      return result;
    };

    const scrollToElementOnShiftRelease = (dragDropContext: DragDropState) => {
      if (
        dragDropContext.elementOnShiftRelease != null &&
        dragDropContext.mouseEventOnShiftRelease != null
      ) {
        window.scrollTo({
          top:
            (dragDropContext.elementOnShiftRelease as any).offsetTop +
            72 +
            52 -
            getMousePositionMemoized(dragDropContext.mouseEventOnShiftRelease)
              .client.y +
            25,
        });
      }
    };

    return (
      <>
        <DragDropProvider>
          <DragDropContext.Consumer>
            {dragDropContext => {
              const isDraggingPanel =
                dragDropContext.dragRef != null &&
                isPanel(dragDropContext.dragRef);
              const isDraggingSection =
                dragDropContext.dragRef != null &&
                isPanelBankSection(dragDropContext.dragRef);

              if (!dragDropContext.shiftKey && lastShiftKeyRef.current) {
                setTimeout(() => {
                  scrollToElementOnShiftRelease(dragDropContext);
                });
              }
              lastShiftKeyRef.current = dragDropContext.shiftKey;

              return (
                <>
                  {((isDraggingPanel && dragDropContext.shiftKey) ||
                    isDraggingSection) &&
                    dragDropContext.mouseDownEvent != null && (
                      <PanelBankSpacer
                        dropTargetSectionRef={renderSectionsWithRefs[0].ref}
                        onDrop={() => {
                          if (isDraggingSection) {
                            moveSectionBefore(
                              dragDropContext.dragRef as PartRefFromObjSchema<PanelBankSectionConfigObjSchema>,
                              renderSectionsWithRefs[0].ref
                            );
                          }
                        }}
                        style={{
                          height: isDraggingSection
                            ? getTopSpacerHeightWhenDraggingSection(
                                dragDropContext
                              )
                            : getTopSpacerHeightWhenDraggingPanel(
                                dragDropContext
                              ),
                        }}
                      />
                    )}
                  {renderSectionsWithRefs.map(renderSectionWithRefs => {
                    const sectionRef = renderSectionWithRefs.ref;
                    // If a user-added section is empty (has 0 panels), we want it to always be visible
                    const forceVisible =
                      renderSectionWithRefs.activePanelRefs.concat(
                        renderSectionWithRefs.inactivePanelRefs
                      ).length === 0;
                    return (
                      (showEmptySections ||
                        (!showEmptySections &&
                          (renderSectionWithRefs.activePanelRefs.length > 0 ||
                            forceVisible))) && (
                        <PanelBankSection
                          key={sectionRef.id}
                          forceCollapsed={
                            isDraggingSection || dragDropContext.shiftKey
                          }
                          panelBankWidth={panelBankWidth}
                          readOnly={readOnly}
                          workspacePanelSettingsRef={panelSettingsRef}
                          panelBankConfigRef={panelBankConfigRef}
                          historyKeyInfo={historyKeyInfo}
                          panelBankSectionConfigRef={sectionRef}
                          customRunColorsRef={customRunColorsRef}
                          runSetRefs={runSetRefs}
                          searchQuery={
                            searchQuery === '' ? undefined : searchQuery
                          }
                          activePanelRefs={
                            renderSectionWithRefs.activePanelRefs
                          }
                          inactivePanelRefs={
                            renderSectionWithRefs.inactivePanelRefs
                          }
                          moveSectionBefore={moveSectionBefore}
                          addSectionAbove={() => {
                            addSection(sectionRef);
                          }}
                          addSectionBelow={() => {
                            addSection(sectionRef, {addAfter: true});
                          }}
                          deleteSection={() => deleteSection(sectionRef)}
                          // deletePanel={panelRef =>
                          //   deletePanel(panelRef, sectionRef)
                          // }
                          defaultMoveToSectionRef={
                            defaultMoveToSection
                              ? defaultMoveToSection.ref
                              : undefined
                          }
                          movePanel={movePanel}
                          addVisButton={
                            <PanelBankAddVisButton
                              singleRun={singleRun}
                              entityName={entityName}
                              customRunColorsRef={customRunColorsRef}
                              runSetRefs={runSetRefs}
                              panelSettingsRef={panelSettingsRef}
                              panelBankSectionConfigRef={sectionRef}
                              // addNewPanel={panelRef =>
                              //   addPanel(panelRef, sectionRef)
                              // }
                            />
                          }
                        />
                      )
                    );
                  })}
                  {((isDraggingPanel && dragDropContext.shiftKey) ||
                    isDraggingSection) &&
                    dragDropContext.mouseDownEvent != null && (
                      <PanelBankSpacer
                        dropTargetSectionRef={
                          renderSectionsWithRefs.slice(-1)[0].ref
                        }
                        onDrop={() => {
                          moveSectionBefore(
                            dragDropContext.dragRef as PartRefFromObjSchema<PanelBankSectionConfigObjSchema>
                          );
                        }}
                        style={{
                          height: '100vh',
                        }}
                      />
                    )}
                </>
              );
            }}
          </DragDropContext.Consumer>
        </DragDropProvider>
        <div style={{padding: 16}}>
          <Button
            data-test="panelbank-add-section-button"
            size="tiny"
            content={'Add a section'}
            onClick={() => addSection(hiddenSection.ref)}
          />
        </div>
      </>
    );
  },
  {id: 'PanelBankSections'}
);

export default PanelBankSections;

// All sections collapse to just headers when you're dragging.
// This spacer preserves the height and provides a drop target above+below the sections.
const PanelBankSpacer = makeComp(
  (props: {
    dropTargetSectionRef: PartRefFromObjSchema<PanelBankSectionConfigObjSchema>;
    style: React.CSSProperties;
    onDrop(): void;
  }) => {
    return (
      <DropTarget
        isValidDropTarget={({dragRef}) => {
          return isPanelBankSection(dragRef);
        }}
        partRef={props.dropTargetSectionRef}
        onDrop={({dragRef}) => {
          if (dragRef && !_.isEqual(dragRef, props.dropTargetSectionRef)) {
            props.onDrop();
          }
        }}
        style={props.style}
      />
    );
  },
  {id: 'PanelBankSpacer'}
);

function usePanelBankConfigActions(
  panelBankConfigRef: PanelBankConfigTypes.Ref
) {
  const initializeNewPanels = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.initializeNewPanels
  );
  const addSection = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.addSection
  );
  const deleteSection = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.deleteSection
  );
  const moveSectionBefore = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.moveSectionBefore
  );
  const movePanel = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.movePanel
  );

  return {
    initializeNewPanels,
    addSection,
    deleteSection,
    moveSectionBefore,
    movePanel,
  };
}

function usePanelBankSectionsProps(props: PanelBankSectionsProps) {
  const panelBankConfigActions = usePanelBankConfigActions(
    props.panelBankConfigRef
  );
  const keysInPanel: string[] = compact(
    flatten(
      props.panelBankConfigWithRefs.sections.map(section =>
        section.panels.map(panel => Panels.getKey(panel))
      )
    )
  );
  const prevPropsKeys = usePrevious(keysInPanel);
  const wandbConfigQueryResult = useRunsWandbConfigQuery(props.runSetRefs);

  if (
    !wandbConfigQueryResult ||
    wandbConfigQueryResult.loading ||
    wandbConfigQueryResult.error
  ) {
    return {
      ...panelBankConfigActions,
      panelBankDiff: {},
    };
  }
  const wbConfig = wandbConfigQueryResult.wandbConfig;

  const expectedPanelSpecs = keyInfoToDefaultPanelSpecs(
    props.historyKeyInfo,
    props.historyKeyViz,
    !!props.singleRun,
    props.panelBankConfigWithRefs.settings,
    wbConfig
  );

  const addedPanelSpecs = keyInfoToAddedPanels(props.historyKeyViz);

  const foundPanelSpecs = panelBankConfigToFoundPanelSpecs(
    props.panelBankConfigWithRefs
  );

  const expectedAddedPanelConfigsWithRefs =
    expectedAPIAddedPanelSpecsFromPanelBankConfigs(
      props.panelBankConfigWithRefs,
      addedPanelSpecs
    );

  const panelBankDiff = getPanelBankDiff(
    expectedPanelSpecs,
    expectedAddedPanelConfigsWithRefs,
    addedPanelSpecs,
    foundPanelSpecs,
    prevPropsKeys || [],
    props.panelBankConfigWithRefs.state
  );

  return {
    ...panelBankConfigActions,
    panelBankDiff,
  };
}

function panelBankSectionsEffect(
  effectProps: Pick<
    ReturnType<typeof usePanelBankSectionsProps>,
    'initializeNewPanels' | 'panelBankDiff'
  >
) {
  const {initializeNewPanels, panelBankDiff} = effectProps;

  // Short-circuit if there are no new keys
  if (Object.keys(panelBankDiff).length === 0) {
    return;
  }
  initializeNewPanels(panelBankDiff);
}
