// TODO: the vega panels don't change size when the browser resizes
import * as globals from '../css/globals.styles';

import * as _ from 'lodash';
import {useQuery} from '../state/graphql/query';
import {useQuery as useApolloQuery} from 'react-apollo';
import * as React from 'react';
import {useMemo} from 'react';
import {Button, Segment, Message} from 'semantic-ui-react';
import ModifiedDropdown from './elements/ModifiedDropdown';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import * as Panels from '../util/panels';
import * as QueryTS from '../util/queryts';
import * as QueryResult from '../state/queryGraph/queryResult';
import * as VegaLib from '../util/vega2';
import * as VegaLib3 from '../util/vega3';
import {useRunsQueryContext} from '../state/runs/hooks';
import Markdown from './Markdown';
import * as BuiltinPanelDefs from './Vega2/builtinPanelDefs';
import CustomPanelEditor from './CustomPanelEditor';
import VegaPanelUserConfig from './Vega2/VegaPanelUserConfig';
import CustomPanelRenderer from './Vega3/CustomPanelRenderer';
import DocUrls from '../util/doc_urls';
import {TargetBlank} from '../util/links';
import * as Generated from '../generated/graphql';
import {
  CustomChartsQueryData,
  CustomChart,
  CustomChartQueryData,
} from '../graphql/customCharts';

import PanelError from './elements/PanelError';
import {AccessOptions} from '../util/permissions';
import gql from 'graphql-tag';
import {EditCustomChartAccessTrigger} from './Vega2/CustomChartAccess';
import {propagateErrorsContext} from '../util/errors';
import makeComp from '../util/profiler';
import {Table} from '../util/csv';
import WandbLoader from './WandbLoader';

import {PanelExportRef} from './PanelImageExportModal';

const PANEL_TYPE = 'Vega2';

export type VegaPanel2Config = Partial<VegaLib.UserSettings> & {
  userQuery?: VegaLib3.Query;
  transform?: VegaLib3.Transform;
  panelDefId?: string;
  customPanelDef?: VegaLib.VegaPanelDef;
  showRunSelector?: boolean;
  defaultViewedRun?: string;
  defaultViewedStepIndex?: number;
  showStepSelector?: boolean;
};

// This MUST always be used when creating new panels
export const INITIAL_CONFIG: VegaPanel2Config = {
  transform: VegaLib3.DEFAULT_TRANSFORM,
};

type VegaPanelProps = Panels.PanelProps<VegaPanel2Config>;
type VegaPanelWithQueryProps = Omit<VegaPanelWithDataProps, 'data'> & {
  result: QueryResult.Row[];
  cols: string[];
  slow: boolean;
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;

  viewedRun: string;
  viewableRunOptions: string[];

  isHistoryTableQuery?: boolean;
  viewedStep?: number;
  viewableStepOptions?: number[];
  userQuery?: VegaLib3.Query;
  setViewedRun(name: string): void;
  setUserQuery(query: VegaLib3.Query): void;
};

interface VegaPanelState {
  config: string;
  editMode: boolean;
}

function getVegaPanelDef(
  config: VegaPanel2Config,
  activeCustomChart: CustomChart | undefined,
  defaultEntityName: string
): VegaLib.VegaPanelDef | null {
  const panelDefID = getPanelDefID(config, defaultEntityName);
  if (panelDefID.includes('/')) {
    if (activeCustomChart == null) {
      return null;
    }
    return {
      name: activeCustomChart.name,
      displayName: activeCustomChart.displayName,
      description: '',
      spec: activeCustomChart.spec,
      access: activeCustomChart.access,
    };
  } else if (panelDefID === 'custom') {
    // remove name for backwards compatibility
    return {...config.customPanelDef!, name: undefined};
  } else {
    return BuiltinPanelDefs.getPanelDef(panelDefID);
  }
}

export function getPanelDefID(
  config: VegaPanel2Config,
  defaultEntityName: string
) {
  if (config.panelDefId && config.panelDefId.startsWith('lib:')) {
    // backwards compatibility
    return (
      defaultEntityName +
      '/' +
      atob(config.panelDefId.split(':')[1]).split(':')[1]
    );
  }
  return config.panelDefId || 'wandb/line/v0';
}
export class VegaPanel extends React.Component<
  VegaPanelWithQueryProps,
  VegaPanelState
> {
  static type = PANEL_TYPE;

  static transformQuery(
    query: QueryTS.Query,
    config: VegaPanel2Config
  ): RunsDataQuery {
    // We always disable this query, and instead use our own internal RunsDataLoader.
    // We need to do this because the query we want to run actually depends on another
    // query (the vegaPanelQuery which asks for user configured views from the views
    // table), which depends on the current panel configuration.
    const result = toRunsDataQuery(query);
    result.disabled = true;
    return result;
  }

  state: VegaPanelState = {
    config: '',
    editMode: this.props.initialConfigState?.editMode ?? false,
  };

  ref: any;

  setRef = (ref: any) => {
    this.ref = ref;
  };

  renderUserConfig() {
    const {userQuery} = this.props.config;
    const {activeCustomChart, config, cols} = this.props;
    const fieldSettings = config.fieldSettings || {};
    const stringSettings = config.stringSettings || {};
    const panelDef = getVegaPanelDef(
      config,
      activeCustomChart,
      this.props.pageQuery.entityName
    );
    if (panelDef == null) {
      return (
        <PanelError message="This chart does not exist or you don't have permission to view it." />
      );
    }
    const {parsedSpec, err} = VegaLib.parseSpecJSON(panelDef.spec);
    if (parsedSpec === {} || err != null) {
      return <PanelError message="Invalid vega spec." />;
    }
    const query = userQuery ?? VegaLib3.defaultRunSetsQuery;
    return (
      <VegaPanelUserConfig
        fixedQuery={VegaLib3.fixedRunSetsQuery}
        templateArgs={this.props.templateArgs}
        userSettings={{fieldSettings, stringSettings}}
        userQuery={query}
        context={this.props.pageQuery}
        refs={VegaLib3.parseSpecFields(parsedSpec) || []}
        fieldOptions={VegaLib.fieldOptions(cols)}
        // historyFieldOptions={VegaLib.historyFieldOptions(keyInfo)}
        updateUserSettings={newSettings => {
          this.props.updateConfig({...config, ...(newSettings as any)});
        }}
        updateUserQuery={newQuery => {
          if (config.userQuery != null) {
            const updatedQuery = VegaLib3.updateMatchingKeys(
              config.userQuery,
              newQuery
            );
            this.props.updateConfig({...config, userQuery: updatedQuery});
          } else {
            this.props.updateConfig({...config, userQuery: newQuery});
          }
        }}
      />
    );
  }

  renderEditor(customCharts: CustomChart[], activeCustomChart?: CustomChart) {
    const panelDef = getVegaPanelDef(
      this.props.config,
      activeCustomChart,
      this.props.pageQuery.entityName
    );
    if (panelDef == null) {
      return <Segment>Invalid panel.</Segment>;
    }
    return (
      <CustomPanelEditor
        entityName={this.props.pageQuery.entityName}
        projectName={this.props.pageQuery.projectName}
        templateVals={this.props.templateVals}
        templateArgs={this.props.templateArgs}
        panelConfig={this.props.config}
        updatePanelConfig={this.props.updateConfig}
        customRunColors={this.props.customRunColors}
        viewableRunOptions={this.props.viewableRunOptions}
        viewedRun={this.props.viewedRun}
        setViewedRun={value => {
          if (this.props.setViewedRun) {
            this.props.setViewedRun(value);
          }
        }}
        isHistoryTableQuery={this.props.isHistoryTableQuery}
        viewableStepOptions={this.props.viewableStepOptions}
        viewedStep={this.props.viewedStep}
        userQuery={this.props.userQuery}
        setUserQuery={this.props.setUserQuery}
        runSetRefs={this.props.runSetRefs}
        onClose={() => {
          this.setState({editMode: false});
        }}
      />
    );
  }

  renderConfig() {
    const {config, customCharts, activeCustomChart} = this.props;
    const options = _.uniqBy(
      [...customCharts, ...(activeCustomChart ? [activeCustomChart] : [])],
      c => c.id
    ).map(customChart => {
      const optID = `${customChart.entity.name}/${customChart.name}`;
      return {
        key: optID,
        value: optID,
        text: customChart.displayName,
      };
    });
    if (config.panelDefId === 'custom') {
      options.push({key: 'custom', value: 'custom', text: 'custom'});
    }
    const curPanelDef = getVegaPanelDef(
      config,
      activeCustomChart,
      this.props.pageQuery.entityName
    );
    return (
      <div className="chart-modal" style={{padding: '0 12px'}}>
        <div className="chart-preview">
          <div style={{display: 'flex'}}>
            <div style={{fontWeight: 600, marginBottom: 8, marginRight: 8}}>
              Chart
            </div>
            <div>
              <TargetBlank
                href={DocUrls.customCharts}>{`Documentation →`}</TargetBlank>
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <ModifiedDropdown
              style={{minWidth: '20em'}}
              search
              selection
              options={options}
              value={getPanelDefID(config, this.props.pageQuery.entityName)}
              onChange={(e, {value}) => {
                if (typeof value === 'string') {
                  this.props.updateConfig({...config, panelDefId: value});
                }
              }}
            />
            <Button
              style={{marginLeft: 12}}
              onClick={() => this.setState({editMode: true})}>
              Edit
            </Button>
          </div>
          {curPanelDef != null && (
            <div
              style={{
                paddingLeft: 2,
                marginTop: 8,
                marginBottom: 24,
                fontStyle: 'italic',
                color: globals.gray500,
              }}>
              <Markdown
                content={
                  VegaLib.getCustomChartDescription(curPanelDef.spec) || ''
                }
                condensed={false}
              />
            </div>
          )}
          <div style={{minHeight: 400, position: 'relative'}}>
            {this.renderViz()}
          </div>
        </div>
        <div
          className="chart-settings"
          style={{paddingTop: 24, paddingBottom: 24}}>
          {this.renderUserConfig()}
        </div>
      </div>
    );
  }

  renderViz = () => {
    const {loading, config, activeCustomChart} = this.props;
    const {fieldSettings, stringSettings} = config;
    const panelDef = getVegaPanelDef(
      config,
      activeCustomChart,
      this.props.pageQuery.entityName
    );
    if (loading) {
      return <WandbLoader></WandbLoader>;
    }
    if (panelDef == null) {
      return (
        <PanelError message="This chart does not exist or you don't have permission to view it." />
      );
    }
    const {parsedSpec, err} = VegaLib.parseSpecJSON(panelDef.spec);
    if (err != null) {
      return <PanelError message={err} />;
    }
    const {result, slow} = this.props;
    if (result == null) {
      return <div>Empty result</div>;
    }
    return (
      <>
        <EditCustomChartAccessTrigger
          direction={'top center'}
          customChartId={`${this.props.pageQuery.entityName}/${panelDef.name}`}>
          {({anchorRef, setOpen}) => {
            return (
              <div
                ref={anchorRef}
                style={{
                  position: 'absolute',
                  left: 0,
                  width: '100%',
                  zIndex: 10,
                }}>
                {this.props.projectAccess === 'USER_READ' &&
                  panelDef.access === 'PRIVATE' && (
                    <Message
                      negative
                      style={{borderRadius: 0, padding: '8px 12px'}}>
                      This is a private chart inside a public project. Outside
                      visitors will be unable to view it.
                      <div
                        style={{fontWeight: 'bold'}}
                        className="fake-link"
                        onClick={() => setOpen(o => !o)}>
                        Change visibility
                      </div>
                    </Message>
                  )}
              </div>
            );
          }}
        </EditCustomChartAccessTrigger>
        <CustomPanelRenderer
          spec={parsedSpec}
          err={err}
          data={result}
          loading={this.props.loading}
          slow={slow}
          userSettings={{
            fieldSettings: fieldSettings || {},
            stringSettings: stringSettings || {},
          }}
          customRunColors={this.props.customRunColors}
          panelExportRef={this.props.panelExportRef}
          showRunSelector={this.props.config.showRunSelector}
          viewedRun={this.props.viewedRun}
          setViewedRun={value => {
            this.props.setViewedRun(value);
          }}
          viewableRunOptions={this.props.viewableRunOptions}
          setUserQuery={this.props.setUserQuery}
          showStepSelector={
            this.props.config.showStepSelector && this.props.isHistoryTableQuery
          }
          viewedStep={this.props.viewedStep}
          viewableStepOptions={this.props.viewableStepOptions}
          panelConfig={this.props.config}
        />
      </>
    );
  };

  render() {
    const {customCharts, activeCustomChart} = this.props;
    if (this.props.configMode) {
      if (this.state.editMode) {
        return this.renderEditor(customCharts, activeCustomChart);
      } else {
        return this.renderConfig();
      }
    } else {
      return (
        <div style={{overflow: 'auto', width: '100%', height: '100%'}}>
          {this.renderViz()}
        </div>
      );
    }
  }
}

type VegaPanelWithDataProps = VegaPanelProps & {
  customCharts: CustomChart[];
  activeCustomChart?: CustomChart;
  projectAccess: AccessOptions;
  loading: boolean;
  refetch(): void;
} & ReturnType<typeof VegaLib3.queryTemplates>;

const VegaPanelWithCustomChartsData = makeComp(
  (props: VegaPanelWithDataProps) => {
    const {config, templateVals} = props;
    const [viewedRun, setViewedRun] = React.useState<string>(
      config.defaultViewedRun ?? VegaLib3.VIEW_ALL_RUNS
    );
    const [userQuery, setUserQuery] = React.useState<
      VegaLib3.Query | undefined
    >(config.userQuery);

    React.useEffect(() => {
      setUserQuery(config.userQuery);
    }, [config.userQuery]);

    React.useEffect(() => {
      setViewedRun(config.defaultViewedRun ?? VegaLib3.VIEW_ALL_RUNS);
    }, [config.defaultViewedRun]);

    const modifiedUserQuery = useMemo(() => {
      const x = VegaLib3.injectAutoFields(
        userQuery ?? VegaLib3.defaultRunSetsQuery
      );
      return x;
    }, [userQuery]);

    const {initialLoading, result, cols, slow} = VegaLib3.useVegaQuery(
      modifiedUserQuery,
      config.transform || VegaLib3.TRANSFORM_WHEN_MISSING,
      templateVals
    );
    const resultWithColors = useMemo(
      () => VegaLib3.computeRunColors(result, props.customRunColors),
      [props.customRunColors, result]
    );
    const filteredCols = cols.filter(c => !VegaLib3.fieldIsHidden(c));
    const viewableRunOptions: string[] = useMemo(() => {
      return [
        VegaLib3.VIEW_ALL_RUNS,
        ...new Set(resultWithColors.map(item => item.name)),
      ];
    }, [resultWithColors]);

    const isHistoryTableQuery =
      modifiedUserQuery?.queryFields[0]?.fields?.filter(
        field => field.name === 'historyTable'
      ).length !== 0;

    const viewedStep = useMemo(() => {
      if (isHistoryTableQuery && config.showStepSelector && userQuery) {
        return VegaLib3.getViewedStep(userQuery);
      }
    }, [userQuery, isHistoryTableQuery, config.showStepSelector]);

    // For now the steps query fetches the union of all steps for all historyTables
    const stepsQuery = useMemo(
      () => VegaLib3.createIndsQuery(modifiedUserQuery),
      [modifiedUserQuery]
    );

    const stepsQueryResult = VegaLib3.useVegaQuery(
      stepsQuery,
      config.transform || VegaLib3.TRANSFORM_WHEN_MISSING,
      templateVals,
      !isHistoryTableQuery
    );

    const steps = React.useMemo(
      () =>
        [
          ...new Set(stepsQueryResult.result.map(row => row._step as number)),
        ].sort((a, b) => a - b),
      [stepsQueryResult]
    );

    return (
      <VegaPanel
        {...props}
        templateArgs={props.templateArgs}
        templateVals={props.templateVals}
        loading={props.loading || initialLoading}
        result={resultWithColors}
        cols={filteredCols}
        slow={slow}
        panelExportRef={props.panelExportRef}
        projectAccess={props.projectAccess}
        viewedRun={viewedRun}
        setViewedRun={setViewedRun}
        viewableRunOptions={viewableRunOptions}
        isHistoryTableQuery={isHistoryTableQuery}
        viewedStep={viewedStep}
        viewableStepOptions={steps}
        userQuery={userQuery}
        setUserQuery={setUserQuery}
      />
    );
  },
  {id: 'PanelVega2.VegaPanelWithCustomChartsData'}
);

// very sad that I need to make this query just to see if I'm in a team.
// hopefully we can do this a better way with the new panel context stuff.
const PROJECT_ACCESS_QUERY = gql`
  query EntityIsTeam($entityName: String!, $projectName: String!) {
    project(entityName: $entityName, name: $projectName) {
      id
      access
    }
  }
`;

const useTableData = (pageQuery: QueryTS.Query, config: VegaPanel2Config) => {
  const context = useRunsQueryContext();
  const {templateVals} = VegaLib3.queryTemplates(pageQuery, context);

  const {loading, result, cols} = VegaLib3.useVegaQuery(
    config.userQuery ?? VegaLib3.defaultRunSetsQuery,
    config.transform || VegaLib3.TRANSFORM_WHEN_MISSING,
    templateVals
  );
  // remove umapping field
  const unmapInd = cols.findIndex(e => e === '');
  cols.splice(unmapInd, 1);
  const table: Table = {cols, data: result};

  return {table, loading};
};
interface ProjectAccessQueryData {
  project: {
    id: string;
    access: AccessOptions;
  };
}

interface ProjectAccessQueryVariables {
  entityName: string;
  projectName: string;
}

const VegaPanelContainer = makeComp(
  (props: VegaPanelProps) => {
    const {pageQuery} = props;
    const {entityName} = props.query;
    const runsQueryContext = useRunsQueryContext();
    const teamCustomChartsQuery = useApolloQuery<
      CustomChartsQueryData,
      Generated.CustomChartsQueryVariables
    >(Generated.CustomChartsDocument, {
      fetchPolicy: 'network-only',
      variables: {entity: entityName},
    });
    const skipWandbCustomChartsQuery = entityName === 'wandb';
    const wandbCustomChartsQuery = useApolloQuery<
      CustomChartsQueryData,
      Generated.CustomChartsQueryVariables
    >(Generated.CustomChartsDocument, {
      fetchPolicy: 'network-only',
      variables: {entity: 'wandb'},
      skip: skipWandbCustomChartsQuery,
    });
    const chartId = getPanelDefID(props.config, entityName);
    const skipActiveCustomChartQuery =
      chartId.startsWith('builtin') || !chartId.includes('/');
    const activeCustomChartQuery = useApolloQuery<
      CustomChartQueryData,
      Generated.CustomChartQueryVariables
    >(Generated.CustomChartDocument, {
      variables: {id: chartId},
      skip: skipActiveCustomChartQuery,
      context: propagateErrorsContext(),
    });
    const projectAccessQuery = useQuery<
      ProjectAccessQueryData,
      ProjectAccessQueryVariables
    >(PROJECT_ACCESS_QUERY, {
      variables: {
        entityName: runsQueryContext.entityName,
        projectName: runsQueryContext.projectName,
      },
    });

    const {templateArgs, templateVals} = useMemo(
      () => VegaLib3.queryTemplates(pageQuery, runsQueryContext),
      [pageQuery, runsQueryContext]
    );

    const loading =
      teamCustomChartsQuery.loading ||
      wandbCustomChartsQuery.loading ||
      activeCustomChartQuery.loading ||
      projectAccessQuery.loading;

    let customCharts: CustomChart[] = [];
    if (!loading) {
      customCharts = teamCustomChartsQuery.data!.customCharts.edges.map(
        e => e.node
      );
      if (
        !skipWandbCustomChartsQuery &&
        // is null if wandb entity doesn't exist, likely on local/qa
        wandbCustomChartsQuery.data!.customCharts != null
      ) {
        customCharts = customCharts.concat(
          wandbCustomChartsQuery
            .data!.customCharts.edges.map(e => e.node)
            .filter(c => c.access === 'PUBLIC')
        );
      }
    }

    return (
      <VegaPanelWithCustomChartsData
        {...props}
        loading={loading}
        templateArgs={templateArgs}
        templateVals={templateVals}
        customCharts={customCharts}
        activeCustomChart={activeCustomChartQuery.data?.customChart}
        projectAccess={
          projectAccessQuery.loading
            ? 'PRIVATE'
            : projectAccessQuery.project.access
        }
        refetch={teamCustomChartsQuery.refetch}
        panelExportRef={props.panelExportRef}
      />
    );
  },
  {id: 'PanelVega2.VegaPanelContainer'}
);

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, VegaPanel2Config> = {
  type: PANEL_TYPE,
  Component: VegaPanelContainer,
  transformQuery: VegaPanel.transformQuery,
  useTableData,
  exportable: {
    csv: true,
    image: true,
  },
};
