import * as S from './LicenseAdminPage.styles';

import React, {useMemo, useState} from 'react';
import {CellInfo, Column} from 'react-table';
import {Header, Button, Modal, Form} from 'semantic-ui-react';

import * as FuzzyMatch from '../util/fuzzyMatch';
import WBReactTable, {DataRow} from '../components/WBReactTable';
import WandbLoader from '../components/WandbLoader';
import NoMatch from '../components/NoMatch';
import {useViewer} from '../state/viewer/hooks';
import CopyableText from '../components/CopyableText';
import {camelize} from '../util/text';
import EditableField from '../components/EditableField';
import GenerateLicenseForm from '../components/GenerateLicenseForm';

import {
  Exact,
  useLocalLicensesQuery,
  useUpdateLocalLicenseMutation,
  LocalLicensesQuery,
} from '../generated/graphql';
import moment from 'moment';
import {QueryResult} from 'react-apollo';
import _ from 'lodash';

type fecthedLicenseType = QueryResult<
  LocalLicensesQuery,
  Exact<{
    expired?: boolean | null | undefined;
  }>
>;

const searchedColumnNames: string[] = ['UID', 'Customer Name', 'Email'];
const nonsearchedColumnNames: string[] = [
  'License',
  'Notes',
  'Generated By',
  'Expires At',
  'Storage Gigs',
  'Teams',
  'Seats',
  'Flags',
];

const smallColumns = ['Storage Gigs', 'Teams', 'Seats', 'Expires At'];
const mediumColumns = ['Generated By', 'License', 'Notes'];

const REDIRECT_TO_NEW_LICENSE_PAGE = true;
const REDIRECT_IGNORE_USERS = ['carey', 'jsbroks'];

const LicenseAdminPage: React.FC = () => {
  const validLocalLicenses = useLocalLicensesQuery({
    variables: {
      expired: false,
    },
    fetchPolicy: 'network-only',
  });
  const expiredLocalLicenses = useLocalLicensesQuery({
    variables: {
      expired: true,
    },
    fetchPolicy: 'network-only',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateLicense] = useUpdateLocalLicenseMutation();

  const viewer = useViewer();

  // fetch valid or expired local licenses
  const parseLicenseToRow = (license: fecthedLicenseType): DataRow[] => {
    return license.data
      ? license.data.localLicenses.map(l => {
          if (l == null) {
            return {searchString: '', row: {}};
          }
          const claims = l.claims;
          return {
            searchString: l.uid + '|' + l.customerName + '|' + l.contactEmail,
            row: {
              id: l.id,
              uid: l.uid,
              license: l.license,
              customerName: l.customerName,
              email: l.contactEmail,
              generatedBy: l.generatedBy.username,
              expiresAt: moment.unix(claims.expiresAt).format('MM/DD/YYYY'),
              teams: claims.teams,
              seats: claims.seats,
              storageGigs: claims.storageGigs,
              flags: claims.flags,
              notes: l.notes,
            },
          };
        })
      : [];
  };

  const validLicenseRows: DataRow[] = parseLicenseToRow(validLocalLicenses);
  const expiredLicenseRows: DataRow[] = parseLicenseToRow(expiredLocalLicenses);

  const toBeSearchedColumns: Column[] = searchedColumnNames.map(name => {
    return {
      Header: name,
      accessor: camelize(name),
      Cell: (cellInfo: CellInfo) => {
        if (name === 'Customer Name') {
          return <span>{cellInfo.value}</span>;
        }
        return (
          <S.OnelineCell>
            <CopyableText
              text={cellInfo.value || ''}
              toastText={`Copied ${name} to clipboard.`}
            />
          </S.OnelineCell>
        );
      },
    };
  });

  // use memo to prevent remounting in WBReactTable
  const nonsearchedColumns: Column[] = useMemo(
    () =>
      nonsearchedColumnNames.map(name => {
        return {
          Header: name === 'Storage Gigs' ? 'Storage (G)' : name,
          accessor: camelize(name),
          width: smallColumns.includes(name)
            ? 100
            : mediumColumns.includes(name)
            ? 128
            : 152,
          Cell: (cellInfo: CellInfo) => {
            if (name === 'Flags') {
              const flagString: string = _.isArray(cellInfo.value)
                ? cellInfo.value.join(', ')
                : '';
              return <span>{flagString}</span>;
            } else if (name === 'License') {
              return (
                <S.OnelineCell>
                  <CopyableText
                    text={cellInfo.value || ''}
                    toastText="Copied license to clipboard."
                  />
                </S.OnelineCell>
              );
            } else if (name === 'Notes') {
              return (
                <EditableField
                  value={cellInfo.value || ''}
                  placeholder={''}
                  updateValue
                  multiline
                  showEditIcon
                  save={text => {
                    updateLicense({
                      variables: {id: cellInfo.original.id, notes: text},
                    });
                  }}
                />
              );
            }
            return <S.CenteredCell>{cellInfo.value}</S.CenteredCell>;
          },
        };
      }),
    [updateLicense]
  );
  const columns = [...toBeSearchedColumns, ...nonsearchedColumns];

  const searchResultColumns = (searchQuery: string) => {
    const searchedColumns: Column[] = searchedColumnNames.map(name => {
      return {
        Header: name,
        accessor: camelize(name),
        Cell: (cellInfo: CellInfo) => (
          <S.OnelineCell>
            {FuzzyMatch.fuzzyMatchHighlight(cellInfo.value, searchQuery)}
          </S.OnelineCell>
        ),
      };
    });
    return [...searchedColumns, ...nonsearchedColumns];
  };

  const openModalHandler = () => {
    setShowModal(true);
  };
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const submitFormHandler = () => {
    setShowModal(false);
    validLocalLicenses.refetch();
    expiredLocalLicenses.refetch();
  };

  // TODO: Clean up components once we are happy with the new deployer.
  if (
    REDIRECT_TO_NEW_LICENSE_PAGE &&
    !REDIRECT_IGNORE_USERS.includes(viewer?.username ?? '')
  ) {
    window.location.href = 'https://deploy.wandb.ai/admin/orders';
  }

  // no case for undefined viewer because this is wrapped in privateRoute
  if (viewer && !viewer.admin) {
    return <NoMatch />;
  }
  if (validLocalLicenses.loading || expiredLocalLicenses.loading) {
    return <WandbLoader />;
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <Header as="h1">Manage Local Licenses</Header>
        <S.GenerateButtonWrapper>
          <Button primary size="small" onClick={openModalHandler}>
            Generate License
          </Button>
        </S.GenerateButtonWrapper>
        <Modal
          size="tiny"
          as={Form}
          open={showModal}
          onClose={closeModalHandler}>
          <Modal.Header>Generate a new license</Modal.Header>
          <S.FormWrapper>
            <GenerateLicenseForm
              onSubmit={submitFormHandler}
              onClose={closeModalHandler}
            />
          </S.FormWrapper>
        </Modal>
      </S.HeaderWrapper>
      <S.Divider />
      <Header as="h2">Valid Local Licenses</Header>
      <WBReactTable
        data={validLicenseRows}
        columns={columns}
        sortable={true}
        pageSize={10}
        searchResultColumns={searchResultColumns}
      />
      <Header as="h2">Expired Local Licenses</Header>
      <WBReactTable
        data={expiredLicenseRows}
        columns={columns}
        sortable={true}
        pageSize={10}
        searchResultColumns={searchResultColumns}
      />
    </S.Wrapper>
  );
};

export default LicenseAdminPage;
