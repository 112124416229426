import React from 'react';
import {Icon} from 'semantic-ui-react';
import makeComp from '../../../util/profiler';

interface DFTableCellIconProps {
  name: string;
  isSelected: boolean;

  onClick(): void;
}

export const DFTableCellIcon: React.FC<DFTableCellIconProps> = makeComp(
  ({name, onClick, isSelected}) => {
    return (
      <>
        <Icon
          name={name as any}
          className={isSelected ? 'selected' : undefined}
          onClick={onClick}
        />
      </>
    );
  },
  {id: 'DFTableCellIcon', memo: true}
);
