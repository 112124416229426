import styled from 'styled-components';
import {Input as SemanticInput} from 'semantic-ui-react';
import * as globals from '../css/globals.styles';

export const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 6px;
  color: ${globals.gray600};
`;

export const Input = styled(SemanticInput)`
  &&&&& {
    &.disabled {
      opacity: 1;
    }
    input:disabled {
      background-color: ${globals.gray200};
      color: ${globals.gray600};
    }
  }
`;

export const ProcessingMessage = styled.div`
  width: 287px;
  padding: 12px 0;
  line-height: 20px;
  background-color: ${globals.green};
  color: ${globals.white};
`;

export const AgreementLabel = styled.label`
  &&& {
    font-size: 14px;
  }
  text-align: left;
`;
