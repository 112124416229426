import '../css/AuthorizePage.less';

import Postmate from 'postmate';
import React, {FC, useState, useEffect, useCallback} from 'react';
import {Mutation} from 'react-apollo';
import {Button, Container, Header, Segment} from 'semantic-ui-react';

import CopyableText from '../components/CopyableText';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import Loader from '../components/WandbLoader';
import {
  GENERATE_API_KEY_MUTATION,
  GenerateApiKeyMutationData,
  GenerateApiKeyMutationVariables,
} from '../graphql/users';
import {withViewer} from '../graphql/users_get_viewer';
import {User} from '../types/graphql';
import makeComp from '../util/profiler';
import {isInIframe} from '../setup';

interface AuthorizePageProps {
  viewer: User; // from withViewer()
  viewerLoading: boolean; // from withViewer()
  refetch: any; // from withViewer()
}

const AuthorizePage: FC<AuthorizePageProps> = makeComp(
  ({viewer, viewerLoading, refetch}) => {
    const [autoAuthorized, setAutoAuthorized] = useState(false);

    const setupPostmate = useCallback((apiKey: string) => {
      const handshake = new Postmate.Model({
        height: () => document.body.offsetHeight,
      });
      handshake.then((parent: any) => {
        // Only emit the key to colab notebooks for security
        if (parent.parentOrigin.endsWith('colab.googleusercontent.com')) {
          parent.emit('authorize', apiKey);
          setAutoAuthorized(true);
        }
      });
    }, []);

    useEffect(() => {
      if (!viewer || viewer.apiKeys.edges.length === 0) {
        return;
      }
      const key = viewer.apiKeys.edges[0];
      setupPostmate(key.node.name);
      // eslint-disable-next-line
    }, []);

    if (viewerLoading || !viewer || !viewer.apiKeys) {
      return <Loader />;
    }

    const apiKeyString =
      viewer.apiKeys.edges.length > 0
        ? viewer.apiKeys.edges[0].node.name
        : undefined;
    // copyText(apiKeyString);

    const generateKeyInterface = (
      <>
        <p className="help-text">
          Generate a new API key so you can authorize your command line.
        </p>
        <Mutation<GenerateApiKeyMutationData, GenerateApiKeyMutationVariables>
          mutation={GENERATE_API_KEY_MUTATION}
          onCompleted={refetch}>
          {generateApiKey => {
            return (
              <Button
                primary
                onClick={() => generateApiKey()}
                content="Generate new API key"
                size="large"
                icon="key"
                className="generate-api-key-button"
              />
            );
          }}
        </Mutation>
      </>
    );

    const copyKeyInterface = (
      <>
        <p className="help-text">
          Copy this key and paste it into your command line to authorize it.
        </p>
        <CopyableText
          dataTest="copyable-API-key"
          className="api-key"
          text={apiKeyString || ''}
          toastText="Copied key to clipboard."
          disabled={isInIframe()}
        />
      </>
    );

    const autoAuthorizedInterface = (
      <>
        <LegacyWBIcon name="check" className="check-icon" />
        <Header as="h1">
          Welcome, <strong>{viewer.username}</strong>!
        </Header>
        <p className="help-text">
          You've been automatically authenticated. You can now continue from
          your terminal.
        </p>
      </>
    );

    return (
      <Container className="authorize-page" text>
        <Segment className="api-key-wrapper">
          {autoAuthorized
            ? autoAuthorizedInterface
            : apiKeyString
            ? copyKeyInterface
            : generateKeyInterface}
        </Segment>
      </Container>
    );
  },
  {id: 'AuthorizePage', memo: true}
);

export default withViewer(AuthorizePage);
