import React from 'react';

import * as Panel2 from './panel';
import NoMatch from '../NoMatch';
import Loader from '../WandbLoader';
import * as NetronUtils from '../../util/netron';
import * as Op from '@wandb/cg/browser/ops';
import * as CGReact from '../../cgreact';
import {isFile, nullableTaggableValue} from '@wandb/cg/browser/model/types';

const inputType = {
  type: 'union' as const,
  members: NetronUtils.EXTENSIONS.map(e => ({
    type: 'file' as const,
    extension: e.slice(1), // remove initial '.'
  })),
};
type PanelNetronProps = Panel2.PanelProps<typeof inputType>;

const PanelNetron: React.FC<PanelNetronProps> = props => {
  const fileNode = props.input.path;
  const unwrappedType = nullableTaggableValue(fileNode.type);
  const directUrlNode = Op.opFileDirectUrl({file: fileNode});
  const directUrlQuery = CGReact.useNodeValue(directUrlNode);
  if (directUrlQuery.loading) {
    return <Loader />;
  }
  const directURL = directUrlQuery.result;
  if (directURL == null) {
    return <NoMatch />;
  }
  // Giving dummy name of "model", with correct extensions
  const name =
    'model.' + (isFile(unwrappedType) ? unwrappedType.extension : '');
  // thirdPartyAnalyticsOK is set by index.html
  const enableTelemetryString = !(window as any).thirdPartyAnalyticsOK
    ? ''
    : '&telemetry=1';
  return (
    <iframe
      style={{width: '100%', height: '100%', border: 'none'}}
      title="Netron preview"
      src={`/netron/index.html?url=${encodeURIComponent(
        directURL
      )}&identifier=${encodeURIComponent(name)}${enableTelemetryString}`}
    />
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'netron',
  Component: PanelNetron,
  inputType,
};
