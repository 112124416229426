import React from 'react';
import {Popup} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import * as S from './LabeledOption.styles';

interface LabeledOptionProps {
  label: string;
  option: React.ReactNode;
  helpText?: string;
  docUrl?: string;
}
const LabeledOption: React.FC<LabeledOptionProps> = makeComp(
  props => {
    /*
  Doesn't really work to have link in a popup, but would be nice to give users a way to 
  quickly get to the docs.

    const helpWithLink =
    props.docUrl != null ? (
      <span>
        {props.helpText}
        <TargetBlank href={props.docUrl}>
          Learn More
        </TargetBlank>
      </span>
    ) : (
      <span>{props.helpText}</span>
    );
    */
    const optionLabel = (
      <S.OptionLabel className="option-label">{props.label}</S.OptionLabel>
    );

    const popupLabel =
      props.helpText != null ? (
        <Popup
          inverted
          size="mini"
          trigger={optionLabel}
          content={props.helpText}
        />
      ) : (
        optionLabel
      );

    return (
      <S.LabeledOptionBlock>
        {popupLabel}
        <S.Option>{props.option}</S.Option>
      </S.LabeledOptionBlock>
    );
  },
  {id: 'LabeledOption'}
);

export default LabeledOption;
