import _ from 'lodash';
import React, {useCallback} from 'react';
import * as RunUtil from '../../util/runs';
import * as FiltersUtil from '../../util/filters';
import {FilterKeySelectorCreatorProps} from '../Filters/FilterKeySelector';
import {
  FilterValueSelector,
  FilterValueSelectorDate,
  FilterValueSelectorTime,
  FilterValueSelectorCreatorProps,
  filterValueOptionTransform,
} from '../Filters/FilterValueSelector';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';

import {useRunValueSuggestionsQuery} from '../../state/graphql/runValueSuggestionsQuery';
import {useProjectTagsQuery} from '../../state/graphql/projectTagsQuery';

import * as ViewHooks from '../../state/views/hooks';
import * as FilterActions from '../../state/views/filter/actions';
import * as FilterTypes from '../../state/views/filter/types';
import {useViewAction} from '../../state/views/hooks';

import {
  WBTableActionFilter,
  WBTableActionFilterButton,
  WBTableActionFilterPicker,
} from '../WBTable/WBTableActionsFilter';
import makeComp from '../../util/profiler';

interface RunsFilterButtonProps {
  compact?: boolean;
  notificationOpen: boolean;
  filtersOpen: boolean;
  filtersRef: FilterTypes.Ref;
}

const RunsFilterButton = makeComp(
  (props: RunsFilterButtonProps) => {
    const {filtersRef} = props;

    const filters = ViewHooks.useWhole(filtersRef);

    const passThroughProps = _.omit(
      props,
      'compact',
      'filtersOpen',
      'filtersRef'
    );
    return (
      <WBTableActionFilterButton
        {...passThroughProps} // Required for use as popup trigger
        compact={props.compact}
        filtersOpen={props.filtersOpen}
        filters={filters}
      />
    );
  },
  {id: 'RunsFilterButton'}
);

interface RunsFilterPickerProps {
  entityName: string;
  projectName: string;
  filtersRef: FilterTypes.Ref;

  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  onFiltersChanged(): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(props: FilterValueSelectorCreatorProps): React.ReactNode;
}

export const RunsFilterPicker = makeComp(
  (props: RunsFilterPickerProps) => {
    const {filtersRef, onFiltersChanged, defaultToggleFilters} = props;

    const filters = ViewHooks.useWhole(filtersRef);
    const setFilters = useViewAction(filtersRef, FilterActions.set);

    const setFiltersWrapped = useCallback(
      (...args: Parameters<typeof setFilters>) => {
        setFilters(...args);
        onFiltersChanged();
      },
      [setFilters, onFiltersChanged]
    );

    return (
      <WBTableActionFilterPicker
        filters={filters}
        defaultToggleFilters={defaultToggleFilters}
        setFilters={setFiltersWrapped}
        filterKeySelector={props.filterKeySelector}
        filterValueSelector={props.filterValueSelector}
      />
    );
  },
  {id: 'RunsFilterPicker'}
);

export const RUNS_FILTER_DEFAULT_KEYS = [
  'run:displayName',
  'tags:-',
  'run:state',
  'run:createdAt',
  'run:duration',
  'run:username',
  'run:sweep',
  'run:group',
  'run:jobType',
  'run:host',
  'run:inputArtifacts',
  'run:outputArtifacts',
];

type RunsFilterKeySelectorProps = FilterKeySelectorCreatorProps & {
  entityName: string;
  projectName: string;
};

export const RunsFilterKeySelector = makeComp(
  (props: RunsFilterKeySelectorProps) => {
    const {entityName, projectName, keyValue, onValidSelection, focusOnMount} =
      props;
    return (
      <NamedProjectFieldSelector
        data-test="filter-key"
        className="filter-dropdown filter-list__key"
        entityName={entityName}
        projectName={projectName}
        defaultKeys={RUNS_FILTER_DEFAULT_KEYS}
        focusOnMount={focusOnMount}
        closeOnChange
        selection
        multi={false}
        value={keyValue}
        setValue={onValidSelection}
        searchByKeyAndText
      />
    );
  },
  {id: 'RunsFilterKeySelector'}
);

type RunsFilterValueSelectorProps = FilterValueSelectorCreatorProps & {
  entityName: string;
  projectName: string;
};

export const RunsFilterValueSelector = makeComp(
  (props: RunsFilterValueSelectorProps) => {
    const {entityName, projectName} = props;

    const {name, section} = props.filter.key;

    const setPartial = (
      partialFilter: Partial<FiltersUtil.IndividualFilter>
    ) => {
      props.setFilter({
        ...props.filter,
        ...partialFilter,
      } as FiltersUtil.IndividualFilter);
    };

    switch (section) {
      case 'run':
        if (name === 'createdAt') {
          return (
            <FilterValueSelectorDate
              value={props.filter.value as string}
              setFilter={setPartial}
            />
          );
        } else if (name === 'duration') {
          const filter = props.filter as FiltersUtil.ValueFilter;
          return (
            <FilterValueSelectorTime
              meta={filter.meta}
              value={props.filter.value as string}
              setFilter={setPartial}
            />
          );
        } else {
          return (
            <RunsFilterSimpleValueSelector
              entityName={entityName}
              projectName={projectName}
              filter={props.filter}
              setFilter={props.setFilter}
            />
          );
        }
      case 'tags':
        return (
          <RunsFilterTagNameSelector
            entityName={entityName}
            projectName={projectName}
            filter={props.filter as FiltersUtil.ValueFilter}
            setFilter={setPartial}
          />
        );
      default:
        return (
          <RunsFilterSimpleValueSelector
            entityName={entityName}
            projectName={projectName}
            filter={props.filter}
            setFilter={props.setFilter}
          />
        );
    }
  },
  {id: 'RunsFilterValueSelector'}
);

interface RunsFilterTagNameSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter;
  setFilter(filter: Partial<FiltersUtil.IndividualFilter>): void;
}

const RunsFilterTagNameSelector = makeComp(
  (props: RunsFilterTagNameSelectorProps) => {
    const {entityName, projectName} = props;
    const projectTagsQuery = useProjectTagsQuery({entityName, projectName});
    const tagCounts = !projectTagsQuery.loading
      ? projectTagsQuery.project.tagCounts
      : [];
    const suggestions = tagCounts.map(tc => ({
      key: tc.name,
      value: tc.name,
      text: tc.name,
      count: tc.count,
    }));
    const tagNames = tagCounts.map(tc => tc.name);
    let currentValue: any;
    const isMulti = props.filter.op === 'IN' || props.filter.op === 'NIN';
    if (isMulti) {
      currentValue = props.filter.value;
      for (const name of currentValue) {
        if (!_.includes(tagNames, name)) {
          tagNames.push(name);
        }
      }
    } else {
      currentValue = props.filter.key.name;
      const valueTagName = props.filter.key.name;
      if (!_.includes(tagNames, valueTagName)) {
        tagNames.push(valueTagName);
      }
    }
    return (
      <ModifiedDropdown
        className="filter-dropdown filter-list__value"
        // With current optimizations, some components do not update on function prop changes.
        // To avoid a stale filter callback, we include an empty style prop to force a re-render.
        // (This is a workaround, not the ideal solution)
        // Refer to shouldUpdate.ts : L16
        style={{}}
        options={suggestions}
        optionTransform={filterValueOptionTransform}
        loading={projectTagsQuery.loading}
        placeholder="value"
        search
        multiple={isMulti}
        inline
        value={currentValue}
        onChange={(e, {value}) => {
          const f = props.filter;
          if (isMulti) {
            props.setFilter({
              ...f,
              value,
              disabled: false,
            } as FiltersUtil.IndividualFilter);
          } else {
            props.setFilter({
              ...f,
              key: {section: 'tags', name: value as string},
              disabled: false,
            });
          }
        }}
      />
    );
  },
  {id: 'RunsFilterTagNameSelector'}
);

interface RunsFilterSimpleValueSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter;
  setFilter(filter: Partial<FiltersUtil.IndividualFilter>): void;
}

const RunsFilterSimpleValueSelector = makeComp(
  (props: RunsFilterSimpleValueSelectorProps) => {
    const {entityName, projectName} = props;
    const keyPath = RunUtil.keyToServerPath(props.filter.key);
    const valueSugg = useRunValueSuggestionsQuery({
      entityName,
      projectName,
      keyPath,
      filters: FiltersUtil.EMPTY_FILTERS,
    });
    const sugg = !valueSugg.loading ? valueSugg.valueSuggestions : [];
    return (
      <FilterValueSelector
        {...props}
        loading={valueSugg.loading}
        suggestions={sugg}
      />
    );
  },
  {id: 'RunsFilterSimpleValueSelector'}
);

interface RunsFilterTableActionProps {
  entityName: string;
  projectName: string;
  compact?: boolean;
  filtersRef: FilterTypes.Ref;
  notificationOpen: boolean;
  pickerOpen: boolean;

  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  setPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFiltersChanged(): void;
}

export const RunsFilterTableAction = makeComp(
  (props: RunsFilterTableActionProps) => {
    const {notificationOpen, pickerOpen, setPickerOpen} = props;
    return (
      <WBTableActionFilter
        open={pickerOpen}
        setOpen={setPickerOpen}
        trigger={filtersOpen => (
          <RunsFilterButton
            notificationOpen={notificationOpen}
            compact={props.compact}
            filtersOpen={filtersOpen}
            filtersRef={props.filtersRef}
          />
        )}
        content={
          <RunsFilterPicker
            entityName={props.entityName}
            projectName={props.projectName}
            filtersRef={props.filtersRef}
            defaultToggleFilters={props.defaultToggleFilters}
            onFiltersChanged={props.onFiltersChanged}
            filterKeySelector={keySelProps => (
              <RunsFilterKeySelector
                {...keySelProps}
                entityName={props.entityName}
                projectName={props.projectName}
              />
            )}
            filterValueSelector={valSelProps => (
              <RunsFilterValueSelector
                {...valSelProps}
                entityName={props.entityName}
                projectName={props.projectName}
              />
            )}
          />
        }
      />
    );
  },
  {id: 'RunsFilterTableAction'}
);
