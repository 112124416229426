// Types and Functions for working with Report configurations.

import * as _ from 'lodash';
import {useMemo} from 'react';
import * as Obj from '@wandb/cg/browser/utils/obj';
import {ID} from '@wandb/cg/browser/utils/string';
import {
  EditorWithMarkdownBlocks,
  MarkdownBlock as SlateMarkdownBlock,
} from '../../components/Slate/plugins/markdown-blocks';
import {
  isPanelGrid,
  PanelGrid,
} from '../../components/Slate/plugins/panel-grids';
import {WBSlateElement} from '../../components/Slate/WBSlate';
import {useSelector} from '../../state/hooks';
import * as ReportViewsSelectors from '../../state/reports/selectors';
import {ReportViewRef} from '../../state/reports/types';
import {DiscussionThread} from '../../state/views/discussionThread/types';
import * as MarkdownBlockTypes from '../../state/views/markdownBlock/types';
import {ReportWidthOption} from '../../state/views/report/types';
import * as RunSetTypes from '../../state/views/runSet/types';
import * as Filter from '../filters';
import {
  getAndFilterFromRootFilter,
  getFilterListFromRootFilter,
  isIndividual,
} from '../filters';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
  migrateReportToPanelBank,
  migrateWorkspaceToPanelBank,
  PanelBankSectionConfigWithVisiblePanels,
} from '../panelbank';
import * as Panels from '../panels';
import * as PanelSettings from '../panelsettings';
import {EMPTY_SETTINGS} from '../panelsettings';
import * as Query from '../queryts';
import {DEFAULT_RUNS_SORT} from '../queryts';
import * as RunFeed from '../runfeed';
import * as Section from '../section';
import {ReportAuthor} from '../section';
import * as SelectionManager from '../selectionmanager';
import * as TableCols from '../tablecols';
import {ReportSpecVersion} from './shared';

export * from './shared';

// Invariants (not managed by TypeScript):
//   - if we have a Global section, each PanelGroup section has the
//     exactly the same number of RunSets as the Global section has.

export type Block = Section.Config | MarkdownBlockTypes.Config;

export interface ReportConfig {
  global: Section.Config;
  width: ReportWidthOption;
  // TODO(john): panelGroups is no longer just panel groups. Rename to blocks.
  // But be careful because it's referenced in many places, some not typed.
  panelGroups: Block[];
  // if panelbank has not been turned on + initialized, version will be ReportSpecVersion.V0
  // if panelbank been turned on + initialized, version will be ReportSpecVersion.Panelbank
  version: ReportSpecVersion;
  authors?: ReportAuthor[];
  // dicussionThreads is populated by ReportActions.loadDiscussionThreads
  discussionThreads: DiscussionThread[];
}

export const DEFAULT_MARKDOWN_BLOCK_BODY =
  'Add markdown, images, and $\\LaTeX$';

// export const EMPTY_REPORT_PANEL_GRID = {
//   panels: Panels.EMPTY_SINGLE_TAB,
//   customRunColors: {},
//   // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
//   panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
//   panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
// };

// export const EMPTY_REPORT: ReportConfig = {
//   global: {
//     panels: Panels.EMPTY,
//     settings: PanelSettings.EMPTY_SETTINGS,
//     // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
//     panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
//     panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
//   },
//   width: 'fixed',
//   panelGroups: [
//     getMarkdownBlockWithTitle('Section 1'),
//     EMPTY_REPORT_PANEL_GRID,
//   ],
//   version: ReportSpecVersion.FirstClassText,
//   discussionThreads: [],
// };

export const PROJECT_UPDATE_INITIAL_BLOCKS: WBSlateElement[] = [
  {type: 'heading', level: 2, children: [{text: 'Previous Baseline'}]},
  {
    type: 'paragraph',
    level: 2,
    children: [{text: 'Last week I worked on ...'}],
  },
  {type: 'heading', level: 2, children: [{text: 'New Findings'}]},
  {
    type: 'paragraph',
    level: 2,
    children: [{text: 'This week I found that ...'}],
  },
  {type: 'heading', level: 2, children: [{text: 'Next Steps'}]},
  {
    type: 'paragraph',
    level: 2,
    children: [{text: 'Next week, we should examine ...'}],
  },
];

export function getEmptyReportConfig(
  initialBlocks: WBSlateElement[] = []
): SlateReport {
  const emptyReportConfig: SlateReport = {
    panelSettings: PanelSettings.EMPTY_SETTINGS,
    width: 'readable',
    blocks: [...initialBlocks, {type: 'paragraph', children: [{text: ''}]}],
    version: ReportSpecVersion.SlateReport,
    discussionThreads: [],
  };

  return _.cloneDeep(emptyReportConfig);
}

function convertFromVeryOldFormat(json: any) {
  // This is an old-style view, convert it.
  let runSets: Section.RunSetConfig[] = [];
  if (json.filters && json.filters.filter.op === 'OR') {
    // Create one runSet for each OR subclause.
    // But the selections were previously configured across all of these sets... should be ok.
    runSets = json.filters.filter.filters.map(
      (filters: Filter.GroupFilter) => ({
        filters: Filter.Or([filters]),
        grouping: [],
        selections: SelectionManager.EMPTY_ALL_SELECTIONS,
        runFeed: RunFeed.EMPTY_CONFIG,
        sort: Query.sortFromJSONSafe(json.sort),
        enabled: true,
      })
    );
  } else {
    runSets = [
      {
        id: Section.generateRunsetID(),
        filters: Filter.EMPTY_FILTERS,
        grouping: [],
        selections: SelectionManager.EMPTY_ALL_SELECTIONS,
        expandedRowAddresses: [],
        runFeed: RunFeed.EMPTY_CONFIG,
        sort: Query.sortFromJSONSafe(json.sort),
        enabled: true,
        name: 'Run set',
        search: {query: ''},
      },
    ];
  }

  // Key the first tab as view '0'
  let panels: Panels.Config = {
    tabs: ['0'],
    views: {'0': json.views[json.tabs[0]]},
  };
  if (panels.views['0'] == null) {
    panels = Panels.EMPTY_SINGLE_TAB;
  }
  panels = Panels.configFromJSON(panels) || Panels.EMPTY_SINGLE_TAB;
  const panelBankSectionConfig = migrateReportToPanelBank(
    EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    panels,
    ReportSpecVersion.V0
  );

  return {
    version: ReportSpecVersion.V0,
    global: {
      settings: PanelSettings.EMPTY_SETTINGS,
      panels: Panels.EMPTY,
      // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
      panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
      panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    },
    width: 'fixed',
    panelGroups: [
      {
        runSets,
        openRunSet: 0,
        openViz: true,
        panels,

        // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
        panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
        panelBankSectionConfig,
        // TODO(adrnswanberg); This should be enforced once we make this field mandatory.
        customRunColors: {},
      },
    ],
  };
}

function convertReportToFirstClassText(json: any) {
  const newPanelGroups = [];
  for (const pg of json.panelGroups) {
    if (pg.type === 'markdown-block') {
      newPanelGroups.push(pg);
    } else {
      // pg is actual panelGroup
      if (pg.name != null && pg.name.trim().length > 0) {
        newPanelGroups.push({
          type: 'markdown-block',
          content: '# ' + pg.name,
          collapsed: !pg.openViz,
        });
      }
      // name field will now be useless but no harm in saving it jic
      newPanelGroups.push(pg);
    }
  }
  // This line really should be uncommented, but it breaks things because
  // the sections are apparently converted based on the version of the parent.
  // json.version = ReportSpecVersion.FirstClassText;
  json.panelGroups = newPanelGroups;
  json.version = ReportSpecVersion.FirstClassText;
}

function autoConvertMarkdownPanelsToFirstClassText(json: any) {
  const newPanelGroups: any = [];
  pgLoop: for (const pg of json.panelGroups) {
    if (pg.type !== 'markdown-block') {
      if (pg.panelBankSectionConfig == null) {
        // too old to handle anymore
        return;
      }
      if (pg.panelBankSectionConfig.panels.length === 1) {
        const panel = pg.panelBankSectionConfig.panels[0];
        if (panel.viewType === 'Markdown Panel') {
          newPanelGroups.push({
            type: 'markdown-block',
            content: panel.config.value,
          });
          continue;
        }
      }
      for (const [i, panel] of pg.panelBankSectionConfig.panels.entries()) {
        if (
          panel.viewType === 'Markdown Panel' &&
          panel.layout.y === 0 &&
          panel.layout.w === 24
        ) {
          newPanelGroups.push({
            type: 'markdown-block',
            content: panel.config.value,
          });
          pg.panelBankSectionConfig.panels.splice(i, 1);
          newPanelGroups.push(pg);
          continue pgLoop;
        }
      }
    }
    newPanelGroups.push(pg);
  }
  json.panelGroups = newPanelGroups;
  mergeConnectedMarkdownPanels(json);
}

function mergeConnectedMarkdownPanels(json: any) {
  const newPanelGroups: any = [];
  for (const pg of json.panelGroups) {
    if (pg.type === 'markdown-block') {
      if (
        newPanelGroups.length > 0 &&
        newPanelGroups[newPanelGroups.length - 1].type === 'markdown-block'
      ) {
        newPanelGroups[newPanelGroups.length - 1].content += '\n' + pg.content;
        continue;
      }
    }
    newPanelGroups.push(pg);
  }
  json.panelGroups = newPanelGroups;
}

// This adds persistent unique IDs to panels that don't already have them
// Note: These IDs should not be confused with ref IDs, which are ephemeral
function addPanelIds(json: any) {
  const newPanelGroups: any = [];
  for (const pg of json.panelGroups) {
    // Add ID to panels
    if (pg.panelBankSectionConfig != null) {
      const newPanels: any = [];
      pg.panelBankSectionConfig.panels.forEach((p: any, i: number) => {
        newPanels.push({
          ...p,
          __id__: p.__id__ ?? ID(),
        });
      });
      pg.panelBankSectionConfig.panels = newPanels;
    }
    newPanelGroups.push(pg);
  }
  json.panelGroups = newPanelGroups;
}

export interface SlateReport {
  version: ReportSpecVersion;
  authors?: ReportAuthor[];
  discussionThreads: DiscussionThread[];
  width: ReportWidthOption;
  panelSettings: PanelSettings.Settings;
  blocks: WBSlateElement[];
}

function convertToSlateReport(old: ReportConfig): SlateReport {
  const result: SlateReport = {
    version: ReportSpecVersion.SlateReport,
    authors: old.authors,
    discussionThreads: old.discussionThreads,
    width: old.width,
    panelSettings: old.global.settings ?? PanelSettings.EMPTY_SETTINGS,
    blocks: [],
  };

  let container = result.blocks;
  for (const block of old.panelGroups) {
    if (block.type === 'markdown-block') {
      const hasCollapsibleHeader =
        EditorWithMarkdownBlocks.getCollapsibleMarkdownHeading(block.content) !=
        null;
      // get out of collapsed mode when we encounter another collapsible heading
      if (hasCollapsibleHeader) {
        container = result.blocks;
      }
      const slateMarkdownBlock: SlateMarkdownBlock = {
        type: block.type,
        content: block.content,
        children: [{text: ''}],
      };
      container.push(slateMarkdownBlock);
      if (hasCollapsibleHeader && block.collapsed) {
        slateMarkdownBlock.collapsedChildren = [];
        container = slateMarkdownBlock.collapsedChildren as WBSlateElement[];
      }
    } else {
      container.push({
        type: 'panel-grid',
        metadata: block,
        children: [{text: ''}],
      });
    }
  }

  result.blocks.push({type: 'paragraph', children: [{text: ''}]});
  return result;
}

export function fromJSON(json: any): SlateReport {
  if (json.views) {
    // Previously this was immediately returned.
    // That made no sense to me so I'm treating it as an intermediate step.
    json = convertFromVeryOldFormat(json);
  }

  // not sure why version is sometimes undefined here
  if (json.version == null || json.version < ReportSpecVersion.FirstClassText) {
    convertReportToFirstClassText(json);
  }

  if (
    json.version == null ||
    json.version < ReportSpecVersion.FirstClassTextConverted
  ) {
    autoConvertMarkdownPanelsToFirstClassText(json);
  }

  if (json.version < ReportSpecVersion.AddPanelIds) {
    addPanelIds(json);

    const global = sectionFromJSON(json.global, {
      specVersion: json.version || ReportSpecVersion.V0,
    });
    let panelGroups: Section.Config[] = [];
    if (_.isArray(json.panelGroups)) {
      panelGroups = json.panelGroups
        .map((pg: any, i: number) =>
          blockFromJSON(pg, {
            index: i,
            // Not sure how specVersion is being used. I'm just passing it in as before.
            specVersion: json.version || ReportSpecVersion.V0,
          })
        )
        .filter(Obj.notEmpty);
    }
    if (panelGroups.length === 0) {
      panelGroups = [
        {
          panels: Panels.EMPTY,
          // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
          panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
          panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
        },
      ];
    }
    json = {
      // Used to be `version: json.version || ReportSpecVersion.V0,`
      // but it seems like this should always return the latest version. Lmk if I misunderstood.
      version: ReportSpecVersion.AddPanelIds,
      global: global || {
        panels: Panels.EMPTY,
        // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
        panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
        panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
        settings: PanelSettings.EMPTY_SETTINGS,
      },
      width: json.width || 'fixed',
      authors: json.authors,
      panelGroups,
      discussionThreads: [],
    };
  }

  if (json.version < ReportSpecVersion.SlateReport) {
    json = convertToSlateReport(json);
  }

  return json;
}

export function blockFromJSON(
  json: any,
  options?: {index?: number; specVersion?: ReportSpecVersion}
) {
  if (json == null) {
    return null;
  }
  if (json.type === 'markdown-block') {
    return markdownBlockFromJSON(json);
  }
  return {
    ...sectionFromJSON(json, options),
    // Remove panelBankConfig, we don't need it, and it can be huge. We had
    // a bug where we populated this via the create-report/save-snapshot
    // action. Now we clear on load.
    panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
  };
}

export function markdownBlockFromJSON(json: any): MarkdownBlockTypes.Config {
  return json;
}

export function sectionFromJSON(
  json: any,
  options?: {index?: number; specVersion?: ReportSpecVersion}
): Section.Config | null {
  // WARNING!!: If you change how this works it will reset some user-defined run colors.
  // See comment in runSetConfigFromJSON.
  const defaultSectionName =
    options?.index != null ? `Section ${options.index + 1}` : '';
  const sectionName = json.name || defaultSectionName;
  const runSets = _.isArray(json.runSets)
    ? json.runSets
        .map((rs: any, i: number) =>
          runSetConfigFromJSON(defaultSectionName, rs, i)
        )
        .filter(Obj.notEmpty)
    : undefined;
  const runSetCount = runSets != null ? runSets.length : 0;
  const panels =
    json.panels != null ? Panels.configFromJSON(json.panels) : null; // old spec (for 'Custom Visualizations' + pinnable panels)
  const panelBankConfig = migrateWorkspaceToPanelBank(
    json.panelBankConfig,
    panels
  );
  const panelBankSectionConfig = migrateReportToPanelBank(
    json.panelBankSectionConfig || EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    panels,
    options?.specVersion || ReportSpecVersion.V0
  );

  return {
    name: sectionName,

    // configFromJSON is Panels|null, this coerces nulls to undefined
    panels: panels != null ? panels : undefined,

    panelBankConfig, // current spec (for PanelBank)
    panelBankSectionConfig,
    runSets,
    openRunSet:
      json.openRunSet != null && json.openRunSet < runSetCount
        ? json.openRunSet
        : undefined,
    openViz: json.openViz == null ? true : json.openViz,
    settings: json.settings, // TODO: don't blindly accept this
    customRunColors: json.customRunColors || {},
  };
}

function filtersFromJSON(json: any): Filter.RootFilter {
  const filters = Filter.fixFilter(json);
  if (Filter.isRootFilter(filters)) {
    return filters;
  }

  // Otherwise, this is an old format, let's see if we can
  // rescue it.
  const simplified = Filter.simplifiedFiltersToValidFilters(
    Filter.simplify(filters)
  );
  if (Filter.isIndividual(simplified)) {
    return Filter.rootFilterSingle(simplified);
  } else if (Filter.isGroup(simplified)) {
    if (simplified.op === 'AND') {
      if (simplified.filters.every(Filter.isIndividual)) {
        // We have one and filter with no additional nesting
        return {
          op: 'OR',
          filters: [{op: 'AND', filters: simplified.filters}],
        };
      } else if (
        simplified.filters.length > 0 &&
        Filter.isGroup(simplified.filters[0]) &&
        simplified.filters[0].op === 'OR' &&
        simplified.filters.slice(1).every(Filter.isIndividual)
      ) {
        // This case is encountered production, and comes from our
        // old "spam filters" feature. The first child filter can be
        // an OR. To recover we drop the first child filter.
        return {
          op: 'OR',
          filters: [{op: 'AND', filters: simplified.filters.slice(1)}],
        };
      }
    }
  }
  // If we get here, we've encountered data that we don't know how to
  // convert. Inspect the user data that caused this error and consider
  // deleting it, it's probably very old.
  throw new Error('encountered invalid filters');
}

function runSetConfigFromJSON(
  runsetNamePrefix: string,
  json: any,
  i: number
): Section.RunSetConfig | undefined {
  if (json != null) {
    // TODO: Call respective fromJSON methods to validate all these fields.

    // WARNING!!: If you change how this works it will probably reset all grouped run colors everywhere.
    // This is because, for the purposes of run coloring, the runsetID is part of the run name.
    // Consider a migration instead, or decide if resetting group colors isn't a big deal.
    const runsetID = Section.runsetIdFromNameParts(
      runsetNamePrefix,
      `Subsection ${i + 1}`
    );
    const filters = filtersFromJSON(json.filters);
    return {
      ...SelectionManager.fromJSON(json),
      id: json.id || runsetID,
      project: json.project,
      filters,
      runFeed:
        (json.runFeed && TableCols.maybeTruncateTableSettings(json.runFeed)) ||
        RunFeed.EMPTY_CONFIG,
      search: json.search ?? {query: ''},
      sort: migrateToMultipleSortKeys(json.sort) ?? Query.CREATED_AT_DESC,
      name: json.name || `Run set ${i + 1}`,
      enabled: json.enabled != null ? json.enabled : true,
    };
  }
  return undefined;
}

function migrateToMultipleSortKeys(
  s: Query.Sort | Query.SortKey | null
): Query.Sort | null {
  if (s == null) {
    return null;
  }
  if ('keys' in s) {
    if (s.keys.length === 0) {
      return DEFAULT_RUNS_SORT;
    }
    return s;
  }
  // we unintentionally store old client-side refs in the view spec
  delete (s as any).ref;
  return {keys: [s]};
}

function getStubbyPanelGrid(): PanelGrid {
  const stub: PanelGrid = {
    type: 'panel-grid',
    children: [{text: ''}],
    metadata: {
      panels: Panels.EMPTY_SINGLE_TAB,
      customRunColors: {},
      // TODO(views): this is just a stub until we figure out how to use PanelBank in reports
      panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
      panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    },
  };
  return _.cloneDeep(stub);
}

export interface CreateReportOpts {
  mergeFilters?: Filter.Filter;
  runSetName?: string;
  noSectionHeader?: boolean;
}

export function fromRunSet(
  runSet: Section.RunSetConfig,
  opts?: CreateReportOpts,
  initialBlocks?: WBSlateElement[]
) {
  // workaround to avoid mutating the run set data structure in redux
  runSet = _.cloneDeep(runSet);

  if (initialBlocks == null) {
    initialBlocks = [
      {type: 'heading', level: 1, children: [{text: 'Section 1'}]},
    ];
  }
  const report = getEmptyReportConfig([...initialBlocks, getStubbyPanelGrid()]);

  const pg = getFirstPanelGroup(report);
  if (!pg) {
    throw new Error('New report is missing panel group.');
  }
  pg.metadata.runSets = [runSet];
  prepareReportConfig(report, opts);
  return report;
}

export function fromSection(
  section: Section.Config,
  opts?: CreateReportOpts,
  initialBlocks?: WBSlateElement[]
) {
  return fromSections([section], opts, initialBlocks);
}

export function fromSections(
  sections: Section.Config[],
  opts?: CreateReportOpts,
  initialBlocks?: WBSlateElement[]
) {
  // workaround to avoid mutating the sections data structure in redux
  sections = _.cloneDeep(sections);

  if (initialBlocks == null) {
    initialBlocks = opts?.noSectionHeader
      ? []
      : [{type: 'heading', level: 1, children: [{text: 'Section 1'}]}];
  }

  let globalPanelSettings: PanelSettings.Settings | null = null;

  const blocks = sections.map(s => {
    s.panelBankSectionConfig.type = 'grid';
    delete s.openRunSet;
    if (s.settings != null) {
      globalPanelSettings = s.settings;
      delete s.settings;
    }
    return {
      type: 'panel-grid',
      children: [{text: ''}],
      metadata: s,
    } as PanelGrid;
  });

  const report = getEmptyReportConfig([...initialBlocks, ...blocks]);

  if (globalPanelSettings != null) {
    report.panelSettings = globalPanelSettings;
  }

  prepareReportConfig(report, opts);

  return report;
}

function prepareReportConfig(report: SlateReport, opts?: CreateReportOpts) {
  if (opts == null) {
    return;
  }
  const {mergeFilters, runSetName} = opts;
  const pg = getFirstPanelGroup(report);
  if (!pg) {
    throw new Error('New report is missing panel group.');
  }
  if (mergeFilters != null) {
    const filterList = getFilterListFromRootFilter(
      pg.metadata.runSets![0].filters
    );
    filterList.unshift(mergeFilters);
  }
  if (runSetName != null) {
    pg.metadata.runSets![0].name = runSetName;
  }
  groupFilterToMultipleRunSetHax(report);
}

// HAX
// we currently do not support group OR filters at the `FilterList` level.
// instead, get the same behavior by splitting the OR filters to multiple run sets.
// this hack is bad and we should feel bad.
function groupFilterToMultipleRunSetHax(report: SlateReport): void {
  const pg = getFirstPanelGroup(report);
  if (!pg) {
    throw new Error('New report is missing panel group.');
  }
  const newRunSets = [];
  for (const rs of pg.metadata.runSets!) {
    const filterList = getFilterListFromRootFilter(rs.filters);
    const newFilterList: Filter.Filter[] = [];
    const filtersToDistribute: Filter.Filter[] = [];
    for (const f of filterList) {
      if (isIndividual(f)) {
        newFilterList.push(f);
      } else if (f.op === 'AND') {
        newFilterList.push(...f.filters);
      } else if (f.op === 'OR') {
        filtersToDistribute.push(...f.filters);
      }
    }
    if (filtersToDistribute.length === 0) {
      const andFilter = getAndFilterFromRootFilter(rs.filters);
      andFilter.filters = newFilterList;
      newRunSets.push(rs);
      continue;
    }
    const distributedRS = filtersToDistribute.map((f, i) => {
      const clone = _.cloneDeep(rs);
      const andFilter = getAndFilterFromRootFilter(clone.filters);
      andFilter.filters = [f, ...newFilterList];
      clone.name = `${rs.name} ${i + 1}`;
      return clone;
    });
    newRunSets.push(...distributedRS);
  }
  pg.metadata.runSets = newRunSets;
}

export function singleEmptyRunSetSelectAll() {
  const report = getEmptyReportConfig();
  const pg = getFirstPanelGroup(report);
  if (!pg) {
    throw new Error('New report is missing panel group.');
  }
  pg.metadata.runSets = [_.cloneDeep(Section.emptyReportRunSetSelectAll())];
  return report;
}

export function singleEmptyRunSetSelectNone() {
  const report = getEmptyReportConfig();
  const pg = getFirstPanelGroup(report);
  if (!pg) {
    throw new Error('New report is missing panel group.');
  }
  pg.metadata.runSets = [_.cloneDeep(Section.emptyReportRunSetSelectNone())];
  return report;
}

export function getFirstPanelGroup(report: SlateReport) {
  for (const block of report.blocks) {
    if (isPanelGrid(block)) {
      return block;
    }
  }
  return null;
}

export interface ProjectSpecifier {
  entityName: string;
  projectName: string;
}

export function useReportProjects(viewRef: ReportViewRef): ProjectSpecifier[] {
  const view = useSelector(state => state.views.views[viewRef.id]);
  const reportNorm = useSelector(ReportViewsSelectors.getReportPart(viewRef));
  const panelGrids = reportNorm.blocks.filter(isPanelGrid);
  const runSets = panelGrids.flatMap(pg => pg.metadata.runSets || []);

  const pss: Array<{entityName: string; projectName: string}> = [];
  if (view.project != null) {
    pss.push({
      entityName: view.project.entityName,
      projectName: view.project.name,
    });
  }
  for (const runSet of runSets) {
    if (runSet.project != null) {
      const ps = {
        entityName: runSet.project.entityName,
        projectName: runSet.project.name,
      };
      pss.push(ps);
    }
  }
  const pssKey = getProjectSpecifierCollectionKey(pss);
  return useMemo(() => {
    const projectSpecifierMap = getProjectSpecifierMap(pss);
    return Object.values(projectSpecifierMap);
    // eslint-disable-next-line
  }, [pssKey]);
}

interface ProjectSpecifierMap {
  [key: string]: ProjectSpecifier;
}

export function getProjectSpecifierMap(
  pss: ProjectSpecifier[]
): ProjectSpecifierMap {
  const map: ProjectSpecifierMap = {};
  for (const ps of pss) {
    const key = getProjectSpecifierKey(ps);
    map[key] = ps;
  }
  return map;
}

export function getProjectSpecifierKey(ps: ProjectSpecifier): string {
  return `${ps.entityName},${ps.projectName}`;
}

function getProjectSpecifierCollectionKey(pss: ProjectSpecifier[]): string {
  return pss.map(getProjectSpecifierKey).join(':');
}

export function createReportSection(
  runSets: RunSetTypes.RunSetConfig[],
  customRunColors: Section.RunColorConfig,
  panelSettings: PanelSettings.Settings | null,
  section: PanelBankSectionConfigWithVisiblePanels,
  panels: Panels.LayedOutPanel[]
) {
  return {
    runSets,
    customRunColors,
    settings: panelSettings ?? EMPTY_SETTINGS,
    // Don't need to pass this through, reports don't use it
    // and it can be huge!
    panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
    panelBankSectionConfig: {
      ...section,
      panels,
    },
  };
}
