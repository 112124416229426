import styled from 'styled-components';

export const Page = styled.div`
  padding: 16px;
`;

export const SingleBenchmark = styled.div`
  margin-bottom: 32px;
`;

export const TabBody = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const BenchmarkName = styled.div`
  font-size: 18px;
  margin-right: 16px;
`;

export const ResultTable = styled.table``;
export const ResultTableRow = styled.tr``;
export const ResultTableRowHeader = styled.td`
  font-weight: bold;
`;
export const ResultTableCell = styled.td``;
