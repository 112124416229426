import '../css/ReportPageHeaderEdit.less';

import React, {useCallback} from 'react';
import makeComp from '../util/profiler';
import {useSelector} from '../state/hooks';
import * as ViewHooks from '../state/views/hooks';
import classNames from 'classnames';
import ReportPanelSettings from '../components/ReportPanelSettings';
import ReportPageActionsEdit from '../components/ReportPageActionsEdit';
import {Button, Popup} from 'semantic-ui-react';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {WBIcon} from '@wandb/ui';
import {ReportViewRef} from '../state/reports/types';
import {ReportPageQueryData} from '../state/graphql/reportPageQuery';
import * as ReportViewsSelectors from '../state/reports/selectors';
import {ReportWidthOption} from '../state/views/report/types';
import * as ReportActions from '../state/views/report/actions';
import {useGrammarlyNoticeContext} from '../util/userContext';

interface ReportPageHeaderEditStickyProps {
  viewRef: ReportViewRef;
  project?: ReportPageQueryData['project'];
  tipsSidebarOpen: boolean;
  setTipsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default makeComp(
  (props: ReportPageHeaderEditStickyProps) => {
    const {viewRef, project, tipsSidebarOpen, setTipsSidebarOpen} = props;

    const view = useSelector(state => state.views.views[viewRef.id]);
    const entityName = view.project?.entityName ?? view.entityName;
    const projectName = view.project?.name;

    const reportRef = useSelector(ReportViewsSelectors.getReportRef(viewRef));
    const setReportWidth = ViewHooks.useViewAction(
      reportRef,
      ReportActions.setWidth
    );
    const reportWidth = useSelector(
      ReportViewsSelectors.getReportWidth(viewRef)
    );

    const setReportWidthAndResize = (width: ReportWidthOption) => {
      setReportWidth(width);
      window.setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
    };

    const {
      dismissed: grammarlyNoticeDismissed,
      dismiss: dismissGrammarlyNotice,
      loading: grammarlyNoticeContextLoading,
    } = useGrammarlyNoticeContext();
    const grammarlyDetected =
      document.querySelector('grammarly-popups') != null;
    const showGrammarlyNotice =
      grammarlyDetected &&
      !grammarlyNoticeContextLoading &&
      !grammarlyNoticeDismissed;
    const grammarlyNotice = showGrammarlyNotice ? (
      <div className="grammarly-notice">
        Grammarly and similar browser plugins can cause reports to be very slow.
        Please pause these extensions to improve performance.
        <WBIcon
          name="close"
          className="dismiss-grammarly-notice"
          onClick={dismissGrammarlyNotice}
        />
      </div>
    ) : null;

    const toggleTipsSidebar = useCallback(
      () => setTipsSidebarOpen(prev => !prev),
      [setTipsSidebarOpen]
    );

    return (
      <>
        <div
          className={classNames('report-header-edit-sticky', {
            'with-grammarly': showGrammarlyNotice,
          })}>
          <div className="report-header-edit-sticky__left">
            <Popup
              inverted
              size="mini"
              position="bottom left"
              trigger={
                <Button
                  size="tiny"
                  className={classNames('wb-icon-button', 'only-icon', {
                    'action-button--active': tipsSidebarOpen,
                  })}
                  active={tipsSidebarOpen}
                  onClick={toggleTipsSidebar}>
                  <LegacyWBIcon name="help" title="Report tips" />
                </Button>
              }>
              Get started with Reports
            </Popup>
            <ReportPanelSettings reportViewRef={viewRef} />
            <Button.Group className="report-header-edit-sticky__toggle-width-buttons">
              <Button
                size="tiny"
                className={classNames('wb-icon-button', 'only-icon', {
                  'action-button--active': reportWidth === 'readable',
                })}
                active={reportWidth === 'readable'}
                onClick={() => {
                  setReportWidthAndResize('readable');
                }}>
                <LegacyWBIcon
                  name="small-width"
                  title="Readable width"></LegacyWBIcon>
              </Button>
              <Button
                size="tiny"
                className={classNames('wb-icon-button', 'only-icon', {
                  'action-button--active': reportWidth === 'fixed',
                })}
                active={reportWidth === 'fixed'}
                onClick={() => {
                  setReportWidthAndResize('fixed');
                }}>
                <LegacyWBIcon
                  name="full-width"
                  title="Fixed width"></LegacyWBIcon>
              </Button>
              <Button
                size="tiny"
                className={classNames('wb-icon-button', 'only-icon', {
                  'action-button--active': reportWidth === 'fluid',
                })}
                active={reportWidth === 'fluid'}
                onClick={() => {
                  setReportWidthAndResize('fluid');
                }}>
                <LegacyWBIcon
                  name="fluid-width"
                  title="Fluid width"></LegacyWBIcon>
              </Button>
            </Button.Group>
          </div>
          <div className="report-header-edit-sticky__right">
            <ReportPageActionsEdit
              entityName={entityName}
              projectName={projectName}
              viewRef={viewRef}
              projectAccess={project?.access ?? 'PRIVATE'}
              isTeam={project?.entity.isTeam ?? false}
            />
          </div>
          {grammarlyNotice}
        </div>
      </>
    );
  },
  {id: 'ReportPageHeaderEditSticky'}
);
