import React, {useMemo, useState} from 'react';
import * as querystring from 'querystring';
import {CurrentPageBySectionRefID} from '../../util/panelbankFlow';
import makeComp from '../../util/profiler';

let params: any = {};
try {
  params = querystring.parse(window.location.search);
} catch (e) {
  console.warn(e);
}

export interface PanelBankContextState {
  exportingUnknownID: boolean;
  exportingPanelRefID: string | null;
  exportingSectionRefID: string | null;
  currentPageBySectionRefID: CurrentPageBySectionRefID;
  disableWaitToLoad: boolean;
}

export const PanelBankContext = React.createContext<PanelBankContextState>({
  exportingUnknownID: false,
  exportingPanelRefID: null,
  exportingSectionRefID: null,
  currentPageBySectionRefID: {},
  disableWaitToLoad: params.disableWaitToLoad != null,
});

export interface PanelBankContextUpdaters {
  setExportingUnknownID(isExporting: boolean): void;
  setExportingPanelRefID(panelRefID: string | null): void;
  setExportingSectionRefID(sectionRefID: string | null): void;
  setCurrentPageForSectionRefID(
    sectionRefID: string,
    currentPage: number
  ): void;
  setDisableWaitToLoad(disabled: boolean): void;
}

export const PanelBankUpdaterContext =
  React.createContext<PanelBankContextUpdaters>({
    setExportingUnknownID: () => {},
    setExportingPanelRefID: () => {},
    setExportingSectionRefID: () => {},
    setCurrentPageForSectionRefID: () => {},
    setDisableWaitToLoad: () => {},
  });

// We introduce these contexts so that the top PanelBank components have access to data from further down the tree.
// We may want to move this into Redux in the future, but there is currently no good place for it.
export const PanelBankContextProvider: React.FC = makeComp(
  ({children}) => {
    const [exportingUnknownID, setExportingUnknownID] =
      useState<boolean>(false);
    const [exportingPanelRefID, setExportingPanelRefID] = useState<
      string | null
    >(null);
    const [exportingSectionRefID, setExportingSectionRefID] = useState<
      string | null
    >(null);
    const [currentPageBySectionRefID, setCurrentPageBySectionRefID] =
      useState<CurrentPageBySectionRefID>({});
    const [disableWaitToLoad, setDisableWaitToLoad] = useState<boolean>(false);

    const updaters = useMemo<PanelBankContextUpdaters>(
      () => ({
        setExportingUnknownID,
        setExportingPanelRefID,
        setExportingSectionRefID,
        setCurrentPageForSectionRefID: (sectionRefID, currentPage) =>
          setCurrentPageBySectionRefID(prev => ({
            ...prev,
            [sectionRefID]: currentPage,
          })),
        setDisableWaitToLoad,
      }),
      []
    );

    const state = useMemo<PanelBankContextState>(
      () => ({
        exportingUnknownID,
        exportingPanelRefID,
        exportingSectionRefID,
        currentPageBySectionRefID,
        disableWaitToLoad,
      }),
      [
        exportingUnknownID,
        exportingPanelRefID,
        exportingSectionRefID,
        currentPageBySectionRefID,
        disableWaitToLoad,
      ]
    );

    return (
      <PanelBankContext.Provider value={state}>
        <PanelBankUpdaterContext.Provider value={updaters}>
          {children}
        </PanelBankUpdaterContext.Provider>
      </PanelBankContext.Provider>
    );
  },
  {id: 'PanelBankContextProvider', memo: true}
);
