import '../../css/FilterEditable.less';

import _ from 'lodash';
import React, {FC} from 'react';
import {Checkbox, Dropdown, Popup} from 'semantic-ui-react';
import {
  EMPTY_FILTER_VALUE,
  IndividualOp,
  IndividualFilter,
  FilterMeta,
} from '../../util/filters';
import * as Run from '../../util/runs';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';
import {FilterTagOpSelector} from './FilterValueSelector';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import makeComp from '../../util/profiler';
const operatorMapping = _.toPairs({
  '=': '=',
  '!=': '\u2260',
  '>=': '\u2265',
  '<=': '\u2264',
  IN: 'IN',
  NIN: 'NOT IN',
});

interface FilterEditableProps {
  filter: IndividualFilter;

  justAdded: boolean;

  setFilter(filter: IndividualFilter): void;
  setFilterOp(op: IndividualOp): void;
  setFilterValue(value: Run.Value): void;
  setFilterMultiValue(value: Run.Value[]): void;
  setFilterMeta(meta: FilterMeta): void;
  setFilterDisabled(disabled: boolean): void;
  deleteFilter(): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(props: FilterValueSelectorCreatorProps): React.ReactNode;
}

export const FilterEditable: FC<FilterEditableProps> = makeComp(
  ({
    filter,
    justAdded,
    setFilter,
    setFilterOp,
    setFilterDisabled,
    deleteFilter,
    filterKeySelector,
    filterValueSelector,
  }) => {
    let operators = operatorMapping.map(v => {
      const isSymbol = v[1].length === 1;
      const [value, text] = v;
      return {
        text: isSymbol ? <span className="symbol">{text}</span> : text,
        value,
      };
    });

    // Get only functional operators for createdAt
    if (filter.key.name === 'createdAt') {
      operators = _.remove(operators, o => new Set(['<=', '>=']).has(o.value));
    }
    if (filter.key.name === 'state') {
      operators = _.remove(operators, o => !new Set(['<=', '>=']).has(o.value));
    }

    return (
      <div data-test="filter-item" className="filter-list__item">
        <Checkbox
          data-test="filter-toggle"
          className="filter-list__checked"
          checked={!filter.disabled}
          onChange={() => {
            // Only toggle if all fields are set.
            const newActive = !filter.disabled;
            setFilterDisabled(newActive);
          }}
        />
        {filterKeySelector({
          focusOnMount: justAdded,
          keyValue: Run.displayKey(filter.key),
          storedKey: Run.displayKey(filter.key),
          onValidSelection: keyString => {
            const filterKey = Run.keyFromString(keyString);
            if (filterKey != null) {
              if (
                filterKey.section === 'run' &&
                filterKey.name === 'createdAt'
              ) {
                setFilter({
                  key: filterKey,
                  op: '<=',
                  value: new Date().toISOString(),
                  disabled: false,
                });
              } else if (filterKey.section === 'tags') {
                setFilter({
                  key: filterKey,
                  op: '=',
                  value: true,
                  disabled: true,
                });
              } else {
                setFilter({
                  key: filterKey,
                  op: '=',
                  value: EMPTY_FILTER_VALUE,
                  disabled: true,
                });
              }
            }
          },
        })}
        {filter.key.section === 'tags' ? (
          <FilterTagOpSelector
            keys={[]}
            filter={filter}
            setFilter={setFilter}
          />
        ) : (
          <Dropdown
            className="filter-dropdown filter-list__operation"
            data-test="filter-operation"
            options={operators}
            placeholder={'operator'}
            selectOnNavigation={false}
            value={filter.op}
            onChange={(e, {value}) => {
              setFilterOp(value as IndividualOp);
            }}
          />
        )}
        {filterValueSelector({
          filter,
          setFilter,
        })}
        <Popup
          inverted
          size="mini"
          content="Remove filter"
          position="right center"
          on="hover"
          trigger={
            <div className="filter-list__delete filter-list__action">
              <LegacyWBIcon
                data-test="filter-delete"
                name="close"
                onClick={deleteFilter}
                title="remove filter"
              />
            </div>
          }
        />
      </div>
    );
  },
  {id: 'FilterEditable', memo: true}
);
