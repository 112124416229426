import * as _ from 'lodash';
import * as QueryString from 'query-string';

export type UrlResult =
  | {type: 'user'; user: string}
  | {type: 'default'}
  | {type: 'empty'}
  | {type: 'none'};

export function readUrl(): UrlResult {
  const parsed = QueryString.parse(window.location.search);
  if (parsed && parsed.workspace) {
    const workspace = _.isArray(parsed.workspace)
      ? parsed.workspace[0]
      : parsed.workspace;
    if (workspace === 'empty') {
      // This will load an empty workspace. Useful for getting out of broken states
      return {
        type: 'empty',
      };
    } else if (workspace === 'default') {
      return {
        type: 'default',
      };
    } else if (_.startsWith(workspace, 'user-')) {
      return {
        type: 'user',
        user: workspace.slice(5),
      };
    }
  }
  return {
    type: 'none',
  };
}
