import React, {useCallback, useState} from 'react';

import * as Query from '../../../util/queryts';

import {
  DFTableActionSort,
  DFTableActionSortButton,
  DFTableActionSortPicker,
  DFTableActionSortDropdown,
} from './DFTableActionsSort';
import makeComp from '../../../util/profiler';

interface DataFrameSortPickerProps {
  defaultSort: Query.Sort;
  sort: Query.Sort;
  loading: boolean;
  sortableKeys: string[];
  disabled?: boolean;
  setSort(sort: Query.Sort): void;
}

export const DataFrameSortTableAction = makeComp(
  (props: DataFrameSortPickerProps) => {
    const [open, setOpen] = useState(false);
    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);
    return (
      <DFTableActionSort
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        trigger={popupOpen => (
          <DFTableActionSortButton
            sortOpen={popupOpen}
            sort={props.sort}
            disabled={props.disabled}
            defaultSort={props.defaultSort}
          />
        )}
        content={
          <DFTableActionSortPicker
            defaultSort={props.defaultSort}
            sort={props.sort}
            setSort={props.setSort}
            makeDropdown={sortKeyIdx => (
              <DFTableActionSortDropdown
                loading={props.loading}
                sortableKeys={props.sortableKeys}
                sort={props.sort}
                sortKeyIdx={sortKeyIdx}
                setSort={props.setSort}
              />
            )}
          />
        }
      />
    );
  },
  {id: 'DataFrameSortTableAction'}
);
