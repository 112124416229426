import styled, {css} from 'styled-components';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {Icon} from 'semantic-ui-react';

interface ControlWrapperProps {
  hovering: boolean;
  canFullscreen?: boolean;
}
export const ControlWrapper = styled.div<ControlWrapperProps>`
  position: relative;
  ${({canFullscreen}) =>
    canFullscreen &&
    css`
      overflow: hidden;
    `}
  height: 100%;
  /* ${({hovering}) => css`
    border: ${hovering ? '1px solid #eee' : 'none'};
    padding: ${hovering ? '0px' : '1px'};
  `} */
`;

interface ControlWrapperBarProps {
  hovering: boolean;
}
export const ControlWrapperBar = styled.div<ControlWrapperBarProps>`
  /* flex: 0 0 auto; */
  position: absolute;
  top: 4px;
  width: calc(100% - 6px);
  visibility: ${props => (props.hovering ? 'visible' : 'hidden')};
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
`;

interface ControlWrapperContentProps {
  canFullscreen?: boolean;
}
export const ControlWrapperContent = styled.div<ControlWrapperContentProps>`
  height: 100%;
  ${({canFullscreen}) =>
    canFullscreen &&
    css`
      overflow: hidden;
    `}
`;

export const IconButton = styled.span`
  width: 22px;
  height: 20px;
  color: #3b3f42;
  background-color: #b3b3b3;
  margin-left: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: initial;
  i {
    margin: 0;
  }
`;

export const FullscreenButton = styled(LegacyWBIcon).attrs({
  name: 'fullscreen',
  role: 'button',
  tabindex: 0,
})``;

export const DevQueryIcon = styled(Icon).attrs({name: 'chart area'})``;

export const DevQueryPopupContent = styled.div`
  max-height: 400;
  max-width: 1200;
  overflow: 'auto';
  font-size: 14;
  white-space: 'nowrap';
`;

export const DevQueryPopupLabel = styled.span`
  font-weight: bold;
`;
