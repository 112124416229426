import '../../css/RunOverview.less';

import _ from 'lodash';
import moment from 'moment';
import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import sanitizeBranchName from 'branch-safe-name/sanitize';

import CopyableText from '../../components/CopyableText';
import DeleteRunModal from '../../components/DeleteRunModal';
import EditableField from '../../components/EditableField';
import TimeDisplay from '../../components/elements/TimeDisplay';
import LegacyWBIcon from '../../components/elements/LegacyWBIcon';
import ProjectAccess from '../../components/ProjectAccess';
import RunConfigTable from '../../components/RunConfigTable';
import RunStateIndicator from '../../components/RunStateIndicator';
import RunSummaryTable from '../../components/RunSummaryTable';
import SubmitToBenchmarkModal from '../../components/SubmitToBenchmarkModal';
import {TagAddButton} from '../../components/TagAddButton';
import {Tags} from '../../components/Tags';
import * as GQLTypes from '../../types/graphql';
import docUrl from '../../util/doc_urls';
import {JSONparseNaN} from '@wandb/cg/browser/utils/jsonnan';
import {AccessOptions} from '../../util/permissions';
import * as RunHelpers from '../../util/runhelpers';
import * as Run from '../../util/runs';
import * as urls from '../../util/urls';
import {BenchmarkApproval} from '../Benchmark/BenchmarkApprovalDialogue';
import makeComp from '../../util/profiler';
import {RunArtifactGrid} from './RunArtifacts';
import {openJSONNewTab} from '../../util/document';
import {monthRoundedTime} from '../../util/time';
import {TargetBlank} from '../../util/links';

interface RunOverviewProps {
  run: GQLTypes.Run;
  project: GQLTypes.Project;
  viewer: GQLTypes.User;

  config: Run.KeyVal;
  summaryMetrics: Run.KeyVal;

  history: any;

  updateRun(fields: {
    displayName?: string;
    notes?: string;
    tags?: string[];
  }): void;

  updateProject(fields: {access?: string}): void;

  deleteRun(id: string, deleteArtifacts?: boolean): void;
}

function isOffline(wandbValue: any) {
  // This is a helper function that de-codes the telemetry data provided in the config from the client
  // For details of the structure see: https://github.com/wandb/client/blob/master/wandb/proto/wandb_telemetry.proto
  // To summarize:  - `t` is the telemetry record
  //                - `3` is the feature field (in the telemetry record)
  //                - `4` is the offline flag (in the feature message)
  return !!wandbValue?.t?.[3]?.[4];
}

function getRunDuration(run: GQLTypes.Run, runIsOffline: boolean) {
  const summary = JSONparseNaN(run.summaryMetrics);
  if (summary?._wandb?.runtime != null) {
    return monthRoundedTime(summary._wandb.runtime);
  }

  if (runIsOffline && summary?._runtime != null) {
    return monthRoundedTime(summary._runtime);
  }

  return (
    <TimeDisplay
      timestamp={new Date(run.createdAt)}
      format="month_round"
      now={new Date(run.heartbeatAt)}
    />
  );
}
const RunOverview: FC<RunOverviewProps> = makeComp(
  ({
    run,
    project,
    viewer,
    config,
    summaryMetrics,
    history,
    updateRun,
    updateProject,
    deleteRun,
  }) => {
    const {
      id,
      name,
      displayName,
      user,
      state,
      createdAt,
      tags,
      notes,
      commit,
      github,
      host,
      benchmarkRun,
      sweep,
      runInfo,
      jobType,
      group,
    } = run;

    const wandb = config._wandb as any;

    let cliVersion;
    let spellUrl;
    let startUTCSeconds;
    let runIsOffline = false;
    if (wandb && wandb.value) {
      cliVersion = wandb.value.cli_version;
      spellUrl = wandb.value.spell_url;
      startUTCSeconds = wandb.value.start_time;
      runIsOffline = isOffline(wandb.value);
    }

    let createdTimeValue = createdAt;
    if (runIsOffline && startUTCSeconds) {
      createdTimeValue = new Date(startUTCSeconds * 1000)
        .toISOString()
        .split('.')[0];
    }

    const createdTime = [
      moment(createdTimeValue + 'Z').format('MMMM Do, YYYY'),
      'at',
      moment(createdTimeValue + 'Z').format('h:mm:ss a'),
    ].join(' ');
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const duration = getRunDuration(run, runIsOffline);

    const readOnly = !viewer || project.readOnly;

    const hasGitHub = commit && github;
    const codeIcon = hasGitHub ? 'github' : 'code';

    let benchmarkSummaryMetrics;
    if (benchmarkRun) {
      benchmarkSummaryMetrics = JSONparseNaN(benchmarkRun.run.summaryMetrics);
    }

    const submitToBenchmarkButton = (publish?: boolean) => {
      return (
        <SubmitToBenchmarkModal
          runName={displayName}
          runSummary={summaryMetrics}
          runId={id}
          run={run}
          trigger={
            <Button
              size="tiny"
              className="wb-icon-button submit-to-benchmark-button"
              primary
              disabled={state === 'running'}>
              <LegacyWBIcon name="benchmark" />
              {publish
                ? 'Publish to Github'
                : `Submit to ${project.linkedBenchmark.name}`}
            </Button>
          }
          benchmarkMeta={project.linkedBenchmark}
          viewer={viewer}
          project={project}
          history={history}
        />
      );
    };
    const systemStats = [runInfo?.gpuCount, runInfo?.gpu, runInfo?.cpuCount];
    const applySystemHardwareStyles = _.compact(systemStats).length > 1;
    return (
      <div className="run-overview">
        <div className="run-overview-top">
          {project.isBenchmark && benchmarkRun && (
            <BenchmarkApproval
              summaryMetrics={benchmarkSummaryMetrics}
              benchmark={project}
              benchmarkRun={benchmarkRun}
            />
          )}
          <div className="mini-page-header">
            <div className="name-header-wrapper">
              <EditableField
                className="name-header"
                readOnly={readOnly}
                asHeader="h1"
                placeholder={name}
                showEditIcon
                value={displayName}
                save={value => {
                  updateRun({displayName: value});
                }}
              />
              <Dropdown
                className="dropdown-menu-icon-button"
                floating
                trigger={
                  <Button size="tiny" className="wb-icon-button only-icon">
                    <LegacyWBIcon name="overflow" />
                  </Button>
                }
                direction="left">
                <Dropdown.Menu>
                  <Modal
                    on="click"
                    // onMount={() => setTimeout(() => window.Prism.highlightAll(), 1)}
                    trigger={
                      <Dropdown.Item icon="database" content="Export Data" />
                    }>
                    <Modal.Header>
                      <h1>Python API</h1>
                    </Modal.Header>
                    <Modal.Content>
                      <h4>Create the run object:</h4>
                      <pre className="language-python" style={{padding: '0em'}}>
                        {`import wandb
api = wandb.Api()
run = api.run("${urls.run({
                          entityName: project.entityName,
                          projectName: project.name,
                          name,
                        })}")`}
                      </pre>
                      <h4>Show history data:</h4>
                      <pre className="language-python">{`print(run.history())`}</pre>
                      <p style={{marginTop: 20}}>
                        Check out the{' '}
                        <TargetBlank href={docUrl.api}>API docs</TargetBlank>{' '}
                        for more information.
                      </p>
                    </Modal.Content>
                  </Modal>
                  <Modal
                    on="click"
                    trigger={
                      <Dropdown.Item icon={codeIcon} content="View Code" />
                    }>
                    <Modal.Header>
                      <h1>View Code</h1>
                    </Modal.Header>
                    <Modal.Content>
                      <Message icon>
                        {<Icon name={codeIcon} />}
                        <Message.Content>
                          <p>
                            Wandb will automatically save the git commit ID of
                            the last commit before each of your runs.
                          </p>
                          {hasGitHub ? (
                            <React.Fragment>
                              <p>
                                You can find your commit{' '}
                                <TargetBlank href={github}>
                                  here on GitHub
                                </TargetBlank>
                                .
                              </p>
                            </React.Fragment>
                          ) : runInfo && runInfo.git ? (
                            <table className="run-page-table">
                              <tr>
                                <td className="key">
                                  <span className="overview-key">
                                    Git remote
                                  </span>
                                </td>
                                <td className="command">
                                  {runInfo.git.remote
                                    ? runInfo.git.remote
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="key">
                                  <span className="overview-key">
                                    Git commit
                                  </span>
                                </td>
                                <td className="command">
                                  {runInfo.git.commit
                                    ? runInfo.git.commit
                                    : '-'}
                                </td>
                              </tr>
                            </table>
                          ) : (
                            <p>
                              However, we couldn't find a git commit for this
                              particular run.
                            </p>
                          )}
                          {RunHelpers.hasDiffPatchFile(run) && (
                            <p>
                              Your uncommitted changes were saved in file called{' '}
                              <strong>diff.patch</strong>, which you can
                              download{' '}
                              <Link
                                to={urls.runDiffPatchFile({
                                  entityName: project.entityName,
                                  projectName: project.name,
                                  runName: name,
                                })}>
                                here
                              </Link>
                            </p>
                          )}
                        </Message.Content>
                      </Message>
                    </Modal.Content>
                  </Modal>
                  {!readOnly && (
                    <>
                      <Dropdown.Divider />
                      {isDeleteModalOpen && (
                        <DeleteRunModal
                          entityName={project.entityName}
                          projectName={project.name}
                          runDisplayName={displayName}
                          runName={name}
                          onModalCanceled={() => setDeleteModalOpen(false)}
                          onDeleteRun={deleteArtifacts => {
                            deleteRun(id, deleteArtifacts);
                            setDeleteModalOpen(false);
                          }}
                        />
                      )}
                      <Dropdown.Item
                        icon="trash"
                        content="Delete run"
                        onClick={() => setDeleteModalOpen(true)}
                      />
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <EditableField
              className="description"
              readOnly={readOnly}
              multiline
              showEditIcon
              renderLinks
              placeholder={'What makes this run special?'}
              value={notes}
              save={value => {
                updateRun({notes: value});
              }}
            />
          </div>
          {project.isBenchmark && benchmarkRun && (
            // This run is a submitted copy
            <div className="overview-item">
              <div className="overview-key">Source</div>
              <div className="overview-value">
                <Dropdown
                  simple
                  className="dropdown-menu-icon-button benchmark-dropdown"
                  icon={null}
                  disabled={!benchmarkRun.originalProject}
                  trigger={
                    <>
                      <LegacyWBIcon name="folder" />
                      Submitted from{' '}
                      {benchmarkRun.originalProject
                        ? benchmarkRun.originalProject.name
                        : 'deleted project'}
                      <LegacyWBIcon name="chevron-expanded" />
                    </>
                  }>
                  <Dropdown.Menu>
                    {benchmarkRun.originalProject && (
                      <Link
                        className="item"
                        to={urls.benchmarkOriginalProject(benchmarkRun)}>
                        Visit original project
                      </Link>
                    )}
                    {benchmarkRun.originalRun && (
                      <Link
                        className="item"
                        to={urls.benchmarkOriginalRunOverview(benchmarkRun)}>
                        Visit original run
                      </Link>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          {project.linkedBenchmark && viewer && (
            // This run has a submitted copy
            <div className="overview-item">
              <div className="overview-key">Benchmark</div>
              <div className="overview-value">
                {benchmarkRun ? (
                  project.linkedBenchmark.gitHubSubmissionRepo &&
                  !benchmarkRun.gitHubSubmissionPR ? (
                    // is halfway through submission process to github backed benchmark
                    submitToBenchmarkButton(true)
                  ) : (
                    // is fully submitted
                    <Dropdown
                      simple
                      className="dropdown-menu-icon-button benchmark-dropdown"
                      icon={null}
                      trigger={
                        <>
                          <LegacyWBIcon name="benchmark" />
                          {(benchmarkRun.state === 'ACCEPTED'
                            ? 'Accepted by '
                            : benchmarkRun.state === 'REJECTED'
                            ? 'Rejected by '
                            : 'Submitted to ') + project.linkedBenchmark.name}
                          <LegacyWBIcon name="chevron-expanded" />
                        </>
                      }>
                      <Dropdown.Menu>
                        <Link
                          className="item"
                          to={urls.benchmarkProject(project.linkedBenchmark)}>
                          Visit benchmark
                        </Link>
                        <Link
                          className="item"
                          to={urls.benchmarkRunOverview(benchmarkRun)}>
                          Visit submitted copy
                        </Link>
                        {benchmarkRun && benchmarkRun.gitHubSubmissionPR && (
                          <TargetBlank
                            className="item"
                            href={benchmarkRun.gitHubSubmissionPR}>
                            View GitHub pull request
                          </TargetBlank>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                ) : (
                  // never started submitting
                  submitToBenchmarkButton(false)
                )}
              </div>
            </div>
          )}
          <div className="overview-item">
            <div className="overview-key">Privacy</div>
            <div className="overview-value">
              <ProjectAccess
                project={project}
                updateProjectAccess={(access: AccessOptions) =>
                  updateProject({
                    access,
                  })
                }
              />
            </div>
          </div>
          {(tags.length > 0 || !readOnly) && (
            <div className="overview-item">
              <div className="overview-key">Tags</div>
              <div className="overview-value">
                <div className="tags-button-wrapper">
                  <Tags
                    tags={tags}
                    enableDelete={true}
                    deleteTag={tag => {
                      updateRun({
                        tags: tags.map(t => t.name).filter(n => n !== tag.name),
                      });
                    }}
                  />
                  {!readOnly && (
                    <TagAddButton
                      compact
                      direction="bottom right"
                      tags={tags}
                      availableTags={project.tags}
                      addTag={async tag => {
                        if (
                          !_.includes(
                            tags.map(t => t.name),
                            tag.name
                          )
                        ) {
                          updateRun({
                            tags: [...tags.map(t => t.name), tag.name],
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="overview-item">
            <div className="overview-key">Author</div>
            <div className="overview-value">
              <Link className="user-link" to={`/${user.username}`}>
                <Image
                  src={user.photoUrl}
                  avatar
                  onError={(i: any) => (i.target.style.display = 'none')}
                />
                {user.username}
              </Link>
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">State</div>
            <div className="overview-value">
              <RunStateIndicator run={run} readOnly={readOnly} />
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Start time</div>
            <div className="overview-value">{createdTime}</div>
          </div>
          {sweep != null && (
            <div className="overview-item">
              <div className="overview-key">Sweep</div>
              <Link
                to={urls.sweep({
                  entityName: project.entityName,
                  projectName: project.name,
                  sweepName: sweep.name,
                })}>
                <div className="overview-value">{sweep.name}</div>
              </Link>
            </div>
          )}
          <div className="overview-item">
            <div className="overview-key">Duration</div>
            <div className="overview-value">{duration}</div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Run path</div>
            <div className="overview-value">
              <CopyableText
                className="run-path"
                text={`${project.entityName}/${project.name}/${name}`}
                toastText="Successfully copied run path"
              />
            </div>
          </div>
          {host !== null && (
            <div className="overview-item">
              <div className="overview-key">Hostname</div>
              <div className="overview-value">{host}</div>
            </div>
          )}
          {runInfo && (
            <>
              <div className="overview-item">
                <div className="overview-key">OS</div>
                <div className="overview-value">{runInfo.os || '-'}</div>
              </div>
              <div className="overview-item">
                <div className="overview-key">Python version</div>
                <div className="overview-value">{runInfo.python || '-'}</div>
              </div>
              {runInfo.executable && (
                <div className="overview-item">
                  <div className="overview-key">Python executable</div>
                  <div className="overview-value">{runInfo.executable}</div>
                </div>
              )}
              {runInfo.colab && (
                <div className="overview-item">
                  <div className="overview-key">Colab link</div>
                  <div className="overview-value">
                    <TargetBlank href={runInfo.colab}>
                      {runInfo.colab}
                    </TargetBlank>
                  </div>
                </div>
              )}
              {runInfo.git && (
                <>
                  <div className="overview-item">
                    <div className="overview-key">Git repository</div>
                    <div className="overview-value command">
                      git clone {runInfo.git.remote}
                    </div>
                  </div>
                  <div className="overview-item">
                    <div className="overview-key">Git state</div>
                    <div className="overview-value command">
                      git checkout -b "{sanitizeBranchName(displayName, '-')}"{' '}
                      {runInfo.git.commit}
                    </div>
                  </div>
                </>
              )}
              <div className="overview-item">
                <div className="overview-key">Command</div>
                <div className="overview-value command">
                  {runInfo.program ? (
                    <div>
                      {runInfo.program +
                        ' ' +
                        (runInfo.args && !runInfo.program.includes('.ipynb')
                          ? runInfo.args
                              .map(a => {
                                // Quote the argument
                                if (a.match(/[\s|&<>]/g)) {
                                  let prefix = '';
                                  // Check if the arg is like --foo=bla bla
                                  if (a.match(/^[\w-]+=/)) {
                                    const parts = a.split('=');
                                    prefix = parts.shift() + '=';
                                    a = parts.join('=');
                                  }
                                  return `${prefix}"${a.replace(/"/g, '\\"')}"`;
                                }
                                return a;
                              })
                              .join(' ')
                          : '')}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {(runInfo.cpuCount || runInfo.gpuCount || runInfo.gpu) && (
                <div
                  style={
                    applySystemHardwareStyles
                      ? {alignItems: 'flex-start', marginTop: '4px'}
                      : undefined
                  }
                  className="overview-item">
                  <div className="overview-key">System Hardware</div>
                  <div className="overview-value">
                    <table className="system-table">
                      <tbody>
                        {runInfo.cpuCount && (
                          <tr>
                            <td className="key">CPU count</td>
                            <td className="val">{runInfo.cpuCount}</td>
                          </tr>
                        )}
                        {runInfo.gpuCount && (
                          <tr>
                            <td className="key">GPU count</td>
                            <td className="val">{runInfo.gpuCount}</td>
                          </tr>
                        )}
                        {runInfo.gpu && (
                          <tr>
                            <td className="key">GPU type</td>
                            <td className="val">{runInfo.gpu}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
          {cliVersion && (
            <div className="overview-item">
              <div className="overview-key">W&amp;B CLI Version</div>
              <div className="overview-value">{cliVersion}</div>
            </div>
          )}
          {spellUrl && (
            <div className="overview-item">
              <div className="overview-key">Spell Run</div>
              <div className="overview-value">
                <TargetBlank href={`${spellUrl}`}>{spellUrl}</TargetBlank>
              </div>
            </div>
          )}
          {group && (
            <div className="overview-item">
              <div className="overview-key">Group</div>
              <div className="overview-value">{group}</div>
            </div>
          )}
          {jobType && (
            <div className="overview-item">
              <div className="overview-key">Job Type</div>
              <div className="overview-value">{jobType}</div>
            </div>
          )}
        </div>
        <Grid stackable className="config-and-summary">
          <RunArtifactGrid
            entityName={project.entityName}
            projectName={project.name}
            runName={run.name}
          />
          <Grid.Row columns={2}>
            <Grid.Column>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}>
                <Header>Config</Header>
                <Button
                  size="tiny"
                  floated="right"
                  title="View raw config"
                  onClick={() =>
                    openJSONNewTab(config, `Config | ${name}`, 'config')
                  }>
                  Raw
                </Button>
              </span>
              <p className="hint-text">
                Config parameters describe your model's inputs.{' '}
                <TargetBlank href={docUrl.config}>Learn more</TargetBlank>
              </p>
              {<RunConfigTable config={config} />}
            </Grid.Column>
            <Grid.Column>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}>
                <Header>Summary</Header>
                <Button
                  size="tiny"
                  floated="right"
                  title="View raw summary"
                  onClick={() =>
                    openJSONNewTab(
                      summaryMetrics,
                      `Summary | ${name}`,
                      'summary'
                    )
                  }>
                  Raw
                </Button>
              </span>
              <p className="hint-text">
                Summary metrics describe your results.{' '}
                <TargetBlank href={docUrl.summaryMetric}>
                  Learn more
                </TargetBlank>
              </p>
              {_.keys(summaryMetrics).length === 0 ? (
                <Segment>
                  <p>No summary metrics saved for this run.</p>
                  <p>
                    Check the{' '}
                    <TargetBlank href={docUrl.summaryMetric}>
                      summary metrics documentation
                    </TargetBlank>{' '}
                    for more information.
                  </p>
                </Segment>
              ) : (
                <RunSummaryTable summary={summaryMetrics} />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  },
  {id: 'RunOverview', memo: true}
);

export default RunOverview;
