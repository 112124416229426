import '../css/LongformMarkdown.less';

import React, {useEffect} from 'react';

import Markdown from './Markdown';
import makeComp from '../util/profiler';

interface LongformMarkdownProps {
  content: string;
}

const LongformMarkdown: React.FC<LongformMarkdownProps> = makeComp(
  props => {
    const [headers, setHeaders] = React.useState<HTMLHeadingElement[] | null>(
      null
    );
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [wrapperEl, setWrapperEl] = React.useState<HTMLElement | null>(null);
    useEffect(() => {
      window.setTimeout(() => {
        if (wrapperEl) {
          let heads = wrapperEl.getElementsByTagName('h1');
          if (heads.length === 0) {
            heads = wrapperEl.getElementsByTagName('h2');
          }
          setHeaders(Array.from(heads));
        }
      });
    }, [wrapperEl, props.content]);

    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    });

    const onScroll = () => {
      if (headers) {
        let lowestScrollPastIndex = 0;
        headers.forEach((header, i) => {
          // consider to be in a section when the header is above
          // 64px below the top of the screen
          if (window.scrollY >= header.offsetTop - 64) {
            lowestScrollPastIndex = i;
          }
        });
        if (activeIndex !== lowestScrollPastIndex) {
          setActiveIndex(lowestScrollPastIndex);
        }
      }
    };

    return (
      <div className="longform-markdown" ref={node => setWrapperEl(node)}>
        <div className="toc">
          {headers &&
            headers.map((header, i) => {
              return (
                <div
                  key={i}
                  className={'toc-item' + (i === activeIndex ? ' active' : '')}
                  onClick={() => {
                    window.scrollTo({
                      top: header.offsetTop - 14,
                      behavior: 'smooth',
                    });
                    header.style.animation = 'none';
                    // read offsetWidth to force reflow to replay animation
                    // tslint:disable-next-line:no-unused-expression
                    void header.offsetWidth;
                    header.style.animation = 'header-flash 2s';
                  }}>
                  {header.textContent}
                </div>
              );
            })}
        </div>
        <Markdown condensed={false} content={props.content} />
      </div>
    );
  },
  {id: 'LongformMarkdown'}
);

export default LongformMarkdown;
