import {
  Plan,
  useSubscriptionPlansQuery,
  Organization,
  PlanType,
  OrganizationSubscription,
  OrganizationSubscriptionType,
  OrgType,
} from '../generated/graphql';
import WandbLoader from '../components/WandbLoader';
import {Elements} from '@stripe/react-stripe-js';
import {Stripe} from '@stripe/stripe-js';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import makeComp from './profiler';
import config from '../config';
import {propagateErrorsContext} from './errors';
import {DeepPartial} from '../types/base';
import {isOneOf} from './utility';

export type CheckoutPlan = {id: string; billingInterval: string};

export type OrganizationFlags = {
  noContact?: boolean;
};

export type Org = {
  id: string;
  name: string;
  memberCount: number;
  teams: Team[];
  flags?: OrganizationFlags;
};

export type Team = {id: string; name: string};

export type PlanClientData = {
  title: string;
  subtitle: string;
  features: string[];
  planType: 'free' | 'stripe' | 'enterprise';
  dbName: string | null;
};

export type PlanInfo = PlanClientData & {
  monthlyPlan: Plan | null;
  yearlyPlan: Plan | null;
};
export type PlanInfoWithActualPlans = PlanClientData & {
  monthlyPlan: Plan;
  yearlyPlan: Plan;
};

export const STANDARD_PLAN: PlanClientData = {
  planType: 'stripe',
  dbName: 'standard',
  title: 'Upgrade to Standard Plan',
  subtitle: 'Standard plan',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const LEGACY_PLANS: PlanClientData[] = [
  {
    planType: 'free',
    dbName: null,
    title: 'Personal',
    subtitle: 'W&B basics for every practitioner',
    features: [
      'Unlimited public & private projects',
      'Public forum support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'startup',
    title: 'Startup',
    subtitle: 'Private collaboration on a small scale for pre-funded startups',
    features: [
      'Up to 3 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'teams',
    title: 'Teams',
    subtitle: 'Essential management and security for small teams',
    features: [
      'Up to 5 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
      'Service accounts',
    ],
  },
  {
    planType: 'enterprise',
    dbName: null,
    title: 'Enterprise',
    subtitle: 'Security, compliance, and flexible deployment',
    features: [
      'Unlimited private projects',
      'Single sign-on enabled',
      'Unlimited data storage',
      'Unlimited data retention',
      'Dedicated customer success',
      'Support & Service SLAs',
      'Professional services available',
      'On-Prem available',
      'Hyperparameter optimization tools',
    ],
  },
];

export function orgSeatsRemaining(
  org: DeepPartial<Organization>
): number | null {
  const {subscriptions, members, pendingInvites} = org;
  if (subscriptions == null || members == null || pendingInvites == null) {
    return null;
  }

  const primarySub = getPrimarySub(org);
  const seatCount = primarySub?.seats;
  if (seatCount == null) {
    return null;
  }

  // TODO: This role field should have an enum typpe.
  const memberCount = members.filter(m => m?.role !== 'billing-only').length;
  const inviteCount = pendingInvites.length;

  return seatCount - (memberCount + inviteCount);
}

export function orgPrimarySubUnitPrice(
  org: DeepPartial<Organization>
): number | null {
  const primarySub = getPrimarySub(org);
  const unitPrice = primarySub?.plan?.unitPrice;
  if (unitPrice == null) {
    return null;
  }

  return unitPrice / 100;
}

export function orgPrimarySubBillingInterval(
  org: DeepPartial<Organization>
): string | null {
  const primarySub = getPrimarySub(org);
  return primarySub?.plan?.billingInterval ?? null;
}

function getPrimarySub(
  org: DeepPartial<Organization>
): DeepPartial<OrganizationSubscription> | null {
  return (
    org?.subscriptions?.find(s => s?.plan?.planType === PlanType.Primary) ??
    null
  );
}

let globalStripePromise: Promise<Stripe | null> | null = null;

export const StripeElements: React.FC = makeComp(
  ({children}) => {
    const [stripePromise, setStripePromise] =
      useState<Promise<Stripe | null> | null>(globalStripePromise);

    useEffect(() => {
      if (globalStripePromise != null) {
        return;
      }
      if (config.ENVIRONMENT_IS_PRIVATE || config.ENVIRONMENT_NAME === 'test') {
        return;
      }
      const stripeApiKey = config.STRIPE_API_KEY;
      if (stripeApiKey == null) {
        throw new Error('Stripe API key not set!');
      }

      import('@stripe/stripe-js').then(module => {
        const stripeLoadResult = module.loadStripe(stripeApiKey);
        setStripePromise(stripeLoadResult);
        globalStripePromise = stripeLoadResult;
      });
    }, []);

    if (stripePromise == null) {
      return <WandbLoader />;
    }

    return <Elements stripe={stripePromise}>{children}</Elements>;
  },
  {
    id: 'StripeElements',
    memo: true,
  }
);

type SubscriptionPlansResult = {
  loading: boolean;
  planInfo: PlanInfoWithActualPlans | null;
  storagePlanID: string | null;
  trackingPlanID: string | null;
};

export function useSubscriptionPlans(): SubscriptionPlansResult {
  const {loading, data} = useSubscriptionPlansQuery({
    context: propagateErrorsContext(),
  });

  const serverPlans = useMemo(() => data?.plans ?? [], [data]);

  const getServerPlan = useCallback(
    (serverPlanName: string) =>
      serverPlans.find(p => p?.name === serverPlanName) ?? null,
    [serverPlans]
  );

  const planInfo = useMemo(() => {
    const {dbName} = STANDARD_PLAN;
    const monthlyPlan = getServerPlan(`${dbName}_monthly`);
    const yearlyPlan = getServerPlan(`${dbName}_yearly`);
    if (monthlyPlan == null || yearlyPlan == null) {
      return null;
    }
    return {...STANDARD_PLAN, monthlyPlan, yearlyPlan};
  }, [getServerPlan]);

  const storagePlanID = useMemo(
    () => getServerPlan('storage_monthly')?.id ?? null,
    [getServerPlan]
  );

  const trackingPlanID = useMemo(
    () => getServerPlan('tracking_monthly')?.id ?? null,
    [getServerPlan]
  );

  return {loading, planInfo, storagePlanID, trackingPlanID};
}

export function isPersonalOrg({orgType}: DeepPartial<Organization>): boolean {
  return orgType === OrgType.Personal;
}

export function isNonPersonalAcademicOrg({
  orgType,
  subscriptions,
}: DeepPartial<Organization>): boolean {
  return (
    orgType === OrgType.Organization &&
    subscriptions?.[0]?.subscriptionType ===
      OrganizationSubscriptionType.Academic
  );
}

export function isNonPersonalTrialOrg({
  orgType,
  stripeBillingInfo,
  subscriptions,
}: DeepPartial<Organization>): boolean {
  if (orgType !== OrgType.Organization) {
    return false;
  }

  if (stripeBillingInfo?.status === 'trialing') {
    return true;
  }

  return isOneOf(subscriptions?.[0]?.subscriptionType, [
    OrganizationSubscriptionType.ManualTrial,
    OrganizationSubscriptionType.UserLedTrial,
    OrganizationSubscriptionType.AcademicTrial,
  ]);
}

export function isNonPersonalPaidOrg(org: DeepPartial<Organization>): boolean {
  return (
    org.orgType === OrgType.Organization &&
    !isNonPersonalAcademicOrg(org) &&
    !isNonPersonalTrialOrg(org)
  );
}
