import styled, {css} from 'styled-components';

export const Container = styled.div<{selected: boolean; loaded: boolean}>`
  width: min(calc(100vw - 64px), 550px);
  margin: 0 auto;
  border-radius: 15px;
  visibility: ${props => (props.loaded ? 'visible' : 'hidden')};

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;
