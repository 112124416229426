import * as _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';
import {useState} from 'react';
import {Button, Divider, Checkbox, Popup} from 'semantic-ui-react';
import * as Filter from '../../../util/filters';
import {maybePluralize} from '../../../util/uihelpers';
import FilterList from '../../Filters/FilterList';
import {FilterKeySelectorCreatorProps} from '../../Filters/FilterKeySelector';
import {FilterValueSelectorCreatorProps} from '../../Filters/FilterValueSelector';

import LegacyWBIcon from '../../elements/LegacyWBIcon';
import {usePrevious} from '../../../state/hooks';
import makeComp from '../../../util/profiler';

// Count of the active filters for the root filter\
export const activeFilterCount = (filter: Filter.RootFilter) => {
  if (filter.op === 'OR' && filter.filters) {
    return filter.filters[0].filters.filter(
      // TODO: display compound filters
      f => !f.disabled && Filter.isIndividual(f)
    ).length;
  } else {
    return 0;
  }
};

function filterCompare(v: Filter.Filter, e: Filter.Filter) {
  return _.isEqual(_.omit(v, ['disabled']), _.omit(e, ['disabled']));
}

// Finds the index of the individual filter in the group filter
// NOTE: 1 depth of children only.
const filterIndex = (
  filters: Filter.GroupFilter,
  checkFilter: Filter.IndividualFilter
) => {
  if (filters.filters) {
    const index = _.findIndex(filters.filters, filter =>
      _.isEqualWith(filter, checkFilter, filterCompare)
    );
    return index;
  } else {
    return -1;
  }
};

// Checks if a group filter has a direct child that
// matches the individual filter that is enabled.
const hasEnabledFilter = (
  filters: Filter.GroupFilter,
  checkFilter: Filter.IndividualFilter
) => {
  const index = filterIndex(filters, checkFilter);
  if (index === -1) {
    return false;
  }

  return !filters.filters[index].disabled;
};

interface DFTableActionFilterButtonProps {
  className?: string;
  compact?: boolean;
  filtersOpen: boolean;
  filters: Filter.RootFilter;
  onClick?(): void;
}

export const DFTableActionFilterButton = makeComp(
  (props: DFTableActionFilterButtonProps) => {
    const {filters} = props;
    let filterButtonClass;
    const filterCount = activeFilterCount(filters);

    const passThroughProps = _.omit(props, 'compact', 'filtersOpen', 'filters');

    if (props.filtersOpen) {
      filterButtonClass = 'action-button--focused';
    } else if (filterCount > 0) {
      filterButtonClass = 'action-button--active';
    } else {
      filterButtonClass = 'action-button--static';
    }
    const buttonText =
      filterCount > 0 ? maybePluralize(filterCount, 'Filter') : 'Filter';

    // Show notification when filters are changed outside of the filter popup (e.g. parcoor selections-to-filters)
    const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
    const firstRender = React.useRef(true);
    const prevFilterCount = usePrevious(filterCount);
    const prevFiltersOpen = usePrevious(props.filtersOpen);
    React.useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
      } else if (
        !props.filtersOpen &&
        !prevFiltersOpen &&
        prevFilterCount !== filterCount
      ) {
        setNotificationPopupOpen(true);
        setTimeout(() => {
          setNotificationPopupOpen(false);
        }, 8000);
      }
    }, [filterCount, prevFilterCount, props.filtersOpen, prevFiltersOpen]);

    return (
      <Popup
        open={notificationPopupOpen}
        position="bottom left"
        pinned
        style={{top: 24, marginLeft: -3, padding: '4px 8px', cursor: 'pointer'}}
        inverted
        onClick={() => setNotificationPopupOpen(false)}
        trigger={
          <Button
            {...passThroughProps}
            data-test="filter-popup"
            size="tiny"
            onClick={() => {
              setNotificationPopupOpen(false);
              if (passThroughProps.onClick) {
                passThroughProps.onClick();
              }
            }}
            className={classNames(
              props.className,
              filterButtonClass,
              'wb-icon-button',
              'table-filter-button'
            )}>
            <LegacyWBIcon name="filter" title={buttonText} />
            {props.compact ? '' : buttonText}
          </Button>
        }
        content={'Filters changed'}
      />
    );
  },
  {id: 'DFTableActionFilterButton'}
);

interface DFTableActionFilterPickerProps {
  filters: Filter.RootFilter;

  defaultToggleFilters?: Filter.DefaultToggleFilter[];

  setFilters(filters: Filter.RootFilter): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;

  filterValueSelector(props: FilterValueSelectorCreatorProps): React.ReactNode;
}

export const DFTableActionFilterPicker = makeComp(
  (props: DFTableActionFilterPickerProps) => {
    const {filters, defaultToggleFilters} = props;

    return (
      <div className="filter-popup">
        {filters.filters.map((filter, i) => (
          <FilterList
            key={i}
            filters={filter}
            canAdd={true}
            index={i}
            pushFilter={(newFilter: Filter.Filter) => {
              props.setFilters(
                Filter.Update.groupPush(filters, [i], newFilter)
              );
            }}
            deleteFilter={(index: number) =>
              props.setFilters(Filter.Update.groupRemove(filters, [i], index))
            }
            setFilter={(index: number, f: Filter.Filter) =>
              props.setFilters(Filter.Update.setFilter(filters, [i, index], f))
            }
            filterKeySelector={props.filterKeySelector}
            filterValueSelector={props.filterValueSelector}
          />
        ))}
        {defaultToggleFilters && (
          <>
            <Divider />
            {defaultToggleFilters.map((defaultFilter, i) => (
              <div key={i} className="filter-list__toggle">
                <span>{defaultFilter.label}</span>
                <Checkbox
                  className="filter-list__action"
                  toggle
                  checked={hasEnabledFilter(
                    filters.filters[0],
                    defaultFilter.filter
                  )}
                  onChange={() => {
                    const index = filterIndex(
                      filters.filters[0],
                      defaultFilter.filter
                    );
                    const filter = filters.filters[0].filters[index];

                    if (index === -1) {
                      props.setFilters(
                        Filter.Update.groupPush(
                          filters,
                          [0],
                          defaultFilter.filter
                        )
                      );
                    } else {
                      props.setFilters(
                        Filter.Update.setFilter(filters, [0, index], {
                          ...defaultFilter.filter,
                          disabled: !filter.disabled,
                        } as Filter.IndividualFilter)
                      );
                    }
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>
    );
  },
  {id: 'DFTableActionFilterPicker'}
);

interface DFTableActionFilterProps {
  trigger: (isOpen: boolean) => React.ReactNode;
  content: React.ReactNode;
}

export const DFTableActionFilter = makeComp(
  (props: DFTableActionFilterProps) => {
    const [open, setOpen] = useState(false);
    return (
      <Popup
        basic
        className="df-table-action-popup"
        on="click"
        position="bottom left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={props.trigger(open)}
        content={props.content}
        popperModifiers={{
          preventOverflow: {enabled: false},
          flip: {enabled: false},
        }}
      />
    );
  },
  {id: 'DFTableActionFilter'}
);
