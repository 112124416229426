import styled from 'styled-components';
import {Button} from 'semantic-ui-react';
import LegacyWBIcon from '../../elements/LegacyWBIcon';

export const SortPickerRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const SortPickerRowClose = styled(LegacyWBIcon).attrs({
  name: 'close',
})<{hidden: boolean}>`
  &&&&& {
    margin-right: 8px;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.6;
    ${({hidden}) => hidden && 'visibility: hidden;'}

    &:hover {
      opacity: 1;
    }
  }
`;

export const SortPickerButton = styled(Button)`
  &&&&& {
    margin-left: 8px;
  }
`;

export const SortPickerActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  margin-left: 29px;
`;

export const SortPickerAction = styled.div`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;
