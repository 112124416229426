import * as React from 'react';
import {Dropdown, Modal, Tab, Image} from 'semantic-ui-react';

import {useApolloClient, useSelector} from '../state/hooks';
import {ReportViewRef} from '../state/reports/types';
import printImg from '../assets/print.png';
import makeComp from '../util/profiler';

interface ReportExportModalProps {
  entityName: string;
  projectName: string;
  onLoadingChange?: (loading: boolean) => void;
  reportViewRef: ReportViewRef | null;
  open: boolean;
  onClose: () => void;
}

const ReportExportModal: React.FC<ReportExportModalProps> = makeComp(
  props => {
    const {entityName, projectName, reportViewRef, onLoadingChange} = props;
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
      if (onLoadingChange) {
        onLoadingChange(loading);
      }
    }, [loading, onLoadingChange]);
    const client = useApolloClient();
    const viewsState = useSelector(state => state.views);
    React.useEffect(() => {
      // We need to remove our custom classes when the print dialog closes.
      const mediaScreen = window.matchMedia('screen');
      const mediaListenerScreen = function (
        this: MediaQueryList,
        mql: MediaQueryListEvent
      ) {
        if (mql.matches) {
          const reportPage = document.querySelector('.report-page');
          if (reportPage) {
            reportPage.classList.remove('report-width-print-landscape');
            reportPage.classList.remove('report-width-print-portrait');
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 500);
          }
        }
      };
      mediaScreen.addListener(mediaListenerScreen);
      return () => {
        mediaScreen.removeListener(mediaListenerScreen);
      };
    });
    const downloadLaTeX = async (
      template: 'default' | 'reproducibilityChallenge'
    ) => {
      if (reportViewRef != null) {
        setLoading(true);
        window.analytics.track('Downloaded LaTeX');
        const ReportExport = await import('../util/reportExport');
        await ReportExport.exportReportAsLaTeX({
          entityName,
          projectName,
          client,
          viewsState,
          reportViewRef,
          template,
        });
        setLoading(false);
        props.onClose();
      }
    };
    function print(mode: 'landscape' | 'portrait') {
      setLoading(true);
      document
        .querySelector('.report-page')
        ?.classList.add('report-width-print-' + mode);
      let style = document.querySelector('#print-page');
      if (style == null) {
        style = document.createElement('style');
        style.id = 'print-page';
        document.head.appendChild(style);
      }
      // Couldn't find a way to do this in pure CSS :(
      if (mode === 'landscape') {
        style.innerHTML = '@page {size: 11in 8.5in}';
      } else {
        style.innerHTML = '@page { size: 8.5in 11in }';
      }

      // TODO: 100ms isn't enough, is 500ms the magic number?
      setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
      setTimeout(() => {
        props.onClose();
        setLoading(false);
      }, 1000);
      // Let our popup close and resize events dispatch
      setTimeout(() => window.print(), 1500);
    }
    const panes = [
      {
        menuItem: 'PDF',
        render: () => (
          <Tab.Pane attached={false}>
            <Image src={printImg} size="medium" rounded floated="left" />
            <p>
              Select landscape or portrait below to open your system print
              dialog.
            </p>
            <p>
              Choose "Save as PDF" from Destination and ensure you un-select
              "Headers and footers" under "More settings".
            </p>
            <Dropdown
              text="Print PDF"
              button
              simple
              item
              loading={loading}
              options={[
                {
                  key: 'portrait',
                  text: 'Portrait',
                  onClick: () => print('portrait'),
                },
                {
                  key: 'landscape',
                  text: 'Landscape',
                  onClick: () => print('landscape'),
                },
              ]}
            />
            <br style={{clear: 'both'}} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'LaTeX',
        render: () => (
          <Tab.Pane attached={false}>
            <p>
              Clicking "Generate LaTeX" will generate a zip file that can be
              uploaded to a service such as{' '}
              <a href="https://overleaf.com">Overleaf</a> for further editing.
            </p>
            <Dropdown
              text="Generate laTeX"
              button
              simple
              item
              loading={loading}
              options={[
                {
                  key: 'default',
                  text: 'Default Template',
                  onClick: () => downloadLaTeX('default'),
                },
                {
                  key: 'reproducibilityChallenge',
                  text: 'Reproducibility Challenge Template',
                  onClick: () => downloadLaTeX('reproducibilityChallenge'),
                },
              ]}
            />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Modal open={props.open} onClose={props.onClose} size={'small'}>
        <Modal.Header>Download Report</Modal.Header>
        <Modal.Content>
          <Tab menu={{secondary: true, pointing: true}} panes={panes} />
        </Modal.Content>
      </Modal>
    );
  },
  {id: 'ReportExportModal'}
);

export default ReportExportModal;
