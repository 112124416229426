import '../css/PanelSettings.less';

import React, {useMemo} from 'react';
import _ from 'lodash';

import {
  Button,
  DropdownProps,
  Popup,
  StrictPopupProps,
} from 'semantic-ui-react';

import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as PlotHelpers from '../util/plotHelpers';
import SmoothingInput from './elements/SmoothingInput';
import RangeInput from './elements/RangeInput';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as RunHelpersUtil from '../util/runhelpers';
import ModifiedDropdown from './elements/ModifiedDropdown';

import {useKeyInfoQuery} from '../state/runs/hooks';
import * as ViewHooks from '../state/views/hooks';
import * as RunSetTypes from '../state/views/runSet/types';
import * as PanelTypes from '../state/views/panel/types';
import * as PanelSettingsActions from '../state/views/panelSettings/actions';
import {useDispatch} from '../state/hooks';
import {updateConfigs} from '../state/views/panel/actions';
import {PanelWithConfig} from '../util/panels';
import * as PanelRunsLinePlot from './PanelRunsLinePlot';
import {
  EMPTY_XAXIS_SETTINGS,
  EMPTY_SMOOTHING_SETTINGS,
  getPanelSettingsGroups,
  Settings,
} from '../util/panelsettings';
import makeComp from '../util/profiler';
import classNames from 'classnames';
import {SmoothingType} from '../util/math';
import {WBIcon} from '@wandb/ui';
import {DispatchableAction} from '../types/redux';
import {useWorkspaceAndLocalPanelSettingsAction} from '../state/views/panelSettings/hooks';

export interface LinePlotPanel
  extends PanelWithConfig<typeof PanelRunsLinePlot.PANEL_TYPE> {
  ref: PanelTypes.Ref;
}

interface PanelSettingsProps {
  popupPosition?: StrictPopupProps['position'];
  extraXAxisOptions?: string[];
  workspacePanelSettingsRef: PanelSettingsViewTypes.Ref;
  // If undefined, we don't suggest extra x-axis options (happens in
  // reports currently)
  runSetRefs?: RunSetTypes.Ref[];
  localPanelSettingsRef?: PanelSettingsViewTypes.Ref;

  reportSettings?: boolean;

  // This is an expensive hook to call! It can inspect the entire
  // panelbank config (huge if there are many metrics), or report.
  // So only call it when we need to (when the user has opened the
  // smoothing popup).
  useAllLinePlotPanels(): LinePlotPanel[];
  updateLocalAndWorkspaceSettings?(
    localPanelSettingsUpdate: Partial<Settings>,
    workspacePanelSettingsUpdate: Partial<Settings>
  ): DispatchableAction;
  resetAllLocals?(
    localPanelSettings: Partial<Settings>,
    workspacePanelSettings: Partial<Settings>
  ): void;
}

export const PanelSettingsComponent = makeComp(
  (props: PanelSettingsProps) => {
    const [settingsPopupOpen, setSettingsPopupOpen] = React.useState(false);
    return (
      <PanelSettingsComponentControlled
        {...props}
        settingsPopupOpen={settingsPopupOpen}
        setSettingsPopupOpen={setSettingsPopupOpen}
      />
    );
  },
  {id: 'PanelSettingsComponent'}
);

type LocalPanelSettingsProps = PanelSettingsControlledProps & {
  localPanelSettingsRef: PanelSettingsViewTypes.Ref;
};

export const LocalPanelSettingsComponentControlled = makeComp(
  (props: LocalPanelSettingsProps) => {
    const updateLocalAndWorkspaceSettings =
      useWorkspaceAndLocalPanelSettingsAction(
        props.localPanelSettingsRef,
        props.workspacePanelSettingsRef,
        PanelSettingsActions.updateLocalAndWorkspacePanelSettings
      );
    return (
      <PanelSettingsComponentControlled
        {...props}
        updateLocalAndWorkspaceSettings={updateLocalAndWorkspaceSettings}
      />
    );
  },
  {id: 'LocalPanelSettingsComponentControlled'}
);

type PanelSettingsControlledProps = PanelSettingsProps & {
  settingsPopupOpen: boolean;
  setSettingsPopupOpen(value: boolean): void;
};

const PanelSettingsComponentControlled = makeComp(
  (props: PanelSettingsControlledProps) => {
    const [resetAllLocalsClicked, setResetAllLocalsClicked] =
      React.useState(false);
    const [resetClicked, setResetClicked] = React.useState(false);
    const {
      popupPosition = 'bottom right',
      workspacePanelSettingsRef,
      useAllLinePlotPanels,
    } = props;

    const workspacePanelSettings = ViewHooks.useWhole(
      workspacePanelSettingsRef
    );
    const localPanelSettings = ViewHooks.useWholeMaybe(
      props.localPanelSettingsRef
    );

    const settings = localPanelSettings ?? workspacePanelSettings;

    const updateWorkspaceSettings = ViewHooks.useViewAction(
      workspacePanelSettingsRef,
      PanelSettingsActions.update
    );

    const updateLocalAndWorkspaceSettings =
      props.updateLocalAndWorkspaceSettings;

    const usingWorkspaceSettings = props.localPanelSettingsRef == null;

    const xAxisActive = usingWorkspaceSettings
      ? workspacePanelSettings.xAxisActive
      : localPanelSettings
      ? localPanelSettings.xAxisActive
      : false;

    const smoothingActive = usingWorkspaceSettings
      ? workspacePanelSettings.smoothingActive
      : localPanelSettings
      ? localPanelSettings.smoothingActive
      : false;

    const usedPanelSettings = localPanelSettings ?? workspacePanelSettings;

    const showGlobalSmoothReset =
      usingWorkspaceSettings &&
      !props.reportSettings &&
      smoothingActive &&
      workspacePanelSettings.localSmoothingSettingsActive
        ? workspacePanelSettings.localSmoothingSettingsActive > 0
        : false;

    const showGlobalXAxisReset =
      !props.localPanelSettingsRef &&
      xAxisActive &&
      workspacePanelSettings.localxAxisSettingsActive
        ? workspacePanelSettings.localxAxisSettingsActive > 0
        : false;
    const resetAllLocalsText = resetAllLocalsClicked
      ? 'Charts updated'
      : 'Override all section settings on this page';
    const resetusingWorkspaceSettingsText = resetClicked
      ? 'Global settings reset'
      : 'Clear workspace level settings';
    const resetLocalText = resetClicked
      ? 'Section settings reset'
      : 'Clear section settings';
    const resetText = usingWorkspaceSettings
      ? resetusingWorkspaceSettingsText
      : resetLocalText;

    const resetxAxisSettings = () => {
      if (usingWorkspaceSettings) {
        updateWorkspaceSettings({
          xAxisActive: false,
          ...EMPTY_XAXIS_SETTINGS,
        });
      } else if (updateLocalAndWorkspaceSettings) {
        updateLocalAndWorkspaceSettings(
          {
            xAxisActive: false,
            ...EMPTY_XAXIS_SETTINGS,
          },
          {
            localxAxisSettingsActive:
              workspacePanelSettings.localxAxisSettingsActive
                ? workspacePanelSettings.localxAxisSettingsActive - 1
                : 0,
          }
        );
      }
      setResetClicked(true);
    };
    const updateXAxis = (xAxis: string) => {
      if (updateLocalAndWorkspaceSettings != null) {
        const updateVal = workspacePanelSettings.localxAxisSettingsActive
          ? workspacePanelSettings.localxAxisSettingsActive + 1
          : 1;
        updateLocalAndWorkspaceSettings(
          {xAxis, xAxisActive: true},
          {localxAxisSettingsActive: updateVal}
        );
      } else {
        updateWorkspaceSettings({xAxis, xAxisActive: true});
      }
      setResetClicked(false);
    };

    const updateXAxisMin = (xAxisMin?: number) => {
      if (updateLocalAndWorkspaceSettings != null) {
        const updateVal = workspacePanelSettings.localxAxisSettingsActive
          ? workspacePanelSettings.localxAxisSettingsActive + 1
          : 1;
        updateLocalAndWorkspaceSettings(
          {xAxisMin, xAxisActive: true},
          {localxAxisSettingsActive: updateVal}
        );
      } else {
        updateWorkspaceSettings({xAxisMin, xAxisActive: true});
      }
      setResetClicked(false);
    };

    const updateXAxisMax = (xAxisMax?: number) => {
      if (updateLocalAndWorkspaceSettings != null) {
        const updateVal = workspacePanelSettings.localxAxisSettingsActive
          ? workspacePanelSettings.localxAxisSettingsActive + 1
          : 1;
        updateLocalAndWorkspaceSettings(
          {xAxisMax, xAxisActive: true},
          {localxAxisSettingsActive: updateVal}
        );
      } else {
        updateWorkspaceSettings({xAxisMax, xAxisActive: true});
      }
      setResetClicked(false);
    };

    const picker =
      props.runSetRefs != null ? (
        <XAxisPickerQueryExtra
          runSetRefs={props.runSetRefs}
          xAxis={usedPanelSettings.xAxis}
          setXAxis={xAxis => {
            updateXAxis(xAxis);
          }}
          xAxisMin={usedPanelSettings.xAxisMin}
          setXAxisMin={xAxisMin => {
            updateXAxisMin(xAxisMin);
          }}
          xAxisMax={usedPanelSettings.xAxisMax}
          setXAxisMax={xAxisMax => {
            updateXAxisMax(xAxisMax);
          }}
        />
      ) : (
        <XAxisPicker
          extraXAxisOptions={[]}
          loading={false}
          xAxis={usedPanelSettings.xAxis}
          setXAxis={xAxis => {
            updateXAxis(xAxis);
          }}
          xAxisMin={usedPanelSettings.xAxisMin}
          setXAxisMin={xAxisMin => {
            updateXAxisMin(xAxisMin);
          }}
          xAxisMax={usedPanelSettings.xAxisMax}
          setXAxisMax={xAxisMax => {
            updateXAxisMax(xAxisMax);
          }}
        />
      );

    const dispatch = useDispatch();
    const linePlotPanels = useAllLinePlotPanels();
    const {
      linePlotPanelsUsingOwnSmoothing,
      linePlotPanelsUsingLocalSmoothing,
      linePlotPanelsUsingSettingSmoothing,
    } = useMemo(
      () => getPanelSettingsGroups(linePlotPanels, usingWorkspaceSettings),
      [linePlotPanels, usingWorkspaceSettings]
    );

    const setSmoothingForLinePlotPanels = (
      smoothingWeight: number,
      smoothingType: SmoothingType,
      panels: LinePlotPanel[],
      reset: boolean,
      resetAllLocals = false
    ) => {
      const refs = panels.map(p => p.ref);

      const updateSettings = {
        smoothingWeight,
        smoothingType,
        useGlobalSmoothingWeight: false,
        useLocalSmoothing: false,
      };
      if (usingWorkspaceSettings) {
        updateSettings.useGlobalSmoothingWeight = !reset;
      } else {
        updateSettings.useLocalSmoothing = !reset;
      }

      if (resetAllLocals) {
        updateSettings.useLocalSmoothing = false;
      }

      dispatch(updateConfigs(refs, updateSettings));
    };

    const resetSmoothingSettings = () => {
      if (usingWorkspaceSettings) {
        updateWorkspaceSettings({
          smoothingActive: false,
          ...EMPTY_SMOOTHING_SETTINGS,
        });
        setSmoothingForLinePlotPanels(
          EMPTY_SMOOTHING_SETTINGS.smoothingWeight,
          EMPTY_SMOOTHING_SETTINGS.smoothingType,
          linePlotPanelsUsingSettingSmoothing,
          true
        );
      } else if (updateLocalAndWorkspaceSettings != null) {
        updateLocalAndWorkspaceSettings(
          {
            smoothingActive: false,
            ...EMPTY_SMOOTHING_SETTINGS,
          },
          {
            localSmoothingSettingsActive:
              workspacePanelSettings.localSmoothingSettingsActive
                ? workspacePanelSettings.localSmoothingSettingsActive - 1
                : 0,
          }
        );
        setSmoothingForLinePlotPanels(
          workspacePanelSettings.smoothingWeight,
          workspacePanelSettings.smoothingType,
          linePlotPanelsUsingSettingSmoothing,
          true
        );
      }
      setResetClicked(true);
    };

    const onPopupClose = () => {
      props.setSettingsPopupOpen(false);
      setResetClicked(false);
      setResetAllLocalsClicked(false);
    };

    return (
      <>
        <Popup
          className="panel-settings-popup"
          on="click"
          position={popupPosition}
          onOpen={() => props.setSettingsPopupOpen(true)}
          onClose={() => onPopupClose()}
          trigger={
            <Button
              size="tiny"
              className={classNames('wb-icon-button only-icon', {
                'action-button--active': xAxisActive,
                'action-button--visible': props.settingsPopupOpen,
              })}>
              <LegacyWBIcon name="xaxis" title="X-Axis options" />
            </Button>
          }
          content={
            <>
              {picker}
              {(showGlobalXAxisReset ||
                (usingWorkspaceSettings &&
                  props.settingsPopupOpen &&
                  resetAllLocalsClicked)) && (
                <div className="override-local-popup">
                  <div className="override-local-popup-text">
                    Some sections have local x-axis settings. Do you want to
                    override all sections with this global smoothing setting?
                    <button
                      onClick={() => {
                        if (props.resetAllLocals) {
                          props.resetAllLocals(
                            {xAxisActive: false, ...EMPTY_XAXIS_SETTINGS},
                            {localxAxisSettingsActive: 0}
                          );
                        }
                        setResetAllLocalsClicked(true);
                      }}
                      className={classNames({
                        'override-local-popup-click': !resetAllLocalsClicked,
                        'override-local-popup-clicked': resetAllLocalsClicked,
                      })}>
                      {resetAllLocalsClicked && <WBIcon name="check" />}
                      {resetAllLocalsText}
                    </button>
                  </div>
                </div>
              )}
              {(xAxisActive || (props.settingsPopupOpen && resetClicked)) && (
                <div className="override-local-popup">
                  <button
                    className={classNames({
                      'override-local-popup-click': !resetClicked,
                      'override-local-popup-clicked': resetClicked,
                    })}
                    onClick={resetxAxisSettings}>
                    {resetClicked && <WBIcon name="check" />}
                    {resetText}
                  </button>
                </div>
              )}
            </>
          }
        />
        <Popup
          className="panel-settings-popup"
          on="click"
          onOpen={() => props.setSettingsPopupOpen(true)}
          onClose={() => onPopupClose()}
          position={popupPosition}
          trigger={
            <Button
              size="tiny"
              className={classNames('wb-icon-button only-icon', {
                'action-button--active': smoothingActive,
                'action-button--visible': props.settingsPopupOpen,
              })}>
              <LegacyWBIcon name="smooth-gray" title="Smoothing options" />
            </Button>
          }
          flowing={true}
          content={
            <div className="smoothing-controls">
              <SmoothingControls
                workspacePanelSettings={workspacePanelSettings}
                localPanelSettings={localPanelSettings}
                updateResetState={setResetClicked}
                updateWorkspaceSettings={updateWorkspaceSettings}
                updateLocalAndWorkspaceSettings={
                  updateLocalAndWorkspaceSettings
                }
                setSmoothingForLinePlotPanels={setSmoothingForLinePlotPanels}
                linePlotPanelsUsingOwnSmoothing={
                  linePlotPanelsUsingOwnSmoothing
                }
                linePlotPanelsUsingGlobalSmoothing={
                  linePlotPanelsUsingSettingSmoothing
                }
              />
              {(showGlobalSmoothReset ||
                (usingWorkspaceSettings &&
                  props.settingsPopupOpen &&
                  resetAllLocalsClicked)) && (
                <div className="override-local-popup">
                  <div className="override-local-popup-text">
                    Some sections have local smoothing settings. Do you want to
                    override all section settings with this global smoothing
                    setting?
                    {
                      <button
                        onClick={() => {
                          if (props.resetAllLocals) {
                            props.resetAllLocals(
                              {
                                ...EMPTY_SMOOTHING_SETTINGS,
                                smoothingActive: false,
                              },
                              {localSmoothingSettingsActive: 0}
                            );
                          }
                          setSmoothingForLinePlotPanels(
                            workspacePanelSettings.smoothingWeight,
                            workspacePanelSettings.smoothingType,
                            linePlotPanelsUsingLocalSmoothing,
                            false,
                            true
                          );
                          setResetAllLocalsClicked(true);
                        }}
                        className={classNames({
                          'override-local-popup-click': !resetAllLocalsClicked,
                          'override-local-popup-clicked': resetAllLocalsClicked,
                        })}>
                        {resetAllLocalsClicked && <WBIcon name="check" />}
                        {resetAllLocalsText}
                      </button>
                    }
                  </div>
                </div>
              )}
              {(smoothingActive ||
                (props.settingsPopupOpen && resetClicked)) && (
                <button
                  className={classNames({
                    'override-local-popup override-local-popup-click':
                      !resetClicked,
                    'override-local-popup override-local-popup-clicked':
                      resetClicked,
                  })}
                  onClick={resetSmoothingSettings}>
                  {resetClicked && <WBIcon name="check" />}
                  {resetText}
                </button>
              )}
            </div>
          }
        />

        {usingWorkspaceSettings && (
          <Popup
            className="panel-settings-popup"
            on="click"
            position={popupPosition}
            content="Ignore outliers in chart scaling"
            trigger={
              <Button
                size="tiny"
                className={
                  'wb-icon-button only-icon' +
                  (settings.ignoreOutliers ? ' action-button--active' : '')
                }
                onClick={e =>
                  updateWorkspaceSettings({
                    ignoreOutliers: !settings.ignoreOutliers,
                  })
                }>
                <LegacyWBIcon
                  name="outlier"
                  title="Ignore outliers in chart scaling"
                />
              </Button>
            }
          />
        )}
      </>
    );
  },
  {id: 'PanelSettingsComponentControlled'}
);

export default PanelSettingsComponentControlled;

interface XAxisPickerQueryExtraProps {
  runSetRefs: RunSetTypes.Ref[];
  xAxis: string;
  setXAxis: (xAxis: string) => void;
  xAxisMin: number | undefined;
  setXAxisMin: (xAxisMin: number | undefined) => void;
  xAxisMax: number | undefined;
  setXAxisMax: (xAxisMax: number | undefined) => void;
}

const SmoothingControls: React.FC<{
  workspacePanelSettings: Settings;
  localPanelSettings: Settings | null;
  linePlotPanelsUsingOwnSmoothing: LinePlotPanel[];
  linePlotPanelsUsingGlobalSmoothing: LinePlotPanel[];
  updateResetState(val: boolean): void;
  updateWorkspaceSettings(panelSettingsUpdate: Partial<Settings>): void;
  updateLocalAndWorkspaceSettings?(
    localPanelSettingsUpdate: Partial<Settings>,
    workspacePanelSettingsUpdate: Partial<Settings>
  ): DispatchableAction;
  setSmoothingForLinePlotPanels(
    smoothingWeight: number,
    smoothingType: SmoothingType,
    panels: LinePlotPanel[],
    reset: boolean
  ): void;
}> = makeComp(
  ({
    workspacePanelSettings,
    localPanelSettings,
    linePlotPanelsUsingOwnSmoothing,
    linePlotPanelsUsingGlobalSmoothing,
    updateResetState,
    setSmoothingForLinePlotPanels,
    updateWorkspaceSettings,
    updateLocalAndWorkspaceSettings,
  }) => {
    const settings = localPanelSettings ?? workspacePanelSettings;
    const overrideString = localPanelSettings
      ? 'Override all charts in this section'
      : 'Override all charts on this page';
    return (
      <div className="smoothing-slider-popup">
        <div className="global-config__control">
          <div>Smoothing</div>
          <SmoothingInput
            dropdownDirection={localPanelSettings == null ? 'right' : 'left'}
            smoothingParam={settings.smoothingWeight}
            smoothingType={settings.smoothingType}
            smoothingTypeDropdown={true}
            onChange={(smoothingParam, smoothingType) => {
              if (updateLocalAndWorkspaceSettings != null) {
                const updateVal =
                  (workspacePanelSettings.localSmoothingSettingsActive ?? 0) +
                  1;

                updateLocalAndWorkspaceSettings(
                  {
                    smoothingWeight: smoothingParam,
                    smoothingType,
                    smoothingActive: true,
                  },
                  {localSmoothingSettingsActive: updateVal}
                );
              } else {
                updateWorkspaceSettings({
                  smoothingWeight: smoothingParam,
                  smoothingType,
                  smoothingActive: true,
                });
              }
              setSmoothingForLinePlotPanels(
                smoothingParam,
                smoothingType,
                linePlotPanelsUsingGlobalSmoothing,
                false
              );
              updateResetState(false);
            }}
          />
        </div>
        {settings.smoothingActive &&
          linePlotPanelsUsingOwnSmoothing.length !== 0 && (
            <div className="use-global-smoothing-section">
              Some charts have per chart smoothing settings. Do you want to
              override all charts with this smoothing setting?
              <button
                className="override-local-popup override-local-popup-click"
                onClick={() => {
                  setSmoothingForLinePlotPanels(
                    settings.smoothingWeight,
                    settings.smoothingType,
                    linePlotPanelsUsingOwnSmoothing,
                    false
                  );
                }}>
                {overrideString}
              </button>
            </div>
          )}
      </div>
    );
  },
  {id: 'SmoothingControls'}
);

const XAxisPickerQueryExtra = makeComp(
  (props: XAxisPickerQueryExtraProps) => {
    const {
      runSetRefs,
      xAxis,
      setXAxis,
      xAxisMin,
      setXAxisMin,
      xAxisMax,
      setXAxisMax,
    } = props;
    const keyInfoQuery = useKeyInfoQuery(runSetRefs);
    const extraXAxisOptions = useMemo(
      () =>
        keyInfoQuery.loading || keyInfoQuery.error != null
          ? []
          : RunHelpersUtil.globalXAxisOptions(keyInfoQuery.historyKeyInfo),
      [keyInfoQuery]
    );
    return (
      <XAxisPicker
        extraXAxisOptions={extraXAxisOptions}
        loading={keyInfoQuery.loading}
        xAxis={xAxis}
        setXAxis={setXAxis}
        xAxisMin={xAxisMin}
        setXAxisMin={setXAxisMin}
        xAxisMax={xAxisMax}
        setXAxisMax={setXAxisMax}
      />
    );
  },
  {id: 'XAxisPickerQueryExtra'}
);

interface XAxisPickerProps {
  loading: boolean;
  extraXAxisOptions: string[];
  xAxis: string;
  setXAxis: (xAxis: string) => void;
  xAxisMin: number | undefined;
  setXAxisMin: (xAxisMin: number | undefined) => void;
  xAxisMax: number | undefined;
  setXAxisMax: (xAxisMax: number | undefined) => void;
}

const XAxisPicker = makeComp(
  (props: XAxisPickerProps) => {
    const {extraXAxisOptions, loading} = props;
    const options = PlotHelpers.defaultXAxisValues
      .concat(extraXAxisOptions)
      .map(o => ({
        key: o,
        text: PlotHelpers.xAxisLabel(o),
        value: o,
      }));

    return (
      <div className="x-axis-picker">
        <div className="x-axis-selector">
          <div className="x-axis-text">X Axis</div>
          <ModifiedDropdown
            selection
            loading={loading}
            value={props.xAxis}
            options={options}
            onChange={(e, data: DropdownProps) => {
              props.setXAxis(data.value as string);
            }}
          />
        </div>
        <div className="x-axis-range-selector">
          X Range
          <RangeInput
            onMinChange={_.debounce(newVal => {
              props.setXAxisMin(newVal);
            }, 500)}
            onMaxChange={_.debounce(newVal => {
              props.setXAxisMax(newVal);
            }, 500)}
            minValue={props.xAxisMin}
            maxValue={props.xAxisMax}
          />
        </div>
      </div>
    );
  },
  {id: 'XAxisPicker'}
);
