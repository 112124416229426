import * as _ from 'lodash';
import * as React from 'react';
import {useState} from 'react';
import {Button, Checkbox, Divider, Label, Popup} from 'semantic-ui-react';

import * as Query from '../../../util/queryts';
import * as Run from '../../../util/runs';
import * as UIHelpers from '../../../util/uihelpers';
import ModifiedDropdown from '../../elements/ModifiedDropdown';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import makeComp from '../../../util/profiler';

interface DFTableActionGroupButtonProps {
  compact?: boolean;
  groupOpen: boolean;
  grouping: Query.Grouping;
}

export const DFTableActionGroupButton = makeComp(
  (props: DFTableActionGroupButtonProps) => {
    let groupButtonClass;
    const groupCount = props.grouping.length;

    const passThroughProps = _.omit(props, 'compact', 'groupOpen', 'grouping');

    if (props.groupOpen) {
      groupButtonClass = 'action-button--focused';
    } else if (groupCount > 0) {
      groupButtonClass = 'action-button--active';
    } else {
      groupButtonClass = 'action-button--static';
    }

    const buttonText =
      groupCount > 0
        ? 'Grouped by ' + UIHelpers.maybePluralize(groupCount, 'field')
        : 'Group';
    return (
      <Button
        {...passThroughProps}
        data-test="group-popup"
        size="tiny"
        className={groupButtonClass + ' wb-icon-button table-group-button'}>
        <LegacyWBIcon name="group-runs" title={buttonText} />
        {props.compact ? '' : buttonText}
      </Button>
    );
  },
  {id: 'DFTableActionGroupButton'}
);

interface DFTableActionGroupDropdownProps {
  groupableKeys: string[];
  loading: boolean;
  grouping: Query.Grouping;

  setGrouping(grouping: Query.Grouping): void;
}

export const DFTableActionGroupDropdown = makeComp(
  (props: DFTableActionGroupDropdownProps) => {
    const {groupableKeys, loading, grouping, setGrouping} = props;
    const options = UIHelpers.makeOptions(groupableKeys);
    return (
      <ModifiedDropdown
        style={{minWidth: '205px'}}
        placeholder="Group by..."
        data-test="group-dropdown"
        closeOnBlur
        multiple
        lazyLoad
        selection
        optionTransform={UIHelpers.beautify}
        options={options}
        search={UIHelpers.searchFunction}
        value={grouping.map(v => Run.keyToString(v))}
        disabled={loading}
        loading={loading}
        itemLimit={100}
        closeOnChange
        onChange={(e, {value}) => {
          // We know it's string[] because we pass in options
          const typedValue: string[] = value as string[];
          // We filter out nulls so casting to Run.Key[] is safe
          const newGrouping: Run.Key[] = typedValue
            .map(v => Run.keyFromString(v))
            .filter(o => o != null) as Run.Key[];
          setGrouping(newGrouping);
        }}
      />
    );
  },
  {id: 'DFTableActionGroupDropdown'}
);

interface DFTableActionGroupPickerProps {
  grouping: Query.Grouping;
  recommendedGrouping?: Query.Grouping; // A good grouping for the current data
  dropdown: JSX.Element;

  setGrouping(grouping: Query.Grouping): void;
}

export const DFTableActionGroupPicker = makeComp(
  (props: DFTableActionGroupPickerProps) => {
    const {recommendedGrouping, grouping, dropdown, setGrouping} = props;

    return (
      <div>
        {dropdown}
        {recommendedGrouping != null && recommendedGrouping.length !== 0 && (
          <React.Fragment>
            <Divider />
            <div className="filter-list__toggle">
              <span style={{marginRight: 16, whiteSpace: 'nowrap'}}>
                Recommended:{' '}
                {recommendedGrouping.map((k, i) => (
                  <Label key={i}>
                    {UIHelpers.getImage(k)} {Run.keyDisplayName(k, true)}
                  </Label>
                ))}
              </span>
              <Checkbox
                className="filter-list__action"
                toggle
                checked={_.isEqual(grouping, recommendedGrouping)}
                onChange={() => {
                  if (_.isEqual(grouping, recommendedGrouping)) {
                    setGrouping([]);
                  } else {
                    setGrouping(recommendedGrouping);
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  },
  {id: 'DFTableActionGroupPicker'}
);

interface DFTableActionGroupProps {
  trigger: (isOpen: boolean) => React.ReactNode;
  content: React.ReactNode;
}

export const DFTableActionGroup = makeComp(
  (props: DFTableActionGroupProps) => {
    const [open, setOpen] = useState(false);
    return (
      <Popup
        basic
        className="df-table-action-popup"
        on="click"
        position="bottom left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={props.trigger(open)}
        content={props.content}
        popperModifiers={{
          preventOverflow: {enabled: false},
          flip: {enabled: false},
        }}
      />
    );
  },
  {id: 'DFTableActionGroup'}
);
