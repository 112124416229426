import React from 'react';
import {Popup} from 'semantic-ui-react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import {TargetBlank} from '../../util/links';

const inputType = {
  type: 'union' as const,
  members: [
    'none' as const,
    'string' as const,
    'number' as const,
    'boolean' as const,
  ],
};
type PanelStringProps = Panel2.PanelProps<typeof inputType>;

const MAX_DISPLAY_LENGTH = 100;

function isURL(text: string): boolean {
  try {
    const url = new URL(text);
    if (url && (url.protocol === 'http:' || url.protocol === 'https:')) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export const PanelString: React.FC<PanelStringProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  const fullStr = String(
    nodeValueQuery.result == null ? '-' : nodeValueQuery.result
  );
  const truncateText = fullStr.length > MAX_DISPLAY_LENGTH;
  const displayText =
    '' +
    (truncateText ? fullStr.slice(0, MAX_DISPLAY_LENGTH) + '...' : fullStr);
  const displayElement = <div>{displayText}</div>;
  const textIsURL = isURL(fullStr);

  if (textIsURL) {
    return <TargetBlank href={fullStr}>{displayText}</TargetBlank>;
  } else if (truncateText) {
    return (
      <Popup
        trigger={displayElement}
        hoverable
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        content={
          <div
            style={{
              maxHeight: 400,
              maxWidth: 1200,
              overflow: 'auto',
              fontSize: 14,
            }}>
            {fullStr}
          </div>
        }
      />
    );
  } else {
    return displayElement;
  }
};

export const Spec: Panel2.PanelSpec = {
  id: 'string',
  Component: PanelString,
  inputType,
};
