import React, {useState, useCallback} from 'react';

import makeComp from '../util/profiler';
import * as S from './PurchaseSeatModal.styles';

export interface PurchaseSeatModalProps {
  seatsToPurchase: number;
  pricePerSeat: number;
  billingInterval: string;
  onConfirm(): void;
  onClose(): void;
}

const PurchaseSeatModal: React.FC<PurchaseSeatModalProps> = makeComp(
  ({
    seatsToPurchase,
    pricePerSeat,
    billingInterval: billingPeriod,
    onConfirm,
    onClose,
  }) => {
    const [confirmClicked, setConfirmClicked] = useState(false);
    const handleConfirm = useCallback(() => {
      setConfirmClicked(true);
      onConfirm();
    }, [onConfirm]);
    const pluralSeats = seatsToPurchase > 1 ? 'seats' : 'seat';
    const totalPrice = seatsToPurchase * pricePerSeat;
    return (
      <S.Modal open size="small" onClose={onClose}>
        <S.Header>Add a new seat?</S.Header>
        <S.Content>
          {`Inviting a new team member will add $${totalPrice}/${billingPeriod} to your organization's subscription.`}
        </S.Content>
        <S.ButtonGroup>
          <S.Button size="small" onClick={onClose}>
            Cancel
          </S.Button>
          <S.Button
            disabled={confirmClicked}
            color="blue"
            size="small"
            data-test="confirm-purchase-seat"
            onClick={handleConfirm}>
            Invite and add {pluralSeats}
          </S.Button>
        </S.ButtonGroup>
      </S.Modal>
    );
  },
  {id: 'PurchaseSeatModal', memo: true}
);

export default PurchaseSeatModal;
