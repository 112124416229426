import * as S from './block-quotes.styles';

import React from 'react';
import {Node, Element, Editor, Transforms, Range, Point} from 'slate';
import {RenderElementProps} from 'slate-react';
import {BlockWrapper} from './drag-drop';

export interface BlockQuote extends Element {
  type: 'block-quote';
}

export const isBlockQuote = (node: Node): node is BlockQuote =>
  node.type === 'block-quote';

export const BlockQuoteElement: React.FC<
  RenderElementProps & {
    element: BlockQuote;
  }
> = ({attributes, element, children}) => {
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.BlockQuote>{children}</S.BlockQuote>
    </BlockWrapper>
  );
};

export const withBlockQuotes = <T extends Editor>(editor: T) => {
  const {deleteBackward, insertBreak} = editor;

  editor.insertBreak = () => {
    const {selection} = editor;

    const blockQuoteEntry = Editor.above(editor, {
      match: n => isBlockQuote(n),
    });

    if (selection != null && blockQuoteEntry != null) {
      const [, path] = blockQuoteEntry;

      const {anchor} = selection;
      const start = Editor.start(editor, path);
      const range = {anchor, focus: start};
      const beforeText = Editor.string(editor, range);

      const allowedBlankLines = 1;
      const tryingToBreakOut =
        beforeText.substr(-allowedBlankLines, allowedBlankLines) ===
        '\n'.repeat(allowedBlankLines);

      if (tryingToBreakOut) {
        Transforms.delete(editor, {
          reverse: true,
          distance: allowedBlankLines,
        });
        Transforms.splitNodes(editor, {always: true});
        Transforms.setNodes(editor, {type: 'paragraph'});
      } else {
        Editor.insertText(editor, '\n');
      }

      return;
    }

    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    const blockQuoteEntry = Editor.above(editor, {
      match: n => isBlockQuote(n),
    });

    if (selection && Range.isCollapsed(selection) && blockQuoteEntry != null) {
      const [, path] = blockQuoteEntry;
      const start = Editor.start(editor, path);

      if (Point.equals(selection.anchor, start)) {
        Transforms.setNodes(editor, {type: 'paragraph'});
        return;
      }
    }

    deleteBackward(...args);
  };

  return editor;
};
