import _ from 'lodash';
import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import AudioCard from '../components/AudioCard';
import BokehCard from '../components/BokehCard';
import HtmlCard from '../components/HtmlCard';
import ImageCard from '../components/ImageCard';
import {
  MaskControl,
  MediaCardProps,
  RunWithHistoryAndMediaWandb,
} from '../components/MediaCard';
import MoleculeCard from '../components/MoleculeCard';
import Object3DCard from '../components/Object3DCard';
import {MediaBrowserPanelConfig} from '../components/PanelMediaBrowser';
import PlotlyCard from '../components/PlotlyCard';
import TableCard from '../components/TableCard';
import VideoCard from '../components/VideoCard';
import {backendHost} from '../config';
import {MatchParams} from '../types/base';
import {
  ImageMetadata,
  MediaCardMetadata,
  MediaCardType,
  mediaStrings,
} from '../types/media';
import {RunHistoryKeyInfo, RunHistoryRow, RunWithHistory} from '../types/run';
import {linkify} from '../util/links';
import {colorN, colorNRGB, ROBIN16} from './colors';
import makeComp from './profiler';
import * as runHelpers from './runhelpers';
import {Struct} from './types';

export interface WBFile {
  path: string;
  _type: string;
  sha256: string;
  size: 144;
}

export function getLastHistoryRowWithKey(
  run: RunWithHistory,
  key: string,
  stepCeiling?: number
): RunHistoryRow | null {
  if (run.history == null) {
    return null;
  }

  for (let i = run.history.length - 1; i >= 0; i--) {
    const historyRow = run.history[i];
    if (stepCeiling != null && historyRow._step > stepCeiling) {
      continue;
    }
    if (historyRow[key] != null) {
      return historyRow;
    }
  }

  return null;
}

// Returns all steps from a run where the give key is present
export function stepsWithKey(run: RunWithHistory, key: string): number[] {
  return (
    run.history
      ?.filter(historyRow => key in historyRow)
      .map(historyRow => historyRow._step) ?? []
  );
}

// Returns all steps from a run where one of a given key is present
export function runHistoryRowsWithOneOfKeys(
  run: RunWithHistory,
  keys?: string[]
): RunHistoryRow[] {
  if (
    keys == null ||
    keys.length === 0 ||
    run.history == null ||
    run.history.length === 0
  ) {
    return [];
  }
  return run.history.filter(row => {
    return keys.some(key => key in row);
  });
}

export function latestStepWithMedia(
  stepsWithMedia: number[],
  stepCeiling: number
): number | null {
  for (let i = stepsWithMedia.length - 1; i >= 0; i--) {
    const step = stepsWithMedia[i];
    if (step <= stepCeiling) {
      return step;
    }
  }
  return null;
}

export function getMediaKeysFromKeyInfo(keyInfo: RunHistoryKeyInfo): string[] {
  const keyTypes = runHelpers.keyTypes(keyInfo);
  return runHelpers.keysOfType(keyTypes, mediaStrings);
}

export function isMediaType(key: string): boolean {
  return _.indexOf(mediaStrings, key) !== -1;
}

export function runFileSource(matchParams: MatchParams, path: string): string {
  const {entityName, projectName, runName} = matchParams;
  const fullPath = `${backendHost()}/files/${entityName}/${projectName}/${runName}`;

  return encodeURI(`${fullPath}/${path}`);
}

// returns an encoded url for a media src file
export const mediaSrc = (
  matchParams: MatchParams,
  fileParams: Array<string | number>, // [mediaKey, stepIndex] for images or [mediaKey, stepIndex, mediaIndex] for audio
  mediaType: 'images' | 'audio' | 'html' | 'videos',
  extension?: string
) => {
  const {entityName, projectName, runName} = matchParams;
  const fullPath = `${backendHost()}/files/${entityName}/${projectName}/${runName}`;
  const fileName = mediaFilePath(fileParams, mediaType, extension);
  return encodeURI(`${fullPath}/${fileName}`);
};

type MediaType = 'images' | 'audio' | 'html' | 'object3D' | 'videos';

// returns an encoded filename for a media src file (includes the 'media/${mediaType}/' prefix)
export function mediaFilePath(
  fileParams: Array<string | number>,
  mediaType: MediaType,
  extension?: string
): string {
  const filename = fileParams.join('_');
  let fileType: string;
  if (extension) {
    fileType = extension;
  } else if (mediaType === 'images') {
    fileType = 'jpg';
  } else if (mediaType === 'audio') {
    fileType = 'wav';
  } else if (mediaType === 'videos') {
    fileType = 'gif';
  } else {
    fileType = 'html';
  } // we currently only support images, audio, and html
  return `media/${mediaType}/${filename}.${fileType}`;
}

export const filePath = (filename: string, mediaType: MediaType) => {
  return encodeURI(`media/${mediaType}/${filename}`);
};

/* === mediaType: IMAGES === */

/* === AUDIO === */

// returns the 'durations' array for the given run/step/key
export const getAudioDurations = (
  run: any,
  stepIndex: number,
  mediaKey: string
) => {
  return [];
};

// returns the max audio duration for the given parameters.
// note: this behaves differently if allRuns.length === 1 (e.g. on run page)
// if we have one run, we want to compare durations across all mediaIndexes for that run+step+key
// but if we have multiple runs (allRuns.length > 1, e.g. on report page), we want to compare durations for a single mediaIndex across runs
export const getMaxAudioDuration = (
  allRuns: RunWithHistory[],
  stepIndex: number,
  mediaKey: string,
  mediaIndex: number
): number =>
  _.max(
    allRuns.map(r => {
      const allDurations = getAudioDurations(r, stepIndex, mediaKey);
      return allRuns.length === 1
        ? _.max(allDurations) // run page
        : allDurations[mediaIndex]; // report page
    })
  ) || 0;

// returns the width of the current waveform in percent (not px!)
// calculated by comparing its duration vs the duration of all currently-displayed waveforms
export const scaledWaveformWidth = (
  allRuns: RunWithHistory[],
  runIndex: number,
  stepIndex: number,
  mediaKey: string,
  mediaIndex: number
) => {
  const maxAudioDuration: number = getMaxAudioDuration(
    allRuns,
    stepIndex,
    mediaKey,
    mediaIndex
  );
  const currentAudioDuration = getAudioDurations(
    allRuns[runIndex],
    stepIndex,
    mediaKey
  )[mediaIndex];

  return 100 * ((currentAudioDuration || 0) / maxAudioDuration);
};

// Helper components for media cards

export const MediaStepDropdown = makeComp(
  ({
    step,
    globalStep,
    stepDropdownOptions,
    setStep,
  }: {
    step: number | undefined;
    globalStep: number;
    stepDropdownOptions: any;
    setStep: (n: number) => void;
  }) => {
    if (_.isEmpty(stepDropdownOptions)) {
      return null;
    }

    const stepMismatch =
      !_.isUndefined(globalStep) && step !== globalStep ? 'step-mismatch' : '';

    return (
      <Dropdown
        key={step || 'none'}
        floating
        lazyLoad
        className={'panel-media-step ' + stepMismatch}
        scrolling
        compact
        value={step}
        options={stepDropdownOptions}
        onChange={(e, {value}) => {
          setStep(value as number);
        }}
      />
    );
  },
  {id: 'MediaStepDropdown', memo: true}
);

export const makeCaptions = (
  currentMediaMetadata:
    | {
        caption?: any;
        captions?: any[];
      }
    | null
    | undefined,
  mediaIndex: number,
  mediaGroupingCount?: number
) => {
  if (currentMediaMetadata == null) {
    return [];
  }
  const groupCount = mediaGroupingCount ?? 1;

  // Some captions are at the media file level
  let captions: string[];
  if ('caption' in currentMediaMetadata && currentMediaMetadata.caption) {
    captions = [String(currentMediaMetadata.caption)];
  } else if (
    'captions' in currentMediaMetadata &&
    currentMediaMetadata.captions
  ) {
    // Some captions are a combination of a sequence of images
    // from cli/data_types: seq_to_json
    captions = currentMediaMetadata.captions
      .slice(mediaIndex * groupCount, (mediaIndex + 1) * groupCount)
      .map(c => String(c));
  } else {
    captions = [];
  }

  const renderedCaptions = captions.map((caption, i) => {
    return (
      <div key={i} style={{flexGrow: 1}}>
        <div className="image-card-caption-text">
          {caption.split('\n').map((cLine, j) => {
            let c: string | ReturnType<typeof linkify> = cLine;
            if (!_.isEmpty(c)) {
              c = linkify(c, {onClick: e => e.stopPropagation()});
            }
            return <div key={j}>{c}</div>;
          })}
        </div>
      </div>
    );
  });

  return renderedCaptions;
};

export const labelComponent = (
  props: MediaCardProps,
  currentStep: number | undefined,
  titleLink: JSX.Element
) => {
  return (
    props.labels &&
    props.labels.map(l => {
      return (
        <div key={l} style={{fontSize: '10px', lineHeight: '16px'}}>
          {l === 'step' ? (
            <span>Step {currentStep}</span>
          ) : l === 'index' ? (
            <span>Index {props.mediaIndex}</span>
          ) : l === 'run' ? (
            <span className="text__single-line" style={{display: 'block'}}>
              {props.disableRunLink ? props.run.displayName : titleLink}
            </span>
          ) : (
            'None'
          )}
        </div>
      );
    })
  );
};

// ** Migrations **

type DeprecatedConfigValues = DeprecatedZoom & DeprecatedLayoutConfig;
export const runMediaPanelMigrations = (
  config: MediaBrowserPanelConfig & DeprecatedConfigValues
): MediaBrowserPanelConfig => {
  return convertMediaPanelConfigToLayoutV2(
    convertMediaPanelConfigZoomToGallery(config)
  );
};
//
// This migration uses heuristics to set old views into a close approximation of their old image sizes,
// but in their new gallery layout
//
// Zoom to Columns Migration
interface DeprecatedZoom {
  zoom: number;
}

// Use a negative one to indicate the zoom migration
// has been run and this is a stale key
export const ZOOM_MIGRATED_TOKEN = 'ZOOM_MIGRATED';
export const convertMediaPanelConfigZoomToGallery = (
  config: MediaBrowserPanelConfig & DeprecatedZoom
): MediaBrowserPanelConfig => {
  const newConfig = _.clone(config);

  if (config.zoom != null && (config as any).zoom !== ZOOM_MIGRATED_TOKEN) {
    const zoom = config.zoom;

    // Migrate all non zoom panels to a simple 3 column actualSize
    if (zoom === 1) {
      newConfig.actualSize = true;
      newConfig.columnCount = 3;
      // Migrate any other zooms to a best guess of their columnCount
    } else if (zoom > 2.5) {
      newConfig.columnCount = 1;
    } else if (zoom <= 2.5) {
      newConfig.columnCount = 2;
    } else if (zoom < 1.4) {
      newConfig.columnCount = 3;
    } else if (zoom < 0.3) {
      newConfig.columnCount = 5;
    } else if (zoom < 0.25) {
      newConfig.columnCount = 8;
    }
    (newConfig as any).zoom = ZOOM_MIGRATED_TOKEN;
  }

  return newConfig;
};

export const getFirstMediaMetadata = (
  history: RunHistoryRow[],
  key: string,
  currentStep: number
): MediaCardMetadata | null => {
  // first, try to find the current step
  let firstHistoryStep = _.find(
    history,
    hr => hr._step === currentStep && key in hr
  );

  // if we were unable to find the current step or the current step does
  // not contain the desired key, take the first step with the desired key
  if (firstHistoryStep == null) {
    firstHistoryStep = _.find(history, hr => key in hr);
  }

  return firstHistoryStep && firstHistoryStep[key];
};

export function getMasks(
  mediaMetadata: ImageMetadata,
  mediaIndex: number
): Struct<WBFile> {
  if (mediaMetadata.masks != null) {
    return mediaMetadata.masks;
  }

  if (mediaMetadata.all_masks?.[mediaIndex] != null) {
    return mediaMetadata.all_masks[mediaIndex];
  }

  return {};
}

export function getBoundingBoxes(
  mediaMetadata: ImageMetadata,
  mediaIndex: number
): Struct<WBFile> {
  if (mediaMetadata.boxes != null) {
    return mediaMetadata.boxes;
  }

  if (mediaMetadata.all_boxes?.[mediaIndex] != null) {
    const v = mediaMetadata.all_boxes[mediaIndex];
    // The original box API was un-nested and returned path at the top level.
    // Only a few runs were logged using this legacy API by wandb test users
    // so we don't want to support this anymore.
    // Return an empty object to ignore these boxes and pretend as if there are no boxes.
    if (v.path != null) {
      return {};
    }
    return v;
  }

  return {};
}

// Layout V2 Migration:
//
// Introduces a new mode, gallery mode and grid mode
// along with allowing multiple media keys.

// The old layout has a set of image keys it used
// This will both be rolled into a new array
export interface DeprecatedLayoutConfig {
  mediaKey?: string; // key for media, like 'examples'
  imageKey?: string; // legacy key for images, like 'examples.'  renamed to mediaKey.
}
export const convertMediaPanelConfigToLayoutV2 = (
  config: MediaBrowserPanelConfig & DeprecatedLayoutConfig
): MediaBrowserPanelConfig => {
  const oldKey = config.mediaKey ?? config.imageKey;
  if (oldKey && config.mediaKeys == null) {
    const newConfig = _.clone(config);
    newConfig.mediaKeys = [oldKey];

    return newConfig;
  }

  return config;
};

// This needs to be initialized in `mediaTypeToComponent` because some of the
// media components may be undefined on first pass due to the import cycle issue.
let mediaToComponentMap:
  | {
      [k in MediaCardType]: React.FunctionComponent<MediaCardProps>;
    }
  | undefined;

export const mediaTypeToComponent = (k: MediaCardType) => {
  if (mediaToComponentMap == null) {
    mediaToComponentMap = {
      audio: AudioCard,
      image: ImageCard,
      video: VideoCard,
      table: TableCard,
      plotly: PlotlyCard,
      html: HtmlCard,
      object3D: Object3DCard,
      bokeh: BokehCard,
      molecule: MoleculeCard,
    };
  }
  return mediaToComponentMap[k];
};

export const WANDB_MASK_CLASS_LABEL_KEY = 'mask/class_labels';
export const WANDB_BBOX_CLASS_LABEL_KEY = 'bounding_box/class_labels';
export const WANDB_DELIMETER = '_wandb_delimeter_';

export const getSingletonValue = (
  run: {
    _wandb: string;
  },
  type: string,
  key: string
) => {
  return _.get(run._wandb, [type, key, 'value']);
};

export interface ClassLabels {
  [key: number]: string;
}

export interface ClassLabelNode {
  key: string;
  type: string;
  value: ClassLabels;
}

export type ClassLabelMap = {
  [media: string]: {
    [typeKey: string]: ClassLabelNode;
  };
};

// Gets all class labels from a set of runs
// that are attached to any of the given
// mediaKeys of the WANDB_KEY type
//
// Returns the class labels into a merged object
// with a hierarchy of {mediaKey: {subKey: classLabels}}
export function classLabelMap(
  runs: RunWithHistoryAndMediaWandb[],
  mediaKeys: string[],
  WANDB_KEY: string
): ClassLabelMap {
  return runs
    .map(r => {
      const labelMap = r._wandb[WANDB_KEY] ?? {};
      return Object.keys(labelMap)
        .map(k => {
          const [mediaKey, subKey] = k.split(WANDB_DELIMETER);
          if (_.includes(mediaKeys, mediaKey)) {
            const v = labelMap[k];
            return {subKey, mediaKey, value: v};
          } else {
            return null;
          }
        })
        .filter(v => v != null) as Array<{
        subKey: string;
        mediaKey: string;
        value: ClassLabelNode;
      }>;
    })
    .reduce((acc, val) => acc.concat(val), [])
    .reduce(
      (acc, v) => {
        if (acc[v.mediaKey] == null) {
          acc[v.mediaKey] = {};
        }
        acc[v.mediaKey][v.subKey] = v.value;

        return acc;
      },
      {} as {
        [mediaKey: string]: {
          [maskKey: string]: ClassLabelNode;
        };
      }
    );
}

// Right now this is just a wrapper, but
// use this as a single code path for segmentation colors
// for future flexibiltiy
export const segmentationMaskColor = (id: number) => {
  return colorNRGB(id, ROBIN16);
};

// Right now this is just a wrapper, but
// use this as a single code path for segmentation colors
// for future flexibiltiy
export const boxColor = (id: number) => {
  return colorN(id, ROBIN16);
};

export const DEFAULT_ALL_MASK_CONTROL = {
  disabled: false,
  opacity: 0.6,
} as MaskControl;

export const DEFAULT_CLASS_MASK_CONTROL = {
  disabled: false,
  opacity: 1,
} as MaskControl;
