import React, {useContext} from 'react';

interface TypingContextValue {
  typing: boolean;
  setTyping(typing: boolean): void;
}

export const TypingContext = React.createContext<TypingContextValue>({} as any);

export const useTyping = () => {
  return useContext(TypingContext);
};
