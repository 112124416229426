import * as _ from 'lodash';
import '../css/GroupPage.less';
import '../css/ProjectPage.less';
import * as globals from '../css/globals.styles';

import * as React from 'react';
import {Segment} from 'semantic-ui-react';
import {useRef, useState, useCallback, useMemo} from 'react';
import {Checkbox, Loader} from 'semantic-ui-react';

import {
  useArtifactDagQueryRecursive,
  Direction,
  Dag,
  Artifact,
  Run,
  Edge,
} from '../state/graphql/artifactDagQuery';
import makeComp from '../util/profiler';
import {urlPrefixed} from '../config';

import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import CytoscapeComponent from 'react-cytoscapejs';

cytoscape.use(dagre);

interface ArtifactDagProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
}

interface ArtifactGroup {
  artifactTypeName: string;
  artifacts: Artifact[];
}

interface RunGroup {
  jobType: string;
  runs: Run[];
}

interface EdgeGroupID {
  artifactTypeName: string;
  jobType: string;
  dir: Direction;
}

type EdgeGroup = EdgeGroupID & {
  edges: Edge[];
};

function edgeGroupStringID(edgeGroup: EdgeGroupID) {
  return `${edgeGroup.artifactTypeName} ${
    edgeGroup.dir === Direction.TowardArtifact ? '<-' : '->'
  } ${edgeGroup.jobType}`;
}

function makeGroupDag(dag: Dag) {
  const {artifacts, runs, edges} = dag;

  const artifactGroups: {[id: string]: ArtifactGroup} = {};
  _.forEach(artifacts, artifact => {
    const groupKey = artifact.artifactTypeName;
    if (artifactGroups[groupKey] == null) {
      artifactGroups[groupKey] = {
        artifactTypeName: artifact.artifactTypeName,
        artifacts: [],
      };
    }
    artifactGroups[groupKey].artifacts.push(artifact);
  });

  const runGroups: {[id: string]: RunGroup} = {};
  _.forEach(runs, run => {
    const groupKey = run.jobType || 'run';
    if (runGroups[groupKey] == null) {
      runGroups[groupKey] = {
        jobType: run.jobType || 'run',
        runs: [],
      };
    }
    runGroups[groupKey].runs.push(run);
  });

  const edgeGroups: {[id: string]: EdgeGroup} = {};
  _.forEach(edges, edge => {
    const edgeGroupID = {
      artifactTypeName: artifacts[edge.artifactID].artifactTypeName,
      jobType: runs[edge.runID].jobType || 'run',
      dir: edge.dir,
    };
    const eid = edgeGroupStringID(edgeGroupID);
    if (edgeGroups[eid] == null) {
      edgeGroups[eid] = {
        ...edgeGroupID,
        edges: [],
      };
    }
    edgeGroups[eid].edges.push(edge);
  });

  return {artifactGroups, runGroups, edgeGroups};
}

type GroupDag = ReturnType<typeof makeGroupDag>;

function dagToCytoGraphEls(
  dag: Dag,
  artifactID: string
): cytoscape.ElementDefinition[] {
  const {artifacts, runs, edges} = dag;
  const graphElements: cytoscape.ElementDefinition[] = [];

  _.forEach(artifacts, (artifact, aid) => {
    graphElements.push({
      data: {
        id: aid,
        label: `${artifact.artifactTypeName}:${artifact.artifactSequenceName}:v${artifact.versionIndex}`,
        type: 'artifactType',
      },
      classes:
        `${artifact.artifactSequenceName}:${artifact.artifactCommitHash}` ===
        artifactID
          ? 'this'
          : '',
    });
  });
  _.forEach(runs, (run, rid) => {
    graphElements.push({
      data: {id: rid, label: `${run.jobType}:${run.displayName}`, type: 'run'},
      classes: 'run',
    });
  });
  _.forEach(edges, (edge, eid) => {
    if (edge.dir === Direction.TowardArtifact) {
      graphElements.push({
        data: {id: eid, source: edge.runID, target: edge.artifactID},
      });
    } else {
      graphElements.push({
        data: {id: eid, source: edge.artifactID, target: edge.runID},
      });
    }
  });

  return graphElements;
}

function groupDagToCytoGraphEls(
  groupDag: GroupDag,
  artifactTypeName: string
): cytoscape.ElementDefinition[] {
  const {artifactGroups, runGroups, edgeGroups} = groupDag;
  const graphElements: cytoscape.ElementDefinition[] = [];

  _.forEach(artifactGroups, (ag, aid) => {
    graphElements.push({
      data: {
        id: 'artType-' + aid,
        label: `${ag.artifactTypeName} (${ag.artifacts.length})`,
        type: 'artifactType',
      },
      classes: ag.artifactTypeName === artifactTypeName ? 'this' : '',
    });
  });
  _.forEach(runGroups, (rg, rid) => {
    graphElements.push({
      data: {
        id: 'jobType-' + rid,
        label: `${rg.jobType} (${rg.runs.length})`,
        type: 'run',
      },
      classes: 'run',
    });
  });
  _.forEach(edgeGroups, (eg, eid) => {
    if (eg.dir === Direction.TowardArtifact) {
      graphElements.push({
        data: {
          id: eid,
          source: 'jobType-' + eg.jobType,
          target: 'artType-' + eg.artifactTypeName,
        },
      });
    } else {
      graphElements.push({
        data: {
          id: eid,
          source: 'artType-' + eg.artifactTypeName,
          target: 'jobType-' + eg.jobType,
        },
      });
    }
  });

  return graphElements;
}

const DAG_REQUEST_LIMIT = 200;

const ArtifactDag = makeComp(
  (props: ArtifactDagProps) => {
    const {
      entityName,
      projectName,
      artifactTypeName,
      artifactSequenceName,
      artifactCommitHash,
    } = props;
    const [limit] = useState(DAG_REQUEST_LIMIT);
    const artifactID = `${artifactSequenceName}:${artifactCommitHash}`;
    const [explodeDag, setExplodeDag] = useState(false);
    const [showAutoArtifacts, setShowAutoArtifacts] = useState(false);
    const [modificationCount, setModificationCount] = useState(0);
    const artifactFilterFn = useCallback(artifact => {
      return (
        (artifact.artifactTypeName !== 'code' ||
          !artifact.artifactSequenceName.startsWith('source-')) &&
        (artifact.artifactTypeName !== 'run_table' ||
          !artifact.artifactSequenceName.startsWith('run-'))
      );
    }, []);

    // We do this twice since useArtifactDagQuery is optimized to
    // run once and only once, regardless of inputs.
    const fullDagResults = useArtifactDagQueryRecursive(
      {
        entityName,
        projectName,
        artifactTypeName,
        artifactSequenceName,
        artifactCommitHash,
      },
      limit
    );
    const filteredDagResults = useArtifactDagQueryRecursive(
      {
        entityName,
        projectName,
        artifactTypeName,
        artifactSequenceName,
        artifactCommitHash,
      },
      limit,
      artifactFilterFn
    );
    const {dag, loading, limited} = useMemo(() => {
      if (showAutoArtifacts) {
        return fullDagResults;
      } else {
        return filteredDagResults;
      }
    }, [showAutoArtifacts, fullDagResults, filteredDagResults]);

    const cyRef = useRef<cytoscape.Core | null>(null);

    const attachEventHandlers = useCallback(
      (cy: cytoscape.Core) => {
        // make sure we only attach the event handlers once
        if (cy === cyRef.current) {
          return;
        }
        cyRef.current = cy;
        cy.on('tap', 'node', evt => {
          const nodeName = evt.target?.id();
          // node-naming scheme only works in exploded view
          if (!explodeDag || nodeName == null) {
            return;
          }
          const nodeNameSplit = nodeName.split('/');
          let pathSegments: string[] = [];
          if (nodeNameSplit.length === 3) {
            // run nodes take the form "entity/project/run_id" (3 fields)
            const [entity, project, runId] = nodeNameSplit;
            pathSegments = [entity, project, 'runs', runId, 'overview'];
          } else {
            // artifact nodes take the form "entity/project/artifact_type/artifact_name:hash_string"
            // e.g. wandb/my_project/model/resnet18_model:81794b32aee5d71b93d1
            const [entity, project, artifactType, artifactHash] = nodeNameSplit;
            const [artifactName, artifactHashStr] = artifactHash.split(':');
            pathSegments = [
              entity,
              project,
              'artifacts',
              artifactType,
              artifactName,
              artifactHashStr,
            ];
          }
          window.open(urlPrefixed(`/${pathSegments.join('/')}`), '_blank');
        });
      },
      [explodeDag]
    );

    const graphDOM = useMemo(() => {
      if (loading) {
        return <Loader active />;
      }
      const graphElements = explodeDag
        ? dagToCytoGraphEls(dag, artifactID)
        : groupDagToCytoGraphEls(makeGroupDag(dag), artifactTypeName);
      return (
        <CytoscapeComponent
          cy={attachEventHandlers}
          key={modificationCount}
          maxZoom={1.5}
          elements={graphElements}
          // If we set height to 100%, or try to use flex, the component starts
          // growing an infinite loop for some reason
          style={{width: '100%', height: '90%'}}
          layout={
            {
              name: 'dagre',
              rankDir: 'LR',
              // rankSep: 10,
              spacingFactor: 0.7,
              nodeDimensionsIncludeLabels: true,
            } as any
          }
          stylesheet={[
            {
              selector: 'node[label]',
              style: {
                label: 'data(label)',
                'font-size': '12px',
                'text-wrap': 'ellipsis',
                'text-max-width': '300',
                'text-margin-y': -4,
              },
            },
            {
              selector: 'node',
              style: {
                color: globals.gray800,
                'background-color': 'white',
                'border-color': globals.primary,
                'border-width': 2,
              },
            },
            {
              selector: 'node.this',
              style: {
                'background-color': globals.primary,
              },
            },
            {
              selector: 'node.run',
              style: {
                shape: 'rectangle',
              },
            },
            {
              selector: 'edge',
              style: {
                width: 2,
                'line-color': '#99ccdf',
                // opacity: 0.5,
                'curve-style': 'straight',
              },
            },
            {
              selector: 'edge',
              style: {
                'target-arrow-shape': 'triangle',
                'target-arrow-color': '#99ccdf',
                // 'arrow-opacity': 0.5,
              },
            },
          ]}
        />
      );
    }, [
      loading,
      dag,
      attachEventHandlers,
      modificationCount,
      artifactID,
      artifactTypeName,
      explodeDag,
    ]);

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Checkbox
            toggle
            checked={explodeDag}
            label={'Explode'}
            onClick={() => {
              setExplodeDag(!explodeDag);
              setModificationCount(modificationCount + 1);
            }}
          />
          <Checkbox
            toggle
            checked={showAutoArtifacts}
            label={'Include generated Artifacts'}
            onClick={() => {
              setShowAutoArtifacts(!showAutoArtifacts);
              setModificationCount(modificationCount + 1);
            }}
          />
        </div>
        <>
          {!loading && limited && (
            <Segment textAlign="center">
              {`Fetch limited to ${limit} artifacts`}{' '}
              {/* Changing limit doesn't work, the DagQuery hook seems to skip an artifact, and
            think there's no more
            TODO: fix (although it's likely we'll change how this dag works entirely)
            */}
              {/* <Button onClick={e => setLimit(limit + DAG_REQUEST_LIMIT)}>
              Fetch more
            </Button> */}
            </Segment>
          )}
          {graphDOM}
        </>
      </>
    );
  },
  {id: 'ArtifactDag'}
);

export default ArtifactDag;
