import '../css/DeleteRunsButton.less';
import * as globals from '../css/globals.styles';

import React, {useState} from 'react';
import {Button, Checkbox, Modal, Popup} from 'semantic-ui-react';

import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface DeleteRunsButtonProps {
  selectedCount: number;
  outputArtifactsCount: number;
  onDelete(deleteArtifacts: boolean): void;
}

const DeleteRunsButton: React.FC<DeleteRunsButtonProps> = makeComp(
  ({selectedCount, onDelete, outputArtifactsCount}) => {
    const [deleteArtifacts, setDeleteArtifacts] = useState(false);
    const [open, setOpen] = useState(false);

    let buttonClass;
    if (open) {
      buttonClass = 'action-button--active';
    } else {
      buttonClass = 'action-button--static';
    }
    const button = (
      <Button
        size="tiny"
        className={
          buttonClass +
          ' enable-pointer-events wb-icon-button only-icon' +
          (selectedCount === 0 ? ' disabled' : '')
        }
        onClick={() => {
          if (selectedCount !== 0) {
            setOpen(true);
          }
        }}>
        <LegacyWBIcon name="delete" />
      </Button>
    );

    if (selectedCount === 0) {
      return (
        <Popup
          basic
          on="hover"
          popperModifiers={{
            preventOverflow: {
              // prevent popper from erroneously constraining the popup to the
              // table header
              boundariesElement: 'viewport',
            },
          }}
          content={
            <p className="hint-text small">
              Select runs to delete by hovering over a row and clicking the
              checkbox on the left.
            </p>
          }
          trigger={button}
        />
      );
    }

    return (
      <React.Fragment>
        <Popup
          basic
          style={{
            color: globals.white,
            backgroundColor: globals.error,
          }}
          popperModifiers={{
            preventOverflow: {
              // prevent popper from erroneously constraining the popup to the
              // table header
              boundariesElement: 'viewport',
            },
          }}
          position="top center"
          content="Delete from project"
          trigger={button}
        />
        <Modal
          className="delete-runs-modal"
          open={open}
          onClose={() => setOpen(false)}>
          <Modal.Header className="delete-runs-modal__header">
            Permanently delete runs
          </Modal.Header>
          <Modal.Content>
            <p>
              Delete
              <b> {selectedCount} </b>
              {`run${selectedCount === 1 ? '' : 's'} `}
              from your project? Deleted runs will be removed everywhere,
              including reports. This action cannot be undone, so double check
              before you click delete.
            </p>
            {outputArtifactsCount > 0 && (
              <Checkbox
                label={`Delete ALL ${outputArtifactsCount} output artifact(s)`}
                checked={deleteArtifacts}
                onChange={() => setDeleteArtifacts(!deleteArtifacts)}
              />
            )}
          </Modal.Content>
          <Modal.Actions className="delete-runs-modal__actions">
            <Button
              color="red"
              disabled={outputArtifactsCount > 0 && !deleteArtifacts}
              onClick={() => {
                onDelete(deleteArtifacts);
                setOpen(false);
              }}>
              {`Delete ${selectedCount} run${selectedCount === 1 ? '' : 's'}`}
            </Button>
            <Button
              className="delete-runs-modal__cancel"
              onClick={() => setOpen(false)}>
              Nevermind
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  },
  {id: 'DeleteRunsButton', memo: true}
);

export default DeleteRunsButton;
