import * as React from 'react';
import {useLayoutEffect, useState} from 'react';
import {labelComponent} from '../util/media';
import makeComp from '../util/profiler';
import {runLink} from '../util/runhelpers';
import {Struct} from '../util/types';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import BokehViewer from './Panel2/BokehViewer';

const BokehCard = makeComp(
  (props: MediaCardProps) => {
    const {run, globalStep, mediaKey, mediaIndex, runSignature, tileMedia} =
      props;
    const blob = tileMedia?.blob;

    const [bokehJson, setBokehJson] = useState<Struct | null>(null);
    useLayoutEffect(() => {
      if (blob == null) {
        return;
      }
      (async () => {
        try {
          const fileText = await new Response(blob).text();
          setBokehJson(JSON.parse(fileText));
        } catch {
          // TODO(axel): show error state for bad Bokeh file
        }
      })();
    }, [blob]);

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    return (
      <BokehViewer
        headerElements={labelComponent(props, tileMedia?.step, titleLink)}
        notFoundElements={[
          <MessageMediaNotFound
            key={mediaKey}
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaIndex={mediaIndex}
            mediaType="bokeh"
          />,
        ]}
        contentNotFound={tileMedia == null}
        bokehJson={bokehJson}
      />
    );
  },
  {id: 'BokehCard', memo: true}
);

export default BokehCard;
