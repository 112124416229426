import '../css/SubscribeForm.less';

import React, {
  useState,
  FunctionComponent,
  useCallback,
  useEffect,
} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button, Message, Input} from 'semantic-ui-react';

import {
  propagateErrorsContext,
  extractErrorMessageFromApolloError,
  doNotRetryContext,
} from '../util/errors';
import {useUpdateCustomerPaymentMethodMutation} from '../generated/graphql';
import makeComp from '../util/profiler';

export interface UpdatePaymentFormProps {
  custoEmail: string;
  organizationId: string;
  onUpdateCompleted(): void;
}

export const UpdatePaymentForm: FunctionComponent<UpdatePaymentFormProps> =
  makeComp(
    props => {
      const [error, setError] = useState<string | null>(null);
      const [success, setSuccess] = useState<string | null>(null);
      const [custoName, setCustoName] = useState('');
      const [formDisabled, setFormDisabled] = useState(false);

      const stripe = useStripe();
      const elements = useElements();
      useEffect(() => {
        if (!stripe || !elements) {
          setFormDisabled(true);
        } else {
          setFormDisabled(false);
        }
      }, [elements, stripe, setFormDisabled]);

      const {custoEmail, organizationId, onUpdateCompleted} = props;

      const [updatePaymentMethodMutation] =
        useUpdateCustomerPaymentMethodMutation({
          context: {...propagateErrorsContext(), ...doNotRetryContext()},
        });

      const handleUpdatePaymentMethod = useCallback(async () => {
        if (!stripe || !elements) {
          throw new Error('Stripe not loaded.');
        }

        if (custoName == null || custoName === '') {
          setError('Must provide cardholder name.');
          return false;
        }

        const cardElement = elements.getElement(CardElement);
        if (cardElement == null) {
          throw new Error('Stripe card element not loaded.');
        }
        const paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: custoName,
            email: custoEmail,
          },
        });

        if (
          paymentMethod.error != null ||
          paymentMethod.paymentMethod == null
        ) {
          setError(
            paymentMethod.error?.message ?? 'Error validating payment method.'
          );
          return false;
        }

        try {
          const updatePaymentMethodResult = await updatePaymentMethodMutation({
            variables: {
              organizationId,
              paymentMethod: paymentMethod.paymentMethod.id,
            },
          });
          if (
            updatePaymentMethodResult.errors == null &&
            updatePaymentMethodResult.data?.updateCustomerPaymentMethod?.success
          ) {
            setSuccess('Your payment method was successfully updated.');
            onUpdateCompleted();
            return true;
          } else {
            const errs = updatePaymentMethodResult.errors?.map(
              ({message}) => message as string
            );
            setError(
              'Error updating payment method' +
                (errs != null ? `: ${errs.join(', ')}` : '')
            );
            return false;
          }
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
          setError(errMsg);
          return false;
        }
      }, [
        custoEmail,
        custoName,
        elements,
        onUpdateCompleted,
        organizationId,
        stripe,
        updatePaymentMethodMutation,
      ]);

      const onUpdatePaymentMethod = useCallback(async () => {
        setFormDisabled(true);
        setError(null);
        setSuccess(null);

        const updatedSuccessfully = await handleUpdatePaymentMethod();

        if (!updatedSuccessfully) {
          setFormDisabled(false);
        }
      }, [handleUpdatePaymentMethod]);

      return (
        <div className="checkout-form">
          {error != null && (
            <Message negative content={error} className="alert" />
          )}
          {success != null && (
            <Message positive content={success} className="alert" />
          )}
          <Input
            className="custo-input"
            placeholder="Name on card"
            onChange={(_0, data) => setCustoName(data.value)}
            disabled={formDisabled}
          />
          <div className="card-element">
            <CardElement
              options={{
                disabled: formDisabled,
                style: {
                  base: {
                    fontSize: '15px',
                    color: '#424770',
                    fontFamily: 'sans-serif',
                    fontWeight: '300',
                    letterSpacing: '0.025em',
                    '::placeholder': {
                      color: 'rgba(55, 59, 62, 0.45)',
                    },
                  },
                },
              }}
            />
          </div>
          <div className="subscribe-button-group">
            <Button
              className="subscribe-button"
              color="blue"
              onClick={onUpdatePaymentMethod}
              disabled={formDisabled}>
              Update
            </Button>
          </div>
        </div>
      );
    },
    {id: 'UpdatePaymentForm'}
  );
