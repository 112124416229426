import * as globals from '../css/globals.styles';
import styled from 'styled-components';
import {Header, Button, Checkbox} from 'semantic-ui-react';
import FancyPopup from '../components/FancyPage/FancyPopup';
import {TABLET_WIDTH, SMALL_TABLET_WIDTH} from '../css/globals.styles';
import {TargetBlank} from '../util/links';

export const SECTION_BORDER_RADIUS = 6;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
  }

  @media (max-width: ${SMALL_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const MobileSectionContainer = styled.div`
  @media (min-width: ${SMALL_TABLET_WIDTH + 1}px) {
    display: none;
  }

  @media (max-width: 520px) {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

export const SectionColumn = styled.div<{grow?: true}>`
  display: flex;

  ${({grow}) => grow && `flex-grow: 1;`}

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    flex-direction: column;
    justify-content: space-between;

    &:not(:first-child) {
      margin-left: 32px;
    }
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    width: 100%;
    flex-direction: row;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
`;

export const Section = styled.div<{grow?: true}>`
  background-color: ${globals.white};
  border-radius: ${SECTION_BORDER_RADIUS}px;
  border: 1px solid ${globals.border};
  padding: 32px;

  ${({grow}) => grow && `flex-grow: 1;`}

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    &:not(:first-child) {
      margin-left: 32px;
    }
  }

  @media (max-width: ${SMALL_TABLET_WIDTH}px) {
    &&&&& {
      margin: 32px 0 0;
    }
  }

  @media (max-width: 520px) {
    &&&&& {
      border-radius: 0;
      border-left: none;
      border-right: none;
      margin-top: 24px;
    }
  }
`;

export const SectionHeader = styled.h3`
  font-size: 20px;
  margin: 0;
`;

export const SectionText = styled.p`
  margin: 16px 0 0;
`;

export const SectionTextSpecial = styled(SectionText as any)`
  color: ${globals.gray500};
  font-style: italic;
`;

export const SectionCheckboxContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;

export const SectionCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

export const SectionButton = styled(Button)`
  &&&&& {
    margin: 24px 0 0;
    padding-left: 1em;
    padding-right: 1em;
  }
`;

export const SidebarHeader = styled(Header)`
  color: ${globals.gray500};
`;

export const SidebarBlock = styled.div`
  position: relative;
  margin-top: 24px;
`;

export const SidebarBlockHeader = styled.h3`
  margin: 0;
  font-size: 20px;
`;

export const SidebarBlockLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 16px;

  a {
    color: ${globals.gray800};

    &:hover {
      color: ${globals.primaryText};
    }
  }
`;

export const SidebarBlockSubsection = styled.div`
  padding-left: 34px;
  margin-top: 16px;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  a {
    color: ${globals.gray800};

    &:hover {
      color: ${globals.primaryText};
    }
  }
`;

export const SidebarBlockLinkSmall = styled(TargetBlank)`
  &&& {
    color: ${globals.gray600};
    font-size: 14px;

    &:hover {
      color: ${globals.primaryText};
    }
  }
`;

export const SidebarBlockLinkIcon = styled.img`
  margin-right: 10px;
`;

export const AlertPopup = styled(FancyPopup)`
  left: 116px;
  width: 168px !important;
  height: 62px;
  padding-left: 14px;
  padding-top: 14px;
  padding-bottom: 14px;

  span {
    padding-right: 20px;
  }
`;
