import styled from 'styled-components';
import LegacyWBIcon from '../elements/LegacyWBIcon';

export const ViewAllIcon = styled(LegacyWBIcon)`
  &&&&&&&&&& {
    position: relative;
    top: 2px;
    font-size: 16px;
    padding-left: 2px !important;
  }
`;
