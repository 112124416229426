import * as React from 'react';
import {useContext} from 'react';
import * as Redux from '../../../types/redux';

import * as Types from './types';
import * as ViewHooks from '../hooks';
import makeComp from '../../../util/profiler';
import {useInView} from 'react-intersection-observer';

const InteractStateContext = React.createContext<Types.Ref | undefined>(
  undefined
);

interface InteractStateProviderProps {
  children: React.ReactNode;
}

export const InteractStateProvider = makeComp(
  (props: InteractStateProviderProps) => {
    const ref = ViewHooks.useNewObjectRef('interactState', Types.EMPTY_STATE);
    return (
      <InteractStateContext.Provider value={ref.ready ? ref.ref : undefined}>
        {props.children}
      </InteractStateContext.Provider>
    );
  },
  {id: 'InteractStateProvider'}
);

// Performant way to get notified when interactState relevant to your component
// changes.
// Pass a mapFn and get back a tuple [domRef, value]. Assign the domRef to the
// target element in your component.
// Value will only update when domRef is onscreen, and you'll only receive
// updates when the result of your mapFn changes.
export function useInteractStateWhenOnScreen<R>(
  mapFn: (interactState: Types.InteractState) => R
) {
  let ref = useContext(InteractStateContext);
  if (ref == null) {
    throw new Error('InteractState context not provided');
  }
  const [domRef, onScreen] = useInView();
  if (!onScreen) {
    ref = {type: 'interactState', id: 'empty', viewID: 'emptyInteractState'};
  }
  return [domRef, ViewHooks.useWholeMapped(ref, mapFn)] as const;
}

export function useInteractState<R>(
  mapFn: (interactState: Types.InteractState) => R
) {
  const ref = useContext(InteractStateContext);
  if (ref == null) {
    throw new Error('InteractState context not provided');
  }
  return ViewHooks.useWholeMapped(ref, mapFn);
}

export function useInteractStateAction<ArgType extends any[]>(
  fn: (ref: Types.Ref, ...fnArgs: ArgType) => Redux.DispatchableAction
) {
  const ref = useContext(InteractStateContext);
  if (ref == null) {
    throw new Error(
      'InteractState context not provided, make sure to use InteractStateProvider somewhere in the React tree'
    );
  }
  return ViewHooks.useViewAction(ref, fn);
}
