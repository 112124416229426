import * as React from 'react';
import {
  Alert,
  Integration,
  Settings,
  useEntityQuery,
  EntityQuery,
  Member,
} from '../generated/graphql';
import {Graph} from '../types/graphql';

import {AccessOptions} from '../util/permissions';
import makeComp from '../util/profiler';
import {RouteComponentProps} from 'react-router';

export interface MemberData {
  id: string;
  admin: boolean;
  pending: boolean;
  username: string;
  name: string;
  email: string;
  photoUrl: string;
  accountType: string;
  apiKey: string;
}

export function toMemberData(m: Member): MemberData {
  return {
    id: m.id ?? '',
    admin: m.admin ?? false,
    pending: m.pending ?? false,
    username: m.username ?? '',
    name: m.name ?? '',
    email: m.email ?? '',
    photoUrl: m.photoUrl ?? '',
    accountType: m.accountType ?? '',
    apiKey: m.apiKey ?? '',
  };
}

export interface EntityData {
  id: string;
  name: string;
  available: boolean;
  photoUrl: string;
  readOnly: boolean;
  storageBytes: number;
  readOnlyAdmin: boolean;
  isTeam: boolean;
  privateOnly: boolean;
  codeSavingEnabled: boolean;
  settings: Settings;
  defaultAccess: AccessOptions;
  members: MemberData[];
  integrations: Graph<Integration>;
  defaultAlerts: Alert[];
  isPaid?: boolean;
  computeHours?: number;
  rateLimits?: {
    filestream: number;
    graphql: number;
  };
}

interface UserData {
  id: string;
  username: string;
  accountType: string;
}

export interface Variables {
  name: string;
}

export interface Data {
  entity: EntityData;
  user: UserData;
}

///// HOC definition

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  entity: undefined;
  user: undefined;
  refetch: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  entity: EntityQuery['entity'];
  user: EntityQuery['user'];
  refetch(): void;
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface TeamQueryResultProps {
  teamQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withTeamQuery = (
  Component: React.ComponentType<TeamQueryResultProps & RouteComponentProps>
) =>
  makeComp(
    (inputProps: RouteComponentProps<{entityName: string}>) => {
      const {match} = inputProps;
      const variables = {
        name: match.params.entityName,
      };

      const entityQuery = useEntityQuery({
        fetchPolicy: 'cache-and-network',
        variables,
      });

      if (entityQuery.loading || entityQuery.data == null) {
        return (
          <Component
            {...inputProps}
            teamQuery={{
              loading: true,
              entity: undefined,
              user: undefined,
              refetch: undefined,
            }}
          />
        );
      }

      return (
        <Component
          {...inputProps}
          teamQuery={{
            loading: false,
            entity: entityQuery.data.entity,
            user: entityQuery.data.user,
            refetch: entityQuery.refetch,
          }}
        />
      );
    },
    {id: 'withTeamQuery'}
  );
