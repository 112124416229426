export interface BenchmarkFields {
  overview: string;
  submissionInstructions: string;
  keys: string[];
  sortMetric: string;
  sortAsc: boolean;
  codeRequired: boolean;
  embargo: boolean;
}

export function fromJSON(json: any): BenchmarkFields {
  return json.fields;
}

export function toJSON(fields: BenchmarkFields): string {
  return JSON.stringify({fields});
}
