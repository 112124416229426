import {JSONparseUserFile} from '@wandb/cg/browser/utils/jsonnan';
import * as _ from 'lodash';
import * as React from 'react';
import {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {Card} from 'semantic-ui-react';
import {getLastHistoryRowWithKey, labelComponent} from '../util/media';
import makeComp from '../util/profiler';
import {runLink} from '../util/runhelpers';
import PanelError from './elements/PanelError';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';

// a single image card

const PlotlyCard: React.FunctionComponent<MediaCardProps> = makeComp(
  props => {
    const {run, mediaKey, mediaIndex, runSignature, width, height} = props;

    // Div to stick plotly chart into
    const plotlyDivRef = React.useRef<HTMLDivElement>(null);
    const [plotlyRenderError, setPlotlyRenderError] = useState(false);

    const {plotlyData, step, error} = usePlotlyCardData(props);

    // Load plotly library
    type PlotlyType = typeof import('plotly.js');
    const [Plotly, setPlotly] = useState<PlotlyType | null>(null);
    useEffect(() => {
      import('plotly.js').then(setPlotly);
    }, []);

    const plotlyNewPlot = useCallback(
      (...args: Parameters<PlotlyType['react']>): void => {
        if (Plotly == null) {
          return;
        }
        let err = false;
        try {
          // Changed from Plotly.newPlot to Plotly.react to fix disappearing plots due to webgl context limit
          // see https://github.com/plotly/plotly.js/issues/2333#issuecomment-391835536
          Plotly.react(...args);
        } catch (e) {
          err = true;
        }
        setPlotlyRenderError(err);
      },
      [Plotly]
    );
    const plotlyNewPlotDebounced = useCallback(_.debounce(plotlyNewPlot, 100), [
      plotlyNewPlot,
    ]);

    // Use the plotly library to render the loaded plotly data to the div
    useEffect(() => {
      // Prevent ugly loading states
      if (
        width === 0 ||
        Plotly == null ||
        plotlyDivRef.current == null ||
        plotlyData == null
      ) {
        return;
      }

      const copy = _.cloneDeep(plotlyData);
      if (copy.layout) {
        if ('width' in copy.layout) {
          delete copy.layout.width;
        }
        copy.layout.height = height + 14;
        copy.layout.autosize = true;
        // TODO: probably need better logic here...
        if (copy.layout.margin) {
          copy.layout.margin.t = 0;
          copy.layout.margin.l = 0;
          copy.layout.margin.r = 0;
          copy.layout.margin.b = 0;
          copy.layout.pad = 0;
        }
      }

      // Webgl is way faster when these are larger. Unfortunately
      // we also run out of GL contexts this way.
      if (_.isArray(copy.data)) {
        copy.data = copy.data.map((d: any) => ({
          ...d,
          type: d.type === 'scatter' ? 'scattergl' : d.type,
        }));
      }

      // TODO: get datarevision to work with Plotly.react
      // copy.layout.datarevision = this.state.currentStepIndex;

      plotlyNewPlotDebounced(plotlyDivRef.current, copy.data, copy.layout, {
        responsive: true,
      });
    }, [plotlyData, Plotly, plotlyNewPlotDebounced, width, height]);

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    return (
      <Card className="plotly-card" style={{width: '100%'}}>
        {/* TITLE (Run Name or Description) */}
        {labelComponent(props, step, titleLink)}
        <div ref={plotlyDivRef} />
        {plotlyRenderError ? (
          <PanelError
            message={
              <div>Plotly rendering error, try resizing this panel.</div>
            }
          />
        ) : (
          plotlyData == null &&
          (error != null ? (
            <PanelError
              className="media-missing"
              message={<div>{error}</div>}
            />
          ) : (
            <MessageMediaNotFound
              mediaIndex={mediaIndex}
              mediaKey={mediaKey}
              stepIndex={step}
              mediaType="table"
            />
          ))
        )}
      </Card>
    );
  },
  {id: 'PlotlyCard', memo: true}
);

export default PlotlyCard;

type PlotlyCardData = {
  plotlyData: any;
  step: number | undefined;
  error: string | null;
};

function usePlotlyCardData(props: MediaCardProps): PlotlyCardData {
  const {run, globalStep, tileMedia, mediaKey} = props;
  const blob = tileMedia?.blob;

  // If this is file-based media, we put the contents of the file here once
  // loaded
  const [filePlotlyData, setFilePlotlyData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (blob == null) {
      return;
    }
    (async () => {
      const fileContents = await new Response(blob).text();
      const parsedPlot = JSONparseUserFile(fileContents);
      if (parsedPlot.error) {
        setError("Couldn't parse JSON data.");
      } else {
        setFilePlotlyData(parsedPlot.result);
      }
    })();
  }, [blob]);

  const historyRow = getLastHistoryRowWithKey(run, mediaKey, globalStep);

  return {
    plotlyData: filePlotlyData ?? historyRow?.[mediaKey]?.plot ?? null,
    step: tileMedia?.step ?? historyRow?._step,
    error,
  };
}
