// process.env vars (PUBLIC_URL, NODE_ENV, and anything starting with REACT_APP_) are injected
// by create-react-app at the time the frontend is built. Because we need different vars for
// different environments, this means we have to build the frontend multiple times, which is
// incredibly time-consuming. This file, along with inject-index-env.sh, serves as a workaround.

// inject-index-env generates a short js file that sets window.CONFIG based on the env
// vars. Then we load that file in index.html, before any other code runs. Then in this
// file, the `config` var will be populated from window.CONFIG. That way, we can do the
// frontend build once and quickly generate a version for each environment.

// PUBLIC_URL is a special case, because create-react-app injects it into the bundle at
// many places to identify paths to static assets. to deal with this, we always set PUBLIC_URL
// to a placeholder value, and then replace all instances of it with find/sed (this also
// happens in inject-index.env.sh)

// Config interface
interface Config {
  AUTH_STUB_JWT: string;
  BACKEND_HOST: string; // NOTE: Don't use this directly. Call backendHost() below.
  ALTERNATE_BACKEND_HOST: string;
  ALTERNATE_BACKEND_PORT: string;
  ANALYTICS_DISABLED: boolean; // used to disable collection of third-party analytics data
  CI: boolean;
  ENABLE_DEBUG_FEATURES: boolean;
  ENABLE_SERVICE_WORKER: boolean;
  ENVIRONMENT_IS_PRIVATE: boolean;
  ENVIRONMENT_NAME: string;
  HOST: string;
  SENTRY_DSN?: string;
  SENTRY_ENVIRONMENT?: string;
  GITHUB_CLIENT_ID?: string;
  STRIPE_API_KEY?: string;
  MAX_BYTES: number;
  MAX_COMPUTE_HOURS_IN_SECONDS: number;
  MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS: number;
  GIT_TAG: string;
  PUBLIC_URL: string; // NOTE: Don't use this directly. Call urlPrefixed() below.
  WEAVE_BACKEND_HOST: string; // NOTE: Don't use this directly. Call backendWeaveHost() below.
  DISABLE_TELEMETRY: boolean; // used to disable collection of local analytics data
}

declare global {
  interface Window {
    CONFIG?: Config;
  }
}

const defaults = {
  AUTH_STUB_JWT: '',
  BACKEND_HOST: '',
  ALTERNATE_BACKEND_HOST: '',
  ALTERNATE_BACKEND_PORT: '',
  ANALYTICS_DISABLED: true,
  ENABLE_DEBUG_FEATURES: false,
  ENABLE_SERVICE_WORKER: false,
  ENVIRONMENT_IS_PRIVATE: false,
  CI: false,
  ENVIRONMENT_NAME: '',
  HOST: '',
  SENTRY_DSN: '',
  SENTRY_ENVIRONMENT: '',
  GITHUB_CLIENT_ID: '',
  STRIPE_API_KEY: '',
  MAX_BYTES: 100000000000, // TODO: remove this once billing for storage is hooked up
  MAX_COMPUTE_HOURS_IN_SECONDS: 900000, // 250 * 60 * 60
  MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS: 18000000, // 5000 * 60 * 60
  GIT_TAG: '',
  PUBLIC_URL: '',
  WEAVE_BACKEND_HOST: '',
  DISABLE_TELEMETRY: false,
};
const config: Config = Object.assign(
  defaults,
  (typeof window !== 'undefined' && window.CONFIG) ?? {}
);

export default config;

export const envIsProd = config.ENVIRONMENT_NAME === 'production';
export const envIsDev =
  config.ENVIRONMENT_NAME === 'development' ||
  config.ENVIRONMENT_NAME === 'devprod';
export const envIsTest = config.ENVIRONMENT_NAME === 'test';
export const envIsIntegration = config.ENVIRONMENT_NAME === 'integration';
export const envIsLocal = envIsLocalComputed();
export const envIsCloudOnprem =
  config.ENVIRONMENT_IS_PRIVATE && config.ENVIRONMENT_NAME !== 'local';

// To make testing simpler, we use this helper function to allow config modification
function envIsLocalComputed() {
  return config.ENVIRONMENT_IS_PRIVATE && config.ENVIRONMENT_NAME === 'local';
}

export const urlPrefixed = (path?: string, host: boolean = true) => {
  // Ensures urls are fully qualified and properly formed, especially important
  // for allowing users to run the app under a sub-path, i.e. https://mycompany.com/wandb.
  // This should be used anytime we're calling wandb.open, window.location.href, or fetch(...).
  // We have a custom eslint rule wandb/no-unprefixed-urls that will assert we use this helper.
  let url = new URL(window.location.origin);
  // Never use a prefix if we're not in local
  if (envIsLocalComputed()) {
    try {
      url = config.PUBLIC_URL.startsWith('http')
        ? new URL(config.PUBLIC_URL)
        : new URL(config.PUBLIC_URL, url.href);
      url.hostname = window.location.hostname;
    } catch (error) {
      console.error(error);
    }
  }

  if (path != null) {
    // This logic does the magic joining of a sub-path and path in the app.
    url = new URL(url.href.replace(/\/$/, '') + path);
  } else if (!url.href.endsWith('/')) {
    // Some routers require a trailing / at the root
    url = new URL(url.href + '/');
  }
  if (!host) {
    return url.pathname;
  }
  return url.href;
};

export const backendHost = (path?: string) => {
  // Check for alternate hostname in origin.
  const alternateHostBase = config.ALTERNATE_BACKEND_HOST.replace(
    'https://api.',
    ''
  );
  if (
    alternateHostBase !== '' &&
    window.location.origin.indexOf(alternateHostBase) !== -1
  ) {
    // Yeah I know this is insane. It's really just a single use case thing for one customer.
    // They have two domain names pointing to one instance, and the second one needs a custom port too.
    let host = config.ALTERNATE_BACKEND_HOST;
    if (config.ALTERNATE_BACKEND_PORT) {
      host = `${host}:${config.ALTERNATE_BACKEND_PORT}`;
    }

    return host;
  }

  // Support for sub-path mounting
  if (config.BACKEND_HOST === '' && config.PUBLIC_URL !== '') {
    // The backendHost should never have a trailing slash, unlike urlPrefixed
    return urlPrefixed(path).replace(/\/$/, '');
  }

  return config.BACKEND_HOST + (path ?? '');
};

// Defining this now so we can corral all access to this value from the start.
export const backendWeaveHost = () => {
  return config.WEAVE_BACKEND_HOST;
};
