import * as Normalize from '../normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.TempSelectionObjSchema>(
  'temp-selections',
  whole => whole
);

export const denormalize = Normalize.denormFn<Types.TempSelectionObjSchema>(
  part => part
);
