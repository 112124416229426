import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {AccessOptions} from '../../util/permissions';

import {useQuery} from './query';
import {useDispatch} from 'react-redux';
import * as ViewActionsInternal from '../views/actionsInternal';
import {ObjType} from '../views/types';
import {ID} from '@wandb/cg/browser/utils/string';

import {useMemo} from 'react';
import {VIEW_METADATA_FRAGMENT} from '../views/graphql';

///// Query definition

export const REPORT_PAGE_QUERY = gql`
  query ReportPage($projectName: String!, $entityName: String!) {
    project(name: $projectName, entityName: $entityName) {
      id
      access
      readOnly
      entity {
        id
        name
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
      }
    }
  }
`;

export interface ReportPageQueryData {
  project: {
    id: string;
    access: AccessOptions;
    readOnly: boolean;
    entity: {
      id: string;
      name: string;
      defaultAccess: AccessOptions;
      readOnlyAdmin: boolean;
      privateOnly: boolean;
      isTeam: boolean;
    };
  };
}

export function useReportPageQuery(
  variables: Generated.ReportPageQueryVariables
) {
  return useQuery<ReportPageQueryData, typeof variables>(REPORT_PAGE_QUERY, {
    variables,
  });
}

// Used for fetching potential report authors
export const TEAM_MEMBERS_QUERY = gql`
  query TeamMembers($entityName: String!) {
    entity(name: $entityName) {
      id
      members {
        id
        username
        name
        photoUrl
      }
    }
  }
`;

export const REPORT_QUERY = gql`
  query Report($viewId: ID!) {
    view(id: $viewId) {
      id
      type
      ...ViewFragmentMetadata2
      spec
    }
  }
  ${VIEW_METADATA_FRAGMENT}
`;

export interface ReportQueryData {
  view: {
    type: ObjType;
    id: string;
    spec: string;
  };
}

/**
 * This replaces useReport in state/reports/hooks.
 * I'm not sure if I really needed to replace it, but useReport was incredibly
 * hard to understand.
 * This does have a problem where it doesn't unload after it's done. I need to fix that.
 */
export function useReportQuery(variables: Generated.ReportQueryVariables) {
  const dispatch = useDispatch();
  const cid = useMemo(() => ID(), []);

  return {
    cid,
    query: useQuery<ReportQueryData, typeof variables>(
      Generated.ReportDocument,
      {
        variables,
        onCompleted: result => {
          const action = ViewActionsInternal.loadFinished(
            cid,
            {...result.view, spec: JSON.parse(result.view.spec)} as any,
            true
          );
          dispatch(action);
        },
      }
    ),
  };
}
