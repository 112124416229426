import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';

export const BeamerIconDiv = styled.div<{absolute?: true}>`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: #ff3e43;
  color: ${globals.white};
  font-size: 11px;
  font-family: arial;
  text-align: center;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: -3px;
      right: -3px;
    `}
`;
