export function getABTestValue(key: string, values: any[]): any {
  const storedValue = getValue(key);
  if (storedValue != null) {
    return storedValue;
  }

  const vi = Math.floor(Math.random() * values.length);
  const v = values[vi];
  saveValue(key, v);
  return v;
}

export function getABTestBool(key: string): boolean {
  return getABTestValue(key, [true, false]);
}

export function toABTestKey(key: string): string {
  return `WANDB_AB_TEST/${key}`;
}

function saveValue(key: string, value: any): void {
  const abKey = toABTestKey(key);
  localStorage.setItem(abKey, JSON.stringify(value));
  window.analytics.identify({[abKey]: value});
}

function getValue(key: string): any | null {
  const abKey = toABTestKey(key);
  const jsonVal = localStorage.getItem(abKey);
  if (jsonVal == null) {
    return null;
  }
  return JSON.parse(jsonVal);
}
