import React from 'react';
import {Checkbox} from 'semantic-ui-react';
import {PropertyEditorComponent} from './property-editors';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

/**
 * A simple checkbox editor for a boolean property.
 * Displays indeterminate state for mixed values.
 * Default is required.
 * @param props
 */
const CheckboxPropertyEditor: PropertyEditorComponent<'checkbox'> = makeComp(
  props => {
    const displayedVal =
      props.values.length === 1 ? props.values[0] : undefined;
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        <Checkbox
          indeterminate={props.values.length > 1}
          checked={displayedVal}
          onChange={(e, {value, checked}) => {
            props.save(checked);
          }}></Checkbox>
      </InspectorPropertyWrapper>
    );
  },
  {id: 'CheckboxPropertyEditor'}
);

export default CheckboxPropertyEditor;
