import * as React from 'react';
import {useState} from 'react';
import {Checkbox} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import RoutedTab from '../RoutedTab';
import * as S from './RegistryPage.styles';
import * as CG from '@wandb/cg/browser/graph';
import * as PanelRegistry from './PanelRegistry';
import * as LibPanel from './panellib/libpanel';
import {useHistory} from 'react-router-dom';
import * as Types from '@wandb/cg/browser/model/types';
import * as CGTypes from '@wandb/cg/browser/types';

const OpArgs: React.FC<{inputTypes: CGTypes.InputTypes}> = makeComp(
  props => {
    const {inputTypes} = props;
    const argNames = Object.keys(inputTypes);
    return (
      <>
        (
        {argNames.map((argName, i) => (
          <S.OpArg>
            <S.OpArgName>{argName}</S.OpArgName>:{' '}
            <S.Type>{Types.toString(inputTypes[argName])}</S.Type>
            {i !== argNames.length - 1 && ', '}
          </S.OpArg>
        ))}
        )
      </>
    );
  },
  {id: 'OpArgs'}
);

const OpReturnType: React.FC<{returnType: CGTypes.OutputType}> = makeComp(
  props => {
    const {returnType} = props;
    return typeof returnType === 'function' ? (
      <span>'[function of input types]'</span>
    ) : (
      <S.Type>{Types.toString(returnType)}</S.Type>
    );
  },
  {id: 'OpArgs'}
);

const OpRegistry: React.FC<{}> = makeComp(
  props => {
    const [showHidden, setShowHidden] = useState(false);
    let ops = Object.values(CG.allGlobalOps());
    if (!showHidden) {
      ops = ops.filter(opDef => !opDef.hidden);
    }
    return (
      <div>
        <S.ControlArea>
          <S.LabeledControl>
            <S.ControlLabel>Show hidden</S.ControlLabel>
            <Checkbox
              toggle
              checked={showHidden}
              onChange={async (e, value) =>
                setShowHidden(value.checked as boolean)
              }
            />
          </S.LabeledControl>
        </S.ControlArea>
        <div>{ops.length} ops</div>
        {ops.map(opDef => (
          <S.Op>
            {opDef.kind && <S.OpKind>{opDef.kind}</S.OpKind>}
            <S.OpName>{opDef.name}</S.OpName>
            <S.OpBody>
              <OpArgs inputTypes={opDef.inputTypes} />
              <S.Sep>{'=>'}</S.Sep>
              <OpReturnType returnType={opDef.outputType} />
            </S.OpBody>
          </S.Op>
        ))}
      </div>
    );
  },
  {id: 'OpRegistry'}
);

const PanelRegistryComp: React.FC<{}> = makeComp(
  props => {
    return (
      <table>
        <tr>
          <th>id</th>
          <th>display name</th>
        </tr>
        {PanelRegistry.PanelSpecs.map(ps => (
          <tr>
            <td>{ps.id}</td> <td>{LibPanel.getDisplayName(ps)}</td>
          </tr>
        ))}
      </table>
    );
  },
  {id: 'PanelRegistry'}
);

const TypeRegistry: React.FC<{}> = makeComp(
  props => {
    return <div>TODO</div>;
  },
  {id: 'TypeRegistry'}
);

interface RegistryPageProps {
  match: {
    params: {
      tab?: string;
    };
  };
}

const RegistryPage: React.FC<RegistryPageProps> = makeComp(
  props => {
    const {tab} = props.match.params;
    const history = useHistory();
    return (
      <S.Page>
        <RoutedTab
          history={history}
          baseUrl="/__registry"
          tabSlugs={['ops', 'panels', 'types']}
          activeTabSlug={tab}
          menu={{
            secondary: true,
            pointing: true,
          }}
          defaultActiveIndex={0}
          panes={[
            {menuItem: 'Ops', render: () => <OpRegistry />},
            {menuItem: 'Panels', render: () => <PanelRegistryComp />},
            {menuItem: 'Types', render: () => <TypeRegistry />},
          ]}
        />
      </S.Page>
    );
  },
  {id: 'RegistryPage'}
);

export default RegistryPage;
