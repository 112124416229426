import gql from 'graphql-tag';
import _ from 'lodash';
import queryString from 'query-string';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Input} from 'semantic-ui-react';
import {isValidEmail} from '@wandb/cg/browser/utils/string';
import RoutedTab from '../components//RoutedTab';
import {toast} from '../components/elements/Toast';
import ExampleShowcase from '../components/ExampleShowcase';
import ExpandableProjectList from '../components/ExpandableProjectList';
import PageWithSidebar from '../components/PageWithSidebar';
import ProjectsTableTeam from '../components/ProjectsTableTeam';
import ReportsTable, {
  reportsTableFragment,
  ReportsTableReport,
} from '../components/ReportsTable';
import RunsTable, {
  runsTableFragment,
  RunsTableRun,
} from '../components/RunsTable';
import TeamInviteModal from '../components/TeamInviteModal';
import TeamSidebar from '../components/TeamSidebar';
import TeamTrialNudgeBar from '../components/TeamTrialNudgeBar';
import config from '../config';
import DeferredQueryData from '../containers/DeferredQueryData';
import {
  CreateInviteMutationVariables,
  EntityRunsQueryResult,
  useCreateInviteMutation,
  UserAccountType,
} from '../generated/graphql';
import {Project as AllProjectsProject} from '../graphql/allProjectsQuery';
import {Graph} from '../types/graphql';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import globalHistory from '../util/history';
import makeComp from '../util/profiler';
import {teamPage} from '../util/urls';
import * as SSection from './Section.styles';
import * as S from './TeamPage.styles';

export const teamPageFragment = gql`
  fragment TeamPageFragment on Entity {
    id
    name
    memberCount
    photoUrl
    available
    readOnly
    storageBytes
    organizationId
    userStats(from: $userStatsStartDate, first: 10) {
      edges {
        node {
          user {
            id
            username
            photoUrl
            accountType
          }
          runCount
        }
      }
    }
    projects(order: "lastActive") {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          totalUsers
          lastActive
          totalRunTime
          allViews(viewType: "runs", first: 10000) {
            edges {
              node {
                ...ReportsTableFragment
              }
            }
          }
        }
      }
    }
    claimingEntity {
      id
      name
    }
  }
  ${reportsTableFragment}
`;

export const ENTITY_RUNS_QUERY = gql`
  query EntityRuns($entityName: String!) {
    entity(name: $entityName) {
      id
      runs(first: 100) {
        edges {
          node {
            ...RunsTableFragment
          }
        }
      }
    }
  }
  ${runsTableFragment}
`;

interface EntityUser {
  user: {
    id: string;
    username: string;
    photoUrl: string;
    accountType?: UserAccountType;
  };
  runCount: number;
}

interface ClaimingEntity {
  id: string;
  name: string;
}

type Project = AllProjectsProject & {allViews: Graph<ReportsTableReport>};

export type TeamPageData = {
  id: string;
  name: string;
  memberCount: number;
  projects: Graph<Project>;
  runs: Graph<RunsTableRun>;
  userStats: Graph<EntityUser>;
  photoUrl: string;
  storageBytes: number;
  available: boolean;
  readOnly: boolean;
  claimingEntity: ClaimingEntity;
  organizationId: string;
};

interface TeamPageProps {
  data: TeamPageData;
  entityRunsQuery: EntityRunsQueryResult;
  tab?: string;
  useHistory?: any;
  filePath?: string;
}

const TeamPage: FC<TeamPageProps> = makeComp(
  ({data, entityRunsQuery, tab}) => {
    const [invite, setInvite] = useState<string>('');
    const [toNewUser, setToNewUser] = useState<boolean>(false);
    const history = useHistory();

    const reportEdges = useMemo(
      () => ({
        edges: _.flatten(data.projects.edges.map(e => e.node.allViews.edges)),
      }),
      [data.projects]
    );

    useEffect(() => {
      const params = queryString.parse(document.location.search);
      // On first render, if the URL indicates that the user is accepting
      // the team invite, open the modal to update default entity
      if (!!params.invited && invite === '') {
        const invited =
          typeof params.invited === 'string'
            ? params.invited
            : params.invited[0];
        localStorage.setItem('auth.invited', invited);
        setInvite(invited);

        if (params.newUser) {
          const newUser =
            typeof params.newUser === 'string'
              ? params.newUser === 'true'
              : params.newUser[0] === 'true';
          setToNewUser(newUser);
        }
      }
      // eslint-disable-next-line
    }, []);

    const closeModalHandler = () => {
      setInvite('');
      // note: this erases all querystring params
      const url = document.location.pathname;
      globalHistory.push(url);
    };

    return (
      <S.Wrapper>
        <PageWithSidebar>
          <TeamSidebar data={data} />
          <div className="main-page">
            {!data.readOnly && data.projects.edges.length === 0 ? (
              <TeamPageOnboarding entityName={data.name} />
            ) : (
              <RoutedTab
                history={history}
                baseUrl={teamPage(data.name)}
                tabSlugs={['overview', 'projects']}
                activeTabSlug={tab}
                panes={[
                  {
                    menuItem: 'Overview',
                    render: () => (
                      <>
                        <ExpandableProjectList
                          projects={data.projects}
                          entityName={data.name}
                          readOnly={data.readOnly}
                        />
                        <DeferredQueryData query={entityRunsQuery}>
                          {runsData => {
                            const runs = runsData?.entity?.runs ?? {edges: []};
                            return (
                              <RunsTable
                                name="Runs"
                                data={runs}
                                showUserColumn={true}
                              />
                            );
                          }}
                        </DeferredQueryData>
                        <ReportsTable data={reportEdges} pageSize={10} />
                      </>
                    ),
                  },
                  {
                    menuItem: 'Projects',
                    render: () => (
                      <div>
                        <ProjectsTableTeam
                          entityName={data.name}
                          readOnly={data.readOnly}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>
          {!!invite && (
            <TeamInviteModal
              invite={invite}
              toNewUser={toNewUser}
              onClose={closeModalHandler}
              teamName={data.name}
            />
          )}
        </PageWithSidebar>
      </S.Wrapper>
    );
  },
  {id: 'TeamPage', memo: true}
);

const TeamPageOnboarding: FC<{entityName: string}> = makeComp(
  ({entityName}) => {
    const [inviteEmailOrUsername, setInviteEmailOrUsername] = useState('');

    const [createInvite] = useCreateInviteMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

    const sendInvite = useCallback(async () => {
      const createInviteVariables: CreateInviteMutationVariables = {
        entityName,
        admin: false,
      };
      createInviteVariables[
        isValidEmail(inviteEmailOrUsername) ? 'email' : 'username'
      ] = inviteEmailOrUsername;
      try {
        const createInviteResult = await createInvite({
          variables: createInviteVariables,
        });
        if (createInviteResult.data?.createInvite?.success) {
          toast(`Invite sent to ${inviteEmailOrUsername}`);
          setInviteEmailOrUsername('');
        } else {
          toast(`Oops, something went wrong.`);
        }
      } catch (e) {
        toast(`Oops, something went wrong.`);
        console.error(extractErrorMessageFromApolloError(e));
      }
    }, [createInvite, entityName, inviteEmailOrUsername]);

    const inviteTeamSection = (
      <SSection.Section grow>
        <SSection.SectionHeader>Invite team members</SSection.SectionHeader>
        <SSection.SectionText>Enter an email or username.</SSection.SectionText>
        <S.InviteForm>
          <Input
            fluid
            data-test="add-team-member-input"
            className="add-member-form--email"
            placeholder={'grace@acme.com'}
            value={inviteEmailOrUsername}
            onChange={(e, {value}) => setInviteEmailOrUsername(value.trim())}
          />
          <Button onClick={sendInvite}>Send</Button>
        </S.InviteForm>
      </SSection.Section>
    );

    const exampleProjectSection = (
      <SSection.Section grow>
        <SSection.SectionHeader>Example project</SSection.SectionHeader>
        <SSection.SectionText>
          Log data from a quick notebook to test W&B features.
        </SSection.SectionText>
        <SSection.SectionButton
          primary
          href="http://wandb.me/intro"
          target="_blank"
          rel="noopener noreferrer">
          Try example
        </SSection.SectionButton>
      </SSection.Section>
    );

    const exampleShowcaseSection = (
      <SSection.Section grow>
        <SSection.SectionHeader>
          Start logging data to your team
        </SSection.SectionHeader>
        <SSection.SectionText>
          Add a few lines of code to get a live dashboard of your model
          training, including logs and system metrics.
        </SSection.SectionText>
        <ExampleShowcase entityName={entityName} />
      </SSection.Section>
    );

    return (
      <>
        <TeamTrialNudgeBar entityName={entityName} />
        <SSection.SectionContainer>
          {!config.ENVIRONMENT_IS_PRIVATE && (
            <SSection.SectionColumn grow>
              {inviteTeamSection}
            </SSection.SectionColumn>
          )}
          <SSection.SectionColumn grow>
            {exampleProjectSection}
          </SSection.SectionColumn>
        </SSection.SectionContainer>
        <SSection.SectionContainer>
          {exampleShowcaseSection}
        </SSection.SectionContainer>
        <SSection.MobileSectionContainer>
          {!config.ENVIRONMENT_IS_PRIVATE && inviteTeamSection}
          {exampleProjectSection}
          {exampleShowcaseSection}
        </SSection.MobileSectionContainer>
      </>
    );
  },
  {id: 'TeamPageOnboarding'}
);

export default TeamPage;
