import {apolloClient} from '../setup';
import gql from 'graphql-tag';
import config, {backendHost} from '../config';
import React from 'react';

const maybeAddAzureHeader = (headers: any, url: string) => {
  /* Azure needs an extra header, this is what's in files.uploadHeaders.  We
  assume we can just check for a windows.net domain.  TODO: can we assume this?
  */
  const parser = document.createElement('a');
  parser.href = url;
  if (parser.hostname.match(/windows.net$/)) {
    headers['x-ms-blob-type'] = 'BlockBlob';
  }
  return headers;
};

export async function publicImageUpload(image: Blob): Promise<string> {
  const {uploadUrl, imageUrl} = (
    await apolloClient.query({
      query: gql`
        query PublicImageUploadURL {
          publicImageUploadUrl {
            uploadUrl
            imageUrl
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
  ).data.publicImageUploadUrl;
  const headers = maybeAddAzureHeader({'Content-Type': 'image/png'}, uploadUrl);
  // See https://cloud.google.com/storage/docs/xml-api/reference-headers#xgoogacl
  headers['x-goog-acl'] = 'public-read';

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return imageUrl;
}

export const profileImageUpload = async (image: Blob) => {
  const viewer = (
    await apolloClient.query({
      query: gql`
        query ViewerUploadUrl {
          viewer {
            photoUploadUrl
            username
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
  ).data.viewer;
  const uploadUrl = viewer.photoUploadUrl;
  const updatedUrl = `${viewer.username}/profile.png`;
  const headers = maybeAddAzureHeader({'Content-Type': 'image/png'}, uploadUrl);

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const entityImageUpload = async (entityName: string, image: Blob) => {
  const entity = (
    await apolloClient.query({
      query: gql`
        query EntityUploadUrl($entityName: String!) {
          entity(name: $entityName) {
            photoUploadUrl
          }
        }
      `,
      variables: {entityName},
      fetchPolicy: 'no-cache',
    })
  ).data.entity;
  const uploadUrl = entity.photoUploadUrl;
  const updatedUrl = `${entityName}/profile.png`;
  const headers = maybeAddAzureHeader({'Content-Type': 'image/png'}, uploadUrl);

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const viewImageUpload = async (
  image: Blob,
  name: string,
  id: string,
  mimeType: string,
  publicImg?: boolean
) => {
  const view = (
    await apolloClient.query({
      query: gql`
        query ViewUploadUrl($id: ID!, $name: String!) {
          view(id: $id) {
            imageUploadUrl(name: $name) {
              url
              publicUrl
              path
            }
          }
        }
      `,
      variables: {
        id,
        name,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.view;
  const {url, publicUrl, path} = view.imageUploadUrl;
  const privateImg =
    !publicImg || publicUrl === '' || config.ENVIRONMENT_IS_PRIVATE;
  const uploadUrl = privateImg ? url : publicUrl;
  const updatedUrl = path;
  const headers = maybeAddAzureHeader({'Content-Type': mimeType}, uploadUrl);
  if (!privateImg) {
    // See https://cloud.google.com/storage/docs/xml-api/reference-headers#xgoogacl
    headers['x-goog-acl'] = 'public-read';
  }
  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const squareCrop = (img: HTMLImageElement, size: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const aspectRatio = img.width / img.height;
  let renderableWidth = size;
  let renderableHeight = size;
  let xStart = 0;
  let yStart = 0;
  if (aspectRatio > 1) {
    renderableWidth = img.width * (size / img.height);
    xStart = (size - renderableWidth) / 2;
  } else if (aspectRatio < 1) {
    renderableHeight = img.height * (size / img.width);
    yStart = (size - renderableHeight) / 2;
  }

  // TODO: handle non-center cropping?
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, xStart, yStart, renderableWidth, renderableHeight);
  }
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => (blob ? resolve(blob) : reject('Unable to resize image')),
      'image/png'
    );
  });
};

export const ensureMaxSize = (img: HTMLImageElement, maxSize: number) => {
  const canvas = document.createElement('canvas');
  let width = img.width;
  let height = img.height;
  let ratio = 1;
  if (img.width > maxSize && img.width > img.height) {
    ratio = img.width / img.height;
    height = maxSize / ratio;
    width = maxSize;
  } else if (img.height > maxSize && img.height > img.width) {
    ratio = img.height / img.width;
    width = maxSize / ratio;
    height = maxSize;
  }
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height);
  }
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => (blob ? resolve(blob) : reject('Unable to resize image')),
      'image/png'
    );
  });
};

const MARKDOWN_IMAGE_MAX_SIZE = 2048;

export interface UploadState {
  uploading: boolean;
  name: string;
  url?: string;
  error?: string;
}

export function useUploadMarkdownImage(
  viewId: string | null,
  publicImg = false
) {
  const [uploadState, setUploadState] = React.useState<UploadState>();

  const upload = React.useCallback(
    async (image: Blob, name: string) => {
      if (viewId == null) {
        return;
      }

      try {
        let ext = '.png';
        if (image.type === 'image/gif') {
          ext = '.gif';
        }
        const uniqueName =
          (Math.random().toString(16) + '000000000').substr(2, 8) + ext;
        const path = await viewImageUpload(
          image,
          uniqueName,
          viewId,
          image.type,
          publicImg
        );
        // Local can use relative urls, prod needs absolute
        const host = config.ENVIRONMENT_NAME === 'local' ? '' : backendHost();
        const downloadUrl = host + path;
        setUploadState({
          url: downloadUrl,
          uploading: false,
          name,
        });
      } catch (error) {
        console.error(error);
        setUploadState({
          error,
          uploading: false,
          name,
        });
      }
    },
    [setUploadState, viewId, publicImg]
  );

  const uploadImages = React.useCallback(
    (files: File[]) => {
      if (viewId == null) {
        console.warn('Attempted to upload an image outside of a view context.');
        return;
      }
      files.forEach(file => {
        if (file.type === 'image/gif') {
          // TODO: prevent MASSIVE gifs?
          setUploadState({
            uploading: true,
            name: file.name,
          });
          upload(file, file.name);
        } else if (file instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const origImg = new Image();
            if (reader.result) {
              origImg.src = reader.result.toString();
              origImg.onload = () => {
                setUploadState({
                  uploading: true,
                  name: file.name,
                });
                ensureMaxSize(origImg, MARKDOWN_IMAGE_MAX_SIZE)
                  .then(blob => upload(blob, file.name))
                  .catch(error => {
                    setUploadState({
                      uploading: false,
                      name: file.name,
                      error,
                    });
                  });
              };
            }
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [upload, viewId]
  );

  return {uploadImages, uploadState, setUploadState};
}
