/*
Resources on colour palettes

Generates qualitative palettes
http://tools.medialab.sciences-po.fr/iwanthue/

Nice properties for colourmaps to have (from matplotlib).
https://matplotlib.org/users/colormaps.html#colorcet (linear_bgy_10_95_c74_n256)
https://bids.github.io/colormap/

And a palette generator from Google
http://google.github.io/palette.js/
*/

import * as globals from '../css/globals.styles';

import Color from 'color';
import _ from 'lodash';
import * as RunsDataLoader from '../containers/RunsDataLoader';
import * as Run from './runs';
import * as Section from './section';
import {gray50} from '../css/globals.styles';

export function colorN(index: number, palette: string[], alpha?: number) {
  /**
   * Given an index and a palette, returns a color.
   */
  const c = Color(palette[index % palette.length]);
  return c
    .alpha(alpha || c.alpha())
    .rgb()
    .string();
}

export function colorFromString(s: string): [number, number, number] {
  return Color(s).rgb().array() as [number, number, number];
}

export function colorNRGB(index: number, palette: string[], alpha?: number) {
  /**
   * Given an index and a palette, returns a color.
   */
  const c = Color(palette[index % palette.length]);
  return c
    .alpha(alpha || c.alpha())
    .rgb()
    .array() as [number, number, number];
}

export function color(index: number, alpha?: number) {
  /**
   * Legacy coloring function. Returns one of 10 "original" colors
   * based on the provided index.
   */
  return colorN(index, COLORS10, alpha);
}

function hashString(s: string) {
  return s.split('').reduce((a, b) => {
    // tslint:disable:no-bitwise
    a = (a << 10) - a + b.charCodeAt(0);
    return a & a;
    // tslint:enable:no-bitwise
  }, 0);
}

export function colorFromName(name: string, alpha?: number) {
  /**
   * Hashes a given run name and returns a corresponding color.
   */
  const idx = Math.abs(hashString(name));
  return colorN(idx, COLORS16, alpha);
}

// TODO(views): This should only accept string | undefined as its third argument. Refactor this once
// PanelRunsLinePlot uses hooks to grab the run colors.
export function runColor(
  run: RunsDataLoader.RunWithRunsetInfo,
  groupKeys: Run.Key[],
  customRunColors: Section.RunColorConfig | string | undefined,
  alpha?: number
) {
  if (typeof customRunColors === 'string' && !_.isEmpty(customRunColors)) {
    return customRunColors;
  }

  // Use a custom-set color, if one exists.
  const name = Run.uniqueId(run, groupKeys);
  if (typeof customRunColors === 'object') {
    const customColor = customRunColors[name];
    if (customColor != null) {
      const c = Color(customColor);
      return c
        .alpha(alpha || c.alpha())
        .rgb()
        .string();
    }
  }

  // Use the color-at-birth whenever there's no custom color
  // and no grouping involved.
  if (
    run.defaultColorIndex != null &&
    (groupKeys == null || _.isEmpty(groupKeys))
  ) {
    return colorN(run.defaultColorIndex, ROBIN16, alpha);
  }

  // If this run is from before we assigned colors at birth,
  // use a hash of the name.
  return colorFromName(name, alpha);
}

export function getColorName(c = ''): string | undefined {
  const colorDetails = getRunColorDetails(c);
  return colorDetails?.name ?? c;
}

export function getTextColor(c = ''): string | undefined {
  const colorDetails = getRunColorDetails(c);
  return colorDetails?.textColor ?? c;
}

// Got this list from a cool site http://tools.medialab.sciences-po.fr/iwanthue/
// First five are locked from demian
// Try to make them lighter towards the end so they fade a little into white background
export const COLORS10 = [
  globals.darkBlue,
  '#df672a',
  '#c1433c',
  '#3d9e3e',
  '#ecbb33',
  '#926ccb',
  '#6bb59b',
  '#ad825c',
  '#c66b9e',
  '#a7b756',
];

export const COLORS16 = [
  '#E87B9F', // pink
  '#A12864', // maroon
  '#DA4C4C', // red
  '#F0B899', // peach
  '#E57439', // orange
  '#EDB732', // yellow
  '#A0C75C', // lime
  '#479A5F', // kelly green
  '#87CEBF', // seafoam
  '#229487', // forest
  '#5BC5DB', // cyan
  '#5387DD', // blue
  '#7D54B2', // purple
  '#C565C7', // magenta
  '#A46750', // brown
  '#A1A9AD', // gray
];

interface RunColorDetails {
  name: string;
  textColor: string;
}

// dictionary mapped from run color (for lines, shaded are, etc.)
// to name and text color (for legend and tooltip)
const runColorDict: {[key: string]: RunColorDetails} = {
  '#E87B9F': {name: 'pink', textColor: globals.runPinkText},
  '#A12864': {name: 'maroon', textColor: globals.runMaroonText},
  '#DA4C4C': {name: 'red', textColor: globals.runRedText},
  '#F0B899': {name: 'peach', textColor: globals.runPeachText},
  '#E57439': {name: 'orange', textColor: globals.runOrangeText},
  '#EDB732': {name: 'yellow', textColor: globals.runYellowText},
  '#A0C75C': {name: 'lime', textColor: globals.runLimeText},
  '#479A5F': {name: 'kelly green', textColor: globals.runKellyGreenText},
  '#87CEBF': {name: 'seafoam', textColor: globals.runSeafoamText},
  '#229487': {name: 'forest', textColor: globals.runForestText},
  '#5BC5DB': {name: 'cyan', textColor: globals.runCyanText},
  '#5387DD': {name: 'blue', textColor: globals.runBlueText},
  '#7D54B2': {name: 'purple', textColor: globals.runPurpleText},
  '#C565C7': {name: 'magenta', textColor: globals.runMagentaText},
  '#A46750': {name: 'brown', textColor: globals.runBrownText},
  '#A1A9AD': {name: 'gray', textColor: globals.runGrayText},
};

export function getRunColorDetails(c: string): RunColorDetails | undefined {
  return c.startsWith('rgb') ? runColorDict[Color(c).hex()] : runColorDict[c];
}

// Our bespoke palette. This is in round-robin order.
export const ROBIN16 = [
  11, // blue
  2, // red
  7, // kelly green
  12, // purple
  0, // pink
  4, // orange
  8, // seafoam
  13, // magenta
  5, // yellow
  10, // cyan
  9, // forest
  3, // peach
  6, // lime
  14, // brown
  1, // maroon
  15, // gray
].map(i => COLORS16[i]);

// https://bids.github.io/colormap/
export const _PLASMA_GRADIENT = [
  [13, 8, 135],
  [16, 7, 136],
  [19, 7, 137],
  [22, 7, 138],
  [25, 6, 140],
  [27, 6, 141],
  [29, 6, 142],
  [32, 6, 143],
  [34, 6, 144],
  [36, 6, 145],
  [38, 5, 145],
  [40, 5, 146],
  [42, 5, 147],
  [44, 5, 148],
  [46, 5, 149],
  [47, 5, 150],
  [49, 5, 151],
  [51, 5, 151],
  [53, 4, 152],
  [55, 4, 153],
  [56, 4, 154],
  [58, 4, 154],
  [60, 4, 155],
  [62, 4, 156],
  [63, 4, 156],
  [65, 4, 157],
  [67, 3, 158],
  [68, 3, 158],
  [70, 3, 159],
  [72, 3, 159],
  [73, 3, 160],
  [75, 3, 161],
  [76, 2, 161],
  [78, 2, 162],
  [80, 2, 162],
  [81, 2, 163],
  [83, 2, 163],
  [85, 2, 164],
  [86, 1, 164],
  [88, 1, 164],
  [89, 1, 165],
  [91, 1, 165],
  [92, 1, 166],
  [94, 1, 166],
  [96, 1, 166],
  [97, 0, 167],
  [99, 0, 167],
  [100, 0, 167],
  [102, 0, 167],
  [103, 0, 168],
  [105, 0, 168],
  [106, 0, 168],
  [108, 0, 168],
  [110, 0, 168],
  [111, 0, 168],
  [113, 0, 168],
  [114, 1, 168],
  [116, 1, 168],
  [117, 1, 168],
  [119, 1, 168],
  [120, 1, 168],
  [122, 2, 168],
  [123, 2, 168],
  [125, 3, 168],
  [126, 3, 168],
  [128, 4, 168],
  [129, 4, 167],
  [131, 5, 167],
  [132, 5, 167],
  [134, 6, 166],
  [135, 7, 166],
  [136, 8, 166],
  [138, 9, 165],
  [139, 10, 165],
  [141, 11, 165],
  [142, 12, 164],
  [143, 13, 164],
  [145, 14, 163],
  [146, 15, 163],
  [148, 16, 162],
  [149, 17, 161],
  [150, 19, 161],
  [152, 20, 160],
  [153, 21, 159],
  [154, 22, 159],
  [156, 23, 158],
  [157, 24, 157],
  [158, 25, 157],
  [160, 26, 156],
  [161, 27, 155],
  [162, 29, 154],
  [163, 30, 154],
  [165, 31, 153],
  [166, 32, 152],
  [167, 33, 151],
  [168, 34, 150],
  [170, 35, 149],
  [171, 36, 148],
  [172, 38, 148],
  [173, 39, 147],
  [174, 40, 146],
  [176, 41, 145],
  [177, 42, 144],
  [178, 43, 143],
  [179, 44, 142],
  [180, 46, 141],
  [181, 47, 140],
  [182, 48, 139],
  [183, 49, 138],
  [184, 50, 137],
  [186, 51, 136],
  [187, 52, 136],
  [188, 53, 135],
  [189, 55, 134],
  [190, 56, 133],
  [191, 57, 132],
  [192, 58, 131],
  [193, 59, 130],
  [194, 60, 129],
  [195, 61, 128],
  [196, 62, 127],
  [197, 64, 126],
  [198, 65, 125],
  [199, 66, 124],
  [200, 67, 123],
  [201, 68, 122],
  [202, 69, 122],
  [203, 70, 121],
  [204, 71, 120],
  [204, 73, 119],
  [205, 74, 118],
  [206, 75, 117],
  [207, 76, 116],
  [208, 77, 115],
  [209, 78, 114],
  [210, 79, 113],
  [211, 81, 113],
  [212, 82, 112],
  [213, 83, 111],
  [213, 84, 110],
  [214, 85, 109],
  [215, 86, 108],
  [216, 87, 107],
  [217, 88, 106],
  [218, 90, 106],
  [218, 91, 105],
  [219, 92, 104],
  [220, 93, 103],
  [221, 94, 102],
  [222, 95, 101],
  [222, 97, 100],
  [223, 98, 99],
  [224, 99, 99],
  [225, 100, 98],
  [226, 101, 97],
  [226, 102, 96],
  [227, 104, 95],
  [228, 105, 94],
  [229, 106, 93],
  [229, 107, 93],
  [230, 108, 92],
  [231, 110, 91],
  [231, 111, 90],
  [232, 112, 89],
  [233, 113, 88],
  [233, 114, 87],
  [234, 116, 87],
  [235, 117, 86],
  [235, 118, 85],
  [236, 119, 84],
  [237, 121, 83],
  [237, 122, 82],
  [238, 123, 81],
  [239, 124, 81],
  [239, 126, 80],
  [240, 127, 79],
  [240, 128, 78],
  [241, 129, 77],
  [241, 131, 76],
  [242, 132, 75],
  [243, 133, 75],
  [243, 135, 74],
  [244, 136, 73],
  [244, 137, 72],
  [245, 139, 71],
  [245, 140, 70],
  [246, 141, 69],
  [246, 143, 68],
  [247, 144, 68],
  [247, 145, 67],
  [247, 147, 66],
  [248, 148, 65],
  [248, 149, 64],
  [249, 151, 63],
  [249, 152, 62],
  [249, 154, 62],
  [250, 155, 61],
  [250, 156, 60],
  [250, 158, 59],
  [251, 159, 58],
  [251, 161, 57],
  [251, 162, 56],
  [252, 163, 56],
  [252, 165, 55],
  [252, 166, 54],
  [252, 168, 53],
  [252, 169, 52],
  [253, 171, 51],
  [253, 172, 51],
  [253, 174, 50],
  [253, 175, 49],
  [253, 177, 48],
  [253, 178, 47],
  [253, 180, 47],
  [253, 181, 46],
  [254, 183, 45],
  [254, 184, 44],
  [254, 186, 44],
  [254, 187, 43],
  [254, 189, 42],
  [254, 190, 42],
  [254, 192, 41],
  [253, 194, 41],
  [253, 195, 40],
  [253, 197, 39],
  [253, 198, 39],
  [253, 200, 39],
  [253, 202, 38],
  [253, 203, 38],
  [252, 205, 37],
  [252, 206, 37],
  [252, 208, 37],
  [252, 210, 37],
  [251, 211, 36],
  [251, 213, 36],
  [251, 215, 36],
  [250, 216, 36],
  [250, 218, 36],
  [249, 220, 36],
  [249, 221, 37],
  [248, 223, 37],
  [248, 225, 37],
  [247, 226, 37],
  [247, 228, 37],
  [246, 230, 38],
  [246, 232, 38],
  [245, 233, 38],
  [245, 235, 39],
  [244, 237, 39],
  [243, 238, 39],
  [243, 240, 39],
  [242, 242, 39],
  [241, 244, 38],
  [241, 245, 37],
  [240, 247, 36],
  [240, 249, 33],
];

// we cut off the light colours at the end because they are hard to see on a white background
export const PLASMA_GRADIENT = _.slice(
  _PLASMA_GRADIENT,
  0,
  Math.round(0.9 * _PLASMA_GRADIENT.length)
);

function toHex(d: number) {
  return ('0' + d.toString(16)).slice(-2).toUpperCase();
}

export const PLASMA_GRADIENT_SPARSE_HEX: string[] = [];
const N_SPARSE = 8;
for (let i = 0; i < N_SPARSE; i++) {
  const colIndex = Math.floor(
    (i * (PLASMA_GRADIENT.length - 1)) / (N_SPARSE - 1)
  );
  const c = PLASMA_GRADIENT[colIndex];
  PLASMA_GRADIENT_SPARSE_HEX.push(
    `#${toHex(c[0])}${toHex(c[1])}${toHex(c[2])}`
  );
}

export const GLOBAL_COLORS = {
  primary: Color(globals.primary),
  outline: Color('rgb(219, 219, 219)'),
  linkBlue: Color(globals.primaryText),
  lightGray: Color('#B3B3B0'),
  gray: Color(globals.textSecondary),
  background: gray50,
};
