import {graphql} from 'react-apollo';
import {VIEW_DELETE, VIEW_UPSERT, VIEW_CREATE_SHARED} from '../graphql/views';

export interface ViewData {
  id: string;
  name: string;
  description: string;
  user: {
    username: string;
  };
  spec: string; // JSON
  updatedAt: string;
}

export interface ViewUpsertVariables {
  id?: string;
  entityName?: string;
  projectName?: string;
  name?: string;
  description?: string;
  type: string;
  spec: any; // TODO: type
}

export interface ViewUpsertResult {
  upsertView: {
    view: ViewData;
  };
  inserted: boolean;
}

export interface ViewDeleteVariables {
  id: string;
  entityName: string;
  projectName: string;
  type: string;
}

export interface ViewCreateSharedVariables {
  entityName: string;
  name: string;
  type: string;
  description?: string;
  spec: string;
}

export interface ViewCreateSharedResult {
  upsertSharedView: {
    view: ViewData;
  };
}

export const upsertViewMutation = graphql<
  {},
  ViewUpsertResult,
  ViewUpsertVariables,
  {}
>(VIEW_UPSERT, {
  props: ({mutate}) => ({
    upsertView: (variables: ViewUpsertVariables) =>
      mutate!({
        variables,
        // Due to what seems to be an apollo bug, this only works if the list is non-empty.
        // So unfortunately for now we do a full refetch instead.
        // update: (cache, {data}) => {
        //   const data2 = data as any;
        //   const id = data2.upsertView.view.id;

        //   const viewFrag = cache.readFragment({id, fragment: fragments.view});
        // },
      }),
  }),
});

export const deleteViewMutation = graphql<{}, {}, ViewDeleteVariables, {}>(
  VIEW_DELETE,
  {
    props: ({mutate}) => ({
      deleteView: (variables: ViewDeleteVariables) =>
        mutate!({
          variables,
          // Due to what seems to be an apollo bug, this only works if the list is non-empty.
          // So unfortunately for now we do a full refetch instead.
          //   update: cache => {
          //     variables.name = variables.projectName;
          //     variables.viewName = undefined;
          //     variables.userName = undefined;
          //     const {project} = cache.readQuery({
          //       query: VIEW_QUERY,
          //       variables,
          //     });

          //     let foundIndex = null;
          //     for (let i = 0; i < project.allViews.edges.length; i++) {
          //       const edge = project.allViews.edges[i];
          //       if (edge.node.id === variables.id) {
          //         foundIndex = i;
          //         break;
          //       }
          //     }
          //     if (foundIndex != null) {
          //       project.allViews.edges.splice(foundIndex, 1);
          //       cache.writeQuery({
          //         query: VIEW_QUERY,
          //         variables,
          //         data: {project},
          //       });
          //     }
          //   },
        }),
    }),
  }
);

export const createSharedViewMutation = graphql<
  {},
  ViewCreateSharedResult,
  ViewCreateSharedVariables,
  {}
>(VIEW_CREATE_SHARED, {
  props: ({mutate}) => ({
    createSharedView: (variables: ViewCreateSharedVariables) =>
      mutate!({variables}),
  }),
});
