import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const CriticalHint = styled.div`
  font-style: italic;
  color: ${globals.red};
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  margin: auto;
  color: gray;
  line-height: 24px;
  font-size: 20px;
`;

export const BigSad = styled.div`
  margin: auto;
  filter: grayscale(100%) opacity(50%);
  line-height: 80px;
  font-size: 60px;
`;
