import React from 'react';
import {useEffect} from 'react';

import {Loader} from 'semantic-ui-react';

import '../css/EditablePanel.less';
import PanelEditor, {PanelEditorProps} from './PanelEditor';

import * as ViewHooks from '../state/views/hooks';
import * as PanelViewTypes from '../state/views/panel/types';
import * as PanelsUtil from '../util/panels';
import makeComp from '../util/profiler';

type AddVisNewPanelEditorProps = Omit<
  PanelEditorProps,
  'panelSpec' | 'panelRef' | 'onOK'
> & {
  panel: PanelViewTypes.Panel;
  noEdit?: boolean;
  onOK(panel: PanelViewTypes.Ref): void;
};

export const AddVisNewPanelEditor = makeComp(
  (props: AddVisNewPanelEditorProps) => {
    const panelRef = ViewHooks.useNewObjectRef('panel', props.panel);

    // If this is a panel that doesnt have an edit mode, then
    // immediately call onOK when our callback is ready.
    useEffect(() => {
      const panelSpec = PanelsUtil.getPanelSpec(props.panel.viewType);
      if (
        panelRef.ready &&
        (panelSpec.noEditMode || props.noEdit || panelSpec.useInspector)
      ) {
        props.onOK(panelRef.ref);
      }
    });

    if (!panelRef.ready) {
      return <Loader />;
    }

    const spec = PanelsUtil.getPanelSpec(props.panel.viewType);
    return (
      <PanelEditor
        {...props}
        panelSpec={spec}
        panelRef={panelRef.ref}
        newPanel
        onOK={() => props.onOK(panelRef.ref)}
      />
    );
  },
  {id: 'AddVisNewPanelEditor'}
);
