import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 0;
  flex-grow: 1;
`;
export const DataHeaderRow = styled.div`
  display: flex;
  font-weight: 600;
  background: white;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 8px;
`;
export const DataCell = styled.div<{width: number}>`
  padding: 4px 8px;
  font-size: 14px;
  width: ${props => props.width}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
`;
export const NoDataMessage = styled.div`
  color: #999;
  padding: 4px 16px;
`;
