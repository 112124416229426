import Color from 'color';
import * as React from 'react';
import {ColorResult, SketchPicker} from 'react-color';
import {Icon, Popup} from 'semantic-ui-react';

import {COLORS16, getColorName} from '../util/colors';
import makeComp from '../util/profiler';

export interface ColorPickerProps {
  activeColor: string;
  style?: React.CSSProperties;
  setRunColor?: (color: string) => void;
}

const ColorPicker = makeComp(
  (props: ColorPickerProps) => {
    const {activeColor, style, setRunColor} = props;

    const handleChangeComplete = (color: ColorResult) => {
      const {a, ...colorMinusAlpha} = color.rgb;
      const alpha = a || 0.8;
      const updatedColor = Color(colorMinusAlpha).alpha(alpha).string();
      if (setRunColor != null) {
        setRunColor(updatedColor);
      }
    };

    return (
      <Popup
        trigger={
          <Icon
            style={{
              ...style,
              opacity: 1,
              color: activeColor,
            }}
            name="circle"
            title={getColorName(activeColor) || activeColor}
          />
        }
        content={
          <SketchPicker
            color={activeColor}
            presetColors={COLORS16}
            onChangeComplete={handleChangeComplete}
          />
        }
        // needs higher zindex than body .ui.dimmer in App.less
        style={{padding: 0, left: '-15px'}}
        on="click"
      />
    );
  },
  {id: 'ColorPicker', memo: true}
);

export default ColorPicker;
