import {Dropdown} from 'semantic-ui-react';
import styled, {css} from 'styled-components';
import * as globals from '../css/globals.styles';
import {TargetBlank} from '../util/links';

export const QuickStartContainer = styled.div<{width?: number}>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 46px 40px;
  max-width: 1520px;

  ${props =>
    props.width &&
    props.width <= 924 &&
    css`
      flex-direction: column;
      padding: 0;
    `}
`;

export const QuickStartContainerRight = styled.div<{width?: number}>`
  display: flex;

  ${props =>
    props.width &&
    props.width <= 1200 &&
    css`
      flex-direction: column;
    `};

  ${props => {
    if (props.width) {
      if (props.width <= 924) {
        return css`
          margin: 0;
        `;
      } else if (props.width <= 1200) {
        return css`
          margin-left: 40px;
          min-width: 624px;
        `;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }}
`;

export const FrameworkColumn = styled.div<{width?: number}>`
  max-width: 320px;
  min-width: 256px;
  height: min-content;
  background: white;
  padding-top: 32px;
  padding-bottom: 16px;
  border: 1px solid ${globals.gray300};
  border-radius: 3px;

  h1 {
    font-size: 20px;
    padding: 0 32px;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    line-height: 20px;
    margin: 8px 0 16px 0;
    padding: 0 32px;
    color: ${globals.gray600};
  }

  ${props =>
    props.width &&
    props.width <= 924 &&
    css`
      border: none;
      min-width: unset;
      max-width: unset;
      padding: 16px;

      h1,
      h2 {
        padding: 0;
      }
    `}
`;

export const Framework = styled.div<{active?: boolean; width?: number}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 56px;
  padding: 12px 32px;
  ${props =>
    props.active
      ? css`
          font-weight: 600;
          background: ${globals.selected};

          &:hover {
            background: ${globals.selectedHover};
          }
        `
      : css`
          &:hover {
            background: ${globals.hover};
          }
        `}

  img {
    height: 32px;
    width: 32px;
    margin-right: 16px;
    object-fit: contain;
  }

  ${props =>
    props.width &&
    props.width <= 924 &&
    css`
      display: none;
    `}
`;

export const FrameworkDropdown = styled(Dropdown)<{width?: number}>`
  &&&&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    background: #f4f4f4;
    padding: 16px;
    width: max-content;

    ${props =>
      props.width &&
      props.width > 924 &&
      css`
        display: none;
      `}
  }
`;

export const IntegrationColumn = styled.div<{width?: number}>`
  &&&& {
    margin: 0 40px;
    padding: 32px;
    max-width: 800px;
    min-width: 592px;
    background: white;
    border: 1px solid ${globals.gray300};
    border-radius: 3px;

    h1 {
      font-size: 34px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 24px;
    }

    h2 {
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-bottom: 24px;
      margin-top: 0;

      img {
        height: 32px;
        width: 32px;
        object-fit: contain;
        margin-right: 12px;
      }
    }

    h3 {
      font-size: 20px;
      color: ${globals.gray600};
      margin-bottom: 16px;
    }

    & > div {
      margin-bottom: 24px;
      max-width: 583px;

      h1 {
        font-family: Inconsolata;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 8px;
      }
    }

    button {
      margin-top: 8px;
    }

    ${props =>
      props.width &&
      props.width <= 1200 &&
      css`
        margin: 0;
      `}

    ${props =>
      props.width &&
      props.width <= 924 &&
      css`
        min-width: unset;
        max-width: unset;
        border: none;
        padding: 16px;

        h1 {
          font-size: 24px;
          margin-bottom: 16px;
        }

        h2 {
          font-size: 20px;
          margin-bottom: 16px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }

        h3 {
          margin-bottom: 8px;
        }

        & > div {
          max-width: unset;
        }
      `}
  }
`;

export const ColabColumn = styled.div<{width?: number}>`
  & > div:first-child {
    font-size: 20px;
    margin-bottom: 16px;
  }

  ${props =>
    props.width && props.width <= 1200
      ? css`
          margin-top: 40px;
          max-width: 800px;
          min-width: 592px;
        `
      : css`
          max-width: 320px;
          min-width: 256px;
        `}

  ${props =>
    props.width &&
    props.width <= 924 &&
    css`
      max-width: unset;
      min-width: unset;
      background: white;
      margin-top: 0;
      padding-top: 16px;

      & > div:first-child {
        padding-left: 16px;
      }
    `}
`;

export const ColabContainer = styled.div<{width?: number}>`
  display: flex;
  flex-direction: column;

  ${props => {
    if (props.width) {
      if (props.width <= 924) {
        return css`
          flex-direction: column;
        `;
      } else if (props.width <= 1200) {
        return css`
          flex-direction: row;
          justify-content: space-between;
        `;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }}
`;

export const Colab = styled(TargetBlank)<{width?: number}>`
  display: flex;
  flex-direction: column;
  background: ${globals.gray200};
  margin-bottom: 16px;
  border-radius: 3px;
  color: ${globals.gray800};
  text-align: center;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    padding-right: 8px;
    margin: 0;
    height: 56px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px 56px 32px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: ${globals.gray800};
      margin-top: 0;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 16px;
      color: ${globals.gray600};
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  i {
    font-size: 24px;
    color: ${globals.gray600};
  }

  &:hover {
    background: ${globals.gray300};

    & > div:last-child > div {
      img {
        background: ${globals.gray800};
      }

      div {
        background: ${globals.primaryDark};
      }
    }
  }

  ${props => {
    if (props.width) {
      if (props.width <= 924) {
        return css`
          text-align: left;
          border-radius: 0;

          & > div:first-child {
            height: 24px;
          }

          & > div:last-child {
            margin: 0 16px 24px 16px;
            align-items: flex-start;
          }
        `;
      } else if (props.width <= 1200) {
        return css`
          max-width: 304px;
        `;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }}
`;

export const ColabButton = styled.div`
  &&& {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    height: 32px;

    img {
      background: ${globals.gray700};
      width: 32px;
      height: 32px;
      padding: 0 4px;
      object-fit: contain;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${globals.primary};
      color: white;
      height: 32px;
      width: 110px;
      text-align: center;
      font-size: 16px;
    }
  }
`;

export const QuickStartCode = styled.div<{width?: number}>`
  background: ${globals.gray900};
  color: white;
  border-radius: 3px;
  font-family: Inconsolata;

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    padding-top: 8px;
    padding-right: 12px;

    i {
      font-size: 18px;
      margin-right: 3px;
    }

    div {
      display: flex;
      align-items: center;
      transition: transform linear 0.1s;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  & > div:last-child {
    padding: 0 28px 32px 28px;
    white-space: pre-wrap;
  }
`;

export const ApiKeySection = styled.div`
  &&&&& {
    display: flex;
    align-items: center;
    max-width: 135px;
    border: 1px solid ${globals.gray300};
    border-radius: 3px;

    div {
      padding: 12px 0 12px 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      font-size: 24px;
      width: 24px;
      height: 24px;
      margin-right: 6px;
      color: ${globals.gray600};
      transition: transform linear 0.1s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;
