import '../css/PanelConfig.less';
import '../css/EditablePanel.less';

import React, {useContext} from 'react';
import {useRef} from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import * as InteractStateActions from '../state/views/interactState/actions';
import * as InteractStateContext from '../state/views/interactState/context';
import {
  PanelCompRedux,
  getPanelSpec,
  isExportable,
  isExportableAs,
} from '../util/panels';
import {makeDidPropsOrStateChange} from '../util/shouldUpdate';
import LegacyWBIcon from './elements/LegacyWBIcon';
import PanelEditor from './PanelEditor';
import SinglePanelInspectorContainer from './SinglePanelInspectorContainer';
import {compact as _compact} from 'lodash';

import {useWaitToLoadTilOnScreen} from '../state/hooks';
import * as ViewHooks from '../state/views/hooks';
import * as RunHooks from '../state/runs/hooks';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import * as PanelViewTypes from '../state/views/panel/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import {isFeatureFlagEnabled} from '../util/featureFlags';
import {PanelMoverMenu, DefaultMovePanelOption} from './PanelMoverMenu';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as PanelTypes from '../state/views/panel/types';
import {PanelExportMenu} from './PanelExportMenu';
import {PanelActionsOverflow} from './PanelActionsOverflow';
import {PanelImageExportModal} from './PanelImageExportModal';
import PanelExportCSV from './PanelExportCSV';
import PanelExportAPI from './PanelExportAPI';
import {useViewer} from '../state/viewer/hooks';
import * as Report from '../util/report';
import {usePushReport} from '../state/reports/hooks';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
} from '../util/panelbank';
import {getFullWidthPanelLayout} from '../util/panelbankGrid';
import {RunQueryContext} from '../state/runs/context';
import {PanelBankUpdaterContext} from '../state/panelbank/context';
import makeComp from '../util/profiler';
import {Popup} from 'semantic-ui-react';

interface EditablePanelProps {
  panelRef: PanelViewTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;

  className?: string;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  currentHeight: number;
  searchQuery?: string;

  dragHandle?: JSX.Element;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  panelBankConfigRef?: PanelBankConfigTypes.Ref; // the panelbank config, used for PanelMover
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref; // the panelbank section that contains this panel, used for PanelMover

  loading?: boolean; // true if an upstream query should delay rendering this panel

  panelCommentCount?: number;
  isHighlighted?: boolean;

  movePanel?(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionConfigTypes.Ref,
    toSectionRef: PanelBankSectionConfigTypes.Ref,
    toIndex: number
  ): void;
  duplicatePanel?(): void;

  onExpand?(): void;
  onRemovePanel?(): void;

  onContentHeightChange?(h: number): void;

  addComment?(): void;
  openPanelComments?(): void;
}

interface EditablePanelState {
  editing: boolean;
  viewingSpec: boolean;
  fullScreen: boolean;
  showExportAPIModal: boolean;
  showExportCSVModal: boolean;
  showExportImageModal: false | 'svg' | 'png';
}

type AllEditablePanelProps = EditablePanelProps &
  ReturnType<typeof useEditablePanelProps>;

class EditablePanelInner extends React.Component<
  AllEditablePanelProps,
  EditablePanelState
> {
  state: EditablePanelState = {
    editing: false,
    viewingSpec: false,
    fullScreen: false,
    showExportAPIModal: false,
    showExportCSVModal: false,
    showExportImageModal: false,
  };
  didPropsOrStateChange = makeDidPropsOrStateChange({
    name: 'EditablePanel2',
    deep: ['pageQuery', 'panel'],
    ignoreFunctions: true,
    ignoreJSX: true,
    debug: false,
    verbose: false,
  });
  panelDOMRef = React.createRef<HTMLDivElement>();

  shouldComponentUpdate(
    nextProps: EditablePanelProps,
    nextState: EditablePanelState
  ) {
    return this.didPropsOrStateChange(
      [this.props, nextProps],
      [this.state, nextState]
    );
  }

  addPanelToReport = () => {
    const {panelRef, setExportingPanelRefID} = this.props;
    setExportingPanelRefID(panelRef.id);
  };

  sharePanel = () => {
    const {
      panel,
      runSets,
      customRunColors,
      panelSettings,
      mergeFilters,
      runSetName,
      pushSavedReport,
    } = this.props;

    const report = Report.fromSection(
      {
        runSets,
        customRunColors,
        settings: panelSettings ?? undefined,
        panelBankConfig: _.cloneDeep(EMPTY_PANEL_BANK_CONFIG),
        panelBankSectionConfig: {
          ..._.cloneDeep(EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT),
          panels: [{...panel, layout: getFullWidthPanelLayout()}],
        },
      },
      {mergeFilters, runSetName, noSectionHeader: true}
    );
    const reportName = `Shared panel ${moment().format('YY/MM/DD HH:MM:SS')}`;
    pushSavedReport(report, reportName, {redirectQS: {highlightShare: null}});
  };

  render() {
    const {
      readOnly,
      disableRunLinks,
      panelRef,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      runSetRefs,
      panelSettings,
      defaultMoveToSectionRef,
      movePanel,
      movePanelToNewSection,
      duplicatePanel,
      onRemovePanel,
      vegaPanelEnabled,
      onContentHeightChange,
      addComment,
      panelCommentCount,
      openPanelComments,
      isHighlighted,
    } = this.props;
    const {
      editing,
      viewingSpec,
      fullScreen,
      showExportAPIModal,
      showExportCSVModal,
    } = this.state;
    const classNames = ['editable-panel', this.props.className];
    const panelSpec = getPanelSpec(this.props.panelType);
    if (this.props.panelType === 'Markdown Panel') {
      // Hack the name in here for now. We should make this an attribute on the PanelClasses
      // if we need more.
      classNames.push('editable-panel-markdown');
    }
    const panelSelected =
      _.find(this.props.panelSelection, this.props.panelRef) != null;
    if (panelSelected) {
      classNames.push('panel-selected');
    }
    if (isHighlighted) {
      classNames.push('panel-highlighted');
    }

    return (
      <>
        {fullScreen && (
          <PanelEditor
            panelSpec={panelSpec}
            editable={false}
            customRunColorsRef={this.props.customRunColorsRef}
            runSetRefs={this.props.runSetRefs}
            panelRef={this.props.panelRef}
            panelSettings={this.props.panelSettings}
            currentHeight={this.props.currentHeight}
            onCancel={() => this.setState({fullScreen: false})}
            disableRunLinks={this.props.disableRunLinks}
            panelBankSectionConfigRef={panelBankSectionConfigRef}
          />
        )}
        {editing &&
          (panelSpec.useInspector ? (
            <SinglePanelInspectorContainer
              panelRef={this.props.panelRef}
              customRunColorsRef={this.props.customRunColorsRef}
              runSetRefs={this.props.runSetRefs}
              panelSettings={this.props.panelSettings}
              onClose={() => {
                this.setState({editing: false});
              }}></SinglePanelInspectorContainer>
          ) : (
            <PanelEditor
              panelSpec={panelSpec}
              panelBankSectionConfigRef={panelBankSectionConfigRef}
              editable={true}
              customRunColorsRef={this.props.customRunColorsRef}
              runSetRefs={this.props.runSetRefs}
              panelRef={this.props.panelRef}
              panelSettings={this.props.panelSettings}
              currentHeight={this.props.currentHeight}
              onCancel={() => this.setState({editing: false})}
              onRemove={this.props.onRemovePanel}
              onOK={() => {
                this.setState({editing: false});
              }}
            />
          ))}
        {viewingSpec && (
          <PanelEditor
            panelBankSectionConfigRef={panelBankSectionConfigRef}
            panelSpec={panelSpec}
            initialConfigState={{editMode: true}}
            editable={true}
            customRunColorsRef={this.props.customRunColorsRef}
            runSetRefs={this.props.runSetRefs}
            panelRef={this.props.panelRef}
            panelSettings={this.props.panelSettings}
            currentHeight={this.props.currentHeight}
            onCancel={() => this.setState({viewingSpec: false})}
            onRemove={this.props.onRemovePanel}
            onOK={() => {
              this.setState({viewingSpec: false});
            }}
          />
        )}
        <div
          className={_compact(classNames).join(' ')}
          onMouseDown={e => {
            if (this.props.viewer?.userInfo?.bio?.includes('INSPECTOR')) {
              if (e.metaKey) {
                document.getSelection()?.removeAllRanges();
                this.props.setPanelSelection(
                  this.props.panelSelection.concat([panelRef])
                );
              } else {
                this.props.setPanelSelection([panelRef]);
              }
            }
          }}>
          {!readOnly && this.props.dragHandle}

          <div className="editable-panel__actions">
            {/* Edit panel */}
            {!readOnly &&
              !panelSpec.noEditMode &&
              (this.props.panelType !== 'Vega' || vegaPanelEnabled) && (
                <Popup
                  content="Edit panel"
                  inverted
                  size="mini"
                  position="top center"
                  trigger={
                    <LegacyWBIcon
                      name="edit"
                      className="panel-action hide-in-hidden-panels"
                      onClick={() => {
                        this.setState({editing: true});
                      }}
                    />
                  }
                />
              )}
            {/* View spec */}
            {readOnly && this.props.panelType === 'Vega2' && (
              <Popup
                content="View panel spec"
                size="mini"
                position="top center"
                trigger={
                  <LegacyWBIcon
                    name="show"
                    className="panel-action"
                    onClick={() => {
                      this.setState({viewingSpec: true});
                    }}
                  />
                }
              />
            )}
            {/* View full screen */}
            <Popup
              content="View full screen"
              inverted
              size="mini"
              position="top center"
              trigger={
                <LegacyWBIcon
                  name="fullscreen"
                  title="View fullscreen"
                  className="panel-action"
                  onClick={() => {
                    this.setState({fullScreen: true});
                  }}
                />
              }
            />
            {readOnly && addComment != null && (
              <Popup
                content="Add a comment"
                inverted
                size="mini"
                position="top center"
                trigger={
                  <LegacyWBIcon
                    name="chat"
                    title="Add comment"
                    className="panel-action"
                    onClick={addComment}
                  />
                }
              />
            )}
            {/* Three-dot popup menu */}
            {!readOnly && (
              <>
                <PanelActionsOverflow
                  addPanelToReport={this.addPanelToReport}
                  sharePanel={this.sharePanel}
                  duplicatePanel={duplicatePanel}
                  onRemovePanel={onRemovePanel}
                  panelExporter={
                    isExportable(panelSpec)
                      ? close => (
                          <PanelExportMenu
                            panelSpec={panelSpec}
                            panelRef={panelRef}
                            runSetRefs={runSetRefs}
                            panelSettings={panelSettings}
                            onTriggerExportImage={(s: 'png' | 'svg') => {
                              this.setState({showExportImageModal: s});
                              close();
                            }}
                            onTriggerExportAPI={() => {
                              this.setState({showExportAPIModal: true});
                              close();
                            }}
                            onTriggerExportCSV={() => {
                              this.setState({showExportCSVModal: true});
                              close();
                            }}
                          />
                        )
                      : undefined
                  }
                  // The "Move panel to {default section}" menu option
                  defaultMovePanelOption={
                    panelBankSectionConfigRef != null &&
                    defaultMoveToSectionRef != null &&
                    panelBankSectionConfigRef !== defaultMoveToSectionRef &&
                    movePanel != null ? (
                      <DefaultMovePanelOption
                        toSectionRef={defaultMoveToSectionRef}
                        defaultMovePanel={() => {
                          movePanel(
                            panelRef,
                            panelBankSectionConfigRef,
                            defaultMoveToSectionRef,
                            0
                          );
                        }}
                      />
                    ) : undefined
                  }
                  panelMover={() =>
                    panelBankSectionConfigRef != null &&
                    panelBankConfigRef != null &&
                    movePanel != null &&
                    movePanelToNewSection != null && (
                      <PanelMoverMenu
                        panelBankConfigRef={panelBankConfigRef}
                        panelBankSectionConfigRef={panelBankSectionConfigRef}
                        onAddSection={newSectionName => {
                          movePanelToNewSection({
                            fromSectionRef: panelBankSectionConfigRef,
                            panelRef,
                            newSectionName,
                          });
                        }}
                        onChange={toSectionRef => {
                          movePanel(
                            panelRef,
                            panelBankSectionConfigRef,
                            toSectionRef,
                            0
                          );
                        }}
                      />
                    )
                  }
                />
                <PanelExportAPI
                  open={showExportAPIModal}
                  onClose={() => this.setState({showExportAPIModal: false})}
                  runSetRefs={runSetRefs}
                />
                {isExportableAs(panelSpec, 'csv') && (
                  <PanelExportCSV
                    open={showExportCSVModal}
                    onClose={() => this.setState({showExportCSVModal: false})}
                    panelRef={panelRef}
                    panelSettings={panelSettings}
                    runSetRefs={runSetRefs}
                  />
                )}
                {!!this.state.showExportImageModal && (
                  <PanelImageExportModal
                    open={true}
                    type={this.state.showExportImageModal}
                    onClose={() => this.setState({showExportImageModal: false})}
                    panelRef={this.props.panelRef}
                    panelSettings={this.props.panelSettings}
                    customRunColorsRef={this.props.customRunColorsRef}
                    readOnly={readOnly}
                    searchQuery={this.props.searchQuery}
                    panelSpec={panelSpec}
                    runSetRefs={this.props.runSetRefs}></PanelImageExportModal>
                )}
              </>
            )}
            {panelCommentCount != null && panelCommentCount > 0 && (
              <Popup
                content="View comments"
                inverted
                size="mini"
                position="top center"
                trigger={
                  <div className="panel-comments" onClick={openPanelComments}>
                    <span>{panelCommentCount}</span>
                  </div>
                }
              />
            )}
          </div>
          <div
            className="editable-panel__content"
            // editablePanelDOMID from reportExport.ts, not imported for code-splitting
            id={'editable-panel-' + this.props.panel.__id__}>
            <PanelCompRedux
              configMode={false}
              runSetRefs={this.props.runSetRefs}
              panelRef={this.props.panelRef}
              panelSettings={this.props.panelSettings}
              customRunColorsRef={this.props.customRunColorsRef}
              currentHeight={this.props.currentHeight}
              dimensions={undefined as any}
              readOnly={readOnly}
              disableRunLinks={disableRunLinks}
              searchQuery={this.props.searchQuery}
              onContentHeightChange={onContentHeightChange}
            />
          </div>
        </div>
      </>
    );
  }
}

function useEditablePanelProps({
  panelRef,
  runSetRefs,
  customRunColorsRef,
  panelSettings,
  panelBankConfigRef,
}: EditablePanelProps) {
  const panel = ViewHooks.useWhole(panelRef);
  const panelType = panel.viewType;
  const runSets = ViewHooks.useWholeArray(runSetRefs);
  const customRunColors = ViewHooks.useWhole(customRunColorsRef);
  const {mergeFilters, runSetName} = useContext(RunQueryContext);
  const {setExportingPanelRefID} = useContext(PanelBankUpdaterContext);
  const {entityName, projectName} = RunHooks.useRunSetsQuery(runSetRefs);
  const viewer = useViewer();
  let movePanelToNewSection;
  if (panelBankConfigRef != null) {
    movePanelToNewSection = ViewHooks.useViewAction(
      panelBankConfigRef,
      PanelBankConfigActions.movePanelToNewSection
    );
  }
  const panelSelection = InteractStateContext.useInteractState(
    interactState => interactState.panelSelection
  );
  const setPanelSelection = InteractStateContext.useInteractStateAction(
    InteractStateActions.setPanelSelection
  );
  const vegaPanelEnabled = isFeatureFlagEnabled({
    featureKey: entityName,
    featureName: 'vega_panel',
  });
  const pushSavedReport = usePushReport(entityName, projectName, false);
  return {
    panel,
    panelType,
    runSets,
    customRunColors,
    panelSettings,
    mergeFilters,
    runSetName,
    setExportingPanelRefID,
    entityName,
    movePanelToNewSection,
    panelSelection,
    setPanelSelection,
    vegaPanelEnabled,
    viewer,
    pushSavedReport,
  };
}

export const EditablePanel = makeComp(
  (props: EditablePanelProps) => {
    const selectedProps = useEditablePanelProps(props);
    const domRef = useRef<HTMLDivElement>(null);
    const shouldRender = useWaitToLoadTilOnScreen(domRef); // && !props.loading;
    return (
      <div ref={domRef}>
        {shouldRender ? (
          <EditablePanelInner {...props} {...selectedProps} />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              border: '1px solid #eee',
              backgroundColor: 'white',
            }}
          />
        )}
      </div>
    );
  },
  {id: 'EditablePanel'}
);
