import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Image, List} from 'semantic-ui-react';

import _ from 'lodash';
import {CreateTeamButton} from '../CreateTeamModal';
import {Graph} from '../../types/graphql';
import makeComp from '../../util/profiler';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {navigateTo} from '../../util/history';
import {useViewer} from '../../state/viewer/hooks';
import {envIsLocal, envIsCloudOnprem} from '../../config';

interface TeamsListProps {
  data: Graph<any>;
  name: string;
  userQueryRefetch: () => Promise<any>;
}

const TeamsList: FC<TeamsListProps> = makeComp(
  ({data, userQueryRefetch}) => {
    const viewer = useViewer() ?? null;
    let addTeamsComp: JSX.Element;

    const teams = _.reject(data.edges, n => {
      return n.node.name === viewer?.entity;
    });
    const defaultEntity = _.find(data.edges, n => {
      return n.node.name === viewer?.entity;
    });

    if (
      envIsLocal ||
      envIsCloudOnprem ||
      viewer == null ||
      viewer.organizations?.length > 0
    ) {
      // This is a bit janky, but we should only show the "create team" modal
      // if we're in any kind of onprem env, or if we don't have the viewer info
      // yet, or if we know this user is already a part of an organization.
      // Otherwise we should direct the user to '/create-team'.
      addTeamsComp = (
        <CreateTeamButton
          onCreate={() => userQueryRefetch()}
          renderButton={onClick => (
            <List.Item onClick={onClick} style={{cursor: 'pointer'}}>
              <LegacyWBIcon name="plus" />
              <List.Content>
                <span className="fake-link">Invite your team</span>
              </List.Content>
            </List.Item>
          )}
        />
      );
    } else {
      addTeamsComp = (
        <List.Item
          onClick={() => navigateTo({pathname: '/create-team'})}
          style={{cursor: 'pointer'}}>
          <LegacyWBIcon name="plus" />
          <List.Content>
            <span className="fake-link">Invite your team</span>
          </List.Content>
        </List.Item>
      );
    }
    return (
      <div style={{marginTop: 24}}>
        {defaultEntity && (
          <>
            <p className="subtitle">Profile</p>
            <List className="teams-list">
              <List.Item key={defaultEntity.node.name}>
                <Image
                  src={defaultEntity.node.photoUrl}
                  style={{width: '20px', paddingRight: '0px'}}
                />
                <List.Content>
                  <Link to={`/${defaultEntity.node.name}`}>
                    {defaultEntity.node.name}
                  </Link>
                </List.Content>
              </List.Item>
            </List>
          </>
        )}
        <p className="subtitle">Teams</p>
        <List className="teams-list">
          {teams.map(team => {
            return (
              <List.Item key={team.node.name}>
                <Image
                  src={team.node.photoUrl}
                  style={{width: '20px', paddingRight: '0px'}}
                />
                <List.Content>
                  <Link to={`/${team.node.name}`}>{team.node.name}</Link>
                </List.Content>
              </List.Item>
            );
          })}
          {addTeamsComp}
        </List>
      </div>
    );
  },
  {id: 'TeamsList', memo: true}
);

export default TeamsList;
