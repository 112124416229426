import React from 'react';
import {useMemo} from 'react';
import * as Types from '@wandb/cg/browser/model/types';
import * as CGReact from '../../cgreact';

import * as Panel2 from './panel';
import {PanelComp2} from './PanelComp';

type PanelMaybeProps = Panel2.PanelConverterProps;

const PanelMaybe: React.FC<PanelMaybeProps> = props => {
  const {input} = props;
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);

  const nodeWithConvertedType = useMemo(() => {
    const convertedType = Spec.convert(input.path.type);
    if (convertedType == null) {
      throw new Error('invalid');
    }
    return {
      ...input.path,
      type: convertedType,
    };
  }, [input.path]);

  return nodeValueQuery.loading || nodeValueQuery.result == null ? (
    <div>-</div>
  ) : (
    <PanelComp2
      input={{path: nodeWithConvertedType}}
      inputType={nodeWithConvertedType.type}
      loading={props.loading}
      panelSpec={props.child}
      configMode={false}
      config={props.config}
      context={props.context}
      updateInput={props.updateInput}
      updateConfig={props.updateConfig}
      updateContext={props.updateContext}
    />
  );
};

export const Spec: Panel2.PanelConvertSpec = {
  id: 'maybe',
  displayName: 'Maybe',
  Component: PanelMaybe,
  convert: (inputType: Types.Type) => {
    let tags: Types.Type | undefined;
    if (Types.isTaggedValueLike(inputType)) {
      tags = Types.taggedValueTagType(inputType);
      inputType = Types.taggedValueValueType(inputType);
    }
    if (!Types.isUnion(inputType)) {
      return null;
    }
    const membersWithoutNull = inputType.members.filter(
      m => m !== 'none' && !(Types.isTaggedValue(m) && m.value === 'none')
    );
    if (membersWithoutNull.length === inputType.members.length) {
      // No null! Maybe means T | null
      return null;
    }
    if (membersWithoutNull.length === 1) {
      return Types.taggedValue(tags, membersWithoutNull[0]);
    }
    return Types.taggedValue(tags, {
      type: 'union',
      members: membersWithoutNull,
    });
  },
  defaultFixedSize: childDims => childDims,
};
