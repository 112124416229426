import * as d3 from 'd3';
import raw from 'raw.macro';

const LEGEND_FONT_SIZE = 11;
const LEGEND_MARKER_VIEWBOX_SIZE = 1024;
const WB_ICON_PREFIX = 'wbic-ic-';

// mirrors assets/wb-icons/fonts/wb-icons.svg
// every icon used as a legend marker needs an entry here to be exported properly
const PATH_BY_WB_ICON_NAME: {[icon: string]: string} = {
  'line-solid':
    'M128 533.333h768c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-768c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667z',
  'line-dash':
    'M128 533.333h298.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-298.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM597.333 533.333h298.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-298.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667z',
  'line-dash-dot':
    'M128 533.333h554.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-554.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM853.333 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667z',
  'line-dash-dot-dot':
    'M128 533.333h341.333c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-341.333c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM853.333 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM640 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667z',
  'line-dot':
    'M597.333 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM810.667 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM384 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667zM170.667 533.333h42.667c23.564 0 42.667-19.103 42.667-42.667v-85.333c0-23.564-19.103-42.667-42.667-42.667h-42.667c-23.564 0-42.667 19.103-42.667 42.667v85.333c0 23.564 19.103 42.667 42.667 42.667z',
  'line-disconnected':
    'M341.333 704c0-70.692-57.308-128-128-128s-128 57.308-128 128c0 70.692 57.308 128 128 128s128-57.308 128-128zM597.333 192c0-70.692-57.308-128-128-128s-128 57.308-128 128c0 70.692 57.308 128 128 128s128-57.308 128-128zM938.667 533.333c0-70.692-57.308-128-128-128s-128 57.308-128 128c0 70.692 57.308 128 128 128s128-57.308 128-128z',
};

export const exportName = () => {
  return ['W&B Chart', new Date().toLocaleString('default')].join(' ');
};

export function commenceDownload(
  filename: string,
  imgdata: string,
  callback?: () => void
) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('download', filename);
  a.setAttribute('href', imgdata);
  a.style.display = 'none';
  a.click();

  setTimeout(() => {
    if (callback) {
      callback();
    }
    document.body.removeChild(a);
  }, 10);
}

export function downloadPDF(parentElement: HTMLElement, name: string) {
  const [svg] = renderSVGForDownload(parentElement);
  const rect = svg.getBoundingClientRect();
  const xml = new XMLSerializer().serializeToString(svg);
  const url = URL.createObjectURL(new Blob([xml], {type: 'text/xml'}));
  // eslint-disable-next-line wandb/no-unprefixed-urls
  const w = window.open(
    url,
    name,
    'width=' + rect.width + ',height=' + rect.height
  );
  if (w) {
    w.document.title = name;
    w.print();
  }
}

export function downloadSVG(parentElement: HTMLElement, name: string) {
  const [svg] = renderSVGForDownload(parentElement);
  const xml = new XMLSerializer().serializeToString(svg);
  const url = URL.createObjectURL(new Blob([xml], {type: 'text/xml'}));
  commenceDownload(name + '.svg', url, () => URL.revokeObjectURL(url));
}

export async function generatePNG(
  parentElement: HTMLElement,
  zoomFactor = window.devicePixelRatio * 4
) {
  // TODO: hacky 5 second timer to ensure we have svg content to render
  let attempts = 0;
  while (attempts < 10) {
    if (parentElement.querySelector('svg') === null) {
      attempts += 1;
      await new Promise(resolve => setTimeout(resolve, 500));
    } else {
      attempts = 10;
    }
  }
  if (parentElement.querySelector('svg') === null) {
    throw new Error('No SVG to render PNG from');
  }
  const parentRect = parentElement.getBoundingClientRect();
  const [svg, extraHeight] = renderSVGForDownload(parentElement);
  if (parentElement.querySelector('svg') === null) {
    alert('Data still loading... unable to render chart.');
    return;
  }

  const contentCanvas: HTMLCanvasElement | null = parentElement.querySelector(
    '.rv-xy-canvas-element'
  );
  const canvas = document.createElement('canvas');
  const div = document.createElement('div');
  const width = parentRect.width * zoomFactor;
  const height = (parentRect.height + extraHeight) * zoomFactor;

  svg.setAttribute('width', width.toString());
  svg.setAttribute('height', height.toString());
  div.setAttribute('style', `width:${width}px;height:${height}px`);
  div.appendChild(svg);
  div.appendChild(canvas);
  document.body.appendChild(div);

  canvas.setAttribute('width', width.toString());
  canvas.setAttribute('height', height.toString());
  canvas.style.display = 'none';

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.fillStyle = 'white'; // TODO: darkmode
  context.fillRect(0, 0, canvas.width, canvas.height);
  const xml = new XMLSerializer().serializeToString(svg);
  const imgsrc = `data:image/svg+xml;base64,${btoa(
    unescape(encodeURIComponent(xml))
  )}`;

  const image = new Image();
  await new Promise((resolve, reject) => {
    image.onload = () => resolve();
    image.onerror = e => {
      document.body.removeChild(div);
      reject(e);
    };
    image.src = imgsrc;
  });

  // Draw svg content
  context.drawImage(image, 0, 0);

  // Draw canvas content with scaling and translates to match the SVG content
  if (contentCanvas != null) {
    const contentRect = contentCanvas.getBoundingClientRect();
    const t = contentRect.top - parentRect.top;
    context.translate(0, t * zoomFactor);
    context.translate(0, extraHeight * zoomFactor);

    // The in-dom canvas is already scaled based on device ratio
    // We want to scale the canvas width and height to take into account for this
    const canvasScaleRatio = zoomFactor / window.devicePixelRatio;
    if (zoomFactor !== window.devicePixelRatio) {
      context.scale(canvasScaleRatio, canvasScaleRatio);
    }
    context.drawImage(contentCanvas, 0, 0);
  }

  const ua = navigator.userAgent.toLowerCase();
  // TODO: Removing the element in safari causes weird black border rendering issues
  if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1) {
    div.style.display = 'none';
  } else {
    document.body.removeChild(div);
  }

  return canvas.toDataURL('image/png');
}

export function downloadPNG(
  parentElement: HTMLElement,
  name: string,
  zoomFactor = window.devicePixelRatio * 4
) {
  generatePNG(parentElement, zoomFactor).then(data => {
    commenceDownload(`${name}.png`, data!);
  });
}

export function renderSVGForDownload(
  parentEl: HTMLElement
): [SVGSVGElement, number] {
  if (parentEl.querySelector('svg') === null) {
    alert('Data still loading... unable to render chart.');
    return [document.createElement('svg') as any, 0];
  }

  const parent = d3.select(parentEl);
  const parentRect = parentEl.getBoundingClientRect();

  const legendEl: HTMLElement | null =
    parentEl.querySelector('.line-plot-legend');
  let extraHeight = 0;
  let legendHeightReduction = 0;
  if (legendEl != null) {
    if (legendEl.scrollHeight > legendEl.offsetHeight) {
      // Hack to expand container vertically if we have legend content which overflows.
      // This allows us to fit all of the legend items in.
      extraHeight += legendEl.scrollHeight - legendEl.offsetHeight;
    }
    if (legendEl.children.length > 0) {
      // Hack to hide "Showing first X groups" message when the
      // amount of legend items exceeds the limit.
      const firstChild = legendEl.children[0] as HTMLElement;
      if (/^Showing first/i.test(firstChild.innerText)) {
        legendHeightReduction = firstChild.offsetHeight;
        extraHeight -= legendHeightReduction;
      }
    }
  }

  // Create an SVG wrapper to pad the chart for labels
  const wrapperSVG = d3
    .select(document.createElementNS('http://www.w3.org/2000/svg', 'svg'))
    .attr(
      'viewBox',
      `0 0 ${parentRect.width} ${parentRect.height + extraHeight}`
    );

  const textG = wrapperSVG.append('g');

  const title: HTMLElement | null = parentEl.querySelector('.panel-title');
  if (title != null) {
    appendText(title)
      .attr('x', '50%')
      .style('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', 13);
  }

  if (legendEl != null) {
    legendEl.scrollTop = 0;
    const labels = parent.selectAll('.line-plot-legend>span>span');

    let currentIconName = '';
    labels.each(function () {
      // remember current legend icon so we can append it before its label
      const iconEl = (this as Element).querySelector('i.icon');
      if (iconEl != null) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < iconEl.classList.length; i++) {
          const c = iconEl.classList[i];
          if (c.startsWith(WB_ICON_PREFIX)) {
            currentIconName = c.replace(WB_ICON_PREFIX, '');
            break;
          }
        }
      }

      if (this instanceof HTMLElement && this.innerText !== '') {
        // create a legend marker for each label
        appendLegendMarker(this, currentIconName, -legendHeightReduction);
        // create a text node for each label
        appendText(this, -legendHeightReduction).style(
          'font-size',
          LEGEND_FONT_SIZE
        );
      }
    });
  }

  const added: Element[] = [];
  parent.selectAll('svg').each(function () {
    if (!(this instanceof Element)) {
      return;
    }
    const svgEl = this;

    // HAX: We don't want to include the blank svg which
    // serves as the container for the line plot's hover text
    if (
      svgEl.children.length === 1 &&
      svgEl.children[0].classList.contains('highlight-container')
    ) {
      return;
    }

    // HAX: We don't want to include duplicate
    // children of elements we've already added
    let p = svgEl.parentElement;
    while (p != null && p !== parentEl) {
      if (added.indexOf(p) !== -1) {
        return;
      }
      p = p.parentElement;
    }

    const rect = svgEl.getBoundingClientRect();
    wrapperSVG
      .append('g')
      .attr(
        'transform',
        `translate(0, ${rect.top - parentRect.top + extraHeight})`
      )
      .append(() => svgEl.cloneNode(true) as Element);
    added.push(this);
  });

  // Global overrides
  if (parentEl.querySelector('.line-plot') == null) {
    // Hide min/max areas for non-line plots
    wrapperSVG.selectAll('path').style('fill-opacity', '0');
  }
  wrapperSVG.selectAll('text').style('font-family', 'sans-serif'); // Standardize font family

  const style = document.createElement('style');
  style.innerText =
    '/*<![CDATA[*/\n' +
    'text { font-size: 10px; fill: black }\n' +
    raw('../../node_modules/react-vis/dist/style.css') +
    '\n' +
    '/*]]>*/';
  const outerNode = wrapperSVG.node();
  if (outerNode != null) {
    outerNode.appendChild(style);
  }
  // TODO: handle histograms
  return [wrapperSVG.node() as SVGSVGElement, extraHeight];

  function appendText(el: HTMLElement, yOffset = 0) {
    const rect = el.getBoundingClientRect();
    return textG
      .append('text')
      .text(el.innerText)
      .attr('x', rect.left - parentRect.left)
      .attr('y', rect.top - parentRect.top + yOffset)
      .style('fill', getComputedStyle(el).color!)
      .style('dominant-baseline', 'text-before-edge');
  }

  function appendLegendMarker(
    el: HTMLElement,
    iconName: string,
    yOffset = 0
  ): void {
    const path = PATH_BY_WB_ICON_NAME[iconName];
    if (path == null) {
      return;
    }
    const rect = el.getBoundingClientRect();
    const x = rect.left - parentRect.left - 19;
    const y = rect.top - parentRect.top + yOffset + 2;

    textG
      .append('svg')
      .attr(
        'viewBox',
        `0 0 ${LEGEND_MARKER_VIEWBOX_SIZE} ${LEGEND_MARKER_VIEWBOX_SIZE}`
      )
      .attr('x', x)
      .attr('y', y)
      .attr('height', LEGEND_FONT_SIZE)
      .attr('width', LEGEND_FONT_SIZE)
      .append('path')
      .attr('d', path)
      .style('fill', getComputedStyle(el).color!);
  }
}
