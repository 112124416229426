import * as globals from '../../../css/globals.styles';

import styled from 'styled-components';
import {REPORT_FONT_SIZE} from '../constants';

export const BlockQuote = styled.blockquote`
  font-family: ${globals.fontName};
  border-left: 4px solid #eee;
  padding-left: 12px;
  font-size: ${REPORT_FONT_SIZE}px;
  margin: 0 12px ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
`;
