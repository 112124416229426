import React from 'react';
import makeComp from '../util/profiler';
import {Button} from 'semantic-ui-react';

export const ArtifactCompareButton = makeComp<{
  show?: boolean;
  onClick?: React.MouseEventHandler;
}>(
  ({show, onClick}) => {
    return show ? (
      <Button
        className="artifact-compare-button"
        size="tiny"
        style={{marginLeft: 8, padding: '2px 4px'}}
        onClick={onClick}>
        Compare
      </Button>
    ) : null;
  },
  {id: 'ArtifactCompareButton'}
);
