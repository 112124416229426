import * as S from './LocalLicenseNudgeBar.styles';

import React from 'react';
import makeComp from '../util/profiler';
import NudgeBar from './NudgeBar';
import {useLicenseExpiredQuery} from '../generated/graphql';

const LocalLicenseNudgeBar: React.FC = makeComp(
  () => {
    const licenseExpiredQuery = useLicenseExpiredQuery();

    if (!licenseExpiredQuery.data?.serverInfo?.licenseExpired) {
      return null;
    }

    return (
      <NudgeBar
        content={
          <p>
            Your license is expired. Contact us to renew at{' '}
            <S.NudgeEmailLink href="mailto:contact@wandb.com">
              contact@wandb.com
            </S.NudgeEmailLink>
          </p>
        }
      />
    );
  },
  {id: 'LocalLicenseNudgeBar'}
);

export default LocalLicenseNudgeBar;
