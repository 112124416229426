import _ from 'lodash';
import React, {FC, useState} from 'react';
import {useDispatch, usePropsSelector} from '../state/hooks';
import * as ViewActions from '../state/views/actions';
import * as ViewSelectors from '../state/views/selectors';
import * as ViewThunks from '../state/views/thunks';
import {useRunsData} from '../state/runs/hooks';
import * as Filter from '../util/filters';
import * as Query from '../util/queryts';
import makeComp from '../util/profiler';

interface ViewListProps {
  entityName: string;
  projectName: string;
  viewType: string;
}

const ViewList = makeComp(
  (props: ViewListProps) => {
    const {entityName, projectName, viewType} = props;
    const dispatch = useDispatch();
    const viewList = usePropsSelector(
      ViewSelectors.makeViewListSelector,
      entityName,
      projectName,
      viewType
    );
    return (
      <div>
        <h3>View list</h3>
        <div>Loading: {viewList.loading.toString()}</div>
        <div>Items</div>
        <ul>
          {_.values(viewList.views).map(v => (
            <li key={v.cid}>
              {v.name} Loading: {v.loading.toString()}{' '}
              <button onClick={() => dispatch(ViewThunks.load(v.cid))}>
                Load view
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  },
  {id: 'ViewList'}
);

const ViewLoader = makeComp(
  () => {
    const [dummy, setDummy] = useState('dummy');
    const [entityName, setEntityName] = useState('shawn');
    const [projectName, setProjectName] = useState('new-projo13');
    const [viewType, setViewType] = useState('project-view');
    const dispatch = useDispatch();

    const viewListParams = {entityName, projectName, viewType};
    return (
      <div>
        <h2>View Loader</h2>
        <div>
          <input value={dummy} onChange={e => setDummy(e.target.value)} />
        </div>
        <div>
          <input
            value={entityName}
            onChange={e => setEntityName(e.target.value)}
          />
        </div>
        <div>
          <input
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
          />
        </div>
        <div>
          <input value={viewType} onChange={e => setViewType(e.target.value)} />
        </div>
        <button
          onClick={() =>
            dispatch(
              ViewThunks.loadMetadataList(
                ViewActions.loadMetadataListStarted(viewListParams)
              )
            )
          }>
          Load
        </button>
        <ViewList {...viewListParams} />
      </div>
    );
  },
  {id: 'ViewLoader'}
);

const RunsDataTest = makeComp(
  () => {
    const rd = useRunsData({
      entityName: 'shawn',
      projectName: 'new-projo13',
      keysLoading: false,
      queries: [
        {
          id: 'test-id-unused',
          entityName: 'shawn',
          projectName: 'new-projo13',
          filters: Filter.EMPTY_FILTERS,
          sort: Query.CREATED_AT_ASC,
        },
      ],
    });
    return (
      <div>
        <h2>Runs data test</h2>
        <pre>{JSON.stringify(rd, undefined, 2)}</pre>
      </div>
    );
  },
  {id: 'RunsDataTest'}
);

const TestPage: FC = makeComp(
  () => {
    return (
      <div>
        <h1>Test Page</h1>
        <RunsDataTest />
        <div style={{marginBottom: 32}} />
        <ViewLoader />
      </div>
    );
  },
  {id: 'TestPage', memo: true}
);

export default TestPage;
