import {matchPath} from 'react-router';
import {
  GALLERY_DISCUSSION_EDIT_PATH,
  GALLERY_DISCUSSION_VIEW_PATH,
  GALLERY_PATH,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
} from '../../routeData';

export const encodeURISafe = makeSafeURIFunction(encodeURI);
export const decodeURISafe = makeSafeURIFunction(decodeURI);
export const encodeURIComponentSafe = makeSafeURIFunction(encodeURIComponent);
export const decodeURIComponentSafe = makeSafeURIFunction(decodeURIComponent);

type URIFunction = typeof encodeURI;

function makeSafeURIFunction(fn: URIFunction): URIFunction {
  return (str: string) => {
    try {
      return fn(str);
    } catch {
      return str;
    }
  };
}

export function encodeURIIfNotYetEncoded(uri: string): string {
  const decoded = decodeURISafe(uri);

  const isAlreadyEncoded = uri !== decoded;
  if (isAlreadyEncoded) {
    return uri;
  }

  return encodeURISafe(uri);
}

// The 'history' library specifically decodes '%25' into '%', while it leaves other
// special characters in their encoded forms. When we try to decode URIs on top of history's
// weird decoding, we run into errors because of the inconsistent special character decoding
// done by history.
// This is a dirty workaround that catches malformed URIs and manually encodes '%' into '%25',
// so that we can decode appropriately.
export function decodeURIComponentHistoryHax(str: string): string {
  try {
    return decodeURIComponent(str);
  } catch {
    const encodedStr = encodeURIPercentChar(str);
    return decodeURIComponentSafe(encodedStr);
  }
}

// Because of inconsistent '%25' decoding behavior from the 'history' library,
// this function might be necessary to properly encode '%' as '%25' in a URI before decoding.
export function encodeURIPercentChar(str: string): string {
  return str.replace(/%(?![0-9A-F]{2})/g, '%25');
}

// Assumes id is a base64 encoded string
export function makeNameAndID(id: string, name?: string): string {
  // Note we strip base64 = padding to make this pretty. It's added back
  // in parseNameAndID above.
  id = id.replace(/=/g, '');
  if (name != null) {
    // Replace all non word characters with dashes, eliminate repeating dashes
    name = name.replace(/\W/g, '-').replace(/-+/g, '-');
  }
  return name != null ? `${encodeURIComponentSafe(name)}--${id}` : id;
}

export type ReportNameAndID = {id: string; name: string};

// Assumes s is <name>--<id> where <id> is a base64 encoded string
export function parseNameAndID(s: string): ReportNameAndID {
  let id = s;
  let name = '';
  const sepPos = s.lastIndexOf('--');
  if (sepPos !== -1) {
    name = decodeURIComponentSafe(s.slice(0, sepPos)).replace(/-/g, ' ');
    id = s.slice(sepPos + 2, s.length);
  }

  // Re-add base64 padding
  const pad = 4 - (id.length % 4);
  if (pad !== 4) {
    for (let i = 0; i < pad; i++) {
      id += '=';
    }
  }

  const decoded = atob(id);
  if (decoded.indexOf(':') === -1) {
    throw new Error('Attempted to parse invalid View ID');
  }
  const [viewString, actualId] = decoded.split(':', 2);
  if (viewString !== 'View' || !actualId.match(/^-{0,1}\d+$/)) {
    throw new Error('Attempted to parse invalid View ID');
  }
  return {name, id};
}

export function parseNameAndIDFromURL(url: string): ReportNameAndID {
  try {
    const nameAndID = url
      .split('?')[0] // remove querystring
      .replace(/\/$/, '') // remove trailing slash
      .split('/') // get last portion of URL
      .slice(-1)[0];
    return parseNameAndID(nameAndID);
  } catch (e) {
    throw new Error('Attempted to parse invalid URL');
  }
}

export function parseNameAndIDFromURLSafe(url: string): ReportNameAndID | null {
  try {
    return parseNameAndIDFromURL(url);
  } catch (e) {
    return null;
  }
}

export function parseNameAndIDSafe(s: string): ReportNameAndID | null {
  try {
    return parseNameAndID(s);
  } catch {
    return null;
  }
}

export function isOnReportView(pathname = window.location.pathname): boolean {
  const match = matchPath(pathname, {
    path: REPORT_PAGE_VIEW_PATH,
    exact: true,
  });
  if (match != null) {
    return true;
  }
  if (isOnPublishedReportView(pathname)) {
    return true;
  }
  return false;
}

export function isOnPublishedReportView(
  pathname = window.location.pathname
): boolean {
  const publishedMatch = matchPath(pathname, {
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    exact: true,
  });
  return publishedMatch != null;
}

export function isOnGalleryPostPage(
  pathname = window.location.pathname
): boolean {
  return isOnGalleryPostView(pathname) || isOnGalleryPostEdit(pathname);
}

export function isOnGalleryPostView(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_POST_VIEW_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryPostEdit(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_POST_EDIT_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryDiscussionPage(
  pathname = window.location.pathname
): boolean {
  return (
    isOnGalleryDiscussionView(pathname) || isOnGalleryDiscussionEdit(pathname)
  );
}

export function isOnGalleryDiscussionView(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_DISCUSSION_VIEW_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryDiscussionEdit(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_DISCUSSION_EDIT_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnFCPage(pathname = window.location.pathname): boolean {
  const match = matchPath(pathname, {path: GALLERY_PATH});
  return match != null;
}

export function canonicalize(url: string): string {
  let canonical = url.split('?')[0];
  if (canonical.endsWith('/')) {
    canonical = canonical.substring(0, canonical.length - 1);
  }
  return canonical;
}
