import * as globals from '../css/globals.styles';

import {History} from 'history';
import {includes} from 'lodash';
import * as Prism from 'prismjs';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

import {UpdateProjectMutationFn} from '../graphql/models';
import {Project, Team} from '../types/graphql';
import * as Benchmark from '../util/benchmark';
import {AccessOptions} from '../util/permissions';
import BenchmarkEditorFields from './BenchmarkEditorFields';
import {ProjectAccessOption, projectAccessOptions} from './ProjectAccess';
import projectName from '../util/projectName';
import makeComp from '../util/profiler';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {useAllProjectsQuery} from '../generated/graphql';
import {getQueryStringParamValue} from '../util/url';
import WandbLoader from './WandbLoader';

interface ProjectEditorProps {
  defaultName?: string;
  noNameEdit?: boolean;
  headerSize?: string;
  history: History;
  extraText?: string;
  benchmark?: boolean;
  submit: UpdateProjectMutationFn;
  addProject(project: Project): void;
}

const ProjectEditor: React.FC<ProjectEditorProps> = makeComp(
  props => {
    const viewer = useViewer();
    if (viewer == null) {
      return <WandbLoader />;
    }
    return <ProjectEditorWithViewer {...props} viewer={viewer} />;
  },
  {
    id: 'ProjectEditor',
    memo: true,
  }
);

const ProjectEditorWithViewer: React.FC<
  ProjectEditorProps & {
    viewer: Viewer;
  }
> = makeComp(
  ({
    viewer,
    defaultName,
    noNameEdit,
    headerSize,
    history,
    extraText,
    benchmark,
    submit,
    addProject,
  }) => {
    const entityNameFromViewer = viewer.defaultEntity?.name ?? viewer.username;
    const defaultEntityName =
      getQueryStringParamValue('entityName') || entityNameFromViewer;
    if (!defaultEntityName) {
      throw new Error('missing entity name');
    }
    const [entityName, setEntityName] = useState(defaultEntityName);
    const {data} = useAllProjectsQuery({
      variables: {entityName, first: 100, order: 'name'},
      fetchPolicy: 'cache-and-network',
    });
    const projects = data?.projects?.edges.map(p => p.node) ?? [];
    const [name, setName] = useState(defaultName ?? '');
    const [access, setAccess] = useState<AccessOptions | undefined>(undefined);
    const [description, setDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [benchmarkFields, setBenchmarkFields] =
      useState<Benchmark.BenchmarkFields>({
        overview:
          'Write a descriptive overview of your benchmark, including a description of the dataset and how they can access it. *(click to edit)*',
        submissionInstructions:
          "These will be rendered in users' linked projects. Let them know if there are any special requirements for submission. *(click to edit)*",
        keys: [],
        sortMetric: '',
        sortAsc: false,
        codeRequired: false,
        embargo: false,
      });

    const firstRenderRef = useRef(true);
    useEffect(() => {
      if (firstRenderRef.current) {
        return;
      }
      Prism.highlightAll();
    });
    useEffect(() => {
      firstRenderRef.current = false;
    }, []);

    const doesProjectNameExist = useCallback(() => {
      const existingProjectNames = projects.map(p => p!.name);
      return includes(existingProjectNames, name);
    }, [name, projects]);

    const canSubmit = useCallback(() => {
      if (doesProjectNameExist()) {
        return false;
      }
      return !!name && !submitting;
    }, [doesProjectNameExist, name, submitting]);

    const typeString = useCallback(
      (capitalize: boolean = false) => {
        if (capitalize) {
          return benchmark ? 'Benchmark' : 'Project';
        }
        return benchmark ? 'benchmark' : 'project';
      },
      [benchmark]
    );

    const projectNameExists = name !== '' && doesProjectNameExist();

    const renderTopFields = useCallback(() => {
      return (
        <>
          {projectNameExists && (
            <Message info size="small">
              <Message.Header>
                A project named "{name}" already exists.
              </Message.Header>
              <Message.Content>
                <p>
                  Try another name, or{' '}
                  <Link to={`/${entityName}/${name}`}>
                    visit your existing project.
                  </Link>
                </p>
              </Message.Content>
            </Message>
          )}
          <Form className="ui form">
            <Form.Field>
              <Form.Input
                disabled={noNameEdit}
                data-test="project-name-field"
                placeholder={`${typeString(true)} name`}
                value={name}
                onChange={(_, {value}) => setName(projectName(value))}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                placeholder={
                  benchmark
                    ? 'Short summary (optional)'
                    : 'Description (optional)'
                }
                onChange={e => setDescription(e.target.value)}
                value={description}
              />
            </Form.Field>
          </Form>
        </>
      );
    }, [
      benchmark,
      description,
      entityName,
      name,
      noNameEdit,
      projectNameExists,
      typeString,
    ]);

    const renderBottomFields = useCallback(
      (t: Team) => {
        return (
          <>
            <Header as="h3">Access</Header>
            <Form className="ui form">
              <Form.Field>
                {projectAccessOptions({
                  readOnlyAdmin: t.readOnlyAdmin,
                  projectAccess: access || t.defaultAccess,
                  defaultAccess: t.defaultAccess,
                  privateOnly: t.privateOnly,
                  isTeam: t.isTeam,
                  projectTypeString: typeString(),
                  selectAccess: (newAccess: AccessOptions) =>
                    setAccess(newAccess),
                }).map(option => (
                  <ProjectAccessOption key={option.access} {...option} />
                ))}
                {extraText && (
                  <p style={{marginTop: 16, marginBottom: 32}}>{extraText}</p>
                )}
              </Form.Field>
            </Form>
          </>
        );
      },
      [access, extraText, typeString]
    );

    const createButton = useCallback(() => {
      return (
        <Button
          data-test="create-project"
          primary
          disabled={!canSubmit()}
          content={'Create'}
          style={{marginTop: 16}}
          onClick={e => {
            e.preventDefault();
            setSubmitting(false);
            submit({
              description,
              access,
              name,
              entityName,
              views: benchmark
                ? JSON.stringify({fields: benchmarkFields})
                : undefined,
            }).then(res => {
              setSubmitting(false);
              if (res.data.upsertModel.inserted) {
                addProject(res.data.upsertModel.project);
              } else {
                history.push(
                  `/${entityName}/${res.data.upsertModel.project.name}`
                );
              }
            });
          }}
        />
      );
    }, [
      access,
      addProject,
      benchmark,
      benchmarkFields,
      canSubmit,
      description,
      entityName,
      history,
      name,
      submit,
    ]);

    const teams: Team[] = viewer.teams.edges.map(
      e => e.node as unknown as Team
    );
    const teamOptions = teams.map(t => ({
      text: t.name,
      value: t.name,
      image: {avatar: true, src: t.photoUrl},
    }));

    const team: Team | undefined = teams.find(t => t.name === entityName);
    useEffect(() => {
      if (team == null && teams.length > 0) {
        setEntityName(
          teams.find(t => t.name === entityNameFromViewer)?.name ??
            teams[0].name
        );
      }
    }, [team, teams, entityNameFromViewer]);

    if (team == null) {
      return <div />;
    }

    return (
      <>
        <Header
          style={{position: 'relative', zIndex: 100, opacity: 1}}
          as={headerSize || 'h1'}>
          Create {typeString()} in{' '}
          <Dropdown
            inline
            compact
            style={{color: globals.primary}}
            placeholder="Team"
            value={entityName}
            options={teamOptions}
            onChange={(_, {value}) => {
              if (typeof value !== 'string') {
                return;
              }
              setEntityName(value);
            }}
          />
        </Header>
        {noNameEdit && projectNameExists ? (
          <Message info size="small">
            <Message.Header>
              A project named "{name}" already exists.
            </Message.Header>
            <Message.Content>
              <p>
                <Link to={`/${entityName}/${name}`}>
                  Visit your existing project.
                </Link>
              </p>
            </Message.Content>
          </Message>
        ) : benchmark ? (
          <>
            <Segment>
              <Header as="h3">Benchmark</Header>
              {renderTopFields()}
            </Segment>
            {benchmark && (
              <BenchmarkEditorFields
                defaultBenchmarkFields={benchmarkFields}
                updateBenchmarkFields={update =>
                  setBenchmarkFields({...benchmarkFields, ...update})
                }
              />
            )}
            <Segment>{renderBottomFields(team)}</Segment>
            {createButton()}
          </>
        ) : (
          <Segment>
            {renderTopFields()}
            {renderBottomFields(team)}
            {createButton()}
          </Segment>
        )}
      </>
    );
  },
  {id: 'ProjectEditorWithViewer', memo: true}
);

export default ProjectEditor;
