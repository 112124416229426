import React from 'react';
import styled from 'styled-components';
import * as globals from '../css/globals.styles';

export const NudgeBarLargeTitle = styled.h1`
  font-size: 1.5rem;
`;

export const NudgeBarLargeAction = styled.h1`
  padding: 18px 0;
`;

export const NudgeBarLargeActionLink = styled.a`
  font-size: 1.4rem;
  color: ${globals.white};
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 10px 20px;
  background-color: transparent;

  &:hover {
    color: ${globals.white};
  }
`;

export const NudgeBarLarge: React.FC = styled.div<{bgColor?: 'errorDark'}>`
  background-color: ${p => globals[p.bgColor ?? 'errorDark']};
  color: ${globals.white};
  text-align: center;
  padding: 60px 10px;

  border-bottom: 1px solid ${globals.white};
`;
