import React, {useState} from 'react';
import {Button, Form, Segment} from 'semantic-ui-react';
import {CreateBenchmarkDiscussionCommentComponent} from '../generated/graphql';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import makeComp from '../util/profiler';

interface CreateDiscussionCommentFormProps {
  discussionThreadID: string;
  onSubmit: () => void;
}

const CreateDiscussionCommentForm: React.FC<CreateDiscussionCommentFormProps> =
  makeComp(
    ({discussionThreadID, onSubmit}) => {
      const [body, setBody] = useState('');
      const [editorKey, setEditorKey] = useState(randomKey());

      return (
        <div className="discussion-board-create-comment">
          <CreateBenchmarkDiscussionCommentComponent>
            {createCommentMutation => {
              return (
                <Segment>
                  <Form>
                    <Form.Field>
                      <InlineMarkdownEditor
                        key={editorKey}
                        placeholder="Contribute to the conversation"
                        readOnly={false}
                        onChange={value => {
                          setBody(value as string);
                        }}
                      />
                    </Form.Field>
                  </Form>
                  <Button
                    style={{marginTop: 5}}
                    disabled={body === ''}
                    onClick={() => {
                      createCommentMutation({
                        variables: {
                          discussionThreadID,
                          body,
                        },
                      })
                        .then(() => onSubmit())
                        .then(() => setEditorKey(randomKey()));
                    }}>
                    Comment
                  </Button>
                </Segment>
              );
            }}
          </CreateBenchmarkDiscussionCommentComponent>
        </div>
      );
    },
    {id: 'CreateDiscussionCommentForm', memo: true}
  );

export default CreateDiscussionCommentForm;

function randomKey(): string {
  return Math.random().toString(36);
}
