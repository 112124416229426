import * as React from 'react';

import NewArtifactType from './NewArtifactType';
import ProjectArtifactTypes from './ProjectArtifactTypes';
import {PanelExportContextProvider} from './Panel2/PanelExportContext';

import '../css/Artifacts.less';
import makeComp from '../util/profiler';

interface ProjectArtifactsTabProps {
  entityName: string;
  projectName: string;
  artifactTypeName?: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  history: any;
}

const ProjectArtifactsTab = makeComp(
  (props: ProjectArtifactsTabProps) => {
    const artifactTypeName =
      props.artifactTypeName != null
        ? decodeURIComponent(props.artifactTypeName)
        : undefined;

    // pass in the decoded artifact type name for correct linking
    const passThroughProps = {...props, artifactTypeName};

    // We're basically doing our own routing. There's probably a way to do this
    // by nesting react router or something
    if (artifactTypeName === 'new') {
      return <NewArtifactType {...passThroughProps} />;
    }
    return <ProjectArtifactTypes {...passThroughProps} />;
  },
  {id: 'ProjectArtifactsTab'}
);

const ProjectArtifactsTabWithClass = makeComp(
  (props: ProjectArtifactsTabProps) => {
    return (
      <div className="artifacts">
        <PanelExportContextProvider>
          <ProjectArtifactsTab {...props} />
        </PanelExportContextProvider>
      </div>
    );
  },
  {id: 'ProjectArtifactsTabWithClass'}
);

export default ProjectArtifactsTabWithClass;
