import gql from 'graphql-tag';
import {UserInfo, Graph} from '../../types/graphql';
import {AccessOptions} from '../../util/permissions';

export const VIEW_METADATA_FRAGMENT = gql`
  fragment ViewFragmentMetadata2 on View {
    id
    name
    displayName
    type
    description
    user {
      id
      username
      photoUrl
      admin
      name
    }
    updatedBy {
      id
      username
    }
    entityName
    project {
      id
      name
      entityName
      readOnly
    }
    previewUrl
    coverUrl
    updatedAt
    createdAt
    starCount
    starred
    parentId
    locked
    viewCount
    showcasedAt
    alertSubscription {
      id
    }
    accessTokens {
      id
      token
      view {
        id
      }
      type
      emails
      createdBy {
        id
        username
        email
        name
      }
      createdAt
      lastAccessedAt
      revokedAt
      projects {
        id
        name
        entityName
      }
    }
  }
`;

// TODO: Rename to metadata
export const VIEW_METADATA_QUERY = gql`
  query Views2Metadata(
    $entityName: String
    $name: String
    $viewType: String = "runs"
    $userName: String
    $viewName: String
  ) {
    project(name: $name, entityName: $entityName) {
      id
      allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
        edges {
          node {
            ...ViewFragmentMetadata2
          }
        }
      }
    }
  }
  ${VIEW_METADATA_FRAGMENT}
`;

export const VIEW_METADATA_SPEC_QUERY = gql`
  query Views2MetadataSpec(
    $entityName: String
    $name: String
    $viewType: String = "runs"
    $userName: String
    $viewName: String
  ) {
    project(name: $name, entityName: $entityName) {
      id
      allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
        edges {
          node {
            ...ViewFragmentMetadata2
            spec
          }
        }
      }
    }
  }
  ${VIEW_METADATA_FRAGMENT}
`;

export const VIEW_QUERY = gql`
  query Views2View($id: ID!) {
    view(id: $id) {
      ...ViewFragmentMetadata2
      spec
    }
  }
  ${VIEW_METADATA_FRAGMENT}
`;

export const VIEW_UPSERT = gql`
  mutation UpsertView2(
    $id: ID
    $entityName: String
    $projectName: String
    $type: String
    $name: String
    $displayName: String
    $description: String
    $spec: String
    $parentId: ID
    $locked: Boolean
    $previewUrl: String
    $coverUrl: String
    $showcasedAt: DateTime
  ) {
    upsertView(
      input: {
        id: $id
        entityName: $entityName
        projectName: $projectName
        name: $name
        displayName: $displayName
        description: $description
        type: $type
        spec: $spec
        parentId: $parentId
        locked: $locked
        previewUrl: $previewUrl
        coverUrl: $coverUrl
        showcasedAt: $showcasedAt
      }
    ) {
      view {
        ...ViewFragmentMetadata2
      }
      inserted
    }
  }
  ${VIEW_METADATA_FRAGMENT}
`;

export const VIEW_DELETE = gql`
  mutation deleteView2($id: ID, $deleteDrafts: Boolean) {
    deleteView(input: {id: $id, deleteDrafts: $deleteDrafts}) {
      success
      pendingDrafts
    }
  }
`;

export const VIEW_BATCH_DELETE = gql`
  mutation deleteViews($ids: [ID!]!, $deleteDrafts: Boolean) {
    deleteViews(input: {ids: $ids, deleteDrafts: $deleteDrafts}) {
      success
      pendingDrafts
    }
  }
`;

export const VIEW_STAR = gql`
  mutation starView($id: ID!) {
    starView(input: {id: $id}) {
      view {
        id
        starCount
        starred
      }
    }
  }
`;

export const VIEW_UNSTAR = gql`
  mutation unstarView($id: ID!) {
    unstarView(input: {id: $id}) {
      view {
        id
        starCount
        starred
      }
    }
  }
`;

export const STARGAZERS_QUERY = gql`
  query StargazersQuery($viewID: ID!, $limit: Int, $cursor: String) {
    view(id: $viewID) {
      id
      stargazers(after: $cursor, first: $limit) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            username
            userInfo
            photoUrl
          }
        }
      }
    }
  }
`;

export interface Stargazer {
  id: string;
  name: string;
  username: string;
  userInfo: UserInfo;
  photoUrl: string;
}

export interface StargazersQueryData {
  view: {
    id: string;
    stargazers: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        node: Stargazer;
      }>;
    };
  };
}

export const PUBLIC_VIEWS_QUERY = gql`
  query PublicViews($limit: Int, $cursor: String) {
    publicViews(type: "runs", after: $cursor, first: $limit) {
      edges {
        node {
          id
          name
          description
          user {
            id
            username
            name
          }
        }
      }
    }
  }
`;

export const VIEW_DRAFTS_QUERY = gql`
  query viewDrafts($viewID: ID!) {
    view(id: $viewID) {
      id
      children {
        edges {
          node {
            id
            displayName
            user {
              id
            }
            entityName
            project {
              id
              name
              entityName
            }
          }
        }
      }
    }
  }
`;

export interface PublicViewsQueryData {
  publicViews: Graph<{
    id: string;
    name: string;
    description: string;
    user: {
      id: string;
      username: string;
      name: string;
    };
  }>;
}

export interface ViewDraft {
  id: string;
  displayName: string;
  user: {
    id: string;
  };
  entityName: string;
  project: {
    id: string;
    name: string;
    entityName: string;
  };
}

export interface ViewDraftsQueryData {
  view: {
    id: string;
    children: Graph<ViewDraft>;
  };
}

export const VIEW_PERMISSIONS_QUERY = gql`
  query viewPermissions($projectName: String!, $entityName: String!) {
    entity(name: $entityName) {
      id
      isTeam
    }
    project(name: $projectName, entityName: $entityName) {
      id
      access
    }
  }
`;

export interface ViewPermissionsData {
  entity: {
    id: string;
    isTeam: boolean;
  };
  project: {
    id: string;
    access: AccessOptions;
  };
}

export const VIEW_VIEW_MUTATION = gql`
  mutation viewView($id: ID!) {
    viewView(input: {id: $id}) {
      success
    }
  }
`;
