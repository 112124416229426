/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'history-scatter-with-size',
  name: 'Scatter plot with size (run history)',
  description:
    'A Vega-lite viz scatter plot of history data. You can choose x-axis, y-axis and size.',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega-lite/v3.json',
    title: 'History scatter plot',
    data: {name: '${history-table:table:x-axis,y-axis,size}'},
    mark: 'point',
    encoding: {
      x: {field: '${history-field:x-axis}', type: 'quantitative'},
      y: {field: '${history-field:y-axis}', type: 'quantitative'},
      size: {field: '${history-field:size}', type: 'quantitative'},
      color: {field: 'runName', type: 'nominal'},
    },
  },
};

export default def;
