import * as React from 'react';

import {Checkbox, Input} from 'semantic-ui-react';
import LabeledOption from './elements/LabeledOption';
import {RunsLinePlotConfig} from './PanelRunsLinePlot';
import {BarChartConfig} from './PanelBarChart';
import MultiButton from './elements/MultiButton';
import * as Query from '../util/queryts';
import makeComp from '../util/profiler';
import {isLegendPosition, isFontSizeOrAuto} from '../util/plotHelpers';

type PanelConfig = RunsLinePlotConfig | BarChartConfig;

interface PanelChartOptionsProps {
  type: 'lines' | 'bars' | 'scalar';
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  defaultTitle?: string;
  defaultXAxisTitle?: string;
  defaultYAxisTitle?: string;
  pageQuery: Query.Query; // for the field selector
}

export function renderChartTitle(
  config: PanelConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  defaultTitle: string = ''
) {
  return (
    <LabeledOption
      label="Title"
      option={
        <Input
          placeholder={defaultTitle}
          value={config.chartTitle || ''}
          onChange={(e, {value}) => {
            updateConfig({
              chartTitle: value,
            });
          }}
        />
      }
    />
  );
}

const PanelChartOptions = makeComp(
  (props: PanelChartOptionsProps) => {
    const {config, updateConfig} = props;

    const fontSizeOptions = ['small', 'medium', 'large', 'auto'];
    const fontSizeLabels = ['Small', 'Medium', 'Large', 'Auto'];

    const legendPositionOptions = ['north', 'south', 'east', 'west'];
    const legendPositionLabels = ['North', 'South', 'East', 'West'];

    return (
      <>
        {renderChartTitle(config, updateConfig, props.defaultTitle)}
        {props.type === 'lines' && (
          <>
            <LabeledOption
              label="X-Axis Title"
              option={
                <Input
                  placeholder={props.defaultXAxisTitle}
                  value={config.xAxisTitle || ''}
                  onChange={(e, {value}) => {
                    updateConfig({
                      xAxisTitle: value,
                    });
                  }}
                />
              }
            />
            <LabeledOption
              label="Y-Axis Title"
              option={
                <Input
                  placeholder={props.defaultYAxisTitle}
                  value={config.yAxisTitle || ''}
                  onChange={(e, {value}) => {
                    updateConfig({
                      yAxisTitle: value,
                    });
                  }}
                />
              }
            />
          </>
        )}
        {(props.type === 'lines' || props.type === 'scalar') && (
          <LabeledOption
            label="Show Legend"
            option={
              <Checkbox
                toggle
                checked={
                  (config as RunsLinePlotConfig).showLegend ??
                  props.type === 'lines' // lines defaults to showLegend: true
                }
                onClick={(e, value) =>
                  updateConfig({
                    showLegend: value.checked,
                  })
                }
              />
            }
          />
        )}
        {props.type === 'lines' && (
          <>
            <LabeledOption
              label="Font Size"
              option={
                <MultiButton
                  keys={fontSizeOptions}
                  labels={fontSizeLabels}
                  selected={config.fontSize ?? 'auto'}
                  onChange={key => {
                    if (isFontSizeOrAuto(key)) {
                      updateConfig({
                        fontSize: key,
                      });
                    }
                  }}
                />
              }
            />
          </>
        )}
        {props.type === 'lines' && (
          <>
            <LabeledOption
              label="Legend Position"
              helpText="Placement of the chart legend."
              option={
                <MultiButton
                  keys={legendPositionOptions}
                  labels={legendPositionLabels}
                  selected={
                    (config as RunsLinePlotConfig).legendPosition ?? 'north'
                  }
                  onChange={key => {
                    if (isLegendPosition(key)) {
                      updateConfig({legendPosition: key});
                    }
                  }}
                />
              }
            />
          </>
        )}
      </>
    );
  },
  {id: 'PanelChartOptions'}
);

export default PanelChartOptions;
