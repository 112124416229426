import React from 'react';
import {useCallback, useState, useMemo} from 'react';
import {Dropdown} from 'semantic-ui-react';
import * as Hooks from '../../state/hooks';
import {PanelComp2} from './PanelComp';
import {getPanelStacksForType} from './availablePanels';
import {AddToReportModal} from '../AddToReportModal';
import {useParams} from 'react-router';
import {Button} from 'semantic-ui-react';
import * as Panel2 from './panel';
import makeComp from '../../util/profiler';
import {ThemeProvider} from 'styled-components';
import * as QueryEditorStyles from './ExpressionEditor.styles';
import * as Op from '@wandb/cg/browser/ops';
import * as LLReact from '../../cgreact';
import WandbLoader from '../WandbLoader';

const inputType = {
  type: 'union' as const,
  members: [
    'artifact' as const,
    {type: 'list' as const, objectType: 'artifact' as const},
  ],
};

export interface PanelFilesConfig {
  path?: string[];
}

type FilesProps = Panel2.PanelProps<typeof inputType, PanelFilesConfig>;

export const FilesPanel: React.FC<FilesProps> = makeComp(
  props => {
    const {config, context, updateConfig, updateContext} = props;
    const configPath = useMemo(() => config?.path ?? [], [config]);
    const pathString = configPath.join('/');

    const newPanelContext = useMemo(
      () => ({
        ...context,
        path: configPath,
      }),
      [context, configPath]
    );

    const artifacts = props.input.path;

    const files = useMemo(() => {
      return Op.applyOpToOneOrMany(
        Op.opArtifactVersionFile,
        'artifactVersion',
        artifacts,
        {
          path: Op.constString(pathString),
        }
      );
    }, [artifacts, pathString]);
    const fileWithTypeRaw = LLReact.useNodeWithServerType(files);
    const fileWithType = Hooks.useGatedValue(
      fileWithTypeRaw,
      ft => !ft.loading
    );

    const params = useParams<{reportNameAndID?: string}>();
    const showExportToReportButton = params.reportNameAndID == null;

    // We currently just store configs of our previews here, keyed by previewer
    // type. This is probably not really right. Maybe use file path?
    // TODO: better config strategy
    const [subConfigs, setSubConfigs] = useState<{[key: string]: any}>({});

    const [curPanelIdState, setCurPanelId] = useState<string | undefined>(
      undefined
    );

    const panelInputType = fileWithType.result.type;
    const {curPanelId, stackIds, handler} = useMemo(
      () => getPanelStacksForType(panelInputType, curPanelIdState),
      [panelInputType, curPanelIdState]
    );
    const finalInput = useMemo(() => {
      return {path: fileWithType.result};
    }, [fileWithType.result]);

    const panelConfig = useMemo(
      () =>
        curPanelId != null ? subConfigs[curPanelId + '-' + pathString] : null,
      [curPanelId, pathString, subConfigs]
    );

    const panelUpdateConfig = useCallback(
      (newConfig: any) => {
        if (curPanelId != null) {
          // console.log("updating",newConfig )
          setSubConfigs(curSubConfigs => ({
            ...curSubConfigs,
            [curPanelId! + '-' + pathString]: {
              ...curSubConfigs[curPanelId! + '-' + pathString],
              ...newConfig,
            },
          }));
        }
      },
      [curPanelId, pathString]
    );
    const panelUpdateContext = useCallback(
      (newContext: any) => {
        if (newContext.path != null) {
          updateConfig({path: newContext.path});
        }
        updateContext(newContext);
      },
      [updateConfig, updateContext]
    );

    if (fileWithTypeRaw.loading) {
      return <WandbLoader />;
    }

    return (
      <ThemeProvider theme={QueryEditorStyles.themes.light}>
        <div
          className="file-browser artifact-file-browser"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
          }}>
          <div
            className="file-browser-path"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
              position: 'sticky',
              left: '0px',
              zIndex: 1001,
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: 24,
                  opacity: 0.7,
                }}>
                &gt;&nbsp;
                {['root'].concat(configPath).map((folderName, i) => {
                  const newPath = configPath.slice(0, i);
                  return [
                    <span
                      className="file-browser-path-item"
                      style={{cursor: 'pointer'}}
                      key={'path' + i}
                      onClick={e => updateConfig({path: newPath})}>
                      {folderName}
                    </span>,
                    i !== configPath.length ? ' / ' : undefined,
                  ];
                })}
              </div>

              {stackIds.length > 1 && (
                <Dropdown
                  selection
                  className="clone-report-modal__select"
                  style={{marginLeft: 24, zIndex: 1001}}
                  options={stackIds.map(si => ({
                    key: si.id,
                    text: si.displayName,
                    value: si.id,
                  }))}
                  value={curPanelId}
                  onChange={(e, {value}) => setCurPanelId(value as string)}
                />
              )}
            </div>
            {showExportToReportButton &&
              curPanelId != null &&
              window.location.pathname.indexOf('reports') === -1 && (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div />
                  <AddToReportModal
                    trigger={<Button size="tiny">Add to report</Button>}
                    reportBlock={{
                      type: 'weave-panel',
                      config: {
                        panelConfig: {
                          panelConfig,
                          exp: fileWithType.result,
                          panelId: curPanelId,
                        },
                        height: 800,
                      },
                      children: [{text: ''}],
                    }}
                  />
                </div>
              )}
          </div>
          <div
            className="file-browser-path"
            style={{
              marginBottom: 16,
              // position: 'sticky',
              // left: '0px',
              // zIndex: 1001,
            }}></div>
          {handler != null && (
            <PanelComp2
              input={finalInput as any}
              inputType={panelInputType}
              loading={false}
              panelSpec={handler}
              configMode={true}
              context={newPanelContext}
              config={panelConfig}
              updateConfig={panelUpdateConfig}
              updateContext={panelUpdateContext}
            />
          )}
          <div style={{flexGrow: 1, overflowX: 'hidden', overflowY: 'auto'}}>
            {handler != null ? (
              <>
                <PanelComp2
                  input={finalInput as any}
                  inputType={panelInputType}
                  loading={false}
                  panelSpec={handler}
                  configMode={false}
                  context={newPanelContext}
                  config={panelConfig}
                  updateConfig={panelUpdateConfig}
                  updateContext={panelUpdateContext}
                />
              </>
            ) : (
              <div>no preview</div>
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  },
  {id: 'FilesPanel'}
);
