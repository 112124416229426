import React from 'react';
import Loader from '../WandbLoader';
import {useGatedValue} from '../../state/hooks';

export const LoadingGate: React.FC<{
  loading: boolean;
  makeChildren: () => React.ReactNode;
}> = props => {
  return useGatedValue(
    <>{props.loading ? <Loader /> : props.makeChildren()}</>,
    x => !props.loading
  );
};
