import React from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';

const inputType = 'boolean' as const;
type PanelBooleanProps = Panel2.PanelProps<typeof inputType>;

const PanelBoolean: React.FC<PanelBooleanProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  return <div>{nodeValueQuery.result ? 'true' : 'false'}</div>;
};

export const Spec: Panel2.PanelSpec = {
  id: 'boolean',
  Component: PanelBoolean,
  inputType,
};
