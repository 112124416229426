import React from 'react';

import {makePropsAreEqual} from '../../util/shouldUpdate';
import {WBTableColumn, WBTableHoverCellCoords} from './WBTable';
import {
  WBTableCellColumnHeader,
  WBTableCellColumnHeaderProps,
} from './WBTableCellColumnHeader';
import {WBTableSortIndicatorComponent} from './WBTableSortIndicator';
import makeComp from '../../util/profiler';

interface WBTableColumnHeaderProps {
  displayedRows: any[]; // table rows that are displayed in the current page
  columns: WBTableColumn[];
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  SortIndicatorComponent?: WBTableSortIndicatorComponent;
  hoverCellCoords: WBTableHoverCellCoords;
  expanded?: boolean;
  childProps(
    column: WBTableColumn,
    columnIndex: number,
    readOnly?: boolean
  ): WBTableCellColumnHeaderProps;
}

function hoverColumn(coords: WBTableHoverCellCoords) {
  if (!coords) {
    return undefined;
  }
  return coords[1];
}

// This component exists solely to prevent calling `shouldComponentUpdate` on
// every individual column header when there are many.
export const WBTableColumnHeaders: React.FC<WBTableColumnHeaderProps> =
  makeComp(
    ({columns, childProps, displayedRows, SortIndicatorComponent}) => {
      return (
        <React.Fragment>
          <div className="wb-tree-padding" />
          {columns.map((column, columnIndex) => {
            const props = childProps(column, columnIndex);
            return (
              <WBTableCellColumnHeader
                {...props}
                displayedRows={displayedRows}
                key={columnIndex}
                SortIndicatorComponent={SortIndicatorComponent}
              />
            );
          })}
          <div className="wb-tree-padding" />
        </React.Fragment>
      );
    },
    {
      id: 'WBTableColumnHeaders',
      memo: (prevProps, nextProps) => {
        if (
          hoverColumn(prevProps.hoverCellCoords) !==
          hoverColumn(nextProps.hoverCellCoords)
        ) {
          return false;
        }
        const propsAreEqual = makePropsAreEqual({
          name: 'WBTableColumnHeaders',
          deep: [
            'displayedRows',
            'columns',
            'groupTempSelection',
            'tempSelectedCount',
          ],
          // handled by shouldComponentUpdate
          ignore: ['hoverCellCoords'],
          ignoreFunctions: true,
          debug: false,
          verbose: true,
        });
        return propsAreEqual(prevProps, nextProps);
      },
    }
  );
