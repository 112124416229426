import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {TargetBlank} from '../util/links';

const TIPS_SIDEBAR_WIDTH = 480;
export const NAVBAR_HEIGHT = 52;
const EDIT_BAR_STICKY_HEIGHT = 64;
const TOTAL_BAR_HEIGHT = NAVBAR_HEIGHT + EDIT_BAR_STICKY_HEIGHT;
const SIDEBAR_NAV_HEIGHT = 140;

export const Sidebar = styled.div<{
  scrollPos?: number;
  heightHax?: boolean;
  open?: boolean;
}>`
  flex-shrink: 0;
  position: sticky;
  top: ${EDIT_BAR_STICKY_HEIGHT}px;
  width: ${TIPS_SIDEBAR_WIDTH}px;
  background: ${globals.gray100};
  padding: 32px 24px;
  ${props => css`
    height: calc(
      100vh -
        ${props.heightHax
          ? NAVBAR_HEIGHT
          : TOTAL_BAR_HEIGHT - (props.scrollPos ?? 0)}px
    );
    transform: translate(${props.open ? 0 : -1 * TIPS_SIDEBAR_WIDTH}px);
    margin-right: ${props.open ? '0' : -1 * TIPS_SIDEBAR_WIDTH}px;
    transition: transform 0.2s ${props.open ? 'ease-out' : 'ease-in'},
      margin-right 0.2s ${props.open ? 'ease-out' : 'ease-in'};
  `}
`;

export const SidebarPage = styled.div<{active?: boolean}>`
  ${props => !props.active && 'display: none;'}
  height: calc(100% + 64px - ${SIDEBAR_NAV_HEIGHT}px);
  overflow-y: scroll;
  padding-bottom: 32px;

  // hide scrollbars
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const SidebarSectionHeader = styled.div<{
  alignItems?: string;
  main?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  ${props =>
    css`
      ${props.alignItems && `align-items: ${props.alignItems};`}
      ${!props.main && 'margin-top: 24px;'}
    `}

  p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;

export const SidebarSectionSubheader = styled.div`
  color: ${globals.gray600};
  margin-top: -4px;
  margin-bottom: 20px;
`;

export const SidebarCollapseButton = styled.div`
  position: relative;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: ${globals.gray600};
  border-radius: 20px;
  cursor: pointer;

  i {
    font-size: 24px;
  }

  &:hover {
    background: ${globals.gray300};
  }
`;

export const SidebarTabs = styled.div`
  display: flex;
  width: 432px;
  margin-top: 16px;
  border-bottom: 2px solid ${globals.gray400};
`;

export const SidebarTab = styled.div<{active?: boolean}>`
  position: relative;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  color: ${globals.gray800};
  ${props =>
    props.active &&
    css`
      font-weight: 600;
      border-bottom: 2px solid ${globals.gray800};
    `}
`;

export const SidebarExamples = styled.div`
  margin-bottom: 32px;
`;

export const SidebarExample = styled.div`
  display: flex;
  height: 104px;
  margin-bottom: 16px;
  color: ${globals.gray800};

  div {
    flex-grow: 1;
    flex-direction: column;
    padding: 18px 22px 18px 24px;
    line-height: 22px;

    div {
      padding: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }
`;

export const SidebarReportTile = styled(TargetBlank)`
  display: flex;
  height: 120px;
  margin: 6px 0px;
  background: white;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.005);
    box-shadow: 1px 1px 14px rgb(0 0 0 / 8%);
  }

  div {
    padding: 24px 16px;
    width: 312px;

    p {
      color: ${globals.gray600};
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;

      &:first-child {
        color: ${globals.gray800};
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      &:last-child {
        color: ${globals.gray500};
        margin-top: 12px;
      }
    }
  }

  img {
    width: 104px;
    height: 104px;
    border-radius: 3px;
    margin: 8px;
  }
`;

export const SidebarCollapsibleSection = styled.div<{expanded?: boolean}>`
  margin: 32px 0;

  & > * {
    padding-left: 28px;
    ${props => !props.expanded && 'display: none;'}
  }

  div {
    &:first-child {
      padding: 0;
      margin-bottom: 6px;
      display: flex;
      font-weight: 600;
      color: ${globals.gray800};

      i {
        width: 24px;
        cursor: pointer;
        font-size: 23px;
        margin-right: 4px;
      }
    }

    &:nth-child(2) {
      margin-bottom: 16px;
      line-height: 20px;
      font-weight: 400;
      color: ${globals.gray600};
    }
  }

  img {
    max-width: 344px;
  }
`;
