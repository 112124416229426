import React, {useCallback} from 'react';
import {
  useCreateStorageSubscriptionMutation,
  useUpgradeCustomerSubscriptionMutation,
} from '../generated/graphql';
import docUrl from '../util/doc_urls';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import history from '../util/history';
import {captureError} from '../util/integrations';
import {TargetBlank} from '../util/links';
import {CheckoutPlan, Org} from '../util/pricing';
import makeComp from '../util/profiler';
import StripeForm, {SubmitParams} from './StripeForm';
import * as S from './UpgradeSubscriptionForm.styles';

type UpgradeSubscriptionFormProps = {
  plan: CheckoutPlan;
  seats: number;
  storagePlanID: string;
  trackingPlanID: string;
  org: Org;
  onSubscriptionCompleted: () => void;
};

const UpgradeSubscriptionForm: React.FC<UpgradeSubscriptionFormProps> =
  makeComp(
    props => {
      const {
        plan,
        seats,
        storagePlanID,
        trackingPlanID,
        org,
        onSubscriptionCompleted,
      } = props;

      const [upgradeCustomerSubscriptionMutation] =
        useUpgradeCustomerSubscriptionMutation({
          context: {...propagateErrorsContext(), ...doNotRetryContext()},
        });

      const [createStorageSubscriptionMutation] =
        useCreateStorageSubscriptionMutation({
          context: {...propagateErrorsContext(), ...doNotRetryContext()},
        });

      const upgradeSubscription = useCallback(
        async ({
          stripe,
          custoEmail,
          paymentMethodID,
          setErrMsg,
        }: SubmitParams): Promise<boolean> => {
          if (seats < org.memberCount) {
            setErrMsg(
              `You have ${org.memberCount} people on your team, so you need at least ${org.memberCount} seats.`
            );
            return false;
          }

          const subResult = await upgrade();
          if (subResult == null) {
            return false;
          }

          const {organizationID, subscription} = subResult;
          const paymentIntent = subscription.latest_invoice.payment_intent;
          const clientSecret = paymentIntent?.client_secret;
          const paymentIntentStatus = paymentIntent?.status;
          const needsPayment =
            ['requires_action', 'requires_payment_method'].indexOf(
              paymentIntentStatus
            ) !== -1;

          if (needsPayment) {
            const paymentConfirmed = await confirmPayment(clientSecret);
            if (!paymentConfirmed) {
              return false;
            }
          }

          const storageSubscription = await subscribeStorage(organizationID);
          if (storageSubscription == null) {
            return false;
          }

          const paymentIntentStorage =
            storageSubscription.latest_invoice.payment_intent;
          const clientSecretStorage = paymentIntentStorage?.client_secret;
          const paymentIntentStatusStorage = paymentIntentStorage?.status;
          const needsPaymentStorage =
            ['requires_action', 'requires_payment_method'].indexOf(
              paymentIntentStatusStorage
            ) !== -1;

          if (needsPaymentStorage) {
            const paymentConfirmedStorage = await confirmPaymentStorage(
              clientSecretStorage
            );
            if (!paymentConfirmedStorage) {
              return false;
            }
          }

          window.analytics.track('upgrade subscription completed', {
            org: org.id,
            plan: plan.id,
            units: seats,
          });
          onSubscriptionCompleted();
          return true;

          // HELPER FUNCTIONS

          async function upgrade(): Promise<{
            organizationID: string;
            subscription: {
              latest_invoice: any;
            };
          } | null> {
            if (paymentMethodID == null) {
              throw new Error(`missing paymentMethodID`);
            }

            try {
              const upgradeSubResult =
                await upgradeCustomerSubscriptionMutation({
                  variables: {
                    organizationID: org.id,
                    planID: plan.id,
                    paymentMethod: paymentMethodID,
                    quantity: seats,
                  },
                });

              const sub =
                upgradeSubResult.data?.upgradeCustomerSubscription
                  ?.subscription;
              const orgID =
                upgradeSubResult.data?.upgradeCustomerSubscription
                  ?.organizationID;

              if (
                upgradeSubResult.errors != null ||
                sub == null ||
                orgID == null
              ) {
                onError({
                  err: "Unknown error upgrading subscription. Exception didn't throw but success was not reported.",
                  op: 'handleUpgrade-unknown',
                  userFacingErrorMsg:
                    'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
                });
                return null;
              }

              return {organizationID: orgID, subscription: sub};
            } catch (err) {
              const errMsg =
                extractErrorMessageFromApolloError(err) ?? err.message;
              onError({
                err,
                op: 'upgradeSubResult',
                extra: {
                  reason: errMsg,
                },
                analyticsEvent: 'UpgradeSubscriptionForm-upgradeSubResult',
                userFacingErrorMsg: `Error processing payment: ${errMsg}`,
              });
              return null;
            }
          }

          async function subscribeStorage(orgID: string): Promise<{
            latest_invoice: any;
          } | null> {
            try {
              const createStorageSubResult =
                await createStorageSubscriptionMutation({
                  variables: {
                    storagePlanId: storagePlanID,
                    trackingPlanId: trackingPlanID,
                    paymentMethod: paymentMethodID,
                    organizationId: orgID,
                    trial: false,
                  },
                });

              const storageSub =
                createStorageSubResult.data?.createStorageSubscription
                  ?.subscription;

              if (createStorageSubResult.errors != null || storageSub == null) {
                onError({
                  err: "Unknown error confirming subscription. Exception didn't throw but success was not reported.",
                  op: 'handleStorageSubscribe-unknown',
                  userFacingErrorMsg:
                    'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
                });
                return null;
              }

              return storageSub;
            } catch (err) {
              const errMsg =
                extractErrorMessageFromApolloError(err) ?? err.message;
              onError({
                err,
                op: 'createStorageSubResult',
                extra: {
                  reason: errMsg,
                },
                analyticsEvent:
                  'UpgradeSubscriptionForm-createStorageSubResult',
                userFacingErrorMsg: `Error processing storage subscription payment: ${errMsg}`,
              });
              return null;
            }
          }

          async function confirmPayment(secret: string): Promise<boolean> {
            try {
              const result = await stripe.confirmCardPayment(secret);

              if (result.error != null) {
                onError({
                  err: result.error.message!,
                  op: 'handleCardPayment',
                  userFacingErrorMsg: `We upgraded your subscription, but had trouble confirming your payment: ${result.error.message}`,
                });
                return false;
              }

              return true;
            } catch (err) {
              onError({
                err,
                op: 'handleCardPayment',
                userFacingErrorMsg: `We upgraded your subscription, but had trouble confirming your payment: ${err}`,
              });
              return false;
            }
          }

          async function confirmPaymentStorage(
            secret: string
          ): Promise<boolean> {
            try {
              const result = await stripe.confirmCardPayment(secret);

              if (result.error != null) {
                onError({
                  err: result.error.message!,
                  op: 'handleCardPaymentStorage',
                  userFacingErrorMsg: `We had trouble confirming your payment: ${result.error.message}`,
                });
                return false;
              }

              return true;
            } catch (err) {
              onError({
                err,
                op: 'handleCardPaymentStorage',
                userFacingErrorMsg: `We had trouble confirming your payment: ${err}`,
              });
              return false;
            }
          }

          type ErrOpts = {
            err: Error | string;
            op: string;
            userFacingErrorMsg: string;
            analyticsEvent?: string;
            extra?: {[key: string]: any};
          };
          function onError(opts: ErrOpts) {
            const extra = {
              email: custoEmail,
              storageID: storagePlanID,
              trackingID: trackingPlanID,
              org: org.id,
              plan: plan.id,
              units: seats,
              ...opts.extra,
            };
            captureError(opts.err, `UpgradeSubscriptionForm-${opts.op}`, {
              extra,
            });
            if (opts.analyticsEvent != null) {
              window.analytics.track(opts.analyticsEvent, extra);
            }
            setErrMsg(opts.userFacingErrorMsg);
          }
        },
        [
          storagePlanID,
          trackingPlanID,
          org,
          plan.id,
          seats,
          onSubscriptionCompleted,
          upgradeCustomerSubscriptionMutation,
          createStorageSubscriptionMutation,
        ]
      );

      return (
        <StripeForm
          orgName={org.name}
          renderButtons={({submitWrapper, formDisabled, submitting}) => (
            <S.ConfirmButton
              primary
              onClick={() => {
                window.analytics.track(
                  'confirm upgrade subscription button clicked',
                  {
                    org: org.id,
                    plan: plan.id,
                    units: seats,
                  }
                );
                submitWrapper(upgradeSubscription);
              }}
              disabled={formDisabled}>
              {submitting ? 'Processing...' : 'Subscribe now'}
            </S.ConfirmButton>
          )}
        />
      );
    },
    {id: 'UpgradeSubscriptionForm', memo: true}
  );

export default UpgradeSubscriptionForm;

type UpgradeSuccessProps = {org: Org};

export const UpgradeSuccess: React.FC<UpgradeSuccessProps> = makeComp(
  ({org}) => {
    const goToTeam = useCallback(() => {
      if (org.teams == null || org.teams.length === 0) {
        return;
      }
      history.push(`/${org.teams[0].name}`);
    }, [org]);

    return (
      <S.SuccessContainer>
        <S.SuccessHeader>Team Successfully Upgraded</S.SuccessHeader>
        <S.SuccessContent>
          <TargetBlank href={docUrl.teams}>Learn more about teams</TargetBlank>,
          or reach out to support@wandb.com for help.
        </S.SuccessContent>
        <S.ConfirmButton primary onClick={goToTeam}>
          Open Team Page →
        </S.ConfirmButton>
      </S.SuccessContainer>
    );
  },
  {id: 'UpgradeSuccess', memo: true}
);
