/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'rundata-scatter-with-size',
  name: 'Scatter plot with size (run data)',
  description:
    'A Vega-lite viz scatter plot of run data. You can choose x-axis, y-axis, size and color fields.',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega-lite/v3.json',
    data: {
      name: 'runs',
    },
    title: 'Run data scatter plot',
    mark: 'point',
    encoding: {
      x: {
        field: '${run-field:x-axis}',
        type: 'quantitative',
      },
      y: {
        field: '${run-field:y-axis}',
        type: 'quantitative',
      },
      size: {
        field: '${run-field:size}',
        type: 'quantitative',
      },
      color: {
        field: '${run-field:color}',
        type: 'quantitative',
      },
    },
  },
};

export default def;
