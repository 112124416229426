import * as globals from '../../css/globals.styles';

import styled from 'styled-components';
import {WBIcon} from '@wandb/ui';

export const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
`;

export const ColumnName = styled.div`
  cursor: pointer;
  padding: 0 4px;
  margin-left: -4px;
  :hover {
    color: ${globals.primary};
    background-color: #eee;
    border-radius: 2px;
  }
`;

export const FilterIcon = styled(WBIcon)`
  cursor: pointer;
  color: ${globals.primary};
  :hover {
    background-color: #eee;
    border-radius: 2px;
  }
`;

export const EllipsisIcon = styled(WBIcon)`
  cursor: pointer;
  visibility: hidden;

  ${ColumnHeader}:hover & {
    visibility: visible;
  }
  padding: 2px 0;
  :hover {
    color: ${globals.primary};
    background-color: #eee;
    border-radius: 2px;
  }
`;

export const SortedIcon = styled(WBIcon)`
  cursor: pointer;
  color: ${globals.primary};
  :hover {
    background-color: #eee;
    border-radius: 2px;
  }
`;

export const SortIcon = styled(WBIcon)`
  cursor: pointer;
  color: #afafaf;
  :hover {
    background-color: #eee;
    border-radius: 2px;
  }
`;

export const ColumnEditorSection = styled.div`
  margin-bottom: 24px;
`;

export const ColumnEditorSectionLabel = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ColumnEditorColumnName = styled.div`
  display: flex;
  align-items: center;
  color: ${globals.gray500};
`;

export const ColumnEditorFieldLabel = styled.div`
  margin-right: 8px;
`;

export const AssignmentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelNameEditor = styled.div`
  margin-bottom: 12px;
`;

export const PanelSettings = styled.div`
  padding: 8px 24px;
  background-color: #f6f6f6;
  border-radius: 4px;
  min-width: 600px;
  :empty {
    padding: 0;
  }
`;

export const CellWrapper = styled.div`
  scrollbar-width: thin;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
`;
