import gql from 'graphql-tag';

import * as Views from './views';

export const fragments = {
  basicRun: gql`
    fragment BasicRunFragment on Run {
      readOnly
      framework
      displayName
      notes
      github
      group
      jobType
      createdAt
      heartbeatAt
      commit
      host
      state
      shouldStop
      groupCounts
      stopped
      defaultColorIndex
      sweep {
        name
        displayName
      }
      agent {
        name
      }
      user {
        username
        photoUrl
      }
      tags: tagColors {
        name
        colorIndex
      }
      benchmarkRun {
        id
      }
      runInfo {
        gpuCount
        gpu
      }
    }
  `,
  detailedRun: gql`
    fragment DetailedRunFragment on Run {
      history
      events
      fileCount
      logLines(after: $logCursor, last: 1000) {
        edges {
          node {
            id
            line
            level
          }
        }
      }
    }
  `,
  runFiles: gql`
    fragment RunFilesFragment on Run {
      files(after: $fileCursor, first: $fileLimit, names: $fileNames) {
        edges {
          node {
            id
            name
            directUrl
            url(upload: $upload)
            sizeBytes
            updatedAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  selectRun: gql`
    fragment SelectRunFragment on Run {
      config
      summaryMetrics(packVersion: 1)
      systemMetrics
    }
  `,
  historyRun: gql`
    fragment HistoryRunFragment on Run {
      history(samples: $historySamples)
    }
  `,
  sampledHistoryRun: gql`
    fragment SampledHistoryRunFragment on Run {
      sampledHistory(specs: $sampledHistorySpecs, packVersion: 1)
    }
  `,
};

// Some weirdness here. If a fragment is disabled by a skip/include directive,
// quiver/graphene will remove the fields in the fragment. Order matters, whatever
// the last fragment to be included/skipped says goes. We must include __typename
// above fragments or it ends up getting removed.
export const RUNS_QUERY = gql`
  query RunsModel(
    $cursor: String
    $name: String!
    $entityName: String
    $jobKey: String
    $order: String
    $filters: JSONString
    $limit: Int = 1000
    $bucketIds: [String]
    $requestSubscribe: Boolean!
    $selectEnable: Boolean = true
    $basicEnable: Boolean = true
    $history: Boolean = false
    $historyKeyInfo: Boolean = false
    $historySamples: Int = 750
    $sampledHistory: Boolean = false
    $fields: [String]
    $historyFields: [String]
    $sampledHistorySpecs: [JSONString!]!
    $groupKeys: [String]
    $groupLevel: Int
  ) {
    project(name: $name, entityName: $entityName) {
      id
      name
      createdAt
      entityName
      description
      summaryMetrics
      views
      requestSubscribe @include(if: $requestSubscribe)
      runCount(filters: $filters)
      runs(
        first: $limit
        after: $cursor
        jobKey: $jobKey
        order: $order
        names: $bucketIds
        filters: $filters
        fields: $fields
        historyFields: $historyFields
        groupKeys: $groupKeys
        groupLevel: $groupLevel
      ) {
        totalCount
        edges {
          node {
            id
            name
            displayName
            __typename
            ...SelectRunFragment @include(if: $selectEnable)
            ...BasicRunFragment @include(if: $basicEnable)
            ...HistoryRunFragment @include(if: $history)
            ...SampledHistoryRunFragment @include(if: $sampledHistory)
            historyKeys @include(if: $historyKeyInfo)
          }
        }
        pageInfo {
          startCursor
          hasPreviousPage
          endCursor
          hasNextPage
        }
      }
    }
    viewer {
      id
      email
      photoUrl
      admin
    }
    serverInfo {
      pollingOK
    }
  }
  ${fragments.basicRun}
  ${fragments.selectRun}
  ${fragments.historyRun}
  ${fragments.sampledHistoryRun}
`;

export const PROJECT_QUERY = gql`
  query RunsProject(
    $name: String!
    $entityName: String
    $baseFilters: JSONString = "{}"
    $filters: JSONString
    $selections: JSONString
  ) {
    project(name: $name, entityName: $entityName) {
      id
      access
      readOnly
      name
      description
      createdAt
      entityName
      views
      requestSubscribe
      totalRunsWithGroup
      totalRunsWithJobType
      runCount(filters: $baseFilters)
      filteredCount: runCount(filters: $filters)
      selectedCount: runCount(filters: $selections)
      projectSettings: allViews(
        viewType: "project"
        viewName: "settings"
        first: 1
      ) {
        edges {
          node {
            ...ViewFragment
          }
        }
      }
    }
  }
  ${Views.fragments.view}
`;

export const RUN_UPSERT = gql`
  mutation upsertRun(
    $id: String
    $displayName: String
    $notes: String
    $tags: [String!]
  ) {
    upsertBucket(
      input: {id: $id, displayName: $displayName, notes: $notes, tags: $tags}
    ) {
      bucket {
        id
        name
        displayName
        notes
        tags: tagColors {
          name
          colorIndex
        }
        project {
          id
          tags {
            name
            colorIndex
          }
        }
      }
      inserted
    }
  }
`;

export const RUN_GROUP_UPSERT = gql`
  mutation upsertRunGroup(
    $entityName: String!
    $projectName: String!
    $name: String!
    $newName: String
    $notes: String
    $tags: [String!]
  ) {
    upsertRunGroup(
      input: {
        projectName: $projectName
        entityName: $entityName
        name: $name
        notes: $notes
        newName: $newName
        tags: $tags
      }
    ) {
      group {
        id
        name
        notes
        tags {
          name
          colorIndex
        }
        project {
          id
          tags {
            name
            colorIndex
          }
        }
      }
    }
  }
`;

export const MODIFY_RUNS = gql`
  mutation modifyRuns(
    $filters: JSONString
    $entityName: String
    $projectName: String
    $addTags: [String]
    $removeTags: [String]
  ) {
    modifyRuns(
      input: {
        filters: $filters
        entityName: $entityName
        projectName: $projectName
        addTags: $addTags
        removeTags: $removeTags
      }
    ) {
      runsSQL {
        id
        name
        __typename
        ...SelectRunFragment
        ...BasicRunFragment
        user {
          id
          username
          photoUrl
        }
      }
    }
  }
  ${fragments.selectRun}
  ${fragments.basicRun}
`;

export const MOVE_RUNS = gql`
  mutation moveRuns(
    $filters: JSONString!
    $sourceEntityName: String!
    $sourceProjectName: String!
    $destinationEntityName: String!
    $destinationProjectName: String!
  ) {
    moveRuns(
      input: {
        filters: $filters
        sourceEntityName: $sourceEntityName
        sourceProjectName: $sourceProjectName
        destinationEntityName: $destinationEntityName
        destinationProjectName: $destinationProjectName
      }
    ) {
      task {
        id
      }
    }
  }
`;

export const DELETE_RUNS = gql`
  mutation deleteRuns(
    $filters: JSONString!
    $entityName: String!
    $projectName: String!
    $deleteArtifacts: Boolean!
  ) {
    deleteRuns(
      input: {
        filters: $filters
        entityName: $entityName
        projectName: $projectName
        deleteArtifacts: $deleteArtifacts
      }
    ) {
      clientMutationId
    }
  }
`;

export const UNDELETE_RUNS = gql`
  mutation undeleteRuns(
    $filters: JSONString!
    $entityName: String!
    $projectName: String!
  ) {
    undeleteRuns(
      input: {
        filters: $filters
        entityName: $entityName
        projectName: $projectName
      }
    ) {
      clientMutationId
    }
  }
`;

export const DELETE_RUN = gql`
  mutation deleteRun($id: ID!, $deleteArtifacts: Boolean = false) {
    deleteRun(input: {id: $id, deleteArtifacts: $deleteArtifacts}) {
      clientMutationId
    }
  }
`;

export const STOP_RUN = gql`
  mutation stopRun($id: ID!) {
    stopRun(input: {id: $id}) {
      clientMutationId
    }
  }
`;

export interface StopRunMutationData {
  clientMutationId: string;
}

export interface StopRunMutationVariables {
  id: string;
}

export const RUN_STOP = gql`
  mutation runStop($id: ID!) {
    stopRun(input: {id: $id}) {
      success
    }
  }
`;

export const DELETE_FILES = gql`
  mutation deleteFiles($ids: [ID!]!) {
    deleteFiles(input: {files: $ids}) {
      clientMutationId
      success
    }
  }
`;
