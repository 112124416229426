import React from 'react';

import {Sort} from '../../../state/views/sort/types';
import makeComp from '../../../util/profiler';
import {DFTableSortIndicator} from './DFTableSortIndicator';

export function makeDataFrameSortIndicator(outerProps: {sort: Sort}) {
  const SortIndicator: React.FC<{
    columnKeyString: string;
  }> = makeComp(
    props => {
      return (
        <DFTableSortIndicator
          sort={outerProps.sort}
          columnKeyString={props.columnKeyString}
        />
      );
    },
    {id: 'DataFrameSortIndicator'}
  );
  return SortIndicator;
}
