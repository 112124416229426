import * as React from 'react';
import {Button, Card} from 'semantic-ui-react';
import emptyImg from '../assets/il-no-projects.png';
import emptyImg2x from '../assets/il-no-projects@2x.png';
import ImageIconHeader from '../components/ImageIconHeader';
import ProjectCard from '../components/ProjectCard';
import '../css/ExpandableProjectList.less';
import {Project} from '../graphql/allProjectsQuery';
import {Graph} from '../types/graphql';
import {isFeatureFlagEnabled} from '../util/featureFlags';
import {LinkNoCrawl} from '../util/links';
import makeComp from '../util/profiler';
import {allProjects, newBenchmarkPage, newProjectPage} from '../util/urls';
import EmptyWatermark from './EmptyWatermark';

interface ExpandableProjectListProps {
  projects: Graph<Project>;
  entityName: string;
  readOnly?: boolean;
}

const ExpandableProjectList: React.FC<ExpandableProjectListProps> = makeComp(
  ({projects, entityName, readOnly}) => {
    const enableCreateBenchmark = isFeatureFlagEnabled({
      featureKey: entityName,
      featureName: 'create-benchmark',
    });
    return (
      <div className="expandable-project-list-wrapper">
        <div className="header-wrapper">
          <ImageIconHeader icon="project" text="Projects" />
          {!readOnly && (
            <div>
              {enableCreateBenchmark && (
                <LinkNoCrawl to={newBenchmarkPage(entityName)}>
                  <Button size="tiny" style={{marginRight: 12}}>
                    Create new benchmark
                  </Button>
                </LinkNoCrawl>
              )}
              <LinkNoCrawl to={newProjectPage(entityName)}>
                <Button primary size="tiny">
                  Create new project
                </Button>
              </LinkNoCrawl>
            </div>
          )}
        </div>
        {projects.edges.length > 0 ? (
          <div className="expandable-project-list">
            <Card.Group>
              {projects.edges.slice(0, 6).map(p => (
                <ProjectCard
                  key={p.node.id}
                  project={p.node}
                  readOnly={readOnly}
                />
              ))}
            </Card.Group>
            <LinkNoCrawl className="see-all-link" to={allProjects(entityName)}>
              See all ➞
            </LinkNoCrawl>
          </div>
        ) : (
          <EmptyWatermark
            imageSource={emptyImg}
            imageSource2x={emptyImg2x}
            header="No projects yet."
            details={
              readOnly
                ? "This user or team hasn't created any projects yet."
                : 'Create a project to start tracking your experiments.'
            }
            wide
          />
        )}
      </div>
    );
  },
  {id: 'ExpandableProjectList'}
);
export default ExpandableProjectList;
