import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {WBIcon} from '@wandb/ui';
import {
  gray800,
  deepBlue,
  gray700,
  gray50,
  error,
  gray400,
  white,
} from '../css/globals.styles';

import {Dropdown, StrictDropdownProps} from 'semantic-ui-react';
import CopyableText from '../components/CopyableText';

export const Background = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 330px;
`;

export const Header = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: ${gray800};

  margin-top: 28px;
`;

export const Subheader = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${gray700};

  margin-top: 16px;
`;

export const ErrorMessage = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${error};

  margin-top: -12px;
`;

export const BigButtonContainer = styled.div`
  display: flex;
  flex-direction: horizontal;
  margin-top: 28px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
`;

export const BigButton = styled.div`
  background: ${gray50};
  border: 1px solid ${gray400};
  box-sizing: border-box;
  border-radius: 6px;

  cursor: pointer;
  height: 154px;
  width: 200px;

  dislay: flex;
  flex-direction: vertical;
  padding: 24px 18px;
  transition: background 0.2s ease;
  &:hover,
  &:focus {
    background: ${gray400};
  }
`;

export const BigButtonHeader = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`;

export const BigButtonSubheader = styled.div`
  margin-top: 10px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const InputContainer = styled.div`
  margin-top: 30px;
  width: 300px;
`;

export const InputLabel = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${gray700};

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const InputText = styled.input`
  border: 1px solid ${gray400};
  box-sizing: border-box;
  border-radius: 4px;

  height: 40px;
  width: 100%;
  margin-top: 8px;
  padding: 8px 32px 8px 12px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`;

export const MultilineInputText = styled.textarea`
  border: 1px solid ${gray400};
  box-sizing: border-box;
  border-radius: 4px;

  width: 100%;
  height: 180px;
  margin-top: 8px;
  padding: 8px 32px 8px 12px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  resize: none;
`;

export const EntityInputText = styled(InputText)`
  padding: 8px 32px 10px 82px;
`;

export const EntityInputIcon = styled(WBIcon)`
  color: ${error};
  position: relative;
  left: 274px;
  top: -30px;
`;

export const InputDropdown = styled<React.ComponentClass<StrictDropdownProps>>(
  Dropdown
)`
  &.ui.selection.dropdown {
    background: ${white};
    border: 1px solid ${gray400};
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: unset;
  }

  margin-top: 8px;
  width: 100%;
`;

export const EntityPrefix = styled.div`
  position: relative;
  margin-bottom: -30px;
  min-height: 78px;

  ::after {
    position: absolute;
    left: 14px;
    top: 16px;
    content: 'wandb.ai/';
    pointer-events: none;
    opacity: 0.6;

    line-height: 22px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
`;

export const Button = styled.button`
  background: ${deepBlue};
  border-radius: 4px;
  border: unset;
  color: ${white};
  cursor: pointer;
  height: 40px;
  justify-content: center;
  margin: 32px 0px;
  width: 100%;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
`;

export const SmallButton = styled(Button)`
  width: 40%;
  margin-right: 12px;
`;

export const CopyableTextInput = styled(CopyableText)`
  height: 40px;
  width: 100%;
  margin: 8px 0px 0px 0px;
  white-space: nowrap;

  border: 1px solid ${gray400};
  box-sizing: border-box;
  border-radius: 4px;

  i.icon.copy-icon {
    opacity: 1;
  }
`;
