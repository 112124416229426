import '../css/ReportShowcase.less';

import React, {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Card} from 'semantic-ui-react';

import emptyImg from '../assets/il-no-reports.png';
import emptyImg2x from '../assets/il-no-reports@2x.png';
import ImageIconHeader from '../components/ImageIconHeader';
import EmptyWatermark from './EmptyWatermark';
import {ReportsTableReport} from './ReportsTable';
import {flashFocusCircle} from '../util/flash';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {useUpsertView2Mutation} from '../generated/graphql';
import {reportView, galleryDiscussionView, galleryPostView} from '../util/urls';
import {useWindowSize} from '../util/window';
import makeComp from '../util/profiler';
import EditableImage from './EditableImage';
import {useApolloClient} from '../state/hooks';
import * as ViewApi from '../state/views/api';
import {TargetBlank} from '../util/links';
import {
  DISCUSSION_CATEGORY_ID,
  getGalleryTagQS,
  ReportIDWithTagIDs,
  ReportMetadata,
  Tag,
  useGallerySpec,
  useReportMetadata,
} from '../util/gallery';

const NARROW_SCREEN_BREAKPOINT = 1200;

interface ReportShowcaseProps {
  reports: ReportsTableReport[];
  readOnly?: boolean;
  inProfilePage?: boolean;
}

const ReportShowcase: React.FC<ReportShowcaseProps> = makeComp(
  ({reports, readOnly, inProfilePage}) => {
    const {width: windowWidth} = useWindowSize();
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (ref.current == null || window.location.hash !== '#report-showcase') {
        return;
      }
      ref.current.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      });
    }, []);

    const [upsertView] = useUpsertView2Mutation();
    const unshowcaseReport = (r: ReportsTableReport) =>
      upsertView({variables: {id: r.id, showcasedAt: new Date(0)}});

    const client = useApolloClient();
    const savePreviewImage = useCallback(
      (r: ReportsTableReport, previewUrl: string) =>
        ViewApi.save(client, {
          id: r.id,
          previewUrl,
        }),
      [client]
    );

    // fetch gallery spec to handle projectless reports and FC tags
    const {loading: galleryLoading, gallerySpec} = useGallerySpec();

    const galleryReportByID: Map<string, ReportIDWithTagIDs> = useMemo(() => {
      const reportMap = new Map<string, ReportIDWithTagIDs>();
      if (!galleryLoading) {
        gallerySpec.reportIDsWithTagIDs.forEach(r => reportMap.set(r.id, r));
      }
      return reportMap;
    }, [galleryLoading, gallerySpec.reportIDsWithTagIDs]);

    const tagsAndCategories = useMemo(
      () =>
        galleryLoading ? [] : [...gallerySpec.tags, ...gallerySpec.categories],
      [galleryLoading, gallerySpec.tags, gallerySpec.categories]
    );

    const tagByID: Map<string, Tag> = useMemo(() => {
      const tagMap = new Map<string, Tag>();
      tagsAndCategories.forEach(t => tagMap.set(t.id, t));
      return tagMap;
    }, [tagsAndCategories]);

    const projectlessReportIDs = useMemo(
      () => reports.filter(r => r.project == null).map(r => r.id),
      [reports]
    );

    const {loading: reportsLoading, reportMetadatas} =
      useReportMetadata(projectlessReportIDs);

    const projectlessReportByID = useMemo(() => {
      const reportMap = new Map<string, ReportMetadata>();
      if (!reportsLoading) {
        (reportMetadatas ?? []).forEach(r => {
          reportMap.set(r.id, r);
        });
      }
      return reportMap;
    }, [reportsLoading, reportMetadatas]);

    return (
      <>
        {inProfilePage && (
          <div className="header-wrapper">
            <ImageIconHeader icon="gallery" text="Showcase" />
          </div>
        )}
        {reports.length > 0 ? (
          <div ref={ref} id="report-showcase" className="report-showcase">
            <Card.Group
              itemsPerRow={windowWidth <= NARROW_SCREEN_BREAKPOINT ? 1 : 2}>
              {reports.map(r => {
                // if we can't find a projectless report's FC metadata, omit it.
                // This should rarely, if ever, happen.
                const projectlessReportMetadata = projectlessReportByID.get(
                  r.id
                );
                if (r.project == null && projectlessReportMetadata == null) {
                  return null;
                }

                // generate different links based on whether report is projectless
                const link =
                  r.project != null
                    ? reportView({
                        entityName: r.project.entityName,
                        projectName: r.project.name,
                        reportID: r.id,
                        reportName: r.displayName,
                      })
                    : (projectlessReportMetadata?.tags?.some(
                        t => t.id === DISCUSSION_CATEGORY_ID
                      )
                        ? galleryDiscussionView
                        : galleryPostView)({
                        entityName: projectlessReportMetadata?.entityName ?? '',
                        reportID: r.id,
                        reportName: r.displayName,
                      });

                // if report is in FC, fetch the first tag for it and build query string
                const galleryReportMetadata = galleryReportByID.get(r.id);
                const galleryReportTag =
                  galleryReportMetadata != null &&
                  galleryReportMetadata.tagIDs.length > 0
                    ? galleryReportMetadata.tagIDs[0]
                    : '';
                const galleryTagMetadata = tagByID.get(galleryReportTag);
                const galleryQS =
                  galleryTagMetadata != null
                    ? getGalleryTagQS(
                        tagsAndCategories,
                        galleryTagMetadata.name
                      )
                    : '';

                return (
                  <TargetBlank
                    key={r.id}
                    className="ui card report-showcase-item"
                    href={link + galleryQS}>
                    <Card.Content className="report-showcase-item-content">
                      <EditableImage
                        className="report-showcase-item-image"
                        photoUrl={r.previewUrl ?? '/logo.png'}
                        displaySize={120}
                        label={false}
                        readOnly={readOnly}
                        viewID={r.id}
                        updateOverlay="Update Preview"
                        save={url => savePreviewImage(r, url)}
                      />
                      <div className="report-showcase-item-text">
                        <Card.Header className="report-showcase-item-title">
                          <MaxLines lines={2} text={r.displayName} />
                        </Card.Header>
                        {r.description.length > 0 && (
                          <Card.Description className="report-showcase-item-description">
                            <MaxLines lines={2} text={r.description} />
                          </Card.Description>
                        )}
                      </div>
                      {!readOnly && inProfilePage && (
                        <LegacyWBIcon
                          name="close"
                          className="report-showcase-item-close"
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            unshowcaseReport(r);
                          }}
                        />
                      )}
                    </Card.Content>
                  </TargetBlank>
                );
              })}
            </Card.Group>
          </div>
        ) : (
          <EmptyWatermark
            className="report-showcase-empty-watermark"
            imageSource={emptyImg}
            imageSource2x={emptyImg2x}
            header="Highlight your favorite reports."
            details={
              <>
                Select reports from public projects to feature here.
                <br />
                <span
                  className="add-report-to-showcase underline-dashed"
                  onClick={() => {
                    const el = document.querySelector(
                      '[data-test="showcase-report-button"]'
                    );
                    if (el != null) {
                      flashFocusCircle(el, {
                        minSpaceFromBottom: 200,
                        padding: 0,
                        offsetY: -3,
                        popping: true,
                      });
                    }
                  }}>
                  Add a report
                </span>
              </>
            }
            wide
          />
        )}
      </>
    );
  },
  {id: 'ReportShowcase'}
);

export default ReportShowcase;

const LINE_HEIGHT_BUFFER = 0.1;

interface MaxLinesProps {
  lines: number;
  text: string;
}

const MaxLines: React.FC<MaxLinesProps> = makeComp(
  ({lines, text}) => {
    const {width: windowWidth} = useWindowSize();
    const prevWindowWidthRef = useRef(windowWidth);
    const [ready, setReady] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    // update truncated value on prop updates
    useLayoutEffect(() => {
      truncate();
      // eslint-disable-next-line
    }, [lines, text]);

    // update truncated value on viewport resize
    useLayoutEffect(() => {
      const prevWindowWidth = prevWindowWidthRef.current;
      prevWindowWidthRef.current = windowWidth;
      // TODO(axel): more efficient truncation/growing starting from current truncated value
      if (windowWidth < prevWindowWidth) {
        truncate();
      } else if (windowWidth > prevWindowWidth) {
        truncate();
      }
      // eslint-disable-next-line
    }, [windowWidth]);

    const visibilityStyle = useMemo<CSSProperties>(
      () => ({visibility: ready ? 'visible' : 'hidden'}),
      [ready]
    );

    if (text == null) {
      return null;
    }

    return <div ref={ref} style={visibilityStyle} />;

    function truncate(): void {
      const el = ref.current;
      if (el == null) {
        return;
      }

      el.textContent = '-';
      const lineHeight = el.getBoundingClientRect().height;

      let t = text;

      el.textContent = t;
      while (
        el.getBoundingClientRect().height >
        lineHeight * lines + LINE_HEIGHT_BUFFER
      ) {
        if (t.slice(t.length - 3) === '...') {
          t = t.slice(0, t.length - 4);
        } else {
          t = t.slice(0, t.length - 1);
        }
        t += '...';
        el.textContent = t;
      }

      if (!ready) {
        setReady(true);
      }
    }
  },
  {id: 'MaxLines'}
);
