// Public actions, to be called by React components.
import {createAction} from 'typesafe-actions';
import {ID} from '@wandb/cg/browser/utils/string';

import * as Types from './types';
import * as ReportTypes from './report/types';

export const loadMetadataListStarted = createAction(
  '@view/loadMetadataListStarted',
  action => (params: Types.LoadMetadataListParams) =>
    action({
      id: ID(),
      params,
    })
);

export const rename = createAction(
  '@view/rename',
  action => (ref: Types.ViewRef, name: string) => action({ref, name})
);

export const setAutosave = createAction(
  '@view/setAutosave',
  action => (ref: Types.ViewRef, autosave: boolean) => action({ref, autosave})
);

export const undo = createAction('@view/undo', action => () => action());
export const redo = createAction('@view/redo', action => () => action());

export const noop = createAction('@view/noop', action => () => action({}));

export const copyObject = createAction(
  '@view/copyObject',
  // We name the target 'ref' so that it is autosaved when copied to
  action => (fromRef: Types.AllPartRefs, ref?: Types.AllPartRefs) => {
    if (ref != null && fromRef.type !== ref.type) {
      // We could usually enforce this with typescript but action types
      // don't play nicely with generics
      throw new Error('internal error');
    }
    if (ref == null) {
      ref = {
        type: fromRef.type,
        viewID: `copied-obj-${ID()}`,
        id: ID(),
      } as Types.AllPartRefs;
    }
    return action({fromRef, ref});
  }
);

export const addObject = createAction(
  '@view/addObject',
  action => (wholeAndType: Types.AllWholeWithTypes) => {
    return action({
      wholeAndType,
      ref: {id: ID(), type: wholeAndType.type, viewID: `new-obj-${ID()}`},
    });
  }
);

export const save = createAction(
  '@view/save',
  action => (viewRef: Types.ViewRef) => action({viewRef})
);

export const setDescription = createAction(
  '@view/setDescription',
  action => (ref: Types.ViewRef, description: string) =>
    action({ref, description})
);

export const setPreviewUrl = createAction(
  '@view/setPreviewUrl',
  action => (ref: Types.ViewRef, previewUrl?: string) =>
    action({ref, previewUrl})
);

export const setCoverUrl = createAction(
  '@view/setCoverUrl',
  action => (ref: Types.ViewRef, coverUrl?: string) => action({ref, coverUrl})
);

export const setLocked = createAction(
  '@view/setLocked',
  action => (ref: Types.ViewRef, locked: boolean) => action({ref, locked})
);

export const setCommentAlertSubscription = createAction(
  '@nosave_view/report/toggleCommentAlertSubscription',
  action => (ref: ReportTypes.ViewRef, subscriptionID?: string) =>
    action({ref, subscriptionID})
);

export const addAccessToken = createAction(
  '@view/addAccessToken',
  action => (ref: Types.ViewRef, accessToken: Types.AccessToken) =>
    action({ref, accessToken})
);

export const removeAccessToken = createAction(
  '@view/removeAccessToken',
  action => (ref: Types.ViewRef, token: string) => action({ref, token})
);
