import React from 'react';
import {Button, Dropdown, Icon} from 'semantic-ui-react';

import {EMPTY_CONFIG as EMPTY_TABLE_SETTINGS} from '../../../util/runfeed';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import makeComp from '../../../util/profiler';

interface DFTablePaginationProps {
  loading: boolean;
  currentPage: number;
  maxPage: number;
  currentPageSize?: number;
  totalRowCount: number;
  style?: any;
  onClickPrevPage(): void;
  onClickNextPage(): void;
  setPageSize(newPageSize: number): void;
}

export const DFTablePagination: React.FC<DFTablePaginationProps> = makeComp(
  ({
    loading,
    currentPage,
    maxPage,
    onClickPrevPage,
    onClickNextPage,
    setPageSize,
    totalRowCount,
    currentPageSize,
    style,
  }) => {
    currentPageSize = currentPageSize || EMPTY_TABLE_SETTINGS.pageSize;
    return (
      <div className="pagination-controls-wrapper" style={style}>
        <div className="pagination-controls">
          {!loading && (
            <span className="pagination-count">
              <Dropdown
                icon={null}
                data-test="pageSize-popup"
                trigger={
                  <span className="page-size-indicator">
                    {totalRowCount > 0 &&
                      `${1 + (currentPage - 1) * currentPageSize}-${Math.min(
                        totalRowCount,
                        currentPage * currentPageSize
                      )}`}
                    <Icon name="dropdown" />
                  </span>
                }>
                <Dropdown.Menu>
                  <Dropdown.Header className="small-header">
                    Show up to
                  </Dropdown.Header>
                  {[10, 20, 50].map(pageSizeOption => (
                    <Dropdown.Item
                      style={{height: 42}}
                      data-test="pageSize-set"
                      key={pageSizeOption}
                      onClick={() => setPageSize(pageSizeOption)}>
                      <div
                        style={{
                          minWidth: 88,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <span>{pageSizeOption} rows</span>
                        <span>
                          {pageSizeOption === currentPageSize && (
                            <LegacyWBIcon name="check" />
                          )}
                        </span>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              of {totalRowCount}
            </span>
          )}
          <Button.Group className="pagination-buttons">
            <Button
              size="tiny"
              className="wb-icon-button only-icon"
              disabled={loading || currentPage === 1}
              onClick={onClickPrevPage}>
              <LegacyWBIcon name="previous" />
            </Button>
            <Button
              size="tiny"
              className="wb-icon-button only-icon"
              disabled={loading || currentPage === maxPage}
              onClick={onClickNextPage}>
              <LegacyWBIcon name="next" />
            </Button>
          </Button.Group>
        </div>
      </div>
    );
  },
  {id: 'DFTablePagination', memo: true}
);
