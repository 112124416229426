import React from 'react';
import {View as VegaView, SignalListenerHandler} from 'vega';

export interface SignalValueProps {
  className?: string;
  view: VegaView;
  signal: string;
  initialValue: any;
}

const SignalValue: React.FC<SignalValueProps> = ({
  className,
  view,
  signal,
  initialValue,
}) => {
  const [value, setValue] = React.useState(initialValue);
  React.useEffect(() => {
    const onSignalChange: SignalListenerHandler = (name, newValue) => {
      setValue(newValue);
    };
    try {
      view.addSignalListener(signal, onSignalChange);
    } catch {
      return;
    }
    return () => {
      view.removeSignalListener(signal, onSignalChange);
    };
  }, [signal, view]);
  return (
    <div className={className}>{value == null ? '-' : value.toString()}</div>
  );
};

export default SignalValue;
