import React, {useCallback, useState, useMemo} from 'react';
import {Mutation} from 'react-apollo';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Header,
  Input,
  Label,
  Segment,
} from 'semantic-ui-react';
import Alerts from '../components/Alerts';

import StoragePercentage from '../components/StoragePercentage';
import DeleteMemberModal from '../components/DeleteMemberModal';
import EditableImage from '../components/EditableImage';
import NoMatch from '../components/NoMatch';
import MemberAccess from '../components/MemberAccess';
import MemberInfo from '../components/MemberInfo';
import ProjectAccess from '../components/ProjectAccess';
import RateLimits from '../components/RateLimits';
import SlackIntegration from '../components/SlackIntegration';
import Loader from '../components/WandbLoader';
import '../css/Team.less';
import {
  UpdateEntityPhotoMutationFn,
  useCreateInviteMutation,
  useCreateServiceAccountMutation,
  UserAccountType,
  useTeamOrganizationQuery,
} from '../generated/graphql';
import {TEAM_PHOTO_UPDATE} from '../graphql/admin';
import {
  MemberData,
  TeamQueryResultProps,
  withTeamQuery,
  EntityData,
  toMemberData,
} from '../graphql/teamQuery';
import {
  UPDATE_ENTITY,
  UPDATE_MEMBER,
  UpdateEntityMutationData,
  UpdateEntityMutationVariables,
  UpdateMemberMutationData,
  UpdateMemberMutationVariables,
} from '../graphql/users';
import config from '../config';
import {storagePage} from '../util/urls';
import {useAdminModeActive, viewerUsingAdminPrivileges} from '../util/admin';
import {
  extractErrorMessageFromApolloError,
  extractStatusCodeFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import * as String from '@wandb/cg/browser/utils/string';
import './Profile.css';
import makeComp from '../util/profiler';
import {HOUR, SECOND} from '../util/time';
import {toast} from 'react-toastify';
import {useViewer} from '../state/viewer/hooks';
import {AccessOptions} from '../util/permissions';
import PurchaseSeatModal, {
  PurchaseSeatModalProps,
} from '../components/PurchaseSeatModal';
import {orgPrimarySubUnitPrice} from '../util/pricing';
import * as Urls from '../util/urls';

type AccountType = 'USER' | 'SERVICE' | 'ADMIN';

type TeamProps = TeamQueryResultProps;

const Team: React.FC<TeamProps & RouteComponentProps> = makeComp(
  ({teamQuery, history}) => {
    const [accountType, setAccountType] = useState<AccountType>(
      UserAccountType.User
    );
    const [emailOrUsername, setEmailOrUsername] = useState('');

    const viewer = useViewer();
    const [createInvite] = useCreateInviteMutation({
      context: propagateErrorsContext(),
    });
    const [createServiceAccount] = useCreateServiceAccountMutation();

    const [purchaseSeatModalState, setPurchaseSeatModalState] =
      useState<PurchaseSeatModalProps | null>(null);

    const orgSubUnitPrice = useMemo(() => {
      if (teamQuery.entity?.organization == null) {
        return null;
      }
      return orgPrimarySubUnitPrice(teamQuery.entity.organization);
    }, [teamQuery.entity]);

    const sendInvite = useCallback(async () => {
      // TODO Check for organization remaining seats.
      if (!teamQuery.entity) {
        return;
      }

      if (accountType === UserAccountType.Service) {
        await createServiceAccount({
          variables: {
            entityName: teamQuery.entity.name,
            description: emailOrUsername,
          },
        });
        teamQuery.refetch();
        setEmailOrUsername('');

        return;
      }

      // Wrap the rest of the logic in a function so that we can either invoke it or delegate it.
      const teamName = teamQuery.entity.name;
      const doCreateInvite = async (addSeat: boolean) => {
        try {
          const createInviteVariable = {
            variables: {
              entityName: teamName,
              admin: accountType === 'ADMIN',
              [String.isValidEmail(emailOrUsername) ? 'email' : 'username']:
                emailOrUsername,
              addSeat,
            },
          };
          const createInviteResult = await createInvite(createInviteVariable);

          if (createInviteResult.errors != null) {
            toast(createInviteResult.errors.map(e => e.message).join(', '));
            console.error(
              extractErrorMessageFromApolloError(createInviteResult.errors)
            );
            return;
          }

          const remainingSeats =
            createInviteResult.data?.createInvite?.remainingSeats != null &&
            createInviteResult.data?.createInvite?.remainingSeats > 0;

          if (
            !createInviteResult.data?.createInvite?.success &&
            teamQuery?.entity?.organization != null &&
            !remainingSeats
          ) {
            // present modal to purchase additional seats if applicable
            if (orgSubUnitPrice != null) {
              setPurchaseSeatModalState({
                seatsToPurchase: 1,
                pricePerSeat: orgSubUnitPrice,
                billingInterval:
                  teamQuery.entity.organization.subscriptions[0]?.plan
                    .billingInterval ?? 'month',
                onConfirm: async () => {
                  await doCreateInvite(true);
                  setPurchaseSeatModalState(null);
                },
                onClose: () => setPurchaseSeatModalState(null),
              });
            } else {
              toast(noOrgSeatsRemainingMessage());
            }
          } else {
            teamQuery.refetch();
            setEmailOrUsername('');
          }
        } catch (err) {
          const statusCode = extractStatusCodeFromApolloError(err);
          if (statusCode === 409) {
            toast(`This user ${emailOrUsername} is already on the team.`);
          } else if (statusCode === 404) {
            // when the user tries to add a user by username
            // but cannot find it, toast custom err message
            toast(`This user ${emailOrUsername} cannot be found.`);
          } else if (statusCode === 403) {
            toast(
              `You don't have permission to add a new user. Ask your team admin.`
            );
          } else {
            // for any other cases, log error message in console
            const errMsg =
              extractErrorMessageFromApolloError(err) ?? err.message;
            console.error(errMsg);
            toast(
              `Something went wrong while trying to add the user ${emailOrUsername}: ${errMsg}`
            );
          }
        }
      };

      doCreateInvite(false);
    }, [
      teamQuery,
      accountType,
      orgSubUnitPrice,
      createServiceAccount,
      emailOrUsername,
      createInvite,
    ]);

    const isAdmin = useAdminModeActive();
    const getViewerMember = useCallback(() => {
      if (!teamQuery || !teamQuery.entity || !viewer) {
        return null;
      }
      const members = teamQuery.entity.members;
      return members.find(m => m.username === viewer.username) || null;
    }, [teamQuery, viewer]);

    const viewerMember = getViewerMember();

    // user can delete member if they are team admin or using admin power
    // and they can delete members EXCEPT themselves
    const canDeleteMember = useCallback(
      (member: MemberData) => {
        const isTeamAdmin = viewerMember?.admin;
        const notDeletingSelf = viewer?.username !== member.username;

        return (isAdmin || isTeamAdmin) && notDeletingSelf;
      },
      [viewer, viewerMember, isAdmin]
    );

    const isViewerMember = useCallback(
      (member: MemberData) => {
        if (!viewerMember) {
          return false;
        }
        return member.username === viewerMember.username;
      },
      [viewerMember]
    );

    const entityName = teamQuery?.entity?.name;
    const teamOrganizationQuery = useTeamOrganizationQuery({
      variables: {entityName},
    });

    const sub =
      teamOrganizationQuery.data?.entity?.organization?.subscriptions.find(
        s => typeof s.privileges.compute_hours === 'number'
      )?.privileges?.compute_hours;

    if (teamQuery.loading) {
      return <Loader />;
    }

    if (teamQuery.entity == null) {
      return <NoMatch />;
    }

    // Am I going to hell for this?
    // We have this nice manually defined type for Entity..which is incorrect
    // as far as the schema goes. But we use it in so many places, so  I propose the
    // following compromise: if the entity is present, let's just pretend all the fields
    // aren't null. So I made a type helper that overrides the nullable fields in the
    // GQL return type with the non-nullable fields in the EntityData type.
    type Override<T, U> = T & {[P in keyof U]: U[P]};
    const entity = teamQuery.entity as Override<
      typeof teamQuery.entity,
      EntityData
    >;

    if (entity.readOnly) {
      return <NoMatch />;
    }

    const nonServiceMemberCount = entity.members.filter(member => {
      return member.accountType !== UserAccountType.Service;
    }).length;

    return (
      <div className="settings-page">
        {purchaseSeatModalState && (
          <PurchaseSeatModal {...purchaseSeatModalState} />
        )}
        <Container text>
          <Header as="h1">Team Settings for {entity.name}</Header>
          <Segment>
            <Header as="h3">Members ({nonServiceMemberCount})</Header>
            <div className="members-list">
              {entity.members.map((m, i) => {
                const member = toMemberData(m);
                return (
                  <React.Fragment key={i}>
                    {i === 0 ? <></> : <Divider />}
                    <div className="member-list-item" key={member.email + i}>
                      <MemberInfo
                        member={member}
                        isViewer={isViewerMember(member)}
                      />
                      <div className="member-actions">
                        {member.pending && <Label horizontal>Invited</Label>}
                        {!member.pending && (
                          <Mutation<
                            UpdateMemberMutationData,
                            UpdateMemberMutationVariables
                          >
                            mutation={UPDATE_MEMBER}
                            onCompleted={teamQuery.refetch}>
                            {updateMember => (
                              <MemberAccess
                                member={member}
                                readOnly={
                                  !viewerMember?.admin &&
                                  !(
                                    viewer?.admin &&
                                    viewerUsingAdminPrivileges()
                                  )
                                }
                                setAdmin={(admin: boolean) => {
                                  updateMember({
                                    variables: {
                                      entityName: entity.name,
                                      user: member.id,
                                      admin,
                                    },
                                  });
                                }}
                              />
                            )}
                          </Mutation>
                        )}
                        {canDeleteMember(member) && (
                          <DeleteMemberModal
                            member={member}
                            org={
                              teamOrganizationQuery.data?.entity?.organization
                            }
                            entityName={teamQuery?.entity?.name}
                            refetch={teamQuery.refetch}></DeleteMemberModal>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {teamQuery.entity.isTeam && (
              <div className="add-member-form">
                <Input
                  fluid
                  data-test="add-team-member-input"
                  className="add-member-form--email"
                  placeholder={
                    addMemberInputPlaceholderByAccountType[accountType]
                  }
                  value={emailOrUsername}
                  onChange={(_, {value}) => setEmailOrUsername(value.trim())}
                />
                <Dropdown
                  data-test="add-member-form-account-type"
                  className="add-member-form--account-type"
                  button
                  compact
                  options={[
                    {text: 'Member', value: 'USER'},
                    {text: 'Admin', value: 'ADMIN'},
                    {text: 'Service', value: 'SERVICE'},
                  ]}
                  value={accountType}
                  onChange={(_, {value}) =>
                    setAccountType(value as AccountType)
                  }
                />
                <Button data-test="add-team-member-button" onClick={sendInvite}>
                  {accountType === UserAccountType.Service
                    ? 'Create'
                    : 'Invite'}
                </Button>
              </div>
            )}
          </Segment>
          <Segment>
            <Header as="h3">Team Avatar</Header>
            <Mutation mutation={TEAM_PHOTO_UPDATE}>
              {(teamUp: UpdateEntityPhotoMutationFn) => (
                <EditableImage
                  photoUrl={entity.photoUrl}
                  displaySize={200}
                  label={false}
                  entityName={entity.name}
                  save={newVal => {
                    teamUp({
                      variables: {
                        photoUrl: newVal,
                        entityName: entity.name,
                      },
                    });
                  }}
                />
              )}
            </Mutation>
          </Segment>
          <Segment>
            <Header as="h3">Team Alerts</Header>
            <p>Notify the team when a member's run finishes or crashes.</p>
            <Alerts
              entity={entity}
              entityRefetch={teamQuery.refetch}
              subscriptionTypes={['SlackChannelSubscription']}
            />
            <SlackIntegration
              history={history}
              entity={teamQuery.entity}
              entityRefetch={teamQuery.refetch}
              integrationReason={
                'Configure a Slack integration so we can send your team alerts.'
              }
            />
          </Segment>
          <Mutation<UpdateEntityMutationData, UpdateEntityMutationVariables>
            mutation={UPDATE_ENTITY}
            onCompleted={teamQuery.refetch}>
            {updateEntity => (
              <>
                <Segment>
                  <Header as="h3">Privacy</Header>
                  <div className="privacy-wrapper">
                    Default project privacy
                    <ProjectAccess
                      project={{
                        readOnly: entity.readOnlyAdmin,
                        access: entity.defaultAccess as AccessOptions,
                        entity: {
                          readOnlyAdmin: true,
                          defaultAccess: entity.defaultAccess as AccessOptions,
                          privateOnly: entity.privateOnly,
                          isTeam: entity.isTeam,
                        },
                      }}
                      updateProjectAccess={(access: string) =>
                        updateEntity({
                          variables: {
                            entityName: entity.name,
                            defaultAccess: access,
                          },
                        })
                      }
                    />
                  </div>
                  <Divider />
                  <div className="privacy-wrapper">
                    <Checkbox
                      toggle
                      label="Force all projects in this team to be private."
                      checked={entity.privateOnly}
                      disabled={entity.readOnlyAdmin}
                      onChange={(_, {checked}) =>
                        updateEntity({
                          variables: {
                            entityName: entity.name,
                            privateOnly: checked,
                          },
                        })
                      }
                    />
                  </div>
                  <Divider />
                  <div className="privacy-wrapper">
                    <Checkbox
                      toggle
                      label="Enable code saving by default"
                      checked={entity.codeSavingEnabled}
                      disabled={entity.readOnlyAdmin}
                      onChange={(_, {checked}) =>
                        updateEntity({
                          variables: {
                            entityName: entity.name,
                            codeSavingEnabled: checked,
                          },
                        })
                      }
                    />
                  </div>
                </Segment>
              </>
            )}
          </Mutation>
          <Segment>
            <Header as="h3">Usage</Header>
            <StoragePercentage
              available={config.MAX_BYTES}
              used={entity.storageBytes}
              selected={0}
              labelOnTop={true}
            />
            {sub != null && (
              <StoragePercentage
                available={sub * (HOUR / SECOND)}
                used={entity.computeHours || 0}
                selected={0}
                computeHours={true}
                labelOnTop={true}
              />
            )}
            <Link to={storagePage(entity.name)}>
              <Button style={{marginTop: 10}}>View Dashboard</Button>
            </Link>
          </Segment>
          {viewer != null && viewer.admin && viewerUsingAdminPrivileges() && (
            <RateLimits entity={entity} />
          )}
        </Container>
      </div>
    );
  },
  {id: 'Team', memo: true}
);

// export dumb component for testing purposes
export {Team};

export default withTeamQuery(Team);

const addMemberInputPlaceholderByAccountType: {[at in AccountType]: string} = {
  SERVICE: 'Enter a description for this service account',
  USER: 'Enter an email or username to invite a new member',
  ADMIN: 'Enter an email or username to invite a new admin',
};

const noOrgSeatsRemainingMessage = () => {
  const message =
    'There are no more available seats in your organization for the current plan.';
  const link = <Link to={Urls.subscriptions()}>Subscription Page.</Link>;
  const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;
  if (!isOnPrem) {
    return (
      <p>
        {message} Manage users on the {link}
      </p>
    );
  }
  return <p>{message}</p>;
};
