import './markdown-blocks.less';
import * as S from './markdown-blocks.styles';
import * as HeadingStyles from './headings.styles';

import React from 'react';
import unified from 'unified';
import {Node, Element, Editor, Transforms, Text, Path} from 'slate';
import {
  RenderElementProps,
  useEditor,
  useSelected,
  useFocused,
  useReadOnly,
  ReactEditor,
  useSlate,
} from 'slate-react';
import * as _ from 'lodash';
import {BlockWrapper} from './drag-drop';
import {createWBSlateEditor} from '../WBSlate';
import {WBSlateReduxBridgeContext} from '../WBSlateReduxBridge';
import {useUploadMarkdownImage} from '../../../util/images';
import {EditorWithHeadings} from './headings';
import parse from 'remark-parse';
import {remarkToSlate, slateToRemark} from 'remark-slate-transformer';
import {isParagraph} from './paragraphs';
import {isLink} from './links';
import math from 'remark-math';
import gfm from 'mdast-util-gfm/to-markdown';
import remarkStringify from 'remark-stringify';
import * as mdastMath from 'mdast-util-math';

export interface MarkdownBlock extends Element {
  type: 'markdown-block';
  content: string;
  autoFocus?: boolean;
  collapsedChildren?: Node[];
}

export const isMarkdownBlock = (node: Node): node is MarkdownBlock =>
  node.type === 'markdown-block';

export const EditorWithMarkdownBlocks = {
  getCollapsibleMarkdownHeading(text: string): string | null {
    const firstLine = text.split('\n')[0];
    if (/^\s*# .+$/.test(firstLine)) {
      return firstLine;
    }
    return null;
  },
};

export const MarkdownBlockElement: React.FC<
  RenderElementProps & {
    element: MarkdownBlock;
  }
> = ({attributes, element, children}) => {
  const [editing, setEditing] = React.useState(element.autoFocus);
  const editor = useEditor();
  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();

  React.useEffect(() => {
    if (element.autoFocus) {
      Transforms.unsetNodes(editor, 'autoFocus', {
        at: ReactEditor.findPath(editor, element),
      });
    }
  }, [editor, element]);

  const {viewId} = React.useContext(WBSlateReduxBridgeContext);

  const {uploadImages, uploadState, setUploadState} =
    useUploadMarkdownImage(viewId);

  const collapsibleHeading =
    EditorWithMarkdownBlocks.getCollapsibleMarkdownHeading(element.content);

  const onStartEditing = React.useCallback(() => {
    if (element.collapsedChildren != null) {
      EditorWithHeadings.uncollapseHeading(editor, element);
    }
    setEditing(true);
  }, [editor, element]);
  const onStopEditing = React.useCallback(() => {
    setEditing(false);
  }, []);

  return (
    <BlockWrapper attributes={attributes} element={element} disableClickSelect>
      <S.MarkdownBlockWrapper contentEditable={false}>
        {!editing && collapsibleHeading != null && (
          <HeadingStyles.HeadingCollapser
            $collapsed={element.collapsedChildren != null}
            contentEditable={false}
            onMouseDown={e => e.preventDefault()}
            onClick={() =>
              EditorWithHeadings.toggleCollapseHeading(editor, element)
            }
          />
        )}
        <S.MarkdownBlock
          selected={selected}
          focused={focused}
          className="slate-markdown-editor"
          key="editor-expanded"
          readOnly={readOnly}
          onChange={text => {
            setUploadState(undefined);
            Transforms.setNodes(
              editor,
              {content: text},
              {at: ReactEditor.findPath(editor, element)}
            );
          }}
          noHelpText
          placeholder="+ Add text"
          editPlaceholder="Type any **markdown** or $latex$"
          serverText={
            collapsibleHeading != null && element.collapsedChildren != null
              ? collapsibleHeading
              : element.content
          }
          updateFromServerText
          editing={editing}
          onPaste={uploadImages}
          uploadState={uploadState}
          onStartEditing={onStartEditing}
          onStopEditing={onStopEditing}
          onDeleteEmpty={() => {
            ReactEditor.focus(editor);
            // deleting the block is done by the default handler
          }}
          // onContentHeightChange={onMarkdownHeightChange}
        />
      </S.MarkdownBlockWrapper>
      {children}
    </BlockWrapper>
  );
};

export const withMarkdownBlocks = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element =>
    isMarkdownBlock(element) ? true : isVoid(element);

  return editor;
};

// Markdown is converted in three steps.
// 1. The given markdown is processed with the remarkToSlate unified plugin
// 2. Tables are converted to markdown blocks in a simple pre-order traversal
// 3. The resulting nodes are set as the content of the fake Slate editor (conversionEditor),
//    which runs some extra custom normalization (see withMarkdownConversion)
export const convertMarkdownToSlate = (markdownContent: string) => {
  const vfile = unified()
    .use(parse)
    .use(math)
    .use(remarkToSlate)
    .processSync(markdownContent);

  const slateNodes = [...vfile.contents] as unknown as Element[];

  /** Preprocessing step, mainly to convert tables into markdown blocks */

  const tableProcessor = unified()
    .data('toMarkdownExtensions', [gfm(), mdastMath.toMarkdown])
    .use(slateToRemark)
    .use(remarkStringify);

  const allDefinitionNodes = findNodesByType(slateNodes, 'definition');

  const recurse = (els: Node[]) => {
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      if (Text.isText(el)) {
        continue;
      }

      // Convert link references into actual links.
      // See https://www.markdownguide.org/basic-syntax/#reference-style-links
      // Note: remarkToSlate converts text in unescaped brackets to a linkReference (with no definition).
      // We don't touch those here, and instead handle them in the normalization step.
      if (el.type === 'linkReference') {
        const linkReferenceDefinition = allDefinitionNodes.find(
          def => def.identifier === el.identifier
        );
        if (linkReferenceDefinition != null) {
          els[i] = {
            type: 'link',
            children: el.children,
            url: linkReferenceDefinition.url,
            title: linkReferenceDefinition.title,
          };
        }
      }

      // Unfortunately need to preprocess code blocks due to Slate normalization bug.
      if (el.type === 'code') {
        const codeLines = el.children
          .map(c => (Text.isText(c) ? c.text : ''))
          .join('')
          .split('\n');
        els[i] = {
          type: 'code-block',
          language: el.lang || undefined,
          children: codeLines.map(l => {
            return {
              type: 'code-line',
              language: el.lang || undefined,
              children: [{text: l}],
            };
          }),
        };
        continue;
      }

      if (el.type === 'callout') {
        const calloutLines = el.children
          .map(c => (Text.isText(c) ? c.text : ''))
          .join('')
          .split('\n');
        els[i] = {
          type: 'callout-block',
          children: calloutLines.map(l => {
            return {
              type: 'callout-line',
              children: [{text: l}],
            };
          }),
        };
        continue;
      }

      if (el.type === 'table') {
        els[i] = {
          type: 'markdown-block',
          content: tableProcessor.stringify(
            tableProcessor.runSync({
              type: 'root',
              children: [el],
            })
          ),
          children: [{text: ''}],
        };
        continue;
      }

      recurse(el.children as Node[]);
    }
  };

  recurse(slateNodes);

  /** Final conversion */

  // Make a copy of WBSlate editor for markdown conversion (not visible to user).
  const fakeEditor = withMarkdownConversion(createWBSlateEditor());
  // Set conversionEditor content to the output of markdownToSlate
  // This triggers the withMarkdownConversion() custom normalization
  Transforms.insertNodes(fakeEditor, slateNodes);

  return _.cloneDeep(fakeEditor.children);
};

const findNodesByType = (
  searchNodes: Node[],
  type: string,
  result?: Node[]
): Node[] => {
  result = result ?? [];
  if (searchNodes == null) {
    return result;
  }
  for (const n of searchNodes) {
    if (n.type === type) {
      result.push(n);
    }
    findNodesByType((n as Element).children, type, result);
  }
  return result;
};

const isCenteredParagraph = (node: Node) => {
  if (!isParagraph(node) || node.children.length === 0) {
    return false;
  }
  const firstChild = node.children[0];
  const lastChild = node.children[node.children.length - 1];
  return (
    Text.isText(firstChild) &&
    firstChild.text.startsWith('-> ') &&
    Text.isText(lastChild) &&
    lastChild.text.endsWith(' <-')
  );
};

export function convertAndTransformMarkdownBlock(
  editor: ReactEditor,
  mdNode: MarkdownBlock,
  path?: Path
): number {
  const slateNodes = convertMarkdownToSlate(mdNode.content);
  if (mdNode.collapsedChildren != null) {
    slateNodes.push(...mdNode.collapsedChildren);
  }
  path = path ?? ReactEditor.findPath(editor, mdNode);
  Transforms.removeNodes(editor, {at: path});
  Transforms.insertNodes(editor, slateNodes, {
    at: path,
  });
  const nodesAdded = slateNodes.length - 1;
  return nodesAdded;
}

declare global {
  interface Window {
    __WB_WYSIWYG_CONVERSION_REVERT?: () => void;
  }
}

export function convertAndTransformAllMarkdownBlocks(
  editor?: ReactEditor
): void {
  // HAX: avoid the work of properly passing the editor by relying on the global set in WBSlate.tsx
  editor = editor ?? (window as any).editor;
  if (editor == null) {
    return;
  }
  ReactEditor.focus(editor);

  const childrenSnapshot = _.cloneDeep(editor.children);
  window.__WB_WYSIWYG_CONVERSION_REVERT = () => {
    if (editor == null) {
      return;
    }
    const nodesToRemove = editor.children.length;
    for (let i = 0; i < nodesToRemove; i++) {
      Transforms.removeNodes(editor, {at: [0]});
    }
    Transforms.setNodes(editor, childrenSnapshot[0], {at: [0]});
    Transforms.insertNodes(editor, childrenSnapshot.slice(1), {at: [1]});
  };

  // HAX: Converting collapsed markdown blocks may leave markdown blocks.
  // Repeat until there are no more.
  while (true) {
    // HAX: Ideally we would let Slate find the path for each MD node, as is done in `convertAndTransformMarkdownBlock`.
    // Unfortunately, Slate returns stale paths on findPath if we do transforms in between.
    // To get around this, we collect the paths at the beginning and keep track of how many nodes have been added by the transforms.
    const mdBlocksWithIndex: Array<{node: MarkdownBlock; i: number}> = [];
    for (let i = 0; i < editor.children.length; i++) {
      const node = editor.children[i];
      if (isMarkdownBlock(node) && markdownBlockIsConvertable(node)) {
        mdBlocksWithIndex.push({node, i});
      }
    }
    if (mdBlocksWithIndex.length === 0) {
      break;
    }
    let nodesAdded = 0;
    for (const {node, i} of mdBlocksWithIndex) {
      nodesAdded += convertAndTransformMarkdownBlock(editor, node, [
        i + nodesAdded,
      ]);
    }
  }
}

export function revertConvertAllMarkdownBlocks(): void {
  window.__WB_WYSIWYG_CONVERSION_REVERT?.();
}

export function editorHasMarkdownBlocks(editor?: ReactEditor): boolean {
  editor = editor ?? (window as any).editor;
  if (editor == null) {
    return false;
  }
  return editor.children.some(isMarkdownBlock);
}

export function markdownBlockIsConvertable(node: MarkdownBlock): boolean {
  const s = node.content.trim();
  // is probably all table
  if (s[0] === '|' && s[s.length - 1] === '|') {
    return false;
  }
  return true;
}

// This tweaks the output of remarkToSlate markdown conversion to match the constraints of WBSlate.
// Some node types are renamed (e.g. "listItem" => "list-item"),
// etc
export const withMarkdownConversion = <T extends Editor>(editor: T) => {
  const {normalizeNode} = editor;
  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (isLink(node)) {
      if (node.url != null && !node.url.startsWith('http')) {
        Transforms.setNodes(editor, {url: `http://${node.url}`}, {at: path});
        return;
      }
    }

    // Reference-style links
    // https://www.markdownguide.org/basic-syntax/#reference-style-links
    if (node.type === 'linkReference') {
      // Text in unescaped brackets is interpreted as a reference link (without
      // a corresponding definition/url), so we just lift the text and wrap it in brackets.
      if (node.url == null) {
        const newNodes = [
          {text: '['},
          ...(node as Element).children,
          {text: ']'},
        ];
        Transforms.removeNodes(editor, {at: path});
        Transforms.insertNodes(editor, newNodes, {at: path});
      }
      return;
    }

    if (node.type === 'definition') {
      Transforms.removeNodes(editor, {at: path});
      return;
    }

    if (node.type === 'thematicBreak') {
      // Change type "thematicBreak" => "horizontal-rule"
      Transforms.setNodes(editor, {type: 'horizontal-rule'}, {at: path});
      return;
    }

    if (isParagraph(node)) {
      // Handle our custom text-centering code (see centerText in markdown.ts)
      // Removes -> and <- from text children, adds textAlign attr
      if (isCenteredParagraph(node)) {
        const firstChild = node.children[0] as Text;
        const lastChild = node.children[node.children.length - 1];
        const newChildren = _.cloneDeep(node.children);
        newChildren[0] = {...firstChild, text: firstChild.text.slice(3)};
        newChildren[newChildren.length - 1] = {
          ...lastChild,
          text: (newChildren[newChildren.length - 1] as Text).text.slice(
            0,
            (newChildren[newChildren.length - 1] as Text).text.length - 3
          ),
        };
        Transforms.removeNodes(editor, {at: path});
        Transforms.insertNodes(
          editor,
          {...node, children: newChildren, textAlign: 'center'},
          {at: path}
        );
        return;
      }

      // Clean up paragraphs with only whitespace
      if (
        node.children.length === 1 &&
        Text.isText(node.children[0]) &&
        /^\s+$/.test(node.children[0].text)
      ) {
        Transforms.removeNodes(editor, {at: path});
        Transforms.insertNodes(
          editor,
          {type: 'paragraph', children: [{text: ''}]},
          {at: path}
        );
        return;
      }
    }
    if (node.type === 'heading') {
      // Rename attribute 'depth' => 'level'
      if (node.depth != null) {
        Transforms.setNodes(editor, {level: node.depth}, {at: path});
        Transforms.unsetNodes(editor, 'depth', {at: path});
      }
      return;
    }
    if (node.type === 'image') {
      const imageParent = Node.parent(editor, path);
      // If it's a linked image, set the href attribute to the link.url and lift the image node
      if (imageParent.type === 'link') {
        Transforms.setNodes(
          editor,
          {
            href: imageParent.url,
          },
          {at: path}
        );
        Transforms.liftNodes(editor, {at: path});
        return;
      }
      // If the image is wrapped in a paragraph, we lift it.
      // Also, if the next node after the image is a centered paragraph,
      // we auto-convert it into a caption on the image.
      if (imageParent.type === 'paragraph') {
        const possibleCaptionPath = Path.next(Path.parent(path));
        if (Node.has(editor, possibleCaptionPath)) {
          const possibleCaption = Node.get(editor, possibleCaptionPath);
          if (
            isCenteredParagraph(possibleCaption) ||
            possibleCaption.textAlign === 'center'
          ) {
            Transforms.removeNodes(editor, {at: possibleCaptionPath});
            Transforms.removeNodes(editor, {at: path});
            Transforms.insertNodes(
              editor,
              {...node, children: [possibleCaption], hasCaption: true},
              {at: path}
            );
          }
        }
        Transforms.liftNodes(editor, {at: path});
        return;
      }
    }
    if (node.type === 'listItem') {
      // Rename type "listItem" => "list-item" and set the "ordered" attribute based on the parent list
      Transforms.setNodes(
        editor,
        {
          type: 'list-item',
          ordered: Node.parent(editor, path)?.ordered,
        },
        {at: path}
      );
      return;
    }
    if (node.type === 'code') {
      // Change type "code" => "code-block", rename attributes
      Transforms.removeNodes(editor, {at: path});
      Transforms.insertNodes(
        editor,
        {
          ...node,
          type: 'code-block',
          language: node.lang,
          metadata: node.meta,
        },
        {at: path}
      );
      return;
    }
    if (node.type === 'blockquote') {
      Transforms.setNodes(editor, {...node, type: 'block-quote'}, {at: path});
      return;
    }
    if (node.type === 'inlineMath') {
      Transforms.removeNodes(editor, {at: path});
      Transforms.insertNodes(
        editor,
        {
          type: 'latex',
          // TODO: will this always have only one child?
          // should this be a constraint on text nodes (if parent type = inlineMath) instead?
          content: (node as Element).children[0].text,
          children: [{text: ''}],
        },
        {at: path}
      );
      return;
    }
    if (node.type === 'math') {
      // Change type "math" => "latex"
      Transforms.removeNodes(editor, {at: path});
      Transforms.insertNodes(
        editor,
        {
          type: 'latex',
          block: true,
          // TODO: will this always have only one child?
          // should this be a constraint on text nodes (with inlineMath parent) instead?
          content: (node as Element).children[0].text,
          children: [{text: ''}],
        },
        {at: path}
      );
      return;
    }
    normalizeNode(entry);
  };
  return editor;
};

interface EditMarkdownBlockWidgetProps {
  node: MarkdownBlock;
  open?: boolean;
  direction: 'up' | 'down';
  onClose(): void;
}

export const EditMarkdownBlockWidget: React.FC<EditMarkdownBlockWidgetProps> =
  ({onClose, open, direction, node}) => {
    const editor = useSlate();
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const [converted, setConverted] = React.useState(false);

    React.useEffect(() => {
      if (open) {
        setConverted(false);
      }
    }, [open]);

    /**
     * Close when the mouse leaves the defined region, unless focused
     */
    React.useEffect(() => {
      const wrapperDOMNode = wrapperRef.current;
      if (wrapperDOMNode == null) {
        return;
      }
      const wrapperDOMRect = wrapperDOMNode.getBoundingClientRect();
      const editorDOMNode = ReactEditor.toDOMNode(editor, editor);
      const DOMNode = ReactEditor.toDOMNode(editor, node).querySelector(
        '.inline-markdown-editor'
      );
      const DOMRect = DOMNode?.getBoundingClientRect();

      const onMouseMove = (e: MouseEvent) => {
        if (DOMRect == null || converted) {
          return;
        }

        if (
          (e.x >= DOMRect.left &&
            e.x <= DOMRect.right &&
            e.y >= DOMRect.top - (direction === 'up' ? 6 : 0) &&
            e.y <= DOMRect.bottom + (direction === 'down' ? 6 : 0)) ||
          (e.x >= wrapperDOMRect.left &&
            e.x <= wrapperDOMRect.right &&
            e.y >= wrapperDOMRect.top &&
            e.y <= wrapperDOMRect.bottom)
        ) {
          return;
        }

        onClose();
      };

      editorDOMNode.addEventListener('mousemove', onMouseMove);

      return () => {
        editorDOMNode.removeEventListener('mousemove', onMouseMove);
      };
    });

    const convertToSlate = React.useCallback(
      (e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        ReactEditor.focus(editor);
        convertAndTransformMarkdownBlock(editor, node);
        setConverted(true);
        window.setTimeout(() => {
          onClose();
        }, 2000);
      },
      [editor, node, onClose]
    );

    if (converted) {
      return (
        <S.EditMarkdownBlockWrapper ref={wrapperRef} converted={true}>
          Converted! Hit cmd+z to undo.
        </S.EditMarkdownBlockWrapper>
      );
    }

    return (
      <S.EditMarkdownBlockWrapper ref={wrapperRef} onClick={convertToSlate}>
        Convert to WYSIWYG
      </S.EditMarkdownBlockWrapper>
    );
  };
