import * as S from './PlusButton.styles';

import React from 'react';
import makeComp from '../../util/profiler';

const PlusButton: React.FC<React.HTMLAttributes<HTMLDivElement>> = makeComp(
  props => {
    return <S.PlusButton onClick={props.onClick}>+</S.PlusButton>;
  },
  {id: 'PlusButton'}
);

export default PlusButton;
