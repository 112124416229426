import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const Wrapper = styled.div<{x: number; y: number; angle: number}>`
  position: fixed;
  z-index: 100;
  left: ${props => props.x - 11}px;
  top: ${props => props.y - 15}px;

  width: 22px;
  height: 30px;
  background: #fbe9e7;
  border-radius: 50%/60% 60% 40% 40%;
  overflow: hidden;
  transform: rotate(${props => props.angle}rad);
`;

export const Stripe = styled.div`
  height: 20%;

  &:not(:first-child) {
    border-top: 1px solid ${globals.white};
  }

  &:nth-child(1) {
    background-color: #ffbde8;
  }

  &:nth-child(2) {
    background-color: #bde8ff;
  }

  &:nth-child(3) {
    background-color: #e8ffbd;
  }

  &:nth-child(4) {
    background-color: #ffe8bd;
  }

  &:nth-child(5) {
    background-color: #e8bdff;
  }
`;
