import * as PanelTypes from '../state/views/panel/types';
import {PartRefFromObjSchema} from '../state/views/types';
import {RepresentationType} from '../types/libs/nglviewer';
import {MaskOptions, MediaCardType} from '../types/media';
import {RunHistoryRow, RunSignature, RunWithHistory} from '../types/run';
import {CompareOp} from '../util/ops';
import {Media} from '../util/runs';
import {Axis, Tile} from './PanelMediaBrowser';

// This type adds the special _wandb field from config to the run for
// dirty state tunneling
export type RunWithHistoryAndMediaWandb = RunWithHistory & {
  _wandb: any;
  entityName: string;
  projectName: string;
};

export type MoleculeConfig = {
  representation?: RepresentationType;
};

export type TileMediaSpec = Pick<
  Tile,
  'runSignature' | 'mediaKey' | 'mediaStep' | 'mediaIndex' | 'run'
> & {mediaType: MediaCardType};

export type TileMedia = {
  step: number;
  historyRow: RunHistoryRow;
  blob: Blob;
  objectURL: string;
  filePath: string;
  directURL: string;
};

export interface MediaCardProps {
  run: RunWithHistoryAndMediaWandb;
  runSignature: RunSignature;

  globalStep: number;

  mediaKey: string;
  mediaIndex: number;

  width: number;
  height: number;

  actualSize: boolean;

  labels?: Axis[];
  runNotes?: string;

  scale?: number;
  single?: boolean;

  mediaWidth?: number;
  mediaHeight?: number;

  disableRunLink?: boolean;

  maskOptions?: MaskOptions;

  // This is for metadata to connect the media browser and the cards
  // For global controls, etc..
  controls?: MediaPanelCardControl;
  // This is used to connect to the shared redux store for a given media panel
  mediaPanelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;

  moleculeConfig?: MoleculeConfig;

  tileMedia: TileMedia | null;
}

// The *Control classes are used to connect information
// controlled in the media panel to information used
// for rendering in the child cards
//
// e.g. Bounding box confidence slider or
//      Segmentation mask toggle
export interface Camera3DControl {
  cameraIndex: number;
}

export interface MaskControl {
  disabled: boolean;
  opacity: number;
}

export interface AllMaskControls {
  toggles?: {
    [mediaKey: string]: {
      [maskKey: string]: {[classOrAll: string]: MaskControl};
    };
  };
}

export interface BoundingBoxClassControl {
  disabled: boolean;
}

export interface BoundingBoxSliderControl {
  disabled?: boolean;
  comparator?: CompareOp;
  value?: number;
}

export interface AllBoundingBoxControls {
  sliders?: {
    [valueName: string]: BoundingBoxSliderControl;
  };
  toggles?: {
    [mediaKey: string]: {
      [boxKey: string]: {[classOrAll: string]: BoundingBoxClassControl};
    };
  };
  styles?: {
    [mediaKey: string]: {
      [boxKey: string]: Style;
    };
  };
}

export interface Style {
  lineStyle: LineStyle;
}

export type LineStyle = 'line' | 'dotted' | 'dashed';

export interface MediaPanelCardControl {
  cameraControl?: Camera3DControl;
  boundingBoxControl?: AllBoundingBoxControls;
  segmentationMaskControl?: AllMaskControls;
}

type GetMediaByType<T extends Media | string> = {
  [mediaType: string]: (v: any) => T | null;
};

type GetMediaParams = {
  historyRow: RunHistoryRow | undefined;
  mediaKey: string;
};

function getMedia<T extends Media | string>(
  {historyRow, mediaKey}: GetMediaParams,
  getMediaByType: GetMediaByType<T>
): T | null {
  const mediaWBValue = historyRow?.[mediaKey];
  if (mediaWBValue == null) {
    return null;
  }

  const getMediaFn = getMediaByType[mediaWBValue._type];
  return getMediaFn?.(mediaWBValue) ?? null;
}

export function getAudioMedia(
  params: GetMediaParams,
  mediaIndex: number
): Media | null {
  return getMedia<Media>(params, {
    audio: v => v.audio?.[mediaIndex] ?? null,
    'audio-file': v => v,
  });
}

export function getImageMedia(params: GetMediaParams): Media | null {
  return getMedia<Media>(params, {
    images: v => v,
    'image-file': v => v,
    'images/separated': v => v,
  });
}

export function getVideoMedia(
  params: GetMediaParams,
  mediaIndex: number
): Media | null {
  return getMedia<Media>(params, {
    videos: v => v.videos?.[mediaIndex] ?? null,
    'video-file': v => v,
  });
}

export function getTableMediaPath(params: GetMediaParams): string | null {
  return getMedia<string>(params, {
    'table-file': v => v.path,
  });
}

export function getPlotlyMediaPath(params: GetMediaParams): string | null {
  return getMedia<string>(params, {
    'plotly-file': v => v.path,
  });
}

export function getHTMLMedia(
  params: GetMediaParams,
  mediaIndex: number
): Media | null {
  return getMedia<Media>(params, {
    html: v => v.html?.[mediaIndex] ?? null,
    'html-file': v => v,
  });
}

export function getObject3DMediaPath(
  params: GetMediaParams,
  mediaIndex: number
): string | null {
  return getMedia<string>(params, {
    object3D: v => {
      const pathSegment = v.filenames?.[mediaIndex];
      if (pathSegment == null) {
        return null;
      }
      return `media/object3D/${pathSegment}`;
    },
    'object3D-file': v => v.path,
  });
}

export function getBokehMediaPath(
  params: GetMediaParams,
  mediaIndex: number
): string | null {
  return getMedia<string>(params, {
    bokeh: v => {
      const pathSegment = v.filenames?.[mediaIndex];
      if (pathSegment == null) {
        return null;
      }
      return `media/bokeh/${pathSegment}`;
    },
    'bokeh-file': v => v.path,
  });
}

export function getMoleculeMediaPath(
  params: GetMediaParams,
  mediaIndex: number
): string | null {
  return getMedia<string>(params, {
    molecule: v => v.filenames?.[mediaIndex] ?? null,
    'molecule-file': v => v,
  });
}
