import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import {UseLoadFile} from './FileBrowser';
import * as Prism from 'prismjs';
import WandbLoader from './WandbLoader';
import MonacoEditor from './Monaco/Editor';
import {File} from '../state/graphql/runFilesQuery';
import NoMatch from './NoMatch';
import './JupyterViewer.css';
import AU from 'ansi_up';
import makeComp from '../util/profiler';
import * as String from '@wandb/cg/browser/utils/string';
import {generateHTML} from '../util/markdown';
import {JSONObject} from '../types/json';
import classnames from 'classnames';

interface NotebookV4 {
  cells: Cell[];
}
interface Media {
  [key: string]: string[];
}
interface StreamOutput {
  output_type: 'stream';
  text: string[];
  name: 'stdout' | 'stderr';
}
interface ErrorOutput {
  output_type: 'error';
  ename: string;
  evalue: string;
  traceback: string[];
}
interface DisplayOutput {
  output_type: 'display_data';
  data: Media;
  metadata: JSONObject;
}
interface ExecuteOutput {
  output_type: 'execute_result';
  data: Media;
  metadata: JSONObject;
  execution_count: number;
}
type Output = StreamOutput | ErrorOutput | DisplayOutput | ExecuteOutput;
interface Cell {
  cell_type: 'code' | 'markdown';
  id?: string;
  metadata: JSONObject;
  execution_count: number;
  source: string[];
  outputs?: Output[];
}

interface JupyterProps {
  file: File;
  useLoadFile: UseLoadFile;
}

function renderableImageType(output: DisplayOutput | ExecuteOutput) {
  return ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'].find(
    type => output.data[type]
  );
}

function renderedImage(output: any, type: string, key: string) {
  return (
    <img
      key={key}
      alt={output.data['text/plain'] || key}
      src={`data:${type};base64,` + output.data[type]}
    />
  );
}

// This normalizes the styles within the iframe and communicates
// the height of the document
const wrapHTML = (html: string, id: string) => {
  return `<html>
  <head>
    <link rel="stylesheet" type="text/css" href="/normalize.css" />
    <script>
    let height;
    const sendPostMessage = () => {
      if (height !== document.body.offsetHeight) {
        height = document.body.offsetHeight;
        window.parent.postMessage({frameHeight: height, id: "${id}"}, '*');
      }
    }
    window.onload = () => sendPostMessage();
    window.onresize = () => sendPostMessage();
    </script>
  </head>${html}</html>`;
};

function processOutputs(
  cell: Cell,
  id: string,
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
) {
  const ansiUp = new AU();
  if (cell.outputs == null) {
    console.warn('Empty cell', cell);
    return [];
  }
  let iframeHTML = '';
  const outputs: JSX.Element[] = [];
  cell.outputs.forEach((output: Output, i: number): void => {
    const key = `${id}-output-${i}`;
    if (output.output_type === 'stream') {
      outputs.push(
        <div
          className={`${output.name} stream`}
          key={key}
          dangerouslySetInnerHTML={{
            __html: ansiUp.ansi_to_html(
              output.text
                .filter((t: string) => !t.startsWith('wandb:'))
                .join('')
            ),
          }}
        />
      );
    } else if (output.output_type === 'error') {
      outputs.push(
        <div
          className="error"
          key={key}
          dangerouslySetInnerHTML={{
            __html: ansiUp.ansi_to_html(output.traceback.join('\n')),
          }}
        />
      );
    }
    if (
      output.output_type !== 'display_data' &&
      output.output_type !== 'execute_result'
    ) {
      console.warn('Skipping rendering of ', output.output_type);
      return undefined;
    }
    const imageType = renderableImageType(output);
    if (output.data['text/html']) {
      if (output.data['text/html'].join('').includes('<iframe')) {
        console.warn('Not rendering nested iframe');
        return undefined;
      }
      iframeHTML += output.data['text/html'].join('');
    } else if (imageType) {
      outputs.push(renderedImage(output, imageType, key));
      // TODO: image/svg+xml, plotly?
    } else if (output.data['text/markdown']) {
      outputs.push(
        <div className="markdown" key={key}>
          {generateHTML(output.data['text/markdown'].join(''))}
        </div>
      );
    } else if (output.data['text/json']) {
      outputs.push(
        <div className="json" key={key}>
          {output.data['text/json'].join('')}
        </div>
      );
    } else if (output.data['text/plain']) {
      outputs.push(
        <div className="text" key={key}>
          {output.data['text/plain'].join('')}
        </div>
      );
    }
  });
  // To keep things simple we always add the HTML at the start of the outputs
  // TODO: this could make some notebooks render funky
  if (iframeHTML !== '') {
    const key = `${id}-iframe`;
    outputs.unshift(
      <iframe
        ref={iframeRef}
        id={key}
        title={key}
        className="html"
        sandbox="allow-scripts allow-popups allow-downloads"
        key={key}
        style={{border: 'none', width: '100%'}}
        srcDoc={wrapHTML(iframeHTML, key)}
      />
    );
  }
  return outputs;
}

const JupyterViewerFromRun: React.FC<JupyterProps> = makeComp(
  props => {
    const {useLoadFile, file} = props;
    const [raw, setRaw] = useState<any>();
    const [error, setErrorVal] = useState(false);
    const setError = useCallback(() => setErrorVal(true), [setErrorVal]);

    useLoadFile(file, {
      onSuccess: setRaw,
      onFailure: setError,
      fallback: setError,
    });

    if (error) {
      return <NoMatch />;
    }

    if (raw == null) {
      return <WandbLoader />;
    }

    return <JupyterViewer raw={raw} />;
  },
  {id: 'JupyterViewerFromRun'}
);

export const JupyterCell: React.FC<{
  cell: Cell;
  runCode?: (code?: string) => void;
  saveCode?: (code: string) => void;
  id: string;
  readonly: boolean;
}> = makeComp(
  ({cell, id, runCode, saveCode, readonly}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    // This effect resizes the iframe so we don't have extra space / scrollbars
    useEffect(() => {
      const updateHeight = (e: any) => {
        if (
          iframeRef.current &&
          e.data.hasOwnProperty('frameHeight') &&
          e.data.id === iframeRef.current.id
        ) {
          const iframeHeight = Math.min(500, e.data.frameHeight);
          iframeRef.current.style.height = iframeHeight + 'px';
        }
      };
      if (iframeRef.current) {
        window.addEventListener('message', updateHeight);
        return () => {
          window.removeEventListener('message', updateHeight);
        };
      }
      return undefined;
    }, [iframeRef]);

    const outputs = useMemo(() => {
      return processOutputs(cell, id, iframeRef);
    }, [cell, id, iframeRef]);

    return (
      <div className={classnames({cell: true, readonly})}>
        {cell.cell_type === 'code' && (
          <div className="input">
            <div className="gutter">
              <span>[{cell.execution_count}]: </span>
            </div>
            <div className="source">
              <MonacoEditor
                value={cell.source.join('')}
                height={cell.source.length * 24}
                options={{
                  readOnly: readonly,
                  hideCursorInOverviewRuler: true,
                  renderLineHighlight: 'none',
                  lineNumbers: 'off',
                  contextmenu: !readonly,
                  occurrencesHighlight: false,
                  folding: false,
                  fontSize: 16,
                  lineDecorationsWidth: 0,
                  scrollbar: {
                    vertical: 'hidden',
                    handleMouseWheel: false,
                    useShadows: false,
                  },
                }}
                theme={'wandb'}
                onChange={(value: string) => saveCode && saveCode(value)}
                editorDidMount={(editor, monaco) => {
                  monaco.editor.defineTheme('wandb', {
                    base: 'vs',
                    inherit: true,
                    rules: [],
                    colors: {
                      'editor.foreground': '#000000',
                      'editor.background': '#f5f5f5',
                    },
                  });
                  editor.addCommand(
                    // tslint:disable-next-line:no-bitwise
                    monaco.KeyMod.Shift | monaco.KeyCode.Enter,
                    _ => runCode && runCode(cell.source.join(''))
                  );
                }}
                language="python"
              />
            </div>
          </div>
        )}
        {cell.cell_type === 'markdown' ? (
          <div
            className="output"
            dangerouslySetInnerHTML={{
              __html: generateHTML(cell.source.join('')).toString(),
            }}
          />
        ) : (
          <div className="output">{outputs}</div>
        )}
      </div>
    );
  },
  {id: 'JupyterCell'}
);

export const JupyterViewer: React.FC<{
  raw: string;
}> = makeComp(
  props => {
    const {raw} = props;
    const idRef = useRef(String.ID());
    useEffect(() => {
      Prism.highlightAll();
    });

    const notebook = useMemo(() => {
      try {
        const parsed = JSON.parse(raw) as NotebookV4;
        parsed.cells.forEach((cell: Cell) => {
          // Kaggle returns cell source as strings instead of arrays
          if (typeof cell.source === 'string') {
            cell.source = (cell.source as string)
              .split('\n')
              .map(s => s + '\n');
            // The final line shouldn't have a newline :(
            cell.source[cell.source.length - 1] =
              cell.source[cell.source.length - 1].trim();
          }
        });
        return parsed;
      } catch {
        return null;
      }
    }, [raw]);
    if (notebook == null) {
      return <div>Error</div>;
    }
    return (
      <div className="notebook" style={{position: 'relative'}}>
        {notebook.cells.map((cell: Cell, i: number) => {
          return (
            <JupyterCell
              readonly={true}
              cell={cell}
              id={`${idRef.current}-cell-${i}`}
              key={`${idRef.current}-cell-${i}`}
            />
          );
        })}
      </div>
    );
  },
  {id: 'JupyterViewer'}
);

export default JupyterViewerFromRun;
