import * as Normalize from '../normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.InteractStateObjSchema>(
  'interactState',
  (whole, ctx) => whole
);

export const denormalize = Normalize.denormFn<Types.InteractStateObjSchema>(
  (part, ctx) => part
);
