import * as globals from '../../../css/globals.styles';

import * as HeadingStyles from './headings.styles';
import styled, {css} from 'styled-components';
import InlineMarkdownEditor from '../../elements/InlineMarkdownEditor';
import {REPORT_FONT_SIZE} from '../constants';

export const MarkdownBlockWrapper = styled.div`
  ${HeadingStyles.HeadingCollapser}, ${HeadingStyles.DeepLink} {
    padding-top: ${REPORT_FONT_SIZE * 2.5}px;
  }
  ${HeadingStyles.commonHeadingStyles}
`;
export const MarkdownBlock = styled(InlineMarkdownEditor)<{
  selected?: boolean;
  focused?: boolean;
}>`
  white-space: normal;
  font-family: ${globals.fontName};
  .markdown {
    overflow: auto;
  }
  ${props =>
    !props.readOnly &&
    css`
      &:not(.editing) {
        transform: translateY(-8px);
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        margin-left: -8px;
        margin-right: -8px;
        padding-left: 8px;
        padding-right: 8px;
        width: calc(100% + 16px);
        border-radius: 4px;
        &:hover {
          background: ${globals.gray50};
        }
      }
      textarea {
        border: none;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none;
        background: none;
        padding: 4px 0;
      }
      &.editing {
        border-left: 4px solid ${globals.gray100};
        margin: 0 -16px 12px;
        padding: 0 16px;
        width: calc(100% + 32px);
        textarea {
          font-family: Inconsolata;
        }
      }
    `}
  ${props =>
    props.selected &&
    css`
      &&:not(.editing) {
        background: rgb(208, 225, 252);
      }
      .placeholder {
        opacity: 0;
      }
    `}
`;

export const EditMarkdownBlockWrapper = styled.div<{converted?: boolean}>`
  border-radius: 4px;
  background-color: rgb(34, 34, 34);
  padding: 8px 12px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  ${props =>
    props.converted &&
    css`
      background: green;
      cursor: default;
    `}
`;
