import _ from 'lodash';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {withApollo, WithApolloClient} from 'react-apollo';
import {Omit, Subtract} from '../../../types/base';
import * as Filter from '../../../util/filters';
import {
  Config as TableConfig,
  EMPTY_CONFIG as EMPTY_TABLE_SETTINGS,
} from '../../../util/runfeed';
import {keyToString} from '../../../util/runs';
import {DFTable, DFTableColumn, DFTableProps} from './DFTable';
import {propagateErrorsContext} from '../../../util/errors';
import makeComp from '../../../util/profiler';
import {captureError} from '../../../util/integrations';

interface DFTableQueryOwnProps {
  query: any;
  queryVariables: DataFrameQueryVariables; // variables for the top-level table query
  tableComponent?: (props: DFTableProps) => JSX.Element;
  // This function takes the top-level query variables, and transforms it to generate query variables for a single row's children
  queryInputTransform: (
    queryVariables: DataFrameQueryVariables,
    recursionDepth?: number,
    parentRow?: any
  ) => DataFrameQueryVariables;
  // This function takes the raw data from the query, and transforms it into props for DFTable
  queryOutputTransform: (
    data: DataFrameQueryOutputData
  ) => DFTableQueryShapedOutput;

  querySettingsTransform?: (
    allGroupKeys: string[],
    tableSettings: TableConfig,
    allColumns: DFTableColumn[],
    rows: any[]
  ) => TableConfig;
}
type DFTableQueryProps = WithApolloClient<
  DFTableQueryOwnProps &
    Subtract<
      DFTableProps,
      {
        columns: any;
        rows: any;
        totalRowCount: any;
      }
    >
>;

export interface DataFrameQueryVariables {
  entityName: string;
  projectName: string;
  dataFrameKeys: string[];
  limit: number;
  after?: string;
  order?: string;
  groupKeys?: string[];
  filters?: Filter.Filter | string;
  includeRows: boolean;
  includeSchema: boolean;
  updateQuery?(query: any): void;
}

// The query's output shape.
export interface DataFrameQueryOutputData {
  dataFrame: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    schema: any;
    edges: Array<{
      node: {
        row: any;
      };
    }>;
  };
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface DFTableQueryShapedOutput {
  loading?: boolean;
  rows: any[];
  columns?: DFTableColumn[];
  totalRowCount?: number;
}

interface RowState {
  loading: boolean;
  rows: any[];
  columns: DFTableColumn[];
  totalRowCount: number;
  pageParams: Partial<DataFrameQueryVariables>;
}

// There's one top-level query, and one query for each member of state.expandedRowAddresses (i.e. each expanded group).
// As queries complete, they populate the state.tableProps.rows tree structure.

const DFTableWithQueryUnwrapped: React.FC<DFTableQueryProps> = makeComp(
  props => {
    const {
      client,
      query,
      queryVariables,
      queryInputTransform,
      queryOutputTransform,
      tableComponent,
      querySettingsTransform,
      grouping,
      tableSettings,
      filters,
    } = props;
    const [expandedRowAddresses, setExpandedRowAddresses] = useState<string[]>(
      []
    );
    const [rowStates, setRowStates] = useState<{
      [key: string]: RowState | undefined;
    }>({});
    const [fetchRowDataAddress, setFetchRowDataAddress] = useState<
      string | null
    >('');

    const findRow = useCallback(
      (address: string): any => {
        const parts = address.split('-');
        const parentAddress = parts.slice(0, parts.length - 1).join('-');
        const parentState = rowStates[parentAddress];
        if (parentState && parentState.rows) {
          return parentState.rows.find(row => row.__address__ === address);
        }
        return undefined;
      },
      [rowStates]
    );

    const fetchRowData = useCallback(
      async (address: string) => {
        const rowState: RowState = rowStates[address] ?? {
          loading: false,
          rows: [],
          columns: [],
          totalRowCount: 0,
          pageParams: {},
        };
        const recursionDepth = address === '' ? 0 : address.split('-').length;
        const parentRow = findRow(address);
        const shapedVariables = queryInputTransform(
          {...queryVariables, ...rowState.pageParams},
          recursionDepth,
          parentRow
        );

        setRowStates({
          ...rowStates,
          [address]: {
            ...rowState,
            loading: true,
            pageParams: {},
          },
        });

        return await client
          .query<DataFrameQueryOutputData>({
            query,
            variables: shapedVariables,
            context: propagateErrorsContext(),
          })
          .then(rawQueryResult => {
            const {data} = rawQueryResult;
            if (data == null) {
              captureError(
                'Unexpected Apollo error, loading: false, data: undefined',
                'DFTableQuery',
                {extra: {rawQueryResult}}
              );
              throw new Error(
                'Unexpected Apollo error, loading: false, data: undefined'
              );
            }
            const shapedOutput = queryOutputTransform(data);
            const newRows = shapedOutput.rows.map((row, i) => ({
              ...row,
              __address__:
                (address ? address + '-' : '') + (i + rowState.rows.length),
              __children__: [],
            }));
            const nextRowState: RowState = {
              loading: false,
              rows: ([] as any[]).concat(rowState.rows, newRows),
              columns: address === '' ? shapedOutput.columns ?? [] : [],
              totalRowCount: shapedOutput.totalRowCount ?? 0,
              pageParams: {
                after: data.dataFrame
                  ? data.dataFrame.pageInfo.endCursor
                  : undefined,
              },
            };

            setRowStates({...rowStates, [address]: nextRowState});
          });
      },
      [
        client,
        findRow,
        query,
        queryInputTransform,
        queryOutputTransform,
        queryVariables,
        rowStates,
      ]
    );

    // open or close the row at the given address (rowAddress looks like '1-6-5-1-5')
    const toggleExpandedRow = useCallback(
      (rowAddress: string) => {
        const expanded = _.includes(expandedRowAddresses, rowAddress);
        if (expanded) {
          // Remove this row and its child rows from the expanded list and clear their child row state
          const collapseRows = expandedRowAddresses.filter(
            a => a === rowAddress || a.startsWith(rowAddress + '-')
          );
          const collapseStates: {
            [key: string]: undefined;
          } = _.fromPairs(_.zip(collapseRows, []));
          setExpandedRowAddresses(
            expandedRowAddresses.filter(a => !_.includes(collapseRows, a))
          );
          setRowStates({
            ...rowStates,
            ...collapseStates,
          });
        } else {
          setExpandedRowAddresses(expandedRowAddresses.concat([rowAddress]));
          setFetchRowDataAddress(rowAddress);
        }
      },
      [expandedRowAddresses, rowStates]
    );

    const assembleRows = useCallback(
      (parentAddress: string): any[] => {
        const rowState = rowStates[parentAddress];
        const rows: any[] = rowState ? rowState.rows : [];
        return rows.map(row => ({
          ...row,
          __children__: assembleRows(row.__address__),
        }));
      },
      [rowStates]
    );

    const assembleTableProps = useCallback((): DFTableProps => {
      const rootRowState: RowState = rowStates[''] || {
        loading: false,
        rows: [],
        columns: [],
        totalRowCount: 0,
        pageParams: {},
      };

      return {
        ...props,
        loading: rootRowState.loading,
        rows: assembleRows(''),
        columns: rootRowState.columns,
        totalRowCount: rootRowState.totalRowCount,
        expandedRowAddresses,
        loadingRowAddresses: expandedRowAddresses.filter(
          address => rowStates[address] && rowStates[address]!.loading
        ),
        tableLoadMore: () => fetchRowData(''),
      } as DFTableProps;
    }, [assembleRows, expandedRowAddresses, fetchRowData, props, rowStates]);

    const prevGroupingRef = useRef(grouping);
    const prevQueryVariablesRef = useRef(queryVariables);
    const prevFiltersRef = useRef(filters);
    useEffect(() => {
      const prevGrouping = prevGroupingRef.current;
      const prevQueryVariables = prevQueryVariablesRef.current;
      const prevFilters = prevFiltersRef.current;
      prevGroupingRef.current = grouping;
      prevQueryVariablesRef.current = queryVariables;
      prevFiltersRef.current = filters;
      // If query settings have changed, clear current data and reload root
      if (
        !_.isEqual(prevGrouping, grouping) ||
        // TODO: Removed sort here as we switch to redux based views.
        // !_.isEqual(prevProps.sort, this.props.sort) ||
        !_.isEqual(prevQueryVariables, queryVariables) ||
        !_.isEqual(prevFilters, filters)
      ) {
        setExpandedRowAddresses([]);
        setRowStates({
          '': {
            loading: true,
            rows: [],
            columns: [],
            totalRowCount: 0,
            pageParams: {},
          },
        });
        setFetchRowDataAddress('');
      }
    }, [grouping, queryVariables, filters]);

    useEffect(() => {
      if (fetchRowDataAddress == null) {
        return;
      }
      fetchRowData(fetchRowDataAddress);
      setFetchRowDataAddress(null);
    }, [fetchRowData, fetchRowDataAddress]);

    const tableProps = assembleTableProps();

    let transformedTableSettings = tableSettings ?? EMPTY_TABLE_SETTINGS;
    if (querySettingsTransform) {
      transformedTableSettings = querySettingsTransform(
        (grouping || []).map(k => keyToString(k)),
        transformedTableSettings,
        tableProps.columns,
        tableProps.rows
      );
    }

    let table = tableComponent;
    if (!table) {
      table = (passProps: DFTableProps) => <DFTable {...passProps} />;
    }
    return table({
      ...tableProps,
      tableSettings: transformedTableSettings,
      toggleExpandedRow,
    });
  },
  {id: 'DFTableWithQueryUnwrapped', memo: true}
);

export const DFTableWithQuery = withApollo(
  DFTableWithQueryUnwrapped
) as React.ComponentClass<Omit<DFTableQueryProps, 'client'>>;
