import {
  Editor,
  Range,
  Node,
  Transforms,
  Location,
  Path,
  Point,
  Text,
} from 'slate';
import {isCodeLine} from './code-blocks';
import {isHeading} from './headings';
import {isFirefox} from '../../../util/cross-browser-magic';

/* eslint-disable no-constant-condition */
import castArray from 'lodash/castArray';
import map from 'lodash/map';

// match a non-whitespace character
const NOT_WHITE_SPACE_REGEX = /\S/;

function charIsNonWhiteSpace(c: string): boolean {
  return NOT_WHITE_SPACE_REGEX.test(c);
}

export const getText = (editor: Editor, at?: Location | null) =>
  (at && Editor.string(editor, at)) ?? '';

export interface BeforeOptions {
  distance?: number | undefined;
  unit?: 'character' | 'word' | 'line' | 'block' | 'offset' | undefined;
}

export interface PointBeforeOptions extends BeforeOptions {
  /**
   * Lookup before the location for `matchString`.
   */
  matchString?: string | string[];

  /**
   * Lookup before the location until this predicate is true
   */
  match?: (value: {
    beforeString: string;
    beforePoint: Point;
    at: Location;
  }) => boolean;

  /**
   * If true, get the point after the matching point.
   * If false, get the matching point.
   */
  afterMatch?: boolean;

  /**
   * If true, lookup until the start of the editor value.
   * If false, lookup until the first invalid character.
   */
  skipInvalid?: boolean;

  /**
   * Allow lookup across multiple node paths.
   */
  multiPaths?: boolean;
}

/**
 * {@link Editor.before} with additional options.
 * TODO: support for sequence of any characters.
 */
export const getPointBefore = (
  editor: Editor,
  at: Location,
  options?: PointBeforeOptions
) => {
  if (!options || (!options.match && !options.matchString)) {
    return Editor.before(editor, at, options);
  }

  let beforeAt = at;
  let previousBeforePoint = Editor.point(editor, at, {edge: 'end'});

  const stackLength = (options.matchString?.length || 0) + 1;
  const stack = Array(stackLength);

  const unitOffset = !options.unit || options.unit === 'offset';

  let count = 0;
  while (true) {
    const beforePoint = Editor.before(editor, beforeAt, options);

    // not found
    if (!beforePoint) {
      return;
    }

    // different path
    if (
      !options.multiPaths &&
      !Path.equals(beforePoint.path, previousBeforePoint.path)
    ) {
      return;
    }

    const beforeString = Editor.string(editor, {
      anchor: beforePoint,
      focus: previousBeforePoint,
    });

    const matchString: string[] = castArray(options.matchString);

    let beforeStringToMatch = beforeString;

    if (unitOffset && stackLength) {
      stack.unshift({
        point: beforePoint,
        text: beforeString,
      });
      stack.pop();

      beforeStringToMatch = map(stack.slice(0, -1), 'text').join('');
    }

    if (
      matchString.includes(beforeStringToMatch) ||
      options.match?.({beforeString: beforeStringToMatch, beforePoint, at})
    ) {
      if (options.afterMatch) {
        if (stackLength && unitOffset) {
          return stack[stack.length - 1]?.point;
        }
        return previousBeforePoint;
      }
      return beforePoint;
    }

    previousBeforePoint = beforePoint;
    beforeAt = beforePoint;

    count += 1;

    if (!options.skipInvalid) {
      if (!matchString || count > matchString.length) {
        return;
      }
    }
  }
};

/**
 * Remove mark and trigger `onChange` if collapsed selection.
 * Copy+pasted from https://github.com/udecode/slate-plugins/blob/c4b39a62ad4ff206402189374a8610995a90de25/packages/common/src/transforms/removeMark.ts#L7-L31
 * PR: https://github.com/wandb/core/pull/7230
 */
export const removeMark = (
  editor: Editor,
  {
    key,
    shouldChange = true,
  }: {
    key: string;
    shouldChange?: boolean;
  }
) => {
  const {selection} = editor;
  if (selection) {
    if (Range.isExpanded(selection)) {
      Transforms.unsetNodes(editor, key, {
        match: Text.isText,
        split: true,
      });
    } else {
      const marks = {...(Editor.marks(editor) || {})};
      delete marks[key];
      editor.marks = marks;
      if (shouldChange) {
        editor.onChange();
      }
    }
  }
};

// Copy+pasted from https://github.com/udecode/slate-plugins/blob/main/packages/autoformat/src/transforms/autoformatInline.ts
export const autoformatInline = (
  editor: Editor,
  {
    type,
    between,
    markup,
    ignoreTrim,
  }: {
    type: string;
    between?: string[];
    markup?: string;
    ignoreTrim?: boolean;
  }
) => {
  const selection = editor.selection as Range;

  const startMarkup = between ? between[0] : markup;
  const endMarkup = between ? between[1] : '';

  let endMarkupPointBefore = selection.anchor;
  if (endMarkup) {
    endMarkupPointBefore = getPointBefore(editor, selection, {
      matchString: endMarkup,
    });
    if (!endMarkupPointBefore) {
      return false;
    }
  }

  const startMarkupPointBefore = getPointBefore(editor, endMarkupPointBefore, {
    matchString: startMarkup,
    skipInvalid: true,
  });
  const startMarkupPointAfter = getPointBefore(editor, endMarkupPointBefore, {
    matchString: startMarkup,
    skipInvalid: true,
    afterMatch: true,
  });

  if (!startMarkupPointAfter) {
    return false;
  }

  // check if the character before markup is non-white-space character.
  // if so, we don't markup. e.g. "one_two_three" stays without italicizing "two"
  const beforeStartPoint = Editor.before(editor, startMarkupPointBefore);

  if (beforeStartPoint) {
    const beforeCharRange = {
      anchor: beforeStartPoint as Point,
      focus: startMarkupPointBefore,
    };
    const charBeforeMarkup = Editor.string(editor, beforeCharRange);
    if (charIsNonWhiteSpace(charBeforeMarkup)) {
      return false;
    }
  }

  // found

  const markupRange: Range = {
    anchor: startMarkupPointAfter,
    focus: endMarkupPointBefore,
  };

  if (!ignoreTrim) {
    const markupText = getText(editor, markupRange);
    if (markupText.trim() !== markupText) {
      return false;
    }
  }

  // delete end markup
  if (endMarkup) {
    endMarkupPointBefore = getPointBefore(editor, selection, {
      matchString: endMarkup,
    });
    Transforms.delete(editor, {
      at: {
        anchor: endMarkupPointBefore,
        focus: selection.anchor,
      },
    });
  }

  // add mark to the text between the markups
  Transforms.select(editor, markupRange);
  editor.addMark(type, true);
  Transforms.collapse(editor, {edge: 'end'});
  removeMark(editor, {key: type, shouldChange: false});

  // delete start markup
  if (!isFirefox) {
    Transforms.delete(editor, {
      at: {
        anchor: startMarkupPointBefore,
        focus: startMarkupPointAfter,
      },
    });
  }

  return true;
};

const SHORTCUTS: {[alias: string]: Partial<Node>} = {
  '* ': {type: 'list-item'},
  '- ': {type: 'list-item'},
  '1. ': {type: 'list-item', ordered: true},
  '[] ': {type: 'list-item', checked: false},
  '> ': {type: 'block-quote'},
  '| ': {type: 'block-quote'},
  '# ': {type: 'heading', level: 1, collapsedChildren: undefined},
  '## ': {type: 'heading', level: 2, collapsedChildren: undefined},
  '### ': {type: 'heading', level: 3, collapsedChildren: undefined},
  '```': {type: 'code-line'},
  '>>> ': {type: 'callout-line'},
  '---': {type: 'horizontal-rule'},
  ___: {type: 'horizontal-rule'},
};

export const withMarkdownShortcuts = <T extends Editor>(editor: T) => {
  const {insertText} = editor;

  editor.insertText = text => {
    const {selection} = editor;

    const blockEntry = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n),
    });
    const block = blockEntry ? blockEntry[0] : null;
    const acceptShortcuts = block && !isCodeLine(block);

    if (!acceptShortcuts) {
      insertText(text);
      return;
    }

    if (
      block &&
      (text === ' ' || text === '`' || text === '-' || text === '_') &&
      selection &&
      Range.isCollapsed(selection)
    ) {
      const {anchor} = selection;

      const path = blockEntry ? blockEntry[1] : [];
      const start = Editor.start(editor, path);
      const range = {anchor, focus: start};
      const beforeText = Editor.string(editor, range);
      const nodeVals = SHORTCUTS[beforeText + text];

      if (nodeVals) {
        if (
          nodeVals.type === 'list-item' &&
          nodeVals.ordered &&
          isHeading(block!)
        ) {
          // special case numbered list in heading
          insertText(text);
          return;
        }

        Transforms.select(editor, range);
        Transforms.delete(editor);
        Transforms.setNodes(editor, nodeVals, {
          match: n => Editor.isBlock(editor, n),
        });

        return;
      }
    }

    insertText(text);

    // these need to be after insertText

    autoformatInline(editor, {type: 'inlineCode', between: ['`', '`']});
    // This isn't correct Markdown; real Markdown treats both _ and * as emphasis,
    // and both __ and ** as strong.
    // But I was having problems with real markdown because **example* would
    // convert to emphasis before I could add the second *.
    // So I said screw it. This pattern is better than "correct" markdown anyway.
    autoformatInline(editor, {type: 'strong', between: ['*', '*']});
    autoformatInline(editor, {type: 'emphasis', between: ['_', '_']});
  };

  return editor;
};
