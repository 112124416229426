/* This is our csv export modal.

This component will generate a csv for any RunsDataQuery. We allow passing
a panel config in because so we can do special post-processing for specific
panel types. This should be refactored so that it doesn't need to know anything
about panels themselves.
*/
import * as S from './WBModal.styles';
import {useCallback, useState} from 'react';

import * as _ from 'lodash';
import * as React from 'react';
import {Loader, StrictButtonProps} from 'semantic-ui-react';

import {RunsDataQuery, RunsData} from '../containers/RunsDataLoader';
import * as Types from '../state/runs/types';
import {captureError} from '../util/integrations';
import * as Panels from '../util/panels';
import * as QueryTS from '../util/queryts';
import * as Run from '../util/runs';
import {X_AXIS_LABELS} from './PanelRunsLinePlot';
import {saveTableAsCSV, Table, TableRow} from '../util/csv';
import {useTableData} from '../util/panels';
import {useRunsData} from '../state/runs/hooks';
import makeComp from '../util/profiler';
import {PCConfig} from './PanelParallelCoord';
import {parseLegendTemplate} from '../util/legend';

const ROW_ID_COLUMN = 'Name';

type ExportProps = {
  pageQuery: QueryTS.Query;
  panel: Panels.LayedOutPanel;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
};

const Export = makeComp(
  (props: ExportProps) => {
    const {trigger, open, onClose} = props;

    const [modalOpen, setModalOpen] = useState(false);

    const openModal = useCallback(() => setModalOpen(true), []);
    const closeModal = useCallback(() => {
      setModalOpen(false);
      onClose?.();
    }, [onClose]);

    return (
      <S.WBModal
        width="1000px"
        height="785px"
        trigger={trigger}
        open={open}
        onOpen={openModal}
        onClose={closeModal}>
        <S.WBModalHeader>Export Preview</S.WBModalHeader>
        <S.WBModalContent>
          {/* we only render the content if the modal is actually open, since
            the ExportModalContent component actually does the query*/}
          {modalOpen || open ? (
            <ExportModalContent {...props} onClose={closeModal} />
          ) : (
            <div />
          )}
        </S.WBModalContent>
      </S.WBModal>
    );
  },
  {id: 'Export'}
);

type ExportContentProps = ExportProps & {
  onClose(): void;
};

const ExportModalContent = makeComp(
  (props: ExportContentProps) => {
    const {pageQuery, panel, onClose} = props;
    const result = useTableData(pageQuery, panel);

    return result.loading ? (
      <Loader />
    ) : (
      <>
        <S.WBModalTable
          noSearch
          columns={result.table.cols.map(c => ({
            id: c,
            Header: c,
            accessor: r => r[c],
          }))}
          data={result.table.data.map(r => ({searchString: '', row: r}))}
          pageSize={20}
        />
        <S.WBModalButton
          primary
          floated="right"
          content="Save as CSV"
          onClick={
            (e => {
              window.analytics.track('Export panel', {
                viewType: props.panel.viewType,
                downloadType: 'csv',
              });
              e.stopPropagation();
              saveTableAsCSV(result.table);
              onClose();
            }) as StrictButtonProps['onClick']
          }
        />
      </>
    );
  },
  {id: 'ExportModalContent'}
);

export default Export;

export function queryToTable(
  data: RunsData,
  query: RunsDataQuery,
  pageQuery: QueryTS.Query,
  config: Panels.PanelConfig
): Table {
  const isHistoryQuery =
    query.historySpecs != null && query.historySpecs.length > 0;
  const toTable = isHistoryQuery ? historyQueryToTable : runsQueryToTable;
  return toTable(data, pageQuery, config);
}

function runsQueryToTable(
  data: RunsData,
  pageQuery: QueryTS.Query,
  config: Panels.PanelConfig | {columnAccessors: string[]}
): Table {
  const runs = data.filtered;

  let chartCols: string[];

  if ('columnAccessors' in config) {
    chartCols = config.columnAccessors;
  } else if ('columns' in config) {
    // Parallel Coordinates panel is a special case because its query returns
    // metrics that it does not intend to display. In this case, we have to pull
    // the displayed columns from its config.
    if ((config as PCConfig).columns == null) {
      throw new Error(
        'Parallel Coordinates panel with `config.columns == null`'
      );
    }
    chartCols = ((config as PCConfig).columns || []).map(
      ({accessor}) => accessor || ''
    );
  } else {
    const extractKeyStrings = (
      section: Run.RunKeySection,
      getProperty: (r: Types.RunWithRunsetInfo) => Run.KeyVal
    ) =>
      _.union(
        ...runs.map(r =>
          _.keys(getProperty(r)).map(k => Run.keyString(section, k))
        )
      );
    chartCols = [
      ...extractKeyStrings('config', r => r.config),
      ...extractKeyStrings('summary', r => r.summary),
    ];
  }

  // Scatter Plot panel is a special case because it has createdAt and heartbeatAt
  // as possible axis values. These properties are always returned in the query results.
  // We should only export them if they're configured as one of the axis values.
  if ('xAxis' in config && 'yAxis' in config) {
    const {xAxis, yAxis, zAxis} = config;
    chartCols = [..._.compact([xAxis, yAxis, zAxis]), ...chartCols];
  }

  const isTags = (col: string) => Run.keyFromString(col)?.section === 'tags';

  const cols = _.uniq([
    ROW_ID_COLUMN,
    ...chartCols.map(Run.keyStringDisplayName),
  ]);
  const colData = runs.map(r => {
    const row: {[k: string]: string} = {};
    row[ROW_ID_COLUMN] = getRowID(pageQuery, r);
    chartCols.forEach(c => {
      const k = Run.keyStringDisplayName(c);
      const v = isTags(c)
        ? Run.getTagsString(r)
        : stringifyValue(Run.getValueFromKeyString(r, c));
      if (!row[k]) {
        row[k] = v;
      }
    });
    return row;
  });

  return {cols, data: colData};
}

// The client-side grouping logic is currently duplicated from PanelRunsLinePlot.getLinesFromData.
// When updating the grouping logic in either place, make sure the other stays in sync.
function historyQueryToTable(
  data: RunsData,
  pageQuery: QueryTS.Query,
  config: Panels.PanelConfig
): Table {
  const runs = data.filtered;

  let panelGroupKey: Run.Key | null;
  if ('aggregate' in config && config.aggregate) {
    const panelGroupBy = config.groupBy;
    if (panelGroupBy == null || panelGroupBy === 'None') {
      panelGroupKey = Run.GROUP_BY_ALL_KEY;
    } else if (panelGroupBy != null) {
      panelGroupKey = Run.configKey(panelGroupBy);
      if (panelGroupKey == null) {
        throw new Error('invalid key');
      }
    }
  }

  const stepKey = ('xAxis' in config && config.xAxis) || '_step';
  const stepKeyLabel = X_AXIS_LABELS[stepKey] || stepKey;
  const legendTemplate =
    'legendTemplate' in config ? config.legendTemplate : null;
  const runSetIndexByDataKey: {[dataKey: string]: number} = {};
  const dataByStep: {[step: number]: TableRow} = {};
  data.histories.data.forEach(({history}, i) => {
    const run = runs[i];
    const runSetIndex =
      pageQuery.runSets &&
      pageQuery.runSets.findIndex(({id}) => id === run.runsetInfo.id);
    if (runSetIndex == null) {
      throw new Error('runSetIndex not found');
    }

    const singleKey = !history.some(h => _.entries(h).length > 1);
    history.forEach(h => {
      const step: number = h[stepKey];
      dataByStep[step] = dataByStep[step] || {[stepKeyLabel]: step};
      let historyKeyVal = _.entries(h);

      if (!singleKey) {
        historyKeyVal = historyKeyVal.filter(([k]) => k !== stepKey);
      }

      historyKeyVal.forEach(([k, v]) => {
        const rowID = getRowID(pageQuery, run, panelGroupKey);
        const key =
          legendTemplate != null
            ? parseLegendTemplate(
                legendTemplate,
                false,
                run,
                panelGroupKey != null ? [panelGroupKey] : [],
                k
              )
            : `${rowID} - ${k}`;
        runSetIndexByDataKey[key] = runSetIndex;
        dataByStep[step][key] = dataByStep[step][key] || [];
        dataByStep[step][key].push(v);
      });
    });
  });

  const steps = _.keys(dataByStep).sort((a, b) => Number(a) - Number(b));
  const dataKeys = _.keys(runSetIndexByDataKey).sort(
    (a, b) => runSetIndexByDataKey[a] - runSetIndexByDataKey[b]
  );

  // Values under the same key are grouped so we display the mean of the values
  const meanDataByStep: {[step: string]: TableRow} = {};
  const minDataByCol: {[col: string]: {[step: string]: any}} = {};
  const maxDataByCol: {[col: string]: {[step: string]: any}} = {};
  _.entries(dataByStep).forEach(([step, stepData]) => {
    meanDataByStep[step] = {};
    _.entries(stepData).forEach(([k, v]) => {
      const arr = _.isArray(v);
      let val;
      if (arr && typeof v[0] === 'number') {
        val = getMean(v);
        minDataByCol[k] = minDataByCol[k] ?? {};
        maxDataByCol[k] = maxDataByCol[k] ?? {};
        minDataByCol[k][step] = Math.min(...v);
        maxDataByCol[k][step] = Math.max(...v);
      } else if (arr && v.length === 1) {
        val = stringifyValue(v[0]);
      } else {
        val = stringifyValue(v);
      }
      meanDataByStep[step][k] = val;
    });
  });

  const tableCols: string[] = [stepKeyLabel];
  dataKeys.forEach(k => {
    tableCols.push(k);
    if (minDataByCol[k] != null) {
      tableCols.push(`${k}__MIN`);
    }
    if (maxDataByCol[k] != null) {
      tableCols.push(`${k}__MAX`);
    }
  });
  const tableData: TableRow[] = steps.map(s => {
    const row: TableRow = {};
    [stepKeyLabel, ...dataKeys].forEach(
      k => (row[k] = stringifyValue(meanDataByStep[s][k]))
    );
    Object.keys(minDataByCol).forEach(
      k => (row[`${k}__MIN`] = stringifyValue(minDataByCol[k][s]))
    );
    Object.keys(maxDataByCol).forEach(
      k => (row[`${k}__MAX`] = stringifyValue(maxDataByCol[k][s]))
    );
    return row;
  });

  return {
    cols: tableCols,
    data: tableData,
  };
}

function getGroupKeyForRun(
  pageQuery: QueryTS.Query,
  r: Types.RunWithRunsetInfo
) {
  if (pageQuery.runSets == null) {
    return null;
  }
  const rs = pageQuery.runSets.find(({id}) => id === r.runsetInfo.id);
  if (
    rs != null &&
    rs.enabled &&
    rs.grouping != null &&
    rs.grouping.length > 0
  ) {
    return rs.grouping[0];
  }
  return null;
}

function getRowID(
  pageQuery: QueryTS.Query,
  r: Types.RunWithRunsetInfo,
  groupKey?: Run.Key | null
) {
  groupKey = groupKey || getGroupKeyForRun(pageQuery, r);
  const rowKey = getMaybeGroupedRunName(r, groupKey);
  return appendRunSetName(pageQuery, r, rowKey);
}

function appendRunSetName(
  pageQuery: QueryTS.Query,
  r: Types.RunWithRunsetInfo,
  str: string
) {
  if (pageQuery.runSets != null && pageQuery.runSets.length > 1) {
    return str + ` (${r.runsetInfo.name})`;
  }
  return str;
}

function getMaybeGroupedRunName(
  r: Types.RunWithRunsetInfo,
  groupKey?: Run.Key | null
) {
  if (groupKey == null) {
    return r.displayName;
  }
  if (_.isEqual(groupKey, Run.GROUP_BY_ALL_KEY)) {
    return Run.keyDisplayName(groupKey);
  }
  return `${Run.keyDisplayName(groupKey)}: ${
    Run.getValue(r, groupKey) || r.displayName
  }`;
}

function getMean(vs: number[]): number {
  const sum = vs.reduce((acc, x) => acc + x);
  return sum / vs.length;
}

const capturedErrMsgs: {[message: string]: boolean} = {};
export function captureUniqueError(err: Error) {
  if (capturedErrMsgs[err.message]) {
    return;
  }
  captureError(`[Panel Export Error] ${err.message}`, 'panelExport', {
    extra: {stack: err.stack},
  });
  capturedErrMsgs[err.message] = true;
}

function stringifyValue(v: any): string {
  if (v == null) {
    return '';
  }
  return _.isObject(v) ? JSON.stringify(v) : String(v);
}

export function sampleQueryHistorySpecs(query: RunsDataQuery) {
  if (query.historySpecs == null) {
    return query;
  }
  const sampledQuery = {
    ...query,
    historySpecs: query.historySpecs?.map(({keys}) => ({
      keys,
      samples: Number.MAX_SAFE_INTEGER,
    })),
  };
  return sampledQuery;
}

export function useSampleAndQueryToTable(
  query: RunsDataQuery,
  pageQuery: QueryTS.Query,
  config: Panels.PanelConfig
) {
  query = sampleQueryHistorySpecs(query);
  const runsData = useRunsData(query);

  let table: Table = {cols: [], data: []};
  try {
    table = queryToTable(runsData.data, query, pageQuery, config);
  } catch (e) {
    captureUniqueError(e);
  }

  return {table, loading: runsData.loading};
}
