// Implements the entity query for the entity page. See projectQuery.tsx for a description
// of motivation for this design.
import gql from 'graphql-tag';
import * as React from 'react';
import {Query} from 'react-apollo';
import {ProfilePageData, profilePageFragment} from '../components/ProfilePage';
import {TeamPageData, teamPageFragment} from '../pages/TeamPage';
import makeComp from '../util/profiler';

///// Simple type helpers
// Type T minus the single key K
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// Type T minus all keys in K
type Subtract<T, K> = Omit<T, keyof K>;

///// Query definition

export const ENTITY_QUERY = gql`
  query EntityPage($entityName: String!, $userStatsStartDate: DateTime!) {
    entity(name: $entityName) {
      ...TeamPageFragment
    }
    user(userName: $entityName) {
      ...ProfilePageFragment
    }
    viewer(entityName: $entityName) {
      username
      admin
    }
  }
  ${teamPageFragment}
  ${profilePageFragment}
`;

// These types define the inputs and outputs of the above gql query

// Any variables required by the query.
interface Variables {
  entityName: string;
  userStatsStartDate: string;
}

// interface UserData {
//   id: string;
//   username: string;
//   defaultEntity: {
//     name: string;
//   };
// }

// The query's output shape.
interface Data {
  entity: TeamPageData;
  user: ProfilePageData;
  projects: any;
  viewer: {
    username: string;
  };
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  match: {
    params: {
      entityName: string;
    };
  };
}

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  entity: undefined;
  user: undefined;
  viewer: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  entity?: TeamPageData;
  user?: ProfilePageData;
  projects: any;
  viewer?: {
    username: string;
  };
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface EntityPageQueryResultProps {
  entityPageQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withEntityPageQuery = <P extends object>(
  Component: React.ComponentType<P & EntityPageQueryResultProps>
) =>
  makeComp(
    (inputProps: Subtract<P, EntityPageQueryResultProps> & InputProps) => {
      const {match} = inputProps;
      // We truncate userStatsStartDate to the nearest day, so that we don't make a
      // new request everytime this component rerenders.
      const startDateTime = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
      const startDate = new Date(
        startDateTime.getFullYear(),
        startDateTime.getMonth(),
        startDateTime.getDate()
      );
      const variables = {
        entityName: match.params.entityName,
        userStatsStartDate: startDate.toISOString(),
      };
      return (
        <Query<Data, Variables> query={ENTITY_QUERY} variables={variables}>
          {rawQueryResult => {
            const r: EntityPageQueryResultProps = {
              entityPageQuery: {
                loading: true,
                user: undefined,
                entity: undefined,
                viewer: undefined,
              },
            };
            if (!rawQueryResult.loading) {
              if (rawQueryResult.data != null) {
                r.entityPageQuery = {
                  loading: false,
                  user: rawQueryResult.data.user,
                  entity: rawQueryResult.data.entity,
                  projects: rawQueryResult.data.projects,
                  viewer: rawQueryResult.data.viewer,
                };
              }
            }
            return <Component {...(inputProps as P)} {...r} />;
          }}
        </Query>
      );
    },
    {id: 'withEntityPageQuery'}
  );
