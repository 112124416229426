// Implements a gql query on project, currently used by the Group
// page. This is a good example of how to create an HOC that does an
// Apollo query. apollo-client's graphql HOC doesn't allow passing
// through variables in a Typesafe way, as far as I can tell, so we resort
// to creating our own HOC's. We could actually generalize this into a
// Typesafe variant of the graphql() function, but it seems reasonble
// to just copy this file and modify it for new queries, rather than
// trying to abstract even more.
import * as _ from 'lodash';
import gql from 'graphql-tag';
import * as Generated from '../../generated/graphql';
import {fragments} from '../../graphql/views';
import {useQuery} from './query';

///// Query definition

export const QUERY = gql`
  query Vega2Panel(
    $projectName: String!
    $entityName: String
    $id: ID!
    $fetchSingle: Boolean!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      allViews(viewType: "vega2-panel") {
        edges {
          node {
            ...ViewFragment
          }
        }
      }
    }
    view(id: $id, type: "vega2-panel") @include(if: $fetchSingle) {
      ...ViewFragment
    }
  }
  ${fragments.view}
`;

// These types define the inputs and outputs of the above gql query

export interface View {
  id: string;
  name: string;
  description: string;
  user: {
    username: string;
    photoUrl: string;
  };
  project: {
    name: string;
  };
  spec: string;
  updatedAt: string;
}

interface Data {
  project: {
    allViews: {
      edges: Array<{node: View}>;
    };
  };
  view?: View;
}

interface Params {
  entityName: string;
  projectName: string;
  viewId?: string;
}

export function useVegaPanelQuery(params: Params) {
  const {entityName, projectName} = params;
  let {viewId} = params;
  if (viewId && viewId.startsWith('builtin:')) {
    viewId = undefined;
  } else if (viewId && viewId.startsWith('lib:')) {
    viewId = viewId.split(':', 2)[1];
  }
  const variables = {
    entityName,
    projectName,
    id: viewId || '',
    fetchSingle: viewId != null,
  };
  const query = useQuery<Data, Generated.VegaPanelQueryVariables>(QUERY, {
    variables,
  });

  if (query.loading || query.initialLoading) {
    return query;
  }

  const {project, view} = query;
  const views = project.allViews.edges.map(e => e.node);
  if (view != null && _.find(views, v => v.id === view.id) == null) {
    views.push(view);
  }

  return {
    ...query,
    project: undefined,
    view: undefined,
    views,
  };
}
