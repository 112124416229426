import styled from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';
import {gray700} from '../css/globals.styles';

export const Wrapper = styled.div`
  .routed-tab .ui.tabular.menu {
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 24px;
    border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
    overflow: visible;
    .item {
      border: none;
    }
    .item.active {
      border: 1px solid ${GLOBAL_COLORS.outline.toString()};
      border-bottom: none;
      background: ${GLOBAL_COLORS.background.toString()};
      margin-bottom: -1px;
      padding-bottom: 12px;
    }
  }
`;

export const InviteForm = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;

  &--email.ui.input {
    flex: 1;
    & > input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &--account-type.ui.dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    box-shadow: none;
  }
`;

export const AvatarPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 64px;
    color: ${gray700};
  }
`;
