import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {gray500, gray100, blue, white} from '../css/globals.styles';
import {Button, Checkbox} from 'semantic-ui-react';

export const MainOptionDescription = styled.div`
  font-size: 16px;
  color: gray;
  margin-left: 56px;
  padding-top: 8px;
`;

export const MainOption = styled.div`
  margin-bottom: 24px;
`;

export const SubOptionAlert = styled.div`
  background-color: ${gray100};
  padding: 16px;
`;

export const SubOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 32px;
  margin-top: 12px;
  cursor: pointer;
`;

export const SubOptionRadio = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  width: 18px;
  height: 18px;
  border: 1px solid ${gray500};
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 12px;

  ${props =>
    props.active &&
    css`
      background-color: ${white};
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        background-color: ${blue};
      }
    `}
  ${props =>
    props.disabled &&
    css`
      background-color: ${gray500};
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        background-color: ${gray500};
      }
    `}
`;

export const SubOptionDescription = styled.div`
  color: ${globals.gray500};
  font-size: 16px;
  margin-top: 6px;
  margin-left: 63px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-item: center;
`;

export const WBModalButton = styled(Button)`
  &&& {
    height: 40px;
    margin: 0 0 0 16px !important;
  }
`;

export const OptionCheckbox = styled(Checkbox)`
  margin-top: 16px;
`;
