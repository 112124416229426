/* Just used for passing args to the Create Sweep page for now */
import {ActionType as TSActionType, getType} from 'typesafe-actions';
import {immerReducer} from '../reducer';
import * as Actions from './actions';
import {BoundingBox2D} from '../../types/media';
import * as _ from 'lodash';

export interface StateType {
  boxData: {
    [panelID: string]: {
      [mediaKey: string]: {[mediaID: string]: BoundingBox2D[]};
    };
  };
}

export type ActionType = TSActionType<typeof Actions>;

const id = (payload: ActionType['payload']) => {
  return [payload.panelID, payload.mediaKey, payload.mediaID];
};

function mediaReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.loadBoxData):
      _.setWith(
        state.boxData,
        id(action.payload),
        action.payload.boxData,
        Object
      );
      break;
    case getType(Actions.clearBoxData):
      _.unset(state.boxData, id(action.payload));
      // Remove top level objects if they are empty after removing children
      if (
        _.isEmpty(state.boxData[action.payload.panelID][action.payload.mediaID])
      ) {
        _.unset(state.boxData, [
          action.payload.panelID,
          action.payload.mediaID,
        ]);
      }
      if (_.isEmpty(state.boxData[action.payload.panelID])) {
        _.unset(state.boxData, action.payload.panelID);
      }
      break;
  }
}

export default immerReducer<StateType, ActionType>(mediaReducer, {
  boxData: {},
});
