import {RunHistoryKeyInfo, RunKeyInfo} from '../../types/run';
import {RunHistoryRow} from '../../types/run';
import * as QueryTS from '../../util/queryts';
import * as Run from '../../util/runs';

type HistorySpec = {
  keys: string[];
  samples: number;
};

// This is the user query interface. This has a lot of legacy stuff in it.
// We should switch to using the Query from runs-low when we can.
export interface Query {
  entityName: string;
  projectName: string;
  runName?: string;
  displayName?: string;
  keysLoading: boolean;
  queries: QueryTS.SingleQuery[];
  disabled?: boolean;
  pollInterval?: number;
  // level?: 'run' | 'subgroup' | 'group';
  history?: boolean;
  historyKeyInfo?: boolean;
  historyFields?: string[];
  historySamples?: number;
  historySpecs?: HistorySpec[];
  page?: {
    size: number;
  };
  select?: string;
  fullConfig?: boolean;
  configKeys?: string[];
  fullSummary?: boolean;
  summaryKeys?: string[];
  wandbKeys?: string[];
}

export type RunWithRunsetInfo = Run.Run & {
  runsetInfo: {
    id: string;
    name?: string;
  };
};

export function isRunWithRunsetInfo(r: Run.Run): r is RunWithRunsetInfo {
  return (
    'runsetInfo' in r &&
    typeof (r as any).runsetInfo?.id === 'string' &&
    ['string', 'undefined'].indexOf(typeof (r as any).runsetInfo?.name) !== -1
  );
}

// Data holds results.
export interface Data {
  initialLoading: boolean;
  loading: boolean;
  entityName: string;
  projectName: string;
  runName?: string;
  displayName?: string;
  filtered: RunWithRunsetInfo[];
  filteredRunsById: {[key: string]: RunWithRunsetInfo};
  keyInfo: RunKeyInfo;
  totalRuns: number;
  limit?: number;
  histories: {
    keyInfo: RunHistoryKeyInfo;
    data: Array<{
      keyInfo: RunHistoryKeyInfo;
      name: string;
      displayName: string;
      history: RunHistoryRow[];
    }>;
  };
  doRunSetGrouping?: boolean;
  loadMore?(): void;
}
