import * as S from './EditableTable.styles';

import React, {PropsWithChildren, useCallback, useState, useMemo} from 'react';
import {Column} from './EditableTable';
import makeComp from '../util/profiler';

const PAGE_SIZES = [10, 20, 30, 40, 50] as const;
type PageSize = typeof PAGE_SIZES[number];

type PaginatedTableProps<T extends {id: string}> = {
  rows: T[];
  columns: Array<Column<T>>;
};

const PaginatedTable = makeComp(
  <T extends {id: string}>({
    rows,
    columns,
  }: PropsWithChildren<PaginatedTableProps<T>>) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<PageSize>(20);

    const pages: T[][] = useMemo(() => {
      if (rows.length === 0) {
        return [[]];
      }
      const res: T[][] = [];
      for (let i = 0; i < rows.length; i += pageSize) {
        res.push(rows.slice(i, i + pageSize));
      }
      return res;
    }, [rows, pageSize]);

    // Use this func to change page size, it ensures that the page is reset to 0.
    // We don't want any out of bounds issues.
    const changePageSize = useCallback((ps: PageSize) => {
      setPage(0);
      setPageSize(ps);
    }, []);

    // page navigation
    const toPrevPage = useCallback(() => {
      setPage(prev => (prev > 0 ? prev - 1 : prev));
    }, []);
    const toNextPage = useCallback(() => {
      setPage(prev => (prev < pages.length - 1 ? prev + 1 : prev));
    }, [pages]);

    const firstRowIndex = page * pageSize + 1;
    const lastRowIndex = page * pageSize + pages[page].length;

    const renderPageControls = (
      <S.Row>
        <S.PageRow>
          <S.PageRowArrow hide={page === 0} onClick={toPrevPage}>
            {'<'}
          </S.PageRowArrow>
          {`${firstRowIndex}-${lastRowIndex}`}
          <S.PageRowDropdown>
            <S.PageRowDropdown.Menu>
              {PAGE_SIZES.map(ps => (
                <S.PageRowDropdown.Item
                  key={ps}
                  active={ps === pageSize}
                  text={ps}
                  onClick={() => changePageSize(ps)}
                />
              ))}
            </S.PageRowDropdown.Menu>
          </S.PageRowDropdown>
          {` of ${rows.length}`}
          <S.PageRowArrow hide={page === pages.length - 1} onClick={toNextPage}>
            {'>'}
          </S.PageRowArrow>
        </S.PageRow>
      </S.Row>
    );

    return (
      <S.Table>
        {renderPageControls}
        <S.Row header>
          {columns.map(({name, width}) => (
            <S.Cell key={name} width={width}>
              {name}
            </S.Cell>
          ))}
        </S.Row>
        {pages[page].map((r, i) => (
          <S.Row key={r.id}>
            {columns.map(({name, width, renderCell}) => (
              <S.Cell key={name} width={width}>
                {renderCell(r, i)}
              </S.Cell>
            ))}
          </S.Row>
        ))}
        {renderPageControls}
      </S.Table>
    );
  },
  {id: 'PaginatedTable', memo: true}
);

export default PaginatedTable;
