import styled from 'styled-components';
import {WBIcon} from '@wandb/ui';

export const RemoveFromOrgSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RemoveFromOrgContent = styled.p`
  padding-left: 8px;
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const DeleteButton = styled(WBIcon)`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
