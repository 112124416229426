import '../../css/Views/ViewBar.less';

import React, {FC, useCallback} from 'react';
import {Route} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {Button, Icon, Image, Popup} from 'semantic-ui-react';

import {useDispatch, useSelector} from '../../state/hooks';
import * as ViewerHooks from '../../state/viewer/hooks';
import * as ViewActions from '../../state/views/actions';
import {useWorkspaceAction} from '../../state/workspaces/hooks';
import * as WorkspaceActions from '../../state/workspaces/actions';
import * as WorkspaceUtil from '../../state/workspaces/util';

import LegacyWBIcon from '../elements/LegacyWBIcon';
import {SignupButton} from '../LoginButton';
import ViewMenu from './ViewMenu';
import makeComp from '../../util/profiler';

export const LOGGED_OUT_USER_ID = 'ANONYMOUS';

interface ViewBarProps {
  entityName: string;
  projectName: string;

  workspaceID: string;

  extraActions?: React.ReactNode;
  readOnly?: boolean;
  isRun?: boolean;
}

type AllViewBarProps = ViewBarProps & ReturnType<typeof useViewBarProps>;

const AnonymousBar: FC = makeComp(
  () => {
    return (
      <div className="view-bar others-view">
        <span
          style={{marginRight: 'auto', marginLeft: 'auto'}}
          className="view-bar__middle">
          <span style={{marginRight: 16, color: 'white'}}>
            Save your changes with a free W&B account.
          </span>
          <SignupButton />
        </span>
      </div>
    );
  },
  {id: 'AnonymousBar', memo: true}
);

interface ReadOnlyBarProps {
  entityName: string;
  projectName: string;
  cantUndo: boolean;
  cantRedo: boolean;
}

const ReadOnlyBar: FC<ReadOnlyBarProps> = makeComp(
  ({entityName, projectName, cantUndo, cantRedo}) => {
    return (
      <div className="view-bar readonly-view">
        Viewing {entityName}'s project
        {
          <Popup
            wide={true}
            position="top right"
            content={`You have read-only access to ${projectName}, so changes you make to this workspace are not saved.`}
            trigger={
              <span className="help-text underline-dashed">
                {!cantUndo || !cantRedo ? 'Changes not saved' : 'Read only'}
              </span>
            }
          />
        }
      </div>
    );
  },
  {id: 'ReadOnlyBar', memo: true}
);

const ViewBar: FC<AllViewBarProps> = makeComp(
  props => {
    const {
      loading,
      viewer,
      cantUndo,
      cantRedo,
      workspaceType,
      workspaceUser,
      workspaceIsPersisted,
      workspaceUpdatedAt,
      readOnly,
      entityName,
      projectName,
      isRun,
      workspaceID,
      extraActions,
      undo,
      redo,
      loadOwn,
      discardChanges,
      copyToOwn,
    } = props;
    if (loading) {
      return <div />;
    }
    if (viewer == null || viewer.id === LOGGED_OUT_USER_ID) {
      return <AnonymousBar {...props} />;
    }

    if (readOnly) {
      return (
        <ReadOnlyBar
          entityName={entityName}
          projectName={projectName}
          cantUndo={cantUndo}
          cantRedo={cantRedo}
        />
      );
    }

    const isOwnView =
      workspaceUser &&
      viewer.id === workspaceUser.id &&
      workspaceType === 'workspace';

    let workspaceName;
    if (workspaceType === 'default') {
      workspaceName = 'Default Workspace';
    } else if (workspaceUser != null) {
      if (viewer.id === workspaceUser.id) {
        workspaceName = 'My Workspace';
      } else {
        workspaceName = workspaceUser.username + "'s workspace";
      }
    } else {
      workspaceName = '';
    }

    let workspaceHelpText = '';
    if (workspaceType === 'default') {
      workspaceHelpText +=
        'You are currently viewing the default workspace, load your own workspace to make changes.';
    } else if (!isOwnView) {
      workspaceHelpText +=
        "You are viewing another user's workspace, load your own workspace to make changes.";
    } else {
      workspaceHelpText +=
        'Your workspace auto-saves your configuration of charts, filters, and table settings.';
    }

    if (isOwnView) {
      if (isRun) {
        workspaceHelpText +=
          ' ' +
          `This workspace will be shared across all runs in ${projectName}.`;
      }
    }

    const isUnsavedFork = !cantUndo && !isOwnView;

    return (
      <div
        className={`view-bar ${
          isOwnView
            ? 'own-view'
            : isUnsavedFork
            ? 'unsaved-view'
            : 'others-view'
        }`}>
        <span className="view-bar__left">
          <ViewMenu
            workspaceID={workspaceID}
            isOwnView={isOwnView}
            currentView={
              workspaceType === 'default' || !workspaceUser
                ? 'default'
                : workspaceUser.username
            }
            trigger={
              <Button className="view-menu-button">
                {workspaceType === 'default' && (
                  <Icon className="home-icon" name="home" />
                )}
                {!isUnsavedFork &&
                  workspaceType !== 'default' &&
                  workspaceUser != null &&
                  viewer.id !== workspaceUser.id && (
                    <Image
                      src={workspaceUser.photoUrl}
                      onError={(img: any) =>
                        (img.target.style.visibility = 'hidden')
                      }
                      avatar
                    />
                  )}
                {isUnsavedFork ? 'Unsaved fork of ' : ''}
                {workspaceName}
                <LegacyWBIcon name="overflow" title="menu" />
              </Button>
            }
          />
        </span>
        <span className="view-bar__middle">{extraActions}</span>
        <span className="view-bar__right">
          {isOwnView && (
            <span className="workspace-updated-text hide-in-mobile">
              <Popup
                wide={true}
                position="top center"
                trigger={
                  <span className="help-text underline-dashed">
                    {workspaceIsPersisted &&
                      workspaceUpdatedAt &&
                      (cantUndo && cantRedo ? (
                        <>
                          Updated{' '}
                          <TimeAgo
                            minPeriod={10}
                            formatter={(
                              value,
                              unit,
                              suffix,
                              epochSeconds,
                              nextFormatter
                            ) =>
                              unit === 'second'
                                ? 'just now'
                                : nextFormatter!(
                                    value,
                                    unit,
                                    suffix,
                                    epochSeconds
                                  )
                            }
                            date={workspaceUpdatedAt + 'Z'}
                          />
                        </>
                      ) : (
                        <>Changes saved automatically</>
                      ))}
                  </span>
                }
                content={workspaceHelpText}
              />
            </span>
          )}
          {isOwnView && (
            <Button.Group size="tiny">
              <Button disabled={cantUndo} icon="undo" onClick={undo} />
              <Button disabled={cantRedo} onClick={redo} icon="redo" />
            </Button.Group>
          )}

          {(viewer.id == null || workspaceUser == null || !isOwnView) &&
            !isUnsavedFork && (
              <Route
                render={({history}) => (
                  <Button
                    size="tiny"
                    content="Return to my workspace"
                    className="return-button"
                    onClick={loadOwn}
                  />
                )}
              />
            )}
          {isUnsavedFork && (
            <Button
              size="tiny"
              color="red"
              content="Discard changes"
              className="discard-button"
              onClick={discardChanges}
            />
          )}
          {!isOwnView && (
            <Button
              size="tiny"
              content="Copy to my workspace"
              className="copy-button"
              onClick={copyToOwn}
            />
          )}
        </span>
      </div>
    );
  },
  {id: 'ViewBar', memo: true}
);

function useViewBarProps(props: ViewBarProps) {
  const viewer = ViewerHooks.useViewer();
  const workspaceView = useSelector(state => {
    const workspace = state.workspaces[props.workspaceID];
    if (workspace == null || workspace.viewRef == null) {
      return undefined;
    }
    return state.views.views[workspace.viewRef.id];
  });
  if (workspaceView == null) {
    throw new Error('invalid state');
  }
  const workspaceType = WorkspaceUtil.workspaceType(workspaceView.name);
  const workspaceUser = workspaceView.user;
  const workspaceUpdatedAt = workspaceView.updatedAt;
  const workspaceIsPersisted = workspaceView.id != null;
  const cantUndo = useSelector(state => state.views.undoActions.length === 0);
  const cantRedo = useSelector(state => state.views.redoActions.length === 0);
  const discardChanges = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.discardChanges
  );
  const loadOwn = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.loadOwn
  );
  const copyToOwn = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.copyToOwn
  );
  const dispatch = useDispatch();
  const undo = useCallback(() => dispatch(ViewActions.undo()), [dispatch]);
  const redo = useCallback(() => dispatch(ViewActions.redo()), [dispatch]);
  return {
    viewer,
    loading: workspaceView == null,
    workspaceIsPersisted,
    workspaceUser,
    workspaceType,
    workspaceUpdatedAt,
    cantUndo,
    cantRedo,
    discardChanges,
    loadOwn,
    copyToOwn,
    undo,
    redo,
  };
}

export default makeComp(
  (props: ViewBarProps) => {
    const selectedProps = useViewBarProps(props);
    return <ViewBar {...props} {...selectedProps} />;
  },
  {id: 'ViewBar.default'}
);
