import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, Header} from 'semantic-ui-react';

import Loader from '../../components/WandbLoader';
import * as Urls from '../../util/urls';
import * as ArtifactUtils from '../../util/artifacts';
import * as Generated from '../../generated/graphql';
import * as Obj from '@wandb/cg/browser/utils/obj';
import {capitalizeFirst} from '@wandb/cg/browser/utils/string';
import makeComp from '../../util/profiler';
import docUrl from '../../util/doc_urls';
import {TargetBlank} from '../../util/links';
import WBReactTable from '../../components/WBReactTable';

const PAGE_SIZE = 20;

type ArtifactType = 'inputArtifacts' | 'outputArtifacts';
type ArtifactQueryType = ReturnType<typeof useRunArtifactVersions>['artifacts'];

type ArtifactEdgeTypes = Generated.ArtifactEdge | Generated.InputArtifactEdge;

const useRunArtifactVersions = (props: {
  entityName: string;
  projectName: string;
  runName: string;
  artifactsType: ArtifactType;
}) => {
  const {entityName, projectName, runName, artifactsType} = props;
  const q = Generated.useRunArtifactVersionsQuery({
    variables: {
      entityName,
      projectName,
      runName,
      includeInput: artifactsType === 'inputArtifacts',
      includeOutput: artifactsType === 'outputArtifacts',
    },
  });
  const run = q.data?.project?.run;
  const edges = run?.[artifactsType]?.edges ?? [];
  const artifacts =
    (edges as ArtifactEdgeTypes[]).map(e => e.node).filter(Obj.notEmpty) ?? [];
  const total = run?.[artifactsType]?.totalCount ?? 0;

  return {...q, artifacts, total};
};

interface RunArtifactsProps {
  entityName: string;
  projectName: string;
  runName: string;
}

export const RunArtifactGrid = makeComp<RunArtifactsProps>(
  props => {
    const inputArtifacts = useRunArtifactVersions({
      ...props,
      artifactsType: 'inputArtifacts',
    });
    const outputArtifacts = useRunArtifactVersions({
      ...props,
      artifactsType: 'outputArtifacts',
    });

    const loading = outputArtifacts.loading || inputArtifacts.loading;
    const hasArtifacts =
      outputArtifacts.total !== 0 || inputArtifacts.total !== 0;

    if (loading && hasArtifacts) {
      return (
        <div style={{position: 'relative', height: 300}}>
          <Loader />
        </div>
      );
    }
    if (!hasArtifacts) {
      return null;
    }

    const hasBothArtifacts =
      outputArtifacts.total > 0 && inputArtifacts.total > 0;
    return (
      <Grid.Row columns={hasBothArtifacts ? 2 : 1}>
        {outputArtifacts.total > 0 && (
          <Grid.Column>
            <Header>Artifact Outputs</Header>
            <p className="hint-text">
              This run produced these artifacts as outputs.{' '}
              <TargetBlank href={docUrl.artifacts}>Learn more</TargetBlank>
            </p>
            <ArtifactsTable
              {...outputArtifacts}
              artifactsType="outputArtifacts"
              noHeader
            />
          </Grid.Column>
        )}
        {inputArtifacts.total > 0 && (
          <Grid.Column>
            <Header>Artifact Inputs</Header>
            <p className="hint-text">
              This run consumed these artifacts as inputs.{' '}
              <TargetBlank href={docUrl.artifacts}>Learn more</TargetBlank>
            </p>
            <ArtifactsTable
              {...inputArtifacts}
              artifactsType="inputArtifacts"
              noHeader
            />
          </Grid.Column>
        )}
      </Grid.Row>
    );
  },
  {id: 'RunArtifactGrid'}
);

const RunArtifacts = makeComp(
  (props: RunArtifactsProps) => {
    const inputArtifacts = useRunArtifactVersions({
      ...props,
      artifactsType: 'inputArtifacts',
    });
    const outputArtifacts = useRunArtifactVersions({
      ...props,
      artifactsType: 'outputArtifacts',
    });

    const loaded = !inputArtifacts.loading && !outputArtifacts.loading;
    const hasOutputArtifacts =
      (outputArtifacts.data?.project?.run?.outputArtifacts?.totalCount ?? -1) >
      0;
    const hasInputArtifacts =
      (inputArtifacts.data?.project?.run?.inputArtifacts?.totalCount ?? -1) > 0;
    const hasArtifacts = hasOutputArtifacts || hasInputArtifacts;
    return (
      <div style={{padding: 64}}>
        {!hasArtifacts && loaded ? (
          <p>No artifacts are used in this run.</p>
        ) : (
          <>
            <ArtifactsTable
              {...inputArtifacts}
              artifactsType="inputArtifacts"
            />
            <ArtifactsTable
              {...outputArtifacts}
              artifactsType="outputArtifacts"
            />
          </>
        )}
      </div>
    );
  },
  {id: 'RunArtifacts'}
);

export default RunArtifacts;

interface ArtifactsTableProps {
  total: number;
  artifacts: ArtifactQueryType;
  artifactsType: ArtifactType;
  loading: boolean;
  noHeader?: boolean;
}

const ArtifactsTable: React.FC<ArtifactsTableProps> = makeComp(
  props => {
    const {loading, total, artifacts, artifactsType, noHeader = false} = props;

    if (loading && total === 0) {
      return (
        <div style={{position: 'relative', height: 300}}>
          <Loader />
        </div>
      );
    }

    if (total === 0) {
      return null;
    }

    const columns = [
      {Header: 'Type', accessor: 'type', width: 180},
      {Header: 'Name', accessor: 'name'},
      {Header: 'Consumer count', accessor: 'count', width: 240},
    ];

    const rows = artifacts.map(a => {
      const {usedBy} = a;
      const displayAlias = ArtifactUtils.artifactNiceName(a);
      const url = Urls.artifact({
        entityName: a.artifactType.project.entity.name,
        projectName: a.artifactType.project.name,
        artifactTypeName: a.artifactType.name,
        artifactSequenceName: a.artifactSequence.name,
        artifactCommitHash: a.commitHash!,
      });

      return {
        searchString: displayAlias,
        row: {
          type: <Link to={url}>{a.artifactType.name}</Link>,
          name: <Link to={url}>{displayAlias}</Link>,
          count: <Link to={url}>{usedBy.totalCount}</Link>,
        },
      };
    });

    return (
      <>
        {!noHeader && (
          <Header as="h2">
            {capitalizeFirst(artifactsType.replace('Artifacts', ''))} artifacts
          </Header>
        )}
        {loading ? (
          <div style={{position: 'relative', height: 200}}>
            <Loader />
          </div>
        ) : (
          <WBReactTable
            className="run-artifacts-table"
            pageSize={PAGE_SIZE}
            data={rows}
            columns={columns}
          />
        )}
      </>
    );
  },
  {id: 'ArtifactsTable', memo: true}
);
