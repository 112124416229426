import produce from 'immer';
import _ from 'lodash';
import React, {useCallback, useMemo, useState} from 'react';
import {QueryResult} from 'react-apollo';
import {CellInfo, Column} from 'react-table';
import {toast} from 'react-toastify';
import {
  Button,
  Dropdown,
  Input,
  Message,
  Modal,
  Popup,
} from 'semantic-ui-react';
import CancelOrganizationSubscriptionModal from '../components/CancelOrganizationSubscriptionModal';
import {CreateTeamModal} from '../components/CreateTeamModal';
import EditUserModal from '../components/EditUserModal';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import NoMatch from '../components/NoMatch';
import UpdateOrganizationSubscriptionModal, {
  OrganizationSubscriptionPrivileges,
} from '../components/UpdateOrganizationSubscriptionModal';
import WandbLoader from '../components/WandbLoader';
import WBReactTable from '../components/WBReactTable';
import '../css/OrganizationAdminPage.less';
import {
  AllOrganizationsQuery,
  OrganizationSubscriptionType,
  useAllOrganizationsQuery,
  useCreateCustomerTrialMutation,
  useSubscriptionPlansQuery,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {viewerUsingAdminPrivileges} from '../util/admin';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {OrganizationFlags} from '../util/pricing';
import makeComp from '../util/profiler';
import {getDashboardLink} from '../util/stripe';
import {slugFormat} from '../util/text';
import * as urls from '../util/urls';
import {TargetBlank} from '../util/links';

const OrganizationAdminPage: React.FC = makeComp(
  () => {
    const viewer = useViewer();
    const [search, setSearch] = useState('');
    const [pageSize, setPageSize] = useState(5);
    const organizations = useAllOrganizationsQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pageSize,
        query: search,
      },
    });
    const handleNextPage = useCallback(() => {
      const cursor = organizations.data?.organizations.pageInfo.endCursor;
      organizations.fetchMore({
        variables: {first: pageSize, cursor, query: search},
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return produce(prev, draft => {
            draft.organizations.pageInfo =
              fetchMoreResult.organizations.pageInfo;
            draft.organizations.edges.push(
              ...fetchMoreResult.organizations.edges
            );
          });
        },
      });
    }, [organizations, pageSize, search]);

    const [modalOpen, setModalOpen] = useState(false);

    if (!viewer) {
      return <WandbLoader></WandbLoader>;
    }
    if (!viewer.admin || !viewerUsingAdminPrivileges()) {
      return <NoMatch />;
    }

    return (
      <div className="org-admin-page">
        <div className="org-admin-page-container">
          <h1>All Organizations</h1>
          <OrganizationList
            organizationsQueryResult={organizations}
            search={search}
            onSearch={setSearch}
            onChangePageSize={setPageSize}
            onNextPage={handleNextPage}
          />
          <div className="divider" />
          <Button
            className="create-trial-button"
            color="grey"
            onClick={() => setModalOpen(true)}>
            Create a trial
          </Button>
          <Modal
            className="trial-modal"
            open={modalOpen}
            onClose={() => setModalOpen(false)}>
            <CreateTrialForm
              onTrialCreated={() => {
                setModalOpen(false);
                organizations.refetch();
              }}
            />
          </Modal>
        </div>
      </div>
    );
  },
  {id: 'OrganizationAdminPage'}
);

const prettifyPlanName = (name: string) => {
  return _.capitalize(name.replace(/_/g, ' '));
};

interface CreateTrialFormProps {
  onTrialCreated(): void;
}

const CreateTrialForm: React.FC<CreateTrialFormProps> = makeComp(
  props => {
    const plans = useSubscriptionPlansQuery();
    const [formDisabled, setFormDisabled] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [numSeats, setNumSeats] = useState<number | null>(null);
    const [trialUsername, setTrialUsername] = useState<string | null>(null);
    const [trialOrg, setTrialOrg] = useState<string | null>(null);
    const [trialPeriod, setTrialPeriod] = useState<number | null>(null);

    const [createCustomerTrialMutation] = useCreateCustomerTrialMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

    const planOptions = useMemo(() => {
      if (plans.loading || plans.data == null) {
        return [];
      }

      return plans.data.plans
        .filter(p => p?.planType === 'PRIMARY')
        .map(p => ({
          key: p?.name,
          text: prettifyPlanName(p?.name ?? ''),
          value: p?.id,
        }));
    }, [plans]);

    const planSeatLimit = useMemo(() => {
      if (selectedPlan == null || plans.loading || plans.data == null) {
        return null;
      }

      return plans.data.plans.find(p => p?.id === selectedPlan)?.maxSeats;
    }, [selectedPlan, plans]);

    const onSubmit = useCallback(async () => {
      setFormDisabled(true);
      setError(null);

      if (
        [selectedPlan, numSeats, trialUsername, trialOrg, trialPeriod].some(
          v => v == null
        )
      ) {
        setError('You must fill out every field.');
        setFormDisabled(false);
        return;
      }

      if (
        numSeats != null &&
        planSeatLimit != null &&
        numSeats > planSeatLimit
      ) {
        setError(
          `Number of seats exceeds limit for this plan (${planSeatLimit})`
        );
        setFormDisabled(false);
        return;
      }

      try {
        const createTrialResult = await createCustomerTrialMutation({
          variables: {
            planId: selectedPlan!,
            userName: trialUsername!,
            newOrganizationName: trialOrg!,
            quantity: numSeats!,
            trialDays: trialPeriod!,
          },
        });

        if (createTrialResult.errors != null) {
          setError(
            `Error creating trial: ${createTrialResult.errors.join(', ')}`
          );
          return;
        }

        toast('Successfully created organization and trial.');
        props.onTrialCreated();
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
        setError(`Error creating trial: ${errMsg}`);
        return;
      } finally {
        setFormDisabled(false);
      }
    }, [
      selectedPlan,
      numSeats,
      trialUsername,
      trialOrg,
      trialPeriod,
      planSeatLimit,
      createCustomerTrialMutation,
      props,
    ]);

    return (
      <>
        <div className="trial-form">
          <h2>Create a trial for a customer</h2>
          {error && <Message negative content={error} />}
          <div className="trial-input-group">
            <div className="dropdown-group">
              <Dropdown
                disabled={formDisabled}
                className="trial-input"
                selection
                value={selectedPlan ?? undefined}
                placeholder={'Select a plan'}
                options={planOptions}
                onChange={(_1, data) => {
                  setNumSeats(1);
                  setSelectedPlan(data.value as string);
                }}
              />
              <Input
                disabled={formDisabled}
                className="trial-input"
                placeholder="Number of seats"
                type="number"
                onChange={(_1, data) => setNumSeats(parseInt(data.value, 10))}
              />
            </div>
            <Input
              disabled={formDisabled}
              className="trial-input"
              placeholder="Username"
              onChange={(_1, data) => setTrialUsername(data.value)}
            />
            <Input
              disabled={formDisabled}
              className="trial-input"
              placeholder="Organization name"
              value={trialOrg}
              onChange={(_1, data) => setTrialOrg(slugFormat(data.value))}
            />
            <Input
              disabled={formDisabled}
              className="trial-input"
              type="number"
              placeholder="Trial period in days"
              onChange={(_1, data) => setTrialPeriod(parseInt(data.value, 10))}
            />
            <Button
              disabled={formDisabled}
              className="submit-button"
              onClick={onSubmit}>
              Create trial
            </Button>
          </div>
        </div>
      </>
    );
  },
  {id: 'CreateTrialForm'}
);

interface OrganizationListProps {
  organizationsQueryResult: QueryResult<AllOrganizationsQuery>;
  search: string;
  onSearch: (search: string) => void;
  onChangePageSize: (pageSize: number) => void;
  onNextPage: () => void;
}

type OrganizationResult =
  AllOrganizationsQuery['organizations']['edges'][number]['node'];

type OrganizationResultWithFlags = Omit<OrganizationResult, 'flags'> & {
  flags: OrganizationFlags;
};

const OrganizationList: React.FC<OrganizationListProps> = makeComp(
  props => {
    const orgQuery = props.organizationsQueryResult;

    const [selectedOrganization, setSelectedOrganization] =
      useState<OrganizationResultWithFlags | null>(null);

    const setSelectedOrganizationWithFlags = useCallback(
      (org: OrganizationResult) => {
        let flags = {};
        try {
          if (org.flags != null) {
            flags = JSON.parse(org.flags);
          }
        } catch {
          // do nothing
        }
        setSelectedOrganization({...org, flags});
      },
      []
    );

    const selectedOrgOption = useMemo(() => {
      if (selectedOrganization == null) {
        return [];
      }
      return [
        {
          text: selectedOrganization?.name ?? '',
          value: selectedOrganization?.id ?? '',
        },
      ];
    }, [selectedOrganization]);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
    const [
      updateOrganizationSubscriptionModalOpen,
      setUpdateOrganizationSubscriptionModalOpen,
    ] = useState(false);
    const [
      isCancelOrganizationSubscriptionModalOpen,
      setCancelOrganizationSubscriptionModalOpen,
    ] = useState(false);

    const getPrimarySubscriptionFromOrg = (org: OrganizationResult | null) => {
      return org?.subscriptions[0];
    };
    const selectedOrgSubscriptionAttrs = useMemo(() => {
      if (selectedOrganization == null) {
        return null;
      }
      const primarySub = getPrimarySubscriptionFromOrg(selectedOrganization);
      if (primarySub == null) {
        return null;
      }

      const privileges: OrganizationSubscriptionPrivileges =
        primarySub.privileges as OrganizationSubscriptionPrivileges;

      return {
        id: primarySub.id,
        seats: primarySub.seats,
        plan: primarySub.plan,
        privileges,
        stripeID:
          selectedOrganization?.stripeBillingInfo?.stripeSubscriptionId ?? null,
        type: primarySub.subscriptionType,
        status: primarySub.status,
        expiresAt: primarySub.expiresAt ?? null,
      };
    }, [selectedOrganization]);

    const rows = useMemo(() => {
      const orgs =
        orgQuery.data?.organizations.edges
          .map(o => o.node)
          .filter((o): o is NonNullable<typeof o> => o != null) ?? [];
      return orgs.map(o => {
        const sub = getPrimarySubscriptionFromOrg(o);
        const planName = prettifyPlanName(sub?.plan.name ?? '');
        const seats = sub?.seats ?? 0;
        const seatsUsed = o.members.length;
        const teams = o.teams.map(t => t.name);
        const billingUser = [
          o.billingUser.username ?? '',
          o.billingUser.email ?? '',
        ];
        const status = (() => {
          if (o.stripeBillingInfo != null) {
            const dashboardLink = getDashboardLink(
              o?.stripeBillingInfo?.stripeSubscriptionId
            );

            const stripeStatus = o.stripeBillingInfo.status;

            const setToCancel =
              stripeStatus === 'active' &&
              o.stripeBillingInfo.cancelAtPeriodEnd;

            const statusString = setToCancel
              ? 'Set to cancel'
              : _.capitalize(stripeStatus);

            return (
              <TargetBlank href={dashboardLink}>
                {`${statusString} (Stripe)`}
              </TargetBlank>
            );
          }

          if (
            sub?.subscriptionType ===
              OrganizationSubscriptionType.ManualTrial ||
            sub?.subscriptionType ===
              OrganizationSubscriptionType.UserLedTrial ||
            sub?.subscriptionType === OrganizationSubscriptionType.AcademicTrial
          ) {
            const disabled = sub.status === 'DISABLED';
            const isAcademicTrial =
              sub.subscriptionType ===
              OrganizationSubscriptionType.AcademicTrial;
            const trialString = isAcademicTrial
              ? 'In academic trial'
              : 'In trial';

            if (disabled) {
              return trialString + ' (disabled)';
            }

            if (sub.expiresAt != null) {
              const expirationDate = new Date(
                sub.expiresAt + 'Z'
              ).toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });
              return trialString + `, expires ${expirationDate}`;
            }

            return trialString;
          }

          if (
            sub?.subscriptionType === OrganizationSubscriptionType.Enterprise ||
            sub?.subscriptionType === OrganizationSubscriptionType.Academic
          ) {
            let statusStr = sub.status === 'DISABLED' ? 'Disabled' : 'Active';
            if (
              sub.subscriptionType === OrganizationSubscriptionType.Academic
            ) {
              statusStr += ' (academic)';
            }
            return statusStr;
          }

          return 'Unknown';
        })();

        return {
          searchString: o.name,
          row: {
            name: o.name,
            planName,
            seats: `${seatsUsed} / ${seats}`,
            teams,
            billingUser,
            status,
            organization: o,
            sub,
          },
        };
      });
    }, [orgQuery.data]);

    type OrgRow = typeof rows[number]['row'];

    const columns = useMemo(() => {
      const orgNameCol: Column = {
        Header: 'Name',
        accessor: 'name',
        width: 100,
        Cell: (cellInfo: CellInfo) => {
          const orgName = cellInfo.value;
          return <span>{orgName}</span>;
        },
      };

      const planNameCol: Column = {
        Header: 'Plan',
        accessor: 'planName',
        width: 100,
        sortable: false,
        Cell: (cellInfo: CellInfo) => {
          return <span>{cellInfo.value}</span>;
        },
      };

      const seatsCol: Column = {
        Header: 'Seats',
        accessor: 'seats',
        width: 100,
        sortable: false,
        Cell: (cellInfo: CellInfo) => {
          return <span>{cellInfo.value}</span>;
        },
      };

      const teamsCol: Column = {
        Header: 'Teams',
        accessor: 'teams',
        sortable: false,
        Cell: (cellInfo: CellInfo) => {
          return (cellInfo.value as string[])
            .flatMap(t => [
              <TargetBlank key={t} href={urls.teamPage(t)}>
                {t}
              </TargetBlank>,
              ', ',
            ])
            .slice(0, -1);
        },
      };

      const billingUserCol: Column = {
        Header: 'Billing User',
        accessor: 'billingUser',
        resizable: true,
        sortable: false,
        Cell: (cellInfo: CellInfo) => {
          const [username, email] = cellInfo.value as string[2];
          return (
            <>
              <TargetBlank href={urls.entity(username)}>{username}</TargetBlank>
              {` (${email})`}
            </>
          );
        },
      };

      const statusColumn: Column = {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        Cell: (cellInfo: CellInfo) => {
          return <span>{cellInfo.value}</span>;
        },
      };

      const actionsColumn: Column = {
        id: 'actions',
        Header: 'Actions',
        sortable: false,
        accessor: (row: OrgRow) => ({
          org: row.organization,
          sub: row.sub,
        }),
        Cell: (cellInfo: CellInfo) => {
          const org = cellInfo.value.org as OrgRow['organization'];
          const sub = cellInfo.value.sub as OrgRow['sub'];
          return (
            <>
              <Popup
                position={'top center'}
                popperModifiers={{
                  preventOverflow: {
                    // prevent popper from erroneously constraining the popup to the
                    // table header
                    boundariesElement: 'viewport',
                  },
                }}
                trigger={
                  <span>
                    <LegacyWBIcon
                      data-test="edit-user-button"
                      link
                      style={{marginRight: 4}}
                      name="edit"
                      onClick={() => {
                        setSelectedOrganizationWithFlags(org);
                        setEditUserModalOpen(true);
                      }}
                    />
                  </span>
                }
                content="Edit organization"
                size="small"
              />
              <Popup
                position={'top center'}
                popperModifiers={{
                  preventOverflow: {
                    // prevent popper from erroneously constraining the popup to the
                    // table header
                    boundariesElement: 'viewport',
                  },
                }}
                trigger={
                  <span>
                    <LegacyWBIcon
                      data-test="create-team-button"
                      link
                      style={{marginRight: 4}}
                      name="plus"
                      onClick={() => {
                        setSelectedOrganizationWithFlags(org);
                        setCreateTeamModalOpen(true);
                      }}
                    />
                  </span>
                }
                content={'Create new team'}
                size="small"
              />
              {sub && (
                <Popup
                  position={'top center'}
                  popperModifiers={{
                    preventOverflow: {
                      // prevent popper from erroneously constraining the popup to the
                      // table header
                      boundariesElement: 'viewport',
                    },
                  }}
                  trigger={
                    <LegacyWBIcon
                      data-test="edit-subscription-button"
                      link
                      style={{marginRight: 4}}
                      name="configuration"
                      onClick={() => {
                        setSelectedOrganizationWithFlags(org);
                        setUpdateOrganizationSubscriptionModalOpen(true);
                      }}
                    />
                  }
                  content={'Edit subscription'}
                  size="small"
                />
              )}
              <Popup
                position={'top center'}
                popperModifiers={{
                  preventOverflow: {
                    // prevent popper from erroneously constraining the popup to the
                    // table header
                    boundariesElement: 'viewport',
                  },
                }}
                trigger={
                  <LegacyWBIcon
                    data-test="delete-team-button"
                    link
                    style={{marginRight: 4}}
                    name="delete"
                    onClick={() => {
                      setSelectedOrganizationWithFlags(org);
                      setCancelOrganizationSubscriptionModalOpen(true);
                    }}
                  />
                }
                content={'Cancel organization'}
                size="small"
              />
            </>
          );
        },
      };

      return [
        orgNameCol,
        planNameCol,
        seatsCol,
        teamsCol,
        billingUserCol,
        statusColumn,
        actionsColumn,
      ].map(o => ({resizable: true, ...o}));
    }, [setSelectedOrganizationWithFlags]);

    const pageSizeOptions = useMemo(() => [5, 10, 20, 30], []); // TODO changeme

    if (orgQuery.loading && orgQuery.data == null) {
      return <WandbLoader />;
    }

    return (
      <>
        {editUserModalOpen && selectedOrganization != null && (
          <EditUserModal
            enableAdminControls
            open
            organizationId={selectedOrganization.id}
            onAddUser={() => orgQuery.refetch()}
            onRemoveUser={() => orgQuery.refetch()}
            onUpdateOrganizationUser={() => orgQuery.refetch()}
            onClose={() => setEditUserModalOpen(false)}
          />
        )}
        <CreateTeamModal
          open={createTeamModalOpen}
          orgOptions={selectedOrgOption}
          teamAdminUsername={
            selectedOrganization?.billingUser.username ?? undefined
          }
          onClose={() => setCreateTeamModalOpen(false)}
          onCreate={() => orgQuery.refetch()}
        />
        <UpdateOrganizationSubscriptionModal
          open={updateOrganizationSubscriptionModalOpen}
          name={selectedOrganization?.name ?? null}
          subscriptionAttributes={selectedOrgSubscriptionAttrs}
          billingUserEmail={selectedOrganization?.billingUser.email ?? null}
          onUpdate={() => {
            orgQuery.refetch();
            toast('Success! Refreshing latest data from backend services...', {
              position: 'top-right',
              autoClose: 25000,
            });
            setUpdateOrganizationSubscriptionModalOpen(false);
          }}
          onClose={() => setUpdateOrganizationSubscriptionModalOpen(false)}
        />
        {selectedOrganization && (
          <CancelOrganizationSubscriptionModal
            open={isCancelOrganizationSubscriptionModalOpen}
            organizationID={selectedOrganization.id}
            onUpdate={() => {
              orgQuery.refetch();
              setCancelOrganizationSubscriptionModalOpen(false);
            }}
            onClose={() => setCancelOrganizationSubscriptionModalOpen(false)}
          />
        )}
        {rows && (
          <WBReactTable
            columns={columns}
            data={rows}
            onFetchNextPage={props.onNextPage}
            onSearch={props.onSearch}
            hasNextPage={orgQuery.data?.organizations.pageInfo.hasNextPage}
            fetchingNextPage={orgQuery.loading}
            pageSizeOptions={pageSizeOptions}
            onChangePageSize={props.onChangePageSize}
          />
        )}
      </>
    );
  },
  {id: 'OrganizationList'}
);

export default OrganizationAdminPage;
