import '../css/ActionTable.less';
import '../css/ReportTable.less';

import * as Generated from '../generated/graphql';
import classNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Confirm, Form, Image, Dropdown} from 'semantic-ui-react';
import docUrl from '../util/doc_urls';
import EditableLabel from './elements/EditableLabel';

import Loader from '../components/WandbLoader';
import MultiStateCheckbox from '../components/MultiStateCheckbox';
import emptyImg from '../assets/il-no-reports.png';
import emptyImg2x from '../assets/il-no-reports@2x.png';
import {fuzzyMatchHighlight, fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import * as Urls from '../util/urls';
import EmptyWatermark from './EmptyWatermark';
import * as ViewerHooks from '../state/viewer/hooks';
import * as ViewApi from '../state/views/api';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {useReportTableQuery} from '../state/graphql/reportTableQuery';
import {useApolloClient} from '../state/hooks';
import {useMutation} from 'react-apollo';
import {useViewer} from '../state/viewer/hooks';
import {ViewMetadata} from '../graphql/views';
import {viewerUsingAdminPrivileges} from '../util/admin';
import {ID} from '@wandb/cg/browser/utils/string';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';
import MultiSelectTable from './MultiSelectTable';
import {WBIcon} from '@wandb/ui';

import TimeAgo from 'react-timeago';

type SortBy = 'displayName' | 'updatedAt' | 'user';
type SortFn = (r: ViewMetadata) => any;

const SORT_FUNCTION_BY_NAME: {[s in SortBy]: SortFn} = {
  displayName: r => r.displayName,
  updatedAt: r => -new Date(r.updatedAt).valueOf(),
  user: r => r.user.username,
};

interface ReportTableProps {
  entityName: string;
  projectName: string;
  onCreateNewReport(): void;
}

type ReportTableQueryLoadedData = Exclude<
  ReturnType<typeof useReportTableQuery>,
  {loading: true}
>;

// Report-loading widget.

const ReportTable = makeComp(
  (props: ReportTableProps) => {
    const {entityName, projectName} = props;

    const reportTableQuery = useReportTableQuery({entityName, projectName});
    if (reportTableQuery.loading) {
      return <Loader />;
    }

    return <ReportTableInner {...props} reportTableQuery={reportTableQuery} />;
  },
  {id: 'ReportTable'}
);

const ReportTableInner = makeComp(
  (
    props: ReportTableProps & {reportTableQuery: ReportTableQueryLoadedData}
  ) => {
    const {entityName, reportTableQuery} = props;

    const viewer = useViewer();
    const [searchQuery, setSearchQuery] = useState('');
    const [byUser, setByUser] = useState('Anyone');

    const readOnly = viewer == null || reportTableQuery.project.readOnly;
    const refetch = reportTableQuery.refetch;
    const reports = reportTableQuery.project.reports.edges.map(e => e.node);
    const reportDrafts = reportTableQuery.project.reportDrafts.edges
      .map(e => e.node)
      // TODO: filtering should be done on the backend
      .filter(draft => draft.user.id === viewer?.id);
    const masterDraftMap: {[master: string]: string} = {};
    for (const draft of reportDrafts) {
      if (draft.parentId != null) {
        masterDraftMap[draft.parentId] = draft.id;
      }
    }
    const orphanReportDrafts = reportDrafts.filter(
      draft => draft.parentId == null
    );

    const userNames = _.sortBy(_.uniq(reports.map(r => r.user.username)));
    const userOptions = ['Anyone']
      .concat(userNames)
      .map(u => ({text: u, key: u, value: u}));

    let filteredReports = reports;
    let filteredOrphanReportDrafts = orphanReportDrafts;
    if (!_.isEmpty(searchQuery)) {
      filteredReports = fuzzyMatchWithMapping(
        filteredReports,
        searchQuery,
        report =>
          [report.displayName, report.user.username, report.project.name].join(
            '|'
          ) // search report name and the report username and report project name
      );
      filteredOrphanReportDrafts = fuzzyMatchWithMapping(
        filteredOrphanReportDrafts,
        searchQuery,
        report =>
          [report.displayName, report.user.username, report.project.name].join(
            '|'
          )
      );
    }
    if (byUser !== 'Anyone') {
      filteredReports = filteredReports.filter(r => r.user.username === byUser);
    }

    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [
      deleteAllDraftsConfirmModalOpen,
      setDeleteAllDraftsConfirmModalOpen,
    ] = useState(false);

    const client = useApolloClient();

    const renderEmpty =
      reports.length === 0 && orphanReportDrafts.length === 0
        ? () => (
            <EmptyWatermark
              imageSource={emptyImg}
              imageSource2x={emptyImg2x}
              header="There aren't any reports yet."
              details={
                <>
                  <p>
                    Reports allow you to save and organize results, to keep
                    track of your work and share with colleagues.
                  </p>
                  <p>
                    <TargetBlank href={docUrl.reports}>
                      See the docs {'\u2192'}
                    </TargetBlank>
                  </p>
                </>
              }
            />
          )
        : undefined;

    const [sortBy, setSortBy] = useState<SortBy>('updatedAt');
    const [sortReverse, setSortReverse] = useState(false);
    const toggleSort = useCallback(
      (sb: SortBy) => {
        if (sb === sortBy) {
          setSortReverse(prev => !prev);
          return;
        }
        setSortBy(sb);
        setSortReverse(false);
      },
      [sortBy]
    );
    const renderSortIcon = (sortKey: string) => {
      if (sortBy !== sortKey) {
        return null;
      }
      return (
        <WBIcon
          name={`${sortReverse ? 'down' : 'up'}-arrow`}
          className="report-table__sort-icon"
        />
      );
    };
    const [sortDraftBy, setSortDraftBy] = useState<SortBy>('updatedAt');
    const [sortDraftReverse, setSortDraftReverse] = useState(false);
    const toggleSortDraft = useCallback(
      (sb: SortBy) => {
        if (sb === sortDraftBy) {
          setSortDraftReverse(prev => !prev);
          return;
        }
        setSortDraftBy(sb);
        setSortDraftReverse(false);
      },
      [sortDraftBy]
    );
    const renderSortDraftIcon = (sortDraftKey: string) => {
      if (sortDraftBy !== sortDraftKey) {
        return null;
      }
      return (
        <WBIcon
          name={`${sortDraftReverse ? 'down' : 'up'}-arrow`}
          className="report-table__sort-icon"
        />
      );
    };

    return (
      <>
        <MultiSelectTable
          readOnly={reportTableQuery.project.readOnly}
          rows={filteredReports}
          draftRows={filteredOrphanReportDrafts}
          sortBy={SORT_FUNCTION_BY_NAME[sortBy]}
          sortReverse={sortReverse}
          sortDraftBy={SORT_FUNCTION_BY_NAME[sortDraftBy]}
          sortDraftReverse={sortDraftReverse}
          renderDialogs={filteredSelectedViews => (
            <>
              <Confirm
                open={deleteConfirmModalOpen}
                onCancel={() => setDeleteConfirmModalOpen(false)}
                onConfirm={() => {
                  ViewApi.deleteViews(
                    client,
                    filteredSelectedViews.map(v => v.id)
                  ).then(({data}) => {
                    if (
                      !data?.deleteViews?.success &&
                      data?.deleteViews?.pendingDrafts
                    ) {
                      setDeleteAllDraftsConfirmModalOpen(true);
                    } else {
                      refetch();
                    }

                    setDeleteConfirmModalOpen(false);
                  });
                }}
                header="Delete Reports"
                content={`Are you sure you want to delete ${filteredSelectedViews.length} reports? This cannot be undone!`}
                confirmButton="Yes, delete these reports"
              />
              <Confirm
                open={deleteAllDraftsConfirmModalOpen}
                onCancel={() => setDeleteAllDraftsConfirmModalOpen(false)}
                onConfirm={() => {
                  ViewApi.deleteViews(
                    client,
                    filteredSelectedViews.map(v => v.id),
                    true
                  ).then(({data}) => {
                    refetch();
                    setDeleteAllDraftsConfirmModalOpen(false);
                  });
                }}
                header="Delete Reports"
                content={`Reports have pending draft edits. Are you sure you want to delete them and all their drafts? This cannot be undone!`}
                confirmButton="Yes, delete these reports and all their drafts"
              />
            </>
          )}
          renderDraftHeader={(checked, onClick) => (
            <>
              <div className="report-table__cell report-table__cell--select">
                <MultiStateCheckbox checked={checked} onClick={onClick} />
              </div>
              <div
                className="report-table__cell report-table__table-title"
                onClick={() => toggleSortDraft('displayName')}>
                Your private drafts
                {renderSortDraftIcon('displayName')}
              </div>
              <div
                className="report-table__cell"
                onClick={() => toggleSortDraft('updatedAt')}>
                Last edited {renderSortDraftIcon('updatedAt')}
              </div>
              <div className="report-table__cell" />
            </>
          )}
          renderDraftRow={
            orphanReportDrafts.length > 0
              ? (r, selected, setSelected) => (
                  <ReportTableDraftRow
                    key={r.id}
                    entityName={entityName}
                    report={r}
                    searchQuery={searchQuery}
                    selected={selected}
                    setSelected={setSelected}
                    onDelete={refetch}
                    readOnly={readOnly}
                  />
                )
              : undefined
          }
          renderHeader={(checked, onClick) => (
            <>
              <div className="report-table__cell report-table__cell--select">
                <MultiStateCheckbox checked={checked} onClick={onClick} />
              </div>
              <div
                className="report-table__cell report-table__table-title"
                onClick={() => toggleSort('displayName')}>
                Reports
                {renderSortIcon('displayName')}
              </div>
              <div className="report-table__cell" />
              <div
                className="report-table__cell"
                onClick={() => toggleSort('updatedAt')}>
                Last edited
                {renderSortIcon('updatedAt')}
              </div>
              <div
                className="report-table__cell"
                onClick={() => toggleSort('user')}>
                Created by
                {renderSortIcon('user')}
              </div>
              <div className="report-table__cell" />
            </>
          )}
          renderRow={(r, selected, setSelected) => (
            <ReportTableRow
              key={r.id}
              entityName={entityName}
              report={r}
              searchQuery={searchQuery}
              selected={selected}
              setSelected={setSelected}
              onDelete={refetch}
              readOnly={readOnly}
              draftId={masterDraftMap[r.id]}
              refetch={refetch}
            />
          )}
          renderAddButton={() => (
            <Button
              primary
              size="tiny"
              className="create-new-report"
              onClick={props.onCreateNewReport}
              content="Create a report"
            />
          )}
          renderDeleteButton={() => (
            <Button
              negative
              size="tiny"
              className="delete-reports"
              onClick={() => setDeleteConfirmModalOpen(true)}
              content="Delete Reports"
            />
          )}
          renderSearch={() => (
            <Form.Input
              className="report-table__search"
              value={searchQuery}
              onChange={(e, {value}) => setSearchQuery(value)}
              icon={
                <LegacyWBIcon
                  name="search"
                  size="large"
                  style={{marginRight: -8}}
                />
              }
              placeholder="Search reports..."
            />
          )}
          renderFilter={() => (
            <Form.Dropdown
              className="report-table__user-filter"
              selection
              options={userOptions}
              value={byUser}
              onChange={(e, {value}) => setByUser(value as string)}
            />
          )}
          renderEmpty={renderEmpty}
        />
      </>
    );
  },
  {id: 'ReportTableInner'}
);

export default ReportTable;

interface ReportTableDraftRowProps {
  entityName: string;
  searchQuery: string;
  report: ViewMetadata;
  readOnly: boolean;
  selected: boolean;
  setSelected(id: string, selected: boolean): void;
  onDelete(): void;
}

const ReportTableDraftRow: React.FC<ReportTableDraftRowProps> = makeComp(
  props => {
    const {entityName, report, searchQuery, selected, setSelected, onDelete} =
      props;
    const [name, setName] = useState(report.displayName);
    const history = useHistory();
    const editUrl = Urls.reportEdit({
      entityName,
      projectName: report.project.name,
      reportID: report.id,
      reportName: name,
    });
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const client = useApolloClient();

    const onSelectChanged = useCallback(
      () => setSelected(report.id, !selected),
      [report.id, selected, setSelected]
    );

    return (
      <>
        <Confirm
          open={deleteConfirmModalOpen}
          onCancel={() => setDeleteConfirmModalOpen(false)}
          onConfirm={() => {
            ViewApi.deleteView(client, report.id).then(() => {
              onDelete();
              setDeleteConfirmModalOpen(false);
            });
          }}
          header="Discard draft"
          content={`Are you sure you want to delete "${name}"? This cannot be undone!`}
          confirmButton="Yes, discard this draft"
        />
        <div className="report-table__row" key={report.id}>
          <div className="report-table__cell report-table__cell--select">
            <MultiStateCheckbox
              checked={selected ? 'checked' : 'unchecked'}
              onClick={onSelectChanged}
            />
          </div>
          <div className="report-table__cell report-table__cell--title">
            <Link to={editUrl}>
              {!editingName ? (
                <span className="report-table__report-title">
                  {fuzzyMatchHighlight(name, searchQuery)}
                </span>
              ) : (
                <EditableLabel
                  key={report.id}
                  title={name}
                  onSave={(newName: string) => {
                    ViewApi.save(client, {
                      id: report.id,
                      displayName: newName,
                    });
                    setName(newName);
                  }}
                  onBlur={() => setEditingName(false)}
                  editing={editingName}
                  serverText={name}
                  onClick={() => false} // we use the menu option to trigger editing mode, rather than direct click
                />
              )}
            </Link>
            {report.description !== '' && (
              <div className="report-table__description">
                {report.description}
              </div>
            )}
          </div>
          <div className="report-table__cell">
            <TimeAgo date={report.updatedAt + 'Z'} live={false} />
          </div>
          <div className="report-table__cell report-table__cell--action-buttons">
            <Dropdown
              className="dropdown-menu-icon-button"
              floating
              pointing="top right"
              trigger={
                <LegacyWBIcon
                  className="report-table__action-button"
                  name="overflow"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                  }}></LegacyWBIcon>
              }>
              <Dropdown.Menu>
                <Dropdown.Item
                  icon={<LegacyWBIcon name="edit" />}
                  content="Rename draft"
                  onClick={(e: React.SyntheticEvent) => {
                    setEditingName(true);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <Dropdown.Item
                  icon={<LegacyWBIcon name="copy" />}
                  content="Clone draft"
                  onClick={() => {
                    ViewApi.load(client, report.id).then(loaded => {
                      ViewApi.save(client, {
                        type: 'runs/draft',
                        name: ID(12),
                        displayName: `Copy of ${loaded.displayName}`,
                        description: loaded.description,
                        spec: loaded.spec,
                        project: loaded.project,
                        previewUrl: loaded.previewUrl,
                      }).then(newDraft => {
                        history.push(
                          loaded.project != null
                            ? Urls.reportEdit({
                                entityName: loaded.project.entityName,
                                projectName: loaded.project.name,
                                reportID: newDraft.id as string,
                                reportName: newDraft.displayName,
                              })
                            : Urls.galleryPostEdit({
                                entityName: loaded.entityName,
                                reportID: newDraft.id as string,
                                reportName: newDraft.displayName,
                              })
                        );
                      });
                    });
                  }}></Dropdown.Item>
                <Dropdown.Item
                  icon={<LegacyWBIcon name="delete" />}
                  content="Discard draft"
                  disabled={props.readOnly}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDeleteConfirmModalOpen(true);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </>
    );
  },
  {id: 'ReportTableDraftRow'}
);

interface ReportTableRowProps {
  entityName: string;
  searchQuery: string;
  report: ViewMetadata;
  readOnly: boolean;
  selected: boolean;
  draftId?: string;
  setSelected(id: string, selected: boolean): void;
  onDelete(): void;
  refetch(): any;
}

const ReportTableRow = makeComp(
  (props: ReportTableRowProps) => {
    const {entityName, report, selected, searchQuery, setSelected, onDelete} =
      props;
    const viewer = ViewerHooks.useViewer();
    const [name, setName] = useState(report.displayName);
    const history = useHistory();
    const viewUrl = Urls.reportView({
      entityName,
      projectName: report.project.name,
      reportID: report.id,
      reportName: name,
    });
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [
      deleteAllDraftsConfirmModalOpen,
      setDeleteAllDraftsConfirmModalOpen,
    ] = useState(false);
    const [deleteDraftConfirmModalOpen, setDeleteDraftConfirmModalOpen] =
      useState(false);
    const [editingName, setEditingName] = useState(false);
    const client = useApolloClient();
    const [starView] = useMutation<
      Generated.StarViewMutationResult,
      Generated.StarViewMutationVariables
    >(Generated.StarViewDocument);
    const [unstarView] = useMutation<
      Generated.UnstarViewMutationResult,
      Generated.UnstarViewMutationVariables
    >(Generated.UnstarViewDocument);

    const onSelectChanged = useCallback(
      () => setSelected(report.id, !selected),
      [report.id, selected, setSelected]
    );

    const canEdit =
      ((viewer != null && report.user.id === viewer.id) ||
        !report.locked ||
        (viewer?.admin && viewerUsingAdminPrivileges())) &&
      !props.readOnly;

    return (
      <>
        <Confirm
          open={deleteConfirmModalOpen}
          onCancel={() => setDeleteConfirmModalOpen(false)}
          onConfirm={() => {
            ViewApi.deleteView(client, report.id).then(({data}) => {
              if (
                !data?.deleteView?.success &&
                data?.deleteView?.pendingDrafts
              ) {
                setDeleteAllDraftsConfirmModalOpen(true);
              } else {
                onDelete();
              }

              setDeleteConfirmModalOpen(false);
            });
          }}
          header="Delete Report"
          content={`Are you sure you want to delete "${name}"? This cannot be undone!`}
          confirmButton="Yes, delete this report"
        />
        <Confirm
          open={deleteAllDraftsConfirmModalOpen}
          onCancel={() => setDeleteAllDraftsConfirmModalOpen(false)}
          onConfirm={() => {
            ViewApi.deleteView(client, report.id, true).then(({data}) => {
              onDelete();
              setDeleteAllDraftsConfirmModalOpen(false);
            });
          }}
          header="Delete Report"
          content={`Report "${name}" has pending draft edits. Are you sure you want to delete it and all its drafts? This cannot be undone!`}
          confirmButton="Yes, delete this report and all its drafts"
        />
        {props.draftId != null && (
          <Confirm
            open={deleteDraftConfirmModalOpen}
            onCancel={() => setDeleteDraftConfirmModalOpen(false)}
            onConfirm={() => {
              ViewApi.deleteView(client, props.draftId as string).then(() => {
                onDelete();
                setDeleteDraftConfirmModalOpen(false);
              });
            }}
            header="Discard draft"
            content={`Are you sure you want to discard your edit of "${name}"? This cannot be undone!`}
            confirmButton="Yes, discard this draft"
          />
        )}
        <div className="report-table__row" key={report.id}>
          <div className="report-table__cell report-table__cell--select">
            <MultiStateCheckbox
              checked={selected ? 'checked' : 'unchecked'}
              onClick={onSelectChanged}
            />
          </div>
          <div className="report-table__cell report-table__cell--title">
            <Link to={viewUrl}>
              {!editingName ? (
                <span className="report-table__report-title">
                  {fuzzyMatchHighlight(name, searchQuery)}
                </span>
              ) : (
                <EditableLabel
                  key={report.id}
                  title={name}
                  onSave={(newName: string) => {
                    ViewApi.save(client, {
                      id: report.id,
                      displayName: newName,
                    });
                    setName(newName);
                  }}
                  onBlur={() => setEditingName(false)}
                  editing={editingName}
                  serverText={name}
                  onClick={() => false} // we use the menu option to trigger editing mode, rather than direct click
                />
              )}
            </Link>
            {report.description !== '' && (
              <div className="report-table__description">
                {report.description}
              </div>
            )}
          </div>
          <div className="report-table__cell report-table__cell--stars-cell">
            <LegacyWBIcon
              onClick={() => {
                if (report.starred) {
                  unstarView({
                    variables: {
                      id: report.id,
                    },
                  });
                } else {
                  starView({
                    variables: {
                      id: report.id,
                    },
                  });
                }
              }}
              name="heart"
              className={classNames({starred: report.starred})}></LegacyWBIcon>
            {report.starCount}
          </div>
          <div className="report-table__cell">
            <TimeAgo date={report.updatedAt + 'Z'} live={false} />
          </div>
          <div className="report-table__cell">
            <Link to={Urls.profilePage(report.user.username)}>
              <Image
                src={report.user.photoUrl}
                avatar
                onError={(i: any) => (i.target.style.display = 'none')}
              />
              {report.user.username}
            </Link>
          </div>
          {props.draftId == null ? (
            <div className="report-table__cell">
              {canEdit && (
                <LegacyWBIcon
                  onClick={() => {
                    ViewApi.load(client, report.id).then(loaded => {
                      ViewApi.save(client, {
                        type: 'runs/draft',
                        name: ID(12),
                        displayName: loaded.displayName,
                        description: loaded.description,
                        spec: loaded.spec,
                        previewUrl: loaded.previewUrl,
                        coverUrl: loaded.coverUrl,
                        project: {
                          entityName,
                          name: loaded.project?.name,
                        },
                        parentId: loaded.id,
                      }).then(newDraft => {
                        history.push(
                          Urls.reportEdit({
                            entityName,
                            projectName: report.project.name,
                            reportID: newDraft.id as string,
                            reportName: newDraft.displayName,
                          })
                        );
                      });
                    });
                  }}
                  className="report-table__action-button"
                  name="edit"></LegacyWBIcon>
              )}
              <Dropdown
                className="dropdown-menu-icon-button"
                pointing="top right"
                trigger={
                  <LegacyWBIcon
                    className="report-table__action-button"
                    name="overflow"
                    onClick={(e: React.SyntheticEvent) => {
                      e.preventDefault();
                    }}></LegacyWBIcon>
                }>
                <Dropdown.Menu>
                  {canEdit && (
                    <Dropdown.Item
                      icon={<LegacyWBIcon name="edit" />}
                      content="Rename report"
                      onClick={(e: React.SyntheticEvent) => {
                        setEditingName(true);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <Dropdown.Item
                    icon={<LegacyWBIcon name="copy" />}
                    content="Clone report"
                    onClick={() => {
                      ViewApi.load(client, report.id).then(loaded => {
                        ViewApi.save(client, {
                          type: 'runs/draft',
                          name: ID(12),
                          displayName: `Copy of ${loaded.user.username}'s ${loaded.displayName}`,
                          description: loaded.description,
                          spec: loaded.spec,
                          project: loaded.project,
                          previewUrl: loaded.previewUrl,
                          coverUrl: loaded.coverUrl,
                        }).then(newDraft => {
                          history.push(
                            loaded.project != null
                              ? Urls.reportEdit({
                                  entityName: loaded.project.entityName,
                                  projectName: loaded.project.name,
                                  reportID: newDraft.id as string,
                                  reportName: newDraft.displayName,
                                })
                              : Urls.galleryPostEdit({
                                  entityName: loaded.entityName,
                                  reportID: newDraft.id as string,
                                  reportName: newDraft.displayName,
                                })
                          );
                        });
                      });
                    }}></Dropdown.Item>
                  {canEdit && (
                    <Dropdown.Item
                      icon={<LegacyWBIcon name="delete" />}
                      content="Delete report"
                      onClick={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeleteConfirmModalOpen(true);
                      }}
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className="report-table__cell report-table__cell--child-draft-wrapper">
              <div className="report-table__child-draft">
                <div className="report-table__child-draft-inner">
                  <div className="report-table__child-draft-info">
                    Draft edit in progress
                  </div>
                  <div className="report-table__child-draft-options">
                    <Link
                      className="report-table__child-draft-option"
                      to={Urls.reportEdit({
                        entityName,
                        projectName: report.project.name,
                        reportName: name,
                        reportID: props.draftId,
                      })}>
                      Continue
                    </Link>
                    <span className="report-table__child-draft-option-separator">
                      &#8226;
                    </span>
                    <span
                      className="report-table__child-draft-option"
                      onClick={() => {
                        setDeleteDraftConfirmModalOpen(true);
                      }}>
                      Discard
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
  {id: 'ReportTableRow'}
);
