import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {
  Button as SemanticButton,
  Dropdown as SemanticDropdown,
  Input as SemanticInput,
  TextArea as SemanticTextArea,
} from 'semantic-ui-react';
import {WBIcon} from '@wandb/ui';

export const Container = styled.div`
  padding: 32px;
`;

export const UnsavedEditsBar = styled.div`
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${globals.galleryAdminPurple};
  text-align: center;
  padding: 8px;
  color: ${globals.white};
`;

export const UnsavedEditsButton = styled(SemanticButton)`
  &&&&& {
    margin-left: 16px;
    margin-right: 0;
  }
`;

export const PageHeading = styled.h1`
  margin-top: 0;
`;

export const Heading = styled.h2``;

export const Subheading = styled.h3`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const sharedInputStyles = css`
  font-family: ${globals.fontName};
  color: ${globals.gray800};
  border: 1px solid ${globals.gray400};
  border-radius: 4px;
  padding: 8px;
  outline: none;
  resize: none;
`;

export const EditHeadingInput = styled(SemanticInput)`
  width: 100%;
  &&&&& input {
    ${sharedInputStyles}
  }
`;

export const EditHeadingTextArea = styled(SemanticTextArea)`
  width: 100%;
  &&&&& {
    ${sharedInputStyles}
  }
`;

export const EditButton = styled(SemanticButton)`
  &&&&& {
    margin: 32px 0 0;
  }
`;

export const Dropdown = styled(SemanticDropdown)``;

export const Image = styled.img`
  width: 168px;
  height: 168px;
`;

export const ActionIcon = styled(WBIcon)`
  font-size: 20px;
  cursor: pointer;

  &:not(:hover) {
    opacity: 0.5;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const ModalLabel = styled.div`
  font-weight: bold;
  margin-bottom: 16px;

  &:not(:first-child) {
    margin-top: 32px;
  }
`;

export const ReportList = styled.div`
  margin: -8px;
  padding: 8px;
  max-height: 500px;
  overflow: auto;
`;

export const AdminButton = styled(SemanticButton)`
  &&&&& {
    background-color: ${globals.gray300};
    padding: 10px 12px;
    margin: 0 16px 0 0;
    color: ${globals.gray900};
    border: none;

    &:hover {
      background-color: ${globals.gray400};
    }
  }
`;
