import _ from 'lodash';
import React from 'react';
import {beautify, makeOptions, searchFunction} from '../../util/uihelpers';
import {Option} from '../../util/uihelpers';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import makeComp from '../../util/profiler';

export interface FilterKeySelectorProps {
  focusOnMount: boolean;
  storedKey: string;
  keys: string[];
  keyValue: string;
  loading: boolean;
  onValidSelection(key: string): void;
}

export type FilterKeySelectorCreatorProps = Omit<
  FilterKeySelectorProps,
  'keys' | 'loading'
>;

export const FilterKeySelector = makeComp(
  (props: FilterKeySelectorProps) => {
    const {storedKey, keys} = props;
    const isBlank = !storedKey || storedKey === '-';
    const options: Option[] = makeOptions(keys);

    return (
      <ModifiedDropdown
        className={`filter-dropdown filter-list__key ${
          isBlank ? 'filter-dropdown--blank' : ''
        }`}
        loading={props.loading}
        disabled={props.loading}
        data-test="filter-key"
        options={options}
        optionTransform={beautify}
        placeholder="Key"
        closeOnChange={true}
        search={searchFunction}
        searchInput={{autoFocus: props.focusOnMount}}
        value={storedKey}
        onChange={(e, {value}) => {
          if (
            (_.indexOf(keys, value) >= 0 && typeof value === 'string') ||
            value === 'tags'
          ) {
            props.onValidSelection(value);
          }
        }}
      />
    );
  },
  {id: 'FilterKeySelector'}
);
