// To add a new built-in Vega panel, add it to the builtinPanels sub-directory,
// then import it here and add it to PANEL_DEFS.
import * as VegaLib from '../../util/vega';
import ConfusionMatrixHistory from './builtinPanels/confusionMatrixHistory';
import DualYAxisPlot from './builtinPanels/dualYAxisPlot';
import HistoryScatterWithSize from './builtinPanels/historyScatterWIthSize';
import RunDataHistogram from './builtinPanels/runDataHistogram';
import RunDataScatterWithSize from './builtinPanels/runDataScatterWIthSize';

export interface BuiltinPanelDef {
  id: string;
  name: string;
  description: string;
  specObj: any;
}

// Panels are listed in this order.
const PANEL_DEFS: BuiltinPanelDef[] = [
  ConfusionMatrixHistory,
  RunDataScatterWithSize,
  HistoryScatterWithSize,
  DualYAxisPlot,
  RunDataHistogram,
];

function builtinID(pd: BuiltinPanelDef): string {
  return 'builtin:' + pd.id;
}

const PANEL_DEF_LOOKUP: {[key: string]: VegaLib.VegaPanelDef} = {};
for (const pd of PANEL_DEFS) {
  PANEL_DEF_LOOKUP[builtinID(pd)] = {
    name: pd.name,
    description: pd.description,
    spec: JSON.stringify(pd.specObj, undefined, 2),
  };
}

export const IDS = PANEL_DEFS.map(pd => builtinID(pd));

export function getPanelDef(id: string): VegaLib.VegaPanelDef | null {
  const result = PANEL_DEF_LOOKUP[id];
  if (result != null) {
    return result;
  }
  return null;
}
