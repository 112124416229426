import * as globals from '../../../css/globals.styles';

import styled, {css} from 'styled-components';
import {REPORT_FONT_SIZE} from '../constants';
import {WBIcon} from '@wandb/ui';

export const ImagePlaceholderWrapper = styled.div<{
  selected: boolean;
  draggingImageOver: boolean;
}>`
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
  position: relative;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
  background: #f6f6f6;
  &:hover {
    background: #f0f0f0;
  }
  ${props =>
    props.draggingImageOver &&
    css`
      background: #f0f0f0;
    `}
  border-radius: 4px;
`;

export const ImagePlaceholder = styled.div`
  padding: 16px;
  color: #999;
  display: flex;
  align-items: center;
  // fixes weird 1px off alignment in Chrome
  line-height: 19px;
`;

export const ImagePlaceholderInput = styled.input`
  opacity: 0;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ImagePlaceholderIcon = styled(WBIcon).attrs({name: 'image'})`
  font-size: 24px;
  margin-right: 8px;
`;

export const LinkedImageWrapper = styled.a`
  width: fit-content;
  display: block;
`;

export const ImageOuterWrapper = styled.div``;

export const ResizeHandle = styled.div`
  background: rgba(128, 128, 128, 0.6);
  border-radius: 2px;
  width: 100%;
  height: 100%;
`;

export const ResizeHandleWrapper = styled.div<{
  cursor: string;
  resizing: boolean;
}>`
  position: absolute;
  padding: 2px;
  width: 8px;
  height: min(48px, 100%);
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: ${props => props.cursor};
  &:hover {
    ${ResizeHandle} {
      background: rgba(128, 128, 128, 0.9);
    }
  }
  ${props =>
    props.resizing &&
    css`
      opacity: 1 !important;
    `}
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
  ${ResizeHandleWrapper} {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &:hover {
    ${ResizeHandleWrapper} {
      opacity: 1;
    }
  }
`;

export const Image = styled.img<{selected: boolean; width?: number}>`
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
  display: block;
  width: fit-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
  ${props =>
    props.width != null &&
    css`
      width: ${props.width}px;
    `}
`;

export const ImageCaption = styled.div<{empty?: boolean; selected?: boolean}>`
  margin-top: -${Math.round(REPORT_FONT_SIZE * 0.4)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
  font-family: Source Serif Pro;
  color: #666;
  ${props =>
    !props.selected &&
    props.empty &&
    css`
      opacity: 0;
      height: 0;
      margin: 0;
    `}
  ${props =>
    props.empty &&
    css`
      &::before {
        content: 'Write a caption...';
        position: absolute;
        color: ${globals.gray500};
      }
    `}
`;

export const AddCaptionButton = styled.div`
  margin-top: -${Math.round(REPORT_FONT_SIZE * 0.4)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
  font-family: Source Serif Pro;
  color: ${globals.gray500};
`;
