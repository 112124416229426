import _ from 'lodash';
import React, {FC, useState, useMemo} from 'react';
import {Dropdown, Input} from 'semantic-ui-react';
import {DeepPartial} from '../../types/base';
import {DropdownOption} from '../../util/uihelpers';
import * as VegaLib from '../../util/vega';
import makeComp from '../../util/profiler';

type VegaPanelUserConfigProps = VegaLib.UserSettings & {
  refs: VegaLib.Ref[];

  runFieldOptions: DropdownOption[];
  historyFieldOptions: DropdownOption[];

  updateUserSettings(newUserSettings: DeepPartial<VegaLib.UserSettings>): void;
};

const VegaPanelUserConfig: FC<VegaPanelUserConfigProps> = makeComp(
  ({
    fieldSettings,
    runFieldSettings,
    runFieldListSettings: propsRunFieldListSettings,
    historyFieldSettings,
    refs,
    runFieldOptions,
    historyFieldOptions,
    updateUserSettings,
  }) => {
    const [runFieldListSettings, setRunFieldListSettings] = useState(
      propsRunFieldListSettings
    );

    const updateFieldSetting = useMemo(
      () =>
        _.debounce((key: string, val: string) => {
          updateUserSettings({fieldSettings: {[key]: val}});
        }, 1000),
      [updateUserSettings]
    );

    const updateRunFieldListSetting = useMemo(
      () =>
        _.debounce((key: string, val: string) => {
          updateUserSettings({runFieldListSettings: {[key]: val}});
        }, 1000),
      [updateUserSettings]
    );

    const inputs = VegaLib.refsToInputs(refs);
    return (
      <>
        {inputs.fieldInputs.map((key, i) => {
          return (
            <React.Fragment key={'field-' + i}>
              <p className="chart-label">{key}</p>
              <Input
                defaultValue={fieldSettings[key]}
                onChange={(e, {value}) => {
                  if (typeof value === 'string') {
                    updateFieldSetting(key, value);
                  }
                }}
              />
            </React.Fragment>
          );
        })}
        {inputs.runFieldInputs.map((key, i) => {
          return (
            <React.Fragment key={'run-field-' + i}>
              <p className="chart-label">{key}</p>
              <Dropdown
                search
                selection
                options={runFieldOptions}
                value={runFieldSettings[key]}
                onChange={(e, {value}) => {
                  if (typeof value === 'string') {
                    updateUserSettings({
                      runFieldSettings: {[key]: value},
                    });
                  }
                }}
              />
            </React.Fragment>
          );
        })}
        {inputs.runFieldListInputs.map((key, i) => {
          const val = runFieldListSettings[key];
          let error = false;
          try {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const unused = new RegExp(val);
          } catch {
            error = true;
          }
          return (
            <React.Fragment key={'run-field-list-' + i}>
              <p className="chart-label">{key}</p>
              <Input
                style={{minWidth: 400}}
                error={error}
                value={runFieldListSettings[key]}
                onChange={(e, {value}) => {
                  if (typeof value === 'string') {
                    setRunFieldListSettings({
                      ...runFieldListSettings,
                      [key]: value,
                    });
                    updateRunFieldListSetting(key, value);
                  }
                }}
              />
              {error && (
                <span
                  style={{marginLeft: 8, color: 'red', fontStyle: 'italic'}}>
                  invalid regex
                </span>
              )}
            </React.Fragment>
          );
        })}
        {inputs.historyFieldInputs.length > 0 && <h4>History fields</h4>}
        {inputs.historyFieldInputs.map((key, i) => (
          <React.Fragment key={'history-field-' + i}>
            <p className="chart-label">{key}</p>
            <Dropdown
              search
              selection
              options={historyFieldOptions}
              // TODO: need to convert to an from multi-representation if the user
              // switches, otherwise we'll crash
              value={historyFieldSettings[key]}
              onChange={(e, {value}) => {
                if (typeof value === 'string') {
                  updateUserSettings({
                    historyFieldSettings: {[key]: value},
                  });
                }
              }}
            />
          </React.Fragment>
        ))}
      </>
    );
  },
  {id: 'VegaPanelUserConfig', memo: true}
);

export default VegaPanelUserConfig;
