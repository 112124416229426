// TODO:
//   - save resized columns
//   - don't filter runs that don't have the table, we want to show
//     that they don't have data.
import React from 'react';
import _ from 'lodash';

import {useLoadHistoryFileTables} from '../state/runs/hooks';
import {Header, Popup} from 'semantic-ui-react';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import {TableCellValue, TableMetadata} from '../types/media';
import * as Filter from '../util/filters';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import * as Run from '../util/runs';
import Markdown from './Markdown';
import ProjectFieldSelector from './ProjectFieldSelector';
import WBReactTable from './WBReactTable';
import ModifiedDropdown from './elements/ModifiedDropdown';

import '../css/PanelMultiRunTable.less';
import PanelError from './elements/PanelError';
import {Column} from 'react-table';
import makeComp from '../util/profiler';

const PANEL_TYPE = 'Multi Run Table';
const COL_MIN_WIDTH = 200;
const BORDER_WIDTH = 1;

export interface MultiRunTableConfig {
  tableKey?: string;
  rowColumnKeys?: string[];
  cellColumnKey?: Array<string | number | boolean> | string | number | boolean;
  pageSize?: number;
}
type MultiRunTablePanelProps = Panels.PanelProps<MultiRunTableConfig>;

export default class MultiRunTablePanel extends React.Component<MultiRunTablePanelProps> {
  static transformQuery(
    query: Query.Query,
    config: MultiRunTableConfig
  ): RunsDataQuery {
    const result = toRunsDataQuery(query, {selectionsAsFilters: true});
    const extraFilters: Filter.Filter[] = [];
    result.page = {
      size: 100,
    };
    if (config.tableKey) {
      result.page.size = 20;
      const key = Run.keyFromString(config.tableKey);
      if (key && (key.section === 'config' || key.section === 'summary')) {
        extraFilters.push({key, op: '!=', value: null});
      }
    }
    if (extraFilters.length > 0 && result.queries.length > 0) {
      result.queries[0].filters = Filter.And([
        result.queries[0].filters,
        ...extraFilters,
      ]);
    }
    result.summaryKeys = config.tableKey
      ? [config.tableKey.split(':')[1]]
      : undefined;
    return result;
  }

  currentTables() {
    const tableKey = this.props.config.tableKey;
    if (tableKey == null) {
      return [];
    }
    const k = Run.keyFromString(tableKey);
    if (k == null) {
      return [];
    }
    return this.props.data.filtered.map(r => ({
      run: r,
      table: Run.getValue(r, k) as TableMetadata,
    }));
    // .filter(t => Obj.notEmpty(t.table));
  }

  currentTableColumns() {
    const tables = this.currentTables();
    if (tables == null) {
      return [];
    }
    return _.union(...tables.map(t => (t.table ? t.table.columns : [])));
  }

  renderConfig() {
    const tableColumnOptions = this.currentTableColumns().map(k => ({
      text: k,
      value: k,
      key: k,
    }));
    return (
      <div className="chart-modal">
        <div className="chart-preview">{this.renderNormal()}</div>
        <div className="chart-settings">
          <div className="form-grid">
            <p className="chart-label">Table key</p>
            <ProjectFieldSelector
              query={this.props.pageQuery}
              multi={false}
              selection
              autoPick
              defaultKeys={[]}
              columns={['summary_metrics']}
              types={['table', 'table-file']}
              value={this.props.config.tableKey || ''}
              setValue={value => this.props.updateConfig({tableKey: value})}
              searchByKeyAndText
            />
            <p className="chart-label">Input</p>
            <ModifiedDropdown
              style={{flexGrow: 1}}
              multiple
              placeholder="Input Columns"
              search
              selection
              options={tableColumnOptions}
              value={this.props.config.rowColumnKeys || []}
              onChange={(e, {value}) =>
                this.props.updateConfig({
                  ...this.props.config,
                  rowColumnKeys: value as string[],
                })
              }
            />
            <p className="chart-label">Output columns</p>
            <ModifiedDropdown
              style={{flexGrow: 1}}
              multiple
              placeholder="Output Columns"
              search
              selection
              options={tableColumnOptions}
              value={this.outputKeys()}
              onChange={(e, {value}) => {
                this.props.updateConfig({
                  ...this.props.config,
                  cellColumnKey: value as string,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  outputKeys() {
    // cellColumnKey was stored as a single value, now we support multiple,
    // so convert single values to arrays.
    const {cellColumnKey} = this.props.config;
    if (cellColumnKey == null) {
      return [];
    }
    const outputKeys = _.isArray(cellColumnKey)
      ? cellColumnKey.map(c => c.toString())
      : [cellColumnKey.toString()];

    // Sanitize output key names. Accessors might not work if they contain periods.
    return outputKeys.map(k => k.replace(/\./g, '-'));
  }

  renderNormal() {
    const {tableKey, rowColumnKeys} = this.props.config;
    const outputKeys = this.outputKeys();
    if (
      tableKey == null ||
      rowColumnKeys == null ||
      outputKeys == null ||
      rowColumnKeys.length === 0
    ) {
      return (
        <PanelError message="Configure this panel to visualize your data." />
      );
    }
    const tables = this.currentTables();
    const pivot: {
      [rowName: string]: {
        runs: {[runName: string]: {[key: string]: string}};
        table: {[colName: string]: string};
      };
    } = {};
    tables.forEach((t, i) => {
      if (t.table == null) {
        return;
      }
      const rowColIndices = rowColumnKeys.map(k =>
        _.indexOf(t.table.columns, k)
      );

      // Sanitize run name because ReactTable can't deal with periods
      // in property names.
      t.run = {...t.run, name: t.run.name.replace(/\./g, '-')};

      t.table.data?.forEach((row: TableCellValue | TableCellValue[]) => {
        if (!_.isArray(row)) {
          return;
        }
        const rowHeader = rowColIndices.map(index => row[index]).join('-');
        if (pivot[rowHeader] == null) {
          pivot[rowHeader] = {
            runs: {},
            table: {},
          };
        }
        const rowData = pivot[rowHeader];
        if (rowData.runs[t.run.name] == null) {
          rowData.runs[t.run.name] = {};
        }
        for (const outputKey of outputKeys) {
          const index = _.indexOf(t.table.columns, outputKey);
          if (index !== -1) {
            rowData.runs[t.run.name][outputKey] = String(row[index]);
          }
        }
        t.table.columns.forEach((cName, k) => {
          rowData.table[cName.toString()] = String(row[k]);
        });
      });
    });

    const data = _.keys(pivot).map(k => ({searchString: k, row: pivot[k]}));
    const columns: Column[] = [
      {
        Header: 'Input',
        headerClassName: 'header-input',
        columns: rowColumnKeys.map(colName => ({
          Header: colName,
          headerClassName: 'header-input-col',
          accessor: `table.${colName}`,
          minWidth: COL_MIN_WIDTH,

          Cell: (row: any) => {
            const numLines = 2;
            const value = row.value == null ? '' : row.value.toString();
            const cell = <Markdown condensed={false} content={value} />;
            return value.split('\n').length > numLines ? (
              <Popup
                position="bottom center"
                hoverable
                trigger={<div style={{maxHeight: `${numLines}em`}}>{cell}</div>}
                content={
                  <div className="panel-multirun-table-code-popup">{cell}</div>
                }
              />
            ) : (
              cell
            );
          },
        })),
      },
      ...outputKeys.map(outputKey => ({
        Header: `Output (${outputKey})`,
        headerClassName: 'header-output',
        columns: tables.map((t, i) => ({
          Header: t.run.displayName,
          headerClassName: 'header-output-col',
          accessor: `runs.${t.run.name}.${outputKey}`,
          minWidth: COL_MIN_WIDTH,
        })),
      })),
    ];
    return (
      <div className="panel-multirun-table">
        <Header as="h5">{Run.keyStringDisplayName(tableKey)}</Header>
        <WBReactTable
          loading={this.props.data.loading}
          data={data}
          columns={columns}
          pageSize={this.props.config.pageSize}
          onChangePageSize={pageSize => this.props.updateConfig({pageSize})}
          style={{
            minWidth:
              (rowColumnKeys.length + tables.length) * COL_MIN_WIDTH +
              2 * BORDER_WIDTH,
          }}
        />
      </div>
    );
  }

  render() {
    return this.props.configMode ? this.renderConfig() : this.renderNormal();
  }
}

const MultiRunTablePanelLoad = makeComp(
  (props: MultiRunTablePanelProps) => {
    const runsDataQuery = useLoadHistoryFileTables(
      props.query,
      props.runsDataQuery
    );
    return (
      <MultiRunTablePanel
        {...props}
        runsDataQuery={runsDataQuery}
        data={runsDataQuery.data}
        loading={runsDataQuery.loading}
      />
    );
  },
  {id: 'MultiRunTablePanelLoad'}
);

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, MultiRunTableConfig> = {
  type: PANEL_TYPE,
  Component: MultiRunTablePanelLoad,
  transformQuery: MultiRunTablePanel.transformQuery,
};
