import React from 'react';
import {Icon, Label, Loader} from 'semantic-ui-react';
import {displayValueSpan} from '../../../util/runhelpers';
import {Value as RunValue} from '../../../util/runs';
import makeComp from '../../../util/profiler';

interface DFTableCellExpandableProps {
  cellValue: RunValue;
  isExpanded?: boolean;
  loadingChildren?: boolean;
  childrenCount?: number;
  toggleExpandedRow?(): void;
}

// renders a cell in the table with a triangle icon to expand the row
export const DFTableCellExpandable: React.FC<DFTableCellExpandableProps> =
  makeComp(
    ({
      cellValue,
      isExpanded,
      loadingChildren,
      childrenCount,
      toggleExpandedRow,
    }) => {
      return (
        <div
          className={`df-tree-cell--header-row 
            ${loadingChildren ? 'df-tree-cell--loading-children' : ''}
      `}
          onClick={toggleExpandedRow}>
          {loadingChildren ? (
            <Loader active inline size="mini" />
          ) : (
            <Icon className={`triangle ${isExpanded ? 'down' : 'right'}`} />
          )}
          {displayValueSpan(cellValue)}
          {childrenCount && (
            <Label className="count-label">{childrenCount}</Label>
          )}
        </div>
      );
    },
    {id: 'DFTableCellExpandable', memo: true}
  );
