import React, {useState} from 'react';
import {Button, Header, Modal} from 'semantic-ui-react';
import {useCancelCustomerSubscriptionMutation} from '../generated/graphql';
import makeComp from '../util/profiler';

export interface CancelOrganizationSubscriptionModalProps {
  open: boolean;
  organizationID: string;
  onUpdate(): void;
  onClose(): void;
}

const CancelOrganizationSubscriptionModal: React.FC<CancelOrganizationSubscriptionModalProps> =
  makeComp(
    props => {
      const {open, organizationID, onUpdate, onClose} = props;
      const [isLoading, setLoading] = useState(false);
      const [cancelCustomerSubscriptionMutation] =
        useCancelCustomerSubscriptionMutation();

      return (
        <Modal open={open}>
          <Header>Cancel Organization</Header>
          <Modal.Content>
            <p>I understand that canceling an organization will:</p>
            <ul>
              <li>Disable all of its teams, disallowing new runs</li>
              <li>Cancel its active Stripe subscriptions (if applicable)</li>
            </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>Nevermind</Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              negative
              onClick={async () => {
                setLoading(true);
                await cancelCustomerSubscriptionMutation({
                  variables: {
                    organizationId: organizationID,
                  },
                });
                setLoading(false);
                onUpdate();
              }}>
              Cancel it!
            </Button>
          </Modal.Actions>
        </Modal>
      );
    },
    {
      id: 'CancelOrganizationSubscriptionModal',
    }
  );

export default CancelOrganizationSubscriptionModal;
