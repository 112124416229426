import '../../css/HelpPopup.less';

import React from 'react';
import {Popup, Icon} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import {TargetBlank} from '../../util/links';

interface HelpPopupProps {
  helpText: string;
  docUrl?: string;
}

const HelpPopup: React.FC<HelpPopupProps> = makeComp(
  props => {
    const popup = (
      <Popup
        inverted
        size="mini"
        className="help-popup-content"
        trigger={<Icon name="help circle" size="small" />}
        content={props.helpText}
      />
    );
    const returnVal =
      props.docUrl != null ? (
        <TargetBlank href={props.docUrl}>{popup}</TargetBlank>
      ) : (
        popup
      );
    return <span className="help-popup">{returnVal}</span>;
  },
  {id: 'HelpPopup'}
);

export default HelpPopup;
