import * as globals from '../css/globals.styles';

import {omit} from 'lodash';
import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {
  Dropdown,
  DropdownItemProps,
  Popup,
  StrictPopupProps,
} from 'semantic-ui-react';

import '../css/PopupDropdown.less';
import makeComp from '../util/profiler';

type DropdownSection = DropdownItemProps[];

export interface PopupDropdownProps extends StrictPopupProps {
  options?: DropdownItemProps[];
  sections?: DropdownSection[];
}

const PopupDropdown: React.FC<PopupDropdownProps> = makeComp(
  props => {
    const {className, options, sections} = props;

    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const popperModifiers = useMemo(
      () => ({
        preventOverflow: {enabled: false},
        flip: {enabled: false},
      }),
      []
    );

    const makeDropdownItem = useCallback(
      (opts, i: number) => (
        <Dropdown.Item
          key={i}
          {...opts}
          onClick={e => {
            opts.onClick?.(e);
            handleClose();
          }}
        />
      ),
      [handleClose]
    );

    const content = useMemo(
      () => (
        <div onClick={handleClose}>
          <Dropdown open={true} icon={null}>
            <Dropdown.Menu>
              {options?.map(makeDropdownItem)}
              {sections?.map((sec, i: number) => (
                <React.Fragment key={i}>
                  {i > 0 && (
                    <div
                      style={{
                        margin: '8px 16px',
                        height: 1,
                        background: globals.gray400,
                      }}
                    />
                  )}
                  {sec.map(makeDropdownItem)}
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      [handleClose, makeDropdownItem, options, sections]
    );

    return (
      <Popup
        basic
        position="bottom left"
        {...omit(props, 'options')}
        className={'popup-dropdown ' + (className || '')}
        on="click"
        hoverable
        popperModifiers={popperModifiers}
        content={content}
        onOpen={handleOpen}
        open={isOpen}
        onClose={handleClose}
      />
    );
  },
  {id: 'PopupDropdown', memo: true}
);

export default PopupDropdown;
