import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {SECTION_BORDER_RADIUS} from '../pages/Section.styles';

export const Nudge = styled.div<{inline?: true}>`
  display: flex;
  align-items: flex-start;
  background: ${globals.deepTeal};
  width: 100%;
  z-index: 1000;
  justify-content: space-between;
  height: 52px;
  color: ${globals.white};
  transition: margin-top 0.2s;
  font-size: 16px;

  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  ${({inline}) =>
    inline &&
    `
    border: 1px solid;
    border-radius: ${SECTION_BORDER_RADIUS}px;
    margin-bottom: 32px;
  `}
`;

export const NudgeInner = styled.div`
  display: flex;
  margin: 14px;
  margin-left: 24px;
`;

export const NudgeMessage = styled.div``;

export const NudgeLink = styled.div`
  margin-left: 12px;
  .nudge-info-link {
    color: ${globals.white};
    font-weight: 600;
  }
`;

export const NudgeDismiss = styled.div`
  margin: 12px;
`;
