import React from 'react';
import memoize from 'memoize-one';

import * as ViewHooks from '../../state/views/hooks';
import * as SortTypes from '../../state/views/sort/types';

import {WBTableSortIndicator} from '../WBTable/WBTableSortIndicator';
import makeComp from '../../util/profiler';

interface RunsSortIndicatorProps {
  columnKeyString: string;
  sortRef: SortTypes.Ref;
}

export const RunsSortIndicator = makeComp(
  (props: RunsSortIndicatorProps) => {
    const sort = ViewHooks.useWhole(props.sortRef);
    return (
      <WBTableSortIndicator
        sort={sort}
        columnKeyString={props.columnKeyString}
      />
    );
  },
  {id: 'RunsSortIndicator'}
);

export const makeRunsSortIndicator = memoize((sortRef: SortTypes.Ref) => {
  const SortIndicator: React.FC<{
    columnKeyString: string;
  }> = makeComp(
    props => {
      return (
        <RunsSortIndicator
          columnKeyString={props.columnKeyString}
          sortRef={sortRef}
        />
      );
    },
    {id: 'makeRunsSortIndicator'}
  );
  return SortIndicator;
});
