import '../css/PanelParameterImportance.less';

import * as Panels from '../util/panels';
import React, {useCallback, useMemo, useEffect} from 'react';
import * as Query from '../util/queryts';
import {
  useParameterImportanceQuery,
  useLazyParameterImportanceQuery,
} from '../state/graphql/paramImportanceQuery';
import WBReactTable from './WBReactTable';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import {Popup, Loader, Button} from 'semantic-ui-react';
import classNames from 'classnames';
import * as Filter from '../util/filters';
import LegacyWBIcon from './elements/LegacyWBIcon';
import PanelError from './elements/PanelError';
import {EMPTY_CONFIG, ConfigV2} from '../util/runfeed';
import {TargetBlank} from '../util/links';
import WBTableColumnEditor from './WBTable/WBTableColumnEditor';
import {ModalTriggerButton} from './elements/ModalTriggerButton';
import {
  keyStringDisplayName,
  fixConfigKeyString,
  isReservedKey,
} from '../util/runs';
import {ParameterImportanceQueryVariables} from '../generated/graphql';
import _ from 'lodash';
import {toast} from './elements/Toast';
import {makeFetchProjectFieldOptions} from '../util/dropdownQueries';
import WBSearchableSelect from './elements/WBSearchableSelect';
import {getIconNameForKey} from '../util/uihelpers';
import docUrl from '../util/doc_urls';

const PANEL_TYPE = 'Parameter Importance';

interface ParameterImportanceConfig {
  targetKey?: string;
  parameterConf?: ConfigV2 | null;
}

type PanelParameterImportanceProps =
  Panels.PanelProps<ParameterImportanceConfig>;

function transformQuery(query: Query.Query, config: any): RunsDataQuery {
  const result = toRunsDataQuery(
    query,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );
  return result;
}

const AutoParametersButton: React.FC<{
  queryVariables: ParameterImportanceQueryVariables;
  parameterConf: ConfigV2;
  setParameterConf: (newConf: ConfigV2) => void;
}> = ({queryVariables, parameterConf, setParameterConf}) => {
  const [fetchParameterImportances, {loading, data}] =
    useLazyParameterImportanceQuery(queryVariables);
  const [buttonClicked, setButtonClicked] = React.useState(false);

  useEffect(() => {
    if (!loading && data != null && buttonClicked) {
      const importanceData = data.project.parameterImportance;
      // Filter out rows where importance and correlation are 0
      const filteredImportanceData = _.pickBy(importanceData, (v, k) => {
        return v.importance !== 0 && v.correlation !== 0;
      });
      const autoParameters = Object.keys(filteredImportanceData).map(
        p => `config:${p}`
      );
      if (!_.isEqual(autoParameters, parameterConf.columnOrder)) {
        setParameterConf({
          ...parameterConf,
          columnOrder: autoParameters,
          columnVisible: autoParameters.reduce(
            (obj, k) => ({...obj, [k]: true}),
            {}
          ),
        });
        toast('Now showing recommended parameters.');
      }
      setButtonClicked(false);
    }
  }, [loading, data, parameterConf, setParameterConf, buttonClicked]);

  return (
    <Popup
      content={'Automatically show the most useful parameters.'}
      position="top right"
      popperModifiers={{
        preventOverflow: {
          // prevent popper from erroneously constraining the popup to the panel
          boundariesElement: 'viewport',
        },
      }}
      trigger={
        <Button
          size="tiny"
          disabled={loading}
          className="wb-icon-button only-icon"
          onClick={() => {
            fetchParameterImportances();
            setButtonClicked(true);
          }}>
          <LegacyWBIcon name="wandb" />
        </Button>
      }
    />
  );
};

export default function PanelParameterImportance(
  props: PanelParameterImportanceProps
) {
  const {updateConfig} = props;
  // targetKey is the comparison metric
  const targetKey = props.config.targetKey || '';
  const setTargetKey = useCallback(
    (key: string | number | undefined) =>
      updateConfig({
        targetKey: key == null ? undefined : (key as string).split(/:(.+)/)[1],
      }),
    [updateConfig]
  );
  // parameterConf stores which parameters are visible
  const parameterConf = props.config.parameterConf || null;
  const setParameterConf = useCallback(
    (newConf: ConfigV2) => updateConfig({parameterConf: newConf}),
    [updateConfig]
  );
  const allConfigKeys = Object.keys(props.data.keyInfo).filter(
    k => k.startsWith('config:') && !isReservedKey(k.split(':')[1])
  );
  // Runtime isn't a config, but we should let users see how it correlates to target metrics.
  allConfigKeys.push('run:duration');

  useEffect(() => {
    if (!props.data.loading) {
      let params: string[] = [];
      // if parameterConf is empty, initialize it with all config keys
      if (parameterConf == null) {
        params = allConfigKeys;
      } else if (
        // if any parameterConf keys aren't formatted correctly, fix them
        parameterConf.columnOrder.some(
          k => !k.startsWith('config:') && !k.startsWith('run:')
        )
      ) {
        params = parameterConf.columnOrder.map(k =>
          k.startsWith('config:') ? k : fixConfigKeyString(`config:${k}`)
        );
      }
      if (params.length > 0) {
        setParameterConf({
          ...EMPTY_CONFIG,
          // Set all config params to be visible by default
          columnVisible: params.reduce((obj, k) => ({...obj, [k]: true}), {}),
          columnOrder: params,
        });
      }
    }
  }, [props.data.loading, allConfigKeys, parameterConf, setParameterConf]);

  // Use toRunsDataQuery to get the filters for each run set.
  const rdq = toRunsDataQuery(props.pageQuery, {selectionsAsFilters: true});
  const importanceFilters = Filter.Or(rdq.queries.map(q => q.filters));

  const runLimit = 1000;
  const parameters = parameterConf?.columnOrder.filter(c =>
    c.startsWith('config:')
  );
  const runParameters = parameterConf?.columnOrder.filter(c =>
    c.startsWith('run:')
  );
  const parameterImportanceQueryVariables = {
    projectName: props.query.queries[0]?.projectName ?? props.query.projectName,
    entityName: props.query.queries[0]?.entityName ?? props.query.entityName,
    targetName: targetKey,
    limit: runLimit,
    parameters,
    runParameters,
    filters: JSON.stringify(Filter.toMongo(importanceFilters)),
  };

  const importanceData = useParameterImportanceQuery(
    parameterImportanceQueryVariables
  );

  const importances = useMemo(() => {
    if (importanceData.loading || parameterConf == null) {
      return [];
    }
    return _.flatten(
      parameterConf.columnOrder.map(key => {
        const hp = key.split(/:(.+)/)[1];
        const allImportances = importanceData.project.parameterImportance;
        const parameterImportance = allImportances[hp];
        if (parameterImportance == null) {
          // Sometimes parameter importance calls return extra keys because of the way we handle string hparams.
          // Look for those here.
          const keys = Object.keys(allImportances).filter(k =>
            k.startsWith(hp)
          );
          return keys.map(k => ({
            key: k,
            ...allImportances[k],
          }));
        } else {
          return [
            {
              key,
              ...parameterImportance,
            },
          ];
        }
      })
    ).filter(row => row.importance != null && row.correlation != null);
  }, [importanceData, parameterConf]);

  const fetchProjectFieldOptions = useMemo(
    () =>
      makeFetchProjectFieldOptions(
        props.pageQuery.entityName,
        props.pageQuery.projectName,
        {columns: ['summary_metrics']}
      ),
    [props.pageQuery.entityName, props.pageQuery.projectName]
  );

  if (parameterConf == null || props.data.loading) {
    return (
      <div style={{position: 'relative', height: '100%'}}>
        <Loader active />
      </div>
    );
  }
  if (props.data.filtered.length < 2) {
    return (
      <PanelError message="Select at least two runs to visualize parameter importance." />
    );
  }

  return (
    <div className="panel-parameter-importance">
      <h4 className="target-selection-header">
        <div style={{flexGrow: 1}}>
          <span className="panel-title">
            Parameter importance with respect to
          </span>
          <WBSearchableSelect
            infiniteScroll
            options={fetchProjectFieldOptions}
            value={targetKey ? 'summary:' + targetKey : undefined}
            initialOption={
              targetKey
                ? {
                    icon: getIconNameForKey({
                      section: 'summary',
                      name: targetKey,
                    }),
                    value: 'summary:' + targetKey,
                    name: targetKey,
                  }
                : undefined
            }
            fetchInitialValue
            onSelect={setTargetKey}
          />
        </div>
        {props.data.totalRuns > runLimit && (
          <div className="message">Limited to {runLimit} runs</div>
        )}
      </h4>
      <WBReactTable
        loading={importanceData.loading}
        sortable={true}
        pageSize={10}
        defaultSorted={[{id: 'importance', desc: true}]}
        extraActions={
          parameterConf != null ? (
            <>
              <ModalTriggerButton
                buttonIcon="configuration"
                buttonText="Parameters"
                modalHeader="Manage Parameters"
                modalContent={
                  <WBTableColumnEditor
                    allColumns={allConfigKeys}
                    config={parameterConf}
                    update={setParameterConf}
                    objectType="Parameters"
                  />
                }
              />
              <AutoParametersButton
                queryVariables={{
                  ...parameterImportanceQueryVariables,
                  parameters: allConfigKeys,
                }}
                parameterConf={parameterConf}
                setParameterConf={setParameterConf}
              />
            </>
          ) : undefined
        }
        columns={[
          {
            Header: 'Config parameter',
            accessor: 'key',
            Cell: row => {
              const keyDisplayName = keyStringDisplayName(row.row.key);
              return (
                <div className="parameter-name" title={keyDisplayName}>
                  {keyStringDisplayName(keyDisplayName)}
                </div>
              );
            },
          },
          {
            Header: (
              <>
                Importance
                <TargetBlank
                  className="docs-link"
                  href={docUrl.parameterImportance}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}>
                  <LegacyWBIcon title="" name="info" />
                </TargetBlank>
              </>
            ),
            accessor: 'importance',
            Cell: row => (
              <Popup
                inverted
                size="mini"
                position="top center"
                trigger={
                  <div
                    className={classNames(
                      'bar-wrapper',
                      'importance-bar-wrapper',
                      {zero: row.row.importance === 0}
                    )}>
                    <div
                      className="bar"
                      style={{width: `${row.row.importance * 100}%`}}
                    />
                  </div>
                }>
                {row.row.importance.toFixed(3)}
              </Popup>
            ),
            defaultSortDesc: true,
          },
          {
            Header: 'Correlation',
            accessor: 'correlation',
            Cell: row => {
              return (
                <Popup
                  inverted
                  size="mini"
                  position="top center"
                  trigger={
                    <div
                      className={classNames(
                        'bar-wrapper',
                        'correlation-bar-wrapper',
                        {
                          negative: row.row.correlation < 0,
                          zero: row.row.correlation === 0,
                        }
                      )}>
                      <div
                        className="bar"
                        style={{
                          width: `${Math.abs(row.row.correlation * 100)}%`,
                        }}
                      />
                    </div>
                  }>
                  {row.row.correlation.toFixed(3)}
                </Popup>
              );
            },
            id: 'correlation',
            defaultSortDesc: true,
          },
        ]}
        data={importances.map((row, i) => {
          return {
            searchString: row.key,
            row,
          };
        })}
      />
    </div>
  );
}

export const Spec: Panels.PanelSpec<
  typeof PANEL_TYPE,
  ParameterImportanceConfig
> = {
  type: PANEL_TYPE,
  Component: PanelParameterImportance,
  transformQuery,
  noEditMode: true,
  icon: 'panel-importance',
};
