import * as globals from '../../../css/globals.styles';

import styled from 'styled-components';

import {REPORT_FONT_SIZE} from '../constants';
import {GLOBAL_COLORS} from '../../../util/colors';
import {WBSelect} from '@wandb/ui';

export const LanguageSelect = styled(WBSelect)`
  position: absolute;
  top: 4px;
  right: 4px;
  justify-content: flex-end;
  width: 124px;
  background: ${globals.gray50};
`;

export const CodeBlockWrapper = styled.div`
  position: relative;
  ${LanguageSelect} {
    opacity: 0;
    transition: opacity 0.15s;
  }
  &:hover {
    ${LanguageSelect} {
      opacity: 0.85;
    }
  }
`;

export const CodeBlock = styled.pre`
  border-radius: 2px;
  font-family: Inconsolata, Consolas, monospace;
  background: ${globals.gray50};
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  padding: 8px 12px;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
  overflow-x: auto;
`;
