import gql from 'graphql-tag';

export const GALLERY_QUERY = gql`
  query Gallery {
    singletonView(type: "fullyConnected") {
      id
      spec
    }
  }
`;

export const UPDATE_GALLERY_QUERY = gql`
  mutation UpdateGallery($id: ID, $spec: String!) {
    upsertView(
      input: {id: $id, spec: $spec, name: "", type: "fullyConnected"}
    ) {
      view {
        id
        updatedAt
        createdAt
        spec
      }
      inserted
    }
  }
`;

export const FEATURED_REPORTS_METADATA_QUERY = gql`
  query FeaturedReportsMetadata($ids: [ID!]!, $recentStarCountFrom: DateTime) {
    views(ids: $ids) {
      edges {
        node {
          id
          entityName
          project {
            id
            entityName
            name
          }
          displayName
          description
          previewUrl
          starCount
          recentStarCount: starCount(from: $recentStarCountFrom)
          starred
          discussionThreads {
            edges {
              node {
                id
                comments {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FEATURED_REPORTS_AUTHORS_QUERY = gql`
  query FeaturedReportsAuthors($usernames: [String!]!) {
    users(usernames: $usernames) {
      edges {
        node {
          id
          name
          username
          photoUrl
          userInfo
        }
      }
    }
  }
`;

export const SUBMIT_GALLERY_DISCUSSION = gql`
  mutation InsertGalleryDiscussion(
    $spec: String!
    $reportID: ID!
    $sendEmail: Boolean
  ) {
    insertGalleryDiscussion(
      input: {spec: $spec, reportID: $reportID, sendEmail: $sendEmail}
    ) {
      success
    }
  }
`;

export const GALLERY_TAG_SECTIONS_QUERY = gql`
  query GalleryTagSections {
    galleryTagSections
  }
`;

export const PROJECT_ACCESSES_QUERY = gql`
  query ProjectAccess($name: String, $entityName: String) {
    project(name: $name, entityName: $entityName) {
      id
      access
    }
  }
`;

export const NEWSLETTER_SUBSCRIPTION_QUERY = gql`
  query NewsletterSubscription($userName: String!) {
    user(userName: $userName) {
      id
      newsletterSubscription {
        id
      }
    }
  }
`;

export const CREATE_NEWSLETTER_SUBSCRIPTION = gql`
  mutation CreateNewsletterSubscription($email: String) {
    createNewsletterSubscription(input: {email: $email}) {
      newsletterSubscription {
        id
      }
    }
  }
`;

export const ALL_NEWSLETTER_SUBSCRIPTIONS = gql`
  query AllNewsletterSubscriptions($first: Int = 10000) {
    newsletterSubscriptions(first: $first) {
      edges {
        node {
          id
          user {
            id
            username
            email
          }
          email
          createdAt
        }
      }
    }
  }
`;
