import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {WBIcon} from '@wandb/ui';
import CopyableText from './CopyableText';

export const CopyIframeSection = styled.div`
  padding-top: 12px;
`;

export const CopyIframeHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const CopyIframeIcon = styled(WBIcon)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  color: ${globals.white};
  background-color: ${globals.gray500};
  border-radius: 50%;
  margin-right: 8px;
  font-size: 20px;
`;

export const GrayDivider = styled.div`
  border-top: 1px solid ${globals.gray400};
  margin: 12px -24px 0;
`;

export const CopyableTextWrapper = styled.div`
  padding-top: 12px;
`;

export const CopyableIframe = styled(CopyableText)`
  color: ${globals.gray700};
  background: ${globals.gray100};
  width: auto;
  max-width: unset;
  overflow: hidden;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 36px;
  padding: 0.5em 0.75em;
  margin: 0;
  .copy-icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&& {
    .copy-icon {
      opacity: 1;
    }
  }

  .copyable-text-content {
      .single-line-text();
  }

  &:hover {
    color: ${globals.textPrimary};
    &&& {
        .copy-icon {
            color: ${globals.textPrimary};
        }
    }
  }
`;
