import React from 'react';
import _ from 'lodash';
import WBSlate, {WBSlateElement} from './WBSlate';
import * as ReportTypes from '../../state/reports/types';
import {useSelector} from '../../state/hooks';
import {getReportPart, getReportRef} from '../../state/reports/selectors';
import {setBlocks} from '../../state/views/report/actions';
import {useDispatch} from 'react-redux';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import makeComp from '../../util/profiler';

interface WBSlateReduxBridgeContextValue {
  entityName: string;
  projectName: string;
  viewId: string;
  viewRef: ReportTypes.ReportViewRef;
  panelSettingsRef: PanelSettingsTypes.Ref;
}

export const WBSlateReduxBridgeContext =
  React.createContext<WBSlateReduxBridgeContextValue>({} as any);

export interface WBSlateReduxBridgeProps {
  className?: string;
  projectName: string;
  entityName: string;
  viewId: string;
  viewRef: ReportTypes.ReportViewRef;
  readOnly: boolean;
}

const WBSlateReduxBridgeDebounced: React.FC<WBSlateReduxBridgeProps> = makeComp(
  ({className, viewRef, projectName, entityName, viewId, readOnly}) => {
    const dispatch = useDispatch();

    const viewPartRef = useSelector(state => getReportRef(viewRef)(state));
    const viewPart = useSelector(state => getReportPart(viewRef)(state));
    const {blocks, panelSettingsRef, width} = viewPart;

    const reduxValue = React.useMemo(
      () => ({
        entityName,
        projectName,
        viewId,
        panelSettingsRef,
        viewRef,
      }),
      [entityName, projectName, viewId, panelSettingsRef, viewRef]
    );

    const updateRedux = React.useCallback(
      _.debounce((newValue: WBSlateElement[]) => {
        dispatch(setBlocks(viewPartRef, newValue));
      }, 1000),
      [dispatch, viewPartRef]
    );

    const [value, setValue] = React.useState<WBSlateElement[]>(blocks);
    const onChange = React.useCallback(
      newValue => {
        // For some reason, this is fired whenever the selection changes.
        // Only update when the actual content changes.
        if (newValue !== value) {
          setValue(newValue);
          updateRedux(newValue);
        }
      },
      [value, updateRedux]
    );

    return (
      <WBSlateReduxBridgeContext.Provider value={reduxValue}>
        <WBSlate
          readOnly={readOnly}
          value={value}
          widthMode={width}
          viewRef={viewRef}
          viewID={viewId}
          onChange={onChange}
        />
      </WBSlateReduxBridgeContext.Provider>
    );
  },
  {id: 'WBSlateReduxBridge', memo: true}
);

export default WBSlateReduxBridgeDebounced;
