import * as S from './ProjectActionMenu.styles';
import * as React from 'react';
import PopupDropdown from './PopupDropdown';
import LegacyWBIcon from './elements/LegacyWBIcon';
import ProjectRenameModal from './ProjectRenameModal';
import {useDeleteModelMutation} from '../generated/graphql';
import {Project} from '../graphql/allProjectsQuery';
import {DeleteProjectModal} from './DeleteProjectModal';
import HighlightedIcon from './HighlightedIcon';
import makeComp from '../util/profiler';

type ProjectActionMenuProps = {
  project: Project;
  refetch?: () => Promise<any>;
};

export const ProjectActionMenu: React.FC<ProjectActionMenuProps> = makeComp(
  ({project, refetch}) => {
    const [renamingProject, setRenamingProject] = React.useState(false);
    const [deleteProjectModalOpen, setDeleteProjectModalOpen] =
      React.useState(false);
    const [deleteProject] = useDeleteModelMutation();
    const onDeleteProject = React.useCallback(async () => {
      await deleteProject({variables: {id: project.id}});
      if (refetch) {
        await refetch();
        setDeleteProjectModalOpen(false);
      } else {
        window.location.reload();
      }
    }, [deleteProject, refetch, project.id]);
    return (
      <S.Wrapper>
        <div className="project-action-menu">
          <ProjectRenameModal
            open={renamingProject}
            targetProject={{name: project.name, entityName: project.entityName}}
            onClose={() => setRenamingProject(false)}
            onRename={() => window.location.reload()}
          />
          <PopupDropdown
            offset={'10px, -10px'}
            position="bottom right"
            trigger={
              <HighlightedIcon
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                }}>
                <LegacyWBIcon name="overflow" />
              </HighlightedIcon>
            }
            options={[
              {
                text: 'Rename',
                icon: 'wbic-ic-edit',
                onClick: () => {
                  setRenamingProject(true);
                },
              },
              {
                text: 'Delete',
                icon: 'wbic-ic-delete',
                onClick: () => setDeleteProjectModalOpen(true),
              },
            ]}
          />
          <DeleteProjectModal
            open={deleteProjectModalOpen}
            projectName={project.name}
            onClose={() => setDeleteProjectModalOpen(false)}
            deleteProject={onDeleteProject}
          />
        </div>
      </S.Wrapper>
    );
  },
  {id: 'ProjectActionMenu'}
);
