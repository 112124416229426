import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const ExampleSection = styled.div`
  padding: 16px 32px;
`;

export const ExampleSectionHeader = styled.div`
  padding: 12px 0;
`;

export const ExampleSectionTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
`;

export const ExampleSectionDescription = styled.div`
  padding-top: 8px;
`;

export const CodeBlockWrapper = styled.div`
  padding: 8px 0px;
`;

export const CodeBlockDescription = styled.div``;

export const InlineCode = styled.span`
  padding: 3px;
  font-family: Inconsolata;
  background-color: ${globals.gray200};
  color: ${globals.textPrimary};
`;
