import React, {FC, useState, useCallback, useRef} from 'react';
import {Header, Input} from 'semantic-ui-react';
import LegacyWBIcon from './LegacyWBIcon';

import '../../css/SearchHeader.less';
import {ReactNode} from 'react';
import makeComp from '../../util/profiler';

interface SearchHeaderProps {
  header: ReactNode;
  placeholder: string;
  searchQuery: string;
  setSearchQuery: (x: string) => void;
}

/**
 * header // title of the search bar
 * placeholder // place holder in the search bar
 * searchQuery // searchQuery state in parent
 * setSearchQuery // helper function to update searchQuery in parent
 * Usage:
 * <SearchHeader
 *  header="Runs"
 *  placeholder="Search"
 *  searchQuery={this.state.searchQuery}
 *  setSearchQuery={x => this.setState({searchQuery: x})}
 * />
 * You can see this example in components/UserOverview.jsx
 */
const SearchHeader: FC<SearchHeaderProps> = makeComp(
  ({header, placeholder, searchQuery, setSearchQuery}) => {
    const [searchFocused, setSearchFocused] = useState(false);
    const inputRef = useRef<Input | null>(null);

    const focus = useCallback(() => {
      setSearchFocused(true);
      if (inputRef.current != null) {
        inputRef.current.focus();
      }
    }, []);

    const unfocus = useCallback(() => {
      setSearchFocused(false);
      setSearchQuery('');
    }, [setSearchQuery]);

    const onKeyDown = useCallback(
      (e: any) => {
        if (e.keyCode === 27) {
          unfocus();
        }
      },
      [unfocus]
    );

    return (
      <div
        className={'searchheader' + (searchFocused ? ' search-visible' : '')}>
        <Header>{React.cloneElement(<div>{header}</div>)}</Header>
        <div className="searchheader-search">
          <LegacyWBIcon
            name="search"
            className="searchheader-icon"
            size="big"
            onClick={focus}
          />
          <Input
            ref={inputRef}
            placeholder={placeholder}
            value={searchQuery || ''}
            onChange={(e, {value}) => {
              setSearchQuery(value);
            }}
            onKeyDown={onKeyDown}
          />
          <LegacyWBIcon
            name="close"
            className="searchheader-close-icon"
            size="big"
            onClick={unfocus}
          />
        </div>
      </div>
    );
  },
  {id: 'SearchHeader', memo: true}
);

export default SearchHeader;
