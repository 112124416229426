import _ from 'lodash';
import {BenchmarkRun, RunInfo} from '../generated/graphql';

// import * as numeral from 'numeral';
import {isArray} from 'util';
import * as GQLTypes from '../types/graphql';
import {MediaString, mediaStrings} from '../types/media';
import {RunHistoryKeyInfo, RunHistoryRow} from '../types/run';
import {NULL_STRING, RESERVED_KEYS} from './constants';
import {flatten} from './flatten';
import {JSONparseNaN} from '@wandb/cg/browser/utils/jsonnan';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Parse from './parse';
import * as String from '@wandb/cg/browser/utils/string';
import Moment from 'moment';
import * as RunsDataLoader from '../containers/RunsDataLoader';
import * as Query from './queryts';
import produce from 'immer';

import numeral from 'numeral';

export type SimpleKey = string;
export type DeepKey = string[];
export type LookupKey = SimpleKey | DeepKey;

export function summaryKey(k: string) {
  return ['summary', k];
}

// Maps run or group to a unique ID
// Do not change this function! It will change all of the colors
// that users have configured
export function uniqueId(
  run: RunsDataLoader.RunWithRunsetInfo,
  groupKeys: Key[],
  includeRunsetId: boolean = true
) {
  let uniqueKey = run.name;
  if (groupKeys.length > 0) {
    // Only include the runsetInfo in the key when we're grouping. This is because
    // we want runs in different runsets to have the same color, but groups
    // should still have different colors. Ideally we would hash the filters or
    // something, but eh.
    uniqueKey =
      (includeRunsetId ? run.runsetInfo.id + '-' : '') +
      groupKeys
        .map(gk => {
          const val = getValue(run, gk);
          const valString = val == null ? 'null' : val.toString();
          return `${keyToString(gk)}:${valString}`;
        })
        .join('-');
  }
  return uniqueKey;
}

export function lookupRunset(
  run: RunsDataLoader.RunWithRunsetInfo,
  query: Query.Query
) {
  if (query.runSets == null) {
    return undefined;
  }
  return query.runSets.find(rs => rs.id === run.runsetInfo.id);
}

export function groupedRunDisplayName(
  run: RunsDataLoader.RunWithRunsetInfo,
  groupKeys: Key[]
) {
  const groupKeysStr = groupKeys
    .map(gKey => {
      const keyStr = keyDisplayName(gKey);
      const value = run ? getValue(run, gKey) : null;
      const valueStr = value != null ? value.toString() : '';
      return keyStr + ': ' + valueStr;
    })
    .join(' ');
  return groupKeysStr;
}

// Wrap in a summary lookup if not nested
// Warning:  ⚠️
// This is a utility function for benchmarks to make
// lookup simple for summary. They should be migrated to
// use full key lookups.
export const lookupKey = (
  r: GQLTypes.WithSummary<BenchmarkRun>,
  lk: LookupKey
) => {
  const k: string[] = isArray(lk) ? lk : summaryKey(lk);
  return _.get(r, k);
};

export const flattenLookupKey = (k: LookupKey) => {
  return isArray(k) ? k.toString() : k;
};

// Use this key to group all records together. Grouping by a non-existent key
// results in a single group.
export const GROUP_BY_ALL_KEY: Key = {
  section: 'config',
  name: '__wb_group_by_all',
};

// These two must match
// The special keys_info section is not actually present on returned runs, but
// we can construct filters using it, to find runs that have specific history
// keys.
const runKeySections = ['run', 'tags', 'config', 'summary', 'keys_info'];
export type RunKeySection = 'run' | 'tags' | 'config' | 'summary' | 'keys_info';
// TODO: Is there a way to constrain name when section is run?
export interface Key {
  section: RunKeySection;
  name: string;
}

export interface Counts {
  runs?: number; // total number of runs
  filtered?: number; // number of visible runs (based on current filters)
  selected?: number; // number of currently-selected runs
}

export function key(section: RunKeySection, name: string): Key {
  return {section, name};
}

export function keyString(section: RunKeySection, name: string): string {
  return keyToString({section, name});
}

export function configKey(name: string): Key {
  return {section: 'config', name};
}

export function checkKey(section: string, name: string): Key | null {
  if (_.indexOf(runKeySections, section) === -1) {
    return null;
  }
  return key(section as RunKeySection, name);
}

// _.isEqual is relatively expensive for small objects. this implements a simple
// equals check that runs 100x faster than _.isEqual.
export function keysEqual(keyA: Key, keyB: Key): boolean {
  return keyA.section === keyB.section && keyA.name === keyB.name;
}

export function keyFromString(keyStr: string): Key | null {
  let [section, name] = String.splitOnce(keyStr, ':');
  if (name == null) {
    name = section;
    section = 'run';
  }
  return checkKey(section, name);
}

export function keyFromJSON(json: any): Key | null {
  if (json == null) {
    return null;
  }
  if (!_.isString(json.section) || !_.isString(json.name)) {
    return null;
  }
  return checkKey(json.section, json.name);
}

export function keyToString(k: Key): string {
  return k.section + ':' + k.name;
}

export function keyToCss(k: Key): string {
  return keyToString(k).replace(':', '_').replace('.', '_');
}

export const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];
export function isTimeKeyString(k: string): boolean {
  return _.includes(TIME_KEYS, k);
}

export function formatTimestamp(timestamp: string) {
  return Moment(timestamp).format("MMM DD 'YY HH:mm");
}

export interface WBValue {
  _type: MediaString;
  [key: string]: any;
}

export interface DataFrame extends WBValue {
  id: string;
  format: string;
  current_project_name: string;
  path: string;
}

export interface Media extends WBValue {
  path: string;
  sha256: string;
  size: number;
  entity: string;
  project: string;
  run: string;
}

// These aren't quite correct, since values can also be fully nested objects and arrays
export type BasicValue = string | number | boolean | null;
export type Value = BasicValue | BasicValue[] | WBValue;

export type DomValue = string | number;

// config and summary are stored as KeyVal
export interface KeyVal {
  // The compiler doesn't like when we an array of runs that have different config keys (in tests),
  // unless we allow undefined here.
  readonly [key: string]: Value | undefined;
}

export interface User {
  username: string;
  photoUrl: string;
}

interface Sweep {
  readonly name: string;
  readonly displayName: string;
}

interface Agent {
  readonly name: string;
}

interface ServicesAvailable {
  readonly tensorboard: boolean;
}

export interface Run {
  readonly id: string;
  readonly name: string;
  readonly projectId?: number;
  readonly description?: string;
  readonly commit?: string;
  readonly github?: string;
  readonly group: string;
  readonly jobType: string;
  readonly sweep?: Sweep;
  readonly agent?: Agent;
  readonly state: string; // TODO: narrow this type
  readonly user: User;
  readonly host: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly heartbeatAt: string;
  readonly tags: GQLTypes.Tag[];
  readonly displayName: string;
  readonly notes: string;
  readonly config: KeyVal;
  readonly _wandb: KeyVal;
  readonly summary: KeyVal;
  readonly groupCounts?: number[];
  readonly stopped: boolean;
  readonly benchmarkRun?: BenchmarkRun;
  readonly sampledHistory?: RunHistoryRow[][];
  readonly historyKeys?: RunHistoryKeyInfo;
  readonly defaultColorIndex?: number;
  readonly servicesAvailable?: ServicesAvailable;
  readonly runInfo?: RunInfo;
  readonly selectedRunName?: string;
  readonly outputArtifactsCount?: number;
  readonly inputArtifactsCount?: number;
  readonly logLineCount?: number;
}

export function fromJson(json: any): Run | null {
  // Safely parse a json object as returned from the server into a validly typed Run
  // This used to return null in a lot more cases, now if we receive invalid data we
  // set the values to defaults. This happens when we select specific fields from
  // the run in the graphql query (for Scatter and Parallel Coordinates plots). It'd
  // probably be better to have a special type for those cases, instead of using default
  // values, so that other parts of the code has better guarantees about what to expect.
  if (typeof json !== 'object') {
    return null;
  }
  const id = json.id;
  if (typeof id !== 'string' || id.length === 0) {
    // console.warn(`Invalid run id: ${json.id}`);
    return null;
  }

  const name = json.name;
  if (
    typeof name !== 'string' ||
    (name.length === 0 && json.groupCounts == null)
  ) {
    // console.warn(`Invalid run name: ${json.name}`);
    return null;
  }

  const group = json.group;
  const jobType = json.jobType;
  const projectId = json.projectId == null ? undefined : Number(json.projectId);

  const outputArtifactsCount = json.outputArtifacts?.totalCount ?? 0;
  const inputArtifactsCount = json.inputArtifacts?.totalCount ?? 0;
  const logLineCount = json.logLineCount;

  let sweep = json.sweep;
  if (sweep == null || typeof sweep.name !== 'string') {
    sweep = undefined;
  }

  let agent = json.agent;
  if (agent == null || typeof agent.name !== 'string') {
    agent = undefined;
  }

  let state = json.state;
  if (typeof name !== 'string' || name.length === 0) {
    state = 'unknown';
  }

  let user = json.user;
  if (user == null || user.username == null || user.photoUrl == null) {
    user = {
      name: '',
      photoUrl: '',
    };
  }

  let host = json.host;
  if (typeof host !== 'string' && host !== null) {
    host = '';
  }

  let github = json.github;
  if (typeof github !== 'string') {
    github = undefined;
  }

  let createdAt = Parse.parseDate(json.createdAt);
  if (createdAt == null) {
    createdAt = new Date();
  }

  let updatedAt = Parse.parseDate(json.updatedAt);
  if (updatedAt == null) {
    updatedAt = new Date(0);
  }

  let heartbeatAt = Parse.parseDate(json.heartbeatAt);
  if (heartbeatAt == null) {
    heartbeatAt = new Date();
  }

  let tags = json.tags;
  if (!(tags instanceof Array)) {
    tags = [];
  }

  let splitConfig: any;
  if (json.config == null) {
    // we don't always pull config. just make it empty
    splitConfig = {config: {}, _wandb: {}};
  } else {
    if (json.groupCounts == null) {
      // When not grouping, config can be deeply nested
      splitConfig = parseConfig(json.config, name);
    } else {
      // When grouping, config is flattened on the server :(
      splitConfig = parseFlatConfig(json.config, name);
    }
    if (splitConfig == null) {
      return null;
    }
  }

  let summary;
  if (json.summaryMetrics == null) {
    // we don't always pull summary. just make it empty
    summary = {};
  } else {
    summary = parseSummary(json.summaryMetrics, name);
    if (summary == null) {
      return null;
    }
  }
  let wandb;
  if (json.wandbConfig == null) {
    wandb = undefined;
  } else {
    wandb = JSONparseNaN(json.wandbConfig);
  }

  // 'wandb_version' is an extra thing put in by the CLI that is a misnomer
  // and useless.
  if (splitConfig.config.wandb_version != null) {
    const conf = splitConfig.config as any;
    delete conf.wandb_version;
  }

  const benchmarkRun = json.benchmarkRun;

  const runInfo = json.runInfo;

  return {
    id,
    name,
    github,
    group,
    jobType,
    projectId,
    sweep,
    agent,
    state,
    user,
    host,
    stopped: json.stopped,
    createdAt: createdAt.toISOString(),
    updatedAt: updatedAt.toISOString(),
    heartbeatAt: heartbeatAt.toISOString(),
    tags,
    displayName: typeof json.displayName === 'string' ? json.displayName : '',
    notes: typeof json.notes === 'string' ? json.notes : '',
    config: splitConfig.config,
    _wandb: wandb || splitConfig._wandb,
    summary,
    groupCounts: json.groupCounts,
    benchmarkRun,
    sampledHistory: json.sampledHistory?.map((rs: RunHistoryRow[]) =>
      rs.map(unpackMetrics)
    ),
    outputArtifactsCount,
    inputArtifactsCount,
    logLineCount,
    historyKeys: json.historyKeys,
    defaultColorIndex: json.defaultColorIndex,
    servicesAvailable: json.servicesAvailable,
    runInfo,
  };
}

export function splitFlatConfig(flatConfig: KeyVal): {
  config: KeyVal;
  _wandb: KeyVal;
} {
  const wandb: {[key: string]: Value | undefined} = {};
  const config: {[key: string]: Value | undefined} = {};
  _.forEach(flatConfig, (v, k) => {
    if (k.includes('.desc')) {
      // drop
    } else if (k.startsWith('_wandb.')) {
      wandb[k.substring('_wandb.'.length)] = v;
    } else {
      config[k] = v;
    }
  });
  return {config, _wandb: wandb};
}

export function flattenConfig(config: any) {
  return _.pickBy(
    removeEmptyObjects(flatten(config, {safe: true})),
    (v, k) => !k.includes('.desc')
  );
}

function parseConfig(
  confJson: any,
  runName: string
): null | {config: KeyVal; _wandb: KeyVal} {
  let nestedConfig: any;
  try {
    nestedConfig = JSONparseNaN(confJson);
  } catch {
    console.warn(`Couldn't parse config for run ${runName}:`, confJson);
    return null;
  }
  if (typeof nestedConfig !== 'object') {
    console.warn(`Invalid config for run ${runName}:`, confJson);
    return null;
  }
  const flatConfig = removeEmptyObjects(flatten(nestedConfig, {safe: true}));
  const nestedWandb = nestedConfig._wandb;
  const splitConfig = splitFlatConfig(flatConfig);
  const config: KeyVal = {...splitConfig.config, _wandb: nestedWandb};
  const _wandb: KeyVal = splitConfig._wandb;

  return {config, _wandb};
}

function parseFlatConfig(
  confJson: any,
  runName: string
): null | {config: KeyVal; _wandb: KeyVal} {
  let config: any;
  try {
    config = JSONparseNaN(confJson);
  } catch {
    console.warn(`Couldn't parse flat config for run ${runName}:`, confJson);
    return null;
  }
  if (typeof config !== 'object') {
    console.warn(`Invalid flat config for run ${runName}:`, confJson);
    return null;
  }
  return splitFlatConfig(config);
}

export function cleanSummary(summary: any) {
  // Watch out, when stuff is grouped it comes out flattened, but when it's
  // not it comes out nested. This code works in both cases.
  summary = unflattenWandbObjects(summary);
  const cleanedSummary = removeEmptyObjects(flatten(summary, {safe: true}));
  return cleanedSummary;
}

export function parseSummary(confSummary: any, runName: string): KeyVal | null {
  let summary: any;
  try {
    summary = JSONparseNaN(confSummary);
  } catch {
    console.warn(`Couldn't parse summary for run ${runName}:`, confSummary);
    return null;
  }
  if (!Obj.isObject(summary)) {
    summary = {};
  }
  summary = cleanSummary(summary);
  return unpackMetrics(summary);
}

function unflattenWandbObjects(summary: {[key: string]: any}) {
  const result: {[key: string]: any} = {};
  _.forEach(summary, (val, k) => {
    const [s, last] = String.splitOnceLast(k, '.');
    if (s == null) {
      if (result[k] == null) {
        result[k] = val;
      }
    } else {
      if (!_.isObject(result[s])) {
        result[s] = {};
      }
      result[s][last!] = val;
    }
  });
  return result;
}

function removeEmptyObjects(obj: Obj.Obj): Obj.Obj {
  // Flatten will return [] or {} as values. We keys with those values
  // to simplify typing and behavior everywhere else.
  return _.pickBy(
    obj,
    o => !(_.isObject(o) && !_.isArray(o) && _.keys(o).length === 0)
  );
}

export function getValueSafe(run: Run, runKey: Key): Value {
  // equivalent to getValue but fixes config keys with the weird legacy missing .value
  // like name implies safer than getValue
  if (runKey.section === 'config' && !runKey.name.includes('value')) {
    return getValue(run, fixConfigKey(runKey));
  } else {
    return getValue(run, runKey);
  }
}

export function getValue(run: Run, runKey: Key): Value {
  // if you are loading a config value and you haven't added the weird .value
  // this will not return a value
  const {section, name} = runKey;
  if (section === 'run') {
    if (name === 'name') {
      return run.name;
    } else if (name === 'description') {
      return run.description || null;
    } else if (name === 'commit') {
      return run.commit || null;
    } else if (name === 'github') {
      return run.github || null;
    } else if (name === 'displayName') {
      return run.displayName;
    } else if (name === 'group') {
      return run.group;
    } else if (name === 'jobType') {
      return run.jobType;
    } else if (name === 'username' || name === 'userName') {
      return run.user.username;
    } else if (name === 'state') {
      return run.state;
    } else if (name === 'notes') {
      return run.notes === '' ? '-' : run.notes;
    } else if (name === 'host') {
      return run.host;
    } else if (name === 'createdAt') {
      return run.createdAt;
    } else if (name === 'updatedAt') {
      return run.updatedAt;
    } else if (name === 'heartbeatAt') {
      return run.heartbeatAt;
    } else if (name === 'duration') {
      return (
        (new Date(run.heartbeatAt).getTime() -
          new Date(run.createdAt).getTime()) /
        1000
      );
    } else if (name === 'agent' || name === 'agent_id') {
      return (run.agent && run.agent.name) || null;
    } else if (name === 'sweep' || name === 'sweepName') {
      return (run.sweep && run.sweep.name) || null;
    } else if (name === 'stopped') {
      return run.stopped;
    } else if (name === 'runInfo.gpu') {
      return run.runInfo?.gpu ?? null;
    } else if (name === 'runInfo.gpuCount') {
      return run.runInfo?.gpuCount ?? null;
    } else {
      return null;
    }
  } else if (section === 'tags') {
    // Only used for comparing before/after to determine re-render
    return run.tags.map(t => t.name).join(', ');
  } else if (section === 'config') {
    const val = run.config[name];
    return val != null ? val : null;
  } else if (section === 'summary') {
    const val = run.summary[name];
    return val != null ? val : null;
  }
  return null;
}

export function getValueFromKeyString(run: Run, keyStr: string): Value {
  const k = keyFromString(keyStr);
  if (k == null) {
    throw new Error('invalid key');
  }
  return getValue(run, k);
}

export function getTagsString(run: Run): string {
  return run.tags.map(t => t.name).join(', ');
}

// Returns Date types. This should be in getValue but we'd need to go and update
// all the call-sites. So it's split out for now.
export function getValueExtra(run: Run, runKey: Key) {
  const {section, name} = runKey;
  if (section === 'run' && name === 'createdAt') {
    return new Date(run.createdAt);
  } else if (section === 'run' && name === 'updatedAt') {
    return new Date(run.updatedAt);
  } else if (section === 'run' && name === 'heartbeatAt') {
    return new Date(run.heartbeatAt);
  }
  return getValue(run, runKey);
}

const nonMediaWBValueTypes = [
  'graph',
  'graph-file',
  'histogram',
  'histogram-file',
] as const;

export type NonMediaWBValueType = typeof nonMediaWBValueTypes[number];

const wbValueTypes = _.union(mediaStrings, nonMediaWBValueTypes);

export function isWBValue(value: Value | undefined): value is WBValue {
  return _.isObject(value) && _.includes(wbValueTypes, (value as any)._type);
}

export function sortableValue(value: Value) {
  if (typeof value === 'number' || typeof value === 'string') {
    return value;
  } else {
    return JSON.stringify(value);
  }
}

export function valueString(value: Value) {
  if (value == null) {
    return NULL_STRING;
  }
  return value.toString();
}

export function displayKey(k: Key) {
  if (k.section && k.name !== '') {
    if (k.section === 'run') {
      return k.name;
    } else {
      return k.section + ':' + k.name;
    }
  } else {
    return '-';
  }
}

export function prettyNumber(value: number): string {
  if (_.isFinite(value)) {
    if (_.isInteger(value)) {
      return value.toString();
    } else {
      if (value < 1 && value > -1) {
        let s = value.toPrecision(4);
        while (s[s.length - 1] === '0') {
          s = s.slice(0, s.length - 1);
        }
        return s;
      } else {
        return numeral(value).format('0.[000]');
      }
    }
  } else {
    return value.toString();
  }
}

export function displayValue(value: Value, nullVal: string = '-'): string {
  if (value == null) {
    return nullVal;
  } else if (typeof value === 'number') {
    return prettyNumber(value);
  }
  return value.toString();
}

export function domValue(value: Value): DomValue {
  if (typeof value === 'number' || typeof value === 'string') {
    return value;
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  return NULL_STRING;
}

export function parseValue(val: any): Value {
  let parsedValue: Value = null;
  if (typeof val === 'number' || typeof val === 'boolean') {
    parsedValue = val;
  } else if (typeof val === 'string') {
    parsedValue = parseFloat(val);
    if (!isNaN(parsedValue)) {
      // If value is '3.' we just get 3, but we return the string '3.' so this can be used in input
      // fields.
      if (parsedValue.toString().length !== val.length) {
        parsedValue = val;
      }
    } else {
      if (val.indexOf('.') === -1) {
        if (val === 'true') {
          parsedValue = true;
        } else if (val === 'false') {
          parsedValue = false;
        } else if (val === NULL_STRING) {
          parsedValue = null;
        } else if (typeof val === 'string') {
          parsedValue = val;
        }
      } else {
        parsedValue = val;
      }
    }
  }
  return parsedValue;
}

export function serverPathToKey(pathString: string): Key | null {
  const [section, name] = String.splitOnce(pathString, '.');
  if (name == null) {
    return null;
  }
  if (section === 'tags') {
    return {
      section: 'tags',
      name,
    };
  } else if (section === 'config') {
    if (name.includes('.desc')) {
      return null;
    }
    return {
      section: 'config',
      name,
    };
  } else if (section === 'summary_metrics') {
    return {
      section: 'summary',
      name,
    };
  }
  return null;
}

export function serverPathToKeyString(pathString: string): string | null {
  const k = serverPathToKey(pathString);
  if (k == null) {
    return null;
  }
  return keyToString(k);
}

export function keyToServerPath(k: Key): string {
  if (k.section === 'config') {
    return 'config.' + k.name;
  } else if (k.section === 'summary') {
    return 'summary_metrics.' + k.name;
  } else if (k.section === 'keys_info') {
    return 'keys_info.keys.' + k.name;
  } else if (k.section === 'run') {
    return k.name;
  } else if (k.section === 'tags') {
    return 'tags.' + k.name;
  }
  // Wouldn't need this throw if we use a switch above
  throw new Error('keyToServerPath error');
}

export function keyStringToServerPath(keyStr: string): string | null {
  const k = keyFromString(keyStr);
  if (k == null) {
    return null;
  }
  return keyToServerPath(k);
}

function shouldAddDotValue(k: Key): boolean {
  return (
    k.section === 'config' &&
    !k.name.includes('.value') &&
    !keysEqual(k, GROUP_BY_ALL_KEY)
  );
}

export function fixConfigKey(k: Key): Key {
  // adds .value to config key
  if (!shouldAddDotValue(k)) {
    return k;
  }
  const splitName = k.name.split('.');
  const splitNameWithValue = [...splitName, 'value'];
  return {
    ...k,
    name: splitNameWithValue.join('.'),
  };
}

export function fixConfigKeyString(ks: string): string {
  const k = keyFromString(ks);
  if (k == null) {
    // punt
    return ks;
  }
  return keyToString(fixConfigKey(k));
}

// For legacy reasons, we need to add ".value" to key strings
// We flatten nested keys by joining them with dots and adding '.value' after the first dot
// e.g. {a: {b: {c: 1}}} => {a.value.b.c: 1},
// For non-nested keys, we add '.value' at the end
// e.g. {a.b.c: 1} => {a.b.c.value: 1}
// If a key is already flat, we don't know if it's actually nested or if it just has dots :(
// So this function generates all possibilities (e.g. key names ['a.value.b.c', 'a.b.value.c', 'a.b.c.value'],
// which we can then test against the run data
export function keyPossibilities(k: Key): Key[] {
  if (!shouldAddDotValue(k)) {
    return [k];
  }
  const splitName = k.name.split('.');

  const keyStringPossibilities: string[] = [];
  for (let i = 0; i < splitName.length; i++) {
    const nestedKeyTest = produce(splitName, draft => {
      draft.splice(i + 1, 0, 'value');
    });
    keyStringPossibilities.push(nestedKeyTest.join('.'));
  }

  return keyStringPossibilities.map(name => ({...k, name}));
}

export const isReservedKey = (k: string) => {
  return RESERVED_KEYS.some(rk => k.startsWith(rk));
};

export function rawConfigKeyDisplayName(k: string): string {
  return k.replace('.value', '');
}

export function keyDisplayName(k: Key, verbose?: boolean): string {
  if (keysEqual(k, GROUP_BY_ALL_KEY)) {
    // LB: I do not understand what this is doing but I am scared to remove it
    return 'Grouped runs';
  }

  if (k.section === 'tags') {
    // Handles the case of "Tags" as a category label in WBTable and Filter dropdown
    return 'Tags';
  } else if (k.section === 'run') {
    switch (k.name) {
      case 'name':
        return 'ID';
      case 'displayName':
        return 'Name';
      case 'userName':
        return 'User';
      case 'username':
        return 'User';
      case 'notes':
        return 'Notes';
      case 'group':
        return 'Group';
      case 'jobType':
        return 'Job Type';
      case 'createdAt':
        if (verbose) {
          return 'Created Timestamp';
        } else {
          return 'Created';
        }
      case 'updatedAt':
        if (verbose) {
          return 'Updated Timestamp';
        } else {
          return 'Updated';
        }
      case 'heartbeatAt':
        if (verbose) {
          return 'Latest Timestamp';
        } else {
          return 'End Time';
        }
      case 'duration':
        return 'Runtime';
      case 'agent':
        return 'Agent';
      case 'stopped':
        return 'Stopped';
      case 'sweep':
        return 'Sweep';
      case 'state':
        return 'State';
      case 'host':
        return 'Hostname';
      case 'description':
        return 'Description';
      case 'commit':
        return 'Commit';
      case 'github':
        return 'GitHub';
      case 'runInfo.gpu':
        return 'GPU Type';
      case 'runInfo.gpuCount':
        return 'GPU Count';
      case 'inputArtifacts':
        return 'Using Artifact';
      case 'outputArtifacts':
        return 'Outputting Artifact';
      default:
        return k.name;
    }
  } else if (k.section === 'config') {
    return rawConfigKeyDisplayName(k.name);
  } else if (k.section === 'summary' && k.name === '__preview__') {
    // hack for dataframe preview column
    return 'Preview';
  } else if (k.section === 'summary') {
    if (k.name === '_runtime') {
      return 'Relative Time (Process)';
    } else if (k.name === '_timestamp') {
      return 'Wall Time';
    } else if (k.name === '_step') {
      return 'Step';
    }
  }
  return k.name;
}

export function keyStringDisplayName(s: string): string {
  const k = keyFromString(s);
  if (k == null) {
    return s;
  }
  return keyDisplayName(k);
}

// returns a string describing a group of runs, like "15 total runs, 9 filtered, 8 selected. Grouped by ["run:*"] Sorted by: run:createdAt ASC"
export function runsSummaryString(runsSummary: {
  counts?: {runs: number; filtered: number; selected: number};
  grouping?: Key[];
  sort?: {key: Key; ascending: boolean};
}) {
  const {counts, grouping, sort} = runsSummary;
  const countsString =
    counts &&
    `${counts.runs} total runs, ${counts.filtered}
        filtered, ${counts.selected} selected.`;
  const groupingString =
    grouping &&
    grouping.length > 0 &&
    `Grouped by: ${JSON.stringify(grouping.map((k: Key) => keyToString(k)))}`;
  const sortString =
    sort &&
    `Sorted by: ${keyToString(sort.key)} ${sort.ascending ? 'ASC' : 'DESC'}`;
  const summaryString = _.compact([
    countsString,
    groupingString,
    sortString,
  ]).join(' ');
  return summaryString;
}

export function notes(run: Pick<Run, 'notes'>) {
  if (run.notes && run.notes.length > 0) {
    return run.notes;
  } else {
    return;
  }
}

export function unpackMetrics(metrics: any): any {
  if (metrics == null) {
    return null;
  }
  _.forEach(metrics, (value, k) => {
    if (isHistogram(value)) {
      metrics[k] = getUnpackedHistogram(value);
    }
  });
  return metrics;
}

interface BaseHistogram {
  _type: 'histogram';
  values: number[];
}

interface UnpackedHistogram extends BaseHistogram {
  bins: number[];
}

interface PackedHistogram extends BaseHistogram {
  packedBins: PackedBins;
}

interface PackedBins {
  min: number;
  size: number;
  count: number;
}

type Histogram = UnpackedHistogram | PackedHistogram;

function isHistogram(h: any): h is Histogram {
  if (h == null) {
    return false;
  }
  return (
    h._type === 'histogram' &&
    h.values != null &&
    (h.bins != null || h.packedBins != null)
  );
}

// This logic should mirror the backend histogram unpack logic in gorilla/pack.go
export function getUnpackedHistogram(h: Histogram): UnpackedHistogram {
  if ('bins' in h) {
    // histogram already unpacked
    return h;
  }

  const bins: number[] = [];
  for (let i = 0; i <= h.packedBins.count; i++) {
    bins.push(h.packedBins.min + i * h.packedBins.size);
  }
  return {
    ..._.omit(h, 'packedBins'),
    bins,
  };
}
