// Implements a gql query on project, currently used by the Group
// page. This is a good example of how to create an HOC that does an
// Apollo query. apollo-client's graphql HOC doesn't allow passing
// through variables in a Typesafe way, as far as I can tell, so we resort
// to creating our own HOC's. We could actually generalize this into a // Typesafe variant of the graphql() function, but it seems reasonable // to just copy this file and modify it for new queries, rather than
// trying to abstract it even more.
// This file and the above text was copied from projectPageQuery.
// A benchmark is represented by a project in the database.
// A project has started to take on the notion of a group of runs.
import gql from 'graphql-tag';
import * as React from 'react';
import {Query} from 'react-apollo';

import {Subtract} from '../types/base';
import {BenchmarkProject} from '../types/graphql';
import makeComp from '../util/profiler';
import {captureError} from '../util/integrations';

///// Query definition

export const BENCHMARK_PROJECT_QUERY = gql`
  query BenchmarkProject($name: String!, $entityName: String) {
    project(name: $name, entityName: $entityName) {
      id
      access
      name
      description
      readOnly
      entityName
      views
      gitHubSubmissionRepo

      acceptedBenchmarkRuns: benchmarkRuns(state: "ACCEPTED") {
        ...benchmarkRunsFragment
      }
      rejectedBenchmarkRuns: benchmarkRuns(state: "REJECTED") {
        ...benchmarkRunsFragment
      }
      submittedBenchmarkRuns: benchmarkRuns(state: "SUBMITTED") {
        ...benchmarkRunsFragment
      }
      entity {
        id
        name
        photoUrl
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
      }
    }
  }

  fragment benchmarkRunsFragment on BenchmarkRunConnection {
    edges {
      node {
        id
        gitHubSubmissionPR
        createdAt
        details
        results
        state
        user {
          id
          username
          photoUrl
          admin
        }
        originalRun {
          id
          name
          displayName
          project {
            id
            name
            entityName
          }
        }
        originalProject {
          id
          name
          entityName
        }
        run {
          github
          commit
          id
          name
          displayName
          notes
          user {
            id
            username
          }
          diffPatchFile: files(first: 1, names: ["diff.patch"]) {
            edges {
              node {
                id
                name
                md5
                sizeBytes
              }
            }
          }
          summaryMetrics
        }
        benchmark {
          id
          name
          entityName
        }
      }
    }
  }
`;

// ${fragments.benchmarkRun}

// Any variables required by the query.
export interface Variables {
  entityName: string;
  name: string;
}

// The query's output shape.
interface Result {
  // We use projects as groups of runs on the backend.
  // Benchmark are implemented as a project.
  project: BenchmarkProject;
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  match: {
    params: {
      entityName: string;
      // A benchmark is represented by a project as a group of runs.
      projectName: string;
    };
  };
}

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  benchmark: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  benchmark: BenchmarkProject;
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface BenchmarkPageQueryResultProps {
  pageQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withBenchmarkPageQuery = <P extends object>(
  Component: React.ComponentType<P & BenchmarkPageQueryResultProps>
) =>
  makeComp(
    (inputProps: Subtract<P, BenchmarkPageQueryResultProps> & InputProps) => {
      const {match} = inputProps;
      const variables = {
        entityName: match.params.entityName,
        name: match.params.projectName,
      };
      return (
        <Query<Result, Variables>
          query={BENCHMARK_PROJECT_QUERY}
          variables={variables}>
          {rawQueryResult => {
            const result: BenchmarkPageQueryResultProps = {
              pageQuery: {
                benchmark: undefined,
                loading: true,
              },
            };
            if (!rawQueryResult.loading) {
              if (
                rawQueryResult.data == null ||
                rawQueryResult.data.project === undefined
              ) {
                captureError(
                  'Unexpected Apollo error, loading: false, data: undefined',
                  'withBenchmarkPageQuery',
                  {extra: {rawQueryResult}}
                );
                throw new Error(
                  'Unexpected Apollo error, loading: false, data: undefined'
                );
              }

              result.pageQuery = {
                loading: false,
                benchmark: rawQueryResult.data.project,
              };
            }
            return <Component {...(inputProps as P)} {...result} />;
          }}
        </Query>
      );
    },
    {id: 'withBenchmarkPageQuery'}
  );
