import _ from 'lodash';
import {datadogLogs} from '@datadog/browser-logs';
import {Context} from '@datadog/browser-core';
import * as Sentry from '@sentry/browser';
import {PlanType} from '../generated/graphql';
import {Viewer} from '../state/viewer/types';
import config, {envIsDev} from '../config';

const datadogEnabled = !config.ANALYTICS_DISABLED && envIsDev;

if (datadogEnabled) {
  datadogLogs.init({
    clientToken: 'pubbbf74d2a526a76acb379ca4102d98808',
    forwardErrorsToLogs: false,
  });
}

interface GithubInfo {
  githubUsername?: string;
  githubCompany?: string;
  githubURL?: string;
  githubFollowers?: number;
}

function formatCompanyForAnalytics(org: Viewer['organizations'][0]) {
  let company = {
    // ID is stable and will preserve analytics history if the
    // org is ever renamed.
    id: org.id,
    name: org.name,
    website: null,
  };

  if (org.subscriptions && org.subscriptions.length > 0) {
    const sub = org.subscriptions.find(
      s => s.plan.planType === PlanType.Primary
    );
    const planTypes = org.subscriptions.map(s => s.plan.planType).join();

    company = Object.assign(company, {
      size: sub?.seats ?? 0, // .plan.maxSeats?
      plan_type: planTypes,
    });
  }

  return company;
}

async function formatViewerForAnalytics(viewer: Viewer) {
  let organization = '';
  let company = null;
  if (viewer.organizations && viewer.organizations.length > 0) {
    organization = viewer.organizations[0].name;
    company = formatCompanyForAnalytics(viewer.organizations[0]);
  }

  const metadata: any = {};
  if (viewer.teams.edges.length > 0) {
    metadata.teamCount = viewer.teams.edges.length;
    metadata.team = viewer.entity;
    // TODO: Segment / intercom doesn't seem to support arrays
    metadata.teams = viewer.teams.edges.map(e => e.node.name).join(', ');
    metadata.isPaid = viewer.teams.edges.some(e => e.node.isPaid);
  }
  const surveyAnswers = viewer.userInfo
    ? {
        howOften: viewer.userInfo.howOften,
      }
    : {};
  const githubUserInfo = await fetchGitHubUserInfo(viewer);

  return {
    email: viewer.email,
    username: viewer.username,
    name: viewer.name,
    createdAt: viewer.createdAt,
    website: viewer.userInfo?.websiteUrl,
    description: viewer.userInfo?.bio,
    company,
    organization,
    ...surveyAnswers,
    ...metadata,
    ...githubUserInfo,
  };
}

async function fetchGitHubUserInfo(viewer: Viewer) {
  const split = viewer.authId?.split('|');
  if (!split || split.length !== 2 || split[0] !== 'github') {
    return {};
  }

  const userID = split[1];
  const cacheKey = `github_user_info_${userID}`;
  const cachedGitHubInfo = localStorage.getItem(cacheKey);
  if (cachedGitHubInfo) {
    return JSON.parse(cachedGitHubInfo);
  }

  let userInfo: GithubInfo | null = null;
  try {
    // eslint-disable-next-line wandb/no-unprefixed-urls
    const resp = await fetch(`https://api.github.com/user/${userID}`);
    const respJSON = await resp.json();
    userInfo = {
      githubUsername: respJSON.login,
      githubCompany: respJSON.company,
      githubURL: respJSON.html_url,
      githubFollowers: respJSON.followers,
    };
  } catch {
    // do nothing
  }

  if (userInfo !== null) {
    localStorage.setItem(cacheKey, JSON.stringify(userInfo));
  }

  return userInfo ?? {};
}

/**
 * Identify with analytics platforms by viewer.
 */
export async function identifyWithViewer(viewer?: Viewer) {
  if (!viewer?.email) {
    return;
  }
  if (window.analytics) {
    const analytics = await formatViewerForAnalytics(viewer);
    window.analytics.identify(viewer.email, analytics);
  }
  Sentry.configureScope(scope => {
    scope.setUser({
      username: viewer.username,
      entity: viewer.entity,
      email: viewer.email,
    });
  });
}

function getZendeskChatContainer(): HTMLElement | null {
  return document.getElementById('launcher')?.parentElement ?? null;
}

function getZendeskChatLauncher(): HTMLElement | null {
  return document.getElementById('zendesk-launcher');
}

async function hideOrShowElWithRetry(
  getEl: () => HTMLElement | null,
  show: boolean,
  tries = 30,
  intervalMS = 100
): Promise<void> {
  for (let i = 0; i < tries; i++) {
    const el = getEl();
    if (el != null) {
      el.style.display = show ? 'block' : 'none';
      return;
    }
    if (i >= tries - 1) {
      return;
    }
    await new Promise(resolve => setTimeout(resolve, intervalMS));
  }
}

export async function hideZendeskChat(): Promise<void> {
  await hideOrShowElWithRetry(getZendeskChatContainer, false);
  await hideOrShowElWithRetry(getZendeskChatLauncher, false);
}

export async function showZendeskChat(): Promise<void> {
  await hideOrShowElWithRetry(getZendeskChatContainer, true);
  await hideOrShowElWithRetry(getZendeskChatLauncher, true);
}

const PERF_METRICS_MESSAGE = 'render time (MS) by component';
const PERF_METRICS_SEND_INTERVAL = 10000;
const MAX_RENDERS_PER_COMP = 1000; // if a component renders more than this, it's probably a render loop

interface PerfMetrics {
  [compName: string]: RenderStats[];
}

type RenderStats = number;

let perfMetrics: PerfMetrics = {};

export function recordRender(compName: string, renderTime: number): void {
  if (!datadogEnabled) {
    return;
  }
  perfMetrics[compName] = perfMetrics[compName] ?? [];
  if (perfMetrics[compName].length < MAX_RENDERS_PER_COMP) {
    perfMetrics[compName].push(Math.round(renderTime));
  }
}

if (datadogEnabled) {
  setInterval(() => {
    if (_.isEmpty(perfMetrics)) {
      return;
    }
    try {
      datadogLogs.logger.info(PERF_METRICS_MESSAGE, {
        data: perfMetrics,
      } as unknown as Context);
    } catch (e) {
      console.warn(`error uploading perf metrics: ${e}`);
    }
    perfMetrics = {};
  }, PERF_METRICS_SEND_INTERVAL);
}
