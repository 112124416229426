import * as S from './EditableTable.styles';

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import produce, {Draft} from 'immer';
import {Form, Modal, Button, StrictInputProps} from 'semantic-ui-react';
import EditableImage from '../components/EditableImage';
import makeComp from '../util/profiler';

const ACTIONS_COLUMN_WIDTH = 140;
const IMAGE_FIELD_SIZE = 200;

export type Column<T extends {id: string}> = {
  name: string;
  width: number;
  renderCell: (data: T, i: number) => React.ReactNode;
};

type EditableTableProps<T extends {id: string}> = {
  rows: T[];
  columns: Array<Column<T>>;
  editableFields: Array<EditableField<T>>;
  rowIsValid: (r: T) => boolean;
  onEdit: (newRows: T[]) => void;
  makeNewRow: () => T;
};

const EditableTable = makeComp(
  <T extends {id: string}>({
    rows,
    columns,
    editableFields,
    rowIsValid,
    onEdit,
    makeNewRow,
  }: PropsWithChildren<EditableTableProps<T>>) => {
    const [editingRow, setEditingRow] = useState<T | null>(null);
    const [produceNewRows, setProduceNewRows] = useState<
      ((rowsDraft: Array<Draft<T>>, editedRow: T) => void) | null
    >(null);

    const moveRowUp = useCallback(
      (i: number) => {
        if (i <= 0) {
          return;
        }
        const newRows = produce(rows, draft => {
          const [removed] = draft.splice(i, 1);
          draft.splice(i - 1, 0, removed);
        });
        onEdit(newRows);
      },
      [rows, onEdit]
    );

    const moveRowDown = useCallback(
      (i: number) => {
        if (i >= rows.length - 1) {
          return;
        }
        const newRows = produce(rows, draft => {
          const [removed] = draft.splice(i, 1);
          draft.splice(i + 1, 0, removed);
        });
        onEdit(newRows);
      },
      [rows, onEdit]
    );

    const deleteRow = useCallback(
      (i: number) => {
        const newRows = produce(rows, draft => {
          draft.splice(i, 1);
        });
        onEdit(newRows);
      },
      [rows, onEdit]
    );

    const addRow = useCallback(() => {
      setEditingRow(makeNewRow());
      setProduceNewRows(() => (rowsDraft: T[], editedRow: T) => {
        rowsDraft.push(editedRow);
      });
    }, [makeNewRow]);

    const editRow = useCallback(
      (i: number) => {
        setEditingRow(rows[i]);
        setProduceNewRows(() => (rowsDraft: T[], editedRow: T) => {
          rowsDraft[i] = editedRow;
        });
      },
      [rows]
    );

    const stopEditingRow = useCallback(() => {
      setEditingRow(null);
      setProduceNewRows(null);
    }, []);

    const saveEditedRow = useCallback(
      (r: T) => {
        if (produceNewRows != null) {
          const newRows = produce(rows, draft => {
            produceNewRows(draft, r);
          });
          onEdit(newRows);
        }
        stopEditingRow();
      },
      [rows, produceNewRows, onEdit, stopEditingRow]
    );

    return (
      <>
        <S.Table>
          <S.Row header>
            <S.Cell noFlexShrink width={ACTIONS_COLUMN_WIDTH} />

            {columns.map(({name, width}) => (
              <S.Cell key={name} width={width}>
                {name}
              </S.Cell>
            ))}
          </S.Row>
          {rows.map((r, i) => (
            <S.Row key={r.id}>
              <S.Cell width={ACTIONS_COLUMN_WIDTH}>
                <S.ActionIcon name="up-arrow" onClick={() => moveRowUp(i)} />
                <S.ActionIcon
                  name="down-arrow"
                  onClick={() => moveRowDown(i)}
                />
                <S.ActionIcon name="edit" onClick={() => editRow(i)} />
                <S.ActionIcon name="delete" onClick={() => deleteRow(i)} />
              </S.Cell>

              {columns.map(({name, width, renderCell}) => (
                <S.Cell key={name} width={width}>
                  {renderCell(r, i)}
                </S.Cell>
              ))}
            </S.Row>
          ))}
          <S.Row>
            <S.AddRow onClick={addRow}>
              <S.AddRowIcon name="plus" />
              Add
            </S.AddRow>
          </S.Row>
        </S.Table>
        {editingRow != null && (
          <EditModal
            row={editingRow}
            fields={editableFields}
            rowIsValid={rowIsValid}
            onSave={saveEditedRow}
            onClose={stopEditingRow}
          />
        )}
      </>
    );
  },
  {id: 'EditableTable', memo: true}
);

export default EditableTable;

type FieldType = 'input' | 'textarea' | 'image';

export type EditableField<T> = {
  rowKey: keyof T;
  label: string;
  type: FieldType;
};

type EditModalProps<T extends {[k: string]: string}> = {
  row: T;
  fields: Array<EditableField<T>>;
  rowIsValid: (r: T) => boolean;
  onSave: (r: T) => void;
  onClose: () => void;
};

const EditModal = makeComp(
  <T extends {[k: string]: string}>({
    row,
    fields,
    rowIsValid,
    onSave,
    onClose,
  }: PropsWithChildren<EditModalProps<T>>) => {
    const inputRef = useRef<HTMLInputElement>();
    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    const [rowDraft, setRowDraft] = useState<T>(row);

    const editRow = useCallback((k: keyof T, v: any) => {
      setRowDraft(prev =>
        produce(prev, draft => {
          draft[k as keyof Draft<T>] = v;
        })
      );
    }, []);

    return (
      <S.Modal open onClose={onClose}>
        <Modal.Content>
          <Form>
            {fields.map(({rowKey, label, type}, i) => {
              let fieldValue: JSX.Element;
              if (type === 'image') {
                fieldValue = (
                  <EditableImage
                    photoUrl={rowDraft[rowKey] ?? '/wave.gif'}
                    displaySize={IMAGE_FIELD_SIZE}
                    label={false}
                    save={v => editRow(rowKey, v)}
                  />
                );
              } else {
                const InputComponent =
                  type === 'textarea' ? S.ModalTextArea : S.ModalInput;
                fieldValue = (
                  <InputComponent
                    ref={i === 0 ? inputRef : undefined}
                    value={rowDraft[rowKey]}
                    onChange={
                      ((e, {value: v}) =>
                        editRow(rowKey, v)) as StrictInputProps['onChange']
                    }
                  />
                );
              }
              return (
                <S.ModalField key={rowKey as string}>
                  <S.ModalLabel>{label}</S.ModalLabel>
                  {fieldValue}
                </S.ModalField>
              );
            })}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            disabled={!rowIsValid(rowDraft)}
            onClick={() => onSave(rowDraft)}>
            Confirm
          </Button>
        </Modal.Actions>
      </S.Modal>
    );
  },
  {id: 'EditableTable.EditModal', memo: true}
);
