/* This is a forked version of the run file browser.
   TODO: probably merge them back together */

import '../css/GroupPage.less';
import '../css/ProjectPage.less';

import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import * as Url from '../util/url';
import {FilesPanel, PanelFilesConfig} from './Panel2/PanelFiles';

import * as Panel2 from './Panel2/panel';
import * as Op from '@wandb/cg/browser/ops';
import makeComp from '../util/profiler';
import {ErrorBoundary} from '../components/ErrorBoundary';
import PanelError from '../components/elements/PanelError';
import {captureError} from '../util/integrations';

interface BaseFilePathArgs {
  entityName: string;
  projectName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}

interface ArtifactFilesProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  filePath?: string;
  useSetFilePath: (base: BaseFilePathArgs) => (newPathArray: string[]) => void;
}

function renderPanelError() {
  return (
    <div style={{padding: 40}}>
      <PanelError
        message={
          <div>
            Oops, something went wrong. If this keeps happening, message
            support@wandb.com with a link to this page
          </div>
        }
      />
    </div>
  );
}
function logError(error: Error) {
  console.error('ArtifactFiles error', error);
  captureError(error, 'ArtifactFiles error');
}

const ArtifactFiles = makeComp(
  (props: ArtifactFilesProps) => {
    const {
      entityName,
      projectName,
      artifactSequenceName,
      artifactCommitHash,
      filePath,
      useSetFilePath,
    } = props;

    const location = useLocation();
    const hash = location.hash.slice(1);
    let compareID: string | undefined;
    let compareSequenceName: string | undefined;
    if (hash.length > 0) {
      const [compareId, compareSN] = hash.split('$');
      compareID = compareId;
      compareSequenceName = compareSN;
    }

    const [context, setContext] = useState<Panel2.PanelContext>({});
    const updateContext = useCallback<Panel2.UpdateContext>(
      newContext => {
        setContext({...context, ...newContext});
      },
      [context]
    );

    const setFilePath = useSetFilePath({
      entityName,
      projectName,
      artifactSequenceName,
      artifactCommitHash,
      compareArtifactID: compareID,
      compareArtifactSequence: compareSequenceName,
    });

    const updateConfig = useCallback(
      (newConfig: PanelFilesConfig) => setFilePath(newConfig.path || []),
      [setFilePath]
    );

    const path = Url.parseRunTabPath(filePath);
    const artifacts = useMemo(() => {
      const artifactOps = [
        Op.opProjectArtifactVersion({
          project: Op.opRootProject({
            entityName: Op.constString(entityName),
            projectName: Op.constString(projectName),
          }),
          artifactName: Op.constString(artifactSequenceName),
          artifactVersionAlias: Op.constString(artifactCommitHash),
        }),
      ];
      if (compareID != null) {
        artifactOps.push(
          Op.opProjectArtifactVersion({
            project: Op.opRootProject({
              entityName: Op.constString(entityName),
              projectName: Op.constString(projectName),
            }),
            artifactName: Op.constString(
              compareSequenceName ?? artifactSequenceName
            ),
            artifactVersionAlias: Op.constString(compareID),
          })
        );
      }
      return Op.oneOrMany(artifactOps as any);
    }, [
      entityName,
      projectName,
      artifactSequenceName,
      artifactCommitHash,
      compareSequenceName,
      compareID,
    ]);

    return (
      <ErrorBoundary onError={logError} renderError={renderPanelError}>
        <FilesPanel
          config={{path}}
          configMode={false}
          loading={false}
          input={{path: artifacts as any}}
          context={context}
          updateConfig={updateConfig}
          updateContext={updateContext}
        />
      </ErrorBoundary>
    );
  },
  {id: 'ArtifactFiles'}
);

export default ArtifactFiles;
