import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {PropertyEditorComponent} from './property-editors';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

/**
 * For selecting from a list of options.
 * Also consider icon-menu for a more compact representation.
 * Default is required.
 * @param props
 */
const DropdownPropertyEditor: PropertyEditorComponent<'dropdown'> = makeComp(
  props => {
    const displayedVal =
      props.values.length === 1 ? props.values[0] : undefined;
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        <Dropdown
          placeholder="Mixed"
          value={props.values.length > 1 ? undefined : displayedVal}
          options={props.options}
          onChange={(e, {value}) => {
            props.save(value as string | number);
          }}></Dropdown>
      </InspectorPropertyWrapper>
    );
  },
  {id: 'DropdownPropertyEditor'}
);

export default DropdownPropertyEditor;
