import '../css/ActivityCalendar.less';

import React from 'react';
import {Icon, Popup, Segment} from 'semantic-ui-react';

import ImageIconHeader from './ImageIconHeader';
import makeComp from '../util/profiler';

interface ActivityCalendarProps {
  dailyRunCount: number[];
}

const ActivityCalendar: React.FC<ActivityCalendarProps> = makeComp(
  ({dailyRunCount}) => {
    let streakLength = 0;
    for (let i = dailyRunCount.length - 1; i >= 0; i--) {
      if (dailyRunCount[i]) {
        streakLength++;
      } else {
        break;
      }
    }
    if (streakLength < 3) {
      streakLength = 0;
    }

    return (
      <>
        <ImageIconHeader icon="activity" text="Activity" />
        <Segment className="activity-calendar">
          <div className="activity-calendar-calendar">
            <div className="activity-calendar-days">
              <div>Mon</div>
              <div>Wed</div>
              <div>Fri</div>
            </div>
            <div className="activity-calendar-blocks-wrapper">
              <div className="activity-calendar-blocks">
                {dailyRunCount.map((c, index) => {
                  const date = new Date();
                  date.setDate(
                    date.getDate() + index - dailyRunCount.length + 1
                  );
                  const isStreakBlock =
                    dailyRunCount.length - index - 1 < streakLength;
                  return (
                    <React.Fragment key={index}>
                      {date.getDate() === 1 && (
                        <span
                          className="activity-calendar-month"
                          style={{left: Math.floor(index / 7) * 16 + 7}}>
                          {date.toLocaleString('en-us', {month: 'short'})}
                        </span>
                      )}
                      <Popup
                        inverted
                        position="top center"
                        size="mini"
                        trigger={
                          <div
                            className={
                              'activity-calendar-block' +
                              (isStreakBlock ? ' streak-block' : '')
                            }
                            style={{
                              background: `hsl(${
                                isStreakBlock ? '35' : '190'
                              }, ${c === 0 ? 0 : 40 + c * 3}%, ${
                                c === 0 ? 95 : Math.max(22, 90 - c * 3)
                              }%)`,
                            }}
                          />
                        }
                        content={`Ran ${
                          c === 0 ? 'no runs' : c === 1 ? '1 run' : c + ' runs'
                        }
                 on ${date.toLocaleDateString()}`}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
          {streakLength !== 0 && (
            <div className="activity-calendar-key">
              <Icon name="fire" />
              {streakLength} day streak
            </div>
          )}
        </Segment>
      </>
    );
  },
  {id: 'ActivityCalendar', memo: true}
);

export default ActivityCalendar;
