import styled, {css} from 'styled-components';

export const ReportPageContainer = styled.div<{isViewMode?: boolean}>`
  display: flex;
  ${props => css`
    ${props.isViewMode && 'flex-direction: column;'}
  `}
`;

export const ReportPageInnerContainer = styled.div`
  width: 100%;
`;
