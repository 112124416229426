import React, {useState, useEffect} from 'react';
import {
  useRenameProjectMutation,
  useAllProjectsQuery,
} from '../generated/graphql';
import {Modal, Form, Button} from 'semantic-ui-react';
import {ApolloError} from 'apollo-client';
import {RouteComponentProps, withRouter} from 'react-router';
import {
  propagateErrorsContext,
  extractErrorMessageFromApolloError,
} from '../util/errors';
import projectName from '../util/projectName';
import LegacyWBIcon from './elements/LegacyWBIcon';

import './ProjectRenameModal.less';
import makeComp from '../util/profiler';

type Props = {
  open: boolean;
  targetProject: {name: string; entityName: string};
  onRename?(): void;
  onClose(): void;
} & RouteComponentProps;

const ProjectRenameModal: React.FC<Props> = makeComp(
  props => {
    const [tempName, setTempName] = useState(props.targetProject.name);
    const [error, setError] = useState<string | null>(null);
    const [renameProject] = useRenameProjectMutation();
    const allProjects = useAllProjectsQuery({
      variables: {entityName: props.targetProject.entityName, first: 100},
      fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
      setTempName(props.targetProject.name);
    }, [props.open, props.targetProject.name]);

    const nameInUse = (name: string) => {
      if (!allProjects.data) {
        return false;
      }

      if (name === props.targetProject.name) {
        // we don't want it to look like an error before the user enters anything
        return false;
      }

      return !!allProjects.data.projects?.edges.find(
        edge => edge.node && edge.node.name === name
      );
    };

    const canRename =
      tempName !== '' &&
      !nameInUse(tempName) &&
      tempName !== props.targetProject.name;

    const localNameInUseError = nameInUse(tempName) && {
      content: `Project with name "${tempName}" already exists`,
    };

    const handleSubmit = () => {
      if (!canRename) {
        return;
      }

      renameProject({
        variables: {
          entityName: props.targetProject.entityName,
          oldProjectName: props.targetProject.name,
          newProjectName: tempName,
        },
        context: propagateErrorsContext(),
      })
        .then(() => {
          props.onRename != null
            ? props.onRename()
            : props.history.replace(
                `/${props.targetProject.entityName}/${encodeURIComponent(
                  tempName
                )}/overview`
              );
          props.onClose();
        })
        .catch((e: ApolloError) => {
          const msg = extractErrorMessageFromApolloError(e);
          if (msg) {
            setError(msg);
          }
        });
    };

    return (
      <Modal
        open={props.open}
        onClose={props.onClose}
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
        className="project-rename-modal">
        <Modal.Header>
          <span className="warning-header">
            <LegacyWBIcon name="warning" size="large" />
            <span className="warning-text">Warning</span>
          </span>
        </Modal.Header>
        <Modal.Content>
          <p>
            Renaming breaks all existing links to your projects, runs, and
            reports.
          </p>
          <p>
            You will need to update any links you've shared as well as calls to{' '}
            <code>wandb.init(project="{props.targetProject.name}")</code> in
            your code.
          </p>

          <p>Are you sure you want to continue?</p>

          <Form onSubmit={handleSubmit}>
            <Form.Input
              type="text"
              value={tempName}
              error={localNameInUseError || (error && {content: error})}
              onChange={(_, {value}) => setTempName(projectName(value))}
              placeholder="Enter a new project name"
              autoFocus
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Form onSubmit={handleSubmit}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button color="red" type="submit" disabled={!canRename}>
              Rename Project
            </Button>
          </Form>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'ProjectRenameModal'}
);

export default withRouter(ProjectRenameModal);
