import {Omit} from '../../../types/base';
import * as UtilReport from '../../../util/report';
import * as SectionTypes from '../section/types';
import * as MarkdownBlockTypes from '../markdownBlock/types';
import * as ViewTypes from '../types';
import * as PanelSettingsTypes from '../panelSettings/types';
import {Ref as DiscussionThreadRef} from '../discussionThread/types';

export const REPORT_VIEW_TYPE = 'runs';

export type ReportConfig = UtilReport.SlateReport;

export type ReportWidthOption = 'readable' | 'fixed' | 'fluid';

export type ReportPageNormalized = Omit<
  ReportConfig,
  'panelSettings' | 'discussionThreads'
> & {
  panelSettingsRef: ViewTypes.PartRefFromObjSchema<PanelSettingsTypes.PanelSettingsObjSchema>;
  discussionThreadRefs: DiscussionThreadRef[];
};

export type Ref = ViewTypes.PartRefFromObjSchema<ReportObjSchema>;

export type ViewRef = ViewTypes.ViewRefFromObjSchema<ReportObjSchema>;

export interface ReportObjSchema {
  type: 'runs'; // legacy name
  whole: ReportConfig;
  part: ReportPageNormalized;
}

export type BlockRef = MarkdownBlockTypes.Ref | SectionTypes.Ref;
export type BlockConfig = MarkdownBlockTypes.Config | SectionTypes.Config;
