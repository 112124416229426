import React, {useState, useCallback} from 'react';
import {Button, Icon, Modal, SemanticICONS} from 'semantic-ui-react';

import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface DangerModalProps {
  yesText?: string;
  icon?: SemanticICONS;
  message?: React.ReactNode;
  trigger: React.ReactElement;
  yes: any;
  open?: boolean;
  onClose?(): void;
}

const DangerModal: React.FC<DangerModalProps> = makeComp(
  ({yesText, icon, message, trigger, yes, open, onClose}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = useCallback(() => setModalOpen(true), []);

    const handleClose = useCallback(() => {
      if (onClose) {
        onClose();
      }
      setModalOpen(false);
    }, [onClose]);

    return (
      <Modal
        open={open == null ? modalOpen : open}
        onClose={handleClose}
        trigger={React.cloneElement(trigger, {
          onClick: handleOpen,
        })}
        size="small">
        <Modal.Header>
          <Icon name={icon || 'exclamation triangle'} /> Danger
        </Modal.Header>
        <Modal.Content>
          {message ?? <p>Are you absolutely sure?</p>}
        </Modal.Content>
        <Modal.Actions>
          <Button className="wb-icon-button" size="tiny" onClick={handleClose}>
            <LegacyWBIcon name="close" />
            Cancel
          </Button>
          <Button
            color="red"
            size="tiny"
            className="wb-icon-button"
            onClick={() => {
              if (yes) {
                yes();
              }
              handleClose();
            }}>
            <LegacyWBIcon name="check" style={{color: 'white'}} />{' '}
            {yesText || 'Yes'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'DangerModal', memo: true}
);

export default DangerModal;
