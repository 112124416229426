import * as S from './ShareReportTrigger.styles';

import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import classNames from 'classnames';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {Modal, Popup} from 'semantic-ui-react';
import '../css/ShareReportTrigger.less';
import * as Generated from '../generated/graphql';
import {
  useCreateAccessTokenMutation,
  useRevokeAccessTokenMutation,
} from '../generated/graphql';
import {useQuery} from '../state/graphql/query';
import {useSelector} from '../state/hooks';
import {ReportViewRef} from '../state/reports/types';
import {useViewer} from '../state/viewer/hooks';
import * as ViewActions from '../state/views/actions';
import {ViewPermissionsData} from '../state/views/graphql';
import {useViewRefAction} from '../state/views/hooks';
import {viewerUsingAdminPrivileges} from '../util/admin';
import makeComp from '../util/profiler';
import {useReportProjects} from '../util/report';
import CopyableText from './CopyableText';
import LegacyWBIcon from './elements/LegacyWBIcon';

const LINK_PERMISSION_EDIT_VALUE = 'edit';
const LINK_PERMISSION_VIEW_VALUE = 'view';
const LINK_PERMISSION_OPTIONS: WBMenuOption[] = [
  {name: 'Can edit', value: LINK_PERMISSION_EDIT_VALUE},
  {name: 'Can view', value: LINK_PERMISSION_VIEW_VALUE},
];
function getLinkPermissionFromViewLocked(locked: boolean): WBMenuOption {
  const linkPermissionVal = locked
    ? LINK_PERMISSION_VIEW_VALUE
    : LINK_PERMISSION_EDIT_VALUE;
  return (
    LINK_PERMISSION_OPTIONS.find(o => o.value === linkPermissionVal) ??
    LINK_PERMISSION_OPTIONS[0]
  );
}

interface ShareReportTriggerProps {
  viewRef: ReportViewRef;
  onClick?(): void;
}

const ShareReportTrigger: React.FC<ShareReportTriggerProps> = makeComp(
  props => {
    const view = useSelector(state => state.views.views[props.viewRef.id]);
    if (view.project == null) {
      throw new Error('attempting to share report without project');
    }
    const viewer = useViewer();
    const accessQuery = useQuery<
      ViewPermissionsData,
      Generated.ViewPermissionsQueryVariables
    >(Generated.ViewPermissionsDocument, {
      variables: {
        projectName: view.project.name,
        entityName: view.project.entityName,
      },
    });
    const setLocked = useViewRefAction(props.viewRef, ViewActions.setLocked);
    const addAccessToken = useViewRefAction(
      props.viewRef,
      ViewActions.addAccessToken
    );
    const removeAccessToken = useViewRefAction(
      props.viewRef,
      ViewActions.removeAccessToken
    );

    const [modalOpen, setModalOpen] = useState(false);
    const onClick = useCallback(() => {
      setModalOpen(true);
      props.onClick?.();
    }, [props.onClick]);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const projectSpecifiers = useReportProjects(props.viewRef);
    const projectNames = projectSpecifiers.map(ps => ps.projectName);
    const crossProjectNames = _.without(projectNames, view.project.name);
    const currentAccessToken = view.accessTokens?.[0];

    const [createAccessToken] = useCreateAccessTokenMutation({
      variables: {
        projects: projectSpecifiers,
        viewId: view.id!,
      },
    });
    const [revokeAccessToken] = useRevokeAccessTokenMutation();
    const [creatingAccessToken, setCreatingAccessToken] = useState(false);
    const [revokingAccessToken, setRevokingAccessToken] = useState(false);
    const [linkPermission, setLinkPermission] = useState<WBMenuOption>(
      getLinkPermissionFromViewLocked(view.locked)
    );
    const setLinkPermissionByValue = useCallback(
      (v: string | number) => {
        const lp = LINK_PERMISSION_OPTIONS.find(o => o.value === v);
        if (lp != null) {
          setLinkPermission(lp);
          setLocked(v !== LINK_PERMISSION_EDIT_VALUE);
        }
      },
      [setLocked]
    );

    const createLink = useCallback(async () => {
      if (creatingAccessToken || currentAccessToken != null) {
        return;
      }
      setCreatingAccessToken(true);
      const result = await createAccessToken();
      const accessToken = result.data?.createAccessToken?.accessToken;
      if (accessToken != null) {
        addAccessToken(accessToken);
      }
      setCreatingAccessToken(false);
    }, [
      creatingAccessToken,
      currentAccessToken,
      createAccessToken,
      addAccessToken,
    ]);

    const deactivateLink = useCallback(async () => {
      if (revokingAccessToken || currentAccessToken == null) {
        return;
      }
      setRevokingAccessToken(true);
      const result = await revokeAccessToken({
        variables: {
          token: currentAccessToken.token,
        },
      });
      if (result.data?.revokeAccessToken?.success) {
        removeAccessToken(currentAccessToken.token);
      }
      setRevokingAccessToken(false);
    }, [
      revokingAccessToken,
      currentAccessToken,
      removeAccessToken,
      revokeAccessToken,
    ]);

    const [normalLinkAnimated, setNormalLinkAnimated] = useState(false);
    const [magicLinkAnimated, setMagicLinkAnimated] = useState(false);
    const animateNormalLink = useCallback(() => {
      setNormalLinkAnimated(false);
      setTimeout(() => {
        setNormalLinkAnimated(true);
      });
    }, []);
    const animateMagicLink = useCallback(() => {
      setMagicLinkAnimated(false);
      setTimeout(() => {
        setMagicLinkAnimated(true);
      });
    }, []);

    const triggerContent = (
      <div style={{display: 'contents'}} onClick={onClick}>
        {props.children}
      </div>
    );

    if (accessQuery.loading) {
      return triggerContent;
    }

    const {
      entity: {isTeam},
      project: {access},
    } = accessQuery;

    const isPrivate = ['USER_READ', 'USER_WRITE'].indexOf(access) === -1;
    const isPrivateWrite = access !== 'USER_WRITE';

    const hasAdminSharePrivileges =
      viewer != null &&
      (view.user.id === viewer.id ||
        (viewer.admin && viewerUsingAdminPrivileges()));

    const {origin, host, pathname} = window.location;
    const accessTokenQS =
      currentAccessToken != null
        ? `?accessToken=${currentAccessToken.token}`
        : '';

    const normalLink = (
      <CopyableText
        className={classNames('report-link', {animated: normalLinkAnimated})}
        text={`${host}${pathname}`}
        copyText={`${origin}${pathname}`}
        toastText="Link copied"
        onClick={animateNormalLink}
      />
    );

    const magicLink = (
      <CopyableText
        className={classNames('report-link', {animated: magicLinkAnimated})}
        text={`${host}${pathname}${accessTokenQS}`}
        copyText={`${origin}${pathname}${accessTokenQS}`}
        toastText="Link copied"
        onClick={animateMagicLink}
      />
    );

    const manageAccessToken =
      currentAccessToken == null ? (
        <div className="manage-magic-link" onClick={createLink}>
          <LegacyWBIcon name="link" className="manage-magic-link-icon" />
          {creatingAccessToken ? 'Creating...' : 'Create a view-only link'}
        </div>
      ) : (
        <div className="manage-magic-link deactivate" onClick={deactivateLink}>
          <LegacyWBIcon name="delete" className="manage-magic-link-icon" />
          {revokingAccessToken ? 'Deactivating...' : 'Deactivate this link'}
        </div>
      );

    const magicLinkSection = (
      <>
        {currentAccessToken != null && (
          <div className="magic-link-section">
            <div className="link-info">
              <div className="link-type">Magic link</div>
              <div className="link-functionality">Grants view-only access</div>
            </div>
            {magicLink}
            <div className="magic-link-warning">
              This link grants view access to all data in this project
              {crossProjectNames.length > 0
                ? ` and the following additional projects: ${crossProjectNames.join(
                    ', '
                  )}`
                : ''}
              .
            </div>
          </div>
        )}
        {manageAccessToken}
        {currentAccessToken != null && <S.GrayDivider></S.GrayDivider>}
      </>
    );

    const iframeCode = `<iframe src="${window.location.href}" style="border:none;height:1024px;width:100%">`;

    const iframeSection = (
      <S.CopyIframeSection>
        <S.CopyIframeHeader>
          <S.CopyIframeIcon name="code" />
          Copy the embed code
        </S.CopyIframeHeader>
        <S.CopyableTextWrapper>
          <S.CopyableIframe
            text={iframeCode}
            copyText={iframeCode}
            toastText="Embed code copied"
          />
        </S.CopyableTextWrapper>
      </S.CopyIframeSection>
    );

    const teamModalContent = (
      <>
        {isPrivateWrite && hasAdminSharePrivileges && (
          <div className="link-info">
            <div className="link-type">Team members</div>
            <div className="link-functionality clickable">
              <WBPopupMenuTrigger
                options={LINK_PERMISSION_OPTIONS}
                selected={linkPermission.value}
                onSelect={setLinkPermissionByValue}>
                {({anchorRef, setOpen}) => (
                  <span ref={anchorRef} onClick={() => setOpen(o => !o)}>
                    {linkPermission.name} <LegacyWBIcon name="down" />
                  </span>
                )}
              </WBPopupMenuTrigger>
            </div>
          </div>
        )}
        {normalLink}
        {isPrivate && hasAdminSharePrivileges && (
          <>
            {magicLinkSection}
            {iframeSection}
          </>
        )}
      </>
    );

    const personalModalContent = isPrivate ? (
      <>
        <p className="explanation-text">
          This project is <b>private</b>, so only you can see this report. Click
          the lock in the navigation bar to change project privacy settings.
        </p>
        {hasAdminSharePrivileges && (
          <>
            {magicLinkSection}
            {iframeSection}
          </>
        )}
      </>
    ) : (
      <>
        <div className="link-info">
          <div className="link-type">
            <Popup
              className="share-report-link-type-popup"
              on="hover"
              position="top center"
              trigger={<span>Anyone</span>}>
              To change who can view this report, edit your project's privacy
              settings.
            </Popup>
          </div>
          <div className="link-functionality">Can view</div>
        </div>
        {normalLink}
        {iframeSection}
      </>
    );

    return (
      <>
        {triggerContent}
        <Modal
          className="share-report-modal"
          size="tiny"
          open={modalOpen}
          onClose={closeModal}>
          <Modal.Content>
            <h3>Share this report</h3>
            {isTeam ? teamModalContent : personalModalContent}
          </Modal.Content>
        </Modal>
      </>
    );
  },
  {id: 'ShareReportTrigger'}
);

export default ShareReportTrigger;
