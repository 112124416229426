import React from 'react';
import {Modal} from 'semantic-ui-react';
import {Highlight, Python} from '../components/Code';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {useRunSetsQuery, useRunsQueryContext} from '../state/runs/hooks';
import makeComp from '../util/profiler';
import docUrl from '../util/doc_urls';
import {TargetBlank} from '../util/links';

interface PanelExportAPIProps {
  runSetRefs: RunSetViewTypes.Ref[];
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

const PanelExportAPI = makeComp(
  ({runSetRefs, trigger, open, onClose}: PanelExportAPIProps) => {
    const query = useRunSetsQuery(runSetRefs);
    const context = useRunsQueryContext();

    let entityName: string | undefined;
    let projectName: string | undefined;
    if (query.runSets && query.runSets[0]) {
      const firstRunSet = query.runSets[0];
      if (firstRunSet.entityName) {
        entityName = firstRunSet.entityName;
      }
      if (firstRunSet.projectName) {
        projectName = firstRunSet.projectName;
      }
    }
    if (!projectName) {
      projectName = context.projectName;
    }
    if (!entityName) {
      entityName = context.entityName;
    }

    let runId: string | undefined;
    if (context.runId) {
      runId = context.runId;
    }

    return (
      <React.Fragment>
        <Modal trigger={trigger} open={open} onClose={onClose}>
          <Modal.Content>
            <h2>Python Export API</h2>
            <p>
              Use the{' '}
              <TargetBlank href={docUrl.pythonApi}>
                Python Export API
              </TargetBlank>{' '}
              to download a CSV of metrics.
            </p>
            {panelExportAPICode(entityName, projectName, runId)}
            <p>
              <TargetBlank href={docUrl.pythonApi}>
                Learn more in our docs →
              </TargetBlank>
            </p>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  },
  {id: 'PanelExportAPI'}
);

function panelExportAPICode(
  entityName: string,
  projectName: string,
  runId?: string
) {
  return (
    <Python>
      <Highlight>
        {runId
          ? `import wandb
api = wandb.Api()

# run is specified by <entity>/<project>/<run id>
run = api.run("${entityName}/${projectName}/${runId}")

# save the metrics for the run to a csv file
metrics_dataframe = run.history()
metrics_dataframe.to_csv("metrics.csv")`
          : `import wandb
api = wandb.Api()

# Project is specified by <entity/project-name>
runs = api.runs("${entityName}/${projectName}")
summary_list = [] 
config_list = [] 
name_list = [] 
for run in runs: 
    # run.summary are the output key/values like accuracy.
    # We call ._json_dict to omit large files 
    summary_list.append(run.summary._json_dict) 

    # run.config is the input metrics.
    # We remove special values that start with _.
    config = {k:v for k,v in run.config.items() if not k.startswith('_')}
    config_list.append(config) 

    # run.name is the name of the run.
    name_list.append(run.name)       

import pandas as pd 
summary_df = pd.DataFrame.from_records(summary_list) 
config_df = pd.DataFrame.from_records(config_list) 
name_df = pd.DataFrame({'name': name_list}) 
all_df = pd.concat([name_df, config_df,summary_df], axis=1)

all_df.to_csv("project.csv")`}
      </Highlight>
    </Python>
  );
}

export default PanelExportAPI;
