import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const EmptyReportSection = styled.div`
  margin: 64px;
  padding: 120px 0;
  color: ${globals.gray500};
  text-align: center;
  border: 1px solid #dcdcdc;
`;

export const PanelButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ImportVizSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background: white;
  border-radius: 4px;
  padding: 0;
  width: 500px;
  height: 440px;
`;

export const ImportVizProjectSection = styled.div``;

export const ImportVizChartSection = styled.div``;
