import '../css/ReportPageHeaderView.less';

import {Popup} from 'semantic-ui-react';
import {useSelector, usePropsSelector} from '../state/hooks';
import {SingleLineText} from './elements/Text';
import React, {useCallback, useMemo, useState} from 'react';
import _ from 'lodash';
import * as queryString from 'query-string';
import EditableField from './EditableField';
import EditReportTrigger from './EditReportTrigger';
import ShareReportTrigger from './ShareReportTrigger';
import {useViewRefAction, usePart} from '../state/views/hooks';
import * as ViewActions from '../state/views/actions';
import {ReportViewRef} from '../state/reports/types';
import {Link} from 'react-router-dom';
import * as ReportTypes from '../state/reports/types';
import * as Urls from '../util/urls';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as ViewerHooks from '../state/viewer/hooks';
import ReportExportModal from './ReportExportModal';
import * as ViewSelectors from '../state/views/selectors';
import * as ReportViewsSelectors from '../state/reports/selectors';
import * as ViewHooks from '../state/views/hooks';
import * as ViewThunks from '../state/views/thunks';
import {auth, isInIframe} from '../setup';
import {useAdminModeActive} from '../util/admin';
import * as Generated from '../generated/graphql';
import {isOwnView} from '../state/views/util';
import {ReportPageNormalized} from '../state/views/report/types';
import {PersistentFlasher} from './elements/PersistentFlasher';
import makeComp from '../util/profiler';
import {scrollToElement} from '../util/document';
import HighlightedIcon from './HighlightedIcon';
import PopupDropdown from './PopupDropdown';
import CloneReportModal from './CloneReportModal';
import StargazerModal from './StargazerModal';
import {useReportCommentAlerts} from '../state/reports/hooks';
import {PanelBankUpdaterContext} from '../state/panelbank/context';
import {useTrackReportActivity} from '../util/gallery';

interface ReportPageHeaderViewProps {
  viewRef: ReportViewRef;
  editAccess?: boolean;
}

export default makeComp(
  (props: ReportPageHeaderViewProps) => {
    const {viewRef} = props;

    const admin = useAdminModeActive();

    const viewer = ViewerHooks.useViewer();
    const view = useSelector(state => state.views.views[viewRef.id]);
    const reportID = view.id;
    if (reportID == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    const [viewView] = Generated.useViewViewMutation();
    React.useEffect(() => {
      viewView({variables: {id: reportID}});
    }, [reportID, viewView]);
    const viewPart = usePart(view.partRef) as ReportPageNormalized;
    const setNotes = useViewRefAction(viewRef, ViewActions.setDescription);
    const entityName = view.project?.entityName ?? view.entityName;
    const projectName = view.project?.name;
    const viewAuthors = viewPart.authors;
    let authors;
    if (viewAuthors != null && viewAuthors.length > 0) {
      authors = viewAuthors;
    } else {
      authors = [_.pick(view.user, 'username', 'name')];
    }
    const notes = view.description || '';

    const track = useTrackReportActivity(reportID);

    const starCount = usePropsSelector(
      ViewSelectors.makeStarCountSelector,
      viewRef
    );
    const starred = usePropsSelector(
      ViewSelectors.makeStarredSelector,
      viewRef
    );
    const star = ViewHooks.useViewRefThunk(viewRef, ViewThunks.starView);
    const unstar = ViewHooks.useViewRefThunk(viewRef, ViewThunks.unstarView);
    const toggleStar = useCallback(() => {
      if (viewer == null) {
        auth.login();
        return;
      }
      if (starred) {
        track('unheartReport', reportID);
        unstar();
      } else {
        track('heartReport', reportID);
        star();
      }
    }, [viewer, starred, star, unstar, reportID, track]);

    const [stargazerModalOpen, setStargazerModalOpen] = useState(false);
    const openStargazerModal = useCallback(
      () => setStargazerModalOpen(true),
      []
    );
    const closeStargazerModal = useCallback(
      () => setStargazerModalOpen(false),
      []
    );

    const initHighlightShare = useMemo(() => {
      const qs = queryString.parse(window.location.search);
      return qs.highlightShare !== undefined;
    }, []);
    const [highlightShare, setHighlightShare] = useState(initHighlightShare);
    const dismissHighlightShare = useCallback(
      () => setHighlightShare(false),
      []
    );

    const [cloneReportModalOpen, setCloneReportModalOpen] = useState(false);
    const openCloneReportModal = useCallback(
      () => setCloneReportModalOpen(true),
      []
    );
    const closeCloneReportModal = useCallback(
      () => setCloneReportModalOpen(false),
      []
    );
    const [moveReportModalOpen, setMoveReportModalOpen] = useState(false);
    const openMoveReportModal = useCallback(
      () => setMoveReportModalOpen(true),
      []
    );
    const closeMoveReportModal = useCallback(
      () => setMoveReportModalOpen(false),
      []
    );

    const [reportExportModalOpen, setReportExportModalOpen] = useState(false);
    const {setDisableWaitToLoad} = React.useContext(PanelBankUpdaterContext);

    const {isSubscribed, subscribe, unsubscribe} =
      useReportCommentAlerts(viewRef);
    const commentsCount = useSelector(
      ReportViewsSelectors.getReportDiscussionCommentsCount(viewRef)
    );

    const ownView = isOwnView(viewer, view);

    const cloneDropdownOption =
      viewer != null && projectName != null && !isInIframe()
        ? {
            text: 'Clone this report',
            icon: 'wbic-ic-copy',
            onClick: openCloneReportModal,
          }
        : null;
    const moveDropdownOption =
      viewer != null &&
      projectName != null &&
      admin &&
      view.type === ReportTypes.REPORT_VIEW_TYPE
        ? {
            text: 'Move this report',
            icon: 'wbic-ic-copy',
            onClick: openMoveReportModal,
          }
        : null;
    const exportDropdownOption =
      projectName != null
        ? {
            text: 'Download',
            icon: 'wbic-ic-download',
            onClick: () => {
              setReportExportModalOpen(true);
              setDisableWaitToLoad(true);
            },
          }
        : null;
    const subscribeDropdownOption =
      viewer != null
        ? {
            text: `Turn ${isSubscribed ? 'off' : 'on'} notifications`,
            icon: `wbic-ic-${
              isSubscribed ? 'bell-outline' : 'bell-slash-outline'
            }`,
            onClick: () => (isSubscribed ? unsubscribe() : subscribe()),
            'data-test': 'report-header-subscribe',
          }
        : null;
    const commentDropdownOption = {
      text: `${commentsCount} • View comments`,
      icon: 'wbic-ic-chat',
      onClick: () => scrollToElement('.report-discussion'),
      'data-test': 'report-header-view-comments',
    };
    const heartsDropdownOption = {
      text: `${starCount} • View likes`,
      icon: 'wbic-ic-heart-outline',
      onClick: openStargazerModal,
    };
    const viewsDropdownOption = {
      text: `${view.viewCount} page views`,
      icon: 'wbic-ic-show',
      style: {
        cursor: 'default',
        background: 'transparent',
        opacity: 0.5,
      },
    };

    const notOwnViewDropdownOptions = _.compact([
      cloneDropdownOption,
      moveDropdownOption,
      exportDropdownOption,
      subscribeDropdownOption,
    ]);
    const ownViewDropdownOptions =
      ownView || admin
        ? [commentDropdownOption, heartsDropdownOption, viewsDropdownOption]
        : null;
    const dropdownOptions = _.compact([
      notOwnViewDropdownOptions,
      ownViewDropdownOptions,
    ]);

    return (
      <div className={`report-header-view`}>
        {view.type === ReportTypes.REPORT_DRAFT_VIEW_TYPE && (
          // Since reports are now WYSIWYG, preview mode is obsolete,
          // so this state should not be possible to get to via the UI.
          // I'm leaving a little message here in case someone somehow gets to
          // this type of URL.
          <div className="report-header-view__draft-preview-message">
            <div className="report-header-view__draft-preview-message-info">
              You are currently previewing a{' '}
              {view.parentId
                ? 'draft edit of a report'
                : 'draft of a new report'}
              . This draft is private to {ownView ? 'you' : view.user.username}.
              Report previews are a deprecated feature; if you arrived here
              accidentally, let us know how.
            </div>
          </div>
        )}
        <div className="report-header-view__content">
          <div className="report-header-view__title">
            <div className="report-header-view__title-name">
              <SingleLineText as="h1">{view.displayName}</SingleLineText>
              {notes !== '' && (
                <div className="report-header-view__description">
                  <EditableField
                    multiline
                    maxLength={512}
                    readOnly={true}
                    placeholder={'Add a description...'}
                    value={notes}
                    // Report metadata is loaded asynchronously so we need to support
                    // dynamic updates to the description.
                    updateValue={true}
                    save={setNotes}
                  />
                </div>
              )}
              <div className="report-header-view__meta">
                <div className="report-header-view__authors">
                  {authors.map(({username, name}, i) => (
                    <React.Fragment key={username}>
                      <Link
                        className="report-header-view__author"
                        to={Urls.profilePage(username)}>
                        {name || username}
                      </Link>
                      {i < authors.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </div>
                <div className="report-header-view__actions">
                  {view.type === ReportTypes.REPORT_VIEW_TYPE &&
                    props.editAccess &&
                    ((viewer != null && view.user.id === viewer.id) ||
                      !view.locked ||
                      admin) && (
                      <EditReportTrigger viewRef={viewRef}>
                        <Popup
                          inverted
                          position="top center"
                          size="mini"
                          content={'Edit report'}
                          trigger={
                            <div className="report-header-view__action">
                              <LegacyWBIcon name="edit" />
                            </div>
                          }
                        />
                      </EditReportTrigger>
                    )}
                  {view.type === ReportTypes.REPORT_VIEW_TYPE && (
                    <>
                      <Popup
                        inverted
                        position="top center"
                        content={
                          commentsCount > 0
                            ? `${commentsCount} comments`
                            : `Add a comment`
                        }
                        size="mini"
                        on="hover"
                        trigger={
                          <div
                            className="report-header-view__action"
                            onClick={() =>
                              scrollToElement('.report-discussion')
                            }>
                            <LegacyWBIcon name="chat" />
                          </div>
                        }
                      />
                      {view.project != null && (
                        <ShareReportTrigger
                          viewRef={viewRef}
                          onClick={dismissHighlightShare}>
                          <PersistentFlasher
                            visible={highlightShare}
                            offsetX={-1}
                            offsetY={15}
                            style={{zIndex: 99}}
                            trigger={
                              <Popup
                                inverted
                                position="top center"
                                content="Share"
                                size="mini"
                                on="hover"
                                trigger={
                                  <div className="report-header-view__action">
                                    <LegacyWBIcon name="share" />
                                  </div>
                                }
                              />
                            }
                          />
                        </ShareReportTrigger>
                      )}
                      <Popup
                        inverted
                        position="top center"
                        content={`${starCount} hearts`}
                        size="mini"
                        on="hover"
                        trigger={
                          <div
                            className="report-header-view__action"
                            onClick={toggleStar}>
                            <LegacyWBIcon
                              name={starred ? 'heart' : 'heart-outline'}
                            />
                          </div>
                        }
                      />
                      <PopupDropdown
                        data-test="report-header-actions-dropdown"
                        offset={'10px, -10px'}
                        position="bottom right"
                        trigger={
                          <div
                            data-test="report-header-actions-overflow"
                            className="report-header-view__action">
                            <HighlightedIcon>
                              <LegacyWBIcon name="overflow" />
                            </HighlightedIcon>
                          </div>
                        }
                        sections={dropdownOptions}
                      />
                      {cloneReportModalOpen && (
                        <CloneReportModal
                          viewRef={viewRef}
                          onClose={closeCloneReportModal}
                        />
                      )}
                      {moveReportModalOpen && (
                        <CloneReportModal
                          moveReport
                          viewRef={viewRef}
                          onClose={closeMoveReportModal}
                        />
                      )}
                      {projectName != null && (
                        <ReportExportModal
                          entityName={entityName}
                          projectName={projectName}
                          reportViewRef={viewRef}
                          open={reportExportModalOpen}
                          onClose={() => setReportExportModalOpen(false)}
                        />
                      )}
                      <StargazerModal
                        viewRef={viewRef}
                        open={stargazerModalOpen}
                        onClose={closeStargazerModal}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
  {id: 'ReportPageHeaderView'}
);
