import styled from 'styled-components';

export const Label = styled.span`
  margin-left: 8px;
  margin-right: 4px;
`;

export const ActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Dropdowns = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const DropdownDiv = styled.div`
  display: flex;
  width: 288px;
  align-items: center;
  justify-content: space-between;
`;
