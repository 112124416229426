import produce from 'immer';
import _ from 'lodash';
import React, {useRef, useState, useCallback} from 'react';
import {Button, Checkbox, Icon, Input, Popup, Tab} from 'semantic-ui-react';
import {
  DragDropProvider,
  DragHandle,
  DragRef,
  DragSource,
  DropTarget,
} from '../../containers/DragDrop';
import {useProjectFieldsQuery} from '../../state/graphql/projectFieldsQuery';
import {
  makeFetchProjectFieldOptions,
  keyStringToWBMenuOption,
} from '../../util/dropdownQueries';
import makeComp from '../../util/profiler';
import EditableLabel from '../elements/EditableLabel';
import HelpPopup from '../elements/HelpPopup';
import LabeledOption from '../elements/LabeledOption';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {DEFAULT_GRADIENT, GradientPicker} from '../GradientPicker';
import ProjectFieldSelector from '../ProjectFieldSelector';
import * as Run from '../../util/runs';
import * as S from '../PanelParallelCoord.styles';
import {Query} from '../../util/queryts';
import {PCColumn, PCConfig} from '../PanelParallelCoord';

// This file defines the config/settings tab component for the Parallel Coordinates panel.
// Note: The main entry point for the Parallel Coordinates panel is in PanelParallelCoord.tsx

interface PCSettingsProps {
  config: PCConfig;
  updateConfig: (newConfig: PCConfig) => void;
  pageQuery: Query;
}

export const PCSettings = makeComp(
  (props: PCSettingsProps) => {
    const {pageQuery, config, updateConfig} = props;
    const {entityName, projectName} = pageQuery;
    const projectFieldsData = useProjectFieldsQuery({
      entityName,
      projectName,
      types: ['string', 'number', 'boolean'],
      columns: ['config', 'summary_metrics'],
      count: 50,
    });
    const {loading, keys} = projectFieldsData;

    const renderColumns = useRef<PCColumn[]>(config.columns || []);
    const columnAccessors = renderColumns.current.map(c => c.accessor || '');

    // tempColumns is used for drag+drop column reordering
    const [tempColumns, setTempColumns] = useState<PCColumn[] | undefined>(
      undefined
    );

    // Save the config and update the renderColumns ref
    const saveConfig = useCallback(
      (newConfig: Partial<PCConfig>) => {
        if (newConfig.columns != null) {
          renderColumns.current = newConfig.columns;
        }
        updateConfig(newConfig);
      },
      [updateConfig]
    );

    let defaultKey = '';
    if (!loading) {
      defaultKey = keys.find(k => !_.includes(columnAccessors, k)) || '';
    }

    // Add a new column
    const addColumn = useCallback(() => {
      const newColumns = produce(renderColumns.current, draft => {
        draft.push({accessor: defaultKey});
      });
      saveConfig({
        columns: newColumns,
      });
    }, [defaultKey, saveConfig]);

    // Remove a column
    const removeColumn = useCallback(
      (col: PCColumn) => {
        const newCols = produce(renderColumns.current, draft => {
          const colIndex = _.findIndex(draft, col);
          draft.splice(colIndex, 1);
        });
        saveConfig({columns: newCols});
      },
      [saveConfig]
    );

    // Set the column key
    const setColumnAccessor = useCallback(
      (col: PCColumn, newAccessor: string) => {
        const newCols = produce(renderColumns.current, draft => {
          const colIndex = _.findIndex(draft, col);
          draft[colIndex].accessor = newAccessor as string;
        });
        saveConfig({columns: newCols});
      },
      [saveConfig]
    );

    // Set the display name for the column
    const renameColumn = useCallback(
      (col: PCColumn, newName: string) => {
        const newCols = produce(renderColumns.current, draft => {
          const colIndex = _.findIndex(draft, col);
          draft[colIndex].displayName = newName;
        });
        saveConfig({columns: newCols});
      },
      [saveConfig]
    );

    // Toggle "inverted" or "log" attribute on the column
    const toggleColumnAttribute = useCallback(
      (col: PCColumn, attribute: 'inverted' | 'log') => {
        const newCols = produce(renderColumns.current, draft => {
          const colIndex = _.findIndex(draft, col);
          draft[colIndex][attribute] = !draft[colIndex][attribute];
        });
        saveConfig({columns: newCols});
      },
      [saveConfig]
    );

    // Move column (drag+drop)
    const onDragEnter = useCallback(
      (col: PCColumn, dragRef: DragRef | null) => {
        if (dragRef != null && dragRef.id !== col.accessor) {
          const cols = tempColumns || renderColumns.current;
          const dragColIndex = _.findIndex(cols, {accessor: dragRef.id});
          const dropColIndex = _.findIndex(cols, col);
          if (dragColIndex > -1 && dropColIndex > -1) {
            const dragCol = cols[dragColIndex];
            const newCols = produce(cols, draft => {
              draft.splice(dragColIndex, 1);
              draft.splice(dropColIndex, 0, dragCol);
            });
            setTempColumns(newCols);
          }
        }
      },
      [tempColumns]
    );

    const onDrop = useCallback(() => {
      if (tempColumns != null) {
        saveConfig({columns: tempColumns});
        setTempColumns(undefined);
      }
    }, [saveConfig, tempColumns]);

    const fetchProjectFieldOptions = React.useMemo(
      () =>
        makeFetchProjectFieldOptions(entityName, projectName, {
          types: ['string', 'number', 'boolean'],
          filterBy: key => {
            return !_.includes(columnAccessors, key.section + ':' + key.name);
          },
        }),
      [columnAccessors, entityName, projectName]
    );

    const axesTab = (
      <DragDropProvider>
        <Tab.Pane as="div" className="form-grid">
          {(tempColumns || renderColumns.current).map(col => {
            const isLog = !!col.log;
            const isInverted = !!col.inverted;

            const value = col.accessor || defaultKey;
            const valueOption = keyStringToWBMenuOption(value);

            return (
              <DropTarget
                key={value}
                partRef={{id: col.accessor || ''}}
                onDrop={onDrop}
                onDragEnter={({dragRef}) => {
                  onDragEnter(col, dragRef);
                }}>
                <DragSource partRef={{id: col.accessor || ''}}>
                  <div className="parallel-coordinates-settings__column">
                    <DragHandle partRef={{id: col.accessor || ''}}>
                      <LegacyWBIcon title="" size="large" name="handle" />
                    </DragHandle>
                    <div className="parallel-coordinates-settings__column__dimension">
                      <Popup
                        content={Run.keyStringDisplayName(col.accessor || '')}
                        trigger={
                          <div>
                            <S.StyledSearchableSelect
                              infiniteScroll
                              disableClearable
                              options={fetchProjectFieldOptions}
                              value={value}
                              initialOption={
                                valueOption == null ? undefined : valueOption
                              }
                              onSelect={v => {
                                setColumnAccessor(col, v as string);
                              }}></S.StyledSearchableSelect>
                          </div>
                        }
                      />
                    </div>
                    <div className="parallel-coordinates-settings__column__display-name">
                      <EditableLabel
                        key={col.accessor}
                        placeholder="Axis title"
                        onSave={(newName: string) => {
                          renameColumn(col, newName);
                        }}
                        // onBlur={() => this.setState({editingIndex: i})}
                        // editing={editingIndex === i}
                        serverText={col.displayName}
                        // onClick={() => false} // we use the menu option to trigger editing mode, rather than direct click
                      />
                    </div>
                    <Popup
                      content="Invert column"
                      inverted
                      position="top center"
                      size="tiny"
                      trigger={
                        <span>
                          <LegacyWBIcon
                            size="large"
                            title=""
                            name={isInverted ? 'sort-down' : 'sort-up'}
                            className={`parallel-coordinates-settings__action
                          parallel-coordinates-settings__action${
                            isInverted ? '-active' : ''
                          }`}
                            onClick={() => {
                              toggleColumnAttribute(col, 'inverted');
                            }}
                          />
                        </span>
                      }
                    />
                    <Popup
                      content="Log scale"
                      inverted
                      position="top center"
                      size="tiny"
                      trigger={
                        <span>
                          <LegacyWBIcon
                            name="log"
                            size="large"
                            title=""
                            className={`parallel-coordinates-settings__action
                          parallel-coordinates-settings__action${
                            isLog ? '-active' : ''
                          }`}
                            onClick={() => {
                              toggleColumnAttribute(col, 'log');
                            }}
                          />
                        </span>
                      }
                    />
                    <LegacyWBIcon
                      title=""
                      className="parallel-coordinates-settings__action"
                      size="large"
                      name="close"
                      onClick={() => {
                        removeColumn(col);
                      }}
                    />
                  </div>
                </DragSource>
              </DropTarget>
            );
          })}
          <Button
            className="parallel-coordinates-settings__add-column"
            onClick={addColumn}>
            <Icon title="" name="plus" />
            Add a column
          </Button>
        </Tab.Pane>
      </DragDropProvider>
    );

    const legendTab = (
      <Tab.Pane as="div" className="form-grid">
        <LabeledOption
          label="Title"
          option={
            <Input
              placeholder={''}
              value={config.chartTitle || ''}
              onChange={(e, {value}) => {
                saveConfig({
                  chartTitle: value,
                });
              }}
            />
          }
        />
        <p className="chart-label">
          Legend Fields
          <HelpPopup helpText="Set extra variables to show when hovering over chart" />
        </p>
        <ProjectFieldSelector
          className="legend"
          disabled={false}
          selection
          query={pageQuery}
          types={['string', 'number', 'boolean']}
          defaultKeys={[
            'run:displayName',
            'run:name',
            'run:createdAt',
            'run:userName',
          ]}
          fluid
          multi
          value={config.legendFields || ['run:displayName']}
          setValue={value => saveConfig({legendFields: value})}
          searchByKeyAndText
        />
      </Tab.Pane>
    );

    const gradientTab = (
      <Tab.Pane as="div" className="form-grid">
        <div className="chart-label">
          <Checkbox
            label="Color lines based on last column value"
            checked={config.gradientColor == null || config.gradientColor}
            onChange={(e, data) => {
              saveConfig({
                gradientColor: data.checked,
              });
            }}
          />
        </div>
        {(config.gradientColor == null || config.gradientColor) && (
          <GradientPicker
            defaultGradient={
              config.customGradient
                ? {
                    type: 'customGradient',
                    gradient: config.customGradient,
                  }
                : DEFAULT_GRADIENT
            }
            setGradient={newGradient => {
              saveConfig({customGradient: newGradient});
            }}
          />
        )}
      </Tab.Pane>
    );

    const settingsPanes = [
      {
        menuItem: 'Axes',
        render: () => axesTab,
      },
      {
        menuItem: 'Legend',
        render: () => legendTab,
      },
      {
        menuItem: 'Gradient',
        render: () => gradientTab,
      },
    ];

    return (
      <div className="chart-settings parallel-coordinates-settings">
        <Tab
          panes={settingsPanes}
          menu={{
            secondary: true,
            pointing: true,
            className: 'chart-settings-tab-menu',
          }}
        />
      </div>
    );
  },
  {id: 'PCSettings'}
);
