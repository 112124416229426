import React from 'react';
import {Transforms, Editor, Range, Text} from 'slate';
import isHotkey from 'is-hotkey';

export const EditorWithMarkShortcuts = {
  toggleMark(editor: Editor, mark: string) {
    const isActive = EditorWithMarkShortcuts.hasMark(editor, mark);

    if (editor.selection != null && Range.isCollapsed(editor.selection)) {
      if (isActive) {
        Editor.removeMark(editor, mark);
      } else {
        Editor.addMark(editor, mark, true);
      }
      return;
    }

    Transforms.setNodes(
      editor,
      {[mark]: isActive ? null : true},
      {match: Text.isText, split: true}
    );
  },

  hasMark(editor: Editor, mark: string) {
    if (editor.selection != null && Range.isCollapsed(editor.selection)) {
      const marks = Editor.marks(editor);
      return marks ? marks[mark] === true : false;
    }

    const [match] = Editor.nodes(editor, {
      match: n => Text.isText(n) && !n[mark],
    });

    return !match;
  },
};

const HOTKEYS: {[key: string]: (editor: Editor) => void} = {
  'mod+b': editor => EditorWithMarkShortcuts.toggleMark(editor, 'strong'),
  'mod+i': editor => EditorWithMarkShortcuts.toggleMark(editor, 'emphasis'),
  'mod+u': editor => EditorWithMarkShortcuts.toggleMark(editor, 'underline'),
  'mod+shift+s': editor => EditorWithMarkShortcuts.toggleMark(editor, 'delete'),
  'mod+shift+c': editor =>
    EditorWithMarkShortcuts.toggleMark(editor, 'inlineCode'),
};

export const markShortcutsOnKeyDown = (
  editor: Editor,
  e: React.KeyboardEvent
) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey)(e.nativeEvent)) {
      e.preventDefault();
      const func = HOTKEYS[hotkey];
      func(editor);
      return true;
    }
  }

  return false;
};
