import * as React from 'react';
import {useCallback, useMemo, useState, useEffect} from 'react';
import makeComp from '../../util/profiler';
import {ThemeProvider} from 'styled-components';
import {Button} from 'semantic-ui-react';
import * as QueryEditorStyles from './ExpressionEditor.styles';
import * as CG from '@wandb/cg/browser/graph';
import * as Op from '@wandb/cg/browser/ops';
import * as ExpressionEditor from './ExpressionEditor';
import {getPanelStacksForType} from './availablePanels';
import {PanelComp2} from './PanelComp';
import PanelNameEditor from './editors/PanelNameEditor';
import * as Code from '@wandb/cg/browser/code';
import * as Update from '../../util/update';
import * as Panel2 from './panel';
import * as LLReact from '../../cgreact';
import * as Types from '@wandb/cg/browser/model/types';
import * as CGTypes from '@wandb/cg/browser/types';
import {Spec as PanelSuperPlotSpec} from './PanelPlot';
import {PanelExportContextProvider} from './PanelExportContext';

export const AssignmentEditor: React.FC<{
  assignment: Code.Assignment;
  panelContext: any;
  updateAssignment(newAssignment: Code.Assignment): void;
}> = makeComp(
  // TODO: What to do if inputType is no longer valid for a step?
  ({assignment, updateAssignment}) => {
    return (
      <div>
        {assignment.name}
        {' = '}
        <ExpressionEditor.ExpressionEditor
          focusOnMount={true}
          node={assignment.node}
          updateNode={newNode => {
            console.log('NEW NODE FOR ASSIGNEMNT', assignment.name, newNode);
            updateAssignment({...assignment, node: newNode});
          }}
        />
      </div>
    );
  },
  {id: 'NodeEditor'}
);

interface Panel {
  panelId: string | undefined;
  config: any;
  // inputVariable: Code.Assignment;
}

interface Block {
  rows: Array<Panel | Code.Assignment>;
}

function blockRowIsAssignment(
  blockRow: Panel | Code.Assignment
): blockRow is Code.Assignment {
  return (blockRow as Code.Assignment).node != null;
}

function nextVarName(block: Block): string {
  let varName = 'a';
  for (let i = 0; i < 26; i++) {
    let found = false;
    for (const br of block.rows) {
      if (blockRowIsAssignment(br) && br.name === varName) {
        found = true;
        break;
      }
    }
    if (!found) {
      return varName;
    }
    varName = String.fromCharCode(varName.charCodeAt(0) + 1);
  }
  return varName;
}

export const EditorPanel: React.FC<{
  varName: string;
  node: Types.Node;
  panelId: string | undefined;
  config: any;
  panelContext: any;
  updatePanelId(newPanelId: string): void;
  updateConfig(newConfig: any): void;
  updatePanelContext(newContext: any): void;
}> = makeComp(
  ({
    node,
    varName,
    panelId,
    config,
    panelContext,
    updatePanelId,
    updateConfig,
    updatePanelContext,
  }) => {
    const {curPanelId, stackIds, handler} = useMemo(
      () => getPanelStacksForType(node.type, panelId),
      [node.type, panelId]
    );

    return curPanelId == null ? (
      <div>No panel for type</div>
    ) : (
      <div style={{marginTop: 16}}>
        {curPanelId != null && (
          <>
            <PanelNameEditor
              autocompleteOptions={stackIds.map(si => ({
                name: si.displayName,
                value: si.id,
              }))}
              value={curPanelId}
              setValue={val => updatePanelId(val as string)}
            />
            ({varName})
          </>
        )}
        {handler != null && (
          <div
            style={{
              border: '1px solid #eee',
              backgroundColor: 'white',
              padding: 8,
            }}>
            <PanelComp2
              input={{path: node}}
              inputType={node.type}
              loading={false}
              panelSpec={handler}
              configMode={false}
              context={panelContext}
              config={config}
              updateConfig={updateConfig}
              updateContext={updatePanelContext}
            />
          </div>
        )}
      </div>
    );
  },
  {id: 'EditorPanel'}
);

// function staceyMendeleev() {
//   const entityName = Op.constString('stacey');
//   const projectName = Op.constString('mendeleev');
//   return Op.opRootProject({entityName, projectName});
// }

function fasionSweepRun() {
  const entityName = Op.constString('shawn');
  const projectName = Op.constString('fasion-sweep');
  const runName = Op.constString('wt85bdxv');
  return Op.opProjectRun({
    project: Op.opRootProject({entityName, projectName}),
    runName,
  });
}

function artifactTable() {
  const entityName = Op.constString('shawn');
  const projectName = Op.constString('dsviz_demo');
  const run = Op.opProjectRun({
    project: Op.opRootProject({entityName, projectName}),
    // TODO: run name
    runName: Op.constString('1ou8xdl2'),
  });
  const loggedArtifact = Op.opRunLoggedArtifactVersion({
    run: run as any,
    artifactVersionName: Op.constString('train_result:v3'),
  });
  const file = Op.opArtifactVersionFile({
    artifactVersion: loggedArtifact,
    path: Op.constString('train_iou_score_table.table.json'),
  });
  const table = Op.opFileTable({
    file: file as any,
  });
  return table;
}

export const EditorFromScratch2: React.FC<{}> = makeComp(
  () => {
    const assignButtonRef = React.useRef<Button>(null);
    const [block, setBlock] = useState<Block>({
      rows: [
        {
          name: 'run',
          node: fasionSweepRun(),
        },
        {
          name: 'artifact_table',
          node: artifactTable(),
        },
        {
          name: 'query',
          node: CG.voidNode(),
        },
        // {
        //   name: 'query',
        //   node: staceyMendeleev(),
        // },
        {
          panelId: undefined as any,
          config: {},
        },
      ],
    });
    const [panelContext, setPanelContext] = useState<any>({});
    const updatePanelContext = useCallback<any>((newContext: any) => {
      setPanelContext((curContext: any) => ({...curContext, ...newContext}));
    }, []);

    useEffect(() => {
      assignButtonRef.current?.focus();
    }, []);

    let curFrame = Code.newCodeBlock();

    let lastOutput: CGTypes.EditingNode = CG.voidNode();
    let lastVarname = 'null';

    const children: JSX.Element[] = [];
    for (let i = 0; i < block.rows.length; i++) {
      const blockRow = block.rows[i];
      if (blockRowIsAssignment(blockRow)) {
        children.push(
          <AssignmentEditor
            key={i}
            assignment={blockRow}
            panelContext={panelContext}
            updateAssignment={newAssignment =>
              setBlock({
                ...block,
                rows: Update.updateArrayIndex(block.rows, i, newAssignment),
              })
            }
          />
        );
        // Add variables to frame as we go, so downward cells can access
        curFrame = {
          ...curFrame,
          statements: [...curFrame.statements, blockRow],
        };
        lastOutput = blockRow.node;
        lastVarname = blockRow.name;
      } else {
        const {panelId, config} = blockRow;
        children.push(
          lastOutput.nodeType !== 'output' ? (
            <div>-</div>
          ) : (
            <EditorPanel
              key={i}
              node={lastOutput as Types.Node}
              varName={lastVarname}
              panelId={panelId}
              config={config}
              panelContext={panelContext}
              updatePanelId={newPanelId =>
                setBlock({
                  ...block,
                  rows: Update.updateArrayIndex(block.rows, i, {
                    ...blockRow,
                    panelId: newPanelId,
                  }),
                })
              }
              updateConfig={newConfig =>
                setBlock({
                  ...block,
                  rows: Update.updateArrayIndex(block.rows, i, {
                    ...blockRow,
                    config: {
                      ...blockRow.config,
                      ...newConfig,
                    },
                  }),
                })
              }
              updatePanelContext={updatePanelContext}
            />
          )
        );
      }
    }

    return (
      <PanelExportContextProvider>
        <LLReact.ComputeGraphContextProvider>
          <ThemeProvider theme={QueryEditorStyles.themes.light}>
            <div style={{padding: 32, display: 'flex'}}>
              <div style={{flex: 1}}>
                {children}
                <div style={{marginTop: 16}}>
                  <Button
                    ref={assignButtonRef}
                    onClick={() =>
                      setBlock({
                        rows: [
                          ...block.rows,
                          {
                            name: nextVarName(block),
                            node: CG.varNode(Types.list('number'), 'a'),
                          },
                        ],
                      })
                    }>
                    Assignment
                  </Button>
                  {lastOutput.nodeType !== 'void' && (
                    <Button
                      onClick={() =>
                        setBlock({
                          rows: [...block.rows, {panelId: '', config: {}}],
                        })
                      }>
                      Panel
                    </Button>
                  )}
                </div>
              </div>
              {/* <div style={{flex: 1}}>
              <pre>{JSON.stringify(block, undefined, 2)}</pre>
              <ComputeGraphViz node={node} width={600} height={600} />
            </div> */}
            </div>
          </ThemeProvider>
        </LLReact.ComputeGraphContextProvider>
      </PanelExportContextProvider>
    );
  },
  {id: 'EditorFromScratch2'}
);

export const SuperPlotRuns: React.FC<{
  entityName: string;
  projectName: string;
}> = makeComp(
  props => {
    const [panelConfig, setPanelConfig] = useState<any>({});
    const updatePanelConfig = useCallback<Panel2.UpdateContext>(
      newConfig => {
        setPanelConfig({...panelConfig, ...newConfig});
      },
      [panelConfig]
    );
    const [panelContext, setPanelContext] = useState<Panel2.PanelContext>({});
    const updatePanelContext = useCallback<Panel2.UpdateContext>(
      newContext => {
        setPanelContext({...panelContext, ...newContext});
      },
      [panelContext]
    );

    const entityName = Op.constString(props.entityName);
    const projectName = Op.constString(props.projectName);
    const project = Op.opRootProject({entityName, projectName});
    const runs = Op.opProjectRuns({project});

    return (
      <PanelComp2
        input={{path: runs}}
        inputType={runs.type}
        loading={false}
        panelSpec={PanelSuperPlotSpec}
        configMode={false}
        context={panelContext}
        config={panelConfig}
        updateConfig={updatePanelConfig}
        updateContext={updatePanelContext}
      />
    );
  },
  {id: 'SuperPlotRuns'}
);
