/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'dual-yaxis-plot',
  name: 'Dual y-axis plot (run history)',
  description:
    'A Vega line plot that plots history values across runs. You can choose the left and right y-axis variables independently.',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 500,
    height: 200,
    padding: 5,

    signals: [
      {
        name: 'interpolate',
        value: 'linear',
        bind: {
          input: 'select',
          options: [
            'basis',
            'cardinal',
            'catmull-rom',
            'linear',
            'monotone',
            'natural',
            'step',
            'step-after',
            'step-before',
          ],
        },
      },
    ],

    data: [
      {
        name: '${history-table:y1-input:x-axis,y1-axis}',
      },
      {
        name: '${history-table:y2-input:x-axis,y2-axis}',
      },
      {
        name: 'y1-data',
        source: 'y1-input',
        transform: [
          {
            type: 'collect',
            sort: {field: '${history-field:x-axis}'},
          },
        ],
      },
      {
        name: 'y2-data',
        source: 'y2-input',
        transform: [
          {
            type: 'collect',
            sort: {field: '${history-field:x-axis}'},
          },
        ],
      },
      {
        name: 'history',
        source: ['y1-data', 'y2-data'],
        transform: [
          {
            type: 'collect',
            sort: {field: '${history-field:x-axis}'},
          },
        ],
      },
    ],

    scales: [
      {
        name: 'x',
        type: 'point',
        range: 'width',
        domain: {
          data: 'history',
          field: '${history-field:x-axis}',
        },
      },
      {
        name: 'y1',
        type: 'linear',
        range: 'height',
        nice: true,
        zero: true,
        domain: {
          data: 'y1-data',
          field: '${history-field:y1-axis}',
        },
      },
      {
        name: 'y2',
        type: 'linear',
        range: 'height',
        nice: true,
        zero: true,
        domain: {
          data: 'y2-data',
          field: '${history-field:y2-axis}',
        },
      },
      {
        name: 'color',
        type: 'ordinal',
        range: 'category',
        domain: {data: 'history', field: 'runName'},
      },
    ],

    axes: [
      {orient: 'bottom', scale: 'x', title: '${history-field:x-axis}'},
      {
        orient: 'left',
        scale: 'y1',
        title: '${history-field:y1-axis}',
        domainWidth: 2,
      },
      {
        orient: 'right',
        scale: 'y2',
        title: '${history-field:y2-axis}',
        domainDash: [4, 4],
        domainWidth: 2,
      },
    ],

    legends: [
      {
        title: 'Run',
        fill: 'color',
      },
    ],

    title: {
      text: '2 y-axis plot, multiple runs (history)',
    },

    marks: [
      {
        type: 'group',
        from: {
          facet: {
            name: 'y1-series',
            data: 'y1-data',
            groupby: 'runName',
          },
        },
        marks: [
          {
            type: 'line',
            from: {data: 'y1-series'},
            encode: {
              enter: {
                x: {scale: 'x', field: '${history-field:x-axis}'},
                y: {
                  scale: 'y1',
                  field: '${history-field:y1-axis}',
                },
                stroke: {scale: 'color', field: 'runName'},
                strokeWidth: {value: 1},
              },
              update: {
                interpolate: {signal: 'interpolate'},
                fillOpacity: {value: 1},
              },
              hover: {
                fillOpacity: {value: 0.5},
              },
            },
          },
        ],
      },
      {
        type: 'group',
        from: {
          facet: {
            name: 'y2-series',
            data: 'y2-data',
            groupby: 'runName',
          },
        },
        marks: [
          {
            type: 'line',
            from: {data: 'y2-series'},
            encode: {
              enter: {
                x: {scale: 'x', field: '${history-field:x-axis}'},
                y: {
                  scale: 'y2',
                  field: '${history-field:y2-axis}',
                },
                stroke: {scale: 'color', field: 'runName'},
                strokeWidth: {value: 1},
                strokeDash: {value: [4, 4]},
              },
              update: {
                interpolate: {signal: 'interpolate'},
                fillOpacity: {value: 1},
              },
              hover: {
                fillOpacity: {value: 0.5},
              },
            },
          },
        ],
      },
    ],
  },
};

export default def;
