import _ from 'lodash';
import React from 'react';
import {Segment, Table, TableHeaderCell} from 'semantic-ui-react';
import {LookupKey} from '../util/runs';

// TODO: Read through index types and make this type safe.
// https://www.typescriptlang.org/docs/handbook/advanced-types.html
//
// I should be able to use this technique from the pluck function below
//
// function pluck<T, K extends keyof T>(o: T, names: K[]): T[K][] {
//   return names.map(n => o[n]);
// }

type VirtualDomValue = JSX.Element | string;

type Constraints<T> =
  | {
      key: LookupKey;
    }
  | {
      bodyFn: (item: T) => VirtualDomValue;
    }
  | {
      value: VirtualDomValue;
    };

export interface EasyTableProps<T> {
  rows: T[];
  cols: Array<
    {
      header: VirtualDomValue;
      key?: LookupKey;
      value?: VirtualDomValue;
      bodyFn?: (item: T, index?: number) => VirtualDomValue;
    } & Constraints<T>
  >;
  maxRows?: number;
}

// The purpose of EasyTable is to provide a simple table to render client side
// collections of data.
//
// The scope of it should stay concise and straightforward, avoid adding complex =
// behaviors and/or server interactions.
export const EasyTable = <T,>({
  rows: propsRows,
  cols,
  maxRows,
}: EasyTableProps<T>) => {
  const rows = maxRows ? propsRows : propsRows.slice(0, maxRows);
  if (rows.length === 0) {
    return <Segment>No runs yet!</Segment>;
  }
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {_.map(cols, (col, index) => {
            return <TableHeaderCell key={index}>{col.header}</TableHeaderCell>;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(rows, (item, index) => (
          <Table.Row key={index}>
            {_.map(cols, (col, innerIndex) => {
              let cellBody;
              if (col.key !== undefined) {
                const v = _.get(item, col.key);
                if (!(typeof v === 'string')) {
                  console.warn(
                    `Index must correspond to a string value: ${col.key}`,
                    {
                      column: col,
                      item,
                    }
                  );
                }
                cellBody = typeof v === 'string' ? v : '-';
              } else if (col.value !== undefined) {
                cellBody = col.value;
              } else if (col.bodyFn !== undefined) {
                cellBody = col.bodyFn(item, index);
              } else {
                console.warn(
                  'Each column must have an index, value, or bodyFn',
                  {
                    column: col,
                    item,
                  }
                );
              }
              return (
                <Table.Cell singleLine key={innerIndex}>
                  {cellBody || '-'}
                </Table.Cell>
              );
            })}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
