import _ from 'lodash';
import * as d3 from 'd3';
import * as Run from '../../util/runs';
import {NULL_STRING, NULL_STRING_ANGLE_BRACKETS} from '../../util/constants';
import {PCColumn} from '../PanelParallelCoord';
import {PanelSelectionConstraint} from '../../util/selectionmanager';

// Utility functions for the Parallel Coordinates plot (PanelParallelCoord.tsx)
//
// This file defines:
// - rowsToYData(), which is the main function to get plot data from run data
// - NumericDimension and StringDimension classes, used internally by rowsToYData
// - a few small helper functions.

export function rowsToYData(
  rows: Array<{[key: string]: Run.Value}>,
  columns: PCColumn[],
  plotScale: d3.ScaleLinear<any, any>,
  gradientColors: number[][],
  grayIndex: number,
  posInfColorIndex: number,
  negInfColorIndex: number
) {
  const possibleTypes: {[key: string]: {string: boolean; number: boolean}} = {};
  rows.forEach((row: any) => {
    Object.keys(row).forEach(col => {
      if (col === 'name') {
        return;
      }

      const val = row[col];

      if (!(col in possibleTypes)) {
        // it's possible to convert anything to a string
        possibleTypes[col] = {
          string: true,
          number: false,
        };
      }
      if (NumericDimension.isVal(val)) {
        possibleTypes[col].number = true;
      }
    });
  });

  const colTypes = _.mapValues(possibleTypes, t => {
    if (t.number) {
      return 'number';
    } else {
      return 'string';
    }
  });

  const yData: {[key: string]: NumericDimension | StringDimension} = {};
  if (rows.length > 0) {
    Object.keys(rows[0]).forEach(accessor => {
      const column = _.find(columns, {accessor});
      const type = colTypes[accessor];
      if (column != null) {
        if (type === 'number') {
          yData[accessor] = new NumericDimension(
            column,
            rows,
            plotScale,
            gradientColors,
            grayIndex,
            posInfColorIndex,
            negInfColorIndex
          );
        } else if (type === 'string') {
          yData[accessor] = new StringDimension(
            column,
            rows,
            plotScale,
            gradientColors,
            grayIndex
          );
        }
      }
    });
  }
  return yData;
}

/* HELPER FUNCTIONS */

export function rgbStr(rgb: number[]) {
  const [r, g, b] = rgb;
  return `rgb(${r}, ${g}, ${b})`;
}

// Returns a number whose value is limited to the given range.
// Example: limit the output of this computation to between 0 and 255
// (x * 255).clamp(0, 255)
function clamp(n: number, min: number, max: number) {
  return Math.min(Math.max(n, min), max);
}

function fakeScale(
  scale: (v: number | string) => number,
  invert: (v: number) => number | string,
  range: number[]
) {
  const s: any = scale.bind(null);

  s.copy = () => {
    return fakeScale(scale, invert, range);
  };

  s.invert = invert;

  s.range = () => {
    return range;
  };

  return s;
}

/* DIMENSIONS */

class NumericDimension {
  static toVal(val: any) {
    // nan, Infinite, and -Infinite are weird versions that accidentally got stored in MySQL
    if (typeof val === 'number' || val instanceof Number) {
      return val;
    } else if (val === 'NaN' || val === 'nan') {
      return NaN;
    } else if (val === Infinity || val === 'Infinity' || val === 'Infinite') {
      return Infinity;
    } else if (
      val === -Infinity ||
      val === '-Infinity' ||
      val === '-Infinite'
    ) {
      return -Infinity;
    } else {
      val = parseFloat(val);
      if (_.isFinite(val)) {
        return val;
      } else {
        return null;
      }
    }
  }

  static isVal(val: number) {
    return NumericDimension.toVal(val) !== null;
  }

  brush?: d3.BrushBehavior<any>;
  minVal: number;
  maxVal: number;
  hasNull: boolean;
  hasInfinity: boolean;
  hasNaN: boolean;
  values: Array<number | null>;
  numScale: d3.ScaleContinuousNumeric<number, number>;
  nanScale: d3.ScaleOrdinal<string, number>;
  finiteSpace: number;
  finiteRangeStart: number;
  finiteRangeEnd: number;
  normScale: any;
  plotScale: any;
  normVals: any;
  plotVals: any;
  nanColorScale: d3.ScaleOrdinal<string, number>;
  numColorScale?: d3.ScaleContinuousNumeric<number, number>;
  colorScale: any;
  colors: any;
  colorStartY: any;
  colorEndY: any;
  getSelectionConstraint: (
    d3Selection: number[],
    inverted?: boolean
  ) => PanelSelectionConstraint;

  constructor(
    column: PCColumn,
    runs: Array<{[key: string]: Run.Value}>,
    plotScale: any,
    gradientColors: number[][],
    grayIndex: number,
    posInfColorIndex: number,
    negInfColorIndex: number
  ) {
    const d3ScaleFn = column.log ? d3.scaleLog : d3.scaleLinear;

    // this.column = column;
    this.minVal = Infinity;
    this.maxVal = -Infinity;
    this.hasNull = false;
    this.hasInfinity = false;
    this.hasNaN = false;

    // Returns the groupSelections.selections.bounds for this axis
    this.getSelectionConstraint = (
      d3Selection: number[],
      inverted: boolean = false
    ): PanelSelectionConstraint => {
      let low = null;
      let high = null;
      [high, low] = d3Selection.map((v: any) => {
        return this.plotScale.invert(v);
      });

      if (inverted) {
        [low, high] = [high, low];
      }

      if (low === high) {
        low = null;
        high = null;
      }
      return {low, high};
    };

    this.values = runs.map(run => {
      let val = NumericDimension.toVal(run[column.accessor || '']);
      // Filter out negative vals in log plots
      if (column.log) {
        if (val < 0) {
          val = null;
        } else if (val === 0) {
          val = -Infinity;
        }
      }

      if (val === null) {
        this.hasNull = true;
      } else if (isNaN(val)) {
        this.hasNaN = true;
      } else if (!_.isFinite(val)) {
        this.hasInfinity = true;
      } else {
        if (this.minVal === null || val < this.minVal) {
          this.minVal = val;
        }
        if (this.maxVal === null || val > this.maxVal) {
          this.maxVal = val;
        }
      }

      return val as number | null;
    });

    // d3.scaleLog.domain.ticks returns an empty array if one of the bounds is 0
    if (column.log && this.minVal === 0) {
      this.minVal = 0.000001;
    }

    this.numScale = d3ScaleFn().domain(
      column.inverted ? [this.maxVal, this.minVal] : [this.minVal, this.maxVal]
    );

    if (!column.log) {
      this.numScale.nice();
    }

    const numScaleTicks = this.numScale.ticks();

    // portions of the axis for different sections
    // finiteSpace: range of finite numbers, a superset of [this.min, this.max]
    // infiniteSpace: -Infinity to Infinity (if data has infinities)
    // totalSpace: null, NaN, (if data has them) + infiniteSpace
    // d3 decides how many ticks there will be based on the domain

    if (column.log) {
      this.finiteSpace = Math.max(1, numScaleTicks.length - 1);
    }
    this.finiteSpace = Math.max(1, numScaleTicks.length - 1);
    let infiniteSpace = this.finiteSpace;
    if (this.hasInfinity) {
      infiniteSpace += 2; // +/-Infinity
    }
    let totalSpace = infiniteSpace;
    if (this.hasNaN || this.hasNull) {
      // a little extra space between the numeric and non-numeric areas
      totalSpace += 2;
    }
    if (this.hasNaN && this.hasNull) {
      totalSpace += 1;
    }

    // null, NaN, and infinities
    const nanDomain: string[] = [];

    // tick text for non-finite values
    const preFiniteTickVals = [];
    const postFiniteTickVals = [];
    if (this.hasNull) {
      nanDomain.push('null');
    }
    if (this.hasNaN) {
      nanDomain.push('NaN');
    }
    if (this.hasNull || this.hasNaN) {
      preFiniteTickVals.push(0);
    }
    if (this.hasNull && this.hasNaN) {
      preFiniteTickVals.push(1 / totalSpace);
    }

    if (this.hasInfinity) {
      nanDomain.push((-Infinity).toString());
      nanDomain.push(Infinity.toString());
      preFiniteTickVals.push(1 - infiniteSpace / totalSpace);
      postFiniteTickVals.push(1);
      this.finiteRangeStart = 1 - (this.finiteSpace + 1) / totalSpace;
      this.finiteRangeEnd = 1 - 1 / totalSpace;
    } else {
      this.finiteRangeStart = 1 - this.finiteSpace / totalSpace;
      this.finiteRangeEnd = 1;
    }

    this.nanScale = d3
      .scaleOrdinal(_.concat(preFiniteTickVals, postFiniteTickVals))
      .domain(nanDomain);

    this.numScale.range([this.finiteRangeStart, this.finiteRangeEnd]);

    this.normScale = fakeScale(
      (v: number | string) => {
        if (_.isFinite(v)) {
          return this.numScale(v as number);
        } else {
          return this.nanScale(v as string);
        }
      },
      (v: number) => this.numScale.invert(v),
      [0, 1]
    );
    this.plotScale = fakeScale(
      v => plotScale(this.normScale(v)),
      v => this.normScale.invert(plotScale.invert(v)),
      plotScale.range()
    );
    const tickValues = _.sortBy(
      _.concat(
        nanDomain as Array<string | number>,
        numScaleTicks as Array<string | number>
      ),
      this.normScale // sort by normScale because plotScale may have a different order
    );
    this.plotScale.ticks = () => tickValues; // breaks the ticks interface slightly by ignoring the count argument
    this.plotScale.tickFormat = (count?: number, specifier?: string) => {
      const numFormat = this.numScale.tickFormat(count, specifier);
      return (tick: number | string | null) => {
        if (_.isFinite(tick)) {
          return numFormat((tick as number | null) ?? 0);
        } else if (tick === null || tick === 'null') {
          return NULL_STRING;
        } else if (isNaN(Number(tick))) {
          return 'NaN';
        } else if (tick === -Infinity) {
          return '-∞';
        } else if (tick === Infinity) {
          return '∞';
        } else {
          return String(tick);
        }
      };
    };

    this.normVals = this.values.map(this.normScale);
    this.plotVals = this.normVals.map(plotScale);

    this.nanColorScale = d3
      .scaleOrdinal([grayIndex, grayIndex, negInfColorIndex, posInfColorIndex])
      .domain([
        'null',
        NaN.toString(),
        (-Infinity).toString(),
        Infinity.toString(),
      ]);

    // legacy case
    this.numColorScale = d3ScaleFn()
      .domain([numScaleTicks[0], numScaleTicks[numScaleTicks.length - 1]])
      .range([0, gradientColors.length - 1]);

    this.colorScale = (v: number | null) => {
      if (v != null && _.isFinite(v) && this.numColorScale != null) {
        // fix rounding bug where v could be smaller than the lower bound of the color scale
        v = clamp(
          v,
          Math.min(numScaleTicks[0], numScaleTicks[numScaleTicks.length - 1]),
          Math.max(numScaleTicks[0], numScaleTicks[numScaleTicks.length - 1])
        );
        return Math.round(this.numColorScale(v));
      } else {
        return this.nanColorScale(v != null ? v.toString() : 'null');
      }
    };

    this.colors = this.values.map(this.colorScale);
    this.colorStartY = this.plotScale(numScaleTicks[0]);
    this.colorEndY = this.plotScale(numScaleTicks[numScaleTicks.length - 1]);
  }

  tickFormat(val: number | null) {
    if (_.isFinite(val)) {
      return this.numScale(val as number);
    } else {
      return this.nanScale(
        val != null ? val.toString() : NULL_STRING_ANGLE_BRACKETS
      );
    }
  }
}

class StringDimension {
  static toVal(val: any): string | null {
    if (typeof val === 'string') {
      return val;
    } else if (val == null) {
      return NULL_STRING_ANGLE_BRACKETS;
    } else {
      return val.toString();
    }
  }

  static isVal(val: any) {
    return val != null;
  }

  brush?: d3.BrushBehavior<any>;
  domain: string[];
  tickValues: Array<number | string | null>;
  normScale: d3.ScaleOrdinal<string, unknown>;
  inverseNormScale: d3.ScaleLinear<number, number>;
  plotScale: any;
  normVals: any;
  plotVals: any;
  colorScale: any;
  colors: any;
  colorStartY: any;
  colorEndY: any;
  getSelectionConstraint: (d3Selection: number[]) => PanelSelectionConstraint;

  constructor(
    column: PCColumn,
    runs: Array<{[key: string]: Run.Value}>,
    plotScale: any,
    gradientColors: number[][],
    grayIndex: number
  ) {
    const stringValues = runs.map(run => {
      return StringDimension.toVal(run[column.accessor || '']);
    });

    this.domain = _(stringValues)
      .filter(s => {
        return s !== null;
      })
      .sort()
      .sortedUniq()
      .value() as string[];

    if (this.domain.length <= 20) {
      this.tickValues = _.clone(this.domain);
    } else {
      this.tickValues = _.range(0, 1.01, 0.1).map(f => {
        return this.domain[Math.round(f * (this.domain.length - 1))];
      });
    }

    // Returns the groupSelections.selections.bounds for this axis
    this.getSelectionConstraint = (
      d3Selection: number[]
    ): PanelSelectionConstraint => {
      const [high, low] = d3Selection.map((v: any) => {
        return this.plotScale.invert(v);
      });

      const fromIndex = this.domain.indexOf(low);
      const toIndex = this.domain.indexOf(high) + 1;
      const domainSlice = this.domain.slice(fromIndex, toIndex);

      return {match: domainSlice};
    };

    const hasNull = stringValues.some(v => v === null);
    if (hasNull) {
      this.domain.splice(0, 0, NULL_STRING_ANGLE_BRACKETS);
    }

    this.normScale = d3
      .scaleOrdinal()
      .domain(this.domain)
      .range(_.range(0, 1.01, 1 / (this.domain.length - 1)))
      .unknown(undefined);

    this.inverseNormScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, this.domain.length - 1]);

    this.plotScale = fakeScale(
      (v: any) => plotScale(this.normScale(v)),
      (v: any) =>
        this.domain[Math.round(this.inverseNormScale(plotScale.invert(v)))],
      plotScale.range()
    );
    this.plotScale.ticks = () => this.tickValues;
    this.plotScale.tickFormat = () => {
      return (tick: any) => {
        if (tick === null) {
          return NULL_STRING;
        } else {
          return tick;
        }
      };
    };

    if (hasNull) {
      this.tickValues.splice(0, 0, null);
    }

    this.normVals = stringValues.map(v =>
      this.normScale(v != null ? v : NULL_STRING_ANGLE_BRACKETS)
    );
    this.plotVals = this.normVals.map(plotScale);

    const colorRange = _.range(0, 1.01, 1 / (this.domain.length - 1)).map(v =>
      Math.round(v * (gradientColors.length - 1))
    );
    if (hasNull) {
      colorRange.splice(0, 0, grayIndex);
    }
    this.colorScale = d3
      .scaleOrdinal()
      .range(colorRange)
      .domain(this.domain)
      .unknown(undefined);

    this.colors = stringValues.map(this.colorScale);
    this.colorStartY = plotScale(0);
    this.colorEndY = plotScale(1);
  }
}
