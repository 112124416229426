import _ from 'lodash';
import React from 'react';
import {useCallback} from 'react';

import {useDispatch} from '../../state/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as SortActions from '../../state/views/sort/actions';
import * as SortTypes from '../../state/views/sort/types';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';

import {
  WBTableActionSort,
  WBTableActionSortButton,
  WBTableActionSortPicker,
} from '../WBTable/WBTableActionsSort';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';
import produce from 'immer';
import makeComp from '../../util/profiler';

interface RunsSortButtonProps {
  compact?: boolean;
  sortOpen: boolean;
  sortRef: SortTypes.Ref;
}

const RunsSortButton = makeComp(
  (props: RunsSortButtonProps) => {
    const sort = ViewHooks.useWhole(props.sortRef);

    const passThroughProps = _.omit(props, 'compact', 'sortOpen', 'sortRef');
    return (
      <WBTableActionSortButton
        {...passThroughProps} // Required for use as popup trigger
        compact={props.compact}
        sortOpen={props.sortOpen}
        sort={sort}
      />
    );
  },
  {id: 'RunsSortButton'}
);

interface RunsSortPickerProps {
  entityName: string;
  projectName: string;
  sortRef: SortTypes.Ref;
  onSortChanged(): void;
}

const RunsSortPicker = makeComp(
  (props: RunsSortPickerProps) => {
    const {entityName, projectName, sortRef, onSortChanged} = props;

    const defaultKeys = [
      'run:displayName',
      'run:state',
      'run:createdAt',
      'run:duration',
      'run:username',
      'run:sweep',
      'run:host',
    ];

    const sort = ViewHooks.useWhole(props.sortRef);
    const dispatch = useDispatch();
    const setSort = useCallback(
      (newSort: Query.Sort) => {
        dispatch(SortActions.set(sortRef, newSort));
        onSortChanged();
      },
      [dispatch, sortRef, onSortChanged]
    );

    return (
      <WBTableActionSortPicker
        sort={sort}
        setSort={setSort}
        makeDropdown={sortKeyIdx => (
          <NamedProjectFieldSelector
            data-test="sort-dropdown"
            className="sort"
            entityName={entityName}
            projectName={projectName}
            defaultKeys={defaultKeys}
            multi={false}
            selection
            searchByKeyAndText
            value={Run.keyToString(sort.keys[sortKeyIdx].key)}
            setValue={value =>
              setSort(
                produce(sort, draft => {
                  draft.keys[sortKeyIdx].key = Run.keyFromString(value)!;
                })
              )
            }
          />
        )}
      />
    );
  },
  {id: 'RunsSortPicker'}
);

type RunsSortTableActionProps = RunsSortPickerProps & {
  compact?: boolean;
  open: boolean;
  onOpen(): void;
  onClose(): void;
};

export const RunsSortTableAction = makeComp(
  (props: RunsSortTableActionProps) => {
    return (
      <WBTableActionSort
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        trigger={open => (
          <RunsSortButton
            compact={props.compact}
            sortOpen={open}
            sortRef={props.sortRef}
          />
        )}
        content={
          <RunsSortPicker
            entityName={props.entityName}
            projectName={props.projectName}
            sortRef={props.sortRef}
            onSortChanged={props.onSortChanged}
          />
        }
      />
    );
  },
  {id: 'RunsSortTableAction'}
);
