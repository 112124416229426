import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {WBIcon} from '@wandb/ui';

export const SubscriptionOrgName = styled.div`
  font-family: ${globals.fontName};
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

export const SubscriptionCard = styled.div`
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 4px;
`;

export const SubscriptionCardBanner = styled.div`
  width: 100%;
  padding: 16px;
  color: white;
  background: ${globals.errorDark};
`;

export const SubscriptionCardRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
`;

export const SubscriptionCardPlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
export const SubscriptionCardPlanHeader = styled.div`
  font-family: ${globals.fontName};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;
export const SubscriptionCardPlanType = styled.div`
  font-family: ${globals.fontName};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;
export const SubscriptionCardPlanMembers = styled.div``;
export const SubscriptionCardPlanUsage = styled.div``;

export const SubscriptionCardHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const SubscriptionCardContentContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
  flex-direction: row;
`;

export const SubscriptionCardContent = styled.div`
  width: 75%;
  margin-left: 16px;
`;
export const SubscriptionCardContentRight = styled(SubscriptionCardContent)`
  text-align: right;
`;

export const SubscriptionCardContentLeft = styled.div`
  width: 50%;
  margin-bottom: 16px;
`;
export const SubscriptionCardContentUsage = styled.div`
  width: 15%;
`;
export const SubscriptionCardContentEstimate = styled.div`
  width: 50%;
`;

export const AddMembers = styled.div`
  margin-top: 16px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: ${globals.gray700};
  cursor: pointer;

  &:hover {
    color: ${globals.primaryText};
  }
`;

export const ConfigIcon = styled(WBIcon)`
  color: black;
  padding-right: 4px;
`;

export const UsagePlanTeams = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsagePlanTeamInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const UsagePlanDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const UsagePlanTeamField = styled.div<{over?: boolean}>`
  min-width: 140px;
  color: ${props => (props.over ? globals.errorDark : globals.gray800)};
`;

export const UsagePlanTeamName = styled.span`
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 4px;
`;

export const UsagePlanTeamFieldTitle = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

export const UsagePlanContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const UsagePlanContentVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsagePlanContentVerticalItem = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

export const AccountPlanContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AccountPlanContentItem = styled.div`
  width: 50%;
`;

export const UsagePlanContentItem = styled.div`
  width: 30%;
`;

export const UsagePlanContentItemTitle = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

export const UsagePlanStatus = styled.span`
  margin-right: 5px;
  margin-bottom: 24px;
`;

export const SubscriptionCardLink = styled.span`
  color: ${GLOBAL_COLORS.linkBlue.toString()};
  cursor: pointer;
  margin-top: 12px;
`;

const ButtonStyles = `
  border: none;
  background: ${globals.primary};
  color: white;
  &:hover {
    background: #005e80;
    color: white;
  }
`;

export const SeePricingButton = styled.div`
  margin-bottom: 12px;
  margin-right: 12px;
  .action-button {
    ${ButtonStyles}
  }
`;

export const UpgradePlanButton = styled.div`
  margin-top: 18px;
  .action-button {
    ${ButtonStyles}
  }
`;

export const UpgradeStorage = styled.div`
  margin-top: 18px;
  border-radius: 5px;
`;

export const UpgradeStorageButton = styled(Button)`
  border: 1px solid ${globals.gray400};
  border: none;
  background: ${globals.gray100};
  color: black;
  &:hover {
    background: ${globals.gray400};
    color: black;
  }
`;

export const ContactSalesButton = styled.div`
  margin-bottom: 12px;
  border-radius: 5px;
  border: 1px solid ${globals.gray400};

  .action-button {
    border: none;
    background: ${globals.gray100};
    color: black;
    &:hover {
      background: ${globals.gray400};
      color: black;
    }
  }
`;

export const UsageSubscriptionPopup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;
export const UsageSubscriptionPopupTitle = styled.div`
  display: flex;
  font-family: ${globals.fontName};
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
`;

export const UsageSubscriptionPopupContent = styled.div`
  font-family: ${globals.fontName};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
`;

export const ConfirmationPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 406;
  width: 406;
  margin: 16px 13px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between !important;

  .action-button {
    border: none;
    background: ${globals.primary} !important;
    color: white;
    width: unset !important;
    &:hover {
      background: #005e80 !important;
      color: white;
    }
  }

  .contact-button {
    width: unsert !important;
    background: ${globals.gray100} !important;
    border: 1px solid ${globals.gray400} !important;
  }
}
`;

export const ConfirmContactUsButton = styled.div`
  border: none;
  background: rgb(73, 119, 208);
  color: white;

  &:hover {
    background: darken(rgb(73, 119, 208), 10%);
    color: white;
  }

  &.current-plan {
    background: white;
    color: rgb(73, 119, 208);
    border: 1px solid rgb(73, 119, 208);
    cursor: default;
  }
`;

export const PricingTable = styled.div`
  margin-bottom: 32px;
`;
export const PricingTableRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const PricingTableHeader = styled.div`
  font-family: ${globals.fontName};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
  margin-top: 4px;
`;
export const PricingTableBody = styled.div`
  font-family: ${globals.fontName};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
  margin-top: 12px;
`;
export const PricingTableFeatureRow = styled.div`
  font-size: 14px;
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const ManageUsageLink = styled(Link)`
  display: flex;
  flex-direction: row;
  color: black;
  align-items: center;
  margin-bottom: 12px;
`;

export const ContactIcon = styled(WBIcon)`
  color: black;
  margin-right: 4px;
`;

export const PersonalUsageDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const TeamMemberLink = styled(Link)`
  color: ${globals.gray600};
`;
