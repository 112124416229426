/* eslint-disable no-template-curly-in-string */

import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'box-lite',
  displayName: 'Box plot (Vega lite)',
  description: 'A simple box plot implemented with Vega Lite',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
    description:
      'A vertical 2D box plot showing median, min, and max in the US population distribution of age groups in 2000.',
    data: {
      name: 'wandb',
    },
    mark: {
      type: 'boxplot',
      extent: 'min-max',
    },
    encoding: {
      x: {field: '${field:x}', type: 'quantitative'},
      y: {field: '${field:y}', type: 'nominal'},
      color: {field: '${field:y}', type: 'nominal'},
    },
  },
};

export default def;
