import {History} from 'history';
import _ from 'lodash';
import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import {Redirect} from 'react-router';
import {Link} from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import {ReportAdminBar} from '../components/ReportAdminBar';
import {ReportDiscussion} from '../components/ReportDiscussion';
import {ReportDiscussionContextProvider} from '../components/ReportDiscussionContext';
import ReportErrorBoundary from '../components/ReportErrorBoundary';
import NewUserFooter from '../components/NewUserFooter';
import ReportPageHeaderView from '../components/ReportPageHeaderView';
import WBSlateReduxBridge from '../components/Slate/WBSlateReduxBridge';
import Loader from '../components/WandbLoader';
import '../css/ReportPage.less';
import {useReportPageQuery} from '../state/graphql/reportPageQuery';
import {useDispatch, usePropsSelector, useSelector} from '../state/hooks';
import {PanelBankContextProvider} from '../state/panelbank/context';
import * as PollingActions from '../state/polling/actions';
import {useReport} from '../state/reports/hooks';
import * as ReportSelectors from '../state/reports/selectors';
import * as ReportTypes from '../state/reports/types';
import {ReportViewRef} from '../state/reports/types';
import {RunQueryContext} from '../state/runs/context';
import * as ViewerHooks from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {useAdminModeActive} from '../util/admin';
import {PUBLISHED_PROJECT_NAME} from '../util/constants';
import {
  maybeLengthenReportDescription,
  setDocumentDescription,
  setDocumentTitle,
} from '../util/document';
import {
  rssLinkTagFCReports,
  rssLinkTagPublicReports,
  useIsGalleryReport,
} from '../util/gallery';
import {navigateTo} from '../util/history';
import {useTrackMaxScrollDepth, useTrackTimeSpentOnPage} from '../util/hooks';
import makeComp from '../util/profiler';
import {isInIframe} from '../setup';
import * as Url from '../util/url';
import * as Urls from '../util/urls';
import * as S from './ReportPage.styles';
import {decodeURIComponentSafe} from '../util/url';

interface ReportPageViewPublishedWorkProps {
  match: {
    params: {
      entityName: string;
      reportNameAndID: string;
    };
  };
  history: History;
  location: Location;
}

export const ReportPageViewPublishedWork = makeComp(
  (props: ReportPageViewPublishedWorkProps) => {
    const {entityName, reportNameAndID} = props.match.params;
    let reportID = '';
    try {
      reportID = Url.parseNameAndID(reportNameAndID).id;
    } catch {
      return <NoMatch />;
    }
    return (
      <ReportPageViewInner
        entityName={entityName}
        projectName={PUBLISHED_PROJECT_NAME}
        reportID={reportID}
        history={props.history}
        projectReadOnly
        publishedWork
      />
    );
  },
  {id: 'ReportPageViewPublishedWork'}
);

interface ReportPageViewValidatorProps {
  match: {
    params: {
      entityName: string;
      projectName?: string;
      reportNameAndID: string;
    };
  };
  history: History;
  location: Location;
}

const ReportPageViewValidator: React.FC<ReportPageViewValidatorProps> =
  makeComp(
    props => {
      const {entityName, reportNameAndID} = props.match.params;
      // HAX: the project name URI we get from react-router might be encoded.
      // Decode this URI component before using it.
      // If this happens again on another URI component (e.g. entity name), we
      // should do more general fix at the react-router layer.
      const projectName =
        props.match.params.projectName != null
          ? decodeURIComponentSafe(props.match.params.projectName)
          : null;
      let reportID = '';
      try {
        reportID = Url.parseNameAndID(reportNameAndID).id;
      } catch {
        return (
          <Redirect
            to={
              projectName != null
                ? Urls.reportList({entityName, projectName}) + '#404'
                : Urls.reportGallery()
            }
          />
        );
      }
      return (
        <>
          <Helmet>
            {rssLinkTagFCReports}
            {rssLinkTagPublicReports}
          </Helmet>
          <ReportErrorBoundary params={props.match.params}>
            {projectName != null ? (
              <ReportPageView
                entityName={entityName}
                projectName={projectName}
                reportID={reportID}
                history={props.history}
              />
            ) : (
              <GalleryPostView
                entityName={entityName}
                reportID={reportID}
                history={props.history}
              />
            )}
          </ReportErrorBoundary>
        </>
      );
    },
    {id: 'ReportPageViewValidator'}
  );

interface ReportPageViewProps {
  entityName: string;
  projectName: string;
  reportID: string;
  history: History;
}

const ReportPageView: React.FC<ReportPageViewProps> = makeComp(
  props => {
    // Note(adrnswanberg): I've factored most of ReportPageView into ReportPageViewInner so
    // that we can split up useReportPageQuery and useReport into separate components.
    // Main reason being: if someone doesn't have access to a ReportPage, we don't want
    // to try to load the report spec because then stuff still start breaking.
    const reportQuery = useReportPageQuery({
      entityName: props.entityName,
      projectName: props.projectName,
    });

    const {isGalleryReport} = useIsGalleryReport(props.reportID);

    if (reportQuery.loading) {
      return <Loader />;
    }

    if (reportQuery.project == null) {
      return <NoMatch />;
    }

    return (
      <PanelBankContextProvider>
        <ReportPageViewInner
          entityName={props.entityName}
          projectName={props.projectName}
          reportID={props.reportID}
          history={props.history}
          projectReadOnly={reportQuery.project.readOnly}
          isFeatured={isGalleryReport}
        />
      </PanelBankContextProvider>
    );
  },
  {id: 'ReportPageView'}
);

type GalleryPostViewProps = {
  entityName: string;
  reportID: string;
  history: History;
};

const GalleryPostView: React.FC<GalleryPostViewProps> = makeComp(
  props => {
    return (
      <PanelBankContextProvider>
        <GalleryPostViewInner
          entityName={props.entityName}
          reportID={props.reportID}
          history={props.history}
        />
      </PanelBankContextProvider>
    );
  },
  {id: 'GalleryPostView'}
);

interface ReportPageViewInnerProps {
  entityName: string;
  projectName: string;
  history: History;
  reportID: string;
  projectReadOnly?: boolean;
  publishedWork?: boolean;
  isFeatured?: boolean;
}

const ReportPageViewInner: React.FC<ReportPageViewInnerProps> = makeComp(
  props => {
    const dispatch = useDispatch();
    useEffect(() => {
      // Don't poll on the report page for now
      dispatch(PollingActions.setBasePollInterval(0));
    }, [dispatch]);

    const admin = useAdminModeActive();
    const viewer = ViewerHooks.useViewer();

    const reportCID = useReport(props.reportID);
    const reportViewID = usePropsSelector(
      ReportSelectors.makeReportViewIDSelector,
      reportCID
    );

    if (reportViewID.loading) {
      return <Loader />;
    }

    return (
      <ReportPageViewReady
        viewRef={reportViewID.viewID}
        viewer={viewer}
        history={props.history}
        entityName={props.entityName}
        projectName={props.projectName}
        editAccess={
          viewer != null && !isInIframe() && (!props.projectReadOnly || admin)
        }
        publishedWork={props.publishedWork}
        reportID={props.reportID}
        isFeatured={props.isFeatured}
      />
    );
  },
  {id: 'ReportPageViewInner'}
);

interface GalleryPostViewInnerProps {
  entityName: string;
  history: History;
  reportID: string;
}

const GalleryPostViewInner: React.FC<GalleryPostViewInnerProps> = makeComp(
  props => {
    const dispatch = useDispatch();
    useEffect(() => {
      // Don't poll on the report page for now
      dispatch(PollingActions.setBasePollInterval(0));
    }, [dispatch]);

    const admin = useAdminModeActive();
    const viewer = ViewerHooks.useViewer();

    const reportCID = useReport(props.reportID);
    const reportViewID = usePropsSelector(
      ReportSelectors.makeReportViewIDSelector,
      reportCID
    );

    const view = useSelector(state =>
      reportViewID.loading ? null : state.views.views[reportViewID.viewID.id]
    );
    const editAccess = (viewer != null && viewer.id === view?.user.id) || admin;

    if (reportViewID.loading) {
      return <Loader />;
    }

    return (
      <ReportPageViewReady
        viewRef={reportViewID.viewID}
        viewer={viewer}
        history={props.history}
        entityName={props.entityName}
        projectName=""
        editAccess={editAccess}
        reportID={props.reportID}
        isFeatured
      />
    );
  },
  {id: 'GalleryPostViewInner'}
);

interface ReportPageViewReadyProps {
  viewRef: ReportTypes.ReportViewRef;
  viewer?: Viewer;
  history: History;
  entityName: string;
  projectName: string;
  editAccess?: boolean;
  publishedWork?: boolean;
  reportID: string;
  isFeatured?: boolean;
}

const ReportPageViewReady: React.FC<ReportPageViewReadyProps> = makeComp(
  props => {
    useTrackTimeSpentOnPage('report', props.reportID);
    const signalPageReady = useTrackMaxScrollDepth(
      'report',
      props.reportID,
      500
    );

    useEffect(() => {
      setTimeout(signalPageReady, 5000);
    }, [signalPageReady]);

    const reportWidth = useSelector(
      ReportSelectors.getReportWidth(props.viewRef)
    );

    const admin = useAdminModeActive();

    return (
      <div
        className={`report-page page-tabs read-only report-width-${reportWidth}`}>
        {!props.publishedWork && (
          <ReportPageViewUrl viewRef={props.viewRef} history={props.history} />
        )}
        <div className="report-page-top">
          {admin && <ReportAdminBar {...props} />}
          <S.ReportPageContainer isViewMode>
            <ReportPageHeaderView
              viewRef={props.viewRef}
              editAccess={props.editAccess}
            />
            <ReportDiscussionContextProvider
              reportViewRef={props.viewRef}
              reportServerID={props.reportID}>
              <RunQueryContext.Provider
                value={{
                  entityName: props.entityName,
                  projectName: props.projectName,
                  report: {id: props.reportID},
                }}>
                <WBSlateReduxBridge
                  readOnly={true}
                  entityName={props.entityName}
                  projectName={props.projectName}
                  viewId={props.reportID}
                  viewRef={props.viewRef}
                />
              </RunQueryContext.Provider>
              <ReportDiscussion />
            </ReportDiscussionContextProvider>
            {props.isFeatured && (
              <div className="report-gallery-text hide-on-print">
                Published with{' '}
                <span className="heart" role="img" aria-label="heart">
                  ❤️
                </span>{' '}
                on Weights &amp; Biases. Read more reports in our community,{' '}
                <Link to={Urls.reportGallery()}>Fully Connected</Link>.
              </div>
            )}
            <div className="show-on-print report-created-footer">
              <p>
                Created with{' '}
                <span className="heart" role="img" aria-label="heart">
                  ❤️
                </span>{' '}
                on Weights &amp; Biases.
              </p>
              {/* eslint-disable-next-line wandb/no-a-tags */}
              <a href={document.location.href}>{document.location.href}</a>
            </div>
          </S.ReportPageContainer>
        </div>
        <NewUserFooter whatFor="to create reports like this one." />
      </div>
    );
  },
  {id: 'ReportPageViewReady'}
);

interface ReportPageViewUrlProps {
  viewRef: ReportViewRef;
  history: History;
}

const ReportPageViewUrl = makeComp(
  (props: ReportPageViewUrlProps) => {
    // We do a deep comparison on this selector because if the view updates,
    // we end up getting a reference change on project. This page doesn't
    // actually update the view since you can't save here. But might as well
    // mirror the edit page behavior.
    const view = useSelector(state => {
      const {entityName, project, id, displayName, description, user} =
        state.views.views[props.viewRef.id];
      return {
        entityName,
        project,
        id,
        displayName,
        description,
        user,
      };
    }, _.isEqual);
    // Make sure url tracks report name
    useEffect(() => {
      if (view.id == null) {
        throw new Error('invalid state');
      }
      setDocumentTitle(view.displayName);
      setDocumentDescription(
        maybeLengthenReportDescription(view.description, view.user)
      );
      navigateTo({
        pathname:
          view.project != null
            ? Urls.reportView({
                entityName: view.project.entityName,
                projectName: view.project.name,
                reportID: view.id,
                reportName: view.displayName,
              })
            : (Url.isOnGalleryDiscussionView()
                ? Urls.galleryDiscussionView
                : Urls.galleryPostView)({
                entityName: view.entityName,
                reportID: view.id,
                reportName: view.displayName,
              }),
        preserveSearch: true,
        preserveHash: true,
        replace: true,
      });
    }, [
      props.history,
      view.displayName,
      view.id,
      view.entityName,
      view.project,
      view.description,
      view.user,
    ]);

    return null;
  },
  {id: 'ReportPageViewUrl'}
);

export default ReportPageViewValidator;
