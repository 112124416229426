import '../css/PanelGroupingOptions.less';

import * as React from 'react';

import {Checkbox} from 'semantic-ui-react';
import LabeledOption from './elements/LabeledOption';
import {RunsLinePlotConfig} from './PanelRunsLinePlot';
import {BarChartConfig, isPlotStyle} from './PanelBarChart';
import {
  ChartAggOption,
  isChartAggOption,
  isChartAreaOption,
} from '../util/plotHelpers';
import {ScalarChartConfig} from './PanelScalarChart';
import docUrl from '../util/doc_urls';
import ProjectFieldSelector from './ProjectFieldSelector';
import ModifiedDropdown from './elements/ModifiedDropdown';
import MultiButton from './elements/MultiButton';

import * as Query from '../util/queryts';
import makeComp from '../util/profiler';

type PanelConfig = BarChartConfig | RunsLinePlotConfig | ScalarChartConfig;
interface PanelGroupingOptions {
  type: 'lines' | 'bars' | 'scalar';
  pageQuery: Query.Query;
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  usingExpressions: boolean;
  isGrouped: boolean; // is grouping happening (could be happening from outside of panel)
  disabled?: boolean;
  totalRuns: number;
  defaultMaxGroupRuns: number;
  absoluteMaxGroupRuns: number;
  singleRun?: boolean;
}

const renderPlotType = (
  config: BarChartConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  usingExpressions: boolean
) => {
  const plotStyleKeys = ['bar', 'boxplot', 'violin'];
  const plotStyleLabels = ['Bar', 'Box Plot', 'Violin'];
  return (
    <LabeledOption
      label="Style"
      helpText="Presentation mode for grouped data"
      docUrl={docUrl.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={plotStyleKeys}
          labels={plotStyleLabels}
          selected={(config as BarChartConfig).plotStyle ?? 'bar'}
          disabled={usingExpressions}
          onChange={key => {
            if (isPlotStyle(key)) {
              updateConfig({
                plotStyle: key,
              });
            }
          }}
        />
      }
    />
  );
};

export const renderAggRange = (
  config: PanelConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  usingExpressions: boolean,
  includeSampleOption: boolean = true
) => {
  const chartAreaKeys = ['minmax', 'stddev', 'stderr', 'none', 'samples'];
  const chartAreaLabels = ['Min/Max', 'Std Dev', 'Std Err', 'None', 'Samples'];
  return (
    <LabeledOption
      label="Range"
      helpText="The aggregation calculation for the range that displays for a group."
      docUrl={docUrl.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={chartAreaKeys}
          labels={chartAreaLabels}
          selected={config.groupArea ?? 'minmax'}
          disabled={usingExpressions}
          onChange={key => {
            if (isChartAreaOption(key)) {
              updateConfig({
                groupArea: key,
              });
            }
          }}
        />
      }
    />
  );
};

export function renderAggType(
  config: PanelConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  defaultGroupAgg: ChartAggOption = 'mean',
  usingExpressions: boolean = false
) {
  const chartAggKeys = ['mean', 'median', 'min', 'max'];
  const chartAggLabels = ['Mean', 'Median', 'Min', 'Max'];

  return (
    <LabeledOption
      label="Agg"
      helpText="The aggregation calculation for the line that displays for a group."
      docUrl={docUrl.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={chartAggKeys}
          labels={chartAggLabels}
          selected={config.groupAgg ?? defaultGroupAgg}
          disabled={usingExpressions}
          onChange={key => {
            if (isChartAggOption(key)) {
              updateConfig({
                groupAgg: key,
              });
            }
          }}
        />
      }
    />
  );
}

export function multiRunAggOptions(props: PanelGroupingOptions) {
  // show user options to aggregate over runs
  const {config, updateConfig, pageQuery, disabled} = props;
  return (
    <>
      <LabeledOption
        label="Runs"
        helpText="Aggregate over runs"
        docUrl={docUrl.compareMetrics + '#grouping'}
        option={
          <>
            <Checkbox
              toggle
              disabled={disabled}
              checked={config.aggregate}
              name="aggregate"
              onChange={(e, value) =>
                updateConfig({
                  aggregate: value.checked,
                })
              }
            />
            {config.aggregate && (
              <>
                <span>Group by</span>
                <ProjectFieldSelector
                  className={'groupby-picker'}
                  disabled={!props.config.aggregate}
                  query={pageQuery}
                  selection
                  columns={['config']}
                  defaultKeys={['config:None']}
                  value={`config:${config.groupBy ?? 'None'}`}
                  multi={false}
                  searchByKeyAndText
                  setValue={value =>
                    updateConfig({
                      groupBy: value.split(':')[1],
                    })
                  }
                />
              </>
            )}
          </>
        }
      />
    </>
  );
}

export function singleRunAggOptions(props: PanelGroupingOptions) {
  // user will always aggregate over metrics
  // if that's not possible show warning
  const {config} = props;
  return (
    <>
      {(config.metrics == null || config.metrics.length <= 1) && (
        <>
          <p>Select multiple metrics to enable grouping</p>
        </>
      )}
    </>
  );
}

const PanelGroupingOptions = makeComp(
  (props: PanelGroupingOptions) => {
    const {
      config,
      updateConfig,
      singleRun,
      disabled,
      usingExpressions,
      isGrouped,
      totalRuns,
      defaultMaxGroupRuns,
      absoluteMaxGroupRuns,
    } = props;

    const showAggregationOptions =
      (props.type === 'bars' &&
        (config as BarChartConfig).plotStyle !== 'boxplot' &&
        (config as BarChartConfig).plotStyle !== 'violin') ||
      props.type === 'lines';

    const disableAggregationOptions =
      props.type === 'lines' && usingExpressions;

    return (
      <>
        {singleRun ? singleRunAggOptions(props) : multiRunAggOptions(props)}
        {(config.aggregateMetrics === true ||
          ((props.type === 'bars' || props.type === 'lines') &&
            config.metrics != null &&
            config.metrics.length > 1)) && (
          <LabeledOption
            label="Metrics"
            helpText="Aggregate over all metrics"
            option={
              <Checkbox
                toggle
                disabled={disabled}
                checked={config.aggregateMetrics}
                name="aggregate"
                onChange={(e, value) =>
                  updateConfig({
                    aggregateMetrics: value.checked,
                  })
                }
              />
            }
          />
        )}
        {isGrouped && (
          <>
            <div className="group-options">
              <div className="group-option">
                {usingExpressions && (
                  <div className="hint-text">
                    <p>
                      Grouping functions are disabled while using expressions.
                      In order to reenable, remove all expressions.
                    </p>
                  </div>
                )}
                {props.type === 'bars' &&
                  renderPlotType(
                    config as BarChartConfig,
                    updateConfig,
                    usingExpressions
                  )}
                {showAggregationOptions &&
                  renderAggType(
                    config,
                    updateConfig,
                    'mean',
                    disableAggregationOptions
                  )}
                {showAggregationOptions &&
                  renderAggRange(
                    config,
                    updateConfig,
                    disableAggregationOptions,
                    props.type === 'lines'
                  )}
              </div>
            </div>
          </>
        )}
        {isGrouped && totalRuns >= defaultMaxGroupRuns && (
          <LabeledOption
            label="Sampled Runs"
            helpText="Number of runs to sample from for grouping (large numbers may impact performance)."
            docUrl={docUrl.compareMetrics + '#grouping'}
            option={
              <ModifiedDropdown
                lazyLoad
                className="no-wrap-options"
                value={config.groupRunsLimit || defaultMaxGroupRuns}
                style={{minWidth: 60}}
                selection
                options={[
                  defaultMaxGroupRuns,
                  // limit the absolute maximum number of runs to group so we don't hit performance issues
                  Math.min(totalRuns, absoluteMaxGroupRuns),
                ]
                  .sort()
                  .map(n => ({
                    value: n,
                    text: `${n}`,
                  }))}
                onChange={(e, {value}) => {
                  if (typeof value === 'number') {
                    updateConfig({
                      groupRunsLimit: value,
                    });
                  }
                }}
              />
            }
          />
        )}
      </>
    );
  },
  {id: 'PanelGroupingOptions'}
);

export default PanelGroupingOptions;
