import styled from 'styled-components';

import * as globals from '../../css/globals.styles';
import {Popup} from 'semantic-ui-react';

export const DownloadMenu = styled(Popup)`
  &&&&& {
    border-radius: 0.25rem;
    font-size: 16px;
    margin-top: 0.625em;
    padding: 0;

    div {
      cursor: pointer;
      padding: 12px 20px;

      &:hover {
        background: ${globals.gray100};
      }
    }
  }
`;
