import React, {useEffect, useState} from 'react';
import makeComp from '../util/profiler';
import EditableField from '../components/EditableField';
import {RunGroup, Tag, useUpsertRunGroupMutation} from '../generated/graphql';
import {Tags} from '../components/Tags';
import {TagAddButton} from '../components/TagAddButton';
import * as Urls from '../util/urls';

type RunGroupOverviewProps = {
  entityName: string;
  projectName: string;
  group: Pick<RunGroup, 'id' | 'name' | 'notes' | 'tags'>;
  readOnly?: boolean;
  availableTags: Tag[];
  history: any;
};

export const RunGroupOverview = makeComp<RunGroupOverviewProps>(
  ({group, entityName, projectName, readOnly, history, availableTags}) => {
    const {name} = group;
    const tags = group.tags.map(t => t.name);
    const [notes, setNotes] = useState<string>(group.notes ?? '');
    useEffect(() => {
      if (group.notes) {
        setNotes(group.notes);
      }
    }, [group.notes]);

    const [updateGroupMutation] = useUpsertRunGroupMutation();
    const updateGroup = (properties: {
      notes?: string;
      tags?: string[];
      newName?: string;
    }) => {
      return updateGroupMutation({
        variables: {
          ...group,
          notes,
          tags,
          entityName,
          projectName,
          ...properties,
        },
      });
    };

    return (
      <div className="run-overview">
        <div className="run-overview-top">
          <div className="mini-page-header">
            <div className="name-header-wrapper">
              <EditableField
                className="name-header"
                readOnly={readOnly}
                asHeader="h1"
                placeholder={name}
                showEditIcon
                value={name}
                save={async newName => {
                  await updateGroup({newName});

                  setTimeout(() => {
                    history.push(
                      Urls.runGroup({
                        entityName,
                        projectName,
                        name: newName,
                      })
                    );
                  }, 1000);
                }}
              />
            </div>
            <EditableField
              className="description"
              readOnly={readOnly}
              multiline
              showEditIcon
              renderLinks
              placeholder={'What makes this group special?'}
              value={notes}
              save={value => {
                setNotes(value);
                updateGroup({notes: value});
              }}
            />
          </div>
          <div className="overview-item">
            <div className="overview-key">Tags</div>
            <div className="overview-value">
              <div className="tags-button-wrapper">
                <Tags
                  tags={group.tags}
                  enableDelete={!readOnly}
                  deleteTag={tag => {
                    if (!tags.includes(tag.name)) {
                      return;
                    }

                    updateGroup({
                      tags: tags.filter(t => t !== tag.name),
                    });
                  }}
                />
                {!readOnly && (
                  <TagAddButton
                    compact
                    direction="bottom right"
                    tags={group.tags}
                    availableTags={availableTags}
                    addTag={async tag => {
                      if (tags.includes(tag.name)) {
                        return;
                      }

                      updateGroupMutation({
                        variables: {
                          ...group,
                          notes,
                          entityName,
                          projectName,
                          tags: [...tags, tag.name],
                        },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
  {id: 'RunGroupOverview'}
);
