import {ID} from '@wandb/cg/browser/utils/string';
import * as CustomRunColorsNormalize from './customRunColors/normalize';
import * as FilterNormalize from './filter/normalize';
import * as GroupPageNormalize from './groupPage/normalize';
import * as GroupSelectionsNormalize from './groupSelections/normalize';
import * as PanelsNormalize from './panels/normalize';
import * as PanelBankConfigNormalize from './panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from './panelBankSectionConfig/normalize';
import * as PanelNormalize from './panel/normalize';
import * as PanelSettingsNormalize from './panelSettings/normalize';
import * as ProjectPageNormalize from './projectPage/normalize';
import * as ReportNormalize from './report/normalize';
import * as ReportDraftNormalize from './reportDraft/normalize';
import * as RunPageNormalize from './runPage/normalize';
import * as RunSetNormalize from './runSet/normalize';
import * as SectionNormalize from './section/normalize';
import * as MarkdownBlockNormalize from './markdownBlock/normalize';
import * as SortNormalize from './sort/normalize';
import * as SweepPageNormalize from './sweepPage/normalize';
import * as TempSelectionsNormalize from './tempSelections/normalize';
import * as InteractStateNormalize from './interactState/normalize';
import * as DiscussionThreadNormalize from './discussionThread/normalize';
import * as DiscussionCommentNormalize from './discussionComment/normalize';

import * as Types from './types';

export type StateType = {
  [T in Types.ObjType]: {
    [id: string]: Types.PartFromType<T>;
  };
};

interface NormContext {
  viewID: string;
  result: Array<PartWithRef<Types.ObjType>>;
}

export type NormFn<S extends Types.ObjSchema> = (
  whole: S['whole'],
  ctx: NormContext
) => S['part'];

export type FullNormFn<S extends Types.ObjSchema> = (
  whole: S['whole'],
  ctx: NormContext
) => Types.PartRefFromType<S['type']>;

export function normFn<S extends Types.ObjSchema>(
  type: S['type'],
  userNormFn: NormFn<S>
): FullNormFn<S> {
  return (whole, ctx) => {
    const part = userNormFn(whole, ctx);

    const ref = {
      type,
      viewID: ctx.viewID,
      id: ID(),
    } as Types.PartRefFromType<S['type']>;
    ctx.result.push({ref, part});

    return ref;
  };
}

interface PartWithRef<T extends Types.ObjType> {
  ref: Types.PartRefFromType<T>;
  part: Types.PartFromType<T>;
}

type NormFunctionMap = {
  [T in Types.ObjType]: FullNormFn<Types.ObjSchemaFromType<T>>;
};

const normFunctions: NormFunctionMap = {
  'project-view': ProjectPageNormalize.normalize,
  'group-view': GroupPageNormalize.normalize,
  'sweep-view': SweepPageNormalize.normalize,
  'run-view': RunPageNormalize.normalize,
  runs: ReportNormalize.normalize,
  'runs/draft': ReportDraftNormalize.normalize,
  runSet: RunSetNormalize.normalize,
  section: SectionNormalize.normalize,
  'markdown-block': MarkdownBlockNormalize.normalize,
  panels: PanelsNormalize.normalize,
  panel: PanelNormalize.normalize,
  panelSettings: PanelSettingsNormalize.normalize,
  sort: SortNormalize.normalize,
  filters: FilterNormalize.normalize,
  'group-selections': GroupSelectionsNormalize.normalize,
  'run-colors': CustomRunColorsNormalize.normalize,
  'temp-selections': TempSelectionsNormalize.normalize,
  interactState: InteractStateNormalize.normalize,
  'panel-bank-config': PanelBankConfigNormalize.normalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.normalize,
  'discussion-thread': DiscussionThreadNormalize.normalize,
  'discussion-comment': DiscussionCommentNormalize.normalize,
};

export function normalize<T extends Types.ObjType>(
  type: T,
  viewID: string,
  whole: Types.WholeFromType<T>
): {
  partRef: Types.PartRefFromType<T>;
  partsWithRefs: Array<PartWithRef<Types.ObjType>>;
} {
  const partsWithRefs: Array<PartWithRef<Types.ObjType>> = [];
  const partNormFn = normFunctions[type] as any as FullNormFn<
    Types.ObjSchemaFromType<T>
  >;
  const partRef = partNormFn(whole, {viewID, result: partsWithRefs});
  return {partRef, partsWithRefs};
}

export function addObj<T extends Types.ObjType>(
  partsState: StateType,
  type: T,
  viewID: string,
  whole: Types.WholeFromType<T>,
  opts?: {
    // Indicates that whole is an object that is not known to immer.
    // We can freeze it as a performance optimization, which tells immer
    // not to walk it to see if something changed
    wholeIsDefinitelyNew?: boolean;
  }
): Types.PartRefFromType<T> {
  const {wholeIsDefinitelyNew} = opts ?? {};
  const {partRef, partsWithRefs} = normalize(type, viewID, whole);
  for (const {ref, part} of partsWithRefs) {
    partsState[ref.type][ref.id] = wholeIsDefinitelyNew
      ? Object.freeze(part)
      : part;
  }
  return partRef;
}

export function lookupPart<R extends Types.AllPartRefs>(
  state: StateType,
  partRef: R
): Types.PartFromType<R['type']> {
  const partsOfType = state[partRef.type];
  if (partsOfType == null) {
    throw new Error('invalid state');
  }
  const partNorm = partsOfType[partRef.id];
  if (partNorm == null) {
    throw new Error('invalid state');
  }
  return partNorm as Types.PartFromType<R['type']>;
}

export function partExists<R extends Types.AllPartRefs>(
  state: StateType,
  partRef: R
): boolean {
  const partsOfType = state[partRef.type];
  if (partsOfType == null) {
    throw new Error('invalid state');
  }
  return partsOfType[partRef.id] != null;
}

interface DenormContext {
  state: StateType;
  partsWithRef: Array<PartWithRef<Types.ObjType>>;
}

export type DenormFn<S extends Types.ObjSchema> = (
  part: S['part'],
  ctx: DenormContext
) => Types.WholeFromType<S['type']>;

export type FullDenormFn<S extends Types.ObjSchema> = (
  ref: Types.PartRefFromType<S['type']>,
  ctx: DenormContext
) => Types.WholeFromTypeWithRef<S['type']>;

export function denormFn<S extends Types.ObjSchema>(
  userDenormFn: DenormFn<S>
): FullDenormFn<S> {
  return (ref, ctx) => {
    const part = lookupPart(ctx.state, ref);
    ctx.partsWithRef.push({part, ref});
    const whole = userDenormFn(part, ctx);
    return {
      ...whole,
      ref,
    } as any;
  };
}

type DenormFunctionMap = {
  [T in Types.ObjType]: FullDenormFn<Types.ObjSchemaFromType<T>>;
};

const denormFunctions: DenormFunctionMap = {
  'project-view': ProjectPageNormalize.denormalize,
  'group-view': GroupPageNormalize.denormalize,
  'sweep-view': SweepPageNormalize.denormalize,
  'run-view': RunPageNormalize.denormalize,
  runs: ReportNormalize.denormalize,
  'runs/draft': ReportDraftNormalize.denormalize,
  'markdown-block': MarkdownBlockNormalize.denormalize,
  runSet: RunSetNormalize.denormalize,
  section: SectionNormalize.denormalize,
  panels: PanelsNormalize.denormalize,
  panel: PanelNormalize.denormalize,
  panelSettings: PanelSettingsNormalize.denormalize,
  sort: SortNormalize.denormalize,
  filters: FilterNormalize.denormalize,
  'group-selections': GroupSelectionsNormalize.denormalize,
  'run-colors': CustomRunColorsNormalize.denormalize,
  'temp-selections': TempSelectionsNormalize.denormalize,
  interactState: InteractStateNormalize.denormalize,
  'panel-bank-config': PanelBankConfigNormalize.denormalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.denormalize,
  'discussion-thread': DiscussionThreadNormalize.denormalize,
  'discussion-comment': DiscussionCommentNormalize.denormalize,
};

export function denormalizeWithParts<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>
): {
  whole: Types.WholeFromTypeWithRef<T>;
  partsWithRef: Array<PartWithRef<Types.ObjType>>;
} {
  const partsWithRef: Array<PartWithRef<Types.ObjType>> = [];
  const wholeDenormFn = denormFunctions[ref.type] as any as FullDenormFn<
    Types.ObjSchemaFromType<T>
  >;
  const whole = wholeDenormFn(ref as any, {state, partsWithRef});
  return {whole, partsWithRef} as any;
}

export function denormalize<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>
): Types.WholeFromTypeWithRef<T> {
  return denormalizeWithParts(state, ref).whole;
}
