import * as React from 'react';
import {useMemo, useState} from 'react';

import {fuzzyMatch} from '../../../util/fuzzyMatch';
import * as RunFeed from '../../../util/runfeed';
import TableEditor from '../../TableEditor';
import makeComp from '../../../util/profiler';

interface DFTableColumnEditorProps {
  config: RunFeed.Config;
  allColumns: string[];
  fixedColumns?: string[];
  objectType?: string; // Just a string used in the UI. defaults to Columns, but is Parameters in the parameter importance panel

  update(config: RunFeed.Config): void;
}

const DFTableColumnEditor = makeComp(
  (props: DFTableColumnEditorProps) => {
    const [search, setSearch] = useState('');

    const allColumns = useMemo(
      () => fuzzyMatch(props.allColumns, search),
      [search, props.allColumns]
    );

    return (
      <TableEditor
        config={props.config}
        allColumns={allColumns}
        fixedColumns={props.fixedColumns}
        searchQuery={search}
        setSearch={setSearch}
        update={props.update}
        objectType={props.objectType}
      />
    );
  },
  {id: 'DFTableColumnEditor'}
);

export default DFTableColumnEditor;
