import React, {useCallback, useState} from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import {useLoadFile} from '../util/requests';
import {RunWithRunsetInfo} from '../containers/RunsDataLoader';
import WandbLoader from './WandbLoader';
import makeComp from '../util/profiler';

interface CodeDiffProps {
  runA?: RunWithRunsetInfo;
  runB?: RunWithRunsetInfo;
  entityName: string;
  projectName: string;
  split: boolean;
  setError: (err: string | undefined) => void;
}

const CodeDiff: React.FC<CodeDiffProps> = makeComp(
  props => {
    const {runA, runB, entityName, projectName, split} = props;
    const [codeA, setCodeA] = useState('');
    const [codeB, setCodeB] = useState('');
    const codePathA = codePath(runA!._wandb);
    const codePathB = codePath(runB!._wandb);
    function normalize(code: string, path: string) {
      // This extracts the source from notebooks for sane diffs
      if (path.endsWith('ipynb')) {
        try {
          const parsed = JSON.parse(code);
          let normalized = '';
          parsed.cells.forEach((cell: any) => {
            normalized += cell.source.join('') + '\n';
          });
          return normalized;
        } catch (e) {
          console.error('Failed to parse code: ', e);
          return code;
        }
      } else {
        return code;
      }
    }
    const setCodeACallback = useCallback(
      (code: string) => setCodeA(normalize(code, codePathA)),
      [setCodeA, codePathA]
    );
    const setCodeBCallback = useCallback(
      (code: string) => setCodeB(normalize(code, codePathB)),
      [setCodeB, codePathB]
    );
    const setErrorCallback = useCallback(
      () => props.setError('Problem loading code'),
      [props]
    );

    function codePath(wandb: any) {
      return (wandb.code_path || wandb.session_history) as string;
    }

    const loadingA = useLoadFile(
      {entityName, projectName, runName: runA!.name},
      codePathA,
      {
        onSuccess: setCodeACallback,
        onFailure: setErrorCallback,
        fallback: setErrorCallback,
      }
    );
    const loadingB = useLoadFile(
      {entityName, projectName, runName: runB!.name},
      codePathB,
      {
        onSuccess: setCodeBCallback,
        onFailure: setErrorCallback,
        fallback: setErrorCallback,
      }
    );

    if (loadingA || loadingB) {
      return <WandbLoader />;
    }

    const highlightSyntax = (str: string) => (
      <pre
        style={{display: 'inline'}}
        dangerouslySetInnerHTML={{
          __html: window.Prism.highlight(
            str || '',
            window.Prism.languages.python
          ),
        }}
      />
    );

    return (
      <ReactDiffViewer
        oldValue={codeA}
        newValue={codeB}
        splitView={split}
        showDiffOnly={codeA !== codeB}
        renderContent={highlightSyntax}
      />
    );
  },
  {id: 'CodeDiff'}
);

export default CodeDiff;
