import '../css/OnboardingFlow.less';

import React, {useEffect} from 'react';
import {Button, Form, Input, Message} from 'semantic-ui-react';

import OnboardingBackground from '../assets/onboarding/signup-colorful-bkg.svg';
import {doAuthRedirect} from '../util/auth';
import {getViewerClaims} from '../state/viewer/selectors';
import {useViewer} from '../state/viewer/hooks';
import {useSelector} from '../state/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import makeComp from '../util/profiler';
import {auth} from '../setup';

export const MINIMUM_PASSWORD_LENGTH = 8;
const ChangePassword: React.FC = makeComp(
  () => {
    const history = useHistory();
    const location = useLocation();

    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState<string | null>(null);
    const viewer = useViewer();
    const viewerClaims = useSelector(getViewerClaims);
    const email = viewerClaims?.originalEmail;

    const passwordTooShort = password.length < MINIMUM_PASSWORD_LENGTH;

    // this page is only usable in local auth, and only when a JWT is available
    useEffect(() => {
      if (!email) {
        console.error(
          'tried to load password reset page with invalid auth or no JWT; redirecting to login'
        );
        history.replace({
          ...location,
          pathname: 'login',
        });
      }
    }, [history, location, email]);

    // TODO: the viewer might be stale, we should likely call reloadCurrentViewer()
    // when this component is mounted.
    useEffect(() => {
      if (viewer && viewer.email !== viewerClaims?.originalEmail) {
        console.error(
          'tried to load password reset page for nonexistent user; redirecting to signup'
        );

        history.replace({
          ...location,
          pathname: 'signup',
          state: {from: '/change-password', internal: true},
        });
      }
    }, [history, location, viewer, viewerClaims]);

    const submit = async () => {
      if (passwordTooShort) {
        return;
      }

      setError(null);

      if (!email) {
        setError('Error while changing password.');
        throw new Error(
          'Somehow submitted password reset in invalid state: this page should not load at all in these conditions'
        );
      }

      try {
        await auth.changePassword(password, email);
        doAuthRedirect();
      } catch (e) {
        console.error('Error changing password: ', e);
        setError('Error while changing password');
      }
    };

    return (
      <div
        className="onboarding-flow"
        style={{backgroundImage: `url(${OnboardingBackground})`}}>
        <div className="onboarding-dialogue">
          <h2>Change Password</h2>
          <Form onSubmit={submit}>
            <p>Enter a new password for the account associated with {email}</p>
            <Message error visible={!!error}>
              {error}
            </Message>
            <Form.Field
              control={Input}
              type="password"
              autoComplete="new-password"
              data-test="new-password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              error={
                password !== '' &&
                passwordTooShort &&
                `Password of at least ${MINIMUM_PASSWORD_LENGTH} characters required`
              }
              placeholder="Password"
            />
            <Button type="submit" style={{display: 'none'}}>
              Change Password
            </Button>
          </Form>
          <div className="actions">
            <Button
              data-test="submit"
              primary
              onClick={submit}
              disabled={passwordTooShort}>
              Change Password
            </Button>
          </div>
        </div>
      </div>
    );
  },
  {id: 'ChangePassword'}
);

export default ChangePassword;
