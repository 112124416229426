import * as queryString from 'query-string';
import React, {useEffect} from 'react';
import {auth} from '../setup';
import makeComp from '../util/profiler';
import Loader from './WandbLoader';

const LoginRedirect: React.FC = makeComp(
  () => {
    useEffect(() => {
      const qs = queryString.parse(window.location.search);
      if (!qs.redirectTo || typeof qs.redirectTo !== 'string') {
        return;
      }

      if (auth.loggedIn()) {
        window.location.href = qs.redirectTo; // eslint-disable-line wandb/no-unprefixed-urls
        return;
      }

      auth.login();
    }, []);
    return <Loader />;
  },
  {id: 'LoginRedirect'}
);

export default LoginRedirect;
