import React from 'react';
import {useState} from 'react';
import AddVisMenu from './AddVisMenu';
import {AddVisNewPanelEditor} from './AddVisNewPanelEditor';

import * as CustomRunColorsTypes from '../state/views/customRunColors/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as PanelViewTypes from '../state/views/panel/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelBankSectionConfigActions from '../state/views/panelBankSectionConfig/actions';
import * as ViewHooks from '../state/views/hooks';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {WBPopupTrigger} from '@wandb/ui';
import {Ref} from 'semantic-ui-react';
import makeComp from '../util/profiler';
import {toast} from './elements/Toast';
import {SectionPanelSorting} from '../util/panelbank';

interface AddVisButtonProps {
  entityName: string;
  singleRun?: boolean;
  runSetRefs: RunSetViewTypes.Ref[];

  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  panelSettingsRef?: PanelSettingsViewTypes.Ref;
  customRunColorsRef: CustomRunColorsTypes.Ref;
}

const PanelBankAddVisButton = makeComp(
  (props: AddVisButtonProps) => {
    const [newPanel, setNewPanel] = useState<PanelViewTypes.Panel>();
    const [noEdit, setNoEdit] = useState<boolean | undefined>();

    const addPanel = ViewHooks.useViewAction(
      props.panelBankSectionConfigRef,
      PanelBankSectionConfigActions.addPanel
    );

    const {sorted} = ViewHooks.usePart(props.panelBankSectionConfigRef);

    const panelSettings =
      ViewHooks.usePartMaybe(props.panelSettingsRef) ?? undefined;

    return (
      <>
        {newPanel && (
          <AddVisNewPanelEditor
            panelBankSectionConfigRef={props.panelBankSectionConfigRef}
            currentHeight={400}
            editable={true}
            customRunColorsRef={props.customRunColorsRef}
            runSetRefs={props.runSetRefs}
            panelSettings={panelSettings}
            panel={newPanel}
            noEdit={noEdit}
            onCancel={() => {
              setNewPanel(undefined);
            }}
            onOK={panelRef => {
              setNewPanel(undefined);
              addPanel(panelRef);
              if (sorted === SectionPanelSorting.Alphabetical) {
                toast('Panel added alphabetically to section');
              }
            }}
          />
        )}
        <WBPopupTrigger
          triangleColor="white"
          direction="bottom left"
          onParentScroll="disable"
          popupContent={({close}) => (
            <AddVisMenu
              entityName={props.entityName}
              singleRun={props.singleRun}
              runSetRefs={props.runSetRefs}
              onSelect={(panel, skipEdit) => {
                close();
                setNewPanel(panel);
                setNoEdit(skipEdit);
              }}
            />
          )}>
          {({anchorRef, setOpen}) => (
            <Ref innerRef={anchorRef}>
              <LegacyWBIcon
                name="plus"
                data-test="add-vis"
                className="add-vis-button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOpen(open => !open);
                }}
              />
            </Ref>
          )}
        </WBPopupTrigger>
      </>
    );
  },
  {id: 'PanelBankAddVisButton'}
);

export default PanelBankAddVisButton;
