import styled, {css} from 'styled-components';
import {WBIcon} from '@wandb/ui';

export const PopupWrapper = styled.div`
  background: white;
  padding: 12px;
  width: 360px;
  min-height: 64px;
`;

export const HelperText = styled.p`
  margin-top: 4px !important;
  color: #999;
`;

export const IndicatorIcon = styled(WBIcon)<{
  open?: boolean;
  readOnly?: boolean;
}>`
  ${props =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
  color: ${props => (props.open ? '#555' : '#999')};
  ${props =>
    !props.readOnly &&
    css`
      cursor: pointer;
      &:hover {
        color: #555;
      }
    `}
`;

export const AdminStripe = styled.div`
  margin-top: 8px;
  padding: 8px 12px;
  margin-left: -12px;
  background: rgb(244, 238, 253);
  width: calc(100% + 24px);
`;
