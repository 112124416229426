import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Loader, Popup} from 'semantic-ui-react';

import {useRunsData} from '../../state/runs/hooks';
import {toRunsDataQuery} from '../../state/runs/lib';
import * as Query from '../../util/queryts';
import * as RunFeed from '../../util/runfeed';
import * as TableCols from '../../util/tablecols';
import {toast} from '../elements/Toast';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {EMPTY_CONFIG} from '../../util/runfeed';
import {WBTableColumn} from '../WBTable/WBTable';
import makeComp from '../../util/profiler';

interface RunsAutoColsBaseProps {
  onClick(): void;
}

const RunsAutoColsBaseButton = makeComp(
  (props: RunsAutoColsBaseProps) => {
    const {onClick, ...passThrough} = props;

    return (
      <Button
        size="tiny"
        className="wb-icon-button only-icon"
        onClick={onClick}
        {...passThrough}>
        <LegacyWBIcon name="wandb" />
      </Button>
    );
  },
  {id: 'RunsAutoColsBaseButton'}
);

const RunsAutoColsLoadingButton = makeComp(
  (props: RunsAutoColsProps) => {
    const {
      query,
      tableSettings,
      setTableSettings,
      currentPage,
      columns,
      ...passThrough
    } = props;

    const runsQuery = toRunsDataQuery(query, undefined, {
      fullConfig: true,
      fullSummary: true,
      configKeys: undefined,
      summaryKeys: undefined,
      wandbKeys: undefined,
      page: {size: 500},
    });
    const {loading, data} = useRunsData(runsQuery);
    const once = useRef(false);

    useEffect(() => {
      if (!loading && tableSettings != null && !once.current) {
        once.current = true;
        const pageSize = tableSettings.pageSize ?? EMPTY_CONFIG.pageSize;
        const i = pageSize * (currentPage - 1);
        const rows = data.filtered.slice(i, i + pageSize);
        setTableSettings(
          TableCols.deriveAutoColumns(tableSettings, rows, columns)
        );
      }
    }, [loading, data, setTableSettings, tableSettings, currentPage, columns]);

    return (
      <Button size="tiny" {...passThrough}>
        <Loader size="tiny" active inline />
      </Button>
    );
  },
  {id: 'RunsAutoColsLoadingButton'}
);

interface RunsAutoColsProps {
  query: Query.Query;
  tableSettings?: RunFeed.Config;
  currentPage: number;
  columns: WBTableColumn[];

  setTableSettings(config: RunFeed.Config): void;
}

export const RunsAutoCols = makeComp(
  (props: RunsAutoColsProps) => {
    const {query, tableSettings, setTableSettings, currentPage, columns} =
      props;
    const [loading, setLoading] = useState(false);

    const setTableSettingsWrapped = useCallback(
      (config: RunFeed.Config) => {
        setTableSettings(config);
        setLoading(false);
        toast('Now showing recommended columns.');
      },
      [setTableSettings, setLoading]
    );

    const startLoad = useCallback(() => setLoading(true), [setLoading]);

    return (
      <Popup
        content={'Automatically show the most useful columns.'}
        position="top right"
        style={{marginRight: -6, top: 4}}
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        trigger={
          loading ? (
            <RunsAutoColsLoadingButton
              query={query}
              tableSettings={tableSettings}
              setTableSettings={setTableSettingsWrapped}
              currentPage={currentPage}
              columns={columns}
            />
          ) : (
            <RunsAutoColsBaseButton onClick={startLoad} />
          )
        }
      />
    );
  },
  {id: 'RunsAutoCols'}
);
