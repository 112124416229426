import * as React from 'react';

import PanelSettingsComponentControlled, {
  LinePlotPanel,
} from '../components/PanelSettings';
import * as ReportViewSelectors from '../state/reports/selectors';
import * as ReportViewTypes from '../state/reports/types';
import * as PanelRunsLinePlot from './PanelRunsLinePlot';
import {useSelector} from '../state/hooks';
import {useWhole} from '../state/views/hooks';
import makeComp from '../util/profiler';
import {isPanelGrid, PanelGrid} from './Slate/plugins/panel-grids';

interface ReportPanelSettingsProps {
  reportViewRef: ReportViewTypes.ReportViewRef;
}

const ReportPanelSettings = makeComp(
  (props: ReportPanelSettingsProps) => {
    const panelSettingsRef = useSelector(
      ReportViewSelectors.getReportPanelSettingsRef(props.reportViewRef)
    );

    const reportRef = useSelector(
      ReportViewSelectors.getReportRef(props.reportViewRef)
    );

    const [settingsPopupOpen, setSettingsPopupOpen] = React.useState(false);

    /**
     * Absolute garbage code.
     * I did this because refs in the Slate document are stale on page load
     * because refs change each reload. It is obscenely brittle.
     */
    const useAllLinePlotPanels = () => {
      const {blocks} = useWhole(reportRef);
      const panelGrids = blocks.filter(b => isPanelGrid(b)) as PanelGrid[];
      const linePlotIds = new Set<string>();
      panelGrids.forEach(pg => {
        pg.metadata.panelBankSectionConfig.panels.forEach(p => {
          if (p.viewType === PanelRunsLinePlot.PANEL_TYPE) {
            linePlotIds.add(p.__id__);
          }
        });
      });
      const linePlotPanels = useSelector(s => {
        const entries = Object.entries(s.views.parts.panel).filter(([k, v]) =>
          linePlotIds.has(v.__id__)
        );
        const panels = entries.map(([k, v]) => {
          const p = {...v, ref: {...(v as any).ref, id: k}};
          return p;
        });
        return panels;
      });
      return linePlotPanels as unknown as LinePlotPanel[];
    };

    return (
      <PanelSettingsComponentControlled
        popupPosition="bottom left"
        workspacePanelSettingsRef={panelSettingsRef}
        useAllLinePlotPanels={useAllLinePlotPanels}
        settingsPopupOpen={settingsPopupOpen}
        setSettingsPopupOpen={setSettingsPopupOpen}
        reportSettings
      />
    );
  },
  {id: 'ReportPanelSettings'}
);

export default ReportPanelSettings;
