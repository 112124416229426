import * as S from './CustomPanelRenderer.styles';

import ReactDOM from 'react-dom';
import React from 'react';
import {useRef} from 'react';
import * as _ from 'lodash';
import * as VegaLib2 from '../../util/vega2';
import * as VegaLib3 from '../../util/vega3';
import * as QueryResult from '../../state/queryGraph/queryResult';
import {transforms, View as VegaView} from 'vega';
import {Vega, VisualizationSpec} from 'react-vega';
import Measure, {BoundingRect} from 'react-measure';
import PanelError from '../elements/PanelError';
import WandbLoader from '../WandbLoader';
import {RunColorConfig} from '../../util/section';

import Rasterize from './Rasterize';
import makeComp from '../../util/profiler';

import {PanelExportRef} from '../../components/PanelImageExportModal';

import {WBSelect} from '@wandb/ui';

import SliderInput from '../elements/SliderInput';
import {VegaPanel2Config} from '../PanelVega2';

transforms.rasterize = Rasterize as any;

const INCOMPLETE_QUERY_MESSAGE =
  'Expression parse error: please check field settings';

function specHasWandbData(spec: VisualizationSpec) {
  if (Array.isArray(spec.data)) {
    for (const table of spec.data) {
      if (table.name === 'wandb') {
        return true;
      }
    }
  } else {
    if (typeof spec.data === 'object') {
      if (spec.data?.name === 'wandb') {
        return true;
      }
    }
  }
  return false;
}

interface CustomPanelRendererProps {
  spec: VisualizationSpec;
  err?: string | null;
  loading: boolean;
  slow: boolean;
  data: QueryResult.Row[];
  userSettings: VegaLib2.UserSettings;
  customRunColors?: RunColorConfig;
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  /**
   * For forcing reruns
   */
  innerKey?: string | number;
  /**
   * For the run selector
   */
  showRunSelector?: boolean;
  viewableRunOptions?: string[];
  viewedRun?: string;
  /**
   * for the step selector
   */
  showStepSelector?: boolean;
  viewedStep?: number;
  viewableStepOptions?: number[];
  panelConfig?: VegaPanel2Config;

  setViewedRun?(runName: string): void;
  setUserQuery?(query: VegaLib3.Query): void;
  setView?(view: VegaView | null): void;
  handleTooltip?(handler: any, event: any, item: any, value: any): void;
}

const CustomPanelRenderer: React.FC<CustomPanelRendererProps> = makeComp(
  props => {
    const {
      spec,
      err,
      loading,
      slow,
      data,
      userSettings,
      panelExportRef,
      innerKey,
      showRunSelector,
      viewableRunOptions,
      viewedRun,
      showStepSelector,
      viewedStep,
      viewableStepOptions,
      panelConfig,
      setViewedRun,
      setUserQuery,
      setView,
      handleTooltip,
    } = props;
    const [dimensions, setDimensions] = React.useState<BoundingRect>();
    const [vegaView, setVegaView] = React.useState<VegaView>();
    const [error, setError] = React.useState<Error>();
    const [showBindings, setShowBindings] = React.useState(false);
    const [stepAndRunSelectorRef, setStepAndRunSelectorRef] =
      React.useState<HTMLDivElement>();
    const elRef = useRef<Element>();
    const onError = React.useCallback((e: Error) => {
      setError(e);
    }, []);
    const onNewView = React.useCallback(
      (v: VegaView) => {
        (window as any).VIEW = v;
        setError(undefined);
        setVegaView(v);
        setView?.(v);
      },
      [setView]
    );

    React.useEffect(() => {
      if (vegaView && dimensions) {
        vegaView.resize();
      }
    }, [dimensions, vegaView]);

    const vegaData = React.useMemo(() => {
      // viewed run change just changes the filtered data
      // becuase it does not require a new query
      if (
        !viewedRun ||
        !showRunSelector ||
        (viewedRun && viewedRun === VegaLib3.VIEW_ALL_RUNS)
      ) {
        vegaView?.setState({data: {wandb: data}});
        return data;
      } else {
        // hack to refresh the data in case vega doesnt do it.
        vegaView?.setState({data: {wandb: []}});
        vegaView?.setState({
          data: {
            wandb: data.filter(row => row.name === viewedRun),
          },
        });
        return data.filter(row => row.name === viewedRun);
      }
    }, [data, viewedRun, showRunSelector, vegaView]);

    const onSliderChange = (value: number) => {
      if (!(panelConfig && viewableStepOptions)) {
        return;
      }
      const newUserQuery = VegaLib3.updateQueryIndex(
        panelConfig.userQuery ?? VegaLib3.defaultRunSetsQuery,
        viewableStepOptions[value]
      );
      if (setUserQuery) {
        setUserQuery(newUserQuery);
      }
    };

    React.useEffect(() => {
      if (viewedRun && setViewedRun) {
        setViewedRun(
          VegaLib3.getDefaultViewedRun(viewedRun, viewableRunOptions)
        );
      }
    }, [viewableRunOptions, viewedRun, setViewedRun]);

    React.useEffect(() => {
      if (showRunSelector || showStepSelector) {
        setTimeout(() => {
          const element = elRef.current?.querySelector('.vega-bindings');
          const newele = document.createElement('div');
          element?.appendChild(newele);
          setStepAndRunSelectorRef(newele);
        }, 500);
      }
    }, [showRunSelector, showStepSelector]);

    if (err != null) {
      return (
        <S.Wrapper>
          <PanelError className="severe" message={err}></PanelError>
        </S.Wrapper>
      );
    } else if (_.isEmpty(spec)) {
      // TODO(john): More specific parse errors
      return (
        <S.Wrapper>
          <PanelError
            className="severe"
            message="Error: Unable to parse spec"></PanelError>
        </S.Wrapper>
      );
    }
    const onDownloadSVG = (
      panelDOMRef: HTMLDivElement | undefined,
      name: string
    ) => {
      if (vegaView == null) {
        return;
      }
      vegaView
        ?.toImageURL('svg')
        .then(url => {
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', name + '.svg');
          link.dispatchEvent(new MouseEvent('click'));
        })
        .catch(downloadError => {
          alert('Error while downloading: ' + downloadError);
        });
    };

    const onDownloadPNG = (
      panelDOMRef: HTMLDivElement | undefined,
      name: string
    ) => {
      if (vegaView == null) {
        return;
      }
      vegaView
        ?.toImageURL('png')
        .then(url => {
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', name + '.png');
          link.dispatchEvent(new MouseEvent('click'));
        })
        .catch(downloadError => {
          alert('Error while downloading: ' + downloadError);
        });
    };

    const onDownloadPDF = (
      panelDOMRef: HTMLDivElement | undefined,
      name: string
    ) => {
      if (vegaView == null) {
        return;
      }
      vegaView
        ?.toImageURL('svg')
        .then(url => {
          const viewWidth = vegaView?.width();
          const viewHeight = vegaView?.height();
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', name + '.svg');
          // eslint-disable-next-line wandb/no-unprefixed-urls
          const w = window.open(
            url,
            name,
            'width=' + viewWidth + ',height=' + viewHeight
          );
          if (w) {
            w.document.title = name;
            w.print();
          }
        })
        .catch(downloadError => {
          alert('Error while downloading: ' + downloadError);
        });
    };

    if (panelExportRef) {
      panelExportRef.current = {
        onDownloadPNG,
        onDownloadSVG,
        onDownloadPDF,
      };
    }

    const hasBindings =
      VegaLib3.specHasBindings(spec) || showRunSelector || showStepSelector;

    const fieldRefs = VegaLib3.parseSpecFields(spec);
    const specWithFields = VegaLib3.injectFields(spec, fieldRefs, userSettings);

    const parseErrorText = 'Expression parse error';

    let width: number = 0;
    let height: number = 0;
    if (dimensions) {
      width = dimensions.width;
      height = dimensions.height;
      if (vegaView) {
        const padding = vegaView.padding() as any;
        width -= padding.left + padding.right;
        height -= padding.top + padding.bottom;
      }
    }

    specWithFields.autosize = 'fit';
    return (
      <Measure
        bounds
        innerRef={ref => {
          if (ref != null) {
            elRef.current = ref;
          }
        }}
        onResize={contentRect => {
          // Performance hack. Opening the semantic modal may add or remove a
          // scrollbar to the document body. This causes a re-layout, and and
          // all panels to resize. Vega panels can be very expensive to resize,
          // so we skip the resize if we're in the background when a modal is
          // open.
          if (dimensions == null) {
            setDimensions(contentRect.bounds);
          } else {
            setTimeout(() => {
              if (elRef.current != null) {
                if (elRef.current.closest('.dimmer') != null) {
                  setDimensions(contentRect.bounds);
                } else {
                  if (
                    // John: added another hack on this hack :)
                    elRef.current.closest('.custom-panel-editor') != null ||
                    document.querySelector('body.dimmed') == null
                  ) {
                    setDimensions(contentRect.bounds);
                  }
                }
              }
            }, 100);
          }
        }}>
        {({measureRef}) => {
          return (
            <S.Wrapper ref={measureRef} showBindings={showBindings}>
              {stepAndRunSelectorRef &&
                showRunSelector &&
                setViewedRun &&
                viewedRun &&
                viewableRunOptions &&
                ReactDOM.createPortal(
                  <WBSelect
                    options={viewableRunOptions.map(row => {
                      return {name: row, value: row};
                    })}
                    value={viewedRun ?? VegaLib3.VIEW_ALL_RUNS}
                    onSelect={(value: any) => {
                      if (setViewedRun && typeof value === 'string') {
                        setViewedRun(value);
                      }
                    }}
                  />,
                  stepAndRunSelectorRef
                )}
              {stepAndRunSelectorRef &&
                showStepSelector &&
                viewableStepOptions &&
                viewedStep != null &&
                setUserQuery &&
                ReactDOM.createPortal(
                  <div>
                    <SliderInput
                      min={0}
                      max={viewableStepOptions.length - 1}
                      onChange={onSliderChange}
                      step={1}
                      value={viewableStepOptions.findIndex(
                        val => val === viewedStep
                      )}
                    />
                    Step: {viewedStep}
                  </div>,
                  stepAndRunSelectorRef
                )}
              {(width > 0 || height > 0) && (
                <Vega
                  key={innerKey}
                  width={width}
                  height={height}
                  spec={specWithFields}
                  data={
                    specHasWandbData(specWithFields)
                      ? {wandb: vegaData}
                      : undefined
                  }
                  actions={false}
                  onError={onError}
                  onNewView={onNewView}
                  tooltip={handleTooltip}
                />
              )}
              {loading &&
                (slow ? (
                  <PanelError
                    message={
                      <>
                        <WandbLoader />
                        <div className="slow-message">
                          This chart is loading very slowly. Change your query
                          to fetch less data.
                        </div>
                      </>
                    }
                  />
                ) : (
                  <WandbLoader />
                ))}
              {!loading && data.length === 0 && (
                <PanelError message="No data available."></PanelError>
              )}
              {error && (
                <PanelError
                  message={
                    error.message.match(parseErrorText)
                      ? INCOMPLETE_QUERY_MESSAGE
                      : error.name + ': ' + error.message
                  }></PanelError>
              )}
              {hasBindings && (
                <S.ToggleBindingsButton
                  name={showBindings ? 'close' : 'configuration'}
                  onClick={() =>
                    setShowBindings(s => !s)
                  }></S.ToggleBindingsButton>
              )}
            </S.Wrapper>
          );
        }}
      </Measure>
    );
  },
  {id: 'CustomPanelRenderer'}
);

export default CustomPanelRenderer;
