import moment from 'moment';
import React, {useContext} from 'react';
import {envIsLocal} from '../config';
import {
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsQuery,
} from '../generated/graphql';
import docUrl from '../util/doc_urls';
import makeComp from '../util/profiler';
import NudgeBar from './NudgeBar';
import {GlobalNudgeBarContext} from './GlobalNudgeBarContextProvider';

const TeamTrialNudgeBar: React.FC<{
  entityName: string;
}> = makeComp(
  ({entityName}) => {
    const {states} = useContext(GlobalNudgeBarContext);

    if (states == null || states.length === 0) {
      return null;
    }

    const hasGlobalBanner = states.some(
      state =>
        state.renderedNudgeBar === 'enterpriseToStandard' ||
        state.renderedNudgeBar === 'enterpriseToAdvanced'
    );

    const subscriptions = useOrganizationSubscriptionsQuery();
    const teamOrg = subscriptions.data?.viewer?.organizations?.find(org =>
      org.teams?.some(team => team.name === entityName)
    );
    const userLedSubscription = teamOrg?.subscriptions.find(
      sub =>
        sub.status === OrganizationSubscriptionStatus.Enabled &&
        sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial
    );

    const showDaysRemaining =
      !!userLedSubscription &&
      !hasGlobalBanner &&
      !!userLedSubscription.expiresAt &&
      new Date(userLedSubscription.expiresAt) > new Date();

    // expiresAt returned from query is utc mode so use current time in utc
    const expiresAt = userLedSubscription?.expiresAt ?? '';
    const daysRemaining = moment(expiresAt + 'Z').diff(moment().utc(), 'days');

    if (envIsLocal || !showDaysRemaining) {
      return null;
    }

    return (
      <NudgeBar
        inline
        content={`${daysRemaining} days remaining in your team trial.`}
        linkText={'Learn more →'}
        link={docUrl.teamTrials}
      />
    );
  },
  {id: 'TeamTrialNudgeBar', memo: true}
);

export default TeamTrialNudgeBar;
