import {isUndefined} from 'lodash';
import * as React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {MediaString} from '../types/media';

import docUrl, {lookupDocUrl} from '../util/doc_urls';
import PanelError from './elements/PanelError';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

interface MessageMediaNotFoundProps {
  stepIndex?: number;
  mediaKey?: string;
  mediaIndex?: number;
  mediaType?: MediaString;
  basic?: boolean; // If true, shows a question mark icon with the message as a popup
}
const MessageMediaNotFound = makeComp(
  (props: MessageMediaNotFoundProps) => {
    const {stepIndex, mediaKey, mediaIndex, basic} = props;
    let mediaType: string = props.mediaType || 'media';
    const docHref = lookupDocUrl(mediaType) || docUrl.logs;
    if (mediaType === 'table') {
      mediaType = 'tables';
    } else if (mediaType === 'object3D') {
      mediaType = '3D objects';
    }
    const errorMessage = (
      <PanelError
        className="media-missing"
        message={
          <>
            <div>
              This run didn't log {mediaType || 'media'}
              {mediaKey && ` for key "${mediaKey}", step ${stepIndex}`}
              {isUndefined(mediaIndex) ? '' : `, index ${mediaIndex}`}.{' '}
              <TargetBlank href={docHref}>Docs →</TargetBlank>
            </div>
          </>
        }
      />
    );
    return basic ? (
      <Popup
        flowing
        hoverable
        trigger={
          <Icon
            name="question circle outline"
            size="big"
            style={{margin: 'auto'}}
          />
        }
        content={errorMessage}
      />
    ) : (
      errorMessage
    );
  },
  {id: 'MessageMediaNotFound'}
);

export default MessageMediaNotFound;
