import * as React from 'react';
import {labelComponent} from '../util/media';
import makeComp from '../util/profiler';
import {useDownloadFile} from '../util/requests';
import {runLink} from '../util/runhelpers';
import {getAudioMedia, MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import AudioViewer from './Panel2/AudioViewer';

// This panel shows the audio for a single run, using wavesurfer.js to display the waveform

type AudioCardProps = MediaCardProps;

export const AudioCard: React.FunctionComponent<AudioCardProps> = makeComp(
  props => {
    const {
      mediaKey,
      mediaIndex,
      run,
      runSignature,
      globalStep,
      height,
      scale,
      tileMedia,
    } = props;

    const downloadFile = useDownloadFile();

    const media = getAudioMedia(
      {historyRow: tileMedia?.historyRow, mediaKey},
      mediaIndex
    );

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    const caption: string | undefined =
      media?.caption ?? tileMedia?.historyRow[mediaKey]?.captions?.[mediaIndex];

    return (
      <AudioViewer
        audioSrc={tileMedia?.objectURL}
        caption={caption}
        height={height}
        scale={scale}
        mediaFailedToLoad={tileMedia == null}
        headerElement={labelComponent(props, tileMedia?.step, titleLink)}
        failedLoadElement={
          <MessageMediaNotFound
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaIndex={mediaIndex}
            mediaType="audio"
          />
        }
        downloadFile={() =>
          downloadFile(runSignature, tileMedia?.filePath ?? '')
        }
      />
    );
  },
  {id: 'AudioCard', memo: true}
);

export default AudioCard;
