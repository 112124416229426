import * as S from './ProjectsTableUser.styles';
import React, {useMemo} from 'react';
import {CellInfo, Column} from 'react-table';
import {NavLink} from 'react-router-dom';

import * as FuzzyMatch from '../util/fuzzyMatch';
import {ProjectActionMenu} from './ProjectActionMenu';
import WBReactTable from './WBReactTable';
import WandbLoader from './WandbLoader';
import {useAllTeamProjectsQuery} from '../generated/graphql';
import {TimeDelta} from '../util/time';
import {isNotNullOrUndefined} from '../util/types';

import TimeAgo from 'react-timeago';

export type ProjectsTableProps = {
  className?: string;
  entityName: string;
  readOnly?: boolean;
  pageSize?: number | null;
  isTeam?: boolean;
  onChangePageSize?: (pageSize: number) => void;
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  className,
  entityName,
  readOnly,
  pageSize,
  onChangePageSize,
}) => {
  const allProjectsQuery = useAllTeamProjectsQuery({
    variables: {entityName},
  });

  const projects =
    allProjectsQuery.data?.projects?.edges.map(e => e.node) ?? [];

  const rows = useMemo(
    () =>
      projects.filter(isNotNullOrUndefined).map(p => ({
        searchString: p.name,
        row: {
          name: p.name,
          lastRun: p.lastActive,
          runCount: p.totalRuns,
          edit: p,
          compute: p.computeHours,
        },
      })),
    [projects]
  );

  const columns = React.useMemo(() => {
    const nameColumn: Column = {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellInfo: CellInfo) => {
        const projectHome: string = entityName;
        const projectName: string = cellInfo.value;
        return (
          <div>
            <NavLink to={`/${projectHome}/${projectName}`}>
              {FuzzyMatch.fuzzyMatchHighlightPieces([projectName])}
            </NavLink>
          </div>
        );
      },
    };
    const lastRunColumn: Column = {
      Header: 'Last Run',
      accessor: 'lastRun',
      Cell: (cellInfo: CellInfo) => {
        if (cellInfo.value == null) {
          return <span>-</span>;
        }
        const runDate = new Date(cellInfo.value + 'Z');

        const dayOld = Date.now() - 24 * 60 * 60 * 1000;
        if (runDate.getTime() > dayOld) {
          return <TimeAgo date={runDate} />;
        }

        const runDateString = runDate.toISOString().substring(0, 10);
        return <span>{runDateString}</span>;
      },
    };
    const runCountColumn: Column = {
      Header: 'Runs',
      accessor: 'runCount',
      Cell: (cellInfo: CellInfo) => {
        return <span>{cellInfo.value}</span>;
      },
    };
    const computeHourColumn: Column = {
      Header: 'Compute Hour',
      accessor: 'compute',
      Cell: (cellInfo: CellInfo) => {
        return (
          <span>{new TimeDelta(cellInfo.value).toSingleUnitString()}</span>
        );
      },
    };
    const editColumn: Column = {
      Header: '',
      accessor: 'edit',
      maxWidth: 50,
      Cell: (cellInfo: CellInfo) => {
        return (
          <S.menuWrapper>
            <ProjectActionMenu
              project={cellInfo.value}
              refetch={() => allProjectsQuery.refetch()}
            />
          </S.menuWrapper>
        );
      },
    };
    const allColumns = [
      nameColumn,
      lastRunColumn,
      runCountColumn,
      computeHourColumn,
    ];
    if (!readOnly) {
      allColumns.push(editColumn);
    }
    return allColumns;
  }, [readOnly, allProjectsQuery, entityName]);

  if (allProjectsQuery.loading) {
    return (
      <S.LoaderWrapper>
        <WandbLoader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper className={className}>
      <S.HeaderWrapper></S.HeaderWrapper>
      <WBReactTable
        data={rows}
        columns={columns}
        pageSize={pageSize ?? 20}
        onChangePageSize={onChangePageSize}
      />
    </S.Wrapper>
  );
};

export default ProjectsTable;
