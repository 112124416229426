import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';
import * as globals from '../../../css/globals.styles';
import {GLOBAL_COLORS} from '../../../util/colors';

export const Link = styled.a`
  font-family: Source Serif Pro;
`;

export const LinkInput = styled.input`
  font-family: ${globals.fontName};
  height: 40px;
  width: 240px;
  padding: 8px;
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  border-radius: 4px;
  background: rgb(250, 251, 253);
  outline: none;
`;

export const EditLinkWrapper = styled.div`
  border-radius: 4px;
  ${LinkInput} {
    padding-right: 32px;
  }
`;

export const RemoveLinkButton = styled(WBIcon).attrs({name: 'close'})`
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -12px;
  font-size: 24px;
  color: ${GLOBAL_COLORS.gray.toString()};
  cursor: pointer;
  &:hover {
    color: black;
  }
`;
