import styled, {css} from 'styled-components';
import {WBIcon} from '@wandb/ui';
import {HoveringToolbarMode} from './HoveringToolbar';

export const Wrapper = styled.div<{
  left: number;
  top: number;
  direction: 'up' | 'down';
  open: boolean;
  mode: HoveringToolbarMode;
}>`
  box-shadow: 0 8px 16px rgba(27, 39, 51, 0.16);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  opacity: ${props => (props.open ? 1 : 0)};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  transform: translateY(
    ${props => (props.open ? 0 : props.direction === 'up' ? 12 : -12)}px
  );
  transition: transform 0.15s, opacity 0.15s;
  ${props =>
    (props.mode === 'link' || props.mode === 'editing') &&
    css`
      padding: 0;
    `}
`;

export const DefaultWrapper = styled.div`
  padding: 4px;
  background: rgb(34, 34, 34);
  display: flex;
  border-radius: 4px;
`;

export const FormatButton = styled(WBIcon)<{
  $active?: boolean;
  $small?: boolean;
}>`
  color: white;
  opacity: 0.74;
  cursor: pointer;
  border-radius: 2px;
  ${props =>
    props.$small &&
    css`
      margin: 2px;
      height: 20px;
    `}
  &:hover {
    opacity: 1;
  }
  &:not(:last-child) {
    margin-right: 1px;
  }
  ${props =>
    props.$active &&
    css`
      opacity: 1;
    `}
  && {
    font-size: ${props => (props.$small ? `16` : `20`)}px;
  }
`;
