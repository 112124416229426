import _ from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {toast} from 'react-toastify';
import {
  Button,
  CheckboxProps,
  Divider,
  Dropdown,
  Input,
  Modal,
  Table,
} from 'semantic-ui-react';
import '../css/EditUserModal.less';
import {
  useAddUserToOrganizationMutation,
  useRemoveUserFromOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationUserMutation,
  useOrganizationSubscriptionInfoQuery,
  PlanType,
} from '../generated/graphql';
import {OrganizationMemberFragment} from '../pages/BillingPage';
import {useViewer} from '../state/viewer/hooks';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {
  OrganizationFlags,
  orgSeatsRemaining,
  orgPrimarySubUnitPrice,
  orgPrimarySubBillingInterval,
} from '../util/pricing';
import makeComp from '../util/profiler';
import * as S from './EditUserModal.styles';
import PurchaseSeatModal, {PurchaseSeatModalProps} from './PurchaseSeatModal';
import LegacyWBIcon from './elements/LegacyWBIcon';
import WandbLoader from './WandbLoader';
import {jsonParseSafe} from '../util/json';

export interface EditUserModalProps {
  open: boolean;
  organizationId: string;
  enableAdminControls?: boolean;
  onAddUser: () => Promise<any>;
  onRemoveUser: () => Promise<any>;
  onUpdateOrganizationUser: () => Promise<any>;
  onClose: () => void;
}

const EditUserModal: FunctionComponent<EditUserModalProps> = makeComp(
  ({
    open,
    organizationId,
    enableAdminControls,
    onAddUser,
    onRemoveUser,
    onUpdateOrganizationUser,
    onClose,
  }) => {
    const orgQuery = useOrganizationSubscriptionInfoQuery({
      variables: {organizationId},
      fetchPolicy: 'network-only',
    });
    const organization = useMemo(() => {
      const queryOrg = orgQuery.data?.organization;
      if (queryOrg == null) {
        return null;
      }

      const primarySub =
        queryOrg.subscriptions.find(
          s => s.plan.planType === PlanType.Primary
        ) ?? null;

      const parsedFlags =
        queryOrg.flags != null
          ? (jsonParseSafe(queryOrg.flags) as OrganizationFlags)
          : null;

      return {
        ...queryOrg,
        parsedFlags,
        seatCount: primarySub?.seats,
        primaryPlanPrice: primarySub?.plan.unitPrice,
      };
    }, [orgQuery.data]);
    const viewer = useViewer();
    const [userName, setUserName] = useState('');
    const [teamsAddedTo, setTeamsAddedTo] = useState<string[]>([]);
    const [userOrgRole, setUserOrgRole] = useState('');
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [userToRemove, setUserToRemove] =
      useState<OrganizationMemberFragment | null>(null);
    const [purchaseSeatModalState, setPurchaseSeatModalState] =
      useState<PurchaseSeatModalProps | null>(null);

    const [addUserToOrganizationMutation] = useAddUserToOrganizationMutation({
      context: propagateErrorsContext(),
    });
    const [removeUserFromOrganizationMutation] =
      useRemoveUserFromOrganizationMutation({
        context: propagateErrorsContext(),
      });
    const [userToUpdateRole, setUserToUpdateRole] =
      useState<OrganizationMemberFragment | null>(null);
    const [updateOrganizationUserMutation] = useUpdateOrganizationUserMutation({
      context: propagateErrorsContext(),
    });

    const [updateOrganization] = useUpdateOrganizationMutation({
      context: propagateErrorsContext(),
    });
    const [organizationFlags, setOrganizationFlags] = useState(
      organization?.parsedFlags ?? null
    );
    useEffect(() => {
      setOrganizationFlags(organization?.parsedFlags ?? null);
    }, [organization]);
    const toggleOrganizationFlag = useCallback(
      (key: keyof OrganizationFlags, val: boolean) => {
        if (organization == null) {
          return;
        }
        const newFlags = {...organizationFlags, [key]: val};
        setOrganizationFlags(newFlags);
        updateOrganization({
          variables: {
            organizationID: organization.id,
            flags: JSON.stringify(newFlags),
          },
        });
      },
      [organization, organizationFlags, updateOrganization]
    );

    const inputRef = useRef<Input>(null);

    const close = useCallback(() => {
      onClose();
      setAddUserVisible(false);
      setUserName('');
      setTeamsAddedTo([]);
      setUserOrgRole('');
    }, [onClose]);

    const addUser = useCallback(async () => {
      if (organization == null) {
        return;
      }
      try {
        const addUserResult = await addUserToOrganizationMutation({
          variables: {
            userName,
            teams: teamsAddedTo,
            organizationId: organization.id,
            userOrgRole,
          },
        });

        if (addUserResult.errors) {
          toast(`Error adding user: ${addUserResult.errors}`);
        } else if (
          addUserResult.data &&
          addUserResult.data.addUserToOrganization?.success
        ) {
          await onAddUser();
          toast('Succesfully added user to organization.');
          close();
        }
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
        toast(`Error adding user to organization: ${errMsg}`);
      }
    }, [
      onAddUser,
      close,
      addUserToOrganizationMutation,
      userName,
      teamsAddedTo,
      organization,
      userOrgRole,
    ]);

    const removeUser = useCallback(
      async (member: OrganizationMemberFragment) => {
        if (organization == null) {
          return;
        }
        try {
          const removeUserResult = await removeUserFromOrganizationMutation({
            variables: {
              userName: member.username,
              organizationId: organization.id,
            },
          });

          if (removeUserResult.errors) {
            toast(`Error removing user: ${removeUserResult.errors}`);
          } else if (
            removeUserResult.data &&
            removeUserResult.data.removeUserFromOrganization?.success
          ) {
            await onRemoveUser();
            toast('Successfully removed user from organization.');
            close();
          }
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
          toast(`Error removing user from organization: ${errMsg}`);
        }
      },
      [onRemoveUser, close, removeUserFromOrganizationMutation, organization]
    );

    const updateUserRole = useCallback(
      async (member: OrganizationMemberFragment) => {
        if (organization == null) {
          return;
        }
        try {
          const updateUserRoleResult = await updateOrganizationUserMutation({
            variables: {
              userName: member.username,
              organizationId: organization.id,
              userOrgRole: member.role,
            },
          });
          if (updateUserRoleResult.errors) {
            toast(`Error updating user role: ${updateUserRoleResult.errors}`);
          } else if (
            updateUserRoleResult.data &&
            updateUserRoleResult.data.updateOrganizationUser?.success
          ) {
            await onUpdateOrganizationUser();
            toast('Successfully updated user role.');
            close();
          }
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
          toast(`Error updating user: ${errMsg}`);
        }
      },
      [
        close,
        organization,
        updateOrganizationUserMutation,
        onUpdateOrganizationUser,
      ]
    );

    const memberList = useMemo(() => {
      const members = organization?.members ?? [];
      return members.map((member, ind) => {
        const isBillingUser =
          member.username === organization?.billingUser.username;
        const firstOpt = isBillingUser
          ? {text: 'Billing Only', value: 'billing-only'}
          : {text: 'Member', value: 'member'};
        const options = [firstOpt, {text: 'Admin', value: 'admin'}];

        const tableCellContents: React.ReactNode[] = [
          member.name,
          member.username,
          (member.teams?.edges ?? []).map(e => e.node?.name ?? '').join(', '),
          <div>
            <Dropdown
              className="edit-user-modal__org-role-dropdown"
              data-test="edit-org-role-dropdown"
              data-test-index={`${ind}`}
              placeholder={member.role}
              selection
              options={options}
              onChange={(e, {value}) =>
                setUserToUpdateRole({...member, role: value as string})
              }
              value={member.role}
            />
          </div>,
          member.username !== viewer?.username && (
            <LegacyWBIcon
              name="close"
              className="edit-user-modal__icon"
              onClick={() => setUserToRemove(member)}
            />
          ),
        ];
        return (
          <Table.Row key={member.username}>
            {tableCellContents.map((c, i) => (
              <Table.Cell key={i} className="edit-user-modal__table-cell">
                {c}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      });
    }, [organization, viewer]);

    const teamOptions = useMemo(() => {
      const teamNameSet: Set<string> = new Set();
      for (const m of organization?.members ?? []) {
        for (const e of m.teams?.edges ?? []) {
          const t = e.node;
          if (t != null) {
            teamNameSet.add(t.name);
          }
        }
      }
      const sortedTeamNames = _.sortBy(Array.from(teamNameSet));
      return sortedTeamNames.map(t => ({text: t, value: t}));
    }, [organization]);

    if (organization == null) {
      return <WandbLoader />;
    }

    return (
      <>
        <Modal className="edit-user-modal" open={open} onClose={close}>
          <Modal.Header>Edit Organization</Modal.Header>
          <Modal.Content>
            {enableAdminControls && (
              <S.Flags>
                <S.FlagText>
                  <b>Sales Only:</b> Flag this org as a sales-led trial, and
                  HIDE all Standard Plan nudges in the app.
                </S.FlagText>
                <S.Flag>
                  <S.FlagCheckbox
                    toggle
                    checked={organizationFlags?.noContact ?? false}
                    onChange={
                      ((e, value) =>
                        toggleOrganizationFlag(
                          'noContact',
                          value.checked ?? false
                        )) as CheckboxProps['onChange']
                    }
                  />
                  Sales led trial
                </S.Flag>
              </S.Flags>
            )}
            {memberList.length > 0 && (
              <Table basic="very" className="edit-user-modal__table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="edit-user-modal__table-header">
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell className="edit-user-modal__table-header">
                      Username
                    </Table.HeaderCell>
                    <Table.HeaderCell className="edit-user-modal__table-header">
                      Teams
                    </Table.HeaderCell>
                    <Table.HeaderCell className="edit-user-modal__table-header">
                      Organization Role
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>{memberList}</Table.Body>
              </Table>
            )}
            <Divider className="edit-user-modal__divider" />
            <form
              className="edit-user-modal__add-user"
              onSubmit={e => {
                if (organization == null) {
                  return;
                }
                if (organization.seatCount == null) {
                  console.error('Organization seat count not found');
                  return;
                }
                e.preventDefault();

                const remaining = orgSeatsRemaining(organization);
                const seatsNeeded =
                  remaining != null ? (remaining - 1) * -1 : 0;
                const price = orgPrimarySubUnitPrice(organization);
                const billingInterval =
                  orgPrimarySubBillingInterval(organization);
                if (
                  // We don't do self-serve seat upgrades for plans without prices.
                  price != null &&
                  billingInterval != null &&
                  seatsNeeded > 0
                ) {
                  setPurchaseSeatModalState({
                    seatsToPurchase: seatsNeeded,
                    pricePerSeat: price,
                    billingInterval,
                    onConfirm: addUser,
                    onClose: () => setPurchaseSeatModalState(null),
                  });
                } else {
                  addUser();
                }
              }}>
              {addUserVisible ? (
                <>
                  <Input
                    ref={inputRef}
                    className="edit-user-modal__input"
                    data-test="add-user-input"
                    placeholder="Enter username"
                    onChange={(e, data) => setUserName(data.value)}
                    value={userName}
                  />
                  <Dropdown
                    className="edit-user-modal__dropdown"
                    data-test="add-user-team-input"
                    placeholder="Select team(s)"
                    fluid
                    multiple
                    search
                    selection
                    options={teamOptions}
                    onChange={(e, {value}) =>
                      setTeamsAddedTo(value as string[])
                    }
                    value={teamsAddedTo}
                  />
                  <Dropdown
                    className="edit-user-modal__dropdown"
                    data-test="add-user-type-dropdown"
                    placeholder="User type"
                    fluid
                    search
                    selection
                    options={[
                      {text: 'Member', value: 'member'},
                      {text: 'Admin', value: 'admin'},
                    ]}
                    onChange={(e, {value}) => setUserOrgRole(value as string)}
                    value={userOrgRole}
                  />
                  <Button
                    className="ui button action-button"
                    data-test="submit-add-user"
                    disabled={userOrgRole === ''}>
                    Add
                  </Button>
                </>
              ) : (
                <div
                  className="edit-user-modal__add-user-link"
                  data-test="show-add-user"
                  onClick={() => {
                    if (organization == null) {
                      return;
                    }
                    setAddUserVisible(true);
                    setTimeout(() => inputRef.current?.focus());
                  }}>
                  + Add member
                </div>
              )}
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={close}>Close</Button>
          </Modal.Actions>
        </Modal>
        <Modal
          className="edit-user-modal"
          open={userToRemove != null}
          onClose={() => {
            setUserToRemove(null);
          }}>
          <Modal.Header>
            Are you sure you want to remove {userToRemove?.username}? They will
            also be removed from teams they are currently a part of.
          </Modal.Header>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                if (userToRemove == null) {
                  return;
                }
                removeUser(userToRemove);
                setUserToRemove(null);
              }}>
              Delete
            </Button>
            <Button
              onClick={() => {
                setUserToRemove(null);
              }}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          className="edit-user-modal"
          open={userToUpdateRole != null}
          onClose={() => {
            setUserToUpdateRole(null);
          }}>
          <Modal.Header>Update Role</Modal.Header>
          <Modal.Content>
            Are you sure you want to update {userToUpdateRole?.username}'s role
            to {userToUpdateRole?.role}?
            {userToUpdateRole?.role === 'billing-only' &&
              ` Before you switch ${userToUpdateRole?.username} to a billing-only user,
             make sure there is another admin on each of your teams. ${userToUpdateRole?.username} 
             will lose access to all of the organization's teams.`}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              data-test="accept-update-user-role-button"
              onClick={() => {
                if (userToUpdateRole == null) {
                  return;
                }
                updateUserRole(userToUpdateRole);
                setUserToUpdateRole(null);
              }}>
              Update
            </Button>
            <Button
              onClick={() => {
                setUserToUpdateRole(null);
              }}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        {purchaseSeatModalState != null && (
          <PurchaseSeatModal {...purchaseSeatModalState} />
        )}
      </>
    );
  },
  {id: 'EditUserModal'}
);

export default EditUserModal;
