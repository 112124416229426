import * as S from './ProjectPage2.styles';

import '../css/GroupPage.less';
import '../css/ProjectPage.less';

import React, {FC, useEffect, useRef, useMemo} from 'react';
import {Redirect} from 'react-router';
import {Link} from 'react-router-dom';

import emptyImg from '../assets/il-no-visuals-yet.png';
import emptyImg2x from '../assets/il-no-visuals-yet@2x.png';
import EmptyWatermark from '../components/EmptyWatermark';
import FancyPage from '../components/FancyPage';
import MultiRunWorkspace from '../components/MultiRunWorkspace';
import NoMatch from '../components/NoMatch';
import ProjectOverview from '../components/ProjectOverview';
import ReportTableWithQuery from '../components/ReportTable';
import SweepsTable from '../components/SweepsTable';
import ProjectArtifactsTab from '../components/ProjectArtifactsTab';
import WandbLoader from '../components/WandbLoader';
import * as Generated from '../generated/graphql';
import {useProjectPageQuery} from '../state/graphql/projectPageQuery';
import {useDispatch} from '../state/hooks';
import {usePushReport} from '../state/reports/hooks';
import * as PollingActions from '../state/polling/actions';
import * as ViewerHooks from '../state/viewer/hooks';
import docUrl from '../util/doc_urls';
import * as Report from '../util/report';
import * as urls from '../util/urls';
import {checkTheme, getTheme} from './Benchmark/Theme';
import {NoRunsSampleScript} from './ProjectPageOnboarding';
import {AnalyticsEvents} from '../util/integrations';
import {toMongo} from '../util/filters';
import RunQueueTabContents from '../components/RunQueueTab';
import {UNDELETE_RUNS_LOOKBACK} from '../components/UndeleteRunsModal';
import {setDocumentTitle, setDocumentDescription} from '../util/document';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';
import ExampleShowcase from '../components/ExampleShowcase';
import {useRunQueueEnabled} from '../util/featureFlags';
import {urlPrefixed} from '../config';
import {decodeURIComponentSafe} from '../util/url';

const MIN_POLL_INTERVAL = 10000;
const MAX_POLL_INTERVAL = 60000;

interface ProjectPageProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
      projectId: string;
      tab?: string;
      artifactTypeName?: string;
      artifactCollectionName?: string;
      artifactCommitHash: string;
      artifactTab?: string;
      filePath?: string;
    };
  };
  history: any;
  showRunQueue: boolean;
}

type AllProjectPageProps = ProjectPageProps &
  ReturnType<typeof useProjectPageProps>;

const ProjectPage: FC<AllProjectPageProps> = makeComp(
  ({
    match,
    history,
    showRunQueue,
    viewer,
    dispatch,
    projectQuery,
    deleteProject,
    undeleteRuns,
    updateProject,
    pushDraftReport,
  }) => {
    const {
      entityName,
      tab,
      artifactTypeName,
      artifactCollectionName,
      artifactCommitHash,
      artifactTab,
      filePath,
    } = match.params;

    // HAX: the project name URI we get from react-router might be encoded.
    // Decode this URI component before using it.
    // If this happens again on another URI component (e.g. entity name), we
    // should do more general fix at the react-router layer.
    const projectName = decodeURIComponentSafe(match.params.projectName);

    const pollIntervalSet = useRef(false);

    useEffect(() => {
      window.analytics.track(AnalyticsEvents.MULTI_RUN_WORKSPACE, {
        page: 'Project',
        panelbank: true,
      });
      // Set poll interval to zero until we know how many runs there in the project.
      dispatch(PollingActions.setBasePollInterval(0));
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (
        !projectQuery.loading &&
        projectQuery.project !== null &&
        !pollIntervalSet.current
      ) {
        // Scale poll interval from 10s (when there are 0 runs) to 60s
        // (when there are 5000 runs).
        let pollInterval =
          ((projectQuery.project.runCount / 5000) * 50 + 10) * 1000;
        if (pollInterval < MIN_POLL_INTERVAL) {
          pollInterval = MIN_POLL_INTERVAL;
        }
        if (pollInterval > MAX_POLL_INTERVAL) {
          pollInterval = MAX_POLL_INTERVAL;
        }
        dispatch(PollingActions.setBasePollInterval(pollInterval));
        pollIntervalSet.current = true;
      }
    });

    const project = !projectQuery.loading ? projectQuery.project : null;

    const tabDescription: string = useMemo(() => {
      switch (tab) {
        case `overview`:
          return `Overview`;
        case `table`:
          return `Table`;
        case `reportlist`:
          return `Reports`;
        case `sweeps`:
          return `Sweeps`;
        case `run-queue`:
          return `Launch`;
        case `artifacts`:
          return `Artifacts`;
        case `workspace`:
        default:
          return 'Workspace';
      }
    }, [tab]);

    const runCount = project?.runCount ?? 0;
    const sweepCount = project?.totalSweeps ?? 0;
    const reportCount = project?.reports.edges.length ?? 0;

    useEffect(() => {
      setDocumentTitle(`${projectName} ${tabDescription}`);
      setDocumentDescription(
        `${tabDescription} of ${projectName}, a machine learning project by ${entityName} using Weights & Biases with ${runCount} runs, ${sweepCount} sweeps, and ${reportCount} reports.`
      );
    }, [
      tabDescription,
      projectName,
      entityName,
      runCount,
      sweepCount,
      reportCount,
    ]);

    if (projectQuery.loading) {
      return <WandbLoader />;
    }

    if (!project) {
      return <NoMatch />;
    }

    if (project.entity.claimingEntity != null) {
      // If the project's entity has been claimed, redirect to the claiming
      // entity instead so the original link still works.
      return (
        <Redirect
          to={urls.project({
            entityName: project.entity.claimingEntity.name,
            name: projectName,
          })}
        />
      );
    }

    if (
      project.isBenchmark &&
      checkTheme({entityName, name: projectName, views: project.views})
    ) {
      return (
        <Redirect to={urls.benchmarkProject({entityName, name: projectName})} />
      );
    }

    const linkedBenchmarkMeta =
      !project.isBenchmark && project.linkedBenchmark != null
        ? project.linkedBenchmark
        : null;

    const linkedBenchmarkInfo =
      linkedBenchmarkMeta != null ? getTheme(linkedBenchmarkMeta) : null;

    let apiKey = '';
    if (
      viewer &&
      viewer.apiKeys &&
      viewer.apiKeys.edges.length > 0 &&
      viewer.apiKeys.edges[0].node != null &&
      viewer.apiKeys.edges[0].node.name != null
    ) {
      apiKey = viewer.apiKeys.edges[0].node!.name;
    }

    const handleDeleteProject = () =>
      deleteProject({variables: {id: project.id}}).then(() => {
        history.push(urls.entity(entityName));
        window.location.reload();
      });

    const handleUndeleteRuns = () =>
      undeleteRuns({
        variables: {
          filters: JSON.stringify(
            toMongo({
              op: '>=',
              key: {
                section: 'run',
                name: 'deletedAt',
              },
              value: new Date(Date.now() - UNDELETE_RUNS_LOOKBACK).toJSON(),
            })
          ),
          entityName,
          projectName,
        },
      }).then(() => {
        window.location.assign(
          urlPrefixed(
            urls.projectObjTab(
              entityName,
              projectName,
              undefined,
              undefined,
              'table'
            )
          )
        );
      });

    if (project.runCount === 0 && projectName === 'sample-project') {
      return <NoRunsSampleScript key="runsTab" apiKey={apiKey} />;
    }

    const emptyState: JSX.Element = (
      <EmptyWatermark
        style={{marginTop: 74}}
        imageSource={emptyImg}
        imageSource2x={emptyImg2x}
        header="You haven't logged any runs yet."
        details={
          <>
            Visit the{' '}
            {linkedBenchmarkInfo != null ? (
              <Link
                to={urls.projectLinkedBenchmark({
                  entityName,
                  name: projectName,
                })}>
                Benchmark tab
              </Link>
            ) : (
              <TargetBlank href={docUrl.root}>Documentation</TargetBlank>
            )}{' '}
            to get started.
          </>
        }
      />
    );

    const baseUrl = urls.project({entityName, name: projectName});

    const onSetTableExpanded = (expanded: boolean) => {
      history.push({
        pathname: baseUrl + (expanded ? '/table' : '/workspace'),
        search: window.location.search,
      });
    };

    const workspaceTabBody = (tableExpanded: boolean) => () => {
      return project.runCount === 0 ? (
        <div>{emptyState}</div>
      ) : (
        <MultiRunWorkspace
          entityName={entityName}
          projectName={projectName}
          tableExpanded={tableExpanded}
          onSetTableExpanded={onSetTableExpanded}
          viewType="project-view"
          title={`Runs (${project.runCount})`}
          recommendGrouping="group-and-jobtype"
        />
      );
    };
    return (
      <div className="project-page">
        <FancyPage
          history={history}
          baseUrl={baseUrl}
          activeSlug={tab}
          defaultIndex={1}
          items={[
            {
              iconName: 'cecile-overview-gray',
              selectedIconName: 'cecile-overview',
              name: 'Overview',
              slug: 'overview',
              render: () => (
                <ProjectOverview
                  project={project}
                  updateProject={updateProject}
                  deleteProject={handleDeleteProject}
                  undeleteRuns={handleUndeleteRuns}
                />
              ),
            },
            ...(project.runCount > 0
              ? [
                  {
                    iconName: 'cecile-workspace-gray',
                    selectedIconName: 'cecile-workspace',
                    name: 'Workspace',
                    slug: 'workspace',
                    render: workspaceTabBody(false),
                  },
                  {
                    iconName: 'cecile-table-gray',
                    selectedIconName: 'cecile-table',
                    name: 'Table',
                    slug: 'table',
                    render: workspaceTabBody(true),
                  },
                ]
              : [
                  {
                    iconName: 'cecile-workspace-gray',
                    selectedIconName: 'cecile-workspace',
                    name: 'Workspace',
                    slug: 'workspace',
                    render: () => {
                      return (
                        <S.ExamplesWrapper>
                          <S.Examples>
                            <S.ExamplesHeader>
                              Start logging metrics
                            </S.ExamplesHeader>
                            <S.ExamplesText>
                              Add a few lines of code to get a live dashboard of
                              your model training, including logs and system
                              metrics.
                            </S.ExamplesText>
                            <ExampleShowcase
                              entityName={entityName}
                              projectName={projectName}
                              blankProject
                            />
                          </S.Examples>
                        </S.ExamplesWrapper>
                      );
                    },
                  },
                ]),
            {
              iconName: 'cecile-reports-gray',
              selectedIconName: 'cecile-reports',
              name: `Reports (${project.reports.edges.length})`,
              slug: 'reportlist',
              render: () => {
                return (
                  <ReportTableWithQuery
                    entityName={entityName}
                    projectName={projectName}
                    onCreateNewReport={() => {
                      const report = Report.getEmptyReportConfig();
                      pushDraftReport(report);
                    }}
                  />
                );
              },
            },
            ...(linkedBenchmarkInfo != null
              ? [
                  {
                    iconName: 'cecile-benchmark-gray',
                    selectedIconName: 'cecile-benchmark',
                    name: 'Benchmark: ' + project.linkedBenchmark.name,
                    externalLink: urls.project(
                      /* TS3.9 upgraded caused type mismatch here, please fix if working on benchmarks */
                      project.linkedBenchmark as any
                    ),
                    sectionIndex: 1,
                  },
                ]
              : []),
            {
              iconName: 'cecile-sweeps-gray',
              selectedIconName: 'cecile-sweeps',
              name: `Sweeps (${project.totalSweeps})`,
              slug: 'sweeps',
              render: () => (
                <SweepsTable
                  entityName={entityName}
                  projectName={projectName}
                />
              ),
            },
            ...(showRunQueue
              ? [
                  {
                    iconName: 'cecile-rocket-gray',
                    selectedIconName: 'cecile-rocket',
                    name: 'Launch',
                    slug: 'run-queue',
                    render: () => (
                      <RunQueueTabContents
                        projectLoading={projectQuery.loading}
                        entityName={entityName}
                        isTeam={project.entity.isTeam}
                        projectName={projectName}
                        runQueues={project.runQueues}
                        refetchProject={projectQuery.refetch}
                      />
                    ),
                  },
                ]
              : []),
            ...(project.runCount > 0
              ? [
                  {
                    iconName: 'artifact-gray',
                    selectedIconName: 'artifact-white',
                    name: `Artifacts`,
                    slug: 'artifacts',
                    render: () => (
                      <ProjectArtifactsTab
                        entityName={entityName}
                        projectName={projectName}
                        history={history}
                        artifactTypeName={artifactTypeName}
                        artifactCollectionName={artifactCollectionName}
                        artifactCommitHash={artifactCommitHash}
                        artifactTab={artifactTab}
                        filePath={filePath}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
    );
  },
  {id: 'ProjectPage2', memo: true}
);

function useProjectPageProps(entityName: string, projectName: string) {
  const viewer = ViewerHooks.useViewer();

  const projectQuery = useProjectPageQuery(
    {
      entityName,
      projectName,
    },
    {
      enablePolling: true,
    }
  );

  const [updateProject] = Generated.useUpsertModelMutation();
  const [deleteProject] = Generated.useDeleteModelMutation();
  const [undeleteRuns] = Generated.useUndeleteRunsMutation();

  const dispatch = useDispatch();

  const pushDraftReport = usePushReport(entityName, projectName);
  const featureEnabled = useRunQueueEnabled();
  const showRunQueue =
    !projectQuery.loading && featureEnabled && viewer != null;

  return {
    viewer,
    dispatch,
    updateProject,
    deleteProject,
    undeleteRuns,
    projectQuery,
    pushDraftReport,
    showRunQueue,
  };
}

export default makeComp(
  (props: ProjectPageProps) => {
    const {entityName} = props.match.params;
    // HAX: the project name URI we get from react-router might be encoded.
    // Decode this URI component before using it.
    // If this happens again on another URI component (e.g. entity name), we
    // should do more general fix at the react-router layer.
    const projectName = decodeURIComponentSafe(props.match.params.projectName);
    const selectedProps = useProjectPageProps(entityName, projectName);
    return <ProjectPage {...props} {...selectedProps} />;
  },
  {id: 'ProjectPage'}
);
