import _ from 'lodash';

/**
 * Constructs a context that informs the error middleware to propagate errors
 * to the request issuer rather than intercepting them.
 */
export function propagateErrorsContext(): {propagateErrors: boolean} {
  return {
    propagateErrors: true,
  };
}

export function doNotRetryContext(): {doNotRetry: boolean} {
  return {
    doNotRetry: true,
  };
}

export function extractErrorMessageFromApolloError(
  err: any
): string | undefined {
  const errMsg =
    _.get(err, 'graphQLErrors[0].message') ??
    _.get(err, 'networkError.result.errors[0].message');

  return errMsg;
}

export function extractStatusCodeFromApolloError(err: any): number | undefined {
  const statusCode =
    err?.networkError?.statusCode ?? err?.graphQLErrors?.[0]?.statusCode;

  return statusCode;
}
