import {History} from 'history';
import queryString from 'query-string';
import {Store} from 'redux';
import {clearCurrentIdToken} from '../actions/auth';
import {CREATE_ENTITY, ENTITY_QUERY} from '../graphql/users';
import {apolloClient, isInIframe} from '../setup';
import {displayError} from '../state/global/actions';
import {reloadCurrentViewer, setCurrentViewer} from '../state/viewer/actions';
import {JWTClaims} from '../state/viewer/types';
import {setCookie, unsetCookie} from './cookie';
import {propagateErrorsContext} from './errors';
import {isOnReportView, stripOriginFromURL} from './url';
import {login, LoginParams, logout} from './urls';
import {urlPrefixed} from '../config';
import globalHistory from './history';

export class Auth {
  // we only want to attempt auto login once per page load
  _autoLoginAttempted = false; // tslint:disable-line
  _loggingOut = false; // tslint:disable-line
  tempKey: string | undefined;
  claims?: JWTClaims;

  store: Store | null = null;

  constructor() {
    // We store the temporary key for password resets and invites.
    const params = queryString.parse(document.location.search);
    if (params.jwt) {
      this.tempKey = params.jwt as string;
    }
  }

  login(options?: LoginParams, state?: any) {
    let backTo = '';
    if (state && state.from && state.from.pathname !== '/login') {
      backTo = state.from.pathname + (state.from.search || '');
      setAuthRedirect(backTo);
    } else {
      setAuthRedirect(document.location.href);
    }
    // The backend will redirect to the login modal or the SSO provider
    // we need to open a new window if we're logging in from an iframe.
    if (isInIframe()) {
      window.open(login(options));
    } else {
      document.location.href = login(options);
    }
  }

  async logout(redirect = true) {
    // This can get called in a loop, only logout if we're logged in.  Maintain
    // loggingOut state in this object
    if (this.loggedIn() && !this._loggingOut) {
      this._loggingOut = true;
      try {
        this.store!.dispatch(clearCurrentIdToken());
        // Hack: set `loading` (in `setCurrentViewer`) to true so that App.tsx
        // renders a Loader. This hides us flashing the Login page (PrivateRoute
        // redirects to /login because we're not logged in).
        this.store!.dispatch(setCurrentViewer(true, undefined));
        await fetch(logout(), {
          method: 'DELETE',
          credentials: 'include',
        });
        if (redirect) {
          window.location.href = urlPrefixed();
        }
      } catch (e) {
        console.error('Unable to logout: ', e);
      }
      this._loggingOut = false;
    }
  }

  parseClaims(jwt: string) {
    if (jwt === 'dummy') {
      // this is effectively a passthrough for the integration tests
      // it'll only work in integration because it relies on gorilla's
      // mock auth header, which is otherwise disabled
      return {exp: Date.now() + 60 * 60 * 24};
    }
    try {
      return JSON.parse(atob(jwt.split('.')[1]));
    } catch (e) {
      return {exp: 0};
    }
  }

  loggedIn(): boolean {
    // We determine if we're logged in from the viewer in our redux store
    // App.tsx is careful to not call .loggedIn until we've made the initial
    // viewer request
    const state = this.store?.getState() ?? {viewer: {}};
    return !!state.viewer.viewer;
  }

  async remoteLogin(email: string, password: string, username?: string) {
    const response = await fetch(login(), {
      method: 'POST',
      body: JSON.stringify({
        email,
        username,
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if ([401, 403].indexOf(response.status) > -1) {
      return false;
    } else if (response.status !== 200) {
      this.store!.dispatch(
        displayError({
          code: 500,
          message:
            'Authentication service is down, ask your administrator to check system logs',
        })
      );
      return false;
    }
    this.tempKey = undefined;
    return true;
  }

  async changePassword(password: string, email?: string, username?: string) {
    const claims = this.store!.getState().viewer.claims;
    const originalEmail = claims?.originalEmail ?? 'local@wandb.com';
    const userUpdates: Array<{[key: string]: any}> = [
      {email: email || originalEmail, password},
    ];
    if (claims?.reset && email) {
      userUpdates.push({
        email: originalEmail,
        delete: true,
      });
    }
    try {
      const reset = await fetch(urlPrefixed('/system-admin/api/users/'), {
        method: 'PUT',
        body: JSON.stringify(userUpdates),
        headers: {
          Authorization: this.tempKey ? `Bearer ${this.tempKey}` : '',
          'Content-Type': 'application/json',
        },
      });
      if (reset.status === 401 || reset.status === 403) {
        this.store!.dispatch(
          displayError({
            code: 403,
            message:
              'Invitation code expired, ask your administrator for a new invite',
          })
        );
        return false;
      } else if (reset.status !== 200) {
        this.store!.dispatch(
          displayError({
            code: reset.status,
            message: 'Unable to create account, contact your administrator.',
          })
        );
        return false;
      }
    } catch (e) {
      console.error('error: ', e);
      this.store!.dispatch(
        displayError({
          code: 500,
          message: 'Unable to change password, try refreshing and try again',
        })
      );
      return false;
    }

    await this.remoteLogin(email || originalEmail, password, username);
    this.store!.dispatch(reloadCurrentViewer());
    return true;
  }

  async autoLogin() {
    /* Attempts to login with the default user, called from index.tsx */
    if (
      !this.loggedIn() &&
      !this._autoLoginAttempted &&
      !window.location.pathname.endsWith('/logout')
    ) {
      try {
        this._autoLoginAttempted = true;
        const success = await this.remoteLogin('local@wandb.com', 'perceptron');
        if (success) {
          console.log('Login succeeded');
          const res = await apolloClient.query({
            context: propagateErrorsContext(),
            query: ENTITY_QUERY,
            variables: {name: 'local'},
          });
          if (res.data.entity.available) {
            await apolloClient.mutate({
              context: propagateErrorsContext(),
              mutation: CREATE_ENTITY,
              variables: {name: 'local'},
            });
          }
          // Reload our viewer into redux, with reset in the claims
          this.store!.dispatch(
            reloadCurrentViewer({
              reset: true,
              originalEmail: 'local@wandb.com',
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async ensureCurrentIdToken() {
    // Use the temporary key if it exists otherwise the auth will be in an HTTP only cookie
    return this.tempKey;
  }
}

declare global {
  interface Window {
    __WB_authRedirect?: string;
  }
}

export const AUTH_REDIRECT_KEY = 'auth.redirect';

export function getAuthRedirect(): string {
  const url =
    window.__WB_authRedirect ?? localStorage.getItem(AUTH_REDIRECT_KEY) ?? '/';
  return stripOriginFromURL(url);
}

export function setAuthRedirect(url: string): void {
  const urlWithoutOrigin = stripOriginFromURL(url);
  if (!isValidAuthRedirect(urlWithoutOrigin)) {
    return;
  }
  window.__WB_authRedirect = urlWithoutOrigin;
  localStorage.setItem(AUTH_REDIRECT_KEY, urlWithoutOrigin);
}

export function clearAuthRedirect(): void {
  delete window.__WB_authRedirect;
  localStorage.removeItem(AUTH_REDIRECT_KEY);
}

export function doAuthRedirect(history?: History): string {
  const url = getAuthRedirect();
  clearAuthRedirect();
  if (history != null) {
    history.replace(url);
  } else {
    window.location.replace(urlPrefixed(url));
  }
  return url;
}

const INVALID_REDIRECT_PATHS = [
  '/login',
  '/logout',
  '/signup',
  '/change-password',
];

function isValidAuthRedirect(url: string): boolean {
  for (const path of INVALID_REDIRECT_PATHS) {
    if (url.startsWith(path)) {
      return false;
    }
  }
  return true;
}

const ACCESS_TOKEN_COOKIE_KEY = 'access_token';

export function setAccessTokenCookie(token: string): void {
  setCookie(ACCESS_TOKEN_COOKIE_KEY, token);
}

export function unsetAccessTokenCookie(): void {
  unsetCookie(ACCESS_TOKEN_COOKIE_KEY);
}

export function setAccessTokenCookieFromQS(): void {
  const {accessToken} = queryString.parse(window.location.search);
  if (accessToken && isOnReportView()) {
    setAccessTokenCookie(accessToken as string);
  } else {
    unsetAccessTokenCookie();
  }
}

// (un)set access token cookie on page load
setAccessTokenCookieFromQS();

// (un)set access token cookie on page change
// THIS IS NECESSARY SO USERS CAN'T ACCESS OTHER PAGES IN PRIVATE
// PROJECTS AFTER LOADING A REPORT WITH AN ACCESS TOKEN
globalHistory.listen(() => {
  setAccessTokenCookieFromQS();
});
