import * as Normalize from '../normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.MarkdownBlockObjSchema>(
  'markdown-block',
  (whole, ctx) => whole
);

export const denormalize = Normalize.denormFn<Types.MarkdownBlockObjSchema>(
  (part, ctx) => part
);
