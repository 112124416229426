import {WBIcon} from '@wandb/ui';
import {Button, Input} from 'semantic-ui-react';
import styled from 'styled-components';
import * as globals from '../css/globals.styles';
import {GLOBAL_COLORS} from '../util/colors';
import LegacyWBIcon from './elements/LegacyWBIcon';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  background-color: ${globals.white};
  color: ${globals.textPrimary};
`;

const containerStyle = `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 34px;
  flex-shrink: 0;
`;

export const MainContainer = styled.div`
  ${containerStyle};

  background-color: ${globals.white};
  color: ${globals.textPrimary};
  width: 400px;
`;

export const SideContainer = styled.div`
  ${containerStyle};

  background-color: ${globals.gray100};
  color: ${globals.textPrimary};
  width: 320px;
`;

export const Header = styled.div`
  padding: 20px;
  padding-top: 32px;
  text-align: center;
`;

export const SideHeader = styled.div`
  padding-top: 32px;
  padding-bottom: 4px;
  text-align: left;
  padding-left: 0px;
  align-self: start;
`;

export const SideButton = styled.div`
  position: relative;
`;

export const SideTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  line-height: 20px;
  font-size: 16px;
  align-self: start;
  margin-left: 32px;
  margin-bottom: 4px;
  color: ${globals.gray600};
`;

export const BillingPeriodPicker = styled.div`
  &&&&& {
    margin-bottom: 16px;

    .button {
      border: 0;
      margin-right: 0;
      width: 108px;

      &.active {
        background-color: ${globals.primary};
        color: ${globals.white};
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

export const SeatsPicker = styled.div`
  margin-bottom: 4px;
  align-self: start;
  margin-left: 28px;
`;

export const PricingTable = styled.div`
  width: 240px;
  margin-bottom: 16px;
`;
export const PricingTableRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const PricingTableHeader = styled.div`
  font-family: ${globals.fontName};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 24px;
`;

export const PricingTableBody = styled.div`
  font-family: ${globals.fontName};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 12px;
  flex-direction: row;
`;

export const PricingTableFeatureRow = styled.div`
  font-size: 14px;
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const PricingTableDescription = styled.div`
  display: flex;
  font-family: ${globals.fontName};
  font-size: 14px;
  font-style: italic;
  margin-top: 24px;
  line-height: 1.3em;
  align-text: left;
  color: ${globals.gray700};
`;

export const PricingTableFooter = styled.div`
  display: flex;
  font-family: ${globals.fontName};
  font-size: 14px;
  margin-top: 24px;
  margin-left: 12px;
  margin-right: 12px;
  line-height: 1.3em;
  text-align: center;
  color: ${globals.gray700};
`;

export const SeatsInput = styled(Input)`
  &&&&& {
    width: 52px;
    margin: 0 4px;

    input {
      padding: 4px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: auto;
      }
    }
  }
`;

export const PlanList = styled.div`
  margin-top: 16px;
`;

export const PlanItem = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 24px;
  position: relative;
  line-height: 1.3em;
  font-size: 14px;
`;

export const PlanListFooter = styled.div`
  display: flex;
  font-family: ${globals.fontName};
  font-size: 16px;
  margin-left: 12px;
  margin-top: 28px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.3em;
  color: ${globals.gray700};
`;

export const PeriodPickerLink = styled.span`
  color: ${GLOBAL_COLORS.linkBlue.toString()};
  cursor: pointer;
  align-self: flex-end;
  margin-right: 24px;
  margin-bottom: 24px;
  flex-direction: column;
  font-size: 14px;
`;

export const UsagePricingLink = styled.span`
  color: ${GLOBAL_COLORS.linkBlue.toString()};
  cursor: pointer;
  align-self: flex-end;
  margin-right: 24px;
  margin-bottom: 24px;
  flex-direction: column;
`;

export const ContactSalesButton = styled.div`
  margin-bottom: 12px;
  border-radius: 5px;
  border: 1px solid ${globals.gray400};

  .action-button {
    border: none;
    background: ${globals.gray100};
    color: black;
    &:hover {
      background: ${globals.gray400};
      color: black;
    }
  }
`;

export const CheckIcon = styled(WBIcon)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContactButton = styled(Button)`
  width: 100%;
`;

export const BackButton = styled(Button)`
  && {
    background: transparent;
    border: none;
    outline: none;
    padding-left: 0px;
  }
`;

export const BackIcon = styled(LegacyWBIcon)`
  &&&&&& {
    font-size: 32px;
  }
`;
