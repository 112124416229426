import * as _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import {useCallback} from 'react';
import {ID} from '@wandb/cg/browser/utils/string';
import PanelError from '../components/elements/PanelError';
import {ErrorBoundary} from '../components/ErrorBoundary';
import * as PanelBarChart from '../components/PanelBarChart';
import * as PanelCodeComparer from '../components/PanelCodeComparer';
import * as PanelConfusionMatrix from '../components/PanelConfusionMatrix';
import * as PanelDataFrames from '../components/PanelDataFrames';
import {PanelExportRef} from '../components/PanelImageExportModal';
import * as PanelMarkdown from '../components/PanelMarkdown';
import * as PanelMediaBrowser from '../components/PanelMediaBrowser';
import * as PanelMultiRunTable from '../components/PanelMultiRunTable';
import * as PanelParallelCoord from '../components/PanelParallelCoord';
import * as PanelParameterImportance from '../components/PanelParameterImportance';
import * as PanelRunComparer from '../components/PanelRunComparer';
import * as PanelRunsLinePlot from '../components/PanelRunsLinePlot';
import * as PanelScalarChart from '../components/PanelScalarChart';
import * as PanelScatterPlot from '../components/PanelScatterPlot';
import * as PanelVega from '../components/PanelVega';
import * as PanelVega2 from '../components/PanelVega2';
import * as PanelVega3 from '../components/PanelVega3';
import * as PanelWeave from '../components/PanelWeave';
import {PanelConfigSpec} from '../components/property-editors/property-editors';
import * as TableExport from '../components/TableExport';
import {
  InjectedRunsDataProps,
  RunsDataQuery,
  withRunsDataLoader,
} from '../containers/RunsDataLoader';
import {useRunsData, useRunSetsQuery} from '../state/runs/hooks';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import * as FilterActions from '../state/views/filter/actions';
import * as ViewHooks from '../state/views/hooks';
import * as PanelActions from '../state/views/panel/actions';
import * as PanelViewTypes from '../state/views/panel/types';
import {Ref as PanelRef} from '../state/views/panel/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {Unpack} from '../types/base';
import {RunHistoryKeyInfo} from '../types/run';
import * as Filter from '../util/filters';
import {useDeepEqualValue} from '../util/hooks';
import {PlotFontSize} from '../util/plotHelpers';
import {captureError} from '../util/integrations';
import * as Query from '../util/queryts';
import * as RunHelpers from '../util/runhelpers';
import * as Run from '../util/runs';
import * as SM from '../util/selectionmanager';
import {Table} from './csv';
import {runMediaPanelMigrations} from './media';
import * as PanelSettings from './panelsettings';
import makeComp from './profiler';
import {RunColorConfig} from './section';

const getPanelSpecs = memoize(() => [
  PanelConfusionMatrix.Spec,
  PanelDataFrames.Spec,
  PanelMediaBrowser.Spec,
  PanelRunsLinePlot.Spec,
  PanelMultiRunTable.Spec,
  PanelMarkdown.Spec,
  PanelScalarChart.Spec,
  PanelVega.Spec,
  PanelVega2.Spec,
  PanelVega3.Spec,
  PanelScatterPlot.Spec,
  PanelWeave.Spec,
  PanelParallelCoord.Spec,
  PanelRunComparer.Spec,
  PanelCodeComparer.Spec,
  PanelBarChart.Spec,
  PanelParameterImportance.Spec,
  // include fake TableExport Run Selector spec here so we can access useTableData
  TableExport.Spec,
]);

// Union type of all the PanelSpecs

type AllPanelSpec = Unpack<ReturnType<typeof getPanelSpecs>>;

// Helpers that extract the ConfigType used in a Spec
type GetSpecType<S, T> = S extends {type: T} ? S : never;
// TODO: combine?
type ConfigTypeFromPanelSpec<S> = S extends PanelSpec<any, infer C> ? C : never;

// Allowed panel types
export type PanelType = AllPanelSpec['type'];

// Generic to derive JSON representation of a given panel from Spec
export interface PanelWithConfig<T extends PanelType> {
  __id__: string; // This is a permanent unique ID (used to associate panels with comments). Note: don't confuse this with the ref ID, which is ephemeral.
  viewType: T;
  config: ConfigTypeFromPanelSpec<GetSpecType<AllPanelSpec, T>>;
  query?: any; // legacy, used by OpenAI dashboard
}
// Helper
type Dist<T extends PanelType> = T extends {} ? PanelWithConfig<T> : never;

// Valid JSON representation for all available Panels
export type Panel = Dist<PanelType> & {
  key?: string;
};

export type PanelConfig = Panel['config'];

// Helper to create a typed panel with a given layout.
export function layedOutPanel<T extends PanelType>(
  p: PanelWithConfig<T> & PanelLayout
): PanelWithConfig<T> & PanelLayout {
  return p;
}

export function getPanelSpec(panelType: PanelType) {
  for (const s of getPanelSpecs()) {
    if (s.type === panelType) {
      return s;
    }
  }
  throw new Error('invalid panel type');
}

type TransformQueryFn = (
  query: Query.Query,
  config: PanelConfig
) => RunsDataQuery;

type UseTableDataFn = (
  query: Query.Query,
  config: PanelConfig
) => {table: Table; loading: boolean};

export function transformQuery(
  query: Query.Query,
  panel: Panel
): RunsDataQuery {
  const s = getPanelSpec(panel.viewType);
  // Here we know that s.transformQuery can be called with panel.config, because
  // spec.type === panel.viewType. However, there's no way to get typescript
  // to narrow a union to an individual member, so we cast.
  const transformFn = s.transformQuery as TransformQueryFn;
  return transformFn(query, panel.config);
}

export function useTableData(
  query: Query.Query,
  panel: Panel
): {table: Table; loading: boolean} {
  const s = getPanelSpec(panel.viewType);
  const useTableDataFn = s.useTableData as UseTableDataFn;
  return useTableDataFn(query, panel.config);
}

export const PanelComp = makeComp(
  (
    props: BasePanelProps & {
      panel: Panel;
      panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
      updateConfig(newConfig: PanelConfig): void;
    }
  ) => {
    const {panel, updateConfig, ...passThroughProps} = props;

    const {Component: PanelComponent, normalizeConfigUpdate} = getPanelSpec(
      panel.viewType
    );

    const normalizeAndUpdateConfig = useCallback(
      (newConfig: PanelConfig) => {
        const normalizedConfig =
          normalizeConfigUpdate != null
            ? normalizeConfigUpdate(newConfig as any)
            : newConfig;
        updateConfig(normalizedConfig);
      },
      [updateConfig, normalizeConfigUpdate]
    );

    return (
      <PanelComponent
        {...passThroughProps}
        // Here we know that s.transformQuery can be called with panel.config, because
        // spec.type === panel.viewType. However, there's no way to get typescript
        // to narrow a union to an invidual member, so we just cast to any.
        config={panel.config as any}
        updateConfig={normalizeAndUpdateConfig as (config: any) => void}
      />
    );
  },
  {id: 'PanelComp'}
);

export const PanelCompWithLoader = withRunsDataLoader(PanelComp);
type PanelCompProps = Parameters<typeof PanelComp>[0];
type PanelCompReduxProps = Omit<
  PanelCompProps,
  | 'panel'
  | 'updateConfig'
  | 'data'
  | 'loading'
  | 'runsDataQuery'
  | 'query'
  | 'pageQuery'
  | 'customRunColors'
> & {
  panelRef: PanelViewTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  initialConfigState?: {[key: string]: any};
};

type MetricName = string;
type DefinedMetric = {
  '1': MetricName;
  '5'?: number; // 1-index of name of defined metric
  '6'?: number[]; // options
};

export type MetricsDict = {
  [key: string]: string;
};

export function findDefinedMetrics(
  definedMetrics: DefinedMetric[]
): MetricsDict {
  const keyMap: MetricsDict = {};
  definedMetrics.forEach(definedMetric => {
    const metric = definedMetric['1'];
    const baseMetricIndex = definedMetric['5'];
    if (metric == null || baseMetricIndex == null) {
      return;
    }
    const baseMetric = definedMetrics[baseMetricIndex - 1]['1'];
    const removedBackSlashMetric = metric.replace(/\\./g, '.');
    if (
      metric.includes('\\.') &&
      definedMetrics.some(m => m['1'] === removedBackSlashMetric)
    ) {
      keyMap[removedBackSlashMetric] = baseMetric;
    } else {
      keyMap[metric] = baseMetric;
    }
  });
  return keyMap;
}

export const PanelCompRedux = makeComp(
  (props: PanelCompReduxProps) => {
    let panel = ViewHooks.usePart(props.panelRef);

    React.useEffect(() => {
      window.analytics.track('Panel Rendered', {
        viewType: panel.viewType,
      });
    }, [panel.viewType]);

    if (
      props.panelSettings != null &&
      panel.viewType === 'Run History Line Plot'
    ) {
      const panelContainsSystemMetrics =
        panel.config.metrics &&
        !!panel.config.metrics.find(metric => metric.startsWith('system/'));

      let xAxis;
      if (panel.config.xAxis != null) {
        // the panel's own saved xAxis always gets top priority, if it
        // has one:
        xAxis = panel.config.xAxis;
      } else if (
        panelContainsSystemMetrics &&
        ['_runtime', '_timestamp'].indexOf(props.panelSettings.xAxis) === -1
      ) {
        // the panel doesn't have its own xAxis, but it can't use the global
        // xAxis because it isn't compatible with system metrics -- fall back
        // to '_runtime'
        xAxis = '_runtime';
      } else if (
        panel.config.startingXAxis != null &&
        !props.panelSettings.xAxisActive
      ) {
        // if the global xAxis is not active use default
        xAxis = panel.config.startingXAxis;
      } else {
        // the panel doesn't have its own xAxis -- it can use the global
        // xAxis instead:
        xAxis = props.panelSettings.xAxis;
      }

      let xAxisMin;
      if (panel.config.xAxisMin != null) {
        xAxisMin = panel.config.xAxisMin;
      } else if (xAxis === props.panelSettings.xAxis) {
        // we can only use the global xAxisMin if the global xAxis matches the
        // one we're actually using for this panel:
        xAxisMin = props.panelSettings.xAxisMin;
      }

      let xAxisMax;
      if (panel.config.xAxisMax != null) {
        xAxisMax = panel.config.xAxisMax;
      } else if (xAxis === props.panelSettings.xAxis) {
        // we can only use the global xAxisMax if the global xAxis matches the
        // one we're actually using for this panel:
        xAxisMax = props.panelSettings.xAxisMax;
      }
      // TODO: change smoothing settings to be handled here
      // haven't done it because we need to handle cases where settings are already set
      // const smoothingWeight = panel.config.smoothingWeight != null ? panel.config.smoothingWeight : props.panelSettings.smoothingWeight
      // const smoothingType = panel.config.smoothingType != null ? panel.config.smoothingType : props.panelSettings.smoothingType

      panel = {
        ...panel,
        config: {
          ...props.panelSettings,
          ...panel.config,
          xAxis,
          xAxisMin,
          xAxisMax,
          // See above TODO about smoothing settings
          // smoothingWeight: smoothingWeight,
          // smoothingType: smoothingType
        },
      };
    }

    panel = useDeepEqualValue(panel);

    const updateConfig = ViewHooks.useViewAction(
      props.panelRef,
      PanelActions.updateConfig
    );

    const customRunColors = ViewHooks.useWhole(props.customRunColorsRef);

    const runSet = ViewHooks.usePart(props.runSetRefs[0]);
    const convertSelectionsToFilters = ViewHooks.useViewAction(
      runSet.filtersRef,
      FilterActions.selectionsToFilters
    );

    const query = useRunSetsQuery(props.runSetRefs);
    const panelQuery = React.useMemo(
      () => transformQuery(query, panel),
      [query, panel]
    );
    const runsDataQuery = useRunsData(panelQuery);

    const logError = React.useCallback(
      (error: Error) => {
        window.analytics.track('Panel Error', {
          viewType: panel.viewType,
          errorMessage: error.message,
          errorName: error.name,
          errorStack: error.stack,
        });
        captureError(error, 'Panel ErrorBoundary error');
      },
      [panel.viewType]
    );

    return (
      <ErrorBoundary onError={logError} renderError={renderPanelError}>
        <PanelComp
          {...props}
          panel={panel}
          updateConfig={updateConfig}
          customRunColors={customRunColors}
          pageQuery={query}
          query={panelQuery}
          loading={runsDataQuery.loading}
          data={runsDataQuery.data}
          runsDataQuery={runsDataQuery}
          convertSelectionsToFilters={convertSelectionsToFilters}
          panelExportRef={props.panelExportRef}
        />
      </ErrorBoundary>
    );
  },
  {id: 'PanelCompRedux'}
);

function renderPanelError() {
  return (
    <div style={{padding: 40}}>
      <PanelError
        message={
          <div>
            Oops, something went wrong. If this keeps happening, message
            support@wandb.com with a link to this page
          </div>
        }
      />
    </div>
  );
}

type BasePanelProps = {
  configMode: boolean;
  initialConfigState?: {[key: string]: any};
  currentHeight: number;
  svg?: boolean;

  // This is undefined in render mode, but always defined in edit
  // mode
  // TODO: don't pass this in, let panels fetch it themselves when
  // they need it
  keyInfo?: RunHistoryKeyInfo;
  dimensions: {w: number; h: number};
  pageQuery: Query.Query;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  customRunColors?: RunColorConfig;
  searchQuery?: string; // Exists if we're searching panels, used to fuzzyHighlight panel title
  runSetRefs: RunSetViewTypes.Ref[];
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  convertSelectionsToFilters?(
    selections: SM.PanelSelections,
    axes?: string[]
  ): void;
  onContentHeightChange?(h: number): void;
} & InjectedRunsDataProps;

export type PanelProps<ConfigType> = BasePanelProps & {
  config: ConfigType;
  updateConfig(config: Partial<ConfigType>): void;
};

export type PanelComponentType<ConfigType> = React.ComponentType<
  PanelProps<ConfigType>
>;

type PanelExportType = 'image' | 'csv' | 'api';

export interface PanelSpec<PanelTypeT, ConfigType> {
  type: PanelTypeT;
  Component: PanelComponentType<ConfigType>;

  // If true this panel doesn't have an editor modal
  noEditMode?: boolean;

  // can be exported
  exportable?: {[t in PanelExportType]?: boolean};

  configSpec?: PanelConfigSpec;

  // use SingleChartInspectorContainer instead of normal config editor, for transitioning to Inspector everything
  useInspector?: boolean;

  // icon name for <WBIcon>
  icon?: string;

  normalizeConfigUpdate?: (config: ConfigType) => ConfigType;

  getTitleFromConfig?: (config: ConfigType) => string;
  transformQuery: (query: Query.Query, config: ConfigType) => RunsDataQuery;
  useTableData?: (
    query: Query.Query,
    config: ConfigType
  ) => {table: Table; loading: boolean};
}

export function isExportable(spec: PanelSpec<any, any>): boolean {
  return (
    spec.exportable != null &&
    Object.values(spec.exportable).some(v => v === true)
  );
}

export function isExportableAs(
  spec: PanelSpec<any, any>,
  exportType: PanelExportType
): boolean {
  return spec.exportable != null && spec.exportable[exportType] === true;
}

export interface LayoutCoords {
  x: number;
  y: number;
}

export interface LayoutDimensions {
  w: number;
  h: number;
}

export type LayoutParameters = LayoutCoords & LayoutDimensions;

export interface PanelLayout {
  layout: LayoutParameters;
}

export type LayedOutPanel = Panel & PanelLayout;

export type LayedOutPanelWithRef = Panel & PanelLayout & {ref: PanelRef};

export type PanelGroupConfig = LayedOutPanel[];

export type PanelGroupId = string;

export interface PanelGroup {
  name: string;
  defaults: any[];
  // TBoard stores this in PanelGroup (view), but Reports store them outside the view.
  globalConfig?: PanelSettings.Settings;
  config: PanelGroupConfig;
}

export interface Config {
  views: {[id: string]: PanelGroup};
  tabs: PanelGroupId[];
}

export const EMPTY: Config = {
  views: {},
  tabs: [],
};

export const EMPTY_SINGLE_TAB: Config = {
  views: {0: {name: 'Panels', defaults: [], config: []}},
  tabs: ['0'],
};

export function configFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): Config | null {
  if (
    !_.isObject(json.views) ||
    !_.isArray(json.tabs) ||
    json.tabs.length === 0
  ) {
    return null;
  }
  // TODO: We should validate everything but we don't, we assume tabs
  // and views are correct.
  const parsedViews: {[id: string]: PanelGroup} = {};
  _.forEach(
    json.views,
    (pg, viewID) =>
      (parsedViews[viewID] = panelGroupFromJSON(pg, defaultViewType))
  );
  return {
    views: parsedViews,
    tabs: json.tabs,
  };
}

export function panelGroupFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): PanelGroup {
  // This is really cheating. It doesn't do any validation, just fixes up
  // defaultViewTypes
  json.config.forEach((panel: any) => {
    if (panel.viewType == null) {
      panel.viewType = defaultViewType;
    } else if (
      panel.viewType === 'Audio' ||
      panel.viewType === 'Image Browser' ||
      panel.viewType === 'Tables' ||
      panel.viewType === 'Images'
    ) {
      panel.viewType = 'Media Browser';
    }
    if (panel.config == null) {
      panel.config = {};
    }
  });
  // filter out legacy panels -- no more Graph panels, now the info is under the Model tab in the Run page
  let config = json.config.filter((p: any) => p.viewType !== 'Graph');
  config = config.map(panelFromJSON);
  return {...json, config};
}

export interface PanelTemplate {
  yAxis: string;
  regex: RegExp;
  key: string;
  percentage: boolean;
}

export const systemPanelTemplates: {[key: string]: PanelTemplate} = {
  'system/cpu-V2': {
    yAxis: 'CPU Utilization (%)',
    regex: /system\/cpu/,
    key: 'system/cpu-V2',
    percentage: true,
  },
  'system/tpu-V2': {
    yAxis: 'TPU Utilization (%)',
    regex: /system\/tpu/,
    key: 'system/tpu-V2',
    percentage: true,
  },
  'system/memory-V2': {
    yAxis: 'System Memory Utilization (%)',
    regex: /system\/memory/,
    key: 'system/memory-V2',
    percentage: true,
  },
  'system/proc.memory.rssMB-V2': {
    yAxis: 'Process Memory In Use (non-swap) (MB)',
    regex: /system\/proc\.memory\.rssMB/,
    key: 'system/proc.memory.rssMB-V2',
    percentage: false,
  },
  'system/proc.memory.percent-V2': {
    yAxis: 'Process Memory In Use (non-swap) (%)',
    regex: /system\/proc\.memory\.percent/,
    key: 'system/proc.memory.percent-V2',
    percentage: true,
  },
  'system/proc.memory.availableMB-V2': {
    yAxis: 'Process Memory Available (non-swap) (MB)',
    regex: /system\/proc\.memory\.availableMB/,
    key: 'system/proc.memory.availableMB-V2',
    percentage: false,
  },
  'system/proc.cpu.threads-V2': {
    yAxis: 'Process CPU Threads In Use',
    regex: /system\/proc\.cpu\.threads/,
    key: 'system/proc.cpu.threads-V2',
    percentage: false,
  },
  'system/disk-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk/,
    key: 'system/disk-V2',
    percentage: true,
  },
  'system/network-V2': {
    yAxis: 'Network Traffic (bytes)',
    regex: /system\/network.(recv|sent)/,
    key: 'system/network-V2',
    percentage: false,
  },
  'system/gpu.gpu-V2': {
    yAxis: 'GPU Utilization (%)',
    regex: /system\/gpu\.\d+\.gpu/,
    key: 'system/gpu.gpu-V2',
    percentage: true,
  },
  'system/gpu.temp-V2': {
    yAxis: 'GPU Temp (℃)',
    regex: /system\/gpu\.\d+\.temp/,
    key: 'system/gpu.temp-V2',
    percentage: false,
  },
  'system/gpu.memory-V2': {
    yAxis: 'GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.\d+\.memory$/,
    key: 'system/gpu.memory-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated-V2': {
    yAxis: 'GPU Memory Allocated (%)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.powerPercent-V2': {
    yAxis: 'GPU Power Usage (%)',
    regex: /system\/gpu\.\d+\.powerPercent$/,
    key: 'system/gpu.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.powerWatts-V2': {
    yAxis: 'GPU Power Usage (W)',
    regex: /system\/gpu\.\d+\.powerWatts$/,
    key: 'system/gpu.powerWatts-V2',
    percentage: false,
  },
  'system/gpu.process.gpu-V2': {
    yAxis: 'Process GPU Utilization (%)',
    regex: /system\/gpu\.process\.\d+\.gpu/,
    key: 'system/gpu.process.gpu-V2',
    percentage: true,
  },
  'system/gpu.process.temp-V2': {
    yAxis: 'Process GPU Temp (℃)',
    regex: /system\/gpu\.process\.\d+\.temp/,
    key: 'system/gpu.process.temp-V2',
    percentage: false,
  },
  'system/gpu.process.memory-V2': {
    yAxis: 'Process GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.\d+\.memory$/,
    key: 'system/gpu.process.memory-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated-V2': {
    yAxis: 'Process GPU Memory Allocated (%)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.process.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.process.powerPercent-V2': {
    yAxis: 'Process GPU Power Usage (%)',
    regex: /system\/gpu\.process\.\d+\.powerPercent$/,
    key: 'system/gpu.process.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.process.powerWatts-V2': {
    yAxis: 'Process GPU Power Usage (W)',
    regex: /system\/gpu\.process\.\d+\.powerWatts$/,
    key: 'system/gpu.process.powerWatts-V2',
    percentage: false,
  },
};

export function panelFromJSON(json: {
  viewType: string;
  config: any;
  query: any;
  __id__?: string;
}): Panel {
  /**
   * The primary purpose of this function is to take legacy settings for plots and update them
   */

  // Doesn't fully check all panel types and options, just does some old data
  // conversions where necessary.
  let panel = json as Panel;

  // LinePlot is an old view type now merged with Run History Line Plot
  if (json.viewType === 'LinePlot') {
    if (json.config.lines) {
      json.config.metrics = json.config.lines;
    }
    json.config.singleRun = true;
    json.viewType = 'Run History Line Plot';
  }

  const viewType = json.viewType;

  if (viewType === 'Run History Line Plot') {
    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key != null) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    // expression used to be a string
    if (json.config.expression != null) {
      json.config.expressions = [json.config.expression];
      json.config.expression = undefined;
    }

    if (json.config.groupLine != null) {
      json.config.groupAgg = json.config.groupLine;
      json.config.groupLine = undefined;
    }

    if (json.config.overrideLineTitles != null) {
      json.config.overrideSeriesTitles = json.config.overrideLineTitles;
      json.config.overrideLineTitles = undefined;
    }

    const config = json.config as PanelRunsLinePlot.RunsLinePlotConfig;
    if (config.legendFields != null) {
      config.legendFields = config.legendFields.map(Run.fixConfigKeyString);
    }
    if (config.groupBy != null && config.groupBy !== 'None') {
      config.groupBy = Run.fixConfigKey({
        section: 'config',
        name: config.groupBy,
      }).name;
    }

    const {
      'system/gpu.powerPercent-V2': powerPercent,
      'system/gpu.powerWatts-V2': powerWatts,
      'system/gpu.process.powerPercent-V2': processPercent,
      'system/gpu.process.powerWatts-V2': processWatts,
    } = systemPanelTemplates;

    // The system metrics panels got saved with bad titles, so we patch them up
    // if they look incorrect.
    switch (config.chartTitle) {
      case powerPercent.yAxis:
        if (_.every(config.metrics, m => m.match(powerWatts.regex))) {
          config.chartTitle = powerWatts.yAxis;
        }
        break;
      case processPercent.yAxis:
        if (_.every(config.metrics, m => m.match(processWatts.regex))) {
          config.chartTitle = processWatts.yAxis;
        }
        break;
    }

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Scatter Plot') {
    const config = json.config;
    if (config.xAxis != null) {
      config.xAxis = Run.fixConfigKeyString(config.xAxis);
    }
    if (config.yAxis != null) {
      config.yAxis = Run.fixConfigKeyString(config.yAxis);
    }
    if (config.zAxis != null) {
      config.zAxis = Run.fixConfigKeyString(config.zAxis);
    }
    if (config.minColor && config.maxColor) {
      config.customGradient = [
        {offset: 0, color: config.minColor},
        {offset: 100, color: config.maxColor},
      ];
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Parallel Coordinates Plot') {
    const config = json.config;
    if (config.dimensions != null) {
      config.dimensions = config.dimensions.map(Run.fixConfigKeyString);
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Media Browser') {
    const config = json.config;

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: runMediaPanelMigrations(config),
    };
  } else if (viewType === 'Bar Chart') {
    const config = json.config;

    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Vega2') {
    // migrate the old query format to the new
    const config = json.config as PanelVega2.VegaPanel2Config;
    let userQuery = config.userQuery;
    const queryFields = userQuery?.queryFields ?? [];
    let fieldSettings = config.fieldSettings ?? {};

    const projectField = queryFields.find(qf => qf.name === 'project');
    const runsField = projectField?.fields.find(qf => qf.name === 'runs');
    const runSetsField = queryFields.find(qf => qf.name === 'runSets');
    if (runsField != null && runSetsField == null) {
      /* eslint-disable no-template-curly-in-string */
      userQuery = {
        queryFields: [
          {
            name: 'runSets',
            args: [{name: 'runSets', value: '${runSets}'}],
            fields: runsField.fields,
          },
        ],
      };
      /* eslint-enable no-template-curly-in-string */
      fieldSettings = Object.fromEntries(
        Object.entries(fieldSettings).map(([key, value]) => {
          if (value.startsWith('project_runs_')) {
            return [key, 'runSets_' + value.substring(13)];
          }
          return [key, value];
        })
      );
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: {
        ...config,
        userQuery,
        fieldSettings,
      },
    };
  }
  if (json.query != null) {
    json.query.filters = Filter.fixFilter(json.query.filters);
  }
  return panel;
}

// This a unique identifier for panels to figure out which panels are pinned
// TODO: This needs to work for all plot types when we switch to the new PanelBank
// NOTE: Key resolution rules are:
//   - if the panel has an explicit `key` property, this always takes precedence
//     (used for multi-metric default panels)
//   - if the panel is a vega panel, use its `historyFieldSettings.key` as the key
//   - if the panel has only one metric, that metric is the key (the panel is
//     presumed to be a single-metric default panel)
//   - if the panel has multiple metrics (with no explicit `key` property), it
//     has no key (it's presumed to be a custom chart)
export function getKey(panel: Panel): string | null {
  if (panel.key) {
    return panel.key;
  }

  if (panel.viewType === 'Run History Line Plot') {
    return panel.config.metrics && panel.config.metrics.length === 1
      ? panel.config.metrics[0]
      : null;
  } else if (panel.viewType === 'Vega') {
    if (
      panel.config.historyFieldSettings != null &&
      panel.config.historyFieldSettings.key != null
    ) {
      return panel.config.historyFieldSettings.key;
    }
    return null;
  } else if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      null;

    return ks && ks.length === 1 ? ks[0] : null;
  } else if (panel.viewType === 'Weave') {
    // Use the Weave panel's last pick op's key. This path should
    // be made more specific in the future.
    return PanelWeave.getKeyOfWeavePanel(panel);
  } else if (panel.viewType === 'Parallel Coordinates Plot') {
    return null;
  } else if (panel.viewType === 'Scatter Plot') {
    return null;
  } else if (panel.viewType === 'Bar Chart') {
    return null;
  }
  return null;
}

export function getMetrics(panel: Panel): string[] {
  if (panel.viewType === 'Run History Line Plot') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Vega') {
    if (panel.config.historyFieldSettings != null) {
      return Object.values(panel.config.historyFieldSettings);
    }
    return [];
  } else if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      [];

    return ks;
  } else if (panel.viewType === 'Parallel Coordinates Plot') {
    return panel.config.columns != null
      ? panel.config.columns.map(c => c.accessor || '')
      : [];
  } else if (panel.viewType === 'Scatter Plot') {
    const keys = _.compact([
      panel.config.xAxis,
      panel.config.yAxis,
      panel.config.zAxis,
    ]);
    return keys;
  } else if (panel.viewType === 'Bar Chart') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Weave') {
    const key = PanelWeave.getKeyOfWeavePanel(panel);
    if (key != null) {
      return [key];
    }
  }

  return [];
}

export const PANEL_GRID_WIDTH = 12;

function panelGridFindNextPanelLoc(
  layouts: LayoutParameters[],
  gridWidth: number,
  panelWidth: number
) {
  const columnBottoms = new Array(gridWidth).fill(0);
  for (const panel of layouts) {
    const panelBottom = panel.y + panel.h;
    for (let x = panel.x; x < panel.x + panel.w; x++) {
      columnBottoms[x] = Math.max(columnBottoms[x], panelBottom);
    }
  }
  const candidates = [];
  for (let x = 0; x < gridWidth - panelWidth + 1; x++) {
    candidates.push(_.max(columnBottoms.slice(x, x + panelWidth)));
  }
  // argmin
  let min = candidates[0];
  let argmin = 0;
  for (let x = 1; x < candidates.length; x++) {
    if (candidates[x] < min) {
      min = candidates[x];
      argmin = x;
    }
  }
  return {x: argmin, y: min};
}

export function getPanelGridAddPanelLayout(panelConfigs: PanelGroupConfig) {
  return {
    ...panelGridFindNextPanelLoc(
      panelConfigs.map(c => c.layout),
      PANEL_GRID_WIDTH,
      6
    ),
    w: 6,
    h: 2,
  };
}

export function isGrouped(
  query: Query.Query,
  config: PanelRunsLinePlot.RunsLinePlotConfig | PanelBarChart.BarChartConfig
) {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  return (
    RunHelpers.isGrouped(query) || config.aggregate || config.aggregateMetrics
  );
}

export function isSingleRun(props: {pageQuery: Query.Query}) {
  // runName is set in the query only if we are looking at a singleRun page
  return props.pageQuery.runName != null;
}

export function getDefaultFontSizeFromHeight(
  panelHeight: number
): PlotFontSize {
  if (panelHeight < 300) {
    return 'small';
  } else if (panelHeight < 600) {
    return 'medium';
  } else {
    return 'large';
  }
}

export function getFontSize(
  fontSize: PlotFontSize | 'auto',
  panelHeight: number
): PlotFontSize {
  if (fontSize === 'auto') {
    return getDefaultFontSizeFromHeight(panelHeight);
  } else {
    return fontSize;
  }
}
