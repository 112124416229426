import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import classnames from 'classnames';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Icon} from 'semantic-ui-react';
import {
  Artifact,
  useArtifactCollectionQuery,
  useDeleteArtifactSequenceMutation,
  useMoveArtifactCollectionMutation,
  useUpdateArtifactCollectionMutation,
} from '../generated/graphql';
import makeComp from '../util/profiler';
import {assertUnreachable} from '../util/types';
import * as Urls from '../util/urls';
import * as S from './ArtifactSidebar.styles';
import {
  DeleteArtifactModal,
  MoveArtifactModal,
  RenameArtifactModal,
} from './ArtifactSidebarActions';
import {ArtifactCompareButton} from './ArtifactSidebarVersion';
import LegacyWBIcon from './elements/LegacyWBIcon';

interface ArtifactMin {
  id: string;
  name: string;
}

interface ArtifactHeaderPopupDropdownProps {
  onRenameArtifact(): void;
  onMoveArtifact(): void;
  onDeleteArtifact(): void;
}

const ArtifactHeaderPopupDropdown = makeComp(
  (props: ArtifactHeaderPopupDropdownProps) => {
    const {onRenameArtifact, onMoveArtifact, onDeleteArtifact} = props;
    const options = useMemo(() => {
      const opts: WBMenuOption[] = [
        {
          value: 'edit',
          name: 'Rename',
          icon: 'edit',
        },
        {
          value: 'move',
          name: 'Move',
          icon: 'move',
        },
        {
          value: 'delete',
          name: 'Delete',
          icon: 'delete',
        },
      ];
      return opts;
    }, []);

    type optionType = 'edit' | 'move' | 'delete';
    const optionRouter = (opt: optionType) => {
      switch (opt) {
        case 'edit':
          return onRenameArtifact();
        case 'move':
          return onMoveArtifact();
        case 'delete':
          return onDeleteArtifact();
      }
      return assertUnreachable(opt);
    };

    return (
      <WBPopupMenuTrigger
        options={options}
        onSelect={value => {
          optionRouter(value as optionType);
        }}>
        {({anchorRef, setOpen}) => (
          <S.EllipsesMenuButton
            className="row-actions-button"
            name="overflow"
            title="menu"
            ref={anchorRef}
            onClick={() => setOpen(true)}
          />
        )}
      </WBPopupMenuTrigger>
    );
  },
  {id: 'ArtifactHeaderPopupDropdown'}
);

const ArtifactItemVersion = makeComp<
  Pick<Artifact, 'commitHash' | 'digest' | 'state'> & {
    to: string;
    isSelected: boolean;
    aliases: Array<{alias: string}>;
    isCompared: boolean;
    showCompare: boolean;
    onCompareClick: React.MouseEventHandler;
  }
>(
  props => {
    const {
      to,
      digest,
      state,
      aliases,
      isSelected,
      isCompared,
      onCompareClick,
      showCompare,
    } = props;

    const tags = aliases.map(a => a.alias);
    const version = tags.find(a => a.startsWith('v')) ?? digest;
    const aliasesStrings = tags.filter(t => t !== version);

    return (
      <Link to={to}>
        <S.ArtifactItemString
          className={classnames('artifact-sidebar-item', 'version-option', {
            'selected-option': isSelected,
            'deleted-option': state === 'DELETED',
            'compared-option': isCompared,
          })}>
          <div>
            <span data-cy="artifact-version">{version} </span>
            {aliasesStrings.map(alias => (
              <S.AliasLabel data-cy="artifact-tag" key={alias}>
                {alias}
              </S.AliasLabel>
            ))}
          </div>
          <ArtifactCompareButton show={showCompare} onClick={onCompareClick} />
        </S.ArtifactItemString>
      </Link>
    );
  },
  {id: 'ArtifactItemVersion'}
);

const ArtifactSequenceHeader = makeComp<
  ArtifactMin & {
    entityName: string;
    projectName: string;
    artifactTypeName: string;
    artifactCommitHash?: string;
    active: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    refetchProjectArtifacts: () => void;
  }
>(
  props => {
    const {
      id,
      name,
      active,
      entityName,
      projectName,
      artifactTypeName,
      onClick,
      refetchProjectArtifacts,
    } = props;

    const to = Urls.artifactSequence({
      entityName,
      projectName,
      artifactTypeName,
      artifactSequenceName: name,
    });
    const icon = active ? 'caret down' : 'caret right';
    const history = useHistory();
    const [isRenaming, setRenaming] = useState(false);
    const [isMoving, setMoving] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const [updateArtifactCollection] = useUpdateArtifactCollectionMutation();
    const [moveArtifactCollection] = useMoveArtifactCollectionMutation({
      onCompleted: refetchProjectArtifacts,
    });
    const [deleteArtifactSequence] = useDeleteArtifactSequenceMutation({
      onCompleted: refetchProjectArtifacts,
    });

    return (
      <S.ArtifactVersionContainer className={'overview-option'}>
        <RenameArtifactModal
          name={name}
          modalOpen={isRenaming}
          onRenameArtifact={async newName => {
            await updateArtifactCollection({
              variables: {
                artifactSequenceID: id,
                name: newName,
              },
            });
            setRenaming(false);
            history.replace(
              Urls.artifactSequence({
                entityName,
                projectName,
                artifactTypeName,
                artifactSequenceName: newName,
              })
            );
          }}
          onClose={async () => {
            setRenaming(false);
          }}
        />
        <MoveArtifactModal
          entityName={entityName}
          projectName={projectName}
          modalOpen={isMoving}
          onMoveArtifact={async destinationArtifactTypeName => {
            await moveArtifactCollection({
              variables: {
                artifactSequenceID: id,
                destinationArtifactTypeName,
              },
            });
            setMoving(false);
            history.push(
              Urls.artifactSequence({
                entityName,
                projectName,
                artifactTypeName: destinationArtifactTypeName,
                artifactSequenceName: name,
              })
            );
          }}
          onClose={() => setMoving(false)}
        />{' '}
        <DeleteArtifactModal
          modalOpen={isDeleting}
          onDeleteArtifact={async () => {
            await deleteArtifactSequence({
              variables: {
                artifactSequenceID: id ?? '',
              },
            });
            history.push(
              Urls.projectArtifacts({entityName, name: projectName})
            );
          }}
          onClose={() => setDeleting(false)}
        />
        <div
          className={'overview-option-inline'}
          style={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          onClick={onClick}>
          <Icon name={icon} />
          <Link to={to}>{name}</Link>
        </div>
        <div className={'overview-option-ellipses'}>
          <ArtifactHeaderPopupDropdown
            onRenameArtifact={() => setRenaming(true)}
            onMoveArtifact={() => setMoving(true)}
            onDeleteArtifact={() => setDeleting(true)}
          />
        </div>
      </S.ArtifactVersionContainer>
    );
  },
  {id: 'ArtifactItemHeader'}
);

export const ArtifactSidebarPaginate = makeComp<{
  size?: 'mini' | 'tiny';
  total?: number;
  page?: number;
  perPage?: number;
  pageInfo: {hasNextPage?: boolean; hasPreviousPage?: boolean};
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}>(
  ({
    onPreviousClick,
    onNextClick,
    total = 0,
    pageInfo,
    perPage = 0,
    page = 0,
    size = 'mini',
  }) => {
    const {hasNextPage, hasPreviousPage} = pageInfo;
    if (!hasNextPage && !hasPreviousPage) {
      return null;
    }

    const pageStart = page * perPage;
    const pageEnd = page * perPage + perPage;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          margin: 2,
          marginLeft: 14,
        }}>
        <div
          style={
            size === 'mini'
              ? {
                  lineHeight: '0.5em',
                  fontSize: '0.8em',
                }
              : {}
          }>
          {pageStart + 1} - {pageEnd} of {total}
        </div>
        <Button.Group className="pagination-buttons">
          <Button
            className="wb-icon-button only-icon page-down-button"
            size={size}
            disabled={!hasPreviousPage}
            onClick={onPreviousClick}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            className="wb-icon-button page-up-button only-icon"
            size={size}
            disabled={!hasNextPage}
            onClick={onNextClick}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
      </div>
    );
  },
  {id: 'ArtifactSidebarPaginate'}
);

const ARTIFACTS_PER_PAGE = 20;

export interface ArtifactSidebarSequenceWithUrlToggleProps extends ArtifactMin {
  entityName: string;
  projectName: string;
  activeArtifactTypeName: string;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  refetchProjectArtifacts: () => void;
}

export const ArtifactSidebarSequenceWithUrlToggle =
  makeComp<ArtifactSidebarSequenceWithUrlToggleProps>(
    props => {
      const urlHash = window.location.hash.slice(1) ?? '';
      const [, compareSequenceName] = urlHash.split('$');
      const [active, setActive] = useState(false);
      const toggle = () => setActive(s => !s);
      const {name, artifactCollectionName} = props;

      // Automatically expand sections based on the url
      useLocation();
      useEffect(() => {
        const isArtifactCollection = artifactCollectionName === name;
        const isCompareCollection = compareSequenceName === name;
        if (isArtifactCollection || isCompareCollection) {
          setActive(true);
        }
      }, [artifactCollectionName, compareSequenceName, name]);

      return (
        <ArtifactSidebarSequence
          {...props}
          onHeaderClick={() => toggle()}
          active={active}
        />
      );
    },
    {id: 'ArtifactSidebarSequenceWithUrl'}
  );

export interface ArtifactSidebarSequenceProps
  extends ArtifactSidebarSequenceWithUrlToggleProps {
  active: boolean;
  onHeaderClick: React.MouseEventHandler;
  refetchProjectArtifacts: () => void;
}

export const ArtifactSidebarSequence = makeComp<ArtifactSidebarSequenceProps>(
  props => {
    const {
      name,
      active,
      entityName,
      projectName,
      artifactCommitHash,
      artifactCollectionName,
      activeArtifactTypeName,
      artifactTypeName,
      artifactTab,
      filePath,
      onHeaderClick,
    } = props;

    const history = useHistory();
    const {data, loading, refetch} = useArtifactCollectionQuery({
      variables: {
        artifactFirst: ARTIFACTS_PER_PAGE,
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: name,
      },
      skip: !active,
    });

    // TODO: can probably do this with react router. And is this really what
    // we want?
    const urlHash = window.location.hash.slice(1) ?? '';
    const [compareID] = urlHash.split('$');

    const artifactCollection = data?.project?.artifactType?.artifactSequence;
    const totalCount = artifactCollection?.artifacts.totalCount;
    const pageInfo = artifactCollection?.artifacts.pageInfo;
    const artifacts = artifactCollection?.artifacts.edges.map(n => n.node);
    const hasArtifacts = artifacts != null && artifacts?.length > 0;

    const fetchWithCursor = (cursor?: string) => {
      refetch({
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: name,
        artifactCursor: cursor,
      });
    };

    const [cursors, setCursors] = useState<string[]>([]);
    const nextPage = () => {
      const cursor = pageInfo?.endCursor;
      if (cursor != null) {
        setCursors([...cursors, cursor]);
        fetchWithCursor(cursor);
      }
    };

    const previousPage = () => {
      const tmpCursors = [...cursors];
      tmpCursors.pop();
      const cursor = _.last(tmpCursors);
      setCursors(tmpCursors);
      fetchWithCursor(cursor);
    };

    return (
      <div>
        <ArtifactSequenceHeader {...props} onClick={onHeaderClick} />
        {active && (
          <S.ArtifactsSidebarArtifactList>
            {loading && data == null ? (
              <S.ArtifactVersionLoad>
                Loading artifacts...
              </S.ArtifactVersionLoad>
            ) : (
              <>
                {!hasArtifacts && (
                  <span style={{marginLeft: '10px'}}>No artifacts found.</span>
                )}
                {artifacts?.map(a => {
                  const url = Urls.artifactSubpage({
                    entityName,
                    projectName,
                    artifactTypeName,
                    artifactSequenceName: name,
                    artifactCommitHash: a.commitHash!,
                    tabName: artifactTab,
                    pathString: filePath,
                  });

                  const onCompareClick: React.MouseEventHandler = e => {
                    e.preventDefault();
                    if (
                      artifactCommitHash == null ||
                      artifactCollectionName == null
                    ) {
                      return;
                    }
                    history.replace(
                      Urls.artifactSubpageCompare({
                        entityName,
                        projectName,
                        artifactTypeName: activeArtifactTypeName,
                        artifactSequenceName: artifactCollectionName,
                        artifactCommitHash,
                        tabName: artifactTab,
                        pathString: filePath,
                        compareArtifactID: a.commitHash!,
                        compareArtifactSequence: props.name,
                      })
                    );
                  };
                  const isSelected = a.commitHash === artifactCommitHash;
                  const isCompared = a.commitHash === compareID;
                  const showCompare =
                    artifactTab === 'files' &&
                    a.commitHash !== artifactCommitHash;

                  return (
                    <ArtifactItemVersion
                      key={a.id}
                      {...a}
                      isSelected={isSelected}
                      isCompared={isCompared}
                      showCompare={showCompare}
                      to={url}
                      onCompareClick={onCompareClick}
                    />
                  );
                })}
              </>
            )}
            <S.ArtifactSidebarArtifactPaginate>
              <ArtifactSidebarPaginate
                page={cursors.length}
                perPage={ARTIFACTS_PER_PAGE}
                total={totalCount}
                pageInfo={pageInfo ?? {}}
                onPreviousClick={previousPage}
                onNextClick={nextPage}
              />
            </S.ArtifactSidebarArtifactPaginate>
          </S.ArtifactsSidebarArtifactList>
        )}
      </div>
    );
  },
  {id: 'ArtifactItem'}
);
