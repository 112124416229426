import React from 'react';
import {useCallback, useMemo, useState} from 'react';
import * as Panel2 from './panel';
import * as CG from '@wandb/cg/browser/graph';
import {PanelComp2} from './PanelComp';
import {Resizable} from 'react-resizable';
import {ErrorBoundary} from '../../components/ErrorBoundary';
import PanelError from '../../components/elements/PanelError';
import {captureError} from '../../util/integrations';
import {Spec as PanelExpressionSpec} from './PanelExpression';

export interface RootQueryPanelConfig {
  panelConfig: any;
  height?: number;
}

function renderPanelError() {
  return (
    <div style={{padding: 40}}>
      <PanelError
        message={
          <div>
            Oops, something went wrong. If this keeps happening, message
            support@wandb.com with a link to this page
          </div>
        }
      />
    </div>
  );
}
function logError(error: Error) {
  console.error('RootQueryPanel error', error);
  captureError(error, 'ArtifactFiles error');
}

export const RootQueryPanel: React.FC<{
  config: RootQueryPanelConfig;
  updateConfig: (newRootQueryConfig: Partial<RootQueryPanelConfig>) => void;
}> = props => {
  const {config, updateConfig} = props;
  const height = useMemo(() => config.height ?? 400, [config.height]);
  const [resizeHeight, setResizeHeight] = useState(height);
  const updateHeight = useCallback(
    (newHeight: number) => {
      updateConfig({height: newHeight});
    },
    [updateConfig]
  );
  // Stick path in to make dir panel work.
  // TODO: fix this to let dir panel update input instead of relying on context
  const [context, setContext] = useState<Panel2.PanelContext>({path: []});
  // TODO: any
  const updateContext = useCallback<Panel2.UpdateContext>(
    newContext => {
      setContext({...context, ...newContext});
    },
    [context]
  );

  const expressionConfig = useMemo(() => {
    const oldConfig = config as any;
    if (config.panelConfig?.exp) {
      return config.panelConfig;
    } else {
      return {
        ...(config.panelConfig ?? {}),
        exp: oldConfig.exp,
        panelConfig: oldConfig.panelConfig,
      };
    }
  }, [config]);

  const updatePanelExpressionConfig = useCallback<any>(
    (newConfig: any) => {
      updateConfig({panelConfig: {...expressionConfig, ...newConfig}});
    },
    [updateConfig, expressionConfig]
  );

  return (
    <ErrorBoundary onError={logError} renderError={renderPanelError}>
      <Resizable
        width={400}
        height={height}
        onResize={(e, data) => {
          setResizeHeight(data.size.height);
        }}
        onResizeStop={() => updateHeight(resizeHeight)}>
        <div style={{height, padding: '6px'}}>
          <PanelComp2
            input={{path: CG.voidNode() as any}}
            inputType={'invalid'}
            loading={false}
            panelSpec={PanelExpressionSpec}
            configMode={false}
            context={context}
            config={expressionConfig}
            updateConfig={updatePanelExpressionConfig}
            updateContext={updateContext}
            noBar
          />
        </div>
      </Resizable>
    </ErrorBoundary>
  );
};
