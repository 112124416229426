import * as S from './paragraphs.styles';

import React from 'react';
import {Node, Element, Editor, Range} from 'slate';
import {
  RenderElementProps,
  useEditor,
  useSelected,
  useReadOnly,
  ReactEditor,
} from 'slate-react';
import {BlockWrapper} from './drag-drop';

export interface Paragraph extends Element {
  type: 'paragraph';
  textAlign?: 'center';
}

export const isParagraph = (node: Node): node is Paragraph =>
  node.type === 'paragraph';

export const ParagraphElement: React.FC<
  RenderElementProps & {
    element: Paragraph;
  }
> = ({attributes, element, children}) => {
  const editor = useEditor();
  const selected = useSelected();
  const readOnly = useReadOnly();
  const empty = Editor.isEmpty(editor, element);
  const collapsed =
    editor.selection != null && Range.isCollapsed(editor.selection);

  const path = ReactEditor.findPath(editor, element);

  if (path.length > 1) {
    return (
      <S.Paragraph {...attributes} textAlign={element.textAlign}>
        {children}
      </S.Paragraph>
    );
  }

  if (path.length === 1 && path[0] === editor.children.length - 1) {
    /** Persistent blank line under report. We don't want to be able to drag it
     * or drag anything under it. It should be extra fat for easy clicking.
     */
    return (
      <S.Paragraph
        {...attributes}
        data-test="trailing-paragraph"
        showHint={selected && empty && collapsed}
        style={readOnly ? {display: 'none'} : undefined}>
        {children}
      </S.Paragraph>
    );
  }

  if (path.length === 1 && path[0] === 0 && Editor.isEmpty(editor, element)) {
    /** Persistent blank line above report. We don't want to be
     * able to drag it, but we do want to be able to drag other
     * block onto (under) it.
     */
    return (
      <BlockWrapper attributes={attributes} element={element} noDragging>
        <S.Paragraph
          showHint={selected && empty && collapsed}
          style={readOnly ? {display: 'none'} : undefined}>
          {children}
        </S.Paragraph>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Paragraph
        showHint={selected && empty && collapsed}
        textAlign={element.textAlign}>
        {children}
      </S.Paragraph>
    </BlockWrapper>
  );
};

export const paragraphNodeToLatex = (node: Paragraph, inner: string) => {
  return `${inner}\n`;
};
