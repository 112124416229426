import {Editor, Transforms, Range} from 'slate';

export const withBetterVoidHandling = <T extends Editor>(editor: T) => {
  const {deleteBackward, deleteFragment, insertBreak} = editor;

  editor.insertBreak = () => {
    const {selection} = editor;

    const voidEntry = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n) && Editor.isVoid(editor, n),
    });

    if (selection != null && voidEntry != null) {
      Transforms.insertNodes(editor, {
        type: 'paragraph',
        children: [{text: ''}],
      });

      return;
    }

    insertBreak();
  };

  // Unfortunate fix for buggy void deletion
  editor.deleteFragment = () => {
    const {selection} = editor;

    if (selection && Range.isExpanded(selection)) {
      Transforms.delete(editor, {hanging: true, voids: true});
      return;
    }

    deleteFragment();
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;

        const previousPoint = Editor.before(editor, selection, {
          unit: 'offset',
        });
        const previousVoid =
          previousPoint &&
          Editor.void(editor, {
            at: previousPoint,
          });
        if (
          (Editor.isEmpty(editor, block) && previousVoid) ||
          Editor.isVoid(editor, block)
        ) {
          Transforms.removeNodes(editor, {at: path});
          return;
        }

        // Make it slightly harder to accidentally backspace away voids
        if (previousPoint != null) {
          const previousVoidMatch = Editor.above(editor, {
            at: previousPoint,
            match: n => Editor.isVoid(editor, n),
          });
          if (previousVoidMatch != null) {
            Transforms.select(editor, previousVoidMatch[1]);
            return;
          }
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};
