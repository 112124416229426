import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {
  Button as SemanticButton,
  Modal as SemanticModal,
} from 'semantic-ui-react';

export const Modal = styled(SemanticModal)`
  max-width: 400px;
`;

export const Header = styled.div`
  font-family: ${globals.fontName};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  padding: 24px 0px 0px 24px;
  text-align: left;
`;

export const Content = styled.div`
  font-family: ${globals.fontName};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 24px 36px 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 24px 24px 0px;
`;

export const Button = styled(SemanticButton)`
  && {
    border: none;
  }
`;
