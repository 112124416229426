import * as S from './code-blocks.styles';

import * as Prism from 'prismjs';
import React from 'react';
import {BlockWrapper} from './drag-drop';

import {
  Element,
  Node,
  Transforms,
  Editor,
  Range,
  Point,
  Text,
  Descendant,
  NodeEntry,
} from 'slate';
import {
  RenderElementProps,
  useReadOnly,
  useEditor,
  ReactEditor,
} from 'slate-react';
import {EditorWithHardBreak} from './hard-break';

export interface CodeBlock extends Element {
  type: 'code-block';
  language?: string;
  metadata?: string;
}

export const isCodeBlock = (node: Node): node is CodeBlock =>
  node.type === 'code-block';

export const CodeBlockElement: React.FC<
  RenderElementProps & {
    element: CodeBlock;
  }
> = ({attributes, element, children}) => {
  const {language = 'python'} = element;
  const readOnly = useReadOnly();

  const editor = useEditor();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.CodeBlockWrapper>
        <S.CodeBlock>{children}</S.CodeBlock>
        {!readOnly && (
          <div contentEditable={false}>
            <S.LanguageSelect
              options={[
                {value: 'javascript', name: 'Javascript'},
                {value: 'python', name: 'Python'},
                {value: 'css', name: 'CSS'},
                {value: 'json', name: 'JSON'},
                {value: 'html', name: 'HTML'},
                {value: 'markdown', name: 'Markdown'},
                {value: 'yaml', name: 'YAML'},
              ]}
              value={language}
              onSelect={v => {
                const path = ReactEditor.findPath(editor, element);
                const range = Editor.range(editor, path);

                Editor.withoutNormalizing(editor, () => {
                  Transforms.setNodes(
                    editor,
                    {language: v},
                    {
                      at: path,
                    }
                  );

                  Transforms.setNodes(
                    editor,
                    {language: v},
                    {
                      at: range,
                    }
                  );
                });
              }}></S.LanguageSelect>
          </div>
        )}
      </S.CodeBlockWrapper>
    </BlockWrapper>
  );
};

export interface CodeLine extends Element {
  type: 'code-line';
  language?: string;
}

export const isCodeLine = (node: Node): node is CodeLine =>
  node.type === 'code-line';

export const CodeLineElement: React.FC<
  RenderElementProps & {
    element: CodeLine;
  }
> = ({attributes, element, children}) => {
  return <div {...attributes}>{children}</div>;
};

const getLength = (token: string | Prism.Token): number => {
  if (typeof token === 'string') {
    return token.length;
  }

  const content = token.content;
  if (typeof content === 'string') {
    return content.length;
  }

  if (Array.isArray(content)) {
    return content.reduce((l, t) => l + getLength(t), 0);
  }

  return 0;
};

export const useCodeHighlighting = (editor: Editor) => {
  const decorate = React.useCallback(
    ([node, path]: NodeEntry<Node>) => {
      const ranges: Range[] = [];

      if (!Text.isText(node)) {
        return ranges;
      }

      const codeLineEntry = Editor.above(editor, {
        at: path,
        match: n => isCodeLine(n),
      });
      if (codeLineEntry == null) {
        return ranges;
      }

      const codeBlock = codeLineEntry[0];

      if (!isCodeLine(codeBlock)) {
        return ranges;
      }

      const language = codeBlock.language?.toLowerCase() || 'python';
      const grammar = Prism.languages[language];
      if (grammar == null) {
        return ranges;
      }

      const tokens = Prism.tokenize(node.text, grammar);
      let start = 0;

      for (const token of tokens) {
        const length = getLength(token);
        const end = start + length;

        if (typeof token !== 'string') {
          ranges.push({
            [token.type]: true,
            anchor: {path, offset: start},
            focus: {path, offset: end},
          });
        }

        start = end;
      }

      return ranges;
    },
    [editor]
  );

  return decorate;
};

export const withCode = <T extends Editor>(editor: T) => {
  const {normalizeNode, insertBreak, deleteBackward} = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (isCodeBlock(node)) {
      const hasCodeLine = node.children.some(isCodeLine);
      if (!hasCodeLine) {
        Transforms.unwrapNodes(editor, {at: path});
        return;
      }

      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        const childPath = [...path, i];
        if (isCodeLine(child)) {
          if (child.language !== node.language) {
            Transforms.setNodes(
              editor,
              {language: node.language},
              {at: childPath}
            );
          }
        } else {
          Transforms.liftNodes(editor, {at: childPath});
          return;
        }
      }
    }

    if (Editor.isEditor(node) || Element.isElement(node)) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i] as Descendant;
        const prev = node.children[i - 1] as Descendant;

        if (prev != null && isCodeBlock(prev) && isCodeBlock(child)) {
          Transforms.mergeNodes(editor, {at: [...path, i]});
          return;
        }

        if (isCodeLine(child) && !isCodeBlock(node)) {
          Transforms.wrapNodes(
            editor,
            {type: 'code-block', language: child.language, children: []},
            {at: [...path, i]}
          );
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    const {selection} = editor;

    const match = Editor.above(editor, {
      match: n => isCodeBlock(n),
    });

    if (selection && match) {
      const [block, path] = match;

      if (
        isCodeBlock(block) &&
        Range.isCollapsed(selection) &&
        Point.equals(selection.anchor, Editor.end(editor, path))
      ) {
        const allowedBlankLines = 2;
        let blankLines = 0;

        for (let i = 0; i < allowedBlankLines; i++) {
          const child = block.children[block.children.length - 1 - i];
          if (
            child != null &&
            Editor.isBlock(editor, child) &&
            Editor.isEmpty(editor, child)
          ) {
            blankLines++;
          } else {
            break;
          }
        }

        if (blankLines === allowedBlankLines) {
          EditorWithHardBreak.hardBreak(editor);
          for (let i = 0; i < allowedBlankLines; i++) {
            Editor.deleteBackward(editor);
          }
          return;
        }
      }
    }

    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const blockMatch = Editor.above(editor, {
        match: n => isCodeBlock(n) && n.children.length === 1,
      });
      const lineMatch = Editor.above(editor, {
        match: n => isCodeLine(n) && Editor.isEmpty(editor, n),
      });

      if (blockMatch != null && lineMatch != null) {
        Transforms.setNodes(editor, {type: 'paragraph'});
        return;
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

export const codeBlockNodeToLatex = (node: CodeBlock, inner: string) => {
  return `\\begin{lstlisting}[language=${
    node.language || 'python'
  }]\n${inner}\\end{lstlisting}`;
};

export const codeLineNodeToLatex = (node: CodeLine, inner: string) => {
  return `${inner}\n`;
};
