// This is types for an older version of selection manager. The newer
// selectionmanager users these types to convert old data.

import * as Run from './runs';
import {SelectionBound} from './selectionmanager';

export interface SelectionGroup {
  readonly groupKey: Run.Key;
  readonly groupValue: Run.Value;
}

export interface SelectionSubgroup {
  readonly groupKey: Run.Key;
  readonly groupValue: Run.Value;
  readonly subgroupKey: Run.Key;
  readonly subgroupValue: Run.Value;
}

export interface SelectionRun {
  readonly id: string;
  readonly memberOf?: SelectionGroup | SelectionSubgroup;
}

// Unfortunately Typescript counts the SelectionManager class as
// SelectionManagerJSON. We should change the SelectionManager class
// so that it doesn't match.
export interface SelectionManagerJSON {
  root: Root;
  bounds: SelectionBound[];
  addGroups: SelectionGroup[];
  subtractGroups: SelectionGroup[];
  addSubgroups: SelectionSubgroup[];
  subtractSubgroups: SelectionSubgroup[];
  addRuns: SelectionRun[];
  subtractRuns: SelectionRun[];
}

export function isSubgroup(
  g: SelectionGroup | SelectionSubgroup
): g is SelectionSubgroup {
  return (g as SelectionSubgroup).subgroupKey != null;
}

export enum Root {
  None,
  All,
  Some,
}
