import React, {useCallback} from 'react';
import * as Panel2 from './panel';
import * as Op from '@wandb/cg/browser/ops';
import * as CG from '@wandb/cg/browser/graph';
import * as Types from '@wandb/cg/browser/model/types';
import * as KeyValTable from './KeyValTable';
import {PanelString, Spec as PanelStringSpec} from './PanelString';
import {PanelNumber, Spec as PanelNumberSpec} from './PanelNumber';
import {callFunction} from '@wandb/cg/browser/hl';

const inputType = {type: 'typedDict' as const, propertyTypes: {}};

export interface ObjectConfig {
  expanded?: boolean;
}
type PanelObjectProps = Panel2.PanelProps<typeof inputType, ObjectConfig>;

export const PanelObject: React.FC<PanelObjectProps> = props => {
  const inputVar = CG.varNode(props.input.path.type, 'input');

  const propertyTypes = Types.typedDictPropertyTypes(props.input.path.type);
  const keys = Object.keys(propertyTypes);

  const updateInput = useCallback(
    (key, newInput) => {
      if (newInput == null) {
        return props.updateInput?.({
          path: Op.opPick({
            obj: inputVar,
            key: Op.constString(key),
          }) as any,
        });
      } else {
        const input = Op.opPick({
          obj: inputVar,
          key: Op.constString(key),
        });
        const innerFunction = callFunction((newInput as any).path, {
          input,
        });
        return props.updateInput?.({path: innerFunction as any});
      }
    },
    [inputVar, props.updateInput]
  );

  return (
    <KeyValTable.Table>
      <tbody>
        {keys.map(k => (
          <KeyValTable.Row key={k}>
            <KeyValTable.Key>
              <KeyValTable.InputUpdateLink onClick={() => updateInput(k, null)}>
                {k}
              </KeyValTable.InputUpdateLink>
            </KeyValTable.Key>
            <KeyValTable.Val>
              {Types.isAssignableTo2(
                propertyTypes[k]!,
                PanelStringSpec.inputType
              ) ? (
                <PanelString
                  input={{
                    path: Op.opPick({
                      obj: props.input.path,
                      key: Op.constString(k),
                    }) as any,
                  }}
                  context={props.context}
                  updateContext={props.updateContext}
                  // Get rid of updateConfig
                  updateConfig={() => {}}
                />
              ) : Types.isAssignableTo2(
                  propertyTypes[k]!,
                  PanelNumberSpec.inputType
                ) ? (
                <PanelNumber
                  input={{
                    path: Op.opPick({
                      obj: props.input.path,
                      key: Op.constString(k),
                    }) as any,
                  }}
                  context={props.context}
                  updateContext={props.updateContext}
                  // Get rid of updateConfig
                  updateConfig={() => {}}
                />
              ) : Types.isAssignableTo2(propertyTypes[k]!, Spec.inputType) ? (
                <PanelObject
                  input={{
                    path: Op.opPick({
                      obj: props.input.path,
                      key: Op.constString(k),
                    }) as any,
                  }}
                  context={props.context}
                  updateContext={props.updateContext}
                  // Get rid of updateConfig
                  updateConfig={() => {}}
                  updateInput={newInput => updateInput(k, newInput)}
                />
              ) : (
                <div>{Types.toString(propertyTypes[k]!, true)}</div>
              )}
            </KeyValTable.Val>
          </KeyValTable.Row>
        ))}
      </tbody>
    </KeyValTable.Table>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'object',
  Component: PanelObject,
  inputType,
};
