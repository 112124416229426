import styled from 'styled-components';

export const FullscreenWrapper = styled.div`
  height: 95vh;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullscreenImageContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const FullscreenImage = styled.img``;
