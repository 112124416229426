import * as React from 'react';
import makeComp from '../util/profiler';

import * as DemoComputeGraph from '../components/Panel2/DemoComputeGraph';

interface QueryDemoPageProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
    };
  };
}

const QueryDemoPage: React.FC<QueryDemoPageProps> = makeComp(
  props => {
    return (
      <DemoComputeGraph.EditorFromScratch2
      // entityName={props.match.params.entityName}
      // projectName={props.match.params.projectName}
      />
    );
  },
  {id: 'QueryDemoPage'}
);

export default QueryDemoPage;
