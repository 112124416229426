import {RootState} from '../../types/redux';
import {createSelector} from 'reselect';
import * as Normalize from '../views/normalize';
import * as Types from './types';
import {useSelector} from '../hooks';
import {useWhole, useWholeMapped} from '../views/hooks';
import {CommentableItem} from '../../components/ReportDiscussionContext';
import {isEqual} from 'lodash';
import {
  PanelGrid,
  isPanelGrid,
} from '../../components/Slate/plugins/panel-grids';

export const getReportsState = (state: RootState) => state.reports;

export function makeReportViewIDSelector(reportID: string | undefined) {
  return createSelector(getReportsState, reports => {
    if (reportID == null) {
      return {loading: true as true};
    }
    const viewID = reports.viewRefs[reportID];
    if (viewID == null) {
      return {loading: true as true};
    }
    return {loading: false as false, viewID};
  });
}

export function makePushedReportSelector() {
  return createSelector(getReportsState, reports => reports.pushedRef);
}

export const getReportID =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const view = state.views.views[reportViewRef.id];
    if (view == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    if (
      view.partRef.type !== Types.REPORT_VIEW_TYPE &&
      view.partRef.type !== Types.REPORT_DRAFT_VIEW_TYPE
    ) {
      throw new Error('invalid state');
    }
    if (view.id == null) {
      throw new Error('invalid state');
    }
    return view.id;
  };

export const getReportParentID =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const view = state.views.views[reportViewRef.id];
    if (view == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    if (
      view.partRef.type !== Types.REPORT_VIEW_TYPE &&
      view.partRef.type !== Types.REPORT_DRAFT_VIEW_TYPE
    ) {
      throw new Error('invalid state');
    }
    return view.parentId;
  };

export const getReportEntityName =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const view = state.views.views[reportViewRef.id];
    if (view == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    if (
      view.partRef.type !== Types.REPORT_VIEW_TYPE &&
      view.partRef.type !== Types.REPORT_DRAFT_VIEW_TYPE
    ) {
      throw new Error('invalid state');
    }
    return view.project?.entityName ?? view.entityName;
  };

export const getReportRef =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const view = state.views.views[reportViewRef.id];
    if (view == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    if (
      view.partRef.type !== Types.REPORT_VIEW_TYPE &&
      view.partRef.type !== Types.REPORT_DRAFT_VIEW_TYPE
    ) {
      throw new Error('invalid state');
    }
    return view.partRef;
  };

export const getReportPart =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const view = state.views.views[reportViewRef.id];
    if (view == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    if (
      view.partRef.type !== Types.REPORT_VIEW_TYPE &&
      view.partRef.type !== Types.REPORT_DRAFT_VIEW_TYPE
    ) {
      throw new Error('invalid state');
    }
    return Normalize.lookupPart(state.views.parts, view.partRef);
  };

export const getReportPanelSettingsRef =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const reportPart = getReportPart(reportViewRef)(state);
    if (reportPart.panelSettingsRef == null) {
      // We define this by default so it should always be available.
      throw new Error('invalid state');
    }
    return reportPart.panelSettingsRef;
  };

export const getReportWidth =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const reportPart = getReportPart(reportViewRef)(state);
    return reportPart.width;
  };

export const getReportBlocks =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const reportPart = getReportPart(reportViewRef)(state);
    return reportPart.blocks;
  };

// This returns all the panels in the report.
// Used as panel lookup for comment references
export const makeReportPanelsSelector = (
  reportViewRef: Types.ReportViewRef
) => {
  let reportPanels: CommentableItem[] | undefined;
  let prevReportPanelGridElements: PanelGrid[] | undefined;
  return () => {
    const reportRef = useSelector(getReportRef(reportViewRef));
    const reportPanelGridElements = useWhole(reportRef).blocks.filter(b =>
      isPanelGrid(b)
    ) as PanelGrid[];
    if (
      reportPanels == null ||
      !isEqual(reportPanelGridElements, prevReportPanelGridElements)
    ) {
      prevReportPanelGridElements = reportPanelGridElements;
      reportPanels = reportPanelGridElements
        .map(block => block.metadata.panelBankSectionConfig.panels)
        .flat() as CommentableItem[];
    }
    return reportPanels;
  };
};

export const getReportDiscussionThreadRefs =
  (reportViewRef: Types.ReportViewRef) => (state: RootState) => {
    const reportPart = getReportPart(reportViewRef)(state);
    return reportPart.discussionThreadRefs;
  };

export const getReportDiscussionCommentsCount =
  (reportViewRef: Types.ReportViewRef) => () => {
    const reportRef = useSelector(getReportRef(reportViewRef));
    const commentsCount = useWholeMapped(reportRef, r =>
      r.discussionThreads.reduce(
        (count: number, thread) => count + thread.comments.length,
        0
      )
    );
    return commentsCount;
  };
