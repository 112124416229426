import '../css/BenchmarkPageHeader.less';

import React, {useEffect} from 'react';
import {Image} from 'semantic-ui-react';

import headerBackground0 from '../assets/benchmarks/benchmark-bkg-0.svg';
import headerBackground1 from '../assets/benchmarks/benchmark-bkg-1.svg';
import headerBackground2 from '../assets/benchmarks/benchmark-bkg-2.svg';
import headerBackground3 from '../assets/benchmarks/benchmark-bkg-3.svg';
import headerBackground4 from '../assets/benchmarks/benchmark-bkg-4.svg';
import EditableField from './EditableField';
import {SaveHandler} from './elements/InlineMarkdownEditor';
import makeComp from '../util/profiler';
import {BENCHMARK_TITLES, formatDescription} from '../pages/Benchmark/titles';
import {setDocumentTitle} from '../util/document';
import Helmet from 'react-helmet';
import {canonicalize} from '../util/url';

const headerBackgrounds = [
  headerBackground0,
  headerBackground1,
  headerBackground2,
  headerBackground3,
  headerBackground4,
];

interface BenchmarkPageHeaderProps {
  avatarUrl: string;
  benchmarkEntityName: string;
  benchmarkName: string;
  benchmarkDescription: string;
  tabs: React.ReactNode;
  readOnly: boolean;
  onSaveDescription: SaveHandler;
}

const BenchmarkPageHeader: React.FC<BenchmarkPageHeaderProps> = makeComp(
  props => {
    // Hard code avatar for special benchmarks
    let benchmarkAvatar: React.ReactNode;
    let benchmarkAvatarURL: string;
    const benchmarkPath = `${props.benchmarkEntityName}/${props.benchmarkName}`;
    const benchmarkTitle = BENCHMARK_TITLES[benchmarkPath] ?? benchmarkPath;
    let headerThemeIndex = 0;
    if (props.benchmarkName === 'codesearchnet') {
      headerThemeIndex = 1;
      benchmarkAvatarURL =
        'https://github.githubassets.com/images/modules/logos_page/GitHub-Logo.png';
      benchmarkAvatar = (
        <Image
          className="benchmark-avatar github-avatar"
          style={{width: 'auto', height: 40, filter: 'invert() brightness(5)'}}
          src={benchmarkAvatarURL}
        />
      );
    } else if (props.benchmarkName === 'dronedeploy-aerial-segmentation') {
      benchmarkAvatarURL =
        'https://staticfiles-cdn1.dronedeploy.com/img/dd-logo.svg';
      benchmarkAvatar = (
        <Image
          className="benchmark-avatar dronedeploy-avatar"
          style={{width: 'auto', height: 60}}
          src={benchmarkAvatarURL}
        />
      );
    } else {
      headerThemeIndex = 2;
      benchmarkAvatarURL = props.avatarUrl;
      benchmarkAvatar = (
        <Image className="benchmark-avatar" src={benchmarkAvatarURL} />
      );
    }

    useEffect(() => {
      setDocumentTitle(benchmarkTitle, false);
    }, [benchmarkTitle]);

    return (
      <div className={'benchmark-page-header header-theme-' + headerThemeIndex}>
        <Helmet>
          <meta property="og:type" content={'article'} />
          <meta
            property="og:url"
            content={canonicalize(window.location.href)}
          />
          <meta property="og:title" content={benchmarkTitle} />
          <meta
            property="og:description"
            content={formatDescription(props.benchmarkDescription)}
          />
          <meta property="og:image" content={benchmarkAvatarURL} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content={props.benchmarkEntityName} />
          <link
            rel="author"
            href={window.location.origin + `/${props.benchmarkEntityName}`}
          />
          <link rel="canonical" href={canonicalize(window.location.href)} />
        </Helmet>
        <div
          className="header-content-wrapper"
          style={{
            backgroundImage: `url(${headerBackgrounds[headerThemeIndex]})`,
          }}>
          <div className="header-content">
            {benchmarkAvatar}
            <div className="header-text-content">
              <h1>{benchmarkTitle}</h1>
              <EditableField
                multiline
                readOnly={props.readOnly}
                className="description"
                value={props.benchmarkDescription}
                displayValue={formatDescription(props.benchmarkDescription)}
                placeholder="What is this benchmark about?"
                save={props.onSaveDescription}
              />
            </div>
          </div>
        </div>
        <div className="tabs-wrapper">{props.tabs}</div>
      </div>
    );
  },
  {id: 'BenchmarkPageHeader'}
);

export default BenchmarkPageHeader;
