import React from 'react';
import * as Panel2 from './panel';
import BokehViewer from './BokehViewer';
import {useAssetContentFromArtifact} from './useAssetFromArtifact';

const inputType = {type: 'bokeh-file' as const};

const PanelBokeh: React.FC<Panel2.PanelProps<typeof inputType>> = props => {
  const inputNode = props.input.path;
  const assetResult = useAssetContentFromArtifact(inputNode);
  if (assetResult.loading) {
    return <div></div>;
  } else {
    return (
      <BokehViewer
        bokehJson={
          assetResult.contents ? JSON.parse(assetResult.contents) : null
        }
      />
    );
  }
};

export const Spec: Panel2.PanelSpec = {
  id: 'bokeh-file',
  displayName: 'Bokeh',
  Component: PanelBokeh,
  inputType,
};
