import React from 'react';
import {Button} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import classNames from 'classnames';

interface MultiButtonProps {
  keys: string[];
  labels?: string[];
  selected?: string;
  onChange?: (selected: string) => void;
  disabled?: boolean;
}
const MultiButton: React.FC<MultiButtonProps> = makeComp(
  props => {
    return (
      <Button.Group size="tiny">
        {props.keys.map((key, i) => (
          <Button
            key={'button option ' + i}
            size="small"
            disabled={props.disabled}
            className={classNames({
              'action-button--active': key === props.selected,
            })}
            onClick={() => {
              if (props.onChange != null) {
                props.onChange(key);
              }
            }}>
            {props.labels ? props.labels[i] : props.keys[i]}
          </Button>
        ))}
      </Button.Group>
    );
  },
  {id: 'MultiButton'}
);

export default MultiButton;
