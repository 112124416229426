import * as _ from 'lodash';
import * as GroupSelectionsNormalize from '../groupSelections/normalize';
import * as Normalize from '../normalize';
import * as FilterNormalize from '../filter/normalize';
import * as SortNormalize from '../sort/normalize';
import * as Types from './types';

// TODO(views): Probably move this somewhere central.
function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]) {
  return _.omit(obj, keys);
}

export const normalize = Normalize.normFn<Types.RunSetObjSchema>(
  'runSet',
  (whole, ctx) => ({
    ...omit(
      whole,
      'grouping',
      'sort',
      'selections',
      'filters',
      'expandedRowAddresses'
    ),
    filtersRef: FilterNormalize.normalize(whole.filters, ctx),
    sortRef: SortNormalize.normalize(whole.sort, ctx),
    groupSelectionsRef: GroupSelectionsNormalize.normalize(
      {
        grouping: whole.grouping,
        selections: whole.selections,
        expandedRowAddresses: whole.expandedRowAddresses,
      },
      ctx
    ),
  })
);

export const denormalize = Normalize.denormFn<Types.RunSetObjSchema>(
  (part, ctx) => {
    const groupSelectionsDenorm = GroupSelectionsNormalize.denormalize(
      part.groupSelectionsRef,
      ctx
    );
    return {
      ...omit(part, 'groupSelectionsRef', 'sortRef', 'filtersRef'),
      filters: FilterNormalize.denormalize(part.filtersRef, ctx),
      grouping: groupSelectionsDenorm.grouping,
      sort: SortNormalize.denormalize(part.sortRef, ctx),
      selections: groupSelectionsDenorm.selections,
      expandedRowAddresses: groupSelectionsDenorm.expandedRowAddresses,
    };
  }
);
