import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 20px;
  display: flex;
  position: relative;
  flex-flow: column wrap;
  align-items: center;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  font-size: 32px;
  margin: 16px 0 16px 0;
  align-items: flex-start;
`;

export const AccordionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  padding: 0 0 8px 0;
`;

export const LinkToDashboardWrapper = styled.div`
  font-size: 18px;
`;

export const ContentWrapper = styled.div`
  padding: 1em 2em;
`;

export const TextWrapper = styled.div`
  padding: 0.5em 0 0.5em;
`;

export const GenerateLicenseFormWrapper = styled.div`
  padding: 16px 32px;
`;
