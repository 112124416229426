import _ from 'lodash';
import numeral from 'numeral';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Button, Modal, Popup} from 'semantic-ui-react';
import CheckoutModal, {
  CheckoutModalContext,
  CheckoutModalUpdaterContext,
} from '../components/CheckoutModal';
import {CreateTeamModal} from '../components/CreateTeamModal';
import {EditSubscriptionForm} from '../components/EditSubscriptionForm';
import EditUserModal from '../components/EditUserModal';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import {StorageSubscribeForm} from '../components/StorageSubscribeForm';
import UpgradeSubscriptionForm, {
  UpgradeSuccess,
} from '../components/UpgradeSubscriptionForm';
import {UpdatePaymentForm} from '../components/UpdatePaymentForm';
import WandbLoader from '../components/WandbLoader';
import {default as config} from '../config';
import '../css/BillingPage.less';
import {
  CustomerSubscriptionInfoQuery,
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  OrgType,
  PlanType,
  useCustomerSubscriptionInfoQuery,
  useEntityStorageQuery,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {useScrollToURLHash} from '../util/document';
import {
  isPersonalOrg,
  isNonPersonalAcademicOrg,
  isNonPersonalTrialOrg,
  isNonPersonalPaidOrg,
  OrganizationFlags,
  StripeElements,
  Team,
  useSubscriptionPlans,
} from '../util/pricing';
import makeComp from '../util/profiler';
import {
  artifactFeatures,
  artifactPrices,
  bytesInGB,
  storageFeatures,
  storagePrices,
  storageTiers,
} from '../util/storage';
import {TimeDelta} from '../util/time';
import {TargetBlank} from '../util/links';
import {profilePage, storagePage, teamPage} from '../util/urls';
import * as S from './BillingPage.styles';
import {GlobalNudgeBarContext} from '../components/GlobalNudgeBarContextProvider';
import {isOneOf} from '../util/utility';

const storageTitle = 'Usage Plan';
const storageSubtitle =
  'Unlock more storage and artifacts with a subscription plan. Free accounts include 100 GB of storage and artifact tracking. Subscribe now, and only pay for usage beyond the free plan.';

const storageFeatureTitle = 'Storage';
const artifactFeatureTitle = 'Artifact tracking';

const planDisplayName = (planName: string) => {
  return _.capitalize(planName.replace('_', ' '));
};

type OrgQueryResult = NonNullable<
  CustomerSubscriptionInfoQuery['viewer']
>['organizations'][number];

export type OrganizationMemberFragment = OrgQueryResult['members'][number];

type InviteFragment = OrgQueryResult['pendingInvites'][number];

export type OrganizationFragment = {
  id: string;
  name: string;
  subscriptionStatus: string;
  seatCount: number;
  cancelAtPeriodEnd: boolean;
  members: OrganizationMemberFragment[];
  pendingInvites: InviteFragment[];
  teams?: Team[];
  billingUserName?: string | null | undefined;
  flags?: OrganizationFlags;
};

export type PlanFragment = {
  type: string;
};

type BillingPageModal =
  | 'checkout'
  | 'storageCheckout'
  | 'storagePricing'
  | 'updatePayment'
  | 'editUser'
  | 'editSubscription'
  | 'createTeam'
  | 'changeSeats';

type BillingPageContextState = {
  selectedOrganization: OrganizationFragment | null;
  activeModal: BillingPageModal | null;
  storageUsed: number;
  trackingUsed: number;
  computeHours: number;
};
export const BillingPageContext = React.createContext<BillingPageContextState>({
  selectedOrganization: null,
  activeModal: null,
  storageUsed: 0,
  trackingUsed: 0,
  computeHours: 0,
});

type BillingPageUpdaterContextState = {
  setActiveModalWithOrganization: (
    m: BillingPageModal | null,
    o: OrganizationFragment | null
  ) => void;
  refetchSubscriptionInfo: () => Promise<any>;
};
const BillingPageUpdaterContext =
  React.createContext<BillingPageUpdaterContextState>({
    setActiveModalWithOrganization: () => {},
    refetchSubscriptionInfo: () => Promise.resolve(),
  });

const BillingPage: React.FC = makeComp(
  () => {
    const viewer = useViewer();

    useScrollToURLHash();
    useEffect(() => {
      if (viewer != null) {
        window.analytics.track('Billing page opened', {viewer});
      }
    }, [viewer]);

    const [selectedOrganization, setSelectedOrganization] =
      useState<OrganizationFragment | null>(null);
    const [activeModal, setActiveModal] = useState<BillingPageModal | null>(
      null
    );

    const userEntity = useEntityStorageQuery({
      fetchPolicy: 'network-only',
      variables: {
        entityName: viewer?.entity,
        enableReferenceTracking: false,
      },
    });
    const entity = userEntity.data?.entity;
    const storageUsed = entity?.storageBytes ?? 0;
    const trackingUsed = entity?.referenceBytes ?? 0;
    const computeHours = entity?.computeHours ?? 0;

    const subscriptionInfo = useCustomerSubscriptionInfoQuery({
      fetchPolicy: 'network-only',
    });
    const organizations = useMemo(
      () => subscriptionInfo.data?.viewer?.organizations ?? [],
      [subscriptionInfo.data]
    );
    const personalOrgs = useMemo(
      () => organizations.filter(isPersonalOrg),
      [organizations]
    );
    const academicOrgs = useMemo(
      () => organizations.filter(isNonPersonalAcademicOrg),
      [organizations]
    );
    const trialOrgs = useMemo(
      () => organizations.filter(isNonPersonalTrialOrg),
      [organizations]
    );
    const paidOrgs = useMemo(
      () => organizations.filter(isNonPersonalPaidOrg),
      [organizations]
    );
    const showCreateTeamButton =
      academicOrgs.length === 0 &&
      trialOrgs.length === 0 &&
      paidOrgs.length === 0;

    const setActiveModalWithOrganization: BillingPageUpdaterContextState['setActiveModalWithOrganization'] =
      useCallback((m, o) => {
        if (m == null && o != null) {
          // Prevent selected organization from being preserved throughout different modals
          throw new Error('must unset organization along with modal');
        }
        setActiveModal(m);
        setSelectedOrganization(o);
      }, []);

    const contextValue: BillingPageContextState = useMemo(
      () => ({
        selectedOrganization,
        activeModal,
        storageUsed,
        trackingUsed,
        computeHours,
      }),
      [
        selectedOrganization,
        activeModal,
        storageUsed,
        trackingUsed,
        computeHours,
      ]
    );
    const updaterContextValue: BillingPageUpdaterContextState = useMemo(
      () => ({
        setActiveModalWithOrganization,
        refetchSubscriptionInfo: subscriptionInfo.refetch,
      }),
      [setActiveModalWithOrganization, subscriptionInfo.refetch]
    );

    if (viewer == null) {
      return <WandbLoader />;
    }

    const renderPersonalStorageUpgrade = () => (
      <>
        <S.PersonalUsageDescription data-test="usage-desc">
          Your personal account has 100 GB of free storage and artifacts.
          <br />
          Subscribe to unlock more space for your projects in W&B.
        </S.PersonalUsageDescription>
        <S.UpgradeStorage>
          <S.UpgradeStorageButton
            data-test="storage-upgrade-button"
            className="action-button"
            onClick={() => {
              window.analytics.track('Subscribe to storage clicked', {
                viewer,
              });
              setActiveModalWithOrganization('storageCheckout', null);
            }}>
            Upgrade plan
          </S.UpgradeStorageButton>
        </S.UpgradeStorage>
      </>
    );

    const renderPersonalUsage = () => (
      <S.UsagePlanTeamInfo>
        <S.UsagePlanTeamField over={storageUsed > config.MAX_BYTES}>
          <S.UsagePlanTeamFieldTitle>Storage</S.UsagePlanTeamFieldTitle>
          {numeral(storageUsed).format('0.0b')}
        </S.UsagePlanTeamField>
        <S.UsagePlanTeamField over={trackingUsed > config.MAX_BYTES}>
          <S.UsagePlanTeamFieldTitle>Artifacts</S.UsagePlanTeamFieldTitle>
          {numeral(trackingUsed).format('0.0b')}
        </S.UsagePlanTeamField>
        <S.UsagePlanTeamField>
          <S.UsagePlanTeamFieldTitle>Compute</S.UsagePlanTeamFieldTitle>
          {new TimeDelta(computeHours).toHoursString()}
        </S.UsagePlanTeamField>
      </S.UsagePlanTeamInfo>
    );

    const noOrgsCard = (
      <>
        <S.SubscriptionOrgName data-test="org-name">
          {viewer.username}
        </S.SubscriptionOrgName>
        <S.SubscriptionCard>
          <S.SubscriptionCardRow>
            <S.SubscriptionCardPlan>
              <S.SubscriptionCardPlanHeader>
                Account
              </S.SubscriptionCardPlanHeader>
              <S.AccountPlanContent>
                <S.SubscriptionCardPlanType>
                  Personal account, $0 / month
                </S.SubscriptionCardPlanType>
                {showCreateTeamButton && (
                  <S.SeePricingButton>
                    <Button
                      data-test="see-pricing-button"
                      className="action-button"
                      onClick={() =>
                        setActiveModalWithOrganization('createTeam', null)
                      }>
                      Create team
                    </Button>
                  </S.SeePricingButton>
                )}
              </S.AccountPlanContent>
            </S.SubscriptionCardPlan>
            <S.SubscriptionCardPlan>
              <S.SubscriptionCardPlanHeader>Usage</S.SubscriptionCardPlanHeader>
              <S.UsagePlanContent>{renderPersonalUsage()}</S.UsagePlanContent>
              <S.ManageUsageLink to={storagePage(viewer.entity)}>
                <S.ConfigIcon name="configuration" /> Manage usage
              </S.ManageUsageLink>
              {renderPersonalStorageUpgrade()}
            </S.SubscriptionCardPlan>
          </S.SubscriptionCardRow>
        </S.SubscriptionCard>
      </>
    );

    const renderOrgCard = (o: typeof organizations[number]): JSX.Element => (
      <OrgCard key={o.id} org={o} viewer={viewer} />
    );
    return (
      <StripeElements>
        <BillingPageContext.Provider value={contextValue}>
          <BillingPageUpdaterContext.Provider value={updaterContextValue}>
            <div className="billing-page">
              <div className="billing-page-container">
                <h1>Subscriptions</h1>
                {trialOrgs.map(renderOrgCard)}
                {paidOrgs.map(renderOrgCard)}
                {academicOrgs.map(renderOrgCard)}
                {personalOrgs.map(renderOrgCard)}
                {personalOrgs.length === 0 && noOrgsCard}
                <div className="other-plans">
                  {/* eslint-disable-next-line wandb/no-a-tags */}
                  <TargetBlank href="/site/academic">
                    Academic and open source teams
                    <LegacyWBIcon
                      name="launch"
                      className="link-icon"></LegacyWBIcon>
                  </TargetBlank>
                </div>
              </div>
            </div>
            <BillingPageActiveModal />
          </BillingPageUpdaterContext.Provider>
        </BillingPageContext.Provider>
      </StripeElements>
    );
  },
  {id: 'BillingPage', memo: true}
);

type OrgCardProps = {
  org: OrgQueryResult;
  viewer: Viewer;
};

const OrgCard: React.FC<OrgCardProps> = makeComp(
  ({org, viewer}) => {
    const {storageUsed, trackingUsed, computeHours} =
      useContext(BillingPageContext);
    const {setActiveModalWithOrganization} = useContext(
      BillingPageUpdaterContext
    );

    const isPersonal = org.orgType === OrgType.Personal;
    const billingInfo = org.stripeBillingInfo;
    const primarySub = org.subscriptions[0];
    const subIsActive =
      billingInfo?.status === 'active' ||
      primarySub.subscriptionType === OrganizationSubscriptionType.Enterprise ||
      primarySub.subscriptionType === OrganizationSubscriptionType.Academic ||
      (isPersonal &&
        primarySub.subscriptionType === OrganizationSubscriptionType.Stripe);
    const subIsTrialing =
      billingInfo?.status === 'trialing' ||
      primarySub.subscriptionType ===
        OrganizationSubscriptionType.ManualTrial ||
      primarySub.subscriptionType ===
        OrganizationSubscriptionType.UserLedTrial ||
      primarySub.subscriptionType ===
        OrganizationSubscriptionType.AcademicTrial;
    const subIsCanceled =
      primarySub.status === OrganizationSubscriptionStatus.Disabled ||
      billingInfo?.status === 'canceled' ||
      billingInfo?.status === 'incomplete_expired';
    const subWillCancel = billingInfo?.cancelAtPeriodEnd;
    const subNeedsPayment = _.includes(
      ['incomplete', 'past_due', 'unpaid'],
      billingInfo?.status
    );
    const rawBillingDate = billingInfo?.currentPeriodEnd;
    const nextBillingDate = rawBillingDate
      ? new Date(rawBillingDate + 'Z').toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      : null;
    const subIsStripe =
      primarySub.subscriptionType === OrganizationSubscriptionType.Stripe;
    const hasStorageSub = org.subscriptions.some(
      (sub: any) => sub.plan.planType === PlanType.Storage
    );
    const hasReferenceSub = org.subscriptions.some(
      (sub: any) => sub.plan.planType === PlanType.Reference
    );
    const showUpgradePlanCTA = !(hasStorageSub && hasReferenceSub);

    const {states} = useContext(GlobalNudgeBarContext);
    const state = states?.find(s => s.organization?.id === org.id);
    const needsToUpgradePlan = isOneOf(state?.renderedNudgeBar, [
      'standardComputeHours',
      'enterpriseToStandard',
    ]);

    // Creates a comma-separated list of links to the members' profile pages
    const membersAndInvitees = [
      ..._.dropRight(
        _.flatten(
          org.members.map((m: any) => [
            <S.TeamMemberLink
              key={`profile-${org.name}-${m.username}`}
              to={profilePage(m.username)}>
              {m.username}
            </S.TeamMemberLink>,
            ', ',
          ])
        )
      ),
      org.pendingInvites.map((pi: any) => `, ${pi.email} (invited)`),
    ];

    const teams = [
      ..._.dropRight(
        _.flatten(
          org.teams.map((m: any) => [
            <S.TeamMemberLink
              key={`team-${org.name}-${m.name}`}
              to={teamPage(m.name)}>
              {m.name}
            </S.TeamMemberLink>,
            ', ',
          ])
        )
      ),
    ];

    const stripePaymentMethod = billingInfo?.paymentMethod;
    const paymentMethod =
      stripePaymentMethod?.cardType && stripePaymentMethod?.endingIn
        ? `${_.upperCase(stripePaymentMethod?.cardType)} •••• ${
            stripePaymentMethod.endingIn
          }`
        : `${
            stripePaymentMethod ? stripePaymentMethod.type + '--' : ''
          } payment details not available`;
    const isOrgAdminOrBillingOnlyUser =
      org.billingUser.username === viewer.username ||
      (org.members.find((m: any) => m.username === viewer.username)?.admin ??
        false);

    const orgName = isPersonal ? viewer.username : org.name;

    const personalControls = (
      <>
        <Popup
          position={'top center'}
          trigger={
            <span>
              <LegacyWBIcon
                data-test="change-plan-button"
                link
                style={{marginRight: 4}}
                name="edit"
                onClick={() => {
                  window.analytics.track('Storage pricing clicked', {viewer});
                  setActiveModalWithOrganization('storagePricing', null);
                }}
              />
            </span>
          }
          content={'See pricing'}
          size="small"
        />
        <Popup
          position={'top center'}
          trigger={
            <>
              <LegacyWBIcon
                link
                style={{marginRight: 4}}
                name="credit-card"
                onClick={() => {
                  setActiveModalWithOrganization('updatePayment', {
                    id: org.id,
                    name: org.name,
                    members: org.members,
                    pendingInvites: org.pendingInvites,
                    teams: org.teams,
                    seatCount: primarySub.seats,
                    subscriptionStatus: billingInfo?.status ?? '',
                    cancelAtPeriodEnd: billingInfo?.cancelAtPeriodEnd ?? false,
                  });
                }}
              />
            </>
          }
          content={'Change payment method'}
          size="small"
        />
      </>
    );

    const orgAdminControls = (
      <>
        <Popup
          position={'top center'}
          trigger={
            <span>
              <LegacyWBIcon
                data-test="edit-subscription"
                link
                style={{marginRight: 4}}
                name="edit"
                onClick={() => {
                  setActiveModalWithOrganization('editSubscription', {
                    id: org.id,
                    name: org.name,
                    members: org.members,
                    teams: org.teams,
                    pendingInvites: org.pendingInvites,
                    seatCount: primarySub.seats,
                    subscriptionStatus: billingInfo?.status ?? '',
                    cancelAtPeriodEnd: billingInfo?.cancelAtPeriodEnd ?? false,
                  });
                }}
              />
            </span>
          }
          content={'Edit/Cancel plan'}
          size="small"
        />
        {subIsStripe && (
          <span>
            <LegacyWBIcon
              link
              style={{marginRight: 4}}
              name="credit-card"
              onClick={() => {
                setActiveModalWithOrganization('updatePayment', {
                  id: org.id,
                  name: org.name,
                  members: org.members,
                  teams: org.teams,
                  pendingInvites: org.pendingInvites,
                  seatCount: primarySub.seats,
                  subscriptionStatus: billingInfo?.status ?? '',
                  cancelAtPeriodEnd: billingInfo?.cancelAtPeriodEnd ?? false,
                });
              }}
            />{' '}
          </span>
        )}
      </>
    );

    const planContent = isPersonal ? (
      <>
        <S.AccountPlanContent>
          <S.SubscriptionCardPlanType>
            Personal account, $0 / month
          </S.SubscriptionCardPlanType>

          <S.SeePricingButton>
            <Button
              data-test="see-pricing-button"
              className="action-button"
              onClick={() =>
                setActiveModalWithOrganization('createTeam', null)
              }>
              Create team
            </Button>
          </S.SeePricingButton>
        </S.AccountPlanContent>
      </>
    ) : (
      <>
        <S.SubscriptionCardPlanType data-test="plan-info">
          <S.UsagePlanContentItemTitle>Plan</S.UsagePlanContentItemTitle>
          {`${
            primarySub.subscriptionType ===
              OrganizationSubscriptionType.Academic ||
            primarySub.subscriptionType ===
              OrganizationSubscriptionType.AcademicTrial
              ? 'Academic'
              : planDisplayName(primarySub.plan.name)
          }, ${primarySub.seats} seats`}
          {primarySub.plan.unitPrice &&
            `; $${(primarySub.plan.unitPrice / 100) * primarySub.seats} / ${
              primarySub.plan.billingInterval
            }`}
        </S.SubscriptionCardPlanType>
        <S.SubscriptionCardPlanType data-test="plan-status">
          <S.UsagePlanContentItemTitle>Status</S.UsagePlanContentItemTitle>
          {subIsActive && 'Active'}
          {subIsTrialing && `In trial`}
          {subIsCanceled && 'Canceled'}
          {nextBillingDate &&
            !subIsCanceled &&
            !subWillCancel &&
            ` (next bill ${nextBillingDate})`}
          {subWillCancel &&
            ` (subscription ends ${
              nextBillingDate ?? 'at the end of the current cycle'
            })`}
          {subNeedsPayment && 'We had trouble receiving your payment.'}
          {subNeedsPayment && org.stripeBillingInfo?.invoiceLink && (
            <>
              {' Click '}
              <TargetBlank href={org.stripeBillingInfo!.invoiceLink}>
                here
              </TargetBlank>
              {' to complete your payment manually.'}
            </>
          )}
          , {isOrgAdminOrBillingOnlyUser && paymentMethod}
          {!isOrgAdminOrBillingOnlyUser && 'Managed by your admin'}
        </S.SubscriptionCardPlanType>
        <S.SubscriptionCardPlanType>
          <S.UsagePlanContentItemTitle>Teams</S.UsagePlanContentItemTitle>
          {teams}
        </S.SubscriptionCardPlanType>
        <S.SubscriptionCardPlanMembers data-test="members">
          <S.UsagePlanContentItemTitle>Members</S.UsagePlanContentItemTitle>
          {membersAndInvitees}
          <S.AddMembers
            data-test="edit-user-button"
            onClick={() => {
              setActiveModalWithOrganization('editUser', {
                id: org.id,
                name: org.name,
                subscriptionStatus: billingInfo?.status ?? '',
                cancelAtPeriodEnd: billingInfo?.cancelAtPeriodEnd ?? false,
                members: org.members,
                teams: org.teams,
                pendingInvites: org.pendingInvites,
                seatCount: primarySub.seats,
                billingUserName: org.billingUser.username,
              });
            }}>
            <S.ConfigIcon name="configuration" />
            Manage members
          </S.AddMembers>
        </S.SubscriptionCardPlanMembers>
        {needsToUpgradePlan && isOrgAdminOrBillingOnlyUser && (
          <S.UpgradePlanButton>
            <Button
              data-test="see-pricing-button"
              className="action-button"
              onClick={() => {
                window.analytics.track(
                  'billing page upgrade plan button clicked',
                  {
                    org: org.id,
                  }
                );
                setActiveModalWithOrganization('checkout', {
                  id: org.id,
                  name: org.name,
                  members: org.members,
                  pendingInvites: org.pendingInvites,
                  teams: org.teams,
                  seatCount: primarySub.seats,
                  subscriptionStatus: billingInfo?.status ?? '',
                  cancelAtPeriodEnd: billingInfo?.cancelAtPeriodEnd ?? false,
                });
              }}>
              Upgrade plan
            </Button>
          </S.UpgradePlanButton>
        )}
      </>
    );

    const usageContent = isPersonal ? (
      <>
        <S.UsagePlanContentVertical>
          <S.UsagePlanContentVerticalItem>
            <S.UsagePlanStatus>
              <S.UsagePlanContentItemTitle>Status</S.UsagePlanContentItemTitle>
              {subIsActive && 'Active'}
              {subIsTrialing && `In trial`}
              {subIsCanceled && 'Canceled'}
              {nextBillingDate &&
                !subIsCanceled &&
                !subWillCancel &&
                ` (will bill again on ${nextBillingDate})`}
              {subWillCancel &&
                ` (subscription ends ${
                  nextBillingDate ?? 'at the end of the current cycle'
                })`}
              {subNeedsPayment && 'We had trouble receiving your payment.'}
              {subNeedsPayment && org.stripeBillingInfo?.invoiceLink && (
                <>
                  {' Click '}
                  <TargetBlank href={org.stripeBillingInfo!.invoiceLink}>
                    here
                  </TargetBlank>
                  {' to complete your payment manually.'}
                </>
              )}
            </S.UsagePlanStatus>
          </S.UsagePlanContentVerticalItem>
          <S.UsagePlanTeamInfo>
            <S.UsagePlanTeamField>
              <S.UsagePlanTeamFieldTitle>Storage</S.UsagePlanTeamFieldTitle>
              {numeral(storageUsed).format('0.0b')}
            </S.UsagePlanTeamField>
            <S.UsagePlanTeamField>
              <S.UsagePlanTeamFieldTitle>Artifacts</S.UsagePlanTeamFieldTitle>
              {numeral(trackingUsed).format('0.0b')}
            </S.UsagePlanTeamField>
            <S.UsagePlanTeamField>
              <S.UsagePlanTeamFieldTitle>Compute</S.UsagePlanTeamFieldTitle>
              {new TimeDelta(computeHours).toHoursString()}
            </S.UsagePlanTeamField>
          </S.UsagePlanTeamInfo>
        </S.UsagePlanContentVertical>
        <S.SubscriptionCardLink>
          <S.ManageUsageLink to={storagePage(viewer.entity)}>
            <S.ConfigIcon name="configuration" /> Manage usage
          </S.ManageUsageLink>
        </S.SubscriptionCardLink>
      </>
    ) : (
      <>
        <S.UsagePlanTeams>
          {org.teams.map((t: any) => (
            <React.Fragment key={t.id}>
              <S.UsagePlanTeamName>{t.name}</S.UsagePlanTeamName>
              <S.UsagePlanTeamInfo>
                <S.UsagePlanTeamField>
                  <S.UsagePlanTeamFieldTitle>Storage</S.UsagePlanTeamFieldTitle>
                  {numeral(t.storage?.size ?? 0).format('0.0b')}
                </S.UsagePlanTeamField>
                <S.UsagePlanTeamField>
                  <S.UsagePlanTeamFieldTitle>
                    Artifacts
                  </S.UsagePlanTeamFieldTitle>
                  {numeral(t.artifact?.size ?? 0).format('0.0b')}
                </S.UsagePlanTeamField>
                <S.UsagePlanTeamField over={state != null}>
                  <S.UsagePlanTeamFieldTitle>Compute</S.UsagePlanTeamFieldTitle>
                  {new TimeDelta(t.computeHours).toHoursString()}
                </S.UsagePlanTeamField>
              </S.UsagePlanTeamInfo>

              <S.ManageUsageLink to={storagePage(t.name)}>
                <S.ConfigIcon name="configuration" /> Manage usage
              </S.ManageUsageLink>
            </React.Fragment>
          ))}

          {showUpgradePlanCTA && (
            <S.UsagePlanDescription>
              Upgrade your plan to include storage and artifacts beyond the base
              100 GB included in your plan.
            </S.UsagePlanDescription>
          )}

          <S.UsagePlanDescription>
            {showUpgradePlanCTA && isOrgAdminOrBillingOnlyUser && subIsStripe && (
              <S.SeePricingButton>
                <Button
                  data-test="upgrade-button"
                  className="action-button"
                  onClick={() => {
                    window.analytics.track('Subscribe to storage clicked', {
                      viewer,
                    });
                    setActiveModalWithOrganization('storageCheckout', {
                      id: org.id,
                      name: org.name,
                      members: org.members,
                      teams: org.teams,
                      pendingInvites: org.pendingInvites,
                      seatCount: primarySub.seats,
                      subscriptionStatus: billingInfo?.status ?? '',
                      cancelAtPeriodEnd:
                        billingInfo?.cancelAtPeriodEnd ?? false,
                    });
                  }}>
                  Upgrade
                </Button>
              </S.SeePricingButton>
            )}
          </S.UsagePlanDescription>
          <S.AddMembers
            data-test="contact-sales-button"
            onClick={() => {
              window.analytics.track('Org storage plan contact us clicked', {
                viewer,
              });
              window.open('http://wandb.com/demo');
            }}>
            <S.ConfigIcon name="chat" /> Contact sales
          </S.AddMembers>
        </S.UsagePlanTeams>
      </>
    );

    return (
      <>
        <S.SubscriptionOrgName data-test="org-name">
          {orgName}
        </S.SubscriptionOrgName>
        <S.SubscriptionCard>
          {needsToUpgradePlan &&
            (isOrgAdminOrBillingOnlyUser ? (
              <S.SubscriptionCardBanner>
                Your organization is over the limit of {state?.maxHours} compute
                hours. Please upgrade your plan to keep using W&amp;B.
              </S.SubscriptionCardBanner>
            ) : (
              <S.SubscriptionCardBanner>
                Your organization is over the limit of {state?.maxHours} compute
                hours. Contact your admin to keep using W&amp;B.
              </S.SubscriptionCardBanner>
            ))}
          <S.SubscriptionCardRow>
            <S.SubscriptionCardPlan>
              <S.SubscriptionCardPlanHeader>
                Account{' '}
                {!isPersonal && isOrgAdminOrBillingOnlyUser && orgAdminControls}
              </S.SubscriptionCardPlanHeader>
              {planContent}
            </S.SubscriptionCardPlan>
            <S.SubscriptionCardPlan>
              <S.SubscriptionCardPlanHeader>
                Usage {isPersonal && personalControls}
              </S.SubscriptionCardPlanHeader>
              {usageContent}
            </S.SubscriptionCardPlan>
          </S.SubscriptionCardRow>
        </S.SubscriptionCard>
      </>
    );
  },
  {id: 'OrgCard', memo: true}
);

const BillingPageActiveModal: React.FC = makeComp(
  () => {
    const viewer = useViewer();

    const {selectedOrganization, activeModal} = useContext(BillingPageContext);

    const {setActiveModalWithOrganization, refetchSubscriptionInfo} =
      useContext(BillingPageUpdaterContext);

    const closeModal = useCallback(() => {
      setActiveModalWithOrganization(null, null);
    }, [setActiveModalWithOrganization]);

    const {planInfo, storagePlanID, trackingPlanID} = useSubscriptionPlans();

    const checkoutOrg = useMemo(() => {
      if (selectedOrganization == null) {
        return null;
      }
      return {
        id: selectedOrganization.id,
        name: selectedOrganization.name,
        memberCount:
          selectedOrganization.members.length +
          (selectedOrganization.pendingInvites?.length ?? 0),
        teams: selectedOrganization.teams ?? [],
      };
    }, [selectedOrganization]);

    const selectedOrgOption = useMemo(() => {
      if (selectedOrganization == null) {
        return [];
      }
      return [
        {
          text: selectedOrganization.name ?? '',
          value: selectedOrganization.id ?? '',
        },
      ];
    }, [selectedOrganization]);

    if (viewer == null) {
      return null;
    }

    return (
      <>
        {activeModal === 'checkout' &&
          planInfo != null &&
          storagePlanID != null &&
          trackingPlanID != null &&
          checkoutOrg != null && (
            <CheckoutModal
              planInfo={planInfo}
              defaultSeats={checkoutOrg.memberCount}
              onTransactionCompleted={refetchSubscriptionInfo}
              onClose={closeModal}
              renderForm={({plan, seats, onTransactionCompleted}) => (
                <UpgradeSubscriptionForm
                  plan={plan}
                  seats={seats}
                  storagePlanID={storagePlanID}
                  trackingPlanID={trackingPlanID}
                  org={checkoutOrg}
                  onSubscriptionCompleted={onTransactionCompleted}
                />
              )}
              renderSuccess={() => <UpgradeSuccess org={checkoutOrg} />}
            />
          )}
        {activeModal === 'updatePayment' && (
          <UpdatePaymentModal
            custoEmail={viewer.email}
            onTransactionCompleted={refetchSubscriptionInfo}
            onClose={closeModal}
          />
        )}
        {activeModal === 'editUser' && selectedOrganization != null && (
          <EditUserModal
            open
            organizationId={selectedOrganization.id}
            onAddUser={refetchSubscriptionInfo}
            onRemoveUser={refetchSubscriptionInfo}
            onUpdateOrganizationUser={refetchSubscriptionInfo}
            onClose={closeModal}
          />
        )}
        {activeModal === 'editSubscription' && (
          <EditSubscriptionModal
            onPlanEdited={refetchSubscriptionInfo}
            onClose={closeModal}
          />
        )}
        {activeModal === 'createTeam' && (
          <CreateTeamModal
            open
            orgOptions={selectedOrgOption}
            onClose={closeModal}
            onCreate={() => {}}
          />
        )}
        {activeModal === 'storageCheckout' &&
          storagePlanID != null &&
          trackingPlanID != null && (
            <StorageCheckoutModal
              storagePlanID={storagePlanID}
              trackingPlanID={trackingPlanID}
              org={selectedOrganization}
              custoEmail={viewer.email}
              onTransactionCompleted={refetchSubscriptionInfo}
              onClose={closeModal}
            />
          )}
        {activeModal === 'storagePricing' && (
          <StoragePricingModal onClose={closeModal} />
        )}
      </>
    );
  },
  {id: 'BillingPageActiveModal', memo: true}
);

type PaymentModalProps = {
  custoEmail: string;
  onTransactionCompleted: () => void;
  onClose: () => void;
};

type StorageCheckoutModalProps = PaymentModalProps & {
  storagePlanID: string;
  trackingPlanID: string;
  org: OrganizationFragment | null;
};

const StorageCheckoutModal: React.FC<StorageCheckoutModalProps> = makeComp(
  props => {
    const {onClose} = props;
    const [showStorageSuccess, setShowStorageSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const contextValue = useMemo(() => ({submitting}), [submitting]);
    const updaterContextValue = useMemo(() => ({setSubmitting}), []);

    const onCloseWhenNotSubmitting = useCallback(() => {
      if (!submitting) {
        onClose();
      }
    }, [submitting, onClose]);

    return (
      <CheckoutModalContext.Provider value={contextValue}>
        <CheckoutModalUpdaterContext.Provider value={updaterContextValue}>
          <Modal
            open
            basic
            className="checkout-modal"
            onClose={onCloseWhenNotSubmitting}>
            {showStorageSuccess ? (
              <StorageSubscriptionSuccessForm {...props} onClose={onClose} />
            ) : (
              <StorageCheckoutModalForm
                {...props}
                onTransactionCompleted={() => {
                  setShowStorageSuccess(true);
                  props.onTransactionCompleted();
                }}
              />
            )}
          </Modal>
        </CheckoutModalUpdaterContext.Provider>
      </CheckoutModalContext.Provider>
    );
  },
  {id: 'StorageCheckoutModal', memo: true}
);

const StorageCheckoutModalForm: React.FC<StorageCheckoutModalProps> = makeComp(
  ({storagePlanID, trackingPlanID, org, onTransactionCompleted}) => {
    const {storageUsed, trackingUsed} = useContext(BillingPageContext);

    const storagePricing = new Map([
      [storageTiers[2], storagePrices[3]],
      [storageTiers[1], storagePrices[2]],
      [storageTiers[0], storagePrices[1]],
    ]);
    const artifactPricing = new Map([
      [storageTiers[2], artifactPrices[3]],
      [storageTiers[1], artifactPrices[2]],
      [storageTiers[0], artifactPrices[1]],
    ]);
    const estimatedBill = (storageUsage: number, artifactUsage: number) => {
      let total = 0.0;
      const calculateTotal = (pricing: Map<number, number>, usage: number) => {
        pricing.forEach((value: number, key: number) => {
          if (usage > key) {
            total += ((usage - key) / bytesInGB) * value;
            usage = key;
          }
        });
      };

      calculateTotal(storagePricing, storageUsage);
      calculateTotal(artifactPricing, artifactUsage);

      return total;
    };

    return (
      <div className="checkout pricing-panel" style={{width: '400px'}}>
        <S.UsageSubscriptionPopup>
          <S.UsageSubscriptionPopupTitle>
            {storageTitle}
          </S.UsageSubscriptionPopupTitle>
          <S.UsageSubscriptionPopupContent>
            {storageSubtitle}
          </S.UsageSubscriptionPopupContent>
          <S.PricingTable>
            <S.PricingTableRow>
              <S.PricingTableHeader>{storageFeatureTitle}</S.PricingTableHeader>
              <S.PricingTableHeader>
                {artifactFeatureTitle}
              </S.PricingTableHeader>
            </S.PricingTableRow>
            <S.PricingTableRow>
              <S.PricingTableBody>
                {storageFeatures.map((tf, i) => (
                  <S.PricingTableFeatureRow key={i}>
                    {tf}
                  </S.PricingTableFeatureRow>
                ))}
              </S.PricingTableBody>
              <S.PricingTableBody>
                {artifactFeatures.map((tf, i) => (
                  <S.PricingTableFeatureRow key={i}>
                    {tf}
                  </S.PricingTableFeatureRow>
                ))}
              </S.PricingTableBody>
            </S.PricingTableRow>
          </S.PricingTable>
          <S.UsageSubscriptionPopupContent>
            You are currently using {numeral(storageUsed).format('0.0b')} of
            storage and {numeral(trackingUsed).format('0.0b')} of artifacts, so
            your bill would be about{' '}
            {estimatedBill(storageUsed, trackingUsed).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            .
          </S.UsageSubscriptionPopupContent>
          <div className="checkout-summary" style={{minHeight: '0px'}}>
            <StorageSubscribeForm
              storagePlanID={storagePlanID}
              trackingPlanID={trackingPlanID}
              orgID={org?.id}
              onSubscriptionCompleted={onTransactionCompleted}
            />
          </div>
        </S.UsageSubscriptionPopup>
      </div>
    );
  },
  {id: 'StorageCheckoutModalForm', memo: true}
);

type StoragePricingModalProps = {onClose: () => void};

const StoragePricingModal: React.FC<StoragePricingModalProps> = makeComp(
  ({onClose}) => {
    return (
      <Modal open basic className="checkout-modal" onClose={onClose}>
        <div className="checkout pricing-panel" style={{width: '406px'}}>
          <S.UsageSubscriptionPopup>
            <S.UsageSubscriptionPopupTitle>
              {storageTitle}
            </S.UsageSubscriptionPopupTitle>
            <S.UsageSubscriptionPopupContent>
              Your subscription lets you only pay for the storage and artifacts
              that you use each month.
            </S.UsageSubscriptionPopupContent>
            <S.PricingTable>
              <S.PricingTableRow>
                <S.PricingTableHeader>
                  {storageFeatureTitle}
                </S.PricingTableHeader>
                <S.PricingTableHeader>
                  {artifactFeatureTitle}
                </S.PricingTableHeader>
              </S.PricingTableRow>

              <S.PricingTableRow>
                <S.PricingTableBody>
                  {storageFeatures.map((tf, i) => (
                    <S.PricingTableFeatureRow key={i}>
                      {tf}
                    </S.PricingTableFeatureRow>
                  ))}
                </S.PricingTableBody>
                <S.PricingTableBody>
                  {artifactFeatures.map((tf, i) => (
                    <S.PricingTableFeatureRow key={i}>
                      {tf}
                    </S.PricingTableFeatureRow>
                  ))}
                </S.PricingTableBody>
              </S.PricingTableRow>
            </S.PricingTable>
            <S.UsageSubscriptionPopupContent>
              Need a bespoke plan? Contact us to discuss custom pricing.
            </S.UsageSubscriptionPopupContent>
            <S.UsageSubscriptionPopupContent>
              <Button
                className="action-button"
                onClick={() => {
                  window.analytics.track('Storage plan contact us clicked');
                  window.open('http://wandb.com/demo');
                }}>
                Contact us
              </Button>
            </S.UsageSubscriptionPopupContent>
          </S.UsageSubscriptionPopup>
        </div>
      </Modal>
    );
  },
  {id: 'StorageCheckoutModal', memo: true}
);

type StorageSubscriptionSuccessFormProps = {
  onClose: () => void;
};

const StorageSubscriptionSuccessForm: React.FC<StorageSubscriptionSuccessFormProps> =
  makeComp(
    ({onClose}) => {
      return (
        <div className="checkout pricing-panel" style={{width: '406px'}}>
          <S.UsageSubscriptionPopup>
            <S.UsageSubscriptionPopupTitle>
              Additional space unlocked
            </S.UsageSubscriptionPopupTitle>
            <S.UsageSubscriptionPopupContent>
              Thanks for subscribing to a storage and artifacts plan to unlock
              additional space for your ML projects. Feel free to contact us
              with questions and feedback.
            </S.UsageSubscriptionPopupContent>
            <S.ConfirmationButtons>
              <Button
                className="contact-button"
                width="unset"
                onClick={() => {
                  window.analytics.track('Storage plan contact us clicked');
                  window.open('http://wandb.com/demo');
                }}>
                Contact us
              </Button>
              <Button
                className="action-button"
                width="unset"
                onClick={() => {
                  window.analytics.track('Storage confirmation OK clicked');
                  onClose();
                }}>
                OK
              </Button>
            </S.ConfirmationButtons>
          </S.UsageSubscriptionPopup>
        </div>
      );
    },
    {id: 'SubscriptionOnboardingForm', memo: true}
  );

type UpdatePaymentModalProps = PaymentModalProps;

const UpdatePaymentModal: React.FC<UpdatePaymentModalProps> = makeComp(
  ({custoEmail, onTransactionCompleted, onClose}) => {
    const {selectedOrganization} = useContext(BillingPageContext);

    if (selectedOrganization == null) {
      return null;
    }

    return (
      <Modal open basic onClose={onClose} className="checkout-modal">
        <div className="update-payment pricing-panel">
          <div className="header">
            <div className="panel-title">Update payment method</div>
          </div>
          <div className="checkout-content">
            <UpdatePaymentForm
              custoEmail={custoEmail}
              organizationId={selectedOrganization.id}
              onUpdateCompleted={onTransactionCompleted}
            />
          </div>
        </div>
      </Modal>
    );
  },
  {id: 'UpdatePaymentModal', memo: true}
);

type EditSubscriptionModalProps = {
  onPlanEdited: () => void;
  onClose: () => void;
};

const EditSubscriptionModal: React.FC<EditSubscriptionModalProps> = makeComp(
  props => {
    const {onPlanEdited, onClose} = props;
    const {selectedOrganization} = useContext(BillingPageContext);

    if (selectedOrganization == null) {
      return null;
    }

    return (
      <Modal open basic onClose={onClose} className="checkout-modal">
        <div className="update-payment pricing-panel">
          <div className="header">
            <div className="panel-title">Change plan</div>
          </div>
          <div className="checkout-content">
            <EditSubscriptionForm
              organizationId={selectedOrganization.id}
              isTrial={selectedOrganization.subscriptionStatus === 'trialing'}
              isCanceled={
                selectedOrganization.cancelAtPeriodEnd ||
                selectedOrganization.subscriptionStatus === 'canceled' ||
                selectedOrganization.subscriptionStatus === 'incomplete_expired'
              }
              onPlanEdited={onPlanEdited}
            />
          </div>
        </div>
      </Modal>
    );
  },
  {id: 'EditSubscriptionModal', memo: true}
);

export default BillingPage;
