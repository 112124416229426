// Just handles the old report url (where we used a ?view query string
// with the username and name), to redirect to the new url, where we just
// use the report ID.
import '../css/ReportPage.less';

import * as _ from 'lodash';
import * as React from 'react';
import {useEffect} from 'react';
import * as QueryString from 'query-string';

import Loader from '../components/WandbLoader';
import * as Urls from '../util/urls';
import * as String from '@wandb/cg/browser/utils/string';

import * as ReportHooks from '../state/reports/hooks';
import makeComp from '../util/profiler';

// Declare the segment analytics object

interface ReportPageOldProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
    };
  };
  history: any;
}

const ReportPageEditOld = makeComp(
  (props: ReportPageOldProps) => {
    const {entityName, projectName} = props.match.params;

    const viewParam =
      QueryString.parse(window.location.search).view || 'New Report';
    const firstView = _.isArray(viewParam) ? viewParam[0] : viewParam;
    const [userName, viewName]: [string | null, string | null] =
      String.splitOnce(firstView, '/');

    if (userName == null || viewName == null) {
      throw new Error('invalid');
    }

    const {loading, loadedReportID} = ReportHooks.useLoadReportByName(
      entityName,
      projectName,
      userName,
      viewName
    );

    useEffect(() => {
      if (!loading) {
        if (loadedReportID != null) {
          props.history.replace(
            Urls.reportView({
              entityName,
              projectName,
              reportID: loadedReportID,
            })
          );
        } else {
          throw new Error('invalid');
        }
      }
    });

    return <Loader />;
  },
  {id: 'ReportPageEditOld'}
);

export default ReportPageEditOld;
