import _ from 'lodash';
import React, {FC, useState, useEffect, useCallback} from 'react';
import {Segment} from 'semantic-ui-react';
import {View as VegaView} from 'vega';
import makeComp from '../../util/profiler';
import ObjectInspector from 'react-inspector';

interface VegaPanelDataPaneProps {
  view: VegaView | null;
}

const VegaPanelDataPane: FC<VegaPanelDataPaneProps> = makeComp(
  ({view}) => {
    const [data, setData] = useState<{
      [key: string]: any;
    }>({});
    const [signals, setSignals] = useState<{
      [key: string]: any;
    }>({});

    const dataListener = useCallback(
      (name: string, value: any) => {
        if (data[name] != null && data[name] !== value) {
          setData({...data, [name]: value});
        }
      },
      [data]
    );

    const signalListener = useCallback(
      (name: string, value: any) => {
        if (signals[name] != null && signals[name] !== value) {
          setSignals({...signals, [name]: value});
        }
      },
      [signals]
    );

    useEffect(() => {
      if (view == null) {
        return;
      }
      const state = view.getState({
        data: n => true,
        signals: n => true,
        recurse: true,
      });

      // Remove data listeners for keys that no longer exist
      for (const haveDataKey of _.keys(data)) {
        if (state.data[haveDataKey] == null) {
          view.removeDataListener(haveDataKey, dataListener);
        }
      }

      // Remove signal listeners for keys that no longer exist
      for (const haveSignalKey of _.keys(signals)) {
        if (state.signals[haveSignalKey] == null) {
          view.removeSignalListener(haveSignalKey, signalListener);
        }
      }

      // Add data listeners for new keys
      const addDataKeys: string[] = [];
      for (const currentDataKey of _.keys(state.data)) {
        if (data[currentDataKey] == null) {
          addDataKeys.push(currentDataKey);
          view.addDataListener(currentDataKey, dataListener);
        }
      }
      if (addDataKeys.length > 0) {
        const newKeyVals = _.fromPairs(
          addDataKeys.map(k => [k, state.data[k]])
        );
        setData({
          ...data,
          ...newKeyVals,
        });
      }

      // Add signal listeners for new keys
      const addSignalKeys: string[] = [];
      for (const currentSignalKey of _.keys(state.signals)) {
        if (!signals.hasOwnProperty(currentSignalKey)) {
          addSignalKeys.push(currentSignalKey);
          view.addSignalListener(currentSignalKey, signalListener);
        }
      }
      if (addSignalKeys.length > 0) {
        const newKeyVals = _.fromPairs(
          addSignalKeys.map(k => [k, state.signals[k]])
        );
        setSignals({
          ...signals,
          ...newKeyVals,
        });
      }
    }, [data, dataListener, signals, signalListener, view]);

    if (view == null) {
      return <Segment>Vega view not available</Segment>;
    }

    return (
      <>
        <h4>Vega data</h4>
        <p className="hint-text">
          The current state of any data tables in your Vega view. The "runs" and
          "history" tables are injected into your Vega view by the W&B UI. They
          are dynamic, and will update in response to the context in which your
          visualization is placed.
        </p>
        <div>
          <ObjectInspector expandLevel={1} data={data} />
        </div>
        <h4>Vega signals</h4>
        <div>
          <p className="hint-text">
            The current state of any signals in your Vega view.
          </p>
          <ObjectInspector expandLevel={1} data={signals} />
        </div>
      </>
    );
  },
  {id: 'VegaPanelDataPane', memo: true}
);

export default VegaPanelDataPane;
