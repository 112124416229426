import React from 'react';

import * as Query from '../../../util/queryts';

import makeComp from '../../../util/profiler';
import {
  DFTableActionGroup,
  DFTableActionGroupButton,
  DFTableActionGroupDropdown,
  DFTableActionGroupPicker,
} from './DFTableActionsGroup';

interface DataFrameGroupPickerProps {
  recommendedGrouping?: Query.Grouping;
  grouping: Query.Grouping;
  loading: boolean;
  groupableKeys: string[];
  disabled?: boolean;
  setGrouping(group: Query.Grouping): void;
}

export const DataFrameGroupTableAction = makeComp(
  (props: DataFrameGroupPickerProps) => {
    return (
      <DFTableActionGroup
        trigger={open => (
          <DFTableActionGroupButton
            groupOpen={open}
            grouping={props.grouping}
          />
        )}
        content={
          <DFTableActionGroupPicker
            grouping={props.grouping}
            setGrouping={props.setGrouping}
            recommendedGrouping={props.recommendedGrouping}
            dropdown={
              <DFTableActionGroupDropdown
                groupableKeys={props.groupableKeys}
                loading={props.loading}
                grouping={props.grouping}
                setGrouping={props.setGrouping}
              />
            }
          />
        }
      />
    );
  },
  {id: 'DataFrameGroupTableAction'}
);
