import styled, {css} from 'styled-components';
import {
  Button as SemanticButton,
  Dropdown as SemanticDropdown,
  Input as SemanticInput,
  Message as SemanticMessage,
} from 'semantic-ui-react';
import {gray500, blue, white} from '../css/globals.styles';
import {WBIcon} from '@wandb/ui';

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  margin: 32px;
  max-width: 470px;
`;

export const Header = styled.h1``;

export const Subheader = styled.div`
  color: ${gray500};
`;

export const ProjectExistsMessage = styled(SemanticMessage)`
  &&&&& {
    margin-top: 28px;
  }
`;

export const FormRow = styled.div<{justifyRight?: boolean}>`
  margin-top: 28px;
  display: flex;
  justify-content: ${props => (props.justifyRight ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

export const FormItem = styled.div<{
  inline?: boolean;
  short?: boolean;
  noGrow?: boolean;
}>`
  width: ${props =>
    props.inline
      ? `160px`
      : `
      100%
    `};
  ${props => !props.noGrow && `flex-grow: 1;`}
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  margin-bottom: 12px;
`;

export const Sublabel = styled.span`
  font-size: 16px;
  color: ${gray500};
`;

export const Input = styled(SemanticInput)`
  width: 100%;
`;

export const Dropdown = styled(SemanticDropdown)`
  height: 39.56px;
  width: 100%;
  padding: 0.58035714em 0.875em;
  background: ${white};
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25em;
  line-height: 1.21428571em;

  &&&&& .text {
    width: 100%;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &&&&& i.icon {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0.875em;
  }
`;

export const AccessRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: pointer;
`;

export const AccessRowRadio = styled.div<{active?: boolean}>`
  width: 24px;
  height: 24px;
  border: 1px solid ${gray500};
  border-radius: 50%;
  flex-shrink: 0;

  ${props =>
    props.active &&
    css`
      background-color: ${blue};
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 50%;
        background-color: ${white};
      }
    `}
`;

export const AccessRowIcon = styled(WBIcon)`
  font-size: 26px;
  color: ${gray500};
  margin: 0 8px 0 16px;
  flex-shrink: 0;
`;

export const Slash = styled.div`
  font-size: 32px;
  padding-top: 32px;
  margin: 0 8px;
`;

export const Button = styled(SemanticButton)``;
