import {WBMenuOption} from '@wandb/ui';
import _ from 'lodash';
import React, {useCallback, useLayoutEffect, useRef} from 'react';
import {TABLE_MIN_COLUMN_WIDTH} from '../../../util/constants';
import {ValueOp} from '../../../util/filters';
import makeComp from '../../../util/profiler';
import {Sort as QuerySort} from '../../../util/queryts';
import {Config as RunFeedDataConfig} from '../../../util/runfeed';
import {
  Key as RunKey,
  keyToCss,
  keyToString,
  Value as RunValue,
} from '../../../util/runs';
import {makePropsAreEqual} from '../../../util/shouldUpdate';
import * as DFTreeHelpers from './dataframesUtil';
import {
  DFTableColumn,
  DFTableHoverCellCoords,
  DFTableRowInterface,
} from './DFTable';
import './DFTable.less';
import {DFTableColumnHeaders} from './DFTableColumnHeaders';
// import {DFTreeHeaderRow} from './DFTreeHeaderRow';
import {DFTableRow} from './DFTableRow';
import {DFTableSortIndicatorComponent} from './DFTableSortIndicator';

interface DFTableGridProps {
  loadingTable: boolean; // from parent
  treeRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  pinned?: boolean;
  columns: DFTableColumn[];
  fixedColumns: string[];
  rows: DFTableRowInterface[];
  expandedRowAddresses: string[];
  loadingRowAddresses: string[];
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  childrenPaginationMap: {
    [key: string]: number | undefined;
  };
  hoverCellCoords: DFTableHoverCellCoords;
  tableSettings: RunFeedDataConfig;
  leftMargin: number;
  scrollXBounds: [number, number];
  expandable?: boolean;
  expanded?: boolean;
  readOnly?: boolean;
  SortIndicatorComponent?: DFTableSortIndicatorComponent;
  setTableSettings(config: Partial<RunFeedDataConfig>): void;
  setTableState(stateUpdate: {
    columnDragAccessor?: string;
    columnDropAccessor?: string;
    columnResizingAccessor?: string;
    columnResizeOffset?: number;
  }): void;
  setHoverCellCoords(newCoords: DFTableHoverCellCoords): void;
  setChildrenPagination(rowAddress: string, activePage?: number): void;
  addGroup(newGroup: RunKey): void;
  moveColumn(): void;
  togglePinnedColumn(columnAccessor: string): void; // add column to (or remove from) pinnedColumnKeys
  hideColumn(columnAccessor: string): void;
  toggleExpandedRow(rowAddress: string): void; // expand or collapse this row (add/remove rowAddress from expandedRowAddresses)
  openSortPopup?(): void;
  // Query actions
  updateSort(updateFn: (sort: QuerySort) => void): void;
  addFilter(key: RunKey, op: ValueOp, value: RunValue): void;
}

export interface VisibleColumns {
  leftPadding: number;
  rightPadding: number;
  visibleColumnRange: [number, number];
}

export const getVisibleColumns = (
  scrollXBounds: [number, number],
  columns: DFTableColumn[],
  columnWidths?: {
    [keys: string]: number;
  }
): VisibleColumns => {
  const actualWidths: number[] = Array(columns.length);
  let totalWidth = 0;
  columns.forEach((column, columnIndex) => {
    const columnKeyString = keyToString(column.key);
    const columnWidth =
      (columnWidths && columnWidths[columnKeyString]) ||
      DFTreeHelpers.defaultColumnWidth(column.key);
    actualWidths[columnIndex] = columnWidth;
    totalWidth += columnWidth;
  });

  let [minX, maxX] = scrollXBounds;
  const span = maxX - minX;

  if (minX > totalWidth - span - 500) {
    minX = totalWidth - span - 500;
    maxX = totalWidth + 500;
  }

  let currentX = 0;
  let minColumn = columns.length;
  let maxColumn = 0;
  let leftPadding = 0;
  let rightPadding = 0;

  columns.forEach((column, columnIndex) => {
    const columnWidth = actualWidths[columnIndex];
    if (currentX >= minX - 500) {
      minColumn = Math.min(minColumn, columnIndex);
    } else {
      leftPadding += columnWidth;
    }
    if (currentX <= maxX + 500) {
      maxColumn = Math.max(maxColumn, columnIndex + 1);
    } else {
      rightPadding += columnWidth;
    }
    currentX += columnWidth;
  });

  return {
    leftPadding,
    rightPadding,
    visibleColumnRange: [minColumn, maxColumn],
  };
};

export const DFTableGrid: React.FC<DFTableGridProps> = makeComp(
  ({
    loadingTable,
    pinned,
    className,
    rows,
    addFilter,
    updateSort,
    tableSettings,
    setTableSettings,
    moveColumn,
    columnDragAccessor,
    columnDropAccessor,
    columnResizingAccessor,
    columns,
    fixedColumns,
    treeRef,
    togglePinnedColumn,
    hideColumn,
    expandedRowAddresses,
    loadingRowAddresses,
    leftMargin,
    toggleExpandedRow,
    setTableState,
    childrenPaginationMap,
    setChildrenPagination,
    addGroup,
    hoverCellCoords,
    setHoverCellCoords,
    scrollXBounds,
    expandable,
    expanded,
    SortIndicatorComponent,
    readOnly,
    openSortPopup,
  }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const trickPanelsIntoResizing = useCallback(() => {
      // panels adapt to window resizes,
      // so just mock a resize
      window.dispatchEvent(new Event('resize'));
    }, []);

    useLayoutEffect(() => {
      trickPanelsIntoResizing();
    }, [trickPanelsIntoResizing]);

    const columnMenuItems = (column: DFTableColumn): WBMenuOption[] => {
      const items: WBMenuOption[] = [];
      if (fixedColumns.indexOf(column.accessor) === -1) {
        items.push(
          {
            value: 'pin',
            name: `${pinned ? 'Unpin' : 'Pin'} column`,
            icon: 'pin',
            onSelect: () => togglePinnedColumn(column.accessor),
          },
          {
            value: 'hide',
            name: `Hide column`,
            icon: 'hide',
            onSelect: () => hideColumn(column.accessor),
          }
        );
      }
      const key = column.key;
      if (key == null) {
        return items;
      }
      if (key.section === 'config') {
        items.push({
          value: 'group',
          name: 'Group by',
          icon: 'folder',
          onSelect: () => {
            addGroup(key);
          },
        });
      }
      if (!_.includes(['Tags'], column.displayName)) {
        const columnSortTextAsc =
          column.displayName === 'Created' ? 'Oldest first' : 'Sort asc';
        items.push({
          value: 'sortasc',
          name: columnSortTextAsc,
          icon: 'up-arrow',
          onSelect: () => {
            if (
              _.isUndefined(columnDragAccessor) &&
              !_.includes(['Tags'], column.displayName)
            ) {
              updateSort(sort => {
                if (sort.keys.length <= 1) {
                  sort.keys = [{key, ascending: true}];
                  return;
                }
                const sortKey = sort.keys.find(sk => _.isEqual(sk.key, key));
                if (sortKey != null) {
                  sortKey.ascending = true;
                } else {
                  sort.keys.push({key, ascending: true});
                }
                openSortPopup?.();
              });
            }
          },
        });
        const columnSortTextDesc =
          column.displayName === 'Created' ? 'Newest first' : 'Sort desc';
        items.push({
          value: 'sortdesc',
          name: columnSortTextDesc,
          icon: 'down-arrow',
          onSelect: () => {
            if (
              _.isUndefined(columnDragAccessor) &&
              !_.includes(['Tags'], column.displayName)
            ) {
              updateSort(sort => {
                if (sort.keys.length <= 1) {
                  sort.keys = [{key, ascending: false}];
                  return;
                }
                const sortKey = sort.keys.find(sk => _.isEqual(sk.key, key));
                if (sortKey != null) {
                  sortKey.ascending = false;
                } else {
                  sort.keys.push({key, ascending: false});
                }
                openSortPopup?.();
              });
            }
          },
        });
      }
      return items;
    };

    const visibleColumns = getVisibleColumns(
      scrollXBounds,
      columns,
      tableSettings.columnWidths
    );
    const [minColumn, maxColumn] = visibleColumns.visibleColumnRange;
    const columnCount = maxColumn - minColumn;

    let columnHeight =
      treeRef && treeRef.current && !isNaN(treeRef.current.clientHeight)
        ? treeRef.current.clientHeight - 44
        : 0;
    if (expandable) {
      // adjust for 52px of padding used to
      // prevent pagination controls from blocking text
      columnHeight -= 52;
    }

    return (
      <React.Fragment>
        <div
          ref={wrapperRef}
          className={`df-tree--${pinned ? 'pinned-' : ''}wrapper`}>
          {!pinned && (
            // This crazy thing was the only way I could get the
            // header background to be full width and sticky
            // when the grid isn't full width.
            // Let me know if you find a better way.
            <div
              className="df-tree-header-background-wrapper"
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                left: 0,
                right: 0,
                height: columnHeight,
                marginBottom: -columnHeight,
              }}>
              <div className="df-tree-header-background" />
            </div>
          )}
          <div
            ref={treeRef}
            className={`df-tree${pinned ? ' df-tree--pinned' : ''} ${
              className || ''
            }${loadingTable ? ' df-tree--loading' : ''}
          `}
            style={{
              gridTemplateColumns: `${visibleColumns.leftPadding}px repeat(${columnCount}, fit-content(${TABLE_MIN_COLUMN_WIDTH}px)) ${visibleColumns.rightPadding}px`,
              marginLeft: leftMargin,
            }}>
            {pinned && <div className="df-tree-header-background" />}

            <DFTableColumnHeaders
              displayedRows={rows}
              columns={columns.slice(minColumn, maxColumn)}
              columnDragAccessor={columnDragAccessor}
              columnDropAccessor={columnDropAccessor}
              columnResizingAccessor={columnResizingAccessor}
              hoverCellCoords={hoverCellCoords}
              expanded={expanded}
              SortIndicatorComponent={SortIndicatorComponent}
              childProps={(column, columnIndex) => {
                const columnAccessor = column.accessor;
                const actualColumnIndex = columnIndex + minColumn;
                return {
                  readOnly,
                  column,
                  columnIndex: actualColumnIndex,
                  columnWidth:
                    (tableSettings.columnWidths &&
                      tableSettings.columnWidths[columnAccessor]) ||
                    DFTreeHelpers.defaultColumnWidth(column.key),
                  draggable: fixedColumns.indexOf(column.accessor) === -1,
                  hovering:
                    columnResizingAccessor == null &&
                    hoverCellCoords &&
                    hoverCellCoords[1] === keyToCss(column.key),
                  cellHoverProps: {
                    onMouseEnter: () => {
                      setHoverCellCoords([undefined, keyToCss(column.key)]);
                    },
                    onMouseLeave: () => {
                      setHoverCellCoords([]);
                    },
                  },
                  columnMenuItems: columnMenuItems(column),
                  columnMovingProps: {
                    dragging: columnDragAccessor === columnAccessor,
                    dropping: columnDropAccessor === columnAccessor,
                    dragHandleProps: {
                      onMouseDown: () =>
                        setTableState({columnDragAccessor: columnAccessor}),
                      onMouseUp: () =>
                        setTableState({columnDragAccessor: undefined}),
                    },
                    onDragEnter: () => {
                      setTableState({columnDropAccessor: columnAccessor});
                    },
                    onDragEnd: () => {
                      setTableState({
                        columnDragAccessor: undefined,
                        columnDropAccessor: undefined,
                      });
                    },
                    onDrop: () => {
                      if (columnDragAccessor) {
                        moveColumn();
                      }
                    },
                  },
                  columnResizingProps: {
                    resizing: columnResizingAccessor === columnAccessor,
                    onResizeStart: () => {
                      setTableState({columnResizingAccessor: columnAccessor});
                    },
                    onResize: offset => {
                      setTableState({columnResizeOffset: offset});
                    },
                    resizeColumn: (newWidth: number) => {
                      setTableState({
                        columnResizingAccessor: undefined,
                        columnResizeOffset: 0,
                      });
                      setTableSettings({
                        columnWidths: {
                          ...tableSettings.columnWidths,
                          [columnAccessor]: newWidth,
                        },
                      });
                      trickPanelsIntoResizing();
                    },
                  },
                };
              }}
            />
            <div className="df-tree-padding" />
            {columns.slice(minColumn, maxColumn).map((column, columnIndex) => {
              const columnAccessor = column.accessor;
              const keyCss = keyToCss(column.key);
              const hovering =
                columnResizingAccessor == null &&
                hoverCellCoords &&
                hoverCellCoords[1] === keyCss &&
                keyCss !== 'run_name';
              const resizing = columnResizingAccessor === columnAccessor;
              const dropping = columnDropAccessor === columnAccessor;
              return (
                <div
                  style={{
                    height: Math.max(columnHeight, 0),
                    marginBottom: Math.min(-columnHeight, 0),
                  }}
                  key={`background-${columnIndex}`}
                  className={`df-tree-cell-column-background${
                    hovering ? ' df-tree-cell--hovering' : ''
                  }${resizing ? ' df-tree-cell--resizing' : ''}${
                    dropping ? ' df-tree-cell--dropping' : ''
                  }`}
                />
              );
            })}
            <div className="df-tree-padding" />

            {rows.map(r => {
              return (
                <DFTableRow
                  key={r.__address__}
                  columns={columns.slice(minColumn, maxColumn)}
                  loadingRowAddresses={loadingRowAddresses}
                  row={r}
                  pinned={pinned}
                  recursionDepth={0}
                  expandedRowAddresses={expandedRowAddresses}
                  toggleExpandedRow={toggleExpandedRow}
                  expanded={expanded}
                  setHoverCellCoords={setHoverCellCoords}
                  addFilter={addFilter}
                  childrenPaginationMap={childrenPaginationMap}
                  setChildrenPagination={setChildrenPagination}
                  hoverCellCoords={hoverCellCoords}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  },
  {
    id: 'DFTableGrid',
    memo: (prevProps, nextProps) => {
      if (
        !_.isEqual(
          getVisibleColumns(
            prevProps.scrollXBounds,
            prevProps.columns,
            prevProps.tableSettings.columnWidths
          ),
          getVisibleColumns(
            nextProps.scrollXBounds,
            nextProps.columns,
            nextProps.tableSettings.columnWidths
          )
        )
      ) {
        return false;
      }
      if (nextProps.rows.length !== prevProps.rows.length) {
        return false;
      }
      for (let i = 0; i < prevProps.rows.length; i++) {
        if (prevProps.rows[i].__address__ !== nextProps.rows[i].__address__) {
          return false;
        }
      }
      const propsAreEqual = makePropsAreEqual({
        name: 'DFTableGrid',
        deep: [
          'tempSelectedCount',
          'fixedColumns',
          'loadingRowAddresses',
          'expandedRowAddresses',
        ],
        // handled in shouldComponentUpdate
        ignore: ['scrollXBounds'],
        ignoreFunctions: true,
        debug: false,
        verbose: true,
      });
      return propsAreEqual(prevProps, nextProps);
    },
  }
);

export default DFTableGrid;
