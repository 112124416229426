import React from 'react';
import * as Panels from '../util/panels';
import * as QueryTS from '../util/queryts';
import {toRunsDataQuery} from '../containers/RunsDataLoader';
import * as BuiltinPanelDefs from './Vega2/builtinPanelDefs';
import {
  derived,
  PanelConfigSpecToProps,
} from './property-editors/property-editors';
import * as VegaLib3 from '../util/vega3';
import VegaViz from './Vega2/VegaViz';
import {Loader} from 'semantic-ui-react';
import {useRunsQueryContext} from '../state/runs/hooks';
import {useVegaPanelQuery, View} from '../state/graphql/vega2PanelQuery';
import {getConfigWithDefaults} from './Inspector';
import makeComp from '../util/profiler';

const PANEL_TYPE = 'Vega3';

/* eslint-disable no-template-curly-in-string */
const configSpec = {
  query: {
    editor: 'query' as const,
    default: VegaLib3.defaultRunSetsQuery.queryFields,
    fixedFields: VegaLib3.fixedRunSetsQuery.queryFields,
  },
  vegaSpec: {
    editor: 'vega-spec' as const,
    default: {panelDefId: BuiltinPanelDefs.IDS[1]},
  },
  mapping: {
    editor: 'vega-mapping' as const,
    default: {name: 'runSets_name', value: 'runSets_summary_loss'},
    query: derived((config: any) => config.query),
    vegaSpec: derived((config: any) => config.vegaSpec),
  },
};
/* eslint-enable no-template-curly-in-string */

function transformQuery(
  query: QueryTS.Query,
  config: PanelConfigSpecToProps<typeof configSpec>
) {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

interface InnerProps {
  config: PanelConfigSpecToProps<typeof configSpec>;
  views: View[];
  pageQuery: QueryTS.Query;
}

const Vega3PanelInner: React.FC<InnerProps> = makeComp(
  props => {
    const context = useRunsQueryContext();
    const spec = VegaLib3.getSpec(props.config.vegaSpec, props.views);
    const {templateVals} = VegaLib3.queryTemplates(props.pageQuery, context);
    const {result} = VegaLib3.useVegaQuery(
      {queryFields: props.config.query},
      VegaLib3.DEFAULT_TRANSFORM,
      templateVals
    );
    return (
      <VegaViz
        spec={spec}
        data={result}
        userSettings={{
          fieldSettings: props.config.mapping,
          stringSettings: {},
        }}
      />
    );
  },
  {id: 'Vega3PanelInner'}
);

const Vega3Panel: React.FC<
  Panels.PanelProps<PanelConfigSpecToProps<typeof configSpec>>
> = makeComp(
  props => {
    const config = getConfigWithDefaults(configSpec, props.config);
    const context = useRunsQueryContext();
    const vegaQuery = useVegaPanelQuery({
      entityName: context.entityName,
      projectName: context.projectName,
      viewId: config.vegaSpec.panelDefId,
    });
    if (vegaQuery.loading) {
      return <Loader></Loader>;
    }
    return (
      <Vega3PanelInner
        config={config}
        views={vegaQuery.views}
        pageQuery={props.pageQuery}
      />
    );
  },
  {id: 'Vega3Panel'}
);

export const Spec: Panels.PanelSpec<
  typeof PANEL_TYPE,
  PanelConfigSpecToProps<typeof configSpec>
> = {
  type: PANEL_TYPE,
  Component: Vega3Panel,
  configSpec,
  useInspector: true,
  transformQuery,
};
