import '../css/RoutedTab.less';

import React from 'react';
import {Tab, TabProps} from 'semantic-ui-react';
import makeComp from '../util/profiler';

interface RoutedTabProps {
  activeTabSlug?: string;
  tabSlugs: string[]; // the first tab's slug is unimportant.
  baseUrl: string;
  history: any;
}

/**
 * A Tab component where each tab is hooked to its own URL, so you can
 * refresh without losing your tab.
 *
 * The full URL of a tab is {baseUrl / tabSlugs[activeTabSlug]} except
 * for the first tab, which is just {baseUrl}.
 */
const RoutedTab: React.FC<RoutedTabProps & TabProps> = makeComp(
  props => {
    const {history, tabSlugs, baseUrl, activeTabSlug} = props;
    // if the URL specifies an invalid tab, just go to baseUrl.
    if (activeTabSlug && !(tabSlugs.indexOf(activeTabSlug) > -1)) {
      history.replace(baseUrl);
    }

    const passThroughProps = {...props};
    delete (passThroughProps as any).activeTabSlug;
    delete (passThroughProps as any).tabSlugs;
    delete (passThroughProps as any).baseUrl;
    delete (passThroughProps as any).history;

    return (
      <Tab
        {...passThroughProps}
        className="routed-tab"
        activeIndex={activeTabSlug ? tabSlugs.indexOf(activeTabSlug) : 0}
        onTabChange={(e, {activeIndex}) => {
          if (typeof activeIndex === 'number') {
            history.push(
              baseUrl + (activeIndex === 0 ? '' : '/' + tabSlugs[activeIndex])
            );
          }
        }}
      />
    );
  },
  {id: 'RoutedTab', memo: true}
);

export default RoutedTab;
