import config from '../config';

const STRIPE_DASHBOARD_PREFIX =
  config.ENVIRONMENT_NAME === 'development' ||
  config.ENVIRONMENT_NAME === 'integration'
    ? 'https://dashboard.stripe.com/test'
    : 'https://dashboard.stripe.com';

export function getDashboardLink(subscriptionId?: string) {
  if (subscriptionId == null) {
    return 'N/A';
  }

  return `${STRIPE_DASHBOARD_PREFIX}/subscriptions/${subscriptionId}`;
}
