import {ApolloQueryResult} from 'apollo-client/core/types';
import classNames from 'classnames';
import memoize from 'fast-memoize';
import produce from 'immer';
import * as _ from 'lodash';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Dropdown,
  Form,
  List,
  Message,
  Popup,
  Tab,
} from 'semantic-ui-react';
import {IconSizeProp} from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import {SelectableAxis, StaticAxis} from '../components/Axis';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import {AVAILABLE_FILES_QUERY} from '../graphql/media';
import {apolloClient} from '../setup';
import {useSelector} from '../state/hooks';
import {Data} from '../state/runs/types';
import * as PanelTypes from '../state/views/panel/types';
import {PartRefFromObjSchema} from '../state/views/types';
import {RequireSome} from '../types/base';
import {
  RepresentationType,
  RepresentationTypeValues,
} from '../types/libs/nglviewer';
import {
  ImageMetadata,
  keyToMediaCardType,
  LayoutType,
  MaskOptions,
  MediaCardMetadata,
  MediaCardType,
  MediaString,
} from '../types/media';
import {RunHistoryRow, RunSignature} from '../types/run';
import {CHART_SAMPLES} from '../util/constants';
import {
  expandRangeToNElements,
  firstGrouped,
  GroupedList,
  mapGroupedLeafs,
  sampleSorted,
} from '../util/data';
import {propagateErrorsContext} from '../util/errors';
import {fuzzyMatchRegex} from '../util/fuzzyMatch';
import {
  ClassLabelMap,
  classLabelMap,
  ClassLabelNode,
  DEFAULT_ALL_MASK_CONTROL,
  DEFAULT_CLASS_MASK_CONTROL,
  getFirstMediaMetadata,
  getMediaKeysFromKeyInfo,
  latestStepWithMedia,
  mediaFilePath,
  mediaTypeToComponent,
  runHistoryRowsWithOneOfKeys,
  segmentationMaskColor,
  stepsWithKey,
  WANDB_BBOX_CLASS_LABEL_KEY,
  WANDB_MASK_CLASS_LABEL_KEY,
} from '../util/media';
import * as Panels from '../util/panels';
import makeComp from '../util/profiler';
import * as Query from '../util/queryts';
import {FileInfo, normalizeMediaFilepath} from '../util/requests';
import * as RunHelpers from '../util/runhelpers';
import {isGrouped} from '../util/runhelpers';
import {isNotNullOrUndefined} from '../util/types';
import {makeOptions} from '../util/uihelpers';
import * as urls from '../util/urls';
import HelpPopup from './elements/HelpPopup';
import LegacyWBIcon from './elements/LegacyWBIcon';
import ModifiedDropdown from './elements/ModifiedDropdown';
import PanelError from './elements/PanelError';
import SliderInput, {INPUT_SLIDER_CLASS} from './elements/SliderInput';
import {getImageInfo} from './ImageCard';
import Input from './Input';
import {
  AllBoundingBoxControls,
  AllMaskControls,
  BoundingBoxClassControl,
  BoundingBoxSliderControl,
  getAudioMedia,
  getBokehMediaPath,
  getHTMLMedia,
  getImageMedia,
  getMoleculeMediaPath,
  getObject3DMediaPath,
  getPlotlyMediaPath,
  getTableMediaPath,
  getVideoMedia,
  LineStyle,
  MaskControl,
  MediaPanelCardControl,
  MoleculeConfig,
  RunWithHistoryAndMediaWandb,
  Style,
  TileMedia,
  TileMediaSpec,
} from './MediaCard';
import * as OverlaysS from './Panel2/ControlImageOverlays.styles';
import {
  BoxConfidenceControl,
  ClassToggle,
  ClassToggleWithSlider,
  ControlTitle,
  SearchInput,
} from './Panel2/ControlsUtil';
import './PanelMediaBrowser.less';
import * as S from './PanelMediaBrowser.styles';
import WandbLoader from './WandbLoader';

export const rowsWithKeysMemo = memoize(runHistoryRowsWithOneOfKeys);

const PANEL_TYPE = 'Media Browser';

// Global configurable
const PADDING = 6;
const MAX_COL_COUNT = 20;
const DEFAULT_MAX_Y_AXIS_COUNT = 30;
const DEFAULT_LAYOUT_TYPE: LayoutType = 'ALL_STACKED';

type MediaBrowserPanelProps = Panels.PanelProps<MediaBrowserPanelConfig>;

interface RangeSettings {
  min: number;
  max: number;
  current: number;
  active?: boolean;
}
export type Axis = 'step' | 'index' | 'run' | 'camera';
const AxisStrings: Axis[] = ['step', 'index', 'run', 'camera'];
type PanelModes = 'gallery' | 'grid';

export interface MediaBrowserPanelConfig {
  chartTitle?: string; //
  stepIndex?: number; // index of the step shown from each run, default: 0
  mediaIndex?: number; // index of the image shown from each run, default: 0
  mediaKeys?: string[]; // key for media, like 'examples'

  // audio-specific config
  scaleWaveformWidth?: boolean; // whether or not we scale the widths of waveforms relative to each other (if false, all will have width:100%)

  actualSize?: boolean; //
  fitToDimension?: boolean; //
  pixelated?: boolean; //

  // Settings for tiling
  columnCount?: number; //
  mode?: PanelModes; //
  gallerySettings?: {
    axis: Axis;
  };
  gridSettings?: {
    xAxis: Axis;
    yAxis: Axis;
  };
  selection?: {
    xAxis?: [number, number];
    yAxis?: [number, number];
  };

  page?: {
    start?: number;
  };

  // How to stack masks
  tileLayout?: {
    [mediaKey: string]: {
      type: LayoutType;
      maskOptions: MaskOptions[];
    };
  };

  // The number of steps to increase on the up and down buttons of the step slider
  stepStrideLength?: number;
  maxGalleryItems?: number;
  maxYAxisCount?: number;
  moleculeConfig?: MoleculeConfig;
  segmentationMaskConfig?: AllMaskControls;
  boundingBoxConfig?: AllBoundingBoxControls;
}

interface SizingSettings {
  columnCount: {
    min: number;
    max: number;
    current: number;
  };
}

function defaultColumnCount(
  mediaType: MediaCardType | null,
  mediaCount?: number
): number {
  if (mediaCount != null && mediaCount < 4) {
    return mediaCount;
  }
  if (mediaType === 'object3D' || mediaType === 'video') {
    return 4;
  }
  if (mediaType === 'image') {
    return Math.min(8, mediaCount ?? Infinity);
  }
  return 1;
}

// We use these as placeholders to preserve spacing in grid mode
type EmptyTile = {
  mediaKey: string;
  isEmpty: true;
};
export type Tile = {
  mediaKey: string;
  mediaStep: number;
  mediaIndex: number;
  runSignature: RunSignature;
  run: RunWithHistoryAndMediaWandb;
  controls?: MediaPanelCardControl;

  // We don't set this for regular tiles, but it make the typing cleaner
  // to interact with empty tiles to have an optional here
  isEmpty?: false;
};

// Helper function to get the current media key or a default
function defaultMediaKeys(allMediaKeys: string[]): string[] | undefined {
  if (allMediaKeys.length === 0) {
    return undefined;
  }
  return [allMediaKeys[0]];
}

type TileWithoutRunSig = Omit<Tile, 'runSignature'>;

interface Tiles {
  tiles: GroupedList<Tile | EmptyTile>;
  galleryAxisData?: number[] | null;
  gridAxisData?: {
    x: number[];
    y: number[];
  } | null;
  // Maintain full scope x axis data so we can preserve zoom
  xAxisTicks?: number[];
  allXAxisValues?: number[];
  historySampleRate?: number;
}

// We can merge this with Tiles on a rewrite
interface Layout {
  tiles: GroupedList<TileWithoutRunSig | EmptyTile>;
  galleryAxisData?: number[] | null;
  gridAxisData?: {
    x: number[];
    y: number[];
  } | null;
  // Maintain full scope x axis data so we can preserve zoom
  xAxisTicks?: number[];
  allXAxisValues?: number[];
  historySampleRate?: number;
}

interface StepMeta {
  runs: Array<{
    min: number;
    max: number;
    steps: number[];
  }>;
  steps: number[];
  min: number;
  max: number;
}

type LayoutPagination = {
  start: number;
  size: number;
};

//  Helpers function for creating the tile layout
function makeLayout(
  data: Data,
  runsWithKey: RunWithHistoryAndMediaWandb[],
  stepMeta: StepMeta,
  config: MediaConfigWithDefaults,
  allMasks: {
    [mediaKey: string]: {
      [maskKey: string]: ClassLabelNode;
    };
  },
  currentStep: number
): Layout {
  const {
    mediaKeys,
    gallerySettings,
    gridSettings,
    mode,
    maxGalleryItems,
    stepIndex,
    mediaIndex,
    columnCount,
    selection,
    maxYAxisCount,
  } = config;
  // NOTE: In future to make these reusable remove the null checks
  // move them to the parent and add the constraints to the types
  if (mediaKeys == null || mediaKeys.length === 0) {
    return {tiles: []};
  }
  // NOTE: In future to make these reusable remove the null checks
  // move them to the parent and add the constraints to the types
  if (stepMeta.runs.length === 0) {
    return {tiles: []};
  }

  // This is some metadata we pass out of the
  // getAxis function for the UI
  // This is a little hacky we could do it cleaner
  let historySampleRate: number | undefined;

  let galleryAxisData: number[] | null = null;
  let gridAxisData: {
    x: number[];
    y: number[];
  } | null = null;

  // We want to return the full axis as well
  // So we can render the full axis and the zoomed
  // on subset
  let xAxisTicks: number[] | undefined;
  let allXAxisValues: number[] | undefined;

  const tiles: Layout['tiles'] = [];

  if (mode === 'gallery') {
    addTilesGalleryMode();
  } else if (mode === 'grid') {
    addTilesGridMode();
  }

  return {
    tiles,
    gridAxisData,
    galleryAxisData,
    xAxisTicks,
    allXAxisValues,
    historySampleRate,
  };

  // HELPER FUNCTIONS

  function addTilesGalleryMode(): void {
    const {axis} = gallerySettings;
    galleryAxisData = getAxisData(axis, {
      maxSize: maxGalleryItems,
    });

    const axisLength = galleryAxisData.length;

    for (let i = 0; i < axisLength; i++) {
      const runIndex = axis === 'run' ? i : 0;
      const run = runsWithKey[runIndex];
      // The step should be the currently set step
      // or in the case of unset panels the max step
      const defaultStep = stepMeta.runs[runIndex].max;
      const panelStep = stepIndex ?? defaultStep;
      const mediaStep = axis === 'step' ? galleryAxisData[i] : panelStep;
      const tileMediaIndex =
        axis === 'index' ? (galleryAxisData[i] as number) : mediaIndex ?? 0;
      const notes = data.filteredRunsById[run.name].notes;

      const meta =
        axis === 'camera'
          ? {camera: {cameraIndex: galleryAxisData[i]}}
          : undefined;

      const tile = {
        mediaStep,
        mediaIndex: tileMediaIndex,
        run: {...run, history: run.history ?? [], notes},
        meta,
      };
      addGroupOrTileForMediaKeys(tile);
    }
  }

  function addTilesGridMode(): void {
    const {xAxis, yAxis} = gridSettings;

    // Full Axis Data for preserving the non-zoom version
    xAxisTicks = getAxisData(xAxis, {
      maxSize: columnCount,
    });

    allXAxisValues = getAxisData(xAxis);

    // Actual Axis Data(Including Zoom)
    const [minX, maxX] = [
      _.get(selection?.xAxis, 0),
      _.get(selection?.xAxis, 1),
    ];
    const [minY, maxY] = [
      _.get(selection?.yAxis, 0),
      _.get(selection?.yAxis, 1),
    ];
    const groupSize = mediaKeys?.length ?? 0;

    const xAxisData = getAxisData(xAxis, {
      maxSize: columnCount / groupSize,
      min: minX,
      max: maxX,
    });
    const yAxisData = getAxisData(yAxis, {
      maxSize: maxYAxisCount,
      min: minY,
      max: maxY,
    });
    gridAxisData = {x: xAxisData, y: yAxisData};

    // Each group is composed of 1 tile for each mediaKey
    // Or multiple if it has many mask tiles
    const groupCount = calcGroupCount(config);

    for (let row = 0; row < yAxisData.length; row++) {
      const addedToRow = [];
      for (let col = 0; col < columnCount / groupCount; col++) {
        // we add empty tiles to fill the row if the number of tiles < columnCount
        if (col >= xAxisData.length) {
          let totalTiles = 0;
          addedToRow.forEach(tileOrGroup => {
            const ts =
              'items' in tileOrGroup ? tileOrGroup.items : [tileOrGroup];
            ts.forEach(t => {
              const maskOptions = getMaskOptions(config, allMasks, t as Tile);
              totalTiles += maskOptions.length;
            });
          });
          while (totalTiles < columnCount) {
            tiles.push({
              mediaKey: mediaKeys?.[0] ?? '',
              isEmpty: true,
            } as EmptyTile);
            totalTiles++;
          }
          break;
        }

        const runIndex = xAxis === 'run' ? col : yAxis === 'run' ? row : 0;

        const run = runsWithKey[runIndex];

        const defaultStep = stepMeta.runs[runIndex].max;
        const mediaStep =
          xAxis === 'step'
            ? xAxisData[col]
            : yAxis === 'step'
            ? yAxisData[row]
            : stepIndex || defaultStep;

        const tileMediaIndex =
          xAxis === 'index'
            ? (xAxisData[col] as number)
            : yAxis === 'index'
            ? yAxisData[row]
            : mediaIndex ?? 0;

        const notes = data.filteredRunsById[run.name].notes;

        const tile = {
          mediaStep,
          mediaIndex: tileMediaIndex,
          run: {...run, history: run.history || [], notes},
        };

        addedToRow.push(addGroupOrTileForMediaKeys(tile));
      }
    }
  }

  // Give the step, index, and run, construct a single tile
  // or a group of tiles for the given set of constraints
  //
  // Constraints included: Media Keys
  //                       Tiling Layouts
  function addGroupOrTileForMediaKeys(
    newTile: Omit<TileWithoutRunSig, 'mediaKey'>
  ) {
    if (mediaKeys == null || mediaKeys.length === 0) {
      throw new Error(`empty mediaKeys`);
    }
    // Create a single tile or a group of tiles depending
    // the number of media keys set

    if (mediaKeys.length === 1) {
      const tile = {
        mediaKey: mediaKeys[0],
        ...newTile,
      };
      tiles.push(tile);
      return tile;
    }

    for (const mediaKey of mediaKeys) {
      const tile = {
        mediaKey,
        ...newTile,
      };
      tiles.push({groupType: 'mediaKeys', items: [tile]});
    }
    return tiles[tiles.length - 1];
  }

  // It returns a range of indices to be used as a lens on
  // the parent collection
  function getAxisData(
    axis: Axis,
    // Min and max indexes to zoom on
    opts?: {
      maxSize?: number;
      min?: number;
      max?: number;
    }
  ): number[] {
    const maxSize = opts?.maxSize;

    if (axis === 'step') {
      const min = opts?.min ?? -Infinity;
      const max = opts?.max ?? Infinity;
      const stepsInRange = stepMeta.steps.filter(s => s >= min && s <= max);
      const samples = maxSize
        ? Math.min(stepsInRange.length, maxSize)
        : stepsInRange.length;
      const stepData = maxSize
        ? sampleSorted(stepsInRange, samples)
        : stepsInRange;

      historySampleRate =
        (stepsInRange[stepsInRange.length - 1] - stepsInRange[0] - 2) / samples;

      return stepData;
    }

    if (axis === 'run') {
      // For runs don't sample, take first n
      return _.range(0, runsWithKey.slice(0, maxSize).length);
    }

    if (axis === 'index') {
      // Get the length of the media array
      const metadata =
        runsWithKey[0].history &&
        getFirstMediaMetadata(
          runsWithKey[0].history,
          mediaKeys?.[0] ?? '',
          currentStep
        );

      const mediaGrouping = (metadata && metadata.grouping) || 1;
      const mediaCount = ((metadata && metadata.count) || 1) / mediaGrouping;
      const mediaIndices = _.range(0, mediaCount);

      const res =
        maxSize && mediaCount > maxSize
          ? _.take(mediaIndices, maxSize)
          : mediaIndices;

      return res;
    }

    return _.range(0, 20);
  }
}

// A type for the config that contains defaults.
// Allows for cleaner type logic
type MediaConfigWithDefaults = ReturnType<typeof getConfigWithDefaults>;

function getConfigWithDefaults(
  config: MediaBrowserPanelConfig,
  mediaType: MediaCardType | null,
  inRunPage: boolean,
  mediaCount?: number
) {
  // Set sane defaults
  const gallerySettings =
    config.gallerySettings ??
    (inRunPage
      ? {
          axis: 'index' as Axis,
        }
      : {
          axis: 'run' as Axis,
        });

  const gridSettings =
    config.gridSettings ??
    (inRunPage
      ? {
          xAxis: 'step' as Axis,
          yAxis: 'index' as Axis,
        }
      : {xAxis: 'step' as Axis, yAxis: 'run' as Axis});

  const mode = config.mode ?? 'gallery';
  const pixelated = config.pixelated ?? true;

  // Actual Size and Fit to 1 Dimension is currently restricted to gallery mode
  let actualSize;
  let fitToDimension;
  if (mode === 'grid') {
    actualSize = false;
    fitToDimension = false;
  } else {
    actualSize = config.actualSize ?? false;
    fitToDimension = config.fitToDimension ?? false;
  }

  const columnCount =
    config.columnCount ?? defaultColumnCount(mediaType, mediaCount);

  const maxYAxisCount = config.maxYAxisCount ?? DEFAULT_MAX_Y_AXIS_COUNT;

  return {
    ...config,
    actualSize,
    fitToDimension,
    columnCount,
    mode,
    pixelated,
    gallerySettings,
    gridSettings,
    maxYAxisCount,
  };
}

const MediaBrowserPanel: React.FC<MediaBrowserPanelProps> = makeComp(
  props => {
    const {data, query, config, configMode, updateConfig} = props;
    const {mediaKeys, stepIndex} = config;
    const panelViewerRef = useRef<HTMLDivElement | null>(null);
    const {
      width: mediaBrowserWidth,
      height: mediaBrowserHeight,
      refCallback: mediaBrowserRefCallback,
    } = useMediaBrowserDimensions();

    const inRunPage = data.histories.data.length === 1;

    const allMediaKeys = getMediaKeysFromKeyInfo(data.histories.keyInfo);
    const defaultKeys = defaultMediaKeys(allMediaKeys);
    const firstMediaKey = mediaKeys?.[0] ?? null;
    const keyTypes = RunHelpers.keyTypes(data.histories.keyInfo);
    const mediaString =
      firstMediaKey != null ? (keyTypes[firstMediaKey] as MediaString) : null;
    const mediaType =
      mediaString != null &&
      mediaString !== 'data-frame' &&
      mediaString !== 'media'
        ? keyToMediaCardType(mediaString)
        : null;

    const allRuns: RunWithHistoryAndMediaWandb[] = useMemo(
      () =>
        data.histories.data.map(r => {
          const run = data.filteredRunsById[r.name];
          const runsetID = run.runsetInfo.id;
          const runsetQuery = _.find(query.queries, q => q.id === runsetID);
          if (runsetQuery == null) {
            throw new Error(
              'Inconsistent data from the server, run does not have a matching runset'
            );
          }
          return {
            ...r,
            _wandb: run._wandb,
            entityName: runsetQuery.entityName,
            projectName: runsetQuery.projectName,
          };
        }),
      [data, query.queries]
    );

    // For now we memoize the function globally
    const [runsWithMedia, runsWithoutMedia] = useMemo(
      () =>
        _.partition(allRuns, runHistory => {
          // Check for runs that contains any of the chosen media keys
          const steps = rowsWithKeysMemo(runHistory, mediaKeys);
          return steps.length > 0;
        }),
      [allRuns, mediaKeys]
    );

    const runsWithMediaSteps = useMemo(
      () =>
        runsWithMedia.map(runHistory =>
          rowsWithKeysMemo(runHistory, mediaKeys).map(s => s._step as number)
        ),
      [runsWithMedia, mediaKeys]
    );
    const allStepData = useMemo(
      () =>
        runsWithMediaSteps.map(steps => ({
          min: _.min(steps) ?? 0,
          max: _.max(steps) ?? 0,
          steps,
        })),
      [runsWithMediaSteps]
    );
    const minStep = Math.min(...allStepData.map(sd => sd.min));
    const maxStep = Math.max(...allStepData.map(sd => sd.max));
    const allSteps = useMemo(
      () =>
        _.uniq(_.flatten(allStepData.map(sd => sd.steps))).sort(
          (a, b) => a - b
        ),
      [allStepData]
    );
    const stepMeta: StepMeta = useMemo(
      () => ({
        runs: allStepData,
        min: minStep,
        max: maxStep,
        steps: allSteps,
      }),
      [allStepData, minStep, maxStep, allSteps]
    );
    const currentStep = stepIndex ?? maxStep;

    const allMasks = useMemo(
      () => classLabelMap(allRuns, mediaKeys ?? [], WANDB_MASK_CLASS_LABEL_KEY),
      [allRuns, mediaKeys]
    );
    const hasMasks = Object.keys(allMasks).length > 0;
    const allBoxes = classLabelMap(
      allRuns,
      mediaKeys ?? [],
      WANDB_BBOX_CLASS_LABEL_KEY
    );
    const hasBoxes = Object.keys(allBoxes).length > 0;

    const firstHistory: RunHistoryRow[] | null =
      data.histories.data[0]?.history ?? null;
    const firstMediaMetadata =
      firstMediaKey != null && firstHistory != null
        ? getFirstMediaMetadata(firstHistory, firstMediaKey, currentStep)
        : null;
    const mediaCount = inRunPage
      ? (firstMediaMetadata && firstMediaMetadata.count) ?? 1
      : data.histories.data.length;
    const firstMaskCount =
      firstMediaKey != null && allMasks[firstMediaKey] != null
        ? Object.keys(allMasks[firstMediaKey]).length
        : 0;

    const configWithDefaults = useMemo(
      () =>
        getConfigWithDefaults(
          config,
          mediaType,
          inRunPage,
          mediaCount * (firstMaskCount + 1)
        ),
      [config, mediaType, inRunPage, mediaCount, firstMaskCount]
    );
    const configWithDefaultsAndMediaKeys = useMemo(() => {
      if (mediaKeys == null) {
        return null;
      }
      return {
        ...configWithDefaults,
        mediaKeys,
      };
    }, [configWithDefaults, mediaKeys]);

    const layout = useMemo(
      () =>
        makeLayout(
          data,
          runsWithMedia,
          stepMeta,
          configWithDefaults,
          allMasks,
          currentStep
        ),
      [data, runsWithMedia, stepMeta, configWithDefaults, allMasks, currentStep]
    );

    const flattenedMediaMetadataForAllRuns: MediaCardMetadata[] = [];
    for (const r of runsWithMedia) {
      for (const h of r.history ?? []) {
        const mediaMetadataForKeys =
          mediaKeys?.map(k => h[k]).filter(isNotNullOrUndefined) ?? [];
        flattenedMediaMetadataForAllRuns.push(...mediaMetadataForKeys);
      }
    }

    const sizingSettings = {
      columnCount: {
        min: 1,
        max: MAX_COL_COUNT,
        current: configWithDefaults.columnCount,
      },
    };

    useEffect(() => {
      window.analytics.track('Media Panel Rendered', {
        type: mediaType,
        hasBoxes,
        hasMasks,
      });
    }, [mediaType, hasBoxes, hasMasks]);

    useEffect(() => {
      // We have to set this in the global config instead of overriding the local
      // config so that the panel fetches the data
      if (mediaKeys == null) {
        updateConfig({mediaKeys: defaultKeys});
      }
    }, [mediaKeys, updateConfig, defaultKeys]);

    // The loading spinner that shows when a media panel is loading
    const renderLoader = () => (
      <div className="panel-media" ref={mediaBrowserRefCallback}>
        <WandbLoader size="large" />
      </div>
    );

    // An error that renders in the panel body so it is still editable
    const renderError = (message: React.ReactChild) => {
      return (
        <PanelBody
          configMode={configMode}
          panelViewerRef={panelViewerRef}
          mediaType={mediaType}
          inRunPage={inRunPage}
          sizingSettings={sizingSettings}
          configWithDefaults={configWithDefaults}
          allMediaKeys={allMediaKeys}
          updateConfig={updateConfig}
          mediaBrowserRefCallback={mediaBrowserRefCallback}>
          <PanelError message={message} />
        </PanelBody>
      );
    };

    if (data.loading) {
      return renderLoader();
    }

    if (
      mediaKeys == null ||
      mediaKeys.length === 0 ||
      configWithDefaultsAndMediaKeys == null
    ) {
      return renderError('No Media Key selected');
    }

    if (mediaString == null) {
      return renderError(
        `Select runs that logged ${mediaKeys[0]} to visualize data here.`
      );
    }
    if (mediaString === 'data-frame') {
      return renderError(
        'Media type: "data-frame" unsupported as a media card'
      );
    }
    if (mediaString === 'media') {
      return renderError('Unsupported media type');
    }
    if (mediaType == null) {
      return renderError(
        <div>
          <p>
            Selected runs are not logging media for the key{' '}
            <b>{mediaKeys[0]}</b>, but instead are logging values of type{' '}
            <b>{mediaString}</b>.
          </p>
          <p>
            If <b>{mediaKeys[0]}</b> is never supposed to be a media type,
            please delete this panel and create the proper panel type manually.
          </p>
        </div>
      );
    }

    if (
      _.isEmpty(data.filtered) ||
      flattenedMediaMetadataForAllRuns.length === 0
    ) {
      return renderError(`Select runs that logged ${mediaType} with the key ${mediaKeys[0]}
        to visualize data here.`);
    }

    if (mediaBrowserWidth == null || mediaBrowserHeight == null) {
      return renderLoader();
    }

    return (
      <MediaBrowserPanelInner
        {...props}
        config={configWithDefaultsAndMediaKeys}
        layout={layout}
        mediaType={mediaType}
        firstMediaMetadata={firstMediaMetadata}
        flattenedMediaMetadataForAllRuns={flattenedMediaMetadataForAllRuns}
        currentStep={currentStep}
        minStep={minStep}
        maxStep={maxStep}
        mediaBrowserWidth={mediaBrowserWidth}
        mediaBrowserHeight={mediaBrowserHeight}
        stepMeta={stepMeta}
        panelViewerRef={panelViewerRef}
        allMasks={allMasks}
        allBoxes={allBoxes}
        runsWithoutMedia={runsWithoutMedia}
        inRunPage={inRunPage}
        sizingSettings={sizingSettings}
        allMediaKeys={allMediaKeys}
        mediaBrowserRefCallback={mediaBrowserRefCallback}
      />
    );
  },
  {id: 'MediaBrowserPanel', memo: true}
);

type MediaConfigWithDefaultsAndMediaKeys = RequireSome<
  MediaConfigWithDefaults,
  'mediaKeys'
>;

type MediaBrowserPanelInnerProps = Omit<MediaBrowserPanelProps, ''> & {
  config: MediaConfigWithDefaultsAndMediaKeys;
  layout: Layout;
  mediaType: MediaCardType;
  firstMediaMetadata: MediaCardMetadata | null;
  flattenedMediaMetadataForAllRuns: MediaCardMetadata[];
  currentStep: number;
  minStep: number;
  maxStep: number;
  mediaBrowserWidth: number;
  mediaBrowserHeight: number;
  stepMeta: StepMeta;
  panelViewerRef: React.MutableRefObject<HTMLDivElement | null>;
  allMasks: ClassLabelMap;
  allBoxes: ClassLabelMap;
  runsWithoutMedia: RunWithHistoryAndMediaWandb[];
  inRunPage: boolean;
  sizingSettings: SizingSettings;
  allMediaKeys: string[];
  mediaBrowserRefCallback: (el: HTMLDivElement) => void;
};

const MediaBrowserPanelInner: React.FC<MediaBrowserPanelInnerProps> = makeComp(
  props => {
    const {
      config,
      configMode,
      disableRunLinks,
      layout,
      mediaType,
      firstMediaMetadata,
      flattenedMediaMetadataForAllRuns,
      currentStep,
      minStep,
      maxStep,
      mediaBrowserWidth,
      mediaBrowserHeight,
      stepMeta,
      panelViewerRef,
      allMasks,
      allBoxes,
      runsWithoutMedia,
      inRunPage,
      sizingSettings,
      allMediaKeys,
      updateConfig,
      mediaBrowserRefCallback,
    } = props;
    const {
      chartTitle,
      mediaKeys,
      mediaIndex,
      gallerySettings,
      gridSettings,
      mode,
      page,
    } = config;

    // Plotly cards perform poorly past 8 on a page. This value allows us to perform well
    // with the default 2 panels in a section open
    const layoutPagination: LayoutPagination | undefined =
      mediaType === 'plotly' ? {start: 0, size: 4} : undefined;

    // Add the correct run metadata to each tile.
    const mediaTiles: GroupedList<Tile | EmptyTile> = useMemo(
      () =>
        mapGroupedLeafs(layout.tiles, t =>
          t.isEmpty === true
            ? t
            : {
                ...t,
                runSignature: {
                  entityName: t.run.entityName,
                  projectName: t.run.projectName,
                  runName: t.run.name,
                },
              }
        ),
      [layout.tiles]
    );

    // Grouping is a secret legacy setting that can be passed in via the CLI
    //
    // if grouping is > 1, we'll group the images accordingly
    // e.g. if grouping = 3, we'll show every 3 images as if they're one image
    const groupingCount = firstMediaMetadata?.grouping ?? 1;

    /* MEDIA INDEX */
    const counts = _.map(flattenedMediaMetadataForAllRuns, m => m.count ?? 1);
    const maxMedia = (_.max(counts) || 0) / groupingCount - 1;

    const minMediaIndex = 0;

    let currentMediaIndex = mediaIndex ?? 0;

    // this can happen if you switch to a mediaKey/step with a lower maxMediaIndex than the current mediaKey/step
    if (currentMediaIndex > maxMedia) {
      currentMediaIndex = maxMedia;
    }

    // scaleWaveformWidth: only used for audio
    // if true, the length of each displayed waveform will be scaled relative
    // to the width of the longest displayed waveform
    // const scaleWaveformWidth = _.isUndefined(panelConfig.scaleWaveformWidth)
    //   ? true
    //   : panelConfig.scaleWaveformWidth;
    // NOTE: Scale waveform currently has a  lot of bugs around
    // missing media keys. It needs to get worked to deal with missing media.
    // I'm disabling it for now.
    // const scaleWaveformWidth = false;

    const galleryAxes = [gallerySettings.axis];

    const gridAxes = [gridSettings.xAxis, gridSettings.yAxis];

    const activeAxes = mode === 'gallery' ? galleryAxes : gridAxes;

    // In gallery mode we want to label everything by what we are iterating across
    let labels: Axis[] = [];
    if (mode === 'gallery' && galleryAxes[0] !== 'index') {
      labels = labels.concat(galleryAxes);
    } else if (gridAxes[0] === 'run') {
      labels.push('run');
    }

    // what are you doing
    const stepSlider = {
      current: currentStep,
      min: minStep,
      max: maxStep,
      active: !_.includes(activeAxes, 'step'),
    };

    const indexSlider = {
      current: mediaIndex ?? 0,
      min: minMediaIndex,
      max: maxMedia,
      active: !_.includes(activeAxes, 'index'),
    };

    const paginationActive =
      mode === 'gallery' &&
      layoutPagination != null &&
      layoutPagination.size < mediaTiles.length;

    const mediaBrowserSize = {
      width: mediaBrowserWidth,
      height: mediaBrowserHeight - (paginationActive ? 30 : 0),
    };

    const headerSubConfig = {
      ...config,
      chartTitle: chartTitle ?? keysToTitle(mediaKeys),
    };
    return (
      <PanelBody
        configMode={configMode}
        panelViewerRef={panelViewerRef}
        mediaBrowserRefCallback={mediaBrowserRefCallback}
        mediaType={mediaType}
        inRunPage={inRunPage}
        sizingSettings={sizingSettings}
        configWithDefaults={config}
        allMediaKeys={allMediaKeys}
        updateConfig={updateConfig}
        header={
          <PanelHeader
            {...{
              mediaBrowserSize,
              sizingSettings,
              config: headerSubConfig,
              updateConfig,
              sliders: {step: stepSlider, index: indexSlider},
              stepMeta,
              panelRef: (props as any).panelRef,
              allMasks,
              allBoxes,
            }}
          />
        }
        footer={
          <PanelFooter
            {...{
              config: _.omit(config, 'chartTitle'),
              updateConfig,
              sliders: {step: stepSlider, index: indexSlider},
              stepMeta,
            }}
          />
        }>
        {paginationActive && layoutPagination != null && (
          <Pagination
            total={mediaTiles.length}
            panelViewerRef={panelViewerRef}
            updateConfig={updateConfig}
            start={page?.start}
            size={layoutPagination.size}
          />
        )}
        <PanelContent
          {...{
            labels,
            mediaType,
            mediaBrowserSize,
            sizingSettings,
            layout: {
              ...layout,
              tiles: mediaTiles,
            },
            currentMediaKeys: mediaKeys,
            config: _.omit(config, 'chartTitle'),
            updateConfig,
            panelRef: (props as any).panelRef,
            disableRunLinks,
            allMasks,
            currentStep,
            maxTiles: layoutPagination?.size,
          }}
        />
        <MissingRuns runsWithoutMedia={runsWithoutMedia} />
      </PanelBody>
    );
  },
  {id: 'MediaBrowserPanelInner', memo: true}
);

type PanelBodyProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  panelSettings?: React.ReactNode;
  configMode: boolean;
  panelViewerRef: React.MutableRefObject<HTMLDivElement | null>;
  mediaType: MediaCardType | null;
  inRunPage: boolean;
  sizingSettings: SizingSettings;
  configWithDefaults: MediaConfigWithDefaults;
  allMediaKeys: string[];
  updateConfig: MediaBrowserPanelProps['updateConfig'];
  mediaBrowserRefCallback: (el: HTMLDivElement) => void;
};

// Create a panel body function so we can render our errors within
// the panel body and allow the panel to remain configurable
// on all error states
const PanelBody: React.FC<PanelBodyProps> = makeComp(
  ({
    header,
    footer,
    children,
    configMode,
    panelViewerRef,
    mediaType,
    inRunPage,
    sizingSettings,
    configWithDefaults,
    allMediaKeys,
    updateConfig,
    mediaBrowserRefCallback,
  }) => {
    const content = (
      <>
        {header}
        <div className="panel-media" ref={mediaBrowserRefCallback}>
          <div ref={panelViewerRef} className="panel-viewer">
            {children}
          </div>
        </div>
        {footer}
      </>
    );

    if (configMode) {
      return (
        <div className="panel-media__config-container">
          <div className="panel-media__config-content">{content}</div>
          <div className="panel-media__config-settings">
            <PanelSettings
              multiRun={!inRunPage}
              mediaType={mediaType}
              sizingSettings={sizingSettings}
              allMediaKeys={allMediaKeys}
              config={configWithDefaults}
              updateConfig={updateConfig}
            />
          </div>
        </div>
      );
    }

    return (
      <div data-test="media-panel" className="panel-media__container">
        {content}
      </div>
    );
  },
  {id: 'PanelMediaBrowser.PanelBody', memo: true}
);

interface MediaBrowserSize {
  width: number;
  height: number;
}

const PanelHeader = makeComp(
  (props: {
    config: RequireSome<MediaBrowserPanelConfig, 'chartTitle'>;
    mediaBrowserSize: MediaBrowserSize;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
    sizingSettings: SizingSettings;
    sliders: {
      step: RangeSettings;
      index: RangeSettings;
    };
    stepMeta: StepMeta;
    panelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
    allMasks: {
      [mediaKey: string]: {
        [maskKey: string]: ClassLabelNode;
      };
    };
    allBoxes: {
      [mediaKey: string]: {
        [boxKey: string]: ClassLabelNode;
      };
    };
  }) => {
    const {updateConfig} = props;
    const {chartTitle} = props.config;
    const bBoxThresholdSliderActive = Object.keys(props.allBoxes).length > 0;
    const boxData = useSelector(s => {
      return s.media.boxData[props.panelRef.id] ?? {};
    });

    const sliderRanges = useMemo(() => {
      const scoreRanges = {} as {
        [key: string]: {
          min: number;
          max: number;
        };
      };

      // Loop through all the relevant boxes and their scores
      // Constructing a range value for each set
      for (const k of props.config.mediaKeys ?? []) {
        _.forEach(boxData[k], (boxes, mediaID) => {
          boxes.forEach(v => {
            _.forEach(v.scores, (score, name) => {
              const oldRange = scoreRanges[name];
              let newRange;
              if (oldRange == null) {
                newRange = {min: score, max: score};
              } else {
                newRange = {
                  min: Math.min(oldRange.min, score),
                  max: Math.max(oldRange.max, score),
                };
              }
              scoreRanges[name] = newRange;
            });
          });
        });
      }

      return scoreRanges;
    }, [boxData, props.config.mediaKeys]);

    const bBoxSlider = () => {
      const bBoxSliderKeys = Object.keys(sliderRanges);

      if (bBoxSliderKeys.length === 0) {
        return;
      }

      const bBoxConfig = props.config.boundingBoxConfig ?? {};
      return (
        <div className="control-popup__item">
          {bBoxSliderKeys.map(k => {
            const currentMin = parseFloat(sliderRanges[k].min.toPrecision(3));

            const currentSliderControl: BoundingBoxSliderControl = _.get(
              props.config.boundingBoxConfig,
              ['sliders', k],
              {comparator: 'gte', value: currentMin}
            );

            const updateSlider = (
              newControl: Partial<BoundingBoxSliderControl>
            ) => {
              const newConfig = produce(bBoxConfig, draft => {
                _.set(draft, ['sliders', k], {
                  ...currentSliderControl,
                  ...newControl,
                });
              });
              updateConfig({boundingBoxConfig: newConfig});
            };

            return (
              <BoxConfidenceControl
                key={k}
                slideRange={sliderRanges[k]}
                {...currentSliderControl}
                name={k}
                onSliderChange={v =>
                  updateSlider({value: parseFloat(v.toPrecision(3))})
                }
                onDisableChange={() =>
                  updateSlider({disabled: !currentSliderControl.disabled})
                }
                onOperatorChange={comparator => updateSlider({comparator})}
              />
            );
          })}
        </div>
      );
    };

    const [maskSearchQuery, setMaskSearchQuery] = useState<string>('');
    const maskQueryRegex = React.useMemo(() => {
      return fuzzyMatchRegex(maskSearchQuery);
    }, [maskSearchQuery]);

    const [boxSearchQuery, setBoxSearchQuery] = useState<string>('');
    const boxQueryRegex = React.useMemo(() => {
      return fuzzyMatchRegex(boxSearchQuery);
    }, [boxSearchQuery]);

    const maskConfig = props.config.segmentationMaskConfig ?? {};
    const boundingBoxConfig = props.config.boundingBoxConfig ?? {};

    const maskControlsActive = Object.keys(props.allMasks).length !== 0;

    const maskLayoutToggle = (mediaKey: string) => {
      const allMaskKeys = Object.keys(props.allMasks[mediaKey]);
      const setToggle = (type: LayoutType) => {
        const newTileLayout = produce(props.config.tileLayout ?? {}, draft => {
          const maskOptions = maskOptionsForLayout(type, allMaskKeys ?? []);

          draft[mediaKey] = {type, maskOptions};
          return draft;
        });
        updateConfig({tileLayout: newTileLayout});
      };

      const layoutType = _.get(
        props.config.tileLayout,
        [mediaKey, 'type'],
        DEFAULT_LAYOUT_TYPE
      );

      return (
        <ButtonGroup>
          <Button
            size="tiny"
            icon
            className={classNames({
              'action-button--active': layoutType === 'ALL_STACKED',
            })}
            onClick={() => setToggle('ALL_STACKED')}>
            <LegacyWBIcon name="overlay-stack"></LegacyWBIcon>
          </Button>
          <Button
            size="tiny"
            icon
            className={classNames({
              'action-button--active': layoutType === 'MASKS_NEXT_TO_IMAGE',
            })}
            onClick={() => setToggle('MASKS_NEXT_TO_IMAGE')}>
            <LegacyWBIcon name={'overlay-2-column'}></LegacyWBIcon>
          </Button>
          {allMaskKeys.length > 1 && (
            <Button
              size="tiny"
              icon
              className={classNames({
                'action-button--active': layoutType === 'ALL_SPLIT',
              })}
              onClick={() => setToggle('ALL_SPLIT')}>
              <LegacyWBIcon name="overlay-3-column"></LegacyWBIcon>
            </Button>
          )}
        </ButtonGroup>
      );
    };

    const isImageShownForMask = (
      mediaKey: string,
      maskKey: string
    ): boolean => {
      const layout = _.get(props.config, ['tileLayout', mediaKey], {
        maskOptions: [],
      }) as {
        maskOptions: MaskOptions[];
      };

      const mo = _.find(layout.maskOptions, v =>
        _.includes(v.maskKeys, maskKey)
      );

      return mo?.showImage ?? false;
    };

    const toggleImageForMask = (mediaKey: string, maskKey: string) => {
      const allLayouts = props.config.tileLayout ?? {};
      const isShown = isImageShownForMask(mediaKey, maskKey);

      const newLayout = produce(allLayouts, draft => {
        // We need to set a default layout to perform updates
        if (draft[mediaKey] == null) {
          const allMaskKeys = Object.keys(props.allMasks[mediaKey]);
          draft[mediaKey] = defaultLayout(allMaskKeys);
        }
        const layout = draft[mediaKey];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < layout.maskOptions.length; i++) {
          const maskKeys = layout.maskOptions[i].maskKeys;

          if (_.includes(maskKeys, maskKey)) {
            layout.maskOptions[i].showImage = !isShown;
          }
        }
      });

      updateConfig({tileLayout: newLayout});
    };

    const maskControls = (
      <div key="mask-control">
        {Object.keys(props.allMasks).map(mediaKey => {
          const relevantMasks = props.allMasks[mediaKey];
          return (
            <React.Fragment key={mediaKey}>
              <div
                style={{marginTop: 15}}
                className="mask-control__mask-section">
                {maskLayoutToggle(mediaKey)}
                <span style={{marginLeft: -5}}>
                  <HelpPopup helpText="Toggle the layout of the masks between: a stack of image and masks, masks adjacent to image, and all spread out"></HelpPopup>
                </span>
              </div>
              {Object.keys(relevantMasks).map(maskKey => {
                const classDict = props.allMasks[mediaKey][maskKey].value;
                return (
                  <OverlaysS.Wrapper key={maskKey} style={{margin: '10px 0'}}>
                    <OverlaysS.Header>
                      <OverlaysS.TitleWrapper>
                        <OverlaysS.VisibilityToggleWrapper
                          style={{marginTop: '5px'}}>
                          <MaskControlComponent
                            mediaKey={mediaKey}
                            maskKey={maskKey}
                            classID={'all'}
                            className="All"
                            maskConfig={maskConfig}
                            updateConfig={updateConfig}
                          />
                        </OverlaysS.VisibilityToggleWrapper>
                        <ControlTitle>{maskKey}</ControlTitle>
                        <LegacyWBIcon
                          style={{
                            marginLeft: 5,
                            fontSize: '1.1em',
                            cursor: 'pointer',
                            verticalAlign: 'middle',
                            color: isImageShownForMask(mediaKey, maskKey)
                              ? 'black'
                              : 'grey',
                          }}
                          onClick={() => toggleImageForMask(mediaKey, maskKey)}
                          name="panel-images"></LegacyWBIcon>

                        <SearchInput
                          value={maskSearchQuery}
                          onChange={setMaskSearchQuery}
                        />
                      </OverlaysS.TitleWrapper>
                    </OverlaysS.Header>
                    <ShowMoreContainer>
                      {Object.keys(classDict)
                        .map(k => [k, classDict[Number(k)]])
                        .filter(([k, name]) => name.match(maskQueryRegex))
                        .map(([k, name]) => (
                          <MaskControlComponent
                            key={mediaKey + maskKey + name}
                            mediaKey={mediaKey}
                            maskKey={maskKey}
                            classID={k}
                            className={name}
                            maskConfig={maskConfig}
                            updateConfig={updateConfig}
                          />
                        ))}
                    </ShowMoreContainer>
                  </OverlaysS.Wrapper>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    );

    const boundingBoxControlsActive = Object.keys(props.allBoxes).length !== 0;
    const boundingBoxControls = (
      <div key="box-control">
        {Object.keys(props.allBoxes).map(mediaKey => {
          const relevantMasks = props.allBoxes[mediaKey];
          return (
            <React.Fragment key={mediaKey}>
              {Object.keys(relevantMasks).map(boxKey => {
                const classDict = props.allBoxes[mediaKey][boxKey].value;
                return (
                  <OverlaysS.Wrapper key={boxKey} style={{margin: '10px 0'}}>
                    <OverlaysS.Header>
                      <OverlaysS.TitleWrapper>
                        <OverlaysS.VisibilityToggleWrapper
                          style={{marginTop: '5px'}}>
                          <BBoxControlComponent
                            mediaKey={mediaKey}
                            boxKey={boxKey}
                            classID={'all'}
                            className="All"
                            boxConfig={boundingBoxConfig}
                            updateConfig={updateConfig}
                          />
                        </OverlaysS.VisibilityToggleWrapper>

                        <ControlTitle>{boxKey}</ControlTitle>
                        <BBoxStyleControl
                          mediaKey={mediaKey}
                          boxKey={boxKey}
                          boxConfig={boundingBoxConfig}
                          updateConfig={updateConfig}
                        />
                        <SearchInput
                          value={boxSearchQuery}
                          onChange={setBoxSearchQuery}
                        />
                      </OverlaysS.TitleWrapper>
                    </OverlaysS.Header>

                    <ShowMoreContainer>
                      {Object.keys(classDict)
                        .map(k => [k, classDict[Number(k)]])
                        .filter(([k, name]) => name.match(boxQueryRegex))
                        .map(([k, name]) => (
                          <BBoxControlComponent
                            key={mediaKey + boxKey + name}
                            mediaKey={mediaKey}
                            boxKey={boxKey}
                            classID={k}
                            className={name}
                            boxConfig={boundingBoxConfig}
                            updateConfig={updateConfig}
                          />
                        ))}
                    </ShowMoreContainer>
                  </OverlaysS.Wrapper>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    );

    const [popup, setPopup] = React.useState<boolean>();
    return (
      <div>
        <div className="panel-header media-panel-header">
          <h6
            style={{width: '100%'}}
            className="panel-title"
            title={chartTitle}>
            {chartTitle}
          </h6>
          {(maskControlsActive || boundingBoxControlsActive) && (
            <Popup
              position={'top left'}
              basic
              pinned
              on="click"
              trigger={
                <LegacyWBIcon
                  style={{cursor: 'pointer', marginTop: -30}}
                  onClick={() => setPopup(!popup)}
                  className={classNames(
                    'media-popup-button',
                    popup
                      ? 'media-popup-button__open'
                      : 'media-popup-button__closed'
                  )}
                  name="configuration"
                />
              }
              popperDependencies={[Object.keys(props.allBoxes).length]}
              open={popup}
              onClose={e => {
                // Prevent the popup when operating a slider
                // The sliders lie out of the popup and trigger a close event
                //
                // This is a bit of a hack, I'd be happy to move on in the
                // future
                //
                // It must be done for all nested popups
                const nestedPopupSelector = [
                  INPUT_SLIDER_CLASS,
                  STYLE_POPUP_CLASS,
                ]
                  .map(c => '.' + c)
                  .join(', ');

                const inPopup =
                  (e.target as HTMLElement).closest(nestedPopupSelector) !=
                  null;

                if (inPopup) {
                  // Do nothing
                } else {
                  setPopup(false);
                }
              }}
              onOpen={e => {
                setPopup(true);
              }}
              content={
                <div className={classNames('control-popup__container')}>
                  {bBoxThresholdSliderActive && bBoxSlider()}
                  {boundingBoxControls}
                  {maskControls}
                </div>
              }
            />
          )}
        </div>
      </div>
    );
  },
  {id: 'PanelMediaBrowser.PanelHeader', memo: true}
);

type PanelContentProps = {
  disableRunLinks?: boolean;
  mediaBrowserSize: {
    width: number;
    height: number;
  };
  config: Omit<MediaBrowserPanelConfig, 'chartTitle'>;
  updateConfig: MediaBrowserPanelProps['updateConfig'];
  // NOTE:  These values are derived from config. We could move these out of
  // the argument and into helper function .e.g: getActiveAxes
  labels: Axis[];
  sizingSettings: SizingSettings;
  mediaType: MediaCardType;
  layout: Tiles;
  panelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
  allMasks: {
    [mediaKey: string]: {
      [maskKey: string]: ClassLabelNode;
    };
  };
  currentStep: number;
  maxTiles?: number;
};

const PanelContent: React.FC<PanelContentProps> = makeComp(
  props => {
    const {
      mediaType,
      layout,
      mediaBrowserSize,
      config,
      disableRunLinks,
      currentStep,
      maxTiles,
      sizingSettings,
      allMasks,
      labels,
    } = props;
    const {columnCount} = sizingSettings;
    const mediaTiles = layout.tiles;

    const [tileMediaByKey, setTileMediaByKey] = useState<Map<
      string,
      TileMedia
    > | null>(null);

    const firstTile = firstGrouped(layout.tiles);

    const firstMediaMetadata =
      firstTile != null && !firstTile.isEmpty && firstTile.run.history != null
        ? getFirstMediaMetadata(
            firstTile.run.history,
            firstTile.mediaKey,
            currentStep
          )
        : null;

    const {cardWidth, cardHeight, mediaWidth, mediaHeight, scale, shrunk} =
      getPanelContentSizing({
        config,
        mediaType,
        mediaBrowserSize,
        sizingSettings,
        firstMediaMetadata,
      });
    const showActualSize = config.actualSize || config.fitToDimension;

    // Sampled x-Axis data
    const xAxisData = layout.gridAxisData?.x;
    // const yAxisData =
    //   layout.axisData instanceof Array ? null : layout.axisData.y;
    // const xAxisZoom = config.zoom?.xAxis;
    const allXAxisValues = layout.allXAxisValues;
    const showXAxisSettings =
      config.gridSettings?.xAxis === 'step' &&
      xAxisData != null &&
      layout.xAxisTicks != null &&
      allXAxisValues != null &&
      (config.columnCount ?? 1) > 1;

    // Local pagination
    const start = config.page?.start ?? 0;
    const paginatedTiles = useMemo(
      () =>
        maxTiles != null && config.mode === 'gallery'
          ? mediaTiles.slice(start, start + maxTiles)
          : mediaTiles,
      [mediaTiles, config.mode, start, maxTiles]
    );

    const updateTileMedia = useCallback<UpdateTileMedia>(
      createUpdateTileMedia(setTileMediaByKey),
      []
    );
    useEffect(() => {
      updateTileMedia(mediaType, paginatedTiles);
    }, [updateTileMedia, mediaType, paginatedTiles]);

    if (firstMediaMetadata == null) {
      return (
        <PanelError
          message={
            <div>
              Selected runs do not have media for given keys:
              <br />
              {config.mediaKeys?.join(', ')}
            </div>
          }
        />
      );
    }

    if (tileMediaByKey == null) {
      return <WandbLoader size="large" />;
    }

    return (
      <div>
        {showXAxisSettings &&
          xAxisData != null &&
          layout.xAxisTicks != null &&
          allXAxisValues != null && (
            <React.Fragment>
              <StaticAxis
                className={`panel-media__x-axis-top ${
                  true && 'panel-media__x-axis-top--revealed'
                }`}
                ticks={xAxisData}
                direction="top"
                itemWidth={cardWidth}
                width={mediaBrowserSize.width}
              />
              <SelectableAxis
                className="panel-media__x-axis"
                ticks={layout.xAxisTicks}
                direction="bottom"
                itemWidth={cardWidth}
                width={mediaBrowserSize.width}
                // Use axis select to indicate zoom
                selection={config.selection?.xAxis}
                onSelect={range => {
                  if (config.columnCount) {
                    const newRange = expandRangeToNElements(
                      range,
                      allXAxisValues,
                      config.columnCount
                    );
                    props.updateConfig({
                      selection: {...config.selection, xAxis: newRange},
                    });

                    return newRange;
                  } else {
                    return range;
                  }
                }}
              />
            </React.Fragment>
          )}

        <Card.Group
          style={{
            gridTemplateColumns: `repeat(${columnCount.current},${cardWidth}px)`,
            minWidth: cardWidth,
          }}
          className={classNames('media-cards', {
            'media-cards--pixelated': config.pixelated,
            'media-cards--table': mediaType === 'table',
            'media-cards--shrunk': shrunk,
            'media-cards--actual': showActualSize,
            'media-cards--full': !showActualSize,
          })}>
          {paginatedTiles.map((tileOrGroup, tileIndex) => {
            // const firstTileOfGroup =
            //   'groupType' in tileOrGroup ? tileOrGroup.items[0] : tileOrGroup;
            const emptyTile = (
              <div
                key={tileIndex}
                className="ui card"
                style={{
                  width: cardWidth,
                  height: cardHeight,
                }}
              />
            );

            if (tileOrGroup == null) {
              return emptyTile;
            }

            const tiles =
              'groupType' in tileOrGroup ? tileOrGroup.items : [tileOrGroup];
            const tilesToRender = getTilesWithMaskOptions({
              config,
              allMasks,
              tiles,
            });
            const firstTileOfGroup: TileWithMaskOptions | null =
              tilesToRender[0] ?? null;

            // Group has no renderable Tiles
            if (firstTileOfGroup == null || firstTileOfGroup.isEmpty) {
              return emptyTile;
            }

            const step = firstTileOfGroup.mediaStep;

            const rowTitleLink = RunHelpers.runLink(
              firstTileOfGroup.runSignature,
              firstTileOfGroup.run.displayName,
              {
                className: 'hide-in-run-page',
                target: '_blank',
                rel: 'noopener noreferrer',
              }
            );

            // Pull out some metadata from the first tile that is needed
            // for the row labels
            //
            // NOTE: This code assumes that the runs are grouped in the same run
            const mediaComponents: JSX.Element[] = [];
            const MediaComponent = mediaTypeToComponent(mediaType);
            for (const tile of tilesToRender) {
              if (tile.isEmpty) {
                mediaComponents.push(emptyTile);
                continue;
              }
              const {
                run,
                mediaIndex,
                mediaKey,
                mediaStep,
                runSignature,
                maskOptions,
              } = tile;
              const runNotes = run.notes;

              const tileAndPanelControls = {
                ...tile.controls,
                segmentationMaskControl: config.segmentationMaskConfig,
                boundingBoxControl: config.boundingBoxConfig,
              };

              const tileMediaKey = getKeyForTileMediaSpec({
                mediaType,
                run,
                mediaIndex,
                mediaKey,
                mediaStep,
                runSignature,
              });

              mediaComponents.push(
                <MediaComponent
                  key={`card-${tileIndex}-${mediaKey}-${maskOptions.maskKeys.toString()}`}
                  run={run}
                  runSignature={runSignature}
                  runNotes={runNotes}
                  labels={labels}
                  globalStep={mediaStep}
                  mediaKey={mediaKey}
                  mediaIndex={mediaIndex}
                  width={cardWidth}
                  height={cardHeight}
                  mediaWidth={mediaWidth ?? undefined}
                  mediaHeight={mediaHeight ?? undefined}
                  actualSize={!!config.actualSize}
                  scale={scale}
                  controls={tileAndPanelControls}
                  mediaPanelRef={props.panelRef}
                  moleculeConfig={config.moleculeConfig}
                  disableRunLink={disableRunLinks}
                  maskOptions={maskOptions}
                  tileMedia={tileMediaByKey?.get(tileMediaKey) ?? null}
                />
              );
            }

            const groupCount = calcGroupCount(config);
            const showGridRow =
              config.mode === 'grid' &&
              config.columnCount &&
              (tileIndex * groupCount) % config.columnCount === 0;

            const gridYAxis = config.gridSettings?.yAxis;
            const gridRow =
              gridYAxis === 'run'
                ? renderGridRow(rowTitleLink)
                : gridYAxis === 'step'
                ? renderGridRow(`Step ${step}`)
                : null;

            return (
              <React.Fragment key={tileIndex}>
                {showGridRow && gridRow}
                {mediaComponents}
              </React.Fragment>
            );
          })}
        </Card.Group>
      </div>
    );
  },
  {id: 'PanelMediaBrowser.PanelContent', memo: true}
);

const PanelFooter = makeComp(
  (props: {
    config: Omit<MediaBrowserPanelConfig, 'chartTitle'>;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
    // NOTE:  These values are derived from config. We could move these out of
    // the argument and into helper function .e.g: getActiveAxes
    sliders: {
      step: RangeSettings;
      index: RangeSettings;
    };
    stepMeta: StepMeta;
  }) => {
    const minStep = props.sliders.step.min;
    const maxStep = props.sliders.step.max;
    const currentStepIndex = props.sliders.step.current;

    const maxMedia = props.sliders.index.max;
    const currentMediaIndex = props.sliders.index.current;

    const {updateConfig} = props;
    const updateStep = useCallback(
      (value: number) =>
        // When panning to the last step, set it back to auto-update
        updateConfig({
          stepIndex: value === maxStep ? undefined : value,
        }),
      [maxStep, updateConfig]
    );
    const stepSliderActive =
      props.sliders.step.active && maxStep > 0 && minStep < maxStep;

    const indexSliderActive = props.sliders.index.active && maxMedia > 0;

    const controlSlidersActive = stepSliderActive || indexSliderActive;

    const controlSliders = (stepSliderActive || indexSliderActive) && (
      <>
        <div className={'control-popup__slider-container control-popup__item'}>
          {stepSliderActive && (
            <div className="control-popup__control-slider">
              <Popup
                trigger={<label>Step</label>}
                content={
                  'This increments every time you call wandb.log in your script'
                }></Popup>
              <SliderInput
                min={minStep}
                max={maxStep}
                minLabel=""
                maxLabel=""
                step={1}
                ticks={props.stepMeta.steps}
                value={currentStepIndex}
                strideLength={props.config.stepStrideLength}
                hasInput
                debounceTime={10}
                onChange={updateStep}
              />
            </div>
          )}

          {indexSliderActive && (
            <div className="control-popup__control-slider">
              <Popup
                trigger={<label>Index</label>}
                content={
                  'This is the index in the array of examples in your wandb.log call'
                }></Popup>
              <SliderInput
                min={0}
                max={maxMedia}
                minLabel="0"
                maxLabel={maxMedia.toString()}
                step={1}
                value={currentMediaIndex}
                hasInput
                onChange={configValue =>
                  updateConfig({mediaIndex: configValue})
                }
              />
            </div>
          )}
        </div>
      </>
    );
    return (
      <div>
        {controlSlidersActive && (
          <div className={classNames('control-popup__container')}>
            {controlSliders}
          </div>
        )}
      </div>
    );
  },
  {id: 'PanelMediaBrowser.PanelFooter', memo: true}
);

const PanelSettings = makeComp(
  (props: {
    config: MediaConfigWithDefaults;
    mediaType: MediaCardType | null;
    multiRun?: boolean;
    allMediaKeys: string[];
    sizingSettings: SizingSettings;
    historySampleRate?: number;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
  }) => {
    const {allMediaKeys, mediaType, updateConfig, sizingSettings} = props;
    const {config} = props;

    // Make filter options
    const axisOptions = (config.mode === 'gallery' ? AxisStrings : AxisStrings)
      .filter(s => {
        // When in not in multi run mode remove the run options
        return !props.multiRun && s === 'run' ? false : true;
      })
      .filter(s => {
        //           Performance tuning required
        //           This requires adding a cache to useLoadFile so it reuses
        //           the same blob between cards and adding something to the
        //           babylon renderer to reuse the same scene object.
        // return mediaType !== 'object3D' && s === 'camera' ? false : true;
        return s !== 'camera';
      })
      .map(s => {
        let text;
        if (s === 'step') {
          text = 'Log Step';
        } else if (s === 'index') {
          text = 'Example Index';
        } else if (s === 'run') {
          text = 'Run';
        } else {
          text = s;
        }

        return {text, key: s, value: s};
      });

    const xAxis = config.gridSettings && config.gridSettings.xAxis;
    const yAxis = config.gridSettings && config.gridSettings.yAxis;
    const galleryAxis = config.gallerySettings && config.gallerySettings.axis;

    const basicTab = (
      <div>
        <Form className="settings__section">
          <div className="panel-setting__item panel-setting__item--stacked">
            <h5 className="input-label">Chart Title</h5>
            <Input
              fluid
              value={config.chartTitle || ''}
              onChange={_.debounce((e, {value}) => {
                updateConfig({
                  chartTitle: value,
                });
              })}
            />
          </div>

          {
            <div className="panel-setting__item panel-setting__item--stacked">
              <h5 className="input-label">Media Key(s)</h5>
              <Form.Field className="panel-setting">
                <ModifiedDropdown
                  style={{width: '100%'}}
                  data-test="media-key-selector"
                  placeholder="Select Media Key"
                  multiple
                  enableReordering
                  search
                  selection
                  options={allMediaKeys.map(k => {
                    return {key: k, value: k, text: k};
                  })}
                  value={config.mediaKeys}
                  onChange={(e, {value}) => {
                    // Convert singletons to array
                    const updateValue = _.isArray(value)
                      ? (value as string[])
                      : [value as string];

                    updateConfig({mediaKeys: updateValue});
                  }}
                />
              </Form.Field>
            </div>
          }

          {sizingSettings.columnCount.max !== sizingSettings.columnCount.min &&
            !config.actualSize &&
            !config.fitToDimension && (
              <>
                <div className="panel-setting__item panel-setting__item--stacked">
                  <h5 className="input-label">Columns</h5>
                  <SliderInput
                    sliderInPopup
                    min={sizingSettings.columnCount.min}
                    max={sizingSettings.columnCount.max}
                    minLabel={sizingSettings.columnCount.min.toString()}
                    maxLabel={sizingSettings.columnCount.max.toString()}
                    step={1}
                    value={sizingSettings.columnCount.current}
                    debounceTime={50}
                    hasInput
                    onChange={configValue =>
                      updateConfig({columnCount: configValue})
                    }
                  />
                </div>
                {config.mode === 'gallery' ? (
                  <div className="panel-setting__item panel-setting__item--stacked">
                    <h5 className="input-label">
                      Max Item Count
                      <HelpPopup helpText="The maximum number of items that will be display in the panel" />
                    </h5>
                    <SliderInput
                      sliderInPopup
                      min={0}
                      max={108}
                      minLabel={'0'}
                      maxLabel={'108'}
                      step={1}
                      value={config.maxGalleryItems}
                      debounceTime={50}
                      hasInput
                      onChange={configValue =>
                        updateConfig({maxGalleryItems: configValue})
                      }
                    />
                  </div>
                ) : (
                  <div className="panel-setting__item panel-setting__item--stacked">
                    <h5 className="input-label">Rows</h5>
                    <SliderInput
                      sliderInPopup
                      min={0}
                      max={50}
                      minLabel={'0'}
                      maxLabel={'50'}
                      step={1}
                      value={config.maxYAxisCount}
                      debounceTime={50}
                      hasInput
                      onChange={configValue =>
                        updateConfig({maxYAxisCount: configValue})
                      }
                    />
                  </div>
                )}
              </>
            )}

          {(mediaType === 'image' || mediaType === 'video') &&
            config.mode !== 'grid' && (
              <>
                <div
                  className="panel-setting__item"
                  onClick={() =>
                    updateConfig({
                      actualSize: !config.actualSize,
                      fitToDimension: false,
                    })
                  }>
                  <Checkbox toggle checked={config.actualSize} />
                  <span className="panel-setting__toggle-label">
                    Original size
                  </span>
                </div>
                {mediaType === 'image' && (
                  <div
                    className="panel-setting__item"
                    onClick={() =>
                      updateConfig({
                        fitToDimension: !config.fitToDimension,
                        actualSize: false,
                      })
                    }>
                    <Checkbox toggle checked={config.fitToDimension} />
                    <span className="panel-setting__toggle-label">
                      Fit to one dimension
                      <HelpPopup helpText="Fit one dimension of your media to the panel and scroll along the other one." />
                    </span>
                  </div>
                )}
              </>
            )}
        </Form>
      </div>
    );

    // Actions
    const swapMode = () => {
      if (config.mode === 'grid') {
        updateConfig({mode: 'gallery'});
      } else {
        updateConfig({mode: 'grid', actualSize: false, fitToDimension: false});
      }
    };

    const advancedTab = (
      <>
        {mediaType === 'image' && (
          <div
            className="panel-setting__item"
            onClick={() => updateConfig({pixelated: !config.pixelated})}>
            <Checkbox toggle checked={!config.pixelated} />
            <span className="panel-setting__toggle-label">Smooth Image</span>
          </div>
        )}

        <div className="panel-setting__item" onClick={swapMode}>
          <Checkbox
            disabled={
              config.actualSize === true || config.fitToDimension === true
            }
            toggle
            checked={config.mode === 'grid'}
          />
          <span className="panel-setting__toggle-label">Grid Mode</span>
        </div>

        {config.mode === 'gallery' && (
          <>
            <h5 className="input-label">Columns</h5>
            <ModifiedDropdown
              data-test="media-key-selector"
              placeholder="Columns"
              options={axisOptions}
              value={galleryAxis}
              selection
              onChange={(e, {value}) => {
                // Convert singletons to array
                const axis = value as Axis;

                updateConfig({
                  gallerySettings: {
                    axis,
                  },
                });
              }}
            />
          </>
        )}

        <div className="panel-setting__item panel-setting__item--stacked">
          <h5 className="input-label">Stride Length</h5>
          <Input
            value={config.stepStrideLength}
            type="number"
            onChange={(e, {value}) => {
              updateConfig({
                stepStrideLength: parseInt(value, 0),
              });
            }}
          />
        </div>

        {config.mode === 'grid' && (
          <>
            <div className="panel-setting__item panel-setting__item--stacked">
              <h5 className="input-label">X-Axis</h5>
              <ModifiedDropdown
                data-test="media-key-selector"
                placeholder="X-Axis"
                // Hide the currently selected option in the counter axis
                options={axisOptions}
                value={xAxis}
                selection
                onChange={(e, {value}) => {
                  // Convert singletons to array
                  const axis = value as Axis;

                  // When the same axis is picked for both cause a swap
                  if (axis === yAxis) {
                    updateConfig({
                      gridSettings: {
                        ...config.gridSettings,
                        yAxis: xAxis,
                        xAxis: axis,
                      },
                    });
                  } else {
                    updateConfig({
                      gridSettings: {
                        ...config.gridSettings,
                        xAxis: axis,
                      },
                    });
                  }
                }}
              />
            </div>
            <div className="panel-setting__item panel-setting__item--stacked">
              <h5 className="input-label">Y-Axis</h5>
              <ModifiedDropdown
                data-test="media-key-selector"
                placeholder="Row"
                options={axisOptions}
                value={yAxis}
                selection
                onChange={(e, {value}) => {
                  const axis = value as Axis;

                  // When the same axis is picked for both cause a swap
                  if (axis === xAxis) {
                    updateConfig({
                      gridSettings: {
                        ...config.gridSettings,
                        yAxis: axis,
                        xAxis: yAxis,
                      },
                    });
                  } else {
                    updateConfig({
                      gridSettings: {
                        ...config.gridSettings,
                        yAxis: axis,
                      },
                    });
                  }
                }}
              />
            </div>
          </>
        )}
        {props.historySampleRate && props.historySampleRate !== 1 && (
          <Message>
            {`Log Steps are sampled at a rate of ~${parseFloat(
              props.historySampleRate.toString()
            ).toFixed(3)} steps per sample`}
          </Message>
        )}
      </>
    );
    const baseTabs = [
      {
        menuItem: 'Basic',
        render: () => (
          <Tab.Pane as="div" className="form-grid">
            {basicTab}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Advanced',
        render: () => (
          <Tab.Pane as="div" className="form-grid">
            {advancedTab}
          </Tab.Pane>
        ),
      },
    ];

    const moleculeTab = {
      menuItem: 'Molecular',
      render: () => {
        const mConfig = config.moleculeConfig || {};

        const updateMConfig = (
          newPartialConfig: Partial<MediaBrowserPanelConfig['moleculeConfig']>
        ) =>
          updateConfig({
            moleculeConfig: {
              ...mConfig,
              ...newPartialConfig,
            },
          });

        const {representation} = mConfig;

        return (
          <Tab.Pane as="div" className="form-grid">
            Representation Style
            <Dropdown
              value={representation ?? 'default'}
              options={makeOptions(RepresentationTypeValues)}
              onChange={(e, v) => {
                if (typeof v.value === 'string') {
                  updateMConfig({
                    representation: v.value as RepresentationType,
                  });
                }
              }}
            />
          </Tab.Pane>
        );
      },
    };
    const tabs = baseTabs;

    if (mediaType === 'molecule') {
      tabs.push(moleculeTab);
    }
    return (
      <Tab
        panes={tabs}
        menu={{
          secondary: true,
          pointing: true,
          className: 'chart-settings-tab-menu',
        }}></Tab>
    );
  },
  {id: 'PanelMediaBrowser.PanelSettings', memo: true}
);

type PaginationProps = {
  total: number;
  panelViewerRef: React.MutableRefObject<HTMLDivElement | null>;
  updateConfig: MediaBrowserPanelProps['updateConfig'];
  size: number;
  start?: number;
};

const Pagination: React.FC<PaginationProps> = makeComp(
  ({total, panelViewerRef, updateConfig, start = 0, size}) => {
    const nextDisabled: boolean = start + size > total;
    const prevDisabled: boolean = start === 0;

    const pageStart = start + 1;
    const pageEnd = Math.min(start + size, total);

    return (
      <div style={{marginBottom: -5}} className="inline-pagination">
        <div className="pagination-count">
          {pageStart} - {pageEnd} of {total}
        </div>
        <Button.Group className="pagination-buttons">
          <Button
            size="tiny"
            disabled={prevDisabled}
            className="wb-icon-button only-icon page-down-button"
            onClick={() => {
              updateConfig({
                page: {start: start - size},
              });
            }}>
            <LegacyWBIcon name="previous"></LegacyWBIcon>
          </Button>
          <Button
            size="tiny"
            disabled={nextDisabled}
            className="wb-icon-button only-icon page-down-button"
            onClick={() => {
              updateConfig({
                page: {start: start + size},
              });
              if (panelViewerRef.current) {
                panelViewerRef.current.scrollTop = 0;
              }
            }}>
            <LegacyWBIcon name="next"></LegacyWBIcon>
          </Button>
        </Button.Group>
      </div>
    );
  },
  {id: 'PanelMediaBrowser.Pagination', memo: true}
);

type MissingRunsProps = {
  runsWithoutMedia: RunWithHistoryAndMediaWandb[];
};

// Render the runs that are selected, but are hidden from the user
const MissingRuns: React.FC<MissingRunsProps> = makeComp(
  ({runsWithoutMedia}) => {
    if (runsWithoutMedia.length === 0) {
      return <div></div>;
    }

    return (
      <S.MissingRuns>
        <Popup
          hoverable
          trigger={
            <a href="#hover">
              Selected Runs Without Media ({`${runsWithoutMedia.length})`}
            </a>
          }>
          <List
            items={runsWithoutMedia.map(r => {
              const linkData = {
                entityName: r.entityName,
                projectName: r.projectName,
                name: r.name,
              };
              return (
                <Link to={urls.run(linkData)}>
                  <li> {r.displayName}</li>
                </Link>
              );
            })}
          />
        </Popup>
      </S.MissingRuns>
    );
  },
  {id: 'PanelMediaBrowser.MissingRuns', memo: true}
);

const MaskControlComponent = makeComp(
  (p: {
    mediaKey: string;
    maskKey: string;
    classID: string;
    className: string;
    maskConfig: AllMaskControls;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
  }) => {
    const {classID, className, mediaKey, maskKey, maskConfig, updateConfig} = p;

    const configPath = ['toggles', mediaKey, maskKey, classID];

    const maskControl: MaskControl =
      _.get(maskConfig, configPath) ??
      (classID === 'all'
        ? DEFAULT_ALL_MASK_CONTROL
        : DEFAULT_CLASS_MASK_CONTROL);

    const update = (newControl: Partial<MaskControl>) => {
      const newConfig = produce(maskConfig, draft => {
        return _.setWith(
          draft,
          configPath,
          {...maskControl, ...newControl},
          Object
        );
      });
      updateConfig({segmentationMaskConfig: newConfig});
    };

    const maskColor =
      classID === 'all' ? [0, 0, 0] : segmentationMaskColor(Number(classID));

    const backgroundColor = `rgb(${maskColor[0]}, ${maskColor[1]}, ${maskColor[2]})`;

    const onToggleHandler = () => update({disabled: !maskControl.disabled});
    const onToggleOpacityHandler = (opacity: number) => update({opacity});

    return (
      <ClassToggleWithSlider
        color={backgroundColor}
        disabled={maskControl.disabled}
        key={className}
        name={classID === 'all' ? classID : className}
        onClick={onToggleHandler}
        opacity={maskControl.opacity}
        onOpacityChange={onToggleOpacityHandler}
      />
    );
  },
  {id: 'MaskControlComponent', memo: true}
);

const BBoxControlComponent = makeComp(
  (p: {
    mediaKey: string;
    boxKey: string;
    classID: string;
    className: string;
    boxConfig: AllBoundingBoxControls;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
  }) => {
    const {classID, className, mediaKey, boxKey, boxConfig, updateConfig} = p;

    const configPath = ['toggles', mediaKey, boxKey, classID];

    const boxControl: BoundingBoxClassControl =
      _.get(boxConfig, configPath) ??
      (classID === 'all'
        ? DEFAULT_ALL_MASK_CONTROL
        : DEFAULT_CLASS_MASK_CONTROL);

    const update = (newControl: Partial<BoundingBoxClassControl>) => {
      const newConfig = produce(boxConfig, draft => {
        return _.setWith(
          draft,
          configPath,
          {...boxControl, ...newControl},
          Object
        );
      });
      updateConfig({boundingBoxConfig: newConfig});
    };

    const classColor =
      classID === 'all' ? [0, 0, 0] : segmentationMaskColor(Number(classID));

    const backgroundColor = `rgb(${classColor[0]}, ${classColor[1]}, ${classColor[2]})`;

    const onClickHandler = () => update({disabled: !boxControl.disabled});

    return (
      <ClassToggle
        color={backgroundColor}
        disabled={boxControl.disabled}
        key={className}
        name={classID === 'all' ? classID : className}
        onClick={onClickHandler}
      />
    );
  },
  {id: 'BBoxControlComponent', memo: true}
);

const styleOptions = [
  {
    key: 'line',
    icon: 'line-solid',
  },
  {
    key: 'dotted',
    icon: 'line-dot',
  },
  {
    key: 'dashed',
    icon: 'line-dash',
  },
];

const STYLE_POPUP_CLASS = 'line-style-buttons';

const BBoxStyleControl = makeComp(
  (p: {
    mediaKey: string;
    boxKey: string;
    boxConfig: AllBoundingBoxControls;
    updateConfig: MediaBrowserPanelProps['updateConfig'];
  }) => {
    const {mediaKey, boxKey, boxConfig, updateConfig} = p;

    const style = boxConfig.styles?.[mediaKey]?.[boxKey] ?? defaultBoxStyle;

    const updateStyle = (newStyle: Style) => {
      const newConfig = produce(boxConfig, draft => {
        // We need to set a default layout to perform updates
        _.set(draft, ['styles', mediaKey, boxKey], {...style, ...newStyle});
        return draft;
      });

      updateConfig({boundingBoxConfig: newConfig});
    };

    const activeMarkOption =
      styleOptions.find(o => o.key === style.lineStyle) || styleOptions[0];

    return (
      <Popup
        offset={-12}
        className="line-style-picker-popup"
        on="click"
        trigger={
          <LegacyWBIcon
            style={{marginLeft: 8}}
            name={activeMarkOption.icon}
            className="line-style-picker"
          />
        }>
        <Button.Group className="line-style-buttons">
          {styleOptions.map(markOption => (
            <Button
              size="tiny"
              active={markOption.key === style.lineStyle}
              className="wb-icon-button only-icon"
              key={markOption.key}
              onClick={() => {
                updateStyle({lineStyle: markOption.key as LineStyle});
              }}>
              <LegacyWBIcon name={markOption.icon} />
            </Button>
          ))}
        </Button.Group>
      </Popup>
    );
  },
  {id: 'BBoxStyleControl', memo: true}
);

const maskOptionsForLayout = (
  layoutType: LayoutType | null,
  maskKeys: string[]
): MaskOptions[] => {
  if (layoutType === null) {
    layoutType = 'MASKS_NEXT_TO_IMAGE';
  }
  if (layoutType === 'ALL_STACKED') {
    return [{maskKeys, showImage: true}];
  } else if (layoutType === 'ALL_SPLIT') {
    return [
      {
        maskKeys: [],
        showImage: true,
      },
      ..._.map(maskKeys, k => ({
        maskKeys: [k],
        showImage: false,
      })),
    ];
  } else if (layoutType === 'MASKS_NEXT_TO_IMAGE') {
    return [
      {
        maskKeys: [],
        showImage: true,
      },
      {
        maskKeys,
        showImage: false,
      },
    ];
  } else {
    // This case should never be hit it is used to hint the type system
    throw new Error('INVALID LAYOUT TYPE');
  }
};

const defaultLayout = (maskKeys: string[]) => {
  return {
    type: DEFAULT_LAYOUT_TYPE as LayoutType,
    maskOptions: maskOptionsForLayout(DEFAULT_LAYOUT_TYPE, maskKeys),
  };
};

const keysToTitle = (keys: string[]) => {
  return keys.length < 2 ? keys[0] : keys.join(', ');
};

function renderGridRow(n: React.ReactNode): JSX.Element {
  return <div className="panel-media__grid-row">{n}</div>;
}

function getTitleFromConfig(config: MediaBrowserPanelConfig): string {
  return (
    config.chartTitle ||
    (config.mediaKeys != null ? keysToTitle(config.mediaKeys) : PANEL_TYPE)
  );
}

function transformQuery(
  query: Query.Query,
  config: MediaBrowserPanelConfig
): RunsDataQuery {
  const mediaKeys = config.mediaKeys || undefined;
  const result = toRunsDataQuery(
    query,
    {
      selectionsAsFilters: true,
    },
    {historyKeyInfo: true}
  );
  result.disabled = false;
  result.history = true;
  // This is required to get _wandb for single runs
  result.wandbKeys = [WANDB_MASK_CLASS_LABEL_KEY, WANDB_BBOX_CLASS_LABEL_KEY];
  //  This is required to get _wandb for multi runs
  // result.configKeys = ['_wandb'];
  if (mediaKeys) {
    result.historySpecs = [
      {keys: ['_step', ...mediaKeys], samples: CHART_SAMPLES},
    ];
  }
  result.page = {
    size: 30,
  };

  if (isGrouped(query)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // Disable grouping for this query, we'll do it ourselves.
    result.queries = result.queries.map(q => ({...q, grouping: []}));
  }

  return result;
}

export const Spec: Panels.PanelSpec<
  typeof PANEL_TYPE,
  MediaBrowserPanelConfig
> = {
  type: PANEL_TYPE,
  Component: MediaBrowserPanel,
  transformQuery,
  getTitleFromConfig,
  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    columnCount: {
      editor: 'slider',
      displayName: 'Columns',
      min: 1,
      max: 20,
      step: 1,
      default: 4,
    },
    actualSize: {
      editor: 'checkbox',
      displayName: 'Original size',
      default: false,
    },
    fitToDimension: {
      editor: 'checkbox',
      displayName: 'Cover fit',
      default: false,
    },
    pixelated: {editor: 'checkbox', displayName: 'Pixelated', default: true},
    mode: {
      editor: 'dropdown',
      displayName: 'Display as',
      default: 'gallery',
      options: [
        {text: 'Gallery', value: 'gallery'},
        {text: 'Grid', value: 'grid'},
      ],
    },
  },
  icon: 'panel-images',
};

export const ShowMoreContainer = makeComp(
  (props: {iconSize?: IconSizeProp; children: JSX.Element[]}) => {
    const [open, setOpen] = useState<boolean>(false);
    const iconSize = props.iconSize;

    const iconProps = {size: iconSize, onClick: () => setOpen(!open)};

    return (
      <div style={{display: 'flex', maxWidth: 600}}>
        <LegacyWBIcon
          {...iconProps}
          name="next"
          style={{
            cursor: 'pointer',
            transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
          className="open show-more-container-toggle"
        />
        <div
          style={{
            maxHeight: open ? undefined : 32,
            overflow: 'hidden',
          }}>
          {props.children}
        </div>
      </div>
    );
  },
  {id: 'ShowMoreContainer', memo: true}
);

const calcGroupCount = (config: MediaBrowserPanelConfig): number => {
  return _.sum(
    _.map(config.mediaKeys, m => {
      const tileCount = _.get(
        config.tileLayout,
        [m, 'maskOptions', 'length'],
        1
      );
      return tileCount;
    })
  );
};

const defaultBoxStyle = {lineStyle: 'line'};

function getMaskOptions(
  config: Omit<MediaBrowserPanelConfig, 'chartTitle'>,
  allMasks: {
    [mediaKey: string]: {
      [maskKey: string]: ClassLabelNode;
    };
  },
  t: EmptyTile | Tile
): MaskOptions[] {
  const maskKeys = Object.keys(allMasks[t.mediaKey] ?? {});
  const tileLayout = _.get(
    config,
    ['tileLayout', t.mediaKey],
    defaultLayout(maskKeys)
  ) as {
    type: string;
    maskOptions: MaskOptions[];
  };
  return tileLayout.maskOptions;
}

type MediaBrowserDimensionsWithRefCallback = {
  width: number | null;
  height: number | null;
  refCallback: (el: HTMLDivElement) => void;
};

function useMediaBrowserDimensions(): MediaBrowserDimensionsWithRefCallback {
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const mediaBrowserWidthRef = useRef(width);
  mediaBrowserWidthRef.current = width;
  const mediaBrowserHeightRef = useRef(height);
  mediaBrowserHeightRef.current = height;

  // Update the image browser width, to determine if we need to resize the media components
  // fires when window is resized or componentDidUpdate
  const updateMediaBrowserSize = useCallback(
    _.debounce(() => {
      const currentMediaBrowserWidth = ref.current && ref.current.clientWidth;

      const currentMediaBrowserHeight = ref.current && ref.current.clientHeight;

      if (
        currentMediaBrowserWidth != null &&
        mediaBrowserWidthRef.current !== currentMediaBrowserWidth
      ) {
        setWidth(currentMediaBrowserWidth);
      }

      if (
        currentMediaBrowserHeight != null &&
        mediaBrowserHeightRef.current !== currentMediaBrowserHeight
      ) {
        setHeight(currentMediaBrowserHeight);
      }
    }, 100),
    []
  );

  // This allows us to set the ref and perform an operation on ref update
  const refCallback = useCallback(
    (ele: HTMLDivElement) => {
      ref.current = ele;
      updateMediaBrowserSize();
    },
    [updateMediaBrowserSize]
  );

  useEffect(() => {
    window.addEventListener('resize', updateMediaBrowserSize);
    return () => {
      window.removeEventListener('resize', updateMediaBrowserSize);
    };
  }, [updateMediaBrowserSize]);

  return {
    width,
    height,
    refCallback,
  };
}

type PanelContentSizing = {
  cardWidth: number;
  cardHeight: number;
  mediaWidth: number | null;
  mediaHeight: number | null;
  scale: number | undefined;
  shrunk: boolean;
};

type GetPanelContentSizingParams = Pick<
  PanelContentProps,
  'config' | 'mediaBrowserSize' | 'sizingSettings' | 'mediaType'
> & {
  firstMediaMetadata: MediaCardMetadata | null;
};

function getPanelContentSizing({
  config,
  mediaType,
  mediaBrowserSize,
  sizingSettings,
  firstMediaMetadata,
}: GetPanelContentSizingParams): PanelContentSizing {
  const availableWidth =
    mediaBrowserSize.width - (sizingSettings.columnCount.current + 1) * PADDING;
  const availableHeight = mediaBrowserSize.height - 2 * PADDING;

  const groupingCount = firstMediaMetadata?.grouping ?? 1;
  // Helper function for getting card size given the current media and panel settings
  const mediaWidth =
    firstMediaMetadata?.width != null
      ? firstMediaMetadata.width * groupingCount
      : null;
  const mediaHeight = firstMediaMetadata?.height ?? null;
  let cardWidth = availableWidth / sizingSettings.columnCount.current;
  let cardHeight = availableHeight;
  // Use width height values that fall back to the card size if we don't
  // have any metadata(This happened in old versions of the cli)
  const imgWidth = mediaWidth ?? cardWidth;
  const imgHeight = mediaHeight ?? cardHeight;
  let scale: number | undefined;
  if (mediaType === 'image') {
    if (config.actualSize) {
      cardWidth = mediaWidth ?? cardWidth;
      cardHeight = mediaHeight ?? cardHeight;
    } else if (config.fitToDimension) {
      if (imgWidth / availableWidth > imgHeight / availableHeight) {
        cardHeight = availableHeight;
        cardWidth = (cardHeight / imgHeight) * imgWidth;
      } else {
        cardWidth = availableWidth;
        cardHeight = (cardWidth / imgWidth) * imgHeight;
      }
    } else {
      // Size all cards by the column width and adjust the height
      // To maintain the aspect ratio
      cardHeight = (cardWidth / imgWidth) * imgHeight;
    }
  } else if (mediaType === 'html' || mediaType === 'plotly') {
    cardHeight = mediaBrowserSize.height;
  } else if (mediaType === 'object3D') {
    cardHeight = cardWidth;
  } else if (mediaType === 'audio') {
    cardHeight = 100;
    // Percent Scaling
    scale = 100;
  } else {
    cardHeight = cardWidth;
  }

  // If the tiles don't fit in the media panel we want to shrink them
  // and change the layout so they're centered
  let shrunk = false;
  const margins = 25;
  if (
    cardHeight > mediaBrowserSize.height - margins &&
    config.actualSize !== true &&
    config.fitToDimension !== true
  ) {
    const aspectRatio = (mediaWidth ?? cardWidth) / (mediaHeight ?? cardHeight);
    cardHeight = mediaBrowserSize.height - margins;
    cardWidth = (mediaBrowserSize.height - margins) * aspectRatio;
    shrunk = true;
  }

  return {
    cardWidth,
    cardHeight,
    mediaWidth,
    mediaHeight,
    scale,
    shrunk,
  };
}

type TileWithMaskOptions = (EmptyTile | Tile) & {
  maskOptions: MaskOptions;
};

type GetTilesWithMaskOptionsParams = Pick<
  PanelContentProps,
  'config' | 'allMasks'
> & {
  tiles: Array<EmptyTile | Tile>;
};

// Expands each tile into multiple based on the mask layout
function getTilesWithMaskOptions({
  config,
  allMasks,
  tiles,
}: GetTilesWithMaskOptionsParams): TileWithMaskOptions[] {
  const tilesWithMaskOptions: TileWithMaskOptions[] = [];

  for (const t of tiles) {
    const maskOptions = getMaskOptions(config, allMasks, t);
    const maskOptionsForTile = maskOptions.map(mo => ({...t, maskOptions: mo}));
    tilesWithMaskOptions.push(...maskOptionsForTile);
  }

  return tilesWithMaskOptions;
}

type UpdateTileMedia = (
  mediaType: MediaCardType,
  paginatedTiles: GroupedList<Tile | EmptyTile>
) => void;

function createUpdateTileMedia(
  setTileMediaByKeyInComp: (tmbk: Map<string, TileMedia> | null) => void
): UpdateTileMedia {
  let lastTileMediaSpecByKey: Map<string, TileMediaSpec> | null = null;
  let tileMediaByKey: Map<string, TileMedia> | null = null;
  let lastRequestID: number | null = null;

  const setTileMediaByKey: typeof setTileMediaByKeyInComp = tmbk => {
    if (tileMediaByKey === tmbk) {
      return;
    }
    tileMediaByKey = tmbk;
    setTileMediaByKeyInComp(tmbk);
  };

  const update = _.debounce(
    async (tileMediaSpecByKey: Map<string, TileMediaSpec>): Promise<void> => {
      const requestID = Math.random();
      lastRequestID = requestID;
      const fetchedTileMediaByKey = await fetchTileMediaByKey(
        tileMediaSpecByKey
      );
      if (lastRequestID === requestID) {
        setTileMediaByKey(fetchedTileMediaByKey);
      }
    },
    500
  );

  return (
    mediaType: MediaCardType,
    paginatedTiles: GroupedList<Tile | EmptyTile>
  ) => {
    const tileMediaSpecByKey = getTileMediaSpecByKey(mediaType, paginatedTiles);
    if (tileMediaSpecByKeyEqual(lastTileMediaSpecByKey, tileMediaSpecByKey)) {
      return;
    }
    lastTileMediaSpecByKey = tileMediaSpecByKey;

    setTileMediaByKey(null);
    update(tileMediaSpecByKey);
  };
}

function getTileMediaSpecByKey(
  mediaType: MediaCardType,
  paginatedTiles: GroupedList<Tile | EmptyTile>
): Map<string, TileMediaSpec> {
  const tileMediaSpecByKey: Map<string, TileMediaSpec> = new Map();
  for (const tileOrGroup of paginatedTiles) {
    if (tileOrGroup == null) {
      continue;
    }

    const tiles =
      'groupType' in tileOrGroup ? tileOrGroup.items : [tileOrGroup];
    if (tiles.length === 0 || tiles[0].isEmpty) {
      continue;
    }

    for (const tile of tiles) {
      if (tile.isEmpty) {
        continue;
      }
      const {run, mediaIndex, mediaKey, mediaStep, runSignature} = tile;
      const tileMediaSpec: TileMediaSpec = {
        mediaType,
        run,
        mediaIndex,
        mediaKey,
        mediaStep,
        runSignature,
      };
      const tileMediaSpecKey = getKeyForTileMediaSpec(tileMediaSpec);
      tileMediaSpecByKey.set(tileMediaSpecKey, tileMediaSpec);
    }
  }
  return tileMediaSpecByKey;
}

function tileMediaSpecByKeyEqual(
  a: Map<string, TileMediaSpec> | null,
  b: Map<string, TileMediaSpec> | null
): boolean {
  if (a == null || b == null) {
    return false;
  }

  const aKeys = new Set(a.keys());
  const bKeys = new Set(b.keys());
  if (aKeys.size !== bKeys.size) {
    return false;
  }

  for (const key of aKeys) {
    if (!bKeys.has(key)) {
      return false;
    }
  }

  return true;
}

async function fetchTileMediaByKey(
  tileMediaSpecByKey: Map<string, TileMediaSpec>
): Promise<Map<string, TileMedia>> {
  const tileMediaSpecsByRunSignatureKey: Map<string, TileMediaSpec[]> =
    new Map();
  for (const [, tileMediaSpec] of tileMediaSpecByKey) {
    const {runSignature} = tileMediaSpec;
    const runSignatureKey = getKeyForRunSignature(runSignature);
    const tileMediaSpecs =
      tileMediaSpecsByRunSignatureKey.get(runSignatureKey) ?? [];
    tileMediaSpecs.push(tileMediaSpec);
    tileMediaSpecsByRunSignatureKey.set(runSignatureKey, tileMediaSpecs);
  }

  const tileMediaByKey: Map<string, TileMedia> = new Map();
  const promises: Array<Promise<void>> = [];
  for (const [, tileMediaSpecs] of tileMediaSpecsByRunSignatureKey) {
    for (const tileMediaSpec of tileMediaSpecs) {
      const promise = (async () => {
        const tileMedia = await getLastAvailableTileMediaForTileMediaSpec(
          tileMediaSpec
        );
        if (tileMedia == null) {
          return;
        }
        const tileMediaSpecKey = getKeyForTileMediaSpec(tileMediaSpec);
        tileMediaByKey.set(tileMediaSpecKey, tileMedia);
      })();
      promises.push(promise);
    }
  }
  await Promise.all(promises);
  return tileMediaByKey;
}

const AVAILABLE_FILES_BATCH_SIZE = 100;
const AVAILABLE_FILES_MAX_STEPS_CHECKED = 1000;

async function getLastAvailableTileMediaForTileMediaSpec(
  tileMediaSpec: TileMediaSpec
): Promise<TileMedia | null> {
  const {runSignature, mediaKey, mediaStep, run} = tileMediaSpec;

  const stepsWithMedia = stepsWithKey(run, mediaKey);
  const latestStep = latestStepWithMedia(stepsWithMedia, mediaStep);
  if (latestStep == null) {
    return null;
  }

  const historyRows = run.history ?? [];
  const historyRowByStep: Map<number, RunHistoryRow> = new Map(
    historyRows.map(r => [r._step, r])
  );

  let stepLimit = latestStep;
  let numStepsChecked = 0;

  while (true) {
    if (stepLimit < 0 || numStepsChecked >= AVAILABLE_FILES_MAX_STEPS_CHECKED) {
      return null;
    }

    const stepsToCheck: number[] = [];
    for (let i = stepsWithMedia.length - 1; i >= 0; i--) {
      const step = stepsWithMedia[i];
      if (step > stepLimit) {
        continue;
      }
      stepsToCheck.unshift(step);
      stepLimit = step - 1;
      if (stepsToCheck.length >= AVAILABLE_FILES_BATCH_SIZE) {
        break;
      }
    }
    if (stepsToCheck.length === 0) {
      return null;
    }
    numStepsChecked += stepsToCheck.length;

    const filePaths: string[] = [];
    const stepByFilePath: Map<string, number> = new Map();
    const filePathByStep: Map<number, string> = new Map();
    for (const s of stepsToCheck) {
      const historyRow = historyRowByStep.get(s);
      if (historyRow == null) {
        continue;
      }
      const filePath = getMediaFilePath({...tileMediaSpec, historyRow});
      if (!filePath) {
        continue;
      }
      filePaths.push(filePath);
      stepByFilePath.set(filePath, s);
      filePathByStep.set(s, filePath);
    }

    let availableFilesResponse: ApolloQueryResult<any>;
    try {
      availableFilesResponse = await apolloClient.query({
        query: AVAILABLE_FILES_QUERY,
        variables: {
          ...runSignature,
          filenames: filePaths,
        },
        fetchPolicy: 'no-cache',
        context: propagateErrorsContext(),
      });
      if (availableFilesResponse.errors != null) {
        throw availableFilesResponse.errors;
      }
    } catch (err) {
      const firstStep = _.first(stepsToCheck);
      const lastStep = _.last(stepsToCheck);
      console.error(
        `Error checking available files for steps ${firstStep} - ${lastStep}: ${err}`
      );
      return null;
    }

    const fileInfos: FileInfo[] =
      availableFilesResponse.data?.project?.run?.files?.edges
        ?.map((e: {node: FileInfo}) => e.node)
        .filter((f: FileInfo) => f.sizeBytes > 0 && f.directUrl) ?? [];

    if (fileInfos.length === 0) {
      continue;
    }

    const fileInfoByFilePath: Map<string, FileInfo> = new Map();
    for (const f of fileInfos) {
      fileInfoByFilePath.set(f.name, f);
    }

    for (let i = stepsToCheck.length - 1; i >= 0; i--) {
      const step = stepsToCheck[i];
      const historyRow = historyRowByStep.get(step);
      if (historyRow == null) {
        continue;
      }
      const filePath = filePathByStep.get(step);
      if (filePath == null) {
        continue;
      }
      const fileInfo = fileInfoByFilePath.get(filePath);
      if (fileInfo == null) {
        continue;
      }

      const {directUrl: directURL} = fileInfo;
      try {
        // eslint-disable-next-line wandb/no-unprefixed-urls
        const fetchFileResponse = await fetch(directURL);
        const blob = await fetchFileResponse.blob();
        const objectURL = URL.createObjectURL(blob);
        return {
          step,
          historyRow,
          blob,
          objectURL,
          filePath,
          directURL,
        };
      } catch (err) {
        console.error(`ERROR FETCHING MEDIA FILE: ${err}`);
        return null;
      }
    }
  }
}

const tileMediaSpecKeySeparator = '__WBTileMediaSpecKeySeparator__';

function getKeyForTileMediaSpec({
  mediaType,
  runSignature,
  mediaKey,
  mediaStep,
  mediaIndex,
}: TileMediaSpec): string {
  const runSignatureKey = getKeyForRunSignature(runSignature);
  return [mediaType, runSignatureKey, mediaKey, mediaStep, mediaIndex].join(
    tileMediaSpecKeySeparator
  );
}

const runSignatureKeySeparator = '__WBTileMediaSpecKeySeparator__';

function getKeyForRunSignature({
  entityName,
  projectName,
  runName,
}: RunSignature): string {
  return [entityName, projectName, runName].join(runSignatureKeySeparator);
}

type GetFilePathFn = (p: GetFilePathParams) => string;

type GetFilePathParams = TileMediaSpec & {
  historyRow: RunHistoryRow;
};

const getFilePathFnByMediaType: {[t in MediaCardType]: GetFilePathFn} = {
  audio: getAudioFilePath,
  image: getImageFilePath,
  video: getVideoFilePath,
  table: getTableFilePath,
  plotly: getPlotlyFilePath,
  html: getHTMLFilePath,
  object3D: getObject3DFilePath,
  bokeh: getBokehFilePath,
  molecule: getMoleculeFilePath,
};

function getMediaFilePath(p: GetFilePathParams): string {
  const filePath = getFilePathFnByMediaType[p.mediaType](p);
  return normalizeMediaFilepath(filePath);
}

function getAudioFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  const stepMedia = getAudioMedia({historyRow, mediaKey}, mediaIndex);
  return (
    stepMedia?.path ??
    mediaFilePath([mediaKey, historyRow._step, mediaIndex], 'audio')
  );
}

function getImageFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
  runSignature,
}: GetFilePathParams): string {
  const stepMedia = getImageMedia({historyRow, mediaKey});
  const {imgFile: filePath} = getImageInfo({
    imgMedia: stepMedia as ImageMetadata | null,
    step: historyRow._step,
    mediaIndex,
    mediaKey,
    runSignature,
  });
  return filePath;
}

function getVideoFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  const stepMedia = getVideoMedia({historyRow, mediaKey}, mediaIndex);
  return (
    stepMedia?.path ??
    mediaFilePath([mediaKey, historyRow._step, mediaIndex], 'videos')
  );
}

function getTableFilePath({historyRow, mediaKey}: GetFilePathParams): string {
  return getTableMediaPath({historyRow, mediaKey}) ?? '';
}

function getPlotlyFilePath({historyRow, mediaKey}: GetFilePathParams): string {
  return getPlotlyMediaPath({historyRow, mediaKey}) ?? '';
}

function getHTMLFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  const stepMedia = getHTMLMedia({historyRow, mediaKey}, mediaIndex);
  return (
    stepMedia?.path ??
    mediaFilePath([mediaKey, historyRow._step, mediaIndex], 'html')
  );
}

function getObject3DFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  return getObject3DMediaPath({historyRow, mediaKey}, mediaIndex) ?? '';
}

function getBokehFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  return getBokehMediaPath({historyRow, mediaKey}, mediaIndex) ?? '';
}

function getMoleculeFilePath({
  historyRow,
  mediaKey,
  mediaIndex,
}: GetFilePathParams): string {
  return getMoleculeMediaPath({historyRow, mediaKey}, mediaIndex) ?? '';
}
