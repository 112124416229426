import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {AccessOptions} from '../../util/permissions';

import {useQuery, UseQueryOptions} from './query';

export const SWEEP_PAGE_QUERY = gql`
  query SweepPage(
    $projectName: String!
    $entityName: String!
    $sweepName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      access
      readOnly
      sweep(sweepName: $sweepName) {
        id
        name
        displayName
        runCount
        runTime
        method
        state
        description
        user {
          id
          username
          photoUrl
        }
        config
        createdAt
        heartbeatAt
        updatedAt
        # earlyStopJobRunning
        controllerRunName
        priorRuns {
          edges {
            node {
              name
            }
          }
        }
        agents {
          edges {
            node {
              id
              name
              state
              host
              persistent
              user {
                id
              }
              metrics
              createdAt
              heartbeatAt
              totalRuns
            }
          }
        }
      }
      entity {
        id
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
      }
    }
  }
`;

export interface SweepData {
  id: string;
  name: string;
  displayName: string;
  state: string;
  createdAt: string;
  config: string;
  runCount: number;
  runTime: number;
  user: {
    id: string;
    username: string;
    photoUrl: string;
  };
}

type DetailedSweepData = SweepData & {
  description: string;
  method: string;
  updatedAt: string;
  heartbeatAt: string;
  controllerRunName: string;
  priorRuns: {
    edges: Array<{
      node: {name: string};
    }>;
  };
  agents: {
    edges: Array<{
      node: AgentData;
    }>;
  };
};

export interface AgentData {
  id: string;
  name: string;
  state: string;
  host?: string;
  heartbeatAt: string;
  totalRuns: number;
}

// The query's output shape.
export interface SweepPageQueryData {
  project: {
    id: string;
    access: AccessOptions;
    readOnly: boolean;
    sweep: DetailedSweepData;
    entity: {
      id: string;
      defaultAccess: AccessOptions;
      readOnlyAdmin: boolean;
      privateOnly: boolean;
      isTeam: boolean;
    };
  } | null;
}

export function useSweepPageQuery(
  variables: Generated.SweepPageQueryVariables,
  options: UseQueryOptions
) {
  return useQuery<SweepPageQueryData, typeof variables>(SWEEP_PAGE_QUERY, {
    ...options,
    variables,
  });
}
