import styled, {css} from 'styled-components';

export const EmbeddedIframeContainer = styled.div<{
  selected: boolean;
}>`
  display: block;
  margin: 0 auto;
  width: min(max(30vw, 240px), 500px);
  height: min(max(30vw, 240px), 500px);
  iframe {
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;
