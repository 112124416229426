import * as globals from '../../../css/globals.styles';

import styles from 'styled-components';
import {REPORT_FONT_SIZE} from '../constants';

export const CalloutBlock = styles.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    background: ${globals.gray200};
    padding: 16px;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
`;

// full width lets editor to click the space and focus on text
export const CalloutLine = styles.div`
    padding: 4px;
    overflow-x: auto;
    width: 100%;
`;

export const CalloutEmoji = styles.div`
    padding: 4px 0px;
    font-size: 20px;
`;
