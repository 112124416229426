import React, {useCallback, useState} from 'react';
import {ReportViewRef} from '../state/reports/types';
import CloneReportModal from './CloneReportModal';
import makeComp from '../util/profiler';

interface CloneReportTriggerProps {
  viewRef: ReportViewRef;
  className?: string;
}

const CloneReportTrigger: React.FC<CloneReportTriggerProps> = makeComp(
  ({viewRef, className, children}) => {
    const [cloneReportModalOpen, setCloneReportModalOpen] = useState(false);
    const openModal = useCallback(() => setCloneReportModalOpen(true), []);
    const closeModal = useCallback(() => setCloneReportModalOpen(false), []);

    return (
      <>
        <div
          className={className}
          style={className ? undefined : {display: 'contents'}}
          onClick={openModal}>
          {children}
        </div>
        {cloneReportModalOpen && (
          <CloneReportModal viewRef={viewRef} onClose={closeModal} />
        )}
      </>
    );
  },
  {id: 'CloneReportTrigger'}
);

export default CloneReportTrigger;
