import styled from 'styled-components';
import {GLOBAL_COLORS} from '../../util/colors';
import WBQueryMenu from './WBQueryMenu';
import {WBAnchoredPopup} from '@wandb/ui';

export const SuggestionMenuPopup = styled(WBAnchoredPopup)`
  border-radius: 4px;
  transform: translateY(2px);
`;

export const SuggestionMenu = styled(WBQueryMenu)``;

export const Option = styled.div<{hovered?: boolean}>`
  color: white;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background: ${props =>
    props.hovered ? GLOBAL_COLORS.primary.toString() : 'none'};
`;
