import * as React from 'react';
import {Checkbox, Dropdown, Header, Loader} from 'semantic-ui-react';
import ImagePreview, {ImagePreviewConfig} from '../components/ImagePreview';
import {backendHost} from '../config';
import {
  RunsData,
  RunsDataQuery,
  toRunsDataQuery,
} from '../containers/RunsDataLoader';
import '../css/PanelDataFrames.less';
import * as DataFrameQuery from '../graphql/dataFrameQuery';
import * as Filters from '../util/filters';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import {Config as TableConfig} from '../util/runfeed';
import * as Run from '../util/runs';
import * as TableCols from './legacy/DataFrames/dataframesTablecols';
import {
  queryInputTransform,
  queryOutputTransform,
  querySettingsTransform,
  sortKeyToString,
  sortStringToKey,
} from './legacy/DataFrames/dataframesUtil';
import produce from 'immer';
import {
  DFTableProps,
  DFTable,
  DFTableColumn,
} from './legacy/DataFrames/DFTable';
import {DFTableWithQuery} from './legacy/DataFrames/DFTableQuery';
import {DFTableActionsAutoCols} from './legacy/DataFrames/DFTableActionsAutoCols';
import {DataFrameFiltersTableAction} from './legacy/DataFrames/DataFrameFilters';
import {DataFrameSortTableAction} from './legacy/DataFrames/DataFrameSort';
import {DataFrameGroupTableAction} from './legacy/DataFrames/DataFrameGroup';
import {makeDataFrameSortIndicator} from './legacy/DataFrames/DataFrameSortIndicator';

function entityName(pageQuery: any): string {
  return (pageQuery && pageQuery.entityName) || '';
}

function projectName(pageQuery: any): string {
  return (pageQuery && (pageQuery.projectName || pageQuery.model)) || '';
}

function imageSrc(
  pageQuery: any,
  runName: string,
  image: any
): string | undefined {
  if (!image || !image.path) {
    return undefined;
  }
  return `${backendHost()}/files/${entityName(pageQuery)}/${projectName(
    pageQuery
  )}/${runName}/${image.path}`;
}

const PANEL_TYPE = 'Data Frame Table';

function availableKeys(data: RunsData): string[] {
  let available: string[] = [];

  if (!data.loading && data.filtered.length > 0 && data.filtered[0].summary) {
    available = Object.keys(data.filtered[0].summary).filter(
      k =>
        data.filtered[0].summary[k] &&
        (data.filtered[0].summary[k] as any)._type === 'data-frame'
    );
  }

  return available;
}

export interface DataFramesConfig {
  dataFrameKey?: string;
  tableSettings?: TableConfig;
  groupKeys?: string[];
  sort?: string;
  filters?: Filters.RootFilter;
  showPreview?: boolean;
  previews?: ImagePreviewConfig[];
  // old preview setting
  previewColumns?: string[];
}

function configReady(config: DataFramesConfig): boolean {
  return !!config.dataFrameKey;
}

function transformRows(rows: any[], f: (row: any) => any): any[] {
  return rows.map(row => {
    row = f(row);
    if (row.__children__) {
      row = {...row, __children__: transformRows(row.__children__, f)};
    }
    return row;
  });
}

function findRow(rows: any[], address: string): any {
  for (const row of rows) {
    if (row.__address__ === address) {
      return row;
    }
    if (row.__children__ && address.startsWith(row.__address__ + '-')) {
      return findRow(row.__children__, address);
    }
  }
  return undefined;
}

function isValueImage(v: any): boolean {
  return v && (v._type === 'image' || v._type === 'image-file');
}

type DataFramesPanelProps = Panels.PanelProps<DataFramesConfig>;

type DataFramesPanelInnerProps = DataFramesPanelProps & {
  tableProps: DFTableProps;
  selectedRow?: string;
  runName: string;

  updateConfig(config: Partial<DataFramesConfig>): void;
};

class DataFramesPanelInner extends React.Component<DataFramesPanelInnerProps> {
  static type = PANEL_TYPE;
  static options = {};

  static transformQuery(
    query: Query.Query,
    _: DataFramesConfig
  ): RunsDataQuery {
    const result = toRunsDataQuery(
      query,
      {selectionsAsFilters: true},
      {fullSummary: true}
    );
    return result;
  }

  tableRef = React.createRef<DFTable>();

  previewOptions(current: string[]) {
    const {tableProps} = this.props;
    if (tableProps.loading || tableProps.rows.length === 0) {
      return current;
    }

    // TODO: WTF
    const firstRow = (tableProps.rows[0] as any).summary;
    return tableProps.columns
      .filter((c: DFTableColumn) => isValueImage(firstRow[c.key.name]))
      .map((c: DFTableColumn) => c.key.name);
  }

  previewConfigs(): ImagePreviewConfig[] {
    const {config} = this.props;

    // Use configured preview columns if we have them
    if (config.previews) {
      return config.previews;
    }

    // convert old preview columns to previews if we have them
    if (config.previewColumns) {
      return config.previewColumns.map(c => ({imageColumn: c}));
    }

    // If everything is blank, default to all image columns
    return this.previewOptions([]).map(c => ({imageColumn: c}));
  }

  renderConfig() {
    const {config, data, tableProps, updateConfig} = this.props;
    const keyOptions = availableKeys(data);

    return (
      <>
        <div className="panel-data-frames-config">
          <Header as="h3">Configure Data Viewer</Header>
          <Header as="h4">
            Use the data viewer to explore logged examples, compare results from
            runs, and understand difficult test cases.
          </Header>
          <label>Data Frame</label>
          <Dropdown
            placeholder={'Select Data Frame'}
            fluid
            selection
            options={keyOptions.map(k => ({
              key: k,
              text: k,
              value: k,
            }))}
            value={config.dataFrameKey}
            onChange={(_, {value}) =>
              updateConfig({dataFrameKey: value as string})
            }
          />
          <Checkbox
            label="Show Semantic Segmantation Preview"
            checked={config.showPreview}
            toggle
            onChange={(_, {checked}) => updateConfig({showPreview: checked})}
          />
        </div>
        {config.showPreview && tableProps.rows.length > 0 && (
          <div className="panel-data-frames--preview config">
            {this.renderPreview(tableProps.rows[0], true)}
          </div>
        )}
      </>
    );
  }

  renderNormal() {
    const {config, configMode, data, tableProps, selectedRow} = this.props;
    if (data.initialLoading) {
      return (
        <div className="panel-data-frames-loader">
          <Loader active />
        </div>
      );
    }
    if (!configReady(config)) {
      return <p>NOT READY</p>;
    }

    // This is a dummy value, if we don't have any valid sort options, we disable the
    // button entirely.
    let defaultSort: Query.Sort = {
      keys: [
        {
          key: {section: 'summary', name: 'wandb_example_id'},
          ascending: false,
        },
      ],
    };
    const sortableColumns = tableProps.columns.filter(
      c => c.key.name !== '__preview__'
    );
    let sortEnabled = false;
    const firstColumn = sortableColumns[0];
    if (firstColumn) {
      sortEnabled = true;
      defaultSort = {
        keys: [
          {
            key: firstColumn.key,
            ascending: true,
          },
        ],
      };
    }

    const transformedRows = transformRows(tableProps.rows, row => ({
      ...row,
      summary: {
        ...row.summary,
        __preview__: row.__address__ === selectedRow,
      },
    }));

    const previewRow = selectedRow && findRow(tableProps.rows, selectedRow);

    let filters = config.filters || Filters.EMPTY_FILTERS;
    if (!Filters.isRootFilter(filters)) {
      filters = Filters.EMPTY_FILTERS;
    }
    const sort = config.sort ? sortStringToKey(config.sort) : defaultSort;

    const grouping = config.groupKeys || [];

    return (
      <div className={'panel-data-frames' + (configMode ? ' config' : '')}>
        {config.showPreview !== false && (
          <div className="panel-data-frames--preview">
            {this.renderPreview(previewRow)}
          </div>
        )}
        <div className="panel-data-frames--table">
          <DFTable
            ref={this.tableRef}
            {...tableProps}
            SortIndicatorComponent={makeDataFrameSortIndicator({
              sort,
            })}
            extraTableActionsLeft={
              <>
                <DataFrameFiltersTableAction
                  filters={filters}
                  filterableKeys={tableProps.columns
                    .filter(c => c.key.name !== '__preview__')
                    .map(c => c.key.name)}
                  setFilters={newFilters => {
                    this.props.updateConfig({
                      filters: newFilters,
                    });
                    if (this.tableRef.current) {
                      this.tableRef.current.resetPagination();
                    }
                  }}
                />
                <DataFrameGroupTableAction
                  grouping={grouping.map(
                    k => ({section: 'summary', name: k} as Run.Key)
                  )}
                  groupableKeys={tableProps.columns.map(c => c.key.name)}
                  loading={false}
                  setGrouping={newGrouping => {
                    this.props.updateConfig({
                      groupKeys: newGrouping.map(k => k.name),
                    });
                    if (this.tableRef.current) {
                      this.tableRef.current.resetPagination();
                    }
                    const tableSettings = config.tableSettings;
                    if (tableSettings) {
                      newGrouping.forEach(groupKey => {
                        const columnAccessor = Run.keyToString(groupKey);
                        if (
                          columnAccessor &&
                          !TableCols.isPinned(tableSettings, columnAccessor)
                        ) {
                          this.props.updateConfig({
                            tableSettings: TableCols.togglePinned(
                              tableSettings,
                              columnAccessor
                            ),
                          });
                        }
                      });
                    }
                  }}
                />
                <DataFrameSortTableAction
                  disabled={!sortEnabled}
                  defaultSort={defaultSort}
                  sort={sort}
                  loading={false}
                  sortableKeys={tableProps.columns
                    .filter(c => c.key.name !== '__preview__')
                    .map(c => c.key.name)}
                  setSort={newSort => {
                    this.props.updateConfig({
                      sort: sortKeyToString(newSort),
                    });
                    if (this.tableRef.current) {
                      this.tableRef.current.resetPagination();
                    }
                  }}
                />
              </>
            }
            extraTableActionsRight={
              <DFTableActionsAutoCols
                tableSettings={tableProps.tableSettings}
                rows={transformedRows}
                setTableSettings={tableProps.setTableSettings}
              />
            }
            rows={transformedRows}
            isColumnSortable={c => c.key.name !== '__preview__'}
          />
        </div>
      </div>
    );
  }

  renderPreview(previewRow: any, configMode: boolean = false) {
    const {pageQuery, selectedRow, runName, updateConfig} = this.props;
    const previewConfigs = this.previewConfigs();

    if (!previewRow) {
      return (
        <div className="panel-data-frames--preview-none">
          <Header as="h3">Select a row to preview</Header>
        </div>
      );
    }

    return (
      <ImagePreview
        key={selectedRow} // force a re-mount on selecting a new row
        columnOptions={this.previewOptions([])}
        previewConfigs={previewConfigs}
        configMode={configMode}
        columnToImage={column =>
          imageSrc(pageQuery, runName, previewRow.summary[column])
        }
        updatePreviewConfigs={newPreviewConfigs =>
          updateConfig({previews: newPreviewConfigs})
        }
      />
    );
  }

  render() {
    if (this.props.configMode) {
      return <div>{this.renderConfig()}</div>;
    } else {
      return this.renderNormal();
    }
  }
}

interface DataFramesPanelState {
  selectedRow?: string;
}

export default class DataFramesPanel extends React.Component<
  DataFramesPanelProps,
  DataFramesPanelState
> {
  static type = DataFramesPanelInner.type;
  static options = DataFramesPanelInner.options;
  static transformQuery = DataFramesPanelInner.transformQuery;

  state: DataFramesPanelState = {};

  updateConfig = (config: Partial<DataFramesConfig>) => {
    setImmediate(() => {
      this.props.updateConfig(config);
      this.setState({selectedRow: undefined});
    });
  };

  render() {
    const {config, data} = this.props;
    const {selectedRow} = this.state;
    const dataFrameOptions = availableKeys(data);
    const dataFrameKey =
      config.dataFrameKey || (dataFrameOptions && dataFrameOptions[0]);

    const updatedConfig = {...config, dataFrameKey};

    let dataFrameID: string | undefined;
    if (dataFrameKey) {
      const df =
        data.filtered &&
        data.filtered[0] &&
        data.filtered[0].summary &&
        data.filtered[0].summary[dataFrameKey];
      if (df) {
        dataFrameID = (df as any).id as string;
      }
    }

    const limit = (config.tableSettings && config.tableSettings.pageSize) || 10;

    const filters = config.filters || Filters.EMPTY_FILTERS;
    const mergeFilters: Filters.Filter = {
      key: {section: 'run', name: 'wandb_data_frame_id'},
      op: '=',
      value: dataFrameID || '',
    };

    const queryVars: DataFrameQuery.InputProps = {
      entityName: entityName(this.props.pageQuery),
      projectName: projectName(this.props.pageQuery),
      limit,
      dataFrameKeys: [dataFrameKey],
      groupKeys: config.groupKeys || [],
      filters: Filters.And([mergeFilters, filters]),
      disabled: !dataFrameID,
      disableRows: !dataFrameID || !configReady(updatedConfig),
    };

    const grouping = config.groupKeys || [];
    const runName = data.filtered.length > 0 ? data.filtered[0].name : '';

    return (
      <DFTableWithQuery
        query={DataFrameQuery.DATA_FRAME_QUERY}
        tableSettings={config.tableSettings}
        fixedColumns={['summary:__preview__'].concat(
          grouping.map(k => 'summary:' + k)
        )}
        grouping={grouping.map(k => ({section: 'summary', name: k} as Run.Key))}
        filters={filters}
        setFilters={newFilters => this.updateConfig({filters: newFilters})}
        setGrouping={newGrouping => {
          this.updateConfig({groupKeys: newGrouping.map(k => k.name)});
          const tableSettings = config.tableSettings;
          if (tableSettings) {
            newGrouping.forEach(groupKey => {
              const columnAccessor = Run.keyToString(groupKey);
              if (
                columnAccessor &&
                !TableCols.isPinned(tableSettings, columnAccessor)
              ) {
                this.updateConfig({
                  tableSettings: TableCols.togglePinned(
                    tableSettings,
                    columnAccessor
                  ),
                });
              }
            });
          }
        }}
        updateSort={updateFn => {
          const newSort = produce(
            config.sort != null ? sortStringToKey(config.sort) : {keys: []},
            updateFn
          );
          this.updateConfig({sort: sortKeyToString(newSort)});
        }}
        setTableSettings={newSettings =>
          this.updateConfig({tableSettings: newSettings})
        }
        queryVariables={{
          entityName: entityName(this.props.pageQuery),
          projectName: projectName(this.props.pageQuery),
          dataFrameKeys: dataFrameKey ? [dataFrameKey] : [],
          limit,
          order: config.sort,
          groupKeys: grouping,
          filters: Filters.And([mergeFilters, filters]),
          includeRows: !!dataFrameID && configReady(updatedConfig),
          includeSchema: true,
        }}
        queryInputTransform={(queryVariables, recursionDepth, parentRow) =>
          queryInputTransform(
            grouping,
            queryVariables,
            recursionDepth,
            parentRow
          )
        }
        queryOutputTransform={rowData =>
          queryOutputTransform(
            grouping,
            (row: string) => this.setState({selectedRow: row}),
            (image: any) => imageSrc(this.props.pageQuery, runName, image),
            rowData
          )
        }
        querySettingsTransform={querySettingsTransform}
        tableComponent={(tableProps: DFTableProps) => (
          <DataFramesPanelInner
            {...queryVars}
            {...this.props}
            runName={runName}
            selectedRow={selectedRow}
            config={updatedConfig}
            tableProps={tableProps}
            updateConfig={this.updateConfig}
          />
        )}
      />
    );
  }
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, DataFramesConfig> = {
  type: PANEL_TYPE,
  Component: DataFramesPanel,
  transformQuery: DataFramesPanel.transformQuery,
};
