import '../css/EmptyWatermark.less';

import React from 'react';
import {Header} from 'semantic-ui-react';
import classNames from 'classnames';
import makeComp from '../util/profiler';

interface EmptyWatermarkProps {
  className?: string;
  imageSource: string;
  imageSource2x: string;
  header: string;
  details?: string | JSX.Element;
  style?: React.CSSProperties;
  wide?: boolean;
}

/**
 * Reusable placeholder for all places where content is empty
 */
const EmptyWatermark: React.FC<EmptyWatermarkProps> = makeComp(
  ({imageSource, imageSource2x, header, details, wide, style, className}) => {
    return (
      <div
        className={classNames('empty-watermark', className, {wide})}
        style={style}>
        <img
          alt="empty watermark"
          src={imageSource}
          srcSet={imageSource + ' 1x,' + imageSource2x + ' 2x'}
        />
        <div className="empty-watermark-content">
          <Header>{header}</Header>
          {details && <div>{details}</div>}
        </div>
      </div>
    );
  },
  {id: 'EmptyWatermark', memo: true}
);
export default EmptyWatermark;
