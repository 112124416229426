import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';

import * as Graph from '@wandb/cg/browser/graph';
import * as HL from '@wandb/cg/browser/hl';
import * as Op from '@wandb/cg/browser/ops';
import * as Simplify from '@wandb/cg/browser/simplify';
import * as Types from '@wandb/cg/browser/model/types';
import {EditingNode} from '@wandb/cg/browser/types';
import {createLocalClient as createClient, Client} from '@wandb/cg/browser';

import * as ServerApiTest from './cgservers/serverApiTest';
import * as ServerApiProd from './cgservers/serverApiProd';
import {useApolloClient, useDeepMemo} from './state/hooks';
import makeComp from './util/profiler';

interface ClientState {
  client?: Client;
}

const ClientContext = React.createContext<ClientState>({
  client: undefined,
});

export const ComputeGraphContextProvider: React.FC<{test?: boolean}> = makeComp(
  ({test, children}) => {
    const apolloClient = useApolloClient();
    const context = useMemo(
      () => ({
        client: createClient(
          test
            ? new ServerApiTest.Client()
            : new ServerApiProd.Client(apolloClient)
        ),
      }),
      [test, apolloClient]
    );

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const subscription = context.client
        .loadingObservable()
        .subscribe(setIsLoading);
      return () => subscription.unsubscribe();
    }, [context]);

    return (
      <div
        data-test="compute-graph-provider"
        className={isLoading ? 'loading cg-executing' : ''}>
        <ClientContext.Provider value={context}>
          {children}
        </ClientContext.Provider>
      </div>
    );
  },
  {id: 'ComputeGraphContextProvider', memo: true}
);

export const useNodeValue = <T extends Types.Type>(
  node: Types.NodeOrVoidNode<T>
): {loading: boolean; result: Types.TypeToTSTypeInner<T>} => {
  // console.log('USE NODE VALUE', GraphUtil.toString(node));
  // Note this is probably expensive and we do it way too
  // often. TODO: check perf!
  node = useDeepMemo(node);
  const context = useContext(ClientContext);
  const [error, setError] = useState();
  const [result, setResult] = useState<{
    node: Types.NodeOrVoidNode;
    value: any;
  }>({node: Graph.voidNode(), value: undefined});
  const client = context.client;
  if (client == null) {
    throw new Error('client not initialized!');
  }
  useEffect(() => {
    if (!Graph.isVoidNode(node)) {
      const obs = client!.subscribe(node);
      const sub = obs.subscribe(
        nodeRes => {
          setResult({node, value: nodeRes});
        },
        caughtError => {
          setError(caughtError);
        }
      );
      return () => sub.unsubscribe();
    } else {
      return;
    }
  }, [client, node]);

  const finalResult = useMemo(() => {
    // Just rethrow the error in the render thread so it can be caught
    // by an error boundary.
    if (error != null) {
      console.error('useNodeValue error', error);
      throw new Error(error);
    }
    return {
      loading: node !== result.node,
      result: result.value,
    };
  }, [result, node, error]);
  return finalResult;
};

export const useClientBound = <T extends any[], R>(
  fn: (client: Client, ...rest: T) => R
): ((...args: T) => R) => {
  const client = useContext(ClientContext).client;
  if (client == null) {
    throw new Error('CG context not initialized');
  }
  return useCallback((...args: T) => fn(client, ...args), [client, fn]);
};

// Given an array node, return a set of valid nodes, one for
// each item in the array.
// TODO: in the future it would be cool to move this logic down,
//   it doesn't need to depend on react hooks.
export const useEach = (
  node: Types.Node<{type: 'list'; objectType: 'any'}>
) => {
  const countNode = useMemo(() => Op.opCount({arr: node}), [node]);
  const countValue = useNodeValue(countNode);
  const result = useMemo(
    () =>
      _.range(countValue.result).map(i =>
        Op.opIndex({arr: node, index: Op.constNumber(i)})
      ),

    [countValue.result, node]
  );
  const finalResult = useMemo(() => {
    return {
      loading: countValue.loading,
      result,
    };
  }, [countValue.loading, result]);

  return finalResult;
};

export const useSimplifiedNode = (node: Types.Node) => {
  node = useDeepMemo(node);
  const context = useContext(ClientContext);
  const [result, setResult] = useState<
    {loading: true} | {loading: false; result: Types.Node}
  >({loading: true});
  useEffect(() => {
    setResult({loading: true});
    const doSimplify = async () => {
      const simpler = await Simplify.simplify(context.client!, node);
      setResult({loading: false, result: simpler});
    };
    doSimplify();
  }, [context.client, node]);
  return result;
};

export const useNodeWithServerType = (
  node: Types.NodeOrVoidNode,
  frame?: {[argName: string]: Types.Node}
): {loading: boolean; result: Types.NodeOrVoidNode} => {
  const [error, setError] = useState();
  node = useDeepMemo(node);
  if (node.nodeType !== 'output' && node.nodeType !== 'void') {
    throw new Error('invalid');
  }
  const [result, setResult] = useState<{
    node: Types.NodeOrVoidNode;
    value: any;
  }>({node: Graph.voidNode(), value: undefined});
  const context = useContext(ClientContext);
  useEffect(() => {
    let isMounted = true;
    if (node.nodeType !== 'output') {
      return;
    }
    // TODO: This is a race if we have multiple loading in parallel!
    HL.refineNode(context.client!, node, {})
      .then(newNode => {
        if (isMounted) {
          setResult({node, value: newNode});
        }
      })
      .catch(e => setError(e));
    return () => {
      isMounted = false;
    };
  }, [context, node, frame]);
  const finalResult = useMemo(() => {
    if (error != null) {
      // rethrow in render thread
      console.error('useNodeWithServerType error', error);
      throw new Error(error);
    }
    return {
      loading: node !== result.node,
      result: node === result.node ? result.value : node,
    };
  }, [result, node, error]);
  return finalResult;
};

export const useExpandedNode = (
  node: Types.NodeOrVoidNode,
  frame: {[argName: string]: Types.Node}
): {loading: boolean; result: Types.NodeOrVoidNode} => {
  const [error, setError] = useState();
  node = useDeepMemo(node);
  const [result, setResult] = useState<{
    node: Types.NodeOrVoidNode;
    value: any;
  }>({node: Graph.voidNode(), value: undefined});
  const context = useContext(ClientContext);
  useEffect(() => {
    let isMounted = true;
    if (node.nodeType !== 'output') {
      return;
    }
    // TODO: This is a race if we have multiple loading in parallel!
    HL.expandAll(context.client!, node as any, frame)
      .then(newNode => {
        if (isMounted) {
          setResult({node, value: newNode});
        }
      })
      .catch(e => setError(e));
    return () => {
      isMounted = false;
    };
  }, [context, node, frame]);
  const finalResult = useMemo(() => {
    if (error != null) {
      // rethrow in render thread
      console.error('useExpanded error', error);
      throw new Error(error);
    }
    return {
      loading: node.nodeType !== 'output' ? false : node !== result.node,
      result:
        node.nodeType !== 'output'
          ? node
          : node === result.node
          ? result.value
          : node,
    };
  }, [result, node, error]);
  return finalResult;
};
type NodeDebugInfoInputType = {[name: string]: NodeDebugInfoType | null};
type NodeDebugInfoType = {
  node?: Types.NodeOrVoidNode;
  nodeString?: string;
  refinedNode?: Types.NodeOrVoidNode;
  refineError?: any;
  nodeValue?: any;
  valueError?: any;
  inputs?: NodeDebugInfoInputType;
  invalidators?: ReturnType<typeof getInvalidatorNodesWithInfo>;
};

function getInvalidatorNodesWithInfo(node: EditingNode) {
  const invalidators = getInvalidators(node);

  if (!invalidators) {
    return undefined;
  }

  return invalidators.map(invalidatorNode => {
    let invalidatedBy:
      | {
          [inputName: string]: {
            expectedType: Types.Type;
            actualValue: EditingNode;
          };
        }
      | undefined;
    if (invalidatorNode.nodeType === 'output') {
      const opDef = Graph.getOpDef(invalidatorNode.fromOp.name);
      invalidatedBy = Object.fromEntries(
        Object.entries(invalidatorNode.fromOp.inputs)
          .filter(
            ([inputName, value]) =>
              !Types.isAssignableTo(value.type, opDef.inputTypes[inputName])
          )
          .map(([inputName, value]) => [
            inputName,
            {
              expectedType: opDef.inputTypes[inputName],
              actualValue: value,
            },
          ])
      );
    }

    return {
      node: invalidatorNode,
      invalidatedBy,
    };
  });
}
function getInvalidators(node: EditingNode): null | EditingNode[] {
  if (node.type !== 'invalid') {
    return null;
  }

  if (HL.isFunctionLiteral(node)) {
    return getInvalidators(node.val);
  }
  if (node.nodeType !== 'output') {
    return [node];
  }

  const invalidInputs = Object.values(node.fromOp.inputs).filter(
    input => input.type === 'invalid'
  );

  if (invalidInputs.length === 0) {
    // if we have no invalid inputs, then this is an invalidating node
    return [node];
  }

  return _.compact(invalidInputs.flatMap(getInvalidators));
}

async function makeDebugNode(
  context: ClientState,
  node: Types.NodeOrVoidNode
): Promise<NodeDebugInfoType> {
  const client = context.client;
  const result = {
    node,
    nodeString: HL.toString(node),
  };

  if (client == null) {
    throw new Error('client not initialized!');
  }
  if (node.nodeType === 'void') {
    return Promise.resolve(result);
  } else {
    return new Promise(async resolve => {
      HL.refineNode(client, node, {})
        .then(async refinedNode => {
          const invalidators = getInvalidatorNodesWithInfo(refinedNode);
          // From Shawn: I removed strip tags = false here. Sorry,
          // I want to make sure we never rely on strip tags in production
          // code, so the client doesn't even allow it anymore. To bring
          // it back, I think it'd be ok to have a client._queryDebug that
          // does it... this would need to be routed through to the server.
          const nodeValue = await client.query(node);
          if (node.nodeType === 'output') {
            const keys = _.keys(node.fromOp.inputs);
            const inputNodes = await Promise.all(
              keys.map(key => makeDebugNode(context, node.fromOp.inputs[key]))
            );
            const inputs = _.fromPairs(
              keys.map((key, ndx) => [key, inputNodes[ndx]])
            );

            resolve({
              ...result,
              refinedNode,
              nodeValue,
              inputs,
              invalidators,
            });
          } else {
            resolve({
              ...result,
              refinedNode,
              nodeValue,
              invalidators,
            });
          }
        })
        .catch(refineError => {
          resolve({
            ...result,
            refineError,
          });
        });
    });
  }
}

// Warning: Only use for debugging - costly and inefficient.
export function useNodeDebugInfo(node: Types.NodeOrVoidNode): {
  loading: boolean;
  result: NodeDebugInfoType | null;
} {
  const context = useContext(ClientContext);
  const [result, setResult] = useState<NodeDebugInfoType | null>();
  node = useDeepMemo(node);

  useEffect(() => {
    makeDebugNode(context, node).then(setResult);
  }, [context, node, setResult]);

  return useMemo(() => {
    if (result == null) {
      return {loading: true, result: null};
    } else {
      return {
        loading: false,
        result,
      };
    }
  }, [result]);
}
