import React from 'react';
import styled from 'styled-components';
import {PropertyEditorComponent} from './property-editors';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {GLOBAL_COLORS} from '../../util/colors';
import classNames from 'classnames';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

const OptionIcon = styled(LegacyWBIcon)`
  &:hover,
  &.active {
    color: ${GLOBAL_COLORS.linkBlue.toString()};
  }
`;

/**
 * Compact way to offer a small list of options.
 * Default is required.
 */
const IconMenuPropertyEditor: PropertyEditorComponent<'icon-menu'> = makeComp(
  props => {
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        {props.options.map(option => (
          <OptionIcon
            onClick={() => {
              props.save(option.value);
            }}
            className={classNames({
              active: props.values.indexOf(option.value) !== -1,
            })}
            name={option.icon}></OptionIcon>
        ))}
      </InspectorPropertyWrapper>
    );
  },
  {id: 'IconMenuPropertyEditor'}
);

export default IconMenuPropertyEditor;
