import * as S from './DFTableActionsSort.styles';

import React, {ReactNode} from 'react';
import _ from 'lodash';
import produce from 'immer';
import {Button, Popup} from 'semantic-ui-react';

import * as Query from '../../../util/queryts';
import * as Run from '../../../util/runs';
import * as UIHelpers from '../../../util/uihelpers';
import ModifiedDropdown from '../../elements/ModifiedDropdown';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import {DEFAULT_RUNS_SORT, DEFAULT_RUNS_SORT_KEY} from '../../../util/queryts';
import makeComp from '../../../util/profiler';

interface DFTableActionSortButtonProps {
  compact?: boolean;
  disabled?: boolean;
  sortOpen: boolean;
  defaultSort?: Query.Sort;
  sort: Query.Sort;
}

export const DFTableActionSortButton = makeComp(
  (props: DFTableActionSortButtonProps) => {
    let sortButtonClass;

    const passThroughProps = _.omit(
      props,
      'compact',
      'sortOpen',
      'defaultSort',
      'sort'
    );

    if (props.sortOpen) {
      sortButtonClass = 'action-button--focused';
    } else if (
      !_.isEqual(
        props.sort.keys,
        props.defaultSort?.keys ?? DEFAULT_RUNS_SORT.keys
      )
    ) {
      sortButtonClass = 'action-button--active';
    } else {
      sortButtonClass = 'action-button--static';
    }

    const buttonText = 'Sort';
    return (
      <Button
        {...passThroughProps} // Required for use as popup trigger
        disabled={props.disabled}
        data-test="sort-popup"
        size="tiny"
        className={sortButtonClass + ' wb-icon-button table-sort-button'}>
        <LegacyWBIcon name="sort" title={buttonText} />
        {props.compact ? '' : buttonText}
      </Button>
    );
  },
  {id: 'DFTableActionSortButton'}
);

interface DFTableActionSortDropdownProps {
  sortableKeys: string[];
  loading: boolean;
  sort: Query.Sort;
  sortKeyIdx: number;

  setSort(sort: Query.Sort): void;
}

export const DFTableActionSortDropdown = makeComp(
  (props: DFTableActionSortDropdownProps) => {
    const {sortableKeys, loading, sort, sortKeyIdx, setSort} = props;
    const sortKey = sort.keys[sortKeyIdx] ?? DEFAULT_RUNS_SORT_KEY;
    const options = UIHelpers.makeOptions(sortableKeys);
    return (
      <ModifiedDropdown
        data-test="sort-dropdown"
        className="sort"
        placeholder="Sort by..."
        search={UIHelpers.searchFunction}
        selection
        lazyLoad
        optionTransform={UIHelpers.beautify}
        itemLimit={100}
        options={options}
        value={Run.keyToString(sortKey.key)}
        disabled={loading}
        loading={loading}
        onChange={(e, {value}) => {
          // We know it's string because we pass in options
          const typedValue: string = value as string;
          const key = Run.keyFromString(typedValue);
          if (key != null) {
            setSort(
              produce(sort, draft => {
                draft.keys[sortKeyIdx].key = key;
              })
            );
          }
        }}
      />
    );
  },
  {id: 'DFTableActionSortDropdown'}
);

interface DFTableActionSortPickerProps {
  defaultSort?: Query.Sort; // the default sort
  sort: Query.Sort; // the current sort

  makeDropdown(sortKeyIdx: number): JSX.Element;
  setSort(sort: Query.Sort): void;
}

export const DFTableActionSortPicker = makeComp(
  (props: DFTableActionSortPickerProps) => {
    const {defaultSort, sort, makeDropdown, setSort} = props;
    return (
      <>
        {sort.keys.map(({key, ascending}, i) => (
          <S.SortPickerRow key={i}>
            <S.SortPickerRowClose
              hidden={sort.keys.length <= 1}
              onClick={() => {
                if (sort.keys.length <= 1) {
                  return;
                }
                setSort(
                  produce(sort, draft => {
                    draft.keys.splice(i, 1);
                  })
                );
              }}
            />
            {makeDropdown(i)}
            <S.SortPickerButton
              data-test="sort-order"
              icon={`wbic-ic-${ascending ? 'up' : 'down'}-arrow`}
              onClick={() => {
                setSort(
                  produce(sort, draft => {
                    draft.keys[i].ascending = !draft.keys[i].ascending;
                  })
                );
              }}
            />
          </S.SortPickerRow>
        ))}
        <S.SortPickerActions>
          <S.SortPickerAction
            data-test="sort-add"
            className="fake-link"
            onClick={() => {
              setSort(
                produce(sort, draft => {
                  draft.keys.push(
                    defaultSort?.keys?.[0] ?? DEFAULT_RUNS_SORT_KEY
                  );
                })
              );
            }}>
            Add another field
          </S.SortPickerAction>
          <S.SortPickerAction
            data-test="sort-reset"
            className="fake-link"
            onClick={() => setSort(defaultSort ?? DEFAULT_RUNS_SORT)}>
            Reset to default
          </S.SortPickerAction>
        </S.SortPickerActions>
      </>
    );
  },
  {id: 'DFTableActionSortPicker'}
);

interface DFTableActionSortProps {
  trigger: (isOpen: boolean) => ReactNode;
  content: ReactNode;
  open: boolean;
  onOpen(): void;
  onClose(): void;
}

export const DFTableActionSort = makeComp(
  (props: DFTableActionSortProps) => {
    return (
      <Popup
        basic
        className="df-table-action-popup"
        on="click"
        position="bottom left"
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        trigger={props.trigger(props.open)}
        content={props.content}
        popperModifiers={{
          preventOverflow: {enabled: false},
          flip: {enabled: false},
        }}
      />
    );
  },
  {id: 'DFTableActionSort'}
);
