import React, {useContext} from 'react';

interface ShiftPressedContextValue {
  shiftPressed: boolean;
  setShiftPressed(shiftPressed: boolean): void;
}

export const ShiftPressedContext =
  React.createContext<ShiftPressedContextValue>({} as any);

export const useShiftPressed = () => {
  return useContext(ShiftPressedContext);
};
