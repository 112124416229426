import {ActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';
import * as Types from './types';

function workspaces(draft: Types.StateType, action: Actions.ActionType) {
  switch (action.type) {
    case getType(Actions.initStart): {
      const {
        id,
        viewListID,
        viewType,
        defaultSpec,
        userWorkspaceName,
        defaultWorkspaceName,
      } = action.payload;
      draft[id] = {
        viewListID,
        viewType,
        defaultSpec,
        userWorkspaceName,
        defaultWorkspaceName,
      };
      break;
    }
    case getType(Actions.unloadWorkspace): {
      const {id} = action.payload;
      delete draft[id];
      break;
    }
    case getType(Actions.viewLoadStart): {
      const {id} = action.payload;
      draft[id].viewRef = undefined;
      break;
    }
    case getType(Actions.viewReady): {
      const {id} = action.payload;
      const view = draft[id];
      if (view != null) {
        view.viewRef = action.payload.viewRef;
      }
      break;
    }
  }
}

export default immerReducer<Types.StateType, ActionType<typeof Actions>>(
  workspaces,
  {}
);
