import React from 'react';
import * as RunHelpers from '../../util/runhelpers';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';

const inputType = 'id' as const;
type PanelIdProps = Panel2.PanelProps<typeof inputType>;

export const PanelId: React.FC<PanelIdProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  return <div>{RunHelpers.displayValue(nodeValueQuery.result)}</div>;
};

export const Spec: Panel2.PanelSpec = {
  id: 'id',
  Component: PanelId,
  inputType,
};
