import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';
import {Button, Input} from 'semantic-ui-react';

import LegacyWBIcon from './elements/LegacyWBIcon';
import {LinePlotPanel, PanelSettingsComponent} from './PanelSettings';
import * as ViewHooks from '../state/views/hooks';
import * as PanelSettingsTypes from '../state/views/panelSettings/types';
import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import * as PanelRunsLinePlot from './PanelRunsLinePlot';
import CreateReportModal from './CreateReportModal';
import {RunHistoryKeyInfo} from '../types/run';
import {useProjectPageQuery} from '../generated/graphql';
import {PersistentFlasher} from './elements/PersistentFlasher';
import {
  CREATE_REPORT_FLASHER_FEATURE_KEY,
  useOnboardingContext,
} from '../util/userContext';
import {useViewer} from '../state/viewer/hooks';
import {
  PanelBankContext,
  PanelBankUpdaterContext,
} from '../state/panelbank/context';
import {getFlowSectionsWithVisiblePanels} from '../util/panelbankFlow';
import makeComp from '../util/profiler';
import {isInJupyterNotebook} from '../setup';
import {panelIsActive, searchRegexFromQuery} from '../util/panelbank';
import * as RunHelpers from '../util/runhelpers';
import {LayedOutPanel} from '../util/panels';
import {PanelBankSectionConfig} from '../state/views/panelBankSectionConfig/types';
import PanelBankControlsModal from './PanelBankControlsModal';
import {PanelBankConfigWithRefs} from './PanelBank';

interface PanelBankControlsProps {
  entityName: string;
  projectName: string;
  readOnly?: boolean;
  panelSettingsRef: PanelSettingsTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  searchQuery: string;
  debouncedSearchQuery: string;
  singleRun?: boolean;
  historyKeyInfo: RunHistoryKeyInfo;
  panelBankWidth: number;
  workspaceID?: string;
  setSearchQuery(searchQuery: string): void;
  resetAllLocals(
    localSettings: PanelSettingsTypes.PanelSettings,
    workspaceSettings: PanelSettingsTypes.PanelSettings
  ): void;
}

// This is the bar at the top of the PanelBank that contains search and settings
const PanelBankControls = makeComp(
  (props: PanelBankControlsProps) => {
    const {
      entityName,
      projectName,
      readOnly,
      searchQuery,
      debouncedSearchQuery,
      panelBankConfigRef,
      panelBankConfigWithRefs,
      panelSettingsRef,
      runSetRefs,
      customRunColorsRef,
      setSearchQuery,
      singleRun,
      historyKeyInfo,
      panelBankWidth,
      workspaceID,
      resetAllLocals,
    } = props;

    const viewer = useViewer();

    const inputRef = useRef<Input>(null);
    useEffect(() => {
      if (inputRef && inputRef.current) {
        // Not sure why this blur and focus is necessary
        // But, without it, you can't type in the input even though it seems to maintain focus
        const innerInputRef = (inputRef.current as any).inputRef.current;
        innerInputRef.blur();
        innerInputRef.focus();
      }
    }, [searchQuery, debouncedSearchQuery]);

    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const updatePanelBankSettings = ViewHooks.useViewAction(
      panelBankConfigRef,
      PanelBankConfigActions.updateSettings
    );

    const {currentPageBySectionRefID} = useContext(PanelBankContext);
    const {setExportingUnknownID} = useContext(PanelBankUpdaterContext);
    const setExporting = useCallback(
      () => setExportingUnknownID(true),
      [setExportingUnknownID]
    );

    const useExportableSections = () => {
      const {sections} = ViewHooks.useWhole(panelBankConfigRef);
      return useMemo(
        () =>
          getFlowSectionsWithVisiblePanels({
            sections,
            currentPageBySectionRefID,
            singleRun,
            searchQuery,
            historyKeyInfo,
            panelBankWidth,
          }),
        [sections]
      );
    };

    const useAllLinePlotPanels = () => {
      const {sections} = ViewHooks.useWhole(panelBankConfigRef);
      return useMemo(
        () =>
          _.flatten(
            sections.map(s =>
              s.panels.filter(p => p.viewType === PanelRunsLinePlot.PANEL_TYPE)
            )
          ) as unknown as LinePlotPanel[],
        [sections]
      );
    };

    const keyTypes = RunHelpers.useKeyTypes(historyKeyInfo);
    const searchRegex = searchRegexFromQuery(searchQuery);
    const usePanelIsActive = useCallback(
      (section: PanelBankSectionConfig, panel: LayedOutPanel) =>
        panelIsActive({
          section,
          panel,
          singleRun,
          searchRegex,
          historyKeyInfo,
          keyTypes,
        }),
      [singleRun, searchRegex, historyKeyInfo, keyTypes]
    );

    return (
      <div className="panel-bank__actions-wrapper">
        <div className="panel-bank__actions">
          <Input
            ref={inputRef}
            className="panel-bank__search"
            icon={<LegacyWBIcon name="search"></LegacyWBIcon>}
            tabIndex={0}
            iconPosition="left"
            value={searchQuery}
            placeholder="Search panels"
            onChange={(e, {value}) => {
              setSearchQuery(value);
            }}
          />
          <PanelSettingsComponent
            runSetRefs={runSetRefs}
            workspacePanelSettingsRef={panelSettingsRef}
            useAllLinePlotPanels={useAllLinePlotPanels}
            resetAllLocals={resetAllLocals}
          />
          {workspaceID != null && (
            <Button
              size="tiny"
              className={'wb-icon-button only-icon'}
              onClick={() => setIsModalOpen(true)}>
              <LegacyWBIcon name="configuration" />
            </Button>
          )}
          {workspaceID != null && (
            <PanelBankControlsModal
              panelBankConfigRef={panelBankConfigRef}
              panelBankConfigWithRefs={panelBankConfigWithRefs}
              workspaceID={workspaceID}
              isModalOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
              }}
              updatePanelBankSettings={updatePanelBankSettings}
            />
          )}
          {!readOnly && viewer != null && (
            <>
              {!isInJupyterNotebook() && (
                <CreateReportButton
                  disabled={false}
                  entityName={entityName}
                  projectName={projectName}
                  onClick={setExporting}
                />
              )}
              <CreateReportModal
                useExportableSections={useExportableSections}
                panelSettingsRef={panelSettingsRef}
                runSetRefs={runSetRefs}
                customRunColorsRef={customRunColorsRef}
                panelIsActive={usePanelIsActive}
              />
            </>
          )}
        </div>
      </div>
    );
  },
  {id: 'PanelBankControls'}
);

export default PanelBankControls;

const CreateReportButton: React.FC<{
  disabled: boolean;
  entityName: string;
  projectName: string;
  onClick(): void;
}> = makeComp(
  ({disabled, entityName, projectName, onClick}) => {
    const projectQuery = useProjectPageQuery({
      variables: {
        entityName,
        projectName,
      },
    });
    const runCount = projectQuery.data?.project?.runCount;

    const {onboardingState, setOnboardingState, loading} = useOnboardingContext(
      CREATE_REPORT_FLASHER_FEATURE_KEY
    );

    // Show the flashing blue dot if there are more than 20 runs
    // and the user hasn't yet clicked this button to dismiss createReport onboarding
    const flasherEnabled =
      !loading &&
      !onboardingState.createReportFlasher &&
      runCount != null &&
      runCount > 20;
    const onCreateReportClick = () => {
      onClick();
      setOnboardingState({...onboardingState, createReportFlasher: true});
    };
    return (
      <PersistentFlasher
        visible={flasherEnabled}
        offsetX={-69}
        style={{zIndex: 99}}
        trigger={
          <Button
            className="save-snapshot-button"
            primary
            onClick={onCreateReportClick}
            disabled={disabled}>
            Create report
          </Button>
        }
        popupContent={
          <>
            <div>Quickly take notes and</div>
            <div>share custom views</div>
          </>
        }
      />
    );
  },
  {id: 'CreateReportButton'}
);
