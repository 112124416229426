import '../../css/FancyPopup.less';
import * as globals from '../../css/globals.styles';

import React from 'react';

import {textWidth} from '../../util/text';
import makeComp from '../../util/profiler';
import LegacyWBIcon from '../elements/LegacyWBIcon';

export interface FancyPopupProps {
  open: boolean;
  y: number;
  className?: string;
  text: string;
  unmountWhenClosed?: true;
  closeButton?: true;
  onClose?(): void;
}

const FancyPopup: React.FC<FancyPopupProps> = makeComp(
  props => {
    const POPUP_FONT = `13px "${globals.fontName}"`;
    const TRANSITION_DURATION = 150;
    const allTexts = React.useRef(new Set<string>());
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const lastTimeout = React.useRef<number | null>(null);

    allTexts.current.add(props.text);

    if (lastTimeout.current) {
      window.clearTimeout(lastTimeout.current);
    }

    if (props.open && innerRef.current) {
      lastTimeout.current = window.setTimeout(() => {
        if (innerRef.current) {
          innerRef.current.style.transitionProperty =
            'top, width, left, opacity, background';
        }
      }, 10);
    }

    if (!props.open && innerRef.current) {
      lastTimeout.current = window.setTimeout(() => {
        if (innerRef.current) {
          innerRef.current.style.transitionProperty =
            'width, left, opacity, background';
        }
      }, TRANSITION_DURATION);
    }

    const style: React.CSSProperties = {
      top: props.y,
      width: textWidth(props.text, POPUP_FONT) + 16,
    };
    if (props.closeButton) {
      style.pointerEvents = 'auto';
    }

    if (props.unmountWhenClosed && !props.open) {
      return null;
    }

    return (
      <div
        ref={innerRef}
        className={`hide-in-mobile fancy-popup${
          props.open ? '' : ' popup-hidden'
        }${props.className ? ` ${props.className}` : ''}`}
        style={style}>
        {props.closeButton && (
          <LegacyWBIcon
            name="close"
            onClick={() => props.onClose?.()}
            style={{
              position: 'absolute',
              top: 4,
              right: 4,
              margin: 0,
              fontSize: 19,
              cursor: 'pointer',
              zIndex: 1,
            }}
          />
        )}
        {Array.from(allTexts.current).map((text, i) => (
          <span
            key={i}
            className={`fancy-popup__text${
              text === props.text ? ' active' : ''
            }`}
            style={{font: POPUP_FONT}}>
            {text}
          </span>
        ))}
      </div>
    );
  },
  {id: 'FancyPopup'}
);

export default FancyPopup;
