import styled from 'styled-components';
import * as globals from '../css/globals.styles';

export const ColabButton = styled.div`
  &&& {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    height: 32px;

    img {
      background: ${globals.gray700};
      width: 32px;
      height: 32px;
      padding: 0 4px;
      object-fit: contain;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${globals.primary};
      color: white;
      height: 32px;
      width: 110px;
      font-size: 16px;
      margin-bottom: 0;
    }

    &:hover {
      img {
        background: ${globals.gray800};
      }

      div {
        background: ${globals.primaryDark};
      }
    }
  }
`;
