import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const Wrapper = styled.div`
  .project-action-menu .icon {
    font-size: 20px;
    color: ${globals.gray800};
  }
`;
