import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Icon, Item, List, Loader, Segment} from 'semantic-ui-react';
import '../css/DiscussionBoard.less';
import {
  BenchmarkDiscussionThreadsComponent,
  DiscussionComment,
  DiscussionCommentConnection,
  DiscussionThread,
  DiscussionThreadPageComponent,
  UpdateDiscussionCommentComponent,
  UpdateDiscussionThreadComponent,
  User,
} from '../generated/graphql';
import {benchmarkDiscussionTab, benchmarkDiscussionThread} from '../util/urls';
import CreateDiscussionCommentForm from './CreateDiscussionCommentForm';
import CreateDiscussionThreadForm from './CreateDiscussionThreadForm';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import LegacyWBIcon from './elements/LegacyWBIcon';
import Markdown from './Markdown';
import makeComp from '../util/profiler';

import TimeAgo from 'react-timeago';

export const DISCUSSION_THREAD_PAGE_QUERY = gql`
  query DiscussionThreadPage($threadID: ID!) {
    discussionThread(id: $threadID) {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
      }
      comments {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            createdAt
            updatedAt
            deletedAt
            body
            isParent
            upvotes
            poster {
              id
              photoUrl
              name
            }
          }
        }
      }
    }
    viewer {
      id
    }
  }
`;

interface DiscussionBoardProps {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
  threadID?: string;
}

const BenchmarkDiscussionBoard: React.FC<DiscussionBoardProps> = makeComp(
  props => {
    const {benchmarkEntityName, benchmarkProjectName, threadID} = props;
    const [threadCreationInProgress, setThreadCreationInProgress] =
      useState(false);

    return (
      <BenchmarkDiscussionThreadsComponent
        variables={{
          benchmarkEntityName,
          benchmarkProjectName,
        }}>
        {({loading, error, data, refetch}) => {
          if (loading || !data) {
            return <Loader />;
          }

          if (threadID) {
            return (
              <DiscussionThreadPage
                entityName={benchmarkEntityName}
                projectName={benchmarkProjectName}
                threadID={threadID}
              />
            );
          }

          if (threadCreationInProgress) {
            return (
              <CreateDiscussionThreadForm
                onCancel={() => setThreadCreationInProgress(false)}
                onSubmit={() => {
                  refetch().then(() => setThreadCreationInProgress(false));
                }}
                {...props}
              />
            );
          }

          const threads = data.project!.benchmarkDiscussionThreads!.edges;
          const discussionThreadEntries =
            threads.length !== 0 ? (
              threads.map(edge => (
                <DiscussionThreadEntry
                  key={edge.node.id}
                  discussionThread={edge.node}
                  poster={edge.node.poster}
                  comments={edge.node.comments}
                  {...props}
                />
              ))
            ) : (
              <Segment>No topics yet. Be the first!</Segment>
            );

          return (
            <div className="discussion-board">
              <Button
                className="discussion-board-button"
                onClick={() => setThreadCreationInProgress(true)}>
                New Topic
              </Button>
              {discussionThreadEntries}
            </div>
          );
        }}
      </BenchmarkDiscussionThreadsComponent>
    );
  },
  {id: 'BenchmarkDiscussionBoard', memo: true}
);

export default BenchmarkDiscussionBoard;

interface DiscussionThreadPageProps {
  entityName: string;
  projectName: string;
  threadID: string;
}

const DiscussionThreadPage: React.FC<DiscussionThreadPageProps> = makeComp(
  ({entityName, projectName, threadID}) => {
    return (
      <DiscussionThreadPageComponent
        variables={{
          threadID,
        }}>
        {({loading, error, data, refetch}) => {
          if (loading || !data) {
            return <Loader />;
          }

          if (!data.discussionThread) {
            // If discussion thread not found, throw error
            return null;
          }

          const {discussionThread, viewer} = data;

          const comments = discussionThread.comments.edges.map(edge => {
            return (
              <UpdateDiscussionCommentComponent>
                {updateCommentMutation => {
                  return (
                    <DiscussionCommentEntry
                      key={edge.node.id}
                      comment={edge.node}
                      poster={edge.node.poster}
                      editable={(viewer && viewer.id) === edge.node.poster.id}
                      onCommentEdit={body => {
                        updateCommentMutation({
                          variables: {
                            discussionCommentID: edge.node.id,
                            body,
                          },
                        }).then(() => refetch());
                      }}
                    />
                  );
                }}
              </UpdateDiscussionCommentComponent>
            );
          });

          return (
            <React.Fragment>
              <div className="discussion-board">
                <div className="topic-header-section">
                  <Link
                    to={benchmarkDiscussionTab({
                      benchmarkEntityName: entityName,
                      benchmarkProjectName: projectName,
                    })}>
                    <Button size="tiny" className="wb-icon-button only-icon">
                      <LegacyWBIcon name="previous" />
                    </Button>
                  </Link>
                  <div className="topic-header">{discussionThread.title}</div>
                </div>
                <UpdateDiscussionThreadComponent>
                  {updateThreadMutation => {
                    return (
                      <DiscussionCommentEntry
                        key={discussionThread.id}
                        comment={discussionThread}
                        poster={discussionThread.poster}
                        editable={
                          (viewer && viewer.id) === discussionThread.poster.id
                        }
                        onCommentEdit={body => {
                          updateThreadMutation({
                            variables: {
                              discussionThreadID: discussionThread.id,
                              body,
                            },
                          });
                        }}
                      />
                    );
                  }}
                </UpdateDiscussionThreadComponent>
                {comments}
                {viewer && (
                  <CreateDiscussionCommentForm
                    onSubmit={() => {
                      refetch();
                    }}
                    discussionThreadID={threadID}
                  />
                )}
              </div>
            </React.Fragment>
          );
        }}
      </DiscussionThreadPageComponent>
    );
  },
  {id: 'DiscussionThreadPage', memo: true}
);

/* DISCUSSION THREAD ENTRY */

interface DiscussionThreadEntryProps {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
  discussionThread: Pick<
    DiscussionThread,
    'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'
  >;
  poster: Pick<User, 'id' | 'photoUrl' | 'name'>;
  comments: Pick<DiscussionCommentConnection, 'totalCount'>;
}

const DiscussionThreadEntry: React.FC<DiscussionThreadEntryProps> = makeComp(
  ({
    benchmarkEntityName,
    benchmarkProjectName,
    discussionThread,
    poster,
    comments,
  }) => {
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
      return (
        <Redirect
          to={benchmarkDiscussionThread({
            benchmarkEntityName,
            benchmarkProjectName,
            threadID: discussionThread.id,
          })}
        />
      );
    }

    return (
      <Segment
        className="discussion-board-topic"
        onClick={() => {
          setRedirect(true);
        }}>
        <List>
          <List.Item key={discussionThread.id}>
            <Item.Header>{discussionThread.title}</Item.Header>
            <Item.Content>
              {comments.totalCount} comments, {poster.name} posted{' '}
              <TimeAgo date={discussionThread.createdAt + 'Z'} />
            </Item.Content>
          </List.Item>
        </List>
      </Segment>
    );
  },
  {id: 'DiscussionThreadEntry', memo: true}
);

/* DISCUSSION COMMENT ENTRY */

interface DiscussionCommentEntryProps {
  comment: Pick<
    DiscussionComment,
    'createdAt' | 'updatedAt' | 'body' | 'upvotes'
  >;
  poster: Pick<User, 'id' | 'photoUrl' | 'name'>;
  editable: boolean;
  onCommentEdit?: (body: string) => void;
}

const DiscussionCommentEntry: React.FC<DiscussionCommentEntryProps> = makeComp(
  ({comment, poster, editable, onCommentEdit}) => {
    const [isEditInProgress, setIsEditInProgress] = useState(false);
    const [body, setBody] = useState(comment.body);

    return (
      <div>
        <div className="discussion-comment">
          <Item.Image
            className="author-image"
            alt="author image"
            src={poster.photoUrl}
          />
          <div className="comment-text">
            <div className="comment-header">
              <div className="comment-author">
                <p>
                  {poster.name} • <TimeAgo date={comment.createdAt + 'Z'} />
                  {comment.updatedAt ? ` (Edited)` : ''}
                </p>
              </div>
              <div className="comment-dropdown">
                <div
                  hidden={!editable || isEditInProgress}
                  onClick={() => setIsEditInProgress(true)}>
                  <LegacyWBIcon name="edit" />
                </div>
                <div
                  hidden={!isEditInProgress}
                  onClick={() => setIsEditInProgress(false)}>
                  <Icon name="cancel" />
                </div>
              </div>
            </div>
            {isEditInProgress ? (
              <div className="comment-body--editing">
                <InlineMarkdownEditor
                  readOnly={false}
                  serverText={comment.body}
                  onChange={setBody}
                />
                <div className="comment-edit-buttons">
                  <Button
                    positive
                    size="tiny"
                    onClick={() => {
                      if (onCommentEdit) {
                        onCommentEdit(body);
                      }

                      setIsEditInProgress(false);
                    }}>
                    Update Comment
                  </Button>
                </div>
              </div>
            ) : (
              <div className="comment-body--static">
                <Markdown content={comment.body} condensed={false} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
  {id: 'DiscussionCommentEntry', memo: true}
);
