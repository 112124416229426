import React, {useCallback} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {useViewer} from '../state/viewer/hooks';
import {useSelector, useApolloClient} from '../state/hooks';
import * as Urls from '../util/urls';
import {useHistory} from 'react-router-dom';
import {ReportViewRef} from '../state/reports/types';
import * as ViewApi from '../state/views/api';
import {ID} from '@wandb/cg/browser/utils/string';
import * as ViewSelectors from '../state/views/selectors';
import * as ViewNormalize from '../state/views/normalize';
import {LoadResultType} from '../state/views/api';
import ReactTimeago from 'react-timeago';
import DraftWarningModal from './DraftWarningModal';
import makeComp from '../util/profiler';
import {isOnGalleryDiscussionView} from '../util/url';

interface EditReportTriggerProps {
  viewRef: ReportViewRef;
}

const EditReportTrigger: React.FC<EditReportTriggerProps> = makeComp(
  props => {
    const viewer = useViewer();
    const client = useApolloClient();
    const view = useSelector(state => state.views.views[props.viewRef.id]);
    const views = useSelector(ViewSelectors.getViews);
    const reportID = view.id;
    const history = useHistory();
    if (reportID == null) {
      throw new Error('invalid state');
    }
    const viewPartRef = view.partRef;
    if (viewPartRef == null) {
      throw new Error('View in redux missing part ref');
    }

    const [draftWarningOpen, setDraftWarningOpen] = React.useState(false);
    const [editedWarningOpen, setEditedWarningOpen] = React.useState(false);
    const [loadedReport, setLoadedReport] =
      React.useState<LoadResultType | null>(null);

    const openDraftWarning = useCallback(() => setDraftWarningOpen(true), []);
    const closeDraftWarning = useCallback(() => setDraftWarningOpen(false), []);

    const createAndGotoDraft = useCallback(async () => {
      const base = loadedReport == null ? view : loadedReport;
      const newDraft = await ViewApi.save(client, {
        type: 'runs/draft',
        name: ID(12),
        displayName: base.displayName,
        description: base.description,
        spec:
          loadedReport == null
            ? ViewNormalize.denormalize(views.parts, viewPartRef)
            : loadedReport.spec,
        project: base.project,
        parentId: base.id,
        previewUrl: base.previewUrl,
        coverUrl: base.coverUrl,
      });
      history.push(
        base.project != null
          ? Urls.reportEdit({
              entityName: base.project.entityName,
              projectName: base.project.name,
              reportID: newDraft.id as string,
              reportName: newDraft.displayName,
            })
          : (isOnGalleryDiscussionView()
              ? Urls.galleryDiscussionEdit
              : Urls.galleryPostEdit)({
              entityName: base.entityName,
              reportID: newDraft.id as string,
              reportName: newDraft.displayName,
            })
      );
    }, [client, history, loadedReport, view, viewPartRef, views.parts]);

    if (viewer == null) {
      return <>{props.children}</>;
    }

    return (
      <>
        <div
          style={{display: 'contents'}}
          onClick={async () => {
            const loaded = await ViewApi.load(client, reportID);
            setLoadedReport(loaded);
            if (loaded.updatedAt > view.updatedAt) {
              setEditedWarningOpen(true);
            } else {
              openDraftWarning();
            }
          }}>
          {props.children}
        </div>
        {loadedReport != null && editedWarningOpen && (
          <Modal
            size="mini"
            open={true}
            onClose={() => {
              setEditedWarningOpen(false);
            }}>
            <Modal.Content>
              <p>
                This report was updated{' '}
                <ReactTimeago
                  live={false}
                  date={loadedReport.updatedAt + 'Z'}
                />{' '}
                {loadedReport.updatedBy != null && (
                  <>by {loadedReport.updatedBy.username}</>
                )}
                . Refresh the page to see its updated contents.
              </p>
              <Button size="tiny" primary onClick={openDraftWarning}>
                Edit updated report
              </Button>
              <Button
                size="tiny"
                onClick={() => {
                  setEditedWarningOpen(false);
                }}>
                Cancel
              </Button>
            </Modal.Content>
          </Modal>
        )}
        {draftWarningOpen && (
          <DraftWarningModal
            parentViewID={reportID}
            onNoDraftFound={createAndGotoDraft}
            onDiscard={createAndGotoDraft}
            onClose={closeDraftWarning}
          />
        )}
      </>
    );
  },
  {id: 'EditReportTrigger'}
);

export default EditReportTrigger;
