import * as globals from '../css/globals.styles';

import * as _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import {useState, useEffect} from 'react';
import TimeAgo from 'react-timeago';
import Loader from './WandbLoader';
import {StorageAction, Node} from '../pages/StorageExplorer';
import {
  Checkbox,
  Header,
  Icon,
  Pagination,
  Search,
  SearchProps,
  Table,
} from 'semantic-ui-react';
import {File} from '../state/graphql/runFilesQuery';
import {fileInfoFromName} from './FileBrowser';
import classNames from 'classnames';
import {History} from 'history';
import * as Url from '../util/url';

import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface FileBrowserProps {
  root: Node;
  path?: string;
  entityName: string;
  selectionStatus: string;
  history: History;
  deleteButton?: React.ReactNode;
  dispatch: React.Dispatch<StorageAction>;
  loadingFiles?: boolean;
  onSearchChange?(e: React.MouseEvent, search: SearchProps): void;
  setFilePath(path: string[]): void;
}

const PAGE_SIZE = 25;

const FileBrowser = makeComp(
  (props: FileBrowserProps) => {
    const {
      root,
      entityName,
      selectionStatus,
      deleteButton,
      setFilePath,
      loadingFiles,
      dispatch,
      onSearchChange,
    } = props;
    const path = Url.parseRunTabPath(props.path);
    let currentFolder: Node = root;
    // traverse the tree to the current directory
    path.forEach(folderName => {
      if (currentFolder.subdirectories[folderName] != null) {
        currentFolder = currentFolder.subdirectories[folderName];
      }
    });

    const breadcrumbs = [entityName].concat(path);
    return (
      <div className="file-browser">
        {/* render the path */}
        <Header className="file-browser-path">
          &gt;&nbsp;
          {breadcrumbs.map((folderName, i) => {
            let seperator;
            if (i !== breadcrumbs.length - 1) {
              seperator = ' / ';
            }
            return [
              <span
                className="file-browser-path-item"
                style={{cursor: 'pointer'}}
                key={'path' + i}
                onClick={e => setFilePath(path.slice(0, i))}>
                {folderName}
              </span>,
              seperator,
            ];
          })}
          {deleteButton}
        </Header>
        {loadingFiles && <Loader />}
        {currentFolder != null && !loadingFiles && (
          <Folder
            folderStatus={selectionStatus}
            onSearchChange={onSearchChange}
            dispatch={dispatch}
            loadingFiles={loadingFiles}
            folder={currentFolder}
            path={path}
            setFilePath={setFilePath}
          />
        )}
      </div>
    );
  },
  {id: 'FileBrowser'}
);

export default FileBrowser;

interface FileMeta {
  id: string;
  bytes: number;
  type: 'file' | 'artifact_version' | 'artifact';
}

// TODO: this is an awfully hacky way of deriving the type
function typeFromFile(file: File) {
  if (file.artifact == null) {
    return 'file';
  }
  return file.name.includes(':v') ? 'artifact_version' : 'artifact';
}

function recurseFiles(root: Node): FileMeta[] {
  const subFolderFiles = Object.keys(root.subdirectories).flatMap(k =>
    recurseFiles(root.subdirectories[k])
  );
  return subFolderFiles.concat(
    root.files.map(f => ({
      id: f.id,
      bytes: f.sizeBytes,
      type: typeFromFile(f),
    }))
  );
}

interface FolderProps {
  loadingFiles?: boolean;
  folder: Node;
  path: string[];
  folderStatus?: string;
  dispatch: React.Dispatch<StorageAction>;
  onSearchChange?(e: React.MouseEvent, search: SearchProps): void;
  setFilePath(path: string[], nullProject?: boolean, nullRun?: boolean): void;
}

const Folder = makeComp(
  (props: FolderProps) => {
    const {folderStatus, folder, path, setFilePath, dispatch, onSearchChange} =
      props;
    const [displayOffset, setDisplayOffset] = useState(0);
    useEffect(() => setDisplayOffset(0), [folder]);
    // separate subfolders from files in this directory
    const rootFiles = folder.files;
    const subfolderKeys = _.sortBy(
      Object.keys(folder.subdirectories),
      key => -(folder.subdirectories[key].size || 0)
    );
    const foldersAndFiles = [...subfolderKeys, ...rootFiles];
    // TODO: do we really want disabled to mean checked?
    function selectedOrDisabled(node: Node | File) {
      return node.selected || node.disabled;
    }
    const allSelected =
      selectedOrDisabled(folder) ||
      (folder.id == null &&
        foldersAndFiles.every(f =>
          selectedOrDisabled(_.isString(f) ? folder.subdirectories[f] : f)
        ));

    return (
      <>
        <Table
          unstackable
          selectable
          className="file-browser-table storage-explorer">
          <Table.Header>
            <Table.Row>
              <Table.Cell className="file-selector-cell">
                {folder.selectable && (
                  <Checkbox
                    checked={allSelected}
                    disabled={folder.disabled}
                    onChange={(e, {checked}) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (folder.id != null) {
                        dispatch!({
                          type: checked ? 'select' : 'deselect',
                          payload: {
                            path,
                            ids: [folder.id],
                            bytes: [folder.size || 0],
                            types: [folder.type!],
                          },
                        });
                      } else {
                        // TODO: are there any cases where I need to support just sub-folders?
                        const files = recurseFiles(folder);
                        dispatch!({
                          type: checked ? 'select' : 'deselect',
                          payload: {
                            path,
                            ids: files.map(file => file.id),
                            bytes: files.map(file => file.bytes),
                            types: files.map(file => file.type),
                          },
                        });
                      }
                    }}
                  />
                )}
              </Table.Cell>
              <Table.Cell className="info">
                {folderStatus || folder.summary}
              </Table.Cell>
              {folder.searchable && (
                <Table.Cell className="search" colSpan="3" textAlign="right">
                  <Search
                    category
                    open={false}
                    onSearchChange={onSearchChange}
                    results={[]}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {foldersAndFiles
              .slice(displayOffset, displayOffset + PAGE_SIZE)
              .map((folderOrFile, i) => {
                if (_.isString(folderOrFile)) {
                  return (
                    <SubFolder
                      dispatch={dispatch}
                      key={'folder-' + folderOrFile}
                      folder={folder.subdirectories[folderOrFile]}
                      folderName={folderOrFile}
                      path={path}
                      setFilePath={setFilePath}
                    />
                  );
                } else {
                  return (
                    <FileRow
                      dispatch={dispatch}
                      key={'file-' + folderOrFile.id}
                      file={folderOrFile}
                      path={path}
                    />
                  );
                }
              })}
          </Table.Body>
        </Table>
        {foldersAndFiles.length > PAGE_SIZE && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Pagination
              defaultActivePage={1}
              totalPages={Math.ceil(foldersAndFiles.length / PAGE_SIZE)}
              onPageChange={(e, data) => {
                const pg = data.activePage;
                if (pg != null && _.isNumber(pg)) {
                  setDisplayOffset((pg - 1) * PAGE_SIZE);
                }
              }}
              size="small"
            />
          </div>
        )}
      </>
    );
  },
  {id: 'Folder'}
);

interface SubFolderProps {
  folder: Node;
  path: string[];
  folderName: string;
  dispatch: React.Dispatch<StorageAction>;
  setFilePath(path: string[]): void;
}

const SubFolder = makeComp(
  (props: SubFolderProps) => {
    const {folder, folderName, path, dispatch, setFilePath} = props;
    const newPath = path.concat([folderName]);
    const size = folder.size;

    return (
      <Table.Row
        className={classNames('file-browser-folder', {
          'child-selected': folder.childSelected,
        })}
        onClick={() => setFilePath(newPath)}>
        <Table.Cell
          className="file-selector-cell"
          // TODO: make this cancel the above click
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
          }}>
          {folder.selectable && (
            <Checkbox
              checked={(folder.selected || folder.disabled) === true}
              disabled={folder.disabled}
              onClick={e => e.stopPropagation()}
              onChange={(e, {checked}) => {
                e.preventDefault();
                e.stopPropagation();
                let ids = [folder.id!];
                let bytes = [folder.size!];
                let types = [folder.type!];
                if (folder.id == null) {
                  const files = recurseFiles(folder);
                  ids = files.map(file => file.id);
                  bytes = files.map(file => file.bytes);
                  types = files.map(file => file.type);
                }
                dispatch({
                  type: checked ? 'select' : 'deselect',
                  payload: {
                    ids,
                    bytes,
                    types,
                    path: newPath,
                  },
                });
              }}
            />
          )}
        </Table.Cell>
        <Table.Cell className="folder-name-cell">
          <div className="file-browser-name-cell-wrapper">
            <LegacyWBIcon className="file-browser-icon" name="folder" />
            <span className="file-browser-folder-name">{folderName}</span>
            &nbsp;/
          </div>
        </Table.Cell>
        <Table.Cell className="contents-cell">{folder.summary}</Table.Cell>
        <Table.Cell className="file-size-cell">
          {numeral(size).format('0.0b')}
        </Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
    );
  },
  {id: 'SubFolder'}
);

interface FileProps {
  file: File;
  path: string[];
  dispatch: React.Dispatch<StorageAction>;
}

const FileRow = makeComp(
  (props: FileProps) => {
    const {file, path, dispatch} = props;
    const fileName = file.name.split('/').pop() || '';
    const newPath = path.concat([fileName]);
    const fileInfo = fileInfoFromName(fileName);
    const iconName = fileInfo.iconName;

    function selectFile(e: React.SyntheticEvent) {
      e.preventDefault();
      e.stopPropagation();
      if (!file.disabled) {
        const checked = !file.selected;
        const type = typeFromFile(file);
        dispatch({
          type: checked ? 'select' : 'deselect',
          payload: {
            ids: [file.id],
            bytes: [file.sizeBytes],
            types: [type],
            path: newPath,
          },
        });
      }
    }

    return (
      <Table.Row onClick={selectFile}>
        <Table.Cell className="file-selector-cell">
          <Checkbox
            checked={(file.selected || file.disabled) === true}
            disabled={file.disabled}
            onClick={e => e.stopPropagation()}
            onChange={selectFile}
          />
        </Table.Cell>
        <Table.Cell className="file-name-cell">
          <div className="file-browser-name-cell-wrapper">
            {file.ref != null ? (
              <Icon
                style={{color: globals.primary, width: 28}}
                name="arrow alternate circle right outline"
              />
            ) : (
              <LegacyWBIcon className="file-browser-icon" name={iconName} />
            )}
            <span className="file-browser-file-name">
              {file.name.split('/').pop()}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell className="updated-time-cell">
          {file.ref != null ? (
            file.ref
          ) : file.updatedAt != null ? (
            <TimeAgo date={file.updatedAt + 'Z'} />
          ) : undefined}
        </Table.Cell>
        <Table.Cell className="file-size-cell">
          {numeral(file.sizeBytes).format('0.0b')}
        </Table.Cell>
        <Table.Cell
          className="hide-in-mobile"
          onClick={(e: Event) => e.stopPropagation()}>
          {file.url != null && (
            <a
              href={
                file.ref != null ? file.ref : Url.encodeURIPercentChar(file.url)
              }
              download={file.name}
              onClick={e => e.stopPropagation()}>
              <LegacyWBIcon name="download" />
            </a>
          )}
        </Table.Cell>
      </Table.Row>
    );
  },
  {id: 'File'}
);
