import React from 'react';
import {useCallback, useMemo, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import * as Op from '@wandb/cg/browser/ops';
import * as Types from '@wandb/cg/browser/model/types';
import * as LLReact from '../../cgreact';

const PageControls: React.FC<{
  rowsNode: Types.Node;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
}> = makeComp(
  ({rowsNode, pageSize, page, setPage}) => {
    const countNode = useMemo(
      () => Op.opCount({arr: rowsNode as any}),
      [rowsNode]
    );

    const prevPage = useCallback(() => setPage(page - 1), [page, setPage]);
    const nextPage = useCallback(() => setPage(page + 1), [page, setPage]);

    const countValue = LLReact.useNodeValue(countNode);

    // return <div>Row count: {value.result}</div>;
    const startIndex = pageSize * page;
    let endIndex = startIndex + pageSize;
    const totalItems = countValue.result;
    if (endIndex > totalItems) {
      endIndex = totalItems;
    }
    useEffect(() => {
      // reset page if we're out of bounds
      // Note: This code used to try to reset when the input node changed as well,
      // but that causes ugly double loading behavior where the table would load,
      // then we'd reset the page to 0 because it was our first render, which
      // caused a bunch more loading and remounting.
      if (startIndex > totalItems) {
        setPage(0);
      }
    }, [rowsNode, startIndex, totalItems, setPage]);

    const onFirstPage = page === 0;
    const onLastPage = endIndex === totalItems;
    const singleItem = startIndex + 1 === endIndex;
    return countValue.loading || totalItems <= pageSize ? (
      <></>
    ) : (
      <div>
        {startIndex + 1}
        {!singleItem && `-${endIndex}`} of {totalItems}{' '}
        <Button.Group className="pagination-buttons">
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={onFirstPage}
            onClick={prevPage}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={onLastPage}
            onClick={nextPage}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
      </div>
    );
  },
  {id: 'ControlPage'}
);

export default PageControls;
