import {RunKeyInfo, RunKeyInfoInfo} from '../types/run';
import * as _ from 'lodash';
import YAML from 'yaml';

import * as Runs from '../util/runs';
import * as Panels from '../util/panels';
import * as Types from '../state/runs/types';
import * as Run from './runs';
import * as Filter from './filters';
import * as Query from './queryts';
import {useRunsData} from '../state/runs/hooks';
import * as PanelParallelCoord from '../components/PanelParallelCoord';
import {RunSetConfig} from '../state/views/runSet/types';
import {SelectionState} from './selectionmanager';
import {ID} from '@wandb/cg/browser/utils/string';

// If sweep.displayName is not set, it'll use the name field from sweep config json
export function getSweepDisplayName(sweep: {
  name: string;
  displayName?: string;
  config?: string;
}) {
  if (sweep.displayName != null) {
    return sweep.displayName;
  }
  if (sweep.config != null) {
    const config = YAML.parse(sweep.config);
    if (config.name != null) {
      return config.name;
    }
  }
  return sweep.name;
}

export type SweepState =
  | 'PENDING'
  | 'RUNNING'
  | 'PAUSED'
  | 'FINISHED'
  | 'CANCELED'
  | 'UNKNOWN';

export const descriptionBySweepState: {[s in SweepState]: string} = {
  PENDING:
    'The sweep is ready. Launch agents on your machines to start kicking off runs.',
  RUNNING: 'Any active agents will receive parameters to run.',
  PAUSED:
    'The sweep server is paused and not giving out any new jobs for agents to run.',
  CANCELED: 'The sweep was manually stopped.',
  FINISHED: 'The sweep completed all of the runs.',
  UNKNOWN:
    'The sweep is in an invalid state. Please contact W&B support and let us know.',
};

export function sweepStateDescription(state: SweepState): string {
  return descriptionBySweepState[state];
}

export function sweepStateDisplayName(state: SweepState) {
  if (state === 'CANCELED') {
    // Fixing this is probably too much effort in the backend. Lol.
    return 'Cancelled';
  }
  return state[0] + state.slice(1).toLowerCase();
}

export interface SweepYamlSettingsChoice {
  distribution: 'choice';
  values: string[];
}

export interface SweepYamlSettingsUniform {
  distribution: 'uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsIntUniform {
  distribution: 'int_uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsQUniform {
  distribution: 'q_uniform';
  min: number;
  max: number;
  q: number;
}

export interface SweepYamlSettingsLogUniform {
  distribution: 'log_uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsQLogUniform {
  distribution: 'q_log_uniform';
  min: number;
  max: number;
  q: number;
}

export interface SweepYamlSettingsNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
}

export interface SweepYamlSettingsQNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
  q: number;
}

export interface SweepYamlSettingsLogNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
}

export interface SweepYamlSettingsQLogNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
  q: number;
}

export interface SweepYamlSettings {
  distribution: string;
  value?: number | string;
  values?: Array<number | string>;
  min?: number;
  max?: number;
  mu?: number;
  sigma?: number;
  q?: number;
}

export interface SweepYamlParameters {
  [name: string]: SweepYamlSettings;
}

export interface SweepYamlConfig {
  program?: string;
  method?: 'bayes' | 'grid' | 'random';
  metric?: {
    name: string;
    goal: 'minimize' | 'maximize';
  };
  parameters?: SweepYamlParameters;
  early_terminate?: {
    type?: 'hyperband';
    min_iter?: number;
    max_iter?: number;
    s?: number;
    eta?: number;
  };
  description?: string;
}

export const distributions = [
  'constant',
  'categorical',
  'uniform',
  'int_uniform',
  'q_uniform',
  'log_uniform',
  'q_log_uniform',
  'normal',
  'q_normal',
  'log_normal',
  'q_log_normal',
];

export const gridDistributions = ['constant', 'categorical'];

export const distributionToFields = {
  constant: ['value'],
  categorical: ['values'],
  uniform: ['min', 'max'],
  int_uniform: ['min', 'max'],
  q_uniform: ['min', 'max', 'q'],
  log_uniform: ['min', 'max'],
  q_log_uniform: ['min', 'max', 'q'],
  normal: ['mu', 'sigma'],
  q_normal: ['mu', 'sigma', 'q'],
  log_normal: ['mu', 'sigma'],
  q_log_normal: ['mu', 'sigma', 'q'],
};

export const methods = ['grid', 'random', 'bayes'];

/*
function isFloat(val: string) {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) {
    return false;
  }

  const parsedVal = parseFloat(val);
  if (isNaN(parsedVal)) {
    return false;
  }
  return true;
}
*/

function isInt(val: string) {
  const intRegex = /^-?\d+$/;
  if (!intRegex.test(val)) {
    return false;
  }

  const intVal = parseInt(val, 10);
  return parseFloat(val) === intVal && !isNaN(intVal);
}

function randnormal_bm() {
  // Slow way to generate a normally distributed random number
  let u: number = 0;
  let v: number = 0;
  while (u === 0) {
    u = Math.random();
  } // Converting [0,1) to (0,1)
  while (v === 0) {
    v = Math.random();
  }
  return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
}

export function sampleValueFromParameterSettings(settings: SweepYamlSettings) {
  if (settings.distribution === 'constant') {
    return settings.value;
  } else if (settings.distribution === 'categorical') {
    if (settings.values != null) {
      const index = Math.floor(Math.random() * settings.values.length);
      return settings.values[index];
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'uniform') {
    if (settings.max != null && settings.min != null) {
      return Math.random() * (settings.max - settings.min) + settings.min;
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'int_uniform') {
    if (settings.max != null && settings.min != null) {
      return Math.floor(
        Math.random() * (settings.max - settings.min) + settings.min
      );
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'q_uniform') {
    if (settings.max != null && settings.min != null && settings.q != null) {
      return (
        Math.round(
          (Math.random() * (settings.max - settings.min) + settings.min) /
            settings.q
        ) * settings.q
      );
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'log_uniform') {
    if (settings.max != null && settings.min != null) {
      return Math.exp(
        Math.random() * (settings.max - settings.min) + settings.min
      );
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'q_log_uniform') {
    if (settings.max != null && settings.min != null && settings.q != null) {
      return (
        Math.round(
          Math.exp(
            Math.random() * (settings.max - settings.min) + settings.min
          ) / settings.q
        ) * settings.q
      );
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'normal') {
    if (settings.mu != null && settings.sigma != null) {
      return randnormal_bm() * settings.sigma + settings.mu;
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'q_normal') {
    if (settings.mu != null && settings.sigma != null && settings.q != null) {
      return (
        Math.round(
          (randnormal_bm() * settings.sigma + settings.mu) / settings.q
        ) * settings.q
      );
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'log_normal') {
    if (settings.mu != null && settings.sigma != null) {
      return Math.exp(randnormal_bm() * settings.sigma + settings.mu);
    } else {
      return undefined;
    }
  } else if (settings.distribution === 'q_log_normal') {
    if (settings.mu != null && settings.sigma != null && settings.q != null) {
      return (
        Math.round(
          Math.exp(randnormal_bm() * settings.sigma + settings.mu) / settings.q
        ) * settings.q
      );
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function getDefaultSweepSettingsFromKeyInfo(keyInfo: RunKeyInfoInfo) {
  if (keyInfo.majorType === 'number') {
    const numberStrings: string[] = Object.keys(keyInfo.valueCount);
    if (numberStrings.length === 0) {
      return undefined;
    }
    const allIntegers = numberStrings.every(value => isInt(value));

    const numbers = numberStrings.map(numberStr => Number(numberStr));
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);
    const distMin = min > 0 ? min / 2 : min * 2;
    const distMax = max > 0 ? max * 2 : max / 2;

    // This can only happen when all values are 0. In this case, don't include
    // this metric since we don't have a good guess for min/max values.
    if (distMin === distMax) {
      return undefined;
    }

    return {
      distribution: allIntegers ? 'int_uniform' : 'uniform',
      min: allIntegers ? Math.round(distMin) : distMin,
      max: allIntegers ? Math.round(distMax) : distMax,
    };
  } else if (keyInfo.majorType === 'boolean') {
    return {
      distribution: 'categorical',
      values: ['true', 'false'],
    };
  } else if (keyInfo.majorType === 'string') {
    return {
      distribution: 'categorical',
      values: Object.keys(keyInfo.valueCount),
    };
  } else {
    return undefined;
  }
}

// Gets the default panels for a sweep workspace, given a sweep config
export function getDefaultPanels(sweep: {
  name: string;
  config?: string;
}): Panels.PanelGroupConfig {
  if (sweep.config == null) {
    return [];
  }
  const config = YAML.parse(sweep.config) as SweepYamlConfig;
  const metric = config.metric;
  const parameters = config.parameters;

  const haveMetric = metric != null && metric.name != null;

  const panels: Panels.PanelGroupConfig = [];

  if (haveMetric && metric != null) {
    // Add a scatter plot panel with envelope for our metric
    const scatterPanel = Panels.layedOutPanel({
      __id__: ID(),
      viewType: 'Scatter Plot',
      layout: {x: 0, y: 0, w: 12, h: 10},
      config: {
        xAxis: 'run:createdAt',
        yAxis: 'summary:' + metric.name,
      },
    });
    if (metric.goal === 'maximize') {
      scatterPanel.config.showMaxYAxisLine = true;
    }
    if (metric.goal === 'minimize') {
      scatterPanel.config.showMinYAxisLine = true;
    }
    panels.push(scatterPanel);

    // Add a parameter importance panel targetting our metric
    const importancePanel = Panels.layedOutPanel({
      __id__: ID(),
      viewType: 'Parameter Importance',
      layout: {x: 12, y: 0, w: 12, h: 10},
      config: {
        targetKey: metric.name,
      },
    });
    panels.push(importancePanel);
  }

  ///// Make an automatic parallel cooradinates plot for this sweep
  // One column for each parameter
  const columns = [];
  if (parameters != null) {
    for (const param of _.keys(parameters)) {
      const paramSettings = parameters[param];
      if (paramSettings.value != null) {
        // This is a constant, skip it.
        continue;
      }
      columns.push({
        accessor: Runs.fixConfigKeyString('config:' + param),
        // enable log scale if the distribution string contains 'log'
        log:
          paramSettings.distribution != null
            ? paramSettings.distribution.indexOf('log') !== -1
            : false,
      });
    }
  }
  // If we have a metric, add it as the last column.
  if (metric != null && metric.name !== null) {
    columns.push({accessor: 'summary:' + metric.name});
  }
  const pcPanel = Panels.layedOutPanel({
    __id__: ID(),
    viewType: PanelParallelCoord.PANEL_TYPE,
    layout: {x: 0, y: haveMetric ? 10 : 0, w: 24, h: 10},
    config: {columns},
  });
  panels.push(pcPanel);
  return panels;
}

export interface SweepConfig {
  columns?: SweepColumn[];
  method?: 'grid' | 'random' | 'bayes';
  metricName?: string;
  program?: string;
  goal?: 'minimize' | 'maximize';
  earlyTerminate?: {
    type: 'hyperband';
    minIter: string;
    eta: string;
  };
}

export interface SweepColumn {
  accessor?: string; // should be something like loss.value
  variableName?: string; // should be something like loss
  distribution?: string; // for example categorical
  value?: string;
  params?: {[key: string]: string};
}

export function useAllRunsData(entityName: string, projectName: string) {
  return useRunsData({
    entityName,
    projectName,
    keysLoading: false,
    fullConfig: true,
    fullSummary: true,
    queries: [
      {
        // LB: What does id do?
        id: 'test-id-unused',
        entityName,
        projectName,
        filters: Filter.EMPTY_FILTERS,
        sort: Query.CREATED_AT_ASC,
      },
    ],
  });
}

export function getConfigFromRunsData(runsData: Types.Data): SweepConfig {
  const program = getProgramFromRunsData(runsData);
  const columns = loadColumnsFromRunsData(runsData);
  const metrics = getMetricNameOptions(runsData.keyInfo);

  const metricName = metrics.length > 0 ? metrics[0].value : '';

  return {
    program: program ?? 'train.py',
    columns,
    method: 'bayes',
    metricName,
    goal: 'minimize',
  };
}

function getProgramFromRunsData(runsData: Types.Data): string | null {
  for (let i = runsData.filtered.length - 1; i >= 0; i--) {
    const run = runsData.filtered[i];
    const path = (run.config._wandb as any)?.value?.code_path;
    if (path != null) {
      return path.replace(/^code\//, '');
    }
  }
  return null;
}

export function getMetricNameOptions(keyInfo: RunKeyInfo) {
  return _.chain(keyInfo)
    .toPairs()
    .filter(
      ([k, ki]) =>
        ki.majorType === 'number' &&
        k.split(':')[0] === 'summary' &&
        !Runs.isReservedKey(k.split(':')[1]) &&
        !(k.split(':')[1] === 'graph')
    )
    .map(([k, ki]) => {
      return {
        text: Run.keyStringDisplayName(k),
        key: Run.keyStringDisplayName(k),
        value: Run.keyStringDisplayName(k),
      };
    })
    .value();
}

function loadColumnsFromRunsData(data: Types.Data) {
  if (!data.keyInfo) {
    return [];
  }

  const columns = Object.keys(data.keyInfo)
    .map(key => {
      const value = data.keyInfo[key];
      // key looks like config:epochs.value this parses it
      // we only want config keys with numeric or string values
      const k = Run.keyFromString(key);
      if (k == null) {
        return null;
      }
      if (k.section !== 'config') {
        return null;
      }
      if (Run.keyDisplayName(k) === 'wandb_version') {
        return null;
      }

      const settings = getDefaultSweepSettingsFromKeyInfo(value);
      if (settings != null) {
        const params: {[key: string]: string} = {};
        if (settings.min != null) {
          params.min = settings.min.toString();
        }
        if (settings.max != null) {
          params.max = settings.max.toString();
        }
        if (settings.values != null) {
          params.values = settings.values.join(',');
        }

        const column: SweepColumn = {
          accessor: k.name,
          variableName: Run.keyDisplayName(k),
          distribution: settings.distribution,
          params,
        };
        return column;
      } else {
        return null;
      }
    })
    .filter((c): c is SweepColumn => c !== null);

  return columns;
}

const SWEEP_CONFIG_KEY_ORDER = [
  'program',
  'method',
  'metric',
  'early_terminate',
  'parameters',
];

interface YAMLPair {
  key: {value: string};
}

function sweepConfigKeySort(a: YAMLPair, b: YAMLPair): number {
  const ai = SWEEP_CONFIG_KEY_ORDER.indexOf(a.key.value);
  const bi = SWEEP_CONFIG_KEY_ORDER.indexOf(b.key.value);
  if (bi === -1) {
    return -1;
  }
  if (ai === -1) {
    return 1;
  }
  return ai - bi;
}

export function generateSweepYamlFromConfig(config: SweepConfig) {
  const parameters: SweepYamlParameters = {};
  if (config.columns) {
    config.columns.forEach(col => {
      if (col) {
        if (col.variableName) {
          const settings = generateSweepSettingsFromColumn(col);
          parameters[col.variableName] = settings;
        }
      }
    });
  }
  const earlyTerminate = config.earlyTerminate
    ? {
        type: config.earlyTerminate.type,
        eta: Number(config.earlyTerminate.eta),
        min_iter: Number(config.earlyTerminate.minIter),
      }
    : undefined;

  const sweepConfig: SweepYamlConfig = {
    program: config.program || 'train.py',
    method: config.method || 'grid',
    metric: {
      name: config.metricName || '',
      goal: config.goal || 'minimize',
    },
    parameters,
  };

  if (earlyTerminate) {
    sweepConfig.early_terminate = earlyTerminate;
  }

  return YAML.stringify(sweepConfig, {sortMapEntries: sweepConfigKeySort});
}

export function generateSweepSettingsFromColumn(col: SweepColumn) {
  const settings: SweepYamlSettings = {
    distribution: col.distribution || 'uniform',
  };

  if (col.params) {
    for (const [key, value] of Object.entries(col.params)) {
      const val = value.trim();
      if (key === 'min') {
        settings.min = Number(val);
      } else if (key === 'max') {
        settings.max = Number(val);
      } else if (key === 'q') {
        settings.q = Number(val);
      } else if (key === 'mu') {
        settings.mu = Number(val);
      } else if (key === 'sigma') {
        settings.sigma = Number(val);
      } else if (key === 'value') {
        settings.value = validNumOrString(val);
      } else if (key === 'values') {
        settings.values = _.compact(val.split(',')).map(v =>
          validNumOrString(v.trim())
        );
      }
    }
  }

  return settings;
}

function validNumOrString(str: string) {
  const num = Number(str);
  return _.isFinite(num) ? num : stripQuotes(str);
}

function stripQuotes(str: string) {
  return str.replace(/"/g, '');
}

export interface CreateSweepArgs {
  runSet: RunSetConfig;
  tempSelections: SelectionState;
}
