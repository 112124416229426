import '../css/EditableImage.less';

import React from 'react';
import classNames from 'classnames';
import Dropzone, {DropzoneRef} from 'react-dropzone';
import {Icon} from 'semantic-ui-react';
import {
  publicImageUpload,
  profileImageUpload,
  viewImageUpload,
  entityImageUpload,
  squareCrop,
} from '../util/images';
import {gray100} from '../css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {AvatarPlaceholder} from '../pages/TeamPage.styles';

interface EditableImageProps {
  photoUrl?: string;
  readOnly?: boolean;
  label: boolean;
  imageSize: number;
  displaySize: number;
  viewID?: string;
  entityName?: string;
  profile?: boolean;
  updateOverlay?: JSX.Element | string;
  className?: string;
  placeholderText?: string;
  save(value: string): void;
}

interface EditableImageState {
  uploading: boolean;
  image: string | ArrayBuffer | null;
}

export default class EditableImage extends React.Component<
  EditableImageProps,
  EditableImageState
> {
  static defaultProps = {
    label: true,
    imageSize: 300,
    displaySize: 300,
  };
  state: EditableImageState = {
    uploading: false,
    image: null,
  };
  dropzoneRef = React.createRef<DropzoneRef>();
  imageRef = React.createRef<HTMLDivElement>();

  upload(image: Blob) {
    if (this.imageRef.current) {
      const fr = new FileReader();
      fr.onload = () => this.setState({image: fr.result});
      fr.readAsDataURL(image);
    }
    if (this.props.viewID) {
      return this.uploadView(image);
    } else if (this.props.entityName) {
      return this.uploadEntity(this.props.entityName, image);
    } else if (this.props.profile) {
      return this.uploadProfile(image);
    } else {
      return this.uploadPublic(image);
    }
  }

  async uploadProfile(image: Blob) {
    try {
      const updatedUrl = await profileImageUpload(image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save profile image: ' + error);
    }
  }

  async uploadEntity(entityName: string, image: Blob) {
    try {
      const updatedUrl = await entityImageUpload(entityName, image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save profile image: ' + error);
    }
  }

  async uploadView(image: Blob) {
    try {
      const name = 'preview.png';
      const updatedUrl = await viewImageUpload(
        image,
        name,
        this.props.viewID!,
        'image/png',
        true
      );
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save report image: ' + error);
    }
  }

  async uploadPublic(image: Blob) {
    try {
      const updatedUrl = await publicImageUpload(image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save image: ' + error);
    }
  }

  onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = acceptedFiles[0];

    if (file.type === 'image/gif') {
      this.upload(file);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const origImg = new Image();
      if (reader.result) {
        origImg.src = reader.result.toString();
        origImg.onload = () => {
          squareCrop(origImg, this.props.imageSize).then(blob =>
            this.upload(blob)
          );
        };
      }
    };
    reader.readAsDataURL(file);
  }

  render() {
    const {
      className,
      label,
      readOnly,
      displaySize,
      photoUrl,
      viewID,
      updateOverlay,
      placeholderText,
    } = this.props;
    const defaultUpdateOverlay = (
      <>
        <Icon name="photo" size="large" /> Update{' '}
        {viewID ? 'Preview' : 'Avatar'}
      </>
    );
    return (
      <div
        className={classNames('editable-field editable-image', className)}
        onClick={e => e.preventDefault()}>
        {label && <label>Avatar</label>}
        <Dropzone
          accept={['image/*']}
          disabled={readOnly}
          ref={this.dropzoneRef}
          onDrop={acceptedFiles => {
            this.onDrop(acceptedFiles);
          }}>
          {({getRootProps, getInputProps}) => {
            const picUrl = this.state.image || photoUrl;
            return (
              <div
                {...getRootProps()}
                className="field-content"
                ref={this.imageRef}
                style={{
                  width: displaySize,
                  height: displaySize,
                  background:
                    picUrl == null ? gray100 : `url("${picUrl}") no-repeat`,
                }}>
                {!readOnly &&
                  (picUrl == null ? (
                    <>
                      <AvatarPlaceholder>
                        <WBIcon name="camera" />
                        <div>{placeholderText ?? 'Add an image'}</div>
                      </AvatarPlaceholder>
                    </>
                  ) : (
                    <div className="update-overlay">
                      {updateOverlay ?? defaultUpdateOverlay}
                    </div>
                  ))}
                <input {...getInputProps()} />
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}
