import styled from 'styled-components';
import {error} from '../css/globals.styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
`;

export const FormWrapper = styled.div`
  padding-bottom: 1em;
`;

export const WarningWrapper = styled.div`
  margin: 0 0 1em 0;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TitleSpan = styled.span`
  display: flex;
  flex-direction: row;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const WarningIconWrapper = styled.div`
  font-weight: semi-bold;
`;

export const WarningMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Required = styled.span`
  color: ${error};
  font-weight: bold;
  padding-right: 4px;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;
