import _ from 'lodash';
import React, {useState, useCallback, useMemo} from 'react';
import {Button, Dropdown, Form, Header, Segment} from 'semantic-ui-react';

import * as Benchmark from '../util/benchmark';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import makeComp from '../util/profiler';

interface BenchmarkEditorFieldsProps {
  withSave?: boolean;
  defaultBenchmarkFields: Benchmark.BenchmarkFields;
  updateBenchmarkFields?(update: Partial<Benchmark.BenchmarkFields>): void;
  onSave?(fields: Benchmark.BenchmarkFields): void;
}

const BenchmarkEditorFields: React.FC<BenchmarkEditorFieldsProps> = makeComp(
  ({
    withSave,
    defaultBenchmarkFields,
    updateBenchmarkFields,
    onSave: propsOnSave,
  }) => {
    const [overview, setOverview] = useState(defaultBenchmarkFields.overview);
    const [submissionInstructions, setSubmissionInstructions] = useState(
      defaultBenchmarkFields.submissionInstructions
    );
    const [keys, setKeys] = useState(defaultBenchmarkFields.keys);
    const [sortMetric, setSortMetric] = useState(
      defaultBenchmarkFields.sortMetric
    );
    const [sortAsc, setSortAsc] = useState(defaultBenchmarkFields.sortAsc);
    const [codeRequired, setCodeRequired] = useState(
      defaultBenchmarkFields.codeRequired
    );
    const [embargo, setEmbargo] = useState(defaultBenchmarkFields.embargo);
    const [edited, setEdited] = useState(false);

    const updaterByStateVar: {
      [k in keyof Benchmark.BenchmarkFields]: (val: any) => void;
    } = useMemo(
      () => ({
        overview: setOverview,
        submissionInstructions: setSubmissionInstructions,
        keys: setKeys,
        sortMetric: setSortMetric,
        sortAsc: setSortAsc,
        codeRequired: setCodeRequired,
        embargo: setEmbargo,
      }),
      []
    );

    const update = useCallback(
      (updatedValues: Partial<Benchmark.BenchmarkFields>) => {
        Object.entries(updatedValues).forEach(([key, val]) =>
          updaterByStateVar[key as keyof Benchmark.BenchmarkFields]?.(val)
        );
        setEdited(true);
        if (updateBenchmarkFields) {
          updateBenchmarkFields(updatedValues);
        }
      },
      [updateBenchmarkFields, updaterByStateVar]
    );

    const onSave = useCallback(() => {
      setEdited(false);
      if (propsOnSave) {
        propsOnSave({
          overview,
          submissionInstructions,
          keys,
          sortMetric,
          sortAsc,
          codeRequired,
          embargo,
        });
      }
    }, [
      codeRequired,
      embargo,
      keys,
      overview,
      propsOnSave,
      sortAsc,
      sortMetric,
      submissionInstructions,
    ]);

    const handleEmbargoChange = useCallback(
      (
        e: any,
        data: {
          value?: string | number | undefined;
        }
      ) => {
        if (data.value === 'on') {
          update({embargo: true});
        } else {
          update({embargo: false});
        }
      },
      [update]
    );

    const handleCodeRequiredChange = useCallback(
      (
        e: any,
        data: {
          value?: string | number | undefined;
        }
      ) => {
        if (data.value === 'on') {
          update({codeRequired: true});
        } else {
          update({codeRequired: false});
        }
      },
      [update]
    );

    const handleSortAscChange = useCallback(
      (
        e: any,
        data: {
          value?: string | number | undefined;
        }
      ) => {
        if (data.value === 'on') {
          update({sortAsc: true});
        } else {
          update({sortAsc: false});
        }
      },
      [update]
    );

    return (
      <>
        <Segment>
          <Header as="h3">Benchmark overview</Header>
          <InlineMarkdownEditor
            readOnly={false}
            rows={25}
            minRows={10}
            autosave={false}
            enableMarkdown={true}
            placeholder="Benchmark overview"
            serverText={overview}
            onBlur={value => update({overview: value})}
          />
        </Segment>

        <Segment>
          <Header as="h3">Submission instructions</Header>
          <InlineMarkdownEditor
            readOnly={false}
            rows={25}
            minRows={10}
            autosave={false}
            enableMarkdown={true}
            placeholder="Submission instructions"
            serverText={submissionInstructions}
            onBlur={value => update({submissionInstructions: value})}
          />
        </Segment>

        <Segment>
          <Header as="h3">Metrics</Header>
          <Dropdown
            className="run-tags-combobox"
            placeholder="Type to add a metric name"
            multiple
            fluid
            selection
            search
            allowAdditions
            options={keys.map(k => ({
              key: k,
              value: k,
              text: k,
            }))}
            value={keys}
            onChange={(e, {value}) => {
              const newKeys = value as string[]; // save because we configured opts
              update({keys: _.sortedUniq(newKeys)});
            }}
          />
          <Header as="h4">Ranking metric</Header>
          <Form>
            <Form.Group inline>
              <Form.Dropdown
                placeholder="Ranking metric"
                selection
                search
                options={keys.map(k => ({
                  key: k,
                  value: k,
                  text: k,
                }))}
                value={sortMetric}
                onChange={(e, {value}) => {
                  if (typeof value !== 'string') {
                    return;
                  }
                  update({sortMetric: value});
                }}
              />
              <Form.Radio
                label="Ascending"
                name="sortAsc"
                value="on"
                checked={sortAsc}
                onChange={handleSortAscChange}
              />
              <Form.Radio
                label="Descending"
                name="sortAsc"
                value="off"
                checked={!sortAsc}
                onChange={handleSortAscChange}
              />
            </Form.Group>
          </Form>
        </Segment>

        <Segment>
          <Header as="h3">Code hiding</Header>
          <Form.Field>
            <Form.Radio
              label="Allow users to hide code for their submitted runs"
              name="embargo"
              value="on"
              checked={embargo}
              onChange={handleEmbargoChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Radio
              label="Show code for all submitted runs"
              name="embargo"
              value="off"
              checked={!embargo}
              onChange={handleEmbargoChange}
            />
          </Form.Field>
        </Segment>

        <Segment>
          <Header as="h3">Require code</Header>
          <Form.Field>
            <Form.Radio
              label="Show a warning to the user when they attempt to submit a run without code"
              name="coderequired"
              value="on"
              checked={codeRequired}
              onChange={handleCodeRequiredChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Radio
              label="Allow submissions without code"
              name="coderequired"
              value="off"
              checked={!codeRequired}
              onChange={handleCodeRequiredChange}
            />
          </Form.Field>
        </Segment>
        {withSave && (
          <Button disabled={!edited} onClick={onSave}>
            Save
          </Button>
        )}
      </>
    );
  },
  {id: 'BenchmarkEditorFields', memo: true}
);

export default BenchmarkEditorFields;
