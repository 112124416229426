import {Button, Input} from 'semantic-ui-react';
import styled, {css} from 'styled-components';
import {WBIcon} from '@wandb/ui';
import WandbLoader from '../components/WandbLoader';
import WBLogo from '../components/WBLogo';
import * as globals from '../css/globals.styles';
import {MOBILE_WIDTH} from '../util/gallery';
import {Link, TargetBlank} from '../util/links';

const SPACING = 32;
const MOBILE_SPACING = 16;
const NAV_HEIGHT = 52;
const LOADING_STYLES = `
  filter: blur(5px);
  opacity: 0.7;
  pointer-events: none;
`;

export const Container = styled.div`
  padding: ${SPACING}px;
  max-width: 1085px;
  margin: 0 auto;

  @media (max-width: ${MOBILE_WIDTH}px) {
    background-color: ${globals.white};
    padding: 0 ${MOBILE_SPACING}px ${MOBILE_SPACING}px ${MOBILE_SPACING}px;
    margin-top: ${NAV_HEIGHT}px;
    min-height: calc(100vh - ${NAV_HEIGHT}px);
  }
`;

export const SectionTitle = styled.div<{
  marginTop?: boolean;
  marginBottom?: boolean;
}>`
  color: ${globals.gray700};
  font-size: 17px;
  text-transform: uppercase;
  ${props => props.marginTop && `margin-top: 28px;`}
  ${props => props.marginBottom && `margin-bottom: ${SPACING / 2}px;`}

  @media (max-width: ${MOBILE_WIDTH}px) {
    font-size: 16px;
  }
`;

export const Tabs = styled.div<{searchActive?: boolean}>`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${globals.gray500};
  margin: 0 -8px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin: 0 -${MOBILE_SPACING}px;
    overflow: auto;
    padding-bottom: 1px;
    border-bottom: none;
    ${props => props.searchActive && `visibility: hidden;`}

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 1px;
      background-color: ${globals.gray500};
    }
  }
`;

export const Tab = styled(Link)<{
  width: number | null;
  active: boolean;
  color?: string;
}>`
  &&&&& {
    position: relative;
    color: ${globals.textPrimary};
    cursor: pointer;
    margin: 0 ${SPACING / 2}px;
    padding: 8px 8px 8px 2px;
    font-size: 18px;
    white-space: nowrap;
    width: ${props => (props.width != null ? `${props.width}px` : `auto`)};

    &:first-child {
      margin-left: 8px;
    }

    ${props =>
      props.color &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -12px;
          margin: auto;
          background-color: ${props.color};
          height: 8px;
          width: 8px;
          border-radius: 50%;
        }
      `}

    ${props =>
      props.active &&
      css`
        font-weight: 600;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          height: 3px;
          background-color: ${globals.textPrimary};
        }
      `}

    @media (max-width: ${MOBILE_WIDTH}px) {
      margin: 0;
      padding: 14px ${MOBILE_SPACING}px;
      border-bottom: 1px solid ${globals.gray500};

      &:first-child {
        margin-left: 0;
      }

      &:before {
        display: none;
      }
    }
  }
`;

export const Cols = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 28px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 0;
  }
`;

export const LeftCol = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const RightCol = styled.div`
  flex-shrink: 0;
  margin-left: ${SPACING}px;
  width: 360px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

export const Box = styled.div<{
  padding?: number;
  hideInMobile?: boolean;
  loading?: boolean;
}>`
  position: relative;
  background-color: ${globals.white};
  border-radius: 8px;
  border: 1px solid ${globals.gray400};
  padding: ${props => props.padding ?? SPACING}px;
  ${props => props.loading && LOADING_STYLES}

  &:not(:first-child) {
    margin-top: ${SPACING / 2}px;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    ${props => props.hideInMobile && `display: none;`}

    border: none;
    padding: 0;
  }
`;

export const CreatePost = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: ${SPACING / 2}px;
`;

export const CreatePostLink = styled(TargetBlank)`
  &&&&& {
    color: ${globals.gray500};
    flex-grow: 1;
    line-height: 40px;
    padding: 0 ${SPACING / 2}px;
    background-color: ${globals.gray50};
    border-radius: 6px;
  }
`;

export const Search = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(WBIcon)`
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 0;
  bottom: 0;
  height: 24px;
  margin: auto;
  font-size: 24px;
  color: ${globals.gray500};
`;

export const SearchInput = styled(Input)`
  flex-grow: 1;
  height: 40px;
  margin-right: ${SPACING}px;

  &&&&& input {
    padding: 0 ${SPACING / 2}px 0 ${SPACING / 2 + 28}px;
    background-color: ${globals.gray50};
    border: none;
    border-radius: 6px;
  }
`;

export const Dropdown = styled.div<{width: number}>`
  display: flex;
  align-items: center;
  width: ${props => props.width}px;
  padding: 4px 0;
  cursor: pointer;
`;

export const DropdownIcon = styled(WBIcon)`
  font-size: 20px;
  margin-left: 2px;
  margin-top: 2px;
`;

const postWrapperStyles = css<{onClick?: () => void}>`
  display: block;
  padding: ${SPACING / 2}px;
  transition: transform 0.3s, box-shadow 0.3s;
  ${props => props.onClick != null && `cursor: pointer;`}

  &:hover {
    transform: scale(1.005);
    box-shadow: 1px 1px 14px rgb(0 0 0 / 8%);
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 0;

    &:not(:first-child) {
      padding-top: ${MOBILE_SPACING * 1.5}px;
    }

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;
export const PostWrapper = styled.div`
  ${postWrapperStyles}
`;
export const PostWrapperLink = styled(Link)`
  ${postWrapperStyles}
`;

export const Post = styled.div`
  position: relative;
  padding-right: 132px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding-right: 96px;
  }
`;

export const PostTitle = styled.div`
  color: ${globals.textPrimary};
  font-size: 20px;
  font-weight: 600;

  @media (max-width: ${MOBILE_WIDTH}px) {
    font-size: 17px;
  }
`;

export const postFlairStyles = css<{color?: string}>`
  &&&&& {
    color: ${props => (props.color ? globals.white : globals.textPrimary)};
    font-size: 14px;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 12px;
    background-color: ${props => props.color || globals.gray100};
    margin-right: 8px;
    vertical-align: top;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 12px;
      position: relative;
      top: 1px;
    }
  }
`;
export const PostFlair = styled.span`
  ${postFlairStyles}
`;
export const PostFlairLink = styled(Link)`
  ${postFlairStyles}
`;

export const PostMeta = styled.div`
  color: ${globals.gray500};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;

  a {
    color: ${globals.gray500};

    &:hover {
      color: ${globals.gray700};
    }
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    font-size: 15px;
  }
`;

export const DotSeparator = styled.div`
  border-radius: 50%;
  width: 2px;
  height: 2px;
  margin: 12px 8px 0 8px;
  background-color: ${globals.gray500};
  display: inline-block;
  vertical-align: top;
`;

export const PostImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 80px;
  object-fit: cover;
  border-radius: 4px;
`;

export const Ribbon = styled.img`
  position: absolute;
  top: 0;
  right: ${SPACING}px;
`;

export const FancyTitle = styled.h1`
  font-size: 34px;
  margin: -12px 0 8px;
  font-weight: 600;
`;

export const Paragraph = styled.p<{large?: boolean}>`
  font-size: ${props => (props.large ? 20 : 16)}px;
  margin-bottom: ${props => (props.large ? 12 : 8)}px;
`;

export const Tags = styled.div`
  margin-top: -8px;
  margin-right: -8px;
`;

export const Tag = styled(Link)<{active: boolean}>`
  font-size: 14px;
  display: inline-block;
  margin-top: 8px;
  margin-right: 8px;
  padding: 4px 14px;
  border-radius: 16px;
  background-color: ${globals.gray100};
  cursor: pointer;

  color: ${globals.textPrimary};
  &:hover {
    color: ${globals.textPrimary};
  }

  ${props =>
    props.active &&
    css`
      background-color: ${globals.gray400};
    `}
`;

export const MobileNav = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${NAV_HEIGHT}px;
  padding-top: ${NAV_HEIGHT}px;
  background-color: ${globals.gray900};
  z-index: 1;
  color: ${globals.white};

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: block;
  }
`;

export const MobileNavLogo = styled(WBLogo)`
  position: absolute;
  top: 0;
`;

export const MobileNavTitle = styled.h1`
  position: absolute;
  top: 0;
  left: 56px;
  font-size: 24px;
  line-height: ${NAV_HEIGHT}px;
  margin: 0;
`;

export const MobileNavMenuIcon = styled.div`
  &&&&& {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 24px;
    width: 32px;
    cursor: pointer;
  }
`;

export const MobileNavMenuIconBar = styled.div`
  background-color: ${globals.gray500};
  height: 2px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(1) {
    top: 0;
    bottom: 0;
  }
  &:nth-child(3) {
    bottom: 0;
  }
`;

export const MobileNavMenu = styled.div<{
  active?: boolean;
  searchActive?: boolean;
}>`
  display: ${props => (props.active ? `block` : `none`)};
  background-color: ${globals.gray800};
  max-height: calc(100vh - ${NAV_HEIGHT}px);
  overflow: auto;
`;

export const MobileNavMenuSection = styled.div<{
  noPadding?: boolean;
  hide?: boolean;
}>`
  position: relative;
  ${props =>
    !props.noPadding && `padding: ${MOBILE_SPACING}px ${MOBILE_SPACING * 2}px;`}
  ${props => props.hide && `display: none;`}

  &:not(:first-child) {
    border-top: 1px solid ${globals.gray100};
  }
`;

export const MobileNavMenuSectionTitle = styled.div`
  text-transform: uppercase;
  margin-top: ${MOBILE_SPACING * 2}px;

  &:first-child {
    margin-top: ${MOBILE_SPACING}px;
  }
`;

export const MobileNavMenuSearchIcon = styled(WBIcon)<{active?: boolean}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${MOBILE_SPACING * 2}px;
  margin: auto;
  font-size: 24px;
  height: 24px;
  margin-right: ${MOBILE_SPACING}px;
  cursor: pointer;
  ${props => props.active && `z-index: 1;`}
`;

export const MobileNavMenuSearchInput = styled(Input)`
  width: 100%;

  &&&&& input {
    color: ${globals.white};
    padding: 14px ${MOBILE_SPACING * 2}px 14px ${MOBILE_SPACING * 3 + 24}px;
    line-height: 24px;
    background-color: transparent;
    border: none;
  }
`;

export const MobileNavMenuCreatePostLink = styled(TargetBlank)`
  &&&&& {
    display: flex;
    align-items: center;
    color: ${globals.white};
    padding: 14px ${MOBILE_SPACING * 2}px;
  }
`;

export const MobileNavMenuCreatePostIcon = styled(WBIcon)`
  font-size: 24px;
  margin-right: ${MOBILE_SPACING}px;
`;

export const MobileNavMenuDropdowns = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${MOBILE_SPACING}px;
`;

export const MobileNavMenuLink = styled(Link)`
  &&&&& {
    display: block;
    color: ${globals.white};
    margin-top: ${MOBILE_SPACING}px;
  }
`;

export const Loader = styled(WandbLoader)<{top?: number; loading: boolean}>`
  &&&&& {
    position: relative;
    margin-bottom: -58px;
    ${props => props.top && `top: ${props.top}px;`}
    ${props => !props.loading && 'display: none;'}
  }
`;

export const ReportSection = styled.div<{loading: boolean}>`
  ${props => props.loading && LOADING_STYLES}
`;

export const SubscribeInputContainer = styled.div`
  display: flex;
  height: 40px;
`;

export const SubscribeInputMessageContainer = styled.div<{loggedIn?: boolean}>`
  position: relative;
  margin-right: 8px;
  ${props => props.loggedIn && 'display: none;'}
`;

export const SubscribeInput = styled(Input)<{invalid?: boolean}>`
  &&&&& {
    height: 40px;
    width: 195px;

    input {
      background-color: ${globals.gray50};
      border-radius: 6px;

      border: 1px solid
        ${props => (props.invalid ? globals.error : 'transparent')};
    }
  }
`;

export const SubscribeButton = styled(Button)`
  &&&&& {
    background-color: ${globals.gray300};
    width: 92px;
    padding: 10px 12px;
    margin: 0;
    color: ${globals.gray900};
    border: none;

    &:hover {
      background-color: ${globals.gray400};
    }
  }
`;

export const SubscribeInvalidMessage = styled.div<{invalid?: boolean}>`
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${globals.errorText};
  ${props => !props.invalid && 'display: none;'}
`;

export const SubscribeSuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: ${globals.successText};

  i {
    margin-right: 4px;
  }
`;

export const SubscribeSection = styled.div<{
  mobile?: boolean;
  mobileHidden?: boolean;
}>`
  font-size: 14px;
  font-weight: 600;

  ${props =>
    props.mobile &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${globals.white};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      text-align: center;
      padding: 8px 16px 16px 16px;

      transition: transform 1s;
      ${props.mobileHidden && `transform: translate3d(0, 100%, 0);`}

      @media (min-width: 401px) {
        font-size: 16px;
      }

      ${SubscribeInputContainer} {
        margin-top: 8px;
        justify-content: center;
      }

      ${SubscribeInvalidMessage} {
        margin-top: -4px;
      }

      ${SubscribeSuccessMessage} {
        margin-top: 8px;
        justify-content: center;
      }

      @media (min-width: ${MOBILE_WIDTH + 1}px) {
        display: none;
      }
    `}
`;
