import React from 'react';
import CheckoutModal from '../components/CheckoutModal';
import {Org, StripeElements, useSubscriptionPlans} from '../util/pricing';
import makeComp from '../util/profiler';
import UpgradeSubscriptionForm, {
  UpgradeSuccess,
} from './UpgradeSubscriptionForm';

type StandardPlanCheckoutModalProps = {
  org: Org;
  onTransactionCompleted: () => void;
  onClose: () => void;
};

const StandardPlanCheckoutModal: React.FC<StandardPlanCheckoutModalProps> =
  makeComp(
    props => {
      const {org, onClose} = props;
      const {planInfo, storagePlanID, trackingPlanID} = useSubscriptionPlans();

      if (planInfo == null || storagePlanID == null || trackingPlanID == null) {
        return null;
      }

      return (
        <StripeElements>
          <CheckoutModal
            planInfo={planInfo}
            defaultSeats={org.memberCount}
            onTransactionCompleted={props.onTransactionCompleted}
            onClose={onClose}
            renderForm={({plan, seats, onTransactionCompleted}) => (
              <UpgradeSubscriptionForm
                plan={plan}
                seats={seats}
                storagePlanID={storagePlanID}
                trackingPlanID={trackingPlanID}
                org={org}
                onSubscriptionCompleted={onTransactionCompleted}
              />
            )}
            renderSuccess={() => <UpgradeSuccess org={org} />}
          />
        </StripeElements>
      );
    },
    {
      id: 'StandardPlanCheckoutModal',
      memo: true,
    }
  );

export default StandardPlanCheckoutModal;
