import _ from 'lodash';

export const getCookie = (cookieName: string): string => {
  const cookieStrs = getCookieStrs();
  for (const cookieStr of cookieStrs) {
    const keyVal = cookieStrToKeyVal(cookieStr);
    if (keyVal == null) {
      continue;
    }
    if (keyVal[0] === cookieName) {
      return keyVal[1];
    }
  }
  return '';
};

export const getCookieBool = (key: string): boolean => {
  const cookieStrs = getCookieStrs();
  for (const cookieStr of cookieStrs) {
    const keyVal = cookieStrToKeyVal(cookieStr);
    if (keyVal == null) {
      continue;
    }
    if (keyVal[0] === key) {
      return true;
    }
  }
  return false;
};

export const getAllCookies = () => {
  const cookieStrs = getCookieStrs();
  const data: {[key: string]: string | string[]} = {};
  for (const cookieStr of cookieStrs) {
    const keyVal = cookieStrToKeyVal(cookieStr);
    if (keyVal == null) {
      continue;
    }
    const [key, val] = keyVal;
    if (data[key] == null) {
      data[key] = val;
    } else if (!_.isArray(data[key])) {
      data[key] = [data[key] as string, val];
    } else {
      (data[key] as string[]).push(val);
    }
  }
  return data;
};

const getCookieStrs = () => {
  const str = decodeURIComponent(document.cookie);
  return str
    .split(';')
    .filter(s => s !== '')
    .map(s => s.trim());
};

const cookieStrToKeyVal = (cookieStr: string) => {
  const sepI = cookieStr.indexOf('=');
  if (sepI < 1) {
    console.log('Invalid cookie', cookieStr);
    return null;
  }
  const key = cookieStr.slice(0, sepI);
  const val = cookieStr.slice(sepI + 1);
  return [key, val] as const;
};

const {host} = window.location;
const isLocalhost = host.includes('localhost');
const hostWithoutSubdomain = host.replace('app.', '');
const unsetExpiresValue = 'Thu, 01 Jan 1970 00:00:00 UTC';

let prodCookieAttrs = `domain=${hostWithoutSubdomain}; SameSite=strict; path=/; secure`;
let devCookieAttrs = `domain=${host}; SameSite=strict; path=/`;
if (isLocalhost) {
  prodCookieAttrs = '';
  devCookieAttrs = '';
}

export function setCookie(key: string, val: string, expires?: Date): void {
  for (const attrs of [prodCookieAttrs, devCookieAttrs]) {
    const cookie =
      expires == null
        ? `${key}=${val}; ${attrs}`
        : `${key}=${val}; expires=${expires} ${attrs}`;

    document.cookie = cookie;
  }
}

export function unsetCookie(key: string): void {
  for (const attrs of [prodCookieAttrs, devCookieAttrs]) {
    document.cookie = `${key}=; expires=${unsetExpiresValue}; ${attrs}`;
  }
}
