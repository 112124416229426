import classNames from 'classnames';
import produce from 'immer';
import * as _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Button, Checkbox, Popup, Tab} from 'semantic-ui-react';
import useResizeObserver from 'use-resize-observer';
import {useSampleAndQueryToTable} from '../components/Export';
import {
  QueryToRunsDataQueryParams,
  RunsDataQuery,
  toRunsDataQuery,
} from '../containers/RunsDataLoader';
import '../css/PanelRunsLinePlot.less';
import {RunQueryContext, RunsQueryContext} from '../state/runs/context';
import {useRunsData} from '../state/runs/hooks';
import {
  RunHistoryKeyInfo,
  RunHistoryKeyType,
  RunHistoryRow,
} from '../types/run';
import {timestampAfter, timestampBefore} from '../util/compare';
import {CHART_SAMPLES} from '../util/constants';
import docUrl from '../util/doc_urls';
import {Expression, metricsInExpression} from '../util/expr';
import * as Filters from '../util/filters';
import {
  getDefaultLegendTemplate,
  legendTemplateFieldNames,
} from '../util/legend';
import {SmoothingType} from '../util/math';
import * as Panels from '../util/panels';
import {
  AggregateCalculation,
  ChartAggOption,
  ChartAreaOption,
  convertLineToBar,
  getLinesFromData,
  LegendPosition,
  Line,
  Mark,
  overrideLineColors,
  overrideLineTitles,
  overrideLineWidths,
  overrideMarks,
  PlotFontSizeOrAuto,
  PlotType,
  prettyXAxisLabel,
  Timestep,
} from '../util/plotHelpers';
import makeComp from '../util/profiler';
import * as Query from '../util/queryts';
import * as RunHelpers from '../util/runhelpers';
import * as Run from '../util/runs';
import {isNotNullOrUndefined} from '../util/types';
import LabeledOption from './elements/LabeledOption';
import LegacyWBIcon from './elements/LegacyWBIcon';
import MetricsPicker from './elements/MetricsPicker';
import ModifiedDropdown from './elements/ModifiedDropdown';
import PanelError from './elements/PanelError';
import PanelTitle from './elements/PanelTitle';
import RangeInput from './elements/RangeInput';
import SmoothingInput from './elements/SmoothingInput';
import {PanelBankPanelContext} from './PanelBankSection';
import PanelChartOptions from './PanelChartOptions';
import PanelExpressionOptions, {
  getExpressionFields,
  parseExpressions,
} from './PanelExpressionOptions';
import PanelGroupingOptions from './PanelGroupingOptions';
import PanelLegend from './PanelLegend';
import * as S from './PanelRunsLinePlot.styles';
import PlotWarning from './PlotWarning';
import BarChart from './vis/BarChart';
import LinePlot, {DomainMaybe} from './vis/LinePlot';
import WandbLoader from './WandbLoader';

export const PANEL_TYPE = 'Run History Line Plot';
const DEFAULT_MAX_GROUP_RUNS = 100;
const ABSOLUTE_MAX_GROUP_RUNS = 1000;

// maximum metrics to get when using regex to pick metrics
const REGEX_METRIC_MAX_LEN = 10;

// if chart height is smaller than this hide the legend by default
const MIN_SHOW_LEGEND_CHART_HEIGHT = 240;
const MIN_SHOW_LEGEND_CHART_WIDTH = 250;

export const X_AXIS_LABELS: {[key: string]: string} = {
  _step: 'Step',
  _absolute_runtime: 'Relative Time (Wall)',
  _runtime: 'Relative Time (Process)',
  _timestamp: 'Wall Time',
};

export interface RunsLinePlotConfig {
  xLogScale?: boolean;
  yLogScale?: boolean;
  xAxis?: string;
  startingXAxis?: string;
  smoothingWeight?: number;
  smoothingType?: SmoothingType;
  useLocalSmoothing?: boolean;
  useGlobalSmoothingWeight?: boolean;
  ignoreOutliers?: boolean;
  showOriginalAfterSmoothing?: boolean; // Show the line and the smoothed line
  xAxisMin?: number;
  xAxisMax?: number;
  yAxisMin?: number;
  yAxisMax?: number;
  legendFields?: string[];
  legendTemplate?: string; // used to generate the default legend
  aggregate?: boolean; // user selected grouping
  aggregateMetrics?: boolean; // aggregate metrics into single metric
  groupBy?: string; // key to group by
  metrics?: string[]; // names of yAxis metrics
  metricRegex?: string; // can choose yAxis metrics by regex
  useMetricRegex?: boolean;
  yAxisAutoRange?: boolean;
  chartTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  limit?: number; // max number of runs or groups to show
  groupRunsLimit?: number;
  expressions?: string[];
  xExpression?: string; // expression for x axis
  plotType?: PlotType;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  colorEachMetricDifferently?: boolean; // if we have multiple metrics, override the run colors
  overrideSeriesTitles?: {[key: string]: string}; // For setting the specific names of lines
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  overrideMarks?: {[key: string]: Mark};
  overrideLineWidths?: {[key: string]: number};
  showLegend?: boolean; // Display legend in chart (default true)
  legendPosition?: LegendPosition;
  fontSize?: PlotFontSizeOrAuto;
}
type RunsLinePlotPanelProps = Panels.PanelProps<RunsLinePlotConfig>;

interface Range {
  min: number | null;
  max: number | null;
}

type LinePlotRunSet = {
  id: string;
  grouping: Query.Grouping | undefined;
  entityName: string | undefined;
  projectName: string | undefined;
};

function getRunSets(query: Query.Query): LinePlotRunSet[] {
  if (query.runSets == null) {
    return [];
  }
  return query.runSets.map(rs => ({
    id: rs.id,
    grouping: rs.grouping,
    entityName: rs.entityName,
    projectName: rs.projectName,
  }));
}

function runsLinePlotTransformQuery(
  query: Query.Query,
  config: RunsLinePlotConfig,
  xStepRange: Range | null,
  parsedExpressions: {
    expressions: Expression[] | undefined;
    xExpression: Expression | undefined;
  }
): RunsDataQuery {
  const singleRun = Boolean(query.runName);

  let result;
  if (singleRun) {
    result = toRunsDataQuery(query);
  } else {
    const queryToDataQueryParams: QueryToRunsDataQueryParams = {
      selectionsAsFilters: true,
    };

    if (config.metrics != null && config.metrics.length > 0) {
      const filters: Filters.Filter[] = config.metrics.map(metric => ({
        key: {section: 'keys_info', name: metric},
        op: '!=',
        value: null,
      }));
      queryToDataQueryParams.mergeFilters = Filters.Or(filters);
    }

    result = toRunsDataQuery(query, queryToDataQueryParams);
  }

  result.disabled = true;

  let legendFields = config.legendFields ?? [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(templateFields, legendFields);
    legendFields = legendFields.concat(extraLegendFields);
  }

  const displayFields = [
    ...legendFields.map(Run.keyFromString).filter(isNotNullOrUndefined),
    ...(query.grouping ?? []),
    ...(config.aggregate && config.groupBy
      ? [Run.key('config', config.groupBy)]
      : []),
  ];
  // We need to query grouping values for runsets if the runsets have grouping
  // since we do the grouping locally.
  for (const rs of query.runSets ?? []) {
    if (rs.grouping != null) {
      displayFields.push(...rs.grouping);
    }
  }

  const expressionFields = getExpressionFields(parsedExpressions);

  const extraFields = _.uniq([...displayFields, ...expressionFields]);

  result.configKeys = extraFields
    .filter(key => key.section === 'config')
    .map(key => key.name);
  result.summaryKeys = extraFields
    .filter(key => key.section === 'summary')
    .map(key => key.name);

  if (config.xAxis != null && config.metrics != null) {
    // We load the history data for the graphs
    // We sample values where an individual metric and the xAxis has values
    // Some users might prefer to sample history rows where all of the metrics
    // and the xAxis has a value, but this will cause some graphs to not display
    // if for example a user logs test data in one step and training data in another
    // and then wants to plot the two metrics in a single graph.
    result.disabled = false;
    result.history = true;

    const derivedXAxis = getDerivedXAxis(config);

    const yAxisExprKeys: string[] = [];
    for (const expr of parsedExpressions.expressions ?? []) {
      yAxisExprKeys.push(...metricsInExpression(expr));
    }
    const xAxisExprKeys: string[] =
      parsedExpressions.xExpression != null
        ? metricsInExpression(parsedExpressions.xExpression)
        : [];

    const xAxisHistoryKeys: string[] = []; // history keys we need besides the metric
    if (!getHasSystemMetrics(config)) {
      xAxisHistoryKeys.push('_step');
    }
    if (derivedXAxis === '_absolute_runtime') {
      // we calculate absolute wall time from the start of the run
      // we need both timestamp and runtime to do this
      xAxisHistoryKeys.push('_timestamp');
      xAxisHistoryKeys.push('_runtime');
    } else {
      // this is the normal case
      xAxisHistoryKeys.push(derivedXAxis);
    }
    xAxisHistoryKeys.push(...xAxisExprKeys);

    result.historySpecs = _.uniq([
      ...config.metrics,
      ...yAxisExprKeys,
      ...xAxisExprKeys,
    ]).map(metricKey => ({
      keys: _.uniq([...xAxisHistoryKeys, metricKey]),
      samples: CHART_SAMPLES,
      ...(xStepRange != null
        ? {
            minStep: xStepRange.min,
            maxStep: xStepRange.max,
          }
        : {}),
    }));

    result.page = {
      size: singleRun ? 1 : config.limit || 10,
    };

    if (Panels.isGrouped(query, config)) {
      // We need the metadata for grouping because we do it locally
      // TODO: move grouping to server
      // result.disableMeta = false;

      // optionally compute group statistics over all runs instead of sub-sampling
      result.page.size = config.groupRunsLimit || DEFAULT_MAX_GROUP_RUNS;
      // Disable grouping for this query, we'll do it ourselves.
      result.queries = result.queries.map(q => ({...q, grouping: []}));
    }
  }

  return result;
}

function getSelectedMetrics(
  metricRegex: string,
  keyInfo?: RunHistoryKeyInfo
): string[] {
  if (keyInfo == null) {
    return [];
  }

  let regex: RegExp;
  try {
    regex = new RegExp(metricRegex, 'g');
  } catch {
    return [];
  }

  const keys = _.keys(keyInfo.keys).filter(k => regex.test(k));
  keys.sort();
  return keys.slice(0, REGEX_METRIC_MAX_LEN);
}

// Max number of lines
function limitLines(
  props: RunsLinePlotPanelProps,
  config: RunsLinePlotConfig
): number {
  if (Panels.isSingleRun(props)) {
    return 10; // a single run might have multiple metrics or expressions
  }
  return config.limit || 10;
}

function warnMaxPlotLinesShowingLimit(
  maxRuns: number,
  plotUnit: string
): JSX.Element {
  return (
    <PlotWarning
      helpText={`Click edit (top right corner) to change the max number of ${plotUnit} displayed`}
      message={`Showing first ${maxRuns} ${plotUnit}`}
    />
  );
}

function warnRunSamplingWhenGroupedLimit(maxRunsSampled: number): JSX.Element {
  return (
    <PlotWarning
      helpText={`Click edit (top right corner) to change the number of runs sampled in group metrics`}
      message={`Computing group metrics from first ${maxRunsSampled} runs`}
    />
  );
}

function warnHistogramMultiRun(lines: Line[]): JSX.Element | undefined {
  if (lines.length === 0) {
    return undefined;
  }

  const firstRun = lines[0].run;
  return (
    <PlotWarning
      message={`Showing histogram for ${
        firstRun?.displayName || 'the first run'
      }`}
    />
  );
}

const MIN_STEP_EXTRA_MARGIN = 51;
const MAX_STEP_EXTRA_MARGIN = 2;

// History points are ALWAYS stored in ascending `_step` order,
// and sampling must always be done in a contiguous `_step` range.
// For monotonically increasing x-axis metrics, we want to do a re-sampling to ensure
// that we don't sample a `_step` range that has out-of-range x-axis values.
// This function calculates the range of `_step` values in which we should re-sample.
// We find the largest `_step` value for which the x-axis value is less than the minimum (left extreme of x-axis)
// and the smallest `_step` value for which the x-axis value is greater than the maximum (right extreme of x-axis).
// This should give us a `_step` range for which all the x-axis values are in the desired range.

// TODO(axel): Move this re-sampling behavior to the backend.
// May have to consider how it interacts with `runs-low` to avoid breaking anything.
function calcStepRange(
  data: Array<{
    keyInfo: RunHistoryKeyInfo;
    name: string;
    displayName: string;
    history: RunHistoryRow[];
  }>,
  historyKeyInfo?: RunHistoryKeyInfo,
  xAxis?: string,
  xMin?: number,
  xMax?: number,
  xExpression?: string
): DomainMaybe {
  const key = xAxis;
  const keyIsStep = key === '_step';
  const keyIsTimestamp = key === '_timestamp';
  if (key == null) {
    return [null, null];
  }
  if (xExpression != null) {
    return [null, null];
  }
  // We only re-sample when x-axis is monotonically increasing
  if (!historyKeyInfo?.keys[key]?.monotonic) {
    return [null, null];
  }

  let xMins: number[] = [];
  let xMaxs: number[] = [];

  if (xMin != null) {
    // Find the largest `_step` value with x-axis value less than the allowed range
    xMins = data
      .map(({history}) => {
        let largestStepValue: number = -Infinity;

        for (const runHistoryRow of history) {
          if (!_.isNumber(runHistoryRow[key])) {
            continue;
          }

          if (greaterThan(runHistoryRow[key], xMin)) {
            // We've crossed into the user-defined range, and every x-axis value after this one will be bigger than xMin

            if (
              !keyIsStep &&
              inRange(runHistoryRow[key]) &&
              _.isNumber(runHistoryRow._step) &&
              largestStepValue === -Infinity
            ) {
              // If we haven't encountered any other `_step` values, pick this one
              largestStepValue = runHistoryRow._step;
            }

            break;
          }

          if (!_.isNumber(runHistoryRow._step)) {
            continue;
          }

          largestStepValue = runHistoryRow._step;
        }

        return largestStepValue;
      })
      .filter(_.isFinite);
  }
  if (xMax != null) {
    // Find the smallest `_step` value with x-axis value greater than the allowed range
    xMaxs = data
      .map(({history}) => {
        let smallestStepValue: number = Infinity;

        for (let i = history.length - 1; i >= 0; i--) {
          const runHistoryRow = history[i];
          if (!_.isNumber(runHistoryRow[key])) {
            continue;
          }

          if (lessThan(runHistoryRow[key], xMax)) {
            // We've crossed into the user-defined range, and every x-axis value before this one will be smaller than xMax

            if (
              !keyIsStep &&
              inRange(runHistoryRow[key]) &&
              _.isNumber(runHistoryRow._step) &&
              smallestStepValue === Infinity
            ) {
              // If we haven't encountered any other `_step` values, pick this one
              smallestStepValue = runHistoryRow._step;
            }

            break;
          }

          if (!_.isNumber(runHistoryRow._step)) {
            continue;
          }

          smallestStepValue = runHistoryRow._step;
        }

        return smallestStepValue;
      })
      .filter(_.isFinite);
  }

  let minStep: number | null;
  let maxStep: number | null;
  if (keyIsStep) {
    // if key is step we can choose the narrowest range
    maxStep = _.min(xMaxs) ?? null;
    minStep = _.max(xMins) ?? null;
  } else {
    // since we don't know if the steps corresponding to the xranges overlap
    // we need to choose the widest range
    maxStep = _.max(xMaxs) ?? null;
    minStep = _.min(xMins) ?? null;
  }
  if (minStep != null) {
    minStep -= MIN_STEP_EXTRA_MARGIN;
  }
  if (maxStep != null) {
    maxStep += MAX_STEP_EXTRA_MARGIN;
  }

  return [minStep, maxStep];

  function inRange(v: number): boolean {
    if (xMin != null && lessThan(v, xMin)) {
      return false;
    }
    if (xMax != null && greaterThan(v, xMax)) {
      return false;
    }
    return true;
  }

  function lessThan(a: number, b: number): boolean {
    if (keyIsTimestamp) {
      return timestampBefore(a, b);
    }
    return a < b;
  }

  function greaterThan(a: number, b: number): boolean {
    if (keyIsTimestamp) {
      return timestampAfter(a, b);
    }
    return a > b;
  }
}

function isHistogramPlot(
  config: Pick<RunsLinePlotConfig, 'metrics'>,
  keyInfo?: RunHistoryKeyInfo
): boolean {
  // Return true if currently plotting a histogram

  if (
    keyInfo == null ||
    config.metrics == null ||
    config.metrics.length === 0
  ) {
    return false;
  }
  const keyTypes = RunHelpers.keyTypes(keyInfo);
  return keyTypes[config.metrics[0]] === 'histogram';
}

function isEmptyChart(
  config: Pick<RunsLinePlotConfig, 'metrics' | 'expressions'>
): boolean {
  const emptyMetrics = config.metrics == null || config.metrics.length === 0;
  const emptyExpressions =
    config.expressions == null || config.expressions.length === 0;
  return emptyMetrics && emptyExpressions;
}

function isBarPlot(props: RunsLinePlotPanelInnerProps): boolean {
  // When theres more than one line and all the visible lines are a single point
  // we show a bar plot.
  const {lines, zooming} = props;
  if (zooming) {
    return false;
  }

  const filteredLines = lines.filter(isValidLine);
  return (
    filteredLines.length > 1 && filteredLines.every(l => l.data.length === 1)
  );
}

function renderBarPlot(props: RunsLinePlotPanelInnerProps): JSX.Element {
  const {lines, config} = props;
  const filteredLines = lines.filter(isValidLine);
  const bars = filteredLines.map(convertLineToBar);

  return (
    <BarChart
      bars={bars}
      min={config.yAxisMin}
      max={config.yAxisMax}
      showAllLabels
    />
  );
}

function isValidLine(l: Line): boolean {
  return !l.aux && l.data.length > 0;
}

function defaultTitle(
  config: Pick<RunsLinePlotConfig, 'metrics' | 'expressions'>
): string {
  const metricsInUse = Boolean(config.expressions?.[0])
    ? config.expressions
    : config.metrics;

  return metricsInUse?.join(', ') ?? '';
}

type RunsLinePlotPanelInnerProps = RunsLinePlotPanelProps & {
  lines: Line[];
  lineCount: number;
  defaultLegendTemplate: string;
  setQueryStepRange: React.Dispatch<React.SetStateAction<Range>>;
  setZoomTimestep: React.Dispatch<
    React.SetStateAction<'seconds' | 'minutes' | 'hours' | 'days' | null>
  >;
  zooming: boolean;
  setZooming: React.Dispatch<React.SetStateAction<boolean>>;
};

function getHasSystemMetrics(
  config: Pick<RunsLinePlotConfig, 'metrics'>
): boolean {
  if (config.metrics == null) {
    return false;
  }
  return config.metrics.some(metric => metric.startsWith('system/'));
}

// system metrics charts can't have _step or null xAxes, so for those
// charts we fall back to _runtime as the default
function getDerivedXAxis(
  config: Pick<RunsLinePlotConfig, 'metrics' | 'xAxis'>
): string {
  if (getHasSystemMetrics(config)) {
    // system metrics have no '_step' to log by, so we use '_runtime'
    // as the default instead
    if (config.xAxis == null || config.xAxis === '_step') {
      return '_runtime';
    }

    return config.xAxis;
  }

  // all non system-metrics charts should use '_step' as the default
  if (config.xAxis == null) {
    return '_step';
  }

  return config.xAxis;
}

function getXAxisChoices(
  config: RunsLinePlotConfig,
  keyInfo: RunHistoryKeyInfo,
  keyTypes: {
    [key: string]: RunHistoryKeyType;
  }
): string[] {
  // Allow choosing any xAxis value that was logged along with any metric.
  let choicePool: string[] = [];
  if (config.metrics != null && config.metrics.length > 0) {
    for (const metric of config.metrics) {
      choicePool.push(...RunHelpers.keysInCommon(keyInfo, metric));
    }
  } else {
    choicePool = _.keys(keyInfo.keys);
  }

  // Limit xAxis choices to number types
  // Put the monotonic xAxis choices first
  // Don't show non-monotic choices if we're looking at a histogram
  const numberChoices = choicePool.filter(k => keyTypes[k] === 'number');
  const monotonicChoices = numberChoices.filter(k => keyInfo.keys[k].monotonic);
  const nonMonotonicChoices = numberChoices.filter(
    k => !keyInfo.keys[k].monotonic
  );

  return _.uniq([
    // _step is not available as an option for system metrics charts
    ...(getHasSystemMetrics(config) ? [] : ['_step']),
    '_absolute_runtime',
    '_runtime',
    '_timestamp',
    ...monotonicChoices,
    ...(isHistogramPlot(config, keyInfo) ? [] : nonMonotonicChoices),
    getDerivedXAxis(config),
  ]);
}

const yAxisNumberKeyBlacklist = new Set([
  '_absolute_runtime',
  '_runtime',
  '_step',
  '_timestamp',
]);
function isBlacklistedYAxisNumberKey(k: string): boolean {
  return yAxisNumberKeyBlacklist.has(k);
}

function getYAxisChoices(
  config: RunsLinePlotConfig,
  keyInfo: RunHistoryKeyInfo,
  keyTypes: {
    [key: string]: RunHistoryKeyType;
  },
  runsContext: Partial<RunsQueryContext>
): string[] {
  const derivedXAxis = getDerivedXAxis(config);
  // `_absolute_runtime` is a special derived xAxis that is calculated post-run
  const xAxis =
    derivedXAxis === '_absolute_runtime' ? '_runtime' : derivedXAxis;

  const yAxisChoices = RunHelpers.keysInCommon(keyInfo, xAxis).filter(k => {
    if (keyTypes[k] === 'number') {
      return !isBlacklistedYAxisNumberKey(k);
    }

    if (keyTypes[k] === 'histogram') {
      // We only allow selecting histograms on single-run pages and reports
      return runsContext.runId != null || runsContext.report != null;
    }

    return false;
  });
  // We may have values for the yAxis that are not in yAxisChoices
  // This happens if the user configures the plot, and then changes the query
  // For example on a runs page, visualizes a single run that has one set of metrics,
  // adds a plot, then switch the eyeball to a run with different metrics.
  yAxisChoices.push(...(config.metrics ?? []));

  return _.uniq(yAxisChoices);
}

type PlotTypeOption = {
  type: PlotType;
  displayName: string;
  iconName: string;
};

const plotTypeOptions: PlotTypeOption[] = [
  {
    type: 'line',
    displayName: 'Line plot',
    iconName: 'standard-line-plot',
  },
  {
    type: 'stacked-area',
    displayName: 'Area plot',
    iconName: 'area-line-plot',
  },
  {
    type: 'pct-area',
    displayName: 'Percent area plot',
    iconName: 'percent-line-plot',
  },
];

function renderPlotTypeSelection(
  config: RunsLinePlotConfig,
  updateConfig: (newConfig: RunsLinePlotConfig) => void
): JSX.Element {
  const plotType = config.plotType ?? 'line';
  return (
    <LabeledOption
      label="Chart Type"
      helpText="Switch between line plot, area plot, and percentage area"
      docUrl={docUrl.compareMetrics + '#plot-style'}
      option={
        <Button.Group>
          {plotTypeOptions.map(({type, displayName, iconName}) => (
            <Popup
              key={type}
              inverted
              size="mini"
              content={displayName}
              trigger={
                <Button
                  size="tiny"
                  className={classNames('wb-icon-button', 'only-icon', {
                    'action-button--active': plotType === type,
                  })}
                  onClick={() => updateConfig({plotType: type})}>
                  <LegacyWBIcon name={iconName} />
                </Button>
              }
            />
          ))}
        </Button.Group>
      }
    />
  );
}

// TODO(axel): This wrapper is only here because I'm not confident that some props are guaranteed to exist.
// Find out what's up and kill this wrapper.
const ConfigWrapper: React.FC<RunsLinePlotPanelInnerProps> = makeComp(
  props => {
    const {data, keyInfo} = props;

    if (data.histories == null || keyInfo == null) {
      return <p>No Histories</p>;
    }

    return <Config {...props} keyInfo={keyInfo} />;
  },
  {id: 'PanelRunsLinePlot.ConfigWrapper', memo: true}
);

type ConfigProps = Omit<RunsLinePlotPanelInnerProps, 'keyInfo'> & {
  keyInfo: NonNullable<RunsLinePlotPanelInnerProps['keyInfo']>;
};

const Config: React.FC<ConfigProps> = makeComp(
  props => {
    const {keyInfo, config, updateConfig} = props;
    const runsContext = useContext(RunQueryContext);

    const singleRun = Panels.isSingleRun(props);
    const renderingBarPlot = isBarPlot(props);

    const keyTypes = RunHelpers.keyTypes(keyInfo);

    const yAxisChoices = getYAxisChoices(
      config,
      keyInfo,
      keyTypes,
      runsContext
    );
    const xAxisChoices = getXAxisChoices(config, keyInfo, keyTypes);

    const xAxisOptions = xAxisChoices.map(k => {
      const notMonotonic =
        keyInfo.keys[k] != null && !keyInfo.keys[k].monotonic;

      let icon = 'wbic-ic-up-arrow';
      if (['_runtime', '_timestamp', '_absolute_runtime'].indexOf(k) > -1) {
        icon = 'calendar';
      } else if (notMonotonic) {
        icon = 'chart bar';
      }

      let text = X_AXIS_LABELS[k] || k;
      if (notMonotonic) {
        text += ' (Not Monotonically Increasing)';
      }

      return {
        icon,
        text,
        value: k,
        key: k,
      };
    });

    const usingExpressions =
      config.expressions != null &&
      config.expressions.length > 0 &&
      !(config.expressions.length === 1 && config.expressions[0] === '');

    // if we're using grouping we should say things like limit 10 groups
    // if not we should say limit 10 runs
    // This doesn't handle the case where there are groups and runs - it
    // just calls them groups.
    const displayGroupsOrRunsStr = Panels.isGrouped(props.pageQuery, config)
      ? 'group'
      : 'run';

    const dataTab = (
      <Tab.Pane as="div" className="form-grid">
        {renderingBarPlot && (
          <p className="hint-text">
            Showing a bar chart instead of a line chart because all logged
            values are length one.
          </p>
        )}
        <LabeledOption
          label="X"
          helpText="Use step, time or any variable logged with wandb.log as xaxis."
          docUrl={docUrl.compareMetrics + '#x-axis'}
          option={
            <S.OptionContainer>
              <ModifiedDropdown
                lazyLoad
                style={{flexGrow: 1}}
                placeholder="X Axis"
                search
                selection
                options={xAxisOptions}
                value={getDerivedXAxis(config)}
                onChange={(e, {value}) => {
                  props.setQueryStepRange({min: null, max: null});
                  updateConfig({
                    xAxis: value as string,
                  });
                }}
              />
            </S.OptionContainer>
          }
        />
        <LabeledOption
          label="Y"
          helpText="Plot any numeric metric logged with wandb.log."
          docUrl={docUrl.compareMetrics + '#y-axis-variables'}
          option={
            <S.OptionContainer>
              <MetricsPicker
                value={config.metrics}
                regexValue={config.metricRegex}
                options={yAxisChoices}
                keyTypes={keyTypes}
                regexInput={config.useMetricRegex ?? false}
                onRegexChange={(newRegex: string) => {
                  updateConfig({
                    metrics: getSelectedMetrics(newRegex, props.keyInfo),
                    metricRegex: newRegex,
                    useMetricRegex: true,
                  });
                }}
                onMetricsChange={(newMetrics: string[]) => {
                  let updateConfigMetrics = newMetrics;

                  // Histograms cannot be displayed alongside other metrics, including other histograms.
                  // If there is a histogram present, overwrite the entire list of metrics
                  // with the one just added by the user.
                  // This ensures that a histogram metric will never coexist with another metric.
                  if (newMetrics.some(k => keyTypes[k] === 'histogram')) {
                    updateConfigMetrics = [_.last(newMetrics)!];
                  }

                  updateConfig({
                    metrics: updateConfigMetrics,
                    useMetricRegex: false,
                  });
                }}
              />
            </S.OptionContainer>
          }
        />
        <LabeledOption
          label="X Axis"
          helpText="Fix the minimum and maximum values for the x-axis"
          docUrl={docUrl.compareMetrics + '#x-range-and-y-range'}
          option={
            <RangeInput
              disabled={
                isEmptyChart(config) ||
                (renderingBarPlot &&
                  config.xAxisMin == null &&
                  config.xAxisMax == null)
              }
              onMinChange={newVal => {
                // reset the query step range so that it can be recalculated on all of the data
                props.setQueryStepRange({min: null, max: null});
                updateConfig({xAxisMin: newVal});
              }}
              onMaxChange={newVal => {
                // reset the query step range so that it can be recalculated on all of the data
                props.setQueryStepRange({min: null, max: null});
                updateConfig({xAxisMax: newVal});
              }}
              minValue={config.xAxisMin}
              maxValue={config.xAxisMax}
              log
              logValue={config.xLogScale}
              onLogChange={newVal => {
                updateConfig({
                  xLogScale: newVal ?? false,
                });
              }}
            />
          }
        />

        <LabeledOption
          label="Y Axis"
          helpText="Fix the minimum and maximum values for the y-axis"
          docUrl={docUrl.compareMetrics + '#x-range-and-y-range'}
          option={
            <RangeInput
              disabled={isEmptyChart(config)}
              onMinChange={newVal => {
                updateConfig({yAxisMin: newVal});
              }}
              onMaxChange={newVal => {
                updateConfig({yAxisMax: newVal});
              }}
              minValue={config.yAxisMin}
              maxValue={config.yAxisMax}
              log
              logValue={config.yLogScale ?? false}
              onLogChange={newVal =>
                updateConfig({
                  yLogScale: newVal ?? false,
                })
              }
              ignoreOutliers
              ignoreOutliersValue={config.ignoreOutliers ?? false}
              onIgnoreOutliersChange={newVal =>
                updateConfig({
                  ignoreOutliers: newVal ?? false,
                })
              }
            />
          }
        />

        <LabeledOption
          label={'Smoothing'}
          helpText="Choose strategy for smoothing lines"
          docUrl={docUrl.compareMetrics + '#smoothing'}
          option={
            <S.OptionContainer>
              <SmoothingInput
                smoothingParam={config.smoothingWeight}
                smoothingType={config.smoothingType}
                smoothingTypePopupDropdown
                onChange={(newSmoothingParam, newSmoothingType) => {
                  updateConfig({
                    smoothingWeight: newSmoothingParam,
                    smoothingType: newSmoothingType,
                    useGlobalSmoothingWeight: false,
                    useLocalSmoothing: false,
                  });
                }}
              />
            </S.OptionContainer>
          }
        />
        {config.smoothingWeight !== 0 &&
          !Panels.isGrouped(props.pageQuery, config) && (
            <LabeledOption
              label={'Show Original'}
              helpText="Show lines before smoothing"
              option={
                <Checkbox
                  toggle
                  checked={config.showOriginalAfterSmoothing ?? true}
                  name="aggregate"
                  onChange={(e, value) =>
                    updateConfig({
                      showOriginalAfterSmoothing: value.checked,
                    })
                  }
                />
              }
            />
          )}
        {!singleRun && (
          <LabeledOption
            label={`Max ${displayGroupsOrRunsStr}s to show`}
            helpText="Maximum number of runs or groups to plot"
            docUrl={docUrl.compareMetrics + '#max-runs-groups'}
            option={
              <ModifiedDropdown
                lazyLoad
                className="no-wrap-options"
                value={limitLines(props, config)}
                style={{minWidth: 60}}
                selection
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 50, 100].map(
                  n => ({
                    value: n,
                    text: `${n} ${
                      // Display "run", "runs", "group" or "groups"
                      n > 1
                        ? displayGroupsOrRunsStr + 's'
                        : displayGroupsOrRunsStr
                    }`,
                  })
                )}
                onChange={(e, {value}) => {
                  if (typeof value === 'number') {
                    updateConfig({
                      limit: value,
                    });
                  }
                }}
              />
            }
          />
        )}
        {!renderingBarPlot &&
          (config.plotType != null ||
            !singleRun ||
            (config.metrics != null && config.metrics.length > 1)) &&
          renderPlotTypeSelection(config as RunsLinePlotConfig, updateConfig)}
      </Tab.Pane>
    );

    const groupingTab = (
      <Tab.Pane as="div" className="form-grid">
        <PanelGroupingOptions
          type={'lines'}
          config={config}
          disabled={isEmptyChart(config)}
          isGrouped={Panels.isGrouped(props.pageQuery, config) ?? false}
          updateConfig={updateConfig}
          defaultMaxGroupRuns={DEFAULT_MAX_GROUP_RUNS}
          absoluteMaxGroupRuns={ABSOLUTE_MAX_GROUP_RUNS}
          totalRuns={props.data.totalRuns}
          usingExpressions={usingExpressions}
          pageQuery={props.pageQuery}
          singleRun={singleRun}
        />
      </Tab.Pane>
    );

    const chartTab = (
      <Tab.Pane as="div" className="form-grid">
        <PanelChartOptions
          type={'lines'}
          config={config}
          updateConfig={updateConfig}
          pageQuery={props.pageQuery}
          defaultTitle={defaultTitle(config)}
          defaultXAxisTitle={prettyXAxisLabel(
            getDerivedXAxis(config),
            props.lines
          )}
        />
      </Tab.Pane>
    );

    const legendTab = (
      <Tab.Pane as="div" className="form-grid">
        <PanelLegend
          type={'lines'}
          config={config}
          updateConfig={updateConfig}
          pageQuery={props.pageQuery}
          defaultTitle={defaultTitle(config)}
          defaultXAxisTitle={prettyXAxisLabel(
            getDerivedXAxis(config),
            props.lines
          )}
          editableLegendSeries={props.lines.filter(
            l => !l.aux && l.name != null
          )}
          defaultLegendTemplate={props.defaultLegendTemplate}
          singleRun={singleRun}
        />
      </Tab.Pane>
    );

    const expressionsTab = (
      <Tab.Pane as="div" className="form-grid">
        <PanelExpressionOptions
          type={'lines'}
          config={config}
          updateConfig={updateConfig}
          availableExpressionVarNames={_.uniq([
            ...xAxisChoices,
            ...yAxisChoices,
          ])}
          exampleIdentifier={config.metrics?.[0] || 'x'}
        />
      </Tab.Pane>
    );

    const [tabActiveIndex, setTabActiveIndex] = useState(0);
    const expressionsPane = useMemo(
      () => ({
        menuItem: 'Expressions',
        render: () => expressionsTab,
      }),
      [expressionsTab]
    );
    const showingExpressionsTab = !renderingBarPlot;

    const settingsPanes = useMemo(
      () => [
        {
          menuItem: 'Data',
          render: () => dataTab,
        },
        {
          menuItem: 'Grouping',
          render: () => groupingTab,
        },
        {
          menuItem: 'Chart',
          render: () => chartTab,
        },
        {
          menuItem: 'Legend',
          render: () => legendTab,
        },
        ...(showingExpressionsTab ? [expressionsPane] : []),
      ],
      [
        dataTab,
        groupingTab,
        chartTab,
        legendTab,
        showingExpressionsTab,
        expressionsPane,
      ]
    );

    useEffect(() => {
      if (!showingExpressionsTab && tabActiveIndex > settingsPanes.length - 1) {
        setTabActiveIndex(settingsPanes.length - 1);
      }
      // eslint-disable-next-line
    }, [showingExpressionsTab]);

    return (
      <div className="chart-modal">
        <div className="chart-preview">
          <GraphWrapper {...props} />
        </div>
        <div className="chart-settings">
          <Tab
            activeIndex={tabActiveIndex}
            onTabChange={(e, {activeIndex}) => {
              if (typeof activeIndex === 'number') {
                setTabActiveIndex(activeIndex);
              }
            }}
            panes={settingsPanes}
            menu={{
              secondary: true,
              pointing: true,
              className: 'chart-settings-tab-menu',
            }}
          />
        </div>
      </div>
    );
  },
  {id: 'PanelRunsLinePlot.Config', memo: true}
);

// TODO(axel): This wrapper is only here because I'm not confident that some props are guaranteed to exist.
// Find out what's up and kill this wrapper.
const GraphWrapper: React.FC<RunsLinePlotPanelInnerProps> = makeComp(
  props => {
    if (props.data.histories == null) {
      return <p>No Histories</p>;
    }

    return <Graph {...props} />;
  },
  {id: 'PanelRunsLinePlot.GraphWrapper', memo: true}
);

type GraphProps = RunsLinePlotPanelInnerProps;

const Graph: React.FC<GraphProps> = makeComp(
  props => {
    const {lines, lineCount, config, loading} = props;

    const elementRef = useRef<HTMLDivElement>(null);
    const {width = 1, height = 1} = useResizeObserver<HTMLDivElement>({
      ref: elementRef,
    });

    const {historyKeyInfo} = useContext(PanelBankPanelContext);

    const memCalcStepRange = useCallback(memoize(calcStepRange), [
      calcStepRange,
    ]);

    const maxRuns = limitLines(props, config);

    let xDomain: DomainMaybe | undefined;
    if (config.xAxisMin != null || config.xAxisMax != null) {
      const xMin = _.isNaN(config.xAxisMin) ? null : config.xAxisMin;
      const xMax = _.isNaN(config.xAxisMax) ? null : config.xAxisMax;
      xDomain = [xMin ?? null, xMax ?? null];
    }

    let yDomain: DomainMaybe | undefined;
    if (config.yAxisMin != null || config.yAxisMax != null) {
      const yMin = _.isNaN(config.yAxisMin) ? null : config.yAxisMin;
      const yMax = _.isNaN(config.yAxisMax) ? null : config.yAxisMax;
      yDomain = [yMin ?? null, yMax ?? null];
    }

    let errorMessage: React.ReactChild | null = null;
    if (isEmptyChart(config)) {
      errorMessage = 'Select a metric to visualize in this line chart.';
    } else if (_.isEmpty(lines)) {
      if (config.metrics != null && config.metrics.length > 0) {
        errorMessage = (
          <>
            Select runs that logged {config.metrics[0]} <br />
            to visualize data in this line chart.
          </>
        );
      } else {
        errorMessage = <>No lines to visualize</>;
      }
    } else if (lines.every(l => _.isEmpty(l.data))) {
      errorMessage = (
        <>
          There's no data for the selected runs. <br />
          Try a different X axis setting. <br />
          Current X axis: {config.xAxis}
        </>
      );
    }
    // we want to be able to show several different warnings in the legend
    // if runs are grouped:
    // - A : warn user if there are more grouped plot lines than max runs (not all groups visible in the table will show up as lines on the chart)
    // - B : more totalRuns that groupRunsLimit (not all runs visible in the table will be used to compute the group summary metrics visible on the chart)
    // if runs are not grouped:
    // - more plot lines than max runs (not all runs from the table will show up in the chart)
    // we warn about B with higher priority than A because B is actually a tighter and more annoying restriction
    // (that is, the total number of runs in a grouped section exceeds the default limit of 100 much faster/much more often--and, crucially,
    // in a less transparent way--than the total number of groups in a section exceeds the smallest default limit of 20)

    const isHistogram = isHistogramPlot(config, props.keyInfo);
    const isGrouped = Panels.isGrouped(props.pageQuery, config);
    const maxGroupRuns = config.groupRunsLimit || DEFAULT_MAX_GROUP_RUNS;
    const totalRunsExceedsOne = props.data.totalRuns > 1;
    const totalRunsExceedsMaxGroupRuns = props.data.totalRuns > maxGroupRuns;
    const totalRunsExceedsMaxRuns = props.data.totalRuns > maxRuns;
    const lineCountExceedsMaxRuns = lineCount > maxRuns;

    const histogramMultiRunWarning = useMemo(
      () => warnHistogramMultiRun(lines),
      [lines]
    );
    const runSamplingWhenGroupedLimitWarning = useMemo(
      () => warnRunSamplingWhenGroupedLimit(maxGroupRuns),
      [maxGroupRuns]
    );
    const maxPlotLinesShowingLimitGroupsWarning = useMemo(
      () => warnMaxPlotLinesShowingLimit(maxRuns, 'groups'),
      [maxRuns]
    );
    const maxPlotLinesShowingLimitRunsWarning = useMemo(
      () => warnMaxPlotLinesShowingLimit(maxRuns, 'runs'),
      [maxRuns]
    );

    const legendPrefix: JSX.Element | undefined = useMemo(() => {
      if (isHistogram && totalRunsExceedsOne) {
        return histogramMultiRunWarning;
      }

      if (isGrouped) {
        if (totalRunsExceedsMaxGroupRuns) {
          // show user warning about group subsampling when the total runs visible in the table
          // exceed the number of runs sampled to compute group metrics in the chart
          return runSamplingWhenGroupedLimitWarning;
        }
        if (lineCountExceedsMaxRuns) {
          // show user warning about max groups if the total groups visible in the table exceed
          // the maximum number of runs displayed in the chart--note this happens less often and is
          // easier to notice than the previous case
          return maxPlotLinesShowingLimitGroupsWarning;
        }
      }

      if (totalRunsExceedsMaxRuns) {
        // show user warning about plot line limit: there are more runs visible in the table than can be displayed
        // as plot lines in the chart
        return maxPlotLinesShowingLimitRunsWarning;
      }

      return undefined;
    }, [
      isHistogram,
      isGrouped,
      totalRunsExceedsOne,
      totalRunsExceedsMaxGroupRuns,
      totalRunsExceedsMaxRuns,
      lineCountExceedsMaxRuns,
      histogramMultiRunWarning,
      runSamplingWhenGroupedLimitWarning,
      maxPlotLinesShowingLimitGroupsWarning,
      maxPlotLinesShowingLimitRunsWarning,
    ]);

    const singleRun = Panels.isSingleRun(props);

    const showLegend =
      (config.showLegend ?? !singleRun) &&
      height >= MIN_SHOW_LEGEND_CHART_HEIGHT &&
      width >= MIN_SHOW_LEGEND_CHART_WIDTH;

    const timestep = lines?.[0]?.timestep;

    return (
      <div className="chart" ref={elementRef}>
        <PanelTitle
          title={getTitleFromConfig(config)}
          searchQuery={props.searchQuery}
          fontSize={Panels.getFontSize(config.fontSize ?? 'auto', height)}
        />

        <div className="chart-content">
          {loading && (
            <WandbLoader size={lines.length === 0 ? 'huge' : 'small'} />
          )}
          {!loading && errorMessage != null ? (
            <PanelError message={errorMessage} />
          ) : isBarPlot(props) ? (
            renderBarPlot(props)
          ) : (
            <LinePlot
              svg={props.svg}
              entityName={props.data.entityName}
              projectName={props.data.projectName}
              xAxis={prettyXAxisLabel(getDerivedXAxis(config), props.lines)}
              yAxis={''}
              xAxisTitle={config.xAxisTitle}
              yAxisTitle={config.yAxisTitle}
              yScale={config.yLogScale ? 'log' : 'linear'}
              xScale={config.xLogScale ? 'log' : 'linear'}
              xDomain={xDomain}
              yDomain={yDomain}
              lines={lines}
              timestep={timestep}
              showLegend={showLegend}
              legendPosition={config.legendPosition ?? 'north'}
              fontSize={Panels.getFontSize(config.fontSize ?? 'auto', height)}
              disableRunLinks={props.disableRunLinks}
              legendPrefix={legendPrefix}
              ignoreOutliers={config.ignoreOutliers ?? false}
              zooming={props.zooming}
              parentWidth={width}
              singleRun={singleRun}
              zoomCallback={(xAxisMin?: number, xAxisMax?: number) => {
                if (xAxisMin == null && xAxisMax == null) {
                  props.setZoomTimestep(null);
                  props.setZooming(false);
                } else {
                  props.setZoomTimestep(timestep ?? null);
                  props.setZooming(true);
                }
                const xStepRange = memCalcStepRange(
                  props.data.histories.data,
                  historyKeyInfo,
                  getDerivedXAxis(config),
                  xAxisMin,
                  xAxisMax,
                  config.xExpression
                );
                props.setQueryStepRange({
                  min: xStepRange[0],
                  max: xStepRange[1],
                });
              }}
            />
          )}
        </div>
      </div>
    );
  },
  {id: 'PanelRunsLinePlot.Graph', memo: true}
);

export const PanelRunsLinePlot: React.FC<RunsLinePlotPanelProps> = makeComp(
  (props: RunsLinePlotPanelProps) => {
    // xStepRange is for sample min and max step in our history query
    // The calculation is a little complicated because we have different
    // xAxis but we can only query for ranges over step.
    const [queryStepRange, setQueryStepRange] = useState<Range>({
      min: null,
      max: null,
    });

    const [zoomTimestep, setZoomTimestep] = useState<Timestep | null>(null);

    const [zooming, setZooming] = useState(false);

    const {historyKeyInfo} = useContext(PanelBankPanelContext);

    const parsedExpressions = useMemo(
      () =>
        parseExpressions(props.config.expressions, props.config.xExpression),
      [props.config.expressions, props.config.xExpression]
    );

    const panelQuery = runsLinePlotTransformQuery(
      props.pageQuery,
      props.config,
      queryStepRange,
      parsedExpressions
    );
    const runsDataQuery = useRunsData(panelQuery);
    const {data} = runsDataQuery;
    const {loading} = data;

    // Recalculate the step offset.  This needs to go here because the data
    // needs to be loaded once to figure out what the step range should be.
    useEffect(() => {
      const stepRangeAlreadySet =
        queryStepRange.min != null || queryStepRange.max != null;
      if (loading || zooming || stepRangeAlreadySet) {
        return;
      }

      const [min, max] = calcStepRange(
        data.histories.data,
        historyKeyInfo,
        getDerivedXAxis(props.config),
        props.config.xAxisMin,
        props.config.xAxisMax
      );
      if (min !== queryStepRange.min || max !== queryStepRange.max) {
        setQueryStepRange({min, max});
      }
    }, [
      data.histories.data,
      loading,
      props.config,
      queryStepRange.max,
      queryStepRange.min,
      historyKeyInfo,
      zooming,
    ]);

    const config = props.config;
    const singleRun = Panels.isSingleRun(props);
    const runSets = getRunSets(props.pageQuery);

    const defaultLegendTemplate = getDefaultLegendTemplate(
      singleRun,
      Panels.isGrouped(props.pageQuery, config) ?? false,
      config.smoothingWeight != null && config.smoothingWeight !== 0,
      config.legendFields ?? [],
      config.metrics ?? [],
      runSets != null && runSets.length > 1,
      config.aggregateMetrics ?? false,
      config.plotType ?? 'line'
    );

    // LB: TODO Make this come from legend spec
    const aggregateCalculations: AggregateCalculation[] = ['minmax', 'stddev'];
    if (config.groupArea === 'stderr') {
      aggregateCalculations.push('stderr');
    } else if (config.groupArea === 'samples') {
      aggregateCalculations.push('samples');
    }

    const getLinesFromDataArgs = {
      groupBy: config.groupBy ?? 'None',
      maxRuns: limitLines(props, config),
      smoothingParam: config.smoothingWeight ?? 0.0,
      smoothingType: config.smoothingType ?? 'exponential',
      showOriginalAfterSmoothing: config.showOriginalAfterSmoothing ?? true,
      legendFields: config.legendFields,
      customRunColors: props.customRunColors,
      // Only pass in the part of runSets that we need. getLinesFromData memoizes
      // with deep equal.
      runSets,
      xAxis: getDerivedXAxis(config),
      yAxis: config.metrics ?? [],
      yLogScale: config.yLogScale,
      panelAggregate: config.aggregate,
      expressions: parsedExpressions.expressions,
      xExpression: parsedExpressions.xExpression,
      singleRun,
      legendTemplate: singleRun
        ? defaultLegendTemplate
        : config.legendTemplate ?? defaultLegendTemplate,
      entityName: data.entityName,
      projectName: data.projectName,
      plotType: config.plotType ?? 'line',
      zoomTimestep,
      groupLine: config.groupAgg ?? 'mean',
      groupArea: config.groupArea ?? 'minmax',
      aggregateCalculations,
      colorEachMetricDifferently:
        config.colorEachMetricDifferently ?? singleRun,
      aggregateMetrics: config.aggregateMetrics ?? false,
    };

    // We need to memoize this per component instance, rather than globally.
    const memGetLinesFromData = useCallback(
      memoize(getLinesFromData, (a: any[], b: any[]) => {
        // uncomment to see why getLinesFromData is being called
        // console.log(
        //   'PanelRunsLinePlot memoize',
        //   a[0] === b[0],
        //   a[1] === b[1],
        //   _.isEqual(a[2], b[2])
        // );
        // console.log(difference(a[2], b[2]));
        // This memoized comparison must be kept in sync with getLinesFromData.
        // Please let Shawn know if you need to change this.
        return a[0] === b[0] && a[1] === b[1] && _.isEqual(a[2], b[2]);
      }),
      [getLinesFromData]
    );

    const [lines, lineCount] = memGetLinesFromData(
      data.filtered,
      data.histories,
      getLinesFromDataArgs
    );

    const linesWithTitleOverride = useMemo(() => {
      if (config.overrideSeriesTitles == null) {
        return lines;
      }
      return overrideLineTitles(lines, config.overrideSeriesTitles, !singleRun);
    }, [lines, config.overrideSeriesTitles, singleRun]);

    const linesWithColorOverride = useMemo(() => {
      if (config.overrideColors == null) {
        return linesWithTitleOverride;
      }
      return overrideLineColors(
        linesWithTitleOverride,
        config.overrideColors,
        !singleRun
      );
    }, [linesWithTitleOverride, config.overrideColors, singleRun]);

    const linesWithMarkOverride = useMemo(() => {
      if (config.overrideMarks == null) {
        return linesWithColorOverride;
      }
      return overrideMarks(
        linesWithColorOverride,
        config.overrideMarks,
        !singleRun
      );
    }, [linesWithColorOverride, config.overrideMarks, singleRun]);

    const linesWithWidthOverride = useMemo(() => {
      if (config.overrideLineWidths == null) {
        return linesWithMarkOverride;
      }
      return overrideLineWidths(
        linesWithMarkOverride,
        config.overrideLineWidths,
        !singleRun
      );
    }, [linesWithMarkOverride, config.overrideLineWidths, singleRun]);

    if (props.configMode) {
      return (
        <ConfigWrapper
          {...props}
          data={data}
          lines={linesWithWidthOverride}
          lineCount={lineCount}
          defaultLegendTemplate={defaultLegendTemplate}
          setQueryStepRange={setQueryStepRange}
          setZoomTimestep={setZoomTimestep}
          zooming={zooming}
          setZooming={setZooming}
        />
      );
    }

    return (
      <GraphWrapper
        {...props}
        data={data}
        loading={loading}
        lines={linesWithWidthOverride}
        lineCount={lineCount}
        defaultLegendTemplate={defaultLegendTemplate}
        setQueryStepRange={setQueryStepRange}
        setZoomTimestep={setZoomTimestep}
        zooming={zooming}
        setZooming={setZooming}
      />
    );
  },
  {id: 'PanelRunsLinePlot', memo: true}
);

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state (the current zoom info). So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query.Query,
  config: RunsLinePlotConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query.Query, config: RunsLinePlotConfig) => {
  const query = runsLinePlotTransformQuery(
    pageQuery,
    config as RunsLinePlotConfig,
    null,
    parseExpressions(config.expressions, config.xExpression)
  );

  return useSampleAndQueryToTable(query, pageQuery, config);
};

const configSpec = {
  chartTitle: {
    editor: 'string' as const,
    displayName: 'Chart title',
  },
  smoothingWeight: {
    editor: 'slider' as const,
    displayName: 'Smoothing',
    min: 0,
    max: 0.99,
    step: 0.01,
    default: 0,
  },
  ignoreOutliers: {
    editor: 'checkbox' as const,
    displayName: 'Hide outliers',
    default: false,
  },
  xLogScale: {
    editor: 'checkbox' as const,
    displayName: 'X log scale',
    default: false,
  },
  yLogScale: {
    editor: 'checkbox' as const,
    displayName: 'Y log scale',
    default: false,
  },
  limit: {
    editor: 'dropdown' as const,
    displayName: 'Runs limit',
    default: 10,
    options: [
      {text: '1 run', value: 1},
      {text: '2 runs', value: 2},
      {text: '3 runs', value: 3},
      {text: '4 runs', value: 4},
      {text: '5 runs', value: 5},
      {text: '6 runs', value: 6},
      {text: '7 runs', value: 7},
      {text: '8 runs', value: 8},
      {text: '9 runs', value: 9},
      {text: '10 runs', value: 10},
      {text: '20 runs', value: 20},
      {text: '30 runs', value: 30},
      {text: '50 runs', value: 50},
      {text: '100 runs', value: 100},
    ],
  },
  plotType: {
    editor: 'icon-menu' as const,
    displayName: 'Plot type',
    options: [
      {icon: 'standard-line-plot', value: 'line'},
      {icon: 'area-line-plot', value: 'stacked-area'},
      {icon: 'percent-line-plot', value: 'pct-area'},
    ],
    default: 'line',
  },
};

function normalizeConfigUpdate(config: RunsLinePlotConfig): RunsLinePlotConfig {
  return produce(config, draft => {
    if (
      'metrics' in draft &&
      (draft.metrics == null || draft.metrics.length < 2)
    ) {
      draft.colorEachMetricDifferently = false;
    }
  });
}

function getTitleFromConfig(config: RunsLinePlotConfig): string {
  return config.chartTitle || defaultTitle(config);
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, RunsLinePlotConfig> = {
  type: PANEL_TYPE,
  Component: PanelRunsLinePlot,
  normalizeConfigUpdate,
  getTitleFromConfig,
  exportable: {
    image: true,
    csv: true,
    api: true,
  },
  configSpec,
  transformQuery: transformQuerySkip,
  useTableData,
  icon: 'panel-line-plot',
};
