import * as S from './soundcloud.styles';

import React from 'react';
import {Node, Element, Transforms} from 'slate';
import {RenderElementProps, ReactEditor, useSelected} from 'slate-react';
import {BlockWrapper} from './drag-drop';

export interface SoundCloud extends Element {
  type: 'soundcloud';
  html: string;
}

export const isSoundCloud = (node: Node): node is SoundCloud =>
  node.type === 'soundcloud';

export const SoundCloudElement: React.FC<
  RenderElementProps & {
    element: SoundCloud;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.EmbeddedIframeContainer
        selected={selected}
        dangerouslySetInnerHTML={{__html: element.html}}
      />
      {children}
    </BlockWrapper>
  );
};

const SOUNDCLOUD_REGEX =
  /^(?<url>https?:\/\/(www\.)?soundcloud\.com\/[^/]+\/[^/]+)\/?(\?[^#]*)?(#t=.+)?$/;

export const withSoundCloud = <T extends ReactEditor>(editor: T) => {
  const {isVoid, insertText} = editor;

  editor.isVoid = element => {
    return isSoundCloud(element) ? true : isVoid(element);
  };

  editor.insertText = text => {
    (async () => {
      const match = SOUNDCLOUD_REGEX.exec(text);
      if (match?.groups != null) {
        try {
          const response = await fetch('https://soundcloud.com/oembed', {
            method: 'POST',
            body: `format=json&url=${match.groups.url}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
          const {html} = await response.json();
          if (html && typeof html === 'string') {
            insertSoundCloudElement(html);
          }
          return;
        } catch (e) {
          console.error(`error embedding SoundCloud link:`, e);
        }
      }

      insertText(text);
    })();
  };

  return editor;

  function insertSoundCloudElement(html: string) {
    const node: Node = {
      type: 'soundcloud',
      html,
      children: [{text: ''}],
    };

    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
  }
};
