import * as React from 'react';
import {useContext, useState, useMemo, useCallback} from 'react';
// import {ErrorBoundary} from 'react-error-boundary';
import * as Panel2 from './panel';
import * as PanelLib from './panellib/libpanel';
import makeComp from '../../util/profiler';
import {Button, Popup, Modal} from 'semantic-ui-react';
import * as Types from '@wandb/cg/browser/model/types';
import {useBioFeatureFlagEnabled} from '../../util/featureFlags';
import {PanelExportUpdaterContext} from './PanelExportContext';
import {ComputeGraphViz} from './ComputeGraphViz';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import * as Op from '@wandb/cg/browser/ops';
import * as CG from '@wandb/cg/browser/graph';
import * as HL from '@wandb/cg/browser/hl';
import * as CGReact from '../../cgreact';
import copyToClipboard from 'copy-to-clipboard';
import * as S from './PanelComp.styles';
import {usePanelContext} from './PanelContext';
import Loader from '../WandbLoader';
import {useDeepMemo} from '../../state/hooks';
import _ from 'lodash';
// import ReactDOM from 'react-dom';
// import {envIsDev, envIsIntegration} from '../../config';

// For some reason the react-error-page takes 5s to render a single error,
// which is barely ok when you have one error.
// In Panel2 we may have a lot of errors, for example when all table cells
// have an error. When that happens in development, the react-error-page
// tries to render tons of errors, essentially hanging your browser. Set this
// to true to kill react with fire when there is an error, to prevent the hang.
// const STOP_REACT_ERROR_PAGE = true;

interface PanelCompProps {
  panelSpec: Panel2.PanelSpecNode;
  input: Panel2.PanelInput;
  inputType: Types.Type;
  context: Panel2.PanelContext;
  config: any;
  loading?: boolean;
  configMode: boolean;
  noBar?: boolean;
  updateConfig(partialConfig: Partial<any>): void;
  updateContext(partialConfig: Partial<Panel2.PanelContext>): void;
  updateInput?(partialInput: Partial<Panel2.PanelInput>): void;
}

// function ErrorFallback(props: {error?: Error}) {
//   if (STOP_REACT_ERROR_PAGE && (envIsDev || envIsIntegration)) {
//     ReactDOM.unmountComponentAtNode(document.getElementById('root') as any);
//     ReactDOM.render(
//       <div style={{padding: 24}}>
//         {'W&B Dev: Killed react to prevent hanging error page.'}
//       </div>,
//       document.getElementById('root')
//     );
//   }
//   const {error} = props;
//   return (
//     <div role="alert">
//       <p>Something went wrong</p>
//       <pre>{error?.message}</pre>
//     </div>
//   );
// }

export const PanelComp2 = makeComp(
  (props: PanelCompProps) => {
    // This is useful so I'm leaving it:
    // useTraceUpdate(props.panelSpec.id, props);
    return (
      // <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PanelComp2Inner {...props} />
      // </ErrorBoundary>
    );
  },
  {id: 'PanelComp', memo: true}
);

export const PanelComp2Inner = makeComp(
  (props: PanelCompProps) => {
    const {panelSpec} = props;
    if (PanelLib.isWithChild(panelSpec)) {
      if (props.configMode) {
        if (PanelLib.isTransform(panelSpec)) {
          return <ConfigTransformComp {...props} />;
        }
        const ConfigComp = panelSpec.ConfigComponent;
        if (ConfigComp == null) {
          return <PanelComp2 {...props} panelSpec={panelSpec.child} />;
        }
        return (
          <ControlWrapper panelProps={props}>
            <ConfigComp
              input={props.input}
              context={props.context}
              loading={props.loading}
              inputType={props.inputType}
              child={panelSpec.child}
              configMode={props.configMode}
              config={props.config}
              updateConfig={props.updateConfig}
              updateContext={props.updateContext}
              updateInput={props.updateInput}
            />
          </ControlWrapper>
        );
      }
      const Comp = panelSpec.Component;
      if (PanelLib.isTransform(panelSpec)) {
        return <RenderTransformComp {...props} />;
      }
      return (
        <ControlWrapper panelProps={props}>
          <Comp
            input={props.input}
            context={props.context}
            loading={props.loading}
            inputType={props.inputType}
            child={panelSpec.child}
            configMode={props.configMode}
            config={props.config}
            updateConfig={props.updateConfig}
            updateContext={props.updateContext}
            updateInput={props.updateInput}
          />
        </ControlWrapper>
      );
    } else {
      if (props.configMode) {
        const ConfigComp = panelSpec.ConfigComponent;
        if (ConfigComp == null) {
          return null;
        }
        return (
          <ControlWrapper panelProps={props}>
            <ConfigComp
              input={props.input}
              context={props.context}
              loading={props.loading}
              configMode={props.configMode}
              config={props.config}
              updateConfig={props.updateConfig}
              updateContext={props.updateContext}
              updateInput={props.updateInput}
            />
          </ControlWrapper>
        );
      }
      const Comp = panelSpec.Component;
      return (
        <ControlWrapper panelProps={props}>
          <Comp
            configMode={props.configMode}
            context={props.context}
            loading={props.loading}
            input={props.input}
            config={props.config}
            updateConfig={props.updateConfig}
            updateContext={props.updateContext}
            updateInput={props.updateInput}
          />
        </ControlWrapper>
      );
    }
  },
  {id: 'PanelComp2Inner'}
);

export const ConfigTransformComp = makeComp(
  (props: PanelCompProps) => {
    const {panelSpec, updateConfig, config} = props;
    const ConfigComp = panelSpec.ConfigComponent!;
    const baseConfig = useDeepMemo(_.omit(props.config, 'childConfig'));
    const newNode = useMemo(
      () =>
        HL.callOp(Panel2.panelIdToPanelOpName(panelSpec.id), {
          input: props.input.path,
          config: Op.constNodeUnsafe('any', baseConfig),
        }),
      [panelSpec.id, baseConfig, props.input.path]
    );
    const {frame} = usePanelContext();
    const {loading, result} = CGReact.useExpandedNode(newNode as any, frame);

    const childConfig = props.config?.childConfig;
    const childInputPath = useMemo(() => ({path: result as any}), [result]);
    const childUpdateConfig = useCallback(
      newConfig =>
        updateConfig({
          ...config,
          childConfig: {
            ...(childConfig ?? {}),
            ...newConfig,
          },
        }),
      [updateConfig, config, childConfig]
    );
    return (
      <>
        <ConfigComp
          input={props.input}
          context={props.context}
          loading={props.loading}
          inputType={props.inputType}
          child={(panelSpec as any).child}
          configMode={props.configMode}
          config={props.config}
          updateConfig={props.updateConfig}
          updateContext={props.updateContext}
          updateInput={props.updateInput}
        />
        {!loading && result.nodeType !== 'void' && (
          <PanelComp2
            {...props}
            input={childInputPath}
            inputType={result.type}
            config={childConfig}
            updateConfig={childUpdateConfig}
            panelSpec={(panelSpec as any).child}
          />
        )}
      </>
    );
  },
  {id: 'ConfigTransformComp'}
);

export const RenderTransformComp = makeComp(
  (props: PanelCompProps) => {
    const {panelSpec, updateConfig, config} = props;
    const baseConfig = useDeepMemo(_.omit(props.config, 'childConfig'));
    const newNode = useMemo(
      () =>
        HL.callOp(Panel2.panelIdToPanelOpName(panelSpec.id), {
          input: props.input.path,
          config: Op.constNodeUnsafe('any', baseConfig),
        }),
      [panelSpec.id, baseConfig, props.input.path]
    );
    const {frame} = usePanelContext();
    const {loading, result} = CGReact.useExpandedNode(newNode as any, frame);

    const childConfig = props.config?.childConfig;
    const childInputPath = useMemo(() => ({path: result as any}), [result]);
    const childUpdateConfig = useCallback(
      newConfig =>
        updateConfig({
          ...config,
          childConfig: {
            ...(childConfig ?? {}),
            ...newConfig,
          },
        }),
      [updateConfig, config, childConfig]
    );
    return loading ? (
      <Loader />
    ) : (
      <PanelComp2
        panelSpec={(panelSpec as any).child}
        input={childInputPath}
        context={props.context}
        loading={props.loading}
        inputType={result.type}
        configMode={props.configMode}
        config={childConfig}
        updateConfig={childUpdateConfig}
        updateContext={props.updateContext}
        updateInput={props.updateInput}
      />
    );
  },
  {id: 'RenderTransformComp'}
);

interface ControlWrapperProps {
  panelProps: PanelCompProps;
}

const ControlWrapper: React.FC<ControlWrapperProps> = ({
  panelProps,
  children,
}) => {
  const devMode = useBioFeatureFlagEnabled('weave-devpopup');
  const [editing, setEditing] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [hovering, setHovering] = useState(false);
  const ConfigComponent = panelProps.panelSpec.ConfigComponent;
  const canEdit = ConfigComponent != null;
  const canFullscreen =
    !panelProps.configMode &&
    'canFullscreen' in panelProps.panelSpec &&
    panelProps.panelSpec.canFullscreen;
  const canShowDevQueryPopup = devMode && !panelProps.configMode;
  const showControls =
    !panelProps.noBar && (canFullscreen || canShowDevQueryPopup);

  return showControls ? (
    <S.ControlWrapper
      hovering={hovering}
      onMouseEnter={() => {
        if (!hovering) {
          setHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (hovering) {
          setHovering(false);
        }
      }}
      canFullscreen={canFullscreen}>
      <S.ControlWrapperBar hovering={hovering}>
        {canEdit && (
          <div style={{cursor: 'pointer'}}>
            <LegacyWBIcon
              name="edit"
              style={{cursor: 'pointer', zIndex: 1000}}
              onClick={() => {
                setEditing(true);
              }}
            />
          </div>
        )}
        {canShowDevQueryPopup && <DevQueryPopup panelProps={panelProps} />}
        {canFullscreen && (
          <S.IconButton
            data-test="panel-fullscreen-button"
            onClick={() => setFullscreen(true)}
            style={{cursor: 'pointer'}}>
            <S.FullscreenButton />
          </S.IconButton>
        )}
      </S.ControlWrapperBar>
      <S.ControlWrapperContent canFullscreen={canFullscreen}>
        <Modal
          open={fullscreen}
          onClose={() => setFullscreen(false)}
          onOpen={() => setFullscreen(true)}>
          <Modal.Content
            style={{
              height: 'calc(90vh - 73px)',
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'stretch',
              alignItems: 'stretch',
            }}>
            {children}
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-test="panel-fullscreen-modal-close-button"
              onClick={() => setFullscreen(false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={editing}
          onClose={() => setEditing(false)}
          onOpen={() => setEditing(true)}>
          <Modal.Content>
            <div className="chart-modal">
              <div className="chart-preview">{children}</div>
              <div className="chart-settings">
                {ConfigComponent != null && (
                  <ConfigComponent
                    input={panelProps.input}
                    context={panelProps.context}
                    loading={panelProps.loading}
                    inputType={panelProps.inputType}
                    child={
                      PanelLib.isWithChild(panelProps.panelSpec)
                        ? panelProps.panelSpec.child
                        : undefined
                    }
                    configMode={panelProps.configMode}
                    config={panelProps.config}
                    updateConfig={panelProps.updateConfig}
                    updateContext={panelProps.updateContext}
                  />
                )}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-test="panel-fullscreen-modal-close-button"
              onClick={() => setEditing(false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        {children}
      </S.ControlWrapperContent>
    </S.ControlWrapper>
  ) : (
    <>{children}</>
  );
};
interface DevQueryPopupContentProps {
  panelProps: PanelCompProps;
}
const DevQueryPopupContent: React.FC<DevQueryPopupContentProps> = makeComp(
  props => {
    const [queryVisType, setQueryVisType] = useState<'string' | 'dag'>(
      'string'
    );
    const {panelProps} = props;
    const {addPanel} = useContext(PanelExportUpdaterContext);
    // Note, we simplify here! This means currently exports are simplified!
    const simplifyResult = CGReact.useSimplifiedNode(panelProps.input.path);
    const node = simplifyResult.loading ? CG.voidNode() : simplifyResult.result;
    return (
      <div
        style={{
          maxHeight: 600,
          maxWidth: 600,
          overflow: 'auto',
          fontSize: 14,
          whiteSpace: 'nowrap',
        }}>
        <div>
          <span>
            <span style={{fontWeight: 'bold', marginRight: 8}}>Query</span>
            <span
              style={{
                cursor: 'pointer',
                borderBottom:
                  queryVisType === 'string' ? '1px solid #888' : undefined,
              }}
              onClick={() => setQueryVisType('string')}>
              string
            </span>
            {' | '}
            <span
              style={{
                cursor: 'pointer',
                borderBottom:
                  queryVisType === 'dag' ? '1px solid #888' : undefined,
              }}
              onClick={() => setQueryVisType('dag')}>
              dag
            </span>
          </span>{' '}
          {node != null ? (
            queryVisType === 'string' ? (
              <pre style={{fontSize: 12}}>{HL.toString(node)}</pre>
            ) : (
              <ComputeGraphViz node={node} width={600} height={300} />
            )
          ) : (
            // <ComputeGraphViz node={node} width={600} height={300} />
            <span>TODO: Node not available</span>
          )}
        </div>
        <div
          onClick={() =>
            copyToClipboard(Types.toString(panelProps.input.path.type, false))
          }>
          <span style={{fontWeight: 'bold'}}>Input type</span>{' '}
          <pre style={{fontSize: 12}}>
            {Types.toString(panelProps.input.path.type)}
          </pre>
        </div>
        <div>
          <span style={{fontWeight: 'bold'}}>Panel</span>{' '}
          {panelProps.panelSpec.id}
        </div>
        {panelProps.config != null && (
          <div>
            <span style={{fontWeight: 'bold'}}>Config</span>{' '}
            {JSON.stringify(panelProps.config, undefined, 2)}
          </div>
        )}
        <Button
          style={{marginTop: 16, padding: '4px 8px'}}
          onClick={() => {
            addPanel({
              node,
              panelId: PanelLib.getStackIdAndName(panelProps.panelSpec).id,
              config: panelProps.config,
            });
          }}>
          New query
        </Button>
      </div>
    );
  },
  {id: 'DevQueryPopupContent'}
);

interface DevQueryPopupProps {
  panelProps: PanelCompProps;
}
const DevQueryPopup: React.FC<DevQueryPopupProps> = makeComp(
  props => {
    const [open, setOpen] = useState(false);
    const {panelProps} = props;
    return (
      <Popup
        trigger={
          // we need the <span> so that Popup can find the position of IconButton when it's wrapped
          // in a styled component -- tried <Ref> but it didn't work
          <span>
            <S.IconButton>
              <S.DevQueryIcon style={{transform: 'translateY(-4px)'}} />
            </S.IconButton>
          </span>
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        hoverable
        content={open && <DevQueryPopupContent panelProps={panelProps} />}
      />
    );
  },
  {
    id: 'DevQueryPopup',
  }
);
