import * as _ from 'lodash';
import produce, {setAutoFreeze} from 'immer';
import {getType} from 'typesafe-actions';
import {Transform} from './serverQuery';
import {HistoryKeyInfoQueryVars} from '../graphql/historyKeysQuery';

import * as Actions from './actions';
import * as Lib from './lib';
import * as ServerQueryServerDelta from './serverQuery_serverDelta';
import * as Types from './types';
import {cyrb53} from '../../util/hash';

export interface HistoryKeyInfoState {
  queryVars: HistoryKeyInfoQueryVars;
  historyKeySets: Array<{setID: string; set: {[key: string]: true}}>;
}

export interface RunsReducerState {
  queries: {[id: string]: Types.CachedQuery};
  queryErrors: {[id: string]: any};
  runs: {[id: string]: Types.CachedRun};

  // We store historyKeyInfo results here, which we use for query
  // merging
  historyKeyInfos: {
    [id: string]: HistoryKeyInfoState;
  };
}

// Globally turn off immer's auto-freeze. It's only on in dev, but it causes
// lots of perf slow downs in dev.
setAutoFreeze(false);

// This needs to be generic to handle different server query
// strategies. This is because we want to do the conversion from server
// result to user result as a reducer step. This way the actions coming
// into to redux will be server results (which are typically delta updates)
// as opposed to user query results (which are always the full result of a
// given query). This is much better for debugging when using the redux dev
// tools.

export const keySetID = (keys: string[]) => {
  return cyrb53(keys.sort().join('')).toString();
};

export function makeRunsReducer<SQ, SR>(
  serverQueryStrategy: Transform<SQ, SR>
) {
  return (
    outerState: RunsReducerState = {
      queries: {},
      queryErrors: {},
      runs: {},
      historyKeyInfos: {},
    },
    action: Actions.FullActionType<SR>
  ) => {
    let result = produce(outerState, draft => {
      switch (action.type) {
        case getType(Actions.registerQueryWithID): {
          draft.queries[action.payload.id] = {
            id: action.payload.id,
            query: action.payload.query,
            loading: true,
            totalRuns: 0,
            result: [],
            generation: 0,
            lastUpdatedAt: new Date(0).toISOString(),
          };
          draft.queryErrors[action.payload.id] = null;
          break;
        }
        case getType(Actions.updateQuery): {
          const query = draft.queries[action.payload.id];
          if (Lib.queryNeedsUpdate(action.payload.query, query)) {
            Lib.updateQuery(action.payload.query, query);
            Lib.collectGarbage(draft);
          }
          break;
        }
        case getType(Actions.unregisterQuery): {
          delete draft.queries[action.payload.id];
          delete draft.queryErrors[action.payload.id];
          Lib.collectGarbage(draft);
          break;
        }
        case getType(Actions.queryErrors): {
          action.payload.ids.forEach((ids, i) => {
            ids.forEach(id => {
              draft.queryErrors[id] = action.payload.errors[i];
            });
          });
          break;
        }
        case getType(Actions.updateRun): {
          const {id, vars} = action.payload;
          const run = draft.runs[id];
          if (run == null) {
            throw new Error('invalid state');
          }
          run.identity.data.displayName = vars.displayName;
          if (run.basic) {
            run.basic.data.notes = vars.notes;
          }
          break;
        }
        case getType(Actions.updateRunTags): {
          const {tagMap} = action.payload;
          Object.keys(tagMap).forEach(id => {
            const run = draft.runs[id];
            if (run != null && run.basic) {
              run.basic.data.tags = tagMap[id];
            }
          });
          break;
        }
        case getType(Actions.clearHistoryKeyInfo): {
          const {id} = action.payload;
          delete draft.historyKeyInfos[id];
          break;
        }
      }
    });
    if (action.type === Actions.QUERY_RESULTS_TYPE) {
      // applyQueryResults previously performed mutations and was applied
      // using immer. immer was taking > 300ms to process heavy results,
      // so applyQueryResults now does an immutable update.
      result = Lib.applyQueryResults(
        serverQueryStrategy,
        result,
        action.payload.mergedQueries,
        action.payload.results
      );
      result = produce(result, draft => Lib.collectGarbage(draft));
    } else if (action.type === getType(Actions.updateHistoryKeyInfo)) {
      // Don't use immer for this, it's super slow.

      const {id, queryVars, historyKeyInfo} = action.payload;
      const prevInfo = outerState.historyKeyInfos[id];
      let prevSets: HistoryKeyInfoState['historyKeySets'] = [];
      // As long as the project remains the same, we keep growing the list of
      // keysets we know about. The more we know about, the less aggressive we'll
      // be about merging.
      if (
        prevInfo != null &&
        prevInfo.queryVars.entityName === queryVars.entityName &&
        prevInfo.queryVars.projectName === queryVars.projectName
      ) {
        prevSets = prevInfo.historyKeySets;
      }
      const prevSetsLookup = _.fromPairs(prevSets.map(s => [s.setID, true]));
      for (const ks of historyKeyInfo.sets) {
        const setID = keySetID(ks.keys);
        if (prevSetsLookup[setID] == null) {
          const set: {[key: string]: true} = {};
          for (const k of ks.keys) {
            set[k] = true;
          }
          prevSets.push({
            setID,
            set,
          });
        }
      }
      result = {
        ...result,
        historyKeyInfos: {
          ...result.historyKeyInfos,
          [id]: {queryVars, historyKeySets: prevSets},
        },
      };
    }
    return result;
  };
}

export const STRATEGY = ServerQueryServerDelta.STRATEGY_GRAPHQL;

export default makeRunsReducer(STRATEGY);
