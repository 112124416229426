import React, {
  CSSProperties,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import _, {isEqual as _isEqual} from 'lodash';
import classNames from 'classnames';

import '../css/DragDrop.less';
import makeComp from '../util/profiler';

// TODO(views): type this to an actual generic ref?
export interface DragRef {
  id: string;
  [key: string]: any;
}
export interface DragData {
  [key: string]: any;
}

export interface DragDropState {
  mouseDownEvent: React.MouseEvent | null;
  dragData: DragData | null;
  dropRef: DragRef | null;
  dragRef: DragRef | null;
  dragStarted: boolean;
  dragging: boolean;

  // Firefox doesn't give you clientX and clientY on drag events (wtf)
  // So we add an event handler to document.dragover and store the result in the context
  clientXY: number[] | null;

  /* capture mouse position and mouse-overed element on shift and
       shift release to enable fancy shift-key based modifiers */
  shiftKey: boolean;
  mouseEventOnShift: React.MouseEvent | null;
  mouseEventOnShiftRelease: React.MouseEvent | null;
  dropRefOnShift: DragRef | null;
  dropRefOnShiftRelease: DragRef | null;
  elementOnShiftRelease: Element | null;

  setMouseDownEvent(e: React.MouseEvent | null): void;
  setDragData(data: DragData | null): void;
  setDropRef(ref: DragRef | null): void;
  setDragRef(ref: DragRef | null): void;
  setDragStarted(dragStarted: boolean): void;
  setDragging(dragStarted: boolean): void;

  /* shift key stuff */
  setShiftKey(shiftKey: boolean): void;
  setDropRefOnShift(ref: DragRef | null): void;
  setDropRefOnShiftRelease(ref: DragRef | null): void;
  setMouseEventOnShift(e: React.MouseEvent | null): void;
  setMouseEventOnShiftRelease(e: React.MouseEvent | null): void;
  setElementOnShiftRelease(e: Element | null): void;
}

const DragDropContext = createContext<DragDropState>({
  mouseDownEvent: null,
  dragData: null,
  dragRef: null,
  dropRef: null,
  dragStarted: false,
  dragging: false,

  clientXY: null,

  shiftKey: false,
  dropRefOnShift: null,
  dropRefOnShiftRelease: null,
  mouseEventOnShift: null,
  mouseEventOnShiftRelease: null,
  elementOnShiftRelease: null,

  // tslint:disable-next-line:no-empty
  setMouseDownEvent: () => {},
  // tslint:disable-next-line:no-empty
  setDragData: () => {},
  // tslint:disable-next-line:no-empty
  setDropRef: () => {},
  // tslint:disable-next-line:no-empty
  setDragRef: () => {},
  // tslint:disable-next-line:no-empty
  setDragStarted: () => {},
  // tslint:disable-next-line:no-empty
  setDragging: () => {},

  // tslint:disable-next-line:no-empty
  setShiftKey: () => {},
  // tslint:disable-next-line:no-empty
  setDropRefOnShift: () => {},
  // tslint:disable-next-line:no-empty
  setDropRefOnShiftRelease: () => {},
  // tslint:disable-next-line:no-empty
  setMouseEventOnShift: () => {},
  // tslint:disable-next-line:no-empty
  setMouseEventOnShiftRelease: () => {},
  // tslint:disable-next-line:no-empty
  setElementOnShiftRelease: () => {},
});
export default DragDropContext;

interface DragDropProviderProps {
  onDocumentDragOver?(ctx: DragDropState, e: DragEvent): void;
}

export const DragDropProvider: FC<DragDropProviderProps> = makeComp(
  ({onDocumentDragOver, children}) => {
    const [mouseDownEvent, setMouseDownEvent] =
      useState<React.MouseEvent | null>(null);
    const [dragData, setDragData] = useState<DragData | null>(null);
    const [dropRef, setDropRef] = useState<DragRef | null>(null);
    const [dragRef, setDragRef] = useState<DragRef | null>(null);
    const [dragStarted, setDragStarted] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [clientXY, setClientXY] = useState<number[] | null>(null);
    const [shiftKey, setShiftKey] = useState(false);
    const [dropRefOnShift, setDropRefOnShift] = useState<DragRef | null>(null);
    const [dropRefOnShiftRelease, setDropRefOnShiftRelease] =
      useState<DragRef | null>(null);
    const [mouseEventOnShift, setMouseEventOnShift] =
      useState<React.MouseEvent | null>(null);
    const [mouseEventOnShiftRelease, setMouseEventOnShiftRelease] =
      useState<React.MouseEvent | null>(null);
    const [elementOnShiftRelease, setElementOnShiftRelease] =
      useState<Element | null>(null);

    const contextVal: DragDropState = useMemo(
      () => ({
        mouseDownEvent,
        setMouseDownEvent,
        dragData,
        setDragData,
        dropRef,
        setDropRef,
        dragRef,
        setDragRef,
        dragStarted,
        setDragStarted,
        dragging,
        setDragging,
        clientXY,
        shiftKey,
        setShiftKey,
        dropRefOnShift,
        setDropRefOnShift,
        dropRefOnShiftRelease,
        setDropRefOnShiftRelease,
        mouseEventOnShift,
        setMouseEventOnShift,
        mouseEventOnShiftRelease,
        setMouseEventOnShiftRelease,
        elementOnShiftRelease,
        setElementOnShiftRelease,
      }),
      [
        mouseDownEvent,
        dragData,
        dropRef,
        dragRef,
        dragStarted,
        dragging,
        clientXY,
        shiftKey,
        dropRefOnShift,
        dropRefOnShiftRelease,
        mouseEventOnShift,
        mouseEventOnShiftRelease,
        elementOnShiftRelease,
      ]
    );
    const contextValRef = useRef(contextVal);
    contextValRef.current = contextVal;

    const setClientXYFromEvent = useMemo(
      () =>
        _.throttle((e: DragEvent) => {
          setClientXY([e.clientX, e.clientY]);
        }, 500),
      []
    );

    const clearDragRef = useCallback(
      (e: any) => {
        // This null check is important.
        // If you call e.preventDefault on all window.mouseup events, it breaks range slider inputs in Safari+Firefox
        if (dragRef != null) {
          e.preventDefault();
          e.stopPropagation();
          setDragRef(null);
        }
      },
      [dragRef]
    );

    useEffect(() => {
      window.addEventListener('mouseup', clearDragRef);
      return () => {
        window.removeEventListener('mouseup', clearDragRef);
      };
    }, [clearDragRef]);

    useEffect(() => {
      // Firefox doesn't give you clientX and clientY on drag events (wtf)
      // So we add an event handler to document.dragover and store the result in the context
      function handler(e: DragEvent) {
        setClientXYFromEvent(e);
        onDocumentDragOver?.(contextValRef.current, e);
      }
      document.addEventListener('dragover', handler);
      return () => {
        document.removeEventListener('dragover', handler);
      };
    }, [setClientXYFromEvent, onDocumentDragOver]);

    return (
      <DragDropContext.Provider value={contextVal}>
        {children}
      </DragDropContext.Provider>
    );
  },
  {id: 'DragDropProvider', memo: true}
);

interface DropTargetProps {
  children?: ReactNode;
  partRef: DragRef;
  className?: string;
  style?: CSSProperties;
  isValidDropTarget?(ctx: DragDropState): boolean; // default is () => true
  onDragOver?(ctx: DragDropState, e: React.DragEvent): void;
  onDragStart?(e: React.DragEvent): void;
  onDragEnter?(ctx: DragDropState, e: React.DragEvent): void;
  onDragLeave?(ctx: DragDropState, e: React.DragEvent): void;
  onDrop?(ctx: DragDropState, e: React.DragEvent): void;
  getClassName?(ctx: DragDropState): string | undefined; // function to dynamically generate class name based on context
  onClick?(e: React.MouseEvent): void;
  onMouseDown?(e: React.MouseEvent): void;
  onMouseUp?(e: React.MouseEvent): void;
}

export const DropTarget: FC<DropTargetProps> = makeComp(
  ({
    children,
    className,
    getClassName,
    style,
    partRef,
    onDragOver,
    onDragStart,
    onDragEnter,
    onDragLeave,
    onDrop,
    isValidDropTarget,
    onClick,
    onMouseDown,
    onMouseUp,
  }) => {
    const context = useContext(DragDropContext);
    const {
      clientXY,
      dragRef,
      dropRef,
      setDragRef,
      setDragData,
      setDragStarted,
      setDragging,
      setDropRef,
      setShiftKey,
    } = context;
    const validDropTarget = (ctx: DragDropState) =>
      isValidDropTarget != null ? isValidDropTarget(ctx) : true;
    return (
      <div
        style={style || undefined}
        className={
          getClassName ? getClassName(context) : className || undefined
        }
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onDragOver={e => {
          e.preventDefault();
          e.stopPropagation();
          if (dragRef != null && validDropTarget(context)) {
            if (dropRef == null || !_isEqual(partRef, dropRef)) {
              setDropRef(partRef);
            }
            if (onDragOver) {
              onDragOver(context, e);
            }
          }
        }}
        onDragStart={e => {
          if (onDragStart) {
            onDragStart(e);
          }
        }}
        onDragEnter={e => {
          e.preventDefault();
          e.stopPropagation();
          if (onDragEnter && dragRef && validDropTarget(context)) {
            onDragEnter(context, e);
          }
        }}
        onDragLeave={e => {
          e.stopPropagation();
          e.preventDefault();
          if (
            onDragLeave &&
            dragRef &&
            clientXY != null &&
            validDropTarget(context)
          ) {
            // Dragging over the target's children can trigger onDragLeave
            // So we use the target bounds to decide if we should actually trigger it
            // (We could also set pointer-events: none on children, but sometimes that causes other problems)
            const targetBounds = e.currentTarget.getBoundingClientRect();
            const [clientX, clientY] = clientXY;
            if (
              clientY < targetBounds.top ||
              clientY > targetBounds.bottom ||
              clientX < targetBounds.left ||
              clientX > targetBounds.right
            ) {
              onDragLeave(context, e);
            }
          }
        }}
        onDrop={e => {
          if (onDrop && dragRef && validDropTarget(context)) {
            e.stopPropagation();
            e.preventDefault();
            onDrop(context, e);
          }
          setDropRef(null);
          setDragData(null);
          setDragStarted(false);
          setDragging(false);
          setDragRef(null);
          setShiftKey(false);
        }}>
        {children}
      </div>
    );
  },
  {id: 'DropTarget', memo: true}
);

interface DragSourceProps {
  children: ReactNode;
  partRef: DragRef;
  data?: DragData;
  className?: string;
  style?: CSSProperties;
  callbackRef?: (el: HTMLDivElement) => void;
  onMouseUp?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onDragStart?(ctx: DragDropState, e: React.DragEvent): void;
  onDragEnd?(ctx: DragDropState, e: React.DragEvent): void;
}

export const DragSource: FC<DragSourceProps> = makeComp(
  ({
    children,
    className,
    style,
    partRef,
    data,
    callbackRef,
    onMouseUp,
    onDragStart,
    onDragEnd,
  }) => {
    const context = useContext(DragDropContext);
    const {
      clientXY,
      dropRef,
      dragRef,
      dragStarted,
      dragging,
      shiftKey,
      setDragData,
      setDropRef,
      setDragStarted,
      setDragging,
      setShiftKey,
      setDropRefOnShift,
      setDropRefOnShiftRelease,
      setMouseEventOnShift,
      setMouseEventOnShiftRelease,
      setElementOnShiftRelease,
    } = context;
    const selectedForDrag = _isEqual(partRef, dragRef);

    const scrollBy = useMemo(
      () =>
        _.throttle((px: number) => {
          window.scrollBy({top: px, behavior: 'auto'});
        }, 30),
      []
    );

    return (
      <div
        className={classNames(className, 'drag-source', {
          'selected-for-drag': selectedForDrag,
          'drag-started': selectedForDrag && dragStarted,
          dragging: selectedForDrag && dragging,
        })}
        ref={callbackRef}
        style={style}
        draggable={selectedForDrag}
        onMouseUp={onMouseUp}
        onDrag={e => {
          if (!dragging) {
            setDragging(true);
          }

          // Automatically scroll the window if you're dragging near the top or bottom of the page
          if (clientXY != null) {
            const clientY = clientXY[1];
            if (clientY < 100) {
              scrollBy((clientY - 100) / 5);
            }
            if (clientY > window.innerHeight - 100) {
              scrollBy((clientY - (window.innerHeight - 100)) / 5);
            }
          }

          if (e.shiftKey !== shiftKey) {
            if (e.shiftKey) {
              // shift key pressed
              setDropRefOnShift(dropRef);
              setMouseEventOnShift(_.clone(e));
            } else {
              if (clientXY != null) {
                const [clientX, clientY] = clientXY;
                // shift key released
                setDropRefOnShiftRelease(dropRef);
                setMouseEventOnShiftRelease(_.clone(e));
                setElementOnShiftRelease(
                  document.elementFromPoint(clientX, clientY)
                );
              }
            }
            setShiftKey(e.shiftKey);
          }
        }}
        onDragStart={e => {
          if (selectedForDrag) {
            setDragStarted(true);
            e.dataTransfer.setData('text', ''); // required for firefox
            if (data) {
              setDragData(data);
            }
            if (onDragStart) {
              onDragStart(context, e);
            }
          }
        }}
        onDragEnd={e => {
          e.stopPropagation();
          setDropRef(null);
          setDragData(null);
          setDragStarted(false);
          setDragging(false);
          setShiftKey(false);
          if (onDragEnd) {
            onDragEnd(context, e);
          }
        }}>
        {children}
      </div>
    );
  },
  {id: 'DragSource', memo: true}
);

interface DragHandleProps {
  partRef: DragRef;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  onMouseDown?(e: React.MouseEvent): void;
}

export const DragHandle: FC<DragHandleProps> = makeComp(
  ({children, className, style, partRef}) => {
    const context = useContext(DragDropContext);
    const {setDragRef, setMouseDownEvent} = context;
    return (
      <div
        className={'drag-drop-handle ' + (className || '')}
        style={style}
        onMouseDown={e => {
          setDragRef(partRef);
          e.persist(); // see https://reactjs.org/docs/events.html#event-pooling
          setMouseDownEvent(e);
        }}>
        {children}
      </div>
    );
  },
  {id: 'DragHandle', memo: true}
);
