import {OnParentScrollOption, OptionRenderer, WBMenuOption} from '@wandb/ui';
import * as _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import makeComp from '../../util/profiler';
import * as S from './WBSearchableSelect.styles';
import {WBSuggesterOptionFetcher} from './WBSuggester';

export type WBSearchableSelectOnSelectHandler = (
  value: string | number | undefined,
  extra: {option?: WBMenuOption}
) => void;

const DEFAULT_OPTION_RENDERER: OptionRenderer = ({
  option,
  hovered,
  selected,
}) => (
  <S.Option hovered={hovered}>
    <S.OptionIcon
      name={option.icon ?? (selected ? 'check' : 'blank')}></S.OptionIcon>
    {option.name ?? option.value}
  </S.Option>
);

export interface WBSearchableSelectProps {
  aew?: string;
  className?: string;
  /**
   * Can be a list of options or a function that fetches options for a given query.
   */
  options?: WBMenuOption[] | WBSuggesterOptionFetcher;
  value?: string | number;
  placeholder?: string;
  onSelect: WBSearchableSelectOnSelectHandler;
  optionRenderer?: OptionRenderer;
  /**
   * How to behave when the scrolling container is scrolled.
   * Can accept a custom handler.
   */
  onParentScroll?: OnParentScrollOption;
  /**
   * Load more options when you scroll to the bottom.
   * Requires that the fetched options implement `cursor` and `isLastResult`.
   */
  infiniteScroll?: boolean;
  /**
   * The number of items to be fetched at a time.
   */
  pageSize?: number;
  /**
   * The number of pixels above the bottom you can scroll before loading the next page.
   */
  scrollThreshold?: number;
  /**
   * For cases when the initial value isn't enough to render the selected option,
   * and you don't want to fetch it in the query.
   */
  initialOption?: WBMenuOption;
  /**
   * Set initial value and option from fetcher. Ignored if `initialOption` is provided.
   */
  fetchInitialValue?: boolean;
  /**
   * Don't display the 'x' button
   */
  disableClearable?: boolean;
}

const WBSearchableSelect: React.FC<WBSearchableSelectProps> = makeComp(
  ({
    className,
    options,
    value,
    placeholder = 'Select an option...',
    onSelect,
    optionRenderer = DEFAULT_OPTION_RENDERER,
    onParentScroll,
    infiniteScroll,
    pageSize,
    scrollThreshold,
    initialOption,
    fetchInitialValue,
    disableClearable,
  }) => {
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<WBMenuOption | null>(
      null
    );

    const findSelectedOption = useCallback(
      (opts: WBMenuOption[]) => {
        const selected = _.find(opts, o => o.value === value);
        if (selected != null) {
          setSelectedOption(selected);
        }
      },
      [value]
    );

    useEffect(() => {
      // If `initialOption` is provided, use it
      if (selectedOption == null && initialOption != null) {
        setSelectedOption(initialOption);
        return;
      }

      // If `value` exists and the selected option does not match, fix that
      if (value != null && Array.isArray(options)) {
        if (
          selectedOption == null ||
          (selectedOption != null && selectedOption.value !== value)
        ) {
          findSelectedOption(options);
        }
        return;
      }
    }, [value, findSelectedOption, options, initialOption, selectedOption]);

    useEffect(() => {
      if (
        fetchInitialValue &&
        initialOption == null &&
        selectedOption == null &&
        value == null &&
        typeof options === 'function'
      ) {
        (async () => {
          const result = await options(query, {count: 1});
          setSelectedOption(prev => {
            if (prev != null) {
              // If an option has already been selected, ignore query result
              return prev;
            }
            const opt = result.options[0] ?? null;
            if (opt != null) {
              onSelect(opt.value, {});
            }
            return opt;
          });
        })();
      }
      // eslint-disable-next-line
    }, []);

    const onAutocompleterSelect = useCallback(
      (v, extra) => {
        onSelect(v, extra);
        setQuery('');
        setOpen(false);
        setSelectedOption(extra.option);
      },
      [onSelect]
    );

    const clearable = !disableClearable && value != null;

    return (
      <S.StyledSuggester
        matchWidth
        options={options}
        selected={value}
        onSelect={onAutocompleterSelect}
        optionRenderer={optionRenderer}
        query={query}
        open={open}
        maxHeight={264}
        infiniteScroll={infiniteScroll}
        onParentScroll={onParentScroll}
        pageSize={pageSize}
        scrollThreshold={scrollThreshold}>
        {({inputRef}) => {
          return (
            <S.Wrapper className={className} clearable={clearable}>
              <S.SearchableInput
                open={open}
                ref={inputRef}
                value={query}
                title={selectedOption?.name}
                placeholder={value == null ? placeholder : ''}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 27: // esc
                      setOpen(false);
                      break;
                    case 40: // down
                      setOpen(true);
                      break;
                  }
                }}
                onFocus={() => {
                  setOpen(true);
                }}
                onClick={() => {
                  setOpen(true);
                }}
                onBlur={() => {
                  setOpen(false);
                  setQuery('');
                }}
                onChange={e => {
                  setOpen(true);
                  setQuery(e.target.value);
                }}></S.SearchableInput>
              {value != null && !query && (
                <S.ValuePreview focused={open}>
                  {selectedOption ? (
                    <>
                      {selectedOption.icon && (
                        <S.OptionIcon name={selectedOption.icon}></S.OptionIcon>
                      )}
                      <span>{selectedOption.name}</span>
                    </>
                  ) : (
                    <span>{value}</span>
                  )}
                </S.ValuePreview>
              )}
              {clearable && (
                <S.ClearIcon
                  name="close"
                  onClick={() => {
                    onSelect(undefined, {});
                    setSelectedOption(null);
                  }}></S.ClearIcon>
              )}
              <S.ExpandIcon name="down" open={open}></S.ExpandIcon>
            </S.Wrapper>
          );
        }}
      </S.StyledSuggester>
    );
  },
  {id: 'WBSearchableSelect', memo: true}
);

export default WBSearchableSelect;
