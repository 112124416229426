import * as React from 'react';
import TimeAgo from 'react-timeago';
import {Card} from 'semantic-ui-react';
import '../css/ProjectCard.less';
import {Project} from '../graphql/allProjectsQuery';
import {LinkNoCrawl} from '../util/links';
import makeComp from '../util/profiler';
import * as urls from '../util/urls';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {ProjectActionMenu} from './ProjectActionMenu';

interface ProjectCardProps {
  readOnly?: boolean;
  project: Project;
}

const ProjectCard = makeComp(
  (props: ProjectCardProps) => {
    const {readOnly, project} = props;
    return (
      <>
        <LinkNoCrawl
          className="ui card project-card"
          key={project.id}
          to={urls.project(project)}>
          <Card.Content>
            {!readOnly && <ProjectActionMenu project={project} />}
            <Card.Header>{project.name}</Card.Header>
            <Card.Meta>{project.entityName}</Card.Meta>
            <Card.Description>{project.description}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <LegacyWBIcon name="summary" />
            {project.totalRuns ? project.totalRuns : 'No'} run
            {project.totalRuns === 1 ? '' : 's'}
            {project.lastActive && (
              <span className="right floated">
                Last ran <TimeAgo date={project.lastActive + 'Z'} />
              </span>
            )}
          </Card.Content>
        </LinkNoCrawl>
      </>
    );
  },
  {id: 'ProjectCard'}
);
export default ProjectCard;
