import {WBMenuOnSelectHandler} from '@wandb/ui';
import classNames from 'classnames';
// A wrapper around react-table that adds our paging and search.
import _ from 'lodash';
import React, {useCallback, useLayoutEffect, useMemo, useState} from 'react';
import ReactTable, {
  Column,
  SortedChangeFunction,
  SortFunction,
  SortingRule,
} from 'react-table';
import 'react-table/react-table.css';
import {Button, Input, InputOnChangeData, Menu} from 'semantic-ui-react';
import '../css/WBReactTable.less';
import {usePrevious} from '../state/hooks';
import {fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import makeComp from '../util/profiler';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as S from './WBReactTable.styles';

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
const DEFAULT_PAGE_SIZE = 5;

export interface DataRow {
  searchString: string;
  row: any;
}

export interface WBReactTableProps {
  /**
   * keys are the table header
   * data is a dictionary that has string values of
   * each key for each row
   * searchResultColumns is function that returns a different set of column
   *     renderers to use when we need to highlight search results
   * onSearch, if supplied, fires when the user enters input into the search bar.
   *     No local search will be performed if specified.
   */
  className?: string;
  data: DataRow[];
  loading?: boolean;
  columns: Column[];
  searchResultColumns?: (searchQuery: string) => Column[];
  noPaging?: boolean;
  noSearch?: boolean;
  noHeader?: boolean;
  topHeader?: [number, number, string];
  pageSize?: number;
  onChangePageSize?: (pageSize: number) => void;
  hasNextPage?: boolean;
  onFetchNextPage?: () => void;
  onSearch?: (query: string) => void;
  fetchingNextPage?: boolean;
  pageSizeOptions?: number[];
  getTrProps?: any;
  sortable?: boolean;
  defaultSorted?: SortingRule[];
  onSortedChange?: SortedChangeFunction;
  extraActions?: JSX.Element;
  style?: React.CSSProperties;
  resizable?: boolean;
  dataTest?: string;
  searchPlaceholder?: string;
  renderHiddenRow?: (rowData: DataRow['row'], i: number) => React.ReactNode;
  defaultSortMethod?: SortFunction;
}

const WBReactTable: React.FC<WBReactTableProps> = makeComp(
  ({
    className,
    data,
    loading,
    columns,
    searchResultColumns,
    noPaging,
    noSearch,
    noHeader,
    pageSize: propsPageSize,
    onChangePageSize,
    hasNextPage = false,
    onFetchNextPage,
    onSearch,
    fetchingNextPage = false,
    pageSizeOptions: propsPageSizeOptions,
    getTrProps,
    sortable,
    defaultSorted,
    onSortedChange,
    extraActions,
    style,
    resizable,
    dataTest,
    searchPlaceholder,
    renderHiddenRow,
    defaultSortMethod,
  }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [awaitingMoreData, setAwaitingMoreData] = useState(false);

    const [statePageSize, setPageSize] = useState<number | undefined>();
    const changePageSize = useCallback(
      (newPageSize: number) => {
        setPageSize(newPageSize);
        onChangePageSize?.(newPageSize);
      },
      [onChangePageSize]
    );

    const pageSizeOptions = propsPageSizeOptions ?? DEFAULT_PAGE_SIZE_OPTIONS;

    const selectedData = useMemo(() => {
      if (!_.isEmpty(searchQuery)) {
        return fuzzyMatchWithMapping(data, searchQuery, d => d.searchString);
      } else {
        return data;
      }
    }, [data, searchQuery]);

    const modifiedColumns = useMemo(() => {
      if (!_.isEmpty(searchQuery) && searchResultColumns != null) {
        return searchResultColumns(searchQuery);
      } else {
        return columns;
      }
    }, [columns, searchQuery, searchResultColumns]);

    const handleSearch = useMemo(() => {
      return _.debounce(
        (
          e: React.ChangeEvent<HTMLInputElement>,
          {value}: InputOnChangeData
        ) => {
          if (onSearch != null) {
            onSearch(value);
          } else {
            setSearchQuery(value);
          }
        },
        200
      );
    }, [onSearch]);

    let pageSize = 1;
    if (statePageSize == null) {
      // pageSize is unset by the user so far
      if (selectedData.length > 0) {
        pageSize = propsPageSize ?? DEFAULT_PAGE_SIZE;
      }
    } else {
      pageSize = statePageSize;
    }
    if (noPaging) {
      pageSize = selectedData.length;
    }
    pageSize = Math.min(pageSize, selectedData.length);

    // if the dataset or page size changes, we must update
    // page to ensure that it remains within bounds
    const prevDataLength = usePrevious(selectedData.length);
    useLayoutEffect(() => {
      if (selectedData.length === 0) {
        return;
      }
      const lastPage = Math.floor((selectedData.length - 1) / pageSize);
      if (page > lastPage) {
        console.log('setting page back to ', lastPage);
        setPage(lastPage);
      }

      // go to the freshly fetched page after fetching
      if (awaitingMoreData && prevDataLength !== selectedData.length) {
        setAwaitingMoreData(false);
        setPage(prev => prev + 1);
      }
    }, [page, pageSize, selectedData.length, awaitingMoreData, prevDataLength]);

    const rows = useMemo(() => selectedData.map(x => x.row), [selectedData]);

    const renderHiddenRows = useCallback(
      (startRow: number, endRow: number) => {
        if (renderHiddenRow == null) {
          return null;
        }
        const hiddenRows: typeof rows = [];
        for (let i = 0; i < rows.length; i++) {
          if (i >= startRow && i < endRow) {
            continue;
          }
          hiddenRows.push(rows[i]);
        }
        return hiddenRows.map(renderHiddenRow);
      },
      [rows, renderHiddenRow]
    );

    return (
      <div
        className={classNames('wb-react-table', className)}
        data-test={dataTest}
        style={style ?? {}}>
        <ReactTable
          // className={striped ? '-striped' : ''}
          loading={loading}
          data={rows}
          columns={modifiedColumns}
          page={page}
          pageSize={pageSize}
          resizable={resizable ?? false}
          pageSizeOptions={pageSizeOptions}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPageJump={false}
          sortable={sortable}
          defaultSorted={defaultSorted}
          defaultSortMethod={defaultSortMethod}
          onSortedChange={onSortedChange}
          getTrProps={getTrProps}>
          {(state, makeTable, instance) => {
            return (
              <>
                {!noHeader && (
                  <Menu secondary style={{margin: '0px', overflowX: 'auto'}}>
                    {!noSearch && (
                      <Menu.Item>
                        <Input
                          className="searchbox"
                          icon={{
                            className: 'wbic-ic-search',
                          }}
                          iconPosition="left"
                          placeholder={searchPlaceholder ?? 'Search'}
                          onChange={handleSearch}
                        />
                      </Menu.Item>
                    )}
                    {!noPaging && (
                      <Menu.Menu position="right">
                        {extraActions && (
                          <Menu.Item>
                            <Button.Group>{extraActions}</Button.Group>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <S.RowCountSelectWrapper>
                            <S.RowCountSelect
                              menuTheme="light"
                              menuFontSize={13}
                              options={state.pageSizeOptions.map(size => ({
                                name: `${size}`,
                                value: size,
                                icon: null,
                              }))}
                              value={pageSize}
                              displayedValue={`${
                                ((state.page || 0) + 1) * pageSize -
                                (pageSize - 1)
                              }-${
                                (state.page || 0) + 1 >
                                  Math.floor(selectedData.length / pageSize) &&
                                selectedData.length % pageSize !== 0
                                  ? selectedData.length
                                  : ((state.page || 0) + 1) * pageSize
                              }`}
                              autoMenuWidth
                              onSelect={changePageSize as WBMenuOnSelectHandler}
                            />
                            {' of '}
                            {selectedData.length}
                            {hasNextPage && '+'}
                          </S.RowCountSelectWrapper>
                        </Menu.Item>
                        <Menu.Item>
                          <S.PageNav>
                            <Button.Group>
                              <Button
                                size="tiny"
                                className="wb-icon-button only-icon"
                                disabled={
                                  !state.canPrevious || fetchingNextPage
                                }
                                onClick={() => setPage(prev => prev - 1)}>
                                <LegacyWBIcon name="previous" />
                              </Button>
                              <Button
                                size="tiny"
                                className="wb-icon-button only-icon"
                                disabled={
                                  !(
                                    state.canNext ||
                                    (onFetchNextPage != null && hasNextPage)
                                  ) || fetchingNextPage
                                }
                                loading={fetchingNextPage}
                                onClick={() => {
                                  if (state.canNext) {
                                    setPage(prev => prev + 1);
                                  } else if (
                                    onFetchNextPage != null &&
                                    hasNextPage
                                  ) {
                                    onFetchNextPage();
                                    setAwaitingMoreData(true);
                                  }
                                }}>
                                <LegacyWBIcon
                                  name="next"
                                  style={{
                                    visibility: fetchingNextPage
                                      ? 'hidden'
                                      : 'visible',
                                  }}
                                />
                              </Button>
                            </Button.Group>
                          </S.PageNav>
                        </Menu.Item>
                      </Menu.Menu>
                    )}
                  </Menu>
                )}
                {makeTable()}
                {renderHiddenRows(state.startRow, state.endRow)}
              </>
            );
          }}
        </ReactTable>
      </div>
    );
  },
  {id: 'WBReactTable', memo: true}
);

export default WBReactTable;
