import gql from 'graphql-tag';
import {History} from 'history';
import * as _ from 'lodash';
import React, {FC, useState, useCallback, useRef} from 'react';
import {Query} from 'react-apollo';
import {Sparklines, SparklinesLine} from 'react-sparklines';
import {
  Button,
  Card,
  Container,
  Dropdown,
  Header,
  Icon,
  Input,
  Loader,
  Modal,
} from 'semantic-ui-react';
import {Tag} from '../components/Tags';
import {TargetBlank} from '../util/links';
import makeComp from '../util/profiler';

const h = 60 * 60 * 1000; // 1 hour in MS
const LAST_HOUR_MS = h;
const LAST_DAY_MS = 24 * h;
const LAST_WEEK_MS = 7 * 24 * h;
const LAST_MONTH_MS = 30 * 24 * h;

export const TRENDING_REPO_QUERY = gql`
  query TrendingRepos($from: DateTime!) {
    trendingRepos(from: $from) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      schema
      edges {
        node {
          row
        }
      }
    }
  }
`;
interface TrendingRepoProps {
  history: History; // browser history
}

const TrendingRepos: FC<TrendingRepoProps> = makeComp(
  ({history}) => {
    const [filter, setFilter] = useState('');
    const [filterDisplay, setFilterDisplay] = useState('');
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState('all');
    const [timeWindowMS, setTimeWindowMS] = useState(LAST_DAY_MS);

    const topics = useRef<string[]>([]);

    const parseRow = useCallback((row: any) => {
      // See query.go#TrendingRepos
      const sparkLine = [];
      for (let i = 0; i < row[9].length; i++) {
        if (i < row[9].length - 1) {
          sparkLine.push(row[9][i]);
        }
      }
      return {
        repoUrl: row[0],
        repoName: row[0].split('github.com/').pop(),
        sparkLine,
        project: row[1],
        language: row[2],
        starChange: row[3],
        starCount: row[4],
        watcherCount: row[5],
        forkCount: row[6],
        topics: row[7],
        description: row[8] || '',
      };
    }, []);

    const fuzzyMatch = useCallback((query: string, source: string) => {
      return source.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }, []);

    const searchRow = useCallback(
      (repo: any) => {
        if (language !== 'all') {
          if (
            !(
              fuzzyMatch(language, repo.repoName) ||
              repo.topics.find((t: string) => fuzzyMatch(language, t))
            )
          ) {
            return false;
          }
        }
        if (filter) {
          if (fuzzyMatch(filter, repo.description)) {
            return true;
          }
          if (fuzzyMatch(filter, repo.repoName)) {
            return true;
          }
          if (repo.topics.find((t: string) => fuzzyMatch(language, t))) {
            return true;
          }
          return false;
        }
        return true;
      },
      [filter, fuzzyMatch, language]
    );

    const timeOptions = [
      {
        key: 'lastHour',
        value: LAST_HOUR_MS,
        text: 'Last hour',
      },
      {
        key: 'lastDay',
        value: LAST_DAY_MS,
        text: 'Last day',
      },
      {
        key: 'lastWeek',
        value: LAST_WEEK_MS,
        text: 'Last week',
      },
      {
        key: 'lastMonth',
        value: LAST_MONTH_MS,
        text: 'Last month',
      },
    ];
    const languageOptions = [
      {
        key: 'all',
        value: 'all',
        text: 'All Frameworks',
      },
      {
        key: 'tensorflow',
        value: 'tensorflow',
        text: 'Tensorflow',
      },
      {
        key: 'pytorch',
        value: 'pytorch',
        text: 'PyTorch',
      },
      {
        key: 'keras',
        value: 'keras',
        text: 'Keras',
      },
    ];
    return (
      <Container className="projects-list-page" style={{paddingBottom: 20}}>
        <Header style={{textAlign: 'center'}} size="huge">
          Trending ML Repos
          <Header.Subheader>
            Browse popular deep learning projects
          </Header.Subheader>
        </Header>
        <div style={{paddingBottom: 10}}>
          <Input
            icon="search"
            value={filterDisplay}
            placeholder="Search..."
            onChange={(e, {value}) => {
              setFilterDisplay(value);
              _.debounce(() => setFilter(value), 200)();
            }}
          />
          <Dropdown
            inline
            style={{marginLeft: 10}}
            options={timeOptions}
            value={timeWindowMS}
            onChange={(e, {value}) => setTimeWindowMS(value as number)}
          />
          <Dropdown
            inline
            style={{marginLeft: 10}}
            options={languageOptions}
            value={language}
            onChange={(e, {value}) => setLanguage((value as string) || 'all')}
          />
        </div>
        <Query
          query={TRENDING_REPO_QUERY}
          fetchPolicy="network-only"
          variables={{from: getTimeWindowStart(timeWindowMS)}}>
          {({loading, error, data}: any) => {
            if (loading || error) {
              return <Loader size="huge" active />;
            }
            const allRepos = data.trendingRepos.edges.map((edge: any) =>
              parseRow(edge.node.row)
            );
            const filteredRepos = [];
            let found = 0;
            for (const repo of allRepos) {
              if (searchRow(repo)) {
                found += 1;
                if (found < 20) {
                  filteredRepos.push(repo);
                } else {
                  break;
                }
              }
            }
            return (
              <Card.Group
                items={filteredRepos.map((repo: any, i: number) => {
                  return {
                    key: repo.repoUrl,
                    fluid: true,
                    meta: '',
                    header: (
                      <>
                        <TargetBlank
                          href={repo.repoUrl}
                          style={{
                            color: 'rgba(0,0,0,.68)',
                            fontSize: '1.5em',
                          }}>
                          {repo.repoName.replace('/', ' / ')}
                        </TargetBlank>
                        <span
                          style={{float: 'right', color: 'rgba(0,0,0,.68)'}}>
                          <Icon name="star" />
                          {repo.starCount} <Icon name="fork" />
                          {repo.forkCount} <Icon name="find" />{' '}
                          {repo.watcherCount} <Icon name="fire" />
                          {repo.starChange}
                          <Button
                            size="mini"
                            icon
                            style={{marginLeft: 10}}
                            onClick={
                              () => (document.location.href = repo.repoUrl) // eslint-disable-line wandb/no-unprefixed-urls
                            }>
                            Star <Icon name="star" />
                          </Button>
                        </span>
                      </>
                    ),
                    description: (
                      <>
                        <p>{repo.description}</p>
                        <div style={{float: 'right', width: 200, height: 20}}>
                          <Sparklines
                            height={30}
                            data={repo.sparkLine}
                            style={{fill: 'none'}}>
                            <SparklinesLine />
                          </Sparklines>
                        </div>
                      </>
                    ),
                    extra: (
                      <div>
                        {repo.topics.slice(0, 5).map((topic: string) => {
                          let idx = topics.current.indexOf(topic);
                          if (idx < 0) {
                            idx = topics.current.length;
                            topics.current.push(topic);
                          }
                          return (
                            <Tag
                              key={`tag-${topic}`}
                              onClick={() => {
                                setFilterDisplay(topic);
                                setFilter(topic);
                              }}
                              tag={{name: topic, colorIndex: idx}}
                            />
                          );
                        })}
                        <Modal
                          size="small"
                          open={open}
                          onClose={() => setOpen(false)}
                          trigger={
                            <Button
                              floated="right"
                              size="tiny"
                              onClick={() =>
                                repo.project
                                  ? history.push(repo.project)
                                  : setOpen(true)
                              }
                              color={repo.project ? 'yellow' : undefined}>
                              {repo.project
                                ? 'View wandb project'
                                : 'Add wandb project'}
                            </Button>
                          }>
                          <Modal.Content>
                            You should really implement this repo, it would be
                            great
                          </Modal.Content>
                          <Modal.Actions>
                            <Button>Email Carey</Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                    ),
                  };
                })}
              />
            );
          }}
        </Query>
      </Container>
    );
  },
  {id: 'TrendingRepos', memo: true}
);

export default TrendingRepos;

function getTimeWindowStart(timeWindowMS: number): string {
  const epochMS = Date.now() - timeWindowMS;
  return new Date(epochMS).toISOString();
}
