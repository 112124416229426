import {RootState} from '../../types/redux';

export const getViewer = (state: RootState) => {
  return state.viewer.viewer;
};

export const reloadViewer = (state: RootState) => {
  return state.viewer.reload;
};

export const getViewerClaims = (state: RootState) => {
  return state.viewer.claims;
};
