import _ from 'lodash';
import React from 'react';
import {Redirect, Route, RouteProps, Switch} from 'react-router';
import App from './App';
import {ToastContainer} from './components/elements/Toast';
import GlobalNudgeBarContextProvider from './components/GlobalNudgeBarContextProvider';
import LocalNudgeBar from './components/LocalNudgeBar';
import Login from './components/Login';
import LoginRedirect from './components/LoginRedirect';
import Logout from './components/Logout';
import NoMatch from './components/NoMatch';
import InternalBenchmarkPage from './components/Panel2/InternalBenchmarkPage';
import RegistryPage from './components/Panel2/RegistryPage';
import SearchNav from './components/SearchNav';
import StorageNudgeBar from './components/StorageNudgeBar';
import TrialNudgeBar from './components/TrialNudgeBar';
import ComputeHourNudgeBar from './components/ComputeHourNudgeBar';
import config, {envIsLocal} from './config';
import PrivateRoute, {PrivateRouteProps} from './containers/PrivateRoute';
import AdminDashboard from './pages/AdminDashboardPage';
import AllProjects from './pages/AllProjects';
import AuthorizePage from './pages/AuthorizePage';
import BenchmarkPage from './pages/Benchmark/BenchmarkPage';
import NewBenchmarkPage from './pages/Benchmark/NewBenchmarkPage';
import BillingPage from './pages/BillingPage';
import TeamPayment from './pages/TeamPayment';
import ChangePassword from './pages/ChangePassword';
import CreateSweepPage from './pages/CreateSweepPage';
import CreateTrialTeam from './pages/CreateTrialTeam';
import EntityPage from './pages/EntityPage';
import ErrorTestPage from './pages/ErrorTestPage';
import GalleryAdmin from './pages/GalleryAdmin';
import GalleryCreateDiscussion from './pages/GalleryCreateDiscussion';
import GalleryCreatePost from './pages/GalleryCreatePost';
import GalleryPage from './pages/GalleryPage';
import GroupPage from './pages/GroupPage';
import Home from './pages/Home';
import HomePageNew, {HomePageForUser} from './pages/HomePageNew';
import HomeQuickStart from './pages/HomeQuickStart';
import LicenseAdmin from './pages/LicenseAdminPage';
import NewBenchmark from './pages/NewBenchmark';
import NewProject from './pages/NewProject';
import OnboardingFlow from './pages/OnboardingFlow';
import OrganizationAdmin from './pages/OrganizationAdminPage';
import OrganizationPage from './pages/OrganizationPage';
import ProjectPage2 from './pages/ProjectPage2';
import QueryDemoPage from './pages/QueryDemoPage';
import ReportPageEdit from './pages/ReportPageEdit';
import ReportPageEditOld from './pages/ReportPageEditOld';
import ReportPageView, {
  ReportPageViewPublishedWork,
} from './pages/ReportPageView';
import ReportPageViewOld from './pages/ReportPageViewOld';
import RunPage2 from './pages/RunPage2';
import SettingsPage from './pages/SettingsPage';
import StorageExplorer from './pages/StorageExplorer';
import SweepPage from './pages/SweepPage';
import Team from './pages/Team';
import UsageAdmin from './pages/UsageAdmin';
import TeamAdmin from './pages/TeamAdmin';
import TestPage from './pages/TestPage';
import TrendingRepos from './pages/TrendingRepos';
import UsersAdmin from './pages/UsersAdminPage';
import routesCheck, {
  BENCHMARK_PATHS,
  GALLERY_DISCUSSION_EDIT_PATH,
  GALLERY_DISCUSSION_VIEW_PATH,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  GROUP_PATHS,
  PROFILE_LIKES_PATH,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROJECT_BASE_PATH,
  PROJECT_PATHS,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  RUN_PATHS,
  STORAGE_EXPLORER_TRACKED_PATH,
  SWEEP_PATHS,
} from './routeData';
import makeComp from './util/profiler';
import LocalLicenseNudgeBar from './components/LocalLicenseNudgeBar';
import {isInIframe} from './setup';
import {GetLicenseNudgeBar} from './components/LocalLicenseGetNudge';

const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;

interface PlainRouteData extends RouteProps {
  RouteComponent: typeof Route;
}

interface RouteWithLayoutData extends RouteWithLayoutProps {
  RouteComponent: typeof RouteWithLayout;
}

interface PrivateRouteData extends PrivateRouteProps {
  RouteComponent: typeof PrivateRoute;
}

interface RedirectData {
  path: string | string[];
  exact?: true;
  redirect: string;
}

type RouteData = (
  | PlainRouteData
  | RouteWithLayoutData
  | PrivateRouteData
  | RedirectData
) & {
  condition?: boolean;
};

export interface RouteWithLayoutProps {
  component: React.ComponentType<any>;
  error?: any;
  allowIframes?: boolean;
  additionalProps?: {[prop: string]: any};
  [prop: string]: any;
}

// adds the Nav and Footer before and after the route component
export const RouteWithLayout: React.FC<RouteWithLayoutProps> = makeComp(
  ({
    component: Component,
    error,
    allowIframes,
    additionalProps = {},
    ...rest
  }) => {
    const preventIframe = isInIframe() && !allowIframes;
    return (
      <Route
        {...rest}
        render={routeProps => {
          return (
            <GlobalNudgeBarContextProvider>
              <SearchNav {...routeProps} />
              <StorageNudgeBar />
              {envIsLocal && <GetLicenseNudgeBar />}
              {envIsLocal && <LocalLicenseNudgeBar />}
              {rest.hasCurrentViewer && <LocalNudgeBar />}
              {!envIsLocal && <ComputeHourNudgeBar />}
              {!envIsLocal && <TrialNudgeBar />}
              <div className="main">
                <ToastContainer />
                {preventIframe ? (
                  <NoMatch unrecoverable />
                ) : (
                  <Component
                    error={error}
                    {...routeProps}
                    {...additionalProps}
                  />
                )}
              </div>
            </GlobalNudgeBarContextProvider>
          );
        }}
      />
    );
  },
  {id: 'RouteWithLayout'}
);

export function makeRoutes(): RouteData[] {
  const routes = [
    {
      RouteComponent: Route,
      path: '/__test_page',
      render: () => <TestPage />,
      exact: true,
    },
    {
      RouteComponent: Route,
      path: ['/__registry', '/__registry/:tab'],
      component: RegistryPage,
      exact: true,
    },
    {
      RouteComponent: Route,
      path: ['/__wbench', '/__wbench/:tab'],
      component: InternalBenchmarkPage,
      exact: true,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/',
      component: Home,
      exact: true,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/login',
      component: Login,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/login-redirect',
      component: LoginRedirect,
    },
    {
      RouteComponent: Route,
      path: '/logout',
      render: () => <Logout />,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/trending',
      component: TrendingRepos,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/home',
      component: HomePageNew,
      routeCache: 'HomePage',
      exact: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/home/projects',
      component: AllProjects,
      routeCache: 'AllProjectsPage',
      exact: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/quickstart/:framework?',
      component: HomeQuickStart,
      routeCache: 'HomeQuickStart',
    },
    {
      RouteComponent: PrivateRoute,
      path: `/${GALLERY_PATH_SEGMENT}/create-post`,
      component: GalleryCreatePost,
      routeCache: 'GalleryCreatePost',
    },
    {
      RouteComponent: PrivateRoute,
      path: `/${GALLERY_PATH_SEGMENT}/create-discussion`,
      component: GalleryCreateDiscussion,
      routeCache: 'GalleryCreateDiscussion',
    },
    {
      RouteComponent: RouteWithLayout,
      path: GALLERY_PATH,
      component: GalleryPage,
    },
    {
      path: '/fullyconnected',
      redirect: GALLERY_PATH,
    },
    {
      path: '/fc',
      redirect: GALLERY_PATH,
    },
    {
      path: '/gallery',
      redirect: GALLERY_PATH,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/__test/simple_error',
      component: ErrorTestPage,
      exact: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/users/:userName',
      component: HomePageForUser,
      routeCache: 'UserPage',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/signup',
      component: OnboardingFlow,
      routeCache: 'OnboardingFlow',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/change-password',
      component: ChangePassword,
      routeCache: 'ChangePassword',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/authorize',
      component: AuthorizePage,
      routeCache: 'AuthorizePage',
      allowIframes: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/settings',
      component: SettingsPage,
      routeCache: 'SettingsPage',
      exact: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: ['/billing', '/subscriptions'],
      component: BillingPage,
      routeCache: 'BillingPage',
      exact: true,
      condition: !isOnPrem,
    },
    {
      RouteComponent: PrivateRoute,
      path: ['/team-payment'],
      component: TeamPayment,
      routeCache: 'TeamPayment',
      exact: true,
      condition: !isOnPrem,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/usage',
      component: UsageAdmin,
      routeCache: 'UsageAdmin',
      exact: true,
      condition: isOnPrem,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/team',
      component: TeamAdmin,
      routeCache: 'TeamAdmin',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/organization',
      component: OrganizationAdmin,
      routeCache: 'OrganizationAdmin',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/users',
      component: UsersAdmin,
      routeCache: 'UsersAdmin',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/license',
      component: LicenseAdmin,
      routeCache: 'LicenseAdmin',
    },
    {
      RouteComponent: PrivateRoute,
      path: `/admin/${GALLERY_PATH_SEGMENT}`,
      component: GalleryAdmin,
      routeCache: 'GalleryAdmin',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/create-team',
      component: CreateTrialTeam,
      routeCache: 'CreateTrialTeam',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/teams/:entityName',
      component: Team,
      routeCache: 'TeamPage',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/admin/home',
      component: AdminDashboard,
      routeCache: 'AdminDashboard',
    },
    {
      RouteComponent: RouteWithLayout,
      path: [
        '/usage/:entityName',
        STORAGE_EXPLORER_TRACKED_PATH,
        '/usage/:entityName/:filePath*',
      ],
      component: StorageExplorer,
      routeCache: 'StorageExplorer',
      exact: true,
      allowAnonymous: true,
    },
    {
      path: '/storage/:entityName/tracked/:filePath*',
      redirect: STORAGE_EXPLORER_TRACKED_PATH,
    },
    {
      path: '/storage/:entityName/:filePath*',
      redirect: '/usage/:entityName/:filePath*',
    },
    {
      path: '/storage/:entityName',
      redirect: '/usage/:entityName',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/new-project',
      component: NewProject,
      routeCache: 'NewProject',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/new-benchmark',
      component: NewBenchmark,
      routeCache: 'NewBenchmark',
    },
    {
      RouteComponent: PrivateRoute,
      path: '/:entityname/benchmark/new',
      component: NewBenchmarkPage,
      routeCache: 'NewBenchmarkPage',
    },
    {
      RouteComponent: RouteWithLayout,
      path: PROFILE_PROJECTS_PATH,
      component: EntityPage,
      routeCache: 'EntityPage',
      exact: true,
      additionalProps: {tab: 'projects'},
    },
    {
      RouteComponent: RouteWithLayout,
      path: PROFILE_LIKES_PATH,
      component: EntityPage,
      routeCache: 'EntityPage',
      exact: true,
      additionalProps: {tab: 'likes'},
    },
    {
      RouteComponent: PrivateRoute,
      path: RUN_PATHS,
      component: RunPage2,
      routeCache: 'RunPage2',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: GROUP_PATHS,
      component: GroupPage,
      routeCache: 'GroupPage',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/:entityName/:projectName/create-sweep',
      component: CreateSweepPage,
      routeCache: 'CreateSweepPage',
      allowIframes: true,
      exact: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: SWEEP_PATHS,
      component: SweepPage,
      routeCache: 'SweepPage',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: BENCHMARK_PATHS,
      component: BenchmarkPage,
      routeCache: 'BenchmarkPage',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    // {
    //   RouteComponent: PrivateRoute,
    //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
    //   component: PublishedWorkPage,
    //   routeCache: 'PublishedWorkPage',
    //   exact: true,
    //   allowAnonymous: true,
    // },
    {
      RouteComponent: PrivateRoute,
      path: '/:entityName/:projectName/reports',
      component: ReportPageEditOld,
      routeCache: 'ReportPageEditOld',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/:entityName/:projectName/published',
      component: ReportPageViewOld,
      routeCache: 'ReportPageViewOld',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/:entityName/:projectName/reports/:reportNameAndID/edit',
      component: ReportPageEdit,
      routeCache: 'ReportPageEdit',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: REPORT_PAGE_VIEW_PATH,
      component: ReportPageView,
      routeCache: 'ReportPageView',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
      component: ReportPageViewPublishedWork,
      routeCache: 'ReportPageViewPublishedWork',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: GALLERY_POST_EDIT_PATH,
      component: ReportPageEdit,
      routeCache: 'ReportPageEdit',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: GALLERY_POST_VIEW_PATH,
      component: ReportPageView,
      routeCache: 'ReportPageView',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: GALLERY_DISCUSSION_EDIT_PATH,
      component: ReportPageEdit,
      routeCache: 'ReportPageEdit',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: GALLERY_DISCUSSION_VIEW_PATH,
      component: ReportPageView,
      routeCache: 'ReportPageView',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: RouteWithLayout,
      path: '/:entityName/:projectName/querydemo',
      component: QueryDemoPage,
      routeCache: 'QueryDemoPage',
      exact: true,
      allowAnonymous: true,
    },
    {
      RouteComponent: PrivateRoute,
      path: '/org/:orgName',
      component: OrganizationPage,
      routeCache: 'OrganizationPage',
      exact: true,
      allowAnonymous: false,
      condition: !isOnPrem,
    },
    {
      RouteComponent: RouteWithLayout,
      path: PROJECT_PATHS,
      component: ProjectPage2,
      routeCache: 'ProjectPage2',
      exact: true,
      allowIframes: true,
      allowAnonymous: true,
    },
    {
      // this is here to truncate extra path segments after the actual path
      path: PROJECT_BASE_PATH,
      redirect: PROJECT_BASE_PATH,
    },
    {
      RouteComponent: RouteWithLayout,
      path: PROFILE_PATH,
      component: EntityPage,
      routeCache: 'EntityPage',
      exact: true,
      allowAnonymous: true,
    },
  ];

  // HAX: These checks ensure that the route mapping above is in sync with routeData.ts.
  // routeData.ts is shared between the main app and the host function.
  if (routes.length !== routesCheck.length) {
    throw new Error(
      `mismatched routes length: ${routes.length}, ${routesCheck.length}`
    );
  }
  for (let i = 0; i < routes.length; i++) {
    const r = routes[i];
    const rc = routesCheck[i];
    if (
      !_.isEqual(r.path, rc.path) ||
      r.redirect !== rc.redirect ||
      r.exact !== rc.exact
    ) {
      throw new Error(`mismatched routes: ${r.path}, ${rc.path}`);
    }
  }

  return routes;
}

const Routes: React.FC = makeComp(
  () => {
    return (
      <App>
        <Switch>
          {makeRoutes()
            .filter(r => r.condition !== false)
            .map((r, i) => {
              if ('redirect' in r) {
                return <Redirect key={i} from={r.path} to={r.redirect} />;
              }
              const {RouteComponent, condition, ...rest} = r;
              const RC = RouteComponent as any;
              return <RC key={i} {...rest} />;
            })}
          <RouteWithLayout component={NoMatch} />
        </Switch>
      </App>
    );
  },
  {id: 'Routes'}
);

export default Routes;
