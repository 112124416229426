import React from 'react';

import {makePropsAreEqual} from '../../../util/shouldUpdate';
import {DFTableColumn, DFTableHoverCellCoords} from './DFTable';
import {
  DFTableCellColumnHeader,
  DFTableCellColumnHeaderProps,
} from './DFTableCellColumnHeader';
import {DFTableSortIndicatorComponent} from './DFTableSortIndicator';
import makeComp from '../../../util/profiler';

interface DFTableColumnHeaderProps {
  displayedRows: any[]; // table rows that are displayed in the current page
  columns: DFTableColumn[];
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  SortIndicatorComponent?: DFTableSortIndicatorComponent;
  hoverCellCoords: DFTableHoverCellCoords;
  expanded?: boolean;
  childProps(
    column: DFTableColumn,
    columnIndex: number,
    readOnly?: boolean
  ): DFTableCellColumnHeaderProps;
}

function hoverColumn(coords: DFTableHoverCellCoords) {
  if (!coords) {
    return undefined;
  }
  return coords[1];
}

// This component exists solely to prevent calling `shouldComponentUpdate` on
// every individual column header when there are many.
export const DFTableColumnHeaders: React.FC<DFTableColumnHeaderProps> =
  makeComp(
    ({columns, childProps, displayedRows, SortIndicatorComponent}) => {
      return (
        <React.Fragment>
          <div className="df-tree-padding" />
          {columns.map((column, columnIndex) => {
            const props = childProps(column, columnIndex);
            return (
              <DFTableCellColumnHeader
                {...props}
                displayedRows={displayedRows}
                key={columnIndex}
                SortIndicatorComponent={SortIndicatorComponent}
              />
            );
          })}
          <div className="df-tree-padding" />
        </React.Fragment>
      );
    },
    {
      id: 'DFTableColumnHeaders',
      memo: (prevProps, nextProps) => {
        if (
          hoverColumn(prevProps.hoverCellCoords) !==
          hoverColumn(nextProps.hoverCellCoords)
        ) {
          return false;
        }
        const propsAreEqual = makePropsAreEqual({
          name: 'DFTableColumnHeaders',
          deep: [
            'displayedRows',
            'columns',
            'groupTempSelection',
            'tempSelectedCount',
          ],
          // handled by shouldComponentUpdate
          ignore: ['hoverCellCoords'],
          ignoreFunctions: true,
          debug: false,
          verbose: true,
        });
        return propsAreEqual(prevProps, nextProps);
      },
    }
  );
