import React from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import {
  NDArrayType,
  nullableTaggableValue,
} from '@wandb/cg/browser/model/types';

const inputType = {
  type: 'ndarray' as const,
  serializationPath: {key: '', path: ''},
  shape: [0],
};
type PanelNDArrayProps = Panel2.PanelProps<typeof inputType>;

export const PanelNDArray: React.FC<PanelNDArrayProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  const ndarrayType = nullableTaggableValue(
    props.input.path.type
  ) as NDArrayType;
  return (
    <div>
      <span>{'ndarray(' + ndarrayType.shape + ') @'}</span>
      <br />
      <span>
        {ndarrayType.serializationPath.path +
          '[' +
          ndarrayType.serializationPath.key +
          ']'}
      </span>
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'ndarray',
  Component: PanelNDArray,
  inputType,
};
