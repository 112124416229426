import * as S from './AdminDashboardPage.styles';

import React, {useState} from 'react';
import {Accordion, AccordionTitleProps, Divider, Icon} from 'semantic-ui-react';
import makeComp from '../util/profiler';
import {NavLink} from 'react-router-dom';
import MoveCloneProjectModal from '../components/MoveCloneProjectModal';
import {envIsLocal} from '../config';
import NoMatch from '../components/NoMatch';
import WandbLoader from '../components/WandbLoader';
import {useViewer} from '../state/viewer/hooks';
import {TargetBlank} from '../util/links';

const AdminDashboardPage = makeComp(
  () => {
    const viewer = useViewer();

    // TODO(haruka): check viewer's permission
    const showSales = true;
    const showCustomerSupport = true;
    const showAdmin = true;

    if (envIsLocal) {
      return <NoMatch />;
    }
    if (!viewer) {
      return <WandbLoader />;
    }
    if (!viewer.admin) {
      return <NoMatch />;
    }

    return (
      <S.PageWrapper>
        {showSales && <SalesContent />}
        {showCustomerSupport && <CustomerSupportContent />}
        {showAdmin && <AdminContent />}
      </S.PageWrapper>
    );
  },
  {id: 'AdminDashboardPage'}
);

const SalesContent = makeComp(
  () => {
    const [activeIndex, setActiveIndex] = useState<string>('');

    const titleClickHandler = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      titleProps: AccordionTitleProps
    ) => {
      const index = titleProps.index;
      if (typeof index !== 'string') {
        return;
      }
      const newIndex = activeIndex === index ? '' : index;
      setActiveIndex(newIndex);
    };

    return (
      <S.SectionWrapper>
        <S.HeaderWrapper>
          <Icon name="line graph" style={{marginRight: '8px'}} />
          Sales
        </S.HeaderWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <TargetBlank href="https://deploy.wandb.ai/admin">
              <Accordion.Title style={{background: 'white'}}>
                <S.AccordionTitleWrapper>
                  Go to local license dashboard <Icon name="dropdown" />
                </S.AccordionTitleWrapper>
              </Accordion.Title>
            </TargetBlank>
          </Accordion>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <TargetBlank href="https://deploy.wandb.ai/admin/orders/new">
              <Accordion.Title
                active={activeIndex === 'generate_license'}
                index={'generate_license'}
                style={{background: 'white'}}
                onClick={titleClickHandler}>
                <S.AccordionTitleWrapper>
                  Generate local licenses <Icon name="dropdown" />
                </S.AccordionTitleWrapper>
              </Accordion.Title>
            </TargetBlank>
          </Accordion>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <NavLink to={'/admin/organization'}>
              <Accordion.Title
                active={activeIndex === 'organization'}
                index={'organization'}
                style={{background: 'white'}}
                onClick={titleClickHandler}>
                <S.AccordionTitleWrapper>
                  Go to organization dashabord <Icon name="dropdown" />
                </S.AccordionTitleWrapper>
              </Accordion.Title>
            </NavLink>
          </Accordion>
        </S.AccordionWrapper>
      </S.SectionWrapper>
    );
  },
  {id: 'salesContent'}
);

const CustomerSupportContent = makeComp(
  () => {
    const [activeIndex, setActiveIndex] = useState<string>('');

    const titleClickHandler = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      titleProps: AccordionTitleProps
    ) => {
      const index = titleProps.index;
      if (typeof index !== 'string') {
        return;
      }
      const newIndex = activeIndex === index ? '' : index;
      setActiveIndex(newIndex);
    };

    return (
      <S.SectionWrapper>
        <S.HeaderWrapper>
          <Icon name="users" style={{marginRight: '8px'}} />
          Customer support
        </S.HeaderWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <NavLink to={'/admin/users'}>
              <Accordion.Title
                active={activeIndex === 'users'}
                index={'users'}
                style={{background: 'white'}}
                onClick={titleClickHandler}>
                <S.AccordionTitleWrapper>
                  Go to users dashboard <Icon name="dropdown" />
                </S.AccordionTitleWrapper>
              </Accordion.Title>
            </NavLink>
          </Accordion>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <Accordion.Title
              index={'teams'}
              style={{background: 'white'}}
              onClick={titleClickHandler}>
              <S.AccordionTitleWrapper>
                Teams (coming soon) <Icon name="dropdown" />
              </S.AccordionTitleWrapper>
            </Accordion.Title>
          </Accordion>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <Accordion.Title
              active={activeIndex === 'move_project'}
              index={'move_project'}
              style={{background: 'white'}}
              onClick={titleClickHandler}>
              <S.AccordionTitleWrapper>
                Move project
                <Icon name="dropdown" />
              </S.AccordionTitleWrapper>
            </Accordion.Title>
            <Accordion.Content
              style={{padding: 0}}
              active={activeIndex === 'move_project'}>
              <Divider fitted />
              <S.ContentWrapper>
                <MoveCloneProjectModal
                  onClose={() => {
                    setActiveIndex('');
                  }}
                />
              </S.ContentWrapper>
            </Accordion.Content>
          </Accordion>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <Accordion.Title
              active={activeIndex === 'clone_project'}
              index={'clone_project'}
              style={{background: 'white'}}
              onClick={titleClickHandler}>
              <S.AccordionTitleWrapper>
                Clone project
                <Icon name="dropdown" />
              </S.AccordionTitleWrapper>
            </Accordion.Title>
            <Accordion.Content
              style={{padding: 0}}
              active={activeIndex === 'clone_project'}>
              <Divider fitted />
              <S.ContentWrapper>
                <MoveCloneProjectModal
                  clone
                  onClose={() => {
                    setActiveIndex('');
                  }}
                />
              </S.ContentWrapper>
            </Accordion.Content>
          </Accordion>
        </S.AccordionWrapper>
      </S.SectionWrapper>
    );
  },
  {id: 'customerSupportContent'}
);

const AdminContent = makeComp(
  () => {
    const [activeIndex, setActiveIndex] = useState<
      AccordionTitleProps | string
    >('');

    const titleClickHandler = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      titleProps: AccordionTitleProps
    ) => {
      const index = titleProps.index;
      if (typeof index !== 'string') {
        return;
      }
      const newIndex = activeIndex === index ? '' : index;
      setActiveIndex(newIndex);
    };

    return (
      <S.SectionWrapper>
        <S.HeaderWrapper>
          <Icon.Group style={{marginRight: '8px'}}>
            <Icon name="user" />
            <Icon corner="bottom right" name="setting" />
          </Icon.Group>
          Admin
        </S.HeaderWrapper>
        <S.AccordionWrapper>
          <Accordion styled>
            <Accordion.Title
              index={'internal-users'}
              style={{
                background: 'white',
              }}
              onClick={titleClickHandler}>
              <S.AccordionTitleWrapper>
                Internal users (coming soon)
                <Icon name="dropdown" />
              </S.AccordionTitleWrapper>
            </Accordion.Title>
          </Accordion>
        </S.AccordionWrapper>
      </S.SectionWrapper>
    );
  },
  {id: 'adminContent'}
);

export default AdminDashboardPage;
