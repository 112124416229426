import {Button, Container, Form, Input, TextArea} from 'semantic-ui-react';

import React, {FC, useCallback, useEffect, useState} from 'react';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';
import {Header} from 'semantic-ui-react';
import '../../css/Benchmark.less';
import {Subset} from '../../types/base';
import {BenchmarkProject} from '../../types/graphql';
import {BENCHMARK_UPSERT} from './BenchmarkPage';
import ImageIcon from '../../components/ImageIcon';
import {SingleLineText} from '../../components/elements/Text';
import {setDocumentTitle} from '../../util/document';
import makeComp from '../../util/profiler';

type NewBenchmarkSubmission = Subset<
  BenchmarkProject,
  'name' | 'description' | 'evaluation' | 'concept'
>;

interface NewBenchmarkPageProps {
  submit: (sub: NewBenchmarkSubmission) => Promise<any>;
  match: {
    params: {
      entityName: string;
    };
  };
}

const NewBenchmarkPage: FC<NewBenchmarkPageProps> = makeComp(
  ({submit, match}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [evaluation, setEvaluation] = useState('');
    const [concept, setConcept] = useState('');

    useEffect(() => {
      setDocumentTitle('New Benchmark');
    }, []);

    const slugFormat = useCallback((e: any) => {
      setName(
        e.target.value.toLowerCase().replace(/\W+/g, '-').replace(/(-)\1/g, '-')
      );
    }, []);

    const handleChange = useCallback((e: any) => {
      const val = e.target.value;
      switch (e.target.name) {
        case 'description':
          setDescription(val);
          break;
        case 'evaluation':
          setEvaluation(val);
          break;
        case 'concept':
          setConcept(val);
          break;
      }
    }, []);

    const handleSubmit = useCallback(
      (e: any) => {
        const variables = {
          isBenchmark: true,
          entityName: match.params.entityName,
          name,
          description,
          evaluation,
          concept,
        };
        submit(variables).then(res => {
          // if (res && res.data && res.data.upsertModel) {
          //   window.location.href = urls.benchmarkProject(
          //     res.data.upsertModel.project
          //   );
          //   console.log('res', res);
          // }
        });
      },
      [submit, match, name, description, evaluation, concept]
    );

    return (
      <div className="new-benchmark">
        <BenchmarkPageHeader
          headerText="New Benchmark"
          extraText="Create a benchmark competition to find the best models for a specific task."
        />
        <Container className="new-benchmark-page">
          <Form onSubmit={handleSubmit} className="ui form">
            <Form.Field>
              <Input
                name="name"
                onChange={slugFormat}
                size="large"
                placeholder="Benchmark Title"
              />
            </Form.Field>
            <Form.Field>
              <Input
                name={'description'}
                onChange={handleChange}
                placeholder="One line description of the challenge"
              />
            </Form.Field>
            <Form.Field>
              <Header as="h3">Concept</Header>
              <TextArea
                name={'concept'}
                onChange={handleChange}
                autoheight
                rows={8}
                placeholder="Describe the context, the dataset, and the goal of the model."
              />
            </Form.Field>
            <Form.Field>
              <Header as="h3">Evaluation</Header>
              <TextArea
                name={'evaluation'}
                onChange={handleChange}
                autoheight
                rows={8}
                placeholder="Describe how you're going to judge the competition specifying necessary files and metrics."
              />
            </Form.Field>
            <Form.Field>
              <Button primary onSubmit={handleSubmit}>
                Create Benchmark
              </Button>
            </Form.Field>
          </Form>
        </Container>
      </div>
    );
  },
  {id: 'NewBenchmarkPage', memo: true}
);

const withMutations = graphql(BENCHMARK_UPSERT, {
  props: ({ownProps, mutate}) => ({
    submit: (vars: any) => {
      if (mutate) {
        return mutate({
          variables: vars,
        });
      }
      return;
    },
  }),
}) as any;

export default withMutations(connect()(NewBenchmarkPage));

interface BenchmarkPageHeaderProps {
  headerText: string;
  extraText: string;
}

const BenchmarkPageHeader = makeComp(
  (props: BenchmarkPageHeaderProps) => {
    const {headerText, extraText} = props;

    return (
      <div className={`page-header`}>
        <div className="page-header__content">
          <div className="page-header__title">
            <ImageIcon
              className="page-header__title__page-type-icon"
              name="benchmark"
            />
            <div className="page-header__title__name">
              <SingleLineText>{headerText || ''} </SingleLineText>
            </div>
          </div>
          <div className="page-header__breadcrumbs">
            <SingleLineText>{extraText || ''} </SingleLineText>
          </div>
        </div>
      </div>
    );
  },
  {id: 'BenchmarkPageHeader'}
);
