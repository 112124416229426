import {createAction} from 'typesafe-actions';
import * as Types from './types';

// User Actions

export const set = createAction(
  '@view/runSet/set',
  action => (ref: Types.Ref, runSetNorm: Types.RunSetPart) =>
    action({ref, runSetNorm})
);

export const update = createAction(
  '@view/runSet/update',
  action => (ref: Types.Ref, runSetUpdate: Partial<Types.RunSetOwnAttrs>) =>
    action({ref, runSetUpdate})
);

export const visualizeAllIfNoneVisualized = createAction(
  '@view/runSet/selectAllIfNoneSelected',
  action => (ref: Types.Ref) => action({ref})
);
