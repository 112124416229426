import {PanelBankSettings} from './../../util/panelbank';
import {createAction} from 'typesafe-actions';

import {ThunkResult} from '../../types/redux';

import {ID} from '@wandb/cg/browser/utils/string';

import * as ViewActions from '../views/actions';
import * as ViewActionsInternal from '../views/actionsInternal';
import * as ViewApi from '../views/api';
import * as ViewThunks from '../views/thunks';
import * as Selectors from './selectors';
import * as Types from './types';
import * as Url from './url';
import * as WorkspaceUtil from './util';
import produce from 'immer';

// User Actions

export function init(
  workspaceID: string,
  entityName: string,
  projectName: string,
  viewType: Types.WorkspaceTypes,
  urlResult: Url.UrlResult,
  defaultSpec: Types.WorkspaceMaterialized,
  workspaceObjectID?: string
): ThunkResult<Promise<void>> {
  const userWorkspaceName = WorkspaceUtil.userWorkspaceName(workspaceObjectID);
  const defaultWorkspaceName =
    WorkspaceUtil.defaultWorkspaceName(workspaceObjectID);
  return (dispatch, getState) => {
    // Create the loadMetadatalist start action so we can get it's ID.
    const startAction = ViewActions.loadMetadataListStarted({
      entityName,
      projectName,
      viewType,
    });

    // Setup the view first
    dispatch(startAction);
    // Then the workspace
    dispatch(
      initStart(
        workspaceID,
        viewType,
        startAction.payload.id,
        defaultSpec,
        userWorkspaceName,
        defaultWorkspaceName
      )
    );

    return dispatch(ViewThunks.loadMetadataList(startAction)).then(views => {
      // loadMetadataList makes network calls, which could hang, and during which we could have
      // already started unloading views. Let's detect and handle that here by making sure
      // the workspaceID we're interested in still exists in redux.
      const state = getState();
      const workspace = state.workspaces[workspaceID] as
        | typeof state['workspaces'][string]
        | undefined;
      if (workspace == null) {
        return Promise.resolve();
      }

      if (urlResult.type === 'default') {
        // Requested the default workspace
        return dispatch(loadDefault(workspaceID));
      } else if (urlResult.type === 'user') {
        // Requested a workspace for a specific user
        const foundView = views.find(
          v =>
            v.name === userWorkspaceName && v.user.username === urlResult.user
        );
        if (foundView != null) {
          return dispatch(load(workspaceID, foundView.cid));
        }
      } else if (urlResult.type === 'none') {
        // No requested, load our own workspace
        return dispatch(loadOwn(workspaceID));
      } else if (urlResult.type === 'empty') {
        // Requested a blank workspace, load our own and clear it.
        return dispatch(loadNew(workspaceID, userWorkspaceName));
      }
      // Otherwise, load our own workspace
      return dispatch(loadOwn(workspaceID));
    });
  };
}

export function deinit(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const {viewListID: metadataListID, viewRef} = state.workspaces[workspaceID];
    dispatch(unloadWorkspace(workspaceID));
    if (viewRef != null) {
      dispatch(ViewThunks.unloadView(viewRef.id));
    }
    dispatch(ViewThunks.unloadMetadataList(metadataListID));
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
  };
}

export function load(
  workspaceID: string,
  cid: string
): ThunkResult<Promise<void>> {
  return (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceID];
    dispatch(viewLoadStart(workspaceID));
    return dispatch(ViewThunks.load(cid)).then(view => {
      if (view.type !== workspace.viewType) {
        throw new Error(
          `Loaded view's type (${view.type}) didn't match workspace type (${workspace.viewType})`
        );
      }
      dispatch(viewReady(workspaceID, {type: workspace.viewType, id: cid}));
      return Promise.resolve();
    });
  };
}

// Loads a new, blank workspace belonging to the current viewer.
function loadNew(
  workspaceID: string,
  workspaceName: string
): ThunkResult<Promise<void>> {
  return (dispatch, getState, client) => {
    const state = getState();
    const workspace = state.workspaces[workspaceID];
    const viewer = state.viewer.viewer;

    const {viewListID, viewType} = workspace;
    if (viewListID == null || viewType == null) {
      throw new Error('Invalid state');
    }
    const viewList = state.views.lists[viewListID];
    // It's weird to grab these from the view list query, but they're there!
    const {entityName, projectName} = viewList.query;

    let readOnly = false;
    if (viewer == null) {
      readOnly = true;
    } else if (viewList.viewIds.length > 0) {
      // This logic isn't quite right, we only know if the project
      // is readonly if there is at least one workspace saved.
      // TODO: Handle the zero workspace case
      const view0 = state.views.views[viewList.viewIds[0]];
      readOnly = view0.project == null || view0.project.readOnly;
    }

    const loadBlank = (defaultSpec: any) => {
      const newOwnView = {
        cid: 'new-view-' + ID(9),
        type: viewType,
        name: workspaceName,
        displayName: workspaceName,
        description: '',
        user: viewer
          ? {
              id: viewer.id,
              username: viewer.username ?? viewer.email,
              photoUrl: viewer.photoUrl,
              admin: viewer.admin,
            }
          : {
              id: 'ANONYMOUS',
              username: '',
              photoUrl: '',
              admin: false,
            },
        entityName,
        project: {
          entityName,
          name: projectName,
          readOnly,
        },
        spec: defaultSpec,
        updatedAt: new Date(),
        createdAt: new Date(),
        locked: true,
      };

      dispatch(ViewActionsInternal.deleteUndoRedoHistory());
      dispatch(
        ViewActionsInternal.loadFinished(newOwnView.cid, newOwnView, true)
      );
      dispatch(viewReady(workspaceID, {type: viewType, id: newOwnView.cid}));
      return Promise.resolve();
    };

    // Look for a saved default workspace
    const {defaultWorkspaceName} = workspace;
    const views = viewList.viewIds.map(vid => state.views.views[vid]);
    const defaultViewMetadata = views.find(
      v => v.name === defaultWorkspaceName
    );
    if (defaultViewMetadata != null && defaultViewMetadata.id != null) {
      // We have have a saved default workspace, load into our new blank workspace.
      return ViewApi.load(client, defaultViewMetadata.id).then(res =>
        loadBlank(res.spec)
      );
    } else {
      // Otherwise use the workspace provided by the code.
      return loadBlank(workspace.defaultSpec);
    }
  };
}

export function loadOwn(workspaceID: string): ThunkResult<Promise<void>> {
  return (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceID];
    const {userWorkspaceName} = workspace;
    const viewer = state.viewer.viewer;

    const {viewListID, viewType} = workspace;
    if (viewListID == null || viewType == null) {
      throw new Error('Invalid state');
    }
    const viewList = state.views.lists[viewListID];

    if (viewer != null) {
      // This could use a selector
      const views = viewList.viewIds.map(vid => state.views.views[vid]);
      const ownView = views.find(
        v => v.name === userWorkspaceName && v.user.id === viewer.id
      );

      if (ownView) {
        return dispatch(load(workspaceID, ownView.cid));
      }
    }

    // Otherwise, we need to create a blank view and load it.
    return dispatch(loadNew(workspaceID, userWorkspaceName));
  };
}

export function loadDefault(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceID];
    const {defaultWorkspaceName} = workspace;

    const viewListID = workspace.viewListID;
    if (viewListID == null) {
      throw new Error('Invalid state');
    }

    // This could use a selector
    const viewList = state.views.lists[viewListID];
    const views = viewList.viewIds.map(vid => state.views.views[vid]);
    const defaultViewMetadata = views.find(
      v => v.name === defaultWorkspaceName
    );

    if (defaultViewMetadata == null) {
      // If no default view has been created, just load our own view and clear it
      return dispatch(loadNew(workspaceID, defaultWorkspaceName));
    } else {
      return dispatch(load(workspaceID, defaultViewMetadata.cid));
    }
  };
}

export function resetWithPanelBankSettings(
  workspaceID: string,
  settings: PanelBankSettings
): ThunkResult<void> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.defaultSpec == null) {
      throw new Error('Invalid state');
    }

    const workspaceSpec = produce(workspace.defaultSpec, draft => {
      if ('panelBankConfig' in draft) {
        draft.panelBankConfig.settings = settings;
      } else if ('section' in draft) {
        draft.section.panelBankConfig.settings = settings;
      }
    });

    dispatch(update(workspaceID, workspaceSpec));
  };
}

export function clearView(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.defaultSpec == null) {
      throw new Error('Invalid state');
    }
    dispatch(update(workspaceID, workspace.defaultSpec));
  };
}

export function fixView(workspaceID: string): ThunkResult<Promise<void>> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.defaultSpec == null) {
      throw new Error('Invalid state');
    }
    dispatch(update(workspaceID, workspace.defaultSpec));
    return Promise.resolve();
  };
}

export function update(
  workspaceID: string,
  spec: Types.WorkspaceMaterialized
): ThunkResult<void> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.viewRef == null) {
      throw new Error('Unexpected state');
    }
    dispatch(ViewThunks.updateViewSpec(workspace.viewRef.id, spec));
  };
}

export function copyToDefault(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState, client) => {
    const state = getState();
    const workspace = getState().workspaces[workspaceID];
    const {defaultWorkspaceName, viewType, viewRef} = workspace;
    if (viewRef == null || viewType == null) {
      throw new Error('Invalid state');
    }
    const specResult = Selectors.makeWorkspaceSpecSelector(
      workspaceID,
      viewType
    )(state);
    if (specResult.loading) {
      throw new Error('Invalid state');
    }
    // remove the id from the view so we don't try to rename the existing view
    const {id: _, ...view} = specResult.view;
    ViewApi.save(client, {
      ...view,
      spec: specResult.fullSpec,
      name: defaultWorkspaceName,
    });
  };
}

export function copyToOwn(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const workspace = getState().workspaces[workspaceID];
    const curView = workspace.viewRef;
    const viewType = workspace.viewType;
    if (curView == null || viewType == null) {
      throw new Error('Invalid state');
    }
    const specResult = Selectors.makeWorkspaceSpecSelector(
      workspaceID,
      viewType
    )(state);
    if (specResult.loading) {
      throw new Error('Invalid state');
    }
    dispatch(loadOwn(workspaceID)).then(() =>
      dispatch(update(workspaceID, specResult.fullSpec))
    );
  };
}

export function discardChanges(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    const curViewRef = workspace.viewRef;
    if (curViewRef == null) {
      throw new Error('Invalid state');
    }
    dispatch(load(workspaceID, curViewRef.id));
  };
}

// Internal actions

export const initStart = createAction(
  '@workspace/initInternal',
  action =>
    (
      id: string,
      viewType: Types.WorkspaceTypes,
      viewListID: string,
      defaultSpec: Types.WorkspaceMaterialized,
      userWorkspaceName: string,
      defaultWorkspaceName: string
    ) =>
      action({
        id,
        viewListID,
        viewType,
        defaultSpec,
        userWorkspaceName,
        defaultWorkspaceName,
      })
);

export const unloadWorkspace = createAction(
  '@workspace/deinitStart',
  action => (id: string) => action({id})
);

export const viewLoadStart = createAction(
  '@workspace/viewLoadStart',
  action => (id: string) => action({id})
);

export const viewReady = createAction(
  '@workspace/viewReady',
  action => (id: string, viewRef: Types.ViewID) => action({id, viewRef})
);

export type ActionType = ReturnType<
  | typeof initStart
  | typeof unloadWorkspace
  | typeof viewLoadStart
  | typeof viewReady
>;
