import React, {FC, createElement} from 'react';
import {
  toast as toastify,
  ToastContainer as ToastifyContainer,
  ToastOptions,
  ToastContent,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './Toast.css';
import LegacyWBIcon from './LegacyWBIcon';
import makeComp from '../../util/profiler';

interface CloseButtonProps {
  closeToast: any;
}

export const ToastContainer: FC = makeComp(
  () => {
    return (
      <ToastifyContainer
        position={toastify.POSITION.TOP_RIGHT}
        hideProgressBar={true}
        className="toast-container"
        toastClassName="toast"
        closeButton={createElement(({closeToast}: CloseButtonProps) => (
          <LegacyWBIcon
            name="close"
            onClick={closeToast}
            className="close-toast"
          />
        ))}
      />
    );
  },
  {id: 'ToastContainer', memo: true}
);

export function toast(text: ToastContent, options?: ToastOptions): void {
  toastify(text, options);
}
