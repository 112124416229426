import styled, {css} from 'styled-components';

export const InlineRunFiltersButton = styled.div<{open: boolean}>`
  display: inline-flex;
  align-items: center;
  background: ${props => props.theme.clickable};
  color: ${props => props.theme.text};
  font-family: Inconsolata;
  padding-left: 4px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 -2px;
  cursor: pointer;
  border-radius: 2px;
  i.icon.wbic-ic-next {
    color: ${props => props.theme.text};
    margin: 0 !important;
    transform: rotate(90deg);
  }
  ${props =>
    props.open &&
    css`
      background: ${props.theme.focused};
    `}
  &:hover {
    background: ${props => props.theme.focused};
  }
`;
