import * as React from 'react';
import {Icon} from 'semantic-ui-react';

import * as _ from 'lodash';
import * as Filter from '../../../util/filters';
import {Sort as QuerySort} from '../../../util/queryts';
import {Config as TableConfig, ConfigV2} from '../../../util/runfeed';
import {
  getValue,
  Key as RunKey,
  keyToString,
  Run,
  WBValue,
} from '../../../util/runs';
import {DFTableColumn, DFTableRowFields} from './DFTable';
import {DFTableCellDefault} from './DFTableCellDefault';
import {DFTableCellExpandable} from './DFTableCellExpandable';
import {DFTableCellIcon} from './DFTableCellIcon';
import {DFTableCellImage} from './DFTableCellImage';
import {
  DataFrameQueryOutputData,
  DataFrameQueryVariables,
  DFTableQueryShapedOutput,
} from './DFTableQuery';
import {
  TABLE_DEFAULT_COLUMN_WIDTH,
  TABLE_DEFAULT_NAME_COLUMN_WIDTH,
  TABLE_MIN_COLUMN_WIDTH,
} from '../../../util/constants';

function parseValue(value: any): any {
  // try parsing out wb values
  if (
    typeof value === 'string' &&
    value.startsWith('{') &&
    value.endsWith('}')
  ) {
    try {
      const wbval = JSON.parse(value);
      if (wbval && wbval._type) {
        value = wbval;
      }
    } catch {
      // nothing
    }
  }
  return value;
}

export function queryInputTransform(
  allGroupKeys: string[],
  queryVariables: DataFrameQueryVariables,
  recursionDepth?: number,
  parentRow?: any
): DataFrameQueryVariables {
  allGroupKeys = _.clone(allGroupKeys) || [];
  let shapedVariables: DataFrameQueryVariables = {
    ...queryVariables,
  };
  // If this is an expanded group, paging doesn't work properly, so just load a
  // bunch of data and just let paging be done in memory.
  if (recursionDepth) {
    shapedVariables = {
      ...shapedVariables,
      limit: 1000,
    };
  }
  _.times(recursionDepth || 0, () => {
    // convert the first group into a filter
    const newFilterName = allGroupKeys.shift();
    if (newFilterName) {
      shapedVariables = {
        ...shapedVariables,
        filters: {
          op: 'AND',
          filters: [shapedVariables.filters].concat([
            {
              key: {section: 'run', name: newFilterName},
              op: '=',
              value: getValue(parentRow, {
                section: 'summary',
                name: newFilterName,
              }),
            } as Filter.Filter,
          ]),
        } as Filter.Filter,
        // the children grouping is the parent grouping minus the first grouping key (which is now a filter)
        groupKeys: allGroupKeys,
      };
    }
  });
  shapedVariables = {
    ...shapedVariables,
    groupKeys:
      shapedVariables.groupKeys && shapedVariables.groupKeys.length > 0
        ? shapedVariables.groupKeys.slice(0, 1)
        : undefined,
    filters:
      shapedVariables.filters != null
        ? typeof shapedVariables.filters === 'string' ||
          shapedVariables.filters instanceof String
          ? shapedVariables.filters
          : JSON.stringify(Filter.toMongo(shapedVariables.filters))
        : undefined,
  };
  return shapedVariables;
}

export function queryOutputTransform(
  groupKeys: string[],
  selectRow: (row: string) => void,
  imageUrl: (img: any) => string | undefined,
  data: DataFrameQueryOutputData
): DFTableQueryShapedOutput {
  const shapedOutput: DFTableQueryShapedOutput = {
    rows: [],
    columns: [],
    totalRowCount: 0,
  };
  const dataFrameResult = data.dataFrame;
  if (dataFrameResult) {
    const cols = dataFrameResult.schema.map((s: any) => s.Name);
    const rows = dataFrameResult.edges.map(e => e.node.row.map(parseValue));
    const rowData = rows.map(row => _.fromPairs(_.zip(cols, row)));

    shapedOutput.rows = rowData.map(d => ({summary: d}));
    shapedOutput.columns = ['__preview__']
      .concat(cols.filter((c: string) => c !== 'f0_'))
      .map((c: string) => {
        const key = {section: 'summary', name: c} as RunKey;
        const filterKey = {section: 'run', name: c} as RunKey;
        return {
          key,
          accessor: keyToString(key),
          displayName: c === '__preview__' ? 'Preview' : c,
          renderHeader:
            c === '__preview__' ? () => <Icon name="eye" /> : undefined,
          renderCell: (
            column,
            row: DFTableRowFields<Run>,
            {
              addFilter,
              toggleExpandedRow,
              recursionDepth,
              isExpanded,
              loadingChildren,
            }
          ) => {
            const value = getValue(row, key);
            const rowAddress = row.__address__;

            if (c === '__preview__') {
              return (
                <DFTableCellIcon
                  name="eye"
                  onClick={() => selectRow(rowAddress)}
                  isSelected={value === true}
                />
              );
            }

            if (value && (value as WBValue)._type === 'image-file') {
              return <DFTableCellImage imageUrl={imageUrl(value)} />;
            }

            // if we're grouping by this column, make the cell expandable
            const i = groupKeys.indexOf(key.name);
            if (i > -1) {
              const childrenCount = getValue(row, {
                section: 'summary',
                name: 'f0_',
              }) as number | undefined;
              return i === recursionDepth ? (
                <DFTableCellExpandable
                  cellValue={value}
                  isExpanded={isExpanded}
                  loadingChildren={loadingChildren}
                  childrenCount={childrenCount}
                  toggleExpandedRow={() => {
                    return toggleExpandedRow && toggleExpandedRow(rowAddress);
                  }}
                />
              ) : (
                // don't repeat the value in child rows
                <DFTableCellDefault columnKey={filterKey} cellValue={''} />
              );
            }
            // not grouping by this column
            return (
              <DFTableCellDefault
                columnKey={filterKey}
                cellValue={value}
                addFilter={addFilter}
              />
            );
          },
        } as DFTableColumn;
      });
    shapedOutput.totalRowCount = dataFrameResult.totalCount;
  }
  return shapedOutput;
}

export function querySettingsTransform(
  allGroupKeys: string[],
  tableSettings: TableConfig,
  allColumns: DFTableColumn[],
  rows: any[]
): TableConfig {
  tableSettings = _.clone(tableSettings);
  tableSettings = forcePinAt(tableSettings, 'summary:__preview__', 0);
  allGroupKeys.forEach((key, i) => {
    tableSettings = forcePinAt(tableSettings, key, i + 1);
  });
  tableSettings.columnWidths = {
    ...tableSettings.columnWidths,
    'summary:__preview__': 48,
  };
  return tableSettings;
}

function forcePinAt(
  tableSettings: TableConfig,
  accessor: string,
  index: number
): TableConfig {
  tableSettings.columnPinned = {
    ...tableSettings.columnPinned,
    [accessor]: true,
  };
  tableSettings.columnVisible = {
    ...tableSettings.columnVisible,
    [accessor]: true,
  };
  tableSettings.columnOrder = tableSettings.columnOrder.filter(
    k => k !== accessor
  );
  tableSettings.columnOrder.splice(index, 0, accessor);

  return tableSettings;
}

export function sortKeyToString(sort: QuerySort): string {
  return sort.keys
    .map(({key, ascending}) => {
      const sortString = key.name;
      const sortPrefix = ascending ? '+' : '-';
      return sortPrefix + sortString;
    })
    .join(',');
}

export function sortStringToKey(sortStr: string): QuerySort {
  return {
    keys: sortStr.split(',').map(keyStr => {
      const [keyPrefix, keyString] = [keyStr[0], keyStr.slice(1)];
      return {
        key: {name: keyString, section: 'summary'},
        ascending: keyPrefix === '+',
      };
    }),
  };
}

export function defaultColumnWidth(k: RunKey) {
  if (k.section === 'run' && k.name === 'displayName') {
    return TABLE_DEFAULT_NAME_COLUMN_WIDTH;
  } else if (
    k.section === 'tags' ||
    (k.section === 'run' && k.name === 'username')
  ) {
    return TABLE_MIN_COLUMN_WIDTH;
  }
  return TABLE_DEFAULT_COLUMN_WIDTH;
}

export function getColumnWidth(tableSettings: ConfigV2, key: RunKey) {
  const columnKeyString = keyToString(key);
  return (
    (tableSettings.columnWidths &&
      tableSettings.columnWidths[columnKeyString]) ||
    defaultColumnWidth(key)
  );
}
