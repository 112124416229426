import * as _ from 'lodash';
import * as React from 'react';
import {Header, Segment} from 'semantic-ui-react';

import Markdown from '../../components/Markdown';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

const keys = ['val_acc', 'val_loss'];

const scoreCols = _.map(keys, k => ({
  Header: k,
  id: k,
  accessor: (run: WithSummary<BenchmarkRun>) => {
    return Run.displayValue(run.summary[k]);
  },
}));

const leaderboardCols = [...Leaderboard.cols, ...scoreCols];

const CONCEPT = `
This is the official leaderboard for the fashion mnist dataset. To start building
models and submitting results, click the "Join benchmark" button above.
`;

const SUBMISSION_INSTRUCTIONS = `
Runs in this project can be submitted to the public fashion mnist benchmark.

To submit a run to the public benchmark, first add a run to this project, by following
the README in the github starter code repository: https://github.com/wandb/fashion-mnist-benchmark-demo

After you've trained a model and have your results here in Weights & Biases, you can
submit your run to the public benchmark by using the menu that appears next to the run
name in the table.
`;

const overview = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <Header as="h3">Top submissions</Header>
      <WBReactTable
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, 'val_acc', false)}
      />
      <Header as="h3">Concept</Header>
      <Segment>
        <Markdown condensed={false} content={CONCEPT} />
      </Segment>
    </React.Fragment>
  );
};

const leaderboard = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <Header as="h3">Top submissions</Header>
      <WBReactTable
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, 'val_acc', false)}
      />
      <Header as="h3">By loss</Header>
      <WBReactTable
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, 'val_loss', false)}
      />
    </React.Fragment>
  );
};

const mnist: Theme = {
  keys: ['val_acc', 'val_loss'],
  benchmarkLink: 'https://github.com/wandb/fashion-mnist-benchmark-demo',
  overview,
  leaderboard,
  submissionGuidelines: SUBMISSION_INSTRUCTIONS,
};

export default mnist;
