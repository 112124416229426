// gql query for polling a run's system metrics.

import gql from 'graphql-tag';
import * as React from 'react';
import {Query} from 'react-apollo';
import {JSONparseNaN} from '@wandb/cg/browser/utils/jsonnan';
import makeComp from '../util/profiler';
import {captureError} from '../util/integrations';

///// Simple type helpers
// Type T minus the single key K
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// Type T minus all keys in K
type Subtract<T, K> = Omit<T, keyof K>;

///// Query definition

export const QUERY = gql`
  query RunSystemMetrics(
    $projectName: String!
    $entityName: String
    $runName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      run(name: $runName) {
        id
        events(samples: 1000)
      }
    }
  }
`;

// These types define the inputs and outputs of the above gql query

// Any variables required by the query.
interface Variables {
  entityName: string;
  projectName: string;
  runName: string;
}

interface ProjectData {
  run: RunData;
}

interface RunData {
  events: string[];
}

// The query's output shape.
interface Data {
  project: ProjectData;
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  entityName: string;
  projectName: string;
  runName: string;
  pollInterval: number;
}

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  systemMetrics: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  systemMetrics: string[];
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface QueryResultProps {
  runSystemMetricsQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withQuery = <P extends object>(
  Component: React.ComponentType<P & QueryResultProps>
) =>
  makeComp(
    (inputProps: Subtract<P, QueryResultProps> & InputProps) => {
      const {entityName, projectName, runName} = inputProps;
      const variables = {entityName, projectName, runName};
      return (
        <Query<Data, Variables>
          query={QUERY}
          variables={variables}
          pollInterval={inputProps.pollInterval}>
          {rawQueryResult => {
            const r: QueryResultProps = {
              runSystemMetricsQuery: {
                systemMetrics: undefined,
                loading: true,
              },
            };
            if (!rawQueryResult.loading) {
              if (rawQueryResult.data == null) {
                captureError(
                  'Unexpected Apollo error, loading: false, data: undefined',
                  'runSystemMetricsQuery',
                  {extra: {rawQueryResult}}
                );
                throw new Error(
                  'Unexpected Apollo error, loading: false, data: undefined'
                );
              }
              const systemMetrics = rawQueryResult.data.project.run.events.map(
                row => JSONparseNaN(row)
              );
              r.runSystemMetricsQuery = {
                loading: false,
                systemMetrics,
              };
            }
            return <Component {...(inputProps as P)} {...r} />;
          }}
        </Query>
      );
    },
    {id: 'runSystemMetricsQuery.withQuery'}
  );
