import * as _ from 'lodash';
import {RunsData} from '../containers/RunsDataLoader';
import {Subset} from '../types/base';
import {RunHistoryRow} from '../types/run';
import * as Run from '../util/runs';
import * as VegaLib from './vega';

export type QueryResult = Subset<RunsData, 'filtered' | 'histories'>;

export const EMPTY_QUERY_RESULT: QueryResult = {
  filtered: [],
  histories: {
    keyInfo: {
      sets: [],
      keys: {},
    },
    data: [],
  },
};

interface TableTypeRuns {
  type: 'run';
}

export interface TableTypeHistory {
  type: 'history';
  name: string;
  keySet: string[];
}

interface TableTypeHistoryPivot {
  type: 'history-pivot';
  baseKeySet: string[];
  pivotKeys: string[];
}

interface TableTypeRunField {
  type: 'run-field';
  tableName: string;
  key: string;
}

type TableType =
  | TableTypeRuns
  | TableTypeHistory
  | TableTypeHistoryPivot
  | TableTypeRunField;

interface Row {
  [key: string]: any;
}

export interface TableChangeSet {
  tableName: string;
  insertRows: Row[];
  remove(row: Row): boolean;
}

function vegaTableName(type: TableType): string {
  switch (type.type) {
    case 'run':
      return 'runs';
    case 'history':
      return 'history';
    // TODO
    case 'run-field':
      return 'run-field';
    // TODO
    case 'history-pivot':
      return 'history-pivot';
    default:
      throw new Error('programming error');
  }
}

function computeRunsChangeset(prevData: QueryResult, data: QueryResult) {
  const removeRunIds: string[] = [];
  const insertRuns: Run.Run[] = [];
  const updateRuns: Run.Run[] = [];

  const prevRuns = prevData.filtered;
  const runs = data.filtered;

  // compute removes
  for (const run of prevRuns) {
    if (_.find(runs, r => r.name === run.name) == null) {
      removeRunIds.push(run.name);
    }
  }

  // compute inserts / updates
  for (const run of runs) {
    const prevRun = _.find(prevRuns, r => r.name === run.name);
    if (prevRun == null) {
      // insert
      insertRuns.push(run);
    } else if (run.heartbeatAt > prevRun.heartbeatAt) {
      // TODO: use updatedAt instead for the check above.
      // (we don't have it available yet)
      // update
      updateRuns.push(run);
    }
  }
  return {removeRunIds, insertRuns, updateRuns};
}

function keySetID(keySet: string[]) {
  return _.sortBy(keySet).join('-');
}

function historyRowKeySetID(row: Row) {
  // row has runID and runName added. Remove them.
  return keySetID(_.keys(_.omit(row, ['runID', 'runName'])));
}

interface HistoryResult {
  runID: string;
  runName: string;
  data: RunHistoryRow[];
}

function findKeySetResult(
  history: QueryResult['histories']['data'],
  keySet: string[]
): {[key: string]: HistoryResult} {
  const hists = history
    .map(h => ({
      runID: h.name,
      runName: h.displayName,
      data: h.history.filter(
        hRow => keySetID(_.keys(hRow)) === keySetID(keySet)
      ),
    }))
    .filter(hObj => hObj.data.length > 0);
  return _.fromPairs(hists.map(h => [h.runID, h]));
}

function historyTableRows(hist: HistoryResult): Row[] {
  const {runID, runName} = hist;
  return _.flatMap(hist.data, r => {
    const tableKeys = Object.keys(r).filter(
      k => r[k] && r[k]._type === 'table'
    );
    if (tableKeys.length === 0) {
      return {...r, runID, runName};
    }
    const tkey = tableKeys[0];
    const {[tkey]: tval, ...restRow} = r;
    const columns: string[] = r[tkey].columns;
    return tval.data.map((tr: any[], rowNumber: number) => ({
      ...restRow,
      ...Object.assign({}, ...tr.map((trv, i) => ({[columns[i]]: trv}))),
      rowNumber,
      runID,
      runName,
    }));
  });
}

function computeHistoryChangeset(
  prevHistories: QueryResult['histories']['data'],
  histories: QueryResult['histories']['data'],
  keySet: string[]
) {
  const prevHistory = findKeySetResult(prevHistories, keySet);
  const history = findKeySetResult(histories, keySet);

  const removeRunIds: string[] = [];
  let insertRows: Row[] = [];

  for (const prevHist of _.values(prevHistory)) {
    if (history[prevHist.runID] == null) {
      removeRunIds.push(prevHist.runID);
    }
  }

  for (const hist of _.values(history)) {
    const prevRunHistory = prevHistory[hist.runID];
    const newRows = historyTableRows(hist);
    if (prevRunHistory == null) {
      insertRows = insertRows.concat(newRows);
    } else {
      if (
        !_.includes(keySet, '_step') &&
        hist.data.length !== prevRunHistory.data.length
      ) {
        removeRunIds.push(hist.runID);
        insertRows = insertRows.concat(newRows);
      } else {
        // When we have step, just insertRows newer than the max step that we've
        // previously seen.
        const maxStep = _.max(prevRunHistory.data.map(h => h._step || 0)) || 0;
        insertRows = insertRows.concat(newRows.filter(h => h._step > maxStep));
      }
    }
  }

  return {removeRunIds, insertRows, keySet};
}

function runToRunTableRow(run: Run.Run): Row {
  return {
    ...run,
    id: run.name,
    name: run.displayName,
    config: _.mapKeys(run.config, (v, k) => _.replace(k, '.value', '')),
  };
}

function fieldTableRunInfo(run: Run.Run): Row {
  return {runName: run.displayName, runID: run.name};
}

function makeFieldTable(runs: Run.Run[], key: string) {
  const k = Run.keyFromString(key);
  const dataset: Array<{[key: string]: any}> = [];
  for (const run of runs) {
    const val = k == null ? null : (Run.getValue(run, k) as any);
    if (_.isArray(val)) {
      for (const row of val) {
        if (_.isObject(row)) {
          dataset.push({...row, ...fieldTableRunInfo(run)});
        } else {
          dataset.push({...fieldTableRunInfo(run), data: row});
        }
      }
    } else {
      if (_.isObject(val)) {
        dataset.push({...val, ...fieldTableRunInfo(run)});
      } else {
        dataset.push({...fieldTableRunInfo(run), data: val});
      }
    }
  }
  return dataset;
}

export function computeTableDelta(
  prevData: QueryResult,
  data: QueryResult,
  tables: TableType[]
): TableChangeSet[] {
  const {removeRunIds, insertRuns, updateRuns} = computeRunsChangeset(
    prevData,
    data
  );

  const changeSets: TableChangeSet[] = [];

  for (const table of tables) {
    if (table.type === 'run') {
      changeSets.push({
        tableName: vegaTableName(table),
        remove: (row: Row) =>
          _.includes(removeRunIds, row.id) ||
          _.find(updateRuns, updateRun => updateRun.name === row.id) != null,
        insertRows: updateRuns.concat(insertRuns).map(runToRunTableRow),
      });
    } else if (table.type === 'history') {
      const change = computeHistoryChangeset(
        prevData.histories.data,
        data.histories.data,
        table.keySet
      );
      changeSets.push({
        tableName: table.name,
        remove: (row: Row) =>
          _.includes(change.removeRunIds, row.runID) &&
          historyRowKeySetID(row) === keySetID(change.keySet),
        insertRows: change.insertRows,
      });
    } else if (table.type === 'run-field') {
      changeSets.push({
        tableName: table.tableName,
        remove: (row: Row) =>
          _.includes(removeRunIds, row.runID) ||
          _.find(updateRuns, updateRun => updateRun.name === row.runID) != null,
        insertRows: makeFieldTable(updateRuns.concat(insertRuns), table.key),
      });
    }
  }

  return changeSets;
}

export interface VegaTable {
  name: string;
  values: Row[];
}

export function computeInitialTables(
  data: QueryResult,
  tables: TableType[]
): VegaTable[] {
  return computeTableDelta(EMPTY_QUERY_RESULT, data, tables).map(changeSet => ({
    name: changeSet.tableName,
    values: changeSet.insertRows,
  }));
}

export function SpecToTables(
  refs: VegaLib.Ref[],
  userSettings: VegaLib.UserSettings
): TableType[] {
  // run table
  let result: TableType[] = [];
  if (refs == null) {
    return [];
  }

  if (
    refs.filter(r => r.type === 'run-field' || r.type === 'run-field-list')
      .length > 0
  ) {
    result.push({type: 'run'});
  }

  // history tables
  const keySets = VegaLib.historyKeySets(
    refs,
    userSettings.historyFieldSettings
  );
  result = result.concat(
    keySets.map(ks => ({type: 'history', name: ks.tableName, keySet: ks.keys}))
  );

  // ref tables
  const runFieldTableRefs = refs.filter(
    r => r.type === 'run-field-table'
  ) as VegaLib.RunFieldTableRef[];
  if (runFieldTableRefs.length > 0) {
    for (const ref of runFieldTableRefs) {
      const key = userSettings.runFieldSettings[ref.tableName];
      if (key != null) {
        result.push({type: 'run-field', tableName: ref.tableName, key});
      }
    }
  }

  return result;
}
