// 'serverDelta' query strategy. This strategy asks the server
// to send us what's changed from our current page of results.

// See serverQuery.ts for function documentation.

import * as _ from 'lodash';

import * as Filter from '../../util/filters';
import * as QueryTS from '../../util/queryts';
import * as Run from '../../util/runs';
import {ApolloClient} from '../types';
import * as Api from './api';
import * as ServerQuery from './serverQuery';
import * as Types from './types';
import * as Update from '../../util/update';

// Refetch objects for up to a minute after their latest update time
// This is to account for any delay in metrics getting written to bigtable which
// happens after the last write to metadata.
const UPDATED_WINDOW = 60 * 1000;

export type ServerQueryDelta = Types.Query & {
  prevResult: {
    page: string[];
    maxUpdatedAt: string;
  };
};

interface ServerDeltaOpInsert {
  op: 'INSERT';
  index: number;
  run: Run.Run;
}

interface ServerDeltaOpUpdate {
  op: 'UPDATE';
  index: number;
  run: Run.Run;
}

interface ServerDeltaOpDelete {
  op: 'DELETE';
  index: number;
}

export type ServerDeltaOp =
  | ServerDeltaOpInsert
  | ServerDeltaOpUpdate
  | ServerDeltaOpDelete;

export interface ServerResultDelta {
  totalRuns: number;
  delta: ServerDeltaOp[];
}

function deltaKey(run: Run.Run, query: Types.Query): string {
  return query.grouping && query.grouping.length > 0 ? run.name : run.id;
}

function toServerQuery(
  query: Types.Query,
  prevResult: Types.QueryResult
): ServerQueryDelta {
  return {
    ...query,
    prevResult: {
      maxUpdatedAt: _.min([
        prevResult.lastUpdatedAt,
        new Date(Date.now() - UPDATED_WINDOW).toISOString(),
      ])!, // lodash typing always makes this possibly undefined, even if it can't be
      page: prevResult.runs.map(r => deltaKey(r, query)),
    },
  };
}

export function fromServerResult(
  query: Types.Query,
  serverResult: ServerResultDelta,
  prevResult: Types.QueryResult
): Types.QueryResult {
  // Note: this was refactored to not use immer for performance reasons
  // immer calculations were taking 200ms for 100 runs with 1500 points.
  let result = {...prevResult, totalRuns: serverResult.totalRuns};
  for (const op of serverResult.delta) {
    switch (op.op) {
      case 'INSERT':
        result = {
          ...result,
          runs: Update.insertArrayItem(result.runs, op.index, op.run),
          lastUpdatedAt:
            op.run.updatedAt > result.lastUpdatedAt
              ? op.run.updatedAt
              : result.lastUpdatedAt,
        };
        break;
      case 'DELETE':
        result = {
          ...result,
          runs: Update.deleteArrayIndex(result.runs, op.index),
        };
        break;
      case 'UPDATE':
        result = {
          ...result,
          runs: Update.updateArrayIndex(result.runs, op.index, op.run),
          lastUpdatedAt:
            op.run.updatedAt > result.lastUpdatedAt
              ? op.run.updatedAt
              : result.lastUpdatedAt,
        };
        break;
    }
  }

  return result;
}

function doQueryGraphql(client: ApolloClient, query: ServerQueryDelta) {
  return Api.doDeltaQuery(client, query);
}

function doQueryLocal(db: Run.Run[], query: ServerQueryDelta) {
  let runs = Filter.filterRuns(query.filters, db);
  runs = QueryTS.groupRuns(query.grouping, runs);
  runs = QueryTS.sortRuns(query.sort, runs);
  runs = runs.slice(0, query.limit);
  // Within each run, filter out history that doesn't match the keys requested by the query.
  runs = runs.map(r => {
    const queryKeys = query.historySpecs?.flatMap(hs => hs.keys) ?? null;
    if (queryKeys) {
      let filteredHistory = r.sampledHistory?.map(sh =>
        sh.map(shs => _.pick(shs, queryKeys))
      );
      filteredHistory = filteredHistory?.filter(fh =>
        fh.every(fhs => {
          const histKeys = _.keys(_.omit(fhs, '_step'));
          return histKeys.length > 0;
        })
      );
      return {...r, sampledHistory: filteredHistory};
    }
    return r;
  });

  const ops: ServerDeltaOp[] = [];
  const page = query.prevResult.page;

  // Make delete ops. Iterate from back so indices are correct after each delete.
  const delIndexes: number[] = [];
  _.forEachRight(page, (pi, i) => {
    if (_.findIndex(runs, r => pi === deltaKey(r, query)) !== i) {
      ops.push({op: 'DELETE', index: i});
      delIndexes.push(i);
    }
  });
  for (const i of delIndexes) {
    page.splice(i, 1);
  }

  // Then inserts
  _.forEach(runs, (r, i) => {
    if (_.find(page, pi => pi === deltaKey(r, query)) == null) {
      ops.push({op: 'INSERT', index: i, run: r});
      page.splice(i, 0, deltaKey(r, query));
    } else if (r.updatedAt > query.prevResult.maxUpdatedAt) {
      ops.push({op: 'UPDATE', index: i, run: r});
    }
  });

  _.zip(runs, page).forEach(([r, pi]) => {
    if (r == null || pi == null) {
      throw new Error('Invalid update alg');
    }
    if (deltaKey(r, query) !== pi) {
      throw new Error('Invalid update alg');
    }
  });

  // Then updates
  return {totalRuns: db.length, delta: ops};
}

// Boilerplate

export const TransformPostFilter: ServerQuery.Transform<
  ServerQueryDelta,
  ServerResultDelta
> = {
  toServerQuery,
  fromServerResult,
};

export const STRATEGY_GRAPHQL: ServerQuery.StrategyGraphql<
  ServerQueryDelta,
  ServerResultDelta
> = {
  ...TransformPostFilter,
  doQuery: doQueryGraphql,
};
export const STRATEGY_LOCAL: ServerQuery.StrategyLocal<
  ServerQueryDelta,
  ServerResultDelta
> = {
  ...TransformPostFilter,
  doQuery: doQueryLocal,
};
