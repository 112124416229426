// This is the W&B run table.
//
// It's very important to consider performance whne making changes here.
// This file in combination with WBTable* has a lots of performance
// optimizations, mainly around making sure we do immutable updates
// so we can memoize compute heavy tasks, and shallow compare props
// in componentShouldUpdate methods.
//
// Please be careful when editing! As of now we don't have performance
// regression tests in place.

import '../../css/RunSelector.less';

import * as _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {Link, Redirect} from 'react-router-dom';

import BulkTagEditor, {TagState} from '../../components/Tags/BulkTagEditor';
import {
  toRunsDataQuery,
  WithRunsDataLoader,
  RunWithRunsetInfo,
} from '../../containers/RunsDataLoader';
import {
  combinedFilter,
  useRunSelectorQuery,
} from '../../state/graphql/runSelectorQuery';
import {useSelectedCounts} from '../../state/graphql/selectedCountQuery';
import {makeDidPropsOrStateChange} from '../../util/shouldUpdate';
import {useRunsQueryContext} from '../../state/runs/hooks';
import * as GroupSelectionsActions from '../../state/views/groupSelections/actions';
import * as FilterActions from '../../state/views/filter/actions';
import * as SortActions from '../../state/views/sort/actions';
import * as ViewerHooks from '../../state/viewer/hooks';
import * as CustomRunColorsTypes from '../../state/views/customRunColors/types';
import * as RunSetTypes from '../../state/views/runSet/types';
import * as ViewHooks from '../../state/views/hooks';
import * as RunHooks from '../../state/runs/hooks';
import * as RunSetActions from '../../state/views/runSet/actions';
import * as TempSelectionsActions from '../../state/views/tempSelections/actions';
import * as TempSelectionsTypes from '../../state/views/tempSelections/types';
import {BenchmarkProject} from '../../types/graphql';
import * as Filter from '../../util/filters';
import * as SelectionManager from '../../util/selectionmanager';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Query from '../../util/queryts';
import {
  Config as TableSettings,
  EMPTY_CONFIG as EMPTY_TABLE_SETTINGS,
} from '../../util/runfeed';
import * as Generated from '../../generated/graphql';
import * as RunHelpers2 from '../../util/runhelpers';
import * as Run from '../../util/runs';
import * as TableCols from '../../util/tablecols';
import * as urls from '../../util/urls';
import DeleteRunsButton from '../DeleteRunsButton';
import EditableLabel from '../elements/EditableLabel';
import TimeDisplay from '../elements/TimeDisplay';
import ProjectPicker from '../ProjectPicker';
import RunMover from '../RunMover';
import RunsSearchBar from '../RunsSearchBar';
import {Tags} from '../Tags';
import RunSelectorRowHeader from './RunSelectorRowHeader';
import {WBTable, WBTableProps, WBTableComp} from '../WBTable/WBTable';
import {WBTableCellDefault} from '../WBTable/WBTableCellDefault';
import {WBTableCellCreatedAt} from '../WBTable/WBTableCellCreatedAt';
import {RunsAutoCols} from './RunsAutoCols';
import RunsDownloadMenu from './RunsDownloadMenu';
import {RunsColumnEditor} from './RunsColumnEditor';
import {RunsGroupTableAction} from './RunsGrouping';
import {RunsSortTableAction} from './RunsSort';
import WBTableActionOverflow from '../WBTable/WBTableActionsOverflow';
import {makeRunsSortIndicator} from './RunsSortIndicator';
import RunsTableHeader from './RunsTableHeader';
import {TagAddButton} from '../TagAddButton';
import {getSweepDisplayName} from '../../util/sweeps';
import {useDispatch} from '../../state/hooks';
import {setCreateSweepArgs} from '../../state/sweeps/actions';
import produce from 'immer';
import makeComp from '../../util/profiler';
import {RunsFreezeAndFilter} from './RunsFreeze';
import {useBioFeatureFlagEnabled} from '../../util/featureFlags';

/*
 * RunSelectorInner is WBTable, wrapped with RunSelectorInnerQuery (to fetch tags)
 * There is one top-level RunsDataLoader query, and one query for each expanded group (see <WBTableRow>)
 */

const FIXED_COLUMNS = ['run:displayName'];
const CELL_FILTER_BLACKLIST: Run.Key[] = [
  {section: 'run', name: 'github'},
  {section: 'run', name: 'runInfo.gpu'},
  {section: 'run', name: 'runInfo.gpuCount'},
];

type RunSelectorFeature =
  | 'create-sweep'
  | 'push-to-run-queue'
  | 'toggle-visibility'
  | 'filter'
  | 'group'
  | 'sort'
  | 'tag'
  | 'move'
  | 'delete'
  | 'export'
  | 'auto-cols'
  | 'edit-cols';
export type DisabledFeaturesMap = {[F in RunSelectorFeature]?: boolean};

interface RunSelectorInnerProps {
  className?: string;
  pageEntityName: string;
  pageProjectName: string;
  expandable?: boolean;
  expanded?: boolean;
  defaultKeys?: Run.Key[]; // fka baseKeys
  pollInterval: number;
  benchmarkMeta?: BenchmarkProject;
  displayBenchmark?: boolean;
  customRunColorsRef: CustomRunColorsTypes.Ref;
  runSetRef: RunSetTypes.Ref;
  tempSelectionsRef: TempSelectionsTypes.Ref;
  title?: string;
  isSingleMode?: boolean;
  selectedRunName?: string;
  onSelectRunName?: (runId: string) => void;
  enableSetProject?: boolean;
  enableFreezeRunset?: boolean;
  recommendedGrouping?: Query.Grouping;
  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  readOnly?: boolean;
  disabledFeatures?: DisabledFeaturesMap;
  disableRunLinks?: boolean;
  onHeightChange?(): void;
}

type AllRunSelectorProps = RunSelectorInnerProps &
  ReturnType<typeof useRunSelectorProps>;

interface RunSelectorInnerState {
  reloadIndex: number;
  tableMessage?: JSX.Element;
  creatingSweep: boolean;
  currentPage: number;
  sortPopupOpen: boolean;
}

class RunSelectorInnerComp extends React.Component<
  AllRunSelectorProps,
  RunSelectorInnerState
> {
  state: RunSelectorInnerState = {
    reloadIndex: 0,
    tableMessage: undefined,
    creatingSweep: false,
    currentPage: 1,
    sortPopupOpen: false,
  };

  didPropsOrStateChange = makeDidPropsOrStateChange({
    name: 'RunSelector',
    debug: false,
    verbose: true,
  });

  tableRef = React.createRef<WBTableComp>();

  filteredKeys = memoize(
    (paths: string[] | undefined): Run.Key[] => {
      if (paths == null) {
        return [];
      }
      const suggested = RunHelpers2.keySuggestions(paths);
      if (suggested != null) {
        return TableCols.filterLegacyKeys(
          suggested.map(Run.keyFromString).filter(Obj.notEmpty)
        );
      }
      return [];
    },
    // Note: We're using string length comparison as a check here, because this can
    // be a very long string (one customer has 4000 columns with long names, which makes
    // this string 900kB)
    (newArgs, oldArgs) => {
      const [newPaths] = newArgs;
      const [oldPaths] = oldArgs;
      if (newPaths == null && oldPaths == null) {
        return true;
      } else if (newPaths != null && oldPaths != null) {
        return newPaths.length === oldPaths.length;
      }
      return false;
    }
  );

  cleanedTableSettings = memoize(
    (
      tableSettings: TableSettings,
      filteredKeys: Run.Key[],
      defaultKeys: Run.Key[],
      hideNewKey?: boolean
    ) => {
      return TableCols.cleanRunFeedConfig(
        tableSettings,
        filteredKeys,
        defaultKeys,
        hideNewKey
      );
    }
  );

  getTopLevelQueryVariables = memoize(
    (
      entityName: string,
      projectName: string,
      grouping: Query.Grouping,
      columnOrder: string[],
      queryVariables: any
    ) => {
      const fetchColumns: Run.Key[] = [
        ...grouping,
        ...(columnOrder
          .map(Run.keyFromString)
          .filter(key => !!key) as Run.Key[]),
      ];

      const configKeys = fetchColumns
        .filter(key => key.section === 'config')
        .map(key => key.name);
      const summaryKeys = fetchColumns
        .filter(key => key.section === 'summary')
        .map(key => key.name);
      const wandbKeys = ['cli_version', 'is_jupyter_run'];

      // Merge state.searchFilter with current filters
      const topLevelQueryVariables: Query.Query = {
        ...queryVariables,
        entityName,
        projectName,
        filters: Query.addSearchFilter(
          queryVariables.filters,
          this.props.runSet.search.query,
          queryVariables.grouping
        ),
        configKeys,
        summaryKeys,
        wandbKeys,
      };
      return topLevelQueryVariables;
    },
    (newArgs, oldArgs) => {
      const [entityName, projectName, grouping, columnOrder, queryVariables] =
        newArgs;
      const [
        oldEntityName,
        oldProjectName,
        oldGrouping,
        oldColumnOrder,
        oldQueryVariables,
      ] = oldArgs;
      return (
        entityName === oldEntityName &&
        projectName === oldProjectName &&
        grouping === oldGrouping &&
        columnOrder === oldColumnOrder &&
        queryVariables === oldQueryVariables
      );
    }
  );

  rows = memoize((rowData: RunWithRunsetInfo[], selectedRunName?: string) => {
    return rowData.map(r => {
      return {
        ...r,
        selectedRunName,
        __address__: TableCols.makeRowAddressComponent(r.name, r.runsetInfo.id),
      };
    });
  });

  lastRowCount = 0;

  allColumns = memoize(
    (
      entityName: string,
      projectName: string,
      defaultKeys: Run.Key[],
      filteredKeys: Run.Key[],
      tableSettings: TableSettings,
      totalRowCount: number,
      benchmarkMeta: BenchmarkProject | undefined,
      displayBenchmark: boolean | undefined
    ) => {
      const {setTableSettings, selectAll, selectNone, disableRunLinks} =
        this.props;
      const filteredKeyStrings = filteredKeys.map(k => Run.keyToString(k));
      const orderKeys = tableSettings.columnOrder
        .filter(k => !_.includes(filteredKeyStrings, k))
        .map(k => Run.keyFromString(k)!);
      return TableCols.unionKeys(defaultKeys, [
        ...filteredKeys,
        ...orderKeys,
      ]).map(key => {
        return TableCols.keyToColumn(key, {
          renderHeader: Run.keysEqual(key, {
            section: 'run',
            name: 'displayName',
          })
            ? (displayedRows: Run.Run[]) => {
                return (
                  <RunsTableHeader
                    isSingleMode={this.props.isSingleMode}
                    totalRowCount={totalRowCount}
                    displayedRows={displayedRows}
                    runSetRef={this.props.runSetRef}
                    tempSelectionsRef={this.props.tempSelectionsRef}
                    showTempSelection={
                      !this.props.expandable || !!this.props.expanded
                    }
                    onlyShowSelected={tableSettings.onlyShowSelected}
                    readOnly={this.props.readOnly}
                    disabledFeatures={this.props.disabledFeatures}
                    onAllItemsTempSelected={() => {
                      // Show the "select all runs in project?" prompt.
                      // TODO(views): This could maybe be factored out somehow.
                      if (totalRowCount > displayedRows.length) {
                        const isGrouped = !_.isEmpty(
                          this.props.groupSelections.grouping
                        );
                        this.setState({
                          tableMessage: (
                            <>
                              All <strong>{displayedRows.length}</strong>
                              {isGrouped ? ' groups ' : ' runs '} on this page
                              are selected.{' '}
                              <span
                                className="fake-link"
                                onClick={() => {
                                  this.props.tempSelectAll();
                                  this.clearTableMessage();
                                }}>
                                Select all
                                {isGrouped ? ' groups ' : ' runs '} in this
                                project
                              </span>
                            </>
                          ),
                        });
                      }
                    }}
                    onAllItemsTempUnselected={this.clearTableMessage}
                    // TODO: Cleanup step: move these callbacks into RunsTableHeader.
                    onVisibilitySelectNone={selectNone}
                    onVisibilitySelectAll={selectAll}
                    onSetOnlyShowSelected={newSetting => {
                      setTableSettings({
                        ...tableSettings,
                        onlyShowSelected: newSetting,
                      });
                      this.reload();
                    }}
                  />
                );
              }
            : undefined,
          renderCell: (
            column,
            row: RunHelpers2.WBTableRowWithRunSetInfo,
            {
              addFilter,
              toggleExpandedRow,
              recursionDepth,
              isGroup,
              isExpanded,
              loadingChildren,
              hoveringRow,
            }
          ) => {
            const isGroupRun = isGroup && row.group === row.name;
            const columnKey = column.key;
            const value = Run.getValue(row, columnKey);
            if (CELL_FILTER_BLACKLIST.some(f => Run.keysEqual(columnKey, f))) {
              return (
                <WBTableCellDefault columnKey={columnKey} cellValue={value} />
              );
            } else if (columnKey.section === 'tags') {
              const allTags =
                !this.props.runSelectorQuery.initialLoading &&
                this.props.runSelectorQuery.project.tags;
              return (isGroup && !isGroupRun) || !allTags ? (
                ''
              ) : (
                <>
                  <Tags
                    tags={row.tags}
                    onClick={tag =>
                      !this.props.readOnly &&
                      addFilter &&
                      addFilter({section: 'tags', name: tag}, '=', true)
                    }
                    enableDelete={!this.props.readOnly}
                    deleteTag={tag =>
                      isGroup
                        ? this.deleteGroupTag(row, tag.name)
                        : this.deleteTag(row, tag.name)
                    }
                  />
                  {!this.props.readOnly && (
                    <div className="icon-wrap">
                      <TagAddButton
                        compact
                        direction="bottom right"
                        tags={row.tags}
                        availableTags={allTags}
                        addTag={async tag =>
                          isGroup
                            ? this.addGroupTag(row, tag.name)
                            : this.addTag(row, tag.name)
                        }
                        iconOnly
                      />
                    </div>
                  )}
                </>
              );
            } else if (
              Run.keysEqual(columnKey, {section: 'run', name: 'createdAt'})
            ) {
              return (
                <WBTableCellCreatedAt
                  createdAt={row.createdAt}
                  addFilter={addFilter}
                />
              );
            } else if (
              Run.keysEqual(columnKey, {section: 'run', name: 'duration'})
            ) {
              return (
                <TimeDisplay
                  timestamp={new Date(row.createdAt)}
                  format="month_round"
                  now={new Date(row.heartbeatAt)}
                />
              );
            } else if (
              Run.keysEqual(columnKey, {section: 'run', name: 'notes'})
            ) {
              return (
                <EditableLabel
                  placeholder={this.props.readOnly ? '-' : 'Add notes...'}
                  readOnly={isGroup && !isGroupRun && this.props.readOnly}
                  onSave={(newNotes: string) => {
                    isGroupRun
                      ? this.saveRunGroupNotes(row, newNotes)
                      : this.saveRunNotes(row, newNotes);
                  }}
                  serverText={row.notes}
                  showPopup
                />
              );
            } else if (
              Run.keysEqual(columnKey, {
                section: 'run',
                name: 'sweep',
              })
            ) {
              return (
                <>
                  {row.sweep ? (
                    disableRunLinks ? (
                      <WBTableCellDefault
                        columnKey={columnKey}
                        cellValue={getSweepDisplayName(row.sweep)}
                        addFilter={addFilter}
                      />
                    ) : (
                      <Link
                        to={urls.sweep({
                          entityName,
                          projectName,
                          sweepName: row.sweep.name,
                        })}>
                        {getSweepDisplayName(row.sweep)}
                      </Link>
                    )
                  ) : (
                    '-'
                  )}
                </>
              );
            } else if (
              Run.keysEqual(columnKey, {
                section: 'run',
                name: 'displayName',
              })
            ) {
              return (
                <RunSelectorRowHeader
                  entityName={entityName}
                  projectName={projectName}
                  tableIsCollapsed={
                    this.props.expandable && !this.props.expanded
                  }
                  recursionDepth={recursionDepth}
                  row={row}
                  customRunColorsRef={this.props.customRunColorsRef}
                  loadingChildren={loadingChildren}
                  isExpanded={!!isExpanded}
                  isSingleMode={this.props.isSingleMode}
                  showArtifactCounts={this.props.showArtifactCounts}
                  showLogCounts={this.props.showLogCounts}
                  selectedRunName={this.props.selectedRunName}
                  onSelectRunName={this.props.onSelectRunName}
                  isGroup={isGroup}
                  groupSelectionsRef={this.props.runSet.groupSelectionsRef}
                  tempSelectionsRef={this.props.tempSelectionsRef}
                  benchmarkMeta={benchmarkMeta}
                  displayBenchmark={displayBenchmark}
                  displayTempCheckbox={!!hoveringRow}
                  readOnly={this.props.readOnly}
                  disabledFeatures={this.props.disabledFeatures}
                  disableRunLink={disableRunLinks}
                  // Actual group selection logic is now pushed down to this component.
                  // We just want to know about changes so we can call hintClose.
                  // TODO(views): There is probably a better pattern for this?
                  onVisibilityCheckboxClick={() => {
                    if (this.tableRef && this.tableRef.current) {
                      this.tableRef.current.hintClose();
                    }
                  }}
                  onTempCheckboxClick={this.clearTableMessage}
                  onGroupButtonClick={() => {
                    if (isGroup) {
                      return (
                        toggleExpandedRow && toggleExpandedRow(row.__address__)
                      );
                    }
                  }}
                  saveRunName={this.saveRunName}
                  deleteRun={(id, deleteArtifacts) =>
                    this.props
                      .deleteRun({variables: {id, deleteArtifacts}})
                      .then(this.reload)
                  }
                  stopRun={id =>
                    this.props.stopRun({variables: {id}}).then(this.reload)
                  }
                  runQueuesEnabled={this.props.runQueuesEnabled}
                />
              );
            } else if (value != null) {
              return (
                <WBTableCellDefault
                  columnKey={columnKey}
                  cellValue={value}
                  addFilter={addFilter}
                />
              );
            } else {
              return '-';
            }
          },
        });
      });
    },
    (newArgs, oldArgs) => {
      if (newArgs.length !== oldArgs.length) {
        return false;
      }
      const [
        entityName,
        projectName,
        defaultKeys,
        filteredKeys,
        tableSettings,
        totalRowCount,
        benchmarkMeta,
        displayBenchmark,
      ] = newArgs;
      const [
        oldEntityName,
        oldProjectName,
        oldDefaultKeys,
        oldFilteredKeys,
        oldTableSettings,
        oldTotalRowCount,
        oldBenchmarkMeta,
        oldDisplayBenchmark,
      ] = oldArgs;
      return (
        entityName === oldEntityName &&
        projectName === oldProjectName &&
        _.isEqual(defaultKeys, oldDefaultKeys) &&
        filteredKeys === oldFilteredKeys &&
        tableSettings === oldTableSettings &&
        totalRowCount === oldTotalRowCount &&
        _.isEqual(benchmarkMeta, oldBenchmarkMeta) &&
        displayBenchmark === oldDisplayBenchmark
      );
    }
  );

  getExtraTableActionsLeft = memoize(
    (
      entityName,
      projectName,
      enableSetProject,
      enableFreezeRunset,
      groupSelections,
      username,
      tempSelectNone,
      tags,
      showOverflowButton,
      disabledFeatures
    ) => (
      <>
        {enableSetProject && (
          <ProjectPicker
            entityName={entityName}
            projectName={projectName}
            setProject={this.props.setProject}
          />
        )}
        <RunsSearchBar
          isGroupSearch={Query.isGroupedByGroup(groupSelections.grouping)}
          searchQuery={this.props.runSet.search.query}
          setSearch={query => {
            if (this.tableRef.current) {
              this.tableRef.current.resetPagination();
            }
            this.props.setSearch(query);
          }}
        />
        {/*// In a div so that the actions stay on the same row*/}
        <div>
          <RunsFreezeAndFilter
            enableFreezeRunset={enableFreezeRunset}
            filtersRef={this.props.runSet.filtersRef}
            entityName={entityName}
            projectName={projectName}
            username={username}
            compact={!!this.props.expandable && !this.props.expanded}
            onChange={() => {
              if (this.tableRef.current != null) {
                this.tableRef.current.resetPagination();
              }
            }}
          />
          <RunsGroupTableAction
            entityName={entityName}
            projectName={projectName}
            groupSelectionsRef={this.props.runSet.groupSelectionsRef}
            recommendedGrouping={this.props.recommendedGrouping}
            compact={!!this.props.expandable && !this.props.expanded}
            onGroupingChanged={() => {
              this.tableRef.current?.resetPagination();
              tempSelectNone();
            }}
          />
          <RunsSortTableAction
            entityName={entityName}
            projectName={projectName}
            sortRef={this.props.runSet.sortRef}
            compact={!!this.props.expandable && !this.props.expanded}
            open={this.state.sortPopupOpen}
            onOpen={() => this.setState({sortPopupOpen: true})}
            onClose={() => this.setState({sortPopupOpen: false})}
            onSortChanged={() =>
              this.tableRef.current && this.tableRef.current.resetPagination()
            }
          />
          {this.props.expandable && !this.props.expanded ? (
            <></>
          ) : (
            <>
              {/* BATCH TAGGING, MOVING, DELETING */}
              <BulkTagEditor
                // TODO(views): Here and below, this is a bad way to pass these counts through as it
                // won't always update.
                selectedCount={this.props.tempSelectedCount}
                tags={tags}
                addTag={(tag: string) => this.bulkUpdateTag('add', tag)}
                removeTag={(tag: string) => this.bulkUpdateTag('remove', tag)}
              />
              <RunMover
                entityName={entityName}
                projectName={projectName}
                selectedCount={this.props.tempSelectedCount}
                refetch={() => this.reload()}
                onMove={(entity, proj) => this.moveRuns(entity, proj)}
              />
              <DeleteRunsButton
                selectedCount={this.props.tempSelectedCount}
                onDelete={(deleteArtifacts: boolean) =>
                  this.deleteRuns(deleteArtifacts)
                }
                outputArtifactsCount={this.props.tempOutputArtifactsCount}
              />
              {showOverflowButton && (
                <WBTableActionOverflow
                  options={[
                    ...(!disabledFeatures?.['create-sweep']
                      ? [
                          {
                            key: 'sweep',
                            icon: 'wbic-ic-sweep',
                            text: 'Create Sweep',
                            onClick: this.createSweepWithPriorRuns,
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </>
          )}
        </div>
      </>
    )
  );

  getExtraTableActionsRight = memoize(
    (
      allQueryVariables,
      topLevelQuery,
      allColumns,
      cleanedTableSettings,
      currentPage
    ) => {
      return (
        <>
          <RunsDownloadMenu
            entityName={this.props.pageEntityName}
            projectName={this.props.pageProjectName}
            pageQuery={allQueryVariables}
            query={topLevelQuery}
            tableSettings={cleanedTableSettings}
          />
          <RunsAutoCols
            query={allQueryVariables}
            tableSettings={cleanedTableSettings}
            setTableSettings={this.props.setTableSettings}
            currentPage={currentPage}
            columns={allColumns}
          />
        </>
      );
    }
  );

  saveRunNotes = _.debounce((run: Run.Run, newNotes: string) => {
    const currentNotes = run.notes;
    if (newNotes !== currentNotes) {
      // Sending undefined values for displayName or notes clears them, so
      // we always need to send both.
      this.props.updateRun(run.id, run.name, {
        displayName: run.displayName,
        notes: newNotes,
      });
    }
  }, 500);

  saveRunGroupNotes = _.debounce((run: Run.Run, newNotes: string) => {
    const {group: name, tags} = run;
    const {entityName, projectName} = this.props;
    this.props.updateGroupRun({
      variables: {
        projectName,
        entityName,
        name,
        tags: tags.map(t => t.name),
        notes: newNotes,
      },
    });
  }, 500);

  updateSort = (updateFn: (sort: Query.Sort) => void) => {
    const {sort, setSort} = this.props;
    const newSort = produce(sort, updateFn);
    setSort(newSort);
  };

  clearTableMessage = (callback?: () => void) => {
    this.setState({tableMessage: undefined}, () => {
      if (callback) {
        callback();
      }
    });
  };

  shouldComponentUpdate(
    nextProps: RunSelectorInnerProps,
    nextState: RunSelectorInnerState
  ): boolean {
    return this.didPropsOrStateChange(
      [this.props, nextProps],
      [this.state, nextState]
    );
  }

  saveRunName = (run: Run.Run, newName: string) => {
    const currentName = run.displayName || run.name;
    if (newName !== currentName) {
      // Sending undefined values for displayName or notes clears them, so
      // we always need to send both.
      this.props.updateRun(run.id, run.name, {
        displayName: newName,
        notes: run.notes,
      });
    }
  };

  addTag = (run: Run.Run, newTagName: string) => {
    const currentTagNames = run.tags.map(t => t.name);
    if (!_.includes(currentTagNames, newTagName)) {
      this.props.updateRunTags(run, [...currentTagNames, newTagName]);
    }
  };

  addGroupTag = (run: Run.Run, newTagName: string) => {
    const currentTagNames = run.tags.map(t => t.name);
    if (currentTagNames.includes(newTagName)) {
      return;
    }

    this.props.updateGroupRunTags(run, [...currentTagNames, newTagName]);
  };

  deleteTag = (run: Run.Run, removeTagName: string) => {
    const currentTagNames = run.tags.map(t => t.name);
    if (_.includes(currentTagNames, removeTagName)) {
      this.props.updateRunTags(
        run,
        currentTagNames.filter(t => t !== removeTagName)
      );
    }
  };

  deleteGroupTag = (run: Run.Run, removeTagName: string) => {
    const currentTagNames = run.tags.map(t => t.name);
    if (!currentTagNames.includes(removeTagName)) {
      return;
    }
    this.props.updateGroupRunTags(
      run,
      currentTagNames.filter(t => t !== removeTagName)
    );
  };

  bulkUpdateTag(actionType: 'add' | 'remove', tagName: string): void {
    const {entityName, projectName} = this.props;
    const queryFilters = this.combinedFilter();
    this.props
      .bulkUpdateRunTags({
        entityName,
        projectName,
        filters: JSON.stringify(queryFilters),
        [actionType === 'add' ? 'addTags' : 'removeTags']: [tagName],
      })
      .then(() => {
        // Refreshes the <BulkTagEditor> menu
        if (
          !this.props.runSelectorQuery.loading &&
          this.props.runSelectorQuery.refetch
        ) {
          this.props.runSelectorQuery.refetch();
        }
      });
  }

  moveRuns(
    destinationEntityName: string,
    destinationProjectName: string
  ): Promise<string> {
    const {entityName, projectName} = this.props;
    const queryFilters = this.combinedFilter();

    return this.props
      .moveRuns({
        variables: {
          sourceEntityName: entityName,
          sourceProjectName: projectName,
          destinationEntityName,
          destinationProjectName,
          filters: JSON.stringify(queryFilters),
        },
      })
      .then(response => {
        if (response.data == null || response.data.moveRuns == null) {
          throw new Error('Unexpected response');
        }
        return response.data.moveRuns.task.id;
      });
  }

  deleteRuns(deleteArtifacts: boolean): void {
    const {entityName, projectName} = this.props;
    const queryFilters = this.combinedFilter();

    this.props
      .deleteRuns({
        variables: {
          entityName,
          projectName,
          filters: JSON.stringify(queryFilters),
          deleteArtifacts,
        },
      })
      .then(this.reload);
  }

  createSweepWithPriorRuns = () => this.setState({creatingSweep: true});

  combinedFilter = () => {
    const {filters, mergeFilters, runSet} = this.props;
    const groupTempSelection = this.props.groupTempSelection;

    return combinedFilter(
      filters,
      groupTempSelection,
      undefined,
      mergeFilters,
      runSet.search.query
    );
  };

  reload = () => {
    this.setState({
      reloadIndex: this.state.reloadIndex + 1,
    });
    if (
      !this.props.runSelectorQuery.loading &&
      this.props.runSelectorQuery.refetch
    ) {
      this.props.runSelectorQuery.refetch();
    }
  };

  columnEditor = (
    config: TableSettings,
    setTableSettings: (config: TableSettings) => void
  ) => {
    const {entityName, projectName} = this.props;
    return (
      <RunsColumnEditor
        entityName={entityName}
        projectName={projectName}
        config={config}
        fixedColumns={FIXED_COLUMNS}
        update={setTableSettings}
      />
    );
  };

  onSetCurrentPage = (currentPage: number) => {
    this.setState({currentPage});
  };

  openSortPopup = () => this.setState({sortPopupOpen: true});

  render() {
    const {
      entityName,
      projectName,
      tableSettings,
      queryVariables,
      benchmarkMeta,
      displayBenchmark,
      setGrouping,
      enableSetProject,
      enableFreezeRunset,
      groupSelections,
      report,
      runSetFull,
      tempSelections,
      tempSelectNone,
      dispatch,
      disabledFeatures,
      sweep,
      onHeightChange,
    } = this.props;
    const {reloadIndex, tableMessage, creatingSweep, currentPage} = this.state;

    if (creatingSweep) {
      dispatch(
        setCreateSweepArgs({
          runSet: runSetFull,
          tempSelections,
        })
      );
      return (
        <Redirect to={`/${entityName}/${projectName}/create-sweep`} push />
      );
    }

    const defaultKeys =
      this.props.defaultKeys ??
      (sweep == null
        ? TableCols.DEFAULT_RUNSELECTOR_KEYS
        : sweep.earlyTerminate
        ? TableCols.DEFAULT_SWEEP_EARLY_TERMINATE_KEYS
        : TableCols.DEFAULT_SWEEP_KEYS); // fka baseKeys

    const runSelectorQuery = this.props.runSelectorQuery;
    const username = this.props.viewer ? this.props.viewer.username : '';

    const project = runSelectorQuery.initialLoading
      ? undefined
      : runSelectorQuery.project;
    const filteredKeys = this.filteredKeys(
      project ? project.fields.edges.map(e => e.node.path) : undefined
    );

    let tags: TagState[] = [];
    if (project) {
      const counts: {[key: string]: number} = {};
      project.tagCounts.forEach(t => {
        counts[t.name] = t.count;
      });
      tags = project.tags.map(tag => ({
        name: tag.name,
        colorIndex: tag.colorIndex,
        count: counts[tag.name] || 0,
      }));
    }

    const cleanedTableSettings = this.cleanedTableSettings(
      tableSettings || EMPTY_TABLE_SETTINGS,
      filteredKeys,
      defaultKeys,
      report != null
    );

    const topLevelQueryVariables = this.getTopLevelQueryVariables(
      entityName,
      projectName,
      groupSelections.grouping,
      cleanedTableSettings.columnOrder,
      queryVariables
    );

    const topLevelQuery = toRunsDataQuery(
      topLevelQueryVariables,
      {selectionsAsFilters: Boolean(cleanedTableSettings!.onlyShowSelected)},
      {
        page: {
          size: cleanedTableSettings.pageSize || EMPTY_TABLE_SETTINGS.pageSize,
        },
        disabled: runSelectorQuery.initialLoading,
        historyKeyInfo: false,
      }
    );

    const showOverflowButton = !disabledFeatures?.['create-sweep'];

    return (
      <>
        <WithRunsDataLoader key={reloadIndex} query={topLevelQuery}>
          {injectedProps => {
            const {data, loading} = injectedProps;
            const rowData = data && data.filtered;
            const {selectedRunName} = this.props;
            const rows = this.rows(rowData, selectedRunName);
            const totalRowCount = data && data.totalRuns;
            const allColumns = this.allColumns(
              entityName,
              projectName,
              defaultKeys,
              filteredKeys,
              cleanedTableSettings,
              totalRowCount,
              benchmarkMeta,
              displayBenchmark
            );

            if (rows.length !== this.lastRowCount) {
              this.lastRowCount = rows.length;
              onHeightChange?.();
            }

            const tableProps: WBTableProps = {
              className:
                'run-selector' +
                (this.props.className ? ` ${this.props.className}` : '') +
                (this.props.expandable ? ` expandable` : '') +
                (this.props.readOnly ? ` read-only` : ''),
              tableSettings: cleanedTableSettings,
              rows,
              columns: allColumns,
              fixedColumns: FIXED_COLUMNS,
              totalRowCount,
              loading: loading || data.initialLoading,
              tableLoadMore: data && data.loadMore,
              expandedRowAddresses: groupSelections.expandedRowAddresses,
              toggleExpandedRow: this.props.toggleExpandedRow,
              grouping: groupSelections.grouping,
              filters: this.props.filters,
              SortIndicatorComponent: makeRunsSortIndicator(
                this.props.runSet.sortRef
              ),
              setTableSettings: this.props.setTableSettings,
              updateSort: this.updateSort,
              setFilters: this.props.setFilters,
              expandable: this.props.expandable,
              expanded: this.props.expanded,
              isSingleMode: this.props.isSingleMode,
              showArtifactCounts: this.props.showArtifactCounts,
              showLogCounts: this.props.showLogCounts,
              title: this.props.title,
              onSetExpanded: this.props.onSetExpanded,
              readOnly: this.props.readOnly,
              setGrouping,
              tableMessage,
              clearTableMessage: this.clearTableMessage,
              extraTableActionsLeft: this.getExtraTableActionsLeft(
                entityName,
                projectName,
                enableSetProject,
                enableFreezeRunset,
                groupSelections,
                username,
                tempSelectNone,
                tags,
                showOverflowButton,
                disabledFeatures
              ),
              extraTableActionsRight: this.getExtraTableActionsRight(
                topLevelQueryVariables,
                topLevelQuery,
                allColumns,
                cleanedTableSettings,
                currentPage
              ),
              columnEditor: this.columnEditor,
              onSetCurrentPage: this.onSetCurrentPage,
              openSortPopup: this.openSortPopup,
              topLevelQueryVariables,
            };
            return <WBTable tableRef={this.tableRef} {...tableProps} />;
          }}
        </WithRunsDataLoader>
      </>
    );
  }
}

function useRunSelectorProps(props: RunSelectorInnerProps) {
  const viewer = ViewerHooks.useViewer();
  const runSet = ViewHooks.usePart(props.runSetRef);
  const runSetFull = ViewHooks.useWhole(props.runSetRef);

  const groupSelections = ViewHooks.useWhole(runSet.groupSelectionsRef);
  const tempSelections = ViewHooks.useWhole(props.tempSelectionsRef);
  const runSetFilters = ViewHooks.useWhole(runSet.filtersRef);
  const sort = runSetFull.sort;

  const toggleExpandedRow = ViewHooks.useViewAction(
    runSet.groupSelectionsRef,
    GroupSelectionsActions.toggleExpandedRowAddress
  );

  const tempSelectAll = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectAll,
    props.tempSelectionsRef,
    runSet.groupSelectionsRef
  );

  const tempSelectNone = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectNone,
    props.tempSelectionsRef,
    runSet.groupSelectionsRef
  );

  const entityName = runSet.project
    ? runSet.project.entityName
    : props.pageEntityName;

  const projectName = runSet.project
    ? runSet.project.name
    : props.pageProjectName;

  const groupTempSelection = useMemo(
    () => ({
      grouping: groupSelections.grouping,
      selections: tempSelections,
      expandedRowAddresses: groupSelections.expandedRowAddresses,
    }),
    [
      groupSelections.grouping,
      groupSelections.expandedRowAddresses,
      tempSelections,
    ]
  );

  const {mergeFilters, sweep, report} = useRunsQueryContext();

  const runSelectorQuery = useRunSelectorQuery({
    entityName,
    projectName,
    filters: runSetFilters,
    mergeFilters,
    groupTempSelection,
    ...props,
  });

  const {tempSelectedCount, tempOutputArtifactsCount} = useSelectedCounts(
    props.runSetRef,
    props.tempSelectionsRef
  );

  const [deleteRun] = Generated.useDeleteRunMutation();
  const [stopRun] = Generated.useStopRunMutation();
  const updateRun = RunHooks.useUpdateRun();
  const updateRunTags = RunHooks.useUpdateRunTags();
  const bulkUpdateRunTags = RunHooks.useBulkUpdateRunTags();
  const [modifyRuns] = Generated.useModifyRunsMutation();
  const [moveRuns] = Generated.useMoveRunsMutation();
  const [deleteRuns] = Generated.useDeleteRunsMutation();
  const [updateGroupRun] = Generated.useUpsertRunGroupMutation();
  const updateGroupRunTags = RunHooks.useUpdateGroupRunTags(
    projectName,
    entityName
  );

  const runSetUpdate = ViewHooks.useViewAction(
    props.runSetRef,
    RunSetActions.update
  );

  const setGrouping = ViewHooks.useViewAction(
    runSet.groupSelectionsRef,
    GroupSelectionsActions.setGrouping
  );

  const setSort = ViewHooks.useViewAction(runSet.sortRef, SortActions.set);

  const selectAll = ViewHooks.useViewAction(
    runSet.groupSelectionsRef,
    GroupSelectionsActions.selectAll
  );

  const selectNone = ViewHooks.useViewAction(
    runSet.groupSelectionsRef,
    GroupSelectionsActions.selectNone
  );

  const setProject = useCallback(
    (newEntityName: string, newProjectName: string) =>
      runSetUpdate({
        project: {entityName: newEntityName, name: newProjectName},
      }),
    [runSetUpdate]
  );

  const setFilters = ViewHooks.useViewAction(
    runSet.filtersRef,
    FilterActions.set
  );

  const setSearch = useCallback(
    (query: string) => runSetUpdate({search: {query}}),
    [runSetUpdate]
  );

  const setTableSettings = useCallback(
    (runFeed: TableSettings) => runSetUpdate({runFeed}),
    [runSetUpdate]
  );

  const dispatch = useDispatch();

  const queryVariables = useMemo(() => {
    return {
      id: runSet.id,
      filters:
        mergeFilters != null
          ? Filter.mergedFilters(runSetFilters, mergeFilters)
          : Filter.mergedFilters(runSetFilters),
      selections: SelectionManager.toFilter(groupSelections),
      sort,
      grouping: groupSelections.grouping,
    } as Query.Query;
  }, [runSet.id, mergeFilters, runSetFilters, groupSelections, sort]);

  const runQueuesEnabled = useBioFeatureFlagEnabled('instant replay');

  return {
    entityName,
    projectName,
    viewer,
    deleteRun,
    stopRun,
    updateRun,
    updateGroupRun,
    updateGroupRunTags,
    modifyRuns,
    moveRuns,
    deleteRuns,
    report,
    runSelectorQuery,
    runSet,
    runSetFull,
    mergeFilters,
    queryVariables,
    filters: runSetFilters,
    tableSettings: runSet.runFeed,
    setProject,
    setFilters,
    setSearch,
    sort,
    setSort,
    setTableSettings,
    groupSelections,
    setGrouping,
    selectAll,
    selectNone,
    groupTempSelection,
    tempOutputArtifactsCount,
    tempSelectAll,
    tempSelectNone,
    tempSelectedCount,
    tempSelections,
    toggleExpandedRow,
    dispatch,
    updateRunTags,
    bulkUpdateRunTags,
    sweep,
    runQueuesEnabled,
  };
}

export const RunSelectorInner = makeComp(
  (props: RunSelectorInnerProps) => {
    const selectedProps = useRunSelectorProps(props);
    return <RunSelectorInnerComp {...props} {...selectedProps} />;
  },
  {id: 'RunSelectorInner'}
);
