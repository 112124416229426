import {Header} from 'semantic-ui-react';
import styled from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import WBReactTable from './WBReactTable';

// export const DeleteButton = styled(
//   (props: ButtonProps & {className: string}) => (
//     <Button content={<DeleteIcon />} {...props} />
//   )
// )`
//   padding: 3px;
// `;

export const Section = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 32px 16px;
`;

export const SectionHeader = styled(Header)`
  flex: 1 0 100%;
`;

export const SectionTable = styled(WBReactTable)`
  flex: 1 0 100%;
`;

export const ArtifactOverview = styled.div`
  background: white;
  padding: 16px 32px;
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
`;

export const DeleteButtonContainer = styled.span`
  display: block;
  position: absolute;
  top: 20px;
  right: 32px;
`;

export const OverviewItem = styled.div`
  display: flex;
  min-height: 32px;
  align-items: center;
`;

export const OverviewKey = styled.div`
  flex: 0 0 128px;
  color: ${GLOBAL_COLORS.lightGray.toString()};
`;

export const OverviewValue = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 35px;

  .copyable-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .artifact-aliases-wrapper {
    display: flex;
  }
  .artifact-add-alias-button {
    height: 26px;
    width: 26px;
    text-align: center;
    margin: 0;
    color: ${GLOBAL_COLORS.gray.toString()};
    font-size: 18px;
    padding: 0px;
  }
`;

export const OverviewValueMarkdownEditor = styled(InlineMarkdownEditor)`
  cursor: pointer;
`;

export const InlineSegment = styled.span`
  margin: 20px;
`;
