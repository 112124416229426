import * as S from './ProjectsTableTeam.styles';
import React, {useMemo} from 'react';
import {CellInfo, Column} from 'react-table';
import {Link, NavLink} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import * as FuzzyMatch from '../util/fuzzyMatch';
import ImageIconHeader from './ImageIconHeader';
import {ProjectActionMenu} from './ProjectActionMenu';
import WBReactTable from './WBReactTable';
import WandbLoader from './WandbLoader';
import {newProjectPage} from '../util/urls';
import {useAllTeamProjectsQuery} from '../generated/graphql';

import TimeAgo from 'react-timeago';

type Project = {
  name: string;
  lastActive: string;
  totalRuns: number;
  totalUsers: number;
};

export type ProjectsTableProps = {
  className?: string;
  entityName: string;
  readOnly?: boolean;
  pageSize?: number | null;
  onChangePageSize?: (pageSize: number) => void;
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  className,
  entityName,
  readOnly,
  pageSize,
  onChangePageSize,
}) => {
  const allProjectsQuery = useAllTeamProjectsQuery({variables: {entityName}});

  const rows = useMemo(
    () =>
      allProjectsQuery.data?.projects?.edges
        .map(e => e.node as unknown as Project)
        .map((p: Project) => ({
          searchString: p.name,
          row: {
            name: p.name,
            lastRun: p.lastActive,
            runCount: p.totalRuns,
            userCount: p.totalUsers,
            edit: p,
          },
        })) ?? [],
    [allProjectsQuery]
  );

  function columns() {
    const nameColumn: Column = {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellInfo: CellInfo) => {
        const projectName: string = cellInfo.value;
        return (
          <S.BreakableLine>
            <NavLink to={`/${entityName}/${projectName}`}>
              {FuzzyMatch.fuzzyMatchHighlightPieces([projectName])}
            </NavLink>
          </S.BreakableLine>
        );
      },
    };
    const lastRunColumn: Column = {
      Header: 'Last Run',
      accessor: 'lastRun',
      Cell: (cellInfo: CellInfo) => {
        if (cellInfo.value == null) {
          return <span>-</span>;
        }
        const runDate = new Date(cellInfo.value + 'Z');
        const dayOld = Date.now() - 24 * 60 * 60 * 1000;
        if (runDate.getTime() > dayOld) {
          return <TimeAgo date={runDate} />;
        }
        const runDateString = runDate.toISOString().substring(0, 10);
        return <span>{runDateString}</span>;
      },
    };
    const runCountColumn: Column = {
      Header: 'Runs',
      accessor: 'runCount',
      Cell: (cellInfo: CellInfo) => {
        return <span>{cellInfo.value}</span>;
      },
    };
    const userColumn: Column = {
      Header: 'Contributors',
      accessor: 'userCount',
      Cell: (cellInfo: CellInfo) => {
        return <span>{cellInfo.value || '-'}</span>;
      },
    };
    const editColumn: Column = {
      Header: '',
      accessor: 'edit',
      maxWidth: 50,
      Cell: (cellInfo: CellInfo) => {
        return (
          <S.menuWrapper>
            <ProjectActionMenu
              project={cellInfo.value}
              refetch={() => allProjectsQuery.refetch()}
            />
          </S.menuWrapper>
        );
      },
    };
    const allColumns = [nameColumn, lastRunColumn, runCountColumn, userColumn];
    if (!readOnly) {
      allColumns.push(editColumn);
    }
    return allColumns;
  }

  if (allProjectsQuery.loading) {
    return (
      <S.LoaderWrapper>
        <WandbLoader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper className={className}>
      <S.HeaderWrapper>
        <ImageIconHeader icon="project" text="Projects" />
        {!readOnly && (
          <Link to={newProjectPage(entityName)}>
            <Button primary size="tiny">
              Create new project
            </Button>
          </Link>
        )}
      </S.HeaderWrapper>
      <WBReactTable
        data={rows}
        columns={columns()}
        defaultSorted={[{id: 'lastActive', desc: false}]}
        searchResultColumns={columns}
        pageSize={pageSize ?? 20}
        onChangePageSize={onChangePageSize}
      />
    </S.Wrapper>
  );
};

export default ProjectsTable;
