import styled from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';

export const LoaderWrapper = styled.div`
  position: relative;
  height: 400px;
`;

export const Wrapper = styled.div``;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  margin-bottom: 12px;
  .image-icon-header {
    margin: 0;
  }
`;

export const ActiveWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UsersWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MoreUsers = styled.div`
  border-radius: 50%;
  background: ${GLOBAL_COLORS.outline.toString()};
  width: 32px;
  height: 32px;
  text-align: center;
  span {
    margin-top: 3px;
    display: inline-block;
  }
`;

export const menuWrapper = styled.div`
  .project-action-menu {
    height: 24px;
    width: 24px;
    transform: translateY(-4px);
  }
`;

export const BreakableLine = styled.div`
  overflow-wrap: break-word;
`;
