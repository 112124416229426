// A wrapper around Monaco editor that maintains its own value
// in state. This prevents update issues (jumping around when the value
// prop coming in changes). Change the key to send a new value in.

import React from 'react';
import {MonacoEditorProps} from 'react-monaco-editor';
import {Loader} from 'semantic-ui-react';
import {useEffect, useState} from 'react';
import makeComp from '../../util/profiler';

const Editor = makeComp(
  (props: MonacoEditorProps) => {
    const {onChange, height, editorDidMount, options, theme} = props;

    const [monacoReady, setMonacoReady] = useState(false);
    const [MonacoEditor, setMonacoEditor] = useState<any>(null);

    const importMonacoEditor = async () => {
      const defaultEditor = (await import('react-monaco-editor')).default;
      setMonacoEditor(() => defaultEditor);
      const setupMonaco = (await import('./monaco')).setupMonaco;
      setupMonaco();
      setMonacoReady(true);
    };
    useEffect(() => {
      importMonacoEditor();
    }, []);

    const [value, setValue] = useState(props.value || '');
    useEffect(() => {
      setValue(props.value || '');
    }, [props.value]);

    if (!monacoReady) {
      return (
        <div style={{height: height || 400}}>
          <Loader>Loading editor</Loader>
        </div>
      );
    }

    return (
      <MonacoEditor
        height={height || 600}
        style={{minWidth: 400}}
        {...props}
        value={value}
        onChange={(val: string, e: any) => {
          setValue(val);
          if (onChange) {
            onChange(val, e);
          }
        }}
        theme={theme}
        editorDidMount={editorDidMount}
        options={Object.assign(
          {
            autoClosingBrackets: 'never',
            autoClosingQuotes: 'never',
            automaticLayout: true,
            cursorBlinking: 'smooth',
            folding: true,
            lineNumbersMinChars: 4,
            minimap: {enabled: false},
            scrollBeyondLastLine: false,
            wordWrap: 'on',
          },
          options || {}
        )}
      />
    );
  },
  {id: 'Monaco.Editor'}
);

export default Editor;
