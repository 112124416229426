import {createAction} from 'typesafe-actions';

import * as Types from './types';
import * as ReportTypes from '../views/report/types';
import {ID} from '@wandb/cg/browser/utils/string';

// Internal Actions
export const loadFinished = createAction(
  '@report/loadFinished',
  action => (id: string, viewID: Types.ReportViewRef) => action({id, viewID})
);

export const pushReport = createAction(
  '@report/pushReport',
  action =>
    (
      entityName: string,
      projectName: string,
      userId: string,
      userName: string,
      config: ReportTypes.ReportConfig
    ) =>
      action({id: ID(), entityName, projectName, userId, userName, config})
);

export const clearPushedReport = createAction(
  '@report/clearPushedReport',
  action => () => action()
);

export const unloadReport = createAction(
  '@report/unloadReport',
  action => (reportID: string) => action({reportID})
);
