import * as S from './QueryFieldsEditor.styles';

import React from 'react';
import * as _ from 'lodash';
import PlusButton from './PlusButton';
import QueryFieldEditor from './QueryFieldEditor';
import * as Update from '../../util/update';
import {
  QueryField,
  QueryTemplateArg,
  getSchemaType,
  makeDupesBefore,
  getDefaultFieldFromSchemaField,
  fieldIsHidden,
} from '../../util/vega3';
import makeComp from '../../util/profiler';

interface QueryFieldsEditorProps {
  typeName: string;
  fields: QueryField[];
  fixedFields?: QueryField[];
  templateArgs: QueryTemplateArg[];
  indentLevel: number;
  setFields(fields: any[]): void;
}

const QueryFieldsEditor: React.FC<QueryFieldsEditorProps> = makeComp(
  ({typeName, fields, fixedFields, templateArgs, indentLevel, setFields}) => {
    const schemaType = getSchemaType(typeName);
    const schemaFields = schemaType?.fields;
    if (!schemaFields) {
      throw new Error('Rendered QueryFieldsEditor without fields');
    }

    // Since we don't yet support aliases, we don't allow you to choose
    // duplicate fields.
    // TODO: Change this logic when we support aliases
    const {defaultSchemaField} = React.useMemo(() => {
      const unusedSchemaFieldsInner = _.differenceBy(
        schemaFields,
        fields,
        f => f.name
      );
      return {
        unusedSchemaFields: unusedSchemaFieldsInner,
        defaultSchemaField: unusedSchemaFieldsInner.find(
          schemaField => schemaField.name !== 'id'
        ),
      };
    }, [fields, schemaFields]);

    const dupesBefore = makeDupesBefore(fields.map(f => f.name));

    return (
      <S.Wrapper>
        {fields.map((f, i) => {
          if (fieldIsHidden(f.name)) {
            return <></>;
          }
          const currentSchemaField = schemaFields.find(
            schemaField => schemaField.name === f.name
          );
          if (currentSchemaField == null) {
            throw new Error('expected currentField to be valid schema field');
          }
          return (
            <QueryFieldEditor
              key={i}
              schemaFields={
                schemaFields.find(
                  foundF => foundF.name === currentSchemaField.name
                ) != null
                  ? schemaFields
                  : [...schemaFields, currentSchemaField]
              }
              alias={dupesBefore[i] !== 0 ? f.name + dupesBefore[i] : undefined}
              typeName={typeName}
              field={f}
              fixedField={(fixedFields || []).find(ff => ff.name === f.name)}
              templateArgs={templateArgs}
              indentLevel={indentLevel + 1}
              deleteField={() => {
                setFields(Update.deleteArrayIndex(fields, i));
              }}
              setField={newField => {
                setFields(Update.updateArrayIndex(fields, i, newField));
              }}
            />
          );
        })}
        {typeName !== 'Query' && defaultSchemaField != null && (
          <PlusButton
            onClick={() =>
              setFields([
                ...fields,
                getDefaultFieldFromSchemaField(defaultSchemaField),
              ])
            }></PlusButton>
        )}
      </S.Wrapper>
    );
  },
  {id: 'QueryFieldsEditor'}
);

export default QueryFieldsEditor;
