import classNames from 'classnames';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Button} from 'semantic-ui-react';
import CheckoutModal from '../components/CheckoutModal';
import SubscribeForm, {SubscriptionSuccess} from '../components/SubscribeForm';
import WandbLoader from '../components/WandbLoader';
import '../css/BillingPage.less';
import {
  Plan,
  useCustomerSubscriptionInfoQuery,
  useSubscriptionPlansQuery,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {propagateErrorsContext} from '../util/errors';
import {
  LEGACY_PLANS,
  PlanInfo,
  PlanInfoWithActualPlans,
  StripeElements,
} from '../util/pricing';
import makeComp from '../util/profiler';

const TeamPayment: FunctionComponent = makeComp(
  () => {
    const viewer = useViewer();
    const customerSubscriptionInfoQuery = useCustomerSubscriptionInfoQuery({
      fetchPolicy: 'network-only',
    });
    const subscriptionPlansQuery = useSubscriptionPlansQuery({
      context: propagateErrorsContext(),
    });
    const [selectedPlanInfo, setSelectedPlanInfo] =
      useState<PlanInfoWithActualPlans | null>(null);

    const serverPlans = useMemo(
      () => subscriptionPlansQuery.data?.plans ?? [],
      [subscriptionPlansQuery.data]
    );

    const getServerPlan = useCallback(
      (serverPlanName: string) =>
        serverPlans.find(p => p?.name === serverPlanName) ?? null,
      [serverPlans]
    );

    const planInfos = useMemo(() => {
      const res: PlanInfo[] = [];

      for (const planClientData of LEGACY_PLANS) {
        const {planType, dbName} = planClientData;
        const monthlyPlan = getServerPlan(`${dbName}_monthly`);
        const yearlyPlan = getServerPlan(`${dbName}_yearly`);
        if (
          planType === 'stripe' &&
          (monthlyPlan == null || yearlyPlan == null)
        ) {
          // skip if we don't have the designated backend plan
          continue;
        }
        res.push({...planClientData, monthlyPlan, yearlyPlan});
      }

      return res;
    }, [getServerPlan]);

    const storagePlanId = useMemo(
      () => getServerPlan('storage_monthly')?.id,
      [getServerPlan]
    );

    const trackingPlanId = useMemo(
      () => getServerPlan('tracking_monthly')?.id,
      [getServerPlan]
    );

    const onSelectPlan = useCallback(
      (planDBName: string) => {
        setSelectedPlanInfo(
          (planInfos.find(p => p.dbName === planDBName) ??
            null) as PlanInfoWithActualPlans | null
        );
      },
      [planInfos]
    );

    const unselectPlan = useCallback(() => {
      setSelectedPlanInfo(null);
    }, []);

    if (
      viewer == null ||
      subscriptionPlansQuery.loading ||
      storagePlanId == null ||
      trackingPlanId == null
    ) {
      return <WandbLoader />;
    }

    return (
      <StripeElements>
        <div className="billing-page">
          <div className="billing-page-container">
            <div className="pricing-panels" id="pricing-panels">
              {planInfos.map((p, i) => (
                <PricingPanel key={i} {...p} onSelectPlan={onSelectPlan} />
              ))}
            </div>
            {selectedPlanInfo != null && (
              <CheckoutModal
                planInfo={selectedPlanInfo}
                onTransactionCompleted={customerSubscriptionInfoQuery.refetch}
                onClose={unselectPlan}
                renderForm={({
                  plan,
                  seats,
                  pricePerPeriod,
                  onTransactionCompleted,
                }) => (
                  <SubscribeForm
                    plan={plan}
                    seats={seats}
                    pricePerPeriod={pricePerPeriod}
                    storagePlanID={storagePlanId}
                    trackingPlanID={trackingPlanId}
                    onSubscriptionCompleted={onTransactionCompleted}
                  />
                )}
                renderSuccess={() => <SubscriptionSuccess />}
              />
            )}
          </div>
        </div>
      </StripeElements>
    );
  },
  {id: 'TeamPayment', memo: true}
);

export default TeamPayment;

type PricingPanelProps = PlanInfo & {
  onSelectPlan: (planDBName: string) => void;
};

const PricingPanel: React.FC<PricingPanelProps> = makeComp(
  ({
    title,
    subtitle,
    features,
    planType,
    dbName,
    monthlyPlan,
    yearlyPlan,
    onSelectPlan,
  }) => {
    return (
      <div
        className={classNames('pricing-panel', {
          enterprise: planType === 'enterprise',
        })}>
        <div className="header">
          <div className="panel-title">{title}</div>
          <div className="panel-subtitle">{subtitle}</div>
        </div>
        <div className="panel-content">
          {features.map((f, i) => (
            <div className="plan-feature" key={i}>
              {f}
            </div>
          ))}
        </div>
        <div className="footer">
          {planType !== 'enterprise' && (
            <PlanPrice monthlyPlan={monthlyPlan} yearlyPlan={yearlyPlan} />
          )}
          <PlanButton
            planType={planType}
            dbName={dbName}
            monthlyPlan={monthlyPlan}
            yearlyPlan={yearlyPlan}
            onSelectPlan={onSelectPlan}
          />
        </div>
      </div>
    );
  },
  {id: 'PricingPanel', memo: true}
);

type PlanPriceProps = {
  monthlyPlan: Plan | null;
  yearlyPlan: Plan | null;
};

const PlanPrice: React.FC<PlanPriceProps> = makeComp(
  ({monthlyPlan, yearlyPlan}) => {
    let priceDisplay = <span className="price">Free</span>;

    if (monthlyPlan?.unitPrice != null && yearlyPlan?.unitPrice != null) {
      priceDisplay = (
        <>
          <span className="price">${monthlyPlan.unitPrice / 100}</span> per user
          / month
        </>
      );
    } else if (monthlyPlan != null && yearlyPlan != null) {
      priceDisplay = <span className="price">Coming soon</span>;
    }

    return <div className="plan-price">{priceDisplay}</div>;
  },
  {id: 'PlanPrice', memo: true}
);

type PlanButtonProps = Pick<
  PlanInfo,
  'planType' | 'dbName' | 'monthlyPlan' | 'yearlyPlan'
> & {onSelectPlan: (planDBName: string) => void};

const PlanButton: React.FC<PlanButtonProps> = makeComp(
  ({planType, dbName, monthlyPlan, yearlyPlan, onSelectPlan}) => {
    const viewer = useViewer();
    switch (planType) {
      case 'free':
        return <Button className="action-button current-plan">Active</Button>;
      case 'enterprise':
        return (
          <Button
            className="action-button"
            onClick={() => {
              window.analytics.track('Enterprise contact us clicked', {
                viewer,
              });
              window.open('/site/demo');
            }}>
            Contact us
          </Button>
        );
      default:
        return (
          <Button
            className="action-button"
            data-test={`${dbName}-button`}
            disabled={monthlyPlan == null || yearlyPlan == null}
            onClick={() => {
              if (dbName == null) {
                return;
              }
              window.analytics.track(`Subscribe to ${dbName} clicked`, {
                viewer,
              });
              onSelectPlan(dbName);
            }}>
            Start a trial
          </Button>
        );
    }
  },
  {
    id: 'PlanButton',
    memo: true,
  }
);
