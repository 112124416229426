import * as S from './BeamerIcon.styles';

import React from 'react';
import {useBeamerNewPosts} from '../util/beamer';
import makeComp from '../util/profiler';

interface BeamerIconProps {
  absolute?: true;
}

const BeamerIcon: React.FC<BeamerIconProps> = makeComp(
  ({absolute}) => {
    const newPosts = useBeamerNewPosts();
    if (newPosts === 0) {
      return null;
    }
    return <S.BeamerIconDiv absolute={absolute}>{newPosts}</S.BeamerIconDiv>;
  },
  {id: 'BeamerIcon', memo: true}
);

export default BeamerIcon;
