import styled from 'styled-components';
import {
  fontName,
  gray700,
  gray500,
  gray400,
  primaryText,
  linkHoverBlue,
  error,
  darkRed,
} from '../css/globals.styles';
import {TargetBlank} from '../util/links';
import {WBIcon} from '@wandb/ui';

export const WysiwygNotice = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${fontName};
  border: 1px solid ${gray400};
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 32px;
`;

export const WysiwygNoticeSuccess = styled.div`
  display: flex;
  align-items: center;
`;

export const WysiwygNoticeSuccessIcon = styled(WBIcon)`
  flex-shrink: 0;
  font-size: 24px;
  margin-right: 8px;
`;

export const WysiwygNoticeLink = styled(TargetBlank)`
  color: ${gray500};
  &:hover {
    color: ${gray700};
  }
  && {
    text-decoration: underline;
  }
`;

export const WysiwygNoticeActions = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: 32px;
`;

export const WysiwygConvert = styled.div`
  color: ${primaryText};
  &:hover {
    color: ${linkHoverBlue};
  }
  cursor: pointer;
`;

export const WysiwygRevert = styled.div`
  color: ${error};
  &:hover {
    color: ${darkRed};
  }
  cursor: pointer;
`;

export const WysiwygDismiss = styled.div`
  color: ${gray500};
  &:hover {
    color: ${gray700};
  }
  cursor: pointer;
  margin-left: 24px;
`;
