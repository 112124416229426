import React from 'react';
import * as RunHelpers from '../../util/runhelpers';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';

const inputType = {
  type: 'union' as const,
  members: ['none' as const, 'number' as const],
};
type PanelNumberProps = Panel2.PanelProps<typeof inputType>;

export const PanelNumber: React.FC<PanelNumberProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  if (nodeValueQuery.result == null) {
    return <div>-</div>;
  }
  return <div>{RunHelpers.displayValue(nodeValueQuery.result)}</div>;
};

export const Spec: Panel2.PanelSpec = {
  id: 'number',
  Component: PanelNumber,
  inputType,
};
