import styled, {css} from 'styled-components';

export const EmbeddedIframe = styled.iframe<{
  selected: boolean;
}>`
  display: block;
  margin: 0 auto;
  width: min(max(45vw, 150px), 300px);
  height: min(max(57vw, 190px), 380px);

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;
