// Report section managment.
// All methods should do immutable updates

import * as Filter from './filters';
import {PanelBankConfig, PanelBankSectionConfig} from './panelbank';
import * as Panels from './panels';
import * as PanelSettings from './panelsettings';
import * as Query from './queryts';
import * as RunFeed from './runfeed';
import * as SelectionManager from './selectionmanager';
import * as String from '@wandb/cg/browser/utils/string';

const EMPTY_RUN_SET_BASE = {
  filters: Filter.EMPTY_FILTERS,
  runFeed: RunFeed.EMPTY_CONFIG,
  sort: Query.CREATED_AT_DESC,
  enabled: true,
  name: 'Run set',
  search: {
    query: '',
  },
};

function emptyRunSetWithID() {
  return {...EMPTY_RUN_SET_BASE, id: String.ID(9)};
}

export function emptyRunSetSelectAll(): RunSetConfig {
  return {
    ...emptyRunSetWithID(),
    ...SelectionManager.EMPTY_ALL,
  };
}

export function emptyRunSetSelectNone(): RunSetConfig {
  return {
    ...emptyRunSetWithID(),
    ...SelectionManager.EMPTY_NONE,
  };
}

export function emptyReportRunSetSelectAll(): RunSetConfig {
  return prepRunSetForReport(emptyRunSetSelectAll());
}

export function emptyReportRunSetSelectNone(): RunSetConfig {
  return prepRunSetForReport(emptyRunSetSelectNone());
}

function prepRunSetForReport(rs: RunSetConfig): RunSetConfig {
  return {
    ...rs,
    runFeed: {
      ...rs.runFeed,
      pageSize: 10,
    },
  };
}

export function generateRunsetID(): string {
  return 'rs/' + String.ID(9);
}

export function runsetIdFromNameParts(...parts: string[]) {
  return ['rs', ...parts].join('/');
}

export type RunSetConfig = SelectionManager.GroupSelectionState & {
  id: string;
  project?: {
    entityName: string;
    name: string;
  };
  filters: Filter.RootFilter;
  runFeed: RunFeed.Config;
  // search is an object to make it easier to extend in the future
  search: {
    query: string;
  };
  enabled?: boolean;
  sort: Query.Sort;
  name: string;
};

export interface RunColorConfig {
  // Key-value pairs where the key is the name of a run, and the value is
  // a hex or rgba string.
  [key: string]: string;
}

export interface Config {
  // to avoid migration, we're giving original sections the type null
  type?: null;
  name?: string;
  openViz?: boolean;
  openRunSet?: number;
  hideRunSets?: boolean;
  runSets?: RunSetConfig[];
  settings?: PanelSettings.Settings;
  customRunColors?: RunColorConfig;
  panels?: Panels.Config; // old spec ('Custom Visualizations' + pinnable panels)
  panelBankConfig: PanelBankConfig; // current spec for single- and multi-run workspaces (whole PanelBank)
  panelBankSectionConfig: PanelBankSectionConfig; // current spec for reports (a single PanelBank section)
}

export interface ReportAuthor {
  username: string;
  name?: string;
}

export function runSetsToRunSetQuery(
  runSets: RunSetConfig[]
): Query.RunSetQuery[] {
  return runSets.map(rs => ({
    ...rs,
    filters: Query.addSearchFilter(rs.filters, rs.search.query, rs.grouping),
    projectName: rs.project != null ? rs.project.name : undefined,
    entityName: rs.project != null ? rs.project.entityName : undefined,
    selections: SelectionManager.toFilter(rs),
  }));
}
