import {Struct} from '../types';

export const SORT_ORDERS = ['Latest', 'Popular', 'Trending'] as const;
export type SortOrder = typeof SORT_ORDERS[number];
export const SORT_ORDERS_SET = new Set(SORT_ORDERS);
export const DEFAULT_SORT_ORDER = SORT_ORDERS[0];

export const LANGUAGES = [
  'English',
  '中文 (简体)',
  '日本語',
  '한국어',
  'Español',
  'Français',
  'Deutsch',
  'Русский',
] as const;
export type Language = typeof LANGUAGES[number];
export const LANGUAGES_SET = new Set(LANGUAGES);
export function isLanguage(str: string): str is Language {
  return LANGUAGES_SET.has(str as Language);
}
export const DEFAULT_LANGUAGE = LANGUAGES[0];

export const POST_CATEGORY_ID = 'ir6df9nxe';
export const DISCUSSION_CATEGORY_ID = '90vhuh15v';
export const GRADIENT_DISSENT_CATEGORY_ID = 's4jjx15g2';

export type ReportAuthor = {username: string; name?: string};

export type ReportIDWithTagIDs = {
  id: string;
  tagIDs: string[];
  authors: ReportAuthor[];
  addedAt: string;
  language: Language;
  pending?: true;
  announcement?: true;
};

export type Headings = {
  title: string;
  subtitle: string;
  description: string;
};

export type GallerySpec = {
  headings: Headings;
  reportIDsWithTagIDs: ReportIDWithTagIDs[];
  categories: Tag[];
  tags: Tag[];
  topLevelFeaturedReportIDs: string[];
};

export const EMPTY_GALLERY_SPEC: GallerySpec = {
  headings: {
    title: '',
    subtitle: '',
    description: '',
  },
  reportIDsWithTagIDs: [],
  categories: [],
  tags: [],
  topLevelFeaturedReportIDs: [],
};

export interface Tag {
  id: string;
  name: string;
  description: string;
  imageURL: string;
  linkForm: string;
  featuredReportIDs: string[];
  color?: string;
}

export type ReportMetadata = {
  id: string;
  language: Language;
  pending?: true;
  announcement?: true;
  tags?: Tag[];
  authors?: ReportAuthor[];
  addedAt?: string;
  entityName: string;
  project?: {
    id: string;
    entityName: string;
    name: string;
  };
  displayName: string;
  description: string;
  previewUrl?: string;
  starCount: number;
  recentStarCount: number;
  starred?: boolean;
  discussionThreads: Struct;
};

export type ReportRSSMetadata = {
  id: string;
  displayName: string;
  authors: string[];
  tags: string[];
  addedAt: string;
  description?: string;
  link: string;
};

export type ReportAuthorMetadata = {
  id: string;
  username: string;
  name: string;
  reportCount: number;
  bio?: string;
  company?: string;
  location?: string;
  photoUrl?: string;
};

export type NewsletterSubscriptionQueryData = {
  id: string;
  user?: {
    username: string;
    email: string;
  };
  email?: string;
  createdAt: string;
};

export type NewsletterSubscription = {
  id: string;
  username?: string;
  email?: string;
  createdAt: string;
};

export function sortOrTagToPath(tags: Tag[], s: string): string {
  for (const t of tags) {
    if (t.name === s) {
      return t.linkForm;
    }
  }
  return formatToPath(s);
}

function formatToPath(s: string): string {
  return s.toLowerCase().replace('&', 'and').split(' ').join('-').trim();
}

const gallerySortPathSet = new Set(SORT_ORDERS.map(formatToPath));
export function pathSegmentIsGallerySort(segment: string): boolean {
  return gallerySortPathSet.has(segment);
}
