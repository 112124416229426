import React from 'react';
import styled from 'styled-components';
import EditableField from '../EditableField';
import {PropertyEditorComponent} from './property-editors';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

const MinimalEditableField = styled(EditableField)`
  margin: 0;
`;

const StringPropertyEditor: PropertyEditorComponent<'string'> = makeComp(
  props => {
    const displayedVal =
      props.values.length === 1 && props.values[0] != null
        ? props.values[0]
        : '';
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        <MinimalEditableField
          placeholder={props.values.length > 1 ? 'Mixed' : 'Auto'}
          value={displayedVal}
          save={value => {
            props.save(value);
          }}></MinimalEditableField>
        {/* <Input value={props.value} onChange={(e, {value}) => {}}></Input> */}
      </InspectorPropertyWrapper>
    );
  },
  {id: 'StringPropertyEditor'}
);

export default StringPropertyEditor;
