import * as S from './ProjectsTableUser.styles';
import React, {useMemo} from 'react';
import {CellInfo, Column} from 'react-table';
import {Link, NavLink} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import * as FuzzyMatch from '../util/fuzzyMatch';
import ImageIconHeader from './ImageIconHeader';
import {ProjectActionMenu} from './ProjectActionMenu';
import WBReactTable from './WBReactTable';
import WandbLoader from './WandbLoader';
import {newProjectPage} from '../util/urls';
import {useAllUserProjectsQuery} from '../generated/graphql';
import {toMongo} from '../util/filters';

import TimeAgo from 'react-timeago';

type Project = {
  name: string;
  lastActive: string;
  totalRuns: number;
  entityName: string;
  user: {username: string};
  filteredCount: number;
};

export type ProjectsTableProps = {
  className?: string;
  entityName: string;
  readOnly?: boolean;
  pageSize?: number | null;
  onChangePageSize?: (pageSize: number) => void;
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  className,
  entityName,
  readOnly,
  pageSize,
  onChangePageSize,
}) => {
  const allProjectsQuery = useAllUserProjectsQuery({
    variables: {
      userName: entityName,
      // Get the count of runs this user has done in this project
      filters: JSON.stringify(
        toMongo({
          key: {section: 'run', name: 'username'},
          op: '=',
          value: entityName,
        })
      ),
    },
  });

  const rows = useMemo(
    () =>
      allProjectsQuery.data?.user?.projects?.edges
        .map(e => e.node as unknown as Project)
        // Filter out projects that this user didn't create, and that they haven't contributed runs to
        // See https://wandb.atlassian.net/browse/WB-5616
        .filter(
          p =>
            !(
              p.entityName !== entityName &&
              p.user.username !== entityName &&
              p.filteredCount === 0
            )
        )
        .map((p: Project) => ({
          searchString: p.name,
          row: {
            name: p.name,
            lastRun: p.lastActive,
            runCount: p.totalRuns,
            team: p.entityName,
            edit: p,
          },
        })) ?? [],
    [allProjectsQuery.data, entityName]
  );

  const columns = React.useMemo(() => {
    const nameColumn: Column = {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellInfo: CellInfo) => {
        const projectHome: string = cellInfo.row.team;
        const projectName: string = cellInfo.value;
        return (
          <S.BreakableLine>
            <NavLink to={`/${projectHome}/${projectName}`}>
              {FuzzyMatch.fuzzyMatchHighlightPieces([projectName])}
            </NavLink>
          </S.BreakableLine>
        );
      },
    };
    const lastRunColumn: Column = {
      Header: 'Last Run',
      accessor: 'lastRun',
      Cell: (cellInfo: CellInfo) => {
        if (cellInfo.value == null) {
          return <span>-</span>;
        }
        const runDate = new Date(cellInfo.value + 'Z');

        const dayOld = Date.now() - 24 * 60 * 60 * 1000;
        if (runDate.getTime() > dayOld) {
          return <TimeAgo date={runDate} />;
        }

        const runDateString = runDate.toISOString().substring(0, 10);
        return <span>{runDateString}</span>;
      },
    };
    const runCountColumn: Column = {
      Header: 'Runs',
      accessor: 'runCount',
      Cell: (cellInfo: CellInfo) => {
        return <span>{cellInfo.value}</span>;
      },
    };
    const teamColumn: Column = {
      Header: 'Entity',
      accessor: 'team',
      Cell: (cellInfo: CellInfo) => {
        const teamName = cellInfo.value;
        return (
          <div>
            <NavLink to={`/${teamName}`}>
              {FuzzyMatch.fuzzyMatchHighlightPieces([teamName])}
            </NavLink>
          </div>
        );
      },
    };
    const editColumn: Column = {
      Header: '',
      accessor: 'edit',
      maxWidth: 50,
      Cell: (cellInfo: CellInfo) => {
        return (
          <S.menuWrapper>
            <ProjectActionMenu
              project={cellInfo.value}
              refetch={() => allProjectsQuery.refetch()}
            />
          </S.menuWrapper>
        );
      },
    };
    const allColumns = [nameColumn, lastRunColumn, runCountColumn, teamColumn];
    if (!readOnly) {
      allColumns.push(editColumn);
    }
    return allColumns;
  }, [readOnly, allProjectsQuery]);

  if (allProjectsQuery.loading) {
    return (
      <S.LoaderWrapper>
        <WandbLoader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper className={className}>
      <S.HeaderWrapper>
        <ImageIconHeader icon="project" text="Projects" />
        {!readOnly && (
          <Link to={newProjectPage(entityName)}>
            <Button primary size="tiny">
              Create new project
            </Button>
          </Link>
        )}
      </S.HeaderWrapper>
      <WBReactTable
        data={rows}
        columns={columns}
        pageSize={pageSize ?? 20}
        onChangePageSize={onChangePageSize}
      />
    </S.Wrapper>
  );
};

export default ProjectsTable;
