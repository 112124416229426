import {Ref as DiscussionCommentRef} from './types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {ThunkResult} from '../../../types/redux';

export function useDiscussionCommentRefThunk<
  RefType extends DiscussionCommentRef,
  ArgType extends any[],
  ThunkType extends ThunkResult<any>
>(ref: RefType | null, fn: (ref: RefType, ...fnArgs: ArgType) => ThunkType) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => (ref != null ? dispatch(fn(ref, ...args)) : void 0),
    [dispatch, ref, fn]
  );
}
