import '../css/SweepPage.less';

import React, {FC, useState, useEffect, useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Header,
  Image,
  Popup,
  Segment,
  Tab,
} from 'semantic-ui-react';
import YAML from 'yaml';

import AgentTable from '../components/AgentTable';
import DeleteSweepModal from '../components/DeleteSweepModal';
import NoMatch from '../components/NoMatch';
import FancyPage from '../components/FancyPage';
import MultiRunWorkspace from '../components/MultiRunWorkspace';
import ProjectAccess from '../components/ProjectAccess';
import WandbLoader from '../components/WandbLoader';
import * as Generated from '../generated/graphql';
import {
  useSweepPageQuery,
  SweepPageQueryData,
} from '../state/graphql/sweepPageQuery';
import {useDispatch} from '../state/hooks';
import * as PollingActions from '../state/polling/actions';
import * as PollingHooks from '../state/polling/hooks';
import * as Filter from '../util/filters';
import {AccessOptions} from '../util/permissions';
import * as Sweeps from '../util/sweeps';
import * as Url from '../util/urls';
import {getDefaultPanelSectionConfig} from '../util/panelbank';
import EditableField from '../components/EditableField';
import RunSelector from '../components/RunSelector';
import {RunQueryContext} from '../state/runs/context';
import {runNamesFilter} from '../util/filters';
import {
  SweepState,
  sweepStateDisplayName,
  SweepYamlConfig,
} from '../util/sweeps';
import {RunSetConfig} from '../util/section';
import {toggleSelection} from '../util/selectionmanager';
import * as Run from '../util/runs';
import {ProjectPageViewSpec} from '../state/views/projectPage/types';
import {Bash, Command} from '../components/Code';
import docUrl from '../util/doc_urls';
import Editor from '../components/Monaco/Editor';
import {propagateErrorsContext} from '../util/errors';
import Log from '../components/Log';
import {AnalyticsEvents} from '../util/integrations';
import {setDocumentTitle, setDocumentDescription} from '../util/document';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

const POLL_INTERVAL = 15000;

interface SweepPageProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
      projectId: string;
      sweepName: string;
      tab?: string;
    };
  };
  history: any;
}

type UseSweepPageProps = ReturnType<typeof useSweepPageProps>;
type UseSweepPagePropsAfterLoad = Omit<UseSweepPageProps, 'sweepQuery'> & {
  project: NonNullable<SweepPageQueryData['project']>;
  refetch: Exclude<
    ReturnType<typeof useSweepPageQuery>,
    {
      loading: true;
    }
  >['refetch'];
};

type SweepPageAllProps = SweepPageProps & UseSweepPagePropsAfterLoad;

// TODO: There are a lot of unnecessary non-null assertions (Typescript's
//   ! operator). We can get rid of them by making fewer things optional in
//   ReportSection
const SweepPage: FC<SweepPageAllProps> = makeComp(
  ({
    project,
    match,
    updateProject,
    configEditorValue,
    setConfigEditorValue,
    savingConfig,
    pollInterval,
    history,
    dispatch,
    upsertSweep,
    refetch,
    setSavingConfig,
  }) => {
    const sweep = project.sweep;
    const {entityName, projectName, sweepName, tab} = match.params;
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

    const config = YAML.parse(sweep.config) as SweepYamlConfig;

    const getSweepName = useCallback(() => {
      if (project.sweep) {
        return Sweeps.getSweepDisplayName(project.sweep);
      }
      return match.params.sweepName;
    }, [match, project]);

    const setSweepState = useCallback(
      (state: string) => {
        // These will either both be set, or both be unset. This is just for typing.
        if (sweep && refetch) {
          upsertSweep({variables: {id: sweep.id, state}}).then(() => refetch());
        }
      },
      [sweep, refetch, upsertSweep]
    );

    const canReconfigure = useCallback(() => {
      return project.sweep.state === 'PENDING';
    }, [project]);

    const saveConfig = useCallback(async () => {
      if (!canReconfigure() || savingConfig) {
        return;
      }

      if (sweep && refetch) {
        setSavingConfig(true);
        await upsertSweep({
          variables: {id: sweep.id, config: configEditorValue},
        });
        await refetch();
        setSavingConfig(false);
      }
    }, [
      canReconfigure,
      savingConfig,
      sweep,
      refetch,
      upsertSweep,
      configEditorValue,
      setSavingConfig,
    ]);

    const resetConfig = useCallback(() => {
      if (savingConfig) {
        return;
      }
      setConfigEditorValue(project.sweep.config);
    }, [project, savingConfig, setConfigEditorValue]);

    const canSaveConfig = useCallback(() => {
      if (!canReconfigure() || project == null) {
        return false;
      }
      return project.sweep.config !== configEditorValue;
    }, [canReconfigure, configEditorValue, project]);

    useEffect(() => {
      window.analytics.track(AnalyticsEvents.MULTI_RUN_WORKSPACE, {
        page: 'Sweep',
        panelbank: true,
      });
      dispatch(PollingActions.setBasePollInterval(POLL_INTERVAL));
    }, [dispatch, projectName]);

    const tabDescription: string = useMemo(() => {
      switch (tab) {
        case `overview`:
          return `Overview`;
        case `table`:
          return `Table`;
        case `agents`:
          return `Agents`;
        case `controls`:
          return `Controls`;
        case `logs`:
          return `Logs`;
        case `workspace`:
        default:
          return 'Workspace';
      }
    }, [tab]);

    useEffect(() => {
      setDocumentTitle(`${getSweepName()} | ${projectName} ${tabDescription}`);
      setDocumentDescription(
        `${tabDescription} of sweep ${getSweepName()} in ${projectName}, a machine learning project by ${entityName} using Weights & Biases.`
      );
    }, [tabDescription, getSweepName, projectName, entityName]);

    const sweepFilter: Filter.Filter = {
      key: {section: 'run', name: 'sweepName'},
      op: '=',
      value: sweepName,
    };

    const baseUrl =
      '/' + entityName + '/' + projectName + '/sweeps/' + sweepName;

    const priorRunsEdges = sweep.priorRuns.edges;

    const priorRunNames = priorRunsEdges.map(edge => edge.node.name);
    const priorRunsFilter = runNamesFilter(priorRunNames);
    const priorRunsTable = () => (
      <RunQueryContext.Provider
        value={{
          entityName,
          projectName,
          mergeFilters: priorRunsFilter,
        }}>
        <RunSelector
          className="single-wb-table"
          pageEntityName={entityName}
          pageProjectName={projectName}
          readOnly={true}
          pollInterval={0}
        />
      </RunQueryContext.Provider>
    );

    const updateDefaultSpec = (defaultSpec: ProjectPageViewSpec) => {
      const toggleSelectionInConfig = (
        runSetConfig: RunSetConfig,
        {
          node: run,
        }: {
          node: {
            name: string;
          };
        }
      ) => toggleSelection(runSetConfig, run as Run.Run, 1) as RunSetConfig;
      const oldConfig = defaultSpec.section.runSets![0];
      const newConfig = sweep.priorRuns.edges.reduce(
        toggleSelectionInConfig,
        oldConfig
      );
      return {
        ...defaultSpec,
        section: {
          ...defaultSpec.section,
          runSets: [newConfig],
          panelBankConfig: {
            ...defaultSpec.section.panelBankConfig,
            sections: [
              {
                ...getDefaultPanelSectionConfig({
                  name: 'Sweep',
                  type: 'grid',
                }),
                isOpen: true,
                panels: Sweeps.getDefaultPanels(project.sweep),
              },
              ...defaultSpec.section.panelBankConfig.sections,
            ],
          },
        },
      };
    };

    let configDownloadURL = '';
    if (sweep.config != null && sweep.config.length > 0) {
      const file = new Blob([sweep.config], {
        type: 'text/yaml',
      });
      configDownloadURL = URL.createObjectURL(file);
    }

    const onSetTableExpanded = (expanded: boolean) => {
      history.push({
        pathname: baseUrl + (expanded ? '/table' : '/workspace'),
        search: window.location.search,
      });
    };

    const overviewTabBody = () => (
      <div className="sweep-overview">
        <DeleteSweepModal
          open={deleteConfirmModalOpen}
          sweeps={[sweep]}
          onClose={() => setDeleteConfirmModalOpen(false)}
          onDelete={() =>
            history.push(
              Url.project({
                name: projectName,
                entityName,
              })
            )
          }
        />
        <div className="sweep-overview-top">
          <div className="mini-page-header">
            <div className="name-header-wrapper">
              <EditableField
                className="name-header"
                readOnly={project.readOnly}
                asHeader="h1"
                placeholder={getSweepName()}
                showEditIcon
                value={getSweepName()}
                save={displayName => {
                  upsertSweep({
                    variables: {id: sweep.id, displayName},
                  });
                }}
              />
              <Dropdown
                className="dropdown-menu-icon-button"
                floating
                trigger={<Button size="tiny" icon="ellipsis vertical" />}
                direction="left">
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="trash"
                    content="Delete sweep"
                    disabled={project.readOnly}
                    onClick={() => setDeleteConfirmModalOpen(true)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <EditableField
              className="description"
              readOnly={project.readOnly}
              multiline
              showEditIcon
              renderLinks
              placeholder={'What makes this sweep special?'}
              value={sweep.description}
              save={value => {
                upsertSweep({variables: {id: sweep.id, description: value}});
              }}
            />
          </div>
          <div className="overview-item">
            <div className="overview-key">ID</div>
            <div className="overview-value">{sweep.name}</div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Privacy</div>
            <div className="overview-value">
              <ProjectAccess
                project={project}
                updateProjectAccess={(access: AccessOptions) =>
                  updateProject({
                    variables: {
                      id: project.id,
                      access,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Created</div>
            <div className="overview-value">
              {new Date(sweep.createdAt + 'Z').toLocaleString()}
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Last updated</div>
            <div className="overview-value">
              {new Date(sweep.updatedAt + 'Z').toLocaleString()}
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Launch agent</div>
            <div className="overview-value">
              <Bash>
                <Command>
                  wandb agent {entityName}/{projectName}/{sweepName}
                </Command>
              </Bash>
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Author</div>
            <div className="overview-value">
              <Link className="user-link" to={`/${sweep.user.username}`}>
                <Image
                  src={sweep.user.photoUrl}
                  avatar
                  onError={(i: any) => (i.target.style.display = 'none')}
                />
                {sweep.user.username}
              </Link>
            </div>
          </div>
        </div>
        <div className="sweep-overview-body">
          {priorRunNames.length > 0 && (
            <>
              <Header as="h3">Prior Runs ({priorRunNames.length})</Header>
              {priorRunsTable()}
            </>
          )}
          {Boolean(sweep.config) && (
            <>
              <Header as="h3">Sweep Configuration</Header>
              {canReconfigure() && (
                <>
                  <p>
                    To change your sweep configuration,{' '}
                    <a href={configDownloadURL} download="sweep.yaml">
                      download your configuration file
                    </a>{' '}
                    and save it as sweep.yaml.
                  </p>
                  <p>
                    Modify it as you like then run the command below to upload
                    the new configuration.
                  </p>
                  <Bash>
                    <Command>
                      wandb sweep --update {entityName}/{projectName}/
                      {sweep.name} sweep.yaml
                    </Command>
                  </Bash>
                  <p>
                    Check out our{' '}
                    <TargetBlank href={docUrl.sweeps} className="doc-link">
                      docs
                    </TargetBlank>{' '}
                    for more information.
                  </p>
                  <p>
                    You can also edit your configuration in the editor below:
                  </p>
                </>
              )}
              <div className="sweep-config-wrapper">
                {canReconfigure() ? (
                  <Editor
                    value={configEditorValue}
                    onChange={setConfigEditorValue}
                    language="yaml"
                  />
                ) : (
                  <pre>{sweep.config}</pre>
                )}
              </div>
              {canReconfigure() && (
                <div className="sweep-config-save">
                  <Button
                    size="tiny"
                    disabled={!canSaveConfig() || savingConfig}
                    onClick={resetConfig}>
                    Cancel
                  </Button>
                  <Button
                    size="tiny"
                    primary
                    disabled={!canSaveConfig() || savingConfig}
                    loading={savingConfig}
                    onClick={saveConfig}>
                    Save
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );

    const workspaceTabBody = (tableExpanded: boolean) => () =>
      (
        <MultiRunWorkspace
          entityName={entityName}
          projectName={projectName}
          workspaceObjectID={sweep.name}
          tableExpanded={tableExpanded}
          onSetTableExpanded={onSetTableExpanded}
          viewType="sweep-view"
          title={`Sweep: ${getSweepName()}`}
          mergeFilters={Filter.Or([sweepFilter, priorRunsFilter])}
          runSetName={`Sweep: ${getSweepName()}`}
          recommendGrouping="group-and-jobtype"
          defaultSpecPostFn={updateDefaultSpec}
          runsQueryContextValue={{
            sweep: {
              id: sweep.id,
              earlyTerminate: config.early_terminate != null,
            },
          }}
        />
      );

    const agentTabBody = () => {
      return (
        <Tab.Pane>
          <div style={{marginBottom: '40px'}}>
            <AgentTable agents={sweep.agents.edges.map(e => e.node)} />
          </div>
        </Tab.Pane>
      );
    };

    const sweepControlsTabBody = () => {
      const state: SweepState = (sweep.state as SweepState) || 'UNKNOWN';
      const pauseMsg = 'Pause the sweep to temporarily stop running new runs.';
      const resumeMsg = 'Resume the sweep to continue running new runs.';
      const finishMsg =
        'Finish the sweep to stop running new runs and let currently running runs finish.';
      const restartMsg =
        'Resume running the sweep. If you exhausted a grid search, no new runs will run unless you delete some of the previous runs.';
      const cancelMsg =
        'Cancel the sweep to kill all running runs and stop running new runs.';
      return (
        <Tab.Pane>
          <Segment padded="very">
            <Header as="h3">Sweep state: {sweepStateDisplayName(state)}</Header>
            <p style={{marginBottom: 32}}>
              <span className="hint-text">
                Create an agent to start your sweep. You can't reconfigure the
                sweep once it has started.
              </span>
            </p>
            <Divider style={{marginTop: 48, marginBottom: 48}} />
            <Header as="h4">Pause / Resume</Header>
            <p style={{marginBottom: 32}}>
              <span className="hint-text">
                {state === 'PAUSED' && resumeMsg}
                {state === 'RUNNING' && pauseMsg}
              </span>
            </p>
            <p>
              <Popup
                trigger={
                  <Button
                    size="small"
                    icon="play"
                    content="Unpause"
                    disabled={state !== 'PAUSED'}
                    onClick={() => setSweepState('RUNNING')}
                  />
                }
                content={resumeMsg}
              />
              {state === 'RUNNING' && (
                <Popup
                  trigger={
                    <Button
                      size="small"
                      icon="pause"
                      content="Pause"
                      onClick={() => setSweepState('PAUSED')}
                    />
                  }
                  content={pauseMsg}
                />
              )}
            </p>
            <Divider style={{marginTop: 48, marginBottom: 48}} />
            <Header as="h4">Stop</Header>
            <p style={{marginBottom: 32}}>
              <span className="hint-text">{finishMsg}</span>
            </p>
            <p>
              <Popup
                trigger={
                  <Button
                    color="red"
                    size="small"
                    icon="check"
                    content="Stop"
                    disabled={state !== 'RUNNING' && state !== 'PAUSED'}
                    onClick={() => setSweepState('FINISHED')}
                  />
                }
                content={finishMsg}
              />
              {state === 'FINISHED' && (
                <Popup
                  trigger={
                    <Button
                      size="small"
                      icon="play"
                      content="Resume"
                      onClick={() => setSweepState('RUNNING')}
                    />
                  }
                  content={restartMsg}
                />
              )}
            </p>
            <Divider style={{marginTop: 48, marginBottom: 48}} />
            <Header as="h4">Cancel</Header>
            <p style={{marginBottom: 32}}>
              <span className="hint-text">{cancelMsg}</span>
            </p>
            <p>
              <Popup
                trigger={
                  <Button
                    color="red"
                    size="small"
                    icon="cancel"
                    disabled={state !== 'RUNNING' && state !== 'PAUSED'}
                    content="Kill"
                    onClick={() => setSweepState('CANCELED')}
                  />
                }
                content={cancelMsg}
              />
            </p>
          </Segment>
        </Tab.Pane>
      );
    };

    const logsTabBody = () => {
      if (!sweep.controllerRunName) {
        return null;
      }
      // const outputLogFile = RunHelpers.outputLogFile(run);
      return (
        <>
          <Log
            entityName={entityName}
            projectName={projectName}
            runName={sweep.controllerRunName}
            pollInterval={pollInterval}
          />
        </>
      );
    };

    return (
      <div style={{marginLeft: 0, marginRight: 0}}>
        <FancyPage
          history={history}
          baseUrl={baseUrl}
          activeSlug={tab}
          defaultIndex={1}
          items={[
            {
              iconName: 'cecile-overview-gray',
              selectedIconName: 'cecile-overview',
              name: 'Overview',
              slug: 'overview',
              render: overviewTabBody,
            },
            {
              iconName: 'cecile-workspace-gray',
              selectedIconName: 'cecile-workspace',
              name: 'Sweep Workspace',
              slug: 'workspace',
              render: workspaceTabBody(false),
            },
            {
              iconName: 'cecile-table-gray',
              selectedIconName: 'cecile-table',
              name: 'Sweep Table',
              slug: 'table',
              render: workspaceTabBody(true),
            },
            {
              iconName: 'cecile-agent-gray',
              selectedIconName: 'cecile-agent',
              name: 'Agents',
              slug: 'agents',
              render: agentTabBody,
            },
            {
              iconName: 'cecile-controls-gray',
              selectedIconName: 'cecile-controls',
              name: 'Sweep Controls',
              slug: 'controls',
              render: sweepControlsTabBody,
            },
            ...(sweep.controllerRunName
              ? [
                  {
                    iconName: 'cecile-logs-gray',
                    selectedIconName: 'cecile-logs',
                    name: 'Logs',
                    slug: 'logs',
                    render: logsTabBody,
                  },
                ]
              : []),
          ]}
        />
      </div>
    );
  },
  {id: 'SweepPage', memo: true}
);

function useSweepPageProps(
  entityName: string,
  projectName: string,
  sweepName: string
) {
  const [configEditorValue, setConfigEditorValue] = useState('');
  const [savingConfig, setSavingConfig] = useState(false);

  const sweepQuery = useSweepPageQuery(
    {
      entityName,
      projectName,
      sweepName,
    },
    {
      fetchPolicy: 'network-only',
      enablePolling: true,
    }
  );

  const pollInterval = PollingHooks.usePollInterval();

  const sweepConfig =
    (!sweepQuery.loading && sweepQuery.project?.sweep?.config) || '';
  useEffect(() => {
    setConfigEditorValue(sweepConfig);
  }, [sweepConfig]);

  const [updateProject] = Generated.useUpsertModelMutation();
  const [upsertSweep] = Generated.useUpsertSweepMutation({
    // Show the error message to the user instead of rendering error page
    context: propagateErrorsContext(),
    onError: ({networkError}) => {
      const message = (networkError as any)?.result?.errors[0]?.message;
      if (message != null) {
        alert(message);
      }
    },
  });

  const dispatch = useDispatch();

  return {
    dispatch,
    updateProject,
    sweepQuery,
    upsertSweep,
    configEditorValue,
    setConfigEditorValue,
    savingConfig,
    setSavingConfig,
    pollInterval,
  };
}

export default makeComp(
  (props: SweepPageProps) => {
    const {entityName, projectName, sweepName} = props.match.params;
    const selectedProps = useSweepPageProps(entityName, projectName, sweepName);

    if (selectedProps.sweepQuery.loading) {
      return <WandbLoader />;
    }
    if (
      selectedProps.sweepQuery.project == null ||
      selectedProps.sweepQuery.project.sweep == null
    ) {
      return <NoMatch />;
    }

    return (
      <SweepPage
        {...props}
        {...selectedProps}
        project={selectedProps.sweepQuery.project}
        refetch={selectedProps.sweepQuery.refetch}
      />
    );
  },
  {id: 'SweepPage.default'}
);
