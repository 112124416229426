import * as Report from '../util/report';

import {createAction} from 'typesafe-actions';

export const clear = createAction('report/CLEAR', resolve => {
  return () => resolve();
});

export const set = createAction('report/ADD', resolve => {
  return (report: Report.ReportConfig) => resolve({report});
});
