import '../css/GroupPage.less';

import {ApolloClient} from 'apollo-client';
import React, {useEffect, useMemo, useState} from 'react';

import FancyPage from '../components/FancyPage';
import MultiRunWorkspace, {
  MultiRunWorkspaceProps,
} from '../components/MultiRunWorkspace';
import NoMatch from '../components/NoMatch';
import WandbLoader from '../components/WandbLoader';
import * as Run from '../util/runs';
import {useDispatch} from '../state/hooks';
import * as PollingActions from '../state/polling/actions';
import * as Filter from '../util/filters';
import {AnalyticsEvents} from '../util/integrations';
import {setDocumentTitle, setDocumentDescription} from '../util/document';
import makeComp from '../util/profiler';
import {decodeURIComponentSafe} from '../util/url';
import * as Urls from '../util/urls';
import RunOverview from './Run/RunOverview';
import {RunGroupOverview} from './RunGroupOverview';
import {
  useDeleteRunMutation,
  useGroupPageQuery,
  useRunQuery,
  useRunsStateQueryQuery,
  useUpsertModelMutation,
  useUpsertRunMutation,
} from '../generated/graphql';
import _ from 'lodash';
import {JSONparseNaN} from '@wandb/cg/browser/utils/jsonnan';
import * as RunHelpers from '../util/runhelpers';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import Log from '../components/Log';
import RunArtifacts from './Run/RunArtifacts';
import RunFiles from '../components/RunFiles';
import {useViewer} from '../state/viewer/hooks';

const POLL_INTERVAL = 15000;

const useRunSelection = (props: {
  entityName: string;
  projectName: string;
  groupName: string;
}) => {
  const {groupName} = props;
  const [selectedRunName, setSelectedRunName] = useState<string>();
  const firstRunQuery = useRunsStateQueryQuery({
    variables: {
      ...props,
      filters: `{"group":"${groupName}"}`,
      sampledHistorySpecs: [],
      groupKeys: [],
      groupLevel: 0,
      order: '-createdAt',
      limit: 1,
    },
  });
  const firstRunInGroup = _.first(
    firstRunQuery.data?.project?.runs?.edges.map(e => e.node)
  );

  useEffect(() => {
    if (selectedRunName == null && firstRunInGroup != null) {
      setSelectedRunName(firstRunInGroup.name);
    }
  }, [selectedRunName, firstRunInGroup]);

  return {selectedRunName, setSelectedRunName};
};

const useRunHelper = (
  projectName: string,
  entityName: string,
  runName?: string
) => {
  const {loading, data, refetch} = useRunQuery({
    skip: runName == null,
    variables: {entityName, projectName, runName: runName ?? ''},
  });
  const project = data?.project;
  const run = project?.run;
  const summaryMetrics =
    (run != null
      ? Run.parseSummary(run.summaryMetrics, run.name)
      : undefined) ?? {};

  const [updateRun] = useUpsertRunMutation();
  const [deleteRun] = useDeleteRunMutation();
  return {
    loading,
    run,
    project,
    summaryMetrics,
    updateRun,
    deleteRun,
    refetch,
  };
};

const useAnalyticsEventsWorkspace = (
  groupName: string,
  entityName: string,
  projectName: string
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.analytics.track(AnalyticsEvents.MULTI_RUN_WORKSPACE, {
      page: 'Group',
      panelbank: true,
    });
    dispatch(PollingActions.setBasePollInterval(POLL_INTERVAL));
  }, [dispatch, groupName, entityName, projectName]);
};

const useGroupPageSeo = (
  groupName: string,
  projectName: string,
  entityName: string,
  tab: string
) => {
  const tabDescription: string = useMemo(() => {
    switch (tab) {
      case `overview`:
        return `Overview`;
      case `system`:
        return `System`;
      case `logs`:
        return `Logs`;
      case `files`:
        return 'Files';
      case `artifacts`:
        return 'Artifacts';
      case `code`:
        return 'Code';
      case `table`:
        return `Table`;
      case `workspace`:
      default:
        return 'Workspace';
    }
  }, [tab]);

  useEffect(() => {
    setDocumentTitle(`${groupName} | ${projectName} ${tabDescription}`);
    setDocumentDescription(
      `${tabDescription} of group ${groupName} in ${projectName}, a machine learning project by ${entityName} using Weights & Biases.`
    );
  }, [tabDescription, groupName, projectName, entityName]);
};

interface GroupPageProps {
  client: ApolloClient<any>;
  history: any;
  match: {
    params: {
      entityName: string;
      projectName: string;
      projectId: string;
      groupName: string;
      tab: string;
      filePath?: string;
    };
  };
}

const GroupPage = makeComp(
  (props: GroupPageProps) => {
    const {entityName, projectName, tab, filePath} = props.match.params;
    const viewer = useViewer();

    // Group names can contain special characters, so we need to URL
    // decode the group name to recover the original name.
    const groupName = decodeURIComponentSafe(props.match.params.groupName);

    useAnalyticsEventsWorkspace(groupName, entityName, projectName);
    useGroupPageSeo(groupName, entityName, projectName, tab);

    const {loading, data, refetch} = useGroupPageQuery({
      variables: {entityName, projectName, groupName},
    });

    const {selectedRunName, setSelectedRunName} = useRunSelection({
      entityName,
      projectName,
      groupName,
    });

    const {
      run,
      project,
      summaryMetrics,
      updateRun,
      deleteRun,
      refetch: refetchRunData,
    } = useRunHelper(projectName, entityName, selectedRunName);

    const [updateProject] = useUpsertModelMutation();

    if (loading) {
      return <WandbLoader />;
    }

    if (data?.project == null) {
      return <NoMatch />;
    }
    const {runGroup} = data.project;

    const mergeFilters: Filter.Filter = {
      key: {section: 'run', name: 'group'},
      op: '=',
      value: groupName === '<null>' ? null : groupName,
    };

    const baseUrl = Urls.runGroup({entityName, projectName, name: groupName});
    const onSetTableExpanded = (expanded: boolean) => {
      props.history.push({
        pathname: baseUrl + (expanded ? '/table' : '/workspace'),
        search: window.location.search,
      });
    };

    const workspaceMultiTabBody =
      (
        multiProps: Partial<MultiRunWorkspaceProps>,
        children?: () => React.ReactNode
      ) =>
      () =>
        selectedRunName == null && children != null ? (
          <WandbLoader />
        ) : (
          <MultiRunWorkspace
            entityName={entityName}
            projectName={projectName}
            tableExpanded={false}
            onSetTableExpanded={onSetTableExpanded}
            viewType="group-view"
            title={`Group: ${groupName}`}
            mergeFilters={mergeFilters}
            recommendGrouping="jobtype"
            selectedRunName={children != null ? selectedRunName : undefined}
            onSelectRunName={setSelectedRunName}
            {...multiProps}>
            {children?.()}
          </MultiRunWorkspace>
        );

    return (
      <div className="group-page">
        <FancyPage
          history={props.history}
          activeSlug={tab}
          baseUrl={baseUrl}
          defaultIndex={0}
          items={[
            {
              iconName: 'cecile-overview-gray',
              selectedIconName: 'cecile-overview',
              name: 'Overview',
              slug: 'overview',
              render: workspaceMultiTabBody({isSingleMode: true}, () => (
                <>
                  {runGroup && (
                    <RunGroupOverview
                      group={runGroup}
                      availableTags={project?.tags ?? []}
                      projectName={projectName}
                      entityName={entityName}
                      history={props.history}
                    />
                  )}
                  {run != null && (
                    <RunOverview
                      key={run.name}
                      project={project as any}
                      run={run as any}
                      summaryMetrics={summaryMetrics}
                      history={props.history}
                      config={JSONparseNaN(run.config)}
                      viewer={viewer as any}
                      updateRun={async fields => {
                        await updateRun({
                          variables: {
                            id: run.id,
                            tags: run.tags.map(t => t.name),
                            displayName: run.displayName,
                            notes: run.notes,
                            ...fields,
                          },
                        });
                        refetch();
                        refetchRunData();
                      }}
                      updateProject={fields =>
                        updateProject({
                          variables: {
                            id: project?.id,
                            entityName: project?.entityName,
                            name: project?.name,
                            ...fields,
                          },
                        })
                      }
                      deleteRun={id =>
                        deleteRun({variables: {id}}).then(() =>
                          window.location.reload()
                        )
                      }
                    />
                  )}
                </>
              )),
            },
            {
              iconName: 'cecile-workspace-gray',
              selectedIconName: 'cecile-workspace',
              name: 'Workspace',
              slug: 'workspace',
              render: workspaceMultiTabBody({tableExpanded: false}),
            },
            {
              iconName: 'cecile-table-gray',
              selectedIconName: 'cecile-table',
              name: 'Table',
              slug: 'table',
              render: workspaceMultiTabBody({tableExpanded: true}),
            },
            {
              iconName: 'cecile-logs-gray',
              selectedIconName: 'cecile-logs',
              name: 'Logs',
              slug: 'logs',
              render: workspaceMultiTabBody(
                {isSingleMode: true, showLogCounts: true},
                () => {
                  if (run == null) {
                    return;
                  }
                  const outputLogFile = RunHelpers.outputLogFile(run as any);
                  return (
                    <>
                      {outputLogFile && (
                        <a
                          href={outputLogFile}
                          download="output.log"
                          className="download-logs-button">
                          <LegacyWBIcon name="download" />
                        </a>
                      )}

                      <Log
                        entityName={entityName}
                        projectName={projectName}
                        runName={run.name}
                        pollInterval={0}
                        hideTimestamp
                      />
                    </>
                  );
                }
              ),
            },
            {
              iconName: 'cecile-files-gray',
              selectedIconName: 'cecile-files',
              name: `Files`,
              slug: 'files',
              render: workspaceMultiTabBody(
                {isSingleMode: true},
                () =>
                  run && (
                    <RunFiles
                      entityName={entityName}
                      projectName={projectName}
                      runName={run.name}
                      path={filePath}
                      totalFiles={run.fileCount ?? 0}
                      history={props.history}
                      groupName={groupName}
                    />
                  )
              ),
            },
            {
              iconName: 'artifact-gray',
              selectedIconName: 'artifact-white',
              name: `Artifacts`,
              slug: 'artifacts',
              render: workspaceMultiTabBody(
                {isSingleMode: true, showArtifactCounts: true},
                () =>
                  run && (
                    <RunArtifacts
                      entityName={entityName}
                      projectName={projectName}
                      runName={run.name}
                    />
                  )
              ),
            },
          ]}
        />
      </div>
    );
  },
  {id: 'GroupPage'}
);

export default GroupPage;
