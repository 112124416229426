import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';

import LegacyWBIcon from './LegacyWBIcon';
import makeComp from '../../util/profiler';

export const ModalTriggerButton: React.FC<{
  modalHeader: string;
  modalContent: JSX.Element;
  buttonIcon: string;
  buttonText: string;
}> = makeComp(
  ({modalContent, modalHeader, buttonIcon, buttonText}) => {
    const [showModal, setShowModal] = useState(false);
    const openModal = useCallback(() => setShowModal(true), [setShowModal]);
    const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

    return (
      <React.Fragment>
        {/* COLUMNS CONFIG */}
        <Modal
          className="small"
          trigger={
            <Button size="tiny" className="wb-icon-button" onClick={openModal}>
              <LegacyWBIcon name={buttonIcon} />
              {buttonText}
            </Button>
          }
          open={showModal}
          onClose={closeModal}>
          <Modal.Header>{modalHeader}</Modal.Header>
          <Modal.Content>{modalContent}</Modal.Content>
          <Modal.Actions>
            <Button primary onClick={closeModal}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  },
  {id: 'ModalTriggerButton'}
);
