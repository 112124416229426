import {useSelector, useDispatch} from 'react-redux';
import * as Selectors from './selectors';
import {AvailableNavigation} from './reducer';
import {updateAvailableNavigation} from './actions';

export function useHasNavigation(): [
  AvailableNavigation,
  (nav: AvailableNavigation) => void
] {
  const dispatch = useDispatch();
  const updateNav = (nav: AvailableNavigation): void => {
    dispatch(updateAvailableNavigation(nav));
  };
  return [useSelector(Selectors.getHasNavigation), updateNav];
}
