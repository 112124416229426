import * as S from './gallery.styles';

import React, {useState} from 'react';
import {Node, Element, Editor, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSlate,
} from 'slate-react';
import {BlockWrapper} from './drag-drop';
import {useFeaturedReportsMetadataQuery} from '../../../generated/graphql';
import ReportShowcase from '../../ReportShowcase';
import {Input, Button} from 'semantic-ui-react';
import {parseNameAndIDFromURL} from '../../../util/url';
import {ReportsTableReport} from '../../ReportsTable';
import {toast} from '../../elements/Toast';

export interface Gallery extends Element {
  type: 'gallery';
  ids: string[];
}

export const isGallery = (node: Node): node is Gallery =>
  node.type === 'gallery';

export const GalleryElement: React.FC<
  RenderElementProps & {
    element: Gallery;
  }
> = ({attributes, element, children}) => {
  const [link, setLink] = useState('');
  const requests = useFeaturedReportsMetadataQuery({
    skip: element.ids.length === 0,
    variables: {ids: element.ids},
  });
  const showcasedReports =
    requests.data?.views?.edges
      .map(edge => edge.node)
      .sort(
        (a, b) =>
          element.ids.indexOf(a?.id ?? '') - element.ids.indexOf(b?.id ?? '')
      ) ?? [];
  const readOnly = useReadOnly();
  const editor = useSlate();
  const addReportID = () => {
    try {
      const id = parseNameAndIDFromURL(link).id;
      if (element.ids.includes(id)) {
        toast('This report already exists in gallery');
      } else {
        Transforms.setNodes(
          editor,
          {ids: element.ids.concat([id])},
          {at: ReactEditor.findPath(editor, element)}
        );
      }
      setLink('');
    } catch (e) {
      alert(e);
    }
  };
  return (
    <BlockWrapper attributes={attributes} element={element}>
      {!readOnly && (
        <div contentEditable={false}>
          <Input
            placeholder="Input your report url"
            value={link}
            onChange={e => setLink(e.target.value)}
          />
          <Button onClick={addReportID}>Add Report</Button>
        </div>
      )}
      <S.Gallery>
        {children}
        {showcasedReports != null && showcasedReports.length > 0 && (
          <ReportShowcase
            reports={showcasedReports as unknown as ReportsTableReport[]}
            readOnly={readOnly}
          />
        )}
      </S.Gallery>
    </BlockWrapper>
  );
};

export const withGallerys = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isGallery(element) ? true : isVoid(element);
  };
  return editor;
};
