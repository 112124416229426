import * as Sentry from '@sentry/browser';
import * as _ from 'lodash';
import * as React from 'react';
import {useLocation, Redirect} from 'react-router-dom';
import {Modal} from 'semantic-ui-react';
import 'whatwg-fetch';
import ErrorPage from './components/ErrorPage';
import './components/vis/ReactVis.css';
import Loader from './components/WandbLoader';
import * as CGReact from './cgreact';
import './css/App.less';
import './css/Components.less';
import './react-autosuggest.css';
import {history, isInIframe, isInJupyterNotebook} from './setup';
import {displayError} from './state/global/actions';
import {InteractStateProvider} from './state/views/interactState/context';
import {useDispatch, useSelector} from './state/hooks';
import * as ViewerHooks from './state/viewer/hooks';
import {hideZendeskChat, showZendeskChat} from './util/analytics';
import makeComp from './util/profiler';
import {captureError, shouldReloadOnError} from './util/integrations';
import {mouseListenersStart} from './util/mouse';
import ErrorPortal from './components/ErrorPortal';
import {useActivityDetectionLoop} from './state/polling/hooks';
import NoMatch from './components/NoMatch';
import {viewerUsingAdminPrivileges} from './util/admin';
import {PUBLISHED_PROJECT_NAME} from './util/constants';
import {disableBeamer, openBeamerOnLoad} from './util/beamer';
import {RouteWithLayout} from './routes';
import {ThemeProvider} from '@wandb/ui';
import {
  tagFromQS,
  getReportGalleryPathParams,
  isOnReportGallery,
  useInitGalleryTags,
  Tag,
  formatGalleryTag,
  useGalleryTags,
} from './util/gallery';
import {isOnReportView} from './util/url';
import startTrace from './util/trace';
import {useEffect} from 'react';
import {DateFromUTCString} from './util/time';
import {envIsIntegration} from './config';
import {Viewer} from './state/viewer/types';

const SURVEY_SETUP_DATE = DateFromUTCString('2021-10-20T00:00:00');

interface AppProps {
  children: React.ReactNode;
}

type AllAppProps = AppProps & ReturnType<typeof useAppProps>;

class App extends React.Component<AllAppProps> {
  componentDidCatch(error: any, info: any) {
    captureError(error, 'app_componentdidcatch');
    this.props.dispatch(displayError(error));
    if (!shouldReloadOnError()) {
      Sentry.showReportDialog();
    }
  }

  componentDidMount() {
    mouseListenersStart();

    if (isInIframe()) {
      hideZendeskChat();
    }
  }

  componentWillUnmount() {
    if (isInIframe()) {
      showZendeskChat();
    }
  }

  render() {
    const {
      viewerState,
      galleryTagsQuery,
      error,
      showErrorPortal,
      errorPortalContent,
      children,
    } = this.props;
    // This is required, downstream components assume viewer is loaded.
    if (viewerState.loading || galleryTagsQuery.loading) {
      return <Loader />;
    }
    const {viewer} = viewerState;
    const trialExpiredModal = undefined;
    // TODO(aswanberg): Disable teams in the backend.
    const blockList = ['luminar'];

    if (viewer && viewer.teams) {
      const teams = viewer.teams.edges;
      if (_.find(teams, t => _.includes(blockList, t.node.name)) != null) {
        return (
          <Modal dimmer="blurring" open={true}>
            <Modal.Header>Trial expired</Modal.Header>
            <Modal.Content>
              The trial period for this account has expired. Please work with
              Weights &amp; Biases to setup payment for continued usage.
            </Modal.Content>
          </Modal>
        );
      }
    }

    const classes = [];
    if (isInIframe()) {
      classes.push('iframe');
    }
    if (isInJupyterNotebook()) {
      classes.push('jupyter');
    }
    if (
      viewer &&
      viewer.userInfo &&
      viewer.userInfo.bio &&
      viewer.userInfo.bio.toLowerCase().includes('night')
    ) {
      classes.push('night-mode');
    }
    // Enforce user to choose a username
    const isAtSignup = window.location.pathname.endsWith('/signup');
    const isAtLogout = window.location.pathname.endsWith('/logout');
    const isUsernameRequired = viewer != null && !(isAtLogout || isAtSignup);
    const hasIncompleteSurvey = viewerHasIncompleteSurvey(viewer);

    if (isUsernameRequired && (viewer?.signupRequired || hasIncompleteSurvey)) {
      // We disable Beamer auto-open for first time signups for a day
      disableBeamer();
      return <Redirect to={{pathname: '/signup', state: {internal: true}}} />;
    }
    // disable beamer on the email verification page
    if (
      error != null &&
      error.code === 403 &&
      error.message &&
      typeof error.message === 'string' &&
      error.message.match('Email must be verified')
    ) {
      disableBeamer();
    }
    if (viewer != null) {
      openBeamerOnLoad();
    }

    const usingAdminPowers = viewer?.admin && viewerUsingAdminPrivileges();
    const onPublishedEditPage = history.location.pathname.includes(
      PUBLISHED_PROJECT_NAME
    );
    const onHiddenPage = !usingAdminPowers && onPublishedEditPage;
    return (
      <ThemeProvider>
        <div className={'app-root ' + classes.join(' ')}>
          {trialExpiredModal}
          {error ? (
            <RouteWithLayout
              error={error}
              component={ErrorPage}
              auth={undefined}
              allowIframes
            />
          ) : onHiddenPage ? (
            <RouteWithLayout component={NoMatch} />
          ) : (
            children
          )}
          <ErrorPortal open={showErrorPortal}>{errorPortalContent}</ErrorPortal>
        </div>
      </ThemeProvider>
    );
  }
}

function useAppProps() {
  // DO NOT REMOVE THIS useOnLocationChange()
  // We need it to re-render App on every location change,
  // which is necessary due to how routes are being rendered.
  useOnLocationChange();

  const galleryTagsQuery = useInitGalleryTags();
  const dispatch = useDispatch();
  // Note: Very strange... if the selector passed in to the next line
  // (to grab state.global.error) is a globally defined function instead
  // of an inline function, we always get undefined back, at least after
  // faking a login error. This could be a serious issue but I haven't
  // been able to figure out the cause. Defining it inline at least
  // gives the correct result.
  const error = useSelector(state => state.global.error);
  const showErrorPortal = useSelector(state => state.global.showErrorPortal);
  const errorPortalContent = useSelector(
    state => state.global.errorPortalContent
  );
  // We keep the viewer state in the redux store, you can't call Auth.loggedIn
  // until this has been loaded.
  ViewerHooks.useInitViewer();
  // TODO: we should verify the unstable profile images don't cause the app
  // to re-render.  See state/viewer/hooks.ts#useViewer
  const viewerState = useSelector(state => state.viewer);

  return {
    viewerState,
    error,
    showErrorPortal,
    errorPortalContent,
    dispatch,
    galleryTagsQuery,
  };
}

export default makeComp(
  (props: AppProps) => {
    const selectedProps = useAppProps();

    // This starts the loop that detects when the tab is backgrounded or
    // the user is inactive, which is used to control polling.
    useActivityDetectionLoop();

    return (
      <CGReact.ComputeGraphContextProvider>
        <InteractStateProvider>
          <App {...props} {...selectedProps} />
        </InteractStateProvider>
      </CGReact.ComputeGraphContextProvider>
    );
  },
  {id: 'App'}
);

function addGalleryTagData(
  tags: Tag[],
  pageViewProps: {[key: string]: string}
): void {
  const galleryPathParams = getReportGalleryPathParams(tags);

  let tag: string | null = null;
  if (isOnReportGallery() && galleryPathParams != null) {
    tag = galleryPathParams.tag;
    if (tag) {
      pageViewProps.value = 'Tag Home';
    }
  } else if (isOnReportView()) {
    tag = tagFromQS();
  }

  if (tag) {
    const formattedTag = formatGalleryTag(tags, tag);
    pageViewProps.category = 'Tag';
    pageViewProps.label = formattedTag;
  }
}

function trackPageView(tags: Tag[]): void {
  const properties: {[key: string]: string} = {};
  addGalleryTagData(tags, properties);
  window.analytics.page(properties);
}

function useOnLocationChange(): void {
  useLocation();

  const tags = useGalleryTags();
  useEffect(() => {
    if (tags.length > 0) {
      trackPageView(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, tags]);

  useEffect(() => {
    startTrace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);
}

function viewerHasIncompleteSurvey(viewer: Viewer | undefined) {
  // test integration initiates users without survey
  // so always return false to skip this checking and not block tests
  if (envIsIntegration) {
    return false;
  }

  const createdAt = viewer?.createdAt;
  if (createdAt == null) {
    return false;
  }

  // We only check if the user finished the survey for those who joined recently
  const createdAtDate = DateFromUTCString(createdAt);
  if (createdAtDate < SURVEY_SETUP_DATE) {
    return false;
  }

  return viewer?.userInfo?.howOften == null;
}
