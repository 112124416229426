import React, {ReactNode} from 'react';
import {graphql} from 'react-apollo';
import {Card, Header, Icon} from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import {History} from 'history';
import Breadcrumbs from '../Breadcrumbs';

import {TimeDelta} from '../../util/time';
import Markdown from '../Markdown';
import Loader from '../WandbLoader';
import {ALL_PROJECTS_QUERY, Node, ProjectWithDetails} from './graphql';

import '../../css/ProjectList.less';
import makeComp from '../../util/profiler';

interface AllProjectCardsProps {
  loading: boolean;
  allowCreate: boolean;
  entityName: string;
  projects: Array<Node<ProjectWithDetails>>;
  isFeaturedProjects: boolean;
  emptyContent: ReactNode;
  history: History;
}

const AllProjectCards: React.FC<AllProjectCardsProps> = makeComp(
  ({
    loading,
    allowCreate,
    entityName,
    projects,
    isFeaturedProjects,
    emptyContent,
    history,
  }) => {
    if (loading) {
      return (
        <div style={{position: 'relative', top: 300}}>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <Card.Group className="project-list link">
          {allowCreate && entityName && (
            <Card
              // Without this `as` prop, the Card will default to an illegal
              // <a> because of the presence of an `onClick`.
              as="div"
              className="raised"
              key="__createNew"
              style={{
                backgroundColor: 'rgb(0, 127, 175, 0.05)',
              }}
              onClick={() =>
                history.push(`/new-project?entityName=${entityName}`)
              }>
              <Card.Content>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 140,
                  }}>
                  <div style={{textAlign: 'center'}}>
                    <Header>Create Project</Header>
                    <Icon name="plus" />
                  </div>
                </div>
              </Card.Content>
            </Card>
          )}
          {projects.map(edge => {
            const project = edge.node;
            let projectPath = `/${project.entityName}/${project.name}/`;
            // if we're displaying featured projects, we want to link to the demo report rather than the project page
            // TODO: ideally, manage featured projects via admin page rather than command line and hardcoded param  =P
            if (isFeaturedProjects) {
              projectPath = projectPath + 'reports/?view=altay%2FDemo%20Report';
            }
            return (
              <Card
                as="div"
                key={project.id}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  history.push(projectPath);
                }}>
                <Card.Content style={{flexGrow: project.description ? 0 : 1}}>
                  <Card.Header>
                    <Breadcrumbs
                      style={{marginRight: 12, marginBottom: 5}}
                      entityName={project.entityName}
                      projectName={project.name}
                      disableLinks
                    />
                  </Card.Header>
                  <Card.Meta>
                    <i>
                      Last Active <TimeAgo date={project.lastActive + 'Z'} />
                    </i>
                  </Card.Meta>
                </Card.Content>
                {project.description && (
                  <Card.Content style={{maxHeight: 160, overflow: 'auto'}}>
                    <Card.Description>
                      <Markdown content={project.description} />
                    </Card.Description>
                  </Card.Content>
                )}
                <Card.Content extra>
                  <p>
                    <Icon name={project.totalUsers === 1 ? 'user' : 'users'} />
                    {project.totalUsers}{' '}
                    {project.totalUsers === 1 ? 'user' : 'users'}
                  </p>
                  <p>
                    <Icon name="rocket" />
                    {project.totalRuns}{' '}
                    {project.totalRuns === 1 ? 'run' : 'runs'}
                  </p>
                  <p>
                    <Icon name="microchip" />{' '}
                    {new TimeDelta(project.computeHours).toSingleUnitString()}{' '}
                    of compute time
                  </p>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
        <div style={{marginTop: 20}}>
          {projects.length === 0 && emptyContent}
        </div>
      </div>
    );
  },
  {id: 'AllProjectCards'}
);

const withData = graphql(ALL_PROJECTS_QUERY, {
  options: ({limit, entityName}: any) => ({
    variables: {entityName, first: limit},
    fetchPolicy: 'cache-and-network',
  }),
  props: ({
    data: {
      loading,
      viewer,
      fetchMore,
      error,
      variables: {entityName},
    },
  }: any) => ({
    loading,
    projects: viewer ? viewer.projects.edges : [],
    error,
    entityName,
    loadMoreEntries: () => {
      return fetchMore({
        query: ALL_PROJECTS_QUERY,
        variables: {
          cursor: viewer.projects.pageInfo.endCursor,
        },
        updateQuery: (previousResult: any, {fetchMoreResult}: any) => {
          const newEdges = fetchMoreResult.data.viewer.projects.edges;
          const pageInfo = fetchMoreResult.data.viewer.projects.pageInfo;
          return {
            comments: {
              edges: [...previousResult.viewer.projects.edges, ...newEdges],
              pageInfo,
            },
          };
        },
      });
    },
  }),
});

export default withData(AllProjectCards);
