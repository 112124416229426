import '../../css/SmoothingInput.less';

import React from 'react';
import makeComp from '../../util/profiler';
import SliderInput from './SliderInput';
import LegacyWBIcon from './LegacyWBIcon';
import {SmoothingType} from '../../util/math';
import {Button, Dropdown, Popup} from 'semantic-ui-react';
import classNames from 'classnames';
import PopupDropdown from '../PopupDropdown';

interface SmoothingInputProps {
  smoothingParam?: number;
  smoothingType?: SmoothingType;
  smoothingTypeDropdown?: boolean;
  smoothingTypePopupDropdown?: boolean;
  dropdownDirection?: 'left' | 'right';
  onChange(smoothingParam: number, smoothingType: SmoothingType): void;
}

const defaultSmoothingType: SmoothingType = 'exponential';

const defaultsFromSmoothingType = (smoothingType: SmoothingType) => {
  if (smoothingType === 'gaussian') {
    return {
      default: 1,
      min: 1,
      max: 100,
      step: 1,
    };
  } else if (smoothingType === 'average') {
    return {
      default: 10,
      min: 1,
      max: 100,
      step: 1,
    };
  } else {
    // exponential
    return {
      default: 0,
      min: 0,
      max: 0.999,
      step: 0.01,
    };
  }
};

const iconNameFromSmoothingType = (smoothingType: SmoothingType) => {
  return smoothingType === 'none'
    ? 'no-smooth'
    : smoothingType === 'average'
    ? 'running-average'
    : smoothingType === 'gaussian'
    ? 'gaussian'
    : 'exponential';
};

const SmoothingInput = makeComp(
  (props: SmoothingInputProps) => {
    const {smoothingType, smoothingParam} = props;

    const smoothingDefaults = defaultsFromSmoothingType(
      smoothingType ?? defaultSmoothingType
    );

    const setSmoothingType = (newSmoothingType: SmoothingType) => {
      const newSmoothingDefaults = defaultsFromSmoothingType(newSmoothingType);
      props.onChange(newSmoothingDefaults.default, newSmoothingType);
    };

    const options = [
      {
        key: 'none',
        text: 'No Smoothing',
        value: 'none',
        active: props.smoothingType === 'none',
        onClick: () => setSmoothingType('none'),
      },
      {
        key: 'exponential',
        text: 'Exponential Moving Average',
        value: 'exponential',
        active:
          props.smoothingType === 'exponential' || props.smoothingType == null,
        onClick: () => setSmoothingType('exponential'),
      },
      {
        key: 'gaussian',
        text: 'Gaussian',
        value: 'gaussian',
        active: props.smoothingType === 'gaussian',
        onClick: () => setSmoothingType('gaussian'),
      },
      {
        key: 'average',
        text: 'Running Average',
        value: 'average',
        active: props.smoothingType === 'average',
        onClick: () => setSmoothingType('average'),
      },
    ];

    const renderSmoothingTypeButton = (
      buttonSmoothingType: SmoothingType,
      helpText: string
    ) => {
      return (
        <Popup
          inverted
          size="mini"
          trigger={
            <Button
              size="small"
              icon
              className={classNames('wb-icon-button', 'only-icon', {
                'action-button--active':
                  props.smoothingType === buttonSmoothingType,
              })}
              onClick={() => {
                setSmoothingType(buttonSmoothingType);
              }}>
              <LegacyWBIcon
                name={iconNameFromSmoothingType(buttonSmoothingType)}
              />
            </Button>
          }
          content={helpText}
        />
      );
    };

    return (
      <>
        <SliderInput
          min={smoothingDefaults.min}
          max={smoothingDefaults.max}
          step={smoothingDefaults.step}
          value={smoothingParam ?? smoothingDefaults.default}
          disabled={smoothingType === 'none'}
          debounceTime={100}
          hasInput
          allowGreaterThanMax
          onChange={value =>
            props.onChange(value, smoothingType ?? defaultSmoothingType)
          }
        />
        {props.smoothingTypePopupDropdown && (
          <PopupDropdown
            trigger={
              <div className="smoothing-type-trigger">
                <Button icon>
                  <LegacyWBIcon
                    name={iconNameFromSmoothingType(
                      props.smoothingType ?? 'exponential'
                    )}
                  />
                </Button>
              </div>
            }
            options={options}
          />
        )}
        {props.smoothingTypeDropdown && (
          <Dropdown
            value={smoothingType}
            direction={props.dropdownDirection ?? 'right'}
            className="smoothing-dropdown"
            trigger={
              <div className="smoothing-type-trigger">
                <Button icon>
                  <LegacyWBIcon
                    name={iconNameFromSmoothingType(
                      props.smoothingType ?? 'exponential'
                    )}
                  />
                </Button>
              </div>
            }
            options={options}
          />
        )}
        {!props.smoothingTypeDropdown && !props.smoothingTypePopupDropdown && (
          <Button.Group size="tiny" className="smoothing-type-options">
            {renderSmoothingTypeButton('none', 'Turn off smoothing')}
            {renderSmoothingTypeButton(
              'exponential',
              "Exponential Moving Average (Tensorboard's algorithm)"
            )}
            {renderSmoothingTypeButton('gaussian', 'Gaussian smoothing')}
            {renderSmoothingTypeButton('average', 'Running average smoothing')}
          </Button.Group>
        )}
      </>
    );
  },
  {id: 'SmoothingInput'}
);

export default SmoothingInput;
