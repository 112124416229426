import React from 'react';

import makeComp from '../../util/profiler';
import * as Panel2 from './panel';
import AudioViewer from './AudioViewer';
import {LoadingGate} from './common';
import download from 'downloadjs';
import {useAssetURLFromArtifact} from './useAssetFromArtifact';

const inputType = {type: 'audio-file' as const};
type PanelAudioProps = Panel2.PanelProps<typeof inputType>;

const PanelAudio: React.FC<PanelAudioProps> = makeComp(
  props => {
    const inputNode = props.input.path;
    const assetResult = useAssetURLFromArtifact(inputNode, true);

    const downloadFile = async () => {
      if (!assetResult.directUrl) {
        console.error(`Failed to retrieve download URL for audio sample`);
        return;
      }

      const blob = await (await fetch(assetResult.directUrl)).blob();
      const pathComponents = assetResult.asset.path.split('/');

      download(blob, pathComponents[pathComponents.length - 1]);
    };

    return (
      <LoadingGate
        loading={assetResult.loading}
        makeChildren={() => {
          return (
            <AudioViewer
              audioSrc={assetResult.directUrl as string}
              caption={assetResult.asset.caption}
              height={100}
              mediaFailedToLoad={
                !assetResult.loading && assetResult.directUrl == null
              }
              downloadFile={downloadFile}
            />
          );
        }}></LoadingGate>
    );
  },
  {
    id: 'PanelAudio',
  }
);

export const Spec: Panel2.PanelSpec = {
  id: 'audio-file',
  Component: PanelAudio,
  inputType,
  displayName: 'Audio',
};
