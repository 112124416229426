import {isNil as _isNil} from 'lodash';
import React, {FC, useState} from 'react';
import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';
import {FilterEditable} from './FilterEditable';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';
import makeComp from '../../util/profiler';

interface FilterListProps {
  filters: Filter.RootFilter['filters'][0];
  index: number;
  canAdd: boolean;
  pushFilter(filter: Filter.Filter): void;
  deleteFilter(index: number): void;
  setFilter(index: number, filter: Filter.IndividualFilter): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(props: FilterValueSelectorCreatorProps): React.ReactNode;
}

const FilterList: FC<FilterListProps> = makeComp(
  ({
    filters: rootFilters,
    canAdd,
    pushFilter,
    deleteFilter,
    setFilter,
    filterKeySelector,
    filterValueSelector,
  }) => {
    const [addedFilterIndex, setAddedFilterIndex] = useState(-1);
    const filters = rootFilters.filters;

    return (
      <div className="filter-list">
        {filters.map((filter, i) => {
          // TODO: display compound filters
          if (!Filter.isIndividual(filter)) {
            return null;
          }
          return (
            <FilterEditable
              key={`filter=${i}`}
              justAdded={addedFilterIndex === i}
              filter={filter}
              setFilterDisabled={(disabled: boolean) => {
                const f: Filter.IndividualFilter =
                  filter as Filter.IndividualFilter;
                setFilter(i, {...f, disabled});
              }}
              setFilter={filt => setFilter(i, filt)}
              setFilterOp={(filterOp: Filter.IndividualOp) => {
                let f: Filter.IndividualFilter =
                  filter as Filter.IndividualFilter;
                if (Filter.isMultiValue(f)) {
                  if (!Filter.isMultiOp(filterOp)) {
                    let val = null;
                    if (f.value.length > 0) {
                      val = f.value[0];
                    }
                    f = {
                      key: f.key,
                      op: filterOp,
                      value: val,
                      disabled: f.disabled,
                    };
                  } else {
                    f = {...f, op: filterOp};
                  }
                } else {
                  if (Filter.isMultiOp(filterOp)) {
                    f = {
                      key: f.key,
                      op: filterOp,
                      disabled: f.disabled,
                      value:
                        f.value != null && f.value !== '*' ? [f.value] : [],
                    };
                  } else {
                    f = {...f, op: filterOp};
                  }
                }
                setFilter(i, f);
              }}
              setFilterValue={(filterValue: Run.Value) => {
                setFilter(i, {
                  ...filter,
                  value: filterValue,
                  disabled: _isNil(filterValue),
                } as Filter.ValueFilter & Filter.ToggleableFilter);
              }}
              setFilterMeta={(meta: any) => {
                setFilter(i, {
                  ...filter,
                  meta,
                } as Filter.ValueFilter);
              }}
              setFilterMultiValue={(filterValue: Run.Value[]) => {
                setFilter(i, {
                  ...filter,
                  value: filterValue,
                  disabled: _isNil(filterValue),
                } as Filter.MultiValueFilter & Filter.ToggleableFilter);
              }}
              deleteFilter={() => deleteFilter(i)}
              filterKeySelector={filterKeySelector}
              filterValueSelector={filterValueSelector}
            />
          );
        })}

        {canAdd && (
          <span
            className="fake-link filter__new"
            data-test="filter-add"
            // onClick={() => pushFilter(Filter.defaultNewFilter)}>
            onClick={() => {
              // pushFilter(defaultNewRunFilter);
              // TODO: check if it's ok to add this as an empty filter
              setAddedFilterIndex(filters.length);
              pushFilter({
                key: {section: 'run', name: ''},
                op: '=',
                value: '',
                disabled: true,
              });
            }}>
            + Add filter
          </span>
        )}
      </div>
    );
  },
  {id: 'FilterList', memo: true}
);

export default FilterList;
