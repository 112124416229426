import 'react-app-polyfill/ie11';
import 'es6-symbol/implement';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
// @ts-ignore
import values from 'object.values';
// @ts-ignore
import entries from 'object.entries';

// Needed for ios12 mobile safari
import 'intersection-observer';

// Polyfills
if (!Object.values) {
  values.shim();
}
if (!Object.entries) {
  entries.shim();
}
if (!Element.prototype.matches) {
  Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}

if (!(window as any).ResizeObserver) {
  (window as any).ResizeObserver = ResizeObserverPolyfill;
}
