import {WBIcon} from '@wandb/ui';
import {Input, Label} from 'semantic-ui-react';
import styled from 'styled-components';
import * as globals from '../css/globals.styles';
import {gray400, gray500, gray700} from '../css/globals.styles';

export const EllipsesMenuButton = styled(WBIcon)`
  font-size: 24px;
  margin-left: 4px;
  cursor: pointer;
  align-items: center;
  padding-right: 1px;
`;

export const ArtifactItemString = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ArtifactTypeContainer = styled.div`
  margin-top: 1em;
`;

export const ArtifactTypeBox = styled.div`
  position: sticky;
  z-index: 2;
  top: 32px;
  background-color: white;
  flex-grow: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${gray500};
  font-weight: 600;
  text-transform: uppercase;
  user-select: none;
`;

export const ArtifactsBox = styled.div`
  margin-left: 8px;
  padding-left: 4px;
  border-left: 1px solid ${gray400};
`;

export const AliasLabel = styled(Label)`
  padding: 0.2em !important;
  font-weight: 600 !important;
  font-size: 14px !important;
`;

export const ArtifactSearchBar = styled(Input)`
  position: sticky !important;
  top: -17px !important;
  padding: 10px 0;
  z-index: 5;
  background-color: white;

  width: 100% !important;

  & > input {
    border: 1px solid gray ${gray400};
    height: 34px;
  }
  & > i {
    width: 34px !important;
    line-height: 24px !important;
    font-size: 24px !important;
    color: ${globals.gray800} !important;
    opacity: 0.8 !important;
  }
` as unknown as typeof Input;

export const ArtifactsSidebarArtifactList = styled.div`
  margin-left: 9px;
  border-left: 1px solid ${gray400};
`;

export const ArtifactSidebarArtifactPaginate = styled.span`
  color: ${gray500};
`;

export const ArtifactSidebarTypePaginate = styled.span`
  color: ${gray700};
`;

export const ArtifactVersionLoad = styled.span`
  color: ${gray700};
  margin-left: 4px;
  font-size: 14px;
`;

export const ArtifactVersionContainer = styled.div`
  margin-bottom: 0;
  top: 52px;
  position: sticky;
  background-color: white;
`;
