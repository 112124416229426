import {ThunkResult} from '../../types/redux';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as Api from './api';
import * as Types from './types';

// Assumes startAction has already been dispatch
export function loadMetadataList(
  startAction: ReturnType<typeof Actions.loadMetadataListStarted>
): ThunkResult<Promise<Api.LoadMetadataListResultType>> {
  return (dispatch, getState, client) => {
    return Api.loadMetadataList(client, startAction.payload.params).then(
      views => {
        dispatch(
          ActionsInternal.loadMetadataListFinished(
            startAction.payload.id,
            views
          )
        );
        return Promise.resolve(views);
      }
    );
  };
}

export function load(cid: string): ThunkResult<Promise<Api.LoadResultType>> {
  return (dispatch, getState, client) => {
    const state = getState();
    const serverID = state.views.views[cid].id;
    const oldViewRef = state.views.views[cid].partRef;
    if (serverID == null) {
      throw new Error('Tried to load view without serverID');
    }
    dispatch(ActionsInternal.loadStarted(cid));
    return Api.load(client, serverID).then(result => {
      dispatch(ActionsInternal.loadFinished(cid, {...result, cid}, true)); // keep the current client ID
      if (oldViewRef != null) {
        dispatch(deleteObject(oldViewRef));
      }
      return Promise.resolve(result);
    });
  };
}

export function deleteObject(ref: Types.AllPartRefs): ThunkResult<void> {
  return dispatch => {
    dispatch(ActionsInternal.deleteHistoryForObject(ref));
    // TODO(someone with spare time): This is our brilliant garbage collection scheme.
    // In order to avoid having selectors crash when they use stale references to grab
    // now-deleted state, we employ the brilliant strategy of...deleting stuff on a time
    // delay. 5 seconds should be plenty to allow react to update/redraw components.
    setTimeout(() => dispatch(ActionsInternal.deleteObject(ref)), 5000);
  };
}

export function updateViewSpec(viewID: string, spec: any): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const oldPartRef = state.views.views[viewID].partRef;
    dispatch(ActionsInternal.updateViewSpec(viewID, spec));
    if (oldPartRef != null) {
      dispatch(deleteObject(oldPartRef));
    }
  };
}

// TODO(views): find a better way to callback from the delete
export function del(
  ref: Types.ViewRef,
  then?: () => void
): ThunkResult<Promise<Api.DeleteResultType>> {
  return (dispatch, getState, client) => {
    const state = getState();
    const serverID = state.views.views[ref.id].id;
    if (serverID == null) {
      throw new Error('Tried to delete view without serverID');
    }
    const res = Api.deleteView(client, serverID);
    if (then) {
      res.then(then);
    }
    return res;
  };
}

export function unloadMetadataList(metadaListID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const viewsInList = state.views.lists[metadaListID].viewIds;

    dispatch(ActionsInternal.unloadMetadataList(metadaListID));
    for (const view of viewsInList) {
      dispatch(unloadView(view));
    }
  };
}

export function unloadView(viewID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const view = state.views.views[viewID];

    if (view) {
      dispatch(ActionsInternal.unloadView(viewID));
      if (view.partRef != null) {
        dispatch(deleteObject(view.partRef));
      }
    }
  };
}

export function starView(ref: Types.ViewRef): ThunkResult<void> {
  return (dispatch, getState, client) => {
    const state = getState();
    const serverID = state.views.views[ref.id].id;
    if (serverID == null) {
      throw new Error('Tried to star view without serverID');
    }

    dispatch(ActionsInternal.starViewStarted(ref.id));
    Api.starView(client, serverID).then(starCount => {
      dispatch(ActionsInternal.starViewFinished(ref.id, starCount));
    });
  };
}

export function unstarView(ref: Types.ViewRef): ThunkResult<void> {
  return (dispatch, getState, client) => {
    const state = getState();
    const serverID = state.views.views[ref.id].id;
    if (serverID == null) {
      throw new Error('Tried to unstar view without serverID');
    }

    dispatch(ActionsInternal.unstarViewStarted(ref.id));
    Api.unstarView(client, serverID).then(starCount => {
      dispatch(ActionsInternal.unstarViewFinished(ref.id, starCount));
    });
  };
}
