import React, {FC, useCallback, useMemo, useState} from 'react';
import {Modal, Popup} from 'semantic-ui-react';
import * as globals from '../css/globals.styles';
import {CheckoutPlan, PlanInfoWithActualPlans} from '../util/pricing';
import makeComp from '../util/profiler';
import {artifactFeatures, storageFeatures} from '../util/storage';
import * as S from './CheckoutModal.styles';

type CheckoutModalContextState = {
  submitting: boolean;
};
export const CheckoutModalContext =
  React.createContext<CheckoutModalContextState | null>(null);

type CheckoutModalUpdaterContextState = {
  setSubmitting: (s: boolean) => void;
};
export const CheckoutModalUpdaterContext =
  React.createContext<CheckoutModalUpdaterContextState | null>(null);

type RenderFormParams = {
  plan: CheckoutPlan;
  seats: number;
  pricePerPeriod: string;
  onTransactionCompleted: () => void;
};

type CheckoutModalProps = {
  planInfo: PlanInfoWithActualPlans;
  defaultSeats?: number;
  onTransactionCompleted: () => void;
  onClose: () => void;
  renderForm: (p: RenderFormParams) => React.ReactNode;
  renderSuccess: () => React.ReactNode;
};

const CheckoutModal: FC<CheckoutModalProps> = makeComp(
  props => {
    const {
      planInfo,
      defaultSeats,
      onTransactionCompleted,
      onClose,
      renderForm,
      renderSuccess,
    } = props;

    const [transactionCompleted, setTransactionCompleted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const contextValue = useMemo(() => ({submitting}), [submitting]);
    const updaterContextValue = useMemo(() => ({setSubmitting}), []);

    const onCloseWhenNotSubmitting = useCallback(() => {
      if (!submitting) {
        onClose();
      }
    }, [submitting, onClose]);

    return (
      <CheckoutModalContext.Provider value={contextValue}>
        <CheckoutModalUpdaterContext.Provider value={updaterContextValue}>
          <Modal
            open
            basic
            className="checkout-modal"
            onClose={onCloseWhenNotSubmitting}>
            {!transactionCompleted ? (
              <CheckoutModalForm
                planInfo={planInfo}
                defaultSeats={defaultSeats}
                onTransactionCompleted={() => {
                  setTransactionCompleted(true);
                  onTransactionCompleted();
                }}
                renderForm={renderForm}
              />
            ) : (
              renderSuccess()
            )}
          </Modal>
        </CheckoutModalUpdaterContext.Provider>
      </CheckoutModalContext.Provider>
    );
  },
  {id: 'CheckoutModal', memo: true}
);

export default CheckoutModal;

type CheckoutModalFormProps = {
  planInfo: PlanInfoWithActualPlans;
  defaultSeats?: number;
  onTransactionCompleted: () => void;
  renderForm: (p: RenderFormParams) => React.ReactNode;
};

const CheckoutModalForm: FC<CheckoutModalFormProps> = makeComp(
  props => {
    const {
      planInfo: {title, monthlyPlan, yearlyPlan},
      defaultSeats,
      onTransactionCompleted,
      renderForm,
    } = props;

    const [selectedPlan, setSelectedPlan] = useState(yearlyPlan);
    const [seats, setSeats] = useState(defaultSeats ?? yearlyPlan.maxSeats);
    const [selectedUsagePricing, setSelectedUsagePricing] = useState(false);

    const monthlyPrice = monthlyPlan.unitPrice ?? 0;
    const yearlyPrice = yearlyPlan.unitPrice ?? 0;
    const selectedPrice =
      selectedPlan.id === monthlyPlan.id ? monthlyPrice : yearlyPrice / 12; // selectedPlan.unitPrice ?? 0;
    const pricePerPeriod = ((selectedPrice * seats) / 100).toLocaleString();

    const onInputSeatsChanged = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      e => {
        const newSeats = Math.max(Number(e.target.value), 1);
        setSeats(newSeats);
      },
      [setSeats]
    );

    return (
      <S.Container>
        <S.MainContainer>
          <S.Header>
            <S.Title>{title}</S.Title>
          </S.Header>
          <S.Subtitle>
            {selectedPlan.id === monthlyPlan.id ? 'Monthly' : 'Annual'}{' '}
            Subscription
          </S.Subtitle>
          <S.SeatsPicker>
            <S.SeatsInput
              data-test="choose-seats-input"
              type="number"
              value={seats}
              onChange={onInputSeatsChanged}
            />
            {` seats  •  $${selectedPrice / 100}/mo = $${
              (selectedPrice / 100) * seats * 12
            }/yearly
            `}
          </S.SeatsPicker>
          <S.PeriodPickerLink
            data-test="period-picker"
            onClick={() =>
              setSelectedPlan(
                selectedPlan.id === monthlyPlan.id ? yearlyPlan : monthlyPlan
              )
            }>
            {selectedPlan.id === monthlyPlan.id
              ? 'Switch to annual, save 22%'
              : 'Switch to monthly'}
          </S.PeriodPickerLink>
          <div className="checkout-summary">
            {renderForm({
              plan: selectedPlan as CheckoutPlan,
              seats,
              pricePerPeriod,
              onTransactionCompleted,
            })}
          </div>
        </S.MainContainer>

        <S.SideContainer>
          {!selectedUsagePricing && (
            <>
              <S.SideHeader>
                <S.Title>Included in your plan</S.Title>
              </S.SideHeader>
              <S.PlanList>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> SaaS hosting in our secure,
                  SOC2-compliant cloud
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> Unlimited W&B runs to track
                  machine learning experiments
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> 100 GB of storage & artifacts,
                  then{' '}
                  <S.UsagePricingLink
                    onClick={() => setSelectedUsagePricing(true)}>
                    usage pricing
                  </S.UsagePricingLink>
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> Public and private projects
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> 5000 cumulative compute hours for
                  experiment tracking
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> Hyperparameter optimization tools
                </S.PlanItem>
                <S.PlanItem>
                  <S.CheckIcon name="check" /> Email and chat support
                </S.PlanItem>
              </S.PlanList>
            </>
          )}
          {selectedUsagePricing && (
            <>
              <S.SideHeader>
                <S.SideTitle>
                  <S.BackButton
                    size="tiny"
                    className="wb-icon-button only-icon"
                    onClick={() => setSelectedUsagePricing(false)}>
                    <S.BackIcon name="previous" />
                  </S.BackButton>{' '}
                  Usage Pricing
                </S.SideTitle>

                <S.PricingTableDescription>
                  Storage and artifact usage is billed monthly, including in
                  annual plans
                </S.PricingTableDescription>

                <S.PricingTable>
                  <S.PricingTableRow>
                    <S.PricingTableHeader>
                      <Popup
                        content={
                          <React.Fragment>
                            <div style={{color: globals.white}}>
                              Files hosted on W&B servers, including logged
                              media, model checkpoints, and uploaded datasets
                            </div>
                          </React.Fragment>
                        }
                        trigger={<div>Storage</div>}
                        position="top left"
                        inverted
                        size="mini"
                      />
                    </S.PricingTableHeader>
                  </S.PricingTableRow>
                  <S.PricingTableRow>
                    <S.PricingTableBody>
                      {storageFeatures.map((tf, i) => (
                        <S.PricingTableFeatureRow key={i}>
                          {tf}
                        </S.PricingTableFeatureRow>
                      ))}
                    </S.PricingTableBody>
                  </S.PricingTableRow>
                  <S.PricingTableRow>
                    <S.PricingTableHeader>
                      <Popup
                        content={
                          <React.Fragment>
                            <div style={{color: globals.white}}>
                              Files tracked by Artifacts, including files stored
                              on external servers such as Google Cloud or an AWS
                              S3 bucket
                            </div>
                          </React.Fragment>
                        }
                        trigger={<div>Artifact tracking</div>}
                        position="top left"
                        inverted
                        size="mini"
                      />
                    </S.PricingTableHeader>
                  </S.PricingTableRow>
                  <S.PricingTableRow>
                    <S.PricingTableBody>
                      {artifactFeatures.map((tf, i) => (
                        <S.PricingTableFeatureRow key={i}>
                          {tf}
                        </S.PricingTableFeatureRow>
                      ))}
                    </S.PricingTableBody>
                  </S.PricingTableRow>
                </S.PricingTable>
              </S.SideHeader>
            </>
          )}

          <S.PlanListFooter>
            Reach out to learn more or customize your plan
          </S.PlanListFooter>

          <S.ContactButton
            className="action-button"
            onClick={() => {
              window.analytics.track('Standard plan contact us clicked');
              window.open('https://wandb.ai/site/contact');
            }}>
            Contact sales
          </S.ContactButton>
        </S.SideContainer>
      </S.Container>
    );
  },
  {id: 'CheckoutModalForm', memo: true}
);
