import {useEffect, useRef} from 'react';

export * from './shared';

export function setDocumentTitle(title: string, appendWB = true): void {
  if (appendWB) {
    title += ' – Weights & Biases';
  }
  document.title = title;
}

export function setDocumentDescription(desc: string): void {
  let metaEl: HTMLMetaElement | null = document.querySelector(
    `meta[name="description"]`
  );
  if (metaEl == null) {
    metaEl = document.createElement('meta');
    metaEl.name = 'description';
    document.head.append(metaEl);
  }
  metaEl.content = desc;
}

export function useScrollToURLHash(delayMS = 1000): void {
  const hashId = window.location.hash.slice(1);
  const hashIdRef = useRef(hashId);
  useEffect(() => {
    if (hashIdRef.current === '') {
      return;
    }
    const hashElement = document.getElementById(hashIdRef.current);
    if (hashElement) {
      setTimeout(() => {
        hashElement.scrollIntoView({behavior: 'smooth'});
      }, delayMS);
      hashIdRef.current = '';
    }
  });
}

export const scrollToElement = (elementSelector: string) => {
  const el = document.querySelector(elementSelector);
  if (el != null) {
    el.scrollIntoView({
      inline: 'center',
      behavior: 'smooth',
    });
  }
};

export function openJSONNewTab(json: any, title: string, target: string): void {
  // eslint-disable-next-line wandb/no-unprefixed-urls
  const newWindow = window.open('', target);
  if (
    typeof newWindow?.document?.write === 'function' &&
    typeof newWindow?.document?.close === 'function' &&
    typeof newWindow?.focus === 'function'
  ) {
    newWindow.document.write(
      `<head><title>${title}</title></head><pre>${JSON.stringify(
        json,
        null,
        4
      )}</pre>`
    );
    newWindow.document.close();
    newWindow.focus();
  }
}
