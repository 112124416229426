import * as S from './ReportSection.styles';

import React, {useMemo, useState} from 'react';
import {Button, Popup} from 'semantic-ui-react';
import * as RunsHooks from '../state/runs/hooks';
import * as ViewHooks from '../state/views/hooks';
import * as SectionActions from '../state/views/section/actions';
import {makePropsAreEqual} from '../util/shouldUpdate';
import {RunSets} from './RunSets';

import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as PanelBankSectionConfigActions from '../state/views/panelBankSectionConfig/actions';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as SectionViewTypes from '../state/views/section/types';
import PanelBankGridSectionFunc from './PanelBankGridSection';
import {PanelWithComments} from './PanelBankSection';
import Measure from 'react-measure';
import {GRID_ROW_HEIGHT, GRID_ITEM_MARGIN} from '../util/panelbankGrid';
import * as ReportsTypes from '../state/reports/types';
import CreateReportModal from './CreateReportModal';
import {getSectionsWithVisiblePanels} from '../util/panelbank';
import makeComp from '../util/profiler';
import classNames from 'classnames';
import {AddVisNewPanelEditor} from './AddVisNewPanelEditor';
import {useSelector} from '../state/hooks';
import * as ReportViewsSelectors from '../state/reports/selectors';
import * as PanelViewTypes from '../state/views/panel/types';
import AddVisMenu from './AddVisMenu';
import ImportPanelMenu from './ImportPanelMenu';

interface ReportSectionProps {
  className?: string;
  entityName: string;
  projectName: string;
  panelSettingsRef: PanelSettingsViewTypes.Ref;
  sectionRef: SectionViewTypes.Ref;
  viewRef: ReportsTypes.ReportViewRef;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  onRunSetHeightChange?(): void;
}

type AllReportSectionProps = ReportSectionProps &
  ReturnType<typeof useReportSectionProps>;

export const ReportSectionComp: React.FC<AllReportSectionProps> = makeComp(
  ({
    className,
    entityName,
    projectName,
    panelSettingsRef,
    sectionRef,
    viewRef,
    readOnly,
    disableRunLinks,
    activePanelRefs,
    deletePanel,
    duplicatePanel,
    sectionPart,
    onRunSetHeightChange,
  }: AllReportSectionProps) => {
    const [panelBankWidth, setPanelBankWidth] = useState(0);
    const movePanel = ViewHooks.useViewAction(
      sectionPart.panelBankConfigRef,
      PanelBankConfigActions.movePanel
    );
    // make sure this is well memoized or the panel components will rerender
    // every update
    const panelSettings = ViewHooks.useWhole(panelSettingsRef);

    const useExportableSections = () => {
      const panelBankSectionConfig = ViewHooks.useWhole(
        sectionPart.panelBankSectionConfigRef
      );
      return useMemo(
        () => getSectionsWithVisiblePanels([panelBankSectionConfig]),
        [panelBankSectionConfig]
      );
    };

    const [newPanel, setNewPanel] = useState<PanelViewTypes.Panel>();
    const [noEdit, setNoEdit] = useState<boolean | undefined>();
    const [openAddVis, setOpenAddVis] = useState(false); // Logic for opening AddVisMenu
    const [openImportChart, setOpenImportChart] = useState(false);
    const addPanel = ViewHooks.useViewAction(
      sectionPart.panelBankSectionConfigRef,
      PanelBankSectionConfigActions.addPanel
    );
    const reportWidth = useSelector(
      ReportViewsSelectors.getReportWidth(viewRef)
    );

    // Make a history keys query so the state is in redux.
    // This allows query merging for panels in the section.
    const keyInfoQuery = RunsHooks.useKeyInfoQuery(sectionPart.runSetRefs);
    if (keyInfoQuery.error) {
      return (
        <div className="report-section">
          <S.EmptyReportSection>
            This set of panels contains runs from a private project, which
            cannot be shown in this report
          </S.EmptyReportSection>
        </div>
      );
    }

    return (
      <div className={classNames('report-section', 'panel-bank', className)}>
        <Measure
          bounds
          onResize={contentRect => {
            setPanelBankWidth(
              contentRect.bounds ? contentRect.bounds.width : 0
            );
          }}>
          {({measureRef}) => (
            <>
              <div className="grid-section-wrapper">
                <div ref={measureRef}>
                  {panelBankWidth > 0 && (
                    <PanelBankGridSectionFunc
                      readOnly={readOnly}
                      panelBankSectionConfigRef={
                        sectionPart.panelBankSectionConfigRef
                      }
                      panelBankWidth={panelBankWidth}
                      gridItemMargin={[-1, -1]}
                      gridRowHeight={GRID_ROW_HEIGHT + GRID_ITEM_MARGIN[1]}
                      gridContainerPadding={[0, 0]}
                      showGridDots={!readOnly}
                      hideEmptyWatermark
                      onClickGrid={() => {
                        if (!openAddVis) {
                          // HAX: we need the setTimeout to ensure this runs
                          // after the add vis Popup's onClose handler
                          setTimeout(() => setOpenAddVis(true));
                        }
                      }}
                      // TODO: re-enable this??
                      // singleRun={singleRun}
                      activePanelRefs={activePanelRefs}
                      inactivePanelRefs={[]}
                      movePanelBetweenSections={movePanel}
                      renderPanel={(panelRef, onContentHeightChange) => {
                        return (
                          <PanelWithComments
                            readOnly={readOnly}
                            disableRunLinks={disableRunLinks}
                            panelRef={panelRef}
                            panelBankSectionConfigRef={
                              sectionPart.panelBankSectionConfigRef
                            }
                            runSetRefs={sectionPart.runSetRefs}
                            historyKeyInfo={
                              !keyInfoQuery.loading &&
                              keyInfoQuery.error == null
                                ? keyInfoQuery.historyKeyInfo
                                : undefined
                            }
                            customRunColorsRef={sectionPart.customRunColorsRef}
                            panelSettings={panelSettings}
                            loading={keyInfoQuery.loading}
                            // searchQuery={searchQuery}
                            deletePanel={() => deletePanel(panelRef)}
                            duplicatePanel={() => duplicatePanel(panelRef)}
                            onContentHeightChange={onContentHeightChange}
                          />
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              {!readOnly && (
                <>
                  <S.PanelButtonWrapper>
                    <Popup
                      basic
                      className="wb-add-vis-menu"
                      on="click"
                      position="top center"
                      open={openImportChart}
                      onOpen={() => setOpenImportChart(true)}
                      onClose={() => setOpenImportChart(false)}
                      trigger={
                        <Button
                          data-test="add-vis"
                          className="add-panel-button">
                          Import panel
                        </Button>
                      }
                      popperModifiers={{
                        preventOverflow: {enabled: false},
                        flip: {enabled: false},
                      }}>
                      <ImportPanelMenu
                        entityName={entityName}
                        projectName={projectName}
                        sectionPart={sectionPart}
                        viewRefID={viewRef.id}
                      />
                    </Popup>
                    <Popup
                      basic
                      className="wb-add-vis-menu"
                      on="click"
                      position="top center"
                      open={openAddVis}
                      onOpen={() => setOpenAddVis(true)}
                      onClose={() => {
                        if (openAddVis) {
                          setOpenAddVis(false);
                        }
                      }}
                      trigger={
                        <Button
                          data-test="add-vis"
                          className="add-panel-button">
                          Add panel
                        </Button>
                      }
                      popperModifiers={{
                        preventOverflow: {enabled: false},
                        flip: {enabled: false},
                      }}>
                      <AddVisMenu
                        entityName={entityName}
                        runSetRefs={sectionPart.runSetRefs}
                        onSelect={(panel, skipEdit) => {
                          setOpenAddVis(false);
                          setNewPanel(panel);
                          setNoEdit(skipEdit);
                        }}
                      />
                    </Popup>
                  </S.PanelButtonWrapper>
                  {newPanel && (
                    <AddVisNewPanelEditor
                      panelBankSectionConfigRef={
                        sectionPart.panelBankSectionConfigRef
                      }
                      currentHeight={400}
                      editable
                      customRunColorsRef={sectionPart.customRunColorsRef}
                      runSetRefs={sectionPart.runSetRefs}
                      panel={newPanel}
                      panelSettings={panelSettings}
                      noEdit={noEdit}
                      onCancel={() => {
                        setNewPanel(undefined);
                      }}
                      onOK={panelRef => {
                        setNewPanel(undefined);
                        addPanel(panelRef, reportWidth === 'readable');
                      }}
                    />
                  )}
                </>
              )}
              {!(readOnly && sectionPart.hideRunSets) && (
                <RunSets
                  entityName={entityName}
                  projectName={projectName}
                  sectionRef={sectionRef}
                  readOnly={readOnly}
                  disableRunLinks={disableRunLinks}
                  onHeightChange={onRunSetHeightChange}
                />
              )}
            </>
          )}
        </Measure>
        <CreateReportModal
          useExportableSections={useExportableSections}
          panelSettingsRef={sectionPart.panelSettingsRef}
          runSetRefs={sectionPart.runSetRefs}
          customRunColorsRef={sectionPart.customRunColorsRef}
        />
      </div>
    );
  },
  {
    id: 'ReportSectionComp',
    memo: makePropsAreEqual({
      name: 'ReportSection',
      deep: ['config', 'customRunColors', 'panelSettings', 'queries'],
      ignoreFunctions: false,
      debug: false,
      verbose: true,
    }),
  }
);

function useReportSectionProps(props: ReportSectionProps) {
  const {sectionRef} = props;
  const sectionPart = ViewHooks.usePart(sectionRef);
  const setOpen = ViewHooks.useViewAction(sectionRef, SectionActions.setOpen);
  const setName = ViewHooks.useViewAction(sectionRef, SectionActions.setName);
  if (sectionPart.runSetRefs.length === 0) {
    // There's always at least one runset
    throw new Error('invalid state');
  }
  // TODO: Filter histograms if multi-run?
  const activePanelRefs = ViewHooks.usePart(
    sectionPart.panelBankSectionConfigRef
  ).panelRefs;
  const deletePanel = ViewHooks.useViewAction(
    sectionPart.panelBankSectionConfigRef,
    PanelBankSectionConfigActions.deletePanel
  );
  const duplicatePanel = ViewHooks.useViewAction(
    sectionPart.panelBankSectionConfigRef,
    PanelBankSectionConfigActions.duplicatePanel
  );

  return {
    activePanelRefs,
    sectionPart,
    setOpen,
    setName,
    deletePanel,
    duplicatePanel,
  };
}

export const ReportSection = makeComp(
  (props: ReportSectionProps) => {
    const selectedProps = useReportSectionProps(props);
    return <ReportSectionComp {...props} {...selectedProps} />;
  },
  {id: 'ReportSection'}
);
