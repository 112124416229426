import * as S from './ExampleShowcase.styles';

import React, {useState} from 'react';
import classNames from 'classnames';
import {Highlight, Python} from './Code';
import {Dropdown} from 'semantic-ui-react';

interface Example {
  label: string;
  testLabel: string;
  getCode: (props: ExampleShowcaseProps) => string;
  docLink: string;
  fullExampleLink: string;
}

const EXAMPLES: Example[] = [
  {
    label: 'Any Framework',
    testLabel: 'any-framework',
    getCode: ({entityName, projectName}) => `
# Flexible integration for any Python script
import wandb

# 1. Start a W&B run
wandb.init(project='${projectName ?? 'gpt3'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# 2. Save model inputs and hyperparameters
config = wandb.config
config.learning_rate = 0.01

# Model training here

# 3. Log metrics over time to visualize performance
wandb.log({"loss": loss})
    `,
    docLink: 'https://docs.wandb.ai/library/log',
    fullExampleLink:
      'https://colab.research.google.com/drive/1aEv8Haa3ppfClcCiC2TB8WLHB4jnY_Ds',
  },
  {
    label: 'PyTorch',
    testLabel: 'pytorch',
    getCode: ({entityName, projectName}) => `
import wandb

# 1. Start a new run
wandb.init(project='${projectName ?? 'gpt-3'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# 2. Save model inputs and hyperparameters
config = wandb.config
config.learning_rate = 0.01

# 3. Log gradients and model parameters
wandb.watch(model)
for batch_idx, (data, target) in enumerate(train_loader):
  ...
  if batch_idx % args.log_interval == 0:
    # 4. Log metrics to visualize performance
    wandb.log({"loss": loss})
  `,
    docLink: 'https://docs.wandb.ai/integrations/pytorch',
    fullExampleLink:
      'https://colab.research.google.com/drive/1QTIK23LBuAkdejbrvdP5hwBGyYlyEJpT?usp=sharing',
  },
  {
    label: 'Keras',
    testLabel: 'keras',
    getCode: ({entityName, projectName}) => `
import wandb
from wandb.keras import WandbCallback

# 1. Start a new run
wandb.init(project='${projectName ?? 'gpt-3'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# 2. Save model inputs and hyperparameters
config = wandb.config
config.learning_rate = 0.01

... Define a model

# 3. Log layer dimensions and metrics over time
model.fit(X_train, y_train, validation_data=(X_test, y_test),
callbacks=[WandbCallback()])
  `,
    docLink: 'https://docs.wandb.ai/integrations/keras',
    fullExampleLink:
      'https://colab.research.google.com/drive/1WZI9C9l8-mzTSNS3mXS2zKzdz7N38jYO?usp=sharing',
  },
  {
    label: 'TensorFlow',
    testLabel: 'tensorflow',
    getCode: ({entityName, projectName}) => `
import wandb

# 1. Start a W&B run
wandb.init(project='${projectName ?? 'gpt-3'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# 2. Save model inputs and hyperparameters
config = wandb.config
config.learning_rate = 0.01

# Model training here

# 3. Log metrics over time to visualize performance
with tf.Session() as sess:
  # ...
  wandb.tensorflow.log(tf.summary.merge_all())
  `,
    docLink: 'https://docs.wandb.ai/integrations/tensorflow',
    fullExampleLink:
      'https://colab.research.google.com/drive/126c1k5IfbQpE7dVmhnoDTdmFfC7CgJqg?usp=sharing',
  },
  {
    label: 'Scikit',
    testLabel: 'scikit',
    getCode: ({entityName, projectName}) => `
import wandb
wandb.init(project='${projectName ?? 'visualize-sklearn'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# Model training here

# Log classifier visualizations
wandb.sklearn.plot_classifier(clf, X_train, X_test, y_train, y_test, y_pred, y_probas, labels, model_name='SVC', feature_names=None)

# Log regression visualizations
wandb.sklearn.plot_regressor(reg, X_train, X_test, y_train, y_test,  model_name='Ridge')

# Log clustering visualizations
wandb.sklearn.plot_clusterer(kmeans, X_train, cluster_labels, labels=None, model_name='KMeans')
  `,
    docLink: 'https://docs.wandb.ai/integrations/scikit',
    fullExampleLink:
      'https://colab.research.google.com/drive/1dxWV5uulLOQvMoBBaJy2dZ3ZONr4Mqlo?usp=sharing',
  },
  {
    label: 'Hugging Face',
    testLabel: 'hugging-face',
    getCode: ({entityName, projectName}) => `
# 1. Install the wandb library
pip install wandb

# 2. Run a script with the Trainer, which automatically logs losses, evaluation metrics, model topology and gradients
python run_glue.py \\
 --model_name_or_path bert-base-uncased \\
 --task_name MRPC \\
 --data_dir $GLUE_DIR/$TASK_NAME \\
 --do_train \\
 --evaluate_during_training \\
 --max_seq_length 128 \\
 --per_gpu_train_batch_size 32 \\
 --learning_rate 2e-5 \\
 --num_train_epochs 3 \\
 --output_dir /tmp/$TASK_NAME/ \\
 --overwrite_output_dir \\
 --logging_steps 50
    `,
    docLink: 'https://docs.wandb.ai/library/integrations/huggingface',
    fullExampleLink:
      'https://colab.research.google.com/drive/1NEiqNPhiouu2pPwDAVeFoN4-vTYMz9F8?usp=sharing',
  },
  {
    label: 'XGBoost',
    testLabel: 'xgboost',
    getCode: ({entityName, projectName}) => `
import wandb

# 1. Start a new run
wandb.init(project='${projectName ?? 'visualize-models'}'${
      entityName ? `, entity='${entityName}'` : ''
    }, name='xgboost')

# 2. Add the callback
bst = xgboost.train(param, xg_train, num_round, watchlist, callbacks=[wandb.xgboost.wandb_callback()])

# Get predictions
pred = bst.predict(xg_test)
  `,
    docLink: 'https://docs.wandb.ai/integrations/xgboost',
    fullExampleLink:
      'https://colab.research.google.com/drive/1aJf2DEobaXCcdv-Ys4sV53bEgkh6_auL?usp=sharing',
  },
  {
    label: 'LightGBM',
    testLabel: 'lightgbm',
    getCode: ({entityName, projectName}) => `
import wandb
import numpy as np
import xgboost as xgb

# 1. Start a W&B run
wandb.init(project='${projectName ?? 'visualize-models'}'${
      entityName ? `, entity='${entityName}'` : ''
    }, name='xgboost')

# 2. Add the wandb callback
bst = gbm = lgb.train(params,
               lgb_train,
               num_boost_round=20,
               valid_sets=lgb_eval,
               valid_names=('validation'),
               callbacks=[wandb.lightgbm.callback()])

# Get prediction
pred = bst.predict(xg_test)
  `,
    docLink: 'https://docs.wandb.ai/integrations/lightgbm',
    fullExampleLink:
      'https://colab.research.google.com/drive/1ybowtxi9LkApZEIXryhRrrhbvDrUsFy4?usp=sharing',
  },
  {
    label: 'Fastai',
    testLabel: 'fastai',
    getCode: ({entityName, projectName}) => `
import wandb
fastai2.callback.wandb import WandbCallback

# 1. Start a new run
wandb.init(project='${projectName ?? 'gpt-3'}'${
      entityName ? `, entity='${entityName}'` : ''
    })

# 2. Automatically log model metrics
learn.fit(..., cbs=WandbCallback())
  `,
    docLink: 'https://docs.wandb.ai/integrations/fastai',
    fullExampleLink:
      'https://colab.research.google.com/drive/1IWrhwcJoncCKHm6VXsNwOr9Yukhz3B49?usp=sharing',
  },
];

type ExampleShowcaseProps = {
  entityName?: string;
  projectName?: string;
  blankProject?: boolean;
};

const ExampleShowcase: React.FC<ExampleShowcaseProps> = React.memo(props => {
  const [activeExampleLabel, setActiveExampleLabel] = useState(
    EXAMPLES[0].label
  );
  const activeExample = EXAMPLES.find(e => e.label === activeExampleLabel);

  const dropdownOpts = EXAMPLES.map(e => ({text: e.label, value: e.label}));

  return (
    <S.Container>
      <S.Picker>
        {EXAMPLES.map(({label, testLabel}) => (
          <S.Choice
            data-test={`example-choice-${testLabel}`}
            key={label}
            className={classNames({active: label === activeExampleLabel})}
            onClick={() => setActiveExampleLabel(label)}>
            {label}
          </S.Choice>
        ))}
      </S.Picker>
      <S.MobilePicker>
        <Dropdown
          options={dropdownOpts}
          onChange={(e, {value}) => setActiveExampleLabel(value as string)}
          value={activeExample!.label}
        />
      </S.MobilePicker>
      {activeExample && (
        <S.Example blankProject={props.blankProject}>
          <S.Code>
            <Python
              style={{
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}>
              <Highlight>{activeExample.getCode(props)}</Highlight>
            </Python>
          </S.Code>
          <S.Links>
            <S.Link
              href={activeExample.docLink}
              target="_blank"
              rel="noopener noreferrer">
              Documentation <S.LinkArrow name="arrow right" />
            </S.Link>
            <S.Link
              href={activeExample.fullExampleLink}
              target="_blank"
              rel="noopener noreferrer">
              See a full example <S.LinkArrow name="arrow right" />
            </S.Link>
          </S.Links>
        </S.Example>
      )}
    </S.Container>
  );
});

export default ExampleShowcase;
