import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import * as Urls from '../util/urls';
import {ViewDraft} from '../state/views/graphql';
import {useViewDraftsQuery} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import * as ViewApi from '../state/views/api';
import {useApolloClient} from '../state/hooks';
import makeComp from '../util/profiler';
import {isOnGalleryDiscussionView} from '../util/url';

const DEFAULT_MAIN_TEXT =
  'You have an existing draft edit of this report. Would you like to continue working on it?';

const DEFAULT_DISCARD_TEXT = 'Discard and start fresh';

export interface DraftWarningModalProps {
  parentViewID: string;
  mainText?: string;
  discardText?: string;
  flipButtonColors?: boolean;
  renderResumeButton?(
    draftID: string,
    resuming: boolean,
    discarding: boolean,
    setResuming: (r: boolean) => void
  ): ReactNode;
  onNoDraftFound(): void;
  onDiscard(): void;
  onClose(): void;
}

const DraftWarningModal: React.FC<DraftWarningModalProps> = makeComp(
  ({
    parentViewID,
    mainText,
    discardText,
    renderResumeButton,
    flipButtonColors = false,
    onNoDraftFound,
    onClose,
    onDiscard,
  }) => {
    const client = useApolloClient();
    const viewer = useViewer();
    const {loading, data} = useViewDraftsQuery({
      variables: {viewID: parentViewID},
      fetchPolicy: 'network-only',
    });

    const draft = useMemo(() => {
      if (data == null || viewer == null) {
        return null;
      }
      return (data.view?.children?.edges.find(
        e => e.node?.user?.id === viewer.id
      )?.node ?? null) as ViewDraft | null;
    }, [data, viewer]);

    useEffect(() => {
      if (loading) {
        return;
      }
      if (draft == null) {
        onNoDraftFound();
      }
      // eslint-disable-next-line
    }, [loading]);

    const [resuming, setResuming] = useState(false);
    const [discarding, setDiscarding] = useState(false);

    const onResume = useCallback(() => setResuming(true), []);

    const onDiscardDraft = useCallback(async () => {
      if (draft == null) {
        return;
      }
      setDiscarding(true);
      await ViewApi.deleteView(client, draft.id);
      onDiscard();
    }, [client, draft, onDiscard]);

    if (draft == null) {
      return null;
    }

    return (
      <Modal size="mini" open onClose={onClose}>
        <Modal.Content>
          <p>{mainText || DEFAULT_MAIN_TEXT}</p>
          {renderResumeButton?.(
            draft.id,
            resuming,
            discarding,
            setResuming
          ) ?? (
            <Link
              style={{display: 'contents'}}
              to={
                draft.project != null
                  ? Urls.reportEdit({
                      entityName: draft.project.entityName,
                      projectName: draft.project.name,
                      reportID: draft.id,
                      reportName: draft.displayName,
                    })
                  : (isOnGalleryDiscussionView()
                      ? Urls.galleryDiscussionEdit
                      : Urls.galleryPostEdit)({
                      entityName: draft.entityName,
                      reportID: draft.id,
                      reportName: draft.displayName,
                    })
              }>
              <Button
                primary={!flipButtonColors}
                size="tiny"
                loading={resuming}
                disabled={resuming || discarding}
                onClick={onResume}>
                Resume
              </Button>
              <span />
            </Link>
          )}
          <Button
            primary={flipButtonColors}
            size="tiny"
            loading={discarding}
            disabled={resuming || discarding}
            onClick={onDiscardDraft}>
            {discardText || DEFAULT_DISCARD_TEXT}
          </Button>
        </Modal.Content>
      </Modal>
    );
  },
  {id: 'DraftWarningModal', memo: true}
);

export default DraftWarningModal;
