import produce from 'immer';
import update from 'immutability-helper';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {toIncludesObj} from '@wandb/cg/browser/utils/obj';
import {WBTableColumn} from '../components/WBTable/WBTable';
import * as RunHelpers from '../util/runhelpers';
import * as RunFeed from './runfeed';
import * as Run from './runs';

export const DEFAULT_RUNSELECTOR_KEYS: Run.Key[] = [
  {section: 'run', name: 'name'},
  {section: 'run', name: 'displayName'},
  {section: 'run', name: 'state'},
  {section: 'run', name: 'notes'},
  {section: 'run', name: 'username'},
  {section: 'run', name: 'group'},
  {section: 'run', name: 'jobType'},
  {section: 'tags', name: '__ALL__'},
  {section: 'run', name: 'createdAt'},
  {section: 'run', name: 'updatedAt'},
  {section: 'run', name: 'heartbeatAt'},
  {section: 'run', name: 'duration'},
  {section: 'run', name: 'sweep'},
  {section: 'run', name: 'host'},
  {section: 'run', name: 'description'},
  {section: 'run', name: 'commit'},
  {section: 'run', name: 'github'},
  {section: 'run', name: 'runInfo.gpuCount'},
  {section: 'run', name: 'runInfo.gpu'},
];
export const DEFAULT_SWEEP_KEYS: Run.Key[] = [
  DEFAULT_RUNSELECTOR_KEYS[0],
  DEFAULT_RUNSELECTOR_KEYS[1],
  {section: 'run', name: 'agent'},
  ...DEFAULT_RUNSELECTOR_KEYS.slice(2),
];
export const DEFAULT_SWEEP_EARLY_TERMINATE_KEYS: Run.Key[] = [
  DEFAULT_RUNSELECTOR_KEYS[0],
  DEFAULT_RUNSELECTOR_KEYS[1],
  {section: 'run', name: 'agent'},
  {section: 'run', name: 'stopped'},
  ...DEFAULT_RUNSELECTOR_KEYS.slice(2),
];

export function filterLegacyKeys(keys: Run.Key[]): Run.Key[] {
  return keys.filter(
    k =>
      !(
        k.name === '_runtime' ||
        k.name === '_step' ||
        k.name === '_timestamp' ||
        (k.section === 'tags' && k.name !== '__ALL__')
      )
  );
}

export const COLUMN_LIMIT = 500;

// avoid _.unionWith() because it's O(n^2)
export function unionKeys(baseKeys: Run.Key[], keys: Run.Key[]): Run.Key[] {
  return baseKeys.concat(
    keys.filter(key => key.section !== 'run' && key.section !== 'tags')
  );
}

export function cleanRunFeedConfig(
  conf: RunFeed.ConfigV0,
  keys: Run.Key[],
  baseKeys: Run.Key[],
  hideNewKey?: boolean
): RunFeed.Config {
  const serverConf = _.cloneDeep(conf);

  let newConf: RunFeed.Config;
  if (serverConf.pinnedColumnKeys || serverConf.configColsAuto != null) {
    const v1 = upgradeV0(serverConf, keys, baseKeys);
    newConf = upgradeV1(v1, keys, baseKeys);
  } else if (serverConf.version === undefined) {
    const v1 = serverConf as RunFeed.ConfigV1;
    newConf = upgradeV1(v1, keys, baseKeys);
  } else {
    newConf = serverConf as RunFeed.Config;
  }

  // conf.columnOrder used to be a Run.Key[], now it's a string (column.accessor)
  const firstColumn = newConf.columnOrder && newConf.columnOrder[0];
  if (firstColumn && firstColumn.hasOwnProperty('section')) {
    newConf.columnOrder = newConf.columnOrder.map((k: unknown) =>
      Run.keyToString(k as Run.Key)
    );
  }

  newConf = enforcePinnedColumnOrdering(newConf);

  const allKeys = unionKeys(baseKeys, keys);

  return initializeTableSettings(
    newConf,
    allKeys.map(key => keyToColumn(key)),
    hideNewKey
  );
}

// All pinned columns must come before unpinned columns.
// 'run:displayName' is a special case; it must always be the first column if it is pinned.
function enforcePinnedColumnOrdering(conf: RunFeed.Config): RunFeed.Config {
  return produce(conf, draft => {
    const {columnOrder, columnPinned} = draft;
    draft.columnOrder = _.sortBy(columnOrder, accessor => {
      if (columnPinned[accessor]) {
        if (accessor === 'run:displayName') {
          return 0;
        }
        return 1;
      }
      return 2;
    });
  });
}

export function maybeTruncateTableSettings(
  conf: RunFeed.Config
): RunFeed.Config {
  // This is sometimes called JSON blobs typed as `any`, so conf might
  // still be an old style config :(
  // Most of this will get refactored properly when the views branch
  // goes in though.
  if (!conf.columnOrder || !conf.columnVisible || !conf.columnPinned) {
    return conf;
  }

  if (
    conf.columnOrder.length <= COLUMN_LIMIT &&
    Object.keys(conf.columnVisible).length <= COLUMN_LIMIT &&
    Object.keys(conf.columnPinned).length <= COLUMN_LIMIT
  ) {
    return conf;
  }

  // Truncate everything to COLUMN_LIMIT so we don't send insanely huge JSON blobs to mysql.
  const newConf = _.clone(conf);
  newConf.columnOrder = newConf.columnOrder.slice(0, COLUMN_LIMIT);
  const truncColumnOrderSet = new Set<string>(newConf.columnOrder);

  // Filter down columnVisible to only those columns in the newly truncated columnOrder.
  const filterObjByKeys = <T>(
    obj: {[key: string]: T},
    filterSet: Set<string>
  ) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (filterSet.has(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {} as {[key: string]: T});
  };

  // Weird stuff ahead. columnVisible should contain one key for every column when
  // the overall number of columns is < 500 because that's how we figure out which
  // columns are "new" and should be displayed by default. If a new column comes in
  // and it's not in columnVisible (as either true or false), we display it because it
  // might be interesting.
  // When colummns > 500, all bets are off. In that case, we just want to know if we've
  // "previously seen" 500 or more columns, and if we have, we don't add new columns to the
  // visible set. So down here, we take care to fill columnVisible up to the limit. We first
  // add the visible columns, and then any previously hidden columns up to the limit. By
  // keeping columnVisible full once we've seen > 500 columns, we know in initializeTableSettings
  // not to show any more new columns (even if columnOrder.length < 500).
  const newColumnVisible: {[key: string]: boolean} = {};
  for (const visibleKey of newConf.columnOrder) {
    newColumnVisible[visibleKey] = true;
  }
  const invisibleKeys = Object.keys(newConf.columnVisible).filter(
    c => !truncColumnOrderSet.has(c)
  );
  for (const invisibleKey of invisibleKeys) {
    if (Object.keys(newColumnVisible).length > COLUMN_LIMIT) {
      break;
    }

    newColumnVisible[invisibleKey] = false;
  }
  newConf.columnVisible = newColumnVisible;

  // ColumnPinned is treated as a set by all callers, so remove all elements that = false.
  const pinnedColumns = Object.keys(newConf.columnPinned)
    .filter(c => newConf.columnPinned[c])
    .reduce((acc, c) => {
      acc[c] = true;
      return acc;
    }, {} as {[key: string]: boolean});
  newConf.columnPinned = filterObjByKeys(pinnedColumns, truncColumnOrderSet);

  newConf.columnWidths = filterObjByKeys(
    newConf.columnWidths,
    truncColumnOrderSet
  );

  return newConf;
}

const COLUMNS_HIDDEN_BY_DEFAULT = toIncludesObj([
  'run:group',
  'run:jobType',
  'run:updatedAt',
  'run:heartbeatAt',
  'run:host',
  'run:description',
  'run:commit',
  'run:github',
  'run:host',
  'run:runInfo.gpu',
  'run:runInfo.gpuCount',
]);

export function initializeTableSettings(
  conf: RunFeed.ConfigV0,
  allColumns: WBTableColumn[],
  hideNewKey?: boolean
): RunFeed.Config {
  const serverConf = _.cloneDeep(conf);

  let newConf = serverConf as RunFeed.Config;
  // If we haven't already hit the column limit, remove unknown keys.
  // If we have hit the limit, then we just have to assume the keys are too far
  // down the field list for us to know about right now.
  if (
    allColumns.length > 0 &&
    Object.keys(newConf.columnVisible).length < COLUMN_LIMIT
  ) {
    // delete anything in config that is no longer a key
    const allColumnAccessorsSet = new Set(allColumns.map(c => c.accessor));
    const columnOrderSet = new Set(newConf.columnOrder);
    const accessorsToRemove = Object.keys(newConf.columnVisible).filter(
      a => !allColumnAccessorsSet.has(a)
    );
    accessorsToRemove.forEach(accessor => {
      delete newConf.columnVisible[accessor];
      delete newConf.columnPinned[accessor];
      delete newConf.columnWidths[accessor];
      columnOrderSet.delete(accessor);
    });
    newConf.columnOrder = newConf.columnOrder.filter(c =>
      columnOrderSet.has(c)
    );
  }

  // put new columns into config.
  // new keys won't be shown in the table if hideNewKey (e.g. from report view)
  if (
    newConf.columnOrder.length < COLUMN_LIMIT &&
    Object.keys(newConf.columnVisible).length < COLUMN_LIMIT
  ) {
    const newColumnAccessors: string[] = [];
    allColumns.forEach(c => {
      const cAccessor = c.accessor;
      if (
        !(cAccessor in newConf.columnVisible) &&
        !COLUMNS_HIDDEN_BY_DEFAULT[cAccessor]
      ) {
        // columnVisible should have a value for every known key;
        // if a key isn't in columnVisible, it's new.
        if (hideNewKey && conf.columnConfigModified) {
          newConf.columnVisible![cAccessor] = false;
          newConf.columnPinned[cAccessor] = false;
        } else {
          newConf.columnVisible![cAccessor] = true;
          // DEFAULT PINNED
          // TODO: move this elsewhere
          newConf.columnPinned[cAccessor] = Run.keysEqual(c.key, {
            section: 'run',
            name: 'displayName',
          });
          newColumnAccessors.push(cAccessor);
        }
      }
    });

    newColumnAccessors.forEach(cAccessor => {
      const insertIndex = findBestIndexToInsertColumn(
        cAccessor,
        newConf.columnOrder,
        newConf.columnPinned
      );
      newConf.columnOrder.splice(insertIndex, 0, cAccessor);
    });
    // This is true if we have an existing config that was saved before we added Notes to the table.
    // In this case, we want to have Notes hidden by default.
    if (
      _.includes(newColumnAccessors, 'run:notes') &&
      !_.includes(newColumnAccessors, 'run:state')
    ) {
      newConf = hideColumns(newConf, ['run:notes']);
    }
  }

  return maybeTruncateTableSettings(newConf);
}

export function upgradeV0(
  conf: RunFeed.ConfigV0,
  keys: Run.Key[],
  baseKeys: Run.Key[]
): RunFeed.ConfigV1 {
  const columnVisible: {[key: string]: boolean} = {};
  const columnPinned: {[key: string]: boolean} = {};
  const allKeys = unionKeys(baseKeys, keys);
  allKeys.forEach(k => {
    columnVisible[Run.keyToString(k)] = false;
    columnPinned[Run.keyToString(k)] = k.section === 'run' && k.name === 'name';
  });

  if (conf.baseCols) {
    conf.baseCols.forEach((k: Run.Key) => {
      columnVisible[Run.keyToString(k)] = true;
    });
  } else {
    baseKeys.forEach((k: Run.Key) => {
      columnVisible[Run.keyToString(k)] = true;
    });
  }
  if (conf.configCols) {
    conf.configCols.forEach((k: Run.Key) => {
      columnVisible[Run.keyToString(k)] = true;
    });
  }
  if (conf.summaryCols) {
    conf.summaryCols.forEach((k: Run.Key) => {
      columnVisible[Run.keyToString(k)] = true;
    });
  }

  keys.forEach(k => {
    if (conf.configColsAuto && k.section === 'config') {
      columnVisible[Run.keyToString(k)] = true;
    }
    if (conf.summaryColsAuto && k.section === 'summary') {
      columnVisible[Run.keyToString(k)] = true;
    }
  });

  const visiblePinnedOrder: Run.Key[] = conf.pinnedColumnKeys
    ? conf.pinnedColumnKeys
        .filter(ks => columnVisible[ks])
        .map(ks => Run.keyFromString(ks)!)
    : [];
  visiblePinnedOrder.forEach((k: Run.Key) => {
    columnPinned[Run.keyToString(k)] = true;
  });

  // old userOrder contained an order of all keys;
  // new columnOrder only contains visible ones
  const visibleUserOrder = conf.userOrder
    ? conf.userOrder.filter(k => columnVisible[Run.keyToString(k)])
    : [];
  const columnOrder = _.unionWith(
    visiblePinnedOrder,
    visibleUserOrder,
    baseKeys.filter(k => columnVisible[Run.keyToString(k)]),
    keys.filter(k => columnVisible[Run.keyToString(k)]),
    _.isEqual
  ).map(key => Run.keyToString(key));

  return {
    onlyShowSelected: conf.onlyShowSelected,
    pageSize: conf.pageSize,
    columnWidths: conf.columnWidths || {},

    columnVisible,
    columnPinned,
    columnOrder,
  };
}

export function upgradeV1(
  v1: RunFeed.ConfigV1,
  keys: Run.Key[],
  baseKeys: Run.Key[]
): RunFeed.Config {
  const nameKey = {section: 'run' as Run.RunKeySection, name: 'name'};
  const nameKeyString = Run.keyToString(nameKey);

  const displayNameKey = {
    section: 'run' as Run.RunKeySection,
    name: 'displayName',
  };
  const displayNameKeyString = Run.keyToString(displayNameKey);

  const columnVisible: {[key: string]: boolean} = v1.columnVisible;
  const columnPinned: {[key: string]: boolean} = v1.columnPinned;
  const allKeys = unionKeys(baseKeys, keys);

  allKeys.forEach(k => {
    const keyString = Run.keyToString(k);

    // Preserve the existing visibility for all keys.
    if (columnVisible[keyString]) {
      if (keyString === nameKeyString) {
        // For name keys, set the displayName's visibility instead.
        columnVisible[displayNameKeyString] = columnVisible[nameKeyString];
        columnVisible[nameKeyString] = false;
      }
    } else {
      // All columns except name are visible by default.
      columnVisible[keyString] = keyString !== nameKeyString;
    }

    // Preserve the existing pinned status for all keys.
    if (columnPinned[keyString]) {
      if (keyString === nameKeyString) {
        // For name keys, set the displayName's pinned status instead.
        columnPinned[displayNameKeyString] = columnPinned[nameKeyString];
        columnPinned[nameKeyString] = false;
      }
    } else {
      // All columns except displayName are unpinned by default.
      columnPinned[keyString] = keyString === displayNameKeyString;
    }
  });

  const columnOrder = _.unionWith(
    // Preserve the existing ordering, swapping out name with displayName.
    v1.columnOrder
      .map(column => (column === nameKeyString ? displayNameKeyString : column))
      .filter(keyString => columnVisible[keyString]),
    // Also include all visible columns.
    allKeys
      .filter(k => columnVisible[Run.keyToString(k)])
      .map(k => Run.keyToString(k)),
    _.isEqual
  );

  // Replace the column width mapping for name with displayName.
  const columnWidths = v1.columnWidths;
  if (columnWidths[nameKeyString]) {
    columnWidths[displayNameKeyString] = columnWidths[nameKeyString];
    delete columnWidths[nameKeyString];
  }

  return {
    version: 2,
    columnVisible,
    columnPinned,
    columnOrder,
    columnWidths,
  };
}

// Convert a Run.Key to a WBTableColumn
export function keyToColumn(
  key: Run.Key,
  optionalAttributes: Partial<WBTableColumn> = {}
): WBTableColumn {
  return {
    key,
    accessor: Run.keyToString(key),
    displayName: Run.keyDisplayName(key),
    ...optionalAttributes,
  };
}

export function togglePinned(config: RunFeed.Config, columnAccessor: string) {
  if (config.columnPinned[columnAccessor]) {
    return unpin(config, columnAccessor);
  } else {
    return pin(config, columnAccessor);
  }
}

function pin(config: RunFeed.Config, columnAccessor: string) {
  let firstUnpinnedIndex = null;
  for (let i = 0; i < config.columnOrder.length; i++) {
    if (!config.columnPinned[config.columnOrder[i]]) {
      firstUnpinnedIndex = i;
      break;
    }
  }
  const newColumnOrder = config.columnOrder.filter(
    c => !_.isEqual(c, columnAccessor)
  );
  if (firstUnpinnedIndex !== null) {
    newColumnOrder.splice(firstUnpinnedIndex, 0, columnAccessor);
  } else {
    newColumnOrder.push(columnAccessor);
  }

  // make all columns visible after pinning
  const newPinnedColumnsShown = _.sum(Object.values(config.columnPinned)) + 1;

  return update(config, {
    columnPinned: {[columnAccessor]: {$set: true}},
    columnVisible: {
      [columnAccessor]: {$set: true},
    },
    columnOrder: {$set: newColumnOrder},
    pinnedColumnsShown: {$set: newPinnedColumnsShown},
  });
}

function unpin(config: RunFeed.Config, columnAccessor: string) {
  const newColumnOrder = config.columnOrder.filter(
    c => !_.isEqual(c, columnAccessor)
  );
  const newIndex = findBestIndexToInsertColumn(
    columnAccessor,
    newColumnOrder,
    config.columnPinned
  );
  newColumnOrder.splice(newIndex, 0, columnAccessor);

  return update(config, {
    columnPinned: {[columnAccessor]: {$set: false}},
    columnOrder: {$set: newColumnOrder},
  });
}

function findBestIndexToInsertColumn(
  columnAccessor: string,
  order: string[],
  pinned: {[key: string]: boolean}
) {
  let insertIndex = -1;
  const columnKey = Run.keyFromString(columnAccessor);
  if (
    columnKey &&
    columnKey.section !== 'config' &&
    columnKey.section !== 'summary'
  ) {
    // Non config/summary keys go before config and summary
    insertIndex = _.findIndex(order, cAccessor => {
      const cKey = Run.keyFromString(cAccessor);
      return (!pinned[columnAccessor] &&
        cKey &&
        (cKey.section === 'config' || cKey.section === 'summary')) as boolean;
    });
  } else if (columnKey && columnKey.section === 'config') {
    // Config keys go after the last config key
    insertIndex = _.findLastIndex(order, cAccessor => {
      const cKey = Run.keyFromString(cAccessor);
      return (!pinned[columnAccessor] &&
        cKey &&
        cKey.section === 'config') as boolean;
    });
    if (insertIndex !== -1) {
      insertIndex += 1;
    }
  }
  // When not found, or this is a summary key, insert at the end
  if (insertIndex === -1) {
    insertIndex = order.length;
  }
  return insertIndex;
}

export function showColumns(
  config: RunFeed.Config,
  columnAccessors: string[],
  smartGuess = false
) {
  const maxAdditions = COLUMN_LIMIT - config.columnOrder.length;
  if (maxAdditions <= 0) {
    toast(`Column limit of ${COLUMN_LIMIT} reached.`);
    return config;
  }

  const columnsToShow = columnAccessors.slice(0, maxAdditions);
  const newColumnVisible = _.clone(config.columnVisible);
  const newColumnOrder = _.clone(config.columnOrder);
  if (smartGuess && columnsToShow.length <= 100) {
    // If we have a manageable number of columns, do the magic thing.
    for (const accessor of columnsToShow) {
      if (config.columnVisible[accessor]) {
        continue;
      }

      const insertIndex = findBestIndexToInsertColumn(
        accessor,
        newColumnOrder,
        config.columnPinned
      );
      newColumnOrder.splice(insertIndex, 0, accessor);
      newColumnVisible[accessor] = true;
    }
  } else {
    // Just adding new columns to visible set.
    for (const accessor of columnsToShow) {
      if (!config.columnVisible[accessor]) {
        newColumnOrder.push(accessor);
        newColumnVisible[accessor] = true;
      }
    }
  }

  return update(config, {
    columnVisible: {$set: newColumnVisible},
    columnOrder: {$set: newColumnOrder},
  });
}

export function hideColumns(config: RunFeed.Config, columnAccessors: string[]) {
  const newColumnPinned = _.clone(config.columnPinned);
  const newColumnVisible = _.clone(config.columnVisible);
  const columnnsToHide = columnAccessors.reduce((acc, cAccessor) => {
    if (config.columnVisible[cAccessor]) {
      newColumnPinned[cAccessor] = false;
      newColumnVisible[cAccessor] = false;
      acc.add(cAccessor);
    }
    return acc;
  }, new Set<string>());

  const newColumnOrder = config.columnOrder.filter(
    col => !columnnsToHide.has(col)
  );

  return update(config, {
    columnPinned: {$set: newColumnPinned},
    columnVisible: {$set: newColumnVisible},
    columnOrder: {$set: newColumnOrder},
  });
}

export function moveBefore(
  config: RunFeed.Config,
  columnAccessor: string,
  beforeColumnAccessor: string
) {
  if (columnAccessor === beforeColumnAccessor) {
    return config;
  }
  const newColumnOrder = config.columnOrder.filter(
    c => !_.isEqual(c, columnAccessor)
  );
  const insertIndex = _.findIndex(newColumnOrder, c =>
    _.isEqual(c, beforeColumnAccessor)
  );
  newColumnOrder.splice(insertIndex, 0, columnAccessor);

  return update(config, {
    columnOrder: {$set: newColumnOrder},
    columnPinned: {
      [columnAccessor]: {$set: config.columnPinned[beforeColumnAccessor]},
    },
  });
}

export function moveToEnd(config: RunFeed.Config, columnAccessor: string) {
  let newColumnOrder = [...config.columnOrder];
  if (config.columnVisible[columnAccessor]) {
    newColumnOrder = config.columnOrder.filter(
      c => !_.isEqual(c, columnAccessor)
    );
  }
  newColumnOrder.push(columnAccessor);
  return update(config, {
    columnPinned: {[columnAccessor]: {$set: false}},
    columnOrder: {$set: newColumnOrder},
  });
}

// export function isPinned(tableSettings: RunFeed.Config, column: WBTableColumn) {
export function isPinned(
  tableSettings: RunFeed.Config,
  columnAccessor: string
) {
  return tableSettings.columnPinned[columnAccessor];
}

// gets all columns, with the pinned columns first in the list
export function getOrderedColumns(
  tableSettings: RunFeed.Config,
  allColumns: WBTableColumn[]
): WBTableColumn[] {
  // This is implemented manually as tables with a large number of columns spend
  // way too much time in this function with a simple underscore-based implementation

  const columnsByAccessor: {[key: string]: WBTableColumn} = {};
  allColumns.forEach(c => {
    columnsByAccessor[c.accessor] = c;
  });

  const res: WBTableColumn[] = [];

  tableSettings.columnOrder.forEach(accessor => {
    const column = columnsByAccessor[accessor];
    if (column) {
      res.push(column);
    }
  });

  return res;
}

export function deriveAutoColumns(
  tableSettings: RunFeed.Config,
  rows: Run.Run[],
  allColumns?: WBTableColumn[]
) {
  let conf = tableSettings;
  let allSuggestedColAccessors: string[];
  if (allColumns != null) {
    allSuggestedColAccessors = RunHelpers.autoColsNew(allColumns, rows, 1);
  } else {
    const suggestedConfigCols = RunHelpers.autoCols('config', rows, 1);
    const suggestedSummaryCols = RunHelpers.autoCols('summary', rows, 1);
    allSuggestedColAccessors = _.union(
      suggestedConfigCols,
      suggestedSummaryCols
    );
  }

  // hide unpinned cols that aren't in the suggestions
  conf = hideColumns(
    conf,
    conf.columnOrder.filter(c => {
      const key = Run.keyFromString(c);
      return (
        key && !conf.columnPinned[c] && !_.some(allSuggestedColAccessors, c)
      );
    })
  );

  // show cols that are in the suggestions
  conf = showColumns(conf, allSuggestedColAccessors, true);

  return conf;
}

export function findColumnByAccessor(
  accessor: string,
  allColumns: WBTableColumn[]
): WBTableColumn | undefined {
  return allColumns.find(c => c.accessor === accessor);
}

export function makeRowAddressComponent(s: string, runSetId?: string) {
  return ((runSetId && `${runSetId}/`) || '') + s.split('-').join('_');
}
