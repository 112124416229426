import gql from 'graphql-tag';
import {useQuery, UseQueryOptions} from './query';
import {SweepData} from './sweepPageQuery';

// Gets all sweeps for the given project

///// Query definition
export const SWEEPS_QUERY = gql`
  query Sweeps($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      readOnly
      sweeps {
        edges {
          node {
            id
            name
            displayName
            state
            runCount
            runTime
            createdAt
            user {
              id
              username
              photoUrl
            }
            config
          }
        }
      }
    }
  }
`;

// // These types define the inputs and outputs of the above gql query

interface ProjectData {
  id: string;
  readOnly: boolean;
  sweeps: {
    edges: Array<{
      node: SweepData;
    }>;
  };
}

// The query's output shape.
export interface SweepsData {
  project: ProjectData;
}

export function useSweepsQuery(
  variables: {
    entityName: string;
    projectName: string;
  },
  options: UseQueryOptions
) {
  return useQuery<SweepsData, typeof variables>(SWEEPS_QUERY, {
    ...options,
    variables,
  });
}
