import '../css/AddVisMenu.less';

// [TODO] Make Vega panels addable from this widget

import _ from 'lodash';
import React, {useCallback} from 'react';
import * as PanelViewTypes from '../state/views/panel/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import LegacyWBIcon from './elements/LegacyWBIcon';
import docUrl, {lookupDocUrl} from '../util/doc_urls';
import {useKeyInfoQuery, useKeysQuery} from '../state/runs/hooks';
import {useViewer} from '../state/viewer/hooks';
import {isFeatureFlagEnabled} from '../util/featureFlags';
import * as RunHelpers from '../util/runhelpers';
import * as Panels from '../util/panels';
import * as PanelVega2 from './PanelVega2';

import {
  mediaCardTypeToKeys,
  MediaCardType,
  isMediaCardType,
} from '../types/media';
import {ID} from '@wandb/cg/browser/utils/string';
import makeComp from '../util/profiler';

const BASIC_PANELS = [
  {
    name: 'Line plot',
    type: 'line',
    icon: 'panel-line-plot',
  },
  {
    name: 'Bar chart',
    type: 'bar',
    icon: 'panel-bar-chart',
  },
  {
    name: 'Scatter plot',
    type: 'scatter',
    icon: 'panel-scatter-plot',
    hideForSingleRun: true,
  },
  {
    name: 'Parallel coordinates',
    type: 'parallel',
    icon: 'panel-parallel',
    hideForSingleRun: true,
  },
  {
    name: 'Scalar chart',
    type: 'scalar',
    icon: 'panel-number',
  },
  {
    name: 'Run comparer',
    type: 'comparer',
    icon: 'panel-run-comparer',
    hideForSingleRun: true,
  },
  {
    name: 'Parameter importance',
    type: 'parameter-importance',
    icon: 'panel-importance',
    hideForSingleRun: true,
  },
  {
    name: 'Code',
    type: 'code',
    icon: 'panel-html',
  },
  {
    name: 'Markdown',
    type: 'markdown',
    icon: 'panel-text',
  },
];

const GALLERY_PANELS = [
  {
    name: 'Images',
    type: 'image',
    icon: 'panel-images',
  },
  {
    name: 'Plotly',
    type: 'plotly',
    icon: 'panel-plotly',
  },
  {
    name: '3D objects',
    type: 'object3D',
    icon: 'panel-3d',
  },
  {
    name: 'Video',
    type: 'video',
    icon: 'panel-video',
  },
  {
    name: 'Audio',
    type: 'audio',
    icon: 'panel-audio',
  },
  {
    name: 'HTML',
    type: 'html',
    icon: 'panel-html',
  },
  {
    name: 'Text table',
    type: 'table',
    icon: 'panel-text-table',
  },
];

interface AddVisMenuProps {
  entityName: string;
  singleRun?: boolean;
  runSetRefs: RunSetViewTypes.Ref[];
  onSelect(panel: PanelViewTypes.Panel, skipEdit?: boolean): void;
}

type AllAddVisMenuProps = AddVisMenuProps &
  ReturnType<typeof useAddVisMenuProps>;

const AddVisMenuComp: React.FC<AllAddVisMenuProps> = makeComp(
  ({
    entityName,
    singleRun,
    runSetRefs,
    onSelect,
    keyInfoQuery,
    dataframeKeysQuery,
    vegaPanelEnabled,
    vega3PanelEnabled,
  }) => {
    const keysOfType = useCallback(
      (type: string): string[] | undefined => {
        if (type === 'confusion' || type === 'dataframe') {
          if (
            dataframeKeysQuery.loading ||
            dataframeKeysQuery.keys.length === 0
          ) {
            return undefined;
          }
          return dataframeKeysQuery.keys;
        }

        if (keyInfoQuery.loading || keyInfoQuery.error != null) {
          return undefined;
        }
        const {historyKeyInfo} = keyInfoQuery;
        if (historyKeyInfo == null) {
          return [];
        }
        const kt = RunHelpers.keyTypes(historyKeyInfo);
        const typesToKeys: {
          [index: string]: string[];
        } = {};
        _.map(kt, (t, key) => {
          if (typesToKeys[t] == null) {
            typesToKeys[t] = [];
          }
          typesToKeys[t].push(key);
        });

        if (['line', 'scatter', 'parallel', 'bar'].indexOf(type) > -1) {
          type = 'number';
        }

        return typesToKeys[type];
      },
      [dataframeKeysQuery.keys, dataframeKeysQuery.loading, keyInfoQuery]
    );

    const classNames = useCallback(
      (type: string): string => {
        if (type.startsWith('custom:')) {
          return ['enabled', 'legacy-vega'].join(' ');
        }
        // Always enable parallel and scatter. They work as long as there is more than one run.
        // Note from John: why would you make this function instead of just inlining it?
        if (
          type === 'markdown' ||
          type === 'parallel' ||
          type === 'scatter' ||
          type === 'panel-weave' ||
          type === 'legacy-vega' ||
          type === 'vega2' ||
          type === 'vega3' ||
          type === 'parameter-importance' ||
          type === 'comparer' ||
          type === 'scalar' ||
          type === 'code' // TODO: only enable when we have code
        ) {
          return ['enabled', type].join(' ');
        }

        const keyList = isMediaCardType(type)
          ? mediaCardTypeToKeys(type as MediaCardType)
          : [type];

        for (const t of keyList) {
          if (keysOfType(t)) {
            return ['enabled', type].join(' ');
          }
        }

        return ['disabled', type].join(' ');
      },
      [keysOfType]
    );

    const openEditor = useCallback(
      (type: string) => {
        // For disabled cards link to docs instead of
        // launching editor
        if (classNames(type).includes('disabled')) {
          window.open(lookupDocUrl(type) ?? docUrl.logs, '_blank'); // eslint-disable-line wandb/no-unprefixed-urls
        }

        if (type.startsWith('custom:')) {
          if (keyInfoQuery.loading || keyInfoQuery.error != null) {
            return;
          }
          const key = type.substr(7);
          const {id, ...props} = keyInfoQuery.viz[key];
          const panelDefId =
            id != null && !(id.startsWith('builtin:') || id.startsWith('lib:'))
              ? `lib:${id}`
              : id;
          onSelect(
            {
              __id__: ID(),
              viewType: 'Vega',
              config: {
                panelDefId,
                ...props,
              },
            } as PanelViewTypes.Panel,
            true
          );
          return;
        }
        // LB: why not check if the card is disabled instead?
        let viewType: Panels.PanelType = 'Run History Line Plot';
        // We could type this if we made a function that returns a panel type
        // and valid config
        let config: any = {};
        if ((type === 'table' || type === 'table-file') && !singleRun) {
          // We use the multi run table if we're in a multiple run context. This is
          // maybe not ideal? The user may want the media browser. Maybe we let the
          // user pick table and then have a second modal where they can pick between
          // the "Gallery" (media browser) and multi run table.
          viewType = 'Multi Run Table';
        } else if (isMediaCardType(type)) {
          viewType = 'Media Browser';
        } else if (type === 'parallel') {
          viewType = 'Parallel Coordinates Plot';
        } else if (type === 'scatter') {
          viewType = 'Scatter Plot';
        } else if (type === 'panel-weave') {
          viewType = 'Weave';
        } else if (type === 'histogram') {
          viewType = 'Run History Line Plot';
        } else if (type === 'markdown') {
          viewType = 'Markdown Panel';
        } else if (type === 'confusion') {
          viewType = 'Confusion Matrix';
        } else if (type === 'scalar') {
          viewType = 'Scalar Chart';
        } else if (type === 'dataframe') {
          viewType = 'Data Frame Table';
        } else if (type === 'legacy-vega') {
          viewType = 'Vega';
        } else if (type === 'vega2') {
          viewType = 'Vega2';
          config = PanelVega2.INITIAL_CONFIG;
        } else if (type === 'vega3') {
          viewType = 'Vega3';
        } else if (type === 'parameter-importance') {
          viewType = 'Parameter Importance';
        } else if (type === 'comparer') {
          viewType = 'Run Comparer';
        } else if (type === 'code') {
          viewType = 'Code Comparer';
        } else if (type === 'bar') {
          viewType = 'Bar Chart';
        } else if (type === 'line') {
          viewType = 'Run History Line Plot';
        }
        onSelect({
          __id__: ID(),
          viewType,
          config,
        } as PanelViewTypes.Panel);
      },
      [classNames, keyInfoQuery, onSelect, singleRun]
    );

    const advancedPanels: Array<{
      name: string;
      type: string;
      icon: string;
    }> = [];

    if (vegaPanelEnabled) {
      advancedPanels.push({
        name: 'Vega',
        type: 'legacy-vega',
        icon: 'panel-line-plot',
      });
    }

    advancedPanels.push({
      name: 'Custom chart',
      type: 'vega2',
      icon: 'panel-line-plot',
    });

    if (vega3PanelEnabled) {
      advancedPanels.push({
        name: 'Vega 3',
        type: 'vega3',
        icon: 'panel-line-plot',
      });
    }

    advancedPanels.push({
      name: 'Weave',
      type: 'panel-weave',
      icon: 'search',
    });

    const basicPanels = singleRun
      ? BASIC_PANELS.filter(p => !p.hideForSingleRun)
      : BASIC_PANELS;
    const column1 = _.concat(basicPanels, advancedPanels);
    const column2 = GALLERY_PANELS;

    return (
      <>
        <div className="wb-add-vis-menu">
          <ul className="add-panel-list right-border">
            {column1.map((item, i) => (
              <li
                data-test={'add-panel-' + item.type}
                key={`col1-panel-option-${i}`}
                className={classNames(item.type)}
                onClick={(e: any) => {
                  openEditor(item.type);
                }}>
                <LegacyWBIcon name={item.icon} />
                {item.name}
              </li>
            ))}
          </ul>
          <ul className="add-panel-list">
            {column2.map((item, i) => (
              <li
                data-test={'add-panel-' + item.type}
                key={`col2-panel-option-${i}`}
                className={classNames(item.type)}
                onClick={(e: any) => {
                  openEditor(item.type);
                }}>
                <LegacyWBIcon name={item.icon} />
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  },
  {id: 'AddVisMenuComp', memo: true}
);

function useAddVisMenuProps(props: AddVisMenuProps) {
  const {entityName, runSetRefs} = props;
  const keyInfoQuery = useKeyInfoQuery(runSetRefs);
  const dataframeKeysQuery = useKeysQuery(runSetRefs, ['data-frame'], 1);
  const viewer = useViewer();
  const bio = viewer?.userInfo?.bio || '';
  const vega3PanelEnabled = bio.includes('vega3') ? true : false;
  const vegaPanelEnabled = isFeatureFlagEnabled({
    featureKey: entityName,
    featureName: 'vega_panel',
  });
  return {
    keyInfoQuery,
    dataframeKeysQuery,
    vegaPanelEnabled,
    vega3PanelEnabled,
  };
}

const AddVisMenu = makeComp(
  (props: AddVisMenuProps) => {
    const selectedProps = useAddVisMenuProps(props);
    return <AddVisMenuComp {...props} {...selectedProps} />;
  },
  {id: 'AddVisMenu'}
);

export default AddVisMenu;
