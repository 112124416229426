import React, {useState} from 'react';
import '../pages/Profile.css';

import {Button, Dropdown, Label, Modal} from 'semantic-ui-react';
import MemberInfo from '../components/MemberInfo';
import {UserAccountType} from '../generated/graphql';
import {MemberData} from '../graphql/teamQuery';
import makeComp from '../util/profiler';

export interface MemberAccessProps {
  member: MemberData;
  readOnly: boolean;

  setAdmin(admin: boolean): void;
}

const MemberAccess: React.FC<MemberAccessProps> = makeComp(
  ({member, readOnly, setAdmin: propsSetAdmin}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [admin, setAdmin] = useState(false);

    let labelColor: 'blue' | 'violet' | 'green' | undefined;
    let labelText = '';

    if (member.accountType === UserAccountType.Service) {
      labelColor = 'blue';
      labelText = 'SERVICE';
    } else if (member.admin) {
      labelColor = 'violet';
      labelText = 'ADMIN';
    } else {
      labelColor = 'green';
      labelText = 'MEMBER';
    }

    const editable =
      !readOnly && member.accountType !== UserAccountType.Service;

    return (
      <>
        <Label
          color={labelColor}
          as={editable ? 'a' : undefined}
          horizontal
          onClick={
            editable
              ? () => {
                  setModalOpen(true);
                  setAdmin(member.admin);
                }
              : undefined
          }>
          {labelText}
        </Label>
        {editable && (
          <Modal
            open={modalOpen}
            size="tiny"
            onClose={() => setModalOpen(false)}
            style={{maxWidth: '500px'}}>
            <Modal.Header>Member Settings</Modal.Header>
            <Modal.Content>
              <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                  <MemberInfo member={member} isViewer={false} />
                </div>
                <div style={{flex: 1}}>
                  <Dropdown
                    value={admin}
                    options={[
                      {text: 'Admin', value: true},
                      {text: 'Member', value: false},
                    ]}
                    onChange={(_, {value}) => setAdmin(value as boolean)}
                    selection
                    fluid
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setModalOpen(false)}>Close</Button>
              <Button primary onClick={() => propsSetAdmin(admin)}>
                Save
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  },
  {id: 'MemberAccess', memo: true}
);

export default MemberAccess;
