import {useQuery} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as Generated from '../../generated/graphql';
import {identifyWithViewer} from '../../util/analytics';
import {useDispatch} from '../hooks';

import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Types from './types';
import _ from 'lodash';

// This should be used everywhere except in app. It always returns valid viewer
// App.tsx blocks the rest of the app from loading until useInitViewer is complete.
const VIEWER_PHOTO_CACHE_TIMEOUT = 1000 * 60 * 30; // 30 minutes.
let viewerPhotoLastCached = 0;
export function useViewer() {
  return useSelector(Selectors.getViewer, (left, right) => {
    // TODO(adrnswanberg): This is a crazy workaround to deal with the fact that profile photos
    // are signed URLs and thus unstable. Without this check, this selector re-fires on
    // every mutation or query that pulls the viewer down.
    //  Remove this when photo URLs are public or signed URLs are stable.
    if (Date.now() - viewerPhotoLastCached > VIEWER_PHOTO_CACHE_TIMEOUT) {
      viewerPhotoLastCached = Date.now();
      return _.isEqual(left, right);
    }
    const l = _.omit(left, 'photoUrl');
    const r = _.omit(right, 'photoUrl');

    return _.isEqual(l, r);
  });
}

// This is only called from App.tsx, it populates the redux store with the viewer
// object and the app hangs until we've set loading to false.  This is how we
// know if we're logged in.
export function useInitViewer() {
  const {loading, data, refetch} = useQuery<
    Generated.ViewerQuery,
    Generated.ViewerQueryVariables
  >(Generated.ViewerDocument);

  const viewer =
    data != null && data.viewer != null
      ? (data.viewer as unknown as Types.Viewer)
      : undefined;

  const reload = useSelector(Selectors.reloadViewer);

  const dispatch = useDispatch();

  // Reload the viewer when set to true
  useEffect(() => {
    if (reload) {
      refetch();
    }
  }, [reload, refetch]);

  useEffect(() => {
    dispatch(Actions.setCurrentViewer(loading, viewer));
    identifyWithViewer(viewer);
  }, [dispatch, viewer, loading]);
}
