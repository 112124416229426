import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {
  Dropdown,
  Input,
  TextArea,
  Modal as SemanticModal,
} from 'semantic-ui-react';
import {WBIcon} from '@wandb/ui';

export const Table = styled.div`
  margin-top: 16px;
  border: 1px solid ${globals.gray400};
`;

export const Row = styled.div<{header?: boolean}>`
  display: flex;

  &:not(:first-child) {
    border-top: 1px solid ${globals.gray400};
  }

  ${props =>
    props.header &&
    css`
      font-weight: 600;
    `}
`;

export const AddRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: ${globals.gray500};
  cursor: pointer;

  &:hover {
    background-color: ${globals.gray100};
  }
`;

export const AddRowIcon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 8px;
`;

export const PageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: ${globals.gray800};
  background-color: ${globals.gray100};
`;

export const PageRowArrow = styled.div<{hide?: boolean}>`
  user-select: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: ${globals.gray500};
  font-size: 20px;
  width: 32px;
  ${props =>
    props.hide &&
    css`
      visibility: hidden;
    `}

  &:hover {
    color: ${globals.gray600};
  }
`;

export const PageRowDropdown = styled(Dropdown)`
  margin-right: -2px;

  i {
    position: relative;
    left: -8px;
  }
`;

export const Cell = styled.div<{width?: number; noFlexShrink?: boolean}>`
  padding: 8px 16px;
  ${props =>
    css`
      ${props.width && `width: ${props.width}px;`}
      ${props.noFlexShrink && 'flex-shrink: 0;'}
    `}
`;

export const ActionIcon = styled(WBIcon)`
  font-size: 20px;
  cursor: pointer;

  &:not(:hover) {
    opacity: 0.5;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const Modal = styled(SemanticModal)`
  max-width: 600px;
`;

export const ModalWarning = styled.div`
  margin-bottom: 12px;
  font-style: italic;
`;

export const ModalField = styled.div`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const ModalLabel = styled.div`
  font-weight: bold;
`;

export const ModalInput = styled(Input)`
  &&&&& {
    display: block;
    margin-top: 8px;
    input {
      width: 100%;
    }
  }
`;

export const ModalTextArea = styled(TextArea)`
  &&&&& {
    display: block;
    margin-top: 8px;
  }
`;

export const ModalSubtext = styled.div`
  margin-top: 8px;
  font-style: italic;
`;
