import _ from 'lodash';
import classNames from 'classnames';
import React, {useState, useMemo, useRef, useCallback, useEffect} from 'react';
import {Input, Popup} from 'semantic-ui-react';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {escapeRegexChars} from '../util/regex';
import makeComp from '../util/profiler';

interface RunsSearchBarProps {
  isGroupSearch: boolean;
  searchQuery: string;
  setSearch(search: string): void;
}

const RunsSearchBar: React.FC<RunsSearchBarProps> = makeComp(
  ({isGroupSearch, searchQuery: propsSearchQuery, setSearch}) => {
    const [focused, setFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState(propsSearchQuery);
    const [regex, setRegex] = useState(true);
    const inputRef = useRef<Input | null>(null);
    const dontBlurRef = useRef(false);

    const setSearchDebounced = useMemo(
      () =>
        _.debounce((value: string) => {
          const newSearch = regex ? value : escapeRegexChars(value);
          if (newSearch !== propsSearchQuery) {
            setSearch(regex ? value : escapeRegexChars(value));
          }
        }, 500),
      [regex, setSearch, propsSearchQuery]
    );

    const toggleRegex = useCallback(() => {
      // HAX: prevent the search bar from blurring
      dontBlurRef.current = true;
      setTimeout(() => {
        dontBlurRef.current = false;
        inputRef.current?.focus();
      });

      setRegex(prev => !prev);
    }, []);

    useEffect(() => {
      setSearchDebounced(searchQuery);
      // eslint-disable-next-line
    }, [regex]);

    const className = [
      'runs-search-bar-input',
      (focused || searchQuery.length > 0) && 'runs-search-bar-input-focused',
    ].join(' ');

    return (
      <div className="runs-search-bar">
        <Input
          ref={inputRef}
          className={className}
          icon={{
            className: 'wbic-ic-search',
            size: 'large',
          }}
          iconPosition="left"
          value={searchQuery}
          placeholder={
            focused
              ? isGroupSearch
                ? 'Find matching groups'
                : 'Find matching runs'
              : undefined
          }
          onChange={(e, {value}) => {
            setSearchQuery(value);
            setSearchDebounced(value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            if (dontBlurRef.current) {
              return;
            }
            // On blur, only unfocus if the search query is empty.
            setFocused(searchQuery ? searchQuery.length > 0 : false);
          }}
        />
        <Popup
          className="runs-search-bar-regex-toggle-popup"
          content="Supports MySQL regex"
          position="top center"
          popperModifiers={{
            preventOverflow: {enabled: false},
          }}
          inverted
          trigger={
            <LegacyWBIcon
              name="regex"
              className={classNames('runs-search-bar-regex-toggle', {
                active: regex,
              })}
              onMouseDown={toggleRegex}
            />
          }
        />
      </div>
    );
  },
  {id: 'RunsSearchBar', memo: true}
);

export default RunsSearchBar;
