import {capitalizeFirst, ID} from '@wandb/cg/browser/utils/string';
import * as queryString from 'query-string';
import React, {useCallback, useEffect, useMemo} from 'react';
import {matchPath} from 'react-router';
import {
  useFeaturedReportsMetadataQuery,
  useGalleryQuery,
} from '../../generated/graphql';
import {GALLERY_PATH, GALLERY_PATH_SEGMENT} from '../../routeData';
import {store} from '../../setup';
import {setGalleryTags} from '../../state/global/actions';
import {useDispatch, useSelector} from '../../state/hooks';
import {useViewer} from '../../state/viewer/hooks';
import {Viewer} from '../../state/viewer/types';
import {RootState} from '../../types/redux';
import history from '../history';
import {DAY} from '../time';
import {isNotNullOrUndefined, Struct} from '../types';
import {WindowSize} from '../window';
import {
  DEFAULT_LANGUAGE,
  DISCUSSION_CATEGORY_ID,
  EMPTY_GALLERY_SPEC,
  GallerySpec,
  Language,
  POST_CATEGORY_ID,
  ReportIDWithTagIDs,
  ReportMetadata,
  SortOrder,
  sortOrTagToPath,
  Tag,
} from './shared';

export * from './shared';

export const MOBILE_WIDTH = 900;

export const CATEGORIES_SHOWING_DESCRIPTION_SET: Set<string> = new Set([
  'Events',
  'Gradient Dissent',
]);

export const TOP_CONTRIBUTORS_LABEL = 'Top contributors';

const GALLERY_ADMINS = new Set([
  'aditya@wandb.com',
  'axel@wandb.com',
  'lavanya@wandb.com',
  'angelica@wandb.com',
  'aman@wandb.com',
  'morg@wandb.com',
  'justin.tenuto@wandb.com',
  'andrea@wandb.com',
  'scott@wandb.com',
  'carey@wandb.com',
  'ddavies@wandb.com',
]);

type GallerySpecResult = {
  loading: boolean;
  viewID?: string;
  gallerySpec: GallerySpec;
  refetchGallerySpec: () => Promise<GallerySpec>;
};

// HAX: we should definitely be putting this in Redux instead
let gallerySpecCache: Omit<GallerySpecResult, 'loading'> | null = null;
export function useGallerySpecCached(): ReturnType<typeof useGallerySpec> {
  const fetchResult = useGallerySpec(gallerySpecCache == null);
  if (gallerySpecCache != null) {
    return {
      loading: false,
      ...gallerySpecCache,
    };
  }
  return fetchResult;
}

export function useGallerySpec(skip?: boolean): GallerySpecResult {
  const {loading, data, refetch} = useGalleryQuery({skip});

  const viewID = data?.singletonView?.id;
  const specStr = data?.singletonView?.spec;

  const gallerySpec: GallerySpec = useMemo(
    () => (specStr != null ? JSON.parse(specStr) : EMPTY_GALLERY_SPEC),
    [specStr]
  );

  const refetchGallerySpec = useCallback(async () => {
    const {data: refetchedData} = await refetch();
    const refetchedSpecStr = refetchedData?.singletonView?.spec;
    const refetchedGallerySpec =
      refetchedSpecStr != null
        ? JSON.parse(refetchedSpecStr)
        : EMPTY_GALLERY_SPEC;
    return refetchedGallerySpec;
  }, [refetch]);

  if (gallerySpec !== EMPTY_GALLERY_SPEC) {
    gallerySpecCache = {
      viewID,
      gallerySpec,
      refetchGallerySpec,
    };
  }

  return {
    loading,
    viewID,
    gallerySpec,
    refetchGallerySpec,
  };
}

export function useReportMetadata(viewIDs?: string[]): {
  loading: boolean;
  reportMetadatas: ReportMetadata[] | null;
  fetchedIDs: string[];
} {
  const {loading: galleryLoading, gallerySpec} = useGallerySpec();

  const galleryViewIDs = useMemo(
    () => gallerySpec.reportIDsWithTagIDs.map(r => r.id),
    [gallerySpec]
  );
  const metadataViewIDs = viewIDs ?? galleryViewIDs;

  // The useMemo is required so this doesn't change
  // throughout the lifetime of the component
  const recentStarCountFrom = useMemo(
    () => new Date(Date.now() - 30 * DAY),
    []
  );
  const {
    loading: reportMetadataLoading,
    data: reportMetadataData,
    variables,
  } = useFeaturedReportsMetadataQuery({
    variables: {
      ids: metadataViewIDs,
      recentStarCountFrom,
    },
    skip: metadataViewIDs.length === 0,
  });

  const reportMetadataNodes = useMemo(
    () => reportMetadataData?.views?.edges.map(e => e.node!),
    [reportMetadataData]
  );

  const reportMetadatas: ReportMetadata[] | null = useMemo(() => {
    if (galleryLoading || reportMetadataNodes == null) {
      return null;
    }
    const combinedTags = [...gallerySpec.categories, ...gallerySpec.tags];
    const tagByID: Map<string, Tag> = new Map(combinedTags.map(t => [t.id, t]));
    const reportIDWithTagIDByID: Map<string, ReportIDWithTagIDs> = new Map(
      gallerySpec.reportIDsWithTagIDs.map(r => [r.id, r])
    );
    return reportMetadataNodes
      .map(n => {
        const reportIDWithTagID = reportIDWithTagIDByID.get(n.id);
        if (reportIDWithTagID == null) {
          return {
            ...n,
            language: DEFAULT_LANGUAGE,
          } as unknown as ReportMetadata;
        }
        const {tagIDs, authors, addedAt, language, pending, announcement} =
          reportIDWithTagID;
        return {
          ...n,
          language,
          tags: tagIDs
            .map(tid => tagByID.get(tid))
            .filter(isNotNullOrUndefined),
          authors,
          addedAt,
          pending,
          announcement,
        } as unknown as ReportMetadata;
      })
      .filter(isNotNullOrUndefined);
  }, [galleryLoading, gallerySpec, reportMetadataNodes]);

  return {
    loading: galleryLoading || reportMetadataLoading,
    reportMetadatas,
    fetchedIDs: variables.ids,
  };
}

export function useIsGalleryReport(viewID: string): {
  loading: boolean;
  isGalleryReport: boolean;
} {
  const {loading, gallerySpec} = useGallerySpec();

  const isGalleryReport = gallerySpec.reportIDsWithTagIDs.some(
    ({id}) => id === viewID
  );

  return {loading, isGalleryReport};
}

export function useIsGalleryAdmin(): {
  loading: boolean;
  isGalleryAdmin: boolean;
} {
  const viewer = useViewer();
  if (viewer == null) {
    return {loading: true, isGalleryAdmin: false};
  }
  return {loading: false, isGalleryAdmin: GALLERY_ADMINS.has(viewer.email)};
}

export function newTag(): Tag {
  return {
    id: ID(),
    name: '',
    description: '',
    imageURL: '',
    linkForm: '',
    featuredReportIDs: [],
  };
}

export function tagFromQS(): string | null {
  const qs = queryString.parse(window.location.search);
  return (qs.galleryTag as string) ?? null;
}

export function useFormatTag(linkForm: string): string {
  const tags = useGalleryTags();
  return formatGalleryTag(tags, linkForm);
}

export function formatGalleryTag(tags: Tag[], linkForm: string): string {
  for (const t of tags) {
    if (t.linkForm === linkForm) {
      return t.name;
    }
  }

  if (linkForm === 'top-contributors') {
    return TOP_CONTRIBUTORS_LABEL;
  }

  return linkForm.split('-').map(capitalizeFirst).join(' ');
}

export function isOnReportGallery(): boolean {
  return getReportGalleryMatch() != null;
}

export function getReportGalleryMatch(): ReturnType<typeof matchPath> {
  return matchPath(history.location.pathname, {path: GALLERY_PATH});
}

export function getReportGalleryPathParams(tags: Tag[]): {
  sort: string | null;
  tag: string | null;
} | null {
  const match = getReportGalleryMatch();
  if (match == null) {
    return null;
  }
  if (match.params.tag) {
    return {
      sort: match.params.sortOrTag!,
      tag: match.params.tag,
    };
  }
  if (match.params.sortOrTag) {
    if (tagExistsByLinkForm(tags, match.params.sortOrTag)) {
      return {
        sort: null,
        tag: match.params.sortOrTag,
      };
    }
    return {
      sort: match.params.sortOrTag,
      tag: null,
    };
  }
  return {sort: null, tag: null};
}

function tagExistsByLinkForm(tags: Tag[], linkForm: string): boolean {
  return tags.some(t => t.linkForm === linkForm);
}

export function useSortOrTagToPath(s: string): string {
  const tags = useGalleryTags();
  return sortOrTagToPath(tags, s);
}

export function getGalleryTagQS(tags: Tag[], activeTag: string): string {
  return `?galleryTag=${sortOrTagToPath(tags, activeTag)}`;
}

export function usePostTag(): Tag | null {
  const tags = useGalleryTags();
  return tags.find(t => t.id === POST_CATEGORY_ID) ?? null;
}

export function useDiscussionTag(): Tag | null {
  const tags = useGalleryTags();
  return tags.find(t => t.id === DISCUSSION_CATEGORY_ID) ?? null;
}
interface InitGalleryTagsResult {
  loading: boolean;
  tags: Tag[];
}

export function useInitGalleryTags(): InitGalleryTagsResult {
  const dispatch = useDispatch();
  const {loading, gallerySpec} = useGallerySpec();
  const tags: Tag[] = useMemo(
    () => [...gallerySpec.categories, ...gallerySpec.tags],
    [gallerySpec]
  );

  useEffect(() => {
    if (!loading) {
      dispatch(setGalleryTags(tags));
    }
  }, [dispatch, loading, tags]);

  return {loading, tags};
}

export function useGalleryTags(): Tag[] {
  const emptyArray = useMemo(() => [], []);
  return useSelector(state => state.global.galleryTags ?? emptyArray);
}

export type TrackProps = {
  value: string | null;
  activeTag: string;
  sortOrder: SortOrder;
  language: Language;
  searchQuery: string;
  mobileNavMenuOpen: boolean;
  viewer: Viewer | null;
};

export function trackOnGalleryPage(
  event: string,
  properties: TrackProps
): void {
  const namespacedEvent = getNamespacedEvent(event);
  const mergedProperties = {
    ...getMergedProperties(),
    ...properties,
  };
  wbTrack(namespacedEvent, mergedProperties);
}

export function useTrackReportActivity(reportID?: string | null): typeof track {
  const {gallerySpec} = useGallerySpecCached();
  return useMemo(() => {
    const isGalleryReport = gallerySpec.reportIDsWithTagIDs.some(
      r => r.id === reportID
    );
    return isGalleryReport ? trackFC : track;
  }, [reportID, gallerySpec]);
}

export function trackFC(event: string, value: string | null): void {
  const namespacedEvent = getNamespacedEvent(event);
  track(namespacedEvent, value);
}

export function track(event: string, value: string | null): void {
  const mergedProperties = {
    ...getMergedProperties(),
    value,
  };
  wbTrack(event, mergedProperties);
}

const WB_ANALYTICS_ANONYMOUS_ID_KEY = 'WB_ANALYTICS_ANONYMOUS_ID';

function wbTrack(event: string, properties: Struct = {}): void {
  window.analytics.track(event, properties);

  let wbAnonymousId = localStorage.getItem(WB_ANALYTICS_ANONYMOUS_ID_KEY);
  if (wbAnonymousId == null) {
    wbAnonymousId = ID();
    localStorage.setItem(WB_ANALYTICS_ANONYMOUS_ID_KEY, wbAnonymousId);
  }
  const viewer = (store.getState() as RootState).viewer.viewer;

  const body = {
    method: 'track',
    args: [event, properties],
    wbAnonymousId,
    email: viewer?.email,
  };

  // eslint-disable-next-line wandb/no-unprefixed-urls
  fetch('/__WB_ANALYTICS__', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  });
}

type MergedProperties = {
  windowSize: WindowSize;
  isMobile: boolean;
};

const GALLERY_EVENT_NAMESPACE = 'FullyConnected';

function getNamespacedEvent(event: string): string {
  return `${GALLERY_EVENT_NAMESPACE}/${event}`;
}

function getMergedProperties(): MergedProperties {
  return {
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    isMobile: window.innerWidth <= MOBILE_WIDTH,
  };
}

export const rssLinkTagFCReports = getRSSLinkTag(GALLERY_PATH_SEGMENT);

export const rssLinkTagPublicReports = getRSSLinkTag('public-reports');

function getRSSLinkTag(pathSegment: string): JSX.Element {
  return (
    <link
      rel="alternate"
      type="application/rss+xml"
      href={`https://wandb.ai/${pathSegment}/rss.xml`}
    />
  );
}
