export const bytesInGB = 1000000000;

export const bytesInTB = bytesInGB * 1000;

export const freeStorage = bytesInGB * 100;

export const storagePrices = [0.0, 0.08, 0.06, 0.05];

export const artifactPrices = [0.0, 0.05, 0.03, 0.02];

export const storageTiers = [
  bytesInGB * 100,
  bytesInTB * 10,
  bytesInTB * 100,
  bytesInTB * 1000,
];

export const storageFeatures = [
  `$${storagePrices[1]} per GB up to ${storageTiers[1] / bytesInTB} TB`,
  `$${storagePrices[2]} per GB up to ${storageTiers[2] / bytesInTB} TB`,
  `$${storagePrices[3]} per GB up to ${storageTiers[3] / bytesInTB} TB`,
  `Over ${storageTiers[3] / bytesInTB} TB, contact us`,
];

export const artifactFeatures = [
  `$${artifactPrices[1]} per GB up to ${storageTiers[1] / bytesInTB} TB`,
  `$${artifactPrices[2]} per GB up to ${storageTiers[2] / bytesInTB} TB`,
  `$${artifactPrices[3]} per GB up to ${storageTiers[3] / bytesInTB} TB`,
  `Over ${storageTiers[3] / bytesInTB} TB, contact us`,
];
