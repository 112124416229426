import React from 'react';
import {Button, Checkbox, Modal} from 'semantic-ui-react';
import {SweepData} from '../state/graphql/sweepPageQuery';
import makeComp from '../util/profiler';
import {useDeleteSweepsMutation} from '../generated/graphql';

interface DeleteSweepModalProps {
  open: boolean;
  sweeps: SweepData[];
  onClose(): void;
  onDelete(): void;
}

const DeleteSweepModal: React.FC<DeleteSweepModalProps> = makeComp(
  ({open, sweeps, onClose, onDelete}) => {
    const [deleteRuns, setDeleteRuns] = React.useState(false);
    const [deleteSweeps] = useDeleteSweepsMutation({
      variables: {ids: sweeps.map(s => s.id), deleteRuns},
    });

    return (
      <Modal className="sweep-delete-modal" open={open} onClose={onClose}>
        <Modal.Header>Delete Sweep{sweeps.length > 1 ? 's' : ''}</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete{' '}
            {sweeps.length === 1
              ? `sweep ${sweeps[0].displayName || sweeps[0].name}`
              : `${sweeps.length} sweeps`}{' '}
            forever?
          </p>
          <p>
            <Checkbox
              radio
              checked={!deleteRuns}
              label={
                <label>
                  Keep the runs and delete the sweep
                  {sweeps.length > 1 ? 's' : ''}.
                </label>
              }
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteRuns(false);
              }}
            />
          </p>
          <p>
            <Checkbox
              radio
              checked={deleteRuns}
              label={
                <label>
                  Delete all the runs as well as the sweep
                  {sweeps.length > 1 ? 's' : ''}.
                </label>
              }
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteRuns(true);
              }}
            />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              await deleteSweeps();
              onDelete();
            }}>
            Delete Sweep{sweeps.length > 1 ? 's' : ''}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'DeleteSweepModal'}
);

export default DeleteSweepModal;
