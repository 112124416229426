import {ThunkResult} from '../../types/redux';
import * as ViewActionsInternal from '../views/actionsInternal';
import * as ViewApi from '../views/api';
import * as ReportTypes from '../views/report/types';
import * as ViewSelectors from '../views/selectors';
import * as ViewThunks from '../views/thunks';
import * as ViewTypes from '../views/types';

import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as Types from './types';
import {displayError} from '../global/actions';

export function load(
  startAction: ReturnType<typeof Actions.loadStarted>
): ThunkResult<void> {
  const {id, reportID} = startAction.payload;
  return (dispatch, getState, client) => {
    dispatch(startAction);
    return ViewApi.load(client, reportID)
      .then(result => {
        if (reportID !== result.id) {
          throw new Error('invalid state');
        }
        dispatch(ViewActionsInternal.loadFinished(result.cid, result, false));

        if (
          result.type !== Types.REPORT_VIEW_TYPE &&
          result.type !== Types.REPORT_DRAFT_VIEW_TYPE
        ) {
          throw new Error(`Loaded report has incorrect type: ${result.type}`);
        }
        dispatch(
          ActionsInternal.loadFinished(id, {
            type: result.type,
            id: result.cid,
          })
        );
      })
      .catch((e: any) => {
        if (typeof e !== 'string') {
          return;
        }

        if (e.match('not found')) {
          // Hack to bring user back to report list if report is not found. Consider making cleaner.
          // eslint-disable-next-line wandb/no-unprefixed-urls
          window.location.replace(
            window.location.href.slice(
              0,
              window.location.href.indexOf('/reports/')
            ) + '/reportlist#404'
          );
          // dispatch(displayError({code: 404, message: 'Report not found'}));
        } else if (e.match('parse error')) {
          dispatch(displayError({code: 500, message: 'Error parsing report'}));
        }
      });
  };
}

export function unload(reportID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    // Loads aren't always successful, so we might be in a bad state by this point.
    // TODO probably introduce checks everywhere we access the store by key like this.
    if (!(reportID in state.reports.viewRefs)) {
      return;
    }
    const viewRef = state.reports.viewRefs[reportID];

    dispatch(ActionsInternal.unloadReport(reportID));
    dispatch(ViewThunks.unloadView(viewRef.id));
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
  };
}

// This is a thunk so we can fetch the whole object and map it just when
// the user clicks the button, as opposed to always mapping a potential
// report that we may not need.
export function pushReportMapped<T extends ViewTypes.ObjType, D>(
  ref: ViewTypes.PartRefFromType<T>,
  map: (
    whole: ViewTypes.WholeFromType<T>,
    userData: D
  ) => ReportTypes.ReportConfig,
  doPush: (config: ReportTypes.ReportConfig) => void,
  userData: D
): ThunkResult<void> {
  const wholeSelector = ViewSelectors.makeWholeSelector(ref);
  return (dispatch, getState, client) => {
    const state = getState();
    // TODO(john): shady unknown casting
    const whole = wholeSelector(state) as unknown as ViewTypes.WholeFromType<T>;

    const config = map(whole, userData);
    doPush(config);
  };
}
