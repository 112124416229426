import React from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import moment from 'moment';

const inputType = 'date' as const;
type PanelDateProps = Panel2.PanelProps<typeof inputType>;

export const PanelDate: React.FC<PanelDateProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  const date = nodeValueQuery.result;
  const dateS = [
    moment(date + 'Z').format('MMMM Do, YYYY'),
    'at',
    moment(date + 'Z').format('h:mm:ss a'),
  ].join(' ');
  return <div>{dateS}</div>;
};

export const Spec: Panel2.PanelSpec = {
  id: 'date',
  Component: PanelDate,
  inputType,
};
