import {History} from 'history';
import React, {FC, useState} from 'react';
import {Redirect} from 'react-router';
import NoMatch from '../components/NoMatch';
import ProfilePage from '../components/ProfilePage';
import Loader from '../components/WandbLoader';
import {useEntityRunsQuery, useUserRunsQuery} from '../generated/graphql';
import {
  EntityPageQueryResultProps,
  withEntityPageQuery,
} from '../graphql/entityPageQuery';
import {propagateErrorsContext} from '../util/errors';
import {useTimer} from '../util/hooks';
import makeComp from '../util/profiler';
import {entity as entityPage} from '../util/urls';
import TeamPage from './TeamPage';

export const DEFER_RUNS_QUERIES_AFTER = 3000;

interface EntityPageProps {
  history: History;
  match: {params: {entityName: string; filePath: string}};
  tab?: string;
}

const EntityPage: FC<EntityPageProps & EntityPageQueryResultProps> = makeComp(
  ({
    history,
    match: {
      params: {entityName, filePath},
    },
    tab,
    entityPageQuery,
  }) => {
    const entityRunsQuery = useEntityRunsQuery({
      variables: {entityName},
      context: propagateErrorsContext(),
    });
    const userRunsQuery = useUserRunsQuery({
      variables: {entityName, dailyRunCountLimit: getDailyRunCountLimit()},
      context: propagateErrorsContext(),
    });
    const [deferRunsQueries, setDeferRunsQueries] = useState(false);
    useTimer(() => setDeferRunsQueries(true), DEFER_RUNS_QUERIES_AFTER);
    const waitForEntityRuns = entityRunsQuery.loading && !deferRunsQueries;
    const waitForUserRuns = userRunsQuery.loading && !deferRunsQueries;

    if (entityPageQuery.loading) {
      return <Loader />;
    }
    if (entityPageQuery.entity == null || entityPageQuery.entity.available) {
      return <NoMatch />;
    }

    // If the entity has been claimed, redirect to the claiming
    // entity instead so the original link still works.
    if (entityPageQuery.entity.claimingEntity !== null) {
      return (
        <Redirect to={entityPage(entityPageQuery.entity.claimingEntity.name)} />
      );
    }

    if (
      entityPageQuery.entity != null &&
      (entityPageQuery.entity.memberCount > 1 || entityPageQuery.user == null)
    ) {
      // Differentiating between a user entity and a team entity is a bit hacky. We could
      // fix up the database to make this simpler. Currently we have a user "tri" who is
      // also a team.
      // We detect a team by checking if the entity has more than one member, or if their
      // is no user with this entityName
      if (waitForEntityRuns) {
        return <Loader />;
      }
      const entity = entityPageQuery.entity;
      return (
        <TeamPage
          data={entity}
          entityRunsQuery={entityRunsQuery}
          filePath={filePath}
          tab={tab}
        />
      );
    }
    // Show the single user page.
    if (waitForUserRuns) {
      return <Loader />;
    }
    return (
      <ProfilePage
        storageBytes={
          (entityPageQuery.entity && entityPageQuery.entity.storageBytes) || 0
        }
        data={entityPageQuery.user}
        viewer={entityPageQuery.viewer}
        history={history}
        filePath={filePath}
        tab={tab}
        userRunsQuery={userRunsQuery}
      />
    );
  },
  {id: 'EntityPage', memo: true}
);

export default withEntityPageQuery(EntityPage);

function getDailyRunCountLimit(): number {
  return 7 * 51 + 1 + new Date().getDay();
}
