import 'react-table/react-table.css';

import _ from 'lodash';
import React from 'react';
import {CellInfo, Column} from 'react-table';

import * as FuzzyMatch from '../util/fuzzyMatch';
import * as RunHelpers from '../util/runhelpers';
import * as Runs from '../util/runs';
import LegacyWBIcon from './elements/LegacyWBIcon';
import WBReactTable from './WBReactTable';

import '../css/RunConfigTable.less';
import makeComp from '../util/profiler';

interface RunSummaryTableProps {
  summary: any; // Receives raw config (json object)
}

const RunSummaryTable: React.FC<RunSummaryTableProps> = makeComp(
  ({summary}) => {
    const cleanedSummary = Runs.cleanSummary(summary);
    const keys = _.sortBy(_.keys(cleanedSummary)).filter(
      k =>
        k !== '_runtime' &&
        k !== '_step' &&
        k !== '_timestamp' &&
        !k.startsWith('_wandb.') &&
        !k.startsWith('gradients/') &&
        !k.startsWith('parameters/')
    );
    const rows = _.map(keys, k => ({searchString: k, row: k}));
    const nameColumn: Column = {
      Header: 'Name',
      accessor: '',
      className: 'name',
      Cell: (cellInfo: CellInfo) => (
        <>
          <LegacyWBIcon name="summary" />
          <span className="name-string">{cellInfo.value}</span>
        </>
      ),
    };
    const valueColumn: Column = {
      Header: 'Value',
      accessor: '',
      Cell: cellInfo => (
        <span className="value">
          {RunHelpers.displayValue(cleanedSummary[cellInfo.value])}
        </span>
      ),
    };

    const columns = [nameColumn, valueColumn];
    const searchResultColumns = (searchQuery: string) => {
      const searchResultNameColumn: Column = {
        Header: 'Name',
        accessor: '',
        className: 'name',
        Cell: (cellInfo: CellInfo) => (
          <>
            <LegacyWBIcon name="summary" />
            <span className="name-string">
              {FuzzyMatch.fuzzyMatchHighlight(cellInfo.value, searchQuery)}
            </span>
          </>
        ),
      };
      return [searchResultNameColumn, valueColumn];
    };
    return (
      <div className="run-config-table">
        <WBReactTable
          data={rows}
          columns={columns}
          searchResultColumns={searchResultColumns}
          pageSize={20}
        />
      </div>
    );
  },
  {id: 'RunSummaryTable', memo: true}
);

export default RunSummaryTable;
