import React, {useState, ReactNode} from 'react';
import {Button, Container, Grid} from 'semantic-ui-react';
import docUrl from '../util/doc_urls';

import {
  Bash,
  Command,
  Result,
  Static,
  Python,
  Highlight,
  Tear,
} from '../components/Code';
import EditableField from '../components/EditableField';
import ProjectRenameModal from '../components/ProjectRenameModal';
import kerasImg from '../assets/keras_logo.png';
import pytorchImg from '../assets/pytorch_logo.png';
import tensorflowImg from '../assets/tensorflow_logo.png';
import fastaiImg from '../assets/fastai_logo.png';
import xgboostImg from '../assets/xgboost_logo.png';

import '../css/ProjectPageOnboarding.less';
import {ProjectData} from '../state/graphql/projectPageQuery';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

const EXAMPLE_WANDB_OUTPUT = `...
wandb: Started W&B process version 0.10.1 with PID 44884
wandb: Syncing https://wandb.ai/sample-user/tutorial/runs/hgu1cyxr
wandb: Run \`wandb off\` to turn off syncing.
wandb: Local directory: wandb/run-20181129_082128-hgu1cyxr
...`;

type Framework =
  | 'no_framework'
  | 'fastai'
  | 'xgboost'
  | 'keras'
  | 'pytorch'
  | 'tensorflow';

interface EmptyProjectProps {
  pageActions: ReactNode;
  project: ProjectData;
  apiKey: string;
}

export const EmptyProject: React.FC<EmptyProjectProps> = makeComp(
  ({pageActions, project, apiKey}) => {
    const [framework, setFramework] = useState<Framework>('no_framework');
    const [showRename, setShowRename] = useState(false);

    const contentByFramework = {
      no_framework: {
        header: (
          <Static>
            {`
import wandb
wandb.init(project="${project.name}", entity="${project.entityName}")`}
          </Static>
        ),
        body: (
          <Static>
            {` 
for _ in range(num_epochs):
  train_model()
  loss = calulate_loss()
  wandb.log({"Loss": loss})`}
          </Static>
        ),
        optionalMsg: `
np.save("weights", weights)
wandb.save("weights.npy")`,
      },

      fastai: {
        header: (
          <Highlight>
            {`import wandb\n`}
            {`from wandb.fastai import WandbCallback\n`}
            {`wandb.init(project="${project.name}", entity="${project.entityName}")\n`}
          </Highlight>
        ),
        body: (
          <Highlight>
            {`learn = cnn_learner(data, models.densenet121, metrics=[accuracy, top_k_accuracy], callback_fns=[WandbCallback])`}
          </Highlight>
        ),
        optionalMsg: false,
      },
      xgboost: {
        header: (
          <Highlight>
            {`import wandb\n`}
            {`from wandb.xgboost import wandb_callback\n`}
            {`wandb.init(project="${project.name}", entity="${project.entityName}")\n`}
          </Highlight>
        ),
        body: (
          <Highlight>
            {`bst = xgb.train(param, xg_train, num_round, watchlist, callbacks=[wandb_callback()])`}
          </Highlight>
        ),
        optionalMsg: false,
      },

      keras: {
        header: (
          <Highlight>
            {`import wandb\n`}
            {`from wandb.keras import WandbCallback\n`}
            {`wandb.init(project="${project.name}", entity="${project.entityName}")\n`}
          </Highlight>
        ),
        body: (
          <Highlight>
            {`model.fit(X_train, y_train, validationData=(X_test, y_test)\n`}
            {`   epochs=config.epochs, callbacks=[WandbCallback()])`}{' '}
          </Highlight>
        ),
        optionalMsg: `model.save(os.path.join(wandb.run.dir, "model.h5"))`,
      },
      pytorch: {
        header: (
          <Highlight>
            {`import wandb\n`}
            {`wandb.init(project="${project.name}", entity="${project.entityName}")\n`}
          </Highlight>
        ),
        body: (
          <React.Fragment>
            <Highlight>{`wandb.watch(model)`}</Highlight>
            <Tear text=" Model train/evaluate code ..." />

            <Static>{`model.train()\n`}</Static>

            <Static>
              {`for batch_idx, (data, target) in enumerate(train_loader)\n`}
            </Static>
            <Static>{`    if batch_idx % args.log_interval == 0:\n`}</Static>
            <Highlight>
              {`        wandb.log({"Test Accuracy": correct / total, "Test Loss": loss})`}
            </Highlight>
          </React.Fragment>
        ),
        optionalMsg: `torch.save(model.state_dict(), os.path.join(wandb.run.dir, 'model.pt'))`,
      },
      tensorflow: {
        header: (
          <Highlight>
            {`import wandb\n`}
            {`from wandb.tensorflow import WandbHook\n`}
            {`wandb.init(project="${project.name}", entity="${project.entityName}", sync_tensorboard=True)\n`}
          </Highlight>
        ),
        body: (
          <>
            <Static>
              {`# If you are using tensorboard already, we will sync all the information from tensorboard and this isn't necessary.\n`}
              {`# If you are using an estimator you can add our hook.\n`}
            </Static>
            <Highlight>
              {`classifier.train(input_fn=train_input_fn, steps=100000, hooks=[WandbHook()])\n`}
            </Highlight>
          </>
        ),
        optionalMsg: `saver = tf.train.Saver()\nsaver.save(sess, os.path.join(wandb.run.dir, "model.ckpt"))`,
      },
    };

    const frameworksWithImg: Array<[Framework, string | null]> = [
      ['no_framework', null],
      ['keras', kerasImg],
      ['pytorch', pytorchImg],
      ['tensorflow', tensorflowImg],
      ['fastai', fastaiImg],
      ['xgboost', xgboostImg],
    ];

    return (
      <Container className="project-getting-started project-page">
        <ProjectRenameModal
          open={showRename}
          targetProject={{name: project.name, entityName: project.entityName}}
          onClose={() => setShowRename(false)}
        />
        <h2 style={{display: 'flex'}}>
          <EditableField
            showEditIcon
            placeholder={project.name}
            value={project.name}
            overrideClick={() => setShowRename(true)}
          />
          {pageActions && (
            <div
              className="page-header__actions"
              style={{flexGrow: 1, marginTop: 0, textAlign: 'right'}}>
              {pageActions}
            </div>
          )}
        </h2>
        <div className="newStep">
          <div className="getting-started-buttons">
            <Grid stackable columns={3}>
              {frameworksWithImg.map(([fw, img]) => {
                const isSelected = framework === fw;

                return (
                  <Grid.Column key={fw}>
                    <Button
                      fluid
                      basic
                      onClick={() => setFramework(fw)}
                      className={
                        isSelected ? 'buttonSelected' : 'buttonUnselected'
                      }>
                      {img ? (
                        <img
                          data-test={`img-${fw}`}
                          alt={fw}
                          src={img}
                          style={{maxHeight: 20}}
                        />
                      ) : (
                        'No Framework'
                      )}
                    </Button>
                  </Grid.Column>
                );
              })}
            </Grid>
          </div>
        </div>
        <div key={framework}>
          <p>
            This is a simple overview of how to instrument your script with
            wandb.
          </p>
          <p>
            See our <TargetBlank href={docUrl.root}>docs</TargetBlank> for more
            information on how wandb works and our{' '}
            <TargetBlank href={docUrl.integrations}>framework docs</TargetBlank>{' '}
            for an overview of framework-specific integrations.
          </p>
          <h2>Install wandb and login from the command line.</h2>
          <Bash>
            <Command>pip install --upgrade wandb</Command>
            <WandbLoginExample apiKey={apiKey} />
          </Bash>

          {(({header, body, optionalMsg = true}) => {
            return (
              <div>
                <h2>Instrument your training script with wandb:</h2>
                <Python>
                  <Static>{`# Init wandb`}</Static>
                  {header}
                  <Tear text=" Model instantiation code ..." />
                  <Static>{`# Log metrics with wandb`}</Static>

                  {body}
                  {optionalMsg && (
                    <React.Fragment>
                      <Static>
                        {`\n# Save model to wandb\n`}
                        {optionalMsg}
                      </Static>
                    </React.Fragment>
                  )}
                </Python>
              </div>
            );
          })(contentByFramework[framework])}
          <h2>Run your training script.</h2>

          <Bash>
            <Command>python train.py</Command>
            <Result>{EXAMPLE_WANDB_OUTPUT}</Result>
          </Bash>
          <p>Refresh this page to see live training metrics.</p>
          <p>
            For more complete code examples, check out our{' '}
            <TargetBlank href="https://github.com/wandb/examples">
              github examples
            </TargetBlank>{' '}
            repository.
          </p>
        </div>
      </Container>
    );
  },
  {id: 'EmptyProject'}
);

interface WandbLoginExampleProps {
  apiKey: string;
}

const WandbLoginExample: React.FC<WandbLoginExampleProps> = ({apiKey}) => {
  let args = '';
  // Add the local instance host when not running in the cloud
  if (!document.location.origin.includes('wandb.ai')) {
    args = ' --host=' + document.location.origin;
  }
  return (
    <Command>
      wandb login{args} {apiKey}
    </Command>
  );
};

interface NoRunsSampleScriptProps {
  apiKey: string;
}

export const NoRunsSampleScript: React.FC<NoRunsSampleScriptProps> = makeComp(
  ({apiKey}) => {
    return (
      <div className="project-getting-started">
        <h2>Example Project</h2>
        <p>
          Copy the following commands in your terminal to clone a simple Keras
          training script and log the results to wandb.
        </p>
        <Bash>
          <Command>git clone http://github.com/wandb/tutorial</Command>
          <Command>
            cd tutorial; pip install --upgrade -r requirements.txt;
          </Command>
          <WandbLoginExample apiKey={apiKey} />
          <Command>python tutorial.py</Command>
        </Bash>
        <div />
        <p>
          If you would like to find more examples of training scripts
          instrumented with wandb, check out our{' '}
          <TargetBlank href="https://github.com/wandb/examples">
            examples repository
          </TargetBlank>
          .
        </p>
      </div>
    );
  },
  {id: 'NoRunsSampleScript'}
);
