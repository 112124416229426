import * as S from './TeamSidebar.styles';

import React from 'react';
import {Mutation} from 'react-apollo';
import {Header, Image} from 'semantic-ui-react';

import '../css/TeamSidebar.less';
import {
  UpdateEntityPhotoMutationFn,
  useOrganizationSubscriptionsQuery,
  UserAccountType,
} from '../generated/graphql';
import {TEAM_PHOTO_UPDATE} from '../graphql/admin';
import {TeamPageData} from '../pages/TeamPage';
import {LinkNoCrawl} from '../util/links';
import makeComp from '../util/profiler';
import * as urls from '../util/urls';
import EditableImage from './EditableImage';
import LegacyWBIcon from './elements/LegacyWBIcon';
import config from '../config';
interface TeamSidebarProps {
  data: TeamPageData;
}

const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;

const TeamSidebar: React.FC<TeamSidebarProps> = makeComp(
  ({data}) => {
    const subscriptions = useOrganizationSubscriptionsQuery();

    const org = subscriptions.data?.viewer?.organizations?.find(
      o => o.id === data.organizationId
    );

    const showOrgDashboard =
      org?.subscriptions?.some(
        sub => sub.privileges?.org_dash_enabled === true
      ) ?? false;

    return (
      <div className="sidebar team-sidebar">
        <div className="content">
          <Mutation mutation={TEAM_PHOTO_UPDATE}>
            {(teamUp: UpdateEntityPhotoMutationFn) => (
              <EditableImage
                placeholderText="Add a team logo"
                photoUrl={data.photoUrl}
                displaySize={200}
                label={false}
                readOnly={data.readOnly}
                entityName={data.name}
                save={newVal => {
                  teamUp({
                    variables: {
                      photoUrl: newVal,
                      entityName: data.name,
                    },
                  });
                }}
              />
            )}
          </Mutation>
          <div>
            <Header as="h1">{data.name}</Header>
            {data.userStats.edges.length > 0 && (
              <>
                <div className="user-list-header-wrapper">
                  <span className="user-list-header">Weekly most active</span>
                  <span className="user-run-count-header">Runs</span>
                </div>
                {data.userStats.edges.map(eu => {
                  return (
                    <div key={eu.node.user.id} className="user-listitem">
                      <Image
                        src={eu.node.user.photoUrl}
                        avatar
                        onError={(i: any) => (i.target.style.display = 'none')}
                      />
                      {eu.node.user.accountType === UserAccountType.User ? (
                        <LinkNoCrawl
                          to={urls.profilePage(eu.node.user.username)}>
                          {eu.node.user.username}
                        </LinkNoCrawl>
                      ) : (
                        eu.node.user.username
                      )}
                      <span className="user-run-count">{eu.node.runCount}</span>
                    </div>
                  );
                })}
              </>
            )}
            <LinkNoCrawl
              data-test="team-see-all"
              to={urls.teamMembers(data.name)}
              className="see-all-link">
              <LegacyWBIcon name="configuration" size="large" />
              Team settings ➞
            </LinkNoCrawl>
            {!isOnPrem && org && (
              <>
                {showOrgDashboard && (
                  <LinkNoCrawl
                    data-test="org-dashboard"
                    to={urls.orgPage(org.name)}
                    className="see-all-link">
                    <LegacyWBIcon name="office" size="large" />
                    Enterprise dashboard ➞
                  </LinkNoCrawl>
                )}
                <S.LinkToPage to={urls.subscriptions()}>
                  <S.LinkToPageIcon name="configuration" size="large" />
                  {org.name}
                </S.LinkToPage>
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
  {id: 'TeamSidebar', memo: true}
);

export default TeamSidebar;
