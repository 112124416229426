import * as _ from 'lodash';
import * as React from 'react';
import {Header, Icon, Segment} from 'semantic-ui-react';

import Markdown from '../../components/Markdown';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

const RANK_METRIC = 'val_loss';

const keys = [RANK_METRIC, 'val_precision', 'val_recall'];

const scoreCols = _.map(keys, k => ({
  Header:
    k === RANK_METRIC ? (
      <span style={{fontWeight: 'bold'}}>
        {k}
        <Icon name="caret up" />
      </span>
    ) : (
      k
    ),
  id: k,
  accessor: (run: WithSummary<BenchmarkRun>) => {
    return Run.displayValue(run.summary[k]);
  },
}));

const leaderboardCols = [...Leaderboard.cols, ...scoreCols];

const CONCEPT = `
This community benchmark is a public home for building deep learning models
to recognize [The Witness](http://the-witness.net/) puzzles in game
screenshots. Read our [blog post](https://wandb.ai/site/articles/i-trained-a-robot-to-play-the-witness) for more information about the 
project.

### Getting started

Follow the "Quickstart" instructions in the [Witness GitHub repository](https://github.com/wandb/witness). This will guide you through running and submitting the model.

### Evaluation

This project is a work-in-progress and the evaluation dataset is still quite limited. Contributions would be welcome in the following areas:

* Improving model performance on the existing dataset
* Expanding the training and validation set (or data augmentation)
* Improving evaluation criteria and metrics
`;

const SUBMISSION_INSTRUCTIONS = (projectName: string) => `
Runs in this project can be submitted to the The Witness public benchmark.

### Getting started

Follow the "Quickstart" instructions in the [Witness GitHub repository](https://github.com/wandb/witness). This will guide you through running and submitting the model.
`;

const overview = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <Header as="h3">Overview</Header>
      <Segment>
        <Markdown condensed={false} content={CONCEPT} />
      </Segment>
      <Header as="h3">Top submissions</Header>
      <WBReactTable
        pageSize={20}
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, RANK_METRIC, false)}
      />
    </React.Fragment>
  );
};

const theme: Theme = {
  keys,
  benchmarkLink: 'https://github.com/wandb/witness',
  overview,
  submissionGuidelines: SUBMISSION_INSTRUCTIONS,
  codeRequired: true,
  embargo:
    'With this option set, your GitHub code/link will not be shown on the W&B platform.',
};

export default theme;
