import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import ReactTimeago from 'react-timeago';
import {Button, Modal} from 'semantic-ui-react';
import {
  useGalleryQuery,
  useInsertGalleryDiscussionMutation,
  useUpdateAccessTokenProjectsMutation,
} from '../generated/graphql';
import {useApolloClient, useSelector} from '../state/hooks';
import {ReportViewRef} from '../state/reports/types';
import {useViewer} from '../state/viewer/hooks';
import * as ViewApi from '../state/views/api';
import {LoadResultType as LoadedView} from '../state/views/api';
import * as ViewNormalize from '../state/views/normalize';
import * as ViewSelectors from '../state/views/selectors';
import {
  DEFAULT_LANGUAGE,
  ReportIDWithTagIDs,
  useGalleryTags,
  useTrackReportActivity,
} from '../util/gallery';
import makeComp from '../util/profiler';
import {
  getProjectSpecifierKey,
  getProjectSpecifierMap,
  useReportProjects,
} from '../util/report';
import {
  isOnGalleryDiscussionEdit,
  isOnGalleryDiscussionView,
  isOnGalleryPostEdit,
  isOnGalleryPostView,
} from '../util/url';
import * as Urls from '../util/urls';
import {TargetBlank} from '../util/links';
import WBModal from './WBModal';

interface SaveDraftTriggerProps {
  viewRef: ReportViewRef;
}

const SaveDraftTrigger: React.FC<SaveDraftTriggerProps> = makeComp(
  props => {
    const viewer = useViewer();
    const client = useApolloClient();
    const history = useHistory();
    const tags = useGalleryTags();
    const view = useSelector(state => state.views.views[props.viewRef.id]);
    const views = useSelector(ViewSelectors.getViews);
    const reportID = view.id;
    if (reportID == null) {
      throw new Error('invalid state');
    }
    const viewPartRef = view.partRef;
    if (viewPartRef == null) {
      throw new Error('View in redux missing part ref');
    }
    const [saving, setSaving] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const cancelSaving = useCallback(() => {
      setWarningOpen(false);
      setSaving(false);
    }, []);
    const [parent, setParent] = useState<LoadedView | null>(null);

    const [updateAccessTokenProjects] = useUpdateAccessTokenProjectsMutation();
    const [insertGalleryDiscussion] = useInsertGalleryDiscussionMutation();

    const {refetch: refetchGallery} = useGalleryQuery();

    const [thanksModalOpen, setThanksModalOpen] = useState(false);

    const projectsIncluded = useReportProjects(props.viewRef);

    const needAccessTokenUpdate = (parentView: LoadedView): boolean => {
      if (
        parentView.accessTokens == null ||
        parentView.accessTokens.length === 0
      ) {
        return false;
      }
      const accessTokenProjects = parentView.accessTokens[0].projects;
      if (projectsIncluded.length !== accessTokenProjects.length) {
        return true;
      }

      const projectSpecifierMap = getProjectSpecifierMap(projectsIncluded);
      return accessTokenProjects.some(
        p =>
          projectSpecifierMap[
            getProjectSpecifierKey({
              entityName: p.entityName,
              projectName: p.name,
            })
          ] == null
      );
    };

    const track = useTrackReportActivity(view.id);

    if (viewer == null) {
      return <>{props.children}</>;
    }

    const saveToMaster = async (parentView: LoadedView) => {
      if (view.project == null && view.user.id === viewer.id) {
        if (isOnGalleryPostView() || isOnGalleryPostEdit()) {
          track('triggerSavePostEdits', view.id ?? null);
        }
        if (isOnGalleryDiscussionView() || isOnGalleryDiscussionEdit()) {
          track('triggerSaveDiscussionEdits', view.id ?? null);
        }
      }

      await ViewApi.save(client, {
        id: view.parentId,
        type: 'runs',
        displayName: view.displayName,
        description: view.description,
        spec: ViewNormalize.denormalize(views.parts, viewPartRef),
        project: view.project,
        previewUrl: view.previewUrl,
        coverUrl: view.coverUrl,
      });
      await ViewApi.deleteView(client, reportID);

      if (needAccessTokenUpdate(parentView)) {
        const token = parentView.accessTokens?.[0]?.token;
        if (token != null) {
          await updateAccessTokenProjects({
            variables: {
              token,
              projects: projectsIncluded,
            },
          });
        }
      }

      history.push(
        view.project != null
          ? Urls.reportView({
              entityName: view.project.entityName,
              projectName: view.project.name,
              reportID: view.parentId as string,
            })
          : (isOnGalleryDiscussionView() || isOnGalleryDiscussionEdit()
              ? Urls.galleryDiscussionView
              : Urls.galleryPostView)({
              entityName: view.entityName,
              reportID: view.parentId as string,
            })
      );
    };

    return (
      <>
        <div
          style={{display: 'contents'}}
          onClick={async () => {
            if (saving) {
              return;
            }
            setSaving(true);

            if (view.parentId == null) {
              if (isOnGalleryPostView() || isOnGalleryPostEdit()) {
                track('clickSubmitPost', view.id ?? null);
              }
              if (isOnGalleryDiscussionView() || isOnGalleryDiscussionEdit()) {
                track('clickSubmitDiscussion', view.id ?? null);
              }
            }

            if (view.parentId == null) {
              const spec = ViewNormalize.denormalize(views.parts, viewPartRef);
              await ViewApi.save(client, {
                id: view.id,
                type: 'runs',
                displayName: view.displayName,
                description: view.description,
                spec,
                project: view.project,
                previewUrl: view.previewUrl,
                coverUrl: view.coverUrl,
              });
              if (isOnGalleryPostView() || isOnGalleryPostEdit()) {
                if (view.id == null) {
                  throw new Error(`attempting to publish view with null id`);
                }
                const tag = tags.find(t => t.id === 'ir6df9nxe');
                if (tag == null) {
                  throw new Error(`unable to find posts tag`);
                }
                const entrySpec: ReportIDWithTagIDs = {
                  id: view.id,
                  tagIDs: [tag.id],
                  authors: (spec as any).authors ?? [],
                  addedAt: new Date().toJSON(),
                  language: DEFAULT_LANGUAGE,
                  pending: true,
                };
                await insertGalleryDiscussion({
                  variables: {
                    spec: JSON.stringify(entrySpec),
                    reportID: view.id,
                    sendEmail: true,
                  },
                });
                await refetchGallery();
                setThanksModalOpen(true);
                return;
              }
              if (isOnGalleryDiscussionView() || isOnGalleryDiscussionEdit()) {
                if (view.id == null) {
                  throw new Error(`attempting to publish view with null id`);
                }
                const tag = tags.find(t => t.id === '90vhuh15v');
                if (tag == null) {
                  throw new Error(`unable to find discussions tag`);
                }
                const entrySpec: ReportIDWithTagIDs = {
                  id: view.id,
                  tagIDs: [tag.id],
                  authors: (spec as any).authors ?? [],
                  addedAt: new Date().toJSON(),
                  language: DEFAULT_LANGUAGE,
                };
                await insertGalleryDiscussion({
                  variables: {
                    spec: JSON.stringify(entrySpec),
                    reportID: view.id,
                  },
                });
                await refetchGallery();
              }
              history.push(
                view.project != null
                  ? Urls.reportView({
                      entityName: view.project.entityName,
                      projectName: view.project.name,
                      reportID,
                    })
                  : Urls.reportGallery({
                      tag:
                        isOnGalleryDiscussionView() ||
                        isOnGalleryDiscussionEdit()
                          ? `forum`
                          : `posts`,
                    })
              );
            } else {
              const loadedParent = await ViewApi.load(client, view.parentId);
              setParent(loadedParent);
              if (loadedParent.updatedAt > view.createdAt) {
                setWarningOpen(true);
              } else {
                saveToMaster(loadedParent);
              }
            }
          }}>
          {props.children}
        </div>
        {parent != null && warningOpen && (
          <Modal size="mini" open={true} onClose={cancelSaving}>
            <Modal.Content>
              <h3>Potential conflict</h3>
              <p style={{marginBottom: 24}}>
                This report was modified{' '}
                <ReactTimeago date={parent.updatedAt + 'Z'} live={false} /> by{' '}
                {parent.updatedBy?.username}.{' '}
                <TargetBlank
                  href={
                    view.project != null
                      ? Urls.reportView({
                          entityName: view.project.entityName,
                          projectName: view.project.name,
                          reportID: view.parentId as string,
                        })
                      : (isOnGalleryDiscussionView() ||
                          isOnGalleryDiscussionEdit()
                          ? Urls.galleryDiscussionView
                          : Urls.galleryPostView)({
                          entityName: view.entityName,
                          reportID: view.parentId as string,
                        })
                  }>
                  View the report
                </TargetBlank>{' '}
                to see their changes and potentially resolve conflicts.
              </p>
              <Button
                color="red"
                size="tiny"
                onClick={() => saveToMaster(parent)}>
                Overwrite changes
              </Button>
              <Button size="tiny" onClick={cancelSaving}>
                Cancel
              </Button>
            </Modal.Content>
          </Modal>
        )}
        {thanksModalOpen && (
          <WBModal
            open
            header=""
            primaryAction={{
              content: 'Go back to Fully Connected',
              onClick: () => history.push(Urls.reportGallery({tag: 'posts'})),
            }}>
            <p>
              Thank you for your submission! We’ll review your submission and
              contact you with any suggestions before publishing.
            </p>
          </WBModal>
        )}
      </>
    );
  },
  {id: 'SaveDraftTrigger'}
);

export default SaveDraftTrigger;
