import * as React from 'react';

import LegacyWBIcon from './LegacyWBIcon';
import makeComp from '../../util/profiler';

export interface WarningMessageProps {
  className?: string;
  content: string | JSX.Element;
  onDismiss?(): void;
}

const WarningMessage = makeComp(
  (props: WarningMessageProps) => {
    const {className, content, onDismiss} = props;
    return (
      <div
        className={
          'ui warning message' + (className != null ? ' ' + className : '')
        }>
        {/* icon */}
        {onDismiss != null && (
          <LegacyWBIcon
            name="close"
            className="close-button"
            onClick={onDismiss}
          />
        )}
        <div className="content">{content}</div>
      </div>
    );
  },
  {id: 'WarningMessage'}
);

export default WarningMessage;
