import React from 'react';
import {Popup, Menu} from 'semantic-ui-react';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {useContext} from 'react';
import {RunQueryContext} from '../state/runs/context';

interface PanelActionsOverflowProps {
  defaultMovePanelOption?: JSX.Element;
  panelMover?: (close: () => void) => JSX.Element | false;
  panelExporter?: (close: () => void) => JSX.Element | false;
  addPanelToReport?(): void;
  sharePanel?(): void;
  duplicatePanel?(): void;
  onRemovePanel?(): void;
}

export const PanelActionsOverflow: React.FC<PanelActionsOverflowProps> =
  React.forwardRef<HTMLElement, PanelActionsOverflowProps>(
    (props, ref: React.Ref<HTMLElement>) => {
      const {
        panelMover,
        defaultMovePanelOption,
        panelExporter,
        addPanelToReport,
        sharePanel,
        duplicatePanel,
        onRemovePanel,
      } = props;
      const [activeSubmenu, setActiveSubmenu] = React.useState<
        'panelMover' | 'panelExporter'
      >();
      const [isOpen, setIsOpen] = React.useState(false);
      const runsContext = useContext(RunQueryContext);
      const handleOpen = () => setIsOpen(true);
      const handleClose = () => {
        setActiveSubmenu(undefined);
        setIsOpen(false);
      };
      const popupWidth = 148;
      const panelMoverOffsetLeft = -82;
      return (
        <Popup
          className="popup-menu"
          basic
          position="bottom right"
          on="click"
          onOpen={handleOpen}
          open={isOpen}
          onClose={handleClose}
          style={{
            width: popupWidth,
            top: -8,
            left:
              activeSubmenu === 'panelMover' ? panelMoverOffsetLeft : undefined,
          }}
          // offset="0, -16px"
          // offset={'-320px, -16px'}
          trigger={
            <LegacyWBIcon
              style={{cursor: 'pointer'}}
              name="overflow"
              onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            />
          }
          content={
            <>
              {activeSubmenu == null && (
                <Menu vertical borderless style={{width: popupWidth}}>
                  {addPanelToReport != null && (
                    <Menu.Item
                      className="panel-action-add-to-report"
                      onClick={() => {
                        addPanelToReport();
                        handleClose();
                      }}>
                      <LegacyWBIcon name="report" />
                      Add to report
                    </Menu.Item>
                  )}
                  {sharePanel != null && (
                    <Menu.Item
                      className="panel-action-share"
                      onClick={() => {
                        sharePanel();
                        handleClose();
                      }}>
                      <LegacyWBIcon name="link" />
                      Share panel
                    </Menu.Item>
                  )}
                  {duplicatePanel != null && (
                    <Menu.Item
                      className="panel-action-duplicate"
                      onClick={() => {
                        duplicatePanel();
                        handleClose();
                      }}>
                      <LegacyWBIcon name="copy" />
                      Duplicate panel
                    </Menu.Item>
                  )}
                  {panelMover && runsContext.report == null && (
                    <>
                      {defaultMovePanelOption}
                      <Menu.Item onClick={() => setActiveSubmenu('panelMover')}>
                        <LegacyWBIcon name="move" />
                        Move panel...
                      </Menu.Item>
                    </>
                  )}
                  {panelExporter && (
                    <Menu.Item
                      onClick={() => setActiveSubmenu('panelExporter')}>
                      <LegacyWBIcon name="download" />
                      Export panel...
                    </Menu.Item>
                  )}
                  {onRemovePanel != null && (
                    <Menu.Item
                      className="panel-action-delete"
                      onClick={() => {
                        onRemovePanel();
                        handleClose();
                      }}>
                      <LegacyWBIcon name="delete" />
                      Delete panel
                    </Menu.Item>
                  )}
                </Menu>
              )}
              {activeSubmenu != null && (
                <>
                  {activeSubmenu === 'panelMover' &&
                    panelMover != null &&
                    panelMover(handleClose)}
                  {activeSubmenu === 'panelExporter' &&
                    panelExporter != null &&
                    panelExporter(handleClose)}
                </>
              )}
            </>
          }
        />
      );
    }
  );
