import * as _ from 'lodash';
import * as React from 'react';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';
import {Icon, Modal, Popup} from 'semantic-ui-react';
import {apolloClient} from '../setup';
import {clearBoxData, loadBoxData} from '../state/media/actions';
import * as PanelTypes from '../state/views/panel/types';
import {PartRefFromObjSchema} from '../state/views/types';
import {BoundingBoxFileData, ImageMetadata} from '../types/media';
import {RunSignature} from '../types/run';
import {captureError} from '../util/integrations';
import {
  boxColor,
  getBoundingBoxes,
  getMasks,
  getSingletonValue,
  labelComponent,
  makeCaptions,
  mediaFilePath,
  mediaSrc,
  runFileSource,
  segmentationMaskColor,
  WANDB_BBOX_CLASS_LABEL_KEY,
  WANDB_DELIMETER,
  WANDB_MASK_CLASS_LABEL_KEY,
  WBFile,
} from '../util/media';
import makeComp from '../util/profiler';
import {
  downloadDataUrl,
  fetchFileInfo,
  FileInfo,
  useLoadFile,
} from '../util/requests';
import {runLink} from '../util/runhelpers';
import {Struct} from '../util/types';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {toast} from './elements/Toast';
import * as S from './ImageCard.styles';
import {
  BoundingBoxClassControl,
  BoundingBoxSliderControl,
  getImageMedia,
  MaskControl,
  MediaCardProps,
  RunWithHistoryAndMediaWandb,
  Style,
} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import {
  BoundingBoxesCanvas,
  SegmentationMaskLoader,
} from './Panel2/ImageWithOverlays';

type ImageCardProps = MediaCardProps;

export const ImageCard: React.FunctionComponent<ImageCardProps> = makeComp(
  props => {
    const {width, height, mediaIndex, mediaKey, tileMedia, globalStep} = props;

    if (tileMedia == null) {
      return (
        <div style={{height: '100%'}}>
          <div className="image-card" style={{width, height, display: 'flex'}}>
            <MessageMediaNotFound
              basic
              mediaIndex={mediaIndex}
              mediaKey={mediaKey}
              stepIndex={globalStep}
              mediaType="images"
            />
          </div>
        </div>
      );
    }

    return <ImageCardInner {...props} tileMedia={tileMedia} />;
  },
  {id: 'ImageCard', memo: true}
);

export default ImageCard;

type ImageCardInnerProps = ImageCardProps & {
  tileMedia: NonNullable<ImageCardProps['tileMedia']>;
};

export const ImageCardInner: React.FunctionComponent<ImageCardInnerProps> =
  makeComp(
    props => {
      const {
        run,
        globalStep,
        width,
        height,
        mediaKey,
        mediaIndex,
        mediaWidth,
        mediaHeight,
        runSignature,
        maskOptions,
        controls,
        mediaPanelRef,
        actualSize,
        tileMedia,
      } = props;
      const {step, historyRow, objectURL, filePath} = tileMedia;

      const masksRef = useRef<HTMLDivElement | null>(null);
      const boxesRef = useRef<HTMLDivElement | null>(null);

      const showMetadataInline = width > 100; // if this is false, metadata will appear in a popup on the image
      const rolledBack = globalStep !== step;

      const mediaMetadata = getImageMedia({
        historyRow,
        mediaKey,
      }) as ImageMetadata | null;

      const paths = getImageInfo({
        imgMedia: mediaMetadata,
        step,
        mediaIndex,
        mediaKey,
        runSignature,
      });

      // Compute pixel offset for sprite based images
      //
      // This will be scaled to the card and used for display
      // to calculate to offset.
      const spritePixelOffset =
        paths.type === 'sprite' ? mediaIndex * width : 0;

      // Compute the pixel offset for the original sprite
      // This is used for downloading
      const originalWidth = mediaMetadata?.width ?? null;
      const originalPixelOffset =
        paths.type === 'sprite' && originalWidth != null
          ? mediaIndex * originalWidth
          : 0;

      const getWBFileDataParams = useMemo(
        () => ({
          currentMediaMetadata: mediaMetadata,
          mediaIndex,
          mediaKey,
          run,
        }),
        [mediaMetadata, mediaIndex, mediaKey, run]
      );
      const maskData = useMemo(
        () => getMaskData(getWBFileDataParams),
        [getWBFileDataParams]
      );
      const boundingBoxData = useMemo(
        () => getBBoxData(getWBFileDataParams),
        [getWBFileDataParams]
      );

      const mediaGroupingCount = mediaMetadata?.grouping || 1;

      const mediaSize =
        mediaWidth && mediaHeight
          ? {width: mediaWidth, height: mediaHeight}
          : null;

      const showImage = !!maskOptions?.showImage;

      const imgStyle: CSSProperties = {
        left: -(actualSize ? originalPixelOffset : spritePixelOffset),
        height: '100%',
        display: showImage ? 'initial' : 'none',
        ...(paths?.type !== 'sprite'
          ? {width: '100%', objectFit: 'contain'}
          : {}),
      };

      const titleLink = runLink(runSignature, run.displayName, {
        className: 'hide-in-run-page',
        target: '_blank',
        rel: 'noopener noreferrer',
      });

      // get captions for all images in the group
      const captions = makeCaptions(
        mediaMetadata,
        mediaIndex,
        mediaGroupingCount
      );

      const cardJSX = (
        <div className="image-card content-card" style={{width}}>
          {labelComponent(props, step, titleLink)}
          <div className="image-card-image" style={{width, height}}>
            <img
              data-test="image-card-img"
              src={objectURL}
              alt="card"
              style={imgStyle}
            />

            {rolledBack && (
              <div className={'content-card__fallback'}>
                <Popup
                  trigger={
                    <Icon
                      style={{color: 'white'}}
                      size="small"
                      name="exclamation circle"
                    />
                  }>
                  No media was found for step: {globalStep}
                  <br />
                  The most recent step: {step} is being displayed
                </Popup>
              </div>
            )}

            {filePath && originalWidth && (
              <LegacyWBIcon
                className="content-card__download"
                onClick={() => {
                  downloadSpritePart(
                    runSignature,
                    filePath,
                    originalPixelOffset,
                    originalWidth,
                    {boxesRef, masksRef}
                  );
                }}
                name="download"
              />
            )}

            {mediaSize && maskData.length > 0 && (
              <div ref={masksRef} className="segmentation-mask__container">
                {maskData.map(
                  ({key, wbFile, classLabels}) =>
                    _.includes(maskOptions?.maskKeys ?? [], key) && (
                      <SegmentationMaskFromFile
                        style={{position: 'absolute', top: 0}}
                        key={key}
                        mediaKey={mediaKey}
                        maskControls={
                          controls?.segmentationMaskControl?.toggles?.[
                            mediaKey
                          ]?.[key]
                        }
                        maskKey={key}
                        classLabels={classLabels}
                        runSignature={runSignature}
                        mask={wbFile}
                        cardSize={{width, height}}
                        mediaSize={mediaSize}
                        mediaPanelRef={mediaPanelRef}
                      />
                    )
                )}
              </div>
            )}
            {mediaSize && boundingBoxData.length > 0 && (
              <div ref={boxesRef} className="bounding-boxes__container">
                {boundingBoxData.map(({key, wbFile, classLabels}) => (
                  <BoundingBoxes
                    style={{position: 'absolute', top: 0}}
                    key={key}
                    mediaKey={mediaKey}
                    boxStyle={
                      controls?.boundingBoxControl?.styles?.[mediaKey]?.[key]
                    }
                    boxToggles={
                      controls?.boundingBoxControl?.toggles?.[mediaKey]?.[key]
                    }
                    boxSliders={controls?.boundingBoxControl?.sliders}
                    boxKey={key}
                    classLabels={classLabels}
                    runSignature={runSignature}
                    boxFileInfo={wbFile}
                    cardSize={{width, height}}
                    mediaSize={mediaSize}
                    mediaPanelRef={mediaPanelRef}
                  />
                ))}
              </div>
            )}
          </div>
          {/* CAPTIONS */}
          {showMetadataInline && captions.length > 0 && (
            <div className="image-card-caption">{captions}</div>
          )}
        </div>
      );

      const enableFullscreen = showImage;
      if (!enableFullscreen) {
        return cardJSX;
      }

      return (
        // Click image to show original size in modal
        <Modal
          size="fullscreen"
          trigger={cardJSX}
          content={
            <S.FullscreenWrapper>
              <S.FullscreenImageContainer>
                <S.FullscreenImage
                  style={{marginLeft: -originalPixelOffset}}
                  alt="card"
                  src={objectURL}
                />
              </S.FullscreenImageContainer>
            </S.FullscreenWrapper>
          }
        />
      );
    },
    {id: 'ImageCardInner', memo: true}
  );

function downloadSpritePart(
  runSignature: RunSignature,
  filename: string,
  offset: number,
  width: number,
  opts?: {
    boxesRef?: React.RefObject<HTMLDivElement>;
    masksRef?: React.RefObject<HTMLDivElement>;
  }
): void {
  // We must create a new Image element instead of using the one already in
  // the dom because the one in the dom will throw a CORS TaintedCanvas
  // Error when converted to a dataUrl
  const img = new Image();
  img.crossOrigin = 'anonymous';

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context == null) {
    throw new Error(`failed to get canvas rendering context`);
  }

  img.onload = () => {
    const height = img.height;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(img, offset, 0, width, height, 0, 0, width, height);

    const masksContainerEl = opts?.masksRef?.current ?? null;
    const bboxContainerEl = opts?.boxesRef?.current ?? null;
    drawCanvasesInElement(context, masksContainerEl);
    drawCanvasesInElement(context, bboxContainerEl);

    const dataUrl = canvas.toDataURL();
    downloadDataUrl(dataUrl, filename);
  };

  (async () => {
    try {
      const file = await fetchFileInfo(apolloClient, {
        ...runSignature,
        filename,
      });
      if (file?.directUrl) {
        img.src = file.directUrl;
      }
    } catch (err) {
      console.error(`ImageCard download error: ${err}`);
      captureError(err, 'Image Card Download');
      toast('File download failed');
    }
  })();
}

type BoundingBoxesProps = {
  style?: React.CSSProperties;
  classLabels:
    | {
        [key: number]: string;
      }
    | undefined;
  mediaKey: string;
  boxKey: string;
  boxFileInfo: WBFile;
  runSignature: RunSignature;
  mediaSize: {
    width: number;
    height: number;
  };
  cardSize: {
    width: number;
    height: number;
  };
  mediaPanelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
  boxStyle?: Style;
  boxToggles?: {
    [classOrAll: string]: BoundingBoxClassControl;
  };
  boxSliders?: {
    [sliderKey: string]: BoundingBoxSliderControl;
  };
};

const BoundingBoxes: React.FC<BoundingBoxesProps> = makeComp(
  props => {
    const {
      runSignature,
      boxFileInfo,
      mediaKey,
      mediaPanelRef,
      boxKey,
      cardSize,
      style,
      mediaSize,
      boxStyle,
      boxSliders,
      boxToggles,
    } = props;
    const dispatch = useDispatch();

    const [boxData, setBoxData] = React.useState<BoundingBoxFileData | null>(
      null
    );
    const [fileMetadata, setFileMetadata] = React.useState<FileInfo | null>(
      null
    );
    const onSuccess = useCallback((b: BoundingBoxFileData, m: FileInfo) => {
      setBoxData(b);
      setFileMetadata(m);
    }, []);

    const classStates = useMemo(
      () =>
        Object.fromEntries(
          _.map(boxData?.class_labels, (label, id) => [
            id,
            {name: label, color: boxColor(parseInt(id, 10))},
          ])
        ),
      [boxData]
    );

    const classOverlayStates = useMemo(
      () =>
        Object.fromEntries(
          _.map(boxToggles, (controls, id) => [id, {opacity: 1, ...controls}])
        ),
      [boxToggles]
    );

    useLoadFile(runSignature, boxFileInfo.path, {
      onSuccess,
      responseType: 'json',
    });

    useEffect(() => {
      if (fileMetadata == null || boxData == null) {
        return;
      }

      dispatch(
        loadBoxData({
          mediaKey,
          panelID: mediaPanelRef.id,
          // Use the file ID as the identifier instead of
          // combining mediaKey, boxKey, step, index, run.id
          mediaID: fileMetadata.id,
          boxData: boxData.box_data,
        })
      );

      return () => {
        if (fileMetadata == null || boxData == null) {
          return;
        }
        dispatch(
          clearBoxData({
            mediaKey,
            panelID: mediaPanelRef.id,
            mediaID: fileMetadata.id,
          })
        );
      };
    }, [boxData, fileMetadata, mediaPanelRef.id, mediaKey, dispatch]);

    return (
      <div
        key={boxKey}
        style={{
          width: cardSize.width,
          height: cardSize.height,
          ...style,
        }}>
        {/* TODO: Don't make the canvas scale make the drawing scale */}
        {boxData && (
          <BoundingBoxesCanvas
            mediaSize={mediaSize}
            boxData={boxData.box_data}
            classStates={classStates}
            bboxControls={{
              type: 'box',
              classSearch: '',
              classSetID: '',
              name: '',
              disabled: false,
              classOverlayStates,
              lineStyle: boxStyle?.lineStyle ?? 'line',
            }}
            sliderControls={boxSliders}
          />
        )}
      </div>
    );
  },
  {id: 'BoundingBoxes', memo: true}
);

type SegmentationMaskFromFileProps = {
  style?: React.CSSProperties;
  classLabels:
    | {
        [key: number]: string;
      }
    | undefined;
  mediaKey: string;
  maskKey: string;
  mask: WBFile;
  runSignature: RunSignature;
  mediaSize: {
    width: number;
    height: number;
  };
  cardSize: {
    width: number;
    height: number;
  };
  mediaPanelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
  maskControls?: {
    [classOrAll: string]: MaskControl;
  };
};

/**
 * Renders segmentation mask from WBFile
 */
const SegmentationMaskFromFile: React.FC<SegmentationMaskFromFileProps> =
  makeComp(
    props => {
      const {
        maskControls,
        mask,
        maskKey,
        runSignature,
        cardSize,
        mediaSize,
        style,
        classLabels,
      } = props;

      const [directURL, setDirectURL] = useState<string | undefined>(undefined);
      const onSuccess = useCallback(
        (__, metadata) => setDirectURL(metadata.directUrl),
        []
      );

      const loaderStyle = useMemo(
        () => ({...cardSize, ...style}),
        [cardSize, style]
      );

      const classOverlay = useMemo(() => maskControls ?? {}, [maskControls]);

      const classState = useMemo(
        () =>
          Object.fromEntries(
            _.map(classLabels, (name, classID) => {
              const [r, g, b] = segmentationMaskColor(parseInt(classID, 10));
              const color = `rgb(${r}, ${g}, ${b})`;
              return [classID, {name, color}];
            })
          ),
        [classLabels]
      );

      useLoadFile(runSignature, mask.path, {
        onSuccess,
        responseType: 'blob',
      });

      if (directURL == null) {
        return <div />;
      }

      return (
        <SegmentationMaskLoader
          key={maskKey}
          style={loaderStyle}
          mediaSize={mediaSize}
          classOverlay={classOverlay}
          classState={classState}
          directUrl={directURL}
        />
      );
    },
    {id: 'SegmentationMaskFromFile', memo: true}
  );

type ImageInfo = {
  imgSrc: string;
  imgFile: string;
  type: 'single' | 'sprite';
};

type GetImageInfoParams = {
  imgMedia: ImageMetadata | null;
  step: number;
  mediaIndex: number;
  mediaKey: string;
  runSignature: RunSignature;
};

export function getImageInfo({
  imgMedia,
  step,
  mediaIndex,
  mediaKey,
  runSignature,
}: GetImageInfoParams): ImageInfo {
  if (imgMedia?.path) {
    return {
      imgSrc: runFileSource(runSignature, imgMedia.path),
      imgFile: imgMedia.path,
      type: 'single',
    };
  }

  let fileParams: Array<string | number>;
  let type: ImageInfo['type'];
  if (imgMedia?._type === 'images/separated') {
    // This is the new format for a collection images which
    // is a set of individual images, not a sprite
    fileParams = [mediaKey, step, mediaIndex];
    type = 'single';
  } else {
    // Our old multiple image format expected a sprite
    // we use the mediaIndex to determine the pixel offset
    // for the image we want
    fileParams = [mediaKey, step];
    type = 'sprite';
  }

  const format = imgMedia?.format ?? 'jpg';

  return {
    imgSrc: mediaSrc(runSignature, fileParams, 'images', format),
    imgFile: mediaFilePath(fileParams, 'images', format),
    type,
  };
}

type WBFileData = {
  key: string;
  wbFile: WBFile;
  classLabels: {
    [key: number]: string;
  };
};

type GetWBFileByKeyFn = (
  mediaMetadata: ImageMetadata,
  mediaIndex: number
) => Struct<WBFile>;

type GetWBFileDataParams = {
  currentMediaMetadata: ImageMetadata | null;
  mediaIndex: number;
  mediaKey: string;
  run: RunWithHistoryAndMediaWandb;
};

function getMaskData(params: GetWBFileDataParams): WBFileData[] {
  return getWBFileData(getMasks, WANDB_MASK_CLASS_LABEL_KEY, params);
}
function getBBoxData(params: GetWBFileDataParams): WBFileData[] {
  return getWBFileData(getBoundingBoxes, WANDB_BBOX_CLASS_LABEL_KEY, params);
}

function getWBFileData(
  getWBFileByKeyFn: GetWBFileByKeyFn,
  classLabelKey: string,
  {currentMediaMetadata, mediaIndex, mediaKey, run}: GetWBFileDataParams
): WBFileData[] {
  if (currentMediaMetadata == null) {
    return [];
  }

  const wbFileByKey = getWBFileByKeyFn(currentMediaMetadata, mediaIndex);

  return Object.entries(wbFileByKey).map(([key, wbFile]) => ({
    key,
    wbFile,
    classLabels: getSingletonValue(
      run,
      classLabelKey,
      mediaKey + WANDB_DELIMETER + key
    ) as {[key: number]: string},
  }));
}

function drawCanvasesInElement(
  context: CanvasRenderingContext2D,
  el: HTMLDivElement | null
): void {
  if (el == null) {
    return;
  }

  const allCanvases = el.querySelectorAll('canvas');

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < allCanvases.length; i++) {
    const canvas = allCanvases[i];
    context.drawImage(canvas, 0, 0);
  }
}
