import {ApolloError} from 'apollo-client';
import copyText from 'copy-to-clipboard';
import download from 'downloadjs';
import {compact} from 'lodash';
import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Dropdown,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Popup,
  Radio,
  StrictInputProps,
  Table,
} from 'semantic-ui-react';
import {AddUserModal} from '../components/AddUserModal';
import {CreateTeamButton} from '../components/CreateTeamModal';
import {toast} from '../components/elements/Toast';
import LinkButton from '../components/LinkButton';
import NoMatch from '../components/NoMatch';
import WandbLoader from '../components/WandbLoader';
import {envIsLocal, urlPrefixed} from '../config';
import {
  useAllEntitiesQuery,
  useDeleteUserMutation,
  useFrontendHostQuery,
  usePurgeUserMutation,
  User,
  useResetPasswordMutation,
  useSearchUsersQuery,
  useSetUserAdminMutation,
  useUndeleteUserMutation,
  useUserLimitReachedQuery,
} from '../generated/graphql';
import {debugCollector} from '../setup';
import {useViewer} from '../state/viewer/hooks';
import {isWandbDomainEmail} from '../util/admin';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {TargetBlank} from '../util/links';
import makeComp from '../util/profiler';
import {teamMembers, teamPage} from '../util/urls';
import * as S from './UsersAdminPage.styles';

const RE_ENABLED_MESSAGE =
  'User re-enabled. To send an invite email or copy an invite link, see the Action menu for this user.';

const getErrorMessage = (err: ApolloError) => {
  const text = extractErrorMessageFromApolloError(err);
  if (!text) {
    return undefined;
  }

  return {
    text,
    type: 'error',
  } as const;
};

const HIDDEN_USERS = ['local@wandb.com', 'restore@wandb.com'];
interface MessageObject {
  text: ReactNode;
  type: 'error' | 'warning' | 'success';
}

const adminToggleDisabled = (user: Partial<User>): boolean => {
  // non-deleted user who is either internal (i.e. have ...@wandb.com)
  // or in local instance can be set as an admin
  const isInternalUser = !!user.email && isWandbDomainEmail(user.email);
  return !!user.deletedAt || !(envIsLocal || isInternalUser);
};

interface UsersTableProps {
  userLimitReached: boolean;
  users: Array<Partial<User> & {id: string}>;
  onUsersChanged(): void;
  setMessage(error?: MessageObject): void;
  sendInvite(email: string): Promise<void>;
}

const UsersTable: React.FC<UsersTableProps> = makeComp(
  props => {
    const {userLimitReached, users, onUsersChanged, setMessage, sendInvite} =
      props;

    const viewer = useViewer();

    const [showDisableModalForUser, setShowDisableModalForUser] = useState<{
      id: string;
      email?: string | null;
    } | null>(null);

    const [deleteUserModalForUser, setDeleteUserModalForUser] = useState<{
      email: string;
      username: string;
    } | null>(null);

    const [purgeUsername, setPurgeUsername] = useState<string>('');
    const [purgeUserEmail, setPurgeUserEmail] = useState<string>('');

    const [deleteUser] = useDeleteUserMutation({
      context: propagateErrorsContext(),
    });
    const [resetPassword] = useResetPasswordMutation({
      context: propagateErrorsContext(),
    });
    const [setUserAdmin] = useSetUserAdminMutation({
      context: propagateErrorsContext(),
    });
    const [undeleteUser] = useUndeleteUserMutation({
      context: propagateErrorsContext(),
    });
    const [purgeUser] = usePurgeUserMutation({
      context: propagateErrorsContext(),
    });

    const closeDeleteUserModal = useCallback(() => {
      setDeleteUserModalForUser(null);
      setPurgeUsername('');
      setPurgeUserEmail('');
    }, []);

    const getTokenURL = async (email: string, resetPasswordLink: boolean) => {
      const resp = await fetch(urlPrefixed('/admin/token'), {
        method: 'POST',

        // invite and password reset actually use the same token claims:
        // the difference is just whether the user already exists or not
        body: JSON.stringify({email}),
      });
      const data: {token: string | null} = await resp.json();

      let inviteURL =
        document.location.origin +
        (resetPasswordLink ? '/change-password' : '/signup');

      if (data.token != null) {
        inviteURL += `?jwt=${data.token}`;
      }

      return inviteURL;
    };

    const copy = async (text: string) => {
      try {
        // try to use the modern clipboard API
        await navigator.clipboard.writeText(text);
      } catch {
        // modern API failed, use the fallback
        console.log('Clipboard API unavailable, falling back to execCommand');
        const copied = copyText(text);

        if (!copied) {
          throw new Error('execCommand copy failed');
        }
      }
    };

    const copyTokenLink = async (email: string, resetPasswordLink: boolean) => {
      let inviteURL: string;
      try {
        inviteURL = await getTokenURL(email, resetPasswordLink);
      } catch (err) {
        console.error(err);
        setMessage({
          text: `Error retrieving ${
            resetPasswordLink ? 'password reset' : 'invite'
          } link.`,
          type: 'error',
        });
        return;
      }
      try {
        await copy(inviteURL);
        setMessage({
          text: `${
            resetPasswordLink ? 'Password reset' : 'Invite'
          } link copied to clipboard`,
          type: 'success',
        });
      } catch {
        setMessage({
          text: (
            <>
              Successfully retrieved{' '}
              {resetPasswordLink ? 'password reset' : 'invite'} link but
              couldn't copy to clipboard. You can copy the{' '}
              <TargetBlank href={inviteURL}>invite URL</TargetBlank> manually
              instead.
            </>
          ),
          type: 'warning',
        });
      }
    };

    return (
      <>
        <Confirm
          open={showDisableModalForUser != null}
          header={`Disable ${
            showDisableModalForUser && showDisableModalForUser.email
          }?`}
          content={
            <Modal.Content>
              <p>
                Are you sure you want to disable this user? Disabled users
                remain in the system along with their work, but cannot log in.
                They do not count against your seat limit.
              </p>
              <p>
                You can always re-enable a disabled user, as long as you have an
                available seat.
              </p>
            </Modal.Content>
          }
          onCancel={() => setShowDisableModalForUser(null)}
          onConfirm={() =>
            showDisableModalForUser &&
            deleteUser({
              variables: {
                id: showDisableModalForUser.id,
              },
            })
              .then(() => {
                onUsersChanged();
                setShowDisableModalForUser(null);
                setMessage({
                  type: 'success',
                  text: 'User was disabled successfully.',
                });
              })
              .catch(err => setMessage(getErrorMessage(err)))
          }
          confirmButton={
            <Button
              data-test="confirm-disable-button"
              color="red"
              content="Disable User"
              primary={false}
            />
          }
        />
        <Confirm
          open={deleteUserModalForUser != null}
          header={`Delete ${deleteUserModalForUser?.email}?`}
          content={
            <Modal.Content>
              <p>
                Are you sure you want to delete this user? Deleting a user
                removes all user data permanently. This action cannot be undone.
              </p>
              <p>
                If you wish to proceed, please enter{' '}
                {deleteUserModalForUser?.username === ''
                  ? 'the'
                  : 'both the username and'}{' '}
                user email below. This information needs to match our records
                for the delete to go through.
              </p>
              <S.SearchBarWrapper>
                {deleteUserModalForUser?.username !== '' && (
                  <S.InputWrapper
                    value={purgeUsername}
                    data-test="delete-username"
                    placeholder="Username"
                    onChange={
                      ((e, {value}) =>
                        setPurgeUsername(value)) as StrictInputProps['onChange']
                    }
                  />
                )}
                <S.InputWrapper
                  value={purgeUserEmail}
                  data-test="delete-email"
                  placeholder="Email"
                  onChange={
                    ((e, {value}) =>
                      setPurgeUserEmail(value)) as StrictInputProps['onChange']
                  }
                />
              </S.SearchBarWrapper>
            </Modal.Content>
          }
          onCancel={closeDeleteUserModal}
          onConfirm={async () => {
            if (deleteUserModalForUser == null) {
              return;
            }
            if (
              deleteUserModalForUser.username !== purgeUsername ||
              deleteUserModalForUser.email !== purgeUserEmail
            ) {
              toast('Please enter the correct username and email.');
              return;
            }
            try {
              await purgeUser({
                variables: {
                  username: purgeUsername,
                  email: purgeUserEmail,
                },
              });
              onUsersChanged();
              setMessage({
                type: 'success',
                text: 'User was deleted successfully.',
              });
            } catch (err) {
              setMessage(getErrorMessage(err));
            } finally {
              closeDeleteUserModal();
            }
          }}
          confirmButton={
            <Button
              data-test="confirm-delete-button"
              color="red"
              content="Delete User"
              primary={false}
            />
          }
        />
        <Table celled className="users" fixed data-test="users-table">
          {viewer && users.length > 0 ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="name">Name</Table.HeaderCell>
                  <Table.HeaderCell className="email">Email</Table.HeaderCell>
                  <Table.HeaderCell className="username">
                    Username
                  </Table.HeaderCell>
                  <Popup
                    trigger={
                      <Table.HeaderCell className="admin" width={2}>
                        Admin <Icon name="question circle" color="grey" />
                      </Table.HeaderCell>
                    }
                    content="Admin users can change system settings, access all projects and runs, and create other admins."
                  />
                  <Table.HeaderCell className="actions" width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users.map(user => (
                  <Table.Row
                    data-test={`user-row-${user.email}`}
                    key={user.id}
                    className="user">
                    <Table.Cell className="name" disabled={!!user.deletedAt}>
                      {user.name}
                    </Table.Cell>
                    <Table.Cell className="email" disabled={!!user.deletedAt}>
                      {user.email}
                    </Table.Cell>
                    <Table.Cell
                      className="username"
                      disabled={!!user.deletedAt}>
                      {user.username}
                    </Table.Cell>
                    {viewer.id === user.id ? (
                      <Popup
                        trigger={
                          <Table.Cell className="admin" width={2}>
                            <Radio toggle disabled checked={!!user.admin} />
                          </Table.Cell>
                        }
                        content="You cannot revoke your own admin privileges."
                      />
                    ) : (
                      <Table.Cell
                        disabled={adminToggleDisabled(user)}
                        className="admin">
                        <Radio
                          data-test="admin-toggle"
                          toggle
                          checked={!!user.admin}
                          disabled={adminToggleDisabled(user)}
                          onClick={() => {
                            setUserAdmin({
                              variables: {
                                id: user.id,
                                admin: !user.admin,
                              },
                            }).catch(setMessage);
                          }}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell
                      className="actions"
                      style={{overflow: 'visible'}}>
                      <Dropdown
                        data-test="user-actions-button"
                        button
                        icon="bars"
                        className="icon">
                        <Dropdown.Menu style={{right: 0, left: 'auto'}}>
                          {!!user.deletedAt ? (
                            <Dropdown.Item
                              data-test="enable-user"
                              disabled={
                                userLimitReached || viewer.id === user.id
                              }
                              icon="unlock"
                              text="Re-Enable"
                              onClick={() =>
                                undeleteUser({
                                  variables: {id: user.id},
                                })
                                  .then(() => {
                                    setMessage({
                                      type: 'success',
                                      text: RE_ENABLED_MESSAGE,
                                    });
                                    onUsersChanged();
                                  })
                                  .catch(err =>
                                    setMessage(getErrorMessage(err))
                                  )
                              }
                            />
                          ) : (
                            <Dropdown.Item
                              data-test="disable-user"
                              disabled={viewer.id === user.id}
                              icon="lock"
                              text="Disable"
                              onClick={() => setShowDisableModalForUser(user)}
                            />
                          )}
                          <Dropdown.Item
                            data-test="delete-user"
                            disabled={viewer.id === user.id}
                            icon="delete"
                            text="Delete"
                            onClick={() =>
                              user.email &&
                              setDeleteUserModalForUser({
                                email: user.email,
                                username: user.username || '',
                              })
                            }
                          />
                          <Dropdown.Item
                            data-test="send-invite"
                            icon="mail"
                            text="Send Invite Email"
                            disabled={!!user.deletedAt}
                            onClick={() => user.email && sendInvite(user.email)}
                          />
                          <Dropdown.Item
                            data-test="copy-invite-link"
                            icon="copy"
                            text="Copy Invite Link"
                            disabled={!!user.deletedAt}
                            onClick={() =>
                              user.email && copyTokenLink(user.email, false)
                            }
                          />
                          <Dropdown.Item
                            data-test="send-password-reset"
                            icon="mail"
                            text="Send Password Reset Email"
                            disabled={!!user.deletedAt}
                            onClick={() =>
                              user.email &&
                              resetPassword({
                                variables: {email: user.email},
                              })
                                .then(() => {
                                  setMessage({
                                    text: `Sent pssword reset email to ${user.email}`,
                                    type: 'success',
                                  });
                                })
                                .catch(e => {
                                  setMessage(getErrorMessage(e));
                                })
                            }
                          />
                          <Dropdown.Item
                            data-test="copy-password-reset-link"
                            icon="copy"
                            text="Copy Password Reset Link"
                            disabled={!!user.deletedAt}
                            onClick={() =>
                              user.email && copyTokenLink(user.email, true)
                            }
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          ) : (
            <Table.Body className="no-users">
              <Table.Row>
                <Table.Cell textAlign="center">
                  No users found matching query.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </>
    );
  },
  {id: 'UsersTable'}
);

const TeamsTab: React.FC = makeComp(
  () => {
    const history = useHistory();

    const {loading, data, refetch} = useAllEntitiesQuery();
    const teams = compact(
      data?.entities?.edges.map(e => e.node).filter(n => n?.isTeam) ?? []
    );

    return (
      <>
        <S.SearchBarWrapper>
          <CreateTeamButton
            onCreate={() => refetch()}
            size="medium"
            color="blue"
          />
        </S.SearchBarWrapper>
        {loading ? (
          <S.LoaderWrapper>
            <WandbLoader />
          </S.LoaderWrapper>
        ) : (
          <Table celled className="users" fixed>
            {teams.length > 0 && (
              <>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="name" width={4}>
                      Team Name
                    </Table.HeaderCell>
                    <Table.HeaderCell className="name">
                      Members
                    </Table.HeaderCell>
                    <Table.HeaderCell className="actions" width={1} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {teams.map(t => (
                    <Table.Row data-test={`team-row-${t.name}`} key={t.id}>
                      <Table.Cell>
                        <TargetBlank href={teamPage(t.name)}>
                          {t.name}
                        </TargetBlank>
                      </Table.Cell>
                      <Table.Cell>
                        {t.members.map(m => m.username || m.email).join(', ')}
                      </Table.Cell>
                      <Table.Cell
                        className="actions"
                        style={{overflow: 'visible'}}>
                        <Dropdown
                          data-test="user-actions-button"
                          button
                          icon="bars"
                          className="icon">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              data-test="disable-user"
                              icon="cog"
                              text="Manage team"
                              onClick={() => history.push(teamMembers(t.name))}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            )}
          </Table>
        )}
      </>
    );
  },
  {id: 'TeamsTab'}
);

const UsersAdminPage: FC = makeComp(
  () => {
    const [query, setQuery] = useState('');
    const [message, setMessage] = useState<MessageObject>();
    const [showDisabledUsers, setShowDisabledUsers] = useState(false);
    const [activeTab, setActiveTab] = useState<'users' | 'teams'>('users');
    const [showModal, setShowModal] = useState<boolean>(false);

    const userLimitReachedQuery = useUserLimitReachedQuery({
      fetchPolicy: 'cache-and-network',
    });
    const frontendhostQuery = useFrontendHostQuery({});

    const viewer = useViewer();
    const users = useSearchUsersQuery({
      variables: {query},
      fetchPolicy: 'cache-and-network',
    });

    const userLimitReached =
      userLimitReachedQuery.data?.serverInfo?.userLimitReached ?? false;

    const sendInvite = (email: string) =>
      fetch(urlPrefixed('/admin/invite_email'), {
        method: 'POST',
        body: JSON.stringify({email}),
      })
        .then(() => {
          setMessage({
            text: `Sent invite to ${email}`,
            type: 'success',
          });
        })
        .catch(err => {
          console.error(err);
          setMessage({
            text: 'Error sending invite.',
            type: 'error',
          });
        });

    useEffect(() => {
      if (envIsLocal && userLimitReachedQuery.error) {
        setMessage({
          text: `Failed to connect to the backend.`,
          type: 'error',
        });
      }
    }, [userLimitReachedQuery]);

    if (!viewer) {
      return <WandbLoader />;
    }
    if (!viewer.admin) {
      return <NoMatch />;
    }

    // make sure the default user and deleted/purged users aren't included in the list of users in the UI
    const usersData = compact(
      (users.data?.users?.edges || []).map(userEdge => userEdge.node)
    ).filter(
      user =>
        user.email != null &&
        !HIDDEN_USERS.includes(user.email) &&
        (showDisabledUsers || user.deletedAt == null)
    );

    return (
      <S.PageWrapper>
        <Container>
          <Header as="h1">Manage Users and Teams</Header>
          {users.loading && !users.data && <WandbLoader />}

          <S.ToggleButtonGroup>
            <Button
              data-test="show-users"
              active={activeTab === 'users'}
              onClick={() => setActiveTab('users')}>
              Users
            </Button>
            <Button
              data-test="show-teams"
              active={activeTab === 'teams'}
              onClick={() => setActiveTab('teams')}>
              Teams
            </Button>
          </S.ToggleButtonGroup>

          <Message
            hidden={!message}
            error={message?.type === 'error'}
            warning={message?.type === 'warning'}
            success={message?.type === 'success'}
            onDismiss={() => setMessage(undefined)}>
            {message && ['error', 'warning'].indexOf(message.type) !== -1 && (
              <Icon name="exclamation" />
            )}
            {message?.text}
            {message?.type === 'error' && envIsLocal && (
              <>
                {' '}
                If this error persists, please contact{' '}
                <a href="mailto:support@wandb.com">support@wandb.com</a> with a{' '}
                <LinkButton
                  onClick={() => {
                    fetch(urlPrefixed('/system-admin/api/debug'), {
                      method: 'POST',
                      headers: {
                        'content-type': 'application/json',
                      },
                      body: JSON.stringify(debugCollector.dump()),
                    })
                      .then(resp => resp.blob())
                      .then(debugBlob => {
                        download(debugBlob, 'debug.zip', 'application/zip');
                      });
                  }}>
                  debug bundle
                </LinkButton>
                .
              </>
            )}
          </Message>

          <Message hidden={!users.error} error>
            {extractErrorMessageFromApolloError(users.error)}
          </Message>

          <Message
            hidden={
              (frontendhostQuery.data?.serverInfo?.frontendHost ?? '').indexOf(
                document.location.origin
              ) > -1
            }
            error>
            The Frontend Host setting doesn't match the current host in the
            browser, so it's probably incorrect.{' '}
            <strong>
              Links in emails and file downloads from the API will not work.
            </strong>{' '}
            {/* eslint-disable-next-line wandb/no-a-tags */}
            <a href={urlPrefixed('/system-admin')}>Click here to update.</a>
          </Message>

          {activeTab === 'users' ? (
            <>
              <S.SearchBarWrapper>
                <Input
                  icon="search"
                  data-test="search-users"
                  value={query}
                  onChange={(e, {value}) => setQuery(value)}
                  placeholder="Search"
                  style={{flex: '0 0 250px'}}
                />
                <S.AddUserWrapper>
                  <AddUserModal
                    open={showModal}
                    query={query}
                    onOpen={() => setShowModal(true)}
                    onClose={() => {
                      userLimitReachedQuery.refetch();
                      setShowModal(false);
                    }}
                    setMessage={setMessage}
                    trigger={
                      <Button data-test="add-user" primary>
                        Add User
                      </Button>
                    }
                  />
                </S.AddUserWrapper>
                <Checkbox
                  data-test="show-disabled-users"
                  label="Show Disabled Users"
                  toggle
                  checked={showDisabledUsers}
                  onClick={() => setShowDisabledUsers(prev => !prev)}
                />
              </S.SearchBarWrapper>
              <UsersTable
                userLimitReached={!!userLimitReached}
                users={usersData}
                onUsersChanged={() => {
                  userLimitReachedQuery.refetch();
                }}
                setMessage={setMessage}
                sendInvite={sendInvite}
              />
            </>
          ) : (
            <TeamsTab />
          )}
        </Container>
      </S.PageWrapper>
    );
  },
  {id: 'UsersAdminPage'}
);

export default UsersAdminPage;
