import * as Sentry from '@sentry/browser';
import {getFullStoryUrl} from './util/integrations';
import config, {backendHost, envIsLocal, envIsCloudOnprem} from './config';

const SegmentIntegration = require('@segment/analytics.js-integration-segmentio');
const Analytics = require('@segment/analytics.js-core/build/analytics');

// thirdPartyAnalyticsOK is set by index.html
if ((window as any).thirdPartyAnalyticsOK) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENVIRONMENT,
    release: config.GIT_TAG,
    normalizeDepth: Infinity,
    beforeSend(event, hint) {
      const error = hint && hint.originalException;
      event.extra = event.extra || {};
      event.extra.fullstory =
        getFullStoryUrl() || 'current session URL API not ready';

      if (window.FS && window.FS.event) {
        window.FS.event('Application Error', {
          name: typeof error === 'string' ? error : error?.name,
          message: typeof error === 'string' ? error : error?.message,
          fileName: typeof error !== 'string' && error?.message,
          stack: typeof error !== 'string' && error?.stack,
          sentryEventId: hint?.event_id,
        });
      }

      return event;
    },
    ignoreErrors: [
      // From RO author: "This error means that ResizeObserver was not able to deliver all observations within
      // a single animation frame. It is benign (your site will not break)."
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',

      // This is MSFT Safe Link agent with poor JS compatibility
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/4
      // (as mentioned in that thread, confirmed the IPs via who.is)
      'Object Not Found Matching Id',

      // Displayed on every rate-limited request
      'status code 429',
    ],
  });
}

// analyticsOK is set by index.html
if ((envIsLocal || envIsCloudOnprem) && (window as any).analyticsOK) {
  const apiHost = backendHost().replace('https://', '') + '/analytics';
  const integrationSettings = {
    'Segment.io': {
      apiHost,
      retryQueue: true,
    },
  };
  window.analytics = new Analytics();
  window.analytics.use(SegmentIntegration);
  window.analytics.init(integrationSettings);
}
