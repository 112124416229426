const root = 'https://docs.wandb.ai/';

// Use this directly if you know the name of the docUrl you want.
const docUrl = {
  root,
  config: root + 'library/config',
  logs: root + 'library/log',
  save: root + 'library/save',
  started: root + 'quickstart',
  examples: 'https://github.com/wandb/examples',
  gettingStarted: root + 'quickstart',
  loggingObjects: root + 'library/log#logging-objects',
  summaryMetric: root + 'library/log#summary-metrics',
  api: root + 'library/api',
  audio: root + 'library/log#media',
  images: root + 'library/log#images-and-overlays',
  video: root + 'library/log#media',
  table: root + 'library/log#media',
  object3D: root + 'library/log#3d-visualizations',
  molecule: root + 'library/log#media',
  html: root + 'library/log#media',
  plotly: root + 'library/log#custom-charts',
  reports: root + 'reports',
  sweeps: root + 'sweeps',
  artifacts: root + 'artifacts',
  artifactsMetadata: root + 'artifacts/api#updating-artifacts',
  compareMetrics: root + 'app/features/panels/compare-metrics/reference',
  customCharts: root + 'app/features/custom-charts',
  limits: root + 'library/advanced/limits',
  codeComparer: root + 'app/features/panels/code#code-comparer',
  pythonApi: root + 'library/api',
  parameterImportance: root + 'app/features/panels/parameter-importance',
  integrations: root + 'guides/integrations',
  fileExport: root + 'library/public-api-guide#download-all-files-from-a-run',
  teams: root + 'ref/app/features/teams',
  teamTrials: root + 'ref/app/features/teams#team-trials',
  publicApi: root + 'ref/python/public-api',
  workspaces: root + 'ref/app/pages/workspaces',
};

// Use this if you need an index lookup
export function lookupDocUrl(name: string) {
  return (docUrl as {[key: string]: string | undefined})[name];
}

export default docUrl;
