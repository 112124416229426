import * as S from './InlineStringEditor.styles';
import * as QueryEditorStyles from '../ExpressionEditor.styles';

import React from 'react';
import {Popup} from 'semantic-ui-react';
import * as SemanticHacks from '../../../util/semanticHacks';
import WBSuggester, {
  WBSuggesterOptionFetcher,
} from '../../elements/WBSuggester';
import {WBMenuOption} from '@wandb/ui';
import makeComp from '../../../util/profiler';

interface InlineStringEditorProps {
  value: string;
  disabled?: boolean;
  noQuotes?: boolean;
  extraSuggestor?: JSX.Element;
  autocompleteOptions?: WBMenuOption[] | WBSuggesterOptionFetcher;
  autofocus?: boolean;
  elementType: 'node' | 'op' | 'panelOp';
  dataTest?: string;
  defaultCursorPos?: number;
  onFocus?(e: React.FocusEvent<HTMLElement>): void;
  onBlur?(): void;
  onKeyDown?(event: React.KeyboardEvent): void;
  setValue(val: string): void;
  onBufferChange?(val: string): void;
}

const InlineStringEditor: React.FC<InlineStringEditorProps> = makeComp(
  props => {
    const ref = React.useRef<HTMLSpanElement>(null);
    const [autocompleterQuery, setAutocompleterQuery] = React.useState('');
    const [autocompleterOpen, setAutocompleterOpen] = React.useState(false);
    React.useEffect(() => {
      if (props.autofocus) {
        ref.current?.focus();
      }
    }, [props.autofocus]);
    let editor = (
      <QueryEditorStyles.ElementSpan
        spellCheck="false"
        elementType={props.elementType}>
        <WBSuggester
          className={SemanticHacks.BLOCK_POPUP_CLICKS_CLASSNAME}
          options={props.autocompleteOptions}
          dataTest={props.dataTest}
          onSelect={v => {
            setAutocompleterOpen(false);
            props.setValue(v as string);
          }}
          query={autocompleterQuery}
          open={autocompleterOpen}
          onParentScroll={() => setAutocompleterOpen(false)}>
          {({inputRef}) => (
            <S.InlineContentEditable
              innerRef={node => {
                (ref as any).current = node;
                inputRef(node);
              }}
              value={props.value}
              disabled={props.disabled}
              onFocus={e => {
                setAutocompleterOpen(true);
                // Set query to empty so we provide all possible results
                setAutocompleterQuery('');
                // And highlight all
                // setTimeout(() => {
                //   document.execCommand('selectAll');
                // }, 1);

                if (props.defaultCursorPos != null) {
                  const tag = ref.current!;
                  const setpos = document.createRange();
                  const set = window.getSelection();

                  if (tag.childNodes.length > 0) {
                    setpos.setStart(
                      tag.childNodes[0],

                      // if the cursor position is negative, we want to start at the end
                      props.defaultCursorPos < 0
                        ? tag.childNodes[0].textContent?.length || 10000000
                        : props.defaultCursorPos
                    );
                    setpos.collapse(true);
                    set?.removeAllRanges();
                    set?.addRange(setpos);
                  }
                }

                props.onFocus?.(e);
              }}
              onKeyDown={props.onKeyDown}
              onBlur={() => {
                setAutocompleterOpen(false);
                props.onBlur?.();
              }}
              onTempChange={v => {
                setAutocompleterQuery(v);
                setAutocompleterOpen(true);
                props.onBufferChange?.(v);
              }}
              onChange={v => {
                if (props.value !== v) {
                  props.setValue(v);
                }
              }}></S.InlineContentEditable>
          )}
        </WBSuggester>
      </QueryEditorStyles.ElementSpan>
    );
    if (props.extraSuggestor != null) {
      editor = (
        <Popup
          open={autocompleterOpen}
          position="bottom right"
          content={props.extraSuggestor}
          trigger={editor}
        />
      );
    }
    return (
      <>
        {!props.noQuotes && <span style={{pointerEvents: 'none'}}>"</span>}
        {editor}
        {!props.noQuotes && <span style={{pointerEvents: 'none'}}>"</span>}
      </>
    );
  },
  {id: 'InlineStringEditor'}
);

export default InlineStringEditor;
