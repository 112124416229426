import {WBIcon, WBSelect} from '@wandb/ui';
import styled, {css} from 'styled-components/macro';
import * as globals from '../css/globals.styles';
import {GLOBAL_COLORS} from '../util/colors';
import EditableField from './EditableField';
import QueryEditor from './Vega3/QueryEditor';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  background: white;
  z-index: 2147483605;
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  font-size: 14px;
  position: relative;
  padding: 0 4px;
`;
export const Tab = styled.div<{active?: boolean}>`
  padding: 4px 12px;
  color: ${globals.gray500};
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  &:hover {
    color: #888;
  }
  ${props =>
    props.active &&
    css`
      color: black !important;
    `}
`;

export const Caret = styled(WBIcon)`
  color: #999;
`;

export const WidthResizer = styled.div<{resizing: boolean; cursor: string}>`
  width: 5px;
  height: 100%;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 1;
  cursor: ${props => props.cursor};
  &::before {
    display: block;
    content: '';
    width: 1px;
    height: 100%;
    margin-left: 2px;
    background: ${GLOBAL_COLORS.outline.toString()};
  }
  ${props =>
    props.resizing &&
    css`
      &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vw;
        cursor: ew-resize;
      }
    `}
`;
export const HeightResizer = styled.div<{resizing: boolean; cursor: string}>`
  height: 5px;
  margin-top: -2px;
  margin-bottom: -2px;
  z-index: 1;
  cursor: ${props => props.cursor};
  &::before {
    display: block;
    content: '';
    height: 1px;
    margin-top: 2px;
    background: ${GLOBAL_COLORS.outline.toString()};
  }
  ${props =>
    props.resizing &&
    css`
      &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vw;
        cursor: ns-resize;
      }
    `}
`;

export const CustomChartError = styled.div`
  margin: 0 auto;
  margin-top: calc(50vh - 100px);
  width: 300px;
`;

/**
 * Three columns
 */

export const EditorsColumn = styled.div`
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
`;
export const PreviewColumn = styled.div<{width: number}>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: ${props => props.width}px;
`;
export const FieldsColumn = styled.div`
  width: 280px;
  flex-shrink: 0;
  border-left: 1px solid ${GLOBAL_COLORS.outline.toString()};
  display: flex;
  flex-direction: column;
`;

/**
 * Editor column
 */

export const ChartEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  .react-monaco-editor-container {
    flex-grow: 1;
    height: 0 !important;
  }
`;
export const ChartSaveManager = styled.div`
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
`;
export const ReadOnlyText = styled.div`
  margin-left: 4px;
  font-size: 12px;
  color: #999;
  align-self: flex-end;
`;
export const ChartActions = styled.div`
  display: flex;
  align-items: center;
`;
export const ChartAction = styled.div`
  padding: 4px 12px;
  color: #999;
  cursor: pointer;
  &:hover {
    color: black;
  }
  display: flex;
  align-items: center;
`;
export const OverflowActionsTrigger = styled(WBIcon)<{open?: boolean}>`
  font-size: 20px;
  color: #999;
  cursor: pointer;
  padding: 0 4px;
  &:hover {
    color: black;
  }
  ${props =>
    props.open &&
    css`
      color: black;
    `}
`;
export const IdOption = styled.div<{hovered?: boolean}>`
  color: white;
  padding: 8px 24px 8px 36px;
  font-size: 12px;
  color: #999;
  cursor: pointer;
  border-bottom: 1px solid #444;
  ${props =>
    props.hovered &&
    css`
      ${ChartId} {
        background: ${GLOBAL_COLORS.primary.toString()};
      }
    `};
`;
export const ChartId = styled.div`
  font-family: Inconsolata;
  font-size: 16px;
  color: white;
  border-radius: 4px;
  padding: 0 8px;
  margin: -4px -8px 0 -8px;
  width: fit-content;
`;
export const ChartNameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
`;
export const ChartName = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${Caret} {
      color: black;
    }
  }
`;
export const DataSourceEditor = styled(QueryEditor)`
  height: 100%;
`;

/**
 * Preview column
 */

export const PreviewManager = styled.div`
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 16px;
  height: 33px;
`;
export const RunButton = styled(WBIcon)<{$highlighted: boolean}>`
  cursor: pointer;
  font-size: 24px;
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  border-radius: 50%;
  margin-right: 4px;
  // margin-left: 16px;
  &:hover {
    background: #f6f6f6;
  }
  ${props =>
    props.$highlighted &&
    css`
      background: #fffab5;
      border-color: #e4d500;
    `}
`;
export const RunModeSelect = styled(WBSelect)`
  max-width: 88px;
`;
export const PreviewWrapper = styled.div<{height: number}>`
  height: ${props => props.height}px;
  flex-shrink: 0;
  background: #f6f6f6;
  padding: 16px;
`;
export const FakePanel = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  background: white;
  border-radius: 4px;
  padding: 22px 10px 10px 10px;
`;
export const DebugWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

/**
 * Fields column
 */
export const FieldsWrapper = styled.div`
  padding: 16px;
  padding-top: 8px;
  height: 0;
  flex-grow: 1;
  overflow: auto;
`;
export const UserFieldRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;
export const UserFieldLabel = styled.div`
  color: #999;
  width: 90px;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
  font-size: 14px;
`;
export const UserFieldString = styled(EditableField)`
  width: 0;
  flex-grow: 1;
  .field-content {
    white-space: nowrap;
  }
`;
export const UserFieldSelect = styled(WBSelect)`
  max-width: calc(100% + 8px);
  margin-left: -8px;
`;
export const CloseButton = styled.button`
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  padding: 12px;
  border-radius: 4px;
  width: calc(100% - 32px);
  margin: 16px;
  font-family: ${globals.fontName};
  cursor: pointer;
  background: none;
  outline: none;
  &:hover {
    background: #f6f6f6;
  }
`;
