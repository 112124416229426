import * as React from 'react';
import {Menu} from 'semantic-ui-react';
import {isExportableAs, getPanelSpec} from '../util/panels';
import * as RunSetViewTypes from '../state/views/runSet/types';
import * as PanelViewTypes from '../state/views/panel/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';

interface PanelExportMenuProps {
  ref: React.RefObject<HTMLElement>;
  panelSpec: ReturnType<typeof getPanelSpec>;
  runSetRefs: RunSetViewTypes.Ref[];
  panelRef: PanelViewTypes.Ref;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  onTriggerExportAPI(): void;
  onTriggerExportCSV(): void;
  onTriggerExportImage(s: 'png' | 'svg'): void;
}

export const PanelExportMenu = React.forwardRef<
  HTMLElement,
  PanelExportMenuProps
>(
  (
    {
      panelSpec,
      runSetRefs,
      panelRef,
      panelSettings,
      onTriggerExportAPI,
      onTriggerExportImage,
      onTriggerExportCSV,
    },
    ref
  ) => {
    return (
      <Menu vertical borderless className="popup-submenu">
        {isExportableAs(panelSpec, 'image') && (
          <>
            <Menu.Item onClick={() => onTriggerExportImage('png')}>
              PNG
            </Menu.Item>
            <Menu.Item onClick={e => onTriggerExportImage('svg')}>
              SVG
            </Menu.Item>
          </>
        )}
        {isExportableAs(panelSpec, 'api') && (
          <Menu.Item title="Export API" onClick={onTriggerExportAPI}>
            Export API
          </Menu.Item>
        )}
        {isExportableAs(panelSpec, 'csv') && (
          <Menu.Item title="Export CSV" onClick={onTriggerExportCSV}>
            CSV Export
          </Menu.Item>
        )}
      </Menu>
    );
  }
);
