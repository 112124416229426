import '../css/flash.less';

export function scrollIntoBounds(
  rect: DOMRect,
  minSpaceFromTop: number,
  minSpaceFromBottom: number
) {
  // scroll into bounds if necessary
  if (rect.top < minSpaceFromTop) {
    window.scrollBy({
      top: rect.top - minSpaceFromTop,
      behavior: 'smooth',
    });
  }
  if (rect.bottom > window.innerHeight - minSpaceFromBottom) {
    window.scrollBy({
      top: rect.bottom - (window.innerHeight - minSpaceFromBottom),
      behavior: 'smooth',
    });
  }
}

/**
 * Flashes a growing blue ring around the element, like a single
 * ripple in water.
 * @param el The element to flash.
 */
export const flash = (el: Element) => {
  // If we ever actually use this function,
  // we should make it more like flashFocus,
  // with options and absolute position instead of fixed.
  const rect = el.getBoundingClientRect();

  const flasher = document.createElement('div');
  flasher.className = 'flasher';
  document.body.appendChild(flasher);
  flasher.style.left = `${rect.left + rect.width / 2}px`;
  flasher.style.top = `${rect.top + rect.height / 2}px`;

  window.setTimeout(() => {
    document.body.removeChild(flasher);
  }, 2000);
};

/**
 * Dims the whole screen aside from the desired element.
 * @param el The element to focus on.
 * @param options
 */
export const flashFocus = (
  el: Element,
  options?: {
    width?: number;
    height?: number;
    padding?: number;
    borderRadius?: number;
    minSpaceFromTop?: number;
    minSpaceFromBottom?: number;
    offsetX?: number;
    offsetY?: number;
    popping?: boolean;
  }
) => {
  const existingFocuser = document.body.querySelector('.focuser');
  if (existingFocuser) {
    return;
  }

  const rect = el.getBoundingClientRect();

  const minSpaceFromTop = options?.minSpaceFromTop ?? 0;
  const minSpaceFromBottom = options?.minSpaceFromBottom ?? 0;
  let width = options?.width ?? rect.width;
  let height = options?.height ?? rect.height;
  const padding = options?.padding ?? 8;
  width += padding * 2;
  height += padding * 2;
  const borderRadius = options?.borderRadius ?? 4;
  const offsetX = options?.offsetX ?? 0;
  const offsetY = options?.offsetY ?? 0;
  const popping = options?.popping ?? false;

  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const focuser = document.createElement('div');
  focuser.className = 'focuser';
  focuser.style.left = `${rect.left + offsetX - padding + scrollLeft}px`;
  focuser.style.top = `${rect.top + offsetY - padding + scrollTop}px`;
  focuser.style.width = `${width}px`;
  focuser.style.height = `${height}px`;
  focuser.style.borderRadius = `${borderRadius}px`;
  document.body.appendChild(focuser);

  scrollIntoBounds(rect, minSpaceFromTop, minSpaceFromBottom);

  const removeFocuser = () => {
    focuser.classList.add('fading-out');
    window.setTimeout(() => {
      if (document.body.contains(focuser)) {
        document.body.removeChild(focuser);
      }
    }, 400);
    window.removeEventListener('mousedown', removeFocuser);
  };

  window.addEventListener('mousedown', removeFocuser);

  const pop = () => {
    focuser.classList.add('popping');
    el.removeEventListener('mousedown', pop);
  };

  if (popping) {
    el.addEventListener('mousedown', pop);
  }
};

export const flashFocusCircle = (
  el: Element,
  options?: {
    radius?: number;
    padding?: number;
    minSpaceFromTop?: number;
    minSpaceFromBottom?: number;
    offsetX?: number;
    offsetY?: number;
    popping?: boolean;
  }
) => {
  const rect = el.getBoundingClientRect();
  const radius = options?.radius ?? Math.max(rect.width, rect.height) / 2;
  const padding = options?.padding;
  const minSpaceFromTop = options?.minSpaceFromTop;
  const minSpaceFromBottom = options?.minSpaceFromBottom;
  const offsetX = options?.offsetX;
  const offsetY = options?.offsetY;
  const popping = options?.popping;
  flashFocus(el, {
    width: radius * 2,
    height: radius * 2,
    borderRadius: radius * 2,
    padding,
    minSpaceFromTop,
    minSpaceFromBottom,
    offsetX,
    offsetY,
    popping,
  });
};
