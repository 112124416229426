import React, {FC} from 'react';
import {monthRoundedTime} from '../../util/time';
import makeComp from '../../util/profiler';

type TimeDisplayFormat = 'seconds' | 'month_round';

export function displayTimeDifference(
  start: Date,
  end: Date,
  format: TimeDisplayFormat
) {
  // convert timestamps to number of milliseconds
  const startTime = start.getTime();
  const endTime = end.getTime();
  const timeDeltaSeconds = Math.abs(Math.round((endTime - startTime) / 1000));

  if (format === 'seconds') {
    return timeDeltaSeconds;
  } else {
    return monthRoundedTime(timeDeltaSeconds);
  }
}

interface TimeDisplayProps {
  timestamp: Date;
  format: TimeDisplayFormat;
  now: Date;
}

const TimeDisplay: FC<TimeDisplayProps> = makeComp(
  ({timestamp, now, format}) => {
    return <span>{displayTimeDifference(timestamp, now, format)}</span>;
  },
  {id: 'TimeDisplay', memo: true}
);

export default TimeDisplay;
