const DESCRIPTION_DEFAULT_TEXT =
  'Publish your model insights with interactive plots for performance metrics, predictions, and hyperparameters.';
const DESCRIPTION_TARGET_LENGTH = 155;

type ReportAuthorInfo = {username: string; name?: string};

export function maybeLengthenReportDescription(
  desc: string,
  author: ReportAuthorInfo
): string {
  if (!desc) {
    desc = DESCRIPTION_DEFAULT_TEXT;
  }
  if (!desc.endsWith('.')) {
    desc += '.';
  }
  desc = desc.trim();

  const appendText = getDescriptionAppendText(author);
  const shortAppendText = getDescriptionAppendText(author, true);
  for (const t of [appendText, shortAppendText]) {
    if (desc.length + t.length <= DESCRIPTION_TARGET_LENGTH) {
      return desc + t;
    }
  }

  return desc;
}

function getDescriptionAppendText(
  author: ReportAuthorInfo,
  short?: boolean
): string {
  const wbStr = short ? 'W&B' : 'Weights & Biases';
  return ` Made by ${author.name || author.username} using ${wbStr}`;
}
