import * as _ from 'lodash';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {Grid, Header, Segment} from 'semantic-ui-react';
import Loader from './WandbLoader';
import {JSONparseUserFile} from '@wandb/cg/browser/utils/jsonnan';

import docUrl from '../util/doc_urls';
import PanelGraph from './PanelGraph';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Requests from '../util/requests';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

interface RunModelInfoProps {
  entityName: string;
  projectName: string;
  runName: string;
  summaryMetrics: any;
}

const RunModelInfo = makeComp(
  (props: RunModelInfoProps) => {
    const {entityName, projectName, runName} = props;
    const graphKeys = _.map(props.summaryMetrics, (val, key) =>
      val && (val._type === 'graph' || val._type === 'graph-file') ? key : null
    ).filter(Obj.notEmpty);
    if (graphKeys.length === 0) {
      return (
        <div className="run-model-info">
          <>
            <p>No model graphs saved for this run.</p>
            <p>
              To save your model, please refer to the{' '}
              <TargetBlank href={docUrl.save}>documentation</TargetBlank>.
            </p>
          </>
        </div>
      );
    }
    return (
      <div className="run-model-info">
        {graphKeys.map(key => (
          <Grid.Column key={key as string}>
            <div className="unpinned-panel">
              <Header>{key}</Header>
              <Graph
                entityName={entityName}
                projectName={projectName}
                runName={runName}
                graph={props.summaryMetrics[key]}
              />
            </div>
          </Grid.Column>
        ))}
      </div>
    );
  },
  {id: 'RunModelInfo'}
);

export default RunModelInfo;

interface GraphProps {
  entityName: string;
  projectName: string;
  runName: string;
  graph: any;
}

const Graph = makeComp(
  (props: GraphProps) => {
    const {entityName, projectName, runName} = props;
    const [graph, setGraph] = useState(props.graph);
    const [error, setError] = useState<string | undefined>();
    const isFileType = graph._type === 'graph-file';
    let filePath: string = '';
    if (isFileType) {
      filePath = graph.path;
    }
    const onSuccess = useCallback((res: any) => {
      const parsed = JSONparseUserFile(res);
      if (parsed.error) {
        setError("Couldn't parse json data");
      } else {
        setGraph(parsed.result);
      }
    }, []);
    const loading = Requests.useLoadFile(
      {entityName, projectName, runName},
      filePath,
      {
        skip: !isFileType,
        onSuccess,
      }
    );
    if (isFileType && loading) {
      return <Loader />;
    }
    if (error) {
      return <Segment>{error}</Segment>;
    }
    if (graph == null) {
      throw new Error('invalid');
    }
    return <PanelGraph graph={graph} />;
  },
  {id: 'RunModelInfo.Graph'}
);
