import React, {useContext, useState, useEffect} from 'react';

import * as _ from 'lodash';
import classNames from 'classnames';
import DragDropContext, {
  DropTarget,
  DragSource,
  DragDropState,
} from '../containers/DragDrop';
import {PanelBankSectionComponentSharedProps, isPanel} from '../util/panelbank';
import * as ViewHooks from '../state/views/hooks';
import * as PanelTypes from '../state/views/panel/types';
import {
  bottom,
  compact,
  getLayoutItem,
  GRID_COLUMN_COUNT,
  GRID_CONTAINER_PADDING,
  GRID_ITEM_MARGIN,
  GRID_ROW_HEIGHT,
  GridLayout,
  GridLayoutItem,
  getNewGridItemLayout,
  GRID_ITEM_DEFAULT_WIDTH,
  GRID_ITEM_DEFAULT_HEIGHT,
} from '../util/panelbankGrid';
import * as PanelBankSectionConfigActions from '../state/views/panelBankSectionConfig/actions';
import EmptyPanelBankSectionWatermark from './EmptyPanelBankSectionWatermark';
import {Resizable} from 'react-resizable';
import produce from 'immer';
import {isFirefox} from '../util/cross-browser-magic';
import {skipTransition} from '../util/animations';
import makeComp from '../util/profiler';
import {ReportDiscussionContext} from './ReportDiscussionContext';

const PanelBankGridSection: React.FC<
  {
    gridItemMargin?: number[];
    gridRowHeight?: number;
    gridContainerPadding?: number[];
    showGridDots?: boolean;
    hideEmptyWatermark?: boolean;
    onClickGrid?(): void;
  } & PanelBankSectionComponentSharedProps
> = makeComp(
  props => {
    const gridItemMargin = props.gridItemMargin || GRID_ITEM_MARGIN;
    const gridContainerPadding =
      props.gridContainerPadding || GRID_CONTAINER_PADDING;
    // a hack to make read-only report panels not shrink due to lack of margin
    const gridRowHeight = props.gridRowHeight || GRID_ROW_HEIGHT;
    const panelRefs = ViewHooks.usePart(
      props.panelBankSectionConfigRef
    ).panelRefs;
    // Note: not using useWholeMapped below because, with it, section.panels is stale if you delete a panel
    const section = ViewHooks.useWhole(props.panelBankSectionConfigRef);
    const serverGridLayout: GridLayout = [];
    section.panels.forEach((p, i) => {
      serverGridLayout.push({
        ...(p.layout || getNewGridItemLayout(serverGridLayout)), // Add layout to panels that don't have it
        id: panelRefs[i].id,
      });
    });
    const setGridLayout = ViewHooks.useViewAction(
      props.panelBankSectionConfigRef,
      PanelBankSectionConfigActions.setGridLayout
    );

    const isOneMarkdown =
      props.readOnly &&
      section.panels.length === 1 &&
      section.panels[0].viewType === 'Markdown Panel';

    const [contentHeightByPanelRefID, setContentHeightByPanelRefID] = useState<{
      [id: string]: number;
    }>({});

    const {dragRef, dropRef, dragData, dragging} = useContext(DragDropContext);
    const [resizingRefId, setResizingRefId] = React.useState<string | null>(
      null
    );
    const [resizingSize, setResizingSize] = React.useState<{
      w: number;
      h: number;
    } | null>(null);

    const getStyleFromLayout = (
      layout: GridLayoutItem,
      useTransform = true
    ) => {
      const {x: left, y: top} = convertToPixelCoords(layout.x, layout.y);
      const size = convertToPixelSize({w: layout.w, h: layout.h});
      return {
        width: size.w,
        height: size.h,
        ...(useTransform
          ? {
              transform: `translate(${left}px, ${top}px)`,
            }
          : {
              left,
              top,
            }),
      } as {
        width: number;
        height: number;
        transform?: string;
        left?: number;
        top?: number;
      };
    };

    const getGridBottom = (layout: GridLayout) => {
      if (props.showGridDots) {
        return Math.max(bottom(layout), 6);
      }
      return bottom(layout);
    };

    const getLayoutHeight = (layout: GridLayout) => {
      const bottomY = getGridBottom(layout);
      const minHeight = props.hideEmptyWatermark ? 0 : 150;
      return Math.max(
        bottomY * gridRowHeight +
          (bottomY - 1) * gridItemMargin[1] +
          gridContainerPadding[1] * 2,
        minHeight
      );
    };

    // Get nearest grid coordinates for the given pixel values
    const convertToGridCoords = (xPx: number, yPx: number) => {
      return {
        x: Math.max(
          Math.round(
            (xPx - gridItemMargin[0]) / (columnWidth + gridItemMargin[0])
          ),
          0
        ),
        y: Math.round(
          (yPx - gridItemMargin[1]) / (gridRowHeight + gridItemMargin[1])
        ),
      };
    };

    // Get grid width and height for the given pixel values
    const convertToGridSize = (size: {
      height: number;
      width: number;
    }): {w: number; h: number} => ({
      w: convertToGridWidth(size.width),
      h: convertToGridHeight(size.height),
    });
    const convertToGridWidth = (w: number, round = true) => {
      const gridWidth =
        (w + gridItemMargin[0]) / (columnWidth + gridItemMargin[0]);
      return round ? Math.round(gridWidth) : gridWidth;
    };
    const convertToGridHeight = (h: number, round = true) => {
      const gridHeight =
        (h + gridItemMargin[1]) / (gridRowHeight + gridItemMargin[1]);
      return round ? Math.round(gridHeight) : gridHeight;
    };

    const convertToPixelCoords = (x: number, y: number) => {
      return {
        x: Math.round(
          (columnWidth + gridItemMargin[0]) * x + gridContainerPadding[0]
        ),
        y: Math.round(
          (gridRowHeight + gridItemMargin[1]) * y + gridContainerPadding[1]
        ),
      };
    };

    const convertToPixelSize = (size: {w: number; h: number}) => {
      return {
        w: Math.round(
          columnWidth * size.w + Math.max(0, size.w - 1) * gridItemMargin[0]
        ),
        h: Math.round(
          gridRowHeight * size.h + Math.max(0, size.h - 1) * gridItemMargin[1]
        ),
      };
    };

    const forceResize = () => {
      setTimeout(() => window.dispatchEvent(new Event('resize')));
    };

    const getPanelSizeFromDragData = (data: any) => ({
      w: (data && data.size && data.size.w) || GRID_ITEM_DEFAULT_WIDTH,
      h: (data && data.size && data.size.h) || GRID_ITEM_DEFAULT_HEIGHT,
    });

    const updateDragoverMouseCoords = (
      ctx: DragDropState,
      e: React.DragEvent<Element>
    ) => {
      const sectionBounds = e.currentTarget.getBoundingClientRect();
      const draggingPanelSize = getPanelSizeFromDragData(dragData);
      const draggingPanelSizePixels = convertToPixelSize(draggingPanelSize);
      const coords = convertToGridCoords(
        e.clientX - sectionBounds.left - draggingPanelSizePixels.w / 2,
        e.clientY - sectionBounds.top - 60
      );
      if (!_.isEqual(coords, dragData && dragData.dragoverMouseCoords)) {
        ctx.setDragData({
          ...dragData,
          dragoverMouseCoords: coords,
        });
      }
    };

    const columnWidth =
      (props.panelBankWidth -
        gridItemMargin[0] * (GRID_COLUMN_COUNT - 1) -
        gridContainerPadding[0] * 2) /
      GRID_COLUMN_COUNT;

    const serverGridLayoutWithoutDragging = produce(serverGridLayout, draft => {
      if (
        dragRef != null &&
        dragging &&
        dragData != null &&
        dragData.dragoverMouseCoords != null
      ) {
        const draggingItemIndex = _.findIndex(draft, {id: dragRef.id});
        if (draggingItemIndex !== -1) {
          draft.splice(draggingItemIndex, 1);
        }
      }
    });

    const draggingOverSection =
      dragData != null && _.isEqual(dropRef, props.panelBankSectionConfigRef);

    let derivedGridLayout = produce(serverGridLayoutWithoutDragging, draft => {
      if (resizingRefId != null && resizingSize != null) {
        const resizingItemIndex = _.findIndex(draft, {id: resizingRefId});
        if (resizingItemIndex !== -1) {
          draft[resizingItemIndex].w = resizingSize.w;
          draft[resizingItemIndex].h = resizingSize.h;
        }
      }
      if (
        dragRef != null &&
        dragging &&
        draggingOverSection &&
        dragData != null &&
        dragData.dragoverMouseCoords != null
      ) {
        const size = getPanelSizeFromDragData(dragData);
        draft.push({
          x: Math.min(
            dragData.dragoverMouseCoords.x,
            GRID_COLUMN_COUNT - size.w
          ),
          y: dragData.dragoverMouseCoords.y,
          ...size,
          id: 'drop-preview',
        });
      }
    });

    // Stack vertically on mobile
    if (props.panelBankWidth <= 768 && props.readOnly) {
      derivedGridLayout = produce(derivedGridLayout, draft => {
        draft.forEach(layout => {
          layout.w = GRID_COLUMN_COUNT;
          layout.x = 0;
        });
      });
    }

    derivedGridLayout = compact(derivedGridLayout, GRID_COLUMN_COUNT);

    let dropPreview = null;
    const dropPreviewLayout = getLayoutItem(derivedGridLayout, 'drop-preview');
    if (dropPreviewLayout) {
      const style = getStyleFromLayout(dropPreviewLayout, false);
      dropPreview = (
        <div
          key="drop-preview"
          className="drop-preview"
          style={{
            ...style,
          }}
        />
      );
    }

    // This is sum hilarious hax to automatically resize a markdown panel to its content's height
    // It's only active when props.readOnly === true
    derivedGridLayout.forEach(l => {
      const contentHeight = contentHeightByPanelRefID[l.id];
      if (contentHeight == null) {
        return;
      }

      // Abort if there are other panels in the same row
      if (
        derivedGridLayout.find(
          otherL => l !== otherL && heightsIntersect(l, otherL)
        ) != null
      ) {
        return;
      }

      const oldH = l.h;
      // These magic numbers correspond to the padding/border of the elements between here and the content
      l.h = Math.ceil(convertToGridHeight(contentHeight + 32 + 6, false));

      // Shift panels below this one accordingly
      derivedGridLayout.forEach(otherL => {
        if (l !== otherL && otherL.y > l.y) {
          otherL.y += l.h - oldH;
        }
      });
    });

    const lowest = getGridBottom(derivedGridLayout);
    const dots = [];
    if (props.showGridDots) {
      for (let i = 0; i <= lowest / 2; i++) {
        for (let j = 0; j < 13; j++) {
          dots.push([
            (props.panelBankWidth / 12) * j,
            i * (gridRowHeight - 1) * 2,
          ]);
        }
      }
    }

    const empty =
      props.activePanelRefs.length + props.inactivePanelRefs.length === 0;

    const {highlightIds} = useContext(ReportDiscussionContext);

    const activePanelIds = ViewHooks.useWholeArray(
      props.activePanelRefs as PanelTypes.Ref[]
    ).map(p => p.__id__);

    const [clickingGrid, setClickingGrid] = useState(false);
    useEffect(() => {
      function onMouseUp() {
        setClickingGrid(false);
      }
      window.addEventListener('mouseup', onMouseUp);
      return () => window.removeEventListener('mouseup', onMouseUp);
    }, []);

    return (
      <div
        className={classNames('grid-section', {
          'one-markdown': isOneMarkdown,
          empty,
        })}>
        <DropTarget
          partRef={props.panelBankSectionConfigRef}
          style={{
            position: 'relative',
            height: getLayoutHeight(derivedGridLayout),
          }}
          onMouseDown={e => {
            if (e.target === e.currentTarget) {
              setClickingGrid(true);
            }
          }}
          onMouseUp={e => {
            if (clickingGrid && e.target === e.currentTarget) {
              props.onClickGrid?.();
            }
          }}
          onDragEnter={(ctx, e) => {
            updateDragoverMouseCoords(ctx, e);
          }}
          onDragOver={updateDragoverMouseCoords}
          onDrop={() => {
            if (!dragData || !dragRef) {
              return;
            }
            if (
              !_.isEqual(
                dragData.fromSectionRef,
                props.panelBankSectionConfigRef
              )
            ) {
              props.movePanelBetweenSections(
                dragRef as PanelTypes.Ref,
                dragData.fromSectionRef,
                props.panelBankSectionConfigRef
              );
            }
            const dropPreviewItem = getLayoutItem(
              derivedGridLayout,
              'drop-preview'
            );
            if (dropPreviewItem) {
              dropPreviewItem.id = dragRef.id;
            }
            setGridLayout(derivedGridLayout);
          }}>
          {dropPreview}
          {empty && !props.hideEmptyWatermark ? (
            <EmptyPanelBankSectionWatermark />
          ) : (
            <>
              {dots.map((dot, i) => (
                <div
                  className="grid-dot"
                  key={`dot-${i}`}
                  style={{left: dot[0], top: dot[1]}}
                />
              ))}
              {props.activePanelRefs.map((panelRef, i) => {
                const panelId = activePanelIds[i];

                let layout = getLayoutItem(derivedGridLayout, panelRef.id);
                if (layout == null) {
                  // If it's not in the layout, it's probably being dragged,
                  // in which case we still need to render it for its drag events to fire,
                  // but we want it to be invisible.
                  layout = {w: 0, h: 0, x: 0, y: 0, id: ''};
                }
                const selectedForDrag =
                  isPanel(dragRef) && _.isEqual(dragRef, panelRef);
                const panelLayoutStyle = getStyleFromLayout(
                  layout,
                  !isFirefox && (!selectedForDrag || dragging)
                );

                // This is sum hilarious hax to automatically resize a markdown panel to its content's height
                // It's only active when props.readOnly === true
                const onContentHeightChanged = props.readOnly
                  ? (h: number) =>
                      setContentHeightByPanelRefID(old => ({
                        ...old,
                        [panelRef.id]: h,
                      }))
                  : undefined;

                return (
                  <Resizable
                    className={classNames(
                      'panel-bank__panel',
                      `col-${layout.w}`,
                      {
                        [`panel-bank__panel-id__${panelId}`]: panelId != null,
                        'panel-bank__panel-small': panelLayoutStyle.width < 225,
                        resizing: resizingRefId === panelRef.id,
                        dragging: selectedForDrag && dragging,
                        'panel-highlight':
                          panelId != null && highlightIds.includes(panelId),
                      }
                    )}
                    key={panelRef.id}
                    width={panelLayoutStyle.width}
                    height={panelLayoutStyle.height}
                    minConstraints={[columnWidth, gridRowHeight]}
                    onResize={(e, data) => {
                      const size = convertToGridSize(data.size);
                      size.w = Math.min(
                        size.w,
                        GRID_COLUMN_COUNT - (layout?.x || 0)
                      );
                      size.w = Math.max(size.w, 1);
                      size.h = Math.max(size.h, 2);
                      if (!_.isEqual(size, resizingSize)) {
                        setResizingSize(size);
                        forceResize();
                      }
                    }}
                    onResizeStart={() => {
                      setResizingRefId(panelRef.id);
                    }}
                    onResizeStop={() => {
                      setResizingRefId(null);
                      setResizingSize(null);
                      setGridLayout(derivedGridLayout);
                    }}>
                    <DragSource
                      style={panelLayoutStyle}
                      partRef={panelRef}
                      onMouseUp={e => {
                        skipTransition(e.currentTarget, 50);
                      }}
                      data={{
                        fromSectionRef: props.panelBankSectionConfigRef,
                        size: {w: layout.w, h: layout.h},
                      }}>
                      {props.renderPanel(panelRef, onContentHeightChanged)}
                    </DragSource>
                  </Resizable>
                );
              })}
            </>
          )}
        </DropTarget>
      </div>
    );
  },
  {id: 'PanelBankGridSection'}
);

export default PanelBankGridSection;

function heightsIntersect(l1: GridLayoutItem, l2: GridLayoutItem) {
  const higher = l2.y < l1.y ? l2 : l1;
  const lower = higher === l1 ? l2 : l1;
  return lower.y < higher.y + higher.h;
}
