import '../css/LineStylePicker.less';

import * as React from 'react';
import {Popup, Button} from 'semantic-ui-react';
import {Mark} from '../util/plotHelpers';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

export interface LineStylePickerProps {
  activeLineStyle: Mark;
  setLineStyle(mark: Mark): void;
}

export function markToIconName(mark: Mark) {
  switch (mark) {
    case 'dashed':
      return 'line-dash';
    case 'dotdash':
      return 'line-dash-dot';
    case 'dotdotdash':
      return 'line-dash-dot-dot';
    case 'points':
      return 'line-disconnected';
    case 'solid':
      return 'line-solid';
    case 'dotted':
      return 'line-dot';
  }
}

export function markToIcon(mark: Mark) {
  return <LegacyWBIcon name={markToIconName(mark)} />;
}

const LineStylePicker = makeComp(
  (props: LineStylePickerProps) => {
    const markOptions = [
      {icon: 'line-solid', value: 'solid', key: 'solid', text: 'Solid'},
      {icon: 'line-dash', value: 'dashed', key: 'dashed', text: 'Dashed'},
      {
        icon: 'line-dash-dot',
        value: 'dotdash',
        key: 'dotdash',
        text: 'Dot Dashed',
      },
      {
        icon: 'line-dash-dot-dot',
        value: 'dotdotdash',
        key: 'dotdotsdash',
        text: 'Dot Dot Dashed',
      },
      {
        icon: 'line-dot',
        value: 'dotted',
        key: 'dotted',
        text: 'Dotted',
      },
      {
        icon: 'line-disconnected',
        value: 'points',
        key: 'points',
        text: 'Points',
      },
    ];

    const activeMarkOption =
      markOptions.find(
        markOption => markOption.value === props.activeLineStyle
      ) || markOptions[0];

    return (
      <Popup
        className="line-style-picker-popup"
        on="click"
        trigger={
          <LegacyWBIcon
            name={activeMarkOption.icon}
            className="line-style-picker"
          />
        }>
        <Button.Group>
          {markOptions.map(markOption => (
            <Button
              size="tiny"
              className="wb-icon-button only-icon"
              key={markOption.key}
              onClick={() => {
                const newMark: Mark = markOption.value as Mark;
                props.setLineStyle(newMark);
              }}>
              <LegacyWBIcon name={markOption.icon} title={markOption.text} />
            </Button>
          ))}
        </Button.Group>
      </Popup>
    );
  },
  {id: 'LineStylePicker'}
);

export default LineStylePicker;
