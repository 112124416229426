import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Divider, Header, Image, List} from 'semantic-ui-react';
import '../css/ProfileSidebar.less';
import {useUpdateUserInfoMutation} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {UserInfo} from '../types/graphql';
import {LinkNoCrawl} from '../util/links';
import makeComp from '../util/profiler';
import EditableField from './EditableField';
import EditableImage from './EditableImage';
import {ProfilePageData} from './ProfilePage';

const EMPTY_USER_INFO: UserInfo = {
  bio: '',
  location: '',
  company: '',
  websiteUrl: '',
  twitterUrl: '',
  githubUrl: '',
};

interface ProfileSidebarProps {
  data: ProfilePageData;
  editable: boolean;
}

const ProfileSidebar: React.FC<ProfileSidebarProps> = makeComp(
  ({data, editable}) => {
    const [updateUser] = useUpdateUserInfoMutation();
    const [userInfo, setUserInfo] = useState(data.userInfo ?? EMPTY_USER_INFO);

    useEffect(() => {
      if (data.userInfo == null) {
        return;
      }
      setUserInfo(data.userInfo);
    }, [data]);

    const viewer = useViewer();
    // Coerce empty string to null;
    const name = !_.isEmpty(data.name) ? data.name! : null;
    const isOwnProfile = viewer != null ? viewer.name === name : false;

    interface FieldConfig {
      key: keyof UserInfo;
      icon: string;
      placeholder: string;
      type?: string;
      baseUrl?: string;
      customDisplay?: RegExp;
    }

    const userInfoFieldConfig: FieldConfig[] = [
      {
        key: 'company',
        icon: 'wbic-ic-office',
        placeholder: 'Your Institution',
      },
      {
        key: 'location',
        icon: 'wbic-ic-place',
        placeholder: 'Your Location',
      },
      {
        key: 'websiteUrl',
        icon: 'wbic-ic-link',
        placeholder: 'example.com',
        type: 'url',
      },
      {
        key: 'twitterUrl',
        icon: 'wbic-ic-twitter',
        placeholder: 'ExampleHandle',
        type: 'url',
        baseUrl: 'twitter.com',
        customDisplay: /twitter\.com\/.+/,
      },
      {
        key: 'githubUrl',
        icon: 'wbic-ic-github',
        placeholder: 'ExampleUsername',
        type: 'url',
        baseUrl: 'github.com',
        customDisplay: /github\.com\/.+/,
      },
    ];

    function customUrlIntoUsername(value: string, urlRegex: RegExp): string {
      if (value === '') {
        return '';
      }
      // if user enters a valid url, get the username which follows the forward slash
      if (urlRegex.test(value)) {
        return value.split('/').pop() || '';
      }
      // if the regex doesn't match, the user probably entered their username or a different/invalid url
      return value;
    }

    function usernameIntoUrl(value: string, baseUrl: string): string {
      if (value === '') {
        return '';
      }
      // if user enters a url, return it.
      // we ignore url protocols (http/https) because EditableField strips those when the user enters a value.
      // (it's very likely that there's better regex for this)
      if (/.+\..+/.test(value)) {
        return value;
      }
      return `${baseUrl}/${value}`;
    }

    function generateUserInfo() {
      return userInfoFieldConfig.map(userConfig => {
        // Hide blank fields from everyone but the profile owner
        if (!isOwnProfile && !userInfo[userConfig.key]) {
          return null;
        }
        let value = (userInfo[userConfig.key] as string | undefined) ?? '';
        // Handle custom display value
        let displayValue;
        if (userConfig.customDisplay != null) {
          displayValue = customUrlIntoUsername(value, userConfig.customDisplay);
          value = usernameIntoUrl(value, userConfig.baseUrl || '');
        }
        return (
          <EditableField
            key={userConfig.key}
            readOnly={!editable}
            value={value}
            displayValue={displayValue}
            updateValue={userConfig.customDisplay != null}
            maxLength={userConfig.type === 'url' ? 1024 : 64}
            icon={userConfig.icon}
            placeholder={userConfig.placeholder}
            type={userConfig.type}
            save={newVal => {
              const newUserInfo = {
                ...userInfo,
                [userConfig.key]: newVal,
              };
              setUserInfo(newUserInfo);
              updateUser({
                variables: {
                  userInfo: JSON.stringify(newUserInfo),
                },
              });
            }}
          />
        );
      });
    }

    const teams = data.teams.edges
      .filter(team => team.node.isTeam)
      .map(team => {
        return (
          <List.Item key={team.node.name}>
            <Image src={team.node.photoUrl} />
            <List.Content>
              <LinkNoCrawl to={`/${team.node.name}`}>
                {team.node.name}
              </LinkNoCrawl>
            </List.Content>
          </List.Item>
        );
      });

    return (
      <div className="sidebar profile-sidebar">
        <div className="content">
          <EditableImage
            profile
            photoUrl={data.photoUrl}
            displaySize={200}
            label={false}
            readOnly={!editable}
            save={newVal => {
              updateUser({
                variables: {
                  photoUrl: newVal,
                },
              });
            }}
          />
          <div>
            <EditableField
              readOnly={!editable}
              asHeader="h1"
              className="profile-name"
              value={editable ? name ?? '' : name ?? data.username}
              updateValue={false}
              placeholder="Your Name"
              maxLength={64}
              save={newVal => {
                updateUser({
                  variables: {
                    name: newVal,
                  },
                });
              }}
            />

            {(name != null || editable) && <Header sub>{data.username}</Header>}
            <EditableField
              readOnly={!editable}
              className="bio"
              multiline
              value={userInfo.bio || ''}
              updateValue={false}
              placeholder="I teach robots how to learn."
              maxLength={256}
              save={newVal => {
                const newUserInfo = {
                  ...userInfo,
                  bio: newVal,
                };
                setUserInfo(newUserInfo);
                updateUser({
                  variables: {
                    userInfo: JSON.stringify(newUserInfo),
                  },
                });
              }}
            />
            <div className="user-info-list">{generateUserInfo()}</div>
            {teams.length !== 0 && <Divider />}
            <List className="user-team-list">{teams}</List>
          </div>
        </div>
      </div>
    );
  },
  {id: 'ProfileSidebar', memo: true}
);

export default ProfileSidebar;
