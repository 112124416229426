import * as _ from 'lodash';
import React, {useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useState} from 'react';
import {useMemo, useCallback, useRef} from 'react';
import {Button, Checkbox} from 'semantic-ui-react';
import {calculatePosition} from 'vega-tooltip';
import {PanelComp2} from './PanelComp';
import * as Panel2 from './panel';
import * as ExpressionEditor from './ExpressionEditor';
import * as Types from '@wandb/cg/browser/model/types';
import * as CG from '@wandb/cg/browser/graph';
import * as Op from '@wandb/cg/browser/ops';
import * as Code from '@wandb/cg/browser/code';
import * as LLReact from '../../cgreact';
import * as TableState from './tableState';
import {getPanelStacksForType, getPanelStackDims} from './availablePanels';
import * as PlotState from './plotState';
// import * as S from './SuperTable.styles';
import {VisualizationSpec} from 'react-vega';
import CustomPanelRenderer from '../Vega3/CustomPanelRenderer';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import {usePanelContext} from './PanelContext';
import {useGatedValue} from '../../state/hooks';
import {ActivityDashboardContext} from '../ActivityDashboard';
import {useTableStateWithRefinedExpressions} from './tableStateReact';
import {escapeDots} from '@wandb/cg/browser/ops';
import {ThemeProvider} from 'styled-components';
import * as QueryEditorStyles from './ExpressionEditor.styles';
import Loader from '../WandbLoader';
import {allObjPaths} from '@wandb/cg/browser/model/typeHelpers';
import * as TableType from './tableType';
import {makeEventRecorder} from './panellib/libanalytics';

const recordEvent = makeEventRecorder('Plot');

const inputType = TableType.TableLikeType;

export function defaultPlot(
  inputNode: Types.Node,
  frame: Code.Frame
): PlotState.PlotConfig {
  const exampleRow = TableState.getExampleRow(inputNode);

  let tableState = TableState.emptyTable();
  tableState = TableState.appendEmptyColumn(tableState);
  const xColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const yColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const colorColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const labelColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const tooltipColId = tableState.order[tableState.order.length - 1];

  const axisSettings: PlotState.PlotConfig['axisSettings'] = {
    x: {},
    y: {},
  };
  const legendSettings: PlotState.PlotConfig['legendSettings'] = {
    color: {},
  };

  let labelAssigned = false;
  if (
    frame.runColors != null &&
    frame.runColors.nodeType === 'const' &&
    Object.keys(frame.runColors.val).length > 1
  ) {
    if (
      Types.isAssignableTo(
        exampleRow.type,
        Types.withNamedTag('run', 'run', 'any')
      )
    ) {
      tableState = TableState.updateColumnSelect(
        tableState,
        labelColId,
        Op.opGetRunTag({obj: CG.varNode(exampleRow.type, 'row')})
      );
      labelAssigned = true;
    } else if (Types.isAssignableTo(exampleRow.type, 'run')) {
      tableState = TableState.updateColumnSelect(
        tableState,
        labelColId,
        CG.varNode(exampleRow.type, 'row')
      );
      labelAssigned = true;
    }
  }

  // If we have a list of dictionaries, try to make a good guess at filling in the dimensions
  if (Types.isAssignableTo(exampleRow.type, Types.typedDict({}))) {
    const propertyTypes = allObjPaths(
      Types.nullableTaggableValue(exampleRow.type)
    );
    let xCandidate: string | null = null;
    let yCandidate: string | null = null;
    let labelCandidate: string | null = null;
    let mediaCandidate: string | null = null;
    // Assign the first two numeric columns to x an y if available
    for (const propertyKey of propertyTypes) {
      if (Types.isAssignableTo(propertyKey.type, 'number')) {
        if (xCandidate == null) {
          xCandidate = propertyKey.path.join('.');
        } else if (yCandidate == null) {
          yCandidate = propertyKey.path.join('.');
        }
      } else if (Types.isAssignableTo(propertyKey.type, 'string')) {
        // don't default to the run name field
        if (
          labelCandidate == null &&
          propertyKey.path.indexOf('runname') === -1
        ) {
          labelCandidate = propertyKey.path.join('.');
        }
      } else if (
        mediaCandidate == null &&
        Types.isAssignableTo(
          propertyKey.type,
          Types.union([
            {type: 'image-file'},
            {type: 'video-file'},
            {type: 'audio-file'},
            {type: 'html-file'},
            {type: 'bokeh-file'},
            {type: 'object3D-file'},
            {type: 'molecule-file'},
          ])
        )
      ) {
        mediaCandidate = propertyKey.path.join('.');
      }
    }

    if (xCandidate != null && yCandidate != null) {
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        Op.opPick({
          obj: CG.varNode(exampleRow.type, 'row'),
          key: Op.constString(xCandidate),
        })
      );

      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opPick({
          obj: CG.varNode(exampleRow.type, 'row'),
          key: Op.constString(yCandidate),
        })
      );

      if (labelCandidate != null && !labelAssigned) {
        tableState = TableState.updateColumnSelect(
          tableState,
          labelColId,
          Op.opPick({
            obj: CG.varNode(exampleRow.type, 'row'),
            key: Op.constString(labelCandidate),
          })
        );
      }

      if (mediaCandidate != null) {
        tableState = TableState.updateColumnSelect(
          tableState,
          tooltipColId,
          Op.opPick({
            obj: CG.varNode(exampleRow.type, 'row'),
            key: Op.constString(mediaCandidate),
          })
        );
      }
    }
  }

  // If we have an array of number, default to a scatter plot
  // by index (for the moment).
  if (Types.isAssignableTo(inputNode.type, Types.list(Types.maybe('number')))) {
    if (frame.domain != null) {
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        Op.opNumberBin({
          in: CG.varNode(exampleRow.type, 'row') as any,
          binFn: Op.opNumbersBinEqual({
            arr: CG.varNode(Types.list('number'), 'domain') as any,
            bins: Op.constNumber(10),
          }),
        }) as any
      );
      tableState = {...tableState, groupBy: [xColId]};
      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opCount({arr: CG.varNode(Types.list(exampleRow.type), 'row') as any})
      );
      axisSettings.x = {
        noTitle: true,
      };
      axisSettings.y = {
        noTitle: true,
      };
      legendSettings.color = {
        noLegend: true,
      };
    } else if (
      Types.isAssignableTo(
        inputNode.type,
        Types.list(
          Types.taggedValue(
            Types.typedDict({run: 'run'}),
            Types.maybe('number')
          )
        )
      )
    ) {
      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opRunName({
          run: Op.opGetRunTag({obj: CG.varNode(exampleRow.type, 'row')}),
        })
      );
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        CG.varNode(exampleRow.type, 'row')
      );
    }
  }

  // If we have an array of string, default to a histogram configuration
  if (
    Types.isAssignableTo(inputNode.type, Types.list(Types.maybe('string'))) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      CG.varNode(exampleRow.type, 'row')
    );
    tableState = {...tableState, groupBy: [yColId]};
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      Op.opCount({arr: CG.varNode(Types.list(exampleRow.type), 'row') as any})
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  // If we have an dict of number, default to a bar chart configuration
  if (
    Types.isAssignableTo(inputNode.type, Types.dict(Types.maybe('number'))) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      CG.varNode('string', 'key')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      CG.varNode(exampleRow.type, 'row')
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  // If we have an dict of array of number, default to a boxplot
  // (note this is calculated on the frontend currently. TODO fix)
  if (
    Types.isAssignableTo(
      inputNode.type,
      Types.dict(Types.list(Types.maybe('number')))
    ) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      CG.varNode('string', 'key')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      CG.varNode(exampleRow.type, 'row')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      colorColId,
      CG.varNode('string', 'key')
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  // If we have an dict of array of string, default to a multi-histogram.
  // Doesn't work yet, types are f'd because PanelPlot handles input
  // dict but tableState group by array case probably doesn't.
  // if (
  //   Types.isAssignableTo(
  //     inputNode.type,
  //     Types.dict(Types.list(Types.maybe('string')))
  //   ) &&
  //   frame.domain != null
  // ) {
  //   tableState = TableState.updateColumnSelect(
  //     tableState,
  //     yColId,
  //     CG.varNode(exampleRow.type, 'row')
  //   );
  //   tableState = TableState.updateColumnSelect(
  //     tableState,
  //     xColId,
  //     Op.opCount({
  //       arr: CG.varNode(Types.list(exampleRow.type), 'row') as any,
  //     })
  //   );
  //   tableState = TableState.updateColumnSelect(
  //     tableState,
  //     colorColId,
  //     CG.varNode('string', 'key')
  //   );
  //   tableState = {...tableState, groupBy: [yColId, colorColId]};
  //   axisSettings.x = {
  //     noTitle: true,
  //   };
  //   axisSettings.y = {
  //     noTitle: true,
  //   };
  //   legendSettings.color = {
  //     noLegend: true,
  //   };
  // }

  return {
    table: tableState,
    dims: {
      x: xColId,
      y: yColId,
      color: colorColId,
      label: labelColId,
      tooltip: tooltipColId,
    },
    axisSettings,
    legendSettings,
  };
}

const useConfig = (
  inputNode: Types.Node,
  propsConfig?: PlotState.PlotConfig
): PlotState.PlotConfig => {
  const {frame} = usePanelContext();

  const newConfig = useMemo(() => {
    // TODO: (ts) Should reset config when the incoming type changes (similar to table - maybe a common refactor?)
    let config =
      propsConfig == null || propsConfig.dims == null
        ? defaultPlot(inputNode, frame)
        : propsConfig;
    if (
      config.axisSettings == null ||
      config.axisSettings.x == null ||
      config.axisSettings.y == null
    ) {
      config = {
        ...config,
        axisSettings: {
          x: {},
          y: {},
        },
      };
    }
    if (config.legendSettings == null || config.legendSettings.color == null) {
      config = {
        ...config,
        legendSettings: {
          color: {},
        },
      };
    }
    return config;
  }, [propsConfig, inputNode, frame]);

  const refinedTableState = useTableStateWithRefinedExpressions(
    inputNode,
    newConfig.table
  );
  return useMemo(() => {
    return {
      ...newConfig,
      table: refinedTableState,
    };
  }, [newConfig, refinedTableState]);
};

type PanelPlotProps = Panel2.PanelProps<typeof inputType, PlotState.PlotConfig>;

// TODO, this produces ugly keys
const fixKeyForVega = (key: string) => {
  return key
    .replace(/\./g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/\\/g, '');
};

export const DimConfig: React.FC<{
  dimName: string;
  input: PanelPlotProps['input'];
  colId: TableState.ColumnId;
  // Hack: you can pass an extra colID to include in when grouping is
  // toggled for this dim. This is used to toggle grouping for color/label
  // together.
  extraGroupColId?: string;
  tableConfig: TableState.TableState;
  updateTableConfig: (newTableState: TableState.TableState) => void;
}> = props => {
  const {colId, tableConfig, input, updateTableConfig} = props;

  const inputNode = input.path;

  const updateDim = useCallback(
    (node: Types.Node) => {
      updateTableConfig(
        TableState.updateColumnSelect(tableConfig, colId, node)
      );
    },
    [updateTableConfig, tableConfig, colId]
  );

  const {frame} = usePanelContext();
  const {rowsNode} = useMemo(
    () => TableState.tableGetResultTableNode(tableConfig, inputNode, frame),
    [tableConfig, inputNode, frame]
  );
  const cellFrame = useMemo(
    () =>
      TableState.getCellFrame(
        inputNode,
        rowsNode,
        frame,
        tableConfig.groupBy,
        tableConfig.columnSelectFunctions,
        colId
      ),
    [
      colId,
      inputNode,
      rowsNode,
      frame,
      tableConfig.columnSelectFunctions,
      tableConfig.groupBy,
    ]
  );

  return (
    <div style={{flexDirection: 'column', flexGrow: 1}}>
      <ThemeProvider theme={QueryEditorStyles.themes.light}>
        <ExpressionEditor.ExpressionEditor
          frame={cellFrame}
          node={tableConfig.columnSelectFunctions[colId]}
          updateNode={updateDim}
        />
      </ThemeProvider>
    </div>
  );
};

export const SimpleDimConfig: React.FC<{
  input: PanelPlotProps['input'];
  colId: TableState.ColumnId;
  tableConfig: TableState.TableState;
  updateTableConfig: (newTableState: TableState.TableState) => void;
}> = props => {
  const {colId, tableConfig, input, updateTableConfig} = props;

  const inputNode = input.path;
  const {frame} = usePanelContext();

  const updateDim = useCallback(
    (node: Types.Node) => {
      updateTableConfig(
        TableState.updateColumnSelect(tableConfig, colId, node)
      );
    },
    [updateTableConfig, tableConfig, colId]
  );

  const {rowsNode} = useMemo(
    () => TableState.tableGetResultTableNode(tableConfig, inputNode, frame),
    [tableConfig, inputNode, frame]
  );
  const cellFrame = useMemo(
    () =>
      TableState.getCellFrame(
        inputNode,
        rowsNode,
        {},
        tableConfig.groupBy,
        tableConfig.columnSelectFunctions,
        colId
      ),
    [
      colId,
      inputNode,
      rowsNode,
      tableConfig.columnSelectFunctions,
      tableConfig.groupBy,
    ]
  );

  return (
    <div style={{flexDirection: 'column', flexGrow: 1}}>
      <ExpressionEditor.ExpressionEditor
        frame={cellFrame}
        node={tableConfig.columnSelectFunctions[colId]}
        updateNode={updateDim}
      />
    </div>
  );
};

export const AxisConfig: React.FC<{
  dimName: string;
  axisSettings: PlotState.AxisSetting;
  updateAxisSettings: (
    dimName: string,
    newAxisSettings: Partial<PlotState.AxisSetting>
  ) => void;
}> = props => {
  const {axisSettings, dimName, updateAxisSettings} = props;

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 8,
        alignItems: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
      <div>Title</div>
      <Checkbox
        style={{marginLeft: 8}}
        checked={!axisSettings.noTitle}
        name={`showlabel-${dimName}`}
        onChange={(e, value) => {
          updateAxisSettings(dimName, {
            noTitle: !value.checked,
          });
        }}
      />
      <div style={{marginLeft: 16}}>Labels</div>
      <Checkbox
        style={{marginLeft: 8}}
        checked={!axisSettings.noLabels}
        name={`showlabel-${dimName}`}
        onChange={(e, value) => {
          updateAxisSettings(dimName, {
            noLabels: !value.checked,
          });
        }}
      />
      <div style={{marginLeft: 16}}>Ticks</div>
      <Checkbox
        style={{marginLeft: 8}}
        checked={!axisSettings.noTicks}
        name={`showlabel-${dimName}`}
        onChange={async (e, value) => {
          updateAxisSettings(dimName, {
            noTicks: !value.checked,
          });
        }}
      />
    </div>
  );
};

export const LegendConfig: React.FC<{
  dimName: string;
  legendSettings: PlotState.LegendSetting;
  updateLegendSettings: (
    dimName: string,
    newLegendSettings: Partial<PlotState.LegendSetting>
  ) => void;
}> = props => {
  const {legendSettings, dimName, updateLegendSettings} = props;

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 8,
        alignItems: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
      Legend
      <Checkbox
        style={{marginLeft: 8}}
        checked={!legendSettings.noLegend}
        name={`showlabel-${dimName}`}
        onChange={(e, value) => {
          updateLegendSettings(dimName, {
            noLegend: !value.checked,
          });
        }}
      />
    </div>
  );
};

const PanelPlotConfig: React.FC<PanelPlotProps> = props => {
  const {input} = props;

  const inputNode = useMemo(
    () => TableType.normalizeTableLike(input.path),
    [input.path]
  );
  const typedInputNodeUse = LLReact.useNodeWithServerType(inputNode);
  const newProps = useMemo(() => {
    return {
      ...props,
      input: {
        ...props.input,
        path: typedInputNodeUse.result as any,
      },
    };
  }, [props, typedInputNodeUse.result]);
  if (typedInputNodeUse.loading) {
    return <Loader />;
  } else if (typedInputNodeUse.result.nodeType === 'void') {
    return <></>;
  } else {
    return <PanelPlotConfigInner {...newProps} />;
  }
};

const PanelPlotConfigInner: React.FC<PanelPlotProps> = props => {
  const {input, updateConfig: propsUpdateConfig} = props;

  const inputNode = input.path;

  const config = useConfig(inputNode, props.config);

  const resetConfig = useCallback(() => {
    propsUpdateConfig({dims: undefined});
  }, [propsUpdateConfig]);

  const updateConfig = useCallback(
    (newConfig: Partial<PlotState.PlotConfig>) => {
      propsUpdateConfig({
        ...config,
        ...newConfig,
      });
    },
    [config, propsUpdateConfig]
  );

  const tableConfig = config.table;
  const updateTableConfig = useCallback(
    (newTableConfig: TableState.TableState) => {
      updateConfig({
        table: newTableConfig,
      });
    },
    [updateConfig]
  );

  return (
    <div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>X Dim</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}
          data-test="x-dim-config">
          <DimConfig
            dimName="x"
            colId={config.dims.x}
            input={input}
            tableConfig={tableConfig}
            updateTableConfig={updateTableConfig}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>Y Dim</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}
          data-test="y-dim-config">
          <DimConfig
            dimName="y"
            colId={config.dims.y}
            input={input}
            tableConfig={tableConfig}
            updateTableConfig={updateTableConfig}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>Label</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}
          data-test="label-dim-config">
          <SimpleDimConfig
            colId={config.dims.label}
            input={input}
            tableConfig={tableConfig}
            updateTableConfig={updateTableConfig}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>Tooltip</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}
          data-test="tooltip-dim-config">
          <DimConfig
            dimName="tooltip"
            colId={config.dims.tooltip}
            input={input}
            tableConfig={tableConfig}
            updateTableConfig={updateTableConfig}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>Mark</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}
          data-test="mark-dim-config">
          <ModifiedDropdown
            selection
            style={{width: '100%'}}
            placeholder="auto"
            value={config.mark}
            options={(
              [
                {
                  key: 'auto',
                  value: null,
                  text: 'auto',
                },
              ] as any
            ).concat(
              PlotState.MARK_OPTIONS.map(o => ({
                key: o,
                value: o,
                text: o,
              }))
            )}
            onChange={(e, {value}) =>
              updateConfig({mark: value as PlotState.MarkOption})
            }
          />
        </div>
      </div>
      <Button size="tiny" onClick={resetConfig}>
        {'Reset & Automate Plot'}
      </Button>
    </div>
  );
};

const PanelPlot2: React.FC<PanelPlotProps> = props => {
  const {input} = props;

  const inputNode = useMemo(
    () => TableType.normalizeTableLike(input.path),
    [input.path]
  );
  const typedInputNodeUse = LLReact.useNodeWithServerType(inputNode);
  const newProps = useMemo(() => {
    return {
      ...props,
      input: {
        ...props.input,
        path: typedInputNodeUse.result as any,
      },
    };
  }, [props, typedInputNodeUse.result]);
  if (typedInputNodeUse.loading) {
    return <Loader />;
  } else if (typedInputNodeUse.result.nodeType === 'void') {
    return <></>;
  } else {
    return <PanelPlot2Inner {...newProps} />;
  }
};

const stringIsColorLike = (val: string): boolean => {
  return (
    val.match('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$') != null || // matches hex code
    (val.startsWith('rgb(') && val.endsWith(')')) || // rgb
    (val.startsWith('hsl(') && val.endsWith(')')) // hsl
  );
};

const useVegaReadyTable = (
  table: TableState.TableState,
  dims: PlotState.PlotConfig['dims'],
  frame: Code.Frame
) => {
  // This function assigns smart defaults for the color of a point based on the label.
  return useMemo(() => {
    const labelSelectFn = table.columnSelectFunctions[dims.label];
    if (labelSelectFn.nodeType !== 'void') {
      const labelType = TableState.getTableColType(table, dims.label);
      if (frame.runColors != null) {
        if (Types.isAssignableTo(labelType, Types.maybe('run'))) {
          let retTable = TableState.updateColumnSelect(
            table,
            dims.color,
            Op.opPick({
              obj: CG.varNode(frame.runColors.type, 'runColors'),
              key: Op.opRunId({
                run: labelSelectFn,
              }),
            })
          );

          retTable = TableState.updateColumnSelect(
            retTable,
            dims.label,
            Op.opRunName({
              run: labelSelectFn,
            })
          );

          return retTable;
        } else if (
          labelSelectFn.nodeType === 'output' &&
          labelSelectFn.fromOp.name === 'run-name'
        ) {
          return TableState.updateColumnSelect(
            table,
            dims.color,
            Op.opPick({
              obj: CG.varNode(frame.runColors.type, 'runColors'),
              key: Op.opRunId({
                run: labelSelectFn.fromOp.inputs.run,
              }),
            })
          );
        }
      }

      if (
        Types.isAssignableTo(
          labelType,
          Types.maybe(Types.union(['number', 'string', 'boolean']))
        )
      ) {
        return TableState.updateColumnSelect(table, dims.color, labelSelectFn);
      }
    }
    return table;
  }, [table, dims, frame.runColors]);
};

const PanelPlot2Inner: React.FC<PanelPlotProps> = props => {
  const {input, updateConfig} = props;

  useEffect(() => {
    recordEvent('VIEW');
  }, []);

  // TODO(np): Hack to detect when we are on an activity dashboard
  const isOrgDashboard =
    Object.keys(useContext(ActivityDashboardContext).frame).length > 0;

  const inputNode = input.path;
  const {frame} = usePanelContext();
  const config = useConfig(inputNode, props.config);
  const {table, dims} = config;
  const vegaReadyTable = useVegaReadyTable(table, dims, frame);

  const {resultNode} = useMemo(
    () => TableState.tableGetResultTableNode(vegaReadyTable, inputNode, frame),
    [vegaReadyTable, inputNode, frame]
  );

  const flatResultNode = useMemo(
    () => Op.opUnnest({arr: resultNode as any}),
    [resultNode]
  );
  const result = LLReact.useNodeValue(flatResultNode);
  const plotTable = useMemo(
    () => (result.loading ? [] : (result.result as any[])),
    [result]
  );
  const flatPlotTable = useMemo(() => {
    return plotTable.map((row, index) => {
      const newRow = _.mapKeys(row, (v, k) => fixKeyForVega(k));
      return newRow;
    });
  }, [plotTable]);

  // If the color field can actually be interpreted as a color
  // then consider it a range, else we should use the default
  // color scheme from vega.
  const colorFieldIsRange = useMemo(() => {
    const colorKey = fixKeyForVega(
      TableState.getTableColumnName(
        vegaReadyTable.columnNames,
        vegaReadyTable.columnSelectFunctions,
        dims.color
      )
    );
    for (const row of flatPlotTable) {
      if (row[colorKey] != null) {
        return stringIsColorLike(String(row[colorKey]));
      }
    }
    return false;
  }, [
    vegaReadyTable.columnNames,
    vegaReadyTable.columnSelectFunctions,
    dims.color,
    flatPlotTable,
  ]);

  const vegaSpec = useMemo(() => {
    const dimTypes = _.mapValues(dims, colId =>
      TableState.getTableColType(vegaReadyTable, colId)
    );

    let mark: PlotState.MarkOption = 'point';
    let xAxisType: string | undefined;
    let yAxisType: string | undefined;
    let colorAxisType: string | undefined;
    const objType = Types.listObjectType(resultNode.type);

    if (objType != null && objType !== 'invalid') {
      if (!Types.isTypedDict(objType)) {
        throw new Error('invalid');
      }
      if (
        Types.isAssignableTo(dimTypes.x, Types.maybe('number')) &&
        Types.isAssignableTo(dimTypes.y, Types.maybe('number'))
      ) {
        mark = 'point';
      } else if (
        Types.isAssignableTo(
          dimTypes.x,
          Types.union(['string', 'date', Types.numberBin])
        ) &&
        Types.isAssignableTo(dimTypes.y, Types.maybe('number'))
      ) {
        mark = 'bar';
      } else if (
        Types.isAssignableTo(dimTypes.x, Types.maybe('number')) &&
        Types.isAssignableTo(dimTypes.y, Types.union(['string', 'date']))
      ) {
        mark = 'bar';
      } else if (
        Types.isAssignableTo(dimTypes.x, Types.list(Types.maybe('number'))) &&
        Types.isAssignableTo(dimTypes.y, Types.union(['string', 'number']))
      ) {
        mark = 'boxplot';
      } else if (
        Types.isAssignableTo(dimTypes.y, Types.list(Types.maybe('number'))) &&
        Types.isAssignableTo(dimTypes.x, Types.union(['string', 'number']))
      ) {
        mark = 'boxplot';
      } else if (
        Types.isAssignableTo(dimTypes.x, Types.list('number')) &&
        Types.isAssignableTo(dimTypes.y, Types.list('number'))
      ) {
        mark = 'line';
      }
      if (
        Types.isAssignableTo(
          dimTypes.x,
          Types.oneOrMany(Types.maybe('number'))
        ) ||
        Types.isAssignableTo(dimTypes.x, Types.numberBin)
      ) {
        xAxisType = 'quantitative';
      } else if (
        Types.isAssignableTo(dimTypes.x, Types.oneOrMany(Types.maybe('string')))
      ) {
        xAxisType = 'nominal';
      } else if (
        Types.isAssignableTo(dimTypes.x, Types.oneOrMany(Types.maybe('date')))
      ) {
        xAxisType = 'temporal';
      }
      if (
        Types.isAssignableTo(
          dimTypes.y,
          Types.oneOrMany(Types.maybe('number'))
        ) ||
        Types.isAssignableTo2(dimTypes.y, Types.numberBin)
      ) {
        yAxisType = 'quantitative';
      } else if (
        Types.isAssignableTo(dimTypes.y, Types.oneOrMany(Types.maybe('string')))
      ) {
        yAxisType = 'nominal';
      } else if (
        Types.isAssignableTo(dimTypes.y, Types.oneOrMany(Types.maybe('date')))
      ) {
        yAxisType = 'temporal';
      }
      if (dimTypes.color != null) {
        if (
          Types.isAssignableTo(
            dimTypes.color,
            Types.oneOrMany(Types.maybe('number'))
          )
        ) {
          colorAxisType = 'quantitative';
        } else if (
          Types.isAssignableTo(
            dimTypes.color,
            Types.oneOrMany(Types.maybe(Types.union(['string', 'boolean'])))
          )
        ) {
          colorAxisType = 'nominal';
        }
      }
    }
    const newSpec = _.merge(
      isOrgDashboard
        ? _.omit(_.cloneDeep(PLOT_TEMPLATE), ['selection'])
        : _.cloneDeep(PLOT_TEMPLATE),
      isOrgDashboard ? _.cloneDeep(ORG_DASHBOARD_TEMPLATE_OVERLAY) : {},
      config?.vegaOverlay ?? {}
    );

    if (xAxisType != null) {
      if (Types.isAssignableTo2(dimTypes.x, Types.numberBin)) {
        newSpec.encoding.x = {
          field:
            fixKeyForVega(
              TableState.getTableColumnName(
                vegaReadyTable.columnNames,
                vegaReadyTable.columnSelectFunctions,
                dims.x
              )
            ) + '.start',
          type: xAxisType,
        };
        newSpec.encoding.x2 = {
          field:
            fixKeyForVega(
              TableState.getTableColumnName(
                vegaReadyTable.columnNames,
                vegaReadyTable.columnSelectFunctions,
                dims.x
              )
            ) + '.stop',
          type: xAxisType,
        };
      } else {
        newSpec.encoding.x = {
          field: fixKeyForVega(
            TableState.getTableColumnName(
              vegaReadyTable.columnNames,
              vegaReadyTable.columnSelectFunctions,
              dims.x
            )
          ),
          type: xAxisType,
        };
      }
      if (xAxisType === 'temporal') {
        // TODO: hard-coded to month, we should encode this in the
        // type system and make it automatic (we know we used opDateRoundMonth)
        newSpec.encoding.x.timeUnit = isOrgDashboard ? 'yearweek' : 'yearmonth';
      }
    }
    if (yAxisType != null) {
      if (Types.isAssignableTo2(dimTypes.y, Types.numberBin)) {
        newSpec.encoding.y = {
          field:
            fixKeyForVega(
              TableState.getTableColumnName(
                vegaReadyTable.columnNames,
                vegaReadyTable.columnSelectFunctions,
                dims.y
              )
            ) + '.start',
          type: yAxisType,
        };
        newSpec.encoding.y2 = {
          field:
            fixKeyForVega(
              TableState.getTableColumnName(
                vegaReadyTable.columnNames,
                vegaReadyTable.columnSelectFunctions,
                dims.y
              )
            ) + '.stop',
          type: yAxisType,
        };
      } else {
        newSpec.encoding.y = {
          field: fixKeyForVega(
            TableState.getTableColumnName(
              vegaReadyTable.columnNames,
              vegaReadyTable.columnSelectFunctions,
              dims.y
            )
          ),
          type: yAxisType,
        };
        if (yAxisType === 'temporal') {
          // TODO: hard-coded to month, we should encode this in the
          // type system and make it automatic (we know we used opDateRoundMonth)
          newSpec.encoding.y.timeUnit = isOrgDashboard
            ? 'yearweek'
            : 'yearmonth';
        }
      }
    }
    if (colorAxisType != null) {
      newSpec.encoding.color = {
        field: fixKeyForVega(
          TableState.getTableColumnName(
            vegaReadyTable.columnNames,
            vegaReadyTable.columnSelectFunctions,
            dims.color
          )
        ),
        type: colorAxisType,
      };
      if (vegaReadyTable.columnSelectFunctions[dims.label].type !== 'invalid') {
        newSpec.encoding.color.field = fixKeyForVega(
          TableState.getTableColumnName(
            vegaReadyTable.columnNames,
            vegaReadyTable.columnSelectFunctions,
            dims.label
          )
        );
        if (colorFieldIsRange) {
          newSpec.encoding.color.scale = {
            range: {
              field: fixKeyForVega(
                TableState.getTableColumnName(
                  vegaReadyTable.columnNames,
                  vegaReadyTable.columnSelectFunctions,
                  dims.color
                )
              ),
            },
          };
        }
      }
    }
    newSpec.mark.type = config.mark ?? mark;

    const {axisSettings, legendSettings} = config;
    if (newSpec.encoding.x != null) {
      if (newSpec.encoding.x.axis == null) {
        // TODO(np): fixme (Applied on org dash only)
        newSpec.encoding.x.axis = isOrgDashboard
          ? {
              format: '%m/%d',
              grid: false,
            }
          : {};
      }
      // TODO(np): fixme
      if (axisSettings.x.scale != null) {
        newSpec.encoding.x.scale = axisSettings.x.scale;
      }
      if (axisSettings.x.noTitle) {
        newSpec.encoding.x.axis.title = null;
      }
      if (axisSettings.x.noLabels) {
        newSpec.encoding.x.axis.labels = false;
      }
      if (axisSettings.x.noTicks) {
        newSpec.encoding.x.axis.ticks = false;
      }
      if (
        axisSettings.x.noTitle &&
        axisSettings.x.noLabels &&
        axisSettings.x.noTicks
      ) {
        newSpec.encoding.x.axis = false;
      }
    }
    if (newSpec.encoding.y != null) {
      if (newSpec.encoding.y.axis == null) {
        newSpec.encoding.y.axis = {};
      }
      // TODO(np): fixme
      if (axisSettings.y.scale != null) {
        newSpec.encoding.y.scale = axisSettings.y.scale;
      }
      if (axisSettings.y.noTitle) {
        newSpec.encoding.y.axis.title = null;
      }
      if (axisSettings.y.noLabels) {
        newSpec.encoding.y.axis.labels = false;
      }
      if (axisSettings.y.noTicks) {
        newSpec.encoding.y.axis.ticks = false;
      }
      if (
        axisSettings.y.noTitle &&
        axisSettings.y.noLabels &&
        axisSettings.y.noTicks
      ) {
        newSpec.encoding.y.axis = false;
      }
    }
    if (newSpec.encoding.color != null) {
      if (legendSettings.color.noLegend) {
        newSpec.encoding.color.legend = false;
      }
    }
    return newSpec;
  }, [
    config,
    dims,
    isOrgDashboard,
    resultNode.type,
    vegaReadyTable,
    colorFieldIsRange,
  ]);

  const [toolTipPos, setTooltipPos] = useState<{
    x: number | undefined;
    y: number | undefined;
    value: any;
  }>({x: undefined, y: undefined, value: undefined});
  const handleTooltip = useCallback(
    (toolTipHandler: any, event: any, item: any, value: any) => {
      const {x, y} = calculatePosition(
        event,
        toolTipRef.current?.getBoundingClientRect()!,
        10,
        10
      );
      if (value == null) {
        setTooltipPos({x: undefined, y: undefined, value: undefined});
      } else {
        setTooltipPos({x, y, value});
      }
    },
    []
  );

  const toolTipRef = useRef<HTMLDivElement>(null);
  // console.log('PLOT TABLE', plotTable);
  // console.log('FLAT PLOT TABLE', flatPlotTable);
  // console.log('VEGA SPEC', JSON.stringify(vegaSpec, undefined, 2));
  const tooltipNode = useMemo(() => {
    const valueResultIndex = toolTipPos.value?._index;
    if (valueResultIndex == null) {
      return CG.voidNode();
    }
    const row = Op.opIndex({
      arr: resultNode,
      index: Op.constNumber(valueResultIndex),
    });
    const toolTipFn = vegaReadyTable.columnSelectFunctions[dims.tooltip];
    if (toolTipFn.nodeType === 'void' || toolTipFn.type === 'invalid') {
      return row;
    }
    return Op.opPick({
      obj: row,
      key: Op.constString(
        escapeDots(
          TableState.getTableColumnName(
            vegaReadyTable.columnNames,
            vegaReadyTable.columnSelectFunctions,
            dims.tooltip
          )
        )
      ),
    });
  }, [
    dims.tooltip,
    resultNode,
    vegaReadyTable.columnNames,
    vegaReadyTable.columnSelectFunctions,
    toolTipPos.value,
  ]);
  const {handler} = useMemo(
    () =>
      getPanelStacksForType(tooltipNode.type, undefined, {
        excludeTable: true,
        excludePlot: true,
      }),
    [tooltipNode.type]
  );
  const updateTooltipConfig = useCallback(
    (newPanelConfig: any) => {
      return updateConfig({
        table: TableState.updateColumnPanelConfig(
          vegaReadyTable,
          config.dims.tooltip,
          newPanelConfig
        ),
      });
    },
    [vegaReadyTable, config.dims.tooltip, updateConfig]
  );

  const contents = useGatedValue(
    <>
      {result.loading ? (
        <Loader />
      ) : (
        <div style={{width: '100%', height: '100%'}}>
          <TooltipPortal>
            <div
              ref={toolTipRef}
              style={{
                position: 'fixed',
                visibility: toolTipPos.x == null ? 'hidden' : 'visible',
                borderRadius: 2,
                padding: 4,
                top: toolTipPos.y,
                left: toolTipPos.x,
                // 2147483605 is the default z-index for panel models to get over
                // intercom. Boy that is nasty - should make better
                zIndex: 2147483605 + 9000,
                background: '#fff',
                boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
                ...getPanelStackDims(handler, tooltipNode.type, config),
              }}>
              {tooltipNode.nodeType !== 'void' && handler != null && (
                <PanelComp2
                  input={{path: tooltipNode}}
                  inputType={tooltipNode.type}
                  loading={false}
                  panelSpec={handler}
                  configMode={false}
                  context={props.context}
                  config={config.table.columns[config.dims.tooltip].panelConfig}
                  updateConfig={updateTooltipConfig}
                  updateContext={props.updateContext}
                />
              )}
            </div>
          </TooltipPortal>
          <CustomPanelRenderer
            spec={vegaSpec}
            loading={false}
            slow={false}
            data={flatPlotTable}
            userSettings={{
              // TODO: I'm putting ! in here cause our fieldSettings
              // doesn't allow undefined. Fix that to allow it.
              fieldSettings: {title: config.title!},
              stringSettings: {
                title: '',
              },
            }}
            handleTooltip={handleTooltip}
          />
        </div>
      )}
      {/* Plot config
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {`${plotType}, ${xAxisType}, ${yAxisType}, ${colorAxisType}`}
      </pre>
      Vega spec
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {JSON.stringify(vegaSpec, undefined, 2)}
      </pre> */}
      {/* Compute graph query
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {toString(resultNode)}
      </pre> */}
      {/* <pre style={{fontSize: 12}}>
        {JSON.stringify(plotTable, undefined, 2)}
      </pre>  */}
      {/* Query result
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {JSON.stringify(flatPlotTable, undefined, 2)}
      </pre> */}
      {/* Input row type
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {Types.toString(exampleInputFrame.x.type)}
      </pre>
      Grouped row type
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {Types.toString(exampleRowFrame.x.type)}
      </pre>{' '} */}
    </>,
    x => !result.loading
  );

  return (
    <div
      data-test="panel-plot-2-wrapper"
      style={{height: '100%', width: '100%'}}
      className={result.loading ? 'loading' : ''}>
      {contents}
    </div>
  );
};

const TooltipPortal: React.FC<{}> = props => {
  const toolTipRef = useRef(document.createElement('div'));
  useEffect(() => {
    const el = toolTipRef.current;
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);
  return ReactDOM.createPortal(props.children, toolTipRef.current);
};

/* eslint-disable no-template-curly-in-string */

export const Spec: Panel2.PanelSpec = {
  id: 'plot',
  ConfigComponent: PanelPlotConfig,
  Component: PanelPlot2,
  inputType,
  defaultFixedSize: {
    width: 200,
    height: (9 / 16) * 200,
  },
};

const PLOT_TEMPLATE: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  data: {
    name: 'wandb',
  },
  padding: 1,
  title: '${field:title}',
  mark: {
    tooltip: {
      content: 'data',
    },
  } as any,
  selection: {
    grid: {
      type: 'interval',
      bind: 'scales',
    },
  },
  encoding: {
    // opacity: {
    //   value: 0.6,
    // },
  },
};

const ORG_DASHBOARD_TEMPLATE_OVERLAY = {
  config: {
    legend: {
      disable: true,
    },
    axis: {
      // labels: false,
      title: null,
    },
    style: {
      cell: {
        stroke: 'transparent',
      },
    },
  },
};
