import styled from 'styled-components';
import * as globals from '../../css/globals.styles';

export const LabeledOptionBlock = styled.div`
  &&&&& {
    display: flex;
  }
`;

export const OptionLabel = styled.span`
  &&&&& {
    width: 80px;
    flex-shrink: 0;
    align-self: center;
    color: ${globals.gray700};
  }
`;

export const Option = styled.div`
  &&&&& {
    flex-grow: 1;
    padding: 4px;
    justify-content: center;
    align-items: center;
  }
`;
