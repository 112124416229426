import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {History} from 'history';

import auth from './auth';
import globalReducer from '../state/global/reducer';
import media from '../state/media/reducer';
import location from './location';
import report from './report';
import views from '../state/views/reducer';
import polling from '../state/polling/reducer';
import reports from '../state/reports/reducer';
import sweeps from '../state/sweeps/reducer';
import runs from '../state/runs-low/reducer';
import workspaces from '../state/workspaces/reducer';
import viewer from '../state/viewer/reducer';

export default (history: History) =>
  combineReducers({
    auth,
    global: globalReducer,
    media,
    location,
    router: connectRouter(history),
    report,
    views,
    workspaces,
    runs,
    polling,
    reports,
    sweeps,
    viewer,
  });
