// This page is a simple effect tied to a route so we can trigger the effect
// with routing logic, like redirects from the onboarding flow.
import React, {useEffect} from 'react';
import {usePushGalleryDiscussion} from '../state/reports/hooks';
import * as Report from '../util/report';
import WandbLoader from '../components/WandbLoader';
import {useViewer} from '../state/viewer/hooks';

const GalleryCreateDiscussion: React.FC = () => {
  const viewer = useViewer();
  const pushDraftReport = usePushGalleryDiscussion(
    viewer?.username ?? '',
    'Discussion Title'
  );

  useEffect(() => {
    const report = Report.getEmptyReportConfig();
    pushDraftReport(report);
  }, [pushDraftReport]);

  return <WandbLoader />;
};

export default GalleryCreateDiscussion;
