import _ from 'lodash';
import React, {createContext, useMemo} from 'react';
import config from '../config';
import {
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsQuery,
  OrganizationSubscriptionsQuery,
} from '../generated/graphql';
import {Org} from '../util/pricing';
import makeComp from '../util/profiler';
import {secondsToHours} from '../util/time';

type GlobalNudgeBarState = {
  organization: Org | null;
  renderedNudgeBar: NudgeBarType | null;
  maxHours: number | null;
  aggressive?: boolean;
};

type GlobalNudgeBarStates = {
  states: GlobalNudgeBarState[] | null;
};

export const GlobalNudgeBarContext = createContext<GlobalNudgeBarStates>({
  states: [],
});

type GlobalNudgeBarUpdaterState = {
  refetch: () => Promise<any>;
};
export const GlobalNudgeBarUpdaterContext =
  createContext<GlobalNudgeBarUpdaterState>({
    refetch: () => Promise.resolve(),
  });

export const GlobalNudgeBarContextProvider = makeComp(
  ({children}) => {
    const {data, refetch} = useOrganizationSubscriptionsQuery();

    const viewerOrgs = useMemo(() => data?.viewer?.organizations ?? [], [data]);
    const contextValue = useMemo(
      () => getGlobalNudgeBarState(viewerOrgs),
      [viewerOrgs]
    );

    const updaterContextValue = useMemo(() => ({refetch}), [refetch]);

    return (
      <GlobalNudgeBarContext.Provider value={contextValue}>
        <GlobalNudgeBarUpdaterContext.Provider value={updaterContextValue}>
          {children}
        </GlobalNudgeBarUpdaterContext.Provider>
      </GlobalNudgeBarContext.Provider>
    );
  },
  {id: 'GlobalNudgeBarContext', memo: true}
);

export default GlobalNudgeBarContextProvider;

type ViewerOrgs = NonNullable<
  OrganizationSubscriptionsQuery['viewer']
>['organizations'];
type ViewerOrg = ViewerOrgs[number];

function getGlobalNudgeBarState(viewerOrgs: ViewerOrgs): GlobalNudgeBarStates {
  const states: GlobalNudgeBarState[] = [];

  for (const viewerOrg of viewerOrgs) {
    const nudgeBarForOrg = getNudgeBarTypeForViewerOrg(viewerOrg);
    if (nudgeBarForOrg != null) {
      const organization = orgFromViewerOrg(viewerOrg);
      const renderedNudgeBar = nudgeBarForOrg.type;
      const maxHours = nudgeBarForOrg.maxComputeHours;
      const aggressive = nudgeBarForOrg.aggressive;
      states.push({organization, renderedNudgeBar, maxHours, aggressive});
    }
  }

  return {states};
}

function orgFromViewerOrg(org: ViewerOrg): Org {
  let flags = {};
  try {
    if (org.flags != null) {
      flags = JSON.parse(org.flags);
    }
  } catch {
    // do nothing
  }

  return {
    id: org.id,
    name: org.name,
    memberCount: org.members.length + org.pendingInvites.length,
    teams: org.teams,
    flags,
  };
}

export type NudgeBarType =
  | 'standardComputeHours'
  | 'advancedComputeHours'
  | 'enterpriseToStandard'
  | 'enterpriseToAdvanced';

type NudgeBarInfo = {
  type: NudgeBarType;
  maxComputeHours: number | null;
  aggressive?: boolean;
};

function getNudgeBarTypeForViewerOrg(org: ViewerOrg): NudgeBarInfo | null {
  const totalComputeHoursInSeconds = org.teams
    .map(t => t.computeHours)
    .reduce((a, b) => a + b, 0);
  const totalOldComputeSeconds = org.teams
    .map(t => t.oldComputeHours)
    .reduce((a, b) => a + b, 0);

  for (const getNudgeBarType of [
    getNudgeBarTypeForComputeHourSub,
    getNudgeBarTypeForEnterpriseSub,
  ]) {
    const nb = getNudgeBarType(
      org,
      totalComputeHoursInSeconds,
      totalOldComputeSeconds
    );
    if (nb != null) {
      return nb;
    }
  }

  return null;
}

function getNudgeBarTypeForComputeHourSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number,
  totalOldComputeSeconds: number
): NudgeBarInfo | null {
  const computeHourSub = org.subscriptions.find(
    sub =>
      typeof sub.privileges.compute_hours === 'number' &&
      _.includes(
        ['basic', 'standard_yearly', 'standard_monthly'],
        sub.plan.name
      )
  );
  if (computeHourSub == null) {
    return null;
  }

  const maxComputeHours = computeHourSub.privileges.compute_hours;
  if (secondsToHours(totalComputeHoursInSeconds) <= maxComputeHours) {
    return null;
  }
  const aggressive = secondsToHours(totalOldComputeSeconds) >= maxComputeHours;

  if (computeHourSub.plan.name === 'basic') {
    return {type: 'standardComputeHours', maxComputeHours, aggressive};
  }

  return {type: 'advancedComputeHours', maxComputeHours, aggressive};
}

function getNudgeBarTypeForEnterpriseSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): NudgeBarInfo | null {
  const oldSub = org.subscriptions.some(sub => {
    return (
      sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial &&
      sub.status === OrganizationSubscriptionStatus.Enabled &&
      sub.expiresAt != null &&
      new Date(sub.expiresAt + 'Z') <= new Date()
    );
  });
  if (!oldSub) {
    return null;
  }

  if (
    totalComputeHoursInSeconds > config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS
  ) {
    return {type: 'enterpriseToAdvanced', maxComputeHours: null};
  }

  if (totalComputeHoursInSeconds > config.MAX_COMPUTE_HOURS_IN_SECONDS) {
    return {type: 'enterpriseToStandard', maxComputeHours: null};
  }

  return null;
}
