import styled from 'styled-components';
import WBSearchableSelect from './elements/WBSearchableSelect';
import * as WBSearchableSelectStyles from './elements/WBSearchableSelect.styles';

export const StyledSearchableSelect = styled(WBSearchableSelect)`
  ${WBSearchableSelectStyles.SearchableInput} {
    border: none;
    background: none;
  }
`;
