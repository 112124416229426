import {apolloClient} from '../setup';
import {ProjectFieldsQueryData} from '../state/graphql/projectFieldsQuery';
import * as Generated from '../generated/graphql';
import * as Run from '../util/runs';
import {getIconNameForKey} from './uihelpers';
import * as Obj from '@wandb/cg/browser/utils/obj';
import {WBSuggesterOptionFetcher} from '../components/elements/WBSuggester';
import {WBMenuOption} from '@wandb/ui';

function patternFromQuery(q: string) {
  if (q === '') {
    return null;
  }
  return (
    '%' +
    q
      .split('')
      .map(c => ('%?_'.indexOf(c) !== -1 ? '\\' + c : c))
      .join('%') +
    '%'
  );
}

export function makeFetchProjectFieldOptions(
  entityName: string,
  projectName: string,
  opts: {
    columns?: string[];
    types?: string[];
    filterBy?: (key: Run.Key) => boolean;
    extraKeys?: string[];
  } = {}
) {
  const {
    columns = ['config', 'summary_metrics'],
    types = ['string', 'number', 'boolean', 'other'],
    filterBy = () => true,
    extraKeys = [],
  } = opts;
  const fetch: WBSuggesterOptionFetcher = async (query, {count, cursor}) => {
    const data = await apolloClient.query<
      ProjectFieldsQueryData,
      Generated.ProjectFieldsQueryVariables
    >({
      query: Generated.ProjectFieldsDocument,
      variables: {
        entityName,
        projectName,
        types,
        columns,
        pattern: patternFromQuery(query),
        count,
        cursor,
      },
    });
    const fetchedOptions = data.data.project.fields.edges
      .map(e => {
        const path = e.node.path;
        const keyString = Run.serverPathToKeyString(path);
        return keyString;
      })
      .filter(keyString => {
        if (keyString == null) {
          return false;
        }
        const key = Run.keyFromString(keyString);
        if (key == null) {
          return false;
        }
        if (key.name.startsWith('_wandb')) {
          return false;
        }
        return filterBy(key);
      })
      .map(keyString => {
        if (keyString == null) {
          return null;
        }
        const option = keyStringToWBMenuOption(keyString);
        return option;
      })
      .filter(Obj.notEmpty);
    const extraOptions = extraKeys
      .filter(k => {
        const key = Run.keyFromString(k);
        if (key == null) {
          return false;
        }
        return filterBy(key);
      })
      .map(keyStringToWBMenuOption)
      .filter(Obj.notEmpty)
      .filter(o => o.name?.toLowerCase().includes(query.toLowerCase()));
    const allOptions =
      cursor == null ? extraOptions.concat(fetchedOptions) : fetchedOptions;
    return {
      nextPageCursor: data.data.project.fields.pageInfo.hasNextPage
        ? data.data.project.fields.pageInfo.endCursor
        : undefined,
      options: allOptions,
    };
  };
  return fetch;
}

export function keyStringToWBMenuOption(
  keyString: string
): WBMenuOption | null {
  const key = Run.keyFromString(keyString);
  if (key == null) {
    return null;
  }
  return {
    value: keyString,
    name: Run.keyDisplayName(key, true),
    icon: getIconNameForKey(key),
  };
}
