import * as globals from '../../css/globals.styles';

import styled, {css} from 'styled-components';
import {GLOBAL_COLORS} from '../../util/colors';
import {WBIcon} from '@wandb/ui';
import {SuggestionMenu} from './WBSuggester.styles';
import WBSuggester from './WBSuggester';

export const Wrapper = styled.div<{clearable: boolean}>`
  position: relative;
  display: inline-block;
  width: 200px;
  ${props =>
    props.clearable &&
    css`
      &:hover {
        ${SearchableInput}, ${ValuePreview} {
          padding-right: 52px;
        }
        ${ClearIcon} {
          display: block;
        }
      }
    `}
`;

export const SearchableInput = styled.input<{open?: boolean}>`
  cursor: pointer;
  width: 100%;
  font-family: ${globals.fontName};
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  border-radius: 4px;
  padding: 8px 32px 8px 12px;
  outline: none;
  &:hover {
    border: 1px solid ${GLOBAL_COLORS.outline.darken(0.1).toString()};
  }
  &::placeholder {
    opacity: 0.4;
  }
  ${props =>
    props.open &&
    css`
      border-color: ${GLOBAL_COLORS.linkBlue.toString()} !important;
    `}
  &:focus {
    cursor: text;
  }
`;

export const ClearIcon = styled(WBIcon)`
  position: absolute;
  right: 30px;
  top: 50%;
  font-size: 18px;
  margin-top: -9px;
  display: none;
  cursor: pointer;
  color: #999;
  &:hover {
    color: black;
  }
`;

export const ExpandIcon = styled(WBIcon)<{open?: boolean}>`
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 18px;
  margin-top: -9px;
  pointer-events: none;
  ${props =>
    props.open &&
    css`
      pointer-events: auto;
      cursor: pointer;
      transform: rotate(180deg);
    `}
`;

export const ValuePreview = styled.div<{focused?: boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 8px 32px 8px 12px;
  border: 1px solid transparent;
  pointer-events: none;
  display: flex;
  align-items: center;
  opacity: ${props => (props.focused ? 0.4 : 1)};
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const StyledSuggester = styled(WBSuggester)`
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  ${SuggestionMenu} {
    background: white;
  }
`;

export const Option = styled.div<{hovered?: boolean}>`
  padding: 8px 24px 8px 12px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  background: ${props => (props.hovered ? '#f6f6f6' : 'none')};
  word-break: break-word;
`;

export const OptionIcon = styled(WBIcon)`
  margin-right: 8px;
  font-size: 18px;
  width: 18px;
  flex-shrink: 0;
  color: #999;
`;
