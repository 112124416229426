import * as React from 'react';
import * as _ from 'lodash';

import {Input, Icon, Button} from 'semantic-ui-react';
import {RunsLinePlotConfig} from './PanelRunsLinePlot';
import LegacyWBIcon from './elements/LegacyWBIcon';

import {
  parseFancyExpression,
  escapeIdentifier,
  Expression,
  summaryKeysInExpression,
  configKeysInExpression,
} from '../util/expr';
import makeComp from '../util/profiler';
import {isReservedKey} from '../util/runs';

type PanelConfig = RunsLinePlotConfig;

interface PanelLegendProps {
  type: 'lines' | 'bars' | 'scalar';
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  availableExpressionVarNames: string[];
  exampleIdentifier: string;
}

export function getExpressionFields(parsedExpressions: {
  expressions?: Expression[];
  xExpression?: Expression;
}) {
  const expressionSummaryFields = _.flatten(
    _.concat(
      parsedExpressions.expressions || [],
      parsedExpressions.xExpression || []
    ).map(expr => summaryKeysInExpression(expr))
  );

  const expressionConfigFields = _.flatten(
    _.concat(
      parsedExpressions.expressions || [],
      parsedExpressions.xExpression || []
    ).map(expr => configKeysInExpression(expr))
  );

  const expressionFields = _.concat(
    expressionConfigFields,
    expressionSummaryFields
  );
  return expressionFields;
}

export function parseExpressions(expressions?: string[], xExpression?: string) {
  let expr: Expression[] | undefined;
  let xExpr: Expression | undefined;
  if (expressions != null) {
    expr = expressions
      .map(e => parseFancyExpression(e))
      .filter(e => e != null) as Expression[];
  }
  if (xExpression != null) {
    xExpr = parseFancyExpression(xExpression);
  }
  return {expressions: expr, xExpression: xExpr};
}

export function renderSingleExpressionInput(
  config: PanelConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  placeholder: string
) {
  return renderExpressionInput(
    config.expressions != null && config.expressions.length > 0
      ? config.expressions[0]
      : undefined,
    value => {
      updateConfig({expressions: [value]});
    },

    placeholder
  );
}

export function renderXExpressionInput(
  config: PanelConfig,
  updateConfig: (newConfig: PanelConfig) => void,
  availableExpressionVarNames: string[]
) {
  return (
    <>
      <b>X Axis Expression</b>
      {renderExpressionInput(
        config.xExpression,
        value => {
          updateConfig({xExpression: value});
        },
        config.xAxis
      )}
      {renderExampleVariables(availableExpressionVarNames)}
    </>
  );
}

export function renderExpressionInput(
  expression: string | undefined,
  updateExpression: (value: string) => void,
  placeholder?: string
) {
  return (
    <Input
      error={
        expression === '' || parseFancyExpression(expression ?? '') == null
      }
      className="expression-input"
      placeholder={escapeIdentifier(placeholder ?? '')}
      fluid
      value={expression || ''}
      onChange={(e, {value}) => {
        updateExpression(value);
      }}
    />
  );
}
export function renderExampleVariables(availableExpressionVarNames: string[]) {
  return (
    <>
      <br />
      <b>Useful Variables:</b>
      <p>
        <span>Metrics:</span>
        <em>
          {' '}
          {availableExpressionVarNames
            .filter(
              varName =>
                !varName.startsWith('system') && !isReservedKey(varName)
            )
            .slice(0, 3)
            .map(varName => escapeIdentifier(varName))
            .join(', ')}
        </em>
        <br />
        <span>System:</span>
        <em>
          {' '}
          {availableExpressionVarNames
            .filter(varName => varName.startsWith('system'))
            .slice(0, 3)
            .map(varName => escapeIdentifier(varName))
            .join(', ')}
        </em>
        <br />
        <span>Special:</span>
        <em> _step</em>
        <br />
        <span>Config and summary:</span>
        <em>
          {' '}
          {
            // eslint-disable-next-line no-template-curly-in-string
            '${summary:loss}'
          }
        </em>{' '}
        or{' '}
        <em>
          {
            // eslint-disable-next-line no-template-curly-in-string
            '${config:lr}'
          }
        </em>
      </p>
    </>
  );
}

const PanelLegend = makeComp(
  (props: PanelLegendProps) => {
    const {config, updateConfig, availableExpressionVarNames} = props;

    const escapedExampleIdentifier = escapeIdentifier(
      config.metrics != null && config.metrics.length > 0
        ? props.exampleIdentifier
        : 'x'
    );

    if (props.type === 'scalar') {
      return (
        <>
          <b>Expressions</b>
          <p className="hint-text">
            Add calculated metrics to your graph. For example 1 -{' '}
            {escapedExampleIdentifier}.
          </p>
          {renderSingleExpressionInput(
            config,
            updateConfig,
            props.exampleIdentifier
          )}
        </>
      );
    }

    return (
      <>
        <b>Expressions</b>
        <p className="hint-text">
          Add calculated metrics to your graph. For example 1 -{' '}
          {escapedExampleIdentifier}.
        </p>

        <>
          {(config.expressions || []).map((expression, i) => (
            <div className="expression" key={`expression-${i}`}>
              <Input
                error={
                  expression === '' || parseFancyExpression(expression) == null
                }
                className="expression-input"
                placeholder={escapedExampleIdentifier}
                fluid
                value={expression}
                onChange={(e, {value}) => {
                  const newExpressions =
                    config.expressions != null ? [...config.expressions] : [];
                  if (i >= newExpressions.length) {
                    newExpressions.push(value);
                  } else {
                    newExpressions[i] = value;
                  }

                  updateConfig({
                    expressions: newExpressions,
                    groupArea: 'none',
                    groupAgg: 'mean',
                  });
                }}
              />
              <LegacyWBIcon
                title=""
                className={'expression-close'}
                size="large"
                name="close"
                onClick={() => {
                  if (
                    config.expressions != null &&
                    i < config.expressions.length
                  ) {
                    const newExpressions =
                      config.expressions != null ? [...config.expressions] : [];
                    newExpressions.splice(i, 1);
                    updateConfig({
                      expressions: newExpressions,
                    });
                  }
                }}
              />
            </div>
          ))}
          <Button
            onClick={() => {
              const newExpressions = [...(config.expressions ?? []), ''];
              updateConfig({
                expressions: newExpressions,
              });
            }}
            className="expression-add">
            <Icon title="" name="plus" />
            Add an Expression
          </Button>
        </>

        <br />

        {props.type === 'lines' &&
          renderXExpressionInput(
            config,
            updateConfig,
            availableExpressionVarNames
          )}
      </>
    );
  },
  {id: 'PanelLegend'}
);

export default PanelLegend;
