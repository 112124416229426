import '../css/OnboardingFlow.less';

import gql from 'graphql-tag';
import React, {useEffect} from 'react';
import {useMutation, useQuery} from 'react-apollo';
import TextareaAutosize from 'react-textarea-autosize';
import {Button, Checkbox, Form, Input} from 'semantic-ui-react';
import classNames from 'classnames';
import {MINIMUM_PASSWORD_LENGTH} from './ChangePassword';
import config, {envIsLocal} from '../config';

import OnboardingBackground from '../assets/onboarding/signup-colorful-bkg.svg';
import RadioForm from '../components/RadioForm';
import WandbLoader from '../components/WandbLoader';
import {
  UPDATE_USER_MUTATION,
  UpdateUserMutationData,
  UpdateUserMutationVariables,
} from '../graphql/users';
import {UserInfo} from '../types/graphql';
import {useSelector} from '../state/hooks';
import {getViewerClaims} from '../state/viewer/selectors';
import {slugFormat} from '../util/text';
import insertScript from '../util/insertScript';
import {doAuthRedirect} from '../util/auth';
import {auth} from '../setup';
import {useHistory, useLocation} from 'react-router-dom';
import makeComp from '../util/profiler';
import {toABTestKey} from '../util/abTest';
import produce from 'immer';
import {Struct} from '../util/types';
import {TargetBlank} from '../util/links';

const REQUIRED_ORG_NAME_AB_TEST_KEY = 'OnboardingFlow.requiredOrgName';
const requireOrgName = true;
const track = getTrackWithABTrait(
  REQUIRED_ORG_NAME_AB_TEST_KEY,
  requireOrgName
);

const CHECK_AVAILABILITY_QUERY = gql`
  query CheckAvailability($name: String) {
    entity(name: $name) {
      id
      available
    }
  }
`;

interface CheckAvailabilityData {
  entity: {
    id: string;
    available: boolean;
  };
}

interface CheckAvailabilityVars {
  name: string;
}

const CREATE_USER_MUTATION = gql`
  mutation CreateEntityOnboardingFlow($name: String!) {
    createEntity(input: {name: $name}) {
      entity {
        id
      }
    }
  }
`;

interface CreateEntityOnboardingFlowData {
  entity: {
    id: string;
  };
}

interface CreateEntityOnboardingFlowVars {
  name: string;
}

const VIEWER_QUERY = gql`
  query ViewerInfo {
    viewer {
      id
      name
      userInfo
      entity
      username
      signupRequired
    }
  }
`;

interface ViewerData {
  viewer: {
    id: string;
    name: string;
    userInfo?: UserInfo;
    entity: string;
    username?: string;
    signupRequired: boolean;
  };
}

const OnboardingFlow: React.FC = makeComp(
  () => {
    const {
      loading: loadingAvailability,
      data: availabilityData,
      refetch: refetchAvailability,
    } = useQuery<CheckAvailabilityData, CheckAvailabilityVars>(
      CHECK_AVAILABILITY_QUERY
    );
    // Indicates if we're accepting an invite or resetting our password for W&B local
    const viewerClaims = useSelector(getViewerClaims);
    const localFlow = viewerClaims != null;
    const changeEmailFlow = viewerClaims?.reset;
    let localUsername = viewerClaims?.name ?? '';
    let isExistingUser = false;

    const {data: viewerData} = useQuery<ViewerData>(VIEWER_QUERY, {
      onCompleted: data => {
        if (!data.viewer) {
          // In wandb local, new users will not exist
          return;
        }
        if (!data.viewer.signupRequired && !localFlow) {
          // Skip to the questions if we don't need to choose a username
          goToNextPage();
        } else {
          track(
            'Entered account creation',
            {userID: data.viewer.id},
            {integrations: {All: true, Salesforce: true}}
          );
        }
        if (
          data.viewer.name &&
          !data.viewer.name.includes('@') &&
          data.viewer.name.match(/^[A-Z]/)
        ) {
          // We made a mistake of setting email to name after a
          // user signs up via email+password.
          // This is a hack to ignore email-like names.
          setName(data.viewer.name);
        }
        const {userInfo} = data.viewer;
        if (userInfo) {
          if (userInfo.howOften) {
            setHowOften(userInfo.howOften);
          }
        }
      },
    });

    // User already exists
    if (viewerData?.viewer?.signupRequired === false) {
      localUsername = viewerData.viewer.entity;
      isExistingUser = true;
    }

    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      if (localFlow && isExistingUser) {
        console.error(
          `tried to load signup page for existing user ${localUsername}: redirecting to password reset`
        );
        // if we came from change-password, logout and try again because we redirect
        // to signup if the logged in users email doesn't match the reset JWT
        const referrer = location.state || ({} as any);
        if (referrer.from === '/change-password') {
          auth.logout(false);
        }
        history.replace({
          ...location,
          pathname: 'change-password',
          state: {internal: true},
        });
      }
    }, [history, location, isExistingUser, localFlow, localUsername]);

    const [updateUser] = useMutation<
      UpdateUserMutationData,
      UpdateUserMutationVariables
    >(UPDATE_USER_MUTATION);

    const [createEntityOnboardingFlow] = useMutation<
      CreateEntityOnboardingFlowData,
      CreateEntityOnboardingFlowVars
    >(CREATE_USER_MUTATION);

    const [name, setName] = React.useState('');
    const [orgName, setOrgName] = React.useState('');
    const [username, setUsername] = React.useState(localUsername);
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);
    const [pageHistory, setPageHistory] = React.useState(['start']);

    const [howOften, setHowOften] = React.useState('');
    const [howOftenDetails, setHowOftenDetails] = React.useState('');

    useEffect(() => {
      const currentPage = pageHistory[pageHistory.length - 1];
      track(`OnboardingFlow: started ${currentPage}`);
    }, [pageHistory]);

    // This is true when we're in a VM or onprem
    const isPrivateInstall = config.ENVIRONMENT_IS_PRIVATE;

    const goToNextPage = () => {
      setPageHistory(ph => {
        let nextPage: string | null = null;
        switch (ph[ph.length - 1]) {
          case 'start':
            if (isPrivateInstall) {
              // Only for VM currently
              finishSurvey();
            } else {
              nextPage = 'howOftenForm';
            }
            break;
          case 'howOftenForm':
            finishSurvey();
            break;
          default:
            throw new Error('Invalid page');
        }
        return nextPage ? [...ph, nextPage] : ph;
      });
    };

    if (!viewerData) {
      return <WandbLoader />;
    }

    const usernameIsValid = (uname: string) =>
      3 <= uname.length && uname.length <= 30;

    const usernameIsAvailable = (uname: string) =>
      usernameIsValid(uname) &&
      availabilityData &&
      (!availabilityData.entity || availabilityData.entity.available);

    const nameComplete = Boolean(name);
    const orgNameComplete = requireOrgName ? Boolean(orgName) : true;
    const startPageComplete =
      (envIsLocal ? true : nameComplete && orgNameComplete) && acceptedTerms;
    const createEntityDisabled =
      !startPageComplete ||
      !usernameIsAvailable(username) ||
      (changeEmailFlow && !email.includes('@'));

    const finishSurvey = () => {
      if ((window as any).thirdPartyAnalyticsOK) {
        try {
          // asynchronously record the conversion with google adwords
          insertScript(
            'https://www.googletagmanager.com/gtag/js?id=AW-715597483'
          ).then(() => {
            (window as any).dataLayer = (window as any).dataLayer || [];
            function gtag(...args: any[]) {
              (window as any).dataLayer.push(args);
            }
            gtag('js', new Date());
            gtag('config', 'AW-715597483');
            gtag('event', 'conversion', {
              send_to: 'AW-715597483/kaoICIyko7QBEKvNnNUC',
            });
          });
        } catch (e) {
          console.error('Error when attempting to register conversion event:');
          console.error(e);
        }
      }
      doAuthRedirect();
    };

    const makeAddDetailsField = (
      value: string,
      setValue: (newValue: string) => void
    ) => (
      <Form className="add-details-field">
        <Form.TextArea
          maxLength={512}
          rows="2"
          minRows={2}
          onChange={e => {
            setValue(e.currentTarget.value);
          }}
          placeholder="(Optional) Add some details"
          value={value}
          control={TextareaAutosize}
        />
      </Form>
    );

    const updateUserInfo = (newFields: Partial<UserInfo>) =>
      updateUser({
        variables: {
          userInfo: JSON.stringify(
            Object.assign({}, viewerData.viewer.userInfo, newFields)
          ),
        },
      });

    const pages: {[key: string]: React.ReactNode} = {
      start: (
        <>
          <h2 data-test="create-account-header">Create an account</h2>
          {changeEmailFlow && (
            <p style={{fontStyle: 'italic', marginTop: -13}}>
              Creating an account ensures your local instance is secure. You can
              add additional users from the Users page.
            </p>
          )}
          <Form>
            <Form.Field
              className="small-margin"
              data-test="name-input"
              control={Input}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              maxLength={64}
              label="Full name"
              required={!envIsLocal}
            />
            {!envIsLocal && (
              <Form.Field
                data-test="org-input"
                control={Input}
                value={orgName}
                onChange={(e: any) => setOrgName(e.target.value)}
                onFocus={() => track('OnboardingFlow: focused org-input')}
                label="Organization"
                maxLength={64}
                required
              />
            )}
            {changeEmailFlow && (
              <Form.Field
                data-test="email-input"
                control={Input}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                placeholder="Email address"
                maxLength={64}
              />
            )}
            <div>
              Choose your username carefully! It cannot be changed after signup.
            </div>
            <div className="username-wrapper">
              {!config.ENVIRONMENT_IS_PRIVATE && (
                <div className="username-prefix">wandb.ai/</div>
              )}
              <Form.Field
                className={classNames(
                  {'username-field': !config.ENVIRONMENT_IS_PRIVATE},
                  {
                    taken: username && !usernameIsAvailable(username),
                  }
                )}
                placeholder="Username"
                control={Input}
                data-test="username-input"
                loading={loadingAvailability}
                value={username}
                maxLength={30}
                onChange={(e: any) => {
                  const newUsername = slugFormat(e.target.value);
                  setUsername(newUsername);
                  if (usernameIsValid(newUsername)) {
                    refetchAvailability({name: newUsername});
                  }
                }}
                label="Username"
                autoComplete="new-password"
                required
              />
            </div>
            <div
              className={classNames('taken-text', {
                active: username && !usernameIsAvailable(username),
              })}>
              This username is taken, try another
            </div>
            {localFlow && (
              <Form.Field
                control={Input}
                type="password"
                autoComplete="new-password"
                data-test="username-password"
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
            )}
            <Form.Field
              inline
              id="agree-to-terms-checkbox"
              control={Checkbox}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              checked={acceptedTerms}
              label={{
                children: (
                  <span>
                    <span data-test="agree-to-terms-checkbox">I</span> agree to
                    the{' '}
                    <TargetBlank href="https://wandb.ai/site/terms">
                      Terms and Conditions
                    </TargetBlank>
                  </span>
                ),
              }}
            />
          </Form>
          <div className="actions">
            <Button
              className="next-button"
              data-test="create-account-button"
              primary
              disabled={createEntityDisabled}
              onClick={async () => {
                track('OnboardingFlow: completed start');
                if (localFlow) {
                  if (password.length < MINIMUM_PASSWORD_LENGTH) {
                    return alert(
                      `Password of at least ${MINIMUM_PASSWORD_LENGTH} characters required`
                    );
                  } else {
                    if (
                      !(await auth.changePassword(password, email, username))
                    ) {
                      // Invalid token, auth handles messaging
                      return;
                    }
                  }
                  createEntityOnboardingFlow({variables: {name: username}})
                    .then(() => {
                      updateUser({
                        variables: {defaultEntity: username, name},
                      }).then(finishSurvey);
                    })
                    .catch(finishSurvey);
                } else {
                  await createEntityOnboardingFlow({
                    variables: {name: username},
                  });
                  // We need to explicitly enable Salesforce for this event
                  // to propagate through segment.
                  track(
                    'Signed Up',
                    {username},
                    {integrations: {All: true, Salesforce: true}}
                  );
                  const variables: UpdateUserMutationVariables = {name};
                  if (orgName) {
                    variables.userInfo = JSON.stringify({company: orgName});
                  }
                  await updateUser({variables});
                  goToNextPage();
                  track(
                    'Finished account creation',
                    {userID: viewerData.viewer.id, username, name, orgName},
                    {integrations: {All: true, Salesforce: true}}
                  );
                }
              }}>
              Continue
            </Button>
          </div>
        </>
      ),
      howOftenForm: (
        <>
          <h3>How often do you train models?</h3>
          <RadioForm
            name="howOftenGroup"
            options={[
              {label: 'Never', key: 'never'},
              {label: 'A few times a year', key: 'few-year'},
              {label: 'Every month', key: 'every-month'},
              {label: 'Every week', key: 'every-week'},
              {label: 'Every day', key: 'every-day'},
            ]}
            value={howOften}
            onChange={newValue => setHowOften(newValue)}
          />
          {makeAddDetailsField(howOftenDetails, setHowOftenDetails)}
          <div className="actions">
            <Button
              className="next-button"
              primary
              disabled={!howOften}
              onClick={() => {
                track('OnboardingFlow: completed howOftenForm');
                updateUserInfo({howOften, howOftenDetails}).then(goToNextPage);
              }}>
              Get started
            </Button>
          </div>
        </>
      ),
    };

    return (
      <div
        className="onboarding-flow"
        style={{backgroundImage: `url(${OnboardingBackground})`}}>
        <div className="onboarding-dialogue">
          {pages[pageHistory[pageHistory.length - 1]]}
        </div>
      </div>
    );
  },
  {id: 'OnboardingFlow'}
);

export default OnboardingFlow;

function getTrackWithABTrait(key: string, val: any) {
  function trackWithTraits(
    event: string,
    properties: Struct = {},
    opts: Struct = {}
  ): void {
    const newOpts = produce(opts, draft => {
      draft.context = draft.context ?? {};
      draft.context.traits = {
        [toABTestKey(key)]: val,
        ...(draft.context.traits ?? {}),
      };
    });
    window.analytics.track(event, properties, newOpts);
  }
  return trackWithTraits;
}
