import gql from 'graphql-tag';
import {useMemo, useRef} from 'react';
import {useQuery} from '@apollo/react-hooks';

import * as Generated from '../../generated/graphql';

import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Run from '../../util/runs';
import {propagateErrorsContext} from '../../util/errors';
import {EMPTY_ARRAY} from '../../util/constants';

export const PROJECT_FIELDS_QUERY = gql`
  query ProjectFields(
    $projectName: String!
    $entityName: String!
    $types: [String!]!
    $columns: [String!]!
    $pattern: String
    $count: Int!
    $cursor: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      fields(
        types: $types
        columns: $columns
        pattern: $pattern
        first: $count
        after: $cursor
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            path
            type
          }
        }
      }
    }
  }
`;

export interface ProjectData {
  id: string;
  fields: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        path: string;
        type: string;
      };
    }>;
  };
}

export interface ProjectFieldsQueryData {
  project: ProjectData;
}

export function useProjectFieldsQuery(
  variables: Generated.ProjectFieldsQueryVariables & {skip?: boolean}
) {
  const {skip, ...gqlVars} = variables;
  const data = useQuery<ProjectFieldsQueryData, typeof variables>(
    Generated.ProjectFieldsDocument,
    {
      fetchPolicy: 'network-only',
      skip,
      variables: gqlVars,
      context: propagateErrorsContext(),
    }
  );
  const fieldsRef = useRef<readonly string[]>(EMPTY_ARRAY);

  const dataFields = useMemo(
    () =>
      skip
        ? EMPTY_ARRAY
        : data.loading
        ? EMPTY_ARRAY
        : data.data?.project.fields.edges
            .map(e => e.node.path)
            .reduce(
              // fields can be returned multiple times if they have are sent with
              // multiple types. reduce to unique values
              (all, field, _1, _2) => {
                if (all.length > 0 && all[all.length - 1] === field) {
                  return all;
                }
                return [...all, field];
              },
              [] as string[]
            ) ?? EMPTY_ARRAY,
    [data, skip]
  );

  if (!data.loading) {
    fieldsRef.current = dataFields;
  }

  const fields = fieldsRef.current;

  const keys = useMemo(() => {
    return fields
      .map(f => Run.serverPathToKeyString(f))
      .filter(Obj.notEmpty)
      .filter(k => !k.startsWith('config:_wandb'))
      .sort();
  }, [fields]);

  return {loading: data.loading, keys};
}
