// THIS MUST BE KEPT IN SYNC WITH THE WEBFLOW FOR wandb.ai/site/benchmarks
// Unfortunately, due to how benchmarks are implemented, there is currently
// no frictionless way to pull titles for each benchmark. Hardcodin' time.
export const BENCHMARK_TITLES: {[key: string]: string} = {
  'deepform/political-ad-extraction': 'DeepForm',
  'safelife/v1dot2': 'SafeLife by Partnership on AI',
  'thinkingmachines/ph-poverty-mapping':
    'PH Poverty Mapping by Thinking Machines',
  'dronedeploy/dronedeploy-aerial-segmentation':
    'Aerial Segment by DroneDeploy',
  'github/codesearchnet': 'CodeSearchNet by GitHub',
  'wandb/droughtwatch': 'Drought Watch',
  'wandb/kmnist': 'KMNIST',
  'wandb/witness': 'The Witness',
  'wandb/catz': 'CATZ Benchmark',
  'wandb/superres': 'Super Resolution',
  'wandb/colorizer-applied-dl': 'Colorizer',
};

export function formatDescription(desc: string): string {
  return `${desc.replace(
    /\.$/,
    ''
  )}. A Benchmark created with Weights and Biases.`;
}
