import {ApolloQueryResult} from 'apollo-client';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import React, {FC, useState, useCallback, useMemo} from 'react';
import {Button, Divider, Grid, Modal, Tab} from 'semantic-ui-react';
import {View as VegaView} from 'vega';
import EditableField from '../../components/EditableField';
import * as ViewMutations from '../../containers/ViewMutations';
import {View} from '../../state/graphql/vegaPanelQuery';
import {RunHistoryKeyInfo} from '../../types/run';
import * as Query from '../../util/queryts';
import * as VegaLib from '../../util/vega';
import {useRunsData, useLoadHistoryFileTables} from '../../state/runs/hooks';
import InlineMarkdownEditor from '../elements/InlineMarkdownEditor';
import VegaPanelDataPane from './VegaPanelDataPane';
import VegaPanelLoad from './VegaPanelLoad';
import Editor from '../Monaco/Editor';
import VegaPanelSaveAs from './VegaPanelSaveAs';
import VegaPanelUserConfig from './VegaPanelUserConfig';
import VegaViz from './VegaViz';
import makeComp from '../../util/profiler';

const VIEW_TYPE = 'vega-panel';

// Public props
interface VegaPanelEditorProps {
  pageQuery: Query.Query;
  keyInfo: RunHistoryKeyInfo;

  state: VegaPanelEditorState;
  vegaViews: View[];

  refetchVegaViews(): void;
  onCancel(): void;
  onApplyCustom(newSpec: string): void;
  onApplyFromLibrary(id: string): void;
}

type VegaPanelEditorInternalProps = VegaPanelEditorWithMutationsProps & {
  // From mutations
  upsertView(
    variables: ViewMutations.ViewUpsertVariables
  ): Promise<ApolloQueryResult<ViewMutations.ViewUpsertResult>>;
  deleteView(
    variables: ViewMutations.ViewDeleteVariables
  ): Promise<ApolloQueryResult<{}>>;
  createSharedView(
    variables: ViewMutations.ViewCreateSharedVariables
  ): Promise<ApolloQueryResult<ViewMutations.ViewCreateSharedResult>>;
};

const VegaPanelEditorInternal: FC<VegaPanelEditorInternalProps> = makeComp(
  ({
    vegaViews,
    state,
    upsertView,
    deleteView,
    createSharedView,
    updateState,
    pageQuery,
    refetchVegaViews,
    onCancel,
    data,
    keyInfo,
    onApplyCustom,
    onApplyFromLibrary,
  }) => {
    const [view, setView] = useState<VegaView | null>(null);
    const [edited, setEdited] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openLoad, setOpenLoad] = useState(false);
    const [openSaveAs, setOpenSaveAs] = useState(false);

    const updateVegaSpec = useMemo(
      () =>
        _.debounce((newSpec: string) => {
          setEdited(true);
          updateState({spec: newSpec});
        }, 500),
      [updateState]
    );

    const spec = VegaLib.parseSpecJSON(state.spec);
    return (
      <>
        <Modal open={openLoad} onClose={() => setOpenLoad(false)}>
          <Modal.Content>
            <VegaPanelLoad
              vegaViews={vegaViews}
              onLoad={id => {
                const vegaView = _.find(vegaViews, vv => vv.id === id);
                if (vegaView != null) {
                  updateState({
                    viewId: vegaView.id,
                    name: vegaView.name,
                    description: vegaView.description,
                    spec: vegaView.spec,
                  });
                  setEdited(false);
                  setOpenLoad(false);
                }
              }}
              onDelete={id => {
                const vegaView = _.find(vegaViews, vv => vv.id === id);
                if (vegaView != null) {
                  return deleteView({
                    type: VIEW_TYPE,
                    entityName: pageQuery.entityName,
                    projectName: pageQuery.projectName,
                    id: vegaView.id,
                  }).then(refetchVegaViews);
                }
                return Promise.resolve();
              }}
            />
          </Modal.Content>
        </Modal>
        <Modal open={openSaveAs} onClose={() => setOpenSaveAs(false)}>
          <Modal.Content>
            <VegaPanelSaveAs
              entityName={pageQuery.entityName}
              projectName={pageQuery.projectName}
              spec={state.spec}
              vegaViews={vegaViews}
              onSave={name => {
                setOpenSaveAs(false);
                setSaving(true);
                setEdited(false);
                updateState({name});
                upsertView({
                  entityName: pageQuery.entityName,
                  projectName: pageQuery.projectName,
                  type: VIEW_TYPE,
                  name,
                  description: state.description,
                  spec: state.spec,
                }).then(result => {
                  refetchVegaViews();
                  setSaving(false);
                  updateState({
                    viewId: result.data.upsertView.view.id,
                  });
                });
              }}
              onSaveShared={(entityName, name) => {
                setOpenSaveAs(false);
                setSaving(true);
                setEdited(false);
                updateState({name});
                createSharedView({
                  entityName,
                  type: VIEW_TYPE,
                  name,
                  description: state.description,
                  spec: state.spec,
                }).then(result => {
                  refetchVegaViews();
                  setSaving(false);
                  updateState({
                    viewId: result.data.upsertSharedView.view.id,
                  });
                });
              }}
            />
          </Modal.Content>
        </Modal>
        <Modal open={true} onClose={onCancel} size="fullscreen">
          <Modal.Content>
            <Grid divided="vertically">
              <Grid.Row>
                <Grid.Column style={{margin: 0}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 style={{margin: 0, marginRight: 16}}>
                      <b>Vega visualization: </b>
                    </h3>
                    <EditableField
                      placeholder="Visualization name"
                      value={state.name}
                      updateValue={true}
                      save={value => {
                        setEdited(true);
                        updateState({name: value});
                      }}
                    />
                    <Button
                      style={{marginLeft: 16}}
                      onClick={() => setOpenLoad(true)}>
                      Load
                    </Button>
                    <Button onClick={() => setOpenSaveAs(true)}>
                      Save as...
                    </Button>
                    <Button
                      disabled={state.viewId == null || !edited}
                      onClick={() => {
                        setSaving(true);
                        setEdited(false);
                        upsertView({
                          id: state.viewId,
                          type: VIEW_TYPE,
                          entityName: pageQuery.entityName,
                          projectName: pageQuery.projectName,
                          name: state.name,
                          description: state.description,
                          spec: state.spec,
                        }).then(() => setSaving(false));
                      }}>
                      Save
                    </Button>
                    {saving && <span className="hint-text">saving...</span>}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Tab
                    // renderActiveOnly={false}
                    defaultActiveIndex={1}
                    panes={[
                      {
                        menuItem: 'Debug',
                        render: () => (
                          <div
                            style={{
                              marginTop: 16,
                              maxHeight: 600,
                              overflow: 'auto',
                            }}>
                            <VegaPanelDataPane view={view} />
                          </div>
                        ),
                      },
                      {
                        menuItem: 'Vega spec',
                        render: () => (
                          <div style={{marginTop: 16}}>
                            <p className="hint-text">
                              Vega is a declarative visualization language, that
                              can be used to create custom visualizations in
                              W&B. (
                              <a href="https://vega.github.io/">
                                Vega documentation
                              </a>
                              )
                            </p>
                            <Editor
                              key={state.viewId || 'unknown-view-id'}
                              value={state.spec}
                              onChange={(value: string) =>
                                updateVegaSpec(value)
                              }
                              language="json"
                            />
                          </div>
                        ),
                      },
                      {
                        menuItem: 'Description',
                        render: () => (
                          <div style={{marginTop: 16}}>
                            <p className="hint-text">
                              Describe what your panel does and how to use it.
                            </p>
                            <InlineMarkdownEditor
                              key={state.viewId || 'unknown-view-id'}
                              className="panel__inline-markdown"
                              saveText={false}
                              readOnly={false}
                              placeholder="Click here to add notes"
                              serverText={state.description}
                              onBlur={text => {
                                updateState({description: text});
                                setEdited(true);
                              }}
                              onChange={text => {
                                updateState({description: text});
                                setEdited(true);
                              }}
                              enableMarkdown
                              autosave
                              minRows={5}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <h4>Panel preview</h4>
                  <p className="hint-text">
                    The panel is rendered by injecting the input data into the
                    Vega spec, and replacing the "wandb-field" references with
                    the "Panel config" values
                  </p>
                  <div style={{height: 300, overflow: 'auto'}}>
                    <VegaViz
                      spec={spec}
                      data={data}
                      userSettings={{
                        fieldSettings: state.fieldSettings,
                        runFieldSettings: state.runFieldSettings,
                        runFieldListSettings: state.runFieldListSettings,
                        historyFieldSettings: state.historyFieldSettings,
                      }}
                      setView={setView}
                    />
                  </div>
                  <Divider style={{marginTop: 64}} />
                  <h4>Panel config</h4>
                  <p className="hint-text">
                    These fields are derived from "wandb-history-field" and
                    "wandb-run-field" references in the Vega spec.
                  </p>
                  <VegaPanelUserConfig
                    refs={VegaLib.parseSpec(spec) || []}
                    runFieldOptions={VegaLib.runFieldOptions(data.keyInfo)}
                    historyFieldOptions={VegaLib.historyFieldOptions(keyInfo)}
                    fieldSettings={state.fieldSettings}
                    runFieldSettings={state.runFieldSettings}
                    runFieldListSettings={state.runFieldListSettings}
                    historyFieldSettings={state.historyFieldSettings}
                    updateUserSettings={newSettings =>
                      updateState(_.merge({}, state, newSettings))
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onCancel}>Close</Button>
            {state.viewId == null || edited ? (
              <Button primary onClick={() => onApplyCustom(state.spec)}>
                Apply custom panel vizualization
              </Button>
            ) : (
              <Button primary onClick={() => onApplyFromLibrary(state.viewId!)}>
                Apply from panel library
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      </>
    );
  },
  {id: 'VegaPanelEditorInternal', memo: true}
);

type VegaPanelEditorWithMutationsProps = VegaPanelEditorWithDataProps &
  ReturnType<typeof useRunsData>;

const VegaPanelEditorWithMutations = (
  compose(
    ViewMutations.upsertViewMutation,
    ViewMutations.deleteViewMutation,
    ViewMutations.createSharedViewMutation
  ) as any
)(
  VegaPanelEditorInternal
) as React.ComponentClass<VegaPanelEditorWithMutationsProps>;

type VegaPanelEditorWithDataProps = VegaPanelEditorProps & {
  updateState(newState: Partial<VegaPanelEditorState>): void;
};

const VegaPanelEditorWithData = makeComp(
  (props: VegaPanelEditorWithDataProps) => {
    const query = VegaLib.runsDataQuery(
      props.pageQuery,
      props.state,
      props.state.spec
    );
    let runsQuery = useRunsData(query);
    runsQuery = useLoadHistoryFileTables(query, runsQuery);
    return <VegaPanelEditorWithMutations {...props} {...runsQuery} />;
  },
  {id: 'VegaPanelEditorWithData'}
);

type VegaPanelEditorState = VegaLib.UserSettings & {
  viewId?: string;
  spec: string;
  name: string;
  description: string;
};

const VegaPanelEditorContainer: FC<VegaPanelEditorProps> = makeComp(
  props => {
    const [state, setState] = useState<VegaPanelEditorState>(props.state);
    const updateState = useCallback(
      (newState: Partial<VegaPanelEditorState>): void => {
        setState({...state, ...newState});
      },
      [state]
    );
    return (
      <VegaPanelEditorWithData
        {...props}
        state={state}
        updateState={updateState}
      />
    );
  },
  {id: 'VegaPanelEditorContainer', memo: true}
);

export default VegaPanelEditorContainer;
