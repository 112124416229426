import styled, {css} from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  display: flex;
`;

export const VisPreviewerWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  background: rgba(240, 240, 240, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VisPreviewer = styled.div<{pos?: {x: number; y: number} | null}>`
  height: 400px;
  width: 600px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  ${props =>
    props.pos &&
    css`
      position: fixed;
      left: ${props.pos.x}px;
      top: ${props.pos.y}px;
    `}
`;
