/*
 * This file converts all of the global styles in globals.less to constants that can be
 * used in styled components.
 */

/************
  Grayscale
************/

export const gray50 = `#f9f9f9`;
export const gray100 = `#f4f4f4`;
export const gray200 = `#eeeeee`;
export const gray300 = `#e6e6e6`;
export const gray400 = `#d2d2d2`;
export const gray500 = `#aaaaaa`;
export const gray600 = `#757575`;
export const gray700 = `#515457`;
export const gray800 = `#363a3d`;
export const darkerGray = `#222222`;
export const gray900 = `#1a1c1f`;

export const textDisabled = gray400;
export const textSecondary = gray600;
export const textPrimary = gray800;

export const disabled = gray400;
export const border = gray400;
export const divider = gray200;
export const backdrop = `rgba(0, 0, 0, 0.9)`;
export const hover = `rgba(0, 0, 0, 0.04)`;
export const selected = `rgba(0, 0, 0, 0.08)`;
export const hoverDark = `rgba(255, 255, 255, 0.08)`;
export const selectedHover = '#E5EBF1';

export const white = `#ffffff`;
export const black = `#000000`;

/***************
  Theme colors
***************/

export const error = `#de6680`;
export const errorBackground = `rgba(222, 102, 128, 0.08)`;
export const errorBorder = `rgba(222, 102, 128, 0.5)`;
export const errorDark = `#a93554`;
export const errorLight = `#ff97af`;
export const errorText = `#d53d5e`;

export const primary = `#2e78c7`;
export const primaryBackground = `rgba(46, 120, 199, 0.08)`;
export const primaryBorder = `rgba(46, 120, 199, 0.5)`;
export const primaryDark = `#004d96`;
export const primaryLight = `#6ba6fa`;
export const primaryText = primary;

export const success = `#00B5A0`;
export const successBackground = `rgba(0, 181, 160, 0.08)`;
export const successBorder = `rgba(0, 181, 160, 0.5)`;
export const successDark = `#008472`;
export const successLight = `#5BE8D1`;
export const successText = `#008576`;

export const warning = `#F59174`;
export const warningBackground = `rgba(245, 145, 116, 0.08)`;
export const warningBorder = `rgba(245, 145, 116, 0.5)`;
export const warningDark = `#BF6248`;
export const warningLight = `#FFC2A3`;
export const warningText = `#DA3D10`;

export const info = `#03B7CE`;
export const infoBackground = `rgba(3, 182, 206, 0.08)`;
export const infoBorder = `rgba(3, 182, 206, 0.5)`;
export const infoDark = `#00879D`;
export const infoLight = `#61EAFF`;
export const infoText = `#028293`;

/*******************************
     User Global Variables
*******************************/

// TODO: This is out of date, update to match our semantic variables!
// Global colors
export const fontName = `Source Sans Pro`;
export const yellow = `#ffc933`;
export const gold = `#ffcc33`;
export const fullYellow = `#ffff00`;
export const red = error;
export const darkRed = `#ff2514`;
export const teal = info;
export const deepTeal = `#039cad`;
export const green = success;
export const blue = primary;
export const deepBlue = `#00648f`;

export const heartColor = `#bd70a3`;

// extended colors (These are only used in PanelMultiRunTable currently).
export const seaFoamGreen = `#7ed2bf`;
export const lightSeaFoamGreen = `#edf8f6`;
export const slateBlue = `#68abca`;
export const lightSlateBlue = `#e9f3f7`;
export const lightYellowOther = `#fff2cc`;
export const lightYellow = `#fffbec`;
export const goldenrod = `#daa300`;

// privacy badge colors
export const fern = `#3ac26c`; // public
export const aqua = `#21bfa4`; // team
export const cerulean = `#37a0c7`; // private
export const orchid = `#a97ccd`; // open

export const lightBlue = `#ddedfc`;
export const mediumBlue = `#80b3f8`;
export const darkBlue = `#338dd8`;
export const linkHoverBlue = `#006c95`;
export const sky = primaryBackground; // highlighted table cell
export const lightSky = `#f3fafd`; // highlighted table row/col

// (almost) AA compliant run text colors (used for legend and tooltip)
// yellow, cyan and seafoam are compromised because AA ver. is very different
export const runBlueText = `#3874D8`;
export const runRedText = `#D73E3E`;
export const runKellyGreenText = `#3D8452`;
export const runPurpleText = `#7D54B2`;
export const runPinkText = `#DB3169`;
export const runOrangeText = `#C5541A`;
export const runSeafoamText = `#55AA99`;
export const runMagentaText = `#B946BC`;
export const runYellowText = `#DD9200`;
export const runCyanText = `#1FA8D4`;
export const runForestText = `#1E8479`;
export const runPeachText = `#C1571C`;
export const runLimeText = `#62802C`;
export const runBrownText = `#A46750`;
export const runMaroonText = `#A12864`;
export const runGrayText = `#6E787E`;

// Shared CSS Rules
export const accordionContentPadding = `25px 20px`;
export const transitionTime = `0.3s`;
export const actionAnimationTime = `0.3s`;
export const backgroundColorTransition = `background-color ${transitionTime} linear`;
export const borderColorTransition = `border-color ${transitionTime} linear`;
export const pillBorderRadius = `20px`;
export const tabletBreakpoint = `768px`;

export const tagPurple = `#e5d6ff`;
export const tagBerry = `#ffd6f0`;
export const tagRed = `#ffe0e0`;
export const tagPeach = `#ffe7cc`;
export const tagYellow = `#fde8ad`;
export const tagAcid = `#c9f5b5`;
export const tagGreen = `#c2f2da`;
export const tagTeal = `#c0f0ed`;
export const tagBlue = `#d6efff`;
export const tagMidnight = `#e0e4ff`;
export const tagLightBlue = `#ccf1db`;

export const galleryAdminPurple = `#bd9df4`;

export const commentYellow = `#FFE897`;

// Box shadows

// Box shadows at different levels from material
export const levelNavbarBoxShadow = `0 1px 3px rgba(0, 0, 0, 0.02),
0 1px 2px rgba(0, 0, 0, 0.12)`;
export const level1BoxShadow = `0 1px 3px rgba(0, 0, 0, 0.12),
0 1px 2px rgba(0, 0, 0, 0.24)`;
export const level2BoxShadow = `0 3px 6px rgba(0, 0, 0, 0.16),
0 3px 6px rgba(0, 0, 0, 0.23)`;
export const level3BoxShadow = `0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23)`;
export const level3BoxShadowFaint = `0 10px 20px rgba(0, 0, 0, 0.07),
0 6px 6px rgba(0, 0, 0, 0.07)`;
export const level4BoxShadow = `0 14px 28px rgba(0, 0, 0, 0.25),
0 10px 10px rgba(0, 0, 0, 0.22)`;
export const level5BoxShadow = `0 19px 38px rgba(0, 0, 0, 0.30),
0 15px 12px rgba(0, 0, 0, 0.22)`;

export const boxShadowButtonsCharts = `0px 1px 3px 0px rgba(54,58,61,0.12)`;
export const boxShadowButtonsChartsHover = `0px 3px 6px 0px rgba(54,58,61,0.12)`;
export const boxShadowModal = `0px 8px 16px 0px rgba(54,58,61,0.16)`;
export const boxShadowDropdown = `0px 4px 8px 0px rgba(54,58,61,0.12)`;
export const boxShadowSection = `1px 1px 20px 0px #e6e6e6`;

// Inner tab box-shadow
export const tabBoxShadow = `inset 0 -4px 25px -8px rgba(0, 0, 0, 0.2)`;

// Font sizes
export const fontSizeStandard = `16px`;
export const lineHeightStandard = `24px`;
export const fontWeightStandard = `100`;

// Sizes
export const navbarHeight = `0px`;

// Spacing
export const pageHeaderButtonSpacing = `10px`;
export const standardSpacingUnit = `8px`;
export const spu = standardSpacingUnit;

// Functional Color Rules
// These should wrap the above colors with semantic names
// TODO: Figure out how tofontSizeStandard organize this
// Action Colors, buttons,
export const actionButtonFocusedColor = deepTeal;
export const actionButtonFocusedFontColor = white;
export const actionButtonFocusedBorderColor = deepTeal;

export const actionButtonActiveColor = info;
export const actionButtonActiveFontColor = white;
export const actionButtonActiveBorderColor = info;

export const actionButtonDefaultColor = gray100;
export const actionButtonDefaultFontColor = textPrimary;
export const actionButtonDefaultBorderColor = `transparent`;

export const actionFocusedColor = primaryBackground;
export const actionFocusedFontColor = textPrimary;

export const actionActiveColor = primaryBackground;
export const actionActiveFontColor = textPrimary;

export const separatorBorder = `1px solid ${border}`;

export const searchNavHeight = `52px`;
export const galleryNavHeight = `52px`;
export const fancyPageSidebarWidth = `52px`;
export const mobileBarHeight = fancyPageSidebarWidth;
export const reportCoverHorizontalMargin = `28px`;
export const reportDraftBackgroundColor = `#fff8e3`;
export const reportFontSize = `18px`;

export const TABLET_WIDTH = 1320;
export const SMALL_TABLET_WIDTH = 1000;
export const MOBILE_WIDTH = 600;
