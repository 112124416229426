import React from 'react';
import makeComp from '../util/profiler';
import {useHasLocalLicenseQuery} from '../generated/graphql';
import {
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
  NudgeBarLarge,
  NudgeBarLargeAction,
} from './NudgeBarLarge';

const GetFreeLicenseButton: React.FC = () => {
  return (
    <NudgeBarLargeAction>
      <NudgeBarLargeActionLink href="https://api.wandb.ai/deploy/redirect/unlicensed">
        Get a free license
      </NudgeBarLargeActionLink>
    </NudgeBarLargeAction>
  );
};

export const GetLicenseNudgeBar: React.FC = makeComp(
  () => {
    const {data, loading} = useHasLocalLicenseQuery();
    if (loading || data?.serverInfo?.hasLocalLicense) {
      return null;
    }
    return (
      <NudgeBarLarge>
        <NudgeBarLargeTitle>
          You need a license for this instance of W&amp;B Local
        </NudgeBarLargeTitle>
        <GetFreeLicenseButton />
      </NudgeBarLarge>
    );
  },
  {id: 'StorageNudgeBar', memo: true}
);
