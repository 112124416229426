import * as _ from 'lodash';
import React from 'react';
import {useMemo, useCallback, useState} from 'react';
import {useRunsData} from '../state/runs/hooks';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import * as Panels from '../util/panels';
import makeComp from '../util/profiler';
import * as Filter from '../util/filters';
import * as QueryTS from '../util/queryts';
import * as ColorUtil from '../util/colors';
import {PanelContextProvider} from './Panel2/PanelContext';
import {useDeepMemo, useGatedValue} from '../state/hooks';
import * as Types from '@wandb/cg/browser/model/types';
import * as CG from '@wandb/cg/browser/graph';
import * as Op from '@wandb/cg/browser/ops';
import * as Panel2 from './Panel2/panel';
import {PanelComp2} from './Panel2/PanelComp';
import {Spec as PanelExpressionSpec} from './Panel2/PanelExpression';
import {lookupRunset} from '../util/runs';

import Loader from './WandbLoader';
import {MAX_RUN_LIMIT} from '@wandb/cg/browser/utils/constants';
// import {callOp} from '@wandb/cg/browser/hl';

const PANEL_TYPE = 'Weave';

export interface WeaveConfig {
  panel2Config?: any;
  key?: string;
}

type TableProps = Panels.PanelProps<WeaveConfig>;

export function weavePanelForSummaryTableKey(key: string): WeaveConfig {
  // var name does not matter, PanelExpression will auto-replace.
  // Using something unique & ugly to force out errors.
  const varNode = CG.varNode(Types.list('run'), 'runs');
  const runSummaryNode = Op.opRunSummary({run: varNode});
  const runTableNode = Op.opPick({
    obj: runSummaryNode,
    key: Op.constString(key),
  });
  return {
    panel2Config: {exp: runTableNode},
    key,
  };
  // Uncomment the below to auto-inject the table merge. For now, I
  // prefer not auto-injecting, but this may change as we get feedback.
  // const newExp = callOp(Panel2.panelIdToPanelOpName('merge'), {
  //   input: runTableNode,
  //   config: Op.constNodeUnsafe('any', {}),
  // });

  // return {
  //   panel2Config: {exp: newExp},
  // };
}

export function getKeyOfWeavePanel(panel: Panels.PanelWithConfig<'Weave'>) {
  const expNodeFromOp = panel.config?.panel2Config?.exp?.fromOp;
  const targetKey = panel.config?.key;
  if (targetKey == null) {
    return null;
  }
  let res: string | null = null;
  if (expNodeFromOp?.name === 'pick') {
    const expKey = expNodeFromOp?.inputs?.key?.val;
    if (targetKey === expKey) {
      const expRunSummary = expNodeFromOp?.inputs?.obj?.fromOp;
      if (expRunSummary?.name === 'run-summary') {
        if (expRunSummary?.inputs?.run?.nodeType === 'var') {
          res = expKey ?? null;
        }
      }
    }
  } else if (expNodeFromOp?.name === Panel2.panelIdToPanelOpName('merge')) {
    // This branch will be useful in the case we uncomment the above injection.
    // After automatically doing .merge
    res = expNodeFromOp?.inputs?.input?.fromOp?.inputs?.key?.val ?? null;
  }
  return res;
}

/** Convert old-style pageQuery to Weave query
 *
 * An old-style pageQuery is what we use everywhere else in the app to fetch
 * run data, controlled via runsets.
 *
 * Caveats:
 *   - Uses opProjectFilteredRuns, a non-public op! We need to fix
 *     this before exporting subparts of panels in reports. The filter
 *     for opProjectFitleredRuns is an old-style filter, not editable
 *     by the expression editor.
 *     TODO: Fix this to use opProjectRuns.filter (and fix gql.ts to push
 *       weave filters down to graphql), before enabling subpanel export.
 *   - Does not convert grouped runSets to grouped Weave queries.
 *     TODO: Do this. But we'll need to figure out how to make it performant.
 *   - limited to 25 runs for performance for now.
 */
function runNodeFromPageQuery(pageQuery: QueryTS.Query) {
  const runSetNodes: Types.Node[] = [];
  if (pageQuery.runSets != null) {
    for (const runSet of pageQuery.runSets) {
      if (runSet.enabled) {
        let filters = Filter.And([runSet.filters, pageQuery.filters]);
        if (runSet.selections != null) {
          filters = Filter.And([filters, runSet.selections]);
        }
        const sort = QueryTS.sortFromJSONSafe(runSet.sort);
        const runs = Op.opProjectFilteredRuns({
          project: Op.opRootProject({
            entityName: Op.constString(
              runSet.entityName ?? pageQuery.entityName
            ),
            projectName: Op.constString(
              runSet.projectName ?? pageQuery.projectName
            ),
          }),
          filter: Op.constString(
            JSON.stringify(Filter.toMongo(Filter.simplifyFilters(filters)))
          ),
          order: Op.constString(QueryTS.sortToOrderString(sort)),
        });
        runSetNodes.push(runs);
      }
    }
  } else {
    let filters = pageQuery.filters;
    if (pageQuery.selections != null) {
      filters = Filter.And([filters, pageQuery.selections]);
    }
    const sort = QueryTS.sortFromJSONSafe(pageQuery.sort);
    const runs = Op.opProjectFilteredRuns({
      project: Op.opRootProject({
        entityName: Op.constString(pageQuery.entityName),
        projectName: Op.constString(pageQuery.projectName),
      }),
      filter: Op.constString(
        JSON.stringify(Filter.toMongo(Filter.simplifyFilters(filters)))
      ),
      order: Op.constString(QueryTS.sortToOrderString(sort)),
    });
    runSetNodes.push(runs);
  }
  if (runSetNodes.length === 0) {
    return Op.constNodeUnsafe(Types.list('run'), []);
  } else if (runSetNodes.length === 1) {
    // This will be the case when we are loading a single run workspace.
    // Setting limit to 1 allows us to make better decisions downstream -
    // in particular when displaying multi-table views.
    const limit = pageQuery.runName != null ? 1 : MAX_RUN_LIMIT;
    return Op.opLimit({
      arr: runSetNodes[0],
      limit: Op.constNumber(limit),
    });
  } else {
    const arrayArgs: {[index: string]: Types.Node} = {};
    runSetNodes.forEach((node, i) => (arrayArgs[i] = node));
    return Op.opLimit({
      arr: Op.opConcat({arr: Op.opArray(arrayArgs as any)}),
      limit: Op.constNumber(MAX_RUN_LIMIT),
    });
  }
}

const PanelWeave: React.FC<TableProps> = makeComp(
  props => {
    const {config, updateConfig} = props;
    const memoedPageQuery = useDeepMemo(props.pageQuery);

    const ungroupedPageQuery = useMemo(() => {
      return {
        ...memoedPageQuery,
        runSets: memoedPageQuery.runSets?.map(runSet => {
          return {...runSet, grouping: []};
        }),
      };
    }, [memoedPageQuery]);

    // We need to use an old-style RunsDataQuery to get the runColors
    // for now.
    const runsDataQuery = useMemo(
      () =>
        toRunsDataQuery(
          ungroupedPageQuery,
          {
            selectionsAsFilters: true,
          },
          {page: {size: 100}}
        ),
      [ungroupedPageQuery]
    );
    const {loading, data} = useRunsData(runsDataQuery);

    // Make runColors available as a variable in the panel context.
    const runColors = useMemo(
      () =>
        _.fromPairs(
          data.filtered.map(resultRun => [
            resultRun.name,
            ColorUtil.runColor(
              resultRun,
              lookupRunset(resultRun, memoedPageQuery)?.grouping ?? [],
              props.customRunColors
            ),
          ])
        ),
      [data.filtered, props.customRunColors, memoedPageQuery]
    );
    const contextVars = useMemo(
      () => ({
        runColors: Op.constNodeUnsafe(
          {type: 'dict', objectType: 'string'},
          runColors
        ),
      }),
      [runColors]
    );

    const run = useMemo(
      () =>
        loading ? CG.voidNode() : runNodeFromPageQuery(ungroupedPageQuery),
      [loading, ungroupedPageQuery]
    );

    const [context, setContext] = useState<Panel2.PanelContext>({});
    // TODO: any
    const updateContext = useCallback<Panel2.UpdateContext>(
      newContext => {
        setContext({...context, ...newContext});
      },
      [context]
    );

    const panel2Config = useMemo(() => {
      let p2Config = config.panel2Config ?? {};
      // One time migration from older table panels that did all merging internally
      // this exposes such config in the child config key which is accessible through
      // the new panel op pattern.
      if (
        p2Config.panelConfig?.combinedTableConfig != null &&
        p2Config.panelConfig?.childConfig == null
      ) {
        p2Config = {
          ...p2Config,
          panelConfig: {
            ...p2Config.panelConfig,
            childConfig: p2Config.panelConfig.combinedTableConfig,
          },
        };
      }
      return p2Config;
    }, [config.panel2Config]);

    const updatePanel2Config = useCallback<any>(
      (newConfig: any) => {
        updateConfig({
          ...config,
          panel2Config: {...config.panel2Config, ...newConfig},
        });
      },
      [config, updateConfig]
    );

    const wrapperRef = React.useRef<HTMLDivElement>(null);

    // This prevents the whole panel grid from being deleted in reports
    // when the user presses backspace. We do the same thing in weave-panels.tsx
    React.useEffect(() => {
      const el = wrapperRef.current;
      if (!el) {
        return;
      }

      const onDOMBeforeInput = (e: Event) => {
        e.stopPropagation();
      };

      el.addEventListener('beforeinput', onDOMBeforeInput);

      return () => {
        el.removeEventListener('beforeinput', onDOMBeforeInput);
      };
    }, []);

    return useGatedValue(
      <div
        ref={wrapperRef}
        style={{height: '100%', overflow: 'hidden', width: '100%'}}>
        {loading ? (
          <Loader />
        ) : (
          run.nodeType !== 'void' && (
            <PanelContextProvider newVars={contextVars}>
              <PanelComp2
                input={{path: run}}
                inputType={run.type}
                loading={false}
                panelSpec={PanelExpressionSpec}
                configMode={false}
                context={context}
                config={panel2Config}
                updateConfig={updatePanel2Config}
                updateContext={updateContext}
                noBar
              />
            </PanelContextProvider>
          )
        )}
      </div>,
      o => !loading
    );
  },
  {id: 'PanelWeave'}
);

export default PanelWeave;

function transformQuery(
  query: QueryTS.Query,
  config: WeaveConfig
): RunsDataQuery {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, WeaveConfig> = {
  type: PANEL_TYPE,
  noEditMode: true,
  Component: PanelWeave,
  transformQuery,
};
