import {useCallback, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from '../hooks';
import * as PollingReducer from './reducer';
import * as PollingActions from './actions';

const LOOP_INTERVAL = 2000;

const STALL_HISTOGRAM_BUCKET0_SIZE = 5;
const STALL_HISTOGRAM_N_BUCKETS = 13;
const STALL_HISTOGRAM_BUCKET_N_SIZE =
  STALL_HISTOGRAM_BUCKET0_SIZE * 2 ** (STALL_HISTOGRAM_N_BUCKETS - 1);
// Exponentially growing reporting interval
const STALL_HISTOGRAM_FIRST_REPORT_INTERVAL = 10000;
const STALL_HISTOGRAM_BLANK: {[key: string]: number} = {
  0: 0,
};
let bucketTop = STALL_HISTOGRAM_BUCKET0_SIZE;
for (let i = 0; i < STALL_HISTOGRAM_N_BUCKETS; i++) {
  STALL_HISTOGRAM_BLANK[bucketTop] = 0;
  bucketTop *= 2;
}

// Stores tab backgrounded and user activity states in redux, and reports
// a histogram of "stall times" to analytics.
export function useActivityDetectionLoop() {
  const lastUserActivityTime = useRef(Date.now());

  const dispatch = useDispatch();

  const setActivityTime = useCallback(() => {
    lastUserActivityTime.current = Date.now();
  }, [lastUserActivityTime]);

  const tabBackgrounded = useRef(false);
  const userInactive = useRef(false);

  // We need to put the state from redux into refs so we can access in the
  // setInterval callback (if we just use local variables the initial state
  // will get captured in the closure created for the setInterval callback)
  tabBackgrounded.current = useSelector(state => state.polling.tabBackgrounded);
  userInactive.current = useSelector(state => state.polling.userInactive);

  const stallHistogram = useRef<{[key: string]: number}>(STALL_HISTOGRAM_BLANK);
  const lastIntervalTime = useRef<number>(Date.now());
  const lastReportTime = useRef<number>(Date.now());
  const curReportInterval = useRef<number>(
    STALL_HISTOGRAM_FIRST_REPORT_INTERVAL
  );

  useEffect(() => {
    const events = ['mousemove', 'keypress'];
    events.forEach(name => {
      document.addEventListener(name, setActivityTime, true);
    });

    const interval = setInterval(() => {
      const curTabBackgrounded = document.hidden;
      const curUserInactive =
        Date.now() - lastUserActivityTime.current > 30 * 60000;

      // We keep a histogram of "stall times". A stall time is: how far off
      // did this timer fire from the time we expected it too?
      // We only track stall time when the user is active and the tab is
      // in the foreground. We report it to the analytics after 10s, and then
      // expontentionally back off from there.
      if (
        (curTabBackgrounded === true && tabBackgrounded.current === false) ||
        (curUserInactive === true && userInactive.current === false)
      ) {
        // tab became backgrounded or user became inactive
        if (Object.values(stallHistogram.current).some(count => count > 0)) {
          // window.analytics.track('js_perf', {
          //   stallHistogram: stallHistogram.current,
          // });
        }
        // Reset histogram
        for (const bucket of Object.keys(stallHistogram.current)) {
          stallHistogram.current[bucket] = 0;
        }
        lastReportTime.current = Date.now();
        curReportInterval.current = STALL_HISTOGRAM_FIRST_REPORT_INTERVAL;
      } else if (curTabBackgrounded === false && curUserInactive === false) {
        // tab is foregrounded and user is active, track this stall
        const now = Date.now();
        const intervalLength = now - lastIntervalTime.current;
        const stall = Math.abs(intervalLength - LOOP_INTERVAL);
        let bucketTopInner = STALL_HISTOGRAM_BUCKET0_SIZE;
        for (let i = 0; i < STALL_HISTOGRAM_N_BUCKETS - 1; i++) {
          if (stall < bucketTopInner) {
            let bucketBottom = bucketTopInner / 2;
            if (bucketTopInner === STALL_HISTOGRAM_BUCKET0_SIZE) {
              bucketBottom = 0;
            }
            stallHistogram.current[bucketBottom] += 1;
            break;
          }
          bucketTopInner *= 2;
        }
        if (bucketTopInner === STALL_HISTOGRAM_BUCKET_N_SIZE) {
          stallHistogram.current[STALL_HISTOGRAM_BUCKET_N_SIZE] += 1;
        }

        if (now - lastReportTime.current > curReportInterval.current) {
          // window.analytics.track('js_perf', {
          //   stallHistogram: stallHistogram.current,
          // });
          // Reset histogram
          for (const bucket of Object.keys(stallHistogram.current)) {
            stallHistogram.current[bucket] = 0;
          }
          curReportInterval.current *= 2;
          lastReportTime.current = now;
        }
      }
      lastIntervalTime.current = Date.now();

      if (curTabBackgrounded !== tabBackgrounded.current) {
        dispatch(PollingActions.setTabBackgrounded(curTabBackgrounded));
      }
      if (curUserInactive !== userInactive.current) {
        dispatch(PollingActions.setUserInactive(curUserInactive));
      }
    }, LOOP_INTERVAL);

    return () => {
      events.forEach(name => {
        document.removeEventListener(name, setActivityTime);
      });
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
}

export function usePollInterval() {
  // It's ok to use getPollInterval in the selector, since it returns a constant
  // which will be ref equal until it changes.
  return useSelector(state => getPollInterval(state.polling));
}

export function getPollInterval(pollingState: PollingReducer.StateType) {
  const {
    tabBackgrounded,
    userPollingOK,
    serverPollingOK,
    userInactive,
    basePollInterval,
  } = pollingState;
  if (!userPollingOK || !serverPollingOK) {
    return 0;
  }
  // When polling changes to 0 to some number, the polling timer restarts,
  // so we won't poll again until the new timer expires. We use a large interval
  // instead. So if the user foregrounds the tab,
  // the amount of time it was backgrounded counts toward the timer, which
  // usually means we'll poll immediately, which is what we want.
  if (tabBackgrounded) {
    return 365 * 24 * 60 * 60000;
  }
  if (userInactive) {
    return 15 * 60000;
  }
  return basePollInterval;
}
