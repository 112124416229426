import React, {useContext, useState} from 'react';
import {useContactUsForUserLedTrialMutation} from '../generated/graphql';
import {getCookieBool, setCookie} from '../util/cookie';
import makeComp from '../util/profiler';
import {
  GlobalNudgeBarContext,
  GlobalNudgeBarUpdaterContext,
} from './GlobalNudgeBarContextProvider';
import StandardPlanCheckoutModal from './StandardPlanCheckoutModal';
import {
  NudgeBarLarge,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
  NudgeBarLargeAction,
} from './NudgeBarLarge';

const TRIAL_NUDGE_COOKIE_NAME = 'hide trial nudge';

const TrialNudgeBar: React.FC = makeComp(
  () => {
    const {states} = useContext(GlobalNudgeBarContext);

    const [createContactUsForUserLedTrial] =
      useContactUsForUserLedTrialMutation();
    const {refetch} = useContext(GlobalNudgeBarUpdaterContext);
    const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
    const onContactUsClicked = async () => {
      setCookie(TRIAL_NUDGE_COOKIE_NAME, 'true');

      try {
        await createContactUsForUserLedTrial();
      } catch (e) {
        console.error('ERROR SENDING INFO TO CONTACT US', e);
      }
    };

    if (states == null || states.length === 0) {
      return null;
    }

    const alreadyContacted = getCookieBool(TRIAL_NUDGE_COOKIE_NAME);
    const contactSalesContent = !alreadyContacted ? (
      <NudgeBarLargeAction>
        <NudgeBarLargeActionLink onClick={onContactUsClicked}>
          Contact sales
        </NudgeBarLargeActionLink>
      </NudgeBarLargeAction>
    ) : (
      <NudgeBarLargeTitle>
        Our sales team will reach out over email to help you upgrade.
      </NudgeBarLargeTitle>
    );

    return (
      <>
        {states.map((state, i) => {
          const showUpgradeButton =
            state.renderedNudgeBar !== 'enterpriseToAdvanced' &&
            !state.organization?.flags?.noContact;
          return (
            (state.renderedNudgeBar === 'enterpriseToAdvanced' ||
              state.renderedNudgeBar === 'enterpriseToStandard') &&
            state.organization && (
              <NudgeBarLarge key={i}>
                <NudgeBarLargeTitle>
                  Your organization {state.organization.name}'s 30 day free
                  trial is over.{' '}
                </NudgeBarLargeTitle>
                {showUpgradeButton ? (
                  <NudgeBarLargeAction>
                    <NudgeBarLargeActionLink
                      onClick={() => {
                        window.analytics.track(
                          'banner upgrade plan button clicked',
                          {
                            org: state.organization?.id,
                          }
                        );
                        setCheckoutModalOpen(true);
                      }}>
                      Upgrade
                    </NudgeBarLargeActionLink>
                  </NudgeBarLargeAction>
                ) : (
                  contactSalesContent
                )}
                {checkoutModalOpen && state.organization && (
                  <StandardPlanCheckoutModal
                    org={state.organization}
                    onTransactionCompleted={refetch}
                    onClose={() => setCheckoutModalOpen(false)}
                  />
                )}
              </NudgeBarLarge>
            )
          );
        })}
      </>
    );
  },
  {id: 'TrialNudgeBar', memo: true}
);

export default TrialNudgeBar;
