import React from 'react';
import StringPropertyEditor from './StringPropertyEditor';
import NumberPropertyEditor from './NumberPropertyEditor';
import DropdownPropertyEditor from './DropdownPropertyEditor';
import SliderPropertyEditor from './SliderPropertyEditor';
import CheckboxPropertyEditor from './CheckboxPropertyEditor';
import IconMenuPropertyEditor from './IconMenuPropertyEditor';
import GradientPropertyEditor from './GradientPropertyEditor';
import QueryPropertyEditor from './QueryPropertyEditor';
import VegaSpecPropertyEditor from './VegaSpecPropertyEditor';
import MappingPropertyEditor from './VegaMappingPropertyEditor';
import {DropdownItemProps} from 'semantic-ui-react';
import {CustomGradient} from '../GradientPicker';
import {QueryField} from '../../util/vega3';
import * as VegaLib2 from '../../util/vega2';
import * as RunSetViewTypes from '../../state/views/runSet/types';

interface DerivedProp<T> {
  (config: any): T;
  isDerived: true;
}
type WithDerivedProps<T> = {[key in keyof T]: T[key] | DerivedProp<T[key]>};

// shorthand for defining a property editor's spec
interface MakePES<T extends string, V, E> {
  editorType: T;
  valueType: V;
  customProps: E;
  customParams: WithDerivedProps<E> & {editor: T} & (undefined extends V
      ? {default?: V}
      : {default: V});
}

interface IconMenuOption {
  icon: string;
  value: string;
}

export interface VegaSpecInfo {
  panelDefId?: string;
  customPanelDef?: VegaLib2.VegaPanelDef;
}

type PropertyEditorSpec =
  | MakePES<
      'string',
      string | undefined,
      {
        displayName: string;
      }
    >
  | MakePES<
      'dropdown',
      string | number,
      {
        displayName: string;
        options: DropdownItemProps[];
      }
    >
  | MakePES<
      'number',
      number | undefined,
      {
        displayName: string;
      }
    >
  | MakePES<
      'slider',
      number,
      {
        displayName: string;
        min: number;
        max: number;
        step: number;
      }
    >
  | MakePES<
      'checkbox',
      boolean,
      {
        displayName: string;
      }
    >
  | MakePES<
      'icon-menu',
      string,
      {
        displayName: string;
        options: IconMenuOption[];
      }
    >
  | MakePES<
      'gradient',
      CustomGradient,
      {
        displayName: string;
      }
    >
  | MakePES<
      'query',
      QueryField[],
      {
        fixedFields: QueryField[];
      }
    >
  | MakePES<'vega-spec', VegaSpecInfo, {}>
  | MakePES<
      'vega-mapping',
      {[from: string]: string},
      {query: QueryField[]; vegaSpec: VegaSpecInfo}
    >;

type PropertyEditorParams = PropertyEditorSpec['customParams'] & {
  visible?: boolean | DerivedProp<boolean>;
};
type EditorType = PropertyEditorSpec['editorType'];

type FilterByEditorType<U, T> = U extends {editorType: T} ? U : never;
export type ValueTypeFromEditorType<T> = FilterByEditorType<
  PropertyEditorSpec,
  T
>['valueType'];
export type CustomPropsFromEditorType<T> = FilterByEditorType<
  PropertyEditorSpec,
  T
>['customProps'];

export interface PanelConfigSpec {
  [key: string]: PropertyEditorParams;
}

export type PanelConfigSpecToProps<
  T extends {[key in keyof T]: PropertyEditorParams}
> = {
  [k in keyof T]: ValueTypeFromEditorType<T[k]['editor']>;
};

export type PropertyEditorComponent<k> = React.FC<
  {
    propertyName: string;
    values: Array<ValueTypeFromEditorType<k>>;
    openedPopout: string | null;
    setOpenedPopout: React.Dispatch<React.SetStateAction<string | null>>;
    save: (val: ValueTypeFromEditorType<k> | undefined) => void;
    runSetRefs: RunSetViewTypes.Ref[];
  } & CustomPropsFromEditorType<k>
>;

export const propertyEditorMap: {
  [k in EditorType]: PropertyEditorComponent<k>;
} = {
  string: StringPropertyEditor,
  dropdown: DropdownPropertyEditor,
  number: NumberPropertyEditor,
  slider: SliderPropertyEditor,
  checkbox: CheckboxPropertyEditor,
  'icon-menu': IconMenuPropertyEditor,
  gradient: GradientPropertyEditor,
  query: QueryPropertyEditor,
  'vega-spec': VegaSpecPropertyEditor,
  'vega-mapping': MappingPropertyEditor,
};

export function derived<T>(derive: (config: any) => T): DerivedProp<T> {
  return Object.assign(derive, {isDerived: true as const});
}
