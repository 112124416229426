import styled from 'styled-components';
import * as globals from '../css/globals.styles';
import {Button} from 'semantic-ui-react';

export const SuccessContainer = styled.div`
  background-color: ${globals.white};
  color: ${globals.textPrimary};
  width: 420px;
  padding: 40px 32px;
`;

export const SuccessHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

export const SuccessContent = styled.div`
  margin: 24px 0;
  font-size: 18px;
  line-height: 24px;
`;

export const ConfirmButton = styled(Button)`
  &&&&& {
    width: 100%;
    &:disabled {
      color: ${globals.gray600};
    }
  }
`;
