import React, {useCallback, useEffect, useRef, useState} from 'react';

import {parse} from 'graphql';
import {ApolloClientType as ApolloClient} from '../setup';
import {propagateErrorsContext} from './errors';
import {usePoll} from './hooks';
import {usePollInterval} from '../state/polling/hooks';

export const Apollo2Context = React.createContext<ApolloClient | undefined>(
  undefined
);

export const useApolloClient2 = () => {
  return React.useContext(Apollo2Context) as ApolloClient;
};

// Only used in the custom charts panel as of now.
export const useQuery2 = (query: string, skip?: boolean) => {
  // Note: multiply by three. The project page polls at a minimum
  // speed of 10s. It backs off based on a query weight calculation.
  // But it doesn't account for the data types returned by useQuery2
  // so we just fudge it up here.
  const globalPollInterval = usePollInterval() * 3;
  const client = useApolloClient2();

  const liveQuery = useRef(query);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [result, setResult] = useState<any>(undefined);
  const [slow, setSlow] = useState<boolean>(false);
  const slowTimer = useRef<ReturnType<typeof setTimeout>>();

  const inFlight = useRef<string>();

  const match = liveQuery.current === query;
  liveQuery.current = query;

  useEffect(() => {
    if (!match) {
      setSlow(false);
      setLoading(true);
    }
  }, [match, setSlow, setLoading]);

  const stopTimer = useCallback(() => {
    if (slowTimer.current != null) {
      clearTimeout(slowTimer.current);
      slowTimer.current = undefined;
    }
  }, [slowTimer]);
  const startTimer = useCallback(() => {
    stopTimer();
    slowTimer.current = setTimeout(() => {
      setSlow(true);
      slowTimer.current = undefined;
    }, 5000);
  }, [stopTimer, setSlow, slowTimer]);

  const makeQuery = useCallback(() => {
    if (inFlight.current === query || skip) {
      return Promise.resolve();
    }

    inFlight.current = query;
    startTimer();
    return client
      .query({
        query: parse(query),
        context: propagateErrorsContext(),
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        if (liveQuery.current === query) {
          setResult(res.data);
          setLoading(false);
          setInitialLoading(false);
          stopTimer();
          inFlight.current = undefined;
        }
      })
      .catch(e => {
        console.log(e);
        if (liveQuery.current === query) {
          setResult(undefined);
          setLoading(false);
          setInitialLoading(false);
          stopTimer();
          inFlight.current = undefined;
        }
      });
  }, [
    client,
    query,
    setResult,
    setLoading,
    startTimer,
    stopTimer,
    inFlight,
    skip,
  ]);

  usePoll(makeQuery, globalPollInterval);
  if (skip) {
    return {loading: false, result: {}, slow: false, initialLoading: false};
  }

  return match && !loading
    ? {loading: false, result, slow, initialLoading}
    : {loading: true, result, slow, initialLoading};
};
