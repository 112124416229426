import * as React from 'react';
import {Button, ButtonProps} from 'semantic-ui-react';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import PopupDropdown, {PopupDropdownProps} from '../PopupDropdown';
import makeComp from '../../util/profiler';

type WBTableActionOverflowProps = PopupDropdownProps;

const WBTableActionOverflow = makeComp(
  ({className, ...props}: WBTableActionOverflowProps) => {
    return (
      <PopupDropdown
        trigger={<OverflowButton />}
        className={`${className} wb-table-action-popup`}
        {...props}
      />
    );
  },
  {id: 'WBTableActionOverflow'}
);

export default WBTableActionOverflow;

export const OverflowButton = makeComp(
  ({className, ...props}: ButtonProps) => {
    return (
      <Button
        size="tiny"
        className={`${className || ''} wb-icon-button only-icon`}
        {...props}>
        <LegacyWBIcon name="overflow" />
      </Button>
    );
  },
  {id: 'OverflowButton'}
);
