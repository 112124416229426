import React from 'react';
import {TransitionablePortal} from 'semantic-ui-react';

import '../css/ErrorPortal.less';
import makeComp from '../util/profiler';

interface ErrorPortalProps {
  open: boolean;
}

const ErrorPortal: React.FC<ErrorPortalProps> = makeComp(
  ({open, children}) => {
    return (
      <TransitionablePortal
        open={open}
        closeOnDocumentClick={false}
        closeOnEscape={false}>
        <div className="error-portal">
          <div className="error-portal-content">{children}</div>
        </div>
      </TransitionablePortal>
    );
  },
  {id: 'ErrorPortal'}
);

export default ErrorPortal;
