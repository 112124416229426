import _ from 'lodash';
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import {Resizable} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {Button} from 'semantic-ui-react';
import DragDropContext, {
  DragSource,
  DropTarget,
  DragDropState,
} from '../containers/DragDrop';
import '../css/PanelBank.less';
import {
  isPanel,
  PanelBankFlowSectionConfig,
  PanelBankSectionComponentSharedProps,
  isDraggingWithinSection,
  DEFAULT_PANEL_SIZE,
} from '../util/panelbank';
import {makePropsAreEqual} from '../util/shouldUpdate';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as ViewHooks from '../state/views/hooks';
import * as PanelBankSectionConfigActions from '../state/views/panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelTypes from '../state/views/panel/types';
import {DispatchableAction} from '../types/redux';
import EmptyPanelBankSectionWatermark from './EmptyPanelBankSectionWatermark';
import classNames from 'classnames';
import {isFirefox} from '../util/cross-browser-magic';
import {
  panelOnActivePage,
  isMobile,
  getColumnWidth,
  getBoxDimensions,
  getPagingParams,
} from '../util/panelbankFlow';
import {PanelBankUpdaterContext} from '../state/panelbank/context';
import {skipTransition} from '../util/animations';
import makeComp from '../util/profiler';

type AllPanelBankFlowSectionProps = PanelBankSectionComponentSharedProps & {
  flowConfig: PanelBankFlowSectionConfig;
  onSetCurrentPage(currentPage: number): void;
  updateFlowConfig(
    newFlowConfig: Partial<PanelBankFlowSectionConfig>
  ): DispatchableAction;
};

const PanelBankFlowSection: React.FC<AllPanelBankFlowSectionProps> = makeComp(
  ({
    panelBankSectionConfigRef,
    panelBankWidth,
    activePanelRefs,
    inactivePanelRefs,
    movePanelBetweenSections: movePanel,
    updateFlowConfig,
    renderPanel,
    flowConfig,
    onSetCurrentPage,
  }: AllPanelBankFlowSectionProps) => {
    const {dragRef, dragging, dropRef, dragData, setDragData} =
      useContext(DragDropContext);
    const [resizingRefId, setResizingRefId] = useState<string | null>(null);
    const [resizingSize, setResizingSize] = useState<{
      width: number;
      height: number;
    }>({width: 0, height: 0});
    const [resizingSectionHeight, setResizingSectionHeight] = useState<
      number | null
    >(null);
    const [currentPage, setCurrentPage] = useState(0);

    const {rowsPerPage, gutterWidth} = flowConfig;
    const dragIndex = _.isEqual(
      dragData?.fromSectionRef,
      panelBankSectionConfigRef
    )
      ? dragData?.dragIndex
      : undefined;
    const dropIndex = _.isEqual(dropRef, panelBankSectionConfigRef)
      ? dragData?.dropIndex
      : undefined;
    const mobile = isMobile();
    const {boxWidth, boxHeight} = getBoxDimensions(panelBankWidth, flowConfig);
    const panelCount = activePanelRefs.length;
    const {panelsPerPage, panelsPerRow, maxPage} = getPagingParams({
      panelBankWidth,
      panelCount,
      flowConfig,
    });
    const paginationHeight = maxPage > 0 ? 32 : 0;
    const pageHeight = resizingSectionHeight
      ? resizingSectionHeight
      : panelCount === 0 && dragData?.dropIndex == null
      ? 0
      : (panelCount <= panelsPerRow ? 1 : rowsPerPage) *
        (gutterWidth + boxHeight);

    const startPanelIndex = currentPage * panelsPerPage;

    // renderPanels is the same length as activePanels, but non-visible panels are set to false
    // This is so panels maintain the same index -- otherwise, panels get unnecessarily re-rerendered
    const renderPanelRefs = activePanelRefs.map((pr, i) =>
      panelOnActivePage(i, currentPage, panelsPerPage) ? pr : false
    );

    // Returns [x,y] px position for a box at the given index
    const getBoxPosition = (boxPositionIndex: number) => {
      return [
        gutterWidth +
          (boxPositionIndex % panelsPerRow) * (boxWidth + gutterWidth),
        Math.floor(boxPositionIndex / panelsPerRow) * (boxHeight + gutterWidth),
      ];
    };

    const dropPreviewPosition = dropIndex != null && getBoxPosition(dropIndex);
    const draggingWithinSection = isDraggingWithinSection(
      panelBankSectionConfigRef,
      dragData
    );
    const resizePreviewPosition =
      resizingRefId != null &&
      getBoxPosition(
        _.findIndex(renderPanelRefs, {id: resizingRefId}) % panelsPerPage
      );
    const noPanels = activePanelRefs.length + inactivePanelRefs.length === 0;

    const setNewCurrentPage = useCallback(
      (cp: number) => {
        setCurrentPage(cp);
        onSetCurrentPage(cp);
      },
      [onSetCurrentPage]
    );

    // Returns the panel index of the current drop target
    const getDropIndex = useCallback(
      (xPx: number, yPx: number) => {
        const x = Math.floor(xPx / (boxWidth + gutterWidth));
        const y = Math.floor(yPx / (boxHeight + gutterWidth));
        return x + y * panelsPerRow;
      },
      [boxHeight, boxWidth, gutterWidth, panelsPerRow]
    );

    const onDragOver = useCallback(
      (ctx: DragDropState, e: React.DragEvent) => {
        const sectionBounds = e.currentTarget.getBoundingClientRect();
        const mousePosition = {
          xPx: e.clientX - sectionBounds.left,
          yPx: currentPage * pageHeight + e.clientY - sectionBounds.top,
        };
        const newDropBoxIndex = Math.min(
          getDropIndex(mousePosition.xPx, Math.max(0, mousePosition.yPx)),
          isDraggingWithinSection(panelBankSectionConfigRef, dragData) &&
            panelCount > 0
            ? panelCount - 1
            : panelCount
        );
        if (newDropBoxIndex !== dragData?.dropIndex) {
          setDragData({
            ...(dragData ?? {}),
            dropIndex: newDropBoxIndex,
          });
        }
      },
      [
        currentPage,
        dragData,
        getDropIndex,
        pageHeight,
        panelBankSectionConfigRef,
        panelCount,
        setDragData,
      ]
    );

    const getThisColumnWidth = useCallback(
      (columnCount?: number) =>
        getColumnWidth(panelBankWidth, flowConfig, columnCount),
      [panelBankWidth, flowConfig]
    );

    // Given a px width, returns the closest number of columns to accomodate that width.
    // (Enables 'snap to columns' behavior when resizing panels.)
    const getClosestColumnCount = useCallback(
      (pxWidth: number) => {
        const snapWidths = [1, 2, 3, 4, 5, 6, 7, 8].map(getThisColumnWidth);
        let closestIndex = 0;
        snapWidths.forEach((columnWidth, i) => {
          if (
            Math.abs(pxWidth - columnWidth) <
            Math.abs(pxWidth - snapWidths[closestIndex])
          ) {
            closestIndex = i;
          }
        });
        return closestIndex + 1;
      },
      [getThisColumnWidth]
    );

    useEffect(() => {
      onSetCurrentPage(currentPage);
      // eslint-disable-next-line
    }, []);

    const prevFlowConfigRef = useRef(flowConfig);
    useEffect(() => {
      const prevFlowConfig = prevFlowConfigRef.current;
      prevFlowConfigRef.current = flowConfig;
      if (_.isEqual(prevFlowConfig, flowConfig)) {
        return;
      }
      if (currentPage > maxPage) {
        setNewCurrentPage(maxPage);
      }
      setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
      // eslint-disable-next-line
    }, [flowConfig]);

    useEffect(() => {
      if (panelCount <= currentPage * panelsPerPage) {
        const newPage = Math.max(
          0,
          Math.floor((panelCount - 1) / panelsPerPage)
        );
        setNewCurrentPage(newPage);
      }
    }, [panelCount, currentPage, panelsPerPage, setNewCurrentPage]);

    return (
      <>
        {!mobile && resizePreviewPosition && (
          <div
            className="resize-preview"
            style={{
              height: resizingSize.height,
              width: resizingSize.width,
              transform: `translate(${
                resizePreviewPosition[0] + gutterWidth
              }px, ${resizePreviewPosition[1]}px) `,
            }}
          />
        )}
        <Resizable
          width={NaN}
          height={noPanels ? DEFAULT_PANEL_SIZE : pageHeight}
          axis="y"
          onResize={(e, data) => {
            const maxRows = Math.ceil(panelCount / panelsPerRow);
            updateFlowConfig({
              rowsPerPage: Math.max(
                1,
                Math.min(
                  maxRows,
                  Math.round(pageHeight / (boxHeight + gutterWidth))
                )
              ),
            });
            setResizingSectionHeight(data.size.height);
          }}
          onResizeStop={() => {
            const maxRows = Math.ceil(panelCount / panelsPerRow);
            const firstVisiblePanelIndex = panelsPerPage * currentPage;
            const newRowsPerPage = Math.max(
              1,
              Math.min(
                maxRows,
                Math.round(pageHeight / (boxHeight + gutterWidth))
              )
            );
            const newCurrentPage = Math.floor(
              firstVisiblePanelIndex / (newRowsPerPage * panelsPerRow)
            );
            updateFlowConfig({rowsPerPage: newRowsPerPage});
            setNewCurrentPage(newCurrentPage);
            setResizingSectionHeight(null);
          }}>
          <div
            className={`flow-section ${
              resizingSectionHeight != null ? 'resizing-section' : ''
            } ${resizingRefId != null ? 'resizing-panel' : ''}
            ${mobile ? 'mobile' : ''}`}
            style={{
              height: noPanels
                ? DEFAULT_PANEL_SIZE
                : pageHeight + paginationHeight,
            }}>
            <DropTarget
              className="flow-section__page"
              style={{
                // HAX: While dragging, we extend the drop target to overlap the section title so that
                // rearranging panels still works when the panel is dropped in the title area.
                // This assumes that the height of the section title area is 48px.
                height: noPanels
                  ? DEFAULT_PANEL_SIZE
                  : pageHeight + (dragging ? 48 : 0),
                paddingTop: dragging ? 48 : 0,
                top: -(dragging ? 48 : 0),
              }}
              partRef={panelBankSectionConfigRef}
              isValidDropTarget={ctx => isPanel(ctx.dragRef)}
              onDragOver={onDragOver}
              onDrop={() => {
                if (
                  movePanel != null &&
                  dragRef &&
                  dragData &&
                  dropIndex != null
                ) {
                  // Unfortunately movePanel is optional because of the way panelbank is reused
                  // in reports. But it's always passed for PanelBankFlowSection, which is
                  // only used in workspaces.
                  movePanel(
                    dragRef as PanelTypes.Ref,
                    dragData.fromSectionRef as PanelBankSectionConfigTypes.Ref,
                    panelBankSectionConfigRef,
                    dropIndex,
                    new Set(inactivePanelRefs.map(r => r.id))
                  );
                }
              }}>
              {noPanels ? (
                <EmptyPanelBankSectionWatermark />
              ) : (
                <div
                  style={{
                    height: (maxPage + 1) * pageHeight,
                    position: 'relative',
                    transform: `translateY(-${currentPage * pageHeight}px)`,
                    transition: 'transform 0.5s',
                  }}>
                  {dropPreviewPosition && (
                    <div
                      key="drop-preview"
                      className="drop-preview"
                      style={{
                        width: boxWidth,
                        height: boxHeight,
                        transform: `translate(${dropPreviewPosition[0]}px, ${dropPreviewPosition[1]}px) `,
                      }}
                    />
                  )}
                  {renderPanelRefs.map(panelRef => {
                    if (!panelRef) {
                      return null;
                    }
                    const panelRefId = panelRef.id;
                    const isResizing = resizingRefId === panelRefId;
                    const selectedForDrag =
                      dragRef && _.isEqual(dragRef, panelRef);

                    let boxPositionIndex = _.findIndex(renderPanelRefs, {
                      id: panelRefId,
                    });
                    // Slide panels to make room during drag+drop
                    if (dropIndex != null) {
                      if (selectedForDrag) {
                        boxPositionIndex = Math.min(
                          dropIndex,
                          renderPanelRefs.length - 1
                        );
                      } else if (draggingWithinSection) {
                        // dragging a panel from the same section to a lower index
                        if (
                          boxPositionIndex >= dropIndex &&
                          dragIndex != null &&
                          boxPositionIndex <= dragIndex
                        ) {
                          boxPositionIndex = boxPositionIndex + 1;
                        } else if (
                          // dragging a panel from the same section to a higher index
                          boxPositionIndex <= dropIndex &&
                          dragIndex != null &&
                          boxPositionIndex > dragIndex
                        ) {
                          boxPositionIndex = boxPositionIndex - 1;
                        }
                      } else if (
                        // dragging a panel from a different section
                        !draggingWithinSection &&
                        boxPositionIndex >= dropIndex
                      ) {
                        boxPositionIndex = boxPositionIndex + 1;
                      }
                    }
                    const boxPosition = getBoxPosition(boxPositionIndex);
                    return (
                      <React.Fragment key={panelRefId}>
                        <Resizable
                          key={panelRefId}
                          className={classNames('panel-bank__panel', {
                            'panel-bank__panel-small': boxWidth < 225,
                            resizing: isResizing,
                            dragging: selectedForDrag && dragging,
                          })}
                          axis={mobile ? 'none' : 'both'}
                          width={boxWidth}
                          height={boxHeight}
                          minConstraints={[
                            DEFAULT_PANEL_SIZE,
                            DEFAULT_PANEL_SIZE,
                          ]}
                          maxConstraints={[
                            panelBankWidth - 2 * gutterWidth,
                            panelBankWidth,
                          ]}
                          onResize={(e, data) => {
                            setResizingSize({
                              width: flowConfig.snapToColumns
                                ? getThisColumnWidth(
                                    getClosestColumnCount(data.size.width)
                                  )
                                : data.size.width,
                              height: data.size.height,
                            });
                          }}
                          onResizeStart={() => setResizingRefId(panelRefId)}
                          onResizeStop={(e, data) => {
                            const newColumnCount = getClosestColumnCount(
                              data.size.width
                            );
                            updateFlowConfig({
                              columnsPerPage: newColumnCount,
                              boxWidth: flowConfig.snapToColumns
                                ? getThisColumnWidth(newColumnCount)
                                : data.size.width,
                              boxHeight: data.size.height,
                            });
                            setResizingRefId(null);
                          }}>
                          <DragSource
                            key={panelRefId}
                            partRef={panelRef}
                            data={{
                              fromSectionRef: panelBankSectionConfigRef,
                              dragIndex: boxPositionIndex,
                            }}
                            onMouseUp={e => {
                              skipTransition(e.currentTarget, 50);
                            }}
                            style={{
                              width: boxWidth,
                              height: boxHeight,
                              ...((selectedForDrag && !dragging) || isFirefox
                                ? {
                                    left: boxPosition[0],
                                    top: boxPosition[1],
                                  }
                                : {
                                    transform: `translate(${boxPosition[0]}px, ${boxPosition[1]}px)`,
                                  }),
                            }}>
                            {renderPanel(panelRef)}
                          </DragSource>
                        </Resizable>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </DropTarget>
            {maxPage > 0 && (
              <div className="flow-section__pagination">
                <div className="pagination-controls">
                  <div className="pagination-count">
                    {startPanelIndex + 1}-
                    {Math.min(startPanelIndex + panelsPerPage, panelCount)} of{' '}
                    {panelCount}
                  </div>
                  <Button.Group className="pagination-buttons">
                    <Button
                      disabled={currentPage === 0}
                      className="page-up wb-icon-button only-icon"
                      size="tiny"
                      onClick={() => setNewCurrentPage(currentPage - 1)}>
                      <DropTarget
                        partRef={panelBankSectionConfigRef}
                        isValidDropTarget={ctx => isPanel(ctx.dragRef)}
                        onDragEnter={() => {
                          setNewCurrentPage(currentPage - 1);
                        }}>
                        <LegacyWBIcon name="previous" />
                      </DropTarget>
                    </Button>
                    <Button
                      disabled={currentPage === maxPage}
                      className="page-down wb-icon-button only-icon"
                      size="tiny"
                      onClick={() => setNewCurrentPage(currentPage + 1)}>
                      <DropTarget
                        partRef={panelBankSectionConfigRef}
                        isValidDropTarget={ctx => isPanel(ctx.dragRef)}
                        onDragEnter={() => {
                          setNewCurrentPage(currentPage + 1);
                        }}>
                        <LegacyWBIcon name="next" />
                      </DropTarget>
                    </Button>
                  </Button.Group>
                </div>
              </div>
            )}
          </div>
        </Resizable>
      </>
    );
  },
  {
    id: 'PanelBankFlowSection',
    memo: makePropsAreEqual({
      name: 'PanelBankFlowSection',
      deep: [
        'activePanelRefs',
        'inactivePanelRefs',
        'groupSelection',
        'globalSettings',
        'panelSettings',
      ],
      ignoreFunctions: true,
      // ignoreJSX: true,
      debug: false,
      verbose: false,
    }),
  }
);

const PanelBankFlowSectionFunc = makeComp(
  (props: PanelBankSectionComponentSharedProps) => {
    const {panelBankSectionConfigRef} = props;
    const {flowConfig} = ViewHooks.usePart(panelBankSectionConfigRef);
    const updateFlowConfig = ViewHooks.useViewAction(
      panelBankSectionConfigRef,
      PanelBankSectionConfigActions.updateFlowConfig
    );

    const {setCurrentPageForSectionRefID} = useContext(PanelBankUpdaterContext);
    const onSetCurrentPage = useCallback(
      (currentPage: number) => {
        setCurrentPageForSectionRefID(
          panelBankSectionConfigRef.id,
          currentPage
        );
      },
      [panelBankSectionConfigRef, setCurrentPageForSectionRefID]
    );

    return (
      <PanelBankFlowSection
        {...props}
        onSetCurrentPage={onSetCurrentPage}
        flowConfig={flowConfig}
        updateFlowConfig={updateFlowConfig}
      />
    );
  },
  {id: 'PanelBankFlowSectionFunc'}
);

export default PanelBankFlowSectionFunc;
