import React from 'react';
import {useCallback, useState} from 'react';
import {Header, Form, Segment} from 'semantic-ui-react';
import * as Generated from '../generated/graphql';
import projectNameSanitize from '../util/projectName';
import * as Urls from '../util/urls';
import * as ArtifactUtils from '../util/artifacts';

import {History} from 'history';
import '../css/NewProject.less';
import makeComp from '../util/profiler';

interface NewArtifactProps {
  entityName: string;
  projectName: string;
  history: History;
}

const NewArtifactPage = makeComp(
  (props: NewArtifactProps) => {
    const {entityName, projectName} = props;
    const [createArtifactType] = Generated.useCreateArtifactTypeMutation();
    const [name, setName] = useState('');
    const [descField, setDescField] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const {description, schema} =
      ArtifactUtils.parseArtifactTypeDescription(descField);
    const setDescription = useCallback(
      (newDescription: string) => {
        setDescField(
          ArtifactUtils.createArtifactTypeDescription(newDescription, schema)
        );
      },
      [schema]
    );
    const setSchema = useCallback(
      (newSchema: string) => {
        setDescField(
          ArtifactUtils.createArtifactTypeDescription(description, newSchema)
        );
      },
      [description]
    );

    console.log('DESC FIELD', descField);

    const canSubmit = name !== '' && name !== 'new' && name.length > 3;

    return (
      <div className="new-project-page">
        <Header style={{position: 'relative', zIndex: 100, opacity: 1}} as="h1">
          {`Create artifact in ${entityName} / ${projectName}`}
        </Header>
        <Segment>
          <Form className="ui form">
            <Form.Field>
              <Form.Input
                placeholder="Artifact type name"
                value={name}
                onChange={(_, {value}) => setName(projectNameSanitize(value))}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                placeholder="Description (optional)"
                value={description}
                onChange={(e, {value}) => {
                  console.log('VAL', value);
                  setDescription(value);
                }}
              />
            </Form.Field>
            <Form.TextArea
              placeholder="Optional JSON schema for metadata"
              value={schema}
              onChange={(e, {value}) => {
                if (typeof value === 'string') {
                  setSchema(value);
                }
              }}
            />
            <Form.Button
              disabled={submitting || !canSubmit}
              onClick={() => {
                setSubmitting(true);
                createArtifactType({
                  variables: {
                    entityName,
                    projectName,
                    name,
                    description: descField,
                  },
                }).then(() =>
                  props.history.push(
                    Urls.artifactType({
                      entityName,
                      projectName,
                      artifactTypeName: name,
                    })
                  )
                );
              }}
              primary>
              Create
            </Form.Button>
          </Form>
        </Segment>
      </div>
    );
  },
  {id: 'NewArtifactPage'}
);

export default NewArtifactPage;
