import * as globals from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';
import {WBIcon} from '@wandb/ui';
import SignalValue from './SignalValue';

export const Caret = styled(WBIcon)`
  color: #999;
`;
export const TableSelectTrigger = styled.div<{open?: boolean}>`
  padding: 4px 12px;
  position: absolute;
  right: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${Caret} {
      color: black;
    }
  }
  ${props =>
    props.open &&
    css`
      ${Caret} {
        transform: rotate(180deg);
      }
    `}
`;
export const TableSelect = styled.div`
  width: 100%;
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
  display: flex;
  font-size: 14px;
  overflow-x: auto;
  padding: 0 4px;
`;
export const TableSelectOption = styled.div<{active?: boolean}>`
  padding: 4px 12px;
  color: ${globals.gray500};
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #888;
  }
  ${props =>
    props.active &&
    css`
      color: black !important;
    `}
`;
export const TableRowCount = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 4px;
  margin-left: 4px;
  line-height: 12px;
`;

export const SignalsTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  overflow: auto;
  height: 0;
  flex-grow: 1;
`;
export const SignalRow = styled.div`
  display: flex;
  margin-bottom: 4px;
`;
export const SignalLabel = styled.div`
  color: #999;
  width: 120px;
  flex-grow: 0.4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
`;
export const StyledSignalValue = styled(SignalValue)`
  width: 200px;
  flex-grow: 0.6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
