import React, {FC, useState} from 'react';
import {Button, Dimmer, Loader, Segment, Table, Modal} from 'semantic-ui-react';
import {useQuery as useApolloQuery, useMutation} from 'react-apollo';
import {getCustomChartDescription} from '../../util/vega2';
import CopyableText from '../CopyableText';
import {CustomChartAccessIndicator} from './CustomChartAccess';
import makeComp from '../../util/profiler';
import * as Generated from '../../generated/graphql';
import {CustomChartsQueryData, CustomChart} from '../../graphql/customCharts';
import WandbLoader from '../WandbLoader';
interface VegaPanelLoadProps {
  customCharts: CustomChart[];
  onDelete(entityName: string, name: string): Promise<any>;
  onLoad(id: string): void;
}

const VegaPanelLoad: FC<VegaPanelLoadProps> = makeComp(
  ({customCharts, onDelete, onLoad}) => {
    const [deleting, setDeleting] = useState(false);

    return (
      <>
        {deleting && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <div>
          {customCharts.length === 0 ? (
            <Segment>
              No custom Vega panels have been saved in this entity yet.
            </Segment>
          ) : (
            <Table className="action-table" padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {customCharts.map(customChart => {
                  const readOnly = customChart.entity.readOnly;
                  return (
                    <Table.Row
                      className="action-table__row"
                      style={{cursor: 'auto'}}
                      key={customChart.id}>
                      <Table.Cell>
                        <CustomChartAccessIndicator
                          readOnly
                          access={
                            customChart.access
                          }></CustomChartAccessIndicator>
                        &nbsp;{customChart.displayName}
                      </Table.Cell>
                      <Table.Cell>
                        <CopyableText
                          code
                          toastText={'Copied chart ID'}
                          text={`${customChart.entity.name}/${customChart.name}`}></CopyableText>
                      </Table.Cell>
                      <Table.Cell>
                        {getCustomChartDescription(customChart.spec)}
                      </Table.Cell>
                      <Table.Cell collapsing singleLine>
                        <Button
                          className="action-table__row__load-button"
                          onClick={() => onLoad(customChart.id)}
                          disabled={deleting}
                          size="tiny"
                          primary
                          content={<span>Load</span>}
                        />
                        {!readOnly && (
                          <Button
                            disabled={deleting}
                            onClick={e => {
                              e.stopPropagation();
                              setDeleting(true);
                              onDelete(
                                customChart.entity.name,
                                customChart.name
                              ).then(() => setDeleting(false));
                            }}
                            negative
                            className="action-table__row__load-button"
                            icon="trash"
                            size="tiny"
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
      </>
    );
  },
  {id: 'Vega2.VegaPanelLoad', memo: true}
);

interface VegaPanelLoadTriggerProps {
  entityName: string;
  onLoad(viewId: string): void;
  children(props: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }): React.ReactNode;
}

export const VegaPanelLoadTrigger: React.FC<VegaPanelLoadTriggerProps> = ({
  entityName,
  onLoad,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const teamCustomChartsQuery = useApolloQuery<
    CustomChartsQueryData,
    Generated.CustomChartsQueryVariables
  >(Generated.CustomChartsDocument, {
    variables: {entity: entityName},
    skip: !open,
  });
  const skipWandbCustomChartsQuery = entityName === 'wandb' || !open;
  const wandbCustomChartsQuery = useApolloQuery<
    CustomChartsQueryData,
    Generated.CustomChartsQueryVariables
  >(Generated.CustomChartsDocument, {
    variables: {entity: 'wandb'},
    skip: skipWandbCustomChartsQuery,
  });

  let customCharts =
    teamCustomChartsQuery.data == null
      ? []
      : teamCustomChartsQuery.data.customCharts.edges.map(e => e.node);
  if (
    !skipWandbCustomChartsQuery &&
    // is null if wandb entity doesn't exist, likely on local/qa
    wandbCustomChartsQuery.data!.customCharts != null
  ) {
    customCharts = customCharts.concat(
      wandbCustomChartsQuery
        .data!.customCharts.edges.map(e => e.node)
        .filter(c => c.access === 'PUBLIC')
    );
  }

  const [deleteCustomChart] = useMutation<
    Generated.DeleteCustomChartMutationResult,
    Generated.DeleteCustomChartMutationVariables
  >(Generated.DeleteCustomChartDocument);
  return (
    <>
      {children({setOpen})}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <Modal.Content>
          {teamCustomChartsQuery.loading || wandbCustomChartsQuery.loading ? (
            <WandbLoader />
          ) : (
            <VegaPanelLoad
              customCharts={customCharts}
              onLoad={id => {
                setOpen(false);
                onLoad(id);
              }}
              onDelete={(deleteEntityName, name) => {
                return deleteCustomChart({
                  variables: {
                    entity: deleteEntityName,
                    name,
                  },
                }).then(() => teamCustomChartsQuery.refetch());
              }}></VegaPanelLoad>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default VegaPanelLoad;
