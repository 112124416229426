import React, {useState} from 'react';
import {Button, Form, Input, Segment} from 'semantic-ui-react';
import {CreateBenchmarkDiscussionThreadComponent} from '../generated/graphql';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface CreateDiscussionThreadFormProps {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const CreateDiscussionThreadForm: React.FC<CreateDiscussionThreadFormProps> =
  makeComp(
    ({benchmarkEntityName, benchmarkProjectName, onCancel, onSubmit}) => {
      const [title, setTitle] = useState('');
      const [body, setBody] = useState('');

      return (
        <div className="discussion-board-create-thread">
          <CreateBenchmarkDiscussionThreadComponent>
            {createThreadMutation => {
              return (
                <React.Fragment>
                  <div className="discussion-board">
                    <div className="topic-header-section">
                      <Button
                        size="tiny"
                        className="wb-icon-button only-icon"
                        onClick={() => onCancel()}>
                        <LegacyWBIcon name="previous" />
                      </Button>
                      <div className="topic-header less-margin">New Topic</div>
                    </div>
                    <Segment>
                      <Form>
                        <Form.Field>
                          <Input
                            placeholder="Title"
                            value={title}
                            size={'large'}
                            onChange={(e, {value}) => {
                              setTitle(value);
                            }}
                            maxLength={200}
                          />
                        </Form.Field>
                        <Form.Field>
                          <InlineMarkdownEditor
                            placeholder="Comment"
                            readOnly={false}
                            onChange={value => {
                              setBody(value as string);
                            }}
                          />
                        </Form.Field>
                        <Button
                          disabled={title === '' || body === ''}
                          onClick={() =>
                            createThreadMutation({
                              variables: {
                                benchmarkEntityName,
                                benchmarkProjectName,
                                title,
                                body,
                              },
                            }).then(() => onSubmit())
                          }>
                          Post Topic
                        </Button>
                      </Form>
                    </Segment>
                  </div>
                </React.Fragment>
              );
            }}
          </CreateBenchmarkDiscussionThreadComponent>
        </div>
      );
    },
    {id: 'CreateDiscussionThreadForm', memo: true}
  );

export default CreateDiscussionThreadForm;
