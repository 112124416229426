import {WBIcon, WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import React from 'react';
import {Popup} from 'semantic-ui-react';
import {TABLE_MIN_COLUMN_WIDTH} from '../../../util/constants';
import {
  Counts as RunCounts,
  keyDisplayName,
  keyToCss,
  keyToString,
} from '../../../util/runs';
import {makePropsAreEqual} from '../../../util/shouldUpdate';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import {DFTableColumn} from './DFTable';
import {DFTableSortIndicatorComponent} from './DFTableSortIndicator';

import copyText from 'copy-to-clipboard';
import {toast} from '../../elements/Toast';
import makeComp from '../../../util/profiler';

import {ResizableBox as ResizableBoxOriginal} from 'react-resizable';

// available types for react-resizable are incomplete
// (as of react-resizable 1.8.0, @types/react-resizable 1.7.2)
const ResizableBox = ResizableBoxOriginal as any;

export interface DFTableCellColumnHeaderProps {
  runCounts?: RunCounts;
  displayedRows?: any[]; // table rows that are displayed in the current page
  columnIndex: number;
  column: DFTableColumn;
  columnMenuItems: WBMenuOption[];
  SortIndicatorComponent?: DFTableSortIndicatorComponent;
  hovering: boolean;
  draggable: boolean;
  readOnly?: boolean;
  columnResizingProps: {
    resizing?: boolean;
    onResizeStart(): void;
    onResize(offset: number): void;
    resizeColumn(width: number): void;
  };
  columnMovingProps: {
    dragging?: boolean;
    dropping?: boolean;
    dragHandleProps: {
      onMouseDown(): void;
      onMouseUp(): void;
    };
    onDragEnd(): void;
    onDragEnter(): void;
    onDrop(): void;
  };
  columnWidth: number;
  cellHoverProps: {
    onMouseEnter(): void;
    onMouseLeave(): void;
  };
}

export const DFTableCellColumnHeader: React.FC<DFTableCellColumnHeaderProps> =
  makeComp(
    ({
      displayedRows,
      column,
      columnIndex,
      columnResizingProps,
      columnMovingProps,
      draggable,
      hovering,
      cellHoverProps,
      columnMenuItems,
      columnWidth,
      SortIndicatorComponent,
      readOnly,
    }: DFTableCellColumnHeaderProps) => {
      const columnKey = column.key;
      const keyCss = keyToCss(columnKey);
      const className = `df-tree-cell df-tree-cell--header df-tree-cell--header-column
              df-tree-cell--column-${keyCss}
              df-tree-cell--column-${columnIndex}
              ${
                hovering && keyCss !== 'run_name'
                  ? 'df-tree-cell--hovering-header'
                  : ''
              }
              ${columnResizingProps.resizing ? 'resizing' : ''}
              ${columnMovingProps.dragging ? 'dragging' : ''}
              ${columnMovingProps.dropping ? 'df-tree-cell--dropping' : ''}`;

      const columnDisplayName = keyDisplayName(column.key);
      return (
        <div
          className="df-tree-cell--header-column-wrapper"
          key={column.accessor}>
          <ResizableBox
            className={className}
            width={columnWidth}
            height={44}
            minConstraints={[TABLE_MIN_COLUMN_WIDTH, 0]}
            axis={readOnly ? 'none' : 'x'}
            onResizeStart={columnResizingProps.onResizeStart}
            onResizeStop={(e: React.SyntheticEvent, data: any) =>
              columnResizingProps.resizeColumn(data.size.width)
            }
            onResize={(e: React.SyntheticEvent, data: any) => {
              columnResizingProps.onResize(data.size.width - columnWidth);
            }}
            onMouseEnter={cellHoverProps.onMouseEnter}
            onMouseLeave={cellHoverProps.onMouseLeave}
            /* BEGIN: drag-and-drop for column reordering */
            draggable={!readOnly && columnMovingProps.dragging}
            onDragEnter={columnMovingProps.onDragEnter}
            onDragOver={(e: React.SyntheticEvent) => {
              e.preventDefault(); // this is necessary for onDrop to work
            }}
            onDragStart={(e: React.DragEvent) => {
              e.dataTransfer.setData('text', ''); // this is necessary for drag+drop to work in firefox
            }}
            onDragEnd={columnMovingProps.onDragEnd}
            onDrop={columnMovingProps.onDrop}>
            {column.renderHeader ? (
              column.renderHeader(displayedRows)
            ) : (
              <Popup
                offset={-11}
                hoverable
                inverted
                content={
                  <span
                    className="df-table-header--copyable"
                    onClick={() => {
                      copyText(columnDisplayName);
                      toast(`Column header copied to clipboard`);
                    }}>
                    <LegacyWBIcon name="copy" className={'copy-icon'} />
                    {columnDisplayName}
                  </span>
                }
                size="mini"
                style={{padding: '8px 10px'}}
                // This is the drag handle and column name
                trigger={
                  <span
                    className={draggable ? 'drag-handle' : ''}
                    onMouseDown={
                      draggable
                        ? columnMovingProps.dragHandleProps.onMouseDown
                        : undefined
                    }
                    onMouseUp={
                      draggable
                        ? columnMovingProps.dragHandleProps.onMouseUp
                        : undefined
                    }>
                    {columnDisplayName}
                  </span>
                }
              />
            )}

            {SortIndicatorComponent && (
              <SortIndicatorComponent
                columnKeyString={keyToString(column.key)}
              />
            )}

            <span className="df-tree-cell--header-column-spacer" />

            {!readOnly && (
              <WBPopupMenuTrigger options={columnMenuItems}>
                {({anchorRef, setOpen, open}) => (
                  <WBIcon
                    className="column-actions-trigger"
                    style={{display: open ? 'block' : undefined}}
                    name="overflow"
                    ref={anchorRef}
                    onClick={() => setOpen(o => !o)}></WBIcon>
                )}
              </WBPopupMenuTrigger>
            )}
          </ResizableBox>
        </div>
        // </StickyHeader>
      );
    },
    {
      id: 'DFTableCellColumnHeader',
      memo: makePropsAreEqual({
        name: 'DFTableCellColumnHeader',
        deep: [
          'column',
          'runCounts',
          'columnKey',
          'columnMenuItems',
          'columnResizingProps',
          'columnMovingProps',
        ],
        ignore: ['cellHoverProps'],
        ignoreFunctions: true,
        debug: false,
        verbose: true,
      }),
    }
  );
