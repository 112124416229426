import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Query} from 'react-apollo';
import {Button, Input, Label} from 'semantic-ui-react';

import {BenchmarkProject} from '../types/graphql';
import SubmitToBenchmarkModal from './SubmitToBenchmarkModal';
import {Viewer} from '../state/viewer/types';
import makeComp from '../util/profiler';

const SUBMIT_RUN_BY_ID_QUERY = gql`
  query SubmitRunByIdQuery(
    $entityName: String!
    $projectName: String!
    $runName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      entityName
      run(name: $runName) {
        id
        name
        displayName
        summaryMetrics
        benchmarkRun {
          id
          results
        }
      }
    }
  }
`;

interface SubmitRunByIdQueryData {
  project: {
    id: string;
    name: string;
    entityName: string;
    run: {
      id: string;
      name: string;
      displayName: string;
      summaryMetrics: any;
      benchmarkRun: {
        id: string;
        results: string;
      };
    };
  };
}

interface SubmitRunByIdQueryVariables {
  entityName: string;
  projectName: string;
  runName: string;
}

interface SubmitRunByIdProps {
  benchmark: BenchmarkProject;
  history: History;
  viewer?: Viewer;
}

const SubmitRunById: React.FC<SubmitRunByIdProps> = makeComp(
  props => {
    const [id, setId] = useState('');
    const [entityName, projectName, runName] = id
      .replace(/^\//, '') // kill trailing slash
      .replace('/runs/', '/') // kill extra '/runs' path segment
      .split('/');
    const validQuery = entityName && projectName && runName;

    return (
      <Query<SubmitRunByIdQueryData, SubmitRunByIdQueryVariables>
        query={SUBMIT_RUN_BY_ID_QUERY}
        variables={{entityName, projectName, runName}}
        skip={!validQuery}>
        {queryResult => {
          const project = queryResult.data && queryResult.data.project;

          const run =
            queryResult.data &&
            queryResult.data.project &&
            queryResult.data.project.run;

          let actionButton = null;
          let formError = false;

          // If there is a valid result allow the user to submit
          if (run) {
            actionButton = (
              <SubmitToBenchmarkModal
                runName={run.displayName}
                run={run}
                runSummary={JSON.parse(run.summaryMetrics)}
                runId={run.id}
                project={project}
                benchmarkMeta={props.benchmark}
                history={props.history}
                viewer={props.viewer}
                trigger={<Button primary>Submit</Button>}
              />
            );
          }

          // If there is a valid query and the result is loading
          // show loading button
          if (validQuery && queryResult.loading) {
            actionButton = (
              <Button primary loading>
                Submit
              </Button>
            );
          }

          // Invalid Query disables the button
          if (!validQuery) {
            actionButton = (
              <Button primary disabled>
                Submit
              </Button>
            );
          }

          if (validQuery && !run) {
            formError = true;
            actionButton = (
              <Button disabled primary>
                Submit
              </Button>
            );
          }

          return (
            <>
              <Input
                fluid
                error={formError}
                value={id}
                onChange={e => {
                  setId(e.target.value.trim());
                }}
                placeholder="Paste run path here"
                action={actionButton}
              />
              {formError && (
                <Label basic color="red" pointing>
                  No run with this path found
                </Label>
              )}
            </>
          );
        }}
      </Query>
    );
  },
  {id: 'SubmitRunById'}
);

export default SubmitRunById;
