import {envIsTest} from '../config';

let textWidthCanvas: HTMLCanvasElement | null = null;
let textWidthSpan: HTMLSpanElement | null = null;

export function textWidth(text: string, font?: string) {
  // We used to use dom measurements for text which are not sub-pixel accurate
  // like the canvas operation is so preserve the same behavior with a ceil
  const w = Math.ceil(textWidthPrecise(text, font));
  return w;
}

export function textWidthPrecise(text: string, fontOption?: string) {
  const font = fontOption ?? '16px graphein';
  // re-use canvas object for better performance

  // Firefox was occasionally throwing a NS_ERROR_FAILURE, this is a
  // recurring bug in firefox, seemingly related to improper garbage collection
  // We're attempting to mitigate this by appending to the dom.
  if (textWidthCanvas == null) {
    textWidthCanvas = document.createElement('canvas');
    textWidthCanvas.style.position = 'absolute';
    textWidthCanvas.style.opacity = '0';
    textWidthCanvas.style.top = '-1px';
    textWidthCanvas.style.height = '1px';
    textWidthCanvas.style.width = '1px';
    textWidthCanvas.style.left = '0';
    document.body.appendChild(textWidthCanvas);
  }

  const context = textWidthCanvas.getContext('2d');
  const inTest = envIsTest;
  if (context == null) {
    if (inTest) {
      return 0;
    }
    throw new Error("Something strange happened. Couldn't retrieve context");
  }

  try {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  } catch {
    return textWidthSpanFallback(text, font);
  }
}

function textWidthSpanFallback(text: string, font: string): number {
  if (textWidthSpan == null) {
    textWidthSpan = document.createElement('span');
    textWidthSpan.style.position = 'absolute';
    textWidthSpan.style.top = '-100px';
    textWidthSpan.style.left = '0';
    textWidthSpan.style.opacity = '0';
    document.body.appendChild(textWidthSpan);
  }
  textWidthSpan.style.font = font;
  textWidthSpan.textContent = text;
  return textWidthSpan.offsetWidth;
}

export function slugFormat(str: string): string {
  return str.toLowerCase().replace(/\W+/g, '-').replace(/(-)\1/g, '-');
}

export function camelize(str: string): string {
  return str
    .split(' ')
    .map(a => a.toLowerCase())
    .map((a, i) => (i === 0 ? a[0] : a[0].toUpperCase()) + a.substring(1))
    .join('');
}
