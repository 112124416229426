import * as S from '../../pages/Section.styles';

import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import config from '../../config';
import docUrl from '../../util/doc_urls';
import * as urls from '../../util/urls';
import makeComp from '../../util/profiler';
import DocsIcon from '../../assets/ic-docs.svg';
import GalleryIcon from '../../assets/ic-gallery.svg';
import ConfigurationIcon from '../../assets/ic-configuration.svg';
import LightbulbIcon from '../../assets/ic-lightbulb.svg';
import pytorchIcon from '../../assets/icon-pytorch.svg';
import kerasIcon from '../../assets/icon-keras.svg';
import ChatIcon from '../../assets/ic-chat.svg';
import {TargetBlank} from '../../util/links';
import {useViewer} from '../../state/viewer/hooks';
import {Framework} from '../../pages/HomeQuickStart';

type ResourcesListProps = {
  showOnboardingAlert: boolean;
  closeOnboardingAlert: () => void;
};

const ResourcesList: React.FC<ResourcesListProps> = makeComp(
  ({showOnboardingAlert, closeOnboardingAlert}) => {
    const viewer = useViewer();
    const onClickQuickStart = useCallback(
      (f?: Framework) =>
        window.analytics.track('Quickstart: navigate from home page', {
          username: viewer?.username,
          framework: f,
        }),
      [viewer]
    );

    return (
      <S.SidebarBlock>
        <S.SidebarBlockHeader>Resources</S.SidebarBlockHeader>
        <S.SidebarBlockLink>
          <S.SidebarBlockLinkIcon src={DocsIcon} />
          <TargetBlank href={docUrl.root}>Documentation</TargetBlank>
        </S.SidebarBlockLink>
        {!config.ENVIRONMENT_IS_PRIVATE && (
          <>
            <S.SidebarBlockLink>
              <S.SidebarBlockLinkIcon src={GalleryIcon} />
              <Link to={urls.reportGallery()}>Fully Connected</Link>
            </S.SidebarBlockLink>
            <S.SidebarBlockLink>
              <S.SidebarBlockLinkIcon src={ChatIcon} />
              <TargetBlank href={urls.communityForum()}>Community</TargetBlank>
            </S.SidebarBlockLink>
          </>
        )}
        <div>
          <S.AlertPopup
            unmountWhenClosed
            open={showOnboardingAlert}
            y={143}
            text="Quickstart Guide moved here."
            closeButton
            onClose={closeOnboardingAlert}
          />
          <S.SidebarBlockLink>
            <S.SidebarBlockLinkIcon src={LightbulbIcon} />
            <div>Quickstart</div>
          </S.SidebarBlockLink>
          <S.SidebarBlockSubsection>
            <div>
              <img src={pytorchIcon} alt={'pytorch'} />
              <TargetBlank
                href={urls.homeQuickStart() + '/pytorch'}
                onClick={() => onClickQuickStart('PyTorch')}>
                PyTorch
              </TargetBlank>
            </div>
            <div>
              <img src={kerasIcon} alt={'keras'} />
              <TargetBlank
                href={urls.homeQuickStart() + '/keras'}
                onClick={() => onClickQuickStart('Keras')}>
                Keras
              </TargetBlank>
            </div>
            <S.SidebarBlockLinkSmall
              href={urls.homeQuickStart()}
              onClick={() => onClickQuickStart()}>
              View all frameworks
            </S.SidebarBlockLinkSmall>
          </S.SidebarBlockSubsection>
        </div>
        <S.SidebarBlockLink>
          <S.SidebarBlockLinkIcon src={ConfigurationIcon} />
          <Link to={'/settings'}>Settings</Link>
        </S.SidebarBlockLink>
      </S.SidebarBlock>
    );
  },
  {id: 'ResourcesList', memo: true}
);

export default ResourcesList;
