import React from 'react';
import EmptyVisualizations from './EmptyVisualizations';
import {flashFocusCircle} from '../util/flash';
import makeComp from '../util/profiler';

const EmptyPanelBankSectionWatermark = makeComp(
  () => {
    return (
      <EmptyVisualizations
        headerText="No visualizations in this section."
        helpText={
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: 4}}>
              <span className="hide-in-report">Drag a panel here, or </span>
              <span
                className="add-vis-helper underline-dashed"
                onClick={(e: React.MouseEvent) => {
                  const el = (
                    e.currentTarget.closest('.panel-bank__section') ||
                    e.currentTarget.closest('.report-section')
                  )?.querySelector('.add-vis-button');
                  if (el) {
                    flashFocusCircle(el, {
                      minSpaceFromTop: 60,
                      padding: 3,
                      popping: true,
                    });
                  }
                }}>
                add a visualization
              </span>
              .
            </div>
          </div>
        }
      />
    );
  },
  {id: 'EmptyPanelBankSectionWatermark'}
);

export default EmptyPanelBankSectionWatermark;
