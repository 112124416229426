import React, {
  Profiler,
  ProfilerOnRenderCallback,
  PropsWithChildren,
  ReactElement,
} from 'react';
import {envIsDev} from '../config';
import {recordRender} from './analytics';

// This is copied straight from React.FC but excludes the additional properties like displayName.
// We need to isolate the function call signature because otherwise TypeScript higher-order type inference breaks.
// See https://github.com/microsoft/TypeScript/pull/30215#issue-258109340
// Specifically, this is one of the requirements for it to work:
// "the called function is a generic function that returns a function type with a single call signature"
type FC<P = {}> = (
  props: PropsWithChildren<P>,
  context?: any
) => ReactElement<any, any> | null;

const onRenderCallback: ProfilerOnRenderCallback = (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime,
  interactions
) => {
  recordRender(id, actualDuration);
};

const withProfiler = envIsDev
  ? <T,>(id: string, Comp: FC<T>): FC<T> => {
      const withProf: typeof Comp = props => {
        return (
          <Profiler id={id} onRender={onRenderCallback}>
            <Comp {...props} />
          </Profiler>
        );
      };
      (withProf as React.FC<T>).displayName = `${id}WithProfiler`;
      return withProf;
    }
  : <T,>(id: string, Comp: FC<T>): FC<T> => Comp;

type PropsAreEqual<T> = (
  prevProps: Readonly<PropsWithChildren<T>>,
  nextProps: Readonly<PropsWithChildren<T>>
) => boolean;

interface MakeCompOpts<T> {
  id: string;
  memo?: true | PropsAreEqual<T>;
  disableProfiler?: true;
}

const makeComp = <T,>(
  Comp: FC<T>,
  {id, memo, disableProfiler}: MakeCompOpts<T>
): FC<T> => {
  (Comp as React.FC<T>).displayName = id;

  if (false && envIsDev && !disableProfiler) {
    Comp = withProfiler(id, Comp);
  }

  if (memo) {
    Comp = React.memo(
      Comp,
      memo !== true ? memo : undefined
    ) as unknown as typeof Comp;
  }

  return Comp;
};

export default makeComp;
