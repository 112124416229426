// Functions for querying runs
import * as _ from 'lodash';

import * as Generated from '../../generated/graphql';
import * as Filter from '../../util/filters';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Run from '../../util/runs';
import {ApolloClient} from '../types';
import {
  ServerDeltaOp,
  ServerQueryDelta,
  ServerResultDelta,
} from './serverQuery_serverDelta';
import * as Types from './types';
import {propagateErrorsContext} from '../../util/errors';
import {sortToOrderString} from '../../util/queryts';

// Convert a query into the variables needed to make the graphql request.
function queryToGQLVars(
  query: Types.Query,
  filters: Filter.Filter
): Generated.RunsStateQueryQueryVariables {
  return {
    entityName: query.entityName,
    projectName: query.projectName,
    order: sortToOrderString(query.sort),
    filters: JSON.stringify(Filter.toMongo(filters)),
    groupKeys: query.grouping.map(Run.keyToServerPath),
    groupLevel: 0,
    enableSampledHistory: query.historySpecs != null,
    sampledHistorySpecs:
      query.historySpecs != null
        ? query.historySpecs.map(hs => JSON.stringify(hs))
        : [],
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableBasic: query.enableBasic || false,
    enableConfig: query.configKeys != null || query.fullConfig === true,
    configKeys: query.fullConfig === true ? undefined : query.configKeys,
    enableSummary: query.summaryKeys != null || query.fullSummary === true,
    summaryKeys: query.fullSummary === true ? undefined : query.summaryKeys,
    enableWandb: query.wandbKeys != null,
    wandbKeys: query.wandbKeys,
    limit: query.limit,
  };
}

const EMPTY_SINGLE_RUNS_QUERY_RESULT = {
  totalRuns: 0,
  runs: [],
  lastUpdatedAt: new Date(0).toISOString(),
};

export const doSingleRunsQuery = (client: ApolloClient, query: Types.Query) => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    return Promise.resolve(EMPTY_SINGLE_RUNS_QUERY_RESULT);
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }

  return client
    .query<Generated.RunsStateQueryQuery>({
      query: Generated.RunsStateQueryDocument,
      fetchPolicy: 'no-cache',
      variables: queryToGQLVars(query, filters),
      context: propagateErrorsContext(),
    })
    .then(result => {
      const project = result.data.project;
      if (project == null || project.runs == null) {
        throw new Error('Runs query failed with invalid project');
      }
      const nodes = project.runs.edges.map(e => {
        const parsedRun = Run.fromJson(e.node);
        if (parsedRun == null) {
          console.warn("Couldn't parse run from server: ", e.node);
        }
        return parsedRun;
      });
      return {
        totalRuns: project.runs.totalCount,
        runs: nodes.filter(Obj.notEmpty),
        lastUpdatedAt:
          _.max(nodes.map(n => n && n.updatedAt)) || new Date(0).toISOString(),
      };
    });
};

function deltaQueryToGQLVars(
  query: ServerQueryDelta,
  filters: Filter.Filter
): Generated.RunsStateDeltaQueryQueryVariables {
  return {
    ...queryToGQLVars(query, filters),
    currentRuns: query.prevResult.page,
    lastUpdated: new Date(query.prevResult.maxUpdatedAt),
  };
}

function parseGqlDeltaOp(op: Generated.RunDiff): ServerDeltaOp {
  if (op.op === 'INSERT' || op.op === 'UPDATE') {
    const parsedRun = Run.fromJson(op.run);
    if (parsedRun == null) {
      throw new Error(
        `Couldn't parse run from server: ${JSON.stringify(op.run)}`
      );
    }
    // typescript really doesn't like this union type for some reason
    if (op.op === 'INSERT') {
      return {
        op: 'INSERT',
        index: op.index,
        run: parsedRun,
      };
    } else {
      return {
        op: 'UPDATE',
        index: op.index,
        run: parsedRun,
      };
    }
  } else if (op.op === 'DELETE') {
    return {
      op: op.op,
      index: op.index,
    };
  }
  throw new Error(`Couldn't parse run diff from server: ${JSON.stringify(op)}`);
}

export const doDeltaQuery = (
  client: ApolloClient,
  query: ServerQueryDelta
): Promise<ServerResultDelta> => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    // Delete everything in reverse order
    return Promise.resolve({
      totalRuns: 0,
      delta: _.range(0, query.prevResult.page.length)
        .reverse()
        .map(i => ({op: 'DELETE', index: i})),
    });
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }
  return client
    .query<Generated.RunsStateDeltaQueryQuery>({
      query: Generated.RunsStateDeltaQueryDocument,
      fetchPolicy: 'no-cache',
      variables: deltaQueryToGQLVars(query, filters),
      context: propagateErrorsContext(),
    })
    .then(result => {
      const project = result.data.project;
      if (project == null || project.runs == null) {
        return {
          totalRuns: 0,
          delta: [],
        };
      }
      /* TS3.9 upgrade caused type mismatch here, because generated gql Run
         type doesn't match our hand-written one */
      const ops = project.runs.delta.map(x => parseGqlDeltaOp(x as any));
      return {
        totalRuns: project.runs.totalCount,
        delta: ops,
      };
    });
};

export const doServerInfoQuery = (
  client: ApolloClient
): Promise<Types.ServerInfo> =>
  client
    .query<Generated.ServerInfoQueryQuery>({
      query: Generated.ServerInfoQueryDocument,
      fetchPolicy: 'no-cache',
      variables: {},
      context: propagateErrorsContext(),
    })
    .then(result => {
      const serverInfo = result.data.serverInfo;
      if (serverInfo == null) {
        throw new Error('Server info query failed');
      }
      return serverInfo;
    });
