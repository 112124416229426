import * as React from 'react';
import {Media3DProps} from './PointCloud';
import {DefaultViewer} from '@babylonjs/viewer';
import makeComp from '../util/profiler';

export const Viewer3D: React.FunctionComponent<Media3DProps> = makeComp(
  props => {
    const {url} = props;

    const babylonContainerRef = React.useRef<HTMLDivElement>(null);

    // Load babylon lib asynchronously to perform bundle splitting
    type BabylonLib = typeof import('../util/render_babylon');
    const [babylonLib, setBabylon] = React.useState<BabylonLib>();
    React.useEffect(() => {
      import('../util/render_babylon').then(b => {
        setBabylon(b);
      });
    }, []);

    const viewer = React.useRef<DefaultViewer>();

    // First render of Viewer
    React.useEffect(() => {
      const container = babylonContainerRef.current;
      if (container && babylonLib) {
        // Create viewer on first render
        if (viewer.current == null) {
          viewer.current = babylonLib.renderViewer(container, url);
        } else {
          viewer.current.loadModel(url);
        }
      }
    }, [url, babylonLib]);

    // Cleanup at unmount
    React.useEffect(() => {
      return () => {
        if (viewer.current) {
          viewer.current.dispose();
        }
      };
    }, []);

    return <div className="object3D-card-babylon" ref={babylonContainerRef} />;
  },
  {id: 'Viewer3D'}
);

export default Viewer3D;
