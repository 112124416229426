import * as S from './AddUserModal.styles';

import React, {FunctionComponent, useState, ReactNode, FC} from 'react';
import {Button, Form, Modal} from 'semantic-ui-react';

import makeComp from '../util/profiler';
import {
  ContactForFreeLicenseContent,
  ContactForUpgradeContent,
} from './ContactModalContent';
import {useViewer} from '../state/viewer/hooks';
import {envIsLocal, urlPrefixed} from '../config';
import {
  useCreateUserFromAdminMutation,
  useSearchUsersQuery,
  useUndeleteUserMutation,
  useUserLimitReachedQuery,
} from '../generated/graphql';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {SEARCH_USERS_QUERY} from '../graphql/users';
import LinkButton from './LinkButton';
import {ApolloError} from 'apollo-client';

interface AddUserModalProps {
  open: boolean;
  trigger?: React.ReactNode;
  header?: JSX.Element;
  query?: string;
  onClose(): void;
  onOpen(): void;
  setMessage?(error?: MessageObject): void;
}
interface MessageObject {
  text: ReactNode;
  type: 'error' | 'warning' | 'success';
}

export const AddUserModal: FunctionComponent<AddUserModalProps> = makeComp(
  props => {
    const {
      open,
      onClose,
      onOpen,
      query = '',
      setMessage = (error?: MessageObject) => {
        console.log(error?.text);
      },
    } = props;
    const viewer = useViewer();

    const userLimitReachedQuery = useUserLimitReachedQuery({
      fetchPolicy: 'no-cache',
      skip: !open,
    });

    const usingDefaultLimit = viewer?.limits.seats === 1;

    const content = envIsLocal ? (
      !userLimitReachedQuery.data?.serverInfo?.userLimitReached ? (
        <AddUserForm query={query} onClose={onClose} setMessage={setMessage} />
      ) : usingDefaultLimit ? (
        ContactForFreeLicenseContent
      ) : (
        <ContactForUpgradeContent numSeats={viewer?.limits.seats} />
      )
    ) : (
      <div data-test="feature-unavailable">
        This feature is only available in the local version of W&B!
      </div>
    );

    return (
      <Modal
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        trigger={props.trigger}
        size="tiny">
        <Modal.Header>
          <S.ModalHeaderWrapper>
            {props.header ?? 'Add user'}
          </S.ModalHeaderWrapper>
        </Modal.Header>
        <S.ModalContentWrapper>
          <Modal.Content>{content}</Modal.Content>
        </S.ModalContentWrapper>
      </Modal>
    );
  },
  {id: 'AddUserModal'}
);

const RE_ENABLED_MESSAGE =
  'User re-enabled. To send an invite email or copy an invite link, see the Action menu for this user.';

interface AddUserFormProps {
  query: string;
  onClose: () => void;
  setMessage: (error?: MessageObject) => void;
}

const AddUserForm: FC<AddUserFormProps> = makeComp(
  (props: AddUserFormProps) => {
    const {query, onClose, setMessage} = props;

    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserAdmin, setNewUserAdmin] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);
    const [shouldSendInvite, setShouldSendInvite] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const existingUsers = useSearchUsersQuery({
      variables: {query},
      fetchPolicy: 'cache-and-network',
    });
    const [undeleteUser] = useUndeleteUserMutation({
      context: propagateErrorsContext(),
    });

    const sendInvite = (email: string) =>
      fetch(urlPrefixed('/admin/invite_email'), {
        method: 'POST',
        body: JSON.stringify({email}),
      })
        .then(() => {
          setMessage({
            text: `Sent invite to ${email}`,
            type: 'success',
          });
        })
        .catch(err => {
          console.error(err);
          setMessage({
            text: 'Error sending invite.',
            type: 'error',
          });
        });

    const [createUser] = useCreateUserFromAdminMutation({
      context: propagateErrorsContext(),
      refetchQueries: [
        {
          query: SEARCH_USERS_QUERY,
          variables: {query},
        },
      ],
      awaitRefetchQueries: true,
    });

    const getErrorMessage = (err: ApolloError) => {
      const text = extractErrorMessageFromApolloError(err);
      if (!text) {
        return undefined;
      }

      return {
        text,
        type: 'error',
      } as const;
    };

    const existingUserEdge = (existingUsers.data?.users?.edges || []).find(
      edge => edge.node?.email === newUserEmail
    );
    const emailInUse = !!existingUserEdge;
    const existingUserDisabled = existingUserEdge?.node?.deletedAt != null;
    const emailIsBlank = !!newUserEmail.match(/^\s*$/);

    let errorMessage: ReactNode | undefined;

    if (emailIsBlank) {
      errorMessage = 'Email must not be blank.';
    } else if (emailInUse && existingUserDisabled) {
      errorMessage = (
        <S.InUseMessageWrapper>
          Email is already in use by a disabled user.{' '}
          <LinkButton
            onClick={async () => {
              if (existingUserEdge != null && existingUserEdge.node != null) {
                undeleteUser({variables: {id: existingUserEdge?.node?.id}})
                  .then(() => {
                    setMessage({type: 'success', text: RE_ENABLED_MESSAGE});
                  })
                  .catch(err => setMessage(getErrorMessage(err)));
                onClose();
              }
            }}>
            Click here to re-enable them.
          </LinkButton>
        </S.InUseMessageWrapper>
      );
    } else if (emailInUse) {
      errorMessage = 'Email is already in use.';
    }

    const onSubmitHandler = async () => {
      if (errorMessage) {
        return;
      }
      setSubmitting(true);
      try {
        await createUser({
          variables: {
            email: newUserEmail,
            admin: newUserAdmin,
          },
        });

        if (shouldSendInvite) {
          try {
            await sendInvite(newUserEmail);
            setMessage({
              text: `User added and invite email sent to ${newUserEmail}`,
              type: 'success',
            });
          } catch (err) {
            console.error(err);
            setMessage({
              text: 'User added successfully, but invite email failed to send -- use Send Invite in the Action menu for this user to retry.',
              type: 'warning',
            });
          }
        } else {
          setMessage({
            text: 'User added. To send an invite email or copy an invite link, see the Action menu for this user.',
            type: 'success',
          });
        }
      } catch (err) {
        setMessage(getErrorMessage(err));
      }
      setSubmitting(false);
      onClose();
    };

    return (
      <Form
        data-test="add-user-form"
        onClose={() => {
          setNewUserEmail('');
          setNewUserAdmin(false);
          setHasInteracted(false);
          onClose();
        }}>
        <Form.Input
          data-test="new-user-email"
          label="Email"
          value={newUserEmail}
          onChange={(e, {value}) => {
            setNewUserEmail(value.replace(/\s+/g, ''));
            setHasInteracted(true);
          }}
          error={hasInteracted && !submitting && errorMessage}
        />
        <Form.Radio
          data-test="new-user-admin"
          toggle
          label="Admin"
          checked={newUserAdmin}
          onClick={() => {
            setNewUserAdmin(prev => !prev);
            setHasInteracted(true);
          }}
        />
        <Form.Checkbox
          data-test="new-user-send-email"
          toggle
          label="Send Invite Email"
          checked={shouldSendInvite}
          onClick={() => {
            setShouldSendInvite(prev => !prev);
            setHasInteracted(true);
          }}
        />
        <S.ButtonWrapper>
          <Button
            data-test="new-user-cancel"
            type="button"
            content="Cancel"
            onClick={onClose}
          />
          <Button
            data-test="new-user-submit"
            color="blue"
            disabled={emailIsBlank || emailInUse}
            onClick={onSubmitHandler}>
            Submit
          </Button>
        </S.ButtonWrapper>
      </Form>
    );
  },
  {id: 'AddUserForm'}
);
