import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Redux from '../../types/redux';

import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Thunks from './thunks';
import * as Types from './types';
import {RETURN_NULL} from '../../util/constants';

// Hook that simplifies dispatching redux view part actions, by binding them
// to a specific view part reference.
export function useViewAction<
  RefType extends Types.AllPartRefs,
  ArgType extends any[]
>(
  ref: RefType,
  fn: (ref: RefType, ...fnArgs: ArgType) => Redux.DispatchableAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(ref, ...args)),
    [dispatch, ref, fn]
  );
}

// Like the above version, but binds all arguments. Useful when an action requires multiple refs.
export function useViewActionBindAll<
  RefType extends Types.AllPartRefs,
  ArgType extends any[]
>(
  fn: (ref: RefType, ...fnArgs: ArgType) => Redux.RootAction,
  ref: RefType,
  ...args: ArgType
) {
  const dispatch = useDispatch();
  return useCallback(() => {
    return dispatch(fn(ref, ...args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ref, fn, ...args]);
}

// Hook that simplifies dispatching redux view actions on a nullable view ref.
export function useViewRefAction<
  RefType extends Types.ViewRef,
  ArgType extends any[]
>(
  ref: RefType | null,
  fn: (ref: RefType, ...fnArgs: ArgType) => Redux.RootAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => (ref != null ? dispatch(fn(ref, ...args)) : void 0),
    [dispatch, ref, fn]
  );
}

export function useViewRefThunk<
  RefType extends Types.ViewRef,
  ArgType extends any[],
  ThunkType extends Redux.ThunkResult<any>
>(ref: RefType | null, fn: (ref: RefType, ...fnArgs: ArgType) => ThunkType) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => (ref != null ? dispatch(fn(ref, ...args)) : void 0),
    [dispatch, ref, fn]
  );
}

export function useObjectCopy<RefType extends Types.AllPartRefs>(ref: RefType) {
  const [copyRef, setCopyRef] = useState<RefType | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // Copy the part on mount
    const action = Actions.copyObject(ref);
    dispatch(action);
    const newRef = action.payload.ref as RefType;
    setCopyRef(newRef);
    return () => {
      // Clean up on unmount
      setCopyRef(null);
      dispatch(Thunks.deleteObject(newRef));
    };
  }, [dispatch, ref]);

  // Becomes true once the copy action succeeds
  const partExists = useSelector(Selectors.makePartExistsSelector(copyRef));

  if (copyRef != null && partExists) {
    return {
      copying: false as false,
      ref: copyRef,
    };
  }
  return {copying: true as true};
}

// This could be more explicitly called useTemporaryWhole but that's
// verbose and ugly. So trying out useNewObjectRef to see how it feels.
export function useNewObjectRef<T extends Types.ObjType>(
  type: T,
  whole: Types.WholeFromType<T>
) {
  return useExistingOrNewObjectRef(type, whole);
}

export function useExistingOrNewObjectRef<T extends Types.ObjType>(
  type: T,
  whole: Types.WholeFromType<T>,
  existing?: Types.PartRefFromType<T>
) {
  const [ref, setRef] = useState<Types.PartRefFromType<T> | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (existing != null) {
      return;
    }
    const wholeAndType = {
      type,
      whole,
    } as unknown as Types.AllWholeWithTypesHelper<T>;
    // Add the object on mount
    const action = Actions.addObject(wholeAndType);
    dispatch(action);
    const newRef = action.payload.ref as Types.PartRefFromType<T>;
    setRef(newRef);

    // Clean up on unmount
    return () => {
      dispatch(Thunks.deleteObject(newRef));
      setRef(null);
    };
  }, [dispatch, type, whole, existing]);

  // Becomes true once the copy action succeeds
  const partExists = useSelector(Selectors.makePartExistsSelector(ref));

  if (existing != null) {
    return {
      ready: true as true,
      ref: existing,
    };
  }
  if (ref != null && partExists) {
    return {
      ready: true as true,
      ref,
    };
  }
  return {ready: false as false};
}

export function useWhole<T extends Types.ObjType>(
  ref: Types.PartRefFromType<T>
) {
  const selector = useMemo(() => Selectors.makeWholeSelector(ref), [ref]);
  return useSelector(selector);
}

export function useWholeMaybe<T extends Types.ObjType>(
  ref?: Types.PartRefFromType<T> | null
) {
  const selector = useMemo(
    () => (ref != null ? Selectors.makeWholeSelector(ref) : RETURN_NULL),
    [ref]
  );
  return useSelector(selector);
}

export function useWholeArray<T extends Types.ObjType>(
  refs: Array<Types.PartRefFromType<T>>
) {
  const stateRef = useRef({});
  const wholeArraySelector = useMemo(
    () => Selectors.makeWholeObjectArraySelector(refs, stateRef.current),
    [refs]
  );
  return useSelector(wholeArraySelector);
}

export function useWholeMapped<T extends Types.ObjType, R>(
  ref: Types.PartRefFromType<T>,
  map: (whole: Types.WholeFromType<T>) => R
) {
  // Instead of memoizing the selector, we create a stateRef to hold
  // static variables across calls to this selector. This is different from how
  // we do useWhole because we have the map fn to consider. The map fn can't
  // be easily memoized because we expect the reference to change on every call.
  const stateRef = useRef({});
  const wholeSelector = useMemo(() => Selectors.makeWholeSelector(ref), [ref]);
  return useSelector(
    Selectors.makeWholeMappedSelector(
      wholeSelector,
      map as (t: any) => R,
      stateRef.current
    )
  );
}

export function usePart<T extends Types.ObjType>(
  ref: Types.PartRefFromType<T>
) {
  const selector = useMemo(() => Selectors.makePartSelector(ref), [ref]);
  return useSelector(selector);
}

export function usePartMaybe<T extends Types.ObjType>(
  ref?: Types.PartRefFromType<T> | null
) {
  const selector = useMemo(
    () => (ref != null ? Selectors.makePartSelector(ref) : RETURN_NULL),
    [ref]
  );
  return useSelector(selector);
}

export function useParts<T extends Types.ObjType>(
  refs: Array<Types.PartRefFromType<T>>
) {
  const selector = useMemo(() => Selectors.makePartsSelector(refs), [refs]);
  return useSelector(selector);
}

export function useOptionalPart<T extends Types.ObjType>(
  ref: Types.PartRefFromType<T> | undefined
) {
  const selector = useMemo(
    () => Selectors.makeOptionalPartSelector(ref),
    [ref]
  );
  return useSelector(selector);
}

export function usePartMapped<T extends Types.ObjType, R>(
  ref: Types.PartRefFromType<T>,
  map: (part: Types.PartFromType<T>) => R
) {
  const stateRef = useRef({});
  return useSelector(
    Selectors.makePartMappedSelector(
      ref,
      map as (t: any) => R,
      stateRef.current
    )
  );
}
