import * as Panel from './panel';

import {Spec as BooleanSpec} from './PanelBoolean';
import {Spec as IdSpec} from './PanelId';
import {Spec as IdCompareSpec} from './PanelIdCompare';
import {Spec as IdCompareCountSpec} from './PanelIdCompareCount';
import {Spec as IdCountSpec} from './PanelIdCount';
import {Spec as TypeSpec} from './PanelType';
import {Spec as DateSpec} from './PanelDate';
import {Spec as NumberSpec} from './PanelNumber';
import {Spec as StringSpec} from './PanelString';
import {Spec as LinkSpec} from './PanelLink';
import {Spec as NullSpec} from './PanelNull';
import {Spec as StringHistogramSpec} from './PanelStringHistogram';
import {Spec as MultiStringHistogramSpec} from './PanelMultiStringHistogram';
import {Spec as BarChartSpec} from './PanelBarChart';
import {Spec as HistogramSpec} from './PanelHistogram';
import {Spec as PlotSpec} from './PanelPlot';
// import {Spec as FacetSpec} from './PanelFacet';
// import {Spec as LayoutContainerSpec} from './PanelLayoutContainer';
// import {Spec as VariablesSpec} from './PanelVariables';
import {Spec as MultiHistogramSpec} from './PanelMultiHistogram';
import {Spec as UnknownSpec} from './PanelUnknown';
import {Spec as ImageSpec} from './PanelImage';
import {Spec as ImageCompareSpec} from './PanelImageCompare';
import {Spec as VideoSpec} from './PanelVideo';
import {Spec as AudioSpec} from './PanelAudio';
import {Spec as HTMLSpec} from './PanelHTML';
import {Spec as BokehSpec} from './PanelBokeh';
import {Spec as Object3DSpec} from './PanelObject3D';
import {Spec as MoleculeSpec} from './PanelMolecule';

import {Spec as ObjectSpec} from './PanelObject';
import {Spec as ProjectOverviewSpec} from './PanelProjectOverview';
import {Spec as RunOverviewSpec} from './PanelRunOverview';

import {TableSpec} from './PanelTable';

import {Spec as StringCompare} from './PanelStringCompare';

// files
import {Spec as FileTextSpec} from './PanelFileText';
import {Spec as FileTextDiffSpec} from './PanelFileTextDiff';
import {Spec as FileMarkdownSpec} from './PanelFileMarkdown';
import {Spec as FileRawImageSpec} from './PanelFileRawImage';
import {Spec as FileJupyterSpec} from './PanelFileJupyter';
import {Spec as DirSpec} from './PanelDir';
import {Spec as NetronSpec} from './PanelNetron';
import {Spec as TraceSpec} from './PanelTrace';
import {Spec as WebVizSpec} from './PanelWebViz';

import {Spec as NDArraySpec} from './PanelNDArray';

// converters
import {Spec as RowSpec} from './PanelRow';
import {Spec as WBObjectSpec} from './PanelWBObject';
import {Spec as MaybeSpec} from './PanelMaybe';

import {Spec as MultiTableSpec2} from './PanelTableMerge';

// TODO: Wrap Panel components with makeSpec calls

// These are the all the registered panels. To register a new one, just add
// its spec here.
// The order of this array determines the default panel recommendation order.
// See scoreHandlerStack in availablePanels.ts for the function that
// determines the final order.
export const PanelSpecs = [
  IdSpec,
  IdCompareSpec,
  IdCompareCountSpec,
  IdCountSpec,

  TypeSpec,
  DateSpec,
  BooleanSpec,

  // numbers
  NumberSpec,
  BarChartSpec,
  HistogramSpec,
  MultiHistogramSpec,

  // strings
  StringSpec,
  StringCompare,
  StringHistogramSpec,
  MultiStringHistogramSpec,

  // arrays
  TableSpec,
  PlotSpec,
  // FacetSpec,

  // Do we still need these?
  NullSpec,
  UnknownSpec,

  // explicit links
  LinkSpec,

  // Objects
  ObjectSpec,

  // WB Objects
  ProjectOverviewSpec,
  RunOverviewSpec,

  // other files
  ImageSpec,
  ImageCompareSpec,
  FileJupyterSpec,
  FileRawImageSpec,
  FileMarkdownSpec,
  FileTextDiffSpec,
  FileTextSpec,
  NetronSpec,
  TraceSpec,
  WebVizSpec,
  VideoSpec,
  AudioSpec,
  HTMLSpec,
  BokehSpec,
  Object3DSpec,
  MoleculeSpec,

  // directories
  DirSpec,

  NDArraySpec,

  // Organizational
  // LayoutContainerSpec,
  // VariablesSpec,
];

export const ConverterSpecs = [
  RowSpec,
  WBObjectSpec,
  MaybeSpec,
  Panel.toConvertSpec(MultiTableSpec2),
];
