import {createAction} from 'typesafe-actions';
import * as Types from './types';

export const setContent = createAction(
  '@view/markdownBlock/setContent',
  action => (ref: Types.Ref, content: string) => action({ref, content})
);

export const setCollapsed = createAction(
  '@view/markdownBlock/setCollapsed',
  action => (ref: Types.Ref, collapsed: boolean) => action({ref, collapsed})
);
