import * as React from 'react';
import {useMemo} from 'react';
import {CreateViewDiscussionCommentMutationVariables} from '../generated/graphql';
import {useReportDiscussion} from '../state/reports/hooks';
import {ReportViewRef} from '../state/reports/types';
import {Viewer} from '../state/viewer/types';
import {Ref as DiscussionThreadRef} from '../state/views/discussionThread/types';
import {Ref as DiscussionCommentRef} from '../state/views/discussionComment/types';
import {Ref as PanelRef} from '../state/views/panel/types';
import {
  getPanelSpec,
  LayedOutPanelWithRef,
  LayedOutPanel,
} from '../util/panels';
import makeComp from '../util/profiler';
import {MentionableUser} from './ReportDiscussion';
import {profilePage} from '../util/urls';
import {Link} from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {isEqual} from 'lodash';
import {useWholeMaybe} from '../state/views/hooks';

interface ReportDiscussionContextState {
  reportViewRef: ReportViewRef | null;
  reportServerID: string | null;
  viewer: Viewer | undefined;
  loadingDiscussionThreads: boolean;
  loadingCreateComment: boolean;
  // All discussion threads for this report
  discussionThreadRefs: DiscussionThreadRef[];
  // Used for @user mentions in comments
  teamMembers: MentionableUser[];
  // All panels in the report, used for &panel mentions in comments
  reportPanels: CommentableItem[];
  // Panels that should be highlighted with a yellow border,
  // to indicate that they're referenced in the active thread or in the current draft comment
  highlightIds: string[];
  // If 'editor', <CommentEditorFrame> (new thread) will be visible.
  // if 'thread', <CommentThreadFrame> (view / reply to existing thread) will be visible.
  // If undefined, no comment frame will be visible
  commentFrame?: 'editor' | 'thread' | undefined;
  // The currently-visible thread in <CommentThreadFrame>
  activeThreadRef?: DiscussionThreadRef | undefined;
  // All currently-viewable threads in <CommentThreadFrame>
  // Defaults to all (discussionThreadRefs) but is filtered if you're viewing a single panel's threads
  allActiveThreadRefs: DiscussionThreadRef[];
  // If true, autofocus the reply text box
  autofocus: boolean;
  // Whether or not users can add comments to panels (or &-reference panels in comments)
  // Panel comments are disabled in old reports until the author saves them again, because the panels don't have persistent IDs
  // See ActionsInternal.loadFinished in reducer.ts for more info
  panelCommentsEnabled: boolean;
  getPanelData(
    panelId: string,
    fallbackDisplayText?: string
  ): {
    panel?: CommentableItem;
    displayText: string;
    displayIcon: string;
  };
}

// Draft text state is put in a separate context to avoid unnecessary re-renders on every keypress
interface ReportDiscussionDraftContextState {
  // Draft text from <CommentEditorFrame>
  commentEditorFrameCommentBody: string;
  // Draft text from <CommentThreadFrame>
  // Saved as a map of {[threadRefId]: 'unsaved reply'} so we can change threads without losing reply state
  commentThreadFrameReplyDraftMap: {[key: string]: string};
  setCommentEditorFrameCommentBody(body: string): void;
  setCommentThreadFrameReplyDraftMap(newDraftMap: {
    [key: string]: string;
  }): void;
}

export const ReportDiscussionDraftContext =
  React.createContext<ReportDiscussionDraftContextState>({
    commentEditorFrameCommentBody: '',
    commentThreadFrameReplyDraftMap: {},
    setCommentEditorFrameCommentBody: () => {},
    setCommentThreadFrameReplyDraftMap: () => {},
  });

export type CommentableItem = LayedOutPanelWithRef;
export type CommentableRef = PanelRef;

export const ReportDiscussionContext =
  React.createContext<ReportDiscussionContextState>({
    reportViewRef: null,
    reportServerID: null,
    viewer: undefined,
    loadingDiscussionThreads: false,
    loadingCreateComment: false,
    discussionThreadRefs: [],
    teamMembers: [],
    reportPanels: [],
    highlightIds: [],
    commentFrame: undefined,
    activeThreadRef: undefined,
    allActiveThreadRefs: [],
    autofocus: true,
    panelCommentsEnabled: false,
    getPanelData: () => ({
      displayIcon: 'panel-line-plot',
      displayText: 'Panel',
    }),
  });

interface ReportDiscussionContextUpdaters {
  setCommentFrame: React.Dispatch<
    React.SetStateAction<'editor' | 'thread' | undefined>
  >;
  createComment(
    mutationVars: Omit<CreateViewDiscussionCommentMutationVariables, 'viewID'>,
    discussionThreadRef?: DiscussionThreadRef
  ): void;
  deleteComment(params: {
    discussionCommentServerID: string;
    discussionCommentRef: DiscussionCommentRef;
    discussionThreadRef: DiscussionThreadRef;
    deleteThread: boolean;
  }): void;
  setActiveThread(
    threadRef?: DiscussionThreadRef,
    setFocus?: boolean,
    allActiveThreadRefs?: DiscussionThreadRef[]
  ): void;
  setAutofocus(af: boolean): void;
  appendPanelMentionToComment(panelId: string): void;
  replacePanelMentionTokens(
    commentBody: string,
    panelMentionOnClick?: (panelId?: string) => void
  ): React.ReactNodeArray;
}

export const ReportDiscussionUpdaterContext =
  React.createContext<ReportDiscussionContextUpdaters>({
    createComment: () => {},
    deleteComment: () => {},
    setCommentFrame: () => {},
    setActiveThread: () => {},
    setAutofocus: () => {},
    appendPanelMentionToComment: () => {},
    replacePanelMentionTokens: () => [<></>],
  });

const USER_MENTION_REGEXP = /@\[(.*?]\(.*?)\)/g;
const PANEL_MENTION_REGEXP = /&\[(.*?]\(.*?)\)/g;

export const ReportDiscussionContextProvider: React.FC<{
  reportViewRef: ReportViewRef;
  reportServerID: string;
}> = makeComp(
  ({reportViewRef, reportServerID, children}) => {
    // Type of comment frame that's currently visible, either 'editor' (new thread/comment) or 'thread' (viewing/replying to existing thread)
    const [commentFrame, setCommentFrame] = React.useState<
      'editor' | 'thread' | undefined
    >(undefined);

    // Draft text content of the <CommentEditorFrame>
    const [commentEditorFrameCommentBody, setCommentEditorFrameCommentBody] =
      React.useState('');

    // Draft text from the <CommentThreadFrame>
    // Saved as a map of {[threadRefId]: 'unsaved reply'} so we can change threads without losing reply state
    const [
      commentThreadFrameReplyDraftMap,
      setCommentThreadFrameReplyDraftMap,
    ] = React.useState<{[key: string]: string}>({});

    // Draft text state is put in a different context to avoid unnecessary re-renders on every keypress
    const draftState = useMemo<ReportDiscussionDraftContextState>(
      () => ({
        commentEditorFrameCommentBody,
        commentThreadFrameReplyDraftMap,
        setCommentEditorFrameCommentBody,
        setCommentThreadFrameReplyDraftMap,
      }),
      [commentEditorFrameCommentBody, commentThreadFrameReplyDraftMap]
    );

    // allActiveThreadRefs is the set of threads available in the CommentThreadFrame.
    // defaults to all threads, but if you're viewing a single panel's threads,
    // allActiveThreadRefs will be the subset of threads which reference that panel
    const [allActiveThreadRefs, setAllActiveThreadRefs] = React.useState<
      DiscussionThreadRef[]
    >([]);

    // activeThreadRef is the single currently-visible thread in the CommentThreadFrame
    const [activeThreadRef, setActiveThreadRef] = React.useState<
      DiscussionThreadRef | undefined
    >(undefined);

    const [autofocus, setAutofocus] = React.useState(true); // if true, it'll autofocus the reply textarea in CommentThreadFrame

    const {
      loadingDiscussionThreads,
      discussionThreadRefs,
      loadingCreateComment,
      viewer,
      teamMembers,
      reportPanels,
      createComment,
      deleteComment,
      panelCommentsEnabled,
    } = useReportDiscussion({
      reportServerID,
      reportViewRef,
    });

    // Takes an array of panel mention tokens, returns the corresponding panel IDs
    const tokensToIds = React.useCallback(
      (panelTokens?: RegExpMatchArray | null) => {
        if (panelTokens == null) {
          return [];
        }
        const ids: string[] = [];
        panelTokens.forEach(token => {
          const panelIdMatch = token.match(/\((.*)\)/);
          if (panelIdMatch != null) {
            const panelId = panelIdMatch[1];
            const panel = reportPanels.find(p => p.__id__ === panelId);
            if (panel != null) {
              ids.push(panel.__id__);
            }
          }
        });
        return ids;
      },
      [reportPanels]
    );

    // highlightIds is the list of currently highlighted panels (yellow border)
    const [highlightIds, setHighlightIds] = React.useState<string[]>([]);

    // Finds all panels mentioned in the active thread or in the current comment draft.
    // This is used to add the highlight border to those panels.
    const activeThread = useWholeMaybe(activeThreadRef);
    React.useEffect(() => {
      let newHighlightIds: string[] = [];
      if (commentFrame === 'editor') {
        // Find panel mentions in the current comment draft
        const editorPanelTokens =
          commentEditorFrameCommentBody.match(PANEL_MENTION_REGEXP);
        newHighlightIds = newHighlightIds.concat(
          tokensToIds(editorPanelTokens)
        );
      } else if (commentFrame === 'thread') {
        // Find panel mentions in the current reply draft
        const activeReplyDraft =
          activeThreadRef != null
            ? commentThreadFrameReplyDraftMap[activeThreadRef.id]
            : undefined;
        if (activeReplyDraft != null) {
          const replyPanelTokens = activeReplyDraft.match(PANEL_MENTION_REGEXP);
          newHighlightIds = newHighlightIds.concat(
            tokensToIds(replyPanelTokens)
          );
        }
        // Find panel mentions in all comments in the current thread
        if (activeThread != null) {
          activeThread.comments.forEach(c => {
            const threadPanelTokens = c.body.match(PANEL_MENTION_REGEXP);
            newHighlightIds = newHighlightIds.concat(
              tokensToIds(threadPanelTokens)
            );
          });
        }
      }
      setHighlightIds(prevHighlightIds => {
        return isEqual(prevHighlightIds, newHighlightIds.sort())
          ? prevHighlightIds
          : newHighlightIds.sort();
      });
    }, [
      activeThread,
      activeThreadRef,
      commentFrame,
      commentEditorFrameCommentBody,
      commentThreadFrameReplyDraftMap,
      tokensToIds,
    ]);

    // Lookup panel by the persistent id (i.e., panel.__id__, not panel.ref.id)
    // Also returns text and icon strings for displaying in comment mentions
    const getPanelData = React.useCallback(
      (panelId: string, fallbackDisplayText?: string) => {
        const commentableItem = reportPanels.find(p => p.__id__ === panelId);
        const defaultData = {
          panel: commentableItem,
          displayText: 'Panel',
          displayIcon: 'panel-line-plot',
        };
        // TODO: display this state in the UI? (e.g. mark outdated?, strikethrough panel reference?)
        if (commentableItem == null || !('viewType' in commentableItem)) {
          return defaultData;
        }
        const panelSpec = getPanelSpec(
          (commentableItem as LayedOutPanel).viewType
        );
        let displayText =
          panelSpec.getTitleFromConfig?.(commentableItem.config as any) ??
          fallbackDisplayText;
        if (displayText == null || displayText?.trim() === '') {
          displayText = panelSpec.type ?? 'Panel';
        }
        const displayIcon = panelSpec.icon ?? 'panel-line-plot';
        return {
          panel: commentableItem,
          displayText,
          displayIcon,
        };
      },
      [reportPanels]
    );

    const state = useMemo<ReportDiscussionContextState>(
      () => ({
        reportServerID,
        reportViewRef,
        viewer,
        loadingDiscussionThreads,
        loadingCreateComment,
        discussionThreadRefs,
        teamMembers,
        reportPanels,
        highlightIds,
        commentFrame,
        activeThreadRef,
        allActiveThreadRefs,
        autofocus,
        panelCommentsEnabled,
        getPanelData,
      }),
      [
        reportServerID,
        reportViewRef,
        viewer,
        loadingDiscussionThreads,
        loadingCreateComment,
        discussionThreadRefs,
        teamMembers,
        reportPanels,
        highlightIds,
        commentFrame,
        activeThreadRef,
        allActiveThreadRefs,
        autofocus,
        panelCommentsEnabled,
        getPanelData,
      ]
    );

    const setActiveThread = React.useCallback(
      (
        threadRef?: DiscussionThreadRef,
        setFocus?: boolean, // if true, it'll autofocus the reply textarea
        allActiveRefs?: DiscussionThreadRef[] // use this to filter the set of threads to a subset of all threads (e.g. viewing discussion threads for a panel)
      ) => {
        setAllActiveThreadRefs(allActiveRefs || discussionThreadRefs);
        setActiveThreadRef(threadRef);
        setCommentFrame(threadRef == null ? undefined : 'thread');
        setAutofocus(!!setFocus);
      },
      [discussionThreadRefs]
    );

    const appendPanelMentionToComment = React.useCallback(
      (panelId: string) => {
        // Get the panel data from redux
        const panel = reportPanels.find(p => {
          return p.__id__ === panelId;
        });
        if (panel != null) {
          const {displayText} = getPanelData(panelId);
          const panelMentionToken = `&[${displayText}](${panelId}) `;
          // Append the panel mention token to the comment body
          // Note: we're using setState callbacks here to get access to the current state
          // Without it, commentFrame (and other state variables) will be stale
          setCommentFrame(prevCommentFrame => {
            if (prevCommentFrame == null || prevCommentFrame === 'editor') {
              // Creating a new comment/thread with <CommentEditorFrame>
              setCommentEditorFrameCommentBody(prevCommentBody => {
                return prevCommentBody === ''
                  ? panelMentionToken
                  : `${prevCommentBody} ${panelMentionToken}`;
              });
            } else {
              // Replying to an existing thread (prevCommentFrame === 'thread') with <CommentThreadFrame>
              setActiveThreadRef(prevActiveThreadRef => {
                if (prevActiveThreadRef != null) {
                  setCommentThreadFrameReplyDraftMap(prevReplyDraftMap => {
                    const prevReplyDraft =
                      prevReplyDraftMap[prevActiveThreadRef.id] || '';
                    const newReplyDraft = {
                      ...prevReplyDraftMap,
                      [prevActiveThreadRef.id]:
                        prevReplyDraft === ''
                          ? panelMentionToken
                          : `${prevReplyDraft} ${panelMentionToken}`,
                    };
                    return newReplyDraft;
                  });
                }
                return prevActiveThreadRef;
              });
            }
            return prevCommentFrame == null ? 'editor' : prevCommentFrame;
          });
        }
      },
      [getPanelData, reportPanels]
    );

    // Takes a comment body string, replaces @user and &panel mention tokens with clickable links
    const replacePanelMentionTokens = React.useCallback(
      (
        commentBody: string,
        panelMentionOnClick?: (panelId?: string) => void
      ) => {
        // Replace @[name](username) with a profile link
        let renderComment = reactStringReplace(
          commentBody,
          USER_MENTION_REGEXP,
          (match, i) => {
            const [name, username] = match.split('](');
            return panelMentionOnClick == null ? (
              name
            ) : (
              <Link key={match + i} to={profilePage(username)}>
                {name}
              </Link>
            );
          }
        );

        // Replace &[panelDisplay](panelId) with an anchor link to the panel
        renderComment = reactStringReplace(
          renderComment,
          PANEL_MENTION_REGEXP,
          (match, i) => {
            const [commentPanelDisplay, panelId] = match.split('](');
            // Look up the current display text using panelId.
            // commentPanelDisplay is the panel title embedded in the comment text,
            // which might be stale (e.g., the panel has been renamed) so we only use it as a fallback
            const {panel, displayText, displayIcon} = getPanelData(
              panelId,
              commentPanelDisplay
            );
            return (
              <div
                key={match + i}
                className="comment-mention"
                onClick={() => {
                  if (panel != null && panelMentionOnClick != null) {
                    panelMentionOnClick(panelId);
                  }
                }}>
                <LegacyWBIcon name={displayIcon} />
                {displayText}
              </div>
            );
          }
        );
        return renderComment;
      },
      [getPanelData]
    );

    const updaters = useMemo<ReportDiscussionContextUpdaters>(
      () => ({
        createComment,
        deleteComment,
        setCommentFrame,
        setActiveThread,
        setAutofocus,
        appendPanelMentionToComment,
        replacePanelMentionTokens,
      }),
      [
        appendPanelMentionToComment,
        createComment,
        deleteComment,
        replacePanelMentionTokens,
        setActiveThread,
      ]
    );

    return (
      <ReportDiscussionContext.Provider value={state}>
        <ReportDiscussionDraftContext.Provider value={draftState}>
          <ReportDiscussionUpdaterContext.Provider value={updaters}>
            {children}
          </ReportDiscussionUpdaterContext.Provider>
        </ReportDiscussionDraftContext.Provider>
      </ReportDiscussionContext.Provider>
    );
  },
  {id: 'ReportDiscussionContextProvider', memo: true}
);
