import React, {useRef, useEffect, useState} from 'react';
import {useBoundingClientRect} from '../../util/hooks';
import {scrollIntoBounds} from '../../util/flash';
import {Portal, Ref, Popup} from 'semantic-ui-react';
import makeComp from '../../util/profiler';

interface PersistentFlasherProps {
  style?: React.CSSProperties;
  visible: boolean;
  trigger: JSX.Element;
  offsetX?: number;
  offsetY?: number;
  scrollIntoView?: boolean; // if true, the window will autoscroll to the trigger
  minSpaceFromTop?: number; // used if scrollIntoView is true
  minSpaceFromBottom?: number; // used if scrollIntoView is true
  popupContent?: string | JSX.Element;
}

// This adds a flashing blue dot to the trigger component
// Use it to draw the user's attention to a button, for example
export const PersistentFlasher: React.FC<PersistentFlasherProps> = makeComp(
  ({
    style = {},
    trigger,
    visible,
    offsetX = 0,
    offsetY = 0,
    scrollIntoView = false,
    minSpaceFromTop = 20,
    minSpaceFromBottom = 20,
    popupContent,
  }) => {
    const triggerRef = useRef<HTMLElement>(null);
    const [position, setPosition] = useState([0, 0]); // absolute position of the flasher, in percent window width/height
    const bcr = useBoundingClientRect(triggerRef, !visible);

    useEffect(() => {
      if (triggerRef.current != null && visible) {
        const rect = triggerRef.current.getBoundingClientRect();
        const scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const leftPx = rect.left + rect.width / 2 + offsetX + scrollLeft;
        const topPx = rect.top + rect.height / 2 + offsetY + scrollTop;
        setPosition([leftPx, topPx]);
        if (scrollIntoView) {
          scrollIntoBounds(rect, minSpaceFromTop, minSpaceFromBottom);
        }
      }
    }, [
      bcr,
      visible,
      offsetX,
      offsetY,
      minSpaceFromTop,
      minSpaceFromBottom,
      scrollIntoView,
    ]);

    const bluedot = (
      <div
        className="persistent-flasher"
        onClick={() => {
          if (triggerRef.current != null) {
            triggerRef.current.click();
          }
        }}
        style={{
          ...style,
          left: position[0],
          top: position[1],
        }}
      />
    );

    return (
      <Portal
        open={visible}
        trigger={<Ref innerRef={triggerRef}>{trigger}</Ref>}>
        {popupContent == null ? (
          bluedot
        ) : (
          <Popup
            trigger={bluedot}
            content={popupContent}
            position="bottom center"
            size="tiny"
            offset="0px, 10px"
          />
        )}
      </Portal>
    );
  },
  {id: 'PersistentFlasher'}
);
