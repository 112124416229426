import * as ParagraphStyles from './paragraphs.styles';
import styled, {css} from 'styled-components';
import {REPORT_FONT_SIZE} from '../constants';

export const UnorderedList = styled.ul`
  padding: 0;
  padding-bottom: 0.05px;
  margin-top: 0;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.6)}px;
`;

export const OrderedList = styled.ol`
  padding: 0;
  margin-top: 0;
  padding-bottom: 0.05px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.6)}px;
`;

export const ListItemWrapper = styled.div`
  position: relative;
`;

export const ListItem = styled.li<{checked?: boolean}>`
  font-family: Source Serif Pro;
  font-size: ${REPORT_FONT_SIZE}px;
  margin-left: 24px;
  padding-left: 4px;

  ${UnorderedList}, ${OrderedList} {
    margin-bottom: 0;
  }

  ${ParagraphStyles.Paragraph} {
    margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.2)}px !important;
  }

  ${props =>
    props.checked &&
    css`
      color: #999;
      text-decoration: line-through;
    `}
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
`;
