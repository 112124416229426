import React, {useState, useCallback, useRef} from 'react';
import {Icon, List} from 'semantic-ui-react';

import {fuzzyMatchHighlight} from '../util/fuzzyMatch';
import * as RunFeed from '../util/runfeed';
import * as Run from '../util/runs';
import {getIconNameForKey} from '../util/uihelpers';
import makeComp from '../util/profiler';
interface TableEditorColumnFieldProps {
  disabled: boolean;
  column: string;
  config: RunFeed.Config;
  dragging: boolean;
  searchQuery: string;
  onDragStart: any;
  onDragEnd: any;
  onPinClick: any;
  onClick: any;
  onDrop(columnAccessor: string): void;
}

const TableEditorColumnField: React.FC<TableEditorColumnFieldProps> = makeComp(
  ({
    disabled,
    column,
    config,
    dragging,
    searchQuery,
    onDragStart,
    onDragEnd,
    onPinClick,
    onClick,
    onDrop,
  }) => {
    // Count leave/enter events, because they fire in pairs when we drag over our
    // own children's boundaries.
    const [draggingOver, setDraggingOver] = useState(0);

    const selfRef = useRef<HTMLDivElement | null>(null);

    const onDragEnter = useCallback(() => {
      setDraggingOver(prev => prev + 1);
    }, []);
    const onDragLeave = useCallback((e: any) => {
      setDraggingOver(prev => prev - 1);
    }, []);

    const visible = config.columnVisible[column];
    const pinned = config.columnPinned[column];
    const key = Run.keyFromString(column)!;
    const iconClassName = 'wbic-ic-' + getIconNameForKey(key);
    return (
      <List.Item
        key={column}
        ref={selfRef}
        className={
          'column-field-wrapper' +
          (dragging ? ' dragging' : '') +
          (visible && draggingOver !== 0 ? ' drop' : '') +
          (disabled ? ' disabled' : '')
        }
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
        onDragOver={(e: React.SyntheticEvent) => {
          e.preventDefault(); // this is necessary for onDrop to work
        }}
        onDrop={() => {
          setDraggingOver(0);
          onDrop(column);
        }}>
        <div
          className="column-field"
          draggable={!disabled}
          onDragStart={onDragStart}
          onClick={onClick}>
          <Icon className={'section-icon ' + iconClassName} />
          <span className="column-name">
            {fuzzyMatchHighlight(Run.keyStringDisplayName(column), searchQuery)}
          </span>
          {visible && (
            <Icon
              name="pin"
              className={pinned ? 'active' : ''}
              onClick={(e: any) => {
                onPinClick();
                e.stopPropagation();
              }}
            />
          )}
        </div>
      </List.Item>
    );
  },
  {id: 'TableEditorColumnField', memo: true}
);

export default TableEditorColumnField;
