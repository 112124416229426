import * as globals from '../../css/globals.styles';

import React from 'react';
import NumberInput from '../elements/NumberInput';
import styled from 'styled-components';
import {PropertyEditorComponent} from './property-editors';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import makeComp from '../../util/profiler';

const MinimalNumberInput = styled(NumberInput)`
  input {
    border: none !important;
    padding: 4px 8px !important;
    margin: -4px -8px !important;
    line-height: 24px !important;
    &:hover {
      background: ${globals.gray100};
    }
  }
`;

const NumberPropertyEditor: PropertyEditorComponent<'number'> = makeComp(
  props => {
    const displayedVal =
      props.values.length === 1 ? props.values[0] : undefined;
    return (
      <InspectorPropertyWrapper>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        <MinimalNumberInput
          value={displayedVal}
          placeholder="Auto"
          onChange={val => {
            props.save(val);
          }}></MinimalNumberInput>
      </InspectorPropertyWrapper>
    );
  },
  {id: 'NumberPropertyEditor'}
);

export default NumberPropertyEditor;
