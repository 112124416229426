import * as S from './VegaPanelRename.styles';

import React, {FC} from 'react';
import {Button, Modal, Input} from 'semantic-ui-react';
import {useMutation, useQuery} from 'react-apollo';
import makeComp from '../../util/profiler';
import * as Generated from '../../generated/graphql';
import {CustomChartQueryData} from '../../graphql/customCharts';

interface VegaPanelRenameProps {
  displayName: string;
  id: string;
  onRename(newName: string): void;
}

const VegaPanelRename: FC<VegaPanelRenameProps> = makeComp(
  ({displayName, id, onRename}) => {
    const [nameValue, setNameValue] = React.useState(displayName);
    return (
      <S.Wrapper>
        <h2>Rename chart</h2>
        <Input
          placeholder="New name"
          value={nameValue}
          autoSelect
          autoFocus
          onChange={(e, {value}) => {
            setNameValue(value);
          }}></Input>
        <S.HelperText>
          The chart's ID, <code>{id}</code>, will be left unchanged.
        </S.HelperText>
        <Button
          primary
          onClick={() => {
            onRename(nameValue);
          }}>
          Rename preset
        </Button>
      </S.Wrapper>
    );
  },
  {id: 'Vega2.VegaPanelRename', memo: true}
);

interface VegaPanelRenameTriggerProps {
  chartId: string;
  children(props: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }): React.ReactNode;
}

export const VegaPanelRenameTrigger: React.FC<VegaPanelRenameTriggerProps> = ({
  chartId,
  children,
}) => {
  const [open, setOpen] = React.useState(false);

  const [updateCustomChart] = useMutation<
    Generated.UpdateCustomChartMutationResult,
    Generated.UpdateCustomChartMutationVariables
  >(Generated.UpdateCustomChartDocument);
  const chartQuery = useQuery<
    CustomChartQueryData,
    Generated.CustomChartQueryVariables
  >(Generated.CustomChartDocument, {
    variables: {id: chartId},
  });
  const entityName = chartQuery.data?.customChart.entity.name;
  const chartName = chartQuery.data?.customChart.name;
  const displayName = chartQuery.data?.customChart.displayName;
  const id = chartQuery.data?.customChart.id;
  return (
    <>
      {children({setOpen})}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        size="mini">
        <Modal.Content>
          {entityName && chartName && displayName && id && (
            <VegaPanelRename
              displayName={displayName}
              id={id}
              onRename={newName => {
                updateCustomChart({
                  variables: {
                    entity: entityName,
                    name: chartName,
                    displayName: newName,
                  },
                });
                setOpen(false);
              }}></VegaPanelRename>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default VegaPanelRename;
