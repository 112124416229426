import styled from 'styled-components';
import {WBSelect} from '@wandb/ui';

export const PageSizeSelectWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 4px;
`;

export const PageSizeSelect = styled(WBSelect)`
  margin-right: 6px;
  padding: 0;
`;
