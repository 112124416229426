import * as S from './CodeBlock.styles';

import React from 'react';
import {Highlight, Python} from './Code';

interface CodeBlockProps {
  title?: string;
  description?: React.ReactNode;
  code?: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({title, description, code}) => {
  return (
    <S.CodeBlockWrapper>
      {title && title.length > 0 && <S.TitleWrapper>{title}</S.TitleWrapper>}
      {description && (
        <S.DescriptionWrapper>{description}</S.DescriptionWrapper>
      )}
      {code && code.length > 0 && (
        <S.CodeWrapper>
          <Python>
            <Highlight>{code}</Highlight>
          </Python>
        </S.CodeWrapper>
      )}
    </S.CodeBlockWrapper>
  );
};

export default CodeBlock;
