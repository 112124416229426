import {backendHost} from '../../config';
import {BenchmarkRun} from '../../generated/graphql';
import {GALLERY_PATH_SEGMENT} from '../../routeData';
import {Subset} from '../../types/base';
import {BenchmarkProject, Project} from '../../types/graphql';

export * from './shared';

export type LoginParams = {
  signup?: boolean;
};

export function homeQuickStart() {
  return '/quickstart';
}

export function communityForum() {
  return 'https://community.wandb.ai';
}

export function benchmarkList() {
  return '/site/benchmarks';
}

export function signup() {
  return '/signup';
}

export function entity(entityName: string) {
  return `/${entityName}`;
}

export function storagePage(
  entityName: string,
  filePath?: string,
  tracked?: boolean,
  computeHour?: boolean
) {
  return `/usage/${entityName}${
    computeHour ? '/computehour' : tracked ? '/tracked' : ''
  }${filePath == null ? '' : '/' + filePath}`;
}

export function project(projectValue: Subset<Project, 'entityName' | 'name'>) {
  return `/${projectValue.entityName}/${projectValue.name}`;
}

export function projectObjTab(
  entityName: string,
  projectName: string,
  projObjType: string | undefined,
  projObjName: string | undefined,
  tab: string
) {
  return (
    `/${entityName}/${projectName}` +
    (projObjType != null ? `/${projObjType}/${projObjName}` : '') +
    `/${tab}`
  );
}

export function newBenchmarkPage(entityName: string) {
  return `/new-benchmark?entityName=${entityName}`;
}

export function newProjectPage(entityName: string) {
  return `/new-project?entityName=${entityName}`;
}

export function allProjects(entityName: string) {
  return `/${entityName}/projects`;
}

export function reportGallery(opts?: {tag?: string; sort?: string}) {
  const segments = [GALLERY_PATH_SEGMENT];
  if (opts?.sort) {
    segments.push(opts.sort);
  }
  if (opts?.tag) {
    segments.push(opts.tag);
  }
  return `/${segments.join('/')}`;
}

export function projectLinkedBenchmark(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/linkedbenchmark';
}

export function createProject(entityName: string) {
  return `/new-project?entityName=${entityName}`;
}

export function profilePage(username: string) {
  return '/' + username;
}

export function teamPage(teamName: string) {
  return '/' + teamName;
}

export function orgPage(orgName: string) {
  return '/org/' + encodeURIComponent(orgName);
}

export function settings() {
  return '/settings';
}

export function subscriptions() {
  return '/subscriptions';
}

export function login(params?: LoginParams) {
  const serialized: Record<string, string> = {};
  if (params && params.signup) {
    serialized.signup = 'true';
  }
  return `${backendHost()}/oidc/login?${new URLSearchParams(
    serialized
  ).toString()}`;
}

export function logout() {
  return `${backendHost()}/oidc/logout`;
}

export function teamMembers(teamName: string) {
  return '/teams/' + teamName;
}

export function projectArtifacts(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/artifacts';
}

export function projectSweeps(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/sweeps';
}

export function projectRunQueue(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/run-queue';
}

export function run(r: {
  entityName: string;
  projectName: string;
  name: string;
}) {
  return `/${r.entityName}/${r.projectName}/runs/${r.name}`;
}

export function runArtifacts(r: {
  entityName: string;
  projectName: string;
  name: string;
}) {
  return `/${r.entityName}/${r.projectName}/runs/${r.name}/artifacts`;
}

export function artifactType(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}`;
}

export function newArtifactType(entityName: string, projectName: string) {
  return `/${entityName}/${projectName}/artifacts/new`;
}

export function newArtifact(
  entityName: string,
  projectName: string,
  artifactName: string
) {
  return `/${entityName}/${projectName}/artifacts/${artifactName}/new`;
}

export function artifactSequence(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}`;
}

export function artifact(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}/${r.artifactCommitHash}`;
}

// Probably switch this to url queries instead of hash
const artifactCompareHash = (
  artifactSequenceName: string,
  compareArtifactID?: string,
  compareArtifactSequence?: string
) => {
  if (compareArtifactID == null) {
    return '';
  }
  const compareString = `#${compareArtifactID}`;
  if (
    artifactSequenceName === compareArtifactSequence ||
    compareArtifactSequence == null
  ) {
    return compareString;
  }
  return `${compareString}$${compareArtifactSequence}`;
};

export function artifactTab(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  tabName: string;
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}/${r.artifactCommitHash}/${
    r.tabName
  }${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function runCodeFile(r: {
  entityName: string;
  projectName: string;
  runName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  path: string[];
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  const {path} = r;
  const pathString =
    path.length > 0 ? `/${path.map(p => encodeURIComponent(p)).join('/')}` : '';
  return `/${r.entityName}/${r.projectName}/runs/${
    r.runName
  }/code${pathString}${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactFile(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  path: string[];
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  const {path} = r;
  const pathString =
    path.length > 0 ? `/${path.map(p => encodeURIComponent(p)).join('/')}` : '';
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}/${
    r.artifactCommitHash
  }/files${pathString}${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactFileStringPath(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  pathString: string;
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}/${
    r.artifactCommitHash
  }/files/${r.pathString}${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactSubpage(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  tabName?: string;
  pathString?: string;
}) {
  const {tabName, pathString} = r;
  if (tabName != null && pathString != null) {
    return artifactFileStringPath({...r, pathString});
  } else if (tabName != null) {
    return artifactTab({...r, tabName});
  } else {
    return artifact(r);
  }
}

export function artifactSubpageCompare(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  tabName?: string;
  pathString?: string;
  compareArtifactID: string;
  compareArtifactSequence?: string;
}): string {
  const {tabName, pathString, compareArtifactID, compareArtifactSequence} = r;
  if (tabName != null && pathString != null) {
    return artifactFileStringPath({
      ...r,
      pathString,
      compareArtifactID,
      compareArtifactSequence,
    });
  } else if (tabName != null) {
    return artifactTab({
      ...r,
      tabName,
      compareArtifactID,
      compareArtifactSequence,
    });
  } else {
    return artifact(r);
  }
}

export function artifactsPage(r: {
  entityName: string;
  projectName: string;
  artifactTypeName?: string;
  artifactSequenceName?: string;
  artifactCommitHash?: string;
  tabName?: string;
  pathString?: string;
}) {
  const {artifactTypeName, artifactSequenceName, artifactCommitHash} = r;
  if (
    artifactTypeName != null &&
    artifactSequenceName != null &&
    artifactCommitHash != null
  ) {
    return artifactSubpage({
      ...r,
      artifactTypeName,
      artifactSequenceName,
      artifactCommitHash,
    });
  }
  if (artifactTypeName != null && artifactSequenceName != null) {
    return artifactSequence({...r, artifactTypeName, artifactSequenceName});
  }
  if (artifactTypeName != null) {
    return artifactType({...r, artifactTypeName});
  }
  const {entityName} = r;
  return projectArtifacts({name: r.projectName, entityName});
}

export function runTabFile(
  entityName: string,
  projectName?: string,
  runName?: string,
  path?: string[],
  disableHistoryHax?: boolean
): string {
  const pathString =
    path && path.length > 0
      ? `/${path.map(p => encodeURIComponent(p)).join('/')}`
      : '';
  const runTabFilePath = `/${entityName}/${projectName}/runs/${runName}/files${pathString}`;
  if (disableHistoryHax) {
    return runTabFilePath;
  }

  // The 'history' library specifically decodes '%25' into '%', while it leaves other
  // special characters in their encoded forms. When we try to decode URIs on top of history's
  // weird decoding, we run into errors because of the inconsistent special character decoding
  // done by history.
  // This is an incredibly dirty workaround that adds an extra '25' after '%25' codes,
  // so that when history decodes them, there will still be a 25 following the '%'.
  // i.e. we still have a '%25' to decode on our end.
  const runTabFilePathHax = runTabFilePath.replace(/%25/g, '%2525');
  return runTabFilePathHax;
}

export function runFile(
  r: {
    entityName: string;
    projectName: string;
    runName: string;
  },
  fileName: string
): string {
  return `${backendHost()}/files/${r.entityName}/${r.projectName}/${
    r.runName
  }/${fileName}`;
}

export function runDiffPatchFile(r: {
  entityName: string;
  projectName: string;
  runName: string;
}): string {
  return runFile(r, 'diff.patch');
}

export function tensorboardUrl(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return (
    backendHost() +
    `/service-redirect/${entityName}/${projectName}/${runName}/tensorboard`
  );
}

export function tensorboardTab(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/tensorboard`;
}

export function runBenchmarkTab(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/benchmark`;
}

export function runModelTab(
  entityName: string,
  projectName: string,
  runName: string,
  modelFile: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/model?fileName=${encodeURIComponent(
    modelFile
  )}`;
}

export function benchmarkRunOverview(br: BenchmarkRun): string {
  return `/${br.benchmark.entityName}/${br.benchmark.name}/runs/${br.run.name}/overview`;
}

export function benchmarkOriginalRunOverview(br: BenchmarkRun): string {
  if (!br.originalProject || !br.originalRun) {
    return '';
  }
  return `/${br.originalProject.entityName}/${br.originalProject.name}/runs/${br.originalRun.name}/overview`;
}

export function benchmarkProject(
  input: Subset<BenchmarkProject, 'entityName' | 'name'>
): string {
  return `/${input.entityName}/${input.name}/benchmark`;
}

export function benchmarkOriginalProject(br: BenchmarkRun): string {
  if (!br.originalProject) {
    return '';
  }
  return `/${br.originalProject.entityName}/${br.originalProject.name}`;
}

export function benchmarkMySubmissionsTab(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/mysubmissions`;
}

export function benchmarkDiscussionTab(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/discussion`;
}

export function benchmarkDiscussionThread(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
  threadID: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/discussion/${r.threadID}`;
}

interface SweepPathParams {
  entityName: string;
  projectName: string;
  sweepName: string;
}

export function sweep(s: SweepPathParams): string {
  return `/${s.entityName}/${s.projectName}/sweeps/${s.sweepName}`;
}

export function sweepOverview(s: SweepPathParams): string {
  return `${sweep(s)}/overview`;
}

export function sweepControls(s: SweepPathParams): string {
  return `${sweep(s)}/controls`;
}

export function runGroup(r: {
  entityName: string;
  projectName: string;
  name: string;
  tab?: string;
  filePath?: string[];
}): string {
  const pathSegments = [
    r.entityName,
    r.projectName,
    'groups',
    encodeURIComponent(r.name),
  ];
  if (r.tab) {
    pathSegments.push(r.tab);
  }
  if (r.tab === 'files' && r.filePath != null && r.filePath.length > 0) {
    pathSegments.push(...r.filePath.map(p => encodeURIComponent(p)));
  }
  return `/${pathSegments.join('/')}`;
}

export function reportList(r: {
  entityName: string;
  projectName: string;
}): string {
  return `/${r.entityName}/${r.projectName}/reportlist`;
}

export function setMetaDescription(description: string): void {
  let metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag == null) {
    metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.setAttribute('name', 'description');
    document.getElementsByTagName('head')[0].appendChild(metaDescriptionTag);
  }
  metaDescriptionTag.content = description;
}

export function userManagement(): string {
  return '/admin/users';
}
