import _ from 'lodash';
import React from 'react';
import {useMemo} from 'react';
import {VisualizationSpec} from 'react-vega';
import CustomPanelRenderer from '../Vega3/CustomPanelRenderer';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import {useColorNode} from './panellib/libcolors';

const inputType = {
  type: 'list' as const,
  objectType: {
    type: 'union' as const,
    members: [
      'none' as const,
      {
        type: 'dict' as const,
        objectType: {
          type: 'union' as const,
          members: ['none' as const, 'string' as const],
        },
      },
      {
        type: 'list' as const,
        maxLength: 10,
        objectType: {
          type: 'union' as const,
          members: ['none' as const, 'string' as const],
        },
      },
    ],
  },
};

type PanelMultiStringHistogramProps = Panel2.PanelProps<typeof inputType>;

/* eslint-disable no-template-curly-in-string */

const HISTO_SPEC: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  description: 'A simple histogram',
  data: {
    name: 'wandb',
  },
  transform: [
    {
      aggregate: [
        {
          op: 'count',
          as: 'value_count',
        },
      ],
      groupby: ['name', 'value'],
    },
    {
      window: [{op: 'row_number', as: 'value_rank'}],
      sort: [
        {
          field: 'value_count',
          order: 'descending',
        },
      ],
    },
    {filter: `datum.value_rank < 100`},
  ],
  title: '${string:title}',
  mark: {type: 'bar', tooltip: {content: 'data'}},
  encoding: {
    y: {
      type: 'nominal',
      field: 'value',
      axis: {
        title: null,
      },
    },
    color: {
      field: 'name',
      title: null,
    },
    x: {
      field: 'value_count',
      stack: null,
      type: 'quantitative',
    },
    opacity: {value: 0.7},
    order: {
      field: 'name',
    },
  },
};

const HISTO_SPEC_COLORED: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  description: 'A simple histogram',
  data: {
    name: 'wandb',
  },
  transform: [
    {
      aggregate: [
        {
          op: 'count',
          as: 'value_count',
        },
      ],
      groupby: ['name', 'value', 'color'],
    },
    {
      window: [{op: 'row_number', as: 'value_rank'}],
      sort: [
        {
          field: 'value_count',
          order: 'descending',
        },
      ],
    },
    {filter: `datum.value_rank < 100`},
  ],
  title: '${string:title}',
  mark: {type: 'bar', tooltip: {content: 'data'}},
  encoding: {
    y: {
      type: 'nominal',
      field: 'value',
      axis: {
        title: null,
      },
    },
    color: {
      type: 'nominal',
      field: 'color',
      legend: false as any,
      scale: {range: {field: 'color'}},
    },
    x: {
      field: 'value_count',
      stack: true,
      type: 'quantitative',
    },
    opacity: {value: 1},
    order: {
      field: 'name',
    },
  },
};

const PanelMultiStringHistogram: React.FC<PanelMultiStringHistogramProps> =
  props => {
    const colorNode = useColorNode(props.input.path);
    const colorNodeValue = CGReact.useNodeValue(colorNode);
    const isColorable = colorNode.nodeType !== 'void';

    const nodeValueQuery = CGReact.useNodeValue(props.input.path);
    const data = useMemo(() => {
      const rows = nodeValueQuery.result;
      if (nodeValueQuery.loading || (isColorable && colorNodeValue.loading)) {
        return [];
      }
      const result: Array<{
        name: string;
        value: string | null;
        color?: string;
      }> = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row == null) {
          // pass
        } else if (_.isArray(row)) {
          row.forEach((item, ndx) => {
            if (item != null) {
              if (!isColorable) {
                result.push({name: '' + ndx, value: item});
              } else {
                result.push({
                  name: colorNodeValue.result[i][ndx] ?? '#94aecb',
                  value: item,
                  color: colorNodeValue.result[i][ndx] ?? '#94aecb',
                });
              }
            }
          });
        } else {
          for (const [key, value] of Object.entries(row)) {
            result.push({name: key, value});
          }
        }
      }
      return result;
    }, [
      nodeValueQuery.loading,
      nodeValueQuery.result,
      isColorable,
      colorNodeValue,
    ]);
    if (data.length === 0) {
      return <>-</>;
    }
    return (
      <div style={{width: 400, maxHeight: 200}}>
        <CustomPanelRenderer
          spec={isColorable ? HISTO_SPEC_COLORED : HISTO_SPEC}
          loading={false}
          slow={false}
          data={data}
          userSettings={{fieldSettings: {}, stringSettings: {title: ''}}}
        />
      </div>
    );
  };

export const Spec: Panel2.PanelSpec = {
  id: 'multi-string-histogram',
  Component: PanelMultiStringHistogram,
  inputType,
};
