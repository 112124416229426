import React from 'react';

import * as Filter from '../../../util/filters';
import {FilterKeySelector} from '../../Filters/FilterKeySelector';
import {FilterValueSelector} from '../../Filters/FilterValueSelector';

import {
  DFTableActionFilter,
  DFTableActionFilterButton,
  DFTableActionFilterPicker,
} from './DFTableActionsFilter';
import makeComp from '../../../util/profiler';

interface DataFrameFiltersPickerProps {
  filters: Filter.RootFilter;
  filterableKeys: string[];
  disabled?: boolean;
  setFilters(sort: Filter.RootFilter): void;
}

export const DataFrameFiltersTableAction = makeComp(
  (props: DataFrameFiltersPickerProps) => {
    return (
      <DFTableActionFilter
        trigger={open => (
          <DFTableActionFilterButton
            filtersOpen={open}
            filters={props.filters}
          />
        )}
        content={
          <DFTableActionFilterPicker
            filters={props.filters}
            setFilters={props.setFilters}
            filterKeySelector={keySelProps => (
              <FilterKeySelector
                {...keySelProps}
                loading={false}
                keys={props.filterableKeys}
              />
            )}
            // TODO: suggestions
            filterValueSelector={valSelProps => (
              <FilterValueSelector
                {...valSelProps}
                loading={false}
                suggestions={[]}
              />
            )}
          />
        }
      />
    );
  },
  {id: 'DataFrameFiltersTableAction'}
);
