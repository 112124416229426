import {BenchmarkRun} from '../../generated/graphql';
import {Subset} from '../../types/base';
import {BenchmarkProject, WithSummary} from '../../types/graphql';
import * as Benchmark from '../../util/benchmark';
import {LookupKey} from '../../util/runs';
import catz from './BenchmarkCatz';
import colorizer from './BenchmarkColorizer';
import drought from './BenchmarkDrought';
import mnist from './BenchmarkFashionMnist';
import kmnist from './BenchmarkKmnist';
import superres from './BenchmarkSuperres';
import test from './BenchmarkTest';
import userCreated from './BenchmarkUserCreated';
import witness from './BenchmarkWitness';
import github from './GitHub';
import safelife from './BenchmarkSafelife';

export interface Theme {
  calculatedResults?: boolean;
  calculatedResultsHeader?: string;
  keys: LookupKey[];
  benchmarkLink: string;
  submissionGuidelines?: string | ((projectName: string) => string);
  overview: (approvedRuns: Array<WithSummary<BenchmarkRun>>) => JSX.Element;
  leaderboard?: (approvedRuns: Array<WithSummary<BenchmarkRun>>) => JSX.Element;
  // Hack: this disables linking existing projects or creating projects with
  // a name other than the default
  noJoinProjectNameChange?: boolean;

  codeRequired?: boolean;
  embargo?: string; // allow users to hide their code

  // These are the user managed fields that are set for user created
  // benchmarks. They map to fields above (in BenchmarkUserCreated).
  benchmarkFields?: Benchmark.BenchmarkFields;
}

export const checkTheme = (
  benchmark: Subset<BenchmarkProject, 'name' | 'entityName'>
): Theme | null => {
  if (
    benchmark.name === 'codenet' ||
    benchmark.name === 'codesearchnet' ||
    benchmark.name === 'codesearch-test'
  ) {
    return github;
  } else if (benchmark.name === '__benchmark-test') {
    return test;
  }

  if (benchmark.entityName === 'wandb') {
    if (benchmark.name === 'fashion-mnist-benchmark') {
      return mnist;
    } else if (benchmark.name === 'colorizer-applied-dl') {
      return colorizer;
    } else if (benchmark.name === 'catz') {
      return catz;
    } else if (benchmark.name === 'droughtwatch') {
      return drought;
    } else if (benchmark.name === 'kmnist') {
      return kmnist;
    } else if (benchmark.name === 'benchmark-test') {
      return test;
    } else if (benchmark.name === 'gh-test-benchmark') {
      return test;
    } else if (benchmark.name === 'codesearch-benchmark') {
      return test;
    } else if (benchmark.name === 'witness') {
      return witness;
    } else if (benchmark.name === 'superres') {
      return superres;
    }
  }

  if (
    benchmark.entityName === 'safelife' &&
    (benchmark.name === 'test_stacey' || benchmark.name === 'v1dot2')
  ) {
    return safelife;
  }

  if (benchmark.views != null) {
    try {
      const json = JSON.parse(benchmark.views);
      const fields = Benchmark.fromJSON(json);
      return userCreated(fields);
    } catch (e) {
      console.warn("Coudn't parse benchmark views ", benchmark.views);
    }
  }
  return null;
};

export const getTheme = (benchmark: BenchmarkProject): Theme => {
  const theme = checkTheme(benchmark);
  if (theme == null) {
    throw new Error(`No match for Benchmark: ${benchmark.name}`);
  }
  return theme;
};
