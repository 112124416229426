import React from 'react';
import {useCallback} from 'react';
import Loader from './WandbLoader';
import * as Urls from '../util/urls';
import FileBrowser from './FileBrowser';
import {useRunFilesQuery} from '../generated/graphql';
import {UseLoadFile, UseLoadFileUrl} from './FileBrowser';
import {useLoadFile} from '../util/requests';
import {MAX_NUM_FILES} from '../util/constants';
import {useSingleFileQuery} from '../state/graphql/singleFileQuery';
import {File} from '../state/graphql/runFilesQuery';
import makeComp from '../util/profiler';

interface RunFilesProps {
  totalFiles: number;
  isCodeHidden?: boolean;
  entityName: string;
  projectName: string;
  runName: string;
  path?: string;
  history: any;
  groupName?: string;
}

const RunFiles = makeComp(
  (props: RunFilesProps) => {
    const {entityName, projectName, runName, history, path, groupName} = props;
    const setFilePath = useCallback(
      (newPathArray: string[]) => {
        history.push(
          groupName
            ? Urls.runGroup({
                entityName,
                projectName,
                name: groupName,
                tab: 'files',
                filePath: newPathArray,
              })
            : Urls.runTabFile(entityName, projectName, runName, newPathArray)
        );
      },
      [entityName, projectName, runName, history, groupName]
    );
    const runFilesQuery = useRunFilesQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        entityName,
        projectName,
        runName,
        maxNumFiles: MAX_NUM_FILES,
      },
    });

    if (runFilesQuery.loading) {
      return <Loader />;
    }
    const files =
      runFilesQuery.data?.project?.run?.files?.edges.map(e => e.node as File) ??
      [];

    const useLoadRunFile: UseLoadFile = (file, options) => {
      return useLoadFile(
        {entityName, projectName, runName},
        file.name,
        options
      );
    };
    const useLoadRunFileUrl: UseLoadFileUrl = file => {
      return useSingleFileQuery({
        entityName,
        projectName,
        runName,
        fileName: file.name,
      });
    };
    return (
      <FileBrowser
        totalFiles={props.totalFiles}
        isCodeHidden={props.isCodeHidden}
        files={files}
        path={path}
        history={history}
        useLoadFile={useLoadRunFile}
        useLoadFileUrl={useLoadRunFileUrl}
        setFilePath={setFilePath}
      />
    );
  },
  {id: 'RunFiles'}
);

export default RunFiles;
