import * as S from './headings.styles';

import {
  RenderElementProps,
  useEditor,
  ReactEditor,
  useReadOnly,
} from 'slate-react';
import {Node, Element, Editor, Transforms, Point, Range} from 'slate';
import {StyledComponent} from 'styled-components';
import {useTyping} from '../TypingContext';
import React, {useCallback, useMemo} from 'react';
import {BlockWrapper} from './drag-drop';
import {getDeepLinkId} from '../util';

import * as _ from 'lodash';
import {
  MarkdownBlock,
  isMarkdownBlock,
  EditorWithMarkdownBlocks,
} from './markdown-blocks';
import {removeHashFromURL} from '../../../util/url';
import copyToClipboard from 'copy-to-clipboard';
import {toast} from '../../elements/Toast';

export interface Heading extends Element {
  type: 'heading';
  level: 1 | 2 | 3;
  collapsedChildren?: Node[];
}

export const isHeading = (node: Node): node is Heading =>
  node.type === 'heading';

export const EditorWithHeadings = {
  collapseHeading(editor: ReactEditor, element: Heading | MarkdownBlock): void {
    const path = ReactEditor.findPath(editor, element);
    const [parentNode, parentPath] = Editor.parent(editor, path);
    const siblings = parentNode.children;
    const startIndex = path[path.length - 1] + 1;

    const collapsedChildren: Node[] = [];

    let endIndex = null;
    for (let i = startIndex; i < siblings.length; i++) {
      const sibling = siblings[i];

      if (
        isMarkdownBlock(sibling) &&
        EditorWithMarkdownBlocks.getCollapsibleMarkdownHeading(
          sibling.content
        ) != null
      ) {
        break;
      }

      const headingLevel = isHeading(element) ? element.level : 1;
      if (isHeading(sibling) && sibling.level <= headingLevel) {
        break;
      }

      const isPersistentBlankLine =
        path.length === 1 && i === editor.children.length - 1;
      if (isPersistentBlankLine) {
        break;
      }

      collapsedChildren.push(_.cloneDeep(sibling));
      endIndex = i;
    }

    if (endIndex != null) {
      const startPath = parentPath.concat([startIndex]);
      const endPath = parentPath.concat([endIndex]);

      const startPoint = Editor.start(editor, startPath);
      const endPoint = Editor.end(editor, endPath);

      Transforms.removeNodes(editor, {
        at: {anchor: startPoint, focus: endPoint},
        voids: true,
        mode: 'highest',
        // required due to slate bug
        hanging: true,
      });
    }

    Transforms.setNodes(editor, {collapsedChildren}, {at: path});
  },

  uncollapseHeading(
    editor: ReactEditor,
    element: Heading | MarkdownBlock
  ): void {
    if (element.collapsedChildren == null) {
      throw new Error(
        'Attempted to uncollapsed heading without collapsed children'
      );
    }

    const path = ReactEditor.findPath(editor, element);
    const insertPath = [...path];
    insertPath[insertPath.length - 1]++;

    Transforms.insertNodes(editor, element.collapsedChildren, {
      at: insertPath,
    });
    Transforms.unsetNodes(editor, 'collapsedChildren', {at: path});
  },

  toggleCollapseHeading(
    editor: ReactEditor,
    element: Heading | MarkdownBlock
  ): void {
    if (element.collapsedChildren == null) {
      EditorWithHeadings.collapseHeading(editor, element);
    } else {
      EditorWithHeadings.uncollapseHeading(editor, element);
    }
  },
};

export const HeadingElement: React.FC<
  RenderElementProps & {
    element: Heading;
  }
> = ({attributes, element, children}) => {
  let Component: StyledComponent<'h2' | 'h3' | 'h4' | 'h5', any>;
  switch (element.level) {
    case 1:
      Component = S.HeadingOne;
      break;
    case 2:
      Component = S.HeadingTwo;
      break;
    case 3:
      Component = S.HeadingThree;
      break;
    default:
      Component = S.HeadingFour;
      break;
  }
  const {typing} = useTyping();
  const editor = useEditor();
  const readOnly = useReadOnly();

  const id = useMemo(() => getDeepLinkId(element), [element]);
  const compProps = typeof id === 'string' ? {id} : {};
  const onClick = useCallback(() => {
    const deepUrl = removeHashFromURL(window.location.href) + '#' + id;
    copyToClipboard(deepUrl);
    toast('Copied link');
  }, [id]);

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <Component {...compProps}>
        {readOnly && <S.CopyLinkIcon onClick={onClick} />}
        {!typing && (
          <S.HeadingCollapser
            $collapsed={element.collapsedChildren != null}
            contentEditable={false}
            onMouseDown={e => e.preventDefault()}
            onClick={() =>
              EditorWithHeadings.toggleCollapseHeading(editor, element)
            }
          />
        )}
        {children}
      </Component>
    </BlockWrapper>
  );
};

export const withHeadings = <T extends Editor>(editor: T) => {
  const {insertBreak, deleteBackward} = editor;

  editor.insertBreak = () => {
    const {selection} = editor;

    const headingEntry = Editor.above(editor, {
      match: n => isHeading(n),
    });

    if (selection != null && headingEntry != null) {
      const [, path] = headingEntry;

      // We never want a heading to be duplicated on enter; either add a paragraph above or below.

      if (
        Range.isCollapsed(selection) &&
        Point.equals(selection.anchor, Editor.start(editor, path))
      ) {
        Transforms.insertNodes(
          editor,
          {type: 'paragraph', children: [{text: ''}]},
          {at: path}
        );

        return;
      }

      Transforms.splitNodes(editor, {always: true});
      Transforms.setNodes(editor, {type: 'paragraph'});

      return;
    }

    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const headingEntry = Editor.above(editor, {
        match: n => isHeading(n),
      });

      if (headingEntry != null) {
        const [, path] = headingEntry;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          Editor.withoutNormalizing(editor, () => {
            Transforms.unsetNodes(editor, ['collapsedChildren', 'level'], {
              at: path,
            });
            Transforms.setNodes(editor, {type: 'paragraph'}, {at: path});
          });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

export const headingNodeToLatex = (node: Heading, inner: string) => {
  switch (node.level) {
    case 1:
      return `\\section{${inner}}\n`;
    case 2:
      return `\\subsection{${inner}\n`;
    default:
      return `\\subsubsection{${inner}\n`;
  }
};
