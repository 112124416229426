import {createAction} from 'typesafe-actions';
import {BoundingBox2D} from '../../types/media';

export const loadBoxData = createAction('@media/loadBoxData', action => {
  return (opts: {
    panelID: string;
    mediaKey: string;
    mediaID: string;
    boxData: BoundingBox2D[];
  }) => action(opts);
});

export const clearBoxData = createAction('@media/clearBoxData', action => {
  return (opts: {panelID: string; mediaKey: string; mediaID: string}) =>
    action(opts);
});
