import {createAction} from 'typesafe-actions';
import * as Types from './types';

// User Actions

export const set = createAction(
  '@view/panelSettings/set',
  action => (ref: Types.Ref, panelSettings: Types.PanelSettings) =>
    action({ref, panelSettings})
);

export const update = createAction(
  '@view/panelSettings/update',
  action =>
    (ref: Types.Ref, panelSettingsUpdate: Partial<Types.PanelSettings>) =>
      action({ref, panelSettingsUpdate})
);

export const setLocalAndWorkspacePanelSettings = createAction(
  '@view/panelSettings/setLocalAndWorkspacePanelSettings',
  action =>
    (
      ref: Types.Ref,
      workspaceRef: Types.Ref,
      localPanelSettings: Types.PanelSettings,
      workspacePanelSettings: Types.PanelSettings
    ) =>
      action({ref, workspaceRef, localPanelSettings, workspacePanelSettings})
);

export const updateLocalAndWorkspacePanelSettings = createAction(
  '@view/panelSettings/updateLocalAndWorkspacePanelSettings',
  action =>
    (
      ref: Types.Ref,
      workspaceRef: Types.Ref,
      localPanelSettingsUpdate: Partial<Types.PanelSettings>,
      workspacePanelSettingsUpdate: Partial<Types.PanelSettings>
    ) =>
      action({
        ref,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      })
);

export const setAllLocalAndWorkspacePanelSettings = createAction(
  '@view/panelSettings/setAllLocalAndWorkspacePanelSettings',
  action =>
    (
      refs: Types.Ref[],
      workspaceRef: Types.Ref,
      localPanelSettings: Types.PanelSettings[],
      workspacePanelSettings: Types.PanelSettings
    ) =>
      action({
        refs,
        workspaceRef,
        localPanelSettings,
        workspacePanelSettings,
      })
);

export const updateAllLocalAndWorkspacePanelSettings = createAction(
  '@view/panelSettings/updateAllLocalAndWorkspacePanelSettings',
  action =>
    (
      refs: Types.Ref[],
      workspaceRef: Types.Ref,
      localPanelSettingsUpdate: Partial<Types.PanelSettings>,
      workspacePanelSettingsUpdate: Partial<Types.PanelSettings>
    ) =>
      action({
        refs,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      })
);
