import * as S from './ReportTipsSidebar.styles';

import * as _ from 'lodash';
import React, {useCallback, useMemo, useState} from 'react';
import makeComp from '../util/profiler';
import * as UtilHooks from '../util/hooks';
import {WBIcon} from '@wandb/ui';
import reportSidebarGraphicNoteToSelf from '../assets/sidebar-report-graphic-note-to-self.svg';
import reportSidebarGraphicWorkLog from '../assets/sidebar-report-graphic-work-log.svg';
import reportSidebarGraphicNewFindings from '../assets/sidebar-report-graphic-new-findings.svg';
import reportSidebarCommands from '../assets/sidebar-report-commands.gif';
import reportSidebarPanelGridTips from '../assets/sidebar-report-panel-grid-tips.png';
import reportSidebarLocking from '../assets/sidebar-report-locking.png';
import {
  ReportMetadata,
  useGallerySpec,
  useReportMetadata,
} from '../util/gallery';
import {reportView} from '../util/urls';
import {TargetBlank} from '../util/links';

const TABS = ['Use Cases', 'Quick Tips'] as const;
type Tab = typeof TABS[number];

const FEATURED_CATEGORY_ID = 'ql7j6wvw7';

type CollapsibleSectionProps = {
  header: string;
  subheader?: string;
  initiallyExpanded?: boolean;
};

const CollapsibleSection: React.FC<CollapsibleSectionProps> = makeComp(
  ({header, subheader, initiallyExpanded, children}) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded ?? false);
    const toggleExpanded = useCallback(() => setIsExpanded(prev => !prev), []);

    return (
      <S.SidebarCollapsibleSection expanded={isExpanded}>
        <div>
          <WBIcon
            name={`chevron-${isExpanded ? 'expanded' : 'minimized'}`}
            onClick={toggleExpanded}
          />
          {header}
        </div>
        {subheader && <div>{subheader}</div>}
        {children}
      </S.SidebarCollapsibleSection>
    );
  },
  {
    id: 'CollapsibleSection',
    memo: true,
  }
);

type SidebarProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportTipsSidebar: React.FC<SidebarProps> = makeComp(
  ({open, setOpen}) => {
    // We need to adjust the sidebar height based on scroll position.
    // The sticky nav and report edit bars make sizing the sidebar tricky.
    const scrollPos = UtilHooks.useScrollPosition();
    const heightHax: boolean = useMemo(
      () => scrollPos > S.NAVBAR_HEIGHT,
      [scrollPos]
    );

    const closeSidebar = useCallback(() => setOpen(false), [setOpen]);

    const [activeTab, setActiveTab] = useState<Tab>('Use Cases');

    const examples = [
      {
        key: 'notes-to-self',
        image: reportSidebarGraphicNoteToSelf,
        label: 'Notes to self',
        description: 'Add a graph to reference later.',
        example: '/stacey/estuary/reports/Distributed-Training--Vmlldzo1MjEw',
      },
      {
        key: 'work-log',
        image: reportSidebarGraphicWorkLog,
        label: 'Work log',
        description: "Track what you've tried and plan next steps.",
        example: '/stacey/estuary/reports/Distributed-Training--Vmlldzo1MjEw',
      },
      {
        key: 'collaborate',
        image: reportSidebarGraphicNewFindings,
        label: 'Collaborate',
        description: 'Sharing findings with your colleagues.',
        example:
          '/stacey/saferlife/reports/Getting-Started-with-Safelife--VmlldzoyNjMwMjY',
      },
    ];

    const quickTips = [
      {
        key: 'sidebar-commands',
        header: 'Adding text, images, and more',
        subheader: `Type "/" to add text, panel grids, images, and more.`,
        image: reportSidebarCommands,
        expanded: true,
      },
      {
        key: 'sidebar-panel-grid-tips',
        header: 'Move, duplicate, or delete a panel grid',
        subheader:
          'To move a panel grid, grab the drag handle to the ' +
          'left of the panel. To duplicate or delete, click the ' +
          'drag handle to see options.',
        image: reportSidebarPanelGridTips,
      },
      {
        key: 'sidebar-locking',
        header: 'Sharing',
        subheader:
          'Want others to see your report? Click the lock ' +
          'icon beside your Projects name in the navbar ' +
          'to make it public.',
        image: reportSidebarLocking,
      },
    ];

    const {loading: galleryLoading, gallerySpec} = useGallerySpec();
    const sampleReportIDs: string[] = useMemo(
      () =>
        galleryLoading
          ? []
          : gallerySpec.reportIDsWithTagIDs
              .filter(r => {
                const reportTags = new Set(r.tagIDs);
                return (
                  reportTags.has(FEATURED_CATEGORY_ID) &&
                  r.language === 'English'
                );
              })
              .map(r => r.id),
      [galleryLoading, gallerySpec]
    );

    const {loading: reportMetadataLoading, reportMetadatas: reports} =
      useReportMetadata(sampleReportIDs.slice(0, 5));

    const renderReportTile = useCallback((r: ReportMetadata) => {
      const authors = _.compact(r.authors?.map(a => a.name) ?? []).join(`, `);
      const tags = _.compact(r.tags?.map(t => t.name) ?? []).join(`, `);
      const link =
        r.project != null
          ? reportView({
              entityName: r.project.entityName,
              projectName: r.project.name,
              reportID: r.id,
              reportName: r.displayName,
            })
          : '';

      return (
        <S.SidebarReportTile key={r.id} href={link}>
          <div>
            <p>{r.displayName}</p>
            <p>{authors}</p>
            <p>{tags}</p>
          </div>
          <img src={r.previewUrl ?? '/logo.png'} alt="report-thumbnail" />
        </S.SidebarReportTile>
      );
    }, []);

    return (
      <S.Sidebar
        scrollPos={heightHax ? undefined : scrollPos}
        heightHax={heightHax}
        open={open}>
        <S.SidebarSectionHeader main>
          <p>Getting started with Reports</p>
          <S.SidebarCollapseButton onClick={closeSidebar}>
            <WBIcon name="previous" />
          </S.SidebarCollapseButton>
        </S.SidebarSectionHeader>
        <S.SidebarTabs>
          {TABS.map(t => (
            <S.SidebarTab
              key={t}
              onClick={() => setActiveTab(t)}
              active={t === activeTab}>
              {t}
            </S.SidebarTab>
          ))}
        </S.SidebarTabs>
        <S.SidebarPage active={activeTab === 'Use Cases'}>
          <S.SidebarSectionHeader>
            <p>Examples</p>
          </S.SidebarSectionHeader>
          <S.SidebarSectionSubheader>
            You can use Reports however you like. Here are a few examples:
          </S.SidebarSectionSubheader>
          <S.SidebarExamples>
            {examples.map(e => (
              <S.SidebarExample key={e.key}>
                <img src={e.image} alt="report example" />
                <div>
                  <div>{e.label}</div>
                  <div>{e.description}</div>
                  <TargetBlank href={e.example}>See Example →</TargetBlank>
                </div>
              </S.SidebarExample>
            ))}
          </S.SidebarExamples>
          <S.SidebarSectionHeader alignItems="baseline">
            <p>Featured Reports</p>
            <TargetBlank href="/fc">Go to Fully Connected →</TargetBlank>
          </S.SidebarSectionHeader>
          <S.SidebarSectionSubheader>
            Check out how others are using reports:
          </S.SidebarSectionSubheader>
          {!reportMetadataLoading &&
            reports != null &&
            reports.map(renderReportTile)}
        </S.SidebarPage>
        <S.SidebarPage active={activeTab === 'Quick Tips'}>
          <S.SidebarSectionHeader>
            <p>Quick Tips</p>
          </S.SidebarSectionHeader>
          <S.SidebarSectionSubheader>
            Check out our{' '}
            <TargetBlank href="https://www.youtube.com/channel/UCBp3w4DCEC64FZr4k9ROxig">
              videos
            </TargetBlank>
            {' and '}
            <TargetBlank href="https://docs.wandb.ai">docs</TargetBlank> for a
            more in-depth look.
          </S.SidebarSectionSubheader>
          {quickTips.map(q => (
            <CollapsibleSection
              key={q.key}
              header={q.header}
              subheader={q.subheader}
              initiallyExpanded={q.expanded}>
              <img src={q.image} alt="quick-tip" />
            </CollapsibleSection>
          ))}
        </S.SidebarPage>
      </S.Sidebar>
    );
  },
  {id: 'ReportTipsSidebar', memo: true}
);

export default ReportTipsSidebar;
