import '../../css/ScalarChart.less';

import React from 'react';

import {Scalar} from '../../util/plotHelpers';
import * as Run from '../../util/runs';
import FancyLegend from '../../components/vis/FancyLegend';
import makeComp from '../../util/profiler';
import {getTextColor} from '../../util/colors';

interface ScalarChartProps {
  data: Scalar | null;
  height?: number;
  showLegend?: boolean;
  groupArea?: 'none' | 'minmax' | 'stddev' | 'stderr' | 'samples';
}

const ScalarChart = makeComp(
  (props: ScalarChartProps) => {
    return (
      <div className="scalar-values">
        {props.data && (
          <div className="scalar-values-inner">
            {props.data.key != null && props.showLegend && (
              <div
                className="scalar-legend"
                style={{
                  color: getTextColor(props.data.color),
                }}>
                <FancyLegend {...props.data.key} />
              </div>
            )}
            <div className="scalar-value">
              {Run.prettyNumber(props.data.value ?? 0)}
            </div>
            {props.groupArea === 'minmax' && props.data.range != null && (
              <div>
                ({Run.prettyNumber(props.data.range[0])},{' '}
                {Run.prettyNumber(props.data.range[1])})
              </div>
            )}
            {props.groupArea === 'stddev' && props.data.stddev != null && (
              <div>{Run.prettyNumber(props.data.stddev)}</div>
            )}
            {props.groupArea === 'stderr' && props.data.stderr != null && (
              <div>{Run.prettyNumber(props.data.stderr)}</div>
            )}
          </div>
        )}
      </div>
    );
  },
  {id: 'ScalarChart'}
);

export default ScalarChart;
