import '../../../util/integrations';
function recordWeavePanelEvent(
  action: string,
  payload?: {[key: string]: string}
) {
  const data: {[key: string]: any} = {};
  data.action = action;
  if (payload) {
    Object.assign(data, payload);
  }
  window.analytics.track('Weave Panel Event', data);
}

export function makeEventRecorder(panelID: string) {
  return (action: string, payload?: {[key: string]: string}) => {
    return recordWeavePanelEvent(panelID + '.' + action, payload);
  };
}
