import '../css/NewUserFooter.less';

import React from 'react';
import {signup, login} from '../util/urls';
import {TargetBlank} from '../util/links';
import {useViewer} from '../state/viewer/hooks';
import makeComp from '../util/profiler';
import {isInIframe} from '../setup';
import {urlPrefixed} from '../config';

interface NewUserFooterProps {
  whatFor?: string;
  skipMadeWith?: boolean;
}

const NewUserFooter: React.FC<NewUserFooterProps> = makeComp(
  (props: NewUserFooterProps) => {
    const viewer = useViewer();
    if (viewer) {
      return <></>;
    }
    const madeWith = props.skipMadeWith
      ? ''
      : 'Made with Weights &amp; Biases.';
    return (
      <div className="new-user-footer">
        <div className="divider" />
        <div className="cta">
          {madeWith}{' '}
          <TargetBlank
            href={urlPrefixed(signup())}
            target={isInIframe() ? '_blank' : '_self'}
            className="footer-link">
            Sign up
          </TargetBlank>{' '}
          or{' '}
          <TargetBlank
            href={login()}
            target={isInIframe() ? '_blank' : '_self'}
            className="footer-link">
            log in
          </TargetBlank>{' '}
          {props.whatFor}
        </div>
      </div>
    );
  },
  {id: 'NewUserFooter'}
);

export default NewUserFooter;
