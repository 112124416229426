import React, {FC, useState} from 'react';
import {Button, Dimmer, Loader, Segment, Table} from 'semantic-ui-react';
import {View} from '../../state/graphql/vegaPanelQuery';
import makeComp from '../../util/profiler';

interface VegaPanelLoadProps {
  vegaViews: View[];
  onDelete(viewId: string): Promise<any>;
  onLoad(viewId: string): void;
}

const VegaPanelLoad: FC<VegaPanelLoadProps> = makeComp(
  ({vegaViews, onDelete, onLoad}) => {
    const [deleting, setDeleting] = useState(false);

    return (
      <>
        {deleting && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <div>
          {vegaViews.length === 0 ? (
            <Segment>
              No custom Vega panels have been saved in this project yet.
            </Segment>
          ) : (
            <Table className="action-table" padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {vegaViews.map(vegaView => {
                  return (
                    <Table.Row
                      className="action-table__row"
                      key={vegaView.id}
                      onClick={() => onLoad(vegaView.id)}>
                      <Table.Cell className="action-table__row__desription-cell">
                        {vegaView.name}
                      </Table.Cell>
                      <Table.Cell className="action-table__row__desription-cell">
                        {vegaView.description}
                      </Table.Cell>
                      <Table.Cell collapsing singleLine>
                        <Button
                          className="action-table__row__load-button"
                          disabled={deleting}
                          size="tiny"
                          primary
                          content={<span>Load</span>}
                        />
                        <Button
                          disabled={deleting}
                          onClick={e => {
                            e.stopPropagation();
                            setDeleting(true);
                            onDelete(vegaView.id).then(() =>
                              setDeleting(false)
                            );
                          }}
                          negative
                          className="action-table__row__load-button"
                          icon="trash"
                          size="tiny"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
      </>
    );
  },
  {id: 'VegaPanelLoad', memo: true}
);

export default VegaPanelLoad;
