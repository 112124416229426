import {errorText} from '../css/globals.styles';

import styled, {css} from 'styled-components';
import {
  Input as SemanticInput,
  Modal as SemanticModal,
  Table as SemanticTable,
  Radio as SemanticRadio,
} from 'semantic-ui-react';

export const Modal = styled(SemanticModal)`
  max-width: 600px;
`;

export const Table = styled(SemanticTable)`
  margin: 250px;
  stackable: true;
  striped: false;
  border: null;
`;

export const TableTitleRow = styled(SemanticTable.Header)`
  font-size: 20px;
`;

export const TableHeaderCell = styled.div`
  width: 55%;
`;

export const Header = styled.div`
  padding-top: 20px;
  margin-left: 20px;
  font-size: 20px;
  color: gray;
`;

export const ModalText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
`;

export const WarningMessage = styled.div`
  color: ${errorText} !important;
`;

export const Radio = styled(SemanticRadio)`
  margin: 0px 15px;
`;

export const Input = styled(SemanticInput)`
  flex-grow: 1;
  margin: 16px;
`;

export const QueueNameSpan = styled.span<{expanded: boolean}>`
  cursor: pointer;
  font-size: 20px;
  transform: translateY(-3px);
  .wbic-ic-next {
    font-size: 22px;
    color: @gray500;

    transform: translateY(3px)
      ${props =>
        props.expanded &&
        css`
        rotate(90deg)
      `};
  }
`;

export const ClickableCell = styled(SemanticTable.Cell)`
  color: blue;
  cursor: pointer;
`;

export const SubTableDiv = styled.div`
  margin-left: 55px;
  max-width: 500px;
`;

export const SuperTableRow = styled.div`
  display: flex;
  margin: 0px;
  line-height: normal;
  color: rgba(0,0,0,0.8);
}
`;

export const NoQueuestext = styled.div`
  margin: 16px;
  font-size: 20px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  margin-left: 16px;
`;

export const ModalInputContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  align: right;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const ModalActions = styled.div`
  display: flex;
  jusify-content: space-between;
  margin: 0px 16px;
`;
