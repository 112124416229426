import classNames from 'classnames';
import {History} from 'history';
import React, {useCallback, useEffect, useState} from 'react';
import {CreateTeamModal} from '../components/CreateTeamModal';
import ExampleShowcase from '../components/ExampleShowcase';
import {UserDetails} from '../components/HomePage/graphql';
import ProjectsList from '../components/HomePage/ProjectsList';
import ResourcesList from '../components/HomePage/ResourcesList';
import TeamsList from '../components/HomePage/TeamsList';
import PageWithSidebar from '../components/PageWithSidebar';
import ReportsTable from '../components/ReportsTable';
import RunsTable from '../components/RunsTable';
import config from '../config';
import DeferredQueryData from '../containers/DeferredQueryData';
import {
  useUpdateEntityMutation,
  useViewerQuery,
  ViewerRunsQueryResult,
} from '../generated/graphql';
import makeComp from '../util/profiler';
import {
  ONBOARDING_RESOURCES_POPUP_FEATURE_KEY,
  TableRowsContextResult,
  useOnboardingContext,
} from '../util/userContext';
import * as S from './HomePageWithData.styles';
import * as StyledSection from './Section.styles';

interface HomePageWithDataProps {
  data: UserDetails;
  history: History;
  refetch: () => Promise<any>;
  runsQuery: ViewerRunsQueryResult;
  tableRowsContext: TableRowsContextResult;
}

const HomePageWithData: React.FC<HomePageWithDataProps> = makeComp(
  ({data, history, refetch, runsQuery, tableRowsContext}) => {
    const viewerQuery = useViewerQuery();
    const userEntity = viewerQuery.data?.viewer?.userEntity;
    const [updateEntity] = useUpdateEntityMutation();
    const [codeSavingEnabled, setCodeSavingEnabled] = useState(false);
    useEffect(() => {
      const enabled = userEntity?.codeSavingEnabled;
      if (enabled != null) {
        setCodeSavingEnabled(enabled);
      }
    }, [userEntity]);

    const {onboardingState, setOnboardingState} = useOnboardingContext(
      ONBOARDING_RESOURCES_POPUP_FEATURE_KEY
    );

    const {state: tableRowsByPage, setState: setTableRowsByPage} =
      tableRowsContext;
    const setTableRows = useCallback(
      (key: string, rows: number) => {
        setTableRowsByPage({
          ...tableRowsByPage,
          home: {
            ...tableRowsByPage.home,
            [key]: rows,
          },
        });
      },
      [tableRowsByPage, setTableRowsByPage]
    );

    const projects = data?.projects ?? {edges: []};
    const reports = data?.views ?? {edges: []};

    const firstRunLogged =
      (runsQuery.data?.viewer?.runs?.edges ?? []).length > 0;
    const showOnboarding = !runsQuery.loading && !firstRunLogged;
    const showOnboardingMoved = !runsQuery.loading && !showOnboarding;

    const exampleProjectSection = (
      <StyledSection.Section>
        <StyledSection.SectionHeader>
          Example project
        </StyledSection.SectionHeader>
        <StyledSection.SectionText>
          Try visualizing results in 2 minutes.
        </StyledSection.SectionText>
        <StyledSection.SectionButton
          primary
          href="http://wandb.me/intro"
          target="_blank"
          rel="noopener noreferrer">
          Try example
        </StyledSection.SectionButton>
      </StyledSection.Section>
    );

    const codeSavingSection = (
      <StyledSection.Section grow>
        <StyledSection.SectionHeader>
          Save snapshots of your code
        </StyledSection.SectionHeader>
        <StyledSection.SectionText>
          Capture code to reproduce models.
        </StyledSection.SectionText>
        <StyledSection.SectionCheckboxContainer>
          <StyledSection.SectionCheckbox
            toggle
            checked={codeSavingEnabled}
            onChange={async () => {
              if (userEntity == null) {
                return;
              }
              setCodeSavingEnabled(!codeSavingEnabled);
              await updateEntity({
                variables: {
                  entityName: userEntity.name,
                  codeSavingEnabled: !codeSavingEnabled,
                },
              });
              await viewerQuery.refetch();
            }}
          />
          Enable code saving
        </StyledSection.SectionCheckboxContainer>
      </StyledSection.Section>
    );

    const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
    const openCreateTeamModal = useCallback(
      () => setCreateTeamModalOpen(true),
      []
    );
    const closeCreateTeamModal = useCallback(
      () => setCreateTeamModalOpen(false),
      []
    );

    const defaultEntityName = data.defaultEntity?.name ?? data.username;

    const inviteTeamSection = (
      <StyledSection.Section grow>
        <StyledSection.SectionHeader>
          Invite your team
        </StyledSection.SectionHeader>
        <StyledSection.SectionText>
          Collaborate seamlessly on projects.
        </StyledSection.SectionText>
        <StyledSection.SectionButton onClick={openCreateTeamModal}>
          {(data.organizations ?? []).length > 0
            ? 'Create team'
            : 'Invite team'}
        </StyledSection.SectionButton>
        <CreateTeamModal
          open={createTeamModalOpen}
          onClose={closeCreateTeamModal}
          orgOptions={(data.organizations ?? []).map(o => ({
            text: o.name,
            value: o.id,
          }))}
          onCreate={teamName => history.push(`/${teamName}`)}
        />
      </StyledSection.Section>
    );

    const exampleShowcaseSection = (
      <StyledSection.Section grow>
        <StyledSection.SectionHeader>
          Try your own project
        </StyledSection.SectionHeader>
        <StyledSection.SectionText>
          Add a few lines of code to get a live dashboard of your model
          training, including logs and system metrics.
        </StyledSection.SectionText>
        <ExampleShowcase entityName={defaultEntityName} />
      </StyledSection.Section>
    );

    const hideSidebarInMobile =
      projects.edges.length === 0 &&
      (data?.teams.edges.length ?? 0) <= 1 &&
      showOnboarding;

    return (
      <PageWithSidebar className="home-page">
        <div
          className={classNames('sidebar', {
            'mobile-hide': hideSidebarInMobile,
          })}>
          <StyledSection.SidebarHeader
            as="h1"
            data-test="home-page-sidebar-header">
            Home
          </StyledSection.SidebarHeader>
          <ProjectsList
            name="Projects"
            data={projects}
            entityName={defaultEntityName}
          />
          {data.teams.edges.length > 0 && (
            <TeamsList
              name="Teams"
              data={data.teams}
              userQueryRefetch={refetch}
            />
          )}
          <ResourcesList
            showOnboardingAlert={
              showOnboardingMoved && !onboardingState.onboardingResourcesPopup
            }
            closeOnboardingAlert={() =>
              setOnboardingState({
                ...onboardingState,
                onboardingResourcesPopup: true,
              })
            }
          />
        </div>
        <div className="main-page">
          {showOnboarding && (
            <S.OnboardingContainer data-test="home-onboarding">
              <S.HomeHeader>
                <S.HomeHeaderMainText>
                  Get started with Weights & Biases
                </S.HomeHeaderMainText>
                <S.HomeHeaderSubText as="h2">
                  Run a machine learning experiment logging results to W&B and
                  see live results in your dashboard in 5 minutes.
                </S.HomeHeaderSubText>
              </S.HomeHeader>
              <StyledSection.SectionContainer>
                <StyledSection.SectionColumn>
                  {exampleProjectSection}
                  {codeSavingSection}
                  {!config.ENVIRONMENT_IS_PRIVATE && inviteTeamSection}
                </StyledSection.SectionColumn>
                <StyledSection.SectionColumn>
                  {exampleShowcaseSection}
                </StyledSection.SectionColumn>
              </StyledSection.SectionContainer>
              <StyledSection.MobileSectionContainer>
                {exampleProjectSection}
                {exampleShowcaseSection}
                {codeSavingSection}
                {!config.ENVIRONMENT_IS_PRIVATE && inviteTeamSection}
              </StyledSection.MobileSectionContainer>
            </S.OnboardingContainer>
          )}
          <div>
            <DeferredQueryData query={runsQuery}>
              {runsData => {
                const runs = runsData?.viewer?.runs ?? {
                  edges: [],
                };
                if (runs.edges.length === 0) {
                  return null;
                }
                return (
                  <RunsTable
                    name="Runs"
                    data={runs}
                    showUserColumn={false}
                    pageSize={tableRowsByPage.home?.runs}
                    onChangePageSize={(pageSize: number) =>
                      setTableRows('runs', pageSize)
                    }
                  />
                );
              }}
            </DeferredQueryData>
            {reports.edges.length > 0 && (
              <ReportsTable
                data={reports}
                pageSize={tableRowsByPage.home?.reports}
                onChangePageSize={(pageSize: number) =>
                  setTableRows('reports', pageSize)
                }
              />
            )}
          </div>
        </div>
      </PageWithSidebar>
    );
  },
  {id: 'HomePageWithData', memo: true}
);

export default HomePageWithData;
