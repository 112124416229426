import React, {useCallback} from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import {PLASMA_GRADIENT} from '../../util/colors';
import {rowsToYData, rgbStr} from './util';
import {legendFromRun} from '../../util/plotHelpers';
import * as SM from '../../util/selectionmanager';
import {GradientStop, gradientToRGBArray} from '../GradientPicker';
import {SelectionBoundsMenu} from '../SelectionBoundsMenu';
import * as ColorUtil from '../../util/colors';
import * as Run from '../../util/runs';
import makeComp from '../../util/profiler';
import * as ViewHooks from '../../state/views/hooks';
import * as InteractStateActions from '../../state/views/interactState/actions';
import * as InteractStateContext from '../../state/views/interactState/context';
import * as GroupSelectionsActionsInternal from '../../state/views/groupSelections/actionsInternal';
import * as FilterActions from '../../state/views/filter/actions';
import {RunWithRunsetInfo} from '../../state/runs/types';
import {Ref as RunSetRef} from '../../state/views/runSet/types';
import {RunColorConfig} from '../../util/section';
import {PCColumn, PCConfig} from '../PanelParallelCoord';
import {toast} from '../elements/Toast';
import {NULL_STRING_ANGLE_BRACKETS} from '../../util/constants';

// This file defines the d3 SVG plot for the Parallel Coordinates panel.
// Note: The main entry point for the Parallel Coordinates panel is in PanelParallelCoord.tsx

const COLOR_SCALE_WIDTH = 30;

interface PCPlotSVGProps {
  runs: RunWithRunsetInfo[];
  runSetRefs: RunSetRef[];
  customRunColors?: RunColorConfig;
  plotWidth: number;
  plotHeight: number;
  config: PCConfig;
  updateConfig: (newConfig: PCConfig) => void;
}

export const PCPlotSVG: React.FC<PCPlotSVGProps> = makeComp(
  props => {
    const plotDivRef = React.useRef<HTMLDivElement>(null);
    const columnCount = (props.config.columns || []).length;

    const svgParams = useRenderSVGParams(props);

    React.useEffect(() => {
      const plotDiv = plotDivRef.current;
      if (plotDiv != null && columnCount > 0) {
        renderSVG({...svgParams, node: plotDiv});
      }
    }, [columnCount, svgParams]);

    return <div className="fill" ref={plotDivRef} />;
  },
  {id: 'PCPlotSVG'}
);

function useRenderSVGParams(props: PCPlotSVGProps) {
  const {
    runs,
    runSetRefs,
    customRunColors,
    plotWidth,
    plotHeight,
    config,
    updateConfig,
  } = props;
  const {customGradient, gradientColor, legendFields, onlyShowSelectedLines} =
    config;
  const columns = config.columns || [];
  const rows = React.useMemo(
    () =>
      runs.map(run => {
        const row: {[key: string]: Run.Value} = {name: run.name};
        for (const col of columns || []) {
          if (col.accessor != null) {
            row[col.accessor] = Run.getValueFromKeyString(run, col.accessor);
          }
        }
        return row;
      }),
    [columns, runs]
  );

  const runSets = ViewHooks.useParts(runSetRefs);

  const groupSelectionsRefs = React.useMemo(() => {
    return runSets
      .filter(runSet => runSet.enabled)
      .map(runSet => runSet.groupSelectionsRef);
  }, [runSets]);
  const groupSelections = ViewHooks.useWholeArray(groupSelectionsRefs);

  const setGroupSelection = ViewHooks.useViewAction(
    runSets[0].groupSelectionsRef,
    GroupSelectionsActionsInternal.setGroupSelections
  );

  // The selected regions on the columns (created by brushCallback)
  const columnSelections = React.useMemo(() => {
    const result: SM.PanelSelections = {};
    for (const accessor of columns.map(c => c.accessor)) {
      if (accessor != null) {
        const key = Run.keyFromString(accessor);
        if (key) {
          result[accessor] = SM.getConstraintsForKey(groupSelections[0], key);
        }
      }
    }
    return result;
  }, [columns, groupSelections]);

  const convertSelectionsToFilters = ViewHooks.useViewAction(
    runSets[0].filtersRef,
    FilterActions.selectionsToFilters
  );

  const convertSelectionToFiltersCallback = useCallback(
    (axis: string) => {
      convertSelectionsToFilters(columnSelections, [axis]);
    },
    [convertSelectionsToFilters, columnSelections]
  );

  const clearSelection = useCallback(
    (axis: string) =>
      setGroupSelection(SM.clearSelections([axis], groupSelections[0])),
    [groupSelections, setGroupSelection]
  );

  // D3 brush event, called when selecting a region on a column
  const brushCallback = useCallback(
    (params: {
      axis: string;
      selectionConstraint: SM.PanelSelectionConstraint;
    }) => {
      const {axis, selectionConstraint} = params;
      const {low, high, match} = selectionConstraint;
      const key = Run.keyFromString(axis);
      if (key != null) {
        if (low == null && high == null && match == null) {
          clearSelection(axis);
        } else {
          const selectionWithoutKey = {
            ...groupSelections[0],
            selections: {
              ...groupSelections[0].selections,
              bounds: groupSelections[0].selections.bounds.filter(
                b => !Run.keysEqual(key, b.key)
              ),
            },
          };
          const selectedRuns = runs.filter(r => {
            const checkboxState = SM.getCheckedState(selectionWithoutKey, r, 1);
            return checkboxState === 'checked';
          });
          const emptySelection = !selectedRuns.some(r => {
            const val = Run.getValue(r, key);
            return (
              val != null &&
              (((low == null || val >= low) && (high == null || val <= high)) ||
                (typeof val === 'string' && match?.includes(val)))
            );
          });
          if (emptySelection) {
            // If no runs are selected, clear the selection
            toast('No runs selected');
            clearSelection(axis);
          } else {
            setGroupSelection(
              SM.setSelectionsToBounds(
                {[axis]: selectionConstraint} as SM.PanelSelections,
                groupSelections[0]
              )
            );
          }
        }
      }
    },
    [clearSelection, groupSelections, runs, setGroupSelection]
  );

  const setHighlight = InteractStateContext.useInteractStateAction(
    InteractStateActions.setHighlight
  );

  // mouseOver and mouseOut for plot lines
  const highlightCallback = useCallback(
    (run: RunWithRunsetInfo | null) => {
      if (run != null) {
        setHighlight('run:name', run.name);
      } else {
        setHighlight('run:name', undefined);
      }
    },
    [setHighlight]
  );

  // TODO: merge this with settings column reordering
  const columnDragendCallback = useCallback(
    (newColumnAccessorOrder: string[]) => {
      if (updateConfig) {
        updateConfig({
          columns: newColumnAccessorOrder.map(
            accessor => _.find(columns, {accessor}) || ({} as PCColumn)
          ),
        });
      }
    },
    [columns, updateConfig]
  );

  // These are all the params passed to renderSVG()
  // node is added when the function is called in useEffect
  const renderSVGParams: Omit<SVGParams, 'node'> = React.useMemo(
    () => ({
      // node: element,
      boxWidth: plotWidth,
      boxHeight: plotHeight,
      rows,
      columns,
      columnSelections,
      groupSelections,
      runs,
      customRunColors,
      customGradient: customGradient ?? [],
      gradientColor: gradientColor ?? true,
      legendFields: legendFields ?? ['run:displayName'],
      onlyShowSelectedLines: onlyShowSelectedLines ?? false,
      brushCallback,
      highlightCallback,
      dragendCallback: columnDragendCallback,
      clearSelection,
      convertSelectionToFilters: convertSelectionToFiltersCallback,
    }),
    [
      brushCallback,
      clearSelection,
      columnDragendCallback,
      columnSelections,
      columns,
      convertSelectionToFiltersCallback,
      customGradient,
      customRunColors,
      gradientColor,
      groupSelections,
      highlightCallback,
      legendFields,
      onlyShowSelectedLines,
      plotHeight,
      plotWidth,
      rows,
      runs,
    ]
  );
  return renderSVGParams;
}

// These are the params for renderSVG(), the function that draws the svg plot
interface SVGParams {
  node: HTMLDivElement;
  rows: Array<{[key: string]: Run.Value}>;
  columns: PCColumn[];
  columnSelections: SM.PanelSelections;
  groupSelections: SM.GroupSelectionState[];
  runs: RunWithRunsetInfo[];
  customRunColors?: RunColorConfig;
  gradientColor: boolean;
  customGradient: GradientStop[];
  legendFields: string[];
  boxWidth: number;
  boxHeight: number;
  onlyShowSelectedLines: boolean;
  brushCallback: (params: {
    axis: string;
    selectionConstraint: SM.PanelSelectionConstraint;
  }) => void;
  highlightCallback: (run: RunWithRunsetInfo | null) => void;
  dragendCallback: (newColumnAccessorOrder: string[]) => void;
  clearSelection: (axis: string) => void;
  convertSelectionToFilters: (axis: string) => void;
}

// Renders the d3 graph
function renderSVG(params: SVGParams) {
  const {
    node,
    rows,
    columns,
    columnSelections,
    groupSelections,
    runs,
    customRunColors,
    brushCallback,
    // mouseOverCallback,
    // mouseOutCallback,
    dragendCallback,
    highlightCallback,
    gradientColor,
    legendFields,
    customGradient,
    boxWidth,
    boxHeight,
    onlyShowSelectedLines,
    clearSelection,
    convertSelectionToFilters,
  } = params;
  let isBrushing = false;

  const margin = {top: 30, right: 30, bottom: 10, left: 30};
  const width = boxWidth - margin.left - margin.right;
  // The - 6 below corrects for an issue where the chart would grow
  // by 6 pixels on every render. I didn't root-cause, so this issue
  // will probably come back...
  const height = boxHeight - margin.top - margin.bottom - 6;

  const yScale = d3.scaleLinear().domain([0, 1]).range([height, 0]);

  let gradientColors = PLASMA_GRADIENT;

  if (customGradient.length > 0) {
    gradientColors = gradientToRGBArray(customGradient, 10);
  }

  const allColors = _.clone(gradientColors);

  // add some colours for disabled values, and desaturated versions of the extrema
  // for positive and negative infinity.
  allColors.push([180, 180, 180]);
  const grayIndex = allColors.length - 1;
  allColors.push([70.5, 84.5, 117.5]);
  const negInfColorIdx = allColors.length - 1;
  allColors.push([172.5, 169, 117.5]);
  const posInfColorIdx = allColors.length - 1;

  const runIndices = _.range(0, rows.length);

  const selectedRunIndices = runIndices.filter(runIdx => {
    const checkboxStates = groupSelections.map(groupSelection =>
      SM.getCheckedState(groupSelection, runs[runIdx], 1)
    );
    return checkboxStates.indexOf('checked') !== -1;
  });

  let visibleRunIndices = runIndices;

  if (onlyShowSelectedLines) {
    visibleRunIndices = selectedRunIndices;

    // LB: if nothing is visible things will get weird, so we select everything.  This could also
    // be confusing.
    if (visibleRunIndices.length === 0) {
      visibleRunIndices = runIndices;
    }
  }
  const visibleRows = visibleRunIndices.map(idx => rows[idx]);

  // mapping from visiblerunidx (passed into d3 object) to index in columnToDimension
  const runIdxToVisibleOnlyRunIndex: {[k: number]: number} = {};
  visibleRunIndices.forEach(
    (runIdx, i) => (runIdxToVisibleOnlyRunIndex[runIdx] = i)
  );

  const columnToDimension = rowsToYData(
    visibleRows,
    columns,
    yScale,
    gradientColors,
    grayIndex,
    posInfColorIdx,
    negInfColorIdx
  );
  let columnAccessors = Object.keys(columnToDimension);
  if (columnAccessors.length === 0) {
    return;
  }

  const lastAccessor = columnAccessors[columnAccessors.length - 1];
  const lastColumnDimension = columnToDimension[lastAccessor];

  // sets the x offsets of the axis
  const x = d3
    .scaleBand()
    .range([0, width])
    .domain(columnAccessors)
    .align(0.5)
    .paddingInner(0.9)
    .paddingOuter(0.1);

  const dragging: {[key: string]: number | undefined} = {};

  const extents: Array<Array<number | null>> = columnAccessors.map(() => [
    null,
    null,
  ]);

  const line = d3.line();

  const svg = d3
    .select(node)
    .html('')
    .append('svg')
    .attr('id', 'parallel-coordinates')
    .attr('class', 'parallel-coordinates-plot')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .style('width', width + margin.left + margin.right + 'px')
    .style('height', height + margin.top + margin.bottom + 'px')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  let gradientId = 'svgGradient';
  if (gradientColor) {
    if (customGradient.length > 0) {
      gradientId = customGradient.map(s => d3.rgb(s.color).r).join('');
    }
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '100%')
      .attr('y2', '0%');

    if (customGradient.length > 0) {
      customGradient.forEach(stop => {
        gradient
          .append('stop')
          .attr('offset', `${stop.offset}%`)
          .attr('stop-color', stop.color);
      });
    } else {
      // legacy gradient
      _(gradientColors).forEach((rgb, i) => {
        gradient
          .append('stop')
          .attr('offset', `${(100 * i) / (gradientColors.length - 1)}%`)
          .attr('stop-color', rgbStr(rgb));
      });
    }
  }

  // Add grey background lines for context.
  const background = svg
    .append('g')
    .attr('class', 'background')
    .selectAll('path')
    .data(visibleRunIndices)
    .enter()
    .append('path')
    .attr('stroke-width', 1)
    .attr('opacity', 0.2)
    .attr('d', path);

  const foreground = svg
    .append('g')
    .attr('class', 'foreground')
    .selectAll('path')
    .data(visibleRunIndices)
    .enter()
    .append('path')
    .attr('d', path)
    .attr('class', 'line')
    .attr('stroke-width', 1);

  const tooltip = d3
    .select(node)
    .append('div')
    .attr('class', 'hint')
    .style('z-index', '10')
    .style('position', 'absolute')
    .style('visibility', 'hidden');

  // Selection bounds menu (clear selection + convert selection to filters)
  let selectionBoundsMenuTimeout: ReturnType<typeof setTimeout>;
  const selectionBoundsMenu = d3
    .select(node)
    .append('div')
    .attr('class', 'selection-bounds-menu')
    .on('mouseover', () => {
      clearTimeout(selectionBoundsMenuTimeout);
    })
    .on('mouseleave', () => {
      selectionBoundsMenuTimeout = setTimeout(hideSelectionBoundsMenu, 1000);
    });

  const renderSelectionBoundsMenu = (axis: string) => {
    ReactDOM.render(
      <SelectionBoundsMenu
        clearSelections={() => clearSelection(axis)}
        convertSelectionsToFilters={() => convertSelectionToFilters(axis)}
      />,
      selectionBoundsMenu.node()
    );
  };

  const showSelectionBoundsMenu = (coords: {top: number; left: number}) => {
    selectionBoundsMenu.style('top', `${coords.top}px`);
    selectionBoundsMenu.style('left', `${coords.left}px`);
    selectionBoundsMenu.style('visibility', 'visible');
  };
  const hideSelectionBoundsMenu = () => {
    selectionBoundsMenu.style('visibility', 'hidden');
  };

  const colorScale = svg
    .append('rect')
    .attr('class', 'color-scale')
    .attr('fill', 'url(#' + gradientId + ')')
    .attr('width', String(COLOR_SCALE_WIDTH))
    .style('visibility', gradientColor ? 'visible' : 'hidden');

  function setColorScale() {
    colorScale
      // the 0.5 makes the left border coincide with the rightmost axis
      .attr('x', (x(lastAccessor) || 0) + 0.5)
      // the 0.5 centers the border in its pixels, lining it up with the axes and getting rid of the anti-aliasing
      .attr(
        'y',
        Math.min(
          lastColumnDimension.colorStartY,
          lastColumnDimension.colorEndY
        ) + 0.5
      )
      .attr(
        'height',
        Math.abs(
          lastColumnDimension.colorStartY - lastColumnDimension.colorEndY
        )
      );

    foreground.attr('stroke', runIdx => {
      const visRunIdx = runIdxToVisibleOnlyRunIndex[runIdx];

      // fix bug where colorIdx could be bigger than ALL_COLORS.length
      if (gradientColor) {
        let colorIdx = lastColumnDimension.colors[visRunIdx] ?? 0;
        if (colorIdx > allColors.length - 1) {
          colorIdx = allColors.length - 1;
        } else if (colorIdx < 0) {
          colorIdx = 0;
        }
        return rgbStr(allColors[colorIdx] ?? [0, 0, 0]);
      } else {
        return ColorUtil.runColor(runs[visRunIdx], [], customRunColors);
      }
    });
  }

  setColorScale();

  addHover();

  // Add a group element for each dimension.
  const g = svg
    .selectAll('.dimension')
    .data(columnAccessors)
    .enter()
    .append('g')
    .attr('class', 'dimension')
    .attr('transform', d => {
      return `translate(${x(d)})`;
    })
    .call(
      d3
        .drag()
        .subject((d: any) => ({x: x(d)}))
        .on('start', (d: any) => {
          dragging[d.toString()] = x(d.toString());
          background.attr('visibility', 'hidden');
        })
        .on('drag', (d: any) => {
          dragging[d] = Math.min(width, Math.max(0, d3.event.x));
          foreground.attr('d', path);
          setColorScale();
          columnAccessors = columnAccessors.sort(
            (a, b) => (position(a) || 0) - (position(b) || 0)
          );
          x.domain(columnAccessors);
          g.attr('transform', (d2: any) => `translate(${position(d2)})`);
        })
        .on('end', function (d: any) {
          delete dragging[d];
          transition(d3.select(this)).attr('transform', `translate(${x(d)})`);
          transition(foreground).attr('d', path);
          background
            .attr('d', path)
            .transition()
            .delay(500)
            .duration(0)
            .attr('visibility', null);
          addHover();
          dragendCallback(columnAccessors);
        }) as any
    );

  // Add an axis and title.
  g.append('g')
    .attr('class', 'axis')
    .each(function (d) {
      const axis = d3.axisLeft(columnToDimension[d].plotScale);
      d3.select(this).call(axis);
    })
    // text does not show up because previous line breaks somehow
    .append('text')
    .attr('class', 'axis-title')
    .style('text-anchor', 'middle')
    .attr('y', -15)
    .text(
      (d, i) =>
        columns[i].displayName ||
        Run.keyStringDisplayName(columns[i].accessor || '')
    );

  // Add and store a brush for each axis.
  // This is the grey rectangle that appears when you make a selection on the axis
  g.append('g')
    .attr('class', 'brush')
    .each(function (d, i) {
      const axisBrush = d3.brushY().extent([
        [-8, 0],
        [8, height],
      ]);
      columnToDimension[d].brush = axisBrush;
      const brushSelect = columnSelections[d];
      d3.select(this).call(axisBrush);
      if (brushSelect != null) {
        let topPx: number | undefined;
        let bottomPx: number | undefined;
        if (brushSelect.low != null && brushSelect.high != null) {
          // Numeric dimensions
          const inverted =
            columns.find(c => c.accessor === d)?.inverted ?? false;
          [topPx, bottomPx] = [brushSelect.low, brushSelect.high].map(
            columnToDimension[d].plotScale
          );
          if (inverted) {
            [bottomPx, topPx] = [topPx, bottomPx];
          }
        } else if (brushSelect.match != null) {
          // String dimensions - snap to nearest value
          const matchVals = brushSelect.match.map(val =>
            // convert null to '<null>' (val should match lookup in StringDimension.domain)
            val == null ? NULL_STRING_ANGLE_BRACKETS : val
          );
          [topPx, bottomPx] = [
            matchVals[0],
            matchVals[matchVals.length - 1],
          ].map(columnToDimension[d].plotScale);
          // Add some padding so the rectangle extends a bit above and below the range
          // (Especially important if only one value is selected)
          // This might be confusing if ticks are less than 10px apart on the axis
          // But if that's the case you probably have bigger problems
          if (topPx != null && bottomPx != null) {
            topPx = topPx + 10;
            bottomPx = bottomPx - 10;
          }
        }
        if (topPx != null && bottomPx != null) {
          const truncTopPx = Math.min(topPx, height);
          const truncBottomPx = Math.max(bottomPx, 0);
          extents[i] = [truncBottomPx, truncTopPx];
          axisBrush.move(d3.select(this), [truncBottomPx, truncTopPx]);
          d3.select(this)
            .on('mouseover', dim => {
              clearTimeout(selectionBoundsMenuTimeout);
              if (typeof dim === 'string') {
                renderSelectionBoundsMenu(dim);
                showSelectionBoundsMenu({
                  top: Math.floor(truncBottomPx) + 30,
                  left: Math.floor(x(dim) || 0) + 38,
                });
              }
            })
            .on('mouseleave', () => {
              selectionBoundsMenuTimeout = setTimeout(
                hideSelectionBoundsMenu,
                1000
              );
            });
        }
      }
      axisBrush
        .on('brush start', brushstart)
        .on('brush', brush)
        .on('end', brushend as any);
      d3.select(this).call(axisBrush);
    })
    .selectAll('rect')
    .attr('x', -8)
    .attr('width', 16);

  updateLinesForBrushes();

  // Add wider transparent lines as hover targets (reusable function)
  function addHover() {
    const selectedIndices = runIndices.filter(runIdx => {
      const checkboxStates = groupSelections.map(groupSelection =>
        SM.getCheckedState(groupSelection, runs[runIdx], 1)
      );
      return checkboxStates.indexOf('checked') !== -1;
    });

    // Remove any previously added lines
    svg.selectAll('g.hoverable').remove();
    // Add new lines based on latest data
    svg
      .append('g')
      .attr('class', 'hoverable')
      .attr('stroke-width', 8)
      .selectAll('path')
      .data(selectedIndices)
      .enter()
      .append('path')
      .attr('d', path)
      .on('mouseover', rowIndex => {
        handleHighlight(rowIndex);
        // mouseOverCallback(rowIndex);
      })
      .on('mouseout', () => {
        handleHighlight(null);
        // mouseOutCallback();
      });
  }

  function position(d: string) {
    const v = dragging[d];
    return v == null ? x(d) : v;
  }

  function transition(g2: any) {
    return g2.transition().duration(500);
  }

  // Returns the path for a given data point.
  function path(i: number) {
    const visRunIdx = runIdxToVisibleOnlyRunIndex[i];

    const vals = columnAccessors.map(
      d =>
        [
          (position(d) as number) + 0.5,
          (columnToDimension[d].plotVals[visRunIdx] as number) + 0.5,
        ] as [number, number]
    );
    const l = line.curve(d3.curveMonotoneX);
    return l(vals);
  }

  function brushstart() {
    isBrushing = true;
    // brushstart gets called on mousedown; reset the run selection
    // in case there was a previous brush that got destroyed
    brush();
  }

  function updateLinesForBrushes() {
    // makes the selected runs colorful and the non selected runs gray
    foreground.style('display', runIdx => {
      if (selectedRunIndices.indexOf(runIdx) !== -1) {
        return null;
      } else {
        return 'none';
      }
    });
  }

  // Handles a brush event, toggling the display of foreground lines.
  function brush() {
    if (d3.event && d3.event.target) {
      for (let i = 0; i < columnAccessors.length; ++i) {
        if (d3.event.target === columnToDimension[columnAccessors[i]].brush) {
          const selection = d3.event.selection;
          if (selection) {
            const [start, end] = selection;
            if (start === end) {
              extents[i] = [null, null];
            } else {
              extents[i] = [start, end];
            }
          } else {
            extents[i] = [null, null];
          }
        }
      }
    }

    updateLinesForBrushes();
  }

  function brushend(axis: string) {
    isBrushing = false;

    let selectionConstraint: SM.PanelSelectionConstraint = {};
    const selection = d3.event.selection;
    const dimension = columnToDimension[axis];
    if (selection != null) {
      const inverted =
        columns.find(c => c.accessor === axis)?.inverted ?? false;

      selectionConstraint = dimension.getSelectionConstraint(
        selection,
        inverted
      );
    }

    brushCallback({axis, selectionConstraint});
  }

  function handleHighlight(lineIndex: number | null) {
    if (isBrushing) {
      return;
    }

    if (lineIndex == null) {
      svg.selectAll('.hover').classed('hover', false);
      tooltip.style('visibility', 'hidden');

      highlightCallback(null);
    } else {
      // highlight the hovered line and dim the other lines
      svg
        .select('.foreground')
        .classed('hover', true)
        .select(`.line:nth-child(${runIdxToVisibleOnlyRunIndex[lineIndex] + 1}`)
        .classed('hover', true);

      const coords = d3.mouse(node);

      tooltip.style('visibility', 'visible');
      if (coords[0] < width / 2) {
        tooltip
          .style('top', coords[1] + 16 + 'px')
          .style('left', coords[0] + 12 + 'px')
          .style('right', '');
      } else {
        tooltip
          .style('top', coords[1] + 16 + 'px')
          .style('right', width - coords[0] - 4 + 'px')
          .style('left', '');
      }
      tooltip.html(
        legendFromRun(
          runs[lineIndex],
          _.uniq(_.concat(legendFields, columnAccessors))
        )
      );
      highlightCallback(runs[lineIndex]);
    }
  }

  return handleHighlight;
}
