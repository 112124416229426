import React from 'react';
import {UploadState} from '../../util/images';
import TextareaAutosize from 'react-textarea-autosize';
import makeComp from '../../util/profiler';

interface TextEditorProps {
  style?: any;
  value: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  uploadState?: UploadState;
  cursorIdx?: number;
  autoFocus?: boolean;
  focusText?: string;
  onClick?(): void;
  onBlur(): void;
  onChange(value: string): void;
  onPaste?(files: File[]): void;
  onSelect?(idx: number): void;
  onDeleteEmpty?(): void;
  onStopEditing?(): void;
}

const TextEditor: React.FC<TextEditorProps> = makeComp(
  ({
    value,
    onClick,
    onBlur,
    onChange,
    onDeleteEmpty,
    onStopEditing,
    uploadState,
    autoFocus,
    focusText,
    ...props
  }) => {
    const [origText, setOrigText] = React.useState<string | undefined>();
    const [newText, setNewText] = React.useState<string | undefined>();
    const [cursorIdx, setCursorIdx] = React.useState<number>(
      props.cursorIdx || value.length
    );
    const [textareaElement, setTextareaElement] =
      React.useState<HTMLTextAreaElement | null>(null);

    const textContent = newText || value;

    // Like Semantic UI's TextArea but allows us to get blur event
    const onBlurHandler = React.useCallback(() => onBlur && onBlur(), [onBlur]);
    const onChangeHandler = React.useCallback(
      (e: any) => onChange && onChange(e.target.value),
      [onChange]
    );
    const onPaste = (e: React.ClipboardEvent) => {
      if (e.clipboardData) {
        const items = e.clipboardData.items;
        if (items) {
          const files: File[] = [];
          // tslint:disable-next-line
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.indexOf('image') !== -1) {
              const file = item.getAsFile();
              if (file != null) {
                files.push(file);
              }
            }
          }
          if (files.length > 0) {
            e.preventDefault();
            if (props.onPaste) {
              props.onPaste(files);
            }
          }
        }
      }
    };

    React.useEffect(() => {
      if (uploadState) {
        const currentText = origText || value;
        if (uploadState.uploading) {
          setOrigText(currentText);
        }
        let title = uploadState.name;
        if (uploadState.error) {
          title = 'Error uploading: ' + title;
        } else if (uploadState.uploading) {
          title = 'Uploading... ' + title;
        }
        const text = `${currentText.substring(0, cursorIdx)} ![${title}](${
          uploadState.url || ''
        }) ${currentText.substring(cursorIdx)}`;
        setNewText(text);
        if (!uploadState.uploading) {
          onChange(text);
          if (textareaElement) {
            textareaElement.setSelectionRange(cursorIdx, cursorIdx);
          }
          setOrigText(undefined);
          setNewText(undefined);
        }
      }
      // We only want to set the newText when uploadState changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadState, textareaElement]);

    React.useEffect(() => {
      let selectionI = cursorIdx;
      if (textContent && focusText) {
        const i = textContent.lastIndexOf(focusText);
        if (i !== -1) {
          selectionI = i;
        }
        if (textareaElement) {
          textareaElement.setSelectionRange(
            selectionI + focusText.length,
            selectionI + focusText.length
          );
        }
      }
      // Only set current cursor position on newly mounted textarea's
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textareaElement]);

    React.useEffect(() => {
      if (textareaElement && autoFocus) {
        textareaElement.focus();
      }
    }, [textareaElement, autoFocus]);

    return (
      <TextareaAutosize
        className="text-editor"
        ref={node => setTextareaElement(node)}
        style={props.style}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyDown={e => {
          const key = e.keyCode || e.charCode;
          if (
            onDeleteEmpty &&
            (key === 8 || key === 46) &&
            textContent === ''
          ) {
            // delete/backspace on empty
            onDeleteEmpty();
          }
          if (onStopEditing && key === 27) {
            onStopEditing();
          }
        }}
        onSelect={() => {
          if (textareaElement) {
            // TODO: this prevents us from setting cursorIdx on initial mount
            if (textareaElement.selectionEnd !== textContent.length) {
              setCursorIdx(textareaElement.selectionEnd);
              if (props.onSelect) {
                props.onSelect(textareaElement.selectionEnd);
              }
            }
          }
        }}
        onPaste={onPaste}
        onClick={onClick}
        value={textContent}
      />
    );
  },
  {id: 'TextEditor'}
);
export default TextEditor;
