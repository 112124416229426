import * as React from 'react';
import {useCallback} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import * as RunFeed from '../../../util/runfeed';
import * as Run from '../../../util/runs';
import * as TableCols from './dataframesTablecols';
import {toast} from '../../elements/Toast';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import {DFTableRowInterface} from './DFTable';
import makeComp from '../../../util/profiler';

interface DFTableActionsAutoColsProps {
  tableSettings?: RunFeed.Config;
  rows: DFTableRowInterface[];

  setTableSettings(config: RunFeed.Config): void;
}

export const DFTableActionsAutoCols = makeComp(
  (props: DFTableActionsAutoColsProps) => {
    const {tableSettings, rows, setTableSettings} = props;

    const onClick = useCallback(() => {
      if (rows.length > 0 && tableSettings) {
        setTableSettings(
          TableCols.deriveAutoColumns(
            tableSettings,
            rows as unknown as Run.Run[]
          )
        );
        toast('Now showing recommended columns.');
      }
    }, [tableSettings, rows, setTableSettings]);

    return (
      <Popup
        content={'Automatically show the most useful columns.'}
        position="top right"
        trigger={
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            onClick={onClick}>
            <LegacyWBIcon name="wandb" />
          </Button>
        }
      />
    );
  },
  {id: 'DFTableActionsAutoCols'}
);
