import * as _ from 'lodash';
import gql from 'graphql-tag';
import {
  ArtifactDagQuery,
  ArtifactDagQueryVariables,
  ArtifactDagDocument,
  ArtifactDagRunFragmentFragment,
} from '../../generated/graphql';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ApolloQueryResult} from 'apollo-client';
import {useApolloClient} from '../hooks';

export const artifactDagUserFragment = gql`
  fragment ArtifactDagUserFragment on User {
    id
    name
  }
`;

export const artifactDagRunFragment = gql`
  fragment ArtifactDagRunFragment on Run {
    id
    runName: name
    displayName
    jobType
    project {
      id
      name
      entityName
    }
    inputArtifacts {
      edges {
        node {
          id
          artifactSequence {
            id
            name
          }
          commitHash
          versionIndex
          artifactType {
            id
            name
            project {
              id
              name
              entityName
            }
          }
        }
      }
    }
    outputArtifacts {
      edges {
        node {
          id
          artifactSequence {
            id
            name
          }
          commitHash
          versionIndex
          artifactType {
            id
            name
            project {
              id
              name
              entityName
            }
          }
        }
      }
    }
  }
`;

export const ARTIFACT_DAG_QUERY = gql`
  query ArtifactDAG(
    $projectName: String!
    $entityName: String!
    $artifactTypeName: String!
    $artifactName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      artifactType(name: $artifactTypeName) {
        id
        name
        description
        artifact(name: $artifactName) {
          id
          artifactSequence {
            id
            name
          }
          commitHash
          versionIndex
          createdBy {
            __typename
            ... on User {
              ...ArtifactDagUserFragment
            }
            ... on Run {
              ...ArtifactDagRunFragment
            }
          }
          usedBy {
            edges {
              node {
                ...ArtifactDagRunFragment
              }
            }
          }
        }
      }
    }
  }
  ${artifactDagUserFragment}
  ${artifactDagRunFragment}
`;

interface RunID {
  entityName: string;
  projectName: string;
  runName: string;
}

export type Run = RunID & {
  displayName: string;

  jobType: string;
};

interface ArtifactID {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
}

export type Artifact = ArtifactID & {
  versionIndex: number;
};

export enum Direction {
  TowardArtifact,
  AwayFromArtifact,
}

interface EdgeID {
  artifactID: string;
  runID: string;

  dir: Direction;
}

export type Edge = EdgeID & {
  runToArtifactEdgeName?: string;
  artifactToRunEdgeName?: string;
};

function artifactStringID(artifactID: ArtifactID) {
  return `${artifactID.entityName}/${artifactID.projectName}/${artifactID.artifactTypeName}/${artifactID.artifactSequenceName}:${artifactID.artifactCommitHash}`;
}

function runStringID(runID: RunID) {
  return `${runID.entityName}/${runID.projectName}/${runID.runName}`;
}

function edgeStringID(edge: Edge) {
  return `${edge.artifactID} ${
    edge.dir === Direction.TowardArtifact ? '<-' : '->'
  } ${edge.runID}`;
}

type GQLRun = ArtifactDagRunFragmentFragment;

interface GQLArtifact {
  id: string;
  artifactSequence: {
    id: string;
    name: string;
  };
  commitHash: string;
  versionIndex: number;
  artifactType: {
    id: string;
    name: string;
    project: {
      id: string;
      name?: string;
      entityName?: string;
    };
  };
}

export function useArtifactDagQueryRecursive(
  artifactID: ArtifactID,
  limit: number,
  artifactFilterFn?: (artifact: {
    entityName: string;
    projectName: string;
    artifactTypeName: string;
    artifactSequenceName: string;
    artifactCommitHash: string;
  }) => boolean
) {
  const [visitedArtifacts, setVisitedArtifacts] = useState<{
    [id: string]: Artifact;
  }>({});
  const [visitedRuns, setVisitedRuns] = useState<{[id: string]: Run}>({});
  const [visitedEdges, setVisitedEdges] = useState<{[id: string]: Edge}>({});

  // Start with one knownUnvisitedArtifact (the one passed in)
  const [knownUnvisitedArtifacts, setKnownUnvisitedArtifacts] = useState<{
    [id: string]: ArtifactID;
  }>({[artifactStringID(artifactID)]: artifactID});

  const runningQueryPromiseRef =
    useRef<Promise<ApolloQueryResult<ArtifactDagQuery>>>();

  const client = useApolloClient();

  const visitArtifact = useCallback(
    (run: Run, gqlArtifact: GQLArtifact, dir: Direction) => {
      if (
        gqlArtifact.commitHash == null ||
        gqlArtifact.versionIndex == null ||
        gqlArtifact.artifactType.project.name == null ||
        gqlArtifact.artifactType.project.entityName == null
      ) {
        // artifacts with null commit hashes / versions are not committed. null entity or
        // project doesn't happen.
        return;
      }
      const rid = runStringID(run);
      const artifact = {
        entityName: gqlArtifact.artifactType.project.entityName,
        projectName: gqlArtifact.artifactType.project.name,
        artifactTypeName: gqlArtifact.artifactType.name,
        artifactSequenceName: gqlArtifact.artifactSequence.name,
        artifactCommitHash: gqlArtifact.commitHash,
      };
      const aid = artifactStringID(artifact);
      const edgeID = {
        artifactID: aid,
        runID: rid,
        dir,
      };
      const eid = edgeStringID(edgeID);
      if (visitedEdges[eid] == null) {
        setVisitedEdges(ve => ({...ve, [eid]: edgeID}));
      }
      if (
        visitedArtifacts[aid] == null &&
        knownUnvisitedArtifacts[aid] == null &&
        (artifactFilterFn == null || artifactFilterFn(artifact))
      ) {
        // const edge = visitedEdges[eid];
        setKnownUnvisitedArtifacts(ka => ({
          ...ka,
          [aid]: artifact,
        }));
      }
    },
    [knownUnvisitedArtifacts, visitedArtifacts, visitedEdges, artifactFilterFn]
  );

  const visitRun = useCallback(
    (artifact: Artifact, gqlRun: GQLRun, dir: Direction) => {
      const aid = artifactStringID(artifact);
      const run = {
        entityName: gqlRun.project!.entityName!,
        projectName: gqlRun.project!.name!,
        runName: gqlRun.runName,
        displayName: gqlRun.displayName!,
        jobType: gqlRun.jobType!,
      };
      const rid = runStringID(run);
      const edgeID = {
        artifactID: aid,
        runID: rid,
        dir,
      };
      const eid = edgeStringID(edgeID);
      if (visitedEdges[eid] == null) {
        setVisitedEdges(ve => ({...ve, [eid]: edgeID}));
      }
      if (visitedRuns[rid] == null) {
        setVisitedRuns(vr => ({...vr, [rid]: run}));
        if (gqlRun.inputArtifacts != null) {
          for (const edge of gqlRun.inputArtifacts.edges) {
            const node = edge.node!;
            visitArtifact(run, node as GQLArtifact, Direction.AwayFromArtifact);
          }
        }
        if (gqlRun.outputArtifacts != null) {
          for (const edge of gqlRun.outputArtifacts.edges) {
            const node = edge.node!;
            visitArtifact(run, node as GQLArtifact, Direction.TowardArtifact);
          }
        }
      }
    },
    [visitArtifact, visitedEdges, visitedRuns]
  );

  useEffect(() => {
    const next = Object.values(knownUnvisitedArtifacts)[0];
    if (
      next != null &&
      runningQueryPromiseRef.current == null &&
      Object.keys(visitedArtifacts).length < limit
    ) {
      runningQueryPromiseRef.current = client.query<
        ArtifactDagQuery,
        ArtifactDagQueryVariables
      >({
        query: ArtifactDagDocument,
        variables: {
          entityName: next.entityName,
          projectName: next.projectName,
          artifactTypeName: next.artifactTypeName,
          artifactName: `${next.artifactSequenceName}:${next.artifactCommitHash}`,
        },
      });
      runningQueryPromiseRef.current.then(result => {
        if (result.data.project?.artifactType?.artifact != null) {
          const gqlArtifact = result.data.project.artifactType.artifact;
          const aid = artifactStringID(next);
          const artifact = {
            ...next,
            versionIndex: gqlArtifact.versionIndex!,
          };

          // Move to visited
          setVisitedArtifacts({...visitedArtifacts, [aid]: artifact});

          const createdRun =
            gqlArtifact.createdBy.__typename === 'Run'
              ? gqlArtifact.createdBy
              : null;
          if (createdRun != null) {
            visitRun(artifact, createdRun, Direction.TowardArtifact);
          }

          for (const edge of gqlArtifact.usedBy.edges) {
            const node = edge.node;
            visitRun(artifact, node!, Direction.AwayFromArtifact);
          }
        }

        // unset promise so our useEffect will be able to run the next query
        runningQueryPromiseRef.current = undefined;

        setKnownUnvisitedArtifacts(kua => {
          // Remove from unvisited
          const aid = artifactStringID(next);
          const {[aid]: deleted, ...knownWithDeletion} =
            knownUnvisitedArtifacts;
          return knownWithDeletion;
        });
      });
    }
  });

  const edges: {[id: string]: Edge} = {};
  _.forEach(visitedEdges, (edge, eid) => {
    if (
      visitedArtifacts[edge.artifactID] != null &&
      visitedRuns[edge.runID] != null
    ) {
      edges[eid] = edge;
    }
  });

  return {
    dag: {
      artifacts: visitedArtifacts,
      edges,
      runs: visitedRuns,
    },
    loading:
      Object.keys(visitedArtifacts).length < limit &&
      Object.keys(knownUnvisitedArtifacts).length !== 0,
    limited: Object.keys(visitedArtifacts).length >= limit,
  };
}

export type Dag = ReturnType<typeof useArtifactDagQueryRecursive>['dag'];
