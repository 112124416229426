import styled from 'styled-components';
import {
  LegendPosition,
  LegendOrientation,
  PlotFontSize,
} from '../../util/plotHelpers';

const flexDirectionByLegendPosition: {[K in LegendPosition]: string} = {
  north: 'column',
  south: 'column-reverse',
  east: 'row-reverse',
  west: 'row',
};

export const LinePlot = styled.div<{legendPosition: LegendPosition}>`
  &&&&& {
    display: flex;
    overflow: hidden;
    flex-grow: 1;

    flex-direction: ${({legendPosition}) =>
      flexDirectionByLegendPosition[legendPosition]};
  }
`;

const pxByLegendFontSize: {[K in PlotFontSize]: number} = {
  small: 12,
  medium: 16,
  large: 20,
};

function getLegendMaxHeightByFontSize(fontSize: PlotFontSize): number {
  const fontSizePx = pxByLegendFontSize[fontSize];
  return Math.round(fontSizePx * 4.33);
}

export const LinePlotLegend = styled.div<{
  fontSize: PlotFontSize;
  orientation: LegendOrientation;
}>`
  &&&&& {
    flex-shrink: 0;
    line-height: 110%;
    font-family: Inconsolata, monospace;
    color: #888 !important;
    display: flex;
    overflow-y: auto;
    word-wrap: break-word;

    // Hide scrollbars
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    font-size: ${({fontSize}) => pxByLegendFontSize[fontSize]}px;

    ${({fontSize, orientation}) =>
      orientation === 'horizontal'
        ? `
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
        max-height: ${getLegendMaxHeightByFontSize(fontSize)}px;
      `
        : `
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 30%;
      `}

    .span-runsetName {
      font-style: italic;
    }
  }
`;

export const LegendEntry = styled.span<{orientation: LegendOrientation}>`
  &&&&& {
    ${({orientation}) =>
      orientation === 'horizontal'
        ? `
        margin-right: 8px;
        margin-left: 8px;
        max-width: 100%;
      `
        : `
        margin-top: 4px;
        margin-bottom: 4px;
      `}
  }
`;

export const InvalidDataAlert = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
