import React, {useCallback, useContext, useState} from 'react';
import {useContactUsForComputeHoursMutation} from '../generated/graphql';
import {getCookieBool, setCookie, unsetCookie} from '../util/cookie';
import makeComp from '../util/profiler';
import * as S from './ComputeHourNudgeBar.styles';
import {
  GlobalNudgeBarContext,
  GlobalNudgeBarUpdaterContext,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';
import StandardPlanCheckoutModal from './StandardPlanCheckoutModal';
import {Redirect, useRouteMatch} from 'react-router';
import {subscriptions} from '../util/urls';
import gql from 'graphql-tag';
import {useQuery} from '../state/graphql/query';

const COMPUTE_HOUR_NUDGE_COOKIE_NAME = 'hide compute hour nudge';

const TEAM_ORG_QUERY = gql`
  query TeamOrg($entityName: String!) {
    entity(name: $entityName) {
      id
      organization {
        id
        name
      }
    }
  }
`;

interface TeamOrgQueryResult {
  entity: {
    id: string;
    organization?: {
      id: string;
      name: string;
    };
  };
}

interface TeamOrgQueryVariables {
  entityName: string;
}

const ComputeHourNudgeBar: React.FC = makeComp(
  () => {
    const {states} = useContext(GlobalNudgeBarContext);
    const {refetch} = useContext(GlobalNudgeBarUpdaterContext);
    const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

    const [contactUs] = useContactUsForComputeHoursMutation();
    const onContactUsClicked = useCallback(async () => {
      setCookie(COMPUTE_HOUR_NUDGE_COOKIE_NAME, 'true');

      try {
        await contactUs();
      } catch (err) {
        console.error(`Error contacting sales for compute hours: ${err}`);
        unsetCookie(COMPUTE_HOUR_NUDGE_COOKIE_NAME);
      }
    }, [contactUs]);

    const routeMatch = useRouteMatch();

    const onTeamPage = routeMatch.params.hasOwnProperty('entityName');
    const onTeamUsagePage = routeMatch.path.startsWith('/usage/');

    const teamOrgQuery = useQuery<TeamOrgQueryResult, TeamOrgQueryVariables>(
      TEAM_ORG_QUERY,
      {
        variables: {
          entityName: (routeMatch.params as TeamOrgQueryVariables).entityName,
        },
        skip: !onTeamPage || onTeamUsagePage,
      }
    );

    if (
      !onTeamPage ||
      onTeamUsagePage ||
      teamOrgQuery.loading ||
      !teamOrgQuery.entity.organization
    ) {
      return null;
    }

    const currentOrg = teamOrgQuery.entity.organization.name;

    if (states == null || states.length === 0) {
      return null;
    }

    const showBanner = states.some(
      state =>
        state?.organization?.name === currentOrg &&
        (state.renderedNudgeBar === 'standardComputeHours' ||
          state.renderedNudgeBar === 'advancedComputeHours')
    );

    if (!showBanner) {
      return null;
    }

    const aggressive = states.some(
      state => state?.organization?.name === currentOrg && state.aggressive
    );

    if (aggressive) {
      return <Redirect to={subscriptions()} />;
    }

    const alreadyContacted = getCookieBool(COMPUTE_HOUR_NUDGE_COOKIE_NAME);
    const contactSalesContent = !alreadyContacted ? (
      <NudgeBarLargeAction>
        <NudgeBarLargeActionLink onClick={onContactUsClicked}>
          Contact sales
        </NudgeBarLargeActionLink>
      </NudgeBarLargeAction>
    ) : (
      <NudgeBarLargeTitle>
        Our sales team will reach out over email to help you upgrade.
      </NudgeBarLargeTitle>
    );

    return (
      <>
        {states.map((state, i) => {
          const showUpgradeButton =
            state.renderedNudgeBar !== 'advancedComputeHours' &&
            !state.organization?.flags?.noContact;
          return (
            (state.renderedNudgeBar === 'advancedComputeHours' ||
              state.renderedNudgeBar === 'standardComputeHours') &&
            state.organization && (
              <NudgeBarLarge key={i}>
                <S.UpgradeMessage shiftVertically={showUpgradeButton}>
                  <NudgeBarLargeTitle>
                    Your organization {state.organization.name} has used up your{' '}
                    {state.renderedNudgeBar === 'standardComputeHours' &&
                      'free'}{' '}
                    {state.maxHours} compute hours.{' '}
                  </NudgeBarLargeTitle>
                  {showUpgradeButton ? (
                    <NudgeBarLargeAction>
                      <NudgeBarLargeActionLink
                        onClick={() => {
                          window.analytics.track(
                            'banner upgrade plan button clicked',
                            {
                              org: state.organization?.id,
                            }
                          );
                          setCheckoutModalOpen(true);
                        }}>
                        Upgrade
                      </NudgeBarLargeActionLink>
                    </NudgeBarLargeAction>
                  ) : (
                    contactSalesContent
                  )}
                </S.UpgradeMessage>

                {checkoutModalOpen && state.organization && (
                  <StandardPlanCheckoutModal
                    org={state.organization}
                    onTransactionCompleted={refetch}
                    onClose={() => setCheckoutModalOpen(false)}
                  />
                )}
              </NudgeBarLarge>
            )
          );
        })}
      </>
    );
  },
  {id: 'TrialNudgeBar', memo: true}
);

export default ComputeHourNudgeBar;
