import React from 'react';
import './DFTable.less';
import makeComp from '../../../util/profiler';

interface DFTableCellImageProps {
  imageUrl?: string;
}

export const DFTableCellImage: React.FC<DFTableCellImageProps> = makeComp(
  ({imageUrl}) => {
    return (
      <React.Fragment>
        {imageUrl ? (
          <div
            className="df-tree-cell--image"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}>
            &nbsp;
          </div>
        ) : (
          '-'
        )}
      </React.Fragment>
    );
  },
  {id: 'DFTableCellImage', memo: true}
);
