import React from 'react';
import {Link} from 'react-router-dom';

import LegacyWBIcon from '../elements/LegacyWBIcon';
import ImageIcon from '../ImageIcon';
import {FancyPageSidebarItem} from './FancyPageSidebar';
import FancyPopup from './FancyPopup';
import makeComp from '../../util/profiler';
import {TargetBlank} from '../../util/links';

interface FancyPageSidebarSectionProps {
  selectedItem: FancyPageSidebarItem;
  items: FancyPageSidebarItem[];
  baseUrl: string;
  defaultIndex?: number;
}

const FancyPageSidebarSection: React.FC<FancyPageSidebarSectionProps> =
  makeComp(
    props => {
      const [hoveredItem, setHoveredItem] =
        React.useState<FancyPageSidebarItem>(props.items[0]);
      const [showPopup, setShowPopup] = React.useState(false);
      const [popupY, setPopupY] = React.useState(0);
      return (
        <div
          className="fancy-page__sidebar__section"
          onMouseLeave={() => {
            setShowPopup(false);
          }}>
          <FancyPopup open={showPopup} y={popupY} text={hoveredItem.name} />
          {props.items.map(item => {
            const className =
              'fancy-page__sidebar__section__item' +
              (props.selectedItem === item
                ? ' fancy-page__sidebar__section__item--selected'
                : '') +
              (item.internalLink || item.externalLink ? ' external-link' : '');
            const onMouseEnter = (
              e: React.MouseEvent<HTMLElement, MouseEvent>
            ) => {
              setPopupY(e.currentTarget.offsetTop + 10);
              setHoveredItem(item);
              setShowPopup(true);
            };
            const icon = (
              <ImageIcon
                name={
                  item.selectedIconName && props.selectedItem.name === item.name
                    ? item.selectedIconName
                    : item.iconName
                }
              />
            );

            const baseLinkProps = {
              key: item.name,
              className,
              onClick: () => setShowPopup(false),
              onMouseEnter,
              'data-test': item.slug + '-tab',
            };
            if (item.internalLink) {
              const linkProps = {
                ...baseLinkProps,
                to: item.internalLink,
              };
              return (
                <Link {...linkProps}>
                  {icon}
                  <LegacyWBIcon name="launch" size="small" title="" />
                </Link>
              );
            } else if (item.externalLink) {
              const linkProps = {
                ...baseLinkProps,
                href: item.externalLink,
              };
              return (
                <TargetBlank {...linkProps}>
                  {icon}
                  <LegacyWBIcon name="launch" size="small" title="" />
                </TargetBlank>
              );
            } else {
              const linkProps = {
                ...baseLinkProps,
                to: {
                  pathname:
                    item === props.items[props.defaultIndex || 0]
                      ? props.baseUrl
                      : props.baseUrl + '/' + item.slug,
                  // Preserve query string. Gets updated because the component happens to re-render on hover.
                  search: window.location.search,
                },
              };
              return <Link {...linkProps}>{icon}</Link>;
            }
          })}
        </div>
      );
    },
    {id: 'FancyPageSidebarSection'}
  );

export default FancyPageSidebarSection;
