import styled from 'styled-components';

export const Gallery = styled.div<{setminwidth?: boolean}>`
  max-width: 1000px;
  display: block;
  margin: 0 auto;
  @media only screen and (min-width: 1000px) {
    min-width: 1000px;
  }
`;
