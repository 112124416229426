import * as S from './CreateTrialTeam.styles';

import React from 'react';

import makeComp from '../util/profiler';
import {slugFormat} from '../util/text';
import _ from 'lodash';
import {Redirect} from 'react-router';
import {
  useCreateUserLedTrialMutation,
  useCreateInviteMutation,
  useAvailableTeamQuery,
  OrgType,
} from '../generated/graphql';
import {
  propagateErrorsContext,
  doNotRetryContext,
  extractErrorMessageFromApolloError,
} from '../util/errors';
import {captureError} from '../util/integrations';
import config, {envIsLocal, envIsCloudOnprem} from '../config';
import {isValidEmail} from '@wandb/cg/browser/utils/string';
import {GraphQLError} from 'graphql';
import {useViewer} from '../state/viewer/hooks';

type TeamType = 'academic' | 'work';

enum CreateStep {
  ChooseTeamType,
  CreateAcademicTeam,
  CreateWorkTeam,
  InviteTeammates,
  Done,
}

const CreateTrialTeam = makeComp(
  () => {
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const [createUserLedTrialMutation] = useCreateUserLedTrialMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });
    const [createInviteMutation] = useCreateInviteMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
    const [createStep, setCreateStep] = React.useState(
      CreateStep.ChooseTeamType
    );
    const [selectedTeamType, setSelectedTeamType] =
      React.useState<TeamType | null>(null);
    const [orgName, setOrgName] = React.useState<string | null>(null);
    const updateOrgName = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => setOrgName(e.target.value),
      []
    );

    const [isEntityAvailable, setEntityAvailable] = React.useState(false);
    const [entityName, setEntityName] = React.useState<string | null>(null);
    const updateEntityName = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setEntityName(slugFormat(e.target.value));
      },
      []
    );
    useAvailableTeamQuery({
      onCompleted: data => {
        if (!data.entity?.available) {
          setEntityAvailable(false);
        } else {
          setEntityAvailable(true);
        }
      },
      variables: {
        teamName: entityName ?? '',
      },
    });

    const [selectedOrgSize, setSelectedOrgSize] = React.useState<number>(0);
    const [justification, setJustification] = React.useState<string | null>(
      null
    );

    const orgSizeOptions = React.useMemo((): Array<{
      key: number;
      text: string;
      value: number;
    }> => {
      if (selectedTeamType === 'academic') {
        return [
          {
            key: 5,
            text: '1-5',
            value: 0,
          },
          {
            key: 10,
            text: '5-10',
            value: 1,
          },
          {
            key: 20,
            text: '10-20',
            value: 2,
          },
          {
            key: 50,
            text: '20-50',
            value: 3,
          },
          {
            key: 100,
            text: '50-100',
            value: 4,
          },
          {
            key: 101,
            text: '100+',
            value: 5,
          },
        ];
      } else if (selectedTeamType === 'work') {
        return [
          {
            key: 10,
            text: '1-10',
            value: 0,
          },
          {
            key: 100,
            text: '10-100',
            value: 1,
          },
          {
            key: 101,
            text: '100+',
            value: 2,
          },
        ];
      } else {
        return [];
      }
    }, [selectedTeamType]);

    const onCreateAcademicTeamClicked = React.useCallback(() => {
      setSelectedTeamType('academic');
      setCreateStep(CreateStep.CreateAcademicTeam);
    }, []);

    const onCreateWorkTeamClicked = React.useCallback(() => {
      setSelectedTeamType('work');
      setCreateStep(CreateStep.CreateWorkTeam);
    }, []);

    const onTeamInputClicked = React.useCallback(async () => {
      if (orgName == null || orgName === '') {
        setErrorMsg('You must set an organization name');
        return;
      }
      if (entityName == null || entityName === '') {
        setErrorMsg('You must set a team name');
        return;
      }
      if (!isEntityAvailable) {
        setErrorMsg('Your specified team URL is already in use');
        return;
      }
      if (
        selectedTeamType === 'academic' &&
        (justification == null || justification === '')
      ) {
        setErrorMsg("You must indicate what you're working on");
        return;
      }

      const orgSize =
        orgSizeOptions.find(o => o.value === selectedOrgSize)?.text ?? 'N/A';

      try {
        const res = await createUserLedTrialMutation({
          variables: {
            newOrganizationName: orgName,
            newTeamName: entityName,
            isAcademic: selectedTeamType === 'academic',
            orgSize,
            justification,
          },
        });

        // Handle possible graphql errors. This is less common than the exception case below,
        // but can, in theory, still happen.
        if (res.errors != null) {
          const collatedErrMsg = res.errors.map(e => e.message).join(', ');
          captureError(collatedErrMsg, 'CreateTrialTeam-submitTeam', {
            extra: {orgName, entityName},
          });
          setErrorMsg(`Error creating team`);
          return;
        }
      } catch (err) {
        captureError(err, 'CreateTrialTeam-submitTeam', {
          extra: {orgName, entityName},
        });
        const errMsg =
          extractErrorMessageFromApolloError(err) ??
          (err.message as string | null) ??
          null;
        setErrorMsg(
          `Error creating team${
            errMsg != null ? ` (${errMsg})` : ''
          }. Please try again.`
        );
        return;
      }

      window.analytics.track('trial team created', {
        name: entityName,
        orgName,
        type: selectedTeamType,
        size: orgSize,
      });
      setCreateStep(CreateStep.InviteTeammates);
      setErrorMsg(null);
    }, [
      orgName,
      entityName,
      selectedTeamType,
      justification,
      createUserLedTrialMutation,
      isEntityAvailable,
      orgSizeOptions,
      selectedOrgSize,
    ]);

    const [teamEmails, setTeamEmails] = React.useState<string | null>(null);

    const onTeamInviteClicked = React.useCallback(async () => {
      if (orgName == null || orgName === '') {
        setErrorMsg('You must set an organization name');
        return;
      }
      if (entityName == null || entityName === '') {
        setErrorMsg('You must set a team name');
        return;
      }

      let emails: string[] | null = null;
      if (!_.isEmpty(teamEmails)) {
        const emailCandidates =
          teamEmails
            ?.split(/[,\n]/)
            .map(e => e.trim())
            .filter(e => e !== '') ?? null;
        if (emailCandidates != null) {
          if (emailCandidates.some(e => !isValidEmail(e))) {
            setErrorMsg('One or more of the emails entered are invalid');
            return;
          } else {
            emails = emailCandidates;
          }
        }
      }

      if (emails != null) {
        try {
          const createInvitePromises = emails.map(e =>
            createInviteMutation({
              variables: {entityName, email: e, admin: false},
            })
          );
          const createInviteResps = await Promise.all(createInvitePromises);

          if (createInviteResps.some(r => r.errors != null)) {
            const collatedErrMsg = createInviteResps
              .filter(r => r.errors != null)
              .flatMap(r => (r.errors as GraphQLError[]).map(e => e.message))
              .join(', ');
            captureError(collatedErrMsg, 'CreateTrialTeam-inviteTeammates', {
              extra: {emails, entityName},
            });
          }
        } catch (err) {
          captureError(err, 'CreateTrialTeam-inviteTeammates', {
            extra: {emails, entityName},
          });
          // Probably not worth throwing an error and staying on this page, because we've already created the team...
        }
      }

      setCreateStep(CreateStep.Done);
    }, [orgName, entityName, teamEmails, createInviteMutation]);

    const hostName = React.useMemo(() => {
      return new URL(config.HOST).hostname;
    }, []);

    const viewer = useViewer();

    if (viewer == null) {
      return <></>;
    }

    const hasNonPersonalOrgs = viewer.organizations.find(
      o => o.orgType !== OrgType.Personal
    );

    if (hasNonPersonalOrgs || envIsLocal || envIsCloudOnprem) {
      return <Redirect to={'/home'} />;
    }

    switch (createStep) {
      case CreateStep.ChooseTeamType:
        return (
          <S.Background>
            <S.Header>Create your team</S.Header>
            <S.Subheader>
              Are you working on personal or academic projects?
            </S.Subheader>
            <S.BigButtonContainer>
              <S.BigButton onClick={onCreateAcademicTeamClicked}>
                <S.BigButtonHeader>Academic</S.BigButtonHeader>
                <S.BigButtonSubheader>
                  For academic research, personal projects, and open source
                  teams
                </S.BigButtonSubheader>
              </S.BigButton>
              <S.BigButton onClick={onCreateWorkTeamClicked}>
                <S.BigButtonHeader>Work</S.BigButtonHeader>
                <S.BigButtonSubheader>
                  For users at companies, collaborating on ML projects at work
                </S.BigButtonSubheader>
              </S.BigButton>
            </S.BigButtonContainer>
          </S.Background>
        );
      case CreateStep.CreateWorkTeam:
      case CreateStep.CreateAcademicTeam:
        return (
          <S.Background>
            <S.Header>Create your team</S.Header>
            <S.Subheader>
              Fill in some details to create a collaborative workspace
            </S.Subheader>
            <S.InputContainer>
              <S.InputLabel>
                {createStep === CreateStep.CreateWorkTeam &&
                  'Organization name'}
                {createStep === CreateStep.CreateAcademicTeam &&
                  'Organization or group name'}
              </S.InputLabel>
              <S.InputText
                placeholder={
                  selectedTeamType === 'work'
                    ? 'Singularity Inc'
                    : 'Stanford University'
                }
                value={orgName ?? ''}
                onChange={updateOrgName}
              />
              <S.InputLabel>Team URL</S.InputLabel>
              <S.EntityPrefix>
                <S.EntityInputText
                  value={entityName ?? ''}
                  onChange={updateEntityName}
                />
                {!isEntityAvailable && <S.EntityInputIcon name="close" />}
              </S.EntityPrefix>
              <S.InputLabel>
                {createStep === CreateStep.CreateWorkTeam && 'Company size'}
                {createStep === CreateStep.CreateAcademicTeam &&
                  'How many collaborators do you have?'}
              </S.InputLabel>
              <S.InputDropdown
                placeholder="1-10"
                selection
                value={selectedOrgSize}
                onChange={(e, d) => setSelectedOrgSize(d.value as number)}
                options={orgSizeOptions}
              />
              {createStep === CreateStep.CreateAcademicTeam && (
                <>
                  <S.InputLabel>What are you working on?</S.InputLabel>
                  <S.MultilineInputText
                    placeholder={
                      "We're reproducing GPT-3 to make an open source implementation available to the public for free."
                    }
                    onChange={e => setJustification(e.target.value)}
                  />
                </>
              )}
              <S.Button onClick={onTeamInputClicked}>Create team</S.Button>
            </S.InputContainer>
            {errorMsg && <S.ErrorMessage>{errorMsg}</S.ErrorMessage>}
          </S.Background>
        );
      case CreateStep.InviteTeammates:
        return (
          <S.Background>
            <S.Header>Invite team members</S.Header>
            <S.Subheader>
              Get started building your collaborative projects together.
              {selectedTeamType === 'work' && (
                <>
                  <br />
                  Enjoy a free team with unlimited seats, up to 250 compute
                  hours.
                </>
              )}
            </S.Subheader>
            <S.InputContainer>
              <S.InputLabel>Share your team link</S.InputLabel>
              <S.CopyableTextInput
                text={`${hostName}/${entityName}`}
                copyText={`${config.HOST}/${entityName}`}
                toastText={'Team URL copied to clipboard'}
              />
              <S.InputLabel>Invite via email</S.InputLabel>
              <S.MultilineInputText
                placeholder="Type or paste multiple emails, separated by commas"
                onChange={e => setTeamEmails(e.target.value)}
              />
              <S.ButtonGroup>
                <S.SmallButton
                  onClick={() => setCreateStep(CreateStep.CreateWorkTeam)}>
                  Back
                </S.SmallButton>
                <S.Button onClick={onTeamInviteClicked}>Create team</S.Button>
              </S.ButtonGroup>
            </S.InputContainer>
            {errorMsg && <S.ErrorMessage>{errorMsg}</S.ErrorMessage>}
          </S.Background>
        );
      case CreateStep.Done:
        return <Redirect to={`/${entityName}`} />;
      default:
        return <></>;
    }
  },
  {id: 'CreateTrialTeam'}
);

export default CreateTrialTeam;
