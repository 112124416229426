import * as S from './RunsDownloadMenu.styles';

import React, {useCallback, useState} from 'react';
import {Button} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import TableExport from '../TableExport';
import RunsExportAPI from './RunsExportAPI';

type RunsDownloadMenuProps = {
  entityName: string;
  projectName: string;
  pageQuery: any;
  query: any;
  tableSettings: any;
};

const RunsDownloadMenu: React.FC<RunsDownloadMenuProps> = makeComp(
  ({entityName, projectName, pageQuery, query, tableSettings}) => {
    const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
    const [CSVModalOpen, setCSVModalOpen] = useState(false);
    const [exportModalOpen, setExportModalOpen] = useState(false);

    // download menu controls
    const openDownloadMenu = useCallback(() => {
      setDownloadMenuOpen(true);
    }, []);
    const closeDownloadMenu = useCallback(() => {
      setDownloadMenuOpen(false);
    }, []);

    // CSV export modal controls
    const openCSVModal = useCallback(() => {
      setDownloadMenuOpen(false);
      setCSVModalOpen(true);
    }, []);
    const closeCSVModal = useCallback(() => {
      setCSVModalOpen(false);
    }, []);

    // export API modal controls
    const openExportModal = useCallback(() => {
      setDownloadMenuOpen(false);
      setExportModalOpen(true);
    }, []);
    const closeExportModal = useCallback(() => {
      setExportModalOpen(false);
    }, []);

    const menuOptions = [
      {
        key: 'export-api',
        label: 'Export API',
        onClick: openExportModal,
      },
      {
        key: 'csv-export',
        label: 'CSV Export',
        onClick: openCSVModal,
      },
    ];

    return (
      <>
        <S.DownloadMenu
          basic
          position="bottom left"
          on="click"
          open={downloadMenuOpen}
          onOpen={openDownloadMenu}
          onClose={closeDownloadMenu}
          popperModifiers={{
            preventOverflow: {enabled: false},
          }}
          trigger={
            <Button size="tiny" className="wb-icon-button only-icon">
              <LegacyWBIcon name="download" className="export-icon" />
            </Button>
          }
          content={
            <>
              {menuOptions.map(o => (
                <div key={o.key} onClick={o.onClick}>
                  {o.label}
                </div>
              ))}
            </>
          }
        />
        <RunsExportAPI
          entityName={entityName}
          projectName={projectName}
          open={exportModalOpen}
          onClose={closeExportModal}
        />
        <TableExport
          pageQuery={pageQuery}
          query={query}
          tableSettings={tableSettings}
          open={CSVModalOpen}
          onClose={closeCSVModal}
        />
      </>
    );
  },
  {id: 'RunsDownloadMenu', memo: true}
);

export default RunsDownloadMenu;
