const HOTKEYS = {
  toggleRunsSidebar: '⌘+K',
  globalSearch: 'ctrl+/',
};

// for non-mac OS
const HOTKEYS_FALLBACK: Partial<typeof HOTKEYS> = {
  toggleRunsSidebar: 'ctrl+K',
};

export const hotkeyString = (hotkey: keyof typeof HOTKEYS) => {
  if (navigator.userAgent.indexOf('Mac') === -1 && hotkey in HOTKEYS_FALLBACK) {
    return HOTKEYS_FALLBACK[hotkey] as string;
  }
  return HOTKEYS[hotkey];
};

export const hotkeyPressed = (
  e: KeyboardEvent,
  hotkey: keyof typeof HOTKEYS
) => {
  const combo = hotkeyString(hotkey);
  const parts = combo.split('+');
  for (const part of parts) {
    switch (part) {
      case 'command':
      case '⌘':
        if (!e.metaKey) {
          return false;
        }
        break;
      case 'ctrl':
        if (!e.ctrlKey) {
          return false;
        }
        break;
      case 'alt':
      case 'option':
      case '⌥':
        if (!e.altKey) {
          return false;
        }
        break;
      case '⇧':
      case 'shift':
        if (!e.shiftKey) {
          return false;
        }
        break;
      default:
        if (e.key !== part.toLowerCase()) {
          return false;
        }
        break;
    }
  }
  return true;
};
