import {ActionType as TSActionType, getType} from 'typesafe-actions';
import {ReactNode} from 'react';
import {immerReducer} from '../reducer';

import * as Actions from './actions';
import {Tag} from '../../util/gallery/shared';

export interface AvailableNavigation {
  forward: boolean;
  backward: boolean;
  backwardCount: number;
}

export interface StateType {
  error?: any;
  showErrorPortal: boolean;
  errorPortalContent?: ReactNode;
  flash?: any;
  galleryTags: Tag[];
  availableNavigation: AvailableNavigation;
}

export type ActionType = TSActionType<typeof Actions>;

function globalReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.displayError): {
      state.error = action.payload.error;
      break;
    }
    case getType(Actions.resetError): {
      state.error = undefined;
      break;
    }
    case getType(Actions.displayErrorPortal): {
      state.showErrorPortal = true;
      state.errorPortalContent = action.payload.content;
      break;
    }
    case getType(Actions.resetErrorPortal): {
      state.showErrorPortal = false;
      break;
    }
    case getType(Actions.setFlash): {
      state.flash = action.payload.flash;
      break;
    }
    case getType(Actions.setGalleryTags): {
      state.galleryTags = action.payload.tags;
      break;
    }
    case getType(Actions.updateAvailableNavigation): {
      action.payload.nav.backwardCount = Math.max(
        0,
        action.payload.nav.backwardCount
      );
      state.availableNavigation = action.payload.nav;
      break;
    }
  }
}

export default immerReducer<StateType, ActionType>(globalReducer, {
  showErrorPortal: false,
  galleryTags: [],
  availableNavigation: {forward: false, backward: false, backwardCount: 0},
});
