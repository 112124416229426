import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Form, Input, Button, Message} from 'semantic-ui-react';
import OnboardingBackground from '../assets/onboarding/signup-colorful-bkg.svg';
import queryString from 'query-string';
import {History, Location} from 'history';

import LinkButton from './LinkButton';
import Loader from './WandbLoader';
import {isInJupyterNotebook, auth} from '../setup';
import {readCachedAuthState} from '../reducers/auth';
import {displayError} from '../state/global/actions';
import {useViewer} from '../state/viewer/hooks';
import {doAuthRedirect} from '../util/auth';
import VerifyEmail from './VerifyEmail';

import {
  propagateErrorsContext,
  extractErrorMessageFromApolloError,
} from '../util/errors';
import {useResetPasswordMutation} from '../generated/graphql';
import makeComp from '../util/profiler';

interface LoginProps {
  history: History;
  location: Location;
  currentIdToken?: string;
}

const getCachedTokenTail = () => {
  const currentCached = readCachedAuthState();

  if (currentCached.currentIdToken) {
    return currentCached.currentIdToken.slice(
      currentCached.currentIdToken.length - 4
    );
  }

  return 'null';
};

const Login: React.FC<LoginProps> = makeComp(
  ({currentIdToken, history, location}) => {
    const [referrer, setReferrer] = useState<{prompt?: boolean}>(
      location.state || {}
    );
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordError, setResetPasswordError] =
      useState<boolean>(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] =
      useState<boolean>(false);

    const [resetPassword] = useResetPasswordMutation({
      context: propagateErrorsContext(),
    });

    const viewer = useViewer();

    const toggleShowResetPassword = useCallback(() => {
      setShowResetPassword(prev => !prev);
      setResetPasswordError(false);
      setResetPasswordSuccess(false);
      setError(false);
    }, []);

    const params = queryString.parse(location.search);
    const errUnverifiedEmail: boolean =
      typeof params.error_description === 'string' &&
      !!params.error_description.match('Email must be verified');

    useEffect(() => {
      // Just redirect when we're actually logged in.
      if (viewer) {
        history.replace({pathname: '/', state: {internal: true}});
      }
    }, [history, viewer]);

    useEffect(() => {
      if (auth.loggedIn()) {
        doAuthRedirect(history);
        return;
      }

      // Jupyter / iframe mode
      if (isInJupyterNotebook()) {
        if (currentIdToken) {
          doAuthRedirect(history);
        } else {
          // Auth works funky in iframes, we attempt to reload to get creds
          setReferrer({prompt: true});
          setTimeout(() => window.location.reload(true), 10000);
        }
        return;
      }

      const options: {signup?: boolean} = {};
      if (params.signUp || params.signup) {
        options.signup = true;
      }
      // invitation links
      if (params.invited) {
        options.signup = true;
        const invited =
          typeof params.invited === 'string'
            ? params.invited
            : params.invited[0];
        localStorage.setItem('auth.invited', invited);
      }

      if (!params.upgradeAuth && auth.loggedIn()) {
        // Already logged in, just go home. This happens when
        // people visit /site and click the login button on the
        // top-right (and are already logged in).
        history.replace('/');
      } else if (params.error && params.error === 'access_denied') {
        console.error('Access denied', params.error_description);
        if (!errUnverifiedEmail) {
          auth.store!.dispatch(
            displayError({
              code: 403,
              message: (
                <>
                  <p>
                    We're sorry, but there was a problem authenticating your
                    account: {params.error_description}
                  </p>
                  <p>
                    <Button
                      icon="refresh"
                      size="small"
                      onClick={() => auth.login(options)}
                    />{' '}
                    Please click to try again.
                  </p>
                  <p>
                    If you continue to see this message, please email{' '}
                    <a href="mailto:support@wandb.com">support@wandb.com</a>.
                  </p>
                </>
              ),
            })
          );
        }
      } else {
        if (!params.local) {
          // if we're not local, then try /oidc/login
          auth.login(options, location.state);
        } else {
          // if server says we're local, then try auto login
          auth.autoLogin();
          setReferrer({prompt: true});
        }
        return;
      }
      // eslint-disable-next-line
    }, []);

    const form = useRef<HTMLFormElement>(null);

    if (errUnverifiedEmail) {
      return <VerifyEmail />;
    }

    if (!referrer.prompt) {
      return <Loader />;
    }

    return (
      <div
        className="onboarding-flow"
        style={{backgroundImage: `url(${OnboardingBackground})`}}>
        <div className="onboarding-dialogue">
          <h2>{showResetPassword ? 'Reset Password' : 'Login'}</h2>

          <div>
            <Form
              ref={form}
              onSubmit={async () => {
                setLoading(true);
                if (showResetPassword) {
                  setResetPasswordSuccess(false);
                  setResetPasswordError(false);
                  console.log(`Resetting password for ${email}`);
                  try {
                    await resetPassword({variables: {email}});
                    setResetPasswordSuccess(true);
                  } catch (e) {
                    const extracted = extractErrorMessageFromApolloError(e);
                    console.error(
                      `Error requesting password reset: ${extracted}`
                    );
                    setResetPasswordError(true);
                  } finally {
                    setLoading(false);
                  }
                  return;
                }
                setError(false);
                console.log(`Logging in as ${email}`);

                console.log(`Prior to login, token is ${getCachedTokenTail()}`);
                const success = await auth.remoteLogin(email, password);
                if (success) {
                  const url = doAuthRedirect();
                  console.log(`Login successful; redirecting to ${url}`);
                  console.log(
                    `At time of redirect, token is ${getCachedTokenTail()}`
                  );
                } else {
                  console.error(`Login failed.`);
                  console.error(
                    `After login failure, token is ${getCachedTokenTail()}`
                  );
                  setLoading(false);
                  setError(true);
                }
              }}>
              <Message
                success
                visible={resetPasswordSuccess}
                content="Password reset email sent"
              />
              <Form.Input
                label="Email"
                name="email"
                data-test="email"
                error={
                  resetPasswordError && 'Error while trying to reset password'
                }
                onChange={(e, {value}) => setEmail(value.replace(/\s+/g, ''))}
              />
              {!showResetPassword && (
                <Form.Field error={error}>
                  <label>Password</label>
                  <Input
                    type="password"
                    name="password"
                    data-test="password"
                    onChange={(e, {value}) => setPassword(value)}
                  />
                  <div
                    style={{display: error ? 'inline-block' : 'none'}}
                    role="alert"
                    className="ui pointing above prompt label">
                    Invalid password
                  </div>
                </Form.Field>
              )}
              {!showResetPassword && (
                <span style={{display: 'block', textAlign: 'right'}}>
                  <LinkButton
                    data-test="forgot-your-password"
                    onClick={toggleShowResetPassword}>
                    Forgot your password?
                  </LinkButton>
                </span>
              )}
              <Button type="submit" style={{display: 'none'}}>
                Login
              </Button>
            </Form>
          </div>
          <div className="actions">
            {showResetPassword && (
              <Button onClick={toggleShowResetPassword}>Cancel</Button>
            )}
            <Button
              primary
              loading={loading}
              data-test="submit"
              onClick={e => {
                form.current?.handleSubmit(e);
              }}>
              {showResetPassword ? 'Reset Password' : 'Login'}
            </Button>
          </div>
        </div>
      </div>
    );
  },
  {id: 'Login'}
);

export default Login;
