import {match, matchPath} from 'react-router';

export const GALLERY_PATH_SEGMENT = 'fully-connected';
export const PUBLISHED_WORK_PATH_SEGMENT = 'published-work';
export const GALLERY_PATH = `/${GALLERY_PATH_SEGMENT}/:sortOrTag?/:tag?`;
export const STORAGE_EXPLORER_TRACKED_PATH =
  '/usage/:entityName/tracked/:filePath*';
export const STORAGE_EXPLORER_COMPUTE_HOURS_PATH =
  '/usage/:entityName/computehour';
export const REPORT_PAGE_VIEW_PATH =
  '/:entityName/:projectName/reports/:reportNameAndID';
export const REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH = `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}/:reportNameAndID`;
export const GALLERY_POST_VIEW_PATH = '/:entityName/posts/:reportNameAndID';
export const GALLERY_POST_EDIT_PATH = `${GALLERY_POST_VIEW_PATH}/edit`;
export const GALLERY_DISCUSSION_VIEW_PATH =
  '/:entityName/discussions/:reportNameAndID';
export const GALLERY_DISCUSSION_EDIT_PATH = `${GALLERY_DISCUSSION_VIEW_PATH}/edit`;
export const PROFILE_PATH = '/:entityName';
export const PROFILE_PROJECTS_PATH = `${PROFILE_PATH}/projects`;
export const PROFILE_LIKES_PATH = `${PROFILE_PATH}/likes`;
export const PROJECT_BASE_PATH = '/:entityName/:projectName';
export const PROJECT_PATHS = [
  PROJECT_BASE_PATH,
  '/:entityName/:projectName/:tab(overview|workspace|table|reportlist|sweeps|run-queue|artifacts)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCommitHash(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab(files)/:filePath*',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab?',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName',
];
export const RUN_PATHS = [
  '/:entityName/:projectName/runs/:runName',
  '/:entityName/:projectName/runs/:runName/:tab(files|code)/:filePath*',
  '/:entityName/:projectName/runs/:runName/:tab',
];
export const GROUP_PATHS = [
  '/:entityName/:projectName/groups/:groupName',
  '/:entityName/:projectName/groups/:groupName/:tab(files)/:filePath*',
  '/:entityName/:projectName/groups/:groupName/:tab',
];
export const SWEEP_PATHS = [
  '/:entityName/:projectName/sweeps/:sweepName',
  '/:entityName/:projectName/sweeps/:sweepName/:tab',
];
export const BENCHMARK_PATHS = [
  '/:entityName/:projectName/benchmark',
  '/:entityName/:projectName/benchmark/:tab/:threadID?',
];

interface RouteData {
  path: string | string[];
  exact?: true;
  redirect?: string;
}

const ROUTES: RouteData[] = [
  {
    path: '/__test_page',
    exact: true,
  },
  {
    path: ['/__registry', '/__registry/:tab'],
    exact: true,
  },
  {
    path: ['/__wbench', '/__wbench/:tab'],
    exact: true,
  },
  {
    path: '/',
    exact: true,
  },
  {
    path: '/login',
  },
  {
    path: '/login-redirect',
  },
  {
    path: '/logout',
  },
  {
    path: '/trending',
  },
  {
    path: '/home',
    exact: true,
  },
  {
    path: '/home/projects',
    exact: true,
  },
  {
    path: '/quickstart/:framework?',
  },
  {
    path: `/${GALLERY_PATH_SEGMENT}/create-post`,
  },
  {
    path: `/${GALLERY_PATH_SEGMENT}/create-discussion`,
  },
  {
    path: GALLERY_PATH,
  },
  {
    path: '/fullyconnected',
    redirect: GALLERY_PATH,
  },
  {
    path: '/fc',
    redirect: GALLERY_PATH,
  },
  {
    path: '/gallery',
    redirect: GALLERY_PATH,
  },
  {
    path: '/__test/simple_error',
    exact: true,
  },
  {
    path: '/users/:userName',
  },
  {
    path: '/signup',
  },
  {
    path: '/change-password',
  },
  {
    path: '/authorize',
  },
  {
    path: '/settings',
    exact: true,
  },
  {
    path: ['/billing', '/subscriptions'],
    exact: true,
  },
  {
    path: ['/team-payment'],
    exact: true,
  },
  {
    path: '/admin/usage',
    exact: true,
  },
  {
    path: '/admin/team',
  },
  {
    path: '/admin/organization',
  },
  {
    path: '/admin/users',
  },
  {
    path: '/admin/license',
  },
  {
    path: `/admin/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/create-team',
  },
  {
    path: '/teams/:entityName',
  },
  {
    path: '/admin/home',
  },
  {
    path: [
      '/usage/:entityName',
      STORAGE_EXPLORER_TRACKED_PATH,
      '/usage/:entityName/:filePath*',
    ],
    exact: true,
  },
  {
    path: '/storage/:entityName/tracked/:filePath*',
    redirect: STORAGE_EXPLORER_TRACKED_PATH,
  },
  {
    path: '/storage/:entityName/:filePath*',
    redirect: '/usage/:entityName/:filePath*',
  },
  {
    path: '/storage/:entityName',
    redirect: '/usage/:entityName',
  },
  {
    path: '/new-project',
  },
  {
    path: '/new-benchmark',
  },
  {
    path: '/:entityname/benchmark/new',
  },
  {
    path: PROFILE_PROJECTS_PATH,
    exact: true,
  },
  {
    path: PROFILE_LIKES_PATH,
    exact: true,
  },
  {
    path: RUN_PATHS,
    exact: true,
  },
  {
    path: GROUP_PATHS,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/create-sweep',
    exact: true,
  },
  {
    path: SWEEP_PATHS,
    exact: true,
  },
  {
    path: BENCHMARK_PATHS,
    exact: true,
  },
  // {
  //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
  //   exact: true,
  // },
  {
    path: '/:entityName/:projectName/reports',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/published',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/reports/:reportNameAndID/edit',
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PATH,
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_EDIT_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_VIEW_PATH,
    exact: true,
  },
  {
    path: GALLERY_DISCUSSION_EDIT_PATH,
    exact: true,
  },
  {
    path: GALLERY_DISCUSSION_VIEW_PATH,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/querydemo',
    exact: true,
  },
  {
    path: '/org/:orgName',
    exact: true,
  },
  {
    path: PROJECT_PATHS,
    exact: true,
  },
  {
    // this is here to truncate extra path segments after the actual path
    path: PROJECT_BASE_PATH,
    redirect: PROJECT_BASE_PATH,
  },
  {
    path: PROFILE_PATH,
    exact: true,
  },
];

export default ROUTES;

function getRouteMatch(path: string): match | null {
  for (const r of ROUTES) {
    const m = matchPath(path, {path: r.path, exact: r.exact});
    if (m != null) {
      return m;
    }
  }
  return null;
}

export function isReportViewPath(path: string): boolean {
  const m = getRouteMatch(path);
  return (
    [REPORT_PAGE_VIEW_PATH, REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH].indexOf(
      m?.path ?? ''
    ) !== -1
  );
}

export function isProfilePath(path: string): boolean {
  const m = getRouteMatch(path);
  return m?.path === PROFILE_PATH;
}
