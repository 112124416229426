import * as S from './WBSlate.styles';

import React from 'react';
import SlashMenu from './SlashMenu';
import {TypingContext} from './TypingContext';
import {ShiftPressedContext} from './ShiftPressedContext';
import {Node, Transforms, Editor, Range, Text} from 'slate';
import HoveringToolbar from './HoveringToolbar';
import {
  Slate,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from 'slate-react';
import {ReportWidthOption} from '../../state/views/report/types';
import {ReportViewRef} from '../../state/reports/types';
import {markShortcutsOnKeyDown} from './plugins/mark-shortcuts';

import {PanelExportContextProvider} from '../../components/Panel2/PanelExportContext';
import FloatingReportDiscussion from '../FloatingReportDiscussion';
import {DragDropProvider} from '../../containers/DragDrop';
import {WBSlateDragDropContext} from './plugins/drag-drop';
import {useScrollToURLHash} from '../../util/document';
import makeComp from '../../util/profiler';
import {isHeading, HeadingElement, Heading} from './plugins/headings';
import {
  isListItem,
  isList,
  ListElement,
  ListItemElement,
  List,
  EditorWithLists,
  ListItem,
} from './plugins/lists';
import {isParagraph, ParagraphElement, Paragraph} from './plugins/paragraphs';
import {
  useCodeHighlighting,
  isCodeBlock,
  CodeBlockElement,
  CodeBlock,
  isCodeLine,
  CodeLineElement,
} from './plugins/code-blocks';
import {
  CalloutBlock,
  isCalloutBlock,
  CalloutBlockElement,
  isCalloutLine,
  CalloutLineElement,
} from './plugins/callout-blocks';
import {
  WeavePanel,
  isWeavePanel,
  WeavePanelElement,
  withWeavePanels,
} from './plugins/weave-panels';
import {PanelGrid, isPanelGrid, PanelGridElement} from './plugins/panel-grids';
import {
  BlockQuote,
  BlockQuoteElement,
  isBlockQuote,
} from './plugins/block-quotes';
import {Gallery, GalleryElement, isGallery} from './plugins/gallery';
import {isImage, Image, ImageElement} from './plugins/images';
import {hardBreakOnKeyDown} from './plugins/hard-break';
import {autoScrollOnKeyDown} from './plugins/auto-scroll';
import {
  isTable,
  isTableRow,
  isTableCell,
  TableElement,
  TableRowElement,
  TableCellElement,
} from './plugins/tables';
import {Latex, LatexElement, isLatex} from './plugins/latex';
import {Video, VideoElement, isVideo} from './plugins/videos';
import {Twitter, TwitterElement, isTwitter} from './plugins/twitter';
import {Spotify, SpotifyElement, isSpotify} from './plugins/spotify';
import {
  SoundCloud,
  SoundCloudElement,
  isSoundCloud,
} from './plugins/soundcloud';
import {Captivate, CaptivateElement, isCaptivate} from './plugins/captivate';
import {
  HorizontalRule,
  HorizontalRuleElement,
  isHorizontalRule,
} from './plugins/horizontal-rules';
import {Link, LinkElement, isLink} from './plugins/links';
import {
  MarkdownBlock,
  MarkdownBlockElement,
  isMarkdownBlock,
} from './plugins/markdown-blocks';
import {
  TableOfContents,
  TableOfContentsElement,
  isTableOfContents,
  withTableOfContents,
} from './plugins/table-of-contents';
import {EditorWithPersistentBlankLines} from './plugins/persistent-blank-lines';

import {createEditor} from 'slate';
import {withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import {withHeadings} from './plugins/headings';
import {withLists} from './plugins/lists';
import {withCode} from './plugins/code-blocks';
import {withCallout} from './plugins/callout-blocks';
import {withPanelGrids} from './plugins/panel-grids';
import {withBlockQuotes} from './plugins/block-quotes';
import {withGallerys} from './plugins/gallery';
import {withImages} from './plugins/images';
import {withTables} from './plugins/tables';
import {withVideos} from './plugins/videos';
import {withTwitter} from './plugins/twitter';
import {withSpotify} from './plugins/spotify';
import {withSoundCloud} from './plugins/soundcloud';
import {withCaptivate} from './plugins/captivate';
import {withLatex} from './plugins/latex';
import {withHorizontalRules} from './plugins/horizontal-rules';
import {withLinks} from './plugins/links';
import {withMarkdownBlocks} from './plugins/markdown-blocks';
import {withPersistentBlankLine} from './plugins/persistent-blank-lines';
import {withEmojis} from './plugins/emojis';
import {withDeleteLineBackwards} from './plugins/delete-line-backwards';
import {withMarkdownShortcuts} from './plugins/markdown-shortcuts';
import {withBetterVoidHandling} from './plugins/better-void-handling';
import {useIsGalleryReport} from '../../util/gallery';
import {useSelector} from '../../state/hooks';

type DOMNode = globalThis.Node;

const DOMNode = globalThis.Node;

const isDOMNode = (value: any): value is DOMNode => {
  return value instanceof DOMNode;
};

const hasEditableTarget = (
  editor: ReactEditor,
  target: EventTarget | null
): target is DOMNode => {
  return (
    isDOMNode(target) &&
    ReactEditor.hasDOMNode(editor, target, {editable: true})
  );
};

export const createWBSlateEditor = () => {
  return withGallerys(
    withBlockQuotes(
      withHeadings(
        withCaptivate(
          withSoundCloud(
            withSpotify(
              withTwitter(
                withVideos(
                  withEmojis(
                    withLatex(
                      withTableOfContents(
                        withHorizontalRules(
                          withCode(
                            withCallout(
                              withTables(
                                withLists(
                                  withDeleteLineBackwards(
                                    withImages(
                                      withWeavePanels(
                                        withPanelGrids(
                                          withPersistentBlankLine(
                                            withMarkdownBlocks(
                                              withLinks(
                                                withMarkdownShortcuts(
                                                  withBetterVoidHandling(
                                                    withReact(
                                                      withHistory(
                                                        createEditor()
                                                      )
                                                    )
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};

export interface WBSlateProps {
  readOnly: boolean;
  widthMode: ReportWidthOption;
  value: WBSlateElement[];
  viewRef: ReportViewRef;
  viewID: string;
  onChange(value: Node[]): void;
}

const WBSlateElement: React.FC<RenderElementProps> = makeComp(
  props => {
    const {element} = props;
    if (isParagraph(element)) {
      return <ParagraphElement {...props} element={element} />;
    }
    if (isListItem(element)) {
      return <ListItemElement {...props} element={element} />;
    }
    if (isList(element)) {
      return <ListElement {...props} element={element} />;
    }
    if (isBlockQuote(element)) {
      return <BlockQuoteElement {...props} element={element} />;
    }
    if (isGallery(element)) {
      return <GalleryElement {...props} element={element} />;
    }
    if (isHeading(element)) {
      return <HeadingElement {...props} element={element} />;
    }
    if (isCodeBlock(element)) {
      return <CodeBlockElement {...props} element={element} />;
    }
    if (isCodeLine(element)) {
      return <CodeLineElement {...props} element={element} />;
    }
    if (isCalloutBlock(element)) {
      return <CalloutBlockElement {...props} element={element} />;
    }
    if (isCalloutLine(element)) {
      return <CalloutLineElement {...props} element={element} />;
    }
    if (isLink(element)) {
      return <LinkElement {...props} element={element} />;
    }
    if (isMarkdownBlock(element)) {
      return <MarkdownBlockElement {...props} element={element} />;
    }
    if (isPanelGrid(element)) {
      return <PanelGridElement {...props} element={element} />;
    }
    if (isWeavePanel(element)) {
      return <WeavePanelElement {...props} element={element} />;
    }
    if (isImage(element)) {
      return <ImageElement {...props} element={element} />;
    }
    if (isTable(element)) {
      return <TableElement {...props} element={element} />;
    }
    if (isTableRow(element)) {
      return <TableRowElement {...props} element={element} />;
    }
    if (isTableOfContents(element)) {
      return <TableOfContentsElement {...props} element={element} />;
    }
    if (isTableCell(element)) {
      return <TableCellElement {...props} element={element} />;
    }
    if (isHorizontalRule(element)) {
      return <HorizontalRuleElement {...props} element={element} />;
    }
    if (isLatex(element)) {
      return <LatexElement {...props} element={element} />;
    }
    if (isVideo(element)) {
      return <VideoElement {...props} element={element} />;
    }
    if (isTwitter(element)) {
      return <TwitterElement {...props} element={element} />;
    }
    if (isSpotify(element)) {
      return <SpotifyElement {...props} element={element} />;
    }
    if (isSoundCloud(element)) {
      return <SoundCloudElement {...props} element={element} />;
    }
    if (isCaptivate(element)) {
      return <CaptivateElement {...props} element={element} />;
    }
    // default to paragraph for unknowns, to prevent crashes after funky migrations
    return <ParagraphElement {...props} element={element as Paragraph} />;
  },
  {id: 'WBSlateElement'}
);

const WBSlateLeaf: React.FC<RenderLeafProps> = makeComp(
  props => {
    const typedLeaf = props.leaf as TextWithFormatting;
    return <LeafNode {...props} leaf={typedLeaf}></LeafNode>;
  },
  {id: 'WBSlateLeaf'}
);

const WBSlate: React.FC<WBSlateProps> = ({
  readOnly,
  widthMode,
  value,
  viewRef,
  viewID,
  onChange,
}) => {
  const editor = React.useMemo(createWBSlateEditor, []);

  const [slashMenuOpen, setSlashMenuOpen] = React.useState(false);

  const view = useSelector(state => state.views.views[viewRef.id]);
  const parentViewId = view.parentId ?? view.id;

  const [dragItem, setDragItem] = React.useState<Node | undefined>(undefined);
  // This is true if any of the drag handle menus are open. Disables the popup on the other handles.
  const [dragHandleMenuOpen, setDragHandleMenuOpen] = React.useState(false);
  const dragContextValue = React.useMemo(
    () => ({
      parentViewId,
      dragItem,
      setDragItem,
      dragHandleMenuOpen,
      setDragHandleMenuOpen,
    }),
    [
      parentViewId,
      dragItem,
      setDragItem,
      dragHandleMenuOpen,
      setDragHandleMenuOpen,
    ]
  );

  const [typing, setTyping] = React.useState(true);
  const typingContextValue = React.useMemo(
    () => ({typing, setTyping}),
    [typing]
  );

  const [shiftPressed, setShiftPressed] = React.useState(false);
  const shiftPressedContextValue = React.useMemo(
    () => ({shiftPressed, setShiftPressed}),
    [shiftPressed]
  );

  // HAX: DO NOT REMOVE. THE "CONVERT ALL MARKDOWN" BUTTON IS RELIANT ON THIS.
  // "sry not sry" - Axel
  (window as any).editor = editor;
  (window as any).Editor = Editor;
  (window as any).ReactEditor = ReactEditor;
  (window as any).Transforms = Transforms;
  (window as any).value = value;

  useScrollToURLHash(3000);

  const {isGalleryReport} = useIsGalleryReport(viewID);

  const renderElement = React.useCallback((props: RenderElementProps) => {
    return <WBSlateElement {...props} />;
  }, []);
  const renderLeaf = React.useCallback((props: RenderLeafProps) => {
    return <WBSlateLeaf {...props} />;
  }, []);

  const addWeavePanel = React.useCallback(
    (panel: any) => {
      const weavePanelNode: Node = {
        type: 'weave-panel',
        children: [{text: ''}],
        config: {
          exp: panel.node,
          panelId: panel.panelId,
          panelConfig: panel.config,
        },
      };
      Editor.insertNode(editor, weavePanelNode);
    },
    [editor]
  );

  const decorate = useCodeHighlighting(editor);

  // TODO(axel): figure out how to normalize on init in a cleaner fashion
  React.useLayoutEffect(() => {
    EditorWithPersistentBlankLines.ensureBlankLine(editor);
    if (!readOnly) {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, []));
    }
  }, [editor, readOnly]);

  return (
    <S.SlateWrapper>
      {isGalleryReport && (
        <FloatingReportDiscussion
          viewRef={viewRef}
          isFeatured={isGalleryReport}
        />
      )}
      <S.PrintReportHack />
      <Slate editor={editor} value={value} onChange={onChange}>
        <TypingContext.Provider value={typingContextValue}>
          <ShiftPressedContext.Provider value={shiftPressedContextValue}>
            <SlashMenu
              open={slashMenuOpen}
              onClose={React.useCallback(() => setSlashMenuOpen(false), [])}
            />
            {!readOnly && <HoveringToolbar></HoveringToolbar>}
            <PanelExportContextProvider addPanel={addWeavePanel}>
              {/* For reordering blocks */}
              <WBSlateDragDropContext.Provider value={dragContextValue}>
                {/* For panels in panel grids */}
                <DragDropProvider>
                  <S.StyledEditable
                    decorate={decorate}
                    readOnly={readOnly}
                    $widthMode={widthMode}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => {
                      // this is slightly modified from slate-react's internal onPaste handler
                      // we need this because slate doesn't handle paste correctly on Safari
                      if (
                        hasEditableTarget(editor, event.target) &&
                        !readOnly
                      ) {
                        event.preventDefault();
                        ReactEditor.insertData(editor, event.clipboardData);
                      }
                    }}
                    onCut={() => {
                      /** Ugly hack to fix cut on a single void node */
                      const {selection} = editor;
                      if (
                        selection &&
                        Range.isCollapsed(selection) &&
                        Editor.void(editor)
                      ) {
                        window.setTimeout(() => {
                          Editor.deleteBackward(editor);
                        });
                      }
                    }}
                    onKeyDown={e => {
                      if (autoScrollOnKeyDown(editor, e)) {
                        return;
                      }

                      if (markShortcutsOnKeyDown(editor, e)) {
                        return;
                      }

                      if (hardBreakOnKeyDown(editor, e)) {
                        return;
                      }

                      if (e.key === '/') {
                        window.setTimeout(() => {
                          setSlashMenuOpen(true);
                        });
                      }

                      // Prevents newline. Necessary in Firefox.
                      if (e.key === 'Enter') {
                        if (slashMenuOpen) {
                          e.preventDefault();
                        }
                      }

                      if (e.key === 'Tab') {
                        e.preventDefault();

                        if (EditorWithLists.onTab(editor, e)) {
                          return;
                        }

                        Transforms.insertText(editor, '\t');
                        return;
                      }

                      // Unfortunate hack because Slate doesn't
                      // detect backspaces on inline voids
                      // in Chrome.
                      if (e.key === 'Backspace') {
                        const inlineVoid = Editor.above(editor, {
                          match: n =>
                            Editor.isVoid(editor, n) &&
                            Editor.isInline(editor, n),
                        });
                        if (inlineVoid != null) {
                          e.preventDefault();
                          Editor.deleteBackward(editor);
                          return;
                        }
                      }

                      if (e.key === 'Shift') {
                        setShiftPressed(true);
                        editor.shiftPressed = true;
                      }

                      setTyping(true);
                    }}
                    onKeyUp={e => {
                      if (e.key === 'Shift') {
                        setShiftPressed(false);
                        editor.shiftPressed = false;
                      }
                    }}
                    onMouseMove={() => {
                      setTyping(false);
                    }}
                  />
                </DragDropProvider>
              </WBSlateDragDropContext.Provider>
            </PanelExportContextProvider>
          </ShiftPressedContext.Provider>
        </TypingContext.Provider>
      </Slate>
    </S.SlateWrapper>
  );
};

export default WBSlate;

export interface TextWithFormatting extends Text {
  strong?: boolean;
  emphasis?: boolean;
  inlineCode?: boolean;
  underline?: boolean;
  delete?: boolean;

  comment?: boolean;
  operator?: boolean;
  url?: boolean;
  keyword?: boolean;
  variable?: boolean;
  regex?: boolean;
  number?: boolean;
  boolean?: boolean;
  tag?: boolean;
  constant?: boolean;
  symbol?: boolean;
  attr?: boolean;
  selector?: boolean;
  punctuation?: boolean;
  string?: boolean;
  char?: boolean;
  function?: boolean;
  class?: boolean;
}

export const isTextWithFormatting = (node: Node): node is TextWithFormatting =>
  typeof node.text === 'string';

const LeafNode: React.FC<RenderLeafProps & {leaf: TextWithFormatting}> = ({
  attributes,
  children,
  leaf,
}) => {
  return (
    <S.Leaf
      strong={leaf.strong}
      emphasis={leaf.emphasis}
      inlineCode={leaf.inlineCode}
      underline={leaf.underline}
      delete={leaf.delete}
      comment={leaf.comment}
      operator={leaf.operator}
      url={leaf.url}
      keyword={leaf.keyword}
      variable={leaf.variable}
      regex={leaf.regex}
      number={leaf.number}
      boolean={leaf.boolean}
      tag={leaf.tag}
      constant={leaf.constant}
      symbol={leaf.symbol}
      attr={leaf.attr}
      selector={leaf.selector}
      punctuation={leaf.punctuation}
      string={leaf.string}
      char={leaf.char}
      function={leaf.function}
      isClass={leaf.class}
      {...attributes}>
      {children}
    </S.Leaf>
  );
};

// When you add a type or edit one, make sure our report pre-rendering
// logic in host/src/report/slate.ts is also updated

export type WBSlateElement =
  | Paragraph
  | List
  | ListItem
  | CodeBlock
  | CalloutBlock
  | BlockQuote
  | Gallery
  | Heading
  | Link
  | MarkdownBlock
  | WeavePanel
  | PanelGrid
  | Image
  | HorizontalRule
  | TableOfContents
  | Latex
  | Video
  | Twitter
  | Spotify
  | SoundCloud
  | Captivate;
