import {Element} from 'slate';

export function getDeepLinkId(element: Element): string | undefined {
  return element.type === 'heading'
    ? element.children
        .map(c => c.text)
        .join('')
        .replace(/\s+/g, '-')
        .toLowerCase()
    : undefined;
}

export function getDeepLinkIdFromText(text: string): string {
  return text.replace(/\s+/g, '-').toLowerCase();
}
