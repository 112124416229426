import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Popup, Table as STable} from 'semantic-ui-react';
import * as globals from '../../css/globals.styles';
import * as Panel2 from './panel';
import makeComp from '../../util/profiler';
import * as SemanticHacks from '../../util/semanticHacks';
import * as CGTypes from '@wandb/cg/browser/types';
import * as Graph from '@wandb/cg/browser/graph';
import * as HL from '@wandb/cg/browser/hl';
import {PanelComp2} from './PanelComp';
// import copyToClipboard from 'copy-to-clipboard';
import * as LLReact from '../../cgreact';
import {useGatedValue, useWhenOnScreenAfterNewValue} from '../../state/hooks';
import EditableField from '../EditableField';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import {INPUT_SLIDER_CLASS} from '../elements/SliderInput';
import {getPanelStackDims, usePanelStacksForType} from './availablePanels';
import * as Types from '@wandb/cg/browser/model/types';
import * as Op from '@wandb/cg/browser/ops';
import * as Table from './tableState';
import * as TableType from './tableType';
import {ControlFilter} from './ControlFilter';
import PageControls from './ControlPage';
import * as ExpressionEditor from './ExpressionEditor';
import * as ExpressionView from './ExpressionView';
import {PanelContextProvider, usePanelContext} from './PanelContext';
import * as S from './PanelTable.styles';
import {useTableStateWithRefinedExpressions} from './tableStateReact';
import WandbLoader from '../WandbLoader';
import _ from 'lodash';
import {makeEventRecorder} from './panellib/libanalytics';

const recordEvent = makeEventRecorder('Table');

const STYLE_POPUP_CLASS = 'control-box-popup';
const inputType = TableType.TableLikeType;

function useUpdatePanelConfig(
  updateTableState: (newTableState: Table.TableState) => void,
  tableState: Table.TableState,
  colId: string
) {
  return useCallback(
    (newPanelConfig: any) => {
      recordEvent('UPDATE_PANEL_CONFIG');
      return updateTableState(
        Table.updateColumnPanelConfig(tableState, colId, newPanelConfig)
      );
    },
    [colId, tableState, updateTableState]
  );
}

export const ColumnHeader: React.FC<{
  isGroupCol: boolean;
  tableState: Table.TableState;
  inputArrayNode: Types.Node;
  rowsNode: Types.Node;
  columnName: string;
  selectFunction: Types.NodeOrVoidNode;
  colId: string;
  panelId: string;
  config: any;
  panelContext: any;
  updatePanelContext(newContext: any): void;
  updateTableState(newTableState: Table.TableState): void;
  updateColumnName(newColumnName: string): void;
  updateSelectFunction(newNode: Types.NodeOrVoidNode): void;
  updatePanelId(newPanelId: string): void;
}> = makeComp(
  ({
    isGroupCol,
    tableState,
    inputArrayNode,
    rowsNode,
    columnName,
    selectFunction: propsSelectFunction,
    colId,
    panelId,
    config,
    panelContext,
    updatePanelContext,
    updateColumnName,
    updateSelectFunction: propsUpdateSelectFunction,
    updatePanelId,
    updateTableState,
  }) => {
    const {frame} = usePanelContext();

    const [selectFunction, setSelectFunction] =
      useState<CGTypes.EditingNode>(propsSelectFunction);
    const updateSelectFunction = useCallback(() => {
      if (HL.nodeIsExecutable(selectFunction)) {
        propsUpdateSelectFunction(selectFunction);
      }
    }, [propsUpdateSelectFunction, selectFunction]);

    const [columnSettingsOpen, setColumnSettingsOpen] = useState(false);
    const openColumnSettings = useCallback(() => {
      setColumnSettingsOpen(true);
      // Copy props in to editing function in case props version
      // has changed externally.
      setSelectFunction(propsSelectFunction);
    }, [propsSelectFunction]);

    const updatePanelConfig = useUpdatePanelConfig(
      updateTableState,
      tableState,
      colId
    );

    const cellFrame = useMemo(
      () =>
        Table.getCellFrame(
          inputArrayNode,
          rowsNode,
          frame,
          tableState.groupBy,
          tableState.columnSelectFunctions,
          colId
        ),
      [
        colId,
        inputArrayNode,
        rowsNode,
        tableState.columnSelectFunctions,
        tableState.groupBy,
        frame,
      ]
    );
    const exampleRowNode = useMemo(() => cellFrame.row, [cellFrame.row]);
    const selectedNode = useMemo(
      () =>
        // Only use selected node if it's executable (has no voids...)
        // otherwise fall back to the props version.
        // TODO: this isn't really right
        HL.nodeIsExecutable(selectFunction)
          ? Table.getCellValueNode(
              exampleRowNode,
              selectFunction,
              tableState.groupBy,
              tableState.columnSelectFunctions,
              tableState.columnNames,
              colId
            )
          : Graph.voidNode(),
      [
        exampleRowNode,
        selectFunction,
        tableState.groupBy,
        tableState.columnSelectFunctions,
        tableState.columnNames,
        colId,
      ]
    );
    const {handler, stackIds, curPanelId} = usePanelStacksForType(
      selectFunction.type,
      panelId,
      {
        excludeTable: true,
        excludePlot: true,
      }
    );
    const enableGroup = LLReact.useClientBound(Table.enableGroupByCol);
    const disableGroup = LLReact.useClientBound(Table.disableGroupByCol);

    const columnMenuItems: WBMenuOption[] = useMemo(() => {
      let menuItems: WBMenuOption[] = [];
      menuItems.push({
        value: 'settings',
        name: 'Column settings',
        icon: 'configuration',
        onSelect: () => openColumnSettings(),
      });
      // menuItems.push({
      //   value: 'filter',
      //   name: 'Filter',
      //   icon: 'filter',
      //   onSelect: openFilter,
      // });
      if (
        !isGroupCol &&
        tableState.groupBy.length === 0 &&
        Types.canGroupType(selectFunction.type)
      ) {
        menuItems.push({
          value: 'group',
          name: 'Group by',
          icon: 'folder',
          onSelect: async () => {
            const newTableState = await enableGroup(
              tableState,
              colId,
              inputArrayNode,
              frame
            );
            recordEvent('GROUP');
            updateTableState(newTableState);
          },
        });
      } else if (isGroupCol) {
        menuItems.push({
          value: 'ungroup',
          name: 'Ungroup',
          icon: 'folder',
          onSelect: async () => {
            const newTableState = await disableGroup(
              tableState,
              colId,
              inputArrayNode,
              frame
            );
            recordEvent('UNGROUP');
            updateTableState(newTableState);
          },
        });
      }
      if (Types.canSortType(selectFunction.type)) {
        menuItems = menuItems.concat(
          makeSortingMenuItems(tableState, colId, updateTableState)
        );
      }
      if (!isGroupCol) {
        if (menuItems.length > 0) {
          menuItems.push(makeMenuItemDivider('insert-div'));
        }
        menuItems = menuItems.concat([
          {
            value: 'insert-right',
            name: 'Insert 1 right',
            icon: 'next',
            onSelect: () => {
              const newTableState = Table.insertColumnRight(
                tableState,
                colId,
                inputArrayNode
              );
              recordEvent('INSERT_COLUMN');
              updateTableState(newTableState);
            },
          },
          {
            value: 'insert-left',
            name: 'Insert 1 left',
            icon: 'previous',
            onSelect: () => {
              const newTableState = Table.insertColumnLeft(
                tableState,
                colId,
                inputArrayNode
              );
              recordEvent('INSERT_COLUMN');
              updateTableState(newTableState);
            },
          },
          makeMenuItemDivider('remove-div'),
          {
            value: 'remove',
            name: 'Remove',
            icon: 'delete',
            onSelect: () => {
              const newTableState = Table.removeColumn(tableState, colId);
              recordEvent('REMOVE_COLUMN');
              updateTableState(newTableState);
            },
          },
          {
            value: 'remove-all-right',
            name: 'Remove all right',
            icon: 'next',
            onSelect: () => {
              const newTableState = Table.removeColumnsToRight(
                tableState,
                colId
              );
              recordEvent('REMOVE_COLUMNS_TO_RIGHT');
              updateTableState(newTableState);
            },
          },
          {
            value: 'remove-all-left',
            name: 'Remove all left',
            icon: 'previous',
            onSelect: () => {
              const newTableState = Table.removeColumnsToLeft(
                tableState,
                colId
              );
              recordEvent('REMOVE_COLUMNS_TO_LEFT');
              updateTableState(newTableState);
            },
          },
        ]);
      }
      return menuItems;
    }, [
      colId,
      isGroupCol,
      enableGroup,
      disableGroup,
      inputArrayNode,
      frame,
      tableState,
      updateTableState,
      selectFunction.type,
      openColumnSettings,
    ]);

    const newContextVars = useMemo(() => {
      // TODO mixing up propsSelectFunction and
      // selectFunction
      return {
        domain: HL.callFunction(propsSelectFunction, {
          row: inputArrayNode,
        }),
      };
    }, [propsSelectFunction, inputArrayNode]);

    return (
      <S.ColumnHeader data-test="column-header">
        <Popup
          basic
          className="wb-table-action-popup"
          on="click"
          open={columnSettingsOpen}
          position="bottom left"
          onOpen={openColumnSettings}
          onClose={(event, data) => {
            const nestedPopupSelector = [INPUT_SLIDER_CLASS, STYLE_POPUP_CLASS]
              .map(c => '.' + c)
              .join(', ');

            const inPopup =
              (event.target as HTMLElement).closest(nestedPopupSelector) !=
              null;

            if (!inPopup) {
              SemanticHacks.withIgnoreBlockedClicks(() => {
                setColumnSettingsOpen(false);
                updateSelectFunction();
              })(event, data);
            }
          }}
          trigger={
            <S.ColumnName
              onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}>
              {isGroupCol && 'Group by ('}
              {columnName !== '' ? (
                columnName
              ) : (
                <ExpressionView.ExpressionView
                  frame={cellFrame}
                  node={propsSelectFunction}
                />
              )}
              {isGroupCol && ')'}
            </S.ColumnName>
          }
          content={
            columnSettingsOpen && (
              <div>
                <S.ColumnEditorSection>
                  <S.ColumnEditorSectionLabel>
                    Cell expression
                  </S.ColumnEditorSectionLabel>
                  <S.AssignmentWrapper>
                    <ExpressionEditor.ExpressionEditor
                      frame={cellFrame}
                      node={selectFunction}
                      updateNode={setSelectFunction}
                      onAccept={updateSelectFunction}
                    />
                  </S.AssignmentWrapper>
                  {/* <div
                    onClick={() =>
                      copyToClipboard(
                        Types.toString(selectFunction.type, false)
                      )
                    }>
                    <div>{Types.toString(selectFunction.type)}</div>
                  </div> */}
                  <S.ColumnEditorColumnName>
                    <S.ColumnEditorFieldLabel>
                      Column name:
                    </S.ColumnEditorFieldLabel>
                    <EditableField
                      value={columnName}
                      placeholder={ExpressionView.simpleNodeString(
                        selectFunction
                      )}
                      save={value => updateColumnName(value as string)}
                    />
                  </S.ColumnEditorColumnName>
                </S.ColumnEditorSection>
                <S.ColumnEditorSection>
                  <S.ColumnEditorSectionLabel>Panel</S.ColumnEditorSectionLabel>
                  <S.PanelNameEditor>
                    <ModifiedDropdown
                      selection
                      search
                      options={stackIds.map(si => ({
                        text: si.displayName,
                        value: si.id,
                      }))}
                      value={curPanelId}
                      onChange={(e, {value}) => updatePanelId(value as string)}
                    />
                  </S.PanelNameEditor>
                  {propsSelectFunction.nodeType !== 'void' &&
                    selectedNode.nodeType !== 'void' &&
                    handler != null && (
                      <S.PanelSettings>
                        <PanelContextProvider newVars={newContextVars}>
                          <PanelComp2
                            input={{path: selectedNode}}
                            inputType={selectFunction.type}
                            loading={false}
                            panelSpec={handler}
                            configMode={true}
                            context={panelContext}
                            config={config}
                            updateConfig={updatePanelConfig}
                            updateContext={updatePanelContext}
                          />
                        </PanelContextProvider>
                      </S.PanelSettings>
                    )}
                </S.ColumnEditorSection>
              </div>
            )
          }
        />
        <div>
          {Types.canSortType(selectFunction.type) && (
            <SortStateToggle
              {...{
                tableState,
                colId,
                updateTableState,
              }}
            />
          )}
          <WBPopupMenuTrigger options={columnMenuItems}>
            {({anchorRef, setOpen, open}) => (
              <S.EllipsisIcon
                className="column-actions-trigger"
                style={{cursor: 'pointer'}}
                name="overflow"
                ref={anchorRef}
                data-test="column-options"
                onClick={() => setOpen(o => !o)}></S.EllipsisIcon>
            )}
          </WBPopupMenuTrigger>
        </div>
      </S.ColumnHeader>
    );
  },
  {id: 'ColumnHeader'}
);

const makeSortingMenuItems = (
  tableState: Table.TableState,
  colId: string,
  updateTableState: (newTableState: Table.TableState) => void
) => {
  const colSortState = tableState.sort.find(
    sort => sort.columnId === colId
  )?.dir;
  const menuItems: WBMenuOption[] = [makeMenuItemDivider('sort-div')];
  if (colSortState !== 'asc') {
    menuItems.push({
      value: 'sort-asc',
      name: 'Sort Asc',
      icon: 'up-arrow',
      onSelect: async () => {
        recordEvent('UPDATE_COLUMN_SORT_ASC');
        const newTableState = Table.enableSortByCol(
          Table.disableSort(tableState),
          colId,
          true
        );
        updateTableState(newTableState);
      },
    });
  }

  if (colSortState !== undefined) {
    menuItems.push({
      value: 'sort-remove',
      name: 'Remove Sort',
      icon: 'delete',
      onSelect: async () => {
        recordEvent('REMOVE_COLUMN_SORT');
        const newTableState = Table.disableSortByCol(tableState, colId);
        updateTableState(newTableState);
      },
    });
  }

  if (colSortState !== 'desc') {
    menuItems.push({
      value: 'sort-desc',
      name: 'Sort Desc',
      icon: 'down-arrow',
      onSelect: async () => {
        recordEvent('UPDATE_COLUMN_SORT_DESC');
        const newTableState = Table.enableSortByCol(
          Table.disableSort(tableState),
          colId,
          false
        );
        updateTableState(newTableState);
      },
    });
  }

  return menuItems;
};

const makeMenuItemDivider = (value: string) => {
  return {
    value,
    disabled: true,
    render: () => (
      <div
        style={{
          marginRight: 12,
          marginLeft: 12,
          borderBottom: '1px solid #888',
        }}
      />
    ),
  };
};

const SortStateToggle: React.FC<{
  tableState: Table.TableState;
  colId: string;
  updateTableState: (newTableState: Table.TableState) => void;
}> = makeComp(
  ({updateTableState, tableState, colId}) => {
    const colSortState = tableState.sort.find(
      sort => sort.columnId === colId
    )?.dir;
    if (colSortState && colSortState === 'desc') {
      return (
        <S.SortedIcon
          name="down-arrow"
          onClick={async e => {
            recordEvent('REMOVE_COLUMN_SORT');
            updateTableState(Table.disableSortByCol(tableState, colId));
          }}
        />
      );
    } else if (colSortState && colSortState === 'asc') {
      return (
        <S.SortedIcon
          name="up-arrow"
          onClick={async e => {
            recordEvent('UPDATE_COLUMN_SORT_DESC');
            updateTableState(
              Table.enableSortByCol(Table.disableSort(tableState), colId, false)
            );
          }}
        />
      );
    } else {
      return <></>;
    }
  },
  {id: 'SortStateToggle'}
);

export const Cell: React.FC<{
  table: Table.TableState;
  inputNode: Types.Node;
  rowNode: Types.Node;
  selectFunction: Types.NodeOrVoidNode;
  colId: string;
  panelId: string;
  config: any;
  panelContext: any;
  updateTableState(newConfig: any): void;
  updatePanelContext(newContext: any): void;
}> = makeComp(
  ({
    table,
    inputNode,
    rowNode,
    selectFunction,
    panelId,
    colId,
    config,
    panelContext,
    updateTableState,
    updatePanelContext,
  }) => {
    const updatePanelConfig = useUpdatePanelConfig(
      updateTableState,
      table,
      colId
    );
    const refineNode = LLReact.useClientBound(HL.refineNode);
    const selectedNode = useMemo(
      () =>
        // TODO: Oof, we have to pass in the whole world, which will force
        // this cell to rerender when any column changes :(
        Table.getCellValueNode(
          rowNode,
          selectFunction,
          table.groupBy,
          table.columnSelectFunctions,
          table.columnNames,
          colId
        ),
      [
        rowNode,
        selectFunction,
        table.groupBy,
        table.columnSelectFunctions,
        table.columnNames,
        colId,
      ]
    );

    const {handler, curPanelId} = usePanelStacksForType(
      selectedNode.type,
      panelId,
      {
        excludeTable: true,
        excludePlot: true,
      }
    );

    // Only render when on screen for the first time. Each time selectedNode
    // changes, this behavior resets.
    const [domRef, shouldRender] = useWhenOnScreenAfterNewValue(selectedNode);

    const updatePanelInput = useCallback<any>(
      (newInput: {path: Types.Node}) => {
        if (selectFunction.nodeType === 'void') {
          throw new Error('invalid');
        }
        if (
          HL.filterNodes(
            newInput.path,
            checkNode =>
              checkNode.nodeType === 'var' && checkNode.varName === 'input'
          ).length === 0
        ) {
          console.warn('invalid updateInput call');
          return;
        }
        const called = HL.callFunction(newInput.path, {input: selectFunction});
        const doUpdate = async () => {
          recordEvent('UPDATE_COLUMN_EXPRESSION_VIA_CELL');
          try {
            const refined = await refineNode(called, {row: rowNode});
            updateTableState(Table.updateColumnSelect(table, colId, refined));
          } catch (e) {
            return Promise.reject(e);
          }
          return Promise.resolve();
        };
        doUpdate().catch(e => {
          console.error('PanelTable error', e);
          throw new Error(e);
        });
      },
      [colId, rowNode, selectFunction, table, updateTableState, refineNode]
    );
    const newContextVars = useMemo(() => {
      return {
        // TODO: This is just plain wrong, callFunction doesn't adjust types!
        domain: HL.callFunction(selectFunction, {row: inputNode}),
      };
    }, [selectFunction, inputNode]);
    return (
      <S.CellWrapper
        ref={domRef}
        data-test-should-render={shouldRender}
        style={{
          ...getPanelStackDims(handler, selectedNode.type, config),
        }}>
        {curPanelId == null ? (
          <div>-</div>
        ) : (
          shouldRender &&
          selectFunction.nodeType !== 'void' &&
          selectedNode.nodeType !== 'void' &&
          handler != null && (
            <PanelContextProvider
              // Make a new variable "domain" available to child cells.
              // This can be used to get the full range of the input data.
              newVars={newContextVars}>
              <PanelComp2
                input={{path: selectedNode}}
                inputType={selectFunction.type}
                loading={false}
                panelSpec={handler}
                configMode={false}
                context={panelContext}
                config={config}
                updateConfig={updatePanelConfig}
                updateContext={updatePanelContext}
                updateInput={updatePanelInput}
              />
            </PanelContextProvider>
          )
        )}
      </S.CellWrapper>
    );
  },
  {id: 'PanelTableCell'}
);

export const Value: React.FC<{
  table: Table.TableState;
  valueNode: Types.Node;
  config: any;
  panelContext: any;
  colId: string;
  updateTableState(newConfig: any): void;
  updatePanelContext(newContext: any): void;
}> = makeComp(
  ({
    table,
    valueNode,
    config,
    panelContext,
    colId,
    updateTableState,
    updatePanelContext,
  }) => {
    const updatePanelConfig = useUpdatePanelConfig(
      updateTableState,
      table,
      colId
    );
    const {handler, curPanelId} = usePanelStacksForType(valueNode.type, '', {
      excludeTable: true,
      excludePlot: true,
    });

    return (
      <>
        {curPanelId == null ? (
          <div>No panel for type {Types.toString(valueNode.type)}</div>
        ) : (
          handler != null && (
            <PanelComp2
              input={{path: valueNode}}
              inputType={valueNode.type}
              loading={false}
              panelSpec={handler}
              configMode={false}
              context={panelContext}
              config={config}
              updateConfig={updatePanelConfig}
              updateContext={updatePanelContext}
            />
          )
        )}
      </>
    );
  },
  {id: 'Value'}
);

// export const RowIndexTag: React.FC<{
//   rowNode: Types.Node;
// }> = makeComp(
//   ({rowNode}) => {
//     const indexVal = LLReact.useNodeValue(Op.opTableRowIndex({obj: rowNode}))
//     if (indexVal.loading) {
//       return <div>-</div>
//     }

//     return <div>{indexVal.result}</div>;
//   },
//   {id: 'RowIndexTag'}
// );

export type PanelTableConfig = {
  tableState?: Table.TableState;
  tableStateInputType?: Types.Type;
};

const migrateConfig = (config: any): PanelTableConfig | undefined => {
  let mConfig = config;
  if (mConfig?.combinedTableConfig != null && mConfig?.columns == null) {
    mConfig = {
      ...mConfig,
      ...mConfig.combinedTableConfig,
    };
  }
  if (
    mConfig != null &&
    mConfig.tableState == null &&
    mConfig.tableStateInputType == null
  ) {
    mConfig = {
      // ...mConfig,
      tableState: {...mConfig},
    };
    if ((mConfig.tableState.order ?? []).length > 0) {
      let exampleSelect: Types.Node;
      if ((mConfig.tableState.groupBy ?? []).length > 0) {
        exampleSelect =
          mConfig.tableState.columnSelectFunctions[
            mConfig.tableState.groupBy[0]
          ];
      } else {
        exampleSelect =
          mConfig.tableState.columnSelectFunctions[mConfig.tableState.order[0]];
      }
      const exampleRowNode = HL.findChainedAncestor(
        exampleSelect,
        (inNode: Types.Node) => {
          return inNode.nodeType === 'var' && inNode.varName === 'row';
        },
        () => true
      );
      if (exampleRowNode != null) {
        mConfig.tableStateInputType = Types.list(exampleRowNode.type);
      }
    }
  }
  return mConfig;
};

const useMigratedConfig = (config: any): PanelTableConfig | undefined => {
  return useMemo(() => {
    // One time migration from older table panels that did merging at the type level
    return migrateConfig(config);
  }, [config]);
};

const stripTag = (type: Types.Type): Types.Type => {
  return Types.isTaggedValue(type) ? Types.taggedValueValueType(type) : type;
};

// Very simple type shape comparison
const typeShapesMatch = (type: Types.Type, toType: Types.Type): boolean => {
  type = stripTag(type);
  toType = stripTag(type);
  if (Types.isList(type)) {
    if (!Types.isList(toType)) {
      return false;
    } else {
      return typeShapesMatch(
        Types.listObjectType(type),
        Types.listObjectType(toType)
      );
    }
  } else if (Types.isTypedDict(type)) {
    if (!Types.isTypedDict(toType)) {
      return false;
    } else {
      for (const key of Object.keys(toType.propertyTypes)) {
        const toKeyType = toType.propertyTypes[key]!;
        const keyType = type.propertyTypes[key];
        if (keyType === undefined || !typeShapesMatch(keyType, toKeyType)) {
          return false;
        }
      }
    }
  }
  return true;
};

const PanelTable: React.FC<
  Panel2.PanelProps<typeof inputType, PanelTableConfig>
> = props => {
  const {input, config, updateConfig} = props;
  const mConfig = useMigratedConfig(config);
  const inputNode = useMemo(
    () => TableType.normalizeTableLike(input.path),
    [input.path]
  );
  const typedInputNode = LLReact.useNodeWithServerType(inputNode);
  const configNeedsReset = useMemo(() => {
    if (mConfig?.tableStateInputType == null || typedInputNode.loading) {
      return false;
    } else {
      return !typeShapesMatch(
        typedInputNode.result.type,
        mConfig?.tableStateInputType
      );
    }
  }, [typedInputNode, mConfig]);
  const usableTableConfig: PanelTableConfig['tableState'] = configNeedsReset
    ? undefined
    : mConfig?.tableState;

  const updateTableStateConfig = useCallback(
    (tableStateConfig: any) => {
      updateConfig({
        tableState: {
          ...(usableTableConfig ?? {}),
          ...tableStateConfig,
        },
        tableStateInputType: typedInputNode.result.type,
      });
    },
    [updateConfig, usableTableConfig, typedInputNode.result.type]
  );

  if (typedInputNode.loading) {
    return <WandbLoader />;
  } else {
    if (
      typedInputNode.result.nodeType === 'void' ||
      !Types.isListLike(typedInputNode.result.type) ||
      Types.listObjectType(typedInputNode.result.type) === 'invalid'
    ) {
      return <></>;
    }
    return (
      <PanelTableInner
        {...props}
        config={usableTableConfig}
        updateConfig={updateTableStateConfig}
        input={{path: typedInputNode.result as any}}
      />
    );
  }
};

const PanelTableInner: React.FC<
  Panel2.PanelProps<typeof inputType, PanelTableConfig['tableState']>
> = props => {
  const {input, updateConfig, updateContext} = props;
  const inputNode = input.path;

  const initTable = Table.initTableWithAllColumnsFromTableType;

  useEffect(() => {
    recordEvent('VIEW');
  }, []);

  const preFilterFrame = useMemo(
    () => Table.getInputRowFrame(inputNode, {}),
    [inputNode]
  );

  // The table that we would auto-generate from the input node.
  const autoTable = useMemo(() => initTable(inputNode), [inputNode, initTable]);

  const propsDiff = Table.tableColumnsDiff(autoTable, props.config);
  const autoDiffersFromProps =
    propsDiff.addedCols.length > 0 || propsDiff.removedCols.length > 0;
  // Select autoTable if uninitialized or autoColumns mode is on.
  // Otherwise, select the configured table.
  let config =
    props.config == null ||
    props.config.columnNames == null ||
    (props.config.autoColumns && autoDiffersFromProps)
      ? autoTable
      : props.config;

  config = useTableStateWithRefinedExpressions(inputNode, config);

  // Is the auto table different from our currently configured table?
  // Leaving this here in case we want to bring it back
  // const {addedCols, removedCols} = useMemo(
  //   () => Table.tableColumnsDiff(autoTable, config),
  //   [autoTable, config]
  // );

  const rowsNode = useMemo(
    () =>
      Table.getRowsNode(
        config.preFilterFunction,
        config.groupBy,
        config.columnSelectFunctions,
        config.columnNames,
        config.order,
        config.sort,
        inputNode
      ),
    [
      config.preFilterFunction,
      config.groupBy,
      config.columnSelectFunctions,
      config.columnNames,
      config.order,
      config.sort,
      inputNode,
    ]
  );

  const visibleRowsNode = useMemo(
    () => Table.getPagedRowsNode(config.pageSize, config.page, rowsNode),
    [config.pageSize, config.page, rowsNode]
  );

  const rowNodesUse = LLReact.useEach(visibleRowsNode as any);

  const rowNodes = useMemo(
    () => (rowNodesUse.loading ? [] : rowNodesUse.result),
    [rowNodesUse.loading, rowNodesUse.result]
  );

  const orderedColumns = config.groupBy.concat(
    Table.getColumnRenderOrder(config)
  );
  const shouldAddIndex = false;
  // const shouldAddIndex = config.groupBy.length === 0;
  const headerItemStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  };
  const headerFrozenItemStyle = {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    borderRight: '1px solid rgba(34,36,38,.1)',
    zIndex: 6,
  };
  const cellItemStyle = {
    maxWidth: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '300px',
  };
  const cellFrozenItemStyle = {
    maxWidth: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '300px',
    position: 'sticky',
    left: 0,
    zIndex: 5,
    background: globals.gray50,
    borderRight: '1px solid rgba(34,36,38,.1)',
  };

  let gridTemplateColumns = config.groupBy.length > 0 ? 'min-content' : '';
  // let gridTemplateColumns = 'minmax(min-content, min-content)';
  for (let i = 0; i < orderedColumns.length - config.groupBy.length; i++) {
    gridTemplateColumns += ' minmax(max-content, 5fr)';
  }
  let gridTemplateRows = 'min-content';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const rowNode of rowNodes) {
    gridTemplateRows += ' min-content';
  }

  const tableIsDefault = useMemo(() => {
    return Table.equalStates(autoTable, props.config) || props.config == null;
  }, [autoTable, props.config]);

  // Only rerender this when not loading
  const content = useGatedValue(
    rowNodesUse.loading ? (
      <WandbLoader />
    ) : config.order.length === 0 ? (
      <Button
        onClick={() => {
          recordEvent('INSERT_COLUMN');
          return updateConfig(Table.appendColumn(config, inputNode));
        }}>
        Add Column
      </Button>
    ) : (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <ControlFilter
              frame={preFilterFrame}
              filterFunction={config.preFilterFunction}
              setFilterFunction={newNode => {
                if (config.preFilterFunction !== newNode) {
                  recordEvent('UPDATE_FILTER_EXPRESSION');
                }
                return updateConfig(Table.updatePreFilter(config, newNode));
              }}
            />
            <Button
              data-test="auto-columns"
              disabled={tableIsDefault && config.autoColumns}
              onClick={() => {
                recordEvent('RESET_COLUMNS');
                updateConfig(autoTable);
              }}
              style={{marginTop: 12}}
              size="tiny">
              {tableIsDefault && config.autoColumns
                ? 'Columns Automated'
                : 'Reset & Automate Columns'}
            </Button>
          </div>
          {/* <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginLeft: 16}}>Auto columns</div>
            <Checkbox
              toggle
              style={{marginLeft: 8}}
              checked={config.autoColumns}
              name="auto-columns"
              data-test="auto-columns"
              onChange={(e, value) => {
                recordEvent('ENABLED_AUTO_COLUMNS');
                updateConfig(
                  Table.setAutoColumns(config, value.checked as boolean)
                );
              }}
            />
            {(removedCols.length > 0 || addedCols.length) > 0 && (
              <div
                style={{
                  marginLeft: 8,
                  backgroundColor: '#f7d673',
                  padding: '0 4px',
                }}>
                [-{removedCols.length} / +{addedCols.length}]
              </div>
            )}
          </div> */}
          {rowsNode != null && (
            <div style={{marginTop: 12}}>
              <PageControls
                rowsNode={rowsNode}
                page={config.page}
                pageSize={config.pageSize}
                setPage={page => updateConfig(Table.setPage(config, page))}
              />
            </div>
          )}
        </div>
        <STable
          data-test="wb_table"
          style={{
            display: 'grid',
            overflow: 'auto',
            gridTemplateColumns,
            gridTemplateRows,
            marginTop: '12px',
          }}>
          {/* display: contents makes css grid ignore these items,
            we could just get rid of the table markup entirely and
            used divs instead so we don't need these */}
          <STable.Header style={{display: 'contents'}}>
            <STable.Row style={{display: 'contents'}}>
              {shouldAddIndex && (
                <STable.HeaderCell style={headerFrozenItemStyle}>
                  {' '}
                </STable.HeaderCell>
              )}
              {orderedColumns.map((colId, colIndex) => {
                const isStickyCol = colIndex < config.groupBy.length;
                const style = isStickyCol
                  ? headerFrozenItemStyle
                  : headerItemStyle;
                return (
                  <STable.HeaderCell key={colId} style={style}>
                    <ColumnHeader
                      isGroupCol={colIndex < config.groupBy.length}
                      tableState={config}
                      inputArrayNode={inputNode}
                      rowsNode={rowsNode}
                      columnName={config.columnNames[colId]}
                      selectFunction={config.columnSelectFunctions[colId]}
                      colId={colId}
                      panelId={config.columns[colId].panelId}
                      config={config.columns[colId].panelConfig}
                      panelContext={props.context}
                      updatePanelContext={updateContext}
                      updateTableState={props.updateConfig}
                      updateColumnName={newName => {
                        if (newName !== config.columnNames[colId]) {
                          recordEvent('UPDATE_COLUMN_NAME');
                        }
                        return updateConfig(
                          Table.updateColumnName(config, colId, newName)
                        );
                      }}
                      updateSelectFunction={newNode => {
                        if (newNode !== config.columnSelectFunctions[colId]) {
                          recordEvent('UPDATE_COLUMN_EXPRESSION');
                        }
                        return updateConfig(
                          Table.updateColumnSelect(config, colId, newNode)
                        );
                      }}
                      updatePanelId={newPanelId => {
                        if (newPanelId !== config.columns[colId].panelId) {
                          recordEvent('UPDATE_COLUMN_PANEL');
                        }
                        return updateConfig(
                          Table.updateColumnPanelId(config, colId, newPanelId)
                        );
                      }}
                    />
                  </STable.HeaderCell>
                );
              })}
            </STable.Row>
          </STable.Header>
          <STable.Body style={{display: 'contents'}}>
            {rowNodes.map((row, rowIndex) => (
              <STable.Row key={rowIndex} style={{display: 'contents'}}>
                {shouldAddIndex && (
                  <STable.Cell style={cellFrozenItemStyle}>
                    {/* {Types.isTaggedValue(row.type) ? (
                      <RowIndexTag rowNode={row}></RowIndexTag>
                    ) : (
                      <div>-</div>
                    )} */}
                  </STable.Cell>
                )}
                {config.groupBy.length > 0 &&
                  config.groupBy.map(groupColId => (
                    <STable.Cell
                      // override a style that we set in semantic that shouldn't apply here
                      // .file-browser td {max-width}
                      style={cellFrozenItemStyle}
                      key="group-col">
                      <Value
                        table={config}
                        colId={groupColId}
                        // Warning: not memoized
                        valueNode={Op.opPick({
                          obj: Op.opGroupGroupKey({
                            obj: rowNodesUse.result[rowIndex] as any,
                          }),
                          key: Op.constString(
                            Op.escapeDots(
                              Table.getTableColumnName(
                                config.columnNames,
                                config.columnSelectFunctions,
                                groupColId
                              )
                            )
                          ),
                        })}
                        config={{}}
                        updateTableState={props.updateConfig}
                        panelContext={props.context}
                        updatePanelContext={updateContext}
                      />
                    </STable.Cell>
                  ))}
                {Table.getColumnRenderOrder(config).map(colId => {
                  return (
                    <STable.Cell
                      // override a style that we set in semantic that shouldn't apply here
                      // .file-browser td {max-width}
                      style={cellItemStyle}
                      key={colId}>
                      <Cell
                        table={config}
                        colId={colId}
                        inputNode={inputNode}
                        rowNode={row}
                        selectFunction={config.columnSelectFunctions[colId]}
                        panelId={config.columns[colId].panelId}
                        config={config.columns[colId].panelConfig}
                        panelContext={props.context}
                        updateTableState={updateConfig}
                        updatePanelContext={updateContext}
                      />
                    </STable.Cell>
                  );
                })}
              </STable.Row>
            ))}
          </STable.Body>
        </STable>
      </div>
    ),
    o => !rowNodesUse.loading
  );

  return (
    <div
      data-test="panel-table-2-wrapper"
      style={{height: '100%', width: '100%'}}
      className={rowNodesUse.loading ? 'loading' : ''}>
      {content}
    </div>
  );
};

export const TableSpec: Panel2.PanelSpec = {
  id: 'table',
  Component: PanelTable,
  inputType,
  equivalentTransform: async (inputNode, config, refineType) => {
    const mConfig = migrateConfig(config);
    const typedInputNode = await refineType(
      TableType.normalizeTableLike(inputNode as any)
    );
    const configNeedsReset = (() => {
      if (mConfig?.tableStateInputType == null) {
        return false;
      } else {
        return !typeShapesMatch(
          typedInputNode.type,
          mConfig?.tableStateInputType
        );
      }
    })();
    const usableTableConfig: PanelTableConfig['tableState'] = configNeedsReset
      ? undefined
      : mConfig?.tableState;

    const initTable = Table.initTableWithAllColumnsFromTableType;

    const autoTable = initTable(typedInputNode as any);
    const propsDiff = Table.tableColumnsDiff(autoTable, usableTableConfig);
    const autoDiffersFromProps =
      propsDiff.addedCols.length > 0 || propsDiff.removedCols.length > 0;
    const finalConfig =
      usableTableConfig == null ||
      usableTableConfig.columnNames == null ||
      (usableTableConfig.autoColumns && autoDiffersFromProps)
        ? autoTable
        : usableTableConfig;

    let rowsNode = Table.getRowsNode(
      finalConfig.preFilterFunction,
      finalConfig.groupBy,
      finalConfig.columnSelectFunctions,
      finalConfig.columnNames,
      finalConfig.order,
      finalConfig.sort,
      typedInputNode as any
    );

    const colOrder = [
      ...finalConfig.groupBy,
      ...finalConfig.order.filter(id => finalConfig.groupBy.indexOf(id) === -1),
    ];

    // Perform the select
    rowsNode = Op.opMap({
      arr: rowsNode,
      mapFn: Op.defineFunction(
        {
          row: Op.opIndex({arr: rowsNode, index: Op.constNumber(0)}).type,
        },
        ({row}) => {
          return Op.opDict(
            _.fromPairs(
              _.map(colOrder, colId => {
                const colName = Op.escapeDots(
                  Table.getTableColumnName(
                    finalConfig.columnNames,
                    finalConfig.columnSelectFunctions,
                    colId
                  )
                );
                let valueNode: Types.NodeOrVoidNode;
                if (finalConfig.groupBy.indexOf(colId) > -1) {
                  valueNode = Op.opPick({
                    obj: Op.opGroupGroupKey({
                      obj: row,
                    }),
                    key: Op.constString(colName),
                  });
                } else {
                  valueNode = Table.getCellValueNode(
                    row,
                    finalConfig.columnSelectFunctions[colId],
                    finalConfig.groupBy,
                    finalConfig.columnSelectFunctions,
                    finalConfig.columnNames,
                    colId
                  );
                }
                return [colName, valueNode];
              })
            ) as any
          );
        }
      ),
    });

    return rowsNode;
  },
};

Panel2.registerPanelFunction(
  TableSpec.id,
  TableSpec.inputType,
  TableSpec.equivalentTransform!
);
