import React, {useCallback} from 'react';
import {ErrorBoundary} from './ErrorBoundary';
import {captureError} from '../util/integrations';
import errorImage from '../assets/il-question-plant.svg';
import makeComp from '../util/profiler';

interface ReportErrorBoundaryProps {
  params: {
    entityName: string;
    projectName?: string;
    reportNameAndID: string;
  };
  editPage?: boolean;
}

const ReportErrorBoundary: React.FC<ReportErrorBoundaryProps> = makeComp(
  ({params, editPage = false, children}) => {
    const logError = useCallback(
      error => {
        const key = editPage ? 'ReportPageEdit' : 'ReportPageView';
        window.analytics.track(`${key} error`, {
          params,
          errorMessage: error.message,
          errorName: error.name,
          errorStack: error.stack,
        });
        captureError(error, `${key} ErrorBoundary error`);
      },
      [params, editPage]
    );

    return (
      <ErrorBoundary renderError={renderReportError} onError={logError}>
        {children}
      </ErrorBoundary>
    );
  },
  {id: 'ReportErrorBoundary', memo: true}
);

function renderReportError() {
  return (
    <div className="report-page-error">
      <div className="report-page-error-content">
        <img
          className="report-page-error-image"
          src={errorImage}
          alt="confused plant"
        />
        <div className="report-page-error-text">
          <div className="report-page-error-header">
            Oops, something went wrong.
          </div>
          If this keeps happening, email us at support@wandb.com with this page
          link.
        </div>
      </div>
    </div>
  );
}

export default ReportErrorBoundary;
