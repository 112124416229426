import {createAction} from 'typesafe-actions';
import * as SMUtil from '../../../util/selectionmanager';
import * as Run from '../../../util/runs';

import * as Types from './types';

export const setGrouping = createAction(
  '@view/groupSelections/setGrouping',
  action => (ref: Types.Ref, grouping: Types.Grouping) =>
    action({ref, grouping})
);

export const toggleExpandedRowAddress = createAction(
  '@view/groupSelections/toggleExpandedRowAddress',
  action =>
    (
      ref: Types.Ref,
      rowAddress: Types.GroupSelections['expandedRowAddresses'][number]
    ) =>
      action({ref, rowAddress})
);

export const toggleSelection = createAction(
  '@view/groupSelections/toggleSelection',
  action => (ref: Types.Ref, run: Run.Run, depth: number) =>
    action({ref, run, depth})
);

export const addBound = createAction(
  '@view/groupSelections/addBound',
  action => (ref: Types.Ref, bound: SMUtil.SelectionBound) =>
    action({ref, bound})
);

export const selectAll = createAction(
  '@view/groupSelections/selectAll',
  action => (ref: Types.Ref) => action({ref})
);

export const selectNone = createAction(
  '@view/groupSelections/selectNone',
  action => (ref: Types.Ref) => action({ref})
);
