import * as React from 'react';

import './AnnotateScreenshot.less';
import makeComp from '../util/profiler';

interface TripleChevron {
  type: 'triple-chevron';
  x: number | string;
  y: number | string;
  color: 'blue';
}

interface Rectangle {
  type: 'rectangle';
  rounded: number;
  x: number | string;
  y: number | string;
  width: number | string;
  height: number | string;
  borderWidth?: number;
  borderColor?: 'blue';
  borderStyle?: string;
  glow?: boolean;
}

type Mark = TripleChevron | Rectangle;

interface AnnotateScreenshotProps {
  marks: Mark[];
  children: any;
}

const makeMark = (mark: Mark) => {
  if (mark.type === 'rectangle') {
    const colorClass =
      'blue' === mark.borderColor ? 'annotation__rectangle--blue' : '';

    return (
      <div
        style={{
          left: mark.x,
          top: mark.y,
          width: mark.width,
          height: mark.height,
          borderRadius: mark.rounded,
          borderWidth: mark.borderWidth || 5,
          borderStyle: mark.borderStyle || 'solid',
        }}
        className={`annotation__rectangle ${colorClass}`}
      />
    );
  } else if (mark.type === 'triple-chevron') {
    const colorString = 'annotation__chevron--' + mark.color;

    return (
      <div
        className={`annotation__triple-chevron ${colorString}`}
        style={{
          left: mark.x,
          top: mark.y,
        }}>
        <div className="annotation__chevron-mark" />
        <div className="annotation__chevron-mark" />
        <div className="annotation__chevron-mark" />
      </div>
    );
  } else {
    throw new Error('Mark type unsupported');
  }
};

export const AnnotateScreenshot = makeComp(
  (props: AnnotateScreenshotProps) => {
    return (
      <div style={{position: 'relative'}}>
        {props.marks.map(makeMark)}
        {props.children}
      </div>
    );
  },
  {id: 'AnnotateScreenshot'}
);
