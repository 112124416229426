import React, {useMemo} from 'react';

import {useProjectFieldsQuery} from '../../state/graphql/projectFieldsQuery';
import {useDebounceState} from '../../util/hooks';
import * as RunFeed from '../../util/runfeed';
import * as Run from '../../util/runs';
import TableEditor from '../TableEditor';
import {useRunsQueryContext} from '../../state/runs/hooks';
import {
  DEFAULT_RUNSELECTOR_KEYS,
  DEFAULT_SWEEP_EARLY_TERMINATE_KEYS,
  DEFAULT_SWEEP_KEYS,
} from '../../util/tablecols';
import makeComp from '../../util/profiler';

interface RunsColumnEditorProps {
  entityName: string;
  projectName: string;

  config: RunFeed.Config;
  fixedColumns?: string[];

  update(config: RunFeed.Config): void;
}

export const RunsColumnEditor = makeComp(
  (props: RunsColumnEditorProps) => {
    const [search, searchDebounced, setSearch] = useDebounceState('', 200);

    const pattern = useMemo(() => {
      if (searchDebounced === '') {
        return null;
      }
      return '%' + searchDebounced.split('').join('%') + '%';
    }, [searchDebounced]);

    // strictly limit the query count when a user is searching so that the load isn't too much
    const queryCount = useMemo(
      () => (pattern == null ? 10000 : 200),
      [pattern]
    );

    const projectFieldsData = useProjectFieldsQuery({
      entityName: props.entityName,
      projectName: props.projectName,
      types: ['string', 'number', 'boolean', 'other'],
      columns: ['config', 'summary_metrics'],
      count: queryCount,
      pattern,
    });

    // These keys have broken behavior when they're not filtered out.
    // This filtering seems to be duplicated in other places.
    // Someone smarter than me should refactor the way we handle special keys.
    const keys = projectFieldsData.keys.filter(
      key =>
        !(
          key === 'config:wandb_version' ||
          key === 'summary:_runtime' ||
          key === 'summary:_step' ||
          key === 'summary:_timestamp'
        )
    );

    const {sweep} = useRunsQueryContext();
    const defaultKeys =
      sweep == null
        ? DEFAULT_RUNSELECTOR_KEYS
        : sweep.earlyTerminate
        ? DEFAULT_SWEEP_EARLY_TERMINATE_KEYS
        : DEFAULT_SWEEP_KEYS;

    const allColumns = useMemo(
      () => [...defaultKeys.map(k => Run.keyToString(k)), ...keys],
      [keys, defaultKeys]
    );

    return (
      <TableEditor
        config={props.config}
        allColumns={allColumns}
        fixedColumns={props.fixedColumns}
        searchQuery={search}
        searchLoading={projectFieldsData.loading}
        setSearch={setSearch}
        update={props.update}
      />
    );
  },
  {id: 'RunsColumnEditor'}
);
