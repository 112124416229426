import * as React from 'react';
import {
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Loader,
  Popup,
} from 'semantic-ui-react';
import {backendHost} from '../config';
import {
  RunsData,
  RunsDataQuery,
  toRunsDataQuery,
} from '../containers/RunsDataLoader';
import '../css/PanelConfusionMatrix.less';
import * as DataFrameQuery from '../graphql/dataFrameQuery';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import {displayValue} from '../util/runhelpers';
import PanelError from './elements/PanelError';

import docUrl from '../util/doc_urls';
import {TargetBlank} from '../util/links';

const PANEL_TYPE = 'Confusion Matrix';

function availableKeys(data: RunsData): string[] {
  let available: string[] = [];

  if (!data.loading && data.filtered.length > 0 && data.filtered[0].summary) {
    available = Object.keys(data.filtered[0].summary).filter(
      k =>
        data.filtered[0].summary[k] &&
        (data.filtered[0].summary[k] as any)._type === 'data-frame'
    );
  }

  return available;
}

function availableColumns(schema: any): string[] {
  return schema.map((c: any) => c.Name as string);
}

const white = [0xff, 0xff, 0xff];
const blue = [0x81, 0xc0, 0xd7];

function cellColor(x: number, left: number[], right: number[]): string {
  // tslint:disable-next-line:no-bitwise
  const color = left.map((l, i) => (l * (1 - x) + right[i] * x) | 0);

  return `rgb(${color.join(',')})`;
}

function parseValue(v: any): any {
  if (typeof v === 'string' && v[0] === '{') {
    try {
      v = JSON.parse(v);
    } catch {
      // ignore
    }
  }
  return v;
}

function isValueImage(v: any): boolean {
  return v && (v._type === 'image' || v._type === 'image-file');
}
function entityName(pageQuery: any): string {
  return (pageQuery && pageQuery.entityName) || '';
}

function projectName(pageQuery: any): string {
  return (pageQuery && (pageQuery.projectName || pageQuery.model)) || '';
}

function runName(pageQuery: any): string {
  return (pageQuery && pageQuery.runName) || '';
}

function imageSrc(pageQuery: any, image: any): string | undefined {
  if (!image || !image.path) {
    return undefined;
  }
  return `${backendHost()}/files/${entityName(pageQuery)}/${projectName(
    pageQuery
  )}/${runName(pageQuery)}/${image.path}`;
}

export interface ConfusionMatrixConfig {
  dataFrameKey?: string;
  trueClassColumn?: string;
  predictedClassColumn?: string;
  popupColumn?: string;
  normalizeClasses?: boolean;
}

function configReady(config: ConfusionMatrixConfig): boolean {
  return (
    (config.dataFrameKey &&
      config.trueClassColumn &&
      config.predictedClassColumn &&
      config.trueClassColumn !== config.predictedClassColumn) ||
    false
  );
}

type ConfusionMatrixPanelProps = Panels.PanelProps<ConfusionMatrixConfig>;

type ConfusionMatrixPanelInnerProps = ConfusionMatrixPanelProps &
  DataFrameQuery.QueryResultProps;

class ConfusionMatrixPanelInner extends React.Component<ConfusionMatrixPanelInnerProps> {
  static type = 'Confusion Matrix';
  static options = {};

  static transformQuery(
    query: Query.Query,
    config: ConfusionMatrixConfig
  ): RunsDataQuery {
    const result = toRunsDataQuery(query, undefined, {fullSummary: true});
    return result;
  }

  renderConfig() {
    const {config, data, updateConfig, dataFrameQuery} = this.props;
    const keyOptions = availableKeys(data).map(k => ({
      text: k,
      value: k,
    }));
    let columnOptions: DropdownItemProps[] = [];
    if (dataFrameQuery.dataFrameSchema) {
      columnOptions = availableColumns(dataFrameQuery.dataFrameSchema[0]).map(
        k => ({
          text: k,
          value: k,
        })
      );
    }
    return (
      <div className="chart-modal">
        <div className="chart-preview"> {this.renderNormal()} </div>
        <div className="chart-settings">
          <div className="panel-confusion-matrix--config">
            <label>Data Frame</label>
            <Dropdown
              options={keyOptions}
              fluid
              selection
              value={
                config.dataFrameKey || (keyOptions[0] && keyOptions[0].value)
              }
              onChange={(_, {value}) =>
                updateConfig({dataFrameKey: value as string})
              }
            />
          </div>
          <div className="panel-confusion-matrix--config">
            <label>True class column</label>
            <Dropdown
              options={columnOptions}
              fluid
              selection
              value={config.trueClassColumn}
              onChange={(_, {value}) =>
                updateConfig({trueClassColumn: value as string})
              }
            />
          </div>
          <div className="panel-confusion-matrix--config">
            <label>Predicted class column</label>
            <Dropdown
              options={columnOptions}
              fluid
              selection
              value={config.predictedClassColumn}
              onChange={(_, {value}) =>
                updateConfig({predictedClassColumn: value as string})
              }
            />
          </div>
          <div className="panel-confusion-matrix--config">
            <label>Popup column</label>
            <Dropdown
              options={columnOptions.concat({text: 'None', value: 'null'})}
              fluid
              selection
              value={config.popupColumn || 'null'}
              onChange={(_, {value}) =>
                updateConfig({popupColumn: value as string})
              }
            />
          </div>
          <div className="panel-confusion-matrix--config">
            <Checkbox
              label="Normalize classes"
              checked={config.normalizeClasses || false}
              toggle
              onChange={(_, {checked}) =>
                updateConfig({normalizeClasses: checked})
              }
            />
          </div>
        </div>
      </div>
    );
  }

  renderNormal() {
    const {config, configMode, data, dataFrameQuery} = this.props;
    if (data.initialLoading || dataFrameQuery.loading) {
      return <Loader active />;
    }

    if (!configReady(config)) {
      return (
        <PanelError
          message={
            <div>
              <p>This panel is not configured.</p>
              <p>
                Select a Data Frame, True Class Column, and Predicted Class
                Column to view the confusion matrix.
              </p>
            </div>
          }
        />
      );
    }
    if (!dataFrameQuery.dataFrame) {
      return (
        <PanelError
          message={
            <div>
              <p>This run doesn't have data frame data.</p>
              <p>
                Your run most likely crashed before logging data frame:{' '}
                <b>{config.dataFrameKey}</b>
              </p>
              <p>
                Read more in our{' '}
                <TargetBlank href={docUrl.loggingObjects}>
                  Documentation
                </TargetBlank>
              </p>
            </div>
          }
        />
      );
    }

    const rows = dataFrameQuery.dataFrame.edges.map(e => e.node.row);

    const allClasses: {[key: string]: boolean} = {};
    rows.forEach(r => {
      if (r[0] != null) {
        allClasses[r[0]] = true;
      }
      if (r[1] != null) {
        allClasses[r[1]] = true;
      }
    });

    const popupIndex = availableColumns(
      dataFrameQuery.dataFrame.schema as string
    ).findIndex(c => c === config.popupColumn);

    // if they select something that is not unique enough for their dataset,
    // don't render 10 million table cells. limit it further in the popup
    const maxClasses = configMode ? 3 : 100;
    let classes = Object.keys(allClasses).sort();
    let more = false;
    if (classes.length > maxClasses) {
      classes = classes.slice(0, maxClasses);
      more = true;
    }
    const classIndices: {[key: string]: number} = {};
    classes.forEach((c, i) => {
      classIndices[c] = i;
    });

    const cells: {[key: string]: number} = {};
    const popups: {[key: string]: string} = {};
    const trueCounts: {[key: string]: number} = {};
    const predCounts: {[key: string]: number} = {};

    // Build up maps for each confusion matrix cell, aggregates, and popups
    rows.forEach(r => {
      // columns 0 and 1 are predictedClass and trueClass
      if (r[0] != null && r[1] != null) {
        cells[r[0] + ',' + r[1]] = r[2];
        predCounts[r[0]] = (predCounts[r[0]] || 0) + r[2];
        trueCounts[r[1]] = (trueCounts[r[1]] || 0) + r[2];
        if (popupIndex !== -1) {
          popups[r[0] + ',' + r[1]] = r[popupIndex];
        }
      }
    });

    const percent = (num: number, den: number): string => {
      if (!den) {
        return '0%';
      }
      return Math.round(((num || 0) / den) * 100) + '%';
    };

    return (
      <div className="panel-confusion-matrix">
        <table>
          <tbody>
            <tr>
              <th colSpan={2} rowSpan={2} />
              <th className="panel-confusion-matrix--summary" />
              <th colSpan={classes.length}> Predicted Class </th>
              {more && <th />}
            </tr>
            <tr>
              <th className="panel-confusion-matrix--summary panel-confusion-matrix--top">
                <div className="panel-confusion-matrix--header panel-confusion-matrix--rotated">
                  Recall
                </div>
              </th>
              {classes.map(cls => (
                <th key={cls} className="panel-confusion-matrix--top">
                  <div className="panel-confusion-matrix--header panel-confusion-matrix--rotated">
                    {cls}
                  </div>
                </th>
              ))}
              {more && (
                <th className="panel-confusion-matrix--more-top">
                  <div className="panel-confusion-matrix--header panel-confusion-matrix--rotated">
                    . . .
                  </div>
                </th>
              )}
            </tr>
            <tr>
              <th className="panel-confusion-matrix--summary" />
              <th className="panel-confusion-matrix--left panel-confusion-matrix--summary">
                Precision
              </th>
              <td className="panel-confusion-matrix--summary" />
              {classes.map(cls => (
                <td key={cls} className="panel-confusion-matrix--summary">
                  {percent(cells[cls + ',' + cls], predCounts[cls])}
                </td>
              ))}
              {more && <th rowSpan={classes.length + 1} />}
            </tr>
            {classes.map((cls, index) => (
              <tr key={cls}>
                {index === 0 && (
                  <th rowSpan={classes.length + 1}>
                    <div className="panel-confusion-matrix--rotated">
                      True Class
                    </div>
                  </th>
                )}
                <th className="panel-confusion-matrix--left">{cls}</th>
                <td className="panel-confusion-matrix--summary">
                  {percent(cells[cls + ',' + cls], trueCounts[cls])}
                </td>
                {classes.map(cls2 => {
                  const cell = cells[cls2 + ',' + cls];
                  const cellPopup = popups[cls2 + ',' + cls];
                  let contents: string | number = '-';
                  let background = cellColor(0.0, white, blue);
                  if (cell && trueCounts[cls]) {
                    background = cellColor(cell / trueCounts[cls], white, blue);
                  }
                  if (config.normalizeClasses) {
                    contents = percent(cell, trueCounts[cls]);
                  } else {
                    contents = cell || 0;
                  }
                  const tableCell = (
                    <td style={{backgroundColor: background}}> {contents} </td>
                  );
                  if (config.popupColumn && config.popupColumn !== 'null') {
                    return (
                      <Popup
                        key={cls2}
                        trigger={tableCell}
                        content={this.renderPopupContent(cellPopup)}
                      />
                    );
                  } else {
                    return (
                      <React.Fragment key={cls2}> {tableCell} </React.Fragment>
                    );
                  }
                })}
              </tr>
            ))}
            {more && (
              <tr>
                <th className="panel-confusion-matrix--more-left">. . .</th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderPopupContent(value: any) {
    value = parseValue(value);
    if (isValueImage(value)) {
      let {width, height} = value;
      let scale = 1.0;
      if (width >= height && width > 300) {
        scale = 300 / width;
      } else if (height > width && height > 300) {
        scale = 300 / height;
      }
      width *= scale;
      height *= scale;
      const src = imageSrc(this.props.pageQuery, value);
      if (!src) {
        return displayValue(null);
      }
      return (
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            backgroundImage: `url(${src})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        />
      );
    }
    return displayValue(value);
  }

  render() {
    if (this.props.configMode) {
      return <div>{this.renderConfig()}</div>;
    } else {
      return this.renderNormal();
    }
  }
}

const ConfusionMatrixPanelWithQuery = DataFrameQuery.withQuery(
  ConfusionMatrixPanelInner
);

export default class ConfusionMatrixPanel extends React.Component<ConfusionMatrixPanelProps> {
  static type = ConfusionMatrixPanelInner.type;
  static options = ConfusionMatrixPanelInner.options;
  static transformQuery = ConfusionMatrixPanelInner.transformQuery;

  entityName() {
    return (this.props.pageQuery && this.props.pageQuery.entityName) || '';
  }

  projectName() {
    return this.props.pageQuery.projectName;
  }

  runName() {
    return (this.props.pageQuery && this.props.pageQuery.runName) || '';
  }

  render() {
    const {config, data} = this.props;
    const dataFrameOptions = availableKeys(data);
    const dataFrameKey =
      config.dataFrameKey || (dataFrameOptions && dataFrameOptions[0]);

    const updatedConfig = {...config, dataFrameKey};

    let dataFrameID: string | undefined;
    if (dataFrameKey) {
      const df =
        data.filtered &&
        data.filtered[0] &&
        data.filtered[0].summary &&
        data.filtered[0].summary[dataFrameKey];
      if (df) {
        dataFrameID = (df as any).id as string;
      }
    }

    let limit = 0;
    let groupKeys: string[] = [];
    let columns: string[] = [];

    if (dataFrameID && configReady(updatedConfig)) {
      limit = 400; // TODO: what should this limit be?
      groupKeys = [config.predictedClassColumn!, config.trueClassColumn!];
      if (updatedConfig.popupColumn && updatedConfig.popupColumn !== 'null') {
        columns = [updatedConfig.popupColumn];
      }
    }

    const queryVars: DataFrameQuery.InputProps = {
      entityName: this.entityName(),
      projectName: this.projectName(),
      limit,
      dataFrameKeys: [dataFrameKey],
      groupKeys,
      filters: {
        key: {section: 'run', name: 'wandb_data_frame_id'},
        op: '=',
        value: dataFrameID || '',
      },
      columns,
      disabled: !dataFrameID,
      disableRows: !dataFrameID || !configReady(updatedConfig),
    };

    return (
      <ConfusionMatrixPanelWithQuery
        {...queryVars}
        {...this.props}
        config={updatedConfig}
      />
    );
  }
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, ConfusionMatrixConfig> =
  {
    type: PANEL_TYPE,
    Component: ConfusionMatrixPanel,
    transformQuery: ConfusionMatrixPanel.transformQuery,
  };
