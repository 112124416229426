// This shares a lot of code with MultiRunWorkspace, try to keep them
// in sync, or refactor
import React, {useMemo} from 'react';

import {color} from '../../util/colors';
import * as FilterUtil from '../../util/filters';
import * as QueryUtil from '../../util/queryts';
import * as RunUtil from '../../util/runs';
import * as RunFeedUtil from '../../util/runfeed';
import * as SMUtil from '../../util/selectionmanager';

import {RunQueryContext} from '../../state/runs/context';
import * as ViewHooks from '../../state/views/hooks';
import * as RunPageActions from '../../state/views/runPage/actions';
import * as RunPageViewTypes from '../../state/views/runPage/types';
import PanelBank from '../../components/PanelBank';
import makeComp from '../../util/profiler';

const OLD_CLI_WARNING_CUTOFF = 1612166400000; // Feb 1 2021 00:00:00 PST

interface RunPagePanelsProps {
  entityName: string;
  projectName: string;
  runPageRef: RunPageViewTypes.Ref;
  run: RunUtil.Run;
  readOnly: boolean;
  workspaceID?: string;
}

export const RunPagePanelsInner = makeComp(
  (props: RunPagePanelsProps) => {
    const {entityName, projectName, runPageRef} = props;
    const runPagePart = ViewHooks.usePart(runPageRef);

    // Need to memoize this or useNewObjectRef will generate a new
    // object repeatedly
    const runSet = useMemo(
      () => ({
        id: 'run-page-runset-id',
        name: 'run-runset',
        filters: FilterUtil.rootFilterSingle({
          key: {section: 'run', name: 'name'},
          op: '=',
          value: props.run.name,
        }),
        grouping: [],
        selections: SMUtil.EMPTY_ALL_SELECTIONS,
        expandedRowAddresses: [],
        enabled: true,
        sort: {
          keys: [
            {
              key: {section: 'run', name: 'createdAt'},
              ascending: true,
            },
          ],
        } as QueryUtil.Sort,
        runFeed: RunFeedUtil.EMPTY_CONFIG,
        search: {query: ''},
      }),
      [props.run.name]
    );
    const runSetRefNew = ViewHooks.useNewObjectRef('runSet', runSet);

    // Need to memoize this or useNewObjectRef will generate a new
    // object repeatedly
    const runColors = useMemo(() => {
      return {[props.run.name]: color(0)};
    }, [props.run.name]);
    const runColorsRefNew = ViewHooks.useNewObjectRef('run-colors', runColors);

    const hideSlowWarning = ViewHooks.useViewAction(
      runPageRef,
      RunPageActions.hideSlowWarning
    );
    const hideCliVersionWarning = ViewHooks.useViewAction(
      runPageRef,
      RunPageActions.hideCliVersionWarning
    );

    const runSetRefs = useMemo(() => {
      if (!runSetRefNew.ready || !runColorsRefNew.ready) {
        return [];
      }
      return [runSetRefNew.ref];
    }, [runSetRefNew.ref, runSetRefNew.ready, runColorsRefNew.ready]);

    if (!runSetRefNew.ready || !runColorsRefNew.ready) {
      return <div />;
    }

    const runColorsRef = runColorsRefNew.ref;

    const cliVersion = props.run._wandb['value.cli_version'];
    const createdAt = new Date(props.run.createdAt).valueOf();
    const oldCliVersion =
      createdAt >= OLD_CLI_WARNING_CUTOFF &&
      typeof cliVersion === 'string' &&
      /^0\.[89](\..+)?$/.test(cliVersion);

    return (
      <div className="run-page-workspace">
        <PanelBank
          entityName={entityName}
          projectName={projectName}
          readOnly={props.readOnly}
          singleRun
          // historyKeyInfo={props.run.historyKeys!}
          panelSettingsRef={runPagePart.panelSettingsRef}
          panelBankConfigRef={runPagePart.panelBankConfigRef}
          customRunColorsRef={runColorsRef}
          runSetRefs={runSetRefs}
          showSlowWarning={runPagePart.slowWarningHiddenAt == null}
          hideSlowWarning={hideSlowWarning}
          showCliVersionWarning={
            oldCliVersion && runPagePart.cliVersionWarningHiddenAt == null
          }
          hideCliVersionWarning={hideCliVersionWarning}
          workspaceID={props.workspaceID}
        />
      </div>
    );
  },
  {id: 'RunPagePanelsInner'}
);

export const RunPagePanels = makeComp(
  (props: RunPagePanelsProps) => {
    const {entityName, projectName, run} = props;
    const context = useMemo(() => {
      return {
        entityName,
        projectName,
        runId: run.name,
        runSetName: `Run: ${run.displayName}`,
      };
    }, [entityName, projectName, run.name, run.displayName]);
    return (
      <RunQueryContext.Provider value={context}>
        <RunPagePanelsInner {...props} />
      </RunQueryContext.Provider>
    );
  },
  {id: 'RunPagePanels'}
);
