import * as Moment from 'moment';
import React, {FC} from 'react';
import {useMemo} from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {Dropdown, Input, Label} from 'semantic-ui-react';
import {NULL_STRING} from '../../util/constants';
import * as Filter from '../../util/filters';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Run from '../../util/runs';
import {beautify} from '../../util/uihelpers';
import {tryJSONParse} from '../../util/try_json_parse';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import {Option} from '../../util/uihelpers';
import makeComp from '../../util/profiler';

interface FilterValueSelectorDateProps {
  value: string | null;
  setFilter(filter: Partial<Filter.IndividualFilter>): void;
}

export const FilterValueSelectorDate: FC<FilterValueSelectorDateProps> =
  makeComp(
    ({value, setFilter}) => {
      return (
        <Datetime
          className="filter-list__value"
          value={value ? new Date(value) : new Date()}
          onChange={(moment: any) =>
            Moment.isMoment(moment) &&
            setFilter({
              value: moment.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
              disabled: false,
            })
          }
        />
      );
    },
    {id: 'FilterValueSelectorDate', memo: true}
  );

const timeScale = {
  seconds: 1,
  minutes: 60,
  hours: 60 * 60,
} as {
  [key in Filter.TimeOption]: number;
};

// Sets any unit to seconds
const toSeconds = (value: string, unit: Filter.TimeOption) => {
  return (parseFloat(value) * timeScale[unit]).toString();
};

// Sets seconds to units
const toUnit = (secondsValue: string, unit: Filter.TimeOption): string => {
  return (parseFloat(secondsValue) / timeScale[unit]).toString();
};

// Convert n units(from) : n units(to)
// Keeping n the same but adjusting units
export const convert = (
  value: string,
  from: Filter.TimeOption,
  to: Filter.TimeOption
): string => {
  const initialValue = parseFloat(value);
  return ((initialValue / timeScale[from]) * timeScale[to]).toString();
};

interface FilterValueSelectorTimeProps {
  value: string | null;
  meta?: Filter.FilterMeta;
  setFilter(filter: Partial<Filter.IndividualFilter>): void;
}

export const FilterValueSelectorTime: FC<FilterValueSelectorTimeProps> =
  makeComp(
    ({value, meta, setFilter}) => {
      const unit: Filter.TimeOption = meta ? meta.unit : 'seconds';
      const val = value || '0';
      const displayV = toUnit(val, unit);

      return (
        <React.Fragment>
          <Input
            className="filter-list__value filter-list__time"
            fluid
            value={displayV}
            onChange={(e, res) => {
              // Take input without text and fails it.
              if (isNaN(parseFloat(res.value))) {
                return;
              }
              setFilter({value: toSeconds(res.value, unit)});
            }}
          />
          <Dropdown
            className="filter-dropdown filter-list__value"
            options={[
              {key: 'seconds', text: 'Seconds', value: 'seconds'},
              {key: 'minutes', text: 'Minutes', value: 'minutes'},
              {key: 'hours', text: 'Hours', value: 'hours'},
            ]}
            value={unit}
            onChange={(e, res) => {
              const unitValue = res.value as Filter.TimeOption;
              setFilter({
                value: convert(val, unit, unitValue),
                meta: {unit: unitValue},
                disabled: false,
              });
            }}
            inline
          />
        </React.Fragment>
      );
    },
    {id: 'FilterValueSelectorTime', memo: true}
  );

interface FilterTagOpSelectorProps {
  keys: string[];
  filter: Filter.IndividualFilter;
  setFilter(filter: Filter.Filter): void;
}

export const FilterTagOpSelector: FC<FilterTagOpSelectorProps> = makeComp(
  ({keys, filter, setFilter}) => {
    const tagNames = keys
      .map(tagKey => {
        const key = Run.keyFromString(tagKey);
        return key && key.name;
      })
      .filter(Obj.notEmpty);
    let currentValue: string;
    if (filter.op === 'IN') {
      currentValue = 'IN';
    } else if (filter.op === 'NIN') {
      currentValue = 'NOT IN';
    } else {
      currentValue = (filter as Filter.IndividualFilter).value
        ? 'set'
        : 'notset';
    }
    return (
      <Dropdown
        className="filter-dropdown filter-list__operation"
        data-test="filter-operation"
        options={[
          {key: 'set', text: 'is', value: 'set'},
          {key: 'notset', text: 'is not', value: 'notset'},
          {key: 'IN', text: 'IN', value: 'IN'},
          {key: 'NIN', text: 'NOT IN', value: 'NOT IN'},
        ]}
        placeholder="value"
        search
        inline
        value={currentValue}
        onChange={(e, {value}) => {
          const tagName =
            filter.key.name === '*' ? tagNames[0] : filter.key.name;
          if (value === 'set') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: tagName},
              op: '=',
              value: true,
            });
          } else if (value === 'notset') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: tagName},
              op: '!=',
              value: false,
            });
          } else if (value === 'IN') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: '*'},
              op: 'IN',
              value: [],
            });
          } else {
            setFilter({
              ...filter,
              key: {section: 'tags', name: '*'},
              op: 'NIN',
              value: [],
            });
          }
        }}
      />
    );
  },
  {id: 'FilterTagOpSelector', memo: true}
);

interface FilterValueSelectorProps {
  loading: boolean;
  suggestions: Array<{
    text: string;
    key: string | number;
    value: Run.Value;
    count: number;
  }>;
  filter: Filter.IndividualFilter;
  setFilter(filter: Filter.IndividualFilter): void;
}

export type FilterValueSelectorCreatorProps = Omit<
  FilterValueSelectorProps,
  'suggestions' | 'loading'
>;

export function filterValueOptionTransform(o: Option) {
  return {
    ...beautify(o),
    content: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {o.text}
        </span>
        {o.text !== '*' && <Label className="count-label">{o.count}</Label>}
      </div>
    ),
  };
}

export const FilterValueSelector = makeComp(
  (props: FilterValueSelectorProps) => {
    const currentValue = props.filter.value;
    const isBlank = !currentValue || currentValue === NULL_STRING;
    const isMulti = Filter.isMultiValue(props.filter);
    const allowsAdditions = Filter.opAllowsAdditions(props.filter.op);

    const {suggestions} = props;
    const options = useMemo(
      () =>
        suggestions.map(s => ({
          ...s,
          value: JSON.stringify(s.value),
          key: s.text,
        })),
      [suggestions]
    );

    return (
      <ModifiedDropdown
        className={`filter-dropdown filter-list__value ${
          isBlank ? 'filter-dropdown--blank' : ''
        }`}
        loading={props.loading}
        data-test="filter-value"
        style={{
          flexWrap: 'wrap',
          flexGrow: 1,
          maxWidth: 400,
        }}
        additionLabel=""
        allowAdditions={allowsAdditions}
        options={options}
        optionTransform={filterValueOptionTransform}
        placeholder="value"
        search
        // fluid
        floating
        // This is a workaround for a bug.
        // Active and value get out of sync
        // It's probably in semantic, but could also be caused by setFilter
        key={(props.filter.value || 0).toString()}
        multiple={Filter.isMultiValue(props.filter)}
        value={
          isMulti
            ? (currentValue as any[]).map(v => JSON.stringify(v))
            : JSON.stringify(currentValue)
        }
        onChange={(e, {value}) => {
          if (value) {
            if (isMulti) {
              if (
                !(typeof value === 'string') &&
                !(typeof value === 'number') &&
                !(typeof value === 'boolean')
              ) {
                props.setFilter({
                  ...props.filter,
                  value: value.map(tryJSONParse),
                  disabled: false,
                } as Filter.IndividualFilter);
              }
            } else {
              props.setFilter({
                ...props.filter,
                value: tryJSONParse(value as string),
                disabled: false,
              } as Filter.IndividualFilter);
            }
          }
        }}
      />
    );
  },
  {id: 'FilterValueSelector'}
);
