import React, {
  ChangeEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import {match as Match} from 'react-router';
import {Mutation, Query} from 'react-apollo';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {
  Container,
  Header,
  Button,
  Checkbox,
  Form,
  Input,
  Divider,
  Message,
  Table,
  Dropdown,
  Modal,
  Icon,
  Image,
  GridColumn,
  Grid,
  TableBody,
  Radio,
  InputOnChangeData,
  CheckboxProps,
  RadioProps,
  DropdownProps,
} from 'semantic-ui-react';
import Loader from '../components/WandbLoader';
import {updateLocationParams} from '../actions/location';
import {bindActionCreators, Dispatch} from 'redux';
import {withViewer} from '../graphql/users_get_viewer';
import {
  SUBSCRIPTION_UPDATE,
  TEAM_PHOTO_UPDATE,
  ALL_ENTITIES,
  TEAM_QUERY,
  CREATE_TEAM,
  USER_QUERY,
} from '../graphql/admin';
import SearchHeader from '../components/elements/SearchHeader';
import {fuzzyMatchWithMapping, fuzzyMatchHighlight} from '../util/fuzzyMatch';
import {
  AllEntitiesQuery,
  CreateTeamMutationFn,
  CreateTeamMutationResult,
  Entity as FullEntity,
  TeamQueryQuery,
  TeamQueryQueryResult,
  UpdateEntityPhotoMutationFn,
  UpdateEntityPhotoMutationResult,
  UpdateSubscriptionMutationFn,
  UpdateSubscriptionMutationResult,
  UserQueryQuery,
} from '../generated/graphql';
import {ApolloError} from 'apollo-client';
import {User} from '../types/graphql';
import makeComp from '../util/profiler';

type Entity = Pick<
  FullEntity,
  | 'id'
  | 'createdAt'
  | 'name'
  | 'memberCount'
  | 'runCount'
  | 'available'
  | 'photoUrl'
  | 'limits'
>;

interface TeamAdminProps {
  updateLocationParams: (params: any) => void;
  match: Match;
  viewerLoading: boolean;
  viewer?: User;
}

const TeamAdmin: React.FC<TeamAdminProps> = makeComp(
  ({
    updateLocationParams: dispatchUpdateLocationParams,
    match,
    viewerLoading,
    viewer,
  }) => {
    const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(
      undefined
    );

    useEffect(() => {
      dispatchUpdateLocationParams(match.params);
      // eslint-disable-next-line
    }, []);

    return (
      <Container className="timeline">
        <Header as="h1">Team Admin Panel</Header>
        {viewerLoading ? (
          <Loader />
        ) : viewer!.admin ? (
          <div>
            <Divider />
            <EntitiesFeedWithQuery
              userName={selectedEntity?.name ?? ''}
              clickOnEntity={setSelectedEntity}
            />
            <Divider />
            <UpdateTeamQuery
              selectedEntity={selectedEntity}
              comp={UpdateTeamSubscription}
            />
            <Divider />
            <UpdateTeamQuery
              selectedEntity={selectedEntity}
              comp={UpdateTeamPhoto}
            />
          </div>
        ) : (
          /* eslint-disable wandb/no-a-tags */
          <div>
            No access, admin privileges needed -- are you a hacker? we're{' '}
            <a href="/site/careers">hiring</a> :)
          </div>
          /* eslint-enable wandb/no-a-tags */
        )}
      </Container>
    );
  },
  {id: 'TeamAdmin'}
);

interface EntitiesFeedWithQueryProps {
  clickOnEntity: (e: Entity) => void;
  userName: string;
}

const EntitiesFeedWithQuery: React.FC<EntitiesFeedWithQueryProps> = makeComp(
  ({clickOnEntity, userName}) => {
    return (
      <Query query={ALL_ENTITIES}>
        {(res: any) => {
          const {loading, error, data, refetch} = res;
          if (loading) {
            return <>'Loading...'</>;
          }
          if (error) {
            return <>'Error!!!'</>;
          }
          return (
            <div>
              <EntitiesFeed
                entities={data!.entities!.edges}
                loadStartAmount={5}
                clickOnEntity={clickOnEntity}
              />
              <Divider />
              <CreateNewTeam userName={userName} refreshEntities={refetch} />
            </div>
          );
        }}
      </Query>
    );
  },
  {id: 'EntitiesFeedWithQuery'}
);

interface EntitiesFeedProps {
  entities: NonNullable<AllEntitiesQuery['entities']>['edges'];
  loadStartAmount: number;
  clickOnEntity: (e: Entity) => void;
}

const EntitiesFeed: React.FC<EntitiesFeedProps> = makeComp(
  ({entities, loadStartAmount, clickOnEntity}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loadedMore, setLoadedMore] = useState(false);

    if (!_.isEmpty(searchQuery)) {
      entities = fuzzyMatchWithMapping(
        entities,
        searchQuery,
        e => e.node!.name!
      );
    }
    return (
      <div className={'homebox' + (loadedMore ? ' loaded-more' : '')}>
        <SearchHeader
          header={
            <div>
              <span style={{color: 'red'}}>Entities</span> &<span> Teams</span>
            </div>
          }
          placeholder={'Search ' + entities.length + ' Entities & Teams...'}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <Table basic="very" compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Members</Table.HeaderCell>
              <Table.HeaderCell>No Of Members</Table.HeaderCell>
              <Table.HeaderCell>No Of Runs</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entities.slice(0, loadedMore ? 2000 : loadStartAmount).map(e => (
              <Table.Row key={e.node!.id}>
                <Table.Cell>
                  {_.includes(
                    e.node!.members.map(m => m.username),
                    e.node!.name
                  ) ? (
                    <button
                      className="link"
                      style={{color: 'red'}}
                      onClick={() => clickOnEntity(e.node!)}>
                      {fuzzyMatchHighlight(e.node!.name!, searchQuery)}
                    </button>
                  ) : (
                    <button
                      className="link"
                      onClick={() => clickOnEntity(e.node!)}>
                      {fuzzyMatchHighlight(e.node!.name!, searchQuery)}
                    </button>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {e
                    .node!.members.map(m => m.username)
                    .slice(0, 3)
                    .join(', ') + (e.node!.members.length > 3 ? '...' : '')}
                </Table.Cell>
                <Table.Cell>{e.node!.memberCount}</Table.Cell>
                <Table.Cell>{e.node!.runCount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!loadedMore && entities.length > loadStartAmount && (
          <button
            className="link"
            style={{fontWeight: 'bold'}}
            onClick={() => setLoadedMore(true)}>
            Load More...
          </button>
        )}
      </div>
    );
  },
  {id: 'EntitiesFeed'}
);

interface UpdateTeamQueryProps {
  comp: React.FC<UpdateTeamSubscriptionProps | UpdateTeamPhotoProps>;
  selectedEntity?: Entity;
}

const UpdateTeamQuery: React.FC<UpdateTeamQueryProps> = makeComp(
  ({comp, selectedEntity}) => {
    const [entityName, setEntityName] = useState('');

    useEffect(() => {
      if (selectedEntity) {
        setEntityName(selectedEntity.name!);
      }
    }, [selectedEntity]);

    const Component = comp;
    return (
      <Query query={TEAM_QUERY} variables={{name: entityName}}>
        {({loading, error, data}: TeamQueryQueryResult) => {
          if (error) {
            return <>`Error!: ${error}`</>;
          }
          return (
            <div>
              <Component
                selectedEntity={data?.entity as Entity | undefined}
                loading={loading}
                updateEntity={setEntityName}
              />
            </div>
          );
        }}
      </Query>
    );
  },
  {id: 'UpdateTeamQuery'}
);

const CompFSDL = () => {
  return (
    <Table>
      <Table.Row>
        <Table.Cell>Repo</Table.Cell>
        <Table.Cell>gradescope/fsdl-text-recognizer-project</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>RedisEnabled</Table.Cell>
        <Table.Cell>true</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>DockerEnabled</Table.Cell>
        <Table.Cell>true</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Expiration</Table.Cell>
        <Table.Cell>60*60*24*30</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Disk</Table.Cell>
        <Table.Cell>20Gi</Table.Cell>
      </Table.Row>
    </Table>
  );
};

const CompZero = () => {
  return (
    <Table>
      <Table.Row>
        <Table.Cell>Repo</Table.Cell>
        <Table.Cell>zerotodeeplearning/ztdl-5-day-bootcamp</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Disk</Table.Cell>
        <Table.Cell>20Gi</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Expiration</Table.Cell>
        <Table.Cell>60*60*24*10</Table.Cell>
      </Table.Row>
    </Table>
  );
};

const CompColor = () => {
  return (
    <Table>
      <Table.Row>
        <Table.Cell>Repo</Table.Cell>
        <Table.Cell>lukas/colorizer</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Disk</Table.Cell>
        <Table.Cell>20Gi</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Expiration</Table.Cell>
        <Table.Cell>60*60*24*30</Table.Cell>
      </Table.Row>
    </Table>
  );
};

const CompDefault = () => {
  return (
    <Table>
      <Table.Row>
        <Table.Cell>Repo</Table.Cell>
        <Table.Cell>lukas/ml-class</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Disk</Table.Cell>
        <Table.Cell>10Gi</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Expiration</Table.Cell>
        <Table.Cell>60*60*24*3</Table.Cell>
      </Table.Row>
    </Table>
  );
};

interface UpdateTeamSubscriptionProps {
  updateEntity: (entityName: string) => void;
  loading: boolean;
  selectedEntity?: Entity;
}

const UpdateTeamSubscription: React.FC<UpdateTeamSubscriptionProps> = makeComp(
  ({updateEntity, loading, selectedEntity}) => {
    /**
     *
     * <UpdateTeamSubscription
     *  selectedEntity={entity}
     *  loading={loading}
     *  updateEntity={fn} />
     *
     * entity is the entity object passed down to this from parent
     * loading is the loading flag of the parent query component
     * updateEntity is a function that updates parent component
     * state after the mutation in this component is finished
     *
     * This commponent is used to update Team subscription. It allows to
     * update subscription either through presets or changing individual flags
     * It doens't trigger a refetch because of apollo caching.
     */

    const [state, setState] = useState<any>({
      entityName: '',
      message: false,
      limits: undefined,
      modalOpen: false,
      preset: true,
    });

    const limitsKeyDict = {
      repo: 'repo',
      disk: 'disk',
      image: 'image',
      teamsEnabled: 'teams_enabled',
      privateProjects: 'private_projects',
      redisEnabled: 'redis_enabled',
      dockerEnabled: 'docker_enabled',
    };

    useEffect(() => {
      if (!selectedEntity) {
        return;
      }
      if (!selectedEntity.available && selectedEntity.limits !== null) {
        const limitsObject = JSON.parse(selectedEntity.limits);
        setState((prevState: any) => ({
          ...prevState,
          selectedEntity,
          entityName: selectedEntity.name,
          limits: _.merge(limitsObject, limitsObject.hub_settings),
          message: false,
          repo: limitsObject.hub_settings
            ? limitsObject.hub_settings.repo
            : null,
          disk: limitsObject.hub_settings
            ? limitsObject.hub_settings.disk
            : null,
          image: limitsObject.hub_settings
            ? limitsObject.hub_settings.image
            : null,
          teamsEnabled: limitsObject.teams_enabled,
          privateProjects: limitsObject.private_projects,
          redisEnabled: limitsObject.hub_settings
            ? limitsObject.hub_settings.redis_enabled
            : null,
          dockerEnabled: limitsObject.hub_settings
            ? limitsObject.hub_settings.docker_enabled
            : null,
        }));
      } else {
        setState((prevState: any) => ({
          ...prevState,
          selectedEntity,
          entityName: selectedEntity.name,
          message: false,
          repo: '',
          disk: '',
          image: '',
          teamsEnabled: false,
          privateProjects: false,
          redisEnabled: false,
          dockerEnabled: false,
        }));
      }
    }, [selectedEntity]);

    const handleEntityChange = (
      e: ChangeEvent<HTMLInputElement>,
      {name, value}: InputOnChangeData
    ) => {
      setState((prevState: any) => ({...prevState, [name]: value}));
      setState((prevState: any) => ({
        ...prevState,
        selectedEntity: undefined,
        message: false,
      }));
      updateEntity(value);
    };
    const handleChange = (
      e: ChangeEvent<HTMLInputElement>,
      {name, value}: InputOnChangeData
    ) => {
      setState((prevState: any) => ({...prevState, [name]: value}));
      setState((prevState: any) => ({...prevState, message: false}));
    };
    const handleCheckboxChange = (
      e: FormEvent<HTMLInputElement>,
      {name, checked}: CheckboxProps
    ) => {
      setState((prevState: any) => ({...prevState, [name!]: checked}));
      setState((prevState: any) => ({...prevState, message: false}));
    };
    const handleSubmit = (fn: UpdateSubscriptionMutationFn) => {
      fn({variables: state});
      setState((prevState: any) => ({...prevState, message: true}));
      handleClose();
    };
    const handleRadioToggle = (
      e: FormEvent<HTMLInputElement>,
      {name, checked}: RadioProps
    ) => {
      setState((prevState: any) => ({...prevState, [name!]: checked}));
    };
    const handleDropdownChange = (
      e: SyntheticEvent<HTMLElement>,
      {name, value}: DropdownProps
    ) => {
      // when selecting presets, we make the rest of the
      // mutation variables null, so that mutation works as
      // expected
      setState((prevState: any) => ({...prevState, [name]: value}));
      setState((prevState: any) => ({
        ...prevState,
        teamsEnabled: null,
        privateProjects: null,
        redisEnabled: null,
        dockerEnabled: null,
        message: false,
        repo: null,
        disk: null,
        image: null,
      }));
    };

    const handleOpen = () =>
      setState((prevState: any) => ({...prevState, modalOpen: true}));

    const handleClose = () =>
      setState((prevState: any) => ({...prevState, modalOpen: false}));

    return (
      <Mutation mutation={SUBSCRIPTION_UPDATE}>
        {(
          upS: UpdateSubscriptionMutationFn,
          {data}: UpdateSubscriptionMutationResult
        ) => {
          return (
            <div>
              <Form success>
                <Header>
                  Update Team Subscriptions
                  <Form.Field
                    width={4}
                    control={Radio}
                    toggle
                    label="Use Preset"
                    name="preset"
                    checked={state.preset}
                    onChange={handleRadioToggle}
                  />
                </Header>
                <Form.Group>
                  <Form.Field
                    width={4}
                    control={Input}
                    name="entityName"
                    label="Team Name"
                    loading={loading}
                    error={
                      state.entityName &&
                      (selectedEntity ? selectedEntity.available : true)
                    }
                    onChange={handleEntityChange}
                    value={
                      state.selectedEntity
                        ? state.selectedEntity.name
                        : state.entityName
                    }
                  />
                  <Form.Field
                    width="4"
                    control={Dropdown}
                    fluid
                    selection
                    name="hubPreset"
                    label={'Hub Preset:'}
                    placeholder="Select Preset"
                    defaultValue="null"
                    disabled={!state.preset}
                    options={[
                      {key: '0', text: 'No Preset', value: null},
                      {key: '1', text: 'Default', value: 'default'},
                      {key: '2', text: 'Color', value: 'color'},
                      {key: '3', text: 'Fsdl', value: 'fsdl'},
                      {key: '4', text: 'Zero', value: 'zero'},
                    ]}
                    onChange={handleDropdownChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    name="repo"
                    disabled={state.preset}
                    label={
                      'Repo: ' +
                      (state.selectedEntity &&
                      state.selectedEntity.limits &&
                      state.limits.hub_settings
                        ? state.limits.hub_settings.repo
                        : '')
                    }
                    onChange={handleChange}
                    value={state.repo != null ? state.repo : ''}
                  />
                  <Form.Field
                    control={Input}
                    name="disk"
                    disabled={state.preset}
                    label={
                      'Disk: ' +
                      (state.selectedEntity &&
                      state.selectedEntity.limits &&
                      state.limits.hub_settings
                        ? state.limits.hub_settings.disk
                        : '')
                    }
                    onChange={handleChange}
                    value={state.disk != null ? state.disk : ''}
                  />
                  <Form.Field
                    control={Input}
                    name="image"
                    disabled={state.preset}
                    label={
                      'Image: ' +
                      (state.selectedEntity &&
                      state.selectedEntity.limits &&
                      state.limits.hub_settings
                        ? state.limits.hub_settings.image
                        : '')
                    }
                    onChange={handleChange}
                    value={state.image != null ? state.image : ''}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Checkbox}
                    onChange={handleCheckboxChange}
                    disabled={state.preset}
                    // defaultIndeterminate={true}
                    name="teamsEnabled"
                    label={
                      'Teams Enabled: ' +
                      (state.selectedEntity && state.selectedEntity.limits
                        ? state.limits.teams_enabled
                        : '')
                    }
                    checked={state.teamsEnabled}
                  />
                  <Form.Field
                    control={Checkbox}
                    onChange={handleCheckboxChange}
                    disabled={state.preset}
                    // defaultIndeterminate={true}
                    name="privateProjects"
                    label={
                      'Private Projects: ' +
                      (state.selectedEntity && state.selectedEntity.limits
                        ? state.limits.private_projects
                        : '')
                    }
                    checked={state.privateProjects}
                  />
                  <Form.Field
                    control={Checkbox}
                    onChange={handleCheckboxChange}
                    disabled={state.preset}
                    // defaultIndeterminate={true}
                    name="redisEnabled"
                    label={
                      'Redis Enabled: ' +
                      (state.selectedEntity &&
                      state.selectedEntity.limits &&
                      state.limits.hub_settings
                        ? state.limits.hub_settings.redis_enabled
                        : '')
                    }
                    checked={state.redisEnabled}
                  />
                  <Form.Field
                    control={Checkbox}
                    onChange={handleCheckboxChange}
                    disabled={state.preset}
                    // defaultIndeterminate={true}
                    name="dockerEnabled"
                    label={
                      'Docker Enabled: ' +
                      (state.selectedEntity &&
                      state.selectedEntity.limits &&
                      state.limits.hub_settings
                        ? state.limits.hub_settings.docker_enabled
                        : '')
                    }
                    checked={state.dockerEnabled}
                  />
                </Form.Group>
                <Modal
                  trigger={
                    <Form.Field
                      control={Button}
                      disabled={selectedEntity?.available ?? true}
                      onClick={handleOpen}>
                      Update
                    </Form.Field>
                  }
                  // basic
                  open={state.modalOpen}
                  onClose={handleClose}
                  closeIcon
                  size="small">
                  <Header
                    icon="pencil"
                    content={
                      'Update Subscriptions of Team: ' + state.entityName
                    }
                  />
                  <Modal.Content>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={10}>
                          {state.hubPreset == null ? (
                            <Table>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Key</Table.HeaderCell>
                                  {state.selectedEntity ? (
                                    <Table.HeaderCell>
                                      Old Value
                                    </Table.HeaderCell>
                                  ) : (
                                    <Table.Cell />
                                  )}
                                  <Table.HeaderCell>New Value</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <TableBody>
                                {_.map(
                                  _.difference(_.keys(state), [
                                    'message',
                                    'entityName',
                                    'limits',
                                    'modalOpen',
                                    'selectedEntity',
                                    'hubPreset',
                                  ]),
                                  (val: keyof typeof limitsKeyDict) => (
                                    <Table.Row key={val}>
                                      <Table.Cell>{val}</Table.Cell>
                                      {state.selectedEntity ? (
                                        <Table.Cell>
                                          {state.limits !== undefined &&
                                          state.limits[limitsKeyDict[val]] !==
                                            null &&
                                          state.limits[limitsKeyDict[val]] !==
                                            undefined
                                            ? state.limits[
                                                limitsKeyDict[val]
                                              ].toString()
                                            : ''}
                                        </Table.Cell>
                                      ) : (
                                        <Table.Cell />
                                      )}
                                      <Table.Cell>
                                        {state[val] !== null &&
                                        state[val] !== undefined
                                          ? state[val].toString()
                                          : ''}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          ) : (
                            <div>
                              <Header>Preset: {state.hubPreset} </Header>
                              <Header size="tiny">
                                You can find these presets in
                                services/gorilla/flags.go
                              </Header>
                              {(() => {
                                switch (state.hubPreset) {
                                  case 'default':
                                    return <CompDefault />;
                                  case 'color':
                                    return <CompColor />;
                                  case 'fsdl':
                                    return <CompFSDL />;
                                  case 'zero':
                                    return <CompZero />;
                                  default:
                                    return null;
                                }
                              })()}
                            </div>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic color="red" onClick={handleClose}>
                      <Icon name="remove" /> No
                    </Button>
                    <Button onClick={() => handleSubmit(upS)} color="green">
                      <Icon name="checkmark" /> Update
                    </Button>
                  </Modal.Actions>
                </Modal>
                {data && state.message ? (
                  <Message success header="Subscription Updated!!" />
                ) : (
                  <div />
                )}
              </Form>
            </div>
          );
        }}
      </Mutation>
    );
  },
  {id: 'UpdateTeamSubscription'}
);

interface UpdateTeamPhotoProps {
  selectedEntity?: Entity;
  updateEntity: (entityName: string) => void;
  loading: boolean;
}

const UpdateTeamPhoto: React.FC<UpdateTeamPhotoProps> = makeComp(
  ({selectedEntity, updateEntity, loading}) => {
    /**
     *
     * <UpdateTeamPhoto
     *  selectedEntity={entity}
     *  loading={loading}
     *  updateEntity={fn} />
     *
     * entity is the entity object
     * loading is the loading flag of the parent query component
     * updateEntity is the function to update entity in the parent component
     *
     * This component is used to update team photo, using mutation component
     * and when the mutatoin is done, it updates the entity in the parent
     * query component. It doesn't trigger a refetch because of apollo caching.
     *
     */

    const [state, setStateInner] = useState<any>({
      message: false,
      photoUrl: selectedEntity ? selectedEntity.photoUrl : '',
      entityName: '',
      modalOpen: false,
    });
    const setState = (nextState: any) =>
      setStateInner((prevState: any) => ({
        ...prevState,
        ...nextState,
      }));

    useEffect(() => {
      setState({
        selectedEntity,
        entityName: selectedEntity ? selectedEntity.name : '',
        photoUrl: selectedEntity
          ? selectedEntity.photoUrl
            ? selectedEntity.photoUrl
            : ''
          : '',
        message: false,
      });
    }, [selectedEntity]);

    const handleChange = (
      e: ChangeEvent<HTMLInputElement>,
      {name, value}: InputOnChangeData
    ) => {
      setState({[name]: value});
      setState({message: false});
    };
    const handleEntityChange = (
      e: ChangeEvent<HTMLInputElement>,
      {name, value}: InputOnChangeData
    ) => {
      setState({[name]: value});
      setState({selectedEntity: undefined, message: false});
      updateEntity(value);
    };
    const handleSubmit = (fn: UpdateEntityPhotoMutationFn) => {
      fn({variables: state});
      setState({message: true});
      handleClose();
    };

    const handleOpen = () => setState({modalOpen: true});

    const handleClose = () => setState({modalOpen: false});

    return (
      <Mutation mutation={TEAM_PHOTO_UPDATE}>
        {(
          teamUp: UpdateEntityPhotoMutationFn,
          {data}: UpdateEntityPhotoMutationResult
        ) => {
          return (
            <div>
              <Form success>
                <Header>Update Team Photo</Header>
                <Form.Group>
                  <Form.Field
                    width={4}
                    control={Input}
                    loading={loading}
                    error={
                      state.entityName &&
                      (selectedEntity ? selectedEntity.available : true)
                    }
                    name="entityName"
                    label="Team Name"
                    onChange={handleEntityChange}
                    value={
                      state.selectedEntity
                        ? state.selectedEntity.name
                        : state.entityName
                    }
                  />
                  <Form.Field
                    width={5}
                    control={Input}
                    name="photoUrl"
                    label={
                      'Photo Url: ' +
                      (selectedEntity
                        ? selectedEntity.photoUrl
                          ? selectedEntity.photoUrl
                          : ''
                        : '')
                    }
                    onChange={handleChange}
                    value={state.photoUrl}
                  />
                </Form.Group>
                <Modal
                  trigger={
                    <Form.Field
                      control={Button}
                      disabled={selectedEntity?.available ?? true}
                      onClick={handleOpen}>
                      Update
                    </Form.Field>
                  }
                  // basic
                  open={state.modalOpen}
                  onClose={handleClose}
                  closeIcon
                  size="small">
                  <Header
                    icon="photo"
                    content={'Update Photo of Team: ' + state.entityName}
                  />
                  <Modal.Content>
                    <Grid>
                      <Grid.Row>
                        {state.selectedEntity ? (
                          <GridColumn width={6}>
                            <p>Old Photo</p>
                            <Image
                              wrapped
                              size="large"
                              src={state.selectedEntity.photoUrl}
                            />
                          </GridColumn>
                        ) : (
                          <p />
                        )}

                        <GridColumn width={6}>
                          <p>New Photo</p>
                          <Image wrapped size="large" src={state.photoUrl} />
                        </GridColumn>
                      </Grid.Row>
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic color="red" onClick={handleClose}>
                      <Icon name="remove" /> No
                    </Button>
                    <Button onClick={() => handleSubmit(teamUp)} color="green">
                      <Icon name="checkmark" /> Update
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Form>
              {data && state.message ? (
                <Message success header={'Team Photo Updated'} />
              ) : (
                <div />
              )}
            </div>
          );
        }}
      </Mutation>
    );
  },
  {id: 'UpdateTeamPhoto'}
);

interface CreateNewTeamProps {
  userName: string;
  refreshEntities: () => Promise<any>;
}

const CreateNewTeam: React.FC<CreateNewTeamProps> = makeComp(
  ({userName, refreshEntities}) => {
    /**
     *
     * <CreateNewTeam userName={adminUserName} refreshEntities={fn} />
     * userName is the userName in the input field
     * refreshEntities is refetch function of parent query component
     *
     * This component is to create a new team, uses mutation compoenent
     * and after the mutation operation is done it refreshes the entities
     * in entity table using refreshEntities prop.
     */

    const [state, setStateInner] = useState<any>({
      teamAdminUserName: '',
      teamName: '',
      userSubmitDisable: true,
      teamSubmitDisable: true,
      modalOpen: false,
      message: false,
    });
    const setState = (nextState: any) =>
      setStateInner((prevState: any) => ({...prevState, ...nextState}));

    useEffect(() => {
      /**
       * This gets triggered every time props change in parent component
       * in this particular case, not just after clicking on the entity
       * in the entity table. But also when entities query was triggered
       * by refetch after the mutation succeeds in this component.
       *
       * this logic based on the message flag is to control the admin user name
       * doesn't change just based on the parent props but also the current
       * adminUsername in the input field if they happen to be different.
       */
      if (!state.message) {
        setState({
          teamAdminUserName: userName,
        });
      }
      setTimeout(() => setState({message: false}), 2000);
    }, [userName, refreshEntities, state.message]);

    const handleChange = (
      e: ChangeEvent<HTMLInputElement>,
      {name, value}: InputOnChangeData
    ) => {
      setState({[name]: value});
      setState({message: false});
    };

    const handleSubmit = (fn: CreateTeamMutationFn) => {
      fn({variables: state});
      handleClose();
    };

    const handleOpen = () => setState({modalOpen: true});
    const handleClose = () => setState({modalOpen: false});

    return (
      <Mutation
        mutation={CREATE_TEAM}
        onCompleted={() => {
          setState({message: true});
          refreshEntities();
        }}>
        {(
          createTeam: CreateTeamMutationFn,
          {data}: CreateTeamMutationResult
        ) => {
          return (
            <div>
              <Form success>
                {/* //onSubmit={() => handleSubmit(createTeam)}> */}
                <Header>Create New Team</Header>
                <Form.Group>
                  <Query
                    query={USER_QUERY}
                    // network-only to fire onCompleted after everychange input
                    fetchPolicy="network-only"
                    onCompleted={(d: UserQueryQuery) => {
                      setState({userSubmitDisable: d.user === null});
                    }}
                    variables={{userName: state.teamAdminUserName}}>
                    {({error}: {error?: ApolloError}) => {
                      if (error) {
                        return <>`Error!: ${error}`</>;
                      }
                      return (
                        <Form.Field
                          width={4}
                          control={Input}
                          name="teamAdminUserName"
                          label="Team Admin User Name"
                          onChange={handleChange}
                          error={
                            state.teamAdminUserName && state.userSubmitDisable
                          }
                          value={state.teamAdminUserName}
                        />
                      );
                    }}
                  </Query>
                  <Query
                    query={TEAM_QUERY}
                    // network-only to fire onCompleted after everychange input
                    fetchPolicy="network-only"
                    onCompleted={(d: TeamQueryQuery) => {
                      setState({
                        teamSubmitDisable:
                          !d.entity ||
                          !d.entity.available ||
                          state.teamName.length < 3,
                      });
                    }}
                    variables={{name: state.teamName}}>
                    {({error}: TeamQueryQueryResult) => {
                      if (error) {
                        return <>`Error!: ${error}`</>;
                      }
                      return (
                        <Form.Field
                          width={4}
                          control={Input}
                          name="teamName"
                          label="New Team Name"
                          error={state.teamName && state.teamSubmitDisable}
                          onChange={handleChange}
                        />
                      );
                    }}
                  </Query>
                </Form.Group>
                <Modal
                  trigger={
                    <Form.Field
                      control={Button}
                      onClick={handleOpen}
                      disabled={
                        state.userSubmitDisable || state.teamSubmitDisable
                      }>
                      Create Team
                    </Form.Field>
                  }
                  open={state.modalOpen}
                  onClose={handleClose}
                  closeIcon
                  size="small">
                  <Header icon="settings" content={'Create new Team'} />
                  <Modal.Content>
                    <div>
                      <Header
                        as="h2"
                        content="Admin User:"
                        subheader={state.teamAdminUserName}
                      />
                      <Header
                        as="h2"
                        content="Team Name:"
                        subheader={state.teamName}
                      />
                    </div>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic color="red" onClick={handleClose}>
                      <Icon name="remove" /> No
                    </Button>
                    <Button
                      onClick={() => handleSubmit(createTeam)}
                      color="green">
                      <Icon name="checkmark" /> Create Team
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Form>
              {data && state.message ? (
                <Message success header={'Team Created'} />
              ) : (
                <div />
              )}
            </div>
          );
        }}
      </Mutation>
    );
  },
  {id: 'CreateNewTeam'}
);

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({updateLocationParams}, dispatch);
};

// export dumb component for testing purposes
export {TeamAdmin};

export default withViewer(connect(null, mapDispatchToProps)(TeamAdmin));
