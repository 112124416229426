import React, {FC} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

export const UNDELETE_RUNS_LOOKBACK = 7 * 24 * 3600 * 1000; // 7 days
const UNDELETE_RUNS_LOOKBACK_STR = '7 days';

export interface UndeleteRunsModalProps {
  open: boolean;
  onClose(): void;
  undeleteRuns(): void;
}

export const UndeleteRunsModal: FC<UndeleteRunsModalProps> = makeComp(
  ({open, onClose, undeleteRuns}) => {
    return (
      <Modal
        size="small"
        open={open}
        onClose={onClose}
        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
        <Modal.Content>
          <p>
            You are about to undelete all runs in this project deleted in the
            last {UNDELETE_RUNS_LOOKBACK_STR}.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="wb-icon-button" size="tiny" onClick={onClose}>
            <LegacyWBIcon name="close" />
            Cancel
          </Button>
          <Button
            color="red"
            size="tiny"
            className="wb-icon-button"
            onClick={undeleteRuns}>
            <LegacyWBIcon name="check" style={{color: 'white'}} /> Undelete all
            runs deleted in the last {UNDELETE_RUNS_LOOKBACK_STR}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'UndeleteRunsModal'}
);
