// Implements a gql query on run. See projectQuery for explanation of the
// implementation details.
import {NetworkStatus} from 'apollo-client';
import gql from 'graphql-tag';
import * as React from 'react';
import {Query} from 'react-apollo';

import {Project} from '../types/graphql';
import makeComp from '../util/profiler';
import {captureError} from '../util/integrations';

///// Simple type helpers
// Type T minus the single key K
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// Type T minus all keys in K
type Subtract<T, K> = Omit<T, keyof K>;

///// Query definition

export const RUN_QUERY = gql`
  query Run($projectName: String!, $entityName: String, $runName: String!) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      readOnly
      createdAt
      access
      views
      entityName
      tags {
        name
        colorIndex
      }
      isBenchmark
      linkedBenchmark {
        id
        entityName
        name
        gitHubSubmissionRepo
        views
      }
      run(name: $runName) {
        id
        name
        displayName
        notes
        config
        summaryMetrics
        historyKeys
        github
        fileCount
        createdAt
        heartbeatAt
        commit
        jobType
        group
        tags: tagColors {
          name
          colorIndex
        }
        host
        state
        stopped
        sweep {
          name
          displayName
        }
        runInfo {
          program
          args
          os
          python
          colab
          executable
          codeSaved
          cpuCount
          gpuCount
          gpu
          git {
            remote
            commit
          }
        }
        diffPatchFile: files(first: 1, names: ["diff.patch"]) {
          edges {
            node {
              id
              name
              md5
              sizeBytes
            }
          }
        }
        metadataFile: files(first: 1, names: ["wandb-metadata.json"]) {
          edges {
            node {
              id
              name
              md5
              sizeBytes
              directUrl
            }
          }
        }
        inputArtifacts {
          totalCount
        }
        outputArtifacts {
          totalCount
        }
        outputLogFile: files(first: 1, names: ["output.log"]) {
          edges {
            node {
              id
              name
              md5
              sizeBytes
              directUrl
              url(upload: false)
            }
          }
        }
        benchmarkRun {
          id
          benchmark {
            id
            name
            entityName
            readOnly
            views
          }
          details
          results
          gitHubSubmissionPR
          run {
            id
            name
            github
            user {
              id
              username
            }
            summaryMetrics
          }
          originalProject {
            id
            name
            entityName
          }
          originalRun {
            id
            name
          }
          isCodeHidden
          state
        }
        user {
          id
          username
          photoUrl
        }
        servicesAvailable {
          tensorboard
        }
      }
      entity {
        id
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
        user {
          id
          username
          accountType
          apiKey
        }
        claimingEntity {
          id
          name
        }
        codeSavingEnabled
      }
    }
  }
`;

// These types define the inputs and outputs of the above gql query

// Any variables required by the query.
interface Variables {
  entityName: string;
  projectName: string;
  runName: string;
}

// The query's output shape.
interface Data {
  project?: Project;
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
      runName: string;
    };
  };
  pollInterval: number;
}

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  project: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  project?: Project;
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface RunQueryResultProps {
  runQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withRunQuery = <P extends object>(
  Component: React.ComponentType<P & RunQueryResultProps>
) =>
  makeComp(
    (inputProps: Subtract<P, RunQueryResultProps> & InputProps) => {
      const {match} = inputProps;
      const variables = {
        entityName: match.params.entityName,
        projectName: match.params.projectName,
        runName: match.params.runName,
      };
      return (
        <Query<Data, Variables>
          query={RUN_QUERY}
          variables={variables}
          pollInterval={inputProps.pollInterval}>
          {rawQueryResult => {
            const r: RunQueryResultProps = {
              runQuery: {
                project: undefined,
                loading: true,
              },
            };
            if (rawQueryResult.networkStatus !== NetworkStatus.loading) {
              if (
                rawQueryResult.data == null ||
                rawQueryResult.data.project === undefined
              ) {
                captureError(
                  'Unexpected Apollo error, loading: false, data: undefined',
                  'withRunQuery',
                  {extra: {rawQueryResult}}
                );
                throw new Error(
                  'Unexpected Apollo error, loading: false, data: undefined'
                );
              }

              // // TODO: This should go in middleware. We should parse all json types.
              // const parseDetails = (p: Project) => {
              //   console.log({p});
              //   return update(p, {
              //     run: {
              //       benchmarkRun: {
              //         details: (d?: string) => (d ? JSON.parse(d) : {}),
              //       },
              //     },
              //   });
              // };

              r.runQuery = {
                loading: false,
                project: rawQueryResult.data.project,
              };
            }
            return <Component {...(inputProps as P)} {...r} />;
          }}
        </Query>
      );
    },
    {id: 'withRunQuery'}
  );
