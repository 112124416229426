import {Text, Range, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';
import emoji from 'node-emoji';

const EMOJI_REGEX = /:\+1:|:-1:|:[\w-]+:/;

export const withEmojis = <T extends ReactEditor>(editor: T) => {
  const {normalizeNode} = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Text.isText(node)) {
      const match = EMOJI_REGEX.exec(node.text);
      if (match != null) {
        const str = match[0];
        const emojified = emoji.get(str);
        if (str !== emojified) {
          const i = match.index;
          const range: Range = {
            anchor: {path, offset: i},
            focus: {path, offset: i + str.length},
          };
          // we have to do delete + insertText at point instead of insertText at range
          // because Slate automatically moves the cursor when we insertText at range
          Transforms.delete(editor, {at: range});
          Transforms.insertText(editor, emojified, {at: range.anchor});
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};
