import React, {useEffect} from 'react';
import {isInJupyterNotebook} from '../setup';
import {useSelector, useDispatch} from '../state/hooks';
import {setUserPollingOK} from '../state/polling/actions';
import {useAutoRefreshContext} from '../util/userContext';
import makeComp from '../util/profiler';

interface AutoRefreshSettingsProps {
  children: (
    autoRefreshEnabled: boolean,
    setAutorefreshEnabled: (enabled: boolean) => void
  ) => React.ReactNode;
}

const AutoRefreshSettings: React.FC<AutoRefreshSettingsProps> = makeComp(
  ({children}) => {
    const {state, setState} = useAutoRefreshContext();
    const userAutoRefreshDisabled = state.disabled ?? false;
    const setUserAutoRefreshDisabled = (d: boolean) => setState({disabled: d});

    const userPollingOK = useSelector(s => s.polling.userPollingOK);
    const dispatch = useDispatch();

    // Mirror UserContext state into redux.
    useEffect(() => {
      dispatch(setUserPollingOK(!userAutoRefreshDisabled));
    }, [dispatch, userAutoRefreshDisabled]);

    return (
      <div>
        {children(
          // Enable auto-refresh by default in Jupyter notebooks.
          userPollingOK || isInJupyterNotebook()!,
          enabled => setUserAutoRefreshDisabled(!enabled)
        )}
      </div>
    );
  },
  {id: 'AutoRefreshSettings', memo: true}
);

export default AutoRefreshSettings;
