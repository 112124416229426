import * as _ from 'lodash';
import {produce} from 'immer';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {Button} from 'semantic-ui-react';
import makeComp from '../../util/profiler';
import RoutedTab from '../RoutedTab';
import * as S from './InternalBenchmarkPage.styles';
import {useHistory} from 'react-router-dom';

import * as TableState from './tableState';
import * as Op from '@wandb/cg/browser/ops';
import * as CG from '@wandb/cg/browser/graph';
import * as Types from '@wandb/cg/browser/model/types';
import * as ServerApiTest from '../../cgservers/serverApiTest';
import {createLocalClient} from '@wandb/cg/browser';

const weaveTestClient = createLocalClient(new ServerApiTest.Client());

function stdDev(array: number[]) {
  const avg = _.sum(array) / array.length;
  return Math.sqrt(
    _.sum(_.map(array, i => Math.pow(i - avg, 2))) / array.length
  );
}

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

interface BenchmarkSpec<T> {
  name: string;
  defaultIters: number;
  setup(): T;
  fn(data: T): any;
}

const exampleBenchmark: BenchmarkSpec<number> = {
  name: 'example',
  defaultIters: 100,
  setup: () => 5,
  fn: (a: number) => {
    let x;
    for (let i = 0; i < 10000000; i++) {
      x = a + i;
    }
    // Return so we don't get unused variable complaint
    return x;
  },
};

function makeTestTable(nRows: number, nCols: number) {
  const tableRows = [];
  for (let i = 0; i < nRows; i++) {
    const row: {[key: string]: number} = {};
    for (let j = 0; j < nCols; j++) {
      row['col' + j] = Math.floor(Math.random() * 10);
    }
    tableRows.push(row);
  }
  return tableRows;
}

function weaveGroupedBasicSetup(nRows: number, nCols: number) {
  const tableRows = makeTestTable(nRows, nCols);
  const rowPropertyTypes: {[key: string]: 'number'} = {};
  for (let j = 0; j < nCols; j++) {
    rowPropertyTypes['col' + j] = 'number';
  }
  const tableRowType = Types.typedDict(rowPropertyTypes);
  let table = TableState.emptyTable();
  const colIds: string[] = [];
  for (let j = 0; j < nCols; j++) {
    let columnId: string;
    ({table, columnId} = TableState.addColumnToTable(
      table,
      Op.opPick({
        obj: CG.varNode(tableRowType, 'row'),
        key: Op.constString('col' + j),
      }) as any
    ));
    colIds.push(columnId);
  }
  table = produce(table, draft => {
    draft.groupBy = [colIds[0]];
  });

  const {resultNode} = TableState.tableGetResultTableNode(
    table,
    Op.constNodeUnsafe(Types.list(tableRowType), tableRows),
    {}
  );
  return resultNode;
}
const weaveBasicGroupLowerBoundSimRun = (
  table: Array<{[key: string]: number}>
) => {
  const colNames = Object.keys(table[0]);
  const col0Name = colNames[0];
  const grouped = _.groupBy(table, row => row[col0Name]);
  const result = Object.values(grouped).map(group => {
    const groupedRow: {[key: string]: number[]} = {};
    for (const colName of colNames) {
      groupedRow[colName] = group.map(r => r[colName]);
    }
    return groupedRow;
  });
  return result;
};

const weaveBasicGroupLarge: BenchmarkSpec<Types.Node> = {
  name: 'weave basic grouping large (nRows: 100000, nCols: 50)',
  defaultIters: 2,
  setup: () => weaveGroupedBasicSetup(100000, 50),
  fn: async node => await weaveTestClient.query(node),
};

const weaveBasicGroupLargeSim: BenchmarkSpec<Array<{[key: string]: number}>> = {
  name: 'weave basic grouping large (nRows: 100000, nCols: 50) [lower bound sim]',
  defaultIters: 2,
  setup: () => makeTestTable(100000, 50),
  fn: weaveBasicGroupLowerBoundSimRun,
};

const weaveBasicGroupMedium: BenchmarkSpec<Types.Node> = {
  name: 'weave basic grouping medium (nRows: 10000, nCols: 50)',
  defaultIters: 5,
  setup: () => weaveGroupedBasicSetup(10000, 50),
  fn: async node => await weaveTestClient.query(node),
};

const weaveBasicGroupMediumSim: BenchmarkSpec<
  Array<{
    [key: string]: number;
  }>
> = {
  name: 'weave basic grouping medium (nRows: 10000, nCols: 50) [lower bound sim]',
  defaultIters: 5,
  setup: () => makeTestTable(10000, 50),
  fn: weaveBasicGroupLowerBoundSimRun,
};

// Put benchmark specs in this list to make them show up in the page.
const benchmarks = [
  exampleBenchmark,
  weaveBasicGroupLarge,
  weaveBasicGroupLargeSim,
  weaveBasicGroupMedium,
  weaveBasicGroupMediumSim,
];

interface BenchmarkResult {
  trials: number[];
  iterations: number;
  totalElapsed: number;
  average: number;
  stdDev: number;
}

const SingleBenchmark: React.FC<{benchmarkSpec: BenchmarkSpec<any>}> = makeComp(
  ({benchmarkSpec: {name, defaultIters, setup, fn}}) => {
    const [running, setRunning] = useState(false);
    const [result, setResult] = useState<BenchmarkResult | undefined>();
    const nIters = defaultIters;

    const runBenchmark = useCallback(() => {
      setRunning(true);
      const doRun = async () => {
        const trials: number[] = [];
        for (let i = 0; i < nIters; i++) {
          console.time(`${name} setup`);
          const initialData = setup();
          console.timeEnd(`${name} setup`);
          console.time(`${name} run`);
          const startMillis = new Date().getTime();
          const testRes = await fn(initialData);
          const runTime = (new Date().getTime() - startMillis) / 1000;
          console.timeEnd(`${name} run`);
          console.log(`${name} result`, testRes);
          trials.push(runTime);
        }
        setRunning(false);
        setResult({
          trials,
          iterations: nIters,
          totalElapsed: _.sum(trials),
          average: _.sum(trials) / nIters,
          stdDev: stdDev(trials),
        });
      };
      setTimeout(() => doRun(), 100);
    }, [name, nIters, setup, fn]);

    // TODO: make this a copy to clipboard link instead
    return (
      <S.SingleBenchmark>
        <S.Header>
          <Button disabled={running} size="tiny" onClick={runBenchmark}>
            Run
          </Button>
          <S.BenchmarkName>{name}</S.BenchmarkName>
        </S.Header>
        {running
          ? 'Running'
          : result != null && (
              <div>
                <S.ResultTable>
                  <tbody>
                    <S.ResultTableRow>
                      <S.ResultTableRowHeader>
                        Iterations
                      </S.ResultTableRowHeader>
                      <S.ResultTableCell>{nIters}</S.ResultTableCell>
                    </S.ResultTableRow>
                    <S.ResultTableRow>
                      <S.ResultTableRowHeader>
                        Total elapsed
                      </S.ResultTableRowHeader>
                      <S.ResultTableCell>
                        {result.totalElapsed.toFixed(4)}
                      </S.ResultTableCell>
                    </S.ResultTableRow>
                    <S.ResultTableRow>
                      <S.ResultTableRowHeader>
                        Average time
                      </S.ResultTableRowHeader>
                      <S.ResultTableCell>
                        {result.average.toFixed(4)} s / iter
                      </S.ResultTableCell>
                    </S.ResultTableRow>
                    <S.ResultTableRow>
                      <S.ResultTableRowHeader>Std dev</S.ResultTableRowHeader>
                      <S.ResultTableCell>
                        {result.stdDev.toFixed(4)} s
                      </S.ResultTableCell>
                    </S.ResultTableRow>
                  </tbody>
                </S.ResultTable>
                <Button
                  size="tiny"
                  onClick={() =>
                    copyToClipboard(
                      [
                        nIters,
                        result.totalElapsed,
                        result.average,
                        result.stdDev,
                      ].join('\t')
                    )
                  }>
                  Copy to clipboard
                </Button>
              </div>
            )}
      </S.SingleBenchmark>
    );
  },
  {id: 'SingleBenchmark'}
);

const BenchmarkTab: React.FC<{}> = makeComp(
  props => {
    return (
      <S.TabBody>
        {benchmarks.map(bs => (
          <SingleBenchmark key={bs.name} benchmarkSpec={bs} />
        ))}
      </S.TabBody>
    );
  },
  {id: 'BenchmarkTab'}
);

interface InternalBenchmarkPageProps {
  match: {
    params: {
      tab?: string;
    };
  };
}

const InternalBenchmarkPage: React.FC<InternalBenchmarkPageProps> = makeComp(
  props => {
    const {tab} = props.match.params;
    const history = useHistory();
    return (
      <S.Page>
        <RoutedTab
          history={history}
          baseUrl="/__wbench"
          tabSlugs={['wbench']}
          activeTabSlug={tab}
          menu={{
            secondary: true,
            pointing: true,
          }}
          defaultActiveIndex={0}
          panes={[{menuItem: 'Benchmark', render: () => <BenchmarkTab />}]}
        />
      </S.Page>
    );
  },
  {id: 'InternalBenchmarkPage'}
);

export default InternalBenchmarkPage;
