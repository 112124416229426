/* Component that stops all navigation away from page when condition is met

This stops both in app navigation (using react-router Prompt) and
browser navigation (using the beforeunload event.)
*/
import * as React from 'react';
import {useEffect} from 'react';
import {Prompt} from 'react-router';
import makeComp from '../util/profiler';

const useBlockUnload = (when: boolean, message: string) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (when) {
        // You have to do all three of these things, because of differing
        // browser implementations.
        e.preventDefault();
        e.returnValue = message;
        return true;
      }
      return false;
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
};

export const BlockNavigation = makeComp(
  (props: {when: boolean; message: string}) => {
    const {when, message} = props;
    useBlockUnload(when, message);
    return <Prompt when={when} message={message} />;
  },
  {id: 'BlockNavigation'}
);
