import React, {FC} from 'react';
import {Input} from 'semantic-ui-react';
import {DropdownOption} from '../../util/uihelpers';
import {QueryEditor} from '../Vega3/QueryEditor';
import * as Query from '../../state/queryGraph/query2';
import * as VegaLib from '../../util/vega2';
import * as VegaLib3 from '../../util/vega3';
import DocUrls from '../../util/doc_urls';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import makeComp from '../../util/profiler';
import {TargetBlank} from '../../util/links';

interface VegaPanelUserConfigProps {
  refs: VegaLib3.FieldRef[];

  userSettings: VegaLib.UserSettings;
  userQuery: VegaLib3.Query;
  fixedQuery: VegaLib3.Query;
  templateArgs: VegaLib3.QueryTemplateArg[];
  fieldOptions: DropdownOption[];

  context: Query.Context;

  updateUserSettings(newUserSettings: VegaLib.UserSettings): void;
  updateUserQuery(newQuery: VegaLib3.Query): void;
}

const VegaPanelUserConfig: FC<VegaPanelUserConfigProps> = makeComp(
  ({
    refs,
    fixedQuery,
    templateArgs,
    fieldOptions,
    userSettings,
    userQuery,
    // context,
    updateUserSettings,
    updateUserQuery,
  }) => {
    const {fieldSettings, stringSettings} = userSettings;

    return (
      <>
        <div style={{display: 'flex'}}>
          <div style={{fontWeight: 600, marginBottom: 8, marginRight: 8}}>
            Query
          </div>
          <div>
            <TargetBlank
              href={DocUrls.customCharts}>{`Documentation →`}</TargetBlank>
          </div>
        </div>
        <QueryEditor
          queryFields={userQuery.queryFields}
          fixedFields={fixedQuery.queryFields}
          templateArgs={templateArgs}
          setQueryFields={newFields =>
            updateUserQuery({queryFields: newFields})
          }
        />

        <div style={{marginBottom: 32}} />
        <div style={{display: 'flex'}}>
          <div style={{fontWeight: 600, marginBottom: 8, marginRight: 8}}>
            Chart fields
          </div>
          <div>
            <TargetBlank
              href={DocUrls.customCharts}>{`Documentation →`}</TargetBlank>
          </div>
        </div>
        {refs.map((r, i) => {
          switch (r.type) {
            case 'field':
              return (
                <React.Fragment key={'table-field-' + i}>
                  <p
                    className="chart-label"
                    style={{paddingTop: 8, paddingBottom: 4, margin: 0}}>
                    {r.name}
                  </p>
                  <ModifiedDropdown
                    style={{width: '100%'}}
                    search
                    selection
                    options={(
                      [{key: '', text: '', value: ''}] as DropdownOption[]
                    ).concat(fieldOptions)}
                    value={fieldSettings[r.name]}
                    onChange={(e, {value}) => {
                      if (typeof value === 'string') {
                        updateUserSettings({
                          ...userSettings,
                          fieldSettings: {...fieldSettings, [r.name]: value},
                        });
                      }
                    }}
                  />
                </React.Fragment>
              );
            case 'string':
              return (
                <React.Fragment key={'table-field-' + i}>
                  <p
                    className="chart-label"
                    style={{paddingTop: 8, paddingBottom: 4, margin: 0}}>
                    {r.name}
                  </p>
                  <Input
                    placeholder={r.default}
                    defaultValue={stringSettings[r.name]}
                    onChange={(e, {value}) => {
                      updateUserSettings({
                        ...userSettings,
                        stringSettings: {...stringSettings, [r.name]: value},
                      });
                    }}></Input>
                </React.Fragment>
              );
            default:
              return <></>;
          }
        })}
      </>
    );
  },
  {id: 'Vega2.VegaPanelUserConfig', memo: true}
);

export default VegaPanelUserConfig;
