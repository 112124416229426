import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import {LinkProps} from 'react-router-dom';
import {StrictInputProps} from 'semantic-ui-react';
import {WBIcon} from '@wandb/ui';
import {isValidEmail} from '@wandb/cg/browser/utils/string';
import PopupDropdown from '../components/PopupDropdown';
import {
  useCreateNewsletterSubscriptionMutation,
  useNewsletterSubscriptionQuery,
  useUpdateUserMutation,
} from '../generated/graphql';
import {GALLERY_PATH_SEGMENT} from '../routeData';
import {auth} from '../setup';
import {usePrevious} from '../state/hooks';
import * as ViewerHooks from '../state/viewer/hooks';
import {propagateErrorsContext} from '../util/errors';
import {fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import {
  CATEGORIES_SHOWING_DESCRIPTION_SET,
  DEFAULT_LANGUAGE,
  DEFAULT_SORT_ORDER,
  DISCUSSION_CATEGORY_ID,
  formatGalleryTag,
  getGalleryTagQS,
  Headings,
  isLanguage,
  Language,
  LANGUAGES,
  MOBILE_WIDTH,
  ReportIDWithTagIDs,
  ReportMetadata,
  rssLinkTagFCReports,
  rssLinkTagPublicReports,
  SortOrder,
  // ReportAuthorMetadata,
  sortOrTagToPath,
  SORT_ORDERS,
  Tag,
  trackOnGalleryPage as track,
  TrackProps,
  useGallerySpec,
  useReportMetadata,
} from '../util/gallery';
import history from '../util/history';
import {useDebounceState} from '../util/hooks';
import {Link} from '../util/links';
import makeComp from '../util/profiler';
import {isNotNullOrUndefined} from '../util/types';
import * as Urls from '../util/urls';
import {useWindowSize} from '../util/window';
import * as S from './GalleryPage.styles';

type Match = {
  params: {
    sortOrTag?: string;
    tag?: string;
  };
};

type GalleryPageProps = {
  match: Match;
};

const GalleryPage: React.FC<GalleryPageProps> = makeComp(
  props => {
    const [updateUser] = useUpdateUserMutation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (auth.loggedIn()) {
        (async () => {
          try {
            await updateUser({variables: {galleryVisited: true}});
          } catch (e) {
            console.error(`Failed to update user galleryVisited: ${e}`);
          }
        })();
      }
    }, [updateUser]);

    const {loading: galleryLoading, gallerySpec} = useGallerySpec();

    const homeCategoryName = useMemo(
      () => gallerySpec.categories.find(t => t.linkForm === '')?.name ?? 'Home',
      [gallerySpec.categories]
    );
    const combinedTags = useMemo(
      () => [...gallerySpec.categories, ...gallerySpec.tags],
      [gallerySpec.categories, gallerySpec.tags]
    );

    // set state based on url
    const {activeTag, sortOrder, language} = getFilterAndSortStateFromURL({
      match: props.match,
      tagList: combinedTags,
      homeCategoryName,
    });

    const [cachedReports, setCachedReports] = useState<ReportMetadata[]>([]);
    const [fetchedReportIDs, setFetchedReportIDs] = useState<Set<string>>(
      new Set<string>()
    );

    const newViewIDs = useMemo(() => {
      // filter by active tag
      const tag = combinedTags.find(t => t.name === activeTag);
      const viewIDsWithTagIDs = tag
        ? gallerySpec.reportIDsWithTagIDs.filter(r => {
            const reportTags = new Set(r.tagIDs);
            return reportTags.has(tag.id);
          })
        : gallerySpec.reportIDsWithTagIDs;
      // omit report IDs that we've tried fetching with
      return viewIDsWithTagIDs
        .filter(v => !fetchedReportIDs.has(v.id))
        .map(v => v.id);
    }, [
      combinedTags,
      activeTag,
      gallerySpec.reportIDsWithTagIDs,
      fetchedReportIDs,
    ]);

    // necessary to fetch all reports when a user is searching
    const [searching, setSearching] = useState(false);

    const {
      loading: reportMetadataLoading,
      reportMetadatas: reports,
      fetchedIDs,
    } = useReportMetadata(searching ? undefined : newViewIDs);

    // cache IDs that we've used when fetching and new reports
    useLayoutEffect(() => {
      if (!reportMetadataLoading && reports != null) {
        setFetchedReportIDs(prev =>
          produce(prev, draft => {
            fetchedIDs.forEach(vid => draft.add(vid));
          })
        );
        setCachedReports(prev =>
          produce(prev, draft => {
            const cachedIDs = new Set(draft.map(r => r.id));
            const newReports = reports.filter(r => !cachedIDs.has(r.id));
            draft.push(...newReports);
          })
        );
      }
    }, [reports, fetchedIDs, reportMetadataLoading]);

    // Query for author info
    // const authorUsernames = useMemo(
    //   () =>
    //     _.compact(
    //       _.uniq(
    //         _.flatten(
    //           reports?.map(r => r.authors?.map(a => a.username) ?? []) ?? []
    //         )
    //       )
    //     ),
    //   [reports]
    // );
    // const {
    //   loading: authorInfoLoading,
    //   data: authorInfo,
    // } = useFeaturedReportsAuthorsQuery({
    //   variables: {
    //     usernames: authorUsernames,
    //   },
    //   skip: authorUsernames.length === 0,
    // });
    // const authorMetadataByUsername: Struct<ReportAuthorMetadata> = useMemo(() => {
    //   const res: Struct<ReportAuthorMetadata> = {};
    //   for (const u of authorInfo?.users?.edges.map(e => e.node) ?? []) {
    //     if (!u?.username) {
    //       continue;
    //     }
    //     res[u.username] = (u as unknown) as ReportAuthorMetadata;
    //   }
    //   return res;
    // }, [authorInfo]);

    const notPendingReports = useMemo(
      () => cachedReports.filter(r => !r.pending),
      [cachedReports]
    );

    const notPendingReportsPastPublishDate = useMemo(
      () =>
        notPendingReports?.filter(r => {
          if (r.addedAt == null) {
            return false;
          }
          const addedAt = new Date(r.addedAt);
          const now = new Date();
          return now >= addedAt;
        }),
      [notPendingReports]
    );

    const pageLoading = galleryLoading || reportMetadataLoading;

    return (
      <>
        <Helmet>
          {rssLinkTagFCReports}
          {rssLinkTagPublicReports}
        </Helmet>
        <GalleryPageLoaded
          {...props}
          reports={notPendingReportsPastPublishDate}
          allFeaturedReports={gallerySpec.reportIDsWithTagIDs}
          headings={gallerySpec.headings}
          categories={gallerySpec.categories}
          tags={gallerySpec.tags}
          combinedTags={combinedTags}
          activeTag={activeTag}
          sortOrder={sortOrder}
          language={language}
          homeCategoryName={homeCategoryName}
          pageLoading={pageLoading}
          setSearching={setSearching}
        />
      </>
    );
  },
  {
    id: 'GalleryPage',
    memo: true,
  }
);

export default GalleryPage;

type GalleryPageLoadedProps = GalleryPageProps &
  FilterAndSortState & {
    reports: ReportMetadata[];
    allFeaturedReports: ReportIDWithTagIDs[];
    headings: Headings;
    categories: Tag[];
    tags: Tag[];
    combinedTags: Tag[];
    homeCategoryName: string;
    pageLoading: boolean;
    setSearching: (val: boolean) => void;
  };

const GalleryPageLoaded: React.FC<GalleryPageLoadedProps> = makeComp(
  ({
    reports,
    allFeaturedReports,
    headings,
    categories,
    tags,
    combinedTags,
    activeTag,
    sortOrder,
    language,
    homeCategoryName,
    pageLoading,
    setSearching,
  }) => {
    const announcementReportIDSet = useMemo(
      () => new Set(reports.filter(r => r.announcement).map(r => r.id)),
      [reports]
    );
    const reportsByID = useMemo(
      () => new Map<string, ReportMetadata>(reports.map(r => [r.id, r])),
      [reports]
    );
    const categoryNameSet = useMemo(
      () => new Set(categories.map(t => t.name)),
      [categories]
    );
    const tagIsCategory = useCallback(
      (tagName: string) => categoryNameSet.has(tagName),
      [categoryNameSet]
    );
    const viewer = ViewerHooks.useViewer();

    const [mobileNavMenuOpen, setMobileNavMenuOpen] = useState(false);
    useEffect(() => {
      setMobileNavMenuOpen(false);
    }, [activeTag]);

    const [
      searchQuery,
      searchQueryDebounced,
      setSearchQuery,
      setSearchQueryLiveAndDebounced,
    ] = useDebounceState('', 300);
    const prevSearchQueryDebounced = usePrevious(searchQueryDebounced);

    const getSortOrderPath = useCallback(
      (newSortOrder: SortOrder) => {
        const pathSegments = [GALLERY_PATH_SEGMENT];
        if (newSortOrder !== DEFAULT_SORT_ORDER) {
          pathSegments.push(sortOrTagToPath(combinedTags, newSortOrder));
        }
        if (activeTag !== homeCategoryName) {
          pathSegments.push(sortOrTagToPath(combinedTags, activeTag));
        }
        return `/${pathSegments.join('/')}`;
      },
      [combinedTags, activeTag, homeCategoryName]
    );
    const getActiveTagPath = useCallback(
      (tagName: string) => {
        const pathSegments = [GALLERY_PATH_SEGMENT];
        if (sortOrder !== DEFAULT_SORT_ORDER) {
          pathSegments.push(sortOrTagToPath(combinedTags, sortOrder));
        }
        if (tagName !== homeCategoryName) {
          pathSegments.push(sortOrTagToPath(combinedTags, tagName));
        }
        return `/${pathSegments.join('/')}`;
      },
      [combinedTags, sortOrder, homeCategoryName]
    );

    const trackProps: Omit<TrackProps, 'value'> = useMemo(
      () => ({
        activeTag,
        sortOrder,
        language,
        searchQuery: searchQueryDebounced,
        mobileNavMenuOpen,
        viewer: viewer ?? null,
      }),
      [
        activeTag,
        sortOrder,
        language,
        searchQueryDebounced,
        mobileNavMenuOpen,
        viewer,
      ]
    );

    useEffect(() => {
      if (
        prevSearchQueryDebounced == null ||
        prevSearchQueryDebounced === searchQueryDebounced
      ) {
        return;
      }
      track('inputSearchQuery', {...trackProps, value: searchQueryDebounced});
    }, [prevSearchQueryDebounced, searchQueryDebounced, trackProps]);

    const sortOrderOptions = useMemo(
      () =>
        SORT_ORDERS.map(o => ({
          text: o,
          onClick: () => {
            track('clickSortOrder', {
              ...trackProps,
              value: o,
            });
            history.push({
              pathname: getSortOrderPath(o),
              search: window.location.search,
            });
          },
        })),
      [getSortOrderPath, trackProps]
    );

    const languageOptions = useMemo(
      () =>
        LANGUAGES.map(o => ({
          text: o,
          onClick: () => {
            track('clickLanguage', {
              ...trackProps,
              value: o,
            });
            history.push({
              search: o === DEFAULT_LANGUAGE ? '' : `?language=${o}`,
            });
          },
        })),
      [trackProps]
    );

    const reportCountByTagID: Map<string, number> = useMemo(() => {
      const res = new Map();
      for (const r of allFeaturedReports) {
        for (const t of r.tagIDs ?? []) {
          res.set(t, res.get(t) ?? 0 + 1);
        }
      }
      return res;
    }, [allFeaturedReports]);

    const tagsWithReports = useMemo(
      () => tags.filter(t => reportCountByTagID.has(t.id)),
      [tags, reportCountByTagID]
    );

    const filteredPosts = useMemo(
      () => reports.filter(r => r.language === language),
      [reports, language]
    );

    const [featuredPosts, notFeaturedPosts]: [
      ReportMetadata[],
      ReportMetadata[]
    ] = useMemo(() => {
      const tag = combinedTags.find(t => t.name === activeTag);
      if (tag == null) {
        return [[], []];
      }
      const announcementAndFeaturedIDs = _.uniq([
        // only show announcements when active tag is a category
        ...(tagIsCategory(activeTag) ? announcementReportIDSet : []),
        ...tag.featuredReportIDs,
      ]);
      const announcementAndFeaturedIDSet = new Set(announcementAndFeaturedIDs);
      const tagReports = filteredPosts.filter(
        r => r.tags?.some(t => t.name === activeTag) ?? false
      );
      return [
        announcementAndFeaturedIDs
          .map(id => reportsByID.get(id))
          .filter(isNotNullOrUndefined)
          .filter(r => r.language === language),
        tagReports.filter(({id}) => !announcementAndFeaturedIDSet.has(id)),
      ];
    }, [
      activeTag,
      tagIsCategory,
      filteredPosts,
      language,
      combinedTags,
      reportsByID,
      announcementReportIDSet,
    ]);

    // mark that the user is searching so we fetch and search all reports
    useEffect(() => {
      setSearching(searchQueryDebounced !== '');
    }, [setSearching, searchQueryDebounced]);

    // use during search since we search all posts
    const sortedPosts = useMemo(
      () => sortPosts(reports, sortOrder),
      [reports, sortOrder]
    );

    const filteredAndSortedPosts = useMemo(
      () => sortPosts(notFeaturedPosts, sortOrder),
      [notFeaturedPosts, sortOrder]
    );

    const searchedPosts = useMemo(() => {
      return searchQueryDebounced !== ''
        ? fuzzyMatchWithMapping(
            sortedPosts,
            searchQueryDebounced,
            rowToSearchString
          )
        : filteredAndSortedPosts;
    }, [sortedPosts, filteredAndSortedPosts, searchQueryDebounced]);

    const renderReport = useCallback(
      (r: ReportMetadata) => {
        const link =
          (r.project != null
            ? Urls.reportView({
                entityName: r.project.entityName,
                projectName: r.project.name,
                reportID: r.id,
                reportName: r.displayName,
              })
            : (r.tags?.some(t => t.id === DISCUSSION_CATEGORY_ID)
                ? Urls.galleryDiscussionView
                : Urls.galleryPostView)({
                entityName: r.entityName,
                reportID: r.id,
                reportName: r.displayName,
              })) + getGalleryTagQS(combinedTags, activeTag);
        return (
          <PostWrapper
            key={r.id}
            {...r}
            link={link}
            activeTag={activeTag}
            tagIsCategory={tagIsCategory}
            getActiveTagPath={getActiveTagPath}
            showDescription={CATEGORIES_SHOWING_DESCRIPTION_SET.has(activeTag)}
            onClick={() =>
              track('clickPost', {
                ...trackProps,
                value: r.id,
              })
            }
            trackProps={trackProps}
            loading={pageLoading}
          />
        );
      },
      [
        combinedTags,
        activeTag,
        tagIsCategory,
        getActiveTagPath,
        trackProps,
        pageLoading,
      ]
    );

    const usingTagSubheading = useMemo(
      () => !categoryNameSet.has(activeTag),
      [activeTag, categoryNameSet]
    );

    const description = useMemo(
      () =>
        combinedTags.find(t => t.name === activeTag)?.description ||
        headings.description,
      [combinedTags, activeTag, headings]
    );
    const descriptionLines = useMemo(
      () => description.split('\n\n'),
      [description]
    );

    return (
      <>
        <S.MobileNav>
          <S.MobileNavLogo
            onClick={() =>
              track('clickMobileNavLogo', {...trackProps, value: null})
            }
          />
          <S.MobileNavTitle
            dangerouslySetInnerHTML={{__html: headings.title}}
          />
          <MobileNavMenuIcon
            onClick={() => {
              track('clickMobileNavMenu', {...trackProps, value: null});
              setMobileNavMenuOpen(prev => !prev);
            }}
          />
          <S.MobileNavMenu active={mobileNavMenuOpen}>
            <S.MobileNavMenuSection noPadding>
              <S.MobileNavMenuSearchIcon
                active={searchQuery !== ''}
                name={searchQuery !== '' ? 'close' : 'search'}
                onClick={
                  searchQuery !== ''
                    ? () => {
                        track('clickClearSearchQuery', {
                          ...trackProps,
                          value: null,
                        });
                        setSearchQuery('');
                      }
                    : undefined
                }
              />
              <S.MobileNavMenuSearchInput
                placeholder="Search posts"
                value={searchQuery}
                onChange={
                  ((e, {value}) =>
                    setSearchQuery(value)) as StrictInputProps['onChange']
                }
              />
            </S.MobileNavMenuSection>
            <S.MobileNavMenuSection noPadding hide={searchQuery !== ''}>
              <S.MobileNavMenuCreatePostLink
                href={`/${GALLERY_PATH_SEGMENT}/create-${
                  ['Featured', 'Forum'].indexOf(activeTag) !== -1
                    ? `discussion`
                    : `post`
                }`}
                onClick={() =>
                  track('clickCreatePost', {
                    ...trackProps,
                    value: null,
                  })
                }>
                <S.MobileNavMenuCreatePostIcon name="edit" />
                {activeTag === 'Blog Posts'
                  ? 'Create a post'
                  : 'Start a discussion'}
              </S.MobileNavMenuCreatePostLink>
            </S.MobileNavMenuSection>
            <S.MobileNavMenuSection hide={searchQuery !== ''}>
              <S.MobileNavMenuSectionTitle>
                Sort and Filter
              </S.MobileNavMenuSectionTitle>
              <S.MobileNavMenuDropdowns>
                <PopupDropdown
                  options={sortOrderOptions}
                  offset={`0px, -16px`}
                  trigger={
                    <div>
                      <DropdownTrigger width={100} value={sortOrder} />
                    </div>
                  }
                />
                <PopupDropdown
                  options={languageOptions}
                  offset={`0px, -16px`}
                  trigger={
                    <div>
                      <DropdownTrigger width={100} value={language} />
                    </div>
                  }
                />
              </S.MobileNavMenuDropdowns>
              <S.MobileNavMenuSectionTitle>
                Popular Topics
              </S.MobileNavMenuSectionTitle>
              {tagsWithReports.map(({name}) => (
                <S.MobileNavMenuLink
                  key={name}
                  to={{
                    pathname: getActiveTagPath(name),
                    search: window.location.search,
                  }}
                  onClick={() =>
                    track('clickTag', {...trackProps, value: name})
                  }>
                  {name}
                </S.MobileNavMenuLink>
              ))}
            </S.MobileNavMenuSection>
          </S.MobileNavMenu>
        </S.MobileNav>
        <S.Container>
          <S.Tabs searchActive={searchQuery !== ''}>
            {categories.map(({id, color, name}) => {
              const specialCaseForumLink = id === DISCUSSION_CATEGORY_ID;
              return (
                <Tab
                  key={name}
                  to={
                    specialCaseForumLink
                      ? Urls.communityForum()
                      : {
                          pathname: getActiveTagPath(name),
                          search: window.location.search,
                        }
                  }
                  active={name === activeTag}
                  color={color}
                  onClick={() => {
                    if (!specialCaseForumLink) {
                      setSearchQueryLiveAndDebounced('');
                    }
                    track('clickTab', {...trackProps, value: name});
                  }}>
                  {name}
                </Tab>
              );
            })}
          </S.Tabs>
          <S.Cols>
            <S.LeftCol>
              {['Featured', 'Blog Posts', 'Forum'].indexOf(activeTag) !==
                -1 && (
                <S.Box loading={pageLoading} hideInMobile padding={14}>
                  <S.CreatePost>
                    <S.Avatar src={viewer?.photoUrl ?? '/logo.png'} />
                    <S.CreatePostLink
                      href={`/${GALLERY_PATH_SEGMENT}/create-${
                        ['Featured', 'Forum'].indexOf(activeTag) !== -1
                          ? `discussion`
                          : `post`
                      }`}
                      onClick={() =>
                        track('clickCreatePost', {
                          ...trackProps,
                          value: null,
                        })
                      }>
                      {activeTag === 'Blog Posts'
                        ? 'Create a post'
                        : 'Start a discussion'}
                    </S.CreatePostLink>
                  </S.CreatePost>
                </S.Box>
              )}
              <S.Box loading={pageLoading} hideInMobile padding={14}>
                <S.Search>
                  <S.SearchIcon name="search" />
                  <S.SearchInput
                    placeholder="Search posts"
                    value={searchQuery}
                    onChange={
                      ((e, {value}) =>
                        setSearchQuery(value)) as StrictInputProps['onChange']
                    }
                  />
                  <PopupDropdown
                    options={sortOrderOptions}
                    offset={`0px, -16px`}
                    trigger={
                      <div>
                        <DropdownTrigger width={100} value={sortOrder} />
                      </div>
                    }
                  />
                  <PopupDropdown
                    options={languageOptions}
                    offset={`0px, -16px`}
                    trigger={
                      <div>
                        <DropdownTrigger width={100} value={language} />
                      </div>
                    }
                  />
                </S.Search>
              </S.Box>
              <ReportSection
                featuredPosts={featuredPosts}
                searchedPosts={searchedPosts}
                searchQuery={searchQueryDebounced}
                activeTag={activeTag}
                pageLoading={pageLoading}
                renderReport={renderReport}
              />
            </S.LeftCol>
            <S.RightCol>
              <S.Box>
                <S.Ribbon src="/ribbon.svg" />
                <S.FancyTitle
                  dangerouslySetInnerHTML={{__html: headings.title}}
                />
                <S.Paragraph
                  large
                  dangerouslySetInnerHTML={{
                    __html: usingTagSubheading ? activeTag : headings.subtitle,
                  }}
                />
                {descriptionLines.map((line, i) => (
                  <S.Paragraph
                    key={i}
                    dangerouslySetInnerHTML={{__html: line}}
                  />
                ))}
                <SubscriptionSection />
              </S.Box>
              <S.Box>
                <S.SectionTitle marginBottom>Popular Topics</S.SectionTitle>
                <S.Tags>
                  {tagsWithReports.map(({name}) => (
                    <S.Tag
                      key={name}
                      onClick={() => {
                        setSearchQueryLiveAndDebounced('');
                        track('clickTag', {
                          ...trackProps,
                          value: name,
                        });
                      }}
                      to={{
                        pathname: getActiveTagPath(name),
                        search: window.location.search,
                      }}
                      active={name === activeTag}>
                      {name}
                    </S.Tag>
                  ))}
                </S.Tags>
              </S.Box>
            </S.RightCol>
          </S.Cols>
        </S.Container>
        <SubscriptionSection mobile />
      </>
    );
  },
  {id: 'GalleryPageLoaded', memo: true}
);

type ReportSectionProps = {
  featuredPosts: ReportMetadata[];
  searchedPosts: ReportMetadata[];
  searchQuery: string;
  activeTag: string;
  pageLoading: boolean;
  renderReport(r: ReportMetadata): JSX.Element;
};

const ReportSection: React.FC<ReportSectionProps> = makeComp(
  ({
    featuredPosts,
    searchedPosts,
    searchQuery,
    activeTag,
    pageLoading,
    renderReport,
  }) => {
    const [currentFeaturedPosts, setCurrentFeaturedPosts] =
      useState(featuredPosts);
    const [currentSearchedPosts, setCurrentSearchedPosts] =
      useState(searchedPosts);

    // only update posts when page is done loading
    useLayoutEffect(() => {
      if (!pageLoading) {
        setCurrentFeaturedPosts(featuredPosts);
        setCurrentSearchedPosts(searchedPosts);
      }
    }, [pageLoading, featuredPosts, searchedPosts]);

    // required for proper placement of loader
    const {height: viewportHeight} = useWindowSize();
    const loaderStylesTop = viewportHeight / 3;

    const searchedPostsHeader = useMemo(() => {
      if (searchQuery !== '') {
        return 'Search results';
      } else {
        return activeTag === 'Digests' ? 'Past' : 'Live Feed';
      }
    }, [searchQuery, activeTag]);

    return (
      <>
        <S.Loader top={loaderStylesTop} loading={pageLoading} />
        <S.ReportSection loading={pageLoading}>
          {currentFeaturedPosts.length > 0 && searchQuery === '' && (
            <>
              <S.SectionTitle marginTop>
                {activeTag === 'Digests' ? 'This Week' : 'Featured Today'}
              </S.SectionTitle>
              <S.Box padding={16}>
                {currentFeaturedPosts.map(renderReport)}
              </S.Box>
            </>
          )}
          {currentSearchedPosts.length > 0 && (
            <>
              <S.SectionTitle marginTop>{searchedPostsHeader}</S.SectionTitle>
              <S.Box padding={16}>
                {currentSearchedPosts.map(renderReport)}
              </S.Box>
            </>
          )}
        </S.ReportSection>
      </>
    );
  },
  {id: 'ReportSection', memo: true}
);

type SubscriptionSectionProps = {
  mobile?: boolean;
};

export const SubscriptionSection: React.FC<SubscriptionSectionProps> = makeComp(
  ({mobile}) => {
    const viewer = ViewerHooks.useViewer();
    const loggedIn: boolean = useMemo(() => viewer != null, [viewer]);

    const [createNewsletterSubscription] =
      useCreateNewsletterSubscriptionMutation({
        context: propagateErrorsContext(),
      });
    const {data} = useNewsletterSubscriptionQuery({
      variables: {
        userName: viewer?.username ?? '',
      },
      skip: !loggedIn,
    });
    const subscribed: boolean = useMemo(
      () => data?.user?.newsletterSubscription != null,
      [data]
    );

    const subscriptionInputRef = useRef<HTMLDivElement>(null);

    const [subscriptionInput, setSubscriptionInput] = useState('');
    const [subscribeSuccess, setSubscribeSuccess] = useState(false);
    const [subscribeError, setSubscribeError] = useState('');

    const createSubscription = useCallback(async () => {
      try {
        if (!loggedIn && !isValidEmail(subscriptionInput)) {
          setSubscribeError('Please enter a valid email.');
          subscriptionInputRef.current?.focus();
          return;
        }

        const mutationVars = loggedIn ? {} : {email: subscriptionInput};
        await createNewsletterSubscription({variables: mutationVars});

        setSubscribeSuccess(true);
        setSubscriptionInput('');
        setSubscribeError('');
      } catch (err) {
        console.error(err);
        subscriptionInputRef.current?.focus();
        setSubscriptionInput('');
        setSubscribeError('Oops, something went wrong.');
      }
    }, [loggedIn, subscriptionInput, createNewsletterSubscription]);

    const [mobileHidden, setMobileHidden] = useState(false);
    useEffect(() => {
      if (!mobile) {
        return;
      }

      let currentTimeoutId: number | null = null;
      const toggleMobileHidden = () => {
        setMobileHidden(true);
        const timeoutId = setTimeout(() => {
          if (timeoutId === currentTimeoutId) {
            setMobileHidden(false);
          }
        }, 2000);
        currentTimeoutId = timeoutId;
      };

      document.addEventListener('scroll', toggleMobileHidden);
      return () => document.removeEventListener('scroll', toggleMobileHidden);
    }, [mobile]);

    const [mobileHiddenFromSuccess, setMobileHiddenFromSuccess] =
      useState(false);
    useEffect(() => {
      if (subscribeSuccess) {
        setTimeout(() => {
          setMobileHiddenFromSuccess(true);
        }, 2000);
      }
    }, [subscribeSuccess]);

    if (subscribed) {
      return null;
    }

    return (
      <S.SubscribeSection
        mobile={mobile}
        mobileHidden={mobileHidden || mobileHiddenFromSuccess}>
        Get weekly updates with the latest ML news.
        {subscribeSuccess ? (
          <S.SubscribeSuccessMessage>
            <WBIcon name="check" />
            Subscribed!
          </S.SubscribeSuccessMessage>
        ) : (
          <S.SubscribeInputContainer>
            <S.SubscribeInputMessageContainer loggedIn={loggedIn}>
              <S.SubscribeInput
                placeholder="Email Address"
                invalid={subscribeError !== ''}
                value={subscriptionInput}
                ref={subscriptionInputRef}
                onChange={
                  ((e, {value}) =>
                    setSubscriptionInput(value)) as StrictInputProps['onChange']
                }
              />
              <S.SubscribeInvalidMessage invalid={subscribeError !== ''}>
                {subscribeError}
              </S.SubscribeInvalidMessage>
            </S.SubscribeInputMessageContainer>
            <S.SubscribeButton
              content="Subscribe"
              onClick={createSubscription}
            />
          </S.SubscribeInputContainer>
        )}
      </S.SubscribeSection>
    );
  },
  {id: 'SubscriptionSection', memo: true}
);

type TabProps = {
  to: LinkProps['to'];
  active: boolean;
  color?: string;
  onClick?: () => void;
};

// We need to set a static width because the element actually grows when
// it's active (the non-monospaced font grows when font-weight: bold).
// On first render, always treat active === false, then immediately set
// static width to the element's width. Then, we use props.active on subsequent renders.
export const Tab: React.FC<TabProps> = ({
  to,
  active,
  color,
  onClick,
  children,
}) => {
  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLAnchorElement | null>(null);
  useLayoutEffect(() => {
    if (width != null) {
      return;
    }
    const el = ref.current;
    if (el != null) {
      setWidth(el.offsetWidth);
    }
  }, [width]);

  const {width: viewportWidth} = useWindowSize();
  const isMobile = viewportWidth <= MOBILE_WIDTH;
  useLayoutEffect(() => {
    if (width != null) {
      setWidth(null);
    }
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <S.Tab
      innerRef={ref}
      to={to}
      active={width != null ? active : false}
      color={color}
      width={width}
      onClick={onClick}>
      {children}
    </S.Tab>
  );
};

type PostWrapperProps = PostProps & {
  loading: boolean;
};

// This wrapper ensures that all the prop changes are synced before
// posts can be updated. Otherwise, there will be visual stutter.
export const PostWrapper: React.FC<PostWrapperProps> = props => {
  const [passthroughProps, setPassthroughProps] = useState(props);

  useLayoutEffect(() => {
    if (!props.loading) {
      setPassthroughProps(props);
    }
  }, [props]);

  return <Post {...passthroughProps} />;
};

type PostProps = ReportMetadata & {
  showDescription?: boolean;
  link?: string;
  activeTag?: string;
  tagIsCategory: (tagName: string) => boolean;
  getActiveTagPath?: (tagName: string) => string;
  onClick?: () => void;
  trackProps?: Omit<TrackProps, 'value'>;
};

export const Post: React.FC<PostProps> = ({
  displayName,
  authors,
  addedAt,
  tags,
  announcement,
  previewUrl,
  description,
  showDescription,
  link,
  activeTag,
  tagIsCategory,
  getActiveTagPath,
  onClick,
  trackProps,
}) => {
  const nonCategoryTags = tags?.filter(t => !tagIsCategory(t.name)) ?? [];

  const descriptionContent = description;
  const notDescriptionContent = (
    <>
      {authors?.map(({username, name}, i) => {
        const authorDisplayName = name || username;
        return (
          <React.Fragment key={username}>
            {i > 0 && ', '}
            {link != null ? (
              <Link
                to={`/${username}`}
                onClick={() => {
                  if (trackProps != null) {
                    track('clickPostAuthor', {...trackProps, value: username});
                  }
                }}>
                {authorDisplayName}
              </Link>
            ) : (
              <span>{authorDisplayName}</span>
            )}
          </React.Fragment>
        );
      })}
      <S.DotSeparator />
      {moment(addedAt).format('MMM D')}
      {nonCategoryTags.length > 0 && (
        <>
          <S.DotSeparator />
          {nonCategoryTags.map((t, i) => (
            <React.Fragment key={t.id}>
              {i > 0 && ', '}
              {getActiveTagPath != null ? (
                <Link
                  to={{
                    pathname: getActiveTagPath(t.name),
                    search: window.location.search,
                  }}
                  onClick={() => {
                    if (trackProps != null) {
                      track('clickPostTag', {...trackProps, value: t.name});
                    }
                  }}>
                  {t.name}
                </Link>
              ) : (
                <span>{t.name}</span>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );

  const content = (
    <S.Post>
      <S.PostTitle>
        {announcement ? (
          <S.PostFlair>Announcement</S.PostFlair>
        ) : (
          tags
            ?.filter(t => tagIsCategory(t.name) && t.name !== activeTag)
            .map(t =>
              getActiveTagPath != null ? (
                <S.PostFlairLink
                  key={t.id}
                  to={{
                    pathname: getActiveTagPath(t.name),
                    search: window.location.search,
                  }}
                  onClick={() => {
                    if (trackProps != null) {
                      track('clickPostFlair', {...trackProps, value: t.name});
                    }
                  }}>
                  {t.name}
                </S.PostFlairLink>
              ) : (
                <S.PostFlair key={t.id}>{t.name}</S.PostFlair>
              )
            )
        )}
        {displayName}
      </S.PostTitle>
      <S.PostMeta>
        {showDescription ? descriptionContent : notDescriptionContent}
      </S.PostMeta>
      <S.PostImage src={previewUrl || '/logo.png'} />
    </S.Post>
  );
  return link != null ? (
    <S.PostWrapperLink to={link} onClick={onClick}>
      {content}
    </S.PostWrapperLink>
  ) : (
    <S.PostWrapper onClick={onClick}>{content}</S.PostWrapper>
  );
};

type DropdownTriggerProps = {width: number; value: string};

export const DropdownTrigger: React.FC<DropdownTriggerProps> = ({
  width,
  value,
}) => {
  return (
    <S.Dropdown width={width}>
      {value} <S.DropdownIcon name="down" />
    </S.Dropdown>
  );
};

type MobileNavMenuIconProps = {
  onClick?: () => void;
};

export const MobileNavMenuIcon: React.FC<MobileNavMenuIconProps> = ({
  onClick,
}) => {
  return (
    <S.MobileNavMenuIcon onClick={onClick}>
      <S.MobileNavMenuIconBar />
      <S.MobileNavMenuIconBar />
      <S.MobileNavMenuIconBar />
    </S.MobileNavMenuIcon>
  );
};

function rowToSearchString({displayName}: ReportMetadata): string {
  return [displayName].join(' ');
}

function sortPosts(
  posts: ReportMetadata[],
  sortOrder: SortOrder
): ReportMetadata[] {
  return _.sortBy(posts, ({addedAt, starCount, recentStarCount}) => {
    switch (sortOrder) {
      case 'Popular':
        return -starCount;
      case 'Trending':
        return -recentStarCount;
      case 'Latest':
      default:
        const addedAtTS = new Date(addedAt ?? '').valueOf();
        return -addedAtTS;
    }
  });
}

type FilterAndSortState = {
  activeTag: string;
  sortOrder: SortOrder;
  language: Language;
};

type FilterAndSortStateFromURLProps = {
  match: Match;
  tagList: Tag[];
  homeCategoryName: string;
};

function getFilterAndSortStateFromURL({
  match,
  tagList,
  homeCategoryName,
}: FilterAndSortStateFromURLProps): FilterAndSortState {
  let computedActiveTag = homeCategoryName;
  let computedSortOrder: SortOrder = DEFAULT_SORT_ORDER;
  let computedLanguage: Language = DEFAULT_LANGUAGE;

  if (match.params.sortOrTag != null) {
    switch (match.params.sortOrTag) {
      case 'latest':
        computedSortOrder = 'Latest';
        break;
      case 'popular':
        computedSortOrder = 'Popular';
        break;
      case 'trending':
        computedSortOrder = 'Trending';
        break;
      default:
        // Not a sort order, but a tag instead
        computedActiveTag = formatGalleryTag(tagList, match.params.sortOrTag);
    }
  }
  if (match.params.tag != null) {
    computedActiveTag = formatGalleryTag(tagList, match.params.tag);
  }

  const {language} = qs.parse(window.location.search);
  if (
    language != null &&
    typeof language === 'string' &&
    isLanguage(language)
  ) {
    computedLanguage = language;
  }

  return {
    activeTag: computedActiveTag,
    sortOrder: computedSortOrder,
    language: computedLanguage,
  };
}
