import * as S from './TeamInviteModal.styles';

import React, {useState, useEffect, useCallback} from 'react';
import makeComp from '../util/profiler';
import {Button, Dropdown, Modal} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {useUpdateUserMutation, useInviteQuery} from '../generated/graphql';
import {propagateErrorsContext} from '../util/errors';
import {useViewer} from '../state/viewer/hooks';
import {notEmpty} from '@wandb/cg/browser/utils/obj';
import {auth} from '../setup';
import {setAuthRedirect} from '../util/auth';
import globalHistory from '../util/history';

interface TeamInviteModalProps {
  invite: string;
  teamName: string;
  toNewUser: boolean;
  onClose(): void;
}

const TeamInviteModal = makeComp(
  (props: TeamInviteModalProps) => {
    const {invite, toNewUser, teamName} = props;
    const [defaultEntity, setDefaultEntity] = useState<string>('');
    const [updatingUser, setUpdatingUser] = useState<boolean>(false);
    const [toUsername, setToUsername] = useState<string>('');
    const [unauthorizedInvite, setUnauthorizedInvite] =
      useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const viewer = useViewer();
    const [updateUser] = useUpdateUserMutation({
      context: propagateErrorsContext(),
    });

    // check if the invitation is sent to the same user as viewer
    const inviteResult = useInviteQuery({
      variables: {key: String(invite)},
      fetchPolicy: 'cache-and-network',
    });

    const onClose = useCallback(() => {
      setShowModal(false);
      props.onClose();
    }, [props]);

    useEffect(() => {
      // initialize defaultEntity to team
      if (defaultEntity === '') {
        setDefaultEntity(teamName);
      }
    }, [defaultEntity, teamName]);

    useEffect(() => {
      if (inviteResult.data != null) {
        setShowModal(true);
      }
    }, [inviteResult]);

    useEffect(() => {
      // toNewUser indicates if the user is back from signup with invitation
      // if so, we want to show the modal for sure so no-op here.
      if (showModal && !toNewUser) {
        if (inviteResult.data?.invite?.toUser == null) {
          if (viewer == null) {
            // invitation to a new user, redirect them to signup and come back to this page
            setAuthRedirect(document.location.href + '&newUser=true');
            globalHistory.push('/login?signup=true');
          } else {
            // a wrong logged-in user uses invitation to new user -> close modal
            onClose();
          }
        } else {
          // invitatation to an existing user, check if invitation is sent to the right user
          const username = inviteResult.data?.invite?.toUser?.username;
          if (username != null) {
            setToUsername(username);

            if (viewer?.username !== username) {
              setUnauthorizedInvite(true);
            }
          }
        }
      }
    }, [inviteResult, viewer, showModal, onClose, toNewUser]);

    // team options for default entity picker
    const teamEdges = viewer?.teams.edges.filter(notEmpty);
    const teamOptions = teamEdges?.map(e => ({
      text: e.node.name,
      value: e.node.name,
    }));

    return (
      <>
        {/* VALID INVITATION -> SHOW DEFAULT ENTITY PICKER MODAL */}
        <Modal size={'tiny'} open={showModal && !unauthorizedInvite}>
          <Modal.Header>Sending runs to this team</Modal.Header>
          <Modal.Content>
            <S.ContentWrapper>
              <S.Inline>
                Now that you're part of this team, we recommend updating this
                setting so your new runs go to the team automatically, instead
                of to your personal account.
              </S.Inline>
              <S.Inline>
                Set your default destination for new runs:
                <S.DropdownWrapper>
                  <Dropdown
                    selection
                    fluid
                    options={teamOptions}
                    value={defaultEntity}
                    onChange={(e, {value}) => {
                      setDefaultEntity(value as string);
                    }}
                  />
                </S.DropdownWrapper>
              </S.Inline>
              <S.Inline>
                You can change this later in your{' '}
                <Link to="/settings">account settings</Link>.
              </S.Inline>
              <S.ButtonWrapper>
                <Button onClick={onClose}>Close</Button>
                <Button
                  primary
                  loading={updatingUser}
                  disabled={updatingUser}
                  onClick={async () => {
                    setUpdatingUser(true);
                    try {
                      updateUser({
                        variables: {defaultEntity},
                      });
                      onClose();
                    } catch (e) {
                      console.error('ERROR UPDATING DEFAULT ENTITY', e);
                    } finally {
                      setUpdatingUser(false);
                    }
                  }}>
                  Update
                </Button>
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </Modal.Content>
        </Modal>
        {/* INVALID INVITATION -> SHOW WRONG USER MODAL */}
        <Modal size={'tiny'} open={showModal && unauthorizedInvite}>
          <Modal.Header>Incorrect account</Modal.Header>
          <Modal.Content>
            <S.ContentWrapper>
              <S.Inline>
                The <S.Bold>{teamName}</S.Bold> invitation was sent to username{' '}
                <S.Bold>{toUsername}</S.Bold>, but you're logged in as{' '}
                <S.Bold>{viewer?.username}</S.Bold>. Please log back in with
                your other account to accept this invitation.
              </S.Inline>
              <S.ButtonWrapper>
                <Button
                  primary
                  onClick={() => {
                    setAuthRedirect(document.location.href);
                    auth.login();
                  }}>
                  Log in as {toUsername}
                </Button>
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </Modal.Content>
        </Modal>
      </>
    );
  },
  {id: 'TeamInviteModal', memo: true}
);

export default TeamInviteModal;
