import * as d3 from 'd3';
import {format} from 'd3-format';
import {scaleLinear, scaleLog} from 'd3-scale';
import produce from 'immer';
import * as _ from 'lodash';
import {FancyLegendProps} from '../components/vis/FancyLegend';
import {RunsData} from '../containers/RunsDataLoader';
import * as globals from '../css/globals.styles';
import {RunWithRunsetInfo} from '../state/runs/types';
import {RunHistoryRow} from '../types/run';
import * as ColorUtil from '../util/colors';
import {CHART_MAX_X_BUCKETS} from '../util/constants';
import {
  configKeysInExpression,
  evaluateExpression,
  Expression,
  expressionToString,
  metricsInExpression,
  summaryKeysInExpression,
} from '../util/expr';
import {
  legendTemplateInsertCrosshairValues,
  legendTemplateRemoveCrosshairValues,
  legendTemplateToFancyLegendProps,
  parseLegendTemplate,
} from '../util/legend';
import * as Query from '../util/queryts';
import * as RunHelpers from '../util/runhelpers';
import * as Run from '../util/runs';
import {RunColorConfig} from '../util/section';
import {color} from './colors';
import {makeHistogram} from './histogram2';
import {LegendData} from './legend';
import {smooth, SmoothingType} from './math';
import {RunWithRunsetInfoAndHistory} from './runhelpers';
import {getValue, Key, keyToString} from './runs';
import {textWidth} from './text';

export const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];
export type PlotType = 'bar' | 'line' | 'stacked-area' | 'pct-area';
export type PlotFontSize = 'small' | 'medium' | 'large';
export type PlotFontSizeOrAuto = PlotFontSize | 'auto';

export function isFontSizeOrAuto(s: string): s is PlotFontSizeOrAuto {
  return s === 'small' || s === 'medium' || s === 'large' || s === 'auto';
}

export type LegendPosition = 'north' | 'south' | 'east' | 'west';
export function isLegendPosition(s: string): s is LegendPosition {
  return s === 'north' || s === 'south' || s === 'east' || s === 'west';
}

export type LegendOrientation = 'horizontal' | 'vertical';
export function isLegendOrientation(s: string): s is LegendOrientation {
  return s === 'horizontal' || s === 'vertical';
}

export type ChartAreaOption =
  | 'minmax'
  | 'stddev'
  | 'stderr'
  | 'none'
  | 'samples';
export function isChartAreaOption(s: string): s is ChartAreaOption {
  return (
    s === 'minmax' ||
    s === 'stddev' ||
    s === 'stderr' ||
    s === 'none' ||
    s === 'samples'
  );
}

export type ChartAggOption = 'mean' | 'min' | 'max' | 'median';
export function isChartAggOption(s: string): s is ChartAggOption {
  return s === 'mean' || s === 'min' || s === 'max' || s === 'median';
}

export type RunSetInfo = Pick<
  Query.RunSetQuery,
  'id' | 'grouping' | 'entityName' | 'projectName'
>;

export function isTimeKey(k: string) {
  return _.includes(TIME_KEYS, k);
}

export interface Point {
  x: number;
  y: number;
  smoothed?: number; // the smoothed value of y
  color?: number | undefined;
  y0?: number; // for a "point" that defines a span from y to y0
  smoothed0?: number; // smoothed value of y0
  legendData?: LegendData; // info showed realtime in legend on hover-over
}

export type Mark =
  | 'solid'
  | 'dashed'
  | 'dotted'
  | 'dotdash'
  | 'dotdotdash'
  | 'points';

export type Timestep = 'seconds' | 'minutes' | 'hours' | 'days';
export type AggregateCalculation =
  | 'mean'
  | 'minmax'
  | 'stddev'
  | 'stderr'
  | 'samples'
  | 'none';

export interface RunDataSeries {
  metricName?: string; // the name of the metric being plotted
  uniqueId?: string; // unique key for every run or group for setting color and overriding attributes
  color?: string;
  originalColor?: string; // set when color is overridden - should factor out
  mark?: Mark;
  originalMark?: Mark; // set when mark is overridden - should factor out
  lineWidth?: number; // thickness of line
  originalLineWidth?: number;
  title?: string; // legend template, i.e. my-run [[ ${x}: ${y} ]]
  originalTitle?: string; // set when title is overwritten by user in config
  displayName?: string; // This is the friendly name of the run or group
}

export type Line = {
  data: Point[];
  run?: RunWithRunsetInfo;
  timestep?: Timestep;
  smoothed?: boolean;
  name?: string; // name of the associated run or group
  type?: 'area' | 'heatmap' | 'scatter' | 'points' | 'line';
  aux?: boolean; // if aux set to true, don't show in legend overlay i.e. smoothing or minmax
  hidden?: boolean; // if true line wont show at all
  fancyTitle?: FancyLegendProps; // title to use in legend
  vars?: {[key: string]: number}; // extra variable in case computing aggregate config or summary
  // sometimes multiple lines are necessary for a visualization
  // for example showing the light original lines when smoothing
  // is added or the area plots of a grouped line.
  aggType?: AggregateCalculation;
  minmaxLine?: Line; // associated min and max line - should factor out
  stddevLine?: Line; // associated stddev line - should factor out
  stderrLine?: Line; // associated stderr line - should factor out
} & RunDataSeries;

export type Bar = {
  key: string; // the label
  value: number; // the value for a regular bar
  color?: string;
  uniqueId?: string; // unique key for setting color, overriding and highlighting
  quartiles?: [number, number, number, number, number]; // for boxplot
  bins?: Array<{bin: number; count: number}>; // for violinplot
  range?: [number, number]; // for error bars
  mean?: number;
  stddev?: number;
} & RunDataSeries;

export interface Scalar {
  key?: FancyLegendProps; // the label
  value: number; // the value for a regular bar
  color?: string;
  uniqueId?: string;
  range?: [number, number];
  stddev?: number;
  stderr?: number;
}

export enum XAxisValues {
  Step = '_step',
  RunTime = '_runtime',
  Timestamp = '_timestamp',
  AbsoluteRunTime = '_absolute_runtime',
}

const DEFAULT_VIOLIN_PLOT_BINS = 10;

/* This is for chunking the x axis and merging points in small windows of X */
interface BucketSpec {
  minX: number;
  maxX: number;
  bucketCount: number;
  alignedWithPoints: boolean;
}

enum GroupingType {
  Table,
  Panel,
  None,
}

function avg(arr: number[], defaultValue: number = NaN) {
  if (arr.length === 0) {
    return defaultValue;
  }
  return arr.reduce((a: number, b: number) => a + b, 0) / arr.length;
}

function median(arr: number[], defaultValue: number = NaN) {
  if (arr.length === 0) {
    return defaultValue;
  }

  const sorted = arr.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

function quartiles(arr: number[]): [number, number, number, number, number] {
  const sorted = arr.slice().sort((a, b) => a - b);
  const q1 = Math.floor(sorted.length * 0.25);
  const middle = Math.floor(sorted.length * 0.5);
  const q3 = Math.floor(sorted.length * 0.5);
  const max = sorted.length - 1;
  return [sorted[0], sorted[q1], sorted[middle], sorted[q3], sorted[max]];
}

function bin(arr: number[], numBins: number) {
  if (arr.length === 0) {
    return [];
  }
  const x = d3
    .scaleLinear()
    .domain(d3.extent(arr) as [number, number])
    .nice(numBins);
  const histogram = d3
    .histogram()
    .domain(x.domain() as [number, number])
    .thresholds(x.ticks(numBins));
  const bins = histogram(arr);
  const ret = bins.map(d => ({
    bin: ((d.x0 || 0) + (d.x1 || 0)) / 2.0,
    count: d.length,
  }));
  return ret;
}

function stddev(arr: number[]) {
  const m = avg(arr);
  return Math.sqrt(
    arr.reduce((sq, n) => {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (arr.length - 1)
  );
}

function stderr(arr: number[], defaultValue: number = 0) {
  if (arr.length === 0) {
    return defaultValue;
  }
  return stddev(arr) / Math.sqrt(arr.length);
}

function arrMax(arr: number[], defaultValue: number = -Infinity) {
  return arr.reduce((a: number, b: number) => Math.max(a, b), defaultValue);
}

function arrMin(arr: number[], defaultValue: number = Infinity) {
  return arr.reduce((a: number, b: number) => Math.min(a, b), defaultValue);
}

function argMax(arr: number[]) {
  return arr.reduce((m, c, i, ar) => (c > ar[m] ? i : m), 0);
}

function argMin(arr: number[]) {
  return arr.reduce((m, c, i, ar) => (c < ar[m] ? i : m), 0);
}

const maxHistoryKeyCount = 16;

export const defaultXAxisValues: string[] = [
  XAxisValues.RunTime,
  XAxisValues.AbsoluteRunTime,
  XAxisValues.Timestamp,
  XAxisValues.Step,
];

export const xAxisLabels: {[key: string]: string} = {
  [XAxisValues.Step]: 'Step',
  [XAxisValues.RunTime]: 'Relative Time (Process)',
  [XAxisValues.AbsoluteRunTime]: 'Relative Time (Wall)',
  [XAxisValues.Timestamp]: 'Wall Time',
};

export function xAxisLabel(key: string): string {
  const label = xAxisLabels[key];
  return label || key;
}

export function overrideLineTitles(
  lines: Line[],
  overrides: {[key: string]: string},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalTitle = l.title;
      if (lineKey in overrides) {
        l.title = overrides[lineKey];
        if (l.fancyTitle) {
          l.fancyTitle.legend = legendTemplateRemoveCrosshairValues(l.title);
        }
      }
    });
  });
}

export function overrideBarTitles(
  bars: Bar[],
  overrides: {[key: string]: string},
  useRunOrGroupId: boolean
): Bar[] {
  return produce(bars, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalTitle = l.title;
      if (lineKey in overrides) {
        l.title = overrides[lineKey];
        l.key = legendTemplateRemoveCrosshairValues(l.title);
      }
    });
  });
}

export function overrideBarColors(
  lines: Bar[],
  overrides: {[key: string]: {color: string; transparentColor: string}},
  useRunOrGroupId: boolean
): Bar[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );

      if (lineKey in overrides) {
        l.originalColor = l.color;
        l.color = overrides[lineKey].color;
      }
    });
  });
}

export function overrideLineColors(
  lines: Line[],
  overrides: {[key: string]: {color: string; transparentColor: string}},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );

      if (lineKey in overrides) {
        l.originalColor = l.color;
        if (l.aux) {
          l.color = overrides[lineKey].transparentColor;
        } else {
          l.color = overrides[lineKey].color;
        }
      }
    });
  });
}

export function overrideMarks(
  lines: Line[],
  overrides: {[key: string]: Mark},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalMark = l.mark;
      if (lineKey in overrides) {
        l.mark = overrides[lineKey];
      }
    });
  });
}

export function overrideLineWidths(
  lines: Line[],
  overrides: {[key: string]: number},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalLineWidth = l.lineWidth;
      if (lineKey in overrides) {
        l.lineWidth = overrides[lineKey];
      }
    });
  });
}

export function getLegendOverrideKeyBackwardsCompatible(
  line: RunDataSeries,
  useRunOrGroupId: boolean,
  overrides: {[key: string]: any}
) {
  // Previously we always used the useRunOrGroupId=false in the getLegendOverrideKey,
  // so we check for that first.  At some point perhaps we can remove this weird
  // logic but I really don't want to affect anyone's charts.  (This would not affect
  // reports or runs pages, so the risk isn't enormous)
  if (useRunOrGroupId === true) {
    return getLegendOverrideKey(line);
  }

  const oldKey = getLegendOverrideKey(line);
  if (oldKey in overrides) {
    return oldKey;
  }
  return getLegendOverrideKey(line, false);
}

export function getLegendOverrideKey(
  line: RunDataSeries,
  useRunOrGroupId: boolean = true
) {
  // Do not change this function! It is the key for the overrides
  // this is the key for the override maps

  // We don't want to use the runOrGroupId for a per run plot
  // because we want to keep the legend overrides when the
  // user changes the run - see: WB-3257
  if (useRunOrGroupId === false) {
    return line.metricName || '';
  }

  // line.uniqueId is unique per run, but we want our legend override
  // to distinguish between metrics in the case that a plot
  // has multiple metrics for a single line
  return (line.uniqueId || '') + ':' + (line.metricName || '');
}

interface Chart {
  config: {lines: string[]};
  layout: {x: number; y: number; w: number; h: number};
}

export function defaultRunCharts(metricNames: [string]) {
  let x = 0;
  let y = 0;
  const importantRegexes = [/loss/, /accuracy|acc$/, /n_success/];
  const charts: Chart[] = [];
  importantRegexes.forEach(regex => {
    const lines = metricNames.filter(n => n.match(regex));
    if (lines.length > 0) {
      charts.push({
        config: {lines},
        layout: {x, y, w: 6, h: 2},
      });
      x += 6;
      if (x === 12) {
        x = 0;
        y += 2;
      }
    }
  });
  if (charts.length === 1) {
    charts[0].layout.w = 12;
  }
  return charts;
}

export function prettyXAxisLabel(xAxis: string, lines: Line[]) {
  /*
   * Make a pretty xAxisLabel
   * Really all we do is:
   * 1) Change special keys _runtime and _timestamp and _step to
   * 2) take times and return (sec), (min) or (hour)
   * otherwise just return the label
   */

  if (!(xAxis in xAxisLabels)) {
    // LB: This is the common case
    return xAxis;
  }

  if (!lines || lines.length === 0) {
    // we can't figure out if its seconds or mins or whatever...
    return 'Time';
  }

  // all timesteps should be the same so we can just look at the first one
  const timestep = lines.length > 0 ? lines[0].timestep || '' : '';
  let label = xAxisLabels[xAxis] || '';
  if (xAxis === '_runtime' || xAxis === '_absolute_runtime') {
    label = 'Time' + (timestep ? ' (' + timestep + ')' : '');
  }

  return label;
}

export function appropriateTimestep(lines: Line[]) {
  /**
   * Returns "seconds", "minutes", "hours", "days" depending on the
   * max values of lines.  Only appropriate if the x axis is relative time.
   */

  if (!lines || lines.length === 0) {
    return 'seconds' as Timestep;
  }
  let maxTime = 0;
  lines.forEach(l => {
    const last = _.last(l.data);
    if (last && last.x > maxTime) {
      maxTime = last.x;
    }
  });
  if (maxTime < 60 * 10) {
    return 'seconds' as Timestep;
  } else if (maxTime < 60 * 60 * 10) {
    return 'minutes' as Timestep;
  } else if (maxTime < 60 * 60 * 24 * 100) {
    return 'hours' as Timestep;
  } else {
    return 'days' as Timestep;
  }
}

function timestepToFactor(timestep: Timestep) {
  if (timestep === 'seconds') {
    return 1.0;
  } else if (timestep === 'minutes') {
    return 60.0;
  } else if (timestep === 'hours') {
    return 60.0 * 60;
  } else if (timestep === 'days') {
    return 60.0 * 60 * 24;
  }
  return 1.0;
}

export function convertTimestepToSeconds(time: number, timestep: Timestep) {
  const factor = timestepToFactor(timestep);
  return time * factor;
}

export function convertSecondsToTimestep(lines: Line[], timestep?: Timestep) {
  /**
   * Converts all ther xAxis values to minutes hours or days by dividing by "factor"
   */
  if (timestep == null) {
    timestep = appropriateTimestep(lines);
  }
  const factor = timestepToFactor(timestep);
  lines.forEach(l => {
    (l.data as Point[]).forEach(p => {
      p.x = p.x / factor;
    });
    l.timestep = timestep;
  });
}

function filterNegative(lines: Line[]) {
  /**
   * Iterate over all the lines and remove non-positive values for log scale
   */
  // TODO: Check if the NaN works ok
  // TODO: This doesn't handle area graphs
  return lines.map((line, i) => {
    const newLine = line;
    newLine.data = line.data.map(point => {
      if (point.y <= 0) {
        point.y = NaN;
      }
      return point;
    });
    return newLine;
  });
}

export function convertLineToBar(line: Line) {
  // Used for when we create lines in lineplot but realize we actually want a bar plot
  // Need to translate the lines into bars

  const key =
    line.title != null
      ? legendTemplateRemoveCrosshairValues(line.title)
      : line.displayName ?? line.name ?? '';

  return {
    ...line,
    key,
    value: line.data[0].y,
    stddev: line.stddevLine?.data[0]?.y,
    range:
      line.minmaxLine != null
        ? [line.minmaxLine.data[0]?.y0, line.minmaxLine.data[0]?.y]
        : undefined,
  } as Bar;
}

export function smoothLine(
  line: Line,
  smoothingParam: number,
  smoothingType: SmoothingType
): Line {
  const pointsX = line.data.map(d => d.x);
  const pointsY = line.data.map(d => d.y);
  const pointsSmoothedY = smooth(
    pointsY,
    pointsX,
    smoothingParam,
    smoothingType
  );
  let pointsSmoothedY0: number[] | null;
  if (line.type === 'area') {
    const pointsY0 = line.data.map(d => d.y0 ?? d.y);

    pointsSmoothedY0 = smooth(pointsY0, pointsX, smoothingParam, smoothingType);
  }
  return {
    ...line,
    smoothed: true,
    data: line.data.map((point, i) => {
      return {
        x: pointsX[i],
        y: pointsSmoothedY[i],
        y0: pointsSmoothedY0?.[i],
        legendData: {
          original: pointsY[i],
        },
      };
    }),
  };
}

function smoothLines(
  lines: Line[],
  smoothingParam: number,
  smoothingType: SmoothingType
) {
  /**
   * Takes an array of lines and returns a new smoothed line for each
   * Line in the array.  Lightens the color of the original lines.
   */

  const smoothedLines: Line[] = lines.map(line =>
    smoothLine(line, smoothingParam, smoothingType)
  );

  const origLines = lines.map(line => ({
    ...line,
    aux: true,
  }));

  return [origLines, smoothedLines];
}

export function bucketsFromLines(lines: Line[]): BucketSpec | null {
  const maxLengthRun = arrMax(
    lines.map(line => line.data.length),
    0
  );

  const xVals = _.flatten(
    lines.map(line => line.data.map(point => point.x))
  ).filter(x => _.isFinite(x));

  if (xVals.length === 0) {
    return null;
  }

  let minX = arrMin(xVals);
  let maxX = arrMax(xVals);

  if (getBucketsShouldAlignWithPoints(lines)) {
    return {minX, maxX, bucketCount: maxLengthRun, alignedWithPoints: true};
  }

  // optimize bucketing strategy for the use case of epochs on the x-axis (one bucket per epoch):
  // - data points are plotted at each bucket's center, so we increase the range
  // by 0.5 on each end - this means we'll see data points precisely at epoch 0 and epoch maxEpoch
  // - set bucketCount to the total number of epochs
  // now enabling this for all x-axis keys because a priori we agree it should Just Work
  minX -= 0.5;
  maxX += 0.5;
  let bucketCount = maxLengthRun;
  // We have a hard-limit on how many buckets we'll have. As the number of points
  // exceeds this number the bucket center points will no longer be exactly aligned
  // with the data. But we MUST ensure that by the time we start sampling (at
  // CHART_SAMPLES points) we get at least 6 points per bucket.
  if (bucketCount > CHART_MAX_X_BUCKETS) {
    bucketCount = CHART_MAX_X_BUCKETS;
  }
  return {minX, maxX, bucketCount, alignedWithPoints: false};
}

// When all of the lines' x values line up perfectly, we can skip the whole bucketing
// process and simply treat each x value as a bucket with 1 point from each line.
// This also prevents jankery -- users don't expect grouping to change
// the x values if all the lines in the group share the same set of x values.
// This almost always returns false when sampling is in effect, as there is no way
// to guarantee sampling the same x values for every line.
function getBucketsShouldAlignWithPoints(lines: Line[]): boolean {
  if (lines.length === 0) {
    return false;
  }
  if (lines.length === 1) {
    return true;
  }

  const firstLine = lines[0];
  const restOfLines = _.tail(lines);

  const firstLineXValues = firstLine.data.map(p => p.x);
  return restOfLines.every(line => {
    const xValues = line.data.map(p => p.x);
    return _.isEqual(firstLineXValues, xValues);
  });
}

type BucketLinesResult = {
  bucketXValues: number[];
  mergedBuckets: number[][];
};

function bucketLines(lines: Line[], bucketSpec: BucketSpec): BucketLinesResult {
  /**
   * We aggregate lines by first bucketing them.  This is important when there
   * is sampling or when the x values don't line up.
   */
  const bucketXValues: number[] = []; // midpoint of each bucket
  let mergedBuckets: number[][] = []; // array of values for each bucket

  if (lines.length === 0) {
    return {bucketXValues, mergedBuckets};
  }

  const {minX, maxX, bucketCount, alignedWithPoints} = bucketSpec;

  if (alignedWithPoints) {
    // Treat each x value as a bucket with 1 point from each line
    const firstLine = lines[0];
    for (let i = 0; i < firstLine.data.length; i++) {
      bucketXValues.push(firstLine.data[i].x);
      const yValueFromAllLines = lines.map(line => line.data[i].y);
      mergedBuckets.push(yValueFromAllLines);
    }
    return {
      bucketXValues,
      mergedBuckets,
    };
  }

  // get all the data points in aligned buckets
  const bucketedLines: number[][] = lines.map((line, j) =>
    avgPointsByBucket(line.data, bucketCount, minX, maxX)
  );

  // do a manual zip because lodash's zip is not like python
  bucketedLines.map((bucket, i) =>
    bucket.forEach((b, j) => {
      mergedBuckets[j] ? mergedBuckets[j].push(b) : (mergedBuckets[j] = [b]);
    })
  );

  // remove NaNs
  mergedBuckets = mergedBuckets.map((xBucket, i) =>
    xBucket.filter(y => isFinite(y))
  );

  const inc = (maxX - minX) / bucketCount;
  mergedBuckets.forEach(
    (xBucket, i) => (bucketXValues[i] = minX + (i + 0.5) * inc)
  );

  return {bucketXValues, mergedBuckets};
}

function bucketLine(line: Line, bucketSpec: BucketSpec): Line {
  /* Takes a single line and buckets the xAxis */
  // TODO: Bucket aggregate vars
  const {minX, maxX, bucketCount, alignedWithPoints} = bucketSpec;

  if (alignedWithPoints) {
    return line;
  }

  const bucketedVals = avgPointsByBucket(line.data, bucketCount, minX, maxX);

  const inc = (maxX - minX) / bucketCount;
  const bucketedData: Point[] = bucketedVals.map((val, i) => ({
    x: minX + (i + 0.5) * inc,
    y: val,
  }));

  return {
    ...line,
    data: bucketedData,
  };
}

function aggregateLines(
  lines: Line[],
  bucketSpec: BucketSpec | null = null,
  name: string = '', // for the legend
  aggregateCalculations: AggregateCalculation[] = [],
  useMedian: boolean = false, // if true calculates median instead of mean
  extraVars: Key[] = []
) {
  /**
   * Takes in a bunch of lines and returns a line with
   * the name Mean + name that plots the average of all the lines passed in
   * as well as a line with a y0 and a y coordinate for react vis
   * representing the min and the max.
   */

  if (lines.length === 0) {
    throw new Error('Programming error: empty lines');
  }

  let bucketXValues: number[] = [];
  let mergedBuckets: number[][] = [];

  if (bucketSpec && bucketSpec.bucketCount > 0) {
    const ret = bucketLines(lines, bucketSpec);
    bucketXValues = ret.bucketXValues;
    mergedBuckets = ret.mergedBuckets;
  } else {
    const xVals = _.flatten(
      lines.map((line, j) => (line.data as Point[]).map(point => point.x))
    );
    // This should already be sorted
    // TODO: Remove
    bucketXValues = _.uniq(xVals).sort((a, b) => a - b);
    const xValToBucketIndex: {[key: number]: number} = {};
    bucketXValues.map((val, i) => (xValToBucketIndex[val] = i));

    // get all the data points in buckets
    lines.map((line, j) =>
      (line.data as Point[]).forEach(point => {
        const bucketIdx = xValToBucketIndex[point.x];
        mergedBuckets[bucketIdx]
          ? mergedBuckets[bucketIdx].push(point.y)
          : (mergedBuckets[bucketIdx] = [point.y]);
      })
    );
  }

  const lineData: Array<{x: number; y: number}> = [];
  const stddevData: Array<{x: number; y: number; y0: number}> = [];
  const stderrData: Array<{x: number; y: number; y0: number}> = [];
  const minmaxData: Array<{x: number; y: number; y0: number}> = [];

  const useMinmax = aggregateCalculations.find(c => c === 'minmax') != null;
  const useStderr = aggregateCalculations.find(c => c === 'stderr') != null;
  const useStddev = aggregateCalculations.find(c => c === 'stddev') != null;
  const useSamples = aggregateCalculations.find(c => c === 'samples') != null;

  for (let i = 0; i < mergedBuckets.length; i++) {
    const bucket = mergedBuckets[i];
    if (bucket.length === 0) {
      continue;
    }
    let avgVal: number;
    if (useMedian) {
      avgVal = median(bucket);
    } else {
      avgVal = avg(bucket);
    }
    lineData.push({x: bucketXValues[i], y: avgVal});

    if (useMinmax) {
      minmaxData.push({
        x: bucketXValues[i],
        y0: arrMin(bucket),
        y: arrMax(bucket),
      });
    }

    if (useStddev) {
      const stddevVal = stddev(bucket);
      const stddevValOrZero = isNaN(stddevVal) ? 0 : stddevVal;
      stddevData.push({
        x: bucketXValues[i],
        y0: avgVal - stddevValOrZero,
        y: avgVal + stddevValOrZero,
      });
    }

    if (useStderr) {
      const stderrVal = stderr(bucket);
      const stderrValOrZero = isNaN(stderrVal) ? 0 : stderrVal;

      stderrData.push({
        x: bucketXValues[i],
        y0: avgVal - stderrValOrZero,
        y: avgVal + stderrValOrZero,
      });
    }
  }

  let minmaxLine: Line | undefined;
  let stddevLine: Line | undefined;
  let stderrLine: Line | undefined;
  let sampleLines: Line[] | undefined;

  const aggregateExtraVars: {[key: string]: number} = {};
  extraVars.forEach(varKey => {
    const vals = lines
      .map(line =>
        line.vars != null ? line.vars[Run.keyToString(varKey)] : null
      )
      .filter(y => y != null && isFinite(y)) as number[];
    aggregateExtraVars[Run.keyToString(varKey)] = avg(vals);
  });

  if (useMinmax) {
    minmaxLine = {
      aggType: 'minmax',
      title: '',
      aux: true,
      data: minmaxData,
      type: 'area',
      run: lines[0].run,
      displayName: name,
      name,
    };
  }
  if (useStddev) {
    stddevLine = {
      aggType: 'stddev',
      title: '',
      aux: true,
      data: stddevData,
      type: 'area',
      run: lines[0].run,
      displayName: name,
      name,
    };
  }

  if (useStderr) {
    stderrLine = {
      aggType: 'stderr',
      title: '',
      aux: true,
      data: stderrData,
      type: 'area',
      run: lines[0].run,
      displayName: name,
      name,
    };
  }

  if (useSamples) {
    sampleLines = lines.map(l => {
      return {...l, aux: true, mark: 'dotted'};
    });
  }

  const meanLine: Line = {
    title: '',
    run: lines[0].run,
    data: lineData,
    aggType: 'mean',
    name,
    vars: aggregateExtraVars,
  };
  return {meanLine, minmaxLine, stddevLine, stderrLine, sampleLines};
}

export function avgPointsByBucket(
  points: Point[],
  bucketCount: number,
  min: number,
  max: number
): number[] {
  /**
   *  Takes a bunch of points with x and y vals, puts them into fixed width buckets and
   *  returns the average y value per bucket.
   */

  const l = points.length;

  const inc = (max - min) / bucketCount;
  const buckets: Point[][] = new Array(bucketCount);
  for (let i = 0; i < bucketCount; i++) {
    buckets[i] = [];
  }

  for (let i = 0; i < l; i++) {
    if (points[i].x === max) {
      buckets[bucketCount - 1].push(points[i]);
    } else {
      if (buckets[Math.floor((points[i].x - min) / inc)] != null) {
        buckets[Math.floor((points[i].x - min) / inc)].push(points[i]);
      } else {
        console.warn("Can't find ", Math.floor((points[i].x - min) / inc));
      }
    }
  }

  const avgBuckets = buckets.map((bucket, i) =>
    bucket.length > 0 ? avg(buckets[i].map((b, j) => b.y)) : NaN
  );
  return avgBuckets;
}

export function isHistoryArray(history: RunHistoryRow[], lineName: string) {
  return (
    history &&
    history[0] &&
    history[0][lineName] &&
    Array.isArray(history[0][lineName])
  );
}

export function isHistoryHistogram(history: RunHistoryRow[], lineName: string) {
  return (
    history &&
    history[0] &&
    history[0][lineName] &&
    history[0][lineName]._type === 'histogram'
  );
}

export function linesFromSystemMetricsPlot(
  events: string[],
  eventKeys: string[], // list of keys for find in events data structure
  xAxis: string,
  smoothingParam: number,
  smoothingType: SmoothingType = 'exponential',
  yAxisLog = false
) {
  const maxEventKeyCount: number = maxHistoryKeyCount;

  const eventNames = eventKeys
    .filter(lineName => !_.startsWith(lineName, '_') && !(lineName === 'epoch'))
    .slice(0, maxEventKeyCount);

  const eventLines = eventNames
    .map((lineName, i) => {
      const lineData = events
        .map(
          (row, j) =>
            ({
              // __index is a legacy name - we should remove it from the logic
              // here at some point.
              x:
                xAxis === '__index' || xAxis === '_step'
                  ? j
                  : Number(row[xAxis as any]),
              y: Number(row[lineName as any]),
            } as Point)
        )
        .filter(
          point =>
            !_.isNil(point.x) &&
            !_.isNil(point.y) &&
            !_.isNaN(point.x) &&
            !_.isNaN(point.y)
        );
      return {
        title: lineName,
        color: color(i + maxHistoryKeyCount),
        colorIndex: i + maxHistoryKeyCount,
        data: lineData,
      } as Line;
    })
    .filter(line => line.data.length > 0);

  const lines = eventLines;

  // TODO: The smoothing should probably happen differently if we're in log scale
  let allLines: Line[] = [];
  if (smoothingType !== 'none' && smoothingParam > 0) {
    const [origLines, smoothedLines] = smoothLines(
      lines,
      smoothingParam,
      smoothingType
    );
    allLines = _.concat(smoothedLines, origLines);
  } else {
    allLines = lines;
  }

  if (yAxisLog) {
    allLines = filterNegative(allLines);
  }

  if (xAxis === '_runtime' || xAxis === '_absolute_runtime') {
    convertSecondsToTimestep(allLines);
  } else if (xAxis === '_timestamp') {
    convertTimestampLinesToMiliseconds(allLines);
  }
  return allLines;
}

export function convertTimestampLinesToMiliseconds(lines: Line[]) {
  lines.forEach((line, i) => {
    line.data.forEach((points, j) => {
      points.x = points.x * 1000;
    });
  });
}

export function histogramFromHistory(
  history: RunHistoryRow[],
  xAxis: string,
  lineName: string
) {
  const line: Line = {data: [], title: ''};
  const validRows = history.filter(row => row[lineName] != null);
  /* Handle the case where history is an array or histogram */
  const min: number =
    _.min(
      validRows.map(row => {
        if (row[lineName]._type && row[lineName]._type === 'histogram') {
          return row[lineName].bins[0];
        }
        return _.min(row[lineName]);
      })
    ) || 0;
  const max: number =
    _.max(
      validRows.map(row => {
        if (row[lineName]._type && row[lineName]._type === 'histogram') {
          return _.last(row[lineName].bins);
        }
        return _.max(row[lineName]);
      })
    ) || 0;
  const numBuckets = 32;

  const visitedX = new Set<number>();
  let j = 0;
  for (const row of validRows) {
    // TODO: we were checking __index / _step here, seems unnecessary
    const x = (row[xAxis] as number) ?? j;
    // if same x exists in mulptiple rows with diff step values, show the first row
    if (visitedX.has(x)) {
      continue;
    }
    visitedX.add(x);
    const values = row[lineName];
    const {counts, binEdges} = makeHistogram(values, numBuckets, min, max);
    counts.forEach((count, k) => {
      if (binEdges[k] !== null && !_.isNaN(binEdges[k])) {
        const y: number = binEdges[k];
        line.data.push({
          x,
          y,
          color: count,
        });
      }
    });
    j++;
  }
  line.type = 'heatmap';
  return line;
}

export function isMonotonicIncreasing(arr: number[]) {
  const n = arr.length;
  let i = 1;
  while (i < n && arr[i] - arr[i - 1] >= 0) {
    i++;
  }
  return i === n;
}

export function linesFromRunsets(props: {
  runsetRuns: RunWithRunsetInfoAndHistory[];
  key: string | string[];
  xAxis: string;
  smoothingParam: number;
  smoothingType: SmoothingType;
  groupKeys: Key[];
  yAxisLog: boolean;
  keepPreSmoothingLines: boolean;
  aggregateCalculations: AggregateCalculation[];
  useMedian: boolean;
  extraVars: Key[];
}) {
  /**
   * Takes in data points in runsetrun format and returns lines.
   * Also does smoothing and bucketing.
   *
   * Inputs
   * data - data structure with all the runs
   * key - yAxis value
   * xAxis - xAxis value
   * smoothingParam - (number [0,1])
   * groupKeys - what config parameters should we aggregate by
   * yAxisLog - is the yaxis log scale - this is to remove non-positive values pre-plot
   */

  const {
    runsetRuns,
    key,
    xAxis,
    smoothingParam,
    smoothingType,
    groupKeys,
    yAxisLog,
    keepPreSmoothingLines,
    aggregateCalculations,
    useMedian,
    extraVars,
  } = props;

  if (runsetRuns.length === 0) {
    return {lineCount: 0, lines: []};
  }

  const allKeys = Array.isArray(key) ? key : [key];

  let lines: Line[] = [];
  for (const k of allKeys) {
    // Typically there is just one key passed in.
    // Multiple keys happens when user wants to aggregate over multiple metrics.
    const newLines: Line[] = runsetRuns.map(runsetRun => {
      let lineData: Array<{x: any; y: any}> = [];
      if (xAxis === '_absolute_runtime') {
        // calcuate wall time from run started
        const firstRow = runsetRun.history[0];
        if (firstRow != null) {
          const startTime =
            (firstRow._timestamp || 0) - (firstRow._runtime || 0);
          lineData = (runsetRun.history || [])
            .map((row, j) => ({
              x: (row._timestamp || NaN) - startTime,
              y: row[k],
            }))
            .filter(point => !_.isNil(point.y) && _.isFinite(point.y));
        }
      } else {
        // normal case
        lineData = (runsetRun.history || [])
          .map((row, j) => ({
            x: row[xAxis],
            y: row[k],
          }))
          .filter(point => !_.isNil(point.y) && _.isFinite(point.y));
      }

      const vars: {[key: string]: number} = {};
      extraVars.forEach(varKey => {
        vars[Run.keyToString(varKey)] = Number(
          Run.getValueSafe(runsetRun, varKey)
        );
      });

      return {
        title: '',
        type: 'line',
        name: runsetRun.name,
        displayName: runsetRun.displayName,
        run: runsetRun,
        data: lineData,
        vars,
      };
    });
    lines = lines.concat(newLines);
  }

  let lineCount = lines.length;

  lines.forEach(line => {
    line.data.sort((a, b) => {
      return a.x - b.x;
    });

    // remove duplicate x values
    line.data = line.data.filter((d, i) => {
      return i === 0 || d.x !== line.data[i - 1].x;
    });
  });

  if (xAxis === '_timestamp') {
    convertTimestampLinesToMiliseconds(lines);
  }

  if (groupKeys.length > 0 || Array.isArray(key)) {
    // If we're sampling we bucket the data into buckets of xaxis values
    // now do this regardless of number of points and regardless of x-axis key
    let buckets: BucketSpec | null = null;
    buckets = bucketsFromLines(lines);

    const groupedLines = _.groupBy(lines, l =>
      // Can use not-null type assertion because we know we set l.run above.
      JSON.stringify(groupKeys.map(gKey => getValue(l.run!, gKey)))
    );
    lines = _.flatMap(groupedLines, gLines => {
      if (gLines.length === 0) {
        return [];
      }
      // groupKeysStr is the nice display name for the group when the user configures
      // the legend tab. This is not what gets persisted in the panel config.
      const groupKeysStr =
        gLines[0].run != null
          ? Run.groupedRunDisplayName(gLines[0].run, groupKeys)
          : '';

      const allAggLines = aggregateLines(
        gLines,
        buckets,
        groupKeysStr,
        aggregateCalculations,
        useMedian,
        extraVars
      );
      return [
        allAggLines.meanLine,
        allAggLines.minmaxLine,
        allAggLines.stddevLine,
        allAggLines.stderrLine,
        allAggLines.sampleLines,
      ]
        .flat()
        .filter(l => l != null) as Line[];
    });

    lineCount = lines.filter(l => !l.aux).length;
  }
  let allLines;
  if (smoothingParam > 0) {
    const [origLines, smoothedLines] = smoothLines(
      lines,
      smoothingParam,
      smoothingType
    );
    allLines = keepPreSmoothingLines
      ? _.concat(smoothedLines, origLines)
      : smoothedLines;
  } else {
    allLines = lines;
  }

  if (yAxisLog) {
    allLines = filterNegative(allLines);
  }

  return {lines: allLines, lineCount};
}

interface LineResult {
  lineCount: number;
  linesByKeyAndMetricName: Line[][][];
  groupingType: GroupingType;
  groupKeys: Run.Key[];
}

function lineResultsForRunset(
  runs: RunsData['filtered'],
  histories: RunsData['histories'],
  props: LinesFromDataProps,
  q?: RunSetInfo
): LineResult {
  const {
    panelAggregate,
    aggregateMetrics,
    groupBy,
    xExpression,
    expressions,
    smoothingParam,
    smoothingType,
    xAxis,
    yLogScale,
    aggregateCalculations,
    groupLine,
    yAxis,
  } = props;
  const useMedian = groupLine === 'median';
  const allExpressions = _.concat(
    expressions || [],
    xExpression != null ? [xExpression] : []
  );
  const extraVars = _.flatten(
    allExpressions.map(expr =>
      _.concat(configKeysInExpression(expr), summaryKeysInExpression(expr))
    )
  );

  const groupKeys = panelAggregate
    ? [Run.key('config', groupBy)]
    : _.get(q, 'grouping') || [];
  const grouped = groupKeys.length > 0 || aggregateMetrics;
  const runsetLines = {lines: [] as Line[], lineCount: 0};

  // yAxis is an array of strings corresponding to multiple metrics
  // Addings the metrics for optional expressions
  const {expressionMetricIdentifiers, xExpressionMetricIdentifiers} =
    getMetricIdentifiersFromExpressions(expressions, xExpression);
  const metrics = getAllMetrics(
    yAxis,
    expressionMetricIdentifiers,
    xExpressionMetricIdentifiers
  );

  if (aggregateMetrics) {
    const runsetLinesForY = linesFromRunsets({
      runsetRuns: RunHelpers.runsetData(runs, histories, q ? q.id : undefined),
      key: metrics,
      xAxis,
      smoothingParam,
      smoothingType,
      groupKeys,
      yAxisLog: yLogScale != null ? yLogScale : false,
      keepPreSmoothingLines: !grouped,
      aggregateCalculations,
      useMedian,
      extraVars,
    });
    runsetLinesForY.lines.forEach(line => {
      line.metricName = metrics.join(' ');
    });
    runsetLines.lines = _.concat(runsetLines.lines, runsetLinesForY.lines);
    runsetLines.lineCount += runsetLinesForY.lineCount;
  } else {
    metrics.forEach(y => {
      const runsetLinesForY = linesFromRunsets({
        runsetRuns: RunHelpers.runsetData(
          runs,
          histories,
          q ? q.id : undefined
        ),
        key: y,
        xAxis,
        smoothingParam,
        smoothingType,
        groupKeys,
        yAxisLog: yLogScale != null ? yLogScale : false,
        keepPreSmoothingLines: !grouped,
        aggregateCalculations,
        useMedian,
        extraVars,
      });
      runsetLinesForY.lines.forEach(line => {
        line.metricName = y;
      });
      runsetLines.lines = _.concat(runsetLines.lines, runsetLinesForY.lines);
      runsetLines.lineCount += runsetLinesForY.lineCount;
    });
  }

  let linesGroupedByName = _.values(
    _.groupBy(runsetLines.lines, line => line.name)
  );

  // handle expressions for x value
  if (xExpression != null) {
    linesGroupedByName.forEach(lineGroup => {
      const filteredLineGroup = lineGroup.filter(l => !l.aux);
      if (filteredLineGroup.length === 0) {
        return;
      }

      const xValsToY = new Map<number, {[key: string]: number}>();
      filteredLineGroup.forEach(line => {
        line.data.forEach(p => {
          if (
            xExpressionMetricIdentifiers.find(
              identifier => identifier === line.metricName
            )
          ) {
            const obj = xValsToY.get(p.x);

            if (obj == null) {
              const newObj: {[key: string]: number} = {};
              if (line.vars != null) {
                Object.entries(line.vars).forEach(([key, value]) => {
                  newObj[key] = value;
                });
              }
              newObj[line.metricName || ''] = p.y;

              xValsToY.set(p.x, newObj);
            } else {
              obj[line.metricName || ''] = p.y;
            }
          }
        });
      });
      // Sorts the x values
      // Todo might want to not sort in the case of a non-monotonic x
      lineGroup.forEach(line => {
        line.data.forEach(p => {
          if (xValsToY.get(p.x) != null) {
            p.x = evaluateExpression(xExpression, xValsToY.get(p.x) || {});
          }
        });
      });

      // We make extra lines to use for the xExpression now we need to delete them
      // if they aren't used somewhere else
      runsetLines.lines = runsetLines.lines.filter(line => {
        return (
          !_.includes(xExpressionMetricIdentifiers, line.metricName) ||
          _.includes(yAxis, line.metricName) ||
          _.includes(expressionMetricIdentifiers, line.metricName)
        );
      });

      linesGroupedByName = _.values(
        _.groupBy(runsetLines.lines, line => line.name)
      );
    });
  }

  // Then map the line data according to the expression
  const exprLines: Line[] = [];

  // If there is an expression with multiple metrics, we need to sample down the lines so that they line up for the expression
  let multiMetricExpression = false;
  if (expressions != null && expressions.length > 0) {
    expressions.forEach(expr => {
      const metricIdentifiers = expr != null ? metricsInExpression(expr) : [];
      if (metricIdentifiers.length > 1) {
        multiMetricExpression = true;
      }
    });
  }

  if (multiMetricExpression) {
    // deal with the fact that the samples don't line up
    // we make a smaller set of buckets and compute an average value across the buckets

    const allLines = linesGroupedByName.flat();
    const buckets = bucketsFromLines(allLines);
    if (buckets != null) {
      const sampledLines = allLines.map(line => {
        return bucketLine(line, buckets);
      });
      linesGroupedByName = _.values(_.groupBy(sampledLines, line => line.name));
    }
  }

  // If expressions are set, we show the derived expressions instead of the metrics
  // This section could be made much more performant if it becomes a bottleneck
  if (expressions != null && expressions.length > 0) {
    expressions.forEach(expr => {
      const metricIdentifiers = expr != null ? metricsInExpression(expr) : [];

      linesGroupedByName.forEach(lineGroup => {
        const filteredLineGroup = lineGroup.filter(l => !l.aux);

        const xValsToY = new Map<number, {[key: string]: number}>();
        filteredLineGroup.forEach(line => {
          line.data.forEach(p => {
            if (
              metricIdentifiers.find(
                identifier => identifier === line.metricName
              )
            ) {
              const obj = xValsToY.get(p.x);

              if (obj == null) {
                const newObj: {[key: string]: number} = {};
                if (line.vars != null) {
                  Object.entries(line.vars).forEach(([key, value]) => {
                    newObj[key] = value;
                  });
                }

                newObj[line.metricName || ''] = p.y;

                xValsToY.set(p.x, newObj);
              } else {
                obj[line.metricName || ''] = p.y;
              }
            }
          });
        });
        const exprData: Point[] = [];
        const keys = Array.from(xValsToY.keys());
        // Requires x values to be sorted, but they already are
        keys.forEach(key => {
          exprData.push({
            x: key,
            y: evaluateExpression(expr, xValsToY.get(key) || {}),
          });
        });

        const exprStr = expressionToString(expr);

        const newLine = {
          name: filteredLineGroup[0].name,
          run: filteredLineGroup[0].run,
          title: filteredLineGroup[0].name + ': ' + exprStr,
          metricName: exprStr,
          data: exprData,
        } as Line;
        exprLines.push(newLine);
      });
    });
  }

  let normalOrExprLines = runsetLines.lines;
  if (exprLines.length > 0) {
    normalOrExprLines = exprLines;
  }

  const namedLines = normalOrExprLines.map(line => {
    return {
      ...line,
      uniqueId: Run.uniqueId(line.run!, groupKeys),
    };
  });

  const groupingType = panelAggregate
    ? GroupingType.Panel
    : grouped
    ? GroupingType.Table
    : GroupingType.None;

  // Finally, pair all mean & average lines together to make it easier to
  // do coloring later.
  let groupedLines: Line[][];
  groupedLines = _.values(
    _.groupBy(namedLines, line => `${line.uniqueId ?? ''}:${line.metricName}`)
  );

  const groupedLinesByKeyAndMetric = groupedLines.map(lg =>
    _.values(_.groupBy(lg, line => line.metricName))
  );

  return {
    lineCount: runsetLines.lineCount,
    linesByKeyAndMetricName: groupedLinesByKeyAndMetric, // [runOrGroupKey][metricName][line]
    groupingType,
    groupKeys,
  };
}

export interface LinesFromDataProps {
  groupBy: string;
  maxRuns: number;
  smoothingParam: number;
  smoothingType: SmoothingType;
  showOriginalAfterSmoothing: boolean;
  legendFields?: string[];
  customRunColors?: RunColorConfig;
  runSets?: RunSetInfo[];
  xAxis: string;
  yAxis: string[];
  yLogScale?: boolean;
  panelAggregate?: boolean;
  aggregateMetrics: boolean;
  expressions?: Expression[];
  xExpression?: Expression;
  singleRun: boolean;
  legendTemplate: string;
  entityName?: string;
  projectName?: string;
  plotType?: PlotType;
  zoomTimestep: Timestep | null;
  groupLine: 'mean' | 'min' | 'max' | 'median' | 'samples';
  groupArea: AggregateCalculation;
  aggregateCalculations: AggregateCalculation[];
  colorEachMetricDifferently: boolean;
}

type LinesFromDataResult = [Line[], number];

// The client-side grouping logic is currently duplicated in components/Export.historyQueryToTable.
// When updating the grouping logic in either place, make sure the other stays in sync.
export function getLinesFromData(
  // WARNING: Do not change the structure of this argument list!
  // We use a the second argument to memoize to reference compare data,
  // but deep compare everything in props. See the memoize wrapped around
  // this in the Graph component.
  runs: RunsData['filtered'],
  histories: RunsData['histories'],
  props: LinesFromDataProps
): LinesFromDataResult {
  /**
   * The purpose of this function is to help PanelRunsLinePlot take the
   * filtered runs data returned by the server query and turn it into
   * Line data that the LinePlot component can use to visualize.
   * This functionality has expanded a lot from tons of user feature requests
   * and could really use a refactor.
   */
  const {
    maxRuns,
    showOriginalAfterSmoothing,
    customRunColors,
    runSets,
    xAxis,
    yAxis,
    expressions = [],
    xExpression,
    singleRun,
    legendTemplate,
    entityName,
    projectName,
    plotType,
    zoomTimestep,
    groupLine,
    groupArea,
    colorEachMetricDifferently,
  } = props;

  const histogramResult = getLinesFromHistogramData(histories, xAxis, yAxis);
  if (histogramResult != null) {
    return histogramResult;
  }

  const useMedian = groupLine === 'median';

  // extra variables to collect for expressions
  const allExpressions = [...expressions];
  if (xExpression != null) {
    allExpressions.push(xExpression);
  }

  const extraVars: Key[] = [];
  for (const expr of allExpressions) {
    extraVars.push(...configKeysInExpression(expr));
    extraVars.push(...summaryKeysInExpression(expr));
  }

  let lineResults: LineResult[];
  const runSetByID: {[id: string]: RunSetInfo} = {};
  if (runSets != null) {
    // When we have a runset (everywhere but the run page), map over the runsets.
    lineResults = runSets.map(runset =>
      lineResultsForRunset(runs, histories, props, runset)
    );
    runSets.forEach(rs => (runSetByID[rs.id] = rs));
  } else {
    // Else we're in the run page and there's no runset.
    lineResults = [lineResultsForRunset(runs, histories, props, undefined)];
  }

  const rootUrl =
    entityName != null && projectName != null
      ? `/${entityName}/${projectName}/runs`
      : null;

  // Set legends for charts
  // TODO(axel): This entire block goes deep into lineResults and mutates data.
  // We should move to more immutability so that the value of lineResults is more consistent.
  lineResults.forEach(lr => {
    const lineGroups = lr.linesByKeyAndMetricName;
    // a line group has a main line along with possibly minmax, stddev and smoothed
    lineGroups.forEach((linesGroupByMetric: Line[][]) => {
      linesGroupByMetric.forEach(lineGroup => {
        const stddevLine = lineGroup.find(line => line.aggType === 'stddev');
        const minmaxLine = lineGroup.find(line => line.aggType === 'minmax');
        const stderrLine = lineGroup.find(line => line.aggType === 'stderr');

        const mainLine = lineGroup.find(
          line => !line.aux // line.aggType == null || line.aggType === 'mean'
        );

        if (mainLine?.run != null) {
          mainLine.title = parseLegendTemplate(
            legendTemplate,
            true,
            mainLine.run,
            lr.groupKeys,
            prettifyMetricName(mainLine.metricName || '')
          );
          const runSet = runSetByID[mainLine.run.runsetInfo.id];
          const runRootURL =
            runSet != null
              ? `/${runSet.entityName ?? entityName}/${
                  runSet.projectName ?? projectName
                }/runs`
              : null;
          mainLine.fancyTitle = legendTemplateToFancyLegendProps(
            legendTemplate,
            mainLine.run,
            lr.groupKeys,
            prettifyMetricName(mainLine.metricName || ''),
            runRootURL ?? rootUrl ?? undefined
          );
          mainLine.stderrLine = stderrLine;

          mainLine.stddevLine = stddevLine;
          mainLine.minmaxLine = minmaxLine;
        }

        // LB Should clean up this logic into pipeline steps
        if (groupLine === 'min' && minmaxLine != null && mainLine != null) {
          mainLine.data = minmaxLine.data.map(point => {
            return {x: point.x, y: point.y0 || 0};
          });
        } else if (
          groupLine === 'max' &&
          minmaxLine != null &&
          mainLine != null
        ) {
          mainLine.data = minmaxLine.data.map(point => {
            return {x: point.x, y: point.y};
          });
        }

        // set all the secondary lines to hidden
        if (stddevLine != null) {
          stddevLine.hidden = true;
        }
        if (minmaxLine != null) {
          minmaxLine.hidden = true;
        }
        if (stderrLine != null) {
          stderrLine.hidden = true;
        }

        // unhide based on groupArea
        if (groupArea === 'minmax') {
          if (minmaxLine != null) {
            minmaxLine.hidden = false;
          }
        }
        if (groupArea === 'stddev') {
          if (stddevLine != null) {
            stddevLine.hidden = false;
          }
        }
        if (groupArea === 'stderr') {
          if (stderrLine != null) {
            stderrLine.hidden = false;
          }
        }
      });
    });
    lr.linesByKeyAndMetricName = lineGroups.map(lgKeyMetric =>
      lgKeyMetric.map(lg => lg.filter(l => !l.hidden))
    );
  });

  const lineCount = _.sum(lineResults.map(lr => lr.lineCount));

  // TODO(axel): This entire block goes deep into lineResults and mutates data.
  // We should move to more immutability so that the value of lineResults is more consistent.
  if (
    xAxis === '_runtime' ||
    (xAxis === '_absolute_runtime' && xExpression == null)
  ) {
    // scale horizontally based on timestep
    const flattenedLines = _.flatten(
      _.flatten(_.flatten(lineResults.map(lr => lr.linesByKeyAndMetricName)))
    );
    if (zoomTimestep != null) {
      // while zooming we don't want to change our timestep
      convertSecondsToTimestep(flattenedLines, zoomTimestep);
    } else {
      convertSecondsToTimestep(flattenedLines);
    }
  }

  let coloredInLines;
  if (singleRun) {
    // In a singleRun plot we assign colors to all the metrics
    coloredInLines = getLinesColoredByMetric(lineResults);
  } else {
    // In a multi run plot we want each run to have a different color
    // But the metrics all have the same colors
    coloredInLines = getLinesColoredByRun(
      lineResults,
      colorEachMetricDifferently,
      customRunColors
    );
  }

  let lines: Line[] = [];
  // Extract 'maxRuns' lines from the results.
  let linesRemaining: number = maxRuns * (yAxis.length + expressions.length);
  for (const groupedLines of _.flatten(coloredInLines)) {
    if (linesRemaining <= 0) {
      break;
    }
    lines.push(...groupedLines);
    linesRemaining -= groupedLines.filter(line => !line.aux).length;
  }

  // Filter out original lines after smoothing, if applicable.
  if (!showOriginalAfterSmoothing) {
    lines = lines.filter(line => line.smoothed);
  }

  // Set some lines to dashed/dotted for visual distinction.
  const allLineColors = lines.map(l => l.color);
  const lineMarkOptions: Mark[] = [
    'solid',
    'dashed',
    'dotted',
    'dotdash',
    'dotdotdash',
  ];
  lines
    .filter(l => !l.aux)
    .forEach((line, i) => {
      // Find the number of previous lines that are the same color as this line
      const sameColorCount = allLineColors
        .slice(0, i)
        .filter(col => col === line.color).length;
      // Round-robin assignment of mark options
      line.mark = lineMarkOptions[sameColorCount % lineMarkOptions.length];
    });

  // make stacked area or percent area charts
  if (
    lines.length > 0 &&
    (plotType === 'stacked-area' || plotType === 'pct-area')
  ) {
    // crazy way of dealing with the fact that the samples don't line up
    // we make some buckets and compute an average value across the buckets
    const buckets = bucketsFromLines(lines);
    const xToSum: {[key: number]: number} = {};
    // Make it a stacked area chart
    lines = lines
      .filter(line => !line.aux)
      .map(line => {
        const aggLine = aggregateLines(
          [line],
          buckets,
          '',
          [],
          useMedian,
          extraVars
        ).meanLine;

        line.type = 'area';
        line.data = aggLine.data.map(point => {
          const newPoint = {
            ...point,
            y0: xToSum[point.x] ?? 0,
            y: point.y + (xToSum[point.x] ?? 0),
            legendData: {
              y: point.y,
              total: point.y + (xToSum[point.x] ?? 0),
            },
          };
          xToSum[point.x] = point.y + (xToSum[point.x] ?? 0);
          return newPoint;
        });
        return line;
      });

    if (plotType === 'pct-area') {
      lines = lines.map(line => {
        line.data = line.data.map(point => {
          const newPoint = {
            ...point,
            y0: (point.y0 ?? 0) / xToSum[point.x],
            y: point.y / xToSum[point.x],
            legendData: {
              y: Number(point.legendData?.y),
              percent:
                ((Number(point.legendData?.y) ?? 0) / xToSum[point.x]) * 100,
              total: point.y / xToSum[point.x],
            },
          };
          return newPoint;
        });
        return line;
      });
    }
  }

  return [lines, lineCount];
}

// Check if we are plotting a histogram in which case we have different
// logic.  We can only plot a histogram for a single run and a single
// metric.
// We may want to make histogram its own panel type.
function getLinesFromHistogramData(
  histories: RunsData['histories'],
  xAxis: string,
  yAxis: string[]
): LinesFromDataResult | null {
  if (!isHistogram(histories, yAxis)) {
    return null;
  }
  const line: Line = histogramFromHistory(
    histories.data[0].history,
    xAxis,
    yAxis[0]
  );
  line.color = ColorUtil.color(0);
  return [[line], 1];
}

function isHistogram(
  histories: RunsData['histories'],
  yAxis: string[]
): boolean {
  if (yAxis.length !== 1) {
    return false;
  }
  return (
    histories.data.length > 0 &&
    (isHistoryArray(histories.data[0].history, yAxis[0]) ||
      isHistoryHistogram(histories.data[0].history, yAxis[0]))
  );
}

function getLinesColoredByMetric(lineResults: LineResult[]): Line[][][] {
  let lineColorIdx = 0;
  return _.flatten(
    lineResults.map(lr => {
      return lr.linesByKeyAndMetricName.map(linesGroupByMetric => {
        return linesGroupByMetric.map(lineGroup => {
          const newLineGroup = lineGroup.map(l => {
            const newLine = {
              ...l,
              color: ColorUtil.color(lineColorIdx, l.aux ? 0.1 : undefined),
            } as Line;

            return newLine;
          });
          lineColorIdx += 1;
          return newLineGroup;
        });
      });
    })
  );
}

function getLinesColoredByRun(
  lineResults: LineResult[],
  colorEachMetricDifferently: boolean,
  customRunColors?: RunColorConfig
): Line[][][] {
  let lineColorIdx = 0;
  return _.flatten(
    lineResults.map(lr => {
      if (
        lr.groupingType === GroupingType.Panel ||
        customRunColors == null ||
        colorEachMetricDifferently
      ) {
        // If we're doing panel grouping OR old-stype coloring, apply round-robin colors.
        // There's extra nesting here so that we can capture mean & area lines together.
        const coloredLinesByKeyAndMetricName = lr.linesByKeyAndMetricName.map(
          linesGroupByMetric => {
            const coloredLineGroupByMetric: Line[][] = linesGroupByMetric.map(
              lineGroup => {
                const coloredLineGroup = lineGroup.map(l => {
                  const newLine = {
                    ...l,
                    color: ColorUtil.color(
                      lineColorIdx,
                      l.aux ? 0.1 : undefined
                    ),
                  } as Line;
                  return newLine;
                });
                if (colorEachMetricDifferently) {
                  lineColorIdx++;
                }
                return coloredLineGroup;
              }
            );
            if (!colorEachMetricDifferently) {
              lineColorIdx++;
            }
            return coloredLineGroupByMetric;
          }
        );
        return coloredLinesByKeyAndMetricName;
      } else {
        // Otherwise, we're doing new coloring either grouped or ungrouped. In either case,
        // it's the same codepath.
        const colLines = lr.linesByKeyAndMetricName.map(linesGroupByMetric => {
          const cl = linesGroupByMetric.map(lineGroup => {
            const newLineGroup = lineGroup.map(l => {
              const newLine = {
                ...l,
                color: ColorUtil.runColor(
                  l.run!,
                  lr.groupKeys,
                  customRunColors,
                  l.aux ? 0.1 : undefined
                ),
              } as Line;
              return newLine;
            });

            return newLineGroup;
          });
          lineColorIdx++;
          return cl;
        });
        return colLines;
      }
    })
  );
}
interface RunDataPoint {
  run: RunWithRunsetInfo;
  value: number;
  metricName: string;
  runOrGroupUniqueId: string;
  runOrGroupDisplayName: string;
}

type GroupDataPoint = RunDataPoint & {
  groupKeys: Run.Key[];
  stddev?: number;
  stderr?: number;
  mean?: number;
  quartiles?: [number, number, number, number, number];
  bins?: Array<{bin: number; count: number}>;
  range?: [number, number];
};

function isGroupDataPoint(
  dataPoint: GroupDataPoint | RunDataPoint
): dataPoint is GroupDataPoint {
  return (dataPoint as GroupDataPoint).groupKeys != null;
}

function aggregatePoints(
  points: RunDataPoint[],
  aggregateCalculations: AggregateCalculation[], // currently we do all calculations
  groupAgg: 'mean' | 'min' | 'max' | 'median' | 'samples',
  groupArea: AggregateCalculation,
  groupKeys: Run.Key[],
  numBins?: number
): GroupDataPoint | null {
  if (points.length === 0) {
    return null;
  }
  const values = points.map(p => p.value);
  const meanVal = avg(values);
  const stddevVal = stddev(values);
  const stderrVal = stderr(values);
  const quartilesVal = quartiles(values);
  const medianVal = quartilesVal[2];

  const bins = numBins != null ? bin(values, numBins) : undefined;

  let runIndex = 0;
  if (groupAgg === 'min') {
    runIndex = argMin(values);
  } else if (groupAgg === 'max') {
    runIndex = argMax(values);
  }

  const value =
    groupAgg === 'mean'
      ? meanVal
      : groupAgg === 'median'
      ? medianVal
      : groupAgg === 'max'
      ? quartilesVal[4]
      : groupAgg === 'min'
      ? quartilesVal[0]
      : 0;

  const area: [number, number] | undefined =
    groupArea === 'minmax'
      ? [quartilesVal[0], quartilesVal[4]]
      : groupArea === 'stddev'
      ? [value - stddevVal, value + stddevVal]
      : groupArea === 'stderr'
      ? [value - stderrVal, value + stderrVal]
      : undefined;

  return {
    run: points[runIndex].run, // max or min case
    metricName: points[runIndex].metricName, // max or min case
    runOrGroupUniqueId: points[runIndex].runOrGroupUniqueId,
    runOrGroupDisplayName: points[runIndex].runOrGroupDisplayName,
    value,
    mean: meanVal,
    stddev: stddevVal,
    stderr: stderrVal,
    quartiles: quartilesVal,
    range: area,
    bins,
    groupKeys,
  };
}

function pointsFromRunset(props: {
  runs: RunWithRunsetInfo[];
  metrics: Key[]; // typically one metric otherwise will aggregate across metrics
  expressions?: Expression[];
  groupKeys: Key[];
  aggregateCalculations: AggregateCalculation[];
  legendTemplate?: string;
  groupAgg?: 'mean' | 'min' | 'max' | 'median' | 'samples';
  groupArea?: AggregateCalculation;
  boxPlot?: boolean;
  violinPlot?: boolean;
  mergeRunsets?: boolean;
}) {
  const {
    runs,
    metrics,
    expressions,
    groupKeys,
    aggregateCalculations,
    groupAgg,
    groupArea,
    violinPlot,
    mergeRunsets,
  } = props;
  /*
   * Converts data in runset format to point format
   * Also does aggregation
   */
  const expressionKeys =
    expressions != null
      ? _.flatten(
          expressions.map(expr =>
            _.concat(
              summaryKeysInExpression(expr),
              configKeysInExpression(expr)
            )
          )
        )
      : [];

  let barData: RunDataPoint[] = runs
    .map(run => {
      if (expressions && expressions.length > 0) {
        const metricsToValue: {[key: string]: number} = {};
        expressionKeys.forEach(
          exprKey =>
            (metricsToValue[keyToString(exprKey)] = Run.getValueSafe(
              run,
              exprKey
            ) as number)
        );
        return expressions.map(expr => {
          const val = evaluateExpression(expr, metricsToValue);
          return {
            metricName: expressionToString(expr),
            value: val,
            run,
            runOrGroupUniqueId: Run.uniqueId(run, groupKeys || []),
            runOrGroupDisplayName:
              groupKeys.length === 0
                ? run.displayName
                : Run.groupedRunDisplayName(run, groupKeys),
          };
        });
      } else {
        return metrics.map(key => {
          const metricName = Run.keyDisplayName(key);
          return {
            run,
            value: Run.getValueSafe(run, key) as number,
            metricName,
            runOrGroupUniqueId: Run.uniqueId(
              run,
              groupKeys ?? [],
              !mergeRunsets
            ),
            runOrGroupDisplayName:
              groupKeys.length === 0
                ? run.displayName
                : Run.groupedRunDisplayName(run, groupKeys),
          };
        });
      }
    })
    .flat();

  if (groupKeys.length > 0 || metrics.length > 1) {
    const groupedBars = _.groupBy(
      barData,
      b =>
        // Can use not-null type assertion because we know we set l.run above.
        b.runOrGroupUniqueId
    );
    const bars = _.flatMap(groupedBars, barSet => {
      return aggregatePoints(
        barSet,
        aggregateCalculations,
        groupAgg ?? 'mean',
        groupArea ?? 'none',
        groupKeys,
        violinPlot ? DEFAULT_VIOLIN_PLOT_BINS : undefined
      );
    }).filter((b): b is GroupDataPoint => b != null);
    barData = bars;
  }

  return barData;
}

const pointResultsForRunset = (
  runs: RunsData['filtered'],
  props: PointsFromDataProps,
  q: RunSetInfo | undefined,
  mergeRunsets: boolean
) => {
  const {
    groupAgg,
    groupArea,
    aggregateCalculations,
    violinPlot,
    legendTemplate,
    expressions,
    metricKeys,
  } = props;

  const runSetID = q?.id;
  const runsForRunset =
    runSetID != null ? runs.filter(r => r.runsetInfo.id === runSetID) : runs;

  const groupKeys = props.panelAggregate
    ? [Run.key('config', props.groupBy || '')]
    : _.get(q, 'grouping') || [];

  const pointResults = props.aggregateMetrics
    ? pointsFromRunset({
        runs: runsForRunset,
        metrics: metricKeys,
        expressions,
        groupKeys,
        aggregateCalculations,
        groupArea,
        groupAgg,
        violinPlot,
        legendTemplate,
        mergeRunsets,
      })
    : metricKeys
        .map(y => {
          return pointsFromRunset({
            runs: runsForRunset,
            metrics: [y],
            groupKeys,
            aggregateCalculations,
            groupArea,
            groupAgg,
            violinPlot,
            legendTemplate,
            mergeRunsets,
          });
        })
        .flat();

  return pointResults;
};

const convertRunDataPointsToBars = (props: {
  dataPoints: Array<RunDataPoint | GroupDataPoint>;
  useRunName: boolean;
  useMetricName: boolean;
  legendTemplate: string;
  colorEachMetricDifferently: boolean;
  customRunColors?: RunColorConfig;
  boxPlot?: boolean;
  violinPlot?: boolean;
}): Bar[] => {
  const {dataPoints, customRunColors, violinPlot, legendTemplate} = props;
  const metricToColorIdx = new Map<string, number>();
  if (props.colorEachMetricDifferently) {
    // build a map of metrics to indexes for coloring
    let maxIdx = 0;
    dataPoints.forEach(point => {
      if (!metricToColorIdx.has(point.metricName)) {
        metricToColorIdx.set(point.metricName, maxIdx);
        maxIdx++;
      }
    });
  }

  const bars = dataPoints.map(point => {
    const titleTemplate = parseLegendTemplate(
      legendTemplate,
      true,
      point.run,
      isGroupDataPoint(point) ? point.groupKeys : [],
      prettifyMetricName(point.metricName)
    );

    const key = legendTemplateRemoveCrosshairValues(titleTemplate).trim();

    const pointColor = props.colorEachMetricDifferently
      ? ColorUtil.color(metricToColorIdx.get(point.metricName) ?? 0)
      : // normal coloring
      point.run
      ? ColorUtil.runColor(
          point.run,
          isGroupDataPoint(point) ? point.groupKeys : [],
          customRunColors
        )
      : '000000';

    const uniqueId = point.runOrGroupUniqueId;

    return {
      key,
      title: titleTemplate,
      color: pointColor,
      metricName: point.metricName,
      uniqueId,
      value: point.value,
      mean: isGroupDataPoint(point) ? point.mean : undefined,
      stddev: isGroupDataPoint(point) ? point.stddev : undefined,
      displayName: point.runOrGroupDisplayName,
      quartiles: isGroupDataPoint(point) ? point.quartiles : undefined,
      bins: violinPlot && isGroupDataPoint(point) ? point.bins : undefined,
      range: isGroupDataPoint(point) ? point.range : undefined,
    };
  });

  return bars;
};

interface PointsFromDataProps {
  metricKeys: Run.Key[];
  customRunColors?: RunColorConfig;
  groupBy?: string;
  panelAggregate?: boolean;
  groupAgg?: 'mean' | 'min' | 'max' | 'median' | 'samples';
  groupArea?: AggregateCalculation;
  runSets?: RunSetInfo[];
  aggregateCalculations: AggregateCalculation[];
  colorEachMetricDifferently: boolean;
  aggregateMetrics: boolean;
  boxPlot?: boolean;
  violinPlot?: boolean;
  legendTemplate: string;
  expressions?: Expression[];
}

export const getPointsFromData = (
  runs: RunsData['filtered'],
  props: PointsFromDataProps
) => {
  /* Convert runsdata to barchart data */

  const {
    runSets,
    metricKeys,
    customRunColors,
    boxPlot,
    violinPlot,
    legendTemplate,
    colorEachMetricDifferently,
  } = props;

  if (runs.length === 0) {
    return [];
  }

  let barResults: RunDataPoint[];
  const runSetByID: {[id: string]: RunSetInfo} = {};
  if (runSets != null) {
    // When we have a runset (everywhere but the run page), map over the runsets.
    barResults = _.flatten(
      runSets.map(runSet => pointResultsForRunset(runs, props, runSet, false))
    );
    runSets.forEach(rs => (runSetByID[rs.id] = rs));
  } else {
    // Else we're in the run page and there's no runset.
    barResults = pointResultsForRunset(runs, props, undefined, false);
  }

  const useMetricName = metricKeys.length > 1;
  const useRunName =
    !useMetricName ||
    _.uniq(barResults.map(br => br.runOrGroupDisplayName)).length > 1;

  const bars = convertRunDataPointsToBars({
    dataPoints: barResults,
    useRunName,
    useMetricName,
    legendTemplate: legendTemplate || '',
    colorEachMetricDifferently,
    customRunColors,
    boxPlot,
    violinPlot,
  });
  return bars;
};

const convertRunDataPointsToScalar = (props: {
  dataPoint: RunDataPoint | GroupDataPoint;
  useRunName: boolean;
  useMetricName: boolean;
  legendTemplate: string;
  customRunColors?: RunColorConfig;
  entityName?: string;
  projectName?: string;
}): Scalar => {
  const {dataPoint, customRunColors, legendTemplate} = props;

  const rootUrl =
    props.entityName != null && props.projectName != null
      ? `/${props.entityName}/${props.projectName}/runs`
      : null;

  const titleTemplate = legendTemplateToFancyLegendProps(
    legendTemplate,
    dataPoint.run,
    [],
    prettifyMetricName(dataPoint.metricName),
    rootUrl ?? undefined
  );

  const pointColor = dataPoint.run
    ? ColorUtil.runColor(dataPoint.run, [], customRunColors)
    : '000000';
  const uniqueId = dataPoint.runOrGroupUniqueId;

  return {
    key: titleTemplate,
    color: pointColor,
    uniqueId,
    value: dataPoint.value,
    range: isGroupDataPoint(dataPoint) ? dataPoint.range : undefined,
    stddev: isGroupDataPoint(dataPoint) ? dataPoint.stddev : undefined,
    stderr: isGroupDataPoint(dataPoint) ? dataPoint.stderr : undefined,
  };
};

interface ScalarFromDataProps {
  metricKeys: Run.Key[];
  customRunColors?: RunColorConfig;
  groupAgg?: 'mean' | 'min' | 'max' | 'median';
  groupArea?: AggregateCalculation;
  runSets?: RunSetInfo[];
  aggregateCalculations: AggregateCalculation[];
  legendTemplate: string;
  expressions?: Expression[];
  entityName?: string;
  projectName?: string;
}

export const getScalarFromData = (
  runs: RunsData['filtered'],
  props: ScalarFromDataProps
): Scalar | null => {
  /* Convert runsdata to barchart data */

  const {metricKeys, customRunColors, legendTemplate} = props;

  if (runs.length === 0) {
    return null;
  }

  let barResults: RunDataPoint[];

  barResults = pointResultsForRunset(
    runs,
    {
      ...props,
      groupBy: '',
      panelAggregate: true,
      aggregateMetrics: true,
      colorEachMetricDifferently: false,
    },
    undefined,
    true
  );

  const useMetricName = metricKeys.length > 1;
  const useRunName =
    !useMetricName ||
    _.uniq(barResults.map(br => br.runOrGroupDisplayName)).length > 1;
  if (barResults.length === 0) {
    return {value: 0} as Scalar;
  }

  const runsetForRun = props.runSets?.find(
    rs => rs.id === barResults[0].run.runsetInfo.id
  );
  const entityName = runsetForRun?.entityName ?? props.entityName;
  const projectName = runsetForRun?.projectName ?? props.projectName;

  const scalar = convertRunDataPointsToScalar({
    dataPoint: barResults[0],
    useRunName,
    useMetricName,
    legendTemplate: legendTemplate || '',
    customRunColors,
    entityName,
    projectName,
  });
  return scalar;
};

export function barOverlay(b: Bar) {
  // quartiles wont exist if bar was converted from line
  return legendTemplateInsertCrosshairValues(
    b.key,
    b.title ?? '',
    true,
    {
      x: {xAxis: b.metricName ?? '', val: b.value},
      mean: b.mean,
      min: b.quartiles != null ? b.quartiles[0] : b?.range?.[0],
      max: b.quartiles != null ? b.quartiles[4] : b?.range?.[1],
      stddev: b.stddev,
    },
    'bar'
  );
}

const prettyMetricReplacements = [
  {
    prettyName: 'CPU Utilization (%)',
    regex: /system\/cpu/,
  },
  {
    prettyName: 'TPU Utilization (%)',
    regex: /system\/tpu/,
  },
  {
    prettyName: 'System Memory Utilization (%)',
    regex: /system\/memory/,
  },
  {
    prettyName: 'Disk Utilization (%)',
    regex: /system\/disk/,
  },
  {
    prettyName: 'Network Traffic Sent (bytes)',
    regex: /system\/network\.sent/,
  },
  {
    prettyName: 'Network Traffic Received (bytes)',
    regex: /system\/network\.recv/,
  },
  {
    prettyName: 'GPU $1 Utilization (%)',
    regex: /system\/gpu\.(\d)+\.gpu/,
  },
  {
    prettyName: 'GPU $1 Temp (℃)',
    regex: /system\/gpu\.(\d)+\.temp/,
  },
  {
    prettyName: 'GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.(\d)+\.memory$/,
  },
  {
    prettyName: 'GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.(\d)+\.memory_?[aA]llocated$/,
  },
  {
    prettyName: 'GPU $1 Power Usage (%)',
    regex: /system\/gpu\.(\d)+\.powerPercent$/,
  },
  {
    prettyName: 'GPU $1 Power Usage (W)',
    regex: /system\/gpu\.(\d)+\.powerWatts$/,
  },
  {
    prettyName: 'Process GPU $1 Utilization (%)',
    regex: /system\/gpu\.process\.(\d)+\.gpu/,
  },
  {
    prettyName: 'Process GPU $1 Temp (℃)',
    regex: /system\/gpu\.process\.(\d)+\.temp/,
  },
  {
    prettyName: 'Process GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.(\d)+\.memory$/,
  },
  {
    prettyName: 'Process GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.process\.(\d)+\.memory_?[aA]llocated$/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (%)',
    regex: /system\/gpu\.process\.(\d)+\.powerPercent$/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (W)',
    regex: /system\/gpu\.process\.(\d)+\.powerWatts$/,
  },
];

export const prettifyMetricName = (originalMetricName: string) => {
  for (const {prettyName, regex} of prettyMetricReplacements) {
    const match = originalMetricName.match(regex);
    if (match) {
      if (match.length > 1) {
        return prettyName.replace('$1', match[1]);
      }

      return prettyName;
    }
  }

  return originalMetricName;
};

export function legendFromRun(run: RunWithRunsetInfo, keys: string[]) {
  const legendFields = keys.map(key => {
    const val = Run.getValueFromKeyString(run, key);
    return {
      title: Run.keyStringDisplayName(key),
      value: Run.isTimeKeyString(key)
        ? Run.formatTimestamp(val as string)
        : Run.displayValue(val),
    };
  });
  return legendFields
    .map(legendField => {
      return (
        "<div><span class='key'>" +
        legendField.title +
        "</span>: <span class='value'>" +
        legendField.value +
        '</span></div>'
      );
    })
    .join('\n');
}

export type YAxisType = 'linear' | 'log';
export type XAxisType = 'linear' | 'log' | 'time';

// Timestamps have long axis tick labels, so we need to add margin for them
type GetPlotMarginParams = {
  axisKeys?: {
    xAxis?: string;
    yAxis?: string;
    zAxis?: string;
  };
  axisDomain?: {
    yAxis?: number[];
  };
  axisType?: {
    yAxis?: YAxisType;
  };
  axisValues?: {
    yAxis?: string[];
  };
  tickTotal?: {
    yAxis?: number;
  };
  fontSize?: PlotFontSize;
};

export const getPlotMargin = ({
  axisKeys = {},
  axisDomain = {},
  axisType = {},
  axisValues = {},
  tickTotal = {},
  fontSize = 'small',
}: GetPlotMarginParams) => {
  const {xAxis, yAxis, zAxis} = axisKeys;
  const xIsTime = xAxis && Run.isTimeKeyString(xAxis);
  const yIsTime = yAxis && Run.isTimeKeyString(yAxis);
  const zIsTime = zAxis && Run.isTimeKeyString(zAxis);

  const margin = {bottom: 30, left: 10, top: 5, right: 20};

  if (axisValues?.yAxis != null) {
    const marginWidth = RunHelpers.getAxisMarginWidth(axisValues.yAxis);
    margin.left = marginWidth;
  } else if (axisDomain?.yAxis) {
    // This is the internal function used by react vis to calculate the axis values
    const scale = getScaleFnFromScaleObject({
      type: axisType.yAxis ?? 'linear',
      domain: axisDomain.yAxis,
    });

    const formatFn = yIsTime ? Run.formatTimestamp : formatYAxis;

    const marginWidth = RunHelpers.getAxisMarginWidth(
      (scale.ticks(tickTotal?.yAxis) as any[]).map(formatFn)
    );
    margin.left = Math.max(marginWidth, margin.left);
  }

  if (xIsTime) {
    margin.top = 5;
    margin.bottom = 55;
    margin.left = 55;
  }
  if (yIsTime) {
    margin.left = 100;
  }
  if (zIsTime) {
    margin.top = 20;
  }

  if (fontSize === 'medium') {
    margin.left *= 1.2;
  } else if (fontSize === 'large') {
    margin.left *= 1.5;
  }

  return margin;
};

export const axisTickRotate = -45;
export const axisTickRotatedSize = 8;
export const getAngledXAxisMarginHeight = (keys: string[]) => {
  if (keys.length === 0) {
    return 0;
  }
  const w =
    keys
      .map(k => textWidth(k, `${axisTickRotatedSize}px "Source San Pro"`))
      .reduce((a, b) => Math.max(a, b)) + 15;
  // Convert the text width to height of the angled triangle with a standard trig operation
  return w * Math.sin(Math.abs(axisTickRotate));
};

const SCALE_FUNCTIONS: {
  linear: () => d3.ScaleLinear<number, number>;
  log: () => d3.ScaleLogarithmic<number, number>;
} = {
  linear: scaleLinear,
  log: scaleLog,
};

/**
 * Copied from react-vis because it was a private function
 * React vis uses this internally to create the scale for
 * axis ticks
 *
 * Create a scale function from the scale object.
 *
 * @returns {*} Scale function.
 */
export function getScaleFnFromScaleObject(scaleObject: {
  domain: number[];
  type: XAxisType | YAxisType;
}) {
  if (!scaleObject) {
    return null;
  }
  const {type, domain} = scaleObject;
  const modDomain =
    domain[0] === domain[1]
      ? domain[0] === 0
        ? [-1, 0]
        : [-domain[0], domain[0]]
      : domain;

  const scale = (SCALE_FUNCTIONS as any)[type]().domain(modDomain);
  return scale;
}

export function formatXAxisNonTime(xType: string, xMin: number, xMax: number) {
  if (xType === 'time') {
    return undefined;
  }

  // Decimal notation with 4 significant digits
  const formatWithSI = format('.4~s');
  const formatWithoutSI = format('.4~r');

  // Avoid using milli SI units, since it's weird to see "800m" instead of "0.8"
  const delta = xMax - xMin;
  const absMin = Math.abs(xMin);
  const absMax = Math.abs(xMax);
  const minInMilliRange = absMin >= 0.001 && absMin < 1;
  const maxInMilliRange = absMax >= 0.001 && absMax < 1;
  if (minInMilliRange && maxInMilliRange) {
    return formatWithoutSI;
  }
  if ((minInMilliRange || maxInMilliRange) && delta < 1000) {
    return formatWithoutSI;
  }

  return formatWithSI;
}

// Format the y-axis for the line chart, which doesn't allow
// time on the y axis
export const formatYAxis = (tick: number): string => {
  return format('.5')(tick).length < 7
    ? format('.5')(tick)
    : format('.2~e')(tick);
};

const fontSizeToPx: {[K in PlotFontSize]: number} = {
  small: 11,
  medium: 14,
  large: 18,
};

export function getAxisStyleForFontSize(fontSize: PlotFontSize = 'small') {
  return {
    fontFamily: globals.fontName,
    fill: '#6b6b76',
    fontSize: `${fontSizeToPx[fontSize]}px`,
  };
}

export function getMetricIdentifiersFromExpressions(
  expressions?: Expression[],
  xExpression?: Expression
): {
  xExpressionMetricIdentifiers: string[];
  expressionMetricIdentifiers: string[];
} {
  const xExpressionMetricIdentifiers =
    xExpression != null ? metricsInExpression(xExpression) : [];
  const expressionMetricIdentifiers =
    expressions != null
      ? _.flatten(expressions.map(expr => metricsInExpression(expr)))
      : [];
  return {xExpressionMetricIdentifiers, expressionMetricIdentifiers};
}

export function getAllMetrics(
  metrics: string[],
  expressionMetricIdentifiers: string[],
  xExpressionMetricIdentifiers: string[]
): string[] {
  return _.uniq(
    _.concat(xExpressionMetricIdentifiers, expressionMetricIdentifiers, metrics)
  );
}
