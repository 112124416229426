import {isEqual as _isEqual} from 'lodash';
import React, {useState, useEffect, useRef} from 'react';

import {ValueOp} from '../../../util/filters';
import {getValue, Key as RunKey, Value as RunValue} from '../../../util/runs';
import {makePropsAreEqual} from '../../../util/shouldUpdate';
import {DFTableColumn, DFTableRowInterface} from './DFTable';
import {rowIsRun} from '../../../util/runhelpers';
import makeComp from '../../../util/profiler';

interface DFTableCellProps {
  className: string;
  column: DFTableColumn;
  row: DFTableRowInterface;
  loadingChildren: boolean;
  recursionDepth: number;
  cellHoverProps: {
    onMouseEnter(): void;
    onMouseLeave(): void;
  };
  hoveringRow: boolean;
  isGroup: boolean;
  isExpanded: boolean;
  expanded?: boolean;
  addFilter(key: RunKey, op: ValueOp, value: RunValue): void;
  toggleExpandedRow(rowAddress: string): void;
}

export const DFTableCell: React.FC<DFTableCellProps> = makeComp(
  ({
    className,
    column,
    row,
    loadingChildren,
    recursionDepth,
    cellHoverProps,
    hoveringRow,
    isGroup,
    isExpanded,
    addFilter,
    toggleExpandedRow,
  }) => {
    const [changeAnimating, setChangeAnimating] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (ref.current == null) {
        return;
      }
      ref.current.addEventListener('animationend', () => {
        setChangeAnimating(false);
      });
    }, []);

    return (
      <div
        ref={ref}
        className={
          className + (changeAnimating ? ' highlight-change-animation' : '')
        }
        // TODO: debounce this if necessary for performance
        onMouseEnter={cellHoverProps.onMouseEnter}
        onMouseLeave={cellHoverProps.onMouseLeave}>
        {column.renderCell
          ? column.renderCell(column, row, {
              addFilter,
              toggleExpandedRow,
              recursionDepth,
              isGroup,
              isExpanded,
              loadingChildren,
              hoveringRow,
            })
          : getValue(row as any, column.key)}
      </div>
    );
  },
  {
    id: 'DFTableCell',
    memo: (prevProps, nextProps) => {
      if (
        rowIsRun(nextProps.row) &&
        rowIsRun(prevProps.row) &&
        !_isEqual(nextProps.row.state, prevProps.row.state)
      ) {
        return false;
      }
      const valChanged = !_isEqual(
        // Completely boneheaded:
        getValue(nextProps.row as any, nextProps.column.key),
        getValue(prevProps.row as any, prevProps.column.key)
      );
      if (valChanged) {
        return false;
      }
      const propsAreEqual = makePropsAreEqual({
        name: 'DFTableCell',
        deep: ['columnKey', 'column'],
        ignore: ['row', 'cellHoverProps'],
        ignoreFunctions: true,
        debug: false,
        verbose: true,
      });
      return propsAreEqual(prevProps, nextProps);
    },
  }
);
