import styled from 'styled-components';

export const PlusButton = styled.span`
  padding: 0 6px;
  background: ${props => props.theme.clickable};
  cursor: pointer;
  user-select: none;
  /* font-size: 18px; */
  line-height: 20px;
  border-radius: 2px;
  display: inline-block;
  &:hover {
    background: ${props => props.theme.focused};
  }
`;
