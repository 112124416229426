import * as Vega3 from '../util/vega3';
import {propagateErrorsContext} from '../util/errors';
import {useApolloClient} from '../state/hooks';
import {parse} from 'graphql';
import * as String from '@wandb/cg/browser/utils/string';
import * as ServerApi from '@wandb/cg/browser/serverApi';
import * as File from '../files';

function toGraphqlArg(arg: Vega3.QueryArg) {
  return arg.name + ': ' + JSON.stringify(arg.value);
}

function toGraphqlArgs(args: Vega3.QueryArg[]) {
  if (args.length === 0) {
    return '';
  }
  let result = '(';
  result += args.map(toGraphqlArg).join(', ');
  result += ')';
  return result;
}

function toGraphqlField(field: Vega3.QueryField, indent: number) {
  let result = field.alias
    ? String.indent(`${field.alias}: ${field.name}`, indent)
    : String.indent(field.name, indent);
  if (field.args != null) {
    result += toGraphqlArgs(field.args);
  }
  if (field.fields.length === 0) {
    result += '\n';
  } else {
    result += ' {\n';
    result += toGraphqlFields(field.fields, indent + 1);
    result += String.indent('}\n', indent);
  }
  return result;
}

function toGraphqlFields(fields: Vega3.QueryField[], indent: number) {
  let result = '';
  for (const field of fields) {
    result += toGraphqlField(field, indent);
  }
  return result;
}

export function toGraphql(query: Vega3.Query): string {
  let documentS = 'query ComputeGraph {\n';
  documentS += toGraphqlFields(query.queryFields, 2);
  documentS += '}\n';
  return documentS;
}

export class Client implements ServerApi.ServerAPI {
  client: ReturnType<typeof useApolloClient>;

  constructor(client: ReturnType<typeof useApolloClient>) {
    this.client = client;
  }

  // TODO: Some semblance of error handling!
  async execute(query: Vega3.Query): Promise<any> {
    const stringQuery = toGraphql(query);
    // console.log('DOING QUERY', query, stringQuery);
    return this.client
      .query({
        query: parse(stringQuery),
        context: propagateErrorsContext(),
      })
      .then(res => {
        // console.log('GOT RESULT', query, res.data);
        return res.data;
      });
  }

  async getArtifactFileContents(artifactId: string, assetPath: string) {
    return File.cachedLoadArtifactFileContent(
      this.client,
      artifactId,
      assetPath
    );
  }

  async getArtifactFileDirectUrl(artifactId: string, assetPath: string) {
    return File.loadArtifactFileDirectUrl(this.client, artifactId, assetPath);
  }

  async getArtifactFileMetadata(artifactId: string, assetPath: string) {
    return File.loadArtifactFilePathMetadata(
      this.client,
      artifactId,
      assetPath
    );
  }

  async getRunFileContents(
    projectName: string,
    runName: string,
    fileName: string,
    entityName?: string
  ) {
    return File.cachedLoadRunFileContent(
      this.client,
      projectName,
      runName,
      fileName,
      entityName
    );
  }
}
