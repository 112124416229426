import {useViewer} from '../state/viewer/hooks';
import makeComp from '../util/profiler';
import {useEntityStorageQuery} from '../generated/graphql';
import * as urls from '../util/urls';
import React from 'react';
import {envIsLocal} from '../config';
import {freeStorage, bytesInGB} from '../util/storage';
import NudgeBar from './NudgeBar';

const StorageNudgeBar: React.FC = makeComp(
  () => {
    const viewer = useViewer();
    const hasTeam =
      (viewer?.teams.edges.filter(e => e.node.isTeam)?.length ?? 0) > 0;
    const hasOrg = (viewer?.organizations?.length ?? 0) > 0;
    const userEntity = useEntityStorageQuery({
      variables: {
        entityName: viewer?.username,
        enableReferenceTracking: false,
      },
    });

    const overLimit =
      userEntity.data?.entity?.storageBytes > freeStorage ||
      userEntity.data?.entity?.referenceBytes > freeStorage;

    const mainText = `You’re exceeding the ${
      freeStorage / bytesInGB
    } GB free plan for storage and artifacts. Upgrade to keep growing your ML projects.`;
    const linkText = 'Manage plan →';
    const link = urls.subscriptions();
    return (
      <>
        {overLimit && !envIsLocal && !hasTeam && !hasOrg && (
          <NudgeBar content={mainText} linkText={linkText} link={link} />
        )}
      </>
    );
  },
  {id: 'StorageNudgeBar', memo: true}
);

export default StorageNudgeBar;
