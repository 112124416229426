import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {WBSelect} from '@wandb/ui';

export const RowCountSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-right: 24px;
  white-space: nowrap;
`;

export const RowCountSelect = styled(WBSelect)`
  padding: 0;
  margin-right: 5px;
`;

export const PageNav = styled.div`
  .ui.buttons .button:first-child {
    border-left: none;
  }
  .wb-icon-button.only-icon {
    border: none;
    border-radius: 50%;
  }
  .ui.buttons .button:last-child,
  .ui.buttons .button:first-child {
    border-radius: 50%;
  }
  .ui.button {
    background: none;
    &:focus,
    &:hover {
      background: ${globals.gray100};
    }
  }
  .ui.button:disabled,
  .ui.buttons .disabled.button,
  .ui.disabled.active.button,
  .ui.disabled.button,
  .ui.disabled.button:hover {
    background: none;
  }
`;
