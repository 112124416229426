import * as S from './HoveringToolbar.styles';
import React from 'react';
import {useSlate, ReactEditor} from 'slate-react';
import {Range, Editor, Transforms, Node} from 'slate';
import {useTyping} from './TypingContext';
import useResizeObserver from 'use-resize-observer';
import {EditLatexWidget, isLatex} from './plugins/latex';
import {
  EditLinkWidget,
  InsertLinkWidget,
  isLink,
  EditorWithLinks,
} from './plugins/links';
import {
  isMarkdownBlock,
  EditMarkdownBlockWidget,
  markdownBlockIsConvertable,
} from './plugins/markdown-blocks';
import {EditorWithMarkShortcuts} from './plugins/mark-shortcuts';

/**
 * All hovering toolbars/editors are simply different modes of the same toolbar,
 * to reuse its positioning and animation logic, and to ensure that only one is
 * ever open at a time.
 */
export type HoveringToolbarMode = 'default' | 'link' | 'editing';

interface FormatButtonProps {
  format: string;
  icon: string;
}

const FormatButton: React.FC<FormatButtonProps> = ({format, icon}) => {
  const editor = useSlate();
  return (
    <S.FormatButton
      name={icon}
      $active={EditorWithMarkShortcuts.hasMark(editor, format)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => {
        EditorWithMarkShortcuts.toggleMark(editor, format);
      }}
    />
  );
};

export interface HoveringToolbarProps {
  className?: string;
}

const HoveringToolbar: React.FC<HoveringToolbarProps> = ({className}) => {
  const editor = useSlate();
  const {typing} = useTyping();
  const ref = React.useRef<HTMLDivElement>(null);

  /** Keep track of whether the mouse is up to avoid showing the toolbar mid-selection. */
  const [mouseIsUp, setMouseIsUp] = React.useState(true);

  const [open, setOpen] = React.useState(false);

  /** The toolbar retains its position even after closing, for a clean exit animation. */
  const [position, setPosition] = React.useState<{x: number; y: number}>({
    x: -1000,
    y: -1000,
  });

  /** For animating up/down movement */
  const [direction, setDirection] = React.useState<'up' | 'down'>('up');

  /** The currently active mode; remains even after closing, for animation purposes */
  const [mode, setMode] = React.useState<HoveringToolbarMode>('default');

  /** The last selection that needed to be saved because the browser can only focus on one input. */
  const [savedSelection, setSavedSelection] = React.useState<Range | null>(
    null
  );

  /** The last node that was hovered over for long enough to start edit */
  const [editingNode, setEditingNode] = React.useState<Node | null>(null);

  /** The currently hovered over node; only includes hoverable nodes */
  const hoveringNode = React.useRef<Node | null>(null);

  const domSelection = window.getSelection();
  const {selection} = editor;

  const {width = 1, height = 1} = useResizeObserver<HTMLDivElement>({ref});

  /**
   * Navigates the toolbar to the given rect, adapting to edges of screen
   */
  const setPositionFromRect = React.useCallback(
    (rect: DOMRect) => {
      const el = ref.current;

      if (el == null) {
        return;
      }

      const editorDOMNode = ReactEditor.toDOMNode(editor, editor);
      const editorDOMRect = editorDOMNode.getBoundingClientRect();

      let newDirection: 'up' | 'down' = 'up';
      if (rect.top < 120) {
        newDirection = 'down';
      }

      setPosition({
        x: Math.max(
          Math.min(
            rect.left - editorDOMRect.left + rect.width / 2,
            window.innerWidth - width / 2 - 8
          ),
          width / 2 + 8
        ),
        y:
          newDirection === 'up'
            ? rect.top - editorDOMRect.top - 6
            : rect.bottom - editorDOMRect.top + 6,
      });
      setDirection(newDirection);
    },
    [editor, width]
  );

  /**
   * Open editor after hovering over an editable element
   */
  React.useEffect(() => {
    const editorDOMNode = ReactEditor.toDOMNode(editor, editor);

    const onMouseEnter = (e: MouseEvent) => {
      if (open || !mouseIsUp) {
        return;
      }

      const slateNode = ReactEditor.toSlateNode(
        editor,
        e.target as HTMLElement
      );

      let matchNode: Node | null = null;

      if (isLink(slateNode) || isMarkdownBlock(slateNode)) {
        matchNode = slateNode;
      }

      if (matchNode == null) {
        const match = Editor.above(editor, {
          at: ReactEditor.findPath(editor, slateNode),
          match: n => isLink(n) || isMarkdownBlock(n),
        });

        if (match != null) {
          matchNode = match[0];
        }
      }

      if (matchNode != null && isMarkdownBlock(matchNode)) {
        if (
          (e.target as HTMLElement).closest('.inline-markdown-editor') === null
        ) {
          matchNode = null;
        }
      }

      if (matchNode == null) {
        hoveringNode.current = null;
        return;
      }

      hoveringNode.current = matchNode;

      if (
        isMarkdownBlock(matchNode) &&
        !markdownBlockIsConvertable(matchNode)
      ) {
        return;
      }

      window.setTimeout(() => {
        if (matchNode == null || hoveringNode.current !== matchNode) {
          return;
        }

        const matchDOMNode = ReactEditor.toDOMNode(editor, matchNode);
        setPositionFromRect(matchDOMNode.getBoundingClientRect());
        setOpen(true);
        setMode('editing');
        setEditingNode(matchNode);
      }, 250);
    };

    editorDOMNode.addEventListener('mouseover', onMouseEnter);

    return () => {
      editorDOMNode.removeEventListener('mouseover', onMouseEnter);
    };
  }, [open, mode, mouseIsUp, editingNode, editor, setPositionFromRect]);

  /**
   * Detect when mouse is up and down
   */
  React.useEffect(() => {
    const editorDOMNode = ReactEditor.toDOMNode(editor, editor);

    const onMouseUp = (e: MouseEvent) => {
      // Wait for selection to clear (if you clicked on it)
      window.setTimeout(() => {
        setMouseIsUp(true);
      });
    };
    const onMouseDown = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as HTMLElement)) {
        setMouseIsUp(false);
      }
    };

    editorDOMNode.addEventListener('mouseup', onMouseUp);
    editorDOMNode.addEventListener('mousedown', onMouseDown);

    return () => {
      editorDOMNode.removeEventListener('mouseup', onMouseUp);
      editorDOMNode.removeEventListener('mousedown', onMouseDown);
    };
  }, [editor]);

  /**
   * Close on mouse down or type
   */
  React.useEffect(() => {
    if (!mouseIsUp || typing) {
      setOpen(false);
      return;
    }
  }, [mouseIsUp, typing]);

  /**
   * Open default menu after selecting anything
   */
  React.useEffect(() => {
    if (
      !open &&
      mouseIsUp &&
      !typing &&
      selection != null &&
      ReactEditor.isFocused(editor) &&
      !Range.isCollapsed(selection) &&
      Editor.string(editor, selection) !== ''
    ) {
      setOpen(true);
      setMode('default');

      if (domSelection == null || domSelection.rangeCount === 0) {
        return;
      }

      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      setPositionFromRect(rect);
    }
  }, [
    editor,
    selection,
    mouseIsUp,
    typing,
    open,
    domSelection,
    setPositionFromRect,
  ]);

  React.useEffect(() => {
    if (selection != null && Range.isCollapsed(selection)) {
      const latexEntry = Editor.above(editor, {match: n => isLatex(n)});
      if (latexEntry != null) {
        setOpen(true);
        setMode('editing');
        setEditingNode(latexEntry[0]);

        const latexDOMNode = ReactEditor.toDOMNode(editor, latexEntry[0]);
        const latexDOMRect = latexDOMNode.getBoundingClientRect();
        setPositionFromRect(latexDOMRect);
      }
    }
  }, [editor, selection, setPositionFromRect, typing]);

  const left = position.x - width / 2;
  const top = position.y - (direction === 'up' ? height : 0);

  return (
    <S.Wrapper
      ref={ref}
      left={left}
      top={top}
      direction={direction}
      open={open}
      className={className}
      mode={mode}>
      {mode === 'link' && savedSelection != null ? (
        <InsertLinkWidget
          savedSelection={savedSelection}
          onCancel={() => {
            setMode('default');
            Transforms.select(editor, savedSelection);
            ReactEditor.focus(editor);
          }}
          onClose={() => {
            setOpen(false);
          }}></InsertLinkWidget>
      ) : mode === 'editing' && editingNode != null ? (
        isLink(editingNode) ? (
          <EditLinkWidget
            linkNode={editingNode}
            direction={direction}
            onClose={() => {
              setOpen(false);
            }}></EditLinkWidget>
        ) : isMarkdownBlock(editingNode) ? (
          <EditMarkdownBlockWidget
            open={open}
            direction={direction}
            node={editingNode}
            onClose={() => {
              setOpen(false);
            }}></EditMarkdownBlockWidget>
        ) : isLatex(editingNode) ? (
          <EditLatexWidget
            node={editingNode}
            open={open}
            onClose={() => {
              setOpen(false);
            }}></EditLatexWidget>
        ) : null
      ) : (
        <S.DefaultWrapper>
          <FormatButton format="strong" icon="bold" />
          <FormatButton format="emphasis" icon="italic" />
          <FormatButton format="delete" icon="strikethrough" />
          <FormatButton format="inlineCode" icon="code" />
          <S.FormatButton
            name={'link'}
            $small
            useNewIconComponent
            $active={EditorWithLinks.isLinkActive(editor)}
            onMouseDown={e => e.preventDefault()}
            onClick={() => {
              if (EditorWithLinks.isLinkActive(editor)) {
                EditorWithLinks.unwrapLink(editor);
              } else {
                setMode('link');
                setSavedSelection(editor.selection);
              }
            }}></S.FormatButton>
        </S.DefaultWrapper>
      )}
    </S.Wrapper>
  );
};

export default HoveringToolbar;
