import '../css/RunStateIndicator.less';

import * as _ from 'lodash';
import React from 'react';
import {Mutation} from 'react-apollo';
import {Button, Icon, Popup} from 'semantic-ui-react';
import {isNullOrUndefined} from 'util';

import DangerModal from '../components/DangerModal';
import {
  STOP_RUN,
  StopRunMutationData,
  StopRunMutationVariables,
} from '../graphql/runs';
import {versionGte} from '../util/version';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface RunStateIndicatorProps {
  run: {
    id: string;
    config: any;
    state: string;
    stopped: boolean;
  };
  readOnly?: boolean;
}

const RunStateIndicator: React.FC<RunStateIndicatorProps> = makeComp(
  props => {
    const [stopPressed, setStopPressed] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);

    const stopRunAvailable = () => {
      const parsedConfig = JSON.parse(props.run.config);
      const cliVersion = _.get(parsedConfig, '_wandb.value.cli_version');
      if (isNullOrUndefined(cliVersion)) {
        return false;
      }

      const isJupyterRun =
        _.get(parsedConfig, '_wandb.value.is_jupyter_run') ?? false;

      return versionGte(cliVersion, '0.8.6') && !isJupyterRun;
    };

    const canBeStopped = () => {
      return (
        !stopPressed && !props.run.stopped && props.run.state === 'running'
      );
    };

    const isStopping = () => {
      return (
        (stopPressed || props.run.stopped) && props.run.state === 'running'
      );
    };

    return (
      <div className="run-state-indicator">
        {props.run.state === 'running' && (
          // <ImageIcon name="running-animation" />
          <Icon name="spinner" color={isStopping() ? 'grey' : 'blue'} loading />
        )}
        <span>{isStopping() ? 'stopping' : props.run.state}</span>
        {stopRunAvailable() && canBeStopped() && (
          <Mutation<StopRunMutationData, StopRunMutationVariables>
            mutation={STOP_RUN}>
            {stopRun => (
              <DangerModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                trigger={
                  <Popup
                    position="right center"
                    size="mini"
                    inverted
                    trigger={
                      <Button
                        size="tiny"
                        className="stop-button"
                        onClick={() => setModalOpen(true)}>
                        <LegacyWBIcon name="stop" />
                      </Button>
                    }>
                    Stop run
                  </Popup>
                }
                message="Are you sure you want to STOP this run? This action cannot be undone!"
                yesText="Stop this run"
                yes={() => {
                  stopRun({variables: {id: props.run.id}});
                  setStopPressed(true);
                }}
              />
            )}
          </Mutation>
        )}
      </div>
    );
  },
  {id: 'RunStateIndicator'}
);

export default RunStateIndicator;
