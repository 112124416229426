// Based on https://raw.githubusercontent.com/uber/react-vis/master/showcase/examples/zoomable-chart/highlight.js

import React from 'react';
import {ScaleUtils, AbstractSeries} from 'react-vis';
import {
  registerMouseUpListener,
  unregisterMouseUpListener,
} from '../../util/mouse';

export interface BoxSelectionProps {
  allow: string;
  color: string;
  opacity: number;
  innerWidth?: number;
  innerHeight?: number;
  marginLeft?: number;
  marginTop?: number;
  onSelectChange: (
    first: {low?: number; high?: number},
    second: {low?: number; high?: number},
    drawArea: BoxSelectionDrawArea
  ) => void;
  xSelect?: {low?: number; high?: number};
  ySelect?: {low?: number; high?: number};
  zSelect?: {low?: number; high?: number};
}

export interface BoxSelectionDrawArea {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export default class BoxSelection extends AbstractSeries<BoxSelectionProps> {
  static displayName = 'BoxSelection';
  static defaultProps = {
    allow: 'x',
    color: 'rgb(180,180,180)',
    opacity: 0.3,
  };
  state = {
    drawing: false,
    drawArea: {top: 0, right: 0, bottom: 0, left: 0},
    startLoc: {x: 0, y: 0},
  };
  mouseUpListener?: number;

  _getDrawArea(loc: {x: number; y: number}) {
    const {innerWidth, innerHeight} = this.props;
    const {startLoc} = this.state;

    const area = {
      top: Math.max(0, Math.min(startLoc.y, loc.y)),
      bottom: Math.min(innerHeight || 100000, Math.max(startLoc.y, loc.y)),
      left: Math.max(0, Math.min(startLoc.x, loc.x)),
      right: Math.min(innerWidth || 100000, Math.max(startLoc.x, loc.x)),
    };
    return area;
  }

  componentDidMount() {
    this.mouseUpListener = registerMouseUpListener(() => this.stopDrawing());
    this.setDrawArea();
  }

  componentWillUnmount() {
    if (this.mouseUpListener != null) {
      unregisterMouseUpListener(this.mouseUpListener);
    }
  }

  componentDidUpdate(prevProps: BoxSelectionProps) {
    if (
      prevProps.xSelect !== this.props.xSelect ||
      prevProps.ySelect !== this.props.ySelect ||
      prevProps.innerWidth !== this.props.innerWidth ||
      prevProps.innerHeight !== this.props.innerHeight
    ) {
      this.setDrawArea();
    }
  }

  onParentMouseDown(e: any) {
    const {marginLeft, marginTop} = this.props;
    const locX = e.nativeEvent.offsetX - (marginLeft || 0);
    const locY = e.nativeEvent.offsetY - (marginTop || 0);

    this.setState({
      drawing: true,
      drawArea: {
        top: locY,
        right: locX,
        bottom: locY,
        left: locX,
      },
      startLoc: {x: locX, y: locY},
    });
  }

  stopDrawing() {
    // Quickly short-circuit if the user isn't drawing in our component
    if (!this.state.drawing) {
      return;
    }

    const {onSelectChange} = this.props;
    const {drawArea} = this.state;
    const xScale = ScaleUtils.getAttributeScale(this.props, 'x');
    const yScale = ScaleUtils.getAttributeScale(this.props, 'y');

    this.setState({
      drawing: false,
    });

    if (drawArea.right - drawArea.left + drawArea.bottom - drawArea.top < 5) {
      // Clear selection
      onSelectChange(
        {low: undefined, high: undefined},
        {low: undefined, high: undefined},
        drawArea
      );
      return;
    }

    // Compute the corresponding domain drawn
    const domainArea = {
      top: (yScale as any).invert(drawArea.top),
      right: (xScale as any).invert(drawArea.right),
      bottom: (yScale as any).invert(drawArea.bottom),
      left: (xScale as any).invert(drawArea.left),
    };

    if (onSelectChange) {
      const xSelect = {
        low: domainArea.left,
        high: domainArea.right,
      };
      const ySelect = {
        high: domainArea.top,
        low: domainArea.bottom,
      };
      onSelectChange(xSelect, ySelect, drawArea);
    }
  }

  onParentMouseMove(e: any) {
    const {marginLeft, marginTop} = this.props;
    const {drawing} = this.state;
    const locX = e.nativeEvent.offsetX - (marginLeft || 0);
    const locY = e.nativeEvent.offsetY - (marginTop || 0);

    if (drawing) {
      const newDrawArea = this._getDrawArea({x: locX, y: locY});
      this.setState({drawArea: newDrawArea});
    }
  }

  setDrawArea() {
    const {xSelect, ySelect, innerWidth, innerHeight} = this.props;
    if (!xSelect?.low && !xSelect?.high && !ySelect?.low && !ySelect?.high) {
      // If none of our axes have selections, don't highlight.
      this.setState({
        drawArea: {left: 0, right: 0, top: 0, bottom: 0},
      });
      return;
    }
    const xScale = ScaleUtils.getAttributeScale(this.props, 'x');
    const yScale = ScaleUtils.getAttributeScale(this.props, 'y');

    const left = xSelect?.low ? xScale(xSelect.low) : 0;
    const right = xSelect?.high ? xScale(xSelect.high) : innerWidth;
    const top = ySelect?.high ? yScale(ySelect.high) : 0;
    const bottom = ySelect?.low ? yScale(ySelect.low) : innerHeight;
    this.setState({
      drawArea: {left, right, top, bottom},
    });
  }

  render() {
    const {marginLeft, marginTop, innerWidth, innerHeight, color, opacity} =
      this.props;
    if (!innerWidth || !innerHeight || innerWidth < 0 || innerHeight < 0) {
      return null;
    }
    const {left, right, top, bottom} = this.state.drawArea;

    return (
      <g
        transform={`translate(${marginLeft}, ${marginTop})`}
        className="highlight-container"
        onMouseUp={e => this.stopDrawing()}>
        <rect
          className="mouse-target"
          fill="black"
          opacity="0"
          x={0}
          y={0}
          width={innerWidth}
          height={innerHeight}
        />
        <rect
          className="highlight"
          pointerEvents="none"
          opacity={opacity}
          fill={color}
          x={left}
          y={top}
          width={right - left}
          height={bottom - top}
        />
      </g>
    );
  }
}
