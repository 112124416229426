import '../css/PanelCodeComparer.less';

import React, {useState, useEffect, useRef, ReactChild, useMemo} from 'react';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import {Key} from '../util/runs';

import {toRunsDataQuery, RunWithRunsetInfo} from '../containers/RunsDataLoader';
import {runColor} from '../util/colors';
import CodeDiff from './CodeDiff';
import WandbLoader from './WandbLoader';
import ModifiedDropdown from './elements/ModifiedDropdown';
import PanelError from './elements/PanelError';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {Link} from 'react-router-dom';
import makeComp from '../util/profiler';
import docUrl from '../util/doc_urls';
import {TargetBlank} from '../util/links';

const PANEL_TYPE = 'Code Comparer';

interface CodeComparerConfig {
  diff: 'split' | 'unified';
}

type PanelCodeComparerProps = Panels.PanelProps<CodeComparerConfig>;

const PanelCodeComparer: React.FC<PanelCodeComparerProps> = makeComp(
  props => {
    const entityName =
      props.query?.queries?.[0]?.entityName ?? props.data.entityName;
    const projectName =
      props.query?.queries?.[0]?.projectName ?? props.data.projectName;
    const [error, setError] = useState<ReactChild | undefined>(undefined);
    const [runA, setRunA] = useState<RunWithRunsetInfo | undefined>(undefined);
    const [runB, setRunB] = useState<RunWithRunsetInfo | undefined>(undefined);
    const lastRuns = useRef(new Set<string>());

    const runsWithCode = useMemo(() => {
      return props.data.filtered.filter(
        r => r._wandb && (r._wandb.code_path || r._wandb.session_history)
      );
    }, [props.data.filtered]);

    const isGrouped =
      props.pageQuery.runSets != null &&
      props.pageQuery.runSets.some(
        runSet => runSet.grouping != null && runSet.grouping?.length > 0
      );

    useEffect(() => {
      if (!props.loading) {
        if (props.data.filtered.length === 0) {
          setError(
            'Select at least one run to load data into this run comparer.'
          );
        }
        const newRun = runsWithCode.find(r => !lastRuns.current.has(r.name));
        if (runA === undefined || runB === undefined) {
          if (runsWithCode.length === 1) {
            setError(undefined);
            setRunA(runsWithCode[0]);
            setRunB(runsWithCode[0]);
          } else if (runsWithCode.length === 0) {
            const errMsg = (
              <div style={{maxWidth: 300}}>
                {isGrouped ? (
                  <>
                    To enable the Code Comparer panel,
                    <br />
                    turn off grouping.
                  </>
                ) : (
                  <>
                    No selected runs have saved code.
                    <br />
                    Enable code saving on your{' '}
                    <Link to="/settings">settings page</Link>, and learn more in{' '}
                    <TargetBlank href={docUrl.codeComparer}>
                      the docs
                    </TargetBlank>
                    .
                  </>
                )}
              </div>
            );
            setError(errMsg);
          } else if (runsWithCode.length > 1) {
            setError(undefined);
            setRunA(runsWithCode[0]);
            setRunB(runsWithCode[1]);
          }
        } else {
          if (newRun) {
            setError(undefined);
            setRunB(newRun);
          } else if (
            props.data.filtered.find(r => r.name === runB.name) == null
          ) {
            setError(undefined);
            setRunB(runsWithCode[runsWithCode.length > 1 ? 1 : 0]);
          }
          if (props.data.filtered.find(r => r.name === runA.name) == null) {
            setRunA(runsWithCode[runsWithCode.length > 1 ? 1 : 0]);
          }
          if (newRun && runA.name === runB.name && runsWithCode.length > 1) {
            setRunA(runsWithCode[0]);
            setRunB(runsWithCode[1]);
          }
        }
      }
      lastRuns.current = new Set(runsWithCode.map(r => r.name));
    }, [runsWithCode, props, runA, runB, isGrouped]);

    if (props.loading) {
      return <WandbLoader />;
    }

    const idToGroupingMap: {[key: string]: Key[] | undefined} = {};
    if (props.pageQuery.runSets) {
      props.pageQuery.runSets.forEach(runSet => {
        idToGroupingMap[runSet.id] = runSet.grouping;
      });
    }

    return (
      <div className="code-comparer-wrapper">
        <div className="code-comparer">
          <div className="header-slave">
            <div className="filler-cell" />
            {[runA, runB].map((run, i) => {
              if (run) {
                const groupKeys = idToGroupingMap[run.runsetInfo.id];
                const color = groupKeys
                  ? runColor(run, groupKeys, props.customRunColors)
                  : undefined;
                return (
                  <div
                    className="cell name-cell"
                    key={'code-dropdown-' + run.name + `-${i}`}
                    title={run.displayName}
                    style={{color, position: 'relative'}}>
                    <ModifiedDropdown
                      style={{color}}
                      data-test="code-key-selector"
                      placeholder="Select Run"
                      options={runsWithCode
                        .filter(r => r.name !== run.name)
                        .map(r => ({
                          key: r.id,
                          value: r.name,
                          text: r.displayName,
                        }))}
                      value={i === 0 ? runA?.displayName : runB?.displayName}
                      search={true}
                      onChange={(e, {value}) => {
                        const selectedRun = runsWithCode.find(
                          r => r.name === (value as string)
                        );
                        setError(undefined);
                        if (i === 0) {
                          setRunA(selectedRun);
                        } else {
                          setRunB(selectedRun);
                        }
                      }}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {runA != null && (
            <div className="diff-toggle-wrapper">
              <LegacyWBIcon
                name={
                  props.config.diff === 'split' ? 'unified-diff' : 'split-diff'
                }
                onClick={() =>
                  props.updateConfig({
                    diff: props.config.diff === 'split' ? 'unified' : 'split',
                  })
                }
              />
            </div>
          )}
          {error ? (
            <PanelError message={error} />
          ) : (
            <div className="master-wrapper">
              {runA && runB && (
                <CodeDiff
                  entityName={entityName}
                  projectName={projectName}
                  runA={runA}
                  runB={runB}
                  setError={setError}
                  split={props.config.diff === 'split'}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
  {id: 'PanelCodeComparer'}
);

export default PanelCodeComparer;

const codeComparerTransformQuery = (query: Query.Query) => {
  const transformed = toRunsDataQuery(
    query,
    {selectionsAsFilters: true},
    {wandbKeys: ['code_path', 'session_history']}
  );
  return transformed;
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, CodeComparerConfig> = {
  type: PANEL_TYPE,
  Component: PanelCodeComparer,
  transformQuery: codeComparerTransformQuery,
  noEditMode: true,
  exportable: {
    api: true,
  },
  icon: 'panel-html',
};
