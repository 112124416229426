import '../css/PanelBank.less';

import React, {useEffect, useMemo, useState} from 'react';
import {Loader} from 'semantic-ui-react';
import * as _ from 'lodash';

import * as InteractStateActions from '../state/views/interactState/actions';
import * as InteractStateContext from '../state/views/interactState/context';
import Measure from 'react-measure';
import WarningMessage from './elements/WarningMessage';
import PanelBankSections from './PanelBankSections';
import PanelBankControls from './PanelBankControls';
import * as PanelSettingsTypes from '../state/views/panelSettings/types';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {useKeyInfoQuery} from '../state/runs/hooks';
import {
  PANEL_BANK_PADDING,
  PanelBankConfig,
  PanelBankSectionConfig,
} from '../util/panelbank';
import {useDebounceState} from '../util/hooks';
import {RunHistoryKeyInfo} from '../types/run';
import {shallowEqual} from 'react-redux';
import {PanelBankContextProvider} from '../state/panelbank/context';
import makeComp from '../util/profiler';
import docUrl from '../util/doc_urls';
import {TargetBlank} from '../util/links';
import {isReservedKey} from '../util/runs';
import {LayedOutPanelWithRef} from '../util/panels';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as ViewHooks from '../state/views/hooks';
import * as PanelTypes from '../state/views/panel/types';
import * as Normalize from '../state/views/normalize';
import {useMemoizedSelector} from '../state/hooks';
import {useAllPanelSettingsAction} from '../state/views/panelSettings/hooks';
import {updateAllLocalAndWorkspacePanelSettings} from '../state/views/panelSettings/actions';

export interface PanelBankProps {
  entityName: string;
  projectName: string;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  singleRun?: boolean; // set to true in RunWorkspace, false otherwise (determines if we use LinePlot or RunsLinePlot)
  panelSettingsRef: PanelSettingsTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  showSlowWarning?: boolean;
  showCliVersionWarning?: boolean;
  workspaceID?: string;
  hideSlowWarning?(): void;
  hideCliVersionWarning?(): void;
}

function historyWeight(historyKeyInfo: RunHistoryKeyInfo): number {
  let totalSteps = 0;
  for (const set of historyKeyInfo.sets) {
    let hasSystem = false;
    for (const key of set.keys) {
      if (key.startsWith('system/')) {
        hasSystem = true;
        break;
      } else if (!isReservedKey(key)) {
        break;
      }
    }
    if (!hasSystem) {
      totalSteps += set.count;
    }
  }
  let estimatedSize = 0;
  for (const key of Object.keys(historyKeyInfo.keys)) {
    if (key.startsWith('system/')) {
      continue;
    }
    const info = historyKeyInfo.keys[key];
    for (const tc of info.typeCounts) {
      let valSize = key.length;
      switch (tc.type) {
        case 'number':
          valSize += 8;
          break;
        case 'string':
          valSize += 16;
          break;
        case 'histogram':
          valSize += 1024;
          break;
        default:
          // this will include things like images which have some metadata about them
          valSize += 256;
          break;
      }
      estimatedSize += valSize * tc.count;
    }
  }

  let estimatedRuns = Math.round(
    totalSteps / Math.max(1, historyKeyInfo.lastStep || 0)
  );
  estimatedRuns = Math.min(100, Math.max(1, estimatedRuns));

  return estimatedSize / estimatedRuns;
}

export type SectionWithRef = Omit<PanelBankSectionConfig, 'panels'> & {
  ref: PanelBankSectionConfigTypes.Ref;
  panels: LayedOutPanelWithRef[];
  localPanelSettingsRef: PanelSettingsTypes.Ref;
};

export type PanelBankConfigWithRefs = Omit<PanelBankConfig, 'sections'> & {
  sections: SectionWithRef[];
};

export function usePanelBankConfigWithRefs(
  panelBankConfigRef: PanelBankConfigTypes.Ref
) {
  const panelBankConfig = ViewHooks.useWhole(panelBankConfigRef);
  const panelBankConfigPart = ViewHooks.usePart(panelBankConfigRef);
  const panelBankSectionConfigRefs = panelBankConfigPart.sectionRefs;
  const {panelRefsBySectionRefId, panelSettingsRefBySectionRefId} =
    useMemoizedSelector(state => {
      const refMap: {[keys: string]: PanelTypes.Ref[]} = {};
      const settingsRefMap: {[keys: string]: PanelSettingsTypes.Ref} = {};
      const parts = state.views.parts;
      panelBankSectionConfigRefs.forEach(sectionRef => {
        const panelBankSectionConfigPart = Normalize.lookupPart(
          parts,
          sectionRef
        );
        refMap[sectionRef.id] = panelBankSectionConfigPart.panelRefs;
        settingsRefMap[sectionRef.id] =
          panelBankSectionConfigPart.localPanelSettingsRef;
      });

      return {
        panelRefsBySectionRefId: refMap,
        panelSettingsRefBySectionRefId: settingsRefMap,
      };
    }, _.isEqual);
  const panelBankConfigWithRefs: PanelBankConfigWithRefs = useMemo(() => {
    // whole panelBankConfig, with section and panel refs
    return {
      ...panelBankConfig,
      sections: panelBankConfig.sections.map((s, i) => {
        const sectionRef = panelBankSectionConfigRefs[i];
        return {
          ...s,
          localPanelSettingsRef: panelSettingsRefBySectionRefId[sectionRef.id],
          ref: sectionRef,
          panels: s.panels.map((p, j) => {
            return {
              ...p,
              ref: panelRefsBySectionRefId[sectionRef.id][j],
            };
          }),
        };
      }),
    };
  }, [
    panelRefsBySectionRefId,
    panelBankConfig,
    panelBankSectionConfigRefs,
    panelSettingsRefBySectionRefId,
  ]);

  return panelBankConfigWithRefs;
}

const PanelBank: React.FC<PanelBankProps> = makeComp(
  props => {
    const {
      entityName,
      projectName,
      readOnly,
      panelBankConfigRef,
      panelSettingsRef,
      runSetRefs,
      customRunColorsRef,
      hideSlowWarning,
      hideCliVersionWarning,
      singleRun,
    } = props;
    const [searchQuery, debouncedSearchQuery, setSearchQuery] =
      useDebounceState('', 750);
    const [panelBankWidth, setPanelBankWidth] = useState(0);
    const keyInfoQuery = useKeyInfoQuery(props.runSetRefs);
    const setPanelSelection = InteractStateContext.useInteractStateAction(
      InteractStateActions.setPanelSelection
    );
    const panelBankConfigWithRefs = usePanelBankConfigWithRefs(
      props.panelBankConfigRef
    );
    const localPanelSettingsRefs = panelBankConfigWithRefs.sections.map(
      section => section.localPanelSettingsRef
    );
    const resetAllLocals = useAllPanelSettingsAction(
      localPanelSettingsRefs,
      panelSettingsRef,
      updateAllLocalAndWorkspacePanelSettings
    );
    const slowHistory = useMemo(() => {
      if (keyInfoQuery.loading || keyInfoQuery.error != null) {
        return false;
      }
      /* tslint:disable:no-bitwise */
      return historyWeight(keyInfoQuery.historyKeyInfo) > 500 << 20; // 500 MB
      /* tslint:enable:no-bitwise */
    }, [keyInfoQuery]);

    useEffect(() => {
      if (slowHistory && props.showSlowWarning) {
        analytics.track('Workspace Slow Warning');
      }
    }, [slowHistory, props.showSlowWarning]);

    if (keyInfoQuery.loading || keyInfoQuery.error != null) {
      return <Loader active size="huge" />;
    }
    const {historyKeyInfo, viz: historyKeyViz} = keyInfoQuery;
    return (
      <>
        <div className="panel-bank">
          {slowHistory && props.showSlowWarning && (
            <WarningMessage
              content={
                <p>
                  You're logging a lot of data – this page may load slowly.{' '}
                  <TargetBlank href={docUrl.limits}>Learn more →</TargetBlank>
                </p>
              }
              className="slow-warning"
              onDismiss={hideSlowWarning}
            />
          )}
          {props.showCliVersionWarning && (
            <WarningMessage
              content={
                <p>
                  Your version of wandb is outdated. Please upgrade with: pip
                  install wandb --upgrade
                </p>
              }
              className="slow-warning"
              onDismiss={hideCliVersionWarning}
            />
          )}
          <PanelBankControls
            entityName={entityName}
            projectName={projectName}
            readOnly={readOnly}
            panelBankConfigRef={panelBankConfigRef}
            panelBankConfigWithRefs={panelBankConfigWithRefs}
            panelSettingsRef={panelSettingsRef}
            runSetRefs={runSetRefs}
            customRunColorsRef={customRunColorsRef}
            searchQuery={searchQuery}
            debouncedSearchQuery={debouncedSearchQuery}
            setSearchQuery={setSearchQuery}
            singleRun={singleRun}
            historyKeyInfo={historyKeyInfo}
            panelBankWidth={panelBankWidth}
            workspaceID={props.workspaceID}
            resetAllLocals={resetAllLocals}
          />
          <PanelBankInner
            panelBankProps={props}
            warningVisible={slowHistory && props.showSlowWarning}
            debouncedSearchQuery={debouncedSearchQuery}
            historyKeyInfo={historyKeyInfo}
            historyKeyViz={historyKeyViz}
            panelBankWidth={panelBankWidth}
            setPanelBankWidth={setPanelBankWidth}
            setPanelSelection={setPanelSelection}
            panelBankConfigWithRefs={panelBankConfigWithRefs}
          />
        </div>
      </>
    );
  },
  {id: 'PanelBank', memo: true}
);

interface PanelBankInnerProps {
  panelBankProps: PanelBankProps;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  warningVisible?: boolean;
  debouncedSearchQuery: string;
  historyKeyInfo: RunHistoryKeyInfo;
  historyKeyViz: {
    [key: string]: any;
  };
  panelBankWidth: number;
  setPanelBankWidth(width: number): void;
  setPanelSelection(args: any): any;
}

const PanelBankInner = makeComp(
  (props: PanelBankInnerProps) => {
    const {
      panelBankProps,
      warningVisible,
      debouncedSearchQuery,
      historyKeyInfo,
      historyKeyViz,
      panelBankWidth,
      panelBankConfigWithRefs,
      setPanelBankWidth,
      setPanelSelection,
    } = props;

    return (
      <Measure
        bounds
        onResize={contentRect => {
          setPanelBankWidth(
            contentRect.bounds
              ? contentRect.bounds.width - PANEL_BANK_PADDING * 2
              : 0
          );
        }}>
        {({measureRef}) => {
          return (
            <div
              ref={measureRef}
              onMouseDown={e => {
                if (
                  (e.target as Element).closest('.panel-bank__panel') == null
                ) {
                  setPanelSelection([]);
                }
              }}>
              <div className="panel-bank__sections">
                {panelBankWidth > 0 && (
                  <PanelBankSections
                    key={panelBankProps.panelBankConfigRef.id}
                    {...panelBankProps}
                    warningVisible={warningVisible}
                    historyKeyInfo={historyKeyInfo}
                    historyKeyViz={historyKeyViz}
                    searchQuery={debouncedSearchQuery}
                    panelBankWidth={panelBankWidth}
                    panelBankConfigWithRefs={panelBankConfigWithRefs}
                  />
                )}
              </div>
            </div>
          );
        }}
      </Measure>
    );
  },
  {
    id: 'PanelBankInner',
    memo: (prevProps, nextProps) => {
      return (
        prevProps.debouncedSearchQuery === nextProps.debouncedSearchQuery &&
        prevProps.historyKeyInfo === nextProps.historyKeyInfo &&
        prevProps.panelBankWidth === nextProps.panelBankWidth &&
        shallowEqual(
          prevProps.panelBankConfigWithRefs,
          nextProps.panelBankConfigWithRefs
        ) &&
        prevProps.setPanelBankWidth === nextProps.setPanelBankWidth &&
        prevProps.setPanelSelection === nextProps.setPanelSelection &&
        shallowEqual(prevProps.panelBankProps, nextProps.panelBankProps)
      );
    },
  }
);

const PanelBankWithContext: React.FC<PanelBankProps> = makeComp(
  props => (
    <PanelBankContextProvider>
      <PanelBank {...props} />
    </PanelBankContextProvider>
  ),
  {id: 'PanelBankWithContext', memo: true}
);

export default PanelBankWithContext;
