import React from 'react';
import WBModal from './WBModal';
import {useMessageOfTheDayQuery} from '../generated/graphql';

const MessageOfTheDay: React.FC = () => {
  const {data} = useMessageOfTheDayQuery();
  const message = data?.serverInfo?.messageOfTheDay;
  if (!message) {
    return null;
  }
  return <MessageOfTheDayLoaded message={message} />;
};

export default MessageOfTheDay;

type MessageOfTheDayLoadedProps = {
  message: string;
};

const MessageOfTheDayLoaded: React.FC<MessageOfTheDayLoadedProps> = ({
  message,
}) => {
  const [open, setOpen] = React.useState(true);
  return (
    <WBModal
      open={open}
      onClose={() => setOpen(false)}
      primaryAction={{content: 'Close', onClick: () => setOpen(false)}}>
      <div dangerouslySetInnerHTML={{__html: message}} />
    </WBModal>
  );
};
