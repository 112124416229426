import React from 'react';

import {RunPagePanels} from './RunPagePanels';

import ViewBar from '../../components/Views/ViewBar';
import WandbLoader from '../../components/WandbLoader';
import WorkspaceErrorHandler from '../../components/WorkspaceErrorHandler';
import {usePropsSelector} from '../../state/hooks';
import {useWorkspaceInit, useViewUrlEffect} from '../../state/workspaces/hooks';
import * as WorkspaceSelectors from '../../state/workspaces/selectors';

import {EMPTY_PANEL_BANK_CONFIG, PanelBankConfig} from '../../util/panelbank';
import * as Panels from '../../util/panels';
import * as PanelSettings from '../../util/panelsettings';
import * as Run from '../../util/runs';
import makeComp from '../../util/profiler';

export interface RunPagePersistentState {
  settings: PanelSettings.Settings;
  panels: Panels.Config;
  panelBankConfig: PanelBankConfig;
  customExpanded: boolean;
  libraryExpanded: boolean;
  slowWarningHiddenAt?: string;
  cliVersionWarningHiddenAt?: string;
}

export const EMPTY_VIEW_STATE: RunPagePersistentState = {
  panels: Panels.EMPTY_SINGLE_TAB,
  panelBankConfig: EMPTY_PANEL_BANK_CONFIG, // spec for PanelBank
  settings: PanelSettings.EMPTY_SETTINGS,
  customExpanded: false,
  libraryExpanded: true,
};

interface RunWorkspaceProps {
  entityName: string;
  projectName: string;
  run: Run.Run;
  readOnly: boolean;
}

const RunWorkspace = makeComp(
  (props: RunWorkspaceProps) => {
    const {entityName, projectName, run, readOnly} = props;

    const workspaceID = useWorkspaceInit(
      entityName,
      projectName,
      'run-view',
      EMPTY_VIEW_STATE
    );
    useViewUrlEffect(workspaceID);

    const workspace = usePropsSelector(
      WorkspaceSelectors.makeRunWorkspaceSelector,
      workspaceID
    );

    if (workspace.loading) {
      return <WandbLoader />;
    }

    return (
      <WorkspaceErrorHandler workspaceID={workspaceID} viewType={'run-view'}>
        <>
          <RunPagePanels
            entityName={entityName}
            projectName={projectName}
            runPageRef={workspace.partRef}
            run={run}
            readOnly={readOnly}
            workspaceID={workspaceID}
          />
          <ViewBar
            workspaceID={workspaceID}
            readOnly={readOnly}
            entityName={entityName}
            projectName={projectName}
            isRun={true}
          />
        </>
      </WorkspaceErrorHandler>
    );
  },
  {id: 'RunWorkspace'}
);

export default RunWorkspace;
