import styled from 'styled-components';
import {Checkbox} from 'semantic-ui-react';

export const Flags = styled.div`
  margin-bottom: 32px;
`;

export const FlagText = styled.div`
  margin-bottom: 12px;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
`;

export const FlagCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;
