import '../../css/EditableLabel.less';

import _ from 'lodash';
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {Link} from 'react-router-dom';

import {Popup} from 'semantic-ui-react';
import {NameProps, pickNameProps} from '../../util/reactUtils';
import {textWidth} from '../../util/text';
import classNames from 'classnames';
import {makePropsAreEqual} from '../../util/shouldUpdate';
import makeComp from '../../util/profiler';

interface EditableLabelProps {
  onSave?: (text: string) => void;
  readOnly?: boolean;
  serverText: string | undefined;
  throttleSpeed?: number;
  title?: string;
  linkTo?: string; // url to link displayText to
  placeholder?: string;
  onChange?: (text: string) => void;
  onClick?: () => void;
  onBlur?: (text: string) => void;
  editing?: boolean;
  showPopup?: boolean; // if true, this will show the text in a popup on hover
}

// Create a label that is editable on click and synced with the server
// The parent can also control the edit state by passing the editing property.
const EditableLabel: FC<EditableLabelProps & NameProps> = makeComp(
  (props: EditableLabelProps & NameProps) => {
    const {
      onSave,
      readOnly,
      serverText,
      throttleSpeed,
      title,
      linkTo,
      placeholder,
      onClick,
      onBlur,
      editing: propsEditing,
      showPopup,
      className,
    } = props;
    const [editing, setEditing] = useState(propsEditing ?? false);
    const [text, setText] = useState<string | undefined>(serverText);

    const textInput = useRef<HTMLInputElement | null>(null);

    const throttledSave = useMemo(
      () =>
        !readOnly && onSave != null
          ? _.throttle((notes: string) => {
              if (onSave != null) {
                onSave(notes);
              }
            }, throttleSpeed || 500)
          : null,
      [readOnly, onSave, throttleSpeed]
    );

    const handleFocus = useCallback(() => {
      setEditing(propsEditing ?? true);
    }, [propsEditing]);

    const handleBlur = useCallback(() => {
      if (onBlur) {
        onBlur(text || '');
      }
      setEditing(false);
    }, [onBlur, text]);

    const handleKeyPress = useCallback(
      (e: any) => {
        if (e.key === 'Enter') {
          handleBlur();
        }
      },
      [handleBlur]
    );

    const handleChange = useCallback(() => {
      if (textInput.current != null) {
        const val = textInput.current.value;
        setText(val);
        if (val !== serverText && throttledSave != null) {
          throttledSave(val);
        }
      }
    }, [serverText, throttledSave]);

    useEffect(() => {
      if (serverText != null && text == null && text !== serverText) {
        setText(serverText);
      }
    }, [serverText, text]);

    const editingLabel = readOnly ? false : propsEditing || editing;

    const fullClassName = classNames('editable-label', className, {
      placeholder: !text || text === '',
      disabled: readOnly,
    });

    const displayText = !text || text === '' ? placeholder : text;
    const inputWidth = 20 + textWidth(displayText || '');

    const displaySpan = (
      <span className={fullClassName} onClick={onClick || handleFocus}>
        {displayText}
      </span>
    );
    return (
      <span
        {...Object.assign(pickNameProps(props), {className: fullClassName})}
        title={title}>
        {editingLabel ? (
          <input
            style={{width: inputWidth}}
            type="text"
            ref={textInput}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            onClick={e => e.stopPropagation()}
            autoFocus
          />
        ) : linkTo ? (
          <Link to={linkTo}>{displayText}</Link>
        ) : showPopup && displayText !== placeholder ? (
          <Popup content={displayText} trigger={displaySpan} />
        ) : (
          displaySpan
        )}
      </span>
    );
  },
  {
    id: 'EditableLabel',
    memo: makePropsAreEqual({
      name: 'EditableLabel',
      deep: [],
      ignore: [],
      ignoreFunctions: true,
      debug: false,
      verbose: true,
    }),
  }
);

export default EditableLabel;
