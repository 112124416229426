import * as S from './QueryInputValueEditor.styles';

import React, {useContext} from 'react';
import InlineListEditor from './InlineListEditor';
import InlineStringEditor from './InlineStringEditor';
import InlineNumberEditor from './InlineNumberEditor';
import {RunFilters} from '../RunSelector/RunsFiltersNoRef';
import * as Filter from '../../util/filters';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import * as ApiSchemaTypes from '../../generated/apiSchema';
import {WBMenuOption} from '@wandb/ui';
import {RunQueryContext} from '../../state/runs/context';
import makeComp from '../../util/profiler';
import {WBSuggesterOptionFetcher} from '../elements/WBSuggester';

interface QueryInputValueEditorProps {
  type: ApiSchemaTypes.__Type;
  val: any;
  disabled?: boolean;
  isTemplate?: boolean;
  autocompleteOptions?: WBMenuOption[] | WBSuggesterOptionFetcher;
  indentLevel: number;
  charsBefore: number;
  setVal(newVal: any): void;
}

const QueryInputValueEditor: React.FC<QueryInputValueEditorProps> = makeComp(
  ({
    val,
    disabled,
    isTemplate,
    type: propsType,
    autocompleteOptions,
    indentLevel,
    charsBefore,
    setVal,
  }) => {
    let type = propsType;
    if (type.kind === 'NON_NULL') {
      type = type.ofType!;
    }

    const {entityName, projectName} = useContext(RunQueryContext);
    return (
      <span>
        {isTemplate ? (
          <InlineStringEditor
            value={val || ''}
            disabled={true}
            setValue={v => setVal(v)}
          />
        ) : type.kind === 'LIST' ? (
          <InlineListEditor
            type={type.ofType!}
            val={val}
            setVal={(v: any) => setVal(v)}
            indentLevel={indentLevel}
            charsBefore={charsBefore}
            autocompleteOptions={autocompleteOptions}
          />
        ) : type.kind === 'SCALAR' ? (
          type.name! === 'String' ? (
            <InlineStringEditor
              value={val || ''}
              disabled={disabled}
              setValue={v => setVal(v)}
              autocompleteOptions={autocompleteOptions}
            />
          ) : type.name! === 'Int' ? (
            // This doesn't render inline
            <InlineNumberEditor
              value={val}
              setValue={v => setVal(v)}
              min={1}
              max={500}></InlineNumberEditor>
          ) : type.name! === 'RunFilter' ? (
            entityName != null && projectName != null ? (
              <RunFilters
                entityName={entityName}
                projectName={projectName}
                filters={val || Filter.EMPTY_FILTERS}
                setFilters={v => setVal(v)}
                onFiltersChanged={() => console.log('onFiltersChanged')}
                preventOverflow
                trigger={(open, filterCount) => (
                  <S.InlineRunFiltersButton open={open}>
                    {filterCount} filters
                    <LegacyWBIcon name="next"></LegacyWBIcon>
                  </S.InlineRunFiltersButton>
                )}
              />
            ) : (
              'INVALID'
            )
          ) : (
            // <input />
            'UNKNOWN_SCALAR'
          )
        ) : (
          'UNKNOWN'
        )}
      </span>
    );
  },
  {id: 'QueryInputValueEditor'}
);

export default QueryInputValueEditor;
