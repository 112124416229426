import {ReactNode} from 'react';
import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Popup} from 'semantic-ui-react';
import * as ProjectPickerQuery from '../graphql/projectPickerQuery';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';
import {useAdminModeActive} from '../util/admin';

interface ProjectPickerProps {
  entityName: string;
  projectName: string;
  triggerOverride?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  setProject(entityName: string, projectName: string): void;
}

const ProjectPicker: React.FC<
  ProjectPickerProps & ProjectPickerQuery.QueryResultProps
> = makeComp(
  ({
    entityName,
    projectName,
    triggerOverride,
    onOpen,
    onClose,
    setProject,
    projectPickerQuery,
  }) => {
    const admin = useAdminModeActive();
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const firstRenderRef = useRef(true);
    useEffect(() => {
      if (firstRenderRef.current) {
        return;
      }
      if (open) {
        onOpen?.();
      } else {
        onClose?.();
      }
      // eslint-disable-next-line
    }, [open]);
    useEffect(() => {
      firstRenderRef.current = false;
    }, []);

    const projects = projectPickerQuery.projects ?? [];

    let projectsWithCurrentProject = projects;
    let currentProjectIndex = projects.findIndex(
      p => p.entityName === entityName && p.name === projectName
    );
    if (currentProjectIndex === -1) {
      projectsWithCurrentProject = [
        {
          entityName,
          name: projectName,
          id: '',
          createdAt: '',
        },
        ...projects,
      ];
      currentProjectIndex = 0;
    }

    let projectsWithAdminSuggestion = projectsWithCurrentProject;
    const searchQuerySplit = searchQuery.split(' / ');
    if (admin && searchQuerySplit.length === 2) {
      const [searchEntityName, searchProjectName] = searchQuerySplit;
      const adminSuggestionIncluded = projectsWithCurrentProject.some(
        p => p.entityName === searchEntityName && p.name === searchProjectName
      );
      if (!adminSuggestionIncluded) {
        projectsWithAdminSuggestion = [
          {
            entityName: searchEntityName,
            name: searchProjectName,
            id: '',
            createdAt: '',
          },
          ...projectsWithCurrentProject,
        ];
      }
    }

    const options = projectsWithAdminSuggestion.map((p, i) => ({
      text: p.entityName + ' / ' + p.name,
      value: i,
    }));
    return (
      <Popup
        basic
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className="wb-table-action-popup"
        on="click"
        position="bottom left"
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        trigger={
          triggerOverride ? (
            triggerOverride
          ) : (
            <Button size="tiny" className={'wb-icon-button'}>
              <LegacyWBIcon name="folder" />
              {entityName + ' / ' + projectName}
            </Button>
          )
        }
        content={
          <Form>
            <Form.Dropdown
              selection
              search
              searchQuery={searchQuery}
              onSearchChange={(e, {searchQuery: newSearchQuery}) =>
                setSearchQuery(newSearchQuery)
              }
              value={currentProjectIndex}
              options={options}
              onChange={(e, {value}) => {
                if (typeof value === 'number') {
                  const proj = projectsWithAdminSuggestion[value];
                  setProject(proj.entityName, proj.name);
                  setOpen(false);
                }
              }}
            />
          </Form>
        }
      />
    );
  },
  {id: 'ProjectPicker', memo: true}
);

export default ProjectPickerQuery.withQuery(ProjectPicker);
