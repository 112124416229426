import {Ref as PanelSettingsRef} from './types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import * as Redux from '../../../types/redux';

export function useWorkspaceAndLocalPanelSettingsAction<
  RefType extends PanelSettingsRef,
  ArgType extends any[]
>(
  localRef: RefType,
  workspaceRef: RefType,
  fn: (
    localRef: RefType,
    workspaceRef: RefType,
    ...fnArgs: ArgType
  ) => Redux.DispatchableAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(localRef, workspaceRef, ...args)),
    [dispatch, localRef, workspaceRef, fn]
  );
}

export function useAllPanelSettingsAction<
  RefType extends PanelSettingsRef,
  ArgType extends any[]
>(
  localRefs: RefType[],
  workspaceRef: RefType,
  fn: (
    localRefs: RefType[],
    workspaceRef: RefType,
    ...fnArgs: ArgType
  ) => Redux.DispatchableAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(localRefs, workspaceRef, ...args)),
    [dispatch, workspaceRef, localRefs, fn]
  );
}
