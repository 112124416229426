import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const ExamplesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const Examples = styled.div`
  background-color: ${globals.white};
  border-radius: 6px;
  border: 1px solid ${globals.border};
  padding: 32px;

  @media (max-width: 520px) {
    &&&&& {
      border-radius: 0;
      border-left: none;
      border-right: none;
      margin-top: 24px;
    }
  }
`;

export const ExamplesHeader = styled.h3`
  font-size: 20px;
  margin: 0;
`;

export const ExamplesText = styled.p`
  margin: 16px 0 0;
`;
