import * as globals from '../css/globals.styles';

import styled from 'styled-components';
import {Icon} from 'semantic-ui-react';
import {
  MOBILE_WIDTH,
  TABLET_WIDTH,
  SMALL_TABLET_WIDTH,
} from '../css/globals.styles';

export const Container = styled.div`
  margin-top: 24px;

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    display: flex;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-left: -32px;
    margin-right: -32px;
  }
`;

export const Picker = styled.div`
  flex-shrink: 0;

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

export const MobilePicker = styled.div`
  background-color: ${globals.gray800};
  color: ${globals.white};
  padding: 4px 32px;

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    display: none;
  }
`;

export const Choice = styled.div`
  padding: 6px 24px 6px 16px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;

  &:not(.active) {
    opacity: 0.6;
    &:hover {
      opacity: 0.9;
    }
  }

  &.active {
    background-color: ${globals.gray100};
  }
`;

export const Example = styled.div<{blankProject?: boolean}>`
  flex-grow: 1;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    max-width: ${props => (props.blankProject ? 560 : 365)}px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    max-width: 590px;
  }

  @media (max-width: 1200px) {
    max-width: 490px;
  }

  @media (max-width: 1100px) {
    max-width: 390px;
  }

  @media (max-width: ${SMALL_TABLET_WIDTH}px) {
    max-width: 610px;
  }

  @media (max-width: 900px) {
    max-width: 510px;
  }

  @media (max-width: 800px) {
    max-width: 455px;
  }

  @media (max-width: 700px) {
    max-width: 355px;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    max-width: none;
  }
`;

export const Code = styled.div`
  font-size: 14px;

  .copyable__item {
    position: relative;

    .copyable__text {
      overflow: auto;
      max-height: 286px;
      width: 100%;

      .code__item {
        padding-left: 20px;
      }
    }

    .copyable__copy-icon {
      position: absolute;
      left: auto;
      top: 5px;
      right: 5px;
      margin: 0;
    }
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    .code__block {
      border-radius: 0;

      .copyable__item {
        .copyable__text {
          .code__item {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }
  }
`;

export const Links = styled.div`
  padding: 16px 20px;

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    border-left: 1px solid ${globals.border};
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
  }
`;

export const Link = styled.a`
  display: block;
  color: ${globals.gray800};

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const LinkArrow = styled(Icon)`
  &&&&& {
    font-size: 11px;
  }
`;
