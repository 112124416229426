import * as _ from 'lodash';
import * as React from 'react';
import {Icon} from 'semantic-ui-react';

import LongformMarkdown from '../../components/LongformMarkdown';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

const RANK_METRIC = 'val_acc';

const keys = [RANK_METRIC];

const scoreCols = _.map(keys, k => ({
  Header:
    k === RANK_METRIC ? (
      <span style={{fontWeight: 'bold'}}>
        {k}
        <Icon name="caret up" />
      </span>
    ) : (
      k
    ),
  id: k,
  accessor: (run: WithSummary<BenchmarkRun>) => {
    return Run.displayValue(run.summary[k]);
  },
}));

const leaderboardCols = [...Leaderboard.cols, ...scoreCols];

const CONCEPT = `
## Introduction

This is the Weights & Biases community benchmark for deep learning models for drought detection from
satellite. With better models, index insurance companies can monitor drought 
conditions&mdash;and send resources to families in the area&mdash;more effectively. The goal is to learn
from ~100K expert labels of forage quality in Northern Kenya (concretely, how many cows from
0 to 3+ can the given geolocation feed?) to more accurately predict drought conditions
from unlabeled satellite images. 

## Getting started

Check out [this repo](https://github.com/wandb/droughtwatch).

From there, you can follow the brief instructions to clone the repo, download the data,
and start training models in a few lines of code. You can upload your experiment runs to W&B to easily
visualize the training, explore the hyperparameters and architecture, and help improve the model.

### Reports

You can check out [this short report](https://app.wandb.ai/stacey/droughtwatch/reports/Drought-Watch-Benchmark-Progress--Vmlldzo3ODQ3OQ) to see how
the baseline was trained, explore some of the submissions so far, and get inspiration for how to run or showcase your experiments. We
would love to see and feature your reports based on Drought Watch&mdash;please include a link to your report with your submission to the benchmark.

## Evaluation

This is an open collaborative benchmark&mdash;the goal is to build the most accurate model together. We encourage you
to include your code and share notes, findings, or observations about your process. You can use this platform to see what
others have tried, get ideas, and synthesize different approaches. We will be using the "val_acc" metric to
sort all submissions. This is the validation accuracy on data in the 'val' folder provided, computed automatically
in the baseline training script. We're also reserving a test set to evaluate long-term generalization.

## Resources

A writeup of the project and dataset is now available on Arxiv: [Satellite-based Prediction of Forage Conditions for Livestock in Northern Kenya](https://arxiv.org/abs/2004.04081).
This work will be presented at ICLR 2020 as part of the [Computer Vision for Agriculture](https://www.cv4gc.org/cv4a2020/) workshop--we will link to the recording once it is available.
You can also find more information on Drought Watch [in this blog post](https://wandb.ai/site/articles/droughtwatch) and [this latest update](https://wandb.ai/site/articles/droughtwatch-collaborative-deep-learning-for-our-planet).

## Dataset credits

The data used in this research was collected through a research collaboration between the International Livestock Research Institute, Cornell University, and UC San Diego.
It was supported by the Atkinson Centre for a Sustainable Future’s Academic Venture Fund, Australian Aid through the AusAID Development Research Awards Scheme Agreement No. 66138,
the National Science Foundation (0832782, 1059284, 1522054), and ARO grant W911-NF-14-1-0498.
`;

const SUBMISSION_INSTRUCTIONS = (projectName: string) => `
Runs in this project can be submitted to the Drought Watch public benchmark.

### Setup instructions

Go to [the starter GitHub repository](https://github.com/wandb/droughtwatch) for this project.
Follow [the Setup Instructions](https://github.com/wandb/droughtwatch#setup-instructions) in the README to set up your environment and
wandb logging, download the data, and start training models in just a few commands.

#### Custom project naming

By default, your training runs will be logged in a project named "droughtwatch". If you chose a different project name while
joining this benchmark that you would like to use for tracking your runs, pass that project name as an argument to wandb.init()
in the training script you run from the starter repo, e.g. wandb.init(project="your custom project name").

### How to submit your results

You can submit runs to this benchmark from the "Runs" table in your "Project workspace" tab.

To submit a specific run, hover over the run's name, click on the
three-dot menu icon that appears to the left of the name, and select "Submit to benchmark". Submissions will become visible within a few days as we are able
to review them.
`;

const overview = () => {
  return <LongformMarkdown content={CONCEPT} />;
};

const leaderboard = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <WBReactTable
        pageSize={20}
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, RANK_METRIC, true)}
      />
    </React.Fragment>
  );
};

const theme: Theme = {
  keys,
  benchmarkLink: 'https://github.com/wandb/droughtwatch',
  overview,
  leaderboard,
  submissionGuidelines: SUBMISSION_INSTRUCTIONS,
  codeRequired: true,
  embargo:
    'With this option set, your GitHub code/link will not be shown on the W&B platform.',
};

export default theme;
