import React from 'react';
import styled from 'styled-components';
import {PropertyEditorComponent} from './property-editors';
import {Gradient, GradientPicker, WBGradient} from '../GradientPicker';
import {
  InspectorPropertyWrapper,
  InspectorPropertyLabel,
} from '../Inspector.styles';
import InspectorPopout from '../InspectorPopout';
import makeComp from '../../util/profiler';

const DisplayedGradient = styled(Gradient)`
  border-radius: 6px;
  margin-right: 8px;
  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }
`;

const GradientPropertyEditor: PropertyEditorComponent<'gradient'> = makeComp(
  props => {
    const [wrapperNode, setWrapperNode] = React.useState<HTMLElement | null>(
      null
    );
    const [openedGradient, setOpenedGradient] =
      React.useState<WBGradient | null>(null);

    return (
      <InspectorPropertyWrapper ref={node => setWrapperNode(node)}>
        <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
        {props.values.map(grad => {
          const typedGradient = {
            type: 'customGradient' as const,
            gradient: grad,
          };
          return (
            <DisplayedGradient
              onClick={e => {
                e.stopPropagation();
                setOpenedGradient(typedGradient);
                props.setOpenedPopout(props.propertyName);
              }}
              index={Math.random()}
              gradient={typedGradient}
              width={140 / props.values.length - 8}
              height={20}></DisplayedGradient>
          );
        })}
        {props.openedPopout === props.propertyName &&
          openedGradient &&
          wrapperNode && (
            <InspectorPopout anchor={wrapperNode}>
              <GradientPicker
                defaultGradient={openedGradient}
                setGradient={gradient => {
                  props.save(gradient);
                }}></GradientPicker>
            </InspectorPopout>
          )}
      </InspectorPropertyWrapper>
    );
  },
  {id: 'GradientPropertyEditor'}
);

export default GradientPropertyEditor;
