import compareVersions from 'compare-versions';
import {captureError} from '../util/integrations';
import {envIsProd, envIsTest} from '../config';
// Given two version strings, returns true if the version denoted by
// `candidate` is greater than or equal to the version denoted by `target`.
// e.g., versionGte("2.1", "2.0") -> true
export function versionGte(candidate: string, target: string) {
  // compareVersions wants prerelease versions to look like 0.1.0-alpha
  // but we call them 0.1.0rc1, so we change 0.1.0rc1 to 0.1.0-rc1 to
  // help compareVersions remain calm.
  candidate = candidate.replace(/(\d)\.?([^\d.-]+)/, '$1-$2');
  target = target.replace(/(\d)\.?([^\d.-]+)/, '$1-$2');
  try {
    return compareVersions.compare(candidate, target, '>=');
  } catch (e) {
    if (!envIsTest) {
      console.error(e);
    }
    if (envIsProd) {
      captureError(
        e,
        `Invalid cli version comparison ${candidate} >= ${target}`
      );
    }
  }
  return false;
}
