import {useState} from 'react';
import * as React from 'react';
import {Checkbox, Dropdown, Input, Popup, Table} from 'semantic-ui-react';
import '../css/Alerts.less';
import {
  Alert,
  AlertSubscription,
  SlackIntegration as Slack,
  StoppedRunCondition,
  useCreateEmailSubscriptionMutation,
  useCreateFinishedRunAlertMutation,
  useCreateScriptableRunAlertMutation,
  useCreateSlackChannelSubscriptionMutation,
  useCreateStoppedRunAlertMutation,
  useDeleteAlertSubscriptionMutation,
  useUpdateStoppedRunAlertMutation,
} from '../generated/graphql';
import {Subset} from '../types/base';
import {Entity} from '../types/graphql';
import {isSlackIntegration, toSlackIntegration} from './SlackIntegration';
import _ from 'lodash';
import makeComp from '../util/profiler';

export type AlertSubscriptionType =
  | 'EmailSubscription'
  | 'SlackChannelSubscription';

export interface AlertsProps {
  entity: Subset<Entity, 'id' | 'name' | 'integrations' | 'defaultAlerts'>;
  entityRefetch: any;
  subscriptionTypes: AlertSubscriptionType[];
}

type Unit = 'minutes' | 'hours' | 'days';

const timeUnits = [
  {key: 'minutes', text: 'minutes', value: 'minutes'},
  {key: 'hours', text: 'hours', value: 'hours'},
  {key: 'days', text: 'days', value: 'days'},
];

function toMilliseconds(duration: number, unit: Unit): number {
  if (unit === 'minutes') {
    return duration * (1000 * 60);
  } else if (unit === 'hours') {
    return duration * (1000 * 60 * 60);
  } else if (unit === 'days') {
    return duration * (1000 * 60 * 60 * 24);
  }
  throw new Error(`invalid unit ${unit}`);
}

function fromMilliseconds(
  milliseconds: number | undefined,
  unit: Unit
): number | undefined {
  if (milliseconds === undefined) {
    return undefined;
  } else if (unit === 'minutes') {
    return milliseconds / (1000 * 60);
  } else if (unit === 'hours') {
    return milliseconds / (1000 * 60 * 60);
  } else if (unit === 'days') {
    return milliseconds / (1000 * 60 * 60 * 24);
  }
  throw new Error(`invalid unit ${unit}`);
}

function defaultUnit(milliseconds: number): Unit {
  if (milliseconds >= 1000 * 60 * 60 * 24) {
    return 'days';
  } else if (milliseconds >= 1000 * 60 * 60) {
    return 'hours';
  } else {
    return 'minutes';
  }
}

function defaultScriptDurationMilliseconds(
  entity: AlertsProps['entity']
): number | undefined {
  const stoppedRunAlert = entity.defaultAlerts.find(
    alert => (alert.condition as any).__typename === 'StoppedRunCondition'
  );

  if (stoppedRunAlert !== undefined) {
    return (stoppedRunAlert.condition as StoppedRunCondition)
      .minimumRunDuration;
  }

  return undefined;
}

const Alerts = makeComp(
  (props: AlertsProps) => {
    const {entity, entityRefetch, subscriptionTypes} = props;
    const defaultDurationMillis = defaultScriptDurationMilliseconds(entity);
    const [durationUnit, setDurationUnit] = useState<
      'minutes' | 'hours' | 'days'
    >(
      defaultDurationMillis !== undefined
        ? defaultUnit(defaultDurationMillis)
        : 'minutes'
    );
    const [scriptDurationMilliseconds, setScriptDurationMilliseconds] =
      useState<number | undefined>(defaultDurationMillis);

    const [createFinishedRunAlert] = useCreateFinishedRunAlertMutation();
    const [createStoppedRunAlert] = useCreateStoppedRunAlertMutation();
    const [updateStoppedRunAlert] = useUpdateStoppedRunAlertMutation();
    const [createScriptableRunAlert] = useCreateScriptableRunAlertMutation();

    const finishedRunAlert = entity.defaultAlerts.find(
      alert => (alert.condition as any).__typename === 'FinishedRunCondition'
    );
    const stoppedRunAlert = entity.defaultAlerts.find(
      alert => (alert.condition as any).__typename === 'StoppedRunCondition'
    );
    const scriptableRunAlert = entity.defaultAlerts.find(
      alert => (alert.condition as any).__typename === 'ScriptableRunCondition'
    );

    let slackIntegration: Slack | undefined;
    for (const integrationEdge of entity.integrations.edges) {
      if (isSlackIntegration(integrationEdge.node)) {
        slackIntegration = toSlackIntegration(integrationEdge.node);
      }
    }

    const finishedRunAlertCreator = () => {
      return createFinishedRunAlert({
        variables: {
          entityName: entity.name,
        },
      }).then(alert => {
        return alert.data!.createFinishedRunAlert!.alert.id;
      });
    };

    const stoppedRunAlertCreator = () => {
      return createStoppedRunAlert({
        variables: {
          entityName: entity.name,
          minimumRunDuration:
            scriptDurationMilliseconds !== undefined
              ? scriptDurationMilliseconds
              : toMilliseconds(10, 'minutes'),
        },
      }).then(alert => {
        return alert.data!.createStoppedRunAlert!.alert.id;
      });
    };

    const scriptableRunAlertCreator = () => {
      return createScriptableRunAlert({
        variables: {
          entityName: entity.name,
        },
      }).then(alert => {
        return alert.data!.createScriptableRunAlert!.alert.id;
      });
    };

    return (
      <div className="alerts">
        <Table className="alerts--table" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{fontWeight: 600}}>
                Events
              </Table.HeaderCell>
              {subscriptionTypes.map(type => {
                if (type === 'EmailSubscription') {
                  return (
                    <Table.HeaderCell key={type} style={{fontWeight: 600}}>
                      Email
                    </Table.HeaderCell>
                  );
                } else {
                  return (
                    <Table.HeaderCell key={type} style={{fontWeight: 600}}>
                      Slack
                    </Table.HeaderCell>
                  );
                }
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Run finished</Table.Cell>
              {subscriptionTypes.map(type => {
                if (type === 'EmailSubscription') {
                  return (
                    <Table.Cell key={type}>
                      <EmailSubscription
                        key={`${type}|${finishedRunAlert?.id}`}
                        alert={finishedRunAlert}
                        alertCreator={finishedRunAlertCreator}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                } else {
                  return (
                    <Table.Cell key={type}>
                      <SlackChannelSubscription
                        key={`${type}|${finishedRunAlert?.id}`}
                        alert={finishedRunAlert}
                        alertCreator={finishedRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                }
              })}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Run crashed after
                <Input
                  className="alerts--crashed-duration"
                  size="mini"
                  type="number"
                  value={fromMilliseconds(
                    scriptDurationMilliseconds,
                    durationUnit
                  )}
                  onChange={_.debounce((e, {value}) => {
                    const durationInMilliseconds = toMilliseconds(
                      parseFloat(value),
                      durationUnit
                    );
                    setScriptDurationMilliseconds(durationInMilliseconds);

                    const alertUpdater = (id: string) => {
                      return updateStoppedRunAlert({
                        variables: {
                          id,
                          minimumRunDuration: durationInMilliseconds,
                        },
                      }).then();
                    };

                    if (stoppedRunAlert !== undefined) {
                      alertUpdater(stoppedRunAlert.id).then();
                    } else {
                      stoppedRunAlertCreator()
                        .then(alertID => {
                          return alertUpdater(alertID);
                        })
                        .then(entityRefetch);
                    }
                  }, 1)}
                  placeholder="10"
                  label={
                    <Dropdown
                      defaultValue={durationUnit}
                      options={timeUnits}
                      onChange={(event, data) => {
                        setDurationUnit(data.value as Unit);
                      }}
                    />
                  }
                  labelPosition="right"
                />
              </Table.Cell>
              {subscriptionTypes.map(type => {
                if (type === 'EmailSubscription') {
                  return (
                    <Table.Cell key={type}>
                      <EmailSubscription
                        key={`${type}|${stoppedRunAlert?.id}`}
                        alert={stoppedRunAlert}
                        alertCreator={stoppedRunAlertCreator}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                } else {
                  return (
                    <Table.Cell key={type}>
                      <SlackChannelSubscription
                        key={`${type}|${stoppedRunAlert?.id}`}
                        alert={stoppedRunAlert}
                        alertCreator={stoppedRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                }
              })}
            </Table.Row>
            <Table.Row>
              <Table.Cell>Scriptable run alerts</Table.Cell>
              {subscriptionTypes.map(type => {
                if (type === 'EmailSubscription') {
                  return (
                    <Table.Cell key={type}>
                      <EmailSubscription
                        key={`${type}|${scriptableRunAlert?.id}`}
                        alert={scriptableRunAlert}
                        alertCreator={scriptableRunAlertCreator}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                } else {
                  return (
                    <Table.Cell key={type}>
                      <SlackChannelSubscription
                        key={`${type}|${scriptableRunAlert?.id}`}
                        alert={scriptableRunAlert}
                        alertCreator={scriptableRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                      />
                    </Table.Cell>
                  );
                }
              })}
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  },
  {id: 'Alerts'}
);

export default Alerts;

interface SubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  subscription: AlertSubscription | undefined;
  subscriptionCreator: (id: string) => Promise<any>;
  disabled: boolean;
  disabledReason: string;
  label: string;
  refetch: any;
}

const Subscription = makeComp(
  (props: SubscriptionProps) => {
    const [subscriptionRemover] = useDeleteAlertSubscriptionMutation();
    const {
      alert,
      alertCreator,
      subscription,
      subscriptionCreator,
      disabled,
      disabledReason,
      label,
      refetch,
    } = props;

    return (
      <Popup
        className="alerts--config-popup"
        disabled={!disabled}
        on="hover"
        content={disabledReason}
        trigger={
          <Checkbox
            className="alerts--config-slider"
            toggle
            label={label}
            disabled={disabled}
            checked={subscription !== undefined}
            onChange={(e, data) => {
              const updateSub = (id: string) => {
                if (data.checked === true && subscription === undefined) {
                  return subscriptionCreator(id).then(refetch);
                } else if (
                  data.checked === false &&
                  subscription !== undefined
                ) {
                  return subscriptionRemover({
                    variables: {
                      id: subscription.id,
                    },
                  }).then(refetch);
                } else {
                  return Promise.resolve();
                }
              };

              // Create the alert if it doesn't yet exist
              if (alert === undefined) {
                alertCreator().then(newAlertID => {
                  return updateSub(newAlertID);
                });
              } else {
                updateSub(alert.id);
              }
            }}
          />
        }
      />
    );
  },
  {id: 'Subscription'}
);

interface EmailSubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  refetch: any;
}

const EmailSubscription = makeComp(
  (props: EmailSubscriptionProps) => {
    const {refetch, alert, alertCreator} = props;
    const [createEmailSubscription] = useCreateEmailSubscriptionMutation();

    let emailSubscription: AlertSubscription | undefined;
    if (alert !== undefined) {
      emailSubscription = alert.subscriptions.find(
        sub => (sub as any).__typename === 'EmailSubscription'
      );
    }

    return (
      <Subscription
        alert={alert}
        alertCreator={alertCreator}
        subscription={emailSubscription}
        disabled={false}
        disabledReason={''}
        label={''}
        refetch={refetch}
        subscriptionCreator={(id: string) => {
          return createEmailSubscription({
            variables: {
              alertID: id,
            },
          });
        }}
      />
    );
  },
  {id: 'EmailSubscription'}
);

interface SlackChannelSubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  integration: Slack | undefined;
  refetch: any;
}

const SlackChannelSubscription = makeComp(
  (props: SlackChannelSubscriptionProps) => {
    const {refetch, alert, alertCreator, integration} = props;
    const [createSlackChannelSubscription] =
      useCreateSlackChannelSubscriptionMutation();

    let slackChannelSubscription: AlertSubscription | undefined;
    if (alert !== undefined) {
      slackChannelSubscription = alert.subscriptions.find(
        sub => (sub as any).__typename === 'SlackChannelSubscription'
      );
    }

    return (
      <Subscription
        alert={alert}
        alertCreator={alertCreator}
        subscription={slackChannelSubscription}
        disabled={integration === undefined}
        disabledReason={'Connect Slack to enable this feature'}
        label={''}
        refetch={refetch}
        subscriptionCreator={(id: string) => {
          return createSlackChannelSubscription({
            variables: {
              integrationID: integration!.id,
              alertID: id,
            },
          });
        }}
      />
    );
  },
  {id: 'SlackChannelSubscription'}
);
