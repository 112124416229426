import '../css/CreateSweepPage.less';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import YAML from 'yaml';

import classnames from 'classnames';
import {
  generateSweepYamlFromConfig,
  getConfigFromRunsData,
  useAllRunsData,
  SweepYamlConfig,
} from '../util/sweeps';
import * as Generated from '../generated/graphql';
import {RunQueryContext} from '../state/runs/context';
import {useNewObjectRef, useWhole, usePart} from '../state/views/hooks';
import {Button, Icon} from 'semantic-ui-react';
import {navigateTo} from '../util/history';
import * as urls from '../util/urls';
import {ExecutionResult} from 'apollo-link';
import docUrl from '../util/doc_urls';
import {Bash, Command} from '../components/Code';
import Editor from '../components/Monaco/Editor';
import * as SM from '../util/selectionmanager';
import {combinedFilter} from '../state/graphql/runSelectorQuery';
import {EMPTY_FILTERS} from '../util/filters';
import * as RunSetTypes from '../state/views/runSet/types';
import * as TempSelectionsTypes from '../state/views/tempSelections/types';
import * as RunTypes from '../state/runs/types';
import {propagateErrorsContext} from '../util/errors';
import {useSelectedCounts} from '../state/graphql/selectedCountQuery';
import {useDispatch, useSelector} from '../state/hooks';
import {clearCreateSweepArgs} from '../state/sweeps/actions';
import RunSelector from '../components/RunSelector';
import {emptyRunSetSelectAll} from '../util/section';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

interface CreateSweepPageProps {
  match: {
    params: {
      entityName: string;
      projectName: string;
    };
  };
  history: any;
}

interface CreateSweepPageInnerProps extends CreateSweepPageProps {
  runsData: RunTypes.Data;
  runSetRef: RunSetTypes.Ref;
  tempSelectionsRef: TempSelectionsTypes.Ref;
}

type CreateSweepStep = 'prior-runs' | 'edit-config' | 'instructions';

interface StepProps {
  title?: ReactNode;
  text?: ReactNode;
  content?: ReactNode;
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  wide?: boolean;
}

const CreateSweepPage: React.FC<CreateSweepPageProps> = makeComp(
  props => {
    const {
      match: {
        params: {entityName, projectName},
      },
    } = props;

    const {loading: runsDataLoading, data: runsData} = useAllRunsData(
      entityName,
      projectName
    );

    const dispatch = useDispatch();

    const priorRunsArgs = useSelector(state => state.sweeps.createSweepArgs);
    useEffect(() => {
      return () => {
        dispatch(clearCreateSweepArgs());
      };
    }, [dispatch]);

    const emptyRunSet = useMemo(emptyRunSetSelectAll, []);
    const {ready: runSetRefReady, ref: runSetRef} = useNewObjectRef(
      'runSet',
      priorRunsArgs?.runSet ?? emptyRunSet
    );

    const {ready: tempSelectionsRefReady, ref: tempSelectionsRef} =
      useNewObjectRef(
        'temp-selections',
        priorRunsArgs?.tempSelections ?? SM.EMPTY_NONE_SELECTIONS
      );

    if (
      runsDataLoading ||
      runsData == null ||
      !runSetRefReady ||
      runSetRef == null ||
      !tempSelectionsRefReady ||
      tempSelectionsRef == null
    ) {
      return null;
    }

    return (
      <RunQueryContext.Provider
        value={{
          entityName,
          projectName,
        }}>
        <CreateSweepPageInner
          {...props}
          {...{runsData, runSetRef, tempSelectionsRef}}
        />
      </RunQueryContext.Provider>
    );
  },
  {id: 'CreateSweepPage'}
);

const CreateSweepPageInner: React.FC<CreateSweepPageInnerProps> = makeComp(
  props => {
    const {
      runsData,
      runSetRef,
      tempSelectionsRef,
      match: {
        params: {entityName, projectName},
      },
    } = props;

    const [upsertSweep] = Generated.useUpsertSweepMutation({
      // Show the error message to the user instead of rendering error page
      context: propagateErrorsContext(),
      onError: ({networkError}) => {
        const message = (networkError as any)?.result?.errors[0]?.message;
        if (message != null) {
          alert(message);
        }
      },
    });

    const runSet = usePart(runSetRef);
    const filters = useWhole(runSet.filtersRef);

    const groupSelections = useWhole(runSet.groupSelectionsRef);
    const tempSelections = useWhole(tempSelectionsRef);
    const groupTempSelection = useMemo(
      () => ({
        grouping: groupSelections.grouping,
        selections: tempSelections,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      }),
      [
        groupSelections.grouping,
        tempSelections,
        groupSelections.expandedRowAddresses,
      ]
    );

    const {tempSelectedCount} = useSelectedCounts(runSetRef, tempSelectionsRef);

    const [step, setStep] = useState<CreateSweepStep>('edit-config');
    const [creatingSweep, setCreatingSweep] = useState(false);
    const [sweep, setSweep] = useState<any>(null);
    const [configYAML, setConfigYAML] = useState(
      generateSweepYamlFromConfig(getConfigFromRunsData(runsData))
    );

    const getPriorRunsFilter = () => {
      return combinedFilter(
        filters ?? EMPTY_FILTERS,
        groupTempSelection,
        undefined,
        undefined,
        runSet.search.query
      );
    };

    const createSweep = async () => {
      if (sweep != null) {
        return;
      }
      setCreatingSweep(true);

      try {
        const config = YAML.parse(configYAML) as SweepYamlConfig;
        const description = config.description ?? '';

        const result = await upsertSweep({
          variables: {
            config: configYAML,
            entityName,
            projectName,
            priorRunsFilters: JSON.stringify(getPriorRunsFilter()),
            description,
          },
        });

        if (result == null) {
          // result may be undefined if upsertSweep errors even though its type says otherwise
          return;
        }

        if (!result.data?.upsertSweep?.inserted) {
          throwError('invalid inserted value', result);
        }

        const newSweep = result.data?.upsertSweep?.sweep;
        if (!newSweep?.name) {
          throwError('invalid sweep name', result);
        }

        setSweep(newSweep);
        setStep('instructions');
      } catch (e) {
        alert(`Error creating sweep: ${e.message || e}`);
      } finally {
        setCreatingSweep(false);
      }
    };

    const goToSweepPage = () => {
      if (sweep == null) {
        return;
      }
      navigateTo({
        pathname: urls.sweepOverview({
          entityName,
          projectName,
          sweepName: sweep.name,
        }),
        preserveSearch: true,
      });
    };

    const docLink = (
      <TargetBlank href={docUrl.sweeps}>
        See the docs <Icon name="arrow right" size="small" />
      </TargetBlank>
    );

    const priorRunsProps = {
      title: 'Prior Runs',
      text: (
        <>
          Select prior runs to feed into your sweep. Random and Grid search
          sweeps will skip these combinations. Bayesian optimization will use
          these runs to inform where to search next. {docLink}
        </>
      ),
      content: (
        <RunQueryContext.Provider
          value={{
            entityName,
            projectName,
          }}>
          <RunSelector
            className="single-wb-table"
            pageEntityName={entityName}
            pageProjectName={projectName}
            pollInterval={0}
            runSetRef={runSetRef}
            tempSelectionsRef={tempSelectionsRef}
            disabledFeatures={{'create-sweep': true, 'toggle-visibility': true}}
          />
        </RunQueryContext.Provider>
      ),
      rightButton: (
        <Button size="tiny" primary onClick={() => setStep('edit-config')}>
          Next
        </Button>
      ),
      wide: true,
    };

    const editConfigProps = {
      title: 'Sweep Configuration',
      text: (
        <>
          Edit the sweep configuration before you launch your sweep. From
          existing runs, we guess good defaults for sweep ranges that are
          slightly broader than the existing ranges of values you've logged.{' '}
          {docLink}
        </>
      ),
      content: (
        <div className="editor-wrapper">
          <Editor
            value={configYAML}
            onChange={setConfigYAML}
            height={500}
            language="yaml"
            theme="vs-dark"
          />
        </div>
      ),
      leftButton: (
        <Button size="tiny" onClick={() => setStep('prior-runs')}>
          Configure prior runs ({tempSelectedCount})
        </Button>
      ),
      rightButton:
        sweep == null ? (
          <Button
            size="tiny"
            primary
            disabled={creatingSweep}
            loading={creatingSweep}
            onClick={createSweep}>
            Initialize Sweep
          </Button>
        ) : (
          <Button size="tiny" primary onClick={() => setStep('instructions')}>
            Next
          </Button>
        ),
    };

    let instructionProps = {};
    if (sweep != null) {
      const sampleRun = null;
      // const sampleRun = sampleRunCommand(sweep.config);
      instructionProps = {
        title: 'Launch Agent(s)',
        text: (
          <>
            On your own machines, launch one or more agents to run the sweep.
            When you launch an agent, this page will become your sweep
            dashboard. {docLink}
          </>
        ),
        content: (
          <div className="text">
            <p>Run the wandb agent command to start a sweep.</p>
            <Bash>
              <Command>
                wandb agent {entityName}/{projectName}/{sweep.name}
              </Command>
            </Bash>
            {sampleRun != null ? (
              <>
                <p>This will cause the agent to execute something like:</p>
                <Bash>
                  <Command>{sampleRun}</Command>
                </Bash>
              </>
            ) : (
              <p>
                The agent will request the next set of parameters for your
                sweep, then launch a run with those parameters.
              </p>
            )}
            <p>
              Once the run finishes, the agent will run another command with
              different parameter values.
            </p>
            <p>
              You can run multiple wandb agents on a single machine or different
              machines.
            </p>
          </div>
        ),
        rightButton: (
          <Button size="tiny" primary onClick={goToSweepPage}>
            View Sweep
          </Button>
        ),
      };
    }

    return (
      <div className="create-sweep-page">
        <div className="create-sweep-page-content">
          <Step show={step === 'prior-runs'} {...priorRunsProps} />
          <Step show={step === 'edit-config'} {...editConfigProps} />
          <Step show={step === 'instructions'} {...instructionProps} />
        </div>
      </div>
    );
  },
  {id: 'CreateSweepPageInner'}
);

export default CreateSweepPage;

const Step: React.FC<StepProps & {show: boolean}> = makeComp(
  ({title, text, content, leftButton, rightButton, wide, show}) => {
    if (title == null || text == null || content == null) {
      return null;
    }
    return (
      <div className={classnames('create-sweep-step', {wide, show})}>
        <div className="create-sweep-step-title">{title}</div>
        <div className="create-sweep-step-content">
          <p>{text}</p>
          {content}
        </div>
        <div className="create-sweep-step-actions">
          {leftButton && <div className="left-action">{leftButton}</div>}
          {rightButton && <div className="right-action">{rightButton}</div>}
        </div>
      </div>
    );
  },
  {id: 'CreateSweepPage.Step'}
);

function throwError(message: string, result: ExecutionResult): never {
  throw new Error(
    `CreateSweepPage: ${message}\ndata: ${JSON.stringify(result)}`
  );
}
