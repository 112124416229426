import gql from 'graphql-tag';
import {History} from 'history';
import _ from 'lodash';
import React, {FC} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import DeferredQueryData from '../containers/DeferredQueryData';
import '../css/ProfilePage.less';
import {UserAccountType, UserRunsQueryResult} from '../generated/graphql';
import {Project} from '../graphql/allProjectsQuery';
import {Graph, Team, UserInfo} from '../types/graphql';
import {viewerUsingAdminPrivileges} from '../util/admin';
import {setDocumentTitle} from '../util/document';
import makeComp from '../util/profiler';
import {isPublicRead} from '../util/projectAccess';
import {canonicalize} from '../util/url';
import {profilePage, reportGallery, setMetaDescription} from '../util/urls';
import ActivityCalendar from './ActivityCalendar';
import Egg from './Egg';
import ExpandableProjectList from './ExpandableProjectList';
import PageWithSidebar from './PageWithSidebar';
import ProfileSidebar from './ProfileSidebar';
import ProjectsTableUser from './ProjectsTableUser';
import ReportShowcase from './ReportShowcase';
import ReportsTable, {
  reportsTableFragment,
  ReportsTableReport,
} from './ReportsTable';
import RoutedTab from './RoutedTab';
import RunsTable, {runsTableFragment, RunsTableRun} from './RunsTable';

interface ProfilePageProps {
  data?: ProfilePageData;
  viewer?: {
    username: string;
    admin?: boolean;
  };
  storageBytes: number;
  filePath: string;
  history: History;
  tab?: string;
  userRunsQuery: UserRunsQueryResult;
}

export const profilePageFragment = gql`
  fragment ProfilePageFragment on User {
    id
    name
    username
    photoUrl
    userInfo
    accountType
    defaultEntity {
      id
      name
    }
    views(viewType: "runs", first: 10000) {
      edges {
        node {
          ...ReportsTableFragment
        }
      }
    }
    starredViews(first: 500) {
      edges {
        node {
          ...ReportsTableFragment
        }
      }
    }
    projects(first: 100) {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          totalUsers
          lastActive
          totalRunTime
        }
      }
    }
    teams(first: 100) {
      edges {
        node {
          id
          name
          photoUrl
          isTeam
        }
      }
    }
  }
  ${reportsTableFragment}
`;

export const USER_RUNS_QUERY = gql`
  query UserRuns($entityName: String!, $dailyRunCountLimit: Int!) {
    user(userName: $entityName) {
      id
      dailyRunCount(limit: $dailyRunCountLimit)
      runs(first: 100) {
        edges {
          node {
            ...RunsTableFragment
          }
        }
      }
    }
  }
  ${runsTableFragment}
`;

export interface ProfilePageData {
  username: string;
  photoUrl: string;
  name?: string;
  userInfo?: UserInfo;
  id: string;
  accountType: string;
  projects: Graph<Project>;
  views: Graph<ReportsTableReport>;
  starredViews: Graph<ReportsTableReport>;
  teams: Graph<Team>;
}

const ProfilePage: FC<ProfilePageProps> = makeComp(
  props => {
    const {data, viewer, userRunsQuery, history} = props;

    React.useEffect(() => {
      // meta description tag for google search results
      if (data) {
        if (data.username) {
          setDocumentTitle(getTitle(data.name ?? data.username), false);
        }
        const {name, userInfo} = data;
        const descParts: string[] = [];
        if (name) {
          descParts.push(`View ML projects from ${name} on Weights & Biases.`);
        }
        if (userInfo != null) {
          const {bio, company, location} = userInfo;
          if (bio) {
            descParts.push(`${bio}.`);
          }
          if (company && location) {
            descParts.push(`Working at ${company} in ${location}.`);
          } else if (company) {
            descParts.push(`Working at ${company}.`);
          } else if (location) {
            descParts.push(`Working in ${location}.`);
          }
        }
        if (descParts.length > 0) {
          setMetaDescription(descParts.join(' '));
        }
      }
    }, [data]);

    if (data == null) {
      return <Loader />;
    }

    const isOwnPage =
      data.username === viewer?.username &&
      data.accountType !== UserAccountType.Anonymous;

    const editable =
      isOwnPage || Boolean(viewer?.admin && viewerUsingAdminPrivileges());

    const showLikesTab = data.starredViews.edges.length > 0 || isOwnPage;

    const userHasPublicReports = data.views.edges.some(({node: r}) =>
      isPublicRead(r.project)
    );

    const promptUserToShowcase = editable && userHasPublicReports;

    const showcasedReports = _.sortBy(
      data.views.edges.map(e => e.node).filter(r => r.showcasedAt != null),
      r => (r.showcasedAt != null ? new Date(r.showcasedAt) : new Date())
    );

    return (
      <PageWithSidebar className="profile-page">
        <Helmet>
          <meta property="og:type" content="profile" />
          <meta
            property="og:url"
            content={canonicalize(window.location.href)}
          />
          <meta
            property="og:title"
            content={getTitle(data.name ?? data.username)}
          />
          <meta property="og:image" content={data.photoUrl} />
          {data.userInfo?.bio && (
            <meta property="og:description" content={data.userInfo.bio} />
          )}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content={data.username} />
          <link rel="canonical" href={canonicalize(window.location.href)} />
        </Helmet>
        <ProfileSidebar data={data} editable={editable} />
        <div className="main-page">
          <RoutedTab
            history={history}
            baseUrl={profilePage(data.username)}
            tabSlugs={['overview', 'projects', 'likes']}
            activeTabSlug={props.tab}
            panes={[
              {
                menuItem: 'Overview',
                render: () => (
                  <>
                    {(promptUserToShowcase || showcasedReports.length > 0) && (
                      <ReportShowcase
                        inProfilePage
                        reports={showcasedReports}
                        readOnly={!editable}
                      />
                    )}
                    <ExpandableProjectList
                      projects={data.projects}
                      entityName={data.username}
                      readOnly={!editable}
                    />
                    <DeferredQueryData query={userRunsQuery}>
                      {runsData => (
                        <ActivityCalendar
                          dailyRunCount={runsData?.user?.dailyRunCount ?? []}
                        />
                      )}
                    </DeferredQueryData>
                    <DeferredQueryData query={userRunsQuery}>
                      {runsData => {
                        const runs = runsData?.user?.runs ?? {edges: []};
                        return (
                          <RunsTable
                            data={runs as unknown as Graph<RunsTableRun>}
                            name="Runs"
                            showUserColumn={false}
                          />
                        );
                      }}
                    </DeferredQueryData>
                    <ReportsTable
                      data={data.views}
                      pageSize={10}
                      showShowcase={editable}
                    />
                  </>
                ),
              },
              {
                menuItem: 'Projects',
                render: () => (
                  <ProjectsTableUser
                    entityName={data.username}
                    readOnly={!editable}
                  />
                ),
              },
              ...(showLikesTab
                ? [
                    {
                      menuItem: 'Likes',
                      render: () =>
                        data.starredViews.edges.length > 0 ? (
                          <ReportsTable
                            data={data.starredViews}
                            pageSize={20}
                          />
                        ) : (
                          <div className="no-stars-watermark">
                            You haven’t liked any reports yet. Check out{' '}
                            <Link to={reportGallery()}>Fully Connected</Link>{' '}
                            for curated reports on popular machine learning
                            research topics.
                          </div>
                        ),
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <Egg />
      </PageWithSidebar>
    );
  },
  {id: 'ProfilePage', memo: true}
);

export default ProfilePage;

function getTitle(user: string): string {
  return `${user} - Machine Learning Portfolio in Weights & Biases`;
}
