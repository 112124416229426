import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Dot = styled.div`
  margin-left: 6px;
  background-color: green;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;
