import * as React from 'react';
import {useCallback, useState} from 'react';
import {Checkbox, Divider, Header, Input, Segment} from 'semantic-ui-react';

import {EntityData} from '../graphql/teamQuery';
import {useUpdateEntityAdminMutation} from '../generated/graphql';
import makeComp from '../util/profiler';

interface RateLimitsProps {
  entity: EntityData;
}

const RateLimits = makeComp(
  (props: RateLimitsProps) => {
    const {entity} = props;
    const [updateEntityAdmin] = useUpdateEntityAdminMutation();

    const [filestream, setFilestream] = useState('');
    const [graphql, setGraphql] = useState('');

    const clear = useCallback(() => {
      setFilestream('');
      setGraphql('');
    }, [setFilestream, setGraphql]);

    return (
      <Segment>
        <Header as="h3">Admin</Header>
        <div className="admin-wrapper">
          <Checkbox
            toggle
            label="Paid"
            checked={entity.isPaid || false}
            onChange={(_, {checked}) => {
              updateEntityAdmin({
                variables: {
                  entityName: entity.name,
                  isPaid: checked,
                },
              });
              clear();
            }}
          />
        </div>
        <Divider />
        <Header as="h4">Rate Limits</Header>
        <Header as="h6">Set to 0 for default</Header>
        <div className="admin-wrapper">
          <Input
            size="mini"
            type="number"
            placeholder={entity.rateLimits?.filestream}
            value={filestream}
            onChange={(_, {value}) => setFilestream(value)}
            label={{
              content: 'Filestream',
              style: {width: '90px'},
            }}
            action={{
              content: 'Save',
              size: 'mini',
              disabled: filestream === '',
              onClick: () => {
                updateEntityAdmin({
                  variables: {
                    entityName: entity.name,
                    rateLimits: {filestream: Number(filestream)},
                  },
                });
                clear();
              },
            }}
          />
        </div>
        <div className="admin-wrapper">
          <Input
            size="mini"
            type="number"
            placeholder={entity.rateLimits?.graphql}
            value={graphql}
            onChange={(_, {value}) => setGraphql(value)}
            label={{
              content: 'GraphQL',
              style: {width: '90px'},
            }}
            action={{
              content: 'Save',
              size: 'mini',
              disabled: graphql === '',
              onClick: () => {
                updateEntityAdmin({
                  variables: {
                    entityName: entity.name,
                    rateLimits: {graphql: Number(graphql)},
                  },
                });
                clear();
              },
            }}
          />
        </div>
      </Segment>
    );
  },
  {id: 'RateLimits'}
);

export default RateLimits;
