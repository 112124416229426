import * as React from 'react';
import {useState, useMemo, RefObject} from 'react';

import {
  downloadPDF,
  downloadSVG,
  downloadPNG,
  exportName,
} from '../util/panelExport';
import {Modal, Button, Input} from 'semantic-ui-react';
import SliderInput from './elements/SliderInput';
import makeComp from '../util/profiler';

import {PanelExportRef} from './PanelImageExportModal';
import {PanelType} from '../util/panels';

interface ImageExportModalProps {
  type: 'svg' | 'png';
  onClose: () => void;
  panelDOMRef: RefObject<HTMLDivElement | null>;
  panelExportRef: React.MutableRefObject<PanelExportRef | undefined>;
  viewType: PanelType;
  open?: boolean;
}

export const ImageExportModal: React.FunctionComponent<ImageExportModalProps> =
  makeComp(
    props => {
      const [width, setWidth] = useState<number>(
        Math.min(600, window.innerWidth - 50)
      );
      const [height, setHeight] = useState<number>(300);
      const defaultName = useMemo(exportName, []);
      const [name, setName] = useState<string>();

      return (
        <Modal open={props.open} onClose={props.onClose}>
          <Modal.Header>Export {props.type.toUpperCase()}</Modal.Header>
          <Modal.Content>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.5em',
              }}>
              Name:
              <Input
                style={{marginLeft: 5}}
                size="mini"
                placeholder={defaultName}
                onChange={(e, d) => setName(d.value)}
                value={name}></Input>
              <div style={{marginRight: 10}}></div>
              Width:
              <SliderInput
                step={1}
                min={20}
                minLabel={'20'}
                max={1600}
                maxLabel={'1600'}
                value={width}
                onChange={v => {
                  setWidth(v);
                }}
                sliderInPopup
                hasInput
              />
              <div style={{marginRight: 10}}> </div>
              Height:
              <SliderInput
                sliderInPopup
                step={1}
                min={20}
                minLabel={'20'}
                max={800}
                maxLabel={'800'}
                value={height}
                onChange={v => setHeight(v)}
                hasInput
              />
              <div style={{marginRight: 10}}></div>
            </div>
            <div style={{overflow: 'scroll'}}>
              <div
                key={width + ',' + height}
                style={{width, height, margin: 'auto'}}>
                {props.children}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            {props.type === 'svg' ? (
              <Button
                primary
                title="Hold shift for a print dialog"
                onClick={e => {
                  window.analytics.track('Export panel', {
                    viewType: props.viewType,
                    downloadType: 'svg',
                  });
                  e.shiftKey
                    ? props.panelExportRef.current != null
                      ? props.panelExportRef.current.onDownloadPDF(
                          props.panelDOMRef.current!,
                          name ?? defaultName
                        )
                      : downloadPDF(
                          props.panelDOMRef.current!,
                          name ?? defaultName
                        )
                    : props.panelExportRef.current != null
                    ? props.panelExportRef.current.onDownloadSVG(
                        props.panelDOMRef.current!,
                        name ?? defaultName
                      )
                    : downloadSVG(
                        props.panelDOMRef.current!,
                        name ?? defaultName
                      );
                  props.onClose();
                }}>
                Download SVG
              </Button>
            ) : (
              <Button
                primary
                onClick={() => {
                  window.analytics.track('Export panel', {
                    viewType: props.viewType,
                    downloadType: 'png',
                  });
                  props.panelExportRef.current != null
                    ? props.panelExportRef.current.onDownloadPNG(
                        props.panelDOMRef.current!,
                        name ?? defaultName
                      )
                    : downloadPNG(
                        props.panelDOMRef.current!,
                        name ?? defaultName
                      );
                  props.onClose();
                }}>
                Download PNG
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      );
    },
    {id: 'ImageExportModal'}
  );
