import _ from 'lodash';
import * as queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import NoMatch from '../components/NoMatch';
import {ReportDiscussionContextProvider} from '../components/ReportDiscussionContext';
import ReportErrorBoundary from '../components/ReportErrorBoundary';
import ReportPageHeaderEdit from '../components/ReportPageHeaderEdit';
import ReportPageHeaderEditSticky from '../components/ReportPageHeaderEditSticky';
import ReportTipsSidebar from '../components/ReportTipsSidebar';
import WBSlateReduxBridge from '../components/Slate/WBSlateReduxBridge';
import Loader from '../components/WandbLoader';
import '../css/ReportPage.less';
import {
  ReportPageQueryData,
  useReportPageQuery,
} from '../state/graphql/reportPageQuery';
import {useDispatch, usePropsSelector, useSelector} from '../state/hooks';
import {PanelBankContextProvider} from '../state/panelbank/context';
import * as PollingActions from '../state/polling/actions';
import {useReport} from '../state/reports/hooks';
import * as ReportSelectors from '../state/reports/selectors';
import * as ReportTypes from '../state/reports/types';
import {ReportViewRef} from '../state/reports/types';
import {RunQueryContext} from '../state/runs/context';
import * as ViewerHooks from '../state/viewer/hooks';
import * as ViewActions from '../state/views/actions';
import {View} from '../state/views/types';
import {isOwnView} from '../state/views/util';
import {setDocumentTitle} from '../util/document';
import makeComp from '../util/profiler';
import * as Url from '../util/url';
import {decodeURIComponentSafe} from '../util/url';
import * as Urls from '../util/urls';
import * as S from './ReportPage.styles';

interface ReportPageEditValidatorProps {
  match: {
    params: {
      entityName: string;
      projectName?: string;
      reportNameAndID: string;
    };
  };
  history: any;
}

const ReportPageEditValidator: React.FC<ReportPageEditValidatorProps> =
  makeComp(
    props => {
      const {entityName, reportNameAndID} = props.match.params;
      // HAX: the project name URI we get from react-router might be encoded.
      // Decode this URI component before using it.
      // If this happens again on another URI component (e.g. entity name), we
      // should do more general fix at the react-router layer.
      const projectName =
        props.match.params.projectName != null
          ? decodeURIComponentSafe(props.match.params.projectName)
          : null;
      let reportID = '';
      try {
        reportID = Url.parseNameAndID(reportNameAndID).id;
      } catch {
        return (
          <Redirect
            to={
              projectName != null
                ? Urls.reportList({entityName, projectName}) + '#404'
                : Urls.reportGallery()
            }
          />
        );
      }
      return (
        <ReportErrorBoundary params={props.match.params} editPage>
          {projectName != null ? (
            <ReportPageEdit
              entityName={entityName}
              projectName={projectName}
              reportID={reportID}
              history={props.history}
            />
          ) : (
            <GalleryPostEdit
              entityName={entityName}
              reportID={reportID}
              history={props.history}
            />
          )}
        </ReportErrorBoundary>
      );
    },
    {id: 'ReportPageEditValidator'}
  );

interface ReportPageEditProps {
  entityName: string;
  projectName: string;
  reportID: string;
  history: any;
}

const ReportPageEdit: React.FC<ReportPageEditProps> = makeComp(
  props => {
    const dispatch = useDispatch();
    useEffect(() => {
      // Use track to send a page event so that FullStory can trigger a message
      // for this page.  (FullStory doesn't use actual page events)
      //
      // This is used to trigger the FullStory help message for the new Report
      // page. We can get rid of this after a month or so.
      window.analytics.track('Page: Report');

      // Don't poll on the report page for now
      dispatch(PollingActions.setBasePollInterval(0));
    });

    const reportPageQuery = useReportPageQuery({
      entityName: props.entityName,
      projectName: props.projectName,
    });

    const reportCID = useReport(props.reportID);
    const reportViewID = usePropsSelector(
      ReportSelectors.makeReportViewIDSelector,
      reportCID
    );

    // Always enable autosave in edit mode. If this is not our own report, we'll
    // redirect to view mode below.
    useEffect(() => {
      if (!reportViewID.loading) {
        dispatch(ViewActions.setAutosave(reportViewID.viewID, true));
      }
    }, [reportViewID.loading, reportViewID.viewID, dispatch]);

    if (reportPageQuery.loading || reportViewID.loading) {
      return <Loader />;
    }

    const {project} = reportPageQuery;
    if (!project) {
      return <NoMatch />;
    }

    return (
      <PanelBankContextProvider>
        <ReportDiscussionContextProvider
          reportViewRef={reportViewID.viewID}
          reportServerID={props.reportID}>
          <ReportPageEditReady
            entityName={props.entityName}
            projectName={props.projectName}
            viewRef={reportViewID.viewID}
            viewId={props.reportID}
            project={project}
            history={props.history}
          />
        </ReportDiscussionContextProvider>
      </PanelBankContextProvider>
    );
  },
  {id: 'ReportPageEdit'}
);

type GalleryPostEditProps = {
  entityName: string;
  reportID: string;
  history: any;
};

const GalleryPostEdit: React.FC<GalleryPostEditProps> = makeComp(
  props => {
    const dispatch = useDispatch();
    useEffect(() => {
      // Use track to send a page event so that FullStory can trigger a message
      // for this page.  (FullStory doesn't use actual page events)
      //
      // This is used to trigger the FullStory help message for the new Report
      // page. We can get rid of this after a month or so.
      window.analytics.track('Page: Report');

      // Don't poll on the report page for now
      dispatch(PollingActions.setBasePollInterval(0));
    });

    const reportCID = useReport(props.reportID);
    const reportViewID = usePropsSelector(
      ReportSelectors.makeReportViewIDSelector,
      reportCID
    );

    // Always enable autosave in edit mode. If this is not our own report, we'll
    // redirect to view mode below.
    useEffect(() => {
      if (!reportViewID.loading) {
        dispatch(ViewActions.setAutosave(reportViewID.viewID, true));
      }
    }, [reportViewID.loading, reportViewID.viewID, dispatch]);

    if (reportViewID.loading) {
      return <Loader />;
    }

    return (
      <PanelBankContextProvider>
        <ReportDiscussionContextProvider
          reportViewRef={reportViewID.viewID}
          reportServerID={props.reportID}>
          <ReportPageEditReady
            entityName={props.entityName}
            projectName=""
            viewRef={reportViewID.viewID}
            viewId={props.reportID}
            history={props.history}
          />
        </ReportDiscussionContextProvider>
      </PanelBankContextProvider>
    );
  },
  {id: 'GalleryPostEdit'}
);

interface ReportPageEditReadyProps {
  entityName: string;
  projectName: string;
  project?: ReportPageQueryData['project'];
  viewRef: ReportViewRef;
  viewId: string;
  history: any;
}

const ReportPageEditReady: React.FC<ReportPageEditReadyProps> = makeComp(
  props => {
    const {entityName, projectName, viewRef, project} = props;
    const viewer = ViewerHooks.useViewer();

    // PERFORMANCE
    // Need to do a deep comparison on the relevant parts of the view, otherwise
    // we end up rerendering the whole report after every save event, because the
    // view updates the updatedAt timestamp, which changes the reference to some
    // of these objects.
    const view = useSelector(state => {
      const {
        partRef,
        user,
        entityName: viewEntityName,
        project: viewProject,
        id,
        displayName,
        type,
        saving,
      } = state.views.views[props.viewRef.id];

      // TODO(adrnswanberg): This is a crazy workaround to deal with the fact that profile photos
      // are signed URLs and thus unstable. Without this deletion, this component re-renders on
      // every save. Remove this when photo URLs are public or signed URLs are stable.
      if (user.photoUrl != null) {
        delete user.photoUrl;
      }

      return {
        partRef,
        user,
        entityName: viewEntityName,
        project: viewProject,
        id,
        displayName,
        type,
        saving,
      };
    }, _.isEqual);

    // If this isn't our report, redirect to view mode
    useEffect(() => {
      if (view.id == null) {
        throw new Error('invalid state');
      }
      if (
        view.type === ReportTypes.REPORT_VIEW_TYPE ||
        !isOwnView(viewer, view as unknown as View)
      ) {
        props.history.replace(
          view.project != null
            ? Urls.reportView({
                entityName: view.project.entityName,
                projectName: view.project.name,
                reportID: view.id,
              })
            : (Url.isOnGalleryDiscussionEdit()
                ? Urls.galleryDiscussionView
                : Urls.galleryPostView)({
                entityName: view.entityName,
                reportID: view.id,
              })
        );
      }
    }, [
      props.history,
      view,
      view.id,
      view.entityName,
      view.project,
      view.user.username,
      view.type,
      viewer,
    ]);

    // Make sure url tracks report name
    useEffect(() => {
      if (view.id == null) {
        throw new Error('invalid state');
      }
      setDocumentTitle(
        `${view.displayName}${
          view.project != null ? ` | ${view.project.name}` : ``
        }`
      );
      if (!view.saving) {
        props.history.replace(
          view.project != null
            ? Urls.reportEdit(
                {
                  entityName: view.project.entityName,
                  projectName: view.project.name,
                  reportID: view.id,
                  reportName: view.displayName,
                },
                window.location.search
              )
            : (Url.isOnGalleryDiscussionEdit()
                ? Urls.galleryDiscussionEdit
                : Urls.galleryPostEdit)({
                entityName: view.entityName,
                reportID: view.id,
                reportName: view.displayName,
              })
        );
      }
    }, [
      props.history,
      view.displayName,
      view.id,
      view.entityName,
      view.project,
      view.saving,
    ]);

    const reportWidth = useSelector(ReportSelectors.getReportWidth(viewRef));

    const runQueryContextValue = React.useMemo(
      () => ({
        entityName,
        projectName,
        report: {id: props.viewId},
      }),
      [entityName, projectName, props.viewId]
    );

    const qs = queryString.parse(window.location.search);
    const [tipsSidebarOpen, setTipsSidebarOpen] = useState(
      qs.firstReport != null
    );

    return (
      <div className={`report-page page-tabs report-width-${reportWidth}`}>
        <div>
          <ReportPageHeaderEditSticky
            viewRef={viewRef}
            project={project}
            tipsSidebarOpen={tipsSidebarOpen}
            setTipsSidebarOpen={setTipsSidebarOpen}
          />
          <S.ReportPageContainer>
            <ReportTipsSidebar
              open={tipsSidebarOpen}
              setOpen={setTipsSidebarOpen}
            />
            <S.ReportPageInnerContainer>
              <ReportPageHeaderEdit viewRef={viewRef} project={project} />
              <RunQueryContext.Provider value={runQueryContextValue}>
                <WBSlateReduxBridge
                  entityName={entityName}
                  projectName={projectName}
                  viewId={props.viewId}
                  readOnly={false}
                  viewRef={props.viewRef}
                />
              </RunQueryContext.Provider>
            </S.ReportPageInnerContainer>
          </S.ReportPageContainer>
        </div>
      </div>
    );
  },
  {id: 'ReportPageEditReady'}
);

export default ReportPageEditValidator;
