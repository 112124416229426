import config from '../config';
import {useViewer} from '../state/viewer/hooks';
type FeatureName =
  | 'create-benchmark'
  | 'test_feature_flag'
  | 'vega_panel'
  | 'run_queues';

export interface FeatureFlag {
  featureName: FeatureName; // the name of the feature, e.g. 'vega_plot'
  featureKey: string | string[]; // the unique id for the flag setting, typically entity name or project name
}

type FeatureFlagData = {
  [featureName in FeatureName]: {[featureKey: string]: 0 | 1};
};

export const isFeatureFlagEnabled = ({
  featureName,
  featureKey,
}: FeatureFlag): boolean => {
  // All features are enabled for CI
  if (config.ENVIRONMENT_NAME === 'integration' || config.CI) {
    return true;
  }

  // Enable benchmarks and vega panels in local
  if (
    config.ENVIRONMENT_NAME === 'local' &&
    ['create-benchmark', 'vega-panel'].indexOf(featureName) > -1
  ) {
    return true;
  }

  // Enable benchmarks for lyft
  if (
    config.ENVIRONMENT_NAME === 'wandb-lyft' &&
    featureName === 'create-benchmark'
  ) {
    return true;
  }

  const isEnabled =
    allFeatureFlags[featureName][
      Array.isArray(featureKey) ? featureKey.join('__') : featureKey
    ] === 1;
  return isEnabled;
};

const allFeatureFlags: FeatureFlagData = {
  'create-benchmark': {
    carey: 1,
    clwainwright: 1,
    deepform: 1,
    dronedeploy: 1,
    github: 1,
    hamelsmu: 1,
    jeffr: 1,
    lavanya: 1,
    lyft: 1,
    nbaryd: 1,
    pde: 1,
    safelife: 1,
    shawn: 1,
    stacey: 1,
    thinkingmachines: 1,
    twitter: 1,
    tri: 1,
    wandb: 1,
  },
  test_feature_flag: {
    altay: 1,
    tom: 1,
  },
  vega_panel: {
    Angle: 1,
    adrianbg: 1,
    adrnswanberg: 1,
    altay: 1,
    angle: 1,
    axel: 1,
    blueriver: 1,
    carey: 1,
    csordas: 1,
    hamelsmu: 1,
    jeffr: 1,
    l2k2: 1,
    lavanya: 1,
    lavanyashukla: 1,
    lshukla: 1,
    nbaryd: 1,
    sculpturearts: 1,
    shawn: 1,
    stacey: 1,
    syllogismos: 1,
    tom: 1,
    tri: 1,
    wandb: 1,
    // Added 4/11/20
    'louis-wb': 1,
    // Added 6/10/20 Kyle Goyette
    kylegoyette: 1,
  },
  run_queues: {
    adrnswanberg: 1,
    shawn: 1,
    axel: 1,
    jeffr: 1,
    tom: 1,
    annirudh: 1,
    carey: 1,
    johnq: 1,
  },
  // The confusion_matrix flag also enabled DataFrames.
  // Both panels are now deprecated (Dec 2020).
  // Legacy panels are kinda-sorta maintained for these folks.
  // (Images are not appearing in the panel. No one has complained so far.)
  // confusion_matrix: {
  //   blueriver: 1,
  //   wandb: 1,
  //   wadhwani: 1,
  //   'simon-gurcke': 1,
  //   genesiscare: 1,
  // },
};

const BIO_FEATURE_NAMES = [
  // Enable the hoverable weave panel2 dev popup
  'weave-devpopup' as const,
  // Enable weave plot planel
  'weave-plot' as const,
  // Enable run queues
  'instant replay' as const,
  // Enable super secret easter egg
  'unicorn-plot' as const,
];
type BioFeatureName = typeof BIO_FEATURE_NAMES[number];

export function useBioFeatureFlagEnabled(featureName: BioFeatureName): boolean {
  const viewer = useViewer();
  // return true for CI
  if (
    featureName !== 'weave-devpopup' &&
    (config.ENVIRONMENT_NAME === 'integration' || config.CI)
  ) {
    return true;
  }
  return viewer?.userInfo?.bio?.toLowerCase()?.includes(featureName) ?? false;
}

export function useRunQueueEnabled(): boolean {
  return useBioFeatureFlagEnabled('instant replay');
}

export function useUnicornFeatureFlagEnabled(): boolean {
  return useBioFeatureFlagEnabled('unicorn-plot');
}
