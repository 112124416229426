export function userFlags(user: any) {
  if (user && user.flags) {
    return JSON.parse(user.flags);
  } else {
    return {};
  }
}

export const allAccessOptions: AccessOptions[] = [
  'USER_WRITE',
  'USER_READ',
  'ENTITY_WRITE',
  'ENTITY_READ',
  'PRIVATE',
];

export function availableAccessOptions(user?: any) {
  let opts = ['USER_WRITE', 'USER_READ'];
  if (user && userFlags(user).private_projects) {
    opts = opts.concat(['ENTITY_WRITE', 'ENTITY_READ', 'PRIVATE']);
  }
  return opts;
}

export type AccessOptions =
  | 'USER_WRITE'
  | 'USER_READ'
  | 'ENTITY_WRITE'
  | 'ENTITY_READ'
  | 'PRIVATE';

export function accessToEnglish(access: string) {
  switch (access) {
    case 'USER_WRITE':
      return 'World writable';
    case 'USER_READ':
      return 'World readable';
    case 'ENTITY_WRITE':
      return 'Team writable';
    case 'ENTITY_READ':
      return 'Team readable';
    case 'PRIVATE':
      return 'Private';
    default:
      return;
  }
}

export function accessIsPublic(a: AccessOptions): boolean {
  return ['USER_WRITE', 'USER_READ'].indexOf(a) !== -1;
}

export function accessIsPrivate(a: AccessOptions): boolean {
  return !accessIsPublic(a);
}
