/* Actions for report in edit mode. This is only used for
   reports that are already saved to the server, and belong to the
   current user. */
import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Confirm, Dropdown, Modal} from 'semantic-ui-react';
import ReportExportModal from './ReportExportModal';
import {useSelector} from '../state/hooks';
import {useViewRefAction} from '../state/views/hooks';
import * as ViewActions from '../state/views/actions';
import {BlockNavigation} from './BlockNavigation';
import {ReportViewRef} from '../state/reports/types';
import EditableImage from './EditableImage';
import SaveDraftTrigger from './SaveDraftTrigger';
import * as ViewHooks from '../state/views/hooks';
import * as ViewThunks from '../state/views/thunks';
import {AccessOptions} from '../util/permissions';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as ReportTypes from '../state/reports/types';
import CloneReportTrigger from './CloneReportTrigger';
import ReactTimeago from 'react-timeago';
import makeComp from '../util/profiler';
import {PanelBankUpdaterContext} from '../state/panelbank/context';
import * as Urls from '../util/urls';
import {isOnGalleryDiscussionEdit, isOnGalleryPostEdit} from '../util/url';

interface ReportPageActionsProps {
  entityName: string;
  projectName?: string;
  viewRef: ReportViewRef;
  projectAccess: AccessOptions;
  isTeam: boolean;
}

const ReportPageActions = makeComp(
  (props: ReportPageActionsProps) => {
    const {entityName, projectName, viewRef} = props;

    const view = useSelector(state => state.views.views[viewRef.id]);
    const history = useHistory();
    const reportID = view.id;
    if (reportID == null) {
      throw new Error('invalid state');
    }

    const navToProject = React.useMemo(
      () =>
        projectName != null
          ? () => {
              history.push(`/${entityName}/${projectName}`);
            }
          : null,
      [entityName, projectName, history]
    );
    const navToGallery = React.useCallback(() => {
      history.push(Urls.reportGallery());
    }, [history]);
    const del = ViewHooks.useViewRefThunk(viewRef, ViewThunks.del);
    const setUrl = useViewRefAction(viewRef, ViewActions.setPreviewUrl);

    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);

    const [reportExportModalOpen, setReportExportModalOpen] = useState(false);
    const [reportExporting, setReportExporting] = useState(false);
    const {setDisableWaitToLoad} = React.useContext(PanelBankUpdaterContext);

    return (
      <>
        <BlockNavigation
          when={view.saving}
          message="Your draft is still auto-saving. Leave anyway?"
        />
        <Confirm
          open={deleteConfirmModalOpen}
          onCancel={() => setDeleteConfirmModalOpen(false)}
          onConfirm={() => {
            del(navToProject ?? navToGallery);
          }}
          header="Discard draft"
          content="Are you sure you want to discard this draft? This cannot be undone!"
          confirmButton="Yes, discard this draft"
        />
        <Modal
          onClose={() => setImageModalOpen(false)}
          open={imageModalOpen}
          size="small">
          <Modal.Header>Set Preview Image</Modal.Header>
          <Modal.Content>
            <EditableImage
              label={false}
              displaySize={400}
              photoUrl={view.previewUrl || '/logo.png'}
              viewID={reportID}
              save={newVal => {
                setUrl(newVal);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setImageModalOpen(false)}>Save</Button>
          </Modal.Actions>
        </Modal>
        <span
          className="report-header-edit-sticky__report-updated-text "
          style={{color: ''}}>
          {view.saving ? (
            <>Draft autosaving...</>
          ) : (
            <>
              Draft autosaved{' '}
              <ReactTimeago
                minPeriod={10}
                formatter={(value, unit, suffix, epochSeconds, nextFormatter) =>
                  unit === 'second'
                    ? 'just now'
                    : nextFormatter!(value, unit, suffix, epochSeconds)
                }
                date={view.updatedAt + 'Z'}
              />
            </>
          )}
        </span>
        <Dropdown
          className="report-header-edit-sticky__overflow dropdown-menu-icon-button"
          floating
          trigger={
            <Button
              loading={reportExporting}
              size="tiny"
              icon="ellipsis vertical"
            />
          }
          direction="left">
          <Dropdown.Menu>
            {projectName != null && (
              <Dropdown.Item
                onClick={() => {
                  setReportExportModalOpen(true);
                  setDisableWaitToLoad(true);
                }}>
                <LegacyWBIcon name="download" />
                Download report
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => setImageModalOpen(true)}>
              <LegacyWBIcon name="file-image" />
              Set preview image
            </Dropdown.Item>
            {projectName != null && (
              <CloneReportTrigger viewRef={viewRef} className="item">
                <LegacyWBIcon name="copy" />
                Clone this report
              </CloneReportTrigger>
            )}
            <Dropdown.Item onClick={() => setDeleteConfirmModalOpen(true)}>
              <LegacyWBIcon name="delete" />
              Discard this draft
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {view.type === ReportTypes.REPORT_DRAFT_VIEW_TYPE && view.id != null && (
          <div style={view.saving ? {cursor: 'not-allowed'} : undefined}>
            <SaveDraftTrigger viewRef={viewRef}>
              <Button
                data-test="save-as-report"
                style={view.saving ? {pointerEvents: 'none'} : undefined}
                primary
                size="tiny"
                className="report-header-edit-sticky__save-button">
                {isOnGalleryPostEdit() ? (
                  <>
                    Publish<span className="hide-in-mobile"> post</span>
                  </>
                ) : isOnGalleryDiscussionEdit() ? (
                  <>
                    Publish<span className="hide-in-mobile"> discussion</span>
                  </>
                ) : view.parentId == null ? (
                  <>
                    Save<span className="hide-in-mobile"> as report</span>
                  </>
                ) : (
                  <>
                    Save<span className="hide-in-mobile"> to report</span>
                  </>
                )}
              </Button>
            </SaveDraftTrigger>
          </div>
        )}
        {projectName != null && (
          <ReportExportModal
            onLoadingChange={setReportExporting}
            entityName={entityName}
            projectName={projectName}
            reportViewRef={viewRef}
            open={reportExportModalOpen}
            onClose={() => setReportExportModalOpen(false)}
          />
        )}
      </>
    );
  },
  {id: 'ReportPageActions'}
);

export default ReportPageActions;
