import * as S from './horizontal-rules.styles';

import React from 'react';
import {Element, Node} from 'slate';
import {ReactEditor, RenderElementProps, useSelected} from 'slate-react';
import {BlockWrapper} from './drag-drop';

export interface HorizontalRule extends Element {
  type: 'horizontal-rule';
}

export const isHorizontalRule = (node: Node): node is HorizontalRule =>
  node.type === 'horizontal-rule';

export const HorizontalRuleElement: React.FC<
  RenderElementProps & {
    element: HorizontalRule;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();

  return (
    <BlockWrapper attributes={attributes} element={element} noDragging>
      <S.HorizontalRule selected={selected} contentEditable={false} />
      {children}
    </BlockWrapper>
  );
};

export const withHorizontalRules = <T extends ReactEditor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isHorizontalRule(element) ? true : isVoid(element);
  };

  return editor;
};
