import * as S from './ColabButton.styles';

import React, {FC} from 'react';
import makeComp from '../util/profiler';
import colabIcon from '../assets/icon-colab.png';

const ColabButton: FC = makeComp(
  () => (
    <S.ColabButton>
      <img src={colabIcon} alt="colab" />
      <div>Open in Colab</div>
    </S.ColabButton>
  ),
  {id: 'ColabButton', memo: true}
);

export default ColabButton;
