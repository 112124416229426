/* Puts a warning banner at the top of charts */
import '../css/PlotWarning.less';

import React from 'react';
import {Popup} from 'semantic-ui-react';
import makeComp from '../util/profiler';

interface PlotWarningProps {
  message: string;
  helpText?: string;
}

const PlotWarning: React.FC<PlotWarningProps> = makeComp(
  props => {
    const span = <span className="plot-warning">{props.message}</span>;

    if (props.helpText == null) {
      return span;
    }
    return (
      <Popup
        inverted
        size="mini"
        position="bottom center"
        content={props.helpText}
        flowing={true}
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        trigger={span}
      />
    );
  },
  {id: 'PlotWarning'}
);

export default PlotWarning;
