import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: ${globals.gray800};
`;

// TODO(np): move me
export const DatePicker = styled.div`
  display: inline-block;
  margin-top: 4px;
  margin-left: 20px;

  .filter-list__value {
    cursor: default;
    background-color: white !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-radius: 0.25em !important;
    min-height: 40px !important;
  }

  .filter-list__value input {
    cursor: pointer;
    background-color: white !important;
  }

  input {
    color: ${globals.gray700};
    width: 100px;
  }

  .rdtPicker {
    top: 44px;
    left: 0;
  }
`;

export const DatePickerGroup = styled.div`
  display: inline-block;
  margin-left: 4px;

  label {
    display: inline-block;
    text-align: right;
  }
`;

// TODO(np): move me
export const UserFilter = styled.div`
  display: inline-block;
  margin-top: 4px;
  min-width: 166px;

  i.wbic-ic-username {
    position: absolute;
    left: 0px;
    margin: 7px;
  }

  div.ui.multiple.search.selection.dropdown {
    padding-left: 30px;
  }
`;

export const FilterSection = styled.section`
  display: flex;
  position: sticky;
  top: 0;
  background-color: ${globals.gray50};
  padding: 12px 40px;
  z-index: 15;

  box-shadow: ${(props: {scrolled: boolean}) =>
    props.scrolled ? '0 4px 12px rgba(0, 0, 0, 0.08)' : 'none'};

  div.cg-loader {
    margin: 7px !important;
  }
`;

export const TopSection = styled.section`
  padding: 20px 40px 0px 40px;
  z-index: 15;
`;

export const HeaderRow = styled.section`
  display: flex;
  align-items: center;
`;

export const BottomSection = styled.section`
  height: 80px;
  background-color: ${globals.gray900};
`;

export const Section = styled.section`
  padding: 20px 40px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ReadoutPanel = styled.div`
  position: relative;
  padding: 8px;
  width: 160px;
  height: 160px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 2px;
  flex-grow: 0;
  margin: 10px;
`;

export const ReadoutTitle = styled.div`
  margin: auto 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const ReadoutValue = styled.div`
  position: relative;
  margin: auto 8px;
  font-size: 26px;
  line-height: 60px;
  text-align: center;
  z-index: 10;
`;

export const MiniGraphWrapper = styled.div`
  filter: grayscale(100%) opacity(30%);
  position: absolute;
  bottom: 8px;
  height: 80px;
  width: 144px;
`;

export const TablePanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 45%;
  height: 250px;
  flex-grow: 0;
  margin: 10px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 8px;
  overflow: hidden;

  td {
    color: ${globals.gray700};
  }

  .placeholder-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${globals.gray900};
  }

  .loader {
    margin: auto !important;
  }

  .placeholder-message {
    line-height: 24px;
    font-size: 16px;
    margin: auto;
    filter: grayscale(100%) opacity(50%);
    text-align: center;
  }

  table {
    border: 0px !important;
  }

  td {
    padding: 8px 0px !important;
  }
`;

export const PanelTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${globals.gray900};
`;

export const MainHeading = styled.div`
  font-size: 35px;
  line-height: 40px;
  margin-right: 16px;
`;

export const Hint = styled.div`
  font-style: italic;
`;

export const Header = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
`;

export const Subheader = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
`;

export const Subtitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-top: 5px;
`;
