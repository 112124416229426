import * as _ from 'lodash';

import * as Types from './types';
import * as Run from '../../util/runs';
import * as Update from '../../util/update';

export function applyMetaUpdate(
  meta: Types.NormalizedMeta,
  keys: string[] | undefined,
  data: Run.KeyVal,
  updated: string
): Types.NormalizedMeta {
  if (keys == null) {
    return meta;
  }
  const metaItemIndex = findMetaItemIndex(meta, keys);
  if (metaItemIndex === -1) {
    return [
      ...meta,
      {
        keys,
        updated,
        data,
      },
    ];
  }
  const metaItem = meta[metaItemIndex];
  if (updated > metaItem.updated) {
    return Update.updateArrayIndex(meta, metaItemIndex, {
      ...metaItem,
      updated,
      data,
    });
  }
  return meta;
}

// use a shared empty object so that its always ref-equal
const empty = {};
export function getMetaResult(
  meta: Types.NormalizedMeta,
  keys: string[] | undefined
): Run.KeyVal {
  if (keys == null) {
    return empty;
  }
  const metaItem = meta[findMetaItemIndex(meta, keys)];
  if (metaItem == null) {
    return empty;
  }
  return metaItem.data;
}

function findMetaItemIndex(meta: Types.NormalizedMeta, keys: string[]) {
  return _.findIndex(meta, mi => _.isEqual(mi.keys, keys));
}
