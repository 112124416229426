import '../css/PanelRunComparer.less';

import React, {useMemo} from 'react';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import _ from 'lodash';
import classNames from 'classnames';
import {Key} from '../util/runs';

import {toRunsDataQuery, RunWithRunsetInfo} from '../containers/RunsDataLoader';
import {RunKeyInfo} from '../types/run';
import {BasicValue, WBValue} from '../util/runs';
import {displayValue, conditionalRunOrGroupLink} from '../util/runhelpers';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {displayTimeDifference} from './elements/TimeDisplay';
import WandbLoader from './WandbLoader';
import PanelError from './elements/PanelError';
import {Checkbox} from 'semantic-ui-react';
import {runColor} from '../util/colors';
import makeComp from '../util/profiler';
import {useParts} from '../state/views/hooks';

import {useSampleAndQueryToTable} from './Export';

type TreeItem = number | string | boolean | WBValue | BasicValue[];
type TreeLeaf = Array<TreeItem | null | undefined>;
interface Tree {
  [key: string]: TreeLeaf | Tree;
}

const compile = (keyInfo: RunKeyInfo, runs: RunWithRunsetInfo[]) => {
  const flatTree: Tree = {};
  Object.keys(keyInfo).forEach(key => {
    const colonIndex = key.indexOf(':');
    const keyType = key.substring(0, colonIndex);
    const keyName = key.substring(colonIndex + 1);
    const value: TreeLeaf = [];
    if (keyType === 'config') {
      runs.forEach(run => {
        value.push(run.config[keyName]);
      });
      flatTree['config.' + keyName.replace('.value', '')] = value;
    } else {
      let isHisto = false;
      runs.forEach(run => {
        const runVal = run.summary[keyName];
        value.push(runVal);

        // The majorType for histograms is number and MediaType doesn't include
        // histograms, so this is the only way to tell that this is a histo.
        if (
          runVal != null &&
          ((runVal as WBValue)._type as string) === 'histogram'
        ) {
          isHisto = true;
        }
      });
      if (isHisto) {
        flatTree['histograms.' + keyName] = value;
      } else {
        flatTree['summary.' + keyName] = value;
      }
    }
  });
  return flatTree;
};

const unflattenSingleEntry = (tree: Tree, key: string, value: any) => {
  let firstDeliminatorIndex = -1;
  for (let i = 1; i < key.length - 1; i++) {
    // indexOf . or /, but ignoring dots following by digits
    // and boundary delimiters
    if (
      (key.charAt(i) === '.' && isNaN(parseInt(key.charAt(i + 1), 10))) ||
      key.charAt(i) === '/'
    ) {
      firstDeliminatorIndex = i;
      break;
    }
  }

  if (firstDeliminatorIndex > -1) {
    const parent = key.substring(0, firstDeliminatorIndex);
    if (tree[parent] == null || typeof tree[parent] !== 'object') {
      // override as object if already set as something else because
      // the object is probably more important
      tree[parent] = {};
    }
    unflattenSingleEntry(
      tree[parent] as Tree,
      key.substring(firstDeliminatorIndex + 1),
      value
    );
  } else {
    tree[key] = value;
  }
};

const unflatten = (flatTree: Tree) => {
  const tree = {};
  Object.keys(flatTree).forEach(key => {
    unflattenSingleEntry(tree, key, flatTree[key]);
  });
  return tree as Tree;
};

const isNilArray = (arr: any[]) => {
  return arr.every(val => _.isNil(val) || val === '');
};

const isBoringArray = (arr: any[]) => {
  return arr.every(val => _.isEqual(val, arr[0]));
};

interface CollapsedTree {
  [key: string]: CollapsedTree | true | undefined;
  __collapsed__?: true;
}

const PANEL_TYPE = 'Run Comparer';

interface RunComparerConfig {
  /*
  Most keys will generally be left expanded, so
  collapsedTree tries to be as small as possible by
  only storing which keys are collapsed. This means any key not in
  collapsedTree is considered expanded. Furthermore, any key in the tree
  without the entry __collapsed__: true is considered expanded.
  Leaves can only be collapsed, because otherwise they don't
  need to be in the tree.

  Example:
  {
    parentKey: {
      childKey1: {
        __collapsed__: true
      }
      childKey2: {
        __collapsed__: true
      }
    }
  }

  This represents an expanded parentKey with two collaped children.
  All other children of parentKey are expanded.
  */
  collapsedTree?: CollapsedTree;
  diffOnly?: boolean;
}

type PanelRunComparerProps = Panels.PanelProps<RunComparerConfig>;

function useRunComparerTree(props: PanelRunComparerProps) {
  const {
    loading,
    data: {filtered: runs, keyInfo},
    config: {diffOnly},
  } = props;
  return useMemo(() => {
    if (loading) {
      return {} as Tree;
    }

    const flatTree = compile(keyInfo, runs);

    flatTree['meta.runtime'] = runs.map(run =>
      displayTimeDifference(
        new Date(run.createdAt),
        new Date(run.heartbeatAt),
        'month_round'
      )
    );

    flatTree['meta.notes'] = runs.map(run => run.notes);

    // remove nils
    Object.keys(flatTree).forEach(key => {
      if (isNilArray(flatTree[key] as TreeLeaf)) {
        delete flatTree[key];
      }
    });

    if (diffOnly && runs.length > 1) {
      // remove all entries where values are all equal
      Object.keys(flatTree).forEach(key => {
        if (isBoringArray(flatTree[key] as TreeLeaf)) {
          delete flatTree[key];
        }
      });
    }

    return unflatten(flatTree);
  }, [loading, runs, keyInfo, diffOnly]);
}

const PanelRunComparer: React.FC<PanelRunComparerProps> = makeComp(
  props => {
    const runSetParts = useParts(props.runSetRefs);

    const isCollapsed = (path: string[]) =>
      _.get(props.config.collapsedTree, [...path, '__collapsed__']);

    const cleanPath = (collapsedTree: CollapsedTree, path: string[]) => {
      // If nothing is collapsed under a key, we don't need the key in our tree,
      // so delete it.
      // This keeps the tree as small as possible.
      if (path.length > 0 && typeof collapsedTree[path[0]] === 'object') {
        cleanPath(collapsedTree[path[0]] as CollapsedTree, [...path].splice(1));
        if (Object.keys(collapsedTree[path[0]] as CollapsedTree).length === 0) {
          delete collapsedTree[path[0]];
        }
      }
    };

    const toggleCollapsed = (path: string[]) => {
      let newCollapsedTree: CollapsedTree;
      if (props.config.collapsedTree) {
        newCollapsedTree = _.cloneDeep(props.config.collapsedTree);
      } else {
        newCollapsedTree = {};
      }
      if (isCollapsed(path)) {
        _.unset(newCollapsedTree, [...path, '__collapsed__']);
        cleanPath(newCollapsedTree, path);
      } else {
        _.set(newCollapsedTree, [...path, '__collapsed__'], true);
      }
      props.updateConfig({collapsedTree: newCollapsedTree});
    };

    const cellTitle = (item: TreeItem) => {
      if (typeof item === 'object' && (item as any)._type === 'histogram') {
        const bins = (item as any).bins;
        return `histogram (${displayValue(bins[0])} to ${displayValue(
          bins[bins.length - 1]
        )})`;
      }

      return JSON.stringify(item);
    };

    const renderSlave = (keyName: string, value: TreeLeaf, path: string[]) => {
      const depth = path.length;

      const hideRow = value.every(item => {
        if (_.isNil(item)) {
          return true;
        }
        if (_.isObject(item) && '_type' in item) {
          const hiddenTypes = ['images/separated', 'images', 'graph'];
          return hiddenTypes.indexOf(item._type) !== -1;
        }
        return false;
      });

      if (hideRow) {
        return null;
      }

      return (
        <div
          className={classNames('slave', {
            diff: !isBoringArray(value),
          })}
          key={keyName}>
          <div
            className="header-cell"
            style={{paddingLeft: 26 + 12 * depth}}
            title={keyName}>
            {keyName}
          </div>
          {value.map((item, i) => (
            <div
              className="cell"
              key={i}
              title={item ? cellTitle(item) : undefined}>
              {!_.isNil(item) ? displayValue(item) : '-'}
            </div>
          ))}
        </div>
      );
    };

    const recurse = (
      tree: Tree,
      keyName: string,
      path: string[]
    ): React.ReactNode => {
      const value = tree[keyName];

      if (Array.isArray(value)) {
        return renderSlave(keyName, value, path);
      } else {
        const depth = path.length;
        const childKeys = Object.keys(value);
        childKeys.sort();

        let collapsed = isCollapsed([...path, keyName]);
        // Default histograms sections to closed
        if (path[0] === 'histograms') {
          collapsed = !collapsed;
        }

        return (
          <div className="master-wrapper" key={keyName}>
            <div
              onClick={() => toggleCollapsed([...path, keyName])}
              className={classNames('master', {
                overlord: depth === 0,
                collapsed,
              })}
              style={{
                // for nested sticky headers
                top: depth === 0 ? 40 : 48 + 24 * depth,
                zIndex: 50 - depth,
              }}>
              <div
                className="master-inner"
                style={{paddingLeft: depth === 0 ? 38 : 26 + 12 * depth}}>
                <LegacyWBIcon name="next" />
                {keyName}
                {collapsed && (
                  <span className="collapsed-count">
                    {`(${childKeys.length} collapsed)`}
                  </span>
                )}
              </div>
            </div>
            {!collapsed &&
              childKeys.map(childKey =>
                recurse(value, childKey, [...path, keyName])
              )}
          </div>
        );
      }
    };

    const runComparerRef = React.useRef<HTMLDivElement>(null);

    const fullTree = useRunComparerTree(props);

    if (props.loading) {
      return <WandbLoader />;
    }

    if (props.data.filtered.length === 0) {
      return (
        <PanelError message="Select at least one run to load data into this run comparer." />
      );
    }

    const topLevelKeys = Object.keys(fullTree);
    const desiredOrder = ['meta', 'config', 'summary', 'histograms'];
    topLevelKeys.sort(
      (a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
    );

    return (
      <div className="run-comparer-wrapper">
        <div className="run-comparer" ref={runComparerRef}>
          <div
            className="scroll-snapper-wrapper"
            style={{
              height: runComparerRef.current
                ? runComparerRef.current.clientHeight
                : undefined,
              marginBottom: runComparerRef.current
                ? -runComparerRef.current.clientHeight
                : undefined,
            }}>
            {Array(props.data.filtered.length + 1)
              .fill(0)
              .map((_1, i) => (
                <div key={i} className="scroll-snapper" />
              ))}
          </div>
          <div
            className={classNames('diff-toggle-wrapper', {
              'diff-only': props.config.diffOnly,
            })}>
            {props.data.filtered.length > 1 && (
              <>
                <span
                  onClick={() =>
                    props.updateConfig({diffOnly: !props.config.diffOnly})
                  }>
                  <Checkbox
                    toggle
                    className="diff-only-toggle"
                    checked={props.config.diffOnly}
                  />
                  <span className="diff-only-label">diff only</span>
                </span>
              </>
            )}
          </div>
          <div className="header-slave">
            <div className="filler-cell" />
            {(() => {
              const idToGroupingMap: {[key: string]: Key[] | undefined} = {};
              if (props.pageQuery.runSets) {
                props.pageQuery.runSets.forEach(runSet => {
                  idToGroupingMap[runSet.id] = runSet.grouping;
                });
              }
              return props.data.filtered.map((run, i) => {
                const runSetConfig = runSetParts.find(
                  rs => rs.id === run.runsetInfo.id
                );
                const groupKeys = idToGroupingMap[run.runsetInfo.id];
                const color = groupKeys
                  ? runColor(run, groupKeys, props.customRunColors)
                  : undefined;
                const runSetEntityName =
                  runSetConfig?.project?.entityName ?? props.data.entityName;
                const runSetProjectName =
                  runSetConfig?.project?.name ?? props.data.projectName;
                const runLink = conditionalRunOrGroupLink(
                  run,
                  runSetEntityName,
                  runSetProjectName,
                  color
                );
                return (
                  <div
                    className="cell name-cell"
                    key={'c' + i}
                    title={run.displayName}
                    // color needs to be defined in both parent and child for ellipses to be colored correctly, lol
                    style={{color}}>
                    {props.disableRunLinks ? run.displayName : runLink}
                  </div>
                );
              });
            })()}
          </div>

          {topLevelKeys.length > 0 ? (
            topLevelKeys.map(key => recurse(fullTree, key, []))
          ) : props.config.diffOnly ? (
            <PanelError message="There are no differences between the selected runs." />
          ) : (
            <PanelError message="No keys found. This is probably an error." />
          )}
        </div>
      </div>
    );
  },
  {id: 'PanelRunComparer'}
);

export default PanelRunComparer;

const runComparerTransformQuery = (
  query: Query.Query,
  config: RunComparerConfig
) => {
  const transformed = toRunsDataQuery(
    query,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );
  return transformed;
};

const useTableData = (pageQuery: Query.Query, config: RunComparerConfig) => {
  const query = toRunsDataQuery(
    pageQuery,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );

  return useSampleAndQueryToTable(query, pageQuery, config);
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, RunComparerConfig> = {
  type: PANEL_TYPE,
  Component: PanelRunComparer,
  transformQuery: runComparerTransformQuery,
  noEditMode: true,
  exportable: {
    csv: true,
    api: true,
  },
  useTableData,
  icon: 'panel-run-comparer',
};
