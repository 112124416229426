import '../css/PageWithSidebar.less';

import React from 'react';
import makeComp from '../util/profiler';

interface PageWithSidebarProps {
  className?: string;
}

const PageWithSidebar: React.FC<PageWithSidebarProps> = makeComp(
  ({className, children}) => {
    return (
      <div className={'page-with-sidebar ' + (className ? className : '')}>
        {children}
      </div>
    );
  },
  {id: 'PageWithSidebar', memo: true}
);

export default PageWithSidebar;
