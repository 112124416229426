import '../css/CopyableText.less';

import classNames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import React, {useCallback} from 'react';

import {toast} from '../components/elements/Toast';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface CopyableTextProps {
  className?: string;
  dataTest?: string;
  text: string;
  copyText?: string;
  toastText: string;
  code?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const CopyableText: React.FC<CopyableTextProps> = makeComp(
  ({
    className,
    dataTest,
    text,
    copyText,
    toastText,
    code,
    disabled,
    onClick,
  }) => {
    const copy = useCallback(() => {
      copyToClipboard(copyText ?? text);
      toast(toastText);
    }, [text, copyText, toastText]);

    return (
      <span
        className={classNames('copyable-text', className, {code, disabled})}
        data-test={dataTest}
        onClick={() => {
          if (disabled) {
            return;
          }
          copy();
          onClick?.();
        }}>
        <span className="copyable-text-content">{text}</span>
        <LegacyWBIcon className="copy-icon" name="copy" />
      </span>
    );
  },
  {id: 'CopyableText', memo: true}
);

export default CopyableText;
