import styled from 'styled-components';
import {
  Input as SemanticInput,
  Checkbox as SemanticCheckbox,
} from 'semantic-ui-react';
import {WBIcon} from '@wandb/ui';

export const Label = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const Input = styled(SemanticInput)``;

export const Checkbox = styled(SemanticCheckbox)``;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  padding: 4px 0;
  cursor: pointer;
`;

export const DropdownIcon = styled(WBIcon)`
  font-size: 20px;
  margin-left: 2px;
  margin-top: 2px;
`;
