import * as S from './ReportAdminBar.styles';
import * as globals from '../css/globals.styles';

import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import _ from 'lodash';
import moment from 'moment';

import classNames from 'classnames';

import {Viewer} from '../state/viewer/types';
import * as ReportTypes from '../state/reports/types';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {
  Modal,
  Popup,
  Button,
  Input,
  StrictInputProps,
  StrictCheckboxProps,
} from 'semantic-ui-react';

import {useApolloClient, useSelector} from '../state/hooks';
import {useReportPageQuery} from '../state/graphql/reportPageQuery';

import {fuzzyMatchRegex} from '../util/fuzzyMatch';

import {capitalizeFirst} from '@wandb/cg/browser/utils/string';
import {PROJECT_ACCESSES_QUERY} from '../state/graphql/gallery';
import {useUpdateGalleryMutation} from '../generated/graphql';
import {ReportObjSchema} from '../state/views/report/types';
import {useReportProjects} from '../util/report';
import {accessIsPrivate, AccessOptions} from '../util/permissions';
import makeComp from '../util/profiler';
import {
  GallerySpec,
  useGallerySpec,
  ReportMetadata,
  useReportMetadata,
  Tag,
  ReportIDWithTagIDs,
  LANGUAGES,
  Language,
} from '../util/gallery';
import {ReportAuthor} from '../util/section';
import {isNotNullOrUndefined} from '../util/types';
import {Post} from '../pages/GalleryPage';
import PopupDropdown from './PopupDropdown';

const ADMIN_COLOR = '#884BF2';

type ReportAdminBarProps = {
  viewRef: ReportTypes.ReportViewRef;
  viewer?: Viewer;
  entityName: string;
  projectName: string;
  editAccess?: boolean;
  publishedWork?: boolean;
};

export const ReportAdminBar: React.FC<ReportAdminBarProps> = props => {
  const {entityName, projectName, viewRef} = props;
  const isGalleryPost = !projectName;

  const view = useSelector(state => state.views.views[viewRef.id]);
  if (view.id == null) {
    throw new Error(`view with null ID`);
  }

  const {
    loading: galleryLoading,
    viewID: galleryViewID,
    gallerySpec,
    refetchGallerySpec,
  } = useGallerySpec();

  const {loading: reportMetadataLoading, reportMetadatas} = useReportMetadata([
    view.id,
  ]);

  const reportPageQuery = useReportPageQuery({
    entityName,
    projectName,
  });

  if (
    galleryLoading ||
    galleryViewID == null ||
    reportMetadataLoading ||
    reportMetadatas?.[0] == null ||
    reportPageQuery.loading
  ) {
    return null;
  }

  return (
    <ReportAdminBarLoaded
      {...props}
      galleryViewID={galleryViewID}
      gallerySpec={gallerySpec}
      refetchGallerySpec={refetchGallerySpec}
      reportMetadata={reportMetadatas[0]}
      projectAccess={
        isGalleryPost ? 'USER_READ' : reportPageQuery.project.access
      }
    />
  );
};

type ReportAdminBarLoadedProps = ReportAdminBarProps & {
  galleryViewID: string;
  gallerySpec: GallerySpec;
  refetchGallerySpec: () => Promise<GallerySpec>;
  reportMetadata: ReportMetadata;
  projectAccess: AccessOptions;
};

export const ReportAdminBarLoaded: React.FC<ReportAdminBarLoadedProps> =
  makeComp(
    props => {
      const {
        galleryViewID,
        gallerySpec,
        refetchGallerySpec,
        reportMetadata,
        projectAccess,
      } = props;
      const view = useSelector(state => state.views.views[props.viewRef.id]);
      const viewPart = useSelector(state =>
        view?.partRef != null
          ? state.views.parts[view.partRef.type as ReportObjSchema['type']][
              view.partRef.id
            ]
          : null
      );

      const authors: ReportAuthor[] =
        viewPart?.authors != null && viewPart.authors.length > 0
          ? viewPart.authors
          : [{username: view.user.username, name: view.user.name}];

      const [reportCardDraft, setReportCardDraft] = useState<ReportMetadata>({
        ...reportMetadata,
        authors: reportMetadata.authors ?? authors,
      });
      const tagsByName: Map<string, Tag> = useMemo(() => {
        const combinedTags = [...gallerySpec.categories, ...gallerySpec.tags];
        return new Map(combinedTags.map(t => [t.name, t]));
      }, [gallerySpec]);
      const setTags = useCallback(
        (newTags: string[]) => {
          setReportCardDraft(prev => ({
            ...prev,
            tags: newTags
              .map(t => tagsByName.get(t))
              .filter(isNotNullOrUndefined),
          }));
        },
        [tagsByName]
      );

      const categoryNameSet = useMemo(
        () => new Set(gallerySpec.categories.map(t => t.name)),
        [gallerySpec.categories]
      );
      const tagIsCategory = useCallback(
        (tagName: string) => categoryNameSet.has(tagName),
        [categoryNameSet]
      );

      const [modalOpen, setModalOpen] = React.useState<boolean>(false);
      const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
      const [party, setParty] = React.useState<boolean>(false);
      const [addingToGallery, setAddingToGallery] = React.useState(false);
      const [removingFromGallery, setRemovingFromGallery] =
        React.useState(false);

      const [language, setLanguage] = React.useState<Language>(
        reportMetadata.language
      );
      const languageOptions = useMemo(
        () => LANGUAGES.map(o => ({text: o, onClick: () => setLanguage(o)})),
        []
      );

      const [publishTime, setPublishTime] = React.useState('');
      useEffect(() => {
        if (publishTime === '') {
          setReportCardDraft(prev => ({
            ...prev,
            addedAt: reportMetadata.addedAt,
          }));
          return;
        }
        const m = moment(publishTime, 'M/D/YYYY h:mm a');
        if (!m.isValid()) {
          setReportCardDraft(prev => ({
            ...prev,
            addedAt: reportMetadata.addedAt,
          }));
          return;
        }
        setReportCardDraft(prev => ({...prev, addedAt: m.toJSON()}));
      }, [publishTime, reportMetadata]);

      const PARTY_DELAY = 40;
      const PARTY_DURATION = 2200;
      const throwParty = () =>
        setTimeout(() => {
          setParty(true);
          setTimeout(() => {
            setParty(false);
          }, PARTY_DURATION);
        }, PARTY_DELAY);

      const openModal = () => {
        setModalOpen(true);

        // HAX: No good way to set a style on Semantic's dimmer which wraps the modal.
        setTimeout(() => {
          const dimmer = document.querySelector('.ui.dimmer') as HTMLDivElement;
          dimmer.style.overflow = 'auto';
        });
      };

      const [updateGallery] = useUpdateGalleryMutation();

      const inGallery = gallerySpec.reportIDsWithTagIDs.some(
        ({id}) => view.id === id
      );

      const client = useApolloClient();
      const projectSpecifiers = useReportProjects(props.viewRef);
      const hasPrivateRunSetRef = useRef(false);
      useEffect(() => {
        const projectAccessPromises = projectSpecifiers.map(ps =>
          client.query({
            query: PROJECT_ACCESSES_QUERY,
            variables: {name: ps.projectName, entityName: ps.entityName},
          })
        );
        (async () => {
          try {
            const results = await Promise.all(projectAccessPromises);
            hasPrivateRunSetRef.current = results.some(r => {
              const acc = r?.data?.project?.access;
              if (acc == null) {
                return true;
              }
              return accessIsPrivate(acc);
            });
          } catch (err) {
            console.error('error loading project accesses', err);
            console.log('project specifiers', projectSpecifiers);
          }
        })();
      }, [client, projectSpecifiers]);

      const addToGallery = async () => {
        if (hasPrivateRunSetRef.current) {
          alert(
            'This report contains private run set(s). You must publish it to add it to the gallery.'
          );
          return;
        }

        setAddingToGallery(true);

        const newEntry: ReportIDWithTagIDs = {
          id: reportCardDraft.id,
          tagIDs: reportCardDraft.tags?.map(t => t.id) ?? [],
          authors: reportCardDraft.authors ?? authors,
          addedAt: reportCardDraft.addedAt ?? new Date().toJSON(),
          language,
          pending: reportCardDraft.pending,
          announcement: reportCardDraft.announcement,
        };

        const updatedGallerySpec = await refetchGallerySpec();
        const updatedInGallery = updatedGallerySpec.reportIDsWithTagIDs.some(
          ({id}) => view.id === id
        );

        let newReportList: ReportIDWithTagIDs[];
        if (updatedInGallery) {
          const reportIdx = updatedGallerySpec.reportIDsWithTagIDs.findIndex(
            r => view.id === r.id
          );
          newReportList = _.cloneDeep(updatedGallerySpec.reportIDsWithTagIDs);
          newReportList = [
            ...newReportList.slice(0, reportIdx),
            newEntry,
            ...newReportList.slice(reportIdx + 1).filter(r => view.id !== r.id),
          ];
        } else {
          newReportList = [newEntry, ...updatedGallerySpec.reportIDsWithTagIDs];
        }

        try {
          await updateGallery({
            variables: {
              id: galleryViewID,
              spec: JSON.stringify({
                ...updatedGallerySpec,
                reportIDsWithTagIDs: newReportList,
              }),
            },
          });
          throwParty();
          await refetchGallerySpec();
        } catch {
          alert('Failed to save');
        } finally {
          setAddingToGallery(false);
        }
      };

      const removeFromGallery = async () => {
        setRemovingFromGallery(true);
        const updatedGallerySpec = await refetchGallerySpec();

        const newReportList = _.filter(
          updatedGallerySpec.reportIDsWithTagIDs,
          r => view.id !== r.id
        );

        try {
          await updateGallery({
            variables: {
              id: galleryViewID,
              spec: JSON.stringify({
                ...updatedGallerySpec,
                reportIDsWithTagIDs: newReportList,
              }),
            },
          });
          throwParty();
          refetchGallerySpec();
        } catch {
          alert('Failed to remove');
        } finally {
          setRemovingFromGallery(false);
        }
      };

      const allTagNames: string[] = useMemo(() => {
        const combinedTags = [...gallerySpec.categories, ...gallerySpec.tags];
        return combinedTags.map(t => t.name);
      }, [gallerySpec]);

      const featureable =
        projectAccess === 'USER_READ' || projectAccess === 'USER_WRITE';

      return (
        <div
          className="hide-on-print"
          style={{
            width: '100%',
            height: 52,
            backgroundColor: globals.galleryAdminPurple,
            display: 'flex',
            alignItems: 'center',
          }}>
          <Popup
            basic
            open={popupOpen}
            onOpen={() => {
              setPopupOpen(true);
            }}
            onClose={() => {
              setPopupOpen(false);
            }}
            trigger={
              <LegacyWBIcon
                style={{
                  fontSize: '2.0em',
                  cursor: 'pointer',
                  color: ADMIN_COLOR,
                  position: 'absolute',
                  right: 15,
                }}
                name="bowtie"></LegacyWBIcon>
            }
            on="click">
            <Popup.Header
              style={{
                color: ADMIN_COLOR,
                textTransform: 'uppercase',
                alignItems: 'center',
                display: 'flex',
              }}>
              <LegacyWBIcon
                style={{
                  fontSize: '1.15em',
                }}
                name="bowtie"></LegacyWBIcon>
              <span style={{fontSize: 12}}>Admin Only</span>
            </Popup.Header>
            <div
              onClick={() => {
                setPopupOpen(false);
                openModal();
              }}
              style={{cursor: 'pointer'}}>
              Add report to gallery
            </div>
          </Popup>

          <Modal
            open={modalOpen}
            onOpen={() => {
              openModal();
            }}
            onClose={() => setModalOpen(false)}>
            <Modal.Header
              style={{
                color: ADMIN_COLOR,
                display: 'flex',
                alignItems: 'center',
              }}>
              <LegacyWBIcon
                style={{color: ADMIN_COLOR}}
                size="big"
                name="bowtie"></LegacyWBIcon>
              Admin: Add to gallery
              <span
                style={{
                  transition: 'all 0.2s',
                  opacity: party ? 1 : 0,
                  position: 'absolute',
                  right: 20,
                }}>
                Successful update!
                <span aria-label="cheers" role="img">
                  🥂
                </span>
                <span aria-label="growth" role="img">
                  🌱
                </span>
              </span>
            </Modal.Header>
            {featureable ? (
              <>
                <Modal.Content>
                  Here's what the report will look like in the gallery. Add tags
                  from the list to categorize this report.
                  <div style={{maxWidth: 600, margin: 'auto'}}>
                    <Post {...reportCardDraft} tagIsCategory={tagIsCategory} />
                  </div>
                  <TagPicker
                    availableTags={allTagNames}
                    setTags={setTags}
                    currentTags={reportCardDraft.tags?.map(t => t.name) ?? []}
                  />
                  <S.Label>Language</S.Label>
                  <PopupDropdown
                    options={languageOptions}
                    offset={`0px, -16px`}
                    trigger={
                      <S.Dropdown>
                        {language} <S.DropdownIcon name="down" />
                      </S.Dropdown>
                    }
                  />
                  <S.Label>Publish Time</S.Label>
                  <S.Input
                    placeholder="1/1/2021 09:00 AM"
                    value={publishTime}
                    onChange={
                      ((e, {value}) =>
                        setPublishTime(value)) as StrictInputProps['onChange']
                    }
                  />
                  <S.Label>Announcement</S.Label>
                  <S.Checkbox
                    checked={!!reportCardDraft.announcement}
                    onChange={
                      ((e, {checked}) =>
                        setReportCardDraft(prev => ({
                          ...prev,
                          announcement: checked ? true : undefined,
                        }))) as StrictCheckboxProps['onChange']
                    }
                  />
                  <S.Label>Approved</S.Label>
                  <S.Checkbox
                    checked={!reportCardDraft.pending}
                    onChange={
                      ((e, {checked}) =>
                        setReportCardDraft(prev => ({
                          ...prev,
                          pending: checked ? undefined : true,
                        }))) as StrictCheckboxProps['onChange']
                    }
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    disabled={
                      !inGallery || removingFromGallery || addingToGallery
                    }
                    loading={removingFromGallery}
                    style={{
                      backgroundColor: inGallery ? ADMIN_COLOR : null,
                      color: inGallery ? 'white' : null,
                      position: 'absolute',
                      left: 10,
                    }}
                    onClick={() => {
                      removeFromGallery();
                    }}>
                    Remove from gallery
                  </Button>
                  <Button
                    // style={{backgroundColor: ADMIN_COLOR, color: 'white'}}
                    onClick={() => {
                      setModalOpen(false);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    disabled={addingToGallery || removingFromGallery}
                    loading={addingToGallery}
                    style={{backgroundColor: ADMIN_COLOR, color: 'white'}}
                    onClick={addToGallery}>
                    {inGallery ? 'Update gallery card' : 'Add to gallery'}
                  </Button>
                </Modal.Actions>
              </>
            ) : (
              <>
                <Modal.Content>
                  This project is private — make it public to add this report to
                  the gallery
                </Modal.Content>

                <Modal.Actions>
                  <Button
                    style={{backgroundColor: ADMIN_COLOR, color: 'white'}}
                    onClick={() => {
                      setModalOpen(false);
                    }}>
                    Okay
                  </Button>
                </Modal.Actions>
              </>
            )}
          </Modal>
        </div>
      );
    },
    {id: 'ReportAdminBar'}
  );

const TagPicker = makeComp(
  (props: {
    setTags: (tags: string[]) => void;
    currentTags: string[];
    availableTags: string[];
  }) => {
    const {availableTags} = props;
    const [showDropdown, setShowDropdown] = useState(false);
    const [query, setQuery] = useState('');
    const inputRef = useRef<Input | null>(null);

    const matchedTags = availableTags.filter(t =>
      t.match(fuzzyMatchRegex(query))
    );

    const setTags = (tags: string[]) => {
      props.setTags(_.uniq(tags));
    };

    return (
      <div
        style={{
          width: '100%',
          marginTop: 10,
        }}
        className={classNames('report-header-edit__author-adder', 'active')}>
        <div
          onClick={() => {
            if (inputRef.current != null) {
              inputRef.current.focus();
            }
          }}
          className="author-adder-fake-input">
          {props.currentTags.map(tag => (
            <div key={tag} className="author-adder-added">
              {tag}
              <LegacyWBIcon
                name="close"
                className="author-adder-remove"
                onClick={() => {
                  setTags(props.currentTags.filter(v => v !== tag));
                }}
              />
            </div>
          ))}
          <Input
            ref={el => (inputRef.current = el)}
            value={query}
            onChange={(e, {value}) => setQuery(value)}
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setShowDropdown(false)}
            placeholder={'Select Tags'}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter') {
                setTags(props.currentTags.concat([capitalizeFirst(query)]));
                setQuery('');
              }
            }}
          />
        </div>
        <div
          style={{zIndex: 2}}
          className={classNames('author-adder-dropdown', {
            active: showDropdown && availableTags.length > 0,
          })}>
          {matchedTags.map(tag => (
            <div
              key={tag}
              className="author-adder-dropdown-item"
              onClick={() => {
                setTags(props.currentTags.concat([tag]));
                setQuery('');
              }}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    );
  },
  {id: 'TagPicker'}
);
