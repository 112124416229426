// Note if updating: see the performance comments at the top of
// RunSelectorInner.tsx

import '../../../css/RunSelector.less';

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import * as _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import {Popup} from 'semantic-ui-react';

import * as Filter from '../../../util/filters';
import {hotkeyString, hotkeyPressed} from '../../../util/hotkeys';
import * as Query from '../../../util/queryts';
import {
  Config as TableSettings,
  EMPTY_CONFIG as EMPTY_TABLE_SETTINGS,
} from '../../../util/runfeed';
import * as Run from '../../../util/runs';
import * as TableCols from './dataframesTablecols';
import {getColumnWidth} from './dataframesUtil';
import LegacyWBIcon from '../../elements/LegacyWBIcon';
import {ModalTriggerButton} from '../../elements/ModalTriggerButton';
import DFTableColumnEditor from './DFTableColumnEditor';
import {
  DFTableGridContainer,
  DFTableGridContainerProps,
} from './DFTableGridContainer';
import {DFTablePagination} from './DFTablePagination';
import {DFTableSortIndicatorComponent} from './DFTableSortIndicator';
import classNames from 'classnames';
import {Sort as QuerySort} from '../../../util/queryts';

export type DFTableHoverCellCoords = Array<number | string | undefined>;
export interface DFTableColumn {
  key: Run.Key;
  accessor: string; // to get data for a cell, do row[column.accessor].  generally is Run.keyToString(column.key)
  displayName?: string;
  groupName?: string;
  columns?: DFTableColumn[];
  // function used to render an individual column header
  renderHeader?(displayedRows?: any[]): JSX.Element | string;
  // function used to render an individual cell
  renderCell?(
    column: DFTableColumn,
    row: DFTableRowInterface,
    options: {
      addFilter?: (key: Run.Key, op: Filter.ValueOp, value: Run.Value) => void;
      toggleExpandedRow?: (rowAddress: string) => void;
      recursionDepth: number;
      isGroup?: boolean;
      isExpanded?: boolean;
      loadingChildren?: boolean;
      hoveringRow?: boolean;
    }
  ): JSX.Element | string;
}

export type DFTableRowFields<T> = DFTableRowInterface & T;

export interface DFTableRowInterface {
  __address__: string;
  __children__: DFTableRowInterface[];
}

export interface DFTableProps {
  style?: any;
  className?: any;

  grouping: Run.Key[];

  loading?: boolean; // from withRunsDataLoader

  rows: DFTableRowInterface[];
  columns: DFTableColumn[];

  // The list of columns that are always pinned to the left and can't be reordered.
  // This is used for the primary key columns such as the name or group keys.
  fixedColumns?: string[]; // by accessor

  // The set of filters to be edited by the RunFilters component inside this RunSelector
  filters?: Filter.RootFilter;

  tableSettings?: TableSettings;

  totalRowCount: number;

  expandedRowAddresses?: string[];
  loadingRowAddresses?: string[];

  tableMessage?: JSX.Element | string;

  // extra buttons for the Table Actions section, aligned to the left side (e.g. Project Picker, Run Search)
  extraTableActionsLeft?: JSX.Element;
  // extra buttons for the Table Actions section, aligned to the right side (e.g. Auto Columns)
  extraTableActionsRight?: JSX.Element;

  renderWithGroupQueries?: (
    gridProps: DFTableGridContainerProps
  ) => JSX.Element;

  columnEditor?: (
    config: TableSettings,
    setTableSettings: (config: TableSettings) => void
  ) => JSX.Element;

  expandable?: boolean;
  expanded?: boolean;
  title?: string;

  readOnly?: boolean;

  SortIndicatorComponent?: DFTableSortIndicatorComponent;

  onSetExpanded?: (expanded: boolean) => void;

  // make the table message disappear
  clearTableMessage?(): void;

  // RunFilters will call this to update filters when the user makes changes.
  setFilters?(filters: Filter.RootFilter): void;

  updateSort?(updateFn: (sort: QuerySort) => void): void;

  isColumnSortable?(column: DFTableColumn): boolean;

  setGrouping(grouping: Run.Key[]): void;

  setTableSettings(config: TableSettings): void;

  toggleExpandedRow?(rowAddress: string): void;

  // If present, runs on pagination onclick to dynamically load more rows
  tableLoadMore?(): void;

  // Search bar for the Table Actions section
  // Arg is a function to reset table pagination
  searchTableAction?(resetPagination: () => void): JSX.Element;

  onSetCurrentPage?(currentPage: number): void;

  openSortPopup?(): void;
}

interface DFTableState {
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  currentPage: number;
  scrolledHorizontally: boolean;
  scrolledVertically: boolean;
  scrollXBounds: [number, number];
  childrenPaginationMap: {[key: string]: number | undefined}; // maps row.__address__ to the active page for each paginated group, like {groupId2 => 2, groupId2 => 5}
  expandedRowAddresses: string[];

  // expandability
  currentClientX: number;
  initialClientX: number;
  dragging: boolean;
  scrollY: number;
  columnResizeOffset: number;
}

export class DFTable extends React.Component<DFTableProps, DFTableState> {
  static getDerivedStateFromProps(
    props: DFTableProps,
    state: DFTableState
  ): null | Partial<DFTableState> {
    if (
      (props.loading || !_.isEmpty(props.loadingRowAddresses)) &&
      _.isEmpty(props.rows)
    ) {
      return null;
    }

    const expandedRowAddresses =
      props.expandedRowAddresses || state.expandedRowAddresses || [];
    return {
      expandedRowAddresses,
    };
  }

  scrollRef = React.createRef<HTMLDivElement>();
  scrollInterval?: any;

  treeRef = React.createRef<HTMLDivElement>();
  pinnedTreeRef = React.createRef<HTMLDivElement>();
  unpinnedTreeRef = React.createRef<HTMLDivElement>();

  wrapperRef = React.createRef<HTMLDivElement>();
  containerRef = React.createRef<HTMLDivElement>();
  topRef = React.createRef<HTMLDivElement>();

  currentWidth = 0;
  snapToWidths: number[] = [];
  expandBreakpoint = 0;

  visibleColumnsInternal = memoize(
    (tableSettings: TableSettings, columns: DFTableColumn[]) => {
      return TableCols.getOrderedColumns(tableSettings, columns);
    }
  );

  tableSettingsInternal = memoize(
    (tableSettings: TableSettings, columns: DFTableColumn[]) => {
      return TableCols.initializeTableSettings(tableSettings, columns);
    }
  );

  sortableColumnsInternal = memoize((columns: DFTableColumn[]) => {
    return this.props.isColumnSortable
      ? columns.filter(this.props.isColumnSortable)
      : columns;
  });

  rowsInternal = memoize(
    (pageSize: number, currentPage: number, rows: DFTableRowInterface[]) => {
      const pageStart = (currentPage - 1) * pageSize;
      const pageEnd = currentPage * pageSize;
      const allRows = rows || [];
      // if rows don't have unique ids, add them
      // TODO: do this recursively to add ids to __children__
      if (allRows[0] && _.isUndefined(allRows[0].__address__)) {
        allRows.forEach((r, i) => (r.__address__ = i + ''));
      }
      return allRows.slice(pageStart, pageEnd);
    }
  );

  // duplicated from DFTableGridContainer; consider de-duping
  pinnedColumns = memoize(
    (tableSettings: TableSettings, columns: DFTableColumn[]) => {
      return columns.filter(c => TableCols.isPinned(tableSettings, c.accessor));
    }
  );

  updateScrollXBounds = _.throttle(
    () => {
      const node = this.scrollRef.current;
      if (node == null) {
        return;
      }

      const scrollXBounds: [number, number] = [
        node.scrollLeft,
        node.scrollLeft + node.offsetWidth,
      ];
      if (!_.isEqual(scrollXBounds, this.state.scrollXBounds)) {
        this.setState({scrollXBounds});
      }
    },
    300,
    {leading: false}
  );

  constructor(props: DFTableProps) {
    super(props);
    this.state = {
      columnDragAccessor: undefined,
      columnDropAccessor: undefined,
      columnResizingAccessor: undefined,
      currentPage: 1,
      scrolledHorizontally: false,
      scrolledVertically: false,
      scrollXBounds: [0, 4000],
      childrenPaginationMap: {},
      expandedRowAddresses: [],

      // expandability
      currentClientX: 0,
      initialClientX: 0,
      dragging: false,
      scrollY: 0,
      columnResizeOffset: 0,
    };
  }

  componentDidUpdate(prevProps: DFTableProps) {
    const scrollRef = this.scrollRef.current;
    if (!scrollRef) {
      return;
    }

    if (this.props.expanded && !prevProps.expanded) {
      // Table expanded since last update. Update scroll lock.
      disableBodyScroll(scrollRef, {reserveScrollBarGap: true});
    } else if (!this.props.expanded && prevProps.expanded) {
      // Table collapsed since last update. Update scroll lock.
      if (window.innerWidth > 768) {
        enableBodyScroll(scrollRef);
      }
    }
  }

  maxPage = () => {
    return Math.ceil(
      (this.props.totalRowCount || this.props.rows.length) / this.pageSize()
    );
  };

  pageSize = () => {
    return this.tableSettings().pageSize || EMPTY_TABLE_SETTINGS.pageSize;
  };

  tableSettings = () => {
    return this.tableSettingsInternal(
      this.props.tableSettings || EMPTY_TABLE_SETTINGS,
      this.props.columns
    );
  };

  visibleColumns = () => {
    return this.visibleColumnsInternal(
      this.tableSettings(),
      this.props.columns
    );
  };

  sortableColumns = () => {
    return this.sortableColumnsInternal(this.props.columns);
  };

  grouping = () => {
    return this.props.grouping || [];
  };

  rows = () => {
    return this.rowsInternal(
      this.pageSize(),
      this.state.currentPage,
      this.props.rows
    );
  };

  resetPagination = () => {
    if (this.props.clearTableMessage) {
      this.props.clearTableMessage();
    }
    this.setCurrentPage(1);
  };

  setCurrentPage = (currentPage: number) => {
    this.setState({currentPage});
    this.props.onSetCurrentPage?.(currentPage);
  };

  // sets the pagination (active page) for the given rowAddress
  setChildrenPagination = (rowAddress: string, activePage?: number) => {
    return this.setState({
      childrenPaginationMap: {
        ...this.state.childrenPaginationMap,
        [rowAddress]: activePage,
      },
    });
  };

  // open or close the row at the given address (rowAddress looks like '1-6-5-1-5')
  toggleExpandedRow = (rowAddress: string) => {
    if (this.props.toggleExpandedRow) {
      this.props.toggleExpandedRow(rowAddress);
    } else {
      const {expandedRowAddresses} = this.state;
      this.setState({
        expandedRowAddresses: _.includes(expandedRowAddresses, rowAddress)
          ? // remove rowAddress from expandedRowAddresses
            expandedRowAddresses.filter(a => a !== rowAddress)
          : // add rowAddress to expandedRowAddresses
            expandedRowAddresses.concat([rowAddress]),
      });
    }
  };

  setTableState = (
    stateUpdate: Pick<
      DFTableState,
      | 'columnDragAccessor'
      | 'columnDropAccessor'
      | 'columnResizingAccessor'
      | 'columnResizeOffset'
    >
  ) => {
    this.setState(stateUpdate);
  };

  handleMouseMove = (e: MouseEvent) => {
    if (this.state.dragging) {
      this.setState({currentClientX: e.clientX});
    }
  };

  computeDestinedIndex = () => {
    const draggedWidth =
      this.currentWidth + this.state.currentClientX - this.state.initialClientX;
    let closestIndex = 0;
    this.snapToWidths.forEach((width, index) => {
      if (
        Math.abs(draggedWidth - width) <
        Math.abs(draggedWidth - this.snapToWidths[closestIndex])
      ) {
        closestIndex = index;
      }
    });
    return closestIndex;
  };

  draggedPastExpandBreakpoint = () => {
    const draggedWidth =
      this.currentWidth + this.state.currentClientX - this.state.initialClientX;
    return draggedWidth > this.expandBreakpoint;
  };

  handleMouseUp = () => {
    if (this.state.dragging) {
      if (this.draggedPastExpandBreakpoint()) {
        this.toggleExpanded();
        this.setState({dragging: false});
      } else {
        this.setTableSettings({
          ...this.tableSettings(),
          pinnedColumnsShown: this.computeDestinedIndex() + 1,
        });
        this.setState({dragging: false});
        const refCurrent = this.containerRef && this.containerRef.current;
        if (refCurrent) {
          refCurrent.style.transitionProperty = 'none';
          window.setTimeout(() => {
            refCurrent.style.transitionProperty = 'width, min-width';
          }, 10);
        }
      }
      // trick panels into refreshing layout
      window.dispatchEvent(new Event('resize'));
    }
  };

  handleScroll = () => {
    this.setState({scrollY: window.scrollY});
  };

  columnEditor = () => {
    const makeColumnEditor =
      this.props.columnEditor ||
      ((tableSettings, setTableSettings) => (
        <DFTableColumnEditor
          allColumns={this.props.columns.map(column => column.accessor)}
          fixedColumns={this.props.fixedColumns}
          config={tableSettings}
          update={setTableSettings}
        />
      ));
    return makeColumnEditor(this.tableSettings(), this.props.setTableSettings);
  };

  componentDidMount() {
    const scrollRef = this.scrollRef.current;
    if (scrollRef) {
      scrollRef.addEventListener('mousewheel', this.preventBackScroll);

      if (this.props.expanded) {
        // If we're loading the table tab, disable the body scroll.
        disableBodyScroll(scrollRef);
      }
    }

    // expandability
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.handleGlobalKeyDown);

    this.props.onSetCurrentPage?.(this.state.currentPage);
  }

  componentWillUnmount() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = undefined;
    }

    // expandability
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keydown', this.handleGlobalKeyDown);

    if (this.scrollRef.current) {
      this.scrollRef.current.removeEventListener(
        'mousewheel',
        this.preventBackScroll
      );

      // Need to clear body scroll lock here, or we won't be able to set it properly later.
      enableBodyScroll(this.scrollRef.current);
    }
  }

  handleGlobalKeyDown = (e: KeyboardEvent) => {
    // alt+space shortcut to expand table
    if (hotkeyPressed(e, 'toggleRunsSidebar')) {
      this.toggleExpanded();
    }
  };

  // This guys prevents the browser backwards behavior some OS Browser combos have for the table.
  preventBackScroll = (e: any) => {
    if (!this.scrollRef.current) {
      return;
    }

    const node = this.scrollRef.current;
    const maxX = node.scrollWidth - node.offsetWidth;

    // If node event looks like it will scroll beyond the bounds of the element, prevent it and set the scroll to the boundary manually
    if (
      // Must be scrollable
      node.offsetWidth !== node.scrollWidth &&
      // More horizontal movement than vertical; this is a hack that sort of works
      Math.abs(e.deltaX) >= Math.abs(e.deltaY) &&
      // Maxed out left side
      ((node.scrollLeft <= 0 && e.deltaX < 0) ||
        // Maxed out right side
        node.scrollLeft + node.offsetWidth >= node.scrollWidth)
    ) {
      e.preventDefault();
      // Manually set the scroll to the boundary
      node.scrollLeft = Math.max(0, Math.min(maxX, node.scrollLeft + e.deltaX));
      node.scrollTop = node.scrollTop + e.deltaY;
    }
  };

  setTableSettings = (newSettings: TableSettings) => {
    this.props.setTableSettings(newSettings);
  };

  moveColumn = () => {
    const fromAccessor = this.state.columnDragAccessor;
    const toAccessor = this.state.columnDropAccessor;
    if (fromAccessor && toAccessor && fromAccessor !== toAccessor) {
      this.setTableSettings(
        TableCols.moveBefore(this.tableSettings(), fromAccessor, toAccessor)
      );
      this.setState({
        columnDragAccessor: undefined,
        columnDropAccessor: undefined,
      });
    }
  };

  togglePinnedColumn = (columnAccessor: string): void => {
    this.setTableSettings(
      TableCols.togglePinned(this.tableSettings(), columnAccessor)
    );
  };

  hideColumn = (columnAccessor: string): void => {
    this.setTableSettings(
      TableCols.hideColumns(this.tableSettings(), [columnAccessor])
    );
  };

  addFilter = (key: Run.Key, op: Filter.ValueOp, value: Run.Value) => {
    const filters = this.props.filters || Filter.EMPTY_FILTERS;
    const {setFilters} = this.props;
    if (setFilters) {
      setFilters(
        Filter.Update.groupPush(filters, [0], {
          key,
          op,
          value,
        })
      );
    }
  };

  updateSort = (updateFn: (sort: Query.Sort) => void) => {
    if (this.props.updateSort) {
      this.props.updateSort(updateFn);
    } else {
      // TODO: actually implement local sorting
      // this.setState(updateFn);
    }
    this.resetPagination();
  };

  setGrouping = (newGrouping: Run.Key[]) => {
    this.props.setGrouping(newGrouping);
    this.resetPagination();
  };

  addGroup = (newGroup: Run.Key) => {
    this.setGrouping([...this.grouping(), newGroup]);
  };

  toggleExpanded = () => {
    const expanded = this.props.expanded;
    if (this.props.expandable) {
      if (this.props.onSetExpanded) {
        this.props.onSetExpanded(!expanded);
      }
    }
  };

  onSliderMouseDown = (e: any) => {
    e.preventDefault();
    this.setState({
      initialClientX: e.clientX,
      currentClientX: e.clientX,
      dragging: true,
    });
  };

  hintClose = () => {
    if (this.wrapperRef.current && this.props.expanded) {
      this.wrapperRef.current.classList.add('hinting-close');
      window.setTimeout(() => {
        if (this.wrapperRef && this.wrapperRef.current) {
          this.wrapperRef.current.classList.remove('hinting-close');
        }
      }, 700);
    }
  };

  updateScroll = () => {
    if (this.scrollRef.current == null) {
      return;
    }
    const x = this.scrollRef.current.scrollLeft;
    const y = this.scrollRef.current.scrollTop;
    const newScrolled = {
      scrolledHorizontally: x > 0,
      scrolledVertically: y > 0,
    };
    if (
      newScrolled.scrolledHorizontally !== this.state.scrolledHorizontally ||
      newScrolled.scrolledVertically !== this.state.scrolledVertically
    ) {
      this.setState(newScrolled);
    }
    this.updateScrollXBounds();
  };

  render() {
    const {
      className,
      tableLoadMore,
      totalRowCount,
      tableMessage,
      clearTableMessage,
      extraTableActionsLeft,
      extraTableActionsRight,
      fixedColumns,
      openSortPopup,
    } = this.props;
    const loading = this.props.loading || false;
    const loadingRowAddresses = this.props.loadingRowAddresses || [];
    const {
      scrolledHorizontally,
      scrolledVertically,
      scrollXBounds,
      currentPage,
      childrenPaginationMap,
      expandedRowAddresses,
      columnDragAccessor,
      columnDropAccessor,
      columnResizingAccessor,
    } = this.state;

    // /* SORTING */
    const visibleColumns = this.visibleColumns();
    const tableSettings = this.tableSettings();

    const pinnedColumns = this.pinnedColumns(tableSettings, visibleColumns);

    this.snapToWidths = [];
    let currentSnapToWidth = 0;
    pinnedColumns.forEach(col => {
      currentSnapToWidth += getColumnWidth(tableSettings, col.key);
      this.snapToWidths.push(currentSnapToWidth);
    });
    const pinnedColumnsShown = tableSettings.pinnedColumnsShown
      ? Math.min(tableSettings.pinnedColumnsShown, pinnedColumns.length)
      : 1;
    this.currentWidth =
      this.snapToWidths[pinnedColumnsShown - 1] +
      this.state.columnResizeOffset +
      1;

    const displayedWidth = this.state.dragging
      ? Math.max(
          this.currentWidth +
            this.state.currentClientX -
            this.state.initialClientX,
          this.snapToWidths[0]
        )
      : this.currentWidth;

    const destinedIndex = this.computeDestinedIndex();
    let destinedWidth = this.snapToWidths[destinedIndex];

    const maxSidebarWidth =
      this.snapToWidths[this.snapToWidths.length - 1] +
      1 +
      this.state.columnResizeOffset;
    const fullExpandedWidth = window.innerWidth - 52;
    this.expandBreakpoint = (maxSidebarWidth + fullExpandedWidth) / 2;
    if (this.draggedPastExpandBreakpoint()) {
      destinedWidth = fullExpandedWidth;
    }

    // The wrapper width determines how squashed everything to the right of the sidebar is
    const wrapperWidth = Math.min(displayedWidth, maxSidebarWidth);

    // The container width is the width of what you see as the sidebar
    const containerWidth = this.props.expanded
      ? 'calc(100vw - 52px)'
      : this.state.dragging && destinedWidth > displayedWidth
      ? destinedWidth
      : displayedWidth > maxSidebarWidth
      ? displayedWidth
      : '100%';

    const gridProps: DFTableGridContainerProps = {
      loading: loading || false,
      columns: this.visibleColumns(),
      fixedColumns: fixedColumns || [],
      rows: this.rows(),
      addFilter: this.addFilter,
      updateSort: this.updateSort,
      addGroup: this.addGroup,
      expandedRowAddresses,
      loadingRowAddresses,
      toggleExpandedRow: this.toggleExpandedRow,
      tableSettings: this.tableSettings(),
      scrollXBounds,
      childrenPaginationMap,
      pinnedTreeRef: this.pinnedTreeRef,
      readOnly: this.props.readOnly,
      unpinnedTreeRef: this.props.expandable
        ? this.unpinnedTreeRef
        : this.scrollRef,
      expandable: this.props.expandable,
      expanded: this.props.expanded,
      SortIndicatorComponent: this.props.SortIndicatorComponent,
      setChildrenPagination: this.setChildrenPagination,
      setTableState: this.setTableState,
      setTableSettings: (settings: Partial<TableSettings>) => {
        this.setTableSettings({
          ...this.tableSettings(),
          ...settings,
        });
      },
      togglePinnedColumn: this.togglePinnedColumn,
      hideColumn: this.hideColumn,
      moveColumn: this.moveColumn,
      columnDragAccessor,
      columnDropAccessor,
      columnResizingAccessor,
      openSortPopup,
    };

    return (
      <div
        ref={this.wrapperRef}
        className={classNames('df-table-wrapper', className, {
          expanded: this.props.expanded,
          'scrolled-horizontally': scrolledHorizontally,
          'scrolled-vertically': scrolledVertically,
          'read-only': this.props.readOnly,
        })}
        style={
          this.props.expandable
            ? {
                width: wrapperWidth,
              }
            : {}
        }>
        <div
          className={'df-table-container'}
          ref={this.containerRef}
          style={
            this.props.expandable
              ? {
                  transitionDuration:
                    (maxSidebarWidth < displayedWidth &&
                      displayedWidth < this.expandBreakpoint) ||
                    columnResizingAccessor != null
                      ? '0s'
                      : undefined,
                  minWidth: containerWidth,
                  width: containerWidth,
                }
              : {}
          }>
          {this.props.expandable && !this.props.expanded && (
            <div
              className="expand-toggle-mobile"
              onClick={this.toggleExpanded}
              style={{top: 134 - Math.min(window.scrollY, 52)}}>
              <LegacyWBIcon name="next" />
              <LegacyWBIcon className="table-icon" name="table" />
            </div>
          )}
          {/* TABLE ACTIONS */}
          <div
            className="df-table-content"
            style={
              this.props.expandable
                ? {
                    paddingBottom: 59,
                    height: `calc(100vh - ${
                      52 - Math.min(this.state.scrollY, 52)
                    }px)`,
                    WebkitMaskImage: this.state.dragging
                      ? destinedWidth < displayedWidth
                        ? `linear-gradient(to left, rgba(0,0,0,.5), rgba(0,0,0,.5) ${
                            displayedWidth - destinedWidth
                          }px, rgba(0,0,0,1) ${
                            displayedWidth - destinedWidth
                          }px)`
                        : `linear-gradient(to left, rgba(0,0,0,.5), rgba(0,0,0,.5) ${
                            destinedWidth - displayedWidth
                          }px, rgba(0,0,0,1) ${
                            destinedWidth - displayedWidth
                          }px)`
                      : undefined,
                  }
                : {}
            }>
            {!this.props.readOnly && (
              <div
                className="df-table-top"
                ref={this.topRef}
                style={this.state.dragging ? {width: destinedWidth} : {}}>
                {this.props.expandable && (
                  <div className="df-table-title-wrapper">
                    <span className="df-table-title">{this.props.title}</span>
                    <Popup
                      inverted
                      open={this.props.expanded ? false : undefined}
                      position="right center"
                      size="mini"
                      className="keyboard-shortcut-popup"
                      trigger={
                        <div
                          className="expand-toggle"
                          onClick={this.toggleExpanded}>
                          <LegacyWBIcon name="next" />
                          <LegacyWBIcon
                            className="table-icon"
                            name={
                              this.props.expanded ? 'table-collapsed' : 'table'
                            }
                          />
                        </div>
                      }>
                      {hotkeyString('toggleRunsSidebar')}
                    </Popup>
                  </div>
                )}
                <div className="df-table-actions">
                  <span className="df-table-actions-left">
                    {/* Filter, Group, Sort, Move, Delete, Tag, etc */}
                    {extraTableActionsLeft}
                  </span>
                  {(!this.props.expandable || this.props.expanded) && (
                    <span className="df-table-actions-right">
                      {/* Auto Cols, etc */}
                      {extraTableActionsRight}
                      {/* COLUMN CONFIG */}
                      <ModalTriggerButton
                        buttonIcon="columns"
                        buttonText="Columns"
                        modalHeader="Manage Columns"
                        modalContent={this.columnEditor()}
                      />
                    </span>
                  )}
                </div>
              </div>
            )}
            {/* TABLE MESSAGE */}
            <div className={`df-table-message ${tableMessage ? 'active' : ''}`}>
              <div>{tableMessage}</div>
            </div>
            <div
              className="df-table-grid-wrapper"
              onScroll={this.updateScroll}
              ref={this.props.expandable ? this.scrollRef : undefined}>
              {/* DATA TABLE */}
              {this.props.renderWithGroupQueries ? (
                this.props.renderWithGroupQueries(gridProps)
              ) : (
                <DFTableGridContainer {...gridProps} />
              )}
            </div>
          </div>
          {/* PAGINATION */}
          <DFTablePagination
            style={this.state.dragging ? {width: destinedWidth} : {}}
            loading={loading}
            currentPage={currentPage}
            maxPage={this.maxPage()}
            currentPageSize={this.tableSettings().pageSize}
            totalRowCount={totalRowCount}
            onClickPrevPage={() => {
              if (clearTableMessage) {
                clearTableMessage();
              }
              this.setCurrentPage(currentPage - 1);
            }}
            onClickNextPage={() => {
              if (clearTableMessage) {
                clearTableMessage();
              }
              if (tableLoadMore != null) {
                tableLoadMore();
              }
              const newPage = currentPage + 1;
              this.setCurrentPage(newPage);
            }}
            setPageSize={newPageSize => {
              this.setTableSettings({
                ...this.tableSettings(),
                pageSize: newPageSize,
              });
              this.resetPagination();
            }}
          />
          {this.props.expandable && !this.props.expanded && (
            <>
              <div
                className="df-table-slider"
                style={{
                  height:
                    this.topRef && this.topRef.current
                      ? this.topRef.current.clientHeight
                      : 0,
                }}
                onMouseDown={this.onSliderMouseDown}
              />
              <div
                className="df-table-slider"
                style={{
                  marginTop:
                    this.topRef && this.topRef.current
                      ? this.topRef.current.clientHeight + 44
                      : 0,
                  height:
                    this.scrollRef && this.scrollRef.current
                      ? this.scrollRef.current.clientHeight
                      : 0,
                }}
                onMouseDown={this.onSliderMouseDown}
              />
              {this.state.dragging && <div className="dragging-overlay" />}
            </>
          )}
        </div>
      </div>
    );
  }

  reload() {
    this.forceUpdate();
  }
}
