import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';

import {routerMiddleware} from 'connected-react-router';
import {applyMiddleware, compose, createStore} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import {envIsDev, envIsIntegration, envIsProd} from './config';
import createRootReducer from './reducers';
import allSagas from './state/sagas';
import {captureError} from './util/integrations';
import {apolloLink, createCircularDependencies} from './util/apollo';
import {
  apolloLink as apolloLink2,
  createCircularDependencies as createCircularDependencies2,
} from './util/apollo2';
import {Auth} from './util/auth';
import globalHistory from './util/history';
import {RootState} from './types/redux';
import produce from 'immer';

import introspectionQueryResultData from './generated/fragmentTypes.json';
import DebugCollector from './util/debug-collector';
import {updateAvailableNavigation} from './state/global/actions';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Create apollo client
export const apolloClient = new ApolloClient({
  link: apolloLink,
  connectToDevTools: envIsDev || envIsIntegration,
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
  // Uncomment to log helpful messages when trying to find objects
  //     that don't have IDs. Don't do this in production since this
  //     function is called for each cache entry for each query.
  // cache: new InMemoryCache({
  //   fragmentMatcher,
  //   dataIdFromObject: object => {
  //     if (
  //       object.__typename != null &&
  //       !object.__typename.endsWith('Edge') &&
  //       !object.__typename.endsWith('Connection')
  //     ) {
  //       if (object.id == null) {
  //         console.warn('Trying to cache object with no ID', object);
  //       }
  //     }
  //     return object.id || null;
  //   },
  // }),
  assumeImmutableResults: true,

  // This is necessary because we abort inflight queries when components unmount.
  // Without this option, Apollo will never fire an aborted query again, even on remount.
  queryDeduplication: false,
});

// apollo-client caches all mutations permanently, just so it
// can display them in the devtools, even if the devtools don't exist.
// It causes a huge memory leak, especially when we auto-save view specs
// which can be very large.
// This is completely braindead and is just of many indicators of poor
// engineering quality from Apollo.
// The problem was reported a long time ago with a nice repro, but never
// addressed: https://github.com/apollographql/apollo-client/issues/2302
// It may be fixed in apollo3, but I have little trust in Apollo and think
// upgrading is pretty risky. We'll need to do a lot of testing whenever
// we decide to do it.
//
// We monkey patch Apollo's mutationStore to disable this behavior.
const mutationStore = apolloClient.queryManager.mutationStore;
const mutationStoreInitMutation: typeof mutationStore.initMutation = function (
  this: any,
  mutationId,
  mutation,
  variables
) {
  this.store[mutationId] = {
    mutation: 'removed, see WB-3579',
    variables: {},
    loading: true,
    error: null,
  };
};
mutationStore.initMutation = mutationStoreInitMutation;

export const apolloClient2 = new ApolloClient({
  link: apolloLink2,
  connectToDevTools: false,
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});

export type ApolloClientType = typeof apolloClient2;

// Create auth client
export const auth = new Auth();

// Setup browser history

// Setup redux
export const history = globalHistory;

// TODO: Get this through redux
export const getHistory = () => history;

// Create initial state.
const initialState = {};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large.
const actionSanitizer = (action: any) => {
  if (action.type === 'view/INIT_ALL') {
    return {
      ...action,
      payload: {...action.payload, viewApi: '<sanitized viewApi>'},
    };
  }
  return action;
};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large.
const stateSanitizer = (state: any) => {
  return {
    ...state,
    views2: {...state.views2, viewApi: '<sanitized viewApi>'},
  };
};

// Create store middleware
const devtoolsMiddleware =
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__({
        actionsBlacklist: ['@view/interactionState'],
        actionSanitizer,
        stateSanitizer,
        // Uncomment this line to enable action tracing
        // trace: true,
      })
    : (f: any) => f;

const sagaMiddleware = createSagaMiddleware({
  onError: (error, {sagaStack}) => {
    console.error(error, sagaStack);
    captureError(error, 'saga_middleware', {
      extra: {
        sagaStack,
      },
    });
  },
});

const middleware = compose(
  applyMiddleware(
    routerMiddleware(history),
    thunk.withExtraArgument(apolloClient),
    sagaMiddleware
  ),
  devtoolsMiddleware
);

// Create the store
const reducer = enableBatching(createRootReducer(history));
export const store = createStore(reducer, initialState as any, middleware);

if (!envIsProd) {
  // Typescript doesn't like us looking for .hit on module
  const untypedModule = module as any;
  if (untypedModule.hot) {
    untypedModule.hot.accept('./reducers', () => {
      store.replaceReducer(reducer);
    });
  }
}

// Give the apollo client backend access to the store and auth (which it uses
// to get the current JWT or refresh it if needed).
createCircularDependencies(store, auth);
createCircularDependencies2(store, auth);

// Give our auth library access to the store
auth.store = store;

// Don't flag as IFrame if we're mounted in a service like Kubeflow.
// Do flag as IFrame if we're rendered in Kubeflow notebooks.
export function isInIframe() {
  try {
    return (
      window.self !== window.top &&
      (window.self.location.origin !== window.top.location.origin ||
        window.top.location.pathname.startsWith('/notebook'))
    );
  } catch (e) {
    return true;
  }
}

// Test if jupyter=true is set on document load. If it's set, then you should
// continue to act as if we're in jupyter notebook mode even if the href
// changes later.
// TODO: revisit this, we may want to differentiate between jupyter and other sites embedding us
const isJupyter = isInIframe() || document.location.href.match(/jupyter=true/);

// FeaturePeek runs containers in an iframe, so we have to differentiate from
// the Jupyter Notebook iframe
const isFeaturePeek = document.location.href.match(/\.featurepeek\.com/);
const isLocalLogin = document.location.href.match(/local=true/);

// We store the available navigation state in redux for iframe nav
const startPath = document.location.pathname;
history.listen((location: any) => {
  const availableNavigation = (store.getState() as RootState).global
    .availableNavigation;
  const nav = produce(availableNavigation, draft => {
    if (history.action === 'PUSH') {
      draft.backward = true;
      draft.forward = false;
    } else if (history.action === 'POP') {
      draft.forward = draft.backwardCount > 0;
      draft.backward = location.pathname !== startPath;
      if (location.pathname === startPath) {
        draft.backwardCount = 0;
      }
    }
  });
  store.dispatch(updateAvailableNavigation(nav));
});

export function isInJupyterNotebook() {
  return isJupyter && !isLocalLogin && !isFeaturePeek;
}

// Global error notification
window.addEventListener('unhandledrejection', event => {
  // NOTE there is no need for this error to be displayed in flash message
  // as it is caught by apollo's `errorLink`
  console.error('Unhandled rejection', event);
});

sagaMiddleware.run(allSagas, apolloClient);

export const debugCollector = new DebugCollector();
