import * as _ from 'lodash';
import {useMemo} from 'react';
import {BoundingBoxSliderControl, LineStyle} from '../MediaCard';
import * as Color from '../../util/colors';
import * as Types from '@wandb/cg/browser/model/types';
import * as OpTypes from '@wandb/cg/node/opTypes';

export interface ClassState {
  color: string;
  name: string;
}

export interface ClassSetState {
  classes: {
    [classID: string]: ClassState;
  };
}

export interface ClassSetControls {
  [classSetID: string]: ClassSetState;
}

export interface OverlayControls {
  [overlayID: string]: OverlayState;
}

export interface OverlayClassState {
  opacity: number;
  disabled: boolean;
}

export interface BaseOverlayState {
  type: ControlType;
  name: string;
  disabled: boolean;
  classSearch: string;
  classSetID: string;
  classOverlayStates: {[classID: string]: OverlayClassState};
}

export interface BoxControlState extends BaseOverlayState {
  type: 'box';
  lineStyle?: LineStyle;
}

export interface MaskControlState extends BaseOverlayState {
  type: 'mask';
  hideImage?: boolean;
}

export interface BoxSliderState {
  [valueName: string]: BoundingBoxSliderControl;
}

export type OverlayState = BoxControlState | MaskControlState;
export type ControlType = OverlayState['type'];

export type UpdateControl = <T extends BaseOverlayState>(
  newControl: Partial<T>
) => void;

function createBaseControls(
  name: string,
  classSetID: string,
  classSet: ClassSetState
): Omit<BaseOverlayState, 'type'> {
  const classStates = Object.fromEntries(
    Object.keys(classSet.classes).map(classID => [
      classID,
      {opacity: 1, disabled: false},
    ])
  );
  return {
    name,
    classSetID,
    classOverlayStates: classStates,
    classSearch: '',
    disabled: false,
  };
}

export function createMaskControls(
  ...args: Parameters<typeof createBaseControls>
): MaskControlState {
  return {...createBaseControls(...args), type: 'mask'};
}

export function createBoxControls(
  ...args: Parameters<typeof createBaseControls>
): BoxControlState {
  return {
    ...createBaseControls(...args),
    type: 'box',
    lineStyle: 'line',
  };
}

const defaultClassSetID = 'default';

export const useImageControls = (
  inputType: Types.Type,
  knownOverlayControls: OverlayControls | undefined
) => {
  const usableType = useMemo(() => {
    return OpTypes.nullableTaggableStrip(inputType) as Types.ImageType;
  }, [inputType]);
  const imageDefinedClassSets = useMemo(() => {
    const classSet = _.mapValues(usableType.classMap ?? {}, (value, key) => {
      const keyNOrNan = parseInt(key, 10);
      const color = isNaN(keyNOrNan)
        ? Color.colorFromName(key)
        : Color.colorN(keyNOrNan, Color.ROBIN16);
      return {color, name: value};
    }) as ClassSetState['classes'];
    const classSetState: ClassSetState = {
      classes: classSet,
    };
    return {
      [defaultClassSetID]: classSetState,
    } as ClassSetControls;
  }, [usableType]);

  const imageDefinedControls = useMemo(() => {
    const imageDefinedControlsInner: {[key: string]: BaseOverlayState} = {};
    (usableType.maskKeys ?? []).forEach(id => {
      const usedId = 'mask-' + String(id);
      const newControl: BaseOverlayState = createMaskControls(
        usedId,
        defaultClassSetID,
        imageDefinedClassSets[defaultClassSetID]
      );
      imageDefinedControlsInner[usedId] = newControl;
    });
    (usableType.boxKeys ?? []).forEach(id => {
      const usedId = 'box-' + String(id);
      const newControl: BaseOverlayState = createBoxControls(
        usedId,
        defaultClassSetID,
        imageDefinedClassSets[defaultClassSetID]
      );
      imageDefinedControlsInner[usedId] = newControl;
      return newControl;
    });

    return imageDefinedControlsInner;
  }, [usableType, imageDefinedClassSets]);

  const mergedControls = useMemo(() => {
    if (knownOverlayControls == null) {
      return imageDefinedControls;
    }
    const imgKeys = Object.keys(imageDefinedControls);
    const knownKeys = Object.keys(knownOverlayControls);
    let needsMerging = false;
    for (const imgKey of imgKeys) {
      if (!knownKeys.includes(imgKey)) {
        needsMerging = true;
        break;
      }
    }
    if (!needsMerging) {
      return knownOverlayControls;
    } else {
      return {
        ...imageDefinedControls,
        ...knownOverlayControls,
      };
    }
  }, [imageDefinedControls, knownOverlayControls]);

  const {maskControls, boxControls} = useMemo(() => {
    const maskControlsInner: MaskControlState[] = [];
    const boxControlsInner: BoxControlState[] = [];
    Object.keys(mergedControls).forEach(key => {
      if (key.startsWith('mask-')) {
        maskControlsInner.push(mergedControls[key] as MaskControlState);
      } else if (key.startsWith('box-')) {
        boxControlsInner.push(mergedControls[key] as BoxControlState);
      }
    });

    return {maskControls: maskControlsInner, boxControls: boxControlsInner};
  }, [mergedControls]);

  return {
    maskControls,
    boxControls,
    controls: mergedControls,
    classSets: imageDefinedClassSets,
  };
};
