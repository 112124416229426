import * as S from './ControlFilter.styles';
import classNames from 'classnames';
import React, {useCallback, useState} from 'react';
import * as SemanticHacks from '../../util/semanticHacks';
import {Button} from 'semantic-ui-react';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import makeComp from '../../util/profiler';
import * as HL from '@wandb/cg/browser/hl';
import * as Code from '@wandb/cg/browser/code';
import * as ExpressionEditor from './ExpressionEditor';
import * as Types from '@wandb/cg/browser/model/types';
import * as CGTypes from '@wandb/cg/browser/types';

interface ControlFilterProps {
  frame: Code.Frame;
  filterFunction: Types.NodeOrVoidNode;
  setFilterFunction(newNode: Types.NodeOrVoidNode): void;
}

export const ControlFilter: React.FC<ControlFilterProps> = makeComp(
  ({
    frame,
    filterFunction: propsFilterFunction,
    setFilterFunction: propsSetFilterFunction,
  }) => {
    const [open, setOpen] = useState(false);
    const [filterFunction, setFilterFunction] =
      useState<CGTypes.EditingNode>(propsFilterFunction);
    const isValid =
      HL.nodeIsExecutable(filterFunction) &&
      (filterFunction.nodeType === 'void' ||
        Types.isAssignableTo(filterFunction.type, Types.maybe('boolean')));
    const updateFilterFunction = useCallback(() => {
      if (HL.nodeIsExecutable(filterFunction) && isValid) {
        propsSetFilterFunction(filterFunction);
      } else {
        setFilterFunction(propsFilterFunction);
      }
    }, [propsFilterFunction, propsSetFilterFunction, filterFunction, isValid]);

    const openPopup = useCallback(() => setOpen(true), []);

    let buttonClass;
    if (open) {
      buttonClass = 'action-button--focused';
    } else if (filterFunction.nodeType !== 'void') {
      buttonClass = 'action-button--active';
    } else {
      buttonClass = 'action-button--static';
    }

    return (
      <S.Popup
        basic
        open={open}
        onOpen={openPopup}
        onClose={SemanticHacks.withIgnoreBlockedClicks(() => {
          updateFilterFunction();
          setOpen(false);
        })}
        className="wb-table-action-popup"
        on="click"
        position="bottom left"
        trigger={
          <Button
            size="tiny"
            className={classNames('filter', 'wb-icon-button', buttonClass)}>
            <LegacyWBIcon name="filter" title={'Filter'} />
            Filter
          </Button>
        }>
        <S.PopupContent>
          <S.Label>Filter expression</S.Label>
          <ExpressionEditor.ExpressionEditor
            frame={frame}
            node={filterFunction}
            updateNode={setFilterFunction}
            focusOnMount
            // debug={true}
          />
          <Button
            disabled={!isValid}
            onClick={updateFilterFunction}
            style={{marginTop: 12}}
            size="tiny">
            Apply
          </Button>
        </S.PopupContent>
      </S.Popup>
    );
  },
  {id: 'ControlFilter', memo: true}
);
