import gql from 'graphql-tag';
import {useQuery} from './query';
import * as Filter from '../../util/filters';

import {TagCount} from '../../types/graphql';
import * as Generated from '../../generated/graphql';

///// Query definition

export const PROJECT_TAGS_QUERY = gql`
  query ProjectTags(
    $projectName: String!
    $entityName: String
    $filters: JSONString
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      tagCounts(filters: $filters) {
        name
        count
      }
    }
  }
`;

// The query's output shape.
interface Data {
  project: {
    tagCounts: TagCount[];
  };
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  entityName: string;
  projectName: string;

  filters?: Filter.Filter;
}

export function useProjectTagsQuery(inputProps: InputProps) {
  const filters = inputProps.filters || Filter.EMPTY_FILTERS;
  const variables = {
    entityName: inputProps.entityName,
    projectName: inputProps.projectName,
    filters: JSON.stringify(Filter.toMongo(filters)),
  };
  return useQuery<Data, Generated.ProjectTagsQueryVariables>(
    Generated.ProjectTagsDocument,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    }
  );
}
