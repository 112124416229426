import gql from 'graphql-tag';

export const SUBSCRIPTION_UPDATE = gql`
  mutation updateSubscription(
    $entityName: String!
    $teamsEnabled: Boolean
    $privateProjects: Boolean
    $gpuEnabled: DateTime
    $hubPreset: String
    $repo: String
    $disk: String
    $redisEnabled: Boolean
    $dockerEnabled: Boolean
    $image: String
  ) {
    updateSubscription(
      input: {
        entityName: $entityName
        teamsEnabled: $teamsEnabled
        privateProjects: $privateProjects
        gpuEnabled: $gpuEnabled
        hubPreset: $hubPreset
        hubSettings: {
          repo: $repo
          disk: $disk
          redisEnabled: $redisEnabled
          dockerEnabled: $dockerEnabled
          image: $image
        }
      }
    ) {
      entity {
        id
        name
        available
        photoUrl
        limits
      }
    }
  }
`;

export const TEAM_PHOTO_UPDATE = gql`
  mutation updateEntityPhoto($entityName: String!, $photoUrl: String) {
    updateEntity(input: {entity: $entityName, photoUrl: $photoUrl}) {
      entity {
        id
        name
        available
        photoUrl
        limits
      }
    }
  }
`;

/** Just make sure the entity projection on
 * TEAM_QUERY, TEAM_PHOTO_UPDATE, SUBSCRIPTION_UPDATE
 * is the same to avoid issues with apollo caching there is
 * some issue with how apollo caching gets effected if the porjection
 * is differrent
 */

export const TEAM_QUERY = gql`
  query TeamQuery($name: String) {
    entity(name: $name) {
      id
      name
      available
      photoUrl
      limits
    }
  }
`;

export const USER_QUERY = gql`
  query UserQuery($userName: String!) {
    user(userName: $userName) {
      id
    }
  }
`;

export const ALL_ENTITIES = gql`
  query allEntities($first: Int = 2000) {
    entities(first: $first) {
      edges {
        node {
          id
          createdAt
          name
          memberCount
          runCount
          isTeam
          members {
            admin
            pending
            username
            email
            name
            id
          }
        }
      }
    }
  }
`;

// TODO(adrnswanberg): Move this into another file?
export const CREATE_TEAM = gql`
  mutation createTeam(
    $teamName: String!
    $organizationId: String
    $teamAdminUserName: String
  ) {
    createTeam(
      input: {
        teamName: $teamName
        organizationId: $organizationId
        teamAdminUserName: $teamAdminUserName
      }
    ) {
      entity {
        id
        name
        available
        photoUrl
        limits
      }
    }
  }
`;

export const UPDATE_ENTITY_ADMIN = gql`
  mutation UpdateEntityAdmin(
    $entityName: String!
    $isPaid: Boolean
    $rateLimits: RateLimitsInput
  ) {
    updateEntity(
      input: {entity: $entityName, isPaid: $isPaid, rateLimits: $rateLimits}
    ) {
      entity {
        id
        isPaid
        rateLimits {
          filestream
          graphql
        }
      }
    }
  }
`;
