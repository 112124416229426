import React from 'react';
import * as _ from 'lodash';
import memoize from 'memoize-one';
import useResizeObserver from 'use-resize-observer';

import * as Panels from '../util/panels';
import * as Query from '../util/queryts';

import {useCallback} from 'react';

import {
  QueryToRunsDataQueryParams,
  toRunsDataQuery,
  RunsDataQuery,
} from '../containers/RunsDataLoader';
import {Tab} from 'semantic-ui-react';
import ProjectFieldSelector from './ProjectFieldSelector';
import WandbLoader from './WandbLoader';
import {
  ChartAggOption,
  ChartAreaOption,
  getScalarFromData,
  PlotFontSizeOrAuto,
} from '../util/plotHelpers';
import PanelError from './elements/PanelError';

import LabeledOption from './elements/LabeledOption';
import PanelTitle from './elements/PanelTitle';
import PanelExpressionOptions from './PanelExpressionOptions';
import {renderAggType, renderAggRange} from './PanelGroupingOptions';
import {renderChartTitle} from './PanelChartOptions';
import {parseExpressions, getExpressionFields} from './PanelExpressionOptions';
import {Expression} from '../util/expr';
import {useRunsData} from '../state/runs/hooks';
import {useSampleAndQueryToTable} from './Export';

import * as Run from '../util/runs';
import * as Filters from '../util/filters';
import {
  getDefaultLegendTemplate,
  legendTemplateFieldNames,
} from '../util/legend';

// import * as InteractStateActions from '../state/views/interactState/actions';
// import * as InteractStateContext from '../state/views/interactState/context';

import ScalarChart from './vis/ScalarChart';
import makeComp from '../util/profiler';

const PANEL_TYPE = 'Scalar Chart';
const DEFAULT_MAX_RUNS = 10000;
const DEFAULT_MAX_GROUP_RUNS = 10000;

export interface ScalarChartConfig {
  metrics?: string[];
  chartTitle?: string;
  aggregate?: boolean; // panel level grouping
  aggregateMetrics?: boolean; // currently not used
  groupBy?: string; // panel level groupBy
  groupRunsLimit?: number;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  legendFields?: string[];
  legendTemplate?: string; // used to generate the default legend
  expressions?: string[];
  showLegend?: boolean;
  fontSize?: PlotFontSizeOrAuto;
}

type PanelScalarChartProps = Panels.PanelProps<ScalarChartConfig>;

const isGrouped = (query: Query.Query, config: ScalarChartConfig) => {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  return (
    (query.runSets != null &&
      query.runSets.filter((rs: any) => rs.enabled && rs.grouping.length > 0)
        .length > 0) ||
    config.aggregate
  );
};

const getRunSets = (query: Query.Query) => {
  return query.runSets != null
    ? query.runSets.map(rs => ({
        id: rs.id,
        grouping: rs.grouping,
        entityName: rs.entityName,
        projectName: rs.projectName,
      }))
    : [];
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function defaultTitle(config: ScalarChartConfig) {
  let metricsInUse = config.metrics;

  // If we can parse the first metric include it in the title
  if (config.expressions != null) {
    const parsedExpressions = parseExpressions(config.expressions, undefined);
    const expr = parsedExpressions?.expressions?.[0];
    if (expr != null) {
      metricsInUse = config.expressions;
    }
  }

  if (metricsInUse == null) {
    return '';
  }

  const prefix = capitalizeFirstLetter(config.groupAgg ?? 'max') + ' of ';

  return (
    prefix +
    metricsInUse
      .map(m => {
        const key = Run.keyFromString(m);
        return key != null ? Run.keyDisplayName(key, true) : m;
      })
      .join(', ')
  );
}

const metricStringsToKeys = (metrics: string[]) => {
  // metrics should be in the form config:metricName, if not we assume they
  // are summary metrics.
  const metricKeys = (metrics || []).map(metricStr => {
    if (metricStr.includes(':')) {
      return (
        Run.keyFromString(metricStr) ||
        ({section: 'summary', name: metricStr} as Run.Key) // shouldn't happen
      );
    } else {
      return {section: 'summary', name: metricStr} as Run.Key;
    }
  });
  return metricKeys;
};

const PanelScalarChart: React.FC<PanelScalarChartProps> = makeComp(
  props => {
    /* const [runHighlight, setInternalRunHighlight] = React.useState<string | null>(
    null
  ); */

    /* const setHighlight = InteractStateContext.useInteractStateAction(
    InteractStateActions.setHighlight
  ); */

    const memParseExpressions = useCallback(memoize(parseExpressions), [
      props.config.expressions,
    ]);

    const parsedExpressions = memParseExpressions(props.config.expressions);

    const panelQuery = scalarChartTransformQuery(
      props.pageQuery,
      props.config,
      parsedExpressions
    );
    const runsDataQuery = useRunsData(panelQuery);
    const {data} = runsDataQuery;

    const elementRef = React.useRef<HTMLDivElement>(null);
    const {height = 1} = useResizeObserver<HTMLDivElement>({
      ref: elementRef,
    });

    const {config} = props;

    const runSets = getRunSets(props.pageQuery);

    // We need to memoize this per component instance, rather than globally.
    // Matches logic in PanelRunsLinePlot
    const memGetScalarFromData = useCallback(
      memoize(getScalarFromData, (a: any, b: any) => {
        return a[0] === b[0] && a[1] === b[1] && _.isEqual(a[2], b[2]);
      }),
      [getScalarFromData]
    );

    const singleRun = Panels.isSingleRun(props);

    const defaultLegendTemplate = getDefaultLegendTemplate(
      singleRun,
      isGrouped(props.pageQuery, config) || false,
      false,
      config.legendFields || [],
      config.metrics || [],
      runSets != null && runSets.length > 1,
      false,
      'bar'
    );

    const scalarData = memGetScalarFromData(data.filtered, {
      metricKeys: metricStringsToKeys(config.metrics || []),
      customRunColors: props.customRunColors,
      groupAgg: config.groupAgg ?? 'max',
      groupArea: config.groupArea,
      runSets,
      aggregateCalculations: [],
      legendTemplate: config.legendTemplate || defaultLegendTemplate,
      expressions: parsedExpressions.expressions || [],
      entityName: props.query.entityName,
      projectName: props.query.projectName,
    });

    /*
  const memOverrideColors = useCallback(memoize(overrideBarColors), [
    overrideBarColors,
  ]);

  const memOverrideTitles = useCallback(memoize(overrideBarTitles), [
    overrideBarTitles,
  ]);

  if (config.overrideSeriesTitles != null) {
    barData = memOverrideTitles(
      barData,
      config.overrideSeriesTitles,
      !singleRun
    );
  }

  if (config.overrideColors != null) {
    barData = memOverrideColors(barData, config.overrideColors, !singleRun);
  }
*/
    const renderConfig = () => {
      const {updateConfig} = props;
      if (!data) {
        return <></>;
      }

      const dataTab = (
        <Tab.Pane as="div" className="form-grid">
          {renderChartTitle(config, updateConfig, defaultTitle(props.config))}

          <LabeledOption
            label="Metric"
            option={
              <ProjectFieldSelector
                className="with-button"
                query={props.pageQuery}
                columns={['summary_metrics', 'config']}
                types={['number']}
                defaultKeys={[]}
                multi={false}
                autoPick
                selection
                value={props.config.metrics?.[0]}
                setValue={value => updateConfig({metrics: [value]})}
                searchByKeyAndText
              />
            }
          />
          {renderAggType(config, updateConfig, 'max')}
          {renderAggRange(config, updateConfig, false, false)}
        </Tab.Pane>
      );

      const expressionsTab = (
        <Tab.Pane as="div" className="form-grid">
          <PanelExpressionOptions
            type={'scalar'}
            config={config}
            updateConfig={updateConfig}
            availableExpressionVarNames={[]}
            exampleIdentifier={Run.rawConfigKeyDisplayName(
              (config.metrics ? config.metrics[0] : undefined) ?? 'summary:acc'
            )}
          />
        </Tab.Pane>
      );

      const settingsPanes = [
        {
          menuItem: 'Data',
          render: () => dataTab,
        },
        {
          menuItem: 'Expressions',
          render: () => expressionsTab,
        },
      ];

      return (
        <div className="chart-modal">
          <div className="chart-preview">{renderGraph()}</div>
          <div className="chart-settings">
            <Tab
              panes={settingsPanes}
              menu={{
                secondary: true,
                pointing: true,
                className: 'chart-settings-tab-menu',
              }}
            />
          </div>
        </div>
      );
    };

    const renderGraph = () => {
      let errorMessage;

      if (config.metrics == null || config.metrics.length === 0) {
        errorMessage = 'Select a metric to visualize in this chart.';
      } else if (data.filtered.length === 0) {
        errorMessage = (
          <>
            Select runs that logged{' '}
            {config.metrics?.map(Run.rawConfigKeyDisplayName).join(',')} <br />
            to visualize data in this chart.
          </>
        );
      }

      if (props.loading) {
        return <WandbLoader />;
      }

      // const highlight = _.find(barData, point => point.uniqueId === runHighlight);

      return (
        <div className="chart" ref={elementRef}>
          <PanelTitle
            title={getTitleFromConfig(props.config)}
            searchQuery={props.searchQuery}
            fontSize={Panels.getFontSize(config.fontSize ?? 'auto', height)}
          />
          <div className="chart-content" ref={elementRef}>
            {!data.loading && errorMessage != null ? (
              <PanelError message={errorMessage} />
            ) : (
              <ScalarChart
                data={scalarData}
                showLegend={
                  config.groupAgg == null ||
                  config.groupAgg === 'min' ||
                  config.groupAgg === 'max'
                }
                groupArea={config.groupArea}
              />
            )}
          </div>
        </div>
      );
    };

    if (props.configMode) {
      return renderConfig();
    } else {
      return renderGraph();
    }
  },
  {id: 'PanelScalarChart'}
);

const scalarChartTransformQuery = (
  query: Query.Query,
  config: ScalarChartConfig,
  parsedExpressions: {
    expressions?: Expression[];
    xExpressions?: Expression;
  }
) => {
  const queryToDataQueryParams: QueryToRunsDataQueryParams = {
    selectionsAsFilters: true,
  };

  const expressionFields = getExpressionFields(parsedExpressions);

  const metricKeys = metricStringsToKeys(config.metrics || []);

  if (config.metrics && config.metrics.length > 0) {
    const filters: Filters.Filter[] = metricKeys.map(metricKey => ({
      key: metricKey,
      op: '!=',
      value: null,
    }));
    const mergeFilters: Filters.Filter = Filters.Or(filters);
    queryToDataQueryParams.mergeFilters = mergeFilters;
  }

  const transformed = toRunsDataQuery(query, queryToDataQueryParams);

  let legendFields = config.legendFields || [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(
      templateFields,
      config.legendFields || []
    );
    legendFields = legendFields.concat(extraLegendFields);
  }

  let displayFields = [
    ...legendFields.map(Run.keyFromString),
    ...(query.grouping || []),
    config.aggregate && config.groupBy
      ? Run.key('config', config.groupBy)
      : null,
  ];

  if (query.runSets != null) {
    query.runSets.forEach(rs => {
      if (rs.grouping) {
        displayFields = displayFields.concat(rs.grouping);
      }
    });
  }

  const extraFields = _.uniq(_.concat(displayFields, expressionFields));

  // And then add them into the correct query fields.
  transformed.configKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'config')
    .map(key => key!.name);
  // Have to concat here to preserve the config key we added above.
  transformed.summaryKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'summary')
    .map(key => key!.name);

  transformed.page = {
    size: DEFAULT_MAX_RUNS,
  };

  if (isGrouped(query, config)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // optionally compute group statistics over all runs instead of sub-sampling
    transformed.page.size = DEFAULT_MAX_GROUP_RUNS;
    // Disable grouping for this query, we'll do it ourselves.
    transformed.queries = transformed.queries.map(q => ({...q, grouping: []}));
  }

  return transformed;
};

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state. So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query.Query,
  config: ScalarChartConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query.Query, config: ScalarChartConfig) => {
  const query = scalarChartTransformQuery(
    pageQuery,
    config,
    parseExpressions(config.expressions, undefined)
  );
  return useSampleAndQueryToTable(query, pageQuery, config);
};

function getTitleFromConfig(config: ScalarChartConfig): string {
  return config.chartTitle || defaultTitle(config);
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, ScalarChartConfig> = {
  type: PANEL_TYPE,
  Component: PanelScalarChart,
  getTitleFromConfig,
  transformQuery: transformQuerySkip,
  useTableData,
  exportable: {
    image: true,
    csv: true,
    api: true,
  },
  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    xAxisMin: {editor: 'number', displayName: 'X min'},
    xAxisMax: {editor: 'number', displayName: 'X max'},
  },
  icon: 'panel-number',
};
