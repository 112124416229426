import React, {useEffect, useState} from 'react';
import * as Prism from 'prismjs';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Container, Header} from 'semantic-ui-react';
import {History} from 'history';
import ProjectListWithData from '../components/HomePage/AllProjectCards';
import {updateLocationParams} from '../actions/location';
import '../css/ProjectList.less';

import docUrl from '../util/doc_urls';
import {setDocumentTitle} from '../util/document';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

interface AllProjectsProps {
  updateLocationParams: (params: {
    entityName: string;
    projectName: string | null;
  }) => void;
  loading: boolean;
  match: {
    params: {
      entityName: string;
    };
  };
  history: History;
}

const AllProjects: React.FC<AllProjectsProps> = makeComp(
  ({
    updateLocationParams: dispatchUpdateLocationParams,
    loading,
    match,
    history,
  }) => {
    const [order] = useState('lastActive');

    useEffect(() => {
      const entityName = match.params.entityName;
      dispatchUpdateLocationParams({
        entityName,
        projectName: null,
      });
      setDocumentTitle('All projects');
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      Prism.highlightAll();
    });

    // const orderOptions = [
    //   {key: 'lastActive', value: 'lastActive', text: 'Last Active'},
    //   {key: 'totalRuns', value: 'totalRuns', text: 'Number of Runs'},
    //   {key: 'totalUsers', value: 'totalUsers', text: 'Number of Users'},
    //   {key: 'totalRunTime', value: 'totalRunTime', text: 'Compute Time'},
    // ];
    return (
      <Container className="projects-list-page">
        <Header as="h2" style={{marginLeft: '40px'}}>
          All Projects
        </Header>
        {/* <div style={{margin: '26px 0'}}>
        {'Sort: '}
        <Dropdown
          selection
          options={orderOptions}
          value={this.state.order}
          onChange={(e, {value}) => this.setState({order: value})}
        />
      </div> */}
        <ProjectListWithData
          history={history}
          allowCreate
          entityName={match.params.entityName}
          order={order}
          loading={loading}
          emptyContent={
            <div>
              <br />
              <h4>You don't have any projects yet.</h4>
              <p>New to wandb?</p>
              <ol>
                <li>
                  Visit the getting started{' '}
                  <TargetBlank href={docUrl.gettingStarted}>
                    documentation.
                  </TargetBlank>
                </li>
                <li>
                  Take a look at our{' '}
                  <TargetBlank href={docUrl.examples}>
                    example projects.
                  </TargetBlank>
                </li>
              </ol>
            </div>
          }
        />
      </Container>
    );
  },
  {id: 'AllProjects'}
);

const mapDispatchToProps = (dispatch: (action: any) => any) => {
  return bindActionCreators({updateLocationParams}, dispatch);
};

// export dumb component for testing purposes
export {AllProjects};

export default connect(null, mapDispatchToProps)(AllProjects);
