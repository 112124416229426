import * as S from './InviteTeamButton.styles';

import React from 'react';

import makeComp from '../util/profiler';
import {useViewer} from '../state/viewer/hooks';
import {navigateTo} from '../util/history';
import {envIsLocal, envIsCloudOnprem} from '../config';
import {CreateTeamButton} from './CreateTeamModal';
import {OrgType} from '../generated/graphql';

export const InviteTeamButton = makeComp(
  () => {
    const viewer = useViewer();

    if (viewer == null) {
      return <></>;
    }

    const hasNonPersonalOrgs = viewer.organizations.find(
      o => o.orgType !== OrgType.Personal
    );

    if (hasNonPersonalOrgs) {
      return <></>;
    }

    if (envIsLocal || envIsCloudOnprem) {
      // In local/onprem, we don't use /create-team.
      return (
        <CreateTeamButton
          onCreate={() => window.location.reload()}
          renderButton={onClick => (
            <S.Button onClick={onClick}>Invite Team</S.Button>
          )}
        />
      );
    }

    return (
      <S.Button onClick={() => navigateTo({pathname: '/create-team'})}>
        Invite Team
      </S.Button>
    );
  },
  {id: 'InviteTeamButton'}
);
