/* Just used for create a new report from a non report page for now */
import {ActionType, getType, StateType} from 'typesafe-actions';
import * as Actions from '../actions/report';
import * as Report from '../util/report';
export type ReportAction = ActionType<typeof Actions>;

interface ReportReducerState {
  report?: Report.ReportConfig;
}

export default function report(
  state: ReportReducerState = {report: undefined},
  action: ReportAction
): ReportReducerState {
  switch (action.type) {
    case getType(Actions.clear):
      return {...state, report: undefined};
    case getType(Actions.set):
      return {...state, report: action.payload.report};
    default:
      return state;
  }
}

export type ReportState = StateType<typeof report>;
