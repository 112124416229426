import {Input} from 'semantic-ui-react';
import styled from 'styled-components';
import {GLOBAL_COLORS} from '../util/colors';

export const PageWrapper = styled.div`
  padding: 40px 0;
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  alignitems: center;
  justify-content: space-between;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  min-height: 400px;
`;

export const ToggleButtonGroup = styled.div`
  margin-bottom: 16px;

  .ui.button {
    border: 1px solid ${GLOBAL_COLORS.outline.toString()};
    margin-right: 0;
    width: 108px;

    &.active {
      background-color: ${GLOBAL_COLORS.primary.toString()};
      color: rgb(255, 255, 255);
    }
    &:nth-child(1) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const AddUserWrapper = styled.div`
  flex: 1 0 auto;
  margin-left: 20px;
`;

export const InputWrapper = styled(Input)`
  width: 100%;
  max-width: 330px;
`;
