import 'react-table/react-table.css';

import _ from 'lodash';
import React from 'react';
import {CellInfo, Column} from 'react-table';

import * as FuzzyMatch from '../util/fuzzyMatch';
import * as RunHelpers from '../util/runhelpers';
import LegacyWBIcon from './elements/LegacyWBIcon';
import WBReactTable from './WBReactTable';
import {flatten} from '../util/flatten';

import '../css/RunConfigTable.less';
import makeComp from '../util/profiler';

interface ArtifactMetadataTableProps {
  metadata: any;
}

const ArtifactMetadataTable: React.FC<ArtifactMetadataTableProps> = makeComp(
  ({metadata: propsMetadata}) => {
    const metadata = flatten(propsMetadata) as any;
    const keys = _.sortBy(_.keys(metadata));
    const rows = _.map(keys, k => ({searchString: k, row: k}));
    const nameColumn: Column = {
      Header: 'Name',
      accessor: '',
      className: 'name',
      Cell: (cellInfo: CellInfo) => (
        <>
          <span className="name-string">{cellInfo.value}</span>
        </>
      ),
    };
    const valueColumn: Column = {
      Header: 'Value',
      accessor: '',
      Cell: cellInfo => (
        <span className="value">
          {RunHelpers.displayValue(metadata[cellInfo.value])}
        </span>
      ),
    };

    const columns = [nameColumn, valueColumn];
    const searchResultColumns = (searchQuery: string) => {
      const searchResultNameColumn: Column = {
        Header: 'Name',
        accessor: '',
        className: 'name',
        Cell: (cellInfo: CellInfo) => (
          <>
            <LegacyWBIcon name="summary" />
            <span className="name-string">
              {FuzzyMatch.fuzzyMatchHighlight(cellInfo.value, searchQuery)}
            </span>
          </>
        ),
      };
      return [searchResultNameColumn, valueColumn];
    };
    return (
      <div className="run-config-table">
        <WBReactTable
          data={rows}
          columns={columns}
          searchResultColumns={searchResultColumns}
          pageSize={20}
        />
      </div>
    );
  },
  {id: 'ArtifactMetadataTable', memo: true}
);

export default ArtifactMetadataTable;
