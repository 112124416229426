import {WBIconButton} from '@wandb/ui';
import copyToClipboard from 'copy-to-clipboard';
import produce from 'immer';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {SortingRule} from 'react-table';
import {Button, Checkbox, Header, Loader, Modal} from 'semantic-ui-react';
import '../css/GroupPage.less';
import '../css/ProjectPage.less';

import * as Generated from '../generated/graphql';
import * as Types from '@wandb/cg/browser/model/types';
import {cachedLoadArtifactManifest} from '../files';
import {useApolloClient} from '../state/hooks';
import {artifactNiceName, isVersionAlias} from '../util/artifacts';
import docUrl from '../util/doc_urls';
import {fuzzyMatchRegex} from '../util/fuzzyMatch';
import {TargetBlank} from '../util/links';
import * as Urls from '../util/urls';
import makeComp from '../util/profiler';
import * as S from './Artifact.styles';
import ArtifactDag from './ArtifactDag';
import ArtifactFiles from './ArtifactFiles';
import ArtifactMetadataTable from './ArtifactMetadataTable';
import {Highlight, Python} from './Code';
import CopyableText from './CopyableText';
import TimeDisplay from './elements/TimeDisplay';
import {toast} from './elements/Toast';
import NoMatch from './NoMatch';
import RoutedTab from './RoutedTab';
import {TagAddButton} from './TagAddButton';
import {Tag} from './Tags';

function useArtifactManifest(artifactId?: string) {
  const client = useApolloClient();
  const [manifest, setManifest] = useState<Types.ReadyManifest | null>();
  const [loading, setLoading] = useState(false);

  const loadArtifactManifestAsync = useCallback(async () => {
    setLoading(true);
    if (artifactId) {
      const result = await cachedLoadArtifactManifest(client, artifactId);
      setManifest(result);
    }
    setLoading(false);
  }, [client, artifactId]);

  useEffect(() => {
    loadArtifactManifestAsync();
  }, [loadArtifactManifestAsync]);

  return {loading, manifest};
}

interface FileEntryLinkProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  pathString: string;
}

const FileEntryLink = makeComp<FileEntryLinkProps>(
  props => {
    const fileUrl = Urls.artifactFileStringPath({...props});
    const baseName = _.last(props.pathString.split('/'));
    const tableName = baseName
      ?.replace('.table.json', '')
      .replace('.partitioned-table.json', '')
      .replace('.joined-table.json', '');

    return <Link to={fileUrl}>{tableName}</Link>;
  },
  {id: 'FileEntryLink'}
);

type FileLinksProps = Omit<FileEntryLinkProps, 'pathString'> & {
  paths: string[];
};

const FileLinks = makeComp<FileLinksProps>(
  props => {
    return (
      <>
        {props.paths.map((path, i) => (
          <span key={path}>
            {i > 0 && ', '}
            <FileEntryLink {...props} pathString={path} />
          </span>
        ))}
      </>
    );
  },
  {id: 'FileLinks'}
);

const useArtifactTables = (artifactId?: string) => {
  const {loading, manifest} = useArtifactManifest(artifactId);
  const tableFiles = useMemo(() => {
    const contents = Object.keys(manifest?.manifest.contents ?? {});
    return contents.filter(
      path =>
        (path.endsWith('.table.json') ||
          path.endsWith('.joined-table.json') ||
          path.endsWith('.partitioned-table.json')) &&
        !path.includes('/') &&
        !path.startsWith('t1_') &&
        !path.startsWith('t2_')
    );
  }, [manifest]);
  return {loading, tableFiles};
};

interface UsedByPageState {
  after: string;
  filter: string;
  order: string;
  pageSize: number;
}

interface ArtifactProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  artifactTab?: string;
  filePath?: string;
  history: any;
  refetch: () => void;
}

const Artifact = makeComp(
  (props: ArtifactProps) => {
    const {
      entityName,
      projectName,
      artifactTypeName,
      artifactSequenceName,
      artifactCommitHash,
      artifactTab,
      filePath,
      history,
      refetch,
    } = props;
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [deleteAliases, setDeleteAliases] = useState(false);
    const [updateArtifact] = Generated.useUpdateArtifactMutation();
    const [deleteArtifact] = Generated.useDeleteArtifactMutation();
    const [usedByPage, setUsedByPage] = useState<UsedByPageState>({
      after: '',
      filter: '',
      order: '',
      pageSize: 5,
    });

    // TODO(here): allow this to load by alias
    // same for sidebar, then we can put alias in the url (latest)
    // and get updates as stuff rolls in
    const q = Generated.useArtifactQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        entityName,
        projectName,
        artifactTypeName,
        artifactID: `${artifactSequenceName}:${artifactCommitHash}`,
        usedByFilters: usedByPage.filter
          ? JSON.stringify({
              display_name: {
                $regex: fuzzyMatchRegex(usedByPage.filter).source,
              },
            })
          : null,
        usedByOrder: usedByPage.order ? usedByPage.order : null,
        usedByAfter: usedByPage.after,
        usedByLimit: usedByPage.pageSize,
      },
    });

    const sequence = Generated.useArtifactCollectionQuery({
      variables: {
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: artifactSequenceName,
      },
    });

    const artifact = q.data?.project?.artifactType?.artifact;
    const artifactId = artifact?.id;
    const artifactTables = useArtifactTables(artifactId);

    const currentSequenceAliases =
      artifact?.aliases.filter(
        a => a.artifactCollectionName === artifactSequenceName
      ) ?? [];

    const pageSizeChanged = useMemo(
      () => (pageSize: number) => {
        setUsedByPage(prev => ({
          ...prev,
          pageSize,
        }));
      },
      [setUsedByPage]
    );

    // changing search query should reset pagination
    const searchChanged = useMemo(
      () => (filter: string) => {
        setUsedByPage(prev => ({
          ...prev,
          after: '',
          filter,
        }));
      },
      [setUsedByPage]
    );

    // changing sort invalidates the data we have loaded
    const sortedChange = useMemo(
      () => (newSorted: SortingRule[]) => {
        setUsedByPage(prev => ({
          ...prev,
          after: '',
          // TODO? helper function somewhere?
          order: newSorted
            .map(rule => `${rule.desc ? '-' : '+'}${rule.id}`)
            .join(','),
        }));
      },
      [setUsedByPage]
    );

    const handleNextPage = useCallback(() => {
      const cursor = artifact?.usedBy.pageInfo.endCursor || '';
      q.fetchMore({
        variables: {
          ...q.variables,
          usedByAfter: cursor,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          const fetchedArtifact =
            fetchMoreResult?.project?.artifactType?.artifact;
          if (!fetchedArtifact) {
            return prev;
          }

          return produce(prev, draft => {
            if (!draft.project?.artifactType?.artifact) {
              draft.project = fetchMoreResult?.project;
              return;
            }
            draft.project.artifactType.artifact.usedBy.pageInfo =
              fetchedArtifact.usedBy.pageInfo;
            draft.project.artifactType.artifact.usedBy.edges.push(
              ...fetchedArtifact.usedBy.edges
            );
          });
        },
      });
    }, [artifact, q]);

    const data =
      artifact?.usedBy.edges.map(edge => ({
        searchString: edge.node.displayName || '',
        row: edge.node,
      })) || [];

    if (!q.loading && artifact == null) {
      return <NoMatch />;
    }

    return (
      <div className="artifact">
        <RoutedTab
          history={props.history}
          baseUrl={Urls.artifact({
            entityName,
            projectName,
            artifactTypeName,
            artifactSequenceName,
            artifactCommitHash,
          })}
          tabSlugs={['overview', 'api', 'metadata', 'files', 'graph']}
          activeTabSlug={artifactTab}
          panes={[
            {
              menuItem: 'Overview',
              render: () =>
                // `q.loading` could mean:
                // * we're loading the artifact for the first time (artifact === null)
                // * or we're loading more runs using this artifact
                artifact == null && q.loading ? (
                  <Loader active />
                ) : artifact == null ? (
                  <NoMatch />
                ) : (
                  (() => {
                    const {digest, state, createdBy, createdAt} = artifact;
                    const isDeleted = artifact.state === 'DELETED';
                    const hasAliases =
                      currentSequenceAliases.filter(a => !isVersionAlias(a))
                        .length > 0;
                    const createdTime = [
                      moment(createdAt + 'Z').format('MMMM Do, YYYY'),
                      'at',
                      moment(createdAt + 'Z').format('h:mm:ss a'),
                    ].join(' ');

                    return (
                      <>
                        <S.ArtifactOverview>
                          {
                            <>
                              <S.DeleteButtonContainer>
                                <WBIconButton
                                  name="delete"
                                  disabled={isDeleted}
                                  data-cy="artifact-delete"
                                  onClick={() => {
                                    setDeleteModalOpen(true);
                                  }}
                                />
                              </S.DeleteButtonContainer>
                              <Modal
                                open={isDeleteModalOpen}
                                onClose={() => setDeleteModalOpen(false)}>
                                <Header>
                                  <p>
                                    Are you sure you want to delete{' '}
                                    <b>{artifactNiceName(artifact)}</b>?
                                  </p>
                                </Header>
                                <Modal.Content>
                                  <p>
                                    The artifact and its files will no longer be
                                    accessible! Additionally, any scripts
                                    referencing this artifact by an alias will
                                    break.
                                  </p>
                                  {hasAliases && (
                                    <Checkbox
                                      label={'Delete existing aliases'}
                                      data-cy="delete-artifact-checkbox"
                                      onChange={() =>
                                        setDeleteAliases(!deleteAliases)
                                      }
                                      checked={deleteAliases}
                                    />
                                  )}
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button
                                    basic
                                    size="tiny"
                                    onClick={() => setDeleteModalOpen(false)}>
                                    Nevermind
                                  </Button>
                                  <Button
                                    negative
                                    size="tiny"
                                    loading={isDeleting}
                                    disabled={hasAliases && !deleteAliases}
                                    data-cy="artifact-delete-confirm"
                                    onClick={async () => {
                                      setDeleting(true);
                                      await deleteArtifact({
                                        variables: {
                                          artifactID: artifact.id,
                                          deleteAliases,
                                        },
                                      });
                                      await q.refetch();
                                      setDeleteModalOpen(false);
                                      setDeleting(false);
                                    }}>
                                    Delete version
                                  </Button>
                                </Modal.Actions>
                              </Modal>
                            </>
                          }
                          <S.OverviewItem>
                            <S.OverviewKey>Version</S.OverviewKey>
                            <S.OverviewValue>
                              <CopyableText
                                text={artifactNiceName(artifact)}
                                toastText={'Copied version to clipboard'}
                              />
                            </S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>Digest</S.OverviewKey>
                            <S.OverviewValue>{digest}</S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>State</S.OverviewKey>
                            <S.OverviewValue>
                              {state.toLowerCase()}
                            </S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>Created</S.OverviewKey>
                            <S.OverviewValue>{createdTime}</S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>Aliases</S.OverviewKey>
                            <S.OverviewValue>
                              <div className="artifact-aliases-wrapper">
                                {currentSequenceAliases.map(alias => {
                                  let onDelete:
                                    | (() => Promise<void>)
                                    | undefined;
                                  if (!isVersionAlias(alias)) {
                                    onDelete = async () => {
                                      await updateArtifact({
                                        variables: {
                                          artifactID: artifact.id,
                                          aliases: artifact.aliases.filter(
                                            a =>
                                              !(
                                                a.artifactCollectionName ===
                                                  artifactSequenceName &&
                                                a.alias === alias.alias
                                              )
                                          ),
                                        },
                                      });
                                      await q.refetch();
                                    };
                                  }

                                  return (
                                    <Alias
                                      key={
                                        alias.artifactCollectionName +
                                        alias.alias
                                      }
                                      alias={alias}
                                      onDelete={onDelete}
                                      onClick={() => {
                                        const copyText = `${alias.artifactCollectionName}:${alias.alias}`;
                                        copyToClipboard(copyText);
                                        toast(
                                          `Copied alias '${copyText}' to clipboard`
                                        );
                                      }}
                                    />
                                  );
                                })}
                                <AddAliasButton
                                  aliases={artifact.aliases}
                                  availableAliases={
                                    sequence.data?.project?.artifactType?.artifactSequence?.aliases?.edges.map(
                                      e => e.node!
                                    ) ?? []
                                  }
                                  onCreate={async alias => {
                                    if (
                                      artifact.aliases.find(
                                        a => a.alias === alias
                                      )
                                    ) {
                                      return;
                                    }

                                    const aliases = artifact.aliases.map(a => {
                                      return {
                                        alias: a.alias,
                                        artifactCollectionName:
                                          a.artifactCollectionName,
                                      };
                                    });

                                    await updateArtifact({
                                      variables: {
                                        artifactID: artifact.id,
                                        aliases: aliases.concat({
                                          alias,
                                          artifactCollectionName:
                                            artifactSequenceName,
                                        }),
                                      },
                                    });
                                    await refetch(); // this is needed to refetch the sidebar
                                    await q.refetch();
                                  }}
                                />
                              </div>
                            </S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>Notes</S.OverviewKey>
                            <S.OverviewValue>
                              <S.OverviewValueMarkdownEditor
                                key={artifact.id}
                                className="description"
                                readOnly={false}
                                saveText={false}
                                placeholder={'What changed in this revision?'}
                                serverText={artifact.description || ''}
                                onChange={value => {
                                  updateArtifact({
                                    variables: {
                                      artifactID: artifact.id,
                                      description: value,
                                    },
                                  });
                                }}
                              />
                            </S.OverviewValue>
                          </S.OverviewItem>
                          <S.OverviewItem>
                            <S.OverviewKey>
                              {createdBy.__typename === 'Run'
                                ? 'Output by'
                                : 'Logged by'}
                            </S.OverviewKey>
                            <S.OverviewValue>
                              {createdBy.__typename === 'Run' ? (
                                createdBy.runName != null ? (
                                  <Link
                                    to={Urls.runArtifacts({
                                      entityName,
                                      projectName,
                                      name: createdBy.runName,
                                    })}>
                                    {createdBy.displayName}
                                  </Link>
                                ) : (
                                  createdBy.displayName
                                )
                              ) : createdBy.username != null ? (
                                <Link to={Urls.entity(createdBy.username)}>
                                  {createdBy.username}
                                </Link>
                              ) : (
                                createdBy.username
                              )}
                            </S.OverviewValue>
                          </S.OverviewItem>
                          {artifactTables.tableFiles.length > 0 && (
                            <S.OverviewItem>
                              <S.OverviewKey>Tables</S.OverviewKey>
                              <S.OverviewValue>
                                <FileLinks
                                  {...props}
                                  paths={artifactTables.tableFiles}
                                />
                              </S.OverviewValue>
                            </S.OverviewItem>
                          )}
                        </S.ArtifactOverview>
                        <S.Section>
                          <S.SectionHeader>Used by</S.SectionHeader>
                          <S.SectionTable
                            columns={[
                              {
                                id: 'display_name',
                                Header: 'Run',
                                accessor: r => r.displayName,
                                Cell: ({original}) => {
                                  return (
                                    <Link
                                      to={Urls.runArtifacts({
                                        entityName:
                                          original.project?.entityName ??
                                          entityName,
                                        projectName:
                                          original.project?.name ?? projectName,
                                        name: original.name,
                                      })}>
                                      {original.displayName}
                                    </Link>
                                  );
                                },
                              },
                              {
                                id: 'group_name',
                                Header: 'Group',
                                accessor: r => r.group,
                              },
                              {
                                id: 'username',
                                Header: 'User',
                                accessor: r => r.user.username,
                              },
                              {
                                id: 'created_at',
                                Header: 'Created',
                                accessor: r => r.createdAt,
                              },
                              {
                                id: 'totalRunTime',
                                // TODO: requires backend support to sort on a computed value
                                sortable: false,
                                Header: 'Duration',
                                Cell: ({original}) => (
                                  <TimeDisplay
                                    timestamp={new Date(original.createdAt)}
                                    format="month_round"
                                    now={new Date(original.heartbeatAt)}
                                  />
                                ),
                              },
                            ]}
                            data={data}
                            hasNextPage={artifact?.usedBy.pageInfo.hasNextPage}
                            loading={q.loading}
                            onChangePageSize={pageSizeChanged}
                            onFetchNextPage={handleNextPage}
                            onSearch={searchChanged}
                            onSortedChange={sortedChange}
                            pageSize={usedByPage.pageSize}
                          />
                        </S.Section>
                      </>
                    );
                  })()
                ),
            },
            {
              menuItem: 'API',
              render: () => {
                if (q.loading) {
                  return <Loader active />;
                }
                if (artifact == null) {
                  throw new Error('invalid');
                }

                return (
                  <Python>
                    <Highlight iconOnly>
                      {`import wandb
run = wandb.init()
artifact = run.use_artifact('${entityName}/${projectName}/${artifactNiceName(
                        artifact
                      )}', type='${artifactTypeName}')
artifact_dir = artifact.download()`}
                    </Highlight>
                  </Python>
                );
              },
            },
            {
              menuItem: 'Metadata',
              render: () => {
                let metadata: any = {};
                if (artifact != null) {
                  try {
                    metadata = JSON.parse(artifact.metadata);
                    metadata = JSON.parse(metadata);
                  } catch {
                    //
                  }
                }
                if (q.loading) {
                  return <Loader active />;
                }
                return metadata != null && _.keys(metadata).length > 0 ? (
                  <>
                    <Header as="h4">Metadata</Header>
                    <ArtifactMetadataTable metadata={metadata} />
                  </>
                ) : (
                  <S.InlineSegment>
                    No metadata associated with this artifact. Learn how to set
                    metadata{' '}
                    <TargetBlank href={docUrl.artifactsMetadata}>
                      in the docs {'\u2192'}
                    </TargetBlank>
                  </S.InlineSegment>
                );
              },
            },
            {
              menuItem: 'Files',
              render: () => {
                return (
                  <ArtifactFiles
                    entityName={entityName}
                    projectName={projectName}
                    artifactTypeName={artifactTypeName}
                    artifactSequenceName={artifactSequenceName}
                    artifactCommitHash={artifactCommitHash}
                    filePath={filePath}
                    useSetFilePath={base => path =>
                      history.push(
                        Urls.artifactFile(
                          Object.assign({artifactTypeName, path}, base)
                        )
                      )}
                  />
                );
              },
            },
            {
              menuItem: 'Graph view',
              render: () => (
                <div
                  style={{
                    height: '100%',
                    padding: 16,
                  }}>
                  <ArtifactDag
                    entityName={entityName}
                    projectName={projectName}
                    artifactTypeName={artifactTypeName}
                    artifactSequenceName={artifactSequenceName}
                    artifactCommitHash={artifactCommitHash}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  },
  {id: 'Artifact'}
);

function colorIndex(alias: Pick<Generated.ArtifactAlias, 'alias'>): number {
  if (isVersionAlias(alias)) {
    return -1;
  }

  let h = 0;
  for (let i = 0; i < alias.alias.length; i++) {
    const char = alias.alias.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    h = (h << 5) - h + char;
    // tslint:disable-next-line:no-bitwise
    h = h & h; // Convert to 32bit integer
  }
  return Math.abs(h);
}

interface AliasProps {
  alias: Pick<Generated.ArtifactAlias, 'alias'>;
  onDelete?: () => Promise<any>;
  onClick?(): void;
}

const Alias = makeComp(
  (props: AliasProps) => {
    const {alias, onDelete, onClick} = props;
    const [isModalOpen, setModalOpen] = useState(false);
    const [isRemoving, setRemoving] = useState(false);

    return (
      <>
        <Tag
          tag={{
            name: alias.alias,
            colorIndex: colorIndex(alias),
          }}
          onDelete={onDelete ? () => setModalOpen(true) : undefined}
          onClick={onClick}
        />
        <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
          <Header>
            <p>
              Are you sure you want to remove the <b>{alias.alias}</b> alias?
            </p>
          </Header>
          <Modal.Content>
            This will break any scripts that use this alias!
          </Modal.Content>
          <Modal.Actions>
            <Button basic size="tiny" onClick={() => setModalOpen(false)}>
              Nevermind
            </Button>
            <Button
              loading={isRemoving}
              negative
              size="tiny"
              onClick={async () => {
                setRemoving(true);

                if (onDelete) {
                  await onDelete();
                }

                setRemoving(false);
                setModalOpen(false);
              }}>
              Remove alias
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  },
  {id: 'Artifact.Alias'}
);

interface AddAliasButtonProps {
  aliases: Array<Pick<Generated.ArtifactAlias, 'alias'>>;
  availableAliases: Array<Pick<Generated.ArtifactAlias, 'alias'>>;
  onCreate: (newAlias: string) => Promise<any>;
}

const AddAliasButton = makeComp(
  (props: AddAliasButtonProps) => {
    const {aliases, availableAliases, onCreate} = props;

    return (
      <TagAddButton
        tags={aliases.map(a => {
          return {name: a.alias, colorIndex: colorIndex(a)};
        })}
        availableTags={availableAliases.map(a => {
          return {name: a.alias, colorIndex: colorIndex(a)};
        })}
        addTag={async tag => {
          if (isVersionAlias({alias: tag.name})) {
            toast('Custom aliases matching /^v(d+)$/ are not allowed.');
            return;
          }
          await onCreate(tag.name);
        }}
        compact={true}
        direction="bottom right"
        noun={'alias'}
      />
    );
  },
  {id: 'AddAliasButton'}
);

export default Artifact;
