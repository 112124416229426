import gql from 'graphql-tag';

import {compact as _compact} from 'lodash';
import * as Generated from '../../generated/graphql';
import {useQuery} from './query';
import {useLazyQuery} from 'react-apollo';

export const PARAMETER_IMPORTANCE_QUERY = gql`
  query ParameterImportance(
    $projectName: String!
    $entityName: String
    $limit: Int!
    $targetName: String!
    $filters: JSONString
    $parameters: [String!]
    $runParameters: [String!]
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      parameterImportance(
        limit: $limit
        filters: $filters
        target: $targetName
        parameters: $parameters
        runParameters: $runParameters
      )
    }
  }
`;

export interface ParameterImportanceData {
  [key: string]: {importance: number; correlation: number};
}

export interface ParameterImportanceQueryData {
  project: {
    id: string;
    parameterImportance: ParameterImportanceData;
  };
}
export function useParameterImportanceQuery(
  variables: Generated.ParameterImportanceQueryVariables
) {
  return useQuery<ParameterImportanceQueryData, typeof variables>(
    PARAMETER_IMPORTANCE_QUERY,
    {
      variables: {
        ...variables,
        parameters: _compact(variables.parameters?.map(k => k.split(':')[1])),
        runParameters: _compact(
          variables.runParameters?.map(k => k.split(':')[1])
        ),
      },
      skip: !variables.targetName,
    }
  );
}

export function useLazyParameterImportanceQuery(
  variables: Generated.ParameterImportanceQueryVariables
) {
  return useLazyQuery<ParameterImportanceQueryData, typeof variables>(
    PARAMETER_IMPORTANCE_QUERY,
    {
      variables: {
        ...variables,
        parameters: _compact(variables.parameters?.map(k => k.split(':')[1])),
        runParameters: _compact(
          variables.runParameters?.map(k => k.split(':')[1])
        ),
      },
    }
  );
}
