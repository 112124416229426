import * as _ from 'lodash';
import React from 'react';
import {useState, useCallback, useMemo} from 'react';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import * as Types from '@wandb/cg/browser/model/types';
import PageControls from './ControlPage';
import * as Table from './tableState';
import * as CGReact from '../../cgreact';
import {useGatedValue} from '../../state/hooks';
import * as Op from '@wandb/cg/browser/ops';
import * as Panel2 from './panel';
import {PanelComp2} from './PanelComp';

export interface PanelRowConfig {
  pageSize: number;
  childConfig: any;
}
type PanelRowProps = Panel2.PanelConverterProps;

function defaultConfig(
  inputType: Types.Type,
  child: Panel2.PanelSpecNode
): PanelRowConfig {
  inputType = Types.nullableTaggableValue(inputType);
  if (!Types.isList(inputType)) {
    throw new Error('invalid');
  }
  let pageSize = 3;
  if (Types.listMaxLength(inputType) === 2) {
    pageSize = 2;
  }
  if (child.id === 'id') {
    pageSize = 1;
  }
  return {pageSize, childConfig: undefined};
}

const useConfig = (
  inputType: Types.Type,
  child: Panel2.PanelSpecNode,
  propsConfig: PanelRowConfig | undefined
): PanelRowConfig => {
  return useMemo(() => {
    const config =
      propsConfig == null || propsConfig.pageSize == null
        ? defaultConfig(inputType, child)
        : propsConfig;
    return config;
  }, [propsConfig, inputType, child]);
};

const PanelRowConfig: React.FC<PanelRowProps> = props => {
  const {updateConfig} = props;
  const config = useConfig(props.input.path.type, props.child, props.config);
  const {pageSize} = config;
  const childConfig = useMemo(
    () => config.childConfig ?? {},
    [config.childConfig]
  );
  const convertedType = Spec.convert(props.inputType);
  if (convertedType == null) {
    throw new Error('invalid');
  }
  const updateChildConfig = useCallback(
    (newConfig: any) =>
      updateConfig({...config, childConfig: {...childConfig, ...newConfig}}),
    [updateConfig, config, childConfig]
  );
  const newInput = useMemo(() => {
    return {
      path: Op.opIndex({
        arr: props.input.path,
        index: Op.varNode('number', 'n'),
      }),
    };
  }, [props.input.path]);
  return (
    <div>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{width: 63, paddingTop: 0, flexShrink: 0}}>Page size</div>
        <div
          style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}>
          <ModifiedDropdown
            style={{marginRight: 8}}
            selection
            value={pageSize ?? 3}
            options={[1, 2, 3, 5, 10].map(o => ({
              key: o,
              value: o,
              text: o,
            }))}
            onChange={(e, {value}) => updateConfig({pageSize: value as number})}
          />
        </div>
      </div>
      <PanelComp2
        input={newInput}
        inputType={convertedType}
        loading={props.loading}
        panelSpec={props.child}
        configMode={true}
        config={childConfig}
        context={props.context}
        updateConfig={updateChildConfig}
        updateContext={props.updateContext}
      />
    </div>
  );
};

const PanelRow: React.FC<PanelRowProps> = props => {
  const {input, updateConfig, child} = props;
  const config = useConfig(props.input.path.type, child, props.config);
  const rowsNode = input.path;
  const [pageNum, setPageNum] = useState(0);
  const {pageSize} = config;
  const childConfig = useMemo(
    () => config.childConfig ?? {},
    [config.childConfig]
  );
  const updateChildConfig = useCallback(
    (newConfig: any) =>
      updateConfig({...config, childConfig: {...childConfig, ...newConfig}}),
    [updateConfig, config, childConfig]
  );
  const visibleRowsNode = useMemo(
    () => Table.getPagedRowsNode(pageSize, pageNum, rowsNode),
    [pageSize, pageNum, rowsNode]
  );
  const rowNodesUse = CGReact.useEach(visibleRowsNode as any);
  const rowNodes = rowNodesUse.result;

  return useGatedValue(
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection: 'row',
        }}>
        {_.range(pageSize).map(offset => {
          const node = rowNodes[offset];
          if (node == null) {
            return <div key={offset} style={{flex: '1 1 auto'}} />;
          }
          return (
            <div
              key={offset}
              style={{marginRight: 8, overflowX: 'auto', flex: '1 1 auto'}}>
              <PanelComp2
                input={{path: node}}
                inputType={node.type}
                loading={props.loading}
                panelSpec={props.child}
                configMode={false}
                config={childConfig}
                context={props.context}
                updateConfig={updateChildConfig}
                updateContext={props.updateContext}
                updateInput={props.updateInput}
              />
            </div>
          );
        })}
      </div>
      {(rowsNode.type.length == null || rowsNode.type.length > pageSize) && (
        <div style={{flex: '0 0 auto'}}>
          <PageControls
            rowsNode={rowsNode}
            page={pageNum}
            pageSize={pageSize}
            setPage={setPageNum}
          />
        </div>
      )}
    </div>,
    o => !rowNodesUse.loading
  );
};

export const Spec: Panel2.PanelConvertSpec = {
  id: 'row',
  displayName: 'List of',
  ConfigComponent: PanelRowConfig,
  Component: PanelRow,
  convert: (inputType: Types.Type) => {
    inputType = Types.nullableTaggableValue(inputType);
    if (Types.isListLike(inputType)) {
      return Types.listObjectType(inputType);
    }
    return null;
  },
  defaultFixedSize: (childDims, type) => ({
    width:
      childDims.width != null
        ? childDims.width * (Types.listLength(type) ?? 3) + 20
        : undefined,
    height: childDims.height != null ? childDims.height + 40 : undefined,
  }),
};
