import React, {useEffect, useRef, useState} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  OnScrollCallback,
} from 'react-virtualized';
import {withQuery, QueryResultProps, LogLineNode} from '../graphql/runLogQuery';
import AU from 'ansi_up';
import _ from 'lodash';
import WandbLoader from './WandbLoader';
import makeComp from '../util/profiler';

const minRowHeight = 16; // should match the line-height
const cache = new CellMeasurerCache({
  defaultHeight: minRowHeight,
  fixedWidth: true,
});

interface LogProps extends QueryResultProps {
  hideTimestamp?: boolean;
}

const Log: React.FC<LogProps> = makeComp(
  ({runLogQuery, hideTimestamp}) => {
    const [autoScroll, setAutoScroll] = useState(true);
    const [lines, setLines] = useState<Array<{node: LogLineNode}>>([]);

    const lastWidthRef = useRef(window.innerWidth);

    const ansiUpRef = useRef<AU | null>(null);
    if (ansiUpRef.current == null) {
      ansiUpRef.current = new AU();
    }
    const ansiUp = ansiUpRef.current;

    const listRef = useRef<List>(null);

    useEffect(() => {
      if (!runLogQuery.loading) {
        setLines(runLogQuery.logLines.edges);
      }
    }, [runLogQuery.loading, runLogQuery.logLines]);

    useEffect(() => {
      scrollToBottom();
    });

    const scrollToBottom = () => {
      const list = listRef.current;
      if (list) {
        list.forceUpdateGrid();
      }
      const lineCount = lines.length;
      if (lineCount !== 0 && autoScroll) {
        if (list) {
          list.scrollToRow(lineCount);
        }
      }
    };

    const checkAutoScroll: OnScrollCallback = e => {
      // autoScroll is enabled if current scroll position is close to the bottom
      // or if list is still empty meaning List height is zero
      const scroll = e.scrollHeight - e.clientHeight - Math.round(e.scrollTop);
      setAutoScroll(scroll < 5 || e.clientHeight === 0);
    };

    const processLine = (l: string) => {
      if (hideTimestamp) {
        l = l.substr(l.indexOf(' ') + 1);
      }
      return l.replace(/ /g, '\u00a0');
    };

    const rowRenderer: ListRowRenderer = ({key, index, style, parent}) => {
      // Only called on valid keys, since we pass rowCount in to VirtualList
      const line = lines[index];
      // TODO: CSS and other dangerous HTML injection issues
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
          minHeight={minRowHeight}>
          <div
            role="row"
            className={`logLine ${line.node.level}`}
            style={{
              ...style,
              overflowWrap: 'break-word',
            }}
            dangerouslySetInnerHTML={{
              __html:
                (index === 0 ? '<br>' : '') +
                ansiUp.ansi_to_html(processLine(line.node.line)) +
                '<br>', // ensures that empty lines have a height
            }}
          />
        </CellMeasurer>
      );
    };

    const rowCount = lines.length;
    // pass through lastLine to force update when it changes (progress bars)
    let lastLine = '';
    if (rowCount > 0) {
      lastLine = _.last(lines)!.node.line;
    }
    if (runLogQuery.loading) {
      return (
        <div className="run-logs">
          <WandbLoader />
        </div>
      );
    }
    return (
      <div className="run-logs">
        <AutoSizer>
          {({width, height}) => {
            if (width !== lastWidthRef.current) {
              // recompute row heights on window resize
              cache.clearAll();
              lastWidthRef.current = width;
            }
            return (
              <List
                ref={listRef}
                onScroll={checkAutoScroll}
                height={height}
                width={width}
                rowCount={rowCount}
                estimatedRowHeight={minRowHeight}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                lastLine={lastLine}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  },
  {id: 'Log'}
);

export default withQuery(Log);
