import '../css/HomePage.less';

import React, {FC, useEffect, useState} from 'react';
import {Query} from 'react-apollo';
import {History} from 'history';

import {
  HOME_PAGE_USER_DETAILS,
  HOME_PAGE_USER_DETAILS_BY_USER,
  HomePageQueryData,
} from '../components/HomePage/graphql';
import Loader from '../components/WandbLoader';
import HomePageWithData from './HomePageWithData';
import {QueryResult} from '@apollo/react-common';
import {useViewerRunsQuery, ViewerRunsQueryResult} from '../generated/graphql';
import {useTimer} from '../util/hooks';
import {DEFER_RUNS_QUERIES_AFTER} from './EntityPage';
import {propagateErrorsContext} from '../util/errors';
import {setDocumentTitle} from '../util/document';
import {TableRowsContextResult, useTableRowsContext} from '../util/userContext';
import makeComp from '../util/profiler';

type RenderHomePage = (
  result: QueryResult<HomePageQueryData>
) => JSX.Element | null;

interface HomePageWithLoaderProps extends QueryResult<HomePageQueryData> {
  runsQuery: ViewerRunsQueryResult;
  deferRunsQuery: boolean;
  tableRowsContext: TableRowsContextResult;
  history: History;
}

const HomePageWithLoader: FC<HomePageWithLoaderProps> = makeComp(
  ({
    runsQuery,
    deferRunsQuery,
    tableRowsContext,
    history,
    loading,
    error,
    data,
    refetch,
  }) => {
    const waitingForRunsQuery = runsQuery.loading && !deferRunsQuery;
    if (error) {
      return <div>`Error!: ${error}`</div>;
    }
    if (
      loading ||
      tableRowsContext.loading ||
      data == null ||
      waitingForRunsQuery
    ) {
      return <Loader />;
    } else {
      return (
        <HomePageWithData
          history={history}
          data={data.viewer}
          refetch={refetch}
          runsQuery={runsQuery}
          tableRowsContext={tableRowsContext}
        />
      );
    }
  },
  {id: 'HomePageWithLoader', memo: true}
);

interface HomePageProps {
  history: History;
}

const HomePageNew: FC<HomePageProps> = makeComp(
  ({history}) => {
    useHomePageEffect();
    const runsQuery = useViewerRunsQuery({
      fetchPolicy: 'network-only',
      context: propagateErrorsContext(),
    });
    const deferRunsQuery = useDeferRunsQuery();
    const tableRowsContext = useTableRowsContext();
    return (
      <HomePageQuery>
        {(queryResults: QueryResult<HomePageQueryData>) => (
          <HomePageWithLoader
            runsQuery={runsQuery}
            deferRunsQuery={deferRunsQuery}
            tableRowsContext={tableRowsContext}
            history={history}
            {...queryResults}
          />
        )}
      </HomePageQuery>
    );
  },
  {id: 'HomePageNew', memo: true}
);

export default HomePageNew;

interface HomePageForUserProps {
  match: {
    params: {
      userName: string;
    };
  };
  history: History;
}

export const HomePageForUser: FC<HomePageForUserProps> = makeComp(
  ({match, history}) => {
    useHomePageEffect();
    const userName = match.params.userName;
    const runsQuery = useViewerRunsQuery({
      variables: {entityName: userName},
      context: propagateErrorsContext(),
    });
    const deferRunsQuery = useDeferRunsQuery();
    const tableRowsContext = useTableRowsContext();
    return (
      <HomePageForUserQuery userName={userName}>
        {(queryResults: QueryResult<HomePageQueryData>) => (
          <HomePageWithLoader
            runsQuery={runsQuery}
            deferRunsQuery={deferRunsQuery}
            tableRowsContext={tableRowsContext}
            history={history}
            {...queryResults}
          />
        )}
      </HomePageForUserQuery>
    );
  },
  {id: 'HomePageForUser', memo: true}
);

interface HomePageQueryProps {
  children: RenderHomePage;
}

const HomePageQuery: FC<HomePageQueryProps> = makeComp(
  ({children}) => {
    return (
      <Query<HomePageQueryData>
        query={HOME_PAGE_USER_DETAILS}
        fetchPolicy="network-only">
        {children}
      </Query>
    );
  },
  {id: 'HomePageQuery', memo: true}
);

const HomePageForUserQuery: FC<
  HomePageQueryProps & {
    userName: string;
  }
> = makeComp(
  ({userName, children}) => {
    return (
      <Query<HomePageQueryData>
        query={HOME_PAGE_USER_DETAILS_BY_USER}
        variables={{userName}}>
        {children}
      </Query>
    );
  },
  {id: 'HomePageForUserQuery', memo: true}
);

const useHomePageEffect = () => {
  useEffect(() => {
    setDocumentTitle('Home');
  }, []);
};

export const useDeferRunsQuery = (): boolean => {
  const [deferRunsQuery, setDeferRunsQuery] = useState(false);
  useTimer(() => setDeferRunsQuery(true), DEFER_RUNS_QUERIES_AFTER);
  return deferRunsQuery;
};
