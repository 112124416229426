import * as S from './panel-grids.styles';

import React, {useCallback, useMemo} from 'react';
import {BlockWrapper} from './drag-drop';
import {Element, Node, Transforms, Path, Editor} from 'slate';
import {
  RenderElementProps,
  useReadOnly,
  useEditor,
  ReactEditor,
  useSelected,
} from 'slate-react';
import * as SectionTypes from '../../../state/views/section/types';
import {useSelector} from '../../../state/hooks';
import {useDispatch} from 'react-redux';
import {
  addNormalizedPanelGrid,
  removeNormalizedPanelGrid,
} from '../../../state/views/actionsInternal';
import {normalize} from '../../../state/views/normalize';
import {useWhole} from '../../../state/views/hooks';
import {WBSlateReduxBridgeContext} from '../WBSlateReduxBridge';
import {captureError} from '../../../util/integrations';
import errorImage from '../../../assets/il-question-plant.svg';
import {ErrorBoundary} from '../../ErrorBoundary';

export interface PanelGrid extends Element {
  type: 'panel-grid';
  metadata: SectionTypes.Config;
}

export const isPanelGrid = (node: Node): node is PanelGrid =>
  node.type === 'panel-grid';

/**
 * This is an insane, indecipherable state machine, and I will
 * forget how it works in a couple weeks. So I hope it doesn't break.
 * The complexity comes from the fact that panel grids (ReportSection.tsx)
 * expect refs and make their updates through our normalized Redux architecture.
 * So we need to make a normalized copy of the panel grid's metadata.
 * We then need to mirror changes made to the normalized copy
 * onto the denormalized copy. Simple enough. But there's a catch:
 * when the user hits undo, a change is made only to the denormalized copy.
 * We then need to renormalize to update what's actually displayed.
 * This is prone to infinite loops, which the state machine is designed to prevent.
 */
export const PanelGridElement: React.FC<
  RenderElementProps & {
    element: PanelGrid;
  }
> = ({attributes, element, children}) => {
  const {viewRef} = React.useContext(WBSlateReduxBridgeContext);
  const editor = useEditor();
  const dispatch = useDispatch();
  const {metadata} = element;
  const updateDenormalizedOnNormalizedUpdate = React.useRef(true);
  const renormalizeOnDenormalizedUpdate = React.useRef(true);
  const partRefRef = React.useRef<SectionTypes.Ref | null>(null);

  /** On an undo, renormalize. */
  React.useEffect(() => {
    if (renormalizeOnDenormalizedUpdate.current) {
      updateDenormalizedOnNormalizedUpdate.current = false;

      if (partRefRef.current != null) {
        dispatch(removeNormalizedPanelGrid(viewRef.id, partRefRef.current));
      }

      const {partRef, partsWithRefs} = normalize(
        'section',
        viewRef.id,
        metadata
      );

      partRefRef.current = partRef;
      dispatch(addNormalizedPanelGrid(viewRef.id, partsWithRefs));
    }
    renormalizeOnDenormalizedUpdate.current = true;
  }, [dispatch, metadata, viewRef.id]);

  /** On dismount, remove from redux. */
  React.useEffect(() => {
    return () => {
      dispatch(removeNormalizedPanelGrid(viewRef.id, partRefRef.current));
    };
  }, [dispatch, viewRef.id]);

  const loadedIntoRedux = useSelector(
    state =>
      partRefRef.current != null &&
      state.views.parts.section[partRefRef.current.id] != null
  );

  return (
    <BlockWrapper attributes={attributes} element={element} disableClickSelect>
      <S.PanelGridWrapper
        onMouseUp={e => {
          Transforms.deselect(editor);
        }}>
        <div contentEditable={false}>
          {partRefRef.current != null && loadedIntoRedux && (
            <PanelGridElementInner
              updateDenormalizedOnNormalizedUpdate={
                updateDenormalizedOnNormalizedUpdate
              }
              renormalizeOnDenormalizedUpdate={renormalizeOnDenormalizedUpdate}
              element={element}
              partRef={partRefRef.current}></PanelGridElementInner>
          )}
        </div>
        {children}
      </S.PanelGridWrapper>
    </BlockWrapper>
  );
};

const PanelGridElementInner: React.FC<{
  element: PanelGrid;
  partRef: SectionTypes.Ref;
  updateDenormalizedOnNormalizedUpdate: React.MutableRefObject<boolean>;
  renormalizeOnDenormalizedUpdate: React.MutableRefObject<boolean>;
}> = ({
  element,
  partRef,
  updateDenormalizedOnNormalizedUpdate,
  renormalizeOnDenormalizedUpdate,
  children,
}) => {
  const {projectName, entityName, viewRef, panelSettingsRef} = React.useContext(
    WBSlateReduxBridgeContext
  );
  const selected = useSelected();
  const whole = useWhole(partRef);
  const readOnly = useReadOnly();
  const editor = useEditor();

  const pathRef = React.useRef<Path>(ReactEditor.findPath(editor, element));
  pathRef.current = ReactEditor.findPath(editor, element);

  /** On a normal change, quietly make a saved change. */
  React.useEffect(() => {
    if (updateDenormalizedOnNormalizedUpdate.current) {
      renormalizeOnDenormalizedUpdate.current = false;
      Transforms.setNodes(
        editor,
        {metadata: whole},
        {
          at: pathRef.current,
        }
      );
    }
    updateDenormalizedOnNormalizedUpdate.current = true;
  }, [
    editor,
    pathRef,
    renormalizeOnDenormalizedUpdate,
    updateDenormalizedOnNormalizedUpdate,
    whole,
  ]);

  // logics for ErrorBoundary wrapping panel grids
  const errorParams = useMemo(
    () => ({entityName, projectName}),
    [entityName, projectName]
  );

  const logError = useCallback(
    (error: any) => {
      const key = readOnly ? 'PanelGridView' : 'PanelGridEdit';
      window.analytics.track(`${key} error`, {
        errorParams,
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
      });
      captureError(error, `${key} ErrorBoundary error`);
    },
    [errorParams, readOnly]
  );

  const renderPanelGridError = useCallback(
    () => (
      <S.PanelGridErrorWrapper>
        <S.ErrorImageWrapper src={errorImage} alt="confused plant" />
        <S.ErrorMessageWrapper>
          <S.ErrorHeaderWrapper>
            Oops, these panels didn't load properly.
          </S.ErrorHeaderWrapper>
          If this keeps happening, contact{' '}
          <S.ErrorContactAnchor
            href="mailto:support@wandb.com"
            target="_blank"
            rel="noopener noreferrer">
            support@wandb.com
          </S.ErrorContactAnchor>{' '}
          with a link to this page.
        </S.ErrorMessageWrapper>
      </S.PanelGridErrorWrapper>
    ),
    []
  );

  return (
    <ErrorBoundary renderError={renderPanelGridError} onError={logError}>
      <S.PanelGrid
        selected={selected}
        entityName={entityName}
        projectName={projectName}
        panelSettingsRef={panelSettingsRef}
        sectionRef={partRef}
        viewRef={viewRef}
        readOnly={readOnly}></S.PanelGrid>
    </ErrorBoundary>
  );
};

export const withPanelGrids = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => (isPanelGrid(element) ? true : isVoid(element));

  return editor;
};
