import gql from 'graphql-tag';
import {useEffect, useMemo, useState, useRef} from 'react';

import * as Filter from '../../util/filters';
import * as QueryTS from '../../util/queryts';
import {useQuery} from './query';
import {useDispatch} from '../hooks';
import {RunHistoryKeyInfo} from '../../types/run';
import * as Generated from '../../generated/graphql';
import * as RunsLowActions from '../runs-low/actions';
import {ID} from '@wandb/cg/browser/utils/string';

export interface HistoryKeyInfoQueryVars {
  entityName: string;
  projectName: string;
  filters: Filter.Filter;
  sort: QueryTS.Sort;
}

export const HISTORY_KEYS_QUERY = gql`
  query HistoryKeys(
    $projectName: String!
    $entityName: String!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        historyKeys
        edges {
          node {
            id
            wandbConfig(keys: ["viz", "visualize"])
          }
        }
      }
    }
  }
`;

interface Data {
  project: {
    runs: {
      historyKeys: RunHistoryKeyInfo;
      edges: Array<{
        node: {
          wandbConfig?: string;
        };
      }>;
    };
  };
}

// For really big result sets, we set a much longer interval
const LONG_POLL_KEYS_THRESHOLD = 1000;

interface VizMap {
  [key: string]: any;
}

function mergeViz(viz: Array<string | undefined>): VizMap {
  const res: VizMap = {};
  viz.forEach(vs => {
    if (vs == null) {
      return;
    }
    const parsed = JSON.parse(vs);
    if (parsed.viz == null && parsed.visualize == null) {
      return;
    }
    const v = parsed.viz;
    if (v != null) {
      Object.keys(v).forEach(k => {
        if (res[k] == null) {
          res[k] = v[k];
        }
      });
    }

    const visualize = parsed.visualize;
    if (visualize != null) {
      Object.keys(visualize).forEach(k => {
        if (res[k] == null) {
          res[k] = visualize[k];
        }
      });
    }
  });
  return res;
}

type UseHistoryKeysQueryResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | {
      loading: false;
      error: null;
      historyKeyInfo: RunHistoryKeyInfo;
      viz: VizMap;
    };

// This query is polled, respecting the user polling settings and the page poll
// interval (stored in redux). If the query is expensive (determined by a simple
// threshold on the result size), we raise the poll interval to a much larger
// value, to reduce network overhead for users.
export function useHistoryKeysQuery(
  queryVars: HistoryKeyInfoQueryVars
): UseHistoryKeysQueryResult {
  const {entityName, projectName, filters, sort} = queryVars;
  const dispatch = useDispatch();
  const idRef = useRef(ID());
  const id = idRef.current;
  const [pollMultiplier, setPollMultiplier] = useState<number>(1);

  const query = useQuery<Data, Generated.HistoryKeysQueryVariables>(
    Generated.HistoryKeysDocument,
    {
      variables: {
        entityName,
        projectName,
        filters: JSON.stringify(Filter.toMongo(filters)),
        order: QueryTS.sortToOrderString(sort),
      },
      enablePolling: true,
      pollMultiplier,
    }
  );

  const initialLoading = query.initialLoading;
  const project = query.initialLoading ? null : query.project;
  const historyKeyInfo = project?.runs.historyKeys;

  useEffect(() => {
    if (!initialLoading && historyKeyInfo != null) {
      const numKeys = Object.keys(historyKeyInfo.keys).length;
      setPollMultiplier(numKeys > LONG_POLL_KEYS_THRESHOLD ? 10 : 1);
    }
  }, [initialLoading, historyKeyInfo]);

  // Mirror history key info into redux so runs-low can use it for
  // query merging
  useEffect(() => {
    if (!initialLoading && historyKeyInfo != null) {
      dispatch(
        RunsLowActions.updateHistoryKeyInfo(id, queryVars, historyKeyInfo)
      );
    }
  }, [id, dispatch, queryVars, initialLoading, historyKeyInfo]);
  // remove history key info from redux on unmount
  useEffect(
    () => () => {
      dispatch(RunsLowActions.clearHistoryKeyInfo(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useMemo(() => {
    if (initialLoading) {
      return {loading: true, error: null};
    }
    if (project == null) {
      return {
        loading: false,
        error: true,
      };
    }
    return {
      loading: false,
      error: null,
      historyKeyInfo: project.runs.historyKeys,
      viz: mergeViz(project.runs.edges.map(e => e.node.wandbConfig)),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading, project]);
}
