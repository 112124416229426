import '../css/EditLaunchConfigModal.less';
import * as S from './EditLaunchConfigModal.styles';

import * as _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Dropdown, DropdownItemProps, Modal} from 'semantic-ui-react';
import Editor from './Monaco/Editor';
import {projectRunQueue, run} from '../util/urls';
import WandbLoader from './WandbLoader';
import {
  CreateRunQueueMutation,
  CreateRunQueueMutationVariables,
  RunQueueAccessType,
  useCreateRunQueueMutation,
  useFetchRunQueuesFromProjectQuery,
  usePushToRunQueueMutation,
  useRunArtifactVersionsQuery,
  useRunInfoAndConfigQuery,
  useUserProjectsQuery,
  useEntityArtifactsQuery,
  useProjectPageQuery,
} from '../generated/graphql';
import {getProjectsFromUserProjectsQuery} from './CreateReportModal';
import {DEFAULT_RUN_QUEUE_NAME} from './RunQueueTab';
import makeComp from '../util/profiler';
import {backendHost} from '../config';
import {useViewer} from '../state/viewer/hooks';
import {ExecutionResult} from 'react-apollo';
import {
  CancellationToken,
  languages,
  editor,
  IPosition,
  Position,
  IDisposable,
} from 'monaco-editor';
import {isNotNullOrUndefined} from '../util/types';

interface EditLaunchConfigModalProps {
  entityName: string;
  projectName: string;
  runName: string;
  onClose: () => void;
}

export interface CreateRunQueueArgs {
  variables: CreateRunQueueMutationVariables;
}

type LaunchDropdown = {
  key: string;
  label: string;
  options: DropdownItemProps[];
  value?: string;
  setValue: (v: string) => void;
  placeholder?: string;
};

interface ProjectData {
  id: string;
  name: string;
  entity: {
    id: string;
    name: string;
  };
}
interface InputArtifactsNode {
  artifactType: {
    id: string;
    name: string;
    project: ProjectData;
  };
  id: string;
  digest: string;
  commitHash?: string | null;
  versionIndex?: number | null;
  artifactSequence: {
    id: string;
    name: string;
    project: ProjectData;
  };
  aliases: Array<{
    artifactCollectionName: string;
    alias: string;
  }>;
  usedBy: {
    totalCount: number;
  };
}

export async function createRunQueueByName(
  createRunQueue: (
    variables: CreateRunQueueArgs
  ) => Promise<ExecutionResult<CreateRunQueueMutation>>,
  pushQueue: string,
  targetEntity: string,
  targetProject: string
) {
  const {data} = await createRunQueue({
    variables: {
      entityName: targetEntity,
      projectName: targetProject,
      queueName: pushQueue,
      access:
        pushQueue === DEFAULT_RUN_QUEUE_NAME
          ? RunQueueAccessType.Project
          : RunQueueAccessType.User,
    },
  });
  if (!data?.createRunQueue?.success || data?.createRunQueue?.queueID == null) {
    return;
  }

  return data?.createRunQueue?.queueID;
}

const cleanConfig = (config: {[key: string]: any}) => {
  _.forEach(config, (v, k) => {
    if (_.isObject(v) && 'value' in v) {
      config[k] = config[k].value;
    } else if (k.includes('.desc')) {
      delete config[k];
    } else if (_.isObject(v)) {
      config[k] = cleanConfig(v);
    }
  });
  return config;
};

const stripArtifactLinksFromSpecRecursively = (spec: {[key: string]: any}) => {
  Object.keys(spec).forEach(key => {
    const val = spec[key];
    if (_.isObject(val)) {
      stripArtifactLinksFromSpecRecursively(spec[key]);
    } else if (typeof val === 'string' && val.startsWith('@')) {
      delete spec[key];
    }
  });
};

const insertArtifactLinksRecursive = (
  spec: {[key: string]: any},
  usedArtisInfo: UsedArtifactInfo[],
  configArtifactStrings: Set<string>
) => {
  Object.keys(spec).forEach(key => {
    if (!_.isObject(spec[key])) {
      return;
    }
    if (spec[key]._type === 'artifactVersion') {
      const configValue = spec[key];
      const arti = usedArtisInfo.find(ai => {
        return (
          ai.sequenceName === configValue.sequenceName &&
          ai.id === configValue.id &&
          ai.version === configValue.version &&
          ai.label === configValue.usedAs
        );
      });
      if (arti != null) {
        spec[key] = `@artifacts:${arti.label}`;
        configArtifactStrings.add(`@artifacts:${arti.label}`);
      } else if (arti == null) {
        // if there is no match, just show it in the config as unfound
        // this could happen if a user sticks a wandb.Artifact
        // in the config
        spec[
          key
        ] = `@unusedArtifacts:${configValue.name}:${configValue.version}`;
      }
    } else {
      insertArtifactLinksRecursive(
        spec[key],
        usedArtisInfo,
        configArtifactStrings
      );
    }
  });
};

const nodeToArtiInfo = (node: InputArtifactsNode) => ({
  name: `Artifact/${node.artifactSequence.name}:v${node.versionIndex}`,
  entity: node.artifactSequence.project.entity.name,
  project: node.artifactSequence.project.name,
  version: `v${node.versionIndex}`,
  id: node.id,
  sequenceName: node.artifactSequence.name,
});

const swapArtifactsForArtifactSpec = (
  spec: {[key: string]: any},
  artiDict: {[key: string]: ArtifactInfos},
  targetEntity: string
) => {
  if ('overrides' in spec && 'artifacts' in spec.overrides) {
    Object.keys(spec.overrides.artifacts).forEach(key => {
      const val = spec.overrides.artifacts[key];
      if (typeof val === 'string' && val.startsWith('Artifact/')) {
        spec.overrides.artifacts[key] = {
          project: artiDict[val].project,
          name: artiDict[val].name,
          entity: targetEntity,
          id: artiDict[val].version,
          _version: 'v0',
        };
      }
    });
  }
  return spec;
};

const stripArtifactLinksFromSpec = (spec: {[key: string]: any}) => {
  if (
    'overrides' in spec &&
    'run_config' in spec.overrides &&
    _.isObject(spec.overrides.run_config)
  ) {
    stripArtifactLinksFromSpecRecursively(spec.overrides.run_config);
  }

  return spec;
};

interface ArtifactInfos {
  name: string;
  entity: string;
  project: string;
  label: string;
  version: string;
}

interface UsedArtifactInfo {
  label: string;
  name: string;
  entity: string;
  project: string;
  version: string;
  id: string;
  sequenceName: string;
}

const getCompletionValues = (
  dict: {[key: string]: ArtifactInfos},
  model: editor.ITextModel,
  position: IPosition
) => {
  const items: languages.CompletionItem[] = [];
  Object.keys(dict).forEach(key => {
    items.push({
      filterText: key,
      label: key,
      insertText: key,
      kind: languages.CompletionItemKind.Function,
      range: {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        endColumn: model.getWordUntilPosition(position).endColumn,
        startColumn: model.getWordUntilPosition(position).startColumn,
      },
    });
  });
  return items;
};

const getArtifactLinks = (
  links: Set<string> | undefined,
  model: editor.ITextModel
) => {
  const items: languages.ILinksList = {links: []};
  if (links == null) {
    return items;
  }
  links.forEach(link => {
    const searchOnlyEditableRange = false;
    const isRegex = false;
    const matchCase = true;
    const wordSeperators = null;
    const captureMatches = false;
    const matches = model.findMatches(
      link,
      searchOnlyEditableRange,
      isRegex,
      matchCase,
      wordSeperators,
      captureMatches
    );
    matches.forEach(match => {
      items.links.push({
        range: {
          startLineNumber: match.range.startLineNumber,
          endLineNumber: match.range.endLineNumber,
          endColumn: match.range.endColumn,
          startColumn: match.range.startColumn,
        },
      });
    });
  });
  return items;
};

const EditLaunchConfigModal: React.FC<EditLaunchConfigModalProps> = makeComp(
  ({entityName, projectName, runName, onClose}) => {
    const [createRunQueue] = useCreateRunQueueMutation();
    const [pushToRunQueue] = usePushToRunQueueMutation();
    const [launchConfig, setLaunchConfig] = useState<string | null>(null);
    const [pushQueue, setPushQueue] = useState<string>(DEFAULT_RUN_QUEUE_NAME);
    const [pushing, setPushing] = useState(false);
    const [usedArtifactStrings, setUsedArtifactStrings] =
      useState<Set<string>>();
    const [targetProject, setTargetProject] = useState<string | undefined>(
      projectName
    );
    const [completionDisposable, setCompletionDisposable] =
      useState<IDisposable | null>(null);

    const viewer = useViewer();
    const history = useHistory();
    const [targetEntity, setTargetEntity] = useState<string>(viewer?.username!);

    const [editedConfig, setEditedConfig] = useState(false);
    const [editedTargetEntity, setEditedTargetEntity] = useState(false);
    const [editedTargetProject, setEditedTargetProject] = useState(false);
    const [editedPushQueue, setEditedPushQueue] = useState(false);

    const projectQuery = useProjectPageQuery({
      variables: {
        entityName: targetEntity,
        projectName: targetProject ?? 'uncategorized',
      },
      skip: targetProject == null,
    });

    const runInfoAndConfig = useRunInfoAndConfigQuery({
      variables: {
        projectName,
        entityName,
        runName,
      },
    });

    const disposeProvider = (provider: IDisposable) => {
      provider.dispose();
      setCompletionDisposable(null);
    };

    const onModalClose = () => {
      if (completionDisposable != null) {
        disposeProvider(completionDisposable);
      }

      onClose();
    };

    const entityArtifactsQuery = useEntityArtifactsQuery({
      variables: {entityName: targetEntity},
    });

    const namesAndAliases: {[key: string]: ArtifactInfos} = useMemo(() => {
      const namesAndAliasesCollector: {[key: string]: ArtifactInfos} = {};
      if (entityArtifactsQuery.data == null) {
        return {};
      }
      entityArtifactsQuery.data.entity?.projects?.edges.forEach(projectEdge => {
        const artifactProjectName = projectEdge.node?.name;
        if (artifactProjectName == null) {
          return;
        }
        projectEdge.node?.artifactTypes.edges.forEach(artifactTypeEdge => {
          artifactTypeEdge.node?.artifactSequences?.edges.forEach(
            artifactSequenceEdge => {
              const artifactSequenceName = artifactSequenceEdge.node?.name;
              artifactSequenceEdge.node?.artifacts.edges.forEach(
                artifactsEdge => {
                  const artifactId = artifactsEdge.node.id;
                  artifactsEdge.node.aliases.forEach(alias => {
                    const aliasName = `${artifactSequenceName}:${alias.alias}`;
                    const aliasLabel = `Artifact/${aliasName}`;
                    namesAndAliasesCollector[aliasLabel] = {
                      label: aliasLabel,
                      name: aliasName,
                      project: artifactProjectName,
                      entity: targetEntity,
                      version: artifactId,
                    };
                  });
                  const versionName = `${artifactSequenceName}:v${artifactsEdge.node.versionIndex}`;
                  const versionLabel = `Artifact/${versionName}`;
                  namesAndAliasesCollector[versionLabel] = {
                    label: versionLabel,
                    name: versionName,
                    project: artifactProjectName,
                    entity: targetEntity,
                    version: artifactId,
                  };
                }
              );
            }
          );
        });
      });
      return namesAndAliasesCollector;
    }, [entityArtifactsQuery, targetEntity]);

    const runArtifacts = useRunArtifactVersionsQuery({
      variables: {
        entityName,
        projectName,
        runName,
        includeOutput: false,
        includeInput: true,
        pageSize: 100,
      },
      fetchPolicy: 'cache-and-network',
    });
    const usedArtisInfo: UsedArtifactInfo[] = useMemo(() => {
      return _.flatten(
        runArtifacts.data?.project?.run?.inputArtifacts?.edges.map(e => {
          if (e.usedAs.length > 0) {
            return e.usedAs.map(ua => {
              if (e.node != null) {
                return {
                  label: ua,
                  ...nodeToArtiInfo(e.node),
                };
              }
              return null;
            });
          } else if (e.node != null) {
            return {
              label: `${e.node.artifactSequence.name}:v${e.node.versionIndex}`,
              ...nodeToArtiInfo(e.node),
            };
          }
          return null;
        })
      ).filter(isNotNullOrUndefined);
    }, [runArtifacts.data]);

    const usedArtisMap = useMemo(() => {
      if (usedArtisInfo != null) {
        const mapped = usedArtisInfo.map(ai => ({[ai.label]: ai.name}));
        return Object.assign({}, ...mapped);
      } else {
        return {};
      }
    }, [usedArtisInfo]);
    const editLaunchConfig = (val: string) => {
      if (!editedConfig) {
        setEditedConfig(true);
        window.analytics.track('In launch config editor, edited config', {
          entity: viewer?.entity,
          sourceEntity: entityName,
          sourceProject: projectName,
        });
      }
      setLaunchConfig(val);
    };

    const changeTargetEntity = (newTargetEntity: string) => {
      if (!editedTargetEntity) {
        setEditedTargetEntity(true);
        window.analytics.track(
          'In launch config editor, edited target entity',
          {
            entity: viewer?.entity,
            sourceEntity: entityName,
            targetEntity: newTargetEntity,
          }
        );
      }
      setTargetEntity(newTargetEntity);
    };

    const changeTargetProject = (newTargetProject: string) => {
      if (!editedTargetProject) {
        setEditedTargetProject(true);
        window.analytics.track(
          'In launch config editor, edited target project',
          {
            entity: viewer?.entity,
            sourceEntity: entityName,
            sourceProject: projectName,
            targetProject: newTargetProject,
          }
        );
      }
      setTargetProject(newTargetProject);
    };

    const changePushQueue = (queueName: string) => {
      if (!editedPushQueue) {
        setEditedPushQueue(true);
        window.analytics.track('In launch config editor, edited target queue', {
          entity: viewer?.entity,
          sourceEntity: entityName,
          sourceProject: projectName,
          queueName,
        });
      }
      setPushQueue(queueName);
    };

    const userProjectsQueryResult = useUserProjectsQuery({
      variables: {
        userName: viewer?.username ?? '',
        includeReports: true,
      },
      fetchPolicy: 'cache-and-network',
      skip: viewer == null,
    });

    const projects = useMemo(() => {
      return getProjectsFromUserProjectsQuery(userProjectsQueryResult);
    }, [userProjectsQueryResult]);

    useEffect(() => {
      if (
        runInfoAndConfig.loading ||
        runInfoAndConfig.error ||
        runArtifacts.loading ||
        runArtifacts.error
      ) {
        return;
      }

      let runConfig: any = {};
      try {
        runConfig = JSON.parse(runInfoAndConfig.data?.project?.run?.config);
      } catch {
        console.log('failed to fetch config or config malformed');
      }

      const configArtifactStrings: Set<string> = new Set();
      const filteredRunConfig = _.omit(cleanConfig(runConfig), '_wandb');
      insertArtifactLinksRecursive(
        filteredRunConfig,
        usedArtisInfo,
        configArtifactStrings
      );

      setUsedArtifactStrings(configArtifactStrings);

      const shownLaunchConfig = JSON.stringify(
        {
          overrides: {
            args: runInfoAndConfig.data?.project?.run?.runInfo?.args ?? [],
            ...(filteredRunConfig !== {} && {run_config: filteredRunConfig}),
            ...(usedArtisInfo != null &&
              usedArtisInfo?.length > 0 && {artifacts: usedArtisMap}),
          },
        },
        null,
        2
      );
      setLaunchConfig(shownLaunchConfig);
    }, [
      entityName,
      projectName,
      runInfoAndConfig,
      runArtifacts,
      usedArtisInfo,
      usedArtisMap,
    ]);

    const projectNameOptions = useMemo(
      () =>
        projects
          .filter(proj => proj.entityName === targetEntity)
          .map(proj => proj.name),
      [targetEntity, projects]
    );

    useEffect(() => {
      if (
        targetProject != null &&
        projectNameOptions.length > 0 &&
        !projectNameOptions.includes(targetProject)
      ) {
        setTargetProject(undefined);
      }
    }, [targetEntity, projectNameOptions, targetProject]);

    // reset the provided artifacts when the entity changes
    useEffect(() => {
      if (entityArtifactsQuery.loading) {
        if (completionDisposable != null) {
          disposeProvider(completionDisposable);
        }
      }
    }, [completionDisposable, entityArtifactsQuery.loading]);

    const useFetchRunQueuesFromProjectQueryResult =
      useFetchRunQueuesFromProjectQuery({
        variables: {
          entityName: targetEntity,
          projectName: targetProject ?? 'uncategorized',
        },
      });

    const pushQueueNameIdMap = Object.fromEntries(
      useFetchRunQueuesFromProjectQueryResult.data?.project?.runQueues?.map(
        runQueue => {
          return [runQueue.name, runQueue.id];
        }
      ) ?? []
    );

    const projectOptions = projectNameOptions.map(name => {
      return {
        key: name,
        text: name,
        value: name,
      };
    });

    const entityOptions =
      [...new Set(projects.map(proj => proj.entityName))].map(ent => {
        return {
          key: ent,
          text: ent,
          value: ent,
        };
      }) ?? [];

    const runQueueOptions = Object.keys(pushQueueNameIdMap).map(name => {
      return {
        key: name,
        text: name,
        value: name,
      };
    });

    // first option should be default
    if (pushQueueNameIdMap[DEFAULT_RUN_QUEUE_NAME] == null) {
      runQueueOptions.unshift({
        key: DEFAULT_RUN_QUEUE_NAME,
        text: DEFAULT_RUN_QUEUE_NAME,
        value: DEFAULT_RUN_QUEUE_NAME,
      });
    } else if (runQueueOptions[0].key !== DEFAULT_RUN_QUEUE_NAME) {
      const initialIndex = runQueueOptions.findIndex(
        option => option.key === DEFAULT_RUN_QUEUE_NAME
      );

      const defaultRunQueue = runQueueOptions.splice(initialIndex, 1)[0];
      runQueueOptions.splice(0, 0, defaultRunQueue);
    }

    const pushRunToQueue = async () => {
      window.analytics.track('In launch config editor, clicked push run', {
        entity: viewer?.entity,
        targetEntity,
        targetProject,
      });
      if (
        launchConfig == null ||
        pushing ||
        runInfoAndConfig.data == null ||
        entityArtifactsQuery.data == null ||
        targetProject == null
      ) {
        return;
      }
      setPushing(true);
      let queueID: string | undefined = pushQueueNameIdMap[pushQueue];
      if (queueID == null) {
        queueID = await createRunQueueByName(
          createRunQueue,
          pushQueue,
          targetEntity,
          targetProject
        );
        if (queueID == null) {
          alert('Run Queue creation failed');
          setPushing(false);
          return;
        }
      }

      const swappedSpec = swapArtifactsForArtifactSpec(
        JSON.parse(launchConfig),
        namesAndAliases,
        targetEntity
      );

      const strippedAndSwappedSpec = stripArtifactLinksFromSpec(swappedSpec);
      await pushToRunQueue({
        variables: {
          queueID,
          runSpec: JSON.stringify({
            ...strippedAndSwappedSpec,
            uri: backendHost() + run({entityName, projectName, name: runName}),
            project: targetProject,
            entity: targetEntity,
            resource: 'local',
          }),
        },
      });
      if (completionDisposable != null) {
        disposeProvider(completionDisposable);
      }

      await projectQuery.refetch();

      history.push(
        projectRunQueue({entityName: targetEntity, name: targetProject!})
      );
    };

    const launchDropDowns: LaunchDropdown[] = [
      {
        key: 'entityDropdown',
        label: 'Entity:',
        options: entityOptions,
        value: targetEntity,
        setValue: changeTargetEntity,
      },
      {
        key: 'projectDropdown',
        label: 'Project:',
        options: projectOptions,
        value: targetProject,
        setValue: changeTargetProject,
        placeholder: 'Select project',
      },
      {
        key: 'runQueueDropdown',
        label: 'Queue:',
        options: runQueueOptions,
        value: pushQueue,
        setValue: changePushQueue,
      },
    ];
    const renderLaunchDropdown = (d: LaunchDropdown) => (
      <S.DropdownDiv key={d.key}>
        <S.Label>{d.label}</S.Label>
        <Dropdown
          options={d.options}
          selection
          search
          value={d.value}
          placeholder={d.placeholder}
          onChange={(e, {value}) => {
            d.setValue(value as string);
          }}
        />
      </S.DropdownDiv>
    );

    return (
      <Modal
        open={true}
        className="edit-run-config-modal"
        onClose={onModalClose}>
        <Modal.Header>Modify your launch config</Modal.Header>
        <Modal.Content>
          <div className="editor-wrapper">
            {launchConfig == null ||
            runInfoAndConfig.data == null ||
            // the editor needs to remount everytime this query is made
            // to load the new entities artifacts
            entityArtifactsQuery.data == null ? (
              <WandbLoader />
            ) : (
              <Editor
                value={launchConfig}
                onChange={editLaunchConfig}
                height={500}
                language="json"
                theme="vs-dark"
                editorDidMount={(
                  monacoEditor: editor.IStandaloneCodeEditor
                ) => {
                  setCompletionDisposable(
                    languages.registerCompletionItemProvider('json', {
                      provideCompletionItems: (
                        model: editor.ITextModel,
                        position: Position
                      ) => {
                        const items = getCompletionValues(
                          namesAndAliases,
                          model,
                          position
                        );
                        return {suggestions: items};
                      },
                    })
                  );
                  languages.registerLinkProvider('json', {
                    provideLinks: (
                      model: editor.ITextModel,
                      token: CancellationToken
                    ) => getArtifactLinks(usedArtifactStrings, model),
                  });
                }}
              />
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <S.Dropdowns>{launchDropDowns.map(renderLaunchDropdown)}</S.Dropdowns>
          <Button
            size="tiny"
            disabled={pushing}
            onClick={() => {
              window.analytics.track(
                'In launch config editor, clicked cancel',
                {
                  entity: viewer?.entity,
                  sourceEntity: entityName,
                  sourceProject: projectName,
                }
              );
              onModalClose();
            }}>
            Cancel
          </Button>
          <Button
            size="tiny"
            primary
            disabled={pushing || targetProject == null}
            loading={pushing}
            onClick={pushRunToQueue}>
            Push Run
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'EditLaunchConfigModal'}
);

export default EditLaunchConfigModal;
