import _ from 'lodash';
import React, {useState, useCallback, useEffect} from 'react';
import {Grid} from 'semantic-ui-react';

import WandbLoader from '../components/WandbLoader';
import * as RunSystemMetricsQuery from '../graphql/runSystemMetricsQuery';
import {color} from '../util/colors';
import {Line, linesFromSystemMetricsPlot} from '../util/plotHelpers';
import makeComp from '../util/profiler';

import LinePlot from './vis/LinePlot';
import useResizeObserver from 'use-resize-observer';

const RunSystemMetrics: React.FC<RunSystemMetricsQuery.QueryResultProps> =
  makeComp(
    ({runSystemMetricsQuery}) => {
      const [initialLoad, setInitialLoad] = useState(true);
      const [metrics, setMetrics] = useState<string[]>([]);
      const elementRef = React.useRef<HTMLDivElement>(null);
      const {width = 1} = useResizeObserver<HTMLDivElement>({
        ref: elementRef,
      });
      const restyleLineByGpuIndex = useCallback((line: Line) => {
        const intMatch = (line.title || '').toString().match(/\d+/);
        if (intMatch) {
          const gpuIndex = intMatch[0];
          line.title = 'GPU ' + gpuIndex;
          line.color = color(parseInt(gpuIndex, 10) % 8);
        }
      }, []);

      const renderPanelIfNotEmpty = useCallback(
        (panel: Panel) => {
          if (panel.lines.length > 0) {
            if (panel.modifyLine) {
              panel.lines.forEach(line => panel.modifyLine!(line));
            }
            return (
              <Grid.Column>
                <div className="unpinned-panel" ref={elementRef}>
                  <h5 className="panel-title" title={panel.yAxis}>
                    {panel.yAxis}
                  </h5>
                  <LinePlot
                    lines={panel.lines}
                    yAxis={panel.yAxis}
                    xAxis={
                      panel.lines[0] && panel.lines[0].timestep
                        ? _.capitalize(panel.lines[0].timestep)
                        : 'Runtime'
                    }
                    singleRun
                    parentWidth={width}
                    yDomain={panel.percentage ? [0, 100] : undefined}
                  />
                </div>
              </Grid.Column>
            );
          } else {
            return undefined;
          }
        },
        [width]
      );

      const getLines = useCallback(
        (lineNames: string[]) => {
          if (metrics == null || metrics.length === 0) {
            return [];
          }
          return linesFromSystemMetricsPlot(metrics, lineNames, '_runtime', 0);
        },
        [metrics]
      );

      const getLinesRegex = useCallback(
        (lineNameRegex: RegExp) => {
          if (metrics == null || metrics.length === 0) {
            return [];
          }
          const row0 = metrics[0];
          const keys = _.keys(row0);
          const matches = keys.filter(k => k.match(lineNameRegex));
          return linesFromSystemMetricsPlot(metrics, matches, '_runtime', 0);
        },
        [metrics]
      );

      useEffect(() => {
        // Apollo sometimes returns loading = true, systemMetrics = null. I believe
        // this is when other components that are polling the graphql run field get
        // results. Apollo ends up blowing away the systemMetrics field until the next
        // time our poll succeeds. So we sadly have to cache results here.
        if (!runSystemMetricsQuery.loading && initialLoad) {
          setInitialLoad(false);
        }
        if (
          runSystemMetricsQuery.systemMetrics != null &&
          runSystemMetricsQuery.systemMetrics !== metrics
        ) {
          setMetrics(runSystemMetricsQuery.systemMetrics);
        }
      }, [initialLoad, metrics, runSystemMetricsQuery]);

      if (initialLoad) {
        return <WandbLoader />;
      }
      if (metrics.length === 0) {
        return (
          <p style={{padding: 16}}>
            No System Metrics data saved yet. W&B automatically saves system
            metrics data for runs that run for longer than a few seconds.
          </p>
        );
      }

      const cpuLines: Panel = {
        yAxis: 'CPU Utilization (%)',
        lines: getLines(['system.cpu']),
        percentage: true,
        modifyLine: line => {
          line.title = '';
        },
      };
      const memoryLines: Panel = {
        yAxis: 'System Memory Utilization (%)',
        lines: getLines(['system.memory']),
        percentage: true,
        modifyLine: line => {
          line.title = '';
        },
      };
      const processMemoryLines: Panel = {
        yAxis: 'Process Memory In Use (non-swap) (MB)',
        lines: getLines(['system.proc.memory.rssMB']),
        percentage: false,
        modifyLine: line => {
          line.title = '';
        },
      };
      const processMemoryPercentLines: Panel = {
        yAxis: 'Process Memory In Use (non-swap) (%)',
        lines: getLines(['system.proc.memory.percent']),
        percentage: true,
        modifyLine: line => {
          line.title = '';
        },
      };
      const processMemoryAvailableLines: Panel = {
        yAxis: 'Process Memory Available (non-swap) (MB)',
        lines: getLines(['system.proc.memory.availableMB']),
        percentage: false,
        modifyLine: line => {
          line.title = '';
        },
      };
      const processCPUThreadsLines: Panel = {
        yAxis: 'Process CPU Threads In Use',
        lines: getLines(['system.proc.cpu.threads']),
        percentage: false,
        modifyLine: line => {
          line.title = '';
        },
      };
      const diskLines: Panel = {
        yAxis: 'Disk Utilization (%)',
        lines: getLines(['system.disk']),
        percentage: true,
        modifyLine: line => {
          line.title = '';
        },
      };
      const networkLines: Panel = {
        yAxis: 'Network Traffic (bytes)',
        lines: getLines(['system.network.recv', 'system.network.sent']),
        percentage: false,
        modifyLine: line => {
          line.title = (line.title || '')
            .toString()
            .replace('system.network.recv', 'received')
            .replace('system.network.sent', 'sent');
        },
      };
      const gpuGpuLines: Panel = {
        yAxis: 'GPU Utilization (%)',
        lines: getLinesRegex(/system.gpu.\d+.gpu/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const gpuTempLines: Panel = {
        yAxis: 'GPU Temp (\u2103)',
        lines: getLinesRegex(/system.gpu.\d+.temp/),
        percentage: false,
        modifyLine: restyleLineByGpuIndex,
      };
      const gpuMemoryLines: Panel = {
        yAxis: 'GPU Time Spent Accessing Memory (%)',
        lines: getLinesRegex(/system.gpu.\d+.memory$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const gpuMemoryAllocated: Panel = {
        yAxis: 'GPU Memory Allocated (%)',
        lines: getLinesRegex(/system.gpu.\d+.memory_?[aA]llocated$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const gpuPowerUsage: Panel = {
        yAxis: 'GPU Power Usage (%)',
        lines: getLinesRegex(/system.gpu.\d+.powerPercent$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };

      const processGpuGpuLines: Panel = {
        yAxis: 'Process GPU Utilization (%)',
        lines: getLinesRegex(/system\.gpu\.process\.\d+.gpu/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const processGpuTempLines: Panel = {
        yAxis: 'Process GPU Temp (\u2103)',
        lines: getLinesRegex(/system\.gpu\.process\.\d+.temp/),
        percentage: false,
        modifyLine: restyleLineByGpuIndex,
      };
      const processGpuMemoryLines: Panel = {
        yAxis: 'Process GPU Time Spent Accessing Memory (%)',
        lines: getLinesRegex(/system\.gpu\.process\.\d+.memory$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const processGpuMemoryAllocated: Panel = {
        yAxis: 'Process GPU Memory Allocated (%)',
        lines: getLinesRegex(/system\.gpu\.process\.\d+.memory_?[aA]llocated$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const processGpuPowerUsage: Panel = {
        yAxis: 'Process GPU Power Usage (%)',
        lines: getLinesRegex(/system\.gpu\.process\.\d+.powerPercent$/),
        percentage: true,
        modifyLine: restyleLineByGpuIndex,
      };
      const tpuLines: Panel = {
        yAxis: 'TPU Utilization (%)',
        lines: getLines(['system.tpu']),
        percentage: true,
        modifyLine: line => {
          line.title = '';
        },
      };

      return (
        <Grid className="panel-grid run-system-metrics" stackable columns={2}>
          {renderPanelIfNotEmpty(cpuLines)}
          {renderPanelIfNotEmpty(memoryLines)}
          {renderPanelIfNotEmpty(processMemoryLines)}
          {renderPanelIfNotEmpty(processMemoryPercentLines)}
          {renderPanelIfNotEmpty(processMemoryAvailableLines)}
          {renderPanelIfNotEmpty(processCPUThreadsLines)}
          {renderPanelIfNotEmpty(diskLines)}
          {renderPanelIfNotEmpty(networkLines)}
          {renderPanelIfNotEmpty(gpuGpuLines)}
          {renderPanelIfNotEmpty(gpuTempLines)}
          {renderPanelIfNotEmpty(gpuMemoryLines)}
          {renderPanelIfNotEmpty(gpuMemoryAllocated)}
          {renderPanelIfNotEmpty(gpuPowerUsage)}
          {renderPanelIfNotEmpty(processGpuGpuLines)}
          {renderPanelIfNotEmpty(processGpuTempLines)}
          {renderPanelIfNotEmpty(processGpuMemoryLines)}
          {renderPanelIfNotEmpty(processGpuMemoryAllocated)}
          {renderPanelIfNotEmpty(processGpuPowerUsage)}
          {renderPanelIfNotEmpty(tpuLines)}
        </Grid>
      );
    },
    {id: 'RunSystemMetrics', memo: true}
  );

export default RunSystemMetricsQuery.withQuery(RunSystemMetrics);

interface Panel {
  lines: Line[];
  yAxis: string;
  percentage: boolean;
  modifyLine?: (line: Line) => void;
}
