import * as S from './QueryPropertyEditor.styles';

import React from 'react';
import {PropertyEditorComponent} from './property-editors';
import {InspectorPropertyWrapper} from '../Inspector.styles';
import {useRunsQueryContext, useRunSetsQuery} from '../../state/runs/hooks';
import {toGraphql, queryTemplates, parseQuery} from '../../util/vega3';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import * as VegaLib3 from '../../util/vega3';
import makeComp from '../../util/profiler';

/**
 * For vega3.
 * @param props
 */
const QueryPropertyEditor: PropertyEditorComponent<'query'> = makeComp(
  props => {
    const [wrapperNode, setWrapperNode] = React.useState<HTMLElement | null>(
      null
    );

    const context = useRunsQueryContext();
    const pageQuery = useRunSetsQuery(props.runSetRefs);
    const {templateArgs, templateVals} = React.useMemo(
      () => queryTemplates(pageQuery, context),
      [pageQuery, context]
    );

    const queryTextRef = React.useRef<HTMLTextAreaElement | null>(null);

    const selectQuery = React.useCallback(() => {
      if (queryTextRef.current != null) {
        const textArea = queryTextRef.current;

        textArea.select();
        textArea.setSelectionRange(0, 999999);
      }
    }, [queryTextRef]);

    const {fixedFields, save} = props;
    const pasteQuery = React.useCallback(
      (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const clipboardData = e.clipboardData || (window as any).clipboardData;
        const pastedText = clipboardData.getData('Text');

        const parsedQuery = parseQuery(pastedText, fixedFields, templateArgs);
        if (parsedQuery != null) {
          save(parsedQuery.queryFields);
        }
      },
      [fixedFields, save, templateArgs]
    );

    const displayedVal =
      props.values.length === 1 ? props.values[0] : undefined;

    const {result} = VegaLib3.useVegaQuery(
      {queryFields: displayedVal || []},
      VegaLib3.DEFAULT_TRANSFORM,
      templateVals
    );
    console.log('result', result);

    if (displayedVal == null) {
      return <InspectorPropertyWrapper>Mixed</InspectorPropertyWrapper>;
    }
    return (
      <InspectorPropertyWrapper ref={node => setWrapperNode(node)}>
        <S.Wrapper>
          <S.Header>Data source</S.Header>
          <S.TextAreaWrapper
            editing={props.openedPopout === props.propertyName}>
            <S.TextArea
              rows={3}
              ref={queryTextRef}
              onFocus={selectQuery}
              onSelect={selectQuery}
              onPaste={pasteQuery}
              value={toGraphql(
                {queryFields: displayedVal},
                templateVals,
                false
              )}
            />
            <S.EditButton
              onClick={e => {
                e.stopPropagation();
                props.setOpenedPopout(o =>
                  o === props.propertyName ? null : props.propertyName
                );
              }}>
              <LegacyWBIcon name="edit"></LegacyWBIcon>
            </S.EditButton>
          </S.TextAreaWrapper>
        </S.Wrapper>
        {props.openedPopout === props.propertyName && wrapperNode && (
          <S.Popout anchor={wrapperNode}>
            <S.StyledQueryEditor
              queryFields={displayedVal}
              fixedFields={props.fixedFields}
              templateArgs={templateArgs}
              setQueryFields={val => props.save(val)}></S.StyledQueryEditor>
            <S.ResultTableWrapper>
              {result && result.length > 0 && (
                <S.ResultTable
                  data={result.map(r => {
                    return {searchString: r.name, row: r};
                  })}
                  columns={Object.keys(result[0]).map(k => {
                    return {Header: k, accessor: k};
                  })}></S.ResultTable>
              )}
            </S.ResultTableWrapper>
          </S.Popout>
        )}
      </InspectorPropertyWrapper>
    );
  },
  {id: 'QueryPropertyEditor'}
);

export default QueryPropertyEditor;
