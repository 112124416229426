import React, {useState} from 'react';
import {ButtonGroup, Popup, Button} from 'semantic-ui-react';
import makeComp from '../util/profiler';
import LegacyWBIcon from './elements/LegacyWBIcon';

// This menu appears when you make a selection in a Parallel Coordinates or Scatter Plot
// Menu options: Clear selection, or Convert selection to filters
export const SelectionBoundsMenu: React.FC<{
  clearSelections(): void;
  convertSelectionsToFilters(): void;
}> = makeComp(
  ({clearSelections, convertSelectionsToFilters}) => {
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [clearPopupOpen, setClearPopupOpen] = useState(false);
    return (
      <ButtonGroup>
        {convertSelectionsToFilters != null && (
          <Popup
            className="selection-bounds-menu-popup"
            size="mini"
            inverted
            position="top center"
            open={filterPopupOpen}
            content={
              <div style={{textAlign: 'center'}}>
                Create a filter <br /> from this selection
              </div>
            }
            trigger={
              <Button
                size="small"
                onClick={() => {
                  setFilterPopupOpen(false);
                  convertSelectionsToFilters();
                  clearSelections();
                }}
                onMouseOver={() => setFilterPopupOpen(true)}
                onMouseOut={() => setFilterPopupOpen(false)}>
                <LegacyWBIcon name="filter" />
              </Button>
            }
          />
        )}
        <Popup
          className="selection-bounds-menu-popup"
          size="mini"
          inverted
          position="top center"
          open={clearPopupOpen}
          trigger={
            <Button
              size="small"
              onClick={() => {
                setClearPopupOpen(false);
                clearSelections();
              }}
              onMouseOver={() => setClearPopupOpen(true)}
              onMouseOut={() => setClearPopupOpen(false)}>
              <LegacyWBIcon name="close" />
            </Button>
          }
          content="Clear selection"
        />
      </ButtonGroup>
    );
  },
  {id: 'SelectionBoundsMenu'}
);
