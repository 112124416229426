import React, {useEffect} from 'react';
import {Loader} from 'semantic-ui-react';

import * as Ops from '@wandb/cg/browser/ops';
import {useUsageAdminPageQuery} from '../generated/graphql';
import ActivityDashboard from '../components/ActivityDashboard';
import {setDocumentTitle} from '../util/document';
import {propagateErrorsContext} from '../util/errors';
import makeComp from '../util/profiler';

import * as S from './UsageAdmin.styles';

const UsageAdminPage: React.FC<{}> = makeComp(
  props => {
    const {loading, data, error} = useUsageAdminPageQuery({
      context: propagateErrorsContext(),
      errorPolicy: 'all',
    });

    useEffect(() => {
      setDocumentTitle('Whole Instance Activity');
    });

    if (loading) {
      return <Loader active size="large" />;
    }

    // Backend says instance dash is not enabled, catch the error and show
    // a nicer modal.
    if (error && error.message?.includes('Not enabled')) {
      return (
        <S.ErrorWrapper>
          <S.BigSad>
            <span role="img" aria-label="warning symbol">
              ⚠
            </span>
          </S.BigSad>
          <S.ErrorMessage>
            This dashboard is not enabled. Please contact{' '}
            <a href="mailto:sales@wandb.com">sales@wandb.com</a> for help.
          </S.ErrorMessage>
        </S.ErrorWrapper>
      );
    }

    // Done loading but nothing to show
    if (data?.instance == null) {
      return (
        <S.ErrorWrapper>
          <S.BigSad>
            <span role="img" aria-label="warning symbol">
              ⚠
            </span>
          </S.BigSad>
          <S.ErrorMessage>Permission denied</S.ErrorMessage>
        </S.ErrorWrapper>
      );
    }

    const users: Array<{name: string; username: string}> = [];
    const baseCounts = {numTeams: 0, numUsers: 0};
    const {numTeams, numUsers} =
      data?.instance?.entities.reduce((memo, ent) => {
        if (!['wandb', 'restore', 'local'].includes(ent.name)) {
          if (ent.isTeam) {
            memo.numTeams++;
          } else {
            memo.numUsers++;
            users.push({name: ent.user!.name!, username: ent.user!.username!});
          }
        }
        return memo;
      }, baseCounts) ?? baseCounts;

    return (
      <ActivityDashboard
        heading={'Whole Instance Activity'}
        subtitle={`${
          numTeams === 1 ? '1 team' : `${numTeams} teams`
        }, ${numUsers} users`}
        userFilterOpts={users}
        projects={Ops.opRootAllProjects({})}
        reports={Ops.opRootAllReports({})}
        artifacts={Ops.opRootAllArtifacts({})}
        dateWindowSize={{month: 3}}
      />
    );
  },
  {id: 'UsageAdminPage'}
);

export default UsageAdminPage;
