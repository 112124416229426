import copyToClipboard from 'copy-to-clipboard';
import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';
import React, {useCallback, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Modal} from 'semantic-ui-react';
import EditableTable, {
  Column,
  EditableField,
} from '../components/EditableTable';
import NoMatch from '../components/NoMatch';
import PaginatedTable from '../components/PaginatedTable';
import WandbLoader from '../components/WandbLoader';
import {
  useAllNewsletterSubscriptionsQuery,
  useUpdateGalleryMutation,
} from '../generated/graphql';
import {
  setViewerUsingAdminPrivileges,
  viewerUsingAdminPrivileges,
} from '../util/admin';
import {saveTextAsCSV} from '../util/csv';
import {
  CATEGORIES_SHOWING_DESCRIPTION_SET,
  DISCUSSION_CATEGORY_ID,
  GallerySpec,
  NewsletterSubscription,
  NewsletterSubscriptionQueryData,
  newTag,
  ReportMetadata,
  Tag,
  useGallerySpec,
  useIsGalleryAdmin,
  useReportMetadata,
} from '../util/gallery';
import makeComp from '../util/profiler';
import {isNotNullOrUndefined} from '../util/types';
import * as Urls from '../util/urls';
import * as S from './GalleryAdmin.styles';
import {Post} from './GalleryPage';

const GalleryAdmin: React.FC = makeComp(
  () => {
    const {loading: isGalleryAdminLoading, isGalleryAdmin: admin} =
      useIsGalleryAdmin();

    const {
      loading: specLoading,
      viewID,
      gallerySpec,
      refetchGallerySpec,
    } = useGallerySpec();

    const {loading: reportMetadataLoading, reportMetadatas: reports} =
      useReportMetadata();

    const notPendingReports = useMemo(
      () => reports?.filter(r => !r.pending),
      [reports]
    );
    const pendingReports = useMemo(
      () => reports?.filter(r => r.pending),
      [reports]
    );

    if (
      isGalleryAdminLoading ||
      specLoading ||
      reportMetadataLoading ||
      viewID == null ||
      notPendingReports == null ||
      pendingReports == null
    ) {
      return <WandbLoader />;
    }

    if (!admin) {
      return <NoMatch />;
    }

    return (
      <GalleryAdminLoaded
        viewID={viewID}
        spec={gallerySpec}
        reports={notPendingReports}
        pendingReports={pendingReports}
        refetch={refetchGallerySpec}
      />
    );
  },
  {id: 'GalleryAdmin', memo: true}
);

export default GalleryAdmin;

type GalleryAdminLoadedProps = {
  viewID: string;
  spec: GallerySpec;
  reports: ReportMetadata[];
  pendingReports: ReportMetadata[];
  refetch: () => Promise<GallerySpec>;
};

const GalleryAdminLoaded: React.FC<GalleryAdminLoadedProps> = makeComp(
  ({viewID, spec, reports, pendingReports, refetch}) => {
    const [specDraft, setSpecDraft] = useState(spec);
    const {
      headings: {title, subtitle, description},
      categories,
      tags,
    } = specDraft;

    const categoryNameSet = useMemo(
      () => new Set(categories.map(t => t.name)),
      [categories]
    );
    const tagIsCategory = useCallback(
      (tagName: string) => categoryNameSet.has(tagName),
      [categoryNameSet]
    );

    const [specDraftVersions, setSpecDraftVersions] = useState<GallerySpec[]>(
      []
    );
    const [specDraftVersionIndex, setSpecDraftVersionIndex] = useState(-1);
    const pushDraftVersion = useCallback(
      (sd: GallerySpec) => {
        setSpecDraft(sd);
        setSpecDraftVersions(prev =>
          produce(prev, draft => {
            if (specDraftVersionIndex === draft.length - 1) {
              draft.push(sd);
            } else {
              draft.splice(specDraftVersionIndex + 1, draft.length, sd);
            }
          })
        );
        setSpecDraftVersionIndex(prev => prev + 1);
      },
      [specDraftVersionIndex]
    );

    const editTitle = useCallback(
      (e, {value}) =>
        pushDraftVersion(
          produce(specDraft, draft => {
            draft.headings.title = value;
          })
        ),
      [pushDraftVersion, specDraft]
    );
    const editSubtitle = useCallback(
      (e, {value}) =>
        pushDraftVersion(
          produce(specDraft, draft => {
            draft.headings.subtitle = value;
          })
        ),
      [pushDraftVersion, specDraft]
    );
    const editDescription = useCallback(
      (e, {value}) =>
        pushDraftVersion(
          produce(specDraft, draft => {
            draft.headings.description = value;
          })
        ),
      [pushDraftVersion, specDraft]
    );

    const reportByID = useMemo(
      () => new Map(reports.map(r => [r.id, r])),
      [reports]
    );
    const reportIDsByTagID = useMemo(() => {
      const res = new Map<string, string[]>();
      for (const r of reports) {
        for (const t of r.tags ?? []) {
          const reportList = res.get(t.id) ?? [];
          reportList.push(r.id);
          res.set(t.id, reportList);
        }
      }
      return res;
    }, [reports]);

    const [updateGallery] = useUpdateGalleryMutation();
    const [savingEdits, setSavingEdits] = useState(false);
    const unsavedEdits = useMemo(
      () => specDraftVersions.length > 0,
      [specDraftVersions]
    );

    const cancelEdits = useCallback(() => {
      setSpecDraft(spec);
      setSpecDraftVersions([]);
      setSpecDraftVersionIndex(-1);
    }, [spec]);

    const canUndo = specDraftVersionIndex > -1;
    const undo = useCallback(() => {
      if (specDraftVersionIndex === -1) {
        return;
      }
      setSpecDraft(
        specDraftVersionIndex === 0
          ? spec
          : specDraftVersions[specDraftVersionIndex - 1]
      );
      setSpecDraftVersionIndex(prev => prev - 1);
    }, [specDraftVersions, specDraftVersionIndex, spec]);

    const canRedo = specDraftVersionIndex < specDraftVersions.length - 1;
    const redo = useCallback(() => {
      if (specDraftVersionIndex === specDraftVersions.length - 1) {
        return;
      }
      setSpecDraft(specDraftVersions[specDraftVersionIndex + 1]);
      setSpecDraftVersionIndex(prev => prev + 1);
    }, [specDraftVersions, specDraftVersionIndex]);

    const saveEdits = useCallback(async () => {
      if (!unsavedEdits || savingEdits) {
        return;
      }
      setSavingEdits(true);

      try {
        const wasUsingAdmin = viewerUsingAdminPrivileges();
        setViewerUsingAdminPrivileges(true);
        await updateGallery({
          variables: {
            id: viewID,
            spec: JSON.stringify(specDraft),
          },
        });
        setViewerUsingAdminPrivileges(wasUsingAdmin);

        await refetch();
        setSpecDraftVersions([]);
        setSpecDraftVersionIndex(-1);
      } catch (e) {
        alert(`Error updating gallery: ${(e as any).message}`);
        console.error(`Error updating gallery: ${e}`);
      } finally {
        setSavingEdits(false);
      }
    }, [unsavedEdits, savingEdits, viewID, updateGallery, specDraft, refetch]);

    const [editingFeaturedKey, setEditingFeaturedKey] = useState<
      'categories' | 'tags' | null
    >(null);
    const [editingFeaturedIndex, setEditingFeaturedIndex] = useState<
      number | null
    >(null);
    const editingFeaturedTag = useMemo(
      () =>
        editingFeaturedKey != null && editingFeaturedIndex != null
          ? specDraft[editingFeaturedKey][editingFeaturedIndex]
          : null,
      [editingFeaturedKey, editingFeaturedIndex, specDraft]
    );

    const allNewsletterSubscriptionsQuery =
      useAllNewsletterSubscriptionsQuery();
    const newsletterSubscriptions: NewsletterSubscription[] = useMemo(
      () =>
        allNewsletterSubscriptionsQuery.data?.newsletterSubscriptions?.edges
          .map(e => e.node as unknown as NewsletterSubscriptionQueryData)
          .map((ns: NewsletterSubscriptionQueryData) => ({
            id: ns.id,
            username: ns.user?.username,
            email: ns.email ?? ns.user?.email,
            createdAt: ns.createdAt,
          })) ?? [],
      [allNewsletterSubscriptionsQuery]
    );

    const allEmails = useMemo(
      () => _.compact(newsletterSubscriptions.map(ns => ns.email)),
      [newsletterSubscriptions]
    );
    const copyAllEmails = useCallback(() => {
      const formattedEmails = allEmails.join(', ');
      copyToClipboard(formattedEmails);
      toast('Emails copied.');
    }, [allEmails]);

    const subscriptionDateAndEmailCSV = useMemo(() => {
      const headerLine = `"Subscribe Date","Email"`;
      const contentLines = newsletterSubscriptions.map(
        ({createdAt, email}) =>
          `"${moment(createdAt + 'Z').format('M/D')}","${email}"`
      );
      const lines = [headerLine, ...contentLines];
      return lines.join('\n');
    }, [newsletterSubscriptions]);
    const downloadAllEmails = useCallback(() => {
      saveTextAsCSV(subscriptionDateAndEmailCSV, 'fc-subscriber-emails.csv');
    }, [subscriptionDateAndEmailCSV]);

    const subscriberColumns: Array<Column<NewsletterSubscription>> = useMemo(
      () => [
        {
          name: 'Date Created',
          renderCell: r => {
            const date = new Date(r.createdAt + 'Z');
            return date.toLocaleString();
          },
          width: 400,
        },
        {name: 'Username', renderCell: r => r.username ?? '', width: 400},
        {name: 'Email', renderCell: r => r.email ?? '', width: 400},
      ],
      []
    );

    const makeTagColumns: (
      specKey: 'categories' | 'tags'
    ) => Array<Column<Tag>> = useCallback(
      (specKey: 'categories' | 'tags') => [
        {name: 'Name', renderCell: r => r.name, width: 200},
        {
          name: 'Description',
          renderCell: r => r.description,
          width: 600,
        },
        {
          name: 'Image',
          renderCell: r => r.imageURL && <S.Image src={r.imageURL} />,
          width: 200,
        },
        {
          name: 'Link Form',
          renderCell: r => r.linkForm,
          width: 200,
        },
        {
          name: 'Featured',
          renderCell: (r, i) => (
            <S.ActionIcon
              name="edit"
              onClick={() => {
                setEditingFeaturedKey(specKey);
                setEditingFeaturedIndex(i);
              }}
            />
          ),
          width: 100,
        },
      ],
      []
    );
    const categoryColumns = useMemo(
      () => makeTagColumns('categories'),
      [makeTagColumns]
    );
    const tagColumns = useMemo(() => makeTagColumns('tags'), [makeTagColumns]);

    const tagEditableFields: Array<EditableField<Tag>> = useMemo(
      () => [
        {
          rowKey: 'name',
          label: 'Name',
          type: 'input',
        },
        {
          rowKey: 'description',
          label: 'Description',
          type: 'textarea',
        },
        {
          rowKey: 'imageURL',
          label: 'Image',
          type: 'image',
        },
        {
          rowKey: 'linkForm',
          label: 'Link Form',
          type: 'input',
        },
      ],
      []
    );
    const categoryEditableFields: Array<EditableField<Tag>> = useMemo(
      () => [
        ...tagEditableFields,
        {rowKey: 'color', label: 'Color', type: 'input'},
      ],
      [tagEditableFields]
    );

    const tagIsValid = useCallback((t: Tag) => t.name !== '', []);

    return (
      <S.Container>
        {unsavedEdits && (
          <S.UnsavedEditsBar>
            You have unsaved changes to the gallery.
            <S.UnsavedEditsButton
              disabled={savingEdits || !canUndo}
              onClick={undo}>
              Undo
            </S.UnsavedEditsButton>
            <S.UnsavedEditsButton
              disabled={savingEdits || !canRedo}
              onClick={redo}>
              Redo
            </S.UnsavedEditsButton>
            <S.UnsavedEditsButton disabled={savingEdits} onClick={cancelEdits}>
              Cancel
            </S.UnsavedEditsButton>
            <S.UnsavedEditsButton
              primary
              disabled={savingEdits}
              loading={savingEdits}
              onClick={saveEdits}>
              Save
            </S.UnsavedEditsButton>
          </S.UnsavedEditsBar>
        )}

        <S.PageHeading>Gallery Admin</S.PageHeading>

        <S.Heading>Headings</S.Heading>
        <S.Subheading>Title</S.Subheading>
        <S.EditHeadingInput value={title} onChange={editTitle} />
        <S.Subheading>Subtitle</S.Subheading>
        <S.EditHeadingInput value={subtitle} onChange={editSubtitle} />
        <S.Subheading>Description</S.Subheading>
        <S.EditHeadingTextArea value={description} onChange={editDescription} />

        <S.Heading>Categories</S.Heading>
        <EditableTable
          rows={categories}
          columns={categoryColumns}
          editableFields={categoryEditableFields}
          rowIsValid={tagIsValid}
          onEdit={newCategories =>
            pushDraftVersion(
              produce(specDraft, draft => {
                draft.categories = newCategories;
              })
            )
          }
          makeNewRow={newTag}
        />

        <S.Heading>Tags</S.Heading>
        <EditableTable
          rows={tags}
          columns={tagColumns}
          editableFields={tagEditableFields}
          rowIsValid={tagIsValid}
          onEdit={newTags =>
            pushDraftVersion(
              produce(specDraft, draft => {
                draft.tags = newTags;
              })
            )
          }
          makeNewRow={newTag}
        />

        <S.Heading>Subscribers</S.Heading>
        <S.AdminButton
          content="Copy emails to clipboard"
          onClick={copyAllEmails}
        />
        <S.AdminButton
          content="Download emails (CSV)"
          onClick={downloadAllEmails}
        />
        <PaginatedTable
          rows={newsletterSubscriptions}
          columns={subscriberColumns}
        />

        <S.Heading>Pending Posts</S.Heading>
        {pendingReports.map(r => {
          const link =
            r.project != null
              ? Urls.reportView({
                  entityName: r.project.entityName,
                  projectName: r.project.name,
                  reportID: r.id,
                  reportName: r.displayName,
                })
              : (r.tags?.some(t => t.id === DISCUSSION_CATEGORY_ID)
                  ? Urls.galleryDiscussionView
                  : Urls.galleryPostView)({
                  entityName: r.entityName,
                  reportID: r.id,
                  reportName: r.displayName,
                });
          return (
            <Post key={r.id} {...r} tagIsCategory={tagIsCategory} link={link} />
          );
        })}

        {editingFeaturedKey != null &&
          editingFeaturedIndex != null &&
          editingFeaturedTag != null && (
            <FeaturedPickerModal
              allItems={_.sortBy(
                reportIDsByTagID
                  .get(editingFeaturedTag.id)
                  ?.map(id => reportByID.get(id))
                  .filter(isNotNullOrUndefined) ?? [],
                r => -(r.addedAt ? new Date(r.addedAt).valueOf() : Date.now())
              )}
              featuredItems={editingFeaturedTag.featuredReportIDs
                .map(id => reportByID.get(id))
                .filter(isNotNullOrUndefined)}
              showDescription={CATEGORIES_SHOWING_DESCRIPTION_SET.has(
                editingFeaturedTag.name
              )}
              tagIsCategory={tagIsCategory}
              onSave={newFeatured => {
                pushDraftVersion(
                  produce(specDraft, draft => {
                    const draftTag =
                      draft[editingFeaturedKey][editingFeaturedIndex];
                    draftTag.featuredReportIDs = newFeatured.map(r => r.id);
                  })
                );
              }}
              onClose={() => {
                setEditingFeaturedKey(null);
                setEditingFeaturedIndex(null);
              }}
            />
          )}
      </S.Container>
    );
  },
  {id: 'GalleryAdminLoaded', memo: true}
);

type FeaturedPickerModalProps = {
  allItems: ReportMetadata[];
  featuredItems: ReportMetadata[];
  showDescription?: boolean;
  tagIsCategory: (tagName: string) => boolean;
  onSave: (newFeatured: ReportMetadata[]) => void;
  onClose: () => void;
};

const FeaturedPickerModal: React.FC<FeaturedPickerModalProps> = ({
  allItems,
  featuredItems,
  showDescription,
  tagIsCategory,
  onSave,
  onClose,
}) => {
  const [featuredItemsDraft, setFeaturedItemsDraft] = useState(featuredItems);

  const featuredItemsSet = useMemo(
    () => new Set(featuredItemsDraft),
    [featuredItemsDraft]
  );
  const notFeaturedItems = useMemo(
    () => allItems.filter(x => !featuredItemsSet.has(x)),
    [allItems, featuredItemsSet]
  );

  const confirm = useCallback(() => {
    onSave(featuredItemsDraft);
    onClose();
  }, [featuredItemsDraft, onSave, onClose]);

  const makeRenderItem = useCallback(
    (toggleItem: (item: ReportMetadata) => void) => (p: ReportMetadata) =>
      (
        <Post
          key={p.id}
          {...p}
          tagIsCategory={tagIsCategory}
          onClick={() => toggleItem(p)}
          showDescription={showDescription}
        />
      ),
    [tagIsCategory, showDescription]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderFeaturedItem = useCallback(
    makeRenderItem(item =>
      setFeaturedItemsDraft(prev =>
        produce(prev, draft => {
          const i = draft.findIndex(x => x.id === item.id);
          if (i !== -1) {
            draft.splice(i, 1);
          }
        })
      )
    ),
    [makeRenderItem]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderNotFeaturedItem = useCallback(
    makeRenderItem(item =>
      setFeaturedItemsDraft(prev =>
        produce(prev, draft => {
          draft.push(item);
        })
      )
    ),
    [makeRenderItem]
  );

  return (
    <Modal open onClose={onClose}>
      <Modal.Content>
        <S.ModalLabel>Featured</S.ModalLabel>
        <S.ReportList>
          {featuredItemsDraft.map(renderFeaturedItem)}
        </S.ReportList>
        <S.ModalLabel>Not featured</S.ModalLabel>
        <S.ReportList>
          {notFeaturedItems.map(renderNotFeaturedItem)}
        </S.ReportList>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={confirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
