import '../css/FancyPage.less';

import React from 'react';

import FancyPageSidebar, {
  FancyPageSidebarItem,
} from './FancyPage/FancyPageSidebar';
import makeComp from '../util/profiler';

interface FancyPageProps {
  history: any;
  baseUrl: string;
  defaultIndex?: number;
  activeSlug?: string;
  items: FancyPageSidebarItem[];
}

const FancyPage: React.FC<FancyPageProps> = makeComp(
  ({baseUrl, activeSlug, items, defaultIndex}) => {
    const activeItem =
      (activeSlug && items.filter(item => item.slug === activeSlug)[0]) ||
      items[defaultIndex || 0];
    return (
      <div className="fancy-page">
        <FancyPageSidebar
          items={items}
          selectedItem={activeItem}
          baseUrl={baseUrl}
          defaultIndex={defaultIndex}
        />
        <div className="fancy-page__content">
          {activeItem && activeItem.render ? activeItem.render() : undefined}
        </div>
      </div>
    );
  },
  {id: 'FancyPage', memo: true}
);

export default FancyPage;
