import gql from 'graphql-tag';

import {useQuery} from '@apollo/react-hooks';
import {useMemo} from 'react';
import {Subset} from '../../types/base';
import {Graph, ProjectField} from '../../types/graphql';

import * as Generated from '../../generated/graphql';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Run from '../../util/runs';

export const RUN_KEYS = gql`
  query RunKeys(
    $entityName: String!
    $projectName: String!
    $types: [String!] = ["number", "string", "boolean", "other"]
    $limit: Int = 1000
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      fields(types: $types, first: $limit) {
        edges {
          node {
            path
          }
        }
      }
    }
  }
`;

export interface RunKeysData {
  project: {
    id: string;
    name: string;
    fields: Graph<Subset<ProjectField, 'path'>>;
  };
}

export function useRunKeysQuery(queryVars: Generated.RunKeysQueryVariables) {
  const {loading, data} = useQuery<
    RunKeysData,
    Generated.RunKeysQueryVariables
  >(Generated.RunKeysDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
  });

  const fields = data != null ? data.project.fields.edges.map(e => e.node) : [];

  const keys = useMemo(() => {
    return fields
      .map(f => Run.serverPathToKeyString(f.path))
      .filter(Obj.notEmpty)
      .filter(k => !k.startsWith('config:_wandb'))
      .sort();
  }, [fields]);

  if (loading) {
    return {loading: true as true};
  }
  return {loading: false as false, keys};
}
