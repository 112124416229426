import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {Loader, Button, Dropdown} from 'semantic-ui-react';
import {Image} from 'semantic-ui-react';
import {useSweepsQuery, SweepsData} from '../state/graphql/sweepsQuery';
import * as Sweeps from '../util/sweeps';
import {TimeDelta} from '../util/time';
import ImageIcon from './ImageIcon';
import docUrl from '../util/doc_urls';
import {
  sweep as sweepUrl,
  sweepControls as sweepControlsUrl,
} from '../util/urls';
import * as Generated from '../generated/graphql';

import '../css/ActionTable.less';
import '../css/ReportTable.less';
import '../css/SweepsTable.less';
import DeleteSweepModal from './DeleteSweepModal';
import EditableLabel from './elements/EditableLabel';
import emptyImg from '../assets/il-no-sweeps.png';
import emptyImg2x from '../assets/il-no-sweeps@2x.png';
import EmptyWatermark from './EmptyWatermark';
import {SweepData} from '../state/graphql/sweepPageQuery';
import {ApolloQueryResult} from 'apollo-client';
import {capitalizeFirst} from '@wandb/cg/browser/utils/string';
import {SweepState, sweepStateDescription} from '../util/sweeps';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';
import MultiSelectTable from './MultiSelectTable';
import {useCallback, useState} from 'react';
import MultiStateCheckbox from './MultiStateCheckbox';
import {TargetBlank} from '../util/links';
import {WBIcon} from '@wandb/ui';

type SortBy =
  | 'displayName'
  | 'state'
  | 'createdAt'
  | 'user'
  | 'runCount'
  | 'runTime';
type SortFn = (r: SweepData) => any;

const SORT_FUNCTION_BY_NAME: {[s in SortBy]: SortFn} = {
  displayName: r => r.displayName,
  state: r => r.state,
  createdAt: r => -new Date(r.createdAt).valueOf(),
  user: r => r.user.username,
  runCount: r => r.runCount,
  runTime: r => r.runTime,
};

const SweepsTable = makeComp(
  (props: {entityName: string; projectName: string}) => {
    const {entityName, projectName} = props;
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const history = useHistory();

    const [sortBy, setSortBy] = useState<SortBy>('createdAt');
    const [sortReverse, setSortReverse] = useState(false);
    const toggleSort = useCallback(
      (sb: SortBy) => {
        if (sb === sortBy) {
          setSortReverse(prev => !prev);
          return;
        }
        setSortBy(sb);
        setSortReverse(false);
      },
      [sortBy]
    );
    const renderSortIcon = (sortKey: string) => {
      if (sortBy !== sortKey) {
        return null;
      }
      return (
        <WBIcon
          name={`${sortReverse ? 'down' : 'up'}-arrow`}
          className="report-table__sort-icon"
        />
      );
    };

    const sweepsQuery = useSweepsQuery(
      {
        entityName,
        projectName,
      },
      {
        fetchPolicy: 'network-only',
        enablePolling: true,
      }
    );
    if (sweepsQuery.loading) {
      return <Loader active />;
    }
    const {project, refetch} = sweepsQuery;

    const readOnly = project.readOnly;
    const sweeps = project.sweeps.edges.map(e => e.node);

    return (
      <div>
        <MultiSelectTable
          className="sweeps-table"
          readOnly={readOnly}
          rows={sweeps}
          sortBy={SORT_FUNCTION_BY_NAME[sortBy]}
          sortReverse={sortReverse}
          renderHeader={(checked, onClick) => (
            <>
              <div className="report-table__cell report-table__cell--select sweep-col-select">
                <MultiStateCheckbox checked={checked} onClick={onClick} />
              </div>
              <div
                className="report-table__cell report-table__table-title sweep-col-name"
                onClick={() => toggleSort('displayName')}>
                Sweep
                {renderSortIcon('displayName')}
              </div>
              <div
                className="report-table__cell sweep-col-state"
                onClick={() => toggleSort('state')}>
                State
                {renderSortIcon('state')}
              </div>
              <div
                className="report-table__cell sweep-col-created"
                onClick={() => toggleSort('createdAt')}>
                Created
                {renderSortIcon('createdAt')}
              </div>
              <div
                className="report-table__cell sweep-col-creator"
                onClick={() => toggleSort('user')}>
                Creator
                {renderSortIcon('user')}
              </div>
              <div
                className="report-table__cell sweep-col-run-count"
                onClick={() => toggleSort('runCount')}>
                Run count
                {renderSortIcon('runCount')}
              </div>
              <div
                className="report-table__cell sweep-col-compute-time"
                onClick={() => toggleSort('runTime')}>
                Compute time
                {renderSortIcon('runTime')}
              </div>
              <div className="report-table__cell sweep-col-menu" />
            </>
          )}
          renderRow={(r, selected, setSelected) => (
            <SweepsTableRow
              key={r.id}
              entityName={entityName}
              projectName={projectName}
              sweep={r}
              readOnly={readOnly}
              refetchSweeps={refetch}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          renderDialogs={filteredSelectedSweeps => (
            <DeleteSweepModal
              open={deleteConfirmModalOpen}
              sweeps={filteredSelectedSweeps}
              onClose={() => setDeleteConfirmModalOpen(false)}
              onDelete={() => {
                refetch();
                setDeleteConfirmModalOpen(false);
              }}
            />
          )}
          renderAddButton={() => (
            <Button
              primary
              size="tiny"
              className="create-new-report"
              onClick={() =>
                history.push(`/${entityName}/${projectName}/create-sweep`)
              }
              content="Create sweep"
            />
          )}
          renderDeleteButton={() => (
            <Button
              negative
              size="tiny"
              className="delete-reports"
              onClick={() => setDeleteConfirmModalOpen(true)}
              content="Delete sweeps"
            />
          )}
          renderEmpty={
            sweeps.length === 0 ? () => <NoSweepsMessage /> : undefined
          }
        />
      </div>
    );
  },
  {id: 'SweepsTable'}
);

export default SweepsTable;

interface SweepsTableSweepProps {
  entityName: string;
  projectName: string;
  sweep: SweepData;
  readOnly: boolean;
  selected: boolean;
  refetchSweeps: (
    variables?:
      | {
          entityName: string;
          projectName: string;
        }
      | undefined
  ) => Promise<ApolloQueryResult<SweepsData>>;
  setSelected(id: string, selected: boolean): void;
}

const SweepsTableRow: React.FC<SweepsTableSweepProps> = makeComp(
  props => {
    const {
      entityName,
      projectName,
      sweep,
      readOnly,
      refetchSweeps,
      selected,
      setSelected,
    } = props;
    const sweepDisplayName = Sweeps.getSweepDisplayName(sweep) || 'Sweep';
    const [editingName, setEditingName] = React.useState(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [upsertSweep] = Generated.useUpsertSweepMutation();

    const urlParams = {
      entityName,
      projectName,
      sweepName: sweep.name,
    };
    const sweepLink = sweepUrl(urlParams);
    const sweepControlsLink = sweepControlsUrl(urlParams);

    const onSelectChanged = useCallback(
      () => setSelected(sweep.id, !selected),
      [sweep.id, selected, setSelected]
    );

    return (
      <div className="report-table__row">
        <DeleteSweepModal
          open={deleteConfirmModalOpen}
          sweeps={[sweep]}
          onClose={() => setDeleteConfirmModalOpen(false)}
          onDelete={() => {
            refetchSweeps();
            setDeleteConfirmModalOpen(false);
          }}
        />
        <div className="report-table__cell report-table__cell--select sweep-col-select">
          <MultiStateCheckbox
            checked={selected ? 'checked' : 'unchecked'}
            onClick={onSelectChanged}
          />
        </div>
        <div className="report-table__cell report-table__cell--title sweep-col-name">
          <Link to={sweepLink}>
            <ImageIcon style={{marginRight: 12}} name="sweep" />
            <EditableLabel
              key={sweep.id}
              title={sweepDisplayName}
              onSave={(displayName: string) => {
                upsertSweep({
                  variables: {id: sweep.id, displayName},
                }).then(() => {
                  refetchSweeps();
                });
              }}
              onBlur={() => setEditingName(false)}
              editing={editingName}
              serverText={sweepDisplayName}
              onClick={() => false} // we use the menu option to trigger editing mode, rather than direct click
            />
          </Link>
        </div>
        <div className="report-table__cell sweep-col-state">
          <Link
            title={sweepStateDescription(sweep.state as SweepState)}
            to={sweepControlsLink}>
            {capitalizeFirst(sweep.state.toLowerCase())}
          </Link>
        </div>
        <div className="report-table__cell sweep-col-created">
          <Link to={sweepLink}>
            <TimeAgo date={sweep.createdAt + 'Z'} live={false} />
          </Link>
        </div>
        <div className="report-table__cell sweep-col-creator">
          <Link to={sweepLink}>
            <Image
              src={sweep.user.photoUrl}
              avatar
              onError={(i: any) => (i.target.style.display = 'none')}
            />
            {sweep.user.username}
          </Link>
        </div>
        <div className="report-table__cell sweep-col-run-count">
          <Link to={sweepLink}>{sweep.runCount}</Link>
        </div>
        <div className="report-table__cell sweep-col-compute-time">
          <Link to={sweepLink}>
            {new TimeDelta(sweep.runTime).toSingleUnitString()}
          </Link>
        </div>
        <div className="report-table__cell sweep-col-menu">
          <Dropdown
            className="dropdown-menu-icon-button"
            pointing="top right"
            trigger={
              <LegacyWBIcon
                className="report-table__action-button"
                name="overflow"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                }}
              />
            }>
            <Dropdown.Menu>
              {!readOnly && (
                <Dropdown.Item
                  icon={<LegacyWBIcon name="edit" />}
                  content="Rename sweep"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditingName(true);
                  }}
                />
              )}
              {!readOnly && (
                <Dropdown.Item
                  icon="wbic-ic-delete"
                  content="Delete sweep"
                  disabled={readOnly}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDeleteConfirmModalOpen(true);
                  }}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  },
  {id: 'SweepTableSweep'}
);

const NoSweepsMessage = makeComp(
  () => {
    const details = (
      <>
        <p>
          Sweeps are a lightweight tool for hyperparameter tuning at any scale.
        </p>
        <p>
          <TargetBlank href={docUrl.sweeps}>
            See the docs {'\u2192'}
          </TargetBlank>
        </p>
      </>
    );
    return (
      <EmptyWatermark
        imageSource={emptyImg}
        imageSource2x={emptyImg2x}
        header="Create a hyperparameter sweep. "
        details={details}
      />
    );
  },
  {id: 'NoSweepsMessage'}
);
