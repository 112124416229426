import '../css/StargazerModal.less';

import {ReportViewRef} from '../state/reports/types';
import React, {useEffect} from 'react';
import {useQuery} from '../state/graphql/query';
import {Modal, Placeholder} from 'semantic-ui-react';
import * as Generated from '../generated/graphql';
import {
  STARGAZERS_QUERY,
  StargazersQueryData,
  Stargazer,
} from '../state/views/graphql';
import {useSelector} from '../state/hooks';
import * as urls from '../util/urls';
import {Link} from 'react-router-dom';
import produce from 'immer';
import makeComp from '../util/profiler';

const TRIGGER_LOAD_BREAKPOINT = 240;

interface StargazerModalInnerProps {
  viewRef: ReportViewRef;
}

const StargazerModalInner: React.FC<StargazerModalInnerProps> = makeComp(
  props => {
    const viewServerID = useSelector(
      state => state.views.views[props.viewRef.id].id
    );

    const query = useQuery<
      StargazersQueryData,
      Generated.StargazersQueryQueryVariables
    >(STARGAZERS_QUERY, {
      fetchPolicy: 'network-only',
      variables: {viewID: viewServerID!, limit: 12},
      skip: !viewServerID,
      notifyOnNetworkStatusChange: true,
    });

    const scrolledToBottom = (el: Element) =>
      el.scrollTop >
      el.scrollHeight - el.clientHeight - TRIGGER_LOAD_BREAKPOINT;

    const checkForLoadingMore = () => {
      const modalWrapper = document.querySelector('.ui.page.modals');
      if (
        modalWrapper &&
        scrolledToBottom(modalWrapper) &&
        !query.loading &&
        query.view.stargazers.pageInfo.hasNextPage &&
        viewServerID
      ) {
        query.fetchMore({
          variables: {
            viewID: viewServerID,
            limit: 6,
            cursor: query.view.stargazers.pageInfo.endCursor,
          },
          updateQuery: (prev, {fetchMoreResult}) => {
            if (!fetchMoreResult) {
              return prev;
            }
            return produce(prev, draft => {
              draft.view.stargazers.pageInfo =
                fetchMoreResult.view.stargazers.pageInfo;
              draft.view.stargazers.edges.push(
                ...fetchMoreResult.view.stargazers.edges
              );
            });
          },
        });
      }
    };

    useEffect(() => {
      const interval = window.setInterval(checkForLoadingMore, 500);
      return () => {
        window.clearInterval(interval);
      };
    });

    let stargazers: Stargazer[] = [];
    if (!query.initialLoading) {
      stargazers = query.view.stargazers.edges.map(e => e.node);
    }

    if (!query.loading && stargazers.length === 0) {
      return (
        <Modal.Content>
          <div className="modal-header">No likes yet.</div>
          <div className="no-stars-watermark">Be the first?</div>
        </Modal.Content>
      );
    }

    return (
      <Modal.Content>
        <div className="modal-header">Likes</div>
        {stargazers.map((user, index) => (
          <React.Fragment key={user.username}>
            <div className="mini-profile-card">
              <Link to={urls.profilePage(user.username)}>
                <img src={user.photoUrl} alt="propic" />
              </Link>
              <div className="text-content">
                <Link className="username" to={urls.profilePage(user.username)}>
                  {user.username}
                </Link>
                {user.userInfo && user.userInfo.company && (
                  <div className="company">{user.userInfo.company}</div>
                )}
              </div>
            </div>
            {index !== stargazers.length - 1 && <div className="divider"></div>}
          </React.Fragment>
        ))}
        {(query.loading || query.view.stargazers.pageInfo.hasNextPage) && (
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )}
      </Modal.Content>
    );
  },
  {id: 'StargazerModalInner'}
);

interface StargazerModalProps {
  viewRef: ReportViewRef;
  open: boolean;
  onClose: () => void;
}

const StargazerModal: React.FC<StargazerModalProps> = makeComp(
  props => {
    return (
      <Modal
        className="stargazer-modal"
        open={props.open}
        onClose={props.onClose}
        onClick={(e: any) => {
          // for some reason clicking on the modal
          // without this causes re-render
          e.stopPropagation();
        }}
        size="mini">
        <StargazerModalInner viewRef={props.viewRef}></StargazerModalInner>
      </Modal>
    );
  },
  {id: 'StargazerModal'}
);

export default StargazerModal;
