import React, {useEffect} from 'react';
import Loader from './WandbLoader';
import {auth} from '../setup';
import makeComp from '../util/profiler';

const Logout: React.FC = makeComp(
  () => {
    useEffect(() => {
      auth.logout();
      // eslint-disable-next-line
    }, []);
    return <Loader />;
  },
  {id: 'Logout'}
);

export default Logout;
