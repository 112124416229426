import * as Normalize from '../normalize';
import * as Types from './types';
import * as DiscussionCommentNormalize from '../discussionComment/normalize';
import {omit as _omit} from 'lodash';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = Normalize.normFn<Types.DiscussionThreadObjSchema>(
  'discussion-thread',
  (whole, ctx) => {
    return {
      ...omit(whole, 'comments'),
      commentRefs: (whole.comments ?? []).map(comment =>
        DiscussionCommentNormalize.normalize(comment, ctx)
      ),
    };
  }
);

export const denormalize = Normalize.denormFn<Types.DiscussionThreadObjSchema>(
  (part, ctx) => {
    return {
      ...omit(part, 'commentRefs'),
      comments:
        part.commentRefs != null
          ? part.commentRefs.map(ref =>
              DiscussionCommentNormalize.denormalize(ref, ctx)
            )
          : [],
    };
  }
);
