import React, {useState, useMemo} from 'react';
import Datetime from 'react-datetime';
import makeComp from '../util/profiler';
import {
  Button,
  Modal,
  Input,
  Header,
  Form,
  Checkbox,
  CheckboxProps,
  InputOnChangeData,
  ButtonProps,
  Dropdown,
} from 'semantic-ui-react';
import {
  useUpdateOrganizationSubscriptionMutation,
  useSubscriptionPlansQuery,
  OrganizationSubscriptionType,
  OrganizationSubscriptionStatus,
} from '../generated/graphql';
import {toast} from 'react-toastify';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import _ from 'lodash';
import {getDashboardLink} from '../util/stripe';
import {isMoment} from 'moment';
import * as urls from '../util/urls';
import {TargetBlank} from '../util/links';

export interface OrganizationSubscriptionAttributes {
  id: string;
  seats: number;
  plan: {id: string; name: string};
  privileges: OrganizationSubscriptionPrivileges;
  stripeID: string | null;
  type: OrganizationSubscriptionType;
  status: OrganizationSubscriptionStatus;
  expiresAt: Date | null;
}

export interface OrganizationSubscriptionPrivileges {
  num_private_teams?: number;
  compute_hours?: number;
  org_dash_enabled?: boolean;
}

export interface UpdateOrganizationSubscriptionModalProps {
  open: boolean;
  name: string | null;
  subscriptionAttributes: OrganizationSubscriptionAttributes | null;
  billingUserEmail: string | null;
  onUpdate(): void;
  onClose(): void;
}

const UpdateOrganizationSubscriptionModal: React.FC<UpdateOrganizationSubscriptionModalProps> =
  makeComp(
    props => {
      const plans = useSubscriptionPlansQuery();
      const planOptions = useMemo(() => {
        if (plans.loading || plans.data == null) {
          return [];
        }

        return plans.data.plans
          .filter(p => p?.planType === 'PRIMARY')
          .map(p => ({
            text: _.capitalize((p?.name ?? '').replace(/_/g, ' ')),
            value: p?.id,
          }));
      }, [plans]);

      const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
      const [newNumPrivateTeams, setNewNumPrivateTeams] = useState<
        number | null
      >(null);
      const [newSeats, setNewSeats] = useState<number | null>(null);
      const [newOrgDashEnabled, setOrgDashEnabled] = useState<boolean | null>(
        null
      );
      const [newComputeHours, setNewComputeHours] = useState<number | null>(
        null
      );
      const [newStripeSubscriptionId, setNewStripeSubscriptionId] = useState<
        string | null
      >(null);
      const [selectedSubscriptionType, setSelectedSubscriptionType] =
        useState<OrganizationSubscriptionType | null>(null);
      const [newExpirationDate, setNewExpirationDate] = useState<Date | null>(
        null
      );
      const [createStripeSubscription, setCreateStripeSubscription] =
        useState(false);
      const [hasAcknowledgedUpdate, setHasAcknowledgedUpdate] = useState(false);
      const [hasAcknowledgedStripeUpdate, setHasAcknowledgedStripeUpdate] =
        useState<boolean>(false);
      const [loading, setLoading] = useState(false);

      const [updateOrganizationSubscriptionMutation] =
        useUpdateOrganizationSubscriptionMutation({
          context: propagateErrorsContext(),
        });

      const clearSelections = () => {
        setSelectedPlan(null);
        setNewSeats(null);
        setOrgDashEnabled(null);
        setNewNumPrivateTeams(null);
        setNewComputeHours(null);
        setSelectedSubscriptionType(null);
        setNewStripeSubscriptionId(null);
        setNewExpirationDate(null);
        setHasAcknowledgedUpdate(false);
        setHasAcknowledgedStripeUpdate(false);
      };

      const onCloseWrapper = () => {
        clearSelections();
        props.onClose();
      };

      const isValidNumericalInput = (seats: number | null): seats is number => {
        return !!(seats && Number.isInteger(seats) && seats > 0);
      };

      const handleUpdate = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        data: ButtonProps
      ) => {
        setLoading(true);
        if (props.subscriptionAttributes == null) {
          return;
        }

        // The backend merges only the changed privileges, so we don't
        // need to worry about default values here.
        const pendingPrivileges: OrganizationSubscriptionPrivileges = {
          org_dash_enabled: newOrgDashEnabled ?? undefined,
          num_private_teams: newNumPrivateTeams ?? undefined,
          compute_hours: newComputeHours ?? undefined,
        };
        const newPrivileges = _.isEmpty(pendingPrivileges)
          ? null
          : JSON.stringify(pendingPrivileges);

        try {
          const updateSubscriptionResult =
            await updateOrganizationSubscriptionMutation({
              variables: {
                organizationSubscriptionID: props.subscriptionAttributes.id,
                planID: selectedPlan,
                seats: newSeats,
                privileges: newPrivileges,
                type: selectedSubscriptionType,
                stripeSubscriptionId: newStripeSubscriptionId,
                expiresAt: newExpirationDate,
                createStripeSubscription,
              },
            });

          if (updateSubscriptionResult.errors) {
            toast(
              `Error updating subscription: ${updateSubscriptionResult.errors}`
            );
          } else {
            props.onUpdate();
            clearSelections();
          }
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err) ?? err.message;
          toast(`Error updating subscription: ${errMsg}`);
        }
        setLoading(false);
      };

      const handleCheckboxInput =
        (setFunc: (b: boolean) => void) =>
        (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
          setFunc(!!data.checked);
        };

      const handleUpdateAcknowledgement = handleCheckboxInput(
        setHasAcknowledgedUpdate
      );
      const handleUpdateStripeAcknowledgement = handleCheckboxInput(
        setHasAcknowledgedStripeUpdate
      );
      const handleCreateStripeSubscription = handleCheckboxInput(
        setCreateStripeSubscription
      );

      const handleNumericalInput =
        (setFunc: (n: number | null) => void) =>
        (
          event: React.ChangeEvent<HTMLInputElement>,
          data: InputOnChangeData
        ) => {
          if (data.value === '') {
            setFunc(null);
          } else {
            const inputValue = parseInt(data.value, 10);
            if (isValidNumericalInput(inputValue)) {
              setFunc(inputValue);
            } else {
              setFunc(null);
            }
          }
        };

      // TODO: Memoize?
      const handleNewSeatInput = handleNumericalInput(setNewSeats);
      const handleNumPrivateTeamsInput = handleNumericalInput(
        setNewNumPrivateTeams
      );
      const handleComputeHoursInput = handleNumericalInput(setNewComputeHours);

      const handleNewOrgDashEnabledInput =
        handleCheckboxInput(setOrgDashEnabled);

      const handleStripeSubscriptionInput = (
        event: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
      ) => {
        if (data.value === '') {
          setNewStripeSubscriptionId(null);
        } else {
          setNewStripeSubscriptionId(data.value as string);
        }
      };

      return (
        <Modal
          open={props.open && props.subscriptionAttributes != null}
          onClose={onCloseWrapper}>
          <Header>{`${props.name}: Edit Subscription`}</Header>
          <Modal.Content>
            <Form size="large">
              <Form.Field inline>
                <label>Choose plan</label>
                <Dropdown
                  className="trial-input"
                  selection
                  value={
                    selectedPlan ??
                    props.subscriptionAttributes?.plan.id ??
                    undefined
                  }
                  placeholder={'Select a plan'}
                  options={planOptions}
                  onChange={(e, data) => {
                    setCreateStripeSubscription(false);
                    setSelectedPlan(data.value as string);
                  }}
                />
              </Form.Field>
              <Form.Field inline>
                <label>Number of private teams</label>
                <Input
                  data-test="private-team-input"
                  value={newNumPrivateTeams ?? ''}
                  placeholder={`${props.subscriptionAttributes?.privileges?.num_private_teams} (Current)`}
                  onChange={handleNumPrivateTeamsInput}
                />
              </Form.Field>
              <Form.Field inline>
                <label>Number of seats</label>
                <Input
                  data-test="seat-count-input"
                  value={newSeats ?? ''}
                  placeholder={`${props.subscriptionAttributes?.seats} (Current)`}
                  onChange={handleNewSeatInput}
                />
              </Form.Field>
              {props.subscriptionAttributes?.privileges?.compute_hours && (
                <Form.Field inline>
                  <label>Compute hour limit</label>
                  <Input
                    data-test="compute-hour-input"
                    value={newComputeHours ?? ''}
                    placeholder={`${props.subscriptionAttributes?.privileges?.compute_hours} (Current)`}
                    onChange={handleComputeHoursInput}
                  />
                </Form.Field>
              )}
              <Form.Field inline>
                <label>
                  Organization dashboard (
                  <TargetBlank href={urls.orgPage(props.name ?? 'not-found')}>
                    preview ⧉
                  </TargetBlank>
                  )
                </label>
                <Checkbox
                  checked={
                    newOrgDashEnabled ??
                    props.subscriptionAttributes?.privileges
                      ?.org_dash_enabled ??
                    false
                  }
                  onChange={handleNewOrgDashEnabledInput}
                />{' '}
              </Form.Field>
              <Form.Field inline>
                <label>Subscription type</label>
                <Dropdown
                  className="trial-input"
                  selection
                  value={
                    selectedSubscriptionType ??
                    props.subscriptionAttributes?.type ??
                    undefined
                  }
                  placeholder={'Select a plan'}
                  options={Object.values(OrganizationSubscriptionType).map(
                    t => ({
                      text: t,
                      value: t,
                    })
                  )}
                  onChange={(e, data) => {
                    setSelectedSubscriptionType(
                      data.value as OrganizationSubscriptionType
                    );
                  }}
                />
              </Form.Field>
              <Form.Field inline>
                <label>Stripe subscription ID</label>
                <Input
                  disabled={
                    selectedSubscriptionType !== 'STRIPE' ||
                    createStripeSubscription
                  }
                  data-test="strip-id-input"
                  value={newStripeSubscriptionId ?? ''}
                  placeholder={`${
                    props.subscriptionAttributes?.stripeID ?? 'None'
                  } (Current)`}
                  onChange={handleStripeSubscriptionInput}
                />
                <label style={{marginLeft: '8px', marginRight: '8px'}}>
                  Create stripe subscription
                </label>
                <Checkbox
                  disabled={selectedSubscriptionType !== 'STRIPE'}
                  placeholder="Create stripe subscription"
                  onChange={handleCreateStripeSubscription}
                />
              </Form.Field>
              <Form.Field inline>
                <label>Expiration date (trials only)</label>
                {['USER_LED_TRIAL', 'MANUAL_TRIAL', 'ACADEMIC_TRIAL'].some(
                  t =>
                    t === selectedSubscriptionType ||
                    (selectedSubscriptionType == null &&
                      t === props.subscriptionAttributes?.type)
                ) ? (
                  <Datetime
                    className="date-picker"
                    value={
                      newExpirationDate ??
                      (props.subscriptionAttributes?.expiresAt
                        ? new Date(props.subscriptionAttributes.expiresAt)
                        : undefined)
                    }
                    onChange={d =>
                      isMoment(d) && setNewExpirationDate(d.toDate())
                    }
                  />
                ) : (
                  <Input disabled />
                )}
              </Form.Field>
              <Form.Field inline>
                <label>
                  I understand that this change modifies the subscription in the
                  W&B application,{' '}
                  <i>
                    <u>
                      {createStripeSubscription
                        ? 'and creates a subscription record in stripe (which I will verify after creation).'
                        : 'and I need to go into stripe separately to update subscriptions there.'}
                    </u>
                  </i>
                  {props.subscriptionAttributes?.stripeID && (
                    <span>
                      <br />
                      <br />
                      Organization's Stripe link:{' '}
                      <TargetBlank
                        href={getDashboardLink(
                          props.subscriptionAttributes?.stripeID
                        )}>
                        {getDashboardLink(
                          props.subscriptionAttributes?.stripeID
                        )}
                      </TargetBlank>
                    </span>
                  )}
                  <br />
                  <br />
                </label>
                <label>Acknowledgement:&nbsp;&nbsp;</label>
                <Checkbox
                  placeholder="Update Subscription Acknowledgement"
                  onChange={handleUpdateAcknowledgement}
                />
              </Form.Field>
              {newStripeSubscriptionId && (
                <Form.Field inline>
                  <label>
                    By modifying the stripe subscription ID, I guarantee I have
                    taken all due care to ensure that the indicated stripe
                    subscription corresponds to this organization. In
                    particular, I guarantee that the stripe subscription's user
                    is the same as the organization's billing user.
                    <span>
                      <br />
                      <br />
                      Stripe subscription link:{' '}
                      <TargetBlank
                        href={getDashboardLink(newStripeSubscriptionId)}>
                        {getDashboardLink(newStripeSubscriptionId)}
                      </TargetBlank>
                      <br />
                      Organization billing user email: {props.billingUserEmail}
                    </span>
                    <br />
                    <br />
                  </label>
                  <label>Acknowledgement:&nbsp;&nbsp;</label>
                  <Checkbox
                    placeholder="Update Stripe Ackknowledgement"
                    onChange={handleUpdateStripeAcknowledgement}
                  />
                </Form.Field>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Cancel" onClick={onCloseWrapper} />
            <Button
              data-test="update-seats-btn"
              primary
              loading={loading}
              disabled={
                loading ||
                !hasAcknowledgedUpdate ||
                (newStripeSubscriptionId != null &&
                  !hasAcknowledgedStripeUpdate)
              }
              onClick={handleUpdate}>
              Update Subscription
            </Button>
          </Modal.Actions>
        </Modal>
      );
    },
    {
      id: 'UpdateOrganizationSubscriptionModal',
      memo: true,
    }
  );

export default UpdateOrganizationSubscriptionModal;
