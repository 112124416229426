import {useCallback, useEffect, useRef} from 'react';

import {ThunkResult} from '../../types/redux';
import {ID} from '@wandb/cg/browser/utils/string';
import {modifyQueryString} from '../../util/url';

import {useDispatch, usePropsSelector} from '../hooks';
import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Types from './types';
import * as Url from './url';
import * as WorkspaceUtil from './util';

export function useWorkspaceInit(
  entityName: string,
  projectName: string,
  viewType: Types.WorkspaceTypes,
  defaultSpec: Types.WorkspaceMaterialized,
  // If specified, we'll create one workspace per combination of viewType
  // and workspaceObjectID per user. We use this for sweeps to get a new
  // workspace for every sweep.
  workspaceObjectID?: string
) {
  const workspaceIDRef = useRef('workspace-' + ID(9));
  const workspaceID = workspaceIDRef.current;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      Actions.init(
        workspaceID,
        entityName,
        projectName,
        viewType,
        Url.readUrl(),
        defaultSpec,
        workspaceObjectID
      )
    );

    return () => dispatch(Actions.deinit(workspaceID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return workspaceID;
}

// Hook that simplifies dispatching redux workspace actions, by binding them
// to a specific view part reference.
export function useWorkspaceAction<ArgType extends any[], R>(
  workspaceID: string,
  fn: (ref: string, ...fnArgs: ArgType) => ThunkResult<R>
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(workspaceID, ...args)),
    [dispatch, workspaceID, fn]
  );
}

export function useViewUrlEffect(workspaceID: string) {
  const workspaceView = usePropsSelector(
    Selectors.makeWorkspaceViewSelector,
    workspaceID
  );
  let workspaceName = '';
  if (!workspaceView.loading) {
    const {view} = workspaceView;
    const workspaceType = WorkspaceUtil.workspaceType(view.name);
    if (workspaceType === 'default') {
      workspaceName = 'default';
    } else if (workspaceType === 'workspace') {
      workspaceName = `user-${view.user.username}`;
    }
  }
  useEffect(() => {
    modifyQueryString(current => ({...current, workspace: workspaceName}));
  }, [workspaceName]);
}
