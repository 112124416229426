import * as S from './Egg.styles';
import React from 'react';
import {useViewer} from '../state/viewer/hooks';

export interface EggProps {
  className?: string;
}

const Egg: React.FC<EggProps> = ({className}) => {
  const [pos, setPos] = React.useState<{x: number; y: number}>({
    x: 10000,
    y: 10000,
  });
  const [angle, setAngle] = React.useState(0);

  React.useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setPos(p => {
        const dist = Math.sqrt((e.clientX - p.x) ** 2 + (e.clientY - p.y) ** 2);
        const desiredDist = 64;

        let newX: number;
        let newY: number;

        if (dist >= desiredDist) {
          return p;
        }

        const atan = Math.atan((p.y - e.clientY) / (e.clientX - p.x));
        const dir = e.clientX > p.x ? atan : atan + Math.PI;

        const pushDir = dir + Math.PI;
        const pushDist = desiredDist - dist;

        setAngle(-pushDir + Math.PI / 2);

        newX = p.x + Math.cos(pushDir) * pushDist;
        newY = p.y - Math.sin(pushDir) * pushDist;

        if (newY > window.innerHeight - 23 && newX > window.innerWidth - 27) {
          if (p.y - e.clientY > p.x - e.clientX) {
            newX = p.x - (p.x - e.clientX);
          } else {
            newY = p.y - (p.y - e.clientY);
          }
        }

        if (newY > window.innerHeight - 23) {
          newY = window.innerHeight - 23;
        }

        if (newX > window.innerWidth - 27) {
          newX = window.innerWidth - 27;
        }

        return {x: newX, y: newY};
      });
    };

    const reposition = () => {
      setPos(p => ({
        x: Math.max(Math.min(p.x, window.innerWidth - 23), 23),
        y: Math.max(Math.min(p.y, window.innerHeight - 27), 27),
      }));
    };

    const onResize = () => {
      reposition();
    };

    reposition();

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <S.Wrapper className={className} x={pos.x} y={pos.y} angle={angle}>
      <S.Stripe></S.Stripe>
      <S.Stripe></S.Stripe>
      <S.Stripe></S.Stripe>
      <S.Stripe></S.Stripe>
      <S.Stripe></S.Stripe>
    </S.Wrapper>
  );
};

const ConditionalEgg = () => {
  const viewer = useViewer();

  if (
    (viewer?.email.includes('wandb') && Math.random() < 0.004) ||
    (new Date().getMonth() === 3 && new Date().getDate() === 4)
  ) {
    return <Egg />;
  }

  return null;
};

export default ConditionalEgg;
