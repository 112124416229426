import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {Icon} from 'semantic-ui-react';

import {constNumber, constString} from '@wandb/cg/browser/ops';
import * as HL from '@wandb/cg/browser/hl';
import * as CG from '@wandb/cg/browser/graph';
import * as Suggest from '@wandb/cg/browser/suggest';
import * as Types from '@wandb/cg/browser/model/types';
import * as CGTypes from '@wandb/cg/browser/types';
import * as CGParser from '@wandb/cg/browser/parser';
import {toString} from '@wandb/cg/browser/hl';
import {Client} from '@wandb/cg/browser';
import {AutosuggestResult} from '@wandb/cg/browser/suggest';

import {BLOCK_POPUP_CLICKS_CLASSNAME} from '../../util/semanticHacks';
import * as CGReact from '../../cgreact';
import {toast} from '../elements/Toast';
import LinkButton from '../LinkButton';
import * as Panel2 from './panel';
import {ToastIconContainer} from './ExpressionEditor.styles';

interface ExpressionEditorExternalState {
  node: CGTypes.EditingNode;
  frame: {[argName: string]: Types.Node};
  debug: boolean;
}

interface ExpressionEditorInternalState {
  buffer: string;
  tailOpKey: number;

  // Focus on void, var, or output node means the user is chaining an
  // an op. Focus on a const node means they're editing that const.
  // Focus on an Op means editing an op name.
  focus?: CGTypes.EditingNode | CGTypes.EditingOp;
  cursorPos?: number;

  suggestions: Array<AutosuggestResult<any>>;

  showPlainText: boolean;
  isEditingPlainText: boolean;
  plainTextHasError: boolean;
}

function nodeOrOpToString(
  nodeOrOp: CGTypes.EditingNode | CGTypes.EditingOp,
  graph: CGTypes.EditingNode
) {
  return HL.isEditingNode(nodeOrOp)
    ? `N: ${HL.toString(nodeOrOp, null)}`
    : `Op: ${HL.opToString(nodeOrOp, graph, null)}`;
}

export interface LogContext {
  debug?: boolean;
  origin: string;
  originNodeOrOp?: CGTypes.EditingNode | CGTypes.EditingOp;
  graph: CGTypes.EditingNode;
}
function log(ctx: LogContext, message: string) {
  if (!ctx.debug) {
    return;
  }

  console.groupCollapsed(
    `From: ${ctx.origin}${
      ctx.originNodeOrOp
        ? ` (${nodeOrOpToString(ctx.originNodeOrOp, ctx.graph)})`
        : ''
    }
    ${message}`
  );
  console.trace();
  console.groupEnd();
}

export type ExpressionEditorState = ExpressionEditorExternalState &
  ExpressionEditorInternalState;

export async function updateNodeAndFocus(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  logContext: LogContext,
  newNodeOrOp: CGTypes.EditingNode | CGTypes.EditingOp,
  forceFocus?: {
    nodeOrOpToFocus: CGTypes.EditingNode | CGTypes.EditingOp;
    initialCursorAtEnd?: boolean;
  }
): Promise<void> {
  log(
    logContext,
    `Replacing current focus (${
      state.focus ? nodeOrOpToString(state.focus, state.node) : ''
    }) with new node or op (${nodeOrOpToString(newNodeOrOp, state.node)})`
  );
  if (state.focus == null) {
    throw new Error('invalid');
  }

  let newGraph: CGTypes.EditingNode<Types.Type>;
  let inferredFocus: CGTypes.EditingNode | CGTypes.EditingOp;
  if (HL.isEditingOp(newNodeOrOp)) {
    // the replacement is an Op

    if (HL.isEditingNode(state.focus)) {
      throw new Error(
        `Can't replace node ${toString(state.focus)} with op ${HL.opToString(
          newNodeOrOp,
          state.node
        )}`
      );
    }

    newGraph = HL.replaceOp(state.node, state.focus, newNodeOrOp);

    // TODO: assertion that op replace didn't change type

    inferredFocus = newNodeOrOp;

    setState({
      ...state,
      node: newGraph,
      focus: inferredFocus,
    });
    return;
  }

  // the replacement is a Node

  if (HL.isEditingOp(state.focus)) {
    throw new Error(
      `Can't replace op ${state.focus.name} with node ${newNodeOrOp}`
    );
  }
  if (newNodeOrOp.nodeType === 'void') {
    throw new Error('invalid');
  }

  // First improve the node if we can
  if (newNodeOrOp.nodeType === 'output') {
    newNodeOrOp = await HL.refineEditingNode(client, newNodeOrOp, state.frame);
  }

  newGraph = await HL.replaceNodeAndUpdateDownstreamTypes(
    client,
    state.node,
    state.focus,
    newNodeOrOp,
    state.frame
  );

  inferredFocus = newNodeOrOp;
  if (newNodeOrOp.nodeType === 'output') {
    const inputs = Object.values(newNodeOrOp.fromOp.inputs);

    for (const input of inputs) {
      if (HL.isFunctionLiteral(input) && CG.isVoidNode(input.val)) {
        inferredFocus = input.val;
        break;
      } else if (input.nodeType === 'void') {
        inferredFocus = input;
        break;
      }
    }
  } else if (
    HL.isFunctionLiteral(newNodeOrOp) &&
    CG.isVoidNode(newNodeOrOp.val)
  ) {
    inferredFocus = newNodeOrOp.val;
  }

  // if the newly inserted node is (or contains) an empty string literal,
  // the user probably wanted to edit it, so we'll focus it
  //
  // WARNING: this could create some odd behavior if we implement suggestions
  // that have empty strings in them we don't mean to edit. Parenthesization
  // suggestions seems like a place this might happen, say if the user already
  // had an empty string in the expression they're reparenthesizing
  if (inferredFocus === newNodeOrOp) {
    const emptyStringLiteral = HL.filterNodes(
      newNodeOrOp,
      node => node.nodeType === 'const' && node.val === ''
    );
    if (emptyStringLiteral.length > 0) {
      setState({
        ...state,
        node: newGraph,
        focus: emptyStringLiteral[0],
      });
      return;
    }
  }

  if (inferredFocus === newNodeOrOp) {
    while (inferredFocus !== newGraph) {
      const consumer = HL.findConsumingOp(inferredFocus, newGraph);
      if (consumer == null) {
        // No more downstream nodes to check
        break;
      }
      if (consumer != null) {
        const opDef = CG.getOpDef(consumer.outputNode.fromOp.name);
        const consumerOutputNode = consumer.outputNode;
        const argNames = Object.keys(consumer.outputNode.fromOp.inputs);
        const argNodes = Object.values(consumer.outputNode.fromOp.inputs);
        const argIndex = argNames.indexOf(consumer.argName);
        if (argIndex === -1) {
          throw new Error('invalid');
        }
        const voidIndex =
          // manyX is the weird array op that takes multiple parameters, just ignore it for now.
          opDef.inputTypes.manyX != null
            ? -1
            : argNodes.findIndex(
                (n, i) =>
                  i >= argIndex &&
                  (n.nodeType === 'void' ||
                    !Types.isAssignableTo(
                      n.type,
                      opDef.inputTypes[argNames[i]]
                    ))
              );
        if (voidIndex !== -1) {
          inferredFocus = argNodes[voidIndex];
          break;
        }
        inferredFocus = consumerOutputNode;
      }
    }
  }

  setState({
    ...state,
    node: newGraph,
    focus: forceFocus != null ? forceFocus.nodeOrOpToFocus : inferredFocus,
    cursorPos: forceFocus?.initialCursorAtEnd ? -1 : state.cursorPos,
    buffer: '',
  });
}

export async function updateNode(
  context: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  node: CGTypes.EditingNode,
  newNodeOrOp: CGTypes.EditingNode
): Promise<void> {
  let newGraph: CGTypes.EditingNode<Types.Type>;
  newGraph = await HL.replaceNodeAndUpdateDownstreamTypes(
    context,
    state.node,
    node,
    newNodeOrOp,
    state.frame
  );

  setState({
    ...state,
    node: newGraph,
    suggestions: [],
  });
}

export async function updateConstFunctionNode(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  constFunctionNode: CGTypes.EditingNode,
  replacementFunctionNode: CGTypes.EditingNode
): Promise<void> {
  // If the user picked an op that returns a function, then we
  // can just use replacementFunctionNode directly, its now an
  // output node that gives us our function argument.
  // Otherwise, the user has constructed a new function, keep it
  // as a const node.
  const newNode = Types.isFunction(replacementFunctionNode.type)
    ? replacementFunctionNode
    : {
        ...constFunctionNode,
        val: replacementFunctionNode,
      };
  const newGraph = await HL.replaceNodeAndUpdateDownstreamTypes(
    client,
    state.node,
    constFunctionNode,
    newNode,
    state.frame
  );

  setState({
    ...state,
    node: newGraph,
  });
}

export async function focusOnTail(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void
): Promise<void> {
  setState({
    ...state,
    focus: state.node,
  });
}

export async function focusNodeOrOp(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  logContext: LogContext,
  nodeOrOp: CGTypes.EditingNode | CGTypes.EditingOp,
  initialCursorAtEnd: boolean = false,
  initialBuffer?: string
) {
  log(logContext, `focusing (${nodeOrOpToString(nodeOrOp, state.node)})`);
  if (
    state.focus !== nodeOrOp ||
    (initialBuffer !== undefined && initialBuffer !== state.buffer)
  ) {
    log(logContext, 'setting state');
    setState({
      ...state,
      focus: nodeOrOp,
      cursorPos: initialCursorAtEnd ? -1 : undefined,
      buffer: initialBuffer ?? state.buffer,
    });
  }
}

export async function blurNodeOrOp(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  logContext: LogContext,
  nodeOrOp: CGTypes.EditingNode | CGTypes.EditingOp
) {
  log(logContext, 'blurring');
  if (state.focus === nodeOrOp) {
    log(logContext, 'setting state');
    setState({
      ...state,
      focus: undefined,
      buffer: '',
    });
  }
}

export async function deletePrev(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  logContext: LogContext
): Promise<void> {
  log(logContext, `Deleting in ${HL.toString(state.node, null)}`);
  if (
    state.focus == null ||
    (state.focus === state.node && state.node.nodeType === 'void') || // this is the "trying to delete an empty expression" case
    HL.isEditingOp(state.focus)
  ) {
    // TODO: figure out delete-on-focused op
    // right now, we delete ops by focusing args to the op and deleting those,
    // then deleting again on voided args (see below).

    // but since we're making ops focusable now, we'll need to cover this case.
    // might be as simple as running deletePrev as if one of the args is focused,
    // but should think it through.
    return;
  }

  // in the next block, we'll generate a version of the expression where
  // we've performed a deletion (updatedNode) and select the node that
  // should be focused in the editor after deletion is complete (nextFocus)
  let updatedNode = state.node;
  let nextFocus = state.focus;

  // We keep track of whether we've replaced a node in the graph with
  // void, because we need to refine in that case.
  let replacedANodeWithVoid = false;
  const replaceNode = (
    toReplace: CGTypes.EditingNode,
    replaceWith: CGTypes.EditingNode
  ) => {
    updatedNode = HL.replaceNode(state.node, toReplace, replaceWith);
    if (replaceWith.nodeType === 'void') {
      replacedANodeWithVoid = true;
    }
  };

  const rightMostAncestor = HL.rightMostToDelete(nextFocus);
  const {argIndex, outputNode} =
    HL.findConsumingOp(rightMostAncestor, state.node) || {};

  // which delete behavior we should use depends on what our consumer is,
  // and where we fall in its list of arguments
  if (
    nextFocus.nodeType === 'output' &&
    ((HL.isDotChainedOp(nextFocus.fromOp) &&
      Object.keys(nextFocus.fromOp.inputs).length === 1) ||
      Panel2.isPanelOpName(nextFocus.fromOp.name))
  ) {
    // Special case for chained unary op (takes no args inside ()).
    // Delete the whole op.

    nextFocus = Object.values(nextFocus.fromOp.inputs)[0];
    replaceNode(state.focus, nextFocus);
  } else if (outputNode == null || argIndex == null) {
    // we have no consumer: focus is the root node of the expression or of a function
    // literal's body
    if (
      rightMostAncestor.nodeType === 'void' &&
      rightMostAncestor !== state.node
    ) {
      // we aren't the root of the entire expression, but we have no op consumer:
      // this means we're the root of a function literal.

      // we'll need to find the function we're a part of, and delete that
      const functionNodes = HL.filterNodes(
        state.node,
        node => HL.isFunctionLiteral(node) && node.val === rightMostAncestor
      );

      if (functionNodes.length === 0) {
        throw new Error(
          `Can't find void node that should be root of function literal in expression ${state.node}`
        );
      }

      if (functionNodes.length > 1) {
        throw new Error(
          `void node was the root of more than one function literal in expression ${state.node}`
        );
      }

      nextFocus = CG.voidNode();
      replaceNode(functionNodes[0], nextFocus);
    } else if (rightMostAncestor.nodeType === 'output') {
      // we should try to delete the rightmost component of the output node at the
      // root.
      const args = Object.values(rightMostAncestor.fromOp.inputs);

      if (args.length === 0) {
        // no args -- presumably a function that takes nothing
        // can be deleted
        // e.g. foo() -> _
        nextFocus = CG.voidNode();
      }

      const first = args[0];
      const rest = args.slice(1);

      let firstNonVoid: CGTypes.EditingNode | undefined;

      for (let i = rest.length - 1; i >= 0; i--) {
        const argNode = rest[i];
        if (argNode.nodeType !== 'void') {
          firstNonVoid = argNode;
          break;
        }
      }

      if (firstNonVoid) {
        // there is a non-void arg within the tail op -- we can delete it
        // e.g. foo(3, x) -> foo(3, _)
        // e.g. x.foo(3, y) -> x.foo(3, _)
        nextFocus = CG.voidNode();
        replaceNode(firstNonVoid, nextFocus);
      } else {
        // all args not in the first position are void -- the first may or may not also
        // be void; it doesn't really matter: at this point we want to replace the op
        // with its (potentially void) first argument
        // e.g. x + _ -> x
        // e.g. x.foo() -> x
        // e.g. x[] -> x
        nextFocus = first;
        replaceNode(rightMostAncestor, nextFocus);
      }
    } else {
      // all non-output nodes in the root position can be deleted directly
      // e.g. 3 -> _
      // e.g. x -> _
      nextFocus = CG.voidNode();
      replaceNode(rightMostAncestor, nextFocus);
    }
  } else {
    const argNodes = Object.values(outputNode.fromOp.inputs);

    if (HL.isBinaryOp(outputNode.fromOp)) {
      // our consumer is a binary infix operator
      if (argIndex === 1) {
        // we're the right child
        if (nextFocus.nodeType !== 'void') {
          // we're NOT already void, so void us out
          // e.g. 3 + 4 -> 3 + _
          nextFocus = CG.voidNode();
          replaceNode(rightMostAncestor, nextFocus);
        } else {
          // replace the binary operator with the left child
          // e.g. 3 + 4 -> 3
          nextFocus = argNodes[0];
          replaceNode(outputNode, nextFocus);
        }
      } else {
        // we're the left child
        if (nextFocus.nodeType !== 'void') {
          // we're NOT already void, so void us out
          // e.g. 3 + 4 -> _ + 4
          nextFocus = CG.voidNode();
          replaceNode(rightMostAncestor, nextFocus);
        } else {
          // we are already void, so delete the entire binary operator
          // e.g. _ + 4 -> _
          nextFocus = CG.voidNode();
          replaceNode(outputNode, nextFocus);
        }
      }
    } else if (HL.isBracketsOp(outputNode.fromOp)) {
      // our consumer is pick (x["foo"]) or index (x[1])
      // these are special cases.
      // regardless of which argument we are, erase the entire op
      // e.g. x["foo"] -> _

      if (outputNode.fromOp.name === 'pick') {
        nextFocus = outputNode.fromOp.inputs.obj;
      } else {
        nextFocus = outputNode.fromOp.inputs.arr;
      }
      replaceNode(outputNode, nextFocus);
    } else if (HL.isDotChainedOp(outputNode.fromOp)) {
      // our consumer is a chained unary operator (x.foo(a,b))

      if (
        argIndex === 0 ||
        (argIndex === 1 && rightMostAncestor.nodeType === 'void')
      ) {
        // we are the first argument (the thing on which we're chaining),
        // OR, we're the first thing inside the parens and we've already been deleted
        // either way: that means we're trying to delete this link off the chain
        // replace us with the thing we chained off of
        // e.g. x.foo() -> x
        // e.g. x.foo(_, 3) -> x (where the cursor is at the _)
        nextFocus = argNodes[0];
        replaceNode(outputNode, nextFocus);
      } else {
        // we're not the first argument (so, we're something inside the parens)
        // delete us
        // e.g. x.foo(1, 2) -> x.foo(1, _)
        // e.g. x.foo(1) -> x.foo(_)
        nextFocus = CG.voidNode();
        replaceNode(rightMostAncestor, nextFocus);

        if (argIndex > 1) {
          // we're not the first thing inside the parentheses, so move the cursor
          // one arg to the left.

          // to see the intention, consider:
          // x.foo(1, 2)
          // if you delete the 2, you should end up focused on the 1 -- but
          // if you delete the 1, you should STAY focused on where the 1 used
          // to be until you delete again and kill the whole chaining
          // operator.

          // this way, if you change your mind -- decide to add the 1 back in --
          // you'll be able to type and replace the thing you just got rid of.
          // if we moved the focus to arg 0, you'd be outside the parens and would
          // have to navigate back in
          nextFocus = argNodes[argIndex - 1];
        }
      }
    } else {
      // our consumer is a function call

      if (argIndex !== 0 || rightMostAncestor.nodeType !== 'void') {
        // either we're not the first argument, OR
        // we're the first argument and we're non void,
        // delete us
        // e.g. function(3, 2) -> function(3, _) -> function(_, _)
        nextFocus = CG.voidNode();
        replaceNode(rightMostAncestor, nextFocus);

        if (argIndex > 0) {
          // same as in unary operator branch above; shift one to the left
          nextFocus = argNodes[argIndex - 1];
        }
      } else {
        // we're the first argument AND already void
        // delete the function call operation
        // e.g. function(_, _) -> _
        nextFocus = CG.voidNode();
        replaceNode(outputNode, nextFocus);
      }
    }
  }
  // Kind of a crazy hack, but works. We need to refine if we've swapped something
  // to a void, so that voids propagate through and invalidate the expression.
  // We don't refine all the time because refine changes all the nodes in the graph
  // and breaks our focus reference. However, refine leaves void nodes alone, so
  // it works in the case we need it to.
  if (replacedANodeWithVoid) {
    updatedNode = await HL.refineEditingNode(client, updatedNode, state.frame);
  }

  setState({
    ...state,
    node: updatedNode,
    focus: nextFocus,
  });
}

export async function setBuffer(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  newBuffer: string
) {
  if (!state.focus || HL.isEditingOp(state.focus)) {
    setState({...state, buffer: newBuffer});
    return;
  }
  let newTail = state.node;
  let newFocus = state.focus;
  let cursorPos: number | undefined;
  const consumer = HL.findConsumingOp(state.focus, state.node);
  if (consumer != null) {
    const opDef = CG.getOpDef(consumer.outputNode.fromOp.name);
    const argType = opDef.inputTypes[consumer.argName];
    const isNumberArg = Types.isAssignableTo('number', argType);
    const isStringArg = Types.isAssignableTo('string', argType);

    if (state.focus.nodeType === 'void' && isNumberArg) {
      // Swap to a const number node if the user starts typing a number
      const matchNum = newBuffer.match(/^\d/);
      if (matchNum != null) {
        const newConstNode = constNumber(parseFloat(matchNum[0]));
        newTail = await HL.refineEditingNode(
          client,
          HL.replaceNode(state.node, state.focus, newConstNode),
          state.frame
        );
        newFocus = newConstNode;
        cursorPos = 1;
      }
    } else if (state.focus.nodeType === 'const' && isNumberArg) {
      if (newBuffer === '') {
        // Swap back to a void node if user deletes a number node
        const newVoidNode = CG.voidNode();
        newTail = await HL.refineEditingNode(
          client,
          HL.replaceNode(state.node, state.focus, newVoidNode),
          state.frame
        );
        newFocus = newVoidNode;
      } else {
        const parsed = Number.parseFloat(newBuffer);

        if (!Number.isNaN(parsed) && parsed !== state.focus.val) {
          // eagerly update the value of a number node whenever the user enters
          // a valid number
          newFocus = {
            ...state.focus,
            val: parsed,
          };
          newTail = HL.replaceNode(state.node, state.focus, newFocus);
        }
      }
    } else if (state.focus.nodeType === 'const' && isStringArg) {
      newFocus = {
        ...state.focus,
        val: newBuffer,
      };
      newTail = HL.replaceNode(state.node, state.focus, newFocus);
    }
  }

  setState({
    ...state,
    node: newTail,
    focus: newFocus,
    buffer: newBuffer,
    cursorPos,
  });
}

export async function toggleShowPlainText(
  context: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void
) {
  if (state.showPlainText) {
    setState({
      ...state,
      showPlainText: false,
      isEditingPlainText: false,
    });
  } else {
    setState({
      ...state,
      showPlainText: true,
    });
  }
}

export async function setIsEditingPlainText(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  isEditingPlainText: boolean
) {
  if (state.isEditingPlainText && !isEditingPlainText) {
    setState({
      ...state,
      isEditingPlainText: false,
    });
  } else {
    const parsed = await CGParser.parseCG(
      client,
      toString(state.node),
      state.frame
    );
    const plainTextHasError = !!(
      !parsed || !HL.allVarsWillResolve(parsed, state.frame)
    );
    setState({
      ...state,
      isEditingPlainText,
      plainTextHasError,
    });
  }
}

export async function setPlainTextExpression(
  client: Client,
  state: ExpressionEditorState,
  setState: (newState: ExpressionEditorState) => void,
  plainTextExpression: string
) {
  const parsed = await CGParser.parseCG(
    client,
    plainTextExpression,
    state.frame
  );

  if (parsed) {
    const node = await HL.refineEditingNode(client, parsed, state.frame);
    const plainTextHasError =
      !node || !HL.allVarsWillResolve(node, state.frame);
    setState({
      ...state,
      node,
      plainTextHasError,
    });
  } else {
    setState({...state, plainTextHasError: true});
  }
}

interface EEContextState {
  state: ExpressionEditorState;
  error: any;
  setState(newState: ExpressionEditorState): void;
  setError(newError: any): void;
}

const EEContext = React.createContext<EEContextState | undefined>(undefined);

export const EEContextProvider: React.FC<{
  node: CGTypes.EditingNode;
  frame?: {[argName: string]: Types.Node};
  debug?: boolean;
  updateNode(newNode: CGTypes.EditingNode): void;
}> = ({node, frame, debug, updateNode: propUpdateNode, children}) => {
  const [error, setError] = useState();
  const [state, setState] = useState<ExpressionEditorInternalState>({
    buffer: '',
    tailOpKey: 0,
    showPlainText: false,
    isEditingPlainText: false,
    plainTextHasError: false,
    suggestions: [],
  });
  const autosuggest = CGReact.useClientBound(Suggest.autosuggest);
  const lookingUpSuggestionsForState =
    React.useRef<ExpressionEditorState | null>(null);

  const setStateAndUpdateNode = useCallback(
    (newState: ExpressionEditorState) => {
      if (newState.node !== node) {
        propUpdateNode(newState.node as any);
      }

      setState(newState);

      if (
        frame &&
        (newState.focus !== state.focus ||
          newState.node !== node ||
          newState.buffer !== state.buffer)
      ) {
        if (!newState.focus) {
          setState({
            ...newState,
            suggestions: [],
          });
          return;
        }

        lookingUpSuggestionsForState.current = newState;
        autosuggest(newState.focus, newState.node, frame, newState.buffer).then(
          newSuggestions => {
            if (lookingUpSuggestionsForState.current !== newState) {
              // it's possible that looking up the suggestions took so long that
              // the user has already changed the state by the time they come back.
              // In that case, just discard the suggestions
              return;
            }

            setState({
              ...newState,
              suggestions: newSuggestions,
            });
            lookingUpSuggestionsForState.current = null;
          }
        );
      }
    },
    [node, frame, state.focus, state.buffer, propUpdateNode, autosuggest]
  );
  const fullState = useMemo(
    () => ({...state, node, frame: frame ?? {}, debug: !!debug}),
    [state, node, frame, debug]
  );

  const contextValue = useMemo(() => {
    if (error != null) {
      // Throw error in render thread so it can be caught by react error boundaries
      console.error('expressionEditorState error', error);
      throw new Error(error);
    }
    return {
      state: fullState,
      setState: setStateAndUpdateNode,
      error,
      setError,
    };
  }, [fullState, setStateAndUpdateNode, error, setError]);

  return (
    <EEContext.Provider value={contextValue}> {children} </EEContext.Provider>
  );
};

export const EESubContextFrameProvider: React.FC<{
  frame?: {[argName: string]: Types.Node};
}> = ({frame, children}) => {
  const parentContext = useContext(EEContext);
  if (parentContext == null) {
    throw new Error('EE context not initialized');
  }
  const contextValue = useMemo(() => {
    return {
      ...parentContext,
      state: {
        ...parentContext.state,
        frame: {...parentContext.state.frame, ...frame},
      },
    };
  }, [parentContext, frame]);

  return (
    <EEContext.Provider value={contextValue}> {children} </EEContext.Provider>
  );
};

export const useAction = <T extends any[], R>(
  fn: (
    client: Client,
    state: ExpressionEditorState,
    setState: (newState: ExpressionEditorState) => void,
    ...rest: T
  ) => R
): ((...args: T) => R) => {
  const fnWithCGContext = CGReact.useClientBound(fn as any);
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  const {state, setState, setError} = context;
  return useCallback(
    (...args: T) => {
      Promise.resolve(fnWithCGContext(state, setState, ...args)).catch(
        (e: any) => setError(e)
      );
    },
    [fnWithCGContext, state, setState, setError]
  ) as any;
};

export const useFocusedNodeOrOp = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.focus;
};

export const useCursorPos = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.cursorPos;
};

export const useTailNode = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.node;
};

export const useTailOpKey = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.tailOpKey;
};

export const useBuffer = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.buffer;
};

export const useFrame = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.frame;
};

export const useConsumingOp = (node: Types.Node) => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return HL.findConsumingOp(node, context.state.node);
};

export const useSuggestions = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }

  return context.state.suggestions;
};

export const useShowPlainText = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.showPlainText;
};

export const useIsEditingPlainText = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.isEditingPlainText;
};

export const usePlainTextHasError = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.plainTextHasError;
};

export const useDebug = () => {
  const context = useContext(EEContext);
  if (context == null) {
    throw new Error('EE context not initialized');
  }
  return context.state.debug;
};

// only works within a single text node:
const setCursorPosition = (toEnd: boolean) => {
  const selection = window.getSelection();

  if (
    !selection ||
    !selection?.anchorNode ||
    !selection?.anchorNode?.textContent
  ) {
    return;
  }

  const range = document.createRange();
  range.setStart(
    selection.anchorNode,
    toEnd ? selection.anchorNode.textContent.length : 0
  );
  range.collapse(true);

  selection.removeAllRanges();
  selection.addRange(range);
};

export const useHandleEditorKeys = (
  allowSpaces: boolean,
  getLogContext: (origin: string) => LogContext
) => {
  const toggleShowPlainTextAction = useAction(toggleShowPlainText);
  const focusNodeOrOpAction = useAction(focusNodeOrOp);
  const deletePrevAction = useAction(deletePrev);
  const suggestions = useSuggestions();
  const updateNodeAndFocusAction = useAction(updateNodeAndFocus);
  const focusedNodeOrOp = useFocusedNodeOrOp();
  const buffer = useBuffer();

  const tailNode = useTailNode();

  return async (e: React.KeyboardEvent<Element>) => {
    const selection = window.getSelection();
    const cursorAtBeginning = selection?.anchorOffset === 0;
    const cursorAtEnd =
      selection?.anchorOffset === selection?.anchorNode?.textContent?.length;

    if ([57, 48].includes(e.keyCode) && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      toast(
        <span className={BLOCK_POPUP_CLICKS_CLASSNAME}>
          To edit parentheses, switch to{' '}
          <LinkButton onClick={toggleShowPlainTextAction}>
            Plain Text View{' '}
            <ToastIconContainer>
              <Icon name="i cursor" size="small" style={{margin: 0}} />
            </ToastIconContainer>
          </LinkButton>{' '}
          .
        </span>
      );
    }

    if (!allowSpaces && e.key === ' ' && focusedNodeOrOp) {
      // in most editors (string literals are an exception) we don't want to
      // insert spaces...
      e.preventDefault();

      if (cursorAtEnd) {
        // ...but if the user types a space at the end of the input, we should
        // interpret that as a signal to move forward.

        // TODO: this behavior is fine for the end of the expression because it
        // allows the user to add a new binary op -- but it's probably not what
        // the user wants INSIDE the expression. In other words, feels good for
        // a space at the end of:
        //
        // x + 4
        //
        // but bad for a space at the 3 in:
        //
        // x[3] + 4
        //
        // in the latter case, the user probably wanted to keep changing the
        // sub-expression within the brackets, but we move them outside instead.
        // Solving this will require "inserting" nodes from a non-OutputNode.

        const matchingSuggestions = suggestions.filter(
          sugg => sugg.suggestionString.trim() === buffer
        );
        if (matchingSuggestions.length > 0) {
          e.preventDefault();
          updateNodeAndFocusAction(
            getLogContext('space to accept suggestion from void node'),
            matchingSuggestions[0].newNodeOrOp
          );
          return;
        }

        const nextNodeOrOp = HL.getNextNodeOrOpInTextOrder(
          focusedNodeOrOp,
          tailNode
        );

        if (!nextNodeOrOp) {
          // this is the end, we can't go forward
          return;
        }

        e.preventDefault();
        focusNodeOrOpAction(
          getLogContext('space to next'),
          nextNodeOrOp,
          undefined,
          undefined
        );
        return;
      }
    }

    if (e.key === 'ArrowLeft' && focusedNodeOrOp) {
      // jump between nodes
      if (e.metaKey) {
        // jump to the beginning of the expression (cmd + left)
        e.preventDefault();
        const ordered = HL.textOrderedNodesAndOps(tailNode);

        if (focusedNodeOrOp === ordered[0]) {
          setCursorPosition(false);
        } else {
          focusNodeOrOpAction(
            getLogContext('command-left to beginning'),
            ordered[0]
          );
        }
        return;
      } else if (cursorAtBeginning) {
        const prevNodeOrOp = HL.getPrevNodeOrOpInTextOrder(
          focusedNodeOrOp,
          tailNode
        );

        if (!prevNodeOrOp) {
          // this is the beginning, we can't go back any further
          return;
        }

        e.preventDefault();
        focusNodeOrOpAction(
          getLogContext('left arrow to prev'),
          prevNodeOrOp,
          true,
          undefined
        );
        return;
      }
    }

    if (e.key === 'ArrowRight' && focusedNodeOrOp) {
      if (e.metaKey) {
        // jump to the end of the expression (cmd + right)
        e.preventDefault();

        if (focusedNodeOrOp === tailNode) {
          setCursorPosition(true);
        } else {
          focusNodeOrOpAction(
            getLogContext('cmd-right to end'),
            tailNode,
            true
          );
        }
        return;
      } else if (cursorAtEnd) {
        // jump to the next node
        const nextNodeOrOp = HL.getNextNodeOrOpInTextOrder(
          focusedNodeOrOp,
          tailNode
        );

        if (!nextNodeOrOp) {
          // this is the end, we can't go forward
          return;
        }

        e.preventDefault();
        focusNodeOrOpAction(
          getLogContext('right arrow to next'),
          nextNodeOrOp,
          undefined,
          undefined
        );
        return;
      }
    }

    // handle automatically switching to a string literal if the user
    // enters a quote character
    if (
      [`'`, `"`].includes(e.key) &&
      focusedNodeOrOp &&
      HL.isEditingNode(focusedNodeOrOp) &&
      HL.couldBeReplacedByType(focusedNodeOrOp, tailNode, 'string')
    ) {
      e.preventDefault();
      const currentlyEditingStringLiteral = focusedNodeOrOp.type === 'string';

      if (currentlyEditingStringLiteral) {
        // "complete" the string literal by moving to the next node
        const nextNodeOrOp = HL.getNextNodeOrOpInTextOrder(
          focusedNodeOrOp,
          tailNode
        );

        if (nextNodeOrOp) {
          focusNodeOrOpAction(
            getLogContext(`completing string literal with ${e.key}`),
            nextNodeOrOp,
            undefined,
            undefined
          );
        }
        return;
      }

      // convert the current node (presumed to be a void) into a
      // string literal
      updateNodeAndFocusAction(
        getLogContext(`converting void buffer '${buffer}' to string literal`),
        constString(buffer)
      );
      return;
    }

    // handle automatically entering the brackets when a user types
    // the [ key (when applicable)
    if (e.key === '[' && cursorAtBeginning) {
      const bracketOpSuggestions = suggestions.filter(
        (
          suggestion
        ): suggestion is AutosuggestResult<CGTypes.EditingOutputNode> => {
          // what op (if any) is this suggestion suggesting?
          let op: CGTypes.EditingOp | undefined;

          if (HL.isEditingOp(suggestion.newNodeOrOp)) {
            op = suggestion.newNodeOrOp;
          } else if (suggestion.newNodeOrOp.fromOp) {
            op = suggestion.newNodeOrOp.fromOp;
          }

          return !!op && HL.isBracketsOp(op);
        }
      );

      // find [] (empty brackets) if it's one of the options
      const voidBracketOp = bracketOpSuggestions.find(suggestion => {
        const arg = Object.values(suggestion.newNodeOrOp.fromOp.inputs)[1];

        return arg.nodeType === 'void';
      });

      if (voidBracketOp) {
        // [] is a valid option, so the user probably wanted to move
        // into the brackets -- let's do that right away instead of
        // making them hit Enter:
        updateNodeAndFocusAction(
          getLogContext('moving from void into brackets because [ pressed'),
          voidBracketOp.newNodeOrOp
        );
        e.preventDefault();
        return;
      }
    }

    if (e.key === ']' && cursorAtEnd) {
      const bracketAncestor = HL.findContainingBracketNode(
        focusedNodeOrOp,
        tailNode
      );

      if (bracketAncestor) {
        // we *are* in a bracket op, and we *are* at the end of the current input.
        // but are we in the last node inside the brackets?
        //
        // consider `a[3 + 4]`. at this point, the cursor could be:
        // * after `3`
        // * after `+`
        // * after `4`
        //
        // we only want to close the brackets if we're at the end -- so in the case
        // above we want to ensure we're after `4` before proceeding

        const ordered = HL.textOrderedNodesAndOps(bracketAncestor);

        if (focusedNodeOrOp === ordered[ordered.length - 1]) {
          // we're at the last child node in the brackets. that means we should
          // close the brackets and move "forward" -- like pressing right arrow
          // from the brackets node:
          const nextNodeOrOp = HL.getNextNodeOrOpInTextOrder(
            focusedNodeOrOp,
            tailNode
          );

          e.preventDefault();

          // if the brackets node *is* the last node in the current expression, we
          // should focus the brackets node itself
          focusNodeOrOpAction(
            getLogContext(
              'focusing last brackets in expression because ] pressed'
            ),
            nextNodeOrOp || bracketAncestor
          );
          return;
        }
      }
    }

    if (e.key === 'Backspace' && cursorAtBeginning) {
      deletePrevAction(
        getLogContext('deleting previous because backspace pressed')
      );
    }
  };
};

export function useGetLogContext(
  baseOrigin: string,
  originNodeOrOp: CGTypes.EditingNode | CGTypes.EditingOp
): (extendedOrigin: string) => LogContext {
  const tailNode = useTailNode();
  const debug = useDebug();

  return useCallback(
    (extendedOrigin: string) => ({
      graph: tailNode,
      origin: `${baseOrigin}->${extendedOrigin}`,
      originNodeOrOp,
      debug,
    }),
    [baseOrigin, debug, originNodeOrOp, tailNode]
  );
}
