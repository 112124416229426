import * as Normalize from '../normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.DiscussionCommentObjSchema>(
  'discussion-comment',
  whole => whole
);

export const denormalize = Normalize.denormFn<Types.DiscussionCommentObjSchema>(
  part => part
);
