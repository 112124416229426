import {PUBLISHED_PROJECT_NAME} from '../constants';
import {PUBLISHED_WORK_PATH_SEGMENT} from '../../routeData';
import {makeNameAndID} from '../url/shared';

export type ReportParams = {
  entityName: string;
  projectName: string;
  reportID: string;
  reportName?: string;
};

export type GalleryPostParams = {
  entityName: string;
  reportID: string;
  reportName?: string;
};

export function reportEdit(r: ReportParams, queryString?: string): string {
  const extra = queryString || '';
  return `${reportViewWithoutPublished(r)}/edit${extra}`;
}

export function reportView(r: ReportParams): string {
  return toPublishedReportURL(reportViewWithoutPublished(r));
}

export function reportViewWithoutPublished(r: ReportParams): string {
  return `/${r.entityName}/${r.projectName}/reports/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function toPublishedReportURL(url: string): string {
  const searchStr = `${PUBLISHED_PROJECT_NAME}/reports`;
  const i = url.indexOf(searchStr);

  if (i === -1) {
    return url;
  }

  return (
    url.slice(0, i) +
    PUBLISHED_WORK_PATH_SEGMENT +
    url.slice(i + searchStr.length)
  );
}

type PublishedReportParams = Omit<ReportParams, 'projectName'>;

export function publishedReportView(r: PublishedReportParams): string {
  return `/${r.entityName}/published-work/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function galleryPostEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryPostView(r)}/edit${extra}`;
}

export function galleryPostView(r: GalleryPostParams): string {
  return `/${r.entityName}/posts/${makeNameAndID(r.reportID, r.reportName)}`;
}

export function galleryDiscussionEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryDiscussionView(r)}/edit${extra}`;
}

export function galleryDiscussionView(r: GalleryPostParams): string {
  return `/${r.entityName}/discussions/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}
