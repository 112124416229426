import {useMemo} from 'react';
import {flatten} from '../util/flatten';
import * as ApiSchemaTypes from '../generated/apiSchema';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as ApiSchema from '../generated/apiSchema.json';
import * as Filter from '../util/filters';
import * as _ from 'lodash';
import * as GraphQL from 'graphql';
import {VegaSpecInfo} from '../components/property-editors/property-editors';
import * as BuiltinPanelDefs from '../components/Vega2/builtinPanelDefs';
import {Spec} from 'vega';
import * as VegaLib2 from './vega2';
import {View} from '../state/graphql/vega2PanelQuery';
import * as QueryResult from '../state/queryGraph/queryResult';
import {RunsQueryContext} from '../state/runs/context';
import * as QueryTS from '../util/queryts';
import {useQuery2} from '../util/apollo2-hooks';
import {VisualizationSpec} from 'react-vega';
import {CustomRunColors} from '../state/views/customRunColors/types';
import {colorN, ROBIN16} from './colors';
import {produce} from 'immer';
import {usePrevious, useGatedValue} from '../state/hooks';
import {isReservedKey} from './runs';

export const HIDDEN_FIELDS = ['defaultColorIndex'];
export const VIEW_ALL_RUNS = 'All runs';
const DEFAULT_LIMIT: number = 500;

export interface Query {
  queryFields: QueryField[];
}

export interface QueryArg {
  name: string;
  value: any;
}

export interface QueryField {
  name: string;
  args?: QueryArg[];

  // TODO: make fields? optional, it's annoying
  fields: QueryField[];
  alias?: string;
}

export interface QueryTemplateArg {
  typeName: string;
  fieldName: string;
  argName: string;
  value: string;
}

export interface Transform {
  name: 'tableWithFullPathColNames' | 'tableWithLeafColNames';
}

export const DEFAULT_TRANSFORM: Transform = {
  name: 'tableWithLeafColNames',
};

// When we don't have a configured transform, we use this.
// This is to handle legacy panels (which didn't set a transform
// and used the old full path column names transform)
export const TRANSFORM_WHEN_MISSING: Transform = {
  name: 'tableWithFullPathColNames',
};

export function fieldIsHidden(field: string) {
  return field !== '_step' && isReservedKey(field);
}
export function makeDupesBefore(arr: string[]): number[] {
  const counts: {[key: string]: number} = {};
  const result: number[] = [];
  for (const v of arr) {
    counts[v] = counts[v] != null ? counts[v] + 1 : 0;
    result.push(counts[v]);
  }
  return result;
}

export function toGraphql(
  query: Query,
  templates: {[template: string]: any},
  replaceTemplates: boolean = true
) {
  const queryType = getSchemaType('Query')!;
  let result = 'query {\n';
  const fields = query.queryFields.filter(f => f.name !== '');
  const dupesBefore = makeDupesBefore(fields.map(f => f.name));
  result += query.queryFields
    .filter((f: any) => f.name !== '')
    .map((f, i) =>
      toGraphqlField(
        f,
        dupesBefore[i],
        2,
        queryType.fields!.find(ff => f.name === ff.name)!,
        templates,
        replaceTemplates
      )
    )
    .join('');
  result += '}\n';
  return result;
}

export function indent(s: string, n: number) {
  let result = '';
  for (let i = 0; i < n; i++) {
    result += ' ';
  }
  return result + s;
}

export function toGraphqlField(
  field: QueryField,
  dupesBefore: number,
  depth: number,
  fieldSchema: ApiSchemaTypes.__Field,
  templates: {[template: string]: any},
  replaceTemplates: boolean = true
) {
  let prefix = field.name;
  if (dupesBefore !== 0) {
    prefix = `${field.name}${dupesBefore}: ${field.name}`;
  }
  let result = indent(prefix, depth);
  if (field.args != null) {
    const argStrings: string[] = [];
    for (const arg of field.args) {
      let val = arg.value;

      // if this is a template and we aren't replacing, put it inline as a string
      if (val in templates && !replaceTemplates) {
        argStrings.push(`${arg.name}: ${JSON.stringify(val)}`);
        continue;
      }

      if (val in templates) {
        val = templates[val];
      }
      const argType = fieldSchema.args.find(a => a.name === arg.name)!.type;
      argStrings.push(`${arg.name}: ${toGraphqlArg(val, argType)}`);
    }
    result += `(${argStrings.join(', ')})`;
  }
  if (field.fields == null || field.fields.length === 0) {
    result += '\n';
    return result;
  }
  result += ' {\n';
  const fieldType = getInnerType(fieldSchema.type)!;
  const fieldDupesBefore = makeDupesBefore(field.fields.map(f => f.name));
  for (const line of field.fields.map((f, i) =>
    toGraphqlField(
      f,
      fieldDupesBefore[i],
      depth + 2,
      fieldType.fields!.find(ff => f.name === ff.name)!,
      templates
    )
  )) {
    result += line;
  }
  result += indent('}\n', depth);
  return result;
}

export function toGraphqlArg(val: any, argType: ApiSchemaTypes.__Type): string {
  if (argType.name != null) {
    argType = getSchemaType(argType.name)!;
  }
  switch (argType.kind) {
    case ApiSchemaTypes.__TypeKind.NonNull:
      return toGraphqlArg(val, argType.ofType!);
    case ApiSchemaTypes.__TypeKind.List:
      const arrayVal = val as any[];
      return (
        '[' +
        arrayVal.map(v => toGraphqlArg(v, argType.ofType!)).join(', ') +
        ']'
      );
    case ApiSchemaTypes.__TypeKind.InputObject:
      const objVal = val as {[key: string]: any};
      const objKeys = Object.keys(objVal);
      return (
        '{' +
        objKeys
          .map(
            k =>
              k +
              ': ' +
              toGraphqlArg(
                objVal[k],
                argType.inputFields!.find(f => f.name === k)!.type
              )
          )
          .join(', ') +
        '}'
      );
    case ApiSchemaTypes.__TypeKind.Scalar:
      if (/[F]ilter/.test(argType.name!)) {
        return JSON.stringify(JSON.stringify(Filter.toMongo(val)));
      } else {
        return JSON.stringify(val);
      }
    default:
      // shouldn't be hit
      return JSON.stringify(val);
  }
}

export function stripResultObj(result: any): any {
  if (_.isArray(result)) {
    return result.map(r => stripResultObj(r));
  } else if (_.isObject(result)) {
    const ret: {[key: string]: any} = {};
    _.forEach(result, (v: any, k) => {
      if (k === '__typename') {
        // skip
      } else {
        ret[k] = stripResultObj(v);
      }
    });
    return ret;
  }
  return result;
}

export function getSchemaType(typeName: string) {
  for (const schemaType of ApiSchema.__schema.types) {
    if (schemaType.name === typeName) {
      return schemaType as ApiSchemaTypes.__Type;
    }
  }
  return null;
}

export function getInnerType(type: ApiSchemaTypes.__Type) {
  while (type.ofType != null) {
    type = type.ofType;
  }
  return getSchemaType(type.name!);
}

export function getDefaultArgFromSchemaArgType(
  schemaArgType: ApiSchemaTypes.__Type
) {
  if (schemaArgType.kind === 'LIST') {
    return [];
  }
  if (schemaArgType.name === 'Int') {
    return 0;
  }
  if (schemaArgType.name === 'String') {
    return '';
  }
  if (schemaArgType.name === 'RunFilter') {
    return Filter.EMPTY_FILTERS;
  }
  return null;
}

/**
 * Prepopulates required args with defaults
 * @param schemaField
 */
export function getDefaultFieldFromSchemaField(
  schemaField: ApiSchemaTypes.__Field
) {
  const args = [];
  for (const arg of schemaField.args) {
    if (arg.type.kind === 'NON_NULL') {
      args.push({
        name: arg.name,
        value: getDefaultArgFromSchemaArgType(arg.type.ofType!),
      });
    }
  }
  return {
    name: schemaField?.name,
    args: args.length > 0 ? args : undefined,
    fields: [],
  };
}

export interface Table {
  cols: string[];
  rows: Array<{[key: string]: any}>;
}

export function transformQueryResultToTable(
  query: Query,
  transform: Transform,
  queryResult: QueryResult.Row[]
): Table {
  queryResult.forEach(result => {
    if (result.runSets != null) {
      result.runSets.forEach((rs: any) => {
        if (rs.groupKeys != null) {
          rs.groupKeys = rs.groupKeys.join('_');
        }
      });
    }
  });
  const rows =
    transform.name === 'tableWithLeafColNames'
      ? QueryResult.flattenNested(queryResult)
      : QueryResult.flattenNestedOld(queryResult);

  const keys = new Set<string>();
  rows.forEach(row => {
    Object.keys(row).forEach(key => {
      keys.add(key);
    });
  });
  return {
    cols: Array.from(keys),
    rows,
  };
}

export function useVegaQuery(
  query: Query,
  transform: Transform,
  templateVals: {[template: string]: any},
  skip?: boolean
) {
  const stringQuery = useMemo(
    () => toGraphql(query, templateVals),
    [query, templateVals]
  );
  const {
    loading,
    result: rawResult,
    slow,
    initialLoading,
  } = useQuery2(stringQuery, skip);

  const {cols, rows} = useMemo(() => {
    const result = rawResult ? [stripResultObj(rawResult)] : [];
    return transformQueryResultToTable(query, transform, result);
  }, [query, rawResult, transform]);

  // Performance: Only send a new row result if rows differs from the previous
  // version that we've sent. Running vega can be very expensive, so we try
  // to keep rows ref-equal as much as possible. Typically when polling there
  // is no change.
  // We use a heuristic equality check rather than a full deep-equal, since
  // this hook runs whenever the caller component renders.
  const previousRows = usePrevious(rows);
  const gatedRows = useGatedValue(
    rows,
    curRows =>
      previousRows == null ||
      curRows.length !== previousRows.length ||
      (curRows.length > 0 &&
        (!_.isEqual(curRows[0], previousRows[0]) ||
          !_.isEqual(
            curRows[curRows.length - 1],
            previousRows[previousRows.length - 1]
          )))
  );

  cols.sort();

  return {loading, initialLoading, result: gatedRows, cols, slow};
}

export function getVegaPanelDef(
  config: VegaSpecInfo,
  views: View[]
): VegaLib2.VegaPanelDef | null {
  const panelDefID = getPanelDefID(config);
  if (_.startsWith(panelDefID, 'lib:')) {
    const id = panelDefID.split(':')[1];
    const view = _.find(views, v => v.id === id);
    if (view == null) {
      return null;
    }
    return {
      name: view.name,
      displayName: view.name,
      description: view.description,
      spec: view.spec,
    };
  } else if (panelDefID === 'custom') {
    return config.customPanelDef!;
  } else {
    return BuiltinPanelDefs.getPanelDef(panelDefID);
  }
}

export function getSpec(config: VegaSpecInfo, views: View[]): Spec | null {
  const panelDef = getVegaPanelDef(config, views);
  if (panelDef == null) {
    return null;
  }
  const {parsedSpec} = VegaLib2.parseSpecJSON(panelDef.spec);
  return parsedSpec;
}

export function getPanelDefID(config: VegaSpecInfo) {
  return config.panelDefId || BuiltinPanelDefs.IDS[0];
}

/* eslint-disable no-template-curly-in-string */
export const defaultRunSetsQuery: Query = {
  queryFields: [
    {
      name: 'runSets',
      args: [
        {name: 'runSets', value: '${runSets}'},
        {name: 'limit', value: DEFAULT_LIMIT},
      ],
      fields: [
        {name: 'id', fields: []},
        {name: 'name', fields: []},
        {
          name: 'summary',
          args: [{name: 'keys', value: ['']}],
          fields: [],
        },
      ],
    },
  ],
};

export const fixedRunSetsQuery: Query = {
  queryFields: [
    {
      name: 'runSets',
      args: [{name: 'runSets', value: '${runSets}'}],
      fields: [
        {name: 'id', fields: []},
        {name: 'name', fields: []},
      ],
    },
  ],
};

export function queryTemplates(
  pageQuery: QueryTS.Query,
  runsQueryContext: RunsQueryContext
): {
  templateArgs: QueryTemplateArg[];
  templateVals: {[template: string]: any};
} {
  const runSetFilters = (rs: QueryTS.RunSetQuery) => {
    const filters = [rs.filters];
    if (runsQueryContext.mergeFilters != null) {
      filters.push(runsQueryContext.mergeFilters);
    }
    if (rs.selections != null) {
      filters.push(rs.selections);
    }
    return filters.length === 1 ? rs.filters : Filter.And(filters);
  };
  return {
    templateArgs: [
      {
        typeName: 'Query',
        fieldName: 'runSets',
        argName: 'runSets',
        value: '${runSets}',
      },
    ],
    templateVals: {
      '${runSets}': (pageQuery.runSets ?? [])
        .filter(rs => rs.enabled)
        .map(rs => ({
          entityName: rs.entityName ?? runsQueryContext.entityName,
          projectName: rs.projectName ?? runsQueryContext.projectName,
          filters: runSetFilters(rs),
          order: QueryTS.sortToOrderString(rs.sort),
          grouping: rs.grouping,
        })),
    },
  };
}
/* eslint-enable no-template-curly-in-string */

function queryDocToQuery(rawQuery: string): Query | null {
  const doc = GraphQL.parse(rawQuery);
  if (doc.definitions.length !== 1) {
    return null;
  }
  const queryNode = doc.definitions[0];
  if (
    queryNode.kind !== 'OperationDefinition' ||
    queryNode.operation !== 'query'
  ) {
    return null;
  }

  const parseValue = (val: GraphQL.ValueNode): any => {
    switch (val.kind) {
      case 'IntValue':
        return Number(val.value);
      case 'FloatValue':
        return Number(val.value);
      case 'StringValue':
        return val.value;
      case 'BooleanValue':
        return val.value;
      case 'NullValue':
        return null;
      case 'EnumValue':
        return val.value;
      case 'ListValue':
        return val.values.map(parseValue);
      case 'ObjectValue':
        return Object.assign(
          {},
          ...val.fields.map(field => ({
            [field.name.value]: parseValue(field.value),
          }))
        );
      default:
        throw new Error('invalid value kind ' + val.kind);
    }
  };

  const parseArg = (arg: GraphQL.ArgumentNode): QueryArg => {
    return {
      name: arg.name.value,
      value: parseValue(arg.value),
    };
  };

  const parseSelection = (sel: GraphQL.SelectionNode): QueryField => {
    if (sel.kind !== 'Field') {
      throw new Error('only fields allowed');
    }

    let args: QueryArg[] | undefined;
    if (sel.arguments != null && sel.arguments.length > 0) {
      args = sel.arguments.map(parseArg);
    }

    let fields: QueryField[] = [];
    if (sel.selectionSet != null) {
      fields = sel.selectionSet.selections.map(parseSelection);
    }

    return {
      name: sel.name.value,
      args,
      fields,
    };
  };

  return {
    queryFields: queryNode.selectionSet.selections.map(parseSelection),
  };
}

function validateAndMergeQuery(
  query: Query,
  fixedFields: QueryField[],
  templateArgs: QueryTemplateArg[]
): Query | null {
  const processArg = (
    arg: QueryArg,
    type: ApiSchemaTypes.__InputValue | undefined
  ): QueryArg => {
    if (type == null) {
      throw new Error('unknown arg ' + arg.name);
    }
    // TODO: better validation
    return arg;
  };
  const processField = (
    field: QueryField,
    fixed: QueryField | undefined,
    type: ApiSchemaTypes.__Field
  ): QueryField => {
    if (field.args != null) {
      const fixedArgs = fixed?.args ?? [];

      const extra = field.args.filter(
        a => fixedArgs.find(aa => a.name === aa.name) == null
      );
      field = {
        ...field,
        args: [
          ...fixedArgs,
          ...extra.map(arg =>
            processArg(
              arg,
              type.args.find(a => a.name === arg.name)
            )
          ),
        ],
      };
    }
    const ftype = getSchemaType(getInnerType(type.type)!.name!)!;
    if (fixed != null) {
      field = {
        ...field,
        fields: [
          ...fixed.fields.filter(
            f => field.fields.find(ff => f.name === ff.name) == null
          ),
          ...field.fields.map(f => {
            const ffield = (ftype.fields ?? []).find(ft => f.name === ft.name);
            if (ffield == null) {
              throw new Error(`unknown field ${f.name} on ${ftype.name}`);
            }
            return processField(
              f,
              (fixed?.fields ?? []).find(ff => f.name === ff.name),
              ffield
            );
          }),
        ],
      };
    }
    return field;
  };
  const queryType = getSchemaType('Query')!;
  return {
    queryFields: processField(
      {name: 'query', fields: query.queryFields},
      {name: 'query', fields: fixedFields},
      {
        name: 'query',
        args: [],
        type: queryType,
        isDeprecated: false,
      }
    ).fields,
  };
}

export function parseQuery(
  rawQuery: string,
  fixedFields: QueryField[],
  templateArgs: QueryTemplateArg[]
): Query | null {
  let query: Query | null = null;
  try {
    query = queryDocToQuery(rawQuery);
    if (query == null) {
      return null;
    }
    return validateAndMergeQuery(query, fixedFields, templateArgs);
  } catch {
    return null;
  }
}

export function valueCharCount(value: string | number | any[]) {
  if (typeof value === 'string') {
    return value.length + 2;
  }
  if (typeof value === 'number') {
    return value.toString().length;
  }
  if (Array.isArray(value)) {
    let result = 0;
    value.forEach(val => {
      result += valueCharCount(val) + 2;
    });
    return result;
  }
  return 6;
}

export function argsCharCount(args: QueryArg[]) {
  let result = 0;
  args.forEach(arg => {
    result += arg.name.length + 2;
    result += valueCharCount(arg.value) + 2;
  });
  return result;
}

/**
 * For renderer
 */

export interface FieldRef {
  type: 'field' | 'string';
  name: string;
  raw: string;
  default?: string;
}

function toRef(s: string): FieldRef | null {
  const [refName, rest, dflt] = s.split(':', 3);
  if (rest == null) {
    return null;
  }
  switch (refName) {
    case 'field':
      return {type: 'field', name: rest, raw: s};
    case 'string':
      return {type: 'string', name: rest, default: dflt, raw: s};
    default:
      return null;
  }
}

export function extractRefs(s: string): FieldRef[] {
  const match = s.match(new RegExp(`\\$\\{.*?\\}`, 'g'));
  if (match == null) {
    return [];
  }
  return match
    .map(m => {
      const ref = toRef(m.slice(2, m.length - 1));
      return ref == null ? null : {...ref, raw: m};
    })
    .filter(Obj.notEmpty);
}

export function parseSpecFields(spec: VisualizationSpec): FieldRef[] {
  const refs = _.uniqWith(
    _.flatMap(
      _.filter(flatten(spec), v => typeof v === 'string'),
      v => extractRefs(v)
    ),
    _.isEqual
  );
  return refs;
}

export function fieldInjectResult(
  ref: FieldRef,
  userSettings: VegaLib2.UserSettings
): string | null {
  let result = '';
  switch (ref.type) {
    case 'field':
      result = userSettings.fieldSettings?.[ref.name] || '';
      if (typeof result !== 'string') {
        result = String(result);
      }
      result = result.replace(/\./g, '\\.');
      return result;
    case 'string':
      return userSettings.stringSettings?.[ref.name] || ref.default || '';
    default:
      return null;
  }
}

export function makeInjectMap(
  refs: FieldRef[],
  userSettings: VegaLib2.UserSettings
): Array<{from: string; to: string}> {
  const result: Array<{from: string; to: string}> = [];
  for (const ref of refs) {
    const inject = fieldInjectResult(ref, userSettings);
    if (inject != null) {
      result.push({
        from: ref.raw,
        to: inject,
      });
    }
  }
  return result;
}

export function injectFields(
  spec: VisualizationSpec,
  refs: FieldRef[],
  userSettings: VegaLib2.UserSettings
): VisualizationSpec {
  const injectMap = makeInjectMap(refs, userSettings);
  return Obj.deepMapValuesAndArrays(spec, (s: any) => {
    if (typeof s === 'string') {
      for (const mapping of injectMap) {
        // Replace all (s.replace only replaces the first occurrence)
        s = s.split(mapping.from).join(mapping.to);
      }
    }
    return s;
  });
}

export function injectAutoFields(query: Query) {
  return produce(query, draft => {
    const runSetsQueryFields = draft.queryFields.find(
      f => f.name === 'runSets'
    );
    if (runSetsQueryFields != null) {
      runSetsQueryFields.fields.push({name: '_defaultColorIndex', fields: []});
      const historyTableFields = runSetsQueryFields.fields.filter(
        f => f.name === 'historyTable'
      );
      historyTableFields.forEach(field => {
        const extraKeys = field.args?.find(a => a.name === 'extraKeys');
        if (
          extraKeys != null &&
          extraKeys.value.find((a: string) => a === '_step') == null
        ) {
          extraKeys.value.push('_step');
        }
      });
      // inject limit so old charts without the limit still work
      const limitArg = runSetsQueryFields.args?.find(a => a.name === 'limit');
      if (limitArg == null) {
        runSetsQueryFields.args?.push({name: 'limit', value: DEFAULT_LIMIT});
      }
    }
  });
}

export function getViewedStep(query: Query) {
  const historyTableKeys = query.queryFields
    .find(f => f.name === 'runSets')
    ?.fields.filter(f => f.name === 'historyTable');
  if (historyTableKeys && historyTableKeys.length > 0) {
    return historyTableKeys[0].args?.find(a => a.name === 'index')?.value;
  }
  return null;
}

/* eslint-disable no-template-curly-in-string */
export function createIndsQuery(query: Query) {
  const historyTableKeys = query.queryFields
    .find(f => f.name === 'runSets')
    ?.fields.filter(f => f.name === 'historyTable');
  const args: any[] | undefined = historyTableKeys
    ?.map(qf => {
      return qf.args?.filter(a => a.name === 'tableKey').map(a => a.value);
    })
    .flat();
  const queryArgs = args?.map(a => [a, '_step']);
  const fields =
    queryArgs && queryArgs.length > 0
      ? queryArgs?.map(arg => {
          return {
            name: 'history',
            args: [{name: 'keys', value: arg}],
            fields: [],
          };
        })
      : [
          {
            name: 'history',
            args: [{name: 'keys', value: ['_step']}],
            fields: [],
          },
        ];
  return {
    queryFields: [
      {
        name: 'runSets',
        args: [{name: 'runSets', value: '${runSets}'}],
        fields,
      },
    ],
  };
}

export function removeQueryIndex(query: Query) {
  const result: Query = produce(query, draft => {
    draft.queryFields
      .find(f => f.name === 'runSets')
      ?.fields.filter(f => f.name === 'historyTable')
      ?.forEach(field => {
        field.args
          ?.slice()
          .reverse()
          .forEach((arg, index) => {
            if (arg.name === 'index') {
              field.args?.splice(field.args.length - 1 - index, 1);
            }
          });
      });
  });
  return result;
}

export function updateQueryIndex(query: Query, index: number) {
  const result = produce(query, draft => {
    draft.queryFields
      .find(f => f.name === 'runSets')
      ?.fields.filter(f => f.name === 'historyTable')
      ?.forEach(field => {
        const val = field.args?.find(a => a.name === 'index');
        if (val == null) {
          field.args?.push({name: 'index', value: index});
        } else {
          val.value = index;
        }
      });
  });
  return result;
}

export function computeRunColors(
  queryResult: Array<{[key: string]: any}>,
  customRunColors: CustomRunColors | undefined
) {
  if (customRunColors == null) {
    return queryResult;
  }
  const pallete = _.range(ROBIN16.length).map(i => colorN(i, ROBIN16));

  const res = queryResult.map(row => {
    let color: string;
    if (customRunColors!.hasOwnProperty(row.id)) {
      color = customRunColors![row.id];
    } else if (row._defaultColorIndex != null) {
      color = pallete[row._defaultColorIndex % ROBIN16.length];
    } else {
      color = 'black';
    }
    const resultRow: {[key: string]: any} = {...row, color};
    delete resultRow._defaultColorIndex;
    return resultRow;
  });
  return res;
}

function hasInput(specBranch: any) {
  if (typeof specBranch === 'object') {
    for (const [key, val] of Object.entries(specBranch)) {
      if (key === 'input') {
        return true;
      }
      if (hasInput(val)) {
        return true;
      }
    }
  }
  return false;
}

export function specHasBindings(spec: VisualizationSpec) {
  if (spec.$schema?.includes('vega-lite/v5')) {
    const params = (spec as any).params;
    return hasInput(params);
  } else if (spec.$schema?.includes('vega-lite')) {
    const selection = (spec as any).selection;
    return hasInput(selection);
  } else {
    const signals = (spec as any).signals;
    if (Array.isArray(signals)) {
      for (const s of signals) {
        if (s.bind != null) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getDefaultViewedRun(
  currDefaultRun?: string,
  runSelectorOptions?: string[]
): string {
  if (currDefaultRun && runSelectorOptions) {
    if (runSelectorOptions.find(row => row === currDefaultRun)) {
      return currDefaultRun;
    } else if (runSelectorOptions.length > 1) {
      // we skip the first one which defaults to all the runs
      return runSelectorOptions[1];
    }
  }

  return VIEW_ALL_RUNS;
}

export function updateMatchingKeys(userQuery: Query, newQuery: Query): Query {
  // Checks if a user is changing their query from summary to history
  // (or vice versa), or summaryTable to historyTable (or vice versa)
  // and keeps existing query information so they don't have to reinput
  const updatedFields = newQuery.queryFields[0].fields.map((value, index) => {
    const prevField = userQuery.queryFields[0].fields[index];
    const updatedField = value;
    if (prevField?.args == null || updatedField?.args == null) {
      return updatedField ? updatedField : prevField;
    }
    if (
      updatedField.name === 'summaryTable' &&
      prevField.name === 'historyTable'
    ) {
      updatedField.args = prevField.args.filter(
        arg => arg.name === 'tableKey' || arg.name === 'tableColumns'
      );
    } else if (
      updatedField.name === 'historyTable' &&
      prevField.name === 'summaryTable'
    ) {
      // add in extra keys arg so it pops up
      const extraKeys = updatedField.args.find(arg => arg.name === 'extraKeys');
      updatedField.args = [...prevField.args];
      if (extraKeys != null) {
        updatedField.args.push(extraKeys);
      }
    } else if (
      (updatedField.name === 'summary' && prevField.name === 'history') ||
      (updatedField.name === 'history' && prevField.name === 'summary')
    ) {
      const keyNameIndex = prevField.args.findIndex(arg => arg.name === 'keys');
      if (keyNameIndex !== -1) {
        updatedField.args[keyNameIndex].value =
          prevField.args[keyNameIndex].value;
      }
    }
    return updatedField;
  });
  return {
    queryFields: [
      {
        args: newQuery.queryFields[0].args,
        fields: updatedFields,
        name: 'runSets',
      },
    ],
  };
}
