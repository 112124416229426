import gql from 'graphql-tag';

import {reportsTableFragment, ReportsTableReport} from '../ReportsTable';
import {runsTableFragment} from '../RunsTable';
import {OnboardingSteps} from '../../types/graphql';

export interface Entity {
  id: string;
  name: string;
}

export interface Node<T> {
  node: T;
}
export interface Graph<T> {
  edges: Array<Node<T>>;
}

export interface Project {
  id: string;
  name: string;
  entityName: string;
  access: string;
}

export interface ProjectWithDetails extends Project {
  description: string;
  totalRuns: number;
  totalUsers: number;
  lastActive: string;
  totalRunTime: number;
  computeHours: number;
}

export interface Entity {
  id: string;
  name: string;
}

export interface Team {
  id: string;
  name: string;
  photoUrl: string;
}

export interface Organization {
  id: string;
  name: string;
}

export interface UserDetails {
  username: string;
  photoUrl: string;
  name: string;
  defaultEntity: Entity;
  id: string;
  projects: Graph<Project>;
  views: Graph<ReportsTableReport>;
  teams: Graph<Team>;
  onboardingSteps: OnboardingSteps | null;
  organizations: Organization[];
}

export const userFragments = {
  user_overview: gql`
    fragment HomePageFragment on User {
      id
      username
      photoUrl
      flags
      onboardingSteps
      organizations {
        id
        name
      }
      defaultEntity {
        id
        name
      }
      views(viewType: "runs", first: 100) {
        edges {
          node {
            ...ReportsTableFragment
          }
        }
      }
      projects(first: 100) {
        edges {
          node {
            id
            name
            entityName
            access
          }
        }
      }
      teams(first: 100) {
        edges {
          node {
            id
            name
            photoUrl
          }
        }
      }
    }
    ${reportsTableFragment}
  `,
};

export const HOME_PAGE_USER_DETAILS = gql`
  query HomePageUserDetails($entityName: String) {
    viewer(entityName: $entityName) {
      ...HomePageFragment
    }
  }
  ${userFragments.user_overview}
`;

export const HOME_PAGE_USER_DETAILS_BY_USER = gql`
  query HomePageUserDetailsByUser($userName: String!) {
    viewer: user(userName: $userName) {
      ...HomePageFragment
    }
  }
  ${userFragments.user_overview}
`;

export const VIEWER_RUNS_QUERY = gql`
  query ViewerRuns($entityName: String) {
    viewer(entityName: $entityName) {
      id
      runs(first: 100) {
        edges {
          node {
            ...RunsTableFragment
          }
        }
      }
    }
  }
  ${runsTableFragment}
`;

export interface HomePageQueryData {
  viewer: UserDetails;
}

export const ALL_PROJECTS_QUERY = gql`
  query AllProjectsQuery(
    $cursor: String
    $entityName: String
    # $order: String
    $first: Int = 500
  ) {
    viewer(entityName: $entityName) {
      id
      projects(
        first: $first
        after: $cursor # order: $order
      ) {
        edges {
          node {
            id
            name
            entityName
            description
            totalRuns
            totalUsers
            lastActive
            totalRunTime
            computeHours
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
