import {useResizer} from '../util/resize';
import * as S from './ProjectArtifactTypes.styles';
import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';

import * as Urls from '../util/urls';
import {TargetBlank} from '../util/links';
import emptyImg from '../assets/il-no-artifacts.png';
import emptyImg2x from '../assets/il-no-artifacts@2x.png';
import docUrl from '../util/doc_urls';
import makeComp from '../util/profiler';
import ArtifactSidebar from './ArtifactSidebar';
import NoMatch from './NoMatch';
import {ArtifactView} from './ArtifactView';
import {
  ProjectArtifactsQuery,
  useProjectArtifactsQuery,
} from '../generated/graphql';

interface ProjectArtifactsProps {
  entityName: string;
  projectName: string;
  artifactTypeName?: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  history: any;
}

/**
 * Uses props to find an artifacts type, sequence and hash. If any of these
 * properties are missing it will attempt to grab the first in each of those
 * sections.
 */
const getArtifactObjectsOrFirst = (
  props: ProjectArtifactsProps,
  data?: ProjectArtifactsQuery
) => {
  const {project} = data ?? {};
  const artifactTypeEdges = project?.artifactTypes?.edges;

  const artifactType = artifactTypeEdges?.find(a =>
    props.artifactTypeName != null
      ? a.node?.name === props.artifactTypeName
      : (a.node?.artifactSequences?.totalCount ?? 0) > 0
  )?.node;

  const artifactSequence = artifactType?.artifactSequences?.edges.find(a =>
    props.artifactCollectionName != null
      ? a.node?.name === props.artifactCollectionName
      : (a.node?.artifacts.totalCount ?? 0) > 0
  );

  const artifactCommitHash =
    props.artifactCommitHash ??
    artifactSequence?.node?.artifacts.edges?.[0]?.node.commitHash;

  return {artifactSequence, artifactType, artifactCommitHash};
};

const ProjectArtifactTypes = makeComp(
  (props: ProjectArtifactsProps) => {
    const {entityName, projectName} = props;
    const {onMouseDown, cursor, dropSize} = useResizer('right', 251, {
      min: 200,
    });

    const {loading, data, refetch} = useProjectArtifactsQuery({
      pollInterval: 5000,
      variables: {entityName, projectName},
    });

    if (loading) {
      return <Loader />;
    }

    const artifactObject = getArtifactObjectsOrFirst(props, data);
    const {artifactType, artifactSequence, artifactCommitHash} = artifactObject;
    const artifactSequenceName = artifactSequence?.node?.name;

    // If there is an artifact sequence provide in the url but we don't find it
    // in the query, it still might exist since the query is limited to 500.
    const sequenceNotFound =
      props.artifactCollectionName != null && artifactSequence == null;

    const routeDoesNotMatch =
      props.artifactTypeName !== artifactType?.name ||
      props.artifactCollectionName !== artifactSequenceName ||
      props.artifactCommitHash !== artifactCommitHash;

    if (routeDoesNotMatch && !sequenceNotFound) {
      const url = Urls.artifactsPage({
        entityName,
        projectName,
        artifactTypeName: artifactType?.name,
        artifactSequenceName,
        artifactCommitHash: artifactCommitHash ?? undefined,
      });
      return <Redirect to={url} />;
    }

    const isArtifactsRootUrl =
      props.artifactTypeName == null &&
      props.artifactCollectionName == null &&
      props.artifactCommitHash == null;

    if (isArtifactsRootUrl) {
      return <NoArtifactsMessage />;
    }

    const artifactTypes = data?.project?.artifactTypes;
    if (artifactType == null || artifactTypes == null) {
      return <NoMatch />;
    }

    return (
      <div className="artifact-page">
        <div className="artifact-page-side-container">
          <div className="artifact-page-side" style={{width: dropSize}}>
            <ArtifactSidebar
              {...props}
              refetchProjectArtifacts={refetch}
              artifactTypes={artifactTypes}
              artifactTypeName={artifactType.name}
            />
          </div>
          <div
            className="artifact-page-side-resizer"
            onMouseDown={onMouseDown}
            style={{cursor}}
          />
        </div>
        <div className="artifact-page-main">
          <ArtifactView {...props} artifactTypeName={artifactType!.name} />
        </div>
      </div>
    );
  },
  {id: 'ProjectArtifactTypes'}
);

const NoArtifactsMessage = makeComp(
  () => {
    const details = (
      <>
        <p>Use artifacts to capture your pipeline steps and model lineage.</p>
        <p>
          <TargetBlank href={docUrl.artifacts}>
            See the docs {'\u2192'}
          </TargetBlank>
        </p>
      </>
    );
    return (
      <S.EmptyWatermark
        imageSource={emptyImg}
        imageSource2x={emptyImg2x}
        header="Track datasets and model versions."
        details={details}
      />
    );
  },
  {id: 'NoArtifactsMessage'}
);
export default ProjectArtifactTypes;
