import * as _ from 'lodash';
import React, {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import {Label} from 'semantic-ui-react';

import {DragHandle, DragSource, DropTarget} from '../containers/DragDrop';
import {
  isPanel,
  isPanelBankSection,
  PANEL_BANK_HIDDEN_SECTION_NAME,
  searchQueryMatchesPanel,
  isDraggingWithinSection,
} from '../util/panelbank';
import LegacyWBIcon from './elements/LegacyWBIcon';
import PanelBankFlowSection from './PanelBankFlowSection';
import PanelBankGridSection from './PanelBankGridSection';
import * as ViewHooks from '../state/views/hooks';
import {PanelBankUpdaterContext} from '../state/panelbank/context';
import PopupDropdown from './PopupDropdown';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelSettingsTypes from '../state/views/panelSettings/types';
import * as PanelTypes from '../state/views/panel/types';
import * as PanelBankSectionConfigActions from '../state/views/panelBankSectionConfig/actions';
import {EditablePanel} from './EditablePanel2';
import * as RunSetViewTypes from '../state/views/runSet/types';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import EditableLabel from './elements/EditableLabel';
import {toast} from './elements/Toast';
import makeComp from '../util/profiler';
import {usePanelComments} from '../state/reports/hooks';
import {LocalPanelSettingsComponentControlled} from '../components/PanelSettings';
import * as PanelSettings from '../components/PanelSettings';
import * as PanelRunsLinePlot from '../components/PanelRunsLinePlot';
import classNames from 'classnames';
import {RunHistoryKeyInfo} from '../types/run';
import {useAllPanelBankSectionConfigsAction} from '../state/views/panelBankSectionConfig/hooks';

interface PanelBankSectionProps {
  readOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigTypes.Ref;
  panelBankWidth: number;
  // query: Query.Query;
  workspacePanelSettingsRef: PanelSettingsTypes.Ref;
  // These are the panels that should be rendered. Note they may be a subset
  // of the panels present in config, due to searching and data availability.
  // It's important to use the config's panels in update logic.
  activePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  // These are panels that will not be rendered.  They were filtered out either
  // by hasDataForPanel or searchQueryMatchesPanel
  inactivePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  historyKeyInfo: RunHistoryKeyInfo;
  searchQuery?: string;
  addVisButton?: ReactElement;
  forceCollapsed?: boolean;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  moveSectionBefore(
    moveRef: PanelBankSectionConfigTypes.Ref,
    beforeSectionRef: PanelBankSectionConfigTypes.Ref
  ): void;
  addSectionAbove(): void;
  addSectionBelow(): void;
  deleteSection(): void;
  movePanel(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionConfigTypes.Ref,
    toSectionRef: PanelBankSectionConfigTypes.Ref,
    toIndex: number
  ): void;
}

export function usePanelBankSectionActions(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  const toggleType = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.toggleType
  );
  const toggleIsOpen = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.toggleIsOpen
  );
  const updateName = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.updateName
  );

  return {
    toggleType,
    toggleIsOpen,
    updateName,
  };
}

export function usePanelBankSectionConfig(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  return {
    panelBankSectionConfig: ViewHooks.useWhole(panelBankSectionConfigRef),
  };
}

function usePanelBankSectionProps(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  return {
    ...usePanelBankSectionActions(panelBankSectionConfigRef),
    ...usePanelBankSectionConfig(panelBankSectionConfigRef),
  };
}

export const PanelBankSection = makeComp(
  (props: PanelBankSectionProps) => {
    const {panelBankSectionConfig, toggleType, toggleIsOpen, updateName} =
      usePanelBankSectionProps(props.panelBankSectionConfigRef);
    const [editingName, setEditingName] = useState(false);
    const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);

    const {
      panelBankConfigRef,
      panelBankSectionConfigRef,
      activePanelRefs,
      inactivePanelRefs,
      addSectionAbove,
      addSectionBelow,
      deleteSection,
      customRunColorsRef,
      workspacePanelSettingsRef,
      panelBankWidth,
      moveSectionBefore,
      runSetRefs,
      historyKeyInfo,
      movePanel,
      addVisButton,
      searchQuery,
      forceCollapsed,
      readOnly,
      defaultMoveToSectionRef,
    } = props;

    const normalizedPanelBankConfig = ViewHooks.usePart(
      panelBankSectionConfigRef
    );
    const localPanelSettingsRef =
      normalizedPanelBankConfig.localPanelSettingsRef;

    const localPanelSettings = ViewHooks.useWhole(localPanelSettingsRef);

    const workspacePanelSettings = ViewHooks.useWhole(
      workspacePanelSettingsRef
    );

    const useAllLinePlotPanels = () => {
      return React.useMemo(
        () =>
          panelBankSectionConfig.panels.filter(
            p => p.viewType === PanelRunsLinePlot.PANEL_TYPE
          ) as unknown as PanelSettings.LinePlotPanel[],
        []
      );
    };

    // make sure this is properly memoized, or
    // the entire section will rerender on every update
    const usedPanelSettings = React.useMemo(() => {
      const usedXAxisSettings = _.pick(
        localPanelSettings.xAxisActive
          ? localPanelSettings
          : workspacePanelSettings,
        'xAxis',
        'xAxisMin',
        'xAxisMax',
        'xAxisActive'
      );

      const usedSmoothingSettings = _.pick(
        localPanelSettings.smoothingActive
          ? localPanelSettings
          : workspacePanelSettings,
        'smoothingWeight',
        'smoothingType',
        'smoothingActive'
      );

      return {
        ...usedXAxisSettings,
        ...usedSmoothingSettings,
        ignoreOutliers: workspacePanelSettings.ignoreOutliers,
      };
    }, [localPanelSettings, workspacePanelSettings]);

    const {setExportingSectionRefID} = useContext(PanelBankUpdaterContext);
    const deletePanel = ViewHooks.useViewAction(
      panelBankSectionConfigRef,
      PanelBankSectionConfigActions.deletePanel
    );
    const duplicatePanel = ViewHooks.useViewAction(
      panelBankSectionConfigRef,
      PanelBankSectionConfigActions.duplicatePanel
    );

    const sortPanels = useAllPanelBankSectionConfigsAction(
      [panelBankSectionConfigRef],
      PanelBankSectionConfigActions.sortPanels
    );

    const activePanelCount = activePanelRefs.length;
    const totalPanelCount = panelBankSectionConfig.panels.length;
    const isMobile = panelBankWidth <= 768; // tablet breakpoint from globals.less
    const isHiddenPanelsSection =
      panelBankSectionConfig.name === PANEL_BANK_HIDDEN_SECTION_NAME;

    const SectionComponent =
      // Don't use grid section (custom layout) on mobile
      !isMobile &&
      // Don't use grid section if searching (otherwise you get weird blank spaces in the layout)
      _.isEmpty(searchQuery?.trim()) &&
      panelBankSectionConfig.type === 'grid'
        ? PanelBankGridSection
        : PanelBankFlowSection;

    const MAX_CUSTOM_PANEL_COUNT = 50; // panel limit for custom layout (grid) sections

    const sectionMenuOptions = _.compact([
      !isMobile && {
        key: 'toggleSectionType',
        text: `Switch to ${
          panelBankSectionConfig.type === 'grid' ? 'standard' : 'custom'
        } layout`,
        icon: `wbic-ic-${
          panelBankSectionConfig.type === 'grid' ? 'standard' : 'custom'
        }-layout`,
        onClick: () => {
          // Disable custom layout if there's more than MAX_CUSTOM_PANEL_COUNT panels
          if (
            panelBankSectionConfig.type !== 'grid' &&
            activePanelCount > MAX_CUSTOM_PANEL_COUNT
          ) {
            toast(
              `Custom Layout is limited to ${MAX_CUSTOM_PANEL_COUNT} panels.`
            );
          } else {
            toggleType();
          }
        },
      },
      {
        key: 'sortPanelsInSection',
        text: 'Sort panels A-Z',
        icon: 'wbic-ic-sort',
        onClick: sortPanels,
      },
      {
        key: 'addSectionAbove',
        text: 'Add section above',
        icon: 'wbic-ic-add-above',
        onClick: addSectionAbove,
      },
      {
        key: 'addSectionBelow',
        text: 'Add section below',
        icon: 'wbic-ic-add-below',
        onClick: addSectionBelow,
      },
      {
        key: 'renameSection',
        text: 'Rename section',
        icon: 'wbic-ic-edit',
        onClick: () => setEditingName(true),
      },
      {
        key: 'addSectionToReport',
        text: 'Add section to report',
        icon: 'wbic-ic-report',
        onClick: () => setExportingSectionRefID(panelBankSectionConfigRef.id),
      },
      {
        key: 'deleteSection',
        text: 'Delete section',
        icon: 'wbic-ic-delete',
        onClick: deleteSection,
      },
    ]);
    const sectionName = panelBankSectionConfig.name;

    // Collapse all sections if we're dragging a section (forceCollapsed=true)
    const sectionIsOpen = panelBankSectionConfig.isOpen && !forceCollapsed;

    return (
      <>
        <DropTarget
          isValidDropTarget={({dragRef, dragData}) => {
            return (
              isPanelBankSection(dragRef) ||
              (isPanel(dragRef) &&
                !isDraggingWithinSection(panelBankSectionConfigRef, dragData))
            );
          }}
          partRef={panelBankSectionConfigRef}
          getClassName={({dragRef, dropRef}) => {
            const elementClassNames = ['panel-bank__section'];
            if (!sectionIsOpen) {
              elementClassNames.push('collapsed');
            }
            if (isHiddenPanelsSection) {
              elementClassNames.push('panel-bank__section__hidden-panels');
            }
            if (dragRef != null && isPanel(dragRef)) {
              // always added when dragging a panel, even if the panel is not in this section
              elementClassNames.push('dragging-panel');
            }
            if (
              dropRef != null &&
              !_.isEqual(dragRef, dropRef) &&
              _.isEqual(panelBankSectionConfigRef, dropRef)
            ) {
              if (isPanelBankSection(dragRef)) {
                elementClassNames.push('active-section-drop-target');
              }
              if (
                isPanel(dragRef) &&
                (!sectionIsOpen ||
                  activePanelRefs.length + inactivePanelRefs.length === 0)
              ) {
                elementClassNames.push('active-box-drop-target');
              }
            }
            return elementClassNames.join(' ');
          }}
          onDrop={({dragRef, dragData}) => {
            // Drag panel onto section
            if (isPanel(dragRef) && dragData) {
              movePanel(
                dragRef,
                dragData.fromSectionRef,
                panelBankSectionConfigRef,
                0
              );
            }
            // Drag section onto section
            if (isPanelBankSection(dragRef)) {
              if (!_.isEqual(dragRef, panelBankSectionConfigRef)) {
                moveSectionBefore(dragRef, panelBankSectionConfigRef);
              }
            }
          }}>
          <DragSource partRef={panelBankSectionConfigRef}>
            <div className="panel-bank__section-title">
              <span
                className="panel-bank__section-title-left"
                onClick={toggleIsOpen}>
                <LegacyWBIcon
                  className={sectionIsOpen ? 'open' : undefined}
                  name="next"
                />
                <EditableLabel
                  title={sectionName}
                  onSave={updateName}
                  onBlur={() => setEditingName(false)}
                  editing={editingName}
                  serverText={sectionName}
                  onClick={() => {
                    // we use the menu option to trigger editing mode, rather than direct click
                    return;
                  }}
                />
                <Label className="count-label">
                  {!_.isEmpty(searchQuery)
                    ? `${activePanelCount} of ${totalPanelCount}`
                    : activePanelCount}
                </Label>
              </span>
              {!isHiddenPanelsSection && (
                <>
                  <DragHandle
                    className="panel-bank__section__drag-handle"
                    partRef={panelBankSectionConfigRef}
                    key={`${panelBankSectionConfig.isOpen}${panelBankSectionConfig.name}${activePanelCount}`}>
                    <LegacyWBIcon
                      title=""
                      name="handle"
                      onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                    />
                  </DragHandle>
                  <div className="panel-bank__section-title-right">
                    <div
                      className={classNames({
                        'panel-section-options-visible': settingsPopupOpen,
                        'panel-section-options': !settingsPopupOpen,
                      })}>
                      <PopupDropdown
                        trigger={
                          <LegacyWBIcon
                            name="overflow"
                            size="big"
                            onClick={(e: React.SyntheticEvent) =>
                              e.stopPropagation()
                            }
                          />
                        }
                        options={sectionMenuOptions}
                        position="bottom left"
                        offset={'-320px, -16px'}
                      />
                    </div>
                    <LocalPanelSettingsComponentControlled
                      runSetRefs={runSetRefs}
                      useAllLinePlotPanels={useAllLinePlotPanels}
                      workspacePanelSettingsRef={workspacePanelSettingsRef}
                      localPanelSettingsRef={localPanelSettingsRef}
                      settingsPopupOpen={settingsPopupOpen}
                      setSettingsPopupOpen={setSettingsPopupOpen}
                    />
                    {addVisButton}
                  </div>
                </>
              )}
            </div>

            {/* using display: none because we don't want to unmount the panels if forceCollapsed is true */}
            <div style={!sectionIsOpen ? {display: 'none'} : {}}>
              {panelBankSectionConfig.isOpen && (
                <SectionComponent
                  key={searchQuery}
                  panelBankSectionConfigRef={panelBankSectionConfigRef}
                  panelBankWidth={panelBankWidth}
                  activePanelRefs={activePanelRefs}
                  inactivePanelRefs={inactivePanelRefs}
                  movePanelBetweenSections={movePanel}
                  addVisButton={addVisButton}
                  // HAXX: this prop is not used, but needed to make the PanelBankFlowSection
                  // rerender when settings are changed
                  panelSettings={usedPanelSettings}
                  renderPanel={panelRef => (
                    <PanelBankPanel
                      readOnly={readOnly}
                      panelRef={panelRef}
                      panelBankSectionConfigRef={panelBankSectionConfigRef}
                      panelBankConfigRef={panelBankConfigRef}
                      runSetRefs={runSetRefs}
                      historyKeyInfo={historyKeyInfo}
                      customRunColorsRef={customRunColorsRef}
                      panelSettings={usedPanelSettings}
                      searchQuery={searchQuery}
                      deletePanel={() => {
                        deletePanel(panelRef, panelBankConfigRef);
                      }}
                      // deletePanel={() => deletePanel(panelRef)}
                      movePanel={movePanel}
                      duplicatePanel={() => {
                        duplicatePanel(panelRef);
                      }}
                      defaultMoveToSectionRef={defaultMoveToSectionRef}
                    />
                  )}
                />
              )}
            </div>
          </DragSource>
        </DropTarget>
        <div className="panel-bank__divider" />
      </>
    );
  },
  {id: 'PanelBankSection'}
);

export interface PanelBankPanelState {
  historyKeyInfo?: RunHistoryKeyInfo;
}

const defaultPanelBankPanelState: PanelBankPanelState = {
  historyKeyInfo: undefined,
};

export const PanelBankPanelContext = createContext(defaultPanelBankPanelState);

interface PanelBankPanelProps {
  panelRef: PanelTypes.Ref;
  panelBankConfigRef?: PanelBankConfigTypes.Ref;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  panelSettings?: PanelSettingsTypes.PanelSettings;
  historyKeyInfo?: RunHistoryKeyInfo;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  searchQuery?: string;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  loading?: boolean;
  deletePanel(): void;
  duplicatePanel(): void;
  movePanel?(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionConfigTypes.Ref,
    toSectionRef: PanelBankSectionConfigTypes.Ref,
    toIndex: number
  ): void;
  onContentHeightChange?(h: number): void;
}

interface PanelCommentsProps {
  panelCommentCount?: number;
  isHighlighted?: boolean;
  openPanelComments?(): void;
  addComment?(): void;
}

export const PanelBankPanel = makeComp(
  (props: PanelBankPanelProps & PanelCommentsProps) => {
    const {
      panelRef,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      runSetRefs,
      customRunColorsRef,
      panelSettings,
      historyKeyInfo,
      searchQuery,
      defaultMoveToSectionRef,
      deletePanel,
      readOnly,
      disableRunLinks,
      loading,
      panelCommentCount,
      movePanel,
      duplicatePanel,
      onContentHeightChange,
      openPanelComments,
      addComment,
      isHighlighted,
    } = props;
    const panel = ViewHooks.useWhole(panelRef);
    const contextValue: PanelBankPanelState = useMemo(() => {
      return {historyKeyInfo};
    }, [historyKeyInfo]);
    return (
      // // Dummy div for testing -- use this if you want to render placeholder boxes without charts
      // <div style={{border: '1px solid'}} draggable="true" className="blank-box">
      //   <DragHandle className="draggable-handle" partRef={panelRef}>
      //     <WBIcon name="handle" />
      //   </DragHandle>
      //   {panelRef.id}
      // </div>
      <PanelBankPanelContext.Provider value={contextValue}>
        <EditablePanel
          key={panelRef.id}
          readOnly={readOnly}
          disableRunLinks={disableRunLinks}
          runSetRefs={runSetRefs}
          panelRef={panelRef}
          customRunColorsRef={customRunColorsRef}
          panelSettings={panelSettings}
          className={`${
            searchQuery && !searchQueryMatchesPanel(searchQuery, panel)
              ? 'search-miss'
              : ''
          } ${panel.viewType.toLowerCase().replace(' ', '-') + '-container'}
      `}
          searchQuery={searchQuery}
          currentHeight={0}
          loading={loading}
          dragHandle={
            <DragHandle
              key={`draghandle-${panelRef.id}`}
              className="draggable-handle"
              partRef={panelRef}>
              <LegacyWBIcon title="" name="handle" />
            </DragHandle>
          }
          onRemovePanel={deletePanel}
          panelBankConfigRef={panelBankConfigRef}
          panelBankSectionConfigRef={panelBankSectionConfigRef}
          movePanel={movePanel}
          duplicatePanel={duplicatePanel}
          onContentHeightChange={onContentHeightChange}
          defaultMoveToSectionRef={defaultMoveToSectionRef}
          openPanelComments={openPanelComments}
          panelCommentCount={panelCommentCount}
          addComment={addComment}
          isHighlighted={isHighlighted}
        />
      </PanelBankPanelContext.Provider>
    );
  },
  {id: 'PanelBankPanel'}
);

// Render a panel with discussion threads/comments
// Currently only works in reports -- needs to be inside <ReportDiscussionContext>
export const PanelWithComments = makeComp(
  (props: PanelBankPanelProps) => {
    const {panelCommentCount, openPanelComments, addComment, isHighlighted} =
      usePanelComments({panelRef: props.panelRef});

    return (
      <PanelBankPanel
        {...props}
        openPanelComments={openPanelComments}
        panelCommentCount={panelCommentCount}
        addComment={props.readOnly ? addComment : undefined}
        isHighlighted={isHighlighted}
      />
    );
  },
  {id: 'PanelWithDiscussion'}
);
