import React, {useState} from 'react';
import makeComp from '../util/profiler';

import {
  Button,
  Dropdown,
  DropdownItemProps,
  Input,
  Modal,
} from 'semantic-ui-react';
import {useArtifactTypesQuery} from '../generated/graphql';

interface RenameArtifactModalProps {
  name: string;
  modalOpen: boolean;
  onRenameArtifact(newName: string): void;
  onClose(): void;
}

export const RenameArtifactModal = makeComp(
  (props: RenameArtifactModalProps) => {
    const {name, modalOpen, onRenameArtifact, onClose} = props;
    const [newName, setNewName] = useState(name);

    return (
      <Modal size="tiny" open={modalOpen} onClose={onClose}>
        <Modal.Header>
          Are you sure you want to rename this artifact?
        </Modal.Header>
        <Modal.Content>
          <p>
            This will break all aliases associated with the current name. You
            will need to update your scripts to refer to the updated name.
          </p>
          <Input
            fluid
            value={newName}
            placeholder={'Artifact name...'}
            onChange={(e, data) => {
              setNewName(data.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="green"
            disabled={newName === name || newName === ''}
            onClick={() => {
              onRenameArtifact(newName);
            }}>
            Rename
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'RenameArtifactModal'}
);

interface MoveArtifactModalProps {
  entityName: string;
  projectName: string;
  modalOpen: boolean;
  onMoveArtifact(destinationArtifactTypeName: string): void;
  onClose(): void;
}

export const MoveArtifactModal = makeComp(
  (props: MoveArtifactModalProps) => {
    const {entityName, projectName, modalOpen, onMoveArtifact, onClose} = props;
    const [value, setValue] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<DropdownItemProps[]>([]);
    const {loading} = useArtifactTypesQuery({
      fetchPolicy: 'no-cache',
      skip: !modalOpen,
      onCompleted: data => {
        setOptions(
          data!.project!.artifactTypes.edges.map(edge => {
            return {
              key: edge.node!.name,
              value: edge.node!.name,
              text: edge.node!.name,
            };
          })
        );
      },
      variables: {
        entityName,
        projectName,
      },
    });

    return (
      <Modal size="tiny" open={modalOpen} onClose={onClose}>
        <Modal.Header>
          Are you sure you want to move this artifact?
        </Modal.Header>
        <Modal.Content>
          <p>
            You will need to update your scripts to log new versions using the
            updated type name.
          </p>
          <Dropdown
            fluid
            search
            selection
            allowAdditions
            placeholder={'Destination artifact type...'}
            options={options}
            loading={loading}
            value={value}
            onChange={(e, data) => {
              setValue(data.value as string);
            }}
            onAddItem={(e, data) => {
              setOptions([
                {
                  key: data.value as string,
                  value: data.value as string,
                  text: data.value as string,
                },
                ...options,
              ]);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="green"
            disabled={value === undefined || value === ''}
            onClick={() => onMoveArtifact(value!)}>
            Move
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'MoveArtifactModal'}
);

interface DeleteArtifactModalProps {
  modalOpen: boolean;
  onDeleteArtifact(): void;
  onClose(): void;
}

export const DeleteArtifactModal = makeComp(
  (props: DeleteArtifactModalProps) => {
    return (
      <Modal size="tiny" open={props.modalOpen} onClose={props.onClose}>
        <Modal.Header>
          Are you sure you want to delete this artifact?
        </Modal.Header>
        <Modal.Content>
          <p>
            All versions of the artifact and the underlying files will be
            scheduled for deletion.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            color="red"
            data-cy="delete-artifact-sequence-confirm"
            onClick={() => {
              props.onDeleteArtifact();
              props.onClose();
            }}>
            Delete this artifact
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'DeleteArtifactModal'}
);
