import '../../css/BenchmarkPage.less';

import {ApolloClient} from 'apollo-client';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, {FC, useEffect} from 'react';
import {graphql, withApollo} from 'react-apollo';
import {Link} from 'react-router-dom';
import ReactTimeago from 'react-timeago';
import {compose} from 'redux';
import {Button, Divider, Header, Menu, Modal, Segment} from 'semantic-ui-react';

import RunPathScreenshot from '../../assets/screenshots/run_path_screenshot.png';
import {AnnotateScreenshot} from '../../components/AnnotateScreenshot';
import BenchmarkDiscussionBoard from '../../components/BenchmarkDiscussionBoard';
import BenchmarkEditorFields from '../../components/BenchmarkEditorFields';
import BenchmarkPageHeader from '../../components/BenchmarkPageHeader';
import {EasyTable} from '../../components/EasyTable';
import RoutedTab from '../../components/RoutedTab';
import SubmitRunById from '../../components/SubmitRunById';
import SubmitToBenchmarkModal from '../../components/SubmitToBenchmarkModal';
import Loader from '../../components/WandbLoader';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {
  BenchmarkPageQueryResultProps,
  withBenchmarkPageQuery,
} from '../../graphql/benchmarkPageQuery';
import {UpdateProjectMutationFn} from '../../graphql/models';
import {withViewerProjects} from '../../graphql/users_get_viewer';
import {Subset} from '../../types/base';
import {BenchmarkProject, WithSummary} from '../../types/graphql';
import * as Benchmark from '../../util/benchmark';
import * as Run from '../../util/runs';
import * as url from '../../util/urls';
import {getTheme} from './Theme';
import {setDocumentTitle} from '../../util/document';
import makeComp from '../../util/profiler';

interface BenchmarkProjectDataLoading {
  loading: true;
  project: undefined;
}

interface BenchmarkProjectDataLoaded {
  loading: false;
  project: BenchmarkProject;
}

interface BenchmarkProjectPageProps {
  client: ApolloClient<any>;

  // TODO: This used to be typed 'User' from types/graphql, which was
  // inaccurate. Should be set to the generated type but I can't find it.
  viewer?: any;

  viewerLoading?: boolean;
  data: BenchmarkProjectDataLoaded | BenchmarkProjectDataLoading;
  updateProject: UpdateProjectMutationFn;
  history: any;
  match: {
    params: {
      entityName: string;
      projectName: string;
      projectId: string;
      tab?: string;
      threadID?: string;
    };
  };
}

export const BENCHMARK_UPSERT = gql`
  mutation upsertLinkedBenchmark(
    $description: String
    $entityName: String
    $id: String
    $name: String
    $framework: String
    $access: String
    $views: JSONString
    $linkedBenchmark: ID
  ) {
    upsertModel(
      input: {
        description: $description
        entityName: $entityName
        id: $id
        name: $name
        framework: $framework
        access: $access
        views: $views
        linkedBenchmark: $linkedBenchmark
      }
    ) {
      project {
        id
        name
        entityName
        description
        access
        views
        isBenchmark
        linkedBenchmark {
          id
          name
        }
      }
      inserted
    }
  }
`;

const BenchmarkPage: FC<
  BenchmarkProjectPageProps & BenchmarkPageQueryResultProps
> = makeComp(
  ({viewer, viewerLoading, updateProject, history, match, pageQuery}) => {
    useEffect(() => {
      setDocumentTitle(match.params.projectName);
    }, [match.params.projectName]);

    // TODO: This should be an apollo middleware
    // We want to guarantee empty arrays here to make the code cleaner.
    const withEmptyEdges = (b: BenchmarkProject) => ({
      ...b,
      acceptedBenchmarkRuns: b.acceptedBenchmarkRuns || {edges: []},
      rejectedBenchmarkRuns: b.rejectedBenchmarkRuns || {edges: []},
      submittedBenchmarkRuns: b.submittedBenchmarkRuns || {edges: []},
    });

    if (pageQuery.loading) {
      return <Loader />;
    }

    let benchmark: BenchmarkProject;
    if (pageQuery.benchmark) {
      benchmark = withEmptyEdges(pageQuery.benchmark);
    } else {
      return <Loader />;
    }

    if (viewerLoading) {
      return <Loader />;
    }

    const theme = getTheme(benchmark);

    // TODO(nbardy): Consider moving this to the benchmarkPageQuery.
    // This should be part of a bigger discussion of organizing code,
    // especially types, around pre-processing.
    const withSummary = (r: BenchmarkRun): WithSummary<BenchmarkRun> => ({
      ...r,
      summary: Run.parseSummary(r.run.summaryMetrics, r.run.name || ''),
    });

    const {entityName, projectName, tab} = match.params;
    const acceptedRuns = benchmark.acceptedBenchmarkRuns.edges
      .map(e => e.node)
      .map(withSummary);
    const rejectedRuns = benchmark.rejectedBenchmarkRuns.edges
      .map(e => e.node)
      .map(withSummary);

    const submittedRuns = benchmark.submittedBenchmarkRuns.edges
      .map(e => e.node)
      .map(withSummary);

    const viewerSubmittedRuns = acceptedRuns
      .concat(rejectedRuns)
      .concat(submittedRuns)
      .filter(br => viewer && br.user.username === viewer.username);

    const reviewedRuns = acceptedRuns.concat(rejectedRuns).sort((a, b) => {
      const d1 = new Date(a.createdAt).getTime();
      const d2 = new Date(b.createdAt).getTime();

      return d1 - d2;
    });

    const scoreCols = _.map(theme.keys, k => ({
      header: k.toString(),
      bodyFn: (run: WithSummary<BenchmarkRun>) => {
        return Run.displayValue(Run.lookupKey(run, k));
      },
      Header: k.toString(),
      id: k.toString(),
      minWidth: 115,
      accessor: (run: WithSummary<BenchmarkRun>) => {
        console.log(run);
        return Run.displayValue(Run.lookupKey(run, k));
      },
    }));

    const overviewTab = () => {
      return theme.overview(acceptedRuns);
    };

    const leaderboardTab = () => {
      return (
        <div className="leaderboard-tab">
          {theme.leaderboard && theme.leaderboard(acceptedRuns)}
        </div>
      );
    };

    const discussionTab = () => {
      return (
        <BenchmarkDiscussionBoard
          benchmarkEntityName={benchmark.entityName}
          benchmarkProjectName={benchmark.name}
          threadID={match.params.threadID}
        />
      );
    };

    const mySubmissionsTab = () => {
      const readableState = (br: Subset<BenchmarkRun, 'state' | 'details'>) => {
        const stateToDisplay: {
          [key: string]: string;
        } = {
          SUBMITTED: 'In review',
          ACCEPTED: 'Accepted',
          REJECTED: 'Rejected',
        };
        let result = stateToDisplay[br.state];
        if (br.state === 'REJECTED' && br.details && br.details.reason) {
          result += ': ' + br.details.reason;
        }
        return result;
      };

      // sort by most recent first
      viewerSubmittedRuns.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );

      return (
        <div className="my-submissions-tab">
          <Segment>
            Submissions are copies of your original runs. Changes to your
            original runs will not affect their copies. Scroll to the right
            inside the table below to see more details about each submission.
          </Segment>
          <WBReactTable
            data={viewerSubmittedRuns.map(benchmarkRun => {
              return {
                row: benchmarkRun,
                searchString: benchmarkRun.run.displayName || '',
              };
            })}
            getTrProps={(state: any, rowInfo: any) => {
              if (rowInfo == null) {
                return {};
              }
              const currentDate = new Date();
              const createdAtDate = new Date(rowInfo.original.createdAt + 'Z');
              if (currentDate.getTime() - createdAtDate.getTime() < 60 * 1000) {
                return {
                  className: 'recently-submitted-run',
                };
              }
              return {};
            }}
            columns={[
              {
                Header: 'Submission',
                id: 'submission',
                minWidth: 120,
                accessor: benchmarkRun => (
                  <Link to={url.benchmarkRunOverview(benchmarkRun)}>
                    {benchmarkRun.run.displayName || benchmarkRun.run.name}
                  </Link>
                ),
              },
              {
                Header: 'Notes',
                id: 'notes',
                accessor: benchmarkRun => (
                  <div
                    style={{
                      WebkitLineClamp: 2,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                    }}
                    title={benchmarkRun.run.notes}>
                    {benchmarkRun.run.notes}
                  </div>
                ),
              },
              {
                Header: 'Status',
                id: 'status',
                accessor: benchmarkRun =>
                  readableState({
                    state: benchmarkRun.state,
                    details: benchmarkRun.details,
                  }),
              },
              {
                Header: 'Submitted',
                id: 'submissionDate',
                accessor: benchmarkRun => (
                  <ReactTimeago
                    date={benchmarkRun.createdAt + 'Z'}
                    live={false}
                  />
                ),
              },
              ...scoreCols,
              {
                Header: 'Original Run',
                id: 'originalRun',
                minWidth: 120,
                accessor: benchmarkRun =>
                  benchmarkRun.originalRun &&
                  benchmarkRun.originalProject && (
                    <Link
                      to={url.run({
                        entityName:
                          benchmarkRun.originalProject.entityName || '',
                        projectName: benchmarkRun.originalProject.name || '',
                        name: benchmarkRun.originalRun.name || '',
                      })}>
                      {benchmarkRun.originalRun.displayName ||
                        benchmarkRun.originalRun.name}
                    </Link>
                  ),
              },
              {
                Header: 'Original Project',
                id: 'originalProject',
                minWidth: 130,
                accessor: benchmarkRun =>
                  benchmarkRun.originalRun &&
                  benchmarkRun.originalProject && (
                    <Link
                      to={url.project({
                        entityName:
                          benchmarkRun.originalProject.entityName || '',
                        name: benchmarkRun.originalProject.name || '',
                      })}>
                      {benchmarkRun.originalProject.name}
                    </Link>
                  ),
              },
            ]}
          />
        </div>
      );
    };

    const adminTab = () => {
      return (
        <div className="benchmark-admin-tab">
          <Header as="h3">Awaiting Review</Header>
          <EasyTable
            cols={[
              {
                header: 'Run',
                bodyFn: br => (
                  <Link to={url.benchmarkRunOverview(br)}>
                    {br.run.displayName}
                  </Link>
                ),
              },
              {header: 'Author', key: 'user.username'},
              ...scoreCols,
              {
                header: '',
                bodyFn: (br: BenchmarkRun) => (
                  <Link
                    to={br.gitHubSubmissionPR || url.benchmarkRunOverview(br)}>
                    <Button primary>Review Run</Button>
                  </Link>
                ),
              },
            ]}
            rows={submittedRuns}
          />
          <Header as="h3">Reviewed Runs</Header>
          <EasyTable
            cols={[
              {
                header: 'Run',
                bodyFn: br => (
                  <Link to={url.benchmarkRunOverview(br)}>
                    {br.run.displayName}
                  </Link>
                ),
              },
              {header: 'Author', key: 'user.username'},
              ...scoreCols,
              {header: 'Submitted', key: 'createdAt'},
              {header: 'Decision', key: 'state'},
              {header: 'Reason', key: 'details.reason'},
            ]}
            rows={reviewedRuns}
          />
          {theme.benchmarkFields && (
            <>
              <Header as="h3">Benchmark settings</Header>
              <BenchmarkEditorFields
                defaultBenchmarkFields={theme.benchmarkFields}
                withSave={true}
                onSave={fields =>
                  updateProject({
                    id: benchmark.id,
                    views: Benchmark.toJSON(fields),
                  })
                }
              />
            </>
          )}
        </div>
      );
    };

    // TODO: Find generated type. Used to be typed Run.Run[], which was inaccurate.
    const recentLinkedRuns: any[] = [];
    if (viewer && viewer.linkedProjects) {
      const linkedProjects = viewer.linkedProjects;
      if (!linkedProjects.edges) {
        return null;
      }

      // Add all runs that are linked, but have not been submitted
      linkedProjects.edges.forEach((lp: any) => {
        if (lp.node.runs.edges) {
          lp.node.runs.edges.forEach((edge: any) => {
            if (!edge.node.benchmarkRun) {
              recentLinkedRuns.push({
                ...edge.node,
                summary: Run.parseSummary(
                  edge.node.summaryMetrics,
                  edge.node.name || ''
                ),
              });
            }
          });
        }
      });
    }

    // sort by most recent first
    recentLinkedRuns.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );

    const submitRunTab = () => {
      return (
        <div className="submit-run-tab">
          {recentLinkedRuns.length > 0 && (
            <>
              <Header as="h3">Submit recent run</Header>
              <WBReactTable
                data={recentLinkedRuns.map(r => {
                  return {
                    row: r,
                    searchString: r.displayName + r.description,
                  };
                })}
                columns={[
                  {
                    Header: 'Name',
                    id: 'displayName',
                    accessor: run => (
                      <Link
                        to={url.run({
                          name: run.name,
                          projectName: run.project.name,
                          entityName: run.project.entityName,
                        })}>
                        {run.displayName}
                      </Link>
                    ),
                  },
                  ...(recentLinkedRuns.every(
                    run => run.project.name === recentLinkedRuns[0].project.name
                  )
                    ? []
                    : [
                        {
                          Header: 'Project',
                          id: 'project',
                          accessor: (run: any) => (
                            <Link
                              to={url.project({
                                name: run.project.name,
                                entityName: run.project.entityName,
                              })}>
                              {run.project.name}
                            </Link>
                          ),
                        },
                      ]),
                  {
                    Header: 'Created At',
                    id: 'createdAt',
                    accessor: run => (
                      <ReactTimeago date={run.createdAt + 'Z'} live={false} />
                    ),
                  },
                  ...(recentLinkedRuns.every(run => !run.notes)
                    ? []
                    : [
                        {
                          Header: 'Notes',
                          id: 'notes',
                          accessor: (run: any) => (
                            <div
                              style={{
                                WebkitLineClamp: 2,
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                              }}
                              title={run.notes}>
                              {run.notes}
                            </div>
                          ),
                        },
                      ]),
                  {Header: 'Run ID', accessor: 'name'},
                  ...scoreCols,
                  {
                    Header: null,
                    id: 'submit',
                    maxWidth: 80,
                    accessor: run => (
                      <SubmitToBenchmarkModal
                        runName={run.name}
                        summaryMetrics={run.summaryMetrics}
                        runId={run.id}
                        trigger={
                          <Button
                            style={{marginRight: 2, float: 'right'}}
                            primary
                            size="tiny">
                            Submit
                          </Button>
                        }
                        run={run}
                        benchmarkMeta={benchmark}
                        viewer={viewer}
                        project={run.project}
                        history={history}
                      />
                    ),
                  },
                ]}
              />
              <Divider horizontal>Or</Divider>
            </>
          )}
          <Header as="h3">
            Submit by run path
            <Modal
              trigger={
                <span className="help-text underline-dashed">
                  What is this?
                </span>
              }>
              <Modal.Header>What is the "Run Path"?</Modal.Header>
              <Modal.Content>
                <p>
                  Formatted as <code>entity/project/run_id</code>, a run's path
                  is its unique identifier. You can find the path of your run on
                  its overview page, as shown below:
                </p>
                <AnnotateScreenshot
                  marks={[
                    {
                      type: 'rectangle' as const,
                      x: '30%',
                      y: '83%',
                      width: '36%',
                      height: '8%',
                      rounded: 5,
                      borderWidth: 3,
                      borderColor: 'blue',
                    },
                  ]}>
                  <img
                    alt="Shows the overview tab on the run page and a highlighting the run path row"
                    style={{maxWidth: '100%'}}
                    src={RunPathScreenshot}
                  />
                </AnnotateScreenshot>
              </Modal.Content>
            </Modal>
          </Header>
          <SubmitRunById
            viewer={viewer}
            benchmark={benchmark}
            history={history}
          />
        </div>
      );
    };

    const tabSlugs = [''];
    const tabPanes = [
      {
        menuItem: <Menu.Item key="overview">Overview</Menu.Item>,
        render: overviewTab,
      },
    ];

    if (theme.leaderboard) {
      tabSlugs.push('leaderboard');
      tabPanes.push({
        menuItem: <Menu.Item key="leaderboard">Leaderboard</Menu.Item>,
        render: leaderboardTab,
      });
    }

    tabSlugs.push('discussion');
    tabPanes.push({
      menuItem: <Menu.Item key="discussion">Discussion</Menu.Item>,
      render: discussionTab,
    });

    if (viewerSubmittedRuns.length > 0) {
      tabSlugs.push('mysubmissions');
      tabPanes.push({
        menuItem: <Menu.Item key="mysubmissions">My Submissions</Menu.Item>,
        render: mySubmissionsTab,
      });
    }

    const isAdmin = !pageQuery.benchmark.readOnly;
    if (isAdmin) {
      tabSlugs.push('admin');
      tabPanes.push({
        menuItem: <Menu.Item key="admin">Admin</Menu.Item>,
        render: adminTab,
      });
    }

    tabSlugs.push('submit');
    tabPanes.push({
      menuItem: (
        <Menu.Item key="submit" className="action-tab">
          Submit a run
        </Menu.Item>
      ),
      render: submitRunTab,
    });

    return (
      <div className="benchmark-project-page">
        <BenchmarkPageHeader
          avatarUrl={benchmark.entity.photoUrl || ''}
          benchmarkEntityName={benchmark.entity.name}
          benchmarkName={benchmark.name}
          benchmarkDescription={benchmark.description}
          readOnly={!!benchmark.readOnly}
          onSaveDescription={text => {
            // TODO: This makes no sense, why do I need both id and linkedBenchmark id?
            updateProject({
              id: benchmark.id,
              linkedBenchmark: benchmark.id,
              description: text,
            });
          }}
          tabs={
            <RoutedTab
              className="page-tabs"
              panes={tabPanes}
              activeTabSlug={tab}
              tabSlugs={tabSlugs}
              history={history}
              baseUrl={'/' + entityName + '/' + projectName + '/benchmark'}
            />
          }
        />
      </div>
    );
  },
  {id: 'BenchmarkPage', memo: true}
);

const withData = compose(withBenchmarkPageQuery, withViewerProjects) as any;

const withMutations = graphql(BENCHMARK_UPSERT, {
  props: ({mutate}) => ({
    updateProject: (variables: any) =>
      mutate &&
      mutate({
        variables: {...variables},
      }),
  }),
}) as any;

export default withMutations(withApollo(withData(BenchmarkPage)));
