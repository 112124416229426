import {Editor} from 'slate';

export const autoScrollOnKeyDown = (editor: Editor, e: React.KeyboardEvent) => {
  if (e.key !== 'Shift' && e.key !== 'Meta') {
    window.setTimeout(() => {
      const domSelection = window.getSelection();
      if (domSelection != null && domSelection.rangeCount > 0) {
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();

        if (rect.x === 0 && rect.y === 0) {
          // weird bug when cursor is on empty line
          return;
        }

        if (rect.bottom > window.innerHeight - 100) {
          window.scrollBy({
            top: rect.bottom - window.innerHeight + 100,
          });
        }

        if (rect.top < 100) {
          window.scrollBy({
            top: rect.top - 100,
          });
        }
      }
    });
  }

  return false;
};
