import '../css/ImageIconHeader.less';

import React from 'react';

import ImageIcon, {ImageIconName} from '../components/ImageIcon';
import makeComp from '../util/profiler';

interface ImageIconHeaderProps {
  icon: ImageIconName;
  text: string;
}

const ImageIconHeader: React.FC<ImageIconHeaderProps> = makeComp(
  ({icon, text}) => {
    return (
      <p className="image-icon-header">
        <ImageIcon name={icon} style={{}} />
        {text}
      </p>
    );
  },
  {id: 'ImageIconHeader', memo: true}
);

export default ImageIconHeader;
