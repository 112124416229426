import '../css/RunQueue.less';
import * as S from './RunQueueTab.styles';
import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {Modal, Button, Loader, InputOnChangeData} from 'semantic-ui-react';
import * as ViewerHooks from '../state/viewer/hooks';

import {
  RunQueue,
  useCreateRunQueueMutation,
  CreateRunQueueMutation,
  useDeleteRunQueuesMutation,
  useDeleteFromRunQueueMutation,
  useTeamMembersQuery,
  RunQueueAccessType,
  useRunsStateQuery,
  RunQueueItem,
} from '../generated/graphql';
import makeComp from '../util/profiler';
import MultiSelectTable from './MultiSelectTable';
import MultiStateCheckbox from './MultiStateCheckbox';
import {WBIcon} from '@wandb/ui';
import {ApolloCurrentQueryResult} from 'apollo-client';
import {ProjectPageQueryData} from '../state/graphql/projectPageQuery';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {runNamesFilter, toMongo} from '../util/filters';
import {run} from '../util/urls';
import {WBPopupMenuTrigger} from '@wandb/ui';
import {isNotNullOrUndefined} from '../util/types';
import {useHistory} from 'react-router';
import {
  CreateRunQueueArgs,
  createRunQueueByName,
} from './EditLaunchConfigModal';
import {ExecutionResult} from 'react-apollo';
import {toast} from 'react-toastify';

export const DEFAULT_RUN_QUEUE_NAME = 'default';

type SortBy = 'name' | 'count';
type SortFn = (r: RunQueue) => string | number;

const SORT_FUNCTION_BY_NAME: {[s in SortBy]: SortFn} = {
  name: r => r.name,
  count: r => r.runQueueItems.edges.length,
};

interface RunQueueTabProps {
  entityName: string;
  projectLoading: boolean;
  projectName: string;
  isTeam: boolean;
  runQueues: RunQueue[];
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
}

const RunQueueTab: React.FC<RunQueueTabProps> = makeComp(
  props => {
    const [mutatingRunQueues, setMutatingRunQueues] = useState(false);
    const [createRunQueue] = useCreateRunQueueMutation();

    const memberData = useTeamMembersQuery({
      variables: {entityName: props.entityName},
    });

    const viewer = ViewerHooks.useViewer();
    const teamMembers = memberData?.data?.entity?.members;

    function isTeamMember() {
      if (viewer == null) {
        return false;
      } else if (teamMembers != null) {
        return (
          teamMembers.findIndex(
            member => member.username === viewer.username
          ) !== -1
        );
      }
      return false;
    }

    const viewerIsTeamMember = props.isTeam && isTeamMember();
    const isViewersProject = props.entityName === viewer?.entity;
    const canCreateRunQueue = isViewersProject || viewerIsTeamMember;
    const showTabContents = viewer != null && memberData.data != null;
    if (!showTabContents) {
      return <Loader />;
    }
    return (
      <>
        <RunQueueTabContents
          {...props}
          runQueues={props.runQueues}
          canCreateRunQueue={canCreateRunQueue}
          mutatingRunQueues={mutatingRunQueues}
          createRunQueue={createRunQueue}
          setMutatingRunQueues={setMutatingRunQueues}
        />
        {mutatingRunQueues && (
          <AddRunQueueModal
            open
            onClose={() => {
              setMutatingRunQueues(false);
            }}
            entityName={props.entityName}
            projectName={props.projectName}
            isTeam={props.isTeam}
            runQueues={props.runQueues}
            refetchProject={props.refetchProject}
          />
        )}
      </>
    );
  },
  {id: 'RunQueueTab'}
);

export default RunQueueTab;

interface RunQueueTabContentsProps extends RunQueueTabProps {
  runQueues: RunQueue[];
  canCreateRunQueue: boolean;
  mutatingRunQueues: boolean;
  createRunQueue: (
    variables: CreateRunQueueArgs
  ) => Promise<ExecutionResult<CreateRunQueueMutation>>;
  setMutatingRunQueues(state: boolean): void;
}

const RunQueueTabContents: React.FC<RunQueueTabContentsProps> = makeComp(
  ({
    runQueues,
    projectName,
    entityName,
    canCreateRunQueue,
    projectLoading,
    createRunQueue,
    setMutatingRunQueues,
    refetchProject,
  }) => {
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [sortReverse, setSortReverse] = useState(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [autoCreatingRunQueue, setAutoCreatingRunQueue] = useState(false);

    useEffect(() => {
      if (!projectLoading && runQueues.length === 0 && !autoCreatingRunQueue) {
        setAutoCreatingRunQueue(true);
        const autoCreateDefaultRunQueue = async () => {
          await createRunQueueByName(
            createRunQueue,
            'default',
            entityName,
            projectName
          );
          await refetchProject();
          setAutoCreatingRunQueue(false);
        };
        autoCreateDefaultRunQueue();
      }
    }, [
      runQueues,
      autoCreatingRunQueue,
      entityName,
      projectName,
      projectLoading,
      refetchProject,
      setAutoCreatingRunQueue,
      createRunQueue,
    ]);

    const toggleSort = useCallback(
      (sb: SortBy) => {
        if (sb === sortBy) {
          setSortReverse(prev => !prev);
          return;
        }
        setSortBy(sb);
        setSortReverse(false);
      },
      [sortBy]
    );
    const renderSortIcon = () => {
      return (
        <WBIcon
          name={`${sortReverse ? 'down' : 'up'}-arrow`}
          className="report-table__sort-icon"
        />
      );
    };
    if (runQueues.length === 0) {
      return <Loader />;
    }

    return (
      <>
        <S.Header>Run Queues ({runQueues.length})</S.Header>
        <MultiSelectTable
          className="runqueues-table"
          readOnly={!canCreateRunQueue}
          rows={runQueues}
          sortBy={SORT_FUNCTION_BY_NAME[sortBy]}
          sortReverse={sortReverse}
          renderRow={(r, selected, setSelected) => {
            const selectable = !(
              r.name === 'default' && r.access === RunQueueAccessType.Project
            );
            return (
              <RunQueuesTableRow
                key={r.id}
                selected={selectable ? selected : false}
                projectName={projectName}
                entityName={entityName}
                setSelected={
                  selectable
                    ? setSelected
                    : () => {
                        toast('Cannot delete default run queue');
                      }
                }
                runQueue={r}
                refetchProject={refetchProject}
              />
            );
          }}
          renderHeader={(checked, onClick) => (
            <>
              <div className="report-table__cell runqueue-table__cell--select runqueue-col-select">
                <MultiStateCheckbox checked={checked} onClick={onClick} />
              </div>
              <div
                className="report-table__cell report-table__table-title runqueue-col-name"
                onClick={() => toggleSort('name')}>
                Queue Name
                {sortBy === 'name' ? renderSortIcon() : null}
              </div>
              <div
                className="report-table__cell runqueue-col-runs"
                onClick={() => toggleSort('count')}>
                Runs queued
                {sortBy === 'count' ? renderSortIcon() : null}
              </div>
              <div className="report-table__cell runqueue-col-runs">
                Runs Completed
              </div>
            </>
          )}
          renderAddButton={() =>
            canCreateRunQueue ? (
              <Button
                primary
                size="tiny"
                className="create-new-queue"
                onClick={() => setMutatingRunQueues(true)}
                content="Create Queue"
              />
            ) : (
              <></>
            )
          }
          renderDeleteButton={() => (
            <Button
              onClick={() => {
                setDeleteConfirmModalOpen(true);
              }}>
              Delete
            </Button>
          )}
          renderDialogs={filteredSelectedRunQueues => {
            return (
              <DeleteRunQueueModal
                open={deleteConfirmModalOpen}
                runQueues={filteredSelectedRunQueues}
                onClose={() => setDeleteConfirmModalOpen(false)}
                onDelete={async () => {
                  setMutatingRunQueues(true);
                  await refetchProject();
                  setDeleteConfirmModalOpen(false);
                  setMutatingRunQueues(false);
                }}
              />
            );
          }}
          renderEmpty={runQueues.length === 0 ? () => <div></div> : undefined}
        />
      </>
    );
  },
  {id: 'RunQueueTabContents'}
);

interface RunQueuesTableRowProps {
  runQueue: RunQueue;
  selected: boolean;
  projectName: string;
  entityName: string;
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
  setSelected(id: string, selected: boolean): void;
}

const RunQueuesTableRow: React.FC<RunQueuesTableRowProps> = makeComp(
  props => {
    const {
      runQueue,
      projectName,
      entityName,
      selected,
      refetchProject,
      setSelected,
    } = props;
    const [expanded, setExpanded] = useState(false);
    const onSelectChanged = useCallback(
      () => setSelected(runQueue.id, !selected),
      [runQueue.id, selected, setSelected]
    );
    const runQueueRunIds = runQueue.runQueueItems.edges.map(e => {
      return e.node.associatedRunId ?? null;
    });
    const filteredRunIds: string[] =
      runQueueRunIds.filter(isNotNullOrUndefined);
    const filter = runNamesFilter(filteredRunIds);
    const runStatesQuery = useRunsStateQuery({
      variables: {
        projectName,
        entityName,
        filters: JSON.stringify(toMongo(filter)),
      },
    });

    const runStates = useMemo(
      () =>
        runStatesQuery.data?.project?.runs?.edges.map(edge => {
          return {id: edge.node.name, state: edge.node.state};
        }),
      [runStatesQuery]
    );
    const {pendingAndRunningItems, completedItems} = useMemo(() => {
      const pendingAndRunningItemsArr: RunQueueItemWithState[] = [];
      const completedItemsArr: RunQueueItemWithState[] = [];
      runQueue.runQueueItems.edges.forEach(e => {
        if (e.node.state === 'PENDING' || e.node.state === 'LEASED') {
          pendingAndRunningItemsArr.push({
            ...e.node,
            runState: e.node.state === 'LEASED' ? 'starting' : 'queued',
          });
        } else if (e.node.state === 'CLAIMED') {
          const runIDAndState = runStates?.find(
            rs => rs.id === e.node.associatedRunId
          );
          if (
            runIDAndState?.state === 'finished' ||
            runIDAndState?.state === 'failed' ||
            runIDAndState?.state === 'crashed'
          ) {
            completedItemsArr.push({
              ...e.node,
              runState: runIDAndState.state,
            });
          } else {
            pendingAndRunningItemsArr.push({
              ...e.node,
              runState: runIDAndState?.state ?? 'unknown',
            });
          }
        }
      });
      return {
        pendingAndRunningItems: pendingAndRunningItemsArr,
        completedItems: completedItemsArr,
      };
    }, [runQueue, runStates]);

    if (runStatesQuery.loading) {
      return <></>;
    }

    return (
      <>
        <S.SuperTableRow onClick={() => setExpanded(!expanded)}>
          <div className="report-table__cell report-table__cell--select queue-col-select">
            <MultiStateCheckbox
              checked={selected ? 'checked' : 'unchecked'}
              onClick={onSelectChanged}
            />
          </div>
          <div className="report-table__cell report-table__cell--title sweep-col-name">
            <S.QueueNameSpan expanded={expanded}>
              <LegacyWBIcon
                className={expanded ? 'open' : undefined}
                name="next"
              />
              {runQueue.name}
            </S.QueueNameSpan>
          </div>
          <div className="report-table__cell sweep-col-state">
            {pendingAndRunningItems.length}
          </div>
          <div className="report-table__cell sweep-col-state">
            {completedItems.length}
          </div>
        </S.SuperTableRow>

        {expanded && runQueue.runQueueItems.edges.length > 0 && (
          <S.SubTableDiv>
            <RunQueuesItemsTable
              runQueue={runQueue}
              pendingAndRunningItems={pendingAndRunningItems}
              completedItems={completedItems}
              refetchProject={refetchProject}
              projectName={projectName}
              entityName={entityName}
            />
          </S.SubTableDiv>
        )}
      </>
    );
  },
  {id: 'RunQueueTableRow'}
);

interface RunQueuesItemsTableProps {
  runQueue: RunQueue;
  pendingAndRunningItems: RunQueueItemWithState[];
  completedItems: RunQueueItemWithState[];
  projectName: string;
  entityName: string;
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
}

const RunQueuesItemsTable: React.FC<RunQueuesItemsTableProps> = makeComp(
  props => {
    const {
      runQueue,
      pendingAndRunningItems,
      completedItems,
      projectName,
      entityName,
      refetchProject,
    } = props;
    let ind = 0;
    const allRunQueueItems = pendingAndRunningItems.concat(completedItems);
    const runQueueItemRows = allRunQueueItems.map(item => {
      if (item.state === 'PENDING' || item.state === 'LEASED') {
        ind += 1;
      }
      return (
        <RunQueueItemTableRow
          key={item.id}
          runQueueID={runQueue.id}
          runQueueItem={item}
          runIndex={ind}
          entityName={entityName}
          projectName={projectName}
          refetchProject={refetchProject}
        />
      );
    });

    return (
      <S.Table basic>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.HeaderCell>Run ID</S.Table.HeaderCell>
            <S.Table.HeaderCell>Status</S.Table.HeaderCell>
            <S.Table.HeaderCell>Resource</S.Table.HeaderCell>
            <S.Table.HeaderCell></S.Table.HeaderCell>
          </S.Table.Row>
        </S.Table.Header>
        <S.Table.Body>{runQueueItemRows}</S.Table.Body>
      </S.Table>
    );
  },
  {id: 'RunQueuesItemsTable'}
);

interface RunQueueItemWithState extends RunQueueItem {
  runState: string;
}

interface RunQueueItemTableRowProps {
  runQueueID: string;
  runQueueItem: RunQueueItemWithState;
  runIndex: number;
  entityName: string;
  projectName: string;
  refetchProject(): void;
}

const RunQueueItemTableRow: React.FC<RunQueueItemTableRowProps> = makeComp(
  props => {
    const {
      runQueueID,
      runQueueItem,
      runIndex,
      entityName,
      projectName,
      refetchProject,
    } = props;
    const [deleteRunQueueItem] = useDeleteFromRunQueueMutation();
    const history = useHistory();
    const options = useMemo(() => {
      return [
        {
          value: 'delete',
          name: 'Delete',
          icon: 'delete',
        },
      ];
    }, []);
    type optionType = 'delete';
    const optionRouter = async (opt: optionType) => {
      switch (opt) {
        case 'delete':
          const {data} = await deleteRunQueueItem({
            variables: {
              queueID: runQueueID,
              runQueueItemId: runQueueItem.id,
            },
          });
          if (!data?.deleteFromRunQueue?.success) {
            alert('failed to delete runqueue');
          } else {
            refetchProject();
          }
      }
    };

    return (
      <S.Table.Row key={runQueueItem.id}>
        {runQueueItem.associatedRunId != null ? (
          <S.ClickableCell
            onClick={() => {
              window.analytics.track('go to run from run queue table', {
                entityName,
                projectName,
                runId: runQueueItem.associatedRunId as string,
              });
              history.push(
                run({
                  entityName,
                  projectName,
                  name: runQueueItem.associatedRunId as string,
                })
              );
            }}>
            {runQueueItem.associatedRunId}
          </S.ClickableCell>
        ) : (
          <S.Table.Cell>{`Run ${runIndex}`}</S.Table.Cell>
        )}

        <S.Table.Cell>{runQueueItem.runState}</S.Table.Cell>
        <S.Table.Cell>{runQueueItem.runSpec.resource}</S.Table.Cell>
        <S.Table.Cell>
          <WBPopupMenuTrigger
            options={options}
            onSelect={value => {
              optionRouter(value as optionType);
            }}>
            {({anchorRef, setOpen}) => (
              <WBIcon
                name="overflow"
                title="menu"
                ref={anchorRef}
                onClick={() => setOpen(true)}
              />
            )}
          </WBPopupMenuTrigger>
        </S.Table.Cell>
      </S.Table.Row>
    );
  },
  {id: 'RunQueueItemTableRow'}
);

interface AddRunQueueModalProps {
  open: boolean;
  entityName: string;
  projectName: string;
  viewingEntity?: string;
  isTeam: boolean;
  runQueues: RunQueue[];
  refetchProject(): Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
  onClose(): void;
}

const AddRunQueueModal: React.FC<AddRunQueueModalProps> = makeComp(
  props => {
    const [createRunQueue] = useCreateRunQueueMutation();
    const [queueName, setQueueName] = useState<string | undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState(false);

    const access =
      props.isTeam && !isPrivate
        ? RunQueueAccessType.Project
        : RunQueueAccessType.User;

    const {disabled, message} = useMemo(() => {
      if (queueName === DEFAULT_RUN_QUEUE_NAME && props.isTeam && isPrivate) {
        return {
          disabled: true,
          message: `"${DEFAULT_RUN_QUEUE_NAME}" runQueue name cannot be private`,
        };
      } else if (props.runQueues.some(rq => rq.name === queueName)) {
        return {
          disabled: true,
          message: 'a runQueue with that name already exists in this project',
        };
      } else if (queueName == null) {
        return {
          disabled: true,
          message: 'must select a queue name',
        };
      }
      return {disabled: false, message: null};
    }, [queueName, props.runQueues, isPrivate, props.isTeam]);

    const onCreateRunQueueClick = async () => {
      window.analytics.track('create new run queue', {
        entityName: props.entityName,
        projectName: props.projectName,
        queueName,
        access,
      });
      if (queueName == null) {
        return;
      }
      const {data} = await createRunQueue({
        variables: {
          projectName: props.projectName,
          entityName: props.entityName,
          queueName,
          access,
        },
      });
      if (
        data == null ||
        !data.createRunQueue?.success ||
        data?.createRunQueue?.queueID == null
      ) {
        alert('Run Queue creation failed');
      } else {
        props.refetchProject();
      }
      props.onClose();
    };

    return (
      <S.Modal open={props.open} onClose={props.onClose}>
        <Modal.Content>
          <S.ModalTitle>Create Queue</S.ModalTitle>
          <S.ModalInputContainer>
            <S.Input
              size="mini"
              placeholder="e.g GPU Queue"
              onChange={(e: React.SyntheticEvent, {value}: InputOnChangeData) =>
                setQueueName(value)
              }
              value={queueName}></S.Input>
          </S.ModalInputContainer>
          <S.ModalActions>
            {props.isTeam && (
              <S.ModalText>
                <div>Private to user:</div>
                <S.Radio
                  type="radio"
                  toggle
                  checked={isPrivate}
                  onChange={() => setIsPrivate(!isPrivate)}
                />
              </S.ModalText>
            )}
            <S.ButtonContainer>
              <Button
                tooltip={message}
                disabled={disabled}
                onClick={() => onCreateRunQueueClick()}>
                Create Queue
              </Button>
            </S.ButtonContainer>
          </S.ModalActions>
        </Modal.Content>
      </S.Modal>
    );
  },
  {id: 'AddRunQueueModal'}
);

interface DeleteRunQueueModalProps {
  open: boolean;
  runQueues: RunQueue[];
  onClose(): void;
  onDelete(): void;
}

const DeleteRunQueueModal: React.FC<DeleteRunQueueModalProps> = makeComp(
  ({open, runQueues, onClose, onDelete}) => {
    const filteredRunQueues = runQueues.filter(
      queue =>
        !(
          queue.name === 'default' &&
          queue.access === RunQueueAccessType.Project
        )
    );
    const [deleteRunQueues] = useDeleteRunQueuesMutation({
      variables: {queueIDs: filteredRunQueues.map(r => r.id)},
    });
    return (
      <Modal className="runQueue-delete-modal" open={open} onClose={onClose}>
        <Modal.Header>
          Delete Run Queue{runQueues.length > 1 ? 's' : ''}
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete{' '}
            {runQueues.length === 1
              ? `run queue ${runQueues[0].name}`
              : `${runQueues.length} run queues`}{' '}
            forever?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            color="red"
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              await deleteRunQueues();
              onDelete();
            }}>
            Delete Run Queue{runQueues.length > 1 ? 's' : ''}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'DeleteRunQueueModal'}
);
