// This page is a simple effect tied to a route so we can trigger the effect
// with routing logic, like redirects from the onboarding flow.
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ModifiedDropdown from '../components/elements/ModifiedDropdown';
import WandbLoader from '../components/WandbLoader';
import WBModal from '../components/WBModal';
import {
  useGalleryQuery,
  useInsertGalleryDiscussionMutation,
  useUserReportsQuery,
} from '../generated/graphql';
import {usePushGalleryPost} from '../state/reports/hooks';
import {useViewer} from '../state/viewer/hooks';
import {
  DEFAULT_LANGUAGE,
  ReportIDWithTagIDs,
  trackFC,
  useGallerySpec,
  useGalleryTags,
} from '../util/gallery';
import history from '../util/history';
import * as Report from '../util/report';
import {DropdownOption} from '../util/uihelpers';
import * as Urls from '../util/urls';

const NEW_POST_KEY = '__NEW_POST__';

const GalleryCreatePost: React.FC = () => {
  const {
    gallerySpec: {reportIDsWithTagIDs},
  } = useGallerySpec();
  const reportIDsAlreadyInGallery: Set<string> = useMemo(
    () => new Set(reportIDsWithTagIDs.map(r => r.id)),
    [reportIDsWithTagIDs]
  );

  const viewer = useViewer();

  const tags = useGalleryTags();
  const [insertGalleryDiscussion] = useInsertGalleryDiscussionMutation();
  const {refetch: refetchGallery} = useGalleryQuery();
  const userReportsQuery = useUserReportsQuery();

  const [selectedReportID, setSelectedReportID] = useState(NEW_POST_KEY);
  const onChangeReportID = useCallback((e, data) => {
    trackFC('selectExistingReportID', data.value);
    setSelectedReportID(data.value);
  }, []);

  const [reportOptions, viewByReportID]: [DropdownOption[], Map<string, any>] =
    useMemo(() => {
      if (userReportsQuery.data == null) {
        return [[], new Map()];
      }
      const userReports = userReportsQuery.data.viewer?.views?.edges ?? [];
      const viewByID: Map<string, any> = new Map();
      const opts: DropdownOption[] = [];
      for (const r of userReports) {
        if (
          r.node?.project == null ||
          reportIDsAlreadyInGallery.has(r.node.id)
        ) {
          continue;
        }
        const entityName = r.node.entity
          ? r.node.entity.name
          : userReportsQuery.data.viewer?.username ?? '';
        const projectName = r.node.project.name;
        const reportName = r.node.displayName ?? '';
        const reportID = r.node.id ?? '';
        viewByID.set(reportID, r.node);
        opts.push({
          key: reportID,
          value: reportID,
          text: [entityName, projectName, reportName].join(' / '),
        });
      }
      return [opts, viewByID];
    }, [userReportsQuery, reportIDsAlreadyInGallery]);
  const reportOptionsWithNewOption: DropdownOption[] = useMemo(
    () => [
      {key: NEW_POST_KEY, value: NEW_POST_KEY, text: 'Create a new post'},
      ...reportOptions,
    ],
    [reportOptions]
  );

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const submitExistingReport = useCallback(async () => {
    if (selectedReportID === NEW_POST_KEY) {
      throw new Error(`attempting to submit existing report with new post key`);
    }
    const view = viewByReportID.get(selectedReportID);
    if (view == null) {
      throw new Error(`unable to find view with id ${selectedReportID}`);
    }
    const tag = tags.find(t => t.id === 'ir6df9nxe');
    if (tag == null) {
      throw new Error(`unable to find posts tag`);
    }
    const entrySpec: ReportIDWithTagIDs = {
      id: view.id,
      tagIDs: [tag.id],
      authors: JSON.parse(view.spec).authors ?? [],
      addedAt: new Date().toJSON(),
      language: DEFAULT_LANGUAGE,
      pending: true,
    };
    await insertGalleryDiscussion({
      variables: {
        spec: JSON.stringify(entrySpec),
        reportID: view.id,
        sendEmail: true,
      },
    });
    await refetchGallery();
    setSubmitted(true);
  }, [
    selectedReportID,
    viewByReportID,
    tags,
    insertGalleryDiscussion,
    refetchGallery,
  ]);

  const pushDraftReport = usePushGalleryPost(
    viewer?.username ?? '',
    'Blog Post Title'
  );
  const createNewPost = useCallback(() => {
    const report = Report.getEmptyReportConfig();
    pushDraftReport(report);
  }, [pushDraftReport]);

  useEffect(() => {
    if (!userReportsQuery.loading && reportOptions.length === 0) {
      createNewPost();
    }
  }, [userReportsQuery, reportOptions, createNewPost]);

  const modalContent = useMemo(
    () =>
      !submitted ? (
        <>
          <p>
            Create a new blog post <u>or</u> pick an existing report to submit
            to Fully Connected. We’ll review your submission and contact you
            with any suggestions before publishing.
          </p>
          <ModifiedDropdown
            selection
            search
            style={{width: '100%'}}
            placeholder="Select a report"
            value={selectedReportID}
            options={reportOptionsWithNewOption}
            onChange={onChangeReportID}
          />
        </>
      ) : (
        <p>
          Thank you for your submission! We’ll review your submission and
          contact you with any suggestions before publishing.
        </p>
      ),
    [submitted, onChangeReportID, reportOptionsWithNewOption, selectedReportID]
  );

  const modalAction = useMemo(
    () =>
      !submitted
        ? {
            content:
              selectedReportID === NEW_POST_KEY ? `Create new post` : `Submit`,
            loading: submitting,
            disabled: submitting,
            onClick: async () => {
              setSubmitting(true);
              trackFC('clickSubmitExistingReport', selectedReportID);
              if (selectedReportID === NEW_POST_KEY) {
                createNewPost();
              } else {
                await submitExistingReport();
              }
            },
          }
        : {
            content: 'Go back to Fully Connected',
            onClick: () => history.push(Urls.reportGallery({tag: 'posts'})),
          },
    [
      submitting,
      submitted,
      createNewPost,
      selectedReportID,
      submitExistingReport,
    ]
  );

  return (
    <>
      <WandbLoader />
      {reportOptions.length > 0 && (
        <WBModal open header="Submit a report" primaryAction={modalAction}>
          {modalContent}
        </WBModal>
      )}
    </>
  );
};

export default GalleryCreatePost;
