// This is used by the requests.spec.js integration test.

import gql from 'graphql-tag';
import * as React from 'react';
import {useQuery} from '../state/graphql/query';

import '../css/HomePage.less';
import makeComp from '../util/profiler';

export const SimpleTestQueryPage = makeComp(
  () => {
    const result = useQuery<{serverInfo: {pollingOK: boolean}}, {}>(
      SIMPLE_TEST_QUERY,
      {}
    );
    if (result.loading === false && result.serverInfo) {
      return <p>SUCCESS</p>;
    }
    return <p>FAILURE</p>;
  },
  {id: 'SimpleTestQueryPage'}
);

export const SIMPLE_TEST_QUERY = gql`
  query SimpleTestQuery {
    serverInfo {
      pollingOK
    }
  }
`;

export default SimpleTestQueryPage;
