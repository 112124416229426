import gql from 'graphql-tag';
import * as Prism from 'prismjs';
import React, {FC, useEffect, useCallback} from 'react';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';

import {History} from 'history';
import ProjectEditor from '../components/ProjectEditor';
import '../css/NewProject.less';
import {UpdateProjectMutationFn} from '../graphql/models';
import {Project} from '../types/graphql';
import {setDocumentTitle} from '../util/document';
import makeComp from '../util/profiler';
import {urlPrefixed} from '../config';

export const BENCHMARK_UPSERT = gql`
  mutation upsertBenchmark(
    $description: String
    $entityName: String!
    $id: String
    $name: String!
    $access: String
    $views: JSONString
  ) {
    upsertModel(
      input: {
        description: $description
        entityName: $entityName
        id: $id
        name: $name
        access: $access
        views: $views
        isBenchmark: true
      }
    ) {
      project {
        id
        name
        entityName
        description
        access
        views
        isBenchmark
      }
      inserted
    }
  }
`;

interface NewBenchmarkProps {
  submit: UpdateProjectMutationFn;
  history: History;
}

export const NewBenchmark: FC<NewBenchmarkProps> = makeComp(
  ({submit, history}) => {
    useEffect(() => {
      setDocumentTitle('Create a new benchmark');
    }, []);

    useEffect(() => {
      Prism.highlightAll();
    });

    const addProject = useCallback((project: Project) => {
      // hacky? triggering whole page refresh to update projects
      window.location.assign(
        urlPrefixed(`/${project.entityName}/${project.name}`)
      );
    }, []);

    return (
      <div style={{marginBottom: 32}} className="model new-project-page">
        <ProjectEditor
          submit={submit}
          history={history}
          addProject={addProject}
          benchmark
        />
      </div>
    );
  },
  {id: 'NewBenchmark', memo: true}
);

const withMutations = graphql(BENCHMARK_UPSERT, {
  props: ({mutate}) => ({
    submit: (variables: any) =>
      mutate!({
        variables,
      }),
  }),
}) as any;

export default withMutations(connect()(NewBenchmark));
