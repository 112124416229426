import React, {useCallback, useEffect, useRef, useState} from 'react';
import makeComp from '../../util/profiler';
import WandbLoader from '../WandbLoader';
import * as Panel2 from './panel';
import {Icon, Placeholder, PlaceholderImage} from 'semantic-ui-react';
import {useAssetURLFromArtifact} from './useAssetFromArtifact';
import {
  RepresentationType,
  RepresentationTypeValues,
} from '../../types/libs/nglviewer';
import ModifiedDropdown from '../elements/ModifiedDropdown';
import * as S from './PanelObject3D.styles';
import {onNextExitFullscreen} from '../../util/fullscreen';

const inputType = {type: 'molecule-file' as const};

type MoleculeConfig = {
  representationType?: RepresentationType;
};
type PanelMoleculeProps = Panel2.PanelProps<typeof inputType, MoleculeConfig>;

const PanelMolecule: React.FC<PanelMoleculeProps> = makeComp(
  props => {
    const inputNode = props.input.path;
    const assetResult = useAssetURLFromArtifact(inputNode);
    const isMolecule =
      !assetResult.loading && assetResult.asset.path.endsWith('.pdb');

    return (
      <div>
        {assetResult.loading ? (
          <WandbLoader />
        ) : isMolecule ? (
          <Molecule
            {...props}
            width={232}
            height={232}
            directUrl={assetResult.directUrl as string}
            representationType={props.config?.representationType ?? 'default'}
            extension="pdb"
          />
        ) : (
          <p>
            Tried to render{' '}
            {assetResult.asset.path ? (
              <code>{assetResult.asset.path}</code>
            ) : (
              'this object'
            )}
            , but only .pdb files are currently supported.
          </p>
        )}
      </div>
    );
  },
  {
    id: 'PanelMolecule',
  }
);

interface MediaMoleculeProps {
  width: number;
  height: number;
  directUrl: string;
  representationType: RepresentationType;
  extension: string;
}

const Molecule: React.FC<MediaMoleculeProps> = makeComp(
  props => {
    const {width, height, directUrl, representationType, extension} = props;
    type NGLLib = typeof import('../../util/nglviewerRender');
    const [nglLib, setNglLib] = useState<NGLLib>();
    useEffect(() => {
      import('../../util/nglviewerRender').then(setNglLib);
    }, []);

    const moleculeRef = useRef<HTMLDivElement>(null);
    const [dataFile, setDataFile] = useState<File>();
    const [screenshotURL, setScreenshotURL] = useState<string>();
    const [isInteractive, setIsInteractive] = useState<boolean>(false);
    const [stage, setStage] = useState<any>(null);
    const [mouseOver, setMouseOver] = useState<boolean>(false);

    const disableInteractivity = useCallback(() => {
      if (stage != null) {
        try {
          stage.dispose();
        } catch (e) {
          // pass
        }
      }
      if (moleculeRef.current != null) {
        moleculeRef.current.innerHTML = '';
      }
      setStage(null);
      setIsInteractive(false);
    }, [stage, setStage, setIsInteractive, moleculeRef]);

    useEffect(disableInteractivity, [representationType]);
    useEffect(() => {
      if (nglLib != null) {
        const fetchedUrl = directUrl;
        setScreenshotURL(undefined);
        fetch(fetchedUrl)
          .then(resp => resp.blob())
          .then(data => {
            if (fetchedUrl === directUrl) {
              const file = new File([data], 'molecule.' + extension);
              setDataFile(file);
              nglLib
                .moleculeScreenshot(
                  file,
                  {width, height},
                  {representation: representationType},
                  extension
                )
                .then(imageBlob => {
                  if (fetchedUrl === directUrl) {
                    const url = window.URL.createObjectURL(imageBlob);
                    setScreenshotURL(url);
                  }
                });
            }
          });
      }
    }, [directUrl, nglLib, width, height, representationType, extension]);

    const onMouseEnter = useCallback(() => {
      setMouseOver(true);
      if (
        !isInteractive &&
        nglLib != null &&
        moleculeRef.current != null &&
        dataFile != null &&
        screenshotURL != null
      ) {
        const newStage = nglLib.moleculeStage(
          moleculeRef.current,
          dataFile,
          {width, height},
          {representation: representationType},
          extension
        );
        setIsInteractive(true);
        setStage(newStage);
        moleculeRef.current
          .getElementsByTagName('canvas')[0]
          .addEventListener('webglcontextlost', disableInteractivity);
      }
    }, [
      dataFile,
      moleculeRef,
      nglLib,
      width,
      height,
      representationType,
      extension,
      setStage,
      isInteractive,
      setIsInteractive,
      disableInteractivity,
      setMouseOver,
      screenshotURL,
    ]);

    const onMouseLeave = useCallback(() => {
      setMouseOver(false);
    }, [setMouseOver]);

    const onFullscreen = useCallback(() => {
      if (nglLib != null && moleculeRef.current != null && dataFile != null) {
        disableInteractivity();
        const screenW = window.screen.width;
        const screenH = window.screen.height;
        const newStage = nglLib.moleculeStage(
          moleculeRef.current,
          dataFile,
          {width: screenW, height: screenH},
          {representation: representationType},
          extension
        );
        onNextExitFullscreen(() => {
          if (newStage != null) {
            newStage.dispose();
          }
          disableInteractivity();
        });

        try {
          moleculeRef.current.requestFullscreen();
        } catch (e) {
          throw new Error('Fullscreen request invalid: ' + e);
        }
      }
    }, [
      dataFile,
      moleculeRef,
      nglLib,
      representationType,
      extension,
      disableInteractivity,
    ]);

    return (
      <S.FlexContainer>
        <div
          className="media-card"
          style={{
            height: '232px',
            width: '232px',
            backgroundImage: `url(${screenshotURL})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          {mouseOver && isInteractive && (
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                padding: '10px',
                color: '#EEE',
                zIndex: 1,
              }}
              onClick={onFullscreen}>
              <Icon size="large" link name="expand arrows alternate" />
            </div>
          )}
          <div className="object3D-card-babylon" ref={moleculeRef} />
          {!isInteractive && screenshotURL == null && (
            <Placeholder
              style={{
                width: '232px',
                height: '232px',
              }}>
              <PlaceholderImage square />
            </Placeholder>
          )}
        </div>
      </S.FlexContainer>
    );
  },
  {id: 'Panel2Molecule'}
);

const PanelMoleculeConfig: React.FC<PanelMoleculeProps> = props => {
  const {config, updateConfig} = props;
  const updateRepresentationType = useCallback(
    (newRepresentationType: RepresentationType) => {
      updateConfig({
        ...config,
        representationType: newRepresentationType,
      });
    },
    [updateConfig, config]
  );

  return (
    <div>
      <ModifiedDropdown
        selection
        options={RepresentationTypeValues.map(item => {
          return {
            text: '' + item,
            value: '' + item,
          };
        })}
        value={config?.representationType ?? 'default'}
        onChange={(e, {value}) =>
          updateRepresentationType(value as RepresentationType)
        }
      />
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'molecule-file',
  Component: PanelMolecule,
  ConfigComponent: PanelMoleculeConfig,
  inputType,
  displayName: '3D Object',
  defaultFixedSize: {
    width: 242,
    height: 242,
  },
};
