import * as _ from 'lodash';
import {Spec as VegaSpec} from 'vega';
import {toRunsDataQuery} from '../containers/RunsDataLoader';
import {RunHistoryKeyInfo, RunKeyInfo, RunKeyInfoInfo} from '../types/run';
import {flatten} from '../util/flatten';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Query from '../util/queryts';
import * as Run from '../util/runs';
import * as String from '@wandb/cg/browser/utils/string';

export interface VegaPanelDef {
  id?: string;
  name: string;
  description: string;
  spec: string;
}

// A reference to a generic input config.
export interface FieldRef {
  type: 'field';
  name: string;
}

// A reference to a wandb run field.
export interface RunFieldRef {
  type: 'run-field';
  name: string;
}

export interface RunFieldListRef {
  type: 'run-field-list';
  name: string;
}

// A reference to a wandb run history field
export interface HistoryFieldRef {
  type: 'history-field';
  name: string;
}

export interface HistoryTableRef {
  type: 'history-table';
  tableName: string;
  keys: string[];
}

export interface RunFieldTableRef {
  type: 'run-field-table';
  tableName: string;
}

export type Ref =
  | FieldRef
  | RunFieldRef
  | RunFieldListRef
  | HistoryFieldRef
  | HistoryTableRef
  | RunFieldTableRef;

type FullRef = Ref & {raw: string};

interface FieldSettings {
  [key: string]: string;
}

export interface UserSettings {
  fieldSettings: FieldSettings;
  runFieldSettings: FieldSettings;
  runFieldListSettings: FieldSettings;
  historyFieldSettings: FieldSettings;
}

function parseFieldRef(s: string): FieldRef | null {
  if (s.length === 0) {
    return null;
  }
  return {type: 'field', name: s};
}

function parseRunFieldRef(s: string): RunFieldRef | null {
  if (s.length === 0) {
    return null;
  }
  return {type: 'run-field', name: s};
}

function parseRunFieldListRef(s: string): RunFieldListRef | null {
  if (s.length === 0) {
    return null;
  }
  return {type: 'run-field-list', name: s};
}

function parseHistoryFieldRef(s: string): HistoryFieldRef | null {
  if (s.length === 0) {
    return null;
  }
  return {
    type: 'history-field',
    name: s,
  };
}

function parseHistoryTableRef(s: string): HistoryTableRef | null {
  if (s.length === 0) {
    return null;
  }
  const [tableName, rest] = String.splitOnce(s, ':');
  if (rest == null || rest.length === 0) {
    return null;
  }
  return {
    type: 'history-table',
    tableName,
    keys: rest.split(','),
  };
}

function parseRunFieldTableRef(s: string): RunFieldTableRef | null {
  if (s.length === 0) {
    return null;
  }
  return {
    type: 'run-field-table',
    tableName: s,
  };
}

function toRef(s: string): Ref | null {
  const [refName, rest] = String.splitOnce(s, ':');
  if (rest == null) {
    return null;
  }
  switch (refName) {
    case 'field':
      return parseFieldRef(rest);
    case 'run-field':
      return parseRunFieldRef(rest);
    case 'run-field-list':
      return parseRunFieldListRef(rest);
    case 'history-field':
      return parseHistoryFieldRef(rest);
    case 'history-table':
      return parseHistoryTableRef(rest);
    case 'run-field-table':
      return parseRunFieldTableRef(rest);
    default:
      return null;
  }
}

export function extractRefs(s: string): FullRef[] {
  const match = s.match(new RegExp(`\\$\\{.*?\\}`, 'g'));
  if (match == null) {
    return [];
  }
  return match
    .map(m => {
      const ref = toRef(m.slice(2, m.length - 1));
      return ref == null ? null : {...ref, raw: m};
    })
    .filter(Obj.notEmpty);
}

export function hasDataset(spec: VegaSpec, name: string): boolean {
  if (spec.data == null) {
    return false;
  }
  return _.find(spec.data, dataset => dataset.name === name) != null;
}

export function isBaseDatasetName(name: string) {
  return name === 'runs' || name === 'history';
}

export function getRefDatasetNames(spec: VegaSpec): string[] {
  if (spec.data == null) {
    return [];
  }
  const dataVals = _.isArray(spec.data) ? spec.data : [spec.data];
  return dataVals
    .filter(
      d =>
        d.name != null &&
        !isBaseDatasetName(d.name) &&
        d.transform == null &&
        (d as any).url == null
    )
    .map(d => d.name);
}

function runKeyStringToVegaField(k: string) {
  k = _.replace(k, '.value', '');
  k = _.replace(k, '.', '\\.');
  k = _.replace(k, ':', '.');
  return k;
}

export function parseSpec(spec: VegaSpec): FullRef[] | null {
  const refs = _.uniqWith(
    _.flatMap(
      _.filter(flatten(spec), v => typeof v === 'string'),
      v => extractRefs(v)
    ),
    _.isEqual
  );

  // validate
  let valid = true;

  // history tables must only be defined once
  const historyTableRefs = refs.filter(
    r => r.type === 'history-table'
  ) as HistoryTableRef[];
  for (const htr of historyTableRefs) {
    if (
      historyTableRefs.filter(checkHTR => htr.tableName === checkHTR.tableName)
        .length > 1
    ) {
      console.warn(
        'Found different definitions for history-table:',
        htr.tableName
      );
      valid = false;
    }
  }

  // history fields must refer to a valid key in a history table
  const historyFieldRefs = refs.filter(
    r => r.type === 'history-field'
  ) as HistoryFieldRef[];
  for (const hfr of historyFieldRefs) {
    if (
      !_.includes(
        _.flatMap(historyTableRefs, htr => htr.keys),
        hfr.name
      )
    ) {
      console.warn('History field refers to invalid table key:', hfr.name);
      valid = false;
    }
  }

  if (!valid) {
    return null;
  }
  return refs;
}

export function fieldInjectResult(
  ref: FullRef,
  userSettings: UserSettings
): string | null {
  let result = '';
  switch (ref.type) {
    case 'field':
      return userSettings.fieldSettings[ref.name] || '';
    case 'run-field':
      result = userSettings.runFieldSettings[ref.name] || '';
      return runKeyStringToVegaField(result);
    case 'history-field':
      result = userSettings.historyFieldSettings[ref.name] || '';
      result = _.replace(result, '.', '\\.');
      return result;
    case 'history-table':
      return ref.tableName;
    case 'run-field-table':
      return ref.tableName;
  }
  return null;
}

export function fieldListInjectResult(
  ref: FullRef,
  userSettings: UserSettings,
  keyInfo: RunKeyInfo
): string[] | null {
  switch (ref.type) {
    case 'run-field-list':
      const regexString = userSettings.runFieldListSettings[ref.name] || '';
      let regex: RegExp;
      try {
        regex = new RegExp(regexString);
      } catch {
        return null;
      }
      const keys = _.keys(keyInfo).filter(s => s.match(regex) != null);
      return keys.map(runKeyStringToVegaField);
  }
  return null;
}

export function makeInjectMap(
  refs: FullRef[],
  userSettings: UserSettings
): Array<{from: string; to: string}> {
  const result: Array<{from: string; to: string}> = [];
  for (const ref of refs) {
    const inject = fieldInjectResult(ref, userSettings);
    if (inject != null) {
      result.push({
        from: ref.raw,
        to: inject,
      });
    }
  }
  return result;
}

export function makeListInjectMap(
  refs: FullRef[],
  userSettings: UserSettings,
  keyInfo: RunKeyInfo
): Array<{from: string; to: string[]}> {
  const result: Array<{from: string; to: string[]}> = [];
  for (const ref of refs) {
    const inject = fieldListInjectResult(ref, userSettings, keyInfo);
    if (inject != null) {
      result.push({
        from: ref.raw,
        to: inject,
      });
    }
  }
  return result;
}

export function injectFields(
  spec: VegaSpec,
  refs: FullRef[],
  userSettings: UserSettings,
  keyInfo: RunKeyInfo
): VegaSpec | null {
  const injectMap = makeInjectMap(refs, userSettings);
  const listInjectMap = makeListInjectMap(refs, userSettings, keyInfo);
  return Obj.deepMapValuesAndArrays(spec, (s: any) => {
    if (_.isArray(s) && s.length > 0 && typeof s[0] === 'string') {
      for (const mapping of listInjectMap) {
        // require exact match
        if (s[0] === mapping.from) {
          return mapping.to;
        }
      }
    } else if (typeof s === 'string') {
      for (const mapping of injectMap) {
        s = s.replace(mapping.from, mapping.to);
      }
    }
    return s;
  });
}

export function refsToInputs(refs: Ref[]) {
  const fieldInputs = (refs.filter(r => r.type === 'field') as FieldRef[]).map(
    r => r.name
  );
  const runFieldInputs = refs
    .map(r => {
      if (r.type === 'run-field') {
        return r.name;
      } else if (r.type === 'run-field-table') {
        return r.tableName;
      }
      return null;
    })
    .filter(Obj.notEmpty);
  const runFieldListInputs = (
    refs.filter(r => r.type === 'run-field-list') as RunFieldListRef[]
  ).map(r => r.name);
  const historyFieldInputs = _.uniq(
    _.flatMap(
      refs.filter(r => r.type === 'history-table') as HistoryTableRef[],
      r => r.keys
    )
  );

  return {
    fieldInputs,
    runFieldInputs,
    runFieldListInputs,
    historyFieldInputs,
  };
}

export function parseSpecJSON(specString: string): {[key: string]: any} {
  let spec: any;
  try {
    spec = JSON.parse(specString);
  } catch {
    // TODO: Do this earlier so we can show nice warning.
    // TODO: parse it with a schema aware parser so we can show good errors
    console.log('INVALID JSON');
    return {};
  }
  if (!_.isObject(spec)) {
    return {};
  }
  return spec;
}

const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];

export function runFieldOptions(runKeyInfo: RunKeyInfo) {
  let keys = _.chain(runKeyInfo)
    .map((ki, k) => [k, ki])
    .map(([k, ki]: [string, RunKeyInfoInfo]) => k)
    // Typescript hacking
    .value() as unknown as string[];

  keys = _.concat(TIME_KEYS, keys);

  return keys.map(name => ({
    text: Run.keyStringDisplayName(name),
    key: name,
    value: name,
  }));
}

export function historyFieldOptions(historyKeyInfo: RunHistoryKeyInfo) {
  // TODO: make more selective based on current keysets
  return _.keys(historyKeyInfo.keys).map(k => ({
    key: k,
    text: k,
    value: k,
  }));
}

export function historyKeySets(
  refs: Ref[],
  historyFieldSettings: UserSettings['historyFieldSettings']
) {
  return (refs.filter(r => r.type === 'history-table') as HistoryTableRef[])
    .map(ref => {
      const keys = ref.keys.map(k => historyFieldSettings[k]);
      if (keys.some(k => k == null)) {
        return null;
      }
      // Always include _step, so we can do delta updates in
      // VegaViz
      if (!_.includes(keys, '_step')) {
        keys.push('_step');
      }
      return {tableName: ref.tableName, keys};
    })
    .filter(Obj.notEmpty);
}

export function runsDataQuery(
  pageQuery: Query.Query,
  userConfig: UserSettings,
  specString?: string
) {
  const result = toRunsDataQuery(
    pageQuery,
    {
      selectionsAsFilters: true,
    },
    {fullConfig: true, fullSummary: true}
  );
  result.page = {size: 200};
  if (specString == null) {
    return result;
  }
  const spec = parseSpecJSON(specString);
  if (spec == null) {
    return result;
  }
  const refs = parseSpec(spec);
  if (refs == null) {
    return result;
  }
  const keySets = historyKeySets(refs, userConfig.historyFieldSettings).map(
    r => r.keys
  );
  result.historySpecs = keySets.map(keys => ({keys, samples: 500}));
  result.history = result.historySpecs.length > 0;
  if (result.historySpecs.length > 0) {
    result.page = {size: 10};
  }
  return result;
}
