import React, {useState} from 'react';
import {Divider, Form, Icon} from 'semantic-ui-react';
import {useViewer} from '../../state/viewer/hooks';
import {viewerUsingAdminPrivileges} from '../../util/admin';
import {View} from '../../state/graphql/vegaPanelQuery';
import makeComp from '../../util/profiler';

interface VegaPanelSaveAsProps {
  entityName: string;
  projectName: string;
  spec: string;
  vegaViews: View[];
  onSave(name: string): void;
  onSaveShared(entityName: string, name: string): void;
}

const VegaPanelSaveAs = makeComp(
  (props: VegaPanelSaveAsProps) => {
    const [saveName, setSaveName] = useState('');

    const [sharedEntityName, setSharedEntityName] = useState(props.entityName);
    const [sharedName, setSharedName] = useState('');

    const viewer = useViewer();
    const willOverwrite =
      props.vegaViews.filter(v => v.name === saveName).length > 0;
    return (
      <Form style={{marginBottom: 0}}>
        <Form.Group style={{marginBottom: 0}}>
          <Form.Input
            placeholder="Vega visualization name"
            autoFocus
            style={{width: 240}}
            value={saveName}
            onChange={(e, {value}) => setSaveName(value)}
          />
          <Form.Button primary onClick={() => props.onSave(saveName)}>
            {willOverwrite ? 'Overwrite' : 'Create'}
          </Form.Button>
        </Form.Group>
        {willOverwrite && (
          <p style={{marginBottom: 0}} className="hint-text">
            <Icon color="orange" name="warning circle" />A panel exists with
            this name. It will be overwritten.
          </p>
        )}
        {viewer &&
          viewer.admin &&
          (viewerUsingAdminPrivileges() ? (
            <>
              <Divider horizontal>
                <Icon color="orange" name="warning circle" />
                Shared Views (admin only)
              </Divider>
              <p className="hint-text">
                Save as a shared view to log with{' '}
                <pre style={{display: 'inline-block'}}>
                  wandb.visualize('{sharedEntityName}/{sharedName}', ...)
                </pre>
                <br />
                Be careful when overwriting plots that users are using. This
                will replace all existing visualizations using this key.
              </p>
              <Form.Group style={{marginBottom: 0}}>
                <Form.Input
                  placeholder="Entity name"
                  style={{width: 180}}
                  value={sharedEntityName}
                  onChange={(e, {value}) => setSharedEntityName(value)}
                />
                <Form.Input
                  placeholder="Shared vega visualization name"
                  style={{width: 240}}
                  value={sharedName}
                  onChange={(e, {value}) => setSharedName(value)}
                />
                <Form.Button
                  primary
                  onClick={() =>
                    props.onSaveShared(sharedEntityName, sharedName)
                  }
                  disabled={sharedEntityName === '' || sharedName === ''}>
                  Save Shared
                </Form.Button>
              </Form.Group>
            </>
          ) : (
            <p className="hint-text" style={{marginBottom: 0}}>
              <Icon color="orange" name="warning circle" />
              Enable admin powers to save shared views.
            </p>
          ))}
      </Form>
    );
  },
  {id: 'VegaPanelSaveAs'}
);

export default VegaPanelSaveAs;
