import React, {useEffect, useLayoutEffect, useState, ReactNode} from 'react';
import {Card, Placeholder} from 'semantic-ui-react';
import makeComp from '../../util/profiler';

interface VideoViewerProps {
  videoSrc: string;
  width: number;
  height: number;
  videoFilename?: string;
  headerElement?: ReactNode;
  footerElement?: ReactNode;
  failedLoadElement?: ReactNode;
  refreshTimestamp?: number;
  mediaFailedToLoad?: boolean;
  single?: boolean;
  setDomLoadFailed?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

// a single image card
const VideoViewer = makeComp(
  (props: VideoViewerProps) => {
    const {
      videoFilename,
      videoSrc,
      refreshTimestamp,
      headerElement,
      footerElement,
      failedLoadElement,
      mediaFailedToLoad,
      setDomLoadFailed,
    } = props;

    const videoRef = React.useRef<HTMLVideoElement>(null);

    const [videoWidth, setVideoWidth] = React.useState<number>();
    const [videoHeight, setVideoHeight] = React.useState<number>();
    const [isPlayButtonVisible, setPlayButtonVisibility] = useState(false);
    const [videoLoaded, setVideoLoaded] = React.useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const videoFile = videoFilename || videoSrc;
    const width = videoWidth || props.width;
    const height = videoHeight || props.height;

    // This section handles impartial data, streaming,
    // and errors in network requests

    // Create a refresh handler to refresh the dom node
    const videoRefresh = React.useCallback(() => {
      if (videoRef.current == null) {
        return;
      }
      const currentTime = videoRef.current.currentTime;
      videoRef.current.src = videoSrc;
      videoRef.current.currentTime = currentTime;
    }, [videoSrc]);

    useEffect(() => {
      videoRefresh();
    }, [videoRefresh, refreshTimestamp]);

    // the exhaustive-deps rule will complain here that it "can't verify" the dependencies,
    // but the intent is for this to run on _every_ render, so I needed to disable it
    useLayoutEffect(
      () => {
        if (containerRef.current) {
          // this won't trigger a re-render if the height hasn't actually changed
          setContainerHeight(
            containerRef.current.getBoundingClientRect().height
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      undefined
    );
    // Check the dom element and the loading spinner
    // In situations with no failures the dom will load first

    const onLoaded = (
      e: React.SyntheticEvent<HTMLVideoElement | HTMLImageElement, Event>
    ) => {
      const t = e.target;
      let w;
      let h;
      if ((t as HTMLVideoElement).videoWidth) {
        const v = t as HTMLVideoElement;
        w = v.videoWidth;
        h = v.videoHeight;
      } else {
        const i = t as HTMLImageElement;
        w = i.naturalWidth;
        h = i.naturalHeight;
      }
      setVideoWidth(w);
      setVideoHeight(h);
      setVideoLoaded(true);
    };

    let cardSize;
    if (!videoLoaded || mediaFailedToLoad) {
      cardSize = {maxWidth: 0, maxHeight: 0};
    }

    const cardStyles = {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'stretch',
      boxShadow: 'none',
    };

    const mediaStyles = {
      ...{cursor: 'pointer', overflow: 'hidden'},
      ...cardSize,
    };

    return (
      <Card className="video-card content-card" style={cardStyles}>
        {headerElement}
        <>
          {!videoLoaded && (
            <Placeholder style={{width, height}}>
              <Placeholder.Image />
            </Placeholder>
          )}

          {!mediaFailedToLoad ? (
            <>
              {videoFile.match(/.gif$/) ? (
                <img
                  src={videoSrc}
                  alt={videoFile}
                  style={mediaStyles}
                  onLoad={onLoaded}
                  onError={() => {
                    setDomLoadFailed?.(true);
                  }}
                />
              ) : (
                <div
                  className="video-container"
                  ref={containerRef}
                  style={mediaStyles}>
                  {containerHeight > 0 && (
                    <div
                      className={`video-card__play-btn__${
                        isPlayButtonVisible ? 'visible' : 'hidden'
                      }`}
                      style={{
                        margin: containerHeight * 0.03,
                        width: containerHeight * 0.2,
                        height: containerHeight * 0.2,
                      }}>
                      <i
                        className="play icon"
                        style={{
                          fontSize: containerHeight * 0.1,
                          paddingLeft: containerHeight * 0.02,
                        }}></i>
                    </div>
                  )}
                  <video
                    autoPlay
                    ref={videoRef}
                    loop
                    muted
                    onClick={e => {
                      const v = e.target as HTMLVideoElement;
                      if (v.paused) {
                        v.play();
                        setPlayButtonVisibility(false);
                      } else {
                        v.pause();
                        setPlayButtonVisibility(true);
                      }
                    }}
                    onLoadedData={onLoaded}
                    onError={() => {
                      setDomLoadFailed?.(true);
                    }}
                    src={videoSrc}
                  />
                </div>
              )}
            </>
          ) : (
            failedLoadElement
          )}
        </>
        {footerElement}
      </Card>
    );
  },
  {id: 'VideoViewer'}
);

export default VideoViewer;
