import * as VegaLib from './vega2';
import * as QueryResult from '../state/queryGraph/queryResult';

interface TableTypeRuns {
  type: 'run';
}

type TableType = TableTypeRuns;

interface Row {
  [key: string]: any;
}

export interface TableChangeSet {
  tableName: string;
  insertRows: Row[];
  remove(row: Row): boolean;
}

export function computeTableDelta(
  prevData: QueryResult.Row[],
  data: QueryResult.Row[],
  tables: TableType[]
): TableChangeSet[] {
  const changeSets: TableChangeSet[] = [];

  // HACK Just put a whole new table in.
  const runsTable = computeInitialTables(data, tables)[0];
  changeSets.push({
    tableName: 'wandb',
    remove: (row: Row) => true,
    // _.includes(removeRunIds, row.id) ||
    // _.find(updateRuns, updateRun => updateRun.name === row.id) != null,
    insertRows: runsTable.values,
  });

  return changeSets;
}

export interface VegaTable {
  name: string;
  values: Row[];
}

export function computeInitialTables(
  data: QueryResult.Row[],
  tables: TableType[]
): VegaTable[] {
  return [
    {
      name: 'wandb',
      values: data,
    },
  ];
}

export function SpecToTables(
  refs: VegaLib.Ref[],
  userSettings: VegaLib.UserSettings
): TableType[] {
  return [{type: 'run'}];
}
