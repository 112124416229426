import React from 'react';
import {Link} from 'react-router-dom';
import '../css/MemberInfo.less';

import {Image} from 'semantic-ui-react';
import {MemberData} from '../graphql/teamQuery';
import makeComp from '../util/profiler';
import {gray800} from '../css/globals.styles';

interface MemberInfoProps {
  member: MemberData;
  isViewer: boolean;
}

const MemberInfo: React.FC<MemberInfoProps> = makeComp(
  ({member, isViewer}) => {
    return (
      <div data-test="member-info" className="member-info">
        <Image
          avatar
          src={member.photoUrl}
          onError={(img: any) => (img.target.style.visibility = 'hidden')}
        />
        <div className="member-info-text">
          <div>
            {member.username ? (
              <Link to={`/${member.username}`} style={{color: gray800}}>
                {member.username}
              </Link>
            ) : (
              `${member.email || member.name}${isViewer ? ' (you)' : ''}`
            )}
          </div>
          <div
            data-test="member-description"
            className={`member-description ${member.accountType}`}>
            {member.apiKey || member.name}
          </div>
        </div>
      </div>
    );
  },
  {id: 'MemberInfo', memo: true}
);

export default MemberInfo;
