import React from 'react';
import * as S from './lists.styles';
import {RenderElementProps, useEditor, ReactEditor} from 'slate-react';
import {BlockWrapper} from './drag-drop';
import {
  Node,
  Element,
  Text,
  Transforms,
  NodeEntry,
  Ancestor,
  Editor,
  Path,
  Descendant,
  Point,
  Range,
} from 'slate';
import {isParagraph} from './paragraphs';
import {Checkbox} from 'semantic-ui-react';

export interface ListItem extends Element {
  type: 'list-item';
  ordered?: boolean;
  checked?: boolean;
  spread?: boolean;
}

export const isListItem = (node: Node): node is ListItem =>
  node.type === 'list-item';

export const EditorWithLists = {
  moveListItemDown(editor: Editor, listItemEntry: NodeEntry<Ancestor>) {
    // Previous sibling is the new parent
    const previousSiblingItem = Editor.node(
      editor,
      Path.previous(listItemEntry[1])
    ) as NodeEntry<Ancestor>;

    if (previousSiblingItem) {
      const [previousNode, previousPath] = previousSiblingItem;

      const sublist = previousNode.children.find(n => isList(n)) as
        | Element
        | undefined;
      const newPath = previousPath.concat(
        sublist ? [1, sublist.children.length] : [1]
      );

      if (!sublist) {
        // Create new sublist
        Transforms.wrapNodes(
          editor,
          {type: 'list', ordered: listItemEntry[0].ordered, children: []},
          {at: listItemEntry[1]}
        );
      }

      // Move the current item to the sublist
      Transforms.moveNodes(editor, {
        at: listItemEntry[1],
        to: newPath,
      });
    }
  },

  onTab(editor: Editor, event: React.KeyboardEvent) {
    let tabbed = false;
    const visited: Set<Node> = new Set();

    // Get each paragraph in the selection to get its parent list item.
    // This is a hacky way to match for list items without their ancestor list items.
    // We can set mode = 'lowest' instead, but then we'll miss ancestor
    // list items which are supposed to be included (if their text is also highlighted).
    // We call .nodes() on every iteration to regenerate the paths, since
    // transforms on a list item may modify the paths of its siblings.
    while (true) {
      const nodes = Editor.nodes(editor, {
        match: n => isParagraph(n) && !visited.has(n),
      });
      let paragraphEntry: NodeEntry | null = null;
      for (const nodeEntry of nodes) {
        const [paragraphNode] = nodeEntry;
        visited.add(paragraphNode);
        paragraphEntry = nodeEntry;
        break;
      }
      if (paragraphEntry == null) {
        break;
      }

      const [, paragraphPath] = paragraphEntry;
      const listItemEntry = Editor.above(editor, {
        at: paragraphPath,
        match: n => isListItem(n),
      });

      if (listItemEntry != null) {
        const [node, path] = listItemEntry;
        const descendantParagraphs = Node.descendants(node);
        for (const [paragraphNode] of descendantParagraphs) {
          visited.add(paragraphNode);
        }
        if (event.shiftKey) {
          if (path.length > 2) {
            Transforms.unwrapNodes(editor, {
              at: path,
            });
          }
        } else {
          if (path[path.length - 1] > 0) {
            EditorWithLists.moveListItemDown(editor, listItemEntry);
          }
        }
        tabbed = true;
      }
    }

    return tabbed;
  },
};

export const ListItemElement: React.FC<
  RenderElementProps & {
    element: ListItem;
  }
> = ({attributes, element, children}) => {
  const editor = useEditor();

  const path = ReactEditor.findPath(editor, element);

  // put children before checkbox so cursor doesn't focus on the prev line
  const content = (
    <S.ListItem checked={element.checked}>
      {children}
      {element.checked != null && (
        <S.CheckboxWrapper contentEditable={false}>
          <Checkbox
            checked={element.checked}
            onChange={() => {
              Transforms.setNodes(
                editor,
                {checked: !element.checked},
                {at: path}
              );
            }}
          />
        </S.CheckboxWrapper>
      )}
    </S.ListItem>
  );

  if (path.length > 2) {
    return <S.ListItemWrapper {...attributes}>{content}</S.ListItemWrapper>;
  }

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.ListItemWrapper>{content}</S.ListItemWrapper>
    </BlockWrapper>
  );
};

export interface List extends Element {
  type: 'list';
  ordered?: boolean;
  start?: number;
  spread?: boolean;
}

export const isList = (node: Node): node is List => node.type === 'list';

export const ListElement: React.FC<
  RenderElementProps & {
    element: List;
  }
> = ({attributes, element, children}) => {
  if (element.ordered) {
    return (
      <S.OrderedList {...attributes} start={element.start}>
        {children}
      </S.OrderedList>
    );
  } else {
    return <S.UnorderedList {...attributes}>{children}</S.UnorderedList>;
  }
};

export const withLists = <T extends Editor>(editor: T) => {
  const {normalizeNode, insertBreak, deleteBackward} = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (isListItem(node)) {
      if (
        node.children.length === 1 &&
        !Editor.isBlock(editor, node.children[0])
      ) {
        Transforms.wrapNodes(
          editor,
          {type: 'paragraph', children: [{text: ''}]},
          {at: path.concat([0])}
        );
        return;
      }

      if (
        node.children.length > 0 &&
        isList(node.children[0]) &&
        node.children[0].ordered !== node.ordered
      ) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.unwrapNodes(editor, {at: path.concat([0])});
          Transforms.unwrapNodes(editor, {at: path.concat([0])});
          Transforms.setNodes(editor, {ordered: !node.ordered}, {at: path});
        });
        return;
      }

      for (let i = 1; i < node.children.length; i++) {
        if (isParagraph(node.children[i])) {
          const startPath = path.concat(i);
          const endPath = path.concat(node.children.length - 1);
          const startPoint = Editor.start(editor, startPath);
          const endPoint = Editor.end(editor, endPath);

          Editor.withoutNormalizing(editor, () => {
            Transforms.wrapNodes(
              editor,
              {
                ...node,
                children: [{text: ''}],
              },
              {at: {anchor: startPoint, focus: endPoint}}
            );

            Transforms.liftNodes(editor, {at: startPath});
          });

          return;
        }
      }
    }

    if (isList(node)) {
      if (node.children.length === 0) {
        Transforms.removeNodes(editor, {at: path});
        return;
      }

      const hasListItem = node.children.some(
        n => n.type === 'list-item' && !!n.ordered === !!node.ordered
      );
      if (!hasListItem) {
        Transforms.unwrapNodes(editor, {at: path});
        return;
      }

      for (let i = 0; i < node.children.length; i++) {
        if (
          node.children[i].type !== 'list-item' ||
          !!node.children[i].ordered !== !!node.ordered
        ) {
          const childPath = [...path, i];
          Transforms.liftNodes(editor, {at: childPath});
          return;
        }
      }
    }

    if (Editor.isEditor(node) || Element.isElement(node)) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i] as Descendant;
        const prev = node.children[i - 1] as Descendant;
        if (
          prev != null &&
          isList(prev) &&
          isList(child) &&
          !!prev.ordered === !!child.ordered
        ) {
          Transforms.mergeNodes(editor, {at: [...path, i]});
          return;
        }

        if (isListItem(child) && !isList(node)) {
          Transforms.wrapNodes(
            editor,
            {type: 'list', ordered: child.ordered, children: []},
            {at: [...path, i]}
          );
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    const listItemEntry = Editor.above(editor, {match: n => isListItem(n)});

    if (listItemEntry != null) {
      const [listItemNode, listItemPath] = listItemEntry;
      const {children} = listItemNode;
      const [firstChild] = children;
      const firstChildIsEmptyElement =
        Element.isElement(firstChild) && Editor.isEmpty(editor, firstChild);
      const firstChildIsEmptyText =
        Text.isText(firstChild) && firstChild.text === '';
      if (
        children.length === 1 &&
        (firstChildIsEmptyElement || firstChildIsEmptyText)
      ) {
        Transforms.unwrapNodes(editor, {at: listItemPath});

        return;
      }
    }

    insertBreak();

    // when splitting checked item, make the new item not checked
    const checkedListItemEntry = Editor.above(editor, {
      match: n => isListItem(n) && !!n.checked,
    });
    if (checkedListItemEntry != null) {
      const [, path] = checkedListItemEntry;
      Transforms.setNodes(editor, {checked: false}, {at: path});
    }
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => isListItem(n),
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          Transforms.unwrapNodes(editor, {at: path});
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};
