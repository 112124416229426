import * as ViewTypes from '../types';
import * as PanelViewTypes from '../panel/types';

export interface InteractState {
  highlight: {
    [axis: string]: string | number | undefined;
  };
  panelSelection: PanelViewTypes.Ref[];
}

export const EMPTY_STATE: InteractState = {highlight: {}, panelSelection: []};

export type Ref = ViewTypes.PartRefFromObjSchema<InteractStateObjSchema>;

export interface InteractStateObjSchema {
  type: 'interactState';
  whole: InteractState;
  part: InteractState;
}
