import React, {useRef, useEffect} from 'react';
import * as _ from 'lodash';
import {Dropdown, Menu} from 'semantic-ui-react';
import * as ViewHooks from '../state/views/hooks';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

interface PanelMoverProps {
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  onAddSection: (newSectionName: string) => void;
  onChange: (toSectionRef: PanelBankSectionConfigTypes.Ref) => void;
}

// "Move panel to section..." submenu
export const PanelMoverMenu: React.FC<PanelMoverProps> = makeComp(
  ({panelBankConfigRef, panelBankSectionConfigRef, onAddSection, onChange}) => {
    const panelMoverRef = useRef<HTMLDivElement>(null);
    const panelBankSectionRefs =
      ViewHooks.usePart(panelBankConfigRef).sectionRefs;
    const panelBankSectionNames = ViewHooks.useWholeMapped(
      panelBankConfigRef,
      pbc => pbc.sections.map(s => s.name)
    );
    const panelMoverOptions = _.compact(
      panelBankSectionRefs.map(r => {
        const sectionIndex = _.findIndex(panelBankSectionRefs, r);
        return sectionIndex < 0 || _.isEqual(r, panelBankSectionConfigRef)
          ? undefined
          : {value: r.id, text: panelBankSectionNames[sectionIndex]};
      })
    );
    useEffect(() => {
      setTimeout(() => {
        panelMoverRef.current?.querySelector('input')?.focus();
      }, 1);
    }, []);

    return (
      <div className="popup-submenu" ref={panelMoverRef}>
        <Menu vertical borderless compact>
          <Menu.Item header>Move panel to section...</Menu.Item>
          <Menu.Item>
            <Dropdown
              selection
              search
              allowAdditions
              additionLabel="Create section: "
              additionPosition="bottom"
              open
              options={panelMoverOptions}
              placeholder="Find or create section..."
              selectOnNavigation={false}
              onAddItem={(e, data) => onAddSection(data.value as string)}
              selectOnBlur={false}
              onChange={(e, data) => {
                const toSectionRef = _.find(
                  panelBankSectionRefs,
                  s => s.id === data.value
                );
                if (toSectionRef) {
                  onChange(toSectionRef);
                }
              }}
            />
          </Menu.Item>
        </Menu>
      </div>
    );
  },
  {id: 'PanelMoverMenu'}
);

// The menu option for "Move panel to {default section}"
export const DefaultMovePanelOption: React.FC<{
  toSectionRef: PanelBankSectionConfigTypes.Ref;
  defaultMovePanel(): void;
}> = makeComp(
  ({toSectionRef, defaultMovePanel}) => {
    const toSectionName = ViewHooks.useWhole(toSectionRef).name;
    return (
      <Menu.Item onClick={defaultMovePanel}>
        <LegacyWBIcon name="move" style={{color: 'green'}} />
        <span style={{lineHeight: '1.2em'}}>
          Move panel to
          <br />
          <span style={{fontWeight: 600}}>
            {_.truncate(toSectionName, {length: 14})}
          </span>
        </span>
      </Menu.Item>
    );
  },
  {id: 'DefaultMovePanelOption'}
);
