import styled from 'styled-components';

export const ContentWrapper = styled.div`
  line-height: 26px;
`;

export const Inline = styled.div`
  padding: 0 0 24px 0;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const DropdownWrapper = styled.div`
  padding: 8px 0 0 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
