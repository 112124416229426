import * as S from './InlineStringEditor.styles';
import * as QueryEditorStyles from '../ExpressionEditor.styles';

import React from 'react';
import WBSuggester, {
  WBSuggesterOptionFetcher,
} from '../../elements/WBSuggester';
import {WBMenuOption} from '@wandb/ui';
import makeComp from '../../../util/profiler';

interface PanelNameEditorProps {
  value: string;
  disabled?: boolean;
  autocompleteOptions?: WBMenuOption[] | WBSuggesterOptionFetcher;
  setValue(val: string): void;
}

const PanelNameEditor: React.FC<PanelNameEditorProps> = makeComp(
  props => {
    const ref = React.useRef<HTMLSpanElement>(null);
    // Disabled, we just always show all options
    // const [autocompleterQuery, setAutocompleterQuery] = React.useState('');
    const [autocompleterOpen, setAutocompleterOpen] = React.useState(false);
    React.useEffect(() => {
      if (props.value === '' && document.querySelector(':focus') == null) {
        ref.current?.focus();
      }
    }, [props.value]);
    return (
      <>
        <QueryEditorStyles.PanelNameSpan spellCheck="false">
          <WBSuggester
            options={props.autocompleteOptions}
            onSelect={v => {
              setAutocompleterOpen(false);
              props.setValue(v as string);
            }}
            // Disabled, we just always show all options
            query={''}
            open={autocompleterOpen}
            onParentScroll={() => setAutocompleterOpen(false)}>
            {({inputRef}) => (
              <S.InlineContentEditable
                innerRef={node => {
                  (ref as any).current = node;
                  inputRef(node);
                }}
                value={props.value}
                disabled={props.disabled}
                onFocus={() => {
                  setAutocompleterOpen(true);
                  // setAutocompleterQuery(props.value);
                }}
                onBlur={() => setAutocompleterOpen(false)}
                onTempChange={v => {
                  // setAutocompleterQuery(v);
                  setAutocompleterOpen(true);
                }}
                onChange={v => {}}></S.InlineContentEditable>
            )}
          </WBSuggester>
        </QueryEditorStyles.PanelNameSpan>
      </>
    );
  },
  {id: 'InlineStringEditor'}
);

export default PanelNameEditor;
