import '../css/TableEditor.less';

import React, {useState, useCallback} from 'react';
import {Button, Grid, Icon, Input, List} from 'semantic-ui-react';
import {cloneDeep as _cloneDeep} from 'lodash';

import {fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import * as Run from '../util/runs';
import * as RunFeed from '../util/runfeed';
import * as TableCols from '../util/tablecols';
import LegacyWBIcon from './elements/LegacyWBIcon';
import TableEditorColumnField from './TableEditorColumnField';
import TableEditorColumnListContainer from './TableEditorColumnListContainer';
import makeComp from '../util/profiler';

const MAX_COLUMNS_SHOWN = 100;

interface TableEditorProps {
  config: RunFeed.Config;
  allColumns: string[];
  fixedColumns?: string[]; // by accessor

  searchQuery: string;
  searchLoading?: boolean;
  objectType?: string; // Just a string used in the UI. defaults to Columns, but is Parameters in the parameter importance panel
  setSearch(search: string): void;
  update(config: RunFeed.Config): void;
}

const TableEditor: React.FC<TableEditorProps> = makeComp(
  ({
    config,
    allColumns,
    fixedColumns,
    searchQuery,
    searchLoading,
    objectType: propsObjectType,
    setSearch,
    update,
  }) => {
    const [draggingColumn, setDraggingColumn] = useState<string | undefined>();
    const [visibleColumnListEl, setVisibleColumnListEl] =
      React.useState<HTMLDivElement | null>(null);

    const resetDraggingState = useCallback(() => {
      setDraggingColumn(undefined);
    }, []);

    const updateConfig: typeof update = useCallback(
      conf => {
        conf.columnConfigModified = true;
        update(conf);
      },
      [update]
    );

    const dropField = useCallback(
      (dropColumn: string, visible: boolean) => {
        let conf = _cloneDeep(config);
        if (draggingColumn) {
          if (visible) {
            conf = TableCols.showColumns(conf, [draggingColumn]);
            conf = TableCols.moveBefore(conf, draggingColumn, dropColumn);
          } else {
            conf = TableCols.hideColumns(conf, [draggingColumn]);
          }
        }
        updateConfig(conf);
      },
      [config, draggingColumn, updateConfig]
    );

    const onContainerDrop = useCallback(
      (visible: boolean) => {
        let conf = _cloneDeep(config);
        if (draggingColumn) {
          if (visible) {
            conf = TableCols.showColumns(conf, [draggingColumn]);
            conf = TableCols.moveToEnd(conf, draggingColumn);
            window.setTimeout(() => {
              if (visibleColumnListEl) {
                visibleColumnListEl.scrollTo({
                  top: visibleColumnListEl.scrollHeight,
                });
              }
            });
          } else {
            conf = TableCols.hideColumns(conf, [draggingColumn]);
          }
        }
        updateConfig(conf);
      },
      [config, draggingColumn, updateConfig, visibleColumnListEl]
    );

    const showColumn = useCallback(
      (columnAccessor: string) => {
        updateConfig(TableCols.showColumns(config, [columnAccessor], true));
      },
      [config, updateConfig]
    );

    const hideColumn = useCallback(
      (columnAccessor: string) => {
        updateConfig(TableCols.hideColumns(config, [columnAccessor]));
      },
      [config, updateConfig]
    );

    const showAll = useCallback(
      (hiddenColumns: string[]) => {
        updateConfig(TableCols.showColumns(config, hiddenColumns, true));
      },
      [config, updateConfig]
    );

    const hideAllUnpinned = useCallback(
      (visibleColumns: string[]) => {
        updateConfig(
          TableCols.hideColumns(
            config,
            visibleColumns.filter(c => !config.columnPinned[c])
          )
        );
      },
      [config, updateConfig]
    );

    const togglePinned = useCallback(
      (column: string) => {
        updateConfig(TableCols.togglePinned(config, column));
      },
      [config, updateConfig]
    );

    const objectType = propsObjectType || 'Columns';

    const getSearchMatches = (columns: string[]) => {
      return fuzzyMatchWithMapping(columns, searchQuery, cAccessor =>
        Run.keyStringDisplayName(cAccessor)
      );
    };

    const searchedColumnsHidden = getSearchMatches(
      allColumns.filter(cAccessor => !config.columnVisible[cAccessor])
    );

    const searchedColumnsVisible = getSearchMatches(config.columnOrder);

    return (
      <Grid className="table-editor">
        {allColumns.length > TableCols.COLUMN_LIMIT && (
          <Grid.Row>
            <Grid.Column>
              <div style={{fontStyle: 'italic'}}>
                <Icon name="warning sign" style={{marginRight: '5px'}} />
                {`Table will only display up to ${TableCols.COLUMN_LIMIT} columns.`}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <Input
              className="table-editor__search"
              icon="search"
              placeholder={`Search ${objectType}`}
              loading={searchLoading}
              onChange={(e, {value}) => setSearch(value)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <TableEditorColumnListContainer
              visibleColumns={false}
              onDrop={() => onContainerDrop(false)}>
              <h5>
                Hidden {objectType}
                <span className="count-tag">
                  {searchedColumnsHidden.length}
                </span>
              </h5>
              <div className="items-scroll-list">
                {searchedColumnsHidden.slice(0, 100).map(column => {
                  return (
                    <TableEditorColumnField
                      key={column}
                      disabled={false}
                      column={column}
                      config={config}
                      onDragStart={(e: React.DragEvent) => {
                        e.dataTransfer.setData('text', ''); // this is necessary for drag+drop to work in firefox
                        setDraggingColumn(column);
                      }}
                      onDragEnd={() => resetDraggingState}
                      onDrop={dropColumn => dropField(dropColumn, false)}
                      onPinClick={null}
                      dragging={column === draggingColumn}
                      searchQuery={searchQuery}
                      onClick={() => showColumn(column)}
                    />
                  );
                })}
                {searchedColumnsHidden.length >= MAX_COLUMNS_SHOWN && (
                  <List.Item className="hint-text">{`Limited to ${MAX_COLUMNS_SHOWN} results. Use search to find other columns.`}</List.Item>
                )}
              </div>
              <Button
                className="right tiny wb-icon-button"
                onClick={() => showAll(searchedColumnsHidden)}>
                <LegacyWBIcon name="next" />
                Show all
              </Button>
            </TableEditorColumnListContainer>
          </Grid.Column>
          <Grid.Column width={8}>
            <TableEditorColumnListContainer
              visibleColumns={true}
              onDrop={() => onContainerDrop(true)}>
              <h5>
                Visible {objectType}
                <span className="count-tag">
                  {searchedColumnsVisible.length}
                </span>
                {config.columnOrder.length === TableCols.COLUMN_LIMIT && (
                  <span className="column-limit-msg">Column limit reached</span>
                )}
              </h5>
              <div
                className="items-scroll-list"
                ref={node => setVisibleColumnListEl(node)}>
                {searchedColumnsVisible.slice(0, 100).map((column, i) => {
                  return (
                    <TableEditorColumnField
                      key={i}
                      disabled={(fixedColumns || []).indexOf(column) !== -1}
                      column={column}
                      config={config}
                      onDragStart={(e: React.DragEvent) => {
                        e.dataTransfer.setData('text', ''); // this is necessary for drag+drop to work in firefox
                        setDraggingColumn(column);
                      }}
                      onDragEnd={() => resetDraggingState}
                      onDrop={dropColumn => dropField(dropColumn, true)}
                      onPinClick={() => togglePinned(column)}
                      dragging={column === draggingColumn}
                      searchQuery={searchQuery}
                      onClick={() => hideColumn(column)}
                    />
                  );
                })}
                {searchedColumnsVisible.length >= MAX_COLUMNS_SHOWN && (
                  <List.Item className="hint-text">{`Limited to ${MAX_COLUMNS_SHOWN} results. Use search to find other columns.`}</List.Item>
                )}
              </div>
              <Button
                className="tiny wb-icon-button"
                onClick={() => hideAllUnpinned(searchedColumnsVisible)}>
                <LegacyWBIcon name="previous" />
                Hide all
              </Button>
            </TableEditorColumnListContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  },
  {id: 'TableEditor', memo: true}
);

export default TableEditor;
