import {WBIcon} from '@wandb/ui';
import moment from 'moment';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Loader} from 'semantic-ui-react';
import {memoize} from 'lodash';
import {produce} from 'immer';

import * as CGTypes from '@wandb/cg/browser/model/types';
import * as CG from '@wandb/cg/browser/graph';
import * as HL from '@wandb/cg/browser/hl';
import * as Op from '@wandb/cg/browser/ops';

import * as TableState from './Panel2/tableState';
import * as CGReact from '../cgreact';
import ModifiedDropdown from '../components/elements/ModifiedDropdown';
import {PanelSpecNode} from '../components/Panel2/panel';
import {PanelComp2} from '../components/Panel2/PanelComp';
import {PanelContextProvider} from '../components/Panel2/PanelContext';
import {Spec as PanelSuperPlotSpec} from '../components/Panel2/PanelPlot';
import {Spec as PanelSimpleTableSpec} from '../components/Panel2/PanelSimpleTable';
import * as UtilHooks from '../util/hooks';
import makeComp from '../util/profiler';
import * as S from './ActivityDashboard.styles';

const Datetime = require('react-datetime');

// All CG for this page is generated once at module load. There are 3 kinds
// of CG expressions:
// - scalars, rendered as the single value in mini panels (single value)
// - 2D tables, rendered as tables (rows of label+value)
// - 3D tables, rendered as stacked bar charts (rows of time+label+value)
const cgPaths = memoize(() => {
  const projects = CG.varNode(
    {type: 'list', objectType: 'project'},
    'projects'
  );

  const reports = Op.opFilter({
    arr: CG.varNode({type: 'list', objectType: 'report'}, 'reports'),
    filterFn: Op.defineFunction({row: 'report'}, ({row}) =>
      dateClause(Op.opReportCreatedAt({report: row}) as any)
    ),
  });

  const artifacts = Op.opFilter({
    arr: CG.varNode({type: 'list', objectType: 'artifact'}, 'artifacts'),
    filterFn: Op.defineFunction({row: 'artifact'}, ({row}) =>
      dateClause(Op.opArtifactCreatedAt({artifact: row}) as any)
    ),
  });

  const runs = Op.opFilter({
    arr: Op.opFlatten({
      arr: Op.opLimit({
        arr: Op.opProjectFilteredRuns({
          project: projects,
          filter: CG.varNode('string', 'runFilter'),
          order: Op.constString('-created_at'),
        }),
        limit: Op.constNumber(100000),
      }) as any,
    }),
    filterFn: Op.defineFunction({row: 'run'}, ({row}) =>
      Op.opAnd({
        lhs: dateClause(
          Op.opRunCreatedAt({
            run: row,
          }) as any // TODO(np): Why
        ),
        rhs: userFilterClause(
          Op.opRunUser({
            run: row,
          }) as any // TODO(np): Why
        ),
      })
    ) as any,
  });

  return {
    countRuns: defineScalar({
      value: Op.opCount({
        arr: runs,
      }),
    }),

    countReports: defineScalar({
      value: Op.opCount({
        arr: reports,
      }),
    }),

    countArtifactVersions: defineScalar({
      value: Op.opNumbersSum({
        numbers: Op.opMap({
          arr: artifacts as any,
          mapFn: Op.defineFunction({row: 'artifact'}, ({row}) =>
            Op.opCount({
              arr: Op.opLimit({
                arr: Op.opArtifactVersions({
                  artifact: row,
                }),
                limit: Op.constNumber(100000),
              }),
            })
          ) as any,
        }),
      }),
    }),

    sumComputeTime: defineScalar({
      value: Op.opNumberToFixed({
        in: Op.opNumbersSum({
          numbers: Op.opMap({
            arr: runs as any,
            mapFn: Op.defineFunction({row: 'run'}, ({row}) =>
              Op.opNumberDiv({
                lhs: Op.opRunRuntime({
                  run: row,
                }),
                rhs: Op.constNumber(3600), // seconds -> hours
              })
            ) as any,
          }),
        }) as any,
        digits: Op.constNumber(2),
      }),
    }),

    sumStorage: defineScalar({
      value: Op.opNumbersSum({
        numbers: Op.opMap({
          arr: artifacts as any,
          mapFn: Op.defineFunction({row: 'artifact'}, ({row}) =>
            Op.opNumbersSum({
              numbers: Op.opMap({
                arr: Op.opLimit({
                  arr: Op.opArtifactVersions({
                    artifact: row,
                  }),
                  limit: Op.constNumber(100000),
                }) as any,
                mapFn: Op.defineFunction(
                  {row: 'artifactVersion'},
                  avMapInputs =>
                    Op.opArtifactVersionSize({
                      artifactVersion: avMapInputs.row,
                    })
                ) as any,
              }),
            })
          ) as any,
        }),
      }),
    }),

    // 2D Tables, rendered as tables
    tableRunsByUser: define2DTable({
      label: Op.opUserLink({
        user: Op.opRunUser({
          run: CG.varNode('run', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode(CGTypes.list('run'), 'row') as any,
      }),
      source: runs,
    }),

    tableReportsByUser: define2DTable({
      label: Op.opUserLink({
        user: Op.opReportCreator({
          report: CG.varNode('report', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode(CGTypes.list('report'), 'row') as any,
      }),
      source: reports,
    }),

    tableRunsByProject: define2DTable({
      label: Op.opProjectLink({
        project: Op.opRunProject({
          run: CG.varNode('run', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode(CGTypes.list('run'), 'row') as any,
      }),
      source: runs,
    }),

    tableReportViewsByReport: define2DTable({
      label: Op.opReportLink({
        report: CG.varNode('report', 'row'),
      }),
      value: Op.opNumbersSum({
        numbers: Op.opReportViewCount({
          report: CG.varNode('report', 'row'),
        }),
      }),
      source: reports,
    }),

    tableComputeTimeByRuns: define2DTable({
      label: Op.opRunLink({
        run: CG.varNode('run', 'row'),
      }),
      value: Op.opNumbersSum({
        numbers: Op.opNumberToFixed({
          in: Op.opNumberDiv({
            lhs: Op.opRunRuntime({
              run: CG.varNode('run', 'row'),
            }),
            rhs: Op.constNumber(3600), // seconds -> hours
          }) as any,
          digits: Op.constNumber(2),
        }),
      }),
      source: runs,
    }),

    tableComputeTimeByProject: define2DTable({
      label: Op.opProjectLink({
        project: Op.opRunProject({run: CG.varNode('run', 'row')}),
      }),
      value: Op.opNumberToFixed({
        in: Op.opNumbersSum({
          numbers: Op.opNumberDiv({
            lhs: Op.opRunRuntime({
              run: CG.varNode('run', 'row'),
            }),
            rhs: Op.constNumber(3600), // seconds -> hours
          }),
        }) as any,
        digits: Op.constNumber(2),
      }),
      source: runs,
    }),

    tableComputeTimeByUser: define2DTable({
      label: Op.opUserLink({
        user: Op.opRunUser({run: CG.varNode('run', 'row')}),
      }),
      value: Op.opNumberToFixed({
        in: Op.opNumbersSum({
          numbers: Op.opNumberDiv({
            // lhs: Op.opPick({
            //   obj: Op.opRunSummary({
            //     run: CG.varNode('run', 'row'),
            //   }),
            //   key: Op.constString('_runtime'),
            // }),
            lhs: Op.opRunRuntime({
              run: CG.varNode('run', 'row'),
            }),
            rhs: Op.constNumber(3600), // seconds -> hours
          }),
        }) as CGTypes.OutputNode<'number'>,
        digits: Op.constNumber(2),
      }),
      source: runs,
    }),

    tableArtifactsByReferences: define2DTable({
      label: Op.opArtifactName({artifact: CG.varNode('artifact', 'row')}),
      value: Op.opNumbersSum({
        numbers: Op.opFlatten({
          arr: Op.opMap({
            arr: Op.opLimit({
              arr: Op.opArtifactVersions({
                artifact: CG.varNode('artifact', 'row'),
              }) as any,
              limit: Op.constNumber(100000),
            }),
            mapFn: Op.defineFunction({row: 'artifactVersion'}, ({row}) =>
              Op.opArtifactVersionReferenceCount({
                artifactVersion: row,
              })
            ) as any,
          }) as any,
        }),
      }),
      source: artifacts,
    }),

    // 3D Tables, rendered as stacked bar charts
    tableRunsByUserOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opRunCreatedAt({run: CG.varNode('run', 'row')}),
      }),
      label: Op.opUserUsername({
        user: Op.opRunUser({
          run: CG.varNode('run', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode(CGTypes.list('run'), 'row'),
      }),
      source: runs,
    }),

    tableRunsByProjectOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opRunCreatedAt({run: CG.varNode('run', 'row')}),
      }),
      label: Op.opProjectName({
        project: Op.opRunProject({
          run: CG.varNode('run', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode(CGTypes.list('run'), 'row'),
      }),
      source: runs,
    }),

    tableReportsByProjectOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opReportCreatedAt({
          report: CG.varNode('report', 'row'),
        }) as any,
      }),
      label: Op.opProjectName({
        project: Op.opReportProject({report: CG.varNode('report', 'row')}),
      }),
      value: Op.opCount({
        arr: CG.varNode({type: 'list', objectType: 'report'}, 'row') as any,
      }),
      source: reports,
    }),

    tableReportsByUserOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opReportCreatedAt({
          report: CG.varNode('report', 'row'),
        }) as any,
      }),
      label: Op.opUserName({
        user: Op.opReportCreator({
          report: CG.varNode('report', 'row'),
        }) as any,
      }),
      value: Op.opCount({
        arr: CG.varNode({type: 'list', objectType: 'report'}, 'row') as any,
      }),
      source: reports,
    }),

    tableComputeTimeByProjectOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opRunCreatedAt({run: CG.varNode('run', 'row')}),
      }),
      label: Op.opProjectName({
        project: Op.opRunProject({run: CG.varNode('run', 'row')}),
      }),
      value: Op.opNumbersSum({
        numbers: Op.opNumberDiv({
          lhs: Op.opRunRuntime({
            run: CG.varNode('run', 'row'),
          }),
          rhs: Op.constNumber(3600), // seconds -> hours
        }),
      }),
      source: runs,
    }),

    tableComputeTimeByUserOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opRunCreatedAt({run: CG.varNode('run', 'row')}) as any,
      }),
      label: Op.opUserName({
        user: Op.opRunUser({run: CG.varNode('run', 'row')}) as any,
      }),
      value: Op.opNumbersSum({
        numbers: Op.opNumberDiv({
          lhs: Op.opRunRuntime({
            run: CG.varNode('run', 'row'),
          }),
          rhs: Op.constNumber(3600), // seconds -> hours
        }),
      }),
      source: runs,
    }),

    tableArtifactVersionsByTypeOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opArtifactCreatedAt({
          artifact: CG.varNode('artifact', 'row'),
        }) as any,
      }),
      label: Op.opArtifactTypeName({
        artifactType: Op.opArtifactType({
          artifact: CG.varNode('artifact', 'row'),
        }),
      }),
      value: Op.opCount({
        arr: CG.varNode({type: 'list', objectType: 'artifact'}, 'row'),
      }),
      source: artifacts,
    }),

    tableStorageByProjectOverTime: define3DTable({
      time: Op.opDateRoundWeek({
        date: Op.opArtifactCreatedAt({
          artifact: CG.varNode('artifact', 'row'),
        }) as any,
      }),
      label: Op.opProjectName({
        project: Op.opArtifactProject({
          artifact: CG.varNode('artifact', 'row'),
        }),
      }),
      value: Op.opNumbersSum({
        numbers: Op.opMap({
          arr: Op.opLimit({
            arr: Op.opFlatten({
              arr: Op.opArtifactVersions({
                artifact: CG.varNode('artifact', 'row'),
              }) as any,
            }),
            limit: Op.constNumber(100000),
          }),
          mapFn: Op.defineFunction({row: 'artifactVersion'}, ({row}) =>
            Op.opArtifactVersionSize({
              artifactVersion: CG.varNode('artifactVersion', 'row'),
            })
          ) as any,
        }),
      }),
      source: artifacts,
    }),
  };
});

interface ScalarProps {
  value: CGTypes.OutputNode;
}
const defineScalar =
  (props: ScalarProps) => (context: ActivityDashboardContext) =>
    props.value;
interface Table2DProps {
  label: CGTypes.OutputNode;
  value: CGTypes.OutputNode;
  source: CGTypes.Node;
}

const define2DTable =
  (props: Table2DProps) => (context: ActivityDashboardContext) => {
    const {label, value, source} = props;

    let table = TableState.emptyTable(); // Array of runs
    let labelCol: string;
    let valueCol: string;

    // Add label column
    ({table, columnId: labelCol} = TableState.addColumnToTable(table, label));

    // Add value column
    ({table, columnId: valueCol} = TableState.addColumnToTable(table, value));

    // Enable grouping by new columns created above
    table = produce(table, draft => {
      draft.pageSize = context.pageSize;
      draft.groupBy = [labelCol];
      draft.sort = [{columnId: valueCol, dir: 'desc'}];
    });

    const {node: derefSourceNode} = HL.dereferenceVariables(
      source,
      context.frame
    );

    const {resultNode} = TableState.tableGetResultTableNode(
      table,
      derefSourceNode,
      context.frame
    );

    return {resultNode, table, sourceNode: derefSourceNode};
  };

interface Table3DProps {
  label: CGTypes.OutputNode;
  value: CGTypes.OutputNode;
  time: CGTypes.OutputNode;
  source: CGTypes.Node;
}

const define3DTable =
  (props: Table3DProps) => (context: ActivityDashboardContext) => {
    const {label, value, time, source} = props;

    let table = TableState.emptyTable();
    let labelCol: string;
    let valueCol: string;
    let timeCol: string;

    ({table, columnId: timeCol} = TableState.addColumnToTable(table, time));

    ({table, columnId: labelCol} = TableState.addColumnToTable(table, label));

    ({table, columnId: valueCol} = TableState.addColumnToTable(table, value));

    table = produce(table, draft => {
      draft.groupBy = [timeCol, labelCol];
      draft.sort = [{columnId: valueCol, dir: 'desc'}];
    });

    const {resultNode} = TableState.tableGetResultTableNode(
      table,
      source,
      context.frame
    );

    return {resultNode, table, sourceNode: source};
  };

// Optimization: `runs` field accepts a filter string, which we can leverage
// to do efficient filtering in GQL layer
const filterString = (startDate: Date, endDate: Date, userFilter: string[]) =>
  JSON.stringify({
    $and: [
      {createdAt: {$gte: startDate.toISOString()}},
      {createdAt: {$lte: endDate.toISOString()}},
      ...(userFilter.length ? [{username: {$in: userFilter}}] : []),
    ],
  });

// TODO(np): Make+use date comparison ops instead of
// converting to unix epoch and comparing that
const dateClause = (dateQuery: CGTypes.Node<'date'>) =>
  Op.opAnd({
    lhs: Op.opNumberGreaterEqual({
      lhs: Op.opDateToNumber({
        date: dateQuery,
      }),
      rhs: Op.opDateToNumber({
        date: Op.varNode('date', 'startDate'),
      }),
    }),
    rhs: Op.opNumberLessEqual({
      lhs: Op.opDateToNumber({
        date: dateQuery,
      }),
      rhs: Op.opDateToNumber({
        date: Op.varNode('date', 'endDate'),
      }),
    }),
  });

const userFilterClause = (userQuery: CGTypes.Node<'user'>) =>
  Op.opOr({
    lhs: Op.opNumberEqual({
      lhs: Op.constNumber(0),
      rhs: Op.opCount({
        arr: Op.varNode(CGTypes.list('string'), 'userFilter'),
      }),
    }),
    rhs: Op.opContains({
      arr: Op.varNode(CGTypes.list('string'), 'userFilter') as any,
      element: Op.opUserUsername({user: userQuery}) as any,
    }),
  });

// --- Main tables and plots ---
// Using CG defined above, bind them to panel component templates to produce
// mountable panel components.  There are 3 kinds:
// Table panels that render a 2D table as a standard table, grouped by label expression
// Stacked bar panels that render a 3D table as a stacked bar chart, colored by label expression
// Mini panels that render a 3D table as a stacked bar chart, overlaid by a scalar value

interface CGPanelProps {
  inputPath: CGTypes.Node;
  config: any;
  spec: PanelSpecNode;
}

// Wrapper for PanelComp2 so we can mount panels without worrying
// about panel context
const CGPanel: React.FC<CGPanelProps> = makeComp(
  props => {
    const {inputPath, config, spec} = props;

    const [panelContext, setPanelContext] = useState<any>({});
    const updatePanelContext = useCallback<any>((newContext: any) => {
      setPanelContext((curContext: any) => ({...curContext, ...newContext}));
    }, []);

    return (
      <PanelComp2
        input={{path: inputPath as any}}
        inputType={inputPath.type}
        loading={false}
        panelSpec={spec}
        configMode={false}
        context={panelContext}
        config={config}
        updateConfig={what => {
          console.log('updateConfig', what);
        }}
        updateContext={updatePanelContext}
      />
    );
  },
  {
    id: 'CGPanel',
  }
);
interface SourceParams {
  table: TableState.TableState;
  resultNode: CGTypes.Node;
  sourceNode: CGTypes.Node;
}

const makeTablePanel = (
  id: string,
  title: string,
  sourceParamsFn: (context: ActivityDashboardContext) => SourceParams
): React.FC =>
  makeComp(
    props => {
      const context = useContext(ActivityDashboardContext);

      const {table, resultNode, sourceNode} = useMemo(
        () => sourceParamsFn(context),
        [context]
      );

      // Hack to force all table columns to be fetched immediately, rather than
      // after the waterfall that the table panel currently does, because it
      // does a count before fetching its nodes.
      // TODO: Remove after getting rid of CGReact.useEach (its no longer necessary)
      CGReact.useNodeValue(resultNode);

      return (
        <S.TablePanel>
          <S.PanelTitle>{title}</S.PanelTitle>
          <CGPanel
            inputPath={sourceNode}
            spec={PanelSimpleTableSpec}
            config={table}
          />
        </S.TablePanel>
      );
    },
    {
      id,
    }
  );

const MostViewsReportTable = makeTablePanel(
  'MostViewsReportTable',
  'Most viewed reports',
  cgPaths().tableReportViewsByReport
);

const MostRunsUserTable = makeTablePanel(
  'MostRunsUserTable',
  'Users with the most runs',
  cgPaths().tableRunsByUser
);

const MostRunsProjectTable = makeTablePanel(
  'MostRunsProjectTable',
  'Projects with the most runs',
  cgPaths().tableRunsByProject
);

const ComputeTimePerProjectTable = makeTablePanel(
  'ComputeTimePerProjectTable',
  'Compute hours per project',
  cgPaths().tableComputeTimeByProject
);

const ComputeTimePerUserTable = makeTablePanel(
  'ComputeTimePerUserTable',
  'Users with the most compute hours',
  cgPaths().tableComputeTimeByUser
);

const LongestComputeTimeRunsTable = makeTablePanel(
  'LongestComputeTimeRunsTable',
  'Runs with the most compute hours',
  cgPaths().tableComputeTimeByRuns
);

const MostReportsByUserTable = makeTablePanel(
  'MostReportsByUserTable',
  'Users who wrote the most reports',
  cgPaths().tableReportsByUser
);

const MostUsedArtifactsTable = makeTablePanel(
  'MostUsedArtifactsTable',
  'Most used artifacts (# of references)',
  cgPaths().tableArtifactsByReferences
);

const domainFromDateWindow = (startDate: Date, endDate: Date) => {
  const windowStart = moment(startDate).startOf('week');
  const windowEnd = moment(endDate).add(1, 'week').startOf('week');
  return [
    {
      year: windowStart.year(),
      month: windowStart.month() + 1,
      date: windowStart.date(),
    },
    {
      year: windowEnd.year(),
      month: windowEnd.month() + 1,
      date: windowEnd.date(),
    },
  ];
};

const makeStackedBarPanel = (
  id: string,
  title: string,
  // table cols should be x,color,y
  sourceParamsFn: (context: ActivityDashboardContext) => SourceParams
): React.FC =>
  makeComp(
    props => {
      const context = useContext(ActivityDashboardContext);

      const {table: partialTable, sourceNode} = useMemo(
        () => sourceParamsFn(context),
        [context]
      );

      const node = useMemo(
        () =>
          HL.dereferenceVariables(
            sourceNode,
            context.frame // TODO(np): Use PanelContext instead
          ).node,
        [sourceNode, context.frame]
      );

      const config = useMemo(() => {
        let table = TableState.appendEmptyColumn(partialTable);
        const labelColId = table.order[table.order.length - 1];
        table = TableState.appendEmptyColumn(table);
        const detailColId = table.order[table.order.length - 1];
        table = TableState.appendEmptyColumn(table);
        const tooltipColId = table.order[table.order.length - 1];
        return {
          table,
          dims: {
            x: table.order[0],
            y: table.order[2],
            color: table.order[1],
            label: labelColId,
            detail: detailColId,
            tooltip: tooltipColId,
          },
          title: '',
          axisSettings: {
            x: {
              scale: {
                domain: domainFromDateWindow(
                  context.startDate,
                  context.endDate
                ),
              },
            },
            y: {},
          },
        };
      }, [context, partialTable]);

      return (
        <S.TablePanel>
          <S.PanelTitle>{title}</S.PanelTitle>
          <CGPanel inputPath={node} spec={PanelSuperPlotSpec} config={config} />
        </S.TablePanel>
      );
    },
    {id}
  );

const WeeklyRunsByUserPlot = makeStackedBarPanel(
  'WeeklyRunsByUserPlot',
  'Weekly runs by user',
  cgPaths().tableRunsByUserOverTime
);

const WeeklyRunsByProjectPlot = makeStackedBarPanel(
  'WeeklyRunsByProjectPlot',
  'Weekly runs by project',
  cgPaths().tableRunsByProjectOverTime
);

const WeeklyReportsByUserPlot = makeStackedBarPanel(
  'WeeklyReportsByUserPlot',
  'Weekly reports by user',
  cgPaths().tableReportsByUserOverTime
);

const WeeklyReportsByProjectPlot = makeStackedBarPanel(
  'WeeklyReportsByProjectPlot',
  'Weekly reports by project',
  cgPaths().tableReportsByProjectOverTime
);

const WeeklyComputeTimeByProjectPlot = makeStackedBarPanel(
  'WeeklyComputeTimeByProjectPlot',
  'Weekly compute hours by project',
  cgPaths().tableComputeTimeByProjectOverTime
);

const WeeklyComputeTimeByUserPlot = makeStackedBarPanel(
  'WeeklyComputeTimeByUserPlot',
  'Weekly compute hours by user',
  cgPaths().tableComputeTimeByUserOverTime
);

// --- Mini panels ---

const makeMiniPanel = (args: {
  id: string;
  title: string;
  sourceNodeFn: (context: ActivityDashboardContext) => CGTypes.OutputNode;

  // If provided, will render as a mini bar chart
  graphParamsFn?: (context: ActivityDashboardContext) => SourceParams;

  // Override the default formatter (JSON.stringify)
  formatter?: (result: any) => any;
}): React.FC => {
  return makeComp(
    props => {
      const context = useContext(ActivityDashboardContext);

      const {node} = HL.dereferenceVariables(
        args.sourceNodeFn(context),
        context.frame // TODO(np): Use PanelContext instead
      );
      const {result} = CGReact.useNodeValue(node);

      let graphContent: React.ReactNode = null;
      if (args.graphParamsFn) {
        const {table: partialTable, sourceNode: graphNode} = useMemo(
          () => args.graphParamsFn!(context),
          [context]
        );
        const dereffedGraphNode = useMemo(
          () => HL.dereferenceVariables(graphNode, context.frame).node,
          [graphNode, context.frame]
        );

        const config = useMemo(() => {
          let table = TableState.appendEmptyColumn(partialTable);
          const labelColId = table.order[table.order.length - 1];
          table = TableState.appendEmptyColumn(table);
          const detailColId = table.order[table.order.length - 1];
          table = TableState.appendEmptyColumn(table);
          const tooltipColId = table.order[table.order.length - 1];
          return {
            table,
            dims: {
              x: table.order[0],
              y: table.order[2],
              color: table.order[1],
              label: labelColId,
              detail: detailColId,
              tooltip: tooltipColId,
            },
            title: '',
            axisSettings: {
              x: {
                scale: {
                  domain: domainFromDateWindow(
                    context.startDate,
                    context.endDate
                  ),
                },
                noLabels: true,
                noTicks: true,
                noTitle: true,
              },
              y: {
                noLabels: true,
                noTicks: true,
                noTitle: true,
              },
            },
            vegaOverlay: {
              mark: {
                type: 'bar',
                cornerRadius: 6,
              },
            },
          };
        }, [context, partialTable]);

        graphContent = (
          <S.MiniGraphWrapper>
            <CGPanel
              inputPath={dereffedGraphNode}
              spec={PanelSuperPlotSpec}
              config={config}
            />
          </S.MiniGraphWrapper>
        );
      }

      return (
        <S.ReadoutPanel>
          <S.ReadoutTitle>{args.title}</S.ReadoutTitle>
          <S.ReadoutValue>
            {args.formatter ? args.formatter(result) : JSON.stringify(result)}
          </S.ReadoutValue>
          {graphContent}
        </S.ReadoutPanel>
      );
    },
    {
      id: args.id,
    }
  );
};

const MiniRuns = makeMiniPanel({
  id: 'MiniRuns',
  title: 'Runs',
  sourceNodeFn: cgPaths().countRuns,
  graphParamsFn: cgPaths().tableRunsByProjectOverTime,
});

const MiniReports = makeMiniPanel({
  id: 'MiniReports',
  title: 'Reports',
  sourceNodeFn: cgPaths().countReports,
  graphParamsFn: cgPaths().tableReportsByProjectOverTime,
});

const MiniArtifactVersions = makeMiniPanel({
  id: 'MiniArtifactVersions',
  title: 'Artifact versions',
  sourceNodeFn: cgPaths().countArtifactVersions,
  graphParamsFn: cgPaths().tableArtifactVersionsByTypeOverTime,
});

const MiniComputeTime = makeMiniPanel({
  id: 'MiniComputeTime',
  title: 'Compute hours',
  sourceNodeFn: cgPaths().sumComputeTime,
  graphParamsFn: cgPaths().tableComputeTimeByProjectOverTime,
  formatter: result => result,
  // moment
  //   .utc(moment.duration(result, 'seconds').asMilliseconds())
  //   .format('H[h] m[m]'),
});

const MiniStorage = makeMiniPanel({
  id: 'MiniStorage',
  title: 'Total storage',
  sourceNodeFn: cgPaths().sumStorage,
  graphParamsFn: cgPaths().tableStorageByProjectOverTime,
  formatter: result => {
    if (typeof result !== 'number') {
      return '';
    }
    if (result > 10e9) {
      return `${(result / 10e9).toFixed(1)}GB`;
    } else if (result > 10e6) {
      return `${(result / 10e6).toFixed(1)}MB`;
    } else if (result > 10e3) {
      return `${(result / 10e3).toFixed(1)}KB`;
    }
    return `${result}B`;
  },
});

// Filter controls
interface DateSelectorProps {
  maxWindowSize: moment.DurationInputObject;
  startDate: Date;
  endDate: Date;
  setStartDate(date: Date): void;
  setEndDate(date: Date): void;
}

const DateSelector: React.FC<DateSelectorProps> = makeComp(
  ({maxWindowSize, startDate, setStartDate, endDate, setEndDate}) => {
    const commonProps = {
      closeOnSelect: true,
      className: 'filter-list__value',
      timeFormat: false,
    };
    const maxWindowDuration = moment.duration(maxWindowSize);
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    return (
      <S.DatePicker>
        <S.DatePickerGroup>
          <label>From</label>
          <Datetime
            {...commonProps}
            value={startDate}
            onChange={(when: moment.Moment) => {
              if (!moment.isMoment(when)) {
                return;
              }
              setStartDate(when.toDate());
              const delta = moment.duration(endMoment.diff(when));
              if (delta.asMilliseconds() > maxWindowDuration.asMilliseconds()) {
                setEndDate(when.add(maxWindowDuration).toDate());
              }
            }}
            isValidDate={(when: moment.Moment) =>
              when.isBefore(endMoment) && when.isBefore(moment.now())
            }
          />
        </S.DatePickerGroup>
        <S.DatePickerGroup>
          <label>to</label>
          <Datetime
            {...commonProps}
            value={endDate} // default = now
            onChange={(when: any) => {
              if (!moment.isMoment(when)) {
                return;
              }
              setEndDate(when.toDate());
              const delta = moment.duration(when.diff(startMoment));
              if (delta.asMilliseconds() > maxWindowDuration.asMilliseconds()) {
                setStartDate(when.subtract(maxWindowDuration).toDate());
              }
            }}
            isValidDate={(when: moment.Moment) =>
              when.isAfter(startMoment) && when.isBefore(moment.now())
            }
          />
        </S.DatePickerGroup>
      </S.DatePicker>
    );
  },
  {id: 'DateSelector', memo: true}
);

interface UserFilterProps {
  users: Array<{name: string; username: string}>;
  filter: string[]; // [] = all users
  setUserFilter(filter: string[]): void;
}

const UserFilter: React.FC<UserFilterProps> = makeComp(
  props => {
    const {users, filter, setUserFilter} = props;

    const options = useMemo(
      () =>
        users.map(u => ({
          text: u.name,
          value: u.username,
        })),
      [users]
    );

    return (
      <S.UserFilter>
        <ModifiedDropdown
          icon={<WBIcon name="username" />}
          multiple
          selection
          options={options}
          placeholder="All users"
          onChange={(_, {value}) => {
            if (Array.isArray(value)) {
              setUserFilter(value as string[]);
            } else {
              setUserFilter([]);
            }
          }}
          value={filter}
        />
      </S.UserFilter>
    );
  },
  {id: 'UserFilter', memo: true}
);

const DEFAULT_PAGE_SIZE: () => number = () => 5;
const DEFAULT_USER_FILTER: () => string[] = () => [];
const DEFAULT_START_DATE: (override: moment.DurationInputObject) => Date = ov =>
  moment().subtract(ov).toDate();
// moment().subtract(1, 'month').toDate();
const DEFAULT_END_DATE: () => Date = () => new Date();

// ActivityDashboardContext is used to manage page state including filter selections
// Nominally, CG expressions receive their parameters through the frame,
// but we need to keep raw copies of filter selections for certain optimizations
interface ActivityDashboardContext {
  startDate: Date;
  endDate: Date;
  userFilter: string[];
  pageSize: number;

  frame: any;
}

export const ActivityDashboardContext =
  React.createContext<ActivityDashboardContext>({
    startDate: new Date(),
    endDate: new Date(),
    userFilter: [],
    pageSize: 0,
    frame: {},
  });

// Put it all together!
interface ActivityDashboardProps {
  // Visual
  heading: any;
  subtitle: string;
  extraHeading?: any;

  // Top controls
  userFilterOpts: Array<{name: string; username: string}>;
  dateWindowSize?: moment.DurationInputObject;

  // CG paths
  // TODO(np): Type these
  projects: any;
  reports: any;
  artifacts: any;
}

const ActivityDashboard: React.FC<ActivityDashboardProps> = makeComp(
  props => {
    const [userFilter, setUserFilter] = useState<UserFilterProps['filter']>(
      DEFAULT_USER_FILTER()
    );

    // const dateWindowSize = props.dateWindowSize ?? {month: 3};
    const dateWindowSize = props.dateWindowSize ?? {week: 2};

    const [startDate, setStartDate] = useState(
      DEFAULT_START_DATE(dateWindowSize)
    );
    const [endDate, setEndDate] = useState(DEFAULT_END_DATE());

    const [pageSize /*, setPageSize*/] = useState(DEFAULT_PAGE_SIZE());

    const scrollPos = UtilHooks.useScrollPosition();

    const contextValue = useMemo(() => {
      const runFilter = filterString(startDate, endDate, userFilter);
      const ctxValue = {
        userFilter,
        startDate,
        endDate,
        pageSize,
        frame: {
          projects: props.projects,
          reports: props.reports,
          artifacts: props.artifacts,
          startDate: Op.constDate(startDate),
          endDate: Op.constDate(endDate),
          userFilter: Op.constStringList(userFilter),
          runFilter: Op.constString(runFilter),
        },
      };

      return ctxValue;
    }, [
      startDate,
      endDate,
      userFilter,
      pageSize,
      props.projects,
      props.reports,
      props.artifacts,
    ]);

    return (
      <S.Wrapper>
        <S.TopSection>
          <S.HeaderRow>
            <S.MainHeading>
              <WBIcon name="office" />
              &nbsp;
              {props.heading}
            </S.MainHeading>
            {/* <Loader active inline className="cg-loader" /> */}
          </S.HeaderRow>
          <S.Subtitle>{props.subtitle}</S.Subtitle>
          <S.Hint>
            This dashboard is in beta. The date filter is limited to a maximum
            width of <b>{moment.duration(dateWindowSize).humanize()}</b>.
          </S.Hint>
          {props.extraHeading}
        </S.TopSection>
        <S.FilterSection scrolled={scrollPos > 61 + 52}>
          <UserFilter
            filter={userFilter}
            setUserFilter={setUserFilter}
            users={props.userFilterOpts}
          />
          <DateSelector
            maxWindowSize={dateWindowSize}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Loader active inline className="cg-loader" />
        </S.FilterSection>
        <ActivityDashboardContext.Provider value={contextValue}>
          <PanelContextProvider newVars={contextValue.frame}>
            <S.Section>
              <S.Header>Bird's eye view</S.Header>
              <S.SectionContent>
                <MiniRuns />
                <MiniComputeTime />
                <MiniReports />
                <MiniArtifactVersions />
                <MiniStorage />
              </S.SectionContent>
            </S.Section>
            <S.Section>
              <S.Header>Team member spotlight</S.Header>
              <S.SectionContent>
                <MostRunsUserTable />
                <WeeklyRunsByUserPlot />

                <ComputeTimePerUserTable />
                <WeeklyComputeTimeByUserPlot />

                <MostReportsByUserTable />
                <WeeklyReportsByUserPlot />
              </S.SectionContent>
            </S.Section>
            <S.Section>
              <S.Header>Project productivity</S.Header>
              <S.SectionContent>
                <MostViewsReportTable />
                <MostUsedArtifactsTable />
              </S.SectionContent>
              <S.SectionContent>
                <MostRunsProjectTable />
                <WeeklyRunsByProjectPlot />
                <WeeklyReportsByProjectPlot />
              </S.SectionContent>
            </S.Section>
            <S.Section>
              <S.Header>Cost management</S.Header>
              <S.Subheader>Where is your compute spend going?</S.Subheader>
              <S.SectionContent>
                <ComputeTimePerProjectTable />
                <WeeklyComputeTimeByProjectPlot />
                <LongestComputeTimeRunsTable />
              </S.SectionContent>
            </S.Section>
          </PanelContextProvider>
        </ActivityDashboardContext.Provider>
        <S.BottomSection />
      </S.Wrapper>
    );
  },
  {id: 'ActivityDashboard'}
);

export default ActivityDashboard;
