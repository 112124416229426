import * as S from './NewProject.styles';

import * as Prism from 'prismjs';
import React, {FC, useState, useEffect, useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {Message, DropdownProps, InputOnChangeData} from 'semantic-ui-react';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';

import {History} from 'history';
import '../css/NewProject.less';
import {PROJECT_UPSERT, UpdateProjectMutationFn} from '../graphql/models';
import {Team} from '../types/graphql';
import {setDocumentTitle} from '../util/document';
import makeComp from '../util/profiler';
import {useViewer} from '../state/viewer/hooks';
import WandbLoader from '../components/WandbLoader';
import {AccessOptions} from '../util/permissions';
import {useAllProjectsQuery} from '../generated/graphql';
import {getQueryStringParamValue} from '../util/url';
import {urlPrefixed} from '../config';

interface NewProjectProps {
  upsertProject: UpdateProjectMutationFn;
  history: History;
}

// Export dumb component for testing purposes
export const NewProject: FC<NewProjectProps> = makeComp(
  ({upsertProject, history}) => {
    useEffect(() => {
      setDocumentTitle('Create a new project');
    }, []);

    useEffect(() => {
      Prism.highlightAll();
    });

    const [submitting, setSubmitting] = useState(false);
    const [projectEntity, setProjectEntity] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectAccess, setProjectAccess] =
      useState<AccessOptions>('ENTITY_READ');

    const {data} = useAllProjectsQuery({
      variables: {entityName: projectEntity, first: 100, order: 'name'},
      fetchPolicy: 'cache-and-network',
      skip: !projectEntity,
    });
    const projects = data?.projects?.edges.map(p => p.node) ?? [];

    const doesProjectNameExist = useCallback(() => {
      const existingProjectNames = projects.map(p => p!.name);
      return _.includes(existingProjectNames, projectName);
    }, [projectName, projects]);

    const canSubmit = useCallback(() => {
      if (doesProjectNameExist()) {
        return false;
      }
      return !!projectName && !submitting;
    }, [doesProjectNameExist, projectName, submitting]);

    const projectNameExists = projectName !== '' && doesProjectNameExist();

    const viewer = useViewer();

    const teams: Team[] | undefined = useMemo(
      () => viewer?.teams.edges.map(e => e.node as unknown as Team),
      [viewer]
    );
    const teamOptions = useMemo(
      () =>
        teams?.map(t => ({
          text: t.name,
          value: t.name,
        })),
      [teams]
    );

    useEffect(() => {
      if (teamOptions == null || projectEntity !== '') {
        return;
      }
      const entityNameFromViewer =
        viewer?.defaultEntity?.name ?? viewer?.username;
      const defaultEntityName =
        getQueryStringParamValue('entityName') || entityNameFromViewer;
      const defaultOption = teamOptions.find(
        o => o.value === defaultEntityName
      );
      if (defaultOption) {
        setProjectEntity(defaultOption.value);
      }
    }, [viewer, teamOptions, projectEntity]);

    const submit = useCallback(async () => {
      setSubmitting(true);
      try {
        const res = await upsertProject({
          description: projectDescription,
          access: projectAccess,
          name: projectName,
          entityName: projectEntity,
        });
        const project = res.data.upsertModel.project;
        const projectPath = `/${project.entityName}/${project.name}`;
        if (res.data.upsertModel.inserted) {
          // hacky? triggering whole page refresh to update projects
          window.location.assign(urlPrefixed(projectPath));
        } else {
          history.push(projectPath);
        }
      } catch (err) {
        let errMsg = 'error creating project';
        // user is creating a public project in an entity with private projects only
        const chosenTeam = teams?.find(t => t.name === projectEntity);
        if (
          projectAccess === 'USER_READ' &&
          chosenTeam?.defaultAccess === 'PRIVATE'
        ) {
          errMsg = `${projectEntity} requires projects to be private, please set project access to private.`;
        }
        console.error(errMsg, err);
        alert(errMsg);
        setSubmitting(false);
      }
    }, [
      teams,
      history,
      projectAccess,
      projectDescription,
      projectEntity,
      projectName,
      upsertProject,
    ]);

    if (viewer == null) {
      return <WandbLoader />;
    }

    return (
      <S.Page data-testid="new-project-page">
        <S.Content>
          <S.Header>Create a new project</S.Header>
          <S.Subheader>
            Use projects to organize and compare related experiments. For best
            performance, keep projects under 10,000 runs.
          </S.Subheader>
          {projectNameExists && (
            <S.ProjectExistsMessage info size="small">
              <Message.Header>
                A project named "{projectName}" already exists.
              </Message.Header>
              <Message.Content>
                <p>
                  Try another name, or{' '}
                  <Link to={`/${projectEntity}/${projectName}`}>
                    visit your existing project.
                  </Link>
                </p>
              </Message.Content>
            </S.ProjectExistsMessage>
          )}
          <S.FormRow>
            <S.FormItem inline noGrow>
              <S.Label>Entity</S.Label>
              <S.Dropdown
                value={projectEntity}
                options={teamOptions}
                onChange={(e: React.SyntheticEvent, {value}: DropdownProps) =>
                  setProjectEntity(value as string)
                }
              />
            </S.FormItem>
            <S.Slash>/</S.Slash>
            <S.FormItem inline>
              <S.Label>Project name</S.Label>
              <S.Input
                data-test="project-name-field"
                placeholder="gpt-4"
                value={projectName}
                onChange={(
                  e: React.SyntheticEvent,
                  {value}: InputOnChangeData
                ) => setProjectName(value)}
              />
            </S.FormItem>
          </S.FormRow>
          <S.FormRow>
            <S.FormItem>
              <S.Label>
                Description <S.Sublabel>(optional)</S.Sublabel>
              </S.Label>
              <S.Input
                placeholder="Text generation with transformers"
                value={projectDescription}
                onChange={(
                  e: React.SyntheticEvent,
                  {value}: InputOnChangeData
                ) => setProjectDescription(value)}
              />
            </S.FormItem>
          </S.FormRow>
          <S.FormRow>
            <S.FormItem>
              <S.Label>Access Controls</S.Label>
              <S.AccessRow onClick={() => setProjectAccess('ENTITY_READ')}>
                <S.AccessRowRadio active={projectAccess === 'ENTITY_READ'} />
                <S.AccessRowIcon name="lock" />
                Private: Only you can view and edit this project.
              </S.AccessRow>
              <S.AccessRow onClick={() => setProjectAccess('USER_READ')}>
                <S.AccessRowRadio active={projectAccess === 'USER_READ'} />
                <S.AccessRowIcon name="lock-open" />
                Public: anyone can view this project.
              </S.AccessRow>
            </S.FormItem>
          </S.FormRow>
          <S.FormRow justifyRight>
            <S.Button
              data-test="create-project"
              disabled={!canSubmit()}
              loading={submitting}
              onClick={submit}>
              Create project
            </S.Button>
          </S.FormRow>
        </S.Content>
      </S.Page>
    );
  },
  {id: 'NewProject', memo: true}
);

const withMutations = graphql(PROJECT_UPSERT, {
  props: ({mutate}) => ({
    upsertProject: (variables: any) =>
      mutate!({
        variables,
      }),
  }),
}) as any;

export default withMutations(connect()(NewProject));
