import * as S from './weave-panels.styles';

import React from 'react';
import {useCallback, useMemo} from 'react';
import {BlockWrapper} from './drag-drop';
import {Element, Node, Editor, Transforms} from 'slate';
import {RenderElementProps, useEditor, ReactEditor} from 'slate-react';
import {
  RootQueryPanelConfig,
  RootQueryPanel,
} from '../../Panel2/RootQueryPanel';

export interface WeavePanel extends Element {
  type: 'weave-panel';
  config: RootQueryPanelConfig;
}

export const isWeavePanel = (node: Node): node is WeavePanel =>
  node.type === 'weave-panel';

export const WeavePanelElement: React.FC<
  RenderElementProps & {
    element: WeavePanel;
  }
> = ({attributes, element, children}) => {
  const editor = useEditor();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const config = useMemo(() => element.config ?? {}, [element.config]);

  const updateConfig = useCallback(
    (newConfig: Partial<RootQueryPanelConfig>) => {
      Transforms.setNodes(
        editor,
        {config: {...config, ...newConfig}},
        {at: ReactEditor.findPath(editor, element)}
      );
    },
    [editor, element, config]
  );

  // We need beforeinput to be handled normally for the embedded contentEditables to work.
  // If we don't stopPropagation, Slate will preventDefault it.
  // Can't use React's onBeforeInput because it's fake.
  React.useEffect(() => {
    const el = wrapperRef.current;
    if (!el) {
      return;
    }

    const onDOMBeforeInput = (e: Event) => {
      e.stopPropagation();
    };

    el.addEventListener('beforeinput', onDOMBeforeInput);

    return () => {
      el.removeEventListener('beforeinput', onDOMBeforeInput);
    };
  }, []);

  return (
    <BlockWrapper attributes={attributes} element={element} disableClickSelect>
      <S.WeavePanelWrapper
        ref={wrapperRef}
        contentEditable={false}
        onMouseUp={e => {
          Transforms.deselect(editor);
        }}
        onKeyDown={e => {
          // Slate will preventDefault onKeyDown if we don't stopPropagation,
          // making users unable to move the cursor
          e.stopPropagation();
        }}>
        <RootQueryPanel config={config} updateConfig={updateConfig} />
        {children}
      </S.WeavePanelWrapper>
    </BlockWrapper>
  );
};

export const withWeavePanels = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;
  editor.isVoid = element => (isWeavePanel(element) ? true : isVoid(element));

  return editor;
};
