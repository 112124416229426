import * as _ from 'lodash';

import {HistorySpec} from '../../types/run';
import * as Obj from '@wandb/cg/browser/utils/obj';
import * as Update from '../../util/update';
import * as Types from './types';

// Does an immutable update to history, given historySpecs (the query) and
// historyResult (the result of that query).
//
// Since runs are normalized, but different queries typically request different
// parts of history, we store all the history spec along side the result for each
// history spec that we have. Selector code uses getQueryHistoryResults below
// to reverse this.
export function updateRunHistory(
  history: Types.NormalizedSampledHistory,
  queryResult: Types.NormalizedSampledHistory,
  sourceHistorySpecs: HistorySpec[] // the history specs we're actually going to update, which are subsets of historySpecs
): Types.NormalizedSampledHistory {
  let result = history;
  // Iterate through each spec in the query, and the rows returned for it
  for (const {spec: querySpec, rows: queryRows} of queryResult) {
    // sourceHistorySpecs are the historySpecs for the source queries, that
    // resulted in the query for which we're handling queryResult. We store
    // normalizedHistory in terms of source queries, not merged queries. So
    // we put the current queryResult rows at each sourceHistorySpec that
    // is a subset of the current queryResult spec. In other words, we
    // unmerge the history results here.
    for (const spec of sourceHistorySpecs) {
      if (specIsSubset(querySpec, spec)) {
        const rows = queryRows.map(r => {
          const subRow: {[key: string]: any} = {};
          for (const k of spec.keys) {
            subRow[k] = r[k];
          }
          return subRow;
        });
        const historyItemIndex = findHistoryItemIndex(history, spec);
        if (historyItemIndex === -1) {
          result = [...result, {spec, rows}];
        } else {
          const historyItem = history[historyItemIndex];
          const prevRows = historyItem.rows;
          if (prevRows.length !== rows.length) {
            result = Update.updateArrayIndex(result, historyItemIndex, {
              ...historyItem,
              rows,
            });
          } else if (rows.length > 0) {
            const prevLastStep = prevRows[prevRows.length - 1]._step;
            const curLastStep = rows[rows.length - 1]._step;
            if (prevLastStep == null || curLastStep == null) {
              // We don't have last step for both, so we just update.
              result = Update.updateArrayIndex(result, historyItemIndex, {
                ...historyItem,
                rows,
              });
            } else if (prevLastStep !== curLastStep) {
              // We do have a last step for both, so we only update if they differ
              // Note this attempt to update less frequently won't be very effect with
              // non-deterministic sampling.
              result = Update.updateArrayIndex(result, historyItemIndex, {
                ...historyItem,
                rows,
              });
            }
          }
        }
      }
    }
  }
  return result;
}

// We store normalized histories for the run (for all the queries we have
// that have asked for history for this run). Here we map back to just
// the result for the specific query that we're considering.
export function getQueryHistoryResults(
  history: Types.NormalizedSampledHistory,
  historySpecs: HistorySpec[]
) {
  return historySpecs
    .map(spec => history[findHistoryItemIndex(history, spec)])
    .filter(Obj.notEmpty)
    .map(hi => hi.rows);
}

function specIsSubset(spec: HistorySpec, testSpec: HistorySpec) {
  return (
    spec.samples === testSpec.samples &&
    spec.minStep === testSpec.minStep &&
    spec.maxStep === testSpec.maxStep &&
    testSpec.keys.length === _.intersection(spec.keys, testSpec.keys).length
  );
}

function findHistoryItemIndex(
  history: Types.NormalizedSampledHistory,
  spec: HistorySpec
): number {
  return _.findIndex(history, hi => _.isEqual(hi.spec, spec));
}
