// THIS IS A GENERATED FILE, DO NOT EDIT IT!
/* eslint-disable import/first */
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '../state/graphql/apolloWithAbort';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  JSONString: any;
  JSON: any;
  Duration: number;
  Int64: any;
};





export type Query = {
  __typename?: 'Query';
  models?: Maybe<ProjectConnection>;
  model?: Maybe<Project>;
  viewer?: Maybe<User>;
  user?: Maybe<User>;
  entity?: Maybe<Entity>;
  users?: Maybe<UserConnection>;
  events?: Maybe<EventConnection>;
  project?: Maybe<Project>;
  projects?: Maybe<ProjectConnection>;
  benchmarks?: Maybe<ProjectConnection>;
  publishedProjects?: Maybe<ProjectConnection>;
  entities?: Maybe<EntityConnection>;
  trendingRepos?: Maybe<RowConnection>;
  dataFrame?: Maybe<RowConnection>;
  dataFrameSchema: Array<Scalars['JSON']>;
  task?: Maybe<Task>;
  discussionThread?: Maybe<DiscussionThread>;
  discussionComment?: Maybe<DiscussionComment>;
  view?: Maybe<View>;
  views?: Maybe<ViewConnection>;
  publicViews?: Maybe<ViewConnection>;
  singletonView?: Maybe<View>;
  featuredReports?: Maybe<View>;
  galleryTagSections: Scalars['JSONString'];
  highQualityUserProfiles?: Maybe<View>;
  serverInfo?: Maybe<ServerInfo>;
  plans: Array<Maybe<Plan>>;
  organizations: OrganizationConnection;
  organization?: Maybe<Organization>;
  customChart?: Maybe<CustomChart>;
  customCharts?: Maybe<CustomChartConnection>;
  artifact?: Maybe<Artifact>;
  localLicenses: Array<Maybe<LocalLicense>>;
  publicImageUploadUrl?: Maybe<PublicImageUploadInfo>;
  invite?: Maybe<Invite>;
  newsletterSubscriptions?: Maybe<NewsletterSubscriptionConnection>;
  clientIDMapping?: Maybe<ClientIdMapping>;
  artifactSequence?: Maybe<ArtifactSequence>;
  instance?: Maybe<WbInstance>;
};


export type QueryModelsArgs = {
  entityName?: Maybe<Scalars['String']>;
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModelArgs = {
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
};


export type QueryViewerArgs = {
  entityName?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
};


export type QueryEntityArgs = {
  name?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['Boolean']>;
};


export type QueryUsersArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  usernames?: Maybe<Array<Scalars['String']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryEventsArgs = {
  entityName?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProjectArgs = {
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
};


export type QueryProjectsArgs = {
  entityName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBenchmarksArgs = {
  entityName: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPublishedProjectsArgs = {
  entityName: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEntitiesArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTrendingReposArgs = {
  from?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDataFrameArgs = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  dataFrameKeys: Array<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDataFrameSchemaArgs = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  dataFrameKeys: Array<Scalars['String']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryDiscussionThreadArgs = {
  id: Scalars['ID'];
};


export type QueryDiscussionCommentArgs = {
  id: Scalars['ID'];
};


export type QueryViewArgs = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};


export type QueryViewsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPublicViewsArgs = {
  type?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySingletonViewArgs = {
  type: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCustomChartArgs = {
  id: Scalars['ID'];
};


export type QueryCustomChartsArgs = {
  type: Scalars['String'];
  entity?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArtifactArgs = {
  id: Scalars['ID'];
};


export type QueryLocalLicensesArgs = {
  expired?: Maybe<Scalars['Boolean']>;
};


export type QueryInviteArgs = {
  key: Scalars['String'];
};


export type QueryNewsletterSubscriptionsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClientIdMappingArgs = {
  clientID: Scalars['ID'];
};


export type QueryArtifactSequenceArgs = {
  id: Scalars['ID'];
};






export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PublicImageUploadInfo = {
  __typename?: 'PublicImageUploadInfo';
  uploadUrl: Scalars['String'];
  imageUrl: Scalars['String'];
};

export enum CloudProvider {
  Aws = 'AWS',
  Gcp = 'GCP',
  Azure = 'AZURE',
  Minio = 'MINIO'
}

export type CloudRegion = {
  __typename?: 'CloudRegion';
  id: Scalars['ID'];
  provider: CloudProvider;
  region: Scalars['String'];
};

export enum UserProjectRestriction {
  Owned = 'OWNED',
  Contributed = 'CONTRIBUTED'
}

export type LocalVersionInfo = {
  __typename?: 'LocalVersionInfo';
  outOfDate: Scalars['Boolean'];
  latestVersionString: Scalars['String'];
  currentVersionString: Scalars['String'];
};

export type ServerInfo = {
  __typename?: 'ServerInfo';
  pollingOK: Scalars['Boolean'];
  availableCloudRegions: Array<CloudRegion>;
  defaultCloudRegion: CloudRegion;
  slackClientID: Scalars['String'];
  cliVersionInfo: Scalars['JSON'];
  latestLocalVersionInfo?: Maybe<LocalVersionInfo>;
  messageOfTheDay?: Maybe<Scalars['String']>;
  isGoogleIap: Scalars['Boolean'];
  userLimitReached: Scalars['Boolean'];
  licenseExpired: Scalars['Boolean'];
  frontendHost: Scalars['String'];
  hasLocalLicense: Scalars['Boolean'];
  exposesExplicitRunQueueAckPath: Scalars['Boolean'];
};

export enum UserAccountType {
  User = 'USER',
  Service = 'SERVICE',
  Anonymous = 'ANONYMOUS'
}

export type ProjectIdentifierInput = {
  Name?: Maybe<Scalars['String']>;
  EntityName?: Maybe<Scalars['String']>;
};

export enum PlanType {
  Primary = 'PRIMARY',
  Storage = 'STORAGE',
  Reference = 'REFERENCE'
}

export enum PlanVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  Deprecated = 'DEPRECATED'
}

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  name: Scalars['String'];
  stripePlanId?: Maybe<Scalars['String']>;
  maxSeats: Scalars['Int'];
  defaultPrivileges: Scalars['JSON'];
  planType: PlanType;
  planVisibility: PlanVisibility;
  unitPrice?: Maybe<Scalars['Int']>;
  billingInterval?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  ndbId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  photoUploadUrl: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
  defaultEntityId?: Maybe<Scalars['Int']>;
  accountType?: Maybe<UserAccountType>;
  loggedInAt?: Maybe<Scalars['DateTime']>;
  defaultEntity?: Maybe<Entity>;
  userEntity?: Maybe<Entity>;
  userInfo?: Maybe<Scalars['JSON']>;
  limits?: Maybe<Scalars['JSON']>;
  onboardingSteps?: Maybe<Scalars['JSON']>;
  private: Scalars['Boolean'];
  signupRequired: Scalars['Boolean'];
  stripeSubscriptionInfo?: Maybe<Scalars['JSON']>;
  newsletterSubscription?: Maybe<NewsletterSubscription>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  projects?: Maybe<ProjectConnection>;
  views?: Maybe<ViewConnection>;
  starredViews?: Maybe<ViewConnection>;
  apiKeys?: Maybe<ApiKeyConnection>;
  apiKey?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['JSONString']>;
  code?: Maybe<Scalars['String']>;
  teams?: Maybe<EntityConnection>;
  organizations: Array<Organization>;
  dailyRunCount: Array<Scalars['Int']>;
  totalRuns: Scalars['Int'];
  runs?: Maybe<RunConnection>;
};


export type UserProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  restrictTo?: Maybe<UserProjectRestriction>;
  linkedBenchmark?: Maybe<ProjectIdentifierInput>;
};


export type UserViewsArgs = {
  viewType?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserStarredViewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserApiKeysArgs = {
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTeamsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserDailyRunCountArgs = {
  limit: Scalars['Int'];
};


export type UserRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node?: Maybe<User>;
  cursor: Scalars['String'];
};

export type Initiator = Run | User;

export enum Aggregation {
  Max = 'MAX',
  Min = 'MIN',
  Average = 'AVERAGE',
  Sum = 'SUM'
}

export type AggregationKey = {
  key: Scalars['String'];
  aggregation: Aggregation;
};

export type GroupAggregation = {
  default: Aggregation;
  custom: Array<AggregationKey>;
};

export type Project = Node & {
  __typename?: 'Project';
  createdAt: Scalars['DateTime'];
  public?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  ndbId?: Maybe<Scalars['ID']>;
  entityId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  access?: Maybe<Scalars['String']>;
  repo?: Maybe<Scalars['String']>;
  dockerImage?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Scalars['String']>;
  subGroupPath?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Int']>;
  storageBytes: Scalars['Int64'];
  computeHours: Scalars['Duration'];
  runs?: Maybe<RunConnection>;
  buckets?: Maybe<RunConnection>;
  entity: Entity;
  user?: Maybe<User>;
  allViews?: Maybe<ViewConnection>;
  run?: Maybe<Run>;
  runGroup?: Maybe<RunGroup>;
  bucket?: Maybe<Run>;
  runCount: Scalars['Int'];
  runOutputArtifactsCount: Scalars['Int'];
  pathCounts?: Maybe<Scalars['JSONString']>;
  valueCounts?: Maybe<Scalars['JSONString']>;
  runKeySuggestions: Array<RunKeySuggestion>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  entityName: Scalars['String'];
  requestSubscribe?: Maybe<Scalars['Boolean']>;
  sweeps?: Maybe<SweepConnection>;
  sweep?: Maybe<Sweep>;
  bucketCount?: Maybe<Scalars['Int']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  totalSweeps: Scalars['Int'];
  totalRuns?: Maybe<Scalars['Int']>;
  totalUsers?: Maybe<Scalars['Int']>;
  lastActive?: Maybe<Scalars['DateTime']>;
  totalRunTime?: Maybe<Scalars['Float']>;
  totalRunsWithGroup: Scalars['Int'];
  totalRunsWithJobType: Scalars['Int'];
  tags: Array<Tag>;
  tagCounts: Array<TagCount>;
  isBenchmark: Scalars['Boolean'];
  gitHubSubmissionRepo?: Maybe<Scalars['String']>;
  linkedBenchmark?: Maybe<Project>;
  benchmarkRuns?: Maybe<BenchmarkRunConnection>;
  benchmarkSubmissions?: Maybe<BenchmarkRunConnection>;
  benchmarkDiscussionThreads: DiscussionThreadConnection;
  isPublished: Scalars['Boolean'];
  fields: ProjectFieldConnection;
  artifactType?: Maybe<ArtifactType>;
  artifactTypes: ArtifactTypeConnection;
  artifact?: Maybe<Artifact>;
  parameterImportance: Scalars['JSON'];
  runQueue?: Maybe<RunQueue>;
  runQueues: Array<RunQueue>;
};


export type ProjectRunsArgs = {
  jobKey?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupAggregation?: Maybe<GroupAggregation>;
  groupLevel?: Maybe<Scalars['Int']>;
  historyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBucketsArgs = {
  jobKey?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectAllViewsArgs = {
  userId?: Maybe<Scalars['Int']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectRunArgs = {
  desc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type ProjectRunGroupArgs = {
  name: Scalars['String'];
};


export type ProjectBucketArgs = {
  desc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  missingOk?: Maybe<Scalars['Boolean']>;
};


export type ProjectRunCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunOutputArtifactsCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectPathCountsArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectValueCountsArgs = {
  keyPath?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunKeySuggestionsArgs = {
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectSweepsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type ProjectSweepArgs = {
  sweepName: Scalars['String'];
};


export type ProjectTagCountsArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectBenchmarkRunsArgs = {
  state: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBenchmarkSubmissionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBenchmarkDiscussionThreadsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectFieldsArgs = {
  types?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectArtifactTypeArgs = {
  name: Scalars['String'];
};


export type ProjectArtifactTypesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectArtifactArgs = {
  name: Scalars['String'];
};


export type ProjectParameterImportanceArgs = {
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  target: Scalars['String'];
  parameters?: Maybe<Array<Scalars['String']>>;
  runParameters?: Maybe<Array<Scalars['String']>>;
};


export type ProjectRunQueueArgs = {
  name: Scalars['String'];
};

export enum RunQueueAccessType {
  Project = 'PROJECT',
  User = 'USER'
}

export type RunQueue = {
  __typename?: 'RunQueue';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated Executor state tracked in LaunchAgent.AgentStatus */
  executorInfo: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  access: RunQueueAccessType;
  runQueueItems: RunQueueItemConnection;
};


export type RunQueueRunQueueItemsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum RunQueueItemState {
  Pending = 'PENDING',
  Leased = 'LEASED',
  Claimed = 'CLAIMED'
}

export type RunQueueItemConnection = {
  __typename?: 'RunQueueItemConnection';
  pageInfo: PageInfo;
  edges: Array<RunQueueItemEdge>;
};

export type RunQueueItemEdge = {
  __typename?: 'RunQueueItemEdge';
  node: RunQueueItem;
  cursor: Scalars['String'];
};

export type RunQueueItem = {
  __typename?: 'RunQueueItem';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  runSpec: Scalars['JSON'];
  state: RunQueueItemState;
  associatedRunId?: Maybe<Scalars['String']>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  pageInfo: PageInfo;
  edges: Array<ProjectEdge>;
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  node?: Maybe<Project>;
  cursor: Scalars['String'];
};

export type UsageStatConnection = {
  __typename?: 'UsageStatConnection';
  pageInfo: PageInfo;
  edges: Array<UsageStatEdge>;
};

export type UsageStatEdge = {
  __typename?: 'UsageStatEdge';
  node: UsageStat;
  cursor: Scalars['String'];
};

export type UsageStat = {
  __typename?: 'UsageStat';
  date?: Maybe<Scalars['DateTime']>;
  stat?: Maybe<Scalars['Float']>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  colorIndex: Scalars['Int'];
};

export type TagCount = {
  __typename?: 'TagCount';
  name: Scalars['String'];
  count: Scalars['Int'];
};

export type ProjectField = {
  __typename?: 'ProjectField';
  path: Scalars['String'];
  type: Scalars['String'];
};

export type ProjectFieldConnection = {
  __typename?: 'ProjectFieldConnection';
  pageInfo: PageInfo;
  edges: Array<ProjectFieldEdge>;
};

export type ProjectFieldEdge = {
  __typename?: 'ProjectFieldEdge';
  node: ProjectField;
  cursor: Scalars['String'];
};

export enum DotBehavior {
  KeepAllDotValues = 'KEEP_ALL_DOT_VALUES',
  SkipFirstDotValue = 'SKIP_FIRST_DOT_VALUE'
}

export type RunGroup = {
  __typename?: 'RunGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  project: Project;
};

export type Run = Node & BucketType & {
  __typename?: 'Run';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  projectId: Scalars['ID'];
  name: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  jobType?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  sweepName?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONString']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  wandbConfig?: Maybe<Scalars['JSONString']>;
  systemMetrics?: Maybe<Scalars['JSONString']>;
  state?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  exitcode?: Maybe<Scalars['Float']>;
  displayName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Scalars['String']>>;
  tagColors: Array<Tag>;
  defaultColorIndex?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  model?: Maybe<Project>;
  id: Scalars['ID'];
  ndbId?: Maybe<Scalars['ID']>;
  sweep?: Maybe<Sweep>;
  agent?: Maybe<Agent>;
  fileCount?: Maybe<Scalars['Int']>;
  github?: Maybe<Scalars['String']>;
  storageBytes: Scalars['Int64'];
  files?: Maybe<FileConnection>;
  runInfo?: Maybe<RunInfo>;
  logLines?: Maybe<LogLineConnection>;
  outputArtifacts?: Maybe<ArtifactConnection>;
  inputArtifacts?: Maybe<InputArtifactConnection>;
  history: Array<Scalars['String']>;
  sampledHistory: Array<Scalars['JSON']>;
  historyKeys?: Maybe<Scalars['JSON']>;
  events: Array<Scalars['String']>;
  logLineCount?: Maybe<Scalars['Int']>;
  historyLineCount?: Maybe<Scalars['Int']>;
  eventsLineCount?: Maybe<Scalars['Int']>;
  historyTail?: Maybe<Scalars['JSONString']>;
  eventsTail?: Maybe<Scalars['JSONString']>;
  patch?: Maybe<Scalars['String']>;
  requestSubscribe?: Maybe<Scalars['Boolean']>;
  shouldStop?: Maybe<Scalars['Boolean']>;
  framework?: Maybe<Scalars['String']>;
  groupCounts?: Maybe<Array<Scalars['Int']>>;
  exampleTableColumns?: Maybe<Scalars['JSONString']>;
  exampleTableTypes?: Maybe<Scalars['JSONString']>;
  exampleTable?: Maybe<Scalars['JSONString']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  debug?: Maybe<Scalars['Boolean']>;
  failed?: Maybe<Scalars['Boolean']>;
  stopped?: Maybe<Scalars['Boolean']>;
  running?: Maybe<Scalars['Boolean']>;
  servicesAvailable?: Maybe<RunServicesAvailable>;
  benchmarkRun?: Maybe<BenchmarkRun>;
  computeSeconds: Scalars['Duration'];
};


export type RunConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  dotBehavior?: Maybe<DotBehavior>;
};


export type RunSummaryMetricsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type RunWandbConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type RunFilesArgs = {
  pattern?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RunLogLinesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RunOutputArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type RunInputArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type RunHistoryArgs = {
  samples?: Maybe<Scalars['Int']>;
  minStep?: Maybe<Scalars['Int64']>;
  maxStep?: Maybe<Scalars['Int64']>;
};


export type RunSampledHistoryArgs = {
  specs: Array<Scalars['JSONString']>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type RunEventsArgs = {
  samples?: Maybe<Scalars['Int']>;
};

export type RunKeySuggestion = {
  __typename?: 'RunKeySuggestion';
  value?: Maybe<Scalars['JSONString']>;
  displayValue?: Maybe<Scalars['JSONString']>;
  count: Scalars['Int'];
};

export type RunServicesAvailable = {
  __typename?: 'RunServicesAvailable';
  tensorboard?: Maybe<Scalars['Boolean']>;
};

export type RunInfo = {
  __typename?: 'RunInfo';
  program?: Maybe<Scalars['String']>;
  args: Array<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  python?: Maybe<Scalars['String']>;
  colab?: Maybe<Scalars['String']>;
  executable?: Maybe<Scalars['String']>;
  codeSaved?: Maybe<Scalars['Boolean']>;
  cpuCount?: Maybe<Scalars['Int']>;
  gpuCount?: Maybe<Scalars['Int']>;
  gpu?: Maybe<Scalars['String']>;
  git?: Maybe<GitInfo>;
};

export type GitInfo = {
  __typename?: 'GitInfo';
  remote?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
};

export type BucketType = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONString']>;
  framework?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  github?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  shouldStop?: Maybe<Scalars['Boolean']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  history: Array<Scalars['String']>;
  sweep?: Maybe<Sweep>;
  exampleTableColumns?: Maybe<Scalars['JSONString']>;
  exampleTableTypes?: Maybe<Scalars['JSONString']>;
  exampleTable?: Maybe<Scalars['JSONString']>;
  fileCount?: Maybe<Scalars['Int']>;
  logLines?: Maybe<LogLineConnection>;
  files?: Maybe<FileConnection>;
};


export type BucketTypeConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type BucketTypeSummaryMetricsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type BucketTypeHistoryArgs = {
  samples?: Maybe<Scalars['Int']>;
  minStep?: Maybe<Scalars['Int64']>;
  maxStep?: Maybe<Scalars['Int64']>;
};


export type BucketTypeLogLinesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BucketTypeFilesArgs = {
  pattern?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RunConnection = {
  __typename?: 'RunConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<RunEdge>;
  paths?: Maybe<Scalars['JSONString']>;
  historyKeys?: Maybe<Scalars['JSON']>;
  delta: Array<RunDiff>;
};


export type RunConnectionDeltaArgs = {
  currentRuns: Array<Scalars['String']>;
  lastUpdated: Scalars['DateTime'];
};

export type RunEdge = {
  __typename?: 'RunEdge';
  node: Run;
  cursor: Scalars['String'];
};

export enum DiffOperation {
  Insert = 'INSERT',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type RunDiff = {
  __typename?: 'RunDiff';
  index: Scalars['Int'];
  op: DiffOperation;
  run?: Maybe<Run>;
};

export type BenchmarkRun = {
  __typename?: 'BenchmarkRun';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  details?: Maybe<Scalars['JSON']>;
  results?: Maybe<Scalars['String']>;
  user: User;
  benchmark: Project;
  run: Run;
  originalProject?: Maybe<Project>;
  originalRun?: Maybe<Run>;
  isCodeHidden: Scalars['Boolean'];
  gitHubSubmissionPR?: Maybe<Scalars['String']>;
};

export type BenchmarkRunConnection = {
  __typename?: 'BenchmarkRunConnection';
  pageInfo: PageInfo;
  edges: Array<BenchmarkRunEdge>;
};

export type BenchmarkRunEdge = {
  __typename?: 'BenchmarkRunEdge';
  node?: Maybe<BenchmarkRun>;
  cursor: Scalars['String'];
};

export type RowConnection = {
  __typename?: 'RowConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  schema: Scalars['JSON'];
  edges: Array<RowEdge>;
};

export type RowEdge = {
  __typename?: 'RowEdge';
  node?: Maybe<RowType>;
};

export type RowType = {
  __typename?: 'RowType';
  row: Scalars['JSON'];
};

export type StorageTreeNode = {
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
};

export type EntityStorageNode = StorageTreeNode & {
  __typename?: 'EntityStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  entity: Entity;
  entityID: Scalars['ID'];
  projects: ProjectStorageNodeConnection;
};


export type EntityStorageNodeProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProjectStorageNodeConnection = {
  __typename?: 'ProjectStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ProjectStorageNodeEdge>;
};

export type ProjectStorageNodeEdge = {
  __typename?: 'ProjectStorageNodeEdge';
  node: ProjectStorageNode;
  cursor: Scalars['String'];
};

export type ProjectStorageNode = StorageTreeNode & {
  __typename?: 'ProjectStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  project: Project;
  projectID: Scalars['ID'];
  runs: RunStorageNodeConnection;
  artifactSequences: ArtifactSequenceStorageNodeConnection;
};


export type ProjectStorageNodeRunsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectStorageNodeArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RunStorageNodeConnection = {
  __typename?: 'RunStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<RunStorageNodeEdge>;
};

export type RunStorageNodeEdge = {
  __typename?: 'RunStorageNodeEdge';
  node: RunStorageNode;
  cursor: Scalars['String'];
};

export type RunStorageNode = StorageTreeNode & {
  __typename?: 'RunStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  run: Run;
  runID: Scalars['ID'];
  files: FileStorageNodeConnection;
};


export type RunStorageNodeFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FileStorageNodeConnection = {
  __typename?: 'FileStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<FileStorageNodeEdge>;
};

export type FileStorageNodeEdge = {
  __typename?: 'FileStorageNodeEdge';
  node: FileStorageNode;
  cursor: Scalars['String'];
};

export type FileStorageNode = StorageTreeNode & {
  __typename?: 'FileStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  file: File;
  fileID: Scalars['ID'];
};

export type ArtifactSequenceStorageNodeConnection = {
  __typename?: 'ArtifactSequenceStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ArtifactSequenceStorageNodeEdge>;
};

export type ArtifactSequenceStorageNodeEdge = {
  __typename?: 'ArtifactSequenceStorageNodeEdge';
  node: ArtifactSequenceStorageNode;
  cursor: Scalars['String'];
};

export type ArtifactSequenceStorageNode = StorageTreeNode & {
  __typename?: 'ArtifactSequenceStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  artifactSequence: ArtifactSequence;
  artifactType: ArtifactType;
  artifactSequenceID: Scalars['ID'];
  artifacts: ArtifactStorageNodeConnection;
};


export type ArtifactSequenceStorageNodeArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ArtifactStorageNodeConnection = {
  __typename?: 'ArtifactStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ArtifactStorageNodeEdge>;
};

export type ArtifactStorageNodeEdge = {
  __typename?: 'ArtifactStorageNodeEdge';
  node: ArtifactStorageNode;
  cursor: Scalars['String'];
};

export type ArtifactStorageNode = StorageTreeNode & {
  __typename?: 'ArtifactStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  artifact: Artifact;
  artifactID: Scalars['ID'];
};

export type Entity = Node & {
  __typename?: 'Entity';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  photoUploadUrl: Scalars['String'];
  subscriptionId?: Maybe<Scalars['Int']>;
  defaultAccess: Scalars['String'];
  privateOnly: Scalars['Boolean'];
  codeSavingEnabled: Scalars['Boolean'];
  isTeam: Scalars['Boolean'];
  projects?: Maybe<ProjectConnection>;
  runs?: Maybe<RunConnection>;
  limits?: Maybe<Scalars['JSONString']>;
  available?: Maybe<Scalars['Boolean']>;
  invites?: Maybe<InviteConnection>;
  members: Array<Member>;
  memberCount: Scalars['Int'];
  invitedTeam?: Maybe<Scalars['String']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  readOnlyAdmin: Scalars['Boolean'];
  runCount: Scalars['Int'];
  user?: Maybe<User>;
  storageBytes: Scalars['Int64'];
  referenceBytes: Scalars['Int64'];
  storageTree?: Maybe<EntityStorageNode>;
  computeHours: Scalars['Duration'];
  userStats?: Maybe<EntityUserConnection>;
  integrations?: Maybe<IntegrationConnection>;
  defaultAlerts: Array<Alert>;
  dailyRunCount: Array<Scalars['Int']>;
  claimingEntity?: Maybe<Entity>;
  claimedEntities?: Maybe<EntityConnection>;
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimits>;
  settings: Settings;
  accessTokens: Array<AccessToken>;
  organizationId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
};


export type EntityProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type EntityRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityInvitesArgs = {
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityRunCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type EntityStorageTreeArgs = {
  enableReferenceTracking?: Maybe<Scalars['Boolean']>;
};


export type EntityComputeHoursArgs = {
  minDaysOld?: Maybe<Scalars['Int']>;
};


export type EntityUserStatsArgs = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type EntityIntegrationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityDailyRunCountArgs = {
  limit: Scalars['Int'];
};


export type EntityClaimedEntitiesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type EntityConnection = {
  __typename?: 'EntityConnection';
  edges: Array<EntityEdge>;
};

export type EntityEdge = {
  __typename?: 'EntityEdge';
  node?: Maybe<Entity>;
  cursor: Scalars['String'];
};

export type EntityUser = Node & {
  __typename?: 'EntityUser';
  id: Scalars['ID'];
  user: User;
  runCount: Scalars['Int'];
};

export type EntityUserConnection = {
  __typename?: 'EntityUserConnection';
  edges: Array<EntityUserEdge>;
};

export type EntityUserEdge = {
  __typename?: 'EntityUserEdge';
  node?: Maybe<EntityUser>;
  cursor: Scalars['String'];
};

export type RateLimits = {
  __typename?: 'RateLimits';
  filestream: Scalars['Int'];
  graphql: Scalars['Int'];
};

export type Settings = {
  __typename?: 'Settings';
  defaultCloudRegion: CloudRegion;
};

export enum AccessTokenType {
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED'
}

export type AccessToken = {
  __typename?: 'AccessToken';
  id: Scalars['ID'];
  token: Scalars['String'];
  view: View;
  type: AccessTokenType;
  emails: Array<Scalars['String']>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  lastAccessedAt?: Maybe<Scalars['DateTime']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  projects: Array<Project>;
};

export enum OrgType {
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL'
}

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  pageInfo: PageInfo;
  edges: Array<OrganizationEdge>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  node: Organization;
  cursor: Scalars['String'];
};

export type Organization = Node & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  billingUser: User;
  stripeBillingInfo?: Maybe<StripeBillingInfo>;
  members: Array<OrganizationMember>;
  pendingInvites: Array<Invite>;
  teams: Array<Entity>;
  subscriptions: Array<OrganizationSubscription>;
  orgType: OrgType;
  flags?: Maybe<Scalars['JSONString']>;
  views: ViewConnection;
  artifactSequences?: Maybe<ArtifactSequenceConnection>;
  projects?: Maybe<ProjectConnection>;
};


export type OrganizationViewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type OrganizationArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type OrganizationProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};

export type StripeBillingInfo = {
  __typename?: 'StripeBillingInfo';
  stripeSubscriptionId: Scalars['String'];
  status: Scalars['String'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  invoiceLink?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  type: Scalars['String'];
  cardType?: Maybe<Scalars['String']>;
  endingIn?: Maybe<Scalars['String']>;
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  user: User;
  admin: Scalars['Boolean'];
  username: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
  photoUrl: Scalars['String'];
  teams?: Maybe<EntityConnection>;
  role: Scalars['String'];
};


export type OrganizationMemberTeamsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum OrganizationSubscriptionType {
  Stripe = 'STRIPE',
  Enterprise = 'ENTERPRISE',
  ManualTrial = 'MANUAL_TRIAL',
  UserLedTrial = 'USER_LED_TRIAL',
  AcademicTrial = 'ACADEMIC_TRIAL',
  Academic = 'ACADEMIC',
  Local = 'LOCAL'
}

export enum OrganizationSubscriptionStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export type OrganizationSubscription = {
  __typename?: 'OrganizationSubscription';
  id: Scalars['ID'];
  plan: Plan;
  privileges: Scalars['JSON'];
  seats: Scalars['Int'];
  subscriptionType: OrganizationSubscriptionType;
  status: OrganizationSubscriptionStatus;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type Sweep = Node & {
  __typename?: 'Sweep';
  id: Scalars['ID'];
  name: Scalars['String'];
  method: Scalars['String'];
  state: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  config: Scalars['String'];
  createdAt: Scalars['DateTime'];
  heartbeatAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  earlyStopJobRunning: Scalars['Boolean'];
  project?: Maybe<Project>;
  controller?: Maybe<Scalars['JSONString']>;
  scheduler?: Maybe<Scalars['JSONString']>;
  displayName?: Maybe<Scalars['String']>;
  runCount: Scalars['Int'];
  runTime: Scalars['Int'];
  bestLoss?: Maybe<Scalars['Float']>;
  runs: RunConnection;
  priorRuns: RunConnection;
  agents: AgentConnection;
  agent?: Maybe<Agent>;
  controllerRunName: Scalars['String'];
};


export type SweepRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SweepAgentArgs = {
  agentName: Scalars['String'];
};

export type SweepConnection = {
  __typename?: 'SweepConnection';
  pageInfo: PageInfo;
  edges: Array<SweepEdge>;
};

export type SweepEdge = {
  __typename?: 'SweepEdge';
  node: Sweep;
  cursor: Scalars['String'];
};

export type Agent = Node & {
  __typename?: 'Agent';
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  persistent?: Maybe<Scalars['Boolean']>;
  sweep: Sweep;
  user?: Maybe<User>;
  metrics: Scalars['JSONString'];
  createdAt: Scalars['DateTime'];
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  totalRuns: Scalars['Int'];
  runs: RunConnection;
};


export type AgentRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AgentConnection = {
  __typename?: 'AgentConnection';
  pageInfo: PageInfo;
  edges: Array<AgentEdge>;
};

export type AgentEdge = {
  __typename?: 'AgentEdge';
  node: Agent;
  cursor: Scalars['String'];
};

export type File = Node & {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  archived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  storageBucket?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  special?: Maybe<Scalars['Boolean']>;
  mimetype?: Maybe<Scalars['String']>;
  ndbId?: Maybe<Scalars['ID']>;
  sizeBytes: Scalars['Int64'];
  md5?: Maybe<Scalars['String']>;
  digest?: Maybe<Scalars['String']>;
  isReference: Scalars['Boolean'];
  exists: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadHeaders: Array<Scalars['String']>;
  directUrl: Scalars['String'];
  artifact?: Maybe<Artifact>;
};


export type FileUrlArgs = {
  upload?: Maybe<Scalars['Boolean']>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  uploadHeaders: Array<Scalars['String']>;
  pageInfo: PageInfo;
  edges: Array<FileEdge>;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  node?: Maybe<File>;
  cursor: Scalars['String'];
};

export type Code = {
  __typename?: 'Code';
  name: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  directUrl: Scalars['String'];
};

export type LogLine = {
  __typename?: 'LogLine';
  line?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type LogLineConnection = {
  __typename?: 'LogLineConnection';
  pageInfo: PageInfo;
  edges: Array<LogLineEdge>;
};

export type LogLineEdge = {
  __typename?: 'LogLineEdge';
  node: LogLine;
  cursor: Scalars['String'];
};

export type ArtifactTypeConnection = {
  __typename?: 'ArtifactTypeConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactTypeEdge>;
};

export type ArtifactTypeEdge = {
  __typename?: 'ArtifactTypeEdge';
  node?: Maybe<ArtifactType>;
  cursor: Scalars['String'];
};

export type ArtifactType = {
  __typename?: 'ArtifactType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project: Project;
  artifactActions?: Maybe<ArtifactActionConnection>;
  artifact?: Maybe<Artifact>;
  artifactSequence?: Maybe<ArtifactSequence>;
  artifactSequences?: Maybe<ArtifactSequenceConnection>;
};


export type ArtifactTypeArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactTypeArtifactArgs = {
  name: Scalars['String'];
};


export type ArtifactTypeArtifactSequenceArgs = {
  name: Scalars['String'];
};


export type ArtifactTypeArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};

export type ArtifactCollection = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project: Project;
  state: ArtifactCollectionState;
  updatedAt?: Maybe<Scalars['DateTime']>;
  artifactActions?: Maybe<ArtifactActionConnection>;
  artifacts?: Maybe<VersionedArtifactConnection>;
  aliases?: Maybe<ArtifactAliasConnection>;
};


export type ArtifactCollectionArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactCollectionArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactCollectionAliasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ArtifactSequenceConnection = {
  __typename?: 'ArtifactSequenceConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactSequenceEdge>;
};

export type ArtifactSequenceEdge = {
  __typename?: 'ArtifactSequenceEdge';
  node?: Maybe<ArtifactSequence>;
  cursor: Scalars['String'];
};

export type ArtifactSequence = ArtifactCollection & {
  __typename?: 'ArtifactSequence';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project: Project;
  state: ArtifactCollectionState;
  updatedAt?: Maybe<Scalars['DateTime']>;
  artifactActions: ArtifactActionConnection;
  latestArtifact?: Maybe<Artifact>;
  artifacts: VersionedArtifactConnection;
  aliases?: Maybe<ArtifactAliasConnection>;
};


export type ArtifactSequenceArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactSequenceArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactSequenceAliasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ArtifactConnectionType = {
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactEdgeType>;
};

export type ArtifactConnection = ArtifactConnectionType & {
  __typename?: 'ArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactEdge>;
};

export type VersionedArtifactConnection = ArtifactConnectionType & {
  __typename?: 'VersionedArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<VersionedArtifactEdge>;
};

export type InputArtifactConnection = ArtifactConnectionType & {
  __typename?: 'InputArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<InputArtifactEdge>;
};

export type ArtifactEdgeType = {
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
};

export type ArtifactEdge = ArtifactEdgeType & {
  __typename?: 'ArtifactEdge';
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
};

export type VersionedArtifactEdge = ArtifactEdgeType & {
  __typename?: 'VersionedArtifactEdge';
  node: Artifact;
  version: Scalars['String'];
  cursor: Scalars['String'];
};

export type InputArtifactEdge = ArtifactEdgeType & {
  __typename?: 'InputArtifactEdge';
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
  usedAs: Array<Scalars['String']>;
};

export enum ArtifactState {
  Pending = 'PENDING',
  Committed = 'COMMITTED',
  Deleted = 'DELETED'
}

export enum ArtifactCollectionState {
  Ready = 'READY',
  Deleted = 'DELETED'
}

export enum ArtifactDigestAlgorithm {
  ManifestMd5 = 'MANIFEST_MD5'
}

export enum ArtifactStorageLayout {
  V1 = 'V1',
  V2 = 'V2'
}

export type ArtifactAliasConnection = {
  __typename?: 'ArtifactAliasConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactAliasEdge>;
};

export type ArtifactAliasEdge = {
  __typename?: 'ArtifactAliasEdge';
  node?: Maybe<ArtifactAlias>;
  cursor: Scalars['String'];
};

export type ArtifactAlias = {
  __typename?: 'ArtifactAlias';
  id: Scalars['ID'];
  artifact: Artifact;
  artifactCollection?: Maybe<ArtifactCollection>;
  artifactCollectionName: Scalars['String'];
  alias: Scalars['String'];
};

export type Artifact = {
  __typename?: 'Artifact';
  id: Scalars['ID'];
  digest: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  versionIndex?: Maybe<Scalars['Int']>;
  aliases: Array<ArtifactAlias>;
  labels?: Maybe<Scalars['JSONString']>;
  metadata?: Maybe<Scalars['JSONString']>;
  state: ArtifactState;
  size: Scalars['Int64'];
  storageBytes: Scalars['Int64'];
  fileCount: Scalars['Int64'];
  artifactType: ArtifactType;
  artifactSequence: ArtifactSequence;
  artifactActions?: Maybe<ArtifactActionConnection>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy: Initiator;
  usedCount: Scalars['Int'];
  usedBy: RunConnection;
  currentManifest?: Maybe<ArtifactManifest>;
  files: FileConnection;
};


export type ArtifactCommitHashArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactVersionIndexArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactAliasesArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactUsedByArgs = {
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactFilesArgs = {
  names?: Maybe<Array<Scalars['String']>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ArtifactManifestConnection = {
  __typename?: 'ArtifactManifestConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactManifestEdge>;
};

export type ArtifactManifestEdge = {
  __typename?: 'ArtifactManifestEdge';
  node?: Maybe<ArtifactManifest>;
  cursor: Scalars['String'];
};

export enum ArtifactManifestType {
  Full = 'FULL',
  Patch = 'PATCH',
  Incremental = 'INCREMENTAL'
}

export type ArtifactManifest = {
  __typename?: 'ArtifactManifest';
  id: Scalars['ID'];
  type: ArtifactManifestType;
  createdAt: Scalars['DateTime'];
  digest: Scalars['String'];
  artifact: Artifact;
  file: File;
};

export type ArtifactActionConnection = {
  __typename?: 'ArtifactActionConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactActionEdge>;
};

export type ArtifactActionEdge = {
  __typename?: 'ArtifactActionEdge';
  node: ArtifactAction;
  cursor: Scalars['String'];
};

export type ArtifactAction = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type UpdateArtifactAction = ArtifactAction & {
  __typename?: 'UpdateArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
  oldTags?: Maybe<Scalars['JSONString']>;
  newTags?: Maybe<Scalars['JSONString']>;
  oldAliases?: Maybe<Array<ArtifactAlias>>;
  newAliases?: Maybe<Array<ArtifactAlias>>;
  oldDescription?: Maybe<Scalars['String']>;
  newDescription?: Maybe<Scalars['String']>;
  oldMetadata?: Maybe<Scalars['JSONString']>;
  newMetadata?: Maybe<Scalars['JSONString']>;
};

export type UseArtifactAction = ArtifactAction & {
  __typename?: 'UseArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type DeleteArtifactAction = ArtifactAction & {
  __typename?: 'DeleteArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type MoveArtifactAliasAction = ArtifactAction & {
  __typename?: 'MoveArtifactAliasAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  destinationArtifact?: Maybe<Artifact>;
  initiator: Initiator;
};

export type ImageUrl = {
  __typename?: 'ImageUrl';
  url?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type View = Node & {
  __typename?: 'View';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  imageUploadUrl: ImageUrl;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['JSONString']>;
  user?: Maybe<User>;
  project?: Maybe<Project>;
  entity?: Maybe<Entity>;
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  starCount: Scalars['Int'];
  starred?: Maybe<Scalars['Boolean']>;
  stargazers: StargazerConnection;
  children?: Maybe<ViewConnection>;
  parentId?: Maybe<Scalars['ID']>;
  locked: Scalars['Boolean'];
  viewCount: Scalars['Int'];
  showcasedAt?: Maybe<Scalars['DateTime']>;
  discussionThreads: DiscussionThreadConnection;
  alertSubscription?: Maybe<AlertSubscription>;
  accessTokens: Array<AccessToken>;
};


export type ViewImageUploadUrlArgs = {
  name: Scalars['String'];
};


export type ViewStarCountArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type ViewStargazersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ViewDiscussionThreadsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ViewConnection = {
  __typename?: 'ViewConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ViewEdge>;
};

export type ViewEdge = {
  __typename?: 'ViewEdge';
  node?: Maybe<View>;
  cursor: Scalars['String'];
};

export type StargazerConnection = {
  __typename?: 'StargazerConnection';
  pageInfo: PageInfo;
  edges: Array<StargazerEdge>;
};

export type StargazerEdge = {
  __typename?: 'StargazerEdge';
  node?: Maybe<User>;
  cursor: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  node?: Maybe<Event>;
  cursor: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  admin?: Maybe<Scalars['Boolean']>;
  pending?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  ips: Array<Scalars['String']>;
  rateLimit?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  pageInfo: PageInfo;
  edges: Array<ApiKeyEdge>;
};

export type ApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  node?: Maybe<ApiKey>;
  cursor: Scalars['String'];
};

export type Invite = {
  __typename?: 'Invite';
  updatedAt?: Maybe<Scalars['DateTime']>;
  toUser?: Maybe<User>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fromUser?: Maybe<User>;
  accepted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type InviteConnection = {
  __typename?: 'InviteConnection';
  pageInfo: PageInfo;
  edges: Array<InviteEdge>;
};

export type InviteEdge = {
  __typename?: 'InviteEdge';
  node?: Maybe<Invite>;
  cursor: Scalars['String'];
};

export type Integration = {
  id: Scalars['ID'];
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  pageInfo: PageInfo;
  edges: Array<IntegrationEdge>;
};

export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  node?: Maybe<Integration>;
  cursor: Scalars['String'];
};

export type SlackIntegration = Integration & {
  __typename?: 'SlackIntegration';
  id: Scalars['ID'];
  teamName: Scalars['String'];
  channelName: Scalars['String'];
};

export type GitHubOAuthIntegration = Integration & {
  __typename?: 'GitHubOAuthIntegration';
  id: Scalars['ID'];
};

export enum AlertSeverity {
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR'
}

export type Alert = {
  __typename?: 'Alert';
  id: Scalars['ID'];
  condition: AlertCondition;
  subscriptions: Array<AlertSubscription>;
  events: AlertEventConnection;
};


export type AlertEventsArgs = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AlertCondition = FinishedRunCondition | StoppedRunCondition | ScriptableRunCondition;

export type FinishedRunCondition = {
  __typename?: 'FinishedRunCondition';
  success: Scalars['Boolean'];
};

export type StoppedRunCondition = {
  __typename?: 'StoppedRunCondition';
  minimumRunDuration: Scalars['Duration'];
};

export type ScriptableRunCondition = {
  __typename?: 'ScriptableRunCondition';
  success: Scalars['Boolean'];
};

export type AlertEventConnection = {
  __typename?: 'AlertEventConnection';
  pageInfo: PageInfo;
  edges: Array<AlertEventEdge>;
};

export type AlertEventEdge = {
  __typename?: 'AlertEventEdge';
  node?: Maybe<AlertEvent>;
  cursor: Scalars['String'];
};

export type AlertEvent = {
  __typename?: 'AlertEvent';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
};

export type AlertSubscription = {
  id: Scalars['ID'];
};

export type SlackChannelSubscription = AlertSubscription & {
  __typename?: 'SlackChannelSubscription';
  id: Scalars['ID'];
  slackIntegration: SlackIntegration;
};

export type EmailSubscription = AlertSubscription & {
  __typename?: 'EmailSubscription';
  id: Scalars['ID'];
};

export enum TaskState {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  state: TaskState;
  progress: Scalars['Int'];
  name: Scalars['String'];
};

export type DiscussionThreadConnection = {
  __typename?: 'DiscussionThreadConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<DiscussionThreadEdge>;
};

export type DiscussionThreadEdge = {
  __typename?: 'DiscussionThreadEdge';
  node: DiscussionThread;
  cursor: Scalars['String'];
};

export type DiscussionThread = {
  __typename?: 'DiscussionThread';
  id: Scalars['ID'];
  poster: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  upvotes: Scalars['Int'];
  title: Scalars['String'];
  body: Scalars['String'];
  comments: DiscussionCommentConnection;
};


export type DiscussionThreadCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DiscussionCommentConnection = {
  __typename?: 'DiscussionCommentConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<DiscussionCommentEdge>;
};

export type DiscussionCommentEdge = {
  __typename?: 'DiscussionCommentEdge';
  node: DiscussionComment;
  cursor: Scalars['String'];
};

export type DiscussionComment = {
  __typename?: 'DiscussionComment';
  id: Scalars['ID'];
  poster: User;
  parentComment?: Maybe<DiscussionComment>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isParent: Scalars['Boolean'];
  upvotes: Scalars['Int'];
  body: Scalars['String'];
  childComments: DiscussionCommentConnection;
};


export type DiscussionCommentChildCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomChart = {
  __typename?: 'CustomChart';
  id: Scalars['ID'];
  entity: Entity;
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  access: Scalars['String'];
  spec: Scalars['JSONString'];
};

export type CustomChartConnection = {
  __typename?: 'CustomChartConnection';
  pageInfo: PageInfo;
  edges: Array<CustomChartEdge>;
};

export type CustomChartEdge = {
  __typename?: 'CustomChartEdge';
  node: CustomChart;
  cursor: Scalars['String'];
};

export type LocalLicense = Node & {
  __typename?: 'LocalLicense';
  id: Scalars['ID'];
  uid: Scalars['String'];
  license: Scalars['String'];
  customerName: Scalars['String'];
  contactEmail: Scalars['String'];
  generatedBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  claims: LicenseClaims;
  notes?: Maybe<Scalars['String']>;
};

export type LicenseClaims = {
  __typename?: 'LicenseClaims';
  seats: Scalars['Int'];
  teams: Scalars['Int'];
  storageGigs: Scalars['Int'];
  flags: Array<Scalars['String']>;
  expiresAt: Scalars['Int64'];
};

export type NewsletterSubscription = {
  __typename?: 'NewsletterSubscription';
  id: Scalars['ID'];
  user?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type NewsletterSubscriptionConnection = {
  __typename?: 'NewsletterSubscriptionConnection';
  edges: Array<NewsletterSubscriptionEdge>;
};

export type NewsletterSubscriptionEdge = {
  __typename?: 'NewsletterSubscriptionEdge';
  node?: Maybe<NewsletterSubscription>;
  cursor: Scalars['String'];
};

export type ClientIdMappingEntry = Artifact | ArtifactSequence;

export type ClientIdMapping = {
  __typename?: 'ClientIDMapping';
  clientID: Scalars['ID'];
  serverID: Scalars['ID'];
  entry: ClientIdMappingEntry;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteModel?: Maybe<DeleteModelPayload>;
  upsertModel?: Maybe<UpsertModelPayload>;
  renameProject?: Maybe<UpsertModelPayload>;
  deleteRun?: Maybe<DeleteRunPayload>;
  deleteRuns?: Maybe<DeleteRunsPayload>;
  undeleteRuns?: Maybe<UndeleteRunsPayload>;
  upsertBucket?: Maybe<UpsertBucketPayload>;
  upsertRunGroup?: Maybe<UpsertRunGroupPayload>;
  upsertSweep?: Maybe<UpsertSweepPayload>;
  createAgent?: Maybe<CreateAgentPayload>;
  agentHeartbeat?: Maybe<AgentHeartbeatPayload>;
  modifyRuns?: Maybe<ModifyRunsPayload>;
  moveRuns?: Maybe<MoveRunsPayload>;
  stopRun?: Maybe<StopRunPayload>;
  addFiles?: Maybe<AddFilesPayload>;
  deleteFiles?: Maybe<DeleteFilesPayload>;
  copyFile?: Maybe<CopyFilePayload>;
  createEntity?: Maybe<CreateEntityPayload>;
  createAnonymousEntity?: Maybe<CreateEntityPayload>;
  deleteEntity?: Maybe<DeleteEntityPayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  createInvite?: Maybe<CreateInvitePayload>;
  deleteInvite?: Maybe<DeleteInvitePayload>;
  createUser?: Maybe<CreateUserPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  undeleteUser?: Maybe<UndeleteUserPayload>;
  purgeUser?: Maybe<PurgeUserPayload>;
  updateEntity?: Maybe<UpdateEntityPayload>;
  createServiceAccount?: Maybe<CreateServiceAccountPayload>;
  generateApiKey?: Maybe<GenerateApiKeyPayload>;
  deleteApiKey?: Maybe<DeleteApiKeyPayload>;
  insertGalleryDiscussion?: Maybe<InsertGalleryDiscussionPayload>;
  upsertView?: Maybe<UpsertViewPayload>;
  moveView?: Maybe<MoveViewPayload>;
  deleteView?: Maybe<DeleteViewPayload>;
  deleteViews?: Maybe<DeleteViewsPayload>;
  starView?: Maybe<StarViewPayload>;
  unstarView?: Maybe<UnstarViewPayload>;
  viewView?: Maybe<ViewViewPayload>;
  upsertSharedView?: Maybe<UpsertSharedViewPayload>;
  setTagColor?: Maybe<SetTagColorPayload>;
  createTeam?: Maybe<CreateTeamPayload>;
  submitBenchmarkRun?: Maybe<SubmitBenchmarkRunPayload>;
  publishBenchmarkRun?: Maybe<PublishBenchmarkRunPayload>;
  updateBenchmarkRun?: Maybe<UpdateBenchmarkRunPayload>;
  updateMember?: Maybe<UpdateMemberPayload>;
  createFinishedRunAlert?: Maybe<CreateAlertPayload>;
  createStoppedRunAlert?: Maybe<CreateAlertPayload>;
  updateStoppedRunAlert?: Maybe<UpdateAlertPayload>;
  createScriptableRunAlert?: Maybe<CreateAlertPayload>;
  deleteAlert?: Maybe<DeleteAlertPayload>;
  notifyScriptableRunAlert?: Maybe<NotifyScriptableRunAlertPayload>;
  createSlackChannelSubscription?: Maybe<CreateSlackChannelSubscriptionPayload>;
  createEmailSubscription?: Maybe<CreateEmailSubscriptionPayload>;
  createViewCommentsAlertSubscription?: Maybe<CreateEmailSubscriptionPayload>;
  deleteAlertSubscription?: Maybe<DeleteAlertSubscriptionPayload>;
  deleteSweep?: Maybe<DeleteSweepsPayload>;
  deleteSweeps?: Maybe<DeleteSweepsPayload>;
  createSlackIntegration?: Maybe<CreateIntegrationPayload>;
  createGitHubOAuthIntegration?: Maybe<CreateIntegrationPayload>;
  deleteIntegration?: Maybe<DeleteIntegrationPayload>;
  createBenchmarkDiscussionThread?: Maybe<CreateDiscussionThreadPayload>;
  createViewDiscussionComment?: Maybe<CreateViewDiscussionCommentPayload>;
  updateDiscussionThread?: Maybe<UpdateDiscussionThreadPayload>;
  createBenchmarkDiscussionComment?: Maybe<CreateDiscussionCommentPayload>;
  updateDiscussionComment?: Maybe<UpdateDiscussionCommentPayload>;
  deleteDiscussionComment?: Maybe<DeleteDiscussionCommentPayload>;
  claimAnonymousEntity?: Maybe<ClaimAnonymousEntityPayload>;
  createCustomerSubscription?: Maybe<CreateCustomerSubscriptionPayload>;
  createStorageSubscription?: Maybe<CreateStorageSubscriptionPayload>;
  upgradeCustomerSubscription?: Maybe<UpgradeCustomerSubscriptionPayload>;
  createCustomerTrial?: Maybe<CreateCustomerTrialPayload>;
  createUserLedTrial?: Maybe<CreateUserLedTrialPayload>;
  contactUsForUserLedTrial?: Maybe<ContactUsForUserLedTrialPayload>;
  contactUsForComputeHours?: Maybe<ContactUsForComputeHoursPayload>;
  cancelCustomerSubscription?: Maybe<CancelCustomerSubscriptionPayload>;
  updateCustomerPaymentMethod?: Maybe<UpdateCustomerPaymentMethodPayload>;
  createLocalLicenseOrganization?: Maybe<CreateLocalLicenseOrganizationPayload>;
  addUserToOrganization?: Maybe<AddUserToOrganizationPayload>;
  removeUserFromOrganization?: Maybe<RemoveUserFromOrganizationPayload>;
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  updateOrganizationUser?: Maybe<UpdateOrganizationUserPayload>;
  createArtifactType?: Maybe<CreateArtifactTypePayload>;
  updateArtifactType?: Maybe<UpdateArtifactTypePayload>;
  createArtifactSequence?: Maybe<CreateArtifactCollectionPayload>;
  updateArtifactSequence?: Maybe<UpdateArtifactCollectionPayload>;
  moveArtifactSequence?: Maybe<MoveArtifactCollectionPayload>;
  deleteArtifactSequence?: Maybe<DeleteArtifactCollectionPayload>;
  createArtifact?: Maybe<CreateArtifactPayload>;
  updateArtifact?: Maybe<UpdateArtifactPayload>;
  useArtifact?: Maybe<UseArtifactPayload>;
  commitArtifact?: Maybe<CommitArtifactPayload>;
  deleteArtifact?: Maybe<DeleteArtifactPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  createArtifactManifest?: Maybe<CreateArtifactManifestPayload>;
  updateArtifactManifest?: Maybe<UpdateArtifactManifestPayload>;
  createArtifactFiles?: Maybe<CreateArtifactFilesPayload>;
  createRunQueue?: Maybe<CreateRunQueuePayload>;
  deleteRunQueues?: Maybe<DeleteRunQueuesPayload>;
  pushToRunQueue?: Maybe<PushToRunQueuePayload>;
  popFromRunQueue?: Maybe<PopFromRunQueuePayload>;
  ackRunQueueItem?: Maybe<AckRunQueueItemPayload>;
  deleteFromRunQueue?: Maybe<DeleteFromRunQueuePayload>;
  createLaunchAgent?: Maybe<CreateLaunchAgentPayload>;
  deleteLaunchAgent?: Maybe<DeleteLaunchAgentPayload>;
  updateLaunchAgent?: Maybe<UpdateLaunchAgentPayload>;
  createAccessToken?: Maybe<CreateAccessTokenPayload>;
  revokeAccessToken?: Maybe<RevokeAccessTokenPayload>;
  updateAccessTokenProjects?: Maybe<UpdateAccessTokenProjectsPayload>;
  createCustomChart?: Maybe<CreateCustomChartPayload>;
  updateCustomChart?: Maybe<UpdateCustomChartPayload>;
  deleteCustomChart?: Maybe<DeleteCustomChartPayload>;
  generateLocalLicense?: Maybe<GenerateLocalLicensePayload>;
  updateOrganizationSubscription?: Maybe<UpdateOrganizationSubscriptionPayload>;
  updateLocalLicense?: Maybe<UpdateLocalLicensePayload>;
  moveProjects?: Maybe<MoveProjectsPayload>;
  cloneProjects?: Maybe<CloneProjectsPayload>;
  createNewsletterSubscription?: Maybe<CreateNewsletterSubscriptionPayload>;
  deleteNewsletterSubscription?: Maybe<DeleteNewsletterSubscriptionPayload>;
  createClientIDMapping?: Maybe<CreateClientIdMappingPayload>;
};


export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};


export type MutationUpsertModelArgs = {
  input: UpsertModelInput;
};


export type MutationRenameProjectArgs = {
  input: RenameProjectInput;
};


export type MutationDeleteRunArgs = {
  input: DeleteRunInput;
};


export type MutationDeleteRunsArgs = {
  input: DeleteRunsInput;
};


export type MutationUndeleteRunsArgs = {
  input: UndeleteRunsInput;
};


export type MutationUpsertBucketArgs = {
  input: UpsertBucketInput;
};


export type MutationUpsertRunGroupArgs = {
  input: UpsertRunGroupInput;
};


export type MutationUpsertSweepArgs = {
  input: UpsertSweepInput;
};


export type MutationCreateAgentArgs = {
  input: CreateAgentInput;
};


export type MutationAgentHeartbeatArgs = {
  input: AgentHeartbeatInput;
};


export type MutationModifyRunsArgs = {
  input: ModifyRunsInput;
};


export type MutationMoveRunsArgs = {
  input: MoveRunsInput;
};


export type MutationStopRunArgs = {
  input: StopRunInput;
};


export type MutationAddFilesArgs = {
  input: AddFilesInput;
};


export type MutationDeleteFilesArgs = {
  input: DeleteFilesInput;
};


export type MutationCopyFileArgs = {
  input: CopyFileInput;
};


export type MutationCreateEntityArgs = {
  input: CreateEntityInput;
};


export type MutationCreateAnonymousEntityArgs = {
  input: CreateAnonymousEntityInput;
};


export type MutationDeleteEntityArgs = {
  input: DeleteEntityInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationCreateInviteArgs = {
  input: CreateInviteInput;
};


export type MutationDeleteInviteArgs = {
  input: DeleteInviteInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationUndeleteUserArgs = {
  input: UndeleteUserInput;
};


export type MutationPurgeUserArgs = {
  input: PurgeUserInput;
};


export type MutationUpdateEntityArgs = {
  input: UpdateEntityInput;
};


export type MutationCreateServiceAccountArgs = {
  input: CreateServiceAccountInput;
};


export type MutationGenerateApiKeyArgs = {
  input: GenerateApiKeyInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};


export type MutationInsertGalleryDiscussionArgs = {
  input: InsertGalleryDiscussionInput;
};


export type MutationUpsertViewArgs = {
  input: UpsertViewInput;
};


export type MutationMoveViewArgs = {
  input: MoveViewInput;
};


export type MutationDeleteViewArgs = {
  input: DeleteViewInput;
};


export type MutationDeleteViewsArgs = {
  input: DeleteViewsInput;
};


export type MutationStarViewArgs = {
  input: StarViewInput;
};


export type MutationUnstarViewArgs = {
  input: UnstarViewInput;
};


export type MutationViewViewArgs = {
  input: ViewViewInput;
};


export type MutationUpsertSharedViewArgs = {
  input: UpsertSharedViewInput;
};


export type MutationSetTagColorArgs = {
  input: SetTagColorInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationSubmitBenchmarkRunArgs = {
  input: SubmitBenchmarkRunInput;
};


export type MutationPublishBenchmarkRunArgs = {
  input: PublishBenchmarkRunInput;
};


export type MutationUpdateBenchmarkRunArgs = {
  input: UpdateBenchmarkRunInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationCreateFinishedRunAlertArgs = {
  input: CreateFinishedRunAlertInput;
};


export type MutationCreateStoppedRunAlertArgs = {
  input: CreateStoppedRunAlertInput;
};


export type MutationUpdateStoppedRunAlertArgs = {
  input: UpdateStoppedRunAlertInput;
};


export type MutationCreateScriptableRunAlertArgs = {
  input: CreateScriptableRunAlertInput;
};


export type MutationDeleteAlertArgs = {
  input: DeleteAlertInput;
};


export type MutationNotifyScriptableRunAlertArgs = {
  input: NotifyScriptableRunAlertInput;
};


export type MutationCreateSlackChannelSubscriptionArgs = {
  input: CreateSlackChannelSubscriptionInput;
};


export type MutationCreateEmailSubscriptionArgs = {
  input: CreateEmailSubscriptionInput;
};


export type MutationCreateViewCommentsAlertSubscriptionArgs = {
  input: CreateViewCommentsAlertSubscriptionInput;
};


export type MutationDeleteAlertSubscriptionArgs = {
  input: DeleteAlertSubscriptionInput;
};


export type MutationDeleteSweepArgs = {
  input: DeleteSweepInput;
};


export type MutationDeleteSweepsArgs = {
  input: DeleteSweepsInput;
};


export type MutationCreateSlackIntegrationArgs = {
  input: CreateSlackIntegrationInput;
};


export type MutationCreateGitHubOAuthIntegrationArgs = {
  input: CreateGitHubOAuthIntegrationInput;
};


export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


export type MutationCreateBenchmarkDiscussionThreadArgs = {
  input: CreateBenchmarkDiscussionThreadInput;
};


export type MutationCreateViewDiscussionCommentArgs = {
  input: CreateViewDiscussionCommentInput;
};


export type MutationUpdateDiscussionThreadArgs = {
  input: UpdateDiscussionThreadInput;
};


export type MutationCreateBenchmarkDiscussionCommentArgs = {
  input: CreateBenchmarkDiscussionCommentInput;
};


export type MutationUpdateDiscussionCommentArgs = {
  input: UpdateDiscussionCommentInput;
};


export type MutationDeleteDiscussionCommentArgs = {
  input: DeleteDiscussionCommentInput;
};


export type MutationClaimAnonymousEntityArgs = {
  input: ClaimAnonymousEntityInput;
};


export type MutationCreateCustomerSubscriptionArgs = {
  input: CreateCustomerSubscriptionInput;
};


export type MutationCreateStorageSubscriptionArgs = {
  input: CreateStorageSubscriptionInput;
};


export type MutationUpgradeCustomerSubscriptionArgs = {
  input: UpgradeCustomerSubscriptionInput;
};


export type MutationCreateCustomerTrialArgs = {
  input: CreateCustomerTrialInput;
};


export type MutationCreateUserLedTrialArgs = {
  input: CreateUserLedTrialInput;
};


export type MutationContactUsForUserLedTrialArgs = {
  input: ContactUsForUserLedTrialInput;
};


export type MutationContactUsForComputeHoursArgs = {
  input: ContactUsForComputeHoursInput;
};


export type MutationCancelCustomerSubscriptionArgs = {
  input: CancelCustomerSubscriptionInput;
};


export type MutationUpdateCustomerPaymentMethodArgs = {
  input: UpdateCustomerPaymentMethodInput;
};


export type MutationCreateLocalLicenseOrganizationArgs = {
  input: CreateLocalLicenseOrganizationInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationUserArgs = {
  input: UpdateOrganizationUserInput;
};


export type MutationCreateArtifactTypeArgs = {
  input: CreateArtifactTypeInput;
};


export type MutationUpdateArtifactTypeArgs = {
  input: UpdateArtifactTypeInput;
};


export type MutationCreateArtifactSequenceArgs = {
  input: CreateArtifactSequenceInput;
};


export type MutationUpdateArtifactSequenceArgs = {
  input: UpdateArtifactSequenceInput;
};


export type MutationMoveArtifactSequenceArgs = {
  input: MoveArtifactSequenceInput;
};


export type MutationDeleteArtifactSequenceArgs = {
  input: DeleteArtifactSequenceInput;
};


export type MutationCreateArtifactArgs = {
  input: CreateArtifactInput;
};


export type MutationUpdateArtifactArgs = {
  input: UpdateArtifactInput;
};


export type MutationUseArtifactArgs = {
  input: UseArtifactInput;
};


export type MutationCommitArtifactArgs = {
  input: CommitArtifactInput;
};


export type MutationDeleteArtifactArgs = {
  input: DeleteArtifactInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationCreateArtifactManifestArgs = {
  input: CreateArtifactManifestInput;
};


export type MutationUpdateArtifactManifestArgs = {
  input: UpdateArtifactManifestInput;
};


export type MutationCreateArtifactFilesArgs = {
  input: CreateArtifactFilesInput;
};


export type MutationCreateRunQueueArgs = {
  input: CreateRunQueueInput;
};


export type MutationDeleteRunQueuesArgs = {
  input: DeleteRunQueuesInput;
};


export type MutationPushToRunQueueArgs = {
  input: PushToRunQueueInput;
};


export type MutationPopFromRunQueueArgs = {
  input: PopFromRunQueueInput;
};


export type MutationAckRunQueueItemArgs = {
  input: AckRunQueueItemInput;
};


export type MutationDeleteFromRunQueueArgs = {
  input: DeleteFromRunQueueInput;
};


export type MutationCreateLaunchAgentArgs = {
  input: CreateLaunchAgentInput;
};


export type MutationDeleteLaunchAgentArgs = {
  input: DeleteLaunchAgentInput;
};


export type MutationUpdateLaunchAgentArgs = {
  input: UpdateLaunchAgentInput;
};


export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInput;
};


export type MutationRevokeAccessTokenArgs = {
  input: RevokeAccessTokenInput;
};


export type MutationUpdateAccessTokenProjectsArgs = {
  input: UpdateAccessTokenProjectsInput;
};


export type MutationCreateCustomChartArgs = {
  input: CreateCustomChartInput;
};


export type MutationUpdateCustomChartArgs = {
  input: UpdateCustomChartInput;
};


export type MutationDeleteCustomChartArgs = {
  input: DeleteCustomChartInput;
};


export type MutationGenerateLocalLicenseArgs = {
  input: GenerateLocalLicenseInput;
};


export type MutationUpdateOrganizationSubscriptionArgs = {
  input: UpdateOrganizationSubscriptionInput;
};


export type MutationUpdateLocalLicenseArgs = {
  input: UpdateLocalLicenseInput;
};


export type MutationMoveProjectsArgs = {
  input: MoveProjectsInput;
};


export type MutationCloneProjectsArgs = {
  input: CloneProjectsInput;
};


export type MutationCreateNewsletterSubscriptionArgs = {
  input: CreateNewsletterSubscriptionInput;
};


export type MutationDeleteNewsletterSubscriptionArgs = {
  input: DeleteNewsletterSubscriptionInput;
};


export type MutationCreateClientIdMappingArgs = {
  input: CreateClientIdMappingInput;
};

export type CreateRunQueueInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  access: RunQueueAccessType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRunQueuePayload = {
  __typename?: 'CreateRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  queueID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunQueuesInput = {
  queueIDs: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunQueuesPayload = {
  __typename?: 'DeleteRunQueuesPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AckRunQueueItemInput = {
  runQueueItemId: Scalars['ID'];
  runName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AckRunQueueItemPayload = {
  __typename?: 'AckRunQueueItemPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PopFromRunQueueInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  launchAgentId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PopFromRunQueuePayload = {
  __typename?: 'PopFromRunQueuePayload';
  runQueueItemId?: Maybe<Scalars['ID']>;
  runSpec?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFromRunQueueInput = {
  queueID: Scalars['ID'];
  runQueueItemId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFromRunQueuePayload = {
  __typename?: 'DeleteFromRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueueInput = {
  queueID: Scalars['ID'];
  runSpec: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueuePayload = {
  __typename?: 'PushToRunQueuePayload';
  runQueueItemId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLaunchAgentInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runQueues: Array<Scalars['ID']>;
  hostname: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLaunchAgentPayload = {
  __typename?: 'CreateLaunchAgentPayload';
  success?: Maybe<Scalars['Boolean']>;
  launchAgentId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLaunchAgentInput = {
  launchAgentId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLaunchAgentPayload = {
  __typename?: 'DeleteLaunchAgentPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLaunchAgentInput = {
  launchAgentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  runQueues?: Maybe<Array<Scalars['ID']>>;
  agentStatus?: Maybe<Scalars['String']>;
  stopPolling?: Maybe<Scalars['Boolean']>;
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLaunchAgentPayload = {
  __typename?: 'UpdateLaunchAgentPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelCustomerSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelCustomerSubscriptionPayload = {
  __typename?: 'CancelCustomerSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserToOrganizationInput = {
  userName: Scalars['String'];
  teams?: Maybe<Array<Scalars['String']>>;
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserToOrganizationPayload = {
  __typename?: 'AddUserToOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserFromOrganizationInput = {
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserFromOrganizationPayload = {
  __typename?: 'RemoveUserFromOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  organizationID: Scalars['ID'];
  flags?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserInput = {
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserPayload = {
  __typename?: 'UpdateOrganizationUserPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerSubscriptionInput = {
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  trial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStorageSubscriptionInput = {
  storagePlanId: Scalars['ID'];
  trackingPlanId: Scalars['ID'];
  organizationId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<Scalars['String']>;
  trial?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeCustomerSubscriptionInput = {
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerTrialInput = {
  userName: Scalars['String'];
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  quantity: Scalars['Int'];
  trialDays: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserLedTrialInput = {
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  orgSize: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForUserLedTrialInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForComputeHoursInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPaymentMethodInput = {
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerSubscriptionPayload = {
  __typename?: 'CreateCustomerSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type CreateStorageSubscriptionPayload = {
  __typename?: 'CreateStorageSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeCustomerSubscriptionPayload = {
  __typename?: 'UpgradeCustomerSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  organizationID: Scalars['ID'];
};

export type CreateCustomerTrialPayload = {
  __typename?: 'CreateCustomerTrialPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserLedTrialPayload = {
  __typename?: 'CreateUserLedTrialPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForUserLedTrialPayload = {
  __typename?: 'ContactUsForUserLedTrialPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForComputeHoursPayload = {
  __typename?: 'ContactUsForComputeHoursPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPaymentMethodPayload = {
  __typename?: 'UpdateCustomerPaymentMethodPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocalLicenseOrganizationInput = {
  newOrganizationName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocalLicenseOrganizationPayload = {
  __typename?: 'CreateLocalLicenseOrganizationPayload';
  organization: Organization;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteModelInput = {
  id: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteModelPayload = {
  __typename?: 'DeleteModelPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertModelInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  dockerImage?: Maybe<Scalars['String']>;
  repo?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  isBenchmark?: Maybe<Scalars['Boolean']>;
  linkedBenchmark?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertModelPayload = {
  __typename?: 'UpsertModelPayload';
  model?: Maybe<Project>;
  project?: Maybe<Project>;
  entityName?: Maybe<Scalars['String']>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RenameProjectInput = {
  entityName: Scalars['String'];
  oldProjectName: Scalars['String'];
  newProjectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunInput = {
  id: Scalars['ID'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunPayload = {
  __typename?: 'DeleteRunPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters: Scalars['JSONString'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunsPayload = {
  __typename?: 'DeleteRunsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteRunsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteRunsPayload = {
  __typename?: 'UndeleteRunsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertBucketInput = {
  name?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sweep?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONString']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  commit?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['Boolean']>;
  entityName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  jobProgram?: Maybe<Scalars['String']>;
  jobRepo?: Maybe<Scalars['String']>;
  jobType?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  runQueueItemId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertBucketPayload = {
  __typename?: 'UpsertBucketPayload';
  bucket?: Maybe<Run>;
  inserted?: Maybe<Scalars['Boolean']>;
  updateAvailable?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunGroupInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  newName?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunGroupPayload = {
  __typename?: 'UpsertRunGroupPayload';
  group?: Maybe<RunGroup>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSweepInput = {
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  config?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  controller?: Maybe<Scalars['JSONString']>;
  scheduler?: Maybe<Scalars['JSONString']>;
  priorRunsFilters?: Maybe<Scalars['JSONString']>;
  displayName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSweepPayload = {
  __typename?: 'UpsertSweepPayload';
  sweep?: Maybe<Sweep>;
  inserted?: Maybe<Scalars['Boolean']>;
  insertedPriorRuns?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
  configValidationWarnings: Array<Scalars['String']>;
};

export type CreateAgentInput = {
  host: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  sweep: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAgentPayload = {
  __typename?: 'CreateAgentPayload';
  agent?: Maybe<Agent>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AgentHeartbeatInput = {
  id: Scalars['ID'];
  metrics?: Maybe<Scalars['JSONString']>;
  runState?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AgentHeartbeatPayload = {
  __typename?: 'AgentHeartbeatPayload';
  agent?: Maybe<Agent>;
  commands?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ModifyRunsInput = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSONString']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  addTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ModifyRunsPayload = {
  __typename?: 'ModifyRunsPayload';
  runs: Array<Maybe<Run>>;
  runsSQL: Array<Maybe<Run>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveRunsInput = {
  filters: Scalars['JSONString'];
  sourceEntityName: Scalars['String'];
  sourceProjectName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  destinationProjectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveRunsPayload = {
  __typename?: 'MoveRunsPayload';
  task: Task;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StopRunInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StopRunPayload = {
  __typename?: 'StopRunPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddFilesInput = {
  names: Array<Maybe<Scalars['String']>>;
  modelName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFilesInput = {
  files: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFilesPayload = {
  __typename?: 'DeleteFilesPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddFilesPayload = {
  __typename?: 'AddFilesPayload';
  files?: Maybe<FileConnection>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type AddFilesPayloadFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CopyFileInput = {
  id: Scalars['ID'];
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CopyFilePayload = {
  __typename?: 'CopyFilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEntityInput = {
  name: Scalars['String'];
  defaultFramework?: Maybe<Scalars['String']>;
  invited?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAnonymousEntityInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEntityPayload = {
  __typename?: 'CreateEntityPayload';
  entity?: Maybe<Entity>;
  apiKey?: Maybe<ApiKey>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteEntityInput = {
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteEntityPayload = {
  __typename?: 'DeleteEntityPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTeamInput = {
  teamName: Scalars['String'];
  teamAdminUserName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionInput = {
  entityName: Scalars['String'];
  teamsEnabled?: Maybe<Scalars['Boolean']>;
  privateProjects?: Maybe<Scalars['Boolean']>;
  gpuEnabled?: Maybe<Scalars['DateTime']>;
  hubPreset?: Maybe<Scalars['String']>;
  hubSettings?: Maybe<HubSettingsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type HubSettingsInput = {
  repo?: Maybe<Scalars['String']>;
  disk?: Maybe<Scalars['String']>;
  redisEnabled?: Maybe<Scalars['Boolean']>;
  dockerEnabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInviteInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  addSeat?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvitePayload = {
  __typename?: 'CreateInvitePayload';
  invite?: Maybe<Invite>;
  success: Scalars['Boolean'];
  remainingSeats?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteInviteInput = {
  id?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteInvitePayload = {
  __typename?: 'DeleteInvitePayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id?: Maybe<Scalars['ID']>;
  defaultEntity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userInfo?: Maybe<Scalars['JSONString']>;
  settingsVisited?: Maybe<Scalars['Boolean']>;
  galleryVisited?: Maybe<Scalars['Boolean']>;
  onboardingHidden?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUserInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteUserInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteUserPayload = {
  __typename?: 'UndeleteUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PurgeUserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PurgeUserPayload = {
  __typename?: 'PurgeUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RateLimitsInput = {
  filestream?: Maybe<Scalars['Int']>;
  graphql?: Maybe<Scalars['Int']>;
};

export type SettingsInput = {
  defaultCloudRegionID?: Maybe<Scalars['String']>;
};

export type UpdateEntityInput = {
  entity: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  codeSavingEnabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimitsInput>;
  settings?: Maybe<SettingsInput>;
};

export type UpdateEntityPayload = {
  __typename?: 'UpdateEntityPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateServiceAccountInput = {
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateServiceAccountPayload = {
  __typename?: 'CreateServiceAccountPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateApiKeyInput = {
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateApiKeyPayload = {
  __typename?: 'GenerateApiKeyPayload';
  apiKey?: Maybe<ApiKey>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteApiKeyInput = {
  id?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteApiKeyPayload = {
  __typename?: 'DeleteApiKeyPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsertGalleryDiscussionInput = {
  spec: Scalars['String'];
  reportID: Scalars['ID'];
  sendEmail?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsertGalleryDiscussionPayload = {
  __typename?: 'InsertGalleryDiscussionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewsletterSubscriptionInput = {
  email?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewsletterSubscriptionPayload = {
  __typename?: 'CreateNewsletterSubscriptionPayload';
  newsletterSubscription: NewsletterSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteNewsletterSubscriptionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteNewsletterSubscriptionPayload = {
  __typename?: 'DeleteNewsletterSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertViewInput = {
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  locked?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  showcasedAt?: Maybe<Scalars['DateTime']>;
};

export type UpsertViewPayload = {
  __typename?: 'UpsertViewPayload';
  view?: Maybe<View>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveViewInput = {
  id: Scalars['ID'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveViewPayload = {
  __typename?: 'MoveViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewInput = {
  id?: Maybe<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewPayload = {
  __typename?: 'DeleteViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  pendingDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewsInput = {
  ids: Array<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewsPayload = {
  __typename?: 'DeleteViewsPayload';
  success?: Maybe<Scalars['Boolean']>;
  pendingDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StarViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StarViewPayload = {
  __typename?: 'StarViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarViewPayload = {
  __typename?: 'UnstarViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ViewViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ViewViewPayload = {
  __typename?: 'ViewViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSharedViewInput = {
  entityName: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSharedViewPayload = {
  __typename?: 'UpsertSharedViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTagColorInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  tagName: Scalars['String'];
  colorIndex?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTagColorPayload = {
  __typename?: 'SetTagColorPayload';
  tags: Array<Tag>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SubmitBenchmarkRunInput = {
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
};

export type SubmitBenchmarkRunPayload = {
  __typename?: 'SubmitBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PublishBenchmarkRunInput = {
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
};

export type PublishBenchmarkRunPayload = {
  __typename?: 'PublishBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBenchmarkRunInput = {
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBenchmarkRunPayload = {
  __typename?: 'UpdateBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberInput = {
  entityName: Scalars['String'];
  user: Scalars['ID'];
  admin?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberPayload = {
  __typename?: 'UpdateMemberPayload';
  member: Member;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFinishedRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStoppedRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  minimumRunDuration: Scalars['Duration'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStoppedRunAlertInput = {
  id: Scalars['ID'];
  minimumRunDuration?: Maybe<Scalars['Duration']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateScriptableRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAlertPayload = {
  __typename?: 'CreateAlertPayload';
  alert: Alert;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAlertPayload = {
  __typename?: 'UpdateAlertPayload';
  alert: Alert;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertPayload = {
  __typename?: 'DeleteAlertPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type NotifyScriptableRunAlertInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
  severity?: Maybe<AlertSeverity>;
  waitDuration?: Maybe<Scalars['Duration']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type NotifyScriptableRunAlertPayload = {
  __typename?: 'NotifyScriptableRunAlertPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackChannelSubscriptionInput = {
  alertID: Scalars['ID'];
  integrationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackChannelSubscriptionPayload = {
  __typename?: 'CreateSlackChannelSubscriptionPayload';
  subscription: SlackChannelSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEmailSubscriptionInput = {
  alertID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEmailSubscriptionPayload = {
  __typename?: 'CreateEmailSubscriptionPayload';
  subscription: EmailSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewCommentsAlertSubscriptionInput = {
  viewID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertSubscriptionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertSubscriptionPayload = {
  __typename?: 'DeleteAlertSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepInput = {
  id: Scalars['ID'];
  deleteRuns: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepsInput = {
  ids: Array<Scalars['ID']>;
  deleteRuns: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepsPayload = {
  __typename?: 'DeleteSweepsPayload';
  affectedAgents: Scalars['Int'];
  affectedRuns: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackIntegrationInput = {
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGitHubOAuthIntegrationInput = {
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  state: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateIntegrationPayload = {
  __typename?: 'CreateIntegrationPayload';
  integration: Integration;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteIntegrationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteIntegrationPayload = {
  __typename?: 'DeleteIntegrationPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBenchmarkDiscussionThreadInput = {
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewDiscussionCommentInput = {
  viewID: Scalars['ID'];
  discussionThreadID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  notifyAllSubscribers?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionThreadInput = {
  discussionThreadID: Scalars['ID'];
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionThreadPayload = {
  __typename?: 'UpdateDiscussionThreadPayload';
  discussionThread: DiscussionThread;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDiscussionThreadPayload = {
  __typename?: 'CreateDiscussionThreadPayload';
  discussionThread: DiscussionThread;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewDiscussionCommentPayload = {
  __typename?: 'CreateViewDiscussionCommentPayload';
  discussionThread: DiscussionThread;
  discussionComment: DiscussionComment;
  alertSubscription: AlertSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBenchmarkDiscussionCommentInput = {
  discussionThreadID: Scalars['ID'];
  parentCommentID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDiscussionCommentPayload = {
  __typename?: 'CreateDiscussionCommentPayload';
  discussionComment: DiscussionComment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionCommentInput = {
  discussionCommentID: Scalars['ID'];
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionCommentPayload = {
  __typename?: 'UpdateDiscussionCommentPayload';
  discussionComment: DiscussionComment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDiscussionCommentInput = {
  id: Scalars['ID'];
  deleteThread?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDiscussionCommentPayload = {
  __typename?: 'DeleteDiscussionCommentPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClaimAnonymousEntityInput = {
  anonymousApiKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClaimAnonymousEntityPayload = {
  __typename?: 'ClaimAnonymousEntityPayload';
  task: Task;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypeInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypePayload = {
  __typename?: 'CreateArtifactTypePayload';
  artifactType: ArtifactType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactTypeInput = {
  artifactTypeID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactTypePayload = {
  __typename?: 'UpdateArtifactTypePayload';
  artifactType: ArtifactType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactSequenceInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactCollectionPayload = {
  __typename?: 'CreateArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactCollectionPayload = {
  __typename?: 'UpdateArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  destinationArtifactTypeName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveArtifactCollectionPayload = {
  __typename?: 'MoveArtifactCollectionPayload';
  artifactCollection?: Maybe<ArtifactCollection>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactCollectionPayload = {
  __typename?: 'DeleteArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ArtifactAliasInput = {
  artifactCollectionName: Scalars['String'];
  alias: Scalars['String'];
};

export type CreateArtifactInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactCollectionName?: Maybe<Scalars['String']>;
  artifactCollectionNames?: Maybe<Array<Scalars['String']>>;
  runName?: Maybe<Scalars['String']>;
  digest: Scalars['String'];
  digestAlgorithm: ArtifactDigestAlgorithm;
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['JSONString']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  metadata?: Maybe<Scalars['JSONString']>;
  distributedID?: Maybe<Scalars['String']>;
  clientID?: Maybe<Scalars['ID']>;
  sequenceClientID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactPayload = {
  __typename?: 'CreateArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactInput = {
  artifactID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['JSONString']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  metadata?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactPayload = {
  __typename?: 'UpdateArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UseArtifactInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  artifactID: Scalars['ID'];
  usedAs?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UseArtifactPayload = {
  __typename?: 'UseArtifactPayload';
  run: Run;
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CommitArtifactInput = {
  artifactID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CommitArtifactPayload = {
  __typename?: 'CommitArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactInput = {
  artifactID: Scalars['ID'];
  deleteAliases?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactPayload = {
  __typename?: 'DeleteArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success: Scalars['Boolean'];
};

export type CreateArtifactManifestInput = {
  artifactID: Scalars['ID'];
  baseArtifactID?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  digest: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  type: ArtifactManifestType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactManifestPayload = {
  __typename?: 'CreateArtifactManifestPayload';
  artifactManifest: ArtifactManifest;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactManifestInput = {
  artifactManifestID: Scalars['ID'];
  baseArtifactID?: Maybe<Scalars['ID']>;
  digest?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactManifestPayload = {
  __typename?: 'UpdateArtifactManifestPayload';
  artifactManifest: ArtifactManifest;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactFilesInput = {
  artifactFiles: Array<CreateArtifactFileSpecInput>;
  storageLayout: ArtifactStorageLayout;
  cloudRegionID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactFileSpecInput = {
  artifactID: Scalars['ID'];
  name: Scalars['String'];
  md5: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  artifactManifestID?: Maybe<Scalars['ID']>;
};

export type CreateArtifactFilesPayload = {
  __typename?: 'CreateArtifactFilesPayload';
  files: FileConnection;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type CreateArtifactFilesPayloadFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CreateAccessTokenInput = {
  projects: Array<ProjectSpecifier>;
  viewId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectSpecifier = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
};

export type CreateAccessTokenPayload = {
  __typename?: 'CreateAccessTokenPayload';
  accessToken: AccessToken;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenPayload = {
  __typename?: 'RevokeAccessTokenPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenProjectsInput = {
  token: Scalars['String'];
  projects: Array<ProjectSpecifier>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenProjectsPayload = {
  __typename?: 'UpdateAccessTokenProjectsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateLocalLicenseInput = {
  contactEmail: Scalars['String'];
  customerName: Scalars['String'];
  seats: Scalars['Int'];
  teams: Scalars['Int'];
  storageGigs: Scalars['Int'];
  expDuration: Scalars['Int'];
  flags: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateLocalLicensePayload = {
  __typename?: 'GenerateLocalLicensePayload';
  localLicense: LocalLicense;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  access: Scalars['String'];
  spec: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomChartPayload = {
  __typename?: 'CreateCustomChartPayload';
  chart: CustomChart;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomChartPayload = {
  __typename?: 'UpdateCustomChartPayload';
  chart: CustomChart;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteCustomChartPayload = {
  __typename?: 'DeleteCustomChartPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionInput = {
  organizationSubscriptionID: Scalars['ID'];
  createStripeSubscription?: Maybe<Scalars['Boolean']>;
  planID?: Maybe<Scalars['ID']>;
  seats?: Maybe<Scalars['Int']>;
  privileges?: Maybe<Scalars['JSONString']>;
  type?: Maybe<OrganizationSubscriptionType>;
  status?: Maybe<OrganizationSubscriptionStatus>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionPayload = {
  __typename?: 'UpdateOrganizationSubscriptionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocalLicenseInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocalLicensePayload = {
  __typename?: 'UpdateLocalLicensePayload';
  localLicense: LocalLicense;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveProjectsInput = {
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveProjectsPayload = {
  __typename?: 'MoveProjectsPayload';
  tasks: Array<Task>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CloneProjectsInput = {
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CloneProjectsPayload = {
  __typename?: 'CloneProjectsPayload';
  tasks: Array<Task>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClientIdMappingInput = {
  clientID: Scalars['ID'];
  serverID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClientIdMappingPayload = {
  __typename?: 'CreateClientIDMappingPayload';
  clientIDMapping: ClientIdMapping;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type WbInstance = {
  __typename?: 'WBInstance';
  entities: Array<Entity>;
  views: ViewConnection;
  artifactSequences?: Maybe<ArtifactSequenceConnection>;
  projects?: Maybe<ProjectConnection>;
};


export type WbInstanceViewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type WbInstanceArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type WbInstanceProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SimpleProjectRunsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type SimpleProjectRunsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateSubscriptionMutationVariables = Exact<{
  entityName: Scalars['String'];
  teamsEnabled?: Maybe<Scalars['Boolean']>;
  privateProjects?: Maybe<Scalars['Boolean']>;
  gpuEnabled?: Maybe<Scalars['DateTime']>;
  hubPreset?: Maybe<Scalars['String']>;
  repo?: Maybe<Scalars['String']>;
  disk?: Maybe<Scalars['String']>;
  redisEnabled?: Maybe<Scalars['Boolean']>;
  dockerEnabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
}>;


export type UpdateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscription?: Maybe<(
    { __typename?: 'UpdateSubscriptionPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type UpdateEntityPhotoMutationVariables = Exact<{
  entityName: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateEntityPhotoMutation = (
  { __typename?: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename?: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type TeamQueryQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type TeamQueryQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
  )> }
);

export type UserQueryQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type UserQueryQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AllEntitiesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type AllEntitiesQuery = (
  { __typename?: 'Query' }
  & { entities?: Maybe<(
    { __typename?: 'EntityConnection' }
    & { edges: Array<(
      { __typename?: 'EntityEdge' }
      & { node?: Maybe<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'createdAt' | 'name' | 'memberCount' | 'runCount' | 'isTeam'>
        & { members: Array<(
          { __typename?: 'Member' }
          & Pick<Member, 'admin' | 'pending' | 'username' | 'email' | 'name' | 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateTeamMutationVariables = Exact<{
  teamName: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  teamAdminUserName?: Maybe<Scalars['String']>;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'CreateTeamPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type UpdateEntityAdminMutationVariables = Exact<{
  entityName: Scalars['String'];
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimitsInput>;
}>;


export type UpdateEntityAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename?: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'isPaid'>
      & { rateLimits?: Maybe<(
        { __typename?: 'RateLimits' }
        & Pick<RateLimits, 'filestream' | 'graphql'>
      )> }
    )> }
  )> }
);

export type CreateFinishedRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type CreateFinishedRunAlertMutation = (
  { __typename?: 'Mutation' }
  & { createFinishedRunAlert?: Maybe<(
    { __typename?: 'CreateAlertPayload' }
    & { alert: (
      { __typename?: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: (
        { __typename: 'FinishedRunCondition' }
        & Pick<FinishedRunCondition, 'success'>
      ) | { __typename: 'StoppedRunCondition' } | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename?: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      ) | (
        { __typename?: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type CreateStoppedRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
  minimumRunDuration: Scalars['Duration'];
}>;


export type CreateStoppedRunAlertMutation = (
  { __typename?: 'Mutation' }
  & { createStoppedRunAlert?: Maybe<(
    { __typename?: 'CreateAlertPayload' }
    & { alert: (
      { __typename?: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename?: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      ) | (
        { __typename?: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type UpdateStoppedRunAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  minimumRunDuration?: Maybe<Scalars['Duration']>;
}>;


export type UpdateStoppedRunAlertMutation = (
  { __typename?: 'Mutation' }
  & { updateStoppedRunAlert?: Maybe<(
    { __typename?: 'UpdateAlertPayload' }
    & { alert: (
      { __typename?: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename?: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      ) | (
        { __typename?: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type CreateScriptableRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type CreateScriptableRunAlertMutation = (
  { __typename?: 'Mutation' }
  & { createScriptableRunAlert?: Maybe<(
    { __typename?: 'CreateAlertPayload' }
    & { alert: (
      { __typename?: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | { __typename: 'StoppedRunCondition' } | (
        { __typename: 'ScriptableRunCondition' }
        & Pick<ScriptableRunCondition, 'success'>
      ), subscriptions: Array<(
        { __typename?: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      ) | (
        { __typename?: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type DeleteAlertMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertMutation = (
  { __typename?: 'Mutation' }
  & { deleteAlert?: Maybe<(
    { __typename?: 'DeleteAlertPayload' }
    & Pick<DeleteAlertPayload, 'success'>
  )> }
);

export type CreateSlackChannelSubscriptionMutationVariables = Exact<{
  alertID: Scalars['ID'];
  integrationID: Scalars['ID'];
}>;


export type CreateSlackChannelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createSlackChannelSubscription?: Maybe<(
    { __typename?: 'CreateSlackChannelSubscriptionPayload' }
    & { subscription: (
      { __typename?: 'SlackChannelSubscription' }
      & Pick<SlackChannelSubscription, 'id'>
    ) }
  )> }
);

export type CreateEmailSubscriptionMutationVariables = Exact<{
  alertID: Scalars['ID'];
}>;


export type CreateEmailSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createEmailSubscription?: Maybe<(
    { __typename?: 'CreateEmailSubscriptionPayload' }
    & { subscription: (
      { __typename?: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) }
  )> }
);

export type CreateViewCommentsAlertSubscriptionMutationVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type CreateViewCommentsAlertSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createViewCommentsAlertSubscription?: Maybe<(
    { __typename?: 'CreateEmailSubscriptionPayload' }
    & { subscription: (
      { __typename?: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) }
  )> }
);

export type DeleteAlertSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteAlertSubscription?: Maybe<(
    { __typename?: 'DeleteAlertSubscriptionPayload' }
    & Pick<DeleteAlertSubscriptionPayload, 'success'>
  )> }
);

export type AllProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type AllProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )>, entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'memberCount'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime' | 'computeHours'>
        )> }
      )>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ) }
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl'>
  )> }
);

export type BenchmarkProjectQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type BenchmarkProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'name' | 'description' | 'readOnly' | 'entityName' | 'views' | 'gitHubSubmissionRepo'>
    & { acceptedBenchmarkRuns?: Maybe<(
      { __typename?: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, rejectedBenchmarkRuns?: Maybe<(
      { __typename?: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, submittedBenchmarkRuns?: Maybe<(
      { __typename?: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type BenchmarkRunsFragmentFragment = (
  { __typename?: 'BenchmarkRunConnection' }
  & { edges: Array<(
    { __typename?: 'BenchmarkRunEdge' }
    & { node?: Maybe<(
      { __typename?: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'gitHubSubmissionPR' | 'createdAt' | 'details' | 'results' | 'state'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
      ), originalRun?: Maybe<(
        { __typename?: 'Run' }
        & Pick<Run, 'id' | 'name' | 'displayName'>
        & { project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )>, originalProject?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )>, run: (
        { __typename?: 'Run' }
        & Pick<Run, 'github' | 'commit' | 'id' | 'name' | 'displayName' | 'notes' | 'summaryMetrics'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username'>
        )>, diffPatchFile?: Maybe<(
          { __typename?: 'FileConnection' }
          & { edges: Array<(
            { __typename?: 'FileEdge' }
            & { node?: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes'>
            )> }
          )> }
        )> }
      ), benchmark: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      ) }
    )> }
  )> }
);

export type SubmitBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
}>;


export type SubmitBenchmarkRunMutation = (
  { __typename?: 'Mutation' }
  & { submitBenchmarkRun?: Maybe<(
    { __typename?: 'SubmitBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename?: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'results'>
    ) }
  )> }
);

export type PublishBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
}>;


export type PublishBenchmarkRunMutation = (
  { __typename?: 'Mutation' }
  & { publishBenchmarkRun?: Maybe<(
    { __typename?: 'PublishBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename?: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'gitHubSubmissionPR'>
    ) }
  )> }
);

export type UpdateBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateBenchmarkRunMutation = (
  { __typename?: 'Mutation' }
  & { updateBenchmarkRun?: Maybe<(
    { __typename?: 'UpdateBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename?: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'state' | 'details'>
    ) }
  )> }
);

export type CustomChartFragmentFragment = (
  { __typename?: 'CustomChart' }
  & Pick<CustomChart, 'id' | 'name' | 'displayName' | 'spec' | 'access'>
  & { entity: (
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'readOnly'>
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type CreateCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  access: Scalars['String'];
  spec: Scalars['JSONString'];
}>;


export type CreateCustomChartMutation = (
  { __typename?: 'Mutation' }
  & { createCustomChart?: Maybe<(
    { __typename?: 'CreateCustomChartPayload' }
    & { chart: (
      { __typename?: 'CustomChart' }
      & CustomChartFragmentFragment
    ) }
  )> }
);

export type UpdateCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateCustomChartMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomChart?: Maybe<(
    { __typename?: 'UpdateCustomChartPayload' }
    & { chart: (
      { __typename?: 'CustomChart' }
      & CustomChartFragmentFragment
    ) }
  )> }
);

export type DeleteCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
}>;


export type DeleteCustomChartMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomChart?: Maybe<(
    { __typename?: 'DeleteCustomChartPayload' }
    & Pick<DeleteCustomChartPayload, 'success'>
  )> }
);

export type CustomChartsQueryVariables = Exact<{
  entity: Scalars['String'];
}>;


export type CustomChartsQuery = (
  { __typename?: 'Query' }
  & { customCharts?: Maybe<(
    { __typename?: 'CustomChartConnection' }
    & { edges: Array<(
      { __typename?: 'CustomChartEdge' }
      & { node: (
        { __typename?: 'CustomChart' }
        & CustomChartFragmentFragment
      ) }
    )> }
  )> }
);

export type CustomChartQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomChartQuery = (
  { __typename?: 'Query' }
  & { customChart?: Maybe<(
    { __typename?: 'CustomChart' }
    & CustomChartFragmentFragment
  )> }
);

export type CreateCustomerSubscriptionMutationVariables = Exact<{
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  trial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerSubscription?: Maybe<(
    { __typename?: 'CreateCustomerSubscriptionPayload' }
    & Pick<CreateCustomerSubscriptionPayload, 'subscription' | 'organizationId' | 'clientMutationId'>
  )> }
);

export type CreateCustomerTrialMutationVariables = Exact<{
  planId: Scalars['ID'];
  userName: Scalars['String'];
  newOrganizationName: Scalars['String'];
  quantity: Scalars['Int'];
  trialDays: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerTrialMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerTrial?: Maybe<(
    { __typename?: 'CreateCustomerTrialPayload' }
    & Pick<CreateCustomerTrialPayload, 'subscription' | 'clientMutationId'>
  )> }
);

export type CreateUserLedTrialMutationVariables = Exact<{
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  orgSize: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateUserLedTrialMutation = (
  { __typename?: 'Mutation' }
  & { createUserLedTrial?: Maybe<(
    { __typename?: 'CreateUserLedTrialPayload' }
    & Pick<CreateUserLedTrialPayload, 'clientMutationId'>
  )> }
);

export type CancelCustomerSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CancelCustomerSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelCustomerSubscription?: Maybe<(
    { __typename?: 'CancelCustomerSubscriptionPayload' }
    & Pick<CancelCustomerSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateCustomerPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateCustomerPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerPaymentMethod?: Maybe<(
    { __typename?: 'UpdateCustomerPaymentMethodPayload' }
    & Pick<UpdateCustomerPaymentMethodPayload, 'success' | 'clientMutationId'>
  )> }
);

export type CustomerSubscriptionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerSubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
      & { stripeBillingInfo?: Maybe<(
        { __typename?: 'StripeBillingInfo' }
        & Pick<StripeBillingInfo, 'status' | 'cancelAtPeriodEnd' | 'currentPeriodEnd' | 'invoiceLink'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & Pick<PaymentMethod, 'id' | 'type' | 'cardType' | 'endingIn'>
        )> }
      )>, billingUser: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username'>
      ), subscriptions: Array<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'unitPrice' | 'billingInterval' | 'planType'>
        ) }
      )>, teams: Array<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'computeHours'>
        & { storage?: Maybe<(
          { __typename?: 'EntityStorageNode' }
          & Pick<EntityStorageNode, 'id' | 'size'>
        )>, artifact?: Maybe<(
          { __typename?: 'EntityStorageNode' }
          & Pick<EntityStorageNode, 'id' | 'size'>
        )> }
      )>, members: Array<(
        { __typename?: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'role'>
        & { teams?: Maybe<(
          { __typename?: 'EntityConnection' }
          & { edges: Array<(
            { __typename?: 'EntityEdge' }
            & { node?: Maybe<(
              { __typename?: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            )> }
          )> }
        )> }
      )>, pendingInvites: Array<(
        { __typename?: 'Invite' }
        & Pick<Invite, 'email'>
      )> }
    )> }
  )> }
);

export type OrganizationSubscriptionInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationSubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'orgType' | 'flags'>
    & { stripeBillingInfo?: Maybe<(
      { __typename?: 'StripeBillingInfo' }
      & Pick<StripeBillingInfo, 'status' | 'cancelAtPeriodEnd' | 'currentPeriodEnd' | 'invoiceLink'>
      & { paymentMethod?: Maybe<(
        { __typename?: 'PaymentMethod' }
        & Pick<PaymentMethod, 'id' | 'type' | 'cardType' | 'endingIn'>
      )> }
    )>, billingUser: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    ), subscriptions: Array<(
      { __typename?: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'unitPrice' | 'billingInterval' | 'planType'>
      ) }
    )>, members: Array<(
      { __typename?: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'role'>
      & { teams?: Maybe<(
        { __typename?: 'EntityConnection' }
        & { edges: Array<(
          { __typename?: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename?: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>, pendingInvites: Array<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'email'>
    )> }
  )> }
);

export type UpgradeCustomerSubscriptionMutationVariables = Exact<{
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpgradeCustomerSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { upgradeCustomerSubscription?: Maybe<(
    { __typename?: 'UpgradeCustomerSubscriptionPayload' }
    & Pick<UpgradeCustomerSubscriptionPayload, 'subscription' | 'organizationID' | 'clientMutationId'>
  )> }
);

export type DataTableRunFilesQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  dataFrameKeys: Array<Scalars['String']>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  order?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  columns?: Maybe<Array<Scalars['String']>>;
  includeRows: Scalars['Boolean'];
  includeSchema: Scalars['Boolean'];
}>;


export type DataTableRunFilesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dataFrameSchema'>
  & { dataFrame?: Maybe<(
    { __typename?: 'RowConnection' }
    & Pick<RowConnection, 'totalCount' | 'schema'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'RowEdge' }
      & { node?: Maybe<(
        { __typename?: 'RowType' }
        & Pick<RowType, 'row'>
      )> }
    )> }
  )> }
);

export type BenchmarkDiscussionThreadsQueryVariables = Exact<{
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
}>;


export type BenchmarkDiscussionThreadsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { benchmarkDiscussionThreads: (
      { __typename?: 'DiscussionThreadConnection' }
      & Pick<DiscussionThreadConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename?: 'DiscussionThreadEdge' }
        & { node: (
          { __typename?: 'DiscussionThread' }
          & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
          & { poster: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name'>
          ), comments: (
            { __typename?: 'DiscussionCommentConnection' }
            & Pick<DiscussionCommentConnection, 'totalCount'>
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type CreateBenchmarkDiscussionThreadMutationVariables = Exact<{
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type CreateBenchmarkDiscussionThreadMutation = (
  { __typename?: 'Mutation' }
  & { createBenchmarkDiscussionThread?: Maybe<(
    { __typename?: 'CreateDiscussionThreadPayload' }
    & { discussionThread: (
      { __typename?: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type UpdateDiscussionThreadMutationVariables = Exact<{
  discussionThreadID: Scalars['ID'];
  body: Scalars['String'];
}>;


export type UpdateDiscussionThreadMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscussionThread?: Maybe<(
    { __typename?: 'UpdateDiscussionThreadPayload' }
    & { discussionThread: (
      { __typename?: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type CreateBenchmarkDiscussionCommentMutationVariables = Exact<{
  discussionThreadID: Scalars['ID'];
  parentCommentID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
}>;


export type CreateBenchmarkDiscussionCommentMutation = (
  { __typename?: 'Mutation' }
  & { createBenchmarkDiscussionComment?: Maybe<(
    { __typename?: 'CreateDiscussionCommentPayload' }
    & { discussionComment: (
      { __typename?: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type UpdateDiscussionCommentMutationVariables = Exact<{
  discussionCommentID: Scalars['ID'];
  body: Scalars['String'];
}>;


export type UpdateDiscussionCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscussionComment?: Maybe<(
    { __typename?: 'UpdateDiscussionCommentPayload' }
    & { discussionComment: (
      { __typename?: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ) }
  )> }
);

export type DeleteDiscussionCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  deleteThread?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteDiscussionCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscussionComment?: Maybe<(
    { __typename?: 'DeleteDiscussionCommentPayload' }
    & Pick<DeleteDiscussionCommentPayload, 'success'>
  )> }
);

export type ViewDiscussionThreadsQueryVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type ViewDiscussionThreadsQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id'>
    & { discussionThreads: (
      { __typename?: 'DiscussionThreadConnection' }
      & Pick<DiscussionThreadConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename?: 'DiscussionThreadEdge' }
        & { node: (
          { __typename?: 'DiscussionThread' }
          & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
          & { poster: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
          ), comments: (
            { __typename?: 'DiscussionCommentConnection' }
            & Pick<DiscussionCommentConnection, 'totalCount'>
            & { pageInfo: (
              { __typename?: 'PageInfo' }
              & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
            ), edges: Array<(
              { __typename?: 'DiscussionCommentEdge' }
              & { node: (
                { __typename?: 'DiscussionComment' }
                & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'body'>
                & { poster: (
                  { __typename?: 'User' }
                  & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
                ) }
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type CreateViewDiscussionCommentMutationVariables = Exact<{
  viewID: Scalars['ID'];
  discussionThreadID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  notifyAllSubscribers?: Maybe<Scalars['Boolean']>;
}>;


export type CreateViewDiscussionCommentMutation = (
  { __typename?: 'Mutation' }
  & { createViewDiscussionComment?: Maybe<(
    { __typename?: 'CreateViewDiscussionCommentPayload' }
    & { discussionThread: (
      { __typename?: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ), discussionComment: (
      { __typename?: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'body'>
      & { poster: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ), alertSubscription: (
      { __typename?: 'SlackChannelSubscription' }
      & Pick<SlackChannelSubscription, 'id'>
    ) | (
      { __typename?: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) }
  )> }
);

export type EntityPageQueryVariables = Exact<{
  entityName: Scalars['String'];
  userStatsStartDate: Scalars['DateTime'];
}>;


export type EntityPageQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & TeamPageFragmentFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & ProfilePageFragmentFragment
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'admin'>
  )> }
);

export type EntityStorageQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  enableReferenceTracking?: Maybe<Scalars['Boolean']>;
}>;


export type EntityStorageQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'storageBytes' | 'referenceBytes' | 'computeHours'>
    & { storage?: Maybe<(
      { __typename?: 'EntityStorageNode' }
      & Pick<EntityStorageNode, 'id' | 'size'>
    )> }
  )> }
);

export type ArtifactNodeFragment = (
  { __typename?: 'ArtifactSequenceStorageNode' }
  & Pick<ArtifactSequenceStorageNode, 'id' | 'name' | 'size' | 'artifactSequenceID'>
  & { artifactType: (
    { __typename?: 'ArtifactType' }
    & Pick<ArtifactType, 'name'>
  ), artifacts: (
    { __typename?: 'ArtifactStorageNodeConnection' }
    & Pick<ArtifactStorageNodeConnection, 'totalSize'>
    & { edges: Array<(
      { __typename?: 'ArtifactStorageNodeEdge' }
      & { node: (
        { __typename?: 'ArtifactStorageNode' }
        & Pick<ArtifactStorageNode, 'id' | 'name' | 'size' | 'artifactID'>
      ) }
    )> }
  ) }
);

export type RunNodeFragment = (
  { __typename?: 'RunStorageNode' }
  & Pick<RunStorageNode, 'id' | 'name' | 'size' | 'runID'>
);

export type EntityStorageTreeQueryVariables = Exact<{
  entityName: Scalars['String'];
  enableReferenceTracking: Scalars['Boolean'];
}>;


export type EntityStorageTreeQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id'>
    & { storageTree?: Maybe<(
      { __typename?: 'EntityStorageNode' }
      & Pick<EntityStorageNode, 'id' | 'name' | 'size'>
      & { projects: (
        { __typename?: 'ProjectStorageNodeConnection' }
        & Pick<ProjectStorageNodeConnection, 'totalSize'>
        & { edges: Array<(
          { __typename?: 'ProjectStorageNodeEdge' }
          & { node: (
            { __typename?: 'ProjectStorageNode' }
            & Pick<ProjectStorageNode, 'id' | 'name' | 'size' | 'projectID'>
            & { runs: (
              { __typename?: 'RunStorageNodeConnection' }
              & Pick<RunStorageNodeConnection, 'totalSize'>
              & { edges: Array<(
                { __typename?: 'RunStorageNodeEdge' }
                & { node: (
                  { __typename?: 'RunStorageNode' }
                  & RunNodeFragment
                ) }
              )> }
            ), artifactSequences: (
              { __typename?: 'ArtifactSequenceStorageNodeConnection' }
              & Pick<ArtifactSequenceStorageNodeConnection, 'totalSize'>
              & { edges: Array<(
                { __typename?: 'ArtifactSequenceStorageNodeEdge' }
                & { node: (
                  { __typename?: 'ArtifactSequenceStorageNode' }
                  & ArtifactNodeFragment
                ) }
              )> }
            ) }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GroupQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'totalRunsWithGroup' | 'totalRunsWithJobType' | 'access' | 'readOnly'>
    & { entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type CreateSlackIntegrationMutationVariables = Exact<{
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
}>;


export type CreateSlackIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createSlackIntegration?: Maybe<(
    { __typename?: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename?: 'SlackIntegration' }
      & Pick<SlackIntegration, 'id'>
    ) | (
      { __typename?: 'GitHubOAuthIntegration' }
      & Pick<GitHubOAuthIntegration, 'id'>
    ) }
  )> }
);

export type CreateGitHubIntegrationMutationVariables = Exact<{
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  state: Scalars['String'];
}>;


export type CreateGitHubIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createGitHubOAuthIntegration?: Maybe<(
    { __typename?: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename?: 'SlackIntegration' }
      & Pick<SlackIntegration, 'id'>
    ) | (
      { __typename?: 'GitHubOAuthIntegration' }
      & Pick<GitHubOAuthIntegration, 'id'>
    ) }
  )> }
);

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { deleteIntegration?: Maybe<(
    { __typename?: 'DeleteIntegrationPayload' }
    & Pick<DeleteIntegrationPayload, 'success'>
  )> }
);

export type GenerateLocalLicenseMutationVariables = Exact<{
  customerName: Scalars['String'];
  contactEmail: Scalars['String'];
  teams: Scalars['Int'];
  seats: Scalars['Int'];
  storageGigs: Scalars['Int'];
  expDuration: Scalars['Int'];
  flags: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type GenerateLocalLicenseMutation = (
  { __typename?: 'Mutation' }
  & { generateLocalLicense?: Maybe<(
    { __typename?: 'GenerateLocalLicensePayload' }
    & Pick<GenerateLocalLicensePayload, 'clientMutationId'>
    & { localLicense: (
      { __typename?: 'LocalLicense' }
      & Pick<LocalLicense, 'id' | 'uid' | 'license' | 'customerName' | 'contactEmail' | 'notes'>
      & { generatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username'>
      ), claims: (
        { __typename?: 'LicenseClaims' }
        & Pick<LicenseClaims, 'seats' | 'teams' | 'storageGigs' | 'flags' | 'expiresAt'>
      ) }
    ) }
  )> }
);

export type AllLocalLicensesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllLocalLicensesQuery = (
  { __typename?: 'Query' }
  & { localLicenses: Array<Maybe<(
    { __typename?: 'LocalLicense' }
    & Pick<LocalLicense, 'id' | 'uid' | 'license' | 'customerName' | 'contactEmail' | 'notes'>
    & { generatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    ), claims: (
      { __typename?: 'LicenseClaims' }
      & Pick<LicenseClaims, 'seats' | 'teams' | 'storageGigs' | 'flags' | 'expiresAt'>
    ) }
  )>> }
);

export type LocalLicensesQueryVariables = Exact<{
  expired?: Maybe<Scalars['Boolean']>;
}>;


export type LocalLicensesQuery = (
  { __typename?: 'Query' }
  & { localLicenses: Array<Maybe<(
    { __typename?: 'LocalLicense' }
    & Pick<LocalLicense, 'id' | 'uid' | 'license' | 'customerName' | 'contactEmail' | 'notes'>
    & { generatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    ), claims: (
      { __typename?: 'LicenseClaims' }
      & Pick<LicenseClaims, 'seats' | 'teams' | 'storageGigs' | 'flags' | 'expiresAt'>
    ) }
  )>> }
);

export type UpdateLocalLicenseMutationVariables = Exact<{
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateLocalLicenseMutation = (
  { __typename?: 'Mutation' }
  & { updateLocalLicense?: Maybe<(
    { __typename?: 'UpdateLocalLicensePayload' }
    & Pick<UpdateLocalLicensePayload, 'clientMutationId'>
    & { localLicense: (
      { __typename?: 'LocalLicense' }
      & Pick<LocalLicense, 'id' | 'notes'>
    ) }
  )> }
);

export type HasLocalLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type HasLocalLicenseQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'hasLocalLicense'>
  )> }
);

export type LatestLocalVersionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestLocalVersionInfoQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & { latestLocalVersionInfo?: Maybe<(
      { __typename?: 'LocalVersionInfo' }
      & Pick<LocalVersionInfo, 'outOfDate' | 'latestVersionString'>
    )> }
  )> }
);

export type AvailableFilesQueryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  filenames: Array<Scalars['String']>;
}>;


export type AvailableFilesQueryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id'>
      & { files?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'md5' | 'sizeBytes'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ModelQueryVariables = Exact<{
  name: Scalars['String'];
  logCursor?: Maybe<Scalars['String']>;
  fileCursor?: Maybe<Scalars['String']>;
  fileLimit?: Maybe<Scalars['Int']>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityName?: Maybe<Scalars['String']>;
  bucketName: Scalars['String'];
  upload?: Maybe<Scalars['Boolean']>;
  detailed: Scalars['Boolean'];
  includeFiles?: Maybe<Scalars['Boolean']>;
}>;


export type ModelQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'readOnly' | 'name' | 'entityName' | 'description' | 'createdAt' | 'bucketCount' | 'access' | 'summaryMetrics' | 'views'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & SelectRunFragmentFragment
      & BasicRunFragmentFragment
      & DetailedRunFragmentFragment
      & RunFilesFragmentFragment
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl' | 'admin' | 'defaultFramework'>
  )> }
);

export type UpsertModelMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
}>;


export type UpsertModelMutation = (
  { __typename?: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename?: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )>, model?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )> }
  )> }
);

export type RenameProjectMutationVariables = Exact<{
  entityName: Scalars['String'];
  oldProjectName: Scalars['String'];
  newProjectName: Scalars['String'];
}>;


export type RenameProjectMutation = (
  { __typename?: 'Mutation' }
  & { renameProject?: Maybe<(
    { __typename?: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )>, model?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )> }
  )> }
);

export type DeleteModelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteModelMutation = (
  { __typename?: 'Mutation' }
  & { deleteModel?: Maybe<(
    { __typename?: 'DeleteModelPayload' }
    & Pick<DeleteModelPayload, 'success'>
  )> }
);

export type ModelsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  anonymous?: Maybe<Scalars['Boolean']>;
}>;


export type ModelsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl'>
  )> }
);

export type AddUserToOrganizationMutationVariables = Exact<{
  userName: Scalars['String'];
  teams?: Maybe<Array<Scalars['String']>>;
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
  userOrgRole: Scalars['String'];
}>;


export type AddUserToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { addUserToOrganization?: Maybe<(
    { __typename?: 'AddUserToOrganizationPayload' }
    & Pick<AddUserToOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RemoveUserFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromOrganization?: Maybe<(
    { __typename?: 'RemoveUserFromOrganizationPayload' }
    & Pick<RemoveUserFromOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  organizationID: Scalars['ID'];
  flags: Scalars['JSONString'];
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization?: Maybe<(
    { __typename?: 'UpdateOrganizationPayload' }
    & Pick<UpdateOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationUserMutationVariables = Exact<{
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationUser?: Maybe<(
    { __typename?: 'UpdateOrganizationUserPayload' }
    & Pick<UpdateOrganizationUserPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationSubscriptionMutationVariables = Exact<{
  organizationSubscriptionID: Scalars['ID'];
  createStripeSubscription: Scalars['Boolean'];
  planID?: Maybe<Scalars['ID']>;
  seats?: Maybe<Scalars['Int']>;
  privileges?: Maybe<Scalars['JSONString']>;
  type?: Maybe<OrganizationSubscriptionType>;
  status?: Maybe<OrganizationSubscriptionStatus>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateOrganizationSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationSubscription?: Maybe<(
    { __typename?: 'UpdateOrganizationSubscriptionPayload' }
    & Pick<UpdateOrganizationSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type AllOrganizationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;


export type AllOrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations: (
    { __typename?: 'OrganizationConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'OrganizationEdge' }
      & { node: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'orgType' | 'flags'>
        & { billingUser: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'email'>
        ), teams: Array<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
        )>, stripeBillingInfo?: Maybe<(
          { __typename?: 'StripeBillingInfo' }
          & Pick<StripeBillingInfo, 'stripeSubscriptionId' | 'status' | 'currentPeriodEnd' | 'cancelAtPeriodEnd'>
          & { paymentMethod?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & Pick<PaymentMethod, 'id' | 'cardType' | 'endingIn'>
          )> }
        )>, members: Array<(
          { __typename?: 'OrganizationMember' }
          & Pick<OrganizationMember, 'id' | 'admin' | 'name' | 'username' | 'photoUrl' | 'role'>
          & { teams?: Maybe<(
            { __typename?: 'EntityConnection' }
            & { edges: Array<(
              { __typename?: 'EntityEdge' }
              & { node?: Maybe<(
                { __typename?: 'Entity' }
                & Pick<Entity, 'id' | 'name'>
              )> }
            )> }
          )> }
        )>, subscriptions: Array<(
          { __typename?: 'OrganizationSubscription' }
          & Pick<OrganizationSubscription, 'seats' | 'id' | 'subscriptionType' | 'status' | 'expiresAt' | 'privileges'>
          & { plan: (
            { __typename?: 'Plan' }
            & Pick<Plan, 'id' | 'name'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type OrganizationSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'flags'>
      & { subscriptions: Array<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'subscriptionType' | 'status' | 'expiresAt' | 'privileges'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name'>
        ) }
      )>, teams: Array<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'computeHours'>
        & { oldComputeHours: Entity['computeHours'] }
      )>, members: Array<(
        { __typename?: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id'>
      )>, pendingInvites: Array<(
        { __typename?: 'Invite' }
        & Pick<Invite, 'id'>
      )> }
    )> }
  )> }
);

export type ProjectPickerQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type ProjectPickerQueryQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'createdAt'>
        )> }
      )> }
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'createdAt'>
        )> }
      )> }
    )>, teams?: Maybe<(
      { __typename?: 'EntityConnection' }
      & { edges: Array<(
        { __typename?: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id'>
          & { projects?: Maybe<(
            { __typename?: 'ProjectConnection' }
            & { edges: Array<(
              { __typename?: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName' | 'createdAt'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AllProjectNamesQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type AllProjectNamesQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'name'>
      )> }
    )> }
  )> }
);

export type MoveProjectsMutationVariables = Exact<{
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type MoveProjectsMutation = (
  { __typename?: 'Mutation' }
  & { moveProjects?: Maybe<(
    { __typename?: 'MoveProjectsPayload' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    )> }
  )> }
);

export type CloneProjectsMutationVariables = Exact<{
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CloneProjectsMutation = (
  { __typename?: 'Mutation' }
  & { cloneProjects?: Maybe<(
    { __typename?: 'CloneProjectsPayload' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    )> }
  )> }
);

export type PublishedWorkProjectQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type PublishedWorkProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'name' | 'description' | 'readOnly' | 'entityName' | 'views'>
    & { entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'photoUrl' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ), reports?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )> }
  )> }
);

export type RunLogLinesQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunLogLinesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id'>
      & { logLines?: Maybe<(
        { __typename?: 'LogLineConnection' }
        & { edges: Array<(
          { __typename?: 'LogLineEdge' }
          & { node: (
            { __typename?: 'LogLine' }
            & Pick<LogLine, 'id' | 'line' | 'level'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type RunQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'readOnly' | 'createdAt' | 'access' | 'views' | 'entityName' | 'isBenchmark'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'name' | 'colorIndex'>
    )>, linkedBenchmark?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'notes' | 'config' | 'summaryMetrics' | 'historyKeys' | 'github' | 'fileCount' | 'createdAt' | 'heartbeatAt' | 'commit' | 'jobType' | 'group' | 'host' | 'state' | 'stopped'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'colorIndex'>
      )>, sweep?: Maybe<(
        { __typename?: 'Sweep' }
        & Pick<Sweep, 'name' | 'displayName'>
      )>, runInfo?: Maybe<(
        { __typename?: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename?: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )>, diffPatchFile?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes'>
          )> }
        )> }
      )>, metadataFile?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes' | 'directUrl'>
          )> }
        )> }
      )>, inputArtifacts?: Maybe<(
        { __typename?: 'InputArtifactConnection' }
        & Pick<InputArtifactConnection, 'totalCount'>
      )>, outputArtifacts?: Maybe<(
        { __typename?: 'ArtifactConnection' }
        & Pick<ArtifactConnection, 'totalCount'>
      )>, outputLogFile?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes' | 'directUrl' | 'url'>
          )> }
        )> }
      )>, benchmarkRun?: Maybe<(
        { __typename?: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'details' | 'results' | 'gitHubSubmissionPR' | 'isCodeHidden' | 'state'>
        & { benchmark: (
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'readOnly' | 'views'>
        ), run: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name' | 'github' | 'summaryMetrics'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        ), originalProject?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )>, originalRun?: Maybe<(
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name'>
        )> }
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl'>
      )>, servicesAvailable?: Maybe<(
        { __typename?: 'RunServicesAvailable' }
        & Pick<RunServicesAvailable, 'tensorboard'>
      )> }
    )>, entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam' | 'codeSavingEnabled'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'accountType' | 'apiKey'>
      )>, claimingEntity?: Maybe<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type CreateRunQueueMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  access: RunQueueAccessType;
}>;


export type CreateRunQueueMutation = (
  { __typename?: 'Mutation' }
  & { createRunQueue?: Maybe<(
    { __typename?: 'CreateRunQueuePayload' }
    & Pick<CreateRunQueuePayload, 'success' | 'queueID'>
  )> }
);

export type DeleteRunQueuesMutationVariables = Exact<{
  queueIDs: Array<Scalars['ID']>;
}>;


export type DeleteRunQueuesMutation = (
  { __typename?: 'Mutation' }
  & { deleteRunQueues?: Maybe<(
    { __typename?: 'DeleteRunQueuesPayload' }
    & Pick<DeleteRunQueuesPayload, 'success'>
  )> }
);

export type PushToRunQueueMutationVariables = Exact<{
  queueID: Scalars['ID'];
  runSpec: Scalars['JSONString'];
}>;


export type PushToRunQueueMutation = (
  { __typename?: 'Mutation' }
  & { pushToRunQueue?: Maybe<(
    { __typename?: 'PushToRunQueuePayload' }
    & Pick<PushToRunQueuePayload, 'runQueueItemId'>
  )> }
);

export type DeleteFromRunQueueMutationVariables = Exact<{
  queueID: Scalars['ID'];
  runQueueItemId: Scalars['ID'];
}>;


export type DeleteFromRunQueueMutation = (
  { __typename?: 'Mutation' }
  & { deleteFromRunQueue?: Maybe<(
    { __typename?: 'DeleteFromRunQueuePayload' }
    & Pick<DeleteFromRunQueuePayload, 'success'>
  )> }
);

export type FetchRunQueuesFromProjectQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type FetchRunQueuesFromProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runQueues: Array<(
      { __typename?: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'name'>
    )> }
  )> }
);

export type RunsStateQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type RunsStateQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name' | 'state'>
        ) }
      )> }
    )> }
  )> }
);

export type RunSystemMetricsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunSystemMetricsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'events'>
    )> }
  )> }
);

export type BasicRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'readOnly' | 'framework' | 'displayName' | 'notes' | 'github' | 'group' | 'jobType' | 'createdAt' | 'heartbeatAt' | 'commit' | 'host' | 'state' | 'shouldStop' | 'groupCounts' | 'stopped' | 'defaultColorIndex'>
  & { sweep?: Maybe<(
    { __typename?: 'Sweep' }
    & Pick<Sweep, 'name' | 'displayName'>
  )>, agent?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'photoUrl'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'name' | 'colorIndex'>
  )>, benchmarkRun?: Maybe<(
    { __typename?: 'BenchmarkRun' }
    & Pick<BenchmarkRun, 'id'>
  )>, runInfo?: Maybe<(
    { __typename?: 'RunInfo' }
    & Pick<RunInfo, 'gpuCount' | 'gpu'>
  )> }
);

export type DetailedRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'history' | 'events' | 'fileCount'>
  & { logLines?: Maybe<(
    { __typename?: 'LogLineConnection' }
    & { edges: Array<(
      { __typename?: 'LogLineEdge' }
      & { node: (
        { __typename?: 'LogLine' }
        & Pick<LogLine, 'id' | 'line' | 'level'>
      ) }
    )> }
  )> }
);

export type RunFilesFragmentFragment = (
  { __typename?: 'Run' }
  & { files?: Maybe<(
    { __typename?: 'FileConnection' }
    & { edges: Array<(
      { __typename?: 'FileEdge' }
      & { node?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'directUrl' | 'url' | 'sizeBytes' | 'updatedAt'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type SelectRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'config' | 'summaryMetrics' | 'systemMetrics'>
);

export type HistoryRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'history'>
);

export type SampledHistoryRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'sampledHistory'>
);

export type RunsModelQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  jobKey?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  bucketIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestSubscribe: Scalars['Boolean'];
  selectEnable?: Maybe<Scalars['Boolean']>;
  basicEnable?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Scalars['Boolean']>;
  historyKeyInfo?: Maybe<Scalars['Boolean']>;
  historySamples?: Maybe<Scalars['Int']>;
  sampledHistory?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  historyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupLevel?: Maybe<Scalars['Int']>;
}>;


export type RunsModelQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'createdAt' | 'entityName' | 'description' | 'summaryMetrics' | 'views' | 'requestSubscribe' | 'runCount'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName' | 'historyKeys'>
          & SelectRunFragmentFragment
          & BasicRunFragmentFragment
          & HistoryRunFragmentFragment
          & SampledHistoryRunFragmentFragment
        ) }
      )>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
      ) }
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl' | 'admin'>
  )>, serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type RunsProjectQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  baseFilters?: Maybe<Scalars['JSONString']>;
  filters?: Maybe<Scalars['JSONString']>;
  selections?: Maybe<Scalars['JSONString']>;
}>;


export type RunsProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly' | 'name' | 'description' | 'createdAt' | 'entityName' | 'views' | 'requestSubscribe' | 'totalRunsWithGroup' | 'totalRunsWithJobType' | 'runCount'>
    & { filteredCount: Project['runCount'], selectedCount: Project['runCount'] }
    & { projectSettings?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type UpsertRunMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
}>;


export type UpsertRunMutation = (
  { __typename?: 'Mutation' }
  & { upsertBucket?: Maybe<(
    { __typename?: 'UpsertBucketPayload' }
    & Pick<UpsertBucketPayload, 'inserted'>
    & { bucket?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'notes'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'colorIndex'>
      )>, project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
        & { tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'colorIndex'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertRunGroupMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  newName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
}>;


export type UpsertRunGroupMutation = (
  { __typename?: 'Mutation' }
  & { upsertRunGroup?: Maybe<(
    { __typename?: 'UpsertRunGroupPayload' }
    & { group?: Maybe<(
      { __typename?: 'RunGroup' }
      & Pick<RunGroup, 'id' | 'name' | 'notes'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'colorIndex'>
      )>, project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
        & { tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'colorIndex'>
        )> }
      ) }
    )> }
  )> }
);

export type ModifyRunsMutationVariables = Exact<{
  filters?: Maybe<Scalars['JSONString']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  addTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeTags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type ModifyRunsMutation = (
  { __typename?: 'Mutation' }
  & { modifyRuns?: Maybe<(
    { __typename?: 'ModifyRunsPayload' }
    & { runsSQL: Array<Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl'>
      )> }
      & SelectRunFragmentFragment
      & BasicRunFragmentFragment
    )>> }
  )> }
);

export type MoveRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  sourceEntityName: Scalars['String'];
  sourceProjectName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  destinationProjectName: Scalars['String'];
}>;


export type MoveRunsMutation = (
  { __typename?: 'Mutation' }
  & { moveRuns?: Maybe<(
    { __typename?: 'MoveRunsPayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type DeleteRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  deleteArtifacts: Scalars['Boolean'];
}>;


export type DeleteRunsMutation = (
  { __typename?: 'Mutation' }
  & { deleteRuns?: Maybe<(
    { __typename?: 'DeleteRunsPayload' }
    & Pick<DeleteRunsPayload, 'clientMutationId'>
  )> }
);

export type UndeleteRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type UndeleteRunsMutation = (
  { __typename?: 'Mutation' }
  & { undeleteRuns?: Maybe<(
    { __typename?: 'UndeleteRunsPayload' }
    & Pick<UndeleteRunsPayload, 'clientMutationId'>
  )> }
);

export type DeleteRunMutationVariables = Exact<{
  id: Scalars['ID'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteRunMutation = (
  { __typename?: 'Mutation' }
  & { deleteRun?: Maybe<(
    { __typename?: 'DeleteRunPayload' }
    & Pick<DeleteRunPayload, 'clientMutationId'>
  )> }
);

export type StopRunMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StopRunMutation = (
  { __typename?: 'Mutation' }
  & { stopRun?: Maybe<(
    { __typename?: 'StopRunPayload' }
    & Pick<StopRunPayload, 'clientMutationId'>
  )> }
);

export type RunStopMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunStopMutation = (
  { __typename?: 'Mutation' }
  & { stopRun?: Maybe<(
    { __typename?: 'StopRunPayload' }
    & Pick<StopRunPayload, 'success'>
  )> }
);

export type DeleteFilesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type DeleteFilesMutation = (
  { __typename?: 'Mutation' }
  & { deleteFiles?: Maybe<(
    { __typename?: 'DeleteFilesPayload' }
    & Pick<DeleteFilesPayload, 'clientMutationId' | 'success'>
  )> }
);

export type ServerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerInfoQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'isGoogleIap' | 'slackClientID'>
    & { availableCloudRegions: Array<(
      { __typename?: 'CloudRegion' }
      & Pick<CloudRegion, 'id' | 'provider' | 'region'>
    )> }
  )> }
);

export type LicenseExpiredQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseExpiredQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'licenseExpired'>
  )> }
);

export type UserLimitReachedQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLimitReachedQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'userLimitReached'>
  )> }
);

export type FrontendHostQueryVariables = Exact<{ [key: string]: never; }>;


export type FrontendHostQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'frontendHost'>
  )> }
);

export type CreateStorageSubscriptionMutationVariables = Exact<{
  storagePlanId: Scalars['ID'];
  trackingPlanId: Scalars['ID'];
  organizationId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<Scalars['String']>;
  trial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateStorageSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createStorageSubscription?: Maybe<(
    { __typename?: 'CreateStorageSubscriptionPayload' }
    & Pick<CreateStorageSubscriptionPayload, 'subscription' | 'clientMutationId'>
  )> }
);

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = (
  { __typename?: 'Query' }
  & { plans: Array<Maybe<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'stripePlanId' | 'maxSeats' | 'defaultPrivileges' | 'planType' | 'planVisibility' | 'unitPrice' | 'billingInterval'>
  )>> }
);

export type UpsertSweepMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  priorRunsFilters?: Maybe<Scalars['JSONString']>;
}>;


export type UpsertSweepMutation = (
  { __typename?: 'Mutation' }
  & { upsertSweep?: Maybe<(
    { __typename?: 'UpsertSweepPayload' }
    & Pick<UpsertSweepPayload, 'inserted' | 'insertedPriorRuns'>
    & { sweep?: Maybe<(
      { __typename?: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'description' | 'state'>
    )> }
  )> }
);

export type DeleteSweepsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  deleteRuns: Scalars['Boolean'];
}>;


export type DeleteSweepsMutation = (
  { __typename?: 'Mutation' }
  & { deleteSweeps?: Maybe<(
    { __typename?: 'DeleteSweepsPayload' }
    & Pick<DeleteSweepsPayload, 'affectedAgents' | 'affectedRuns'>
  )> }
);

export type GetTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename: 'Task' }
    & Pick<Task, 'id' | 'name' | 'state' | 'progress' | 'createdAt' | 'updatedAt' | 'type'>
  )> }
);

export type TeamOrganizationQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type TeamOrganizationQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam' | 'computeHours'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
      & { members: Array<(
        { __typename?: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id' | 'username' | 'role'>
      )>, subscriptions: Array<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'privileges'>
      )> }
    )> }
  )> }
);

export type AvailableTeamQueryVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type AvailableTeamQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'available'>
  )> }
);

export type UserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type UserContextQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { views?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & Pick<View, 'id' | 'spec'>
        )> }
      )> }
    )> }
  )> }
);

export type UserOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserOrganizationsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
    )> }
  )> }
);

export type EntityQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type EntityQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'readOnly' | 'readOnlyAdmin' | 'isTeam' | 'privateOnly' | 'storageBytes' | 'codeSavingEnabled' | 'defaultAccess' | 'isPaid' | 'computeHours'>
    & { settings: (
      { __typename?: 'Settings' }
      & { defaultCloudRegion: (
        { __typename?: 'CloudRegion' }
        & Pick<CloudRegion, 'id' | 'provider' | 'region'>
      ) }
    ), rateLimits?: Maybe<(
      { __typename?: 'RateLimits' }
      & Pick<RateLimits, 'filestream' | 'graphql'>
    )>, members: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'admin' | 'pending' | 'email' | 'username' | 'name' | 'photoUrl' | 'accountType' | 'apiKey'>
    )>, defaultAlerts: Array<(
      { __typename?: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: (
        { __typename: 'FinishedRunCondition' }
        & Pick<FinishedRunCondition, 'success'>
      ) | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      ) | (
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      )> }
    )>, integrations?: Maybe<(
      { __typename?: 'IntegrationConnection' }
      & { edges: Array<(
        { __typename?: 'IntegrationEdge' }
        & { node?: Maybe<(
          { __typename: 'SlackIntegration' }
          & Pick<SlackIntegration, 'teamName' | 'channelName' | 'id'>
        ) | (
          { __typename: 'GitHubOAuthIntegration' }
          & Pick<GitHubOAuthIntegration, 'id'>
        )> }
      )> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
      & { billingUser: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username'>
      ), subscriptions: Array<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'unitPrice' | 'billingInterval' | 'planType'>
        ) }
      )>, members: Array<(
        { __typename?: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'role'>
      )>, pendingInvites: Array<(
        { __typename?: 'Invite' }
        & Pick<Invite, 'email'>
      )> }
    )> }
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'accountType'>
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserConnection' }
    & { edges: Array<(
      { __typename?: 'UserEdge' }
      & { node?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
      )> }
    )> }
  )> }
);

export type SetUserAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  admin: Scalars['Boolean'];
}>;


export type SetUserAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type PurgeUserMutationVariables = Exact<{
  username: Scalars['String'];
  email: Scalars['String'];
}>;


export type PurgeUserMutation = (
  { __typename?: 'Mutation' }
  & { purgeUser?: Maybe<(
    { __typename?: 'PurgeUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'deletedAt'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'DeleteUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type UndeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UndeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { undeleteUser?: Maybe<(
    { __typename?: 'UndeleteUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type CreateInviteMutationVariables = Exact<{
  entityName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  addSeat?: Maybe<Scalars['Boolean']>;
}>;


export type CreateInviteMutation = (
  { __typename?: 'Mutation' }
  & { createInvite?: Maybe<(
    { __typename?: 'CreateInvitePayload' }
    & Pick<CreateInvitePayload, 'success' | 'remainingSeats'>
    & { invite?: Maybe<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id' | 'name' | 'email' | 'createdAt'>
      & { toUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  )> }
);

export type DeleteInviteMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
}>;


export type DeleteInviteMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvite?: Maybe<(
    { __typename?: 'DeleteInvitePayload' }
    & Pick<DeleteInvitePayload, 'success'>
  )> }
);

export type UpdateMemberMutationVariables = Exact<{
  entityName: Scalars['String'];
  user: Scalars['ID'];
  admin: Scalars['Boolean'];
}>;


export type UpdateMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateMember?: Maybe<(
    { __typename?: 'UpdateMemberPayload' }
    & { member: (
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'admin'>
    ) }
  )> }
);

export type CreateServiceAccountMutationVariables = Exact<{
  entityName: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateServiceAccountMutation = (
  { __typename?: 'Mutation' }
  & { createServiceAccount?: Maybe<(
    { __typename?: 'CreateServiceAccountPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
      & { apiKeys?: Maybe<(
        { __typename?: 'ApiKeyConnection' }
        & { edges: Array<(
          { __typename?: 'ApiKeyEdge' }
          & { node?: Maybe<(
            { __typename?: 'ApiKey' }
            & Pick<ApiKey, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CreateEntityMutationVariables = Exact<{
  name: Scalars['String'];
  invited?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
}>;


export type CreateEntityMutation = (
  { __typename?: 'Mutation' }
  & { createEntity?: Maybe<(
    { __typename?: 'CreateEntityPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'invitedTeam'>
      & { projects?: Maybe<(
        { __typename?: 'ProjectConnection' }
        & { edges: Array<(
          { __typename?: 'ProjectEdge' }
          & { node?: Maybe<(
            { __typename?: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        )> }
      )> }
    )>, apiKey?: Maybe<(
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'name'>
    )> }
  )> }
);

export type CreateUserFromAdminMutationVariables = Exact<{
  email: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserFromAdminMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'CreateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin'>
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  defaultEntity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  settingsVisited?: Maybe<Scalars['Boolean']>;
  galleryVisited?: Maybe<Scalars['Boolean']>;
  onboardingHidden?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'flags' | 'entity'>
    )> }
  )> }
);

export type UpdateUserInfoMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  userInfo?: Maybe<Scalars['JSONString']>;
  defaultEntity?: Maybe<Scalars['String']>;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'userInfo' | 'photoUrl' | 'entity'>
    )> }
  )> }
);

export type RedeemHubAccessCodeMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type RedeemHubAccessCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'flags'>
    )> }
  )> }
);

export type GenerateApiKeyMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
}>;


export type GenerateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { generateApiKey?: Maybe<(
    { __typename?: 'GenerateApiKeyPayload' }
    & { apiKey?: Maybe<(
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { deleteApiKey?: Maybe<(
    { __typename?: 'DeleteApiKeyPayload' }
    & Pick<DeleteApiKeyPayload, 'success'>
  )> }
);

export type UpdateEntityMutationVariables = Exact<{
  entityName: Scalars['String'];
  defaultAccess?: Maybe<Scalars['String']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  codeSavingEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<SettingsInput>;
}>;


export type UpdateEntityMutation = (
  { __typename?: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename?: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'privateOnly' | 'limits'>
      & { settings: (
        { __typename?: 'Settings' }
        & { defaultCloudRegion: (
          { __typename?: 'CloudRegion' }
          & Pick<CloudRegion, 'id' | 'provider' | 'region'>
        ) }
      ) }
    )> }
  )> }
);

export type ClaimAnonymousEntityMutationVariables = Exact<{
  anonymousApiKey: Scalars['String'];
}>;


export type ClaimAnonymousEntityMutation = (
  { __typename?: 'Mutation' }
  & { claimAnonymousEntity?: Maybe<(
    { __typename?: 'ClaimAnonymousEntityPayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    ) }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
  )> }
);

export type ViewerQueryVariables = Exact<{
  includeStorage?: Maybe<Scalars['Boolean']>;
}>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'authId' | 'admin' | 'email' | 'entity' | 'defaultFramework' | 'photoUrl' | 'flags' | 'code' | 'username' | 'createdAt' | 'name' | 'accountType' | 'userInfo' | 'limits' | 'signupRequired'>
    & { teams?: Maybe<(
      { __typename?: 'EntityConnection' }
      & { edges: Array<(
        { __typename?: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam' | 'organizationId'>
        )> }
      )> }
    )>, organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
    )>, apiKeys?: Maybe<(
      { __typename?: 'ApiKeyConnection' }
      & { edges: Array<(
        { __typename?: 'ApiKeyEdge' }
        & { node?: Maybe<(
          { __typename?: 'ApiKey' }
          & Pick<ApiKey, 'id' | 'name'>
        )> }
      )> }
    )>, defaultEntity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, userEntity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'codeSavingEnabled' | 'storageBytes' | 'isTeam'>
      & { settings: (
        { __typename?: 'Settings' }
        & { defaultCloudRegion: (
          { __typename?: 'CloudRegion' }
          & Pick<CloudRegion, 'id' | 'provider' | 'region'>
        ) }
      ), defaultAlerts: Array<(
        { __typename?: 'Alert' }
        & Pick<Alert, 'id'>
        & { condition: (
          { __typename: 'FinishedRunCondition' }
          & Pick<FinishedRunCondition, 'success'>
        ) | (
          { __typename: 'StoppedRunCondition' }
          & Pick<StoppedRunCondition, 'minimumRunDuration'>
        ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
          { __typename: 'SlackChannelSubscription' }
          & Pick<SlackChannelSubscription, 'id'>
        ) | (
          { __typename: 'EmailSubscription' }
          & Pick<EmailSubscription, 'id'>
        )> }
      )>, integrations?: Maybe<(
        { __typename?: 'IntegrationConnection' }
        & { edges: Array<(
          { __typename?: 'IntegrationEdge' }
          & { node?: Maybe<(
            { __typename: 'SlackIntegration' }
            & Pick<SlackIntegration, 'teamName' | 'channelName' | 'id'>
          ) | (
            { __typename: 'GitHubOAuthIntegration' }
            & Pick<GitHubOAuthIntegration, 'id'>
          )> }
        )> }
      )>, claimedEntities?: Maybe<(
        { __typename?: 'EntityConnection' }
        & { edges: Array<(
          { __typename?: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename?: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ViewerProjectsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type ViewerProjectsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'entity' | 'photoUrl' | 'username' | 'createdAt'>
    & { linkedProjects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
          & { runs?: Maybe<(
            { __typename?: 'RunConnection' }
            & { edges: Array<(
              { __typename?: 'RunEdge' }
              & { node: (
                { __typename?: 'Run' }
                & Pick<Run, 'id' | 'name' | 'notes' | 'summaryMetrics' | 'displayName' | 'createdAt'>
                & { project?: Maybe<(
                  { __typename?: 'Project' }
                  & Pick<Project, 'id' | 'entityName' | 'name'>
                )>, benchmarkRun?: Maybe<(
                  { __typename?: 'BenchmarkRun' }
                  & Pick<BenchmarkRun, 'id' | 'results'>
                  & { originalRun?: Maybe<(
                    { __typename?: 'Run' }
                    & Pick<Run, 'id' | 'name'>
                  )> }
                )> }
              ) }
            )> }
          )> }
        )> }
      )> }
    )>, projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewerQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQueryQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'admin' | 'email' | 'entity' | 'defaultFramework' | 'photoUrl' | 'userInfo' | 'flags' | 'code' | 'username' | 'createdAt' | 'signupRequired'>
    & { teams?: Maybe<(
      { __typename?: 'EntityConnection' }
      & { edges: Array<(
        { __typename?: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id' | 'isPaid' | 'name' | 'photoUrl'>
        )> }
      )> }
    )>, organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
      & { subscriptions: Array<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'seats'>
        & { plan: (
          { __typename?: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'maxSeats' | 'planType'>
        ) }
      )> }
    )>, apiKeys?: Maybe<(
      { __typename?: 'ApiKeyConnection' }
      & { edges: Array<(
        { __typename?: 'ApiKeyEdge' }
        & { node?: Maybe<(
          { __typename?: 'ApiKey' }
          & Pick<ApiKey, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewFragmentFragment = (
  { __typename?: 'View' }
  & Pick<View, 'id' | 'type' | 'name' | 'displayName' | 'description' | 'spec' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )> }
);

export type ViewFragmentMetadataFragment = (
  { __typename?: 'View' }
  & Pick<View, 'id' | 'type' | 'name' | 'displayName' | 'description' | 'updatedAt' | 'createdAt' | 'starred' | 'starCount' | 'parentId' | 'locked' | 'viewCount'>
  & { updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )>, alertSubscription?: Maybe<(
    { __typename?: 'SlackChannelSubscription' }
    & Pick<SlackChannelSubscription, 'id'>
  ) | (
    { __typename?: 'EmailSubscription' }
    & Pick<EmailSubscription, 'id'>
  )> }
);

export type ViewsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
}>;


export type ViewsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'views' | 'totalRunsWithGroup' | 'totalRunsWithJobType'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentFragment
        )> }
      )> }
    )>, projectSettings?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ViewsMetadataQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
}>;


export type ViewsMetadataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'views' | 'totalRunsWithGroup' | 'totalRunsWithJobType'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )> }
  )> }
);

export type UpsertViewMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
}>;


export type UpsertViewMutation = (
  { __typename?: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename?: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename?: 'View' }
      & ViewFragmentFragment
    )> }
  )> }
);

export type DeleteViewMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeleteViewMutation = (
  { __typename?: 'Mutation' }
  & { deleteView?: Maybe<(
    { __typename?: 'DeleteViewPayload' }
    & Pick<DeleteViewPayload, 'success'>
  )> }
);

export type CreateSharedViewMutationVariables = Exact<{
  entityName: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
}>;


export type CreateSharedViewMutation = (
  { __typename?: 'Mutation' }
  & { upsertSharedView?: Maybe<(
    { __typename?: 'UpsertSharedViewPayload' }
    & { view?: Maybe<(
      { __typename?: 'View' }
      & ViewFragmentFragment
    )> }
  )> }
);

export type CreateAccessTokenMutationVariables = Exact<{
  projects: Array<ProjectSpecifier>;
  viewId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { createAccessToken?: Maybe<(
    { __typename?: 'CreateAccessTokenPayload' }
    & Pick<CreateAccessTokenPayload, 'clientMutationId'>
    & { accessToken: (
      { __typename?: 'AccessToken' }
      & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails' | 'createdAt' | 'lastAccessedAt' | 'revokedAt'>
      & { view: (
        { __typename?: 'View' }
        & Pick<View, 'id'>
      ), createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'email' | 'name'>
      ), projects: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    ) }
  )> }
);

export type RevokeAccessTokenMutationVariables = Exact<{
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RevokeAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { revokeAccessToken?: Maybe<(
    { __typename?: 'RevokeAccessTokenPayload' }
    & Pick<RevokeAccessTokenPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateAccessTokenProjectsMutationVariables = Exact<{
  token: Scalars['String'];
  projects: Array<ProjectSpecifier>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateAccessTokenProjectsMutation = (
  { __typename?: 'Mutation' }
  & { updateAccessTokenProjects?: Maybe<(
    { __typename?: 'UpdateAccessTokenProjectsPayload' }
    & Pick<UpdateAccessTokenProjectsPayload, 'success' | 'clientMutationId'>
  )> }
);

export type ArtifactCollectionQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  artifactCursor?: Maybe<Scalars['String']>;
  artifactFirst?: Maybe<Scalars['Int']>;
}>;


export type ArtifactCollectionQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name' | 'description'>
      & { artifactSequence?: Maybe<(
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
        & { artifacts: (
          { __typename?: 'VersionedArtifactConnection' }
          & Pick<VersionedArtifactConnection, 'totalCount'>
          & { pageInfo: (
            { __typename?: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename?: 'VersionedArtifactEdge' }
            & { node: (
              { __typename?: 'Artifact' }
              & Pick<Artifact, 'id' | 'digest' | 'commitHash' | 'state' | 'createdAt'>
              & { aliases: Array<(
                { __typename?: 'ArtifactAlias' }
                & Pick<ArtifactAlias, 'artifactCollectionName' | 'alias'>
              )> }
            ) }
          )> }
        ), aliases?: Maybe<(
          { __typename?: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename?: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename?: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'alias'>
              & { artifact: (
                { __typename?: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
              ) }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactDagUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name'>
);

export type ArtifactDagRunFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'id' | 'displayName' | 'jobType'>
  & { runName: Run['name'] }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )>, inputArtifacts?: Maybe<(
    { __typename?: 'InputArtifactConnection' }
    & { edges: Array<(
      { __typename?: 'InputArtifactEdge' }
      & { node?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id' | 'commitHash' | 'versionIndex'>
        & { artifactSequence: (
          { __typename?: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id' | 'name'>
        ), artifactType: (
          { __typename?: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
          & { project: (
            { __typename?: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          ) }
        ) }
      )> }
    )> }
  )>, outputArtifacts?: Maybe<(
    { __typename?: 'ArtifactConnection' }
    & { edges: Array<(
      { __typename?: 'ArtifactEdge' }
      & { node?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id' | 'commitHash' | 'versionIndex'>
        & { artifactSequence: (
          { __typename?: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id' | 'name'>
        ), artifactType: (
          { __typename?: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
          & { project: (
            { __typename?: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type ArtifactDagQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactName: Scalars['String'];
}>;


export type ArtifactDagQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name' | 'description'>
      & { artifact?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id' | 'commitHash' | 'versionIndex'>
        & { artifactSequence: (
          { __typename?: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id' | 'name'>
        ), createdBy: (
          { __typename: 'Run' }
          & ArtifactDagRunFragmentFragment
        ) | (
          { __typename: 'User' }
          & ArtifactDagUserFragmentFragment
        ), usedBy: (
          { __typename?: 'RunConnection' }
          & { edges: Array<(
            { __typename?: 'RunEdge' }
            & { node: (
              { __typename?: 'Run' }
              & ArtifactDagRunFragmentFragment
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type ArtifactFilesQueryVariables = Exact<{
  artifactID: Scalars['ID'];
  artifactFileNames: Array<Scalars['String']>;
}>;


export type ArtifactFilesQuery = (
  { __typename?: 'Query' }
  & { artifact?: Maybe<(
    { __typename?: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt'>
    & { files: (
      { __typename?: 'FileConnection' }
      & { edges: Array<(
        { __typename?: 'FileEdge' }
        & { node?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'name' | 'displayName' | 'digest' | 'url' | 'directUrl' | 'isReference' | 'sizeBytes' | 'updatedAt'>
        )> }
      )> }
    ) }
  )> }
);

export type ArtifactIdQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactID: Scalars['String'];
}>;


export type ArtifactIdQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifact?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id'>
      )> }
    )> }
  )> }
);

export type ArtifactPathInfoQueryVariables = Exact<{
  artifactId: Scalars['ID'];
}>;


export type ArtifactPathInfoQuery = (
  { __typename?: 'Query' }
  & { artifact?: Maybe<(
    { __typename?: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt' | 'commitHash' | 'versionIndex'>
    & { artifactSequence: (
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
    ), artifactType: (
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      ) }
    ) }
  )> }
);

export type ArtifactManifestQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactID: Scalars['String'];
}>;


export type ArtifactManifestQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifact?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id' | 'createdAt'>
        & { currentManifest?: Maybe<(
          { __typename?: 'ArtifactManifest' }
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'directUrl'>
          ) }
        )> }
      )> }
    )> }
  )>, serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & { defaultCloudRegion: (
      { __typename?: 'CloudRegion' }
      & Pick<CloudRegion, 'id'>
    ) }
  )> }
);

export type ArtifactManifestFromIdQueryVariables = Exact<{
  artifactId: Scalars['ID'];
}>;


export type ArtifactManifestFromIdQuery = (
  { __typename?: 'Query' }
  & { artifact?: Maybe<(
    { __typename?: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt'>
    & { artifactSequence: (
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
    ), artifactType: (
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      ) }
    ), currentManifest?: Maybe<(
      { __typename?: 'ArtifactManifest' }
      & Pick<ArtifactManifest, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'directUrl'>
      ) }
    )> }
  )>, serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & { defaultCloudRegion: (
      { __typename?: 'CloudRegion' }
      & Pick<CloudRegion, 'id'>
    ) }
  )> }
);

export type ResolveLatestSequenceAliasToArtifactIdQueryVariables = Exact<{
  sequenceId: Scalars['ID'];
}>;


export type ResolveLatestSequenceAliasToArtifactIdQuery = (
  { __typename?: 'Query' }
  & { artifactSequence?: Maybe<(
    { __typename?: 'ArtifactSequence' }
    & { latestArtifact?: Maybe<(
      { __typename?: 'Artifact' }
      & Pick<Artifact, 'id'>
    )> }
  )> }
);

export type ArtifactCommitHashQueryVariables = Exact<{
  id: Scalars['ID'];
  artifactCollectionName: Scalars['String'];
}>;


export type ArtifactCommitHashQuery = (
  { __typename?: 'Query' }
  & { artifact?: Maybe<(
    { __typename?: 'Artifact' }
    & Pick<Artifact, 'id' | 'commitHash'>
  )> }
);

export type CreateArtifactTypeMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateArtifactTypeMutation = (
  { __typename?: 'Mutation' }
  & { createArtifactType?: Maybe<(
    { __typename?: 'CreateArtifactTypePayload' }
    & { artifactType: (
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateArtifactMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  labels?: Maybe<Scalars['JSONString']>;
  metadata?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateArtifactMutation = (
  { __typename?: 'Mutation' }
  & { updateArtifact?: Maybe<(
    { __typename?: 'UpdateArtifactPayload' }
    & { artifact: (
      { __typename?: 'Artifact' }
      & Pick<Artifact, 'id' | 'digest' | 'description' | 'labels'>
      & { aliases: Array<(
        { __typename?: 'ArtifactAlias' }
        & Pick<ArtifactAlias, 'id' | 'artifactCollectionName' | 'alias'>
      )> }
    ) }
  )> }
);

export type DeleteArtifactMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  deleteAliases?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteArtifactMutation = (
  { __typename?: 'Mutation' }
  & { deleteArtifact?: Maybe<(
    { __typename?: 'DeleteArtifactPayload' }
    & { artifact: (
      { __typename?: 'Artifact' }
      & Pick<Artifact, 'id' | 'state'>
    ) }
  )> }
);

export type DeleteArtifactSequenceMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
}>;


export type DeleteArtifactSequenceMutation = (
  { __typename?: 'Mutation' }
  & { deleteArtifactSequence?: Maybe<(
    { __typename?: 'DeleteArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'state'>
    ) }
  )> }
);

export type UpdateArtifactTypeMutationVariables = Exact<{
  artifactTypeID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateArtifactTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateArtifactType?: Maybe<(
    { __typename?: 'UpdateArtifactTypePayload' }
    & { artifactType: (
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'description'>
    ) }
  )> }
);

export type UpdateArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateArtifactCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateArtifactSequence?: Maybe<(
    { __typename?: 'UpdateArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
    ) }
  )> }
);

export type MoveArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
  destinationArtifactTypeName: Scalars['String'];
}>;


export type MoveArtifactCollectionMutation = (
  { __typename?: 'Mutation' }
  & { moveArtifactSequence?: Maybe<(
    { __typename?: 'MoveArtifactCollectionPayload' }
    & { artifactCollection?: Maybe<(
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type DeleteArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
}>;


export type DeleteArtifactCollectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteArtifactSequence?: Maybe<(
    { __typename?: 'DeleteArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename?: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id'>
    ) }
  )> }
);

export type CreateArtifactFilesMutationVariables = Exact<{
  artifactFiles: Array<CreateArtifactFileSpecInput>;
  layout?: Maybe<ArtifactStorageLayout>;
}>;


export type CreateArtifactFilesMutation = (
  { __typename?: 'Mutation' }
  & { createArtifactFiles?: Maybe<(
    { __typename?: 'CreateArtifactFilesPayload' }
    & { files: (
      { __typename?: 'FileConnection' }
      & { edges: Array<(
        { __typename?: 'FileEdge' }
        & { node?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'name' | 'displayName' | 'directUrl' | 'isReference' | 'sizeBytes' | 'updatedAt'>
          & { uploadUrl: File['url'] }
        )> }
      )> }
    ) }
  )> }
);

export type ArtifactQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactID: Scalars['String'];
  usedByFilters?: Maybe<Scalars['JSONString']>;
  usedByOrder?: Maybe<Scalars['String']>;
  usedByAfter: Scalars['String'];
  usedByLimit: Scalars['Int'];
}>;


export type ArtifactQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
      & { artifact?: Maybe<(
        { __typename?: 'Artifact' }
        & Pick<Artifact, 'id' | 'digest' | 'description' | 'state' | 'createdAt' | 'metadata' | 'versionIndex' | 'labels'>
        & { artifactSequence: (
          { __typename?: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id' | 'name'>
        ), aliases: Array<(
          { __typename?: 'ArtifactAlias' }
          & Pick<ArtifactAlias, 'artifactCollectionName' | 'alias'>
        )>, createdBy: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'displayName' | 'jobType'>
          & { runName: Run['name'] }
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
          )> }
        ) | (
          { __typename: 'User' }
          & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
        ), usedBy: (
          { __typename?: 'RunConnection' }
          & Pick<RunConnection, 'totalCount'>
          & { pageInfo: (
            { __typename?: 'PageInfo' }
            & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
          ), edges: Array<(
            { __typename?: 'RunEdge' }
            & { node: (
              { __typename?: 'Run' }
              & Pick<Run, 'id' | 'name' | 'jobType' | 'displayName' | 'group' | 'createdAt' | 'heartbeatAt'>
              & { user?: Maybe<(
                { __typename?: 'User' }
                & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
              )>, project?: Maybe<(
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'entityName' | 'name'>
              )> }
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type ArtifactTypeQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
}>;


export type ArtifactTypeQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename?: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name' | 'description'>
      & { artifactSequences?: Maybe<(
        { __typename?: 'ArtifactSequenceConnection' }
        & { edges: Array<(
          { __typename?: 'ArtifactSequenceEdge' }
          & { node?: Maybe<(
            { __typename?: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt'>
            & { artifacts: (
              { __typename?: 'VersionedArtifactConnection' }
              & Pick<VersionedArtifactConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactTypesQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
}>;


export type ArtifactTypesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename?: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename?: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
        )> }
      )> }
    ) }
  )> }
);

export type EntityArtifactsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityArtifactsQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name'>
          & { artifactTypes: (
            { __typename?: 'ArtifactTypeConnection' }
            & { edges: Array<(
              { __typename?: 'ArtifactTypeEdge' }
              & { node?: Maybe<(
                { __typename?: 'ArtifactType' }
                & Pick<ArtifactType, 'id' | 'name'>
                & { artifactSequences?: Maybe<(
                  { __typename?: 'ArtifactSequenceConnection' }
                  & { edges: Array<(
                    { __typename?: 'ArtifactSequenceEdge' }
                    & { node?: Maybe<(
                      { __typename?: 'ArtifactSequence' }
                      & Pick<ArtifactSequence, 'id' | 'name' | 'createdAt'>
                      & { artifacts: (
                        { __typename?: 'VersionedArtifactConnection' }
                        & { edges: Array<(
                          { __typename?: 'VersionedArtifactEdge' }
                          & { node: (
                            { __typename?: 'Artifact' }
                            & Pick<Artifact, 'id' | 'versionIndex'>
                            & { aliases: Array<(
                              { __typename?: 'ArtifactAlias' }
                              & Pick<ArtifactAlias, 'id' | 'alias'>
                            )> }
                          ) }
                        )> }
                      ) }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type GalleryQueryVariables = Exact<{ [key: string]: never; }>;


export type GalleryQuery = (
  { __typename?: 'Query' }
  & { singletonView?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id' | 'spec'>
  )> }
);

export type UpdateGalleryMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  spec: Scalars['String'];
}>;


export type UpdateGalleryMutation = (
  { __typename?: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename?: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename?: 'View' }
      & Pick<View, 'id' | 'updatedAt' | 'createdAt' | 'spec'>
    )> }
  )> }
);

export type FeaturedReportsMetadataQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  recentStarCountFrom?: Maybe<Scalars['DateTime']>;
}>;


export type FeaturedReportsMetadataQuery = (
  { __typename?: 'Query' }
  & { views?: Maybe<(
    { __typename?: 'ViewConnection' }
    & { edges: Array<(
      { __typename?: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename?: 'View' }
        & Pick<View, 'id' | 'entityName' | 'displayName' | 'description' | 'previewUrl' | 'starCount' | 'starred'>
        & { recentStarCount: View['starCount'] }
        & { project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'entityName' | 'name'>
        )>, discussionThreads: (
          { __typename?: 'DiscussionThreadConnection' }
          & { edges: Array<(
            { __typename?: 'DiscussionThreadEdge' }
            & { node: (
              { __typename?: 'DiscussionThread' }
              & Pick<DiscussionThread, 'id'>
              & { comments: (
                { __typename?: 'DiscussionCommentConnection' }
                & Pick<DiscussionCommentConnection, 'totalCount'>
              ) }
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type FeaturedReportsAuthorsQueryVariables = Exact<{
  usernames: Array<Scalars['String']>;
}>;


export type FeaturedReportsAuthorsQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserConnection' }
    & { edges: Array<(
      { __typename?: 'UserEdge' }
      & { node?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'userInfo'>
      )> }
    )> }
  )> }
);

export type InsertGalleryDiscussionMutationVariables = Exact<{
  spec: Scalars['String'];
  reportID: Scalars['ID'];
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type InsertGalleryDiscussionMutation = (
  { __typename?: 'Mutation' }
  & { insertGalleryDiscussion?: Maybe<(
    { __typename?: 'InsertGalleryDiscussionPayload' }
    & Pick<InsertGalleryDiscussionPayload, 'success'>
  )> }
);

export type GalleryTagSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GalleryTagSectionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'galleryTagSections'>
);

export type ProjectAccessQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectAccessQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access'>
  )> }
);

export type NewsletterSubscriptionQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type NewsletterSubscriptionQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { newsletterSubscription?: Maybe<(
      { __typename?: 'NewsletterSubscription' }
      & Pick<NewsletterSubscription, 'id'>
    )> }
  )> }
);

export type CreateNewsletterSubscriptionMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type CreateNewsletterSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createNewsletterSubscription?: Maybe<(
    { __typename?: 'CreateNewsletterSubscriptionPayload' }
    & { newsletterSubscription: (
      { __typename?: 'NewsletterSubscription' }
      & Pick<NewsletterSubscription, 'id'>
    ) }
  )> }
);

export type AllNewsletterSubscriptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type AllNewsletterSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { newsletterSubscriptions?: Maybe<(
    { __typename?: 'NewsletterSubscriptionConnection' }
    & { edges: Array<(
      { __typename?: 'NewsletterSubscriptionEdge' }
      & { node?: Maybe<(
        { __typename?: 'NewsletterSubscription' }
        & Pick<NewsletterSubscription, 'id' | 'email' | 'createdAt'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'email'>
        )> }
      )> }
    )> }
  )> }
);

export type GroupPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  groupName: Scalars['String'];
}>;


export type GroupPageQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'totalRunsWithGroup' | 'totalRunsWithJobType' | 'access' | 'readOnly'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'name' | 'colorIndex'>
    )>, runGroup?: Maybe<(
      { __typename?: 'RunGroup' }
      & Pick<RunGroup, 'id' | 'name' | 'notes'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'colorIndex'>
      )> }
    )>, entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type HistoryKeysQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type HistoryKeysQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & Pick<RunConnection, 'historyKeys'>
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'wandbConfig'>
        ) }
      )> }
    )> }
  )> }
);

export type InviteQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type InviteQuery = (
  { __typename?: 'Query' }
  & { invite?: Maybe<(
    { __typename?: 'Invite' }
    & { toUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  )> }
);

export type MessageOfTheDayQueryVariables = Exact<{ [key: string]: never; }>;


export type MessageOfTheDayQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'messageOfTheDay'>
  )> }
);

export type MoveViewMutationVariables = Exact<{
  id: Scalars['ID'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type MoveViewMutation = (
  { __typename?: 'Mutation' }
  & { moveView?: Maybe<(
    { __typename?: 'MoveViewPayload' }
    & Pick<MoveViewPayload, 'success' | 'clientMutationId'>
  )> }
);

export type MultiRunWorkspaceQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type MultiRunWorkspaceQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'totalRunsWithGroup' | 'totalRunsWithJobType' | 'readOnly' | 'isBenchmark'>
    & { linkedBenchmark?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, runQueues: Array<(
      { __typename?: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'name' | 'createdBy' | 'access'>
    )> }
  )> }
);

export type OrganizationPageQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrganizationPageQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'name' | 'username'>
    )>, subscriptions: Array<(
      { __typename?: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'privileges'>
    )> }
  )> }
);

export type ParameterImportanceQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  targetName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  parameters?: Maybe<Array<Scalars['String']>>;
  runParameters?: Maybe<Array<Scalars['String']>>;
}>;


export type ParameterImportanceQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'parameterImportance'>
  )> }
);

export type ProjectArtifactsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ProjectArtifactsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename?: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename?: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name' | 'description'>
          & { artifactSequences?: Maybe<(
            { __typename?: 'ArtifactSequenceConnection' }
            & Pick<ArtifactSequenceConnection, 'totalCount'>
            & { edges: Array<(
              { __typename?: 'ArtifactSequenceEdge' }
              & { node?: Maybe<(
                { __typename?: 'ArtifactSequence' }
                & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt'>
                & { artifacts: (
                  { __typename?: 'VersionedArtifactConnection' }
                  & Pick<VersionedArtifactConnection, 'totalCount'>
                  & { edges: Array<(
                    { __typename?: 'VersionedArtifactEdge' }
                    & { node: (
                      { __typename?: 'Artifact' }
                      & Pick<Artifact, 'id' | 'commitHash'>
                    ) }
                  )> }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type ProjectFieldsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  types: Array<Scalars['String']>;
  columns: Array<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type ProjectFieldsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { fields: (
      { __typename?: 'ProjectFieldConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename?: 'ProjectFieldEdge' }
        & { node: (
          { __typename?: 'ProjectField' }
          & Pick<ProjectField, 'path' | 'type'>
        ) }
      )> }
    ) }
  )> }
);

export type ProjectPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectPageQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'lastActive' | 'access' | 'description' | 'readOnly' | 'totalRuns' | 'totalRunTime' | 'computeHours' | 'totalRunsWithGroup' | 'totalRunsWithJobType' | 'totalSweeps' | 'totalUsers' | 'runCount' | 'isBenchmark' | 'views'>
    & { runQueues: Array<(
      { __typename?: 'RunQueue' }
      & Pick<RunQueue, 'createdAt' | 'createdBy' | 'access' | 'updatedAt' | 'id' | 'name'>
      & { runQueueItems: (
        { __typename?: 'RunQueueItemConnection' }
        & { edges: Array<(
          { __typename?: 'RunQueueItemEdge' }
          & { node: (
            { __typename?: 'RunQueueItem' }
            & Pick<RunQueueItem, 'createdAt' | 'updatedAt' | 'id' | 'runSpec' | 'state' | 'associatedRunId'>
          ) }
        )> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'photoUrl'>
    )>, linkedBenchmark?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, reports?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )>, entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
      & { claimingEntity?: Maybe<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type ProjectTagsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type ProjectTagsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { tagCounts: Array<(
      { __typename?: 'TagCount' }
      & Pick<TagCount, 'name' | 'count'>
    )> }
  )> }
);

export type ReportPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ReportPageQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly'>
    & { entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type TeamMembersQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamMembersQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id'>
    & { members: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'username' | 'name' | 'photoUrl'>
    )> }
  )> }
);

export type ReportQueryVariables = Exact<{
  viewId: Scalars['ID'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id' | 'type' | 'spec'>
    & ViewFragmentMetadata2Fragment
  )> }
);

export type ReportTableQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ReportTableQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'readOnly'>
    & { reportDrafts?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )>, reports?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )> }
  )> }
);

export type RunArtifactVersionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
  includeOutput?: Maybe<Scalars['Boolean']>;
  includeInput?: Maybe<Scalars['Boolean']>;
}>;


export type RunArtifactVersionsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id'>
      & { outputArtifacts?: Maybe<(
        { __typename?: 'ArtifactConnection' }
        & Pick<ArtifactConnection, 'totalCount'>
        & { edges: Array<(
          { __typename?: 'ArtifactEdge' }
          & { node?: Maybe<(
            { __typename?: 'Artifact' }
            & Pick<Artifact, 'id' | 'digest' | 'commitHash' | 'versionIndex'>
            & { artifactType: (
              { __typename?: 'ArtifactType' }
              & Pick<ArtifactType, 'id' | 'name'>
              & { project: (
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename?: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              ) }
            ), artifactSequence: (
              { __typename?: 'ArtifactSequence' }
              & Pick<ArtifactSequence, 'id' | 'name'>
            ), aliases: Array<(
              { __typename?: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'artifactCollectionName' | 'alias'>
            )>, usedBy: (
              { __typename?: 'RunConnection' }
              & Pick<RunConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )>, inputArtifacts?: Maybe<(
        { __typename?: 'InputArtifactConnection' }
        & Pick<InputArtifactConnection, 'totalCount'>
        & { edges: Array<(
          { __typename?: 'InputArtifactEdge' }
          & Pick<InputArtifactEdge, 'usedAs'>
          & { node?: Maybe<(
            { __typename?: 'Artifact' }
            & Pick<Artifact, 'id' | 'digest' | 'commitHash' | 'versionIndex'>
            & { artifactType: (
              { __typename?: 'ArtifactType' }
              & Pick<ArtifactType, 'id' | 'name'>
              & { project: (
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename?: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              ) }
            ), artifactSequence: (
              { __typename?: 'ArtifactSequence' }
              & Pick<ArtifactSequence, 'id' | 'name'>
              & { project: (
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename?: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              ) }
            ), aliases: Array<(
              { __typename?: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'artifactCollectionName' | 'alias'>
            )>, usedBy: (
              { __typename?: 'RunConnection' }
              & Pick<RunConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type RunFilesQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  maxNumFiles: Scalars['Int'];
}>;


export type RunFilesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { files?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'url' | 'sizeBytes' | 'updatedAt'>
            & { artifact?: Maybe<(
              { __typename?: 'Artifact' }
              & Pick<Artifact, 'id' | 'digest'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type RunInfoAndConfigQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunInfoAndConfigQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'config'>
      & { runInfo?: Maybe<(
        { __typename?: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename?: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )> }
    )> }
  )> }
);

export type RunInfoQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunInfoQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id'>
      & { runInfo?: Maybe<(
        { __typename?: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename?: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )> }
    )> }
  )> }
);

export type RunKeysQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type RunKeysQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { fields: (
      { __typename?: 'ProjectFieldConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectFieldEdge' }
        & { node: (
          { __typename?: 'ProjectField' }
          & Pick<ProjectField, 'path'>
        ) }
      )> }
    ) }
  )> }
);

export type RunSelectorQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  tempFilters?: Maybe<Scalars['JSONString']>;
}>;


export type RunSelectorQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'runCount'>
    & { fields: (
      { __typename?: 'ProjectFieldConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectFieldEdge' }
        & { node: (
          { __typename?: 'ProjectField' }
          & Pick<ProjectField, 'path'>
        ) }
      )> }
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'name' | 'colorIndex'>
    )>, tagCounts: Array<(
      { __typename?: 'TagCount' }
      & Pick<TagCount, 'name' | 'count'>
    )> }
  )> }
);

export type RunSetSummaryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  selections: Scalars['JSONString'];
}>;


export type RunSetSummaryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'] }
  )> }
);

export type RunValueSuggestionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type RunValueSuggestionsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { runKeySuggestions: Array<(
      { __typename?: 'RunKeySuggestion' }
      & Pick<RunKeySuggestion, 'value' | 'displayValue' | 'count'>
    )> }
  )> }
);

export type ViewSpecQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewSpecQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id' | 'spec'>
  )> }
);

export type SearchNavQueryQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  sweepName: Scalars['String'];
  entityProjectsLimit?: Maybe<Scalars['Int']>;
}>;


export type SearchNavQueryQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'admin' | 'photoUrl' | 'username'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
    )>, projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )> }
    )>, teams?: Maybe<(
      { __typename?: 'EntityConnection' }
      & { edges: Array<(
        { __typename?: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl'>
          & { projects?: Maybe<(
            { __typename?: 'ProjectConnection' }
            & { edges: Array<(
              { __typename?: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )>, projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    )> }
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly' | 'isBenchmark'>
    & { entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'readOnlyAdmin' | 'defaultAccess' | 'privateOnly' | 'isTeam'>
    ), run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'displayName' | 'state'>
    )>, sweep?: Maybe<(
      { __typename?: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'config'>
    )> }
  )> }
);

export type SelectedCountQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  tempFilters?: Maybe<Scalars['JSONString']>;
}>;


export type SelectedCountQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'], tempSelectedCount: Project['runCount'], tempOutputArtifactsCount: Project['runOutputArtifactsCount'] }
  )> }
);

export type SingleFileQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type SingleFileQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id'>
      & { files?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'sizeBytes' | 'updatedAt'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type SweepPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  sweepName: Scalars['String'];
}>;


export type SweepPageQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly'>
    & { sweep?: Maybe<(
      { __typename?: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'runCount' | 'runTime' | 'method' | 'state' | 'description' | 'config' | 'createdAt' | 'heartbeatAt' | 'updatedAt' | 'controllerRunName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl'>
      )>, priorRuns: (
        { __typename?: 'RunConnection' }
        & { edges: Array<(
          { __typename?: 'RunEdge' }
          & { node: (
            { __typename?: 'Run' }
            & Pick<Run, 'name'>
          ) }
        )> }
      ), agents: (
        { __typename?: 'AgentConnection' }
        & { edges: Array<(
          { __typename?: 'AgentEdge' }
          & { node: (
            { __typename?: 'Agent' }
            & Pick<Agent, 'id' | 'name' | 'state' | 'host' | 'persistent' | 'metrics' | 'createdAt' | 'heartbeatAt' | 'totalRuns'>
            & { user?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id'>
            )> }
          ) }
        )> }
      ) }
    )>, entity: (
      { __typename?: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type SweepsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type SweepsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'readOnly'>
    & { sweeps?: Maybe<(
      { __typename?: 'SweepConnection' }
      & { edges: Array<(
        { __typename?: 'SweepEdge' }
        & { node: (
          { __typename?: 'Sweep' }
          & Pick<Sweep, 'id' | 'name' | 'displayName' | 'state' | 'runCount' | 'runTime' | 'createdAt' | 'config'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'username' | 'photoUrl'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type UsageAdminPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UsageAdminPageQuery = (
  { __typename?: 'Query' }
  & { instance?: Maybe<(
    { __typename?: 'WBInstance' }
    & { entities: Array<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'name' | 'isTeam'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'username'>
      )> }
    )> }
  )> }
);

export type ContactUsForUserLedTrialMutationVariables = Exact<{
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type ContactUsForUserLedTrialMutation = (
  { __typename?: 'Mutation' }
  & { contactUsForUserLedTrial?: Maybe<(
    { __typename?: 'ContactUsForUserLedTrialPayload' }
    & Pick<ContactUsForUserLedTrialPayload, 'clientMutationId'>
  )> }
);

export type ContactUsForComputeHoursMutationVariables = Exact<{
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type ContactUsForComputeHoursMutation = (
  { __typename?: 'Mutation' }
  & { contactUsForComputeHours?: Maybe<(
    { __typename?: 'ContactUsForComputeHoursPayload' }
    & Pick<ContactUsForComputeHoursPayload, 'clientMutationId'>
  )> }
);

export type UserProjectsQueryVariables = Exact<{
  userName: Scalars['String'];
  includeReports: Scalars['Boolean'];
  first?: Maybe<Scalars['Int']>;
}>;


export type UserProjectsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename?: 'EntityConnection' }
      & { edges: Array<(
        { __typename?: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename?: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
          & { projects?: Maybe<(
            { __typename?: 'ProjectConnection' }
            & { edges: Array<(
              { __typename?: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename?: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName'>
                & { reports?: Maybe<(
                  { __typename?: 'ViewConnection' }
                  & { edges: Array<(
                    { __typename?: 'ViewEdge' }
                    & { node?: Maybe<(
                      { __typename?: 'View' }
                      & ViewFragmentMetadataFragment
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AllUserProjectsQueryVariables = Exact<{
  userName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type AllUserProjectsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'totalRuns' | 'lastActive' | 'computeHours'>
          & { filteredCount: Project['runCount'] }
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        )> }
      )>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    )> }
  )> }
);

export type AllTeamProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type AllTeamProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime' | 'computeHours'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  )> }
);

export type UserReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserReportsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
    & { views?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & Pick<View, 'id' | 'name' | 'displayName' | 'spec'>
          & { entity?: Maybe<(
            { __typename?: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )>, project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<Project, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type Vega2PanelQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  fetchSingle: Scalars['Boolean'];
}>;


export type Vega2PanelQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )>, view?: Maybe<(
    { __typename?: 'View' }
    & ViewFragmentFragment
  )> }
);

export type VegaPanelQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  fetchSingle: Scalars['Boolean'];
}>;


export type VegaPanelQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )>, view?: Maybe<(
    { __typename?: 'View' }
    & ViewFragmentFragment
  )> }
);

export type WandbConfigQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type WandbConfigQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'wandbConfig'>
        ) }
      )> }
    )> }
  )> }
);

export type DiscussionThreadPageQueryVariables = Exact<{
  threadID: Scalars['ID'];
}>;


export type DiscussionThreadPageQuery = (
  { __typename?: 'Query' }
  & { discussionThread?: Maybe<(
    { __typename?: 'DiscussionThread' }
    & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
    & { poster: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'photoUrl' | 'name'>
    ), comments: (
      { __typename?: 'DiscussionCommentConnection' }
      & Pick<DiscussionCommentConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename?: 'DiscussionCommentEdge' }
        & { node: (
          { __typename?: 'DiscussionComment' }
          & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent' | 'upvotes'>
          & { poster: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name'>
          ) }
        ) }
      )> }
    ) }
  )>, viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type HomePageFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'photoUrl' | 'flags' | 'onboardingSteps'>
  & { organizations: Array<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, defaultEntity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
  )>, views?: Maybe<(
    { __typename?: 'ViewConnection' }
    & { edges: Array<(
      { __typename?: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename?: 'View' }
        & ReportsTableFragmentFragment
      )> }
    )> }
  )>, projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'access'>
      )> }
    )> }
  )>, teams?: Maybe<(
    { __typename?: 'EntityConnection' }
    & { edges: Array<(
      { __typename?: 'EntityEdge' }
      & { node?: Maybe<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'photoUrl'>
      )> }
    )> }
  )> }
);

export type HomePageUserDetailsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type HomePageUserDetailsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & HomePageFragmentFragment
  )> }
);

export type HomePageUserDetailsByUserQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type HomePageUserDetailsByUserQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & HomePageFragmentFragment
  )> }
);

export type ViewerRunsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type ViewerRunsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type AllProjectsQueryQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type AllProjectsQueryQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename?: 'ProjectConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime' | 'computeHours'>
        )> }
      )>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ) }
    )> }
  )> }
);

export type ProfilePageFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'userInfo' | 'accountType'>
  & { defaultEntity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
  )>, views?: Maybe<(
    { __typename?: 'ViewConnection' }
    & { edges: Array<(
      { __typename?: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename?: 'View' }
        & ReportsTableFragmentFragment
      )> }
    )> }
  )>, starredViews?: Maybe<(
    { __typename?: 'ViewConnection' }
    & { edges: Array<(
      { __typename?: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename?: 'View' }
        & ReportsTableFragmentFragment
      )> }
    )> }
  )>, projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime'>
      )> }
    )> }
  )>, teams?: Maybe<(
    { __typename?: 'EntityConnection' }
    & { edges: Array<(
      { __typename?: 'EntityEdge' }
      & { node?: Maybe<(
        { __typename?: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'isTeam'>
      )> }
    )> }
  )> }
);

export type UserRunsQueryVariables = Exact<{
  entityName: Scalars['String'];
  dailyRunCountLimit: Scalars['Int'];
}>;


export type UserRunsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'dailyRunCount'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type ReportsTableFragmentFragment = (
  { __typename?: 'View' }
  & Pick<View, 'id' | 'displayName' | 'description' | 'starCount' | 'updatedAt' | 'starred' | 'showcasedAt' | 'previewUrl'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'access'>
  )> }
);

export type RunsTableFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt' | 'heartbeatAt' | 'updatedAt' | 'groupCounts'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )> }
);

export type SubmitRunByIdQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type SubmitRunByIdQueryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'summaryMetrics'>
      & { benchmarkRun?: Maybe<(
        { __typename?: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'results'>
      )> }
    )> }
  )> }
);

export type RunCsvQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type RunCsvQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename?: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { benchmarkRun?: Maybe<(
        { __typename?: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'results'>
      )>, submissionFiles?: Maybe<(
        { __typename?: 'FileConnection' }
        & { edges: Array<(
          { __typename?: 'FileEdge' }
          & { node?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'md5'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpsertLinkedBenchmarkMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  linkedBenchmark?: Maybe<Scalars['ID']>;
}>;


export type UpsertLinkedBenchmarkMutation = (
  { __typename?: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename?: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views' | 'isBenchmark'>
      & { linkedBenchmark?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type SimpleTestQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SimpleTestQueryQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type UpsertBenchmarkMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
}>;


export type UpsertBenchmarkMutation = (
  { __typename?: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename?: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views' | 'isBenchmark'>
    )> }
  )> }
);

export type CheckAvailabilityQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type CheckAvailabilityQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'available'>
  )> }
);

export type CreateEntityOnboardingFlowMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateEntityOnboardingFlowMutation = (
  { __typename?: 'Mutation' }
  & { createEntity?: Maybe<(
    { __typename?: 'CreateEntityPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entity' }
      & Pick<Entity, 'id'>
    )> }
  )> }
);

export type ViewerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerInfoQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'userInfo' | 'entity' | 'username' | 'signupRequired'>
  )> }
);

export type TeamPageFragmentFragment = (
  { __typename?: 'Entity' }
  & Pick<Entity, 'id' | 'name' | 'memberCount' | 'photoUrl' | 'available' | 'readOnly' | 'storageBytes' | 'organizationId'>
  & { userStats?: Maybe<(
    { __typename?: 'EntityUserConnection' }
    & { edges: Array<(
      { __typename?: 'EntityUserEdge' }
      & { node?: Maybe<(
        { __typename?: 'EntityUser' }
        & Pick<EntityUser, 'runCount'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'photoUrl' | 'accountType'>
        ) }
      )> }
    )> }
  )>, projects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & { edges: Array<(
      { __typename?: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive' | 'totalRunTime'>
        & { allViews?: Maybe<(
          { __typename?: 'ViewConnection' }
          & { edges: Array<(
            { __typename?: 'ViewEdge' }
            & { node?: Maybe<(
              { __typename?: 'View' }
              & ReportsTableFragmentFragment
            )> }
          )> }
        )> }
      )> }
    )> }
  )>, claimingEntity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
  )> }
);

export type EntityRunsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityRunsQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type TrendingReposQueryVariables = Exact<{
  from: Scalars['DateTime'];
}>;


export type TrendingReposQuery = (
  { __typename?: 'Query' }
  & { trendingRepos?: Maybe<(
    { __typename?: 'RowConnection' }
    & Pick<RowConnection, 'totalCount' | 'schema'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'RowEdge' }
      & { node?: Maybe<(
        { __typename?: 'RowType' }
        & Pick<RowType, 'row'>
      )> }
    )> }
  )> }
);

export type RunStateBasicFragmentFragment = (
  { __typename?: 'Run' }
  & Pick<Run, 'readOnly' | 'framework' | 'displayName' | 'notes' | 'github' | 'projectId' | 'group' | 'jobType' | 'createdAt' | 'heartbeatAt' | 'commit' | 'host' | 'state' | 'shouldStop' | 'groupCounts' | 'stopped' | 'defaultColorIndex' | 'logLineCount'>
  & { sweep?: Maybe<(
    { __typename?: 'Sweep' }
    & Pick<Sweep, 'name' | 'displayName'>
  )>, agent?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'photoUrl'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'name' | 'colorIndex'>
  )>, benchmarkRun?: Maybe<(
    { __typename?: 'BenchmarkRun' }
    & Pick<BenchmarkRun, 'id'>
  )>, runInfo?: Maybe<(
    { __typename?: 'RunInfo' }
    & Pick<RunInfo, 'gpu' | 'gpuCount'>
  )>, inputArtifacts?: Maybe<(
    { __typename?: 'InputArtifactConnection' }
    & Pick<InputArtifactConnection, 'totalCount'>
  )>, outputArtifacts?: Maybe<(
    { __typename?: 'ArtifactConnection' }
    & Pick<ArtifactConnection, 'totalCount'>
  )> }
);

export type RunsStateQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  order: Scalars['String'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  filters: Scalars['JSONString'];
  limit: Scalars['Int'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type RunsStateQueryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { edges: Array<(
        { __typename?: 'RunEdge' }
        & { node: (
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName' | 'updatedAt' | 'config' | 'summaryMetrics' | 'wandbConfig' | 'systemMetrics' | 'historyKeys' | 'sampledHistory'>
          & RunStateBasicFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type RunsStateDeltaQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  order: Scalars['String'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  filters: Scalars['JSONString'];
  limit: Scalars['Int'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  lastUpdated: Scalars['DateTime'];
}>;


export type RunsStateDeltaQueryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename?: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { delta: Array<(
        { __typename?: 'RunDiff' }
        & Pick<RunDiff, 'index' | 'op'>
        & { run?: Maybe<(
          { __typename?: 'Run' }
          & Pick<Run, 'id' | 'name' | 'projectId' | 'displayName' | 'updatedAt' | 'config' | 'summaryMetrics' | 'wandbConfig' | 'systemMetrics' | 'historyKeys' | 'sampledHistory'>
          & RunStateBasicFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ServerInfoQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerInfoQueryQuery = (
  { __typename?: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename?: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type ViewFragmentMetadata2Fragment = (
  { __typename?: 'View' }
  & Pick<View, 'id' | 'name' | 'displayName' | 'type' | 'description' | 'entityName' | 'previewUrl' | 'coverUrl' | 'updatedAt' | 'createdAt' | 'starCount' | 'starred' | 'parentId' | 'locked' | 'viewCount' | 'showcasedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin' | 'name'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'readOnly'>
  )>, alertSubscription?: Maybe<(
    { __typename?: 'SlackChannelSubscription' }
    & Pick<SlackChannelSubscription, 'id'>
  ) | (
    { __typename?: 'EmailSubscription' }
    & Pick<EmailSubscription, 'id'>
  )>, accessTokens: Array<(
    { __typename?: 'AccessToken' }
    & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails' | 'createdAt' | 'lastAccessedAt' | 'revokedAt'>
    & { view: (
      { __typename?: 'View' }
      & Pick<View, 'id'>
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'email' | 'name'>
    ), projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName'>
    )> }
  )> }
);

export type Views2MetadataQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
}>;


export type Views2MetadataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & ViewFragmentMetadata2Fragment
        )> }
      )> }
    )> }
  )> }
);

export type Views2MetadataSpecQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
}>;


export type Views2MetadataSpecQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & Pick<View, 'spec'>
          & ViewFragmentMetadata2Fragment
        )> }
      )> }
    )> }
  )> }
);

export type Views2ViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Views2ViewQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'spec'>
    & ViewFragmentMetadata2Fragment
  )> }
);

export type UpsertView2MutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  locked?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  showcasedAt?: Maybe<Scalars['DateTime']>;
}>;


export type UpsertView2Mutation = (
  { __typename?: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename?: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename?: 'View' }
      & ViewFragmentMetadata2Fragment
    )> }
  )> }
);

export type DeleteView2MutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteView2Mutation = (
  { __typename?: 'Mutation' }
  & { deleteView?: Maybe<(
    { __typename?: 'DeleteViewPayload' }
    & Pick<DeleteViewPayload, 'success' | 'pendingDrafts'>
  )> }
);

export type DeleteViewsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteViewsMutation = (
  { __typename?: 'Mutation' }
  & { deleteViews?: Maybe<(
    { __typename?: 'DeleteViewsPayload' }
    & Pick<DeleteViewsPayload, 'success' | 'pendingDrafts'>
  )> }
);

export type StarViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StarViewMutation = (
  { __typename?: 'Mutation' }
  & { starView?: Maybe<(
    { __typename?: 'StarViewPayload' }
    & { view?: Maybe<(
      { __typename?: 'View' }
      & Pick<View, 'id' | 'starCount' | 'starred'>
    )> }
  )> }
);

export type UnstarViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnstarViewMutation = (
  { __typename?: 'Mutation' }
  & { unstarView?: Maybe<(
    { __typename?: 'UnstarViewPayload' }
    & { view?: Maybe<(
      { __typename?: 'View' }
      & Pick<View, 'id' | 'starCount' | 'starred'>
    )> }
  )> }
);

export type StargazersQueryQueryVariables = Exact<{
  viewID: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type StargazersQueryQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id'>
    & { stargazers: (
      { __typename?: 'StargazerConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename?: 'StargazerEdge' }
        & { node?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'username' | 'userInfo' | 'photoUrl'>
        )> }
      )> }
    ) }
  )> }
);

export type PublicViewsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type PublicViewsQuery = (
  { __typename?: 'Query' }
  & { publicViews?: Maybe<(
    { __typename?: 'ViewConnection' }
    & { edges: Array<(
      { __typename?: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename?: 'View' }
        & Pick<View, 'id' | 'name' | 'description'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewDraftsQueryVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type ViewDraftsQuery = (
  { __typename?: 'Query' }
  & { view?: Maybe<(
    { __typename?: 'View' }
    & Pick<View, 'id'>
    & { children?: Maybe<(
      { __typename?: 'ViewConnection' }
      & { edges: Array<(
        { __typename?: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename?: 'View' }
          & Pick<View, 'id' | 'displayName' | 'entityName'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id'>
          )>, project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ViewPermissionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ViewPermissionsQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'access'>
  )> }
);

export type ViewViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewViewMutation = (
  { __typename?: 'Mutation' }
  & { viewView?: Maybe<(
    { __typename?: 'ViewViewPayload' }
    & Pick<ViewViewPayload, 'success'>
  )> }
);

export const BenchmarkRunsFragmentFragmentDoc = gql`
    fragment benchmarkRunsFragment on BenchmarkRunConnection {
  edges {
    node {
      id
      gitHubSubmissionPR
      createdAt
      details
      results
      state
      user {
        id
        username
        photoUrl
        admin
      }
      originalRun {
        id
        name
        displayName
        project {
          id
          name
          entityName
        }
      }
      originalProject {
        id
        name
        entityName
      }
      run {
        github
        commit
        id
        name
        displayName
        notes
        user {
          id
          username
        }
        diffPatchFile: files(first: 1, names: ["diff.patch"]) {
          edges {
            node {
              id
              name
              md5
              sizeBytes
            }
          }
        }
        summaryMetrics
      }
      benchmark {
        id
        name
        entityName
      }
    }
  }
}
    `;
export const CustomChartFragmentFragmentDoc = gql`
    fragment CustomChartFragment on CustomChart {
  id
  name
  displayName
  entity {
    id
    name
    readOnly
  }
  user {
    id
    username
  }
  spec
  access
}
    `;
export const ArtifactNodeFragmentDoc = gql`
    fragment ArtifactNode on ArtifactSequenceStorageNode {
  id
  name
  size
  artifactType {
    name
  }
  artifactSequenceID
  artifacts {
    totalSize
    edges {
      node {
        id
        name
        size
        artifactID
      }
    }
  }
}
    `;
export const RunNodeFragmentDoc = gql`
    fragment RunNode on RunStorageNode {
  id
  name
  size
  runID
}
    `;
export const BasicRunFragmentFragmentDoc = gql`
    fragment BasicRunFragment on Run {
  readOnly
  framework
  displayName
  notes
  github
  group
  jobType
  createdAt
  heartbeatAt
  commit
  host
  state
  shouldStop
  groupCounts
  stopped
  defaultColorIndex
  sweep {
    name
    displayName
  }
  agent {
    name
  }
  user {
    username
    photoUrl
  }
  tags: tagColors {
    name
    colorIndex
  }
  benchmarkRun {
    id
  }
  runInfo {
    gpuCount
    gpu
  }
}
    `;
export const DetailedRunFragmentFragmentDoc = gql`
    fragment DetailedRunFragment on Run {
  history
  events
  fileCount
  logLines(after: $logCursor, last: 1000) {
    edges {
      node {
        id
        line
        level
      }
    }
  }
}
    `;
export const RunFilesFragmentFragmentDoc = gql`
    fragment RunFilesFragment on Run {
  files(after: $fileCursor, first: $fileLimit, names: $fileNames) {
    edges {
      node {
        id
        name
        directUrl
        url(upload: $upload)
        sizeBytes
        updatedAt
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export const SelectRunFragmentFragmentDoc = gql`
    fragment SelectRunFragment on Run {
  config
  summaryMetrics(packVersion: 1)
  systemMetrics
}
    `;
export const HistoryRunFragmentFragmentDoc = gql`
    fragment HistoryRunFragment on Run {
  history(samples: $historySamples)
}
    `;
export const SampledHistoryRunFragmentFragmentDoc = gql`
    fragment SampledHistoryRunFragment on Run {
  sampledHistory(specs: $sampledHistorySpecs, packVersion: 1)
}
    `;
export const ViewFragmentFragmentDoc = gql`
    fragment ViewFragment on View {
  id
  type
  name
  displayName
  description
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
  }
  spec
  updatedAt
}
    `;
export const ViewFragmentMetadataFragmentDoc = gql`
    fragment ViewFragmentMetadata on View {
  id
  type
  name
  displayName
  description
  updatedBy {
    id
    username
  }
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
  }
  updatedAt
  createdAt
  starred
  starCount
  parentId
  locked
  viewCount
  alertSubscription {
    id
  }
}
    `;
export const ArtifactDagUserFragmentFragmentDoc = gql`
    fragment ArtifactDagUserFragment on User {
  id
  name
}
    `;
export const ArtifactDagRunFragmentFragmentDoc = gql`
    fragment ArtifactDagRunFragment on Run {
  id
  runName: name
  displayName
  jobType
  project {
    id
    name
    entityName
  }
  inputArtifacts {
    edges {
      node {
        id
        artifactSequence {
          id
          name
        }
        commitHash
        versionIndex
        artifactType {
          id
          name
          project {
            id
            name
            entityName
          }
        }
      }
    }
  }
  outputArtifacts {
    edges {
      node {
        id
        artifactSequence {
          id
          name
        }
        commitHash
        versionIndex
        artifactType {
          id
          name
          project {
            id
            name
            entityName
          }
        }
      }
    }
  }
}
    `;
export const ReportsTableFragmentFragmentDoc = gql`
    fragment ReportsTableFragment on View {
  id
  displayName
  description
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
    access
  }
  starCount
  updatedAt
  starred
  showcasedAt
  previewUrl
}
    `;
export const HomePageFragmentFragmentDoc = gql`
    fragment HomePageFragment on User {
  id
  username
  photoUrl
  flags
  onboardingSteps
  organizations {
    id
    name
  }
  defaultEntity {
    id
    name
  }
  views(viewType: "runs", first: 100) {
    edges {
      node {
        ...ReportsTableFragment
      }
    }
  }
  projects(first: 100) {
    edges {
      node {
        id
        name
        entityName
        access
      }
    }
  }
  teams(first: 100) {
    edges {
      node {
        id
        name
        photoUrl
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export const ProfilePageFragmentFragmentDoc = gql`
    fragment ProfilePageFragment on User {
  id
  name
  username
  photoUrl
  userInfo
  accountType
  defaultEntity {
    id
    name
  }
  views(viewType: "runs", first: 10000) {
    edges {
      node {
        ...ReportsTableFragment
      }
    }
  }
  starredViews(first: 500) {
    edges {
      node {
        ...ReportsTableFragment
      }
    }
  }
  projects(first: 100) {
    edges {
      node {
        id
        name
        entityName
        description
        totalRuns
        totalUsers
        lastActive
        totalRunTime
      }
    }
  }
  teams(first: 100) {
    edges {
      node {
        id
        name
        photoUrl
        isTeam
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export const RunsTableFragmentFragmentDoc = gql`
    fragment RunsTableFragment on Run {
  id
  name
  displayName
  state
  createdAt
  heartbeatAt
  updatedAt
  user {
    id
    username
  }
  project {
    id
    name
    entityName
  }
  groupCounts
}
    `;
export const TeamPageFragmentFragmentDoc = gql`
    fragment TeamPageFragment on Entity {
  id
  name
  memberCount
  photoUrl
  available
  readOnly
  storageBytes
  organizationId
  userStats(from: $userStatsStartDate, first: 10) {
    edges {
      node {
        user {
          id
          username
          photoUrl
          accountType
        }
        runCount
      }
    }
  }
  projects(order: "lastActive") {
    edges {
      node {
        id
        name
        entityName
        description
        totalRuns
        totalUsers
        lastActive
        totalRunTime
        allViews(viewType: "runs", first: 10000) {
          edges {
            node {
              ...ReportsTableFragment
            }
          }
        }
      }
    }
  }
  claimingEntity {
    id
    name
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export const RunStateBasicFragmentFragmentDoc = gql`
    fragment RunStateBasicFragment on Run {
  readOnly
  framework
  displayName
  notes
  github
  projectId
  group
  jobType
  createdAt
  heartbeatAt
  commit
  host
  state
  shouldStop
  groupCounts
  stopped
  defaultColorIndex
  sweep {
    name
    displayName
  }
  agent {
    name
  }
  user {
    username
    photoUrl
  }
  tags: tagColors {
    name
    colorIndex
  }
  benchmarkRun {
    id
  }
  runInfo {
    gpu
    gpuCount
  }
  inputArtifacts {
    totalCount
  }
  outputArtifacts {
    totalCount
  }
  logLineCount
}
    `;
export const ViewFragmentMetadata2FragmentDoc = gql`
    fragment ViewFragmentMetadata2 on View {
  id
  name
  displayName
  type
  description
  user {
    id
    username
    photoUrl
    admin
    name
  }
  updatedBy {
    id
    username
  }
  entityName
  project {
    id
    name
    entityName
    readOnly
  }
  previewUrl
  coverUrl
  updatedAt
  createdAt
  starCount
  starred
  parentId
  locked
  viewCount
  showcasedAt
  alertSubscription {
    id
  }
  accessTokens {
    id
    token
    view {
      id
    }
    type
    emails
    createdBy {
      id
      username
      email
      name
    }
    createdAt
    lastAccessedAt
    revokedAt
    projects {
      id
      name
      entityName
    }
  }
}
    `;
export const SimpleProjectRunsDocument = gql`
    query SimpleProjectRuns($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
    runs {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
}
    `;
export type SimpleProjectRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>, 'query'> & ({ variables: SimpleProjectRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SimpleProjectRunsComponent = (props: SimpleProjectRunsComponentProps) => (
      <ApolloReactComponents.Query<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables> query={SimpleProjectRunsDocument} {...props} />
    );
    
export type SimpleProjectRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>
    } & TChildProps;
export function withSimpleProjectRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SimpleProjectRunsQuery,
  SimpleProjectRunsQueryVariables,
  SimpleProjectRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables, SimpleProjectRunsProps<TChildProps, TDataName>>(SimpleProjectRunsDocument, {
      alias: 'simpleProjectRuns',
      ...operationOptions
    });
};

/**
 * __useSimpleProjectRunsQuery__
 *
 * To run a query within a React component, call `useSimpleProjectRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectRunsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useSimpleProjectRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>(SimpleProjectRunsDocument, baseOptions);
      }
export function useSimpleProjectRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>(SimpleProjectRunsDocument, baseOptions);
        }
export type SimpleProjectRunsQueryHookResult = ReturnType<typeof useSimpleProjectRunsQuery>;
export type SimpleProjectRunsLazyQueryHookResult = ReturnType<typeof useSimpleProjectRunsLazyQuery>;
export type SimpleProjectRunsQueryResult = ApolloReactCommon.QueryResult<SimpleProjectRunsQuery, SimpleProjectRunsQueryVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($entityName: String!, $teamsEnabled: Boolean, $privateProjects: Boolean, $gpuEnabled: DateTime, $hubPreset: String, $repo: String, $disk: String, $redisEnabled: Boolean, $dockerEnabled: Boolean, $image: String) {
  updateSubscription(input: {entityName: $entityName, teamsEnabled: $teamsEnabled, privateProjects: $privateProjects, gpuEnabled: $gpuEnabled, hubPreset: $hubPreset, hubSettings: {repo: $repo, disk: $disk, redisEnabled: $redisEnabled, dockerEnabled: $dockerEnabled, image: $image}}) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type UpdateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export type UpdateSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>, 'mutation'>;

    export const UpdateSubscriptionComponent = (props: UpdateSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables> mutation={UpdateSubscriptionDocument} {...props} />
    );
    
export type UpdateSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>
    } & TChildProps;
export function withUpdateSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables,
  UpdateSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables, UpdateSubscriptionProps<TChildProps, TDataName>>(UpdateSubscriptionDocument, {
      alias: 'updateSubscription',
      ...operationOptions
    });
};

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      teamsEnabled: // value for 'teamsEnabled'
 *      privateProjects: // value for 'privateProjects'
 *      gpuEnabled: // value for 'gpuEnabled'
 *      hubPreset: // value for 'hubPreset'
 *      repo: // value for 'repo'
 *      disk: // value for 'disk'
 *      redisEnabled: // value for 'redisEnabled'
 *      dockerEnabled: // value for 'dockerEnabled'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, baseOptions);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const UpdateEntityPhotoDocument = gql`
    mutation updateEntityPhoto($entityName: String!, $photoUrl: String) {
  updateEntity(input: {entity: $entityName, photoUrl: $photoUrl}) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type UpdateEntityPhotoMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>;
export type UpdateEntityPhotoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>, 'mutation'>;

    export const UpdateEntityPhotoComponent = (props: UpdateEntityPhotoComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables> mutation={UpdateEntityPhotoDocument} {...props} />
    );
    
export type UpdateEntityPhotoProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>
    } & TChildProps;
export function withUpdateEntityPhoto<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityPhotoMutation,
  UpdateEntityPhotoMutationVariables,
  UpdateEntityPhotoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables, UpdateEntityPhotoProps<TChildProps, TDataName>>(UpdateEntityPhotoDocument, {
      alias: 'updateEntityPhoto',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateEntityPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityPhotoMutation, { data, loading, error }] = useUpdateEntityPhotoMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      photoUrl: // value for 'photoUrl'
 *   },
 * });
 */
export function useUpdateEntityPhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>(UpdateEntityPhotoDocument, baseOptions);
      }
export type UpdateEntityPhotoMutationHookResult = ReturnType<typeof useUpdateEntityPhotoMutation>;
export type UpdateEntityPhotoMutationResult = ApolloReactCommon.MutationResult<UpdateEntityPhotoMutation>;
export type UpdateEntityPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>;
export const TeamQueryDocument = gql`
    query TeamQuery($name: String) {
  entity(name: $name) {
    id
    name
    available
    photoUrl
    limits
  }
}
    `;
export type TeamQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamQueryQuery, TeamQueryQueryVariables>, 'query'>;

    export const TeamQueryComponent = (props: TeamQueryComponentProps) => (
      <ApolloReactComponents.Query<TeamQueryQuery, TeamQueryQueryVariables> query={TeamQueryDocument} {...props} />
    );
    
export type TeamQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamQueryQuery, TeamQueryQueryVariables>
    } & TChildProps;
export function withTeamQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamQueryQuery,
  TeamQueryQueryVariables,
  TeamQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamQueryQuery, TeamQueryQueryVariables, TeamQueryProps<TChildProps, TDataName>>(TeamQueryDocument, {
      alias: 'teamQuery',
      ...operationOptions
    });
};

/**
 * __useTeamQueryQuery__
 *
 * To run a query within a React component, call `useTeamQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQueryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeamQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamQueryQuery, TeamQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamQueryQuery, TeamQueryQueryVariables>(TeamQueryDocument, baseOptions);
      }
export function useTeamQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamQueryQuery, TeamQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamQueryQuery, TeamQueryQueryVariables>(TeamQueryDocument, baseOptions);
        }
export type TeamQueryQueryHookResult = ReturnType<typeof useTeamQueryQuery>;
export type TeamQueryLazyQueryHookResult = ReturnType<typeof useTeamQueryLazyQuery>;
export type TeamQueryQueryResult = ApolloReactCommon.QueryResult<TeamQueryQuery, TeamQueryQueryVariables>;
export const UserQueryDocument = gql`
    query UserQuery($userName: String!) {
  user(userName: $userName) {
    id
  }
}
    `;
export type UserQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserQueryQuery, UserQueryQueryVariables>, 'query'> & ({ variables: UserQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserQueryComponent = (props: UserQueryComponentProps) => (
      <ApolloReactComponents.Query<UserQueryQuery, UserQueryQueryVariables> query={UserQueryDocument} {...props} />
    );
    
export type UserQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserQueryQuery, UserQueryQueryVariables>
    } & TChildProps;
export function withUserQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserQueryQuery,
  UserQueryQueryVariables,
  UserQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserQueryQuery, UserQueryQueryVariables, UserQueryProps<TChildProps, TDataName>>(UserQueryDocument, {
      alias: 'userQuery',
      ...operationOptions
    });
};

/**
 * __useUserQueryQuery__
 *
 * To run a query within a React component, call `useUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQueryQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useUserQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQueryQuery, UserQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQueryQuery, UserQueryQueryVariables>(UserQueryDocument, baseOptions);
      }
export function useUserQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQueryQuery, UserQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQueryQuery, UserQueryQueryVariables>(UserQueryDocument, baseOptions);
        }
export type UserQueryQueryHookResult = ReturnType<typeof useUserQueryQuery>;
export type UserQueryLazyQueryHookResult = ReturnType<typeof useUserQueryLazyQuery>;
export type UserQueryQueryResult = ApolloReactCommon.QueryResult<UserQueryQuery, UserQueryQueryVariables>;
export const AllEntitiesDocument = gql`
    query allEntities($first: Int = 2000) {
  entities(first: $first) {
    edges {
      node {
        id
        createdAt
        name
        memberCount
        runCount
        isTeam
        members {
          admin
          pending
          username
          email
          name
          id
        }
      }
    }
  }
}
    `;
export type AllEntitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllEntitiesQuery, AllEntitiesQueryVariables>, 'query'>;

    export const AllEntitiesComponent = (props: AllEntitiesComponentProps) => (
      <ApolloReactComponents.Query<AllEntitiesQuery, AllEntitiesQueryVariables> query={AllEntitiesDocument} {...props} />
    );
    
export type AllEntitiesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllEntitiesQuery, AllEntitiesQueryVariables>
    } & TChildProps;
export function withAllEntities<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllEntitiesQuery,
  AllEntitiesQueryVariables,
  AllEntitiesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllEntitiesQuery, AllEntitiesQueryVariables, AllEntitiesProps<TChildProps, TDataName>>(AllEntitiesDocument, {
      alias: 'allEntities',
      ...operationOptions
    });
};

/**
 * __useAllEntitiesQuery__
 *
 * To run a query within a React component, call `useAllEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllEntitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, baseOptions);
      }
export function useAllEntitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, baseOptions);
        }
export type AllEntitiesQueryHookResult = ReturnType<typeof useAllEntitiesQuery>;
export type AllEntitiesLazyQueryHookResult = ReturnType<typeof useAllEntitiesLazyQuery>;
export type AllEntitiesQueryResult = ApolloReactCommon.QueryResult<AllEntitiesQuery, AllEntitiesQueryVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($teamName: String!, $organizationId: String, $teamAdminUserName: String) {
  createTeam(input: {teamName: $teamName, organizationId: $organizationId, teamAdminUserName: $teamAdminUserName}) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type CreateTeamMutationFn = ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTeamMutation, CreateTeamMutationVariables>, 'mutation'>;

    export const CreateTeamComponent = (props: CreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTeamMutation, CreateTeamMutationVariables> mutation={CreateTeamDocument} {...props} />
    );
    
export type CreateTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>
    } & TChildProps;
export function withCreateTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  CreateTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTeamMutation, CreateTeamMutationVariables, CreateTeamProps<TChildProps, TDataName>>(CreateTeamDocument, {
      alias: 'createTeam',
      ...operationOptions
    });
};

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *      organizationId: // value for 'organizationId'
 *      teamAdminUserName: // value for 'teamAdminUserName'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = ApolloReactCommon.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateEntityAdminDocument = gql`
    mutation UpdateEntityAdmin($entityName: String!, $isPaid: Boolean, $rateLimits: RateLimitsInput) {
  updateEntity(input: {entity: $entityName, isPaid: $isPaid, rateLimits: $rateLimits}) {
    entity {
      id
      isPaid
      rateLimits {
        filestream
        graphql
      }
    }
  }
}
    `;
export type UpdateEntityAdminMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>;
export type UpdateEntityAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>, 'mutation'>;

    export const UpdateEntityAdminComponent = (props: UpdateEntityAdminComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables> mutation={UpdateEntityAdminDocument} {...props} />
    );
    
export type UpdateEntityAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>
    } & TChildProps;
export function withUpdateEntityAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityAdminMutation,
  UpdateEntityAdminMutationVariables,
  UpdateEntityAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables, UpdateEntityAdminProps<TChildProps, TDataName>>(UpdateEntityAdminDocument, {
      alias: 'updateEntityAdmin',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityAdminMutation__
 *
 * To run a mutation, you first call `useUpdateEntityAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityAdminMutation, { data, loading, error }] = useUpdateEntityAdminMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      isPaid: // value for 'isPaid'
 *      rateLimits: // value for 'rateLimits'
 *   },
 * });
 */
export function useUpdateEntityAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>(UpdateEntityAdminDocument, baseOptions);
      }
export type UpdateEntityAdminMutationHookResult = ReturnType<typeof useUpdateEntityAdminMutation>;
export type UpdateEntityAdminMutationResult = ApolloReactCommon.MutationResult<UpdateEntityAdminMutation>;
export type UpdateEntityAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>;
export const CreateFinishedRunAlertDocument = gql`
    mutation CreateFinishedRunAlert($entityName: String!) {
  createFinishedRunAlert(input: {entityName: $entityName}) {
    alert {
      id
      condition {
        __typename
        ... on FinishedRunCondition {
          success
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateFinishedRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>;
export type CreateFinishedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>, 'mutation'>;

    export const CreateFinishedRunAlertComponent = (props: CreateFinishedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables> mutation={CreateFinishedRunAlertDocument} {...props} />
    );
    
export type CreateFinishedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>
    } & TChildProps;
export function withCreateFinishedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFinishedRunAlertMutation,
  CreateFinishedRunAlertMutationVariables,
  CreateFinishedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables, CreateFinishedRunAlertProps<TChildProps, TDataName>>(CreateFinishedRunAlertDocument, {
      alias: 'createFinishedRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateFinishedRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateFinishedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinishedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinishedRunAlertMutation, { data, loading, error }] = useCreateFinishedRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCreateFinishedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>(CreateFinishedRunAlertDocument, baseOptions);
      }
export type CreateFinishedRunAlertMutationHookResult = ReturnType<typeof useCreateFinishedRunAlertMutation>;
export type CreateFinishedRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateFinishedRunAlertMutation>;
export type CreateFinishedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>;
export const CreateStoppedRunAlertDocument = gql`
    mutation CreateStoppedRunAlert($entityName: String!, $minimumRunDuration: Duration!) {
  createStoppedRunAlert(input: {entityName: $entityName, minimumRunDuration: $minimumRunDuration}) {
    alert {
      id
      condition {
        __typename
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateStoppedRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>;
export type CreateStoppedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>, 'mutation'>;

    export const CreateStoppedRunAlertComponent = (props: CreateStoppedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables> mutation={CreateStoppedRunAlertDocument} {...props} />
    );
    
export type CreateStoppedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>
    } & TChildProps;
export function withCreateStoppedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateStoppedRunAlertMutation,
  CreateStoppedRunAlertMutationVariables,
  CreateStoppedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables, CreateStoppedRunAlertProps<TChildProps, TDataName>>(CreateStoppedRunAlertDocument, {
      alias: 'createStoppedRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateStoppedRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateStoppedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoppedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoppedRunAlertMutation, { data, loading, error }] = useCreateStoppedRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      minimumRunDuration: // value for 'minimumRunDuration'
 *   },
 * });
 */
export function useCreateStoppedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>(CreateStoppedRunAlertDocument, baseOptions);
      }
export type CreateStoppedRunAlertMutationHookResult = ReturnType<typeof useCreateStoppedRunAlertMutation>;
export type CreateStoppedRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateStoppedRunAlertMutation>;
export type CreateStoppedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>;
export const UpdateStoppedRunAlertDocument = gql`
    mutation UpdateStoppedRunAlert($id: ID!, $minimumRunDuration: Duration) {
  updateStoppedRunAlert(input: {id: $id, minimumRunDuration: $minimumRunDuration}) {
    alert {
      id
      condition {
        __typename
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type UpdateStoppedRunAlertMutationFn = ApolloReactCommon.MutationFunction<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>;
export type UpdateStoppedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>, 'mutation'>;

    export const UpdateStoppedRunAlertComponent = (props: UpdateStoppedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables> mutation={UpdateStoppedRunAlertDocument} {...props} />
    );
    
export type UpdateStoppedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>
    } & TChildProps;
export function withUpdateStoppedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateStoppedRunAlertMutation,
  UpdateStoppedRunAlertMutationVariables,
  UpdateStoppedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables, UpdateStoppedRunAlertProps<TChildProps, TDataName>>(UpdateStoppedRunAlertDocument, {
      alias: 'updateStoppedRunAlert',
      ...operationOptions
    });
};

/**
 * __useUpdateStoppedRunAlertMutation__
 *
 * To run a mutation, you first call `useUpdateStoppedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoppedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoppedRunAlertMutation, { data, loading, error }] = useUpdateStoppedRunAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minimumRunDuration: // value for 'minimumRunDuration'
 *   },
 * });
 */
export function useUpdateStoppedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>(UpdateStoppedRunAlertDocument, baseOptions);
      }
export type UpdateStoppedRunAlertMutationHookResult = ReturnType<typeof useUpdateStoppedRunAlertMutation>;
export type UpdateStoppedRunAlertMutationResult = ApolloReactCommon.MutationResult<UpdateStoppedRunAlertMutation>;
export type UpdateStoppedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>;
export const CreateScriptableRunAlertDocument = gql`
    mutation CreateScriptableRunAlert($entityName: String!) {
  createScriptableRunAlert(input: {entityName: $entityName}) {
    alert {
      id
      condition {
        __typename
        ... on ScriptableRunCondition {
          success
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateScriptableRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>;
export type CreateScriptableRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>, 'mutation'>;

    export const CreateScriptableRunAlertComponent = (props: CreateScriptableRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables> mutation={CreateScriptableRunAlertDocument} {...props} />
    );
    
export type CreateScriptableRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>
    } & TChildProps;
export function withCreateScriptableRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateScriptableRunAlertMutation,
  CreateScriptableRunAlertMutationVariables,
  CreateScriptableRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables, CreateScriptableRunAlertProps<TChildProps, TDataName>>(CreateScriptableRunAlertDocument, {
      alias: 'createScriptableRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateScriptableRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateScriptableRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScriptableRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScriptableRunAlertMutation, { data, loading, error }] = useCreateScriptableRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCreateScriptableRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>(CreateScriptableRunAlertDocument, baseOptions);
      }
export type CreateScriptableRunAlertMutationHookResult = ReturnType<typeof useCreateScriptableRunAlertMutation>;
export type CreateScriptableRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateScriptableRunAlertMutation>;
export type CreateScriptableRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation DeleteAlert($id: ID!) {
  deleteAlert(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteAlertMutationFn = ApolloReactCommon.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;
export type DeleteAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAlertMutation, DeleteAlertMutationVariables>, 'mutation'>;

    export const DeleteAlertComponent = (props: DeleteAlertComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAlertMutation, DeleteAlertMutationVariables> mutation={DeleteAlertDocument} {...props} />
    );
    
export type DeleteAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>
    } & TChildProps;
export function withDeleteAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
  DeleteAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAlertMutation, DeleteAlertMutationVariables, DeleteAlertProps<TChildProps, TDataName>>(DeleteAlertDocument, {
      alias: 'deleteAlert',
      ...operationOptions
    });
};

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, baseOptions);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = ApolloReactCommon.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const CreateSlackChannelSubscriptionDocument = gql`
    mutation CreateSlackChannelSubscription($alertID: ID!, $integrationID: ID!) {
  createSlackChannelSubscription(input: {alertID: $alertID, integrationID: $integrationID}) {
    subscription {
      id
    }
  }
}
    `;
export type CreateSlackChannelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>;
export type CreateSlackChannelSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>, 'mutation'>;

    export const CreateSlackChannelSubscriptionComponent = (props: CreateSlackChannelSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables> mutation={CreateSlackChannelSubscriptionDocument} {...props} />
    );
    
export type CreateSlackChannelSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateSlackChannelSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSlackChannelSubscriptionMutation,
  CreateSlackChannelSubscriptionMutationVariables,
  CreateSlackChannelSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables, CreateSlackChannelSubscriptionProps<TChildProps, TDataName>>(CreateSlackChannelSubscriptionDocument, {
      alias: 'createSlackChannelSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateSlackChannelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSlackChannelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackChannelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackChannelSubscriptionMutation, { data, loading, error }] = useCreateSlackChannelSubscriptionMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *      integrationID: // value for 'integrationID'
 *   },
 * });
 */
export function useCreateSlackChannelSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>(CreateSlackChannelSubscriptionDocument, baseOptions);
      }
export type CreateSlackChannelSubscriptionMutationHookResult = ReturnType<typeof useCreateSlackChannelSubscriptionMutation>;
export type CreateSlackChannelSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSlackChannelSubscriptionMutation>;
export type CreateSlackChannelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>;
export const CreateEmailSubscriptionDocument = gql`
    mutation CreateEmailSubscription($alertID: ID!) {
  createEmailSubscription(input: {alertID: $alertID}) {
    subscription {
      id
    }
  }
}
    `;
export type CreateEmailSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>;
export type CreateEmailSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>, 'mutation'>;

    export const CreateEmailSubscriptionComponent = (props: CreateEmailSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables> mutation={CreateEmailSubscriptionDocument} {...props} />
    );
    
export type CreateEmailSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateEmailSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEmailSubscriptionMutation,
  CreateEmailSubscriptionMutationVariables,
  CreateEmailSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables, CreateEmailSubscriptionProps<TChildProps, TDataName>>(CreateEmailSubscriptionDocument, {
      alias: 'createEmailSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateEmailSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateEmailSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailSubscriptionMutation, { data, loading, error }] = useCreateEmailSubscriptionMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useCreateEmailSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>(CreateEmailSubscriptionDocument, baseOptions);
      }
export type CreateEmailSubscriptionMutationHookResult = ReturnType<typeof useCreateEmailSubscriptionMutation>;
export type CreateEmailSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateEmailSubscriptionMutation>;
export type CreateEmailSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>;
export const CreateViewCommentsAlertSubscriptionDocument = gql`
    mutation CreateViewCommentsAlertSubscription($viewID: ID!) {
  createViewCommentsAlertSubscription(input: {viewID: $viewID}) {
    subscription {
      id
    }
  }
}
    `;
export type CreateViewCommentsAlertSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>;
export type CreateViewCommentsAlertSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>, 'mutation'>;

    export const CreateViewCommentsAlertSubscriptionComponent = (props: CreateViewCommentsAlertSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables> mutation={CreateViewCommentsAlertSubscriptionDocument} {...props} />
    );
    
export type CreateViewCommentsAlertSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateViewCommentsAlertSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateViewCommentsAlertSubscriptionMutation,
  CreateViewCommentsAlertSubscriptionMutationVariables,
  CreateViewCommentsAlertSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables, CreateViewCommentsAlertSubscriptionProps<TChildProps, TDataName>>(CreateViewCommentsAlertSubscriptionDocument, {
      alias: 'createViewCommentsAlertSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateViewCommentsAlertSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateViewCommentsAlertSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewCommentsAlertSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewCommentsAlertSubscriptionMutation, { data, loading, error }] = useCreateViewCommentsAlertSubscriptionMutation({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useCreateViewCommentsAlertSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>(CreateViewCommentsAlertSubscriptionDocument, baseOptions);
      }
export type CreateViewCommentsAlertSubscriptionMutationHookResult = ReturnType<typeof useCreateViewCommentsAlertSubscriptionMutation>;
export type CreateViewCommentsAlertSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateViewCommentsAlertSubscriptionMutation>;
export type CreateViewCommentsAlertSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>;
export const DeleteAlertSubscriptionDocument = gql`
    mutation DeleteAlertSubscription($id: ID!) {
  deleteAlertSubscription(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteAlertSubscriptionMutationFn = ApolloReactCommon.MutationFunction<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>;
export type DeleteAlertSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>, 'mutation'>;

    export const DeleteAlertSubscriptionComponent = (props: DeleteAlertSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables> mutation={DeleteAlertSubscriptionDocument} {...props} />
    );
    
export type DeleteAlertSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>
    } & TChildProps;
export function withDeleteAlertSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAlertSubscriptionMutation,
  DeleteAlertSubscriptionMutationVariables,
  DeleteAlertSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables, DeleteAlertSubscriptionProps<TChildProps, TDataName>>(DeleteAlertSubscriptionDocument, {
      alias: 'deleteAlertSubscription',
      ...operationOptions
    });
};

/**
 * __useDeleteAlertSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteAlertSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertSubscriptionMutation, { data, loading, error }] = useDeleteAlertSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>(DeleteAlertSubscriptionDocument, baseOptions);
      }
export type DeleteAlertSubscriptionMutationHookResult = ReturnType<typeof useDeleteAlertSubscriptionMutation>;
export type DeleteAlertSubscriptionMutationResult = ApolloReactCommon.MutationResult<DeleteAlertSubscriptionMutation>;
export type DeleteAlertSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>;
export const AllProjectsDocument = gql`
    query AllProjects($entityName: String!, $order: String, $first: Int = 500) {
  projects(first: $first, entityName: $entityName, order: $order) {
    edges {
      node {
        id
        name
        entityName
        description
        totalRuns
        totalUsers
        lastActive
        totalRunTime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  entity(name: $entityName) {
    id
    memberCount
  }
  user(userName: $entityName) {
    projects(first: $first) {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          totalUsers
          lastActive
          totalRunTime
          computeHours
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  viewer {
    id
    email
    photoUrl
  }
}
    `;
export type AllProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllProjectsQuery, AllProjectsQueryVariables>, 'query'> & ({ variables: AllProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllProjectsComponent = (props: AllProjectsComponentProps) => (
      <ApolloReactComponents.Query<AllProjectsQuery, AllProjectsQueryVariables> query={AllProjectsDocument} {...props} />
    );
    
export type AllProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllProjectsQuery, AllProjectsQueryVariables>
    } & TChildProps;
export function withAllProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllProjectsQuery,
  AllProjectsQueryVariables,
  AllProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllProjectsQuery, AllProjectsQueryVariables, AllProjectsProps<TChildProps, TDataName>>(AllProjectsDocument, {
      alias: 'allProjects',
      ...operationOptions
    });
};

/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, baseOptions);
      }
export function useAllProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, baseOptions);
        }
export type AllProjectsQueryHookResult = ReturnType<typeof useAllProjectsQuery>;
export type AllProjectsLazyQueryHookResult = ReturnType<typeof useAllProjectsLazyQuery>;
export type AllProjectsQueryResult = ApolloReactCommon.QueryResult<AllProjectsQuery, AllProjectsQueryVariables>;
export const BenchmarkProjectDocument = gql`
    query BenchmarkProject($name: String!, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    access
    name
    description
    readOnly
    entityName
    views
    gitHubSubmissionRepo
    acceptedBenchmarkRuns: benchmarkRuns(state: "ACCEPTED") {
      ...benchmarkRunsFragment
    }
    rejectedBenchmarkRuns: benchmarkRuns(state: "REJECTED") {
      ...benchmarkRunsFragment
    }
    submittedBenchmarkRuns: benchmarkRuns(state: "SUBMITTED") {
      ...benchmarkRunsFragment
    }
    entity {
      id
      name
      photoUrl
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    ${BenchmarkRunsFragmentFragmentDoc}`;
export type BenchmarkProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>, 'query'> & ({ variables: BenchmarkProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BenchmarkProjectComponent = (props: BenchmarkProjectComponentProps) => (
      <ApolloReactComponents.Query<BenchmarkProjectQuery, BenchmarkProjectQueryVariables> query={BenchmarkProjectDocument} {...props} />
    );
    
export type BenchmarkProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>
    } & TChildProps;
export function withBenchmarkProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BenchmarkProjectQuery,
  BenchmarkProjectQueryVariables,
  BenchmarkProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BenchmarkProjectQuery, BenchmarkProjectQueryVariables, BenchmarkProjectProps<TChildProps, TDataName>>(BenchmarkProjectDocument, {
      alias: 'benchmarkProject',
      ...operationOptions
    });
};

/**
 * __useBenchmarkProjectQuery__
 *
 * To run a query within a React component, call `useBenchmarkProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkProjectQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useBenchmarkProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>(BenchmarkProjectDocument, baseOptions);
      }
export function useBenchmarkProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>(BenchmarkProjectDocument, baseOptions);
        }
export type BenchmarkProjectQueryHookResult = ReturnType<typeof useBenchmarkProjectQuery>;
export type BenchmarkProjectLazyQueryHookResult = ReturnType<typeof useBenchmarkProjectLazyQuery>;
export type BenchmarkProjectQueryResult = ApolloReactCommon.QueryResult<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>;
export const SubmitBenchmarkRunDocument = gql`
    mutation SubmitBenchmarkRun($id: ID!, $isCodeHidden: Boolean, $benchmarkEntityName: String, $benchmarkName: String) {
  submitBenchmarkRun(input: {id: $id, isCodeHidden: $isCodeHidden, benchmarkEntityName: $benchmarkEntityName, benchmarkName: $benchmarkName}) {
    benchmarkRun {
      id
      results
    }
  }
}
    `;
export type SubmitBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>;
export type SubmitBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>, 'mutation'>;

    export const SubmitBenchmarkRunComponent = (props: SubmitBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables> mutation={SubmitBenchmarkRunDocument} {...props} />
    );
    
export type SubmitBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>
    } & TChildProps;
export function withSubmitBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitBenchmarkRunMutation,
  SubmitBenchmarkRunMutationVariables,
  SubmitBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables, SubmitBenchmarkRunProps<TChildProps, TDataName>>(SubmitBenchmarkRunDocument, {
      alias: 'submitBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __useSubmitBenchmarkRunMutation__
 *
 * To run a mutation, you first call `useSubmitBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBenchmarkRunMutation, { data, loading, error }] = useSubmitBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isCodeHidden: // value for 'isCodeHidden'
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkName: // value for 'benchmarkName'
 *   },
 * });
 */
export function useSubmitBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>(SubmitBenchmarkRunDocument, baseOptions);
      }
export type SubmitBenchmarkRunMutationHookResult = ReturnType<typeof useSubmitBenchmarkRunMutation>;
export type SubmitBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<SubmitBenchmarkRunMutation>;
export type SubmitBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>;
export const PublishBenchmarkRunDocument = gql`
    mutation PublishBenchmarkRun($id: ID!, $isCodeHidden: Boolean, $benchmarkEntityName: String, $benchmarkName: String) {
  publishBenchmarkRun(input: {id: $id, isCodeHidden: $isCodeHidden, benchmarkEntityName: $benchmarkEntityName, benchmarkName: $benchmarkName}) {
    benchmarkRun {
      id
      gitHubSubmissionPR
    }
  }
}
    `;
export type PublishBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>;
export type PublishBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>, 'mutation'>;

    export const PublishBenchmarkRunComponent = (props: PublishBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables> mutation={PublishBenchmarkRunDocument} {...props} />
    );
    
export type PublishBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>
    } & TChildProps;
export function withPublishBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PublishBenchmarkRunMutation,
  PublishBenchmarkRunMutationVariables,
  PublishBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables, PublishBenchmarkRunProps<TChildProps, TDataName>>(PublishBenchmarkRunDocument, {
      alias: 'publishBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __usePublishBenchmarkRunMutation__
 *
 * To run a mutation, you first call `usePublishBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishBenchmarkRunMutation, { data, loading, error }] = usePublishBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isCodeHidden: // value for 'isCodeHidden'
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkName: // value for 'benchmarkName'
 *   },
 * });
 */
export function usePublishBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>(PublishBenchmarkRunDocument, baseOptions);
      }
export type PublishBenchmarkRunMutationHookResult = ReturnType<typeof usePublishBenchmarkRunMutation>;
export type PublishBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<PublishBenchmarkRunMutation>;
export type PublishBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>;
export const UpdateBenchmarkRunDocument = gql`
    mutation UpdateBenchmarkRun($id: ID!, $state: String, $details: JSONString) {
  updateBenchmarkRun(input: {id: $id, state: $state, details: $details}) {
    benchmarkRun {
      id
      state
      details
    }
  }
}
    `;
export type UpdateBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>;
export type UpdateBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>, 'mutation'>;

    export const UpdateBenchmarkRunComponent = (props: UpdateBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables> mutation={UpdateBenchmarkRunDocument} {...props} />
    );
    
export type UpdateBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>
    } & TChildProps;
export function withUpdateBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBenchmarkRunMutation,
  UpdateBenchmarkRunMutationVariables,
  UpdateBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables, UpdateBenchmarkRunProps<TChildProps, TDataName>>(UpdateBenchmarkRunDocument, {
      alias: 'updateBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __useUpdateBenchmarkRunMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkRunMutation, { data, loading, error }] = useUpdateBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>(UpdateBenchmarkRunDocument, baseOptions);
      }
export type UpdateBenchmarkRunMutationHookResult = ReturnType<typeof useUpdateBenchmarkRunMutation>;
export type UpdateBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<UpdateBenchmarkRunMutation>;
export type UpdateBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>;
export const CreateCustomChartDocument = gql`
    mutation CreateCustomChart($entity: String!, $name: String!, $displayName: String!, $type: String!, $access: String!, $spec: JSONString!) {
  createCustomChart(input: {entity: $entity, name: $name, displayName: $displayName, type: $type, access: $access, spec: $spec}) {
    chart {
      ...CustomChartFragment
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CreateCustomChartMutationFn = ApolloReactCommon.MutationFunction<CreateCustomChartMutation, CreateCustomChartMutationVariables>;
export type CreateCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>, 'mutation'>;

    export const CreateCustomChartComponent = (props: CreateCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomChartMutation, CreateCustomChartMutationVariables> mutation={CreateCustomChartDocument} {...props} />
    );
    
export type CreateCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomChartMutation, CreateCustomChartMutationVariables>
    } & TChildProps;
export function withCreateCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomChartMutation,
  CreateCustomChartMutationVariables,
  CreateCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomChartMutation, CreateCustomChartMutationVariables, CreateCustomChartProps<TChildProps, TDataName>>(CreateCustomChartDocument, {
      alias: 'createCustomChart',
      ...operationOptions
    });
};

/**
 * __useCreateCustomChartMutation__
 *
 * To run a mutation, you first call `useCreateCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomChartMutation, { data, loading, error }] = useCreateCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      type: // value for 'type'
 *      access: // value for 'access'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomChartMutation, CreateCustomChartMutationVariables>(CreateCustomChartDocument, baseOptions);
      }
export type CreateCustomChartMutationHookResult = ReturnType<typeof useCreateCustomChartMutation>;
export type CreateCustomChartMutationResult = ApolloReactCommon.MutationResult<CreateCustomChartMutation>;
export type CreateCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>;
export const UpdateCustomChartDocument = gql`
    mutation UpdateCustomChart($entity: String!, $name: String!, $displayName: String, $type: String, $access: String, $spec: JSONString) {
  updateCustomChart(input: {entity: $entity, name: $name, displayName: $displayName, type: $type, access: $access, spec: $spec}) {
    chart {
      ...CustomChartFragment
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type UpdateCustomChartMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>;
export type UpdateCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>, 'mutation'>;

    export const UpdateCustomChartComponent = (props: UpdateCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomChartMutation, UpdateCustomChartMutationVariables> mutation={UpdateCustomChartDocument} {...props} />
    );
    
export type UpdateCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>
    } & TChildProps;
export function withUpdateCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomChartMutation,
  UpdateCustomChartMutationVariables,
  UpdateCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomChartMutation, UpdateCustomChartMutationVariables, UpdateCustomChartProps<TChildProps, TDataName>>(UpdateCustomChartDocument, {
      alias: 'updateCustomChart',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomChartMutation__
 *
 * To run a mutation, you first call `useUpdateCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomChartMutation, { data, loading, error }] = useUpdateCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      type: // value for 'type'
 *      access: // value for 'access'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useUpdateCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>(UpdateCustomChartDocument, baseOptions);
      }
export type UpdateCustomChartMutationHookResult = ReturnType<typeof useUpdateCustomChartMutation>;
export type UpdateCustomChartMutationResult = ApolloReactCommon.MutationResult<UpdateCustomChartMutation>;
export type UpdateCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>;
export const DeleteCustomChartDocument = gql`
    mutation DeleteCustomChart($entity: String!, $name: String!) {
  deleteCustomChart(input: {entity: $entity, name: $name}) {
    success
  }
}
    `;
export type DeleteCustomChartMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>;
export type DeleteCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>, 'mutation'>;

    export const DeleteCustomChartComponent = (props: DeleteCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCustomChartMutation, DeleteCustomChartMutationVariables> mutation={DeleteCustomChartDocument} {...props} />
    );
    
export type DeleteCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>
    } & TChildProps;
export function withDeleteCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCustomChartMutation,
  DeleteCustomChartMutationVariables,
  DeleteCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCustomChartMutation, DeleteCustomChartMutationVariables, DeleteCustomChartProps<TChildProps, TDataName>>(DeleteCustomChartDocument, {
      alias: 'deleteCustomChart',
      ...operationOptions
    });
};

/**
 * __useDeleteCustomChartMutation__
 *
 * To run a mutation, you first call `useDeleteCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomChartMutation, { data, loading, error }] = useDeleteCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>(DeleteCustomChartDocument, baseOptions);
      }
export type DeleteCustomChartMutationHookResult = ReturnType<typeof useDeleteCustomChartMutation>;
export type DeleteCustomChartMutationResult = ApolloReactCommon.MutationResult<DeleteCustomChartMutation>;
export type DeleteCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>;
export const CustomChartsDocument = gql`
    query CustomCharts($entity: String!) {
  customCharts(type: "vega2", entity: $entity) {
    edges {
      node {
        ...CustomChartFragment
      }
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CustomChartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomChartsQuery, CustomChartsQueryVariables>, 'query'> & ({ variables: CustomChartsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomChartsComponent = (props: CustomChartsComponentProps) => (
      <ApolloReactComponents.Query<CustomChartsQuery, CustomChartsQueryVariables> query={CustomChartsDocument} {...props} />
    );
    
export type CustomChartsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomChartsQuery, CustomChartsQueryVariables>
    } & TChildProps;
export function withCustomCharts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomChartsQuery,
  CustomChartsQueryVariables,
  CustomChartsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomChartsQuery, CustomChartsQueryVariables, CustomChartsProps<TChildProps, TDataName>>(CustomChartsDocument, {
      alias: 'customCharts',
      ...operationOptions
    });
};

/**
 * __useCustomChartsQuery__
 *
 * To run a query within a React component, call `useCustomChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChartsQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useCustomChartsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomChartsQuery, CustomChartsQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomChartsQuery, CustomChartsQueryVariables>(CustomChartsDocument, baseOptions);
      }
export function useCustomChartsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomChartsQuery, CustomChartsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomChartsQuery, CustomChartsQueryVariables>(CustomChartsDocument, baseOptions);
        }
export type CustomChartsQueryHookResult = ReturnType<typeof useCustomChartsQuery>;
export type CustomChartsLazyQueryHookResult = ReturnType<typeof useCustomChartsLazyQuery>;
export type CustomChartsQueryResult = ApolloReactCommon.QueryResult<CustomChartsQuery, CustomChartsQueryVariables>;
export const CustomChartDocument = gql`
    query CustomChart($id: ID!) {
  customChart(id: $id) {
    ...CustomChartFragment
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CustomChartComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomChartQuery, CustomChartQueryVariables>, 'query'> & ({ variables: CustomChartQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomChartComponent = (props: CustomChartComponentProps) => (
      <ApolloReactComponents.Query<CustomChartQuery, CustomChartQueryVariables> query={CustomChartDocument} {...props} />
    );
    
export type CustomChartProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomChartQuery, CustomChartQueryVariables>
    } & TChildProps;
export function withCustomChart<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomChartQuery,
  CustomChartQueryVariables,
  CustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomChartQuery, CustomChartQueryVariables, CustomChartProps<TChildProps, TDataName>>(CustomChartDocument, {
      alias: 'customChart',
      ...operationOptions
    });
};

/**
 * __useCustomChartQuery__
 *
 * To run a query within a React component, call `useCustomChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomChartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomChartQuery, CustomChartQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomChartQuery, CustomChartQueryVariables>(CustomChartDocument, baseOptions);
      }
export function useCustomChartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomChartQuery, CustomChartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomChartQuery, CustomChartQueryVariables>(CustomChartDocument, baseOptions);
        }
export type CustomChartQueryHookResult = ReturnType<typeof useCustomChartQuery>;
export type CustomChartLazyQueryHookResult = ReturnType<typeof useCustomChartLazyQuery>;
export type CustomChartQueryResult = ApolloReactCommon.QueryResult<CustomChartQuery, CustomChartQueryVariables>;
export const CreateCustomerSubscriptionDocument = gql`
    mutation createCustomerSubscription($planId: ID!, $newOrganizationName: String!, $paymentMethod: String!, $quantity: Int!, $trial: Boolean!, $clientMutationId: String) {
  createCustomerSubscription(input: {planId: $planId, newOrganizationName: $newOrganizationName, paymentMethod: $paymentMethod, quantity: $quantity, trial: $trial, clientMutationId: $clientMutationId}) {
    subscription
    organizationId
    clientMutationId
  }
}
    `;
export type CreateCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>;
export type CreateCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const CreateCustomerSubscriptionComponent = (props: CreateCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables> mutation={CreateCustomerSubscriptionDocument} {...props} />
    );
    
export type CreateCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomerSubscriptionMutation,
  CreateCustomerSubscriptionMutationVariables,
  CreateCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables, CreateCustomerSubscriptionProps<TChildProps, TDataName>>(CreateCustomerSubscriptionDocument, {
      alias: 'createCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerSubscriptionMutation, { data, loading, error }] = useCreateCustomerSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      newOrganizationName: // value for 'newOrganizationName'
 *      paymentMethod: // value for 'paymentMethod'
 *      quantity: // value for 'quantity'
 *      trial: // value for 'trial'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>(CreateCustomerSubscriptionDocument, baseOptions);
      }
export type CreateCustomerSubscriptionMutationHookResult = ReturnType<typeof useCreateCustomerSubscriptionMutation>;
export type CreateCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateCustomerSubscriptionMutation>;
export type CreateCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>;
export const CreateCustomerTrialDocument = gql`
    mutation createCustomerTrial($planId: ID!, $userName: String!, $newOrganizationName: String!, $quantity: Int!, $trialDays: Int!, $clientMutationId: String) {
  createCustomerTrial(input: {planId: $planId, userName: $userName, newOrganizationName: $newOrganizationName, quantity: $quantity, trialDays: $trialDays, clientMutationId: $clientMutationId}) {
    subscription
    clientMutationId
  }
}
    `;
export type CreateCustomerTrialMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>;
export type CreateCustomerTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>, 'mutation'>;

    export const CreateCustomerTrialComponent = (props: CreateCustomerTrialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables> mutation={CreateCustomerTrialDocument} {...props} />
    );
    
export type CreateCustomerTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>
    } & TChildProps;
export function withCreateCustomerTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomerTrialMutation,
  CreateCustomerTrialMutationVariables,
  CreateCustomerTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables, CreateCustomerTrialProps<TChildProps, TDataName>>(CreateCustomerTrialDocument, {
      alias: 'createCustomerTrial',
      ...operationOptions
    });
};

/**
 * __useCreateCustomerTrialMutation__
 *
 * To run a mutation, you first call `useCreateCustomerTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerTrialMutation, { data, loading, error }] = useCreateCustomerTrialMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      userName: // value for 'userName'
 *      newOrganizationName: // value for 'newOrganizationName'
 *      quantity: // value for 'quantity'
 *      trialDays: // value for 'trialDays'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCustomerTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>(CreateCustomerTrialDocument, baseOptions);
      }
export type CreateCustomerTrialMutationHookResult = ReturnType<typeof useCreateCustomerTrialMutation>;
export type CreateCustomerTrialMutationResult = ApolloReactCommon.MutationResult<CreateCustomerTrialMutation>;
export type CreateCustomerTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>;
export const CreateUserLedTrialDocument = gql`
    mutation createUserLedTrial($newOrganizationName: String!, $newTeamName: String!, $orgSize: String!, $isAcademic: Boolean!, $justification: String, $clientMutationId: String) {
  createUserLedTrial(input: {newOrganizationName: $newOrganizationName, newTeamName: $newTeamName, orgSize: $orgSize, isAcademic: $isAcademic, justification: $justification, clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type CreateUserLedTrialMutationFn = ApolloReactCommon.MutationFunction<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>;
export type CreateUserLedTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>, 'mutation'>;

    export const CreateUserLedTrialComponent = (props: CreateUserLedTrialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables> mutation={CreateUserLedTrialDocument} {...props} />
    );
    
export type CreateUserLedTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>
    } & TChildProps;
export function withCreateUserLedTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserLedTrialMutation,
  CreateUserLedTrialMutationVariables,
  CreateUserLedTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables, CreateUserLedTrialProps<TChildProps, TDataName>>(CreateUserLedTrialDocument, {
      alias: 'createUserLedTrial',
      ...operationOptions
    });
};

/**
 * __useCreateUserLedTrialMutation__
 *
 * To run a mutation, you first call `useCreateUserLedTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLedTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLedTrialMutation, { data, loading, error }] = useCreateUserLedTrialMutation({
 *   variables: {
 *      newOrganizationName: // value for 'newOrganizationName'
 *      newTeamName: // value for 'newTeamName'
 *      orgSize: // value for 'orgSize'
 *      isAcademic: // value for 'isAcademic'
 *      justification: // value for 'justification'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateUserLedTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>(CreateUserLedTrialDocument, baseOptions);
      }
export type CreateUserLedTrialMutationHookResult = ReturnType<typeof useCreateUserLedTrialMutation>;
export type CreateUserLedTrialMutationResult = ApolloReactCommon.MutationResult<CreateUserLedTrialMutation>;
export type CreateUserLedTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>;
export const CancelCustomerSubscriptionDocument = gql`
    mutation cancelCustomerSubscription($organizationId: ID!, $clientMutationId: String) {
  cancelCustomerSubscription(input: {organizationId: $organizationId, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type CancelCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>;
export type CancelCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const CancelCustomerSubscriptionComponent = (props: CancelCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables> mutation={CancelCustomerSubscriptionDocument} {...props} />
    );
    
export type CancelCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withCancelCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CancelCustomerSubscriptionMutation,
  CancelCustomerSubscriptionMutationVariables,
  CancelCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables, CancelCustomerSubscriptionProps<TChildProps, TDataName>>(CancelCustomerSubscriptionDocument, {
      alias: 'cancelCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useCancelCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCustomerSubscriptionMutation, { data, loading, error }] = useCancelCustomerSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCancelCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>(CancelCustomerSubscriptionDocument, baseOptions);
      }
export type CancelCustomerSubscriptionMutationHookResult = ReturnType<typeof useCancelCustomerSubscriptionMutation>;
export type CancelCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelCustomerSubscriptionMutation>;
export type CancelCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>;
export const UpdateCustomerPaymentMethodDocument = gql`
    mutation updateCustomerPaymentMethod($organizationId: ID!, $paymentMethod: String!, $clientMutationId: String) {
  updateCustomerPaymentMethod(input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type UpdateCustomerPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>;
export type UpdateCustomerPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>, 'mutation'>;

    export const UpdateCustomerPaymentMethodComponent = (props: UpdateCustomerPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables> mutation={UpdateCustomerPaymentMethodDocument} {...props} />
    );
    
export type UpdateCustomerPaymentMethodProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>
    } & TChildProps;
export function withUpdateCustomerPaymentMethod<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomerPaymentMethodMutation,
  UpdateCustomerPaymentMethodMutationVariables,
  UpdateCustomerPaymentMethodProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables, UpdateCustomerPaymentMethodProps<TChildProps, TDataName>>(UpdateCustomerPaymentMethodDocument, {
      alias: 'updateCustomerPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerPaymentMethodMutation, { data, loading, error }] = useUpdateCustomerPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateCustomerPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>(UpdateCustomerPaymentMethodDocument, baseOptions);
      }
export type UpdateCustomerPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCustomerPaymentMethodMutation>;
export type UpdateCustomerPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerPaymentMethodMutation>;
export type UpdateCustomerPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>;
export const CustomerSubscriptionInfoDocument = gql`
    query CustomerSubscriptionInfo {
  viewer {
    id
    organizations {
      id
      name
      orgType
      stripeBillingInfo {
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        invoiceLink
        paymentMethod {
          id
          type
          cardType
          endingIn
        }
      }
      billingUser {
        id
        username
      }
      subscriptions {
        plan {
          id
          name
          unitPrice
          billingInterval
          planType
        }
        privileges
        seats
        expiresAt
        subscriptionType
        status
      }
      teams {
        id
        name
        storage: storageTree(enableReferenceTracking: false) {
          id
          size
        }
        artifact: storageTree(enableReferenceTracking: true) {
          id
          size
        }
        computeHours
      }
      members {
        id
        username
        name
        photoUrl
        admin
        teams {
          edges {
            node {
              id
              name
            }
          }
        }
        role
      }
      pendingInvites {
        email
      }
    }
  }
}
    `;
export type CustomerSubscriptionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>, 'query'>;

    export const CustomerSubscriptionInfoComponent = (props: CustomerSubscriptionInfoComponentProps) => (
      <ApolloReactComponents.Query<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables> query={CustomerSubscriptionInfoDocument} {...props} />
    );
    
export type CustomerSubscriptionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>
    } & TChildProps;
export function withCustomerSubscriptionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomerSubscriptionInfoQuery,
  CustomerSubscriptionInfoQueryVariables,
  CustomerSubscriptionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables, CustomerSubscriptionInfoProps<TChildProps, TDataName>>(CustomerSubscriptionInfoDocument, {
      alias: 'customerSubscriptionInfo',
      ...operationOptions
    });
};

/**
 * __useCustomerSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useCustomerSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSubscriptionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerSubscriptionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>(CustomerSubscriptionInfoDocument, baseOptions);
      }
export function useCustomerSubscriptionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>(CustomerSubscriptionInfoDocument, baseOptions);
        }
export type CustomerSubscriptionInfoQueryHookResult = ReturnType<typeof useCustomerSubscriptionInfoQuery>;
export type CustomerSubscriptionInfoLazyQueryHookResult = ReturnType<typeof useCustomerSubscriptionInfoLazyQuery>;
export type CustomerSubscriptionInfoQueryResult = ApolloReactCommon.QueryResult<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>;
export const OrganizationSubscriptionInfoDocument = gql`
    query OrganizationSubscriptionInfo($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    name
    orgType
    flags
    stripeBillingInfo {
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      invoiceLink
      paymentMethod {
        id
        type
        cardType
        endingIn
      }
    }
    billingUser {
      id
      username
    }
    subscriptions {
      plan {
        id
        name
        unitPrice
        billingInterval
        planType
      }
      privileges
      seats
      expiresAt
      subscriptionType
      status
    }
    members {
      id
      username
      name
      photoUrl
      admin
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
      role
    }
    pendingInvites {
      email
    }
  }
}
    `;
export type OrganizationSubscriptionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>, 'query'> & ({ variables: OrganizationSubscriptionInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationSubscriptionInfoComponent = (props: OrganizationSubscriptionInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables> query={OrganizationSubscriptionInfoDocument} {...props} />
    );
    
export type OrganizationSubscriptionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionInfoQuery,
  OrganizationSubscriptionInfoQueryVariables,
  OrganizationSubscriptionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables, OrganizationSubscriptionInfoProps<TChildProps, TDataName>>(OrganizationSubscriptionInfoDocument, {
      alias: 'organizationSubscriptionInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationSubscriptionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>(OrganizationSubscriptionInfoDocument, baseOptions);
      }
export function useOrganizationSubscriptionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>(OrganizationSubscriptionInfoDocument, baseOptions);
        }
export type OrganizationSubscriptionInfoQueryHookResult = ReturnType<typeof useOrganizationSubscriptionInfoQuery>;
export type OrganizationSubscriptionInfoLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionInfoLazyQuery>;
export type OrganizationSubscriptionInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>;
export const UpgradeCustomerSubscriptionDocument = gql`
    mutation upgradeCustomerSubscription($planID: ID!, $organizationID: ID!, $paymentMethod: String!, $quantity: Int!, $clientMutationId: String) {
  upgradeCustomerSubscription(input: {planID: $planID, organizationID: $organizationID, paymentMethod: $paymentMethod, quantity: $quantity, clientMutationId: $clientMutationId}) {
    subscription
    organizationID
    clientMutationId
  }
}
    `;
export type UpgradeCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>;
export type UpgradeCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const UpgradeCustomerSubscriptionComponent = (props: UpgradeCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables> mutation={UpgradeCustomerSubscriptionDocument} {...props} />
    );
    
export type UpgradeCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withUpgradeCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpgradeCustomerSubscriptionMutation,
  UpgradeCustomerSubscriptionMutationVariables,
  UpgradeCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables, UpgradeCustomerSubscriptionProps<TChildProps, TDataName>>(UpgradeCustomerSubscriptionDocument, {
      alias: 'upgradeCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useUpgradeCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeCustomerSubscriptionMutation, { data, loading, error }] = useUpgradeCustomerSubscriptionMutation({
 *   variables: {
 *      planID: // value for 'planID'
 *      organizationID: // value for 'organizationID'
 *      paymentMethod: // value for 'paymentMethod'
 *      quantity: // value for 'quantity'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpgradeCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>(UpgradeCustomerSubscriptionDocument, baseOptions);
      }
export type UpgradeCustomerSubscriptionMutationHookResult = ReturnType<typeof useUpgradeCustomerSubscriptionMutation>;
export type UpgradeCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpgradeCustomerSubscriptionMutation>;
export type UpgradeCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>;
export const DataTableRunFilesDocument = gql`
    query DataTableRunFiles($entityName: String!, $projectName: String!, $dataFrameKeys: [String!]!, $groupKeys: [String!], $order: String, $limit: Int, $after: String, $filters: JSONString, $columns: [String!], $includeRows: Boolean!, $includeSchema: Boolean!) {
  dataFrame(entityName: $entityName, projectName: $projectName, dataFrameKeys: $dataFrameKeys, groupKeys: $groupKeys, order: $order, filters: $filters, columns: $columns, first: $limit, after: $after) @include(if: $includeRows) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    schema
    edges {
      node {
        row
      }
    }
  }
  dataFrameSchema(entityName: $entityName, projectName: $projectName, dataFrameKeys: $dataFrameKeys) @include(if: $includeSchema)
}
    `;
export type DataTableRunFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>, 'query'> & ({ variables: DataTableRunFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DataTableRunFilesComponent = (props: DataTableRunFilesComponentProps) => (
      <ApolloReactComponents.Query<DataTableRunFilesQuery, DataTableRunFilesQueryVariables> query={DataTableRunFilesDocument} {...props} />
    );
    
export type DataTableRunFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>
    } & TChildProps;
export function withDataTableRunFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DataTableRunFilesQuery,
  DataTableRunFilesQueryVariables,
  DataTableRunFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DataTableRunFilesQuery, DataTableRunFilesQueryVariables, DataTableRunFilesProps<TChildProps, TDataName>>(DataTableRunFilesDocument, {
      alias: 'dataTableRunFiles',
      ...operationOptions
    });
};

/**
 * __useDataTableRunFilesQuery__
 *
 * To run a query within a React component, call `useDataTableRunFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTableRunFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTableRunFilesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      dataFrameKeys: // value for 'dataFrameKeys'
 *      groupKeys: // value for 'groupKeys'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      columns: // value for 'columns'
 *      includeRows: // value for 'includeRows'
 *      includeSchema: // value for 'includeSchema'
 *   },
 * });
 */
export function useDataTableRunFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>(DataTableRunFilesDocument, baseOptions);
      }
export function useDataTableRunFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>(DataTableRunFilesDocument, baseOptions);
        }
export type DataTableRunFilesQueryHookResult = ReturnType<typeof useDataTableRunFilesQuery>;
export type DataTableRunFilesLazyQueryHookResult = ReturnType<typeof useDataTableRunFilesLazyQuery>;
export type DataTableRunFilesQueryResult = ApolloReactCommon.QueryResult<DataTableRunFilesQuery, DataTableRunFilesQueryVariables>;
export const BenchmarkDiscussionThreadsDocument = gql`
    query BenchmarkDiscussionThreads($benchmarkEntityName: String!, $benchmarkProjectName: String!) {
  project(entityName: $benchmarkEntityName, name: $benchmarkProjectName) {
    id
    benchmarkDiscussionThreads {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          title
          body
          upvotes
          poster {
            id
            photoUrl
            name
          }
          comments {
            totalCount
          }
        }
      }
    }
  }
}
    `;
export type BenchmarkDiscussionThreadsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>, 'query'> & ({ variables: BenchmarkDiscussionThreadsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BenchmarkDiscussionThreadsComponent = (props: BenchmarkDiscussionThreadsComponentProps) => (
      <ApolloReactComponents.Query<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables> query={BenchmarkDiscussionThreadsDocument} {...props} />
    );
    
export type BenchmarkDiscussionThreadsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>
    } & TChildProps;
export function withBenchmarkDiscussionThreads<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BenchmarkDiscussionThreadsQuery,
  BenchmarkDiscussionThreadsQueryVariables,
  BenchmarkDiscussionThreadsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables, BenchmarkDiscussionThreadsProps<TChildProps, TDataName>>(BenchmarkDiscussionThreadsDocument, {
      alias: 'benchmarkDiscussionThreads',
      ...operationOptions
    });
};

/**
 * __useBenchmarkDiscussionThreadsQuery__
 *
 * To run a query within a React component, call `useBenchmarkDiscussionThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkDiscussionThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkDiscussionThreadsQuery({
 *   variables: {
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkProjectName: // value for 'benchmarkProjectName'
 *   },
 * });
 */
export function useBenchmarkDiscussionThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>(BenchmarkDiscussionThreadsDocument, baseOptions);
      }
export function useBenchmarkDiscussionThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>(BenchmarkDiscussionThreadsDocument, baseOptions);
        }
export type BenchmarkDiscussionThreadsQueryHookResult = ReturnType<typeof useBenchmarkDiscussionThreadsQuery>;
export type BenchmarkDiscussionThreadsLazyQueryHookResult = ReturnType<typeof useBenchmarkDiscussionThreadsLazyQuery>;
export type BenchmarkDiscussionThreadsQueryResult = ApolloReactCommon.QueryResult<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>;
export const CreateBenchmarkDiscussionThreadDocument = gql`
    mutation CreateBenchmarkDiscussionThread($benchmarkEntityName: String!, $benchmarkProjectName: String!, $title: String!, $body: String!) {
  createBenchmarkDiscussionThread(input: {benchmarkEntityName: $benchmarkEntityName, benchmarkProjectName: $benchmarkProjectName, title: $title, body: $body}) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type CreateBenchmarkDiscussionThreadMutationFn = ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>;
export type CreateBenchmarkDiscussionThreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>, 'mutation'>;

    export const CreateBenchmarkDiscussionThreadComponent = (props: CreateBenchmarkDiscussionThreadComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables> mutation={CreateBenchmarkDiscussionThreadDocument} {...props} />
    );
    
export type CreateBenchmarkDiscussionThreadProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>
    } & TChildProps;
export function withCreateBenchmarkDiscussionThread<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBenchmarkDiscussionThreadMutation,
  CreateBenchmarkDiscussionThreadMutationVariables,
  CreateBenchmarkDiscussionThreadProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables, CreateBenchmarkDiscussionThreadProps<TChildProps, TDataName>>(CreateBenchmarkDiscussionThreadDocument, {
      alias: 'createBenchmarkDiscussionThread',
      ...operationOptions
    });
};

/**
 * __useCreateBenchmarkDiscussionThreadMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkDiscussionThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkDiscussionThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkDiscussionThreadMutation, { data, loading, error }] = useCreateBenchmarkDiscussionThreadMutation({
 *   variables: {
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkProjectName: // value for 'benchmarkProjectName'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBenchmarkDiscussionThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>(CreateBenchmarkDiscussionThreadDocument, baseOptions);
      }
export type CreateBenchmarkDiscussionThreadMutationHookResult = ReturnType<typeof useCreateBenchmarkDiscussionThreadMutation>;
export type CreateBenchmarkDiscussionThreadMutationResult = ApolloReactCommon.MutationResult<CreateBenchmarkDiscussionThreadMutation>;
export type CreateBenchmarkDiscussionThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>;
export const UpdateDiscussionThreadDocument = gql`
    mutation UpdateDiscussionThread($discussionThreadID: ID!, $body: String!) {
  updateDiscussionThread(input: {discussionThreadID: $discussionThreadID, body: $body}) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type UpdateDiscussionThreadMutationFn = ApolloReactCommon.MutationFunction<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>;
export type UpdateDiscussionThreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>, 'mutation'>;

    export const UpdateDiscussionThreadComponent = (props: UpdateDiscussionThreadComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables> mutation={UpdateDiscussionThreadDocument} {...props} />
    );
    
export type UpdateDiscussionThreadProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>
    } & TChildProps;
export function withUpdateDiscussionThread<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDiscussionThreadMutation,
  UpdateDiscussionThreadMutationVariables,
  UpdateDiscussionThreadProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables, UpdateDiscussionThreadProps<TChildProps, TDataName>>(UpdateDiscussionThreadDocument, {
      alias: 'updateDiscussionThread',
      ...operationOptions
    });
};

/**
 * __useUpdateDiscussionThreadMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionThreadMutation, { data, loading, error }] = useUpdateDiscussionThreadMutation({
 *   variables: {
 *      discussionThreadID: // value for 'discussionThreadID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateDiscussionThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>(UpdateDiscussionThreadDocument, baseOptions);
      }
export type UpdateDiscussionThreadMutationHookResult = ReturnType<typeof useUpdateDiscussionThreadMutation>;
export type UpdateDiscussionThreadMutationResult = ApolloReactCommon.MutationResult<UpdateDiscussionThreadMutation>;
export type UpdateDiscussionThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>;
export const CreateBenchmarkDiscussionCommentDocument = gql`
    mutation CreateBenchmarkDiscussionComment($discussionThreadID: ID!, $parentCommentID: ID, $body: String!) {
  createBenchmarkDiscussionComment(input: {discussionThreadID: $discussionThreadID, parentCommentID: $parentCommentID, body: $body}) {
    discussionComment {
      id
      createdAt
      updatedAt
      deletedAt
      body
      isParent
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type CreateBenchmarkDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>;
export type CreateBenchmarkDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>, 'mutation'>;

    export const CreateBenchmarkDiscussionCommentComponent = (props: CreateBenchmarkDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables> mutation={CreateBenchmarkDiscussionCommentDocument} {...props} />
    );
    
export type CreateBenchmarkDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>
    } & TChildProps;
export function withCreateBenchmarkDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBenchmarkDiscussionCommentMutation,
  CreateBenchmarkDiscussionCommentMutationVariables,
  CreateBenchmarkDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables, CreateBenchmarkDiscussionCommentProps<TChildProps, TDataName>>(CreateBenchmarkDiscussionCommentDocument, {
      alias: 'createBenchmarkDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useCreateBenchmarkDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkDiscussionCommentMutation, { data, loading, error }] = useCreateBenchmarkDiscussionCommentMutation({
 *   variables: {
 *      discussionThreadID: // value for 'discussionThreadID'
 *      parentCommentID: // value for 'parentCommentID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBenchmarkDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>(CreateBenchmarkDiscussionCommentDocument, baseOptions);
      }
export type CreateBenchmarkDiscussionCommentMutationHookResult = ReturnType<typeof useCreateBenchmarkDiscussionCommentMutation>;
export type CreateBenchmarkDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<CreateBenchmarkDiscussionCommentMutation>;
export type CreateBenchmarkDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>;
export const UpdateDiscussionCommentDocument = gql`
    mutation UpdateDiscussionComment($discussionCommentID: ID!, $body: String!) {
  updateDiscussionComment(input: {discussionCommentID: $discussionCommentID, body: $body}) {
    discussionComment {
      id
      createdAt
      updatedAt
      deletedAt
      body
      isParent
      poster {
        id
        photoUrl
        name
        username
      }
    }
  }
}
    `;
export type UpdateDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>;
export type UpdateDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>, 'mutation'>;

    export const UpdateDiscussionCommentComponent = (props: UpdateDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables> mutation={UpdateDiscussionCommentDocument} {...props} />
    );
    
export type UpdateDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>
    } & TChildProps;
export function withUpdateDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDiscussionCommentMutation,
  UpdateDiscussionCommentMutationVariables,
  UpdateDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables, UpdateDiscussionCommentProps<TChildProps, TDataName>>(UpdateDiscussionCommentDocument, {
      alias: 'updateDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useUpdateDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionCommentMutation, { data, loading, error }] = useUpdateDiscussionCommentMutation({
 *   variables: {
 *      discussionCommentID: // value for 'discussionCommentID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>(UpdateDiscussionCommentDocument, baseOptions);
      }
export type UpdateDiscussionCommentMutationHookResult = ReturnType<typeof useUpdateDiscussionCommentMutation>;
export type UpdateDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<UpdateDiscussionCommentMutation>;
export type UpdateDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>;
export const DeleteDiscussionCommentDocument = gql`
    mutation DeleteDiscussionComment($id: ID!, $deleteThread: Boolean) {
  deleteDiscussionComment(input: {id: $id, deleteThread: $deleteThread}) {
    success
  }
}
    `;
export type DeleteDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>;
export type DeleteDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>, 'mutation'>;

    export const DeleteDiscussionCommentComponent = (props: DeleteDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables> mutation={DeleteDiscussionCommentDocument} {...props} />
    );
    
export type DeleteDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>
    } & TChildProps;
export function withDeleteDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDiscussionCommentMutation,
  DeleteDiscussionCommentMutationVariables,
  DeleteDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables, DeleteDiscussionCommentProps<TChildProps, TDataName>>(DeleteDiscussionCommentDocument, {
      alias: 'deleteDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useDeleteDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useDeleteDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscussionCommentMutation, { data, loading, error }] = useDeleteDiscussionCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteThread: // value for 'deleteThread'
 *   },
 * });
 */
export function useDeleteDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>(DeleteDiscussionCommentDocument, baseOptions);
      }
export type DeleteDiscussionCommentMutationHookResult = ReturnType<typeof useDeleteDiscussionCommentMutation>;
export type DeleteDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<DeleteDiscussionCommentMutation>;
export type DeleteDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>;
export const ViewDiscussionThreadsDocument = gql`
    query ViewDiscussionThreads($viewID: ID!) {
  view(id: $viewID) {
    id
    discussionThreads {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          title
          body
          upvotes
          poster {
            id
            photoUrl
            name
            username
          }
          comments {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                createdAt
                updatedAt
                body
                poster {
                  id
                  photoUrl
                  name
                  username
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ViewDiscussionThreadsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>, 'query'> & ({ variables: ViewDiscussionThreadsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewDiscussionThreadsComponent = (props: ViewDiscussionThreadsComponentProps) => (
      <ApolloReactComponents.Query<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables> query={ViewDiscussionThreadsDocument} {...props} />
    );
    
export type ViewDiscussionThreadsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>
    } & TChildProps;
export function withViewDiscussionThreads<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewDiscussionThreadsQuery,
  ViewDiscussionThreadsQueryVariables,
  ViewDiscussionThreadsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables, ViewDiscussionThreadsProps<TChildProps, TDataName>>(ViewDiscussionThreadsDocument, {
      alias: 'viewDiscussionThreads',
      ...operationOptions
    });
};

/**
 * __useViewDiscussionThreadsQuery__
 *
 * To run a query within a React component, call `useViewDiscussionThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDiscussionThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDiscussionThreadsQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useViewDiscussionThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>(ViewDiscussionThreadsDocument, baseOptions);
      }
export function useViewDiscussionThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>(ViewDiscussionThreadsDocument, baseOptions);
        }
export type ViewDiscussionThreadsQueryHookResult = ReturnType<typeof useViewDiscussionThreadsQuery>;
export type ViewDiscussionThreadsLazyQueryHookResult = ReturnType<typeof useViewDiscussionThreadsLazyQuery>;
export type ViewDiscussionThreadsQueryResult = ApolloReactCommon.QueryResult<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>;
export const CreateViewDiscussionCommentDocument = gql`
    mutation CreateViewDiscussionComment($viewID: ID!, $discussionThreadID: ID, $body: String!, $notifyAllSubscribers: Boolean) {
  createViewDiscussionComment(input: {viewID: $viewID, discussionThreadID: $discussionThreadID, body: $body, notifyAllSubscribers: $notifyAllSubscribers}) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
        username
      }
    }
    discussionComment {
      id
      createdAt
      updatedAt
      body
      poster {
        id
        photoUrl
        name
        username
      }
    }
    alertSubscription {
      id
    }
  }
}
    `;
export type CreateViewDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>;
export type CreateViewDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>, 'mutation'>;

    export const CreateViewDiscussionCommentComponent = (props: CreateViewDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables> mutation={CreateViewDiscussionCommentDocument} {...props} />
    );
    
export type CreateViewDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>
    } & TChildProps;
export function withCreateViewDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateViewDiscussionCommentMutation,
  CreateViewDiscussionCommentMutationVariables,
  CreateViewDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables, CreateViewDiscussionCommentProps<TChildProps, TDataName>>(CreateViewDiscussionCommentDocument, {
      alias: 'createViewDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useCreateViewDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useCreateViewDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewDiscussionCommentMutation, { data, loading, error }] = useCreateViewDiscussionCommentMutation({
 *   variables: {
 *      viewID: // value for 'viewID'
 *      discussionThreadID: // value for 'discussionThreadID'
 *      body: // value for 'body'
 *      notifyAllSubscribers: // value for 'notifyAllSubscribers'
 *   },
 * });
 */
export function useCreateViewDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>(CreateViewDiscussionCommentDocument, baseOptions);
      }
export type CreateViewDiscussionCommentMutationHookResult = ReturnType<typeof useCreateViewDiscussionCommentMutation>;
export type CreateViewDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<CreateViewDiscussionCommentMutation>;
export type CreateViewDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>;
export const EntityPageDocument = gql`
    query EntityPage($entityName: String!, $userStatsStartDate: DateTime!) {
  entity(name: $entityName) {
    ...TeamPageFragment
  }
  user(userName: $entityName) {
    ...ProfilePageFragment
  }
  viewer(entityName: $entityName) {
    username
    admin
  }
}
    ${TeamPageFragmentFragmentDoc}
${ProfilePageFragmentFragmentDoc}`;
export type EntityPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityPageQuery, EntityPageQueryVariables>, 'query'> & ({ variables: EntityPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityPageComponent = (props: EntityPageComponentProps) => (
      <ApolloReactComponents.Query<EntityPageQuery, EntityPageQueryVariables> query={EntityPageDocument} {...props} />
    );
    
export type EntityPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityPageQuery, EntityPageQueryVariables>
    } & TChildProps;
export function withEntityPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityPageQuery,
  EntityPageQueryVariables,
  EntityPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityPageQuery, EntityPageQueryVariables, EntityPageProps<TChildProps, TDataName>>(EntityPageDocument, {
      alias: 'entityPage',
      ...operationOptions
    });
};

/**
 * __useEntityPageQuery__
 *
 * To run a query within a React component, call `useEntityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityPageQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      userStatsStartDate: // value for 'userStatsStartDate'
 *   },
 * });
 */
export function useEntityPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityPageQuery, EntityPageQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityPageQuery, EntityPageQueryVariables>(EntityPageDocument, baseOptions);
      }
export function useEntityPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityPageQuery, EntityPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityPageQuery, EntityPageQueryVariables>(EntityPageDocument, baseOptions);
        }
export type EntityPageQueryHookResult = ReturnType<typeof useEntityPageQuery>;
export type EntityPageLazyQueryHookResult = ReturnType<typeof useEntityPageLazyQuery>;
export type EntityPageQueryResult = ApolloReactCommon.QueryResult<EntityPageQuery, EntityPageQueryVariables>;
export const EntityStorageDocument = gql`
    query EntityStorage($entityName: String, $enableReferenceTracking: Boolean) {
  entity(name: $entityName) {
    id
    storageBytes
    referenceBytes
    computeHours
    storage: storageTree(enableReferenceTracking: $enableReferenceTracking) {
      id
      size
    }
  }
}
    `;
export type EntityStorageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityStorageQuery, EntityStorageQueryVariables>, 'query'>;

    export const EntityStorageComponent = (props: EntityStorageComponentProps) => (
      <ApolloReactComponents.Query<EntityStorageQuery, EntityStorageQueryVariables> query={EntityStorageDocument} {...props} />
    );
    
export type EntityStorageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityStorageQuery, EntityStorageQueryVariables>
    } & TChildProps;
export function withEntityStorage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityStorageQuery,
  EntityStorageQueryVariables,
  EntityStorageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityStorageQuery, EntityStorageQueryVariables, EntityStorageProps<TChildProps, TDataName>>(EntityStorageDocument, {
      alias: 'entityStorage',
      ...operationOptions
    });
};

/**
 * __useEntityStorageQuery__
 *
 * To run a query within a React component, call `useEntityStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityStorageQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      enableReferenceTracking: // value for 'enableReferenceTracking'
 *   },
 * });
 */
export function useEntityStorageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityStorageQuery, EntityStorageQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityStorageQuery, EntityStorageQueryVariables>(EntityStorageDocument, baseOptions);
      }
export function useEntityStorageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityStorageQuery, EntityStorageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityStorageQuery, EntityStorageQueryVariables>(EntityStorageDocument, baseOptions);
        }
export type EntityStorageQueryHookResult = ReturnType<typeof useEntityStorageQuery>;
export type EntityStorageLazyQueryHookResult = ReturnType<typeof useEntityStorageLazyQuery>;
export type EntityStorageQueryResult = ApolloReactCommon.QueryResult<EntityStorageQuery, EntityStorageQueryVariables>;
export const EntityStorageTreeDocument = gql`
    query EntityStorageTree($entityName: String!, $enableReferenceTracking: Boolean!) {
  entity(name: $entityName) {
    id
    storageTree(enableReferenceTracking: $enableReferenceTracking) {
      id
      name
      size
      projects {
        totalSize
        edges {
          node {
            id
            name
            size
            projectID
            runs @skip(if: $enableReferenceTracking) {
              totalSize
              edges {
                node {
                  ...RunNode
                }
              }
            }
            artifactSequences {
              totalSize
              edges {
                node {
                  ...ArtifactNode
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${RunNodeFragmentDoc}
${ArtifactNodeFragmentDoc}`;
export type EntityStorageTreeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>, 'query'> & ({ variables: EntityStorageTreeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityStorageTreeComponent = (props: EntityStorageTreeComponentProps) => (
      <ApolloReactComponents.Query<EntityStorageTreeQuery, EntityStorageTreeQueryVariables> query={EntityStorageTreeDocument} {...props} />
    );
    
export type EntityStorageTreeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>
    } & TChildProps;
export function withEntityStorageTree<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityStorageTreeQuery,
  EntityStorageTreeQueryVariables,
  EntityStorageTreeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityStorageTreeQuery, EntityStorageTreeQueryVariables, EntityStorageTreeProps<TChildProps, TDataName>>(EntityStorageTreeDocument, {
      alias: 'entityStorageTree',
      ...operationOptions
    });
};

/**
 * __useEntityStorageTreeQuery__
 *
 * To run a query within a React component, call `useEntityStorageTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityStorageTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityStorageTreeQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      enableReferenceTracking: // value for 'enableReferenceTracking'
 *   },
 * });
 */
export function useEntityStorageTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>(EntityStorageTreeDocument, baseOptions);
      }
export function useEntityStorageTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>(EntityStorageTreeDocument, baseOptions);
        }
export type EntityStorageTreeQueryHookResult = ReturnType<typeof useEntityStorageTreeQuery>;
export type EntityStorageTreeLazyQueryHookResult = ReturnType<typeof useEntityStorageTreeLazyQuery>;
export type EntityStorageTreeQueryResult = ApolloReactCommon.QueryResult<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>;
export const GroupDocument = gql`
    query Group($name: String!, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    totalRunsWithGroup
    totalRunsWithJobType
    access
    readOnly
    entity {
      id
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type GroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupQuery, GroupQueryVariables>, 'query'> & ({ variables: GroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupComponent = (props: GroupComponentProps) => (
      <ApolloReactComponents.Query<GroupQuery, GroupQueryVariables> query={GroupDocument} {...props} />
    );
    
export type GroupProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupQuery, GroupQueryVariables>
    } & TChildProps;
export function withGroup<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupQuery,
  GroupQueryVariables,
  GroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupQuery, GroupQueryVariables, GroupProps<TChildProps, TDataName>>(GroupDocument, {
      alias: 'group',
      ...operationOptions
    });
};

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
      }
export function useGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = ApolloReactCommon.QueryResult<GroupQuery, GroupQueryVariables>;
export const CreateSlackIntegrationDocument = gql`
    mutation CreateSlackIntegration($code: String!, $entityName: String!, $redirectURI: String!) {
  createSlackIntegration(input: {code: $code, entityName: $entityName, redirectURI: $redirectURI}) {
    integration {
      id
    }
  }
}
    `;
export type CreateSlackIntegrationMutationFn = ApolloReactCommon.MutationFunction<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>;
export type CreateSlackIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>, 'mutation'>;

    export const CreateSlackIntegrationComponent = (props: CreateSlackIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables> mutation={CreateSlackIntegrationDocument} {...props} />
    );
    
export type CreateSlackIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>
    } & TChildProps;
export function withCreateSlackIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSlackIntegrationMutation,
  CreateSlackIntegrationMutationVariables,
  CreateSlackIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables, CreateSlackIntegrationProps<TChildProps, TDataName>>(CreateSlackIntegrationDocument, {
      alias: 'createSlackIntegration',
      ...operationOptions
    });
};

/**
 * __useCreateSlackIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateSlackIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackIntegrationMutation, { data, loading, error }] = useCreateSlackIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      entityName: // value for 'entityName'
 *      redirectURI: // value for 'redirectURI'
 *   },
 * });
 */
export function useCreateSlackIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>(CreateSlackIntegrationDocument, baseOptions);
      }
export type CreateSlackIntegrationMutationHookResult = ReturnType<typeof useCreateSlackIntegrationMutation>;
export type CreateSlackIntegrationMutationResult = ApolloReactCommon.MutationResult<CreateSlackIntegrationMutation>;
export type CreateSlackIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>;
export const CreateGitHubIntegrationDocument = gql`
    mutation CreateGitHubIntegration($code: String!, $entityName: String!, $redirectURI: String!, $state: String!) {
  createGitHubOAuthIntegration(input: {code: $code, entityName: $entityName, state: $state, redirectURI: $redirectURI}) {
    integration {
      id
    }
  }
}
    `;
export type CreateGitHubIntegrationMutationFn = ApolloReactCommon.MutationFunction<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>;
export type CreateGitHubIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>, 'mutation'>;

    export const CreateGitHubIntegrationComponent = (props: CreateGitHubIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables> mutation={CreateGitHubIntegrationDocument} {...props} />
    );
    
export type CreateGitHubIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>
    } & TChildProps;
export function withCreateGitHubIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGitHubIntegrationMutation,
  CreateGitHubIntegrationMutationVariables,
  CreateGitHubIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables, CreateGitHubIntegrationProps<TChildProps, TDataName>>(CreateGitHubIntegrationDocument, {
      alias: 'createGitHubIntegration',
      ...operationOptions
    });
};

/**
 * __useCreateGitHubIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateGitHubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGitHubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGitHubIntegrationMutation, { data, loading, error }] = useCreateGitHubIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      entityName: // value for 'entityName'
 *      redirectURI: // value for 'redirectURI'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useCreateGitHubIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>(CreateGitHubIntegrationDocument, baseOptions);
      }
export type CreateGitHubIntegrationMutationHookResult = ReturnType<typeof useCreateGitHubIntegrationMutation>;
export type CreateGitHubIntegrationMutationResult = ApolloReactCommon.MutationResult<CreateGitHubIntegrationMutation>;
export type CreateGitHubIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($id: ID!) {
  deleteIntegration(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteIntegrationMutationFn = ApolloReactCommon.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export type DeleteIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>, 'mutation'>;

    export const DeleteIntegrationComponent = (props: DeleteIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables> mutation={DeleteIntegrationDocument} {...props} />
    );
    
export type DeleteIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>
    } & TChildProps;
export function withDeleteIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
  DeleteIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteIntegrationMutation, DeleteIntegrationMutationVariables, DeleteIntegrationProps<TChildProps, TDataName>>(DeleteIntegrationDocument, {
      alias: 'deleteIntegration',
      ...operationOptions
    });
};

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, baseOptions);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = ApolloReactCommon.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const GenerateLocalLicenseDocument = gql`
    mutation generateLocalLicense($customerName: String!, $contactEmail: String!, $teams: Int!, $seats: Int!, $storageGigs: Int!, $expDuration: Int!, $flags: [String!]!, $notes: String, $clientMutationId: String) {
  generateLocalLicense(input: {customerName: $customerName, contactEmail: $contactEmail, teams: $teams, seats: $seats, storageGigs: $storageGigs, expDuration: $expDuration, flags: $flags, notes: $notes, clientMutationId: $clientMutationId}) {
    localLicense {
      id
      uid
      license
      customerName
      contactEmail
      generatedBy {
        id
        username
      }
      claims {
        seats
        teams
        storageGigs
        flags
        expiresAt
      }
      notes
    }
    clientMutationId
  }
}
    `;
export type GenerateLocalLicenseMutationFn = ApolloReactCommon.MutationFunction<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>;
export type GenerateLocalLicenseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>, 'mutation'>;

    export const GenerateLocalLicenseComponent = (props: GenerateLocalLicenseComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables> mutation={GenerateLocalLicenseDocument} {...props} />
    );
    
export type GenerateLocalLicenseProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>
    } & TChildProps;
export function withGenerateLocalLicense<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateLocalLicenseMutation,
  GenerateLocalLicenseMutationVariables,
  GenerateLocalLicenseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables, GenerateLocalLicenseProps<TChildProps, TDataName>>(GenerateLocalLicenseDocument, {
      alias: 'generateLocalLicense',
      ...operationOptions
    });
};

/**
 * __useGenerateLocalLicenseMutation__
 *
 * To run a mutation, you first call `useGenerateLocalLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLocalLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLocalLicenseMutation, { data, loading, error }] = useGenerateLocalLicenseMutation({
 *   variables: {
 *      customerName: // value for 'customerName'
 *      contactEmail: // value for 'contactEmail'
 *      teams: // value for 'teams'
 *      seats: // value for 'seats'
 *      storageGigs: // value for 'storageGigs'
 *      expDuration: // value for 'expDuration'
 *      flags: // value for 'flags'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useGenerateLocalLicenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>(GenerateLocalLicenseDocument, baseOptions);
      }
export type GenerateLocalLicenseMutationHookResult = ReturnType<typeof useGenerateLocalLicenseMutation>;
export type GenerateLocalLicenseMutationResult = ApolloReactCommon.MutationResult<GenerateLocalLicenseMutation>;
export type GenerateLocalLicenseMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateLocalLicenseMutation, GenerateLocalLicenseMutationVariables>;
export const AllLocalLicensesDocument = gql`
    query allLocalLicenses {
  localLicenses {
    id
    uid
    license
    customerName
    contactEmail
    generatedBy {
      id
      username
    }
    claims {
      seats
      teams
      storageGigs
      flags
      expiresAt
    }
    notes
  }
}
    `;
export type AllLocalLicensesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>, 'query'>;

    export const AllLocalLicensesComponent = (props: AllLocalLicensesComponentProps) => (
      <ApolloReactComponents.Query<AllLocalLicensesQuery, AllLocalLicensesQueryVariables> query={AllLocalLicensesDocument} {...props} />
    );
    
export type AllLocalLicensesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>
    } & TChildProps;
export function withAllLocalLicenses<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllLocalLicensesQuery,
  AllLocalLicensesQueryVariables,
  AllLocalLicensesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllLocalLicensesQuery, AllLocalLicensesQueryVariables, AllLocalLicensesProps<TChildProps, TDataName>>(AllLocalLicensesDocument, {
      alias: 'allLocalLicenses',
      ...operationOptions
    });
};

/**
 * __useAllLocalLicensesQuery__
 *
 * To run a query within a React component, call `useAllLocalLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLocalLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLocalLicensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllLocalLicensesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>(AllLocalLicensesDocument, baseOptions);
      }
export function useAllLocalLicensesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>(AllLocalLicensesDocument, baseOptions);
        }
export type AllLocalLicensesQueryHookResult = ReturnType<typeof useAllLocalLicensesQuery>;
export type AllLocalLicensesLazyQueryHookResult = ReturnType<typeof useAllLocalLicensesLazyQuery>;
export type AllLocalLicensesQueryResult = ApolloReactCommon.QueryResult<AllLocalLicensesQuery, AllLocalLicensesQueryVariables>;
export const LocalLicensesDocument = gql`
    query LocalLicenses($expired: Boolean = false) {
  localLicenses(expired: $expired) {
    id
    uid
    license
    customerName
    contactEmail
    generatedBy {
      id
      username
    }
    claims {
      seats
      teams
      storageGigs
      flags
      expiresAt
    }
    notes
  }
}
    `;
export type LocalLicensesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LocalLicensesQuery, LocalLicensesQueryVariables>, 'query'>;

    export const LocalLicensesComponent = (props: LocalLicensesComponentProps) => (
      <ApolloReactComponents.Query<LocalLicensesQuery, LocalLicensesQueryVariables> query={LocalLicensesDocument} {...props} />
    );
    
export type LocalLicensesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LocalLicensesQuery, LocalLicensesQueryVariables>
    } & TChildProps;
export function withLocalLicenses<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LocalLicensesQuery,
  LocalLicensesQueryVariables,
  LocalLicensesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LocalLicensesQuery, LocalLicensesQueryVariables, LocalLicensesProps<TChildProps, TDataName>>(LocalLicensesDocument, {
      alias: 'localLicenses',
      ...operationOptions
    });
};

/**
 * __useLocalLicensesQuery__
 *
 * To run a query within a React component, call `useLocalLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalLicensesQuery({
 *   variables: {
 *      expired: // value for 'expired'
 *   },
 * });
 */
export function useLocalLicensesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocalLicensesQuery, LocalLicensesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocalLicensesQuery, LocalLicensesQueryVariables>(LocalLicensesDocument, baseOptions);
      }
export function useLocalLicensesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocalLicensesQuery, LocalLicensesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocalLicensesQuery, LocalLicensesQueryVariables>(LocalLicensesDocument, baseOptions);
        }
export type LocalLicensesQueryHookResult = ReturnType<typeof useLocalLicensesQuery>;
export type LocalLicensesLazyQueryHookResult = ReturnType<typeof useLocalLicensesLazyQuery>;
export type LocalLicensesQueryResult = ApolloReactCommon.QueryResult<LocalLicensesQuery, LocalLicensesQueryVariables>;
export const UpdateLocalLicenseDocument = gql`
    mutation updateLocalLicense($id: ID!, $notes: String, $clientMutationId: String) {
  updateLocalLicense(input: {id: $id, notes: $notes, clientMutationId: $clientMutationId}) {
    localLicense {
      id
      notes
    }
    clientMutationId
  }
}
    `;
export type UpdateLocalLicenseMutationFn = ApolloReactCommon.MutationFunction<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>;
export type UpdateLocalLicenseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>, 'mutation'>;

    export const UpdateLocalLicenseComponent = (props: UpdateLocalLicenseComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables> mutation={UpdateLocalLicenseDocument} {...props} />
    );
    
export type UpdateLocalLicenseProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>
    } & TChildProps;
export function withUpdateLocalLicense<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLocalLicenseMutation,
  UpdateLocalLicenseMutationVariables,
  UpdateLocalLicenseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables, UpdateLocalLicenseProps<TChildProps, TDataName>>(UpdateLocalLicenseDocument, {
      alias: 'updateLocalLicense',
      ...operationOptions
    });
};

/**
 * __useUpdateLocalLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateLocalLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalLicenseMutation, { data, loading, error }] = useUpdateLocalLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateLocalLicenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>(UpdateLocalLicenseDocument, baseOptions);
      }
export type UpdateLocalLicenseMutationHookResult = ReturnType<typeof useUpdateLocalLicenseMutation>;
export type UpdateLocalLicenseMutationResult = ApolloReactCommon.MutationResult<UpdateLocalLicenseMutation>;
export type UpdateLocalLicenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocalLicenseMutation, UpdateLocalLicenseMutationVariables>;
export const HasLocalLicenseDocument = gql`
    query HasLocalLicense {
  serverInfo {
    hasLocalLicense
  }
}
    `;
export type HasLocalLicenseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>, 'query'>;

    export const HasLocalLicenseComponent = (props: HasLocalLicenseComponentProps) => (
      <ApolloReactComponents.Query<HasLocalLicenseQuery, HasLocalLicenseQueryVariables> query={HasLocalLicenseDocument} {...props} />
    );
    
export type HasLocalLicenseProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>
    } & TChildProps;
export function withHasLocalLicense<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HasLocalLicenseQuery,
  HasLocalLicenseQueryVariables,
  HasLocalLicenseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HasLocalLicenseQuery, HasLocalLicenseQueryVariables, HasLocalLicenseProps<TChildProps, TDataName>>(HasLocalLicenseDocument, {
      alias: 'hasLocalLicense',
      ...operationOptions
    });
};

/**
 * __useHasLocalLicenseQuery__
 *
 * To run a query within a React component, call `useHasLocalLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasLocalLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasLocalLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasLocalLicenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>) {
        return ApolloReactHooks.useQuery<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>(HasLocalLicenseDocument, baseOptions);
      }
export function useHasLocalLicenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>(HasLocalLicenseDocument, baseOptions);
        }
export type HasLocalLicenseQueryHookResult = ReturnType<typeof useHasLocalLicenseQuery>;
export type HasLocalLicenseLazyQueryHookResult = ReturnType<typeof useHasLocalLicenseLazyQuery>;
export type HasLocalLicenseQueryResult = ApolloReactCommon.QueryResult<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>;
export const LatestLocalVersionInfoDocument = gql`
    query latestLocalVersionInfo {
  serverInfo {
    latestLocalVersionInfo {
      outOfDate
      latestVersionString
    }
  }
}
    `;
export type LatestLocalVersionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>, 'query'>;

    export const LatestLocalVersionInfoComponent = (props: LatestLocalVersionInfoComponentProps) => (
      <ApolloReactComponents.Query<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables> query={LatestLocalVersionInfoDocument} {...props} />
    );
    
export type LatestLocalVersionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>
    } & TChildProps;
export function withLatestLocalVersionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LatestLocalVersionInfoQuery,
  LatestLocalVersionInfoQueryVariables,
  LatestLocalVersionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables, LatestLocalVersionInfoProps<TChildProps, TDataName>>(LatestLocalVersionInfoDocument, {
      alias: 'latestLocalVersionInfo',
      ...operationOptions
    });
};

/**
 * __useLatestLocalVersionInfoQuery__
 *
 * To run a query within a React component, call `useLatestLocalVersionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestLocalVersionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestLocalVersionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestLocalVersionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>(LatestLocalVersionInfoDocument, baseOptions);
      }
export function useLatestLocalVersionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>(LatestLocalVersionInfoDocument, baseOptions);
        }
export type LatestLocalVersionInfoQueryHookResult = ReturnType<typeof useLatestLocalVersionInfoQuery>;
export type LatestLocalVersionInfoLazyQueryHookResult = ReturnType<typeof useLatestLocalVersionInfoLazyQuery>;
export type LatestLocalVersionInfoQueryResult = ApolloReactCommon.QueryResult<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>;
export const AvailableFilesQueryDocument = gql`
    query AvailableFilesQuery($projectName: String!, $entityName: String!, $runName: String!, $filenames: [String!]!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      files(names: $filenames) {
        edges {
          node {
            id
            name
            directUrl
            md5
            sizeBytes
          }
        }
      }
    }
  }
}
    `;
export type AvailableFilesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>, 'query'> & ({ variables: AvailableFilesQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableFilesQueryComponent = (props: AvailableFilesQueryComponentProps) => (
      <ApolloReactComponents.Query<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables> query={AvailableFilesQueryDocument} {...props} />
    );
    
export type AvailableFilesQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>
    } & TChildProps;
export function withAvailableFilesQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableFilesQueryQuery,
  AvailableFilesQueryQueryVariables,
  AvailableFilesQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables, AvailableFilesQueryProps<TChildProps, TDataName>>(AvailableFilesQueryDocument, {
      alias: 'availableFilesQuery',
      ...operationOptions
    });
};

/**
 * __useAvailableFilesQueryQuery__
 *
 * To run a query within a React component, call `useAvailableFilesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableFilesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableFilesQueryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      filenames: // value for 'filenames'
 *   },
 * });
 */
export function useAvailableFilesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>(AvailableFilesQueryDocument, baseOptions);
      }
export function useAvailableFilesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>(AvailableFilesQueryDocument, baseOptions);
        }
export type AvailableFilesQueryQueryHookResult = ReturnType<typeof useAvailableFilesQueryQuery>;
export type AvailableFilesQueryLazyQueryHookResult = ReturnType<typeof useAvailableFilesQueryLazyQuery>;
export type AvailableFilesQueryQueryResult = ApolloReactCommon.QueryResult<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>;
export const ModelDocument = gql`
    query Model($name: String!, $logCursor: String, $fileCursor: String, $fileLimit: Int = 10, $fileNames: [String] = [], $entityName: String, $bucketName: String!, $upload: Boolean, $detailed: Boolean!, $includeFiles: Boolean = false) {
  project(name: $name, entityName: $entityName) {
    id
    readOnly
    name
    entityName
    description
    createdAt
    bucketCount
    access
    summaryMetrics
    run(name: $bucketName) {
      id
      name
      __typename
      ...SelectRunFragment
      ...BasicRunFragment
      ...DetailedRunFragment @include(if: $detailed)
      ...RunFilesFragment @include(if: $includeFiles)
    }
    views
  }
  viewer {
    id
    email
    photoUrl
    admin
    defaultFramework
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}
${DetailedRunFragmentFragmentDoc}
${RunFilesFragmentFragmentDoc}`;
export type ModelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ModelQuery, ModelQueryVariables>, 'query'> & ({ variables: ModelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ModelComponent = (props: ModelComponentProps) => (
      <ApolloReactComponents.Query<ModelQuery, ModelQueryVariables> query={ModelDocument} {...props} />
    );
    
export type ModelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ModelQuery, ModelQueryVariables>
    } & TChildProps;
export function withModel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModelQuery,
  ModelQueryVariables,
  ModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ModelQuery, ModelQueryVariables, ModelProps<TChildProps, TDataName>>(ModelDocument, {
      alias: 'model',
      ...operationOptions
    });
};

/**
 * __useModelQuery__
 *
 * To run a query within a React component, call `useModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelQuery({
 *   variables: {
 *      name: // value for 'name'
 *      logCursor: // value for 'logCursor'
 *      fileCursor: // value for 'fileCursor'
 *      fileLimit: // value for 'fileLimit'
 *      fileNames: // value for 'fileNames'
 *      entityName: // value for 'entityName'
 *      bucketName: // value for 'bucketName'
 *      upload: // value for 'upload'
 *      detailed: // value for 'detailed'
 *      includeFiles: // value for 'includeFiles'
 *   },
 * });
 */
export function useModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelQuery, ModelQueryVariables>) {
        return ApolloReactHooks.useQuery<ModelQuery, ModelQueryVariables>(ModelDocument, baseOptions);
      }
export function useModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelQuery, ModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModelQuery, ModelQueryVariables>(ModelDocument, baseOptions);
        }
export type ModelQueryHookResult = ReturnType<typeof useModelQuery>;
export type ModelLazyQueryHookResult = ReturnType<typeof useModelLazyQuery>;
export type ModelQueryResult = ApolloReactCommon.QueryResult<ModelQuery, ModelQueryVariables>;
export const UpsertModelDocument = gql`
    mutation upsertModel($description: String, $entityName: String, $id: String, $name: String, $framework: String, $access: String, $views: JSONString) {
  upsertModel(input: {description: $description, entityName: $entityName, id: $id, name: $name, framework: $framework, access: $access, views: $views}) {
    project {
      id
      name
      entityName
      description
      access
      views
    }
    model {
      id
      name
      entityName
      description
      access
      views
    }
    inserted
  }
}
    `;
export type UpsertModelMutationFn = ApolloReactCommon.MutationFunction<UpsertModelMutation, UpsertModelMutationVariables>;
export type UpsertModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertModelMutation, UpsertModelMutationVariables>, 'mutation'>;

    export const UpsertModelComponent = (props: UpsertModelComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertModelMutation, UpsertModelMutationVariables> mutation={UpsertModelDocument} {...props} />
    );
    
export type UpsertModelProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertModelMutation, UpsertModelMutationVariables>
    } & TChildProps;
export function withUpsertModel<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertModelMutation,
  UpsertModelMutationVariables,
  UpsertModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertModelMutation, UpsertModelMutationVariables, UpsertModelProps<TChildProps, TDataName>>(UpsertModelDocument, {
      alias: 'upsertModel',
      ...operationOptions
    });
};

/**
 * __useUpsertModelMutation__
 *
 * To run a mutation, you first call `useUpsertModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertModelMutation, { data, loading, error }] = useUpsertModelMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      framework: // value for 'framework'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *   },
 * });
 */
export function useUpsertModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertModelMutation, UpsertModelMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertModelMutation, UpsertModelMutationVariables>(UpsertModelDocument, baseOptions);
      }
export type UpsertModelMutationHookResult = ReturnType<typeof useUpsertModelMutation>;
export type UpsertModelMutationResult = ApolloReactCommon.MutationResult<UpsertModelMutation>;
export type UpsertModelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertModelMutation, UpsertModelMutationVariables>;
export const RenameProjectDocument = gql`
    mutation renameProject($entityName: String!, $oldProjectName: String!, $newProjectName: String!) {
  renameProject(input: {entityName: $entityName, oldProjectName: $oldProjectName, newProjectName: $newProjectName}) {
    project {
      id
      name
      entityName
      description
      access
      views
    }
    model {
      id
      name
      entityName
      description
      access
      views
    }
    inserted
  }
}
    `;
export type RenameProjectMutationFn = ApolloReactCommon.MutationFunction<RenameProjectMutation, RenameProjectMutationVariables>;
export type RenameProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameProjectMutation, RenameProjectMutationVariables>, 'mutation'>;

    export const RenameProjectComponent = (props: RenameProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RenameProjectMutation, RenameProjectMutationVariables> mutation={RenameProjectDocument} {...props} />
    );
    
export type RenameProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RenameProjectMutation, RenameProjectMutationVariables>
    } & TChildProps;
export function withRenameProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameProjectMutation,
  RenameProjectMutationVariables,
  RenameProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RenameProjectMutation, RenameProjectMutationVariables, RenameProjectProps<TChildProps, TDataName>>(RenameProjectDocument, {
      alias: 'renameProject',
      ...operationOptions
    });
};

/**
 * __useRenameProjectMutation__
 *
 * To run a mutation, you first call `useRenameProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameProjectMutation, { data, loading, error }] = useRenameProjectMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      oldProjectName: // value for 'oldProjectName'
 *      newProjectName: // value for 'newProjectName'
 *   },
 * });
 */
export function useRenameProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameProjectMutation, RenameProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameProjectMutation, RenameProjectMutationVariables>(RenameProjectDocument, baseOptions);
      }
export type RenameProjectMutationHookResult = ReturnType<typeof useRenameProjectMutation>;
export type RenameProjectMutationResult = ApolloReactCommon.MutationResult<RenameProjectMutation>;
export type RenameProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameProjectMutation, RenameProjectMutationVariables>;
export const DeleteModelDocument = gql`
    mutation deleteModel($id: String!) {
  deleteModel(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteModelMutationFn = ApolloReactCommon.MutationFunction<DeleteModelMutation, DeleteModelMutationVariables>;
export type DeleteModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteModelMutation, DeleteModelMutationVariables>, 'mutation'>;

    export const DeleteModelComponent = (props: DeleteModelComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteModelMutation, DeleteModelMutationVariables> mutation={DeleteModelDocument} {...props} />
    );
    
export type DeleteModelProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteModelMutation, DeleteModelMutationVariables>
    } & TChildProps;
export function withDeleteModel<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteModelMutation,
  DeleteModelMutationVariables,
  DeleteModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteModelMutation, DeleteModelMutationVariables, DeleteModelProps<TChildProps, TDataName>>(DeleteModelDocument, {
      alias: 'deleteModel',
      ...operationOptions
    });
};

/**
 * __useDeleteModelMutation__
 *
 * To run a mutation, you first call `useDeleteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelMutation, { data, loading, error }] = useDeleteModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteModelMutation, DeleteModelMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteModelMutation, DeleteModelMutationVariables>(DeleteModelDocument, baseOptions);
      }
export type DeleteModelMutationHookResult = ReturnType<typeof useDeleteModelMutation>;
export type DeleteModelMutationResult = ApolloReactCommon.MutationResult<DeleteModelMutation>;
export type DeleteModelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteModelMutation, DeleteModelMutationVariables>;
export const ModelsDocument = gql`
    query Models($cursor: String, $entityName: String, $order: String, $first: Int = 500, $anonymous: Boolean = false) {
  projects(first: $first, after: $cursor, entityName: $entityName, order: $order, anonymous: $anonymous) {
    edges {
      node {
        id
        name
        entityName
        description
        totalRuns
        totalUsers
        lastActive
        totalRunTime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    id
    email
    photoUrl
  }
}
    `;
export type ModelsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ModelsQuery, ModelsQueryVariables>, 'query'>;

    export const ModelsComponent = (props: ModelsComponentProps) => (
      <ApolloReactComponents.Query<ModelsQuery, ModelsQueryVariables> query={ModelsDocument} {...props} />
    );
    
export type ModelsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ModelsQuery, ModelsQueryVariables>
    } & TChildProps;
export function withModels<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModelsQuery,
  ModelsQueryVariables,
  ModelsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ModelsQuery, ModelsQueryVariables, ModelsProps<TChildProps, TDataName>>(ModelsDocument, {
      alias: 'models',
      ...operationOptions
    });
};

/**
 * __useModelsQuery__
 *
 * To run a query within a React component, call `useModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      entityName: // value for 'entityName'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      anonymous: // value for 'anonymous'
 *   },
 * });
 */
export function useModelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
        return ApolloReactHooks.useQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, baseOptions);
      }
export function useModelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, baseOptions);
        }
export type ModelsQueryHookResult = ReturnType<typeof useModelsQuery>;
export type ModelsLazyQueryHookResult = ReturnType<typeof useModelsLazyQuery>;
export type ModelsQueryResult = ApolloReactCommon.QueryResult<ModelsQuery, ModelsQueryVariables>;
export const AddUserToOrganizationDocument = gql`
    mutation addUserToOrganization($userName: String!, $teams: [String!], $organizationId: ID!, $clientMutationId: String, $userOrgRole: String!) {
  addUserToOrganization(input: {userName: $userName, teams: $teams, organizationId: $organizationId, clientMutationId: $clientMutationId, userOrgRole: $userOrgRole}) {
    success
    clientMutationId
  }
}
    `;
export type AddUserToOrganizationMutationFn = ApolloReactCommon.MutationFunction<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;
export type AddUserToOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>, 'mutation'>;

    export const AddUserToOrganizationComponent = (props: AddUserToOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables> mutation={AddUserToOrganizationDocument} {...props} />
    );
    
export type AddUserToOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>
    } & TChildProps;
export function withAddUserToOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddUserToOrganizationMutation,
  AddUserToOrganizationMutationVariables,
  AddUserToOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables, AddUserToOrganizationProps<TChildProps, TDataName>>(AddUserToOrganizationDocument, {
      alias: 'addUserToOrganization',
      ...operationOptions
    });
};

/**
 * __useAddUserToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddUserToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToOrganizationMutation, { data, loading, error }] = useAddUserToOrganizationMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      teams: // value for 'teams'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *      userOrgRole: // value for 'userOrgRole'
 *   },
 * });
 */
export function useAddUserToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>(AddUserToOrganizationDocument, baseOptions);
      }
export type AddUserToOrganizationMutationHookResult = ReturnType<typeof useAddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationResult = ApolloReactCommon.MutationResult<AddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;
export const RemoveUserFromOrganizationDocument = gql`
    mutation removeUserFromOrganization($userName: String!, $organizationId: ID!, $clientMutationId: String) {
  removeUserFromOrganization(input: {userName: $userName, organizationId: $organizationId, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type RemoveUserFromOrganizationMutationFn = ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export type RemoveUserFromOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>, 'mutation'>;

    export const RemoveUserFromOrganizationComponent = (props: RemoveUserFromOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables> mutation={RemoveUserFromOrganizationDocument} {...props} />
    );
    
export type RemoveUserFromOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>
    } & TChildProps;
export function withRemoveUserFromOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserFromOrganizationMutation,
  RemoveUserFromOrganizationMutationVariables,
  RemoveUserFromOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables, RemoveUserFromOrganizationProps<TChildProps, TDataName>>(RemoveUserFromOrganizationDocument, {
      alias: 'removeUserFromOrganization',
      ...operationOptions
    });
};

/**
 * __useRemoveUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationMutation, { data, loading, error }] = useRemoveUserFromOrganizationMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>(RemoveUserFromOrganizationDocument, baseOptions);
      }
export type RemoveUserFromOrganizationMutationHookResult = ReturnType<typeof useRemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationResult = ApolloReactCommon.MutationResult<RemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($organizationID: ID!, $flags: JSONString!) {
  updateOrganization(input: {organizationID: $organizationID, flags: $flags}) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export type UpdateOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>, 'mutation'>;

    export const UpdateOrganizationComponent = (props: UpdateOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> mutation={UpdateOrganizationDocument} {...props} />
    );
    
export type UpdateOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
    } & TChildProps;
export function withUpdateOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
  UpdateOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationMutation, UpdateOrganizationMutationVariables, UpdateOrganizationProps<TChildProps, TDataName>>(UpdateOrganizationDocument, {
      alias: 'updateOrganization',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationUserDocument = gql`
    mutation updateOrganizationUser($userName: String!, $organizationId: ID!, $userOrgRole: String!, $clientMutationId: String) {
  updateOrganizationUser(input: {userName: $userName, organizationId: $organizationId, userOrgRole: $userOrgRole, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationUserMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>;
export type UpdateOrganizationUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>, 'mutation'>;

    export const UpdateOrganizationUserComponent = (props: UpdateOrganizationUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables> mutation={UpdateOrganizationUserDocument} {...props} />
    );
    
export type UpdateOrganizationUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables,
  UpdateOrganizationUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables, UpdateOrganizationUserProps<TChildProps, TDataName>>(UpdateOrganizationUserDocument, {
      alias: 'updateOrganizationUser',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserMutation, { data, loading, error }] = useUpdateOrganizationUserMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      organizationId: // value for 'organizationId'
 *      userOrgRole: // value for 'userOrgRole'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>(UpdateOrganizationUserDocument, baseOptions);
      }
export type UpdateOrganizationUserMutationHookResult = ReturnType<typeof useUpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>;
export const UpdateOrganizationSubscriptionDocument = gql`
    mutation updateOrganizationSubscription($organizationSubscriptionID: ID!, $createStripeSubscription: Boolean!, $planID: ID, $seats: Int, $privileges: JSONString, $type: OrganizationSubscriptionType, $status: OrganizationSubscriptionStatus, $stripeSubscriptionId: String, $expiresAt: DateTime) {
  updateOrganizationSubscription(input: {organizationSubscriptionID: $organizationSubscriptionID, planID: $planID, seats: $seats, privileges: $privileges, type: $type, status: $status, stripeSubscriptionId: $stripeSubscriptionId, expiresAt: $expiresAt, createStripeSubscription: $createStripeSubscription}) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>;
export type UpdateOrganizationSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>, 'mutation'>;

    export const UpdateOrganizationSubscriptionComponent = (props: UpdateOrganizationSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables> mutation={UpdateOrganizationSubscriptionDocument} {...props} />
    );
    
export type UpdateOrganizationSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationSubscriptionMutation,
  UpdateOrganizationSubscriptionMutationVariables,
  UpdateOrganizationSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables, UpdateOrganizationSubscriptionProps<TChildProps, TDataName>>(UpdateOrganizationSubscriptionDocument, {
      alias: 'updateOrganizationSubscription',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSubscriptionMutation, { data, loading, error }] = useUpdateOrganizationSubscriptionMutation({
 *   variables: {
 *      organizationSubscriptionID: // value for 'organizationSubscriptionID'
 *      createStripeSubscription: // value for 'createStripeSubscription'
 *      planID: // value for 'planID'
 *      seats: // value for 'seats'
 *      privileges: // value for 'privileges'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useUpdateOrganizationSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>(UpdateOrganizationSubscriptionDocument, baseOptions);
      }
export type UpdateOrganizationSubscriptionMutationHookResult = ReturnType<typeof useUpdateOrganizationSubscriptionMutation>;
export type UpdateOrganizationSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationSubscriptionMutation>;
export type UpdateOrganizationSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>;
export const AllOrganizationsDocument = gql`
    query allOrganizations($first: Int, $cursor: String, $query: String) {
  organizations(first: $first, after: $cursor, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        orgType
        flags
        billingUser {
          id
          username
          email
        }
        teams {
          id
          name
        }
        stripeBillingInfo {
          stripeSubscriptionId
          status
          currentPeriodEnd
          cancelAtPeriodEnd
          paymentMethod {
            id
            cardType
            endingIn
          }
        }
        members {
          id
          admin
          name
          username
          photoUrl
          teams {
            edges {
              node {
                id
                name
              }
            }
          }
          role
        }
        subscriptions {
          plan {
            id
            name
          }
          seats
          id
          subscriptionType
          status
          expiresAt
          privileges
        }
      }
    }
  }
}
    `;
export type AllOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>, 'query'>;

    export const AllOrganizationsComponent = (props: AllOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<AllOrganizationsQuery, AllOrganizationsQueryVariables> query={AllOrganizationsDocument} {...props} />
    );
    
export type AllOrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllOrganizationsQuery, AllOrganizationsQueryVariables>
    } & TChildProps;
export function withAllOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllOrganizationsQuery,
  AllOrganizationsQueryVariables,
  AllOrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllOrganizationsQuery, AllOrganizationsQueryVariables, AllOrganizationsProps<TChildProps, TDataName>>(AllOrganizationsDocument, {
      alias: 'allOrganizations',
      ...operationOptions
    });
};

/**
 * __useAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, baseOptions);
      }
export function useAllOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, baseOptions);
        }
export type AllOrganizationsQueryHookResult = ReturnType<typeof useAllOrganizationsQuery>;
export type AllOrganizationsLazyQueryHookResult = ReturnType<typeof useAllOrganizationsLazyQuery>;
export type AllOrganizationsQueryResult = ApolloReactCommon.QueryResult<AllOrganizationsQuery, AllOrganizationsQueryVariables>;
export const OrganizationSubscriptionsDocument = gql`
    query OrganizationSubscriptions {
  viewer {
    id
    organizations {
      id
      name
      flags
      subscriptions {
        subscriptionType
        status
        expiresAt
        privileges
        plan {
          id
          name
        }
      }
      teams {
        id
        name
        computeHours
        oldComputeHours: computeHours(minDaysOld: 14)
      }
      members {
        id
      }
      pendingInvites {
        id
      }
    }
  }
}
    `;
export type OrganizationSubscriptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>, 'query'>;

    export const OrganizationSubscriptionsComponent = (props: OrganizationSubscriptionsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables> query={OrganizationSubscriptionsDocument} {...props} />
    );
    
export type OrganizationSubscriptionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionsQuery,
  OrganizationSubscriptionsQueryVariables,
  OrganizationSubscriptionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables, OrganizationSubscriptionsProps<TChildProps, TDataName>>(OrganizationSubscriptionsDocument, {
      alias: 'organizationSubscriptions',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionsQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>(OrganizationSubscriptionsDocument, baseOptions);
      }
export function useOrganizationSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>(OrganizationSubscriptionsDocument, baseOptions);
        }
export type OrganizationSubscriptionsQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsQuery>;
export type OrganizationSubscriptionsLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsLazyQuery>;
export type OrganizationSubscriptionsQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>;
export const ProjectPickerQueryDocument = gql`
    query ProjectPickerQuery($entityName: String!) {
  entity(name: $entityName) {
    id
    projects(first: 2000) {
      edges {
        node {
          id
          name
          entityName
          createdAt
        }
      }
    }
  }
  viewer {
    id
    projects(first: 500) {
      edges {
        node {
          id
          name
          entityName
          createdAt
        }
      }
    }
    teams(first: 100) {
      edges {
        node {
          id
          projects(first: 500) {
            edges {
              node {
                id
                name
                entityName
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ProjectPickerQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>, 'query'> & ({ variables: ProjectPickerQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectPickerQueryComponent = (props: ProjectPickerQueryComponentProps) => (
      <ApolloReactComponents.Query<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables> query={ProjectPickerQueryDocument} {...props} />
    );
    
export type ProjectPickerQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>
    } & TChildProps;
export function withProjectPickerQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectPickerQueryQuery,
  ProjectPickerQueryQueryVariables,
  ProjectPickerQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables, ProjectPickerQueryProps<TChildProps, TDataName>>(ProjectPickerQueryDocument, {
      alias: 'projectPickerQuery',
      ...operationOptions
    });
};

/**
 * __useProjectPickerQueryQuery__
 *
 * To run a query within a React component, call `useProjectPickerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectPickerQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>(ProjectPickerQueryDocument, baseOptions);
      }
export function useProjectPickerQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>(ProjectPickerQueryDocument, baseOptions);
        }
export type ProjectPickerQueryQueryHookResult = ReturnType<typeof useProjectPickerQueryQuery>;
export type ProjectPickerQueryLazyQueryHookResult = ReturnType<typeof useProjectPickerQueryLazyQuery>;
export type ProjectPickerQueryQueryResult = ApolloReactCommon.QueryResult<ProjectPickerQueryQuery, ProjectPickerQueryQueryVariables>;
export const AllProjectNamesDocument = gql`
    query AllProjectNames($entityName: String!, $first: Int = 500) {
  projects(first: $first, entityName: $entityName, order: "name") {
    edges {
      node {
        name
      }
    }
  }
}
    `;
export type AllProjectNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>, 'query'> & ({ variables: AllProjectNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllProjectNamesComponent = (props: AllProjectNamesComponentProps) => (
      <ApolloReactComponents.Query<AllProjectNamesQuery, AllProjectNamesQueryVariables> query={AllProjectNamesDocument} {...props} />
    );
    
export type AllProjectNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllProjectNamesQuery, AllProjectNamesQueryVariables>
    } & TChildProps;
export function withAllProjectNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllProjectNamesQuery,
  AllProjectNamesQueryVariables,
  AllProjectNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllProjectNamesQuery, AllProjectNamesQueryVariables, AllProjectNamesProps<TChildProps, TDataName>>(AllProjectNamesDocument, {
      alias: 'allProjectNames',
      ...operationOptions
    });
};

/**
 * __useAllProjectNamesQuery__
 *
 * To run a query within a React component, call `useAllProjectNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectNamesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllProjectNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectNamesQuery, AllProjectNamesQueryVariables>(AllProjectNamesDocument, baseOptions);
      }
export function useAllProjectNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectNamesQuery, AllProjectNamesQueryVariables>(AllProjectNamesDocument, baseOptions);
        }
export type AllProjectNamesQueryHookResult = ReturnType<typeof useAllProjectNamesQuery>;
export type AllProjectNamesLazyQueryHookResult = ReturnType<typeof useAllProjectNamesLazyQuery>;
export type AllProjectNamesQueryResult = ApolloReactCommon.QueryResult<AllProjectNamesQuery, AllProjectNamesQueryVariables>;
export const MoveProjectsDocument = gql`
    mutation moveProjects($projectNames: [String!], $sourceEntityName: String!, $destinationEntityName: String!, $ownerUsername: String!, $notes: String, $clientMutationId: String) {
  moveProjects(input: {projectNames: $projectNames, sourceEntityName: $sourceEntityName, destinationEntityName: $destinationEntityName, ownerUsername: $ownerUsername, notes: $notes, clientMutationId: $clientMutationId}) {
    tasks {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type MoveProjectsMutationFn = ApolloReactCommon.MutationFunction<MoveProjectsMutation, MoveProjectsMutationVariables>;
export type MoveProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveProjectsMutation, MoveProjectsMutationVariables>, 'mutation'>;

    export const MoveProjectsComponent = (props: MoveProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<MoveProjectsMutation, MoveProjectsMutationVariables> mutation={MoveProjectsDocument} {...props} />
    );
    
export type MoveProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveProjectsMutation, MoveProjectsMutationVariables>
    } & TChildProps;
export function withMoveProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveProjectsMutation,
  MoveProjectsMutationVariables,
  MoveProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveProjectsMutation, MoveProjectsMutationVariables, MoveProjectsProps<TChildProps, TDataName>>(MoveProjectsDocument, {
      alias: 'moveProjects',
      ...operationOptions
    });
};

/**
 * __useMoveProjectsMutation__
 *
 * To run a mutation, you first call `useMoveProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectsMutation, { data, loading, error }] = useMoveProjectsMutation({
 *   variables: {
 *      projectNames: // value for 'projectNames'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      ownerUsername: // value for 'ownerUsername'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useMoveProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveProjectsMutation, MoveProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveProjectsMutation, MoveProjectsMutationVariables>(MoveProjectsDocument, baseOptions);
      }
export type MoveProjectsMutationHookResult = ReturnType<typeof useMoveProjectsMutation>;
export type MoveProjectsMutationResult = ApolloReactCommon.MutationResult<MoveProjectsMutation>;
export type MoveProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveProjectsMutation, MoveProjectsMutationVariables>;
export const CloneProjectsDocument = gql`
    mutation cloneProjects($projectNames: [String!], $sourceEntityName: String!, $destinationEntityName: String!, $ownerUsername: String!, $notes: String, $clientMutationId: String) {
  cloneProjects(input: {projectNames: $projectNames, sourceEntityName: $sourceEntityName, destinationEntityName: $destinationEntityName, ownerUsername: $ownerUsername, notes: $notes, clientMutationId: $clientMutationId}) {
    tasks {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type CloneProjectsMutationFn = ApolloReactCommon.MutationFunction<CloneProjectsMutation, CloneProjectsMutationVariables>;
export type CloneProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CloneProjectsMutation, CloneProjectsMutationVariables>, 'mutation'>;

    export const CloneProjectsComponent = (props: CloneProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<CloneProjectsMutation, CloneProjectsMutationVariables> mutation={CloneProjectsDocument} {...props} />
    );
    
export type CloneProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CloneProjectsMutation, CloneProjectsMutationVariables>
    } & TChildProps;
export function withCloneProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CloneProjectsMutation,
  CloneProjectsMutationVariables,
  CloneProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CloneProjectsMutation, CloneProjectsMutationVariables, CloneProjectsProps<TChildProps, TDataName>>(CloneProjectsDocument, {
      alias: 'cloneProjects',
      ...operationOptions
    });
};

/**
 * __useCloneProjectsMutation__
 *
 * To run a mutation, you first call `useCloneProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneProjectsMutation, { data, loading, error }] = useCloneProjectsMutation({
 *   variables: {
 *      projectNames: // value for 'projectNames'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      ownerUsername: // value for 'ownerUsername'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCloneProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloneProjectsMutation, CloneProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<CloneProjectsMutation, CloneProjectsMutationVariables>(CloneProjectsDocument, baseOptions);
      }
export type CloneProjectsMutationHookResult = ReturnType<typeof useCloneProjectsMutation>;
export type CloneProjectsMutationResult = ApolloReactCommon.MutationResult<CloneProjectsMutation>;
export type CloneProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<CloneProjectsMutation, CloneProjectsMutationVariables>;
export const PublishedWorkProjectDocument = gql`
    query PublishedWorkProject($name: String!, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    access
    name
    description
    readOnly
    entityName
    views
    entity {
      id
      photoUrl
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
    reports: allViews(viewType: "runs") {
      edges {
        node {
          ...ViewFragmentMetadata
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type PublishedWorkProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>, 'query'> & ({ variables: PublishedWorkProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PublishedWorkProjectComponent = (props: PublishedWorkProjectComponentProps) => (
      <ApolloReactComponents.Query<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables> query={PublishedWorkProjectDocument} {...props} />
    );
    
export type PublishedWorkProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>
    } & TChildProps;
export function withPublishedWorkProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PublishedWorkProjectQuery,
  PublishedWorkProjectQueryVariables,
  PublishedWorkProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables, PublishedWorkProjectProps<TChildProps, TDataName>>(PublishedWorkProjectDocument, {
      alias: 'publishedWorkProject',
      ...operationOptions
    });
};

/**
 * __usePublishedWorkProjectQuery__
 *
 * To run a query within a React component, call `usePublishedWorkProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedWorkProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedWorkProjectQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function usePublishedWorkProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>(PublishedWorkProjectDocument, baseOptions);
      }
export function usePublishedWorkProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>(PublishedWorkProjectDocument, baseOptions);
        }
export type PublishedWorkProjectQueryHookResult = ReturnType<typeof usePublishedWorkProjectQuery>;
export type PublishedWorkProjectLazyQueryHookResult = ReturnType<typeof usePublishedWorkProjectLazyQuery>;
export type PublishedWorkProjectQueryResult = ApolloReactCommon.QueryResult<PublishedWorkProjectQuery, PublishedWorkProjectQueryVariables>;
export const RunLogLinesDocument = gql`
    query RunLogLines($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      logLines(last: 10000) {
        edges {
          node {
            id
            line
            level
          }
        }
      }
    }
  }
}
    `;
export type RunLogLinesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunLogLinesQuery, RunLogLinesQueryVariables>, 'query'> & ({ variables: RunLogLinesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunLogLinesComponent = (props: RunLogLinesComponentProps) => (
      <ApolloReactComponents.Query<RunLogLinesQuery, RunLogLinesQueryVariables> query={RunLogLinesDocument} {...props} />
    );
    
export type RunLogLinesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunLogLinesQuery, RunLogLinesQueryVariables>
    } & TChildProps;
export function withRunLogLines<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunLogLinesQuery,
  RunLogLinesQueryVariables,
  RunLogLinesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunLogLinesQuery, RunLogLinesQueryVariables, RunLogLinesProps<TChildProps, TDataName>>(RunLogLinesDocument, {
      alias: 'runLogLines',
      ...operationOptions
    });
};

/**
 * __useRunLogLinesQuery__
 *
 * To run a query within a React component, call `useRunLogLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunLogLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunLogLinesQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunLogLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunLogLinesQuery, RunLogLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<RunLogLinesQuery, RunLogLinesQueryVariables>(RunLogLinesDocument, baseOptions);
      }
export function useRunLogLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunLogLinesQuery, RunLogLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunLogLinesQuery, RunLogLinesQueryVariables>(RunLogLinesDocument, baseOptions);
        }
export type RunLogLinesQueryHookResult = ReturnType<typeof useRunLogLinesQuery>;
export type RunLogLinesLazyQueryHookResult = ReturnType<typeof useRunLogLinesLazyQuery>;
export type RunLogLinesQueryResult = ApolloReactCommon.QueryResult<RunLogLinesQuery, RunLogLinesQueryVariables>;
export const RunDocument = gql`
    query Run($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    readOnly
    createdAt
    access
    views
    entityName
    tags {
      name
      colorIndex
    }
    isBenchmark
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    run(name: $runName) {
      id
      name
      displayName
      notes
      config
      summaryMetrics
      historyKeys
      github
      fileCount
      createdAt
      heartbeatAt
      commit
      jobType
      group
      tags: tagColors {
        name
        colorIndex
      }
      host
      state
      stopped
      sweep {
        name
        displayName
      }
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
      diffPatchFile: files(first: 1, names: ["diff.patch"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
          }
        }
      }
      metadataFile: files(first: 1, names: ["wandb-metadata.json"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
            directUrl
          }
        }
      }
      inputArtifacts {
        totalCount
      }
      outputArtifacts {
        totalCount
      }
      outputLogFile: files(first: 1, names: ["output.log"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
            directUrl
            url(upload: false)
          }
        }
      }
      benchmarkRun {
        id
        benchmark {
          id
          name
          entityName
          readOnly
          views
        }
        details
        results
        gitHubSubmissionPR
        run {
          id
          name
          github
          user {
            id
            username
          }
          summaryMetrics
        }
        originalProject {
          id
          name
          entityName
        }
        originalRun {
          id
          name
        }
        isCodeHidden
        state
      }
      user {
        id
        username
        photoUrl
      }
      servicesAvailable {
        tensorboard
      }
    }
    entity {
      id
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
      user {
        id
        username
        accountType
        apiKey
      }
      claimingEntity {
        id
        name
      }
      codeSavingEnabled
    }
  }
}
    `;
export type RunComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunQuery, RunQueryVariables>, 'query'> & ({ variables: RunQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunComponent = (props: RunComponentProps) => (
      <ApolloReactComponents.Query<RunQuery, RunQueryVariables> query={RunDocument} {...props} />
    );
    
export type RunProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunQuery, RunQueryVariables>
    } & TChildProps;
export function withRun<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunQuery,
  RunQueryVariables,
  RunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunQuery, RunQueryVariables, RunProps<TChildProps, TDataName>>(RunDocument, {
      alias: 'run',
      ...operationOptions
    });
};

/**
 * __useRunQuery__
 *
 * To run a query within a React component, call `useRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunQuery, RunQueryVariables>) {
        return ApolloReactHooks.useQuery<RunQuery, RunQueryVariables>(RunDocument, baseOptions);
      }
export function useRunLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunQuery, RunQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunQuery, RunQueryVariables>(RunDocument, baseOptions);
        }
export type RunQueryHookResult = ReturnType<typeof useRunQuery>;
export type RunLazyQueryHookResult = ReturnType<typeof useRunLazyQuery>;
export type RunQueryResult = ApolloReactCommon.QueryResult<RunQuery, RunQueryVariables>;
export const CreateRunQueueDocument = gql`
    mutation createRunQueue($entityName: String!, $projectName: String!, $queueName: String!, $access: RunQueueAccessType!) {
  createRunQueue(input: {entityName: $entityName, projectName: $projectName, queueName: $queueName, access: $access}) {
    success
    queueID
  }
}
    `;
export type CreateRunQueueMutationFn = ApolloReactCommon.MutationFunction<CreateRunQueueMutation, CreateRunQueueMutationVariables>;
export type CreateRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>, 'mutation'>;

    export const CreateRunQueueComponent = (props: CreateRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRunQueueMutation, CreateRunQueueMutationVariables> mutation={CreateRunQueueDocument} {...props} />
    );
    
export type CreateRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateRunQueueMutation, CreateRunQueueMutationVariables>
    } & TChildProps;
export function withCreateRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRunQueueMutation,
  CreateRunQueueMutationVariables,
  CreateRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRunQueueMutation, CreateRunQueueMutationVariables, CreateRunQueueProps<TChildProps, TDataName>>(CreateRunQueueDocument, {
      alias: 'createRunQueue',
      ...operationOptions
    });
};

/**
 * __useCreateRunQueueMutation__
 *
 * To run a mutation, you first call `useCreateRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunQueueMutation, { data, loading, error }] = useCreateRunQueueMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      queueName: // value for 'queueName'
 *      access: // value for 'access'
 *   },
 * });
 */
export function useCreateRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRunQueueMutation, CreateRunQueueMutationVariables>(CreateRunQueueDocument, baseOptions);
      }
export type CreateRunQueueMutationHookResult = ReturnType<typeof useCreateRunQueueMutation>;
export type CreateRunQueueMutationResult = ApolloReactCommon.MutationResult<CreateRunQueueMutation>;
export type CreateRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>;
export const DeleteRunQueuesDocument = gql`
    mutation deleteRunQueues($queueIDs: [ID!]!) {
  deleteRunQueues(input: {queueIDs: $queueIDs}) {
    success
  }
}
    `;
export type DeleteRunQueuesMutationFn = ApolloReactCommon.MutationFunction<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>;
export type DeleteRunQueuesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>, 'mutation'>;

    export const DeleteRunQueuesComponent = (props: DeleteRunQueuesComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables> mutation={DeleteRunQueuesDocument} {...props} />
    );
    
export type DeleteRunQueuesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>
    } & TChildProps;
export function withDeleteRunQueues<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunQueuesMutation,
  DeleteRunQueuesMutationVariables,
  DeleteRunQueuesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables, DeleteRunQueuesProps<TChildProps, TDataName>>(DeleteRunQueuesDocument, {
      alias: 'deleteRunQueues',
      ...operationOptions
    });
};

/**
 * __useDeleteRunQueuesMutation__
 *
 * To run a mutation, you first call `useDeleteRunQueuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunQueuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunQueuesMutation, { data, loading, error }] = useDeleteRunQueuesMutation({
 *   variables: {
 *      queueIDs: // value for 'queueIDs'
 *   },
 * });
 */
export function useDeleteRunQueuesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>(DeleteRunQueuesDocument, baseOptions);
      }
export type DeleteRunQueuesMutationHookResult = ReturnType<typeof useDeleteRunQueuesMutation>;
export type DeleteRunQueuesMutationResult = ApolloReactCommon.MutationResult<DeleteRunQueuesMutation>;
export type DeleteRunQueuesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>;
export const PushToRunQueueDocument = gql`
    mutation pushToRunQueue($queueID: ID!, $runSpec: JSONString!) {
  pushToRunQueue(input: {queueID: $queueID, runSpec: $runSpec}) {
    runQueueItemId
  }
}
    `;
export type PushToRunQueueMutationFn = ApolloReactCommon.MutationFunction<PushToRunQueueMutation, PushToRunQueueMutationVariables>;
export type PushToRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>, 'mutation'>;

    export const PushToRunQueueComponent = (props: PushToRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<PushToRunQueueMutation, PushToRunQueueMutationVariables> mutation={PushToRunQueueDocument} {...props} />
    );
    
export type PushToRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PushToRunQueueMutation, PushToRunQueueMutationVariables>
    } & TChildProps;
export function withPushToRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PushToRunQueueMutation,
  PushToRunQueueMutationVariables,
  PushToRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PushToRunQueueMutation, PushToRunQueueMutationVariables, PushToRunQueueProps<TChildProps, TDataName>>(PushToRunQueueDocument, {
      alias: 'pushToRunQueue',
      ...operationOptions
    });
};

/**
 * __usePushToRunQueueMutation__
 *
 * To run a mutation, you first call `usePushToRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushToRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushToRunQueueMutation, { data, loading, error }] = usePushToRunQueueMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *      runSpec: // value for 'runSpec'
 *   },
 * });
 */
export function usePushToRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<PushToRunQueueMutation, PushToRunQueueMutationVariables>(PushToRunQueueDocument, baseOptions);
      }
export type PushToRunQueueMutationHookResult = ReturnType<typeof usePushToRunQueueMutation>;
export type PushToRunQueueMutationResult = ApolloReactCommon.MutationResult<PushToRunQueueMutation>;
export type PushToRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>;
export const DeleteFromRunQueueDocument = gql`
    mutation deleteFromRunQueue($queueID: ID!, $runQueueItemId: ID!) {
  deleteFromRunQueue(input: {queueID: $queueID, runQueueItemId: $runQueueItemId}) {
    success
  }
}
    `;
export type DeleteFromRunQueueMutationFn = ApolloReactCommon.MutationFunction<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>;
export type DeleteFromRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>, 'mutation'>;

    export const DeleteFromRunQueueComponent = (props: DeleteFromRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables> mutation={DeleteFromRunQueueDocument} {...props} />
    );
    
export type DeleteFromRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>
    } & TChildProps;
export function withDeleteFromRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFromRunQueueMutation,
  DeleteFromRunQueueMutationVariables,
  DeleteFromRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables, DeleteFromRunQueueProps<TChildProps, TDataName>>(DeleteFromRunQueueDocument, {
      alias: 'deleteFromRunQueue',
      ...operationOptions
    });
};

/**
 * __useDeleteFromRunQueueMutation__
 *
 * To run a mutation, you first call `useDeleteFromRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromRunQueueMutation, { data, loading, error }] = useDeleteFromRunQueueMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *      runQueueItemId: // value for 'runQueueItemId'
 *   },
 * });
 */
export function useDeleteFromRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>(DeleteFromRunQueueDocument, baseOptions);
      }
export type DeleteFromRunQueueMutationHookResult = ReturnType<typeof useDeleteFromRunQueueMutation>;
export type DeleteFromRunQueueMutationResult = ApolloReactCommon.MutationResult<DeleteFromRunQueueMutation>;
export type DeleteFromRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>;
export const FetchRunQueuesFromProjectDocument = gql`
    query fetchRunQueuesFromProject($entityName: String!, $projectName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runQueues {
      id
      name
    }
  }
}
    `;
export type FetchRunQueuesFromProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>, 'query'> & ({ variables: FetchRunQueuesFromProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRunQueuesFromProjectComponent = (props: FetchRunQueuesFromProjectComponentProps) => (
      <ApolloReactComponents.Query<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables> query={FetchRunQueuesFromProjectDocument} {...props} />
    );
    
export type FetchRunQueuesFromProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>
    } & TChildProps;
export function withFetchRunQueuesFromProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRunQueuesFromProjectQuery,
  FetchRunQueuesFromProjectQueryVariables,
  FetchRunQueuesFromProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables, FetchRunQueuesFromProjectProps<TChildProps, TDataName>>(FetchRunQueuesFromProjectDocument, {
      alias: 'fetchRunQueuesFromProject',
      ...operationOptions
    });
};

/**
 * __useFetchRunQueuesFromProjectQuery__
 *
 * To run a query within a React component, call `useFetchRunQueuesFromProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunQueuesFromProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunQueuesFromProjectQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useFetchRunQueuesFromProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>(FetchRunQueuesFromProjectDocument, baseOptions);
      }
export function useFetchRunQueuesFromProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>(FetchRunQueuesFromProjectDocument, baseOptions);
        }
export type FetchRunQueuesFromProjectQueryHookResult = ReturnType<typeof useFetchRunQueuesFromProjectQuery>;
export type FetchRunQueuesFromProjectLazyQueryHookResult = ReturnType<typeof useFetchRunQueuesFromProjectLazyQuery>;
export type FetchRunQueuesFromProjectQueryResult = ApolloReactCommon.QueryResult<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>;
export const RunsStateDocument = gql`
    query RunsState($projectName: String!, $entityName: String, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters) {
      edges {
        node {
          id
          name
          state
        }
      }
    }
  }
}
    `;
export type RunsStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateQuery, RunsStateQueryVariables>, 'query'> & ({ variables: RunsStateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateComponent = (props: RunsStateComponentProps) => (
      <ApolloReactComponents.Query<RunsStateQuery, RunsStateQueryVariables> query={RunsStateDocument} {...props} />
    );
    
export type RunsStateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateQuery, RunsStateQueryVariables>
    } & TChildProps;
export function withRunsState<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateQuery,
  RunsStateQueryVariables,
  RunsStateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateQuery, RunsStateQueryVariables, RunsStateProps<TChildProps, TDataName>>(RunsStateDocument, {
      alias: 'runsState',
      ...operationOptions
    });
};

/**
 * __useRunsStateQuery__
 *
 * To run a query within a React component, call `useRunsStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRunsStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateQuery, RunsStateQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateQuery, RunsStateQueryVariables>(RunsStateDocument, baseOptions);
      }
export function useRunsStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateQuery, RunsStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateQuery, RunsStateQueryVariables>(RunsStateDocument, baseOptions);
        }
export type RunsStateQueryHookResult = ReturnType<typeof useRunsStateQuery>;
export type RunsStateLazyQueryHookResult = ReturnType<typeof useRunsStateLazyQuery>;
export type RunsStateQueryResult = ApolloReactCommon.QueryResult<RunsStateQuery, RunsStateQueryVariables>;
export const RunSystemMetricsDocument = gql`
    query RunSystemMetrics($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      events(samples: 1000)
    }
  }
}
    `;
export type RunSystemMetricsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>, 'query'> & ({ variables: RunSystemMetricsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSystemMetricsComponent = (props: RunSystemMetricsComponentProps) => (
      <ApolloReactComponents.Query<RunSystemMetricsQuery, RunSystemMetricsQueryVariables> query={RunSystemMetricsDocument} {...props} />
    );
    
export type RunSystemMetricsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>
    } & TChildProps;
export function withRunSystemMetrics<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSystemMetricsQuery,
  RunSystemMetricsQueryVariables,
  RunSystemMetricsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSystemMetricsQuery, RunSystemMetricsQueryVariables, RunSystemMetricsProps<TChildProps, TDataName>>(RunSystemMetricsDocument, {
      alias: 'runSystemMetrics',
      ...operationOptions
    });
};

/**
 * __useRunSystemMetricsQuery__
 *
 * To run a query within a React component, call `useRunSystemMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSystemMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSystemMetricsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunSystemMetricsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>(RunSystemMetricsDocument, baseOptions);
      }
export function useRunSystemMetricsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>(RunSystemMetricsDocument, baseOptions);
        }
export type RunSystemMetricsQueryHookResult = ReturnType<typeof useRunSystemMetricsQuery>;
export type RunSystemMetricsLazyQueryHookResult = ReturnType<typeof useRunSystemMetricsLazyQuery>;
export type RunSystemMetricsQueryResult = ApolloReactCommon.QueryResult<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>;
export const RunsModelDocument = gql`
    query RunsModel($cursor: String, $name: String!, $entityName: String, $jobKey: String, $order: String, $filters: JSONString, $limit: Int = 1000, $bucketIds: [String], $requestSubscribe: Boolean!, $selectEnable: Boolean = true, $basicEnable: Boolean = true, $history: Boolean = false, $historyKeyInfo: Boolean = false, $historySamples: Int = 750, $sampledHistory: Boolean = false, $fields: [String], $historyFields: [String], $sampledHistorySpecs: [JSONString!]!, $groupKeys: [String], $groupLevel: Int) {
  project(name: $name, entityName: $entityName) {
    id
    name
    createdAt
    entityName
    description
    summaryMetrics
    views
    requestSubscribe @include(if: $requestSubscribe)
    runCount(filters: $filters)
    runs(first: $limit, after: $cursor, jobKey: $jobKey, order: $order, names: $bucketIds, filters: $filters, fields: $fields, historyFields: $historyFields, groupKeys: $groupKeys, groupLevel: $groupLevel) {
      totalCount
      edges {
        node {
          id
          name
          displayName
          __typename
          ...SelectRunFragment @include(if: $selectEnable)
          ...BasicRunFragment @include(if: $basicEnable)
          ...HistoryRunFragment @include(if: $history)
          ...SampledHistoryRunFragment @include(if: $sampledHistory)
          historyKeys @include(if: $historyKeyInfo)
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
  viewer {
    id
    email
    photoUrl
    admin
  }
  serverInfo {
    pollingOK
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}
${HistoryRunFragmentFragmentDoc}
${SampledHistoryRunFragmentFragmentDoc}`;
export type RunsModelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsModelQuery, RunsModelQueryVariables>, 'query'> & ({ variables: RunsModelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsModelComponent = (props: RunsModelComponentProps) => (
      <ApolloReactComponents.Query<RunsModelQuery, RunsModelQueryVariables> query={RunsModelDocument} {...props} />
    );
    
export type RunsModelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsModelQuery, RunsModelQueryVariables>
    } & TChildProps;
export function withRunsModel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsModelQuery,
  RunsModelQueryVariables,
  RunsModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsModelQuery, RunsModelQueryVariables, RunsModelProps<TChildProps, TDataName>>(RunsModelDocument, {
      alias: 'runsModel',
      ...operationOptions
    });
};

/**
 * __useRunsModelQuery__
 *
 * To run a query within a React component, call `useRunsModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsModelQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      jobKey: // value for 'jobKey'
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      bucketIds: // value for 'bucketIds'
 *      requestSubscribe: // value for 'requestSubscribe'
 *      selectEnable: // value for 'selectEnable'
 *      basicEnable: // value for 'basicEnable'
 *      history: // value for 'history'
 *      historyKeyInfo: // value for 'historyKeyInfo'
 *      historySamples: // value for 'historySamples'
 *      sampledHistory: // value for 'sampledHistory'
 *      fields: // value for 'fields'
 *      historyFields: // value for 'historyFields'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *   },
 * });
 */
export function useRunsModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsModelQuery, RunsModelQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsModelQuery, RunsModelQueryVariables>(RunsModelDocument, baseOptions);
      }
export function useRunsModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsModelQuery, RunsModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsModelQuery, RunsModelQueryVariables>(RunsModelDocument, baseOptions);
        }
export type RunsModelQueryHookResult = ReturnType<typeof useRunsModelQuery>;
export type RunsModelLazyQueryHookResult = ReturnType<typeof useRunsModelLazyQuery>;
export type RunsModelQueryResult = ApolloReactCommon.QueryResult<RunsModelQuery, RunsModelQueryVariables>;
export const RunsProjectDocument = gql`
    query RunsProject($name: String!, $entityName: String, $baseFilters: JSONString = "{}", $filters: JSONString, $selections: JSONString) {
  project(name: $name, entityName: $entityName) {
    id
    access
    readOnly
    name
    description
    createdAt
    entityName
    views
    requestSubscribe
    totalRunsWithGroup
    totalRunsWithJobType
    runCount(filters: $baseFilters)
    filteredCount: runCount(filters: $filters)
    selectedCount: runCount(filters: $selections)
    projectSettings: allViews(viewType: "project", viewName: "settings", first: 1) {
      edges {
        node {
          ...ViewFragment
        }
      }
    }
  }
}
    ${ViewFragmentFragmentDoc}`;
export type RunsProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsProjectQuery, RunsProjectQueryVariables>, 'query'> & ({ variables: RunsProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsProjectComponent = (props: RunsProjectComponentProps) => (
      <ApolloReactComponents.Query<RunsProjectQuery, RunsProjectQueryVariables> query={RunsProjectDocument} {...props} />
    );
    
export type RunsProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsProjectQuery, RunsProjectQueryVariables>
    } & TChildProps;
export function withRunsProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsProjectQuery,
  RunsProjectQueryVariables,
  RunsProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsProjectQuery, RunsProjectQueryVariables, RunsProjectProps<TChildProps, TDataName>>(RunsProjectDocument, {
      alias: 'runsProject',
      ...operationOptions
    });
};

/**
 * __useRunsProjectQuery__
 *
 * To run a query within a React component, call `useRunsProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsProjectQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      baseFilters: // value for 'baseFilters'
 *      filters: // value for 'filters'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useRunsProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsProjectQuery, RunsProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsProjectQuery, RunsProjectQueryVariables>(RunsProjectDocument, baseOptions);
      }
export function useRunsProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsProjectQuery, RunsProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsProjectQuery, RunsProjectQueryVariables>(RunsProjectDocument, baseOptions);
        }
export type RunsProjectQueryHookResult = ReturnType<typeof useRunsProjectQuery>;
export type RunsProjectLazyQueryHookResult = ReturnType<typeof useRunsProjectLazyQuery>;
export type RunsProjectQueryResult = ApolloReactCommon.QueryResult<RunsProjectQuery, RunsProjectQueryVariables>;
export const UpsertRunDocument = gql`
    mutation upsertRun($id: String, $displayName: String, $notes: String, $tags: [String!]) {
  upsertBucket(input: {id: $id, displayName: $displayName, notes: $notes, tags: $tags}) {
    bucket {
      id
      name
      displayName
      notes
      tags: tagColors {
        name
        colorIndex
      }
      project {
        id
        tags {
          name
          colorIndex
        }
      }
    }
    inserted
  }
}
    `;
export type UpsertRunMutationFn = ApolloReactCommon.MutationFunction<UpsertRunMutation, UpsertRunMutationVariables>;
export type UpsertRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertRunMutation, UpsertRunMutationVariables>, 'mutation'>;

    export const UpsertRunComponent = (props: UpsertRunComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertRunMutation, UpsertRunMutationVariables> mutation={UpsertRunDocument} {...props} />
    );
    
export type UpsertRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertRunMutation, UpsertRunMutationVariables>
    } & TChildProps;
export function withUpsertRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertRunMutation,
  UpsertRunMutationVariables,
  UpsertRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertRunMutation, UpsertRunMutationVariables, UpsertRunProps<TChildProps, TDataName>>(UpsertRunDocument, {
      alias: 'upsertRun',
      ...operationOptions
    });
};

/**
 * __useUpsertRunMutation__
 *
 * To run a mutation, you first call `useUpsertRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRunMutation, { data, loading, error }] = useUpsertRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      notes: // value for 'notes'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpsertRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertRunMutation, UpsertRunMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertRunMutation, UpsertRunMutationVariables>(UpsertRunDocument, baseOptions);
      }
export type UpsertRunMutationHookResult = ReturnType<typeof useUpsertRunMutation>;
export type UpsertRunMutationResult = ApolloReactCommon.MutationResult<UpsertRunMutation>;
export type UpsertRunMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertRunMutation, UpsertRunMutationVariables>;
export const UpsertRunGroupDocument = gql`
    mutation upsertRunGroup($entityName: String!, $projectName: String!, $name: String!, $newName: String, $notes: String, $tags: [String!]) {
  upsertRunGroup(input: {projectName: $projectName, entityName: $entityName, name: $name, notes: $notes, newName: $newName, tags: $tags}) {
    group {
      id
      name
      notes
      tags {
        name
        colorIndex
      }
      project {
        id
        tags {
          name
          colorIndex
        }
      }
    }
  }
}
    `;
export type UpsertRunGroupMutationFn = ApolloReactCommon.MutationFunction<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>;
export type UpsertRunGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>, 'mutation'>;

    export const UpsertRunGroupComponent = (props: UpsertRunGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertRunGroupMutation, UpsertRunGroupMutationVariables> mutation={UpsertRunGroupDocument} {...props} />
    );
    
export type UpsertRunGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>
    } & TChildProps;
export function withUpsertRunGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertRunGroupMutation,
  UpsertRunGroupMutationVariables,
  UpsertRunGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertRunGroupMutation, UpsertRunGroupMutationVariables, UpsertRunGroupProps<TChildProps, TDataName>>(UpsertRunGroupDocument, {
      alias: 'upsertRunGroup',
      ...operationOptions
    });
};

/**
 * __useUpsertRunGroupMutation__
 *
 * To run a mutation, you first call `useUpsertRunGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRunGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRunGroupMutation, { data, loading, error }] = useUpsertRunGroupMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      name: // value for 'name'
 *      newName: // value for 'newName'
 *      notes: // value for 'notes'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpsertRunGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>(UpsertRunGroupDocument, baseOptions);
      }
export type UpsertRunGroupMutationHookResult = ReturnType<typeof useUpsertRunGroupMutation>;
export type UpsertRunGroupMutationResult = ApolloReactCommon.MutationResult<UpsertRunGroupMutation>;
export type UpsertRunGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>;
export const ModifyRunsDocument = gql`
    mutation modifyRuns($filters: JSONString, $entityName: String, $projectName: String, $addTags: [String], $removeTags: [String]) {
  modifyRuns(input: {filters: $filters, entityName: $entityName, projectName: $projectName, addTags: $addTags, removeTags: $removeTags}) {
    runsSQL {
      id
      name
      __typename
      ...SelectRunFragment
      ...BasicRunFragment
      user {
        id
        username
        photoUrl
      }
    }
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}`;
export type ModifyRunsMutationFn = ApolloReactCommon.MutationFunction<ModifyRunsMutation, ModifyRunsMutationVariables>;
export type ModifyRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ModifyRunsMutation, ModifyRunsMutationVariables>, 'mutation'>;

    export const ModifyRunsComponent = (props: ModifyRunsComponentProps) => (
      <ApolloReactComponents.Mutation<ModifyRunsMutation, ModifyRunsMutationVariables> mutation={ModifyRunsDocument} {...props} />
    );
    
export type ModifyRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ModifyRunsMutation, ModifyRunsMutationVariables>
    } & TChildProps;
export function withModifyRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModifyRunsMutation,
  ModifyRunsMutationVariables,
  ModifyRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ModifyRunsMutation, ModifyRunsMutationVariables, ModifyRunsProps<TChildProps, TDataName>>(ModifyRunsDocument, {
      alias: 'modifyRuns',
      ...operationOptions
    });
};

/**
 * __useModifyRunsMutation__
 *
 * To run a mutation, you first call `useModifyRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRunsMutation, { data, loading, error }] = useModifyRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      addTags: // value for 'addTags'
 *      removeTags: // value for 'removeTags'
 *   },
 * });
 */
export function useModifyRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ModifyRunsMutation, ModifyRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<ModifyRunsMutation, ModifyRunsMutationVariables>(ModifyRunsDocument, baseOptions);
      }
export type ModifyRunsMutationHookResult = ReturnType<typeof useModifyRunsMutation>;
export type ModifyRunsMutationResult = ApolloReactCommon.MutationResult<ModifyRunsMutation>;
export type ModifyRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<ModifyRunsMutation, ModifyRunsMutationVariables>;
export const MoveRunsDocument = gql`
    mutation moveRuns($filters: JSONString!, $sourceEntityName: String!, $sourceProjectName: String!, $destinationEntityName: String!, $destinationProjectName: String!) {
  moveRuns(input: {filters: $filters, sourceEntityName: $sourceEntityName, sourceProjectName: $sourceProjectName, destinationEntityName: $destinationEntityName, destinationProjectName: $destinationProjectName}) {
    task {
      id
    }
  }
}
    `;
export type MoveRunsMutationFn = ApolloReactCommon.MutationFunction<MoveRunsMutation, MoveRunsMutationVariables>;
export type MoveRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveRunsMutation, MoveRunsMutationVariables>, 'mutation'>;

    export const MoveRunsComponent = (props: MoveRunsComponentProps) => (
      <ApolloReactComponents.Mutation<MoveRunsMutation, MoveRunsMutationVariables> mutation={MoveRunsDocument} {...props} />
    );
    
export type MoveRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveRunsMutation, MoveRunsMutationVariables>
    } & TChildProps;
export function withMoveRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveRunsMutation,
  MoveRunsMutationVariables,
  MoveRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveRunsMutation, MoveRunsMutationVariables, MoveRunsProps<TChildProps, TDataName>>(MoveRunsDocument, {
      alias: 'moveRuns',
      ...operationOptions
    });
};

/**
 * __useMoveRunsMutation__
 *
 * To run a mutation, you first call `useMoveRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRunsMutation, { data, loading, error }] = useMoveRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      sourceProjectName: // value for 'sourceProjectName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      destinationProjectName: // value for 'destinationProjectName'
 *   },
 * });
 */
export function useMoveRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveRunsMutation, MoveRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveRunsMutation, MoveRunsMutationVariables>(MoveRunsDocument, baseOptions);
      }
export type MoveRunsMutationHookResult = ReturnType<typeof useMoveRunsMutation>;
export type MoveRunsMutationResult = ApolloReactCommon.MutationResult<MoveRunsMutation>;
export type MoveRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveRunsMutation, MoveRunsMutationVariables>;
export const DeleteRunsDocument = gql`
    mutation deleteRuns($filters: JSONString!, $entityName: String!, $projectName: String!, $deleteArtifacts: Boolean!) {
  deleteRuns(input: {filters: $filters, entityName: $entityName, projectName: $projectName, deleteArtifacts: $deleteArtifacts}) {
    clientMutationId
  }
}
    `;
export type DeleteRunsMutationFn = ApolloReactCommon.MutationFunction<DeleteRunsMutation, DeleteRunsMutationVariables>;
export type DeleteRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunsMutation, DeleteRunsMutationVariables>, 'mutation'>;

    export const DeleteRunsComponent = (props: DeleteRunsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunsMutation, DeleteRunsMutationVariables> mutation={DeleteRunsDocument} {...props} />
    );
    
export type DeleteRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunsMutation, DeleteRunsMutationVariables>
    } & TChildProps;
export function withDeleteRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunsMutation,
  DeleteRunsMutationVariables,
  DeleteRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunsMutation, DeleteRunsMutationVariables, DeleteRunsProps<TChildProps, TDataName>>(DeleteRunsDocument, {
      alias: 'deleteRuns',
      ...operationOptions
    });
};

/**
 * __useDeleteRunsMutation__
 *
 * To run a mutation, you first call `useDeleteRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunsMutation, { data, loading, error }] = useDeleteRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      deleteArtifacts: // value for 'deleteArtifacts'
 *   },
 * });
 */
export function useDeleteRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunsMutation, DeleteRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunsMutation, DeleteRunsMutationVariables>(DeleteRunsDocument, baseOptions);
      }
export type DeleteRunsMutationHookResult = ReturnType<typeof useDeleteRunsMutation>;
export type DeleteRunsMutationResult = ApolloReactCommon.MutationResult<DeleteRunsMutation>;
export type DeleteRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunsMutation, DeleteRunsMutationVariables>;
export const UndeleteRunsDocument = gql`
    mutation undeleteRuns($filters: JSONString!, $entityName: String!, $projectName: String!) {
  undeleteRuns(input: {filters: $filters, entityName: $entityName, projectName: $projectName}) {
    clientMutationId
  }
}
    `;
export type UndeleteRunsMutationFn = ApolloReactCommon.MutationFunction<UndeleteRunsMutation, UndeleteRunsMutationVariables>;
export type UndeleteRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>, 'mutation'>;

    export const UndeleteRunsComponent = (props: UndeleteRunsComponentProps) => (
      <ApolloReactComponents.Mutation<UndeleteRunsMutation, UndeleteRunsMutationVariables> mutation={UndeleteRunsDocument} {...props} />
    );
    
export type UndeleteRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UndeleteRunsMutation, UndeleteRunsMutationVariables>
    } & TChildProps;
export function withUndeleteRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UndeleteRunsMutation,
  UndeleteRunsMutationVariables,
  UndeleteRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UndeleteRunsMutation, UndeleteRunsMutationVariables, UndeleteRunsProps<TChildProps, TDataName>>(UndeleteRunsDocument, {
      alias: 'undeleteRuns',
      ...operationOptions
    });
};

/**
 * __useUndeleteRunsMutation__
 *
 * To run a mutation, you first call `useUndeleteRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteRunsMutation, { data, loading, error }] = useUndeleteRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useUndeleteRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<UndeleteRunsMutation, UndeleteRunsMutationVariables>(UndeleteRunsDocument, baseOptions);
      }
export type UndeleteRunsMutationHookResult = ReturnType<typeof useUndeleteRunsMutation>;
export type UndeleteRunsMutationResult = ApolloReactCommon.MutationResult<UndeleteRunsMutation>;
export type UndeleteRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>;
export const DeleteRunDocument = gql`
    mutation deleteRun($id: ID!, $deleteArtifacts: Boolean = false) {
  deleteRun(input: {id: $id, deleteArtifacts: $deleteArtifacts}) {
    clientMutationId
  }
}
    `;
export type DeleteRunMutationFn = ApolloReactCommon.MutationFunction<DeleteRunMutation, DeleteRunMutationVariables>;
export type DeleteRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunMutation, DeleteRunMutationVariables>, 'mutation'>;

    export const DeleteRunComponent = (props: DeleteRunComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunMutation, DeleteRunMutationVariables> mutation={DeleteRunDocument} {...props} />
    );
    
export type DeleteRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunMutation, DeleteRunMutationVariables>
    } & TChildProps;
export function withDeleteRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunMutation,
  DeleteRunMutationVariables,
  DeleteRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunMutation, DeleteRunMutationVariables, DeleteRunProps<TChildProps, TDataName>>(DeleteRunDocument, {
      alias: 'deleteRun',
      ...operationOptions
    });
};

/**
 * __useDeleteRunMutation__
 *
 * To run a mutation, you first call `useDeleteRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunMutation, { data, loading, error }] = useDeleteRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteArtifacts: // value for 'deleteArtifacts'
 *   },
 * });
 */
export function useDeleteRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunMutation, DeleteRunMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunMutation, DeleteRunMutationVariables>(DeleteRunDocument, baseOptions);
      }
export type DeleteRunMutationHookResult = ReturnType<typeof useDeleteRunMutation>;
export type DeleteRunMutationResult = ApolloReactCommon.MutationResult<DeleteRunMutation>;
export type DeleteRunMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunMutation, DeleteRunMutationVariables>;
export const StopRunDocument = gql`
    mutation stopRun($id: ID!) {
  stopRun(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type StopRunMutationFn = ApolloReactCommon.MutationFunction<StopRunMutation, StopRunMutationVariables>;
export type StopRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StopRunMutation, StopRunMutationVariables>, 'mutation'>;

    export const StopRunComponent = (props: StopRunComponentProps) => (
      <ApolloReactComponents.Mutation<StopRunMutation, StopRunMutationVariables> mutation={StopRunDocument} {...props} />
    );
    
export type StopRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StopRunMutation, StopRunMutationVariables>
    } & TChildProps;
export function withStopRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StopRunMutation,
  StopRunMutationVariables,
  StopRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StopRunMutation, StopRunMutationVariables, StopRunProps<TChildProps, TDataName>>(StopRunDocument, {
      alias: 'stopRun',
      ...operationOptions
    });
};

/**
 * __useStopRunMutation__
 *
 * To run a mutation, you first call `useStopRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRunMutation, { data, loading, error }] = useStopRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopRunMutation, StopRunMutationVariables>) {
        return ApolloReactHooks.useMutation<StopRunMutation, StopRunMutationVariables>(StopRunDocument, baseOptions);
      }
export type StopRunMutationHookResult = ReturnType<typeof useStopRunMutation>;
export type StopRunMutationResult = ApolloReactCommon.MutationResult<StopRunMutation>;
export type StopRunMutationOptions = ApolloReactCommon.BaseMutationOptions<StopRunMutation, StopRunMutationVariables>;
export const RunStopDocument = gql`
    mutation runStop($id: ID!) {
  stopRun(input: {id: $id}) {
    success
  }
}
    `;
export type RunStopMutationFn = ApolloReactCommon.MutationFunction<RunStopMutation, RunStopMutationVariables>;
export type RunStopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RunStopMutation, RunStopMutationVariables>, 'mutation'>;

    export const RunStopComponent = (props: RunStopComponentProps) => (
      <ApolloReactComponents.Mutation<RunStopMutation, RunStopMutationVariables> mutation={RunStopDocument} {...props} />
    );
    
export type RunStopProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RunStopMutation, RunStopMutationVariables>
    } & TChildProps;
export function withRunStop<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunStopMutation,
  RunStopMutationVariables,
  RunStopProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RunStopMutation, RunStopMutationVariables, RunStopProps<TChildProps, TDataName>>(RunStopDocument, {
      alias: 'runStop',
      ...operationOptions
    });
};

/**
 * __useRunStopMutation__
 *
 * To run a mutation, you first call `useRunStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runStopMutation, { data, loading, error }] = useRunStopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunStopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RunStopMutation, RunStopMutationVariables>) {
        return ApolloReactHooks.useMutation<RunStopMutation, RunStopMutationVariables>(RunStopDocument, baseOptions);
      }
export type RunStopMutationHookResult = ReturnType<typeof useRunStopMutation>;
export type RunStopMutationResult = ApolloReactCommon.MutationResult<RunStopMutation>;
export type RunStopMutationOptions = ApolloReactCommon.BaseMutationOptions<RunStopMutation, RunStopMutationVariables>;
export const DeleteFilesDocument = gql`
    mutation deleteFiles($ids: [ID!]!) {
  deleteFiles(input: {files: $ids}) {
    clientMutationId
    success
  }
}
    `;
export type DeleteFilesMutationFn = ApolloReactCommon.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>;
export type DeleteFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFilesMutation, DeleteFilesMutationVariables>, 'mutation'>;

    export const DeleteFilesComponent = (props: DeleteFilesComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFilesMutation, DeleteFilesMutationVariables> mutation={DeleteFilesDocument} {...props} />
    );
    
export type DeleteFilesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>
    } & TChildProps;
export function withDeleteFiles<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFilesMutation,
  DeleteFilesMutationVariables,
  DeleteFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFilesMutation, DeleteFilesMutationVariables, DeleteFilesProps<TChildProps, TDataName>>(DeleteFilesDocument, {
      alias: 'deleteFiles',
      ...operationOptions
    });
};

/**
 * __useDeleteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilesMutation, { data, loading, error }] = useDeleteFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFilesMutation, DeleteFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFilesMutation, DeleteFilesMutationVariables>(DeleteFilesDocument, baseOptions);
      }
export type DeleteFilesMutationHookResult = ReturnType<typeof useDeleteFilesMutation>;
export type DeleteFilesMutationResult = ApolloReactCommon.MutationResult<DeleteFilesMutation>;
export type DeleteFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const ServerInfoDocument = gql`
    query ServerInfo {
  serverInfo {
    availableCloudRegions {
      id
      provider
      region
    }
    isGoogleIap
    slackClientID
  }
}
    `;
export type ServerInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServerInfoQuery, ServerInfoQueryVariables>, 'query'>;

    export const ServerInfoComponent = (props: ServerInfoComponentProps) => (
      <ApolloReactComponents.Query<ServerInfoQuery, ServerInfoQueryVariables> query={ServerInfoDocument} {...props} />
    );
    
export type ServerInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServerInfoQuery, ServerInfoQueryVariables>
    } & TChildProps;
export function withServerInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServerInfoQuery,
  ServerInfoQueryVariables,
  ServerInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServerInfoQuery, ServerInfoQueryVariables, ServerInfoProps<TChildProps, TDataName>>(ServerInfoDocument, {
      alias: 'serverInfo',
      ...operationOptions
    });
};

/**
 * __useServerInfoQuery__
 *
 * To run a query within a React component, call `useServerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServerInfoQuery, ServerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ServerInfoQuery, ServerInfoQueryVariables>(ServerInfoDocument, baseOptions);
      }
export function useServerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServerInfoQuery, ServerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServerInfoQuery, ServerInfoQueryVariables>(ServerInfoDocument, baseOptions);
        }
export type ServerInfoQueryHookResult = ReturnType<typeof useServerInfoQuery>;
export type ServerInfoLazyQueryHookResult = ReturnType<typeof useServerInfoLazyQuery>;
export type ServerInfoQueryResult = ApolloReactCommon.QueryResult<ServerInfoQuery, ServerInfoQueryVariables>;
export const LicenseExpiredDocument = gql`
    query LicenseExpired {
  serverInfo {
    licenseExpired
  }
}
    `;
export type LicenseExpiredComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LicenseExpiredQuery, LicenseExpiredQueryVariables>, 'query'>;

    export const LicenseExpiredComponent = (props: LicenseExpiredComponentProps) => (
      <ApolloReactComponents.Query<LicenseExpiredQuery, LicenseExpiredQueryVariables> query={LicenseExpiredDocument} {...props} />
    );
    
export type LicenseExpiredProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LicenseExpiredQuery, LicenseExpiredQueryVariables>
    } & TChildProps;
export function withLicenseExpired<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LicenseExpiredQuery,
  LicenseExpiredQueryVariables,
  LicenseExpiredProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LicenseExpiredQuery, LicenseExpiredQueryVariables, LicenseExpiredProps<TChildProps, TDataName>>(LicenseExpiredDocument, {
      alias: 'licenseExpired',
      ...operationOptions
    });
};

/**
 * __useLicenseExpiredQuery__
 *
 * To run a query within a React component, call `useLicenseExpiredQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseExpiredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseExpiredQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseExpiredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LicenseExpiredQuery, LicenseExpiredQueryVariables>) {
        return ApolloReactHooks.useQuery<LicenseExpiredQuery, LicenseExpiredQueryVariables>(LicenseExpiredDocument, baseOptions);
      }
export function useLicenseExpiredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LicenseExpiredQuery, LicenseExpiredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LicenseExpiredQuery, LicenseExpiredQueryVariables>(LicenseExpiredDocument, baseOptions);
        }
export type LicenseExpiredQueryHookResult = ReturnType<typeof useLicenseExpiredQuery>;
export type LicenseExpiredLazyQueryHookResult = ReturnType<typeof useLicenseExpiredLazyQuery>;
export type LicenseExpiredQueryResult = ApolloReactCommon.QueryResult<LicenseExpiredQuery, LicenseExpiredQueryVariables>;
export const UserLimitReachedDocument = gql`
    query UserLimitReached {
  serverInfo {
    userLimitReached
  }
}
    `;
export type UserLimitReachedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>, 'query'>;

    export const UserLimitReachedComponent = (props: UserLimitReachedComponentProps) => (
      <ApolloReactComponents.Query<UserLimitReachedQuery, UserLimitReachedQueryVariables> query={UserLimitReachedDocument} {...props} />
    );
    
export type UserLimitReachedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserLimitReachedQuery, UserLimitReachedQueryVariables>
    } & TChildProps;
export function withUserLimitReached<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserLimitReachedQuery,
  UserLimitReachedQueryVariables,
  UserLimitReachedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserLimitReachedQuery, UserLimitReachedQueryVariables, UserLimitReachedProps<TChildProps, TDataName>>(UserLimitReachedDocument, {
      alias: 'userLimitReached',
      ...operationOptions
    });
};

/**
 * __useUserLimitReachedQuery__
 *
 * To run a query within a React component, call `useUserLimitReachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLimitReachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLimitReachedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLimitReachedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>) {
        return ApolloReactHooks.useQuery<UserLimitReachedQuery, UserLimitReachedQueryVariables>(UserLimitReachedDocument, baseOptions);
      }
export function useUserLimitReachedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserLimitReachedQuery, UserLimitReachedQueryVariables>(UserLimitReachedDocument, baseOptions);
        }
export type UserLimitReachedQueryHookResult = ReturnType<typeof useUserLimitReachedQuery>;
export type UserLimitReachedLazyQueryHookResult = ReturnType<typeof useUserLimitReachedLazyQuery>;
export type UserLimitReachedQueryResult = ApolloReactCommon.QueryResult<UserLimitReachedQuery, UserLimitReachedQueryVariables>;
export const FrontendHostDocument = gql`
    query FrontendHost {
  serverInfo {
    frontendHost
  }
}
    `;
export type FrontendHostComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FrontendHostQuery, FrontendHostQueryVariables>, 'query'>;

    export const FrontendHostComponent = (props: FrontendHostComponentProps) => (
      <ApolloReactComponents.Query<FrontendHostQuery, FrontendHostQueryVariables> query={FrontendHostDocument} {...props} />
    );
    
export type FrontendHostProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FrontendHostQuery, FrontendHostQueryVariables>
    } & TChildProps;
export function withFrontendHost<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FrontendHostQuery,
  FrontendHostQueryVariables,
  FrontendHostProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FrontendHostQuery, FrontendHostQueryVariables, FrontendHostProps<TChildProps, TDataName>>(FrontendHostDocument, {
      alias: 'frontendHost',
      ...operationOptions
    });
};

/**
 * __useFrontendHostQuery__
 *
 * To run a query within a React component, call `useFrontendHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontendHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontendHostQuery({
 *   variables: {
 *   },
 * });
 */
export function useFrontendHostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontendHostQuery, FrontendHostQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontendHostQuery, FrontendHostQueryVariables>(FrontendHostDocument, baseOptions);
      }
export function useFrontendHostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontendHostQuery, FrontendHostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontendHostQuery, FrontendHostQueryVariables>(FrontendHostDocument, baseOptions);
        }
export type FrontendHostQueryHookResult = ReturnType<typeof useFrontendHostQuery>;
export type FrontendHostLazyQueryHookResult = ReturnType<typeof useFrontendHostLazyQuery>;
export type FrontendHostQueryResult = ApolloReactCommon.QueryResult<FrontendHostQuery, FrontendHostQueryVariables>;
export const CreateStorageSubscriptionDocument = gql`
    mutation createStorageSubscription($storagePlanId: ID!, $trackingPlanId: ID!, $organizationId: ID, $paymentMethod: String, $trial: Boolean!, $clientMutationId: String) {
  createStorageSubscription(input: {storagePlanId: $storagePlanId, trackingPlanId: $trackingPlanId, organizationId: $organizationId, paymentMethod: $paymentMethod, trial: $trial, clientMutationId: $clientMutationId}) {
    subscription
    clientMutationId
  }
}
    `;
export type CreateStorageSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>;
export type CreateStorageSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>, 'mutation'>;

    export const CreateStorageSubscriptionComponent = (props: CreateStorageSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables> mutation={CreateStorageSubscriptionDocument} {...props} />
    );
    
export type CreateStorageSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateStorageSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateStorageSubscriptionMutation,
  CreateStorageSubscriptionMutationVariables,
  CreateStorageSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables, CreateStorageSubscriptionProps<TChildProps, TDataName>>(CreateStorageSubscriptionDocument, {
      alias: 'createStorageSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateStorageSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStorageSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageSubscriptionMutation, { data, loading, error }] = useCreateStorageSubscriptionMutation({
 *   variables: {
 *      storagePlanId: // value for 'storagePlanId'
 *      trackingPlanId: // value for 'trackingPlanId'
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      trial: // value for 'trial'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateStorageSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>(CreateStorageSubscriptionDocument, baseOptions);
      }
export type CreateStorageSubscriptionMutationHookResult = ReturnType<typeof useCreateStorageSubscriptionMutation>;
export type CreateStorageSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateStorageSubscriptionMutation>;
export type CreateStorageSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>;
export const SubscriptionPlansDocument = gql`
    query SubscriptionPlans {
  plans {
    id
    name
    stripePlanId
    maxSeats
    defaultPrivileges
    planType
    planVisibility
    unitPrice
    billingInterval
  }
}
    `;
export type SubscriptionPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>, 'query'>;

    export const SubscriptionPlansComponent = (props: SubscriptionPlansComponentProps) => (
      <ApolloReactComponents.Query<SubscriptionPlansQuery, SubscriptionPlansQueryVariables> query={SubscriptionPlansDocument} {...props} />
    );
    
export type SubscriptionPlansProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
    } & TChildProps;
export function withSubscriptionPlans<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables,
  SubscriptionPlansProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubscriptionPlansQuery, SubscriptionPlansQueryVariables, SubscriptionPlansProps<TChildProps, TDataName>>(SubscriptionPlansDocument, {
      alias: 'subscriptionPlans',
      ...operationOptions
    });
};

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = ApolloReactCommon.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const UpsertSweepDocument = gql`
    mutation upsertSweep($id: ID, $description: String, $state: String, $displayName: String, $config: String, $entityName: String, $projectName: String, $priorRunsFilters: JSONString) {
  upsertSweep(input: {id: $id, description: $description, state: $state, displayName: $displayName, config: $config, entityName: $entityName, projectName: $projectName, priorRunsFilters: $priorRunsFilters}) {
    sweep {
      id
      name
      displayName
      description
      state
    }
    inserted
    insertedPriorRuns
  }
}
    `;
export type UpsertSweepMutationFn = ApolloReactCommon.MutationFunction<UpsertSweepMutation, UpsertSweepMutationVariables>;
export type UpsertSweepComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertSweepMutation, UpsertSweepMutationVariables>, 'mutation'>;

    export const UpsertSweepComponent = (props: UpsertSweepComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertSweepMutation, UpsertSweepMutationVariables> mutation={UpsertSweepDocument} {...props} />
    );
    
export type UpsertSweepProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertSweepMutation, UpsertSweepMutationVariables>
    } & TChildProps;
export function withUpsertSweep<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertSweepMutation,
  UpsertSweepMutationVariables,
  UpsertSweepProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertSweepMutation, UpsertSweepMutationVariables, UpsertSweepProps<TChildProps, TDataName>>(UpsertSweepDocument, {
      alias: 'upsertSweep',
      ...operationOptions
    });
};

/**
 * __useUpsertSweepMutation__
 *
 * To run a mutation, you first call `useUpsertSweepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSweepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSweepMutation, { data, loading, error }] = useUpsertSweepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      state: // value for 'state'
 *      displayName: // value for 'displayName'
 *      config: // value for 'config'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      priorRunsFilters: // value for 'priorRunsFilters'
 *   },
 * });
 */
export function useUpsertSweepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertSweepMutation, UpsertSweepMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertSweepMutation, UpsertSweepMutationVariables>(UpsertSweepDocument, baseOptions);
      }
export type UpsertSweepMutationHookResult = ReturnType<typeof useUpsertSweepMutation>;
export type UpsertSweepMutationResult = ApolloReactCommon.MutationResult<UpsertSweepMutation>;
export type UpsertSweepMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertSweepMutation, UpsertSweepMutationVariables>;
export const DeleteSweepsDocument = gql`
    mutation deleteSweeps($ids: [ID!]!, $deleteRuns: Boolean!) {
  deleteSweeps(input: {ids: $ids, deleteRuns: $deleteRuns}) {
    affectedAgents
    affectedRuns
  }
}
    `;
export type DeleteSweepsMutationFn = ApolloReactCommon.MutationFunction<DeleteSweepsMutation, DeleteSweepsMutationVariables>;
export type DeleteSweepsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>, 'mutation'>;

    export const DeleteSweepsComponent = (props: DeleteSweepsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSweepsMutation, DeleteSweepsMutationVariables> mutation={DeleteSweepsDocument} {...props} />
    );
    
export type DeleteSweepsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteSweepsMutation, DeleteSweepsMutationVariables>
    } & TChildProps;
export function withDeleteSweeps<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSweepsMutation,
  DeleteSweepsMutationVariables,
  DeleteSweepsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSweepsMutation, DeleteSweepsMutationVariables, DeleteSweepsProps<TChildProps, TDataName>>(DeleteSweepsDocument, {
      alias: 'deleteSweeps',
      ...operationOptions
    });
};

/**
 * __useDeleteSweepsMutation__
 *
 * To run a mutation, you first call `useDeleteSweepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSweepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSweepsMutation, { data, loading, error }] = useDeleteSweepsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      deleteRuns: // value for 'deleteRuns'
 *   },
 * });
 */
export function useDeleteSweepsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSweepsMutation, DeleteSweepsMutationVariables>(DeleteSweepsDocument, baseOptions);
      }
export type DeleteSweepsMutationHookResult = ReturnType<typeof useDeleteSweepsMutation>;
export type DeleteSweepsMutationResult = ApolloReactCommon.MutationResult<DeleteSweepsMutation>;
export type DeleteSweepsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>;
export const GetTaskDocument = gql`
    query GetTask($id: ID!) {
  task(id: $id) {
    id
    name
    __typename
    state
    progress
    createdAt
    updatedAt
    type
  }
}
    `;
export type GetTaskComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTaskQuery, GetTaskQueryVariables>, 'query'> & ({ variables: GetTaskQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTaskComponent = (props: GetTaskComponentProps) => (
      <ApolloReactComponents.Query<GetTaskQuery, GetTaskQueryVariables> query={GetTaskDocument} {...props} />
    );
    
export type GetTaskProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTaskQuery, GetTaskQueryVariables>
    } & TChildProps;
export function withGetTask<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTaskQuery, GetTaskQueryVariables, GetTaskProps<TChildProps, TDataName>>(GetTaskDocument, {
      alias: 'getTask',
      ...operationOptions
    });
};

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
      }
export function useGetTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = ApolloReactCommon.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const TeamOrganizationDocument = gql`
    query TeamOrganization($entityName: String) {
  entity(name: $entityName) {
    id
    isTeam
    computeHours
    organization {
      id
      name
      members {
        id
        username
        role
      }
      subscriptions {
        id
        privileges
      }
    }
  }
}
    `;
export type TeamOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>, 'query'>;

    export const TeamOrganizationComponent = (props: TeamOrganizationComponentProps) => (
      <ApolloReactComponents.Query<TeamOrganizationQuery, TeamOrganizationQueryVariables> query={TeamOrganizationDocument} {...props} />
    );
    
export type TeamOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamOrganizationQuery, TeamOrganizationQueryVariables>
    } & TChildProps;
export function withTeamOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamOrganizationQuery,
  TeamOrganizationQueryVariables,
  TeamOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamOrganizationQuery, TeamOrganizationQueryVariables, TeamOrganizationProps<TChildProps, TDataName>>(TeamOrganizationDocument, {
      alias: 'teamOrganization',
      ...operationOptions
    });
};

/**
 * __useTeamOrganizationQuery__
 *
 * To run a query within a React component, call `useTeamOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOrganizationQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamOrganizationQuery, TeamOrganizationQueryVariables>(TeamOrganizationDocument, baseOptions);
      }
export function useTeamOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamOrganizationQuery, TeamOrganizationQueryVariables>(TeamOrganizationDocument, baseOptions);
        }
export type TeamOrganizationQueryHookResult = ReturnType<typeof useTeamOrganizationQuery>;
export type TeamOrganizationLazyQueryHookResult = ReturnType<typeof useTeamOrganizationLazyQuery>;
export type TeamOrganizationQueryResult = ApolloReactCommon.QueryResult<TeamOrganizationQuery, TeamOrganizationQueryVariables>;
export const AvailableTeamDocument = gql`
    query availableTeam($teamName: String!) {
  entity(name: $teamName) {
    id
    available
  }
}
    `;
export type AvailableTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableTeamQuery, AvailableTeamQueryVariables>, 'query'> & ({ variables: AvailableTeamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableTeamComponent = (props: AvailableTeamComponentProps) => (
      <ApolloReactComponents.Query<AvailableTeamQuery, AvailableTeamQueryVariables> query={AvailableTeamDocument} {...props} />
    );
    
export type AvailableTeamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableTeamQuery, AvailableTeamQueryVariables>
    } & TChildProps;
export function withAvailableTeam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableTeamQuery,
  AvailableTeamQueryVariables,
  AvailableTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableTeamQuery, AvailableTeamQueryVariables, AvailableTeamProps<TChildProps, TDataName>>(AvailableTeamDocument, {
      alias: 'availableTeam',
      ...operationOptions
    });
};

/**
 * __useAvailableTeamQuery__
 *
 * To run a query within a React component, call `useAvailableTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTeamQuery({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useAvailableTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableTeamQuery, AvailableTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableTeamQuery, AvailableTeamQueryVariables>(AvailableTeamDocument, baseOptions);
      }
export function useAvailableTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableTeamQuery, AvailableTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableTeamQuery, AvailableTeamQueryVariables>(AvailableTeamDocument, baseOptions);
        }
export type AvailableTeamQueryHookResult = ReturnType<typeof useAvailableTeamQuery>;
export type AvailableTeamLazyQueryHookResult = ReturnType<typeof useAvailableTeamLazyQuery>;
export type AvailableTeamQueryResult = ApolloReactCommon.QueryResult<AvailableTeamQuery, AvailableTeamQueryVariables>;
export const UserContextDocument = gql`
    query UserContext {
  viewer {
    id
    views(viewType: "userContext") {
      edges {
        node {
          id
          spec
        }
      }
    }
  }
}
    `;
export type UserContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserContextQuery, UserContextQueryVariables>, 'query'>;

    export const UserContextComponent = (props: UserContextComponentProps) => (
      <ApolloReactComponents.Query<UserContextQuery, UserContextQueryVariables> query={UserContextDocument} {...props} />
    );
    
export type UserContextProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserContextQuery, UserContextQueryVariables>
    } & TChildProps;
export function withUserContext<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserContextQuery,
  UserContextQueryVariables,
  UserContextProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserContextQuery, UserContextQueryVariables, UserContextProps<TChildProps, TDataName>>(UserContextDocument, {
      alias: 'userContext',
      ...operationOptions
    });
};

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
        return ApolloReactHooks.useQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, baseOptions);
      }
export function useUserContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, baseOptions);
        }
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export type UserContextQueryResult = ApolloReactCommon.QueryResult<UserContextQuery, UserContextQueryVariables>;
export const UserOrganizationsDocument = gql`
    query UserOrganizations {
  viewer {
    id
    organizations {
      id
      name
      orgType
    }
  }
}
    `;
export type UserOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>, 'query'>;

    export const UserOrganizationsComponent = (props: UserOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<UserOrganizationsQuery, UserOrganizationsQueryVariables> query={UserOrganizationsDocument} {...props} />
    );
    
export type UserOrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserOrganizationsQuery, UserOrganizationsQueryVariables>
    } & TChildProps;
export function withUserOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserOrganizationsQuery,
  UserOrganizationsQueryVariables,
  UserOrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserOrganizationsQuery, UserOrganizationsQueryVariables, UserOrganizationsProps<TChildProps, TDataName>>(UserOrganizationsDocument, {
      alias: 'userOrganizations',
      ...operationOptions
    });
};

/**
 * __useUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, baseOptions);
      }
export function useUserOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, baseOptions);
        }
export type UserOrganizationsQueryHookResult = ReturnType<typeof useUserOrganizationsQuery>;
export type UserOrganizationsLazyQueryHookResult = ReturnType<typeof useUserOrganizationsLazyQuery>;
export type UserOrganizationsQueryResult = ApolloReactCommon.QueryResult<UserOrganizationsQuery, UserOrganizationsQueryVariables>;
export const EntityDocument = gql`
    query Entity($name: String!) {
  entity(name: $name) {
    id
    name
    available
    photoUrl
    readOnly
    readOnlyAdmin
    isTeam
    privateOnly
    storageBytes
    codeSavingEnabled
    settings {
      defaultCloudRegion {
        id
        provider
        region
      }
    }
    defaultAccess
    isPaid
    rateLimits {
      filestream
      graphql
    }
    members {
      id
      admin
      pending
      email
      username
      name
      photoUrl
      accountType
      apiKey
    }
    defaultAlerts {
      id
      condition {
        __typename
        ... on FinishedRunCondition {
          success
        }
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        __typename
        ... on EmailSubscription {
          id
        }
        ... on SlackChannelSubscription {
          id
        }
      }
    }
    integrations {
      edges {
        node {
          id
          __typename
          ... on SlackIntegration {
            teamName
            channelName
          }
        }
      }
    }
    computeHours
    organization {
      id
      name
      orgType
      billingUser {
        id
        username
      }
      subscriptions {
        plan {
          id
          name
          unitPrice
          billingInterval
          planType
        }
        privileges
        seats
        expiresAt
        subscriptionType
        status
      }
      members {
        id
        username
        name
        photoUrl
        admin
        role
      }
      pendingInvites {
        email
      }
    }
  }
  user(userName: $name) {
    id
    username
    accountType
  }
}
    `;
export type EntityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityQuery, EntityQueryVariables>, 'query'> & ({ variables: EntityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityComponent = (props: EntityComponentProps) => (
      <ApolloReactComponents.Query<EntityQuery, EntityQueryVariables> query={EntityDocument} {...props} />
    );
    
export type EntityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityQuery, EntityQueryVariables>
    } & TChildProps;
export function withEntity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityQuery,
  EntityQueryVariables,
  EntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityQuery, EntityQueryVariables, EntityProps<TChildProps, TDataName>>(EntityDocument, {
      alias: 'entity',
      ...operationOptions
    });
};

/**
 * __useEntityQuery__
 *
 * To run a query within a React component, call `useEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEntityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityQuery, EntityQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityQuery, EntityQueryVariables>(EntityDocument, baseOptions);
      }
export function useEntityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityQuery, EntityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityQuery, EntityQueryVariables>(EntityDocument, baseOptions);
        }
export type EntityQueryHookResult = ReturnType<typeof useEntityQuery>;
export type EntityLazyQueryHookResult = ReturnType<typeof useEntityLazyQuery>;
export type EntityQueryResult = ApolloReactCommon.QueryResult<EntityQuery, EntityQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($query: String) {
  users(query: $query) {
    edges {
      node {
        id
        name
        username
        email
        admin
        deletedAt
      }
    }
  }
}
    `;
export type SearchUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchUsersQuery, SearchUsersQueryVariables>, 'query'>;

    export const SearchUsersComponent = (props: SearchUsersComponentProps) => (
      <ApolloReactComponents.Query<SearchUsersQuery, SearchUsersQueryVariables> query={SearchUsersDocument} {...props} />
    );
    
export type SearchUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchUsersQuery, SearchUsersQueryVariables>
    } & TChildProps;
export function withSearchUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchUsersQuery,
  SearchUsersQueryVariables,
  SearchUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchUsersQuery, SearchUsersQueryVariables, SearchUsersProps<TChildProps, TDataName>>(SearchUsersDocument, {
      alias: 'searchUsers',
      ...operationOptions
    });
};

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const SetUserAdminDocument = gql`
    mutation SetUserAdmin($id: ID!, $admin: Boolean!) {
  updateUser(input: {id: $id, admin: $admin}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type SetUserAdminMutationFn = ApolloReactCommon.MutationFunction<SetUserAdminMutation, SetUserAdminMutationVariables>;
export type SetUserAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetUserAdminMutation, SetUserAdminMutationVariables>, 'mutation'>;

    export const SetUserAdminComponent = (props: SetUserAdminComponentProps) => (
      <ApolloReactComponents.Mutation<SetUserAdminMutation, SetUserAdminMutationVariables> mutation={SetUserAdminDocument} {...props} />
    );
    
export type SetUserAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SetUserAdminMutation, SetUserAdminMutationVariables>
    } & TChildProps;
export function withSetUserAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetUserAdminMutation,
  SetUserAdminMutationVariables,
  SetUserAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SetUserAdminMutation, SetUserAdminMutationVariables, SetUserAdminProps<TChildProps, TDataName>>(SetUserAdminDocument, {
      alias: 'setUserAdmin',
      ...operationOptions
    });
};

/**
 * __useSetUserAdminMutation__
 *
 * To run a mutation, you first call `useSetUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAdminMutation, { data, loading, error }] = useSetUserAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useSetUserAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserAdminMutation, SetUserAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<SetUserAdminMutation, SetUserAdminMutationVariables>(SetUserAdminDocument, baseOptions);
      }
export type SetUserAdminMutationHookResult = ReturnType<typeof useSetUserAdminMutation>;
export type SetUserAdminMutationResult = ApolloReactCommon.MutationResult<SetUserAdminMutation>;
export type SetUserAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<SetUserAdminMutation, SetUserAdminMutationVariables>;
export const PurgeUserDocument = gql`
    mutation PurgeUser($username: String!, $email: String!) {
  purgeUser(input: {username: $username, email: $email}) {
    user {
      id
      deletedAt
    }
  }
}
    `;
export type PurgeUserMutationFn = ApolloReactCommon.MutationFunction<PurgeUserMutation, PurgeUserMutationVariables>;
export type PurgeUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PurgeUserMutation, PurgeUserMutationVariables>, 'mutation'>;

    export const PurgeUserComponent = (props: PurgeUserComponentProps) => (
      <ApolloReactComponents.Mutation<PurgeUserMutation, PurgeUserMutationVariables> mutation={PurgeUserDocument} {...props} />
    );
    
export type PurgeUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PurgeUserMutation, PurgeUserMutationVariables>
    } & TChildProps;
export function withPurgeUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PurgeUserMutation,
  PurgeUserMutationVariables,
  PurgeUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PurgeUserMutation, PurgeUserMutationVariables, PurgeUserProps<TChildProps, TDataName>>(PurgeUserDocument, {
      alias: 'purgeUser',
      ...operationOptions
    });
};

/**
 * __usePurgeUserMutation__
 *
 * To run a mutation, you first call `usePurgeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurgeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purgeUserMutation, { data, loading, error }] = usePurgeUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePurgeUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurgeUserMutation, PurgeUserMutationVariables>) {
        return ApolloReactHooks.useMutation<PurgeUserMutation, PurgeUserMutationVariables>(PurgeUserDocument, baseOptions);
      }
export type PurgeUserMutationHookResult = ReturnType<typeof usePurgeUserMutation>;
export type PurgeUserMutationResult = ApolloReactCommon.MutationResult<PurgeUserMutation>;
export type PurgeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<PurgeUserMutation, PurgeUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(input: {id: $id}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>;

    export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables> mutation={DeleteUserDocument} {...props} />
    );
    
export type DeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>
    } & TChildProps;
export function withDeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserMutation, DeleteUserMutationVariables, DeleteUserProps<TChildProps, TDataName>>(DeleteUserDocument, {
      alias: 'deleteUser',
      ...operationOptions
    });
};

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UndeleteUserDocument = gql`
    mutation UndeleteUser($id: ID!) {
  undeleteUser(input: {id: $id}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type UndeleteUserMutationFn = ApolloReactCommon.MutationFunction<UndeleteUserMutation, UndeleteUserMutationVariables>;
export type UndeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UndeleteUserMutation, UndeleteUserMutationVariables>, 'mutation'>;

    export const UndeleteUserComponent = (props: UndeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<UndeleteUserMutation, UndeleteUserMutationVariables> mutation={UndeleteUserDocument} {...props} />
    );
    
export type UndeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UndeleteUserMutation, UndeleteUserMutationVariables>
    } & TChildProps;
export function withUndeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UndeleteUserMutation,
  UndeleteUserMutationVariables,
  UndeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UndeleteUserMutation, UndeleteUserMutationVariables, UndeleteUserProps<TChildProps, TDataName>>(UndeleteUserDocument, {
      alias: 'undeleteUser',
      ...operationOptions
    });
};

/**
 * __useUndeleteUserMutation__
 *
 * To run a mutation, you first call `useUndeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteUserMutation, { data, loading, error }] = useUndeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndeleteUserMutation, UndeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UndeleteUserMutation, UndeleteUserMutationVariables>(UndeleteUserDocument, baseOptions);
      }
export type UndeleteUserMutationHookResult = ReturnType<typeof useUndeleteUserMutation>;
export type UndeleteUserMutationResult = ApolloReactCommon.MutationResult<UndeleteUserMutation>;
export type UndeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UndeleteUserMutation, UndeleteUserMutationVariables>;
export const CreateInviteDocument = gql`
    mutation CreateInvite($entityName: String!, $email: String, $username: String, $admin: Boolean, $addSeat: Boolean) {
  createInvite(input: {entityName: $entityName, email: $email, username: $username, admin: $admin, addSeat: $addSeat}) {
    invite {
      id
      name
      email
      createdAt
      toUser {
        name
      }
    }
    success
    remainingSeats
  }
}
    `;
export type CreateInviteMutationFn = ApolloReactCommon.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;
export type CreateInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInviteMutation, CreateInviteMutationVariables>, 'mutation'>;

    export const CreateInviteComponent = (props: CreateInviteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInviteMutation, CreateInviteMutationVariables> mutation={CreateInviteDocument} {...props} />
    );
    
export type CreateInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>
    } & TChildProps;
export function withCreateInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInviteMutation,
  CreateInviteMutationVariables,
  CreateInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInviteMutation, CreateInviteMutationVariables, CreateInviteProps<TChildProps, TDataName>>(CreateInviteDocument, {
      alias: 'createInvite',
      ...operationOptions
    });
};

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      admin: // value for 'admin'
 *      addSeat: // value for 'addSeat'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, baseOptions);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = ApolloReactCommon.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const DeleteInviteDocument = gql`
    mutation DeleteInvite($id: String, $entityName: String) {
  deleteInvite(input: {id: $id, entityName: $entityName}) {
    success
  }
}
    `;
export type DeleteInviteMutationFn = ApolloReactCommon.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>;
export type DeleteInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInviteMutation, DeleteInviteMutationVariables>, 'mutation'>;

    export const DeleteInviteComponent = (props: DeleteInviteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInviteMutation, DeleteInviteMutationVariables> mutation={DeleteInviteDocument} {...props} />
    );
    
export type DeleteInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>
    } & TChildProps;
export function withDeleteInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInviteMutation,
  DeleteInviteMutationVariables,
  DeleteInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInviteMutation, DeleteInviteMutationVariables, DeleteInviteProps<TChildProps, TDataName>>(DeleteInviteDocument, {
      alias: 'deleteInvite',
      ...operationOptions
    });
};

/**
 * __useDeleteInviteMutation__
 *
 * To run a mutation, you first call `useDeleteInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteMutation, { data, loading, error }] = useDeleteInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useDeleteInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInviteMutation, DeleteInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument, baseOptions);
      }
export type DeleteInviteMutationHookResult = ReturnType<typeof useDeleteInviteMutation>;
export type DeleteInviteMutationResult = ApolloReactCommon.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInviteMutation, DeleteInviteMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($entityName: String!, $user: ID!, $admin: Boolean!) {
  updateMember(input: {entityName: $entityName, user: $user, admin: $admin}) {
    member {
      id
      admin
    }
  }
}
    `;
export type UpdateMemberMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;
export type UpdateMemberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMemberMutation, UpdateMemberMutationVariables>, 'mutation'>;

    export const UpdateMemberComponent = (props: UpdateMemberComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMemberMutation, UpdateMemberMutationVariables> mutation={UpdateMemberDocument} {...props} />
    );
    
export type UpdateMemberProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>
    } & TChildProps;
export function withUpdateMember<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
  UpdateMemberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateMemberMutation, UpdateMemberMutationVariables, UpdateMemberProps<TChildProps, TDataName>>(UpdateMemberDocument, {
      alias: 'updateMember',
      ...operationOptions
    });
};

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      user: // value for 'user'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = ApolloReactCommon.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const CreateServiceAccountDocument = gql`
    mutation CreateServiceAccount($entityName: String!, $description: String!) {
  createServiceAccount(input: {description: $description, entityName: $entityName}) {
    user {
      id
      name
      apiKeys {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type CreateServiceAccountMutationFn = ApolloReactCommon.MutationFunction<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;
export type CreateServiceAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>, 'mutation'>;

    export const CreateServiceAccountComponent = (props: CreateServiceAccountComponentProps) => (
      <ApolloReactComponents.Mutation<CreateServiceAccountMutation, CreateServiceAccountMutationVariables> mutation={CreateServiceAccountDocument} {...props} />
    );
    
export type CreateServiceAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>
    } & TChildProps;
export function withCreateServiceAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateServiceAccountMutation,
  CreateServiceAccountMutationVariables,
  CreateServiceAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateServiceAccountMutation, CreateServiceAccountMutationVariables, CreateServiceAccountProps<TChildProps, TDataName>>(CreateServiceAccountDocument, {
      alias: 'createServiceAccount',
      ...operationOptions
    });
};

/**
 * __useCreateServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAccountMutation, { data, loading, error }] = useCreateServiceAccountMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateServiceAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>(CreateServiceAccountDocument, baseOptions);
      }
export type CreateServiceAccountMutationHookResult = ReturnType<typeof useCreateServiceAccountMutation>;
export type CreateServiceAccountMutationResult = ApolloReactCommon.MutationResult<CreateServiceAccountMutation>;
export type CreateServiceAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;
export const CreateEntityDocument = gql`
    mutation CreateEntity($name: String!, $invited: String, $framework: String) {
  createEntity(input: {name: $name, defaultFramework: $framework, invited: $invited}) {
    entity {
      id
      name
      photoUrl
      invitedTeam
      projects(first: 100) {
        edges {
          node {
            id
            name
            entityName
          }
        }
      }
    }
    apiKey {
      id
      name
    }
  }
}
    `;
export type CreateEntityMutationFn = ApolloReactCommon.MutationFunction<CreateEntityMutation, CreateEntityMutationVariables>;
export type CreateEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEntityMutation, CreateEntityMutationVariables>, 'mutation'>;

    export const CreateEntityComponent = (props: CreateEntityComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEntityMutation, CreateEntityMutationVariables> mutation={CreateEntityDocument} {...props} />
    );
    
export type CreateEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEntityMutation, CreateEntityMutationVariables>
    } & TChildProps;
export function withCreateEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEntityMutation,
  CreateEntityMutationVariables,
  CreateEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEntityMutation, CreateEntityMutationVariables, CreateEntityProps<TChildProps, TDataName>>(CreateEntityDocument, {
      alias: 'createEntity',
      ...operationOptions
    });
};

/**
 * __useCreateEntityMutation__
 *
 * To run a mutation, you first call `useCreateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityMutation, { data, loading, error }] = useCreateEntityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      invited: // value for 'invited'
 *      framework: // value for 'framework'
 *   },
 * });
 */
export function useCreateEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityMutation, CreateEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityMutation, CreateEntityMutationVariables>(CreateEntityDocument, baseOptions);
      }
export type CreateEntityMutationHookResult = ReturnType<typeof useCreateEntityMutation>;
export type CreateEntityMutationResult = ApolloReactCommon.MutationResult<CreateEntityMutation>;
export type CreateEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityMutation, CreateEntityMutationVariables>;
export const CreateUserFromAdminDocument = gql`
    mutation CreateUserFromAdmin($email: String!, $admin: Boolean) {
  createUser(input: {email: $email, admin: $admin}) {
    user {
      id
      name
      username
      email
      admin
    }
  }
}
    `;
export type CreateUserFromAdminMutationFn = ApolloReactCommon.MutationFunction<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>;
export type CreateUserFromAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>, 'mutation'>;

    export const CreateUserFromAdminComponent = (props: CreateUserFromAdminComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables> mutation={CreateUserFromAdminDocument} {...props} />
    );
    
export type CreateUserFromAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>
    } & TChildProps;
export function withCreateUserFromAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserFromAdminMutation,
  CreateUserFromAdminMutationVariables,
  CreateUserFromAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables, CreateUserFromAdminProps<TChildProps, TDataName>>(CreateUserFromAdminDocument, {
      alias: 'createUserFromAdmin',
      ...operationOptions
    });
};

/**
 * __useCreateUserFromAdminMutation__
 *
 * To run a mutation, you first call `useCreateUserFromAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromAdminMutation, { data, loading, error }] = useCreateUserFromAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useCreateUserFromAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>(CreateUserFromAdminDocument, baseOptions);
      }
export type CreateUserFromAdminMutationHookResult = ReturnType<typeof useCreateUserFromAdminMutation>;
export type CreateUserFromAdminMutationResult = ApolloReactCommon.MutationResult<CreateUserFromAdminMutation>;
export type CreateUserFromAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($defaultEntity: String, $defaultFramework: String, $photoUrl: String, $code: String, $settingsVisited: Boolean, $galleryVisited: Boolean, $onboardingHidden: Boolean) {
  updateUser(input: {defaultEntity: $defaultEntity, defaultFramework: $defaultFramework, photoUrl: $photoUrl, code: $code, settingsVisited: $settingsVisited, galleryVisited: $galleryVisited, onboardingHidden: $onboardingHidden}) {
    user {
      id
      flags
      entity
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>
    } & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      defaultEntity: // value for 'defaultEntity'
 *      defaultFramework: // value for 'defaultFramework'
 *      photoUrl: // value for 'photoUrl'
 *      code: // value for 'code'
 *      settingsVisited: // value for 'settingsVisited'
 *      galleryVisited: // value for 'galleryVisited'
 *      onboardingHidden: // value for 'onboardingHidden'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($name: String, $photoUrl: String, $userInfo: JSONString, $defaultEntity: String) {
  updateUser(input: {name: $name, userInfo: $userInfo, defaultEntity: $defaultEntity, photoUrl: $photoUrl}) {
    user {
      id
      name
      userInfo
      photoUrl
      entity
    }
  }
}
    `;
export type UpdateUserInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export type UpdateUserInfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>, 'mutation'>;

    export const UpdateUserInfoComponent = (props: UpdateUserInfoComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables> mutation={UpdateUserInfoDocument} {...props} />
    );
    
export type UpdateUserInfoProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>
    } & TChildProps;
export function withUpdateUserInfo<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables,
  UpdateUserInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserInfoMutation, UpdateUserInfoMutationVariables, UpdateUserInfoProps<TChildProps, TDataName>>(UpdateUserInfoDocument, {
      alias: 'updateUserInfo',
      ...operationOptions
    });
};

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      photoUrl: // value for 'photoUrl'
 *      userInfo: // value for 'userInfo'
 *      defaultEntity: // value for 'defaultEntity'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, baseOptions);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = ApolloReactCommon.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const RedeemHubAccessCodeDocument = gql`
    mutation RedeemHubAccessCode($code: String) {
  updateUser(input: {code: $code}) {
    user {
      id
      flags
    }
  }
}
    `;
export type RedeemHubAccessCodeMutationFn = ApolloReactCommon.MutationFunction<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>;
export type RedeemHubAccessCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>, 'mutation'>;

    export const RedeemHubAccessCodeComponent = (props: RedeemHubAccessCodeComponentProps) => (
      <ApolloReactComponents.Mutation<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables> mutation={RedeemHubAccessCodeDocument} {...props} />
    );
    
export type RedeemHubAccessCodeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>
    } & TChildProps;
export function withRedeemHubAccessCode<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RedeemHubAccessCodeMutation,
  RedeemHubAccessCodeMutationVariables,
  RedeemHubAccessCodeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables, RedeemHubAccessCodeProps<TChildProps, TDataName>>(RedeemHubAccessCodeDocument, {
      alias: 'redeemHubAccessCode',
      ...operationOptions
    });
};

/**
 * __useRedeemHubAccessCodeMutation__
 *
 * To run a mutation, you first call `useRedeemHubAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemHubAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemHubAccessCodeMutation, { data, loading, error }] = useRedeemHubAccessCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemHubAccessCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>(RedeemHubAccessCodeDocument, baseOptions);
      }
export type RedeemHubAccessCodeMutationHookResult = ReturnType<typeof useRedeemHubAccessCodeMutation>;
export type RedeemHubAccessCodeMutationResult = ApolloReactCommon.MutationResult<RedeemHubAccessCodeMutation>;
export type RedeemHubAccessCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<RedeemHubAccessCodeMutation, RedeemHubAccessCodeMutationVariables>;
export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey($description: String) {
  generateApiKey(input: {description: $description}) {
    apiKey {
      id
      name
    }
  }
}
    `;
export type GenerateApiKeyMutationFn = ApolloReactCommon.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export type GenerateApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>, 'mutation'>;

    export const GenerateApiKeyComponent = (props: GenerateApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables> mutation={GenerateApiKeyDocument} {...props} />
    );
    
export type GenerateApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>
    } & TChildProps;
export function withGenerateApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateApiKeyMutation,
  GenerateApiKeyMutationVariables,
  GenerateApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateApiKeyMutation, GenerateApiKeyMutationVariables, GenerateApiKeyProps<TChildProps, TDataName>>(GenerateApiKeyDocument, {
      alias: 'generateApiKey',
      ...operationOptions
    });
};

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, baseOptions);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = ApolloReactCommon.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($id: String!) {
  deleteApiKey(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteApiKeyMutationFn = ApolloReactCommon.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export type DeleteApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>, 'mutation'>;

    export const DeleteApiKeyComponent = (props: DeleteApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables> mutation={DeleteApiKeyDocument} {...props} />
    );
    
export type DeleteApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>
    } & TChildProps;
export function withDeleteApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables,
  DeleteApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteApiKeyMutation, DeleteApiKeyMutationVariables, DeleteApiKeyProps<TChildProps, TDataName>>(DeleteApiKeyDocument, {
      alias: 'deleteApiKey',
      ...operationOptions
    });
};

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, baseOptions);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = ApolloReactCommon.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const UpdateEntityDocument = gql`
    mutation UpdateEntity($entityName: String!, $defaultAccess: String, $privateOnly: Boolean, $codeSavingEnabled: Boolean, $settings: SettingsInput) {
  updateEntity(input: {entity: $entityName, defaultAccess: $defaultAccess, privateOnly: $privateOnly, codeSavingEnabled: $codeSavingEnabled, settings: $settings}) {
    entity {
      id
      defaultAccess
      privateOnly
      limits
      settings {
        defaultCloudRegion {
          id
          provider
          region
        }
      }
    }
  }
}
    `;
export type UpdateEntityMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>;
export type UpdateEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityMutation, UpdateEntityMutationVariables>, 'mutation'>;

    export const UpdateEntityComponent = (props: UpdateEntityComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityMutation, UpdateEntityMutationVariables> mutation={UpdateEntityDocument} {...props} />
    );
    
export type UpdateEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>
    } & TChildProps;
export function withUpdateEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityMutation,
  UpdateEntityMutationVariables,
  UpdateEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityMutation, UpdateEntityMutationVariables, UpdateEntityProps<TChildProps, TDataName>>(UpdateEntityDocument, {
      alias: 'updateEntity',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      defaultAccess: // value for 'defaultAccess'
 *      privateOnly: // value for 'privateOnly'
 *      codeSavingEnabled: // value for 'codeSavingEnabled'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityMutation, UpdateEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument, baseOptions);
      }
export type UpdateEntityMutationHookResult = ReturnType<typeof useUpdateEntityMutation>;
export type UpdateEntityMutationResult = ApolloReactCommon.MutationResult<UpdateEntityMutation>;
export type UpdateEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityMutation, UpdateEntityMutationVariables>;
export const ClaimAnonymousEntityDocument = gql`
    mutation ClaimAnonymousEntity($anonymousApiKey: String!) {
  claimAnonymousEntity(input: {anonymousApiKey: $anonymousApiKey}) {
    task {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type ClaimAnonymousEntityMutationFn = ApolloReactCommon.MutationFunction<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>;
export type ClaimAnonymousEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>, 'mutation'>;

    export const ClaimAnonymousEntityComponent = (props: ClaimAnonymousEntityComponentProps) => (
      <ApolloReactComponents.Mutation<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables> mutation={ClaimAnonymousEntityDocument} {...props} />
    );
    
export type ClaimAnonymousEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>
    } & TChildProps;
export function withClaimAnonymousEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ClaimAnonymousEntityMutation,
  ClaimAnonymousEntityMutationVariables,
  ClaimAnonymousEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables, ClaimAnonymousEntityProps<TChildProps, TDataName>>(ClaimAnonymousEntityDocument, {
      alias: 'claimAnonymousEntity',
      ...operationOptions
    });
};

/**
 * __useClaimAnonymousEntityMutation__
 *
 * To run a mutation, you first call `useClaimAnonymousEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAnonymousEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAnonymousEntityMutation, { data, loading, error }] = useClaimAnonymousEntityMutation({
 *   variables: {
 *      anonymousApiKey: // value for 'anonymousApiKey'
 *   },
 * });
 */
export function useClaimAnonymousEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>(ClaimAnonymousEntityDocument, baseOptions);
      }
export type ClaimAnonymousEntityMutationHookResult = ReturnType<typeof useClaimAnonymousEntityMutation>;
export type ClaimAnonymousEntityMutationResult = ApolloReactCommon.MutationResult<ClaimAnonymousEntityMutation>;
export type ClaimAnonymousEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(input: {email: $email}) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    
export type ResetPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>
    } & TChildProps;
export function withResetPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResetPasswordMutation, ResetPasswordMutationVariables, ResetPasswordProps<TChildProps, TDataName>>(ResetPasswordDocument, {
      alias: 'resetPassword',
      ...operationOptions
    });
};

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ViewerDocument = gql`
    query Viewer($includeStorage: Boolean = false) {
  viewer {
    id
    authId
    admin
    email
    entity
    defaultFramework
    photoUrl
    flags
    code
    username
    createdAt
    name
    accountType
    userInfo
    limits
    signupRequired
    teams(first: 100) {
      edges {
        node {
          id
          name
          photoUrl
          defaultAccess
          readOnlyAdmin
          privateOnly
          isTeam
          organizationId
        }
      }
    }
    organizations {
      id
      name
      orgType
    }
    apiKeys {
      edges {
        node {
          id
          name
        }
      }
    }
    defaultEntity {
      id
      name
    }
    userEntity {
      id
      name
      defaultAccess
      codeSavingEnabled
      storageBytes @include(if: $includeStorage)
      settings {
        defaultCloudRegion {
          id
          provider
          region
        }
      }
      isTeam
      defaultAlerts {
        id
        condition {
          __typename
          ... on FinishedRunCondition {
            success
          }
          ... on StoppedRunCondition {
            minimumRunDuration
          }
        }
        subscriptions {
          __typename
          ... on EmailSubscription {
            id
          }
          ... on SlackChannelSubscription {
            id
          }
        }
      }
      integrations {
        edges {
          node {
            id
            __typename
            ... on SlackIntegration {
              teamName
              channelName
            }
          }
        }
      }
      claimedEntities {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type ViewerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerQuery, ViewerQueryVariables>, 'query'>;

    export const ViewerComponent = (props: ViewerComponentProps) => (
      <ApolloReactComponents.Query<ViewerQuery, ViewerQueryVariables> query={ViewerDocument} {...props} />
    );
    
export type ViewerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerQuery, ViewerQueryVariables>
    } & TChildProps;
export function withViewer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerQuery,
  ViewerQueryVariables,
  ViewerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerQuery, ViewerQueryVariables, ViewerProps<TChildProps, TDataName>>(ViewerDocument, {
      alias: 'viewer',
      ...operationOptions
    });
};

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *      includeStorage: // value for 'includeStorage'
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
      }
export function useViewerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = ApolloReactCommon.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const ViewerProjectsDocument = gql`
    query ViewerProjects($entityName: String, $name: String) {
  viewer {
    id
    entity
    photoUrl
    username
    createdAt
    linkedProjects: projects(first: 100, linkedBenchmark: {EntityName: $entityName, Name: $name}) {
      edges {
        node {
          id
          name
          entityName
          runs(order: "createdAt", first: 50) {
            edges {
              node {
                id
                name
                notes
                summaryMetrics
                project {
                  id
                  entityName
                  name
                }
                displayName
                createdAt
                benchmarkRun {
                  id
                  results
                  originalRun {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects(first: 100) {
      edges {
        node {
          id
          name
          entityName
        }
      }
    }
  }
}
    `;
export type ViewerProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>, 'query'>;

    export const ViewerProjectsComponent = (props: ViewerProjectsComponentProps) => (
      <ApolloReactComponents.Query<ViewerProjectsQuery, ViewerProjectsQueryVariables> query={ViewerProjectsDocument} {...props} />
    );
    
export type ViewerProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerProjectsQuery, ViewerProjectsQueryVariables>
    } & TChildProps;
export function withViewerProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerProjectsQuery,
  ViewerProjectsQueryVariables,
  ViewerProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerProjectsQuery, ViewerProjectsQueryVariables, ViewerProjectsProps<TChildProps, TDataName>>(ViewerProjectsDocument, {
      alias: 'viewerProjects',
      ...operationOptions
    });
};

/**
 * __useViewerProjectsQuery__
 *
 * To run a query within a React component, call `useViewerProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useViewerProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerProjectsQuery, ViewerProjectsQueryVariables>(ViewerProjectsDocument, baseOptions);
      }
export function useViewerProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerProjectsQuery, ViewerProjectsQueryVariables>(ViewerProjectsDocument, baseOptions);
        }
export type ViewerProjectsQueryHookResult = ReturnType<typeof useViewerProjectsQuery>;
export type ViewerProjectsLazyQueryHookResult = ReturnType<typeof useViewerProjectsLazyQuery>;
export type ViewerProjectsQueryResult = ApolloReactCommon.QueryResult<ViewerProjectsQuery, ViewerProjectsQueryVariables>;
export const ViewerQueryDocument = gql`
    query ViewerQuery {
  viewer {
    id
    admin
    email
    entity
    defaultFramework
    photoUrl
    userInfo
    flags
    code
    username
    createdAt
    signupRequired
    teams(first: 100) {
      edges {
        node {
          id
          isPaid
          name
          photoUrl
        }
      }
    }
    organizations {
      id
      name
      subscriptions {
        plan {
          id
          name
          maxSeats
          planType
        }
        seats
      }
    }
    apiKeys {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type ViewerQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerQueryQuery, ViewerQueryQueryVariables>, 'query'>;

    export const ViewerQueryComponent = (props: ViewerQueryComponentProps) => (
      <ApolloReactComponents.Query<ViewerQueryQuery, ViewerQueryQueryVariables> query={ViewerQueryDocument} {...props} />
    );
    
export type ViewerQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerQueryQuery, ViewerQueryQueryVariables>
    } & TChildProps;
export function withViewerQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerQueryQuery,
  ViewerQueryQueryVariables,
  ViewerQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerQueryQuery, ViewerQueryQueryVariables, ViewerQueryProps<TChildProps, TDataName>>(ViewerQueryDocument, {
      alias: 'viewerQuery',
      ...operationOptions
    });
};

/**
 * __useViewerQueryQuery__
 *
 * To run a query within a React component, call `useViewerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerQueryQuery, ViewerQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerQueryQuery, ViewerQueryQueryVariables>(ViewerQueryDocument, baseOptions);
      }
export function useViewerQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerQueryQuery, ViewerQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerQueryQuery, ViewerQueryQueryVariables>(ViewerQueryDocument, baseOptions);
        }
export type ViewerQueryQueryHookResult = ReturnType<typeof useViewerQueryQuery>;
export type ViewerQueryLazyQueryHookResult = ReturnType<typeof useViewerQueryLazyQuery>;
export type ViewerQueryQueryResult = ApolloReactCommon.QueryResult<ViewerQueryQuery, ViewerQueryQueryVariables>;
export const ViewsDocument = gql`
    query Views($entityName: String, $name: String, $viewType: String = "runs", $userName: String, $viewName: String) {
  project(name: $name, entityName: $entityName) {
    id
    views
    totalRunsWithGroup
    totalRunsWithJobType
    allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
      edges {
        node {
          ...ViewFragment
        }
      }
    }
    projectSettings: allViews(viewType: "project-settings", viewName: "project-settings", first: 1) {
      edges {
        node {
          ...ViewFragment
        }
      }
    }
  }
}
    ${ViewFragmentFragmentDoc}`;
export type ViewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewsQuery, ViewsQueryVariables>, 'query'>;

    export const ViewsComponent = (props: ViewsComponentProps) => (
      <ApolloReactComponents.Query<ViewsQuery, ViewsQueryVariables> query={ViewsDocument} {...props} />
    );
    
export type ViewsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewsQuery, ViewsQueryVariables>
    } & TChildProps;
export function withViews<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewsQuery,
  ViewsQueryVariables,
  ViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewsQuery, ViewsQueryVariables, ViewsProps<TChildProps, TDataName>>(ViewsDocument, {
      alias: 'views',
      ...operationOptions
    });
};

/**
 * __useViewsQuery__
 *
 * To run a query within a React component, call `useViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      viewType: // value for 'viewType'
 *      userName: // value for 'userName'
 *      viewName: // value for 'viewName'
 *   },
 * });
 */
export function useViewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewsQuery, ViewsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewsQuery, ViewsQueryVariables>(ViewsDocument, baseOptions);
      }
export function useViewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewsQuery, ViewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewsQuery, ViewsQueryVariables>(ViewsDocument, baseOptions);
        }
export type ViewsQueryHookResult = ReturnType<typeof useViewsQuery>;
export type ViewsLazyQueryHookResult = ReturnType<typeof useViewsLazyQuery>;
export type ViewsQueryResult = ApolloReactCommon.QueryResult<ViewsQuery, ViewsQueryVariables>;
export const ViewsMetadataDocument = gql`
    query ViewsMetadata($entityName: String, $name: String, $viewType: String = "runs", $userName: String, $viewName: String) {
  project(name: $name, entityName: $entityName) {
    id
    views
    totalRunsWithGroup
    totalRunsWithJobType
    allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
      edges {
        node {
          ...ViewFragmentMetadata
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type ViewsMetadataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewsMetadataQuery, ViewsMetadataQueryVariables>, 'query'>;

    export const ViewsMetadataComponent = (props: ViewsMetadataComponentProps) => (
      <ApolloReactComponents.Query<ViewsMetadataQuery, ViewsMetadataQueryVariables> query={ViewsMetadataDocument} {...props} />
    );
    
export type ViewsMetadataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewsMetadataQuery, ViewsMetadataQueryVariables>
    } & TChildProps;
export function withViewsMetadata<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewsMetadataQuery,
  ViewsMetadataQueryVariables,
  ViewsMetadataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewsMetadataQuery, ViewsMetadataQueryVariables, ViewsMetadataProps<TChildProps, TDataName>>(ViewsMetadataDocument, {
      alias: 'viewsMetadata',
      ...operationOptions
    });
};

/**
 * __useViewsMetadataQuery__
 *
 * To run a query within a React component, call `useViewsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewsMetadataQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      viewType: // value for 'viewType'
 *      userName: // value for 'userName'
 *      viewName: // value for 'viewName'
 *   },
 * });
 */
export function useViewsMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewsMetadataQuery, ViewsMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewsMetadataQuery, ViewsMetadataQueryVariables>(ViewsMetadataDocument, baseOptions);
      }
export function useViewsMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewsMetadataQuery, ViewsMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewsMetadataQuery, ViewsMetadataQueryVariables>(ViewsMetadataDocument, baseOptions);
        }
export type ViewsMetadataQueryHookResult = ReturnType<typeof useViewsMetadataQuery>;
export type ViewsMetadataLazyQueryHookResult = ReturnType<typeof useViewsMetadataLazyQuery>;
export type ViewsMetadataQueryResult = ApolloReactCommon.QueryResult<ViewsMetadataQuery, ViewsMetadataQueryVariables>;
export const UpsertViewDocument = gql`
    mutation upsertView($id: ID, $entityName: String, $projectName: String, $type: String, $name: String, $description: String, $spec: String!) {
  upsertView(input: {id: $id, entityName: $entityName, projectName: $projectName, name: $name, description: $description, type: $type, spec: $spec}) {
    view {
      ...ViewFragment
    }
    inserted
  }
}
    ${ViewFragmentFragmentDoc}`;
export type UpsertViewMutationFn = ApolloReactCommon.MutationFunction<UpsertViewMutation, UpsertViewMutationVariables>;
export type UpsertViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertViewMutation, UpsertViewMutationVariables>, 'mutation'>;

    export const UpsertViewComponent = (props: UpsertViewComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertViewMutation, UpsertViewMutationVariables> mutation={UpsertViewDocument} {...props} />
    );
    
export type UpsertViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertViewMutation, UpsertViewMutationVariables>
    } & TChildProps;
export function withUpsertView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertViewMutation,
  UpsertViewMutationVariables,
  UpsertViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertViewMutation, UpsertViewMutationVariables, UpsertViewProps<TChildProps, TDataName>>(UpsertViewDocument, {
      alias: 'upsertView',
      ...operationOptions
    });
};

/**
 * __useUpsertViewMutation__
 *
 * To run a mutation, you first call `useUpsertViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertViewMutation, { data, loading, error }] = useUpsertViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useUpsertViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertViewMutation, UpsertViewMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertViewMutation, UpsertViewMutationVariables>(UpsertViewDocument, baseOptions);
      }
export type UpsertViewMutationHookResult = ReturnType<typeof useUpsertViewMutation>;
export type UpsertViewMutationResult = ApolloReactCommon.MutationResult<UpsertViewMutation>;
export type UpsertViewMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertViewMutation, UpsertViewMutationVariables>;
export const DeleteViewDocument = gql`
    mutation deleteView($id: ID) {
  deleteView(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteViewMutationFn = ApolloReactCommon.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>;
export type DeleteViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteViewMutation, DeleteViewMutationVariables>, 'mutation'>;

    export const DeleteViewComponent = (props: DeleteViewComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteViewMutation, DeleteViewMutationVariables> mutation={DeleteViewDocument} {...props} />
    );
    
export type DeleteViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>
    } & TChildProps;
export function withDeleteView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteViewMutation,
  DeleteViewMutationVariables,
  DeleteViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteViewMutation, DeleteViewMutationVariables, DeleteViewProps<TChildProps, TDataName>>(DeleteViewDocument, {
      alias: 'deleteView',
      ...operationOptions
    });
};

/**
 * __useDeleteViewMutation__
 *
 * To run a mutation, you first call `useDeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewMutation, { data, loading, error }] = useDeleteViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteViewMutation, DeleteViewMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteViewMutation, DeleteViewMutationVariables>(DeleteViewDocument, baseOptions);
      }
export type DeleteViewMutationHookResult = ReturnType<typeof useDeleteViewMutation>;
export type DeleteViewMutationResult = ApolloReactCommon.MutationResult<DeleteViewMutation>;
export type DeleteViewMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteViewMutation, DeleteViewMutationVariables>;
export const CreateSharedViewDocument = gql`
    mutation createSharedView($entityName: String!, $name: String!, $type: String!, $description: String, $spec: String!) {
  upsertSharedView(input: {entityName: $entityName, name: $name, type: $type, description: $description, spec: $spec}) {
    view {
      ...ViewFragment
    }
  }
}
    ${ViewFragmentFragmentDoc}`;
export type CreateSharedViewMutationFn = ApolloReactCommon.MutationFunction<CreateSharedViewMutation, CreateSharedViewMutationVariables>;
export type CreateSharedViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>, 'mutation'>;

    export const CreateSharedViewComponent = (props: CreateSharedViewComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedViewMutation, CreateSharedViewMutationVariables> mutation={CreateSharedViewDocument} {...props} />
    );
    
export type CreateSharedViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSharedViewMutation, CreateSharedViewMutationVariables>
    } & TChildProps;
export function withCreateSharedView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSharedViewMutation,
  CreateSharedViewMutationVariables,
  CreateSharedViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSharedViewMutation, CreateSharedViewMutationVariables, CreateSharedViewProps<TChildProps, TDataName>>(CreateSharedViewDocument, {
      alias: 'createSharedView',
      ...operationOptions
    });
};

/**
 * __useCreateSharedViewMutation__
 *
 * To run a mutation, you first call `useCreateSharedViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedViewMutation, { data, loading, error }] = useCreateSharedViewMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateSharedViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedViewMutation, CreateSharedViewMutationVariables>(CreateSharedViewDocument, baseOptions);
      }
export type CreateSharedViewMutationHookResult = ReturnType<typeof useCreateSharedViewMutation>;
export type CreateSharedViewMutationResult = ApolloReactCommon.MutationResult<CreateSharedViewMutation>;
export type CreateSharedViewMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>;
export const CreateAccessTokenDocument = gql`
    mutation createAccessToken($projects: [ProjectSpecifier!]!, $viewId: ID!, $clientMutationId: String) {
  createAccessToken(input: {projects: $projects, viewId: $viewId, clientMutationId: $clientMutationId}) {
    accessToken {
      id
      token
      view {
        id
      }
      type
      emails
      createdBy {
        id
        username
        email
        name
      }
      createdAt
      lastAccessedAt
      revokedAt
      projects {
        id
        name
        entityName
      }
    }
    clientMutationId
  }
}
    `;
export type CreateAccessTokenMutationFn = ApolloReactCommon.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export type CreateAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>, 'mutation'>;

    export const CreateAccessTokenComponent = (props: CreateAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables> mutation={CreateAccessTokenDocument} {...props} />
    );
    
export type CreateAccessTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>
    } & TChildProps;
export function withCreateAccessToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccessTokenMutation,
  CreateAccessTokenMutationVariables,
  CreateAccessTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccessTokenMutation, CreateAccessTokenMutationVariables, CreateAccessTokenProps<TChildProps, TDataName>>(CreateAccessTokenDocument, {
      alias: 'createAccessToken',
      ...operationOptions
    });
};

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      projects: // value for 'projects'
 *      viewId: // value for 'viewId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, baseOptions);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = ApolloReactCommon.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export const RevokeAccessTokenDocument = gql`
    mutation revokeAccessToken($token: String!, $clientMutationId: String) {
  revokeAccessToken(input: {token: $token, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type RevokeAccessTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;
export type RevokeAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>, 'mutation'>;

    export const RevokeAccessTokenComponent = (props: RevokeAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables> mutation={RevokeAccessTokenDocument} {...props} />
    );
    
export type RevokeAccessTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>
    } & TChildProps;
export function withRevokeAccessToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeAccessTokenMutation,
  RevokeAccessTokenMutationVariables,
  RevokeAccessTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables, RevokeAccessTokenProps<TChildProps, TDataName>>(RevokeAccessTokenDocument, {
      alias: 'revokeAccessToken',
      ...operationOptions
    });
};

/**
 * __useRevokeAccessTokenMutation__
 *
 * To run a mutation, you first call `useRevokeAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessTokenMutation, { data, loading, error }] = useRevokeAccessTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRevokeAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>(RevokeAccessTokenDocument, baseOptions);
      }
export type RevokeAccessTokenMutationHookResult = ReturnType<typeof useRevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationResult = ApolloReactCommon.MutationResult<RevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;
export const UpdateAccessTokenProjectsDocument = gql`
    mutation updateAccessTokenProjects($token: String!, $projects: [ProjectSpecifier!]!, $clientMutationId: String) {
  updateAccessTokenProjects(input: {token: $token, projects: $projects, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type UpdateAccessTokenProjectsMutationFn = ApolloReactCommon.MutationFunction<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>;
export type UpdateAccessTokenProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>, 'mutation'>;

    export const UpdateAccessTokenProjectsComponent = (props: UpdateAccessTokenProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables> mutation={UpdateAccessTokenProjectsDocument} {...props} />
    );
    
export type UpdateAccessTokenProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>
    } & TChildProps;
export function withUpdateAccessTokenProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAccessTokenProjectsMutation,
  UpdateAccessTokenProjectsMutationVariables,
  UpdateAccessTokenProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables, UpdateAccessTokenProjectsProps<TChildProps, TDataName>>(UpdateAccessTokenProjectsDocument, {
      alias: 'updateAccessTokenProjects',
      ...operationOptions
    });
};

/**
 * __useUpdateAccessTokenProjectsMutation__
 *
 * To run a mutation, you first call `useUpdateAccessTokenProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessTokenProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessTokenProjectsMutation, { data, loading, error }] = useUpdateAccessTokenProjectsMutation({
 *   variables: {
 *      token: // value for 'token'
 *      projects: // value for 'projects'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateAccessTokenProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>(UpdateAccessTokenProjectsDocument, baseOptions);
      }
export type UpdateAccessTokenProjectsMutationHookResult = ReturnType<typeof useUpdateAccessTokenProjectsMutation>;
export type UpdateAccessTokenProjectsMutationResult = ApolloReactCommon.MutationResult<UpdateAccessTokenProjectsMutation>;
export type UpdateAccessTokenProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>;
export const ArtifactCollectionDocument = gql`
    query ArtifactCollection($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactCollectionName: String!, $artifactCursor: String, $artifactFirst: Int) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      description
      artifactSequence(name: $artifactCollectionName) {
        __typename
        id
        name
        description
        artifacts(after: $artifactCursor, first: $artifactFirst) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              digest
              commitHash
              state
              createdAt
              aliases {
                artifactCollectionName
                alias
              }
            }
          }
        }
        aliases {
          edges {
            node {
              alias
              artifact {
                id
                versionIndex
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactCollectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>, 'query'> & ({ variables: ArtifactCollectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionComponent = (props: ArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionQuery, ArtifactCollectionQueryVariables> query={ArtifactCollectionDocument} {...props} />
    );
    
export type ArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>
    } & TChildProps;
export function withArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionQuery,
  ArtifactCollectionQueryVariables,
  ArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionQuery, ArtifactCollectionQueryVariables, ArtifactCollectionProps<TChildProps, TDataName>>(ArtifactCollectionDocument, {
      alias: 'artifactCollection',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      artifactCursor: // value for 'artifactCursor'
 *      artifactFirst: // value for 'artifactFirst'
 *   },
 * });
 */
export function useArtifactCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>(ArtifactCollectionDocument, baseOptions);
      }
export function useArtifactCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>(ArtifactCollectionDocument, baseOptions);
        }
export type ArtifactCollectionQueryHookResult = ReturnType<typeof useArtifactCollectionQuery>;
export type ArtifactCollectionLazyQueryHookResult = ReturnType<typeof useArtifactCollectionLazyQuery>;
export type ArtifactCollectionQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>;
export const ArtifactDagDocument = gql`
    query ArtifactDAG($projectName: String!, $entityName: String!, $artifactTypeName: String!, $artifactName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      description
      artifact(name: $artifactName) {
        id
        artifactSequence {
          id
          name
        }
        commitHash
        versionIndex
        createdBy {
          __typename
          ... on User {
            ...ArtifactDagUserFragment
          }
          ... on Run {
            ...ArtifactDagRunFragment
          }
        }
        usedBy {
          edges {
            node {
              ...ArtifactDagRunFragment
            }
          }
        }
      }
    }
  }
}
    ${ArtifactDagUserFragmentFragmentDoc}
${ArtifactDagRunFragmentFragmentDoc}`;
export type ArtifactDagComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactDagQuery, ArtifactDagQueryVariables>, 'query'> & ({ variables: ArtifactDagQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactDagComponent = (props: ArtifactDagComponentProps) => (
      <ApolloReactComponents.Query<ArtifactDagQuery, ArtifactDagQueryVariables> query={ArtifactDagDocument} {...props} />
    );
    
export type ArtifactDagProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactDagQuery, ArtifactDagQueryVariables>
    } & TChildProps;
export function withArtifactDag<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactDagQuery,
  ArtifactDagQueryVariables,
  ArtifactDagProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactDagQuery, ArtifactDagQueryVariables, ArtifactDagProps<TChildProps, TDataName>>(ArtifactDagDocument, {
      alias: 'artifactDag',
      ...operationOptions
    });
};

/**
 * __useArtifactDagQuery__
 *
 * To run a query within a React component, call `useArtifactDagQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactDagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactDagQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactName: // value for 'artifactName'
 *   },
 * });
 */
export function useArtifactDagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactDagQuery, ArtifactDagQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactDagQuery, ArtifactDagQueryVariables>(ArtifactDagDocument, baseOptions);
      }
export function useArtifactDagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactDagQuery, ArtifactDagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactDagQuery, ArtifactDagQueryVariables>(ArtifactDagDocument, baseOptions);
        }
export type ArtifactDagQueryHookResult = ReturnType<typeof useArtifactDagQuery>;
export type ArtifactDagLazyQueryHookResult = ReturnType<typeof useArtifactDagLazyQuery>;
export type ArtifactDagQueryResult = ApolloReactCommon.QueryResult<ArtifactDagQuery, ArtifactDagQueryVariables>;
export const ArtifactFilesDocument = gql`
    query ArtifactFiles($artifactID: ID!, $artifactFileNames: [String!]!) {
  artifact(id: $artifactID) {
    id
    createdAt
    files(names: $artifactFileNames) {
      edges {
        node {
          id
          name
          displayName
          digest
          url
          directUrl
          isReference
          sizeBytes
          updatedAt
        }
      }
    }
  }
}
    `;
export type ArtifactFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>, 'query'> & ({ variables: ArtifactFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactFilesComponent = (props: ArtifactFilesComponentProps) => (
      <ApolloReactComponents.Query<ArtifactFilesQuery, ArtifactFilesQueryVariables> query={ArtifactFilesDocument} {...props} />
    );
    
export type ArtifactFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactFilesQuery, ArtifactFilesQueryVariables>
    } & TChildProps;
export function withArtifactFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactFilesQuery,
  ArtifactFilesQueryVariables,
  ArtifactFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactFilesQuery, ArtifactFilesQueryVariables, ArtifactFilesProps<TChildProps, TDataName>>(ArtifactFilesDocument, {
      alias: 'artifactFiles',
      ...operationOptions
    });
};

/**
 * __useArtifactFilesQuery__
 *
 * To run a query within a React component, call `useArtifactFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactFilesQuery({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      artifactFileNames: // value for 'artifactFileNames'
 *   },
 * });
 */
export function useArtifactFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactFilesQuery, ArtifactFilesQueryVariables>(ArtifactFilesDocument, baseOptions);
      }
export function useArtifactFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactFilesQuery, ArtifactFilesQueryVariables>(ArtifactFilesDocument, baseOptions);
        }
export type ArtifactFilesQueryHookResult = ReturnType<typeof useArtifactFilesQuery>;
export type ArtifactFilesLazyQueryHookResult = ReturnType<typeof useArtifactFilesLazyQuery>;
export type ArtifactFilesQueryResult = ApolloReactCommon.QueryResult<ArtifactFilesQuery, ArtifactFilesQueryVariables>;
export const ArtifactIdDocument = gql`
    query ArtifactID($projectName: String!, $entityName: String!, $artifactTypeName: String!, $artifactID: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifact(name: $artifactID) {
        id
      }
    }
  }
}
    `;
export type ArtifactIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactIdQuery, ArtifactIdQueryVariables>, 'query'> & ({ variables: ArtifactIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactIdComponent = (props: ArtifactIdComponentProps) => (
      <ApolloReactComponents.Query<ArtifactIdQuery, ArtifactIdQueryVariables> query={ArtifactIdDocument} {...props} />
    );
    
export type ArtifactIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactIdQuery, ArtifactIdQueryVariables>
    } & TChildProps;
export function withArtifactId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactIdQuery,
  ArtifactIdQueryVariables,
  ArtifactIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactIdQuery, ArtifactIdQueryVariables, ArtifactIdProps<TChildProps, TDataName>>(ArtifactIdDocument, {
      alias: 'artifactId',
      ...operationOptions
    });
};

/**
 * __useArtifactIdQuery__
 *
 * To run a query within a React component, call `useArtifactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactIdQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useArtifactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactIdQuery, ArtifactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactIdQuery, ArtifactIdQueryVariables>(ArtifactIdDocument, baseOptions);
      }
export function useArtifactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactIdQuery, ArtifactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactIdQuery, ArtifactIdQueryVariables>(ArtifactIdDocument, baseOptions);
        }
export type ArtifactIdQueryHookResult = ReturnType<typeof useArtifactIdQuery>;
export type ArtifactIdLazyQueryHookResult = ReturnType<typeof useArtifactIdLazyQuery>;
export type ArtifactIdQueryResult = ApolloReactCommon.QueryResult<ArtifactIdQuery, ArtifactIdQueryVariables>;
export const ArtifactPathInfoDocument = gql`
    query ArtifactPathInfo($artifactId: ID!) {
  artifact(id: $artifactId) {
    id
    createdAt
    commitHash
    versionIndex
    artifactSequence {
      id
      name
    }
    artifactType {
      id
      name
      project {
        id
        name
        entityName
      }
    }
  }
}
    `;
export type ArtifactPathInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>, 'query'> & ({ variables: ArtifactPathInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactPathInfoComponent = (props: ArtifactPathInfoComponentProps) => (
      <ApolloReactComponents.Query<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables> query={ArtifactPathInfoDocument} {...props} />
    );
    
export type ArtifactPathInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>
    } & TChildProps;
export function withArtifactPathInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactPathInfoQuery,
  ArtifactPathInfoQueryVariables,
  ArtifactPathInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables, ArtifactPathInfoProps<TChildProps, TDataName>>(ArtifactPathInfoDocument, {
      alias: 'artifactPathInfo',
      ...operationOptions
    });
};

/**
 * __useArtifactPathInfoQuery__
 *
 * To run a query within a React component, call `useArtifactPathInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactPathInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactPathInfoQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactPathInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>(ArtifactPathInfoDocument, baseOptions);
      }
export function useArtifactPathInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>(ArtifactPathInfoDocument, baseOptions);
        }
export type ArtifactPathInfoQueryHookResult = ReturnType<typeof useArtifactPathInfoQuery>;
export type ArtifactPathInfoLazyQueryHookResult = ReturnType<typeof useArtifactPathInfoLazyQuery>;
export type ArtifactPathInfoQueryResult = ApolloReactCommon.QueryResult<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>;
export const ArtifactManifestDocument = gql`
    query ArtifactManifest($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactID: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifact(name: $artifactID) {
        id
        createdAt
        currentManifest {
          file {
            directUrl
          }
        }
      }
    }
  }
  serverInfo {
    defaultCloudRegion {
      id
    }
  }
}
    `;
export type ArtifactManifestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>, 'query'> & ({ variables: ArtifactManifestQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactManifestComponent = (props: ArtifactManifestComponentProps) => (
      <ApolloReactComponents.Query<ArtifactManifestQuery, ArtifactManifestQueryVariables> query={ArtifactManifestDocument} {...props} />
    );
    
export type ArtifactManifestProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactManifestQuery, ArtifactManifestQueryVariables>
    } & TChildProps;
export function withArtifactManifest<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactManifestQuery,
  ArtifactManifestQueryVariables,
  ArtifactManifestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactManifestQuery, ArtifactManifestQueryVariables, ArtifactManifestProps<TChildProps, TDataName>>(ArtifactManifestDocument, {
      alias: 'artifactManifest',
      ...operationOptions
    });
};

/**
 * __useArtifactManifestQuery__
 *
 * To run a query within a React component, call `useArtifactManifestQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactManifestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactManifestQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useArtifactManifestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactManifestQuery, ArtifactManifestQueryVariables>(ArtifactManifestDocument, baseOptions);
      }
export function useArtifactManifestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactManifestQuery, ArtifactManifestQueryVariables>(ArtifactManifestDocument, baseOptions);
        }
export type ArtifactManifestQueryHookResult = ReturnType<typeof useArtifactManifestQuery>;
export type ArtifactManifestLazyQueryHookResult = ReturnType<typeof useArtifactManifestLazyQuery>;
export type ArtifactManifestQueryResult = ApolloReactCommon.QueryResult<ArtifactManifestQuery, ArtifactManifestQueryVariables>;
export const ArtifactManifestFromIdDocument = gql`
    query ArtifactManifestFromID($artifactId: ID!) {
  artifact(id: $artifactId) {
    id
    createdAt
    artifactSequence {
      id
      name
    }
    artifactType {
      id
      name
      project {
        id
        name
        entityName
      }
    }
    currentManifest {
      id
      file {
        directUrl
      }
    }
  }
  serverInfo {
    defaultCloudRegion {
      id
    }
  }
}
    `;
export type ArtifactManifestFromIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>, 'query'> & ({ variables: ArtifactManifestFromIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactManifestFromIdComponent = (props: ArtifactManifestFromIdComponentProps) => (
      <ApolloReactComponents.Query<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables> query={ArtifactManifestFromIdDocument} {...props} />
    );
    
export type ArtifactManifestFromIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>
    } & TChildProps;
export function withArtifactManifestFromId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactManifestFromIdQuery,
  ArtifactManifestFromIdQueryVariables,
  ArtifactManifestFromIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables, ArtifactManifestFromIdProps<TChildProps, TDataName>>(ArtifactManifestFromIdDocument, {
      alias: 'artifactManifestFromId',
      ...operationOptions
    });
};

/**
 * __useArtifactManifestFromIdQuery__
 *
 * To run a query within a React component, call `useArtifactManifestFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactManifestFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactManifestFromIdQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactManifestFromIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>(ArtifactManifestFromIdDocument, baseOptions);
      }
export function useArtifactManifestFromIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>(ArtifactManifestFromIdDocument, baseOptions);
        }
export type ArtifactManifestFromIdQueryHookResult = ReturnType<typeof useArtifactManifestFromIdQuery>;
export type ArtifactManifestFromIdLazyQueryHookResult = ReturnType<typeof useArtifactManifestFromIdLazyQuery>;
export type ArtifactManifestFromIdQueryResult = ApolloReactCommon.QueryResult<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>;
export const ResolveLatestSequenceAliasToArtifactIdDocument = gql`
    query ResolveLatestSequenceAliasToArtifactId($sequenceId: ID!) {
  artifactSequence(id: $sequenceId) {
    latestArtifact {
      id
    }
  }
}
    `;
export type ResolveLatestSequenceAliasToArtifactIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>, 'query'> & ({ variables: ResolveLatestSequenceAliasToArtifactIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ResolveLatestSequenceAliasToArtifactIdComponent = (props: ResolveLatestSequenceAliasToArtifactIdComponentProps) => (
      <ApolloReactComponents.Query<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables> query={ResolveLatestSequenceAliasToArtifactIdDocument} {...props} />
    );
    
export type ResolveLatestSequenceAliasToArtifactIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>
    } & TChildProps;
export function withResolveLatestSequenceAliasToArtifactId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResolveLatestSequenceAliasToArtifactIdQuery,
  ResolveLatestSequenceAliasToArtifactIdQueryVariables,
  ResolveLatestSequenceAliasToArtifactIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables, ResolveLatestSequenceAliasToArtifactIdProps<TChildProps, TDataName>>(ResolveLatestSequenceAliasToArtifactIdDocument, {
      alias: 'resolveLatestSequenceAliasToArtifactId',
      ...operationOptions
    });
};

/**
 * __useResolveLatestSequenceAliasToArtifactIdQuery__
 *
 * To run a query within a React component, call `useResolveLatestSequenceAliasToArtifactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveLatestSequenceAliasToArtifactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveLatestSequenceAliasToArtifactIdQuery({
 *   variables: {
 *      sequenceId: // value for 'sequenceId'
 *   },
 * });
 */
export function useResolveLatestSequenceAliasToArtifactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>(ResolveLatestSequenceAliasToArtifactIdDocument, baseOptions);
      }
export function useResolveLatestSequenceAliasToArtifactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>(ResolveLatestSequenceAliasToArtifactIdDocument, baseOptions);
        }
export type ResolveLatestSequenceAliasToArtifactIdQueryHookResult = ReturnType<typeof useResolveLatestSequenceAliasToArtifactIdQuery>;
export type ResolveLatestSequenceAliasToArtifactIdLazyQueryHookResult = ReturnType<typeof useResolveLatestSequenceAliasToArtifactIdLazyQuery>;
export type ResolveLatestSequenceAliasToArtifactIdQueryResult = ApolloReactCommon.QueryResult<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>;
export const ArtifactCommitHashDocument = gql`
    query ArtifactCommitHash($id: ID!, $artifactCollectionName: String!) {
  artifact(id: $id) {
    id
    commitHash(artifactCollectionName: $artifactCollectionName)
  }
}
    `;
export type ArtifactCommitHashComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>, 'query'> & ({ variables: ArtifactCommitHashQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCommitHashComponent = (props: ArtifactCommitHashComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables> query={ArtifactCommitHashDocument} {...props} />
    );
    
export type ArtifactCommitHashProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>
    } & TChildProps;
export function withArtifactCommitHash<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCommitHashQuery,
  ArtifactCommitHashQueryVariables,
  ArtifactCommitHashProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables, ArtifactCommitHashProps<TChildProps, TDataName>>(ArtifactCommitHashDocument, {
      alias: 'artifactCommitHash',
      ...operationOptions
    });
};

/**
 * __useArtifactCommitHashQuery__
 *
 * To run a query within a React component, call `useArtifactCommitHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCommitHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCommitHashQuery({
 *   variables: {
 *      id: // value for 'id'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *   },
 * });
 */
export function useArtifactCommitHashQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>(ArtifactCommitHashDocument, baseOptions);
      }
export function useArtifactCommitHashLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>(ArtifactCommitHashDocument, baseOptions);
        }
export type ArtifactCommitHashQueryHookResult = ReturnType<typeof useArtifactCommitHashQuery>;
export type ArtifactCommitHashLazyQueryHookResult = ReturnType<typeof useArtifactCommitHashLazyQuery>;
export type ArtifactCommitHashQueryResult = ApolloReactCommon.QueryResult<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>;
export const CreateArtifactTypeDocument = gql`
    mutation CreateArtifactType($entityName: String!, $projectName: String!, $name: String!, $description: String) {
  createArtifactType(input: {entityName: $entityName, projectName: $projectName, name: $name, description: $description}) {
    artifactType {
      id
      name
    }
  }
}
    `;
export type CreateArtifactTypeMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>;
export type CreateArtifactTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>, 'mutation'>;

    export const CreateArtifactTypeComponent = (props: CreateArtifactTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables> mutation={CreateArtifactTypeDocument} {...props} />
    );
    
export type CreateArtifactTypeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>
    } & TChildProps;
export function withCreateArtifactType<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactTypeMutation,
  CreateArtifactTypeMutationVariables,
  CreateArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables, CreateArtifactTypeProps<TChildProps, TDataName>>(CreateArtifactTypeDocument, {
      alias: 'createArtifactType',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactTypeMutation__
 *
 * To run a mutation, you first call `useCreateArtifactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactTypeMutation, { data, loading, error }] = useCreateArtifactTypeMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateArtifactTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>(CreateArtifactTypeDocument, baseOptions);
      }
export type CreateArtifactTypeMutationHookResult = ReturnType<typeof useCreateArtifactTypeMutation>;
export type CreateArtifactTypeMutationResult = ApolloReactCommon.MutationResult<CreateArtifactTypeMutation>;
export type CreateArtifactTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactTypeMutation, CreateArtifactTypeMutationVariables>;
export const UpdateArtifactDocument = gql`
    mutation UpdateArtifact($artifactID: ID!, $description: String, $aliases: [ArtifactAliasInput!], $labels: JSONString, $metadata: JSONString) {
  updateArtifact(input: {artifactID: $artifactID, description: $description, aliases: $aliases, labels: $labels, metadata: $metadata}) {
    artifact {
      id
      digest
      description
      aliases {
        id
        artifactCollectionName
        alias
      }
      labels
    }
  }
}
    `;
export type UpdateArtifactMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactMutation, UpdateArtifactMutationVariables>;
export type UpdateArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>, 'mutation'>;

    export const UpdateArtifactComponent = (props: UpdateArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactMutation, UpdateArtifactMutationVariables> mutation={UpdateArtifactDocument} {...props} />
    );
    
export type UpdateArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactMutation, UpdateArtifactMutationVariables>
    } & TChildProps;
export function withUpdateArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactMutation,
  UpdateArtifactMutationVariables,
  UpdateArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactMutation, UpdateArtifactMutationVariables, UpdateArtifactProps<TChildProps, TDataName>>(UpdateArtifactDocument, {
      alias: 'updateArtifact',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactMutation, { data, loading, error }] = useUpdateArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      description: // value for 'description'
 *      aliases: // value for 'aliases'
 *      labels: // value for 'labels'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactMutation, UpdateArtifactMutationVariables>(UpdateArtifactDocument, baseOptions);
      }
export type UpdateArtifactMutationHookResult = ReturnType<typeof useUpdateArtifactMutation>;
export type UpdateArtifactMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactMutation>;
export type UpdateArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>;
export const DeleteArtifactDocument = gql`
    mutation DeleteArtifact($artifactID: ID!, $deleteAliases: Boolean = false) {
  deleteArtifact(input: {artifactID: $artifactID, deleteAliases: $deleteAliases}) {
    artifact {
      id
      state
    }
  }
}
    `;
export type DeleteArtifactMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactMutation, DeleteArtifactMutationVariables>;
export type DeleteArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>, 'mutation'>;

    export const DeleteArtifactComponent = (props: DeleteArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactMutation, DeleteArtifactMutationVariables> mutation={DeleteArtifactDocument} {...props} />
    );
    
export type DeleteArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactMutation, DeleteArtifactMutationVariables>
    } & TChildProps;
export function withDeleteArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactMutation,
  DeleteArtifactMutationVariables,
  DeleteArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactMutation, DeleteArtifactMutationVariables, DeleteArtifactProps<TChildProps, TDataName>>(DeleteArtifactDocument, {
      alias: 'deleteArtifact',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactMutation, { data, loading, error }] = useDeleteArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      deleteAliases: // value for 'deleteAliases'
 *   },
 * });
 */
export function useDeleteArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactMutation, DeleteArtifactMutationVariables>(DeleteArtifactDocument, baseOptions);
      }
export type DeleteArtifactMutationHookResult = ReturnType<typeof useDeleteArtifactMutation>;
export type DeleteArtifactMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactMutation>;
export type DeleteArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>;
export const DeleteArtifactSequenceDocument = gql`
    mutation DeleteArtifactSequence($artifactSequenceID: ID!) {
  deleteArtifactSequence(input: {artifactSequenceID: $artifactSequenceID}) {
    artifactCollection {
      id
      state
    }
  }
}
    `;
export type DeleteArtifactSequenceMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>;
export type DeleteArtifactSequenceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>, 'mutation'>;

    export const DeleteArtifactSequenceComponent = (props: DeleteArtifactSequenceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables> mutation={DeleteArtifactSequenceDocument} {...props} />
    );
    
export type DeleteArtifactSequenceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>
    } & TChildProps;
export function withDeleteArtifactSequence<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactSequenceMutation,
  DeleteArtifactSequenceMutationVariables,
  DeleteArtifactSequenceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables, DeleteArtifactSequenceProps<TChildProps, TDataName>>(DeleteArtifactSequenceDocument, {
      alias: 'deleteArtifactSequence',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactSequenceMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactSequenceMutation, { data, loading, error }] = useDeleteArtifactSequenceMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *   },
 * });
 */
export function useDeleteArtifactSequenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>(DeleteArtifactSequenceDocument, baseOptions);
      }
export type DeleteArtifactSequenceMutationHookResult = ReturnType<typeof useDeleteArtifactSequenceMutation>;
export type DeleteArtifactSequenceMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactSequenceMutation>;
export type DeleteArtifactSequenceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>;
export const UpdateArtifactTypeDocument = gql`
    mutation UpdateArtifactType($artifactTypeID: ID!, $description: String) {
  updateArtifactType(input: {artifactTypeID: $artifactTypeID, description: $description}) {
    artifactType {
      id
      description
    }
  }
}
    `;
export type UpdateArtifactTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>;
export type UpdateArtifactTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>, 'mutation'>;

    export const UpdateArtifactTypeComponent = (props: UpdateArtifactTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables> mutation={UpdateArtifactTypeDocument} {...props} />
    );
    
export type UpdateArtifactTypeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>
    } & TChildProps;
export function withUpdateArtifactType<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactTypeMutation,
  UpdateArtifactTypeMutationVariables,
  UpdateArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables, UpdateArtifactTypeProps<TChildProps, TDataName>>(UpdateArtifactTypeDocument, {
      alias: 'updateArtifactType',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactTypeMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactTypeMutation, { data, loading, error }] = useUpdateArtifactTypeMutation({
 *   variables: {
 *      artifactTypeID: // value for 'artifactTypeID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateArtifactTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>(UpdateArtifactTypeDocument, baseOptions);
      }
export type UpdateArtifactTypeMutationHookResult = ReturnType<typeof useUpdateArtifactTypeMutation>;
export type UpdateArtifactTypeMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactTypeMutation>;
export type UpdateArtifactTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>;
export const UpdateArtifactCollectionDocument = gql`
    mutation UpdateArtifactCollection($artifactSequenceID: ID!, $name: String, $description: String) {
  updateArtifactSequence(input: {artifactSequenceID: $artifactSequenceID, name: $name, description: $description}) {
    artifactCollection {
      id
      name
      description
    }
  }
}
    `;
export type UpdateArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>;
export type UpdateArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>, 'mutation'>;

    export const UpdateArtifactCollectionComponent = (props: UpdateArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables> mutation={UpdateArtifactCollectionDocument} {...props} />
    );
    
export type UpdateArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>
    } & TChildProps;
export function withUpdateArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactCollectionMutation,
  UpdateArtifactCollectionMutationVariables,
  UpdateArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables, UpdateArtifactCollectionProps<TChildProps, TDataName>>(UpdateArtifactCollectionDocument, {
      alias: 'updateArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactCollectionMutation, { data, loading, error }] = useUpdateArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>(UpdateArtifactCollectionDocument, baseOptions);
      }
export type UpdateArtifactCollectionMutationHookResult = ReturnType<typeof useUpdateArtifactCollectionMutation>;
export type UpdateArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactCollectionMutation>;
export type UpdateArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>;
export const MoveArtifactCollectionDocument = gql`
    mutation MoveArtifactCollection($artifactSequenceID: ID!, $destinationArtifactTypeName: String!) {
  moveArtifactSequence(input: {artifactSequenceID: $artifactSequenceID, destinationArtifactTypeName: $destinationArtifactTypeName}) {
    artifactCollection {
      id
      name
      description
    }
  }
}
    `;
export type MoveArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>;
export type MoveArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>, 'mutation'>;

    export const MoveArtifactCollectionComponent = (props: MoveArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables> mutation={MoveArtifactCollectionDocument} {...props} />
    );
    
export type MoveArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>
    } & TChildProps;
export function withMoveArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveArtifactCollectionMutation,
  MoveArtifactCollectionMutationVariables,
  MoveArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables, MoveArtifactCollectionProps<TChildProps, TDataName>>(MoveArtifactCollectionDocument, {
      alias: 'moveArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useMoveArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useMoveArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveArtifactCollectionMutation, { data, loading, error }] = useMoveArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *      destinationArtifactTypeName: // value for 'destinationArtifactTypeName'
 *   },
 * });
 */
export function useMoveArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>(MoveArtifactCollectionDocument, baseOptions);
      }
export type MoveArtifactCollectionMutationHookResult = ReturnType<typeof useMoveArtifactCollectionMutation>;
export type MoveArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<MoveArtifactCollectionMutation>;
export type MoveArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>;
export const DeleteArtifactCollectionDocument = gql`
    mutation DeleteArtifactCollection($artifactSequenceID: ID!) {
  deleteArtifactSequence(input: {artifactSequenceID: $artifactSequenceID}) {
    artifactCollection {
      id
    }
  }
}
    `;
export type DeleteArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>;
export type DeleteArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>, 'mutation'>;

    export const DeleteArtifactCollectionComponent = (props: DeleteArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables> mutation={DeleteArtifactCollectionDocument} {...props} />
    );
    
export type DeleteArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>
    } & TChildProps;
export function withDeleteArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactCollectionMutation,
  DeleteArtifactCollectionMutationVariables,
  DeleteArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables, DeleteArtifactCollectionProps<TChildProps, TDataName>>(DeleteArtifactCollectionDocument, {
      alias: 'deleteArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactCollectionMutation, { data, loading, error }] = useDeleteArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *   },
 * });
 */
export function useDeleteArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>(DeleteArtifactCollectionDocument, baseOptions);
      }
export type DeleteArtifactCollectionMutationHookResult = ReturnType<typeof useDeleteArtifactCollectionMutation>;
export type DeleteArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactCollectionMutation>;
export type DeleteArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>;
export const CreateArtifactFilesDocument = gql`
    mutation CreateArtifactFiles($artifactFiles: [CreateArtifactFileSpecInput!]!, $layout: ArtifactStorageLayout = V1) {
  createArtifactFiles(input: {artifactFiles: $artifactFiles, storageLayout: $layout}) {
    files {
      edges {
        node {
          id
          name
          displayName
          uploadUrl: url(upload: true)
          directUrl
          isReference
          sizeBytes
          updatedAt
        }
      }
    }
  }
}
    `;
export type CreateArtifactFilesMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>;
export type CreateArtifactFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>, 'mutation'>;

    export const CreateArtifactFilesComponent = (props: CreateArtifactFilesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables> mutation={CreateArtifactFilesDocument} {...props} />
    );
    
export type CreateArtifactFilesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>
    } & TChildProps;
export function withCreateArtifactFiles<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactFilesMutation,
  CreateArtifactFilesMutationVariables,
  CreateArtifactFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables, CreateArtifactFilesProps<TChildProps, TDataName>>(CreateArtifactFilesDocument, {
      alias: 'createArtifactFiles',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactFilesMutation__
 *
 * To run a mutation, you first call `useCreateArtifactFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactFilesMutation, { data, loading, error }] = useCreateArtifactFilesMutation({
 *   variables: {
 *      artifactFiles: // value for 'artifactFiles'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useCreateArtifactFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>(CreateArtifactFilesDocument, baseOptions);
      }
export type CreateArtifactFilesMutationHookResult = ReturnType<typeof useCreateArtifactFilesMutation>;
export type CreateArtifactFilesMutationResult = ApolloReactCommon.MutationResult<CreateArtifactFilesMutation>;
export type CreateArtifactFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>;
export const ArtifactDocument = gql`
    query Artifact($projectName: String!, $entityName: String!, $artifactTypeName: String!, $artifactID: String!, $usedByFilters: JSONString, $usedByOrder: String, $usedByAfter: String!, $usedByLimit: Int!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      artifact(name: $artifactID) {
        id
        digest
        description
        state
        createdAt
        metadata
        versionIndex
        artifactSequence {
          id
          name
        }
        aliases {
          artifactCollectionName
          alias
        }
        labels
        createdBy {
          __typename
          ... on Run {
            id
            runName: name
            displayName
            jobType
            user {
              id
              name
              username
              photoUrl
            }
          }
          ... on User {
            id
            name
            username
            photoUrl
          }
        }
        usedBy(filters: $usedByFilters, order: $usedByOrder, after: $usedByAfter, first: $usedByLimit) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              jobType
              displayName
              group
              createdAt
              heartbeatAt
              user {
                id
                name
                username
                photoUrl
              }
              project {
                id
                entityName
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactQuery, ArtifactQueryVariables>, 'query'> & ({ variables: ArtifactQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactComponent = (props: ArtifactComponentProps) => (
      <ApolloReactComponents.Query<ArtifactQuery, ArtifactQueryVariables> query={ArtifactDocument} {...props} />
    );
    
export type ArtifactProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactQuery, ArtifactQueryVariables>
    } & TChildProps;
export function withArtifact<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactQuery,
  ArtifactQueryVariables,
  ArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactQuery, ArtifactQueryVariables, ArtifactProps<TChildProps, TDataName>>(ArtifactDocument, {
      alias: 'artifact',
      ...operationOptions
    });
};

/**
 * __useArtifactQuery__
 *
 * To run a query within a React component, call `useArtifactQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactID: // value for 'artifactID'
 *      usedByFilters: // value for 'usedByFilters'
 *      usedByOrder: // value for 'usedByOrder'
 *      usedByAfter: // value for 'usedByAfter'
 *      usedByLimit: // value for 'usedByLimit'
 *   },
 * });
 */
export function useArtifactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactQuery, ArtifactQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactQuery, ArtifactQueryVariables>(ArtifactDocument, baseOptions);
      }
export function useArtifactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactQuery, ArtifactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactQuery, ArtifactQueryVariables>(ArtifactDocument, baseOptions);
        }
export type ArtifactQueryHookResult = ReturnType<typeof useArtifactQuery>;
export type ArtifactLazyQueryHookResult = ReturnType<typeof useArtifactLazyQuery>;
export type ArtifactQueryResult = ApolloReactCommon.QueryResult<ArtifactQuery, ArtifactQueryVariables>;
export const ArtifactTypeDocument = gql`
    query ArtifactType($projectName: String!, $entityName: String!, $artifactTypeName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      description
      artifactSequences(order: "-createdAt") {
        edges {
          node {
            id
            name
            description
            createdAt
            artifacts {
              totalCount
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>, 'query'> & ({ variables: ArtifactTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactTypeComponent = (props: ArtifactTypeComponentProps) => (
      <ApolloReactComponents.Query<ArtifactTypeQuery, ArtifactTypeQueryVariables> query={ArtifactTypeDocument} {...props} />
    );
    
export type ArtifactTypeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactTypeQuery, ArtifactTypeQueryVariables>
    } & TChildProps;
export function withArtifactType<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactTypeQuery,
  ArtifactTypeQueryVariables,
  ArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactTypeQuery, ArtifactTypeQueryVariables, ArtifactTypeProps<TChildProps, TDataName>>(ArtifactTypeDocument, {
      alias: 'artifactType',
      ...operationOptions
    });
};

/**
 * __useArtifactTypeQuery__
 *
 * To run a query within a React component, call `useArtifactTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactTypeQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *   },
 * });
 */
export function useArtifactTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactTypeQuery, ArtifactTypeQueryVariables>(ArtifactTypeDocument, baseOptions);
      }
export function useArtifactTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactTypeQuery, ArtifactTypeQueryVariables>(ArtifactTypeDocument, baseOptions);
        }
export type ArtifactTypeQueryHookResult = ReturnType<typeof useArtifactTypeQuery>;
export type ArtifactTypeLazyQueryHookResult = ReturnType<typeof useArtifactTypeLazyQuery>;
export type ArtifactTypeQueryResult = ApolloReactCommon.QueryResult<ArtifactTypeQuery, ArtifactTypeQueryVariables>;
export const ArtifactTypesDocument = gql`
    query ArtifactTypes($entityName: String, $projectName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type ArtifactTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>, 'query'>;

    export const ArtifactTypesComponent = (props: ArtifactTypesComponentProps) => (
      <ApolloReactComponents.Query<ArtifactTypesQuery, ArtifactTypesQueryVariables> query={ArtifactTypesDocument} {...props} />
    );
    
export type ArtifactTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactTypesQuery, ArtifactTypesQueryVariables>
    } & TChildProps;
export function withArtifactTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactTypesQuery,
  ArtifactTypesQueryVariables,
  ArtifactTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactTypesQuery, ArtifactTypesQueryVariables, ArtifactTypesProps<TChildProps, TDataName>>(ArtifactTypesDocument, {
      alias: 'artifactTypes',
      ...operationOptions
    });
};

/**
 * __useArtifactTypesQuery__
 *
 * To run a query within a React component, call `useArtifactTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactTypesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useArtifactTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactTypesQuery, ArtifactTypesQueryVariables>(ArtifactTypesDocument, baseOptions);
      }
export function useArtifactTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactTypesQuery, ArtifactTypesQueryVariables>(ArtifactTypesDocument, baseOptions);
        }
export type ArtifactTypesQueryHookResult = ReturnType<typeof useArtifactTypesQuery>;
export type ArtifactTypesLazyQueryHookResult = ReturnType<typeof useArtifactTypesLazyQuery>;
export type ArtifactTypesQueryResult = ApolloReactCommon.QueryResult<ArtifactTypesQuery, ArtifactTypesQueryVariables>;
export const EntityArtifactsDocument = gql`
    query EntityArtifacts($entityName: String!) {
  entity(name: $entityName) {
    id
    projects {
      edges {
        node {
          id
          name
          artifactTypes {
            edges {
              node {
                id
                name
                artifactSequences(order: "-createdAt") {
                  edges {
                    node {
                      id
                      name
                      createdAt
                      artifacts(first: 100) {
                        edges {
                          node {
                            id
                            versionIndex
                            aliases {
                              id
                              alias
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type EntityArtifactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>, 'query'> & ({ variables: EntityArtifactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityArtifactsComponent = (props: EntityArtifactsComponentProps) => (
      <ApolloReactComponents.Query<EntityArtifactsQuery, EntityArtifactsQueryVariables> query={EntityArtifactsDocument} {...props} />
    );
    
export type EntityArtifactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityArtifactsQuery, EntityArtifactsQueryVariables>
    } & TChildProps;
export function withEntityArtifacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityArtifactsQuery,
  EntityArtifactsQueryVariables,
  EntityArtifactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityArtifactsQuery, EntityArtifactsQueryVariables, EntityArtifactsProps<TChildProps, TDataName>>(EntityArtifactsDocument, {
      alias: 'entityArtifacts',
      ...operationOptions
    });
};

/**
 * __useEntityArtifactsQuery__
 *
 * To run a query within a React component, call `useEntityArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityArtifactsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityArtifactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityArtifactsQuery, EntityArtifactsQueryVariables>(EntityArtifactsDocument, baseOptions);
      }
export function useEntityArtifactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityArtifactsQuery, EntityArtifactsQueryVariables>(EntityArtifactsDocument, baseOptions);
        }
export type EntityArtifactsQueryHookResult = ReturnType<typeof useEntityArtifactsQuery>;
export type EntityArtifactsLazyQueryHookResult = ReturnType<typeof useEntityArtifactsLazyQuery>;
export type EntityArtifactsQueryResult = ApolloReactCommon.QueryResult<EntityArtifactsQuery, EntityArtifactsQueryVariables>;
export const GalleryDocument = gql`
    query Gallery {
  singletonView(type: "fullyConnected") {
    id
    spec
  }
}
    `;
export type GalleryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GalleryQuery, GalleryQueryVariables>, 'query'>;

    export const GalleryComponent = (props: GalleryComponentProps) => (
      <ApolloReactComponents.Query<GalleryQuery, GalleryQueryVariables> query={GalleryDocument} {...props} />
    );
    
export type GalleryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GalleryQuery, GalleryQueryVariables>
    } & TChildProps;
export function withGallery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GalleryQuery,
  GalleryQueryVariables,
  GalleryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GalleryQuery, GalleryQueryVariables, GalleryProps<TChildProps, TDataName>>(GalleryDocument, {
      alias: 'gallery',
      ...operationOptions
    });
};

/**
 * __useGalleryQuery__
 *
 * To run a query within a React component, call `useGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGalleryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
        return ApolloReactHooks.useQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, baseOptions);
      }
export function useGalleryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, baseOptions);
        }
export type GalleryQueryHookResult = ReturnType<typeof useGalleryQuery>;
export type GalleryLazyQueryHookResult = ReturnType<typeof useGalleryLazyQuery>;
export type GalleryQueryResult = ApolloReactCommon.QueryResult<GalleryQuery, GalleryQueryVariables>;
export const UpdateGalleryDocument = gql`
    mutation UpdateGallery($id: ID, $spec: String!) {
  upsertView(input: {id: $id, spec: $spec, name: "", type: "fullyConnected"}) {
    view {
      id
      updatedAt
      createdAt
      spec
    }
    inserted
  }
}
    `;
export type UpdateGalleryMutationFn = ApolloReactCommon.MutationFunction<UpdateGalleryMutation, UpdateGalleryMutationVariables>;
export type UpdateGalleryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>, 'mutation'>;

    export const UpdateGalleryComponent = (props: UpdateGalleryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateGalleryMutation, UpdateGalleryMutationVariables> mutation={UpdateGalleryDocument} {...props} />
    );
    
export type UpdateGalleryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateGalleryMutation, UpdateGalleryMutationVariables>
    } & TChildProps;
export function withUpdateGallery<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateGalleryMutation,
  UpdateGalleryMutationVariables,
  UpdateGalleryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateGalleryMutation, UpdateGalleryMutationVariables, UpdateGalleryProps<TChildProps, TDataName>>(UpdateGalleryDocument, {
      alias: 'updateGallery',
      ...operationOptions
    });
};

/**
 * __useUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGalleryMutation, { data, loading, error }] = useUpdateGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useUpdateGalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGalleryMutation, UpdateGalleryMutationVariables>(UpdateGalleryDocument, baseOptions);
      }
export type UpdateGalleryMutationHookResult = ReturnType<typeof useUpdateGalleryMutation>;
export type UpdateGalleryMutationResult = ApolloReactCommon.MutationResult<UpdateGalleryMutation>;
export type UpdateGalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>;
export const FeaturedReportsMetadataDocument = gql`
    query FeaturedReportsMetadata($ids: [ID!]!, $recentStarCountFrom: DateTime) {
  views(ids: $ids) {
    edges {
      node {
        id
        entityName
        project {
          id
          entityName
          name
        }
        displayName
        description
        previewUrl
        starCount
        recentStarCount: starCount(from: $recentStarCountFrom)
        starred
        discussionThreads {
          edges {
            node {
              id
              comments {
                totalCount
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FeaturedReportsMetadataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>, 'query'> & ({ variables: FeaturedReportsMetadataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FeaturedReportsMetadataComponent = (props: FeaturedReportsMetadataComponentProps) => (
      <ApolloReactComponents.Query<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables> query={FeaturedReportsMetadataDocument} {...props} />
    );
    
export type FeaturedReportsMetadataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>
    } & TChildProps;
export function withFeaturedReportsMetadata<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FeaturedReportsMetadataQuery,
  FeaturedReportsMetadataQueryVariables,
  FeaturedReportsMetadataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables, FeaturedReportsMetadataProps<TChildProps, TDataName>>(FeaturedReportsMetadataDocument, {
      alias: 'featuredReportsMetadata',
      ...operationOptions
    });
};

/**
 * __useFeaturedReportsMetadataQuery__
 *
 * To run a query within a React component, call `useFeaturedReportsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedReportsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedReportsMetadataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      recentStarCountFrom: // value for 'recentStarCountFrom'
 *   },
 * });
 */
export function useFeaturedReportsMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>(FeaturedReportsMetadataDocument, baseOptions);
      }
export function useFeaturedReportsMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>(FeaturedReportsMetadataDocument, baseOptions);
        }
export type FeaturedReportsMetadataQueryHookResult = ReturnType<typeof useFeaturedReportsMetadataQuery>;
export type FeaturedReportsMetadataLazyQueryHookResult = ReturnType<typeof useFeaturedReportsMetadataLazyQuery>;
export type FeaturedReportsMetadataQueryResult = ApolloReactCommon.QueryResult<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>;
export const FeaturedReportsAuthorsDocument = gql`
    query FeaturedReportsAuthors($usernames: [String!]!) {
  users(usernames: $usernames) {
    edges {
      node {
        id
        name
        username
        photoUrl
        userInfo
      }
    }
  }
}
    `;
export type FeaturedReportsAuthorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>, 'query'> & ({ variables: FeaturedReportsAuthorsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FeaturedReportsAuthorsComponent = (props: FeaturedReportsAuthorsComponentProps) => (
      <ApolloReactComponents.Query<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables> query={FeaturedReportsAuthorsDocument} {...props} />
    );
    
export type FeaturedReportsAuthorsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>
    } & TChildProps;
export function withFeaturedReportsAuthors<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FeaturedReportsAuthorsQuery,
  FeaturedReportsAuthorsQueryVariables,
  FeaturedReportsAuthorsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables, FeaturedReportsAuthorsProps<TChildProps, TDataName>>(FeaturedReportsAuthorsDocument, {
      alias: 'featuredReportsAuthors',
      ...operationOptions
    });
};

/**
 * __useFeaturedReportsAuthorsQuery__
 *
 * To run a query within a React component, call `useFeaturedReportsAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedReportsAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedReportsAuthorsQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useFeaturedReportsAuthorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>) {
        return ApolloReactHooks.useQuery<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>(FeaturedReportsAuthorsDocument, baseOptions);
      }
export function useFeaturedReportsAuthorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>(FeaturedReportsAuthorsDocument, baseOptions);
        }
export type FeaturedReportsAuthorsQueryHookResult = ReturnType<typeof useFeaturedReportsAuthorsQuery>;
export type FeaturedReportsAuthorsLazyQueryHookResult = ReturnType<typeof useFeaturedReportsAuthorsLazyQuery>;
export type FeaturedReportsAuthorsQueryResult = ApolloReactCommon.QueryResult<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>;
export const InsertGalleryDiscussionDocument = gql`
    mutation InsertGalleryDiscussion($spec: String!, $reportID: ID!, $sendEmail: Boolean) {
  insertGalleryDiscussion(input: {spec: $spec, reportID: $reportID, sendEmail: $sendEmail}) {
    success
  }
}
    `;
export type InsertGalleryDiscussionMutationFn = ApolloReactCommon.MutationFunction<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>;
export type InsertGalleryDiscussionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>, 'mutation'>;

    export const InsertGalleryDiscussionComponent = (props: InsertGalleryDiscussionComponentProps) => (
      <ApolloReactComponents.Mutation<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables> mutation={InsertGalleryDiscussionDocument} {...props} />
    );
    
export type InsertGalleryDiscussionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>
    } & TChildProps;
export function withInsertGalleryDiscussion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertGalleryDiscussionMutation,
  InsertGalleryDiscussionMutationVariables,
  InsertGalleryDiscussionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables, InsertGalleryDiscussionProps<TChildProps, TDataName>>(InsertGalleryDiscussionDocument, {
      alias: 'insertGalleryDiscussion',
      ...operationOptions
    });
};

/**
 * __useInsertGalleryDiscussionMutation__
 *
 * To run a mutation, you first call `useInsertGalleryDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGalleryDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGalleryDiscussionMutation, { data, loading, error }] = useInsertGalleryDiscussionMutation({
 *   variables: {
 *      spec: // value for 'spec'
 *      reportID: // value for 'reportID'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useInsertGalleryDiscussionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>(InsertGalleryDiscussionDocument, baseOptions);
      }
export type InsertGalleryDiscussionMutationHookResult = ReturnType<typeof useInsertGalleryDiscussionMutation>;
export type InsertGalleryDiscussionMutationResult = ApolloReactCommon.MutationResult<InsertGalleryDiscussionMutation>;
export type InsertGalleryDiscussionMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>;
export const GalleryTagSectionsDocument = gql`
    query GalleryTagSections {
  galleryTagSections
}
    `;
export type GalleryTagSectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>, 'query'>;

    export const GalleryTagSectionsComponent = (props: GalleryTagSectionsComponentProps) => (
      <ApolloReactComponents.Query<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables> query={GalleryTagSectionsDocument} {...props} />
    );
    
export type GalleryTagSectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>
    } & TChildProps;
export function withGalleryTagSections<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GalleryTagSectionsQuery,
  GalleryTagSectionsQueryVariables,
  GalleryTagSectionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables, GalleryTagSectionsProps<TChildProps, TDataName>>(GalleryTagSectionsDocument, {
      alias: 'galleryTagSections',
      ...operationOptions
    });
};

/**
 * __useGalleryTagSectionsQuery__
 *
 * To run a query within a React component, call `useGalleryTagSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryTagSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryTagSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGalleryTagSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>(GalleryTagSectionsDocument, baseOptions);
      }
export function useGalleryTagSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>(GalleryTagSectionsDocument, baseOptions);
        }
export type GalleryTagSectionsQueryHookResult = ReturnType<typeof useGalleryTagSectionsQuery>;
export type GalleryTagSectionsLazyQueryHookResult = ReturnType<typeof useGalleryTagSectionsLazyQuery>;
export type GalleryTagSectionsQueryResult = ApolloReactCommon.QueryResult<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>;
export const ProjectAccessDocument = gql`
    query ProjectAccess($name: String, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    access
  }
}
    `;
export type ProjectAccessComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectAccessQuery, ProjectAccessQueryVariables>, 'query'>;

    export const ProjectAccessComponent = (props: ProjectAccessComponentProps) => (
      <ApolloReactComponents.Query<ProjectAccessQuery, ProjectAccessQueryVariables> query={ProjectAccessDocument} {...props} />
    );
    
export type ProjectAccessProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectAccessQuery, ProjectAccessQueryVariables>
    } & TChildProps;
export function withProjectAccess<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectAccessQuery,
  ProjectAccessQueryVariables,
  ProjectAccessProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectAccessQuery, ProjectAccessQueryVariables, ProjectAccessProps<TChildProps, TDataName>>(ProjectAccessDocument, {
      alias: 'projectAccess',
      ...operationOptions
    });
};

/**
 * __useProjectAccessQuery__
 *
 * To run a query within a React component, call `useProjectAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAccessQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectAccessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectAccessQuery, ProjectAccessQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectAccessQuery, ProjectAccessQueryVariables>(ProjectAccessDocument, baseOptions);
      }
export function useProjectAccessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectAccessQuery, ProjectAccessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectAccessQuery, ProjectAccessQueryVariables>(ProjectAccessDocument, baseOptions);
        }
export type ProjectAccessQueryHookResult = ReturnType<typeof useProjectAccessQuery>;
export type ProjectAccessLazyQueryHookResult = ReturnType<typeof useProjectAccessLazyQuery>;
export type ProjectAccessQueryResult = ApolloReactCommon.QueryResult<ProjectAccessQuery, ProjectAccessQueryVariables>;
export const NewsletterSubscriptionDocument = gql`
    query NewsletterSubscription($userName: String!) {
  user(userName: $userName) {
    id
    newsletterSubscription {
      id
    }
  }
}
    `;
export type NewsletterSubscriptionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>, 'query'> & ({ variables: NewsletterSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewsletterSubscriptionComponent = (props: NewsletterSubscriptionComponentProps) => (
      <ApolloReactComponents.Query<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables> query={NewsletterSubscriptionDocument} {...props} />
    );
    
export type NewsletterSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>
    } & TChildProps;
export function withNewsletterSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsletterSubscriptionQuery,
  NewsletterSubscriptionQueryVariables,
  NewsletterSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables, NewsletterSubscriptionProps<TChildProps, TDataName>>(NewsletterSubscriptionDocument, {
      alias: 'newsletterSubscription',
      ...operationOptions
    });
};

/**
 * __useNewsletterSubscriptionQuery__
 *
 * To run a query within a React component, call `useNewsletterSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterSubscriptionQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useNewsletterSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>(NewsletterSubscriptionDocument, baseOptions);
      }
export function useNewsletterSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>(NewsletterSubscriptionDocument, baseOptions);
        }
export type NewsletterSubscriptionQueryHookResult = ReturnType<typeof useNewsletterSubscriptionQuery>;
export type NewsletterSubscriptionLazyQueryHookResult = ReturnType<typeof useNewsletterSubscriptionLazyQuery>;
export type NewsletterSubscriptionQueryResult = ApolloReactCommon.QueryResult<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>;
export const CreateNewsletterSubscriptionDocument = gql`
    mutation CreateNewsletterSubscription($email: String) {
  createNewsletterSubscription(input: {email: $email}) {
    newsletterSubscription {
      id
    }
  }
}
    `;
export type CreateNewsletterSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>;
export type CreateNewsletterSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>, 'mutation'>;

    export const CreateNewsletterSubscriptionComponent = (props: CreateNewsletterSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables> mutation={CreateNewsletterSubscriptionDocument} {...props} />
    );
    
export type CreateNewsletterSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateNewsletterSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewsletterSubscriptionMutation,
  CreateNewsletterSubscriptionMutationVariables,
  CreateNewsletterSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables, CreateNewsletterSubscriptionProps<TChildProps, TDataName>>(CreateNewsletterSubscriptionDocument, {
      alias: 'createNewsletterSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateNewsletterSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterSubscriptionMutation, { data, loading, error }] = useCreateNewsletterSubscriptionMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateNewsletterSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>(CreateNewsletterSubscriptionDocument, baseOptions);
      }
export type CreateNewsletterSubscriptionMutationHookResult = ReturnType<typeof useCreateNewsletterSubscriptionMutation>;
export type CreateNewsletterSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateNewsletterSubscriptionMutation>;
export type CreateNewsletterSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>;
export const AllNewsletterSubscriptionsDocument = gql`
    query AllNewsletterSubscriptions($first: Int = 10000) {
  newsletterSubscriptions(first: $first) {
    edges {
      node {
        id
        user {
          id
          username
          email
        }
        email
        createdAt
      }
    }
  }
}
    `;
export type AllNewsletterSubscriptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>, 'query'>;

    export const AllNewsletterSubscriptionsComponent = (props: AllNewsletterSubscriptionsComponentProps) => (
      <ApolloReactComponents.Query<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables> query={AllNewsletterSubscriptionsDocument} {...props} />
    );
    
export type AllNewsletterSubscriptionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>
    } & TChildProps;
export function withAllNewsletterSubscriptions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllNewsletterSubscriptionsQuery,
  AllNewsletterSubscriptionsQueryVariables,
  AllNewsletterSubscriptionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables, AllNewsletterSubscriptionsProps<TChildProps, TDataName>>(AllNewsletterSubscriptionsDocument, {
      alias: 'allNewsletterSubscriptions',
      ...operationOptions
    });
};

/**
 * __useAllNewsletterSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAllNewsletterSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsletterSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsletterSubscriptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllNewsletterSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>(AllNewsletterSubscriptionsDocument, baseOptions);
      }
export function useAllNewsletterSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>(AllNewsletterSubscriptionsDocument, baseOptions);
        }
export type AllNewsletterSubscriptionsQueryHookResult = ReturnType<typeof useAllNewsletterSubscriptionsQuery>;
export type AllNewsletterSubscriptionsLazyQueryHookResult = ReturnType<typeof useAllNewsletterSubscriptionsLazyQuery>;
export type AllNewsletterSubscriptionsQueryResult = ApolloReactCommon.QueryResult<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>;
export const GroupPageDocument = gql`
    query GroupPage($projectName: String!, $entityName: String, $groupName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    totalRunsWithGroup
    totalRunsWithJobType
    access
    tags {
      name
      colorIndex
    }
    readOnly
    runGroup(name: $groupName) {
      id
      name
      notes
      tags {
        name
        colorIndex
      }
    }
    entity {
      id
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type GroupPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupPageQuery, GroupPageQueryVariables>, 'query'> & ({ variables: GroupPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupPageComponent = (props: GroupPageComponentProps) => (
      <ApolloReactComponents.Query<GroupPageQuery, GroupPageQueryVariables> query={GroupPageDocument} {...props} />
    );
    
export type GroupPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupPageQuery, GroupPageQueryVariables>
    } & TChildProps;
export function withGroupPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupPageQuery,
  GroupPageQueryVariables,
  GroupPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupPageQuery, GroupPageQueryVariables, GroupPageProps<TChildProps, TDataName>>(GroupPageDocument, {
      alias: 'groupPage',
      ...operationOptions
    });
};

/**
 * __useGroupPageQuery__
 *
 * To run a query within a React component, call `useGroupPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useGroupPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupPageQuery, GroupPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupPageQuery, GroupPageQueryVariables>(GroupPageDocument, baseOptions);
      }
export function useGroupPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupPageQuery, GroupPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupPageQuery, GroupPageQueryVariables>(GroupPageDocument, baseOptions);
        }
export type GroupPageQueryHookResult = ReturnType<typeof useGroupPageQuery>;
export type GroupPageLazyQueryHookResult = ReturnType<typeof useGroupPageLazyQuery>;
export type GroupPageQueryResult = ApolloReactCommon.QueryResult<GroupPageQuery, GroupPageQueryVariables>;
export const HistoryKeysDocument = gql`
    query HistoryKeys($projectName: String!, $entityName: String!, $filters: JSONString, $limit: Int = 100, $order: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters, first: $limit, order: $order) {
      historyKeys
      edges {
        node {
          id
          wandbConfig(keys: ["viz", "visualize"])
        }
      }
    }
  }
}
    `;
export type HistoryKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HistoryKeysQuery, HistoryKeysQueryVariables>, 'query'> & ({ variables: HistoryKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HistoryKeysComponent = (props: HistoryKeysComponentProps) => (
      <ApolloReactComponents.Query<HistoryKeysQuery, HistoryKeysQueryVariables> query={HistoryKeysDocument} {...props} />
    );
    
export type HistoryKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HistoryKeysQuery, HistoryKeysQueryVariables>
    } & TChildProps;
export function withHistoryKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HistoryKeysQuery,
  HistoryKeysQueryVariables,
  HistoryKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HistoryKeysQuery, HistoryKeysQueryVariables, HistoryKeysProps<TChildProps, TDataName>>(HistoryKeysDocument, {
      alias: 'historyKeys',
      ...operationOptions
    });
};

/**
 * __useHistoryKeysQuery__
 *
 * To run a query within a React component, call `useHistoryKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryKeysQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHistoryKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HistoryKeysQuery, HistoryKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<HistoryKeysQuery, HistoryKeysQueryVariables>(HistoryKeysDocument, baseOptions);
      }
export function useHistoryKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HistoryKeysQuery, HistoryKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HistoryKeysQuery, HistoryKeysQueryVariables>(HistoryKeysDocument, baseOptions);
        }
export type HistoryKeysQueryHookResult = ReturnType<typeof useHistoryKeysQuery>;
export type HistoryKeysLazyQueryHookResult = ReturnType<typeof useHistoryKeysLazyQuery>;
export type HistoryKeysQueryResult = ApolloReactCommon.QueryResult<HistoryKeysQuery, HistoryKeysQueryVariables>;
export const InviteDocument = gql`
    query Invite($key: String!) {
  invite(key: $key) {
    toUser {
      username
    }
  }
}
    `;
export type InviteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InviteQuery, InviteQueryVariables>, 'query'> & ({ variables: InviteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InviteComponent = (props: InviteComponentProps) => (
      <ApolloReactComponents.Query<InviteQuery, InviteQueryVariables> query={InviteDocument} {...props} />
    );
    
export type InviteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InviteQuery, InviteQueryVariables>
    } & TChildProps;
export function withInvite<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteQuery,
  InviteQueryVariables,
  InviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InviteQuery, InviteQueryVariables, InviteProps<TChildProps, TDataName>>(InviteDocument, {
      alias: 'invite',
      ...operationOptions
    });
};

/**
 * __useInviteQuery__
 *
 * To run a query within a React component, call `useInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useInviteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InviteQuery, InviteQueryVariables>) {
        return ApolloReactHooks.useQuery<InviteQuery, InviteQueryVariables>(InviteDocument, baseOptions);
      }
export function useInviteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InviteQuery, InviteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InviteQuery, InviteQueryVariables>(InviteDocument, baseOptions);
        }
export type InviteQueryHookResult = ReturnType<typeof useInviteQuery>;
export type InviteLazyQueryHookResult = ReturnType<typeof useInviteLazyQuery>;
export type InviteQueryResult = ApolloReactCommon.QueryResult<InviteQuery, InviteQueryVariables>;
export const MessageOfTheDayDocument = gql`
    query MessageOfTheDay {
  serverInfo {
    messageOfTheDay
  }
}
    `;
export type MessageOfTheDayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>, 'query'>;

    export const MessageOfTheDayComponent = (props: MessageOfTheDayComponentProps) => (
      <ApolloReactComponents.Query<MessageOfTheDayQuery, MessageOfTheDayQueryVariables> query={MessageOfTheDayDocument} {...props} />
    );
    
export type MessageOfTheDayProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>
    } & TChildProps;
export function withMessageOfTheDay<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MessageOfTheDayQuery,
  MessageOfTheDayQueryVariables,
  MessageOfTheDayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MessageOfTheDayQuery, MessageOfTheDayQueryVariables, MessageOfTheDayProps<TChildProps, TDataName>>(MessageOfTheDayDocument, {
      alias: 'messageOfTheDay',
      ...operationOptions
    });
};

/**
 * __useMessageOfTheDayQuery__
 *
 * To run a query within a React component, call `useMessageOfTheDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageOfTheDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageOfTheDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageOfTheDayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>) {
        return ApolloReactHooks.useQuery<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>(MessageOfTheDayDocument, baseOptions);
      }
export function useMessageOfTheDayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>(MessageOfTheDayDocument, baseOptions);
        }
export type MessageOfTheDayQueryHookResult = ReturnType<typeof useMessageOfTheDayQuery>;
export type MessageOfTheDayLazyQueryHookResult = ReturnType<typeof useMessageOfTheDayLazyQuery>;
export type MessageOfTheDayQueryResult = ApolloReactCommon.QueryResult<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>;
export const MoveViewDocument = gql`
    mutation moveView($id: ID!, $entityName: String!, $projectName: String!, $clientMutationId: String) {
  moveView(input: {id: $id, entityName: $entityName, projectName: $projectName, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type MoveViewMutationFn = ApolloReactCommon.MutationFunction<MoveViewMutation, MoveViewMutationVariables>;
export type MoveViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveViewMutation, MoveViewMutationVariables>, 'mutation'>;

    export const MoveViewComponent = (props: MoveViewComponentProps) => (
      <ApolloReactComponents.Mutation<MoveViewMutation, MoveViewMutationVariables> mutation={MoveViewDocument} {...props} />
    );
    
export type MoveViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveViewMutation, MoveViewMutationVariables>
    } & TChildProps;
export function withMoveView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveViewMutation,
  MoveViewMutationVariables,
  MoveViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveViewMutation, MoveViewMutationVariables, MoveViewProps<TChildProps, TDataName>>(MoveViewDocument, {
      alias: 'moveView',
      ...operationOptions
    });
};

/**
 * __useMoveViewMutation__
 *
 * To run a mutation, you first call `useMoveViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveViewMutation, { data, loading, error }] = useMoveViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useMoveViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveViewMutation, MoveViewMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveViewMutation, MoveViewMutationVariables>(MoveViewDocument, baseOptions);
      }
export type MoveViewMutationHookResult = ReturnType<typeof useMoveViewMutation>;
export type MoveViewMutationResult = ApolloReactCommon.MutationResult<MoveViewMutation>;
export type MoveViewMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveViewMutation, MoveViewMutationVariables>;
export const MultiRunWorkspaceDocument = gql`
    query MultiRunWorkspace($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    totalRunsWithGroup
    totalRunsWithJobType
    readOnly
    isBenchmark
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    runQueues {
      id
      name
      createdBy
      access
    }
  }
}
    `;
export type MultiRunWorkspaceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>, 'query'> & ({ variables: MultiRunWorkspaceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MultiRunWorkspaceComponent = (props: MultiRunWorkspaceComponentProps) => (
      <ApolloReactComponents.Query<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables> query={MultiRunWorkspaceDocument} {...props} />
    );
    
export type MultiRunWorkspaceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>
    } & TChildProps;
export function withMultiRunWorkspace<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MultiRunWorkspaceQuery,
  MultiRunWorkspaceQueryVariables,
  MultiRunWorkspaceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables, MultiRunWorkspaceProps<TChildProps, TDataName>>(MultiRunWorkspaceDocument, {
      alias: 'multiRunWorkspace',
      ...operationOptions
    });
};

/**
 * __useMultiRunWorkspaceQuery__
 *
 * To run a query within a React component, call `useMultiRunWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiRunWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiRunWorkspaceQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useMultiRunWorkspaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>) {
        return ApolloReactHooks.useQuery<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>(MultiRunWorkspaceDocument, baseOptions);
      }
export function useMultiRunWorkspaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>(MultiRunWorkspaceDocument, baseOptions);
        }
export type MultiRunWorkspaceQueryHookResult = ReturnType<typeof useMultiRunWorkspaceQuery>;
export type MultiRunWorkspaceLazyQueryHookResult = ReturnType<typeof useMultiRunWorkspaceLazyQuery>;
export type MultiRunWorkspaceQueryResult = ApolloReactCommon.QueryResult<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>;
export const OrganizationPageDocument = gql`
    query OrganizationPage($name: String!) {
  organization(name: $name) {
    id
    name
    members {
      id
      name
      username
    }
    subscriptions {
      privileges
    }
  }
}
    `;
export type OrganizationPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationPageQuery, OrganizationPageQueryVariables>, 'query'> & ({ variables: OrganizationPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationPageComponent = (props: OrganizationPageComponentProps) => (
      <ApolloReactComponents.Query<OrganizationPageQuery, OrganizationPageQueryVariables> query={OrganizationPageDocument} {...props} />
    );
    
export type OrganizationPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationPageQuery, OrganizationPageQueryVariables>
    } & TChildProps;
export function withOrganizationPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationPageQuery,
  OrganizationPageQueryVariables,
  OrganizationPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationPageQuery, OrganizationPageQueryVariables, OrganizationPageProps<TChildProps, TDataName>>(OrganizationPageDocument, {
      alias: 'organizationPage',
      ...operationOptions
    });
};

/**
 * __useOrganizationPageQuery__
 *
 * To run a query within a React component, call `useOrganizationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPageQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationPageQuery, OrganizationPageQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationPageQuery, OrganizationPageQueryVariables>(OrganizationPageDocument, baseOptions);
      }
export function useOrganizationPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationPageQuery, OrganizationPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationPageQuery, OrganizationPageQueryVariables>(OrganizationPageDocument, baseOptions);
        }
export type OrganizationPageQueryHookResult = ReturnType<typeof useOrganizationPageQuery>;
export type OrganizationPageLazyQueryHookResult = ReturnType<typeof useOrganizationPageLazyQuery>;
export type OrganizationPageQueryResult = ApolloReactCommon.QueryResult<OrganizationPageQuery, OrganizationPageQueryVariables>;
export const ParameterImportanceDocument = gql`
    query ParameterImportance($projectName: String!, $entityName: String, $limit: Int!, $targetName: String!, $filters: JSONString, $parameters: [String!], $runParameters: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    parameterImportance(limit: $limit, filters: $filters, target: $targetName, parameters: $parameters, runParameters: $runParameters)
  }
}
    `;
export type ParameterImportanceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>, 'query'> & ({ variables: ParameterImportanceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ParameterImportanceComponent = (props: ParameterImportanceComponentProps) => (
      <ApolloReactComponents.Query<ParameterImportanceQuery, ParameterImportanceQueryVariables> query={ParameterImportanceDocument} {...props} />
    );
    
export type ParameterImportanceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ParameterImportanceQuery, ParameterImportanceQueryVariables>
    } & TChildProps;
export function withParameterImportance<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ParameterImportanceQuery,
  ParameterImportanceQueryVariables,
  ParameterImportanceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ParameterImportanceQuery, ParameterImportanceQueryVariables, ParameterImportanceProps<TChildProps, TDataName>>(ParameterImportanceDocument, {
      alias: 'parameterImportance',
      ...operationOptions
    });
};

/**
 * __useParameterImportanceQuery__
 *
 * To run a query within a React component, call `useParameterImportanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useParameterImportanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParameterImportanceQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      limit: // value for 'limit'
 *      targetName: // value for 'targetName'
 *      filters: // value for 'filters'
 *      parameters: // value for 'parameters'
 *      runParameters: // value for 'runParameters'
 *   },
 * });
 */
export function useParameterImportanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>) {
        return ApolloReactHooks.useQuery<ParameterImportanceQuery, ParameterImportanceQueryVariables>(ParameterImportanceDocument, baseOptions);
      }
export function useParameterImportanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParameterImportanceQuery, ParameterImportanceQueryVariables>(ParameterImportanceDocument, baseOptions);
        }
export type ParameterImportanceQueryHookResult = ReturnType<typeof useParameterImportanceQuery>;
export type ParameterImportanceLazyQueryHookResult = ReturnType<typeof useParameterImportanceLazyQuery>;
export type ParameterImportanceQueryResult = ApolloReactCommon.QueryResult<ParameterImportanceQuery, ParameterImportanceQueryVariables>;
export const ProjectArtifactsDocument = gql`
    query ProjectArtifacts($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          name
          description
          artifactSequences(order: "-createdAt") {
            edges {
              node {
                id
                name
                description
                createdAt
                artifacts(first: 1) {
                  totalCount
                  edges {
                    node {
                      id
                      commitHash
                    }
                  }
                }
              }
            }
            totalCount
          }
        }
      }
    }
  }
}
    `;
export type ProjectArtifactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>, 'query'> & ({ variables: ProjectArtifactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectArtifactsComponent = (props: ProjectArtifactsComponentProps) => (
      <ApolloReactComponents.Query<ProjectArtifactsQuery, ProjectArtifactsQueryVariables> query={ProjectArtifactsDocument} {...props} />
    );
    
export type ProjectArtifactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>
    } & TChildProps;
export function withProjectArtifacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectArtifactsQuery,
  ProjectArtifactsQueryVariables,
  ProjectArtifactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectArtifactsQuery, ProjectArtifactsQueryVariables, ProjectArtifactsProps<TChildProps, TDataName>>(ProjectArtifactsDocument, {
      alias: 'projectArtifacts',
      ...operationOptions
    });
};

/**
 * __useProjectArtifactsQuery__
 *
 * To run a query within a React component, call `useProjectArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectArtifactsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectArtifactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>(ProjectArtifactsDocument, baseOptions);
      }
export function useProjectArtifactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>(ProjectArtifactsDocument, baseOptions);
        }
export type ProjectArtifactsQueryHookResult = ReturnType<typeof useProjectArtifactsQuery>;
export type ProjectArtifactsLazyQueryHookResult = ReturnType<typeof useProjectArtifactsLazyQuery>;
export type ProjectArtifactsQueryResult = ApolloReactCommon.QueryResult<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>;
export const ProjectFieldsDocument = gql`
    query ProjectFields($projectName: String!, $entityName: String!, $types: [String!]!, $columns: [String!]!, $pattern: String, $count: Int!, $cursor: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    fields(types: $types, columns: $columns, pattern: $pattern, first: $count, after: $cursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          path
          type
        }
      }
    }
  }
}
    `;
export type ProjectFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>, 'query'> & ({ variables: ProjectFieldsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectFieldsComponent = (props: ProjectFieldsComponentProps) => (
      <ApolloReactComponents.Query<ProjectFieldsQuery, ProjectFieldsQueryVariables> query={ProjectFieldsDocument} {...props} />
    );
    
export type ProjectFieldsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectFieldsQuery, ProjectFieldsQueryVariables>
    } & TChildProps;
export function withProjectFields<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectFieldsQuery,
  ProjectFieldsQueryVariables,
  ProjectFieldsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectFieldsQuery, ProjectFieldsQueryVariables, ProjectFieldsProps<TChildProps, TDataName>>(ProjectFieldsDocument, {
      alias: 'projectFields',
      ...operationOptions
    });
};

/**
 * __useProjectFieldsQuery__
 *
 * To run a query within a React component, call `useProjectFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFieldsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      types: // value for 'types'
 *      columns: // value for 'columns'
 *      pattern: // value for 'pattern'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useProjectFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectFieldsQuery, ProjectFieldsQueryVariables>(ProjectFieldsDocument, baseOptions);
      }
export function useProjectFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectFieldsQuery, ProjectFieldsQueryVariables>(ProjectFieldsDocument, baseOptions);
        }
export type ProjectFieldsQueryHookResult = ReturnType<typeof useProjectFieldsQuery>;
export type ProjectFieldsLazyQueryHookResult = ReturnType<typeof useProjectFieldsLazyQuery>;
export type ProjectFieldsQueryResult = ApolloReactCommon.QueryResult<ProjectFieldsQuery, ProjectFieldsQueryVariables>;
export const ProjectPageDocument = gql`
    query ProjectPage($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
    lastActive
    access
    description
    readOnly
    totalRuns
    totalRunTime
    computeHours
    totalRunsWithGroup
    totalRunsWithJobType
    totalSweeps
    totalUsers
    runCount
    isBenchmark
    views
    runQueues {
      createdAt
      createdBy
      access
      updatedAt
      id
      name
      runQueueItems {
        edges {
          node {
            createdAt
            updatedAt
            id
            runSpec
            state
            associatedRunId
          }
        }
      }
    }
    user {
      id
      username
      photoUrl
    }
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    reports: allViews(viewType: "runs") {
      edges {
        node {
          ...ViewFragmentMetadata
        }
      }
    }
    entity {
      id
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
      claimingEntity {
        id
        name
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type ProjectPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectPageQuery, ProjectPageQueryVariables>, 'query'> & ({ variables: ProjectPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectPageComponent = (props: ProjectPageComponentProps) => (
      <ApolloReactComponents.Query<ProjectPageQuery, ProjectPageQueryVariables> query={ProjectPageDocument} {...props} />
    );
    
export type ProjectPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectPageQuery, ProjectPageQueryVariables>
    } & TChildProps;
export function withProjectPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectPageQuery,
  ProjectPageQueryVariables,
  ProjectPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectPageQuery, ProjectPageQueryVariables, ProjectPageProps<TChildProps, TDataName>>(ProjectPageDocument, {
      alias: 'projectPage',
      ...operationOptions
    });
};

/**
 * __useProjectPageQuery__
 *
 * To run a query within a React component, call `useProjectPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, baseOptions);
      }
export function useProjectPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, baseOptions);
        }
export type ProjectPageQueryHookResult = ReturnType<typeof useProjectPageQuery>;
export type ProjectPageLazyQueryHookResult = ReturnType<typeof useProjectPageLazyQuery>;
export type ProjectPageQueryResult = ApolloReactCommon.QueryResult<ProjectPageQuery, ProjectPageQueryVariables>;
export const ProjectTagsDocument = gql`
    query ProjectTags($projectName: String!, $entityName: String, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    tagCounts(filters: $filters) {
      name
      count
    }
  }
}
    `;
export type ProjectTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectTagsQuery, ProjectTagsQueryVariables>, 'query'> & ({ variables: ProjectTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectTagsComponent = (props: ProjectTagsComponentProps) => (
      <ApolloReactComponents.Query<ProjectTagsQuery, ProjectTagsQueryVariables> query={ProjectTagsDocument} {...props} />
    );
    
export type ProjectTagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectTagsQuery, ProjectTagsQueryVariables>
    } & TChildProps;
export function withProjectTags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectTagsQuery,
  ProjectTagsQueryVariables,
  ProjectTagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectTagsQuery, ProjectTagsQueryVariables, ProjectTagsProps<TChildProps, TDataName>>(ProjectTagsDocument, {
      alias: 'projectTags',
      ...operationOptions
    });
};

/**
 * __useProjectTagsQuery__
 *
 * To run a query within a React component, call `useProjectTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTagsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, baseOptions);
      }
export function useProjectTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, baseOptions);
        }
export type ProjectTagsQueryHookResult = ReturnType<typeof useProjectTagsQuery>;
export type ProjectTagsLazyQueryHookResult = ReturnType<typeof useProjectTagsLazyQuery>;
export type ProjectTagsQueryResult = ApolloReactCommon.QueryResult<ProjectTagsQuery, ProjectTagsQueryVariables>;
export const ReportPageDocument = gql`
    query ReportPage($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    readOnly
    entity {
      id
      name
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type ReportPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportPageQuery, ReportPageQueryVariables>, 'query'> & ({ variables: ReportPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportPageComponent = (props: ReportPageComponentProps) => (
      <ApolloReactComponents.Query<ReportPageQuery, ReportPageQueryVariables> query={ReportPageDocument} {...props} />
    );
    
export type ReportPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportPageQuery, ReportPageQueryVariables>
    } & TChildProps;
export function withReportPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportPageQuery,
  ReportPageQueryVariables,
  ReportPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportPageQuery, ReportPageQueryVariables, ReportPageProps<TChildProps, TDataName>>(ReportPageDocument, {
      alias: 'reportPage',
      ...operationOptions
    });
};

/**
 * __useReportPageQuery__
 *
 * To run a query within a React component, call `useReportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useReportPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportPageQuery, ReportPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportPageQuery, ReportPageQueryVariables>(ReportPageDocument, baseOptions);
      }
export function useReportPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportPageQuery, ReportPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportPageQuery, ReportPageQueryVariables>(ReportPageDocument, baseOptions);
        }
export type ReportPageQueryHookResult = ReturnType<typeof useReportPageQuery>;
export type ReportPageLazyQueryHookResult = ReturnType<typeof useReportPageLazyQuery>;
export type ReportPageQueryResult = ApolloReactCommon.QueryResult<ReportPageQuery, ReportPageQueryVariables>;
export const TeamMembersDocument = gql`
    query TeamMembers($entityName: String!) {
  entity(name: $entityName) {
    id
    members {
      id
      username
      name
      photoUrl
    }
  }
}
    `;
export type TeamMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamMembersQuery, TeamMembersQueryVariables>, 'query'> & ({ variables: TeamMembersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamMembersComponent = (props: TeamMembersComponentProps) => (
      <ApolloReactComponents.Query<TeamMembersQuery, TeamMembersQueryVariables> query={TeamMembersDocument} {...props} />
    );
    
export type TeamMembersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamMembersQuery, TeamMembersQueryVariables>
    } & TChildProps;
export function withTeamMembers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamMembersQuery,
  TeamMembersQueryVariables,
  TeamMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamMembersQuery, TeamMembersQueryVariables, TeamMembersProps<TChildProps, TDataName>>(TeamMembersDocument, {
      alias: 'teamMembers',
      ...operationOptions
    });
};

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, baseOptions);
      }
export function useTeamMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, baseOptions);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = ApolloReactCommon.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const ReportDocument = gql`
    query Report($viewId: ID!) {
  view(id: $viewId) {
    id
    type
    ...ViewFragmentMetadata2
    spec
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type ReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportQuery, ReportQueryVariables>, 'query'> & ({ variables: ReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportComponent = (props: ReportComponentProps) => (
      <ApolloReactComponents.Query<ReportQuery, ReportQueryVariables> query={ReportDocument} {...props} />
    );
    
export type ReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportQuery, ReportQueryVariables>
    } & TChildProps;
export function withReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportQuery,
  ReportQueryVariables,
  ReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportQuery, ReportQueryVariables, ReportProps<TChildProps, TDataName>>(ReportDocument, {
      alias: 'report',
      ...operationOptions
    });
};

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
      }
export function useReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = ApolloReactCommon.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportTableDocument = gql`
    query ReportTable($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    readOnly
    reportDrafts: allViews(viewType: "runs/draft") {
      edges {
        node {
          ...ViewFragmentMetadata
        }
      }
    }
    reports: allViews(viewType: "runs") {
      edges {
        node {
          ...ViewFragmentMetadata
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type ReportTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportTableQuery, ReportTableQueryVariables>, 'query'> & ({ variables: ReportTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportTableComponent = (props: ReportTableComponentProps) => (
      <ApolloReactComponents.Query<ReportTableQuery, ReportTableQueryVariables> query={ReportTableDocument} {...props} />
    );
    
export type ReportTableProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportTableQuery, ReportTableQueryVariables>
    } & TChildProps;
export function withReportTable<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportTableQuery,
  ReportTableQueryVariables,
  ReportTableProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportTableQuery, ReportTableQueryVariables, ReportTableProps<TChildProps, TDataName>>(ReportTableDocument, {
      alias: 'reportTable',
      ...operationOptions
    });
};

/**
 * __useReportTableQuery__
 *
 * To run a query within a React component, call `useReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTableQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useReportTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportTableQuery, ReportTableQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportTableQuery, ReportTableQueryVariables>(ReportTableDocument, baseOptions);
      }
export function useReportTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportTableQuery, ReportTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportTableQuery, ReportTableQueryVariables>(ReportTableDocument, baseOptions);
        }
export type ReportTableQueryHookResult = ReturnType<typeof useReportTableQuery>;
export type ReportTableLazyQueryHookResult = ReturnType<typeof useReportTableLazyQuery>;
export type ReportTableQueryResult = ApolloReactCommon.QueryResult<ReportTableQuery, ReportTableQueryVariables>;
export const RunArtifactVersionsDocument = gql`
    query RunArtifactVersions($projectName: String!, $entityName: String!, $runName: String!, $pageSize: Int = 10000, $includeOutput: Boolean = false, $includeInput: Boolean = false) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      outputArtifacts(first: $pageSize) @include(if: $includeOutput) {
        totalCount
        edges {
          node {
            artifactType {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            id
            digest
            commitHash
            versionIndex
            artifactSequence {
              id
              name
            }
            aliases {
              artifactCollectionName
              alias
            }
            usedBy {
              totalCount
            }
          }
        }
      }
      inputArtifacts(first: $pageSize) @include(if: $includeInput) {
        totalCount
        edges {
          node {
            artifactType {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            id
            digest
            commitHash
            versionIndex
            artifactSequence {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            aliases {
              artifactCollectionName
              alias
            }
            usedBy(first: 0) {
              totalCount
            }
          }
          usedAs
        }
      }
    }
  }
}
    `;
export type RunArtifactVersionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>, 'query'> & ({ variables: RunArtifactVersionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunArtifactVersionsComponent = (props: RunArtifactVersionsComponentProps) => (
      <ApolloReactComponents.Query<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables> query={RunArtifactVersionsDocument} {...props} />
    );
    
export type RunArtifactVersionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>
    } & TChildProps;
export function withRunArtifactVersions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunArtifactVersionsQuery,
  RunArtifactVersionsQueryVariables,
  RunArtifactVersionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables, RunArtifactVersionsProps<TChildProps, TDataName>>(RunArtifactVersionsDocument, {
      alias: 'runArtifactVersions',
      ...operationOptions
    });
};

/**
 * __useRunArtifactVersionsQuery__
 *
 * To run a query within a React component, call `useRunArtifactVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunArtifactVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunArtifactVersionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      pageSize: // value for 'pageSize'
 *      includeOutput: // value for 'includeOutput'
 *      includeInput: // value for 'includeInput'
 *   },
 * });
 */
export function useRunArtifactVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>(RunArtifactVersionsDocument, baseOptions);
      }
export function useRunArtifactVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>(RunArtifactVersionsDocument, baseOptions);
        }
export type RunArtifactVersionsQueryHookResult = ReturnType<typeof useRunArtifactVersionsQuery>;
export type RunArtifactVersionsLazyQueryHookResult = ReturnType<typeof useRunArtifactVersionsLazyQuery>;
export type RunArtifactVersionsQueryResult = ApolloReactCommon.QueryResult<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>;
export const RunFilesDocument = gql`
    query RunFiles($projectName: String!, $entityName: String, $runName: String!, $maxNumFiles: Int!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      files(first: $maxNumFiles) {
        edges {
          node {
            id
            name
            url(upload: false)
            sizeBytes
            updatedAt
            artifact {
              id
              digest
            }
          }
        }
      }
    }
  }
}
    `;
export type RunFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunFilesQuery, RunFilesQueryVariables>, 'query'> & ({ variables: RunFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunFilesComponent = (props: RunFilesComponentProps) => (
      <ApolloReactComponents.Query<RunFilesQuery, RunFilesQueryVariables> query={RunFilesDocument} {...props} />
    );
    
export type RunFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunFilesQuery, RunFilesQueryVariables>
    } & TChildProps;
export function withRunFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunFilesQuery,
  RunFilesQueryVariables,
  RunFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunFilesQuery, RunFilesQueryVariables, RunFilesProps<TChildProps, TDataName>>(RunFilesDocument, {
      alias: 'runFiles',
      ...operationOptions
    });
};

/**
 * __useRunFilesQuery__
 *
 * To run a query within a React component, call `useRunFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFilesQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      maxNumFiles: // value for 'maxNumFiles'
 *   },
 * });
 */
export function useRunFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunFilesQuery, RunFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<RunFilesQuery, RunFilesQueryVariables>(RunFilesDocument, baseOptions);
      }
export function useRunFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunFilesQuery, RunFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunFilesQuery, RunFilesQueryVariables>(RunFilesDocument, baseOptions);
        }
export type RunFilesQueryHookResult = ReturnType<typeof useRunFilesQuery>;
export type RunFilesLazyQueryHookResult = ReturnType<typeof useRunFilesLazyQuery>;
export type RunFilesQueryResult = ApolloReactCommon.QueryResult<RunFilesQuery, RunFilesQueryVariables>;
export const RunInfoAndConfigDocument = gql`
    query RunInfoAndConfig($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
      config
    }
  }
}
    `;
export type RunInfoAndConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>, 'query'> & ({ variables: RunInfoAndConfigQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunInfoAndConfigComponent = (props: RunInfoAndConfigComponentProps) => (
      <ApolloReactComponents.Query<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables> query={RunInfoAndConfigDocument} {...props} />
    );
    
export type RunInfoAndConfigProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>
    } & TChildProps;
export function withRunInfoAndConfig<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunInfoAndConfigQuery,
  RunInfoAndConfigQueryVariables,
  RunInfoAndConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables, RunInfoAndConfigProps<TChildProps, TDataName>>(RunInfoAndConfigDocument, {
      alias: 'runInfoAndConfig',
      ...operationOptions
    });
};

/**
 * __useRunInfoAndConfigQuery__
 *
 * To run a query within a React component, call `useRunInfoAndConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunInfoAndConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunInfoAndConfigQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunInfoAndConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>(RunInfoAndConfigDocument, baseOptions);
      }
export function useRunInfoAndConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>(RunInfoAndConfigDocument, baseOptions);
        }
export type RunInfoAndConfigQueryHookResult = ReturnType<typeof useRunInfoAndConfigQuery>;
export type RunInfoAndConfigLazyQueryHookResult = ReturnType<typeof useRunInfoAndConfigLazyQuery>;
export type RunInfoAndConfigQueryResult = ApolloReactCommon.QueryResult<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>;
export const RunInfoDocument = gql`
    query RunInfo($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
    }
  }
}
    `;
export type RunInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunInfoQuery, RunInfoQueryVariables>, 'query'> & ({ variables: RunInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunInfoComponent = (props: RunInfoComponentProps) => (
      <ApolloReactComponents.Query<RunInfoQuery, RunInfoQueryVariables> query={RunInfoDocument} {...props} />
    );
    
export type RunInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunInfoQuery, RunInfoQueryVariables>
    } & TChildProps;
export function withRunInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunInfoQuery,
  RunInfoQueryVariables,
  RunInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunInfoQuery, RunInfoQueryVariables, RunInfoProps<TChildProps, TDataName>>(RunInfoDocument, {
      alias: 'runInfo',
      ...operationOptions
    });
};

/**
 * __useRunInfoQuery__
 *
 * To run a query within a React component, call `useRunInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunInfoQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunInfoQuery, RunInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RunInfoQuery, RunInfoQueryVariables>(RunInfoDocument, baseOptions);
      }
export function useRunInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunInfoQuery, RunInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunInfoQuery, RunInfoQueryVariables>(RunInfoDocument, baseOptions);
        }
export type RunInfoQueryHookResult = ReturnType<typeof useRunInfoQuery>;
export type RunInfoLazyQueryHookResult = ReturnType<typeof useRunInfoLazyQuery>;
export type RunInfoQueryResult = ApolloReactCommon.QueryResult<RunInfoQuery, RunInfoQueryVariables>;
export const RunKeysDocument = gql`
    query RunKeys($entityName: String!, $projectName: String!, $types: [String!] = ["number", "string", "boolean", "other"], $limit: Int = 1000) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    fields(types: $types, first: $limit) {
      edges {
        node {
          path
        }
      }
    }
  }
}
    `;
export type RunKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunKeysQuery, RunKeysQueryVariables>, 'query'> & ({ variables: RunKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunKeysComponent = (props: RunKeysComponentProps) => (
      <ApolloReactComponents.Query<RunKeysQuery, RunKeysQueryVariables> query={RunKeysDocument} {...props} />
    );
    
export type RunKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunKeysQuery, RunKeysQueryVariables>
    } & TChildProps;
export function withRunKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunKeysQuery,
  RunKeysQueryVariables,
  RunKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunKeysQuery, RunKeysQueryVariables, RunKeysProps<TChildProps, TDataName>>(RunKeysDocument, {
      alias: 'runKeys',
      ...operationOptions
    });
};

/**
 * __useRunKeysQuery__
 *
 * To run a query within a React component, call `useRunKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunKeysQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      types: // value for 'types'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRunKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunKeysQuery, RunKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<RunKeysQuery, RunKeysQueryVariables>(RunKeysDocument, baseOptions);
      }
export function useRunKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunKeysQuery, RunKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunKeysQuery, RunKeysQueryVariables>(RunKeysDocument, baseOptions);
        }
export type RunKeysQueryHookResult = ReturnType<typeof useRunKeysQuery>;
export type RunKeysLazyQueryHookResult = ReturnType<typeof useRunKeysLazyQuery>;
export type RunKeysQueryResult = ApolloReactCommon.QueryResult<RunKeysQuery, RunKeysQueryVariables>;
export const RunSelectorDocument = gql`
    query RunSelector($name: String!, $entityName: String, $tempFilters: JSONString) {
  project(name: $name, entityName: $entityName) {
    id
    runCount
    fields(types: ["number", "string", "boolean", "other"], first: 1000) {
      edges {
        node {
          path
        }
      }
    }
    tags {
      name
      colorIndex
    }
    tagCounts(filters: $tempFilters) {
      name
      count
    }
  }
}
    `;
export type RunSelectorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorQuery, RunSelectorQueryVariables>, 'query'> & ({ variables: RunSelectorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorComponent = (props: RunSelectorComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorQuery, RunSelectorQueryVariables> query={RunSelectorDocument} {...props} />
    );
    
export type RunSelectorProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorQuery, RunSelectorQueryVariables>
    } & TChildProps;
export function withRunSelector<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorQuery,
  RunSelectorQueryVariables,
  RunSelectorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorQuery, RunSelectorQueryVariables, RunSelectorProps<TChildProps, TDataName>>(RunSelectorDocument, {
      alias: 'runSelector',
      ...operationOptions
    });
};

/**
 * __useRunSelectorQuery__
 *
 * To run a query within a React component, call `useRunSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      tempFilters: // value for 'tempFilters'
 *   },
 * });
 */
export function useRunSelectorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorQuery, RunSelectorQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorQuery, RunSelectorQueryVariables>(RunSelectorDocument, baseOptions);
      }
export function useRunSelectorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorQuery, RunSelectorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorQuery, RunSelectorQueryVariables>(RunSelectorDocument, baseOptions);
        }
export type RunSelectorQueryHookResult = ReturnType<typeof useRunSelectorQuery>;
export type RunSelectorLazyQueryHookResult = ReturnType<typeof useRunSelectorLazyQuery>;
export type RunSelectorQueryResult = ApolloReactCommon.QueryResult<RunSelectorQuery, RunSelectorQueryVariables>;
export const RunSetSummaryDocument = gql`
    query RunSetSummary($projectName: String!, $entityName: String!, $selections: JSONString!) {
  project(name: $projectName, entityName: $entityName) {
    id
    selectedCount: runCount(filters: $selections)
  }
}
    `;
export type RunSetSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>, 'query'> & ({ variables: RunSetSummaryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSetSummaryComponent = (props: RunSetSummaryComponentProps) => (
      <ApolloReactComponents.Query<RunSetSummaryQuery, RunSetSummaryQueryVariables> query={RunSetSummaryDocument} {...props} />
    );
    
export type RunSetSummaryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSetSummaryQuery, RunSetSummaryQueryVariables>
    } & TChildProps;
export function withRunSetSummary<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSetSummaryQuery,
  RunSetSummaryQueryVariables,
  RunSetSummaryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSetSummaryQuery, RunSetSummaryQueryVariables, RunSetSummaryProps<TChildProps, TDataName>>(RunSetSummaryDocument, {
      alias: 'runSetSummary',
      ...operationOptions
    });
};

/**
 * __useRunSetSummaryQuery__
 *
 * To run a query within a React component, call `useRunSetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSetSummaryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useRunSetSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSetSummaryQuery, RunSetSummaryQueryVariables>(RunSetSummaryDocument, baseOptions);
      }
export function useRunSetSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSetSummaryQuery, RunSetSummaryQueryVariables>(RunSetSummaryDocument, baseOptions);
        }
export type RunSetSummaryQueryHookResult = ReturnType<typeof useRunSetSummaryQuery>;
export type RunSetSummaryLazyQueryHookResult = ReturnType<typeof useRunSetSummaryLazyQuery>;
export type RunSetSummaryQueryResult = ApolloReactCommon.QueryResult<RunSetSummaryQuery, RunSetSummaryQueryVariables>;
export const RunValueSuggestionsDocument = gql`
    query RunValueSuggestions($projectName: String!, $entityName: String, $keyPath: String!, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    runKeySuggestions(keyPath: $keyPath, filters: $filters) {
      value
      displayValue
      count
    }
  }
}
    `;
export type RunValueSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>, 'query'> & ({ variables: RunValueSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunValueSuggestionsComponent = (props: RunValueSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables> query={RunValueSuggestionsDocument} {...props} />
    );
    
export type RunValueSuggestionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>
    } & TChildProps;
export function withRunValueSuggestions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunValueSuggestionsQuery,
  RunValueSuggestionsQueryVariables,
  RunValueSuggestionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables, RunValueSuggestionsProps<TChildProps, TDataName>>(RunValueSuggestionsDocument, {
      alias: 'runValueSuggestions',
      ...operationOptions
    });
};

/**
 * __useRunValueSuggestionsQuery__
 *
 * To run a query within a React component, call `useRunValueSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunValueSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunValueSuggestionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      keyPath: // value for 'keyPath'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRunValueSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>(RunValueSuggestionsDocument, baseOptions);
      }
export function useRunValueSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>(RunValueSuggestionsDocument, baseOptions);
        }
export type RunValueSuggestionsQueryHookResult = ReturnType<typeof useRunValueSuggestionsQuery>;
export type RunValueSuggestionsLazyQueryHookResult = ReturnType<typeof useRunValueSuggestionsLazyQuery>;
export type RunValueSuggestionsQueryResult = ApolloReactCommon.QueryResult<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>;
export const ViewSpecDocument = gql`
    query ViewSpec($id: ID!) {
  view(id: $id) {
    id
    spec
  }
}
    `;
export type ViewSpecComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewSpecQuery, ViewSpecQueryVariables>, 'query'> & ({ variables: ViewSpecQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewSpecComponent = (props: ViewSpecComponentProps) => (
      <ApolloReactComponents.Query<ViewSpecQuery, ViewSpecQueryVariables> query={ViewSpecDocument} {...props} />
    );
    
export type ViewSpecProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewSpecQuery, ViewSpecQueryVariables>
    } & TChildProps;
export function withViewSpec<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewSpecQuery,
  ViewSpecQueryVariables,
  ViewSpecProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewSpecQuery, ViewSpecQueryVariables, ViewSpecProps<TChildProps, TDataName>>(ViewSpecDocument, {
      alias: 'viewSpec',
      ...operationOptions
    });
};

/**
 * __useViewSpecQuery__
 *
 * To run a query within a React component, call `useViewSpecQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewSpecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewSpecQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewSpecQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewSpecQuery, ViewSpecQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewSpecQuery, ViewSpecQueryVariables>(ViewSpecDocument, baseOptions);
      }
export function useViewSpecLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewSpecQuery, ViewSpecQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewSpecQuery, ViewSpecQueryVariables>(ViewSpecDocument, baseOptions);
        }
export type ViewSpecQueryHookResult = ReturnType<typeof useViewSpecQuery>;
export type ViewSpecLazyQueryHookResult = ReturnType<typeof useViewSpecLazyQuery>;
export type ViewSpecQueryResult = ApolloReactCommon.QueryResult<ViewSpecQuery, ViewSpecQueryVariables>;
export const SearchNavQueryDocument = gql`
    query SearchNavQuery($entityName: String, $projectName: String, $runName: String!, $sweepName: String!, $entityProjectsLimit: Int = 10000) {
  viewer {
    id
    admin
    photoUrl
    username
    organizations {
      id
      name
      orgType
    }
    projects(first: 500) {
      edges {
        node {
          id
          name
          entityName
        }
      }
    }
    teams(first: 500) {
      edges {
        node {
          id
          name
          photoUrl
          projects(first: 500) {
            edges {
              node {
                id
                name
                entityName
              }
            }
          }
        }
      }
    }
  }
  projects(entityName: $entityName, first: $entityProjectsLimit) {
    edges {
      node {
        id
        name
        entityName
      }
    }
  }
  project(entityName: $entityName, name: $projectName) {
    id
    access
    readOnly
    isBenchmark
    entity {
      id
      readOnlyAdmin
      defaultAccess
      privateOnly
      isTeam
    }
    run(name: $runName) {
      id
      displayName
      state
    }
    sweep(sweepName: $sweepName) {
      id
      name
      displayName
      config
    }
  }
}
    `;
export type SearchNavQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>, 'query'> & ({ variables: SearchNavQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchNavQueryComponent = (props: SearchNavQueryComponentProps) => (
      <ApolloReactComponents.Query<SearchNavQueryQuery, SearchNavQueryQueryVariables> query={SearchNavQueryDocument} {...props} />
    );
    
export type SearchNavQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchNavQueryQuery, SearchNavQueryQueryVariables>
    } & TChildProps;
export function withSearchNavQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchNavQueryQuery,
  SearchNavQueryQueryVariables,
  SearchNavQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchNavQueryQuery, SearchNavQueryQueryVariables, SearchNavQueryProps<TChildProps, TDataName>>(SearchNavQueryDocument, {
      alias: 'searchNavQuery',
      ...operationOptions
    });
};

/**
 * __useSearchNavQueryQuery__
 *
 * To run a query within a React component, call `useSearchNavQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNavQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNavQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *      sweepName: // value for 'sweepName'
 *      entityProjectsLimit: // value for 'entityProjectsLimit'
 *   },
 * });
 */
export function useSearchNavQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNavQueryQuery, SearchNavQueryQueryVariables>(SearchNavQueryDocument, baseOptions);
      }
export function useSearchNavQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNavQueryQuery, SearchNavQueryQueryVariables>(SearchNavQueryDocument, baseOptions);
        }
export type SearchNavQueryQueryHookResult = ReturnType<typeof useSearchNavQueryQuery>;
export type SearchNavQueryLazyQueryHookResult = ReturnType<typeof useSearchNavQueryLazyQuery>;
export type SearchNavQueryQueryResult = ApolloReactCommon.QueryResult<SearchNavQueryQuery, SearchNavQueryQueryVariables>;
export const SelectedCountDocument = gql`
    query SelectedCount($name: String!, $entityName: String, $filters: JSONString, $tempFilters: JSONString) {
  project(name: $name, entityName: $entityName) {
    id
    selectedCount: runCount(filters: $filters)
    tempSelectedCount: runCount(filters: $tempFilters)
    tempOutputArtifactsCount: runOutputArtifactsCount(filters: $tempFilters)
  }
}
    `;
export type SelectedCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SelectedCountQuery, SelectedCountQueryVariables>, 'query'> & ({ variables: SelectedCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SelectedCountComponent = (props: SelectedCountComponentProps) => (
      <ApolloReactComponents.Query<SelectedCountQuery, SelectedCountQueryVariables> query={SelectedCountDocument} {...props} />
    );
    
export type SelectedCountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SelectedCountQuery, SelectedCountQueryVariables>
    } & TChildProps;
export function withSelectedCount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelectedCountQuery,
  SelectedCountQueryVariables,
  SelectedCountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SelectedCountQuery, SelectedCountQueryVariables, SelectedCountProps<TChildProps, TDataName>>(SelectedCountDocument, {
      alias: 'selectedCount',
      ...operationOptions
    });
};

/**
 * __useSelectedCountQuery__
 *
 * To run a query within a React component, call `useSelectedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      tempFilters: // value for 'tempFilters'
 *   },
 * });
 */
export function useSelectedCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedCountQuery, SelectedCountQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedCountQuery, SelectedCountQueryVariables>(SelectedCountDocument, baseOptions);
      }
export function useSelectedCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedCountQuery, SelectedCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedCountQuery, SelectedCountQueryVariables>(SelectedCountDocument, baseOptions);
        }
export type SelectedCountQueryHookResult = ReturnType<typeof useSelectedCountQuery>;
export type SelectedCountLazyQueryHookResult = ReturnType<typeof useSelectedCountLazyQuery>;
export type SelectedCountQueryResult = ApolloReactCommon.QueryResult<SelectedCountQuery, SelectedCountQueryVariables>;
export const SingleFileDocument = gql`
    query SingleFile($projectName: String!, $entityName: String, $runName: String!, $fileName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      files(first: 1, names: [$fileName]) {
        edges {
          node {
            id
            name
            directUrl
            sizeBytes
            updatedAt
          }
        }
      }
    }
  }
}
    `;
export type SingleFileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SingleFileQuery, SingleFileQueryVariables>, 'query'> & ({ variables: SingleFileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SingleFileComponent = (props: SingleFileComponentProps) => (
      <ApolloReactComponents.Query<SingleFileQuery, SingleFileQueryVariables> query={SingleFileDocument} {...props} />
    );
    
export type SingleFileProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SingleFileQuery, SingleFileQueryVariables>
    } & TChildProps;
export function withSingleFile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SingleFileQuery,
  SingleFileQueryVariables,
  SingleFileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SingleFileQuery, SingleFileQueryVariables, SingleFileProps<TChildProps, TDataName>>(SingleFileDocument, {
      alias: 'singleFile',
      ...operationOptions
    });
};

/**
 * __useSingleFileQuery__
 *
 * To run a query within a React component, call `useSingleFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleFileQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSingleFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SingleFileQuery, SingleFileQueryVariables>) {
        return ApolloReactHooks.useQuery<SingleFileQuery, SingleFileQueryVariables>(SingleFileDocument, baseOptions);
      }
export function useSingleFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleFileQuery, SingleFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SingleFileQuery, SingleFileQueryVariables>(SingleFileDocument, baseOptions);
        }
export type SingleFileQueryHookResult = ReturnType<typeof useSingleFileQuery>;
export type SingleFileLazyQueryHookResult = ReturnType<typeof useSingleFileLazyQuery>;
export type SingleFileQueryResult = ApolloReactCommon.QueryResult<SingleFileQuery, SingleFileQueryVariables>;
export const SweepPageDocument = gql`
    query SweepPage($projectName: String!, $entityName: String!, $sweepName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    readOnly
    sweep(sweepName: $sweepName) {
      id
      name
      displayName
      runCount
      runTime
      method
      state
      description
      user {
        id
        username
        photoUrl
      }
      config
      createdAt
      heartbeatAt
      updatedAt
      controllerRunName
      priorRuns {
        edges {
          node {
            name
          }
        }
      }
      agents {
        edges {
          node {
            id
            name
            state
            host
            persistent
            user {
              id
            }
            metrics
            createdAt
            heartbeatAt
            totalRuns
          }
        }
      }
    }
    entity {
      id
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type SweepPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SweepPageQuery, SweepPageQueryVariables>, 'query'> & ({ variables: SweepPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SweepPageComponent = (props: SweepPageComponentProps) => (
      <ApolloReactComponents.Query<SweepPageQuery, SweepPageQueryVariables> query={SweepPageDocument} {...props} />
    );
    
export type SweepPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SweepPageQuery, SweepPageQueryVariables>
    } & TChildProps;
export function withSweepPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SweepPageQuery,
  SweepPageQueryVariables,
  SweepPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SweepPageQuery, SweepPageQueryVariables, SweepPageProps<TChildProps, TDataName>>(SweepPageDocument, {
      alias: 'sweepPage',
      ...operationOptions
    });
};

/**
 * __useSweepPageQuery__
 *
 * To run a query within a React component, call `useSweepPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      sweepName: // value for 'sweepName'
 *   },
 * });
 */
export function useSweepPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SweepPageQuery, SweepPageQueryVariables>) {
        return ApolloReactHooks.useQuery<SweepPageQuery, SweepPageQueryVariables>(SweepPageDocument, baseOptions);
      }
export function useSweepPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SweepPageQuery, SweepPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SweepPageQuery, SweepPageQueryVariables>(SweepPageDocument, baseOptions);
        }
export type SweepPageQueryHookResult = ReturnType<typeof useSweepPageQuery>;
export type SweepPageLazyQueryHookResult = ReturnType<typeof useSweepPageLazyQuery>;
export type SweepPageQueryResult = ApolloReactCommon.QueryResult<SweepPageQuery, SweepPageQueryVariables>;
export const SweepsDocument = gql`
    query Sweeps($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    readOnly
    sweeps {
      edges {
        node {
          id
          name
          displayName
          state
          runCount
          runTime
          createdAt
          user {
            id
            username
            photoUrl
          }
          config
        }
      }
    }
  }
}
    `;
export type SweepsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SweepsQuery, SweepsQueryVariables>, 'query'> & ({ variables: SweepsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SweepsComponent = (props: SweepsComponentProps) => (
      <ApolloReactComponents.Query<SweepsQuery, SweepsQueryVariables> query={SweepsDocument} {...props} />
    );
    
export type SweepsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SweepsQuery, SweepsQueryVariables>
    } & TChildProps;
export function withSweeps<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SweepsQuery,
  SweepsQueryVariables,
  SweepsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SweepsQuery, SweepsQueryVariables, SweepsProps<TChildProps, TDataName>>(SweepsDocument, {
      alias: 'sweeps',
      ...operationOptions
    });
};

/**
 * __useSweepsQuery__
 *
 * To run a query within a React component, call `useSweepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useSweepsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
        return ApolloReactHooks.useQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, baseOptions);
      }
export function useSweepsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, baseOptions);
        }
export type SweepsQueryHookResult = ReturnType<typeof useSweepsQuery>;
export type SweepsLazyQueryHookResult = ReturnType<typeof useSweepsLazyQuery>;
export type SweepsQueryResult = ApolloReactCommon.QueryResult<SweepsQuery, SweepsQueryVariables>;
export const UsageAdminPageDocument = gql`
    query UsageAdminPage {
  instance {
    entities {
      name
      isTeam
      user {
        name
        username
      }
    }
  }
}
    `;
export type UsageAdminPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsageAdminPageQuery, UsageAdminPageQueryVariables>, 'query'>;

    export const UsageAdminPageComponent = (props: UsageAdminPageComponentProps) => (
      <ApolloReactComponents.Query<UsageAdminPageQuery, UsageAdminPageQueryVariables> query={UsageAdminPageDocument} {...props} />
    );
    
export type UsageAdminPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UsageAdminPageQuery, UsageAdminPageQueryVariables>
    } & TChildProps;
export function withUsageAdminPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UsageAdminPageQuery,
  UsageAdminPageQueryVariables,
  UsageAdminPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UsageAdminPageQuery, UsageAdminPageQueryVariables, UsageAdminPageProps<TChildProps, TDataName>>(UsageAdminPageDocument, {
      alias: 'usageAdminPage',
      ...operationOptions
    });
};

/**
 * __useUsageAdminPageQuery__
 *
 * To run a query within a React component, call `useUsageAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageAdminPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsageAdminPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsageAdminPageQuery, UsageAdminPageQueryVariables>) {
        return ApolloReactHooks.useQuery<UsageAdminPageQuery, UsageAdminPageQueryVariables>(UsageAdminPageDocument, baseOptions);
      }
export function useUsageAdminPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsageAdminPageQuery, UsageAdminPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsageAdminPageQuery, UsageAdminPageQueryVariables>(UsageAdminPageDocument, baseOptions);
        }
export type UsageAdminPageQueryHookResult = ReturnType<typeof useUsageAdminPageQuery>;
export type UsageAdminPageLazyQueryHookResult = ReturnType<typeof useUsageAdminPageLazyQuery>;
export type UsageAdminPageQueryResult = ApolloReactCommon.QueryResult<UsageAdminPageQuery, UsageAdminPageQueryVariables>;
export const ContactUsForUserLedTrialDocument = gql`
    mutation contactUsForUserLedTrial($clientMutationId: String) {
  contactUsForUserLedTrial(input: {clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type ContactUsForUserLedTrialMutationFn = ApolloReactCommon.MutationFunction<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>;
export type ContactUsForUserLedTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>, 'mutation'>;

    export const ContactUsForUserLedTrialComponent = (props: ContactUsForUserLedTrialComponentProps) => (
      <ApolloReactComponents.Mutation<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables> mutation={ContactUsForUserLedTrialDocument} {...props} />
    );
    
export type ContactUsForUserLedTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>
    } & TChildProps;
export function withContactUsForUserLedTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ContactUsForUserLedTrialMutation,
  ContactUsForUserLedTrialMutationVariables,
  ContactUsForUserLedTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables, ContactUsForUserLedTrialProps<TChildProps, TDataName>>(ContactUsForUserLedTrialDocument, {
      alias: 'contactUsForUserLedTrial',
      ...operationOptions
    });
};

/**
 * __useContactUsForUserLedTrialMutation__
 *
 * To run a mutation, you first call `useContactUsForUserLedTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsForUserLedTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsForUserLedTrialMutation, { data, loading, error }] = useContactUsForUserLedTrialMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useContactUsForUserLedTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>(ContactUsForUserLedTrialDocument, baseOptions);
      }
export type ContactUsForUserLedTrialMutationHookResult = ReturnType<typeof useContactUsForUserLedTrialMutation>;
export type ContactUsForUserLedTrialMutationResult = ApolloReactCommon.MutationResult<ContactUsForUserLedTrialMutation>;
export type ContactUsForUserLedTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>;
export const ContactUsForComputeHoursDocument = gql`
    mutation contactUsForComputeHours($clientMutationId: String) {
  contactUsForComputeHours(input: {clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type ContactUsForComputeHoursMutationFn = ApolloReactCommon.MutationFunction<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>;
export type ContactUsForComputeHoursComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>, 'mutation'>;

    export const ContactUsForComputeHoursComponent = (props: ContactUsForComputeHoursComponentProps) => (
      <ApolloReactComponents.Mutation<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables> mutation={ContactUsForComputeHoursDocument} {...props} />
    );
    
export type ContactUsForComputeHoursProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>
    } & TChildProps;
export function withContactUsForComputeHours<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ContactUsForComputeHoursMutation,
  ContactUsForComputeHoursMutationVariables,
  ContactUsForComputeHoursProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables, ContactUsForComputeHoursProps<TChildProps, TDataName>>(ContactUsForComputeHoursDocument, {
      alias: 'contactUsForComputeHours',
      ...operationOptions
    });
};

/**
 * __useContactUsForComputeHoursMutation__
 *
 * To run a mutation, you first call `useContactUsForComputeHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsForComputeHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsForComputeHoursMutation, { data, loading, error }] = useContactUsForComputeHoursMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useContactUsForComputeHoursMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>(ContactUsForComputeHoursDocument, baseOptions);
      }
export type ContactUsForComputeHoursMutationHookResult = ReturnType<typeof useContactUsForComputeHoursMutation>;
export type ContactUsForComputeHoursMutationResult = ApolloReactCommon.MutationResult<ContactUsForComputeHoursMutation>;
export type ContactUsForComputeHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>;
export const UserProjectsDocument = gql`
    query UserProjects($userName: String!, $includeReports: Boolean!, $first: Int = 10000) {
  user(userName: $userName) {
    id
    teams {
      edges {
        node {
          id
          name
          projects(order: "lastActive", first: $first) {
            edges {
              node {
                id
                name
                entityName
                reports: allViews(viewType: "runs") @include(if: $includeReports) {
                  edges {
                    node {
                      ...ViewFragmentMetadata
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type UserProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProjectsQuery, UserProjectsQueryVariables>, 'query'> & ({ variables: UserProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProjectsComponent = (props: UserProjectsComponentProps) => (
      <ApolloReactComponents.Query<UserProjectsQuery, UserProjectsQueryVariables> query={UserProjectsDocument} {...props} />
    );
    
export type UserProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserProjectsQuery, UserProjectsQueryVariables>
    } & TChildProps;
export function withUserProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserProjectsQuery,
  UserProjectsQueryVariables,
  UserProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserProjectsQuery, UserProjectsQueryVariables, UserProjectsProps<TChildProps, TDataName>>(UserProjectsDocument, {
      alias: 'userProjects',
      ...operationOptions
    });
};

/**
 * __useUserProjectsQuery__
 *
 * To run a query within a React component, call `useUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      includeReports: // value for 'includeReports'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
      }
export function useUserProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
        }
export type UserProjectsQueryHookResult = ReturnType<typeof useUserProjectsQuery>;
export type UserProjectsLazyQueryHookResult = ReturnType<typeof useUserProjectsLazyQuery>;
export type UserProjectsQueryResult = ApolloReactCommon.QueryResult<UserProjectsQuery, UserProjectsQueryVariables>;
export const AllUserProjectsDocument = gql`
    query AllUserProjects($userName: String!, $first: Int = 10000, $filters: JSONString) {
  user(userName: $userName) {
    id
    projects(first: $first) {
      edges {
        node {
          id
          name
          entityName
          totalRuns
          lastActive
          computeHours
          filteredCount: runCount(filters: $filters)
          user {
            id
            username
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
    `;
export type AllUserProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllUserProjectsQuery, AllUserProjectsQueryVariables>, 'query'> & ({ variables: AllUserProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllUserProjectsComponent = (props: AllUserProjectsComponentProps) => (
      <ApolloReactComponents.Query<AllUserProjectsQuery, AllUserProjectsQueryVariables> query={AllUserProjectsDocument} {...props} />
    );
    
export type AllUserProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllUserProjectsQuery, AllUserProjectsQueryVariables>
    } & TChildProps;
export function withAllUserProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllUserProjectsQuery,
  AllUserProjectsQueryVariables,
  AllUserProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllUserProjectsQuery, AllUserProjectsQueryVariables, AllUserProjectsProps<TChildProps, TDataName>>(AllUserProjectsDocument, {
      alias: 'allUserProjects',
      ...operationOptions
    });
};

/**
 * __useAllUserProjectsQuery__
 *
 * To run a query within a React component, call `useAllUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserProjectsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      first: // value for 'first'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllUserProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserProjectsQuery, AllUserProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllUserProjectsQuery, AllUserProjectsQueryVariables>(AllUserProjectsDocument, baseOptions);
      }
export function useAllUserProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserProjectsQuery, AllUserProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllUserProjectsQuery, AllUserProjectsQueryVariables>(AllUserProjectsDocument, baseOptions);
        }
export type AllUserProjectsQueryHookResult = ReturnType<typeof useAllUserProjectsQuery>;
export type AllUserProjectsLazyQueryHookResult = ReturnType<typeof useAllUserProjectsLazyQuery>;
export type AllUserProjectsQueryResult = ApolloReactCommon.QueryResult<AllUserProjectsQuery, AllUserProjectsQueryVariables>;
export const AllTeamProjectsDocument = gql`
    query AllTeamProjects($entityName: String!, $first: Int = 10000) {
  projects(first: $first, entityName: $entityName, order: "lastActive") {
    edges {
      node {
        id
        name
        entityName
        totalRuns
        totalUsers
        lastActive
        totalRunTime
        computeHours
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;
export type AllTeamProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>, 'query'> & ({ variables: AllTeamProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllTeamProjectsComponent = (props: AllTeamProjectsComponentProps) => (
      <ApolloReactComponents.Query<AllTeamProjectsQuery, AllTeamProjectsQueryVariables> query={AllTeamProjectsDocument} {...props} />
    );
    
export type AllTeamProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>
    } & TChildProps;
export function withAllTeamProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllTeamProjectsQuery,
  AllTeamProjectsQueryVariables,
  AllTeamProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllTeamProjectsQuery, AllTeamProjectsQueryVariables, AllTeamProjectsProps<TChildProps, TDataName>>(AllTeamProjectsDocument, {
      alias: 'allTeamProjects',
      ...operationOptions
    });
};

/**
 * __useAllTeamProjectsQuery__
 *
 * To run a query within a React component, call `useAllTeamProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllTeamProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>(AllTeamProjectsDocument, baseOptions);
      }
export function useAllTeamProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>(AllTeamProjectsDocument, baseOptions);
        }
export type AllTeamProjectsQueryHookResult = ReturnType<typeof useAllTeamProjectsQuery>;
export type AllTeamProjectsLazyQueryHookResult = ReturnType<typeof useAllTeamProjectsLazyQuery>;
export type AllTeamProjectsQueryResult = ApolloReactCommon.QueryResult<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>;
export const UserReportsDocument = gql`
    query UserReports {
  viewer {
    id
    username
    views(viewType: "runs") {
      edges {
        node {
          id
          name
          displayName
          spec
          entity {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type UserReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserReportsQuery, UserReportsQueryVariables>, 'query'>;

    export const UserReportsComponent = (props: UserReportsComponentProps) => (
      <ApolloReactComponents.Query<UserReportsQuery, UserReportsQueryVariables> query={UserReportsDocument} {...props} />
    );
    
export type UserReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserReportsQuery, UserReportsQueryVariables>
    } & TChildProps;
export function withUserReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserReportsQuery,
  UserReportsQueryVariables,
  UserReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserReportsQuery, UserReportsQueryVariables, UserReportsProps<TChildProps, TDataName>>(UserReportsDocument, {
      alias: 'userReports',
      ...operationOptions
    });
};

/**
 * __useUserReportsQuery__
 *
 * To run a query within a React component, call `useUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserReportsQuery, UserReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserReportsQuery, UserReportsQueryVariables>(UserReportsDocument, baseOptions);
      }
export function useUserReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserReportsQuery, UserReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserReportsQuery, UserReportsQueryVariables>(UserReportsDocument, baseOptions);
        }
export type UserReportsQueryHookResult = ReturnType<typeof useUserReportsQuery>;
export type UserReportsLazyQueryHookResult = ReturnType<typeof useUserReportsLazyQuery>;
export type UserReportsQueryResult = ApolloReactCommon.QueryResult<UserReportsQuery, UserReportsQueryVariables>;
export const Vega2PanelDocument = gql`
    query Vega2Panel($projectName: String!, $entityName: String, $id: ID!, $fetchSingle: Boolean!) {
  project(name: $projectName, entityName: $entityName) {
    id
    allViews(viewType: "vega2-panel") {
      edges {
        node {
          ...ViewFragment
        }
      }
    }
  }
  view(id: $id, type: "vega2-panel") @include(if: $fetchSingle) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;
export type Vega2PanelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Vega2PanelQuery, Vega2PanelQueryVariables>, 'query'> & ({ variables: Vega2PanelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const Vega2PanelComponent = (props: Vega2PanelComponentProps) => (
      <ApolloReactComponents.Query<Vega2PanelQuery, Vega2PanelQueryVariables> query={Vega2PanelDocument} {...props} />
    );
    
export type Vega2PanelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Vega2PanelQuery, Vega2PanelQueryVariables>
    } & TChildProps;
export function withVega2Panel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Vega2PanelQuery,
  Vega2PanelQueryVariables,
  Vega2PanelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Vega2PanelQuery, Vega2PanelQueryVariables, Vega2PanelProps<TChildProps, TDataName>>(Vega2PanelDocument, {
      alias: 'vega2Panel',
      ...operationOptions
    });
};

/**
 * __useVega2PanelQuery__
 *
 * To run a query within a React component, call `useVega2PanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useVega2PanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVega2PanelQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      fetchSingle: // value for 'fetchSingle'
 *   },
 * });
 */
export function useVega2PanelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Vega2PanelQuery, Vega2PanelQueryVariables>) {
        return ApolloReactHooks.useQuery<Vega2PanelQuery, Vega2PanelQueryVariables>(Vega2PanelDocument, baseOptions);
      }
export function useVega2PanelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Vega2PanelQuery, Vega2PanelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Vega2PanelQuery, Vega2PanelQueryVariables>(Vega2PanelDocument, baseOptions);
        }
export type Vega2PanelQueryHookResult = ReturnType<typeof useVega2PanelQuery>;
export type Vega2PanelLazyQueryHookResult = ReturnType<typeof useVega2PanelLazyQuery>;
export type Vega2PanelQueryResult = ApolloReactCommon.QueryResult<Vega2PanelQuery, Vega2PanelQueryVariables>;
export const VegaPanelDocument = gql`
    query VegaPanel($projectName: String!, $entityName: String, $id: ID!, $fetchSingle: Boolean!) {
  project(name: $projectName, entityName: $entityName) {
    id
    allViews(viewType: "vega-panel") {
      edges {
        node {
          ...ViewFragment
        }
      }
    }
  }
  view(id: $id, type: "vega-panel") @include(if: $fetchSingle) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;
export type VegaPanelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VegaPanelQuery, VegaPanelQueryVariables>, 'query'> & ({ variables: VegaPanelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VegaPanelComponent = (props: VegaPanelComponentProps) => (
      <ApolloReactComponents.Query<VegaPanelQuery, VegaPanelQueryVariables> query={VegaPanelDocument} {...props} />
    );
    
export type VegaPanelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VegaPanelQuery, VegaPanelQueryVariables>
    } & TChildProps;
export function withVegaPanel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VegaPanelQuery,
  VegaPanelQueryVariables,
  VegaPanelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VegaPanelQuery, VegaPanelQueryVariables, VegaPanelProps<TChildProps, TDataName>>(VegaPanelDocument, {
      alias: 'vegaPanel',
      ...operationOptions
    });
};

/**
 * __useVegaPanelQuery__
 *
 * To run a query within a React component, call `useVegaPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useVegaPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVegaPanelQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      fetchSingle: // value for 'fetchSingle'
 *   },
 * });
 */
export function useVegaPanelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VegaPanelQuery, VegaPanelQueryVariables>) {
        return ApolloReactHooks.useQuery<VegaPanelQuery, VegaPanelQueryVariables>(VegaPanelDocument, baseOptions);
      }
export function useVegaPanelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VegaPanelQuery, VegaPanelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VegaPanelQuery, VegaPanelQueryVariables>(VegaPanelDocument, baseOptions);
        }
export type VegaPanelQueryHookResult = ReturnType<typeof useVegaPanelQuery>;
export type VegaPanelLazyQueryHookResult = ReturnType<typeof useVegaPanelLazyQuery>;
export type VegaPanelQueryResult = ApolloReactCommon.QueryResult<VegaPanelQuery, VegaPanelQueryVariables>;
export const WandbConfigDocument = gql`
    query WandbConfig($projectName: String!, $entityName: String!, $filters: JSONString, $limit: Int = 100, $order: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters, first: $limit, order: $order) {
      edges {
        node {
          id
          wandbConfig
        }
      }
    }
  }
}
    `;
export type WandbConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WandbConfigQuery, WandbConfigQueryVariables>, 'query'> & ({ variables: WandbConfigQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const WandbConfigComponent = (props: WandbConfigComponentProps) => (
      <ApolloReactComponents.Query<WandbConfigQuery, WandbConfigQueryVariables> query={WandbConfigDocument} {...props} />
    );
    
export type WandbConfigProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WandbConfigQuery, WandbConfigQueryVariables>
    } & TChildProps;
export function withWandbConfig<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WandbConfigQuery,
  WandbConfigQueryVariables,
  WandbConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WandbConfigQuery, WandbConfigQueryVariables, WandbConfigProps<TChildProps, TDataName>>(WandbConfigDocument, {
      alias: 'wandbConfig',
      ...operationOptions
    });
};

/**
 * __useWandbConfigQuery__
 *
 * To run a query within a React component, call `useWandbConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useWandbConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWandbConfigQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWandbConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WandbConfigQuery, WandbConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<WandbConfigQuery, WandbConfigQueryVariables>(WandbConfigDocument, baseOptions);
      }
export function useWandbConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WandbConfigQuery, WandbConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WandbConfigQuery, WandbConfigQueryVariables>(WandbConfigDocument, baseOptions);
        }
export type WandbConfigQueryHookResult = ReturnType<typeof useWandbConfigQuery>;
export type WandbConfigLazyQueryHookResult = ReturnType<typeof useWandbConfigLazyQuery>;
export type WandbConfigQueryResult = ApolloReactCommon.QueryResult<WandbConfigQuery, WandbConfigQueryVariables>;
export const DiscussionThreadPageDocument = gql`
    query DiscussionThreadPage($threadID: ID!) {
  discussionThread(id: $threadID) {
    id
    createdAt
    updatedAt
    title
    body
    upvotes
    poster {
      id
      photoUrl
      name
    }
    comments {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          body
          isParent
          upvotes
          poster {
            id
            photoUrl
            name
          }
        }
      }
    }
  }
  viewer {
    id
  }
}
    `;
export type DiscussionThreadPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>, 'query'> & ({ variables: DiscussionThreadPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiscussionThreadPageComponent = (props: DiscussionThreadPageComponentProps) => (
      <ApolloReactComponents.Query<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables> query={DiscussionThreadPageDocument} {...props} />
    );
    
export type DiscussionThreadPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>
    } & TChildProps;
export function withDiscussionThreadPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DiscussionThreadPageQuery,
  DiscussionThreadPageQueryVariables,
  DiscussionThreadPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables, DiscussionThreadPageProps<TChildProps, TDataName>>(DiscussionThreadPageDocument, {
      alias: 'discussionThreadPage',
      ...operationOptions
    });
};

/**
 * __useDiscussionThreadPageQuery__
 *
 * To run a query within a React component, call `useDiscussionThreadPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionThreadPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionThreadPageQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useDiscussionThreadPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>(DiscussionThreadPageDocument, baseOptions);
      }
export function useDiscussionThreadPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>(DiscussionThreadPageDocument, baseOptions);
        }
export type DiscussionThreadPageQueryHookResult = ReturnType<typeof useDiscussionThreadPageQuery>;
export type DiscussionThreadPageLazyQueryHookResult = ReturnType<typeof useDiscussionThreadPageLazyQuery>;
export type DiscussionThreadPageQueryResult = ApolloReactCommon.QueryResult<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>;
export const HomePageUserDetailsDocument = gql`
    query HomePageUserDetails($entityName: String) {
  viewer(entityName: $entityName) {
    ...HomePageFragment
  }
}
    ${HomePageFragmentFragmentDoc}`;
export type HomePageUserDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>, 'query'>;

    export const HomePageUserDetailsComponent = (props: HomePageUserDetailsComponentProps) => (
      <ApolloReactComponents.Query<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables> query={HomePageUserDetailsDocument} {...props} />
    );
    
export type HomePageUserDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>
    } & TChildProps;
export function withHomePageUserDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomePageUserDetailsQuery,
  HomePageUserDetailsQueryVariables,
  HomePageUserDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables, HomePageUserDetailsProps<TChildProps, TDataName>>(HomePageUserDetailsDocument, {
      alias: 'homePageUserDetails',
      ...operationOptions
    });
};

/**
 * __useHomePageUserDetailsQuery__
 *
 * To run a query within a React component, call `useHomePageUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageUserDetailsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useHomePageUserDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>(HomePageUserDetailsDocument, baseOptions);
      }
export function useHomePageUserDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>(HomePageUserDetailsDocument, baseOptions);
        }
export type HomePageUserDetailsQueryHookResult = ReturnType<typeof useHomePageUserDetailsQuery>;
export type HomePageUserDetailsLazyQueryHookResult = ReturnType<typeof useHomePageUserDetailsLazyQuery>;
export type HomePageUserDetailsQueryResult = ApolloReactCommon.QueryResult<HomePageUserDetailsQuery, HomePageUserDetailsQueryVariables>;
export const HomePageUserDetailsByUserDocument = gql`
    query HomePageUserDetailsByUser($userName: String!) {
  viewer: user(userName: $userName) {
    ...HomePageFragment
  }
}
    ${HomePageFragmentFragmentDoc}`;
export type HomePageUserDetailsByUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>, 'query'> & ({ variables: HomePageUserDetailsByUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HomePageUserDetailsByUserComponent = (props: HomePageUserDetailsByUserComponentProps) => (
      <ApolloReactComponents.Query<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables> query={HomePageUserDetailsByUserDocument} {...props} />
    );
    
export type HomePageUserDetailsByUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>
    } & TChildProps;
export function withHomePageUserDetailsByUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomePageUserDetailsByUserQuery,
  HomePageUserDetailsByUserQueryVariables,
  HomePageUserDetailsByUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables, HomePageUserDetailsByUserProps<TChildProps, TDataName>>(HomePageUserDetailsByUserDocument, {
      alias: 'homePageUserDetailsByUser',
      ...operationOptions
    });
};

/**
 * __useHomePageUserDetailsByUserQuery__
 *
 * To run a query within a React component, call `useHomePageUserDetailsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageUserDetailsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageUserDetailsByUserQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useHomePageUserDetailsByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>(HomePageUserDetailsByUserDocument, baseOptions);
      }
export function useHomePageUserDetailsByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>(HomePageUserDetailsByUserDocument, baseOptions);
        }
export type HomePageUserDetailsByUserQueryHookResult = ReturnType<typeof useHomePageUserDetailsByUserQuery>;
export type HomePageUserDetailsByUserLazyQueryHookResult = ReturnType<typeof useHomePageUserDetailsByUserLazyQuery>;
export type HomePageUserDetailsByUserQueryResult = ApolloReactCommon.QueryResult<HomePageUserDetailsByUserQuery, HomePageUserDetailsByUserQueryVariables>;
export const ViewerRunsDocument = gql`
    query ViewerRuns($entityName: String) {
  viewer(entityName: $entityName) {
    id
    runs(first: 100) {
      edges {
        node {
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type ViewerRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerRunsQuery, ViewerRunsQueryVariables>, 'query'>;

    export const ViewerRunsComponent = (props: ViewerRunsComponentProps) => (
      <ApolloReactComponents.Query<ViewerRunsQuery, ViewerRunsQueryVariables> query={ViewerRunsDocument} {...props} />
    );
    
export type ViewerRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerRunsQuery, ViewerRunsQueryVariables>
    } & TChildProps;
export function withViewerRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerRunsQuery,
  ViewerRunsQueryVariables,
  ViewerRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerRunsQuery, ViewerRunsQueryVariables, ViewerRunsProps<TChildProps, TDataName>>(ViewerRunsDocument, {
      alias: 'viewerRuns',
      ...operationOptions
    });
};

/**
 * __useViewerRunsQuery__
 *
 * To run a query within a React component, call `useViewerRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useViewerRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerRunsQuery, ViewerRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerRunsQuery, ViewerRunsQueryVariables>(ViewerRunsDocument, baseOptions);
      }
export function useViewerRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerRunsQuery, ViewerRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerRunsQuery, ViewerRunsQueryVariables>(ViewerRunsDocument, baseOptions);
        }
export type ViewerRunsQueryHookResult = ReturnType<typeof useViewerRunsQuery>;
export type ViewerRunsLazyQueryHookResult = ReturnType<typeof useViewerRunsLazyQuery>;
export type ViewerRunsQueryResult = ApolloReactCommon.QueryResult<ViewerRunsQuery, ViewerRunsQueryVariables>;
export const AllProjectsQueryDocument = gql`
    query AllProjectsQuery($cursor: String, $entityName: String, $first: Int = 500) {
  viewer(entityName: $entityName) {
    id
    projects(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          totalUsers
          lastActive
          totalRunTime
          computeHours
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    `;
export type AllProjectsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>, 'query'>;

    export const AllProjectsQueryComponent = (props: AllProjectsQueryComponentProps) => (
      <ApolloReactComponents.Query<AllProjectsQueryQuery, AllProjectsQueryQueryVariables> query={AllProjectsQueryDocument} {...props} />
    );
    
export type AllProjectsQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>
    } & TChildProps;
export function withAllProjectsQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllProjectsQueryQuery,
  AllProjectsQueryQueryVariables,
  AllProjectsQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllProjectsQueryQuery, AllProjectsQueryQueryVariables, AllProjectsQueryProps<TChildProps, TDataName>>(AllProjectsQueryDocument, {
      alias: 'allProjectsQuery',
      ...operationOptions
    });
};

/**
 * __useAllProjectsQueryQuery__
 *
 * To run a query within a React component, call `useAllProjectsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQueryQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllProjectsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>(AllProjectsQueryDocument, baseOptions);
      }
export function useAllProjectsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>(AllProjectsQueryDocument, baseOptions);
        }
export type AllProjectsQueryQueryHookResult = ReturnType<typeof useAllProjectsQueryQuery>;
export type AllProjectsQueryLazyQueryHookResult = ReturnType<typeof useAllProjectsQueryLazyQuery>;
export type AllProjectsQueryQueryResult = ApolloReactCommon.QueryResult<AllProjectsQueryQuery, AllProjectsQueryQueryVariables>;
export const UserRunsDocument = gql`
    query UserRuns($entityName: String!, $dailyRunCountLimit: Int!) {
  user(userName: $entityName) {
    id
    dailyRunCount(limit: $dailyRunCountLimit)
    runs(first: 100) {
      edges {
        node {
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type UserRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserRunsQuery, UserRunsQueryVariables>, 'query'> & ({ variables: UserRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserRunsComponent = (props: UserRunsComponentProps) => (
      <ApolloReactComponents.Query<UserRunsQuery, UserRunsQueryVariables> query={UserRunsDocument} {...props} />
    );
    
export type UserRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserRunsQuery, UserRunsQueryVariables>
    } & TChildProps;
export function withUserRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserRunsQuery,
  UserRunsQueryVariables,
  UserRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserRunsQuery, UserRunsQueryVariables, UserRunsProps<TChildProps, TDataName>>(UserRunsDocument, {
      alias: 'userRuns',
      ...operationOptions
    });
};

/**
 * __useUserRunsQuery__
 *
 * To run a query within a React component, call `useUserRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      dailyRunCountLimit: // value for 'dailyRunCountLimit'
 *   },
 * });
 */
export function useUserRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRunsQuery, UserRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRunsQuery, UserRunsQueryVariables>(UserRunsDocument, baseOptions);
      }
export function useUserRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRunsQuery, UserRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRunsQuery, UserRunsQueryVariables>(UserRunsDocument, baseOptions);
        }
export type UserRunsQueryHookResult = ReturnType<typeof useUserRunsQuery>;
export type UserRunsLazyQueryHookResult = ReturnType<typeof useUserRunsLazyQuery>;
export type UserRunsQueryResult = ApolloReactCommon.QueryResult<UserRunsQuery, UserRunsQueryVariables>;
export const SubmitRunByIdQueryDocument = gql`
    query SubmitRunByIdQuery($entityName: String!, $projectName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
    run(name: $runName) {
      id
      name
      displayName
      summaryMetrics
      benchmarkRun {
        id
        results
      }
    }
  }
}
    `;
export type SubmitRunByIdQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>, 'query'> & ({ variables: SubmitRunByIdQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubmitRunByIdQueryComponent = (props: SubmitRunByIdQueryComponentProps) => (
      <ApolloReactComponents.Query<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables> query={SubmitRunByIdQueryDocument} {...props} />
    );
    
export type SubmitRunByIdQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>
    } & TChildProps;
export function withSubmitRunByIdQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitRunByIdQueryQuery,
  SubmitRunByIdQueryQueryVariables,
  SubmitRunByIdQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables, SubmitRunByIdQueryProps<TChildProps, TDataName>>(SubmitRunByIdQueryDocument, {
      alias: 'submitRunByIdQuery',
      ...operationOptions
    });
};

/**
 * __useSubmitRunByIdQueryQuery__
 *
 * To run a query within a React component, call `useSubmitRunByIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitRunByIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitRunByIdQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useSubmitRunByIdQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>(SubmitRunByIdQueryDocument, baseOptions);
      }
export function useSubmitRunByIdQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>(SubmitRunByIdQueryDocument, baseOptions);
        }
export type SubmitRunByIdQueryQueryHookResult = ReturnType<typeof useSubmitRunByIdQueryQuery>;
export type SubmitRunByIdQueryLazyQueryHookResult = ReturnType<typeof useSubmitRunByIdQueryLazyQuery>;
export type SubmitRunByIdQueryQueryResult = ApolloReactCommon.QueryResult<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>;
export const RunCsvDocument = gql`
    query RunCSV($projectName: String!, $entityName: String, $runName: String!, $fileName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      benchmarkRun {
        id
        results
      }
      submissionFiles: files(first: 1, names: [$fileName]) {
        edges {
          node {
            id
            name
            md5
          }
        }
      }
    }
  }
}
    `;
export type RunCsvComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunCsvQuery, RunCsvQueryVariables>, 'query'> & ({ variables: RunCsvQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunCsvComponent = (props: RunCsvComponentProps) => (
      <ApolloReactComponents.Query<RunCsvQuery, RunCsvQueryVariables> query={RunCsvDocument} {...props} />
    );
    
export type RunCsvProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunCsvQuery, RunCsvQueryVariables>
    } & TChildProps;
export function withRunCsv<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunCsvQuery,
  RunCsvQueryVariables,
  RunCsvProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunCsvQuery, RunCsvQueryVariables, RunCsvProps<TChildProps, TDataName>>(RunCsvDocument, {
      alias: 'runCsv',
      ...operationOptions
    });
};

/**
 * __useRunCsvQuery__
 *
 * To run a query within a React component, call `useRunCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunCsvQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useRunCsvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunCsvQuery, RunCsvQueryVariables>) {
        return ApolloReactHooks.useQuery<RunCsvQuery, RunCsvQueryVariables>(RunCsvDocument, baseOptions);
      }
export function useRunCsvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunCsvQuery, RunCsvQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunCsvQuery, RunCsvQueryVariables>(RunCsvDocument, baseOptions);
        }
export type RunCsvQueryHookResult = ReturnType<typeof useRunCsvQuery>;
export type RunCsvLazyQueryHookResult = ReturnType<typeof useRunCsvLazyQuery>;
export type RunCsvQueryResult = ApolloReactCommon.QueryResult<RunCsvQuery, RunCsvQueryVariables>;
export const UpsertLinkedBenchmarkDocument = gql`
    mutation upsertLinkedBenchmark($description: String, $entityName: String, $id: String, $name: String, $framework: String, $access: String, $views: JSONString, $linkedBenchmark: ID) {
  upsertModel(input: {description: $description, entityName: $entityName, id: $id, name: $name, framework: $framework, access: $access, views: $views, linkedBenchmark: $linkedBenchmark}) {
    project {
      id
      name
      entityName
      description
      access
      views
      isBenchmark
      linkedBenchmark {
        id
        name
      }
    }
    inserted
  }
}
    `;
export type UpsertLinkedBenchmarkMutationFn = ApolloReactCommon.MutationFunction<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>;
export type UpsertLinkedBenchmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>, 'mutation'>;

    export const UpsertLinkedBenchmarkComponent = (props: UpsertLinkedBenchmarkComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables> mutation={UpsertLinkedBenchmarkDocument} {...props} />
    );
    
export type UpsertLinkedBenchmarkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>
    } & TChildProps;
export function withUpsertLinkedBenchmark<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertLinkedBenchmarkMutation,
  UpsertLinkedBenchmarkMutationVariables,
  UpsertLinkedBenchmarkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables, UpsertLinkedBenchmarkProps<TChildProps, TDataName>>(UpsertLinkedBenchmarkDocument, {
      alias: 'upsertLinkedBenchmark',
      ...operationOptions
    });
};

/**
 * __useUpsertLinkedBenchmarkMutation__
 *
 * To run a mutation, you first call `useUpsertLinkedBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLinkedBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLinkedBenchmarkMutation, { data, loading, error }] = useUpsertLinkedBenchmarkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      framework: // value for 'framework'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *      linkedBenchmark: // value for 'linkedBenchmark'
 *   },
 * });
 */
export function useUpsertLinkedBenchmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>(UpsertLinkedBenchmarkDocument, baseOptions);
      }
export type UpsertLinkedBenchmarkMutationHookResult = ReturnType<typeof useUpsertLinkedBenchmarkMutation>;
export type UpsertLinkedBenchmarkMutationResult = ApolloReactCommon.MutationResult<UpsertLinkedBenchmarkMutation>;
export type UpsertLinkedBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>;
export const SimpleTestQueryDocument = gql`
    query SimpleTestQuery {
  serverInfo {
    pollingOK
  }
}
    `;
export type SimpleTestQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>, 'query'>;

    export const SimpleTestQueryComponent = (props: SimpleTestQueryComponentProps) => (
      <ApolloReactComponents.Query<SimpleTestQueryQuery, SimpleTestQueryQueryVariables> query={SimpleTestQueryDocument} {...props} />
    );
    
export type SimpleTestQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>
    } & TChildProps;
export function withSimpleTestQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SimpleTestQueryQuery,
  SimpleTestQueryQueryVariables,
  SimpleTestQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SimpleTestQueryQuery, SimpleTestQueryQueryVariables, SimpleTestQueryProps<TChildProps, TDataName>>(SimpleTestQueryDocument, {
      alias: 'simpleTestQuery',
      ...operationOptions
    });
};

/**
 * __useSimpleTestQueryQuery__
 *
 * To run a query within a React component, call `useSimpleTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimpleTestQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>(SimpleTestQueryDocument, baseOptions);
      }
export function useSimpleTestQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>(SimpleTestQueryDocument, baseOptions);
        }
export type SimpleTestQueryQueryHookResult = ReturnType<typeof useSimpleTestQueryQuery>;
export type SimpleTestQueryLazyQueryHookResult = ReturnType<typeof useSimpleTestQueryLazyQuery>;
export type SimpleTestQueryQueryResult = ApolloReactCommon.QueryResult<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>;
export const UpsertBenchmarkDocument = gql`
    mutation upsertBenchmark($description: String, $entityName: String!, $id: String, $name: String!, $access: String, $views: JSONString) {
  upsertModel(input: {description: $description, entityName: $entityName, id: $id, name: $name, access: $access, views: $views, isBenchmark: true}) {
    project {
      id
      name
      entityName
      description
      access
      views
      isBenchmark
    }
    inserted
  }
}
    `;
export type UpsertBenchmarkMutationFn = ApolloReactCommon.MutationFunction<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>;
export type UpsertBenchmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>, 'mutation'>;

    export const UpsertBenchmarkComponent = (props: UpsertBenchmarkComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables> mutation={UpsertBenchmarkDocument} {...props} />
    );
    
export type UpsertBenchmarkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>
    } & TChildProps;
export function withUpsertBenchmark<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertBenchmarkMutation,
  UpsertBenchmarkMutationVariables,
  UpsertBenchmarkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables, UpsertBenchmarkProps<TChildProps, TDataName>>(UpsertBenchmarkDocument, {
      alias: 'upsertBenchmark',
      ...operationOptions
    });
};

/**
 * __useUpsertBenchmarkMutation__
 *
 * To run a mutation, you first call `useUpsertBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBenchmarkMutation, { data, loading, error }] = useUpsertBenchmarkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *   },
 * });
 */
export function useUpsertBenchmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>(UpsertBenchmarkDocument, baseOptions);
      }
export type UpsertBenchmarkMutationHookResult = ReturnType<typeof useUpsertBenchmarkMutation>;
export type UpsertBenchmarkMutationResult = ApolloReactCommon.MutationResult<UpsertBenchmarkMutation>;
export type UpsertBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>;
export const CheckAvailabilityDocument = gql`
    query CheckAvailability($name: String) {
  entity(name: $name) {
    id
    available
  }
}
    `;
export type CheckAvailabilityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>, 'query'>;

    export const CheckAvailabilityComponent = (props: CheckAvailabilityComponentProps) => (
      <ApolloReactComponents.Query<CheckAvailabilityQuery, CheckAvailabilityQueryVariables> query={CheckAvailabilityDocument} {...props} />
    );
    
export type CheckAvailabilityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>
    } & TChildProps;
export function withCheckAvailability<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckAvailabilityQuery,
  CheckAvailabilityQueryVariables,
  CheckAvailabilityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CheckAvailabilityQuery, CheckAvailabilityQueryVariables, CheckAvailabilityProps<TChildProps, TDataName>>(CheckAvailabilityDocument, {
      alias: 'checkAvailability',
      ...operationOptions
    });
};

/**
 * __useCheckAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAvailabilityQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>(CheckAvailabilityDocument, baseOptions);
      }
export function useCheckAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>(CheckAvailabilityDocument, baseOptions);
        }
export type CheckAvailabilityQueryHookResult = ReturnType<typeof useCheckAvailabilityQuery>;
export type CheckAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckAvailabilityLazyQuery>;
export type CheckAvailabilityQueryResult = ApolloReactCommon.QueryResult<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>;
export const CreateEntityOnboardingFlowDocument = gql`
    mutation CreateEntityOnboardingFlow($name: String!) {
  createEntity(input: {name: $name}) {
    entity {
      id
    }
  }
}
    `;
export type CreateEntityOnboardingFlowMutationFn = ApolloReactCommon.MutationFunction<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>;
export type CreateEntityOnboardingFlowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>, 'mutation'>;

    export const CreateEntityOnboardingFlowComponent = (props: CreateEntityOnboardingFlowComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables> mutation={CreateEntityOnboardingFlowDocument} {...props} />
    );
    
export type CreateEntityOnboardingFlowProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>
    } & TChildProps;
export function withCreateEntityOnboardingFlow<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEntityOnboardingFlowMutation,
  CreateEntityOnboardingFlowMutationVariables,
  CreateEntityOnboardingFlowProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables, CreateEntityOnboardingFlowProps<TChildProps, TDataName>>(CreateEntityOnboardingFlowDocument, {
      alias: 'createEntityOnboardingFlow',
      ...operationOptions
    });
};

/**
 * __useCreateEntityOnboardingFlowMutation__
 *
 * To run a mutation, you first call `useCreateEntityOnboardingFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityOnboardingFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityOnboardingFlowMutation, { data, loading, error }] = useCreateEntityOnboardingFlowMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEntityOnboardingFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>(CreateEntityOnboardingFlowDocument, baseOptions);
      }
export type CreateEntityOnboardingFlowMutationHookResult = ReturnType<typeof useCreateEntityOnboardingFlowMutation>;
export type CreateEntityOnboardingFlowMutationResult = ApolloReactCommon.MutationResult<CreateEntityOnboardingFlowMutation>;
export type CreateEntityOnboardingFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>;
export const ViewerInfoDocument = gql`
    query ViewerInfo {
  viewer {
    id
    name
    userInfo
    entity
    username
    signupRequired
  }
}
    `;
export type ViewerInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerInfoQuery, ViewerInfoQueryVariables>, 'query'>;

    export const ViewerInfoComponent = (props: ViewerInfoComponentProps) => (
      <ApolloReactComponents.Query<ViewerInfoQuery, ViewerInfoQueryVariables> query={ViewerInfoDocument} {...props} />
    );
    
export type ViewerInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerInfoQuery, ViewerInfoQueryVariables>
    } & TChildProps;
export function withViewerInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerInfoQuery,
  ViewerInfoQueryVariables,
  ViewerInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerInfoQuery, ViewerInfoQueryVariables, ViewerInfoProps<TChildProps, TDataName>>(ViewerInfoDocument, {
      alias: 'viewerInfo',
      ...operationOptions
    });
};

/**
 * __useViewerInfoQuery__
 *
 * To run a query within a React component, call `useViewerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerInfoQuery, ViewerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerInfoQuery, ViewerInfoQueryVariables>(ViewerInfoDocument, baseOptions);
      }
export function useViewerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerInfoQuery, ViewerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerInfoQuery, ViewerInfoQueryVariables>(ViewerInfoDocument, baseOptions);
        }
export type ViewerInfoQueryHookResult = ReturnType<typeof useViewerInfoQuery>;
export type ViewerInfoLazyQueryHookResult = ReturnType<typeof useViewerInfoLazyQuery>;
export type ViewerInfoQueryResult = ApolloReactCommon.QueryResult<ViewerInfoQuery, ViewerInfoQueryVariables>;
export const EntityRunsDocument = gql`
    query EntityRuns($entityName: String!) {
  entity(name: $entityName) {
    id
    runs(first: 100) {
      edges {
        node {
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type EntityRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityRunsQuery, EntityRunsQueryVariables>, 'query'> & ({ variables: EntityRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityRunsComponent = (props: EntityRunsComponentProps) => (
      <ApolloReactComponents.Query<EntityRunsQuery, EntityRunsQueryVariables> query={EntityRunsDocument} {...props} />
    );
    
export type EntityRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityRunsQuery, EntityRunsQueryVariables>
    } & TChildProps;
export function withEntityRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityRunsQuery,
  EntityRunsQueryVariables,
  EntityRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityRunsQuery, EntityRunsQueryVariables, EntityRunsProps<TChildProps, TDataName>>(EntityRunsDocument, {
      alias: 'entityRuns',
      ...operationOptions
    });
};

/**
 * __useEntityRunsQuery__
 *
 * To run a query within a React component, call `useEntityRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityRunsQuery, EntityRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityRunsQuery, EntityRunsQueryVariables>(EntityRunsDocument, baseOptions);
      }
export function useEntityRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityRunsQuery, EntityRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityRunsQuery, EntityRunsQueryVariables>(EntityRunsDocument, baseOptions);
        }
export type EntityRunsQueryHookResult = ReturnType<typeof useEntityRunsQuery>;
export type EntityRunsLazyQueryHookResult = ReturnType<typeof useEntityRunsLazyQuery>;
export type EntityRunsQueryResult = ApolloReactCommon.QueryResult<EntityRunsQuery, EntityRunsQueryVariables>;
export const TrendingReposDocument = gql`
    query TrendingRepos($from: DateTime!) {
  trendingRepos(from: $from) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    schema
    edges {
      node {
        row
      }
    }
  }
}
    `;
export type TrendingReposComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TrendingReposQuery, TrendingReposQueryVariables>, 'query'> & ({ variables: TrendingReposQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TrendingReposComponent = (props: TrendingReposComponentProps) => (
      <ApolloReactComponents.Query<TrendingReposQuery, TrendingReposQueryVariables> query={TrendingReposDocument} {...props} />
    );
    
export type TrendingReposProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrendingReposQuery, TrendingReposQueryVariables>
    } & TChildProps;
export function withTrendingRepos<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrendingReposQuery,
  TrendingReposQueryVariables,
  TrendingReposProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrendingReposQuery, TrendingReposQueryVariables, TrendingReposProps<TChildProps, TDataName>>(TrendingReposDocument, {
      alias: 'trendingRepos',
      ...operationOptions
    });
};

/**
 * __useTrendingReposQuery__
 *
 * To run a query within a React component, call `useTrendingReposQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingReposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingReposQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useTrendingReposQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrendingReposQuery, TrendingReposQueryVariables>) {
        return ApolloReactHooks.useQuery<TrendingReposQuery, TrendingReposQueryVariables>(TrendingReposDocument, baseOptions);
      }
export function useTrendingReposLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrendingReposQuery, TrendingReposQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrendingReposQuery, TrendingReposQueryVariables>(TrendingReposDocument, baseOptions);
        }
export type TrendingReposQueryHookResult = ReturnType<typeof useTrendingReposQuery>;
export type TrendingReposLazyQueryHookResult = ReturnType<typeof useTrendingReposLazyQuery>;
export type TrendingReposQueryResult = ApolloReactCommon.QueryResult<TrendingReposQuery, TrendingReposQueryVariables>;
export const RunsStateQueryDocument = gql`
    query RunsStateQuery($entityName: String!, $projectName: String!, $order: String!, $groupKeys: [String!]!, $groupLevel: Int!, $filters: JSONString!, $limit: Int!, $sampledHistorySpecs: [JSONString!]!, $enableBasic: Boolean = true, $enableSystemMetrics: Boolean = true, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableConfig: Boolean = false, $configKeys: [String!], $enableSummary: Boolean = false, $summaryKeys: [String!], $enableWandb: Boolean = false, $wandbKeys: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(first: $limit, order: $order, filters: $filters, groupKeys: $groupKeys, groupLevel: $groupLevel) {
      totalCount
      edges {
        node {
          id
          name
          displayName
          updatedAt
          ...RunStateBasicFragment @include(if: $enableBasic)
          config(keys: $configKeys) @include(if: $enableConfig)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
          systemMetrics @include(if: $enableSystemMetrics)
          historyKeys @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
        }
      }
    }
  }
}
    ${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>, 'query'> & ({ variables: RunsStateQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateQueryComponent = (props: RunsStateQueryComponentProps) => (
      <ApolloReactComponents.Query<RunsStateQueryQuery, RunsStateQueryQueryVariables> query={RunsStateQueryDocument} {...props} />
    );
    
export type RunsStateQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateQueryQuery, RunsStateQueryQueryVariables>
    } & TChildProps;
export function withRunsStateQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateQueryQuery,
  RunsStateQueryQueryVariables,
  RunsStateQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateQueryQuery, RunsStateQueryQueryVariables, RunsStateQueryProps<TChildProps, TDataName>>(RunsStateQueryDocument, {
      alias: 'runsStateQuery',
      ...operationOptions
    });
};

/**
 * __useRunsStateQueryQuery__
 *
 * To run a query within a React component, call `useRunsStateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      order: // value for 'order'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      enableBasic: // value for 'enableBasic'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableConfig: // value for 'enableConfig'
 *      configKeys: // value for 'configKeys'
 *      enableSummary: // value for 'enableSummary'
 *      summaryKeys: // value for 'summaryKeys'
 *      enableWandb: // value for 'enableWandb'
 *      wandbKeys: // value for 'wandbKeys'
 *   },
 * });
 */
export function useRunsStateQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateQueryQuery, RunsStateQueryQueryVariables>(RunsStateQueryDocument, baseOptions);
      }
export function useRunsStateQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateQueryQuery, RunsStateQueryQueryVariables>(RunsStateQueryDocument, baseOptions);
        }
export type RunsStateQueryQueryHookResult = ReturnType<typeof useRunsStateQueryQuery>;
export type RunsStateQueryLazyQueryHookResult = ReturnType<typeof useRunsStateQueryLazyQuery>;
export type RunsStateQueryQueryResult = ApolloReactCommon.QueryResult<RunsStateQueryQuery, RunsStateQueryQueryVariables>;
export const RunsStateDeltaQueryDocument = gql`
    query RunsStateDeltaQuery($entityName: String!, $projectName: String!, $order: String!, $groupKeys: [String!]!, $groupLevel: Int!, $filters: JSONString!, $limit: Int!, $sampledHistorySpecs: [JSONString!]!, $enableBasic: Boolean = true, $enableSystemMetrics: Boolean = true, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableConfig: Boolean = false, $configKeys: [String!], $enableSummary: Boolean = false, $summaryKeys: [String!], $enableWandb: Boolean = false, $wandbKeys: [String!], $currentRuns: [String!]!, $lastUpdated: DateTime!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(first: $limit, order: $order, filters: $filters, groupKeys: $groupKeys, groupLevel: $groupLevel) {
      totalCount
      delta(currentRuns: $currentRuns, lastUpdated: $lastUpdated) {
        index
        op
        run {
          id
          name
          projectId
          displayName
          updatedAt
          ...RunStateBasicFragment @include(if: $enableBasic)
          config(keys: $configKeys) @include(if: $enableConfig)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
          systemMetrics @include(if: $enableSystemMetrics)
          historyKeys @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
        }
      }
    }
  }
}
    ${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateDeltaQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>, 'query'> & ({ variables: RunsStateDeltaQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateDeltaQueryComponent = (props: RunsStateDeltaQueryComponentProps) => (
      <ApolloReactComponents.Query<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables> query={RunsStateDeltaQueryDocument} {...props} />
    );
    
export type RunsStateDeltaQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>
    } & TChildProps;
export function withRunsStateDeltaQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateDeltaQueryQuery,
  RunsStateDeltaQueryQueryVariables,
  RunsStateDeltaQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables, RunsStateDeltaQueryProps<TChildProps, TDataName>>(RunsStateDeltaQueryDocument, {
      alias: 'runsStateDeltaQuery',
      ...operationOptions
    });
};

/**
 * __useRunsStateDeltaQueryQuery__
 *
 * To run a query within a React component, call `useRunsStateDeltaQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateDeltaQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateDeltaQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      order: // value for 'order'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      enableBasic: // value for 'enableBasic'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableConfig: // value for 'enableConfig'
 *      configKeys: // value for 'configKeys'
 *      enableSummary: // value for 'enableSummary'
 *      summaryKeys: // value for 'summaryKeys'
 *      enableWandb: // value for 'enableWandb'
 *      wandbKeys: // value for 'wandbKeys'
 *      currentRuns: // value for 'currentRuns'
 *      lastUpdated: // value for 'lastUpdated'
 *   },
 * });
 */
export function useRunsStateDeltaQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>(RunsStateDeltaQueryDocument, baseOptions);
      }
export function useRunsStateDeltaQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>(RunsStateDeltaQueryDocument, baseOptions);
        }
export type RunsStateDeltaQueryQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryQuery>;
export type RunsStateDeltaQueryLazyQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryLazyQuery>;
export type RunsStateDeltaQueryQueryResult = ApolloReactCommon.QueryResult<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>;
export const ServerInfoQueryDocument = gql`
    query ServerInfoQuery {
  serverInfo {
    pollingOK
  }
}
    `;
export type ServerInfoQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>, 'query'>;

    export const ServerInfoQueryComponent = (props: ServerInfoQueryComponentProps) => (
      <ApolloReactComponents.Query<ServerInfoQueryQuery, ServerInfoQueryQueryVariables> query={ServerInfoQueryDocument} {...props} />
    );
    
export type ServerInfoQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>
    } & TChildProps;
export function withServerInfoQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServerInfoQueryQuery,
  ServerInfoQueryQueryVariables,
  ServerInfoQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServerInfoQueryQuery, ServerInfoQueryQueryVariables, ServerInfoQueryProps<TChildProps, TDataName>>(ServerInfoQueryDocument, {
      alias: 'serverInfoQuery',
      ...operationOptions
    });
};

/**
 * __useServerInfoQueryQuery__
 *
 * To run a query within a React component, call `useServerInfoQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerInfoQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerInfoQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerInfoQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>(ServerInfoQueryDocument, baseOptions);
      }
export function useServerInfoQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>(ServerInfoQueryDocument, baseOptions);
        }
export type ServerInfoQueryQueryHookResult = ReturnType<typeof useServerInfoQueryQuery>;
export type ServerInfoQueryLazyQueryHookResult = ReturnType<typeof useServerInfoQueryLazyQuery>;
export type ServerInfoQueryQueryResult = ApolloReactCommon.QueryResult<ServerInfoQueryQuery, ServerInfoQueryQueryVariables>;
export const Views2MetadataDocument = gql`
    query Views2Metadata($entityName: String, $name: String, $viewType: String = "runs", $userName: String, $viewName: String) {
  project(name: $name, entityName: $entityName) {
    id
    allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
      edges {
        node {
          ...ViewFragmentMetadata2
        }
      }
    }
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2MetadataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2MetadataQuery, Views2MetadataQueryVariables>, 'query'>;

    export const Views2MetadataComponent = (props: Views2MetadataComponentProps) => (
      <ApolloReactComponents.Query<Views2MetadataQuery, Views2MetadataQueryVariables> query={Views2MetadataDocument} {...props} />
    );
    
export type Views2MetadataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2MetadataQuery, Views2MetadataQueryVariables>
    } & TChildProps;
export function withViews2Metadata<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2MetadataQuery,
  Views2MetadataQueryVariables,
  Views2MetadataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2MetadataQuery, Views2MetadataQueryVariables, Views2MetadataProps<TChildProps, TDataName>>(Views2MetadataDocument, {
      alias: 'views2Metadata',
      ...operationOptions
    });
};

/**
 * __useViews2MetadataQuery__
 *
 * To run a query within a React component, call `useViews2MetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2MetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2MetadataQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      viewType: // value for 'viewType'
 *      userName: // value for 'userName'
 *      viewName: // value for 'viewName'
 *   },
 * });
 */
export function useViews2MetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2MetadataQuery, Views2MetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2MetadataQuery, Views2MetadataQueryVariables>(Views2MetadataDocument, baseOptions);
      }
export function useViews2MetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2MetadataQuery, Views2MetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2MetadataQuery, Views2MetadataQueryVariables>(Views2MetadataDocument, baseOptions);
        }
export type Views2MetadataQueryHookResult = ReturnType<typeof useViews2MetadataQuery>;
export type Views2MetadataLazyQueryHookResult = ReturnType<typeof useViews2MetadataLazyQuery>;
export type Views2MetadataQueryResult = ApolloReactCommon.QueryResult<Views2MetadataQuery, Views2MetadataQueryVariables>;
export const Views2MetadataSpecDocument = gql`
    query Views2MetadataSpec($entityName: String, $name: String, $viewType: String = "runs", $userName: String, $viewName: String) {
  project(name: $name, entityName: $entityName) {
    id
    allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
      edges {
        node {
          ...ViewFragmentMetadata2
          spec
        }
      }
    }
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2MetadataSpecComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>, 'query'>;

    export const Views2MetadataSpecComponent = (props: Views2MetadataSpecComponentProps) => (
      <ApolloReactComponents.Query<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables> query={Views2MetadataSpecDocument} {...props} />
    );
    
export type Views2MetadataSpecProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>
    } & TChildProps;
export function withViews2MetadataSpec<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2MetadataSpecQuery,
  Views2MetadataSpecQueryVariables,
  Views2MetadataSpecProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables, Views2MetadataSpecProps<TChildProps, TDataName>>(Views2MetadataSpecDocument, {
      alias: 'views2MetadataSpec',
      ...operationOptions
    });
};

/**
 * __useViews2MetadataSpecQuery__
 *
 * To run a query within a React component, call `useViews2MetadataSpecQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2MetadataSpecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2MetadataSpecQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      viewType: // value for 'viewType'
 *      userName: // value for 'userName'
 *      viewName: // value for 'viewName'
 *   },
 * });
 */
export function useViews2MetadataSpecQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>(Views2MetadataSpecDocument, baseOptions);
      }
export function useViews2MetadataSpecLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>(Views2MetadataSpecDocument, baseOptions);
        }
export type Views2MetadataSpecQueryHookResult = ReturnType<typeof useViews2MetadataSpecQuery>;
export type Views2MetadataSpecLazyQueryHookResult = ReturnType<typeof useViews2MetadataSpecLazyQuery>;
export type Views2MetadataSpecQueryResult = ApolloReactCommon.QueryResult<Views2MetadataSpecQuery, Views2MetadataSpecQueryVariables>;
export const Views2ViewDocument = gql`
    query Views2View($id: ID!) {
  view(id: $id) {
    ...ViewFragmentMetadata2
    spec
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2ViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2ViewQuery, Views2ViewQueryVariables>, 'query'> & ({ variables: Views2ViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const Views2ViewComponent = (props: Views2ViewComponentProps) => (
      <ApolloReactComponents.Query<Views2ViewQuery, Views2ViewQueryVariables> query={Views2ViewDocument} {...props} />
    );
    
export type Views2ViewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2ViewQuery, Views2ViewQueryVariables>
    } & TChildProps;
export function withViews2View<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2ViewQuery,
  Views2ViewQueryVariables,
  Views2ViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2ViewQuery, Views2ViewQueryVariables, Views2ViewProps<TChildProps, TDataName>>(Views2ViewDocument, {
      alias: 'views2View',
      ...operationOptions
    });
};

/**
 * __useViews2ViewQuery__
 *
 * To run a query within a React component, call `useViews2ViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2ViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2ViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViews2ViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2ViewQuery, Views2ViewQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2ViewQuery, Views2ViewQueryVariables>(Views2ViewDocument, baseOptions);
      }
export function useViews2ViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2ViewQuery, Views2ViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2ViewQuery, Views2ViewQueryVariables>(Views2ViewDocument, baseOptions);
        }
export type Views2ViewQueryHookResult = ReturnType<typeof useViews2ViewQuery>;
export type Views2ViewLazyQueryHookResult = ReturnType<typeof useViews2ViewLazyQuery>;
export type Views2ViewQueryResult = ApolloReactCommon.QueryResult<Views2ViewQuery, Views2ViewQueryVariables>;
export const UpsertView2Document = gql`
    mutation UpsertView2($id: ID, $entityName: String, $projectName: String, $type: String, $name: String, $displayName: String, $description: String, $spec: String, $parentId: ID, $locked: Boolean, $previewUrl: String, $coverUrl: String, $showcasedAt: DateTime) {
  upsertView(input: {id: $id, entityName: $entityName, projectName: $projectName, name: $name, displayName: $displayName, description: $description, type: $type, spec: $spec, parentId: $parentId, locked: $locked, previewUrl: $previewUrl, coverUrl: $coverUrl, showcasedAt: $showcasedAt}) {
    view {
      ...ViewFragmentMetadata2
    }
    inserted
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type UpsertView2MutationFn = ApolloReactCommon.MutationFunction<UpsertView2Mutation, UpsertView2MutationVariables>;
export type UpsertView2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertView2Mutation, UpsertView2MutationVariables>, 'mutation'>;

    export const UpsertView2Component = (props: UpsertView2ComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertView2Mutation, UpsertView2MutationVariables> mutation={UpsertView2Document} {...props} />
    );
    
export type UpsertView2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertView2Mutation, UpsertView2MutationVariables>
    } & TChildProps;
export function withUpsertView2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertView2Mutation,
  UpsertView2MutationVariables,
  UpsertView2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertView2Mutation, UpsertView2MutationVariables, UpsertView2Props<TChildProps, TDataName>>(UpsertView2Document, {
      alias: 'upsertView2',
      ...operationOptions
    });
};

/**
 * __useUpsertView2Mutation__
 *
 * To run a mutation, you first call `useUpsertView2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertView2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertView2Mutation, { data, loading, error }] = useUpsertView2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *      parentId: // value for 'parentId'
 *      locked: // value for 'locked'
 *      previewUrl: // value for 'previewUrl'
 *      coverUrl: // value for 'coverUrl'
 *      showcasedAt: // value for 'showcasedAt'
 *   },
 * });
 */
export function useUpsertView2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertView2Mutation, UpsertView2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertView2Mutation, UpsertView2MutationVariables>(UpsertView2Document, baseOptions);
      }
export type UpsertView2MutationHookResult = ReturnType<typeof useUpsertView2Mutation>;
export type UpsertView2MutationResult = ApolloReactCommon.MutationResult<UpsertView2Mutation>;
export type UpsertView2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertView2Mutation, UpsertView2MutationVariables>;
export const DeleteView2Document = gql`
    mutation deleteView2($id: ID, $deleteDrafts: Boolean) {
  deleteView(input: {id: $id, deleteDrafts: $deleteDrafts}) {
    success
    pendingDrafts
  }
}
    `;
export type DeleteView2MutationFn = ApolloReactCommon.MutationFunction<DeleteView2Mutation, DeleteView2MutationVariables>;
export type DeleteView2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteView2Mutation, DeleteView2MutationVariables>, 'mutation'>;

    export const DeleteView2Component = (props: DeleteView2ComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteView2Mutation, DeleteView2MutationVariables> mutation={DeleteView2Document} {...props} />
    );
    
export type DeleteView2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteView2Mutation, DeleteView2MutationVariables>
    } & TChildProps;
export function withDeleteView2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteView2Mutation,
  DeleteView2MutationVariables,
  DeleteView2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteView2Mutation, DeleteView2MutationVariables, DeleteView2Props<TChildProps, TDataName>>(DeleteView2Document, {
      alias: 'deleteView2',
      ...operationOptions
    });
};

/**
 * __useDeleteView2Mutation__
 *
 * To run a mutation, you first call `useDeleteView2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteView2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteView2Mutation, { data, loading, error }] = useDeleteView2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteDrafts: // value for 'deleteDrafts'
 *   },
 * });
 */
export function useDeleteView2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteView2Mutation, DeleteView2MutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteView2Mutation, DeleteView2MutationVariables>(DeleteView2Document, baseOptions);
      }
export type DeleteView2MutationHookResult = ReturnType<typeof useDeleteView2Mutation>;
export type DeleteView2MutationResult = ApolloReactCommon.MutationResult<DeleteView2Mutation>;
export type DeleteView2MutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteView2Mutation, DeleteView2MutationVariables>;
export const DeleteViewsDocument = gql`
    mutation deleteViews($ids: [ID!]!, $deleteDrafts: Boolean) {
  deleteViews(input: {ids: $ids, deleteDrafts: $deleteDrafts}) {
    success
    pendingDrafts
  }
}
    `;
export type DeleteViewsMutationFn = ApolloReactCommon.MutationFunction<DeleteViewsMutation, DeleteViewsMutationVariables>;
export type DeleteViewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteViewsMutation, DeleteViewsMutationVariables>, 'mutation'>;

    export const DeleteViewsComponent = (props: DeleteViewsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteViewsMutation, DeleteViewsMutationVariables> mutation={DeleteViewsDocument} {...props} />
    );
    
export type DeleteViewsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteViewsMutation, DeleteViewsMutationVariables>
    } & TChildProps;
export function withDeleteViews<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteViewsMutation,
  DeleteViewsMutationVariables,
  DeleteViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteViewsMutation, DeleteViewsMutationVariables, DeleteViewsProps<TChildProps, TDataName>>(DeleteViewsDocument, {
      alias: 'deleteViews',
      ...operationOptions
    });
};

/**
 * __useDeleteViewsMutation__
 *
 * To run a mutation, you first call `useDeleteViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewsMutation, { data, loading, error }] = useDeleteViewsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      deleteDrafts: // value for 'deleteDrafts'
 *   },
 * });
 */
export function useDeleteViewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteViewsMutation, DeleteViewsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteViewsMutation, DeleteViewsMutationVariables>(DeleteViewsDocument, baseOptions);
      }
export type DeleteViewsMutationHookResult = ReturnType<typeof useDeleteViewsMutation>;
export type DeleteViewsMutationResult = ApolloReactCommon.MutationResult<DeleteViewsMutation>;
export type DeleteViewsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteViewsMutation, DeleteViewsMutationVariables>;
export const StarViewDocument = gql`
    mutation starView($id: ID!) {
  starView(input: {id: $id}) {
    view {
      id
      starCount
      starred
    }
  }
}
    `;
export type StarViewMutationFn = ApolloReactCommon.MutationFunction<StarViewMutation, StarViewMutationVariables>;
export type StarViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StarViewMutation, StarViewMutationVariables>, 'mutation'>;

    export const StarViewComponent = (props: StarViewComponentProps) => (
      <ApolloReactComponents.Mutation<StarViewMutation, StarViewMutationVariables> mutation={StarViewDocument} {...props} />
    );
    
export type StarViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StarViewMutation, StarViewMutationVariables>
    } & TChildProps;
export function withStarView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StarViewMutation,
  StarViewMutationVariables,
  StarViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StarViewMutation, StarViewMutationVariables, StarViewProps<TChildProps, TDataName>>(StarViewDocument, {
      alias: 'starView',
      ...operationOptions
    });
};

/**
 * __useStarViewMutation__
 *
 * To run a mutation, you first call `useStarViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starViewMutation, { data, loading, error }] = useStarViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStarViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StarViewMutation, StarViewMutationVariables>) {
        return ApolloReactHooks.useMutation<StarViewMutation, StarViewMutationVariables>(StarViewDocument, baseOptions);
      }
export type StarViewMutationHookResult = ReturnType<typeof useStarViewMutation>;
export type StarViewMutationResult = ApolloReactCommon.MutationResult<StarViewMutation>;
export type StarViewMutationOptions = ApolloReactCommon.BaseMutationOptions<StarViewMutation, StarViewMutationVariables>;
export const UnstarViewDocument = gql`
    mutation unstarView($id: ID!) {
  unstarView(input: {id: $id}) {
    view {
      id
      starCount
      starred
    }
  }
}
    `;
export type UnstarViewMutationFn = ApolloReactCommon.MutationFunction<UnstarViewMutation, UnstarViewMutationVariables>;
export type UnstarViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnstarViewMutation, UnstarViewMutationVariables>, 'mutation'>;

    export const UnstarViewComponent = (props: UnstarViewComponentProps) => (
      <ApolloReactComponents.Mutation<UnstarViewMutation, UnstarViewMutationVariables> mutation={UnstarViewDocument} {...props} />
    );
    
export type UnstarViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnstarViewMutation, UnstarViewMutationVariables>
    } & TChildProps;
export function withUnstarView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnstarViewMutation,
  UnstarViewMutationVariables,
  UnstarViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnstarViewMutation, UnstarViewMutationVariables, UnstarViewProps<TChildProps, TDataName>>(UnstarViewDocument, {
      alias: 'unstarView',
      ...operationOptions
    });
};

/**
 * __useUnstarViewMutation__
 *
 * To run a mutation, you first call `useUnstarViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstarViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstarViewMutation, { data, loading, error }] = useUnstarViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnstarViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnstarViewMutation, UnstarViewMutationVariables>) {
        return ApolloReactHooks.useMutation<UnstarViewMutation, UnstarViewMutationVariables>(UnstarViewDocument, baseOptions);
      }
export type UnstarViewMutationHookResult = ReturnType<typeof useUnstarViewMutation>;
export type UnstarViewMutationResult = ApolloReactCommon.MutationResult<UnstarViewMutation>;
export type UnstarViewMutationOptions = ApolloReactCommon.BaseMutationOptions<UnstarViewMutation, UnstarViewMutationVariables>;
export const StargazersQueryDocument = gql`
    query StargazersQuery($viewID: ID!, $limit: Int, $cursor: String) {
  view(id: $viewID) {
    id
    stargazers(after: $cursor, first: $limit) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          username
          userInfo
          photoUrl
        }
      }
    }
  }
}
    `;
export type StargazersQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StargazersQueryQuery, StargazersQueryQueryVariables>, 'query'> & ({ variables: StargazersQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StargazersQueryComponent = (props: StargazersQueryComponentProps) => (
      <ApolloReactComponents.Query<StargazersQueryQuery, StargazersQueryQueryVariables> query={StargazersQueryDocument} {...props} />
    );
    
export type StargazersQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StargazersQueryQuery, StargazersQueryQueryVariables>
    } & TChildProps;
export function withStargazersQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StargazersQueryQuery,
  StargazersQueryQueryVariables,
  StargazersQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StargazersQueryQuery, StargazersQueryQueryVariables, StargazersQueryProps<TChildProps, TDataName>>(StargazersQueryDocument, {
      alias: 'stargazersQuery',
      ...operationOptions
    });
};

/**
 * __useStargazersQueryQuery__
 *
 * To run a query within a React component, call `useStargazersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStargazersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStargazersQueryQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useStargazersQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StargazersQueryQuery, StargazersQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<StargazersQueryQuery, StargazersQueryQueryVariables>(StargazersQueryDocument, baseOptions);
      }
export function useStargazersQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StargazersQueryQuery, StargazersQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StargazersQueryQuery, StargazersQueryQueryVariables>(StargazersQueryDocument, baseOptions);
        }
export type StargazersQueryQueryHookResult = ReturnType<typeof useStargazersQueryQuery>;
export type StargazersQueryLazyQueryHookResult = ReturnType<typeof useStargazersQueryLazyQuery>;
export type StargazersQueryQueryResult = ApolloReactCommon.QueryResult<StargazersQueryQuery, StargazersQueryQueryVariables>;
export const PublicViewsDocument = gql`
    query PublicViews($limit: Int, $cursor: String) {
  publicViews(type: "runs", after: $cursor, first: $limit) {
    edges {
      node {
        id
        name
        description
        user {
          id
          username
          name
        }
      }
    }
  }
}
    `;
export type PublicViewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PublicViewsQuery, PublicViewsQueryVariables>, 'query'>;

    export const PublicViewsComponent = (props: PublicViewsComponentProps) => (
      <ApolloReactComponents.Query<PublicViewsQuery, PublicViewsQueryVariables> query={PublicViewsDocument} {...props} />
    );
    
export type PublicViewsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PublicViewsQuery, PublicViewsQueryVariables>
    } & TChildProps;
export function withPublicViews<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PublicViewsQuery,
  PublicViewsQueryVariables,
  PublicViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PublicViewsQuery, PublicViewsQueryVariables, PublicViewsProps<TChildProps, TDataName>>(PublicViewsDocument, {
      alias: 'publicViews',
      ...operationOptions
    });
};

/**
 * __usePublicViewsQuery__
 *
 * To run a query within a React component, call `usePublicViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicViewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePublicViewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicViewsQuery, PublicViewsQueryVariables>) {
        return ApolloReactHooks.useQuery<PublicViewsQuery, PublicViewsQueryVariables>(PublicViewsDocument, baseOptions);
      }
export function usePublicViewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicViewsQuery, PublicViewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublicViewsQuery, PublicViewsQueryVariables>(PublicViewsDocument, baseOptions);
        }
export type PublicViewsQueryHookResult = ReturnType<typeof usePublicViewsQuery>;
export type PublicViewsLazyQueryHookResult = ReturnType<typeof usePublicViewsLazyQuery>;
export type PublicViewsQueryResult = ApolloReactCommon.QueryResult<PublicViewsQuery, PublicViewsQueryVariables>;
export const ViewDraftsDocument = gql`
    query viewDrafts($viewID: ID!) {
  view(id: $viewID) {
    id
    children {
      edges {
        node {
          id
          displayName
          user {
            id
          }
          entityName
          project {
            id
            name
            entityName
          }
        }
      }
    }
  }
}
    `;
export type ViewDraftsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewDraftsQuery, ViewDraftsQueryVariables>, 'query'> & ({ variables: ViewDraftsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewDraftsComponent = (props: ViewDraftsComponentProps) => (
      <ApolloReactComponents.Query<ViewDraftsQuery, ViewDraftsQueryVariables> query={ViewDraftsDocument} {...props} />
    );
    
export type ViewDraftsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewDraftsQuery, ViewDraftsQueryVariables>
    } & TChildProps;
export function withViewDrafts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewDraftsQuery,
  ViewDraftsQueryVariables,
  ViewDraftsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewDraftsQuery, ViewDraftsQueryVariables, ViewDraftsProps<TChildProps, TDataName>>(ViewDraftsDocument, {
      alias: 'viewDrafts',
      ...operationOptions
    });
};

/**
 * __useViewDraftsQuery__
 *
 * To run a query within a React component, call `useViewDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDraftsQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useViewDraftsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewDraftsQuery, ViewDraftsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewDraftsQuery, ViewDraftsQueryVariables>(ViewDraftsDocument, baseOptions);
      }
export function useViewDraftsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewDraftsQuery, ViewDraftsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewDraftsQuery, ViewDraftsQueryVariables>(ViewDraftsDocument, baseOptions);
        }
export type ViewDraftsQueryHookResult = ReturnType<typeof useViewDraftsQuery>;
export type ViewDraftsLazyQueryHookResult = ReturnType<typeof useViewDraftsLazyQuery>;
export type ViewDraftsQueryResult = ApolloReactCommon.QueryResult<ViewDraftsQuery, ViewDraftsQueryVariables>;
export const ViewPermissionsDocument = gql`
    query viewPermissions($projectName: String!, $entityName: String!) {
  entity(name: $entityName) {
    id
    isTeam
  }
  project(name: $projectName, entityName: $entityName) {
    id
    access
  }
}
    `;
export type ViewPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>, 'query'> & ({ variables: ViewPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewPermissionsComponent = (props: ViewPermissionsComponentProps) => (
      <ApolloReactComponents.Query<ViewPermissionsQuery, ViewPermissionsQueryVariables> query={ViewPermissionsDocument} {...props} />
    );
    
export type ViewPermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewPermissionsQuery, ViewPermissionsQueryVariables>
    } & TChildProps;
export function withViewPermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewPermissionsQuery,
  ViewPermissionsQueryVariables,
  ViewPermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewPermissionsQuery, ViewPermissionsQueryVariables, ViewPermissionsProps<TChildProps, TDataName>>(ViewPermissionsDocument, {
      alias: 'viewPermissions',
      ...operationOptions
    });
};

/**
 * __useViewPermissionsQuery__
 *
 * To run a query within a React component, call `useViewPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPermissionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useViewPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPermissionsQuery, ViewPermissionsQueryVariables>(ViewPermissionsDocument, baseOptions);
      }
export function useViewPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPermissionsQuery, ViewPermissionsQueryVariables>(ViewPermissionsDocument, baseOptions);
        }
export type ViewPermissionsQueryHookResult = ReturnType<typeof useViewPermissionsQuery>;
export type ViewPermissionsLazyQueryHookResult = ReturnType<typeof useViewPermissionsLazyQuery>;
export type ViewPermissionsQueryResult = ApolloReactCommon.QueryResult<ViewPermissionsQuery, ViewPermissionsQueryVariables>;
export const ViewViewDocument = gql`
    mutation viewView($id: ID!) {
  viewView(input: {id: $id}) {
    success
  }
}
    `;
export type ViewViewMutationFn = ApolloReactCommon.MutationFunction<ViewViewMutation, ViewViewMutationVariables>;
export type ViewViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ViewViewMutation, ViewViewMutationVariables>, 'mutation'>;

    export const ViewViewComponent = (props: ViewViewComponentProps) => (
      <ApolloReactComponents.Mutation<ViewViewMutation, ViewViewMutationVariables> mutation={ViewViewDocument} {...props} />
    );
    
export type ViewViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ViewViewMutation, ViewViewMutationVariables>
    } & TChildProps;
export function withViewView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewViewMutation,
  ViewViewMutationVariables,
  ViewViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ViewViewMutation, ViewViewMutationVariables, ViewViewProps<TChildProps, TDataName>>(ViewViewDocument, {
      alias: 'viewView',
      ...operationOptions
    });
};

/**
 * __useViewViewMutation__
 *
 * To run a mutation, you first call `useViewViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewViewMutation, { data, loading, error }] = useViewViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ViewViewMutation, ViewViewMutationVariables>) {
        return ApolloReactHooks.useMutation<ViewViewMutation, ViewViewMutationVariables>(ViewViewDocument, baseOptions);
      }
export type ViewViewMutationHookResult = ReturnType<typeof useViewViewMutation>;
export type ViewViewMutationResult = ApolloReactCommon.MutationResult<ViewViewMutation>;
export type ViewViewMutationOptions = ApolloReactCommon.BaseMutationOptions<ViewViewMutation, ViewViewMutationVariables>;