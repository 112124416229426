export type SmoothingType = 'exponential' | 'gaussian' | 'average' | 'none';

export function smooth(
  data: number[],
  dataX: number[],
  smoothingParameter: number,
  smoothingType: SmoothingType
) {
  if (smoothingType === 'exponential') {
    return smoothExponentialMovingAverage(data, smoothingParameter);
  } else if (smoothingType === 'gaussian') {
    return smoothGaussian(data, dataX, smoothingParameter);
  } else if (smoothingType === 'average') {
    return smoothMovingAverage(data, smoothingParameter);
  } else {
    // should not be possible to reach
    return smoothExponentialMovingAverage(data, smoothingParameter);
  }
}

export function smoothExponentialMovingAverage(
  data: number[],
  smoothingParam: number
) {
  /**
   * Exponential moving average with zero-debias. Same algothim as tensorboard.
   */

  // for legacy compatibility we pass in the square of the smoothing value as the smoothing
  // parameter for exponential moving average.

  const smoothingWeight = Math.min(Math.sqrt(smoothingParam || 0), 0.999);
  const smoothedData: number[] = [];
  let last = data.length > 0 ? 0 : NaN;
  let numAccum = 0;
  const isConstant = data.every(v => v === data[0]);

  data.forEach(d => {
    const nextVal = d;
    if (isConstant || !Number.isFinite(nextVal)) {
      smoothedData.push(nextVal);
      return;
    } else {
      last = last * smoothingWeight + (1 - smoothingWeight) * nextVal;
      numAccum++;

      let debiasWeight = 1;
      if (smoothingWeight !== 1.0) {
        debiasWeight = 1.0 - Math.pow(smoothingWeight, numAccum);
      }
      smoothedData.push(last / debiasWeight);
    }
  });
  return smoothedData;
}

export function smoothMovingAverage(data: number[], windowLength: number) {
  const smoothedData: number[] = [];
  for (let i = 0; i < data.length; i++) {
    let sum = 0;
    let count = 0;
    for (
      let j = Math.ceil(i - windowLength / 2);
      j < Math.ceil(i + windowLength / 2);
      j++
    ) {
      const clampedJ = clamp(j, [0, data.length - 1]);
      if (Number.isFinite(data[clampedJ])) {
        sum += data[clampedJ];
        count += 1;
      }
    }
    if (count > 0) {
      smoothedData.push(sum / count);
    } else {
      smoothedData.push(NaN);
    }
  }
  return smoothedData;
}

export function smoothGaussian(data: number[], dataX: number[], sigma: number) {
  /**
   * Performs gaussian smoothing and returns an array of same length as data.
   * Might want to have a version in the future where the x values of the returned array
   * are linspace.
   *
   * This is an N^2 algorithm where N is length of data. Definitely could speed this up
   * with an approximation by not looking at far away points if it becomes a bottleneck.
   */
  if (data.length !== dataX.length) {
    console.warn('Smoothing with different length x and data');
    return data;
  }
  if (sigma <= 0) {
    console.warn('Sigma must be greater than 0');
    return data;
  }

  const smoothedData: number[] = [];
  const smoothedX = dataX;
  for (const x1 of smoothedX) {
    let weightSum = 0;
    let sum = 0;
    for (let j = 0; j < dataX.length; j++) {
      const x2 = dataX[j];
      const y = data[j];
      const delta = x2 - x1;
      const weight = Math.exp(-(delta * delta) / (2 * sigma * sigma));
      sum += weight * y;
      weightSum += weight;
    }
    smoothedData.push(sum / weightSum);
  }
  return smoothedData;
}

export function clamp(value: number, [low, high]: [number, number]) {
  return Math.min(Math.max(value, low), high);
}

// .e.g.
// Rounds the first number to the nearest multiple of the second
//
// 11 , 3  => 9
// 22 , 13 => 13
// 52 , 8 => 5
export function roundToNearestMultiple(value: number, multiple: number) {
  const half = multiple / 2;
  return value + half - ((value + half) % multiple);
}

// Check if v is in the range of x1, x2
export function inRange(v: number, x1: number, x2: number) {
  return v > x1 && v < x2;
}
