import * as S from './WBModal.styles';

import * as React from 'react';
import makeComp from '../util/profiler';
import {ButtonProps} from 'semantic-ui-react';

interface WBModalProps {
  trigger?: React.ReactNode;
  open?: boolean;
  header?: string;
  primaryAction?: ButtonProps;
  // Presence of includeCancel prop indicates we want a visible cancel button.
  includeCancel?: boolean;
  onClose?(): void;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

const WBModal: React.FC<WBModalProps> = makeComp(
  ({
    trigger,
    open,
    header,
    primaryAction,
    includeCancel,
    onClose,
    onClick,
    children,
  }) => {
    return (
      <S.WBModal
        trigger={trigger}
        open={open}
        onClose={onClose}
        onClick={onClick}>
        {header && <S.WBModalHeader>{header}</S.WBModalHeader>}
        <S.WBModalContent>{children}</S.WBModalContent>
        <S.WBModalActions>
          {/* Cancel is usually the first item in a row of actions */}
          {includeCancel && (
            <S.WBModalButton content="Cancel" onClick={onClose} />
          )}
          {primaryAction && <S.WBModalButton primary {...primaryAction} />}
        </S.WBModalActions>
      </S.WBModal>
    );
  },
  {id: 'WBModal', memo: true}
);

export default WBModal;
