import React from 'react';
import classNames from 'classnames';

import {fuzzyMatchHighlight} from '../../util/fuzzyMatch';
import makeComp from '../../util/profiler';
import {PlotFontSize} from '../../util/plotHelpers';

interface PanelTitleProps {
  title: string;
  searchQuery?: string;
  fontSize?: PlotFontSize | 'auto';
}

const PanelTitle: React.FC<PanelTitleProps> = makeComp(
  props => {
    return (
      <h6
        className={classNames('panel-title', props.fontSize)}
        title={props.title}>
        {props.searchQuery
          ? fuzzyMatchHighlight(props.title, props.searchQuery)
          : props.title}
      </h6>
    );
  },
  {id: 'PanelTitle'}
);

export default PanelTitle;
