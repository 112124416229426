import _ from 'lodash';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export function secondsToHours(x: number): number {
  return x / (HOUR / SECOND);
}
export function hoursToSeconds(x: number): number {
  return x * (HOUR / SECOND);
}

export class TimeDelta {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;

  constructor(seconds: number) {
    let remaining = seconds;
    this.seconds = remaining % 60;
    remaining = Math.floor(remaining / 60);
    this.minutes = remaining % 60;
    remaining = Math.floor(remaining / 60);
    this.hours = remaining % 24;
    remaining = Math.floor(remaining / 24);
    this.days = remaining;
  }

  toSingleUnitString(): string {
    // Return an approximate string using the largest unit that would
    // have a non-zero value.
    if (this.days !== 0) {
      return this.days + ' day' + (this.days !== 1 ? 's' : '');
    } else if (this.hours !== 0) {
      return this.hours + ' hour' + (this.hours !== 1 ? 's' : '');
    } else if (this.minutes !== 0) {
      return this.minutes + ' minute' + (this.minutes !== 1 ? 's' : '');
    } else {
      return this.seconds + ' second' + (this.seconds !== 1 ? 's' : '');
    }
  }

  toHoursString(): string {
    let hours = this.hours;
    hours += this.days * 24;
    hours += this.minutes / 60;
    hours += this.seconds / 3600;

    return (
      Number(hours.toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2,
      }) +
      ' hour' +
      (hours !== 1 ? 's' : '')
    );
  }

  toDHMSString(): string {
    // Produces string in format Ddays HH:mm:ss
    let result = `${_.padStart(this.hours.toString(), 2, '0')}:${_.padStart(
      this.minutes.toString(),
      2,
      '0'
    )}:${_.padStart(this.seconds.toString(), 2, '0')}`;
    if (this.days) {
      result = `${this.days}d ${result}`;
    }
    return result;
  }
}
// given ns, the number of seconds elapsed,
// return the time elapsed as a factorization into whole
// units from largest to smallest, down to seconds
// largest unit in this timestamp will be months
// e.g. 2mo 17d 2h 18m 6s
export function monthRoundedTime(ns: number) {
  // convenient units in seconds
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;

  const mo = Math.floor(ns / month);
  const d = Math.floor((ns % month) / day);
  const h = Math.floor((ns % day) / hour);
  const m = Math.floor((ns % hour) / minute);
  const s = Math.floor(ns % minute);

  const moDisplay = mo > 0 ? mo + 'mo ' : '';
  const dDisplay = d > 0 ? d + 'd ' : '';
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s >= 0 ? s + 's' : '';
  return moDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
}

// takes a duration (in seconds) and returns formatted string like hh:mm:ss
export function formatTime(duration: number | undefined) {
  if (_.isUndefined(duration)) {
    duration = 0;
  }
  duration = Math.ceil(duration);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  let timeArray = [minutes, seconds];
  if (hours > 0) {
    timeArray = [hours].concat(timeArray);
  }
  return timeArray.map(t => ('0' + t).slice(-2)).join(':');
}

// This function is used to convert the Date strings returned
// from the server to local time adjusted Date
//
// The server returns DateTime as UTC strings, but they do not
// have the Z postfix required for the browse to recognize them
// as UTC and convert them correct to the timezone
//
// NOTE: This calls to this function should get moved to
//       Apollo middleware in a cleanup that involves fixing the
//       incorrect types as well
export function DateFromUTCString(d: Date | string) {
  // Adding a string will call toString on the date object
  return new Date(d + 'Z');
}

export function addUTCTimezoneIfNotPresent(isoDateString: string): string {
  if (isoDateString.endsWith('Z')) {
    return isoDateString;
  }
  return isoDateString + 'Z';
}
