import * as globals from '../css/globals.styles';

import styled from 'styled-components';

export const CodeBlockWrapper = styled.div`
  border: 1px solid ${globals.gray300};
  background: ${globals.white};
  padding: 20px 24px;
  border-radius: 4px;
`;

export const TitleWrapper = styled.div`
  padding: 4px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`;

export const DescriptionWrapper = styled.div`
  font-size: 16px;
  padding: 4px 0;
  color: ${globals.gray600};
`;

export const CodeWrapper = styled.div`
  padding: 4px 0;
  .code__block {
    padding: 0;
    .copyable__item {
      .copyable__text {
        padding: 24px 0 24px 24px;
        overflow: auto;
        width: 100%;

        .code__item {
          padding-left: 20px;
        }
      }

      .copyable__copy-icon {
        left: auto;
        top: 16px;
        right: 16px;
        margin: 0;
      }
    }
  }
`;
