import * as S from './videos.styles';

import React from 'react';
import {Node, Element, Transforms} from 'slate';
import {RenderElementProps, ReactEditor, useSelected} from 'slate-react';
import {BlockWrapper} from './drag-drop';
import * as queryString from 'query-string';

export interface Video extends Element {
  type: 'video';
  url: string;
}

export const isVideo = (node: Node): node is Video => node.type === 'video';

export const VideoElement: React.FC<
  RenderElementProps & {
    element: Video;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.EmbeddedIframe
        selected={selected}
        title={element.url}
        src={element.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      {children}
    </BlockWrapper>
  );
};

const VIDEO_REGEX = /^https?:\/\/www\.youtube\.com\/watch\/?\?.+$/;
const VIDEO_REGEX_SHORTENED = /^https?:\/\/youtu\.be\/(\w+)\/?(\?.+)?$/;
const VIDEO_REGEX_EMBED =
  /^https?:\/\/www\.youtube\.com\/embed\/\w+\/?(\?.+)?$/;

export const withVideos = <T extends ReactEditor>(editor: T) => {
  const {isVoid, insertText} = editor;

  editor.isVoid = element => {
    return isVideo(element) ? true : isVoid(element);
  };

  editor.insertText = text => {
    if (VIDEO_REGEX_EMBED.test(text)) {
      insertVideoElement(text);
      return;
    }

    const shortenedMatch = VIDEO_REGEX_SHORTENED.exec(text);
    if (shortenedMatch != null) {
      const {
        query: {t},
      } = queryString.parseUrl(text);
      let url = `https://www.youtube.com/embed/${shortenedMatch[1]}`;
      if (t && typeof t === 'string') {
        // TODO(axel): Support full YouTube timestamp format. Currently only supports seconds.
        url += `?start=${t.replace(/\D/g, '')}`;
      }
      insertVideoElement(url);
      return;
    }

    if (VIDEO_REGEX.test(text)) {
      const {
        query: {v, t},
      } = queryString.parseUrl(text);
      if (v && typeof v === 'string') {
        let url = `https://www.youtube.com/embed/${v}`;
        if (t && typeof t === 'string') {
          // TODO(axel): Support full YouTube timestamp format. Currently only supports seconds.
          url += `?start=${t.replace(/\D/g, '')}`;
        }
        insertVideoElement(url);
        return;
      }
    }

    insertText(text);
  };

  return editor;

  function insertVideoElement(url: string) {
    const node: Node = {
      type: 'video',
      url,
      children: [{text: ''}],
    };

    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
  }
};
