import '../css/ProjectAccess.less';

import React, {useState} from 'react';
import {Button, Checkbox, Icon, Label, Modal} from 'semantic-ui-react';

import {AccessOptions} from '../util/permissions';
import LegacyWBIcon from './elements/LegacyWBIcon';
import makeComp from '../util/profiler';

const accessMatches = (accessA: AccessOptions, accessB: AccessOptions) => {
  return (
    accessA === accessB ||
    (accessA !== 'USER_READ' &&
      accessA !== 'USER_WRITE' &&
      accessB !== 'USER_READ' &&
      accessB !== 'USER_WRITE')
  );
};

export interface ProjectAccessOptionProps {
  name: string;
  icon: string;
  access: AccessOptions;
  description: string;
  disabled: boolean;
  warning: boolean;
  default: boolean;
  selected: boolean;
  overrideText: string | false;
  advanced?: boolean;
  select(): void;
}

export interface ProjectAccessInput {
  readOnlyAdmin: boolean;
  projectAccess: AccessOptions;
  defaultAccess: AccessOptions;
  privateOnly: boolean;
  isTeam: boolean;
  projectTypeString?: string;
  selectAccess(access: string): void;
}

export function projectAccessOptions(
  input: ProjectAccessInput
): ProjectAccessOptionProps[] {
  const {
    readOnlyAdmin,
    projectAccess,
    defaultAccess,
    privateOnly,
    isTeam,
    selectAccess,
  } = input;

  const projectTypeString = input.projectTypeString || 'project';

  if (isTeam) {
    return [
      {
        name: `Team ${projectTypeString}`,
        icon: 'lock',
        access: 'ENTITY_READ',
        description: `Only team members can see and edit this ${projectTypeString}.`,
        disabled: false,
        warning: false,
        default: accessMatches('ENTITY_READ', defaultAccess),
        selected: accessMatches('ENTITY_READ', projectAccess),
        overrideText: false,
        advanced: false,
        select: () => selectAccess('ENTITY_READ'),
      },
      {
        name: `Public ${projectTypeString}`,
        icon: 'lock-open',
        access: 'USER_READ',
        description: `Anyone can see runs and reports in this ${projectTypeString}.`,
        disabled: privateOnly && readOnlyAdmin,
        warning: true,
        default: accessMatches('USER_READ', defaultAccess),
        selected: accessMatches('USER_READ', projectAccess),
        overrideText:
          privateOnly &&
          !readOnlyAdmin &&
          `As a team admin you can make ${projectTypeString}s public.`,
        advanced: false,
        select: () => selectAccess('USER_READ'),
      },
      {
        name: `Open ${projectTypeString}`,
        icon: 'lock-open',
        access: 'USER_WRITE',
        description: `Anyone can submit runs and write reports in this ${projectTypeString}.`,
        disabled: privateOnly && readOnlyAdmin,
        warning: true,
        default: accessMatches('USER_WRITE', defaultAccess),
        selected: accessMatches('USER_WRITE', projectAccess),
        overrideText:
          privateOnly &&
          !readOnlyAdmin &&
          `As a team admin you can make ${projectTypeString}s public.`,
        advanced: true,
        select: () => selectAccess('USER_WRITE'),
      },
    ];
  } else {
    return [
      {
        name: `Public ${projectTypeString}`,
        icon: 'lock-open',
        access: 'USER_READ',
        description: `Anyone can see runs and reports in this ${projectTypeString}.`,
        disabled: privateOnly && readOnlyAdmin,
        warning: false,
        default: accessMatches('USER_READ', defaultAccess),
        selected: accessMatches('USER_READ', projectAccess),
        overrideText: false,
        advanced: false,
        select: () => selectAccess('USER_READ'),
      },
      {
        name: `Private ${projectTypeString}`,
        icon: 'lock',
        access: 'ENTITY_READ',
        description: `Only you can view and edit this ${projectTypeString}.`,
        disabled: false,
        warning: false,
        default: accessMatches('ENTITY_READ', defaultAccess),
        selected: accessMatches('ENTITY_READ', projectAccess),
        overrideText: false,
        advanced: false,
        select: () => selectAccess('ENTITY_READ'),
      },
      {
        name: `Open ${projectTypeString}`,
        icon: 'lock-open',
        access: 'USER_WRITE',
        description: `Anyone can submit runs and write reports in this ${projectTypeString}.`,
        disabled: privateOnly && readOnlyAdmin,
        warning: false,
        default: accessMatches('USER_WRITE', defaultAccess),
        selected: accessMatches('USER_WRITE', projectAccess),
        overrideText: false,
        advanced: true,
        select: () => selectAccess('USER_WRITE'),
      },
    ];
  }
}

function convertOldAccess(access: AccessOptions): AccessOptions {
  if (access === 'PRIVATE') {
    return 'ENTITY_READ';
  }
  return access;
}

export const ProjectAccessOption: React.FC<ProjectAccessOptionProps> = makeComp(
  ({
    name,
    icon,
    access,
    description,
    disabled,
    warning,
    default: optionDefault,
    selected,
    overrideText,
    select,
  }) => {
    return (
      <Checkbox
        radio
        label={
          <label>
            <div className="project-access--modal-option-title">
              <LegacyWBIcon className="lock-icon" name={icon} />
              {name}
              {warning && <Icon name="warning sign" color="red" />}
              {optionDefault && <Label color="grey">DEFAULT</Label>}
            </div>
            <div className="project-access--modal-option-description">
              {description}
            </div>
            {overrideText && (
              <div className="project-access--modal-option-description">
                {overrideText}
              </div>
            )}
          </label>
        }
        value={access}
        key={access}
        checked={selected}
        disabled={disabled}
        readOnly={disabled}
        className="project-access--modal-option"
        onClick={select}
      />
    );
  },
  {id: 'ProjectAccessOption', memo: true}
);

export interface ProjectAccessProject {
  readOnly: boolean;
  access: AccessOptions;
  entity: {
    readOnlyAdmin: boolean;
    defaultAccess: AccessOptions;
    privateOnly: boolean;
    isTeam: boolean;
  };
}

export interface ProjectAccessProps {
  project: ProjectAccessProject;
  compact?: boolean;
  updateProjectAccess(access: AccessOptions): void;
}

// This is the project access level icon (locked or unlocked) and its popup,
// including the option to change the access level (if you have permission)

const ProjectAccess: React.FC<ProjectAccessProps> = makeComp(
  ({project, compact, updateProjectAccess}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState<AccessOptions>(
      convertOldAccess(project.access)
    );
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const {readOnly, entity} = project;
    const {readOnlyAdmin, isTeam, privateOnly, defaultAccess} = entity;
    let projectAccess = project.access;
    // this is necessary for older projects (before we added the private projects flag)
    // see availableOptions() in ProjectEditor.js
    // TODO: data migration to fix this in the db!
    // TODO: Should change the type to reflect that this can be null
    if (!projectAccess) {
      projectAccess = defaultAccess;
    }

    let accessBadgeText: string;
    if (projectAccess === 'USER_READ') {
      accessBadgeText = 'PUBLIC';
    } else if (projectAccess === 'USER_WRITE') {
      accessBadgeText = 'OPEN';
    } else if (isTeam) {
      accessBadgeText = 'TEAM';
    } else {
      accessBadgeText = 'PRIVATE';
    }

    const options = projectAccessOptions({
      readOnlyAdmin,
      projectAccess: selectedAccess,
      defaultAccess,
      privateOnly,
      isTeam,
      selectAccess: (access: AccessOptions) => setSelectedAccess(access),
    });

    const currentOption = options.find(
      option => option.access === selectedAccess
    );
    const disabled = currentOption ? currentOption.disabled : false;

    const onIndicatorClick = () => {
      if (!readOnly) {
        setModalOpen(true);
        setSelectedAccess(convertOldAccess(projectAccess));
      }
    };

    return (
      <>
        {compact ? (
          <span
            className="project-access--indicator"
            onClick={onIndicatorClick}>
            {accessMatches('ENTITY_READ', projectAccess) ||
            accessMatches('ENTITY_WRITE', projectAccess) ? (
              <LegacyWBIcon className="lock-icon" name="lock" />
            ) : (
              <LegacyWBIcon className="lock-icon" name="lock-open" />
            )}
          </span>
        ) : (
          <div className="project-access">
            <Label
              as="a"
              size="medium"
              className={`project-access--badge ${accessBadgeText.toLowerCase()}`}
              onClick={onIndicatorClick}>
              {accessMatches('ENTITY_READ', projectAccess) ||
              accessMatches('ENTITY_WRITE', projectAccess) ? (
                <LegacyWBIcon className="lock-icon" name="lock" />
              ) : (
                <LegacyWBIcon className="lock-icon" name="lock-open" />
              )}
              {accessBadgeText}
            </Label>
          </div>
        )}
        <Modal
          open={modalOpen}
          className="project-access--modal"
          onClose={() => setModalOpen(false)}>
          <Modal.Header>Project Settings</Modal.Header>
          <Modal.Content>
            <p>Privacy settings affect your whole project.</p>
            {options.map(option => {
              if (option.advanced) {
                // hide open option by default
                if (!showAdvancedSettings) {
                  return <React.Fragment key="advanced"></React.Fragment>;
                }
              }
              return <ProjectAccessOption key={option.access} {...option} />;
            })}
            {!showAdvancedSettings && (
              <span
                className="show-advanced-settings-button"
                onClick={() => setShowAdvancedSettings(true)}>
                + Show advanced settings
              </span>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
            <Button
              primary
              disabled={disabled}
              onClick={() => {
                updateProjectAccess(selectedAccess);
                setModalOpen(false);
              }}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  },
  {id: 'ProjectAccess', memo: true}
);

export default ProjectAccess;
