import gql from 'graphql-tag';
import {useRef} from 'react';

import {useQuery} from './query';
import {combinedFilter} from './runSelectorQuery';

import * as Filter from '../../util/filters';
import * as SM from '../../util/selectionmanager';

import {useRunsQueryContext} from '../runs/hooks';
import * as RunSetTypes from '../views/runSet/types';
import * as ViewHooks from '../views/hooks';
import * as TempSelectionsTypes from '../views/tempSelections/types';

///// Query definition

export const SELECTED_COUNT_QUERY = gql`
  query SelectedCount(
    $name: String!
    $entityName: String
    $filters: JSONString
    $tempFilters: JSONString
  ) {
    project(name: $name, entityName: $entityName) {
      id
      selectedCount: runCount(filters: $filters)
      tempSelectedCount: runCount(filters: $tempFilters)
      tempOutputArtifactsCount: runOutputArtifactsCount(filters: $tempFilters)
    }
  }
`;

// These types define the inputs and outputs of the above gql query

export interface SelectedCountData {
  id: string;
  selectedCount: number;
  tempOutputArtifactsCount: number;
  tempSelectedCount: number;
}

// The query's output shape.
interface Data {
  project: SelectedCountData;
}

interface QueryInputProps {
  entityName: string;
  projectName: string;
  mergeFilters?: Filter.Filter;
  spamFilters?: Filter.Filter;
  filters: Filter.Filter;
  groupSelection: SM.GroupSelectionState;
  groupTempSelection: SM.GroupSelectionState;
  searchQuery?: string;
}

export function useSelectedCountQuery(inputProps: QueryInputProps) {
  const variables = {
    entityName: inputProps.entityName,
    name: inputProps.projectName,
    filters: JSON.stringify(
      combinedFilter(
        inputProps.filters,
        inputProps.groupSelection,
        inputProps.spamFilters,
        inputProps.mergeFilters,
        inputProps.searchQuery
      )
    ),
    tempFilters: JSON.stringify(
      combinedFilter(
        inputProps.filters,
        inputProps.groupTempSelection,
        undefined,
        inputProps.mergeFilters,
        inputProps.searchQuery
      )
    ),
  };
  return useQuery<Data, typeof variables>(SELECTED_COUNT_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  });
}

// A more component-friendly version of useSelectedCountQuery. Returns the most recent counts.
export function useSelectedCounts(
  runSetRef: RunSetTypes.Ref,
  tempSelectionsRef: TempSelectionsTypes.Ref,
  refetch?: boolean
) {
  const tempSelections = ViewHooks.useWhole(tempSelectionsRef);
  const runSetPart = ViewHooks.usePart(runSetRef);
  const groupSelections = ViewHooks.useWhole(runSetPart.groupSelectionsRef);
  const filters = ViewHooks.useWhole(runSetPart.filtersRef);

  const runsQueryContext = useRunsQueryContext();

  // runsQueryContext gives you the project/entity from the first runset.
  // This handles runsets from multiple projects in the same report.
  const selectedContext = {
    ...runsQueryContext,
    entityName: runSetPart.project?.entityName || runsQueryContext.entityName,
    projectName: runSetPart.project?.name || runsQueryContext.projectName,
  };

  const groupTempSelection = {
    grouping: groupSelections.grouping,
    selections: tempSelections,
    expandedRowAddresses: groupSelections.expandedRowAddresses,
  };
  const selectedCountQueryProps = {
    ...selectedContext,
    filters,
    groupSelection: groupSelections,
    groupTempSelection,
    searchQuery: runSetPart.search.query,
  };
  const selectedCountQuery = useSelectedCountQuery(selectedCountQueryProps);
  if (refetch && 'refetch' in selectedCountQuery) {
    selectedCountQuery.refetch();
  }

  const selectedCountsCached = useRef({
    tempOutputArtifactsCount: 0,
    tempSelectedCount: 0,
    selectedCount: 0,
  });

  if (!selectedCountQuery.loading && selectedCountQuery.project != null) {
    selectedCountsCached.current.tempOutputArtifactsCount =
      selectedCountQuery.project.tempOutputArtifactsCount;
    selectedCountsCached.current.tempSelectedCount =
      selectedCountQuery.project.tempSelectedCount;
    selectedCountsCached.current.selectedCount =
      selectedCountQuery.project.selectedCount;
  }

  return selectedCountsCached.current;
}
