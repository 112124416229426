import React from 'react';
import {Form, Radio} from 'semantic-ui-react';
import makeComp from '../util/profiler';

interface RadioFormOption {
  label: string;
  key: string;
}

interface RadioFormProps {
  name: string;
  options: RadioFormOption[];
  value: string;
  onChange: (newValue: string) => void;
}

const RadioForm: React.FC<RadioFormProps> = makeComp(
  props => {
    return (
      <>
        {props.options.map(option => (
          <Form.Field
            control={Radio}
            name={props.name}
            value={option.key}
            key={option.key}
            label={option.label}
            checked={props.value === option.key}
            onChange={() => props.onChange(option.key)}
          />
        ))}
      </>
    );
  },
  {id: 'RadioForm'}
);

export default RadioForm;
