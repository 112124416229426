import * as React from 'react';
import {Icon} from 'semantic-ui-react';

import {RenderFullscreen, RenderScreenshot} from '../util/render_babylon';
import {MediaPanelCardControl} from './MediaCard';
import makeComp from '../util/profiler';

export interface Media3DProps {
  width: number;
  height: number;
  blob: Blob;
  url: string;
  controls?: MediaPanelCardControl;
}

export const PointCloud: React.FunctionComponent<Media3DProps> = makeComp(
  props => {
    const {width, height, blob} = props;

    const babylonContainerRef = React.useRef<HTMLDivElement>(null);
    const [renderError, setRenderError] = React.useState<Error | null>(null);

    // Load babylon lib asynchronously to perform bundle splitting
    type BabylonLib = typeof import('../util/render_babylon');
    const [babylonLib, setBabylon] = React.useState<BabylonLib>();
    React.useEffect(() => {
      import('../util/render_babylon').then(setBabylon);
    }, []);

    const [screenshot, setScreenshot] = React.useState<string>();

    React.useEffect(() => {
      let cleanup: CallableFunction | undefined;
      const renderScreenshot = async () => {
        if (babylonContainerRef.current && babylonLib) {
          const fileContents = await new Response(blob).text();
          try {
            const result = babylonLib.renderJsonPoints<RenderScreenshot>(
              fileContents,
              {
                fullscreen: false,
                width,
                height,
              },
              props.controls?.cameraControl
            );
            cleanup = result.cleanup;
            const img = await babylonLib.renderScreenshot(result);
            setScreenshot(img);
          } catch (e) {
            setRenderError(e);
            console.error(e);
          }
        }
      };

      renderScreenshot();

      return () => {
        if (cleanup) {
          cleanup();
        }
      };
    }, [width, height, blob, babylonLib, props.controls]);

    // Callback launch fullscreen viewer
    // NOTE: This has to stay a callback because fullscreen
    // requests can only happen as a response to user actions
    const requestFullscreen = React.useCallback(() => {
      let cleanup: CallableFunction | undefined;
      const renderFullscreen = async () => {
        if (babylonLib && babylonContainerRef.current) {
          const domElement = babylonContainerRef.current;
          const fileContents = await new Response(blob).text();
          const result = await babylonLib.renderJsonPoints<RenderFullscreen>(
            fileContents,
            {
              domElement,
              fullscreen: true,
            }
          );
          babylonLib.renderFullscreen(result);
          cleanup = result.cleanup;
        }
      };
      renderFullscreen();

      return cleanup;
    }, [blob, babylonLib]);

    if (renderError != null) {
      return <div className="card3d">Error: {renderError.message}</div>;
    }

    return (
      <div className="media-card">
        <div className="object3D-card-babylon" ref={babylonContainerRef} />
        {screenshot && (
          <>
            <div className="media-card__fullscreen" onClick={requestFullscreen}>
              <Icon
                size="large"
                link
                className="media-card__fullscreen-button"
                name="expand arrows alternate"
              />
            </div>
            <img alt="point-cloud-card" src={screenshot} />
          </>
        )}
      </div>
    );
  },
  {id: 'PointCloud'}
);

export default PointCloud;
