import styled, {css} from 'styled-components';

export const EmbeddedIframe = styled.iframe<{
  selected: boolean;
}>`
  display: block;
  margin: 0 auto;
  height: min(calc((100vw - 128px) * 9 / 16), 603px);
  @media only screen and (max-width: 768px) {
    height: min(calc((100vw - 64px) * 9 / 16), 603px);
  }

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;
