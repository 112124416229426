import * as S from './ContactModalContent.styles';

import makeComp from '../util/profiler';
import {Button} from 'semantic-ui-react';
import React from 'react';

interface ContactModalProps {
  dataTest: string;
  message: JSX.Element;
  buttonText: string;
  upgrade?: boolean;
}

const ContactModalContent = makeComp(
  (props: ContactModalProps) => {
    const {dataTest, message, buttonText} = props;
    return (
      <S.ContentWrapper data-test={dataTest}>
        <S.MessageWrapper>{message}</S.MessageWrapper>
        <S.ButtonWrapper>
          <Button
            primary
            href="https://wandb.ai/site/local-contact"
            target="_blank"
            rel="noopener noreferrer">
            {buttonText}
          </Button>
        </S.ButtonWrapper>
        <S.SubMessageWrapper>
          Can't access the link? Contact our team at{' '}
          <S.LinkAnchor
            href="mailto:support@wandb.com"
            target="_blank"
            rel="noopener noreferrer">
            support@wandb.com
          </S.LinkAnchor>{' '}
          to {!!props.upgrade ? 'upgrade' : 'request'} a license.
        </S.SubMessageWrapper>
      </S.ContentWrapper>
    );
  },
  {id: 'ContactForLicenseModal'}
);

interface ContactForUpgradeContentProps {
  numSeats: number;
}

export const ContactForUpgradeContent = (
  props: ContactForUpgradeContentProps
) => {
  return (
    <ContactModalContent
      dataTest="contact-for-upgrade"
      message={
        <div>
          All {props.numSeats} seats are already in use. Contact us to upgrade
          your license so you can invite more collaborators.
        </div>
      }
      buttonText="Contact sales"
      upgrade
    />
  );
};

export const ContactForFreeLicenseContent = (
  <ContactModalContent
    dataTest="contact-for-license"
    message={
      <div>
        You're using a personal install of W&B. To invite collaborators, request
        a free license for up to 3 users.
      </div>
    }
    buttonText="Request a team license"
  />
);

export default ContactModalContent;
