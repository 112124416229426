import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';

import './polyfills';
import './integrations';
import {
  store,
  apolloClient,
  apolloClient2,
  history,
  debugCollector,
} from './setup';
import * as serviceWorker from './serviceWorker';
import config, {envIsDev, envIsIntegration} from './config';
import Routes from './routes';
import {Apollo2Context} from './util/apollo2-hooks';
import './index.css';
import 'semantic/dist/semantic.min.css';
import MessageOfTheDay from './components/MessageOfTheDay';

if (config.ENVIRONMENT_IS_PRIVATE) {
  // this obfuscates the original log call locations, so only use it
  // in local, when Sentry et all aren't available
  debugCollector.instrument();
}

const shouldRedirectToHTTPS = () =>
  (envIsDev || envIsIntegration) &&
  window.location.hostname !== 'localhost' &&
  window.location.protocol !== 'https:';

const render = async (Component: React.FC) => {
  if (shouldRedirectToHTTPS()) {
    window.location.protocol = 'https:';
    return;
  }
  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Apollo2Context.Provider value={apolloClient2}>
          <ConnectedRouter history={history}>
            <MessageOfTheDay />
            <Component />
          </ConnectedRouter>
        </Apollo2Context.Provider>
      </ApolloProvider>
    </Provider>,
    document.getElementById('root')
  );
};
// Note: Routes is App from App.js
render(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    render(NextApp);
  });
}

// We've disabled service workers because of caching issues described here:
// https://jeffy.info/2018/10/10/sw-in-c-r-a.html
if (config.ENABLE_SERVICE_WORKER) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
