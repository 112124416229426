import styled, {css} from 'styled-components';

export const DropdownHeader = styled.div`
  padding: 2px 12px 0;
  font-size: 12px;
  color: ${props => props.theme.text};
  opacity: 0.5;
`;

export const OptionalParameterOption = styled.div<{hovered: boolean}>`
  font-family: Inconsolata;
  color: ${props => props.theme.text};
  padding: 6px 12px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${props => (props.hovered ? props.theme.popupHover : 'none')};
`;

export const Wrapper = styled.div`
  display: inline;
  align-items: center;
`;

export const ArgsWrapper = styled.div<{expanded?: boolean}>`
  ${props =>
    props.expanded
      ? css`
          padding-left: 16px;
          border-left: 1px solid ${props.theme.indenter};
        `
      : css`
          display: inline;
        `}
`;

export const EllipsisButton = styled.div<{open: boolean}>`
  user-select: none;
  display: inline-flex;
  align-items: center;
  background: ${props => props.theme.clickable};
  border-radius: 6px;
  height: 12px;
  cursor: pointer;
  vertical-align: middle;
  .wbic-ic-overflow {
    font-size: 20px;
    margin: 0;
    transform: rotate(90deg);
  }
  ${props =>
    props.open &&
    css`
      background: ${props.theme.focused};
    `}
  &:hover {
    background: ${props => props.theme.focused};
  }
`;
