import {getCookie, setCookie, unsetCookie} from './cookie';
import {useViewer} from '../state/viewer/hooks';

const USE_ADMIN_PRIVILEGES_COOKIE_KEY = 'use_admin_privileges';

export function useAdminModeActive(): boolean {
  const viewer = useViewer();
  if (!viewer?.admin) {
    return false;
  }
  return viewerUsingAdminPrivileges();
}

export function viewerUsingAdminPrivileges(): boolean {
  return getCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY) === 'true';
}

export function setViewerUsingAdminPrivileges(enable: boolean): void {
  if (enable) {
    setCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY, 'true');
  } else {
    unsetCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY);
  }
}

const wandbDomainEmailRegex = new RegExp('^.+?@wandb.com$');
export function isWandbDomainEmail(email: string): boolean {
  return wandbDomainEmailRegex.test(email);
}
