import '../../css/Views/ViewMenu.less';

import React, {FC} from 'react';
import {shallowEqual} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';

import {useSelector} from '../../state/hooks';
import * as ViewerHooks from '../../state/viewer/hooks';
import {useWorkspaceAction} from '../../state/workspaces/hooks';
import * as WorkspaceActions from '../../state/workspaces/actions';
import {viewerUsingAdminPrivileges} from '../../util/admin';
import makeComp from '../../util/profiler';
import {TargetBlank} from '../../util/links';
import docUrl from '../../util/doc_urls';

interface ViewMenuProps {
  workspaceID: string;
  trigger: React.ReactNode;
  currentView: string;
  isOwnView?: boolean | null;
}

type AllViewMenuProps = ViewMenuProps & ReturnType<typeof useViewMenuProps>;

const ViewMenu: FC<AllViewMenuProps> = makeComp(
  ({
    trigger,
    currentView,
    isOwnView,
    views,
    viewerIsAdmin,
    loadDefault,
    load,
    clearView,
    copyToDefault,
  }) => {
    return (
      <Dropdown
        floating
        className="view-menu dropdown-menu-text-icon-button"
        trigger={trigger}
        direction="right">
        <Dropdown.Menu>
          <Dropdown.Header
            content="Load another workspace"
            className="small-header"
          />
          <div className="scroll-container">
            {currentView !== 'default' && (
              <Dropdown.Item
                onClick={loadDefault}
                className="load-default-workspace-item"
                icon="home"
                text="Default"
              />
            )}
            {views
              // Only admins can see admin views
              .filter(
                view =>
                  view.user.username !== currentView &&
                  (viewerIsAdmin && viewerUsingAdminPrivileges()
                    ? true
                    : !view.user.admin)
              )
              .map(view => (
                <Dropdown.Item
                  key={view.cid}
                  onClick={() => load(view.cid)}
                  image={{
                    avatar: true,
                    src: view.user.photoUrl,
                    onError: (img: any) =>
                      (img.target.style.visibility = 'hidden'),
                  }}
                  content={view.user.username}
                />
              ))}
          </div>
          {isOwnView && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header
                className="small-header"
                content="Workspace actions"
              />
              <Dropdown.Item text="Clear workspace" onClick={clearView} />
              <Dropdown.Item
                text="Copy to default workspace"
                onClick={copyToDefault}
              />
            </>
          )}
          <>
            <Dropdown.Divider />
            <Dropdown.Header
              className="small-header"
              content="Workspace documentation"
            />
            <Dropdown.Item
              as={TargetBlank}
              href={docUrl.workspaces}
              text="How to use workspaces"
            />
          </>
        </Dropdown.Menu>
      </Dropdown>
    );
  },
  {id: 'ViewMenu', memo: true}
);

function useViewMenuProps(props: ViewMenuProps) {
  const viewer = ViewerHooks.useViewer();
  const workspaceViews = useSelector(state => {
    const workspace = state.workspaces[props.workspaceID];
    if (workspace.viewListID == null) {
      return [];
    }
    const metadataQuery = state.views.lists[workspace.viewListID];
    if (metadataQuery == null) {
      throw new Error('Unexpected state');
    }
    return metadataQuery.viewIds
      .map(vid => state.views.views[vid])
      .filter(v => v.name === 'workspace');
  }, shallowEqual);
  const copyToDefault = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.copyToDefault
  );
  const clearView = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.clearView
  );
  const load = useWorkspaceAction(props.workspaceID, WorkspaceActions.load);
  const loadDefault = useWorkspaceAction(
    props.workspaceID,
    WorkspaceActions.loadDefault
  );
  return {
    viewerIsAdmin: viewer != null ? viewer.admin : false,
    views: workspaceViews,
    copyToDefault,
    clearView,
    load,
    loadDefault,
  };
}

export default makeComp(
  (props: ViewMenuProps) => {
    const selectedProps = useViewMenuProps(props);
    return <ViewMenu {...props} {...selectedProps} />;
  },
  {id: 'ViewMenuProps'}
);
