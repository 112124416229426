import * as S from './QueryArgsEditor.styles';
import * as QueryEditorStyles from './QueryEditor.styles';

import React from 'react';
import {ThemeContext} from 'styled-components';
import {WBMenuOption} from '@wandb/ui';
import QueryArgEditor from './QueryArgEditor';
import {WBPopupMenuTrigger} from '@wandb/ui';
import * as ApiSchemaTypes from '../../generated/apiSchema';
import {schemaTypeToColoredString} from './QueryEditor';
import * as Update from '../../util/update';
import LegacyWBIcon from '../elements/LegacyWBIcon';
import {
  QueryTemplateArg,
  getDefaultArgFromSchemaArgType,
  QueryArg,
  argsCharCount,
} from '../../util/vega3';
import * as _ from 'lodash';
import makeComp from '../../util/profiler';

interface QueryArgsEditorProps {
  typeName: string;
  fieldName: string;
  args?: QueryArg[];
  fixedArgs?: QueryArg[];
  templateArgs: QueryTemplateArg[];
  schemaArgs: ApiSchemaTypes.__InputValue[];
  indentLevel: number;
  charsBefore: number;
  setArgs(args: QueryArg[]): void;
}

function parseRequiredNumberArg(requiredArg: ApiSchemaTypes.__InputValue) {
  return Number(requiredArg.defaultValue);
}

const QueryArgsEditor: React.FC<QueryArgsEditorProps> = makeComp(
  props => {
    const {
      typeName,
      fieldName,
      fixedArgs,
      schemaArgs,
      templateArgs,
      indentLevel,
      charsBefore,
      setArgs,
    } = props;
    const themeContext = React.useContext(ThemeContext);
    const args = props.args || [];
    const requiredSchemaArgs = schemaArgs.filter(
      a => a.type.kind === 'NON_NULL'
    );
    for (const requiredSchemaArg of requiredSchemaArgs) {
      const argIndex = args.findIndex(
        arg => arg.name === requiredSchemaArg.name
      );
      if (argIndex === -1) {
        // this needs to be done because the default value for schema args is string
        const argValue =
          requiredSchemaArg.type.ofType?.name === 'Int' ||
          requiredSchemaArg.type.ofType?.name === 'Float'
            ? parseRequiredNumberArg(requiredSchemaArg)
            : requiredSchemaArg.defaultValue;
        args.push({
          name: requiredSchemaArg.name,
          value: argValue,
        });
      }
    }
    const missingSchemaArgs = schemaArgs
      .filter(
        schemaArg =>
          _.findIndex(args, arg => arg.name === schemaArg.name) === -1
      )
      // filter out TableFilters since they are not implemented
      .filter(type => type.type.name !== 'TableFilter');

    const dropdownOptions: WBMenuOption[] = [
      {
        value: -1,
        disabled: true,
        render: () => (
          <S.DropdownHeader>Add optional parameter</S.DropdownHeader>
        ),
      },
    ];

    const expandedView =
      indentLevel * 2 + charsBefore + 1 + argsCharCount(args) >
      QueryEditorStyles.MAX_CHARS_PER_LINE;

    dropdownOptions.push(
      ...missingSchemaArgs.map<WBMenuOption>((schemaArg, i) => {
        return {
          name: schemaArg.name,
          value: i,
          render: ({hovered}) => (
            <S.OptionalParameterOption hovered={hovered}>
              <QueryEditorStyles.ParamSpan>
                {schemaArg.name}
              </QueryEditorStyles.ParamSpan>
              :&nbsp;
              {schemaTypeToColoredString(schemaArg.type)}
            </S.OptionalParameterOption>
          ),
        };
      })
    );

    let rollingCharsBefore = props.charsBefore;

    return (
      <S.Wrapper>
        {args.length > 0 && <span>(</span>}
        <S.ArgsWrapper expanded={expandedView}>
          {args.length > 0 &&
            args.map((arg, i) => {
              const schemaArg = schemaArgs.find(a => a.name === arg.name)!;
              const fixedArg = (fixedArgs || []).find(a => a.name === arg.name);
              const tempCharsBefore = rollingCharsBefore;
              let tableName: string | undefined;
              if (arg.name === 'tableColumns') {
                tableName = args[0].value;
              }
              rollingCharsBefore += argsCharCount([arg]);
              return (
                <React.Fragment key={schemaArg.name}>
                  <QueryArgEditor
                    argIndex={i}
                    schemaArg={schemaArg}
                    arg={arg}
                    fieldName={props.fieldName}
                    fixedArg={fixedArg}
                    tableName={tableName}
                    templateArg={templateArgs.find(
                      a =>
                        a.typeName === typeName &&
                        a.fieldName === fieldName &&
                        a.argName === arg.name &&
                        a.value === arg.value
                    )}
                    expandedView={expandedView}
                    indentLevel={indentLevel}
                    charsBefore={expandedView ? 0 : tempCharsBefore}
                    deleteArg={() => setArgs(Update.deleteArrayIndex(args, i))}
                    setArg={newArg =>
                      setArgs(Update.updateArrayIndex(args, i, newArg))
                    }></QueryArgEditor>
                  {i < args.length - 1 && (
                    <span style={{pointerEvents: 'none'}}>,&nbsp;</span>
                  )}
                  {expandedView && <br />}
                </React.Fragment>
              );
            })}
          {missingSchemaArgs.length > 0 && (
            <>
              {!expandedView && <>&nbsp;</>}
              <WBPopupMenuTrigger
                menuBackgroundColor={themeContext.popupBackground}
                options={dropdownOptions}
                onSelect={value => {
                  setArgs(
                    Update.insertArrayItem(args, args.length, {
                      name: missingSchemaArgs[value as number].name,
                      value:
                        missingSchemaArgs[value as number].defaultValue ??
                        getDefaultArgFromSchemaArgType(
                          missingSchemaArgs[value as number].type
                        ),
                    })
                  );
                }}>
                {({anchorRef, open, setOpen}) => (
                  <S.EllipsisButton
                    ref={anchorRef}
                    open={open}
                    onClick={() => setOpen(o => !o)}>
                    <LegacyWBIcon name="overflow"></LegacyWBIcon>
                  </S.EllipsisButton>
                )}
              </WBPopupMenuTrigger>
            </>
          )}
        </S.ArgsWrapper>
        {args.length > 0 && ')'}
      </S.Wrapper>
    );
  },
  {id: 'QueryArgsEditor'}
);

export default QueryArgsEditor;
