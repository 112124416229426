import React from 'react';
import {Button, Modal} from 'semantic-ui-react';
import '../css/SubscriptionAgreement.less';
import makeComp from '../util/profiler';
import Markdown from './Markdown';

type SubscriptionAgreementProps = {
  onClose(): void;
};

const SubscriptionAgreement: React.FC<SubscriptionAgreementProps> = makeComp(
  ({onClose}) => {
    return (
      <Modal className="terms-modal" size="small" basic open onClose={onClose}>
        <Markdown content={subscriptionAgreement} condensed={false} />
        <Button className="close-button" basic color="grey" onClick={onClose}>
          Close
        </Button>
      </Modal>
    );
  },
  {id: 'SubscriptionAgreement', memo: true}
);

export default SubscriptionAgreement;

// Our subscription agreement. Canonical copy is here: https://docs.google.com/document/d/11UrlMzMADfnmBmNLTXJBwJJIaU33RQeVBwwusG1UfrA/.
const subscriptionAgreement = `
# W&B Platform Subscription Agreement
(last updated January 20, 2020)

**This W&B Platform Subscription Agreement** (this “**Agreement**”) is a legal agreement between Weights and Biases Inc. (“**W&B**”, “**we**,” “**us**,” or “**our**”) and the company or individual (“**Subscriber**”) that is identified as the “Subscriber” on the software subscription registration page on W&B’s website (the “**Registration Page**”).  This Agreement sets forth the terms and conditions on which W&B will provide Subscriber with access to the web-based machine learning development tools that are included in Subscriber’s subscription, as set forth on the Registration Page (the “**W&B Platform**”).  

**If you agree on behalf of the Subscriber to the terms and conditions set forth in this Agreement and wish to access and use the W&B Platform, please click on the “I Agree” button below.  This will constitute a binding agreement between the Subscriber and W&B.  If you do not agree to the terms and conditions of this Agreement, please click the “Cancel” button.  Subscriber will not be permitted to access or use the W&B Platform unless you agree to the terms and conditions of this Agreement on behalf of the Subscriber.  If you agree to the terms and conditions of this Agreement, then these terms and conditions will apply to all subsequent access and use of W&B Platform by or on behalf of Subscriber, whether by you or by any other user.**

For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, W&B and Subscriber (collectively, the “**Parties**” and each, a “**Party**”) hereby agree as follows:

1. **Software Subscription**.  W&B grants Subscriber the non-exclusive right to access and use the W&B Platform during the Term (as defined below), on a software-as-a-service basis, subject to the terms of this Agreement.

2. **Subscription Period**.  W&B offers subscriptions to the W&B Software on annual basis, and may offer other subscription plans (e.g., monthly).  Subscriber’s subscription to the W&B Software is annual, unless a different subscription plan is offered by W&B and selected by Subscriber on the Registration Page.  For annual subscriptions, the “**Subscription Period**” means one (1) year.  For other subscription plans, the “**Subscription Period**” means the period provided for in the subscription plan selected by Subscriber on the Registration Page (e.g., one (1) month).  The first Subscription Period begins when Subscriber agrees to this Agreement.  All subscriptions automatically renew for consecutive Subscription Periods, unless terminated in accordance with Section 6 below

3. **Usage Limitations**.  Subscriber’s use of the W&B Platform is subject to any applicable usage limitations set on the Registration Page. 

4. **Support Services**.  As part of Subscriber’s subscription to the W&B Platform, W&B will provide technical support services to Subscriber with respect to the W&B Platform, consisting of web-based access to W&B technical support help desk for W&B Platform technical questions and reporting of technical issues related to the W&B Platform. 

5. **Subscription Fees; Payment**.  For the subscription to the W&B Platform, Subscriber will pay W&B a subscription fee for each Subscription Period (the “**Subscription Fees**”).  The Subscription Fee for the initial Subscription Period is set forth on the Registration Page.  W&B may adjust the Subscription Fee for any renewal of the Subscription Period by providing Subscriber with notice (which may be provided via email) of the adjustment at least sixty (60) days before the start of the renewal Subscription Period.  In addition, the Subscription Fees will be adjusted if Subscriber makes changes to its subscription (for example, by subscribing to additional features that may be offered by W&B from time to time).  Subscription Fees for the initial Subscription Period and each renewal will be charged to the credit card provided by Subscriber at the beginning of the Subscription Period, and Subscriber hereby authorizes such charges.  Subscriber is responsible for maintaining accurate and up-to-date credit card information on file with W&B.  If Subscriber’s credit card is rejected, W&B may suspend Subscriber’s access to the W&B Software and terminate this Agreement for material breach pursuant to Section 6 below unless Subscriber provides valid credit card information within the ten (10) business day cure period set forth in Section 6.  All Subscription Fees are non-refundable.

6. **Term and Termination**.  The term of this Agreement begins when Subscriber agrees to this Agreement and will continue in effect for successive, consecutive Subscription Periods, unless and until terminated in accordance with this Section 6 (the “**Term**”).  Subscriber may terminate this Agreement, effective as of the end of the then-current Subscription Period, by sending W&B email notice of such cancellation to support@wandb.com or through the cancellation mechanism provided in the account management dashboard within the W&B Platform (in each case, before the end of the then-current Subscription Period).  W&B may terminate this Agreement, effective as of the end of the then-current Subscription Period, by sending email notice of such cancellation to the email address that W&B has on file for Subscriber, before the end of the then-current Subscription Period.   In addition, in the event of a material breach of this Agreement by a Party, the other Party may terminate this Agreement by providing ten (10) business days prior, written notice of termination to the breaching Party (which notice may be provided via email); provided, however, that this Agreement will not terminate if the breaching Party has cured the breach before the end of such ten (10) business day period.  If Subscriber’s credit card is rejected, W&B may terminate Subscriber’s access to the W&B Software and this Agreement 

7. **Survival**. The following provisions will survive any expiration or termination of this Agreement:  this Section 7 (“Survival”), Section 8 (“Ownership; Restrictions on Use; Feedback; Subscriber Data”), Section 9 (“Confidentiality”), Section 10 (“Representations and Warranties; Disclaimer”); Section 11 (“Liability”), Section 12 (“Indemnification”) and Section 13 (“General Provisions”). 

8. **Ownership; Restrictions on Use; Feedback; Subscriber Data.**

    1. **Ownership of W&B Platform**.  W&B retains all rights, title and interest in and to the W&B Platform and all related code, algorithms and other components, together with all intellectual property rights with respect thereto.  Subscriber will have no rights with respect to any of the foregoing, other than the limited right to access and use the W&B Platform as set forth in Section 1. 

    2. **Restrictions on Use**.  Except as otherwise explicitly provided in this Agreement, Subscriber will not, and will not permit or authorize any third party to: (i) copy, prepare derivative works of, decompile or reverse engineer any software, algorithms or other technology associated with the W&B Platform; (ii) sublicense, rent, loan, lease, or otherwise permit a third party to access or use the W&B Platform; or (iii) use the W&B Platform for any unlawful or fraudulent purposes.  Subscriber will limit access to the W&B Platform to individuals who are employees or contractors of Subscriber (“**Users**”).  Subscriber will be responsible for ensuring compliance with this Agreement by each User and for the use or misuse of the W&B Platform by the Users. 

    3. **Feedback**.  Subscriber grants to W&B a worldwide, perpetual, irrevocable, royalty-free, transferable, sublicensable license to use and incorporate into W&B’s products and services any suggestion, enhancement request, recommendation, correction or other feedback provided by Subscriber relating to the operation of the W&B Platform or any other product or service of W&B.

    4. **Subscriber Data**.  Subscriber shall retain ownership of all rights to any data that Subscriber uploads to the W&B Platform (the “**Subscriber Data**”).  

    5. **Usage Data**.  W&B may collect data with respect to Subscriber’s usage of the W&B Platform, and may analyze and use such data, in a form that is aggregated with other data and does not identify Subscriber, for the purposes of improving W&B’s products and services. 

9. **Confidentiality.**

    1. “**Confidential Information**” means any business, financial, technical or other information of a Party (the “**Disclosing Party**”) that is provided to the other Party (the “**Recipient**”) and which when provided is designated by the Disclosing Party as “confidential” or “proprietary” or which, under the circumstances, would reasonably be considered to be confidential.  For the avoidance of doubt:  (a) Subscriber’s Confidential Information includes, without limitation, the Subscriber Data and (b) W&B’s Confidential Information includes, without limitation, all non-public information relating to the W&B Platform.  Notwithstanding any of the foregoing, “**Confidential Information**” will not include information that: (a) is or becomes generally available to the public through no fault or action of the Recipient; (b) the Recipient lawfully receives from a third party without restriction on disclosure and without breach of a nondisclosure obligation; or (c) the Recipient knew prior to receiving such information from the Disclosing Party or develops independently of this Agreement.  Notwithstanding anything to the contrary, any Feedback will be deemed not to be included in the definition of Subscriber’s Confidential Information.

    2. Recipient agrees that, unless otherwise approved by the Disclosing Party in writing: (a) it will not disclose any of the Disclosing Party’s Confidential Information to any third party, other than to its affiliates and its and their respective contractors who are bound by obligations of confidentiality and have a need to know such Confidential Information in connection with Recipient’s performance under this Agreement; (b) it will not use any such Confidential Information, except in connection with Recipient’s performance under this Agreement; and (c) it will protect and maintain the confidentiality of such Confidential Information using at least the same level of care it uses to protect its own Confidential Information of similar nature (but in no event less than reasonable care).  

10. **Representations and Warranties; Disclaimer.**

    1. **Authority**.  Each Party represents, warrants and covenants that it has the full right, power and authority to enter into this Agreement and to discharge its obligations hereunder.  

    2. **W&B Platform**.  W&B represents, warrants and covenants that the W&B Platform does not and will not violate or infringe upon:  (i) any copyright, trademark, trade secret or other intellectual property or proprietary rights (other than patent rights) of any third party; or (ii) to W&B’s knowledge, any patent rights of any third party.  

    3. **Disclaimer**.  EXCEPT FOR THE REPRESENTATIONS AND WARRANTIES SET FORTH ABOVE IN THIS SECTION 10, THE W&B PLATFORM AND ANY OTHER SERVICES AND ITEMS PROVIDED BY W&B IN CONNECTION WITH THIS AGREEMENT ARE PROVIDED “AS IS” WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, AND W&B HEREBY DISCLAIMS ALL OTHER REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ANY REPRESENTATIONS OR WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE.  W&B MAKES NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE AVAILABILITY OF THE W&B PLATFORM OR THAT THE W&B PLATFORM WILL BE ERROR FREE.

11. **Liability.**  

    1. **Liability Exclusion**.  NEITHER PARTY WILL BE LIABLE TO THE OTHER PARTY (NOR TO ANY PERSON CLAIMING RIGHTS DERIVED FROM SUCH OTHER PARTY’S RIGHTS) FOR CONSEQUENTIAL, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR EXEMPLARY DAMAGES OF ANY KIND, OR FOR ANY LOST REVENUES OR PROFITS, LOSS OF USE, LOSS OF COST OR OTHER SAVINGS, LOSS OF DATA, OR LOSS OF GOODWILL OR REPUTATION, WITH RESPECT TO ANY CLAIMS BASED ON CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE ARISING OUT OF OR RELATING TO THE W&B PLATFORM OR ANY OTHER SERVICES OR ITEMS PROVIDED BY W&B HEREUNDER OR OTHERWISE ARISING OUT OF OR RELATING TO THIS AGREEMENT, REGARDLESS OF WHETHER THE PARTY LIABLE OR ALLEGEDLY LIABLE WAS ADVISED, HAD OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY THEREOF.

    2. **Limitation of Damages**.  EACH PARTY’S MAXIMUM, CUMULATIVE LIABILITY ARISING OUT OF OR RELATING TO THE W&B PLATFORM, ANY OTHER SERVICES OR ITEMS PROVIDED BY W&B HEREUNDER OR OTHERWISE ARISING OUT OF OR RELATING TO THIS AGREEMENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE), WILL NOT EXCEED, IN THE AGGREGATE FOR ALL CLAIMS, THE TOTAL SUBSCRIPTION FEES PAID BY SUBSCRIBER TO W&B UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE ON WHICH THE FIRST CLAIM AROSE. 

    3. **Exceptions**.  Notwithstanding anything to the contrary, the exclusions and limitations set forth in _Section 11.1_ and _Section 11.2_ will not apply with respect to: (i) any damages arising from a Party’s fraud or willful misconduct, or from infringement or misappropriation of a Party’s intellectual property rights, (ii) the Parties’ respective indemnity obligations under _Section 12_ with respect to Third Party Claims (as defined below) or (iii) Subscriber’s payment obligations under this Agreement.

12. **Indemnification.**  

    1. **W&B’s Indemnity Obligation**.  W&B will defend, indemnify and hold harmless Subscriber and its affiliates, and each of their respective officers, directors, employees, contractors and agents (collectively, “**Subscriber Indemnitees**”) from and against any and all claims, actions, lawsuits and investigations brought by a third party (“**Third Party Claims**”) and will pay any settlements, awards, fines and reasonable attorney’s fees and expenses and court costs (collectively, “**Losses**”), in each case to the extent arising from or relating to any allegation that W&B’s provision of the W&B Platform (excluding, for the avoidance of doubt, any Subscriber Data) infringes, misappropriates or violates any third party copyrights, trademarks, patents or other intellectual property rights.

    2. **Subscriber’s Indemnity Obligation**.  Subscriber will defend, indemnify, and hold harmless, W&B and its affiliates, and each of its and their respective officers, directors, employees, partners, contractors and agents (collectively, “**W&B Indemnitees**”) from and against any and all Third Party Claims and will pay any Losses associated with such Third Party Claims, in each case to the extent arising from or relating to any allegation that any Subscriber Data infringes or violates any third party rights, or otherwise arising from Subscriber’s use of the W&B Platform (except to the extent covered by W&B’s indemnity obligations under _Section 12.1_).

    3. **Indemnity Procedures**.  A Party seeking indemnification for a Third Party Claim under this Agreement will give the other Party (the “**Indemnitor**”) written notice of the Third Party Claim promptly (and in any event within fifteen (15) calendar days after the service of the citation or summons); provided, however, that the failure to give timely notice hereunder will not affect rights to indemnification hereunder, except to the extent that Indemnitor demonstrates actual damage caused by such failure.  Indemnitor may elect to direct the defense or settlement of any such Third Party Claim by giving written notice to the Party seeking indemnity, which election will be effective immediately upon receipt by the Party seeking indemnity of such written notice of election.  The Indemnitor will have the right to employ counsel reasonably acceptable to the Party seeking indemnity to defend any such Third Party Claim, or to compromise, settle or otherwise dispose of the same, if the Indemnitor deems it advisable to do so, all at the expense of the Indemnitor; provided, however, that the Indemnitor will not settle, or consent to any entry of judgment in, any such Third Party Claim without obtaining either:  (i) an unconditional release of the Party seeking indemnity (and all other Subscriber Indemnitees or W&B Indemnitees, as applicable) from all liability with respect to all claims underlying such Third Party Claim or (ii) the prior, written consent of the Party seeking indemnity.  The Parties will fully cooperate with each other in any such Third Party Claim and will make available to each other any books or records useful for the defense of any such Third Party Claim. 

13. **General Provisions.**

    1. **Relationship of the Parties**.  Each Party is an independent contractor of the other Party.  Nothing herein will constitute a partnership between or joint venture by the Parties, or constitute either Party the agent of the other. 

    2. **Assignment**.  Neither Party may assign or otherwise transfer this Agreement without the prior, written consent of the other Party; provided, however, that, notwithstanding the foregoing, a Party may, without the consent of the other Party, assign or otherwise transfer this Agreement (including all of such Party’s rights and obligations hereunder):  (i) to any of its affiliates or (ii) in connection with a merger, consolidation, sale of equity interests, sale of all or substantially all assets or other change of control transaction.  Any assignment or other transfer in violation of this Section will be null and void.  Subject to the foregoing, this Agreement will be binding upon and inure to the benefit of the Parties hereto and their permitted successors and assigns.

    3. **Force Majeure**.  Except with respect to failure to pay any amount due under this Agreement, nonperformance of either Party will be excused to the extent that performance is rendered impossible by strike, fire, flood, governmental acts, orders or restrictions, failure of suppliers, or any other reason where failure to perform is beyond the control and not caused by the negligence of the non-performing Party. 

    4. **Choice of Law**. This Agreement, and any disputes directly or indirectly arising from or relating to this Agreement, will be governed by and construed in accordance with the laws of the State of California, without regard to principles of conflicts of law. 

    5. **Exclusive Forum**.  The Parties hereby consent and agree to the exclusive jurisdiction of the state and federal courts located in San Francisco, California for all suits, actions or proceedings directly or indirectly arising out of or relating to this Agreement, and waive any and all objections to such courts, including but not limited to objections based on improper venue or inconvenient forum, and each Party hereby irrevocably submits to the jurisdiction of such courts in any suits, actions or proceedings arising out of or relating to this Agreement. 

    6. **Modification**.  No modification of or amendment to this Agreement will be effective unless in writing and agreed upon (including via a clickthrough agreement) by both Parties; provided, however, that notwithstanding the foregoing, W&B may modify or amend this Agreement by sending notice of such amendment to the email address that Subscriber has on file with W&B, provided that such amendment will not become effective until the start of the next Subscription Period. 

    7. **No Waiver**.  No waiver of any rights is to be charged against any Party unless such waiver is in writing signed by an authorized representative of the party so charged. 

    8. **Severability**.  If any provision of this Agreement is held invalid or unenforceable by any court of competent jurisdiction, the other provisions of this Agreement will remain in full force and effect, and, if legally permitted, such offending provision will be replaced with an enforceable provision that as nearly as possible effects the Parties’ intent.  

    9. **Entire Agreement**. This Agreement contains the entire understanding of the Parties with respect to the subject matter hereof and supersedes all prior agreements and commitments with respect thereto.  There are no other oral or written understandings, terms or conditions and neither Party has relied upon any representation, express or implied, not contained in this Agreement.

`;
