import * as Normalize from '../normalize';
import * as Types from './types';

export const normalize = Normalize.normFn<Types.SortObjSchema>(
  'sort',
  whole => whole
);

export const denormalize = Normalize.denormFn<Types.SortObjSchema>(
  part => part
);
