import * as S from './QueryEditor.styles';

import React, {useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import QueryFieldsEditor from './QueryFieldsEditor';
import {QueryField, QueryTemplateArg} from '../../util/vega3';
import * as ApiSchemaTypes from '../../generated/apiSchema';
import makeComp from '../../util/profiler';

export function schemaTypeToColoredString(
  schemaType: ApiSchemaTypes.__Type
): React.ReactNode {
  if (schemaType.kind === 'NON_NULL') {
    return (
      <>
        {schemaTypeToColoredString(schemaType.ofType!)}
        {'!'}
      </>
    );
  }
  if (schemaType.kind === 'LIST') {
    return (
      <>
        {'['}
        {schemaTypeToColoredString(schemaType.ofType!)}
        {']'}
      </>
    );
  }
  return <S.TypeSpan>{schemaType.name}</S.TypeSpan>;
}

export interface QueryEditorProps {
  className?: string;
  queryFields: QueryField[];
  fixedFields?: QueryField[];
  templateArgs?: QueryTemplateArg[];
  setQueryFields(fields: QueryField[]): void;
}

export const QueryEditor: React.FC<QueryEditorProps> = makeComp(
  props => {
    const [currentTheme, setCurrentTheme] = React.useState(S.themes.carey);
    useEffect(() => {
      function handleKeyDown(e: KeyboardEvent) {
        if (e.keyCode === 75 && e.metaKey) {
          setCurrentTheme(theme => {
            if (theme === S.themes.carey) {
              return S.themes.dark;
            } else {
              return S.themes.carey;
            }
          });
        }
      }
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
    return (
      <ThemeProvider theme={currentTheme}>
        <S.Wrapper className={props.className}>
          <S.InnerWrapper>
            <S.QuerySpan>query</S.QuerySpan> &#123;
            <QueryFieldsEditor
              typeName={'Query'}
              fields={props.queryFields}
              fixedFields={props.fixedFields}
              templateArgs={props.templateArgs ?? []}
              indentLevel={0}
              setFields={fields => props.setQueryFields(fields)}
            />
            &#125;
          </S.InnerWrapper>
        </S.Wrapper>
      </ThemeProvider>
    );
  },
  {id: 'QueryEditor'}
);

export default QueryEditor;
