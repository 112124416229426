import _ from 'lodash';
import React, {FC, useCallback, useRef, useState} from 'react';
import {graphql} from 'react-apollo';
import {Button, Form, Header, Icon, Input, Radio} from 'semantic-ui-react';

import {isString} from 'util';
import {EasyTable} from '../../components/EasyTable';
import {BenchmarkRun, Run} from '../../generated/graphql';
import {
  BENCHMARK_SUBMIT_DECISION,
  SubmitDecisionMutationFn,
  Verdict,
} from '../../graphql/benchmarks';
import {BenchmarkProject} from '../../types/graphql';
import * as RunUtil from '../../util/runs';
import {getTheme} from './Theme';
import makeComp from '../../util/profiler';
import {TargetBlank} from '../../util/links';

interface BenchmarkApprovalProps {
  summaryMetrics: any;
  benchmark: BenchmarkProject;
  benchmarkRun: BenchmarkRun;
  updateDecision: SubmitDecisionMutationFn;
}

export const BenchmarkApprovalBody: FC<BenchmarkApprovalProps> = makeComp(
  ({summaryMetrics, benchmark, benchmarkRun, updateDecision}) => {
    const [value, setValue] = useState<string | undefined>();
    const [reason, setReason] = useState<string | undefined>();
    const reasonInput = useRef<Input | null>(null);
    const handleChange = useCallback((e: any, data: any) => {
      setValue(data.value);
      setReason(data.reason);
    }, []);

    // This needs an any for now because we would need to use.
    // Circular imports to represent our benchmark data structure.
    const benchmarkTheme = getTheme(benchmarkRun.benchmark as any);

    // If we are scoring on the backend use the result column from the benchmark run
    const scores =
      benchmarkTheme.calculatedResults && benchmarkTheme.calculatedResultsHeader
        ? [
            {
              header: benchmarkTheme.calculatedResultsHeader,
              value: benchmarkRun.results || 'Error',
            },
          ]
        : // Otherwise load in from the provided summary keys
          _.map(benchmarkTheme.keys, k => {
            const val = _.get(summaryMetrics, k);
            return {
              header: isString(k) ? k : k.toString(),
              value: typeof val === 'number' ? RunUtil.displayValue(val) : '-',
            };
          });

    return (
      <div className="benchmark-approval-wrapper">
        <div className={'benchmark-approval__' + benchmarkRun.state}>
          <Header as="h4">
            {(benchmarkRun.state === 'ACCEPTED'
              ? 'Accepted to'
              : benchmarkRun.state === 'REJECTED'
              ? 'Rejected from'
              : benchmark.readOnly
              ? 'Awaiting review from'
              : 'Review submission to') +
              ' ' +
              benchmarkRun.benchmark.name +
              ' '}
            benchmark
            {benchmarkRun.state !== 'SUBMITTED' && !benchmark.readOnly && (
              <Button
                size="tiny"
                onClick={() => {
                  updateDecision(benchmarkRun.id, 'SUBMITTED', '');
                }}>
                <Icon name="undo" />
                Undo decision
              </Button>
            )}
          </Header>
          {benchmarkRun.state === 'REJECTED' &&
            benchmarkRun.details &&
            benchmarkRun.details.reason && (
              <p>Reason: {benchmarkRun.details.reason}</p>
            )}
          <EasyTable
            cols={[
              {
                header: 'GitHubRepo',
                bodyFn: (run: Run) =>
                  run.github ? (
                    <TargetBlank href={run.github}>GitHub Link</TargetBlank>
                  ) : (
                    <span>-</span>
                  ),
              },
              {header: 'Author', key: 'user.username'},
              ...scores,
            ]}
            rows={[benchmarkRun.run]}
          />
          {benchmarkRun.state === 'SUBMITTED' && !benchmark.readOnly && (
            <Form className="form">
              <Form.Field>
                <Radio
                  label="Accept Submission"
                  name="ACCEPTED"
                  value="ACCEPTED"
                  checked={value === 'ACCEPTED'}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label="Reject Submission"
                    name="REJECTED"
                    value="REJECTED"
                    checked={value === 'REJECTED'}
                    onClick={() =>
                      // Timeout is necessary because input when this toggles.
                      setTimeout(() => reasonInput.current!.focus())
                    }
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    ref={reasonInput}
                    onChange={(e, d) => setReason(d.value)}
                    value={reason}
                    className="simple"
                    style={{minWidth: 400}}
                    disabled={value !== 'REJECTED'}
                    placeholder="Describe why you're rejecting this submission"
                  />
                </Form.Field>{' '}
              </Form.Group>
              <Form.Field>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    const verdict = value as Verdict;
                    // This button is disabled without a value so we can cast away the undefined
                    updateDecision(benchmarkRun.id, verdict, reason ?? '');
                  }}
                  disabled={!value}
                  primary>
                  Submit Decision
                </Button>
              </Form.Field>
            </Form>
          )}
        </div>
      </div>
    );
  },
  {id: 'BenchmarkApprovalBody', memo: true}
);

const withMutations = graphql(BENCHMARK_SUBMIT_DECISION, {
  props: ({mutate}) => ({
    updateDecision: (id: string, state: Verdict, reason: string) => {
      if (mutate) {
        const detailsObj = {reason};
        const details = JSON.stringify(detailsObj);
        return mutate({
          variables: {id, state, details},
        });
      }

      return new Promise(resolve => resolve());
    },
  }),
}) as any;

export const BenchmarkApproval = withMutations(BenchmarkApprovalBody);
