import * as _ from 'lodash';
import * as React from 'react';
import {Header, Icon, Segment} from 'semantic-ui-react';

import {isString} from 'util';
import Markdown from '../../components/Markdown';
import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

const RANK_METRIC = ['details', 'score'];

const DISPLAY_KEYS = [RANK_METRIC];

const scoreCols = _.map(DISPLAY_KEYS, k => ({
  Header:
    k === RANK_METRIC ? (
      <span style={{fontWeight: 'bold'}}>
        {k}
        <Icon name="caret down" />
      </span>
    ) : (
      k
    ),
  id: isString(k) ? k : k.join(),
  accessor: (run: WithSummary<BenchmarkRun>) => {
    return Run.displayValue(_.get(run, k));
  },
}));

const leaderboardCols = [...Leaderboard.cols, ...scoreCols];

const CONCEPT = `
# Code Search Benchmark

To participate follow the instructions at: [https://github.com/wandb/codesearch](https://github.com/wandb/codesearch)



`;

const SUBMISSION_INSTRUCTIONS = `
Test submission text
`;

const overview = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <Header as="h3">Top submissions</Header>
      <WBReactTable
        pageSize={20}
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, RANK_METRIC, false)}
      />
      <Header as="h3">Concept</Header>
      <Segment>
        <Markdown condensed={false} content={CONCEPT} />
      </Segment>
    </React.Fragment>
  );
};

const theme: Theme = {
  keys: DISPLAY_KEYS,
  benchmarkLink: 'https://github.com/wandb/fake',
  overview,
  submissionGuidelines: SUBMISSION_INSTRUCTIONS,
};

export default theme;
