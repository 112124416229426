import React, {useEffect} from 'react';
import {Loader} from 'semantic-ui-react';

import * as Ops from '@wandb/cg/browser/ops';

import {
  OrganizationPageQuery,
  useOrganizationPageQuery,
} from '../generated/graphql';
import makeComp from '../util/profiler';
import {setDocumentTitle} from '../util/document';
import {useAdminModeActive} from '../util/admin';
import ActivityDashboard from '../components/ActivityDashboard';

import * as S from './OrganizationPage.styles';

interface OrganizationPageProps {
  match: {
    params: {
      orgName: string;
    };
  };
}

const OrganizationPage: React.FC<OrganizationPageProps> = makeComp(
  props => {
    const isAdmin = useAdminModeActive();

    const {loading, data} = useOrganizationPageQuery({
      variables: {
        name: props.match.params.orgName,
      },
    });

    const orgName = data?.organization?.name ?? '';

    useEffect(() => {
      setDocumentTitle(orgName);
    });

    const numTeams = data?.organization?.subscriptions.find(
      sub => typeof sub.privileges.num_private_teams === 'number'
    )?.privileges.num_private_teams;

    const numMembers = data?.organization?.members.length;

    const hasOrgDashPrivilege = (orgData: OrganizationPageQuery) => {
      return orgData?.organization?.subscriptions?.some(
        sub => sub.privileges?.org_dash_enabled === true
      );
    };

    const org = Ops.opRootOrg({orgName: Ops.constString(orgName)});

    if (loading) {
      return <Loader active size="large" />;
    }

    // Done loading but nothing to show
    if (
      data?.organization == null ||
      (!isAdmin && !hasOrgDashPrivilege(data))
    ) {
      return (
        <S.ErrorWrapper>
          <S.BigSad>
            <span role="img" aria-label="warning symbol">
              ⚠
            </span>
          </S.BigSad>
          <S.ErrorMessage>Organization not found.</S.ErrorMessage>
        </S.ErrorWrapper>
      );
    }

    return (
      <ActivityDashboard
        heading={orgName}
        subtitle={`${
          numTeams === 1 ? '1 team' : `${numTeams} teams`
        }, ${numMembers} users`}
        extraHeading={
          isAdmin &&
          !hasOrgDashPrivilege(data) && (
            <S.CriticalHint>
              <strong>**ADMIN PREVIEW**</strong> This dashboard is disabled for
              this organization and is not visible to its members.
            </S.CriticalHint>
          )
        }
        userFilterOpts={
          data?.organization?.members?.map(m => ({
            name: m.name,
            username: m.username,
          })) || []
        }
        projects={Ops.opOrgProjects({org})}
        reports={Ops.opOrgReports({org})}
        artifacts={Ops.opOrgArtifacts({org})}
        dateWindowSize={{month: 3}}
      />
    );
  },
  {id: 'OrganizationPage'}
);

export default OrganizationPage;
