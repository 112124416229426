import {WBMenuOption, WBPopupMenuTrigger, WBSelect} from '@wandb/ui';
import classNames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import * as _ from 'lodash';
import React from 'react';
import {useMutation, useQuery} from 'react-apollo';
import ReactDOM from 'react-dom';
import {VisualizationSpec} from 'react-vega';
import {Checkbox, Message} from 'semantic-ui-react';
import {View as VegaView} from 'vega';
import * as Generated from '../generated/graphql';
import {useProjectPageQuery, useUserProjectsQuery} from '../generated/graphql';
import {CustomChartQueryData} from '../graphql/customCharts';
import {usePushReport} from '../state/reports/hooks';
import {useViewer} from '../state/viewer/hooks';
import {useWholeArray} from '../state/views/hooks';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {propagateErrorsContext} from '../util/errors';
import {ID} from '@wandb/cg/browser/utils/string';
import {getFullWidthPanelLayout} from '../util/panelbankGrid';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
} from '../util/panelbank';
import {PanelWithConfig} from '../util/panels';
import {fromSection} from '../util/report';
import {useResizer} from '../util/resize';
import {emptyReportRunSetSelectAll, RunColorConfig} from '../util/section';
import * as VegaLib2 from '../util/vega2';
import * as VegaLib3 from '../util/vega3';
import {getProjectsFromUserProjectsQuery} from './CreateReportModal';
import * as S from './CustomPanelEditor.styles';
import DataSignalViewer from './DataSignalViewer';
import PanelError from './elements/PanelError';
import SliderInput from './elements/SliderInput';
import {toast} from './elements/Toast';
import MonacoEditor from './Monaco/Editor';
import {VegaPanel2Config} from './PanelVega2';
import {
  CustomChartAccessIndicator,
  EditCustomChartAccessTrigger,
} from './Vega2/CustomChartAccess';
import {VegaPanelLoadTrigger} from './Vega2/VegaPanelLoad';
import {VegaPanelRenameTrigger} from './Vega2/VegaPanelRename';
import {VegaPanelSaveAsTrigger} from './Vega2/VegaPanelSaveAs';
import CustomPanelRenderer from './Vega3/CustomPanelRenderer';
import WandbLoader from './WandbLoader';

function getPanelDefID(config: VegaPanel2Config, defaultEntityName: string) {
  if (config.panelDefId && config.panelDefId.startsWith('lib:')) {
    // backwards compatibility
    return (
      defaultEntityName +
      '/' +
      atob(config.panelDefId.split(':')[1]).split(':')[1]
    );
  }
  return config.panelDefId || 'wandb/line/v0';
}
export interface CustomPanelEditorProps {
  className?: string;
  templateVals: {
    [template: string]: any;
  };
  templateArgs: VegaLib3.QueryTemplateArg[];
  entityName: string;
  projectName: string;
  panelConfig: VegaPanel2Config;
  customRunColors?: RunColorConfig;
  runSetRefs: RunSetViewTypes.Ref[];
  viewableRunOptions?: string[];
  viewedRun?: string;

  isHistoryTableQuery?: boolean;
  viewableStepOptions?: number[];
  viewedStep?: number;
  userQuery?: VegaLib3.Query;
  setViewedRun(value: string): void;
  setUserQuery(userQuery: VegaLib3.Query): void;
  updatePanelConfig(config: Partial<VegaPanel2Config>): void;
  onClose?(): void;
}

const CustomPanelEditor: React.FC<CustomPanelEditorProps> = ({
  className,
  entityName,
  projectName,
  panelConfig,
  templateVals,
  templateArgs,
  customRunColors,
  runSetRefs,
  viewableRunOptions,
  viewedRun,
  isHistoryTableQuery,
  viewableStepOptions,
  viewedStep,
  userQuery,
  setViewedRun,
  setUserQuery,
  updatePanelConfig,
  onClose,
}) => {
  const [currentEditorTab, setCurrentEditorTab] = React.useState<
    'chart' | 'data' | 'other settings'
  >('chart');
  const [vegaView, setVegaView] = React.useState<VegaView | null>(null);
  const [previewKey, setPreviewKey] = React.useState(0);
  const [manualRenderedParsedSpec, setManualRenderedParsedSpec] =
    React.useState<VisualizationSpec | null>(null);
  const [runMode, setRunMode] = React.useState('auto');

  const {
    onMouseDown: widthResizerOnMouseDown,
    cursor: previewWidthCursor,
    dropSize: previewWidth,
    resizing: previewWidthResizing,
  } = useResizer('left', 480, {min: 160});
  const {
    onMouseDown: heightResizerOnMouseDown,
    cursor: previewHeightCursor,
    dropSize: previewHeight,
    resizing: previewHeightResizing,
  } = useResizer('down', 320, {min: 160});

  const activeChartId = getPanelDefID(panelConfig, entityName);
  const skipActiveCustomChartQuery =
    activeChartId.startsWith('builtin') || !activeChartId.includes('/');
  const activeCustomChartQuery = useQuery<
    CustomChartQueryData,
    Generated.CustomChartQueryVariables
  >(Generated.CustomChartDocument, {
    variables: {id: activeChartId},
    skip: skipActiveCustomChartQuery,
    context: propagateErrorsContext(),
  });
  const [updateCustomChart] = useMutation<
    Generated.UpdateCustomChartMutationResult,
    Generated.UpdateCustomChartMutationVariables
  >(Generated.UpdateCustomChartDocument);
  const modifiedUserQuery = React.useMemo(
    () => VegaLib3.injectAutoFields(userQuery ?? VegaLib3.defaultRunSetsQuery),
    [userQuery]
  );
  const {loading, result, cols, slow} = VegaLib3.useVegaQuery(
    modifiedUserQuery,
    panelConfig.transform || VegaLib3.TRANSFORM_WHEN_MISSING,
    templateVals
  );
  const resultWithColors = React.useMemo(
    () => VegaLib3.computeRunColors(result, customRunColors),
    [customRunColors, result]
  );
  const filteredCols = cols.filter(c => !VegaLib3.fieldIsHidden(c));

  const defaultRun = VegaLib3.getDefaultViewedRun(
    viewedRun,
    viewableRunOptions
  );

  const activeChart = activeCustomChartQuery.data?.customChart;

  const activeSpec = panelConfig.customPanelDef?.spec ?? activeChart?.spec;
  const modified =
    panelConfig.customPanelDef != null &&
    activeChart != null &&
    panelConfig.customPanelDef.spec !== activeChart.spec;

  const {parsedSpec, err} = React.useMemo(
    () =>
      activeSpec == null
        ? {parsedSpec: {}, err: null}
        : VegaLib2.parseSpecJSON(activeSpec),
    [activeSpec]
  );

  const viewer = useViewer();
  const pushDraftReport = usePushReport(
    viewer?.username ?? entityName,
    'uncategorized'
  );
  const userProjectsQuery = useUserProjectsQuery({
    variables: {userName: viewer?.username ?? '', includeReports: true},
    skip: viewer == null,
  });

  const uncategorizedExists: boolean = getProjectsFromUserProjectsQuery(
    userProjectsQuery
  ).some(row => row.name === 'uncategorized');

  const runSets = useWholeArray(runSetRefs);

  const projectQuery = useProjectPageQuery({
    variables: {
      entityName,
      projectName,
    },
  });

  let projectWriteAccess: boolean = false;
  if (!projectQuery.loading && projectQuery.data?.project?.readOnly != null) {
    projectWriteAccess = !projectQuery.data.project.readOnly;
  }

  React.useEffect(() => {
    if (
      runMode === 'manual' &&
      parsedSpec != null &&
      !activeCustomChartQuery.loading &&
      manualRenderedParsedSpec == null
    ) {
      setManualRenderedParsedSpec(parsedSpec);
    }
  }, [
    parsedSpec,
    manualRenderedParsedSpec,
    runMode,
    activeCustomChartQuery.loading,
  ]);

  const [createNewProject] = Generated.useUpsertModelMutation();

  const onSliderChange = React.useCallback(
    (value: number) => {
      const newUserQuery = VegaLib3.updateQueryIndex(
        panelConfig.userQuery ?? VegaLib3.defaultRunSetsQuery,
        viewableStepOptions ? viewableStepOptions[value] : 0
      );
      updatePanelConfig({
        ...panelConfig,
        userQuery: newUserQuery,
        defaultViewedStepIndex: value,
      });
    },
    [panelConfig, updatePanelConfig, viewableStepOptions]
  );

  const updateSpec = React.useCallback(
    (newSpec: string) => {
      updatePanelConfig({
        ...panelConfig,
        customPanelDef: {
          ...panelConfig.customPanelDef,
          spec: newSpec,
          displayName: '',
          description: '',
        },
      });
    },
    [panelConfig, updatePanelConfig]
  );
  const debouncedUpdateSpec = React.useMemo(
    () => _.debounce(updateSpec, 500),
    [updateSpec]
  );
  React.useLayoutEffect(
    () => () => debouncedUpdateSpec.flush(),
    [debouncedUpdateSpec]
  );

  function wrap(content: React.ReactNode) {
    return ReactDOM.createPortal(
      <S.Wrapper className={classNames(className, 'custom-panel-editor')}>
        {content}
      </S.Wrapper>,
      document.body
    );
  }

  if (activeCustomChartQuery.loading) {
    return wrap(<WandbLoader></WandbLoader>);
  }

  if (!skipActiveCustomChartQuery && activeChart == null) {
    return wrap(
      <S.CustomChartError>
        Oops! This chart does not exist or you don't have permission to view it.
        <S.CloseButton onClick={onClose}>Close editor</S.CloseButton>
      </S.CustomChartError>
    );
  }
  if (activeChartId.startsWith('builtin')) {
    return wrap(
      <S.CustomChartError>
        Outmoded chart type <strong>{activeChartId}</strong>. Please switch to a
        newer equivalent. Sorry for the inconvenience!.
        <S.CloseButton onClick={onClose}>Close editor</S.CloseButton>
      </S.CustomChartError>
    );
  }
  if (activeSpec == null) {
    return wrap(
      <S.CustomChartError>
        Unknown error. Sorry for the inconvenience.
        <S.CloseButton onClick={onClose}>Close editor</S.CloseButton>
      </S.CustomChartError>
    );
  }

  const userFieldRefs =
    (parsedSpec && VegaLib3.parseSpecFields(parsedSpec)) || [];
  let userFieldOptions: WBMenuOption[] = [{value: '', name: '-'}];
  userFieldOptions = userFieldOptions.concat(
    filteredCols.map(c => {
      return {value: c};
    })
  );

  const readOnly = activeChart != null && activeChart.entity.readOnly;

  const modifiedViewOnlySpec = modified && readOnly && viewer;

  const clonePanelToNewReport = async () => {
    if (panelConfig.customPanelDef == null || activeChart == null) {
      return;
    }
    // if uncategorized project does not exist for user, insert it
    if (!uncategorizedExists) {
      await createNewProject({
        variables: {
          entityName: viewer?.username,
          description: '',
          name: 'uncategorized',
          access: 'ENTITY_WRITE',
        },
      });
    }

    const panel: PanelWithConfig<'Vega2'> = {
      __id__: ID(9),
      viewType: 'Vega2',
      config: {
        ...panelConfig,
        panelDefId: 'custom',
        customPanelDef: {
          ...panelConfig.customPanelDef,
          name: 'custom',
          displayName: 'custom',
        },
      },
    };
    const runSetsWithEmptyDefault =
      runSets.length > 0 ? runSets : [emptyReportRunSetSelectAll()];
    const runSetsWithProject = runSetsWithEmptyDefault.map(rs => ({
      ...rs,
      project: rs.project ?? {
        entityName,
        name: projectName,
      },
    }));
    const report = fromSection({
      runSets: runSetsWithProject,
      panelBankConfig: _.cloneDeep(EMPTY_PANEL_BANK_CONFIG),
      panelBankSectionConfig: {
        ..._.cloneDeep(EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT),
        panels: [{...panel, layout: getFullWidthPanelLayout()}],
      },
    });
    pushDraftReport(report);
  };

  const otherSettingsTabContent = (
    <div>
      <div>
        <Checkbox
          toggle
          label="Show run selector"
          checked={panelConfig.showRunSelector}
          onClick={() => {
            updatePanelConfig({
              ...panelConfig,
              showRunSelector: !panelConfig.showRunSelector,
              defaultViewedRun: defaultRun,
            });
            setViewedRun(defaultRun);
          }}
        />
        {panelConfig.showRunSelector && (
          <WBSelect
            value={panelConfig.defaultViewedRun ?? VegaLib3.VIEW_ALL_RUNS}
            options={
              viewableRunOptions?.map(row => {
                return {name: row, value: row};
              }) ?? []
            }
            onSelect={value => {
              if (typeof value === 'string') {
                updatePanelConfig({
                  ...panelConfig,
                  defaultViewedRun: value,
                });
                setViewedRun(value);
              }
            }}
            typeable={false}
            disabled={!panelConfig.showRunSelector}
          />
        )}
      </div>
      <div>
        {isHistoryTableQuery && (
          <Checkbox
            toggle
            label="Show step selector"
            checked={panelConfig.showStepSelector}
            onClick={() => {
              const newUserQuery = panelConfig.showStepSelector
                ? VegaLib3.removeQueryIndex(
                    panelConfig.userQuery ?? VegaLib3.defaultRunSetsQuery
                  )
                : VegaLib3.updateQueryIndex(
                    panelConfig.userQuery ?? VegaLib3.defaultRunSetsQuery,
                    viewableStepOptions
                      ? viewableStepOptions[viewableStepOptions.length - 1]
                      : 0
                  );
              updatePanelConfig({
                ...panelConfig,
                userQuery: newUserQuery,
                showStepSelector: !panelConfig.showStepSelector,
                defaultViewedStepIndex: viewableStepOptions
                  ? viewableStepOptions.length - 1
                  : 0,
              });
            }}
          />
        )}
        {viewableStepOptions &&
          panelConfig.showStepSelector &&
          panelConfig.defaultViewedStepIndex != null &&
          isHistoryTableQuery && (
            <div>
              <SliderInput
                min={0}
                max={viewableStepOptions.length - 1}
                onChange={onSliderChange}
                step={1}
                value={
                  panelConfig.defaultViewedStepIndex <
                  viewableStepOptions.length
                    ? panelConfig.defaultViewedStepIndex
                    : viewableStepOptions.length - 1
                }
                hasInput={false}
                debounceTime={100}
              />
              Step:{' '}
              {panelConfig.defaultViewedStepIndex < viewableStepOptions.length
                ? viewableStepOptions[panelConfig.defaultViewedStepIndex]
                : viewableStepOptions[viewableStepOptions.length - 1]}
            </div>
          )}
      </div>
    </div>
  );

  return wrap(
    <>
      <S.EditorsColumn>
        <S.Tabs>
          <S.Tab
            active={currentEditorTab === 'chart'}
            onClick={() => {
              setCurrentEditorTab('chart');
            }}>
            Chart definition
          </S.Tab>
          <S.Tab
            active={currentEditorTab === 'data'}
            onClick={() => {
              setCurrentEditorTab('data');
            }}>
            Data source
          </S.Tab>
          <S.Tab
            active={currentEditorTab === 'other settings'}
            onClick={() => {
              setCurrentEditorTab('other settings');
            }}>
            Other Settings
          </S.Tab>
        </S.Tabs>
        {currentEditorTab === 'chart' && (
          <S.ChartEditorWrapper>
            <S.ChartSaveManager>
              <VegaPanelLoadTrigger
                entityName={entityName}
                onLoad={id => {
                  setVegaView(null);
                  if (runMode === 'manual') {
                    setManualRenderedParsedSpec(null);
                  }
                  updatePanelConfig({
                    ...panelConfig,
                    panelDefId: id,
                    customPanelDef: undefined,
                  });
                }}>
                {({setOpen: setLoadOpen}) => {
                  if (activeChartId === 'custom') {
                    return (
                      <S.ChartAction
                        onClick={() => {
                          setLoadOpen(true);
                        }}>
                        Load preset
                      </S.ChartAction>
                    );
                  } else {
                    return (
                      <S.ChartNameWrapper>
                        <EditCustomChartAccessTrigger
                          customChartId={activeChartId}>
                          {({anchorRef, open, setOpen: setEditAccessOpen}) => (
                            <CustomChartAccessIndicator
                              ref={anchorRef}
                              open={open}
                              access={activeChart!.access}
                              readOnly={readOnly}
                              onClick={() =>
                                setEditAccessOpen(o => !o)
                              }></CustomChartAccessIndicator>
                          )}
                        </EditCustomChartAccessTrigger>
                        <S.ChartName
                          onClick={() => {
                            setLoadOpen(true);
                          }}>
                          {activeChart?.displayName + (modified ? '*' : '')}
                          <S.Caret name="down"></S.Caret>
                        </S.ChartName>
                        {readOnly && <S.ReadOnlyText>View only</S.ReadOnlyText>}
                      </S.ChartNameWrapper>
                    );
                  }
                }}
              </VegaPanelLoadTrigger>
              <VegaPanelSaveAsTrigger
                entityName={entityName}
                projectName={projectName}
                spec={activeSpec}
                onSave={id => {
                  updatePanelConfig({...panelConfig, panelDefId: id});
                }}>
                {({setOpen: setSaveAsOpen}) => (
                  <S.ChartActions>
                    {!projectWriteAccess ? (
                      <div />
                    ) : activeChartId === 'custom' ? (
                      <>
                        <S.ChartAction
                          onClick={() => {
                            setSaveAsOpen(true);
                          }}>
                          Save as preset
                        </S.ChartAction>
                      </>
                    ) : (
                      <>
                        <S.ChartAction
                          onClick={() => {
                            updatePanelConfig({
                              ...panelConfig,
                              panelDefId: 'custom',
                              customPanelDef: {
                                displayName: '',
                                description: '',
                                spec: activeSpec,
                              },
                            });
                          }}>
                          Detach
                        </S.ChartAction>
                        {modified && activeChart != null && (
                          <>
                            <S.ChartAction
                              onClick={() => {
                                updatePanelConfig({
                                  ...panelConfig,
                                  customPanelDef: undefined,
                                });
                              }}>
                              Revert
                            </S.ChartAction>
                            <S.ChartAction
                              onClick={() => {
                                updateCustomChart({
                                  variables: {
                                    type: 'vega2',
                                    entity: entityName,
                                    name: activeChart.name,
                                    displayName: activeChart.displayName,
                                    spec: activeSpec,
                                  },
                                });
                              }}>
                              Push changes
                            </S.ChartAction>
                          </>
                        )}
                        <VegaPanelRenameTrigger chartId={activeChartId}>
                          {({setOpen: setRenameOpen}) => (
                            <WBPopupMenuTrigger
                              options={[
                                {
                                  value: 'copy-id',
                                  render: ({hovered}) => (
                                    <S.IdOption hovered={hovered}>
                                      Preset ID
                                      <S.ChartId>{activeChartId}</S.ChartId>
                                    </S.IdOption>
                                  ),
                                },
                                ...[
                                  {
                                    value: 'rename',
                                    name: 'Rename preset',
                                  },
                                ],
                                {
                                  value: 'save-as',
                                  name: 'Save as new preset',
                                },
                              ]}
                              onSelect={v => {
                                switch (v) {
                                  case 'copy-id':
                                    copyToClipboard(activeChartId);
                                    toast('Copied preset ID');
                                    break;
                                  case 'rename':
                                    setRenameOpen(true);
                                    break;
                                  case 'save-as':
                                    setSaveAsOpen(true);
                                    break;
                                }
                              }}>
                              {({anchorRef, setOpen, open}) => (
                                <S.OverflowActionsTrigger
                                  open={open}
                                  name="overflow"
                                  ref={anchorRef}
                                  onClick={() =>
                                    setOpen(o => !o)
                                  }></S.OverflowActionsTrigger>
                              )}
                            </WBPopupMenuTrigger>
                          )}
                        </VegaPanelRenameTrigger>
                      </>
                    )}
                  </S.ChartActions>
                )}
              </VegaPanelSaveAsTrigger>
            </S.ChartSaveManager>
            {modifiedViewOnlySpec && (
              <Message negative style={{borderRadius: 0, padding: '8px 12px'}}>
                Create a report to save your changes.
                <div
                  style={{fontWeight: 'bold'}}
                  className="fake-link"
                  onClick={clonePanelToNewReport}>
                  Create report
                </div>
              </Message>
            )}
            <MonacoEditor
              value={activeSpec}
              language="json"
              onChange={debouncedUpdateSpec}
            />
          </S.ChartEditorWrapper>
        )}
        {currentEditorTab === 'data' && (
          <S.DataSourceEditor
            queryFields={
              panelConfig?.userQuery == null
                ? VegaLib3.defaultRunSetsQuery.queryFields
                : panelConfig.userQuery.queryFields
            }
            fixedFields={VegaLib3.fixedRunSetsQuery.queryFields}
            templateArgs={templateArgs}
            setQueryFields={newFields => {
              if (panelConfig.userQuery != null) {
                const updatedQuery = VegaLib3.updateMatchingKeys(
                  panelConfig.userQuery,
                  {queryFields: newFields}
                );
                updatePanelConfig({...panelConfig, userQuery: updatedQuery});
              } else {
                updatePanelConfig({
                  ...panelConfig,
                  userQuery: {queryFields: newFields},
                });
              }
            }}
          />
        )}
        {currentEditorTab === 'other settings' && otherSettingsTabContent}
      </S.EditorsColumn>
      <S.WidthResizer
        onMouseDown={widthResizerOnMouseDown}
        cursor={previewWidthCursor}
        resizing={previewWidthResizing}
      />
      <S.PreviewColumn width={previewWidth}>
        <S.PreviewManager>
          <S.RunButton
            $highlighted={
              runMode === 'manual' && parsedSpec !== manualRenderedParsedSpec
            }
            name="chevron-minimized"
            onClick={() => {
              setPreviewKey(Math.random());
              setManualRenderedParsedSpec(parsedSpec);
            }}></S.RunButton>
          <S.RunModeSelect
            options={
              // swap so that the other option is always below
              runMode === 'auto'
                ? [
                    {value: 'auto', name: 'Auto'},
                    {value: 'manual', name: 'Manual'},
                  ]
                : [
                    {value: 'manual', name: 'Manual'},
                    {value: 'auto', name: 'Auto'},
                  ]
            }
            autoMenuWidth={true}
            value={runMode}
            onSelect={v => {
              if (v === 'manual') {
                setManualRenderedParsedSpec(parsedSpec);
              }
              setRunMode(v as string);
            }}></S.RunModeSelect>
        </S.PreviewManager>
        <S.PreviewWrapper height={previewHeight}>
          <S.FakePanel>
            {parsedSpec == null ? (
              <PanelError message="Invalid vega spec." />
            ) : runMode === 'manual' && manualRenderedParsedSpec == null ? (
              <WandbLoader></WandbLoader>
            ) : (
              <CustomPanelRenderer
                innerKey={previewKey}
                spec={
                  runMode === 'manual' ? manualRenderedParsedSpec! : parsedSpec
                }
                err={err}
                data={resultWithColors}
                loading={loading}
                slow={slow}
                userSettings={{
                  fieldSettings: panelConfig.fieldSettings || {},
                  stringSettings: panelConfig.stringSettings || {},
                }}
                customRunColors={customRunColors}
                setView={setVegaView}
                showRunSelector={panelConfig.showRunSelector}
                viewedRun={viewedRun}
                viewableRunOptions={viewableRunOptions}
                setViewedRun={setViewedRun}
                setUserQuery={setUserQuery}
                viewedStep={viewedStep}
                showStepSelector={panelConfig.showStepSelector}
                viewableStepOptions={viewableStepOptions}
                panelConfig={panelConfig}
              />
            )}
          </S.FakePanel>
        </S.PreviewWrapper>
        <S.HeightResizer
          cursor={previewHeightCursor}
          resizing={previewHeightResizing}
          onMouseDown={heightResizerOnMouseDown}></S.HeightResizer>
        <S.DebugWrapper>
          <DataSignalViewer view={vegaView} />
        </S.DebugWrapper>
      </S.PreviewColumn>
      <S.FieldsColumn>
        <S.Tabs>
          <S.Tab active={true}>User fields</S.Tab>
        </S.Tabs>
        <S.FieldsWrapper>
          {userFieldRefs.map((r, i) => {
            switch (r.type) {
              case 'field':
                return (
                  <S.UserFieldRow key={r.type + '-' + r.name}>
                    <S.UserFieldLabel className="chart-label">
                      {r.name}
                    </S.UserFieldLabel>
                    <S.UserFieldSelect
                      options={userFieldOptions}
                      value={panelConfig.fieldSettings?.[r.name] || ''}
                      onSelect={value => {
                        if (typeof value === 'string') {
                          updatePanelConfig({
                            ...panelConfig,
                            fieldSettings: {
                              ...panelConfig.fieldSettings,
                              [r.name]: value,
                            },
                          });
                        }
                      }}></S.UserFieldSelect>
                  </S.UserFieldRow>
                );
              case 'string':
                return (
                  <S.UserFieldRow key={r.type + '-' + r.name}>
                    <S.UserFieldLabel className="chart-label">
                      {r.name}
                    </S.UserFieldLabel>
                    <S.UserFieldString
                      value={panelConfig.stringSettings?.[r.name] || ''}
                      placeholder={r.default ?? 'empty'}
                      save={value => {
                        updatePanelConfig({
                          ...panelConfig,
                          stringSettings: {
                            ...panelConfig.stringSettings,
                            [r.name]: value,
                          },
                        });
                      }}></S.UserFieldString>
                  </S.UserFieldRow>
                );
              default:
                return <></>;
            }
          })}
        </S.FieldsWrapper>
        <S.CloseButton onClick={onClose}>Close editor</S.CloseButton>
      </S.FieldsColumn>
    </>
  );
};

export default CustomPanelEditor;
