import * as React from 'react';
import * as d3 from 'd3';
import {pickNameProps, NameProps} from '../util/reactUtils';
import {clamp} from '../util/math';
import * as vec2 from '../util/vec2';
import './Axis.less';
import makeComp from '../util/profiler';

// Note I originally wrote this to support X, and Y Axises
// but decided to narrow the scope to just the X axis for time being
export const SelectableAxis = makeComp(
  (
    props: {
      direction: 'top' | 'bottom' | 'left' | 'right';
      width: number;
      // A range of numbers(inclusive) that represents the current selection
      selection?: [number, number];
      ticks: number[];
      itemWidth?: number;
      // On select takes a range and returns a new range.
      //
      // This allows the parent to recieve the control event
      // And response according setting a new range.
      // In most cases this the same range, but in certain cases
      // The parent wants to control what ranges are valid.
      // E.g. Expanding very narrow range.
      onSelect?: (range: [number, number]) => [number, number];
    } & NameProps
  ) => {
    interface Selection {
      selecting: boolean;
      sliding?: boolean;
      startPoint?: number;
      endPoint?: number;
    }

    const itemWidth = props.itemWidth || 0;
    const padding = itemWidth / 2;
    const innerWidth = props.width - 2 * padding;

    const scale = React.useMemo(
      () =>
        d3
          .scaleLinear()
          .domain(d3.extent(props.ticks) as [number, number])
          .range([0, innerWidth]),
      [props.ticks, innerWidth]
    );

    // Scale the given selection to the axis
    const propsStart = props.selection && scale(props.selection[0]);
    const propsEnd = props.selection && scale(props.selection[1]);

    const [{selecting, startPoint, endPoint}, setSelection] =
      React.useState<Selection>({
        selecting: false,
        startPoint: propsStart,
        endPoint: propsEnd,
      });

    const [sliding, setSliding] = React.useState<boolean>(false);
    const [slidePos, setSlidePos] = React.useState<[number, number]>([0, 0]);

    let pos: [number, number] | null;

    // Pass two ranges of x values
    // This will scale the points to the domain, call the domain
    // setter and update locally if the setter makes a change
    const onSelect = (ps: [number, number]) => {
      const [p1, p2] = ps;

      let newSelection;
      if (props.onSelect) {
        const p1Scaled = scale.invert(p1);
        const p2Scaled = scale.invert(p2);
        const newScaledRange = props.onSelect([p1Scaled, p2Scaled]);
        newSelection = newScaledRange.map(scale);
      } else {
        newSelection = [p1, p2];
      }

      setSelection({
        selecting: false,
        startPoint: newSelection[0],
        endPoint: newSelection[1],
      });
    };

    // Initial Selection Events
    const onStart = (e: React.MouseEvent<SVGElement>) => {
      if (pos == null || sliding) {
        return;
      }
      const startX = clamp(e.pageX - pos[0], [0, innerWidth]);
      setSelection({selecting: true, startPoint: startX});
    };

    const onDrag = (e: React.MouseEvent<SVGElement>) => {
      if (!selecting || pos == null) {
        return;
      }

      const endX = clamp(e.pageX - pos[0], [0, innerWidth]);
      setSelection(s => ({...s, endPoint: endX}));
    };

    const onEnd = (e: React.MouseEvent<SVGElement>) => {
      if (!selecting || pos == null) {
        return;
      }
      const endX = clamp(e.pageX - pos[0], [0, innerWidth]);

      setSelection(s => ({...s, endPoint: endX, selecting: false}));
      if (props.onSelect && endX && startPoint) {
        onSelect([Math.min(endX, startPoint), Math.max(endX, startPoint)]);
      }
    };

    // Presented the selected part: the whole bar, the start handle or end handle
    type SlidePart = 'start-handle' | 'whole-bar' | 'end-handle';
    // Converts a part a single to its proper multishift(start and end)
    const partToShift = (shift: number, p: SlidePart): [number, number] =>
      p === 'start-handle'
        ? [shift, 0]
        : p === 'whole-bar'
        ? [shift, shift]
        : [0, shift];

    const onSelectionAdjust = (
      e: React.MouseEvent<SVGElement>,
      part: SlidePart
    ) => {
      if (startPoint != null && endPoint != null && !selecting) {
        const initial = [e.pageX, e.pageY] as [number, number];
        setSliding(true);
        e.stopPropagation();

        const onSlide = (se: MouseEvent) => {
          const diff = vec2.sub([se.pageX, se.pageY], initial);
          const shift = partToShift(diff[0], part);

          setSlidePos(shift);
        };

        document.body.addEventListener('mousemove', onSlide);

        const endSliding = (se: MouseEvent) => {
          const diff = vec2.sub([se.pageX, se.pageY], initial);
          const shift = partToShift(diff[0], part);
          setSlidePos([0, 0]);
          setSliding(false);
          const newPos = vec2.add(shift, [startPoint, endPoint]);
          const point = vec2.clamp(newPos, [0, props.width]);
          onSelect(point);

          // Cleanup
          document.body.removeEventListener('mouseup', endSliding);
          document.body.removeEventListener('mousemove', onSlide);
        };

        // Listen for next mouse up event anywhere
        document.body.addEventListener('mouseup', endSliding);
      }
    };

    const axis = React.useMemo(() => {
      const method =
        props.direction === 'top'
          ? d3.axisTop
          : props.direction === 'bottom'
          ? d3.axisBottom
          : props.direction === 'left'
          ? d3.axisLeft
          : d3.axisRight;

      const newAxis = method(scale);

      // Use fixed scale values when given
      if (props.ticks) {
        newAxis.tickValues(props.ticks);
      }
      return newAxis;
    }, [props.ticks, scale, props.direction]);

    const setRef = (ele: SVGElement | null) => {
      if (ele && axis) {
        axis(d3.select(ele as any));
        const bound = ele.getBoundingClientRect();
        pos = [bound.left, bound.top];
      }
    };

    // Display local state selection when in interactive mode
    // Otherwise the selection props are prioritized
    //
    // When neither are available terminate to null
    let selectionUI;
    // When a selecton has been made render UI
    if (startPoint != null && endPoint != null) {
      const [p1, p2] = vec2.clamp(vec2.add([startPoint, endPoint], slidePos), [
        0,
        innerWidth,
      ]);
      const width = Math.abs(p2 - p1);

      selectionUI = {
        x: Math.min(p1, p2),
        width,
      };
    }

    // TODO: Pull out the static axis code
    // and use it on top of the chart
    const HANDLE_SIZE = 2;
    const SCALE_THICKNESS = 20;
    return (
      <svg
        {...pickNameProps(props)}
        style={{cursor: 'col-resize', userSelect: 'none'}}
        width={props.width}
        onMouseDown={onStart}
        onMouseUp={onEnd}
        onMouseMove={onDrag}>
        <g
          style={{transform: `translateX(${padding}px) translateY(20px)`}}
          ref={setRef}
        />
        {selectionUI && (
          <g
            style={{
              transform: `translateX(${padding}px) translateY(0px)`,
            }}>
            <rect
              style={{cursor: 'grab'}}
              onMouseDown={e => onSelectionAdjust(e, 'whole-bar')}
              width={selectionUI.width}
              height={SCALE_THICKNESS}
              fill="rgba(32,32,213,0.3)"
              color="black"
              x={selectionUI.x}
              y={SCALE_THICKNESS / 2}
            />
            <g className="axis-control" width="40" height="40">
              <rect
                className="axis-control__stick"
                onMouseDown={e => onSelectionAdjust(e, 'start-handle')}
                x={selectionUI.x - HANDLE_SIZE / 2}
                y={SCALE_THICKNESS / 2 - 2}
                width={HANDLE_SIZE}
                height={SCALE_THICKNESS + 2}
              />
              <rect
                className="axis-control__knob"
                onMouseDown={e => onSelectionAdjust(e, 'start-handle')}
                x={selectionUI.x}
                y={SCALE_THICKNESS / 2 - 6}
                width={HANDLE_SIZE}
                height={SCALE_THICKNESS / 2 + 4}
              />
            </g>
            <g className="axis-control" width="20" height="40">
              <rect
                onMouseDown={e => onSelectionAdjust(e, 'end-handle')}
                className="axis-control__stick"
                x={selectionUI.x + selectionUI.width - HANDLE_SIZE / 2}
                y={SCALE_THICKNESS / 2 - 2}
                width={HANDLE_SIZE}
                height={SCALE_THICKNESS + 2}
              />
              <rect
                onMouseDown={e => onSelectionAdjust(e, 'end-handle')}
                className="axis-control__knob"
                x={selectionUI.x + selectionUI.width}
                y={SCALE_THICKNESS / 2 - 6}
                width={HANDLE_SIZE}
                height={SCALE_THICKNESS / 2 + 4}
              />
            </g>
          </g>
        )}
      </svg>
    );
  },
  {id: 'SelectableAxis'}
);

// Note I originally wrote this to support X, and Y Axises
// but decided to narrow the scope to just the X axis for time being
export const StaticAxis = makeComp(
  (
    props: {
      direction: 'top' | 'bottom' | 'left' | 'right';
      width: number;
      // A range of numbers(inclusive) that represents the current selection
      ticks: number[];
      // The size of the in the axis
      itemWidth?: number;
    } & NameProps
  ) => {
    const itemWidth = props.itemWidth || 0;
    const padding = itemWidth / 2;
    const innerWidth = props.width - 2 * padding;

    const scale = React.useMemo(
      () =>
        d3
          .scaleLinear()
          .domain(d3.extent(props.ticks) as [number, number])
          .range([0, innerWidth]),
      [props.ticks, innerWidth]
    );

    const axis = React.useMemo(() => {
      const method =
        props.direction === 'top'
          ? d3.axisTop
          : props.direction === 'bottom'
          ? d3.axisBottom
          : props.direction === 'left'
          ? d3.axisLeft
          : d3.axisRight;

      const newAxis = method(scale);

      // Use fixed scale values when given
      if (props.ticks) {
        newAxis.tickValues(props.ticks);
      }
      return newAxis;
    }, [props.ticks, scale, props.direction]);

    const setRef = (ele: SVGElement | null) => {
      if (ele && axis) {
        axis(d3.select(ele as any));
      }
    };

    return (
      <svg {...pickNameProps(props)} width={props.width} height={15}>
        <g
          style={{transform: `translateX(${padding}px) translateY(20px)`}}
          ref={setRef}
        />
      </svg>
    );
  },
  {id: 'StaticAxis'}
);
