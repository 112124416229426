import * as _ from 'lodash';
import * as Normalize from '../normalize';
import * as PanelsNormalize from '../panels/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as PanelBankConfigNormalize from '../panelBankConfig/normalize';
import * as Types from './types';

// TODO(views): Probably move this somewhere central.
function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]) {
  return _.omit(obj, keys);
}

export const normalize = Normalize.normFn<Types.RunPageObjSchema>(
  'run-view',
  (whole, ctx) => {
    // console.log('whole run-view', whole);
    return {
      ...omit(whole, 'panels', 'settings', 'panelBankConfig'),
      panelsRef: PanelsNormalize.normalize(whole.panels, ctx),
      panelBankConfigRef: PanelBankConfigNormalize.normalize(
        whole.panelBankConfig,
        ctx
      ),
      panelSettingsRef: PanelSettingsNormalize.normalize(whole.settings, ctx),
    };
  }
);

export const denormalize = Normalize.denormFn<Types.RunPageObjSchema>(
  (part, ctx) => ({
    ...omit(part, 'panelsRef', 'panelSettingsRef', 'panelBankConfigRef'),
    panels: PanelsNormalize.denormalize(part.panelsRef, ctx),
    panelBankConfig: PanelBankConfigNormalize.denormalize(
      part.panelBankConfigRef,
      ctx
    ),
    settings: PanelSettingsNormalize.denormalize(part.panelSettingsRef, ctx),
  })
);
