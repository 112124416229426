// graphql query for polling a runs files.

import gql from 'graphql-tag';
import * as React from 'react';
import {Query} from 'react-apollo';
import * as Filter from '../util/filters';
import makeComp from '../util/profiler';
import {captureError} from '../util/integrations';

///// Simple type helpers
// Type T minus the single key K
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// Type T minus all keys in K
type Subtract<T, K> = Omit<T, keyof K>;

///// Query definition

export const DATA_FRAME_QUERY = gql`
  query DataTableRunFiles(
    $entityName: String!
    $projectName: String!
    $dataFrameKeys: [String!]!
    $groupKeys: [String!]
    $order: String
    $limit: Int
    $after: String
    $filters: JSONString
    $columns: [String!]
    $includeRows: Boolean!
    $includeSchema: Boolean!
  ) {
    dataFrame(
      entityName: $entityName
      projectName: $projectName
      dataFrameKeys: $dataFrameKeys
      groupKeys: $groupKeys
      order: $order
      filters: $filters
      columns: $columns
      first: $limit
      after: $after
    ) @include(if: $includeRows) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      schema
      edges {
        node {
          row
        }
      }
    }
    dataFrameSchema(
      entityName: $entityName
      projectName: $projectName
      dataFrameKeys: $dataFrameKeys
    ) @include(if: $includeSchema)
  }
`;

// These types define the inputs and outputs of the above gql query

// Any variables required by the query.
interface Variables {
  entityName: string;
  projectName: string;
  dataFrameKeys: string[];
  limit: number;
  after?: string;
  order?: string;
  groupKeys?: string[];
  filters?: string;
  columns?: string[];
  includeRows: boolean;
  includeSchema: boolean;
}

interface DataFrameData {
  totalCount: number;
  pageInfo: any;
  schema: any;
  edges: Array<{node: {row: any}}>;
}

// The query's output shape.
interface Data {
  dataFrame: DataFrameData;
  dataFrameSchema: any;
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
export interface InputProps {
  entityName: string;
  projectName: string;
  limit: number;
  dataFrameKeys: string[];
  order?: string;
  groupKeys: string[];
  filters?: Filter.Filter;
  columns?: string[];
  disabled?: boolean;
  disableRows?: boolean;
  disableSchema?: boolean;
}

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  dataFrame: undefined;
  dataFrameSchema: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  dataFrame: DataFrameData;
  dataFrameSchema: any;
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface QueryResultProps {
  dataFrameQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

export const withQuery = <P extends object>(
  Component: React.ComponentType<P & QueryResultProps>
) =>
  makeComp(
    (inputProps: Subtract<P, QueryResultProps> & InputProps) => {
      const {
        entityName,
        projectName,
        dataFrameKeys,
        limit,
        order,
        groupKeys,
        filters,
        columns,
        disabled,
        disableRows,
        disableSchema,
      } = inputProps;

      const variables: Variables = {
        entityName,
        projectName,
        dataFrameKeys,
        limit,
        order,
        groupKeys: groupKeys.length > 0 ? groupKeys : undefined,
        filters:
          filters != null
            ? JSON.stringify(Filter.toMongo(Filter.mergedFilters(filters)))
            : undefined,
        columns,
        includeRows: !disableRows,
        includeSchema: !disableSchema,
      };
      return (
        <Query<Data, Variables>
          query={DATA_FRAME_QUERY}
          variables={variables}
          skip={disabled}>
          {rawQueryResult => {
            const r: QueryResultProps = {
              dataFrameQuery: {
                dataFrame: undefined,
                dataFrameSchema: undefined,
                loading: true,
              },
            };
            if (disabled) {
              r.dataFrameQuery.loading = false;
            } else {
              if (!rawQueryResult.loading) {
                if (rawQueryResult.data == null) {
                  captureError(
                    'Unexpected Apollo error, loading: false, data: undefined',
                    'dataFrameQuery',
                    {extra: {rawQueryResult}}
                  );
                  throw new Error(
                    'Unexpected Apollo error, loading: false, data: undefined'
                  );
                } else {
                  const dataFrame = rawQueryResult.data.dataFrame;
                  const dataFrameSchema = rawQueryResult.data.dataFrameSchema;
                  r.dataFrameQuery = {
                    loading: false,
                    dataFrame,
                    dataFrameSchema,
                  };
                }
              }
            }
            return <Component {...(inputProps as P)} {...r} />;
          }}
        </Query>
      );
    },
    {id: 'dataFrameQuery.withQuery'}
  );
