export type Vec2 = [number, number];
export function clamp(value: Vec2, [low, high]: Vec2) {
  return [
    Math.min(Math.max(value[0], low), high),
    Math.min(Math.max(value[1], low), high),
  ] as Vec2;
}

export function add(x: Vec2, y: Vec2) {
  return [x[0] + y[0], x[1] + y[1]] as Vec2;
}

export function sub(x: Vec2, y: Vec2) {
  return [x[0] - y[0], x[1] - y[1]] as Vec2;
}

// sqrt(x0^2+x1^2)
export function mag(x: Vec2) {
  return Math.sqrt(x[0] * x[0] + x[1] * x[1]);
}

// distance
export function dist(x: Vec2, y: Vec2) {
  return mag(sub(x, y));
}
