import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 40px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px #d3d3d3;
  position: relative;
  margin-top: 12px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OnelineCell = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CenteredCell = styled.div`
  justify-self: center;
`;

export const FormWrapper = styled.div`
  padding: 24px;
`;

export const GenerateButtonWrapper = styled.div`
  height: 40px;
`;

export const AddButtonWrapper = styled.div`
  height: 34px;
`;
