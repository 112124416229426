import styled from 'styled-components';

export const FlagInputWrapper = styled.div`
  width: 140px;
  margin-right: 10px;
`;

export const InputHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 4px;
`;

export const NewFlagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;

export const FlagListWrapper = styled.div`
  margin: 0;
  padding-bottom: 4px;
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const InputLabel = styled.div`
  font-size: 16px;
  max-width: 90px;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  padding: 0px;
`;

export const ClaimsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DeleteButton = styled.div`
  :hover {
    color: red;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  font-size: 16px;
  max-width: 165px;
  overflow-wrap: anywhere;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
