export const USER_WORKSPACE_NAME = 'workspace';
export const DEFAULT_WORKSPACE_NAME = 'default';

export function workspaceType(viewName: string) {
  return viewName.startsWith(DEFAULT_WORKSPACE_NAME)
    ? DEFAULT_WORKSPACE_NAME
    : USER_WORKSPACE_NAME;
}

export function userWorkspaceName(workspaceObjectID?: string) {
  const workspaceNameSuffix =
    workspaceObjectID != null ? `-${workspaceObjectID}` : '';
  return USER_WORKSPACE_NAME + workspaceNameSuffix;
}

export function defaultWorkspaceName(workspaceObjectID?: string) {
  const workspaceNameSuffix =
    workspaceObjectID != null ? `-${workspaceObjectID}` : '';
  return DEFAULT_WORKSPACE_NAME + workspaceNameSuffix;
}
