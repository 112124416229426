import {WBPopupDirection, WBPopupTrigger} from '@wandb/ui';
import {ApolloQueryResult} from 'apollo-client';
import * as _ from 'lodash';
import * as React from 'react';
import {Button, Input} from 'semantic-ui-react';
import '../css/TagAddButton.less';
import * as GQLTypes from '../types/graphql';
import * as FuzzyMatch from '../util/fuzzyMatch';
import makeComp from '../util/profiler';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {Tag} from './Tags';

export type SaveTags = (tags: string[]) => Promise<ApolloQueryResult<{}>>;

interface TagAddButtonProps {
  tags: GQLTypes.Tag[];
  availableTags: GQLTypes.Tag[];
  compact?: boolean;
  direction?: WBPopupDirection;
  noun?: 'tag' | 'alias';
  iconOnly?: boolean;
  addTag(tag: GQLTypes.Tag): Promise<void>;
}

export const TagAddButton: React.FC<TagAddButtonProps> = makeComp(
  ({addTag, availableTags, compact, direction, noun, iconOnly}) => {
    const [searchString, setSearchString] = React.useState('');
    const [isAdding, setAdding] = React.useState(false);
    let tagOptions = _.chain(availableTags)
      // .difference(this.props.chosenTags)
      .sort()
      .value();
    tagOptions = FuzzyMatch.fuzzyMatchWithMapping(
      tagOptions,
      searchString,
      t => t.name
    );
    noun = noun ?? 'tag';
    const nextColorIndex =
      (_.max(availableTags.map(t => t.colorIndex)) || -1) + 1;

    const onTagAdd = async (tag: GQLTypes.Tag, closer: () => void) => {
      setAdding(true);
      await addTag(tag);
      closer();
      setAdding(false);
    };

    return (
      <WBPopupTrigger
        popupContent={({close}) => (
          <div className="tag-add-button-menu">
            <Input
              onClick={(e: any) => e.stopPropagation()}
              icon="search"
              className="search tag-add-button-search"
              placeholder={`Search or create a new ${noun}`}
              value={searchString}
              loading={isAdding}
              onChange={(e, {value}) => setSearchString(value)}
              onKeyPress={async (e: React.KeyboardEvent) => {
                if (e.key === 'Enter' && searchString !== '') {
                  await onTagAdd(
                    {
                      name: searchString,
                      colorIndex: nextColorIndex,
                    },
                    close
                  );
                }
              }}
            />
            <div className="tag-add-button-results">
              {tagOptions.map((tag, index) => (
                <div
                  className="tag-add-button-result-item"
                  data-test="tag-result"
                  key={index}
                  onClick={async () => {
                    await onTagAdd(tag, close);
                  }}>
                  <Tag tag={tag} />
                </div>
              ))}
              {searchString &&
                !_.includes(
                  availableTags.map(t => t.name),
                  searchString
                ) && (
                  <div
                    key="__create-new"
                    className="tag-add-button-result-item create-new"
                    data-test="create-new-tag"
                    onClick={async () => {
                      await onTagAdd(
                        {
                          name: searchString,
                          colorIndex: nextColorIndex,
                        },
                        close
                      );
                    }}>
                    <span>Create new tag: </span>
                    <Tag
                      tag={{
                        name: searchString,
                        colorIndex: nextColorIndex,
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
        direction={direction || 'center left'}>
        {({anchorRef, setOpen}) =>
          iconOnly ? (
            <div
              className="tag-add-button-icon-only"
              data-test="tag-add-button"
              onClick={() => setOpen(true)}
              ref={anchorRef}>
              +
            </div>
          ) : (
            <div
              className={`tag-add-button dropdown-menu-text-icon-left-button${
                compact ? ' compact' : ''
              }`}
              data-test="tag-add-button"
              onClick={() => setOpen(true)}
              ref={anchorRef}>
              {compact ? (
                <Button className="wb-icon-button" size="tiny">
                  +
                </Button>
              ) : (
                <Button
                  size="tiny"
                  className="wb-icon-button"
                  icon={<LegacyWBIcon name="tag-gray" />}
                  content="Add a tag"
                />
              )}
            </div>
          )
        }
      </WBPopupTrigger>
    );
  },
  {id: 'TagAddButton'}
);
