import '../css/ProjectOverview.less';

import * as S from './ProjectOverview.styles';

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Image, Modal} from 'semantic-ui-react';

import modalImg from '../assets/submit_benchmark_run.png';
import EditableField from './EditableField';
import {ProjectData} from '../state/graphql/projectPageQuery';
import {AccessOptions} from '../util/permissions';
import {TimeDelta} from '../util/time';
import {benchmarkProject} from '../util/urls';
import LegacyWBIcon from './elements/LegacyWBIcon';
import {ExtraProjectActions} from './ExtraProjectActions';
import ProjectAccess from './ProjectAccess';
import ProjectRenameModal from './ProjectRenameModal';
import makeComp from '../util/profiler';
import docUrl from '../util/doc_urls';
import CodeBlock from './CodeBlock';
import {TargetBlank} from '../util/links';

interface Example {
  title: string;
  description: React.ReactNode;
  getCode?: (entityName: string, projectName: string) => string;
}

const EXAMPLES: Example[] = [
  {
    title: 'Find the run path',
    description: (
      <S.CodeBlockDescription>
        To use the public API, you'll often need the run path which is{' '}
        <S.InlineCode>{'<entity>/<project>/<run_id>'}</S.InlineCode>. In the app
        UI, open a run page, then click on the Overview tab to see the run path.
      </S.CodeBlockDescription>
    ),
  },
  {
    title: 'Update config for an existing run',
    description: 'This example updates one of your configuration settings.',
    getCode: (entityName, projectName) => `import wandb
api = wandb.Api()

run = api.run("${entityName}/${projectName}/<run_id>")
run.config["key"] = updated_value
run.update()`,
  },
  {
    title: 'Export metrics from a single run to a CSV file',
    description:
      'This snippet finds all the metrics saved for a single run and saves them to a CSV file.',
    getCode: (entityName, projectName) => `import wandb
api = wandb.Api()

# run is specified by <entity>/<project>/<run_id>
run = api.run("${entityName}/${projectName}/<run_id>")

# save the metrics for the run to a csv file
metrics_dataframe = run.history()
metrics_dataframe.to_csv("metrics.csv")`,
  },
  {
    title: 'Read metrics for a run',
    description: (
      <S.CodeBlockDescription>
        Pull down the accuracy and timestamps for logged metric data. In this
        example, data was logged with{' '}
        <S.InlineCode>{'wandb.log({"accuracy": acc})'}</S.InlineCode> to a run
        with the run path{' '}
        <S.InlineCode>{'<entity>/<project>/<run_id>'}</S.InlineCode>.
      </S.CodeBlockDescription>
    ),
    getCode: (entityName, projectName) => `import wandb
api = wandb.Api()

run = api.run("${entityName}/${projectName}/<run_id>")
if run.state == "finished":
    for i, row in run.history().iterrows():
      print(row["_timestamp"], row["accuracy"])`,
  },
  {
    title: 'Get unsampled metric data',
    description:
      "When you pull data from history, by default it's sampled to 500 points. Get all the logged data points using run.scan_history(). Here's an example downloading all the loss data points logged in history.",
    getCode: (entityName, projectName) => `import wandb
api = wandb.Api()

run = api.run("${entityName}/${projectName}/<run_id>")
history = run.scan_history()
losses = [row["loss"] for row in history]`,
  },
  {
    title: 'Download the best model file from a sweep',
    description:
      'This snippet downloads the model file with the highest validation accuracy from a sweep with runs that saved model files to model.h5.',
    getCode: (entityName, projectName) => `import wandb
api = wandb.Api()

sweep = api.sweep("${entityName}/${projectName}/<sweep_id>")
runs = sorted(sweep.runs,
  key=lambda run: run.summary.get("val_acc", 0), reverse=True)
val_acc = runs[0].summary.get("val_acc". 0)
print(f"Best run {runs[0].name} with {val_acc}% validation accuracy")

runs[0].file("model.h5").download(replace=True)
print("Best model saved to model-best.h5")`,
  },
];

interface ProjectOverviewProps {
  project: ProjectData;
  updateProject: any;
  deleteProject(): void;
  undeleteRuns(): void;
}

const ProjectOverview: React.FC<ProjectOverviewProps> = makeComp(
  ({project, updateProject, deleteProject, undeleteRuns}) => {
    const [showRename, setShowRename] = useState<boolean>(false);

    return (
      <div className="project-overview">
        <div className="project-overview-top">
          <div className="mini-page-header">
            <div className="name-header-wrapper">
              <EditableField
                showEditIcon
                className="name-header"
                asHeader="h1"
                placeholder={project.name}
                value={project.name}
                overrideClick={() => setShowRename(true)}
              />
              <ProjectRenameModal
                open={showRename}
                targetProject={{
                  name: project.name,
                  entityName: project.entityName,
                }}
                onClose={() => setShowRename(false)}
              />
              <ExtraProjectActions
                project={project}
                deleteProject={deleteProject}
                undeleteRuns={undeleteRuns}
              />
            </div>

            <EditableField
              className="description"
              readOnly={project.readOnly}
              multiline
              showEditIcon
              placeholder={'What problem does this project tackle?'}
              value={project.description}
              save={value => {
                updateProject({
                  variables: {id: project.id, description: value},
                });
              }}
            />
          </div>
          {project.linkedBenchmark && (
            <div className="overview-item">
              <div className="overview-key">Benchmark</div>
              <div className="overview-value">
                <Dropdown
                  className="dropdown-menu-icon-button benchmark-dropdown"
                  simple
                  icon={null}
                  trigger={
                    <>
                      <LegacyWBIcon name="benchmark" />
                      {'Linked to ' + project.linkedBenchmark.name}
                      <LegacyWBIcon name="chevron-expanded" />
                    </>
                  }>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Modal
                        trigger={<Dropdown.Item>Submit run</Dropdown.Item>}>
                        <Modal.Header icon="benchmark">
                          Submit to benchmark
                        </Modal.Header>
                        <Modal.Content>
                          <p>
                            To submit a run from the table, open the menu and
                            click <b>Submit to benchmark</b>.
                          </p>
                          <img
                            alt="Screenshot showing the submission dropdown is located in the runs table to the left of the name"
                            style={{
                              width: '100%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                            src={modalImg}
                          />
                        </Modal.Content>
                      </Modal>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={benchmarkProject(project.linkedBenchmark)}>
                      Visit benchmark
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          <div className="overview-item">
            <div className="overview-key">Privacy</div>
            <div className="overview-value">
              <ProjectAccess
                project={project}
                updateProjectAccess={(access: AccessOptions) =>
                  updateProject({
                    variables: {
                      id: project.id,
                      access,
                    },
                  })
                }
              />
            </div>
          </div>
          {project.lastActive != null && (
            <div className="overview-item">
              <div className="overview-key">Last active</div>
              <div className="overview-value">
                {new Date(project.lastActive).toLocaleString()}
              </div>
            </div>
          )}
          <div className="overview-item">
            <div className="overview-key">Author</div>
            <div className="overview-value">
              <Link className="user-link" to={`/${project.user.username}`}>
                <Image
                  src={project.user.photoUrl}
                  avatar
                  onError={(i: any) => (i.target.style.display = 'none')}
                />
                {project.user.username}
              </Link>
            </div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Contributors</div>
            <div className="overview-value">{`${project.totalUsers} user${
              project.totalUsers === 1 ? '' : 's'
            }`}</div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Total runs</div>
            <div className="overview-value">{project.totalRuns}</div>
          </div>
          <div className="overview-item">
            <div className="overview-key">Total compute</div>
            <div className="overview-value">
              {new TimeDelta(project.computeHours).toSingleUnitString()}
            </div>
          </div>
        </div>
        <S.ExampleSection>
          <S.ExampleSectionHeader>
            <S.ExampleSectionTitle>Export & Update Data</S.ExampleSectionTitle>
            <S.ExampleSectionDescription>
              Use our Public API to export data or update data, such as editing
              the config of existing runs. Learn more in{' '}
              <TargetBlank href={docUrl.publicApi}>the docs →</TargetBlank>
            </S.ExampleSectionDescription>
          </S.ExampleSectionHeader>
          {EXAMPLES.map((example: Example, i: number) => (
            <S.CodeBlockWrapper key={i}>
              <CodeBlock
                title={example.title}
                description={example.description}
                code={example.getCode?.(
                  project.entityName,
                  project.name
                )}></CodeBlock>
            </S.CodeBlockWrapper>
          ))}
        </S.ExampleSection>
      </div>
    );
  },
  {id: 'ProjectOverview'}
);

export default ProjectOverview;
