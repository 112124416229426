import styled from 'styled-components';
import {Popup as SemanticPopup} from 'semantic-ui-react';

export const Popup = styled(SemanticPopup)`
  &&&&& {
    padding: 0;
  }
`;

export const PopupContent = styled.div`
  padding: 16px;
`;

export const Label = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
`;
