import * as S from './DataSignalViewer.styles';
import * as CustomPanelEditorStyles from './CustomPanelEditor.styles';
import React from 'react';
import {View as VegaView, truthy} from 'vega';
import VegaDataTable from './VegaDataTable';
import {isPlainObject} from 'lodash';

export interface DataSignalViewerProps {
  className?: string;
  view: VegaView | null;
}

const DataSignalViewer: React.FC<DataSignalViewerProps> = ({
  className,
  view,
}) => {
  const [currentDebugTab, setCurrentDebugTab] = React.useState<
    'data' | 'signals'
  >('data');
  const [tableSelectOpen, setTableSelectOpen] = React.useState(false);
  const [selectedDataTable, setSelectedDataTable] = React.useState<
    string | null
  >(null);
  const [viewState, setViewState] = React.useState<{
    data?: any;
    signals?: any;
  } | null>(null);

  React.useEffect(() => {
    // Because view is constantly changing without React's knowledge,
    // viewState will sometimes be outdated. May fix in future.
    setViewState(
      view == null
        ? null
        : view.getState({
            data: truthy,
            signals: truthy,
            recurse: true,
          })
    );
  }, [view]);

  React.useLayoutEffect(() => {
    if (
      viewState != null &&
      (selectedDataTable == null || viewState.data[selectedDataTable] == null)
    ) {
      const dtOptions = Object.keys(viewState.data);
      setSelectedDataTable(
        viewState.data.wandb != null
          ? 'wandb'
          : dtOptions.length > 1
          ? dtOptions[1]
          : dtOptions[0]
      );
    }
  }, [viewState, selectedDataTable]);

  if (!view) {
    return <></>;
  }
  const dataKeys = viewState == null ? [] : Object.keys(viewState.data);
  const signalKeys = viewState == null ? [] : Object.keys(viewState.signals);

  const dataLength = (data: any) => {
    if (Array.isArray(data)) {
      return data.length;
    } else if (isPlainObject(data)) {
      return Object.keys(data).length;
    }
    return 0;
  };

  return (
    <>
      <CustomPanelEditorStyles.Tabs>
        <CustomPanelEditorStyles.Tab
          active={currentDebugTab === 'data'}
          onClick={() => {
            setCurrentDebugTab('data');
          }}>
          Data
        </CustomPanelEditorStyles.Tab>
        <CustomPanelEditorStyles.Tab
          active={currentDebugTab === 'signals'}
          onClick={() => {
            setCurrentDebugTab('signals');
          }}>
          Signals
        </CustomPanelEditorStyles.Tab>
        {currentDebugTab === 'data' && (
          <S.TableSelectTrigger
            open={tableSelectOpen}
            onClick={() => {
              setTableSelectOpen(o => !o);
            }}>
            {selectedDataTable}
            <S.Caret name="down"></S.Caret>
          </S.TableSelectTrigger>
        )}
      </CustomPanelEditorStyles.Tabs>
      {currentDebugTab === 'data' && tableSelectOpen && (
        <S.TableSelect>
          {dataKeys.map(k => {
            return (
              <S.TableSelectOption
                key={k}
                active={selectedDataTable === k}
                onClick={() => {
                  setViewState(
                    view == null
                      ? null
                      : view.getState({
                          data: truthy,
                          signals: truthy,
                          recurse: true,
                        })
                  );
                  setSelectedDataTable(k);
                }}>
                {k}
                <S.TableRowCount>
                  {dataLength(viewState?.data?.[k])}
                </S.TableRowCount>
              </S.TableSelectOption>
            );
          })}
        </S.TableSelect>
      )}
      {currentDebugTab === 'data' &&
        viewState != null &&
        selectedDataTable != null && (
          <VegaDataTable
            key={selectedDataTable}
            view={view}
            name={selectedDataTable}
            initialValue={viewState.data[selectedDataTable]}
            onValueChange={newValue => {
              setViewState(vs => {
                if (vs == null) {
                  return null;
                }
                return {
                  ...vs,
                  data: {...vs.data, [selectedDataTable]: newValue},
                };
              });
            }}></VegaDataTable>
        )}
      {currentDebugTab === 'signals' && (
        <S.SignalsTable>
          {signalKeys.map(s => {
            const initialValue = viewState?.signals[s];
            if (typeof initialValue === 'function') {
              return null;
            }
            return (
              <S.SignalRow key={s}>
                <S.SignalLabel>{s}</S.SignalLabel>&nbsp;
                <S.StyledSignalValue
                  view={view}
                  signal={s}
                  initialValue={viewState?.signals[s]}></S.StyledSignalValue>
              </S.SignalRow>
            );
          })}
        </S.SignalsTable>
      )}
    </>
  );
};

export default DataSignalViewer;
