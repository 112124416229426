import '../css/SubscribeForm.less';

import React, {FunctionComponent, useMemo, useState, useCallback} from 'react';
import {Button, Message} from 'semantic-ui-react';

import {useCancelCustomerSubscriptionMutation} from '../generated/graphql';
import {doNotRetryContext, propagateErrorsContext} from '../util/errors';
import makeComp from '../util/profiler';

export interface EditSubscriptionFormProps {
  organizationId: string;
  isTrial: boolean;
  isCanceled: boolean;
  onPlanEdited(): void;
}

export const EditSubscriptionForm: FunctionComponent<EditSubscriptionFormProps> =
  makeComp(
    props => {
      const {organizationId, isTrial, isCanceled, onPlanEdited} = props;

      const [confirmCancel, setConfirmCancel] = useState(false);
      const [success, setSuccess] = useState<string | null>(null);
      const [error, setError] = useState<string | null>(null);
      const [formDisabled, setFormDisabled] = useState(false);

      const [cancelSubscriptionMutation] =
        useCancelCustomerSubscriptionMutation({
          context: {...propagateErrorsContext(), ...doNotRetryContext()},
        });

      const onCancelTrial = useCallback(async () => {
        setFormDisabled(true);
        try {
          const result = await cancelSubscriptionMutation({
            variables: {organizationId},
          });

          if (result.data?.cancelCustomerSubscription?.success) {
            setSuccess('Your subscription has been canceled.');
            onPlanEdited();
          } else {
            setError(
              'There was a problem canceling your subscription. Please contact support@wandb.com for help.'
            );
          }
        } catch (e) {
          setError(
            'There was a problem canceling your subscription. Please contact support@wandb.com for help.'
          );
        }
      }, [cancelSubscriptionMutation, onPlanEdited, organizationId]);

      const contactText = useMemo(() => {
        return [
          `To change ${
            isTrial ? '' : 'or cancel '
          }your plan, contact us by opening the chat widget in the lower right corner, or email us at `,
          <a key="wandb-support-email" href="mailto:support@wandb.com">
            support@wandb.com
          </a>,
        ];
      }, [isTrial]);

      const innerHtml = useMemo(() => {
        if (confirmCancel) {
          return (
            <>
              {success != null && (
                <Message positive content={success} className="alert" />
              )}
              {error != null && (
                <Message negative content={error} className="alert" />
              )}
              <div className="checkout-notice">
                Are you sure you want to cancel? Access to your shared teams and
                projects may be interrupted.
              </div>
              <div className="checkout-divider"></div>
              <Button
                data-test="confirm-cancel"
                negative
                className="cancel-button"
                disabled={formDisabled}
                onClick={onCancelTrial}>
                Yes, cancel
              </Button>
            </>
          );
        } else {
          return (
            <>
              <div className="checkout-notice">{contactText}</div>
              {isTrial && (
                <>
                  <div className="checkout-divider"></div>
                  <Button
                    data-test="cancel-subscription"
                    negative
                    className="cancel-button"
                    disabled={isCanceled}
                    onClick={() => setConfirmCancel(true)}>
                    Cancel trial
                  </Button>
                </>
              )}
            </>
          );
        }
      }, [
        confirmCancel,
        contactText,
        error,
        formDisabled,
        isCanceled,
        isTrial,
        onCancelTrial,
        success,
      ]);

      return <div className="checkout-form">{innerHtml}</div>;
    },
    {id: 'EditSubscriptionForm'}
  );
