import React, {
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Form,
  Header,
  Input,
  Modal,
  Dropdown,
  DropdownProps,
  SemanticSIZES,
  SemanticCOLORS,
} from 'semantic-ui-react';

import WBModal from './WBModal';
import {slugFormat} from '../util/text';
import {useViewer} from '../state/viewer/hooks';
import {
  useUserOrganizationsQuery,
  useEntityQuery,
  useCreateTeamMutation,
  OrgType,
} from '../generated/graphql';
import {userFlags} from '../util/permissions';
import config, {envIsLocal, envIsCloudOnprem} from '../config';
import makeComp from '../util/profiler';
import {navigateTo} from '../util/history';

interface CreateTeamButtonProps {
  size?: SemanticSIZES;
  color?: SemanticCOLORS;
  readonly?: boolean;
  renderButton?(onClick: () => void): JSX.Element;
  onCreate(teamName?: string): void;
}

interface CreateTeamModalContentProps extends CreateTeamButtonProps {
  orgOptions: Array<{text: string; value: string}>;
  teamAdminUsername?: string;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?(): void;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const CreateTeamModal: FunctionComponent<CreateTeamModalContentProps> =
  makeComp(
    props => {
      const {orgOptions} = props;
      const viewer = useViewer();
      const [teamName, setTeamName] = useState('');
      const [selectedOrganization, setSelectedOrganization] = useState<
        string | null
      >(null);
      const [teamNameIsAvailable, setTeamNameIsAvailable] = useState<
        boolean | null
      >(null);

      // Set the first org as the default selection when it's available.
      useEffect(() => {
        if (orgOptions.length > 0) {
          setSelectedOrganization(orgOptions[0].value);
        }
      }, [orgOptions]);

      const handleTeamNameChange = useCallback(
        (e: any, data: any) => {
          setTeamName(slugFormat(data.value));
          setTeamNameIsAvailable(null);
        },
        [setTeamName, setTeamNameIsAvailable]
      );

      const handleOrganizationChange = useCallback(
        (_, data: DropdownProps) => {
          setSelectedOrganization(data.value as string);
        },
        [setSelectedOrganization]
      );

      const handleCompletedEntityQuery = useCallback(
        (queryData: any) => {
          if (queryData.entity.name !== teamName) {
            // We've edited the team name since this query was sent.
            return;
          }

          setTeamNameIsAvailable(queryData.entity.available);
        },
        [teamName, setTeamNameIsAvailable]
      );

      const isSubmitDisabled = useMemo(() => {
        if (teamName.length < 3) {
          return true;
        }
        if (teamNameIsAvailable !== true) {
          return true;
        }
        return false;
      }, [teamName, teamNameIsAvailable]);

      const teamNameIcon = useMemo(
        () => ({
          name: (isSubmitDisabled ? 'stop' : 'check') + ' circle outline',
          color: isSubmitDisabled ? 'red' : 'green',
          size: 'small',
        }),
        [isSubmitDisabled]
      );

      const entityQuery = useEntityQuery({
        fetchPolicy: 'network-only',
        variables: {name: teamName},
        skip: teamName === '',
        onCompleted: handleCompletedEntityQuery,
      });

      const [createTeam] = useCreateTeamMutation({
        onCompleted: () => props.onCreate(teamName),
      });

      const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;

      const loading = viewer == null || entityQuery.loading;

      if (orgOptions.length === 0) {
        const message = envIsLocal ? (
          <>
            Teams functionality requires a valid license. Contact{' '}
            <a href="mailto:sales@wandb.com">sales@wandb.com</a> to get started.
          </>
        ) : (
          <>It's completely free to try, get started today!</>
        );
        const modalAction = envIsLocal
          ? undefined
          : {
              content: 'Create team',
              onClick: () => navigateTo({pathname: '/create-team'}),
            };
        return (
          <WBModal
            trigger={props.trigger}
            open={props.open}
            onClose={props.onClose}
            onClick={props.onClick}
            header="Collaborative Teams"
            primaryAction={modalAction}>
            Collaborate on ML projects in a central dashboard with W&B Teams.{' '}
            {message}
          </WBModal>
        );
      }

      return (
        <Modal
          open={props.open}
          onClose={props.onClose}
          onClick={props.onClick}
          trigger={props.trigger}>
          <Header>Choose your team name</Header>
          <Modal.Content>
            <Form size="large">
              {!isOnPrem && (
                <Form.Field inline>
                  <label>Organization</label>
                  <Dropdown
                    data-test="choose-org-dropdown"
                    options={orgOptions}
                    value={selectedOrganization ?? ''}
                    direction="left"
                    onChange={handleOrganizationChange}
                    loading={loading}
                  />
                </Form.Field>
              )}
              <Form.Field inline>
                <label>Team Name</label>
                <Input
                  data-test="team-name-input"
                  name="defaultEntity"
                  placeholder="Team Name"
                  loading={loading}
                  value={teamName}
                  onChange={handleTeamNameChange}
                  icon={teamNameIcon}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {props.onClose && (
              <Button content="Cancel" onClick={props.onClose} />
            )}
            <Button
              data-test="submit-create-team"
              primary
              disabled={isSubmitDisabled || loading}
              onClick={() => {
                const variables: {
                  teamName: string;
                  organizationId?: string;
                  teamAdminUserName?: string;
                } = {
                  teamName,
                };
                if (
                  selectedOrganization != null &&
                  selectedOrganization !== 'none'
                ) {
                  variables.organizationId = selectedOrganization;
                }
                if (props.teamAdminUsername != null) {
                  variables.teamAdminUserName = props.teamAdminUsername;
                }
                createTeam({
                  variables,
                });
                props.onClose?.();
              }}>
              Create team ➞
            </Button>
          </Modal.Actions>
        </Modal>
      );
    },
    {id: 'CreateTeamModalContent'}
  );

export const CreateTeamButton: FunctionComponent<CreateTeamButtonProps> =
  makeComp(
    props => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleOpen = useCallback(() => setModalOpen(true), [setModalOpen]);
      const handleClose = useCallback(
        () => setModalOpen(false),
        [setModalOpen]
      );

      const viewer = useViewer();
      const userOrganizations = useUserOrganizationsQuery();

      // TODO(adrnswanberg | cvp): Replace this with organization limits.
      const isLocalAndTeamsAllowed = viewer?.limits.teams > 0;

      const orgOptions = useMemo(() => {
        if (viewer == null || userOrganizations.loading) {
          return [];
        }

        // Filter out the personal organizations
        const orgs =
          userOrganizations.data?.viewer?.organizations
            .filter(o => o.orgType !== OrgType.Personal)
            .map(o => ({
              text: o.name,
              value: o.id,
            })) ?? [];

        // For now, always make legacy teams an option in onprem.
        // It's only sometimes an option in local.
        const legacyTeamsOption =
          userFlags(viewer).teams_enabled ||
          envIsCloudOnprem ||
          (envIsLocal && isLocalAndTeamsAllowed)
            ? [{text: 'none', value: 'none'}]
            : [];
        return [...legacyTeamsOption, ...orgs];
      }, [
        viewer,
        userOrganizations.loading,
        userOrganizations.data,
        isLocalAndTeamsAllowed,
      ]);
      return (
        <>
          {props.renderButton?.(handleOpen) ?? (
            <Button
              data-test="create-team-button"
              className="create-team-button"
              size={props.size ?? 'tiny'}
              color={props.color}
              disabled={props.readonly === true}
              onClick={handleOpen}>
              New team
            </Button>
          )}
          <CreateTeamModal
            {...props}
            orgOptions={orgOptions}
            open={modalOpen}
            onClose={handleClose}></CreateTeamModal>
        </>
      );
    },
    {id: 'CreateTeamButton'}
  );
