// import classnames from 'classnames';
import classNames from 'classnames';
import React from 'react';
import '../../css/Text.less';
import {NameProps, pickNameProps} from '../../util/reactUtils';
import makeComp from '../../util/profiler';

interface TextProps {
  children: any;
  maxWidth?: number;
  as?: any;
}

const singleLineTextClass = 'text__single-line';

// Renders text
// If the text is given a maxSize prop it will
// overflow with no line wrap and ellipsis.
export const SingleLineText: React.SFC<TextProps & NameProps> = makeComp(
  props => {
    const passThrough = pickNameProps(props);
    const className = classNames(props.className, singleLineTextClass);
    const style = props.maxWidth ? {maxWidth: props.maxWidth} : {};
    const title = Array.isArray(props.children)
      ? props.children.join('')
      : props.children;
    const otherProps = {className, style, title};

    const El = props.as || 'span';

    return (
      <El {...passThrough} {...otherProps}>
        {props.children}
      </El>
    );
  },
  {id: 'SingleLineText'}
);
