import '../../css/RangeInput.less';

import React from 'react';
import {Button, Popup} from 'semantic-ui-react';

import NumberInput from './NumberInput';
import LegacyWBIcon from './LegacyWBIcon';
import makeComp from '../../util/profiler';

interface RangeInputProps {
  className?: string;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  stepper?: boolean;
  log?: boolean;
  logValue?: boolean;
  ignoreOutliers?: boolean;
  ignoreOutliersValue?: boolean;
  onMinChange?: (newVal?: number) => void;
  onMaxChange?: (newVal?: number) => void;
  onLogChange?: (newVal?: boolean) => void;
  onIgnoreOutliersChange?: (newVal?: boolean) => void;
}

const RangeInput: React.FC<RangeInputProps> = makeComp(
  props => {
    return (
      <div className={'range-and-options'}>
        <div className={'range-input'}>
          <NumberInput
            disabled={props.disabled}
            stepper={false}
            placeholder="min"
            value={props.minValue}
            onChange={newVal => {
              if (props.onMinChange != null) {
                props.onMinChange(newVal);
              }
            }}
          />
          <NumberInput
            className="max"
            disabled={props.disabled}
            stepper={false}
            placeholder="max"
            value={props.maxValue}
            onChange={newVal => {
              if (props.onMaxChange != null) {
                props.onMaxChange(newVal);
              }
            }}
          />
        </div>
        {props.log && (
          <Popup
            on="hover"
            position="top center"
            content="Log scale"
            inverted
            size="mini"
            trigger={
              <Button
                disabled={props.disabled}
                toggle
                icon
                active={props.logValue}
                onClick={(e, {value}) => {
                  if (props.onLogChange != null) {
                    props.onLogChange(!props.logValue);
                  }
                }}>
                <LegacyWBIcon name="log" title="" />
              </Button>
            }
          />
        )}
        {props.ignoreOutliers && (
          <Popup
            inverted
            size="mini"
            on="hover"
            position="bottom right"
            content="Ignore outliers in chart scaling"
            trigger={
              <Button
                disabled={props.disabled}
                active={props.ignoreOutliersValue}
                icon
                toggle
                onClick={(e, {value}) => {
                  if (props.onIgnoreOutliersChange != null) {
                    props.onIgnoreOutliersChange(!props.ignoreOutliersValue);
                  }
                }}>
                <LegacyWBIcon
                  name="outlier"
                  title="Ignore outliers in chart scaling"
                />
              </Button>
            }
          />
        )}
      </div>
    );
  },
  {id: 'RangeInput'}
);

export default RangeInput;
