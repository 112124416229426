import * as S from './links.styles';

import React from 'react';
import {Node, Element, Editor, Transforms, Range} from 'slate';
import {RenderElementProps, ReactEditor, useSlate} from 'slate-react';
import isUrl from 'is-url';
import {
  checkURLPointToCurrentReport,
  getURLAndSection,
} from '../../../util/url';

export interface Link extends Element {
  type: 'link';
  url: string;
}

export const isLink = (node: Node): node is Link => node.type === 'link';

export const EditorWithLinks = {
  insertLink(editor: Editor, url: string) {
    if (editor.selection) {
      EditorWithLinks.wrapLink(editor, url);
    }
  },

  isLinkActive(editor: Editor) {
    const [link] = Editor.nodes(editor, {match: n => isLink(n)});
    return !!link;
  },

  unwrapLink(editor: Editor) {
    Transforms.unwrapNodes(editor, {match: n => isLink(n)});
  },

  wrapLink(editor: Editor, url: string) {
    if (EditorWithLinks.isLinkActive(editor)) {
      EditorWithLinks.unwrapLink(editor);
    }

    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{text: url}] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, {split: true});
      Transforms.collapse(editor, {edge: 'end'});
    }
  },
};

export const LinkElement: React.FC<RenderElementProps & {element: Link}> = ({
  attributes,
  element,
  children,
}) => {
  const linkToCurrentReport = checkURLPointToCurrentReport(element.url);
  let href = element.url;
  if (linkToCurrentReport) {
    href = getURLAndSection(element.url).section ?? '';
  }

  return (
    <S.Link
      {...attributes}
      href={href}
      target={linkToCurrentReport ? undefined : '_blank'}>
      {children}
    </S.Link>
  );
};

export const withLinks = <T extends Editor>(editor: T) => {
  const {insertText, isInline, insertBreak} = editor;

  editor.isInline = element => {
    return isLink(element) ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      EditorWithLinks.wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertBreak = () => {
    const {selection} = editor;

    const linkEntry = Editor.above(editor, {match: n => isLink(n)});

    // when splitting the link, make the new text not linked
    if (selection && linkEntry) {
      Transforms.splitNodes(editor, {always: true});
      EditorWithLinks.unwrapLink(editor);

      const pointAfter = Editor.after(editor, editor.selection!);
      Transforms.select(editor, pointAfter!);

      return;
    }

    insertBreak();
  };

  // editor.insertData = data => {
  //   const text = data.getData('text/plain');

  //   if (text && isUrl(text)) {
  //     EditorWithLinks.wrapLink(editor, text);
  //   } else {
  //     insertData(data);
  //   }
  // };

  return editor;
};

export const linkNodeToLatex = (node: Link, inner: string) => {
  return `\\href{${node.url}}{${inner}}`;
};

interface InsertLinkWidgetProps {
  savedSelection: Range;
  onCancel(): void;
  onClose(): void;
}

export const InsertLinkWidget: React.FC<InsertLinkWidgetProps> = ({
  savedSelection,
  onCancel,
  onClose,
}) => {
  const editor = useSlate();

  return (
    <S.LinkInput
      placeholder="Enter link URL"
      autoFocus
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.preventDefault();
          onCancel();
        }
        if (e.key === 'Enter') {
          Transforms.select(editor, savedSelection);
          ReactEditor.focus(editor);
          if (e.currentTarget.value.trim() !== '') {
            EditorWithLinks.wrapLink(editor, fixUrl(e.currentTarget.value));
          }
          Transforms.collapse(editor, {edge: 'end'});
          e.preventDefault();
          onClose();
        }
      }}></S.LinkInput>
  );
};

const fixUrl = (url: string) => {
  if (!url.includes('://')) {
    url = 'http://' + url;
  }

  return url;
};

interface EditLinkWidgetProps {
  linkNode: Link;
  direction: 'up' | 'down';
  onClose(): void;
}

export const EditLinkWidget: React.FC<EditLinkWidgetProps> = ({
  onClose,
  linkNode,
  direction,
}) => {
  const editor = useSlate();
  const [tempUrl, setTempUrl] = React.useState(linkNode.url);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    setTempUrl(linkNode.url);
  }, [linkNode]);

  /**
   * Close when the mouse leaves the defined region, unless focused
   */
  React.useEffect(() => {
    const wrapperDOMNode = wrapperRef.current;
    if (wrapperDOMNode == null) {
      return;
    }
    const wrapperDOMRect = wrapperDOMNode.getBoundingClientRect();
    const editorDOMNode = ReactEditor.toDOMNode(editor, editor);
    const linkDOMNode = ReactEditor.toDOMNode(editor, linkNode);
    const linkDOMRect = linkDOMNode.getBoundingClientRect();

    const onMouseMove = (e: MouseEvent) => {
      if (focused) {
        return;
      }

      if (
        (e.x >= linkDOMRect.left &&
          e.x <= linkDOMRect.right &&
          e.y >= linkDOMRect.top - (direction === 'up' ? 6 : 0) &&
          e.y <= linkDOMRect.bottom + (direction === 'down' ? 6 : 0)) ||
        (e.x >= wrapperDOMRect.left &&
          e.x <= wrapperDOMRect.right &&
          e.y >= wrapperDOMRect.top &&
          e.y <= wrapperDOMRect.bottom)
      ) {
        return;
      }

      onClose();
    };

    editorDOMNode.addEventListener('mousemove', onMouseMove);

    return () => {
      editorDOMNode.removeEventListener('mousemove', onMouseMove);
    };
  });

  return (
    <S.EditLinkWrapper ref={wrapperRef}>
      <S.LinkInput
        placeholder="Enter link URL"
        value={tempUrl}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={e => {
          setTempUrl(e.target.value);
        }}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            onClose();
          }
          if (e.key === 'Enter') {
            const fixedUrl = fixUrl(e.currentTarget.value);
            Transforms.setNodes(
              editor,
              {url: fixedUrl},
              {at: ReactEditor.findPath(editor, linkNode)}
            );
            setTempUrl(fixedUrl);
            e.preventDefault();
            onClose();
          }
        }}></S.LinkInput>
      <S.RemoveLinkButton
        name="close"
        onClick={() => {
          Transforms.unwrapNodes(editor, {
            at: ReactEditor.findPath(editor, linkNode),
          });
          onClose();
        }}></S.RemoveLinkButton>
    </S.EditLinkWrapper>
  );
};
