import update from 'immutability-helper';
import React, {FC, useState, useEffect, useCallback, useRef} from 'react';
import {Mutation} from 'react-apollo';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  Input,
  List,
  Message,
  Segment,
} from 'semantic-ui-react';
import Alerts from '../components/Alerts';

import AnonymousEntityClaimer from '../components/AnonymousEntityClaimer';
import CopyableText from '../components/CopyableText';
import {CreateTeamButton} from '../components/CreateTeamModal';
import EditableField from '../components/EditableField';
import EditableImage from '../components/EditableImage';
import LegacyWBIcon from '../components/elements/LegacyWBIcon';
import GitHubIntegration from '../components/GitHubIntegration';
import ProjectAccess from '../components/ProjectAccess';
import SlackIntegration from '../components/SlackIntegration';
import StoragePercentage from '../components/StoragePercentage';
import Loader from '../components/WandbLoader';
import config from '../config';
import '../css/SettingsPage.less';
import {
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsQuery,
  useUpdateUserMutation,
} from '../generated/graphql';
import {
  DELETE_API_KEY_MUTATION,
  DeleteApiKeyMutationData,
  DeleteApiKeyMutationVariables,
  GENERATE_API_KEY_MUTATION,
  GenerateApiKeyMutationData,
  GenerateApiKeyMutationVariables,
  REDEEM_HUB_ACCESS_CODE_MUTATION,
  RedeemHubAccessCodeMutationData,
  RedeemHubAccessCodeMutationVariables,
  UPDATE_ENTITY,
  UPDATE_USER_MUTATION,
  UpdateEntityMutationData,
  UpdateEntityMutationVariables,
  UpdateUserMutationData,
  UpdateUserMutationVariables,
} from '../graphql/users';
import {withViewer} from '../graphql/users_get_viewer';
import {User} from '../types/graphql';
import {isFeatureFlagEnabled} from '../util/featureFlags';
import {flashFocus} from '../util/flash';
import {notEmpty} from '@wandb/cg/browser/utils/obj';
import {teamPage, storagePage} from '../util/urls';
import makeComp from '../util/profiler';
import {TargetBlank} from '../util/links';

interface SettingsPageProps extends RouteComponentProps {
  viewer: User; // from withViewer()
  viewerLoading: boolean; // from withViewer()
  refetch: any;
}

const SettingsPage: FC<
  SettingsPageProps & ReturnType<typeof useSettingsPageProps>
> = makeComp(
  ({viewer, viewerLoading, refetch, updateUser, testSplitEnabled, history}) => {
    const [code, setCode] = useState('');
    const [hubAccessMessage, setHubAccessMessage] = useState<string>('');
    const [initialLoad, setInitialLoad] = useState(true);

    const scrolled = useRef(false);
    const subscriptions = useOrganizationSubscriptionsQuery();

    const gpuAccess = useCallback((flags: any) => {
      return (
        flags.gpu_enabled &&
        flags.gpu_enabled > new Date().getTime() / 1000 - 3600 * 24
      );
    }, []);

    useEffect(() => {
      if (viewerLoading || !viewer) {
        return;
      }

      const qsParsed = qs.parse(window.location.search);
      if (qsParsed.focusNewTeam !== undefined) {
        // Remove all variables from URL.
        window.history.replaceState(null, '', window.location.pathname);

        const el = document.querySelector('.create-team-button');
        if (el != null) {
          flashFocus(el, {
            minSpaceFromBottom: 200,
            popping: true,
            borderRadius: 5,
          });
        }
      }

      updateUser({variables: {settingsVisited: true}});
    }, [updateUser, viewer, viewerLoading]);

    useEffect(() => {
      // After the first loading spinner, suppress future ones.
      if (initialLoad) {
        setInitialLoad(false);
      }

      if (window.location.hash && !scrolled.current) {
        const hash = window.location.hash.slice(1);
        if (hash) {
          const el = document.getElementById(hash);
          if (el != null) {
            el.scrollIntoView();
            scrolled.current = true;
          }
        }
      }
    }, [initialLoad]);

    if (viewerLoading && !viewer) {
      return <Loader />;
    }

    let viewerFlags: any = {};
    if (viewer && viewer.flags) {
      viewerFlags = JSON.parse(viewer.flags);
    }

    if (!viewer.userInfo) {
      viewer.userInfo = {
        bio: '',
        location: '',
        company: '',
        websiteUrl: '',
      };
    }

    const trialSub = subscriptions.data?.viewer?.organizations?.find(org =>
      org.subscriptions?.some(
        sub =>
          sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial
      )
    );

    const trialOrgID = trialSub?.id;
    let trialRemainingText = '';
    if (trialSub?.subscriptions[0].expiresAt) {
      const trialRemaining =
        new Date(trialSub.subscriptions[0].expiresAt + 'Z').getTime() -
        Date.now();
      const trialRemainingDays = Math.ceil(trialRemaining / (1000 * 3600 * 24));
      if (trialRemainingDays === 0) {
        trialRemainingText = ' - Your trial expires today.';
      } else if (trialRemainingDays < -1) {
        trialRemainingText =
          ' - ' + trialRemainingDays * -1 + ' days overdue in trial';
      } else if (trialRemainingDays >= 2) {
        trialRemainingText =
          ' - ' + trialRemainingDays + ' days remaining in trial';
      } else if (trialRemainingDays === 1) {
        trialRemainingText =
          ' - ' + trialRemainingDays + ' day remaining in trial';
      } else if (trialRemainingDays === -1) {
        trialRemainingText =
          ' - ' + trialRemainingDays * -1 + ' day overdue in trial';
      }
    }

    const teamEdges = viewer.teams.edges.filter(notEmpty);

    const defaultTeamEdge = teamEdges.find(e => e.node.name === viewer.entity);
    const defaultTeam = defaultTeamEdge ? defaultTeamEdge.node : undefined;

    const teamOptions = teamEdges.map(e => ({
      text: e.node.name,
      value: e.node.name,
    }));

    const userEntity = viewer.userEntity;
    const userEntityNotDefault = viewer.entity !== userEntity.name;

    return (
      <div className="settings-page">
        {viewerLoading && !initialLoad && <Loader />}
        <Container text>
          <Header as="h1">User Settings</Header>
          <Mutation<UpdateUserMutationData, UpdateUserMutationVariables>
            mutation={UPDATE_USER_MUTATION}>
            {updateUserMutation => (
              <>
                <Segment>
                  <Header as="h2">Profile</Header>
                  <EditableImage
                    profile
                    displaySize={200}
                    photoUrl={viewer.photoUrl}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          photoUrl: newVal,
                        },
                      });
                    }}
                  />
                  {testSplitEnabled && 'SPLIT ENABLED'}
                  <Divider />
                  <EditableField
                    label="Name"
                    placeholder="Geoffrey Hinton"
                    value={viewer.name}
                    updateValue={false}
                    maxLength={64}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          name: newVal,
                        },
                      });
                    }}
                  />
                  <Divider />
                  <EditableField
                    label="Bio"
                    placeholder="I teach robots how to learn."
                    multiline
                    value={viewer.userInfo.bio || ''}
                    updateValue={false}
                    maxLength={512}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          userInfo: JSON.stringify(
                            update(viewer.userInfo, {bio: {$set: newVal}})
                          ),
                        },
                      });
                    }}
                  />
                  <Divider />
                  <EditableField
                    label="Institution"
                    placeholder="University of Toronto"
                    value={viewer.userInfo.company || ''}
                    updateValue={false}
                    maxLength={64}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          userInfo: JSON.stringify(
                            update(viewer.userInfo, {company: {$set: newVal}})
                          ),
                        },
                      });
                    }}
                  />
                  <Divider />
                  <EditableField
                    label="Location"
                    placeholder="Canada"
                    value={viewer.userInfo.location || ''}
                    updateValue={false}
                    maxLength={64}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          userInfo: JSON.stringify(
                            update(viewer.userInfo, {
                              location: {$set: newVal},
                            })
                          ),
                        },
                      });
                    }}
                  />
                  <Divider />
                  <EditableField
                    label="Website"
                    placeholder="example.com"
                    type="url"
                    value={viewer.userInfo.websiteUrl || ''}
                    updateValue={false}
                    maxLength={1024}
                    save={newVal => {
                      updateUserMutation({
                        variables: {
                          userInfo: JSON.stringify(
                            update(viewer.userInfo, {
                              websiteUrl: {$set: newVal},
                            })
                          ),
                        },
                      });
                    }}
                  />
                </Segment>
                <Segment>
                  <Header as="h2">Account Details</Header>
                  <EditableField
                    readOnly
                    label="Username"
                    value={viewer.username}
                    placeholder="Username"
                  />
                  <Divider />
                  <EditableField
                    readOnly
                    label="Email"
                    value={viewer.email}
                    placeholder="Email"
                  />
                </Segment>
              </>
            )}
          </Mutation>
          <Segment id="project-defaults">
            <Header as="h2">Project Defaults</Header>
            {teamOptions.length > 1 && (
              <Mutation<UpdateUserMutationData, UpdateUserMutationVariables>
                mutation={UPDATE_USER_MUTATION}
                onCompleted={refetch}>
                {updateUserMutation => (
                  <>
                    <div className="defaults-wrapper">
                      Default location to create new projects
                      <Dropdown
                        options={teamOptions}
                        value={defaultTeam ? defaultTeam.name : undefined}
                        direction="left"
                        onChange={(_, {value}) =>
                          updateUserMutation({
                            variables: {
                              defaultEntity: value as string,
                            },
                          })
                        }
                      />
                    </div>
                    {!userEntityNotDefault && <Divider />}
                  </>
                )}
              </Mutation>
            )}
            {userEntityNotDefault && (
              <div className="defaults-wrapper">
                <p className="description">
                  Below are the default settings for new projects you create in
                  your personal account. To view your organization's settings,
                  go to your{' '}
                  <Link to={`/teams/${viewer.entity}`}>team settings page</Link>
                  .
                </p>
              </div>
            )}
            <Mutation<UpdateEntityMutationData, UpdateEntityMutationVariables>
              mutation={UPDATE_ENTITY}
              onCompleted={refetch}>
              {updateEntity => (
                <>
                  <div className="defaults-wrapper">
                    Default project privacy in your personal account
                    <ProjectAccess
                      project={{
                        readOnly: false,
                        access: userEntity.defaultAccess,
                        entity: {
                          readOnlyAdmin: false,
                          defaultAccess: userEntity.defaultAccess,
                          privateOnly: false,
                          isTeam: userEntity.isTeam,
                        },
                      }}
                      updateProjectAccess={(access: string) =>
                        updateEntity({
                          variables: {
                            entityName: userEntity.name,
                            defaultAccess: access,
                          },
                        })
                      }
                    />
                  </div>
                  <Divider />
                  <div className="defaults-wrapper">
                    Enable code saving in your personal account
                    <Checkbox
                      style={{marginLeft: 'auto'}}
                      toggle
                      checked={userEntity.codeSavingEnabled === true}
                      data-test="code-save-checkbox"
                      onChange={() =>
                        updateEntity({
                          variables: {
                            entityName: userEntity.name,
                            codeSavingEnabled: !userEntity.codeSavingEnabled,
                          },
                        }).then(refetch)
                      }
                    />
                  </div>
                </>
              )}
            </Mutation>
          </Segment>
          <Segment>
            <Header as="h2">Teams</Header>
            <List>
              {viewer.teams.edges.map((t, index) => {
                const team = t.node;
                return (
                  <React.Fragment key={t.node.id}>
                    <List.Item className="team-listitem">
                      <Image avatar src={team.photoUrl} />
                      <Link to={teamPage(team.name)}>
                        {team.name}{' '}
                        {team.organizationId === trialOrgID &&
                          trialOrgID != null &&
                          trialRemainingText}
                      </Link>
                    </List.Item>
                    {index !== viewer.teams.edges.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </List>
            <CreateTeamButton onCreate={() => refetch()} />
          </Segment>
          {!config.ENVIRONMENT_IS_PRIVATE && (
            <>
              <Segment>
                <Header as="h2">Alerts</Header>
                <p>Get notified when your runs finish or crash.</p>
                <Alerts
                  entity={viewer.userEntity}
                  entityRefetch={refetch}
                  subscriptionTypes={[
                    'EmailSubscription',
                    'SlackChannelSubscription',
                  ]}
                />
                <SlackIntegration
                  history={history}
                  entity={viewer.userEntity}
                  entityRefetch={refetch}
                  integrationReason={
                    'Configure a Slack integration so we can send you alerts.'
                  }
                />
              </Segment>
              <Segment>
                <Header as="h2">Personal GitHub integration</Header>
                <p>Connect a personal GitHub for submitting benchmark runs.</p>
                <GitHubIntegration
                  history={history}
                  entity={viewer.userEntity}
                  entityRefetch={refetch}
                />
              </Segment>
            </>
          )}
          <Segment>
            <Header as="h2">API keys</Header>
            <Mutation<DeleteApiKeyMutationData, DeleteApiKeyMutationVariables>
              mutation={DELETE_API_KEY_MUTATION}
              onCompleted={refetch}>
              {deleteApiKey => {
                return (
                  <div className="api-keys">
                    {viewer.apiKeys.edges.map((k, index) => {
                      const key = k.node;
                      return (
                        <React.Fragment key={'api-block' + index}>
                          <div className="api-key-wrapper">
                            <div className="api-key">
                              <Icon className="key-icon" name="key" />
                              <CopyableText
                                text={key.name}
                                toastText="Copied key to clipboard."
                              />
                            </div>
                            <LegacyWBIcon
                              className="delete-action"
                              name="delete"
                              onClick={() =>
                                deleteApiKey({
                                  variables: {id: k.node.id},
                                })
                              }
                            />
                          </div>
                          {index !== viewer.apiKeys.edges.length - 1 && (
                            <Divider />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              }}
            </Mutation>
            <Mutation<
              GenerateApiKeyMutationData,
              GenerateApiKeyMutationVariables
            >
              mutation={GENERATE_API_KEY_MUTATION}
              onCompleted={refetch}>
              {generateApiKey => {
                return (
                  <Button size="tiny" onClick={() => generateApiKey()}>
                    New key
                  </Button>
                );
              }}
            </Mutation>
          </Segment>
          <Segment>
            <Header as="h2">Claimed Accounts</Header>
            <p>
              Claim your anonymous accounts to assume ownership of all their
              projects and runs.
            </p>
            <AnonymousEntityClaimer viewer={viewer} refetch={refetch} />
          </Segment>
          <Segment>
            <Header as="h3">Storage</Header>
            <StoragePercentage
              available={config.MAX_BYTES}
              used={viewer.userEntity.storageBytes}
            />
            <Link to={storagePage(viewer.entity)}>
              <Button style={{marginTop: 10}}>Manage Storage</Button>
            </Link>
          </Segment>
          {!config.ENVIRONMENT_IS_PRIVATE && (
            <Mutation<
              RedeemHubAccessCodeMutationData,
              RedeemHubAccessCodeMutationVariables
            >
              mutation={REDEEM_HUB_ACCESS_CODE_MUTATION}>
              {redeemCode => {
                return (
                  <Segment>
                    <Header as="h2">Hub Access</Header>
                    <p>
                      The Hub, a hosted GPU environment, is only available for
                      our in-person classes. It's not currently provided for
                      general use.
                    </p>
                    {hubAccessMessage && (
                      <Message error>{hubAccessMessage}</Message>
                    )}
                    <Input
                      fluid
                      onChange={e => {
                        setCode(e.currentTarget.value);
                      }}
                      action={{
                        content: 'Redeem',
                        onClick: () => {
                          redeemCode({variables: {code}}).then(res => {
                            if (!res || !res.data) {
                              return;
                            }
                            const flags = JSON.parse(
                              res.data.updateUser.user.flags
                            );
                            if (gpuAccess(flags)) {
                              window.open(
                                'https://hub2.wandb.us/hub/login?gpu=true',
                                '_blank'
                              );
                            } else {
                              setHubAccessMessage('Code expired, try again.');
                            }
                          });
                        },
                      }}
                      placeholder={viewer.code || 'Enter your access code'}
                    />
                    {gpuAccess(viewerFlags) && (
                      <TargetBlank
                        className="hub-link"
                        href="https://hub2.wandb.us/hub/login?gpu=true">
                        Start a hub instance ➞
                      </TargetBlank>
                    )}
                  </Segment>
                );
              }}
            </Mutation>
          )}
          <Segment>
            {/* eslint-disable wandb/no-a-tags */}
            <Header as="h2">Policies</Header>
            <p>
              <a style={{marginRight: 24}} href="/site/privacy">
                Privacy policy
              </a>
              <a style={{marginRight: 24}} href="/site/terms">
                Terms of service
              </a>
              {!config.ENVIRONMENT_IS_PRIVATE && (
                /* eslint-disable-next-line */
                <a style={{marginRight: 24}} className="optanon-toggle-display">
                  Cookie Settings
                </a>
              )}
            </p>
            {/* eslint-enable wandb/no-a-tags */}
          </Segment>
        </Container>
      </div>
    );
  },
  {id: 'SettingsPage', memo: true}
);

const useSettingsPageProps = (props: SettingsPageProps) => {
  const {viewer, viewerLoading} = props;
  const testSplitEnabled = viewerLoading
    ? false
    : isFeatureFlagEnabled({
        featureKey: viewer.username,
        featureName: 'test_feature_flag',
      });
  const [updateUser] = useUpdateUserMutation();
  return {
    testSplitEnabled,
    updateUser,
  };
};

export default withViewer(
  makeComp(
    (props: SettingsPageProps) => {
      const settingsPageProps = useSettingsPageProps(props);
      return <SettingsPage {...props} {...settingsPageProps} />;
    },
    {id: 'SettingsPage.default'}
  )
);
