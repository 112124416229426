import React, {useCallback, useMemo, useState} from 'react';
import {Button} from 'semantic-ui-react';
import '../css/SubscribeForm.less';
import {
  useCreateCustomerSubscriptionMutation,
  useCreateStorageSubscriptionMutation,
} from '../generated/graphql';
import docUrl from '../util/doc_urls';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {captureError} from '../util/integrations';
import {CheckoutPlan} from '../util/pricing';
import makeComp from '../util/profiler';
import StripeForm, {SubmitParams} from './StripeForm';
import {urlPrefixed} from '../config';
import {TargetBlank} from '../util/links';

type SubscribeFormProps = {
  plan: CheckoutPlan;
  seats: number;
  storagePlanID: string;
  trackingPlanID: string;
  pricePerPeriod: string;
  onSubscriptionCompleted: () => void;
};

const SubscribeForm: React.FC<SubscribeFormProps> = makeComp(
  props => {
    const {
      plan,
      seats,
      storagePlanID,
      trackingPlanID,
      pricePerPeriod,
      onSubscriptionCompleted,
    } = props;

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [createCustomerSubscriptionMutation] =
      useCreateCustomerSubscriptionMutation({
        context: {...propagateErrorsContext(), ...doNotRetryContext()},
      });

    const [createStorageSubscriptionMutation] =
      useCreateStorageSubscriptionMutation({
        context: {...propagateErrorsContext(), ...doNotRetryContext()},
      });

    const trialPeriodEnd = useMemo(() => {
      const trialEnd = new Date();
      trialEnd.setDate(trialEnd.getDate() + 30);
      return trialEnd.toLocaleDateString();
    }, []);

    const track = useCallback(
      (trial: boolean) => {
        window.analytics.track('Subscribe purchase button clicked', {
          plan: plan.id,
          units: seats,
          trial,
        });
      },
      [plan.id, seats]
    );

    const submit = useCallback(
      async (
        {stripe, orgName, custoEmail, paymentMethodID, setErrMsg}: SubmitParams,
        trial: boolean
      ): Promise<boolean> => {
        const subResult = await subscribe();
        if (subResult == null) {
          return false;
        }

        const {orgID: organizationID, subscription} = subResult;
        const paymentIntent = subscription.latest_invoice.payment_intent;
        const clientSecret = paymentIntent?.client_secret;
        const paymentIntentStatus = paymentIntent?.status;
        const needsPayment =
          ['requires_action', 'requires_payment_method'].indexOf(
            paymentIntentStatus
          ) !== -1;

        if (needsPayment) {
          const paymentConfirmed = await confirmPayment(clientSecret);
          if (!paymentConfirmed) {
            return false;
          }
        }

        const storageSubscription = await subscribeStorage(organizationID);
        if (storageSubscription == null) {
          return false;
        }

        const paymentIntentStorage =
          storageSubscription.latest_invoice.payment_intent;
        const clientSecretStorage = paymentIntentStorage?.client_secret;
        const paymentIntentStatusStorage = paymentIntentStorage?.status;
        const needsPaymentStorage =
          ['requires_action', 'requires_payment_method'].indexOf(
            paymentIntentStatusStorage
          ) !== -1;

        if (needsPaymentStorage) {
          const paymentConfirmedStorage = await confirmPaymentStorage(
            clientSecretStorage
          );
          if (!paymentConfirmedStorage) {
            return false;
          }
        }

        onSubscriptionCompleted();
        return true;

        // HELPER FUNCTIONS

        async function subscribe(): Promise<{
          orgID: string;
          subscription: {
            latest_invoice: any;
          };
        } | null> {
          if (paymentMethodID == null) {
            throw new Error(`missing paymentMethodID`);
          }

          try {
            const createSubResult = await createCustomerSubscriptionMutation({
              variables: {
                newOrganizationName: orgName,
                planId: plan.id,
                paymentMethod: paymentMethodID,
                trial,
                quantity: seats,
              },
            });

            const sub =
              createSubResult.data?.createCustomerSubscription?.subscription;
            const orgID =
              createSubResult.data?.createCustomerSubscription?.organizationId;

            if (
              createSubResult.errors != null ||
              sub == null ||
              orgID == null
            ) {
              onError({
                err: "Unknown error confirming subscription. Exception didn't throw but success was not reported.",
                op: 'handleSubscribe-unknown',
                userFacingErrorMsg:
                  'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
              });
              return null;
            }

            return {orgID, subscription: sub};
          } catch (err) {
            const errMsg =
              extractErrorMessageFromApolloError(err) ?? err.message;
            onError({
              err,
              op: 'createSubResult',
              extra: {
                reason: errMsg,
              },
              analyticsEvent: 'SubscribeForm-createSubResult',
              userFacingErrorMsg: `Error processing ${
                trial ? 'trial' : 'payment'
              }: ${errMsg}`,
            });
            return null;
          }
        }

        async function subscribeStorage(orgID: string): Promise<{
          latest_invoice: any;
        } | null> {
          try {
            const createStorageSubResult =
              await createStorageSubscriptionMutation({
                variables: {
                  storagePlanId: storagePlanID,
                  trackingPlanId: trackingPlanID,
                  paymentMethod: paymentMethodID,
                  organizationId: orgID,
                  trial,
                },
              });

            const storageSub =
              createStorageSubResult.data?.createStorageSubscription
                ?.subscription;

            if (createStorageSubResult.errors != null || storageSub == null) {
              onError({
                err: "Unknown error confirming subscription. Exception didn't throw but success was not reported.",
                op: 'handleStorageSubscribe-unknown',
                userFacingErrorMsg:
                  'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
              });
              return null;
            }

            return storageSub;
          } catch (err) {
            const errMsg =
              extractErrorMessageFromApolloError(err) ?? err.message;
            onError({
              err,
              op: 'createStorageSubResult',
              extra: {
                reason: errMsg,
              },
              analyticsEvent: 'SubscribeForm-createStorageSubResult',
              userFacingErrorMsg: `Error processing storage subscription ${
                trial ? 'trial' : 'payment'
              }: ${errMsg}`,
            });
            return null;
          }
        }

        async function confirmPayment(secret: string): Promise<boolean> {
          try {
            const result = await stripe.confirmCardPayment(secret);

            if (result.error != null) {
              onError({
                err: result.error.message!,
                op: 'handleCardPayment',
                userFacingErrorMsg: `We created your organization, but had trouble confirming your payment: ${result.error.message}`,
              });
              return false;
            }

            return true;
          } catch (err) {
            onError({
              err,
              op: 'handleCardPayment',
              userFacingErrorMsg: `We created your organization, but had trouble confirming your payment: ${err}`,
            });
            return false;
          }
        }

        async function confirmPaymentStorage(secret: string): Promise<boolean> {
          try {
            const result = await stripe.confirmCardPayment(secret);

            if (result.error != null) {
              onError({
                err: result.error.message!,
                op: 'handleCardPaymentStorage',
                userFacingErrorMsg: `We had trouble confirming your payment: ${result.error.message}`,
              });
              return false;
            }

            return true;
          } catch (err) {
            onError({
              err,
              op: 'handleCardPaymentStorage',
              userFacingErrorMsg: `We had trouble confirming your payment: ${err}`,
            });
            return false;
          }
        }

        type ErrOpts = {
          err: Error | string;
          op: string;
          userFacingErrorMsg: string;
          analyticsEvent?: string;
          extra?: {[key: string]: any};
        };
        function onError(opts: ErrOpts) {
          const extra = {
            email: custoEmail,
            storageID: storagePlanID,
            trackingID: trackingPlanID,
            plan: plan.id,
            units: seats,
            trial,
            ...opts.extra,
          };
          captureError(opts.err, `SubscribeForm-${opts.op}`, {
            extra,
          });
          if (opts.analyticsEvent != null) {
            window.analytics.track(opts.analyticsEvent, extra);
          }
          setErrMsg(opts.userFacingErrorMsg);
        }
      },
      [
        storagePlanID,
        trackingPlanID,
        plan.id,
        seats,
        onSubscriptionCompleted,
        createCustomerSubscriptionMutation,
        createStorageSubscriptionMutation,
      ]
    );

    return (
      <StripeForm
        renderButtons={({submitWrapper, formDisabled, submitting}) => (
          <>
            <Button
              className="subscribe-button"
              color="blue"
              onClick={() => {
                if (!showConfirmation) {
                  setShowConfirmation(true);
                } else {
                  track(true);
                  submitWrapper(p => submit(p, true));
                }
              }}
              disabled={formDisabled}>
              {submitting
                ? 'Processing...'
                : showConfirmation
                ? 'Confirm'
                : 'Try free for 30 days'}
            </Button>
            {showConfirmation ? (
              <div className="checkout-fineprint">
                {`By clicking confirm, you authorize Weights & Biases to charge
              you $${pricePerPeriod} when your trial ends on ${trialPeriodEnd},
              and then again on the same day every ${plan.billingInterval}.
              If you do not wish to be charged, you may return to this page at any
              time and cancel your subscription. Weights & Biases will email you
              trial expiration reminders 7 and 3 days before your trial ends.`}
              </div>
            ) : (
              <>
                <div>or</div>
                <Button
                  className="subscribe-button"
                  onClick={() => {
                    track(false);
                    submitWrapper(p => submit(p, false));
                  }}
                  disabled={formDisabled}>
                  {submitting ? 'Processing...' : 'Subscribe now'}
                </Button>
              </>
            )}
          </>
        )}
      />
    );
  },
  {id: 'SubscribeForm', memo: true}
);

export default SubscribeForm;

export const SubscriptionSuccess: React.FC = makeComp(
  () => {
    return (
      <div className="checkout pricing-panel confirmation">
        <div className="header">
          <div className="panel-title">Thank you for subscribing!</div>
        </div>
        <div className="checkout-content">
          <div className="checkout-info">
            Learn more about teams in the{' '}
            <TargetBlank href={docUrl.root}>docs</TargetBlank>, or reach out to
            us at support@wandb.com for help.
          </div>
          <div className="checkout-info">
            You can now create private teams. Go to your settings page to get
            started.
          </div>
          <Button
            className="onboarding-button"
            onClick={() => {
              window.open(urlPrefixed('/settings?focusNewTeam'));
            }}>
            Settings page →
          </Button>
        </div>
      </div>
    );
  },
  {id: 'SubscriptionSuccess', memo: true}
);
