import React from 'react';
import {useDropzone} from 'react-dropzone';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import * as Panels from '../util/panels';
import * as Query from '../util/queryts';
import {useUploadMarkdownImage} from '../util/images';
import InlineMarkdownEditor from './elements/InlineMarkdownEditor';
import * as PanelTypes from '../state/views/panel/types';
import {useSelector} from '../state/hooks';
import {DEFAULT_MARKDOWN_BLOCK_BODY} from '../util/report';
import makeComp from '../util/profiler';

const PANEL_TYPE = 'Markdown Panel';

export interface MarkdownConfig {
  value: string;
}

// We assume MarkdownPanel will never be mounted by AutoPanel as we
// assume props.panelRef is present.  PanelCompRedux always passes it.
interface PanelProps {
  panelRef?: PanelTypes.Ref;
}

type MarkdownPanelProps = Panels.PanelProps<MarkdownConfig> & PanelProps;

const MarkdownPanel: React.FC<MarkdownPanelProps> = makeComp(
  props => {
    const [cursorIdx, setCursorIdx] = React.useState<number>();
    const view = useSelector(state =>
      props.panelRef == null ? null : state.views.views[props.panelRef.viewID]
    );
    const {uploadImages, uploadState, setUploadState} = useUploadMarkdownImage(
      view?.id || null
    );

    const {getRootProps, getInputProps, open} = useDropzone({
      onDrop: uploadImages,
      disabled: view == null,
      accept: ['image/*'],
      noDragEventsBubbling: true,
    });

    return (
      <div
        style={{height: '100%', outline: 'none'}}
        {...getRootProps({onClick: e => e.stopPropagation()})}>
        <InlineMarkdownEditor
          className="panel__inline-markdown"
          readOnly={props.readOnly ? props.readOnly : false}
          saveText={false}
          key="report-page"
          placeholder={DEFAULT_MARKDOWN_BLOCK_BODY}
          serverText={props.config.value}
          onBlur={text =>
            props.updateConfig({
              ...props.config,
              value: text,
            })
          }
          onChange={text => {
            setUploadState(undefined);
            props.updateConfig({
              ...props.config,
              value: text,
            });
          }}
          onPaste={uploadImages}
          onSelect={setCursorIdx}
          onHelpSelect={open}
          cursorIdx={cursorIdx}
          uploadState={uploadState}
          enableMarkdown
          autosave
          minRows={5}
          onContentHeightChange={props.onContentHeightChange}
        />
        <input {...getInputProps()} />
      </div>
    );
  },
  {id: 'MarkdownPanel'}
);

export default MarkdownPanel;

const transformQuery = (query: Query.Query): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, MarkdownConfig> = {
  type: PANEL_TYPE,
  Component: MarkdownPanel,
  transformQuery,
  noEditMode: true,
  icon: 'panel-text',
};
