import '../../css/MetricsPicker.less';

import _ from 'lodash';

import React, {FC} from 'react';
import ModifiedDropdown from './ModifiedDropdown';
import {Button, Input, Popup} from 'semantic-ui-react';
import LegacyWBIcon from './LegacyWBIcon';

import {RunHistoryKeyType} from '../../types/run';
import {prettifyMetricName} from '../../util/plotHelpers';
import makeComp from '../../util/profiler';

interface MetricsPickerProps {
  value?: string[];
  regexValue?: string;
  options: string[];
  keyTypes?: {
    [key: string]: RunHistoryKeyType;
  };
  regexInput: boolean;

  onMetricsChange: (newMetrics: string[]) => void;
  onRegexChange: (newRegex: string) => void;
}

const MetricsPicker: FC<MetricsPickerProps> = makeComp(
  (props: MetricsPickerProps) => {
    const [regexInput, setRegexInput] = React.useState(props.regexInput);
    const [regexValue, setRegexValue] = React.useState(props.regexValue ?? '');
    const {options, keyTypes} = props;

    const onRegexChangeDebounced = _.debounce((value: string) => {
      props.onRegexChange(value);
    }, 500);

    const score = (opt: string) => {
      if (opt.startsWith('system')) {
        // put the system metrics last
        return -2;
      } else if (keyTypes != null && keyTypes[opt] !== 'number') {
        // put the histograms next to last
        return -1;
      } else {
        // put the nice regular metrics first
        return 0;
      }
    };
    options.sort((a, b) => {
      return score(b) - score(a);
    });

    const dropdownOptions = options.map(k => ({
      icon:
        keyTypes != null
          ? keyTypes[k] === 'number'
            ? 'hashtag'
            : 'list'
          : undefined,
      text:
        keyTypes == null || keyTypes[k] === 'number'
          ? prettifyMetricName(k)
          : prettifyMetricName(k) + ' (Histogram)',
      value: k,
      key: k,
    }));

    // Set a nice default metric if possible
    if (props.value == null && options.length > 0) {
      props.onMetricsChange([options[0]]);
    }

    return (
      <div className="metrics-picker">
        {regexInput ? (
          <Input
            style={{flexGrow: 1}}
            className="with-button regex-input"
            placeholder="Select metrics with a regular expression"
            value={regexValue}
            onChange={(event, {value}) => {
              setRegexValue(value);
              onRegexChangeDebounced(value);
            }}
          />
        ) : (
          <ModifiedDropdown
            data-test="y-axis"
            lazyLoad
            className="with-button regex-dropdown"
            placeholder="Select metrics to visualize"
            multiple
            search
            selection
            value={props.value}
            options={dropdownOptions}
            onChange={(event, {value}) => {
              if (_.isArray(value) && value.every(v => typeof v === 'string')) {
                props.onMetricsChange(value as string[]);
              }
            }}
          />
        )}
        <Popup
          inverted
          size="mini"
          on="hover"
          position="top right"
          content="Regular Expression"
          trigger={
            <Button
              icon
              toggle
              className={regexInput ? ' active' : ''}
              onClick={e => setRegexInput(!regexInput)}>
              <LegacyWBIcon name="regex" />
            </Button>
          }></Popup>
      </div>
    );
  },
  {id: 'MetricsPicker', memo: true}
);

export default MetricsPicker;
