import React from 'react';
import {useMemo} from 'react';
import {VisualizationSpec} from 'react-vega';
import {useInView} from 'react-intersection-observer';
import {useColorNode} from './panellib/libcolors';
import CustomPanelRenderer from '../Vega3/CustomPanelRenderer';
import {useGatedValue} from '../../state/hooks';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';

const inputType = {
  type: 'list' as const,
  objectType: {
    type: 'union' as const,
    members: ['none' as const, 'string' as const],
  },
};

type PanelStringHistogramProps = Panel2.PanelProps<typeof inputType>;

/* eslint-disable no-template-curly-in-string */

const HISTO_SPEC: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  description: 'A simple histogram',
  data: {
    name: 'wandb',
  },
  transform: [
    {
      aggregate: [
        {
          op: 'count',
          as: 'value_count',
        },
      ],
      groupby: ['value'],
    },
    {
      window: [{op: 'row_number', as: 'value_rank'}],
      sort: [
        {
          field: 'value_count',
          order: 'descending',
        },
      ],
    },
    {filter: `datum.value_rank < 100`},
  ],
  title: '${string:title}',
  mark: {type: 'bar', tooltip: {content: 'data'}},
  encoding: {
    y: {
      type: 'nominal',
      field: 'value',
      axis: {
        title: null,
      },
    },
    x: {
      field: 'value_count',
      type: 'quantitative',
    },
    opacity: {value: 0.7},
  },
};

const HISTO_SPEC_COLORED: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  description: 'A simple histogram',
  data: {
    name: 'wandb',
  },
  transform: [
    {
      aggregate: [
        {
          op: 'count',
          as: 'value_count',
        },
      ],
      groupby: ['value', 'color'],
    },
    {
      window: [{op: 'row_number', as: 'value_rank'}],
      sort: [
        {
          field: 'value_count',
          order: 'descending',
        },
      ],
    },
    {filter: `datum.value_rank < 100`},
  ],
  title: '${string:title}',
  mark: {type: 'bar', tooltip: {content: 'data'}},
  encoding: {
    y: {
      type: 'nominal',
      field: 'value',
      axis: {
        title: null,
      },
    },
    x: {
      field: 'value_count',
      type: 'quantitative',
    },
    color: {
      type: 'nominal',
      field: 'color',
      legend: false as any,
      scale: {range: {field: 'color'}},
    },
    opacity: {
      value: 1,
    },
  },
};

const PanelStringHistogram: React.FC<PanelStringHistogramProps> = props => {
  // const domRef = useRef<HTMLDivElement>(null);
  const colorNode = useColorNode(props.input.path);
  const colorNodeValue = CGReact.useNodeValue(colorNode);
  const isColorable = colorNode.nodeType !== 'void';
  const {ref, inView} = useInView();
  const hasBeenOnScreen = useGatedValue(inView, o => o);
  const nodeValueQuery = CGReact.useNodeValue(props.input.path);
  const data = useMemo(() => {
    if (nodeValueQuery.loading || (isColorable && colorNodeValue.loading)) {
      return [];
    }
    if (!isColorable) {
      return nodeValueQuery.result.map(v => ({value: v}));
    } else {
      return nodeValueQuery.result.map((v, ndx) => ({
        value: v,
        color: colorNodeValue.result[ndx] ?? '#94aecb',
      }));
    }
  }, [nodeValueQuery, isColorable, colorNodeValue]);
  return (
    <div ref={ref} style={{width: 250, maxHeight: 160}}>
      {hasBeenOnScreen ? (
        <>
          <CustomPanelRenderer
            spec={isColorable ? HISTO_SPEC_COLORED : HISTO_SPEC}
            loading={nodeValueQuery.loading}
            slow={false}
            data={data}
            userSettings={{fieldSettings: {}, stringSettings: {title: ''}}}
          />
        </>
      ) : (
        <div
          style={{
            backgroundColor: '#eee',
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'string-histogram',
  Component: PanelStringHistogram,
  inputType,
};
