import React from 'react';
import '../css/LinkButton.less';
import makeComp from '../util/profiler';

const LinkButton: React.FC<React.HTMLProps<HTMLSpanElement>> = makeComp(
  props => {
    return (
      <span
        tabIndex={0}
        role="button"
        {...props}
        className={'link-button ' + props.className || ''}></span>
    );
  },
  {id: 'LinkButton'}
);

export default LinkButton;
