import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {fragments, ViewMetadata} from '../../graphql/views';

import {useQuery} from './query';
import {Graph} from '../../types/graphql';

///// Query definition

export const REPORT_TABLE_QUERY = gql`
  query ReportTable($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      readOnly
      reportDrafts: allViews(viewType: "runs/draft") {
        edges {
          node {
            ...ViewFragmentMetadata
          }
        }
      }
      reports: allViews(viewType: "runs") {
        edges {
          node {
            ...ViewFragmentMetadata
          }
        }
      }
    }
  }
  ${fragments.viewMetadata}
`;

export interface ProjectData {
  id: string;
  readOnly: boolean;
  reportDrafts: Graph<ViewMetadata>;
  reports: Graph<ViewMetadata>;
}

export interface ReportTableQueryData {
  project: ProjectData;
}

export function useReportTableQuery(
  variables: Generated.ReportTableQueryVariables
) {
  return useQuery<ReportTableQueryData, typeof variables>(REPORT_TABLE_QUERY, {
    fetchPolicy: 'network-only',
    variables,
  });
}
