import * as _ from 'lodash';
import {RunHistoryKeyInfo, RunHistoryKeyType} from '../types/run';
import * as Panels from '../util/panels';
import {GRID_COLUMN_COUNT} from './panelbankGrid';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelTypes from '../state/views/panel/types';
import {DragRef, DragData} from '../containers/DragDrop';
import {ReactElement} from 'react';
import * as PanelsUtil from './panels';
import {
  PartRefFromObjSchema,
  PartRefFromType,
  WholeFromTypeWithRef,
} from '../state/views/types';
import {groupBy, isEqual} from 'lodash';
import {weavePanelForSummaryTableKey} from '../components/PanelWeave';
import {RunsLinePlotConfig} from '../components/PanelRunsLinePlot';
import * as RunHelpers from '../util/runhelpers';
import {mediaStrings} from '../types/media';
import {ReportSpecVersion} from './report';
import {VegaPanelConfig} from '../components/PanelVega';
import {ID} from '@wandb/cg/browser/utils/string';
import {QueryField} from './vega3';
import {toIncludesObj} from '@wandb/cg/browser/utils/obj';
import {isReservedKey} from './runs';

import * as PanelSettings from '../util/panelsettings';

export const PANEL_BANK_CHARTS_NAME = 'Charts';
export const PANEL_BANK_TABLES_NAME = 'Tables';
export const PANEL_BANK_MEDIA_NAME = 'Media';
export const PANEL_BANK_SYSTEM_NAME = 'System';
export const PANEL_BANK_HIDDEN_SECTION_NAME = 'Hidden Panels';
export const PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME = 'Custom Visualizations';
export const PANEL_BANK_CUSTOM_CHARTS_NAME = 'Custom Charts';

export const DEFAULT_PANEL_SIZE = 152;
export const PANEL_BANK_PADDING = 16;

function getV0GridColumnCount() {
  return Panels.PANEL_GRID_WIDTH;
}

export enum PanelBankConfigState {
  Init,
  Ready,
}

export enum OrganizationPrefix {
  FirstPrefix = 1,
  LastPrefix,
}

export enum SectionPanelSorting {
  None,
  Manual,
  Alphabetical,
}

export interface PanelBankSettings {
  autoOrganizePrefix?: OrganizationPrefix;
  showEmptySections: boolean; // if false, we'll hide sections with zero panels
  defaultMoveToSectionName?: string; // the name of the default section for the "Move panel to..." feature
  sortAlphabetically: boolean; // sort panels alphabetically
}

export interface PanelBankConfig {
  state: PanelBankConfigState;
  sections: ReadonlyArray<PanelBankSectionConfig>;
  settings: PanelBankSettings;
}

export const EMPTY_PANEL_BANK_SETTINGS: PanelBankSettings = {
  autoOrganizePrefix: OrganizationPrefix.LastPrefix,
  showEmptySections: false,
  sortAlphabetically: false,
};

export const EMPTY_PANEL_BANK_CONFIG: PanelBankConfig = {
  state: PanelBankConfigState.Init,
  settings: EMPTY_PANEL_BANK_SETTINGS,
  // default includes a 'Hidden Panels' section
  sections: [
    {
      ...getDefaultPanelSectionConfig(),
      name: 'Hidden Panels',
    },
  ],
};

export const EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT: PanelBankSectionConfig =
  getDefaultPanelSectionConfig({name: 'Report Panels', type: 'grid'});

export interface PanelBankSectionConfig {
  name: string;
  panels: ReadonlyArray<Panels.LayedOutPanel>;
  isOpen: boolean;
  flowConfig: PanelBankFlowSectionConfig;
  type: 'grid' | 'flow';
  sorted: SectionPanelSorting;
  localPanelSettings: PanelSettings.Settings;
}

export interface PanelBankFlowSectionConfig {
  snapToColumns: boolean;
  columnsPerPage: number;
  rowsPerPage: number;
  gutterWidth: number;
  boxWidth: number;
  boxHeight: number;
}

// These are shared by PanelBankFlowSection and PanelBankGridSection
export interface PanelBankSectionComponentSharedProps {
  readOnly?: boolean;
  panelBankWidth: number;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  activePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  inactivePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  addVisButton?: ReactElement;
  // HAXX: this is used to have the panel bank section rerender
  // when the panel settings change
  panelSettings?: PanelSettings.Settings;
  renderPanel(
    panelRef: PanelTypes.Ref,
    onContentHeightChange?: (h: number) => void
  ): JSX.Element;
  movePanelBetweenSections(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionConfigTypes.Ref,
    toSectionRef: PanelBankSectionConfigTypes.Ref,
    toIndex?: number,
    inactivePanelRefIDs?: Set<string>
  ): void;
}

export function isPanel(ref: DragRef | null): ref is PanelTypes.Ref {
  return ref != null && ref.type === 'panel';
}

export function isHidden(
  prevKeysSet: Set<string>,
  key: string,
  panelBankConfigState: PanelBankConfigState
): boolean {
  return (
    prevKeysSet.has(key) && panelBankConfigState !== PanelBankConfigState.Init
  );
}

export function isPanelBankSection(
  ref: DragRef | null
): ref is PanelBankSectionConfigTypes.Ref {
  return ref != null && ref.type === 'panel-bank-section-config'; // o.panels != null;
}

export function isDraggingWithinSection(
  panelBankSectionConfigRef: PartRefFromObjSchema<PanelBankSectionConfigTypes.PanelBankSectionConfigObjSchema>,
  dragData: DragData | null
) {
  return (
    dragData != null &&
    _.isEqual(dragData.fromSectionRef, panelBankSectionConfigRef)
  );
}

interface DefaultNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  keyType: string;
  defaultSection: string;
  defaultXAxis?: string;
}

interface DefaultVegaPanelSpec {
  type: 'legacy-vega';
  keyType: string;
  config: VegaPanelConfig;
  defaultSection: string;
}

interface FoundNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  currentRef: PartRefFromType<'panel'>;
  defaultXAxis?: string | null;
}

interface FoundVegaPanelSpec {
  type: 'legacy-vega';
  viz: VegaPanelConfig;
  currentRef: PartRefFromType<'panel'>;
}

interface DefaultPanelSpecs {
  [key: string]: DefaultNonVegaPanelSpec | DefaultVegaPanelSpec;
}

interface FoundPanelSpecs {
  [key: string]: FoundNonVegaPanelSpec | FoundVegaPanelSpec;
}

interface AddPanel {
  type: 'add';
  spec: DefaultNonVegaPanelSpec | DefaultVegaPanelSpec | APIAddedPanelSpec;
}

interface UpdateNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  defaultXAxis?: string;
}

interface UpdateVegaPanelSpec {
  type: 'legacy-vega';
  config: VegaPanelConfig;
}

interface UpdatePanel {
  type: 'update';
  currentRef: PartRefFromType<'panel'>;
  spec: UpdateNonVegaPanelSpec | UpdateVegaPanelSpec | APIAddedPanelSpec;
}

export interface PanelBankDiff {
  [key: string]: AddPanel | UpdatePanel;
}

export interface APIAddedPanelBankDiff {
  [key: string]: {
    type: 'update';
    currentRef: PartRefFromType<'panel'>;
    spec: APIAddedPanelSpec;
  };
}

interface FoundAPIAddedPanelSpecs {
  [key: string]: {
    config: Panels.PanelConfig;
    currentRef: PartRefFromType<'panel'>;
    viewType: string;
    type: 'api-added-panel';
  };
}

interface APIAddedPanelSpec {
  config: Panels.PanelConfig;
  viewType:
    | 'Confusion Matrix'
    | 'Data Frame Table'
    | 'Media Browser'
    | 'Run History Line Plot'
    | 'Multi Run Table'
    | 'Markdown Panel'
    | 'Scalar Chart'
    | 'Vega'
    | 'Vega2'
    | 'Vega3'
    | 'Scatter Plot'
    | 'Parallel Coordinates Plot'
    | 'Run Comparer'
    | 'Code Comparer'
    | 'Bar Chart'
    | 'Parameter Importance';
  type: 'api-added-panel';
  keyType?: string;
  defaultSection: string;
}

interface APIAddedPanelSpecs {
  [key: string]: APIAddedPanelSpec;
}

export const keyInfoToAddedPanels = (keyViz: {[key: string]: any}) => {
  const addedPanels: APIAddedPanelSpecs = {};
  for (const viz of Object.keys(keyViz)) {
    const panel_type = keyViz[viz].panel_type;
    const v = keyViz[viz].panel_config;
    if (v != null) {
      addedPanels[viz] = {
        type: 'api-added-panel',
        config: {...v},
        defaultSection: PANEL_BANK_CUSTOM_CHARTS_NAME,
        viewType: panel_type,
      };
    }
  }
  return addedPanels;
};

// This gets called a lot. Keep it fast.
export const keyInfoToDefaultPanelSpecs = (
  keyInfo: RunHistoryKeyInfo,
  keyViz: {[key: string]: any},
  isSingleRun: boolean,
  panelBankSettings: PanelBankSettings,
  WBConfig?: Array<{[key: string]: any}>
) => {
  // for some available metrics, create a flattened mapping
  // of expected panel keys -> metrics

  // the resulting panel bank should contain ALL of the panels described here
  // (even if some are in the Hidden Panels section)
  // (this excludes Charlemagne panels added through wandbs _add_panel api)
  const keyTypes = RunHelpers.keyTypes(keyInfo);
  const defaultPanelSpecs: DefaultPanelSpecs = {};

  let {systemMetrics, remainingMetrics} = groupBy(
    Object.keys(keyInfo.keys),
    metric =>
      metric.startsWith('system') ? 'systemMetrics' : 'remainingMetrics'
  );
  systemMetrics = systemMetrics ?? [];
  remainingMetrics = remainingMetrics ?? [];

  for (const template of Object.values(Panels.systemPanelTemplates)) {
    const {match, noMatch} = groupBy(systemMetrics, metric =>
      metric.match(template.regex) ? 'match' : 'noMatch'
    );
    if (match != null && match.length > 0) {
      if (!isSingleRun) {
        defaultPanelSpecs[template.key] = {
          type: 'default-panel',
          metrics: match,
          keyType: 'number',
          defaultSection: PANEL_BANK_SYSTEM_NAME,
        };
      }
    }

    systemMetrics = noMatch || [];
  }

  remainingMetrics = [...systemMetrics, ...remainingMetrics];
  const definedMetrics: Panels.MetricsDict =
    WBConfig != null && WBConfig[0] != null && WBConfig[0].m != null
      ? Panels.findDefinedMetrics(WBConfig[0].m)
      : {};

  // the remaining metrics should all correspond to single-metric default
  // panels
  for (const metric of remainingMetrics) {
    if (['_step', '_runtime', '_timestamp'].includes(metric)) {
      continue;
    }

    const keyType = keyTypes[metric];

    if (keyViz[metric]) {
      // skip cases where the visualization is defined with a config, since it is a Charlemagne
      // panel with a key identical to a metric
      if ('panel_config' in keyViz[metric]) {
        continue;
      }
      const {id: storedPanelDefId, ...props} = keyViz[metric];
      const panelDefId =
        storedPanelDefId != null &&
        !(
          storedPanelDefId.startsWith('builtin:') ||
          storedPanelDefId.startsWith('lib:')
        )
          ? `lib:${storedPanelDefId}`
          : storedPanelDefId;
      defaultPanelSpecs[metric] = {
        type: 'legacy-vega',
        config: {
          ...props,
          panelDefId,
        },
        keyType,
        defaultSection: PANEL_BANK_MEDIA_NAME,
      };
      continue;
    }

    const isMedia = (mediaStrings as string[]).includes(keyType);
    const isChart = ['number', 'histogram'].includes(keyType);
    let defaultSection = isMedia
      ? ['table-file', 'partitioned-table', 'joined-table'].includes(keyType)
        ? PANEL_BANK_TABLES_NAME
        : PANEL_BANK_MEDIA_NAME
      : PANEL_BANK_CHARTS_NAME;

    if (isMedia || isChart) {
      // keys containing certain separators get grouped in sections named after the prefix
      const prefix = getPrefixSectionName(
        metric,
        panelBankSettings.autoOrganizePrefix
      );

      if (prefix != null) {
        defaultSection = prefix;
      }
    }

    if (!isMedia && defaultSection === 'system') {
      defaultSection = PANEL_BANK_SYSTEM_NAME;
    }

    defaultPanelSpecs[metric] = {
      type: 'default-panel',
      metrics: [metric],
      keyType: keyTypes[metric],
      defaultSection,
      defaultXAxis: definedMetrics[metric],
    };
  }
  return defaultPanelSpecs;
};

function getPrefixSectionName(
  metric: string,
  splitType?: OrganizationPrefix
): string | null {
  const sepPos =
    splitType === OrganizationPrefix.LastPrefix
      ? metric.lastIndexOf('/')
      : metric.indexOf('/');
  if (sepPos === -1) {
    return null;
  }
  return metric.slice(0, sepPos);
}

export const panelBankConfigToFoundPanelSpecs = (
  panelBankConfig: PanelBankConfig
) => {
  // flatten the panel bank config into a panel key -> metrics mapping:

  const foundPanelSpecs: FoundPanelSpecs = {};
  for (const section of panelBankConfig.sections) {
    for (const panel of section.panels) {
      const panelCast = panel as WholeFromTypeWithRef<'panel'>;
      const key = Panels.getKey(panelCast);
      if (!key) {
        continue;
      }

      if (
        panelCast.viewType === 'Media Browser' &&
        panelCast.config.mediaKeys &&
        panelCast.config.mediaKeys.length === 1
      ) {
        const mediaKey = panelCast.config.mediaKeys[0];

        foundPanelSpecs[key] = {
          type: 'default-panel',
          metrics: [mediaKey],
          currentRef: panelCast.ref,
        };

        continue;
      }

      if (panelCast.viewType === 'Weave') {
        foundPanelSpecs[key] = {
          type: 'default-panel',
          metrics: [key],
          currentRef: panelCast.ref,
        };

        continue;
      }

      if (panelCast.viewType === 'Run History Line Plot') {
        if (key) {
          // multi-metric default panels always have an explicit key:
          foundPanelSpecs[key] = {
            type: 'default-panel',
            metrics: panelCast.config.metrics || [],
            currentRef: panelCast.ref,
            defaultXAxis: panelCast.config.startingXAxis,
          };
        }
      }

      if (panelCast.viewType === 'Vega') {
        if (key) {
          foundPanelSpecs[key] = {
            type: 'legacy-vega',
            viz: panelCast.config,
            currentRef: panelCast.ref,
          };
        }
      }

      // any *other* panels (i.e. panels with more than one metric and no
      // explicit key) are assumed to be custom
    }
  }

  return foundPanelSpecs;
};

export const expectedAPIAddedPanelSpecsFromPanelBankConfigs = (
  panelBankConfig: PanelBankConfig,
  addedPanelSpecs: APIAddedPanelSpecs
) => {
  const foundPanelConfigs: FoundAPIAddedPanelSpecs = {};
  for (const section of panelBankConfig.sections) {
    for (const panel of section.panels) {
      const panelCast = panel as WholeFromTypeWithRef<'panel'>;
      const key = Panels.getKey(panelCast);
      if (!key || !addedPanelSpecs[key]) {
        continue;
      }
      foundPanelConfigs[key] = {
        config: panelCast.config,
        currentRef: panelCast.ref,
        viewType: panelCast.viewType,
        type: 'api-added-panel',
      };
    }
  }
  return foundPanelConfigs;
};

export const getPanelBankDiff = (
  expected: DefaultPanelSpecs,
  actualPanelConfigsAndRefs: FoundAPIAddedPanelSpecs,
  added: APIAddedPanelSpecs,
  actual: FoundPanelSpecs,
  prevKeys: string[],
  panelBankConfigState: PanelBankConfigState
) => {
  // returns a map of operations that will bring the actual panel up to speed
  // with the expected panel

  const diff: PanelBankDiff = {};

  const prevKeysSet = new Set(prevKeys);
  for (const key of Object.keys(expected)) {
    const expectedPanel = expected[key];
    const actualPanel = actual[key];

    if (expectedPanel.type === 'legacy-vega') {
      if (!actualPanel) {
        diff[key] = {
          type: 'add',
          spec: expectedPanel,
        };
      } else if (
        actualPanel.type !== 'legacy-vega' ||
        !isEqual(expectedPanel.config, actualPanel.viz)
      ) {
        diff[key] = {
          type: 'update',
          currentRef: actualPanel.currentRef,
          spec: expectedPanel,
        };
      }

      continue;
    }

    if (actualPanel && actualPanel.type === 'legacy-vega') {
      console.warn(
        `Panel with key '${key}' expected to be non-vega, but was vega`
      );
      continue;
    }

    const metricsInActual = new Set(actualPanel ? actualPanel.metrics : []);

    const metricsDiff = expectedPanel.metrics.filter(
      metric => !metricsInActual.has(metric)
    );

    if (
      metricsDiff.length > 0 ||
      expectedPanel.defaultXAxis !== actualPanel.defaultXAxis
    ) {
      // the panel bank is either missing the chart for this key, or it has the
      // chart but needs to add some new metrics to it
      // or update the default x axis
      if (actualPanel) {
        diff[key] = {
          type: 'update',
          currentRef: actual[key].currentRef,
          spec: {
            type: 'default-panel',
            metrics: metricsDiff,
            defaultXAxis: expectedPanel.defaultXAxis,
          },
        };
      } else {
        // if we've seen this key before and we're *not* reinitializing the panelbank,
        // that means the user just deleted the chart for this key -- we need to hide it
        diff[key] = {
          type: 'add',
          spec: {
            ...expectedPanel,
            defaultSection: isHidden(prevKeysSet, key, panelBankConfigState)
              ? PANEL_BANK_HIDDEN_SECTION_NAME
              : expectedPanel.defaultSection,
          },
        };
      }
    }
  }
  // handle panels added through add_panel API, which holds the config in the spec
  for (const key of Object.keys(added)) {
    const addedPanel = added[key];
    const actualPanel = actualPanelConfigsAndRefs[key];
    if (!actualPanel) {
      diff[key] = {
        type: 'add',
        spec: {
          ...addedPanel,
          defaultSection: isHidden(prevKeysSet, key, panelBankConfigState)
            ? PANEL_BANK_HIDDEN_SECTION_NAME
            : addedPanel.defaultSection,
        },
      };
    } else if (
      !isEqual(addedPanel.config, actualPanel.config) ||
      addedPanel.viewType !== actualPanel.viewType
    ) {
      diff[key] = {
        type: 'update',
        currentRef: actualPanel.currentRef,
        spec: addedPanel,
      };
    }
  }
  return diff;
};

export function generateSystemPanel(
  key: keyof typeof Panels.systemPanelTemplates,
  metrics: string[]
) {
  const template = Panels.systemPanelTemplates[key];

  return {
    __id__: ID(),
    key,
    viewType: 'Run History Line Plot',
    config: {
      metrics,
      groupBy: 'None',
      chartTitle: template.yAxis,
      yAxisMin: template.percentage ? 0 : undefined,
      yAxisMax: template.percentage ? 100 : undefined,
    } as RunsLinePlotConfig,
  };
}

export function getDefaultPanelConfig(
  keyName: string,
  addPanelSpec: AddPanel['spec']
): Panels.LayedOutPanel {
  if (addPanelSpec.type === 'legacy-vega') {
    return {
      __id__: ID(),
      key: keyName,
      viewType: 'Vega',
      config: addPanelSpec.config,
    } as Panels.LayedOutPanel;
  } else if (addPanelSpec.type === 'api-added-panel') {
    return {
      key: keyName,
      viewType: addPanelSpec.viewType,
      config: addPanelSpec.config,
    } as Panels.LayedOutPanel;
  }

  const metrics = addPanelSpec.metrics.sort();
  if (keyName in Panels.systemPanelTemplates) {
    return generateSystemPanel(
      keyName as keyof typeof Panels.systemPanelTemplates,
      metrics
    ) as Panels.LayedOutPanel;
  }

  const defaultxAxis = addPanelSpec.defaultXAxis ?? '_step';

  if (
    ['table-file', 'partitioned-table', 'joined-table'].includes(
      addPanelSpec.keyType
    )
  ) {
    return {
      __id__: ID(),
      viewType: 'Weave',
      config: weavePanelForSummaryTableKey(keyName),
    } as Panels.LayedOutPanel;
  }

  return {
    __id__: ID(),
    viewType:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? 'Run History Line Plot'
        : 'Media Browser',
    config:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? {
            metrics: [keyName],
            groupBy: 'None',
            legendFields: ['run:displayName'],
            yAxisAutoRange: false,
            yLogScale: false,
            startingXAxis: defaultxAxis,
          }
        : {
            mediaKeys: [keyName],
          },
  } as Panels.LayedOutPanel;
}

// the panel in this function is expected to come from Immer:
// we change it in place instead of returning a new panel
export function addMetricsToPanelConfig(
  mutablePanel: Panels.LayedOutPanel,
  newMetrics: string[]
) {
  // NOTE: this, and the diff infrastructure, could probably be adapted to
  // other kinds of plots in the future with a few changes
  if (mutablePanel.viewType !== 'Run History Line Plot') {
    return;
  }

  mutablePanel.config.metrics = [
    ...(mutablePanel.config.metrics || []),
    ...newMetrics,
  ];
}

export function updateDefaultxAxis(
  mutablePanel: Panels.LayedOutPanel,
  newXAxis?: string
) {
  if (mutablePanel.viewType !== 'Run History Line Plot') {
    return;
  }
  mutablePanel.config.startingXAxis = newXAxis;
}

export function getDefaultPanelSectionConfig(args?: {
  name?: string;
  type?: 'grid' | 'flow';
}): PanelBankSectionConfig {
  const sectionName = args?.name || 'Panel Section';
  return {
    name: sectionName,
    isOpen:
      _.includes(
        [PANEL_BANK_CHARTS_NAME, PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME],
        sectionName
      ) || isMediaSectionName(sectionName), // open by default
    panels: [],
    type: args?.type || 'flow',
    flowConfig: {
      snapToColumns: true,
      columnsPerPage: isMediaSectionName(sectionName) ? 1 : 3,
      rowsPerPage: 2,
      gutterWidth: 16,
      boxWidth: 460,
      boxHeight: 300,
    },
    sorted: SectionPanelSorting.None,
    localPanelSettings: PanelSettings.EMPTY_SETTINGS,
  };
}

// Panel grid layout conversion from V0 to V1
function upgradePanelLayoutV0toV1(
  panelLayoutV0: Panels.LayoutParameters
): Panels.LayoutParameters {
  const xScale = GRID_COLUMN_COUNT / getV0GridColumnCount();
  // Panels go from height 150px to 32px, and 150/32 rounds to 5.
  // Doing 32px instead of 30px to maintain multiples of 4.
  // This means panels will become slightly taller.
  const yScale = 5;
  return {
    x: panelLayoutV0.x * xScale,
    y: panelLayoutV0.y * yScale,
    w: panelLayoutV0.w * xScale,
    h: panelLayoutV0.h * yScale,
  };
}

// Converts the legacy "Custom Visualizations" (pinned panels) section to a PanelBank section
// Returns the new config
function upgradeToPanelBank(
  legacyConfig?: Panels.Config,
  singlePanelBankSection?: boolean // this is true for reports
): PanelBankConfig | PanelBankSectionConfig {
  const panelBankConfig = EMPTY_PANEL_BANK_CONFIG;
  // If there's a custom viz section in state.panels, convert it to a PanelBank section (in state.panelBankConfig)
  const oldPanels = legacyConfig && legacyConfig.views[0].config;
  if (oldPanels && oldPanels.length > 0) {
    // create an empty section
    const newSection: PanelBankSectionConfig = {
      ...getDefaultPanelSectionConfig(),
      name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
      type: 'grid',
    };
    // For each panel in oldPanels, create a new panel, copying the config and layout
    const newPanels = oldPanels.map(p => {
      return {
        __id__: ID(),
        config: {...p.config},
        layout: upgradePanelLayoutV0toV1(p.layout),
        viewType: p.viewType,
      } as Panels.LayedOutPanel;
    });
    return singlePanelBankSection
      ? // Only return a single section (PanelBankSectionConfig)
        {...newSection, panels: [...newPanels]}
      : // Return a full PanelBankConfig
        {
          ...panelBankConfig,
          sections: [
            {...newSection, panels: [...newPanels]},
            ...panelBankConfig.sections,
          ],
        };
  }
  return singlePanelBankSection
    ? EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT
    : panelBankConfig;
}

// Returns the array of keys that are present in panelBankConfig
export function getInitializedKeys(panelBankConfig: PanelBankConfig): string[] {
  // console.time('initializedkeys');
  const initializedKeys = _.flatten(
    _.compact(
      panelBankConfig.sections.map(s => {
        return _.compact(s.panels.map(p => Panels.getKey(p)));
      })
    )
  );
  // console.timeEnd('initializedkeys');
  return initializedKeys;
}

// Returns the array of keys that aren't yet present in panelBankConfig
export function getUninitializedKeys(
  initializedKeysLookup: {[key: string]: 1},
  keyInfo: RunHistoryKeyInfo
) {
  const uninitializedKeys = Object.keys(keyInfo.keys).filter(
    k => !isReservedKey(k) && initializedKeysLookup[k] == null
  );
  return uninitializedKeys;
}

interface KeyQueryArgSpec {
  name: 'keys' | 'extraKeys' | 'foldKeys' | 'tableColumns';
  value: string[];
}

interface TableQueryArgSpec {
  name: 'tableKey';
  value: string;
}

interface QueryArgSpecs extends Array<TableQueryArgSpec | KeyQueryArgSpec> {}

const alwaysHaveDataPanelTypes = toIncludesObj([
  'Markdown Panel',
  'Parallel Coordinates Plot',
  'Scatter Plot',
  'Parameter Importance',
  'Run Comparer',
  'Code Comparer',
  'Bar Chart',
  'Confusion Matrix',
  'Data Frame Table',
]);

// Returns true if we have the data we need to render this panel
export function haveDataForPanel(
  historyKeyInfo: RunHistoryKeyInfo,
  panel: Panels.Panel
): boolean {
  if (alwaysHaveDataPanelTypes[panel.viewType]) {
    return true;
  }
  if (panel.viewType === 'Vega2') {
    const userQuery = panel.config.userQuery;
    let queryArgs: Array<undefined | QueryArgSpecs>;
    if (userQuery != null) {
      queryArgs = userQuery.queryFields[0].fields
        // we filter out the config keys, since we don't have an easy
        // way to retrieve all of the configs for all of the runs
        .filter(
          (field): field is QueryField =>
            field.args != null && field.name !== 'config'
        )
        .map(field => field.args) as Array<undefined | QueryArgSpecs>;
    } else {
      return true;
    }

    for (const queryArg of queryArgs) {
      if (queryArg == null) {
        continue;
      }
      const hasData: boolean = queryArg.every(arg => {
        if (arg.name === 'tableColumns') {
          return true;
        } else if (arg.name === 'tableKey') {
          return historyKeyInfo.keys[arg.value] != null;
        } else {
          return (
            !Array.isArray(arg.value) ||
            arg.value.every(key => historyKeyInfo.keys[key] != null)
          );
        }
      });
      if (!hasData) {
        return false;
      }
    }
  } else {
    const metrics = Panels.getMetrics(panel);
    if (metrics.some(metric => historyKeyInfo.keys[metric] == null)) {
      return false;
    }
  }
  return true;
}

const alwaysMatchPanelTypes = toIncludesObj([
  'Markdown Panel',
  'Parameter Importance',
  'Run Comparer',
  'Code Comparer',
  'Confusion Matrix',
  'Data Frame Table',
]);

export function searchQueryMatchesPanel(
  searchQuery: string,
  panel: Panels.Panel
) {
  if (alwaysMatchPanelTypes[panel.viewType]) {
    return true;
  }
  const searchRegex = searchRegexFromQuery(searchQuery);
  if (searchRegex == null) {
    return true;
  }
  return searchRegexMatchesPanel(searchRegex, panel);
}

export function searchRegexMatchesPanel(regex: RegExp, panel: Panels.Panel) {
  if (alwaysMatchPanelTypes[panel.viewType]) {
    return true;
  }

  const matchAgainst = [...Panels.getMetrics(panel)];
  if ('chartTitle' in panel.config && panel.config.chartTitle != null) {
    matchAgainst.push(panel.config.chartTitle);
  }
  for (const ma of matchAgainst) {
    if (regex.test(ma)) {
      return true;
    }
  }

  return false;
}

export const panelSortingKeyFromPanel = (panel: Panels.Panel) => {
  if ('chartTitle' in panel.config) {
    return panel.config.chartTitle;
  }
  if (
    'mediaKeys' in panel.config &&
    panel.config.mediaKeys != null &&
    panel.config.mediaKeys?.length > 0
  ) {
    return panel.config.mediaKeys[0];
  }
  if (
    'metrics' in panel.config &&
    panel.config.metrics != null &&
    panel.config.metrics.length > 0
  ) {
    return panel.config.metrics[0];
  }
  if ('key' in panel && panel.key != null) {
    return panel.key;
  }
  return undefined;
};

export function sortPanelCheck(
  keyA: string | undefined,
  keyB: string | undefined
): number {
  if (keyA == null && keyB == null) {
    return 0;
  }
  if (keyA == null) {
    return -1;
  }
  if (keyB == null) {
    return 1;
  }
  if (_.isNumber(keyA) && _.isNumber(keyB)) {
    return keyA - keyB;
  }
  return keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1;
}

export const panelBankSectionFromJSON = (
  panelBankSectionConfig: PanelBankSectionConfig
): PanelBankSectionConfig => {
  return {
    ...panelBankSectionConfig,
    panels: panelBankSectionConfig.panels.map(p =>
      Panels.panelFromJSON(p as any)
    ) as any,
  };
};

// This is run in the loadFinished reducer, for run and multi-run workspaces.
// It returns a full PanelBank.
// Put all migrations to the PanelBankConfig object here.
export const migrateWorkspaceToPanelBank = (
  existingPanelBankConfig?: PanelBankConfig | null,
  legacyConfig?: PanelsUtil.Config | null
): PanelBankConfig => {
  const pbc = existingPanelBankConfig || EMPTY_PANEL_BANK_CONFIG;

  const panelBankConfig =
    pbc && pbc.state === PanelBankConfigState.Init && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig) as PanelBankConfig)
      : pbc;

  return {
    ...panelBankConfig,
    sections: panelBankConfig.sections.map(s => {
      // We merge this to migrate the object (i.e., add any new attributes that have been added to panel section config)
      return {
        ...getDefaultPanelSectionConfig(),
        ...panelBankSectionFromJSON(s),
      };
    }),
  };
};

// This is run in the loadFinished reducer, for reports.
// (A report section's panels = a single PanelBankSection)
// It returns a single PanelBankSection
// Put all migrations to the PanelBankSectionConfig object here.
export const migrateReportToPanelBank = (
  existingPanelBankSectionConfig: PanelBankSectionConfig,
  legacyConfig: PanelsUtil.Config | null,
  specVersion: ReportSpecVersion
): PanelBankSectionConfig => {
  const panelBankSectionConfig =
    specVersion === ReportSpecVersion.V0 && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig, true) as PanelBankSectionConfig)
      : existingPanelBankSectionConfig; // no-op

  return panelBankSectionFromJSON(panelBankSectionConfig);
};

export function searchRegexFromQuery(searchQuery: string): RegExp | null {
  let searchRegex: RegExp | null = null;
  const query = searchQuery.trim();
  if (query.length > 0) {
    try {
      searchRegex = new RegExp(query, 'i');
    } catch {
      // since we execute search onChange, we need to catch invalid/incomplete regexes, like '[' without closing ']'
    }
  }
  return searchRegex;
}

export function isHistogram(
  panel: Panels.Panel,
  historyKeyInfo: RunHistoryKeyInfo
): boolean {
  const keyTypes = RunHelpers.keyTypes(historyKeyInfo);
  return isHistogramWithKeyTypes(panel, keyTypes);
}

export function isHistogramWithKeyTypes(
  panel: Panels.Panel,
  keyTypes: {[key: string]: RunHistoryKeyType}
): boolean {
  const panelKey = Panels.getKey(panel);
  return panelKey != null && keyTypes[panelKey] === 'histogram';
}

export interface PanelIsActiveParams {
  section: PanelBankSectionConfig;
  panel: Panels.LayedOutPanel;
  singleRun?: boolean;
  searchRegex: RegExp | null;
  historyKeyInfo: RunHistoryKeyInfo;
  keyTypes?: {[key: string]: RunHistoryKeyType};
}

export function panelIsActive({
  section,
  panel,
  singleRun,
  searchRegex,
  historyKeyInfo,
  keyTypes,
}: PanelIsActiveParams): boolean {
  // Don't show histograms in multi-run workspaces
  if (!singleRun) {
    const hist =
      keyTypes != null
        ? isHistogramWithKeyTypes(panel, keyTypes)
        : isHistogram(panel, historyKeyInfo);
    if (hist) {
      return false;
    }
  }

  if (searchRegex != null && !searchRegexMatchesPanel(searchRegex, panel)) {
    return false;
  }

  if (section.type !== 'grid' && !haveDataForPanel(historyKeyInfo, panel)) {
    return false;
  }

  return true;
}

export function isMediaSectionName(name: string): boolean {
  return (
    name === PANEL_BANK_MEDIA_NAME ||
    name === PANEL_BANK_TABLES_NAME ||
    name.startsWith(`${PANEL_BANK_MEDIA_NAME}/`)
  );
}

export interface PanelBankSectionConfigWithVisiblePanels
  extends PanelBankSectionConfig {
  ref: PanelBankSectionConfigTypes.Ref;
  visiblePanels: ReadonlyArray<Panels.LayedOutPanel>;
}

export function getSectionsWithVisiblePanels(
  sections: readonly PanelBankSectionConfig[],
  mappingFn: (
    s: PanelBankSectionConfig
  ) => PanelBankSectionConfigWithVisiblePanels = defaultSectionToSectionWithVisiblePanelsMapping
): PanelBankSectionConfigWithVisiblePanels[] {
  return sections.map(mappingFn).filter(s => s.visiblePanels.length > 0);
}

export function defaultSectionToSectionWithVisiblePanelsMapping(
  s: PanelBankSectionConfig
): PanelBankSectionConfigWithVisiblePanels {
  return {
    ...s,
    ref: (s as any).ref,
    visiblePanels: [...s.panels],
  };
}
