import * as React from 'react';
import {Card} from 'semantic-ui-react';
import makeComp from '../../util/profiler';

interface HtmlViewerProps {
  headerElements?: JSX.Element[];
  notFoundElements?: JSX.Element[];
  htmlNotFound?: boolean;
  height?: number;
  src?: string;
}

// a single image card
const HtmlViewer = makeComp(
  (props: HtmlViewerProps) => {
    const {headerElements, notFoundElements, height, htmlNotFound, src} = props;

    return (
      <div style={{padding: '10px', height: '100%', width: '100%'}}>
        <Card className="html-card" style={{width: '100%'}}>
          {headerElements}

          {!!htmlNotFound ? (
            {notFoundElements}
          ) : (
            <iframe
              title="Html card"
              sandbox="allow-same-origin allow-scripts"
              style={{border: 'none', height: (height ? height : 300) - 50}}
              src={src}
            />
          )}
        </Card>
      </div>
    );
  },
  {id: 'HtmlViewer'}
);

export default HtmlViewer;
