import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import * as _ from 'lodash';
import {
  useEntityStorageTreeQuery,
  useRunFilesLazyQuery,
  useTeamOrganizationQuery,
} from '../generated/graphql';
import {File} from '../state/graphql/runFilesQuery'; // TODO move this type def
import WandbLoader from '../components/WandbLoader';
import makeComp from '../util/profiler';
import {History} from 'history';
import StorageFileBrowser from '../components/StorageFileBrowser';
import {DateTime} from 'vega-lite/build/src/datetime';
import StoragePercentage from '../components/StoragePercentage';
import StorageConfirmDelete from '../components/StorageConfirmDelete';
import * as Urls from '../util/urls';
import {MAX_NUM_FILES, MAX_STORAGE_TREE_NODES} from '../util/constants';
import config from '../config';
import numeral from 'numeral';
import produce from 'immer';
import {Container, Grid, Segment, SearchProps, Tab} from 'semantic-ui-react';
import {matchPath} from 'react-router';
import {
  STORAGE_EXPLORER_COMPUTE_HOURS_PATH,
  STORAGE_EXPLORER_TRACKED_PATH,
} from '../routeData';
import ProjectsTable from '../components/ProjectsTableComputeHour';
import {HOUR, SECOND, TimeDelta} from '../util/time';

interface TreeNode {
  id?: string;
  size: number;
  name: string;
  type: TreeNodeType;
  children: TreeNode[];
}

type TreeNodeType =
  | 'entity'
  | 'project'
  | 'run'
  | 'file'
  | 'artifact'
  | 'artifact_type'
  | 'artifact_version';

interface StorageExplorerProps {
  history: History;
  match: {
    params: {
      entityName: string;
      filePath?: string;
    };
  };
}

// The LeafMap lets us know the path, type and bytes of all selected objects
export interface LeafMap {
  [id: string]: {type: TreeNodeType; path: readonly string[]; bytes: number};
}

export interface Node {
  files: File[];
  subdirectories: {[key: string]: Node};
  searchable?: boolean;
  selectable?: boolean;
  selected?: boolean;
  disabled?: boolean;
  childSelected?: boolean;
  id?: string;
  type?: TreeNodeType;
  summary?: string;
  fileCount?: number;
  size?: number;
  name?: string;
}

export const HIDDEN_FILES = [/^wandb-metadata\.json$/, /^artifact\/\d+\/.+$/];

export function fileHidden(name: string) {
  return HIDDEN_FILES.some(regex => regex.test(name));
}

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
function nodeFactory(
  overrides: Optional<Node, 'files' | 'subdirectories'>
): Node {
  return Object.assign(
    {
      files: [],
      subdirectories: {},
      size: 0,
      searchable: false,
      selectable: true,
      selected: false,
      fileCount: 0,
    },
    overrides
  );
}

// Takes a flat array of file objects and converts it into a nested object, based on filenames
export function makeFileTree(
  filesArray: File[],
  opts: {
    selectedIds: Set<string>;
    selectedPaths: string[];
    runId?: string;
    selected?: boolean;
  }
): Node {
  const fileTree = nodeFactory({
    selected: opts.selected === true,
    id: opts.runId,
    type: 'run',
  });
  filesArray.forEach(file => {
    if (fileHidden(file.name)) {
      return;
    }
    let currentFolder = fileTree;
    // 'media/images/image01.jpg' => ['media','images','image01.jpg']
    const path = file.name.split('/');
    let selected = fileTree.selected;
    const currentPath: string[] = [];
    while (path.length > 1) {
      // The following is safe to do because we made sure path had elems in the loop condition.
      const folderName = path.shift()!;
      currentPath.push(folderName);
      const pathSelected =
        selected || opts.selectedPaths.some(p => currentPath.join('/') === p);
      let disabled = pathSelected;
      // Don't disable the first folder that's selected
      if (!selected && pathSelected) {
        disabled = false;
        selected = true;
      }
      currentFolder.childSelected = selected;
      // create subfolder if it doesn't already exist
      if (!currentFolder.subdirectories[folderName]) {
        currentFolder.subdirectories[folderName] = nodeFactory({
          selected,
          disabled,
          childSelected: selected,
          name: folderName,
        });
      }
      currentFolder.size! += file.sizeBytes;
      currentFolder.fileCount! += 1;
      const subFolder = currentFolder.subdirectories[folderName];
      currentFolder = subFolder;
    }
    // if we've come to the last item in the path, add this file object to the current folder
    currentFolder.size! += file.sizeBytes;
    currentFolder.fileCount! += 1;
    file.selected =
      fileTree.selected ||
      currentFolder.selected ||
      opts.selectedIds.has(file.id);
    file.disabled = fileTree.selected;
    currentFolder.files.push(file);
    // Lets add some nice summaries.
    let summary = '';
    const subFolderCount = Object.keys(currentFolder.subdirectories).length;
    if (subFolderCount > 0) {
      summary +=
        subFolderCount + (subFolderCount === 1 ? ' subfolder' : ' subfolders');
    }
    if (currentFolder.fileCount! > 0) {
      if (subFolderCount > 0) {
        summary += ', ';
      }
      summary +=
        currentFolder.fileCount +
        (currentFolder.fileCount === 1 ? ' file' : ' files');
    }
    currentFolder.summary = summary;
  });

  return fileTree;
}

// Our little redux store for storage explorer.  TODO: likely move this into
// the main redux store.
interface StorageExplorerState {
  filter?: string;
  since?: DateTime;
  selectedIds: Set<string>;
  leafMap: LeafMap;
  filtered: string[];
  selectedBytes: number;
}

interface SelectionPayload {
  ids: string[];
  bytes: number[];
  types: TreeNodeType[];
  path?: string[];
}

export type StorageAction =
  | {
      type: 'select';
      payload: SelectionPayload;
    }
  | {
      type: 'deselect';
      payload: SelectionPayload;
    }
  | {type: 'filter'; payload: string[]}
  | {type: 'clear'};

const initialState = (): StorageExplorerState => ({
  selectedIds: new Set<string>(),
  leafMap: {},
  filtered: [],
  selectedBytes: 0,
});

const reducer = produce(
  (draft: StorageExplorerState, action: StorageAction) => {
    switch (action.type) {
      case 'select':
        // We store a mapping of ids to leaf paths / sizes to render nice
        // confirmation dialogs for files or artifact versions
        if (action.payload.path) {
          // Remove any selected child nodes if a parent node was selected
          Object.keys(draft.leafMap)
            .filter(key => {
              const leafPath = draft.leafMap[key].path;

              // leaf path must be longer than current path if it's a child
              if (leafPath.length <= action.payload.path!.length) {
                return false;
              }

              // leaf must have current path as a prefix if it's a child
              for (let i = 0; i < action.payload.path!.length; i++) {
                if (leafPath[i] !== action.payload.path![i]) {
                  return false;
                }
              }

              return true;
            })
            .forEach(key => {
              draft.selectedIds.delete(key);
              draft.selectedBytes -= draft.leafMap[key].bytes;
              delete draft.leafMap[key];
            });
        }
        action.payload.ids.forEach((id, i) => {
          draft.leafMap[id] = {
            path: action.payload.path!,
            type: action.payload.types[i],
            bytes: action.payload.bytes[i],
          };
          draft.selectedBytes += action.payload.bytes[i];
          draft.selectedIds.add(id);
        });
        return;
      case 'deselect':
        if (action.payload.ids.length === 0) {
          draft.selectedBytes = 0;
          draft.selectedIds.clear();
          draft.leafMap = {};
        } else {
          action.payload.ids.forEach((id, i) => {
            draft.selectedIds.delete(id);
            delete draft.leafMap[id];
            draft.selectedBytes -= action.payload.bytes[i];
          });
        }
        return;
      case 'clear':
        return initialState();
      case 'filter':
        draft.filtered = action.payload;
        return;
      default:
        throw new Error(`Unknown storage action ${JSON.stringify(action)}`);
    }
  },
  initialState()
);

// formats run, artifact, and file to read sanely
function pluralize(singular: string, count: number): string {
  if (count === 1) {
    return `${count} ${singular}`;
  } else if (count === MAX_STORAGE_TREE_NODES) {
    return `${count}+ ${singular}s`;
  } else {
    return `${count} ${singular}s`;
  }
}

const StorageExplorer: React.FC<StorageExplorerProps> = makeComp(
  ({
    history,
    match: {
      params: {entityName, filePath},
    },
  }) => {
    const isTrackedPath =
      matchPath(window.location.pathname, {
        path: STORAGE_EXPLORER_TRACKED_PATH,
        exact: true,
      }) != null;
    const isComputeHourPath =
      matchPath(window.location.pathname, {
        path: STORAGE_EXPLORER_COMPUTE_HOURS_PATH,
        exact: true,
      }) != null;
    const [state, dispatch] = useReducer(reducer, initialState());
    const [projectName, setProjectName] = useState<string | undefined>(
      undefined
    );
    const [runName, setRunName] = useState<string | undefined>(undefined);
    const [getRunFiles, {data: runData, loading: runLoading}] =
      useRunFilesLazyQuery({fetchPolicy: 'cache-and-network'});
    const [artifactName, setArtifactName] = useState<string | undefined>(
      undefined
    );
    const [artifactTypeName, setArtifactTypeName] = useState<
      string | undefined
    >(undefined);
    const [typeName, setTypeName] = useState<string | undefined>(undefined);
    const [path, setPath] = useState<string | undefined>(undefined);
    const [files, setFiles] = useState<File[]>([]);
    const [searchResults, setSearchResults] = useState<Node | undefined>(
      undefined
    );
    const [usedBytes, setUsedBytes] = useState<number>(0);
    const {loading, data, refetch} = useEntityStorageTreeQuery({
      fetchPolicy: 'cache-and-network',
      variables: {entityName, enableReferenceTracking: isTrackedPath === true},
    });

    const teamOrganizationQuery = useTeamOrganizationQuery({
      skip: !isComputeHourPath,
      variables: {entityName},
    });

    const isTeam = teamOrganizationQuery.data?.entity?.isTeam;

    const showStoragePercentage = !isComputeHourPath || isTeam;

    const sub = isTeam
      ? teamOrganizationQuery.data?.entity?.organization?.subscriptions.find(
          s => typeof s.privileges.compute_hours === 'number'
        )
      : null;

    const computeHours = teamOrganizationQuery.data?.entity?.computeHours || 0;

    const pathParts = useMemo(() => {
      const parts = filePath ? filePath.split('/') : [];
      // Automatically navigate to artifacts in tracking mode
      if (isTrackedPath && parts.length > 0) {
        if (parts.length === 1 || parts[1] !== 'artifacts') {
          parts.splice(1, 0, 'artifacts');
        }
      }
      return parts;
    }, [filePath, isTrackedPath]);

    const setFilePath = useCallback(
      (newPathArray: string[]) => {
        history.push(
          Urls.storagePage(entityName, newPathArray.join('/'), isTrackedPath)
        );
      },
      [entityName, isTrackedPath, history]
    );

    // pathParts is project, type, run_or_artifact_type, file_path_or_artifact_sequence
    useEffect(() => {
      // Parse the browser path and set state accordingly
      if (pathParts.length > 0) {
        setProjectName(pathParts[0]);
      } else {
        setFiles([]);
        setSearchResults(undefined);
        setProjectName(undefined);
      }
      if (pathParts.length > 1) {
        setTypeName(pathParts[1]);
      } else {
        setTypeName(undefined);
      }
      if (pathParts.length > 2 && pathParts[1] === 'runs') {
        setRunName(pathParts[2]);
      } else {
        setRunName(undefined);
      }
      if (pathParts.length > 2 && pathParts[1] === 'artifacts') {
        setArtifactTypeName(pathParts[2]);
        if (pathParts.length > 3) {
          setArtifactName(pathParts[3]);
        } else {
          setArtifactName(undefined);
        }
      } else {
        setArtifactTypeName(undefined);
      }
      // Reset files when navigating at the projectName / typeName levels
      // Also sets the file path within a run or version path within artifacts
      if (pathParts.length < 3) {
        setFiles([]);
        setPath(undefined);
        dispatch({
          type: 'filter',
          payload: [],
        });
      } else {
        setPath(pathParts.slice(3).join('/'));
      }
    }, [pathParts]);

    useEffect(() => {
      // Clear our state when entityName changes or we switch to tracked
      dispatch({type: 'clear'});
    }, [entityName, isTrackedPath]);

    useEffect(() => {
      if (projectName != null && runName != null) {
        getRunFiles({
          variables: {
            entityName,
            projectName,
            runName,
            maxNumFiles: MAX_NUM_FILES,
          },
        });
      }
    }, [entityName, projectName, runName, getRunFiles]);

    // Propagate a runs files
    useEffect(() => {
      if (runData != null && runName != null) {
        setFiles(runData.project?.run?.files?.edges.map(e => e.node!) ?? []);
      }
    }, [runData, runName]);

    // This transforms the storageTree data into something more friendly for us to render
    const rawData = useMemo(() => {
      let root: TreeNode = {
        name: 'root',
        size: 0,
        type: 'entity',
        children: [],
      };

      if (data == null || data.entity == null) {
        return root;
      }
      const {storageTree} = data.entity;
      if (storageTree == null) {
        return root;
      }
      setUsedBytes(storageTree.size);

      root = {
        id: data.entity.id,
        size: storageTree.size,
        type: 'entity',
        name: storageTree.name,
        children: storageTree.projects.edges
          .map(projectEdge => {
            const {projectID, size, name, runs, artifactSequences} =
              projectEdge.node;
            let r: TreeNode[] = [];
            if (runs != null) {
              r = runs.edges.map(runEdge => {
                // tslint:disable-next-line:no-shadowed-variable
                const {runID, size, name} = runEdge.node;

                return {
                  id: runID,
                  name,
                  size,
                  type: 'run',
                  children: [],
                } as TreeNode;
              });
            }
            const artifactTypes: TreeNode[] = [];
            artifactSequences.edges.forEach(sequenceEdge => {
              // tslint:disable:no-shadowed-variable
              const {artifactSequenceID, size, name, artifacts, artifactType} =
                sequenceEdge.node;

              // Ignore V1 artifacts or reference only artifacts in storage mode
              if (size !== 0) {
                let at = artifactTypes.find(
                  at => at.name === artifactType.name
                );
                if (at == null) {
                  at = {
                    name: artifactType.name,
                    size,
                    type: 'artifact_type',
                    children: [],
                  } as TreeNode;
                  artifactTypes.push(at);
                } else {
                  at.size += size;
                }

                const av = artifacts.edges.map(artifactEdge => {
                  return {
                    id: artifactEdge.node.artifactID,
                    name: `${name}:${artifactEdge.node.name}`,
                    size: artifactEdge.node.size,
                    type: 'artifact_version',
                    children: [],
                  } as TreeNode;
                });

                // tslint:enable:no-shadowed-variable
                at.children.push({
                  id: artifactSequenceID,
                  name,
                  size,
                  type: 'artifact',
                  children: av,
                });
              }
            });

            return {
              id: projectID,
              name,
              size,
              type: 'project',
              children: r.concat(artifactTypes),
            } as TreeNode;
          })
          .filter(p => p.size > 0),
      };
      return root;
    }, [data]);

    // Used for building the storage tree with selection properly set from state
    const newNodeOrUpdate = useCallback(
      (
        root: Node,
        rawNode: TreeNode,
        subDir: string,
        selectable: boolean = true,
        disabled: boolean = false
      ) => {
        const childSelected = Object.values(state.leafMap).some(
          v =>
            v.path.indexOf(root.name || '') > -1 && v.path.indexOf(subDir) > -1
        );
        const selected =
          rawNode.id != null && state.selectedIds.has(rawNode.id);
        let subNode = nodeFactory({
          disabled,
          selected,
          selectable,
          childSelected,
          id: rawNode.id,
          type: rawNode.type,
          size: rawNode.size,
          name: subDir,
        });
        if (root.subdirectories[subDir]) {
          subNode = root.subdirectories[subDir];
          subNode.id = rawNode.id;
          subNode.size! += rawNode.size!;
          subNode.selected = selected;
        } else {
          root.subdirectories[subDir] = subNode;
        }
        return subNode;
      },
      [state.selectedIds, state.leafMap]
    );

    // Generate a summary of folder contents
    const folderSummary = (root: Node) => {
      let summary = '';
      if (root.fileCount != null && root.fileCount > 0) {
        summary += pluralize('file', root.fileCount);
      }
      if (root.subdirectories.runs || root.name === 'runs') {
        const base =
          root.name === 'runs'
            ? root.subdirectories
            : root.subdirectories.runs.subdirectories;
        summary += pluralize('run', Object.keys(base).length);
      }
      if (root.subdirectories.artifacts || root.type === 'artifact_type') {
        summary +=
          root.type === 'artifact_type' ? '' : summary === '' ? '' : ', ';
        const base =
          root.type === 'artifact_type'
            ? root.subdirectories
            : root.subdirectories.artifacts.subdirectories;
        let artCount = Object.keys(base).length;
        if (root.name === 'artifacts') {
          artCount = Object.keys(base).reduce(
            (i, k) => i + Object.keys(base[k].subdirectories).length,
            0
          );
        }
        summary += pluralize('artifact', artCount);
      }
      if (root.type === 'artifact') {
        summary = pluralize('version', root.files.length);
      }
      return summary;
    };

    const makeArtifactTree = useCallback(
      (
        root: TreeNode,
        projectID: string | undefined,
        artifactType: Node,
        projectSelected: boolean
      ) => {
        const art = newNodeOrUpdate(
          artifactType,
          root,
          root.name,
          true,
          projectSelected
        );
        art.files = root.children.map(av => ({
          id: av.id!,
          name: av.name,
          sizeBytes: av.size,
          disabled: art.selected,
          selected: art.selected || state.selectedIds.has(av.id!),
          artifact: {id: av.id!, digest: av.id!}, // TODO: HAX
        }));

        art.summary = folderSummary(art);

        const totalSize = data?.entity?.storageTree?.projects.edges
          .find(p => p.node.projectID === projectID)
          ?.node.artifactSequences.edges.find(
            a =>
              a.node.artifactType.name === artifactType.name &&
              a.node.name === root.name
          )?.node.artifacts.totalSize;
        if (totalSize !== undefined) {
          art.size = totalSize;
        }

        return art;
      },
      [newNodeOrUpdate, data, state.selectedIds]
    );

    // Build the storage tree from the backend data and selection state
    const tree = useMemo(() => {
      // TODO: only build as much of the tree as we need?
      const augmented: Node = {
        files: [],
        searchable: true,
        selectable: false,
        subdirectories: {},
        fileCount: 0,
      };
      if (rawData == null) {
        return augmented;
      }
      rawData.children.forEach(proj => {
        // Project
        const projectSelected = state.selectedIds.has(proj.id!);
        const filtered =
          state.filtered.length === 0 || state.filtered.indexOf(proj.id!) > -1;
        if (!filtered) {
          return;
        }
        const childSelected = Object.values(state.leafMap).some(
          v => v.path[0] === proj.name
        );
        // don't allow selection in runs / artifacts
        const projNode: Node = nodeFactory({
          selectable: projectName == null,
          childSelected,
          selected: projectSelected,
          size: proj.size,
          id: proj.id,
          type: 'project',
          name: proj.name,
        });

        proj.children.forEach(r => {
          const pluralType = r.type === 'artifact_type' ? 'artifacts' : 'runs';
          // Asset type (runs, artifacts)
          const subNode = newNodeOrUpdate(
            projNode,
            r,
            pluralType,
            false,
            projectSelected
          );
          if (r.type === 'artifact_type') {
            // Artifacts
            const artifactType = newNodeOrUpdate(
              subNode,
              r,
              r.name,
              false,
              projectSelected
            );
            r.children.forEach(c =>
              makeArtifactTree(c, proj.id, artifactType, projectSelected)
            );

            artifactType.summary = folderSummary(artifactType);
          } else {
            // Run
            newNodeOrUpdate(subNode, r, r.name, true, projectSelected);

            const totalSize = data?.entity?.storageTree?.projects.edges.find(
              p => p.node.projectID === proj.id
            )?.node.runs.totalSize;
            if (totalSize !== undefined) {
              subNode.size = totalSize;
            }
          }
          subNode.summary = folderSummary(subNode);
        });
        projNode.summary = folderSummary(projNode);
        augmented.subdirectories[proj.name] = projNode;
      });
      return augmented;
    }, [
      data,
      rawData,
      projectName,
      state.filtered,
      state.selectedIds,
      state.leafMap,
      newNodeOrUpdate,
      makeArtifactTree,
    ]);

    const projectFolder =
      projectName != null && tree
        ? tree.subdirectories[projectName]
        : undefined;
    const runId =
      (runName &&
        projectFolder &&
        projectFolder.subdirectories.runs?.subdirectories[runName].id) ||
      'null';

    const humanNameFromID = useCallback(
      (id: string) => {
        return state.leafMap[id].type;
      },
      [state.leafMap]
    );

    // Summarize what's currently selected
    const selectionStatus = useMemo(() => {
      if (state.selectedIds && state.selectedIds.size > 0) {
        const ids = Array.from(state.selectedIds).map(humanNameFromID);
        const counts = ids.reduce((counter, id) => {
          counter[id] = counter[id] || 0;
          counter[id]++;
          return counter;
        }, {} as {[id: string]: number});
        const keys = Object.keys(counts);
        let summary = keys
          .map(
            (c, i) =>
              `${counts[c]} ${c.replace('_', ' ')}${counts[c] > 1 ? 's' : ''}${
                keys.length === 1 || i === keys.length - 1
                  ? ' '
                  : i === keys.length - 2
                  ? ' and '
                  : ', '
              }`
          )
          .join('');
        summary += ` selected (${numeral(state.selectedBytes).format('0.0b')})`;
        return summary;
      } else {
        let summary = `Open a ${projectName ? 'folder' : 'project'} to select ${
          isTrackedPath ? 'artifacts' : 'files'
        } to delete`;
        if (typeName) {
          if (runName) {
            if (path) {
              summary = `Select all files in this folder`;
            } else {
              summary = `Select this run and all files in it`;
            }
          } else if (artifactName) {
            summary = `Select this artifact and all versions in it`;
          } else {
            if (typeName === 'artifacts') {
              summary = 'Open an artifact to delete specific versions';
            } else {
              summary = `Select all ${typeName} in this project`;
            }
          }
        }
        return summary;
      }
    }, [
      state.selectedIds,
      state.selectedBytes,
      humanNameFromID,
      projectName,
      artifactName,
      runName,
      typeName,
      isTrackedPath,
      path,
    ]);

    // Set the root to the augmented storageTree or a runs files
    const currentFolder: Node = useMemo(() => {
      if (searchResults) {
        return searchResults;
      } else if (
        files.length > 0 &&
        projectFolder?.id &&
        projectName &&
        runName
      ) {
        // Find selected paths for file folder selection
        const selectedPaths = new Set<string>();
        Object.values(state.leafMap)
          .filter(l => l.path[0] === projectName && l.path[2] === runName)
          .forEach(l => {
            selectedPaths.add(l.path.slice(3).join('/'));
          });
        const selected =
          state.selectedIds.has(runId) ||
          state.selectedIds.has(projectFolder.id);
        return makeFileTree(files, {
          selectedIds: state.selectedIds,
          selectedPaths: Array.from(selectedPaths),
          runId,
          selected,
        });
      } else if (projectFolder && artifactTypeName) {
        if (artifactName) {
          return projectFolder.subdirectories.artifacts.subdirectories[
            artifactTypeName
          ].subdirectories[artifactName];
        } else {
          return projectFolder.subdirectories.artifacts.subdirectories[
            artifactTypeName
          ];
        }
      } else {
        return projectFolder || tree;
      }
    }, [
      searchResults,
      files,
      state.selectedIds,
      state.leafMap,
      projectFolder,
      projectName,
      artifactName,
      artifactTypeName,
      runName,
      runId,
      tree,
    ]);

    if (loading) {
      return <WandbLoader />;
    }

    // This currently just filters project names, but it's designed to support other
    // types eventually
    function handleSearchChange(e: React.MouseEvent, search: SearchProps) {
      if (search.value === '') {
        return dispatch({
          type: 'filter',
          payload: [],
        });
      }
      // Handle search key strokes
      const re = new RegExp(
        '^' + _.escapeRegExp(search.value).replace('\\*', '.+'),
        'i'
      );
      const projectIds = Object.keys(tree.subdirectories)
        .filter(k => re.test(k))
        .map(k => tree.subdirectories[k].id!);
      dispatch({
        type: 'filter',
        payload: projectIds,
      });
    }

    const Manager = (
      <Tab.Pane as={'div'}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <h3>
                  {isComputeHourPath
                    ? 'Compute Hours'
                    : `Bytes ${isTrackedPath ? 'Tracked' : 'Stored'}`}
                </h3>
                {showStoragePercentage ? (
                  <StoragePercentage
                    available={
                      isComputeHourPath
                        ? sub
                          ? sub.privileges.compute_hours * (HOUR / SECOND)
                          : config.MAX_COMPUTE_HOURS_IN_SECONDS
                        : config.MAX_BYTES
                    }
                    used={isComputeHourPath ? computeHours : usedBytes}
                    selected={state.selectedBytes}
                    computeHours={isComputeHourPath}
                  />
                ) : (
                  <div>
                    Personal Usage Plan:{' '}
                    {new TimeDelta(computeHours).toHoursString()} / Unlimited
                  </div>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isComputeHourPath ? (
          <ProjectsTable entityName={entityName} />
        ) : (
          <StorageFileBrowser
            entityName={entityName}
            root={currentFolder}
            dispatch={dispatch}
            selectionStatus={selectionStatus}
            deleteButton={
              <StorageConfirmDelete
                dispatch={dispatch}
                refetch={() => {
                  setFilePath([]);
                  dispatch({type: 'clear'});
                  return refetch({
                    entityName,
                    enableReferenceTracking: isTrackedPath === true,
                  });
                }}
                summary={selectionStatus.replace(' selected', '')}
                selectedIds={state.selectedIds}
                rootTree={tree}
                leafMap={state.leafMap}
                entityName={entityName}
              />
            }
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            loadingFiles={runLoading}
            path={pathParts.join('/')}
            history={history}
            setFilePath={setFilePath}
          />
        )}
      </Tab.Pane>
    );

    const panes = [
      {
        menuItem: 'Bytes Stored',
        render: () => Manager,
      },
      {
        menuItem: 'Bytes Tracked',
        render: () => Manager,
      },
      {
        menuItem: 'Compute Hours',
        render: () => Manager,
      },
    ];

    return (
      <Container style={{marginTop: 20}}>
        <h2>Usage Dashboard ({entityName})</h2>
        {usedBytes === 0 && !isComputeHourPath ? (
          <Segment>
            <h3>Storage statistics unavailable</h3>
            <p>This entity hasn't stored or tracked any data yet</p>
          </Segment>
        ) : (
          <Tab
            activeIndex={isTrackedPath ? 1 : isComputeHourPath ? 2 : 0}
            onTabChange={(e, d) =>
              history.replace(
                Urls.storagePage(
                  entityName,
                  undefined,
                  d.activeIndex === 1,
                  d.activeIndex === 2
                )
              )
            }
            menu={{secondary: true, pointing: true}}
            panes={panes}
          />
        )}
      </Container>
    );
  },
  {id: 'StorageExplorer', memo: true}
);
export default StorageExplorer;
