import '../css/CloneReportModal.less';

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {Button, Modal} from 'semantic-ui-react';
import {useUserProjectsQuery, useMoveViewMutation} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {
  DropdownOption,
  getProjectsFromUserProjectsQuery,
  getTeamsFromUserProjectsQuery,
} from './CreateReportModal';
import ModifiedDropdown from './elements/ModifiedDropdown';
import * as ViewApi from '../state/views/api';
import {ID} from '@wandb/cg/browser/utils/string';
import * as ViewNormalize from '../state/views/normalize';
import * as Urls from '../util/urls';
import {useApolloClient, useSelector} from '../state/hooks';
import * as ViewSelectors from '../state/views/selectors';
import {ReportViewRef} from '../state/reports/types';
import {SaveableView} from '../state/views/types';
import {ReportConfig} from '../state/views/report/types';
import makeComp from '../util/profiler';
import {ReportAuthor} from '../util/section';

interface CloneReportModalProps {
  moveReport?: boolean;
  viewRef: ReportViewRef;
  onClose(): void;
}

const CloneReportModal: React.FC<CloneReportModalProps> = makeComp(
  ({moveReport, viewRef, onClose}) => {
    const client = useApolloClient();
    const viewer = useViewer();
    const views = useSelector(ViewSelectors.getViews);

    const userProjectsQuery = useUserProjectsQuery({
      variables: {userName: viewer?.username ?? '', includeReports: false},
      skip: viewer == null,
    });

    const view = useSelector(state => state.views.views[viewRef.id]);
    if (view.project == null) {
      throw new Error('attempting to clone a report without a project');
    }
    const {id: projectID, entityName, name: projectName} = view.project;
    if (projectID == null) {
      throw new Error('invalid state');
    }
    const viewPartRef = view.partRef;
    if (viewPartRef == null) {
      throw new Error('View in redux missing part ref');
    }

    const projectOptions: DropdownOption[] = useMemo(() => {
      if (userProjectsQuery.loading) {
        return [];
      }
      const teams = getTeamsFromUserProjectsQuery(userProjectsQuery);
      const opts: DropdownOption[] = [];
      for (const t of teams) {
        const teamOpts =
          t?.projects?.edges.map(e => ({
            text:
              teams.length > 1
                ? `${e.node?.entityName}/${e.node?.name}`
                : e.node?.name ?? '',
            value: e.node?.id ?? '',
          })) ?? [];
        opts.push(...teamOpts);
      }
      return opts;
    }, [userProjectsQuery]);

    const history = useHistory();
    const [selectedProjectID, setSelectedProjectID] = useState('');
    const selectedProject = useMemo(() => {
      if (userProjectsQuery.loading) {
        return null;
      }
      const projects = getProjectsFromUserProjectsQuery(userProjectsQuery);
      return projects.find(p => p.id === selectedProjectID);
    }, [selectedProjectID, userProjectsQuery]);

    const onChangeProject = useCallback((e, data) => {
      setSelectedProjectID(data.value);
    }, []);

    useEffect(() => {
      const projects = getProjectsFromUserProjectsQuery(userProjectsQuery);
      if (projects.length === 0) {
        return;
      }
      // if a project on the list is already selected, don't change it against the user's will
      let projo = projects.find(p => p.id === selectedProjectID);
      // otherwise, select current project
      if (projo == null) {
        projo = projects.find(
          p => p.entityName === entityName && p.name === projectName
        );
      }
      // if current project is not on the list, default to first project
      if (projo == null) {
        projo = projects[0];
      }
      setSelectedProjectID(projo.id);
      // eslint-disable-next-line
    }, [userProjectsQuery, entityName, projectName]);

    const [cloningReport, setCloningReport] = useState(false);

    const onCloneReport = useCallback(async () => {
      if (selectedProject == null || cloningReport) {
        return;
      }

      const spec = ViewNormalize.denormalize(
        views.parts,
        viewPartRef
      ) as ReportConfig;

      const cloner = _.pick(viewer, 'name', 'username');
      spec.authors = [cloner as ReportAuthor];

      setCloningReport(true);
      const project = _.pick(
        selectedProject,
        'entityName',
        'name'
      ) as NonNullable<SaveableView['project']>;

      const newDraft = await ViewApi.save(client, {
        type: 'runs/draft',
        name: ID(12),
        displayName: `Copy of ${view.user.username}'s ${view.displayName}`,
        description: view.description,
        spec,
        project,
        previewUrl: view.previewUrl,
        coverUrl: view.coverUrl,
      });

      history.push(
        Urls.reportEdit({
          entityName: project.entityName,
          projectName: project.name!,
          reportID: newDraft.id as string,
          reportName: newDraft.displayName,
        })
      );
    }, [
      cloningReport,
      history,
      client,
      view,
      views,
      viewer,
      viewPartRef,
      selectedProject,
    ]);

    const [moveView] = useMoveViewMutation();
    const onMoveReport = useCallback(async () => {
      if (view.id == null || selectedProject == null || cloningReport) {
        return;
      }

      setCloningReport(true);

      try {
        const res = await moveView({
          variables: {
            id: view.id,
            entityName: selectedProject.entityName,
            projectName: selectedProject.name,
          },
        });
        if (!res?.data?.moveView?.success) {
          throw new Error(`failed to move report ${view.id}`);
        }
      } catch (err) {
        alert(`Error moving report: ${err}`);
        setCloningReport(false);
        return;
      }

      history.push(
        Urls.reportView({
          entityName: selectedProject.entityName,
          projectName: selectedProject.name,
          reportID: view.id,
          reportName: view.displayName,
        })
      );
    }, [
      view.id,
      view.displayName,
      history,
      cloningReport,
      moveView,
      selectedProject,
    ]);

    return (
      <Modal className="clone-report-modal" open onClose={onClose}>
        <Modal.Header>{moveReport ? 'Move' : 'Clone'} Report</Modal.Header>
        <Modal.Content>
          <p className="clone-report-modal__text">
            Pick a destination for your {moveReport ? 'moved' : 'cloned'}{' '}
            report.
          </p>
          <ModifiedDropdown
            selection
            search
            className="clone-report-modal__select"
            options={projectOptions}
            value={selectedProjectID}
            onChange={onChangeProject}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={cloningReport} onClick={onClose}>
            Back
          </Button>
          <Button
            primary
            loading={cloningReport}
            disabled={cloningReport}
            onClick={moveReport ? onMoveReport : onCloneReport}>
            {moveReport ? 'Move' : 'Clone'} report
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
  {id: 'CloneReportModal', memo: true}
);

export default CloneReportModal;
