import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';

import {Project} from '../types/graphql';
import {AccessOptions} from '../util/permissions';
import {fragments as runFragments} from './runs';

// TODO: Rename these queries to be project instead of model
// TODO: Type the Results
export const MODEL_QUERY = gql`
  query Model(
    $name: String!
    $logCursor: String
    $fileCursor: String
    $fileLimit: Int = 10
    $fileNames: [String] = []
    $entityName: String
    $bucketName: String!
    $upload: Boolean
    $detailed: Boolean!
    $includeFiles: Boolean = false
  ) {
    project(name: $name, entityName: $entityName) {
      id
      readOnly
      name
      entityName
      description
      createdAt
      bucketCount
      access
      summaryMetrics
      run(name: $bucketName) {
        id
        name
        __typename
        ...SelectRunFragment
        ...BasicRunFragment
        ...DetailedRunFragment @include(if: $detailed)
        ...RunFilesFragment @include(if: $includeFiles)
      }
      views
    }
    viewer {
      id
      email
      photoUrl
      admin
      defaultFramework
    }
  }
  ${runFragments.selectRun}
  ${runFragments.basicRun}
  ${runFragments.detailedRun}
  ${runFragments.runFiles}
`;

export const PROJECT_UPSERT = gql`
  mutation upsertModel(
    $description: String
    $entityName: String
    $id: String
    $name: String
    $framework: String
    $access: String
    $views: JSONString
  ) {
    upsertModel(
      input: {
        description: $description
        entityName: $entityName
        id: $id
        name: $name
        framework: $framework
        access: $access
        views: $views
      }
    ) {
      project {
        id
        name
        entityName
        description
        access
        views
      }
      model {
        id
        name
        entityName
        description
        access
        views
      }
      inserted
    }
  }
`;

export const PROJECT_RENAME = gql`
  mutation renameProject(
    $entityName: String!
    $oldProjectName: String!
    $newProjectName: String!
  ) {
    renameProject(
      input: {
        entityName: $entityName
        oldProjectName: $oldProjectName
        newProjectName: $newProjectName
      }
    ) {
      project {
        id
        name
        entityName
        description
        access
        views
      }
      model {
        id
        name
        entityName
        description
        access
        views
      }
      inserted
    }
  }
`;

export interface ProjectUpsertMutationData {
  project: {
    id: string;
    name: string;
    entityName: string;
    description: string;
    access: AccessOptions;
    views: any;
  };
  model: {
    id: string;
    name: string;
    entityName: string;
    description: string;
    access: AccessOptions;
    views: any;
  };
  inserted: boolean;
}

export interface ProjectUpsertMutationVariables {
  id?: string;
  name: string;
  entityName: string;
  framework?: any;
  access?: AccessOptions;
  views?: any;
}

// TODO: See how to generate this with something from schema.
type Identifier = {id: string} | {name: string; entityName: string};
export type UpdateProjectMutationFn = (
  vars: {
    id?: string;
    description?: string;
    access?: string;
    linkedBenchmark?: string;
    entityName?: string;
    views?: string;
    name?: string;
  } & Identifier
) => Promise<
  ApolloQueryResult<{
    upsertModel: {
      project: Project;
      inserted: boolean;
    };
  }>
>;

export const MODEL_DELETION = gql`
  mutation deleteModel($id: String!) {
    deleteModel(input: {id: $id}) {
      success
    }
  }
`;

export const MODELS_QUERY = gql`
  query Models(
    $cursor: String
    $entityName: String
    $order: String
    $first: Int = 500
    $anonymous: Boolean = false
  ) {
    projects(
      first: $first
      after: $cursor
      entityName: $entityName
      order: $order
      anonymous: $anonymous
    ) {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          totalUsers
          lastActive
          totalRunTime
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    viewer {
      id
      email
      photoUrl
    }
  }
`;
