import {all} from 'redux-saga/effects';
import RunsSagas from './runs-low/sagas';
import {ApolloClient} from './types';
import ViewsSagas from './views/sagas';

// TODO: We let errors / rejections bubble up to here. Set the error state in redux
// when it happens so we show the error page, and test that this works on Apollo
// client error, and on thrown Errors e.g. in views/api:loadMetadataList
export default function* rootSaga(client: ApolloClient) {
  yield all([RunsSagas(client), ViewsSagas(client)]);
}
