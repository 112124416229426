import React, {useLayoutEffect} from 'react';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';

import config, {envIsProd} from '../config';
import {withViewer} from '../graphql/users_get_viewer';
import Loader from '../components/WandbLoader';
import '../css/Home.less';
import {auth} from '../setup';
import {User} from '../types/graphql';
import makeComp from '../util/profiler';
import {homeQuickStart, login} from '../util/urls';
import {useViewerRunsQuery} from '../generated/graphql';
import {propagateErrorsContext} from '../util/errors';
import {useDeferRunsQuery} from './HomePageNew';

const REDIRECT_TO_SITE = !config.ENVIRONMENT_IS_PRIVATE && envIsProd;
interface HomeProps {
  viewerLoading: boolean;
  viewer?: User;
}

// Export Home for testing purposes
export const Home: React.FC<HomeProps> = makeComp(
  ({viewerLoading, viewer}) => {
    const params = queryString.parse(document.location.search);
    const fromVerifyEmail = 'verifyEmail' in params;

    // Fetch user runs to determine whether they land on home or quickstart page.
    // If a user is on a team and has no runs of their own, show them the normal home page.
    const runsQuery = useViewerRunsQuery({
      fetchPolicy: 'network-only',
      context: propagateErrorsContext(),
    });
    const deferRunsQuery = useDeferRunsQuery();
    const waitingForRunsQuery = runsQuery.loading && !deferRunsQuery;
    const firstRunLogged =
      (runsQuery.data?.viewer?.runs?.edges ?? []).length > 0;
    const showOnboarding = !runsQuery.loading && !firstRunLogged;
    const userIsOnTeam = (viewer?.teams.edges ?? []).length > 1;

    useLayoutEffect(() => {
      if (
        !REDIRECT_TO_SITE ||
        params.loader ||
        viewerLoading ||
        viewer != null ||
        fromVerifyEmail
      ) {
        return;
      }
      window.location.href = '/site';
    }, [fromVerifyEmail, params.loader, viewer, viewerLoading]);

    if (params.loader) {
      let backgroundColor = '#ecbb33';
      if (params.color != null) {
        backgroundColor =
          typeof params.color === 'string' ? params.color : params.color[0];
      }
      return (
        <div
          style={{
            backgroundColor,
            width: '100%',
            height: 500,
            position: 'relative',
          }}>
          <Loader {...params} />
        </div>
      );
    }
    if (viewerLoading || waitingForRunsQuery) {
      return <Loader />;
    }
    if (viewer != null) {
      const pathname =
        showOnboarding && !userIsOnTeam ? homeQuickStart() : `/home`;
      return <Redirect to={{pathname, state: {internal: true}}} />;
    }
    // Immediately log the user in if they're redirected from email verification link
    if (fromVerifyEmail) {
      auth.login();
      return <Loader />;
    }

    if (!REDIRECT_TO_SITE) {
      /* eslint-disable wandb/no-a-tags */
      return (
        <div className="front-page">
          <div className="hero">
            <h1>Developer tools for deep learning</h1>
            <h2>Those who don't track training are doomed to repeat it.</h2>
            {!config.ENVIRONMENT_IS_PRIVATE && (
              <a href={login({signup: true})} data-test="signup">
                Sign Up
              </a>
            )}
          </div>
          <div className="main-page" />
        </div>
      );
      /* eslint-enable wandb/no-a-tags */
    }
    return null;
  },
  {id: 'Home'}
);

export default withViewer(Home);
