import '../css/ReportPageHeaderEdit.less';
import * as S from './ReportPageHeaderEdit.styles';

import {useApolloClient, useSelector} from '../state/hooks';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {Input} from 'semantic-ui-react';
import EditableField from './EditableField';
import {usePart, useViewRefAction} from '../state/views/hooks';
import * as ViewActions from '../state/views/actions';
import {ReportViewRef} from '../state/reports/types';
import {ReportPageQueryData} from '../state/graphql/reportPageQuery';
import LegacyWBIcon from './elements/LegacyWBIcon';
import * as ReportViewsSelectors from '../state/reports/selectors';
import * as ReportActions from '../state/views/report/actions';
import * as ViewHooks from '../state/views/hooks';
import classNames from 'classnames';
import * as Urls from '../util/urls';
import {Link} from 'react-router-dom';
import {ReportPageNormalized} from '../state/views/report/types';
import {ReportAuthor} from '../util/section';
import {useTeamMembersQuery} from '../generated/graphql';
import {fuzzyMatchWithMapping} from '../util/fuzzyMatch';
import * as ViewApi from '../state/views/api';
import makeComp from '../util/profiler';
import {useAdminModeActive} from '../util/admin';
import {
  editorHasMarkdownBlocks,
  convertAndTransformAllMarkdownBlocks,
  revertConvertAllMarkdownBlocks,
} from './Slate/plugins/markdown-blocks';
import {useWysiwygConversionContext} from '../util/userContext';

interface ReportPageHeaderEditProps {
  viewRef: ReportViewRef;
  project?: ReportPageQueryData['project'];
}

export default makeComp(
  (props: ReportPageHeaderEditProps) => {
    const {viewRef} = props;
    const admin = useAdminModeActive();

    const view = useSelector(state => state.views.views[viewRef.id]);
    const [parentViewLoaded, setParentViewLoaded] = useState(false);
    const [parentViewUser, setParentViewUser] = useState<{
      username: string;
      name?: string;
    } | null>(null);

    const client = useApolloClient();
    useEffect(() => {
      (async () => {
        if (!view.parentId) {
          setParentViewLoaded(true);
          return;
        }
        const loaded = await ViewApi.load(client, view.parentId);
        setParentViewUser(loaded.user);
        setParentViewLoaded(true);
      })();
    }, [client, view.parentId]);

    const reportID = view.id;
    if (reportID == null || view.partRef == null) {
      throw new Error('invalid state');
    }
    const viewPart = usePart(view.partRef) as ReportPageNormalized;
    const rename = useViewRefAction(viewRef, ViewActions.rename);
    const setNotes = useViewRefAction(viewRef, ViewActions.setDescription);
    const entityName = view.project?.entityName ?? view.entityName;
    const notes = view.description || '';

    const reportRef = useSelector(ReportViewsSelectors.getReportRef(viewRef));

    const addAuthor = ViewHooks.useViewAction(
      reportRef,
      ReportActions.addAuthor
    );
    const removeAuthor = ViewHooks.useViewAction(
      reportRef,
      ReportActions.removeAuthor
    );
    const viewAuthors = viewPart.authors;
    const authors = viewAuthors ?? [];
    const originalAuthor = authors[0];
    useEffect(() => {
      if (!parentViewLoaded) {
        return;
      }
      if (viewAuthors == null || (!admin && viewAuthors.length === 0)) {
        const authorUser = parentViewUser != null ? parentViewUser : view.user;
        addAuthor(_.pick(authorUser, 'username', 'name'));
      }
    }, [
      admin,
      viewAuthors,
      addAuthor,
      view.user,
      parentViewUser,
      parentViewLoaded,
    ]);
    const [authorAdderActive, setAuthorAdderActive] = useState(false);

    const teamMembersQuery = useTeamMembersQuery({
      variables: {entityName},
    });

    let authorOptions: ReportAuthor[] = [];
    if (
      !teamMembersQuery.loading &&
      teamMembersQuery.data?.entity?.members != null
    ) {
      authorOptions = teamMembersQuery.data.entity.members as ReportAuthor[];
      if (!admin) {
        authorOptions = authorOptions.filter(
          m => m.username !== originalAuthor?.username
        );
      }
    }

    const {
      dismissed: wysiwygConversionDismissed,
      dismiss: dismissWysiwygConversion,
      loading: wysiwygConversionContextLoading,
    } = useWysiwygConversionContext();
    const [convertOngoing, setConvertOngoing] = useState(false);
    const [convertedToWysiwyg, setConvertedToWysiwyg] = useState(false);
    const convertToWysiwyg = useCallback(() => {
      if (convertOngoing) {
        return;
      }
      setConvertOngoing(true);
      setTimeout(() => {
        convertAndTransformAllMarkdownBlocks();
        setConvertedToWysiwyg(true);
        setConvertOngoing(false);
      });
    }, [convertOngoing]);
    const revertConvertToWysiwyg = useCallback(() => {
      if (convertOngoing) {
        return;
      }
      setConvertOngoing(true);
      setTimeout(() => {
        revertConvertAllMarkdownBlocks();
        setConvertedToWysiwyg(false);
        setConvertOngoing(false);
      });
    }, [convertOngoing]);
    const showWysiwygNotice =
      !wysiwygConversionContextLoading &&
      !wysiwygConversionDismissed &&
      (editorHasMarkdownBlocks() || convertedToWysiwyg);
    const wysiwygNotice = showWysiwygNotice ? (
      <S.WysiwygNotice>
        {!convertedToWysiwyg ? (
          <div>
            This report contains Markdown blocks. Would you like to convert them
            into{' '}
            <S.WysiwygNoticeLink href="https://www.notion.so/wandbai/WYSIWYG-Reports-FAQ-7d9527dc33e142b1ab66f431e21cdf88">
              WYSIWYG
            </S.WysiwygNoticeLink>
            ?
          </div>
        ) : (
          <S.WysiwygNoticeSuccess>
            <S.WysiwygNoticeSuccessIcon name="check" />
            <div>
              Successfully converted to WYSIWYG.{' '}
              <S.WysiwygNoticeLink href="https://www.notion.so/WYSIWYG-Reports-FAQ-7d9527dc33e142b1ab66f431e21cdf88#e1a428c7328542d8ab07a28b5e27acc9">
                Some formatting may have changed
              </S.WysiwygNoticeLink>
              .
            </div>
          </S.WysiwygNoticeSuccess>
        )}
        <S.WysiwygNoticeActions>
          {!convertedToWysiwyg ? (
            <S.WysiwygConvert onClick={convertToWysiwyg}>
              {convertOngoing ? 'Converting...' : 'Convert'}
            </S.WysiwygConvert>
          ) : (
            <S.WysiwygRevert onClick={revertConvertToWysiwyg}>
              {convertOngoing ? 'Reverting...' : 'Revert'}
            </S.WysiwygRevert>
          )}
          <S.WysiwygDismiss onClick={dismissWysiwygConversion}>
            Dismiss
          </S.WysiwygDismiss>
        </S.WysiwygNoticeActions>
      </S.WysiwygNotice>
    ) : null;

    return (
      <div className="report-header-edit">
        <div className="report-header-edit__container">
          {wysiwygNotice}
          <EditableField
            className="report-header-edit__name-header"
            readOnly={false}
            asHeader="h1"
            placeholder="Report title"
            maxLength={128}
            value={view.displayName || ''}
            save={value => rename(value)}
          />
          <EditableField
            className="report-header-edit__description"
            multiline
            maxLength={512}
            readOnly={false}
            placeholder={'Add a description...'}
            value={notes}
            // Report metadata is loaded asynchronously so we need to support
            // dynamic updates to the description.
            updateValue={true}
            save={setNotes}
          />
          <div className="report-header-edit__authors">
            {!authorAdderActive
              ? authors.map(({username, name}, i) => (
                  <React.Fragment key={username}>
                    <Link
                      className="report-header-edit__author"
                      to={Urls.profilePage(username)}>
                      {name || username}
                    </Link>
                    {i < authors.length - 1 && ', '}
                  </React.Fragment>
                ))
              : !admin &&
                originalAuthor != null && (
                  <Link
                    className="report-header-edit__author"
                    to={Urls.profilePage(originalAuthor.username)}>
                    {originalAuthor.name || originalAuthor.username}
                  </Link>
                )}
            {authorOptions.length > 0 && (
              <>
                <LegacyWBIcon
                  name="plus"
                  className="report-header-edit__add-author"
                  onClick={() => setAuthorAdderActive(true)}
                />
                <AuthorAdder
                  active={authorAdderActive}
                  added={
                    admin ? authors : authors.filter(a => a !== originalAuthor)
                  }
                  options={authorOptions}
                  onAdd={addAuthor}
                  onRemove={removeAuthor}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
  {id: 'ReportPageHeaderEdit'}
);

const AUTHOR_ADDER_MAX_RESULTS = 10;

interface AuthorAdderProps {
  active: boolean;
  added: ReportAuthor[];
  options: ReportAuthor[];
  onAdd(a: ReportAuthor): void;
  onRemove(a: ReportAuthor): void;
}

const AuthorAdder = makeComp(
  ({active, added, options, onAdd, onRemove}: AuthorAdderProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [query, setQuery] = useState('');
    const inputRef = useRef<Input | null>(null);

    useEffect(() => {
      if (!active) {
        return;
      }
      setTimeout(() => {
        if (inputRef.current != null) {
          inputRef.current.focus();
        }
      }, 100);
    }, [active]);

    const add = useCallback(
      (a: ReportAuthor) => {
        onAdd(a);
        setQuery('');
        if (inputRef.current != null) {
          inputRef.current.focus();
        }
      },
      [onAdd]
    );

    const isAdded = useCallback(
      (a: ReportAuthor) => added.some(author => a.username === author.username),
      [added]
    );

    const optionsToAdd = useMemo(() => {
      let results = options.filter(o => o.username != null && !isAdded(o));
      if (query.length > 0) {
        results = fuzzyMatchWithMapping(
          results,
          query,
          ({username, name}) => `${username},${name}`
        );
      }
      return results.slice(0, AUTHOR_ADDER_MAX_RESULTS);
    }, [options, query, isAdded]);

    return (
      <div className={classNames('report-header-edit__author-adder', {active})}>
        <div className="author-adder-fake-input">
          {added.map(author => (
            <div key={author.username} className="author-adder-added">
              {author.name || author.username}
              <LegacyWBIcon
                name="close"
                className="author-adder-remove"
                onClick={() => onRemove(author)}
              />
            </div>
          ))}
          <Input
            ref={el => (inputRef.current = el)}
            value={query}
            onChange={e => setQuery(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setShowDropdown(false)}
            placeholder={'Select a team member'}
          />
        </div>
        <div
          className={classNames('author-adder-dropdown', {
            active: showDropdown && optionsToAdd.length > 0,
          })}>
          {optionsToAdd.map(author => (
            <div
              key={author.username}
              className="author-adder-dropdown-item"
              onClick={() => add(author)}>
              {author.name || author.username}
            </div>
          ))}
        </div>
      </div>
    );
  },
  {id: 'AuthorAdder'}
);
